import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import PlantMaster from "../PlantMaster";

class PlantItemMasterWeb extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <PlantMaster {...this.props} />
      </Layout>
    );
  }
}
export default PlantItemMasterWeb;
