import React, { Component } from "react";
import moment from "moment";
import {
  grey,
  darkGrey,
  successButtonBackground,
} from "../../../constants/internal/colors";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  einvoiceAndEwayCardContainer: {
    marginTop: "40px",
    display: "flex",
    gap: "50px",
    width: "90%",
    justifyContent: "flex-end",
    //alignContent: "space-evenly",
  },
  invoicetypeTextContainer: {
    fontSize: "1.4em",
    fontWeight: "bold",
    padding: "10px 20px",
  },
  errorOrSuccessMessageContainer: {
    height: "9vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8em",
    color: successButtonBackground,
  },
  errorMessageContainer: {
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8em",
    color: "red",
  },
};
class EinvoiceAndEwayCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleValidationInvoiceType = (name) => {
    if (
      name === "Combined Invoice" &&
      (
        (this.props?.laDetailsInDispatchCounter?.item_category
          ?.is_allow_rate_zero &&
          Number(this.props?.laDetailsInDispatchCounter?.order?.rate) === 0)
        || this.props?.laDetailsInDispatchCounter?.tran_supply_type === "B2C"
      )
    ) {
      return true;
    }
    if (
      name === "Normal Invoice" &&
      (
        (
          Number(this.props?.laDetailsInDispatchCounter?.order?.rate) !== 0)
        && this.props?.laDetailsInDispatchCounter?.tran_supply_type === "B2B"
      )
    ) {
      return true;
    }
    return false;
    // const { laDetailsInDispatchCounter } = this.props;
    // if (name === "Combined Invoice") {
    //   return true;
    // } else {
    //   return false;
    // }
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value }, () => {
      this.props.handleSelectEnvoiceType(event.target.value);
    });
  };
  handleChangeEway = (event) => {
    this.setState({ value: event.target.value }, () => {
      this.props.handleSelectEway(event.target.value);
    });
  };
  render() {
    const { classes, eWayBill, invoiceMethod } = this.props;

    return (
      <>
        {/* <div className={classes.einvoiceAndEwayCardContainer}>
          <Paper style={{ width: "50%", padding: "20px 0px 0px" }}>
            <div className={classes.invoicetypeTextContainer}>
              <span>Invoice Details</span>
            </div>
            <Divider />
            <div id="test">
              <FormControl
                component="fieldset"
                style={{ width: "100%", padding: "10px 14px" }}
              >
                <div
                  style={{
                    display: "flex",
                    // width: "72%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 4px",
                    fontSize: "1.0em",
                    // color: COLORS.grey,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#839192",
                      fontWeight: "bold",
                      whiteSpace: "nowrap"
                    }}
                  >
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Date
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Plant
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      LA Number
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Supply Type
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Stock
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Invoice Number
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "end",
                    }}
                  >
                    <div
                      style={{
                        height: "16px",
                        marginBottom: "5px",
                      }}
                    >
                      {moment(
                        this.props.laDetailsInDispatchCounter?.gate_in_date
                      ).format("DD-MMM-YYYY")}
                    </div>
                    <div style={{ height: "16px", marginBottom: "5px", whiteSpace: "nowrap" }}>
                      {this.props.laDetailsInDispatchCounter?.plant_name}
                    </div>
                    <div style={{ height: "16px", marginBottom: "5px" }}>
                      {this.props.laDetailsInDispatchCounter?.la_number}
                    </div>
                    <div style={{ height: "16px", marginBottom: "5px" }}>
                      {this.props.laDetailsInDispatchCounter?.tran_supply_type}
                    </div>
                    <div style={this.props.availableStock === null || this.props.availableStock < this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight ? { height: "16px", marginBottom: "5px", color: "red" } : { height: "16px", marginBottom: "5px" }}>
                      {this.props.availableStock === null ? this.props.stockError : `${this.props.availableStock} MTS`}
                    </div>
                    <div style={{ height: "16px", marginBottom: "5px" }}>
                      {this.props.laDetailsInDispatchCounter
                        ?.erp_invoice_number != null
                        ? this.props.laDetailsInDispatchCounter
                          ?.erp_invoice_number
                        : "Click Obtain Invoice Number"}
                    </div>
                  </div>
                </div>
              </FormControl>
            </div>

            <>
              <Divider />

              {this.props.stockError ? "" :
                <div className={classes.errorMessageContainer}>
                  {
                    (Number(this.props.availableStock) >= this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight) ? <></> :
                      <span>Stock is lower than LA quantity</span>
                  }
                </div>
              }
            </>


          </Paper>
          <Paper style={{ width: "50%", padding: "20px 0px 0px" }}>
            <div className={classes.invoicetypeTextContainer}>
              <span>Payment Details</span>
            </div>
            <Divider />
            <div id="test">
              <FormControl
                component="fieldset"
                style={{ width: "100%", padding: "10px 14px" }}
              >
                <div
                  style={{
                    display: "flex",
                    // width: "72%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 4px",
                    fontSize: "1.0em",
                    // color: COLORS.grey,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#839192",
                      fontWeight: "bold",
                      whiteSpace: "nowrap"
                    }}
                  >
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Payment Terms
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Due Date
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Paid Amount
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Balance
                    </span>
                    <span style={{ height: "16px", marginBottom: "5px" }}>
                      Advance Credit Limit(Available)
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "end",
                    }}
                  >
                    <div
                      style={{
                        height: "16px",
                        marginBottom: "5px",
                      }}
                    >
                      {moment(
                        this.props.laDetailsInDispatchCounter?.gate_in_date
                      ).format("DD-MMM-YYYY")}
                    </div>
                    <div style={{ height: "16px", marginBottom: "5px", whiteSpace: "nowrap" }}>
                      {this.props.laDetailsInDispatchCounter?.plant_name}
                    </div>
                    <div style={{ height: "16px", marginBottom: "5px" }}>
                      {this.props.laDetailsInDispatchCounter?.la_number}
                    </div>
                    <div style={{ height: "16px", marginBottom: "5px" }}>
                      {this.props.laDetailsInDispatchCounter?.tran_supply_type}
                    </div>
                    <div style={this.props.availableStock === null || this.props.availableStock < this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight ? { height: "16px", marginBottom: "5px", color: "red" } : { height: "16px", marginBottom: "5px" }}>
                      {this.props.availableStock === null ? this.props.stockError : `${this.props.availableStock} MTS`}
                    </div>
                  </div>
                </div>
              </FormControl>
            </div>

            <>
              <Divider />

              {this.props.stockError ? "" :
                <div className={classes.errorMessageContainer}>
                  {
                    (Number(this.props.availableStock) >= this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight) ? <></> :
                      <span>Stock is lower than LA quantity</span>
                  }
                </div>
              }
            </>


          </Paper>
        </div> */}
        <div className={classes.einvoiceAndEwayCardContainer}>
          <Paper style={{ width: "38%", padding: "20px 0px 0px" }}>
            <div className={classes.invoicetypeTextContainer}>
              <span>
                Invoice Type<span style={{ color: "red" }}>*</span>
              </span>
            </div>
            <Divider />
            <div id="test">
              <FormControl
                component="fieldset"
                style={{ width: "100%", padding: "24px 24px" }}
              >
                <RadioGroup
                  aria-label="gender"
                  name="gender2"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  {invoiceMethod.map((data) => (
                    <FormControlLabel
                      value={data.name}
                      style={{ color: darkGrey }}
                      key={data.name}
                      control={
                        <Radio
                          disabled={this.handleValidationInvoiceType(data.name)}
                          style={{
                            color: grey,
                          }}
                        />
                      }
                      labelPlacement="end"
                      label={data.name}
                      onChange={this.handleChange}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider />
              <div className={classes.errorOrSuccessMessageContainer}>
                <span>Data is ready to generate invoice</span>
              </div>
            </div>
          </Paper>
          <Paper style={{ width: "38%", padding: "20px 0px 0px" }}>
            <div
              style={{
                fontSize: "1.4em",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <span>
                E-Way Bill<span style={{ color: "red" }}>*</span>
              </span>
            </div>
            <Divider />
            <div id="test">
              <FormControl
                component="fieldset"
                style={{ width: "100%", padding: "24px 24px" }}
              >
                <RadioGroup
                  aria-label="gender"
                  name="gender2"
                  value={this.state.value}
                  onChange={this.handleChangeEway}
                >
                  {eWayBill.map((data) => (
                    <FormControlLabel
                      style={{ color: darkGrey }}
                      key={data.name}
                      value={data.name}
                      control={
                        <Radio
                          //disabled={this.checkEWayValidation(data.name)}
                          style={{
                            color: grey,
                          }}
                        />
                      }
                      labelPlacement="end"
                      label={
                        <div style={{ marginLeft: "14px" }}>{data.name}</div>
                      }
                      onChange={this.handleChangeEway}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider />
              <div
                style={{
                  height: "9vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className={classes.errorOrSuccessMessageContainer}>
                  <span>Data is ready to generate e-way</span>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(EinvoiceAndEwayCard);
