import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import DisplayAppVersionData from "../DisplayAppVersionData";

class AppVersionWeb extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <DisplayAppVersionData
          {...this.props}
          selectedappVersionData={this.props.selectedappVersionData}
          appVersionOperation={this.props.appVersionOperation}
          selectedappVersionDataFetchLoader={this.props.selectedappVersionDataFetchLoader}
          selectedappVersion={this.props.selectedappVersion}
        />
      </Layout>
    );
  }
}
export default AppVersionWeb;
