import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '4px',
        height: '12px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: '4px',
        height: '12px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function RandomWeightTable({ randomWeights, grossWt,
    tolerance_from_0to20_mts,
    tolerance_from_20to30_mts,
    tolerance_above_30_mts }) {
    //console.log(weighslipId, allWeighings)

    return (
        <div style={{ padding: '10px' }}>
            {
                randomWeights.length > 0 ? <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Weighslip #</StyledTableCell>
                                <StyledTableCell align="center">Date/Time</StyledTableCell>
                                <StyledTableCell align="center">Weight</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {randomWeights.map((row) => {
                                let allrandomWtTolerance = ((row.weight - grossWt) / grossWt) * 100;
                                return (<StyledTableRow key={row.item_id}>
                                    <StyledTableCell component="th" scope="row" align="center">
                                        {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{moment(row.created_at).format("DD-MMM-yyyy  hh:mm A")}</StyledTableCell>
                                    <StyledTableCell align="center"
                                        style={{
                                            color: ((20.000 >= grossWt && grossWt > 0) && (allrandomWtTolerance > tolerance_from_0to20_mts)) ? "red" :
                                                ((20.001 <= grossWt && grossWt <= 30.000) && (allrandomWtTolerance > tolerance_from_20to30_mts)) ? "red" :
                                                    (grossWt >= 30.001 && allrandomWtTolerance > tolerance_above_30_mts) ? "red" : (allrandomWtTolerance < 0) ? "red" : "black"
                                        }}
                                    >{row.weight} MTS</StyledTableCell>
                                </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                    : <></>
            }
        </div>
    );
}