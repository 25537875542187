import React, { Component } from "react";
import Bread from "../../common/Breadcrubs";
import { withStyles } from "@material-ui/core/styles";
import ManagePlantMaster from "./ManagePlantMaster";
import ManageGatesMaster from "./ManageGatesMaster";
import ManageWeighbridgeMaster from "./ManageWeighbridgeMaster";
import ManageDispatchCounterMaster from "./ManageDispatchCounterMaster";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";

const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
};
class PlantItemMasterWeb extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div style={styles.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  this.props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Plant Item Master",
                onClick: () =>
                  this.props.history.push(
                    `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}`
                  ),
              },
              {
                title:
                  this?.props?.plantMasterOperation === "add"
                    ? "Create New"
                    : this?.props?.selectedPlantData.plant_name || "",
                onClick: "",
              },
            ]}
          />
          <ManagePlantMaster {...this.props} />
          <ManageGatesMaster {...this.props} />
          <ManageWeighbridgeMaster {...this.props} />
          <ManageDispatchCounterMaster {...this.props} />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(PlantItemMasterWeb);
