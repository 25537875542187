import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import "../../../../styles/b2b/web/DailyRatesWeb.css";
import { withStyles } from "@material-ui/core/styles";
import Ledger from "../Ledger";

const styles = {
  dailyRatesWebMainBody: {
    // background:
    //   "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    overflowY: "scroll",
    width: "65vw",
  },
};
class LedgerWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      classes,
      isMobile,
      ledgerRequestData,
      ledgerRequestStatus,
      handleDateChange,
      fromDate,
      toDate,
      ledgerType,
      isLoading,
    } = this.props;

    return (
      <WebLayout appBar profileSelect>
        <div className={classes.dailyRatesWebMainBody}>
          <Ledger
            {...this.props}
            classes={classes}
            isMobile={isMobile}
            ledgerRequestStatus={ledgerRequestStatus}
            fromDate={fromDate}
            toDate={toDate}
            handleDateChange={handleDateChange}
            ledgerRequestData={ledgerRequestData}
            ledgerType={ledgerType}
            isLoading={isLoading}
            handleLedgerType={this.props.handleLedgerType}
            handleSubmitButton={this.props.handleSubmitButton}
            handleResetLedgerRequest={this.props.handleResetLedgerRequest}
            handleClearState={this.props.handleClearState}
            handleDownloadPDFButton={this.props.handleDownloadPDFButton}
          />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(LedgerWeb);
