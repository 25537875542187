import React from 'react';
import html2pdf from 'html2pdf.js';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Button from '@material-ui/core/Button';

import { pxToEm } from '../../../../methods';
const DownloadPdf = (props) => (
  <div className="tc mb4 mt2">
    <div id="myMm" style={{ height: '1mm' }} />

    <Button
      onClick={() => {
        let options = {
          filename: props.fileName,
          margin: 10,
          html2canvas: {
            scale: 4,
          },
          jsPDF: {
            format: 'a4',
            orientation: 'portrait',
            pagesplit: true,
          },
        };
        let htmlData = document.getElementById(props.printSection).innerHTML;
        html2pdf()
          .from(htmlData)
          .set(options)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            let totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.text(
                ` ${props.headerTitle ? props.headerTitle : ''}  ${
                  props.headerDate ? props.headerDate : ''
                }  ${props.headerPublisedBy ? props.headerPublisedBy : ''}`,
                5,
                5
              );
            }
          })
          .save();
      }}
      style={{
        marginRight: 10,
        fontSize: pxToEm(14),
      }}
      startIcon={<CloudDownloadOutlinedIcon />}
    >
      DOWNLOAD
    </Button>
  </div>
);
export default DownloadPdf;
