import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Typography,
    Button,
    CircularProgress,
    Tooltip,
    // Checkbox,
    TablePagination,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    IconButton,
    Popover,
    Divider,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import {
    grey,
    secondaryGrey,
    whiteSmoke,
    drawerTextColor,
    successButtonBackground,
    secondaryBlueText,
} from "../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { checkInvalidValue, hasSpecialAccess, hasUpdateAccess, pxToEm } from "../../../../methods";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import "../../../../styles/internal/scroll.css";
import ToolTipComponent from "../../common/ToolTipComponent";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    formControl: {
        minWidth: 200,
        maxWidth: 500,
    },
    removeIconDimensions: {
        height: 18,
        width: 18,
        margin: "0px 5px 0px 5px",
        color: grey,
        cursor: "pointer",
    },
    tableHeader: {
        backgroundColor: whiteSmoke,
        color: grey,
    },
    tableHead: {
        fontSize: pxToEm(13),
        fontWeight: "bold",
        color: grey,
        width: '33%'
    },
    tableBody: {
        fontFamily: 'Poppins',
        fontSize: pxToEm(13),
        width: '23%'
    },
    addNewButton: {
        width: "181px",
        height: "43px",
        border: `1px dashed ${secondaryGrey}`,
        borderRadius: "4px",
        opacity: "1",
        color: grey,
        fontSize: pxToEm(13),
        fontWeight: "bold",
    },
    customerTypeTitle: {
        fontSize: pxToEm(15),
        color: grey,
        fontWeight: "bold",
        margin: "20px 0px 20px 0px",
    },
    saveButton: {
        backgroundColor: successButtonBackground,
        color: drawerTextColor,
        marginRight: 10,
    },
    addAndSaveButton: {
        display: "flex",
        alignItems: "center",
        margin: "15px 0px 20px 0px",
        justifyContent: "space-between",
    },
    saveCancelButton: {
        display: "flex",
        placeSelf: "strech",
    },
    alignStateText: {
        display: "flex",
        placeContent: "flex-start",
        alignItems: "center",
    },
    rightSpacing: {
        display: "flex",
        marginLeft: "10px",
        fontSize: "0.8em",
        alignItems: "center",
        fontWeight: "normal",
    },
    editIcon: {
        heigth: "15px",
        width: "15px",
    },
    iconContainer: {
        height: 30,
        width: 30,
        background: "yellow",
        borderRadius: 100,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: whiteSmoke,
        overflow: "hidden",
        color: grey,
    },
    dataFieldsForCheckbox: {
        display: "flex",
        alignItems: "center",
        color: secondaryBlueText,
    },
    captionDetails: {
        fontSize: pxToEm(13),
        fontWeight: "bold",
        color: grey,
        paddingBottom: 10,
    },
    titleLabelStyle: {
        fontSize: pxToEm(16),
        color: secondaryBlueText,
        fontWeight: "bold",
    },
    '@keyframes spin': {
        "0%": {
            paddingTop: 0,
            marginBootom: 1,
            transform: 'scale(0.8) rotate(360deg)'
        },
        "100%": {
            paddingTop: 0,
            marginBootom: 1,
            transform: 'scale(0.8) rotate(0deg)'
        }
    }
});
const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: "10px 5px",
            },
        },
    },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
};

const BasicPopover = (props) => {

    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        // className={classes.popoverBox}
        >
            <Typography>
                <div style={{ width: 450, padding: "3%" }}>
                    <Typography style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: pxToEm(13),
                        fontWeight: '500',
                        alignItems: "center",
                        fontFamily: 'Poppins',
                    }}>
                        <div style={{ color: secondaryBlueText, width: '30%', textAlign: 'start' }}>
                            Old Value
                        </div>
                        <div style={{ color: secondaryBlueText, width: '30%' }}>
                            New Value
                        </div>
                        <div style={{ color: secondaryBlueText, width: '40%', textAlign: 'end' }}>
                            Modified By
                        </div>
                    </Typography>
                    <Divider variant='middle' style={{ margin: '2% 0' }} />
                    {props.content.map((data, index) => {
                        return (
                            <div key={index}>
                                <Typography style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontSize: pxToEm(13),
                                    fontWeight: '500',
                                    alignItems: "flex-start",
                                    fontFamily: 'Poppins',
                                }}>
                                    <div style={{ width: '30%', textAlign: 'start' }}>
                                        {data?.old_value}
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        {data?.new_value}
                                    </div>
                                    <div style={{ width: '40%', textAlign: 'end' }}>
                                        {data?.created_by}
                                        <p style={{ margin: 0, fontSize: pxToEm(13), color: grey }}>
                                            {
                                                moment(data?.created_at).format("DD-MMM-YYYY") + " | " +
                                                moment(data?.created_at).format("hh:mm A")
                                            }
                                        </p>
                                    </div>
                                </Typography>
                                <Divider
                                    variant='middle'
                                    style={{
                                        margin: '2% 0',
                                        display: index === (props.content?.length - 1) ? 'none' : 'block'
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </Typography>
        </Popover>
    );
}


export default function ManageCustomerAccountCreditLimit(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [anchorEl, setAnchorEl] = useState(null);
    const [creditLimitPopoverData, setCreditLimitPopoverData] = useState([]);
    // const [syncButtonClicked, setSyncButtonClicked] = useState(null);
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const allAllowedPaymentMethods =
    //   props.props.allAllowedPaymentMethods &&
    //     props.props.allAllowedPaymentMethods.length > 0
    //     ? props.props.allAllowedPaymentMethods
    //     : [];
    // const customerPaymentMethods =
    //   props.props.customerAllowedPaymentMethods &&
    //     props.props.customerAllowedPaymentMethods.length > 0
    //     ? props.props.customerAllowedPaymentMethods
    //     : [];
    // const filteredAllAllowedPaymentMethods = allAllowedPaymentMethods.filter(
    //   (x) =>
    //     !customerPaymentMethods.filter((y) => y.payment_method_detail.id === x.id)
    //       .length
    // );


    // const handleCustomerCreditLimitMethodOperation = (operation, type) => {
    //   type === 'ADVANCE'
    //     ? setCustomerCreditLimitAdvanceOperation(operation)
    //     : type === 'NON-ADVANCE' && setCustomerCreditLimitNonAdvanceOperation(operation)
    // };

    useEffect(() => {
        props?.handleSubcontractVclLimit()
    }, [])

    const handleTotalCreditLimitCheckButton = (field) => {
        // handleCustomerCreditLimitMethodOperation('view', field)
        props?.handleTotalCreditLimitSaveButton(field)
    }

    const handleSyncClick = (field) => {
        // setSyncButtonClicked(field)
        props?.handleSyncEffectiveCreditLimit(field)
        // setTimeout(() => {
        // setSyncButtonClicked(null)
        // }, 1500)
    }

    const handlePopoverClick = (event, field) => {
        if (field === 'advance') {
            setCreditLimitPopoverData(props?.totalCreditLimitAdvanceLogDetail)
        }
        else if (field === 'non-advance') {
            setCreditLimitPopoverData(props?.totalCreditLimitOtherLogDetail)
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    return (
        <>
            <Typography className={classes.customerTypeTitle} component={"span"}>
                <div className={classes.alignStateText}>
                    <Typography className={classes.titleLabelStyle}>
                        CUSTOMER CREDIT LIMIT (VCL)
                    </Typography>
                </div>
            </Typography>
            <Typography className={classes.captionDetails} component={"span"}>
                This credit limit value will specify the quantity given to customer for excess order.
            </Typography>
            <ThemeProvider theme={theme}>
                <Paper>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow className={classes.tableHeader} style={{ fontFamily: 'Poppins' }}>
                                    <TableCell
                                        align="left"
                                        className={classes.tableHead}
                                    >
                                        SUBCONTRACT NUMBER
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.tableHead}
                                    // style={{ width: '20%' }}
                                    >
                                        CREDIT LIMIT {"(PROVIDED)"}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={classes.tableHead}
                                    // style={{ width: '35%' }}
                                    >
                                        CREDIT LIMIT {"(AVAILABLE)"}
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.subcontractVclLimit &&
                                    props?.subcontractVclLimit.length > 0
                                    ? props?.subcontractVclLimit
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="left"
                                                    className={classes.tableBody}
                                                    style={{ paddingBottom: 20 }}>{row.sub_contract_number}</TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    className={classes.tableBody}
                                                    style={{ paddingBottom: 20 }}>{row.total_vcl}</TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={classes.tableBody}>{row.available_vcl}</TableCell>
                                            </TableRow>
                                        ))
                                    : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {props?.subcontractVclLimit?.length > 0 ? (
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            component="div"
                            count={props?.subcontractVclLimit?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    ) : (
                        ""
                    )}
                </Paper>
            </ThemeProvider>

        </>
    );
}
