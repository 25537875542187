import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
  secondaryColor,
} from "../../../styles/colors";
import "../../../styles/b2b/web/RegistrationWeb.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as EmailValidator from "email-validator";
import Button from "@material-ui/core/Button";
import CircularProgressLoader from "../../common/CircularProgressLoader";
import SendIcon from '@mui/icons-material/Send';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { IconButton } from "@material-ui/core";

const styles = {
  registerLoginCredentialWebMainBody: {
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 50px 20px 50px",
    maxHeight: "85%",
    overflowY: "scroll",
  },
  registerLoginCredentialWebInternalBody: {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  InputAdornmentStyle: {
    color: secondaryColor,
    display: "flex",
    alignItems: "center",
    fontSize: "1.2em",
  },
  textFieldStyle: {
    width: "100%",
  },
};

class RegisterLoginCredentialWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberExist: false,
      numberNotExist: false,
    };
  }

  handleGetEmailOtp = () => {
    this.setState(
      {
        emailOTPLoader: true,
        otpEmailError: false,
        emailOtpRecieved: false,
      },
      () => {
        this.props.resetEmailOtp();
        this.props.verifyEmailCliked();
        // this.props
        //   .otpSendInEmail(this.props.email)
        this.props
          .GetOtpEmailRegistration(this.props.email)
          .then((res) => {
            if (res.status === 200) {
              this.props.handleTimerOn(true)
              this.setState(
                {
                  emailOTPLoader: false,
                  disabledEmailVerifyFor360Sec: true,
                  emailOtpRecieved: true,
                  emailOTPNumber: res.data.verification_code,
                },
                () => {
                  this.handleDisabledEmailVerifyFor30Sec();
                  this.props.timer(360)
                  this.props.handleChangeEmailOTPNumber(
                    this.state.emailOTPNumber
                  );
                }
              );
            }
          })
          .catch((error) => {
            this.setState({ emailOTPLoader: false });
          });
      }
    );
  };
  handleVerifyEmailOtp = () => {
    this.props
      .VerifyOtpEmailRegistration(this.props.email, this.props.emailOTP)
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          this.props.handleTimerOn(false)
          this.props.handleOtpValiadationText('OtpEmailText', "OTP Verified Successfully")
          this.setState({
            disabledEmailVerifyFor360Sec: false
          })
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.handleOtpValiadationText('OtpEmailText', error.response?.data.msg)
        this.setState({ emailOTPLoader: false, });
      });
  };
  handleContactVerify = () => {
    this.props.resetOTP();
    this.setState({
      contactNumberOTPLoader: true,
      otpError: false,
    });

    if (this.props.contactNumber.length === 10) {
      // this.props
      //   .phoneExist(this.props.contactNumber)
      this.props
        .GetOtpPhoneRegistration(this.props.contactNumber)
        .then((res) => {
          if (res.status === 200) {
            this.props.handleTimerOn(true)
            this.setState(
              {
                disabledContactVerifyFor360Sec: true,
                contactNumberOTPLoader: false,
                otpRecieved: true,
                otpNumber: res.data.otp,
              },
              () => {
                this.handleDisabledContactVerifyFor30Sec();
                this.props.timer(360)
                this.props.handleChangeOtpNumber(
                  this.state.otpNumber
                );
              }
            );
          }
        })
        .catch((error) => {
          console.log(error.response?.data)
          this.props.handleOtpValiadationText('OtpPhoneText', error?.response?.data)
          this.setState({ contactNumberOTPLoader: false, });
        });
    }
  }
  //         if (status.data.msg === "Already exist") {
  //           this.setState({ numberExist: true }, () => {
  //             if (this.state.numberExist) {
  //               this.setState({
  //                 contactNumberOTPLoader: false,
  //               });
  //             }
  //           });
  //         }
  //         if (status.data.msg === "Not exist") {
  //           this.setState(
  //             {
  //               numberNotExist: true,
  //               disabledContactVerifyFor360Sec: true,
  //             },
  //             () => {
  //               this.props
  //                 .sendOTP(this.props.contactNumber)
  //                 .then((res) => {
  //                   if (res.status === 200) {
  //                     this.setState(
  //                       {
  //                         disabledContactVerifyFor360Sec: true,
  //                         contactNumberOTPLoader: false,
  //                         otpRecieved: true,
  //                         otpNumber: res.data.otp,
  //                       },
  //                       () => {
  //                         this.handleDisabledContactVerifyFor30Sec();
  //                         this.props.timer(360)
  //                         this.props.handleChangeOtpNumber(
  //                           this.state.otpNumber
  //                         );
  //                       }
  //                     );
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   this.setState({
  //                     contactNumberOTPLoader: false,
  //                     otpRecieved: false
  //                   });
  //                 });
  //             }
  //           );
  //         }
  //         else {
  //           this.setState({
  //             contactNumberOTPLoader: false,
  //             otpRecieved: false
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         this.setState({
  //           contactNumberOTPLoader: false,
  //           otpRecieved: false
  //         });
  //       });
  //   }
  // };
  handleVerifyPhoneOtp = () => {
    this.props
      .VerifyOtpPhoneRegistration(this.props.contactNumber, this.props.otp)
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          this.props.handleTimerOn(false)
          this.props.handleOtpValiadationText('OtpPhoneText', "OTP Verified Successfully")
          this.setState({
            disabledContactVerifyFor360Sec: false
          })
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.handleOtpValiadationText('OtpPhoneText', error.response?.data.msg)
        this.setState({ contactNumberOTPLoader: false, });
      });
  };
  handleDisabledEmailVerifyFor30Sec = () => {
    setTimeout(() => {
      this.props.handleTimerOn(false)
      this.setState({ disabledEmailVerifyFor360Sec: false });
    }, 360000);
  };
  handleDisabledContactVerifyFor30Sec = () => {
    setTimeout(() => {
      this.props.handleTimerOn(false)
      this.setState({ disabledContactVerifyFor360Sec: false });
    }, 360000);
  };
  render() {
    const {
      emailTypeError,
      contactNumberLengthError,
      numberExist,
      emailExist,
      otpError,
      otpEmailError,
    } = this.state;
    const { classes, contactNumber, email, otp, emailOTP, OtpEmailText, OtpPhoneText, } = this.props;
    return (
      <div
        className={classes.registerLoginCredentialWebMainBody}
        id="registerLoginCredentialWebMainBody"
      >
        <div className={classes.registerLoginCredentialWebInternalBody}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "8px",
            }}
          >
            <span
              style={{ color: dailyRateScreenTextColor, fontSize: "1.2em" }}
            >
              Login Credential
            </span>
            <span
              style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}
            >
              This information will be used to login into the portal
            </span>
            <span
              style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}
            >
              You can add more email and contact later in setting
            </span>
          </div>
          <TextField
            name="email"
            id="outlined-name"
            label={emailExist ? "Email already exist" : "Email"}
            margin="normal"
            variant="outlined"
            disabled={this.state?.disabledContactVerifyFor360Sec || OtpEmailText === "OTP Verified Successfully"}
            value={email.toLowerCase()}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            error={emailTypeError ? true : emailExist ? true : false}
            // onFocus={() => {
            //   this.props.resetEmailOtp();
            //   this.props.activeStep4EmailFocus();
            //   this.setState({
            //     emailTypeError: false,
            //     emailExist: false,
            //     emailOtpRecieved: false,
            //   });
            // }}
            // onBlur={() => {
            //   this.props.activeStep4EmailBlur();
            //   if (EmailValidator.validate(email) === false) {
            //     this.setState({ emailTypeError: true });
            //   }
            //   if (EmailValidator.validate(email) === true) {
            //     this.props
            //       .emailExist(email)
            //       .then((status) => {
            //         if (status.data.msg === "Already exist") {
            //           this.setState({
            //             emailExist: true,
            //             emailOTPLoader: false,
            //           });
            //           this.props.emailExistCheck();
            //         }
            //         if (status.data.msg === "Not exist") {
            //           this.props.resetEmailExistCheck();
            //         }
            //       })
            //       .catch((e) => {
            //         console.log(e);
            //       });
            //   }
            // }}
            inputProps={{
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px" },
            }}
            helperText={
              this.state?.disabledEmailVerifyFor360Sec
                ?
                <span style={{ color: "green", display: 'flex', alignItems: 'center' }}>
                  <TaskAltIcon style={{ transform: 'scale(0.8)' }} />  OTP Sent Successfully
                </span>
                :
                <span
                  style={{
                    color: emailTypeError ? "red" : bottomNavigationFontColor,
                    fontSize: "0.8em",
                  }}
                >
                  {emailTypeError
                    ? "Please enter valid email address"
                    : "An OTP will sent to this address , please  verify"}
                </span>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ transform: this.state.emailOTPLoader && 'scale(0.4)' }}>
                  {this.state.emailOTPLoader ? (
                    <CircularProgressLoader />
                  ) : (
                    <IconButton
                      disabled={
                        EmailValidator.validate(email) === false ||
                        this.state.disabledEmailVerifyFor360Sec ||
                        this.state?.disabledContactVerifyFor360Sec ||
                        OtpEmailText === "OTP Verified Successfully"
                      }
                      style={{
                        fontSize: "0.7em",
                        textTransform: "capitalize",
                        padding: "6px 0px",
                        color:
                          EmailValidator.validate(email) === false ||
                            this.state.disabledEmailVerifyFor360Sec ||
                            this.state?.disabledContactVerifyFor360Sec ||
                            OtpEmailText === "OTP Verified Successfully"
                            ? " grey"
                            : secondaryColor,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleGetEmailOtp();
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              style: {
                paddingRight: 8
              }
            }}
          />
          {this.state.emailOtpRecieved ? (
            <TextField
              name="emailOTP"
              id="outlined-name"
              label="OTP"
              margin="normal"
              variant="outlined"
              value={emailOTP}
              disabled={OtpEmailText === "OTP Verified Successfully"}
              onChange={this.props.handleTextChange}
              className={classes.textFieldStyle}
              inputProps={{
                maxLength: 6,
                style: { padding: "13px 14px" },
              }}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
              onFocus={() => {
                this.setState({ otpEmailError: false });
              }}
              onBlur={() => {
                this.props.handleOnBlurEmailOTP();
                // if (this.state.emailOTPNumber !== emailOTP) {
                //   this.setState({ otpEmailError: true });
                // }
                if (OtpEmailText === "OTP Verified Successfully") {
                  this.setState({ otpEmailError: false })
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={emailOTP.length !== 6 || OtpEmailText === "OTP Verified Successfully" ? true : false}
                      style={{
                        fontSize: "0.7em",
                        padding: "6px 0px",
                        textTransform: "capitalize",
                        color: emailOTP.length !== 6 || OtpEmailText === "OTP Verified Successfully" ? " grey" : secondaryColor
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleVerifyEmailOtp()
                      }}
                    >
                      Verify
                    </Button>
                  </InputAdornment>
                ),
                style: { paddingRight: 0 }
              }}
              helperText={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {OtpEmailText === "OTP Verified Successfully"
                    ?
                    <span style={{ color: "green", display: 'flex', alignItems: 'center' }}>
                      <TaskAltIcon style={{ transform: 'scale(0.8)' }} /> OTP Verified Successfully
                    </span>
                    :
                    <span style={{ color: "red" }}>
                      {OtpEmailText}
                    </span>
                  }
                  <span style={{ color: "green", display: this.props.timerOn ? 'flex' : 'none' }}>
                    {this.props?.timerText}
                  </span>
                </div>
              }
              error={otpEmailError}
            />
          ) : null}
          <TextField
            name="contactNumber"
            id="outlined-name"
            label={numberExist ? "Number already exist" : "Contact Number"}
            margin="normal"
            variant="outlined"
            disabled={this.state?.disabledEmailVerifyFor360Sec || OtpPhoneText === "OTP Verified Successfully"}
            value={contactNumber}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            // onFocus={() => {
            //   this.props.activeStep4PhoneFocus();
            //   this.props.resetOTP();
            //   this.setState({
            //     contactNumberLengthError: false,
            //     numberExist: false,
            //     showOTP_inputField: false,
            //     otpRecieved: false,
            //   });
            // }}
            // onBlur={() => {
            //   this.props.activeStep4PhoneBlur();
            //   if (contactNumber.length < 10) {
            //     this.setState({ contactNumberLengthError: true });
            //   }
            // }}
            error={contactNumberLengthError ? true : numberExist ? true : false}
            inputProps={{
              maxLength: 10,
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px" },
            }}
            helperText={
              this.state.disabledContactVerifyFor360Sec
                ?
                <span style={{ color: "green", display: 'flex', alignItems: 'center' }}>
                  <TaskAltIcon style={{ transform: 'scale(0.8)' }} />  OTP Sent Successfully
                </span>
                :
                <span style={{ color: "red" }}>
                  {contactNumberLengthError
                    ? "Please enter valid 10 digit contact number"
                    : null}
                </span>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ transform: this.state.contactNumberOTPLoader && 'scale(0.4)' }}>
                  {this.state.contactNumberOTPLoader ? (
                    <CircularProgressLoader />
                  ) : (
                    <IconButton
                      disabled={
                        contactNumber.length < 10 ||
                        this.state.disabledContactVerifyFor360Sec ||
                        this.state?.disabledEmailVerifyFor360Sec ||
                        OtpPhoneText === "OTP Verified Successfully"
                      }
                      style={{
                        fontSize: "0.7em",
                        textTransform: "capitalize",
                        padding: "6px 0px",
                        color:
                          contactNumber.length < 10 ||
                            this.state.disabledContactVerifyFor360Sec ||
                            this.state?.disabledEmailVerifyFor360Sec ||
                            OtpPhoneText === "OTP Verified Successfully"
                            ? "grey"
                            : secondaryColor,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleContactVerify();
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              style: {
                paddingRight: 8
              }
            }}
          />
          {this.state.otpRecieved && this.state.otpRecieved ? (
            <TextField
              name="otp"
              id="outlined-name"
              label="OTP"
              margin="normal"
              variant="outlined"
              disabled={OtpPhoneText === "OTP Verified Successfully"}
              value={otp}
              onChange={this.props.handleTextChange}
              className={classes.textFieldStyle}
              inputProps={{
                maxLength: 6,
                style: { padding: "13px 14px" },
              }}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
              onFocus={() => {
                this.setState({ otpError: false });
              }}
              onBlur={() => {
                // if (this.state.otpNumber !== otp) {
                //   this.setState({ otpError: true });
                // }
                if (OtpPhoneText === "OTP Verified Successfully") {
                  this.setState({ otpError: false })
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={otp.length !== 6 || OtpPhoneText === "OTP Verified Successfully" ? true : false}
                      style={{
                        fontSize: "0.7em",
                        padding: "6px 0px",
                        textTransform: "capitalize",
                        color: otp.length !== 6 || OtpPhoneText === "OTP Verified Successfully" ? " grey" : secondaryColor
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleVerifyPhoneOtp()
                      }}
                    >
                      Verify
                    </Button>
                  </InputAdornment>
                ),
                style: { paddingRight: 0 }
              }}
              helperText={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {OtpPhoneText === "OTP Verified Successfully"
                    ?
                    <span style={{ color: "green", display: 'flex', alignItems: 'center' }}>
                      <TaskAltIcon style={{ transform: 'scale(0.8)' }} /> OTP Verified Successfully
                    </span>
                    :
                    <span style={{ color: "red" }}>
                      {OtpPhoneText}
                    </span>
                  }
                  <span style={{ color: "green", display: this.props.timerOn ? 'flex' : 'none' }}>
                    {this.props?.timerText}
                  </span>
                </div>
              }
              // helperText={
              //   <span style={{ color: "red" }}>
              //     {otpError ? "Invalid OTP" : null}
              //   </span>
              // }
              error={otpError}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterLoginCredentialWeb);
