import React, { Component } from "react";
import DispatchPlanning, {
  DispatchPlanningWeb,
  DispatchPlanningDetailsWeb,
} from "../../../components/internal/dispatchPlanning";
import { Route, Switch } from "react-router-dom";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import moment from "moment";
import { checkApiError, isAccessibleModule, isAccessibleWidget } from "../../../methods";
import { filter } from "lodash";
import debounce from "lodash.debounce";
import CreateLoadingAdvice from "../../../components/internal/contracts/web/newUi/CreateLoadingAdvice";
import SnackbarComponent from "../../../components/internal/common/SnackbarComponent";
import OpenInDesktop from "../../../components/internal/common/OpenInDesktop";
import LoadingAdvicesContainer from "../contracts/LoadingAdvices"
import { handlePrintPlantDetails } from "../../../components/internal/dispatchPlanning/PrintContents";

class DispatchPlanningContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchPlaningList: [],
      todaydispatchPlaningList: [],
      tableDataByDate: [],
      originalResponseDispatchPlanningList: [],
      plantLoader: false,
      plants: [],
      selectedItemCategory: null,
      selectedPlant: null,
      transType: [],
      rowsCount: 0,
      searchValue: "",
      orderValidity: 10,
      orderValidityDate: this.generateOrderValidityDate(new Date(), 10),
      selectedCustomerPaymentTerms: [],
      buyerSelectionList: [],
      consigneeSelectionList: [],
      itemDetailsData: [],
      driverLicenceNumber: null,
      selectedOrderId: null,
      allOrders: [],
      orderDetails: [],
      orderDetailsLoader: true,
      modeOfTransport: null,
      gateInDate: new Date(),
      paymentTerm: null,
      laDetails: [],
      allPaymentTermsList: {
        allotted_payment_method: [],
        not_allotted_payment_method: [],
      },
      allPaymentTermsListLoader: true,
      allBuyersList: [],
      allConsigneeList: [],
      allItemCategoryList: { subscribed: [], unsubscribed: [] },
      plantsList: [],
      allItemsList: [],
      tranportersList: [],
      laItemsList: [],
      laItemsListLoader: false,
      laOperation: null,
      itemCategoryData: {},
      categoryCityDiscountData: [],
      categoryPaymentMethodDiscountData: [],
      allPlantsData: [],
      allTcsRates: [],
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      anchorEl: null,
      open: null,
      selectedOrderDate: null,
      prevItemCategoryName: null,
      count: null,
      prevLink: '',
      nextLink: '',
      date: "",
      tcsRate: {},
      readyQtyModeOpen: false,
      dispatchTablePdfData: [],
      dispatchTablePrintLoader: false,
    };
  }
  cancelToken = '';
  _isMounted = false;
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };



  componentDidMount() {
    // console.log(this.props)
    this.fetchTransTypeMaster();
    // this.handleFetchTransporterData();
    if (isAccessibleModule(this.props.user, "Dispatch Planning")) {
      // isAccessibleModule(user, moduleName)

      // if (isAccessibleWidget(this.props.user, "dispatch"))
      this.handelFetchPlants(); // isAccessibleWidget(user, keyCode)
    }

  }
  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      dispatchPlaningList: [],
      tableDataByDate: [],
      originalResponseDispatchPlanningList: [],
      plantLoader: false,
      plants: [],
      selectedItemCategory: null,
      selectedPlant: null,
      transType: [],
      rowsCount: 0,
      searchValue: "",
      orderValidity: 10,
      orderValidityDate: this.generateOrderValidityDate(new Date(), 10),
      selectedCustomerPaymentTerms: [],
      buyerSelectionList: [],
      consigneeSelectionList: [],
      itemDetailsData: [],
      driverLicenceNumber: null,
      selectedOrderId: null,
      allOrders: [],
      orderDetails: [],
      orderDetailsLoader: true,
      modeOfTransport: null,
      gateInDate: new Date(),
      paymentTerm: null,
      laDetails: [],
      allPaymentTermsList: {
        allotted_payment_method: [],
        not_allotted_payment_method: [],
      },
      allPaymentTermsListLoader: true,
      allBuyersList: [],
      allConsigneeList: [],
      allItemCategoryList: { subscribed: [], unsubscribed: [] },
      plantsList: [],
      allItemsList: [],
      tranportersList: [],
      laItemsList: [],
      laItemsListLoader: false,
      laOperation: null,
      itemCategoryData: {},
      categoryCityDiscountData: [],
      categoryPaymentMethodDiscountData: [],
      allPlantsData: [],
      allTcsRates: [],
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      anchorEl: null,
      open: null,
      selectedOrderDate: null,
      prevItemCategoryName: null,
      count: null,
      prevLink: '',
      nextLink: '',
      subcontractRows: [],
      freightBasis: []
    });
  }

  handleAddButton = (orderId, laOperation) => {
    // console.log(orderId, laOperation)
    this.setState({ selectedOrderId: orderId, laOperation: laOperation },
      () => {
        this.fetchOrderData();
        this.fetchTransTypeMaster();
        this.hanldleFetchOrderPlantPlanning();
      });
    this.handleFetchTransporterData();
    this.handleFetchAllPlantData();
    this.handleFetchAllTcsData();
    this._isMounted = true;
  }

  fetchOrderData = (paramOrderId = null) => {
    let orderId = this?.state?.selectedOrderId ?? paramOrderId;
    axios
      .get(`${APIROUTES.CONTRACTS_DATA_FETCH}${orderId}/`, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          let orderData = response.data;
          this.setState(
            {
              orderDetails: orderData,
              orderDetailsLoader: false,
            },
            () => {
              this.handleFetchAllPaymentTermsList(orderData?.customer?.id);
              this.handleFetchBuyerForCustomer(orderData?.customer?.id);
              this.handleFetchConsigneeForCustomer(orderData?.customer?.id);
              this.fetchAllItemCategories(orderData?.customer?.id);
              // if (this?.state?.laOperation === "add") {\
              this.handleFetchItemCategory(orderData?.item_category?.id);
              this.handleFetchItemsForCategory(orderData?.item_category?.id);
              this.handleFetchPaymentMethodDiscountForCategory(
                orderData?.item_category?.id
              );
              this.handleFetchCityDiscountForCategory(orderData?.item_category?.id);
            });
        } else {
          this.setState({
            orderDetailsLoader: false,
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching contract data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          orderDetailsLoader: false,
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching contract data `,
          snackbarType: "error",
        });
      });
  };

  fetchTcsRate = (customerId, itemId) => {
    return axios
      .get(
        `${APIROUTES.GET_TCS_RATE}?customer_id=${customerId}&item_id=${itemId}`,
        this.getOptions(this.props.user)
      );
  };

  handleFetchTcsRateData = (customerId, itemId) => {
    this.fetchTcsRate(customerId, itemId)
      .then((response) => {
        if (response.status === 200) {
          //console.log(response);
          this.setState({
            tcsRate: response.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching tcs rate or plants",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.response?.data?.details
            ? error?.response?.data?.details
            : error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error occured while fetching tcs rate or plants",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };

  handleFetchAllPaymentTermsList = (customerId) => {
    axios.get(
      `${APIROUTES.CONTRACTS_PAYMENT_METHOD}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        // console.log("fetchCustomerPaymentTerms : ", response);
        if (response.status === 200) {
          this.setState(
            {
              allPaymentTermsList: response.data,
              allPaymentTermsListLoader: false,
            });
        } else {
          this.setState({
            allPaymentTermsListLoader: false,
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all payment terms`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleFetchBuyerForCustomer = (customerId) => {
    axios.get(
      `${APIROUTES.CONTRACTS_BUYER}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        // console.log("fetchBuyers : ", response.data.results);
        if (response.status === 200) {
          this.setState(
            {
              allBuyersList: response.data.results,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all buyers list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all buyers list`,
          snackbarType: "error",
        });
      });
  };

  fetchAllItemCategories = (customerId) => {
    axios.get(
      `${APIROUTES.CONTRACTS_ITEM_CATEGORIES}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        // console.log("fetchItemCategories : ", response.data);
        if (response.status === 200) {
          this.setState(
            {
              allItemCategoryList: response.data,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all consignee list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all consignee list`,
          snackbarType: "error",
        });
      });
  };
  handleFetchConsigneeForCustomer = (customerId) => {
    axios.get(
      `${APIROUTES.CONTRACTS_CONSIGNEE}${customerId}/`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        // console.log("fetchConsignees : ", response.data.results);
        if (response.status === 200) {
          this.setState(
            {
              allConsigneeList: response.data.results,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all consignee list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all consignee list`,
          snackbarType: "error",
        });
      });
  };
  handleFetchItemsForCategory = (itemCategoryId) => {
    console.log(itemCategoryId)
    axios.get(
      `${APIROUTES.ITEM_CATEGORY_MASTER_ITEMS}?id=${itemCategoryId}&active=${true}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        // console.log("fetchItemsData : ", response.data);
        if (response.status === 200) {
          this.setState(
            {
              allItemsList: response.data,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all consignee list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all consignee list`,
          snackbarType: "error",
        });
      });
  };
  handleFetchPaymentMethodDiscountForCategory = (itemCategoryId) => {
    axios
      .get(
        `${APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT}?id=${itemCategoryId}`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState(
            { categoryPaymentMethodDiscountData: response.data }
          );
        }
      })
      .catch((error) => {
        // console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching payment method discount",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchCityDiscountForCategory = (itemCategoryId) => {
    axios
      .get(
        `${APIROUTES.ITEM_CATEGORY_CITY_DISCOUNT}?id=${itemCategoryId}`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ categoryCityDiscountData: response.data }
          );
        }
      })
      .catch((error) => {
        // console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching city discount data",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchItemCategory = (itemCategoryId) => {
    axios
      .get(
        `${APIROUTES.MM_CREATE_NEW_CATEGORY}${itemCategoryId}/`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ itemCategoryData: response.data }
          );
        }
      })
      .catch((error) => {
        // console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching item category",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchTransporterData = () => {
    axios
      .get(`${APIROUTES.TRANSPORTERS}?active=true`, this.getOptions(this.props.user))
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ tranportersList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching transporters data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchAllPlantData = () => {
    axios
      .get(
        APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ allPlantsData: response.data });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching all plants data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchAllTcsData = () => {
    axios
      .get(APIROUTES.TCS, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          this.setState({ allTcsRates: response.data });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching all tcs rates",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  hanldleFetchOrderPlantPlanning = (paramOrderId = null) => {

    let orderId = this?.state?.selectedOrderId ?? paramOrderId;
    axios.get(
      `${APIROUTES.ORDER_PLANT_PLANNING}?order_id=${orderId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        console.log("plantsList : ", response.data);
        if (response.status === 200) {
          this.setState(
            {
              plantsList: response.data.results,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all buyers list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all buyers list`,
          snackbarType: "error",
        });
      });
  };
  fetchTransTypeMaster = () => {
    axios
      .get(APIROUTES.GET_TRANS_TYPE_MASTER, this.getOptions(this.props.user))
      .then((response) => {
        this.setState({
          transType: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handelFetchPlants = () => {
    this.setState({ plantLoader: true });
    axios
      .get(`${APIROUTES.GET_PLANT}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            plants: response.data,
            plantLoader: false,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({
          plantLoader: false,
        });
      });
  };
  handleCloseBackDrop = () => {
    this.setState({ dispatchLoader: false })
  }
  handleSearchClear = () => {
    this.setState({
      searchValue: ""
    })
  }

  handleSearchCustomerTable = (searchtext, item_category_id, plant_id, selectedDate) => {
    console.log(searchtext)
    this.setState({
      searchValue: searchtext,
    }, () => {
      searchtext !== ""
        ? this.handleSearchCustomerTextData(item_category_id, plant_id, selectedDate)
        : this.handleFetchDispatchReadyQtyDetailsWithDate(
          moment(new Date(selectedDate)).format("YYYY-MM-DD"),
          item_category_id,
          plant_id,
        )
    })
  }

  handleSearchCustomerTextData = debounce((item_category_id, plant_id, selectedDate) => {
    const actualUrl = `${APIROUTES.GET_SEARCH_DISPATCH_PLANING_CUSTOMER}search=${this.state?.searchValue}&item_category_id=${item_category_id}&plant_id=${plant_id}&date=${selectedDate}`;

    if (typeof this.cancelToken != typeof undefined) {
      this?.cancelToken && this.cancelToken.cancel("Operation canceled") ||
        // }
        // this.cancelToken = axios.CancelToken.source()
        this.setState({ dispatchLoader: true }, () =>
          axios
            .get(actualUrl, this.getOptions(this.props?.user))
            .then((response) => {
              if (response.status === 200) {
                // let data = response.data
                console.log('inside')
                this.setState({
                  dispatchPlaningList: this.state?.todaydispatchPlaningList,
                  tableDataByDate: {
                    date: new Date(),
                    orders_qty: this.state?.tableDataByDate?.orders_qty,
                    orders: response.data.results
                  },
                  dispatchLoader: false
                });
              }
            })
            .catch((error) => {
              // else {
              this.handleOpenSnackbar(
                this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
                "error"
              );
              setTimeout(() => {
                this.handleCloseSnackbar();
              }, 4000)
              // }
            })
        )
    }
  }, 500)

  handleSearchTable = (searchtext, item_category_id, plant_id, newLink = 0) => {
    console.log(searchtext)
    this.setState({
      searchValue: searchtext,
    }, () => {
      searchtext !== ""
        ? this.handleSearchTextData(item_category_id, plant_id, newLink)
        : this.handleFetchDispatchDetailsWithDate(
          moment(new Date()).format("YYYY-MM-DD"),
          item_category_id,
          plant_id,
          0
        )
    })
  }
  // let originalResponseDispatchPlanningList = this.state.originalResponseDispatchPlanningList.find(el => el.date === date)
  // const filterData = originalResponseDispatchPlanningList?.orders
  //   .filter(ele =>
  //     ele.order_number.toLowerCase().includes(value) || ele.first_name.toLowerCase().includes(value) || ele.last_name.toLowerCase().includes(value))

  // console.log(originalResponseDispatchPlanningList)
  handleSearchTextData = debounce((item_category_id, plant_id, newLink = 0) => {
    const actualUrl = `${APIROUTES.GET_SEARCH_DISPATCH_PLANING}search=${this.state?.searchValue}&item_category_id=${item_category_id}&plant_id=${plant_id}`;
    const filteredLink = newLink === 0 ? actualUrl : newLink

    axios
      .get(filteredLink, this.getOptions(this.props?.user))
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            dispatchPlaningList: this.state?.todaydispatchPlaningList,
            tableDataByDate: {
              date: new Date(),
              orders_qty: this.state?.tableDataByDate?.orders_qty,
              orders: response.data.results
            },
            count: response.data.count,
            // rowsCount: response.data.results.length,
            rowsCount: 10,
            prevLink: response.data.previous,
            nextLink: response.data.next,
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching search data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      })
  }, 500)

  // filterdata = (array, value) => {
  //   return array.filter(ele => ele.order_number.toLowerCase().includes(value) || ele.first_name.toLowerCase().includes(value) || ele.last_name.toLowerCase().includes(value))
  // }
  handleFetchDispatchDetails = (item_category_name, item_category_id, plant_id, newLink = 0) => {
    // console.log(item_category_name, item_category_id, plant_id)
    const actualUrl = `${APIROUTES.FETCH_DISPATCH_PLANING
      }item_category_id=${item_category_id}&plant_id=${plant_id}`;
    const filteredLink = newLink === 0 ? actualUrl : newLink
    let date = moment(new Date()).format("YYYY-MM-DD")
    this.setState({ dispatchLoader: true }, () => {
      axios
        .get(filteredLink, this.getOptions(this.props.user))
        .then((response) => {
          if (response.status === 200) {
            if (response.data.results[0].orders === null) {
              this.setState({
                dispatchPlaningList: response.data.results,
                tableDataByDate: [],
                count: response.data.count,
                prevLink: response.data.previous,
                nextLink: response.data.next,
                dispatchLoader: false,
              },
                () => {
                  this.props.history.push({
                    pathname: `/dispatchPlanning/${item_category_id}/${plant_id}`,
                    state: {
                      item_category_id,
                      item_category_name,
                      plant_id,
                      selectedItemCategoryName: item_category_name
                    }
                  });
                }
              )
            }
            response.data.results.map(disp => {
              if (disp.date === date) {
                this.setState({
                  rowsCount: disp.orders.length,
                  selectedItemCategory: item_category_id,
                  selectedPlant: plant_id
                })
              }
            })
            let data = response.data.results
            // data.map((el, i) =>)
            // console.log('hereeeeeee')
            this.setState(
              {
                todaydispatchPlaningList: response.data.results,
                originalResponseDispatchPlanningList: response.data.results,
                dispatchPlaningList: response.data.results,
                tableDataByDate: data.find((el, i) => el.date === date),
                count: response.data.count,
                prevLink: response.data.previous,
                nextLink: response.data.next,
                dispatchLoader: false,
              },
              () => {
                this.props.history.push({
                  pathname: `/dispatchPlanning/${item_category_id}/${plant_id}`,
                  state: {
                    item_category_id,
                    item_category_name,
                    plant_id,
                    selectedItemCategoryName: item_category_name
                  }
                });
              }
            );
          }
        })
        .catch((err) => {
          this.setState({ dispatchLoader: false });
        });
    });
  };

  fetchPendingQuantity = (orderId) => {
    return axios.get(
      `${APIROUTES.CHECK_PENDING_QUANTITY}?order_id=${orderId}`,
      this.getOptions(this.props.user)
    );
  }

  handleFetchDispatchDetailsWithDate = (date, item_category_id, plant_id, newLink = 0) => {
    // console.log(date, item_category_id, plant_id, newLink)
    if (this.state.searchValue) {
      this.handleSearchClear()
    }
    const actualUrl = `${APIROUTES.FETCH_DISPATCH_PLANING
      }item_category_id=${item_category_id}&plant_id=${plant_id}&created_at=${date}`;
    const filteredLink = newLink === 0 ? actualUrl : newLink
    this.setState({ dispatchLoader: true, rowsCount: 0 }, () => {
      axios
        .get(filteredLink, this.getOptions(this.props.user))
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data)
            response.data.results.map(disp => {
              if (disp.date === date) {
                this.setState({
                  rowsCount: disp.orders.length
                })
              }
            })
            let data = response.data.results
            this.setState({
              originalResponseDispatchPlanningList: response.data.results,
              dispatchPlaningList: response.data.results,
              tableDataByDate: data.find((el, i) => el.date === date),
              count: response.data.count,
              prevLink: response.data.previous,
              nextLink: response.data.next,
              dispatchLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ dispatchLoader: false });
        });
    });
  };
  handleFetchDispatchReadyQtyDetailsWithDate = (date, item_category_id, plant_id) => {
    console.log("INSIDE API")
    if (typeof this.cancelToken != typeof undefined) {
      this?.cancelToken && this.cancelToken.cancel("Operation canceled")
    }
    this.cancelToken = axios.CancelToken.source()
    if (this.state.searchValue) {
      this.handleSearchClear()
    }
    this.handleClearState();
    this.setState({ dispatchLoader: true, rowsCount: 0 }, () => {
      axios
        .get(
          `${APIROUTES.FETCH_DISPATCH_PLANING_READY_QTY
          }item_category_id=${item_category_id}&plant_id=${plant_id}&created_at=${date}`,
          { cancelToken: this.cancelToken.token },
          // {
          //   headers: {
          //     Authorization: `JWT ${this.props.user.token}`,
          //     "Content-Type": "application/json;charset=UTF-8",
          //   },
          //   onDownloadProgress: function ({ loaded, total, download = true }) {
          //     console.log(loaded, total)
          //     let percentCompleted = Math.floor(loaded / total * 100)
          //     console.log(percentCompleted + "%")
          //   },
          //   cancelToken: this.cancelToken.token
          // })
          this.getOptions(this.props.user))
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data)
            if (response.data[0].orders === null) {
              this.setState({
                todaydispatchPlaningList: new Date(date) == new Date()
                  ? response.data
                  : this.state.todaydispatchPlaningList,
                originalResponseDispatchPlanningList: response.data,
                dispatchPlaningList: response.data,
                tableDataByDate: [],
                dispatchLoader: false,
              })
            }
            response.data.map(disp => {
              if (disp.date === date) {
                this.setState({
                  rowsCount: disp.orders !== null && disp.orders.length
                })
              }
            })
            let data = response.data
            this.setState({
              todaydispatchPlaningList: new Date(date) == new Date()
                ? response.data
                : this.state.todaydispatchPlaningList,
              originalResponseDispatchPlanningList: response.data,
              dispatchPlaningList: response.data,
              tableDataByDate: data.find((el, i) => el.date === date),
              dispatchLoader: false,
            });
          }
        })
        .catch((error) => {
          console.log(error, 'hertt')
          if (JSON.stringify(error) === '{"message":"Operation canceled"}') {
            this.setState({ dispatchLoader: true, })
          }
          else {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.setState({ dispatchLoader: false });
              this.handleCloseSnackbar();
            }, 4000)
          }
        })
    })
  }

  handleCreateReadyQty = (data) => {
    return axios.post(APIROUTES.CREATE_DISPATCH_PLANING_READY_QTY,
      data,
      this.getOptions(this.props.user)
    )
  }

  handleUpdateReadyQty = (data, customerid) => {
    return axios.patch(
      `${APIROUTES.UPDATE_DISPATCH_PLANING_READY_QTY}${customerid}/`,
      data,
      this.getOptions(this.props.user)
    )
  }

  handleUpdateLaApproval = (status, laId) =>
    new Promise((resolve, reject) => {
      let data = {
        dispatch_status: status,
        status_remark: "",
      };
      axios
        .put(
          `${APIROUTES.UPDATE_LA_STATUS_IN_DISPATCH_PLANING + laId + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });
  handleClearState = () => {
    this.setState({
      originalResponseDispatchPlanningList: [],
      dispatchPlaningList: [],
      tableDataByDate: [],
      dispatchLoader: false,
    });
  };

  setRowsCount = (count) => {
    this.setState({
      rowsCount: count
    })
  }

  handleTableData = (date) => {
    let dispatchPlaningList = this.state.dispatchPlaningList.find(el => el.date === date)
    // console.log(dispatchPlaningList)
    this.setState({
      tableDataByDate: dispatchPlaningList
    })
  }

  generateOrderValidityDate = (dateObj, numberOfDaysToAdd) => {
    // console.log("TO" + Number(numberOfDaysToAdd))
    dateObj.setDate(dateObj.getDate() + Number(numberOfDaysToAdd));
    return dateObj;
  };

  handleChange = (name) => (event) => {
    const { value, checked } = event.target;
    this.setState({ [name]: value });
    if (name === "orderValidity") {
      let toDayDate = new Date();
      this.setState(
        {
          orderValidityDate: this.generateOrderValidityDate(
            toDayDate,
            !value ? 0 : value
          ),
        }
      );
    } else if (name === "order") {
      if (this?.state?.itemDetailsData.length < 1) {
        this.addNewItemDetailsData();
      }
    } else {
      console.log("invalid operation");
    }
  };
  addNewItemDetailsData = () => {
    this.setState((previousState) => {
      return {
        itemDetailsData: [
          ...previousState.itemDetailsData,
          {
            item: "",
            quantity: "",
            operation: "add",
          },
        ],
      };
    });
  };
  handleDateChange = (date) => {
    if (date !== "Invalid Date") {
      this.setState({ gateInDate: date });
    } else {
      this.setState({ gateInDate: null });
    }
  };
  removeItemDetailsData = (itemDataIndex) => {
    this.setState((previousState) => {
      const itemDetailsData = [...previousState.itemDetailsData];
      itemDetailsData.splice(itemDataIndex, 1);
      return { itemDetailsData };
    });
  };

  handleSelectedOrderDate = (date) => {
    // console.log(date)
    this.setState({ selectedOrderDate: date })
  }

  handleItemCategoryName = (data) => {
    console.log(data)
    this.setState({ prevItemCategoryName: data })
  }

  handleLaDataSaveEdit = (laDetails) => {

    let laOperation = this?.state?.laOperation;
    let itemId = this.props.location?.state?.itemId;
    let plantId = this.props.location?.state?.plantId;
    let selectedOrderDate = this.state?.selectedOrderDate;
    let todayDate = moment(new Date()).format("YYYY-MM-DD")
    // console.log(selectedOrderDate)
    // console.log("laOperation: ", laOperation);
    // console.log("laDetails : ", laDetails);
    if (laOperation === "add") {
      axios
        .post(APIROUTES.LOADING_ADVICE_CREATE,
          laDetails,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          // console.log("createLa : ", response);
          if (response.status === 201) {
            this.props.history.push(
              {
                pathname: `/dispatchPlanning/${this.props.location?.state?.itemId}/${this.props.location?.state?.plantId}`,
                state: {
                  itemCategoryName: this.state?.prevItemCategoryName
                }
              }
            );
            this.handleFetchDispatchDetailsWithDate(
              todayDate,
              itemId,
              plantId
            )
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : `Error occured while fetching all buyers list`,
              snackbarType: "error",
            });
          }
        })
        .catch((error) => {
          // console.log(error?.response?.data)
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 5000);
          // this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: error?.data?.details
          //     ? error?.data?.details
          //     : error?.data?.message
          //       ? error?.data?.message
          //       : `Error occured while fetching all buyers list`,
          //   snackbarType: "error",
          // })
        });
    } else if (laOperation === "edit") {
      if (laDetails.items.length > 0) {
        // laDetails.items.map((itemData) => {
        //   if (!itemData.id) {
        //     // this.handleCreateItemData(itemData);
        //   } else {
        //     let dataTobeUpdated = {
        //       loading_advice_item_id: itemData.id,
        //       item: itemData.item,
        //       item_qty: itemData.item_qty,
        //     };
        //     this.handleUpdateItemData(dataTobeUpdated);
        //   }
        // });
        this.props
          .updateLa(laDetails, this.state.selectedLaId)
          .then((response) => {
            // console.log("fetchBuyers : ", response.data.results);
            if (response.status === 200) {
              // console.log(this.props)
              this.props.history.push(
                `/internalLoadingAdvices/${this.props.plantId}`
              );
              this.props.handleDateChangeLA(this.props.date)
            } else {
              this.setState({
                openSnackbar: true,
                snackbarMessage: response?.data?.details
                  ? response?.data?.details
                  : response?.data?.message
                    ? response?.data?.message
                    : `Error occured while fetching all buyers list`,
                snackbarType: "error",
              });
            }
          })
          .catch((error) => {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 5000);
            // this.setState({
            //   openSnackbar: true,
            //   snackbarMessage: error?.data?.details
            //     ? error?.data?.details
            //     : error?.data?.message
            //       ? error?.data?.message
            //       : `Error occured while fetching all buyers list`,
            //   snackbarType: "error",
            // });
          });
      } else {
        console.log("items data in la is ");
      }
    } else {
      console.log("Invalid La operation for data handelling");
    }
  };

  fetchOrders = async (itemId, customerId, plantId = 0) => {
    // console.log("ID" + itemId + " : " + customerId)
    console.log("plantId - ", plantId)
    this.setState({
      itemDataFetchLoader: true,
    });
    const baseURL = `${APIROUTES.CONTRACTS_DROPDOWN_LIST}`;
    const params = plantId === 0
      ? `?item_category=${itemId}&customer=${customerId}`
      : `?item_category=${itemId}&customer=${customerId}&plant=${plantId}`
    const finalURL = baseURL + params

    await axios.get(finalURL, this.getOptions(this.props.user))
      .then((response) => {
        const { data } = response;
        let tempraryItemCategoryData = data.map(
          (orders) => orders.item_category
        );
        const seen = new Set();
        const itemCategoryTabData = tempraryItemCategoryData.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });
        this.setState({
          allOrders: data,
          itemCategoryTabData,
          itemDataFetchLoader: false,
        });
      })
      .catch((error) => {
        this.setState({
          itemDataFetchLoader: false,
        });
      })
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleActionMenuClose = () => {
    this.setState({ anchorEl: null }, () => this.setState({ open: Boolean(this.state.anchorEl) }))
  };

  fetchPlantsForItemCategory = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.PLANTS_FOR_ITEM_CATEGORY}${itemCategoryId}/`,
      this.getOptions(this.props.user)
    );
  };


  deleteItemsData = (itemId) => {
    return axios.delete(
      `${APIROUTES.DELETE_LOADING_ADVICE_ITEM}${itemId}/`,
      this.getOptions(this.props.user)
    );
  };

  handleLaApprove = (laId, laUpdateData, date, plantId, laCancelReason) => {
    // console.log(date)
    // console.log(plantId)
    // let updatedData = { is_approved: true };
    let itemId = this.props.location?.state?.itemId;
    let localPlantId = this.props.location?.state?.plantId;
    let todayDate = moment(new Date()).format("YYYY-MM-DD")
    if (laUpdateData?.dispatch_status != "APPROVED") {
      laUpdateData = { ...laUpdateData, cancel_reason: laCancelReason }
    }
    axios.patch(
      `${APIROUTES.LOADING_ADVICE_APPROVE}${laId}/`,
      laUpdateData,
      this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          // this.handleFetchDispatchDetailsWithDate(
          //   this.state.newDate, this.state.plantID
          // );
          this.setState({
            openSnackbar: true,
            snackbarMessage: `LA ${response?.data?.dispatch_status} successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => this.handleFetchDispatchDetailsWithDate(
                todayDate, itemId, plantId ?? localPlantId
              ), 3000)
            });
        }
        else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while approving LA try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        this.setState({ dispatchLoader: false });
      });
  };

  updateLa = (laData, laId) => {
    return axios.put(
      `${APIROUTES.LOADING_ADVICE_UPDATE}${laId}/`,
      laData,
      this.getOptions(this.props.user)
    );
  };

  createLa = (laData) => {
    return axios.post(
      APIROUTES.LOADING_ADVICE_CREATE,
      laData,
      this.getOptions(this.props.user)
    );
  };

  dispatchTablePdf = (itemID, plantID, selectedDate, handleOpenSnackClick, headerData) => {
    this.setState({ dispatchTablePrintLoader: true })
    axios.get(
      `${APIROUTES.DISPATCH_TABLE_PDF}?item_category_id=${itemID}&plant_id=${plantID}&date=${selectedDate}`,
      this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            dispatchTablePdfData: response.data,
            dispatchTablePrintLoader: false
          }, () => {
            handlePrintPlantDetails(this.state.dispatchTablePdfData, headerData, handleOpenSnackClick, "PRINT DISPATCH")
          });
        }
      })
      .catch((error) => {
        this.setState({ dispatchTablePrintLoader: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleDateChangeLA = (date) => {
    // console.log("here STATE CHANGE" + date)
    this.setState({
      date: date
    });
  }
  handlePrintReload = (data) => {
    this.setState({ readyQtyModeOpen: data }, () => console.log(this.state?.readyQtyModeOpen))
  }

  handleFetchSubcontracts = (order_id) => {
    axios.get(
      `${APIROUTES.GET_SUBCONTRACTS}?order_id=${order_id}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data
          const result = []
          for (const k of Object.keys(responseData)) {
            if (response.data[k]?.length > 0) {
              const _data = responseData[k]?.filter((_, i) => i != 0)
              _data.forEach((i) => result.push(i))
            }
          }
          console.log(result)
          this.setState({
            subcontractRows: result
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching subcontracts",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : error?.details
                ? error?.details
                : "Error occured while fetching subcontracts",
          snackbarType: "error",
        });
      });
  };

  handleFetchItemsWithTagsForCategory = (tagId, categoryId) => {
    axios.get(
      `${APIROUTES.GET_ITEMS_PER_TAGS}?tag=${tagId}&category_id=${categoryId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allItemsListWithTag: response.data
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all items list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all items list`,
          snackbarType: "error",
        });
      });
  };


  handleGetSubcontractItemsForCategory = (subcontractId) => {
    axios.get(
      `${APIROUTES.GET_SUB_CONTRACT_ITEMS}?id=${subcontractId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allSubcontractItemList: response.data
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all subcontract items list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all subcontract item list`,
          snackbarType: "error",
        });
      });
  };

  handleFetchFreightBasis = () => {
    axios.get(
      `${APIROUTES.FREIGHT_BASIS}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            freightBasis: response.data
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching freight basis`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching freight basis`,
          snackbarType: "error",
        });
      });
  };

  render() {
    const { isMobile } = this.props;
    // console.log(this.state)
    // console.log(sessionStorage)
    // console.log(this.props)
    // { console.log("HH  ", this.props.itemId) }
    // console.log(this.state?.originalResponseDispatchPlanningList[3]?.orders?.length)
    return (
      <>
        <Switch>
          <Route
            exact
            path="/dispatchPlanning/createLoadingAdviceFromOrder/:orderId"
            render={(props) =>
            // isMobile ? (
            //   <DispatchPlanning {...this.state} {...props} {...this.props} />
            // ) : 
            (
              <CreateLoadingAdvice
                {...this.state}
                {...this.props}
                isMobile={isMobile}
                generateOrderValidityDate={this.generateOrderValidityDate}
                handleChange={this.handleChange}
                handleDateChange={this.handleDateChange}
                addNewItemDetailsData={this.addNewItemDetailsData}
                removeItemDetailsData={this.removeItemDetailsData}
                handleLaDataSaveEdit={this.handleLaDataSaveEdit}
                fetchItemCategoryPaymentTermDiscount={
                  this.fetchItemCategoryPaymentTermDiscount
                }
                fetchItemCategoryPincodeDiscount={this.fetchItemCategoryPincodeDiscount}
                fetchAllOrders={this.fetchOrders}
                handleActionMenuClose={this.handleActionMenuClose}
                laStatus={this.props.status}
                laQty={this.props.laQty}
                plantId={this.props.location?.state?.plantId}
                plants={this.props.location?.state?.plants}
                plantsName={this.props.location?.state?.plantsName}
                itemCategoryName={this.props.location?.state?.itemCategoryName}
                itemId={this.props.location?.state?.itemId}
                customerId={this.props.location?.state?.customerId}
                pendingQuantity={this.props.location?.state?.pendingQuantity}
                laId={this.props.laId}
                itemTotalQuantity={this.props.itemTotalQuantity}
                orderNo={this.props.orderNo}
                paymentMethodId={this.props.paymentMethodId}
                buyerPinCode={this.props.buyerPinCode}
                fetchPendingQuantity={this.props.fetchPendingQuantity}
                prevSelectedDate={this.props.location?.state?.prevSelectedDate}
                handleItemCategoryName={this.handleItemCategoryName}
                handleFetchTcsRateData={this.handleFetchTcsRateData}
                fetchTcsRate={this.fetchTcsRate}
                handleFetchSubcontracts={this.handleFetchSubcontracts}
                handleFetchItemsWithTagsForCategory={this.handleFetchItemsWithTagsForCategory}
                handleGetSubcontractItemsForCategory={this.handleGetSubcontractItemsForCategory}
                handleFetchFreightBasis={this.handleFetchFreightBasis}
              />
            )
            }
          />
          <Route
            exact
            path="/dispatchPlanning/:itemId/:plantId"
            render={(props) =>
            // isMobile ? (
            //   <DispatchPlanning {...this.state} {...props} {...this.props} />
            // ) : 
            (
              <DispatchPlanningDetailsWeb
                {...this.state}
                {...props}
                {...this.props}
                isMobile={isMobile}
                handleFetchDispatchDetails={this.handleFetchDispatchDetails}
                handleClearState={this.handleClearState}
                handleFetchDispatchDetailsWithDate={
                  this.handleFetchDispatchDetailsWithDate
                }
                handleFetchDispatchReadyQtyDetailsWithDate={
                  this.handleFetchDispatchReadyQtyDetailsWithDate
                }
                handelFetchPlants={this.handelFetchPlants}
                handleCreateReadyQty={this.handleCreateReadyQty}
                handleUpdateReadyQty={this.handleUpdateReadyQty}
                handleSearchTable={this.handleSearchTable}
                handleSearchCustomerTable={this.handleSearchCustomerTable}
                handleSearchTextData={this.handleSearchTextData}
                handleSearchClear={this.handleSearchClear}
                handleUpdateLaApproval={this.handleUpdateLaApproval}
                setRowsCount={this.setRowsCount}
                handleTableData={this.handleTableData}
                handleAddButton={this.handleAddButton}
                handleSelectedOrderDate={this.handleSelectedOrderDate}
                anchorElUpdated={this.state.anchorEl}
                openUpdated={this.state.open}
                itemCategoryName={this.props?.location?.state?.selectedItemCategoryName || this.props?.location?.state?.itemCategoryName}
                prevSelectedDate={this.props.location?.state?.prevSelectedDate}
                prevItemCategoryName={this.props.location?.state?.prevItemCategoryName}
                fetchPendingQuantity={this.fetchPendingQuantity}
                handleActionMenuClose={this.handleActionMenuClose}
                handlePrintReload={this.handlePrintReload}
                dispatchTablePdf={this.dispatchTablePdf}
                dispatchTablePdfData={this.state.dispatchTablePdfData}
                dispatchTablePrintLoader={this.state.dispatchTablePrintLoader}
              />
            )
            }
          />
          <Route
            exact
            path="/dispatchPlanning"
            render={(props) =>
            // isMobile ? (
            //   <DispatchPlanning {...this.state} {...props} {...this.props} />
            // ) : 
            (
              <DispatchPlanningWeb
                {...this.state}
                {...props}
                {...this.props}
                isMobile={isMobile}
                handleFetchDispatchDetails={this.handleFetchDispatchDetails}
                handleCloseBackDrop={this.handleCloseBackDrop}
                fetchPendingQuantity={this.fetchPendingQuantity}
              />
            )
            }
          />
          <Route
            exact
            path="/dispatchPlanning/order/:orderId/editLoadingAdvice/:laId"
            render={(props) =>
            // isMobile ? (
            //   <OpenInDesktop {...this.props} {...this.states} />
            // ) : 
            (
              <LoadingAdvicesContainer
                {...props}
                user={this.props.user}
                {...this.state}
                isMobile={isMobile}
                fetchAllOrders={this.fetchOrders}
                fetchOrderData={this.fetchOrderData}
                fetchCustomerPaymentTerms={this.handleFetchAllPaymentTermsList}
                fetchBuyers={this.handleFetchBuyerForCustomer}
                fetchConsignees={this.handleFetchConsigneeForCustomer}
                fetchItemCategories={this.fetchAllItemCategories}
                fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
                fetchOrderPlantPlanning={this.hanldleFetchOrderPlantPlanning}
                updateLa={this.updateLa}
                createLa={this.createLa}
                fetchItemsData={this.handleFetchItemsForCategory}
                itemDataFetchLoader={this.state.itemDataFetchLoader}
                plants={this.props.location?.state?.plants}
                plantId={this.props.location?.state?.plantId}
                date={this.props.location?.state?.date}
                status={this.props.location?.state?.status}
                laQty={this.props.location?.state?.laQty}
                itemId={this.props.location?.state?.itemId}
                customerId={this.props.location?.state?.customerId}
                laId={this.props.location?.state?.laId}
                itemTotalQuantity={this.props.location?.state?.itemTotalQuantity}
                orderNo={this.props.location?.state?.orderNo}
                paymentMethodId={this.props.paymentMethodId}
                buyerPinCode={this.props.location?.state?.buyerPinCode}
                plantsName={this.props.location?.state?.plantsName}
                orderStatus={this.props.location?.state?.orderStatus}
                handleFetchDispatchDetailsWithDate={
                  this.handleFetchDispatchDetailsWithDate
                }
                handleFetchDispatchReadyQtyDetailsWithDate={
                  this.handleFetchDispatchReadyQtyDetailsWithDate
                }
                handleCreateReadyQty={this.handleCreateReadyQty}
                handleUpdateReadyQty={this.handleUpdateReadyQty}
                handleDateChangeLA={this.handleDateChangeLA}
                deleteItemsData={this.deleteItemsData}
                fetchPendingQuantity={this.fetchPendingQuantity}
                handleLaApprove={this.handleLaApprove}
                handleActionMenuClose={this.handleActionMenuClose}
                handleItemCategoryName={this.handleItemCategoryName}
                handleFetchTcsRateData={this.handleFetchTcsRateData}
                fetchTcsRate={this.fetchTcsRate}
              />
            )
            }
          />
        </Switch>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
        />
      </>
    );
  }
}
export default DispatchPlanningContainer;
