import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import PlantCard from "./PlantCard";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
} from "../../../styles/colors";
import moment from "moment";
import CreateDispatchSelectOrder from "./CreateDispatchSelectOrder";
import {
  Typography,
  TextField,
  MenuItem,
  Divider,
  Paper,
  FormControl,
  Select,
  InputAdornment,
  InputLabel,
  IconButton,
  Button,
  ListSubheader,
  Grow,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";
import CreateDispatchOrderSelect from "./CreateDispatchOrderSelect";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { drawerBackground, earthBlue, secondaryBlueText, secondaryGrey } from "../../../constants/internal/colors";
import { pxToEm } from "../../../methods";
import CreateDispatchSubContract from "./CreateDispatchSubContract";

const styles = {
  createDispatchGateInContainer: {
    width: "100%",
    height: window.innerHeight - 126,
    maxHeight: window.innerHeight - 126,
    padding: "0px 20px",
    overflowY: "scroll",
  },
  dateShowContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  },
  gateInTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.19em",
    fontWeight: 500,
    marginTop: 15,
  },
  textFieldStyle: {
    paddingBottom: "20px",
    width: "98%",
  },
  cardContainer: {
    paddingTop: "10px",
  },
  formControl: {
    margin: "0px 0px 6px 0px",
    width: "100%",
  },
  overviewValueContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: bottomNavigationFontColor,
    marginTop: "10px",
    padding: "0px 5px",
  },
  messageShow: {
    width: "100%",
    paddingTop: "10px",
    paddingLeft: "15px",
    fontSize: "1.1em",
    color: bottomNavigationFontColor,
  }
};

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});
class CreateDispatchGateIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBuyerListPopper: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.openBuyerListPopper) {
      this.setState({ openBuyerListPopper: false });
    }
  }
  handleClosePopper = (e) => {
    this.setState({ openBuyerListPopper: false });
  };
  handleOpenListPopper = (e) => {
    // e.stopPropagation();
    this.setState({ openBuyerListPopper: true });
  };

  componentWillMount() {
    let max = moment(new Date()).add(7, "days").toDate();
    this.setState({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(max).format("YYYY-MM-DD"),
    });
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.gateInDate !== this.props.gateInDate || this.props?.laState.mode === 'edit') {
  //     console.log("prevProps", prevProps.gateInDate, this.props.gateInDate)
  //   }
  // }

  componentDidMount() {
    // console.log(String(localStorage.getItem("prevUrl")))
    if (![NaN, null, undefined, 'null', 'NaN', 'undefined'].includes(String(localStorage.getItem("prevUrl")))) {
      let Url = localStorage.getItem("prevUrl")
      localStorage.setItem("prevUrl", 'NaN')
      this.props.history.push(String(Url))
    }
  }
  render() {
    const { classes,
      handleTextChange,
      handleDateChange,
      gateInDate,
      pendingCategories,
      showKeys,
      items,
      orders,
      truckCapacity,
      setOrderId,
      setItem,
      setItemQuantity,
      setOrderAndItem,
      selectedItemCategory,
      selectedOrderItemCategory,
      plants,
      selectedPlantPendingQuantity,
      plantPlanningLoader,
      selectedOrder,
      transType,
      selectedTransType,
      user,
      createLAAllowed,
      showLACreateError,
      createLAType,
      isMobile,
      fetchItemCategoriesPaymentDiscount,
      subContractDropdownList,
      ordersListLoader,
      selectedEditSubContractNumber,
      subContractNumber,
    } = this.props;
    const { openBuyerListPopper } = this.state
    // console.log(this.props.createLAType)
    return (
      <div className={classes.createDispatchGateInContainer}
      // onClick={openBuyerListPopper && this.handleClosePopper}
      >
        <div className={classes.dateShowContainer} >
          <span className={classes.gateInTextStyle}>Gate In Date</span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            The date at which your truck will be reach at the point
          </span>
          <Fragment>
            <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.textField}
                  name="gateInDate"
                  id="date"
                  style={{
                    width: '100%',
                    fontSize: window.innerWidth < 375 && pxToEm(14),
                    fontFamily: "Poppins",
                  }}
                  inputVariant="outlined"
                  label="Gate in date *"
                  format="dd/MM/yyyy"
                  minDate={new Date(this.state.startDate)}
                  maxDate={new Date(this.state.endDate)}
                  margin="normal"
                  // defaultValue={this.props?.laState.mode === 'edit'
                  //   ? this.props?.laState?.laDetails?.gate_in_date
                  //   : gateInDate}
                  value={gateInDate}
                  onChange={handleDateChange}
                  placeholder="e.g.: DD/MM/YYYY"
                  InputAdornmentProps={{ position: "end" }}
                  InputProps={{
                    className: classes.datePicker,
                    readOnly: true,
                    style: {
                      fontSize: window.innerWidth < 375 && pxToEm(15.5),
                      fontFamily: "Poppins",
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </Fragment>

          <span className={classes.gateInTextStyle}>
            Select Contract*
          </span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em", marginBottom: "20px" }}>
            Select the Contract for which the loading advice to be created
          </span>
          <CreateDispatchSelectOrder
            location={this.props.location}
            isMobile={isMobile}
            items={items}
            orders={orders}
            ordersListLoader={ordersListLoader}
            handleTextChange={this.props.handleTextChange}
            setItemQuantity={setItemQuantity}
            setItem={setItem}
            setOrderId={setOrderId}
            // handleSaveOrderData={this.handleSaveOrderData}
            // setOrderIdAndAmount={this.setOrderIdAndAmount}
            // handleSetOrderItem={this.handleSetOrderItem}
            handleClosePopper={this.handleClosePopper}
            handleOpenListPopper={this.handleOpenListPopper}
            openBuyerListPopper={openBuyerListPopper}
            truckCapacity={truckCapacity}
            handleOrderSelect={this.props.handleOrderSelect}
            setOrderIdAndAmount={this.props.setOrderIdAndAmount}
            handleSaveOrderData={this.props.handleSaveOrderData}
            handleSetOrderItem={this.props.handleSetOrderItem}
            setOrderAndItem={setOrderAndItem}
            fetchPlantsList={this.props.fetchPlantsList}
            selectedItemCategory={selectedItemCategory}
            createLAType={createLAType}
            selectedOrder={selectedOrder}
            fetchItemCategoriesPaymentDiscount={fetchItemCategoriesPaymentDiscount}
            subContractNumber={subContractNumber}
          />

        </div>

        <div style={{ marginTop: 10, marginBottom: 15 }}>
          <div className={classes.cardContainer}>
            <PlantCard
              plantsList={this.props.plants}
              selectedPlantAndItem={this.props.selectedPlantAndItem}
              selectedPlantPendingQuantity={selectedPlantPendingQuantity}
              pendingCategories={pendingCategories}
              showKeys={showKeys}
              selectedOrderItemCategory={selectedOrderItemCategory}
              plantPlanningLoader={plantPlanningLoader}
              createLAAllowed={createLAAllowed}
              showLACreateError={showLACreateError}
              laState={this.props?.laState}
            />
          </div>
        </div>

        {selectedPlantPendingQuantity && this.props?.plants && (this.props?.plantSubContractEnabled) && (this.props?.laState?.mode === 'edit' ? this.props?.laState?.laDetails?.created_from_subcontract : true)
          ?
          <div style={{ marginTop: 10, marginBottom: 15 }}>
            <div style={{ paddingBottom: '40%' }}>
              {this.props.subContractDropdownListLoader
                ? <span> Loading...</span>
                : this.props.subContractDropdownList && this.props.subContractDropdownList?.length > 0
                  ?
                  <div className={classes.dateShowContainer}>
                    <span className={classes.gateInTextStyle}>
                      Select Sub-Contract*
                    </span>
                    <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em", marginBottom: "20px" }}>
                      Select the sub-contract for which the loading advice to be created
                    </span>
                    <CreateDispatchSubContract
                      location={this.props.location}
                      isMobile={isMobile}
                      orders={subContractDropdownList}
                      handleSubcontractFormDetail={this.props.handleSubcontractFormDetail}
                      laState={this.props?.laState}
                      selectedEditSubContractNumber={selectedEditSubContractNumber}
                      subContractNumber={subContractNumber}
                    />
                  </div>
                  :
                  <div style={styles.messageShow}>
                    LA cannot be created without a subcontract for this plant
                  </div>
              }
            </div>
          </div>
          :
          null}
      </div>

    );
  }
}
export default withStyles(styles)(CreateDispatchGateIn);
