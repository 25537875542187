import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Switch,
  withStyles,
} from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
} from "../../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { checkApiError, hasUpdateAccess, pxToEm } from "../../../../../methods";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
// import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarComponent from "../../../common/SnackbarComponent";
import Bread from "../../../common/Breadcrubs";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import RemoveConfirmationDialog from "../../../common/RemoveConfirmationDialog";
import SaveButton from "../../../common/SaveButton";
import ToolTipComponent from "../../../common/ToolTipComponent";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  formControlPanImage: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  loaderStyle: { color: primaryColor },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  }
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


export default function ManageTagsMaster(props) {
  const classes = useStyles();
  const [tagName, setTagName] = React.useState(
    props?.selectedTagData?.tag_name || ""
  );
  const [tagActive, setTagActive] = React.useState(
    props?.selectedTagData?.is_active || true
  );
  const [tagOperation, setTagMasterOperation] = React.useState(
    props?.tagOperation || "add"
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = React.useState(false);
  const [tagNameError, setTagNameError] = React.useState(false);
  const [removeTagLoader, setRemoveTagLoader] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [removeConfirmationDialogData, setRemoveConfirmationDialogData] =
    React.useState({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });
  React.useEffect(() => {
    setTagName(props?.selectedTagData.tag_name);
    setTagActive(props?.selectedTagData?.is_active)
  }, [props?.selectedTagData]);

  React.useEffect(() => {
    if (props?.tagOperation === "view") {
      !checkAlphanumeric(props?.selectedTagData.tag_name) ?
        setTagNameError(true) : setTagNameError(false)
    }
  }, [props?.selectedTagData])

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const checkAlphanumeric = (input) => {
    const regex = /^(?=.*[0-9])(?=[a-zA-Z]+)[a-zA-Z0-9]+|([a-zA-Z]+)$/;
    if (regex.test(input)) {
      return true;
    }
    return false;
  }

  const handleChange = (event) => {
    if (event.target.name === 'is_active') {
      setTagActive(event.target.checked)
    }
    else if (event.target.name === 'tagName') {
      setTagName(event.target.value);
    }
    if (event.target.name === 'tagName') {
      if (!event.target.value) {
        setTagNameError(true);
      } else {
        !checkAlphanumeric(event.target.value) ?
          setTagNameError(true) : setTagNameError(false)
      }
    }
  };

  const handleCancelButtonClick = () => {
    // if (tagOperation === "edit") {
    //   setTagMasterOperation("view");
    // } else {
    //   props.history.goBack();
    // }
    props.history.push(INTERNALROUTES.LIST_TAGS_MASTER);
  };
  const handleEditSaveButtonClick = () => {
    if (tagOperation === "view") {
      setTagMasterOperation("edit");
    } else if (tagOperation === "edit") {
      let tagMasterUpdateData = {
        tag_name: tagName,
        is_active: tagActive,
      };
      setIsLoading(true)
      props
        .handleTagMasterDataUpdate(
          props.selectedTagData.id,
          tagMasterUpdateData
        )
        .then((response) => {
          if (response.status === 200) {
            // setTagMasterOperation("view");
            handleOpenSnackbar(
              `Tag Master Data Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.goBack();
              handleCloseSnackbar()
            }, 4000);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating Tag master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (tagOperation === "add") {
      let tagMasterAddData = {
        tag_name: tagName,
        is_active: tagActive,
      };
      setIsLoading(true)
      props
        .handleTagMasterDataAdd(tagMasterAddData)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            handleOpenSnackbar(
              `Tag Master Created Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.push(`${INTERNALROUTES.LIST_TAGS_MASTER}`);
              handleCloseSnackbar()
            }, 4000);
            // props.history.push({
            //   pathname: `${INTERNALROUTES.LIST_TAGS_MASTER}`,
            //   openSnackbar: true,
            //   snackbarMessage: "Tag created successfully",
            //   snackbarType: "success",
            // });
            //props.history.goBack();
            // setTagMasterOperation("view");
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while creating tag please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };
  const handleRemoveTag = () => {
    setRemoveConfirmationDialogData({
      ...removeConfirmationDialogData,
      openDialog: true,
      dialogTitle: `Remove ${tagName} ?`,
      dialogContent: `Are you sure you want to delete tag ${tagName}`,
    });
  };
  const handleRemoveConfirmationDialogClose = () => {
    setRemoveConfirmationDialogData({
      ...removeConfirmationDialogData,
      openDialog: false,
      dialogTitle: "",
      dialogContent: "",
    });
  };
  const handleConfirmationDialogDeleteClick = () => {
    setIsLoadingRemove(true)
    handleRemoveConfirmationDialogClose()
    setRemoveTagLoader(true);
    props
      .handleRemoveTag(props.selectedTagData.id)
      .then((response) => {
        if (response.status === 204) {
          handleOpenSnackbar(
            `Tag Master Removed Successfully`,
            "success"
          );
          setTimeout(() => {
            setIsLoadingRemove(false)
            setRemoveTagLoader(false);
            props.history.goBack();
            handleCloseSnackbar()
          }, 4000);
        }
        else if (response['response']['status'] === 500) {
          setIsLoadingRemove(false)
          handleOpenSnackbar(
            `This Tag Master cannot be Deleted Because it is referenced to Other Data`,
            "error"
          );
          setTimeout(() => {
            setRemoveTagLoader(false);
            handleCloseSnackbar()
          }, 8000);
        }
        else {
          setIsLoadingRemove(false)
          handleOpenSnackbar(
            `Error occured while removing tag please try again`,
            "error"
          );
          setTimeout(() => {
            setRemoveTagLoader(false);
            handleCloseSnackbar()
          }, 4000);
        }
      })
      .catch((error) => {
        setIsLoadingRemove(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          setRemoveTagLoader(false);
          handleCloseSnackbar();
        }, 4000);
      });
  };

  return (
    <Layout {...props}>
      {props.selectedTagDataFetchLoader ? (
        <CircularProgress disableShrink className={classes.loaderStyle} />
      ) : (
        <>
          <div className={classes.mainDiv}>
            <div className={classes.rightPadding}>
              <Bread
                data={[
                  {
                    title: "Master Management",
                    onClick: () =>
                      props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
                  },
                  {
                    title: "Item Tags",
                    onClick: () => props.history.goBack(),
                  },
                  {
                    title: tagOperation === "add" ? "Create New" : tagName,
                    onClick: "",
                  },
                ]}
              />
              <div className={classes.topMargin}>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>
                    TAG NAME
                  </Typography>
                  {tagOperation === "view" ? (
                    <Typography className={classes.titleLabel}>
                      {tagName}
                    </Typography>
                  ) : (
                    <TextField
                      label="TAG NAME"
                      name="tagName"
                      type="text"
                      onChange={handleChange}
                      placeholder="Enter tag name"
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={tagName}
                      error={tagNameError}
                      helperText={tagNameError ? "Invalid name" : ""}
                    />
                  )}
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>
                    ACTIVE
                  </Typography>
                  <div className={classes.isActive}>
                    <IOSSwitch
                      disabled={tagOperation === "view"}
                      checked={(tagActive) === undefined ? true : Boolean(tagActive)}
                      onChange={handleChange}
                      name="is_active"
                    />
                  </div>
                </div>
                <ToolTipComponent
                  title="You have no edit permission on this widget"
                  condition={tagOperation === "view" && !hasUpdateAccess(props.user, 'tags_master')}
                >
                  <SaveButton
                    size="medium"
                    disabled={
                      tagOperation === "view" ?
                        !hasUpdateAccess(props.user, 'tags_master') || isLoadingRemove || false
                        :
                        !tagName ||
                        !tagName === "" ||
                        tagNameError ||
                        isLoadingRemove
                    }
                    className={
                      tagOperation === "view"
                        ? classes.primaryBackground
                        : classes.saveButton
                    }
                    variant="contained"
                    startIcon={
                      tagOperation === "view" ? (
                        <EditOutlinedIcon />
                      ) : (
                        <CheckOutlinedIcon />
                      )
                    }
                    onClick={handleEditSaveButtonClick}
                    isLoading={isLoading}
                  >
                    {tagOperation === "view"
                      ? "EDIT"
                      : tagOperation === "add"
                        ? "SAVE"
                        : "UPDATE"}
                  </SaveButton>
                </ToolTipComponent>
                {tagOperation === "add" ? null : (
                  <ToolTipComponent
                    title="You have no edit permission on this widget"
                    condition={!hasUpdateAccess(props.user, 'tags_master')}
                  >
                    <SaveButton
                      size="medium"
                      className={classes.removeButton}
                      variant="contained"
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={handleRemoveTag}
                      disabled={isLoading || !hasUpdateAccess(props.user, 'tags_master')}
                      isLoading={isLoadingRemove}
                    >
                      REMOVE
                    </SaveButton>
                  </ToolTipComponent>
                )}
                <Button
                  size="medium"
                  className={classes.margin}
                  onClick={handleCancelButtonClick}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
          <SnackbarComponent
            open={snackbarData.openSnackbar}
            handleClose={handleCloseSnackbar}
            snackbarMessage={snackbarData.snackbarMessage}
            type={snackbarData.snackbarType}
          />
          <RemoveConfirmationDialog
            open={removeConfirmationDialogData.openDialog}
            dialogTitle={removeConfirmationDialogData.dialogTitle}
            dialogContent={removeConfirmationDialogData.dialogContent}
            removeDataLoader={removeTagLoader}
            handleClose={handleRemoveConfirmationDialogClose}
            handleConfirmationDialogDeleteClick={
              handleConfirmationDialogDeleteClick
            }
          />
        </>
      )}
    </Layout>
  );
}
