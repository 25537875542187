import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Switch, withStyles } from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { checkAlphabet, checkApiError, pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import SaveButton from "../../common/SaveButton";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "40%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // width: '40%'
  }
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


function ManageUnitData(props) {
  const classes = useStyles();
  const [unitData, setUnitData] = React.useState(
    props.selectedUnitData || { unit_name: null, unit_code: null, is_active: true }
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [unitOperation, setUnitOperation] = React.useState(props.unitOperation);
  const [unitName, setUnitName] = React.useState([]);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [unitNameError, setUnitNameError] = React.useState({
    errorStatus: false,
    errorText: ''
  });
  const [unitCodeError, setUnitCodeError] = React.useState({
    errorStatus: false,
    errorText: ''
  });
  const [
    removeConfirmationDialogData,
    setRemoveConfirmationDialogData,
  ] = React.useState({
    openDialog: false,
    dialogTitle: ``,
    dialogContent: "",
  });

  React.useEffect(() => {
    props.unitNamesLists.map(data =>
      setUnitName(prevState => [(data.unit_name).toUpperCase(), ...prevState])
    )
    setUnitData(props.selectedUnitData);
    setUnitOperation(props.unitOperation);
    if (props?.selectedUnitData?.unit_name) {
      !checkUnitName(props?.selectedUnitData?.unit_name) ?
        setUnitNameError({ errorStatus: true, errorText: 'Invalid name' }) :
        setUnitNameError({ errorStatus: false, errorText: '' });
    }
    if (props?.selectedUnitData?.unit_code) {
      !checkAlphabet(props?.selectedUnitData?.unit_code) ?
        setUnitCodeError({ errorStatus: true, errorText: 'Invalid Code' }) :
        setUnitCodeError({ errorStatus: false, errorText: '' });
    }
  }, [props.selectedUnitData, props.unitOperation]);

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const checkUnitName = (input) => {
    const regex = /^[a-zA-Z\s]+[a-zA-Z0-9\s]+$/;
    if (regex.test(input)) {
      return true
    }
    return false;
  }

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    setUnitData({
      ...unitData,
      [name]: name === "is_active" ? checked : value,
    });
    if (!value) {
      if (name === "unit_name") {
        setUnitNameError({ errorStatus: true, errorText: 'Invalid name' });
      } else if (name === "unit_code") {
        setUnitCodeError({ errorStatus: true, errorText: 'Invalid Code' });
      }
    } else {
      if (name === "unit_name") {
        !checkUnitName(value) ?
          setUnitNameError({ errorStatus: true, errorText: 'Invalid name' }) :
          unitName.includes((event.target.value).toUpperCase()) ?
            setUnitNameError({ errorStatus: true, errorText: 'Already exist' }) :
            setUnitNameError({ errorStatus: false, errorText: '' })
      } else if (name === "unit_code") {
        !checkAlphabet(value) ?
          setUnitCodeError({ errorStatus: true, errorText: 'Invalid Code' }) :
          setUnitCodeError({ errorStatus: false, errorText: '' })
      } else {
        setUnitNameError({ errorStatus: false, errorText: '' });
        setUnitCodeError({ errorStatus: false, errorText: '' });
      }
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };
  const handleEditSaveButtonClick = () => {
    let addData = {
      unit_name: unitData.unit_name,
      unit_code: unitData.unit_code,
      is_active: (unitData.is_active) === undefined ? true : Boolean(unitData.is_active),
    }
    setIsLoading(true)
    if (unitOperation === "edit") {
      props
        .handleUnitMasterDataUpdate(unitData, unitData.id)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Unit Master Updated Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                props.history.goBack()
              }, 3000)
            }
            // props.history.goBack();
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating unit master details please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (unitOperation === "add") {
      props
        .handleUnitMasterDataAdd(addData)
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Unit Master Created Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                props.history.push(`${INTERNALROUTES.UNIT}/${response.data.id}`)
              }, 3000)
            }
            // props.history.push(`${INTERNALROUTES.UNIT}/${response.data.id}`);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while creating unit master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Unit Master",
                onClick: () => props.history.push(INTERNALROUTES.UNIT),
              },
              {
                title: unitOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                UNIT NAME *
              </Typography>
              <TextField
                label="Unit Name"
                name="unit_name"
                type="text"
                style={{
                  width: "400px"
                }}
                onChange={handleChange}
                placeholder="Enter unit name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={unitData?.unit_name || ""}
                error={unitNameError.errorStatus}
                helperText={unitNameError.errorText}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                UNIT CODE *
              </Typography>
              <TextField
                label="Unit Code"
                name="unit_code"
                type="text"
                style={{
                  width: "400px"
                }}
                onChange={handleChange}
                placeholder="Enter unit code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 3, minLength: 3, max: 3 }}
                value={unitData?.unit_code || ""}
                error={unitCodeError.errorStatus}
                helperText={unitCodeError.errorText}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACTIVE
              </Typography>
              <div className={classes.isActive}>
                <IOSSwitch
                  checked={(unitData.is_active) === undefined
                    ? true
                    : Boolean(unitData.is_active)
                  }
                  onChange={handleChange}
                  name="is_active"
                />
              </div>
            </div>
            <SaveButton
              size="medium"
              disabled={
                unitOperation === "view"
                  ? false
                  : !unitData.unit_name ||
                  !unitData.unit_code ||
                  unitData.unit_name === "" ||
                  unitData.unit_code === "" ||
                  unitNameError.errorStatus ||
                  unitCodeError.errorStatus
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {unitOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManageUnitData);
