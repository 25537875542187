import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  CardContent,
  CardActions,
  Card,
  Grow,
  CardActionArea,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import RiplEwayBillPdfDownload from "./RiplEwayBillPdfDownload";
import RiplInvoicePdfDownload from "./RiplInvoicePdfDownload";
import ExitPassDownload from "./ExitPassDownload";
import moment from "moment";
import {
  drawerTextColor,
  primaryColor,
} from "../../../constants/internal/colors";
const useStyles = makeStyles({
  cardStyle: {
    borderRadius: "30px",
    minWidth: 275,
    maxWidth: 400,
    height: 325,
  },
  cardHeaderTitle: { display: "flex", justifyContent: "space-between" },
  pdfTitleWayBill: {
    // paddingTop: "20px",
    width: "55%",
    justifyContent: "space-between",
  },
  pdfTitleInvoice: {
    paddingTop: "45px",
    width: "100%",
    justifyContent: "space-evenly",
  },
  eInvoiceDetails: {
    display: "flex",
    flexDirection: "column",
    background: drawerTextColor,
    placeContent: "center",
    placeItems: "center",
    justifyContent: "space-evenly",
  },
  pdfActions: {
    display: "flex",
    placeItems: "center",
    placeContent: "center",
    justifyContent: "space-between",
  },
  loaderDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ApprovalContainerBox: {
    color: "#F3FAF3",
    background: "#F3FAF3",
    marginBottom: "15px",
    borderRadius: "5px",
  },
  approvalListStyleDiv: {
    display: "flex",
    color: "#F3FAF3",
    background: "#F3FAF3",
    marginBottom: "15px",
    borderRadius: "5px",
    //placeItems: "center",
    padding: "10px 8px",
    justifyContent: "space-between",
    //width: "40%"
  },
  approvalListStyleText: {
    width: "100%",
    color: "rgb(112,112,112)",
    alignItems: "center",
    fontSize: "1em"
  },
  emailStyleText: {
    display: "flex",
    width: "100%",
    color: "rgb(112,112,112)",
    alignItems: "center",
    fontSize: "0.85em"
  },
  loaderStyle: { color: primaryColor },
});

// const styles = {
//   ApprovalContainerBox: {
//     color: "#F3FAF3",
//     background: "#F3FAF3",
//     marginBottom: "15px",
//   },
// }
export default function InvoiceWayBillEinvoiceViewDownloadCard(props) {
  const classes = useStyles();
  console.log("InvoiceWayBillEinvoiceViewDownloadCard : ", props);
  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(props.documentFor === "Exit Pass"
        ? { timeout: 3000 }
        : props.documentFor === "E-Way Bill"
          ? { timeout: 2000 }
          : { timeout: 1000 })}
    >
      <Card className={classes.cardStyle} elevation={5}>
        <CardHeader
          title={
            <div className={classes.cardHeaderTitle}>
              <Typography
                style={{
                  opacity: 0.4,
                  fontSize: "1rem",
                }}
              >
                {props?.documentFor || ""}
              </Typography>
              {props.documentFor === "E-Invoice" ? (
                <Typography
                  style={{
                    fontSize: "1rem",
                    color:
                      props?.eInvoiceResponse?.IrnStatus === "ACT"
                        ? "green"
                        : "red",
                  }}
                >
                  {props?.eInvoiceResponse?.IrnStatus === "ACT"
                    ? "Active"
                    : props?.eInvoiceResponse?.IrnStatus === "CNL"
                      ? "Cancelled"
                      : ""}
                </Typography>
              ) : null}
            </div>
          }
        />
        {props?.documentFor === "E-Way Bill" ? (
          props?.ewayPostDataLoader ? (
            <div className={classes.loaderDiv}>
              <CircularProgress disableShrink className={classes.loaderStyle} />
            </div>
          ) : !props?.eWayBillResponse?.EwbNo ? (
            !props?.eWayBillResponse?.EWayBill ? (
              <CardActionArea style={{ padding: 20 }}>
                <Typography
                  style={{
                    opacity: 0.4,
                    fontSize: "1rem",
                    color: "red",
                  }}
                >
                  {props?.eWayBillResponse?.e_way_message ||
                    "Error occured while creating e-way bill."}
                </Typography>
              </CardActionArea>
            ) : (
              <>
                <CardActionArea>
                  <CardContent>
                    <div className={classes.eInvoiceDetails}>
                      <Typography
                        style={{
                          opacity: 0.4,
                          fontSize: "1rem",
                        }}
                      >
                        {`${props?.eWayBillResponse?.EWayBill
                          ? `E-Way Bill Number : ${props?.eWayBillResponse?.EWayBill || ""
                          }`
                          : ""
                          }`}
                      </Typography>
                      <Typography
                        style={{
                          opacity: 0.4,
                          fontSize: "1rem",
                        }}
                      >
                        {`${props?.eWayBillResponse?.Date
                          ? `Date & Time : ${moment(
                            props?.eWayBillResponse?.Date,
                            [
                              "DD/MM/YYYY hh:mm:ss A",
                              "YYYY-MM-DD hh:mm:ss A",
                            ]
                          ).format("DD-MMM-YYYY LT")}`
                          : ""
                          }
                            `}
                      </Typography>
                      <Typography
                        style={{
                          opacity: 0.4,
                          fontSize: "1rem",
                        }}
                      >
                        {props?.eWayBillResponse?.ValidUpTo
                          ? `Valid Upto :  ${moment(
                            props?.eWayBillResponse?.ValidUpTo,
                            "DD/MM/YYYY hh:mm:ss LT"
                          ).format("DD-MMM-YYYY LT")}`
                          : ""}
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div className={classes.pdfTitleWayBill}>
                    <div className={classes.pdfActions}>
                      <RiplEwayBillPdfDownload
                        pdfOperation={"VIEW"}
                        {...props}
                      />
                      <RiplEwayBillPdfDownload
                        pdfOperation={"DOWNLOAD"}
                        {...props}
                      />
                    </div>
                  </div>
                </CardActions>
              </>
            )
          ) : (
            <>
              <CardActionArea>
                <CardContent>
                  <div className={classes.eInvoiceDetails}>
                    <Typography
                      style={{
                        opacity: 0.4,
                        fontSize: "1rem",
                      }}
                    >
                      {`${props?.eWayBillResponse?.EwbNo
                        ? `E-Way Bill Number : ${props?.eWayBillResponse?.EwbNo || ""}`
                        : ""
                        }`}
                    </Typography>
                    <Typography
                      style={{
                        opacity: 0.4,
                        fontSize: "1rem",
                      }}
                    >
                      {`${props?.eWayBillResponse?.EwbDt
                        ? `Date & Time : ${moment(
                          props?.eWayBillResponse?.EwbDt,
                          ["DD/MM/YYYY hh:mm:ss A", "YYYY-MM-DD hh:mm:ss A"]
                        ).format("DD-MMM-YYYY LT")}`
                        : ""
                        }
                            `}
                    </Typography>
                    <Typography
                      style={{
                        opacity: 0.4,
                        fontSize: "1rem",
                      }}
                    >
                      {props?.eWayBillResponse?.EwbValidTill
                        ? `Valid Upto :  ${moment(
                          props?.eWayBillResponse?.EwbValidTill,
                          ["DD/MM/YYYY hh:mm:ss A", "YYYY-MM-DD hh:mm:ss A"]
                        ).format("DD-MMM-YYYY LT")}`
                        : ""}
                    </Typography>
                  </div>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <div className={classes.pdfTitleWayBill}>
                  <div className={classes.pdfActions}>
                    <RiplEwayBillPdfDownload pdfOperation={"VIEW"} {...props} />
                    <RiplEwayBillPdfDownload
                      pdfOperation={"DOWNLOAD"}
                      {...props}
                    />
                  </div>
                </div>
              </CardActions>
            </>
          )
        ) : props?.documentFor === "Exit Pass" ? (
          <>
            <CardActionArea>
              <CardContent>
                <div className={classes.eInvoiceDetails}>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {`${props?.eInvoiceResponse?.AckNo
                      ? `Exit pass for E-invoice : ${props?.eInvoiceResponse?.AckNo || ""
                      }`
                      : props?.laDetailsInDispatchCounter?.la_number
                        ? `Exit pass for invoice : ${props?.laDetailsInDispatchCounter?.la_number || ""
                        }`
                        : ""
                      }
                    `}
                  </Typography>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {`${`Date & Time : ${moment(
                      props?.eInvoiceResponse?.AckDate || props?.eInvoiceResponse?.EwbDt || props?.eWayBillResponse?.EwbDt || props?.eWayBillResponse?.Date || props?.eWayBillResponse?.AckDate ||
                      props?.laDetailsInDispatchCounter?.created_at || "",
                      ["DD/MM/YYYY hh:mm a", "YYYY-MM-DD hh:mm a"]
                    ).format("DD-MMM-YYYY LT")}`}
                            `}
                  </Typography>
                </div>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <div className={classes.pdfTitleInvoice}>
                <div className={classes.pdfActions}>
                  <ExitPassDownload pdfOperation={"VIEW"} {...props} />
                  <ExitPassDownload pdfOperation={"DOWNLOAD"} {...props} />
                </div>
              </div>
            </CardActions>
          </>
        ) : (
          <>
            <CardActionArea>
              <CardContent>
                <div className={classes.eInvoiceDetails}>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {`${props.documentFor === "Invoice" ||
                      props.documentFor === "Delivery Challan"
                      ? props?.laDetailsInDispatchCounter?.la_number
                        ? `LA Number : ${props?.laDetailsInDispatchCounter?.la_number || ""
                        }`
                        : ""
                      : props.documentFor === "E-Invoice"
                        ? props?.eInvoiceResponse?.AckNo
                          ? `Acknowledgement No : ${props?.eInvoiceResponse?.AckNo || ""
                          }`
                          : ""
                        : ""
                      }
                    `}
                  </Typography>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {`${props.documentFor === "Invoice" ||
                      props.documentFor === "Delivery Challan"
                      ? props?.eWayBillResponse?.Date ||
                        props?.eWayBillResponse?.EwbDt
                        ? `Date & Time : ${moment(
                          props?.eWayBillResponse?.Date ||
                          props?.eWayBillResponse?.EwbDt || "",
                          ["DD/MM/YYYY hh:mm:ss A", "YYYY-MM-DD hh:mm:ss A"]
                        ).format("DD-MMM-YYYY LT")}`
                        : ""
                      : props.documentFor === "E-Invoice"
                        ? props?.eInvoiceResponse?.AckDate
                          ? `Date & Time : ${moment(
                            props?.eInvoiceResponse?.AckDate,
                            ["DD/MM/YYYY hh:mm:ss A", "YYYY-MM-DD hh:mm:ss A"]
                          ).format("DD-MMM-YYYY LT")}`
                          : ""
                        : ""
                      }
                    `}
                  </Typography>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {""}
                  </Typography>
                </div>
              </CardContent>
            </CardActionArea>
            <CardActions>

              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ width: "100%", }}>
                  {/* <div className={classes.ApprovalContainerBox}> */}
                  <div className={classes.approvalListStyleDiv}>
                    <div style={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <span className={classes.approvalListStyleText}>
                        Plant Copy
                      </span>
                    </div>

                    <div className={classes.pdfTitleWayBill}>
                      <div className={classes.pdfActions}>
                        <RiplInvoicePdfDownload pdfOperation={"VIEW"} {...props} />
                        <RiplInvoicePdfDownload
                          pdfOperation={"DOWNLOAD"}
                          {...props}
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </div>

                </div>

                <div style={{ width: "100%", }}>
                  {/* <div className={classes.ApprovalContainerBox}> */}
                  <div className={classes.approvalListStyleDiv}>
                    <div style={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <span className={classes.approvalListStyleText}>
                        Customer Copy
                      </span>
                    </div>

                    <div className={classes.pdfTitleWayBill}>
                      <div className={classes.pdfActions}>
                        <RiplEwayBillPdfDownload pdfOperation={"VIEW"} {...props} />
                        <RiplEwayBillPdfDownload
                          pdfOperation={"DOWNLOAD"}
                          {...props}
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div style={{ width: "100%", }}>
                  {/* <div className={classes.ApprovalContainerBox}> */}
                  <div className={classes.approvalListStyleDiv}>
                    <div style={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <span className={classes.approvalListStyleText}>
                        Email
                      </span>
                    </div>

                    <div style={{ width: "55%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <span className={classes.emailStyleText}>
                        Email sent to vishal@smilebots.com
                      </span>
                    </div>

                    {/* <div className={classes.pdfTitleWayBill}>
                      <div className={classes.pdfActions}>
                        <RiplEwayBillPdfDownload pdfOperation={"VIEW"} {...props} />
                        <RiplEwayBillPdfDownload
                          pdfOperation={"DOWNLOAD"}
                          {...props}
                        />
                      </div>
                    </div> */}
                  </div>
                  {/* </div> */}
                </div>
              </div>



            </CardActions>
          </>
        )}
      </Card>
    </Grow >
  );
}
