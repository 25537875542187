import axios from 'axios';
import React, { Component } from 'react';
import DashboardWeb, {
  DashboardMobile,
} from '../../../components/internal/dashboard/index';
import APIROUTES from '../../../constants/internal/InternalApiRoutes';
import withAuthentication from '../../../hoc/withAuthentication';
class DashboardContainer extends Component {

  changePassword = (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.CHANGE_PASSWORD, {
          oldPassword: oldPassword,
          newPassword: newPassword,
          credential: this.props.user.employee_id,
        })
        .then(resolve)
        .catch(reject);
    });

  render() {
    return (
      // this?.props?.isMobile ? (
      //   <DashboardMobile {...this.props} />
      // ) : 
      // (
      <DashboardWeb
        {...this.props}
        changePassword={this.changePassword}
      />
    );
  }
}
export default withAuthentication(DashboardContainer);
