import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '4px',
        height: '12px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 11,
        padding: '4px',
        height: '12px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function WeighbridgeItemTable({ weighslipId, allWeighings }) {
    //console.log(weighslipId, allWeighings)

    const result = allWeighings.find(a => a.id == weighslipId).items;
    return (
        <div style={{ padding: '10px' }}>
            {
                result.length > 0 ? <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Items</StyledTableCell>
                                <StyledTableCell align="center">Bundle Count</StyledTableCell>
                                <StyledTableCell align="center">Quantity</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result.map((row) => (
                                <StyledTableRow key={row.item_id}>
                                    {/* {console.log(row)} */}
                                    <StyledTableCell component="th" scope="row" align="center">
                                        {row.item}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.actual_section_count}</StyledTableCell>
                                    <StyledTableCell align="center">{row.alloted_weight} MTS</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                    : <></>
            }
        </div>
    );
}