import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManageTdsRate from "../../../../components/internal/masterManagement/tdsRate/ManageTdsRate";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { isAccessibleModule } from "../../../../methods";
import { Route } from "react-router-dom";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayTdsRateData from "../../../../components/internal/masterManagement/tdsRate/web";

class ManageTdsRateContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      tdsRateOperation: null,
      selectedTdsRateId: null,
      selectedTdsRateData: [],
      selectedTdsRateDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let tdsRateOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ tdsRateOperation }, () => console.log(1));
    if (tdsRateOperation !== "add") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedTdsRateData(id);
      this.setState({ selectedTdsRateId: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let tdsRateOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedTdsRateId =
      tdsRateOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (tdsRateOperation !== state.tdsRateOperation) {
      updatedData.set("tdsRateOperation", tdsRateOperation);
    }
    if (selectedTdsRateId !== state.selectedTdsRateId) {
      updatedData.set("selectedTdsRateId", selectedTdsRateId);
    }
    if (updatedData.size > 0) {
      return {
        tdsRateOperation: updatedData.has("tdsRateOperation")
          ? updatedData.get("tdsRateOperation")
          : tdsRateOperation,
        selectedTdsRateId: updatedData.has("selectedTdsRateId")
          ? updatedData.get("selectedTdsRateId")
          : [],
      };
    }
    return null;
  }
  handleFetchSelectedTdsRateData = async (selectedTdsRateId) => {
    await this.setState({ selectedTdsRateDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.TDS_RATE}${selectedTdsRateId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedTdsRateData: response.data,
              selectedTdsRateDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedTdsRateDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected bank data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedTdsRateDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching selected bank data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleTdsRateMasterDataAdd = (tdsRateData) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.TDS_RATE, tdsRateData, this.getOptions(this.props.user))
        .then((response) => {
          this.handleFetchSelectedTdsRateData(response.data.id);
          this.props.getTdsRateList();
          resolve(response);
        })
        .catch(reject);
    });
  handleTdsRateMasterDataUpdate = (tdsRateData, tdsRateDataId) =>
    new Promise((resolve, reject) => {
      axios
        .put(
          `${APIROUTES.TDS_RATE}${tdsRateDataId}/`,
          tdsRateData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedTdsRateData(tdsRateDataId);
          this.props.getTdsRateList();
          resolve(response);
        })
        .catch(reject);
    });
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  render() {
    const { tdsRateOperation } = this.state;
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    // if (isAccessibleModule(this.props?.user, "Banks"))
    else {
      return (
        <>
          {tdsRateOperation === "view" ? (
            <DisplayTdsRateData
              {...this.props}
              {...this.state}
              handlePaymentTermDataUpdate={this.handlePaymentTermDataUpdate}
              handlePaymentTermDataAdd={this.handlePaymentTermDataAdd}
              handleRemovePaymentTerm={this.handleRemovePaymentTerm}
            />
          ) : (
            <ManageTdsRate
              {...this.props}
              {...this.state}
              handleTdsRateMasterDataUpdate={this.handleTdsRateMasterDataUpdate}
              handleTdsRateMasterDataAdd={this.handleTdsRateMasterDataAdd}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManageTdsRateContainer);
