import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  successButtonBackground,
  drawerTextColor,
  secondaryBlueText,
  earthBlue,
  drawerBackground,
} from "../../../../constants/internal/colors";
import { checkApiError, pxToEm, UpperCaseText } from "../../../../methods";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import Bread from "../../common/Breadcrubs";
import { withRouter } from "react-router-dom";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../common/SnackbarComponent";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { validationsRateTemplateDetails } from "./ItemCategoryValidationSchema";
import PresentToAllOutlinedIcon from "@material-ui/icons/PresentToAllOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { gridColumnsTotalWidthSelector } from "@material-ui/data-grid";
import SaveButton from "../../common/SaveButton";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const useStyles = makeStyles({
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  toleranceFieldStyles: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
  },
  loadingAndInsuranceRateFields: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
  },
  formControl: {
    width: "50%",
  },
  formControlgst: {
    width: "30%",
  },
  toleranceFieldWidth: { width: "45%" },
  loadingInsuranceFieldWidth: { width: "45%" },
  formControlPanImage: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    justifyContent: "space-between",
    width: "100%",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    width: "17%",
  },
  titleLabelStylePanImage: {
    fontSize: pxToEm(15),
    color: secondaryBlueText,
    fontWeight: "bold",
    // marginBottom: 15,
    cursor: "pointer",
    textDecoration: "underline",
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "100%" },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logoImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
    cursor: "pointer",
  },
  logoImagePreview: {
    height: "100",
    width: "100",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    width: "40%",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  generalTopMargin: {
    paddingTop: 20,
  },
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },

  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

function ManageItemCategoryRateTemplateTab(props) {
  const classes = useStyles();
  const [ItemCategoryData, setItemCategoryData] = React.useState(
    //   {
    //   category_name: "",
    //   hsn_code: "",
    //   min_tolerance_value: "",
    //   max_tolerance_value: "",
    //   cgst: "",
    //   sgst: "",
    //   igst: "",
    //   loading_rate: "",
    //   insurance_rate: "",
    //   div_code: "",
    //   item_category_code: "",
    //   item_category_logo: "",
    //   primary_unit: "",
    //   secondary_unit: "",
    //   is_active: "",
    //   is_allow_rate_zero: "",
    //   is_consider_packing: "",
    //   loading_rate_valid_from: "",
    //   insurance_rate_valid_from: "",
    // }
    props?.selectedItemCategoryData
  );
  const [itemCategoryLogoError, setItemCategoryLogoError] = React.useState(false)
  const [itemCategoryLogoUploadData, setItemCategoryLogoUploadData] = React.useState({
    itemCategoryLogoRawUploadedFile: "",
    itemCategoryLogoImageUrl: null,
    itemCategoryLogoFileName: null,
  });
  const [categoryName, setCategoryName] = React.useState([]);
  const [categoryNameError, setCategoryNameError] = React.useState({
    errorStatus: false,
    errorText: ''
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [itemOperation, setItemOperation] = React.useState(props.itemOperation);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  React.useEffect(() => {
    props.itemCategoriesList.map(data =>
      setCategoryName(prevState => [(data.category_name).toUpperCase(), ...prevState])
    )
  }, [props.itemCategoriesList]);

  React.useEffect(() => {
    let itemCategoryImageData = props.selectedItemCategoryData.item_category_logo
      ? props.selectedItemCategoryData.item_category_logo.split("/")
      : [];
    let itemCategoryFileName =
      itemCategoryImageData.length > 0
        ? itemCategoryImageData[Number(itemCategoryImageData.length) - 1]
        : null;
    let updateditemCategoryData = {
      itemCategoryLogoRawUploadedFile: "",
      itemCategoryLogoImageUrl: props.selectedItemCategoryData.item_category_logo,
      itemCategoryLogoFileName: itemCategoryFileName,
    };
    setItemCategoryLogoUploadData(updateditemCategoryData);
  }, [])

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleLoadingRateValidFromChange = (date) => {
    setItemCategoryData({
      ...ItemCategoryData,
      'loading_rate_valid_from': moment(date).format("YYYY-MM-DD"),
    });

  }

  const handleInsuranceRateValidFromChange = (date) => {
    setItemCategoryData({
      ...ItemCategoryData,
      'insurance_rate_valid_from': moment(date).format("YYYY-MM-DD"),
    });

  }

  const handleChange = (event) => {

    const { value, name, checked } = event.target;
    // console.log(name === "ItemCategoryData" && value)
    setItemCategoryData({
      ...ItemCategoryData,
      [name]: name === "is_active" || name === "is_allow_rate_zero" || name === "is_consider_packing"
        ? checked
        : ['div_code', 'item_category_code'].includes(name)
          ? UpperCaseText(value)
          : value,
    });
    if (event.target.name === "category_name") {
      categoryName.includes((event.target.value).toUpperCase()) ?
        setCategoryNameError({ errorStatus: true, errorText: 'Already exist' }) :
        setCategoryNameError({ errorStatus: false, errorText: '' })
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };
  // console.log(ItemCategoryData["item_category_logo"])
  const handleEditSaveButtonClick = () => {
    // console.log(ItemCategoryData["item_category_logo"])
    setIsLoading(true)
    let itemCategoryFormData = new FormData()

    Object.keys(ItemCategoryData).forEach(function (key, index) {

      if (key === "entity_code" || key === "related_to") {

      } else if (itemOperation === "edit" && key === "item_category_logo" && typeof ItemCategoryData["item_category_logo"] === "string") {

      }
      else {
        // console.log(key)
        itemCategoryFormData.append(key, key === 'hsn_code' ? "" : ItemCategoryData[key])
      }
      // console.log(ItemCategoryData[key]);
      // console.log(key)
    });
    // console.log(itemCategoryFormData)

    // console.log(myObject);
    if (itemOperation === "edit") {
      if (!ItemCategoryData["item_category_logo"] || ItemCategoryData["item_category_logo"] === "") {
        // console.log("else")
        setItemCategoryLogoError(true)
      } else {
        // console.log("if")
        if (itemCategoryLogoError) {
          setItemCategoryLogoError(false)
        }
        props
          .updateItemCategory(itemCategoryFormData, ItemCategoryData.id)
          .then((response) => {
            if (response.status === 200) {
              handleOpenSnackbar(
                `Item Category Updated Successfully`,
                "success"
              );
              setTimeout(() => {
                // props.history.goBack();
                setIsLoading(false)
                props.getItemCateogriesList()
                props.history.push(
                  `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}`
                );
                props.history.push(
                  `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/${response.data.id}`
                );
                handleCloseSnackbar()
              }, 4000);
            } else {
              setIsLoading(false)
              handleOpenSnackbar(
                `Error occured while updating item category details please try again`,
                "error"
              );
            }
          })
          .catch((error) => {
            setIsLoading(false)
            handleOpenSnackbar(
              handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000);
          });
      }

    } else if (itemOperation === "add") {
      // console.log("here")

      if (!ItemCategoryData["item_category_logo"] || ItemCategoryData["item_category_logo"] === "") {
        // console.log("else")
        setItemCategoryLogoError(true)
      } else {
        // console.log("if")
        if (itemCategoryLogoError) {
          setItemCategoryLogoError(false)
        }
        props
          .saveItemCategory(itemCategoryFormData)
          .then((response) => {
            if (response.status === 201) {
              handleOpenSnackbar(
                `Item Category Created Successfully`,
                "success"
              );
              setTimeout(() => {
                setIsLoading(false)
                props.getItemCateogriesList();
                props.history.push(
                  `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}`
                );
                props.history.push(
                  `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/${response.data.id}`
                );
                handleCloseSnackbar()
              }, 4000);
            } else {
              setIsLoading(false)
              handleOpenSnackbar(
                `Error occured while creating item category please try again`,
                "error"
              );
            }
          })
          .catch((error) => {
            setIsLoading(false)
            handleOpenSnackbar(
              handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000);
          });
      }
    } else {
      console.log("Invalid operation");
    }
  };
  const handleItemCategoryLogoImageUpload = (e) => {
    if (e.target.files.length) {
      // console.log(e.target.files[0]);
      setItemCategoryLogoUploadData({
        ...itemCategoryLogoUploadData,
        ["itemCategoryLogoImageUrl"]: URL.createObjectURL(e.target.files[0]),
        ["itemCategoryLogoRawUploadedFile"]: e.target.files[0],
        ["itemCategoryLogoFileName"]: e.target.files[0].name,
      });
      setItemCategoryData({
        ...ItemCategoryData,
        ["item_category_logo"]: e.target.files[0],
      });
      setItemCategoryLogoError(false)
    }
  };
  const handleItemCategoryLogoImageRemove = () => {
    // console.log("remove image");
    setItemCategoryLogoUploadData({
      ...itemCategoryLogoUploadData,
      ["itemCategoryLogoImageUrl"]: "",
      ["itemCategoryLogoRawUploadedFile"]: "",
      ["itemCategoryLogoFileName"]: null,
    });
    setItemCategoryData({
      ...ItemCategoryData,
      ["item_category_logo"]: "",
    });
    setItemCategoryLogoError(true)
  };
  const [listData, setListData] = React.useState("");
  React.useEffect(() => {
    setItemCategoryData(props?.selectedItemCategoryData);
    // console.log(props?.selectedItemCategoryData)
    setItemOperation(props.itemOperation);
  }, [props?.selectedItemCategoryData, props.itemOperation]);
  React.useEffect(() => {
    axios
      .get(APIROUTES.DAILY_RATE_TEMPLATE_GET)
      .then((response) => {
        if (response.status === 200) setListData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // console.log(itemCategoryLogoError);

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Item Categories",
                onClick: () =>
                  props.history.push(INTERNALROUTES.LISTITEMCATEGORIESMASTER),
              },
              {
                title: itemOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />

          <div className={classes.generalDetailsDisplaySection}>
            <div className={classes.dataDisplay}>
              <Formik
                initialValues={{
                  category_name: ItemCategoryData?.category_name || "",
                  hsn_code: ItemCategoryData?.hsn_code || "",
                  min_tolerance_value: ItemCategoryData?.min_tolerance_value || "",
                  max_tolerance_value: ItemCategoryData?.max_tolerance_value || "",
                  cgst: ItemCategoryData?.cgst || "",
                  sgst: ItemCategoryData?.sgst || "",
                  igst: ItemCategoryData?.igst || "",
                  loading_rate: ItemCategoryData?.loading_rate || "",
                  insurance_rate: ItemCategoryData?.insurance_rate || "",
                  div_code: ItemCategoryData?.div_code || "",
                  item_category_code: ItemCategoryData?.item_category_code || "",
                  item_category_logo: ItemCategoryData?.item_category_logo || "",
                  primary_unit: ItemCategoryData?.primary_unit || "",
                  secondary_unit: ItemCategoryData?.secondary_unit || "",
                  is_active: ItemCategoryData?.is_active || "",
                  is_allow_rate_zero: ItemCategoryData?.is_allow_rate_zero || "",
                  is_consider_packing: ItemCategoryData?.is_consider_packing || "",
                  loading_rate_valid_from: ItemCategoryData?.loading_rate_valid_from || "",
                  insurance_rate_valid_from: ItemCategoryData?.insurance_rate_valid_from || "",
                }}
                // enableReinitialize={true}
                validationSchema={validationsRateTemplateDetails}
                // validateOnChange={true}
                // validateOnBlur={true}
                onSubmit={
                  handleEditSaveButtonClick
                }
              >
                {(formik) => (
                  <Form>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        CATEGORY NAME*
                      </Typography>
                      <TextField
                        type="text"
                        name="category_name"
                        className={classes.formControl}
                        label="CATEGORY NAME"
                        value={ItemCategoryData?.category_name}
                        onChange={(e) => {
                          handleChange(e)
                          formik.setFieldValue(
                            "category_name",
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        size="small"
                        helperText={categoryNameError.errorStatus ?
                          categoryNameError.errorText
                          :
                          (formik.errors.category_name &&
                            formik.touched.category_name &&
                            String(formik.errors.category_name))
                        }
                        error={categoryNameError.errorStatus ?
                          categoryNameError.errorStatus
                          :
                          Boolean(
                            formik.errors.category_name &&
                            formik.touched.category_name
                          )
                        }
                      // error={props.itemCategoryNameError}
                      // helperText={props.itemCategoryNameError ? "Invalid item name" : ""}
                      />
                    </div>
                    {/* <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>HSN CODE*</Typography>
                      <TextField
                        type="number"
                        className={classes.formControl}
                        label="HSN CODE"
                        name="hsn_code"
                        value={ItemCategoryData.hsn_code}
                        onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          handleChange(e)
                          formik.setFieldValue(
                            "hsn_code",
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        size="small"
                        helperText={
                          formik.errors.hsn_code &&
                          formik.touched.hsn_code &&
                          String(formik.errors.hsn_code)
                        }
                        error={Boolean(
                          formik.errors.hsn_code &&
                          formik.touched.hsn_code
                        )}
                      // error={props.hsnCodeError}
                      // helperText={props.hsnCodeError ? "Invalid HSN code" : ""}
                      />
                    </div> */}
                    <div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          TOLERANCE*
                        </Typography>
                        <div className={classes.toleranceFieldStyles}>
                          <TextField
                            type="number"
                            className={classes.toleranceFieldWidth}
                            label="SHORT VALUE"
                            name="min_tolerance_value"
                            value={ItemCategoryData.min_tolerance_value}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "min_tolerance_value",
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "min_tolerance_value",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                            size="small"
                            helperText={
                              formik.errors.min_tolerance_value &&
                              formik.touched.min_tolerance_value &&
                              String(formik.errors.min_tolerance_value)
                            }
                            error={Boolean(
                              formik.errors.min_tolerance_value &&
                              formik.touched.min_tolerance_value
                            )}
                            // error={props.toleranceShortValueError}
                            // helperText={
                            //   props.toleranceShortValueError ? "Invalid short value" : ""
                            // }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography className={classes.titleLabel}>
                                    MT
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            type="number"
                            name="max_tolerance_value"
                            className={classes.toleranceFieldWidth}
                            label="MAX VALUE"
                            value={ItemCategoryData.max_tolerance_value}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "max_tolerance_value",
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "max_tolerance_value",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                            size="small"
                            helperText={
                              formik.errors.max_tolerance_value &&
                              formik.touched.max_tolerance_value &&
                              String(formik.errors.max_tolerance_value)
                            }
                            error={Boolean(
                              formik.errors.max_tolerance_value &&
                              formik.touched.max_tolerance_value
                            )}
                            // error={props.toleranceMaxValueError}
                            // helperText={
                            //   props.toleranceMaxValueError ? "Invalid max value" : ""
                            // }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography className={classes.titleLabel}>
                                    MT
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>GST %*</Typography>
                        <div className={classes.toleranceFieldStyles}>
                          <TextField
                            type="number"
                            name="cgst"
                            className={classes.formControlgst}
                            label="CGST"
                            value={ItemCategoryData.cgst}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "cgst",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                            size="small"
                            // error={props.cgstError}
                            // helperText={props.cgstError ? "Invalid CGST" : ""}
                            helperText={
                              formik.errors.cgst &&
                              formik.touched.cgst &&
                              String(formik.errors.cgst)
                            }
                            error={Boolean(
                              formik.errors.cgst &&
                              formik.touched.cgst
                            )}
                          />
                          <TextField
                            type="number"
                            name="sgst"
                            className={classes.formControlgst}
                            label="SGST"
                            value={ItemCategoryData.sgst}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "sgst",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                            size="small"
                            // error={props.sgstError}
                            // helperText={props.sgstError ? "Invalid SGST" : ""}
                            helperText={
                              formik.errors.sgst &&
                              formik.touched.sgst &&
                              String(formik.errors.sgst)
                            }
                            error={Boolean(
                              formik.errors.sgst &&
                              formik.touched.sgst
                            )}
                          />
                          <TextField
                            type="number"
                            name="igst"
                            className={classes.formControlgst}
                            label="IGST"
                            value={ItemCategoryData.igst}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "igst",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                            size="small"
                            // error={props.igstError}
                            // helperText={props.igstError ? "Invalid IGST" : ""}
                            helperText={
                              formik.errors.igst &&
                              formik.touched.igst &&
                              String(formik.errors.igst)
                            }
                            error={Boolean(
                              formik.errors.igst &&
                              formik.touched.igst
                            )}
                          />
                        </div>

                      </div>
                      {/* <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>SGST %</Typography>

                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>IGST %</Typography>

                      </div> */}
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          LOADING RATE*
                        </Typography>
                        <div className={classes.loadingAndInsuranceRateFields}>
                          <TextField
                            type="number"
                            name="loading_rate"
                            className={classes.loadingInsuranceFieldWidth}
                            label="LOADING RATE"
                            value={ItemCategoryData.loading_rate}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onKeyPress={(event) => {
                              if (event?.key === '+' || event?.key === '=') {
                                event.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "loading_rate",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                            size="small"
                            helperText={
                              formik.errors.loading_rate &&
                              formik.touched.loading_rate &&
                              String(formik.errors.loading_rate)
                            }
                            error={Boolean(
                              formik.errors.loading_rate &&
                              formik.touched.loading_rate
                            )}
                          />
                          {console.log(ItemCategoryData)}
                          <ThemeProvider theme={materialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                name="loading_rate_valid_from"
                                size="small"
                                inputVariant="outlined"
                                label="Effective From"
                                format="dd/MM/yyyy"
                                className={classes.loadingInsuranceFieldWidth}
                                //disablePast={new Date(ItemCategoryData?.loading_rate_valid_from) > new Date() ? new Date(ItemCategoryData?.loading_rate_valid_from) : new Date()}
                                // minDate={ItemCategoryData?.loading_rate_valid_from
                                //   ? new Date(ItemCategoryData?.loading_rate_valid_from)
                                //   : new Date()}
                                minDate={new Date(ItemCategoryData?.loading_rate_valid_from)}
                                value={!ItemCategoryData?.loading_rate_valid_from ? null : new Date(ItemCategoryData?.loading_rate_valid_from)}
                                helperText={(formik.errors.loading_rate_valid_from && formik.touched.loading_rate_valid_from) ? String(formik.errors.loading_rate_valid_from) : " "}
                                onChange={(e) => {
                                  handleLoadingRateValidFromChange(e)
                                  formik.setFieldValue(
                                    "loading_rate_valid_from",
                                    e
                                  );
                                }}
                                placeholder="e.g.: DD/MM/YYYY"
                                InputAdornmentProps={{ position: "end", }}
                                InputProps={{
                                  style: {
                                    paddingRight: 0,
                                  },
                                  readOnly: true
                                }}
                                error={Boolean(
                                  formik.errors.loading_rate_valid_from &&
                                  formik.touched.loading_rate_valid_from
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </div>
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          INSURANCE RATE*
                        </Typography>
                        <div className={classes.loadingAndInsuranceRateFields}>
                          <TextField
                            type="number"
                            name="insurance_rate"
                            className={classes.loadingInsuranceFieldWidth}
                            label="INSURANCE RATE"
                            value={ItemCategoryData.insurance_rate}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onKeyPress={(event) => {
                              if (event?.key === '+' || event?.key === '=') {
                                event.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              handleChange(e)
                              formik.setFieldValue(
                                "insurance_rate",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                            size="small"
                            helperText={
                              formik.errors.insurance_rate &&
                              formik.touched.insurance_rate &&
                              String(formik.errors.insurance_rate)
                            }
                            error={Boolean(
                              formik.errors.insurance_rate &&
                              formik.touched.insurance_rate
                            )}
                          />
                          <ThemeProvider theme={materialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                name="insurance_rate_valid_from"
                                size="small"
                                inputVariant="outlined"
                                label="Effective From"
                                format="dd/MM/yyyy"
                                className={classes.loadingInsuranceFieldWidth}
                                //disablePast={new Date(ItemCategoryData?.insurance_rate_valid_from) > new Date() ? new Date(ItemCategoryData?.insurance_rate_valid_from) : new Date()}
                                // minDate={ItemCategoryData?.insurance_rate_valid_from
                                //   ? new Date(ItemCategoryData?.insurance_rate_valid_from)
                                //   : new Date()}
                                minDate={new Date(ItemCategoryData?.insurance_rate_valid_from)}
                                value={!ItemCategoryData?.insurance_rate_valid_from ? null : new Date(ItemCategoryData?.insurance_rate_valid_from)}
                                helperText={(formik.errors.insurance_rate_valid_from && formik.touched.insurance_rate_valid_from) ? String(formik.errors.insurance_rate_valid_from) : " "}
                                onChange={(e) => {
                                  handleInsuranceRateValidFromChange(e)
                                  formik.setFieldValue(
                                    "insurance_rate_valid_from",
                                    e
                                  );
                                }}
                                placeholder="e.g.: DD/MM/YYYY"
                                InputAdornmentProps={{ position: "end", }}
                                InputProps={{
                                  style: {
                                    paddingRight: 0,
                                  },
                                  readOnly: true
                                }}
                                error={Boolean(
                                  formik.errors.insurance_rate_valid_from &&
                                  formik.touched.insurance_rate_valid_from
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </div>
                      </div>
                      {/* <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          RATE DIFFERENCE*
                        </Typography>
                        <TextField
                          type="number"
                          name="rate_difference"
                          className={classes.formControl}
                          label="RATE DIFFERENCE"
                          value={ItemCategoryData.rate_difference}
                          onChange={(e) => {
                            handleChange(e)
                            formik.setFieldValue(
                              "rate_difference",
                              e.target.value
                            );
                          }}
                          variant="outlined"
                          size="small"
                          helperText={
                            formik.errors.rate_difference &&
                            formik.touched.rate_difference &&
                            String(formik.errors.rate_difference)
                          }
                          error={Boolean(
                            formik.errors.rate_difference &&
                            formik.touched.rate_difference
                          )}
                        />
                      </div> */}
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          DIV CODE*
                        </Typography>
                        <TextField
                          type="text"
                          name="div_code"
                          className={classes.formControl}
                          label="DIV CODE"
                          value={ItemCategoryData.div_code}
                          onChange={(e) => {
                            handleChange(e)
                            formik.setFieldValue(
                              "div_code",
                              e.target.value
                            );
                          }}
                          variant="outlined"
                          size="small"
                          helperText={
                            formik.errors.div_code &&
                            formik.touched.div_code &&
                            String(formik.errors.div_code)
                          }
                          error={Boolean(
                            formik.errors.div_code &&
                            formik.touched.div_code
                          )}
                        />
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          ITEM CATEGORY CODE*
                        </Typography>
                        <TextField
                          type="text"
                          name="item_category_code"
                          className={classes.formControl}
                          label="ITEM CATEGORY CODE"
                          value={ItemCategoryData.item_category_code}
                          onChange={(e) => {
                            handleChange(e)
                            formik.setFieldValue(
                              "item_category_code",
                              e.target.value
                            );
                          }}
                          variant="outlined"
                          size="small"
                          helperText={
                            formik.errors.item_category_code &&
                            formik.touched.item_category_code &&
                            String(formik.errors.item_category_code)
                          }
                          error={Boolean(
                            formik.errors.item_category_code &&
                            formik.touched.item_category_code
                          )}
                        />
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          ITEM CATEGORY LOGO *
                        </Typography>
                        <div className={classes.formControlPanImage}>
                          {!itemCategoryLogoUploadData.itemCategoryLogoImageUrl ? (
                            <>
                              <label
                                htmlFor="upload-button"
                                className={classes.fileUploadName}
                              >
                                <div className={classes.logoImageUpload}>
                                  <PresentToAllOutlinedIcon
                                    className={classes.logoImagePreview}
                                    style={{ cursor: "pointer", }}
                                  />
                                  <Typography className={classes.generalText}>
                                    Upload
                                  </Typography>
                                </div>
                              </label>
                              <input
                                type="file"
                                accept=".jpg,.png"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleItemCategoryLogoImageUpload(e);
                                }}
                              />

                            </>


                          ) : (
                            <span
                              onClick={() =>
                                window.open(itemCategoryLogoUploadData.itemCategoryLogoImageUrl, "_blank")
                              }
                              className={classes.titleLabelStylePanImage}
                            >
                              {itemCategoryLogoUploadData.itemCategoryLogoFileName}
                            </span>
                          )}
                          {
                            !ItemCategoryData.item_category_logo ? "" :
                              <DeleteOutlineOutlinedIcon
                                width="20"
                                height="20"
                                onClick={handleItemCategoryLogoImageRemove}
                                style={{ cursor: "pointer", marginLeft: -10 }}
                              />
                          }
                        </div>

                      </div>
                      {
                        itemCategoryLogoError ?
                          <p style={{ color: "red", marginLeft: "17%", marginTop: -10 }}>This field is required *</p>
                          : " "
                      }
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          PRIMARY UNIT*
                        </Typography>
                        <FormControl size="small" variant="outlined" error={Boolean(
                          formik.errors.primary_unit &&
                          formik.touched.primary_unit
                        )}>
                          <InputLabel
                            htmlFor="primary_unit"
                          >
                            PRIMARY UNIT
                          </InputLabel>
                          <Select
                            id="primary_unit"
                            variant="outlined"
                            label="PRIMARY UNIT"
                            name="primary_unit"
                            style={{ width: 190 }}
                            // className={classes.formControl}
                            MenuProps={MenuProps}
                            value={props.unitList?.length > 0
                              && (props.unitList.find((data) => (
                                data.unit_code == ItemCategoryData.primary_unit
                              )))?.unit_code
                            }
                            onChange={
                              (e) => {
                                handleChange(e)
                                // setItemCategoryData({
                                //   ...ItemCategoryData,
                                //   ['primary_unit']: e.target.value
                                // })
                                formik.setFieldValue(
                                  "primary_unit",
                                  e.target.value
                                )
                              }}
                          >
                            {props.unitList?.length > 0
                              ? props.unitList.map((data) => (
                                <MenuItem value={data.unit_code}>{data.unit_code}</MenuItem>
                              ))
                              : null}
                          </Select>
                          <FormHelperText>
                            {
                              (formik.errors.primary_unit && formik.touched.primary_unit)
                                ? String(formik.errors.primary_unit) : " "
                            }
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          SECONDARY UNIT*
                        </Typography>
                        <FormControl size="small" variant="outlined" error={Boolean(
                          formik.errors.secondary_unit &&
                          formik.touched.secondary_unit
                        )}>
                          <InputLabel
                            htmlFor="secondary_unit"
                          >
                            SECONDARY UNIT
                          </InputLabel>
                          <Select
                            id="secondary_unit"
                            variant="outlined"
                            label="SECONDARY UNIT"
                            name="secondary_unit"
                            style={{ width: 190 }}
                            // className={classes.formControl}
                            MenuProps={MenuProps}
                            value={props.unitList?.length > 0
                              && (props.unitList.find((data) => (
                                data.unit_code == ItemCategoryData.secondary_unit
                              )))?.unit_code}
                            onChange={
                              (e) => {
                                handleChange(e)
                                formik.setFieldValue(
                                  "secondary_unit",
                                  e.target.value
                                )
                              }}
                          >
                            {props.unitList?.length > 0
                              ? props.unitList.map((data) => (
                                <MenuItem value={data.unit_code}>{data.unit_code}</MenuItem>
                              ))
                              : null}
                          </Select>
                          <FormHelperText>
                            {
                              (formik.errors.secondary_unit && formik.touched.secondary_unit)
                                ? String(formik.errors.secondary_unit) : " "
                            }
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          ACTIVE STATUS
                        </Typography>
                        <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                          <IOSSwitch
                            checked={ItemCategoryData.is_active}
                            onChange={(e) => handleChange(e)}
                            name="is_active"
                          />
                        </div>
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          ALLOW 0 RATE CONTRACT
                        </Typography>
                        <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                          <IOSSwitch
                            checked={ItemCategoryData.is_allow_rate_zero}
                            onChange={(e) => handleChange(e)}
                            name="is_allow_rate_zero"
                          />
                        </div>
                      </div>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          CONSIDER PACKING WEIGHT
                        </Typography>
                        <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                          <IOSSwitch
                            checked={ItemCategoryData.is_consider_packing}
                            onChange={(e) => handleChange(e)}
                            name="is_consider_packing"
                          />
                        </div>
                      </div>
                      <div>
                        <SaveButton
                          size="medium"
                          className={classes.saveButton}
                          variant="contained"
                          startIcon={<CheckOutlinedIcon />}
                          type="submit"
                          disabled={categoryNameError.errorStatus}
                          isLoading={isLoading}
                        >
                          {itemOperation === "add" ? "SAVE" : "UPDATE"}
                        </SaveButton>

                        <Button
                          size="medium"
                          className={classes.margin}
                          onClick={handleCancelButtonClick}
                        >
                          CANCEL
                        </Button>
                      </div>

                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManageItemCategoryRateTemplateTab);
