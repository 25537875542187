import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    primaryColor,
    bottomNavigationFontColor,
    dailyRateScreenTextColor,
    lightGreyColor,
} from "../../../styles/colors";
// import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Lock from "@material-ui/icons/Lock";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from "moment"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { pxToEm } from "../../../methods";
import TileSkeleton from "../common/TileSkeleton";

const styles = {
    quantityShowContainer: {
        display: "flex",
        justifyContent: "center",
    },
    quantityShow: {
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
    },
    quantity: {
        width: "44%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    quantityText: {
        color: dailyRateScreenTextColor,
        fontSize: "1.25em",
    },
    formControlContainer: {
        width: "100%",
        marginTop: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    orderNameMenuContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "10px 0px",
        fontSize: "0.8em",
        color: lightGreyColor,
        fontFamily: 'Poppins'
    },
    orderIdShowContainer: {
        alignItems: "flex-start",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        textOverflow: "ellipsis",
        marginLeft: "5px"
    },
    verticalLineStyle: {
        height: "2.3vh",
        width: "1px",
        background: bottomNavigationFontColor,
        margin: "0px 6px",
    },
    orderQuantityShowContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    textFieldStyle: {
        width: "100%",
    },
    formControlStyle: {
        margin: "20px 0px 6px 0px",
        width: "100%",
    },
    textFieldQuantity: {
        width: "35%",
        margin: "0px",
    },
    lockIconContainer: {
        width: window.innerWidth < 375 ? "5%" : "10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
};

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null

}
class AddOrderAndItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrder: ""
        };
        // this.inpRef1 = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.selectedOrder, this.props.location?.state?.type, this.props.orders)
        if (this.props.location?.state?.type && (prevProps.selectedOrder !== this.props.selectedOrder)) {
            this.props.fetchItemCategoriesPaymentDiscount(this.props?.selectedOrder?.item_category?.id)
        }
    }

    render() {
        const { capacityError,
        } = this.state;
        const {
            classes,
            isMobile,
            orders,
            ordersListLoader,
            items,
            setItemQuantity,
            setItem,
            setOrderId,
            openBuyerListPopper,
            truckCapacity,
            selectedOrder,
            location,
            subContractNumber,
            fetchItemCategoriesPaymentDiscount
        } = this.props;
        // console.log(location, subContractNumber)
        return (
            <FormControl fullWidth>
                <InputLabel
                    id="demo-simple-select-label"
                    style={{ fontSize: window.innerWidth < 375 && pxToEm(14) }}
                >
                    Select Contract
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedOrder?.order_number || ""}
                    label="Select Contract"
                    MenuProps={MenuProps}
                    disabled={location?.state?.type ||
                        location?.state?.mode === 'edit' && Boolean(subContractNumber)
                    }
                    style={{ fontSize: window.innerWidth < 375 && pxToEm(15), }}
                // onChange={() => this.props.handleOrderSelect(data)}
                >
                    {ordersListLoader
                        ? <TileSkeleton skeletonType='selectOrder' tileLength={6} />
                        : orders && orders.length > 0 ?
                            orders.map((data, i) => (
                                <MenuItem
                                    key={data.order_number}
                                    value={data.order_number}
                                    component="div"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (data.is_fifo_allowed || i === orders.length - 1) {
                                            // this.props.setOrderIdAndAmount(data.order_number);
                                            this.props.handleOrderSelect(data)
                                            console.log(data)
                                            this.props.fetchItemCategoriesPaymentDiscount(data.item_category?.id)
                                            // this.props.fetchPlantsList(data)
                                            this.props.handleClosePopper();
                                        }
                                    }}
                                    disabled={!(data.is_fifo_allowed || i === orders.length - 1) ||
                                        (location?.state?.mode === 'edit' && data?.subcontract_enabled === true)
                                    }
                                    style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                                >
                                    <div style={{ width: "100%" }}>
                                        <div className={classes.orderNameMenuContainer}>
                                            <div className={classes.lockIconContainer}>
                                                {(location?.state?.mode === 'edit' && data?.subcontract_enabled === true)
                                                    ? <CheckCircleOutlinedIcon style={{ marginRight: "5px" }} />
                                                    : (data.is_fifo_allowed || i === orders.length - 1) ? null : (
                                                        <Lock style={{ marginRight: "5px" }} />
                                                    )}
                                            </div>

                                            <div
                                                style={{
                                                    width: window.innerWidth < 375 ? "65%" : "60%",
                                                    color: 'black'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div
                                                        className={classes.orderIdShowContainer}
                                                    >
                                                        <span style={{ whiteSpace: 'break-spaces' }}>
                                                            {data?.item_category?.category_name}
                                                            {data.tag ? ' (' + data.tag + ')' : ""} | {" "}
                                                            {moment(data.created_at).format("DD-MMM-yyyy")}
                                                        </span>
                                                        <span>Rate: ₹{parseFloat(data?.net_rate).toFixed(2)}</span>
                                                        <span>{data.order_number}</span>


                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ width: "30%", color: 'black' }}>
                                                <div
                                                    className={
                                                        classes.orderQuantityShowContainer
                                                    }
                                                >
                                                    {/* <span>{data.pending_qty}</span> */}
                                                    <span>{data.order_available_quantity} MT</span>
                                                    <span style={{ fontSize: "0.8em" }}>
                                                        Balance
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider variant="middle" />
                                    </div>
                                </MenuItem>
                            )) : null
                    }
                </Select>
            </FormControl>
        );
    }
}
export default withStyles(styles)(AddOrderAndItem);
