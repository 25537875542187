import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import OrdersList from "./OrdersList";
import Button from "@material-ui/core/Button";
import { secondaryColor } from "../../../styles/colors";
import Chip from "@material-ui/core/Chip";

const styles = {
  ordersMainBody: {
    height: "95%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ordersNavigationStyle: {
    width: "90%",
    height: "56px",
    margin: "30px 0px 30px 0px",
    display: "flex",
    alignItems: "center",
  },
  orderListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: window.innerHeight - 200,
    height: window.innerHeight - 200,
    overflowY: "scroll",
  },
  buttonStyle: {
    textTransform: "capitalize",
    fontSize: "1.2em",
    marginRight: "15px",
  },
  myOrderCircleStyle: {
    width: "8px",
    background: "black",
    height: "8px",
    borderRadius: "50%",
    marginLeft: "10px",
    marginTop: "-8px",
  },
};
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerOrderShow: true,
      dealerOrders: [
        {
          status: "pending",
          orderId: "98##KK",
          date: "24/oct/2015",
          name: "Real Wire",
          weight: "30",
        },
        {
          status: "cancelled",
          orderId: "98##GH",
          date: "24/oct/2015",
          name: "Real Wire",
          weight: "130",
        },
        {
          status: "action",
          orderId: "98##OO",
          date: "24/oct/2015",
          name: "Real Wire",
          weight: "200",
        },
      ],
    };
  }
  render() {
    const { myOrders, dealerOrders, dealerOrderShow } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.ordersMainBody}>
        <div className={classes.ordersNavigationStyle}>
          <div>
            <Button
              className={classes.buttonStyle}
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/orders");
              }}
            >
              My Contracts
            </Button>
            {myOrders ? <div className={classes.myOrderCircleStyle} /> : null}
          </div>
          <div>
            <Button
              className={classes.buttonStyle}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Dealer Contracts
            </Button>
            {dealerOrderShow ? (
              <div className={classes.myOrderCircleStyle} />
            ) : null}
          </div>
        </div>
        <div style={{ width: "90%", marginBottom: "10px" }}>
          <Chip
            label="All"
            style={{
              width: "25%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
              backgroundColor: secondaryColor,
              color: "white",
              fontWeight: "bold",
            }}
          />
          <Chip
            label="Ongoing"
            style={{
              width: "25%",
              marginLeft: "6%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
            }}
          />
          <Chip
            label="Pending"
            style={{
              width: "25%",
              marginLeft: "6%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
            }}
          />
        </div>
        <div className={classes.orderListContainer}>
          {dealerOrders &&
            dealerOrders.map((data) => (
              <OrdersList
                key={data.orderId}
                status={data.status}
                orderId={data.orderId}
                date={data.date}
                name={data.name}
                weight={data.weight}
                dealerOrders={true}
              />
            ))}
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Orders));
