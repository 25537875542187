import HOST from "../common/host";

const APIROUTES = {
  // Users and Permissions Routes
  ROLES_AND_PERMISSIONS:
    HOST + "api/users-and-permissions/roles-and-permissions/",
  ROLE: HOST + "api/users-and-permissions/roles/",
  PERMISSIONS: HOST + "api/users-and-permissions/permissions/",
  WIDGETS: HOST + "api/users-and-permissions/widgets/",
  IPS_BULK: HOST + "api/users-and-permissions/white-listed-ips/bulk/",
  IPS_DELETE: HOST + "api/users-and-permissions/white-listed-ips/",
  MODULES_TO_ROLE: HOST + "api/users-and-permissions/modules-to-roles/",
  ROLE_TO_A_USER: HOST + "api/users-and-permissions/roles-to-users/",
  GET_ALL_INTERNAL_USERS: HOST + "api/users-and-permissions/internal-users/",
  DAILY_RATES_STATE: HOST + "api/masters/regions/",
  MASTER_STATE_UPDATE: HOST + "api/masters/regions/",
  ADD_MASTER_STATE: HOST + "api/masters/regions/",
  DELETE_MASTER_STATE: HOST + "api/masters/regions/",
  GET_ALL_ACCESS_AREA:
    HOST +
    "api/users-and-permissions/access-area-management/?ordering=module_name",
  POST_INTERNAL_USER: HOST + "api/registration/user/",
  INTERNAL_USER: HOST + "api/users/user/",
  UPDATE_INTERNAL_USER: HOST + "api/users/",
  GET_INTERNAL_USER: HOST + "api/users/detail/",
  PUT_INTERNAL_USER_CREDENTIAL: HOST + "api/users/credential/",
  REMOVE_INTERNAL_USER: HOST + "api/users/",
  PUT_INTERNAL_USER_PERSONAL: HOST + "api/users/user/",
  DAILYRATES: HOST + "api/daily-rates/",
  DAILY_RATE_TEMPLATE_GET: HOST + "api/masters/item-categories/list/",
  DAILY_RATE_TEMPLATE_DETAILS: HOST + "api/masters/daily-rates-template/",
  DAILY_RATE_TEMPLATE_CREATE: HOST + "api/masters/daily-rates-template/create/",
  DAILY_RATE_TEMPLATE_DELETE: HOST + "api/masters/daily-rates-template/detail/",
  LINKED_ITEM: HOST + "api/masters/linkeditem/",
  LINKED_ITEM_UPADTE_DELETE: HOST + "api/masters/linkeditem/update-delete/",
  MM_CREATE_NEW_CATEGORY: HOST + "api/masters/item-categories/",
  MM_DELETE_NEW_CATEGORY_RATE_LINKING: HOST + "api/masters/item-categories/rate_difference/",
  MM_ITEM_TYPE_GET: HOST + "api/masters/items/?category_id=",
  MM_ITEM_TYPE_POST: HOST + "api/masters/items/",
  MM_ITEM_TYPES_POST: HOST + "api/masters/item-categories/items/",
  MM_ITEM_TYPE_DELETE: HOST + "api/masters/items/",
  MM_ITEM_TYPE_EDIT: HOST + "api/masters/items/bulk/",
  GET_ALL_ITEMS_MASTERS: HOST + "api/masters/items/",
  DAILYRATELIVERATE: HOST + "api/daily-rates/live-rates/",
  DAILYRATEOPENRATE: HOST + "api/daily-rates/open-rates/",
  DAILYRATEPUBLISH: HOST + "api/daily-rates/bulk/",
  DAILY_RATE_UNPUBLISH: HOST + "api/daily-rates/bulk/",
  DAILYRATEPASTRATE: HOST + "api/daily-rates/past-rates/",
  DAILYRATEPASTRATESINGLESELECTION:
    HOST + "api/daily-rates/past-rates/single-selection/",
  DELETE_STATE_FROM_MASTER: HOST + "api/masters/states/",
  CUSTOMERS_GET: HOST + "api/customers/",
  CUSTOMERS_CREDIT_LIMIT_SYNC: HOST + "api/customers/effective_credit/",
  CUSTOMER_TYPES: HOST + "api/masters/customer_types/",
  CUSTOMER_TAGS: HOST + "api/masters/tags/",
  CUSTOMERS_GET_REMOTE: HOST + "api/customers/list-pagination/",
  CUSTOMERS_SEARCH_REMOTE: HOST + "api/customers/list-search/",
  GET_ALL_CUSTOMERS_LIST: HOST + "api/customers/list/",
  CUSTOMERS_SUBSCRIPTION_GET: HOST + "api/customers/customer-subscription/",
  CUSTOMERS_DEALERS_GET: HOST + "api/customers/dealers/",
  DELETE_CUSTOMER_DEALER_DISTRIBUTOR: HOST + "api/customers/dealers/",
  VERIFY_GST: HOST + "api/registration/check/gst/",
  POST_CUSTOMER: HOST + "api/registration/customer/",
  DELETE_CUSTOMER: HOST + "api/customers/",
  GET_STATE_AND_CITY: HOST + "api/masters/pincode/pincode_detail/",
  POST_DEALER_DISTRIBUTOR_IN_CUSTOMER: HOST + "api/customers/dealers/create/",
  GET_DEALER_DISTRIBUTOR: HOST + "api/customers/dealers/",
  GET_BROKER: HOST + "api/customers/customer_list/to_create_buyer/broker_type/",
  CREATE_CUSTOMER_SUBSCRIPTION:
    HOST + "api/customers/customer-subscription/create/",
  GET_BROKERAGE_ITEM_CATEGORIES_LIST: HOST + "api/customers/customer-brokerage/",
  DELETE_DISCOUNT_ITEM_CATEGORIES_LIST: HOST + "api/customers/customer-discount/",
  DELETE_CUSTOMER_SUBSCRIPTION: HOST + "api/customers/customer-subscription/",
  UPDATE_CUSTOMER: HOST + "api/customers/",
  CHANGE_PASSWORD: HOST + "api/auth/change-password/",
  GET_ALL_CUSTOMER_SUBSCRIPTION: HOST + "api/customers/customer-subscription/",
  CUSTOMER_ADDITIONAL_ADDRESSES: HOST + "api/customers/customer-addresses/",
  CUSTOMER_ADDITIONAL_CONTACT_PERSON: HOST + "api/customers/contact-person/",
  CUSTOMER_ALLOWED_PAYMENT_METHOD: HOST + "api/customers/payment-method/",
  CUSTOMER_ADD_EXTRA_NUMBER: HOST + "api/customers/extra-phones/",
  GET_ITEM_CATEGORIES_LIST: HOST + "api/masters/item-categories/list/",
  FREIGHT_BASIS: HOST + "api/masters/freightbasis/",
  GET_STATES_LIST: HOST + "api/masters/states/",
  GET_STATES_LIST_DETAIL: HOST + "api/masters/states/detail/",
  GET_CITY_LIST: HOST + "api/masters/cities/",
  GET_CITY_LIST_DETAIL: HOST + "api/masters/cities/detail/",
  CUSTOMER_ADDITIONAL_CONTACT_PERSON: HOST + "api/customers/contact-person/",
  CUSTOMER_ALLOWED_PAYMENT_METHOD: HOST + "api/customers/payment-method/",
  GET_ALL_ALLOWED_PAYMENT_METHOD: HOST + "api/masters/payment-method/",
  GET_CUSTOMER_BUYERS: HOST + "api/customers/buyer/",
  GET_CUSTOMER_CONSIGNEE: HOST + "api/customers/consignee/",
  GET_CUSTOMER_ALL_CONSIGNEE_LIST:
    HOST + "api/customers/list-of-addresses-to-create-consignee/",
  CREATE_CUSTOMER_BUYER: HOST + "api/customers/buyer/",
  CREATE_CUSTOMER_CONSIGNEE: HOST + "api/customers/consignee/",
  DELETE_CUSTOMER_BUYER: HOST + "api/customers/buyer/",
  DELETE_CUSTOMER_CONSIGNEE: HOST + "api/customers/consignee/",
  CUSTOMER_RESET_PASSWORD: HOST + "api/auth/reset-password/",
  GET_ALL_TAGS_MASTERS_LIST: HOST + "api/masters/tags/",
  GET_ALL_PLANT_ITEM_MASTER_LIST: HOST + "api/masters/plants/",
  ITEMS_IN_PLANT_MASTER: HOST + "api/masters/plants/item_category/",
  UPDATE_ITEMS_IN_PLANT_MASTER: HOST + "api/masters/plants/item_category/update/",
  UPDATE_REMOVE_ITEMS_NATURE_IN_PLANT_MASTER: HOST + "api/masters/plants/item_nature/update_delete/",
  LINK_ITEM_NATURE: HOST + "api/masters/plants/item_nature/",
  LINK_ITEM_NATURE_LIST: HOST + "api/masters/plants/item_nature/list/",
  CUSTOMER_ADDITIONAL_ADDRESSES: HOST + "api/customers/customer-addresses/",
  CUSTOMER_CLIENT_SEARCH: HOST + "api/customers/search-customers/",
  CUSTOMER_CLIENT_CONSIGNEE_SEARCH: HOST + "api/customers/search-consignee/",
  CONTRACTS_CLIENT_SEARCH: HOST + "api/contracts/search-customers/",
  CONTRACTS_PAYMENT_METHOD: HOST + "api/contracts/payment-methods/",
  GET_TRANS_TYPE_MASTER: HOST + "api/masters/trantype/",
  CONTRACTS_ITEM_CATEGORIES: HOST + "api/contracts/item-categories/",
  CONTRACTS: HOST + "api/contracts/",
  CONTRACTS_CREATE: HOST + "api/contracts/create/",
  CONTRACTS_DATA_FETCH: HOST + "api/contracts/retrieve/",
  CONTRACTS_BROKER: HOST + "api/contracts/brokers/",
  CONTRACTS_BUYER: HOST + "api/contracts/buyers/",
  CONTRACTS_CONSIGNEE: HOST + "api/contracts/consignee/",
  PLANTS_FOR_ITEM_CATEGORY: HOST + "api/contracts/plants/",
  CONTRACTS_LIST: HOST + "api/contracts/list/?ordering=-created_at",
  GET_PENDING_CONTRACTS: HOST + "api/contracts/list/",
  GET_SEARCH_CONTRACTS: HOST + "api/contracts/list/search/",
  CONTRACTS_DROPDOWN_LIST: HOST + "api/contracts/dropdown/list/",
  GET_CONTRACT_MODIFICATION_DETAILS: HOST + "api/contracts/get/edit_logs/?",
  CONTRACT_MODIFICATION_CONFORMATION: HOST + "api/contracts/approve/edit_request/",
  CONTRACT_SHORT_CLOSE: HOST + "api/contracts/short_close/",
  CONTRACT_CONVERSION_ITEM_CATEGORIES: HOST + "api/contracts/conversion/item_category/",
  CONTRACT_CONVERSION_ORDER: HOST + "api/contracts/conversion/order/",
  DAILY_RATE_LIVE_RATE_V2: HOST + "api/daily-rates/v2/live-rates/",
  DAILY_RATE_OPEN_RATE_V2: HOST + "api/daily-rates/v2/open-rates/",
  DAILY_RATE_PUBLISH_V2: HOST + "api/daily-rates/v2/bulk/",
  DAILY_RATE_PAST_RATE_V2: HOST + "api/daily-rates/v2/past-rates/",
  DAILY_RATE_PAST_RATE_SINGLE_SELECTION_V2:
    HOST + "api/daily-rates/v2/past-rates/single-selection/",
  DAILY_RATE_UNPUBLISH_V2: HOST + "api/daily-rates/v2/bulk/",
  GET_APP_BANNER: HOST + "api/masters/news/",
  GET_APP_BANNER_DETAIL: HOST + "api/masters/news/",
  GET_APP_VERSION: HOST + "api/masters/appdetails/",
  GET_ITEM_SIZE: HOST + "api/masters/itemsize/",
  GET_REASON: HOST + "api/masters/reason/",
  GET_CUSTOMER_TYPE: HOST + "api/masters/customer_types/",
  GATES_ADD_IN_PLANT: HOST + "api/masters/gate/",
  GATES_UPDATE_IN_PLANT: HOST + "api/masters/gate/",
  DELETE_GATE: HOST + "api/masters/gate/",
  GET_GATES_IN_PLANT: HOST + "api/masters/gate/?plant_id=",
  GET_WEIGHBRIDGE: HOST + "api/masters/weighbridge/?plant_id=",
  WEIGHBRIDGE_ADD_IN_PLANT: HOST + "api/masters/weighbridge/",
  UPDATE_WEIGHBRIDGE_IN_PLANT: HOST + "api/masters/weighbridge/",
  DELETE_WEIGHBRIDGE: HOST + "api/masters/weighbridge/",
  GET_DISPATCH_COUNTER: HOST + "api/masters/dispatchcounter/?plant_id=",
  ADD_DISPATCH_COUNTER: HOST + "api/masters/dispatchcounter/",
  UPDATE_DISPATCH_COUNTER: HOST + "api/masters/dispatchcounter/",
  DELETE_DISPATCH_COUNTER: HOST + "api/masters/dispatchcounter/",
  GET_LA_DETAILS: HOST + "api/dispatch/loading-advice/",
  PDF_UPLOAD: HOST + "api/dispatch/send_invoice/pdf/",
  DISPATCH_TABLE_PDF: HOST + "api/dispatch/get/all/orders/date/",
  GET_MORE_LA_DETAILS: HOST + "api/contracts/loading-advice/",
  GET_MORE_LA_LOGS_DETAILS: HOST + "api/contracts/loading-advice/logs/?",
  GET_LA_MODIFICATION_DETAILS: HOST + "api/contracts/loading-advice/edit_logs/?",
  GET_LA_MODIFICATION_ACCESSIBLE: HOST + "api/contracts/loading-advice/approve/edit_request/",
  DISACRD_WEIGH_SLIPS: HOST + "api/dispatch/discard/weight/detail/",
  UNLOAD_WEIGH_SLIPS: HOST + "api/dispatch/unload/weight/detail/",
  SYNC_LA_PAYMENT: HOST + "api/dispatch/check/payment/status/",
  GET_LA_WEEK_DETAILS: HOST + "api/contracts/loading_advice_datewise/date/",
  ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT:
    HOST + "api/masters/item-categories/payment-method-wise-discount/",
  ITEM_CATEGORY_CITY_DISCOUNT_DELETE:
    HOST + "api/masters/item-categories/city-wise-discount/",
  ITEM_CATEGORY_CITY_DISCOUNT:
    HOST + "api/masters/item-categories/city-wise-discount/",
  GET_GATES: HOST + "api/dispatch/get/gate/?ip_address=",
  GET_WEIGHBRIDGE_IN_DISPATCH_OPERATION:
    HOST + "api/dispatch/get/weighbridge/?ip_address=",
  CONTRACTS_PLANT_PLANNING: HOST + "api/contracts/plantplanning/",
  CONTRACTS_LOADING_ADVICE_LIST: HOST + "api/contracts/loading-advice/list/",
  LA_LIST_DATEWISE_LOADING_ADVICE_MODULE: HOST + "api/contracts/loading-advice/list/datewise/",
  ITEM_CATEGORY_MASTER_ITEMS: HOST + "api/masters/item-categories/items/",
  UPDATE_LA_STATUS_GATE_IN: HOST + "api/dispatch/update/loading-advice-status/",
  FETCH_LA_DETAILS_IN_WEIGHBRIDGE:
    HOST + "api/dispatch/get/loading-advice/weighbridge/",
  FETCH_LA_DETAILS_IN_DISPATCH_COUNTER:
    HOST + "api/dispatch/get/loading-advice/dispatch-counter/",
  ORDER_PLANT_PLANNING: HOST + "api/contracts/plant-planning/",
  LOADING_ADVICE_CREATE: HOST + "api/contracts/loading-advice/create",
  LOADING_ADVICE_UPDATE: HOST + "api/contracts/loading-advice/update/detail/",
  TRANSPORTERS: HOST + "api/masters/transporter/",
  GET_DISPATCH_COUNTERS: HOST + "api/dispatch/get/dispatch-counter/",
  BANK_MASTER: HOST + "api/masters/bank/",
  FETCH_DISPATCH_PLANING: HOST + "api/dispatch/get/orders/?",
  FETCH_DISPATCH_PLANING_READY_QTY: HOST + "api/dispatch/get/plant/customer/ready_qty?",
  CREATE_DISPATCH_PLANING_READY_QTY: HOST + "api/dispatch/create/ready_quantity/",
  UPDATE_DISPATCH_PLANING_READY_QTY: HOST + "api/dispatch/update/ready_quantity/",
  GET_SEARCH_DISPATCH_PLANING: HOST + "api/dispatch/get/contract/search/?",
  GET_SEARCH_DISPATCH_PLANING_CUSTOMER: HOST + "api/dispatch/get/dispatch_planning/customer/search/?",
  ITEM_CATEGORY_MASTER_ITEM_SECTION:
    HOST + "api/masters/item-categories/item-section/",
  UPDATE_LA_STATUS_IN_DISPATCH_PLANING:
    HOST + "api/dispatch/update/loading-advice-approval/",
  UPDATE_WEIGHT_ALLOTMENT:
    HOST + "api/dispatch/update/loading-advice/weight-allotment/",
  CONTRACTS_UPDATE: HOST + "api/contracts/update/",
  CONTRACTS_REJECT: HOST + "api/contracts/reject/",
  CONTRACTS_LA_ITEMS_LIST: HOST + "api/contracts/loading-advice-item/list/",
  TDS_RATE: HOST + "api/masters/tdsrate/",
  TRANSPORTER: HOST + "api/masters/transporter/",
  TCS: HOST + "api/masters/tcs/",
  TCSMATRIX: HOST + "api/masters/tcs_matrix/",
  TCSMATRIXSINGLE: HOST + "api/masters/tcs-matrix/",
  TCSRATES: HOST + "api/masters/tcs/",
  UNIT: HOST + "api/masters/unit/",
  UPDATE_WEIGHING: HOST + "api/dispatch/update/weighbridge-details/",
  PINCODE: HOST + "api/masters/pincode/",
  GET_PINCODE: HOST + "api/masters/pincode-search/",
  GET_CITY: HOST + "api/masters/cities/search/",
  GET_TCS_RATE: HOST + "api/contracts/la/tcs_rate/",

  // sub-contracts
  SUB_CONTRACT_ADD: HOST + "api/contracts/sub-contract/",
  ENABLE_SUB_CONTRACT: HOST + "api/contracts/sub-contract/enable/",
  GET_SUBCONTRACTS: HOST + "api/contracts/sub-contract/plant/list/",
  GET_ITEMS_PER_TAGS: HOST + "api/contracts/tag/list/items/",
  UPDATE_SUB_CONTRACT: HOST + "api/contracts/sub-contract/update/",
  CANCEL_SUB_CONTRACT: HOST + "api/contracts/sub-contract/cancel/",
  REOPEN_SUB_CONTRACT: HOST + "api/contracts/sub-contract/re-open/",
  GET_SUB_CONTRACT_ITEMS: HOST + "api/contracts/sub-contract/master/items/",
  GET_SUBCONTRACT_VCL_LIMIT_CUSTOMER: HOST + "api/customers/sub-contracts/virtual_credit/",
  GET_SUBCONTRACT_PDF: HOST + "api/contracts/sub-contract/pdf/",

  // Dispatch
  GET_VR_NUM_FROM_ERP: HOST + "api/dispatch/get/vrno_from_erp/",
  POST_INVOICE_INTO_ERP: HOST + " api/dispatch/create/invoice/",
  CREATE_LOADING_ADVICE_ITEM:
    HOST + "api/contracts/loading-advice-item/create/",
  UPDATE_LOADING_ADVICE_ITEM:
    HOST + "api/contracts/loading-advice-item/update/",
  DELETE_LOADING_ADVICE_ITEM:
    HOST + "api/contracts/loading-advice-item/get_or_delete/",
  CHANGE_LA_STATUS: HOST + "api/dispatch/roll_back/status/",
  LA_REPLACEMENT: HOST + "api/dispatch/eway_cancel/detail/",
  LA_CANCEL_AFTER_BILLED: HOST + "api/dispatch/eway_cancel/unreplaced/",
  UNLOCK_WEIGHBRIDGE: HOST + "api/dispatch/weighbridge-unlocked/",
  RANDOM_WEIGHT_WEIGHBRIDGE: HOST + "api/dispatch/weight-check/",
  // Reports
  GET_INVOICES: HOST + "api/erp/get/la/invoices/",
  GET_EWAY_INVOICES: HOST + "api/erp/get/eways/?",
  LOADING_ADVICE_APPROVE: HOST + "api/dispatch/update/loading-advice-approval/",
  GET_PLANT: HOST + "api/dispatch/get/plant/",
  GENERATE_E_INVOICE_AND_E_WAY:
    HOST + "api/dispatch/create/e-invoice-and-e-way/",
  GENERATE__E_WAY: HOST + "api/dispatch/create/e-way/",
  CHECK_STOCK_BEFORE_BILLING: HOST + "api/dispatch/check/stock/",
  ITEM_NATURE: HOST + "api/masters/item-nature/",
  GET_ITEM_CATEGORY: HOST + "api/masters/item-categories/list/",
  GET_CONTRACT_REPORTS: HOST + "api/contracts/reports/",
  CUSTOMER_SEARCH_REPORTS: HOST + "api/customers/report/list/",
  GET_DISPATCH_DETAILS_REPORTS: HOST + "api/dispatch/get/dispatch-details/",
  GET_WEIGHBIDGE_AND_GATE_REPORTS: HOST + "api/dispatch/get/gate-weighbridge-report/",
  GET_EXCEPTION_REPORTS: HOST + "api/customers/log_report/",
  GET_SUBCONTRACT_REPORTS: HOST + "api/contracts/report/subcontract/",
  GET_SUBCONTRACT_CSV_REPORTS: HOST + "api/contracts/report/csv/subcontract/",
  GET_DYNAMIC_REPORTS_MODULE_FIELD: HOST + "api/dynamic_report/module-fields/",
  GET_DYNAMIC_REPORTS_FILTER_TYPES: HOST + "api/dynamic_report/filter-types/",
  GET_DYNAMIC_REPORTS_SAVED_TEMPLATE: HOST + "api/dynamic_report/report-templates/",
  GET_DYNAMIC_REPORTS_ACTIVE_TEMPLATE: HOST + "api/dynamic_report/active-template/",
  POST_DYNAMIC_REPORTS: HOST + "api/dynamic_report/fetch-report/",
  CANCEL_RESET_DYNAMIC_REPORTS: HOST + "api/dynamic_report/cancel-request/",
  SAVE_TEMPLATE_DYNAMIC_REPORTS: HOST + "api/dynamic_report/save-template/",
  APPLY_TEMPLATE_DYNAMIC_REPORTS: HOST + "api/dynamic_report/template-detail/",
  PINNED_TEMPLATE_DYNAMIC_REPORTS: HOST + "api/dynamic_report/pinned-template/",
  UNPINNED_TEMPLATE_DYNAMIC_REPORTS: HOST + "api/dynamic_report/unpinned-template/",
  DELETE_TEMPLATE_DYNAMIC_REPORTS: HOST + "api/dynamic_report/delete-template/",
  // Customers
  POST_BROKERAGE_ITEMCAT: HOST + "api/customers/customer-brokerage/create/",
  POST_DISCOUNT_ITEMCAT: HOST + "api/customers/customer-discount/create/",
  GET_EWAY_DATA_TRACKING: HOST + "api/erp/get/ewaybill/tracking/",
  UPDATE_EWAY_DATA_TRACKING: HOST + "api/erp/update/ewaybill/tracking/",
  GET_EWAY_DATA_DISTANCE_TRACKING: HOST + "api/erp/distance/",
  ERP_EWAY_GENERATE: HOST + "api/erp/generate-e-way/",
  GET_ERP: HOST + "api/erp/",
  GET_ERP_GENERATE_IRN: HOST + "api/erp/generate-eway-using-irn/",
  EINVOICE_GENERATE: HOST + "api/erp/generate-e-invoice/",
  EINVOICE_EWAY_GENERATE: HOST + "api/erp/generate-einvoice-and-eway/",
  BROKERAGE_ITEM_GET: HOST + "api/customers/customer-brokerage/",
  GET_E_INVOICE_AND_E_WAY: HOST + "api/dispatch/get/eway_creation/detail/",
  CHECK_PENDING_QUANTITY: HOST + "api/dispatch/calculations/",
  CHANGE_STATUS_NORMAL_INVOICE: HOST + "api/dispatch/update/loading_advice_status/billed/",
  DELETE_EXTRA_PHONE_NUMBER: HOST + "api/customers/extra-phones-delete/",
  CUSTOMER_EXTRA_PHONES: HOST + "api/customers/extra-phones/",
  GET_COUNTRY_CODES: HOST + "api/masters/countries/",
  GET_ALL_PAYMENT_METHODS: HOST + "api/masters/payment-method/",
  GET_ITEM_CATEGORIES_ITEM_SIZE: HOST + "api/masters/dropdown/itemsize/",
  GET_CUSTOMER_DISCOUNT: HOST + "api/customers/customer-discount/",
  // Dashboard
  GET_DISPATCH_QTY: HOST + "api/dispatch/dashboard/",
  // Ledger Request
  GET_LEDGER_REQUESTS: HOST + "api/ledger/requests/all/",
  VIEW_LEDGER_PDF: HOST + "api/ledger/erp/ledger_view/",
  DOWNLOAD_LEDGER_PDF: HOST + "api/ledger/erp/ledger/download/",
  APPROVE_LEDGER_REQ: HOST + "api/ledger/requests/approve/",
  RESET_LEDGER_REQ: HOST + "api/ledger/ledger_reset/",
  GET_LEDGER_COUNT: HOST + "api/ledger/ledger_requests/count/",
  GET_APP_LEDGER_PDF: HOST + "api/ledger/customer/download/ledger_view/",
  // Dashboard
  SALES_DATA: HOST + "api/dashboard/sales-data/",
  MONTHWISE_PLANTWISE_DATA: HOST + "api/dashboard/sales-graph-data/",
  TODAY_TOTAL_DATA: HOST + "api/dashboard/today-data/",
  ORDER_BOOKING_ITEMWISE_DATA: HOST + "api/dashboard/order-itemwise-data/",
  ORDER_BOOKING_CUSTOMERTYPE_DATA: HOST + "api/dashboard/order-customertype-data/",
  ORDER_BOOKING_CUSTOMERGROUP_DATA: HOST + "api/dashboard/order-customergroup-data/",
  ORDER_BOOKING_CONSIGNEEDATA_DATA: HOST + "api/dashboard/order-consignee-data/",
  LA_BOOKING_ITEMWISE_DATA: HOST + "api/dashboard/la-itemwise-data/",
  LA_BOOKING_CUSTOMERTYPE_DATA: HOST + "api/dashboard/la-customertype-data/",
  LA_BOOKING_CUSTOMERGROUP_DATA: HOST + "api/dashboard/la-customergroup-data/",
  LA_BOOKING_CONSIGNEEDATA_DATA: HOST + "api/dashboard/la-consignee-data/",
  ORDER_BOOKING_DATA: HOST + "api/dashboard/order-booking-data/",
  ORDER_BOOKING_GRAPH_DATA: HOST + "api/dashboard/order-booking-graph-data/",
  ORDER_BOOKING_WEIGHTED_DATA: HOST + "api/dashboard/order-booking-weighted-data/",
  LA_BOOKING_WEIGHTED_DATA: HOST + "api/dashboard/la-booking-weighted-data/",
  CUSTOMER_BROKER_DATA: HOST + "api/dashboard/customer-broker-data/",
  CUSTOMER_GRAPH_DATA: HOST + "api/dashboard/customer-graph-data/",
  BROKER_GRAPH_DATA: HOST + "api/dashboard/broker-graph-data/",
  DELIVERED_BILLED_STATE_DATA: HOST + "api/dashboard/deliver-bill-state-data/",
  SALES_PERSON_DATA: HOST + "api/dashboard/sales-person-data/",
  SALES_PERSON_GRAPH_DATA: HOST + "api/dashboard/sales-person-graph-data/",
  ITEM_CATEGORY_SALES_DIFFERENCES_DATA: HOST + "api/dashboard/sales-club-item-data/",
  IN_PLANT_DATA: HOST + "api/dashboard/in-plant-data/",
  // Fetch Sales Return
  FETCH_SALES_RETURN_LA: HOST + "api/dispatch/get/sales_return/"
};
export default APIROUTES;
