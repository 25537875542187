import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Input from "@material-ui/icons/Input";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Notifications from "@material-ui/icons/NotificationsNone";
import Badge from '@mui/material/Badge';
import { webPrimaryColor } from "../../../../styles/colors";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../../styles/colors";
import withUser from "../../../../hoc/withUser";
import withAuthentication from "../../../../hoc/withAuthentication";
import NotificationList from "../../NotificationList";
import { withRouter } from "react-router";
import PersonOutline from "@material-ui/icons/PersonOutline";

const styles = {
  listItemTextStyle: {
    marginLeft: "20px",
    fontSize: "0.8em",
  },
  clearAllButton: {
    textTransform: "capitalize",
    color: primaryColor,
    fontWeight: "bold",
  },
  avatarStyle: {
    height: "33px",
    width: "43px",
    borderRadius: "23%",
    backgroundColor: primaryColor,
  },
  listItemStyle: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "4px"
  },
  popperStyle: {
    padding: "16px",
    display: "flex",
    // alignItems: "center",
    flexDirection: "column"
  }

};
class AppBarWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsLoader: true,
      notifications: [],
      dates: [],
    };
  }

  componentWillMount() {
    this.getProps(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.getProps(nextProps);
  }

  getProps = (currentProps) => {
    this.setState(
      {
        notifications: currentProps.userNotifications,
        dates: currentProps.dates,
        notificationsLoader: false,
        unreadNotifications: currentProps.unreadNotifications,
      }
      // () => this.fetchdates(currentProps.userNotifications)
    );
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open, openLogout: false }));
    // this.props.fetchNotificationList()
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };
  handleToggleLogout = () => {
    this.setState((state) => ({ openLogout: !state.openLogout, open: false }));
  };
  handleCloseLogout = (event) => {
    if (this.anchorEl1.contains(event.target)) {
      return;
    }
    this.setState({ openLogout: false });
  };

  render() {
    const { open, openLogout, notifications, unreadNotifications } = this.state;
    // console.log(this.state)
    return (
      <div>
        <AppBar
          position="static"
          style={{
            position: "fixed",
            height: "80px",
            backgroundColor: webPrimaryColor,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                this.props.handleDrawer();
              }}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "30px",
              }}
            >
              <Button
                style={{ color: "white" }}
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                aria-owns={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  this.handleToggle()
                  this.props.fetchNotificationList()
                }}
              >
                <span style={{ display: "flex", justifyContent: "center" }}>
                  <Badge
                    badgeContent={
                      notifications && (notifications.filter(d => d.is_read === false)).length
                    }
                    sx={{
                      "& .MuiBadge-badge": {
                        color: webPrimaryColor,
                        backgroundColor: "white"
                      }
                    }}>
                    <Notifications />
                  </Badge>
                </span>
              </Button>
              <Button
                buttonRef={(node1) => {
                  this.anchorEl1 = node1;
                }}
                aria-haspopup="true"
                aria-owns={openLogout ? "menu-list-grow-logout" : undefined}
                color="inherit"
                style={{ textDecoration: "underline" }}
                onClick={this.handleToggleLogout}
              >
                {this.props.user && this.props.user.first_name}{" "}
                {this.props.user && this.props.user.last_name}
              </Button>
            </div>
          </Toolbar>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement={"bottom-end"}
            style={{ width: "35%" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} id="menu-list-grow">
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <div>
                      <NotificationList
                        {...this.props}
                        notifications={this.state.notifications}
                        dates={this.state.dates}
                        handleFetchMoreNotification={
                          this.props.handleFetchMoreNotification
                        }
                        handleNotificationClick={this.props.handleNotificationClick}
                      />
                    </div>
                  </ClickAwayListener>
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "20px",
                      height: "0px",
                      borderLeft: "5px solid transparent",
                      borderRight: "5px solid transparent",
                      borderBottom: "10px solid white",
                    }}
                  />

                </Paper>
              </Grow>
            )}
          </Popper>
          <Popper
            open={openLogout}
            anchorEl={this.anchorEl1}
            transition
            disablePortal
            placement={"bottom-end"}
            style={{ width: "150px", left: "100px" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} id="menu-list-grow-logout">
                <Paper>
                  <ClickAwayListener onClickAway={this.handleCloseLogout}>
                    <div style={styles.popperStyle}>
                      <div style={styles.listItemStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          // () => {
                          this.props.history.push("/b2bProfile");
                          // }
                        }}
                      >
                        <PersonOutline />
                        <span style={{ paddingLeft: "14px" }}>My Profile</span>
                      </div>
                      <div style={styles.listItemStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.logout();
                        }}
                      >
                        <Input />
                        <span style={{ paddingLeft: "14px" }}>Logout</span>
                      </div>
                    </div>
                  </ClickAwayListener>
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "20px",
                      height: "0px",
                      borderLeft: "5px solid transparent",
                      borderRight: "5px solid transparent",
                      borderBottom: "10px solid white",
                    }}
                  />
                </Paper>
              </Grow>
            )}
          </Popper>
        </AppBar>
      </div>
    );
  }
}
export default withUser(
  withAuthentication(withRouter(withStyles(styles)(AppBarWeb)))
);
