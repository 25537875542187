import React from "react";
export default class InvoiceSVG extends React.Component {
  render() {
    return (
      <svg
        style={{ fill: "white", height: "20px", width: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="12.564"
        height="16.459"
        viewBox="0 0 12.564 16.459"
      >
        <g transform="translate(0)">
          <path
            class="a"
            d="M62.642.426h0a.759.759,0,0,0-.825.125l-1.07.947L59.292.2a.77.77,0,0,0-1.027,0L56.811,1.5,55.356.2a.77.77,0,0,0-1.028,0L52.873,1.5,51.8.551a.765.765,0,0,0-1.274.577v14.2a.766.766,0,0,0,1.276.577l1.07-.947,1.455,1.3a.77.77,0,0,0,1.027,0l1.454-1.3,1.454,1.3a.77.77,0,0,0,1.028,0l1.456-1.3,1.071.947a.765.765,0,0,0,1.274-.577V1.128A.759.759,0,0,0,62.642.426ZM61.257,14.383a.769.769,0,0,0-1.024,0l-1.455,1.3-1.454-1.3a.77.77,0,0,0-1.027,0l-1.454,1.3-1.456-1.3a.766.766,0,0,0-1.021,0L51.3,15.33V13.276l0-12.147,1.068.947a.769.769,0,0,0,1.024,0l1.455-1.3,1.454,1.3a.77.77,0,0,0,1.027,0l1.454-1.3,1.456,1.3a.766.766,0,0,0,1.021,0l1.067-.947v9.763l0,4.439Z"
            transform="translate(-50.529 0)"
          />
        </g>
        <g transform="translate(2.243 7.844)">
          <g transform="translate(0)">
            <path
              class="a"
              d="M116.428,203.527h-7.307a.385.385,0,1,0,0,.771h7.307a.385.385,0,1,0,0-.771Z"
              transform="translate(-108.736 -203.527)"
            />
          </g>
        </g>
        <g transform="translate(2.243 5.532)">
          <g transform="translate(0)">
            <path
              class="a"
              d="M112.96,143.527h-3.838a.385.385,0,1,0,0,.771h3.838a.385.385,0,1,0,0-.771Z"
              transform="translate(-108.736 -143.527)"
            />
          </g>
        </g>
        <g transform="translate(2.243 10.156)">
          <g transform="translate(0)">
            <path
              class="a"
              d="M116.428,263.527h-7.307a.385.385,0,1,0,0,.771h7.307a.385.385,0,1,0,0-.771Z"
              transform="translate(-108.736 -263.527)"
            />
          </g>
        </g>
      </svg>
    );
  }
}
