import React, { Component } from "react";
import Cancel from "../Cancel";
import { withStyles } from "@material-ui/core/styles";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {
  CancelDispatchMainBody: {
    height: window.innerHeight - 70,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  },
};
class CancelDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    this.props.history.push("/dispatch");
  };
  render() {
    const { classes } = this.props;
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        <div className={classes.CancelDispatchMainBody}>
          <Cancel {...this.props} />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(CancelDispatch);
