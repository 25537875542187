import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router";
import COLORS from "../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Dialpad from "@material-ui/icons/Dialpad";
import InfoCard from "./InfoCard";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
import moment from "moment";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const styles = {
  breadcrumbsStyle: {
    color: COLORS.secondaryBlueText,
    cursor: "pointer",
    margin: "10px 0px 0px 10px",
  },
  paperStyle: {
    width: "96%",
    marginTop: "2%",
  },
  plantNameShowContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px",
  },
};
class DispatchCounterHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laNumberLengthError: false,
      errorMessage: "",
      weighbridge_AllStatus: [],
      Weighbridge_Status: [
        { name: "Loading Status", title: "Ongoing", status: true },
        { name: "Plant Status", title: "", status: true },
      ],
      Weighbridge_randomWtStatus: [
        { name: "Loading Status", title: "Ongoing", status: true },
        { name: "Plant Status", title: "", status: true },
        {
          name: "Last Random Weight",
          title: "#WB-27GG",
          status: true
        }
      ],
      dcId: this.props?.match.params?.dcId,
      isCopied: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.laDetailsInDispatchCounter != this.props.laDetailsInDispatchCounter) {
      this.setState({
        weighbridge_AllStatus: this.props?.laDetailsInDispatchCounter?.dispatch_status === "PACKED"
          || this.props?.laDetailsInDispatchCounter?.dispatch_status === "ALLOTED" ||
          this.props?.laDetailsInDispatchCounter?.dispatch_status === "BILLED" ?
          this.state.Weighbridge_randomWtStatus : this.state.Weighbridge_Status
      })
    }
  }

  isStatusGood = (name, dispatchCounterDetails) => {
    let status = false;
    // console.log(name, dispatchCounterDetails)
    switch (name.toUpperCase()) {
      case "LOADING STATUS":
        if (
          dispatchCounterDetails?.dispatch_status?.toUpperCase() === "PACKED" ||
          dispatchCounterDetails?.dispatch_status?.toUpperCase() === "ALLOTED" ||
          dispatchCounterDetails?.dispatch_status?.toUpperCase() === "BILLED" ||
          dispatchCounterDetails?.dispatch_status?.toUpperCase() === "DISPATCHED"
        ) {
          status = true;
        }
        break;

      case "PLANT STATUS":
        if (dispatchCounterDetails?.plant_name === this.props.plantName) {
          status = true;
        }
        break;
      case "LAST RANDOM WEIGHT":
        if ((this.props.laDetailsInDispatchCounter?.random_weight_check === null || this.props.laDetailsInDispatchCounter?.random_weight_check?.is_valid)) {
          status = true;
        }
        break;

      default:
        break;
    }
    return status;
  };

  onCopyText = () => {
    this.setState(prevValue => { return { ...prevValue, isCopied: !prevValue.isCopied } })
    setTimeout(() => {
      this.setState({
        isCopied: false,
      });
    }, 1000);
  }

  render() {
    const { laNumber } = this.props;
    const {
      classes,
      laDetailsInDispatchCounter,
      laDispatchCounterLoader,
      plantName,
      dcName,
    } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          className={classes.breadcrumbsStyle}
        >
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
          >
            Dispatch Operations
          </Link>
          <Link color="inherit">Dispatch Counter</Link>
        </Breadcrumbs>
        <Paper elevation={1} className={classes.paperStyle}>
          <div className={classes.plantNameShowContainer}>
            <span>
              {plantName} - {dcName}
            </span>
            <span>{moment().format("lll")}</span>
          </div>
          <Divider variant="middle" />
          <div
            style={{
              display: "flex",
              padding: "12px 12px 40px 12px",
              marginBottom: "22px",
              height: !laDetailsInDispatchCounter ? "66vh" : null,
            }}
          >
            <div style={{ width: "50%" }}>
              <Paper
                elevation={1}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px 18px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "24vh",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
                }}
              >
                <Dialpad style={{ marginBottom: "18px" }} />
                <TextField
                  disabled={this.props.laDetailsInDispatchCounter !== null}
                  name="laNumber"
                  autoFocus
                  style={{ width: "85%" }}
                  id="outlined-adornment-amount"
                  variant="outlined"
                  label="LA Number here"
                  value={laNumber.toUpperCase()}
                  helperText={
                    this.state.laNumberLengthError ? (
                      <span style={{ color: COLORS.orange }}>
                        {this.state.errorMessage}
                      </span>
                    ) : null
                  }
                  onChange={(e) => {
                    this.props.handleTextChange(e);
                    let la = (e.target.value).trim();
                    if (la.length === 14 || la.length === 15) {
                      this.props.handleFetchLoadingAdviceInDispatchCounter(la);
                    }
                  }}
                  onFocus={() => {
                    this.setState({ laNumberLengthError: false });
                  }}
                  onBlur={() => {
                    if (
                      this.props.laNumber.length !== 14 ||
                      this.props.laNumber.length !== 15
                    ) {
                      this.setState({
                        laNumberLengthError: true,
                        errorMessage:
                          "Please fill valid 14 or 15 digit LA number",
                      });
                    }
                  }}
                />
              </Paper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {laDetailsInDispatchCounter === null &&
                  laDispatchCounterLoader === false ? null : (
                  <InfoCard
                    title_1="LOADING"
                    title_2="ADVICE"
                    key_1="Item Category"
                    value_1={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.category_name
                    }
                    key_2="Gate In Date"
                    value_2={moment(
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.gate_in_date
                    ).format("DD/MM/YYYY")}
                    key_3="La Quantity"
                    value_3={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.la_quantity + "MT"
                    }
                    key_4="LA Status"
                    value_4={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.dispatch_status
                    }
                    infoLoader={laDispatchCounterLoader}
                  />
                )}
                {laDetailsInDispatchCounter === null &&
                  laDispatchCounterLoader === false ? null : (
                  <InfoCard
                    title_1="TRUCK"
                    title_2="DETAILS"
                    key_1="Transporter Name"
                    value_1={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.transporter_name
                    }
                    key_2="Driver Name"
                    value_2={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.driver_name
                    }
                    key_3="Truck Capacity"
                    value_3={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.truck_capacity + "MT"
                    }
                    key_4="Driver Number"
                    value_4={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter.driver_contact_number
                    }
                    infoLoader={laDispatchCounterLoader}
                  />
                )}
                {laDetailsInDispatchCounter === null &&
                  laDispatchCounterLoader === false ? null : (
                  <InfoCard
                    title_1="CUSTOMER"
                    title_2="DETAILS"
                    key_1="Booked by"
                    value_1={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter?.customer?.first_name + " " +
                      laDetailsInDispatchCounter?.customer?.last_name
                    }
                    key_2="Buyer"
                    value_2={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter?.buyer?.first_name + " " +
                      laDetailsInDispatchCounter?.buyer?.last_name
                    }
                    key_3="Consignee"
                    value_3={
                      laDetailsInDispatchCounter &&
                      laDetailsInDispatchCounter?.consignee?.first_name + " " +
                      laDetailsInDispatchCounter?.consignee?.last_name
                    }
                    infoLoader={laDispatchCounterLoader}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                width: "44%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {laDetailsInDispatchCounter === null &&
                this.props.laDispatchCounterLoader === false ? null : (
                <InfoCard
                  title_1="ITEM"
                  title_2="DETAILS"
                  infoLoader={laDispatchCounterLoader}
                  itemShowInDC={true}
                  laDetailsInDispatchCounter={
                    laDetailsInDispatchCounter && laDetailsInDispatchCounter
                  }
                />
              )}
              {laDetailsInDispatchCounter === null ? null : (
                <div style={{ width: "92%", marginTop: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <span>{laDetailsInDispatchCounter?.la_number}</span>
                    <span>{laDetailsInDispatchCounter?.truck_number}</span>
                  </div>
                  <Divider variant="middle" />
                  <div>
                    {this.state.weighbridge_AllStatus.map((data) => {
                      let randomTolerance = Number(laDetailsInDispatchCounter?.random_weight_check?.tolerance).toFixed(2);
                      let secondaryText = data.name === "Last Random Weight" ? randomTolerance > laDetailsInDispatchCounter?.random_weight_check?.master_tolerance ? `Vehicle is Overweight (${randomTolerance}%)`
                        : randomTolerance < 0 ? `Vehicle is underweight (${randomTolerance}%)`
                          : randomTolerance < laDetailsInDispatchCounter?.random_weight_check?.master_tolerance ? `Weight in Tolerance (${randomTolerance}%)`
                            : "No Random Weight Done" : "";
                      return <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              style={
                                this.isStatusGood(
                                  data.name,
                                  laDetailsInDispatchCounter
                                )
                                  ? { background: COLORS.veryLightGreen }
                                  : { background: COLORS.lightPink }
                              }
                            >
                              {this.isStatusGood(
                                data.name,
                                laDetailsInDispatchCounter
                              ) ? (
                                <Check style={{ color: COLORS.lightGreen_1 }} />
                              ) : (
                                <Cancel style={{ color: COLORS.pink }} />
                              )}
                            </Avatar>
                          </ListItemAvatar>
                          <Tooltip title={data.name === "Last Random Weight" && laDetailsInDispatchCounter?.random_weight_check !== null ? "Tolerance is " + laDetailsInDispatchCounter?.random_weight_check?.master_tolerance + "%" : ''} placement="top-start">
                            <ListItemText
                              primary={data.name}
                              secondary={secondaryText}
                              primaryTypographyProps={{
                                style: { fontSize: "0.9em" },
                              }}
                              secondaryTypographyProps={{
                                style: { fontSize: "0.9em" },
                              }}
                            />

                          </Tooltip>
                          {data.name === "Last Random Weight" ?
                            <>
                              <CopyToClipboard text={secondaryText}
                                onCopy={() => this.onCopyText()}>
                                {this.state.isCopied ? (<span style={{
                                  marginRight: laDetailsInDispatchCounter?.random_weight_check !== null && randomTolerance < 0 ?
                                    90 : randomTolerance < laDetailsInDispatchCounter?.random_weight_check?.master_tolerance ?
                                      110 : randomTolerance > laDetailsInDispatchCounter?.random_weight_check?.master_tolerance ? 90 : 130, fontSize: "10px", marginTop: 13, color: "#FFB600"
                                }}>Copied!</span>) : (
                                  <ContentCopyOutlinedIcon
                                    style={{
                                      fontSize: "small",
                                      cursor: 'pointer',
                                      marginTop: 13,
                                      marginRight: laDetailsInDispatchCounter?.random_weight_check !== null && randomTolerance < 0 ?
                                        120 : randomTolerance < laDetailsInDispatchCounter?.random_weight_check?.master_tolerance ?
                                          150 : randomTolerance > laDetailsInDispatchCounter?.random_weight_check?.master_tolerance ?
                                            130 : 165
                                    }}
                                  />)
                                }
                              </CopyToClipboard>
                            </>
                            : ""}
                          <ListItemSecondaryAction>
                            {data.name === "Plant Status" ? (
                              <span style={{ color: COLORS.grey }}>
                                {laDetailsInDispatchCounter?.plant_name}
                              </span>
                            )
                              : data.name === "Last Random Weight" ? (
                                <div style={{ display: "flex", color: COLORS.grey }}>
                                  <div>
                                    {laDetailsInDispatchCounter?.random_weight_check !== null ?
                                      laDetailsInDispatchCounter?.random_weight_check?.weight : "--"
                                    }
                                  </div>
                                </div>
                              ) : (
                                <span style={{ color: COLORS.grey }}>
                                  {laDetailsInDispatchCounter?.dispatch_status}
                                </span>
                              )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "16%",
                    }}
                  >
                    <Button
                      style={{ color: COLORS.primaryColor }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ laNumber: "" });
                        this.props.handleClearLoadingAdvice();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={(laDetailsInDispatchCounter?.random_weight_check?.is_valid === false) || (
                        !(
                          laDetailsInDispatchCounter.dispatch_status ===
                          "PACKED" ||
                          laDetailsInDispatchCounter.dispatch_status ===
                          "ALLOTED" ||
                          laDetailsInDispatchCounter.dispatch_status ===
                          "BILLED" ||
                          laDetailsInDispatchCounter.dispatch_status ===
                          "DISPATCHED"
                        ) ||
                        laDetailsInDispatchCounter?.plant_name !== plantName)
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          laDetailsInDispatchCounter.dispatch_status ===
                          "PACKED"
                        ) {
                          this.props.history.push(
                            "/dispatchOperation/dc/2/details",
                            this.state
                          );
                        } else if (
                          laDetailsInDispatchCounter.dispatch_status ===
                          "ALLOTED"
                        ) {
                          this.props.history.push(
                            "/dispatchOperation/dc/2/details/invoices",
                            this.state
                          );
                        } else if (
                          laDetailsInDispatchCounter.dispatch_status ===
                          "BILLED" || laDetailsInDispatchCounter.dispatch_status ===
                          "DISPATCHED"
                        ) {
                          this.props?.handleBilledNext(laNumber);
                        }
                      }}
                      variant="contained"
                      style={{
                        backgroundColor: ((laDetailsInDispatchCounter?.random_weight_check?.is_valid === false)) || (
                          !(
                            laDetailsInDispatchCounter.dispatch_status ===
                            "PACKED" ||
                            laDetailsInDispatchCounter.dispatch_status ===
                            "ALLOTED" ||
                            laDetailsInDispatchCounter.dispatch_status ===
                            "BILLED" ||
                            laDetailsInDispatchCounter.dispatch_status ===
                            "DISPATCHED"
                          ) ||
                          laDetailsInDispatchCounter?.plant_name !== plantName)
                          ? COLORS.grey
                          : COLORS.primaryColor,
                        color: "white",
                        padding: "12px 48px",
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Paper >
      </div >
    );
  }
}
export default withRouter(withStyles(styles)(DispatchCounterHome));
