import React, { Component } from "react";
// import B2bDispatch from "../../../components/b2b/mobile/B2bDispatch";
// import B2bDispatchWeb from "../../../components/b2b/web/B2bDispatchWeb";
// import B2bDispatchCreate from "../../../components/b2b/mobile/B2bDispatchCreate";
// import B2bDispatchCreateWeb from "../../../components/b2b/web/B2bDispatchCreateWeb";
// import B2bSingleDispatch from "../../../components/b2b/mobile/B2bSingleDispatch";
// import B2bSingleDispatchWeb from "../../../components/b2b/web/B2bSingleDispatchWeb";
import Dispatch, {
  DispatchWeb,
  DispatchCreate,
  DispatchCreateWeb,
  SingleDispatch,
  SingleDispatchWeb,
  PendingDispatch,
  PendingDispatchWeb,
  CancelDispatch,
  CancelDispatchWeb,
  ConfirmDispatch,
  ConfirmDispatchWeb,
} from "../../../components/b2b/dispatch";
import moment from "moment";
import withUser from "../../../hoc/withUser";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import axios from "axios";
import B2b_URL from "../../../constants/b2b/B2bRoutes";
import SnackbarComponent from "../../../components/common/SnackbarComponent"
import { checkValidFloatValue, isValidDriverLicense, checkNameFieldAlphaNumeric, checkApiError, checkQtyLogic, arraySortBykeyAllBrowser, StaticAppVersion, checkInvalidValue } from "../../../methods";
import { Snackbar } from "@material-ui/core";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import upiqr from "upiqr";

class B2bDispatchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      gateInDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedPlant: "",
      truckNumber: "",
      truckCapacity: "",
      transporterName: "",
      transporterContactNumber: "",
      driverName: null,
      driverContactNumber: null,
      driverLicenseNumber: null,
      buyerName: "",
      buyerId: "",
      consigneeName: "",
      consigneeId: "",
      paymentMethod: "",
      pageHeight: window.innerHeight,
      LADetails: null,
      buyersList: null,
      consigneesList: null,
      paymentMethods: null,
      ordersList: null,
      ordersListLoader: false,
      paymentMethodID: null,
      selectedLAItems: [],
      setOrderAndItem: new Map(),
      totalLAItemsQuantity: null,
      addAnotherOrder: false,
      setItem: "",
      setItemID: "",
      setItemQuantity: "",
      item: "",
      quantity: null,
      addAnotherOrder: false,
      categoryItems: null,
      setOrderId: "",
      selectedBuyer: null,
      selectedConsignee: null,
      plantsList: null,
      selectedPaymentMethodId: null,
      selectedOrderId: null,
      openDialog: false,
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
      plantPlanningLoader: null,
      submitLALoader: null,
      loadingAdviceList: [],
      loadingAdviceLoader: false,
      singleLALoader: false,
      transType: [],
      createLAAllowed: false,
      showLACreateError: false,
      itemQtyError: false,
      selectedOrder: null,
      LALazyLoader: false,
      lazyLoad: true,
      isTransporterAvailable: true,
      transporterID: null,
      transporters: null,
      allPlantList: [],
      showDispatch: 'new',
      LASubmitLoader: false,
      allStateList: [],
      downloadInvoiceReady: false,
      itemEditIndex: '',
      b2CQrImage: null,
      customerRemark: null,
      nextURL: null,
      selectedEditSubContractNumber: "",
      subContractNumber: "",
      subContract: "",
      plantSubContractEnabled: false,
      subContractDropdownList: [],
      subContractDropdownListLoader: false,
      selectedSubContractDetail: {},
      selectedSubContractPayment: {},
      selectedOrderItemCategoryId: null
    };
  }
  cancelToken = '';

  handleDispatchSelectChip = (key) => {
    this.setState({ showDispatch: key })
  }

  offlineCheck = () => {
    this.setState({ isOffline: true });
  };
  onlineCheck = () => {
    this.setState({ isOffline: false });
  };

  componentWillMount() {
    window.addEventListener("offline", this.offlineCheck);
    window.addEventListener("online", this.onlineCheck);
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }


  // getOptions = (user) => {
  //   return {
  //     headers: {
  //       Authorization: `JWT ${user.token}`,
  //       "Content-Type": "application/json;charset=UTF-8",
  //     },
  //   };
  // };

  componentDidMount() {
    // console.log("re-render in index")
    // console.log(this.props.location)
    if (navigator.onLine === false) {
      this.setState({
        isOffline: true,
      });
    }
    let max = moment(new Date()).add(7, "days").toDate();
    this.setState({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(max).format("YYYY-MM-DD"),
    });
    let location = this.props.location
    // if()
    if (location?.state?.LADetails) {
      this.setState({
        LADetails: location.state.LADetails
      })
    }
    console.log(this.props)
    this.fetchBuyerList()
    this.fetchConsigneeList()
    this.fetchPaymentMethod()
    // this.fetchOrderList()
    this.fetchAllLA("new")
    this.fetchAllPlant()
    this.fetchTransTypeMaster()
    if (this.state.allStateList?.length === 0) {
      this.getAllStatesList()
    }
    // this.fetchTransporters()
    // this.fetchPlantsList()
    // this.fetchLADetails(this.props.location.state.LANumber ? this.props.location.state.LANumber : null)
  }


  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  // fetchTransporters = () => {
  //   axios.get(`${B2b_URL.GET_TRANSPORTERS}`
  //     , this.getOptions(this.props.user))
  //     .then(response => {
  //       console.log(response)
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }

  handleEditData = (LaData, mode) => {
    console.log("EDIT -> ", LaData)
    if (LaData) {
      this.fetchBuyerList()
      this.fetchConsigneeList()
      this.fetchPaymentMethod()
      this.fetchPlantsList(LaData, mode)
      if (this.state.allStateList?.length === 0) {
        this.getAllStatesList()
      }
      let newOrderObj = []
      LaData?.loading_advice_items.map(d =>
        newOrderObj.push({
          item: d?.item?.item_name,
          quantity: d?.item_qty,
          id: d?.item?.id
        })
      )
      this.fetchItemsList(LaData?.item_category?.id, mode, newOrderObj, LaData?.order.tag_id)
      console.log(newOrderObj, this.state?.totalLAItemsQuantity, "LaData?.gate_in_date =", LaData?.gate_in_date)
      let totalLAItemsQuantity = newOrderObj.reduce((total, obj) => Number(obj.quantity) + total, 0)
      this.setState({
        selectedOrderQuantity: LaData?.order.quantity,
        selectedOrderId: LaData?.order.id,
        setOrderId: LaData?.order.order_number,
        plantsList: null,
        plantPlanningLoader: true,
        selectedOrder: LaData?.order,
        selectedBuyerDataForLA: LaData?.buyer,
        selectedConsigneeDataForLA: LaData?.consignee,
        selectedPaymentMethodDataForLA: LaData?.payment_method,
        gateInDate: LaData?.gate_in_date,
        truckNumber: LaData?.truck_number,
        truckCapacity: LaData?.truck_capacity,
        transporterID: LaData?.transporter?.id || 'Not Available',
        transporterName: LaData?.transporter?.acc_name || 'Not Available',
        transporterContactNumber: LaData?.transporter?.mobile || 'Not Available',
        driverName: LaData?.driver_name,
        driverContactNumber: LaData?.driver_contact_number,
        driverLicenseNumber: LaData?.driver_licence,
        setOrderAndItem: this.state.setOrderAndItem.set(LaData?.order?.order_number, newOrderObj),
        totalLAItemsQuantity: Number(Number(totalLAItemsQuantity).toFixed(3)),
        customerRemark: LaData?.customer_remark
      }, () => {
        if (LaData?.subcontract_number !== null) {
          console.log("here -> subcontract edit")
          this.setState({
            selectedEditSubContractNumber: LaData?.subcontract_number,
            subContractNumber: LaData?.subcontract_number,
          })
        }
      })
    }
  }

  fetchAllLA = (type, plantList) => {
    if (!this.state.loadingAdviceLoader)
      this.setState({
        loadingAdviceLoader: true
      })
    let data = {
      filter_by: type,
      plant: plantList?.length > 0 ? plantList : null
    }
    let updatedData = Object.entries(data).reduce((a, [k, v]) => (v !== null ? (a[k] = v, a) : a), {})

    if (typeof this.cancelToken != typeof undefined) {
      this?.cancelToken && this.cancelToken.cancel("Operation canceled")
    }
    this.cancelToken = axios.CancelToken.source()

    axios
      .post(
        `${B2b_URL.GET_ALL_LA}?V=${StaticAppVersion()}`,
        updatedData,
        { cancelToken: this.cancelToken.token },
        this.getOptions(this.props.user))
      .then(response => {
        if (response.status === 200) {
          this.setState({
            loadingAdviceList: Array.isArray(response?.data) ? response?.data : response?.data?.results,
            loadingAdviceLoader: false,
            nextURL: response.data.next,
          })
        }
      })
      .catch(err => {
        // console.log(err)
        if (JSON.stringify(err) === '{"message":"Operation canceled"}') {
          this.setState({ dispatchLoader: true, })
        }
        else {
          // this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: "Error occured while fetching LA",
          //   snackbarType: "error"
          // })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        }
      })

  }
  fetchAllPlant = () => {
    axios
      .get(`${B2b_URL.GET_PLANTS}?active=${true}&V=${StaticAppVersion()}`, this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        this.setState({
          allPlantList: (response.data).filter(d => (d.plant_short_name !== null && d.plant_short_name !== '') && d),
        })
      })
      .catch(err => {
        // console.log(err)
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: "Error occured while fetching Plant",
        //   snackbarType: "error"
        // })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })

  }

  fetchNextLA = (filter, url, plantList) => {
    // console.log(filter)
    if (url) {
      if (!this.state.LALazyLoader)
        this.setState({
          LALazyLoader: true,
          lazyLoad: false,
        })
      let data = {
        filter_by: filter,
        plant: plantList?.length > 0 ? plantList : null
      }
      if (url === this.state.nextURL)
        axios.post(`${url}&V=${StaticAppVersion()}`, data, this.getOptions(this.props.user))
          .then(response => {
            // console.log(response)
            let prevLA = this.state.loadingAdviceList
            let res = response.data.results
            let loadingAdviceList = prevLA.concat(res)



            this.setState({
              nextURL: response.data.next,
              loadingAdviceList,
              LALazyLoader: false,
              lazyLoad: response.data.next ? true : false,

            })

          })
          .catch(err => {
            // console.log(err)
            this.setState({
              // openSnackbar: true,
              // snackbarMessage: "Error occured while fetching Contracts",
              // snackbarType: "error",
              LALazyLoader: false
            })
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 4000)
          })
    }
  }

  getAllStatesList = (countryId) => {
    axios.get(`${B2b_URL.GET_STATES}${!countryId ? 101 : countryId}&V=${StaticAppVersion()}`,
      this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            allStateList: responseData,
          });
        },

      )
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })

  };

  handleOpenSnackBar = () => {
    // console.log(this.state.singleOrderInvoices)
    this.setState({
      openSnackbar: true,
      snackbarMessage: "Downloading PDF....",
      snackbarType: "success"
    })
  }

  handleInvoiceClick = (invoice) => {
    console.log("handleInvoiceClick")
    this.handleOpenSnackBar()
    axios
      .get(`${APIROUTES.FETCH_LA_DETAILS_IN_DISPATCH_COUNTER}${invoice.la_number}/?V=${StaticAppVersion()}/`)
      .then((response) => {
        if (response.status === 200) {
          this.state.allStateList && this.state.allStateList.map(data => {
            if (data['id'] === response.data.plant.plant_state) {
              this.setState({
                plant_state: data['name']
              })
            }
          })
          let data = response.data
          this.setState(
            {
              laDetailsInDispatchCounter: data,
              laDispatchCounterLoader: false,
              downloadedInvoice: invoice
            },
            () => {
              this.getEinvoiceEwayDetails(invoice)
              this.handleCreateB2cInvoiceQr()
            }
            // () => {
            //   this.handleGetDistance();
            // }
          );


        } else {
          this.setState({
            laDetailsInDispatchCounter: null,
            laDispatchCounterLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ laDispatchCounterLoader: false });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  }

  handlePostInvoiceDownload = () => {
    console.log("handlePostInvoiceDownload")
    let invoice = { ...this.state.downloadedInvoice }
    // let downloadedInvoice = this.state.singleOrderInvoices.find(inv => inv.la_number === invoice.la_number)
    // downloadedInvoice.downloadInvoiceReady = false
    this.setState({ downloadInvoiceReady: false })
  }

  getEinvoiceEwayDetails = (data) => {
    console.log("getEinvoiceEwayDetails")
    axios
      .get(`${APIROUTES.GET_E_INVOICE_AND_E_WAY}${data.la_number}/?V=${StaticAppVersion()}/`)
      .then((response) => {
        this.setState({ downloadInvoiceReady: true })
        this.setState({
          eInvoiceResponse: response.data,
          eWayBillResponse: response.data?.is_eway_created
            ? response.data
            : null,
          iseWaySelect: response.data?.is_eway_created,
          invoiceType: response.data?.invoice_type,
        });
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  handleCreateB2cInvoiceQr = () => {
    //alert("QR Genrating");
    this.setState({ dataInvoicePostLoader: true });
    let supplierAccountDetails = this?.state?.plantAccountDetails;
    if (
      this?.state?.laDetailsInDispatchCounter?.payment_method
        ?.payment_method_name === "Advance"
    ) {
      // this.props.history.push("download");
    } else {
      upiqr({
        currency: this?.state?.laDetailsInDispatchCounter?.customer?.currency_code,
        payeeVPA:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_upi_id,
        payeeName:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_name,
        amount: this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value,
        transactionRef: this.state?.laDetailsInDispatchCounter?.erp_invoice_number,
        transactionNote: `SUPGSTN ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number
          } PAYEE_AC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no
          } PAYEE_IFSC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc
          } ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
            ? "IGST " + this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount
            : `CGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount} SGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount}`
          } CESS ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount
          } TOT_AMT ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value
          } INV_DATE ${moment(
            this?.state?.laDetailsInDispatchCounter?.created_at,
            ["DD/MM/YYYY", "YYYY-MM-DD"]
          ).format("DD MMM YYYY")}`,
      })
        .then((upi) => {
          console.log(upi.qr); // data:image/png;base64,eR0lGODP...
          this.setState(
            { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
            () => console.log(1)
          );
        })
        .catch((err) => {
          this.setState({ b2CQrImage: null, dataInvoicePostLoader: false })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        });
    }
  };


  fetchOrderList = (itemId = 0, plantId = 0) => {
    this.setState({ ordersListLoader: true })

    const baseURL = `${B2b_URL.FETCH_ORDERS_FOR_DROPDOWN}`;
    const params = itemId !== 0 && plantId === 0
      ? `?item_category=${itemId}&customer=${this.props.user.id}&V=${StaticAppVersion()}`
      : plantId !== 0 && itemId !== 0
        ? `?item_category=${itemId}&customer=${this.props.user.id}&plant=${plantId}&V=${StaticAppVersion()}`
        : `?customer=${this.props.user.id}&V=${StaticAppVersion()}`
    const finalURL = baseURL + params

    axios.get(finalURL, this.getOptions(this.props.user))
      .then(response => {
        // console.log(response.data)
        let data = response.data
        let fifoOrder = null
        this.setState({
          ordersList: response.data,
          ordersListLoader: false
        })

      })
      .catch(err => {
        this.setState({ ordersListLoader: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })

  }

  fetchItemsList = (itemCategoryId, mode = '', newOrderObj = '', tagID = '') => {
    const url = !checkInvalidValue(tagID)
      ? `${B2b_URL.GET_TAG_WISE_ITEMS}?tag=${tagID}&category_id=${itemCategoryId}&V=${StaticAppVersion()}`
      : `${B2b_URL.GET_TAG_WISE_ITEMS}?category_id=${itemCategoryId}&V=${StaticAppVersion()}`
    axios.get(url)
      .then(response => {
        // console.log(response)
        this.setState({
          categoryItems: mode === 'edit'
            ? response?.data.filter(d => { return !newOrderObj?.find(data => data?.id == d?.id) && d })
            : response.data,
          items: response.data
        }, () => console.log(newOrderObj, this.state?.categoryItems, response?.data.filter(d => !newOrderObj?.find(data => data?.id == d?.id))))
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }


  fetchBuyerList = () => {
    // console.log(this.props.user)
    axios.get(`${B2b_URL.GET_BUYERS_LIST}?customer_id=${this.props.user.id}&V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        this.setState({
          buyersList: response.data.results
        })
        // let plantPlanning = response.data.results
        // this.setState({
        //   singleOrderPlantPlanning: plantPlanning
        // })
      })
      .catch(err => {
        // console.log(err)
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: "Error occured while fetching Buyers List",
        //   snackbarType: "error"
        // })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }
  fetchPaymentMethod = () => {
    axios
      .get(`${B2b_URL.GET_PAYMENT_METHOD + this.props.user.id}&V=${StaticAppVersion()}`)
      .then((response) => {
        // console.log(response);
        this.setState({ paymentMethods: response.data });
      })
      .catch(err => {
        // console.log(err)
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: "Error occured while fetching Payment Methods",
        //   snackbarType: "error"
        // })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  };
  fetchConsigneeList = () => {
    axios.get(`${B2b_URL.GET_CONSIGNEES_LIST}${this.props.user.id}/?V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        this.setState({
          consigneesList: response.data.results
        })
        // let plantPlanning = response.data.results
        // this.setState({
        //   singleOrderPlantPlanning: plantPlanning
        // })
      })
      .catch(err => {
        // console.log(err)
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: "Error occured while fetching Consignees List",
        //   snackbarType: "error"
        // })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  handleTransportCancel = () => {
    this.setState({
      transporterName: "",
      transporterContactNumber: "",
      transporterID: null
    })
  }


  handleShowTransporterTextFields = (value, datafor) => {
    // console.log("here", value)
    if (value !== 'Not Available') {
      if (datafor) {
        this.setState({
          transporterID: value.id,
          transporterContactNumber: value.mobile,
          transporterName: value.acc_name
        })
      }
      else {

        this.setState({ isTransporterAvailable: value })
      }
    }
    else if (value === 'Not Available') {
      this.setState({
        transporterID: 'Not Available',
        transporterContactNumber: 'Not Available',
        transporterName: 'Not Available'
      })
    }
  }

  handleCloseBackDrop = () => {
    this.setState({ singleLALoader: false })
  }

  fetchLADetails = (laNumber, message) => {
    // console.log(this.state.singleLALoader)
    // if (!this.state.singleLALoader)
    this.setState({
      singleLALoader: true
    })
    if (laNumber)
      axios.get(`${B2b_URL.GET_LA_DETAILS}${laNumber}/?V=${StaticAppVersion()}/`
        , this.getOptions(this.props.user))
        .then(response => {
          if (response.status === 200 && response.data.length > 0) {
            // console.log(response)
            // console.log("here")
            let status = response.data[0].dispatch_status

            let LADetails = response.data[0]
            // console.log(LADetails[0])
            let logDetails = LADetails.log_details

            let LADetailsrev = logDetails?.reverse()
            // console.log(LADetailsrev)
            // console.log(this.state.singleLALoader)
            this.setState({
              LADetails: response.data[0],

            })
            // if (status === "PENDING") {
            //   // if (this.props.location.pathname !== `/dispatch/pending/${laNumber}` || !this.props.location.pathname)
            //   //   this.props.history.push({ pathname: `/dispatch/pending/${laNumber}`, state: { message: message } });
            //   // else
            //   //   this.props.history.replace({ pathname: `/dispatch/pending/${laNumber}`, state: { message: message } });
            //   if (this.props.location.pathname !== `/dispatch/singleDispatch/${laNumber}` || !this.props.location.pathname)
            //     this.props.history.push({ pathname: `/dispatch/singleDispatch/${laNumber}`, state: { LADetails, LADetailsrev } });
            //   else {
            //     this.props.history.replace({
            //       pathname: `/dispatch/singleDispatch/${laNumber}`,
            //       state: { LADetails, LADetailsrev }
            //     });
            //   }

            // }
            if (status === "DISPATCHED") {
              if (this.props.location.pathname !== `/dispatch/dispatched/${laNumber}` || !this.props.location.pathname)
                this.props.history.push({ pathname: `/dispatch/dispatched/${laNumber}`, state: { LADetails, LADetailsrev } });
              else
                this.props.history.replace({
                  pathname: `/dispatch/dispatched/${laNumber}`,
                  state: { LADetails, LADetailsrev }
                });

            }
            else if (status === "CANCELLED" ||
              status === "LAPSED" ||
              status === "HOLD"
            ) {
              if (this.props.location.pathname !== `/dispatch/cancelled/${laNumber}` || !this.props.location.pathname)
                this.props.history.push({ pathname: `/dispatch/cancelled/${laNumber}`, state: { LADetails, LADetailsrev } });
              else
                this.props.history.replace({
                  pathname: `/dispatch/cancelled/${laNumber}`,
                  state: { LADetails, LADetailsrev }
                });

            }


            // if (status === "GATEIN" ||
            //   status === "PACKED" ||
            //   status === "BILLED" ||
            //   status === "ALLOTED" ||
            //   status === "LOADING" ||
            //   status === "DISPATCHED" ||
            //   status === "APPROVED" ||
            //   status === "CANCELLED" ||
            //   status === "LAPSED" ||
            //   status === "HOLD"
            // ) 
            else {
              // let LADetailsrev = response.data[0].log_details
              if (this.props.location.pathname !== `/dispatch/singleDispatch/${laNumber}` || !this.props.location.pathname)
                this.props.history.push({ pathname: `/dispatch/singleDispatch/${laNumber}`, state: { LADetails, LADetailsrev } });
              else {
                this.props.history.replace({
                  pathname: `/dispatch/singleDispatch/${laNumber}`,
                  state: { LADetails, LADetailsrev }
                });
              }

            }

            this.setState({
              singleLALoader: false
            })
          }

        })
        .catch(err => {
          this.setState({
            // openSnackbar: true,
            // snackbarMessage: "Error occured while fetching LA Details",
            // snackbarType: "error",
            singleLALoader: false
          })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        })

    // else return "NO LA NUMBER FOUND"
  }

  fetchTransTypeMaster = () => {
    axios
      .get(`${B2b_URL.GET_TRANS_TYPE_MASTER}?V=${StaticAppVersion()}`, this.getOptions(this.props.user))
      .then((response) => {
        // console.log(response)
        this.setState({
          transType: response.data,
          selectedTransType: response.data.length > 0 ?
            (this.props.user.gst_information === null ||
              this.props.user.gst_information === undefined ?
              response.data.find(t => t.tran_supply_type === "B2C")?.id :
              response.data.find(t => t.tran_supply_type === "B2B")?.id) :
            null,
          selectedTransTypeName: response.data.length > 0 ?
            (this.props.user.gst_information === null ||
              this.props.user.gst_information === undefined ?
              response.data.find(t => t.tran_supply_type === "B2C")?.tran_supply_type :
              response.data.find(t => t.tran_supply_type === "B2B")?.tran_supply_type) :
            null,

        });
      })
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };

  fetchPlantsList = (data, type) => {
    // console.log(data, type)
    let selectedOrder = {}
    if (!type || type === undefined) {
      axios.get(`${B2b_URL.RETRIEVE_ORDER_DETAILS}${data.id}/?V=${StaticAppVersion()}`)
        .then(res => {
          // console.log(res)
          selectedOrder = res.data
          this.setState({
            selectedOrderItemCategory: selectedOrder.item_category.category_name,
            selectedOrderItemCategoryId: selectedOrder.item_category.id,
            // plantPlanningLoader:true,
          })
        })
        .catch(err => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        })
    } else {
      selectedOrder = data
      this.setState({
        selectedOrderItemCategory: selectedOrder.item_category.category_name,
        selectedOrderItemCategoryId: selectedOrder.item_category.id,
        // plantPlanningLoader:true,
      })
    }

    axios
      .get(`${B2b_URL.GET_SINGLE_PLANT_PLANNING}?order_id=${type === "edit" ? data?.order?.id : data.id}&V=${StaticAppVersion()}`)
      .then((response) => {

        const list = response.data.results
        let plants = []
        // console.log(list);
        if (list.length > 0) {
          list.forEach(element => {
            plants.push(element)
          });
        }
        this.setState({
          plantsList: plants,
          plantPlanningLoader: false
        })
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  handleItemEdit = (data, indexValue) => {
    this.setState({
      itemEditIndex: indexValue,
      setItemID: data?.id,
      setItemQuantity: data?.quantity,
      setItem: data?.item,
      setItemQuantityError: !checkQtyLogic(data?.quantity)
        ? 'Last digit be 0 or 5'
        : Number(Number(data?.quantity).toFixed(3)) == Number(Number(0).toFixed(3))
          ? 'Invalid Quantity'
          : null
    })
  }

  handleSaveOrderData = (id, items, mode = '', rowItemData = '') => {
    // console.log(id, items)
    let newItems = items.filter(item =>
      item.id !== this.state.setItemID
    )
    console.log(newItems)
    let totalItemQuantity = 0
    // Array.from(this.state.setOrderAndItem.entries()).map(
    //   ([key, value]) => (
    //     console.log(key, value)
    //   ))
    items.map(item => item.quantity)
    const orderId = id;
    const orderObj = {
      item: this.state.setItem,
      quantity: this.state.setItemQuantity,
      id: this.state.setItemID,
    };
    let pendingQuantity = ((Number(this.state.selectedPlantPendingQuantity)).toFixed(3));
    // console.log(pendingQuantity, this.state.selectedPlantPendingQuantity, orderObj.quantity)



    // const totalLAItemsQuantity = 0
    if (this.state.setOrderAndItem.has(orderId)) {
      let newOrderObj = this.state.setOrderAndItem.get(orderId);
      let newtotalLAItemsQuantity = 0
      if (Boolean(rowItemData)) {
        console.log(rowItemData, orderObj)
        newOrderObj = newOrderObj.map(d => d?.id === rowItemData?.id ? orderObj : d)
        newtotalLAItemsQuantity = newOrderObj.reduce((total, obj) => (Number(Number(obj.quantity).toFixed(3))) + total, 0)
        console.log("IF : ", newtotalLAItemsQuantity, newOrderObj)
      }
      else {
        // newtotalLAItemsQuantity = newOrderObj.reduce((total, obj) => (Number(Number(obj.quantity).toFixed(3))) + total, 0)
        // console.log("else : ", newtotalLAItemsQuantity, newOrderObj)
        newOrderObj.push(orderObj);
        newtotalLAItemsQuantity = newOrderObj.reduce((total, obj) => (Number(Number(obj.quantity).toFixed(3))) + total, 0)
        console.log("else : ", newtotalLAItemsQuantity, newOrderObj)
        console.log("else : ", newOrderObj)
      }
      this.setState({
        setOrderAndItem: this.state.setOrderAndItem.set(orderId, newOrderObj),
        totalLAItemsQuantity: Number(Number(newtotalLAItemsQuantity).toFixed(3)),
        addAnotherOrder: false,
        setItem: "",
        setItemQuantity: "",
        setItemID: "",
        categoryItems: newItems,
        selectedPlantPendingQuantity: pendingQuantity
      });
    } else {
      this.setState({
        setOrderAndItem: this.state.setOrderAndItem.set(orderId, [orderObj]),
        totalLAItemsQuantity: this.state.setItemQuantity,
        addAnotherOrder: false,
        setItem: "",
        setItemID: "",
        setItemQuantity: "",
        categoryItems: newItems,
        selectedPlantPendingQuantity: pendingQuantity
      });
    }
  };

  checkItemQtyError = (error, totalqty) => {
    // console.log(error)
    this.setState({
      itemQtyError: error,
    })
  }
  // setOrderIdAndAmount = (id) => {
  //   // console.log(id)
  //   this.setState({ setOrderId: id,
  //   selectedOrderId:data.id });
  // };

  handleTransTypeSelect = (e) => {
    // console.log(e.target.value)
    this.setState({
      selectedTransType: e.target.value
    })
  }

  handleSetOrderItem = (item) => {
    // console.log(item)
    let itemID = this.state.categoryItems.find(cat => {
      if (cat.item_name === item)
        return cat.id
    })
    // console.log(itemID)
    this.setState({ setItem: itemID.item_name, setItemID: itemID.id });
  };

  handleOrderSelect = (order, type) => {
    console.log(order)
    this.setState({
      selectedOrderQuantity: order.quantity,
      selectedOrderId: order.id,
      setOrderId: order.order_number,
      plantsList: null,
      plantPlanningLoader: true,
      selectedOrder: order,
      selectedBuyerDataForLA: order.buyer,
      selectedConsigneeDataForLA: order.consignee,
      selectedPaymentMethodDataForLA: order.payment_method
    })
    if (["PENDING", "HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED", "CONVERSION_ALLOTMENT_HOLD"].includes(order?.order_status)) {
      this.handleOpenSnackbar(
        `Contract status is ${order?.order_status === "CONVERSION_ALLOTMENT_HOLD"
          ? "HOLD"
          : order?.order_status
        }`, "warning")
      setTimeout(() => {
        this.setState({ plantPlanningLoader: false, })
        this.handleCloseSnackbar()
      }, 3000)
    }
    else if ((new Date(order?.order_validity) < new Date())) {
      this.handleOpenSnackbar(`Contract validity expired`, "warning")
      setTimeout(() => {
        this.setState({ plantPlanningLoader: false, })
        this.handleCloseSnackbar()
      }, 3000)
    }

    else {
      const url = !checkInvalidValue(order.tag_id)
        ? `${B2b_URL.GET_TAG_WISE_ITEMS}?tag=${order.tag_id}&category_id=${order.item_category.id}&V=${StaticAppVersion()}`
        : `${B2b_URL.GET_TAG_WISE_ITEMS}?category_id=${order.item_category.id}&V=${StaticAppVersion()}`
      axios.get(url)
        .then(response => {
          // console.log(response)
          this.setState({
            categoryItems: response.data,
            items: response.data
          })
        })
        .catch(err => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        })
      this.fetchSingleOrderLoadingAdvices(order)
    }

  }

  fetchSingleOrderLoadingAdvices = (order) => {
    // if (!this.state.laLoader) {
    //   this.setState({
    //     laLoader: true
    //   })
    // }
    // console.log(order)
    axios.get(`${B2b_URL.GET_SINGLE_ORDER_LA}${order.id}/?V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        // let plantPlanning = response.data.results

        let data = response.data
        let totalLAQuantity = 0
        if (data.length > 0)
          data.map(q => {
            if (!(q.dispatch_status === "CANCELLED" || q.dispatch_status === "LAPSED")) {
              totalLAQuantity += q.quantity
            }
          })
        // console.log(totalLAQuantity)
        this.setState({
          totalLAQuantity
        })
        this.fetchPlantsList(order)
        // if (totalLAQuantity < order.quantity) {
        //   this.setState({
        //     createLAAllowed: true,
        //     showLACreateError: false
        //   })

        // } else {
        //   this.setState({
        //     createLAAllowed: false,
        //     showLACreateError: true
        //   })
        // }


        // }
        // this.setState({
        //   singleOrderLA: response.data,
        //   laLoader: false,
        //   // totalLAQuantity
        // })
      })
      .catch(err => {
        // console.log(err)
        this.setState({
          // openSnackbar: true,
          // snackbarMessage: `Error while Loading Advices for this Contract`,
          // snackbarType: "error",
          laLoader: false,
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })


  }

  checkError = (e, name) => {
    // console.log(e.target.value, name)
    const { value } = e.target

    const truckNumberValidationCase_1 = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/g;
    const truckNumberValidationCase_2 = /^[A-Z]{2}[0-9]{1}[A-Z]{3}[0-9]{4}$/g;
    const truckNumberValidationCase_3 = /^[A-Z]{3}[0-9]{4}$/g;
    const truckNumberValidationCase_4 = /^[A-Z]{2}[0-9]{4}$/g;


    if (name === "driverContactNumber") {
      if (value.length > 0 && value.length < 10) {
        this.setState({ driverContactNumberLengthError: true });
      }
      else {
        this.setState({ driverContactNumberLengthError: false });
      }
    }
    if (name === "driverName") {

      // console.log(value, !checkNameFieldAlphaNumeric(value))
      // if (!checkNameFieldAlphaNumeric(value)) {
      this.setState({ driverNameError: value === "" ? false : !checkNameFieldAlphaNumeric(value) });
      // }
      // else {
      //   this.setState({ driverNameError: false });
      // }
    }

    if (name === "transporterName") {
      // console.log(value)
      // if (!checkNameFieldAlphaNumeric(value)) {
      this.setState({ transporterNameError: value === "" ? false : !checkNameFieldAlphaNumeric(value) });
      // }
      // else {
      //   this.setState({ transporterNameError: false });
      // }
    }

    if (name === "driverLicenseNumber") {
      const regex = /^[a-zA-Z0-9]+$/;
      // if (value.length < 15 || !isValidDriverLicense(value)) {
      this.setState({ driverLicenseNumberError: value === "" ? false : !regex.test(value) });
      // }
      // else {
      //   this.setState({ driverLicenseNumberError: false });
      // }
    }
    if (name === "transporterContactNumber") {
      if (value.length < 10) {
        this.setState({ transporterContactNumberLengthError: value === "" ? false : value.length < 10 });
      }
      else {
        this.setState({ transporterContactNumberLengthError: false });
      }
    }
    if (name === "truckNumber") {
      const regex = /^[a-zA-Z]{2}[0-9]{1,2}[a-zA-Z0-9\s]{2,}$/;
      // if (

      // ) {
      this.setState({ truckNumberValidationError: value === "" ? false : !regex.test(value) });
      // });
      //       } else {
      //         this.setState({ truckNumberValidationError: true });
      //       }
    }
  }


  handleSelectOrderId = (key, items) => {
    // console.log(items)
    this.setState({ setOrderId: key }, () => {
      this.handleSaveOrderData(key);
    });
  };
  deleteOrder = (key) => {
    // console.log(key)
    let newSet = this.state.setOrderAndItem.delete(key);
    this.setState({
      addAnotherOrder: false,
      setOrderAndItem: new Map(),
      // setOrderId: "",
      selectedOrderQuantity: null
    });
  };
  deleteOrderItem = (key, index, data, mode = '') => {
    let catItems = this.state.categoryItems
    let itemToBeAdded = this.state.items.find(item => item.id === data.id)
    // console.log(itemToBeAdded)
    catItems.push(itemToBeAdded)
    // this.setState({
    //   categoryItems: catItems
    // })
    let items = this.state.setOrderAndItem.get(key);
    let totalLAItemsQuantity = 0
    items.splice(index, 1);
    // console.log(items)
    const itemqty = this.state?.setOrderAndItem
    let totalqty = 0
    itemqty &&
      Array.from(itemqty.entries()).map(
        ([key, value]) => (
          value.map(item => {
            // console.log(item.quantity, totalqty)
            totalqty += parseFloat(item.quantity)
          })
        ))
    // console.log(totalqty)
    let itemTotal = parseFloat(totalqty) || 0
    console.log(key, index, data, items, itemqty)
    totalLAItemsQuantity = items.reduce((total, obj) => (Number(Number(obj.quantity).toFixed(3))) + total, 0)
    let pendingQuantity = Number(Number(Number(this.state.selectedPlantAvailableQuantity).toFixed(3)) - Number(Number(itemTotal).toFixed(3))).toFixed(3)
    console.log(totalLAItemsQuantity)
    // if (items.length > 0) {
    //   items.map(item => totalLAItemsQuantity += Number(item.quantity))
    // }
    this.setState({
      totalLAItemsQuantity: Number(Number(totalLAItemsQuantity).toFixed(3)),
      categoryItems: catItems,
      selectedPlantPendingQuantity: pendingQuantity,
      selectedPlantAvailableQuantity: this.state?.selectedPlantAvailableQuantity
    })
  };

  handleDateChange = (date) => {
    console.log(date)
    if (date !== "Invalid Date") {
      this.setState({
        gateInDate: moment(date).format("YYYY-MM-DD"),
      })
    } else {
      console.log("invalid date");
    }
  }

  handleTextChange = (event, rowData = '') => {
    const { name, value } = event.target
    console.log(name, value, rowData)
    this.setState({
      [name]: name === "driverLicenseNumber" ? value.toUpperCase() : value,
    });
    if (name === "setItemQuantity") {
      if (!checkQtyLogic(value)) {
        this.setState({
          setItemQuantityError: 'Last digit be 0 or 5'
        })
      }
      else if (checkQtyLogic(value)) {
        this.setState({
          setItemQuantityError: Number(Number(value).toFixed(3)) == Number(Number(0).toFixed(3))
            ? 'Invalid Quantity'
            : null
        })
      }
      const itemqty = this.state.setOrderAndItem
      let totalqty = 0
      // console.log("here")
      itemqty &&
        Array.from(itemqty.entries()).map(
          ([key, value]) => (
            value.map(item => {
              if (rowData) {
                totalqty += parseFloat(item?.id == rowData?.id ? (Number(value) || 0) : item.quantity)
              }
              else {// console.log(item.quantity, totalqty)
                totalqty += parseFloat(item.quantity)
              }
            })
          ))
      console.log(totalqty)
      let itemTotal = parseFloat(totalqty) + (parseFloat(value) || 0)

      // console.log("else here", this.state?.selectedPlantPendingQuantity, this.state?.selectedPlantAvailableQuantity)
      this.setState({
        selectedPlantPendingQuantity: Number((Number(Number(this.state?.selectedPlantAvailableQuantity).toFixed(3)) - Number(Number(itemTotal).toFixed(3))).toFixed(3))
      },
        () => {
          // console.log(itemqty, (parseFloat(value) || 0), itemTotal, parseFloat(this.state.selectedPlantPendingQuantity), Number(this.state?.selectedPlantAvailableQuantity), parseFloat(this.state.truckCapacity))
          if (parseFloat(value) > parseFloat(this.state.truckCapacity) ||
            // parseFloat(value) > parseFloat(this.state.selectedPlantPendingQuantity) ||
            // itemTotal > parseFloat(this.state.selectedPlantPendingQuantity) ||
            parseFloat(this.state.selectedPlantPendingQuantity) < 0 ||
            itemTotal > parseFloat(this.state.truckCapacity)) {
            // this.handlePendingQuantityUpdate(event, true)
            this.handleShowQuantityError(true)
          }

          else {
            // this.handlePendingQuantityUpdate(event, false)
            this.handleShowQuantityError(false)
          }
        })
      // }
    }
    else if (name === 'truckCapacity') {
      if (!checkQtyLogic(value)) {
        this.setState({
          truckCapacityError: 'Last digit be 0 or 5'
        })
      }
      else if (checkQtyLogic(value)) {
        this.setState({
          truckCapacityError: null
        })
      }
    }
  };
  handleTextChangeErrorCheck = (event) => {
    // console.log("heresssssssssssssssss")
    const { name, value } = event.target
    if (name === "setItemQuantity") {
      this.setState({
        setItemQuantityError: value === "" ? false : !checkValidFloatValue(value)
      })
      let decimalValue = value === "" ? null : value.split(".")[1]
      // console.log(decimalValue, checkValidFloatValue(value) && decimalValue)
      if (checkValidFloatValue(value) && decimalValue) {
        if (decimalValue.length === 3) {
          if (decimalValue[2] === "5" || decimalValue[2] === "0") {
            this.setState({
              setItemQuantityError: false
            })
          } else {
            this.setState({
              setItemQuantityError: true
            })
          }
        }
        // console.log(decimalValue)
      }


    }
  }

  handlePendingQuantityUpdate = (e, error) => {
    if (!error) {
    }
  }


  handleShowQuantityError = (value) => {
    // console.log(value)
    this.setState({
      capacityError: value
    })
  }
  handleSelectBuyerName = (buyer) => {
    console.log(buyer)
    this.setState({
      buyerName: buyer?.first_name,
      buyerId: buyer?.id,
      selectedBuyer: buyer
    }, () => {
      this.setState({
        selectedTransType: (buyer?.gstin_number === null || buyer?.gstin_number === undefined)
          ? this.state?.transType.find(t => t.tran_supply_type === "B2C")?.id
          : this.state?.transType.find(t => t.tran_supply_type === "B2B")?.id,
        selectedTransTypeName: (buyer?.gstin_number === null || buyer?.gstin_number === undefined)
          ? this.state?.transType.find(t => t.tran_supply_type === "B2C")?.tran_supply_type
          : this.state?.transType.find(t => t.tran_supply_type === "B2B")?.tran_supply_type
      })
    });
  };
  handleSelectConsigneeName = (consignee) => {
    console.log(consignee, this.props?.mode)
    this.setState({
      consigneeName: consignee?.consignee_detail?.first_name || consignee?.first_name,
      consigneeId: consignee?.consignee_detail?.consignee_id || consignee?.id,
      selectedConsignee: consignee
    });
  };
  handleSelectPaymentMethod = (payment) => {
    console.log(payment)
    this.setState({
      paymentMethod: payment.payment_method_name || payment.payment_method_detail.payment_method_name,
      selectedPaymentMethodId: payment?.id ? payment?.id : payment?.payment_method_detail?.id
    });
  };

  handleSubcontractFormDetail = (data, mode = "") => {
    console.log(data)
    if (mode !== 'edit' && !checkInvalidValue(data?.subcontract_payment_detail?.payment_doc_valid_upto) &&
      (new Date(data?.subcontract_payment_detail?.payment_doc_valid_upto) < new Date())) {
      this.handleOpenSnackbar(`SubContract validity expired`, "warning")
      setTimeout(() => {
        this.handleCloseSnackbar()
      }, 3000)
    }
    else {
      let selectedSubContractDetail = {};
      Object.assign(selectedSubContractDetail, data.subcontract_payment_detail, { subcontract_number: data.sub_contract_number })
      this.setState({
        selectedSubContractDetail: selectedSubContractDetail,
        subContract: data?.subcontract_id,
        subContractNumber: data?.sub_contract_number,
        selectedEditSubContractNumber: "",
        selectedBuyerDataForLA: mode === 'edit' ? this.state.selectedBuyerDataForLA : data?.buyer,
        selectedConsigneeDataForLA: mode === 'edit' ? this.state.selectedConsigneeDataForLA : data?.consignee,
        paymentMethod: data?.payment_detail.payment_method_name,
        selectedPaymentMethodId: data?.payment_detail.payment_id,
        selectedSubContractPayment: data?.payment_detail,
        selectedPlantPendingQuantity: mode === 'edit'
          ? Number(Number(data?.available_quantity).toFixed(3))
          : Number(Number(data?.available_quantity).toFixed(3)),
        selectedPlantAvailableQuantity: mode === 'edit'
          ? Number(Number(data?.available_quantity).toFixed(3)) + Number(Number(this.state?.totalLAItemsQuantity).toFixed(3))
          : Number(Number(data?.available_quantity).toFixed(3)),
      }, () => {
        axios.get(`${B2b_URL.GET_SUBCONTRACT_ITEM_LIST}?subcontract_id=${data?.subcontract_id}&V=${StaticAppVersion()}`)
          .then(response => {
            const responseData = response.data
            this.setState({
              categoryItems: responseData,
              items: responseData
            })
          })
          .catch(err => {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 4000)
          })
      })
    }
  }

  fetchSubContractDropdownListDetail = (orderID, plantID) => {
    if (orderID && plantID) {
      this.setState({ subContractDropdownListLoader: true })
      axios.get(
        `${B2b_URL.GET_SUBCONTRACT_LIST}?order_id=${orderID}&plant_id=${plantID}&V=${StaticAppVersion()}`,
        this.getOptions(this.props.user))
        .then(response => {
          let responseData = response.data['SubContract List data']
          // let responseData = String(plantID).includes('303055004') ? [] : dummyData
          this.setState({
            subContractDropdownList: responseData,
            subContractDropdownListLoader: false,
          })
        })
        .catch(err => {
          this.setState({ subContractDropdownListLoader: false })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        })
    }
  }

  selectedPlantAndItem = (selected) => {
    console.log(selected)
    if (selected?.sub_contract_enabled) {
      this.setState({ plantSubContractEnabled: true }, () => {
        this.fetchSubContractDropdownListDetail(this.state?.selectedOrderId, selected.plant)
      })
    } else {
      this.setState({ plantSubContractEnabled: false })
    }
    // console.log(parseFloat(selected.pending_qty))
    const itemqty = this.state?.setOrderAndItem
    let totalqty = 0
    itemqty &&
      Array.from(itemqty.entries()).map(
        ([key, value]) => (
          value.map(item => {
            // console.log(item.quantity, totalqty)
            totalqty += parseFloat(item.quantity)
          })
        ))
    // console.log(totalqty)
    let itemTotal = parseFloat(totalqty) || 0
    if (selected === '') {
      this.setState({
        selectedPlant: '',
        selectedPlantPendingQuantity: 0,
        selectedPlantAvailableQuantity: 0,
      })
    }
    else {
      this.setState({
        selectedPlant: {
          plant_name: selected?.plant_name,
          id: selected?.plant,
          pending: selected?.pending_qty,
          order_available_quantity: selected?.order_available_quantity
        },
        selectedPlantPendingQuantity: Number(selected?.order_available_quantity),
        selectedPlantAvailableQuantity: Number(Number(Number(selected?.order_available_quantity).toFixed(3)) + Number(Number(itemTotal).toFixed(3))),
        // , selectedItem: {
        //   item_category_id: selected.item_category_id,
        //   item_category: selected.item_category,
        //   pending: selected.pending,

        // }
      })
    }
  };

  LAItemsMethod = (LAitems, newLAItem) => {
    let data = (LAitems.find(d => d?.item?.id == newLAItem.id))
    if (data?.item?.id == newLAItem.id && Number(data?.item_qty).toFixed(3) === Number(newLAItem?.quantity).toFixed(3)) {
      return 'UNCHANGE';
    }
    else if (data?.item?.id == newLAItem.id && Number(data?.item_qty).toFixed(3) !== Number(newLAItem?.quantity).toFixed(3)) {
      return 'UPDATED';
    }
    else {
      return 'ADDED';
    }
  }

  handleLASubmit = (data, mode = '', id = '', laNo = '', loading_advice_items = '') => {
    // console.log(this.state.categoryItems)

    const {
      gateInDate,
      transporterContactNumber,
      transporterName,
      truckCapacity,
      truckNumber,
      driverName,
      driverContactNumber,
      selectedBuyer,
      selectedConsignee,
      setOrderId,
      setOrderAndItem,
    } = data
    let items = []
    let editLAItems = []
    let deletedItems = []
    let orderID = ""

    // order
    mode === 'edit' ?
      Array.from(setOrderAndItem.entries()).map(([key, value]) => {
        value.map(data => {
          editLAItems.push({
            id: (loading_advice_items.find(d => d?.item?.id == data.id))?.id || null,
            item: data.id,
            item_qty: data.quantity,
            method: this.LAItemsMethod(loading_advice_items, data)
          })

        })

        deletedItems = (loading_advice_items.filter(d => !editLAItems.find(Item => Item?.item === d?.item?.id)))
        console.log('deletedItems ', deletedItems)
        deletedItems.length > 0 && deletedItems.map(data => {
          editLAItems.push({
            id: data?.id,
            item: data?.item?.id,
            item_qty: data?.item_qty,
            method: 'DELETED',
            actual_section_count: data.actual_section_count,
            alloted_weight: data.alloted_weight,
            average_section_weight: data.average_section_weight,
            created_at: data.created_at,
            created_by: data.created_by,
            loading_advice: data.loading_advice,
            modified_at: data.modified_at,
            modified_by: data.modified_by,
            net_weight: data.net_weight,
            planned_section_count: data.planned_section_count,
            rate_difference: data.rate_difference,
            remark: data.remark,
            section_name: data.section_name,
            section_weight: data.section_weight,
            weighing: data.weighing,
          })
        })
      })

      :
      Array.from(setOrderAndItem.entries()).map(([key, value]) =>
        value.map(data => {
          items.push({
            item: data.id,
            item_qty: data.quantity
          })

        })
      )
    console.log("1", this.state.selectedOrderItemCategoryId)
    let LAData = {
      gate_in_date: gateInDate,
      plant: this.state.selectedPlant.id,
      item_category: this.state.selectedOrderItemCategoryId && this.state.selectedOrderItemCategoryId,
      truck_number: truckNumber,
      truck_capacity: truckCapacity,
      transporter: this.state.isTransporterAvailable
        ? this.state.transporterID === 'Not Available'
          ? null
          : this.state.transporterID
        : null,
      transporter_detail: this.state.isTransporterAvailable
        ? null
        : {
          acc_name: transporterName === 'Not Available' ? null : transporterName,
          mobile: transporterContactNumber === 'Not Available' ? null : transporterContactNumber
        },
      driver_name: driverName === "" ? null : driverName,
      driver_contact_number: driverContactNumber === "" ? null : driverContactNumber,
      buyer: selectedBuyer.id,
      consignee: selectedConsignee.id,
      payment_method: this.state.selectedPaymentMethodId,
      order: this.state.selectedOrderId,
      items: mode === 'edit' ? editLAItems : items,
      cess_amount: "0.00",
      cess_rate: "0",
      driver_licence: this.state.driverLicenseNumber === "" ? null : this.state.driverLicenseNumber,
      freight_charge_amount: "0",
      freight_charge_rate: "0",
      fright_advance: "0",
      mode_of_transport: "ROAD",
      tran_sup_type: this.state.selectedTransType,
      customer_remark: this.state?.customerRemark,
    }
    console.log("subContract= ", this.state.subContract, !checkInvalidValue(this.state?.subContract))
    if (!checkInvalidValue(this.state?.subContract)) {
      LAData.sub_contract = this.state?.subContract;
      LAData.created_from_subcontract = true;
    }

    // this.props.history.push({ pathname: `/dispatch/singleDispatch/${LAData.order}`, state: LAData })
    // console.log(LAData)
    this.setState({ LASubmitLoader: true })
    mode === 'edit' ? (
      axios.patch(`${B2b_URL.UPDATE_LA}${id}/?V=${StaticAppVersion()}`, LAData)
        .then(response => {
          // console.log(response)
          if (response.status === 200) {
            let laNumber = laNo
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Loading Advice updated Successfully",
              snackbarType: "success"
            }, () => {
              // localStorage.setItem("returnUrl", '/dispatch')
              // localStorage.setItem("prevUrl", '/dispatch')
              setTimeout(() => {
                this.props.history.push('/dispatch')
                this.fetchLADetails(laNumber, "LA updated")
                this.clearAllCreateDispatchData()
                this.setState({ LASubmitLoader: false })
                this.handleCloseSnackbar();
                // this.props.history.push({ pathname: `/dispatch/singleDispatch/${laNumber}` })
              }, 3000)
            })
          }
        })
        .catch(error => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.setState({ LASubmitLoader: false })
            this.handleCloseSnackbar();
          }, 4000)
        })
    )
      : (
        axios.post(`${B2b_URL.CREATE_LA}?V=${StaticAppVersion()}`, LAData)
          .then(response => {
            console.log(response)
            if (response.status === 201) {
              let laNumber = response.data?.la_number
              this.setState({
                openSnackbar: true,
                snackbarMessage: "Loading Advice created Successfully",
                snackbarType: "success"
              }, () => {
                setTimeout(() => {
                  this.fetchLADetails(laNumber, "LA created")
                  this.clearAllCreateDispatchData()
                  this.setState({ LASubmitLoader: false })
                  this.handleCloseSnackbar();
                  this.props.history.push({ pathname: `/dispatch/singleDispatch/${laNumber}`, state: LAData })
                }, 3000)
              })
            }
          })
          .catch(error => {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              this.setState({ LASubmitLoader: false })
              this.handleCloseSnackbar();
            }, 4000)
          })
      )
  }

  handleCancelLA = (id) => {
    axios.patch(`${B2b_URL.CANCEL_LA}${id}/?V=${StaticAppVersion()}`)
      .then(response => {
        this.handleOpenSnackbar('Loading Advice cancelled Successfully');
        setTimeout(() => {
          this.props.history.push("/dispatch/")
          this.handleCloseSnackbar();
        }, 3000)
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  handleCloseLAForm = (type) => {
    // console.log("closed")
    this.setState({
      openDialog: !this.state.openDialog
    })
    if (type === "clearData")
      this.clearAllCreateDispatchData()
    // if ([window.location.pathname].includes('/edit')) {
    //   console.log(this.props.history)
    //   let returnURL = String(localStorage.getItem("returnUrl"))
    //   // this.props.history.push(returnURL);
    //   this.fetchLADetails(returnURL.slice(returnURL.indexOf('LA'),))
    // }
  }

  clearAllCreateDispatchData = () => {
    this.setState({
      gateInDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedPlant: "",
      truckNumber: "",
      truckCapacity: "",
      transporterName: "",
      transporterContactNumber: "",
      isTransporterAvailable: true,
      transporterID: false,
      driverName: "",
      driverContactNumber: "",
      buyerName: "",
      consigneeName: "",
      paymentMethod: "",
      LADetails: null,
      setOrderId: "",
      setOrderAndItem: new Map(),
      selectedOrderQuantity: null,
      selectedBuyer: null,
      selectedConsignee: null,
      selectedPaymentMethodId: null,
      selectedOrderId: null,
      categoryItems: null,
      setOrderId: "",
      setItem: "",
      setItemID: "",
      buyerId: "",
      consigneeId: "",
      selectedItem: null,
      plantsList: null,
      plantPlanningLoader: null,
      submitLALoader: null,
      selectedBuyer: null,
      selectedConsignee: null,
      plantPlanningLoader: null,
      submitLALoader: null,
      loadingAdviceLoader: false,
      singleLALoader: false,
      createLAAllowed: false,
      showLACreateError: false,
      itemQtyError: false,
      selectedOrder: null,
      LALazyLoader: false,
      lazyLoad: true,
      customerRemark: null,
      selectedEditSubContractNumber: "",
      subContractNumber: "",
      subContract: "",
      plantSubContractEnabled: false,
      subContractDropdownList: [],
      subContractDropdownListLoader: false,
      selectedSubContractDetail: {},
      selectedSubContractPayment: {},
      selectedOrderItemCategoryId: null
    });
  };
  render() {
    const {

      items,
      gateInDate,
      selectedPlant,
      truckNumber,
      truckCapacity,
      transporterName,
      transporterContactNumber,
      driverName,
      driverContactNumber,
      buyerName,
      consigneeName,
      paymentMethod,
      pageHeight,
      consigneesList,
      buyersList,
      paymentMethods,
      ordersList,
      ordersListLoader,
      paymentMethodID,
      categoryItems,
      selectedLAItems,
      selectedOrderQuantity,
      setItemQuantity,
      addAnotherOrder,
      snackbarMessage, snackbarType, openSnackbar,
      loadingAdviceLoader,
      loadingAdviceList,
      singleLALoader,
      LALazyLoader,
      nextURL,
      lazyLoad,
      allPlantList,
      showDispatch,
      isOffline,
      downloadInvoiceReady,
      b2CQrImage,
      customerRemark,
      subContract,
      selectedEditSubContractNumber,
      subContractNumber,
      subContractDropdownList,
      subContractDropdownListLoader,
      plantSubContractEnabled,
      selectedSubContractDetail,
      selectedSubContractPayment,
    } = this.state;
    const { isMobile } = this.props;
    console.log("totalLAItemsQuantity = ", this.state?.totalLAItemsQuantity)

    return (
      <div>
        <Route
          exact
          path="/dispatch"
          render={(props) =>
            isMobile ? (
              <Dispatch {...props} user={this.props.user} {...this.props}
                fetchAllLA={this.fetchAllLA}
                loadingAdviceList={loadingAdviceList}
                fetchLADetails={this.fetchLADetails}
                loadingAdviceLoader={loadingAdviceLoader}
                LALazyLoader={LALazyLoader}
                fetchNextLA={this.fetchNextLA}
                lazyLoad={lazyLoad}
                nextURL={nextURL}
                handleCloseBackDrop={this.handleCloseBackDrop}
                singleLALoader={singleLALoader}
                allPlantList={allPlantList}
                showDispatch={showDispatch}
                handleDispatchSelectChip={this.handleDispatchSelectChip}
              />
            ) : (
              <DispatchWeb {...props} user={this.props.user} {...this.props}
                fetchAllLA={this.fetchAllLA}
                loadingAdviceList={loadingAdviceList}
                fetchLADetails={this.fetchLADetails}
                loadingAdviceLoader={loadingAdviceLoader}
                LALazyLoader={LALazyLoader}
                fetchNextLA={this.fetchNextLA}
                lazyLoad={lazyLoad}
                nextURL={nextURL}
                handleCloseBackDrop={this.handleCloseBackDrop}
                singleLALoader={singleLALoader}
                allPlantList={allPlantList}
                showDispatch={showDispatch}
                handleDispatchSelectChip={this.handleDispatchSelectChip}
              />
            )
          }
        />
        {/* <Route
          exact
          path="/dispatch/pending/:id"
          render={(props) =>
            isMobile ? (
              <PendingDispatch {...props} {...this.props}
                details={this.state.LADetails}
                singleLALoader={singleLALoader}
                fetchLADetails={this.fetchLADetails}
              />
            ) : (
              <PendingDispatchWeb {...props} {...this.props}
                details={this.state.LADetails}
                singleLALoader={singleLALoader}
                fetchLADetails={this.fetchLADetails}
              />
            )
          }
        /> */}
        {/* <Route
          exact
          path="/dispatch/action/:id"
          render={(props) =>
            isMobile ? (
              <ConfirmDispatch {...props} {...this.props} />
            ) : (
              <ConfirmDispatchWeb {...props} {...this.props} />
            )
          }
        /> */}
        <Route
          exact
          path={["/dispatch/singleDispatch/:id", "/dispatch/cancelled/:id", "/dispatch/dispatched/:id"]}
          render={(props) =>
            isMobile ? (
              <SingleDispatch
                {...props}
                user={this.props.user}
                {...this.props}
                details={this.state.LADetails}
                loadingAdviceList={loadingAdviceList}
                singleLALoader={singleLALoader}
                fetchLADetails={this.fetchLADetails}
                handleInvoiceClick={this.handleInvoiceClick}
                handlePostInvoiceDownload={this.handlePostInvoiceDownload}
                b2CQrImage={b2CQrImage}
                downloadInvoiceReady={downloadInvoiceReady}
                laDetailsInDispatchCounter={this.state?.laDetailsInDispatchCounter}
                invoiceType={this.state?.invoiceType}
                plant_state={this.state?.plant_state}
                eInvoiceResponse={this.state?.eInvoiceResponse}
                eWayBillResponse={this.state?.eWayBillResponse}
                iseWaySelect={this.state?.iseWaySelect}
                handleCancelLA={this.handleCancelLA}
              />
            ) : (
              <SingleDispatchWeb
                {...props}
                user={this.props.user}
                {...this.props}
                details={this.state.LADetails}
                loadingAdviceList={loadingAdviceList}
                singleLALoader={singleLALoader}
                fetchLADetails={this.fetchLADetails}
                handleInvoiceClick={this.handleInvoiceClick}
                b2CQrImage={b2CQrImage}
                handlePostInvoiceDownload={this.handlePostInvoiceDownload}
                downloadInvoiceReady={downloadInvoiceReady}
                laDetailsInDispatchCounter={this.state?.laDetailsInDispatchCounter}
                invoiceType={this.state?.invoiceType}
                plant_state={this.state?.plant_state}
                eInvoiceResponse={this.state?.eInvoiceResponse}
                eWayBillResponse={this.state?.eWayBillResponse}
                iseWaySelect={this.state?.iseWaySelect}
                handleCancelLA={this.handleCancelLA}
              />
            )
          }
        />
        <Route
          exact
          path={["/dispatch/create", "/dispatch/edit/:id"]}
          render={(props) =>
            isMobile ? (
              <DispatchCreate
                {...props}
                {...this.props}
                {...this.state}
                pageHeight={pageHeight}
                user={this.props.user}
                laDetails={this.props.location?.state?.laDetails}
                mode={this.props.location?.state?.mode}
                handleEditData={this.handleEditData}
                handleItemEdit={this.handleItemEdit}
                items={categoryItems}
                handleTextChange={this.handleTextChange}
                handleDateChange={this.handleDateChange}
                handleTextChangeErrorCheck={this.handleTextChangeErrorCheck}
                gateInDate={gateInDate}
                selectedPlant={selectedPlant}
                selectedPlantAndItem={this.selectedPlantAndItem}
                truckNumber={truckNumber}
                truckCapacity={truckCapacity}
                transporterName={transporterName}
                transporterContactNumber={transporterContactNumber}
                driverName={driverName}
                driverContactNumber={driverContactNumber}
                buyerName={buyerName}
                consigneeName={consigneeName}
                paymentMethod={paymentMethod}
                handleSelectBuyerName={this.handleSelectBuyerName}
                handleSelectConsigneeName={this.handleSelectConsigneeName}
                handleSelectPaymentMethod={this.handleSelectPaymentMethod}
                clearAllCreateDispatchData={this.clearAllCreateDispatchData}
                fetchBuyerList={this.fetchBuyerList}
                fetchConsigneeList={this.fetchConsigneeList}
                buyersList={buyersList}
                consigneesList={consigneesList}
                paymentMethods={paymentMethods}
                ordersList={ordersList}
                ordersListLoader={ordersListLoader}
                paymentMethodID={paymentMethodID}
                selectedLAItems={selectedLAItems}
                customerRemark={customerRemark}
                setOrderIdAndAmount={this.setOrderIdAndAmount}
                handleSaveOrderData={this.handleSaveOrderData}
                handleSetOrderItem={this.handleSetOrderItem}
                deleteOrder={this.deleteOrder}
                deleteOrderItem={this.deleteOrderItem}
                handleOrderSelect={this.handleOrderSelect}
                categoryItems={this.state.categoryItems}
                handleSelectOrderId={this.handleSelectOrderId}
                handleLASubmit={this.handleLASubmit}
                selectedOrderQuantity={selectedOrderQuantity}
                setItemQuantity={setItemQuantity}
                handleCloseLAForm={this.handleCloseLAForm}
                fetchPlantsList={this.fetchPlantsList}
                handleTransTypeSelect={this.handleTransTypeSelect}
                checkItemQtyError={this.checkItemQtyError}
                handleShowQuantityError={this.handleShowQuantityError}
                checkError={this.checkError}
                handleShowTransporterTextFields={this.handleShowTransporterTextFields}
                handleTransportCancel={this.handleTransportCancel}
                LASubmitLoader={this.state.LASubmitLoader}
                fetchLADetails={this.fetchLADetails}
                fetchOrderList={this.fetchOrderList}
                handleSubcontractFormDetail={this.handleSubcontractFormDetail}
                subContract={subContract}
                selectedEditSubContractNumber={selectedEditSubContractNumber}
                subContractNumber={subContractNumber}
                subContractDropdownList={subContractDropdownList}
                subContractDropdownListLoader={subContractDropdownListLoader}
                plantSubContractEnabled={plantSubContractEnabled}
                selectedSubContractDetail={selectedSubContractDetail}
                selectedSubContractPayment={selectedSubContractPayment}
              />
            ) : (
              <DispatchCreateWeb
                {...props}
                {...this.props}
                {...this.state}
                pageHeight={pageHeight}
                user={this.props.user}
                laDetails={this.props.location?.state?.laDetails}
                mode={this.props.location?.state?.mode}
                handleEditData={this.handleEditData}
                handleItemEdit={this.handleItemEdit}
                items={categoryItems}
                handleTextChange={this.handleTextChange}
                handleDateChange={this.handleDateChange}
                handleTextChangeErrorCheck={this.handleTextChangeErrorCheck}
                gateInDate={gateInDate}
                selectedPlant={selectedPlant}
                selectedPlantAndItem={this.selectedPlantAndItem}
                truckNumber={truckNumber}
                truckCapacity={truckCapacity}
                transporterName={transporterName}
                transporterContactNumber={transporterContactNumber}
                driverName={driverName}
                driverContactNumber={driverContactNumber}
                buyerName={buyerName}
                consigneeName={consigneeName}
                paymentMethod={paymentMethod}
                customerRemark={customerRemark}
                handleSelectBuyerName={this.handleSelectBuyerName}
                handleSelectConsigneeName={this.handleSelectConsigneeName}
                handleSelectPaymentMethod={this.handleSelectPaymentMethod}
                clearAllCreateDispatchData={this.clearAllCreateDispatchData}
                fetchBuyerList={this.fetchBuyerList}
                fetchConsigneeList={this.fetchConsigneeList}
                buyersList={buyersList}
                consigneesList={consigneesList}
                paymentMethods={paymentMethods}
                ordersList={ordersList}
                ordersListLoader={ordersListLoader}
                paymentMethodID={paymentMethodID}
                selectedLAItems={selectedLAItems}
                setOrderIdAndAmount={this.setOrderIdAndAmount}
                handleSaveOrderData={this.handleSaveOrderData}
                handleSetOrderItem={this.handleSetOrderItem}
                deleteOrder={this.deleteOrder}
                deleteOrderItem={this.deleteOrderItem}
                handleOrderSelect={this.handleOrderSelect}
                categoryItems={this.state.categoryItems}
                handleSelectOrderId={this.handleSelectOrderId}
                handleLASubmit={this.handleLASubmit}
                selectedOrderQuantity={selectedOrderQuantity}
                setItemQuantity={setItemQuantity}
                handleCloseLAForm={this.handleCloseLAForm}
                fetchPlantsList={this.fetchPlantsList}
                handleTransTypeSelect={this.handleTransTypeSelect}
                checkItemQtyError={this.checkItemQtyError}
                handleShowQuantityError={this.handleShowQuantityError}
                checkError={this.checkError}
                handleShowTransporterTextFields={this.handleShowTransporterTextFields}
                handleTransportCancel={this.handleTransportCancel}
                LASubmitLoader={this.state.LASubmitLoader}
                fetchLADetails={this.fetchLADetails}
                fetchOrderList={this.fetchOrderList}
                handleSubcontractFormDetail={this.handleSubcontractFormDetail}
                subContract={subContract}
                selectedEditSubContractNumber={selectedEditSubContractNumber}
                subContractNumber={subContractNumber}
                subContractDropdownList={subContractDropdownList}
                subContractDropdownListLoader={subContractDropdownListLoader}
                plantSubContractEnabled={plantSubContractEnabled}
                selectedSubContractDetail={selectedSubContractDetail}
                selectedSubContractPayment={selectedSubContractPayment}
              />
            )
          }
        />
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
        <Snackbar
          open={isOffline}
          onClose={this.handleClose}
          autoHideDuration={4000}
          TransitionComponent={"up"}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          message={<span id="message-id">No Internet Connection</span>}
        />
      </div>
    );
  }
}
export default withUser(withRouter(B2bDispatchContainer));
