import React, { Component } from 'react'
import { makeStyles } from "@material-ui/core";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Print from "@material-ui/icons/Print";
import Tooltip from "@mui/material/Tooltip";
import Moment from 'moment'
import imageCode from "./ImageCode.json"
import pdfContentData from './data.json'
import { arraySortBykeyAllBrowser, checkInvalidValue, printPDFInMobile } from '../../../../methods';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

let pdfData
let tableSubHeaderItemList = []
let columnSpansList = []

export class ReportsDynamicPDFDownload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tableData: [],
    }
  }

  fetchHeaders = (pdfProps) => {
    // console.log(pdfProps)
    const { pdfHeader, reportName, } = pdfProps
    let tableHeaders = []
    let tableSubHeaders = []
    let headers = []

    headers = pdfHeader
    headers.forEach(header => {
      if (header.splitCol) {
        columnSpansList.push(header.header)
      }
    })

    tableSubHeaderItemList = pdfProps?.state?.planned_plants || []

    let subheaders = [{ header: "", key: "" }]
    headers.map((head, i) => {
      !(columnSpansList.includes(head.header) && tableSubHeaderItemList.length === 0) &&
        tableHeaders.push({
          text: head.header,
          style: ["itemsHeader", "center"],
          border: [true, true, true, true],
          colSpan: columnSpansList.includes(head.header) ? tableSubHeaderItemList.length : 1
        })

      if (columnSpansList.includes(head.header) && tableSubHeaderItemList.length > 0) {
        tableSubHeaderItemList.map((d, k) => {
          tableSubHeaders.push({
            text: d[reportName === 'Sub Contracts' && 'itemSize'],
            style: ["itemsSubHeader", "center"],
            border: [true, true, true, true],
          })
          subheaders.push(d)

          if (k !== 0) {
            tableHeaders.push({
              text: '',
              style: ["itemsHeader", "center"],
              border: [true, true, true, true],
            })
          }
        })
      } else {
        !(columnSpansList.includes(head.header) && tableSubHeaderItemList.length === 0) &&
          tableSubHeaders.push({
            text: "",
            style: ["itemsHeader", "center"],
            border: [false, false, false, false],
          })
        subheaders.push(head)
      }
    })

    let finalHeaders = []
    let pdfSubHeader = []
    pdfHeader.forEach(header => {
      if (columnSpansList.includes(header.header)) {
        tableSubHeaderItemList.forEach(d => {
          pdfSubHeader.push(
            {
              minWidth: 40,
              header: reportName === 'Sub Contracts' && d.itemSize,
              key: reportName === 'Sub Contracts' && d.itemSize,
            }
          )
        })
      } else {
        pdfSubHeader.push({
          minWidth: 40,
          header: "",
          key: ""
        })
      }
    })

    headers.forEach((header, i) => {
      if (columnSpansList.includes(header.header)) {
        tableSubHeaderItemList.forEach(d => {
          let key = reportName === 'Sub Contracts' && d.itemSize;
          finalHeaders.push({
            header: reportName === 'Sub Contracts' && d.itemSize,
            calculation: key,
            dec_places: 3,
            key
          })
        })
      } else {
        finalHeaders.push(header)
      }
    })

    console.log(finalHeaders)
    if (headers) {
      let unSortedData = JSON.parse(JSON.stringify(pdfProps.data))
      console.log("sorted =", pdfProps.data, unSortedData, arraySortBykeyAllBrowser(unSortedData, 'customer', '', 'contract'))
      this.handleCalculations({
        dataList: arraySortBykeyAllBrowser(unSortedData, 'customer', '', 'contract'),
        pdfProps,
        tableHeaders,
        headers,
        pdfContentData,
        tableSubHeaders,
        subheaders,
        finalHeaders,
        reportName: pdfProps.reportName,
        pdfHeader: pdfHeader,
        tableSubHeaderItemList: tableSubHeaderItemList
      })
    }
  }

  groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      if (obj[key]) {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      }
      return objectsByKeyValue;
    }, {});

  handleCalculations = (data) => {
    const { dataList, headers, reportName, finalHeaders, tableSubHeaderItemList } = data
    let calculationNeededFor = []
    finalHeaders.forEach(header => {
      if (header.calculation) {
        calculationNeededFor.push(header)
      }
    })

    let groupByLA = this.groupBy('contract')
    let groupedList = groupByLA(dataList)
    console.log(groupedList, dataList)
    Object.keys(groupedList).forEach((element, index) => {
      if (element !== "undefined") {
        groupedList[element].forEach((ele, i) => {
          ele.index = `${index + 1}.${i + 1}`
        })
      }
    })

    Object.keys(groupedList).forEach(element => {
      let fromIndex = dataList.findIndex(ele => ele.contract === element)
      let toIndex = dataList.findLastIndex(ele => ele.contract === element)
      let filteredRowForRateCalc = (groupedList[element]?.filter(d => !["NOT ALLOTED", "BEFORE SUBCONTRACT ENABLED"].includes(d.subcontract_number)))?.length

      let calcObj = {}
      calculationNeededFor.forEach(calc => {
        if (["dispached_qty", "balance_quantity",].includes(calc.key)) {
          tableSubHeaderItemList.map(d => {
            let calcValue = 0
            let key = reportName === 'Sub Contracts' && d.itemSize;
            for (let i = fromIndex; i <= toIndex; i++) {
              let item = dataList[i]
              calcValue += Number(item[key] === undefined ? 0 : item[key])
            }

            calcObj[key] = ["avg_ex_rate", "avg_freight_rate"].includes(calc.calculation)
              ? (calcValue / (filteredRowForRateCalc)).toFixed(calc.dec_places)
              : calcValue.toFixed(calc.dec_places)
          })
        }
        else {
          let calcValue = 0
          let key = calc.key
          for (let i = fromIndex; i <= toIndex; i++) {
            let item = dataList[i]
            calcValue += Number(item[key] || 0)
          }

          calcObj[key] = ["avg_ex_rate", "avg_freight_rate"].includes(calc.calculation)
            ? (calcValue / (filteredRowForRateCalc)).toFixed(calc.dec_places)
            : calcValue.toFixed(calc.dec_places)
        }
      })
      calcObj[reportName === 'Sub Contracts' && "tag_name"] = "SUB TOTAL"
      calcObj["isSubTotalRow"] = true
      calcObj["id"] = toIndex + 1
      dataList.splice(toIndex + 1, 0, calcObj)
    })
    let subTotalList = dataList.filter(l => l.isSubTotalRow == true)
    let actualList = dataList.filter(l => l.isSubTotalRow !== true)
    let grandCalcObj = {}
    let AvgField = ['EX_rate', 'freight_rate']
    let filteredRowForRateTotalCalc = (actualList.filter(d => !["NOT ALLOTED", "BEFORE SUBCONTRACT ENABLED"].includes(d.subcontract_number)))?.length
    Object.keys(subTotalList[0]).forEach(key => {
      if (key !== 'isSubTotalRow') {
        console.log(filteredRowForRateTotalCalc, AvgField.includes(key) && Number(Number(actualList.reduce((a, c) => { return +a + Number(c[key] || 0) }, 0)) / Number(filteredRowForRateTotalCalc || 0)), AvgField.includes(key) && Number(Number(actualList.reduce((a, c) => { return +a + Number(c[key] || 0) }, 0)) / Number(filteredRowForRateTotalCalc || 0)).toFixed(2))
        grandCalcObj[key] = key === 'freight_rate' ? "" : AvgField.includes(key)
          ? Number(Number(actualList.reduce((a, c) => { return +a + Number(c[key] || 0) }, 0)) / Number(filteredRowForRateTotalCalc || 0)).toFixed(2)
          : Number(actualList.reduce((a, c) => { return +a + Number(c[key] || 0) }, 0)).toFixed(3)
      }
    })
    grandCalcObj[reportName === 'Sub Contracts' && "tag_name"] = "TOTAL CALCULATION"
    grandCalcObj["isGrandTotalRow"] = true
    grandCalcObj["id"] = dataList.length + 1
    dataList.splice(dataList.length, 0, grandCalcObj)

    pdfData = dataList
    console.log(pdfData)
    this.handlePDFDownload(data)
  }

  handlePDFDownload = (pdfdata) => {
    const {
      pdfProps, tableHeaders, tableSubHeaders,
      finalHeaders, reportName, pdfHeader, tableSubHeaderItemList
    } = pdfdata
    let tableDetails = []
    let tableData = []

    // total width in landscape = 660
    let columnWidths = []
    let rowData = []
    let headerColumnWidths = ["50%", "50%"]

    let selectedPlants = pdfProps.plants ? pdfProps.plants.join(', ') : null
    let SelectedItemCats = pdfProps.itemCats ? pdfProps.itemCats.join(', ') : null
    let selectedCustomerType = pdfProps.customerType ? pdfProps.customerType.join(', ') : null
    let selectedCustomerTypeL1 = pdfProps.customerTypeL1 ? pdfProps.customerTypeL1.join(', ') : null
    let SelectedItemTag = pdfProps.ItemTag ? pdfProps.ItemTag.join(', ') : null
    let SelectedStatus = pdfProps.status ? pdfProps.status.join(', ') : null

    tableDetails.push([
      {
        text: `Report name - ${pdfProps.reportName}`,
        style: "leftAlignDetails",
        border: [false, false, false, false],
      },
      {
        text: `Date Range - From ${(Moment(pdfProps.startDate).format('DD-MMM-yyyy'))} To ${(Moment(pdfProps.endDate).format('DD-MMM-yyyy'))}`,
        style: "rightAlignDetails",
        border: [false, false, false, false],
      },
    ])

    tableDetails.push([
      {
        text: `Date of Generation - ${(Moment(new Date()).format('DD-MMM-yyyy'))}`,
        style: "leftAlignDetails",
        border: [false, false, false, false],
      },
      {
        text: `User Name - ${pdfProps.user.first_name} ${pdfProps.user.last_name}`,
        style: "rightAlignDetails",
        border: [false, false, false, false],
      },
    ])

    tableDetails.push([
      {
        text: `Item Category name - ${SelectedItemCats}`,
        style: "leftAlignDetails",
        border: [false, false, false, false],
      },
      {
        text: `Customer Type - ${selectedCustomerType}`,
        style: "rightAlignDetails",
        border: [false, false, false, false],
      },
    ])

    tableDetails.push([
      {
        text: `Customer Type L1 - ${selectedCustomerTypeL1}`,
        style: "leftAlignDetails",
        border: [false, false, false, false],
      },
      {
        text: `Item tag - ${SelectedItemTag}`,
        style: "rightAlignDetails",
        border: [false, false, false, false],
      },
    ])
    tableDetails.push([
      {
        text: `Sub Contract Status - ${SelectedStatus}`,
        style: "leftAlignDetails",
        border: [false, false, false, false],
      },
      {
        text: ``,
        style: "rightAlignDetails",
        border: [false, false, false, false],
      },

    ])

    columnWidths = [10, 30, 40, 40, 35, 35, 35, 30, 30, 35, 40, 35, 35, 30, 30, 30, 10, 30, 30]

    tableData.push(tableHeaders)
    tableData.push(tableSubHeaders)


    pdfData.map((item, i) => {
      for (let j = 0; j < finalHeaders.length; j++) {
        rowData.push(
          {
            text: item[finalHeaders[j].key],
            // border: [false, false, false, true],
            border: [true, true, true, true],
            style: ["tableItems"],
            bold: (item[reportName === 'Sub Contracts' && "tag_name"] === 'SUB TOTAL' ||
              item[reportName === 'Sub Contracts' && "tag_name"] === "TOTAL CALCULATION") && true,
            alignment: (Number(item[finalHeaders[j].key]) || Number(item[finalHeaders[j].key]) == 0)
              ? 'center'
              : "left",
            fillColor: (item?.isSubTotalRow || item?.isGrandTotalRow) && "#f5f5f5"
          }
        )
      }
      tableData.push(rowData)

      if (rowData.find(d => d.text == 'SUB TOTAL') !== undefined) {
        rowData = []
        for (let j = 0; j < finalHeaders.length; j++) {
          rowData.push(
            {
              text: " ",
              border: [false, false, false, false],
              style: ["tableItems", "left"],
            }
          )
        }
        tableData.push(rowData)
      }
      rowData = []
    })
    console.log(tableData)

    const dd = this.docdef(
      tableDetails,
      headerColumnWidths,
      tableData,
      pdfHeader,
      tableSubHeaderItemList
    )

    const date = new Date()
    const pdfReportName = `${pdfProps.reportName}_${Moment(date).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`

    if (!window.cordova) {
      // console.log("herere", pdfReportName)
      pdfMake
        .createPdf(dd)
        .open()
      // .download(pdfReportName, pdfProps.handleOpenSnackClick());
    }
    else {
      let fileName = `${pdfProps.reportName}`
      printPDFInMobile(pdfMake, dd, fileName, pdfProps.handleOpenSnackClick, '')
    }
  }

  docdef = (tableDetails, headerColumnWidths, tableData, pdfHeader, tableSubHeaderItemList) => {
    let letDyanmicBodyColumnWidth = []
    let staticColumnsLength = (pdfHeader.filter(d => d?.splitCol !== true)).length
    let staticStartColumnsLength = (pdfHeader.findIndex(d => d?.splitCol === true))
    let staticLastColumnsLength = staticColumnsLength - staticStartColumnsLength
    let dynamicFieldlength = tableSubHeaderItemList.length
    // console.log(staticColumnsLength, tableData, dynamicFieldlength, staticStartColumnsLength, staticLastColumnsLength)
    let startingStaticColumnWidth = dynamicFieldlength === 0
      ? [42, 42, 58, 38, 45, 35, 35, 28, 28, 30]
      : dynamicFieldlength > 0 && dynamicFieldlength <= 4
        ? [40, 40, 52, 34, 40, 35, 35, 28, 28, 30]
        : dynamicFieldlength > 4 && dynamicFieldlength <= 5
          ? [30, 32, 52, 34, 42, 28, 30, 26, 24, 28,]
          : dynamicFieldlength > 5
            ? [26, 32, 52, 34, 42, 28, 30, 26, 24, 28]
            : dynamicFieldlength === 7
              ? [26, 32, 52, 28, 32, 28, 30, 26, 20, 20]
              : dynamicFieldlength >= 8 && [23, 29, 49, 25, 29, 25, 27, 26, 17, 17]
    let lastStaticColumnWidth = dynamicFieldlength === 0
      ? [32, 40, 40, 38, 30,]
      : dynamicFieldlength > 0 && dynamicFieldlength <= 4
        ? [30, 36, 38, 35, 30,]
        : dynamicFieldlength > 4
          ? [27, 36, 32, 34, 28]
          : dynamicFieldlength === 7
            ? [21, 30, 28, 30, 22]
            : dynamicFieldlength >= 8 && [20, 28, 27, 28, 20]
    let middleDynamicColumnWidth = Array(dynamicFieldlength).fill(
      dynamicFieldlength > 0 && dynamicFieldlength <= 1
        ? 38
        : dynamicFieldlength === 2 || dynamicFieldlength <= 3
          ? 25
          : dynamicFieldlength === 4
            ? 21
            : dynamicFieldlength === 5
              ? 21
              : dynamicFieldlength >= 6 && 19
    )

    letDyanmicBodyColumnWidth.push(...startingStaticColumnWidth)
    letDyanmicBodyColumnWidth.push(...middleDynamicColumnWidth)
    letDyanmicBodyColumnWidth.push(...lastStaticColumnWidth)
    console.log(letDyanmicBodyColumnWidth)
    return {
      pageOrientation: 'landscape',
      header: function (currentPage) {
        if (currentPage === 1)
          return {
            columns: [
              {
                image: imageCode,
                width: 50,
                height: 50,
                margin: [10, 0, 0, 0]
              }
            ]
          }
      },
      footer: function (currentPage, pageCount) {
        return {
          table: {
            widths: ["*"],
            body: [
              [
                { text: 'page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 5, 35, 0], fontSize: 7, }
              ]
            ]
          },
          layout: 'noBorders'
        };
      },

      content:
        [
          {
            table: {
              body: tableDetails,
              widths: headerColumnWidths,
              dontBreakRows: true,
            },
            margin: [0, 10, 0, 0],
          },
          {
            table: {
              headerRows: 2,
              body: tableData,
              widths: letDyanmicBodyColumnWidth,
              dontBreakRows: true,

            },
            margin: dynamicFieldlength <= 1
              ? [20, 10, 30, 0]
              : dynamicFieldlength <= 3
                ? [0, 10, 0, 0]
                : dynamicFieldlength <= 5
                  ? [-15, 10, 0, 0]
                  : dynamicFieldlength <= 6
                    ? [-20, 10, 0, 0]
                    : dynamicFieldlength >= 7 && [-38, 10, 0, 0]
          },
          ,
        ],

      styles: {
        itemsHeader: {
          padding: [0, 2, 0, 2],
          fontSize: 7,
          bold: true,
          fillColor: "#f5f5f5",
          alignment: "center",
        },
        itemsSubHeader: {
          padding: [-5, 2, -5, 2],
          fontSize: 6.5,
          bold: true,
          fillColor: "#f5f5f5",
          alignment: "center",
        },
        tableItems: {
          padding: [-10, 2, -5, 2],
          fontSize: 6,
          alignment: "left",

        },
        leftAlignDetails: {
          alignment: "left",
          fontSize: 10,
        },
        rightAlignDetails: {
          alignment: "right",
          fontSize: 10,
        }
      },
    };
  }


  render() {
    return (
      <Tooltip title="Download PDF" placement="top">
        <div>
          <Print onClick={() => {
            this.fetchHeaders(this.props)
          }}
            style={{ color: "#4b87c5", cursor: "pointer", marginTop: 9, }} />
        </div>
      </Tooltip>
    )
  }
}

export default ReportsDynamicPDFDownload
