import React, { Component } from "react";
import "../../../styles/Register.css";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import Button from "@material-ui/core/Button";
import {
  dailyRateScreenTextColor,
  secondaryColor,
} from "../../../styles/colors";
import { withRouter } from "react-router";
import SpinLoader from "../../common/SpinLoader";

const styles = {
  mainBodyRegisterSuccessfull: {
    padding: "15px 15px 0px 15px",
    display: "flex",
    flexDirection: "column",
  },
  registerFormContainer: {
    height: window.innerHeight - 145,
    display: "flex",
    flexDirection: "column",
  },
  paperStyle: {
    width: "31%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    // height: "95%",
  },
  textFieldStyle: {
    width: "95%",
    backgroundColor: "white",
  },

  cancleButtonStyle: {
    marginTop: "10px",
    // color: dailyRateScreenTextColor,
    color: "white",
    backgroundColor: "#1B388B",
    width: '45%'
  },
  emailTextStyle: {
    color: secondaryColor,
    fontSize: "1.4em",
  },
};
class RegisterSuccessfull extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }

  render() {
    const {
      classes,
      legal_name_of_business,
      date_of_registration,
      Gst_number,
      email,
      contactNumber,
      registrationLoader,
      panNumber,
    } = this.props;
    return (
      <div className={classes.mainBodyRegisterSuccessfull}>
        {registrationLoader ? (
          <div
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinLoader />
          </div>
        ) : (
          <div>
            <div className="register-successfull-header-img">
              <div className={classes.paperStyle}>
                <img
                  src={
                    window.cordova
                      ? `${this.image}${"/RealDealWeb2.png"}`
                      : "/RealDealWeb2.png"
                  }
                  className="img-style-in-register"
                  alt="img"
                />
              </div>
            </div>
            {console.log(this.props.history)}
            <div className={classes.registerFormContainer}>
              <div className="register-successful-main-div" style={{ flex: 1 }}>
                <div className="register-successfull-text-div">
                  <span
                    style={{
                      color: dailyRateScreenTextColor,
                      marginLeft: "6px",
                    }}
                  >
                    Registration Successfull
                  </span>
                </div>
                <div className="register-successfull-company-detail-show-div">
                  <div className="register-successfull-company-detail">
                    <div className="register-successfull-paper">
                      <div className="register-successful-paper-left">
                        <span style={{ color: dailyRateScreenTextColor }}>
                          {legal_name_of_business}
                        </span>
                        <span className="register-id-text">
                          {panNumber === "" ? Gst_number : panNumber}
                        </span>
                        <span className="register-email-text">{email}</span>
                        <span className="register-mobile-text">
                          {contactNumber}
                        </span>
                      </div>
                      {/* <div className="register-successfull-paper-right">
                    <span>
                      <Add />
                    </span>
                  </div> */}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="registeration-date-show-div">
                  <div className="registeration-date-show-left">
                    {/* <span style={{ color: dailyRateScreenTextColor }}>
                      Registration date
                    </span> */}
                    {/* <span className="text-style-firm">Firm ID</span> */}
                  </div>
                  <div className="registeration-date-show-right">
                    <span style={{ color: dailyRateScreenTextColor }}>
                      {date_of_registration}
                    </span>
                    {/* <span className="text-style-firm">WE#$33444</span> */}
                  </div>
                </div>
                <br />

                {/* <div className="message-after-registration">
                  <span>an email has been sent to your mail</span>
                  <span className={classes.emailTextStyle}>{email}</span>
                  <span>Kindly verify it</span>
                </div>
                <br /> */}
                <br />
                <div className="message-after-registration">
                  <span style={{ color: secondaryColor }}>
                    Please wait while our team verify your details.
                  </span>
                  <span style={{ color: secondaryColor }}>
                    This should not take more than 72 hours.
                  </span>
                </div>
              </div>
              <div className="register-successfull-logout-div">
                <Button
                  className={classes.cancleButtonStyle}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/login");
                  }}
                >
                  Login
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(RegisterSuccessfull));
