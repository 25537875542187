import React, { Component } from "react";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../styles/colors";
import { withRouter } from "react-router";
import { isValidDriverLicense, checkNameFieldAlphaNumeric, checkInvalidValue } from "../../../methods";
import SaveButton from "../../internal/common/SaveButton";

const styles = {
    submitButton: {
        height: "100%",
        color: "white",
        fontWeight: "bold",
        backgroundColor: primaryColor,
    }
};

class Stepper extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    checkTransporterError = () => {
        const {
            transporterName,
            transporterContactNumber,
            transporterContactNumberLengthError,
            transporterNameError,
            transporterID,
            activeStep,
            isTransporterAvailable,
        } = this.props;


        if (activeStep === 1) {
            // console.log(transporterName)
            if (transporterName === 'Not Available') {
                return false
            }
            else {
                if (isTransporterAvailable) {
                    if (transporterID) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    if (transporterName === ""
                        || transporterContactNumber === ""
                        || transporterContactNumber.length < 10
                        || transporterNameError
                        || transporterContactNumberLengthError) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        }

        return false
    }

    render() {
        const {
            theme,
            activeStep,
            gateInDate,
            selectedPlant,
            truckNumber,
            truckCapacity,
            transporterName,
            transporterContactNumber,
            driverName,
            driverContactNumber,
            buyerName,
            consigneeName,
            paymentMethod,
            setOrderId,
            setOrderAndItem,
            startDate,
            endDate,
            driverLicenseNumber,
            itemQtyError,
            selectedPlantPendingQuantity,
            selectedOrderId,
            driverContactNumberLengthError,
            truckNumberValidationError,
            transporterContactNumberLengthError,
            driverLicenseNumberError,
            transporterNameError,
            driverNameError,
            capacityError,
            truckCapacityError,
            LASubmitLoader,
            subContractDropdownList,
            plantSubContractEnabled,
            subContract,
        } = this.props;
        // console.log((capacityError))
        // console.log(activeStep === 1 && (driverContactNumber.length > 0 && driverContactNumber.length < 10))
        return (
            <div>
                <MobileStepper
                    style={{ height: "56px" }}
                    variant="dots"
                    steps={5}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        activeStep === 4 ? (
                            <SaveButton
                                variant="contained"
                                style={styles.submitButton}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (this.props?.laState?.mode !== 'edit' || this.props?.laState?.mode == undefined) {
                                        let prevUrl = localStorage.getItem("returnUrl");
                                        localStorage.setItem("prevUrl", prevUrl)
                                    }
                                    this.props.handleLASubmit(
                                        this.props,
                                        this.props?.laState?.mode === 'edit' && 'edit',
                                        this.props?.laState?.mode === 'edit' && this.props?.laState?.laDetails?.id,
                                        this.props?.laState?.mode === 'edit' && this.props?.laState?.laDetails?.la_number,
                                        this.props?.laState?.mode === 'edit' && this.props?.laState?.laDetails?.loading_advice_items,
                                    )
                                    // this.props.history.push("/dispatch/singleDispatch/123");
                                }}
                                isLoading={LASubmitLoader}
                            >
                                Submit
                            </SaveButton>
                        ) : (
                            <Button
                                size="small"
                                onClick={
                                    // activeStep !== 3 ?
                                    this.props.handleNextStepper
                                    // : this.props.checkQtyError
                                }
                                disabled={
                                    (activeStep === 0 && gateInDate === "") ||
                                    (activeStep === 0 && gateInDate < startDate) ||
                                    (activeStep === 0 && gateInDate > endDate) ||
                                    (activeStep === 0 && selectedPlant === "") ||
                                    (activeStep === 0 && (selectedPlantPendingQuantity && Number(selectedPlantPendingQuantity) === 0)) ||
                                    (activeStep === 0 && (plantSubContractEnabled && (subContractDropdownList?.length === 0 || checkInvalidValue(subContract)))) ||
                                    (activeStep === 1 && truckNumber === "") ||
                                    (activeStep === 1 && truckCapacity === "") ||
                                    (activeStep === 1 && truckCapacity[0] === 0) ||
                                    (activeStep === 1 && truckCapacityError) ||
                                    this.checkTransporterError() ||
                                    (activeStep === 1 && truckNumberValidationError) ||
                                    (activeStep === 1 && driverLicenseNumberError) ||
                                    (activeStep === 1 && driverNameError) ||
                                    (activeStep === 1 && driverContactNumberLengthError) ||
                                    (activeStep === 2 && buyerName === "") ||
                                    (activeStep === 2 && consigneeName === "") ||
                                    (activeStep === 2 && paymentMethod === "") ||
                                    (activeStep === 3 && setOrderId === "") ||
                                    (activeStep === 3 && setOrderAndItem.size === 0) ||
                                    (activeStep === 3 && capacityError)

                                }
                            >
                                {activeStep === 4 ? "Submit" : "Next"}
                                {theme.direction === "rtl" ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        )
                    }
                    backButton={
                        <Button
                            size="small"
                            disabled={LASubmitLoader}
                            onClick={(e) => {
                                e.preventDefault();
                                if (activeStep === 0) {
                                    this.props.handleCloseLAForm()
                                    // this.props.history.push("/dispatch");
                                } else if (activeStep === 3) {
                                    this.props.handleBackStepper();
                                }
                                else {
                                    this.props.handleBackStepper();
                                }
                            }}
                        //disabled={activeStep === 0}
                        >
                            {theme.direction === "rtl" ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </div>
        );
    }
}
export default withRouter(withStyles(styles, { withTheme: true })(Stepper));
