import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
// import PlantTable from "./PlantTable";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import "../../../../styles/internal/scroll.css";
import { darkGrey, grey } from "../../../../constants/internal/colors";
import Bread from "../../common/Breadcrubs";
import { isAccessibleWidget, pxToEm } from "../../../../methods";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { CSVLink } from "react-csv";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { Box, IconButton, InputAdornment, Modal, TextField, Typography } from "@material-ui/core";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from "@material-ui/icons/EditOutlined";
// import { GridActionsCellItem } from "@mui/x-data-grid";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CircularProgressLoader from "../../../common/CircularProgressLoader";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Table from "../../common/Table";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";

// import InfoIcon from "@material-ui/icons/InfoOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4b87c5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: 'Poppins',
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "#fff",
//   },
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

<StyledTableCell align="center">
  Transporter Info
</StyledTableCell>

const customersStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    height: `calc(100vh - 250px)`,
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  itemCategory: {
    width: "100%",
    display: "flex",
    alignments: "center",
  },
  freight: {
    width: "100%",
    diaplay: "flex",
    alignItems: "center",
  },
  filterSection: {
    margin: "25px 0px 20px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterAlignment: {
    display: "flex",
    alignItems: "center",
  },
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  searchBox: {
    width: "100%",
  },
  formControl: {
    minWidth: "100%",
    marginLeft: "10px",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    // paddingBottom: "30px",
    // paddingTop: "10px",
    // paddingLeft: "30px",
  },
  rightPadding: { paddingRight: "25px" },
  fontColor: {
    color: "gray",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "20px",
  },
  EInvoiceContainer: {
    width: "100%",
    // padding: "30px",
  },
  plantNameText: {
    color: darkGrey,
    margin: "8px 0px",
    fontSize: "1.0em",
  },
  tabLabelContainer: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.3em",
  },
  tabLabelSecondaryText: {
    fontSize: "0.8em",
    color: grey,
  },
  serachFieldBar: {
    width: "30%",
    display: "flex",
    left: 10,
    justifyContent: "flex-start",
  },
  dropdownText: {

    '& .MuiTypography-root': {
      padding: '0',
      fontFamily: 'Poppins',
      fontSize: pxToEm(12),
      lineHeight: 1
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};
const headers = [
  {
    label: "Plant",
    key: "plant_code",
  },
  {
    label: "Invoice Date",
    key: "doc_date",
  },
  {
    label: "Invoice Number",
    key: "doc_no",
  },

  {
    label: "Supplier GSTIN",
    key: "sup_gstin",
  },
  {
    label: "Receiver GSTIN",
    key: "rec_gstin",
  },

  {
    label: "Receiver Name",
    key: "rec_name",
  },
  {
    label: "Receiver Pincode",
    key: "rec_pincode",
  },
  {
    label: "Transport Distance",
    key: "trans_distance",
  },
  {
    label: "Transporter Name",
    key: "transporter_name",
  },
  {
    label: "Vehicle Number",
    key: "vehicle_no",
  },
  {
    label: "Eway Expiry",
    key: "eway_valid_upto",
  },
  {
    label: "Eway State",
    key: "eway_state",
  },
  {
    label: "Eway Number",
    key: "eway_number",
  },
  {
    label: "Eway Created At",
    key: "eway_created_at",
  },
  {
    label: "Eway Remark",
    key: "eway_remark",
  },
  {
    label: "Quantity",
    key: "quantity",
  },
  {
    label: "HSN Code",
    key: "hsn_code",
  },
  {
    label: "IRN",
    key: "irn",
  },
  {
    label: "Freight",
    key: "freight_basis"
  },
  {
    label: "ItemCategory",
    key: "item_category"
  }
];
function DispatchTrackingWeb({
  classes,
  invoices,
  selectedDate,
  // handleDateChange,
  handleSelect,
  filters,
  selectedFilter,
  plants,
  plant,
  selectPlant,
  selectedCategory,
  handleSelectedMoreFilter,
  moreFilters,
  selectedMoreFilter,
  data,
  filter,
  isMobile,
  ...props
}) {
  // const plantsStatic = [
  //   {
  //     name: "Nova",
  //     code: "NV",
  //     EInvUserName: "AAACN0407F_API_MAH",
  //     EInvPassword: "Nisl@1234567",
  //     companyName: "Nova Iron And Steel Limited",
  //     bankName: "ICICI BANK",
  //     accountNumber: "016105009326",
  //     ifscCode: "ICIC0000161",
  //     upiId: "",
  //   },
  //   {
  //     name: "Borjhara",
  //     code: "RB",
  //     EInvUserName: "realispat1_API_MAH",
  //     EInvPassword: "Realispat1234@",
  //     companyName: "Real Ispat & Power Limited",
  //     bankName: "State Bank of India",
  //     accountNumber: "30011973134",
  //     ifscCode: "SBIN0008536",
  //     upiId: "realispat@sbi", // realispatandpowerltd@sbi, MAB0450047A0000178@Yesbank
  //   },
  //   {
  //     name: "Urla Plant",
  //     code: "RU",
  //     EInvUserName: "realispat1_API_MAH",
  //     EInvPassword: "Realispat1234@",
  //     companyName: "Real Ispat & Power Limited",
  //     bankName: "State Bank of India",
  //     accountNumber: "30011973134",
  //     ifscCode: "SBIN0008536",
  //     upiId: "realispatandpowerltd@sbi", // MAB0450047A0000178@Yesbank
  //   },
  //   {
  //     name: "Shivalay",
  //     code: "SH",
  //     EInvUserName: "SHIVALAYIS_API_MAH",
  //     EInvPassword: "Ispat@202012",
  //     companyName: "Shivalay Ispat & Power Private Limited",
  //     bankName: "State Bank of India",
  //     accountNumber: "37993289611",
  //     ifscCode: "SBIN0008536",
  //     upiId: "sippl@sbi", // shivalayispatandpower@sbi
  //   },
  //   {
  //     name: "API Ispat",
  //     code: "AP",
  //     EInvUserName: "apigstn123_API_MAH",
  //     EInvPassword: "Sudhir#19923",
  //     companyName: "API Ispat & Power Private Limited",
  //     bankName: "State Bank of India",
  //     accountNumber: "35297672702",
  //     ifscCode: "SBIN0008536",
  //     upiId: "apiipl@sbi", // apiispatandpower@sbi, MAB0450047A0000175@Yesbank
  //   },
  // ];

  const [plantsData, setPlantsData] = React.useState([]);
  const [tableData, setTableData] = React.useState({
    data: []
  });
  const [tableFilterData, setTableFilterData] = React.useState({
    data: []
  });
  // const [tabValue, setTabValue] = React.useState(0);
  const [plantName, setPlantName] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [searchField, setSearchField] = React.useState(null)
  // const [freightBasisData, setFreightBasisData] = React.useState({});
  const [selectedFreightFilters, setSelectedFreightFilters] = React.useState('');
  const [selectedItemCategories, setSelectedItemCategories] = useState([]);
  const [itemCategories, setItemCategories] = React.useState([]);
  const [itemCategoriesName, setItemCategoriesName] = React.useState([]);
  const [itemMasterData, setItemMasterData] = React.useState([]);
  const [freightBasisMasterData, setFreightBasisMasterData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [selectedPlants, setSelectedPlants] = React.useState([]);
  const [plantItemCategoryData, setPlantItemCategoryData] = React.useState([]);

  useEffect(() => {
    // rerendering when tablefilter data update
  }, [tableFilterData])
  useEffect(() => {
    const data = plantsData.filter((p) =>
      plantName.includes(p.plant_short_name)
    );
    const code = data.map((d) => d.plant_code);
    handlePlantItemCategoryList(code)
  }, [plantName])

  useEffect(() => {
    const data = plantItemCategoryData.filter(item =>
      itemCategoriesName.includes(item.item_category_name)
    );
    const code = data.map(d => d.id)
    console.log(itemCategoriesName, data, code)
    setSelectedItemCategories(code)
  }, [itemCategoriesName])

  const headersNew = [
    // {
    //   minWidth: 50,
    //   header: "Index",
    //   key: "id",
    // },
    {
      minWidth: 110,
      header: "Date",
      key: "doc_date",
      // flex: 1,
    },
    {
      minWidth: 100,
      header: "Invoice #",
      key: "doc_no",
      flex: 1,
    },
    {
      minWidth: 80,
      header: "Plant",
      key: "plant_code",
      flex: 1,
    },
    {
      minWidth: 100,
      header: "Receivers Name",
      key: "rec_name",
      flex: 1,
    },
    // {
    //   minWidth: 100,
    //   header: "Item Category",
    //   key: "item_category",
    //   flex: 1,
    // },
    // {
    //   minWidth: 80,
    //   header: "Freight",
    //   key: "freight",
    //   flex: 1,
    // },

    {
      minWidth: 110,
      header: "Transporter Info",
      key: "vehicle_no",
      flex: 1,
      renderCell: (params) => <TransportInfo {...params} />
    },
    {
      minWidth: 100,
      header: "Quantity",
      key: "quantity",
      flex: 1,
    },
    {
      minWidth: 130,
      header: "EWB #",
      key: "eway_number",
      flex: 1,
    },
    {
      minWidth: 125,
      header: "Eway Expiry",
      key: "eway_valid_upto",
      flex: 1,
    },
    {
      minWidth: 130,
      header: "Eway State",
      key: "eway_state",
      renderCell: (params) => <EwayState {...params} />,
      flex: 1,
    },
    {
      minWidth: 120,
      header: "Action",
      // type: 'actions',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => <CountButton {...params} handleOnClick={handleOnClick} user={props.user} />,
      flex: 1,
    },
  ];

  const TransportInfo = (props) => {
    return (
      <Tooltip
        title={`${props?.row.vehicle_no}`}
        placement="top"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}>
          <InfoIcon />
        </div>
      </Tooltip>
    )
  }

  const EwayState = (props) => {
    // console.log(props)
    const row = props?.row?.eway_state
    return (
      row.eway_state === "open w/ext" ? (
        moment().format("D-M-YYYY").toString() ===
          moment(row.eway_valid_upto)
            .format("D-M-YYYY")
            .toString() ? (
          <Tooltip
            title={row.logs.map((l) => (
              <div style={{ marginBottom: "10px" }}>
                <p style={{ margin: 0 }}>
                  Updated by: {l.creator_name}
                </p>
                <p style={{ margin: 0 }}>
                  Updated On : {l.created_at.slice(0, 10)}
                </p>
                <p style={{ margin: 0 }}>
                  New Extension Date : {l.new_expiry_date}
                </p>
                <p style={{ margin: 0 }}>
                  Remark : {l.new_remark}
                </p>
              </div>
            ))}
            placement="top"
          >
            <div>
              <Chip
                style={{
                  backgroundColor: "#ef6c00",
                  color: "#fff",
                }}
                label={"OPEN (E)"}
              />
            </div>
          </Tooltip>
        ) : Date.parse(new Date()) >
          Date.parse(moment(row.eway_valid_upto,
            [
              "DD/MM/YYYY hh:mm:ss A",
              "DD/MM/YYYY hh:mm:ss",
              "DD/MM/YYYY",
              "YYYY-MM-DD hh:mm:ss A",
              "YYYY-MM-DD",
              "YYYY-MM-DD hh:mm:ss"
            ]).format("YYYY-MM-DD")) ? (
          <Tooltip
            title={row.logs.map((l) => (
              <div style={{ marginBottom: "10px" }}>
                <p style={{ margin: 0 }}>
                  Updated by: {l.creator_name}
                </p>
                <p style={{ margin: 0 }}>
                  Updated On : {l.created_at.slice(0, 10)}
                </p>
                <p style={{ margin: 0 }}>
                  New Extension Date : {l.new_expiry_date}
                </p>
                <p style={{ margin: 0 }}>
                  Remark : {l.new_remark}
                </p>
              </div>
            ))}
            placement="top"
          >
            <div>
              <Chip
                style={{
                  backgroundColor: "#b22a00",
                  color: "#fff",
                }}
                label={"OVERDUE (E)"}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            // title={`${row.logs[0].created_at} | ${row.logs[0].creator_name}`}
            // sx={{ width: "1000px", height: "100px" }}
            title={row.logs.map((l) => (
              <div style={{ marginBottom: "10px" }}>
                <p style={{ margin: 0 }}>
                  Updated by: {l.creator_name}
                </p>
                <p style={{ margin: 0 }}>
                  Updated On : {l.created_at.slice(0, 10)}
                </p>
                <p style={{ margin: 0 }}>
                  New Extension Date : {l.new_expiry_date}
                </p>
                <p style={{ margin: 0 }}>
                  Remark : {l.new_remark}
                </p>
              </div>
            ))}
            placement="top"
          >
            <div>
              <Chip
                style={{
                  backgroundColor: "#2e7d32",
                  color: "#fff",
                }}
                label={"OPEN (E)"}
              />
            </div>
          </Tooltip>
        )
      ) : moment().format("D-M-YYYY").toString() ===
        moment(row.eway_valid_upto)
          .format("D-M-YYYY")
          .toString() ? (
        <Chip
          style={{
            backgroundColor: "#ef6c00",
            color: "#fff",
          }}
          label={"OPEN"}
        />
      ) : Date.parse(new Date()) >
        Date.parse(moment(row.eway_valid_upto,
          [
            "DD/MM/YYYY hh:mm:ss A",
            "DD/MM/YYYY hh:mm:ss",
            "DD/MM/YYYY",
            "YYYY-MM-DD hh:mm:ss A",
            "YYYY-MM-DD",
            "YYYY-MM-DD hh:mm:ss"
          ]).format("YYYY-MM-DD")) ? (
        <Chip
          style={{
            backgroundColor: "#b22a00",
            color: "#fff",
          }}
          label={"OVERDUE"}
        />
      ) : Date.parse(new Date()) <
        Date.parse(moment(row.eway_valid_upto,
          [
            "DD/MM/YYYY hh:mm:ss A",
            "DD/MM/YYYY hh:mm:ss",
            "DD/MM/YYYY",
            "YYYY-MM-DD hh:mm:ss A",
            "YYYY-MM-DD",
            "YYYY-MM-DD hh:mm:ss"
          ]).format("YYYY-MM-DD")) ? (
        <Chip
          style={{
            backgroundColor: "#2e7d32",
            color: "#fff",
          }}
          label={"OPEN"}
        />
      ) : (
        ""
      )
    )
  }

  const CountButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const [selectedrow, setSelectedRow] = React.useState(null);
    const [actions, setActions] = React.useState("");
    const [dateValue, setDateValue] = React.useState(null);
    const [remarkData, setRemarkData] = React.useState("");
    const [dateError, setDateError] = React.useState(false);

    const handleOpen = (row) => {
      setSelectedRow(row);
      setOpen(true);
    }

    const handleClose = () => {
      setSelectedRow(null);
      setOpen(false);
      setDateValue("");
    }

    const handleDateChange = (newValue) => {
      setDateValue(newValue);
      console.log("Date selected:", newValue)
      console.log(JSON.stringify(newValue));
      console.log(newValue);
    };

    const handleActionChange = (event) => {
      setActions(event.target.value);
    };
    let stringify = JSON.stringify(dateValue);
    const handleUpdate = (id, object) => {
      setDateError(false);
      if (actions === "cancel") {
        new Promise((resolve, reject) => {
          axios({
            // url: `https://ngsales.realdeals.pro/api/erp/update/ewaybill/tracking/${id}`,
            url: `${APIROUTES.UPDATE_EWAY_DATA_TRACKING}${id}`,
            method: "patch",
            headers: {
              Authorization: `JWT ${props.user.token}`,
              "Content-Type": "application/json;charset=UTF-8",
            },
            data: { eway_state: actions, eway_remark: remarkData },
          })
            .then((response) => {
              resolve(response);
              setOpen(false);

              handleOnClick(plantName);
            })
            .catch(reject);
        });
      } else if (actions === "open w/ext") {
        if (stringify === "null") {
          setDateError(true);
        } else if (stringify != null) {
          // let finalDate = JSON.stringify(dateValue);
          console.log("finalDate format", moment(dateValue).format("YYYY-MM-DD"));
          new Promise((resolve, reject) => {
            axios({
              // url: `https://ngsales.realdeals.pro/api/erp/update/ewaybill/tracking/${id}`,
              url: `${APIROUTES.UPDATE_EWAY_DATA_TRACKING}${id}`,
              method: "patch",
              headers: {
                Authorization: `JWT ${props.user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
              },
              data: {
                eway_valid_upto: moment(dateValue).format("YYYY-MM-DD"),
                eway_state: actions,
                eway_remark: remarkData,
              },
            })
              .then((response) => {
                resolve(response);
                handleOnClick(plantName);
                setOpen(false);
              })
              .catch(reject);
          });
        }
      } else if (actions === "closed") {
        new Promise((resolve, reject) => {
          axios({
            // url: `https://ngsales.realdeals.pro/api/erp/update/ewaybill/tracking/${id}`,
            url: `${APIROUTES.UPDATE_EWAY_DATA_TRACKING}${id}`,
            method: "patch",
            headers: {
              Authorization: `JWT ${props.user.token}`,
              "Content-Type": "application/json;charset=UTF-8",
            },
            data: { eway_state: actions, eway_remark: remarkData },
          })
            .then((response) => {
              resolve(response);
              handleOnClick(plantName);
              setOpen(false);
            })
            .catch(reject);
        });
      }
    };
    let dataHandleChange = function (event) {
      setRemarkData(event.target.value);
    };


    return (
      <>
        <Button
          startIcon={<EditIcon />}
          style={{ color: grey }}
          onClick={(e) => { handleOpen(props.row); setActions("") }}
        />
        <Modal
          open={open}
          onClose={() => {
            handleClose();
          }}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" marginBottom="15px">
              Select new state of EWB
            </Typography>
            <FormControl fullWidth>
              {" "}
              <Select value={actions} onChange={handleActionChange}>
                <MenuItem value={"closed"}>Delivered to Customer</MenuItem>
                <MenuItem value={"cancel"}>EWB Cancelled</MenuItem>
                <MenuItem value={"open w/ext"}>EWB Extended</MenuItem>
              </Select>
            </FormControl>
            {actions === "open w/ext" ? (
              <div style={{ marginTop: "20px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {/* {console.log("initial date", moment(selectedrow?.eway_valid_upto, ['DD /MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD'))} */}
                  {/* {console.log("original format", selectedrow?.eway_valid_upto)} */}
                  <DesktopDatePicker
                    required
                    label="Select Date"
                    input="date"
                    inputFormat="dd/MM/yyyy"
                    value={moment(moment(dateValue || selectedrow?.eway_valid_upto, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')).toDate()}
                    minDate={moment(moment(dateValue || selectedrow?.eway_valid_upto, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')).toDate()}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={dateError}
                        helperText={dateError ? "Fill Date To Proceed" : ""}
                      />
                    )}
                    readonly={false}
                  />
                </LocalizationProvider>

                <TextField
                  sx={{ marginTop: "20px" }}
                  id="outlined-basic"
                  label="Add your notes"
                  variant="outlined"
                  defaultValue={selectedrow?.eway_remark}
                  onChange={dataHandleChange}
                />
              </div>
            ) : null}
            {actions === "closed" ? (
              <div style={{ marginTop: "20px" }}>
                <TextField
                  sx={{ marginTop: "20px" }}
                  id="outlined-basic"
                  label="Add your notes"
                  variant="outlined"
                  defaultValue={selectedrow?.eway_remark}
                  onChange={dataHandleChange}
                />
              </div>
            ) : null}
            {actions === "cancel" ? (
              <div style={{ marginTop: "20px" }}>
                <TextField
                  sx={{ marginTop: "20px" }}
                  id="outlined-basic"
                  label="Add your notes"
                  variant="outlined"
                  defaultValue={selectedrow?.eway_remark}
                  onChange={dataHandleChange}
                />
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button
                variant="text"
                style={{ marginRight: "10px" }}
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleUpdate(selectedrow.id, selectedrow);
                }}
              >
                OK
              </Button>
            </div>
          </Box>
        </Modal>
      </>
    );
  };
  useEffect(() => {
    setPlantsData(props.plantItemMasterList)
  }, [props.plantItemMasterList])

  useEffect(() => {
    setItemMasterData(props.itemCategoryMasterList)
  }, [props.itemCategoryMasterList])

  useEffect(() => {
    setFreightBasisMasterData(props.freightBasisMaster)
  }, [props.freightBasisMaster])

  // useEffect(() => {
  //   console.log(tableFilterData?.data, selectedFreightFilters)
  //   if (searchField?.length > 0) {
  //     if (selectedFreightFilters.length > 0) {
  //       let dropdownfilterData = tableFilterData?.data.filter(d => Array(selectedFreightFilters).includes(d?.freight))
  //       console.log("IF 1 :", dropdownfilterData)
  //       setTableFilterData({ data: dropdownfilterData })
  //     }
  //     else {
  //       console.log("else 1 :")
  //       setTableFilterData({ data: tableFilterData?.data })
  //     }
  //   }
  //   else {
  //     if (selectedFreightFilters.length > 0) {
  //       let dropdownfilterData = tableData.data.filter(d => Array(selectedFreightFilters).includes(d?.freight))
  //       console.log("IF 1 :", dropdownfilterData)
  //       setTableFilterData({ data: dropdownfilterData })
  //     }
  //     else {
  //       console.log("else 1 :")
  //       setTableFilterData({ data: tableData.data })
  //     }
  //   }
  // }, [selectedFreightFilters])

  const handleItemCategoryFilter = (e) => {
    const { value } = e.target;
    if (value.includes("")) {
      setItemCategoriesName([])
    }
    else {
      setItemCategoriesName(
        typeof value === "string" ? value.split(",") : value
      )
    }
  };

  const handleDropdownFilter = (e) => {
    const { value } = e.target;
    setSelectedFreightFilters(value)
  }

  const handlePlantItemCategoryList = (code) => {
    console.log(code)
    let filterPlant = plantsData.filter(d => code.includes(d?.plant_code))
    let itemCategoriesList = []
    filterPlant.map(p => {
      p.item_categories.map(item => {
        itemCategoriesList.length > 0
          ?
          (!Boolean((itemCategoriesList?.find(data => data?.id === item?.item_category_id)))) &&
          itemCategoriesList.push({
            id: item?.item_category_id,
            item_category_name: item?.item_category_name,
            is_active: item?.is_active
          })
          :
          itemCategoriesList.push({
            id: item?.item_category_id,
            item_category_name: item?.item_category_name,
            is_active: item?.is_active
          })
      })
    })
    itemCategoriesList.length > 0
      ? setPlantItemCategoryData(itemCategoriesList)
      : setPlantItemCategoryData([])
  }
  const handleSearch = (event) => {
    setSearchField(event.target.value)
    const lowercasedValue = event.target.value.toLowerCase().trim();
    if (lowercasedValue !== '') {
      const filteredData = tableData?.data?.filter((item) => {
        return Object.keys(item).some((key) =>
          !['vehicle_no', 'eway_number', 'doc_no'].includes(key)
            ? false
            : item[key] !== null &&
              typeof (event.target.value) === typeof ('')
              ? item[key]?.toString().toLowerCase().trim().includes(lowercasedValue)
              : typeof (event.target.value) === typeof (9)
                ? item[key]?.toString().includes((event.target.value).toString())
                : item[key]?.toString().includes((event.target.value).toString())
        );
      });
      setTableFilterData({ data: filteredData })
    } else {
      setTableFilterData({ data: tableData.data })
    }
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPlantName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleOnClick = (code, selectedItemCategories, selectedFreightFilters) => {
    setLoading(true);
    let baseURL = `${APIROUTES.GET_EWAY_DATA_TRACKING}?plant_code=${code}`
    let params = selectedItemCategories.length > 0 && selectedFreightFilters !== ''
      ? `&item_category_ids=${selectedItemCategories.join(',')}&freight_basis=${selectedFreightFilters}`
      : selectedItemCategories.length > 0 && selectedFreightFilters === ''
        ? `&item_category_ids=${selectedItemCategories.join(',')}`
        : selectedFreightFilters !== '' && selectedItemCategories.length <= 0
          ? `&freight_basis=${selectedFreightFilters}`
          : ''
    let finalURL = params !== '' ? baseURL + params : baseURL

    axios({
      url: finalURL,
      method: "get",
      headers: {
        Authorization: `JWT ${props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    }).then(
      (response) => {
        const formattedData = response.data?.map((row, index) => ({
          freight_basis: row.freight_basis,
          id: row.id,
          doc_date: moment(row.token_date).format("DD-MM-YYYY") !== "Invalid date" ? moment(row.token_date).format("DD-MM-YYYY") : moment(row.doc_date).format("DD-MM-YYYY"),
          doc_no: row.doc_no,
          plant_code: (plantsData.find(d => d?.plant_code === row.plant_code))?.plant_short_name,
          rec_name: row.rec_name,
          vehicle_no: `${row.vehicle_no} | ${row.transporter_name}`,
          freight: row?.invoice_details?.freight_basis,
          quantity: parseFloat(row.quantity).toFixed(3),
          eway_number: row.eway_number,
          eway_valid_upto: moment(row.eway_valid_upto, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("DD-MM-YYYY") ===
            "Invalid date"
            ? `${moment(
              row.eway_valid_upto.substring(0, 10),
              "DD/MM/YYYY"
            ).format("DD-MM-YYYY")} 23:59:00`
            : `${moment(row.eway_valid_upto, ["DD/MM/YYYY", "YYYY-MM-DD"]).format(
              "DD-MM-YYYY"
            )}
            23:59:00`,
          eway_state: row,
        }))
        setTableData({
          ...tableData,
          data: formattedData
        })
        setTableFilterData({
          ...tableFilterData,
          data: formattedData
        })
        // setTableData(response.data);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  };
  console.log(tableData.data)
  return (
    <>
      <Layout {...props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <Bread
              data={[
                {
                  title: "Dispatch Tracking",
                },
              ]}
            />
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: isMobile ? 'flex-start' : "center",
                  justifyContent: "space-between",
                  flexDirection: isMobile && 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: isMobile ? 'flex-start' : "center",
                    justifyContent: 'space-between',
                    width: isMobile ? '100%' : 700,
                    flexDirection: isMobile && 'column',
                    marginBottom: isMobile && 20
                  }}
                >
                  <FormControl sx={{ m: 1, width: isMobile ? '90%' : 150 }} size='small'>
                    <InputLabel
                      style={{ width: "100%" }}
                      id="demo-simple-select-label"
                      variant="outlined"
                      label="Outlined"
                    >
                      Select Plants
                    </InputLabel>
                    <Select
                      multiple
                      value={plantName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Select Plants" />}
                      // renderValue={(selected) => selected.map(d => d.slice(0, 10).concat('.. ')).join(", ")}
                      renderValue={(selected) => selected.join(", ")}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      MenuProps={MenuProps}
                      style={{
                        fontFamily: 'Poppins',
                      }}
                    >
                      {plantsData.map((data) => {
                        if (isAccessibleWidget(props.user, data.plant_code + '_DispatchTracking'))
                          return (
                            <MenuItem
                              key={data.plant_code}
                              value={data.plant_short_name}
                              style={{ height: 45 }}
                            >
                              <Checkbox
                                style={{ borderColor: 'gray', color: '#1976d2' }}
                                checked={plantName.indexOf(data.plant_short_name) > -1}
                              />

                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }
                                }}

                                primary={data.plant_short_name}
                                className={classes.dropdownText}
                              />
                            </MenuItem>
                          );
                        else return null;
                      })}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, width: isMobile ? '90%' : 180 }} size="small">
                    <InputLabel id="item-category-filter-label">Item Categories</InputLabel>
                    {console.log(itemCategoriesName)}
                    <Select
                      multiple
                      value={itemCategoriesName}
                      disabled={plantName?.length === 0}
                      onChange={handleItemCategoryFilter}
                      input={<OutlinedInput label="Item Categories" />}
                      labelId="item-category-filter-label"
                      id="item-category-filter"
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      style={{
                        fontFamily: 'Poppins',
                      }}
                    >
                      <MenuItem key="ALL" value="">
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={itemCategoriesName.length === 0}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          primary="ALL"
                          className={classes.dropdownText}
                        />
                      </MenuItem>
                      {plantItemCategoryData ?
                        plantItemCategoryData.map((category) => (
                          <MenuItem key={category.id} value={category.item_category_name}>
                            <Checkbox
                              style={{ borderColor: 'gray', color: '#1976d2' }}
                              checked={itemCategoriesName.indexOf(category.item_category_name) > -1}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                },
                              }}
                              primary={category.item_category_name}
                              className={classes.dropdownText}
                            />
                          </MenuItem>
                        ))
                        :
                        null}
                    </Select>
                  </FormControl>


                  <FormControl sx={{ m: 1, width: isMobile ? '90%' : 120 }} size='small'>
                    <InputLabel id="freight-filter-label">Freight</InputLabel>
                    <Select
                      disabled={plantName?.length === 0}
                      input={<OutlinedInput label="Freight" />}
                      value={selectedFreightFilters}
                      onChange={handleDropdownFilter}
                      labelId="freight-filter-label"
                      id="freight-filter"
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                      style={{
                        fontFamily: 'Poppins',
                      }}
                    >
                      <MenuItem key="ALL" value="">
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={selectedFreightFilters === ''}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          primary="ALL"
                          className={classes.dropdownText}
                        />
                      </MenuItem>
                      {freightBasisMasterData ?
                        freightBasisMasterData.map(d => (
                          <MenuItem key={d.name} value={d.name}>
                            <Checkbox
                              style={{ borderColor: 'gray', color: '#1976d2' }}
                              checked={selectedFreightFilters.indexOf(d.name) > -1}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                },
                              }}
                              className={classes.dropdownText}
                              primary={d.name}
                            />
                          </MenuItem>
                        ))
                        : null
                      }
                      {/* <MenuItem key="FOR" value="FOR">
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={selectedFreightFilters.indexOf("FOR") > -1}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          primary="FOR"
                          className={classes.dropdownText}
                        />
                      </MenuItem>
                      <MenuItem key="TO-PAY" value="TO-PAY">
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={selectedFreightFilters.indexOf("TO-PAY") > -1}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          primary="TO-PAY"
                          className={classes.dropdownText}
                        />
                      </MenuItem> */}

                    </Select>
                  </FormControl>
                  <span style={{ width: isMobile ? '90%' : 180, marginTop: isMobile && 10 }}>
                    <TextField
                      className={classes.serachFieldBar}
                      disabled={tableData?.data?.length === 0}
                      type="text"
                      label="Vehicle, Invoice, Eway"
                      autoComplete="off"
                      value={searchField}
                      variant="outlined"
                      style={{ width: '100%' }}
                      size="small"
                      InputLabelProps={{
                        style: { fontSize: '12px' }
                      }}
                      onChange={handleSearch}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{ padding: 0, display: searchField === '' || searchField == null ? 'none' : 'block' }}
                              onClick={() => {
                                setSearchField('')
                                setTableFilterData({
                                  data: selectedFreightFilters !== ''
                                    ? tableFilterData?.data
                                    : tableData?.data
                                })
                              }
                              }
                            >
                              <CloseOutlinedIcon />
                            </IconButton>
                            <IconButton>
                              <SearchOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          paddingRight: 0
                        }
                      }}
                    />
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'end',
                    // width: '20%'
                  }}
                >
                  {plantName.length > 0 ? (
                    <>
                      <Button
                        variant="contained"
                        style={{ width: "120px", marginRight: "20px", marginLeft: isMobile && 10 }}
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          const data = plantsData.filter((p) =>
                            plantName.includes(p.plant_short_name)
                          );
                          const code = data.map((d) => d.plant_code);
                          setSearchField('');
                          handleOnClick(code, selectedItemCategories, selectedFreightFilters);
                        }}
                      >
                        {" "}
                        Fetch Data
                      </Button>
                    </>
                  ) : (
                    ""
                  )}

                  <Tooltip title="Download CSV" placement="top">
                    <div>
                      {tableData?.data?.length === 0 ? (
                        ""
                      ) : (
                        <CSVLink
                          data={tableData.data}
                          headers={headers}
                          filename={`DispatchTracking ${moment().format(
                            "DD-MM-YYYY , h-mm"
                          )}.csv`}
                        >
                          <CloudDownloadOutlinedIcon
                            style={{ color: "#4b87c5" }}
                          // disabled={tableData.length === 0}
                          />
                        </CSVLink>
                      )}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Paper>
            <div style={{ marginTop: "20px", width: '100%' }}>
              {loading ?
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    // height: "40vh",
                    alignItems: "center",
                  }}
                >
                  <CircularProgressLoader />
                </div>
                :
                tableData.data && (tableData.data).length > 0 ? (

                  <Table
                    data={tableFilterData}
                    headers={headersNew}
                    value={'dispatchTracking'}
                    tableHeight={"calc(100vh - 255px)"}
                  />
                  // <PlantTable
                  //  tableData={tableFilterData.length > 0 ? tableFilterData : tableData}
                  //  headers={headers}
                  //  handleOnClick={handleOnClick}
                  //  plantName={plantsData
                  //    .filter((p) => plantName.includes(p.plant_name))
                  //    .map((a) => a.plant_code)}
                  //  props={props}
                  //  loading={loading}
                  // />
                ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </Layout>
      <div></div>
    </>
  );
}

export default withStyles(customersStyles)(DispatchTrackingWeb);
