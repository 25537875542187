import React, { Component } from "react";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import withUser from "../../../hoc/withUser";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import { Route } from "react-router-dom";
import SalesTarget from "../../../components/internal/salesTarget/web/SalesTarget"
import SalesTargetScheme from "../../../components/internal/salesTarget/web/SalesTargetScheme";
import SalesTargetManage from "../../../components/internal/salesTarget/web/SalesTargetManage";
import CreateScheme from "../../../components/internal/salesTarget/web/CreateScheme";
import { arraySortBykeyAllBrowser, checkApiError, fetchApiDataAndUpdateLocalStorage, getOptions, handleSnackbarMessageBox } from "../../../methods";
import SnackbarComponent from "../../../components/internal/common/SnackbarComponent";

class SalesTargetOperation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemCategoryList: [],
            dealerDistributorList: [],
            allStateList: [],
            openSnackbar: false,
            snackbarMessage: null,
            snackbarType: "",
        };
    }

    componentDidMount() {

    }

    handleOpenSnackbar = (message, type) => {
        this.setState({
            openSnackbar: true,
            snackbarMessage: message,
            snackbarType: type,
        });
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            openSnackbar: false,
            snackbarMessage: "",
            snackbarType: "",
        });
    };

    handleCallbackDataFunction = (receivedData, place = '', callBackLogicFunction = '') => {
        if (place === 'error') {
            callBackLogicFunction(receivedData, place)
            this.handleOpenSnackbar(
                handleSnackbarMessageBox(checkApiError(receivedData?.response)),
                "error"
            );
            setTimeout(() => {
                this.handleCloseSnackbar();
            }, 4000);
        }
        else {
            callBackLogicFunction(receivedData, place)
        }
    }

    getAllDealersDistributors = (displaySection) => {
        fetchApiDataAndUpdateLocalStorage(
            `${APIROUTES.CUSTOMERS_GET}?customer_type=${displaySection}`,
            `dealerDistributorList`,
            this.handleCallbackDataFunction,
            this.handleAllDealerDistributorsLogic
        )
    };


    handleAllDealerDistributorsLogic = (responseData, place) => {
        if (place !== 'error') {
            let responseDataFilter = responseData && responseData?.filter(d => (
                d?.credentials?.is_approved === true &&
                d?.credentials?.is_active === true))
            let responseTabledata = [];
            responseDataFilter?.map(d => {
                responseTabledata.push({
                    id: d.id,
                    name:
                        d.gst_information !== null
                            ? d.gst_information.trade_name
                                ? d.gst_information.trade_name
                                : d.gst_information.legal_name_of_business
                                    ? d.gst_information.legal_name_of_business
                                    : (d.first_name ? d.first_name : "") + (d.last_name ? d.last_name : "")

                            : d.pan_card_information !== null
                                ? d.pan_card_information.company_name
                                    ? d.pan_card_information.company_name
                                    : (d.first_name ? d.first_name : "") + " " + (d.last_name ? d.last_name : "")
                                : (d.first_name ? d.first_name : "") + " " + (d.last_name ? d.last_name : ""),
                    state: 'Chhattisgarh',
                    last_year_sale: '1729',
                    this_year_sale: '1729',
                    status: 'Pending'
                })
            })
            this.setState({
                dealerDistributorList: responseTabledata,
            });
        }
        else {
            console.log("here error come")
            this.setState({
                dealerDistributorList: [],
            });
        }
    }

    getAllStatesList = (countryId) => {
        fetchApiDataAndUpdateLocalStorage(
            `${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}`,
            `allStateList`,
            this.handleCallbackDataFunction,
            this.handleAllStatesListLogic,
            true
        )
    };

    handleAllStatesListLogic = (responseData, place = '') => {
        if (place !== 'error') {
            let responseDataSort = arraySortBykeyAllBrowser(responseData, 'name');
            this.setState({ allStateList: responseDataSort })
        }
    }

    fetchItemCategory = () => {
        fetchApiDataAndUpdateLocalStorage(
            `${APIROUTES.GET_ITEM_CATEGORY}?active=${true}`,
            `itemCategoryList`,
            this.handleCallbackDataFunction,
            this.handleItemCategoryLogic,
            true
        )
    };

    handleItemCategoryLogic = (responseData, place = '') => {
        if (place !== 'error') {
            let responseDataSort = arraySortBykeyAllBrowser(responseData, 'category_name');
            this.setState({ itemCategoryList: responseDataSort, });
        }
    }

    handleCreateScheme = (data, displaySection) => {
        console.log(data, displaySection)
        // axios
        //     .post(`${APIROUTES.GET_ITEM_CATEGORY}`,
        //         data,
        //         getOptions(this.props.user))
        //     .then((response) => {
        //         this.handleOpenSnackbar(`${displaySection} Scheme created successfully`);
        //         setTimeout(() => {
        //             this.handleCloseSnackbar();
        //         }, 5000);
        //     })
        //     .catch((error) => {
        //         this.handleOpenSnackbar(
        //             this.handleSnackbarMessageBox(checkApiError(error.response.data)),
        //             "error"
        //         );
        //         setTimeout(() => {
        //             this.handleCloseSnackbar();
        //         }, 4000);
        //     });
    }

    render() {
        const {
            itemCategoryList,
            dealerDistributorList,
            allStateList,
            openSnackbar,
            snackbarMessage,
            snackbarType
        } = this.state;

        return (
            <>
                <Route
                    exact
                    path={[
                        `${INTERNALROUTES.SALESTARGET}`,
                    ]}
                    render={(props) => (
                        <SalesTarget
                            {...props}
                            {...this.props}
                            {...this.state}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        `${INTERNALROUTES.SALESTARGET_DISTRIBUTOR_SCHEME}`,
                        `${INTERNALROUTES.SALESTARGET_DEALER_SCHEME}`,
                        `${INTERNALROUTES.SALESTARGET_SALESPERSON_TARGET}`,
                    ]}
                    render={(props) => (
                        <SalesTargetScheme
                            {...props}
                            {...this.props}
                            {...this.state}
                            displaySection={
                                props.location.pathname.split("/")[
                                props.location.pathname.split("/").length - 2
                                ]
                            }
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        `${INTERNALROUTES.SALESTARGET_DISTRIBUTOR_MANAGE}`,
                        `${INTERNALROUTES.SALESTARGET_DEALER_MANAGE}`,
                        `${INTERNALROUTES.SALESTARGET_SALESPERSON_MANAGE}`,
                    ]}
                    render={(props) => (
                        <SalesTargetManage
                            {...props}
                            {...this.props}
                            {...this.state}
                            displaySection={
                                props.location.pathname.split("/")[
                                props.location.pathname.split("/").length - 2
                                ]
                            }
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        `${INTERNALROUTES.SALESTARGET_DISTRIBUTOR_SCHEME_CREATE}`,
                        `${INTERNALROUTES.SALESTARGET_DEALER_SCHEME_CREATE}`,
                        `${INTERNALROUTES.SALESTARGET_SALESPERSON_TARGET_CREATE}`,
                    ]}
                    render={(props) => (
                        <CreateScheme
                            {...props}
                            {...this.props}
                            {...this.state}
                            displaySection={
                                props.location.pathname.split("/")[
                                props.location.pathname.split("/").length - 3
                                ]
                            }
                            fetchItemCategory={this.fetchItemCategory}
                            handleCreateScheme={this.handleCreateScheme}
                            getAllDealersDistributors={this.getAllDealersDistributors}
                            getAllStatesList={this.getAllStatesList}
                            itemCategoryList={itemCategoryList}
                            dealerDistributorList={dealerDistributorList}
                            allStateList={allStateList}
                        />
                    )}
                />
                <SnackbarComponent
                    open={openSnackbar}
                    handleClose={this.handleCloseSnackbar}
                    snackbarMessage={snackbarMessage}
                    type={snackbarType}
                />
            </>
        );

    }
}

export default withUser(SalesTargetOperation);
