import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Switch,
} from "@material-ui/core";
import {
  grey,
  primaryColor,
  successButtonBackground,
  drawerTextColor,
  secondaryBlueText,
  whiteSmoke,
  orange
} from "../../../../constants/internal/colors";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import COLORS from "../../../../constants/internal/colors";
import { checkAlphaNumericLength, checkApiError, hasUpdateAccess, hasWriteAccess, pxToEm, UpperCaseText } from "../../../../methods";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import SnackbarComponent from "../../../common/SnackbarComponent";
import ToolTipComponent from "../../common/ToolTipComponent";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";


const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: "0.5px",
    },
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  topMargin: { marginTop: 30, width: "70%" },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  addNewButton: {
    marginTop: 10,
    // width: "14%",
    height: "43px",
    border: `1px dashed ${COLORS.secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: COLORS.grey,
    fontWeight: "bold",
    marginBottom: 15,
    padding: "5px 22px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  textField: {
    width: "70%",
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  titleLabelStylePanImage: {
    fontSize: pxToEm(15),
    color: secondaryBlueText,
    fontWeight: "bold",
    // marginBottom: 15,
    cursor: "pointer",
    textDecoration: "underline",
  },
  tableContainer: {
    marginTop: 10,
    width: "100%",
    overflow: "auto",
  },
  table: {
    border: "0px",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  helperText: {
    position: 'absolute',
    // top: '2.2rem',
    top: pxToEm(45)
  }
});

const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.defaultChecked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function ManagePlantItemMaster(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [itemCategoryNameList, setItemCategoryNameList] = React.useState([])
  const [itemEditShow, setItemEditShow] = React.useState({
    show: true,
    index: -1
  });
  const [openItemNature, setOpenItemNature] = React.useState(false);
  const [itemNatureNameList, setItemNatureNameList] = React.useState([])
  const [itemNatureEditShow, setItemNatureEditShow] = React.useState({
    show: true,
    index: -1
  });
  const [plantItemDataDisplay, setPlantItemMasterData] = React.useState(
    props.selectedPlantData || {
      id: 1,
      plant_code: "",
      godown_code: "",
      plant_logo: "",
      logo_banner: "",
      plant_name: "",
      plant_short_name: "",
      plants_address_sr_no: "",
      plant_upi_id: "",
      plant_account_no: "",
      plant_account_ifsc: "",
      plant_state: "",
      plant_pincode: "",
      plant_city: "",
      is_active: "",
      plant_address: "",
      plant_gst_number: "",
      plant_pan_number: "",
      plant_mobile: "",
      plant_email: "",
      branch_name: "",
      bank_name: "",
      division: "",
      range: "",
      commissionerate: "",
      cin: "",
    }
  );
  const [plantItemCategoryError, setPlantItemCategoryError] = React.useState({
    item_category: false,
    plant_item_category_code: false,
    voucher_code: false,
  });

  const [plantItemNatureError, setPlantItemNatureError] = React.useState({
    item_nature: false,
    godown_code: false,
  });
  const [plantItemCategories, setPlantItemCategories] = React.useState(
    props?.selectedPlantData?.item_categories || []
  );
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [plantItemCategoryData, setItemCategoryData] = React.useState([
    {
      plant_item_category_code: "",
      item_category: "",
      voucher_code: "",
      is_active: true
    },
  ]);
  const [plantItemNatures, setPlantItemNatures] = React.useState(
    props?.itemNatureData || []
  );
  const [plantItemNatureData, setItemNatureData] = React.useState([
    {
      item_nature: "",
      plant: "",
      godown_code: "",
      is_active: true
    },
  ]);
  const allItemCategories = props?.itemCategoriesList || [];
  const [filteredAllItemCategories, setFilteredAllItemCategories] =
    React.useState(
      allItemCategories && allItemCategories.length > 0
        ? plantItemCategories && plantItemCategories.length > 0
          ? allItemCategories.filter(
            (x) =>
              !plantItemCategories.filter((y) => {
                return y.id === x.id;
              }).length
          )
          : allItemCategories
        : []
    );
  const allItemNatures = props?.allItemNatureList || [];
  //console.log("allItemNatures", allItemNatures)
  // console.log("plantItemNatures", plantItemNatures)
  const [filteredAllItemNatures, setFilteredAllItemNatures] =
    React.useState(
      allItemNatures && allItemNatures.length > 0
        ? plantItemNatures && plantItemNatures.length > 0
          ? allItemNatures.filter(
            (x) =>
              !plantItemNatures.filter((y) => {
                return y.item_nature === x.item_nature;
              }).length
          )
          : allItemNatures
        : []
    );
  const [plantMasterOperation, setPlantItemMasterOperation] = React.useState(
    props?.plantMasterOperation || "add"
  );
  React.useEffect(() => {
    setPlantItemCategories(props.selectedPlantData.item_categories);
    setPlantItemNatures(props.itemNatureData);
    handleFilteredAllItemCategories();
    handleFilteredAllItemNature();
    setPlantItemMasterData(props.selectedPlantData);
    setPlantItemMasterOperation(props.plantMasterOperation);
  }, [
    props.selectedPlantData,
    props?.selectedPlantData?.item_categories,
    props.plantMasterOperation,
    props.itemNatureData
  ]);


  React.useEffect(() => {
    if (plantItemCategories) {
      plantItemCategories.map((data, itemCategoryIndex) => (
        setItemCategoryNameList(prevState => [(data.item_category_name), ...prevState])
      ))
    }
  }, [plantItemCategories])

  React.useEffect(() => {
    // if (plantItemNatures) {
    //   plantItemNatures?.map((data, itemCategoryIndex) => (
    //     setItemNatureNameList(prevState => [(data.item_nature), ...prevState])
    //   ))
    // }
    handleFilteredAllItemNature();

    if (Array.isArray(plantItemNatures)) {
      // Extract item_nature properties from plantItemNatures and accumulate them in a new array
      const newItemNatureNames = plantItemNatures.map(data => data.item_nature);

      // Update itemNatureNameList state with the accumulated array
      setItemNatureNameList(newItemNatureNames);
    }
  }, [plantItemNatures])

  // if (!props.allStateListDataFetchLoader) {
  //   props.allStateList.map(data => {
  //     if (data['id'] === plantItemDataDisplay.plant_state) {
  //       // console.log(data['id'] + ":" + data['name'])
  //       setPlantItemMasterData(prevValue => {
  //         return {
  //           ...prevValue, plant_state: data['name']
  //         }
  //       })
  //     }
  //   })
  // }
  const handleCancelButtonClick = () => {
    props.history.push(`${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}`);
  };
  const handleEditButtonClick = () => {
    props.history.push(
      `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}/edit/${props?.selectedPlantId}`
    );
  };
  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };
  const handleFilteredAllItemCategories = () => {
    let filteredAllItemCategories =
      allItemCategories && allItemCategories.length > 0
        ? plantItemCategories && plantItemCategories.length > 0
          ? allItemCategories.filter(
            (x) =>
              !plantItemCategories.filter((y) => {
                return y.id === x.id;
              }).length
          )
          : allItemCategories
        : [];
    setFilteredAllItemCategories(filteredAllItemCategories);
  };

  const handleFilteredAllItemNature = () => {
    let filteredAllItemNatures =
      allItemNatures && allItemNatures.length > 0
        ? plantItemNatures && plantItemNatures.length > 0
          ? allItemNatures.filter(
            (x) =>
              !plantItemNatures.filter((y) => {
                return y.item_nature === x.item_nature;
              }).length
          )
          : allItemNatures
        : [];
    console.log(filteredAllItemNatures)
    setFilteredAllItemNatures(filteredAllItemNatures);
  };

  const handlePlantItemCategoryValueChange = (id) => (e) => {
    setItemCategoryData({
      ...plantItemCategoryData,
      item_category: id,
    });
  };
  const handlePlantItemNatureValueChange = (id) => (e) => {
    setItemNatureData({
      ...plantItemNatureData,
      item_nature: id,
    });
  };
  const handleFieldValidationCheck = (plant_item_category_code, voucher_code) => {
    if (plant_item_category_code) {
      !checkAlphaNumericLength(plant_item_category_code) ?
        setPlantItemCategoryError(prevValue => { return { ...prevValue, plant_item_category_code: true } }) :
        setPlantItemCategoryError(prevValue => { return { ...prevValue, plant_item_category_code: false } })
    }
    if (voucher_code) {
      !checkAlphaNumericLength(voucher_code) ?
        setPlantItemCategoryError(prevValue => { return { ...prevValue, voucher_code: true } }) :
        setPlantItemCategoryError(prevValue => { return { ...prevValue, voucher_code: false } })
    }
  }

  const handleFieldValidationCheckForItemNature = (godown_code) => {
    if (godown_code) {
      !checkAlphaNumericLength(godown_code) ?
        setPlantItemNatureError(prevValue => { return { ...prevValue, godown_code: true } }) :
        setPlantItemNatureError(prevValue => { return { ...prevValue, godown_code: false } })
    }
  }

  const handlePlantItemCategoryValueOnChange = (e) => {
    const { name, value } = e.target
    if (name === 'is_active') {
      setItemCategoryData({
        ...plantItemCategoryData,
        is_active: e.target.checked,
      });
    }
    else {
      setItemCategoryData({
        ...plantItemCategoryData,
        [name]: ['plant_item_category_code', 'voucher_code'].includes(name)
          ? UpperCaseText(value)
          : value,
      });
      if (!value) {
        setPlantItemCategoryError(prevValue => {
          return {
            ...prevValue, [name]: true
          }
        })
      } else {
        if (name === 'plant_item_category_code') {
          !checkAlphaNumericLength(value) ?
            setPlantItemCategoryError(prevValue => { return { ...prevValue, [name]: true } }) :
            setPlantItemCategoryError(prevValue => { return { ...prevValue, [name]: false } })
        }
        else if (name === 'voucher_code') {
          !checkAlphaNumericLength(value) ?
            setPlantItemCategoryError(prevValue => { return { ...prevValue, [name]: true } }) :
            setPlantItemCategoryError(prevValue => { return { ...prevValue, [name]: false } })
        }
      }
    }
  };

  const handlePlantItemNatureValueOnChange = (e) => {
    const { name, value } = e.target
    if (name === 'is_active') {
      setItemNatureData({
        ...plantItemNatureData,
        is_active: e.target.checked,
      });
    }
    else {
      setItemNatureData({
        ...plantItemNatureData,
        [name]: ['godown_code'].includes(name)
          ? UpperCaseText(value)
          : value,
      });
      if (!value) {
        setPlantItemNatureError(prevValue => {
          return {
            ...prevValue, [name]: true
          }
        })
      }
      else if (name === 'godown_code') {
        !checkAlphaNumericLength(value) ?
          setPlantItemNatureError(prevValue => { return { ...prevValue, [name]: true } }) :
          setPlantItemNatureError(prevValue => { return { ...prevValue, [name]: false } })
      }
    }
  };
  const handleItemSaveUpdate = (name, itemId) => {
    let plantItemMasterData = new FormData();
    plantItemMasterData.append(
      `item_category`,
      plantItemCategoryData.item_category
    );
    plantItemMasterData.append(
      `plant_item_category_code`,
      plantItemCategoryData.plant_item_category_code
    );
    plantItemMasterData.append(
      `voucher_code`,
      plantItemCategoryData.voucher_code
    );
    plantItemMasterData.append(
      "is_active",
      Boolean(plantItemCategoryData.is_active)
    );

    setItemCategoryData({
      plant_item_category_code: "",
      item_category: "",
      voucher_code: "",
      is_active: ""
    });
    setPlantItemCategoryError({
      plant_item_category_code: false,
      item_category: false,
      voucher_code: false,
    })
    let updatedData = [{
      plant_item_category_code: plantItemCategoryData.plant_item_category_code,
      item_category: plantItemCategoryData.item_category,
      voucher_code: plantItemCategoryData.voucher_code,
      is_active: plantItemCategoryData.is_active,
    }]
    let addedData = {
      plant: plantItemDataDisplay?.id,
      item_category: updatedData,
    };

    handleItemCancel()

    if (name === "edit") {
      handleItemCancel()
      props.handleUpdateItemsInPlant(plantItemMasterData, itemId)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Plant Item Category Updated Successfully`,
              "success"
            );
            setItemEditShow({ show: true, index: -1 });
            setTimeout(() => handleCloseSnackbar(), 3000);
            // console.log(props.selectedPlantDataFetchLoader)
          } else {
            handleOpenSnackbar(
              `Error occured while updating plant item master please try again`,
              "error"
            );
          }
          setItemEditShow({ show: true, index: -1 });
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            // checkApiError(error.response.data),
            "error"
          );
          setItemEditShow({ show: true, index: -1 });
        });
    }

    else if (name == "add") {
      handleItemCancel()
      props
        .handleAddNewItemsInPlant(addedData)
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Plant Item Category Added Successfully`,
              "success"
            );
          } else {
            handleOpenSnackbar(
              `Error occured while creating plant item master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            // checkApiError(error.response.data),
            "error"
          );
        });
    }
    else {
      console.log("Invalid operation");
    }
  }

  const handleItemNatureSaveUpdate = (name, itemNatureId) => {
    let plantItemNatureMasterData = new FormData();
    plantItemNatureMasterData.append(
      `plant`,
      plantItemDataDisplay?.id
    );
    plantItemNatureMasterData.append(
      `item_nature`,
      plantItemNatureData.item_nature
      //"TE"
    );
    plantItemNatureMasterData.append(
      `godown_code`,
      plantItemNatureData.godown_code
    );
    plantItemNatureMasterData.append(
      "is_active",
      Boolean(plantItemNatureData.is_active)
    );

    setItemNatureData({
      item_nature: "",
      godown_code: "",
      is_active: "",
      plant: ""
    });
    setPlantItemNatureError({
      item_nature: false,
      godown_code: false,
    })
    let updatedData = {
      plant: plantItemDataDisplay?.id,
      item_nature: plantItemNatureData.item_nature,
      //plantItemNatureData.item_nature,
      //item_nature: "TE",
      godown_code: plantItemNatureData.godown_code,
      is_active: plantItemNatureData.is_active,
    }
    let addedData = {
      //plant: plantItemDataDisplay?.id,
      ...updatedData,
    };

    handleItemNatureCancel()

    if (name === "edit") {
      handleItemNatureCancel()
      props.handleUpdateItemsNatureInPlant(plantItemNatureMasterData, itemNatureId)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Plant Item nature Updated Successfully`,
              "success"
            );
            setItemNatureEditShow({ show: true, index: -1 });
            setTimeout(() => handleCloseSnackbar(), 3000);
            // console.log(props.selectedPlantDataFetchLoader)
          } else {
            handleOpenSnackbar(
              `Error occured while updating plant item  please try again`,
              "error"
            );
          }
          setItemNatureEditShow({ show: true, index: -1 });
        })
        .catch((error) => {
          handleOpenSnackbar(
            //handleSnackbarMessageBox(
            checkApiError(error.response.data),
            // ),
            // checkApiError(error.response.data),
            "error"
          );
          setItemNatureEditShow({ show: true, index: -1 });
        });
    }

    else if (name == "add") {
      handleItemNatureCancel()
      props
        .handleLinkItemNature(addedData)
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Plant Item Nature Added Successfully`,
              "success"
            );
          } else {
            handleOpenSnackbar(
              `Error occured while creating plant item master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            //handleSnackbarMessageBox(
            checkApiError(error.response.data),
            //),
            // checkApiError(error.response.data),
            "error"
          );
        });
    } else if (name == "delete") {
      props
        .handleRemoveItemsNatureFromPlant(itemNatureId)
        .then((response) => {
          if (response.status === 204) {
            handleOpenSnackbar(
              `Plant item nature deleted Successfully`,
              "success"
            );
            console.log(plantItemNatures)
          } else {
            handleOpenSnackbar(
              `Error occured while deleting plant item nature please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            // checkApiError(error.response.data),
            "error"
          );
        });
    }
    else {
      console.log("Invalid operation");
    }
  }

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleItemCancel = () => {
    setItemCategoryData({
      plant_item_category_code: "",
      item_category: "",
      voucher_code: "",
      is_active: true
    })
    setPlantItemCategoryError({
      plant_item_category_code: false,
      item_category: false,
      voucher_code: false,
    })
    setOpen(false)
  }

  const handleItemNatureCancel = () => {
    setItemNatureData({
      item_nature: "",
      godown_code: "",
      is_active: true
    })
    setPlantItemNatureError({
      item_nature: false,
      godown_code: false,
    })
    setOpenItemNature(false)
  }

  const handleEditShow = (indexNumber, data) => {
    setItemEditShow({
      show: false,
      index: indexNumber
    })
    let rowItemData = filteredAllItemCategories.find(d => d.category_name === data.item_category_name)
    // console.log(rowItemData)
    // filteredAllItemCategories.map(filterData => {
    // if (filterData.category_name === data.item_category_name) {
    if (rowItemData !== undefined) {
      setItemCategoryData({
        plant_item_category_code: data.plant_item_category_code,
        // item_category: filterData.id,
        item_category: rowItemData.id,
        voucher_code: data.voucher_code,
        is_active: data.is_active
      })
    }
    else if (rowItemData === undefined) {
      setItemCategoryData({
        plant_item_category_code: data.plant_item_category_code,
        item_category: data.item_category_id,
        voucher_code: data.voucher_code,
        is_active: data.is_active
      })
    }
    // }
    // })
  }
  const handleItemNatureEditShow = (indexNumber, data) => {
    setItemNatureEditShow({
      show: false,
      index: indexNumber
    })
    let rowItemData = filteredAllItemNatures.find(d => d.item_nature === data.item_nature)
    // if (rowItemData !== undefined) {
    //   setPlantItemNatures({
    //     //plant_item_category_code: data.plant_item_category_code,
    //     // item_category: filterData.id,
    //     item_nature: rowItemData.item_nature,
    //     godown_code: data.godown_code,
    //     is_active: data.is_active
    //   })
    // }
    // else
    if (rowItemData === undefined) {
      setItemNatureData({
        //plant_item_category_code: data.plant_item_category_code,
        item_nature: data.item_nature,
        godown_code: data.godown_code,
        is_active: data.is_active
      })
    }
    // }
    // })
  }
  return (
    <>
      <div className={classes.topMargin}>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT NAME</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_name}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT SHORT NAME</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay?.plant_short_name}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT CODE</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_code}
          </Typography>
        </div>
        {/* <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>GODOWN CODE</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.godown_code}
          </Typography>
        </div> */}
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>
            PLANT GSTIN NUMBER
          </Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_gst_number}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>
            PLANT PAN NUMBER
          </Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_pan_number}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT CIN</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.cin}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT RANGE</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.range}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT DIVISION</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.division}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT COMMISSIONERATE</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.commissionerate}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>
            PLANT MOBILE NUMBER
          </Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_mobile}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT EMAIL</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_email}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT UPI ID</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_upi_id}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT BANK NAME</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.bank_name}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT BRANCH NAME</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.branch_name}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT ACCOUNT NUMBER</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_account_no}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT ACCOUNT IFSC</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_account_ifsc}
          </Typography>
        </div>

        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT ADDRESS SERIAL NUMBER</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plants_address_sr_no}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT ADDRESS</Typography>
          <Typography className={classes.titleLabel} style={{ maxWidth: '50%', textAlign: 'end' }}>
            {plantItemDataDisplay.plant_address}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT PINCODE</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.plant_pincode}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT CITY</Typography>
          <Typography className={classes.titleLabel} style={{ textAlign: "right" }}>
            {plantItemDataDisplay.plant_city}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT STATE</Typography>
          <Typography className={classes.titleLabel}>
            {/* {plantItemDataDisplay.plant_state} */}
            {(props.allStateList.find(d => d.id == plantItemDataDisplay.plant_state))?.name}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT ACTIVE</Typography>
          <Typography className={classes.titleLabel}>
            {plantItemDataDisplay.is_active ?
              (
                <CheckCircle
                  style={{ color: successButtonBackground }}
                />
              ) : (
                <Cancel style={{ color: orange }} />
              )}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PLANT LOGO</Typography>
          <Typography
            onClick={() =>
              window.open(plantItemDataDisplay.plant_logo, "_blank")
            }
            className={classes.titleLabelStylePanImage}
          >
            {plantItemDataDisplay.plant_logo}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>LOGO BANNER</Typography>
          <Typography
            onClick={() =>
              window.open(plantItemDataDisplay.logo_banner, "_blank")
            }
            className={classes.titleLabelStylePanImage}
          >
            {plantItemDataDisplay.logo_banner}
          </Typography>
        </div>
        <div style={{ marginTop: "20px", marginBottom: "40px" }}>
          <ToolTipComponent
            title="You have no edit permission on this widget"
            condition={!hasUpdateAccess(props.user, 'plant_item_master')}
          >
            <Button
              size="medium"
              className={classes.primaryBackground}
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              disabled={!hasUpdateAccess(props.user, 'plant_item_master')}
              onClick={handleEditButtonClick}
            >
              EDIT
            </Button>
          </ToolTipComponent>
          <Button
            size="medium"
            className={classes.margin}
            onClick={handleCancelButtonClick}
          >
            CANCEL
          </Button>
        </div>
        <div style={{
          marginTop: "10px"
        }}>
          <Typography className={classes.titleLabel}>
            ITEM CATEGORIES
          </Typography>
        </div>
        <div style={{
          marginBottom: "20px"
        }}>
          <TableContainer
            className={classes.tableContainer}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '25%'
                    }}
                  >
                    Item Category
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '20%'
                    }}
                  >
                    Div Code
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '25%'
                    }}
                  >
                    Voucher Code
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '10%'
                    }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '20%'
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plantItemCategories && plantItemCategories.length > 0 ? (
                  plantItemCategories.map((itemCategory, index) => (
                    index === itemEditShow?.index ?

                      <TableRow style={{ display: open || !itemEditShow.show ? "table-row" : "none" }}>
                        <>
                          <TableCell
                            index={index}
                            style={{ width: '22%' }}
                          >
                            <FormControl
                              variant="outlined"
                              size="small"
                              className={classes.addMoreButton}
                              style={{ minWidth: 200 }}
                            >
                              <InputLabel id="itemCategorySelect">
                                Item Category
                              </InputLabel>
                              {console.log(itemCategory.item_category_name == "GK TMT" && index)}
                              <Select
                                name="item_category"
                                labelId="itemCategorySelect"
                                displayEmpty
                                defaultValue={itemCategory.item_category_id}
                                value={plantItemCategoryData.item_category}
                                label="Item Category"
                                MenuProps={MenuProps}
                              >
                                {allItemCategories &&
                                  (allItemCategories.find(d => d.id === itemCategory.item_category_id)) === undefined ?
                                  <MenuItem
                                    value={itemCategory.item_category_id}
                                    style={{ display: 'none', color: "black" }}
                                    key={itemCategory.item_category_id}
                                  >
                                    {itemCategory.item_category_name}
                                  </MenuItem>
                                  : null
                                }
                                <MenuItem value="select" disabled={true}>
                                  <em>Select Category</em>
                                </MenuItem>
                                {filteredAllItemCategories &&
                                  filteredAllItemCategories.length > 0
                                  ? filteredAllItemCategories.map(
                                    (filteritemCategory, selectIndex) => (
                                      <MenuItem
                                        key={selectIndex}
                                        onClick={handlePlantItemCategoryValueChange(filteritemCategory.id)}
                                        value={filteritemCategory.id}
                                        style={{
                                          display: itemCategoryNameList.includes(filteritemCategory.category_name) ? "none" : "flex"
                                        }}
                                      >
                                        {filteritemCategory.category_name}
                                      </MenuItem>
                                    )
                                  )
                                  : null}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell
                            index={index}
                            style={{ width: '18%' }}
                          >
                            <TextField
                              label="Div Code*"
                              name="plant_item_category_code"
                              type="text"
                              onChange={handlePlantItemCategoryValueOnChange}
                              placeholder="Enter div code"
                              className={classes.addMoreButton}
                              margin="dense"
                              variant="outlined"
                              inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                              FormHelperTextProps={{ className: classes.helperText }}
                              value={plantItemCategoryData.plant_item_category_code}
                              error={plantItemCategoryError.plant_item_category_code}
                              helperText={plantItemCategoryError.plant_item_category_code ? "Invalid Code" : ""}
                            />
                          </TableCell>
                          <TableCell
                            index={index}
                            style={{ width: '24%' }}
                          >
                            <TextField
                              label="Enter voucher code*"
                              name="voucher_code"
                              type="text"
                              onChange={handlePlantItemCategoryValueOnChange}
                              placeholder="Enter voucher code"
                              className={classes.addMoreButton}
                              margin="dense"
                              variant="outlined"
                              inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                              FormHelperTextProps={{ className: classes.helperText }}
                              value={plantItemCategoryData.voucher_code}
                              error={plantItemCategoryError.voucher_code}
                              helperText={plantItemCategoryError.voucher_code ? "Invalid Code" : ""}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            index={index}
                            style={{ width: '8%' }}
                          >
                            <IOSSwitch
                              checked={Boolean(plantItemCategoryData.is_active)}
                              onChange={handlePlantItemCategoryValueOnChange}
                              name="is_active"
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            index={index}
                            style={{
                              display: !itemEditShow.show ? "table-cell" : "none",
                              width: '28%',
                              minWidth: 150
                            }}
                          >
                            <IconButton
                              style={{
                                display: itemEditShow.show ? "inline" : "none",
                                // marginLeft: 20
                              }}
                              onClick={() => {
                                handleFieldValidationCheck(
                                  plantItemCategoryData.plant_item_category_code,
                                  plantItemCategoryData.voucher_code
                                )
                                setItemEditShow({ show: false, index: index });
                              }
                              }
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                            <div style={{ display: itemEditShow.show ? "none" : "inline", }}>
                              <IconButton
                                onClick={() => handleItemSaveUpdate("edit", itemCategory.id)}
                                disabled={
                                  plantItemCategoryError.plant_item_category_code ||
                                  plantItemCategoryError.voucher_code ||
                                  plantItemCategoryError.item_category
                                }
                              >
                                <CheckOutlinedIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setItemEditShow({ show: true, index: -1 });
                                  handleItemCancel();
                                }}>
                                <CloseOutlinedIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        </>
                      </TableRow>
                      :
                      !props.selectedPlantItemFetchLoader &&
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            color: grey,
                            fontSize: "0.8em",
                            width: '25%'
                          }}
                          index={index}
                        >
                          {itemCategory.item_category_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: grey,
                            fontSize: "0.8em",
                            width: '20%'
                          }}
                          index={index}
                        >
                          {itemCategory.plant_item_category_code}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: grey,
                            fontSize: "0.8em",
                            width: '25%'
                            // paddingLeft: 80
                          }}
                          index={index}
                        >
                          {itemCategory.voucher_code}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: grey,
                            fontSize: "0.8em",
                            width: '15%'
                            // paddingLeft: 80
                          }}
                          index={index}
                        >
                          {itemCategory.is_active ?
                            (
                              <CheckCircle
                                style={{ color: successButtonBackground }}
                              />
                            ) : (
                              <Cancel style={{ color: orange }} />
                            )}
                        </TableCell>
                        <TableCell
                          align="center"
                          index={index}
                          style={{ width: '15%' }}
                        >
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(props.user, 'plant_item_master')}
                          >
                            <IconButton
                              style={{
                                display: index !== itemEditShow?.index ? "inline" : "none",
                                // marginLeft: 20
                              }}
                              disabled={!hasUpdateAccess(props.user, 'plant_item_master')}
                              onClick={(e) => handleEditShow(index, itemCategory)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </ToolTipComponent>
                          <div style={{ display: index !== itemEditShow?.index ? "none" : "inline", }}>
                            <IconButton onClick={() => handleItemSaveUpdate("edit", itemCategory.id)}>
                              <CheckOutlinedIcon />
                            </IconButton>
                            <IconButton onClick={() => { setItemEditShow({ show: true, index: -1 }); handleItemCancel(); }}>
                              <CloseOutlinedIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                  ))
                ) : null}
                {itemEditShow.show ?
                  <TableRow style={{ display: open || !itemEditShow.show ? "table-row" : "none" }}>
                    <>
                      <TableCell
                        index="1000"
                      >
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.addMoreButton}
                          style={{ minWidth: 200, width: '25%' }}
                        >
                          <InputLabel id="itemCategorySelect">
                            Item Category*
                          </InputLabel>
                          {/* {console.log("item_category :" + plantItemCategoryData.item_category)} */}
                          <Select
                            name="item_category"
                            labelId="itemCategorySelect"
                            value={plantItemCategoryData.item_category || ""}
                            label="Item Category"
                            MenuProps={MenuProps}
                            required
                          >
                            <MenuItem value="select" disabled={true}>
                              <em>Select Category</em>
                            </MenuItem>
                            {filteredAllItemCategories &&
                              filteredAllItemCategories.length > 0
                              ? filteredAllItemCategories.map(
                                (filteritemCategory, selectIndex) => (
                                  <MenuItem
                                    key={selectIndex}
                                    onClick={handlePlantItemCategoryValueChange(filteritemCategory.id)}
                                    value={filteritemCategory.id}
                                    style={{
                                      display: itemCategoryNameList.includes(filteritemCategory.category_name) ? "none" : "flex"
                                    }}
                                  >
                                    {filteritemCategory.category_name}
                                  </MenuItem>
                                )
                              )
                              : null}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        index="1000"
                        style={{ width: '17%' }}
                      >
                        <TextField
                          label="Div Code*"
                          name="plant_item_category_code"
                          type="text"
                          onChange={handlePlantItemCategoryValueOnChange}
                          placeholder="Enter div code"
                          className={classes.addMoreButton}
                          margin="dense"
                          variant="outlined"
                          inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                          FormHelperTextProps={{ className: classes.helperText }}
                          value={plantItemCategoryData.plant_item_category_code || ""}
                          error={plantItemCategoryError.plant_item_category_code}
                          helperText={plantItemCategoryError.plant_item_category_code ? "Invalid Code" : ""}
                        />
                      </TableCell>
                      {/* {console.log("category :" + plantItemCategoryData.plant_item_category_code)} */}
                      <TableCell
                        index="1000"
                        style={{ width: '28%' }}
                      >
                        <TextField
                          label="Enter voucher code*"
                          name="voucher_code"
                          type="text"
                          onChange={handlePlantItemCategoryValueOnChange}
                          placeholder="Enter voucher code"
                          className={classes.addMoreButton}
                          margin="dense"
                          variant="outlined"
                          inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                          FormHelperTextProps={{ className: classes.helperText }}
                          value={plantItemCategoryData.voucher_code || ""}
                          error={plantItemCategoryError.voucher_code}
                          helperText={plantItemCategoryError.voucher_code ? "Invalid Code" : ""}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        index="1000"
                        style={{ width: '10%' }}
                      >
                        <IOSSwitch
                          checked={Boolean(plantItemCategoryData.is_active)}
                          onChange={handlePlantItemCategoryValueOnChange}
                          name="is_active"
                        />
                      </TableCell>
                      {/* {console.log("voucher :" + plantItemCategoryData.voucher_code)} */}
                    </>

                  </TableRow>
                  :
                  null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ToolTipComponent
          title="You have no create permission on this widget"
          condition={!hasWriteAccess(props.user, 'plant_item_master')}
        >
          <Button
            style={{ display: open ? "none" : "inline-flex" }}
            size="medium"
            className={classes.addNewButton}
            variant="outlined"
            startIcon={<AddOutlinedIcon />}
            disabled={!itemEditShow.show || !hasWriteAccess(props.user, 'plant_item_master')}
            onClick={() => setOpen(true)}
          >
            ADD NEW
          </Button>
        </ToolTipComponent>
        <div style={{ marginBottom: open ? 70 : 0 }}>
          <Button
            style={{ display: !open ? "none" : "inline-flex" }}
            size="medium"
            className={classes.saveButton}
            variant="contained"
            startIcon={<CheckOutlinedIcon />}
            onClick={() => handleItemSaveUpdate("add", 0)}
            disabled={
              !plantItemCategoryData.voucher_code ||
              !plantItemCategoryData.plant_item_category_code ||
              !plantItemCategoryData.item_category ||
              plantItemCategoryData.voucher_code === "" ||
              plantItemCategoryData.plant_item_category_code === "" ||
              plantItemCategoryData.item_category == "" ||
              plantItemCategoryError.plant_item_category_code ||
              plantItemCategoryError.voucher_code ||
              plantItemCategoryError.item_category
            }
          >
            SAVE
          </Button>
          <Button
            style={{ display: !open ? "none" : "inline-flex" }}
            size="medium"
            className={classes.margin}
            onClick={handleItemCancel}
          >
            CANCEL
          </Button>
        </div>

        <div style={{
          marginTop: "10px"
        }}>
          <Typography className={classes.titleLabel}>
            ITEM NATURE (GODOWN)
          </Typography>
        </div>
        <div style={{
          marginBottom: "20px"
        }}>
          <TableContainer
            className={classes.tableContainer}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '25%'
                    }}
                  >
                    Item Nature
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '20%'
                    }}
                  >
                    Godown Code
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '10%'
                    }}
                  >
                    Active
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.9em",
                      fontWeight: "bold",
                      width: '20%'
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plantItemNatures && plantItemNatures.length > 0 ? (
                  plantItemNatures.map((itemNature, index) => (
                    index === itemNatureEditShow?.index ?

                      <TableRow style={{ display: openItemNature || !itemNatureEditShow.show ? "table-row" : "none" }}>
                        <>
                          <TableCell
                            index={index}
                            style={{ width: '22%' }}
                          >
                            <FormControl
                              variant="outlined"
                              size="small"
                              className={classes.addMoreButton}
                              style={{ minWidth: 200 }}
                            >
                              <InputLabel id="itemNatureSelect">
                                Item Nature
                              </InputLabel>
                              {console.log(itemNatureNameList)}
                              {console.log(itemNature.item_nature)}
                              {console.log(filteredAllItemNatures)}
                              <Select
                                name="item_nature"
                                labelId="itemNatureSelect"
                                value={plantItemNatureData.item_nature}
                                label="Item Nature"
                                MenuProps={MenuProps}
                              >
                                {allItemNatures &&
                                  (allItemNatures.find(d => d.item_nature === itemNature.item_nature)) === undefined ?
                                  <MenuItem
                                    value={itemNature.item_nature}
                                    style={{ display: 'none', color: "black" }}
                                    key={itemNature.item_nature}
                                  >
                                    {itemNature.item_nature}
                                  </MenuItem>
                                  : null
                                }
                                <MenuItem value="select" disabled={true}>
                                  <em>Select Item Nature</em>
                                </MenuItem>
                                {allItemNatures &&
                                  allItemNatures.length > 0
                                  ? allItemNatures.map(
                                    (filteritemNature, selectIndex) => (
                                      <MenuItem
                                        key={selectIndex}
                                        onClick={handlePlantItemNatureValueChange(filteritemNature.item_nature)}
                                        value={filteritemNature.item_nature}
                                        style={{
                                          display: itemNatureNameList.includes(filteritemNature.item_nature) ? "none" : "flex"
                                        }}
                                      >
                                        {filteritemNature.item_nature}
                                      </MenuItem>
                                    )
                                  )
                                  : null}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell
                            index={index}
                            style={{ width: '18%' }}
                          >
                            <TextField
                              label="Godown Code*"
                              name="godown_code"
                              type="text"
                              onChange={handlePlantItemNatureValueOnChange}
                              placeholder="Enter godown code"
                              className={classes.addMoreButton}
                              margin="dense"
                              variant="outlined"
                              //inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                              FormHelperTextProps={{ className: classes.helperText }}
                              value={plantItemNatureData.godown_code}
                              error={plantItemNatureError.godown_code}
                              helperText={plantItemNatureError.godown_code ? "Invalid Code" : ""}
                            />
                          </TableCell>

                          <TableCell
                            align="center"
                            index={index}
                            style={{ width: '8%' }}
                          >
                            <IOSSwitch
                              checked={Boolean(plantItemNatureData.is_active)}
                              onChange={handlePlantItemNatureValueOnChange}
                              name="is_active"
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            index={index}
                            style={{
                              display: !itemNatureEditShow.show ? "table-cell" : "none",
                              width: '28%',
                              minWidth: 150
                            }}
                          >
                            <IconButton
                              style={{
                                display: itemNatureEditShow.show ? "inline" : "none",
                                // marginLeft: 20
                              }}
                              onClick={() => {
                                handleFieldValidationCheckForItemNature(
                                  plantItemNatureData.godown_code,
                                  plantItemNatureData.item_nature
                                )
                                setItemNatureEditShow({ show: false, index: index });
                              }
                              }
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                            <div style={{ display: itemNatureEditShow.show ? "none" : "inline", }}>
                              <IconButton
                                onClick={() => handleItemNatureSaveUpdate("edit", itemNature.id)}
                                disabled={
                                  plantItemNatureError.godown_code ||
                                  plantItemNatureError.item_nature
                                }
                              >
                                <CheckOutlinedIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setItemNatureEditShow({ show: true, index: -1 });
                                  handleItemNatureCancel();
                                }}>
                                <CloseOutlinedIcon />
                              </IconButton>
                            </div>
                          </TableCell>

                        </>
                      </TableRow>
                      :
                      // !props.selectedPlantItemFetchLoader &&
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            color: grey,
                            fontSize: "0.8em",
                            width: '25%'
                          }}
                          index={index}
                        >
                          {itemNature.item_nature}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: grey,
                            fontSize: "0.8em",
                            width: '20%'
                          }}
                          index={index}
                        >
                          {itemNature.godown_code}
                        </TableCell>
                        {/* {console.log("itemNature", itemNature)} */}
                        <TableCell
                          align="center"
                          style={{
                            color: grey,
                            fontSize: "0.8em",
                            width: '15%'
                            // paddingLeft: 80
                          }}
                          index={index}
                        >
                          {itemNature.is_active ?
                            (
                              <CheckCircle
                                style={{ color: successButtonBackground }}
                              />
                            ) : (
                              <Cancel style={{ color: orange }} />
                            )}
                        </TableCell>
                        <TableCell
                          align="center"
                          index={index}
                          style={{ width: '15%' }}
                        >
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(props.user, 'plant_item_master')}
                          >
                            <IconButton
                              style={{
                                display: index !== itemNatureEditShow?.index ? "inline" : "none",
                                // marginLeft: 20
                              }}
                              disabled={!hasUpdateAccess(props.user, 'plant_item_master')}
                              onClick={(e) => handleItemNatureEditShow(index, itemNature)}
                            >
                              <EditOutlinedIcon />
                              {/* edit2 */}
                            </IconButton>
                            <IconButton
                              style={{
                                display: index !== itemNatureEditShow?.index ? "inline" : "none",
                                // marginLeft: 20
                              }}
                              //disabled={!hasUpdateAccess(props.user, 'plant_item_master')}
                              onClick={(e) => handleItemNatureSaveUpdate("delete", itemNature.id)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </ToolTipComponent>
                          <div style={{ display: index !== itemNatureEditShow?.index ? "none" : "inline", }}>
                            <IconButton onClick={() => handleItemNatureSaveUpdate("edit", itemNature.id)}>
                              <CheckOutlinedIcon />
                            </IconButton>
                            <IconButton onClick={() => { setItemNatureEditShow({ show: true, index: -1 }); handleItemNatureCancel(); }}>
                              <CloseOutlinedIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                  ))
                ) : null}
                {itemNatureEditShow.show ?
                  <TableRow style={{ display: openItemNature || !itemNatureEditShow.show ? "table-row" : "none" }}>
                    <>
                      <TableCell
                        index="1000"
                      >
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.addMoreButton}
                          style={{ minWidth: 200, width: '25%' }}
                        >
                          <InputLabel id="itemNatureSelect">
                            Item Nature*
                          </InputLabel>
                          {/* {console.log("item_category :" + plantItemNatureData.item_category)} */}
                          <Select
                            name="item_nature"
                            labelId="itemNatureSelect"
                            value={plantItemNatureData.item_nature || ""}
                            label="Item Nature"
                            MenuProps={MenuProps}
                            required
                          >
                            <MenuItem value="select" disabled={true}>
                              <em>Select Item Nature</em>
                            </MenuItem>
                            {filteredAllItemNatures &&
                              filteredAllItemNatures.length > 0
                              ? filteredAllItemNatures.map(
                                (filteritemNature, selectIndex) => (
                                  <MenuItem
                                    key={selectIndex}
                                    onClick={handlePlantItemNatureValueChange(filteritemNature.item_nature)}
                                    value={filteritemNature.item_nature}
                                  // style={{
                                  //   display: itemNatureNameList.includes(filteritemNature.item_nature) ? "none" : "flex"
                                  // }}
                                  >
                                    {filteritemNature.item_nature}
                                  </MenuItem>
                                )
                              )
                              : null}
                          </Select>
                        </FormControl>
                      </TableCell>

                      {/* {console.log("category :" + plantItemNatureData.plant_item_category_code)} */}
                      <TableCell
                        index="1000"
                        style={{ width: '28%' }}
                      >
                        <TextField
                          label="Enter godown code*"
                          name="godown_code"
                          type="text"
                          onChange={handlePlantItemNatureValueOnChange}
                          placeholder="Enter godown code"
                          className={classes.addMoreButton}
                          margin="dense"
                          variant="outlined"
                          //inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                          FormHelperTextProps={{ className: classes.helperText }}
                          value={plantItemNatureData.godown_code || ""}
                          error={plantItemNatureError.godown_code}
                          helperText={plantItemNatureError.godown_code ? "Invalid Code" : ""}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        index="1000"
                        style={{ width: '10%' }}
                      >
                        <IOSSwitch
                          checked={Boolean(plantItemNatureData.is_active)}
                          onChange={handlePlantItemNatureValueOnChange}
                          name="is_active"
                        />
                      </TableCell>
                      {/* {console.log("voucher :" + plantItemNatureData.voucher_code)} */}


                    </>

                  </TableRow>
                  :
                  null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ToolTipComponent
          title="You have no create permission on this widget"
          condition={!hasWriteAccess(props.user, 'plant_item_master')}
        >
          <Button
            style={{ display: openItemNature ? "none" : "inline-flex" }}
            size="medium"
            className={classes.addNewButton}
            variant="outlined"
            startIcon={<AddOutlinedIcon />}
            disabled={!itemNatureEditShow.show || !hasWriteAccess(props.user, 'plant_item_master')}
            onClick={() => setOpenItemNature(true)}
          >
            ADD NEW
          </Button>
        </ToolTipComponent>
        <div style={{ marginBottom: openItemNature ? 70 : 0 }}>
          <Button
            style={{ display: !openItemNature ? "none" : "inline-flex" }}
            size="medium"
            className={classes.saveButton}
            variant="contained"
            startIcon={<CheckOutlinedIcon />}
            onClick={() => handleItemNatureSaveUpdate("add", 0)}
            disabled={
              !plantItemNatureData.godown_code ||
              !plantItemNatureData.item_nature ||
              plantItemNatureData.godown_code === "" ||
              plantItemNatureData.item_nature == "" ||
              plantItemNatureError.godown_code ||
              plantItemNatureError.item_nature
            }
          >
            SAVE
          </Button>
          <Button
            style={{ display: !openItemNature ? "none" : "inline-flex" }}
            size="medium"
            className={classes.margin}
            onClick={handleItemNatureCancel}
          >
            CANCEL
          </Button>
        </div>

        <SnackbarComponent
          open={snackbarData.openSnackbar}
          handleClose={handleCloseSnackbar}
          snackbarMessage={snackbarData.snackbarMessage}
          type={snackbarData.snackbarType}
          customAutoHideDuration={5000}
        />
      </div>
    </>
  );
}
