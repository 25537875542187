import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import {
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Typography,
  Switch,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import COLORS, {
  secondaryBlueText,
  primaryColor,
  drawerTextColor,
  grey,
  tableHeaderText,
  whiteSmoke,
  successButtonBackground,
  cancelButtonBackground,
} from "../../../../../constants/internal/colors";
import SnackbarComponent from "../../../common/SnackbarComponent";
import Spinner from "../../../common/Spinner";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CreateIcon from "@material-ui/icons/Create";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { checkApiError, checkNameFieldAlphaNumeric, hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../../methods";
import moment from "moment";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Bread from "../../../common/Breadcrubs";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import SaveButton from "../../../common/SaveButton";
import ToolTipComponent from "../../../common/ToolTipComponent";

const stateCategoryDataStyles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titleWithBlueFont: {
    fontSize: pxToEm(18),
    color: secondaryBlueText,
  },
  displayData: {
    marginTop: 10,
  },
  categoryNameSection: {
    display: "flex",
    placeContent: "space-between",
    marginTop: 50,
    alignItems: "baseline",
    flexDirection: "column",
  },
  categoryNameAlignment: {
    display: "flex",
    placeItems: "baseline",
    marginTop: 20,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  editButton: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    marginRight: 10,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(14),
    fontWeight: "bold",
  },
  displayText: {
    color: grey,
    fontSize: pxToEm(14),
    paddingLeft: 10,
  },
  saveCancelButton: {
    marginTop: 20,
    display: "flex",
    placeContent: "baseline",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  tableContainer: {
    marginTop: 30,
    width: "100%",
    height: "calc(100vh - 260px)",
    overflow: "auto",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
  },
  tableHeaderCell: {
    color: tableHeaderText,
    fontWeight: "bold",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
  },
  rightItemFix: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    float: "right",
  },
  bodyTableRow: {
    "&:hover": {
      backgroundColor: COLORS.tableHoverColor + " !important",
    },
  },
  iconContainer: {
    height: 30,
    width: 30,
    background: "yellow",
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: whiteSmoke,
    overflow: "hidden",
    color: grey,
    "&:hover": {
      backgroundColor: whiteSmoke,
      width: 70,
      transition: "width 0.3s",
      cursor: "pointer",
    },
  },
  addButton: {
    float: "left",
    marginBottom: 30,
    marginTop: 20
  },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: '25ch'
  }
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class ManageStateCategoryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateName: "",
      stateCode: "",
      is_active: true,
      stateOperation: "view",
      stateId: null,
      page: 0,
      rowsPerPage: 10,
      openSnackbar: false,
      snackbarMessage: ``,
      snackbarType: "",
      stateNameError: false,
      stateCodeError: false,
      isLoading: false,
      isLoadingRemove: false
    };
  }

  componentDidMount() { }
  helperStateReset = () => {
    this.setState({
      stateName: "",
      stateCode: "",
      stateOperation: "view",
      stateId: null,
    });
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleDailyRateRegionState = (name) => {
    if (name === "add") {
      this.setState({ isLoading: true })
      this.props.handleAddState(this.state.stateName, this.state.stateCode, this.state.is_active)
        .then((response) => {
          if (response.status === 201) {
            this.helperStateReset()
            this.handleOpenSnackbar(
              `Daily Rate Region Created Successfully`,
              "success"
            );
            setTimeout(() => {
              this.setState({ isLoading: false })
              this.handleCloseSnackbar()
            }, 4000);
          } else {
            this.setState({ isLoading: false })
            this.handleOpenSnackbar(
              `Error Occured While Creating Daily Rate Region Please Try Again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   this.handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          this.setState({ isLoading: false })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.props.history.push(`${INTERNALROUTES.MASTERSMANAGMENT}`)
            this.props.history.push(`${INTERNALROUTES.LISTSTATEMASTER}`)
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
    else if (name === "edit") {
      this.setState({ isLoading: true })
      this.props.handleUpdateState(
        this.state.stateName,
        this.state.stateCode,
        this.state.stateId,
        this.state.is_active
      )
        .then((response) => {
          if (response.status === 200) {
            this.helperStateReset()
            this.handleOpenSnackbar(
              `Daily Rate Region Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              this.setState({ isLoading: false })
              this.handleCloseSnackbar()
            }, 4000);
          } else {
            this.setState({ isLoading: false })
            this.handleOpenSnackbar(
              `Error Occured While Updating Daily Rate Region Please Try Again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   this.handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          this.setState({ isLoading: false })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
    else if (name === "remove") {
      this.setState({ isLoadingRemove: true })
      this.props.handleDeleteState(this.state.stateId)
        .then((response, error) => {
          if (response.status === 204) {
            this.helperStateReset()
            this.handleOpenSnackbar(
              `Daily Rate Region Deleted Successfully`,
              "success"
            );
            setTimeout(() => {
              this.setState({ isLoadingRemove: false })
              this.handleCloseSnackbar()
            }, 4000);
          }
          // for (let key in response['response']) {
          //   console.log(key + ":" + response['response'][key])
          // }
          else if (response['response']['status'] === 500) {
            this.setState({ isLoadingRemove: false })
            this.helperStateReset()
            this.handleOpenSnackbar(
              `This Region cannot be Deleted Because it is referenced to Other Data (Daily Rate)`,
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
              this.props.history.push(`${INTERNALROUTES.MASTERSMANAGMENT}`)
              this.props.history.push(`${INTERNALROUTES.LISTSTATEMASTER}`)
            }, 5000);
          }
          else {
            this.setState({ isLoadingRemove: false })
            this.handleOpenSnackbar(
              `Error Occured While Deleting Daily Rate Region Please Try Again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   this.handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          this.setState({ isLoadingRemove: false })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
  }

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.name === "is_active" ? event.target.checked : event.target.value,
    });
    // if (this.state.stateOperation !== "add") {
    if (event.target.name !== "is_active") {
      if (!event.target.value) {
        event.target.name === 'stateName' ?
          this.setState({ stateNameError: true }) :
          this.setState({ stateCodeError: true });
      }
      else {
        if (event.target.name === 'stateName') {
          !checkNameFieldAlphaNumeric(event.target.value) ?
            this.setState({ stateNameError: true }) :
            this.setState({ stateNameError: false });
        }
        else if (event.target.name === 'stateCode') {
          !((event.target.value).length === 2) ?
            this.setState({ stateCodeError: true }) :
            this.setState({ stateCodeError: false });
        }
      }
    }
  };

  handleAddState = () => {
    if (!this.state.stateName || !this.state.stateCode) {
      console.log("All fields must be filled");
    } else {
      console.log("add new clicked");
    }
  };
  handleEditState = () => {
    console.log("in edit state");
    this.setState({ stateOperation: "edit" });
  };
  handleRemoveState = () => {
    console.log(`${this.state.selectedOptionToDisplay} selected to remove.`);
  };

  handleRowClick = (stateName, id, stateCode, active) => {
    this.setState({
      stateOperation: "edit",
      stateName: stateName,
      stateCode: stateCode,
      stateId: id,
      is_active: active
    });
  };

  renderBreadCrumbs = () => {
    let arr = [
      {
        title: "Master Management",
        onClick: () => this.props.history.goBack(),
      },
      {
        title: "Daily Rate Regions",
        onClick: () => this.setState({ stateOperation: "view" }),
      },
    ];
    if (this.state.stateOperation === "edit") {
      arr.push({
        title: "Edit",
        onClick: () => this.props.history.goBack(),
      });
    } else if (this.state.stateOperation === "add") {
      arr.push({
        title: "Create New",
        onClick: () => this.props.history.goBack(),
      });
    }
    return arr;
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };
  render() {
    const { stateOperation } = this.state;
    const { classes, dailyRatesState, fetchingState } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            {fetchingState ? (
              <React.Fragment>
                <Spinner />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Bread data={this.renderBreadCrumbs()} />
                {stateOperation === "view" ? (
                  <React.Fragment>
                    <div className={classes.addButton}>
                      <ToolTipComponent
                        title="You have no create permission on this widget"
                        condition={!hasWriteAccess(this.props.user, 'daily_rate_regions')}
                      >
                        <Button
                          style={{
                            backgroundColor: hasWriteAccess(this.props.user, 'daily_rate_regions') && COLORS.primaryColor,
                            color: hasWriteAccess(this.props.user, 'daily_rate_regions') && COLORS.drawerTextColor,
                          }}
                          variant="contained"
                          startIcon={<AddOutlinedIcon />}
                          disabled={!hasWriteAccess(this.props.user, 'daily_rate_regions')}
                          onClick={() => this.setState({ stateOperation: "add" })}
                        >
                          ADD NEW
                        </Button>
                      </ToolTipComponent>
                    </div>
                    <TableContainer
                      className={classes.tableContainer}
                      component={Paper}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell className={classes.tableHeaderCell}>
                              STATE
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableHeaderCell}
                            >
                              STATES CODE
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.tableHeaderCell}
                            >
                              LAST EDITED
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.tableHeaderCell}
                              style={{ width: 100 }}
                            >
                              &nbsp;
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(this.state.rowsPerPage > 0
                            ? dailyRatesState &&
                            dailyRatesState?.slice(
                              Number(this.state.page) *
                              Number(this.state.rowsPerPage),
                              Number(this.state.page) *
                              Number(this.state.rowsPerPage) +
                              Number(this.state.rowsPerPage)
                            )
                            : dailyRatesState
                          )?.map((displayData, index) => (
                            <TableRow
                              hover
                              key={displayData.state_name}
                              className={classes.bodyTableRow}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.productFont}
                              >
                                {displayData.state_name}
                              </TableCell>
                              <TableCell align="center">
                                {displayData.state_code}
                              </TableCell>
                              <TableCell align="right">
                                <div>
                                  <div className={classes.rightItemFix}>
                                    {moment(displayData.modified_at).format(
                                      "DD MMM YYYY"
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                <div
                                  style={{ width: 100 }}
                                  onClick={() =>
                                    this.handleRowClick(
                                      displayData.state_name,
                                      displayData.id,
                                      displayData.state_code,
                                      displayData.is_active
                                    )
                                  }
                                >
                                  <div className={classes.iconContainer}>
                                    <CreateIcon className={classes.icon} />
                                    <div
                                      style={{
                                        flex: 1,
                                        marginLeft: 5,
                                        textAlign: "left",
                                      }}
                                    >
                                      edit
                                    </div>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Paper>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={dailyRatesState?.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={(e) => {
                          e.preventDefault();
                          this.handleChangeRowsPerPage(e);
                        }}
                      />
                    </Paper>
                  </React.Fragment>
                ) : stateOperation === "edit" ? (
                  <div style={{ marginTop: 30, width: "38%" }}>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>STATE NAME* </Typography>
                      <TextField
                        label="State Name"
                        id="outlined-margin-dense1"
                        name="stateName"
                        onChange={this.handleTextChange}
                        value={this.state.stateName}
                        placeholder="Enter State Name"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        error={this.state.stateNameError}
                        helperText={this.state.stateNameError ? "Invalid Name" : ""}
                      />
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>STATE CODE* </Typography>
                        <TextField
                          label="State Code"
                          id="outlined-margin-dense2"
                          name="stateCode"
                          onChange={this.handleTextChange}
                          value={this.state.stateCode}
                          placeholder="Enter State Code"
                          className={classes.textField}
                          margin="dense"
                          inputProps={{ maxLength: 2, minLength: 2, max: 2, min: 2 }}
                          onKeyPress={(event) => {
                            if (event.charCode >= 48 && event.charCode <= 57) {
                              // let it happen, don't do anything
                            }
                            else { event.preventDefault(); }
                          }}
                          variant="outlined"
                          error={this.state.stateCodeError}
                          helperText={this.state.stateCodeError ? "Invalid Code" : ""}
                        />
                      </div>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        ACTIVE
                      </Typography>
                      <div className={classes.isActive}>
                        <IOSSwitch
                          checked={(this.state.is_active) === undefined
                            ? true
                            : Boolean(this.state.is_active)
                          }
                          onChange={this.handleTextChange}
                          name="is_active"
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <ToolTipComponent
                        title="You have no edit permission on this widget"
                        condition={!hasUpdateAccess(this.props.user, 'daily_rate_regions')}
                      >
                        <SaveButton
                          size="medium"
                          className={classes.saveButton}
                          variant="contained"
                          startIcon={<CheckOutlinedIcon />}
                          onClick={() => this.handleDailyRateRegionState("edit")}
                          disabled={
                            this.state.stateNameError ||
                            this.state.stateCodeError ||
                            this.state.stateName === "" ||
                            this.state.stateCode === "" ||
                            this.state?.isLoadingRemove ||
                            !hasUpdateAccess(this.props.user, 'daily_rate_regions')
                          }
                          isLoading={this.state?.isLoading}
                        >
                          SAVE
                        </SaveButton>
                      </ToolTipComponent>
                      <ToolTipComponent
                        title="You have no edit permission on this widget"
                        condition={!hasUpdateAccess(this.props.user, 'daily_rate_regions')}
                      >
                        <SaveButton
                          size="medium"
                          className={classes.removeButton}
                          variant="contained"
                          disabled={this.state?.isLoading || !hasUpdateAccess(this.props.user, 'daily_rate_regions')}
                          startIcon={<DeleteOutlineOutlinedIcon />}
                          onClick={() => this.handleDailyRateRegionState("remove")}
                          isLoading={this.state?.isLoadingRemove}
                        >
                          REMOVE
                        </SaveButton>
                      </ToolTipComponent>
                      <Button
                        size="medium"
                        className={classes.margin}
                        onClick={() =>
                          this.setState({
                            stateOperation: "view",
                            stateNameError: false,
                            stateCodeError: false,
                            stateName: "",
                            stateCode: "",
                            stateId: null
                          })
                        }
                      >
                        CANCEL
                      </Button>
                    </div>
                  </div>
                ) : stateOperation === "add" ? (
                  <div>
                    <div style={{ marginTop: 30, width: "38%" }}>
                      <div style={{ display: "flex", alignItems: "center", width: "60vh", justifyContent: "space-between" }}>
                        <Typography className={classes.titleLabel}>STATE NAME*</Typography>
                        <TextField
                          label="State Name"
                          id="outlined-margin-dense3"
                          name="stateName"
                          onChange={this.handleTextChange}
                          value={this.state.stateName}
                          placeholder="Enter State Name"
                          className={classes.textField}
                          margin="dense"
                          variant="outlined"
                          error={this.state.stateNameError}
                          helperText={this.state.stateNameError ? "Invalid Name" : ""}
                        />
                      </div>
                      <div style={{ marginTop: 10, display: "flex", alignItems: "center", width: "60vh", justifyContent: "space-between" }}>
                        <Typography className={classes.titleLabel}>STATE CODE*</Typography>
                        <TextField
                          label="State Code"
                          id="outlined-margin-dense4"
                          name="stateCode"
                          onChange={this.handleTextChange}
                          value={this.state.stateCode}
                          placeholder="Enter State Code"
                          className={classes.textField}
                          inputProps={{ maxLength: 2, minLength: 2, max: 2, min: 2 }}
                          onKeyPress={(event) => {
                            if (event.charCode >= 48 && event.charCode <= 57) {
                              // let it happen, don't do anything
                            }
                            else { event.preventDefault(); }
                          }}
                          margin="dense"
                          variant="outlined"
                          error={this.state.stateCodeError}
                          helperText={this.state.stateCodeError ? "Invalid Code" : ""}
                        />
                      </div>
                      <div style={{ marginTop: 20, display: "flex", alignItems: "center", width: "60vh", justifyContent: "space-between" }}>
                        <Typography className={classes.titleLabel}>
                          ACTIVE
                        </Typography>
                        <div className={classes.isActive}>
                          <IOSSwitch
                            checked={(this.state.is_active) === undefined
                              ? true
                              : Boolean(this.state.is_active)
                            }
                            onChange={this.handleTextChange}
                            name="is_active"
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: 30 }}>
                        <SaveButton
                          size="medium"
                          className={classes.saveButton}
                          variant="contained"
                          startIcon={<CheckOutlinedIcon />}
                          disabled={
                            this.state.stateNameError ||
                            this.state.stateCodeError ||
                            this.state.stateName === "" ||
                            this.state.stateCode === ""
                          }
                          onClick={() => this.handleDailyRateRegionState("add")}
                          isLoading={this.state.isLoading}
                        >
                          SAVE
                        </SaveButton>
                        <Button
                          size="medium"
                          className={classes.margin}
                          onClick={() =>
                            this.setState({
                              stateOperation: "view",
                              stateNameError: false,
                              stateCodeError: false,
                              stateName: "",
                              stateCode: "",
                              stateId: null
                            })
                          }
                        >
                          CANCEL
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1>nice loading spinner here</h1>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
        />
      </Layout>
    );
  }
}
export default withStyles(stateCategoryDataStyles)(ManageStateCategoryData);
