import React, { Component } from "react";
import OrderCard from "./OrderCard";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor } from "../../../styles/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  CancelMainBody: {
    width: "100%",
  },
  messageShowContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  messageShow: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    fontSize: "1.1em",
    color: bottomNavigationFontColor,
  },
};

class Cancel extends Component {
  render() {
    // console.log(this.props);
    const { classes } = this.props;
    return (
      <div className={classes.CancelMainBody}>
        {!this.props.isMobile ? (
          <div style={{ padding: "10px 0px 10px 2%" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                this.props.history.goBack();
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          </div>
        ) : null}
        <OrderCard title="Cancelled LA" />
        <div className={classes.messageShowContainer}>
          <div className={classes.messageShow}>
            <span>This LA is cancelled </span>
          </div>
        </div>
        <div
          style={{
            height: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CancelIcon
            style={{
              height: "90%",
              width: "40%",
              color: bottomNavigationFontColor,
            }}
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Cancel);
