import React, { Component } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManageCustomerTypeData from "../../../../components/internal/masterManagement/customerType/ManageCustomerTypeData";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayCustomerTypeData from "../../../../components/internal/masterManagement/customerType/web";
import { checkApiError } from "../../../../methods";

class ManageCustomerTypeContainer extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			customerTypeOperation: null,
			selectedCustomerType: null,
			selectedCustomerTypeData: [],
			selectedCustomerTypeDataFetchLoader: false,
			snackbarMessage: null,
			openSnackbar: false,
			snackbarType: "",
		};
	}
	getOptions = (user) => {
		return {
			headers: {
				Authorization: `JWT ${user.token}`,
				"Content-Type": "application/json;charset=UTF-8",
			},
		};
	};
	componentWillUnmount() {
		this._isMounted = false;
	}
	componentDidMount() {
		this._isMounted = true;
		let customerTypeOperation = this.props.location.pathname.includes("add")
			? "add"
			: this.props.location.pathname.includes("edit")
				? "edit"
				: "view";

		this.setState({ customerTypeOperation });
		if (customerTypeOperation !== "add") {
			const id = this.props?.location?.pathname?.split("/")[
				this.props?.location?.pathname?.split("/").length - 1
			];
			this.handleFetchSelectedCustomerTypeData(id);
			this.setState({ selectedCustomerType: id });
		}
	}

	static getDerivedStateFromProps(props, state) {
		let updatedData = new Map();
		let customerTypeOperation = props.location.pathname.includes("add")
			? "add"
			: props.location.pathname.includes("edit")
				? "edit"
				: "view";
		let selectedCustomerType =
			customerTypeOperation !== "add"
				? props?.location?.pathname?.split("/")[
				props?.location?.pathname?.split("/").length - 1
				]
				: null;
		if (customerTypeOperation !== state.customerTypeOperation) {
			updatedData.set("customerTypeOperation", customerTypeOperation);
		}
		if (selectedCustomerType !== state.selectedCustomerType) {
			updatedData.set("selectedCustomerType", selectedCustomerType);
		}
		if (updatedData.size > 0) {
			return {
				customerTypeOperation: updatedData.has("customerTypeOperation")
					? updatedData.get("customerTypeOperation")
					: customerTypeOperation,
				selectedCustomerType: updatedData.has("selectedCustomerType")
					? updatedData.get("selectedCustomerType")
					: [],
			};
		}
		return null;
	}
	handleFetchSelectedCustomerTypeData = async (selectedCustomerType) => {
		await this.setState({ selectedCustomerTypeDataFetchLoader: true });
		await axios
			.get(
				`${APIROUTES.GET_CUSTOMER_TYPE}${selectedCustomerType}/`,
				this.getOptions(this.props.user)
			)
			.then(
				(response) => {
					if (response.status === 200) {
						this.setState({
							selectedCustomerTypeData: response.data,
							selectedCustomerTypeDataFetchLoader: false,
						});
					} else {
						this.setState({
							selectedCustomerTypeDataFetchLoader: false,
							openSnackbar: true,
							snackbarMessage:
								"Something went wrong while fetching selected customer Type data try again!",
							snackbarType: "warning",
						});
					}
				},
				(error) => {
					// this.setState({
					//   selectedCustomerTypeDataFetchLoader: false,
					//   openSnackbar: true,
					//   snackbarMessage:
					//     "Something went wrong while fetching selected customer Type data try again!",
					//   snackbarType: "error",
					// });
					this.handleOpenSnackbar(
						this.handleSnackbarMessageBox(checkApiError(error.response.data)),
						"error"
					);
					setTimeout(() => {
						this.handleCloseSnackbar();
					}, 4000);
				}
			);
	};

	handleCustomerTypeDataAdd = (customerTypeData) =>
		new Promise((resolve, reject) => {
			// console.log("customerTypeData : ", customerTypeData);
			axios
				.post(
					APIROUTES.GET_CUSTOMER_TYPE,
					customerTypeData,
					this.getOptions(this.props.user)
				)
				.then((response) => {
					this.handleFetchSelectedCustomerTypeData(response.data.id);
					this.props.getCustomerTypeList();
					resolve(response);
				})
				.catch(reject);
		});
	handleCustomerTypeDataUpdate = (customerTypeData) =>
		new Promise((resolve, reject) => {
			// console.log("customerTypeData : ", customerTypeData);
			// console.log("customerTypeId : ", customerTypeId);
			const id = this.props?.location?.pathname?.split("/")[
				this.props?.location?.pathname?.split("/").length - 1
			];
			axios
				.patch(
					`${APIROUTES.GET_CUSTOMER_TYPE}${id}/`,
					customerTypeData,
					this.getOptions(this.props.user)
				)
				.then((response) => {
					this.handleFetchSelectedCustomerTypeData(id);
					// this.handleFetchSelectedCustomerTypeData(response.data.id);
					this.props.getCustomerTypeList();
					resolve(response);
				})
				.catch(reject);
		});
	handleCloseSnackbar = (event, customerType) => {
		if (customerType === "clickaway") {
			return;
		}
		this.setState({
			openSnackbar: false,
			snackbarMessage: null,
			snackbarType: "",
		});
	};

	handleOpenSnackbar = (message, type) => {
		this.setState({
			openSnackbar: true,
			snackbarMessage: message,
			snackbarType: type,
		});
	};

	handleSnackbarMessageBox = (data) => {
		return (
			data.map((item, index) => {
				return (
					<>
						<p style={{ margin: 0 }}>
							{item}
						</p>
					</>)
			})
		)
	}

	render() {
		const { customerTypeOperation } = this.state;
		if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
		// if (isAccessibleModule(this.props?.user, "Banks"))
		else {
			return (
				<>
					{customerTypeOperation === "view" ? (
						<DisplayCustomerTypeData {...this.props} {...this.state} />
					) : (
						<ManageCustomerTypeData
							{...this.props}
							{...this.state}
							handleCustomerTypeDataUpdate={this.handleCustomerTypeDataUpdate}
							handleCustomerTypeDataAdd={this.handleCustomerTypeDataAdd}
						/>
					)}
					<SnackbarComponent
						open={this.state.openSnackbar}
						handleClose={this.handleCloseSnackbar}
						snackbarMessage={this.state.snackbarMessage}
						type={this.state.snackbarType}
					/>
				</>
			);
		}
		// else return <AccessDenied />;
	}
}
export default withUser(ManageCustomerTypeContainer);
