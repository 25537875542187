import React from 'react';
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  cancelButtonBackground,
  drawerTextColor,
  primaryColor,
} from '../../../constants/internal/colors';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  loaderStyle: { color: primaryColor },
});

export default function RemoveConfirmationDialog(props) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
    >
      <DialogTitle>{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.removeDataLoader ? (
          <CircularProgress disableShrink className={classes.loaderStyle} />
        ) : (
          <>
            <Button onClick={props.handleClose} className={classes.margin}>
              CANCEL
            </Button>
            <Button
              onClick={props.handleConfirmationDialogDeleteClick}
              className={classes.removeButton}
            >
              DELETE
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
