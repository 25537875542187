import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import numberToText from "number2text";
import { ToWords } from 'to-words';
import telephone from "./telephone.json"
import {
  primaryColor,
  drawerTextColor,
} from "../../../constants/internal/colors";
import { printPDFInMobile } from "../../../methods";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const dashboardStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    borderRadius: "30px",
  },
});


function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
function savebase64AsPDF(folderpath, filename, content, contentType, downloadLoader) {
  // Convert the base64 string in a Blob
  console.log("here", folderpath)
  let DataBlob = b64toBlob(content, contentType);

  console.log("Starting to write the file :3");
  window.resolveLocalFileSystemURL(folderpath, function (dir) {
    console.log("Access to the directory granted succesfully");
    dir.getFile(filename, { create: true }, function (file) {
      console.log("File created succesfully.");

      file.createWriter(function (fileWriter) {
        console.log("Writing content to file");
        fileWriter.write(DataBlob);
        console.log("Folder Path" + folderpath + filename);
        let finalPath = folderpath + filename;
        window?.cordova?.plugins.fileOpener2.open(finalPath, 'application/pdf')
        downloadLoader !== '' && downloadLoader()
        // window.open(finalPath, '_system');

      }, function () {
        alert('Unable to save file in path ' + folderpath);
      });
    });
  });
}


class RIPLInvoicePdfDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laDetailsInDispatchCounter: {},
      total_invoice_value: 0.0,
      total_taxable_value: 0.0,
      total_value: 0.0,
      total_cess_value: 0.0,
      total_cgst_value: 0.0,
      total_sgst_value: 0.0,
      total_igst_value: 0.0,
      total_cess_non_advol_value: 0.0,
      total_freight_advance_amount: 0.0,
      total_quantity: 0.0,
      total_tcs_amount: 0.0,
      total_freight_amount: 0.0,
      total_tax_on_amount: 0.0,
      eInvoiceResponse: null,
      eWayBillResponse: null,
      b2CQrImage: null,
      plantAccountDetails: {},
      isPDFMailed: false,
    };
  }

  componentDidMount() {
    this.setState({
      laDetailsInDispatchCounter: this.props.laDetailsInDispatchCounter,
      total_invoice_value: this.props.total_invoice_value,
      total_taxable_value: this.props.total_taxable_value,
      total_value: this.props.total_value,
      total_cess_value: this.props.total_cess_value,
      total_cgst_value: this.props.total_cgst_value,
      total_sgst_value: this.props.total_sgst_value,
      total_igst_value: this.props.total_igst_value,
      eInvoiceResponse: this.props.eInvoiceResponse,
      eWayBillResponse: this.props.eWayBillResponse,
      total_cess_non_advol_value: this.props.total_cess_non_advol_value,
      total_freight_advance_amount: this.props.total_freight_advance_amount,
      total_freight_amount: this.props.total_freight_amount,
      total_quantity: this.props.total_quantity,
      total_tcs_amount: this.props.total_tcs_amount,
      total_tax_on_amount: this.props.total_tax_on_amount,
      b2CQrImage: this.props.b2CQrImage,
      plantAccountDetails: this.props.plantAccountDetails,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.laDetailsInDispatchCounter !== state.laDetailsInDispatchCounter) {
      return { laDetailsInDispatchCounter: props.laDetailsInDispatchCounter };
    }
    if (props.total_invoice_value !== state.total_invoice_value) {
      return { total_invoice_value: props.total_invoice_value };
    }
    if (props.total_taxable_value !== state.total_taxable_value) {
      return { total_taxable_value: props.total_taxable_value };
    }
    if (props.total_value !== state.total_value) {
      return { total_value: props.total_value };
    }
    if (props.total_cess_value !== state.total_cess_value) {
      return { total_cess_value: props.total_cess_value };
    }
    if (props.total_cgst_value !== state.total_cgst_value) {
      return { total_cgst_value: props.total_cgst_value };
    }
    if (props.total_sgst_value !== state.total_sgst_value) {
      return { total_sgst_value: props.total_sgst_value };
    }
    if (props.total_igst_value !== state.total_igst_value) {
      return { total_igst_value: props.total_igst_value };
    }
    if (props.eInvoiceResponse !== state.eInvoiceResponse) {
      return { eInvoiceResponse: props.eInvoiceResponse };
    }
    if (props.eWayBillResponse !== state.eWayBillResponse) {
      return { eWayBillResponse: props.eWayBillResponse };
    }
    if (props.total_cess_non_advol_value !== state.total_cess_non_advol_value) {
      return { total_cess_non_advol_value: props.total_cess_non_advol_value };
    }
    if (
      props.total_freight_advance_amount !== state.total_freight_advance_amount
    ) {
      return {
        total_freight_advance_amount: props.total_freight_advance_amount,
      };
    }
    if (props.total_freight_amount !== state.total_freight_amount) {
      return { total_freight_amount: props.total_freight_amount };
    }
    if (props.total_quantity !== state.total_quantity) {
      return { total_quantity: props.total_quantity };
    }
    if (props.total_tcs_amount !== state.total_tcs_amount) {
      return { total_tcs_amount: props.total_tcs_amount };
    }
    if (props.total_tax_on_amount !== state.total_tax_on_amount) {
      return { total_tax_on_amount: props.total_tax_on_amount };
    }
    if (props.b2CQrImage !== state.b2CQrImage) {
      return { b2CQrImage: props.b2CQrImage };
    }

    if (props.plantAccountDetails !== state.plantAccountDetails) {
      return { plantAccountDetails: props.plantAccountDetails };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.invoiceDownloadLoader && !this.state.isPDFMailed) {
      // if (!Boolean(localStorage.getItem('emailPDFcalled'))) {
      this.handleViewOrDownloadPdf("EMAIL", "ORIGINAL FOR RECIPIENT")
      // localStorage.setItem('emailPDFcalled', true)
      // }
      if (this.props.invoiceForPlatform)
        this.handleViewOrDownloadPdf("DOWNLOAD", "ORIGINAL FOR RECIPIENT")
      this.setState({
        isPDFMailed: true
      })
    }

  }

  // componentWillUnmount() {
  //   localStorage.removeItem('emailPDFcalled')
  // }

  numberConvertor = (number) => {
    const toWords = new ToWords();

    let words = toWords.convert(number, { currency: true });
    return words
  }

  // Function to convert logo into BASE64 Code

  getBase64ImageFromURL(ford, url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        // console.log(ford, dataURL)
        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  // dataURLtoFile = (dataurl, filename) => {

  //   var arr = dataurl.split(','),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], filename, { type: mime });
  // }

  //Usage example:

  handleViewOrDownloadPdf = async (pdfOperation, copyForText) => {
    // console.log("called", this.props, this.props)
    let divisionTable = []
    let gstTable = []
    let panTable = []

    let footerContainer = []
    let headerContainer = []


    if (this.props.isLogoRequired || pdfOperation === "EMAIL") {
      headerContainer.push([
        {
          columns: [
            [
              this.state?.laDetailsInDispatchCounter?.plant?.plant_logo &&
              {
                image: await this.getBase64ImageFromURL("plant", this.state?.laDetailsInDispatchCounter?.plant?.plant_logo),
                width: 120,
                height: 50,
                alignment: "left",
                // border: [false, false, false, false],
              },
              {
                text: `Works : ${this.props?.laDetailsInDispatchCounter?.plant?.plant_address}${this.props?.laDetailsInDispatchCounter?.plant?.plant_city ?
                  `, ${this.props?.laDetailsInDispatchCounter?.plant?.plant_city}` : ""} ${this.props?.plant_state ?
                    `, ${this.props?.plant_state}` : ""}${this.props?.laDetailsInDispatchCounter?.plant?.plant_pincode ?
                      `- ${this.props?.laDetailsInDispatchCounter?.plant?.plant_pincode}` : ""}`,
                fontSize: 7,
                // width: 200,
                margin: [0, 2, 0, 0]
              },
              {
                text: `Ph. No. : ${this.props?.laDetailsInDispatchCounter?.plant?.plant_mobile ? this.props?.laDetailsInDispatchCounter?.plant?.plant_mobile : ""}`,
                fontSize: 7
              },
              {
                text: `e-mail : ${this.props?.laDetailsInDispatchCounter?.plant?.plant_email}`,
                fontSize: 7
              }
            ],
            {
              text: "TAX INVOICE",
              width: 170,
              fontSize: 8,
              bold: true,
              decoration: "underline",
              // height: 50,
              alignment: "center",
              border: [true, true, true, true],
            },
            // [
            //   {
            //     // style: 'tableExample',
            //     style: "tableAlignRight",
            //     table: {
            //       body: rightInvoiceTypeTable,
            //       widths: [60, 15, 90],
            //       // dontBreakRows: true,
            //     },
            //     // layout: "headerLayout"
            //     // margin: [0, 15, 0, 0]
            //   },
            //   this.state?.laDetailsInDispatchCounter?.item_category?.item_category_logo &&
            //   {
            //     image: await this.getBase64ImageFromURL("item", this.state?.laDetailsInDispatchCounter?.item_category?.item_category_logo),
            //     width: 60,
            //     height: 60,
            //     alignment: "right",
            //     margin: [0, 2, 5, 0],
            //     // border: [false, false, false, false]
            //   },
            // ],
            {
              columns: [
                [
                  {
                    text: copyForText,
                    width: 290,
                    style: "topRightTextStyle",
                    border: [true, true, true, true],
                    // border: [false, false, false, false],
                  },
                  this.state?.laDetailsInDispatchCounter?.item_category?.item_category_logo &&
                  {
                    image: await this.getBase64ImageFromURL("item", this.state?.laDetailsInDispatchCounter?.item_category?.item_category_logo),
                    width: 60,
                    height: 60,
                    alignment: "right",
                    margin: [0, 5, 5, 0],
                    // border: [false, false, false, false]
                  },
                ]
              ]
            },
          ]
        },
        {
          columns: [
            {
              style: 'tableExample',
              table: {
                body: divisionTable,
                widths: [75, 5, 75],
                // dontBreakRows: true,
              },
              layout: "headerLayout"
              // margin: [0, 15, 0, 0]
            },
            {
              style: 'tableExample',
              table: {
                body: gstTable,
                widths: [50, 5, 100],
                // dontBreakRows: true,
              },
              layout: "headerLayout"
              // margin: [0, 15, 0, 0]
            },
            {
              style: 'tableExample',
              table: {
                body: panTable,
                widths: [20, 5, 125],
                // dontBreakRows: true,
              },
              layout: "headerLayout"
              // margin: [0, 15, 0, 0]
            },
          ]
        },
      ])

      let detailsWidth = [88, 12,]

      footerContainer.push([
        {
          text: "Disclaimer : ",
          style: "disclaimerStyle"
        },
        {
          columns: [
            [

              {
                text: "(1)  We declare that the particulars given above are true and correct.",
                style: "fontSevenLeft",
                width: 310,
              },
              {
                text: "(2)  The amount indicated / represents the price actually charged and that there is not flow of",
                style: "fontSevenLeft",
                width: 310,
              },
              {
                text: "additional consideration directly or indirectly from the buyer.",
                alignment: "left",
                fontSize: 7,
                margin: [14, 0, 0, 0],
                width: 310,
              },

              {
                text: "Notes :",
                style: "disclaimerStyle",
                border: [false, false, false, false],

              },
              {
                text: "(1)  Payment to be strictly on scheduled time, otherwise interest will be charged @18% P.A.",
                style: "fontSevenLeft",
                border: [false, false, false, false],
                width: 310
              },
              {
                text: "(2)  We are not responsible for any breakage and shortage once the goods leave our",
                style: "fontSevenLeft",
                border: [false, false, false, false],
                width: 310
              },
              {
                text: "premises/handed over to Railway or Motor Transport or other carriers.",
                alignment: "left",
                fontSize: 7,
                margin: [14, 0, 0, 0],
                border: [false, false, false, false],
                width: 310
              },
              {
                text: "(3)  Any variation in our supply should bought to our notice within 3 days from the receipt",
                style: "fontSevenLeft",
                border: [false, false, false, false],
                width: 310
              },
              {
                text: "of the goods.",
                alignment: "left",
                fontSize: 7,
                margin: [14, 0, 0, 0],
                border: [false, false, false, false],
                width: 310
              },
              {
                text: "(4)  Subject to Raipur Jurisdiction.",
                style: "fontSevenLeft",
                border: [false, false, false, false],
                width: 310
              },
              {
                text: "(5)  Tax Invoice under Section 31 with Rule 46 of CGST Act 2017",
                style: "fontSevenLeft",
                border: [false, false, false, false],
                width: 310
              },

            ],
            [
              {
                text: `For, ${this.props?.laDetailsInDispatchCounter?.plant?.plant_name}`,
                style: "forSignatureCenter",
                border: [false, false, false, false],
                margin: [0, 25, 0, 0],
                color: "blue",
                width: 300
              },
              {
                text: `Authorized Signature`,
                style: "SignatureCenter",
                border: [false, false, false, false],
                margin: [35, 50, 0, 0],
                width: 300
              },
            ]
          ]
        },
        {
          text: "Bank Details :",
          style: "disclaimerStyle",
          border: [false, false, false, false],
          margin: [0, -9, 0, 0]
        },
        {
          text: `Bank : ${this.props?.laDetailsInDispatchCounter?.plant?.bank_name}`,
          style: "fontSevenLeft",
          border: [false, false, false, false],
          // bold: true,
        },
        {
          text: `A/C # : ${this.props?.laDetailsInDispatchCounter?.plant?.plant_account_no}`,
          style: "fontSevenLeft",
          border: [false, false, false, false],
          // bold: true,
        },
        {
          text: `Branch and IFSC Code : ${this.props?.laDetailsInDispatchCounter?.plant?.branch_name} and ${this.props?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc}`,
          style: "fontSevenLeft",
          border: [false, false, false, false],
          // bold: true,
        },
        {
          columns: [
            {
              text: "Corporate Office :",
              style: "fontSevenLeft",
              border: [true, true, true, true],
              bold: true,
              width: 57
            },
            {
              text: `“Vrindavan”, Near IDBI Bank, Civil Lines, Raipur (C.G.) - 492001`,
              style: "fontSevenLeft",
              border: [true, true, true, true],
              width: 200

            }
          ],
          width: 300
        },
        {
          margin: [0, 10, 0, 0],
          columns: [
            {
              width: "25%",
              text: "E & OE.",
              style: "OEstyle"
            },
            {
              columns: [
                {
                  width: "16%",
                  text: `Driver / `,
                  style: "OEstyle"
                },
                {
                  image: telephone,
                  width: 9,
                  height: 9,
                  margin: [0, 1.5, 0, 0],
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  width: "2%",
                  text: " "
                },
                {
                  style: "OEValuestyle",
                  width: "52%",
                  text: `${" "} : ${this.props?.laDetailsInDispatchCounter?.driver_name} / ${this.props?.laDetailsInDispatchCounter?.driver_contact_number}`
                }
              ]
            },
            {
              columns: [
                {
                  width: "38%",
                  text: `Prepared/Checked By :`,
                  style: "OEValuestyle"
                },
                // {
                //   image: telephone,
                //   width: 9,
                //   height: 9,
                //   margin: [0, 1.5, 0, 0],
                //   alignment: "left",
                //   border: [false, false, false, false],
                // },
                // {
                //   width: "2%",
                //   text: " "
                // },
                {
                  style: "OEValuestyle",
                  width: "60%",
                  text: ` ${this.props?.laDetailsInDispatchCounter?.log_details?.BILLED?.user_name ? this.props?.laDetailsInDispatchCounter?.log_details?.BILLED?.user_name : ""}`,
                }
              ]
            },
            // {
            //   width: "37%",
            //   text: `Prepared By : ${this.props?.laDetailsInDispatchCounter?.log_details?.BILLED?.user_name ? this.props?.laDetailsInDispatchCounter?.log_details?.BILLED?.user_name : ""}`,
            //   style: "OEstyle"
            // },

          ]
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },
        this.props?.laDetailsInDispatchCounter?.plant?.logo_banner ?
          {
            image: await this.getBase64ImageFromURL("banner", this.props?.laDetailsInDispatchCounter?.plant?.logo_banner),
            width: 500,
            height: 22,
            alignment: "center",
            margin: [0, 2, 0, 2],
            // border: [false, false, false, false]
          } :
          {
            text: "",
            margin: [0, 26, 0, 0]
          },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },

        {
          text: "This is computer generated invoice",
          alignment: "center",
          fontSize: 7,
          margin: [0, 2, 0, 0]
        }
      ])
    }




    divisionTable.push([
      {
        text: "Range",
        style: "divisionHeader",
        border: [true, true, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, true, false, false],
      },
      {
        text: this.props?.laDetailsInDispatchCounter?.plant?.range,
        style: "divisionHeader",
        border: [false, true, true, false],
      },
    ],
      [{
        text: "Division",
        style: "divisionHeader",
        border: [true, false, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, false],
      },
      {
        text: this.props?.laDetailsInDispatchCounter?.plant?.division,
        style: "divisionHeader",
        border: [false, false, true, false],
      },
      ],
      [{
        text: "Commissionerate",
        style: "divisionHeader",
        border: [true, false, false, true],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, true],
      },
      {
        text: this.props?.laDetailsInDispatchCounter?.plant?.commissionerate,
        style: "divisionHeader",
        border: [false, false, true, true],
      },
      ],)

    gstTable.push([{
      text: "GSTIN",
      style: "divisionHeader",
      border: [true, true, false, false],
    },
    {
      text: ":",
      style: "divisionHeader",
      border: [false, true, false, false],
    },
    {
      text: this.props?.laDetailsInDispatchCounter?.plant?.plant_gst_number,
      style: "divisionHeader",
      border: [false, true, true, false],
    },
    ],
      [{
        text: "State Code",
        style: "divisionHeader",
        border: [true, false, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, false],
      },
      {
        text: this.props?.laDetailsInDispatchCounter?.plant?.plant_gst_number
          && `${this.props?.laDetailsInDispatchCounter?.plant?.plant_gst_number[0]}${this.props?.laDetailsInDispatchCounter?.plant?.plant_gst_number[1]}`,
        style: "divisionHeader",
        border: [false, false, true, false],
      },
      ],
      [{
        text: "State Name",
        style: "divisionHeader",
        border: [true, false, false, true],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, true],
      },
      {
        text: this.props?.plant_state,
        style: "divisionHeader",
        border: [false, false, true, true],
      },
      ],)

    panTable.push([{
      text: "Pan",
      style: "divisionHeader",
      border: [true, true, false, false],
    },
    {
      text: ":",
      style: "divisionHeader",
      border: [false, true, false, false],
    },
    {
      text: this.props?.laDetailsInDispatchCounter?.plant?.plant_pan_number,
      style: "divisionHeader",
      border: [false, true, true, false],
    },
    ],
      [{
        text: "CIN",
        style: "divisionHeader",
        border: [true, false, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, false],
      },
      {
        text: this.props?.laDetailsInDispatchCounter?.plant?.cin,
        style: "divisionHeader",
        border: [false, false, true, false],
      },
      ],
      [{
        text: "Tax is payable on reverse charges : No",
        colSpan: 3,
        style: "divisionHeader",
        border: [true, false, true, true],
      },
      {
        text: "",
        style: "divisionHeader",
        border: [false, false, false, true],
      },
      {
        text: "",
        style: "divisionHeader",
        border: [false, false, true, true],
      },
      ],)


    let sgstCgstSum = parseFloat(
      Number(
        this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount
      ) +
      Number(
        this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount
      )
    ).toFixed(2);
    let tableData = [];
    let roundOffSum = 0;
    // if (this?.state?.laDetailsInDispatchCounter?.total_values
    //   ?.total_item_value && this?.state?.laDetailsInDispatchCounter?.total_values
    //     ?.round_off)
    //   roundOffSum = this?.state?.laDetailsInDispatchCounter?.total_values
    //     ?.total_item_value?.toFixed(2) + this?.state?.laDetailsInDispatchCounter?.total_values
    //       ?.round_off?.toFixed(2)
    // let taxationTableData = [];
    let taxationTableData = [];
    // if (
    //   Number(this?.state?.laDetailsInDispatchCounter?.freight_charge_amount) !==
    //   0
    // ) {
    //   taxationTableData.push([
    //     {
    //       text: "FREIGHT CHARGE :",
    //       border: [false, false, false, false],
    //       style: "fontSixCenter",
    //       width: "40%",
    //     },
    //     {
    //       border: [false, false, false, false],
    //       text: `${
    //         // this?.state?.laDetailsInDispatchCounter[0]?.FREIGHTRATE || 0
    //         this?.state?.laDetailsInDispatchCounter?.freight_charge_rate.toFixed(2) || 0
    //         } / MT`,
    //       style: "fontSixCenter",
    //       width: "30%",
    //     },
    //     {
    //       // text: parseFloat(this?.state?.total_freight_amount || 0).toFixed(2),
    //       text: parseFloat(
    //         this?.state?.laDetailsInDispatchCounter?.freight_charge_amount || 0
    //       ).toFixed(2),
    //       border: [false, false, false, false],
    //       // style: "fontEightRight",
    //       style: "fontSixRight",
    //       width: "30%",
    //     },
    //   ]);
    // }

    let eInvoiceDataTable = [];
    //let eInvoiceDataTable = [];
    let b2CInvoiceData = [];


    const pageContent = []
    // let generateContent = async (i) => {
    // console.log(i)
    // eInvoiceDataTable = []
    // tableData = []
    // taxationTableData = []
    // headerContainer = []

    // if (this.props.isLogoRequired) {
    //   headerContainer.push()
    // }

    // Taxation Table Contents



    if (
      Number(
        this?.state?.laDetailsInDispatchCounter?.total_values.sgst_amount
      ) >
      Number(this?.state?.laDetailsInDispatchCounter?.total_values.igst_amount)
    ) {
      taxationTableData.push([
        {
          text: "SGST :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          // text: `${this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes.map(
          //   (ele) => find(ele.tax_type === "SGST").tax_percent
          // )} %`,
          text:
            Number(this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes.find(
              (o) => o.tax_type === "SGST"
            ).tax_percent).toFixed(2) + "%",
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          // text: this?.state?.total_sgst_value || "",
          text: this?.state?.laDetailsInDispatchCounter?.total_values
            .sgst_amount.toFixed(2),
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
      taxationTableData.push([
        {
          text: "CGST :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text:
            Number(this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes.find(
              (o) => o.tax_type === "CGST"
            ).tax_percent).toFixed(2) + "%",
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          // text: this?.state?.total_cgst_value || "",
          text: this?.state?.laDetailsInDispatchCounter?.total_values
            .cgst_amount.toFixed(2),
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    } else {

      if (this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount !== 0) {
        taxationTableData.push([
          {
            text: "IGST :",
            border: [false, false, false, false],
            style: "fontSevenRight",
            width: "40%",
          },
          {
            border: [false, false, false, false],
            text:
              Number(this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes.find(
                (o) => o.tax_type === "IGST"
              )?.tax_percent).toFixed(2) + "%",

            style: "taxDetailCenter",
            width: "30%",
          },
          {
            // text: this?.state?.total_igst_value,
            text: this?.state?.laDetailsInDispatchCounter?.total_values
              .igst_amount,
            border: [false, false, false, false],
            // style: "fontEightRight",
            style: "fontSevenRight",
            width: "30%",
          },
        ]);
      } else {
        taxationTableData.push([
          {
            text: "",
            border: [false, false, false, false],
            style: "taxDetailCenter",
            width: "40%",
          },
          {
            text: "",
            border: [false, false, false, false],
            style: "fontSixRight",
            width: "30%",
          },
          {
            text: "",
            border: [false, false, false, false],
            style: "fontSevenRight",
            width: "30%",
          },
        ]);
      }

    }

    if (this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount !== 0) {
      taxationTableData.push([
        {
          text: "CESS :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: `${
            // this?.state?.laDetailsInDispatchCounter[0]?.FREIGHTRATE || 0
            Number(this?.state?.laDetailsInDispatchCounter?.cess_rate).toFixed(2) || 0
            } / MT`,
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          // text: parseFloat(this?.state?.total_freight_amount || 0).toFixed(2),
          text: parseFloat(
            this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount || 0
          ).toFixed(2),
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSixRight",
          width: "30%",
        },
      ]);
    }

    if (this?.state?.laDetailsInDispatchCounter?.total_values?.tcs_amount !== 0) {
      taxationTableData.push([
        {
          text: "TCS :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: `${
            // this?.state?.laDetailsInDispatchCounter[0]?.FREIGHTRATE || 0
            Number(this?.state?.laDetailsInDispatchCounter?.tcs_percent) || 0
            } %`,
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          // text: parseFloat(this?.state?.total_freight_amount || 0).toFixed(2),
          text: `${parseFloat(
            this?.state?.laDetailsInDispatchCounter?.total_values?.tcs_amount || 0
          ).toFixed(2)}`,
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    }

    if (Number(this?.state?.laDetailsInDispatchCounter?.fright_advance) !== 0) {
      taxationTableData.push([
        {
          text: "FREIGHT ADVANCE :",
          border: [false, false, false, false],
          style: "fontSevenLeft",
          width: "40%",
        },
        {
          text: "",
          border: [false, false, false, false],
          style: "taxDetailCenter",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text:
            Number(this?.state?.laDetailsInDispatchCounter?.fright_advance).toFixed(2),

          style: "fontSevenRight",
          width: "30%",
        },
        // {
        //   // text: this?.state?.total_igst_value,
        //   text: this?.state?.laDetailsInDispatchCounter?.total_values
        //     .igst_amount,
        //   border: [false, false, false, false],
        //   // style: "fontEightRight",
        //   style: "fontSevenRight",
        //   width: "30%",
        // },
      ]);
    }
    // else {
    //   taxationTableData.push([
    //     {
    //       text: "",
    //       border: [false, false, false, false],
    //       style: "taxDetailCenter",
    //       width: "40%",
    //     },
    //     {
    //       text: "",
    //       border: [false, false, false, false],
    //       style: "fontSixRight",
    //       width: "30%",
    //     },
    //     {
    //       text: "",
    //       border: [false, false, false, false],
    //       style: "fontSevenRight",
    //       width: "30%",
    //     },
    //   ]);
    // }

    if (this?.state?.laDetailsInDispatchCounter?.total_values
      ?.round_off && this?.state?.laDetailsInDispatchCounter?.total_values
        ?.round_off !== 0) {
      taxationTableData.push([
        {
          text: "ROUND OFF :",
          border: [false, false, false, true],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, true],
          text: "",
          style: "fontSevenCenter",
          width: "30%",
        },
        {
          text: this?.state?.laDetailsInDispatchCounter?.total_values
            ?.round_off?.toFixed(2),
          border: [false, false, false, true],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    }

    //E-Invoice Table Contents
    if (this.props?.documentFor === "Delivery Challan") {
      // console.log(this?.state)
      eInvoiceDataTable.push([
        {
          text: `DELIVERY CHALLAN NO. : ${this?.state?.laDetailsInDispatchCounter?.erp_invoice_number}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `DELIVERY CHALLAN DATE : ${moment(this?.state?.eWayBillResponse?.Date ||
            this?.state?.eWayBillResponse?.EwbDt || this?.state?.eInvoiceResponse?.EwbDt ||
            this?.state?.laDetailsInDispatchCounter?.gate_in_date,
            [
              "DD/MM/YYYY hh:mm:ss A",
              "YYYY-MM-DD hh:mm:ss A",
            ]).format("DD-MMM-YYYY") || " "}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `TYPE : ${this.props?.documentFor.toUpperCase()}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `CATEGORY : ${this?.state?.laDetailsInDispatchCounter?.tran_supply_type}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
    }


    if (this.props?.documentFor === "Invoice" && this?.state?.laDetailsInDispatchCounter?.tran_supply_type !== "B2C") {
      eInvoiceDataTable.push([
        {
          text: `INVOICE NO. : ${this?.state?.laDetailsInDispatchCounter?.erp_invoice_number}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `INVOICE DATE : ${moment(this?.state?.laDetailsInDispatchCounter?.doc_date, [
            "DD/MM/YYYY",
            "YYYY-MM-DD",
          ]).format("DD-MMM-YYYY")}`,
          // text: `INVOICE DATE : ${this?.state?.eInvoiceResponse?.DocDate}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `TYPE : ${this.props?.documentFor.toUpperCase()}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `CATEGORY : ${this?.state?.laDetailsInDispatchCounter?.tran_supply_type}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
    }

    if (this?.state?.eInvoiceResponse !== null && this?.state?.laDetailsInDispatchCounter?.tran_supply_type !== "B2C") {
      if (this?.state?.eInvoiceResponse?.Status === "1") {
        eInvoiceDataTable.push([
          {
            text: "E-Invoice",
            style: "invoiceDetailLable",
            fillColor: "#f5f5f5",
            alignment: "center",
            border: [false, false, false, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            image: `data:image/png;base64,${this?.state?.eInvoiceResponse?.SignedQRCode}`,
            width: 115,
            height: 115,
            alignment: "center",
            border: [true, false, true, false],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `IRN : ${this?.state?.eInvoiceResponse?.Irn || ""}`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `IRN DATE : ${this.state.eInvoiceResponse && this.state.eInvoiceResponse.AckDate
              ? moment(
                new Date(this?.state?.eInvoiceResponse?.AckDate)
              ).format("DD-MMM-YYYY")
              : ""
              }`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: "",
            border: [false, false, false, false],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `INVOICE NO. : ${this?.state?.eInvoiceResponse?.DocNo}`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `INVOICE DATE : ${moment(this?.state?.laDetailsInDispatchCounter?.doc_date, [
              "DD/MM/YYYY",
              "YYYY-MM-DD",
            ]).format("DD-MMM-YYYY")}`,
            // text: `INVOICE DATE : ${this?.state?.eInvoiceResponse?.DocDate}`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `TYPE : ${this?.state?.eInvoiceResponse?.DocType === "INV" ? "TAX INVOICE"
              : this?.state?.eInvoiceResponse?.DocType === "CRN" ? "CREDIT NOTE"
                : this?.state?.eInvoiceResponse?.DocType === "DBN" && "DEBIT NOTE"}`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
        // console.log(this?.state?.laDetailsInDispatchCounter?.doc_date)
        eInvoiceDataTable.push([
          {
            text: `CATEGORY : ${this?.state?.laDetailsInDispatchCounter?.tran_supply_type}`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
      }

    } else if (
      this?.state?.laDetailsInDispatchCounter?.tran_supply_type === "B2C"
    ) {
      eInvoiceDataTable.push([
        {
          text: "Invoice",
          style: "invoiceDetailLable",
          fillColor: "#f5f5f5",
          alignment: "center",
          border: [false, false, false, true],
        },
      ]);
      this?.state?.b2CQrImage &&
        eInvoiceDataTable.push([
          this?.state?.laDetailsInDispatchCounter?.payment_method
            ?.payment_method_name === "Advance"
            ? //    &&
            // this?.state?.laDetailsInDispatchCounter?.payment_amount
            {
              qr: `{
                 "SUPGSTN" : "${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number || ""
                }", "PAYEE_AC" : ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no || ""
                }, "PAYEE_IFSC" : "${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc || ""
                }", ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
                  ? `"IGST : ${this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount || ""
                  }, `
                  : `"CGST : ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount || ""
                  }, "SGST" : ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount || ""
                  }, `
                } "CESS" :  ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount || ""
                }, "TOT_AMT" : ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value || ""
                }, "INV_DATE" : ${moment(
                  this?.state?.laDetailsInDispatchCounter?.created_at,
                  ["DD/MM/YYYY", "YYYY-MM-DD"]
                ).format("DD MMM YYYY")},
            }`,
              fit: 130,
              width: 130,
              height: 130,
              alignment: "center",
              border: [true, false, true, false],
            }
            : this?.state?.b2CQrImage === null ? {} : {
              image: this?.state?.b2CQrImage || "",
              width: 130,
              height: 130,
              alignment: "center",
              border: [true, false, true, false],
            },
        ]);
      if (
        this?.state?.laDetailsInDispatchCounter?.payment_method
          ?.payment_method_name === "Advance"
      ) {
        eInvoiceDataTable.push([
          {
            text: `REFERENCE NO. : ${this?.state?.laDetailsInDispatchCounter?.order
              ?.party_ref_number || ""
              }`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `AMOUNT 
             ${this?.state?.laDetailsInDispatchCounter?.payment_method
                ?.payment_method_name === "Advance" &&
                (this?.state?.laDetailsInDispatchCounter?.payment_amount !==
                  null ||
                  this?.state?.laDetailsInDispatchCounter?.order
                    ?.party_ref_number !== null)
                ? this?.state?.laDetailsInDispatchCounter?.payment_amount || 0
                : 0
              }
            `,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);

        eInvoiceDataTable.push([
          {
            text: `PAYMENT DATE : ${
              // this?.state?.plantItemDetails[0]?.REFRENCEDATE !== null
              //   ? moment(
              //       new Date(this.state.plantItemDetails[0]?.REFRENCEDATE)
              //     ).format("DD-MMM-YYYY")
              //   : ""
              "01/02/2021"
              }`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `MODE OF PAYMENT : ${this?.state?.laDetailsInDispatchCounter?.payment_method?.payment_method_name}`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);
      }
      eInvoiceDataTable.push([
        {
          text: "",
          border: [false, false, false, false],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `INVOICE NO. : ${this?.state?.laDetailsInDispatchCounter?.erp_invoice_number}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `INVOICE DATE : ${moment(this?.state?.laDetailsInDispatchCounter?.doc_date, [
            "DD/MM/YYYY",
            "YYYY-MM-DD",
          ]).format("DD-MMM-YYYY")}`,
          // text: `INVOICE DATE : ${this?.state?.eInvoiceResponse?.DocDate}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `TYPE : ${this.props?.documentFor.toUpperCase()}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
      eInvoiceDataTable.push([
        {
          text: `CATEGORY : ${this?.state?.laDetailsInDispatchCounter?.tran_supply_type}`,
          style: "invoiceDetailLable",
          color: "#333333",
          fillColor: "#f5f5f5",
          margin: [5, 0, 0, 0],
          alignment: "left",
          border: [true, false, true, true],
        },
      ]);
    }

    // TABLE DATA TABLE CONTENTS

    tableData.push([
      {
        text: "Sr. No.",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "Description and Speci. Of Goods",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "HSN/SAC No.",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "No. Of Packages",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "Total Qty.",
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
      {
        text: "Unit",
        style: "itemsHeader",
        alignment: "center",
        border: [false, false, false, true],
      },
      {
        text: "Unit Rate (₹)",
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
      // {
      //   text: "Net Rate",
      //   style: "itemsHeader",
      //   alignment: "right",
      //   border: [false, false, false, true],
      // },
      {
        text: `Freight Charge ${this.props?.laDetailsInDispatchCounter?.freight_charge_rate ? `(${this.props?.laDetailsInDispatchCounter?.freight_charge_rate} /MT)` : ""} `,
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
      {
        text: "Amount(Rs.)",
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
    ]);

    // console.log(this.state)
    this.state.laDetailsInDispatchCounter.loading_advice_items.map(
      (itemDetails, index) => {
        roundOffSum =
          Number(roundOffSum) + Number(itemDetails?.ROUNDOFFAMOUNT1 || 0);
        tableData.push([
          {
            //text: itemDetails?.SERIAL_NO || "",
            text: index + 1,
            style: "itemNumber",
            border: [false, false, false, false],
          },
          {
            // text: itemDetails?.PRODUCTNAME || "",
            text: itemDetails?.item?.item_name || "",
            style: "itemSubTitle",
            border: [false, false, false, false],
          },
          {
            // text: itemDetails?.HSNCODE || "",
            text:
              this?.state?.laDetailsInDispatchCounter?.item_category
                ?.hsn_code || "",
            style: "itemNumber",
            border: [false, false, false, false],
          },
          {
            // text:
            // this?.state?.laDetailsInDispatchCounter[0]?.DOCTYPE === "CRN" ||
            // this?.state?.laDetailsInDispatchCounter[0]?.DOCTYPE === "DBN"
            //   ? 0
            //   : itemDetails?.REMARK || "",
            text: `${itemDetails?.actual_section_count || 0} ${itemDetails?.section_name || ""
              }`,

            style: "itemNumber",
            border: [false, false, false, false],
          },
          {
            // text: parseFloat(itemDetails?.QUANTITY || 0).toFixed(3),
            // text: parseFloat(itemDetails?.item_qty || 0).toFixed(3),
            text: `${Number.isNaN(
              Number(
                this.state.laDetailsInDispatchCounter?.loading_advice_items[
                  index
                ]?.alloted_weight
              )
            )
              ? 0
              : Number(
                this.state.laDetailsInDispatchCounter?.loading_advice_items[
                  index
                ]?.alloted_weight
              ).toFixed(3)
              }`,
            style: "itemNumberRightAligned",
            border: [false, false, false, false],
          },
          {
            // text: itemDetails?.QTYUNIT || "",
            // text: itemDetails?.item?.unit_name || "",
            text: "MT",
            style: "itemNumber",
            border: [false, false, false, false],
          },
          {
            // text: parseFloat(
            //   Number(itemDetails?.RATE || 0) -
            //     Number(itemDetails?.DISCOUNT || 0)
            // ).toFixed(2),
            // text: parseFloat(
            //   Number(itemDetails?.total_amount || 0) -
            //     Number(itemDetails?.item?.discount_amount || 0)
            // ).toFixed(2),
            text: itemDetails?.item_rate.toFixed(2),
            style: "itemNumberRightAligned",
            border: [false, false, false, false],
          },
          // {
          //   // text: parseFloat(itemDetails?.TAXONAMOUNT || 0).toFixed(2),
          //   // text: parseFloat(itemDetails?.taxable_amount).toFixed(2),
          //   text: this.state.laDetailsInDispatchCounter?.net_rate ? this.state.laDetailsInDispatchCounter?.net_rate.toFixed(2) : "",
          //   style: "itemNumberRightAligned",
          //   border: [false, false, false, false],
          // },
          {
            // text: parseFloat(itemDetails?.TAXONAMOUNT || 0).toFixed(2),
            // text: parseFloat(itemDetails?.taxable_amount).toFixed(2),
            text: (this.state.laDetailsInDispatchCounter?.freight_charge_rate * itemDetails?.alloted_weight).toFixed(2),
            style: "itemNumberRightAligned",
            border: [false, false, false, false],
          },
          {
            // text: parseFloat(itemDetails?.TAXONAMOUNT || 0).toFixed(2),
            // text: parseFloat(itemDetails?.taxable_amount).toFixed(2),
            text: itemDetails?.item_taxable_amount.toFixed(2),
            style: "itemNumberRightAligned",
            border: [false, false, false, false],
          },
        ]);
      }
    );

    pageContent.push({
      columns: [

        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: [50, 50, 50, 50],
            body: [
              [
                {
                  text: "Bill To",
                  style: "invoiceDetailLable",
                  colSpan: 4,
                  alignment: "left",
                  fillColor: "#f5f5f5",
                  border: [false, false, false, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.first_name +
                    " " +
                    this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.last_name,
                  style: "buyerName",
                  colSpan: 4,
                  alignment: "left",
                  border: [true, false, true, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.address ?
                      (
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.address
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.address + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.city
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.city + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.state
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.state + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.country
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.country + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.pincode
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.pincode
                          : "")
                      )
                      :
                      (
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.door_number
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.door_number + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.floor_number
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.floor_number + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.building_name
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.building_name + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.street
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.street + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.location
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.location + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name + ", "
                          : "")
                        +
                        ((this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name &&
                          this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name)
                          ? ((this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name).trim().toUpperCase() !==
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name).trim().toUpperCase())
                            ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name + ", "
                            : ""
                          : this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name
                            ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name + ", "
                            : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.state_name
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.state_name + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.pincode
                          ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.pincode
                          : "")
                      ),
                  style: "buyerAddressDetails",
                  colSpan: 4,
                  alignment: "left",
                  border: [true, false, true, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: `STATE CODE: ${this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.state_code
                    || this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.state_code || ""
                    }`,
                  style: "buyerAddressDetails",
                  colSpan: 2,
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {},
                {
                  text: `GSTIN: ${this?.state?.laDetailsInDispatchCounter?.buyer?.gstin_number === null ? "N/A" : this?.state?.laDetailsInDispatchCounter?.buyer?.gstin_number}`,
                  style: "buyerAddressDetails",
                  alignment: "left",
                  colSpan: 2,
                  border: [false, false, true, false],
                },
                {},
              ],
              [
                {
                  text: `PAN NO: ${this?.state?.laDetailsInDispatchCounter?.buyer
                    ?.pan_card_number ?
                    this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.pan_card_number :
                    this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.gstin_number ?
                      this?.state?.laDetailsInDispatchCounter?.buyer
                        ?.gstin_number.slice(2, 12) : ""
                    }`,
                  style: "buyerAddressDetails",
                  alignment: "left",
                  colSpan: 2,
                  border: [true, false, false, false],
                },
                {},
                {
                  text: `MOBILE NO: ${this?.state?.laDetailsInDispatchCounter?.buyer
                    ?.customer_addresses?.contact ||
                    this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.gst_addresses?.contact || ""
                    }`,
                  style: "buyerAddressDetails",
                  alignment: "left",
                  colSpan: 2,
                  border: [false, false, true, false],
                },
                {},
              ],
              [
                {
                  text: `Email : ${this?.state?.laDetailsInDispatchCounter?.buyer
                    ?.customer_addresses?.email ||
                    this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.gst_addresses?.email || ""
                    } `,
                  style: "buyerAddressDetails",
                  colSpan: 4,
                  alignment: "left",
                  border: [true, false, true, true],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: "",
                  colSpan: 4,
                  border: [false, false, false, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: `Consignee (Ship To)`,
                  style: "invoiceDetailLable",
                  colSpan: 4,
                  alignment: "left",
                  fillColor: "#f5f5f5",
                  border: [true, false, true, true],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.consignee
                      ?.first_name +
                    " " +
                    this?.state?.laDetailsInDispatchCounter?.consignee
                      ?.last_name,
                  style: "buyerName",
                  colSpan: 4,
                  alignment: "left",
                  border: [true, false, true, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.address ?
                      (
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.address
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.address + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.city
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.city + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.state
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.state + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.country
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.country + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.pincode
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.pincode
                          : "")
                      )
                      :
                      (
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.door_number
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.door_number + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.floor_number
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.floor_number + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.building_name
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.building_name + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.street
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.street + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.location
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.location + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name + ", "
                          : "")
                        +
                        ((this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name &&
                          this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name)
                          ? ((this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name).trim().toUpperCase() !==
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name).trim().toUpperCase())
                            ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                            : ""
                          : this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name
                            ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name
                            : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.state_name
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.state_name + ", "
                          : "")
                        +
                        (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.pincode
                          ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.pincode
                          : "")
                      ),
                  style: "buyerAddressDetails",
                  colSpan: 4,
                  alignment: "left",
                  border: [true, false, true, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: `STATE CODE: ${this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.state_code
                    || this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.state_code || ""
                    }`,
                  style: "buyerAddressDetails",
                  colSpan: 2,
                  alignment: "left",
                  border: [true, false, false, false],
                },
                {},
                {
                  text: `GSTIN: ${this?.state?.laDetailsInDispatchCounter?.consignee?.gstin_number === null ? "N/A" : this?.state?.laDetailsInDispatchCounter?.consignee?.gstin_number}`,
                  style: "buyerAddressDetails",
                  alignment: "left",
                  colSpan: 2,
                  border: [false, false, true, false],
                },
                {},
              ],
              [
                {
                  text: `PAN NO: ${this?.state?.laDetailsInDispatchCounter?.consignee
                    ?.pan_card_number ?
                    this?.state?.laDetailsInDispatchCounter?.consignee
                      ?.pan_card_number :
                    this?.state?.laDetailsInDispatchCounter?.consignee
                      ?.gstin_number ?
                      this?.state?.laDetailsInDispatchCounter?.consignee
                        ?.gstin_number.slice(2, 12) : ""
                    }`,
                  style: "buyerAddressDetails",
                  alignment: "left",
                  colSpan: 2,
                  border: [true, false, false, false],
                },
                {},
                {
                  text: `MOBILE NO: ${this?.state?.laDetailsInDispatchCounter?.consignee
                    ?.consignee_from_customer_addresses?.contact ||
                    this?.state?.laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_gst_information_addresses?.contact || ""
                    }`,
                  style: "buyerAddressDetails",
                  alignment: "left",
                  colSpan: 2,
                  border: [false, false, true, false],
                },
                {},
              ],
              [
                {
                  text: `Email : ${this?.state?.laDetailsInDispatchCounter?.consignee
                    ?.consignee_from_customer_addresses?.email ||
                    this?.state?.laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_gst_information_addresses?.email || ""
                    }`,
                  style: "buyerAddressDetails",
                  colSpan: 4,
                  alignment: "left",
                  border: [true, false, true, true],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: "",
                  colSpan: 4,
                  border: [false, false, false, false],
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: "CONTRACT NO. :",
                  style: "buyerAddressDetails",
                  alignment: "left",
                  fillColor: "#f5f5f5",
                  border: [true, true, false, true],
                  colSpan: 2,
                },
                {},
                {
                  text: this?.state?.laDetailsInDispatchCounter?.order
                    ?.order_number,
                  style: "buyerAddressDetails",
                  alignment: "left",
                  fillColor: "#f5f5f5",
                  color: "#333333",
                  border: [true, true, false, true],
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: "CONTRACT DATE :",
                  style: "buyerAddressDetails",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                  alignment: "left",
                  colSpan: 2,
                },
                {},
                {
                  text:
                    this.state.laDetailsInDispatchCounter &&
                      this.state.laDetailsInDispatchCounter?.order
                        ?.order_validity
                      ? moment(
                        new Date(
                          this?.state?.laDetailsInDispatchCounter?.order?.created_at
                        )
                      ).format("DD-MMM-YYYY")
                      : "",

                  style: "buyerAddressDetails",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                  color: "#333333",

                  alignment: "left",
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: "LA NO :",
                  style: "buyerAddressDetails",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                  alignment: "left",
                  colSpan: 2,
                },
                {},
                {
                  text:
                    this.state.laDetailsInDispatchCounter &&
                      this.state.laDetailsInDispatchCounter?.la_number
                      ?
                      this?.state?.laDetailsInDispatchCounter?.la_number
                      : "",

                  style: "buyerAddressDetails",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                  color: "#333333",

                  alignment: "left",
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: "LA DATE :",
                  style: "buyerAddressDetails",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                  alignment: "left",
                  colSpan: 2,
                },
                {},
                {
                  text:
                    this.state.laDetailsInDispatchCounter &&
                      this.state.laDetailsInDispatchCounter?.created_at
                      ? moment(
                        new Date(
                          this?.state?.laDetailsInDispatchCounter?.created_at
                        )
                      ).format("DD-MMM-YYYY")
                      : "",

                  style: "buyerAddressDetails",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                  color: "#333333",

                  alignment: "left",
                  colSpan: 2,
                },
                {},
              ],
            ],
          },

          layout: {
            hLineColor: function (i, node) {
              return "#f5f5f5";
            },
            vLineColor: function (i, node) {
              return "#f5f5f5";
            },

          },
        },
        {
          margin: [10, 5, 0, 0],
          table: {
            headerRows: 0,
            widths: [75, 75],
            body: [
              [
                {
                  text: "TRUCK NO :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.laDetailsInDispatchCounter?.truck_number?.toUpperCase(),
                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "L.R. NO. :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.laDetailsInDispatchCounter?.lr_number,
                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "L.R. DATE :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: moment(
                    this?.state?.laDetailsInDispatchCounter?.lr_date
                  ).format("DD-MMM-YYYY"),

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "TRANSPORTER NAME :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.laDetailsInDispatchCounter
                    ?.transporter_name,

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],

              [
                {
                  text: "MODE OF TRANSPORT :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter
                      ?.mode_of_transport || "",

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "FRIEGHT",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.laDetailsInDispatchCounter?.fright,

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  border: [false, false, false, false],
                },
                {},
              ],
              [
                {
                  text: "FROM :",
                  style: "invoiceDetailLable",
                  fillColor: "#f5f5f5",
                  border: [false, false, false, false],
                },
                {
                  text: this?.state?.laDetailsInDispatchCounter?.plant
                    ?.plant_city,

                  style: "invoiceDetailLable",
                  color: "#333333",
                  fillColor: "#f5f5f5",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "TO :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text:
                    this.state.laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_customer_addresses?.city ||
                    this.state.laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_gst_information_addresses?.district_name,

                  style: "invoiceDetailLable",
                  color: "#333333",
                  fillColor: "#f5f5f5",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "PLACE OF SUPPLY :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.customer_addresses?.city_name || this?.state?.laDetailsInDispatchCounter?.buyer
                        ?.customer_addresses?.city ||
                    (this?.state?.laDetailsInDispatchCounter?.buyer
                      ?.gst_addresses?.city_name || this?.state?.laDetailsInDispatchCounter?.buyer
                        ?.gst_addresses?.district_name),
                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  border: [false, false, false, false],
                },
                {},
              ],
              [
                {
                  text: "AGENT NAME : ",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.customer
                      ?.customer_type === "BROKER" || this?.state?.laDetailsInDispatchCounter?.customer
                        ?.customer_type === "DISTRIBUTOR"
                      ? this?.state?.laDetailsInDispatchCounter?.customer
                        ?.first_name + " " +
                      this?.state?.laDetailsInDispatchCounter?.customer
                        ?.last_name
                      : "",

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "PARTY REF NO :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.order
                      ?.party_ref_number || "",

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "PARTY REF DATE :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text:
                    moment(
                      this?.state?.laDetailsInDispatchCounter?.order
                        ?.party_ref_date
                    ).format("DD-MMM-YYYY") || "",
                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "L.C. NO. :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.laDetailsInDispatchCounter?.lc_number,
                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "L.C. DATE :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.laDetailsInDispatchCounter?.lc_date ? moment(
                    this?.state?.laDetailsInDispatchCounter?.lc_date
                  ).format("DD-MMM-YYYY") : "",
                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "",
                  colSpan: 2,
                  border: [false, false, false, false],
                },
                {},
              ],
              [
                {
                  text: "E-WAY BILL NO. : ",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.eWayBillResponse?.EwbNo || this?.state?.eWayBillResponse?.EWayBill || " ",

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],
              [
                {
                  text: "E-WAY BILL DATE :",
                  style: "invoiceDetailLable",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
                {
                  text: moment(this?.state?.eWayBillResponse?.EwbDt || this?.state?.eWayBillResponse?.Date, [
                    "DD/MM/YYYY hh:mm:ss A",
                    "YYYY-MM-DD hh:mm:ss A",
                  ]).format(
                    "DD-MMM-YYYY"
                  ) === "Invalid date" ? " " : moment(this?.state?.eWayBillResponse?.EwbDt || this?.state?.eWayBillResponse?.Date, [
                    "DD/MM/YYYY hh:mm:ss A",
                    "YYYY-MM-DD hh:mm:ss A",
                  ]).format(
                    "DD-MMM-YYYY"
                  ),

                  style: "invoiceDetailLable",
                  color: "#333333",
                  border: [false, false, false, false],
                  fillColor: "#f5f5f5",
                },
              ],

              [
                {
                  text: "",
                  colSpan: 2,
                  border: [false, false, false, false],
                },
                {},
              ],
            ],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#f5f5f5";
            },
            vLineColor: function (i, node) {
              return "#f5f5f5";
            },
          },
        },
        {
          // style: 'tableExample',
          margin: [-45, 5, 0, 0],
          table: {
            headerRows: 1,
            widths: [115],
            body:
              eInvoiceDataTable.length !== 0 &&
                (this.props?.documentFor === "E-Invoice" ||
                  this.props?.documentFor === "Invoice" ||
                  this.props?.documentFor === "Delivery Challan")
                ? eInvoiceDataTable
                : [{}],
          },
          layout: {
            hLineColor: function (i, node) {
              return "#f5f5f5";
            },
            vLineColor: function (i, node) {
              return "#f5f5f5";
            },
          },
        },
      ],
    })
    pageContent.push({
      margin: [5, 5, 0, 0],
      table: {
        headerRows: 1,
        widths: [25, 130, 45, 55, 35, 35, 45, 55, 45],
        body: tableData,
      },
      // layout: "noBorders"
    })

    pageContent.push(
      {
        margin: [5, 0, 0, 0],
        table: {
          headerRows: 0,
          widths: [50, 223, 35, 143, 55],
          body: [
            [
              {
                text: "Amount in words :",
                style: "fontSixLeft",
                fillColor: "#f5f5f5",
                border: [true, true, false, true],
              },
              {
                text: `${this.numberConvertor(
                  this?.state?.laDetailsInDispatchCounter?.total_values
                    ?.taxable_amount,
                  "",
                  true
                )}`,
                style: "fontSixLeft",
                border: [false, true, false, true],
                margin: [-8, 0, 0, 0],
                fillColor: "#f5f5f5",
              },
              {
                text:
                  Number(
                    this.state?.laDetailsInDispatchCounter?.total_values
                      ?.alloted_weight
                  ).toFixed(3) || 0,
                border: [false, true, false, true],
                margin: [12, 0, 0, 0],
                // style: "fontEightLeft",
                style: "itemNumberRightAligned",
                fillColor: "#f5f5f5",
              },

              {
                border: [false, true, false, true],
                text: "Total Value of Supply :",
                style: "fontSevenRight",
                // style: "fontSevenLeft",
                fillColor: "#f5f5f5",
              },
              {
                border: [false, true, true, true],
                //text: "40000",
                text: this?.state?.laDetailsInDispatchCounter?.total_values
                  ?.taxable_amount?.toFixed(2),
                // text: this?.state?.total_tax_on_amount || "",
                style: "fontSevenRight",
                // style: "fontSevenLeft",
                alignment: "right",
                fillColor: "#f5f5f5",
              },
            ],
          ],
        },
      }
    )

    pageContent.push({
      margin: [5, 0, 5, 0],
      columns: [
        {
          text: `Total Tax Value (in words) : ${("",
            this?.state?.laDetailsInDispatchCounter?.total_values
              ?.sgst_amount >
              this?.state?.laDetailsInDispatchCounter?.total_values
                ?.igst_amount
              ? this.numberConvertor(Number(sgstCgstSum), "", true)
              : this.numberConvertor(
                this?.state?.laDetailsInDispatchCounter?.total_values
                  ?.igst_amount,
                "",
                true
              ))
            }.`,
          // style: "fontEightLeft",
          style: "fontSixLeft",
          margin: [0, 5, 10, 5],
        },
        {
          margin: [0, 0, 5, 0],
          table: {
            headerRows: 0,
            widths: [65, "*", 60],
            body: taxationTableData,
          },
        },
      ],
    })

    pageContent.push({
      margin: [5, 0, 0, 0],
      columns: [
        {
          text: `REMARK : ${this?.state?.laDetailsInDispatchCounter?.remark || ""
            } ${copyForText !== "DUPLICATE FOR TRANSPORTER" ?
              `\n` + 'PAYMENT TERMS : ' + `${this?.state?.laDetailsInDispatchCounter?.payment_method
                ?.payment_method_name || ""
              // this?.state?.laDetailsInDispatchCounter[0]?.PAYMENT_MODE || ""
              }`
              : ""}`,
          style: "fontSixLeft",
          // style: "fontSevenLeft",
          margin: [0, 0, 0, 5],
        },
      ],
    })

    pageContent.push({
      margin: [5, 0, 0, 0],
      // pageBreak: i === 2 ? "" : "after",
      table: {
        headerRows: 0,
        widths: [70, 335, 55, 55],
        body: [
          [
            {
              text: "Total Amount in words :",
              border: [true, true, false, true],
              bold: true,
              style: "fontSixLeft",
              fillColor: "#f5f5f5",
            },
            {
              border: [false, true, false, true],
              text: `${this.numberConvertor(
                this?.state?.laDetailsInDispatchCounter?.total_values
                  ?.total_item_value,
                "",
                true
              )}`,
              style: "fontSixLeft",
              margin: [-13, 0, 0, 0],
              fillColor: "#f5f5f5",
            },
            {
              border: [false, true, false, true],
              text: "Total Amount ₹",
              style: "fontSevenLeft",
              // style: "fontSevenLeft",
              fillColor: "#f5f5f5",
            },
            {
              border: [false, true, true, true],
              text:
                this?.state?.laDetailsInDispatchCounter?.total_values
                  ?.total_item_value?.toFixed(2) || "",

              style: "fontSevenRight",
              fillColor: "#f5f5f5",
            },
          ],
        ],
      },
    })

    // console.log("here")
    //  let content = 
    // }

    // const finalcontent = []
    // for (let i = 0; i < 3; i++) {
    //   await generateContent(i)
    //   // let contents = pageContent.slice()
    //   // contents.forEach(content => {
    //   //   finalcontent.push(content)
    //   // })
    // }

    // console.log(finalcontent, pageContent)
    var dd = {
      info: {
        title: `${this.props?.laDetailsInDispatchCounter?.erp_invoice_number
          }_${new Date().toISOString()}`,
        author: "RIPL",
        subject: `${this?.state?.eInvoiceResponse?.DocType === "INV" ? "TAX INVOICE"
          : this?.state?.eInvoiceResponse?.DocType === "CRN" ? "CREDIT NOTE"
            : this?.state?.eInvoiceResponse?.DocType === "DBN" && "DEBIT NOTE"}`,
        keywords: `${this?.state?.eInvoiceResponse?.DocType === "INV" ? "TAX INVOICE"
          : this?.state?.eInvoiceResponse?.DocType === "CRN" ? "CREDIT NOTE"
            : this?.state?.eInvoiceResponse?.DocType === "DBN" && "DEBIT NOTE"}`,
      },
      pageMargins: this.props.isLogoRequired ? [15, 160, 15, 225] : [15, 170, 15, 30],
      header: {
        stack: headerContainer,
        margin: [15, 25, 15, 0]
      },
      footer: {
        stack: footerContainer,
        margin: [15, 0, 0, 0]

      },
      // {
      //   columns: [
      //     {
      //       text: "This is computer generated invoice",
      //       alignment: "center"
      //     }
      //   ]
      // },
      background: this.props.isLogoRequired ?
        function (currentPage, pageSize) {
          return {
            // image: AegleBase64,
            // width: 200,
            text: "<",
            absolutePosition: { x: 15, y: pageSize.height / 2 - 4 },
            // margin: [20, 0, 0, 0]
          }
        } :
        function (currentPage, pageSize) {
          return {
            // image: AegleBase64,
            // width: 200,
            text: "<",
            absolutePosition:
              { x: 15, y: pageSize.height / 2 - 5 },
            // margin: [20, 0, 0, 0]
          }
        },

      content: pageContent,
      styles: {
        tableExample: {
          margin: [0, 5, 0, 5],
        },
        tableAlignRight: {
          alignment: "right",
          padding: [0, 0, 0, 0]
        },
        topRightTextStyle: {
          // width: 150,
          fontSize: 6,
          // height: 50,
          margin: [0, 0, 10, 0],
          alignment: "right",
        },
        checkBoxStyle: {
          width: 5,
          height: 5,
          margin: [0, 1, 1, 0],
          alignment: "right",
        },
        headerLayout: {
          hLineWidth: 0.5,
          vLineWidth: function (i, node) {
            return 0.5;
          },
        },
        fontSeventeenCenter: {
          alignment: "center",
          fontSize: 17,
          margin: [0, 3, 0, 3],
        },

        fontSixLeft: {
          alignment: "left",
          fontSize: 6,
          margin: [0, 0, 0, 0],
        },
        disclaimerStyle: {
          alignment: "left",
          fontSize: 7,
          bold: true,
          // margin: [0, 5, 0, 0],
        },
        fontSixRight: {
          alignment: "right",
          fontSize: 6,
          margin: [0, 0, 0, 0],
        },
        OEstyle: {
          fontSize: 10
        },
        OEValuestyle: {
          fontSize: 8,
          margin: [0, 1, 0, 0],
        },
        fontSixCenter: {
          alignment: "center",
          fontSize: 6,
          margin: [0, 3, 0, 3],
        },
        taxDetailCenter: {
          alignment: "center",
          fontSize: 7,
        },
        fontSevenLeft: {
          alignment: "left",
          fontSize: 7,
          margin: [0, 0, 0, 0],
        },
        forSignatureCenter: {
          alignment: "center",
          fontSize: 11
        },
        SignatureCenter: {
          alignment: "center",
          fontSize: 9
        },
        fontSevenRight: {
          alignment: "right",
          fontSize: 7,
          margin: [0, 0, 0, 0],
        },
        fontSevenCenter: {
          alignment: "center",
          fontSize: 7,
          margin: [0, 3, 0, 3],
        },
        fontEightRight: {
          alignment: "right",
          fontSize: 8,
          margin: [0, 0, 0, 0],
          // bold: true,
        },
        fontEightLeft: {
          alignment: "left",
          fontSize: 8,
          // bold: true,
          margin: [0, 0, 0, 0],
        },
        fontEight: {
          alignment: "left",
          fontSize: 8,
          margin: [0, 0, 0, 0],
        },
        buyerName: {
          bold: true,
          color: "#333333",

          fontSize: 7,
          alignment: "left",
          margin: [0, 0, 0, 3],
        },
        buyerAddressDetails: {
          fontSize: 6,
          alignment: "left",
        },
        divisionHeader: {
          fontSize: 8,
          alignment: "left",
        },
        taxInvoiceHeader: {
          fontSize: 9,
          alignment: "center",
        },
        invoiceDetailLable: {
          color: "#333333",

          fontSize: 6,
          alignment: "left",
        },
        itemsHeader: {
          // padding: [0, 2, 0, 2],

          fontSize: 7,
          bold: true,
          fillColor: "#f5f5f5",
        },
        itemSubTitle: {
          // margin: [0, 2, 0, 2],

          fontSize: 7,
          alignment: "left",
        },
        itemNumber: {
          // margin: [0, 2, 0, 2],

          fontSize: 7,
          alignment: "center",
        },
        itemTotalQuantity: {
          // margin: [14, 2, 0, 2],

          fontSize: 6.5,
          alignment: "left",
        },
        itemNumberRightAligned: {
          // margin: [0, 2, 0, 2],

          fontSize: 6.5,
          alignment: "right",
        },
        itemRowEvenNumber: {
          margin: [0, 2, 0, 2],
          fontSize: 8,
          alignment: "center",
        },
      },
    };

    // setTimeout(() => {
    if (pdfOperation === "VIEW") {
      console.log("view here")
      pdfMake.createPdf(dd).open();
    } else if (pdfOperation === "DOWNLOAD") {
      // console.log("downloaded")
      // console.log(this.props.invoiceForPlatform)
      if (this.props.invoiceForPlatform) {
        this.props.handlePostInvoiceDownload(this.props)
      }
      // this.props.invoi
      if (this.props?.device === 'cordova') {
        if (window.cordova) {
          let actualFilename = `Invoice_${this.props.docNumber}.pdf`
          let downloadLoader = ''
          if (this?.props?.handleDownloadLoader) { downloadLoader = this?.props?.handleDownloadLoader }
          printPDFInMobile(pdfMake, dd, actualFilename, '', downloadLoader)
          // pdfMake.createPdf(dd).getBuffer(function (buffer) {
          //   const data = buffer.toString('base64')
          //   let contentType = "application/pdf";
          //   // if cordova.file is not available use instead :
          //   // var folderpath = "file:///storage/emulated/0/";
          //   // let folderpath = window?.cordova.file.externalRootDirectory + 'Download/';
          //   // if (folderpath == null) {
          //   //   folderpath = window?.cordova.file.dataDirectory + 'Download/'
          //   // }
          //   let folderpath = ""
          //   if (window.cordova.platformId !== 'android') {
          //     folderpath = window?.cordova.file.documentsDirectory
          //   }
          //   else {
          //     folderpath = window?.cordova.file.externalRootDirectory + 'Download/';
          //     if (folderpath == null) {
          //       folderpath = window?.cordova.file.dataDirectory + 'Download/'
          //       if (folderpath == null) {
          //         folderpath = window?.cordova.file.dataDirectory || window?.cordova.file.documentsDirectory + 'Download/' || window?.cordova.file.documentsDirectory
          //         if (folderpath == null) {
          //           window?.cordova?.plugins?.fileChooser.open(
          //             {
          //               mime: 'application/pdf',
          //               title: 'Save PDF to...',
          //               // Optional: Specify initial directory if needed
          //               // initialPath: '/sdcard/',
          //             },
          //             function (uri) {
          //               folderpath = uri
          //               // The selected location will be available in the 'uri' variable.
          //               // You can use it to save the PDF to the chosen location.
          //               // For example, use the File API to write the PDF file to the selected location.
          //             },
          //             function (error) {
          //               console.log('File picker error: ' + error);
          //             }
          //           );
          //         }

          //       }
          //     }
          //   }
          //   console.log(actualFilename)
          //   if (folderpath === null) {
          //     alert(`No Access of Files ${folderpath}`)
          //   }
          //   else {
          //     savebase64AsPDF(folderpath, actualFilename, data, contentType, downloadLoader);
          //   }
          // })
        }
        else {
          this?.props?.handleDownloadLoader && this.props?.handleDownloadLoader()
          pdfMake.createPdf(dd).download(`${new Date().toISOString()}.pdf`);
        }

      }
      else {
        pdfMake.createPdf(dd).download(`${new Date().toISOString()}.pdf`);
      }
    } else if (pdfOperation === "EMAIL") {
      console.log("here111 = ", this.props.isLogoRequired)
      if (this.props.isLogoRequired !== undefined) {
        // pdfMake.createPdf(dd).open()
        const pdfDocGenerator = pdfMake.createPdf(dd);
        pdfDocGenerator.getBase64((document) => {
          console.log("here222", document)
          this.props.pdfpayload(dd, document, "invoice")
        });
      }
    } else {
      console.log("invalid pdf operation");
    }
    // }, 2000);

  };


  render() {
    const { classes } = this.props;

    // console.log(this.props)
    return (this.props.invoiceForPlatform ?
      <div>

      </div> :
      <Button
        className={classes.primaryBackground}
        sx={{ textTransform: "none" }}
        style={{ width: this.props.pdfOperation === "VIEW" ? "30%" : "55%", height: "24px" }}
        // startIcon={<PictureAsPdfOutlinedIcon />}
        onClick={() => {
          if (this.props.isLogoRequired) {
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "ORIGINAL FOR RECIPIENT")
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "DUPLICATE FOR TRANSPORTER")
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "TRIPLICATE FOR SUPPLIER")
          } else if (this.props.pdfOperation === "EMAIL") {
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "ORIGINAL FOR RECIPIENT")
          } else {
            this.handleViewOrDownloadPdf(this.props.pdfOperation)
          }
        }}
      >
        <span style={{ fontSize: "11.2px" }}>
          {this.props.pdfOperation}
        </span>
      </Button>
    );
  }
}

export default withStyles(dashboardStyles)(RIPLInvoicePdfDownload);
