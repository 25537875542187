import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { webPrimaryColor } from "../../styles/colors";
import DrawerContent from "../../components/b2b/web/layoutComponent/DrawerContent";
import AppBar from "../../components/b2b/web/layoutComponent/AppBar";
import B2b_URL from "../../constants/b2b/B2bRoutes";
import axios from "axios";
import withUser from "../../hoc/withUser";
import moment from "moment";
import { StaticAppVersion, compareValues } from "../../methods.js";
const styles = {
  childrenBodyStyle: {
    width: "100%",
    // height: "100vh",
    // height: 'calc(100% - 90)',
    // position: 'relative',
    // top: 90,
    display: "flex",
    // overflowY: "auto",
    paddingTop: "90px",
    paddingLeft: "3%",
    paddingRight: "0px",
  },
  drawerStyle: {
    width: "17%",
    backgroundColor: webPrimaryColor,
    // border: "1px solid #1B388B",
    height: "100%",
  },
};
class WebLayout extends Component {
  state = {
    top: false,
    left: true,
    bottom: false,
    right: false,
    notificationLoader: true,
    fetchNextNotification: "",
    userNotifications: [],
    unreadNotifications: 0,
    layoutHistory: [],
    logoutPopoverText: ""
  };
  componentDidMount() {
    this.fetchNotificationList()
    // try {
    //   setInterval(async () => {
    //     this.fetchNotificationList()
    //   }, 30000);
    // } catch (e) {
    //   console.log(e);
    // }
  }

  // componentDidMount() {
  //   this.fetchNotificationList()
  // }

  fetchNotificationList = () => {
    var dates = [];
    axios
      .get(`${B2b_URL.NOTIFICATIONS}/?V=${StaticAppVersion()}`, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then((response) => {
        // console.log(response)
        let userNotifications = response.data;
        if (userNotifications.length > 0) {
          let unreadNotifications = 0
          userNotifications.map(not => {
            if (!not.isread) {
              unreadNotifications++
            }
          })
          // console.log(unreadNotifications)
          userNotifications.map((item) => {
            if (dates.includes(moment(item.created_at).format("DD MMMM, dddd"))) {
              return;
            } else {
              dates.push(moment(item.created_at).format("DD MMMM, dddd"));
            }
          });
          this.setState({
            notificationLoader: false,
            userNotifications: userNotifications.sort(
              compareValues("created_at", "desc")
            ),
            dates,
            fetchNextNotification: response.data.next,
            unreadNotifications,
          });
        } else {
          this.setState({
            notificationLoader: false,
            userNotifications: response.data
          });
        }
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          this.setState({
            logoutPopoverText: error.response?.data?.detail
          }, () => console.log(error.response?.data?.detail, this.state?.logoutPopoverText))
        }
      })
  }
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
  handleDrawer = () => {
    this.setState({ left: !this.state.left });
  };



  handleNotificationClick = (notification) => {
    console.log(notification)
    const payload = [{
      id: notification.id,
      is_read: true
    }]
    // let link = notification.notification_link.split("/")
    // // console.log(link)
    // let linkk = `/${link[3]}/${link[4]}/${link[5]}`
    // console.log(linkk)
    // this.props.history.push(linkk)
    axios.put(
      `${B2b_URL.READNOTIFICATION}/?V=${StaticAppVersion()}`,
      payload,
      // this.getOptions(this.props.user)

    ).then(response => {
      // console.log(response)
      let link = notification.notification_link.split("/")
      // console.log(link)
      let linkk = `/${link[3]}/${link[4]}/${link[5]}`
      // console.log(linkk)
      this.props.history.push(linkk)
      this.props.fetchNotificationList()
    })
      .catch(err => console.log(err))

  }
  handleFetchMoreNotification = (URL) => {
    this.setState({ nextNotificationLoader: true });
    var dates = this.state.dates;

    axios
      .get(`${URL}/?V=${StaticAppVersion()}`, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then((response) => {
        var nextNotification,
          nextNotification = response.data.results;
        nextNotification.map((item) => {
          if (dates.includes(moment(item.created_at).format("DD MMMM, dddd"))) {
            return;
          } else {
            dates.push(moment(item.created_at).format("DD MMMM, dddd"));
          }
        });
        this.state.userNotifications.push(...nextNotification);
        this.setState({
          notificationLoader: false,
          userNotifications: this.state.userNotifications.sort(
            compareValues("created_at", "desc")
          ),
          dates,
          fetchNextNotification: response.data.next,
          nextNotificationLoader: false,
        });
      });
  };
  render() {
    const {
      classes,
      appBar,
      profileSelect,
      dailyRates,
      orderSelect,
      dispatchSelect,
    } = this.props;
    // console.log(this.state.userNotifications)
    // console.log(this.props)
    const top = appBar && (
      <AppBar
        {...this.state}
        handleDrawer={this.handleDrawer}
        userNotifications={
          this.state.notificationLoader === false
            ? this.state.userNotifications
            : null
        }
        dates={
          this.state.notificationLoader === false ? this.state.dates : null
        }
        handleNotificationClick={this.handleNotificationClick}
        fetchNotificationList={this.fetchNotificationList}
      />
    );

    return (
      <div>
        {top}
        <div style={{ height: "100vh", display: "flex", width: "100%" }}>
          <Drawer
            variant="permanent"
            className={classes.drawerStyle}
            PaperProps={{
              className: classes.drawerStyle,
            }}
            open={this.state.left}
            onClose={this.toggleDrawer("left", false)}
          >
            <div
              // tabIndex={0}
              role="button"
              onClick={this.toggleDrawer("left", false)}
              onKeyDown={this.toggleDrawer("left", false)}
            >
              <DrawerContent
                profileSelect={profileSelect}
                dailyRates={dailyRates}
                orderSelect={orderSelect}
                dispatchSelect={dispatchSelect}
                logoutPopoverText={this.state?.logoutPopoverText}
              />
            </div>
          </Drawer>
          <div className={classes.childrenBodyStyle}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default withUser(withStyles(styles)(WebLayout));
