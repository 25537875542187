import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import DispatchCounterHome from "../DispatchCounterHome";

class DispatchCounter extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <DispatchCounterHome {...this.props} />
      </Layout>
    );
  }
}
export default withRouter(DispatchCounter);
