import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Checkbox,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core';
import Popover from '@mui/material/Popover';
import AccessTime from '@material-ui/icons/AccessTime';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import IpRestrictedPopoverComponent from "../web/IpRestrictedPopoverComponent";
// import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {
  grey,
  whiteSmoke,
  secondaryBlueText,
  drawerTextColor,
  secondaryGrey,
} from '../../../../constants/internal/colors';

import { checkApiError, pxToEm } from '../../../../methods';
import SnackbarComponent from '../../common/SnackbarComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tableHeaderRow: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: 'bold',
  },
  tableBody: {
    fontSize: pxToEm(14),
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontSize: pxToEm(15),
    flexBasis: '70%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: pxToEm(15),
    color: theme.palette.text.secondary,
  },
  titleWithBlueFont: {
    fontSize: pxToEm(15),
    color: secondaryBlueText,
  },
  expandPanelSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
  },
  generalSpacingRight: {
    paddingRight: 10,
  },
  restrictionIcons: {
    paddingRight: 10,
  },
  ipRestriction: {
    width: '90px',
    height: '21px',
    borderRadius: '10px',
    opacity: '1',
    fontSize: pxToEm(11),
    display: 'flex',
    alignItems: 'center',
    placeContent: 'space-evenly',
    backgroundColor: secondaryBlueText,
    color: drawerTextColor,
  },
  ipRestrictedIcon: {
    width: '14px',
    height: '14px',
  },
  addNewButton: {
    marginTop: 10,
    width: '181px',
    height: '43px',
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: '4px',
    opacity: '1',
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: 'bold',
    marginBottom: 15,
  },
  icon: {
    height: 18,
    width: 18,
    margin: '0px 5px 0px 5px',
    color: grey,
  },
  iconContainer: {
    height: 30,
    width: 100,
    background: 'yellow',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: whiteSmoke,
    overflow: 'hidden',
    color: grey,
  },
  tableRowAlignment: {
    placeItems: 'baseline',
  },
  table: {
    minWidth: 650,
  },
  gray: {
    color: "#666"
  },
  blue: {
    color: "#3B69B0"
  },
}));

const CutomColorCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    '&$checked': {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function RoleAccessComponent({
  role,
  modules,
  addModuleToARole,
  getARoleAndPermissions,
  deleteAModuleFromRole,
  updateWidgetPermissionForRole,
  history,
}) {
  const classes = useStyles();
  const [addNew, toggleAddNew] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [readWriteState, setReadWriteState] = React.useState({
    checkedRead: false,
    checkedWrite: false,
    checkedUpdate: false,
    checkedApprove: false,
    checkedSpecial: false
  });
  const [responseData, setResponseData] = React.useState([]);
  const [roleAssignData, setRoleAssignData] = React.useState(
    role && role.accesses ? role.accesses : []
  );
  const [roleAssignDataLoader, setRoleAssignDataLoader] = React.useState(false);
  const [permissionsDetails, setPermissionsDetails] = React.useState(false);
  const [assignedModules, setAssignedModules] = React.useState(
    role && role.accesses ? role.accesses : []
  );
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });

  React.useEffect(() => {
    if (role && role?.accesses) {
      setAssignedModules(role.accesses);
      setRoleAssignData(role?.accesses);
      setRoleAssignDataLoader(true);
    }
  }, [role?.accesses]);

  React.useEffect(() => {
    if (responseData) {
      // console.log('responseData if')
      for (let i = 0; i < roleAssignData.length; i++) {
        if (responseData.module === roleAssignData[i].module_id) {
          // console.log('responseData if module')
          for (let j = 0; j < roleAssignData[i].widgets.length; j++) {
            if (responseData.id === roleAssignData[i].widgets[j].permission_id) {
              // console.log('responseData if widget')
              let newData = [...roleAssignData];
              newData[i].widgets[j]["has_create_permission"] = responseData.has_create_permission;
              newData[i].widgets[j]["has_read_permission"] = responseData.has_read_permission;
              newData[i].widgets[j]["has_update_permission"] = responseData.has_update_permission;
              newData[i].widgets[j]["has_approve_permission"] = responseData.has_approve_permission;
              newData[i].widgets[j]["has_special_permission"] = responseData.has_special_permission;
              // console.log("h read", responseData.has_create_permission)
              setRoleAssignData(newData)
              setRoleAssignDataLoader(true)
            }
          }
        }
      }
    }
  }, [responseData]);

  React.useEffect(() => {
    if (permissionsDetails) {
      // console.log(permissionsDetails)
      updateWidgetPermissionForRole({
        has_create_permission: readWriteState.checkedWrite,
        has_read_permission: (readWriteState.checkedRead ||
          readWriteState.checkedWrite ||
          readWriteState.checkedUpdate ||
          readWriteState.checkedApprove ||
          readWriteState.checkedSpecial) ?
          true
          :
          false
        ,
        has_update_permission: readWriteState.checkedUpdate,
        has_approve_permission: readWriteState.checkedApprove,
        has_special_permission: readWriteState.checkedSpecial,
        //has_delete_permission: readWriteState.checkedWrite,
        permissionId: permissionsDetails.permissionId,
        role: permissionsDetails.roleId,
        module: permissionsDetails.moduleId,
        widget: permissionsDetails.widgetId,
      })
        .then((response) => {
          if (response.status === 200) {
            setResponseData(response.data)
            handleOpenSnackbar(
              `Widget Permission Changed Successfully`,
              "success"
            );
            setTimeout(() => {
              // console.log('2')
              setPermissionsDetails(false)
              handleCloseSnackbar()
            }, 3000);
          }
          else {
            handleOpenSnackbar(
              `Error occured while changing permission of widget please try again`,
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar()
            }, 4000);
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
      // setPermissionsDetails(false)
      // )
      // .catch(console.error);
    }
  }, [permissionsDetails]);

  const handleCheckboxChange = (
    roleId,
    moduleId,
    widgetId,
    permissionId,
    event
  ) => {
    console.log(moduleId + " : " + permissionId)
    for (let i = 0; i < roleAssignData.length; i++) {
      if (moduleId === roleAssignData[i].module_id) {
        console.log("MODULE" + roleAssignData[i].module_id)
        for (let j = 0; j < roleAssignData[i].widgets.length; j++) {
          if (permissionId === roleAssignData[i].widgets[j].permission_id) {
            setReadWriteState({
              checkedRead: event.target.name === "checkedRead"
                ? event.target.checked : roleAssignData[i].widgets[j]["has_read_permission"],
              checkedWrite: event.target.name === "checkedWrite"
                ? event.target.checked : roleAssignData[i].widgets[j]["has_create_permission"],
              checkedUpdate: event.target.name === "checkedUpdate"
                ? event.target.checked : roleAssignData[i].widgets[j]["has_update_permission"],
              checkedApprove: event.target.name === "checkedApprove"
                ? event.target.checked : roleAssignData[i].widgets[j]["has_approve_permission"],
              checkedSpecial: event.target.name === "checkedSpecial"
                ? event.target.checked : roleAssignData[i].widgets[j]["has_special_permission"],
            })
          }
        }
      }
    }
    setPermissionsDetails({ roleId, moduleId, widgetId, permissionId });
    // setReadWriteState({ ...readWriteState, [event.target.name]: event.target.checked });
  };

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleAddNewModule = (e) => {
    toggleAddNew(true);
  };

  const handleCancel = (e) => {
    toggleAddNew(false);
  };

  const handleSearch = (e, newInputValue, data) => {
    const module = data.find((e) => e.name === newInputValue);
    if (module) {
      const newAssignedModlues = assignedModules;
      newAssignedModlues.push({
        ...module,
        module_name: module.name,
        status: 'new',
      });
      setAssignedModules(newAssignedModlues);
      toggleAddNew(false);
      addModuleToARole({
        role: role.id,
        module: module.id,
      })
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Module Added Successfully`,
              "success"
            );
            setTimeout(() => {
              history.push(`/internalUsersAndPermissions/rolesAndPermissions`);
              history.push(`/internalUsersAndPermissions/rolesAndPermissions/view/${role.id}`);
              // getARoleAndPermissions(role.id);
              handleCloseSnackbar()
            }, 3000);
          }
          else {
            handleOpenSnackbar(
              `Error occured while adding module please try again`,
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar()
            }, 4000);
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
      //   getARoleAndPermissions(role.id);
      // })
      // .catch((e) => {
      //   console.error(e);
      //   let removedList = assignedModules;
      //   removedList = removedList.filter((e) => !Boolean(e.status));
      //   setAssignedModules(removedList);
      // });
    }
  };

  function handleRemove(id) {
    let removedList = assignedModules;
    removedList = removedList.map((e) => ({
      ...e,
      status: e.id === id ? 'removed' : undefined,
    }));
    setAssignedModules(removedList);
    deleteAModuleFromRole(id)
      .then((response) => {
        if (response.status === 204) {
          handleOpenSnackbar(
            `Module Removed Successfully`,
            "success"
          );
          setTimeout(() => {
            setExpanded(false);
            handleCancel('flag');
            getARoleAndPermissions(role.id);
            handleCloseSnackbar()
          }, 3000);
        }
        else {
          handleOpenSnackbar(
            `Error occured while removing module please try again`,
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar()
          }, 4000);
        }
      })
      .catch((error) => {
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000);
      });
    //   setExpanded(false);
    //   handleCancel('flag');
    //   getARoleAndPermissions(role.id);
    // })
    // .catch(console.error);
  }

  const handleChange = (listItem) => (event, isExpanded) => {
    setExpanded(isExpanded ? listItem : false);
  };

  // const handleInfoClick = (infoName) => {
  //   setAnchorEl(infoName);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const InfoButtonDescription = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleInfoClick = (event) => {
      console.log(event.currentTarget)
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <>
        <IconButton
          sx={{ display: 'inline', }}
          aria-describedby={id}
          className={anchorEl !== null ? classes.blue : classes.gray}
          variant="text"
          onClick={handleInfoClick}
          size="small"
        >
          <InfoOutlinedIcon style={{ transform: 'scale(0.8)' }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        // className={classes.popoverBox}
        >
          <Typography style={{ padding: 2, margin: 5, fontSize: pxToEm(14) }}>
            {props.description}
          </Typography>
        </Popover>
      </>
    )
  }

  return (
    <>
      <div className={classes.root}>

        {/* {role && role.accesses.length > 0
          ? role.accesses.map((data, index) => ( */}
        {roleAssignDataLoader ?
          roleAssignData && roleAssignData.length ?
            roleAssignData.map((data, index) => (
              <ExpansionPanel
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    data.status === 'new' || data.status === 'removed' ? (
                      <AccessTime />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className={classes.expandPanelSummary}>
                    <Typography className={classes.heading}>
                      <span className={classes.generalSpacingRight}>
                        {data.module_name}
                      </span>
                    </Typography>
                  </div>
                  {expanded === index && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemove(data.id);
                      }}
                      className={classes.iconContainer}
                    >
                      {data.status === 'new' || data.status === 'removed' ? (
                        <AccessTime className={classes.icon} />
                      ) : (
                        <>
                          <CancelIcon className={classes.icon} />
                          remove
                        </>
                      )}
                    </div>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.tableHeaderRow}>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            WIDGET
                            <InfoButtonDescription
                              buttonName="widget"
                              description={data.widget_description}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            READ
                            <InfoButtonDescription
                              buttonName="read"
                              description={data.read_description}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            CREATE
                            <InfoButtonDescription
                              buttonName="create"
                              description={data.write_description}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            EDIT
                            <InfoButtonDescription
                              buttonName="edit"
                              description={data.edit_description}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            APPROVE
                            <InfoButtonDescription
                              buttonName="approve"
                              description={data.approve_description}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            SPECIAL
                            <InfoButtonDescription
                              buttonName="special"
                              description={data.special_description}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            NOTIFICATION
                            <InfoButtonDescription
                              buttonName="notification"
                              description={data.notification_description}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {data.widgets !== undefined && data?.widgets.map((row, i) => (
                          <TableRow key={i} >

                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={classes.tableBody}
                            >
                              {row.widget_name}
                            </TableCell>

                            <TableCell
                              align="center"
                              className={classes.tableBody}
                            >
                              {/* {console.log(row.has_read_permission)} */}
                              <CutomColorCheckbox
                                checked={
                                  (
                                    row.has_read_permission ||
                                    row.has_create_permission ||
                                    row.has_update_permission ||
                                    row.has_approve_permission ||
                                    row.has_special_permission
                                  )
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(
                                    role.id,
                                    data.module_id,
                                    row.widget_id,
                                    row.permission_id,
                                    e
                                  );
                                }}
                                name="checkedRead"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableBody}
                            >
                              <CutomColorCheckbox
                                checked={row.has_create_permission}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(
                                    role.id,
                                    data.module_id,
                                    row.widget_id,
                                    row.permission_id,
                                    e
                                  );
                                }}
                                name="checkedWrite"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableBody}
                            >
                              <CutomColorCheckbox
                                checked={row.has_update_permission}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(
                                    role.id,
                                    data.module_id,
                                    row.widget_id,
                                    row.permission_id,
                                    e
                                  );
                                }}
                                name="checkedUpdate"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableBody}
                            >
                              <CutomColorCheckbox
                                checked={row.has_approve_permission}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(
                                    role.id,
                                    data.module_id,
                                    row.widget_id,
                                    row.permission_id,
                                    e
                                  );
                                }}
                                name="checkedApprove"
                              />
                            </TableCell>

                            <TableCell
                              align="center"
                              className={classes.tableBody}
                            >
                              <CutomColorCheckbox
                                checked={row.has_special_permission}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(
                                    role.id,
                                    data.module_id,
                                    row.widget_id,
                                    row.permission_id,
                                    e
                                  );
                                }}
                                name="checkedSpecial"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableBody}
                            >
                              <CutomColorCheckbox
                                // checked={row.has_notification_permission}
                                // onChange={(e) => {
                                // e.preventDefault();
                                // handleCheckboxChange(
                                //   role.id,
                                //   data.module_id,
                                //   row.widget_id,
                                //   row.permission_id,
                                //   e
                                // );
                                // }}
                                name="checkedNotification"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
            : null
          : null
        }

        {
          addNew && (
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="products-state-price-table"
              >
                <TableBody>
                  <TableRow key={'new'} className={classes.tableRowAlignment}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.tableBody}
                    >
                      <Autocomplete
                        options={modules.filter(
                          (module) =>
                            !Boolean(
                              assignedModules.find(
                                (assigned) => assigned.module_name === module.name
                              )
                            )
                        )}
                        getOptionLabel={(opt) => opt.name}
                        onInputChange={(e, value) =>
                          handleSearch(e, value, modules)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={'Search modules'}
                            margin="none"
                            size="small"
                            variant="outlined"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      <span style={{ width: 300 }} className={classes.tableBody}>
                        &nbsp;
                      </span>
                    </TableCell>

                    <TableCell align="left" className={classes.tableBody}>
                      <span onClick={handleCancel} className={classes.tableBody}>
                        <div className={classes.iconContainer}>
                          <CancelIcon className={classes.icon} />
                          <div
                            style={{
                              flex: 1,
                              marginLeft: 5,
                              textAlign: 'left',
                            }}
                          >
                            cancel
                          </div>
                        </div>
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
      </div>
      {role && !addNew && (
        <Button
          variant="outlined"
          size="medium"
          startIcon={<CheckOutlinedIcon />}
          className={classes.addNewButton}
          onClick={handleAddNewModule}
        >
          ADD MODULE
        </Button>
      )}
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </>
  );
}
