import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Tooltip,
  Checkbox,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  whiteSmoke,
  drawerTextColor,
  successButtonBackground,
  secondaryBlueText,
} from "../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import "../../../../styles/internal/scroll.css";
import ToolTipComponent from "../../common/ToolTipComponent";
const CustomCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  formControl: {
    width: "80%",
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
    fontFamily: 'Poppins',
  },
  tableHead: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
  },
  tableBody: {
    fontSize: pxToEm(13),
    fontFamily: 'Poppins',
  },
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  customerTypeTitle: {
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
    fontSize: pxToEm(16),
    color: secondaryBlueText,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  addAndSaveButton: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0px 20px 0px",
    justifyContent: "space-between",
  },
  saveCancelButton: {
    display: "flex",
    placeSelf: "strech",
  },
  alignStateText: {
    display: "flex",
    placeContent: "flex-start",
    alignItems: "center",
  },
  rightSpacing: {
    display: "flex",
    marginLeft: "10px",
    fontSize: "0.8em",
    alignItems: "center",
    fontWeight: "normal",
  },
  editIcon: {
    heigth: "15px",
    width: "15px",
  },
  iconContainer: {
    height: 30,
    width: 30,
    background: "yellow",
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: whiteSmoke,
    overflow: "hidden",
    color: grey,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    color: secondaryBlueText,
  },
  captionDetails: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    marginBottom: 10,
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "10px 5px 10px 5px",
      },
    },
  },
});
export default function ManageCustomerAccountAdditionalContacts(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [contactRows, setContactRows] = React.useState(props?.props?.customerAdditionalContacts)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes = useStyles();
  // const rows =
  //   props.props.customerAdditionalContacts.length > 0
  //     ? props.props.customerAdditionalContacts
  //     : [];
  const [
    customerAdditionalContactOperation,
    setCustomerAdditionalContactOperation,
  ] = React.useState("view");
  const handleCustomerAdditionalContactOperationChange = (operation) => {
    setCustomerAdditionalContactOperation(operation);
  };

  useEffect(() => {
    setContactRows(props.props.customerAdditionalContacts)
    console.log(props?.props.customerAdditionalContacts)
  }, [props.props.customerAdditionalContacts])

  useEffect(() => {
    if (props?.props?.openAdditionalCOntactForm) {
      // console.log("here")
      setCustomerAdditionalContactOperation("view")
      props?.props?.handleOpenFormStateChange()
    }
  }, [props?.props?.openAdditionalCOntactForm])
  // console.log(props?.props?.openAdditionalCOntactForm, customerAdditionalContactOperation)
  console.log(contactRows)
  return (
    <>
      <Typography className={classes.customerTypeTitle} component={"span"}>
        <div className={classes.alignStateText}>
          <div>ADDITIONAL CONTACTS</div>
        </div>
      </Typography>
      <Typography className={classes.captionDetails} component={"span"}>
        These contact are notified via email and sms for daily rates &
        dispatches
      </Typography>
      <ThemeProvider theme={theme}>
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="left" className={classes.tableHead}>
                    NAME
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    EMAIL
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    CONTACT
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    NOTIFICATION STATUS
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableHead}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactRows && contactRows.length > 0
                  ? contactRows.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={classes.tableBody}
                        style={{ width: '18%' }}
                      >
                        {(props?.additionalContactOperation.operation === "edit" &&
                          props?.additionalContactOperation.rowID === row.id)
                          ? (
                            <TextField
                              label="Name"
                              type="text"
                              value={props?.additionalContactRowEditData?.contact_name || ""}
                              variant="outlined"
                              size="small"
                              style={{ fontFamily: 'Poppins', }}
                              InputProps={{
                                style: {
                                  fontFamily: 'Poppins',
                                }
                              }}
                              onChange={(e) => props.handleCustomerAdditionalContactEditValueChange(e, "contact_name")}
                              error={props.additionalContactEditError.contact_name}
                              helperText={
                                props.additionalContactEditError.contact_name
                                  ? "Incorrect Name"
                                  : null
                              }
                            />
                          )
                          : row.name
                        }
                      </TableCell>
                      <TableCell align="left" className={classes.tableBody}>
                        {(props?.additionalContactOperation.operation === "edit" &&
                          props?.additionalContactOperation.rowID === row.id)
                          ? (
                            <TextField
                              label="Email"
                              type="email"
                              value={props?.additionalContactRowEditData?.contact_email_id || ""}
                              variant="outlined"
                              size="small"
                              style={{ fontFamily: 'Poppins', }}
                              InputProps={{
                                style: {
                                  fontFamily: 'Poppins',
                                }
                              }}
                              onChange={(e) => props.handleCustomerAdditionalContactEditValueChange(e, "contact_email_id")}
                              error={props.additionalContactEditError.contact_email_id}
                              helperText={
                                props.additionalContactEditError.contact_email_id
                                  ? "Incorrect Email"
                                  : null
                              }
                            />
                          )
                          : row.email_id
                        }
                      </TableCell>
                      <TableCell align="left" className={classes.tableBody} style={{ width: '18%' }}>
                        {(props?.additionalContactOperation.operation === "edit" &&
                          props?.additionalContactOperation.rowID === row.id)
                          ? (
                            <TextField
                              type="number"
                              label="Contact"
                              value={props?.additionalContactRowEditData?.contact_phone_number || ""}
                              variant="outlined"
                              size="small"
                              style={{ fontFamily: 'Poppins', width: '80%' }}
                              InputProps={{
                                style: {
                                  fontFamily: 'Poppins',
                                }
                              }}
                              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => props.handleCustomerAdditionalContactEditValueChange(e, "contact_phone_number")}
                              error={props.additionalContactEditError.contact_phone_number}
                              helperText={
                                props.additionalContactEditError.contact_phone_number
                                  ? "Incorrect Contact Number"
                                  : null
                              }
                            />
                          )
                          : row.phone_number
                        }
                      </TableCell>
                      <TableCell align="left" className={classes.tableBody}>
                        <div className={classes.dataFieldsForCheckbox}>
                          {(props?.additionalContactOperation.operation === "edit" &&
                            props?.additionalContactOperation.rowID === row.id)
                            ? (
                              <>
                                <CustomCheckbox
                                  checked={props.additionalContactRowEditData?.contact_is_notification_enabled}
                                  onChange={(e) => props.handleCustomerAdditionalContactEditValueChange(e, "contact_is_notification_enabled")}
                                // name="notificationStatus"
                                />
                                <Typography
                                  className={classes.generalText}
                                  component={"span"}
                                  style={{ fontFamily: 'Poppins', fontSize: pxToEm(16) }}
                                >
                                  {props.additionalContactRowEditData?.contact_is_notification_enabled
                                    ? "Active"
                                    : ""}
                                </Typography>
                              </>
                            )
                            : <>
                              <CustomCheckbox
                                disabled={true}
                                checked={row.is_notification_enabled}
                                name="notificationStatus"
                              />
                              {row.is_notification_enabled ? (
                                <Typography
                                  className={classes.generalText}
                                  component={"span"}
                                  style={{ fontFamily: 'Poppins', fontSize: pxToEm(16) }}
                                >
                                  Active
                                </Typography>
                              ) : (
                                ""
                              )}
                            </>
                          }
                        </div>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.tableBody}
                      >
                        {(props?.additionalContactOperation.operation === "edit" &&
                          props?.additionalContactOperation.rowID === row.id)
                          ? (
                            <>
                              {
                                props.props.saveAdditionalContactLoader && (
                                  <CircularProgress
                                    size={20}
                                    style={{ marginRight: "10px", }}
                                  />
                                )
                              }
                              <ToolTipComponent
                                title="Invalid value"
                                condition={props.additionalContactEditError.contact_name ||
                                  props.additionalContactEditError.contact_email_id ||
                                  props.additionalContactEditError.contact_phone_number}
                                title2="Save"
                                condition2={props?.additionalContactOperation.operation === "edit" &&
                                  props?.additionalContactOperation.rowID === row.id}

                                spanWidth="25%"
                              >
                                <IconButton
                                  style={{
                                    display: 'inline',
                                    paddingTop: 0
                                  }}
                                  variant="text"
                                  disabled={
                                    props.additionalContactEditError.contact_name ||
                                    props.additionalContactEditError.contact_email_id ||
                                    props.additionalContactEditError.contact_phone_number ||
                                    props.props.saveAdditionalContactLoader
                                  }
                                  onClick={() => props.handleEditSaveCustomerAdditionalContact()}
                                  size="small"
                                >
                                  <CheckOutlinedIcon />
                                </IconButton>
                              </ToolTipComponent>
                              <ToolTipComponent
                                title="Cancel"
                                condition={props?.additionalContactOperation.operation === "edit" &&
                                  props?.additionalContactOperation.rowID === row.id}
                                spanWidth="25%"
                              >
                                <IconButton
                                  style={{
                                    display: 'inline',
                                    paddingTop: 0
                                  }}
                                  variant="text"
                                  disabled={props.props.saveAdditionalContactLoader}
                                  onClick={() => {
                                    props?.handleAdditionalContactOperation('view', row)
                                  }}
                                  size="small"
                                >
                                  <CloseOutlinedIcon />
                                </IconButton>
                              </ToolTipComponent>
                            </>
                          )
                          : (<>
                            <ToolTipComponent
                              title="You have no edit permission on this widget"
                              condition={!hasUpdateAccess(props.props.user, "customerManagement")}
                              title2='Edit'
                              condition2={hasUpdateAccess(props.props.user, "customerManagement")}
                              spanWidth="25%"
                            >
                              <IconButton
                                style={{
                                  display: 'inline',
                                  marginBootom: 1,
                                  paddingTop: 0
                                }}
                                variant="text"
                                onClick={() => props?.handleAdditionalContactOperation('edit', row)}
                                size="small"
                              >
                                <EditOutlinedIcon />
                              </IconButton>
                            </ToolTipComponent>
                            {props.props.removeAdditionalContactLoader ===
                              index ? (
                              <CircularProgress size={15} />
                            ) : (
                              <ToolTipComponent
                                title="You have no edit permission on this widget"
                                condition={!hasUpdateAccess(props.props.user, "customerManagement")}
                                title2={`Click to remove contact`}
                                condition2={hasUpdateAccess(props.props.user, "customerManagement")}
                              >
                                <IconButton
                                  className={classes.removeIconDimensions}
                                  onClick={(evt) => {
                                    props.props.handleRemoveCustomerAdditionalContactPerson(
                                      row.id
                                    );
                                    props.props.handleRemoveAdditionalContactCircularLoadingShow(
                                      index
                                    );
                                  }}
                                  disabled={
                                    hasUpdateAccess(props.props.user, "customerManagement")
                                      ? false
                                      : true
                                  }
                                >
                                  <DeleteOutlinedIcon />
                                </IconButton>
                              </ToolTipComponent>
                            )}
                          </>
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))
                  : null}
                {customerAdditionalContactOperation === "add" && !props?.closeAdditionalCOntactForm ? (
                  <TableRow key={props.props.dealerDistributorId}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.tableBody}
                    >
                      <TextField
                        label="Name"
                        type="text"
                        value={props?.props?.additionalContactName || ""}
                        variant="outlined"
                        size="small"
                        style={{ fontFamily: 'Poppins', }}
                        InputProps={{
                          style: {
                            fontFamily: 'Poppins',
                          }
                        }}
                        onChange={props.props.handleCustomerAdditionalContactValueChange(
                          "additionalContactName"
                        )}
                        error={props.props.additionalContactNameError}
                        helperText={
                          props.props.additionalContactNameError
                            ? "Incorrect Name"
                            : null
                        }
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      <TextField
                        label="Email"
                        type="email"
                        value={props?.props?.additionalContactEmail || ""}
                        variant="outlined"
                        size="small"
                        style={{ fontFamily: 'Poppins', }}
                        InputProps={{
                          style: {
                            fontFamily: 'Poppins',
                          }
                        }}
                        onChange={props.props.handleCustomerAdditionalContactValueChange(
                          "additionalContactEmail"
                        )}
                        error={props.props.additionalContactEmailError}
                        helperText={
                          props.props.additionalContactEmailError
                            ? "Incorrect Email"
                            : null
                        }
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      <TextField
                        type="number"
                        label="Contact"
                        value={props?.props?.additionalContactContact || ""}
                        variant="outlined"
                        size="small"
                        style={{ fontFamily: 'Poppins', }}
                        InputProps={{
                          style: {
                            fontFamily: 'Poppins',
                          }
                        }}
                        onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                        onWheel={(e) => e.target.blur()}
                        onChange={props.props.handleCustomerAdditionalContactValueChange(
                          "additionalContactContact"
                        )}
                        error={props.props.additionalContactContactError}
                        helperText={
                          props.props.additionalContactContactError
                            ? "Incorrect Contact Number"
                            : null
                        }
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      <div className={classes.dataFieldsForCheckbox}>
                        <CustomCheckbox
                          checked={
                            props.props.additionalContactNotificationStatus
                          }
                          onChange={props.props.handleCustomerAdditionalContactValueChange(
                            "additionalContactNotificationStatus"
                          )}
                          name="notificationStatus"
                        />
                        <Typography
                          className={classes.generalText}
                          component={"span"}
                          style={{ fontFamily: 'Poppins', fontSize: pxToEm(16) }}
                        >
                          {props.props.additionalContactNotificationStatus
                            ? "Active"
                            : ""}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableBody}
                    ></TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {contactRows.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={contactRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )}
        </Paper>
      </ThemeProvider>
      <div className={classes.addAndSaveButton}>
        {customerAdditionalContactOperation === "view" ? (
          <ToolTipComponent
            placement='top-start'
            title="You have no edit permission on this widget"
            condition={!hasUpdateAccess(props.props.user, "customerManagement")}
          >
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddOutlinedIcon />}
              className={classes.addNewButton}
              onClick={() => {
                handleCustomerAdditionalContactOperationChange("add");
                props?.props?.handleOpenFormStateChange()
              }}
              disabled={
                hasUpdateAccess(props.props.user, "customerManagement")
                  ? false
                  : true
              }
            >
              ADD NEW
            </Button>
          </ToolTipComponent>
        ) : null}
        {customerAdditionalContactOperation === "view" ? null : props.props
          .saveAdditionalContactLoader ? (
          <CircularProgress disableShrink />
        ) : (
          <div className={classes.saveCancelButton}>
            <Button
              size="medium"
              disabled={
                !props.props.additionalContactName ||
                props.props.additionalContactNameError ||
                props.props.additionalContactEmailError ||
                props.props.additionalContactContactError
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={(evt) =>
                props.props.handleSaveCustomerAdditionalContact(evt)
              }
            >
              Save
            </Button>

            <Button
              size="medium"
              onClick={() =>
                handleCustomerAdditionalContactOperationChange("view")
              }
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
