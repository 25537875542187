import React, { Component } from "react";
import {
  secondaryBlueText,
  grey,
  darkGrey,
  successButtonBackground,
  orange,
} from "../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
// import CheckCircle from "@material-ui/icons/CheckCircle";
// import Info from "@material-ui/icons/Info";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import moment from "moment";
// import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";

const styles = {
  eWayBillText: {
    color: secondaryBlueText,
    fontWeight: "bold",
    fontSize: "1.3em",
  },

  keyStyle: {
    color: grey,
    fontWeight: "bold",
  },
  twoDivSeparationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleTextStyle: {
    color: secondaryBlueText,
    fontWeight: "bold",
  },
  iconStyleCheckCircle: {
    color: successButtonBackground,
    height: "0.9em",
    width: "0.9em",
  },
  iconInfoStyle: {
    color: orange,
    height: "0.9em",
    width: "0.9em",
  },
  leftKeyStyle: {
    color: grey,
    fontWeight: "bold",
    margin: "4px 0px",
  },
  addressStyle: {
    color: darkGrey,
    width: "70%",
    display: "flex",
    justifyContent: "flex-end",
  },
  fourElementContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "10vh",
    alignItems: "center",
    marginBottom: "50px",
  },
  fourElementChild: {
    width: "25%",
    display: "flex",
    alignItems: "center",
  },
};

class EwayData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      // states,
      // transportMode,
      // outward,
      // SubType,
      // DocType,
      // RecState,
      // SupState,
      // TransMode,
      // vehicleTypeOption,
      // VehicleType,
      // DOCNO,
      // handleTextChange,
      // DOCDATE,
      // RECGSTIN,
      // SUPGSTIN,
      // SUPNAME,
      // SUPADD1,
      // SUPADD2,
      // SUPCITY,
      // SUPPINCODE,
      // RECNAME,
      // RECADD1,
      // RECADD2,
      // RECCITY,
      // RECPINCODE,
      // TRANSPORTERID,
      // TRANSPORTERNAME,
      // VEHICLENO,
      // PRODUCTNAME,
      // PRODUCTDESC,
      // QUANTITY,
      // QTYUNIT,
      // TAXABLEVALUE,
      // SGSTRATE,
      // CGSTVALUE,
      // IGSTRATE,
      // TRANSDOCNO,
      // TRANSDOCDATE,
      // transporterDistance,
    } = this.props;
    return (
      <div>
        {/* <p className={classes.eWayBillText}>E-Way Bill</p> */}
        <div className={classes.fourElementContainer}>
          {/* <div className={classes.fourElementChild}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel htmlFor="age-simple">SUB TYPE</InputLabel>
              <Select
                value={SubType}
                onChange={handleTextChange}
                inputProps={{
                  name: "SubType",
                  id: "age-simple",
                }}
              >
                {outward.map((data) => (
                  <MenuItem
                    key={data.name}
                    value={data.name}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
          {/* <div className={classes.fourElementChild}>
            <TextField
              name="transporterDistance"
              id="outlined-name"
              label="Transporter Distance"
              margin="normal"
              variant="outlined"
              fullWidth
              style={{ width: "100%" }}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                this.props.handleTextChange({
                  target: { name: "transporterDistance", value },
                });
              }}
              value={transporterDistance}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">KM</InputAdornment>
                ),
              }}
            />
          </div> */}

          {/* <div className={classes.fourElementChild}>
            <span className={classes.leftKeyStyle}>DOCUMENT DATE</span>
            <span style={{ marginLeft: "20px" }}>
              {moment(new Date(DOCDATE)).format("MMM Do YY")}
            </span>
          </div> */}
        </div>
        {/* <div className={classes.twoDivSeparationContainer}>
          <div style={{ width: "45%", padding: "24px 0px" }}>
            <div className={classes.twoDivSeparationContainer}>
              <p className={classes.titleTextStyle}>RECIPIENT DETAIL</p>
              {(RECGSTIN && RECGSTIN.length < 15) ||
              (RECGSTIN && RECGSTIN.length > 15) ? (
                <Info
                  style={{ color: orange, height: "0.9em", width: "0.9em" }}
                />
              ) : (
                <CheckCircle className={classes.iconStyleCheckCircle} />
              )}
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}> GSTIN</span>
              <span
                style={{
                  color:
                    (RECGSTIN && RECGSTIN.length < 15) ||
                    (RECGSTIN && RECGSTIN.length > 15)
                      ? orange
                      : darkGrey,
                }}
              >
                {RECGSTIN && RECGSTIN}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>NAME</span>
              <span style={{ color: darkGrey }}>{RECNAME}</span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>ADDRESS </span>
              <span className={classes.addressStyle}>{RECADD1 + RECADD2}</span>
            </div>

            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>CITY</span>
              <span style={{ color: darkGrey }}>{RECCITY}</span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>STATE CODE</span>
              <span style={{ color: darkGrey }}>
                {RECGSTIN && RECGSTIN.substring(0, 2)}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>PINCODE</span>
              <span style={{ color: darkGrey }}>{RECPINCODE}</span>
            </div>
          </div>
          <div style={{ width: "45%", padding: "24px 0px" }}>
            <div className={classes.twoDivSeparationContainer}>
              <p className={classes.titleTextStyle}>SUPPLIER DETAIL</p>
              <Info className={classes.iconInfoStyle} />
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>GSTIN</span>
              <span
                style={{
                  color:
                    SUPGSTIN.length < 15 || SUPGSTIN.length > 15
                      ? orange
                      : darkGrey,
                }}
              >
                {SUPGSTIN}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>NAME</span>
              <span style={{ color: darkGrey }}>{SUPNAME}</span>
            </div>
            
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>ADDRESS </span>
              <span className={classes.addressStyle}>{SUPADD1 + SUPADD2}</span>
            </div>

            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>CITY</span>
              <span style={{ color: darkGrey }}>{SUPCITY}</span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>STATE CODE</span>
              <span style={{ color: darkGrey }}>
                {SUPGSTIN.substring(0, 2)}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>PINCODE</span>
              <span style={{ color: darkGrey }}>{SUPPINCODE}</span>
            </div>
          </div>
        </div> */}

        {/* <div className={classes.twoDivSeparationContainer}>
          <div style={{ width: "45%", padding: "24px 0px" }}>
            <div className={classes.twoDivSeparationContainer}>
              <p className={classes.titleTextStyle}>OTHER DETAIL</p>
              <CheckCircle className={classes.iconStyleCheckCircle} />
            </div>
            

            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>TRANSPORTER ID</span>
              <span style={{ color: darkGrey }}>{TRANSPORTERID}</span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>TRANSPORTER NAME</span>
              <span style={{ color: darkGrey }}>{TRANSPORTERNAME}</span>
            </div>
           
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>DOCUMENT NUMBER</span>
              <span
                style={{
                  color: DOCNO.length > 16 ? orange : darkGrey,
                }}
              >
                {TRANSDOCNO}
              </span>
            </div>

            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>
                TRANSPORTER DOCUMENT DATE
              </span>{" "}
              <span style={{ color: darkGrey }}>
                {moment(new Date(TRANSDOCDATE)).format("MMM Do YY")}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>VEHICLE NUMBER</span>
              <span style={{ color: darkGrey }}>{VEHICLENO}</span>
            </div>
          </div>
         
        </div> */}
      </div>
    );
  }
}
export default withStyles(styles)(EwayData);
