import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import Dispatches from "../Dispatches";

class DispatchOperationWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout {...this.props}>
        <Dispatches
          {...this.props}
          Title="Dispatch Planning"
          routeLink="/dispatchPlanning"
          anchorElUpdated={this.props.anchorEl}
          openUpdated={this.props.open}
          itemCategoryName={this.props.itemCategoryName}
          prevSelectedDate={this.props?.prevSelectedDate}
          handleSelectedOrderDate={this.props.handleSelectedOrderDate}
          prevItemCategoryName={this.props.prevItemCategoryName}
          fetchPendingQuantity={this.props.fetchPendingQuantity}
          handleActionMenuClose={this.props.handleActionMenuClose}
          dispatchTablePdf={this.props.dispatchTablePdf}
          dispatchTablePdfData={this.props.dispatchTablePdfData}
          dispatchTablePrintLoader={this.props.dispatchTablePrintLoader}
          isMobile={this.props.isMobile}
        />
      </Layout>
    );
  }
}
export default DispatchOperationWeb;
