import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Requirements from "../Requirements";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  addRequirementsMainBody: {
    width: "40%",
  },
};
class AddRequirements extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar orderSelect>
        <div className={classes.addRequirementsMainBody}>
          <Requirements />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(AddRequirements);
