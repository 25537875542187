// Mobile Imports
import Orders from "./mobile";
import DealerOrdersMobile from "./mobile/DealerOrdersMobile";
import PendingOrders from "./mobile/PendingOrders";
import SingleOrder from "./mobile/SingleOrder";
import SingleOrderDetails from "./mobile/SingleOrderDetails";
import AddRequirements from "./mobile/AddRequirements";
import CanceledOrder from "./mobile/CanceledOrder";

// Web Imports
import OrdersWeb from "./web";
import DealerOrdersWeb from "./web/DealerOrdersWeb";
import PendingOrdersWeb from "./web/PendingOrders";
import SingleOrderWeb from "./web/SingleOrder";
import SingleOrderDetailsWeb from "./web/SingleOrderDetails";
import AddRequirementsWeb from "./web/AddRequirements";
import CanceledOrderWeb from "./web/CanceledOrder";

// Exports
export default Orders;
export {
  OrdersWeb,
  DealerOrdersMobile,
  DealerOrdersWeb,
  PendingOrders,
  PendingOrdersWeb,
  SingleOrder,
  SingleOrderWeb,
  SingleOrderDetails,
  SingleOrderDetailsWeb,
  AddRequirements,
  AddRequirementsWeb,
  CanceledOrder,
  CanceledOrderWeb,
};
