import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Cancel from "../Cancel";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  addCanceledOrderMainBody: {
    width: "100%",
  },
};
class AddCanceledOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar orderSelect>
        <div className={classes.addCanceledOrderMainBody}>
          <Cancel {...this.props} />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(AddCanceledOrder);
