import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import {
  bottomNavigationFontColor,
  lightPrimaryColor,
  primaryColor,
} from "../../../styles/colors";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import OrderCard from "../common/OrderCard";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  orderShowTextMessageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: "26px",
  },
  orderShowTextMessage: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    fontSize: "1.2em",
    lineHeight: "1.7em",
    color: bottomNavigationFontColor,
  },
  bottomContainer: {},
  bottomTermContainer: {
    height: "7vh",
    width: "85%",
  },
  bottomButtonContainer: {
    width: "85%",
    height: "10vh",
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
  },
  confirmButtonStyle: {
    height: "85%",
    width: "50%",
    backgroundColor: primaryColor,
  },
  discardButtonStyle: {
    marginLeft: "20px",
    textTransform: "capitalize",
    color: primaryColor,
  },
  orderCardContainer: {
    //paddingTop: "20px",
  },
  dialogTop: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 26px",
    height: "5vh",
    alignItems: "center",
  },
  dialogContentContainer: {
    padding: "0px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.2em",
    height: "10vh",
  },
  dialogButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "8vh",
  },
  dialogButtonShow: {
    width: "70%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  yesButtonStyle: {
    width: "78px",
    backgroundColor: primaryColor,
    padding: "8px 24px",
  },
  goBackButton: {
    color: primaryColor,
    textTransform: "capitalize",
  },
};
class Single extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
    };
  }
  render() {
    const { classes, isMobile } = this.props;
    return (
      <div>
        {!this.props.isMobile ? (
          <div style={{ padding: "10px 0px 10px 4%" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                this.props.history.goBack();
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          </div>
        ) : null}
        <div className={classes.orderCardContainer}>
          <OrderCard />
        </div>
        <div className={classes.orderShowTextMessageContainer}>
          <div className={classes.orderShowTextMessage}>
            <span>The contract is pending approval by you.</span>
            <span>Please select the option below to</span>
            <span>confirm the contract</span>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            width: this.props.isMobile ? "100%" : "40%",
            height: "22vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className={classes.bottomTermContainer}>
            <Checkbox checked={true} style={{ color: lightPrimaryColor }} />
            <span>
              I accept the
              <span
                style={{
                  color: lightPrimaryColor,
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/orders/terms-and-conditions");
                }}
              >
                Term & Conditions
              </span>
            </span>
          </div>
          <div className={classes.bottomButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.confirmButtonStyle}
              onClick={(e) => {
                e.preventDefault();
                // this.props.history.push(
                //   `/orders/orderDetails/${this.props.match.params.orderId}`
                // );
              }}
            >
              Confirm Contract
            </Button>
            <Button
              className={classes.discardButtonStyle}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ openDialog: true });
              }}
            >
              Discard Contract
            </Button>
          </div>
        </div>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          PaperProps={{
            style: {
              width: !isMobile && "25%",
              // height: "26vh",
              overflowY: "inherit",
              borderRadius: "5%",
            },
          }}
        >
          <div>
            <div className={classes.dialogTop}>
              <Close
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ openDialog: false });
                }}
              />
            </div>
            <div className={classes.dialogContentContainer}>
              <span>Are you sure want to cancel this contract ?</span>
            </div>
            <div className={classes.dialogButtonContainer}>
              <div className={classes.dialogButtonShow}>
                <Button className={classes.goBackButton}>Go Back</Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.yesButtonStyle}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ openDialog: false });
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Single));
