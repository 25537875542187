import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Switch,
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  drawerBackground,
  drawerTextColor,
  earthBlue,
  grey,
  orange,
  secondaryBlueText,
  secondaryGrey,
  successButtonBackground,
  whiteSmoke,
} from "../../../../constants/internal/colors";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../methods";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment/moment";
import SaveButton from "../../common/SaveButton";
import Popover from '@mui/material/Popover';
import COLORS from "../../../../constants/internal/colors";
import Divider from "@material-ui/core/Divider";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles({
  formControl: {
    width: "80%",
  },
  textField: {
    // width: "90%",

    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiIconButton-root': {
      padding: '12px 6px',
    }
  },
  inputWidth: { width: 250 },
  table: {
    minWidth: 650,
  },
  tableHead: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: "bold",
  },
  removeOption: {
    color: secondaryGrey,
    fontSize: pxToEm(13),
    flex: 1,
    cursor: "pointer",
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  rateLinkingDescription: {
    color: grey,
    fontSize: pxToEm(14),
  },
  linkSection: {
    display: "flex",
    placeItems: "center",
    marginTop: "35px",
    justifyContent: "space-evenly",
    marginBottom: 15,
    flex: 1,
  },
  noRatesLinkedTitle: {
    fontSize: pxToEm(12),
  },
  iconContainer: {
    height: 30,
    width: 250,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    color: grey,
  },
  editTextIcon: {
    borderRadius: 100,
    padding: 2,
    marginLeft: "10px",
    width: "70px",
    display: "flex",
    backgroundColor: whiteSmoke,
    color: grey,
    cursor: "pointer",
  },
  editText: {
    flex: 1,
    marginLeft: 5,
    textAlign: "left",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
  },
  alignItemsGeneral: {
    display: "flex",
    alignItems: "center",
    flex: 4.5,
  },
  popoverBox: {
    '& .MuiPopover-paper': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
      marginBottom: 10,
      // borderRadius: 20,
    }
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '500',
    alignItems: "center"
  },
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  }
};

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.defaultChecked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


export default function RateLinkingSectionComponent(props) {
  const classes = useStyles();

  const {
    allItemCategories,
    handleSaveRateLinkingItem,
    selectedItemId,
    handleRateLinkingOperation,
    rateLinkingOperation,
    isLoadingRateLinking
  } = props;
  const addNewEndRef = useRef(null)

  const [rateLinkingData, setRateLinkingData] = useState(
    props?.rateLinkingData || []
  )
  // const [rateLinkingOperation, setRateLinkingOperation] = useState({
  //   operation: 'view',
  //   index: -1
  // });
  const [rateLinkingShowData, setRateLinkingShowData] = useState({
    "item_category": '',
    "linked_item_category": '',
    "rate_difference": '',
    "is_active": true,
    "valid_from": null,
    "valid_upto": null
  })

  React.useEffect(() => {
    setRateLinkingData(props?.rateLinkingData)
  }, [props?.rateLinkingData])

  const scrollToBottom = () => {
    addNewEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const handleEditButton = (indexNumber, data) => {
    // setRateLinkingOperation({
    //   operation: 'edit',
    //   index: indexNumber
    // })
    handleRateLinkingOperation('edit', indexNumber)
    setRateLinkingShowData({
      "item_category": data.item_category,
      "linked_item_category": data.linked_item_category,
      "rate_difference": data.rate_difference,
      "is_active": data.is_active,
      "valid_from": data.valid_from,
      "valid_upto": data.valid_upto
    })
  }

  const handleItemCancel = () => {
    handleRateLinkingOperation('view', -1)
    // setRateLinkingOperation({ operation: 'view', index: -1 })
    setRateLinkingShowData({
      "item_category": '',
      "linked_item_category": '',
      "rate_difference": '',
      "is_active": true,
      "valid_from": null,
      "valid_upto": null
    })
  }

  const handleDateChange = (name) => (date) => {
    if (date !== "Invalid Date") {
      setRateLinkingShowData(prevValue => {
        return {
          ...prevValue,
          [name]: moment(date).format("YYYY-MM-DD"),
        }
      });
    } else {
      console.log("invalid date");
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setRateLinkingShowData(prevValue => {
      return {
        ...prevValue,
        [name]: name === 'is_active' ? checked : value
      }
    })
    console.log(rateLinkingShowData)
  }

  const Mousehover = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (e) => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            fontFamily: 'Poppins',
            fontSize: "inherit",
            fontWeight: anchorEl === null ? "400" : "500",
            color: anchorEl === null ? "#666" : "#3B69B0",
            cursor: 'pointer',
            display: "inline",
            marginTop: "3px",
            marginLeft: "5px"
          }}
        >
          <InfoOutlinedIcon />
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          className={classes.popoverBox}
        >
          <Typography sx={{ p: 1 }}>
            {props.content}
          </Typography>
        </Popover>
      </div>
    );
  }

  const ClickedRateDiffBox = (props) => {
    let data = props.data;
    return (
      <div style={{ width: 350, padding: "5%" }}>
        <Typography className={classes.dialogText}>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Rate difference
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid from
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid upto
          </div>
        </Typography>
        <Divider variant='middle' style={{ margin: '2% 0' }} />
        {data?.map((rateDiffData, rdindex) => {
          return (
            <div key={rdindex}>
              <Typography className={classes.dialogText}>
                <div>
                  {rateDiffData?.value}
                </div>
                <div style={{
                  marginLeft: !rateDiffData?.valid_upto
                    ? null : "70px",
                }}>
                  {!rateDiffData?.valid_from
                    ? null
                    : moment(rateDiffData?.valid_from).format("DD-MM-YYYY")}
                </div>
                <div>
                  {!rateDiffData?.valid_upto
                    ? null
                    : moment(rateDiffData?.valid_upto).format("DD-MM-YYYY")}
                </div>
              </Typography>
              <Divider
                variant='middle'
                style={{
                  margin: '2% 0',
                  display: rdindex === (data?.length - 1) ? 'none' : 'block'
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <div className={classes.rateLinkingTitle}>
        <div className={classes.iconContainer}>
          <span className={classes.contentTitle}>RATE LINKING</span>
        </div>
      </div>
      <div className={classes.rateLinkingDescription}>
        <Typography className={classes.rateLinkingDescription}>
          If you link CATEGORY to a parent category, CATEGORY's daily rates
          can be derived from the parent category's Base Rate.
        </Typography>
        <Typography className={classes.rateLinkingDescription}>
          This does not allow auto-publish of rates, User will still have to
          manually approve and publish CATEGORY's Daily
        </Typography>
      </div>
      <div style={{
        marginBottom: "20px"
      }}>
        <TableContainer
          className={classes.tableContainer}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell
                  align="left"
                  style={{
                    color: grey,
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    width: '20%'
                  }}
                >
                  Category to Link
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: grey,
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    width: '15%'
                  }}
                >
                  Rate Difference
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: grey,
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    width: '15%'
                  }}
                >
                  Effective From
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: grey,
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    width: '15%'
                  }}
                >
                  Effective Upto
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: grey,
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    width: '5%'
                  }}
                >
                  Active
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: grey,
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    width: '15%'
                  }}
                >
                  {/* Action */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rateLinkingData &&
                rateLinkingData.length > 0 ?
                rateLinkingData.map((data, index) => (
                  (['add'].includes(rateLinkingOperation.operation) || rateLinkingOperation.index !== index)
                    ?
                    (<TableRow style={{ backgroundColor: '#FFF' }}>
                      <TableCell
                        align="left"
                        style={{
                          // color: grey,
                          fontSize: "1em",
                          width: '20%'
                        }}
                        index={index}
                      >
                        {allItemCategories &&
                          (allItemCategories.find(d => d.id === data?.linked_item_category))?.category_name
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          // color: grey,
                          fontSize: "1em",
                          width: '15%'
                        }}
                        index={index}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          ₹ {data?.rate_difference}
                          <Mousehover
                            content={<ClickedRateDiffBox
                              data={data?.rate_difference_logs}
                            />}
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          // color: grey,
                          fontSize: "1em",
                          width: '15%'
                        }}
                        index={index}
                      >
                        {!data?.valid_from
                          ? null
                          : moment(data?.valid_from).format("DD MMM YYYY")
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          // color: grey,
                          fontSize: "1em",
                          width: '15%'
                        }}
                        index={index}
                      >
                        {!data?.valid_upto
                          ? null
                          : moment(data?.valid_upto).format("DD MMM YYYY")
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          fontSize: "0.8em",
                          width: '5%'
                          // paddingLeft: 80
                        }}
                        index={index}
                      >
                        {data.is_active ?
                          (
                            <CheckCircle
                              style={{ color: successButtonBackground }}
                            />
                          ) : (
                            <Cancel style={{ color: orange }} />
                          )}
                      </TableCell>
                      <TableCell
                        align="right"
                        index={index}
                        style={{ width: '15%' }}
                      >
                        <ToolTipComponent
                          title="You have no edit permission on this widget"
                          condition={!hasUpdateAccess(props.props.user, 'item_categories')}
                        >
                          <IconButton
                            style={{
                              display: index !== rateLinkingOperation?.index ? "inline" : "none",
                              // marginLeft: 20
                            }}
                            disabled={isLoadingRateLinking || !hasUpdateAccess(props.props.user, 'item_categories')}
                            onClick={(e) => handleEditButton(index, data)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </ToolTipComponent>
                        <ToolTipComponent
                          title="You have no edit permission on this widget"
                          condition={!hasUpdateAccess(props.props.user, 'item_categories')}
                        >
                          <IconButton
                            style={{
                              display: index !== rateLinkingOperation?.index ? "inline" : "none",
                            }}
                            disabled={isLoadingRateLinking || !hasUpdateAccess(props.props.user, 'item_categories')}
                            onClick={(e) => handleSaveRateLinkingItem(rateLinkingShowData, 'delete', data.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ToolTipComponent>
                      </TableCell>
                    </TableRow>)
                    :
                    <TableRow style={{ backgroundColor: '#FFF' }}>
                      <TableCell
                        align="left"
                        style={{
                          color: grey,
                          fontSize: "0.8em",
                          width: '20%'
                        }}
                        index={index}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          size="small"
                        >
                          <InputLabel id="item-category-selection">
                            Select Category
                          </InputLabel>
                          <Select
                            name='linked_item_category'
                            labelId="item-category-selection"
                            MenuProps={MenuProps}
                            onChange={handleChange}
                            label="Select Category"
                            size="small"
                            value={rateLinkingShowData.linked_item_category}
                          >
                            <MenuItem value="select" disabled={true}>
                              <em>Select</em>
                            </MenuItem>
                            {allItemCategories &&
                              allItemCategories.map((product, index) => (
                                <MenuItem
                                  value={product.id}
                                  style={{ display: (rateLinkingData?.find(d => d.linked_item_category !== rateLinkingShowData.linked_item_category && d.linked_item_category == product.id) || product.id == props.props.match.params.id || !product.is_active) && 'none' }}
                                >
                                  {product.category_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          fontSize: "0.8em",
                          width: '15%'
                        }}
                        index={index}
                      >
                        <TextField
                          type="number"
                          className={classes.formControl}
                          variant="outlined"
                          size="small"
                          value={rateLinkingShowData.rate_difference}
                          onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                          onWheel={(e) => e.target.blur()}
                          name="rate_difference"
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">₹</InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          fontSize: "0.8em",
                          width: '15%'
                          // paddingLeft: 80
                        }}
                        index={index}
                      >
                        <ThemeProvider theme={materialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.textField}
                              name="valid_from"
                              size="small"
                              inputVariant="outlined"
                              label="Effective From *"
                              format="dd/MM/yyyy"
                              //disablePast={new Date(rateLinkingShowData?.valid_from) > new Date() ? new Date(rateLinkingShowData?.valid_from) : new Date()}
                              helperText={null}
                              value={
                                !rateLinkingShowData.valid_from
                                  ? null
                                  : new Date(rateLinkingShowData.valid_from)
                              }
                              // minDate={rateLinkingShowData?.valid_from
                              //   ? new Date(rateLinkingShowData?.valid_from)
                              //   : new Date()}
                              minDate={new Date(rateLinkingShowData?.valid_from)}
                              onChange={handleDateChange('valid_from')}
                              placeholder="e.g.: DD/MM/YYYY"
                              InputAdornmentProps={{ position: "end" }}
                              InputProps={{ className: classes.datePicker, readOnly: true }}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          fontSize: "0.8em",
                          width: '15%'
                          // paddingLeft: 80
                        }}
                        index={index}
                      > ---------
                        {/* <ThemeProvider theme={materialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.textField}
                              name="valid_upto"
                              size="small"
                              inputVariant="outlined"
                              label="Effective Upto *"
                              format="dd/MM/yyyy"
                              // disabled={rateLinkingShowData.valid_from === null}
                              disabled={true}
                              // disablePast={true}
                              // minDate={new Date(rateLinkingShowData.valid_from)}
                              value={
                                !rateLinkingShowData.valid_upto
                                  ? null
                                  : new Date(rateLinkingShowData.valid_upto)
                              }
                              // onChange={handleDateChange('valid_upto')}
                              placeholder="e.g.: DD/MM/YYYY"
                              InputAdornmentProps={{ position: "end" }}
                              InputProps={{ className: classes.datePicker, readOnly: true }}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider> */}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          fontSize: "0.8em",
                          width: '5%'
                          // paddingLeft: 80
                        }}
                        index={index}
                      >
                        <IOSSwitch
                          checked={Boolean(rateLinkingShowData.is_active)}
                          onChange={handleChange}
                          name="is_active"
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        index={index}
                        style={{ width: '15%' }}
                      >
                        <div style={{ display: index !== rateLinkingOperation?.index ? "none" : "inline", }}>
                          <IconButton
                            onClick={(e) => {
                              handleSaveRateLinkingItem(rateLinkingShowData, 'update', data.id)
                            }}
                            disabled={
                              isLoadingRateLinking ||
                              rateLinkingShowData.linked_item_category === '' ||
                              rateLinkingShowData.rate_difference === '' ||
                              rateLinkingShowData.valid_from === null
                              // rateLinkingShowData.valid_upto === null ||
                              // moment(new Date(rateLinkingShowData.valid_upto)).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")
                            }
                          >
                            <CheckOutlinedIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => { handleRateLinkingOperation('view', -1) }}
                            disabled={isLoadingRateLinking}
                          >
                            <CloseOutlinedIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                ))
                : null
              }
              {
                ['add'].includes(rateLinkingOperation.operation) &&
                <TableRow style={{ backgroundColor: '#FFF' }}>
                  <TableCell
                    align="left"
                    style={{
                      color: grey,
                      fontSize: "0.8em",
                      width: '20%'
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      size="small"
                    >
                      <InputLabel id="item-category-selection">
                        Select Category
                      </InputLabel>
                      <Select
                        name="linked_item_category"
                        labelId="item-category-selection"
                        MenuProps={MenuProps}
                        onChange={handleChange}
                        label="Select Category"
                        size="small"
                        fullWidth
                        value={rateLinkingShowData.linked_item_category}
                      >
                        {console.log(props, props.props?.match?.params?.id)}
                        <MenuItem value="select" disabled={true}>
                          <em>Select</em>
                        </MenuItem>
                        {allItemCategories &&
                          allItemCategories.map((product, index) => (
                            <MenuItem
                              value={product.id}
                              style={{ display: (rateLinkingData?.find(d => d.linked_item_category == product.id) || product.id == props.props.match.params.id || !product.is_active) && 'none' }}
                            >
                              {product.category_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.8em",
                      width: '15%'
                    }}
                  >
                    <TextField
                      type="number"
                      className={classes.formControl}
                      variant="outlined"
                      size="small"
                      value={rateLinkingShowData.rate_difference}
                      onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                      onWheel={(e) => e.target.blur()}
                      name="rate_difference"
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.8em",
                      width: '15%'
                    }}
                  >
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.textField}
                          name="valid_from"
                          size="small"
                          inputVariant="outlined"
                          label="Effective Fromll *"
                          format="dd/MM/yyyy"
                          //disablePast={true}
                          //minDate={new Date()}
                          minDate={new Date(rateLinkingShowData?.valid_from)}
                          value={
                            !rateLinkingShowData.valid_from
                              ? null
                              : new Date(rateLinkingShowData.valid_from)
                          }
                          onChange={handleDateChange('valid_from')}
                          placeholder="e.g.: DD/MM/YYYY"
                          InputAdornmentProps={{ position: "end" }}
                          InputProps={{ className: classes.datePicker, readOnly: true }}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.8em",
                      width: '15%'
                    }}
                  > ----------
                    {/*  <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.textField}
                          name="valid_upto"
                          size="small"
                          inputVariant="outlined"
                          label="Effective Upto *"
                          format="dd/MM/yyyy"
                          disabled={rateLinkingShowData.valid_from === null}
                          disablePast={true}
                          minDate={new Date(rateLinkingShowData.valid_from)}
                          value={
                            !rateLinkingShowData.valid_upto
                              ? null
                              : new Date(rateLinkingShowData.valid_upto)
                          }
                          onChange={handleDateChange('valid_upto')}
                          placeholder="e.g.: DD/MM/YYYY"
                          InputAdornmentProps={{ position: "end", }}
                          InputProps={{ className: classes.datePicker, readOnly: true }}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>  */}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      fontSize: "0.8em",
                      width: '5%'
                      // paddingLeft: 80
                    }}
                  >
                    <IOSSwitch
                      checked={Boolean(rateLinkingShowData.is_active)}
                      onChange={handleChange}
                      name="is_active"
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: grey,
                      fontSize: "0.8em",
                      width: '15%'
                      // paddingLeft: 80
                    }}
                  >

                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div ref={addNewEndRef} />

      <div>
        <ToolTipComponent
          title="You have no create permission on this widget"
          condition={!hasWriteAccess(props.props.user, 'item_categories')}
        >
          <Button
            style={{ display: rateLinkingOperation.operation === 'add' ? "none" : "inline-flex" }}
            size="medium"
            className={classes.addNewButton}
            variant="outlined"
            startIcon={<AddOutlinedIcon />}
            disabled={isLoadingRateLinking || !hasWriteAccess(props.props.user, 'item_categories')}
            onClick={() => {
              handleRateLinkingOperation('add', 100)
              // setRateLinkingOperation({ operation: 'add', index: 100 })
              setRateLinkingShowData({
                "item_category": selectedItemId,
                "linked_item_category": '',
                "rate_difference": '',
                "is_active": true,
                "valid_from": null,
                "valid_upto": null
              })
              setTimeout(() => scrollToBottom(), 10)
            }
            }
          >
            ADD NEW
          </Button>
        </ToolTipComponent>
        <SaveButton
          style={{ display: !(rateLinkingOperation.operation === 'add') ? "none" : "inline-flex" }}
          size="medium"
          className={classes.saveButton}
          variant="contained"
          startIcon={<CheckOutlinedIcon />}
          onClick={(e) => {
            handleSaveRateLinkingItem(rateLinkingShowData, 'add')
          }}
          isLoading={isLoadingRateLinking}
          disabled={
            rateLinkingShowData.linked_item_category === '' ||
            rateLinkingShowData.rate_difference === '' ||
            rateLinkingShowData.valid_from === null
            // rateLinkingShowData.valid_upto === null
          }
        >
          Save
        </SaveButton>
        <Button
          style={{
            display: !(rateLinkingOperation.operation === 'add') ? "none" : "inline-flex",
            marginLeft: 10
          }}
          size="medium"
          className={classes.margin}
          onClick={handleItemCancel}
          disabled={isLoadingRateLinking}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
