import React, { Component } from 'react';
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import Dispatches from '../../dispatchPlanning/Dispatches';

class LoadingAdviceOperationWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Layout {...this.props}>
                <Dispatches {...this.props}
                    Title="Loading Advices"
                    routeLink="/internalLoadingAdvices"
                    dispatchPlaningListLA={this.props.dispatchPlaningList}
                    handleLaApprove={this.props.handleLaApprove}
                    handleCancelLa={this.props.handleCancelLa}
                    Loader={this.props.dispatchLoader}
                    handleSnackbarClose={this.props.handleSnackbarClose}
                    handleFetchDispatchLADetails={this.props.handleFetchDispatchLADetails}
                    handleDiscardWeighSlips={this.props.handleDiscardWeighSlips}
                    handleUnlockWeighbridge={this.props.handleUnlockWeighbridge}
                    handleFetchSalesReturn={this.props.handleFetchSalesReturn}
                    fetchReasonCancelLA={this.props.fetchReasonCancelLA}
                    handleLAReplacement={this.props.handleLAReplacement}
                    handleLACancelAfterBilled={this.props.handleLACancelAfterBilled}
                    handleChangeLAStatus={this.props.handleChangeLAStatus}
                    getInvoiceDetails={this.props.getInvoiceDetails}
                    invoiceDetails={this.props.invoiceDetails}
                    hideLADetailsDialog={this.props.hideLADetailsDialog}
                    searchLAValue={this.props.searchLAValue}
                    handleLASearchValue={this.props.handleLASearchValue}
                    handleLASearchClear={this.props.handleLASearchClear}
                    // approveLaModification={this.props.approveLaModification}
                    // rejectLaModification={this.props.rejectLaModification}
                    date={this.props.date}
                    clickLaConformDate={this.props.clickLaConformDate}
                    handleDateChangeLA={this.props.handleDateChangeLA}
                    prevDate={this.props.prevDate}
                    isMobile={this.props.isMobile}
                />
            </Layout>
        );
    }
}
export default LoadingAdviceOperationWeb;
