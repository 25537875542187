import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    StepConnector,
    StepLabel,
    Step,
    Stepper,
    makeStyles,
    withStyles,
    Typography,
    Box,
    CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { pxToEm } from "../../../methods";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import { primaryColor } from "../../../constants/internal/colors";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: "#FFAE42",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#4caf50",
        },
    },
    line: {
        borderColor: "#eaeaf0",
        borderWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const QontoConnectorRed = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: "#ff0000",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#4caf50",
        },
    },
    line: {
        borderColor: "#gray",
        borderWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center",
    },

    active: {
        color: "#FFAE42",

    },
    activeRed: {
        color: "#FF0000",
    },
    circle: {
        width: 9,
        height: 9,
        borderRadius: "50%",
        backgroundColor: "currentColor",
    },
    completed: {
        color: "#4caf50",
        zIndex: 1,
        fontSize: 22,
        paddingRight: 6
    },
    completedRed: {
        color: "#FF0000",
        zIndex: 1,
        fontSize: 18,
        paddingLeft: 4
    },
});


const useVericalStepperStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        // overflowY: "scroll",
        margin: '0 0 5px 0',
        // paddingBottom: 50,
        minWidth: 400,

        '& .MuiStepLabel-label': {
            height: 20
        },
        '& .MuiStepper-vertical': {
            flexDirection: 'column-reverse',
        }
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepContent: {
        fontFamily: 'Poppins',
        fontSize: "inherit",
        fontWeight: '400',
        margin: '1% 3%'
    },
    stepContentText: {
        fontSize: pxToEm(13),
        fontWeight: '500',
        color: "#3B69B0"
    },
    loaderStyle: {
        color: primaryColor
    },
}));


const LAStatusVerticalStepper = (props) => {
    const classes = useVericalStepperStyles();
    const [activeStep, setActiveStep] = useState();
    const [laNumber, setLaNumber] = useState(props.laNumber);
    const [laStatus, setLAStatus] = useState(props.laStatus)
    const [laClickedLogData, setLaClickedLogData] = useState([])
    const [totalLog, setTotalLog] = useState();
    const steps = getSteps(props);


    useEffect(() => {
        if (laStatus !== undefined) {
            // console.log(laNumber + "--" + laStatus)
            // getStatusInNumber(laStatus)
        }

    }, [laStatus])

    function fetchLaLogData(number) {
        return (
            fetch(`${APIROUTES.GET_MORE_LA_LOGS_DETAILS}la_number=${number}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        )
    }

    function ClickedFetchData(number) {
        fetchLaLogData(number)
            .then(res => { return res.json() })
            .catch(e => console.log(e))
            .then((laLogDetail) => {
                setLaClickedLogData(laLogDetail?.slice(0).reverse())
            })
    }

    useEffect(() => {
        ClickedFetchData(laNumber)
    }, [])

    useEffect(() => {
        if (laClickedLogData && laClickedLogData.length > 0) {
            // console.log(laClickedLogData.length)
            let index = laClickedLogData.length;
            setActiveStep(laClickedLogData.length)
            // console.log("INDEX" + laClickedLogData[index - 1].log_detail.status)
            // console.log("NUMBER " + getStatusInNumber(laClickedLogData[index - 1].log_detail.status))
            laClickedLogData[index - 1].log_detail.status === 'HOLD' ?
                getStatusInNumber(laClickedLogData[index - 2].log_detail.status)
                :
                getStatusInNumber(laClickedLogData[index - 1].log_detail.status)

        }

    }, [laClickedLogData])



    function getSteps(props) {
        return [
            {
                status: "Created",
            },
            {
                status: "Approved",
            },
            {
                status: "Gate-In",
            },
            {
                status: "Loading",
            },
            {
                status: "Packed",
            },
            {
                status: "Alloted",
            },
            {
                status: "Billed",
            },
            {
                status: "Dispatched",
            },

        ];
    }

    function getSteps2(props) {
        return [
            {
                status: "Created",
            },
            {
                status: "Lapsed",
            },
            {
                status: "Cancelled",
            },
        ]
    }


    function getStatusInNumber(statusString) {
        // console.log("statusString :" + statusString)
        return (
            statusString.length > 0 ?
                statusString === 'PENDING' || statusString === 'CREATE' ? setTotalLog(1) :
                    statusString === 'APPROVED' ? setTotalLog(2) :
                        statusString === 'GATEIN' ? setTotalLog(3) :
                            statusString === 'LOADING' ? setTotalLog(4) :
                                statusString === 'PACKED' ? setTotalLog(5) :
                                    statusString === 'ALLOTED' ? setTotalLog(6) :
                                        statusString === 'BILLED' ? setTotalLog(7) :
                                            statusString === 'DISPATCHED' ? setTotalLog(8) :
                                                statusString === 'LAPSED' ? setActiveStep(10) :
                                                    statusString === 'UNLOADING' ? setActiveStep(11) :
                                                        statusString === 'UNLOADED' ? setActiveStep(12) :
                                                            statusString === 'CANCELLED' ? setActiveStep(13) :
                                                                null
                : null
        )
    }

    function QontoStepIcon(props) {
        const classes = useQontoStepIconStyles();
        const { active, completed } = props;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed ? (
                    <Check className={classes.completed} style={{ transform: 'scale(1.2)' }} />
                ) : (
                    <div className={classes.circle} />
                )}
            </div>
        )
    }

    QontoStepIcon.propTypes = {
        active: PropTypes.bool,
        completed: PropTypes.bool,
    };

    function QontoStepIcon2(props) {
        const classes = useQontoStepIconStyles();
        const { active, completed } = props;
        console.log(props)
        console.log(laClickedLogData)

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed ?
                    props.icon === laClickedLogData.length &&
                        (laClickedLogData[laClickedLogData.length - 1].log_detail.status === "CANCELLED" ||
                            laClickedLogData[laClickedLogData.length - 1].log_detail.status === "REJECTED") ?
                        (<CloseIcon className={classes.completedRed} />)
                        :
                        (<Check className={classes.completed} style={{ transform: 'scale(1.2)', fontSize: 22, paddingLeft: 6, paddingRight: 0 }} />)
                    : (
                        <div className={classes.circle} />
                    )}
            </div>
        )
    }

    QontoStepIcon2.propTypes = {
        active: PropTypes.bool,
        completed: PropTypes.bool,
    };



    return (
        <div className={classes.root}>
            {['UNLOADING', 'UNLOADED', 'LAPSED', 'CANCELLED', 'REJECTED'].includes(props.laStatus) ?
                <Box sx={{ minWidth: 400 }}>
                    {laClickedLogData !== undefined ?
                        (<Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            connector={<QontoConnectorRed />}
                        >
                            {laClickedLogData.map((step, index) => (
                                <Step key={step.status}>
                                    <StepLabel StepIconComponent={QontoStepIcon2}>
                                        {index < laClickedLogData.length ?
                                            <div>
                                                <span>
                                                    {laClickedLogData[index].log_detail.status === "CREATE" ? "Created" : laClickedLogData[index].log_detail.status.slice(0, 1) + laClickedLogData[index].log_detail.status.slice(1).toLowerCase()}
                                                </span>
                                                <span className={classes.stepContent}>
                                                    <Typography
                                                        className={classes.stepContentText}
                                                        component="span"
                                                    >
                                                        {" -  "}
                                                        {
                                                            (moment(laClickedLogData[index].log_detail.status_update_date).format("DD-MMM-YY") + " " + laClickedLogData[index].log_detail.time)

                                                        }
                                                        {laClickedLogData[index].log_detail.plant_component ?
                                                            ' @ ' + laClickedLogData[index].log_detail.plant_component
                                                            :
                                                            " "
                                                        }
                                                    </Typography>
                                                    <Typography className={classes.stepContentText}>
                                                        by
                                                        {" " + laClickedLogData[index].log_detail.created_by?.personal_details?.first_name + " " + laClickedLogData[index].log_detail.created_by?.personal_details?.last_name + " "}
                                                        {laClickedLogData[index].log_detail.created_by.personal_details.customer_type ?
                                                            ("(" + laClickedLogData[index].log_detail.created_by.personal_details.customer_type + ")")
                                                            :
                                                            laClickedLogData[index].log_detail.created_by.employee_id ?
                                                                "(" + laClickedLogData[index].log_detail.created_by.employee_id + ")"
                                                                :
                                                                " "
                                                        }
                                                    </Typography>
                                                </span>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>)
                        :
                        (<Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            connector={<QontoConnectorRed />}
                        >
                            <CircularProgress
                                disableShrink
                                className={classes.loaderStyle}
                            />
                        </Stepper>)
                    }
                </Box>
                :
                <Box sx={{ minWidth: 400 }}>
                    {laClickedLogData !== undefined ?
                        (<Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            connector={<QontoConnector />}
                        >
                            {/* {console.log(laClickedLogData)} */}
                            {laClickedLogData.map((step, index) => (
                                <Step key={step.status}>
                                    <StepLabel
                                        StepIconComponent={QontoStepIcon}
                                        style={{ paddingLeft: 9, height: 20, }}
                                    >
                                        {index < laClickedLogData.length ?
                                            <div>
                                                <span>
                                                    {laClickedLogData[index].log_detail.status === "CREATE" ? "Created" : laClickedLogData[index].log_detail.status.slice(0, 1) + laClickedLogData[index].log_detail.status.slice(1).toLowerCase()}
                                                </span>
                                                <span className={classes.stepContent}>
                                                    <Typography
                                                        className={classes.stepContentText}
                                                        component="span"
                                                    >
                                                        {" -  "}
                                                        {(moment(laClickedLogData[index].log_detail.status_update_date).format("DD-MMM-YY") + " " + laClickedLogData[index].log_detail.time)

                                                        }
                                                        {laClickedLogData[index].log_detail.plant_component ?
                                                            ' @ ' + laClickedLogData[index].log_detail.plant_component
                                                            :
                                                            " "
                                                        }
                                                    </Typography>
                                                    <Typography className={classes.stepContentText}>
                                                        by
                                                        {" " + laClickedLogData[index].log_detail.created_by?.personal_details?.first_name + " " + laClickedLogData[index].log_detail.created_by?.personal_details?.last_name + " "}
                                                        {laClickedLogData[index].log_detail.created_by.personal_details.customer_type ?
                                                            ("(" + laClickedLogData[index].log_detail.created_by.personal_details.customer_type + ")")
                                                            :
                                                            laClickedLogData[index].log_detail.created_by.employee_id ?
                                                                "(" + laClickedLogData[index].log_detail.created_by.employee_id + ")"
                                                                :
                                                                " "
                                                        }
                                                    </Typography>
                                                </span>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </StepLabel>
                                </Step>
                            ))}
                            {/* {console.log(totalLog)} */}
                            {/* {console.log(steps.slice(totalLog, 9))} */}
                            {steps.slice(totalLog, 9).map((step, index) => (
                                <Step key={step.status} >
                                    <StepLabel StepIconComponent={QontoStepIcon}
                                        style={{ paddingLeft: 9, height: 20, }}
                                    >
                                        {step.status}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>)
                        :
                        (<Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            connector={<QontoConnector />}
                        >
                            <CircularProgress
                                disableShrink
                                className={classes.loaderStyle}
                            />
                        </Stepper>)
                    }
                </Box>
            }
        </div>
    );
}

export default LAStatusVerticalStepper;

