import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import SpinLoader from "../../../common/SpinLoader";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Check from "@material-ui/icons/Check";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  grey,
  highlitedTextColor,
  secondaryGrey,
} from "../../../../constants/internal/colors";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

const styles = {
  addContractsMainBody: {
    height: "100%",
    width: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "100%",
  },
  contractsButtonStyle: {
    textTransform: "capitalize",
    fontSize: "1.1em",
    color: highlitedTextColor,
  },
  contactTextFieldContainer: {
    marginTop: "20px",
    paddingLeft: "5px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "96%",
    paddingBottom: "70px",

    paddingLeft: "25px",
  },
  spinLoaderContiner: {
    width: "30%",
    paddingTop: 20,
    marginTop: "20px",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  customerInfoContainer: {
    paddingLeft: "20px",
    paddingTop: "22px",
    width: "30%",
    display: "flex",
    flexDirection: "column",
  },
  buyerAndConsigneeSelectContainer: {
    width: "70%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "26px",
  },
  checkBoxLabelText: {
    color: secondaryGrey,
    fontWeight: "bold",
    marginRight: "6px",
  },
};

const subscribed = [
  {
    label: "GK TMT",
  },
  {
    label: "WIRE ROAD",
  },
  {
    label: "DD ROAD",
  },
  {
    label: "RR WIRE",
  },
];
const unSubscribed = [
  {
    label: "SHAH TMT",
  },
  {
    label: "WIRE TMT",
  },
  {
    label: "DD TMT",
  },
  {
    label: "RR TMT",
  },
];
class AddContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: "",
      rates: "",
      Quantity: "",
      orderValidity: "",
      showCustomerDetail: false,
      openCustomerListPopper: false,
      customer: [
        {
          name: "Ramesh Traders",
          contactNo: "909090909090",
          gstNo: "WWR434343EEE222",
        },
        {
          name: "Akash Traders",
          contactNo: "8088878787",
          gstNo: "S333ERRW#3333",
        },
        {
          name: "Rahul Traders",
          contactNo: "8088878787",
          gstNo: "S333QQQQW#3333",
        },
      ],
    };
  }
  handleTextChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.contact !== "") {
          this.setState({ openCustomerListPopper: true });
        }
      }
    );
  };
  handleTextChangeName = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleClosePopper = () => {
    this.setState({ openCustomerListPopper: false });
  };

  handleChangeSelectMenu = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };
  render() {
    const {
      customer,
      contact,
      openCustomerListPopper,
      showCustomerDetail,
      showCustomerDetailLoader,
      rates,
      Quantity,
      orderValidity,
    } = this.state;
    const { classes } = this.props;
    return (
      <Layout {...this.props}>
        <div
          className={classes.addContractsMainBody}
          onClick={(e) => {
            e.preventDefault();
            this.handleClosePopper();
          }}
        >
          <div className={classes.navigationBar}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/internalContracts");
              }}
              size="small"
              className={classes.contractsButtonStyle}
            >
              Contracts
            </Button>

            <ChevronRight style={{ color: "#A3A3A3" }} />
            <span style={{ color: "#A3A3A3" }}>Create New</span>
          </div>
          <Paper elevation={1} className={classes.paperStyle}>
            <div className={classes.contactTextFieldContainer}>
              <Downshift id="downshift-popper">
                {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
                  <div className={classes.container}>
                    <TextField
                      name="contact"
                      id="outlined-name"
                      label="Contacts"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      style={{ width: "90%" }}
                      onChangeCapture={(e) => {
                        this.handleTextChange(e);
                      }}
                      onFocusCapture={() => {
                        this.setState({
                          showCustomerDetail: false,
                        });
                      }}
                      inputRef={(node) => (this.inpRef1 = node)}
                      InputProps={{
                        ...getInputProps({
                          value: contact,
                          placeholder: "",
                        }),
                      }}
                    />

                    {this.inpRef1 && (
                      <Popper
                        open={openCustomerListPopper}
                        anchorEl={this.inpRef1}
                      >
                        <div
                          {...(isOpen
                            ? getMenuProps({}, { suppressRefError: true })
                            : {})}
                        >
                          <Paper
                            square
                            style={{
                              maxHeight: "155px",

                              overflowY: "scroll",
                              marginTop: 8,
                              width: this.inpRef1
                                ? this.inpRef1.clientWidth
                                : null,
                            }}
                          >
                            {customer.map((data) => (
                              <MenuItem
                                key={data.gstNo}
                                component="div"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState(
                                    {
                                      contact: data.contactNo,
                                      customerData: data,
                                    },
                                    () => {
                                      this.setState(
                                        {
                                          openCustomerListPopper: false,
                                          showCustomerDetail: true,
                                          showCustomerDetailLoader: true,
                                        },
                                        () => {
                                          setInterval(() => {
                                            this.setState({
                                              showCustomerDetailLoader: false,
                                            });
                                          }, 2000);
                                        }
                                      );
                                    }
                                  );
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    lineHeight: "1em",
                                  }}
                                >
                                  <span>{data.name}</span>
                                  <span
                                    style={{ color: grey, fontSize: "0.7em" }}
                                  >
                                    {data.gstNo}
                                  </span>
                                  <span
                                    style={{ color: grey, fontSize: "0.7em" }}
                                  >
                                    {data.contactNo}
                                  </span>
                                </div>
                              </MenuItem>
                            ))}
                          </Paper>
                        </div>
                      </Popper>
                    )}
                  </div>
                )}
              </Downshift>
              {showCustomerDetail && showCustomerDetailLoader === false ? (
                <div className={classes.buyerAndConsigneeSelectContainer}>
                  <div style={{ width: "50%" }}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "90%" }}
                      className={classes.formControl}
                    >
                      <InputLabel
                        id="label-id"
                        ref={(ref) => {
                          this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                      >
                        Default Consignee
                      </InputLabel>
                      <Select
                        value={this.state.age}
                        onChange={this.handleChange}
                        input={
                          <OutlinedInput
                            label="Default Consignee "
                            labelWidth={this.state.labelWidth}
                            name="age"
                            id="outlined-age-simple"
                            labelId="label-id"
                          />
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Shahid</MenuItem>
                        <MenuItem value={20}>Loki</MenuItem>
                        <MenuItem value={30}>Amit</MenuItem>
                      </Select>
                      <FormHelperText
                        style={{ fontSize: "0.8em", color: secondaryGrey }}
                      >
                        Can be changed later in loading advoice
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div style={{ width: "50%" }}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "90%" }}
                      className={classes.formControl}
                    >
                      <InputLabel
                        id="label-id"
                        ref={(ref) => {
                          this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                      >
                        Default Buyer
                      </InputLabel>
                      <Select
                        value={this.state.age}
                        onChange={this.handleChange}
                        input={
                          <OutlinedInput
                            label="Default Buyer"
                            labelWidth={this.state.labelWidth}
                            name="age"
                            id="outlined-age-simple"
                            labelId="label-id"
                          />
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Shahid</MenuItem>
                        <MenuItem value={20}>Loki</MenuItem>
                        <MenuItem value={30}>Amit</MenuItem>
                      </Select>
                      <FormHelperText
                        style={{ fontSize: "0.8em", color: secondaryGrey }}
                      >
                        Can be changed later in loading advoice
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              ) : null}
            </div>

            {showCustomerDetail ? (
              <div>
                <div className={classes.customerInfoContainer}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <span
                      style={{ width: "30%", fontWeight: "bold", color: grey }}
                    >
                      NAME-
                    </span>
                    <span style={{ width: "70%" }}>
                      {this.state.customerData.name}
                    </span>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <span
                      style={{ width: "30%", fontWeight: "bold", color: grey }}
                    >
                      GSTIN-
                    </span>
                    <span style={{ width: "70%" }}>
                      {this.state.customerData.gstNo}
                    </span>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <span
                      style={{ width: "30%", fontWeight: "bold", color: grey }}
                    >
                      TYPE-
                    </span>
                    <span style={{ width: "70%" }}>Direct Customer</span>
                  </div>
                </div>
                <Divider
                  variant="middle"
                  style={{ margin: "30px 18px 30px 0px" }}
                />
              </div>
            ) : null}

            {showCustomerDetailLoader ? (
              <div className={classes.spinLoaderContiner}>
                <SpinLoader />
                <span
                  style={{ marginTop: "40px", fontWeight: "bold", color: grey }}
                >
                  LOADING SUBSCRIPTION
                </span>
              </div>
            ) : showCustomerDetail && showCustomerDetailLoader === false ? (
              <div>
                <div
                  style={{
                    width: "90%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "30px",
                  }}
                >
                  <TextField
                    style={{
                      width: "30%",
                    }}
                    id="outlined-select-currency-native"
                    select
                    label="Select Category"
                    className={classes.textField}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <optgroup
                      label="Subscribed"
                      style={{ color: grey, fontSize: "0.8em" }}
                    >
                      {subscribed.map((option, index) => (
                        <option
                          value="hai"
                          style={{ color: "black" }}
                          key={index}
                        >
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup
                      label="UnSubscribed"
                      style={{ color: grey, fontSize: "0.8em" }}
                    >
                      {unSubscribed.map((option, index) => (
                        <option
                          value="hai"
                          style={{ color: "black" }}
                          key={index}
                        >
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                  </TextField>
                  <TextField
                    name="rates"
                    style={{
                      width: "30%",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    value={rates}
                    id="outlined-name"
                    label="Rate"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      this.handleTextChangeName({
                        target: { name: "rates", value },
                      });
                    }}
                  />
                  <TextField
                    name="Quantity"
                    style={{
                      width: "30%",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    id="outlined-name"
                    label="Quantiy"
                    margin="normal"
                    variant="outlined"
                    value={Quantity}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      this.handleTextChangeName({
                        target: { name: "Quantity", value },
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span style={{ fontSize: "0.9em", color: grey }}>
                            MT
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "60%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "30px",
                  }}
                >
                  <TextField
                    style={{
                      width: "45%",
                    }}
                    id="outlined-select-currency-native"
                    select
                    label="Select Category"
                    className={classes.textField}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <optgroup
                      label="Subscribed"
                      style={{ color: grey, fontSize: "0.8em" }}
                    >
                      {subscribed.map((option, index) => (
                        <option
                          value="hai"
                          style={{ color: "black" }}
                          key={index}
                        >
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup
                      label="UnSubscribed"
                      style={{ color: grey, fontSize: "0.8em" }}
                    >
                      {unSubscribed.map((option, index) => (
                        <option
                          value="hai"
                          style={{ color: "black" }}
                          key={index}
                        >
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                  </TextField>
                  <TextField
                    name="orderValidity"
                    style={{
                      width: "45%",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    id="outlined-name"
                    label="Order validity (Days)"
                    margin="normal"
                    variant="outlined"
                    value={orderValidity}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      this.handleTextChangeName({
                        target: { name: "orderValidity", value },
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span style={{ fontSize: "0.9em", color: grey }}>
                            Days
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "60%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Internal Remark"
                    multiline
                    rows="4"
                    style={{
                      width: "45%",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="Customer Remark"
                    multiline
                    rows="4"
                    style={{
                      width: "45%",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ padding: "30px 0px 0px 20px" }}>
                  <span className={classes.checkBoxLabelText}>
                    ALLOW BREAKING FIFO
                  </span>
                  <Checkbox
                    style={{ color: "blue" }}
                    checked={true}
                    value="checkedA"
                  />
                </div>
                <div style={{ padding: "20px 0px 0px 14px" }}>
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      backgroundColor: "green",
                      width: "14%",
                      marginRight: "30px",
                    }}
                  >
                    <Check style={{ marginRight: "7px " }} />
                    Save
                  </Button>
                  <Button color="primary">Cancel</Button>
                </div>
              </div>
            ) : null}
          </Paper>
        </div>
      </Layout>
    );
  }
}
export default withRouter(withStyles(styles)(AddContracts));
