import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from "@material-ui/core";
import { grey } from "../../../constants/internal/colors";
import { withRouter } from "react-router";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    paddingTop: "0px",
  },

  tableCellStyle: {
    color: grey,
    width: "12%",
    fontSize: "0.8em",
    fontWeight: "bold",
  },
  tableBottomCellStyle: {
    color: grey,
    fontSize: "1em",
    fontWeight: "bold"
  },
  tableCellValueStyle: {
    padding: "14px",
    color: grey,
  },
});

class ItemTableList extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 10,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  getNumber = (number) => {
    if (number === null || isNaN(number)) {
      return 0;
    } else {
      return parseFloat(number);
    }
  };

  handleTaxableValue = (row, laDetailsInDispatchCounter) => {
    return (
      this.getNumber(row.alloted_weight) *
      (this.getNumber(laDetailsInDispatchCounter.order.rate) +
        this.getNumber(laDetailsInDispatchCounter.order.freight_charge_amount))
    ).toFixed(2);
  };
  handleTotalAmount = (row, laDetailsInDispatchCounter) => {
    return (
      Number(this.handleTaxableValue(row, laDetailsInDispatchCounter) * 0.18) +
      Number(this.handleTaxableValue(row, laDetailsInDispatchCounter))
    ).toFixed(3);
  };
  render() {
    const { classes, laDetailsInDispatchCounter } = this.props;
    const { rowsPerPage, page } = this.state;
    return (
      <Paper className={classes.root} style={{ boxShadow: "none" }}>
        <div className={classes.tableWrapper}>
          <TableContainer className={classes.tableContainerStyle} id="scroll">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      width: "10%",
                      color: grey,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                    }}
                  >
                    Serial No.
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: "25%",
                      color: grey,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    HSN/SAC Code
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Quantity
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Unit
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Net Rate
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Taxable Value
                  </TableCell>
                  {/* <TableCell align="center" className={classes.tableCellStyle}>
                    Total Amount
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {laDetailsInDispatchCounter.loading_advice_items &&
                  laDetailsInDispatchCounter.loading_advice_items
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        // style={{ cursor: "pointer" }}
                        key={row.id}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   this.props.history.push(
                      //     `/internalContracts/singleContracts/${row.id}`
                      //   );
                      // }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.item.item_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {laDetailsInDispatchCounter?.item_category
                            ?.hsn_code || ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {Number.isNaN(
                            Number(
                              laDetailsInDispatchCounter?.loading_advice_items[
                                index
                              ]?.alloted_weight
                            )
                          )
                            ? 0
                            : Number(
                              laDetailsInDispatchCounter
                                ?.loading_advice_items[index]?.alloted_weight
                            )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          MT
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.item_rate}

                          {/* {this.handleTaxableValue(
                            row,
                            laDetailsInDispatchCounter
                          )} */}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.item_taxable_amount}
                          {/* {this.handleTaxableValue(
                            row,
                            laDetailsInDispatchCounter
                          )} */}
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          > */}
                        {/* {row.total_item_value} */}
                        {/* {this.handleTotalAmount(
                              row,
                              laDetailsInDispatchCounter
                            )} */}
                        {/* </div>
                        </TableCell> */}
                      </TableRow>
                    ))}
              </TableBody>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      width: "10%",
                      color: grey,
                      fontSize: "0.95em",
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: "25%",
                      color: grey,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCellStyle}
                  ></TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableBottomCellStyle}
                  >
                    {laDetailsInDispatchCounter?.total_values?.alloted_weight}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableBottomCellStyle}
                  >MT</TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableBottomCellStyle}
                  >
                    {/* {parseFloat(laDetailsInDispatchCounter.net_rate).toFixed(2)} */}
                  </TableCell>
                  <TableCell align="center"
                    className={classes.tableBottomCellStyle}
                  >
                    {laDetailsInDispatchCounter?.total_values?.taxable_amount}
                  </TableCell>
                  {/* <TableCell align="center" className={classes.tableBottomCellStyle}>
                    {laDetailsInDispatchCounter?.total_values?.total_item_value}
                  </TableCell> */}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    );
  }
}

ItemTableList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ItemTableList));
