import { CircularProgress } from '@mui/material';
import React, { Component } from 'react'
import {
    secondaryColor,
    bottomNavigationFontColor,
    errorMessageColor
} from "../../../styles/colors";
import SpinLoader from "../../common/SpinLoader";
import { Divider } from '@material-ui/core';
import TileSkeleton from '../common/TileSkeleton';
import { pxToEm } from '../../../methods';
import { DataArray } from '@mui/icons-material';
import { grey } from '../../../constants/internal/colors';

const styles = {
    pendingDispatchesContainer: (isMobile) => ({
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: isMobile ? 10 : 20,
        padding: '10px 6px',
        borderRadius: 12,
        backgroundColor: '#FFF',
        boxShadow:
            "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
    }),
    pendingDispatchMessage: (isMobile) => ({
        width: isMobile ? "95%" : "98%",
        display: "flex",
        flexDirection: "column",
        // marginBottom: "20px",
    }),
    plantNameStyle: {
        margin: "10px 0px",
        color: secondaryColor,

    },
    plantNameValueStyle: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px",
        width: '100%'
    },
    plantBoxTitle: {
        width: '25%',
        textAlign: 'left',
        color: secondaryColor,

        fontSize: window.innerWidth < 310 ? pxToEm(13.5) : window.innerWidth < 375 && pxToEm(13.6)
        // display: 'flex',
        // justifyContent: 'flex-start'
    },
    qtyBoxTitle: {
        width: '37%',
        textAlign: 'right',
        color: secondaryColor,

        fontSize: window.innerWidth < 310 ? pxToEm(13.8) : window.innerWidth < 375 && pxToEm(13.8)
        // display: 'flex',
        // justifyContent: 'flex-end'
    },
    clickHereToOrderCircle: {
        width: "8px",
        height: "8px",
        background: errorMessageColor,
        borderRadius: "50%",
        marginRight: 10
    },
    plantBox: {
        width: '25%',
        textAlign: 'left',
        fontSize: window.innerWidth < 310 ? pxToEm(13.5) : window.innerWidth < 375 && pxToEm(13.5)
        // display: 'flex',
        // justifyContent: 'flex-start'
    },
    qtyBox: {
        width: '37%',
        textAlign: 'right',
        // fontFamily: 'ui-monospace',
        // fontFamily: "'Roboto Mono', monospace",
        fontFamily: "Courier-Bold",
        letterSpacing: '.5px',
        // fontWeight: 500,
        // transform: 'scale(1.2,)',
        fontSize: window.innerWidth < 310 ? pxToEm(13.7) : window.innerWidth < 375 && pxToEm(14),
        // color: grey
        // display: 'flex',
        // justifyContent: 'flex-end'
    },
    plantBoxTotal: {
        width: '25%',
        textAlign: 'left',


        fontSize: window.innerWidth < 310 ? pxToEm(13.8) : window.innerWidth < 375 && pxToEm(13.8)
    },
    qtyBoxTotal: {
        width: '37%',
        textAlign: 'right',
        // fontFamily: 'ui-monospace',
        // fontFamily: "'Roboto Mono', monospace",
        fontFamily: "Courier-Bold",
        letterSpacing: '.7px',
        fontWeight: 600,
        // transform: 'scale(1.2,)',
        // borderTop: '1px solid grey',
        // color: grey,
        fontSize: window.innerWidth < 310 ? pxToEm(13.7) : window.innerWidth < 375 && pxToEm(14)
    }
};


export class PendingDispatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readyQtyValue: []

        };
    }

    handleDispatchPush = () => {
        this.props.history.push("/dispatch/create");
    }

    handleTotalSumCalculation = (arr, item, fieldName) => {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            let categoryData = arr[i].category;
            for (let j = 0; j < categoryData.length; j++) {
                let itemData = categoryData[j]
                if (itemData.item_category === item) {
                    sum = sum + itemData[`${fieldName}`]
                }
            }
        }
        return sum.toFixed(3);
        // return String(sum).match(/^\d*\.?\d{0,3}$/) ? sum : String(sum).toFixed(3)

        // if (arr) {
        //     let sum = arr?.reduce((a, c) => {
        //         return Number(a) + Number(c[`${fieldName}`] !== undefined ? c[`${fieldName}`] : 0)
        //     }, 0)
        //     return String(sum).match(/^\d*\.?\d{0,3}$/) ? sum : sum.toFixed(3)
        // }
        // return 0;
    }

    componentDidMount() {
        // console.log("here")
        // if (prevProps?.pendingDispatchList !== this.props.pendingDispatchList) {
        if (this.props.pendingDispatchList?.length > 0 && this.state.readyQtyValue.length === 0) {
            // console.log('hererere')
            let readyQtyValue = [];
            let filterDataPlant = (this.props.pendingDispatchList).filter(item => item.category.length > 0)
            for (let i = 0; i < filterDataPlant.length; i++) {
                // console.log(i, this.state.readyQtyValue)
                let data = filterDataPlant[i].category
                let datafilter = data.filter(d => Number(d.ready_quantity) !== Number(0))
                // console.log(datafilter)
                if (datafilter.length > 0) {
                    datafilter.map(d => readyQtyValue.push(d.item_category))
                    // readyQtyValue.push(datafilter.map(d => { return d.item_category }))
                    // console.log("drf", readyQtyValue)
                    this.setState({
                        readyQtyValue: readyQtyValue
                    },)
                    // () => console.log(this.state.readyQtyValue))

                }
            }
        }
    }


    render() {
        const {
            classes,
            isMobile,
            subscribedDailyRates,
            subscribedDailyRatesLoader,
            dailyRatesLastUpdatedAt,
            pendingDispatchList,
            pendingDispatchListLoader,
            pendingItemCategories,
        } = this.props;
        const { readyQtyValue } = this.state;
        // console.log(this.props)
        return (
            <>
                {pendingItemCategories && pendingItemCategories?.length > 0 ?
                    (<div style={styles.pendingDispatchesContainer(isMobile)}>
                        <div style={styles.pendingDispatchMessage(isMobile)}>

                            {pendingItemCategories.map((item, index) =>
                                // console.log(item)
                                <div style={{ marginBottom: 10 }}>
                                    <div className={classes.plantNameStyle}>
                                        {item}
                                    </div>
                                    <div className={classes.plantNameValueStyle}>
                                        <span style={styles.plantBoxTitle}>Plant</span>
                                        <span style={styles.qtyBoxTitle}>
                                            {readyQtyValue?.includes(String(item)) &&
                                                'Ready Qty'
                                            }
                                        </span>
                                        <span style={styles.qtyBoxTitle}>Pending Qty</span>
                                    </div>
                                    {
                                        pendingDispatchList ?
                                            pendingDispatchList.map(pending =>
                                                pending.category.length > 0 ?
                                                    pending.category.map(cat =>
                                                        (cat.item_category === item) ?
                                                            cat.pending !== 0 &&
                                                            <div className={classes.plantNameValueStyle}>
                                                                <span style={styles.plantBox}>{pending.plant_short_name} </span>
                                                                <span style={styles.qtyBox}>
                                                                    {(cat?.ready_quantity !== 0
                                                                        ? <span
                                                                            style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handleDispatchPush()
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={styles.clickHereToOrderCircle}
                                                                            />
                                                                            <span>{" " + cat?.ready_quantity + ' MT' || ''}</span>
                                                                        </span>

                                                                        : ""
                                                                    )}
                                                                </span>
                                                                <span style={styles.qtyBox}>
                                                                    {cat?.pending !== 0
                                                                        ? (cat?.pending).toFixed(3) + ' MT' || ''
                                                                        : ""
                                                                    }
                                                                </span>
                                                            </div> : null

                                                    ) : null
                                            ) : null
                                    }

                                    <div className={classes.plantNameValueStyle}>
                                        <span style={styles.plantBoxTotal}></span>
                                        <span style={styles.qtyBoxTotal}></span>
                                        {/* {(readyQtyValue[0])?.includes(String(item)) &&
                                            <span style={styles.qtyBoxTotal}>
                                                {
                                                    (this.handleTotalSumCalculation(pendingDispatchList, item, 'ready_quantity') === NaN ||
                                                        this.handleTotalSumCalculation(pendingDispatchList, item, 'ready_quantity') == 0
                                                        ? ''
                                                        : this.handleTotalSumCalculation(pendingDispatchList, item, 'ready_quantity') + ' MT'
                                                        || '')
                                                }
                                            </span>
                                        } */}
                                        <span style={styles.qtyBoxTotal}>
                                            {
                                                (this?.handleTotalSumCalculation(pendingDispatchList, item, 'pending') === NaN ||
                                                    this?.handleTotalSumCalculation(pendingDispatchList, item, 'pending') == 0
                                                    ? ""
                                                    : this?.handleTotalSumCalculation(pendingDispatchList, item, 'pending') + ' MT' || '')
                                            }
                                        </span>
                                    </div>

                                </div>
                            )
                            }
                        </div>
                    </div>)
                    : null
                }
            </>
        )
    }
}

export default PendingDispatches