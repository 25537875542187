import React, { useEffect, useState } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import ItemCategoryDetailsComponent from "../../../../components/internal/masterManagement/itemCategory/ItemCategoryDetailsComponent";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import AccessDenied from "../../../../components/common/AccessDenied";

// container for Master Management -> RateTemplate Category -> View
const ItemCategoryDetails = (props) => {
  const { user, isMobile } = props;
  const [AllCategories, setAllCategories] = useState([]);
  const [FetchingRateTemplate, setFetchingRateTemplate] = useState(true);
  const [FetchingItemType, setFetchingItemType] = useState(true);
  const [FetchingRateLinking, setFetchingRateLinking] = useState(true);
  const [RateTemplate, setRateTemplate] = useState(null);
  const [RateLinking, setRateLinking] = useState(null);
  const [StateList, setStateList] = useState([]);
  const [ItemType, setItemType] = useState(null);

  const getAllCateogries = async (options) => {
    axios.get(APIROUTES.DAILY_RATE_TEMPLATE_GET, options).then(
      (response) => {
        let result = response.data;
        console.log("response ", result);
        setAllCategories(result);
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  const getCategoryDetails = (options, id) => {
    axios.get(APIROUTES.DAILY_RATE_TEMPLATE_DETAILS + id + "/", options).then(
      (response) => {
        let result = response.data;
        setRateTemplate(result);
        if (result.related_to) {
          getRateLinking(options, result.related_to);
        } else {
          setFetchingRateLinking(false);
        }
        setFetchingRateTemplate(false);
      },
      (error) => {
        console.log("error", error);
        setFetchingRateTemplate(false);
        setFetchingRateLinking(false);
      }
    );
  };

  const getItemTypes = async (options, id) => {
    if (!FetchingItemType) setFetchingItemType(true);
    try {
      const { data } = await axios.get(
        APIROUTES.MM_ITEM_TYPE_GET + id,
        options
      );
      setItemType(data);
      setFetchingItemType(false);
    } catch (err) {
      console.log("err ", err);
      setFetchingItemType(false);
    }
  };

  const getRateLinking = async (options, id) => {
    try {
      let { data } = await axios.get(
        APIROUTES.DAILY_RATE_TEMPLATE_DETAILS + id + "/",
        options
      );
      setRateLinking({ name: data.category_name });
      setFetchingRateLinking(false);
    } catch (err) {
      console.log("err in rate link ", err);
      setFetchingRateLinking(false);
    }
  };

  const getStateList = async (options) => {
    try {
      let { data } = await axios.get(APIROUTES.DAILY_RATES_STATE, options);
      setStateList(data);
    } catch (err) {
      console.log("err in rate link ", err);
    }
  };

  useEffect(() => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };
      const id =
        props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ];
      getAllCateogries(options);
      getStateList(options);
      getCategoryDetails(options, id);
      getItemTypes(options, id);
    }
  }, [user]);

  // methods for component

  const postToRateTemplateTable = async (data) => {
    const options = {
      headers: {
        Authorization: `JWT ${props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    try {
      setFetchingRateTemplate(true);
      let response = await axios.post(
        APIROUTES.DAILY_RATE_TEMPLATE_CREATE,
        data,
        options
      );
      getCategoryDetails(options, data.item_category);
    } catch (err) {
      console.log("err ", err);
      getCategoryDetails(options, data.item_category);
    }
  };

  const postToItemTypeTable = async (data) => {
    setFetchingItemType(true);
    new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `JWT ${props.user.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      axios
        .post(APIROUTES.MM_ITEM_TYPE_POST, data, options)
        .then((response) => {
          getItemTypes(options, data.item_category);
          resolve(response);
        })
        .catch((err) => {
          console.log("err in postToItemTypeTable ", err);
          reject();
        })
        .finally(() => {
          setFetchingRateTemplate(false);
        });
    });
  };

  const putRateLinkingData = async (data, id) => {
    setFetchingRateLinking(true);
    new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `JWT ${props.user.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      axios
        .put(`${APIROUTES.DAILY_RATE_TEMPLATE_DETAILS}${id}/`, data, options)
        .then((response) => {
          getRateLinking(options, id);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          setFetchingRateLinking(false);
        });
    });
  };

  // render

  if (isMobile) return <OpenInDesktop {...this.props} />;

  if (!user.is_admin && user.status !== "approved") {
    return <AccessDenied />;
  }

  return (
    <div>
      <ItemCategoryDetailsComponent
        FetchingItemType={FetchingItemType}
        FetchingRateTemplate={FetchingRateTemplate}
        FetchingRateLinking={FetchingRateLinking}
        RateTemplate={RateTemplate}
        ItemType={ItemType}
        RateLinking={RateLinking}
        StateList={StateList}
        postToRateTemplateTable={postToRateTemplateTable}
        postToItemTypeTable={postToItemTypeTable}
        AllCategories={AllCategories}
        putRateLinkingData={putRateLinkingData}
        {...props}
      />
    </div>
  );
};

export default ItemCategoryDetails;
