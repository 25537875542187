import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../../styles/b2b/B2bEditProfile.css";
import TextField from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { primaryColor } from "../../../styles/colors";

const styles = {
  b2bEditProfileMainBody: {
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    padding: "20px",
    overflow: "scroll",
  },
  textFieldStyle: {
    width: "93%",
  },
  nextButtonStyle: {
    width: "93%",
    height: "56px",
    background: primaryColor,
    color: "white",
    fontWeight: "bold",
  },
};
class B2bEditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.b2bEditProfileMainBody}>
        <div className="b2b-mobile-edit-profile-header-container">
          <div className="b2b-mobile-edit-profile-image-container">
            <img
              src={
                window.cordova
                  ? `${this.image}${"/RealDealWeb2.png"}`
                  : "/RealDealWeb2.png"
              }
              width="100%"
              height="auto"
              alt="img"
            />
          </div>
        </div>
        <div>
          <span>Company Details</span>
        </div>
        <br />
        <div className="b2b-mobile-edit-profile-form-start-container">
          <TextField
            key="name"
            name="name"
            id="outlined-name"
            label="Company Name"
            defaultValue="XYS PVT LTD."
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          />
          <TextField
            key="gstno"
            name="GstNo"
            id="outlined-name"
            label="GSTIN"
            defaultValue="SHJSH8787JHHJ"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          />
          <TextField
            key="phone"
            name="phoneNumber"
            id="outlined-name"
            label="Phone Number"
            defaultValue="881234555"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          />
          <br />
          <div className="b2b-mobile-edit-profile-add-more-number-container">
            <span className="b2b-mobile-edit-profile-add-more-number">
              <Add style={{ marginRight: "10px" }} /> Add more Contact Number
            </span>
          </div>
          <br />
          <TextField
            key="email"
            name="email"
            id="outlined-name"
            label="Email Adress"
            defaultValue="XYS@YY.com"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          />
          <TextField
            key="address"
            name="address"
            id="outlined-name"
            label="Company Address"
            defaultValue="Civil lines , Raipur , 45667"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          />
          <TextField
            key="pincode"
            name="pincode"
            id="outlined-name"
            label="Pincode"
            defaultValue="497889"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          />
          <br />
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
            variant="contained"
            color="primary"
            className={classes.nextButtonStyle}
          >
            Next
          </Button>
          <br />
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(B2bEditProfile);
