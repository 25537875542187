import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import Close from "@material-ui/icons/Close";
import Terms from "../Terms";

class TermsAndConditions extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<Close items />}
        //appBarTitle="Terms and conditions"
      >
        <Terms isMobile {...this.props} />
      </MobileLayout>
    );
  }
}
export default TermsAndConditions;
