import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../../styles/b2b/B2bProfile.css";
import Button from "@material-ui/core/Button";
import { primaryColor } from "../../../styles/colors";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { withRouter } from "react-router";
import backStack from "cordova-back-stack";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import withAuthentication from "../../../hoc/withAuthentication";
import { pxToEm } from "../../../methods";
import StaticVersionDisplay from "../../common/StaticVersionDisplay";
import { Divider, MenuItem, MenuList } from "@material-ui/core";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const styles = {
  b2bProfileMainBody: {
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    //paddingTop: "30px",
  },
  backButtonStyle: {
    height: "100%",
    width: "45%",
    borderRadius: "0px 25px 0px 0px",
    backgroundColor: primaryColor,
  },
  b2bProfileInternalBody: {
    height: window.innerHeight - 74,
    padding: "30px 20px 0px 20px",
    overflowY: "scroll",
  },
  manageBuyerButton: {
    textTransform: "capitalize",
    fontSize: "1.4em",
    color: primaryColor,
    marginLeft: "3%",
  },
  logOutButtonStyle: {
    height: window.innerWidth < 375 ? "65%" : "90%",
    width: window.innerWidth < 375 ? "50%" : "55%",
    color: "white",
    fontWeight: "bold",
    backgroundColor: primaryColor,
    fontSize: window.innerWidth < 375 && pxToEm(13)
  },
  menuListStyle: {
    borderRadius: 10,
    color: primaryColor
    // padding: '6px',
    // margin: '10px 0',
    // backgroundColor: '#FFF',
    // boxShadow:
    // "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  menuItemStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10
  },
  menuItemText: {
    fontSize: "1em",
    fontWeight: 500,
    color: primaryColor
  }
};
class B2bProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      this.props.history.goBack();
    });
  }

  componentDidMount() {
    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = '/home';
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
  }

  render() {
    const { classes, user } = this.props;
    // console.log(this.props, user)
    return (
      <div className={classes.b2bProfileMainBody}>
        <div className={classes.b2bProfileInternalBody}>
          <div className="b2b-mobile-profile-header-container">
            <div className="b2b-mobile-profile-header-left-section">
              <div className="b2b-mobile-profile-header-left-image-container">
                <img
                  src={
                    window.cordova
                      ? `${this.image}${"/RealDealWeb2.png"}`
                      : "/RealDealWeb2.png"
                  }
                  style={{
                    height: "100%",
                    width: "auto",
                    objectFit: "contain",
                    // borderRadius: 12 
                  }}
                  alt="img"
                />
              </div>
              <span className="b2b-mobile-profile-user-name-text-style">
                {user.first_name} {user.last_name}
              </span>
              {/* <span
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile/settings/editProfile")
                }}
              >
                <Edit />
              </span>*/}
            </div>

          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "12px",
              marginTop: "10px",
            }}
          >
            <span style={{ color: "#8b959a", paddingLeft: 10 }}>Registered under </span>
            <span style={{ color: "#35425a", paddingLeft: 10, fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.4em" }}>
              {user.gst_information ? user.gst_information.trade_name : null}
            </span>
          </div>
          <div className="b2b-mobile-profile-email-container">
            <span className="b2b-mobile-profile-email-text" style={{ paddingLeft: 10 }}>
              UID
            </span>
            <span className="b2b-mobile-profile-email" style={{ fontSize: window.innerWidth < 375 && pxToEm(16), paddingLeft: 10 }}>
              {user.employee_id}
            </span>
          </div>
          <div className="b2b-mobile-profile-email-container">
            <span className="b2b-mobile-profile-email-text" style={{ paddingLeft: 10 }}>
              Email
            </span>
            <span className="b2b-mobile-profile-email" style={{ fontSize: window.innerWidth < 375 && pxToEm(16), paddingLeft: 10 }}>
              {user.email}
            </span>
          </div>

          <div className="b2b-mobile-profile-email-container">
            <span className="b2b-mobile-profile-email-text" style={{ paddingLeft: 10 }}>Contact</span>
            <span className="b2b-mobile-profile-email" style={{ fontSize: window.innerWidth < 375 && pxToEm(16), paddingLeft: 10 }}>
              {user.phone ? `+91 ${user.phone}` : "-"}
            </span>
          </div>

          <div className="b2b-mobile-profile-email-container">
            <span className="b2b-mobile-profile-email-text" style={{ paddingLeft: 10 }}>
              {user.gst_information ? "GSTIN" : "PAN"}</span>
            <span className="b2b-mobile-profile-email" style={{ fontSize: window.innerWidth < 375 && pxToEm(16), paddingLeft: 10 }}>
              {user?.gst_information ? user?.gst_information?.gstin_number
                : user?.pan_card_information?.pan_card_number
              }
            </span>
          </div>

          <div className="b2b-mobile-profile-email-container">
            <span className="b2b-mobile-profile-email-text" style={{ paddingLeft: 10 }}>Address</span>
            <span className="b2b-mobile-profile-email" style={{ fontSize: window.innerWidth < 375 && pxToEm(16), paddingLeft: 10 }}>
              {
                user?.address?.address ?
                  `${user?.address?.address} - ${user?.address?.pincode}`
                  :
                  `${user?.address?.door_number && `#${user?.address?.door_number},`}
                      ${user?.address?.floor_number &&
                  `${user?.address?.floor_number === 0 ? null :
                    user?.address?.floor_number}${user?.address?.floor_number === "0" ?
                      null : user?.address?.floor_number === "1" ? "st" :
                        user?.address?.floor_number === "2" ? "nd" : "rd"
                  } floor,`}
                      ${user?.address?.building_name && `,${user?.address?.building_name},`}
                      ${user?.address?.street && `${user?.address?.street},`}
                      ${user?.address?.location && `${user?.address?.location},`}
                      ${user?.address?.city_name && `${user?.address?.city_name}`}
                      ${user?.address?.pincode && `-${user?.address?.pincode}`}`
              }
            </span>
          </div>
          <Divider style={{ margin: '0 10px' }} />
          <div
          // style={{ margin: '15px 0' }}
          >
            <MenuList style={styles.menuListStyle}>
              <MenuItem
                style={styles.menuItemStyle}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.fetchLedgerRequest()
                  this.props.history.push("/b2bProfile/ledger")
                }}
              >
                <span style={styles.menuItemText}>
                  Ledger Request
                </span>
                <KeyboardArrowRightOutlinedIcon />
              </MenuItem>
            </MenuList>
          </div>
          <div
          // style={{ margin: '15px 0' }}
          >
            <MenuList style={styles.menuListStyle}>
              <MenuItem
                style={styles.menuItemStyle}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile/settings/changePassword");
                }}
              >
                <span style={styles.menuItemText}>
                  Change Password
                </span>
                <KeyboardArrowRightOutlinedIcon />
              </MenuItem>
              {/* <Divider variant="middle" /> */}
              <MenuItem
                style={styles.menuItemStyle}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.logout();
                }}
              >
                <span style={styles.menuItemText}>
                  Logout
                </span>
                <KeyboardArrowRightOutlinedIcon />
              </MenuItem>
            </MenuList>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "56px",
              marginTop: "15px",
              fontSize: 20
            }}
          >
            <StaticVersionDisplay />
          </div>
        </div>
        <div
          className="b2b-mobile-profile-bottom-navigation"
          style={{
            height: window.innerWidth < 375 && 50,
            background:
              "transparent linear-gradient(180deg, #F5F8FC 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/dailyRates");
            }}
            variant="contained"
            color="primary"
            className={classes.backButtonStyle}
          >
            <KeyboardArrowLeft /> Back
          </Button>
        </div>
      </div>
    );
  }
}
export default withAuthentication(withRouter(withStyles(styles)(B2bProfile)));
