import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  secondaryColor,
} from "../../../styles/colors";
import moment from "moment";
import { checkInvalidValue, pxToEm } from "../../../methods";

const styles = {
  createDispatchReviewAndSubmitContainer: {
    width: "100%",
    height: window.innerHeight - 126,
    maxHeight: window.innerHeight - 126,
    padding: "0px 20px 80px 20px",
    overflowY: "scroll",
  },
  reviewAndSubmitTextContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  },
  reviewAndSubmitText: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.19em",
    fontWeight: 500,
  },
  cardBox: {
    boxShadow:
      "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "1.9em",
    padding: "12px",
    borderRadius: "10px",
    marginBottom: "20px",
  },
  plantNameText: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(13.6) : "1.1em",
  },
  gateInDateContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // color: bottomNavigationFontColor,
    fontSize: "1em",
    padding: "0px 5px",
  },
  buyerText: {
    color: secondaryColor,
    fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.2em",
    marginBottom: "10px",
  },
  overviewValueContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // color: bottomNavigationFontColor,
    marginTop: "10px",
    fontSize: "1em",
    padding: "0px 5px",
  },
  LAValueContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // color: bottomNavigationFontColor,
    marginTop: "10px",
    fontSize: "1em",
    // padding: "0px 40px 0px 5px",
  },
  singleBlockContainer: {
    width: "100%",
    padding: "10px 0px",
  },
  textAlignStart: {
    width: '45%',
    textAlign: 'start',
    fontSize: window.innerWidth < 375 && pxToEm(13.6)
  },
  textAlignEnd: {
    width: '50%',
    textAlign: 'end',
    fontSize: window.innerWidth < 375 && pxToEm(13.6)
  }
};
class CreateDispatchReviewAndSubmit extends Component {
  render() {
    const {
      classes,
      gateInDate,
      selectedBuyer,
      selectedConsignee,
      paymentMethod,
      transporterContactNumber,
      transporterName,
      truckCapacity,
      truckNumber,
      driverContactNumber,
      driverName,
      setOrderId,
      selectedPlant,
      setOrderAndItem,
      selectedOrderQuantity,
      selectedItem,
      selectedOrderItemCategory,
      selectedPlantPendingQuantity,
      driverLicenseNumber,
      totalLAItemsQuantity,
      isMobile,
      selectedTransType,
      selectedTransTypeName,
      customerRemark,
      selectedSubContractDetail,
    } = this.props;
    // console.log(truckNumber, truckCapacity)
    // console.log(
    //   "driverContactNumber: ", driverContactNumber,
    //   "driverContactNumber: ", driverContactNumber, "driverLicenseNumber: ", driverLicenseNumber)
    return (
      <div className={classes.createDispatchReviewAndSubmitContainer}>
        <div className={classes.reviewAndSubmitTextContainer}>
          <span className={classes.reviewAndSubmitText}>Review and Submit</span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            The date at which your truck will be reach at the point
          </span>
        </div>
        <div className={classes.cardBox}>
          <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
            <span className={classes.plantNameText}>
              {selectedPlant ? selectedPlant.plant_short_name : "-"}
            </span>
            <span
              style={{
                // color: bottomNavigationFontColor, 
                fontSize: window.innerWidth < 375 ? pxToEm(13.6) : "0.9em"
              }}
            >
              {selectedOrderItemCategory ? selectedOrderItemCategory : "-"}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", width: "40%", }}>
            <span className={classes.plantNameText}>
              {totalLAItemsQuantity ? `${totalLAItemsQuantity}` : "-"} MT

            </span>
          </div>
        </div>
        <div
          style={{ lineHeight: "2.8em", width: "100%", padding: "10px 0px" }}
        >
          <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.2em" }}>
            Overview
          </span>
          <div className={classes.gateInDateContainer}>
            <span style={styles.textAlignStart}>Gate in date</span>
            <span style={styles.textAlignEnd}>{moment(gateInDate).format("DD-MMM-yyyy")}</span>
          </div>

          <div className={classes.gateInDateContainer}>
            <span style={styles.textAlignStart}>Trans Type</span>
            <span style={styles.textAlignEnd}>{selectedTransTypeName || ""}</span>
          </div>
        </div>
        <div style={styles.singleBlockContainer}>
          <span className={classes.buyerText}>
            Loading advice and particulars
          </span>
          <div className={classes.LAValueContainer}>
            <span style={styles.textAlignStart}>Contract Number</span>
            <span style={styles.textAlignEnd}>{setOrderId}</span>
          </div>
          {
            !checkInvalidValue(selectedSubContractDetail?.subcontract_number) &&
            <div className={classes.LAValueContainer}>
              <span style={styles.textAlignStart}>SubContract Number</span>
              <span style={styles.textAlignEnd}>{selectedSubContractDetail?.subcontract_number}</span>
            </div>
          }
          {
            setOrderAndItem ?
              Array.from(setOrderAndItem.entries()).map(([key, value]) =>

                value.map(data =>
                  <div className={classes.LAValueContainer}>
                    <span style={styles.textAlignStart}>{data.item}</span>
                    <span style={styles.textAlignEnd}>{data.quantity} MT</span>
                  </div>
                )) : null
          }
          <div className={classes.LAValueContainer}>
            <span style={styles.textAlignStart}>Customer Remark</span>
            <span style={styles.textAlignEnd}>{customerRemark}</span>
          </div>

          {/* <div className={classes.LAValueContainer}>
            <span>10 mm</span>
            <span>20 MT</span>
          </div> */}
        </div>
        <div style={styles.singleBlockContainer}>
          <span className={classes.buyerText}>Buyer</span>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Name</span>
            <span style={styles.textAlignEnd}>{selectedBuyer ? `${selectedBuyer.first_name} ${selectedBuyer.last_name}` : "-"} </span>
          </div>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>{selectedBuyer &&
              selectedBuyer.gstin_number ? "GSTIN" : "PAN"}</span>
            <span style={styles.textAlignEnd}>{selectedBuyer &&
              selectedBuyer.gstin_number ? selectedBuyer.gstin_number :
              selectedBuyer && selectedBuyer.pan_card_number
            }</span>
          </div>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Contact</span>
            <span style={styles.textAlignEnd}>{selectedBuyer && selectedBuyer.phone}</span>
          </div>
        </div>
        <div style={styles.singleBlockContainer}>
          <span className={classes.buyerText}>Consignee</span>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Name</span>
            <span style={styles.textAlignEnd}>
              {selectedConsignee
                ? selectedConsignee?.consignee_detail
                  ? `${selectedConsignee?.consignee_detail?.first_name} ${selectedConsignee?.consignee_detail?.last_name}`
                  : `${selectedConsignee?.first_name} ${selectedConsignee?.last_name}`
                : "-"
              }
            </span>
          </div>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>
              {
                selectedConsignee &&
                  selectedConsignee?.consignee_detail
                  ? selectedConsignee.consignee_detail.gstin_number ? "GSTIN" : "PAN"
                  : selectedConsignee?.gstin_number ? "GSTIN" : "PAN"
              }</span>
            <span style={styles.textAlignEnd}>
              {
                selectedConsignee &&
                  selectedConsignee?.consignee_detail
                  ? selectedConsignee?.consignee_detail?.gstin_number
                    ? selectedConsignee?.consignee_detail?.gstin_number
                    : selectedConsignee && selectedConsignee?.consignee_detail?.pan_card_number
                  : selectedConsignee?.gstin_number
                    ? selectedConsignee?.gstin_number
                    : selectedConsignee && selectedConsignee?.pan_card_number
              }
            </span>

          </div>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Contact</span>
            <span style={styles.textAlignEnd}>{selectedConsignee && selectedConsignee?.consignee_detail?.phone}</span>
          </div>
        </div>
        <div style={styles.singleBlockContainer}>
          <span className={classes.buyerText}>Payment</span>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Payment Mode</span>
            <span style={styles.textAlignEnd}>{paymentMethod && paymentMethod}</span>
          </div>
          {
            !checkInvalidValue(selectedSubContractDetail?.payment_doc_number) &&
            <>
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Payment Doc No</span>
                <span style={styles.textAlignEnd}>
                  {selectedSubContractDetail?.payment_doc_number}
                </span>
              </div>
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Payment Doc Date</span>
                <span style={styles.textAlignEnd}>
                  {moment(selectedSubContractDetail?.payment_doc_date).format('DD-MMM-YYYY')}
                </span>
              </div>
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Payment Bank Name</span>
                <span style={styles.textAlignEnd}>
                  {selectedSubContractDetail?.payment_bank_name}
                </span>
              </div>
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Payment Doc Value</span>
                <span style={styles.textAlignEnd}>
                  {selectedSubContractDetail?.payment_doc_value}
                </span>
              </div>
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Payment Doc Valid Upto</span>
                <span style={styles.textAlignEnd}>
                  {moment(selectedSubContractDetail?.payment_doc_valid_upto).format('DD-MMM-YYYY')}
                </span>
              </div>
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Payment Doc Remark</span>
                <span style={styles.textAlignEnd}>
                  {selectedSubContractDetail?.payment_doc_remark}
                </span>
              </div>
            </>
          }
        </div>
        <div style={styles.singleBlockContainer}>
          <span className={classes.buyerText}>Transport details</span>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Name</span>
            <span style={styles.textAlignEnd}>{transporterName === 'Not Available' ? "" : transporterName}</span>
          </div>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Contact</span>
            <span style={styles.textAlignEnd}>{transporterContactNumber === 'Not Available' ? "" : transporterContactNumber}</span>
          </div>
        </div>
        <div style={styles.singleBlockContainer}>
          <span className={classes.buyerText}>Truck Details</span>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Truck Number</span>
            <span style={styles.textAlignEnd}>{truckNumber}</span>
          </div>
          <div className={classes.overviewValueContainer}>
            <span style={styles.textAlignStart}>Truck capacity</span>
            <span style={styles.textAlignEnd}>{truckCapacity} MT</span>
          </div>
        </div>
        {driverContactNumber || driverName || driverLicenseNumber ?
          <div style={{
            width: "100%",
            paddingTop: "10px",
            paddingBottom: isMobile ? "10px" : "65px"
          }}>
            <span className={classes.buyerText}>Driver Details</span>
            {!driverName || driverName === "" ? null :
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Driver Name</span>
                <span style={styles.textAlignEnd}>{driverName}</span>
              </div>
            }
            {!driverContactNumber || driverContactNumber === "" ? null :
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Driver Contact</span>
                <span style={styles.textAlignEnd}>{driverContactNumber}</span>
              </div>
            }
            {!driverLicenseNumber || driverLicenseNumber === "" ? null :
              <div className={classes.overviewValueContainer}>
                <span style={styles.textAlignStart}>Driver Licence Number</span>
                <span style={styles.textAlignEnd}>{driverLicenseNumber}</span>
              </div>
            }
          </div> : null
        }

      </div>
    );
  }
}
export default withStyles(styles)(CreateDispatchReviewAndSubmit);
