import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Chip,
  Select,
  Input,
  MenuItem,
  Tooltip,
  FormControl,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import DisplayPastRatesData from "./DisplayPastRatesData";
import {
  whiteSmoke,
  primaryColor,
  grey,
  secondaryBlueText,
} from "../../../../constants/internal/colors";
import { pxToEm, isPastRateFilterDateValid } from "../../../../methods";
import axios from "axios";
import SnackbarComponent from "../../common/SnackbarComponent";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import DatePickerComponent from "./DatePickerComponent";
import DatePickerComponentPastRateEndDate from "./DatePickerComponentPastRateEndDate";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
const CustomCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const styles = {
  formControl: {
    display: "flex",
    flex: 4.5,
    fontSize: pxToEm(14),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  chip: {
    margin: 2,
  },
  pastRatesMainDiv: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  pastRatesTitleDiv: {
    padding: 15,
  },
  filterIcon: {
    display: "flex",
    flex: 0.5,
    cursor: "pointer",
  },
  datePickerWidth: {
    borderRadius: "25px",
    backgroundColor: whiteSmoke,
    display: "flex",
    flex: 2.5,

    fontSize: pxToEm(14),
    alignItems: "center",
    justifyContent: "center",
  },
  filterOptionsDiv: {
    display: "flex",
    justifyContent: "flex-end",
    placeItems: "center",
    padding: 10,
    flex: 1,
  },
  fontStyle: {
    fontSize: pxToEm(18),
  },
  blackFontColor: {
    color: "#000",
  },
  loaderDiv: {
    margin: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderStyle: { color: primaryColor },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
  },
  iconContainer: {
    height: 30,
    width: 30,
    background: "yellow",
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: whiteSmoke,
    overflow: "hidden",
    color: grey,
    "&:hover": {
      backgroundColor: whiteSmoke,
      width: 130,
      transition: "width 0.3s",
      cursor: "pointer",
    },
  },
  filterText: {
    flex: 1,
    marginLeft: 5,
    textAlign: "left",
  },
  selectRightArrowIcon: {
    fill: "white",
  },
};

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
class PastRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvPastRateData: [],
      csvPastRateLoader: false,
      pastRateDataToDisplay: [],
      pastRateDataCount: 0,
      pastRatesLoader: false,
      filteredPastRatesLoader: false,
      allPastRateDataToDisplay: [],
      filteredPastRateDataToDisplay: [],
      selectedCategoryData: null,
      selectedCategoryDataLoader: true,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      selectedMultipleCategory: [],
      defaultDisplaySelectedMultipleCategory: ["All/All Categories"],
      selectedMultipleCategoryIdForPastRateDataFilter: [],
      selectedMultipleCategoryNameForPastRateDataFilter: [],
      startDate: null,
      endDate: null,
      pastRatePublishedBy: "(email not found)",
      pastRatePublishedByUID: "",
      pastRatePublishDate: null,
      selectedFilteredCategoryName: null,
      allCategories: [],
      pastRateDataFilterLoader: false,
      displayFilteredPastRates: false,
      page: 0
    };
  }

  componentDidMount() {
    this.initializeData(this.props);
    this.props.getPastRateFilteredData()
  }

  componentWillReceiveProps(nextProps) {
    this.initializeData(nextProps);
  }

  initializeData(whichProps) {
    this.setState({
      pastRatesLoader: whichProps.pastRatesLoader,
      filteredPastRatesLoader: whichProps.filteredPastRatesLoader,
      pastRateDataFilterLoader: false,
      allPastRateDataToDisplay: whichProps.allPastRateDataToDisplay,
      // pastRateDataToDisplay: this.state.displayFilteredPastRates
      //   ? whichProps.filteredPastRateDataToDisplay
      //   : whichProps.allPastRateDataToDisplay,
      pastRateDataToDisplay: whichProps.filteredPastRateDataToDisplay,
      // pastRateDataCount: 
      csvPastRateData: whichProps.csvPastRateData,
      csvPastRateLoader: whichProps.csvPastRateLoader,
    });
    if (whichProps.allCategories && whichProps.allCategories.length > 0) {
      let allCategories = this.removeDuplicateData(whichProps.allCategories);
      this.setState({ allCategories });
    }
  }
  handleChangePage = (newPage) => {
    this.setState(
      {
        page: newPage,
        pastRateDataFilterLoader: true
      },
      () => this.getFilteredPastRateData(),
    );
  };

  removeDuplicateData = (data) => {
    let uniq = {};
    return data.filter(
      (obj) => !uniq[obj.categoryId] && (uniq[obj.categoryId] = true)
    );
  };
  handleChangeSelected = async (event) => {
    let tempSelectedMultipleCategory = event.target.value;
    let selectedMultipleCategory = tempSelectedMultipleCategory.filter(
      (e) => e !== "All/All Categories"
    );
    await this.setState({ selectedMultipleCategory });
    let selectedMultipleCategoryIdForPastRateDataFilter = [];
    let selectedMultipleCategoryNameForPastRateDataFilter = [];
    if (selectedMultipleCategory.length > 0) {
      selectedMultipleCategory.map((input, inputIndex) => {
        let fields = input.split("/");
        selectedMultipleCategoryIdForPastRateDataFilter[inputIndex] = fields[0];
        selectedMultipleCategoryNameForPastRateDataFilter[inputIndex] =
          fields[1];
        return 1;
      });
      await this.setState(
        {
          selectedMultipleCategoryIdForPastRateDataFilter,
          selectedMultipleCategoryNameForPastRateDataFilter,
        },
        () => this.getFilteredPastRateData()
      );
    } else {
      await this.setState({
        selectedMultipleCategoryIdForPastRateDataFilter: [],
        selectedMultipleCategoryNameForPastRateDataFilter: [],
      });
      if (!this.state.startDate && !this.state.endDate) {
        this.handleClearFiltersClick();
      } else {
        this.getFilteredPastRateData();
      }
    }
  };
  handleGetSelectedCategoryData = (selectedCategory, date) => {
    this.setState({
      selectedCategoryDataLoader: true,
      selectedCategoryData: null,
    });
    axios
      .get(
        APIROUTES.DAILY_RATE_PAST_RATE_SINGLE_SELECTION_V2 +
        `?id=${selectedCategory}&date=${moment(date).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: `JWT ${this.props.user.token}`,
          },
        }
      )
      .then(
        (response) => {
          if (response.status === 200) {
            let publishData = response.data;
            let emailNotFound = "(email not found)";
            if (publishData && publishData.length > 0) {
              publishData.map((data) => {
                if (data.is_base_rate === true) {
                  let publishEmail = data.created_by.email;
                  let publishUID = data.created_by.employee_id;
                  let publishDate = data.created_at;
                  let selectedFilteredCategoryName =
                    data.item_category.category_name;
                  if (!publishEmail) {
                    this.setState({
                      pastRatePublishedBy: emailNotFound,
                      pastRatePublishedByUID: "",
                      pastRatePublishDate: publishDate,
                      selectedFilteredCategoryName,
                    });
                  } else {
                    this.setState({
                      pastRatePublishedBy: publishEmail,
                      pastRatePublishedByUID: publishUID,
                      pastRatePublishDate: publishDate,
                      selectedFilteredCategoryName,
                    });
                  }
                }
                return 1;
              });
            } else {
              this.setState({ pastRatePublishedBy: emailNotFound, pastRatePublishedByUID: "", });
            }
            this.setState({
              selectedCategoryData: publishData,
              selectedCategoryDataLoader: false,
            });
          } else {
            this.setState({
              selectedCategoryDataLoader: false,
              pastRatePublishedBy: null,
              pastRatePublishedByUID: "",
              pastRatePublishDate: null,
              selectedFilteredCategoryName: null,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching selected categories data",
              snackbarType: "error",
            });
          }
        },
        (error) => {
          this.setState({
            selectedCategoryDataLoader: false,
            pastRatePublishedBy: null,
            pastRatePublishedByUID: "",
            pastRatePublishDate: null,
            selectedFilteredCategoryName: null,
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching selected categories data",
            snackbarType: "error",
          });
        }
      );
  };
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  handleDateChange = (setDateFor) => async (date) => {
    if (date !== "Invalid Date") {
      await this.setState({ [setDateFor]: date }, () =>
        this.getFilteredPastRateData()
      );
    } else {
      if (
        !this.state.selectedMultipleCategoryIdForPastRateDataFilter &&
        !this.state.startDate &&
        !this.state.endDate
      ) {
        this.handleClearFiltersClick();
      } else {
        this.getFilteredPastRateData();
      }
    }
  };
  handleClearFiltersClick = () => {
    this.setState({
      selectedMultipleCategory: [],
      selectedMultipleCategoryIdForPastRateDataFilter: [],
      selectedMultipleCategoryNameForPastRateDataFilter: [],
      startDate: null,
      endDate: null,
      displayFilteredPastRates: false,
      pastRateDataToDisplay: this.state.allPastRateDataToDisplay,
    });
  };
  getFilteredPastRateData = async () => {
    this.setState({
      filteredPastRatesLoader: true,
      displayFilteredPastRates: true,
      pastRatesLoader: true
    });
    await this.props.getPastRateFilteredData(
      this.state.selectedMultipleCategoryIdForPastRateDataFilter
        ? this.state.selectedMultipleCategoryIdForPastRateDataFilter
        : null,
      isPastRateFilterDateValid(
        moment(this.state.startDate).format("YYYY-MM-DD")
      )
        ? this.state.startDate
        : null,
      isPastRateFilterDateValid(moment(this.state.endDate).format("YYYY-MM-DD"))
        ? this.state.endDate
        : null,
      this.state.page
    );
    this.setState({
      filteredPastRatesLoader: false,
      displayFilteredPastRates: false,
      pastRatesLoader: false
    });

  };


  render() {
    const {
      pastRatesLoader,
      pastRateDataToDisplay,
      csvPastRateData,
      csvPastRateLoader,
      openSnackbar,
      snackbarMessage,
      snackbarType,
      selectedCategoryData,
      selectedMultipleCategory,
      defaultDisplaySelectedMultipleCategory,
      startDate,
      endDate,
      pastRatePublishedBy,
      pastRatePublishedByUID,
      pastRatePublishDate,
      selectedCategoryDataLoader,
      selectedFilteredCategoryName,
      allCategories,
      pastRateDataFilterLoader,
      selectedMultipleCategoryIdForPastRateDataFilter,
      displayFilteredPastRates,
      filteredPastRatesLoader,
    } = this.state;
    return (
      <Paper style={styles.pastRatesMainDiv}>
        <div style={styles.pastRatesTitleDiv}>
          <Typography style={styles.fontStyle}>
            <span style={styles.blackFontColor}>Past Rates</span>
          </Typography>
        </div>
        <div style={styles.filterOptionsDiv}>
          <FormControl style={styles.formControl}>
            <Select
              disableUnderline
              multiple
              value={
                selectedMultipleCategory.length > 0
                  ? selectedMultipleCategory
                  : defaultDisplaySelectedMultipleCategory
              }
              MenuProps={MenuProps}
              style={{
                autoWidth: "true",
                backgroundColor: "transparent",
              }}
              size="small"
              onChange={(e) => {
                e.preventDefault();
                this.handleChangeSelected(e);
              }}
              input={<Input />}
              renderValue={(selected) => (
                <div style={styles.chips}>
                  {selected.map((value) => {
                    let fields = value.split("/");
                    return (
                      <Chip
                        size="small"
                        key={fields[0]}
                        label={fields[1]}
                        style={styles.chip}
                        icon={<LayersIcon />}
                      />
                    );
                  })}
                </div>
              )}
            >
              <MenuItem value="All" disabled>
                All Categories
              </MenuItem>
              {allCategories && allCategories.length > 0
                ? allCategories.map((pastRateData) => (
                  <MenuItem
                    key={pastRateData}
                    value={
                      pastRateData.categoryId +
                      "/" +
                      pastRateData.categoryName
                    }
                  >
                    <CustomCheckbox
                      checked={
                        selectedMultipleCategory.indexOf(
                          pastRateData.categoryId +
                          "/" +
                          pastRateData.categoryName
                        ) > -1
                      }
                    />
                    <ListItemText primary={pastRateData.categoryName} />
                  </MenuItem>
                ))
                : null}
            </Select>
          </FormControl>
          <Tooltip title={"Input from date"} placement="top" arrow>
            <div style={styles.datePickerWidth}>
              <DatePickerComponent
                handleDateChange={this.handleDateChange}
                displayDate={startDate}
              />
            </div>
          </Tooltip>
          {!startDate ? null : (
            <Tooltip title={"Input to date"} placement="top" arrow>
              <div style={styles.datePickerWidth}>
                <DatePickerComponentPastRateEndDate
                  handleDateChange={this.handleDateChange}
                  displayDate={endDate}
                  startDate={startDate}
                />
              </div>
            </Tooltip>
          )}
          {selectedMultipleCategoryIdForPastRateDataFilter.length > 0 ||
            startDate !== null ||
            endDate !== null ? (
            pastRateDataFilterLoader ? (
              <CircularProgress disableShrink style={styles.loaderStyle} />
            ) : (
              <div
                style={styles.iconContainer}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleClearFiltersClick();
                }}
              >
                <CancelIcon style={styles.icon} />
                <div style={styles.filterText}>Clear Filters</div>
              </div>
            )
          ) : null}
        </div>
        <div style={styles.pastRatesDisplayDiv}>
          {pastRateDataToDisplay.length > 0 ? (
            <DisplayPastRatesData
              csvPastRateData={csvPastRateData}
              csvPastRateLoader={csvPastRateLoader}
              handleGetSelectedCategoryData={this.handleGetSelectedCategoryData}
              selectedCategoryData={selectedCategoryData}
              pastRatePublishedBy={pastRatePublishedBy}
              pastRatePublishedByUID={pastRatePublishedByUID}
              pastRatePublishDate={pastRatePublishDate}
              selectedFilteredCategoryName={selectedFilteredCategoryName}
              selectedCategoryDataLoader={selectedCategoryDataLoader}
              startDate={startDate}
              endDate={endDate}
              handleChangePage={this.handleChangePage}
              handleCsvDownload={this.handleCsvDownload}
              page={this.state.page}
              pastRatesDataCount={this.props.pastRatesDataCount}
              pastRateDataToDisplay={this.state.pastRateDataToDisplay}
            />
          ) : (
            <div style={styles.loaderDiv}>
              {displayFilteredPastRates ? (
                filteredPastRatesLoader ? (
                  <CircularProgress style={styles.loaderStyle} />
                ) : (
                  <Typography style={styles.loaderStyle}>
                    No past rate data entries found for applied filters. Change
                    filter values and try again.
                  </Typography>
                )
              ) : pastRatesLoader ? (
                <CircularProgress style={styles.loaderStyle} />
              ) : (
                <Typography style={styles.loaderStyle}>
                  No past rate data entries found
                </Typography>
              )}
            </div>
          )}
        </div>
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </Paper>
    );
  }
}
export default PastRates;
