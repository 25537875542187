import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import {
  grey,
  primaryColor,
  drawerTextColor,
} from "../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  dataFields: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridTemplateRows: "repeat(1, 1fr)",
    margin: "20px"
    // grid-column-gap: 0px,
    // grid-row-gap: 0px,
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    textAlign: "center"
  },

  topMargin: { marginTop: 30, width: "100%" },

  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
});
function DisplayTcsMatrixData(props) {
  const classes = useStyles();
  const [tcsRates, setTcsRates] = React.useState([]);
  const [tcsMatrixDisplayData, setTcsDisplayData] = React.useState(
    props?.selectedTcsData || []
  );
  // 
  React.useEffect(() => {
    setTcsDisplayData(props?.selectedTcsData);
    props.getTcsRates().then((response) => {
      if (response.status === 200) {
        setTcsRates(response.data)
      }
    })
      .catch((error) => {
        console.log(error);
      });
  }, [props?.selectedTcsData]);
  const handleCancelButtonClick = () => {
    props.history.push(INTERNALROUTES.TCSMATRIX);
  };
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "TCS Matrix",
                onClick: () => props.history.push(INTERNALROUTES.TCSMATRIX),
              },
              {
                title: props.match.params.id || "",
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                BUYER PAYMENT &gt; 50 L
              </Typography>
              <Typography className={classes.titleLabel}>
                SPECIFIED
              </Typography>
              <Typography className={classes.titleLabel}>
                DECLARATION
              </Typography>
              <Typography className={classes.titleLabel}>
                TO &gt; 10 CRORES
              </Typography>
              <Typography className={classes.titleLabel}>
                TCS RATE
              </Typography>
            </div>
            {tcsMatrixDisplayData ? tcsMatrixDisplayData.map(tcsMa => (
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  {tcsMa.buyer_payment_gt_50l_fy || ""}
                </Typography>
                <Typography className={classes.titleLabel}>
                  {tcsMa.specified_person || ""}
                </Typography>
                <Typography className={classes.titleLabel}>
                  {tcsMa.declaration || ""}
                </Typography>
                <Typography className={classes.titleLabel}>
                  {tcsMa.buyer_turnover_gt_10cr || ""}
                </Typography>
                <Typography className={classes.titleLabel}>
                  {tcsRates?.find(element => element.id == tcsMa.tcs_rate)?.tcs_percent || ""}
                </Typography>
              </div>
            )) : null}
            <ToolTipComponent
              title="You have no create permission on this widget"
              condition={tcsMatrixDisplayData.length == 0 && !hasWriteAccess(props.user, 'tcs_matrix')}
              title2="You have no edit permission on this widget"
              condition2={tcsMatrixDisplayData.length > 0 && !hasUpdateAccess(props.user, 'tcs_matrix')}
            >
              <Button
                size="medium"
                className={classes.primaryBackground}
                variant="contained"
                startIcon={<EditOutlinedIcon />}
                disabled={tcsMatrixDisplayData.length == 0
                  ? !hasWriteAccess(props.user, 'tcs_matrix')
                  : !hasUpdateAccess(props.user, 'tcs_matrix')
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (tcsMatrixDisplayData.length == 0) {
                    console.log(props.tcsOperation);
                    props.history.push(
                      `${INTERNALROUTES.TCSMATRIX}/add/${props.match.params.id}`
                    );
                  } else {
                    props.history.push(
                      `${INTERNALROUTES.TCSMATRIX}/edit/${props.match.params.id}`
                    );
                  }

                }}
              >
                {tcsMatrixDisplayData.length > 0 ? "EDIT" : "CREATE"}
              </Button>
            </ToolTipComponent>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(DisplayTcsMatrixData);
