import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import withUser from "../../../../hoc/withUser";
import ManageStateCategoryData from "../../../../components/internal/masterManagement/dailyRateRegions/web";
import MasterManagmentMobile from "../../../../components/internal/masterManagement/dailyRateRegions/mobile";
// import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
// import { Route } from "react-router-dom";
// Old Imports
// import ManageStateCategoryData from "../../../components/internal/masterManagement/web/dailyRateRegions/index";
// import ItemCategoryView from "./masterItemCategory/ItemCategoryView";
// import ItemCategoryDetails from "./masterItemCategory/ItemCategoryDetails";
// import ItemCategoryCreate from "./masterItemCategory/ItemCategoryCreate";
// import ItemCategoryEdit from "./masterItemCategory/ItemCategoryEdit";
// New Imports
// import MastersManagement, {
//   MasterManagmentMobile,
//   DisplayMastersDataDetailsList,
// } from "../../../components/internal/masterManagement";
// import ManageItemCategoryDataContainer from "./mastersItemCategoryNewUi/ManageItemCategoryDataContainer";
// import ManageItemTagsDataContainer from "./mastersItemCategoryNewUi/ManageItemTagsDataContainer";
// import ManagePaymentTermDataContainer from "./mastersItemCategoryNewUi/ManagePaymentTermDataContainer";
// import ManagePlantMasterDataContainer from "./mastersItemCategoryNewUi/ManagePlantMasterDataContainer";
// import { findAllByDisplayValue } from "@testing-library/react";
import { arraySortBykeyAllBrowser, isAccessibleModule } from "../../../../methods";
class MastersManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNewUi: true,
      displayItemMasterNewUi: true,
      itemOperation: null,
      stateOperation: null,
      selectedOptionToDisplay: null,
      fetchingState: false,
      itemCategoriesDataFetchLoader: false,
      itemCategoriesList: [],
      paymentTermsList: [],
      paymentTermsDataFetchLoader: false,
      plantItemMasterList: [],
      plantItemMasterDataFetchLoader: false,
      tagsMasterList: [],
      tagsMasterDataFetchLoader: false,
    };
  }

  componentDidMount() {
    this.getDailyRatesState();
    this.setState({ fetchingState: false });
    // if (isAccessibleModule(this.props?.user, 'Masters')) {
    //   this.getDailyRatesState();
    //   this.getItemCateogriesList();
    //   this.getPaymentTermsList();
    //   this.getTagsMasterList();
    //   this.getPlantItemMasterList();
    // }
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  // New ui fuctions
  getDailyRatesState = () => {
    this.setState({ fetchingState: true });
    axios
      .get(APIROUTES.DAILY_RATES_STATE, this.getOptions(this.props.user))
      .then(
        (response) => {
          var responseData = arraySortBykeyAllBrowser(response.data, 'state_name');
          this.setState({
            dailyRatesState: responseData,
            fetchingState: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ fetchingState: false });
        }
      );
  };
  //   getItemCateogriesList = () => {
  //     this.setState({ itemCategoriesDataFetchLoader: true });
  //     axios
  //       .get(APIROUTES.GET_ITEM_CATEGORIES_LIST, this.getOptions(this.props.user))
  //       .then(
  //         (response) => {
  //           var responseData = response.data;
  //           this.setState({
  //             itemCategoriesList: responseData,
  //             itemCategoriesDataFetchLoader: false,
  //           });
  //         },
  //         (error) => {
  //           console.log("error", error);
  //           this.setState({ itemCategoriesDataFetchLoader: false });
  //         }
  //       );
  //   };
  //   getPaymentTermsList = () => {
  //     this.setState({ paymentTermsDataFetchLoader: true });
  //     axios
  //       .get(
  //         APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD,
  //         this.getOptions(this.props.user)
  //       )
  //       .then(
  //         (response) => {
  //           var responseData = response.data;
  //           this.setState({
  //             paymentTermsList: responseData,
  //             paymentTermsDataFetchLoader: false,
  //           });
  //         },
  //         (error) => {
  //           console.log("error", error);
  //           this.setState({ paymentTermsDataFetchLoader: false });
  //         }
  //       );
  //   };
  //   getTagsMasterList = () => {
  //     this.setState({ tagsMasterDataFetchLoader: true });
  //     axios
  //       .get(
  //         APIROUTES.GET_ALL_TAGS_MASTERS_LIST,
  //         this.getOptions(this.props.user)
  //       )
  //       .then(
  //         (response) => {
  //           var responseData = response.data;
  //           this.setState({
  //             tagsMasterList: responseData,
  //             tagsMasterDataFetchLoader: false,
  //           });
  //         },
  //         (error) => {
  //           console.log("error", error);
  //           this.setState({ tagsMasterDataFetchLoader: false });
  //         }
  //       );
  //   };
  //   getPlantItemMasterList = () => {
  //     this.setState({ plantItemMasterDataFetchLoader: true });
  //     axios
  //       .get(
  //         APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST,
  //         this.getOptions(this.props.user)
  //       )
  //       .then(
  //         (response) => {
  //           var responseData = response.data;
  //           this.setState({
  //             plantItemMasterList: responseData,
  //             plantItemMasterDataFetchLoader: false,
  //           });
  //         },
  //         (error) => {
  //           console.log("error", error);
  //           this.setState({ plantItemMasterDataFetchLoader: false });
  //         }
  //       );
  //   };
  // old ui functions
  selectedState = (id) => {
    const selectedStateDetails = this.state.dailyRatesState.filter(
      (data) => data.id === id
    );
    this.setState({ selectedStateDetails });
  };

  handleUpdateState = (stateName, stateCode, stateId, active) =>
    new Promise((resolve, reject) => {
      const data = {
        state_name: stateName,
        state_code: stateCode,
        is_active: active
      };
      this.setState({ fetchingState: true });
      axios
        .put(
          `${APIROUTES.MASTER_STATE_UPDATE}${stateId}/`,
          data,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.getDailyRatesState();
          this.setState({ fetchingState: false });
          resolve(response);
        })
        .catch(reject);
    });

  handleAddState = (stateName, stateCode, active) =>
    new Promise((resolve, reject) => {
      const data = {
        state_name: stateName,
        state_code: stateCode,
        is_active: active
      };
      this.setState({ fetchingState: true });
      axios
        .post(
          APIROUTES.ADD_MASTER_STATE,
          data,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.getDailyRatesState();
          this.setState({ fetchingState: false });
          resolve(response);
        })
        .catch(reject);
    });

  handleDeleteState = (stateId) =>
    new Promise((resolve, reject) => {
      this.setState({ fetchingState: true });
      axios
        .delete(
          `${APIROUTES.DELETE_MASTER_STATE}${stateId}/`,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.getDailyRatesState();
          this.setState({ fetchingState: false });
          resolve(response);
        })
        .catch(error => {
          if (error['response']['status'] === 500) {
            this.setState({ fetchingState: false });
            resolve(error)
          }
          reject(error);
        })
    });

  render() {
    const { selectedStateDetails } = this.state;
    // const { user } = this.props;
    if (this?.props?.isMobile) return <MasterManagmentMobile />;
    else if (isAccessibleModule(this.props?.user, "Masters")) {
      return (
        <ManageStateCategoryData
          {...this.props}
          {...this.state}
          fetchingState={this.state.fetchingState}
          selectedStateDetails={selectedStateDetails}
          handleUpdateState={this.handleUpdateState}
          handleAddState={this.handleAddState}
          handleDeleteState={this.handleDeleteState}
        />
      );
    } else {
      return <AccessDenied />;
    }
  }
}

export default withUser(MastersManagementContainer);
