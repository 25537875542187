import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormHelperText } from "@material-ui/core";


export class FilterByLAQTY extends Component {

    render() {
        const { value, valueError, fieldFor, label } = this.props
        // console.log(maxQuantity && !(maxQuantity === ""))
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
            }}>
                <TextField
                    name={label}
                    id="outlined-name"
                    label={label}
                    value={value}
                    style={{ width: "100%" }}
                    margin="normal"
                    variant="outlined"

                    error={valueError}
                    helperText={valueError &&
                        "Invalid input"
                        // :
                        // minQuantity > maxQuantity
                        // && "invalid input"
                    }
                    // onKeyPress={
                    //     (event) => {
                    //         if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46) {

                    //         } else {
                    //             event.preventDefault()
                    //         }
                    //     }
                    // }
                    // InputLabelProps={{
                    //     style: { fontSize: "0.9em" },
                    // }}
                    onChange={this.props.handleChange(fieldFor)}
                />
            </div>
        )
    }
}

export default FilterByLAQTY