import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

export class FilterBypaymentDueDate extends Component {
    render() {
        const { paymentDueDate } = this.props
        // console.log(paymentDueDate)
        return (
            // <div style={{ width: "100%", backgroundColor: "green" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ marginTop: "15px", minWidth: "100%", backgroundColor: "red" }}>
                <DesktopDatePicker
                    label="Payment Due Date"
                    value={paymentDueDate}
                    //   maxDate={new Date()}
                    onChange={this.props.handlePaymentDueDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="dd-MMM-yyyy"
                    valueDefault={null}
                />
            </LocalizationProvider>
            // </div>
        )
    }
}

export default FilterBypaymentDueDate