import React from "react";
export default class MaskGroupSVG extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="16.000000pt" height="16.000000pt" viewBox="0 0 40.000000 40.000000" preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M115 364 c-45 -23 -66 -45 -86 -89 -24 -52 -24 -106 1 -154 10 -21 16 -41 13 -45 -4 -3 3 -6 15 -6 13 0 22 6 22 15 0 8 -6 15 -13 15 -31 0 -53 80 -38 138 14 52 44 88 92 112 46 24 87 25 136 5 20 -8 38 -13 41 -10 3 2 -11 12 -31 20 -47 19 -114 19 -152 -1z" />
                    <path d="M310 319 c0 -6 10 -19 22 -28 30 -25 37 -114 13 -165 -38 -79 -136 -117 -218 -83 -20 9 -37 12 -37 8 0 -15 61 -34 106 -34 58 0 130 44 158 98 26 49 27 118 4 164 -10 18 -15 37 -12 42 3 5 -4 9 -15 9 -12 0 -21 -5 -21 -11z" />
                    <path d="M133 296 c-49 -26 -73 -45 -73 -57 0 -13 100 -61 116 -55 11 4 14 -5 14 -39 0 -50 -4 -52 -55 -28 -27 13 -35 23 -35 44 0 15 -4 31 -10 34 -6 4 -10 -8 -10 -29 0 -33 4 -38 50 -61 60 -31 68 -31 125 -3 43 21 45 24 43 62 l-1 41 -4 -36 c-3 -31 -10 -38 -48 -57 l-45 -22 0 51 c0 29 4 48 9 44 13 -8 122 47 118 60 -4 13 -118 75 -137 74 -8 -1 -34 -11 -57 -23z m106 -10 l43 -23 -46 -21 -46 -22 -41 21 c-45 23 -45 23 3 49 41 23 35 24 87 -4z m-96 -58 c24 -12 36 -23 29 -26 -16 -5 -92 27 -92 40 0 13 18 9 63 -14z m167 17 c0 -6 -77 -45 -90 -45 -21 0 -9 17 23 33 31 15 67 22 67 12z" />
                </g>
            </svg>
        );
    }
}
