import React, { Component } from "react";
import WebLayout from "../../../layouts/b2b/WebLayout";
import "../../../styles/b2b/web/B2bEditProfileWeb.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { primaryColor } from "../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";

const styles = {
  b2bEditProfileWebMainBody: {
    paddingTop: "80px",
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    width: '45%'
  },
  b2bEditProfileWebInternalBody: {
    height: "100%",
    padding: "40px 30px 20px 30px",
  },
  myProfileButtonStyle: {
    textTransform: "capitalize",
    fontSize: "1.1em",
    color: "#1B388B",
  },
  textFieldStyle: {
    width: "92%",
  },
};
class B2bEditProfileWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar profileSelect>
        <div className={classes.b2bEditProfileWebMainBody}>
          <div className={classes.b2bEditProfileWebInternalBody}>
            <div className="b2b-edit-profile-web-header-button-container">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile");
                }}
                size="small"
                className={classes.myProfileButtonStyle}
              >
                My Profile
              </Button>
              {/* <ChevronRight style={{ color: primaryColor }} /> */}
              {/* <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile/settings");
                }}
                size="small"
                className={classes.myProfileButtonStyle}
              >
                settings
              </Button> */}
              <ChevronRight style={{ color: "#A3A3A3" }} />
              <span style={{ color: "#A3A3A3" }}>Edit Profile</span>
            </div>
            <br />

            <div className="b2b-edit-profile-web-form-container" style={{ width: '100%' }}>
              <TextField
                name="name"
                id="outlined-name"
                label="Name"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                inputProps={{
                  style: { padding: "15px 14px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "-4px" },
                }}
              />
              <TextField
                name="gstNo"
                id="outlined-name"
                label="2322HHHHDBDBD"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                inputProps={{
                  style: { padding: "15px 14px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "-4px" },
                }}
              />
              <TextField
                name="phoneNumber"
                id="outlined-name"
                label="Phone Number "
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                inputProps={{
                  style: { padding: "15px 14px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "-4px" },
                }}
              />
              <br />
              <div className="b2b-edit-profile-web-add-more-number-container">
                <span className="b2b-edit-profile-web-add-more-number">
                  <Add style={{ marginRight: "10px" }} /> Add more Contact
                  Number
                </span>
              </div>
              <TextField
                name="email"
                id="outlined-name"
                label="Email Address"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                inputProps={{
                  style: { padding: "15px 14px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "-4px" },
                }}
              />
              <TextField
                name="address"
                id="outlined-name"
                label="Address"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                inputProps={{
                  style: { padding: "15px 14px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "-4px" },
                }}
              />
              <TextField
                name="pincode"
                id="outlined-name"
                label="Pincode"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                inputProps={{
                  style: { padding: "15px 14px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "-4px" },
                }}
              />
              <br />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#1B388B",
                  fontWeight: "bold",
                  color: "white",
                  width: "92%",
                  padding: "12px 16px",
                }}
              >
                Next
              </Button>
              <br />
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
              >
                Cancel
              </Button>
              <br />
            </div>
          </div>
        </div>
      </WebLayout>
    );
  }
}

export default withStyles(styles)(B2bEditProfileWeb);
