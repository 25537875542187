import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router";
import COLORS from "../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Check from "@material-ui/icons/Check";
import WeighingStepper from "./WeighingStepper";
import CircularProgress from "@material-ui/core/CircularProgress";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import { pxToEm } from '../../../methods';


const styles = {
  breadcrumbsStyle: {
    color: COLORS.secondaryBlueText,
    cursor: "pointer",
    margin: "10px 0px 0px 10px",
  },
  formControl: {
    width: "100%",
  },
  helperText: {
    position: "absolute",
    top: pxToEm(50),
  }
};

const threshold = 3.0;

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laItemDetails: [],
      selectedItemId: null,
      itemQuantity: 0,
      allSelectedItem: [],
      allotedWeight: 0.0,
      weightToAllot: 0.0,
      currentWeightToAllot: 0.0,
      actualSectionCount: 0.0,
      actualSectionWeight: 0.0,
      calculatedSectionCount: 0.0,
      markAsPackedLoader: false,
      maxInputAllowed: 0.0,
      minInputAllowed: 0.0,
      total_weight_to_allot: 0.0,
      itemQuantityInputError: false,
      // --------
      weighSlips: [],
      laWeighSlips: [],
      allotedItems: [],
      la: { remark: null },
      selectedWeighSlipID: false,
      dcId: this.props.location.state?.dcId,
      laItemDetailsInDC: [],
      bundleToleranceErr: []
    };
  }

  componentDidMount() {
    const { laDetailsInDispatchCounter: la, history } = this.props;
    console.log(la)
    if (!la) history.push(INTERNALROUTES.DISPATCH_OPERATION);
    const weigh_considered = la?.weigh_slips?.slice(1, la?.weigh_slips?.length)
    const allotedWeight =
      //this.state.laItemDetails?.reduce((total, w) => total + Number(w?.alloted_weight), 0)
      la?.total_values?.alloted_weight === 0 ? la?.total_values?.weighing_weight : la?.total_values?.alloted_weight

    this.setState(
      {
        weightToAllot: 0.0,
        allotedWeight,
        la: la,
        laItemDetailsInDC: la && la?.loading_advice_items[0]?.alloted_weight === "0.000" ?
          la?.loading_advice_weighing_items : la?.loading_advice_items?.map(
            (data) => data
          ),
      }
    );

    la?.loading_advice_items &&
      this.setState({
        laItemDetails: [...la?.loading_advice_items]
      })

    weigh_considered &&
      this.setState(
        {
          weighSlips: [...weigh_considered],
          laWeighSlips: [...weigh_considered]
        },
        () => {
          const { weighSlips } = this.state;
          this.setState({
            selectedWeighSlipID: weighSlips.length > 0 ? weighSlips[0].id : false,
          });
        }
      );
  }

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleLARemarkChange = (event) => {
    const { value, name } = event.target;
    this.setState((previousState) => {
      const la = {
        ...previousState.la,
        [name]: value === "" ? null : value,
      };
      return { la };
    });
  }

  handleMarkAsPacked = () => {
    let { allotedItems } = this.state;
    const {
      laDetailsInDispatchCounter: la,
      history,
      handleUpdateLaWithWeightAllotment,
    } = this.props;
    this.setState({ markAsPackedLoader: true }, () => {
      allotedItems = this.state.laItemDetailsInDC?.map((data) => {
        const item = {};
        item.item = data.item.id;
        item.section_weight = data.item.section.section_weight;
        item.section_name = data.item.section.section_name;
        item.actual_section_count = data.actual_section_count;
        item.alloted_weight = this.props.laDetailsInDispatchCounter?.item_category?.is_consider_packing ?
          Number(data?.actual_section_count) * Number(data?.item?.section?.section_weight).toFixed(3) :
          data.alloted_weight;
        item.remark = data.remark;
        return item;
      });
      handleUpdateLaWithWeightAllotment(allotedItems, la?.la_number, this.state.dcId, this.state.la?.remark)
        .then((response) => {
          if (response.status === 200)
            history.push("details/invoices", {
              dcId: this.state.dcId,
            });
        })
        .catch((err) => console.log(err))
        .finally(() => this.setState({ markAsPackedLoader: false }));
    });
  };

  getEstBundleSize = (itemQuantity, section_weight) => {
    let val = parseFloat(itemQuantity) % parseFloat(section_weight);
    if (val === 0) {
      return parseFloat(itemQuantity) / parseFloat(section_weight);
    }
    if (!Number.isFinite(val)) {
      return 0;
    } else {
      return (
        Math.floor(parseFloat(itemQuantity) / parseFloat(section_weight)) +
        "-" +
        Math.ceil(parseFloat(itemQuantity) / parseFloat(section_weight))
      );
    }
  };

  getPermittedNumber = (num) => {
    return Number(Number(num).toFixed(3));
  };

  checkBundleCount = (
    toleranceUpto1Mts,
    toleranceUpto5Mts,
    toleranceAbove5Mts, itemQtyForManual, weighbridgeWeight, sectionWeight, packingWeight, name
  ) => (event) => {
    // console.log(toleranceUpto5Mts)
    const { value } = event.target;
    if (name === "actualSectionCount") {
      const dispatchedQty = Number(value) * sectionWeight;
      const packingWtInMts = packingWeight * Number(value);
      const adjustedWt = Number(Number(weighbridgeWeight - dispatchedQty - packingWtInMts).toFixed(3));
      const finalTolerance = Number(Number((adjustedWt / weighbridgeWeight) * 100).toFixed(3));
      // console.log(finalTolerance)
      // const itemQtyToCompare = itemCount?.length > 0 ? itemQtyForManual : (weights.find((w) => w.item === selected[0]?.item))?.item_qty;
      const itemQtyToCompare = itemQtyForManual;
      // console.log(Number(itemQtyToCompare))
      // console.log("packingwetin MTS", packingWtInMts)
      // console.log("adjusted weight", adjustedWt)
      // console.log("dispatchedQty", dispatchedQty)
      if (1.000 >= itemQtyToCompare && itemQtyToCompare > 0) {
        if (finalTolerance > toleranceUpto1Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (1.001 <= itemQtyToCompare && itemQtyToCompare <= 5) {
        if (finalTolerance > toleranceUpto5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (itemQtyToCompare > 5) {
        if (finalTolerance > toleranceAbove5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (finalTolerance < 0) {
        return { error: true, errorMessage: 'bundle error' }
      }
    }
  }


  render() {
    const {
      classes,
      laDetailsInDispatchCounter: la,
      history,
      plantName,
      dcName,
    } = this.props;
    const {
      allotedWeight,
      weighSlips,
      weightToAllot,
      allotedItems,
      laItemDetails,
      selectedWeighSlipID,
      selectedItemId,
      actualSectionCount,
      itemQuantity,
      laWeighSlips
    } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          className={classes.breadcrumbsStyle}
        >
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              history.push("/dispatchOperation");
            }}
          >
            Dispatch Operations
          </Link>
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Dispatch Counter
          </Link>
          <Link color="inherit">Details</Link>
        </Breadcrumbs>
        <div style={{ margin: "12px 8px" }}>
          {plantName} - {dcName}
        </div>
        <Paper
          elevation={1}
          style={{
            width: "96%",
            display: "flex",
            flexDirection: "column",
            height: "auto",
            marginBottom: "25px",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
          }}
        >
          <div style={{ height: "34%", display: "flex" }}>
            <div style={{ width: "60%", padding: "18px 28px 0px" }}>
              <span style={{ fontSize: "2em" }}>{la?.la_number}</span>
              <div>
                <WeighingStepper
                  weigh_slips={laWeighSlips}
                  tareWeight={la?.tare_weight}
                />
              </div>
            </div>
            <div style={{ width: "50%", paddingTop: "6px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color: COLORS.grey,
                }}
              >
                <span>LA QUANTITY</span>
                <span>{la && parseFloat(la?.la_quantity)} MT</span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color: COLORS.grey,
                }}
              >
                <span>ALLOTED WEIGHT</span>
                <span>{Number(allotedWeight).toFixed(3)} MT</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color:
                    Number(Number(weightToAllot).toFixed(3)) > 0 ? COLORS.orange : "rgb(205, 220, 57)",
                }}
              >
                <span>WEIGHT TO ALLOT</span>
                <span>{Number(weightToAllot).toFixed(3)} MT</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color:
                    COLORS.grey,
                }}
              >
                <span>LOADING ADVICE REMARKS</span>
                <span>
                  <TextField
                    name="remark"
                    label="LA REMARK"
                    className={classes.formControl}
                    onChange={this.handleLARemarkChange}
                    rows="3"
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 200 }}
                    variant="outlined"
                    size="small"
                    value={this.state.la?.remark}
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
              fontWeight: "bold",
              background: "rgba(163, 166, 180, 0.44)",
              color: COLORS.grey,
              height: "7vh",
              alignItems: "center",
              margin: "16px 16px 0px",
            }}
          >
            <span
              style={{
                width: "15%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              ITEM
            </span>
            <span
              style={{
                width: "15%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              LA QUANTITY
            </span>
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              SECTION DETAIL
            </span>
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "right",
              }}
            >
              BUNDLE COUNT
            </span>
            {la?.item_category?.is_consider_packing &&
              <span
                style={{
                  width: "20%",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                BUNDLED WEIGHT
              </span>
            }
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "right",
              }}
            >
              ALLOT WEIGHT
            </span>
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "right",
                //marginLeft: "20px"
              }}
            >
              ITEM REMARKS
            </span>
            <span
              style={{
                width: "10%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            ></span>
          </div>
          {this.state.laItemDetailsInDC.map((data, index) => (
            <div
              id="test upper"
              style={{
                margin: "16px 16px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "15%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {data?.item?.item_name}
              </div>
              <div
                style={{
                  width: "12%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Number(data.item_qty)}
              </div>
              <div
                style={{
                  width: "23%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px 12px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <CssTextField
                    size="small"
                    style={{ marginLeft: "25px" }}
                    id="outlined-name"
                    // label="Section info"
                    margin="normal"
                    variant="outlined"
                    multiline
                    maxRows={Infinity}
                    removeAllotedItem
                    helperText={`Section weight ${Number(data?.item?.section?.section_weight).toFixed(3) +
                      " " +
                      "MT/bundle"
                      } `}
                    value={
                      "Est " +
                      this.getEstBundleSize(
                        data?.alloted_weight,
                        data?.item?.section?.section_weight
                      ) +
                      " bundles"
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  padding: "0px 12px 12px 0px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    type="number"
                    id="outlined-dense"
                    name="actualSectionCount"
                    style={{ width: "80%", marginLeft: "13px" }}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    error={Number(data?.actual_section_count) < 0 || this.state.bundleToleranceErr[index]?.error}
                    value={data?.actual_section_count}
                    onChange={(e) => {
                      const currentItemQuatity = [...this.state.laItemDetailsInDC];
                      currentItemQuatity[index].actual_section_count = e.target.value;
                      this.setState({
                        laItemDetailsInDC: currentItemQuatity
                      })

                      if (la?.item_category?.is_consider_packing) {
                        const resultOfBundle = this.checkBundleCount(data?.item?.tolerance_upto_1mts,
                          data?.item?.tolerance_from_1to5mts,
                          data?.item?.tolerance_above_5mts, data?.item_qty,
                          data?.alloted_weight, data?.item?.section?.section_weight,
                          data?.item?.packing_weight, "actualSectionCount")(e);
                        let errorBundle = [...this.state.bundleToleranceErr]
                        errorBundle[index] = resultOfBundle;
                        this.setState({
                          bundleToleranceErr: errorBundle
                        })
                      }

                    }}
                    onKeyPress={(event) => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    helperText={Number(data?.actual_section_count) < 0 ? "Invalid Bundle Count" :
                      this.state.bundleToleranceErr[index]?.error ? this.state.bundleToleranceErr[index]?.errorMessage :
                        Number(data?.actual_section_count) > 0 ? `Actual bundle weight ${(Number(data?.alloted_weight) / Number(data?.actual_section_count))?.toFixed(3)} MT`
                          : 'Actual bundle weight 0.000 MT'}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                  />
                </div>
              </div>
              {la?.item_category?.is_consider_packing &&
                <div
                  style={{
                    width: "20%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "0px 12px",
                    //marginLeft: "20px"
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <TextField
                      type="number"
                      size="small"
                      style={{ width: "80%", pointerEvents: "none" }}
                      id="outlined-name"
                      //label="Bundled Weight"
                      variant="outlined"
                      name="itemQuantity"
                      value={Number(data?.actual_section_count) * Number(data?.item?.section?.section_weight).toFixed(3)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">MT</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              }
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px 12px",
                  //marginLeft: "5px"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <TextField
                    size="small"
                    style={{ width: "80%", pointerEvents: "none" }}
                    id="outlined-name"
                    label="Quantity"
                    variant="outlined"
                    name="itemQuantity"
                    value={this.getPermittedNumber(
                      data?.alloted_weight
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">MT</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px 30px 0px 0px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    name="itemRemark"
                    label="Item remark"
                    className={classes.formControl}
                    multiline
                    margin="normal"
                    variant="outlined"
                    size="small"
                    value={data?.remark}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
              </div>
              {/* <div
                  style={{
                    width: "10%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    aria-label="Delete"
                    onClick={(e) => {
                      e.preventDefault();
                      const result = removeAllotedItem(
                        data.weighSlipId,
                        laItemDetails,
                        weighSlips,
                        allotedItems,
                        allotedWeight,
                        weightToAllot,
                        la,
                        laWeighSlips.find((e) => e.id === data.weighSlipId)
                          ?.loaded_weight -
                        (laWeighSlips.findIndex(
                          (e) => e.id === data.weighSlipId
                        ) === 0
                          ? la?.tare_weight
                          : laWeighSlips[
                            laWeighSlips.findIndex(
                              (e) => e.id === data.weighSlipId
                            ) - 1
                          ]?.loaded_weight),
                        laWeighSlips
                      );
                      this.setState({ ...result });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div> */}
            </div>
          ))}

          {/* {!selectedWeighSlipID ? null : (
            <div
              id="test"
              style={{
                margin: "16px 16px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                size="small"
                style={{ width: "15%" }}
                variant="outlined"
              >
                <InputLabel
                  id="label-id"
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Item
                </InputLabel>
                <Select
                  value={
                    laItemDetails.find((e) => e.id === selectedItemId)?.item
                      .item_name
                  }
                  input={
                    <OutlinedInput
                      label="Item"
                      labelId="label-id"
                      name="age"
                      id="outlined-age-simple"
                      InputLabelProps={{
                        style: { fontSize: "0.9em" },
                      }}
                    />
                  }
                >
                  {prioritySelectionForItems(
                    selectedWeighSlipID,
                    laItemDetails,
                    la,
                    laWeighSlips
                  ).map((data, index) => (
                    <MenuItem
                      disabled={!data.enabled}
                      value={data.item.item_name}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ selectedItemId: data.id });
                      }}
                    >
                      {data.item.item_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div
                style={{
                  width: "12%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Number.isNaN(
                  Number(
                    laItemDetails.find((e) => e.id === selectedItemId)?.item_qty
                  )
                )
                  ? ""
                  : Number(
                    laItemDetails.find((e) => e.id === selectedItemId)
                      ?.item_qty
                  )}
              </div>

              <div
                style={{
                  width: "23%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px 12px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  {laItemDetails.find((e) => e.id === selectedItemId) ? (
                    <CssTextField
                      size="small"
                      style={{ marginLeft: "25px" }}
                      id="outlined-name"
                      // label="Section info"
                      margin="normal"
                      variant="outlined"
                      multiline
                      maxRows={Infinity}
                      removeAllotedItem
                      name="actualSectionCount"
                      helperText={`Section weight ${parseFloat(
                        laItemDetails.find((e) => e.id === selectedItemId)?.item
                          .section?.section_weight
                      )} MT/bundle`}
                      // FormHelperTextProps={{
                      //   style: {
                      //     marginLeft: "0px",
                      //     marginRight: "0px",
                      //     marginTop: "1px",
                      //   },
                      // }}
                      value={
                        "Est " +
                        this.getEstBundleSize(
                          this.getPermittedNumber(
                            weighSlips.find((e) => e.id === selectedWeighSlipID)
                              ?.loaded_weight -
                            (laWeighSlips.findIndex(
                              (e) => e.id === selectedWeighSlipID
                            ) === 0
                              ? la?.tare_weight
                              : laWeighSlips[
                                laWeighSlips.findIndex(
                                  (e) => e.id === selectedWeighSlipID
                                ) - 1
                              ]?.loaded_weight)),
                          laItemDetails.find((e) => e.id === selectedItemId)
                            ?.item.section?.section_weight
                        ) +
                        " bundles"
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  ) : null}
                </div>
                 
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  disabled={!Boolean(selectedItemId)}
                  size="small"
                  style={{ width: "80%", marginLeft: "25px" }}
                  id="outlined-name"
                  label="No of bundle*"
                  margin="normal"
                  variant="outlined"
                  name="actualSectionCount"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    this.handleTextChange({
                      target: { name: "actualSectionCount", value },
                    });
                  }}
                  helperText={`Actual bundle weight ${Number(actualSectionCount) === 0
                    ? 0
                    : (
                      this.getPermittedNumber(
                        weighSlips.find((e) => e.id === selectedWeighSlipID)
                          ?.loaded_weight -
                        (laWeighSlips.findIndex(
                          (e) => e.id === selectedWeighSlipID
                        ) === 0
                          ? la?.tare_weight
                          : laWeighSlips[
                            laWeighSlips.findIndex(
                              (e) => e.id === selectedWeighSlipID
                            ) - 1
                          ]?.loaded_weight)
                      ) / parseFloat(Number(actualSectionCount))
                    ).toFixed(3)
                    } `}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  value={actualSectionCount}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">MT</InputAdornment>
                //   ),
                // }}
                />
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  error={this.state.itemQuantityInputError}
                  size="small"
                  style={{ width: "80%", marginRight: "10px" }}
                  id="outlined-name"
                  label="Quantity"
                  margin="normal"
                  variant="outlined"
                  name="itemQuantity"
                  helperText=""
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginTop: "1px",
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9\.]/g, "");
                    const formattedValue = Number(value).toFixed(3);
                    if (Number(value) > Number(this.state.maxInputAllowed)) {
                      this.setState({ itemQuantityInputError: true });
                    } else if (
                      Number(value) < Number(this.state.minInputAllowed)
                    ) {
                      this.setState({ itemQuantityInputError: true });
                    } else {
                      this.setState({ itemQuantityInputError: false });
                    }
                    this.handleTextChange({
                      target: { name: "itemQuantity", formattedValue },
                    });
                  }}
                  onFocus={(e) => {
                    this.setState({
                      itemQuantityInputError: false,
                    });
                  }}
                  value={this.getPermittedNumber(
                    weighSlips.find((e) => e.id === selectedWeighSlipID)
                      ?.loaded_weight -
                    (laWeighSlips.findIndex(
                      (e) => e.id === selectedWeighSlipID
                    ) === 0
                      ? la?.tare_weight
                      : laWeighSlips[
                        laWeighSlips.findIndex(
                          (e) => e.id === selectedWeighSlipID
                        ) - 1
                      ]?.loaded_weight)
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">MT</InputAdornment>
                    ),
                  }}
                />
              </div>

              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  name="itemRemark"
                  label="Item remark"
                  className={classes.formControl}
                  style={{ pointerEvents: "none" }}
                  multiline
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={
                    laItemDetails.find((e) => e.id === selectedItemId)?.remark || ""
                  }
                />

              </div>
              <div
                style={{
                  width: "10%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={
                    !Boolean(selectedItemId) ||
                    !Boolean(Number(actualSectionCount))
                  }
                  style={{
                    backgroundColor: !Boolean(selectedItemId) ||
                      !Boolean(Number(actualSectionCount)) ? " " : COLORS.successButtonBackground
                  }}
                  variant="contained"
                  color="primary"

                  onClick={(e) => {
                    e.preventDefault();
                    const result = allotItemWithWeight(
                      selectedItemId,
                      selectedWeighSlipID,
                      laItemDetails,
                      weighSlips,
                      allotedItems,
                      allotedWeight,
                      weightToAllot,
                      actualSectionCount,

                      this.getPermittedNumber(
                        laWeighSlips.find(
                          (e) => e.id === selectedWeighSlipID
                        )?.loaded_weight -
                        (laWeighSlips.findIndex(
                          (e) => e.id === selectedWeighSlipID
                        ) === 0
                          ? la?.tare_weight
                          : laWeighSlips[
                            laWeighSlips.findIndex(
                              (e) => e.id === selectedWeighSlipID
                            ) - 1
                          ]?.loaded_weight)
                      )
                    );
                    this.setState({
                      ...result,
                      selectedItemId: false,
                      actualSectionCount: 0,
                    });
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )} */}

          {la?.loading_advice_items.length > 0 ? (
            <div
              style={{
                display: "flex",
                float: "right",
                paddingRight: "38px",
                marginTop: "18px",
                justifyContent: "flex-end",
                marginBottom: "18px",
              }}
            >
              <Button color="primary" style={{ marginRight: "12px" }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/dispatchOperation");
                }}
              >
                Cancel
              </Button>
              {this.state.markAsPackedLoader ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={Number(Number(weightToAllot).toFixed(3)) !== 0.0 ||
                    this.state.laItemDetailsInDC.some(a => Number(a.actual_section_count) < 0) ||
                    this.state.bundleToleranceErr?.some(b => b?.error)}
                  onClick={this.handleMarkAsPacked}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor:
                      Number(Number(weightToAllot).toFixed(3)) !== 0.0 ||
                        this.state.laItemDetailsInDC.some(a => Number(a.actual_section_count) < 0) ||
                        this.state.bundleToleranceErr?.some(b => b?.error)
                        ? COLORS.grey
                        :
                        COLORS.successButtonBackground,
                  }}
                >
                  <Check />
                  Next
                </Button>
              )}
            </div>
          ) : null}


        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Details));

function allotItemWithWeight(
  laItemId,
  weighSlipId,
  laItemDetails,
  weighSlips,
  allotedItems,
  allotedWeight,
  weightToAllot,
  ...args
) {
  const currentWeightToAllot = weightToAllot;
  let allotedItem = null;
  const laItemIndex = laItemDetails.findIndex((el) => el.id === laItemId);
  const weighSlipIndex = weighSlips.findIndex((el) => el.id === weighSlipId);
  if (laItemIndex < 0 || weighSlipIndex < 0) {
    throw new Error(`laItemIndex/weighSlipId couldn't be found.`);
  }
  if (
    laItemId < 0 ||
    isNaN(laItemId) ||
    allotedItems.find((e) => e.laItemId === laItemId)
  ) {
    throw new Error(`Invalid laItemId.`);
  }
  if (
    weighSlipId < 0 ||
    isNaN(weighSlipId) ||
    allotedItems.find((e) => e.weighSlipId === weighSlipId)
  ) {
    throw new Error(`Invalid weighSlipId.`);
  }

  const actualSectionCount = args[0];
  let actualSectionWeight = 0.0;
  let calculatedSectionCount = 0.0;
  const laItem = laItemDetails[laItemIndex];
  const weighSlip = weighSlips[weighSlipIndex];

  if (weighSlip && laItem) {
    actualSectionWeight =
      parseFloat(weighSlip.loaded_weight) / parseFloat(actualSectionCount);
    calculatedSectionCount =
      parseFloat(laItem.item_qty) /
      parseFloat(laItem.item.section.section_weight);
    allotedWeight += args[1];
    weightToAllot -= args[1];

    allotedItem = Object.assign(
      { actualSectionCount, actualSectionWeight, calculatedSectionCount },
      {
        laItemId: laItem?.id,
        ...laItem,
      },
      {
        weighSlipId: weighSlip?.id,
        ...weighSlip,
      },
      {
        alloted_weight: args[1]
      }

    );
    allotedItems.push(allotedItem);
    laItemDetails.splice(laItemIndex, 1);
    weighSlips.splice(weighSlipIndex, 1);
  }
  const selectedWeighSlipID = weighSlips.length > 0 ? weighSlips[0]?.id : false;
  weightToAllot = parseFloat(Number(weightToAllot).toFixed(3))
  return {
    laItemDetails,
    weighSlips,
    allotedItems,
    selectedWeighSlipID,
    allotedWeight,
    weightToAllot,
    currentWeightToAllot
  };
}

function removeAllotedItem(
  weighSlipId,
  laItemDetails,
  weighSlips,
  allotedItems,
  allotedWeight,
  weightToAllot,
  la,
  itemWeight,
  laWeighSlips
) {
  const allLAItems = [...la?.loading_advice_items];
  const allWeighSlips = [...laWeighSlips];

  if (weighSlipId < 0 || isNaN(weighSlipId))
    throw new Error(`Invalid weighSlipId.`);

  const allotedItemIndex = allotedItems.findIndex(
    (item) => item.weighSlipId === weighSlipId
  );

  if (allotedItemIndex < 0)
    throw new Error(`weighSlipId couldn't be found in alloted items.`);

  const laItemId = allotedItems[allotedItemIndex]?.laItemId;

  const laItem = allLAItems.find((e) => e.id === laItemId);
  const weighSlip = allWeighSlips.find((e) => e.id === weighSlipId);

  if (
    laItem &&
    weighSlip &&
    laItemDetails.findIndex((e) => e.id === laItem.id) < 0 &&
    weighSlips.findIndex((e) => e.id === weighSlip.id) < 0
  ) {
    laItemDetails.push(laItem);
    weighSlips.push(weighSlip);
    allotedItems.splice(allotedItemIndex, 1);
    allotedWeight -= itemWeight;
    weightToAllot += itemWeight;
  }

  return {
    allotedItems,
    weighSlips,
    laItemDetails,
    selectedWeighSlipID: weighSlips.length > 0 ? weighSlips[0].id : false,
    allotedWeight,
    weightToAllot,
  };
}

function prioritySelectionForItems(selectedWeighSlipID, laItemDetails, la, laWeighSlips) {
  const selectedWeighSlip = laWeighSlips.find(
    (e) => e.id === selectedWeighSlipID
  );

  if (!selectedWeighSlip)
    throw new Error("selectedWeighSlipID could not be found in weigh slips");

  if (isNaN(selectedWeighSlip.loaded_weight))
    throw new Error("invalid weight on weigh slips");

  const currentWeight = (
    parseFloat(selectedWeighSlip.loaded_weight) -
    (laWeighSlips.findIndex((e) => e.id === selectedWeighSlipID) === 0
      ? la?.tare_weight
      : laWeighSlips[
        laWeighSlips.findIndex((e) => e.id === selectedWeighSlipID) - 1
      ]?.loaded_weight)
  ).toFixed(3);
  laItemDetails = laItemDetails
    .map((e) => {
      const item = e;
      const difference = Math.abs(
        currentWeight - parseFloat(item.item_qty)
      ).toFixed(3);

      const maxTolerance = la?.item_category?.max_tolerance_value;
      //const minTolerance = la?.item_category?.min_tolerance_value

      //item.enabled = (parseFloat(difference) <= parseFloat(minTolerance)) && (parseFloat(difference) <= parseFloat(maxTolerance))
      item.enabled = (parseFloat(difference) <= parseFloat(maxTolerance))

      item.difference = difference;
      return item;
    })
    .sort((a, b) => a.difference - b.difference);
  return laItemDetails;
}
