import React from "react";
import Paper from "@material-ui/core/Paper";
import COLORS from "../../../constants/internal/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

function InfoCard(props) {
  return (
    <Paper
      elevation={1}
      style={{
        width: "92%",
        marginTop: "6%",
        padding: "10px 5px 5px",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
      }}
    >
      {props.infoLoader ? (
        <div
          style={{
            minHeight: "13vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ display: "flex", padding: "10px 8px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              justifyContent: "center",
            }}
          >
            <span> {props.title_1}</span>
            <span>{props.title_2}</span>
            <span style={{ color: "grey", fontSize: "12px" }}>
              {props.title_value}
            </span>
          </div>
          <div
            id="horizontal"
            style={{
              width: "1px",
              background: COLORS.grey,
              opacity: "0.7",
              margin: "0px 2px",
            }}
          />
          <div style={{ width: "100%" }}>
            {props.itemShow ? (
              props.laDetailsInWeighbridge.loading_advice_items.map((data) => (
                <div
                  style={{
                    display: "flex",
                    // width: "72%",
                    justifyContent: "space-between",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column", color: "#839192", padding: "5px" }}>
                    <span style={{ height: "16px" }}>{data.item}</span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ height: "16px" }}>{data.item_qty}</span>
                  </div>
                </div>
              ))
            ) : props.itemShowInDC ? (
              props.laDetailsInDispatchCounter.loading_advice_items.map(
                (data) => (
                  <div
                    style={{
                      display: "flex",
                      // width: "72%",
                      justifyContent: "space-between",
                      fontSize: "14px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", color: "#839192", padding: "5px" }}>
                      <span style={{ height: "16px" }}>
                        {data.item.item_name}
                      </span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ height: "16px" }}>{data.item_qty}</span>
                    </div>
                  </div>
                )
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  // width: "72%",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", color: "#839192", padding: "5px" }}>
                  <span style={{ height: "19px" }}>{props.key_1}</span>
                  <span style={{ height: "19px" }}>{props.key_2}</span>
                  <span style={{ height: "19px" }}>{props.key_3}</span>
                  <span style={{ height: "19px" }}>{props.key_4}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>
                  <span style={{ height: "19px", display: "flex", justifyContent: "flex-end" }}>{props.value_1}</span>
                  <span style={{ height: "19px", display: "flex", justifyContent: "flex-end" }}>{props.value_2}</span>
                  <span style={{ height: "19px", display: "flex", justifyContent: "flex-end" }}>{props.value_3}</span>
                  <span style={{ height: "19px", display: "flex", justifyContent: "flex-end" }}>{props.value_4}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )
      }
    </Paper >
  );
}
export default InfoCard;
