import React, { Component } from "react";
import WebLayout from "../../../layouts/b2b/WebLayout";
import "../../../styles/b2b/web/B2bProfileChangePasswordWeb.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  primaryColor,
  bottomNavigationFontColor,
} from "../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
// import Slide from "@material-ui/core/Slide";
import SnackbarComponent from "../../common/SnackbarComponent";
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { PasswordLoginValid } from "../../../methods";

const styles = {
  b2bProfileChangePasswordWebMainBody: {
    // paddingTop: "80px",
    // height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "100%",
  },
  b2bProfileChangePasswordWebInternalBody: {
    height: "100%",
    padding: "40px 0px 20px 0px",
  },
  myProfileButtonStyle: {
    textTransform: "capitalize",
    fontSize: "1.1em",
    color: "#1B388B",
  },
  textFieldStyle: {
    width: "92%",
  },
  SaveButtonStyle: {
    height: "100%",
    width: "100%",
    color: "white",
    fontWeight: "bold",
    backgroundColor: primaryColor,
  },
};

class B2bProfileChangePasswordWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      newPasswordLengthError: false
    };
  }
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "newPassword") {
      !PasswordLoginValid(event.target.value) ?
        this.setState({ newPasswordLengthError: true, }) : this.setState({ newPasswordLengthError: false, })
    }
  };

  handleClickShowOldPassword = () => {
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    })
  }

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    })
  }

  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    })
  }

  handleChangePassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
        open: true,
        Transition: "up",
      });
    } else {
      this.props
        .changePassword(this.state.oldPassword, this.state.newPassword)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ open: true, Transition: "up" }, () => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 3000);
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            this.setState({ oldPasswordError: true });
          }
        });
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;
    const {
      newPasswordLengthError,
      confirmPasswordLenghtError,
      passwordNotMatch,
      oldPasswordError,
      noInternetConnection,
      showOldPassword,
      showNewPassword,
      showConfirmPassword,
    } = this.state;
    return (
      <WebLayout appBar profileSelect>
        <div className={classes.b2bProfileChangePasswordWebMainBody}>
          <div className={classes.b2bProfileChangePasswordWebInternalBody}>
            <div className="b2b-profile-change-password-web-header-button-container">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile");
                }}
                size="small"
                className={classes.myProfileButtonStyle}
              >
                My Profile
              </Button>
              {/* <ChevronRight style={{ color: primaryColor }} />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile/settings");
                }}
                size="small"
                className={classes.myProfileButtonStyle}
              >
                settings
              </Button> */}
              <ChevronRight style={{ color: "#A3A3A3" }} />
              <span style={{ color: "#A3A3A3" }}>Change Password</span>
            </div>
            <br />
            <div className="b2b-profile-change-password-web-form-container">
              <TextField
                name="oldPassword"
                required
                id="outlined-name"
                label="Old Password"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                type={showOldPassword ? "text" : "password"}
                onChange={this.handleTextChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowOldPassword}
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>

                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "0px" },
                }}
                onFocus={() => {
                  this.setState({ oldPasswordError: false });
                }}
                helperText={
                  <span style={{ color: "red" }}>
                    {oldPasswordError ? "Old Password Is Invalid" : null}
                  </span>
                }
                error={oldPasswordError ? true : false}
              />
              <br />
              <TextField
                name="newPassword"
                required
                id="outlined-name"
                label="New Password"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowNewPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>

                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: "1.1em", marginTop: "-4px" },
                }}
                onChange={this.handleTextChange}
                onFocus={() => {
                  this.setState({
                    newPasswordLengthError: false,
                    passwordNotMatch: false,
                  });
                }}
                onBlur={() => {
                  if (this.state.newPassword.length < 6) {
                    this.setState({ newPasswordLengthError: true });
                  }
                }}
                error={newPasswordLengthError ? true : false}
                helperText={
                  <span style={{ color: "red" }}>
                    {newPasswordLengthError
                      ? "Password must be 8 to 15 characters in length with one lowercase,one numeric value and one special character!"
                      : ""}
                  </span>
                }
              />
              <br />
              <div
                style={{
                  width: "92%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <span
                  style={{
                    color: bottomNavigationFontColor,
                    fontSize: "0.8em",
                  }}
                >
                  Password strength
                </span>
                <span
                  style={{
                    backgroundColor:
                      this.state.newPassword &&
                        this.state.newPassword.length >= 2 &&
                        this.state.newPassword.length < 4
                        ? "#e68948"
                        : this.state.newPassword &&
                          this.state.newPassword.length >= 4 &&
                          this.state.newPassword.length < 7
                          ? "#4986c2"
                          : this.state.newPassword &&
                            this.state.newPassword.length >= 7
                            ? "#c6d430"
                            : "gainsboro",
                    width: "13%",
                    height: "10px",
                    border: "1px solid #9E9E9E",
                    borderRadius: "10px",
                  }}
                />
                <span
                  style={{
                    backgroundColor:
                      this.state.newPassword &&
                        this.state.newPassword.length >= 3 &&
                        this.state.newPassword.length < 5
                        ? "#e68948"
                        : this.state.newPassword &&
                          this.state.newPassword.length >= 5 &&
                          this.state.newPassword.length < 8
                          ? "#4986c2"
                          : this.state.newPassword &&
                            this.state.newPassword.length >= 8
                            ? "#c6d430"
                            : "gainsboro",
                    width: "13%",
                    height: "10px",
                    border: "1px solid #9E9E9E",
                    borderRadius: "10px",
                  }}
                />
                <span
                  style={{
                    backgroundColor:
                      this.state.newPassword &&
                        this.state.newPassword.length >= 4 &&
                        this.state.newPassword.length < 6
                        ? "#e68948"
                        : this.state.newPassword &&
                          this.state.newPassword.length >= 6 &&
                          this.state.newPassword.length < 8
                          ? "#4986c2"
                          : this.state.newPassword &&
                            this.state.newPassword.length >= 8
                            ? "#c6d430"
                            : "gainsboro",
                    width: "13%",
                    height: "10px",
                    border: "1px solid #9E9E9E",
                    borderRadius: "10px",
                  }}
                />
                <span
                  style={{
                    backgroundColor:
                      this.state.newPassword &&
                        this.state.newPassword.length >= 5 &&
                        this.state.newPassword.length < 6
                        ? "#e68948"
                        : this.state.newPassword &&
                          this.state.newPassword.length >= 6 &&
                          this.state.newPassword.length < 8
                          ? "#4986c2"
                          : this.state.newPassword &&
                            this.state.newPassword.length >= 8
                            ? "#c6d430"
                            : "gainsboro",
                    width: "13%",
                    height: "10px",
                    border: "1px solid #9E9E9E",
                    borderRadius: "10px",
                  }}
                />
              </div>

              <TextField
                name="confirmPassword"
                required
                id="outlined-name"
                label="Confirm Password"
                margin="normal"
                variant="outlined"
                className={classes.textFieldStyle}
                type={showConfirmPassword ? "text" : "password"}
                onChange={this.handleTextChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>

                    </InputAdornment>
                  ),
                }}
                onFocus={() => {
                  this.setState({
                    confirmPasswordLenghtError: false,
                    passwordNotMatch: false,
                  });
                }}
                onBlur={() => {
                  if (this.state.confirmPassword.length < 6) {
                    this.setState({ confirmPasswordLenghtError: true });
                  }
                  if (
                    this.state.newPassword.length >= 6 &&
                    this.state.confirmPassword.length >= 6 &&
                    this.state.newPassword !== this.state.confirmPassword
                  ) {
                    this.setState({ passwordNotMatch: true });
                  }
                }}
                error={
                  confirmPasswordLenghtError
                    ? true
                    : passwordNotMatch
                      ? true
                      : false
                }
                helperText={
                  <span style={{ color: "red" }}>
                    {confirmPasswordLenghtError
                      ? "Password must be 8 to 15 characters in length with one lowercase,one numeric value and one special character!"
                      : passwordNotMatch
                        ? "Password did not match"
                        : null}
                  </span>
                }
              />
              <br />
              <Button
                disabled={
                  this.state.oldPassword === "" ||
                  this.state.newPassword.length < 6 ||
                  this.state.confirmPassword.length < 6 ||
                  this.state.newPassword !== this.state.confirmPassword
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.handleChangePassword();
                }}
                variant="contained"
                color="primary"
                //className={classes.SaveButtonStyle}
                style={{
                  height: "100%",
                  width: "92%",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: primaryColor,
                }}
              >
                Save
              </Button>
              <br />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
                color="primary"
              // className={classes.SaveButtonStyle}
              >
                Cancel
              </Button>

            </div>


            {noInternetConnection ?
              <Snackbar
                autoHideDuration={3000}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={this.state.Transition}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                message={
                  <span id="message-id">
                    {noInternetConnection ? "No Internet Connection" : "Password has been changed"}
                  </span>
                }
              /> :
              <SnackbarComponent
                autoHideDuration={3000}
                open={this.state.open}
                // handleClose={this.handleClose}

                // open={this.state.snackbarData.openSnackbar}
                handleClose={this.handleCloseSnackbar}
                snackbarMessage={noInternetConnection ? "No Internet Connection" : "Password has been changed"}
                type={noInternetConnection ? "" : "success"}
              />}
          </div>
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(B2bProfileChangePasswordWeb);
