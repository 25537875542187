import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  darkGreyColor,
  primaryColor,
  secondaryColor
} from "../../../styles/colors";
import Close from "@material-ui/icons/Close";
import { pxToEm } from "../../../methods";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  drawerBackground,
  earthBlue,
  grey,
  secondaryBlueText,
  secondaryGrey
} from "../../../constants/internal/colors";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import TileSkeleton from "../common/TileSkeleton";
import { isEmpty } from "lodash";

const styles = {
  topContainer: {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    alignItems: "center",
  },
  ledgerRequestContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: '90%',
  },
  ledgerRequestInnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: '100%',
    padding: '10px 0',
    // margin: '5px 0'
  },
  nameTitle: {
    color: secondaryColor,
    fontSize: '1.5em'
  },
  subTitle: {
    color: darkGreyColor,
    fontSize: "0.8em"
  },
  radioTitleLabel: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(17) : "1.19em",
    fontWeight: 500,
    marginBottom: 20
  },
  radioOptionCardMainBody: {
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
    // height: window.innerWidth < 375 ? "8vh" : "10vh",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "10px 12px",
    fontSize: window.innerWidth < 375 && pxToEm(14),
    margin: "6px 0px",
    fontFamily: 'Poppins',
    fontSize: 16
  },
  dateContainer: {
    width: '100%',
    marginBottom: 10,
  },
  infoText: {
    color: darkGreyColor,
    fontSize: "0.9em",
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    margin: '10px 0',
    padding: '0 20px',
    width: '100%',
  },
  discardButton: {
    color: grey,
    backgroundColor: 'inherit',
    marginLeft: 0,
    marginRight: 20,
    border: 0,
    fontSize: pxToEm(17),
    fontWeight: 600,
    textTransform: 'none',
  },
  dialogContainer: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "15px 20px 20px 20px"
  },
  dialogTop: {
    display: "flex",
    justifyContent: "flex-end",
    // padding: "10px 26px",
    height: "5vh",
    alignItems: "center",
  },
  dialogContentContainer: {
    padding: "0px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.2em",
    height: "10vh",
  },
  dialogButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "8vh",
  },
  dialogButtonShow: {
    width: "90%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  goBackButton: {
    color: primaryColor,
    textTransform: "capitalize",
  },
  yesButtonStyle: {
    width: "78px",
    backgroundColor: primaryColor,
    padding: "8px 24px",
  },
};

const RadioOption = [
  { key: 'Debtor A/C', value: "1" },
  { key: 'Sales Return/Purchase A/C', value: "2" },
  { key: 'Brokerage A/C', value: "3" },
]

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
        fontFamily: 'Poppins',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
      fontFamily: 'Poppins',
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
        fontFamily: 'Poppins',
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
        fontFamily: 'Poppins',
      },
      dayDisabled: {
        color: secondaryGrey,
        fontFamily: 'Poppins',
      },
      current: {
        color: drawerBackground,
        fontFamily: 'Poppins',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});


class LedgerRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      dateError: false
    };
  }

  componentDidMount() {

  }

  handleClose = () => {
    this.setState({ openDialog: false });
  }

  // handleToDateRange = () => {
  //   let A = new Date(this.props.fromDate)
  //   let year = (A.getMonth() >= 0 && A.getMonth() <= 2)
  //     ? A.getFullYear()
  //     : (A.getFullYear() + 1)
  //   let B = new Date(year, 2, 31)
  //   console.log(new Date(B) > new Date() ? new Date() : new Date(B))
  //   return new Date(B) > new Date() ? new Date() : new Date(B)
  // }

  handleDateError = (e) => {
    console.log("E :", e, Boolean(e))
    if (Boolean(e)) {
      this.setState({ dateError: true })
    }
    else {
      this.setState({ dateError: false })
    }
  }

  render() {
    const {
      isMobile,
      classes,
      fromDate,
      toDate,
      ledgerRequestData,
      ledgerType,
      user
    } = this.props;
    const {
      openDialog,
      dateError,
    } = this.state;
    console.log(this.props)
    return (
      <div style={styles.topContainer}>
        <div style={{
          padding: "10px 0px",
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              this.setState({ openDialog: true });
            }}
          >
            <Close />
          </IconButton>
          <span
            style={{
              marginLeft: "7px",
              color: darkGreyColor,
              fontWeight: 600,
              fontSize: pxToEm(20)
            }}>
            My Ledger Requests
          </span>
        </div>
        {isEmpty(ledgerRequestData)
          ? (<TileSkeleton skeletonType="ledger_request" />)
          :
          <>
            <div style={styles.ledgerRequestContainer}>
              <div style={styles.ledgerRequestInnerContainer}>
                <span style={styles.nameTitle}>
                  {ledgerRequestData?.company_name || ""}
                </span>
                <span style={styles.subTitle}>
                  {console.log(this.props.user)}
                  {user?.employee_id}
                </span>
                <span style={styles.subTitle}>
                  {ledgerRequestData?.pan !== ''
                    ? `PAN: ${ledgerRequestData?.pan}`
                    : `GSTIN: ${ledgerRequestData?.gst}`
                  }
                </span>
              </div>
              {console.log(ledgerRequestData)}
              <div style={styles.ledgerRequestInnerContainer}>
                <FormControl style={{ width: '100%' }}>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    style={styles.radioTitleLabel}
                  >
                    Ledger Type*
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={ledgerType}
                  // onChange={handleChange}
                  >
                    {ledgerRequestData?.acc_code_all &&
                      ledgerRequestData?.acc_code_all.map(data => {
                        return (
                          <FormControlLabel
                            style={styles.radioOptionCardMainBody}
                            key={data?.account_type}
                            value={data.account_type}
                            disableTypography={true}
                            control={
                              <Radio
                                style={{
                                  color: secondaryColor,
                                  transform: window.innerWidth < 375 && 'scale(0.8)'
                                }}
                              />
                            }
                            label={
                              data.account_type === "D"
                                ? 'Debtor Account'
                                : data.account_type === "G"
                                  ? 'Sales Return/Purchase Account'
                                  : data.account_type === "K" &&
                                  'Brokerage Account'
                            }
                            onChange={(e) => {
                              e.preventDefault();
                              this.props.handleLedgerType(data.account_type);
                            }}
                          />
                        )
                      })
                    }
                  </RadioGroup>
                </FormControl>
              </div>
              <div style={styles.ledgerRequestInnerContainer}>
                <div style={styles.dateContainer}>
                  <Fragment>
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.textField}
                          name="fromDate"
                          id="date"
                          style={{
                            width: '100%',
                            fontSize: window.innerWidth < 375 && pxToEm(14),
                            fontFamily: 'Poppins'
                          }}
                          inputVariant="outlined"
                          label="From Date *"
                          format="dd/MM/yyyy"
                          minDate={new Date('2020', '03', '01')}
                          maxDate={new Date()}
                          margin="normal"
                          value={fromDate}
                          onChange={(e) => this.props.handleDateChange('fromDate', e)}
                          placeholder="e.g.: DD/MM/YYYY"
                          InputAdornmentProps={{ position: "end" }}
                          InputProps={{
                            className: classes.datePicker,
                            readOnly: true,
                            style: {
                              fontSize: window.innerWidth < 375 && pxToEm(15.5),
                              fontFamily: 'Poppins'
                            }
                          }}
                          onError={this.handleDateError}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </Fragment>
                </div>
                <div style={styles.dateContainer}>
                  <Fragment>
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.textField}
                          name="toDate"
                          id="date"
                          style={{
                            width: '100%',
                            fontSize: window.innerWidth < 375 && pxToEm(14),
                            fontFamily: 'Poppins'
                          }}
                          inputVariant="outlined"
                          label="To Date *"
                          format="dd/MM/yyyy"
                          minDate={new Date(fromDate)}
                          maxDate={
                            new Date(
                              (new Date(fromDate).getMonth() >= 0 && new Date(fromDate).getMonth() <= 2)
                                ? new Date(fromDate).getFullYear()
                                : (new Date(fromDate).getFullYear() + 1),
                              2,
                              31) > new Date()
                              ? new Date()
                              : new Date(
                                (new Date(fromDate).getMonth() >= 0 && new Date(fromDate).getMonth() <= 2)
                                  ? new Date(fromDate).getFullYear()
                                  : (new Date(fromDate).getFullYear() + 1),
                                2,
                                31)
                          }
                          margin="normal"
                          value={toDate}
                          onChange={(e) => this.props.handleDateChange('toDate', e)}
                          placeholder="e.g.: DD/MM/YYYY"
                          InputAdornmentProps={{ position: "end" }}
                          InputProps={{
                            className: classes.datePicker,
                            readOnly: true,
                            style: {
                              fontSize: window.innerWidth < 375 && pxToEm(15.5),
                              fontFamily: 'Poppins'
                            }
                          }}
                          onError={this.handleDateError}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </Fragment>
                </div>
                <span style={styles.infoText}>
                  1. &nbsp;Send email of your ledger to sales@realdeals.pro
                </span>
                <span style={styles.infoText}>
                  2. Date Range cannot be more than 397 days from Today
                </span>
                <span style={styles.infoText}>
                  3. Date Range cannot be from two different Financial Years
                </span>
              </div>
              <div style={styles.ledgerRequestInnerContainer}>
                <div style={styles.buttonContainer}>
                  <Button
                    variant="text"
                    style={styles.discardButton}
                    onClick={() => {
                      this.setState({ openDialog: true });
                    }}
                  >
                    Discard
                  </Button>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                    <Button
                      variant="contained"
                      disabled={ledgerRequestData.total_request > 0 || dateError}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.handleSubmitButton()
                      }}
                      style={{
                        width: 120,
                        backgroundColor: ledgerRequestData.total_request === 0 && !dateError
                          ? primaryColor
                          : 'rgba(0, 0, 0, 0.12)',
                        color: ledgerRequestData.total_request === 0 && !dateError
                          ? '#FFF'
                          : 'rgba(0, 0, 0, 0.12)',
                        fontSize: pxToEm(20),
                        fontWeight: 600,
                        textTransform: 'none',
                        padding: '8px 28px'
                      }}
                    >
                      Submit
                    </Button>
                    <FormHelperText
                      style={{ fontSize: "0.8em", color: secondaryGrey, textAlign: 'center' }}
                    >
                      {ledgerRequestData.total_request === 0 && !dateError
                        ? '1 request allowed per day'
                        : dateError
                          ? 'Invalid Date'
                          : 'Cannot request anymore ledgers today'
                      }
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
            <Dialog
              open={openDialog}
              onClose={this.handleClose}
              aria-labelledby="simple-dialog-title"
              PaperProps={{
                style: {
                  width: !isMobile && "312px",
                  height: !isMobile && '235px',
                  overflowY: "inherit",
                  borderRadius: "15px",
                  alignItems: 'flex-start',
                  padding: 8
                },
              }}
            >
              <div style={styles.dialogContainer}>
                <div className={classes.dialogTop} style={{ height: !isMobile && 30 }}>
                  <Close
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleClose()
                    }}
                  />
                </div>
                <div className={classes.dialogContentContainer} style={{ height: !isMobile && 120 }}>
                  <span>Are you sure want to cancel this Ledger Request ?</span>
                </div>
                <div className={classes.dialogButtonContainer} style={{ height: !isMobile && 30 }}>
                  <div className={classes.dialogButtonShow}>
                    <Button className={classes.goBackButton}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleClose()
                      }}
                    >
                      Go Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.yesButtonStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/b2bProfile");
                        this.props.handleClearState()
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              </div>
            </Dialog>
          </>
        }
      </div>
    );
  }
}
export default withStyles(styles)(LedgerRequest);
