import React, { Component } from 'react'
import { Button, makeStyles } from "@material-ui/core";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Print from "@material-ui/icons/Print";
import Tooltip from "@mui/material/Tooltip";
import Moment from 'moment'
import imageCode from "./ImageCode.json"
import image from "../../../../styles/icon.png"
import { plantTableHeaders } from '../headers'
import invoiceData from './invoiceData.json'
import moment from 'moment';
import { printPDFInMobile } from '../../../../methods';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
})


let invoices

export class ReportsPDFDownload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tableData: [],
            tabValue: "",
        }
    }

    // groupedItems = key => array => items.reduce((groups, item) => {
    //     const groupKey = item.la_number;
    //     if (!groups[groupKey]) {
    //         groups[groupKey] = [];
    //     }
    //     groups[groupKey].push(item);
    //     return groups;
    // }, {});

    groupByA = (array, key) => {
        return array.reduce((groups, item) => {
            const groupKey = item[key];
            if (!groups[groupKey]) {
                groups[groupKey] = [];
            }
            groups[groupKey].push(item);
            return groups;
        }, {});
    }


    groupBy = key => array =>
        array.reduce((objectsByKeyValue, obj) => {
            if (obj[key]) {
                const value = obj[key];
                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            }
            return objectsByKeyValue;
        }, {});

    handleCalculations = (data) => {
        const { list, pdfProps, tableHeaders, headers } = data
        let calculationNeededFor = []
        headers.forEach(header => {
            if (header.calculation) {
                calculationNeededFor.push(header)
            }
        })
        // console.log("here", calculationNeededFor)

        // const groupedItems = this.groupByA(items, 'la_number');
        // console.log(groupedItems);
        // console.log(this.groupedItems);
        let groupByLA = this.groupBy('la_number')
        let groupedList = groupByLA(list)
        console.log(groupedList)
        Object.keys(groupedList).forEach(element => {
            // console.log(element)
            if (element !== "undefined")
                groupedList[element].forEach((ele, i) => {
                    // console.log(element, ele.index, `${ele.index}.${i + 1}`)

                    ele.index = `${ele.index}.${i + 1}`
                })

        })
        // Logic for SubTotal Only
        Object.keys(groupedList).forEach(element => {
            // groupedList[element]
            // let index = indexOf(element.findLast(element))
            let fromIndex = list.findIndex(ele => ele.la_number === element)
            let toIndex = list.findLastIndex(ele => ele.la_number === element)
            // console.log(fromIndex, toIndex, list)
            // if (fromIndex !== toIndex) {
            // console.log(element, element.index)
            let calcObj = {}
            calculationNeededFor.forEach(calc => {
                let calcValue = 0
                let key = calc.key
                // let indexCopy = 0
                for (let i = fromIndex; i <= toIndex; i++) {
                    let item = list[i]
                    calcValue += Number(item[key])
                    // let index = list[i].index
                    // console.log(index, indexCopy)
                    // list[i].index = `${list[i].index}.${indexCopy + 1}`
                    // indexCopy++
                    // console.log(item[key], calcValue)
                }

                // let insertingIndex = list[toIndex + 1]
                // insertingIndex[key] = calcValue
                calcObj[key] = calc.calculation === "avg_rate"
                    || calc.calculation === "avg_discount"
                    || calc.calculation === "avg_tare_weight" ?
                    (calcValue / (toIndex - fromIndex + 1)).toFixed(calc.dec_places)
                    : calc.dec_places ? calcValue.toFixed(calc.dec_places) : calcValue
            })
            calcObj["invoice_date"] = "SUB TOTAL"
            calcObj["party_ref_date"] = ""
            calcObj["isSubTotalRow"] = true
            list.splice(toIndex + 1, 0, calcObj)
            // }
            // list.forEach((listElement, i) => {
            //     let index
            //     if (listElement.la_number === element) {
            //         index = i
            //         console.log(element, index)
            //     }
            // })
            // let index = list.findLastIndex(element)
            // console.log(groupedList[element])
        })
        invoices = list

        this.handlePDFDownload(pdfProps, tableHeaders, headers)
        // console.log()
    }
    fetchHeaders = (pdfProps) => {
        // console.log(pdfProps)
        let tableHeaders = []
        let headers = []
        // if (pdfProps.tabValue === 1)
        //     headers = plantTableHeaders(pdfProps.tabValue, pdfProps?.datafor, "pdf")
        // else
        headers = plantTableHeaders(pdfProps.tabValue, pdfProps?.datafor)
        // console.log(headers)
        headers.map(head => {
            tableHeaders.push({
                text: head.header,
                style: ["itemsHeader", "center"],
                border: [false, false, false, true],
            })
        })

        // console.log(tableHeaders)
        if (headers) {
            // this.generateData(pdfProps, tableHeaders, headers)
            this.handleCalculations({ list: JSON.parse(JSON.stringify(pdfProps.data)), pdfProps, tableHeaders, headers })

        }
    }
    handlePDFDownload = (pdfProps, tableHeaders, headers) => {
        // console.log("Download Started......", pdfProps)
        console.log(invoices)
        const { tabValue } = pdfProps
        let tableDetails = []
        let tableData = []

        // total width in landscape = 660
        let columnWidths = []
        let table2Widths = []
        let tableData2 = []
        let headerColumnWidths = ["50%", "50%"]

        // let selectedPlants = pdfProps.plants && pdfProps.plants.length === 0 ? 'All' : pdfProps.plants.length > 0 ? pdfProps.plants.join(', ') : null
        // let SelectedItemCats = pdfProps.itemCats && pdfProps.itemCats.length === 0 ? 'All' : pdfProps.itemCats.length > 0 ? pdfProps.itemCats.join(', ') : null

        // console.log(invoices)
        tableDetails.push([
            {
                text: `Report name - ${pdfProps.reportName}_${moment(new Date()).format("DD-MMM-yyyy_HH:mm:ss")}`,
                style: "leftAlignDetails",
                border: [false, false, false, false],
            },
            {
                text: `Date Range - From ${(Moment(pdfProps.startDate).format('DD-MMM-yyyy'))} To ${(Moment(pdfProps.endDate).format('DD-MMM-yyyy'))}`,
                style: "rightAlignDetails",
                border: [false, false, false, false],
            },
        ])

        tableDetails.push([
            {
                text: `Date of Generation - ${(Moment(new Date()).format('DD-MMM-yyyy HH:mm'))}`,
                style: "leftAlignDetails",
                border: [false, false, false, false],
            },
            {
                text: `User Name - ${pdfProps.user.first_name} ${pdfProps.user.last_name}`,
                style: "rightAlignDetails",
                border: [false, false, false, false],
            },
        ])

        // tableDetails.push([
        //     {
        //         text: `Plant name - ${selectedPlants}`,
        //         style: "leftAlignDetails",
        //         border: [false, false, false, false],
        //     },
        //     {
        //         text: `Item Category name - ${SelectedItemCats}`,
        //         style: "rightAlignDetails",
        //         border: [false, false, false, false],
        //     },
        // ])
        // tableDetails.push([
        //     {
        //         text: pdfProps?.state?.calculations?.total_qty ? `Total Quantity - ${parseFloat(pdfProps?.state?.calculations?.total_qty).toFixed(3)} MT` : "",
        //         style: "leftAlignDetails",
        //         border: [false, false, false, false],
        //     },
        //     {
        //         text: ``,
        //         style: "rightAlignDetails",
        //         border: [false, false, false, false],
        //     },
        // ])

        // tableData.push(tableHeaders)
        columnWidths = tabValue === 0 ? [15, 30, 20, 50, 45, 30, 30, 30, 30, 30, 45, 30, 27, 35, 35, 40, 30, 35, 35] :
            tabValue === 1 ? [10, 30, 40, 40, 35, 35, 35, 30, 30, 35, 40, 35, 35, 30, 30, 30, 30, 30, 40] :
                tabValue === 2 ? [15, 40, 30, 45, 40, 35, 40, 40, 35, 40, 40, 35, 40, 40, 40, 35, 40] :
                    tabValue === 3 ? [15, 40, 50, 80, 80, 70, 70, 60, 65, 60, 30, 30] :
                        // tabValue === 3 ? [15, 60, 60, 70, 70, 70, 70, 60, 65, 60, 30] :
                        []
        // console.log(tableData)


        // if (pdfProps.tabValue === 0) {
        //     columnWidths = [15, 50, 30, 50, 50, 50, 50, 50, 55, 50, 40, 45, 40, 40, 40]
        tableData.push([
            // {
            //     text: "#",
            //     border: [false, false, false, true],
            //     style: ["itemsHeader", "center"],
            // },
        ])
        headers.forEach(head => {
            // console.log(tableData.length)
            tableData[0].push(
                {
                    text: head.header,
                    style: ["itemsHeader", "center"],
                    border: [false, false, false, true],
                }
            )
        })
        console.log(invoices.length)
        let count = 0
        invoices.map((item, i) => {
            tableData.push([
                // {
                //     text: "",
                //     border: [false, false, false, true],
                //     style: item.isSubTotalRow ? ["subTotal", "center"] : ["tableItems", "center"],
                // },
            ])
            if (i === invoices.length - 1) {
                tableData.push([
                    // {
                    //     text: "",
                    //     border: [false, false, false, true],
                    //     style: ["subTotal", "center"],
                    // },
                ])
                console.log(tableData)
            }
            // if (i === pdfProps.data.length - 1 && tabValue === 3) {

            // } else {
            headers.forEach(head => {
                console.log(head.key, item, item[head.key])
                tableData[i + 1].push(
                    {
                        text: item[head.key],
                        style: item.isSubTotalRow ? ["subTotal", "center"] : ["tableItems", "center"],
                        border: [false, false, false, true],
                    }
                )
                if (i === invoices.length - 1) {
                    count++
                    // console.log(tableData[i + 2])
                    // console.log(head.calculation)
                    if (head.calculation) {
                        console.log("true", head.calculation, pdfProps?.state?.calculations[head.calculation])
                        tableData[i + 2].push({
                            text: pdfProps?.state?.calculations[head.calculation] || pdfProps?.state?.calculations[head.calculation] === 0 ? `${head.calc_text} = ${pdfProps?.state?.calculations[head.calculation]}` : "",
                            style: ["subTotal", "center"],
                            border: [false, false, false, true],
                        })
                    } else if (head.key === "invoice_date") {
                        console.log("true", head.calculation)
                        tableData[i + 2].push({
                            text: "GRAND TOTAL",
                            style: ["subTotal", "center"],
                            border: [false, false, false, true],
                        })
                    } else {
                        console.log("false", head.calculation)
                        tableData[i + 2].push({
                            text: "",
                            style: ["subTotal", "center"],
                            border: [false, false, false, true],
                        })
                    }

                    // console.log(tableData[i + 2])
                }
            })
            // }
            // console.log(tableData)
        })
        // }

        // console.log(pdfProps)
        console.log(tableData)
        console.log(count)
        const dd = this.docdef(pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths)

        // pdfProps.tabValue === 1 ?
        // this.contractsDocdef(pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths) :
        const date = new Date()
        const reportName = `${pdfProps.reportName}_${Moment(date).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`
        if (!window.cordova) {
            console.log("herere22", reportName)
            pdfMake
                .createPdf(dd)
                .download(reportName, () => pdfProps.handleOpenSnackClick());
        }
        else {
            let fileName = `${pdfProps.reportName}`
            printPDFInMobile(pdfMake, dd, fileName, pdfProps.handleOpenSnackClick, '')
        }
    }


    docdef = (pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths) => {
        // console.log("called")
        return {
            // pageSize: "A2",
            pageOrientation: 'landscape',
            header: function (currentPage) {
                // console.log(imageCode)
                if (currentPage === 1)
                    return {
                        columns: [
                            {
                                image: imageCode,
                                width: 50,
                                height: 50,
                                margin: [10, 0, 0, 0]
                            }
                        ]
                    }
            },
            // footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
            footer: function (currentPage, pageCount) {
                return {
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                // { text: 'Confidential', alignment: 'center' },
                                { text: 'page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 5, 35, 0], fontSize: 7, }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                };
            },

            content:
                // function (currentPage) {
                //     console.log(pdfProps.tabValue)
                //     if (currentPage === 1)
                //         return 
                [
                    // "dbuhgieckhn iojhmwgmni fkjkenbfk jnemfnkujen .c",
                    {
                        // style: 'tableExample',
                        table: {
                            body: tableDetails,
                            widths: headerColumnWidths,
                            dontBreakRows: true,
                        },
                        margin: [0, 10, 0, 0]
                    },
                    {
                        // style: 'tableExample',s
                        table: {
                            body: tableData,
                            widths: columnWidths,
                            dontBreakRows: true,

                        },
                        margin: [-20, 10, 0, 0],
                        // pageBreak: "after",
                    },
                    // {
                    //     style: 'tableExample',
                    //     table: {
                    //         body: tableData2,
                    //         widths: table2Widths,
                    //         dontBreakRows: true,
                    //     },
                    //     margin: [0, 15, 0, 0],
                    // },


                    // }
                    ,
                    // {
                    //     style: 'tableExample',
                    //     table: {
                    //         body: tableData,
                    //         widths: columnWidths,
                    //         dontBreakRows: true,

                    //     },
                    //     margin: [0, 15, 0, 0],
                    //     pageBreak: "after",
                    // },
                ],
            // pdfProps.tabValue === 1 || pdfProps.tabValue === 2 || pdfProps.tabValue === 3 &&

            styles: {
                itemsHeader: {
                    padding: [0, 2, 0, 2],
                    // fontSize: 8,
                    fontSize: 6,
                    bold: true,
                    fillColor: "#f5f5f5",
                    alignment: "center",
                },
                tableItems: {
                    padding: [0, 2, 0, 2],
                    fontSize: 5,
                    alignment: "center",

                },
                subTotal: {
                    padding: [0, 1, 0, 1],
                    fontSize: 5,
                    alignment: "center",
                    fillColor: "#f5f5f5"
                },
                leftAlignDetails: {
                    alignment: "left",
                    // margin: [0, 15, 0, 0],
                    fontSize: 10,
                },
                rightAlignDetails: {
                    alignment: "right",
                    // margin: [0, 15, 0, 0],
                    fontSize: 10,
                }

            },
        };
    }
    render() {
        // console.log(code)
        // const classes = useStyles()
        return (
            <Tooltip title="Download PDF" placement="top">
                <div>
                    {/* <Button
                        onClick={() => this.handlePDFDownload(this.props)}
                        style={{ backgroundColor: 'green', width: "20px" }}
                    > */}
                    <Print onClick={() => {
                        this.fetchHeaders(this.props)
                    }}
                        style={{ color: "#4b87c5", cursor: "pointer", marginTop: 9, }} />
                    {/* </Button> */}

                </div>

            </Tooltip>
        )
    }
}

export default ReportsPDFDownload