import { Divider } from "@material-ui/core";
import { Skeleton, Stack } from "@mui/material";

const styles = {
  listContainerContractStyle: {
    cursor: "pointer",
    width: window.innerWidth > 770 ? window.innerWidth * 0.5 : window.innerWidth * 0.9,
    // width: '90%',
    height: 145,
    backgroundColor: '#fff',
    borderRadius: "12px",
    margin: "10px 0px",
    padding: "10px 6px",
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    boxShadow:
      "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  orderDetailChildTopBar: {
    width: "100%",
    height: "20%",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 12px 0px",
    alignItems: 'center',
  },
  orderShowMainDetail: {
    padding: "15px 12px",
    display: "flex",
    justifyContent: "space-around",
    height: "80%",
    width: "100%",
    alignItems: "center",
  },
  orderShowMainDetailLeft: {
    width: "42%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  orderShowMainDetailRight: {
    width: "56%",

    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  orderShowMainDetailRightTop: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 0px 3px 10px",
  },
  listContainerStyle: {
    cursor: "pointer",
    width: window.innerWidth > 770 ? window.innerWidth * 0.4 : window.innerWidth * 0.9,
    // width: '90%',
    height: 90,
    backgroundColor: '#fff',
    borderRadius: "12px",
    margin: "10px 0px",
    padding: "10px 6px",
    display: "flex",
    alignItems: "center",
    boxShadow:
      "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  listContainerLeftSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  listContainerMiddleSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "58%",
    height: "100%",
    // alignItems: "center",
    paddingLeft: "5px",
    justifyContent: "center",
  },
  listContainerRightSideStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "22%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  dailyrateBox: {
    // position: "relative",
    backgroundColor: '#fff',
    marginTop: "10px",
    width: window.innerWidth * .33,
    height: 80,
    margin: 4,
    borderRadius: "8px",
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  },
  cardTextContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "6px 10px",
    justifyContent: "center",
  },
  radioOptionCardMainBody: {
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
    // height: window.innerWidth < 375 ? "8vh" : "10vh",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "16px 12px",
    // fontSize: window.innerWidth < 375 && pxToEm(14),
    margin: "6px 0px",
    fontFamily: 'Poppins',
    fontSize: 16
  }
}

const TileSkeleton = (props) => {
  const { skeletonType, tileLength } = props
  return (
    skeletonType === "contracts"
      ?
      [...Array(tileLength || 4).keys()].map((components, index) => {
        return (
          <div style={styles.listContainerContractStyle}>
            <div style={styles.orderDetailChildTopBar}>
              <Skeleton animation="wave" variant="text" width="55%" height={20} />
              <span style={{ display: "flex", alignItems: 'center', width: "25%" }}>
                <Skeleton style={{ marginRight: 5 }} animation="wave" variant="circular" width={10} height={10} />
                <Skeleton animation="wave" variant="text" width="85%" height={20} />
              </span>
            </div>
            <div style={styles.orderShowMainDetail}>
              <div style={styles.orderShowMainDetailLeft}>
                <Skeleton animation="wave" variant="text" width="50%" height={35} />
                <Skeleton animation="wave" variant="text" width="65%" height={35} />
                <Skeleton animation="wave" variant="text" width="80%" height={20} />
                <Skeleton animation="wave" variant="text" width="40%" height={20} />
              </div>
              <Skeleton animation="wave" variant="text" width={4} height={100} />
              <div style={styles.orderShowMainDetailRight}>
                <div style={styles.orderShowMainDetailRightTop}>
                  <Skeleton animation="wave" variant="text" width="40%" height={20} />
                  <Skeleton animation="wave" variant="text" width="24%" height={20} />
                </div>
                <div style={styles.orderShowMainDetailRightTop}>
                  <Skeleton animation="wave" variant="text" width="40%" height={20} />
                  <Skeleton animation="wave" variant="text" width="24%" height={20} />
                </div>
                <div style={styles.orderShowMainDetailRightTop}>
                  <Skeleton animation="wave" variant="text" width="40%" height={20} />
                  <Skeleton animation="wave" variant="text" width="24%" height={20} />
                </div>
              </div>
            </div>
          </div>
        )
      })
      : skeletonType === "dispatch"
        ?
        [...Array(tileLength || 6).keys()].map((components, index) => {
          return (
            <div style={styles.listContainerStyle}>
              <div style={styles.listContainerLeftSideStyle}>
                <Stack spacing={0} style={{ alignItems: 'center' }}>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                  <Skeleton animation="wave" variant="text" width={40} height={15} />
                </Stack>
              </div>
              <div style={styles.listContainerMiddleSideStyle}>
                <Stack spacing={0} style={{ alignItems: 'start' }}>
                  <Skeleton animation="wave" variant="text" width="60%" height={25} />
                  <Skeleton animation="wave" variant="text" width="50%" height={20} />
                  <Skeleton animation="wave" variant="text" width="90%" height={15} />
                </Stack>
              </div>
              <div style={styles.listContainerRightSideStyle}>
                <Skeleton animation="wave" variant="text" width="80%" height={20} />
              </div>
            </div>
          )
        })
        : skeletonType === "notification"
          ?
          [...Array(tileLength || 4).keys()].map((components, index) => {
            return (
              <div style={{ margin: '20px 0' }}>
                <Stack spacing={2}>
                  <Skeleton animation="wave" variant="text" width="35%" height={20} />
                  <div style={{ dispaly: 'flex', flexDirection: 'row' }}>
                    <span style={{ display: "inline-block", width: '20%' }}>
                      <Skeleton style={{ borderRadius: '23%' }} animation="wave" variant="rounded" width={43} height={43} />
                    </span>
                    <span style={{ display: "inline-block", width: '80%' }}>
                      <Skeleton animation="wave" variant="text" width="90%" height={20} />
                      <Skeleton animation="wave" variant="text" width="20%" height={15} />
                    </span>
                  </div>
                  <div style={{ dispaly: 'flex', flexDirection: 'row' }}>
                    <span style={{ display: "inline-block", width: '20%' }}>
                      <Skeleton style={{ borderRadius: '23%' }} animation="wave" variant="rounded" width={43} height={43} />
                    </span>
                    <span style={{ display: "inline-block", width: '80%' }}>
                      <Skeleton animation="wave" variant="text" width="90%" height={20} />
                      <Skeleton animation="wave" variant="text" width="20%" height={15} />
                    </span>
                  </div>
                </Stack>
              </div>
            )
          })
          : skeletonType === "pending actions"
            ?
            [...Array(tileLength || 1).keys()].map((components, index) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '95%', marginBottom: 20, }} >
                  Pending Actions
                  <div style={styles.listContainerContractStyle}>
                    <div style={styles.orderDetailChildTopBar}>
                      <Skeleton animation="wave" variant="text" width="55%" height={20} />
                      <span style={{ display: "flex", alignItems: 'center', width: "25%" }}>
                        <Skeleton style={{ marginRight: 5 }} animation="wave" variant="circular" width={10} height={10} />
                        <Skeleton animation="wave" variant="text" width="85%" height={20} />
                      </span>
                    </div>
                    <div style={styles.orderShowMainDetail}>
                      <div style={styles.orderShowMainDetailLeft}>
                        <Skeleton animation="wave" variant="text" width="50%" height={35} />
                        <Skeleton animation="wave" variant="text" width="65%" height={35} />
                        <Skeleton animation="wave" variant="text" width="80%" height={20} />
                        <Skeleton animation="wave" variant="text" width="40%" height={20} />
                      </div>
                      <Skeleton animation="wave" variant="text" width={4} height={100} />
                      <div style={styles.orderShowMainDetailRight}>
                        <div style={styles.orderShowMainDetailRightTop}>
                          <Skeleton animation="wave" variant="text" width="40%" height={20} />
                          <Skeleton animation="wave" variant="text" width="24%" height={20} />
                        </div>
                        <div style={styles.orderShowMainDetailRightTop}>
                          <Skeleton animation="wave" variant="text" width="40%" height={20} />
                          <Skeleton animation="wave" variant="text" width="24%" height={20} />
                        </div>
                        <div style={styles.orderShowMainDetailRightTop}>
                          <Skeleton animation="wave" variant="text" width="40%" height={20} />
                          <Skeleton animation="wave" variant="text" width="24%" height={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            : skeletonType === "rates"
              ?
              [...Array(tileLength || 4).keys()].map((components, index) => {
                return (
                  <div style={styles.dailyrateBox}>
                    <div style={styles.cardTextContainer}>
                      <Stack spacing={0} style={{ alignItems: 'start' }}>
                        <Skeleton animation="wave" variant="text" width={80} height={15} />
                        <Skeleton animation="wave" variant="text" width={50} height={15} />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Skeleton style={{ marginRight: 5 }} animation="wave" variant="circular" width={8} height={8} />
                          <Skeleton animation="wave" variant="text" width={60} height={10} />
                        </div>
                      </Stack>
                    </div>
                  </div>
                )
              })
              : skeletonType === "pending dispatches"
                ?
                [...Array(tileLength || 2).keys()].map((components, index) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 20 }} >
                      <Skeleton animation="wave" variant="text" width="45%" height={20} />
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, width: '30%', height: 65, justifyContent: 'space-between' }}>
                          <Skeleton animation="wave" variant="text" width="90%" height={15} />
                          <Skeleton animation="wave" variant="text" width="40%" height={10} />
                          <Skeleton animation="wave" variant="text" width="40%" height={10} />
                          <Skeleton animation="wave" variant="text" width="50%" height={15} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 10, width: '30%', height: 65, justifyContent: 'space-between' }}>
                          <Skeleton animation="wave" variant="text" width="90%" height={15} />
                          <Skeleton animation="wave" variant="text" width="40%" height={10} />
                          <Skeleton animation="wave" variant="text" width="40%" height={10} />
                          <Skeleton animation="wave" variant="text" width="50%" height={15} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 10, width: '30%', height: 65, justifyContent: 'space-between' }}>
                          <Skeleton animation="wave" variant="text" width="90%" height={15} />
                          <Skeleton animation="wave" variant="text" width="40%" height={10} />
                          <Skeleton animation="wave" variant="text" width="40%" height={10} />
                          <Skeleton animation="wave" variant="text" width="50%" height={12} />
                        </div>
                      </div>
                    </div>
                  )
                })
                : skeletonType === "plant planning"
                  ?
                  [...Array(tileLength || 1).keys()].map((components, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 10 }} >
                        <Skeleton animation="wave" variant="text" width="40%" height={20} />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                          <Skeleton style={{ borderRadius: 16, margin: '10px 0' }} animation="wave" variant="rounded" height={50} />
                          <Skeleton style={{ borderRadius: 16, margin: '10px 0' }} animation="wave" variant="rounded" height={50} />
                        </div>
                      </div>
                    )
                  })
                  : skeletonType === "selectOrder"
                    ?
                    [...Array(tileLength || 1).keys()].map((components, index) => {
                      return (
                        <>
                          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', marginBottom: 10 }} >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '25%' }}>
                              <Skeleton animation="wave" variant="circular" width={25} height={25} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                              <Skeleton animation="wave" variant="text" width="80%" height={20} />
                              <Skeleton animation="wave" variant="text" width="50%" height={15} />
                              <Skeleton animation="wave" variant="text" width="48%" height={10} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
                              <Skeleton animation="wave" variant="text" width="35%" height={18} />
                              <Skeleton animation="wave" variant="text" width="25%" height={10} />
                            </div>
                          </div>
                          <Divider variant="middle" style={{ margin: 10 }} />
                        </>
                      )
                    })
                    : skeletonType === "invoices"
                      ?
                      [...Array(tileLength || 3).keys()].map((components, index) => {
                        return (
                          <div style={styles.listContainerStyle}>
                            <div style={styles.listContainerLeftSideStyle}>
                              <Stack spacing={0} style={{ alignItems: 'center' }}>
                                <Skeleton style={{ borderRadius: 15 }} animation="wave" variant="rectangular" width={45} height={50} />
                              </Stack>
                            </div>
                            <div style={styles.listContainerMiddleSideStyle}>
                              <Stack spacing={0} style={{ alignItems: 'start' }}>
                                <Skeleton animation="wave" variant="text" width="95%" height={25} />
                                <Skeleton animation="wave" variant="text" width="50%" height={20} />
                                <Skeleton animation="wave" variant="text" width="90%" height={15} />
                              </Stack>
                            </div>
                            <div style={styles.listContainerRightSideStyle}>
                              <Skeleton animation="wave" variant="text" width="80%" height={20} />
                            </div>
                          </div>
                        )
                      })
                      : skeletonType === "ledger_request"
                        ?
                        <div style={{ margin: '20px 0', width: '90%' }}>
                          <Stack spacing={2}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Skeleton animation="wave" variant="text" width="45%" height={25} />
                              <Skeleton animation="wave" variant="text" width="25%" height={15} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                              <Skeleton animation="wave" variant="text" width="45%" height={20} />
                              <span style={{ display: "inline-block", width: '95%' }}>
                                <div style={styles.radioOptionCardMainBody}>
                                  <Skeleton animation="wave" variant="circular" width={20} height={20} />
                                  <Skeleton animation="wave" variant="text" width="80%" height={30} style={{ marginLeft: 20 }} />
                                </div>
                                <div style={styles.radioOptionCardMainBody}>
                                  <Skeleton animation="wave" variant="circular" width={20} height={20} />
                                  <Skeleton animation="wave" variant="text" width="80%" height={30} style={{ marginLeft: 20 }} />
                                </div>
                                <div style={styles.radioOptionCardMainBody}>
                                  <Skeleton animation="wave" variant="circular" width={20} height={20} />
                                  <Skeleton animation="wave" variant="text" width="80%" height={30} style={{ marginLeft: 20 }} />
                                </div>
                              </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Skeleton animation="wave" variant="text" width="95%" height={80} />
                              <Skeleton animation="wave" variant="text" width="95%" height={80} />
                              <Skeleton animation="wave" variant="text" width="95%" height={15} />
                              <Skeleton animation="wave" variant="text" width="95%" height={15} />
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              alignItems: 'center'
                            }}>
                              <Skeleton animation="wave" variant="text" width="25%" height={40} />
                              <Skeleton animation="wave" variant="text" width="35%" height={60} style={{ marginLeft: 60 }} />
                            </div>
                          </Stack>
                        </div>
                        : null
  )
}

export default TileSkeleton;