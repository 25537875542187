import "date-fns";
import React, { Fragment, Component } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  MenuItem,
  Divider,
  Paper,
  FormControl,
  Select,
  InputAdornment,
  InputLabel,
  IconButton,
  Button,
  ListSubheader,
  Grow,
  Tooltip,
  FormHelperText,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  secondaryBlueText,
  primaryColor,
  drawerTextColor,
  earthBlue,
  drawerBackground,
  successButtonBackground,
  lightGreyColor,
  bottomNavigationFontColor,
  whiteSmoke,
} from "../../../../../constants/internal/colors";
import SnackbarComponent from "../../../common/SnackbarComponent";
import { pxToEm, calculatePercent, checkInvalidValue, findValueReturnText } from "../../../../../methods";
import { createMuiTheme } from "@material-ui/core";
import { withRouter } from "react-router";
import { styled, ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import AddMoreItemButton from "../../../customer/web/AddMoreDottedButtonComponent";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import Lock from "@material-ui/icons/Lock";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isThisMinute } from "date-fns";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toProperCase } from '../../../../../methods';
import Alert from "@mui/material/Alert";
import SpinLoader from "../../../../common/SpinLoader";
import { isEmpty } from "lodash"
import debounce from "lodash.debounce";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";


import {
  validationsTaxationDetails,
  validationsOrderDetails,
  validationsLogisticDetails,
} from "./LAValidationSchema";
import Bread from "../../../common/Breadcrubs";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import { Panorama } from "@material-ui/icons";
import { object } from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { white } from "../../../../../styles/colors";
import Downshift from "downshift";
import APIROUTES from "../../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Print from "@material-ui/icons/Print";
import { handlePrintLAOverviewTable } from "../../../common/LAOverviewPdf";


const styles = {
  createLoadingAdviceMainDiv: { width: "100%", placeItems: "center" },
  stepperVertical: {
    '& .MuiStepConnector-lineVertical': {
      opacity: 0
    },

    '& .MuiStepContent-root': {
      borderLeft: 0
    }
  },
  formControl: {
    margin: 5,
    width: "100%",
  },
  mainDiv: {
    width: "100%",
    // height: "100%",
    paddingBottom: "30px",
    // overflowY: "scroll",
  },

  rightPadding: { paddingRight: "25px" },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    // height: "30px",
    width: "100%",
  },
  headerData: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
  paperDiv: {
    paddingTop: "20px"
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  noDataDiv: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    display: "flex",
    // height: "calc(100vh - 250px)",
    height: '80vh'
  },
  dataFieldsForColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    flexDirection: "column",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    width: "50%",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: "#4B87C5",
    fontWeight: "bold",
  },
  datePicker: {
    color: primaryColor,
    textColor: drawerTextColor,
    calendarTextColor: drawerTextColor,
    selectColor: primaryColor,
    selectTextColor: drawerTextColor,
    calendarYearBackgroundColor: primaryColor,
    headerColor: primaryColor,
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "48%" },
  dataDisplayItemData: { display: "flex", placeItems: "center", width: "32%" },
  additionalDataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
  },
  paperStyle: {
    width: "100%",
    // height: "calc(100vh - 120px)",
    height: '80vh',
    display: "flex",
    marginRight: "25px",
    padding: "20px",
    overflowX: "auto",
  },
  margin: {
    margin: 5,
  },
  displayFlex: {
    padding: "30px 30px 0px 10px",
  },
  saveButton: {
    backgroundColor: "#4B87C5",
    color: drawerTextColor,
    marginRight: 10,
  },
  backButton: {
    color: "#4B87C5",
  },
  orderNameMenuContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
    fontSize: "0.8em",
    color: lightGreyColor,
  },
  lockIconContainer: {
    width: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalLineStyle: {
    height: "2.3vh",
    width: "1px",
    background: bottomNavigationFontColor,
    margin: "0px 6px",
  },
  orderQuantityShowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
    width: "100%",
  },
  discountContainerStyleLeft: {
    width: "85%",
    border: "1px solid gainsboro",
    borderRadius: "15px",
  },
  discountHeaderDiv: {
    padding: "10px",
    background: whiteSmoke,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  discountTextStyle: {
    fontWeight: "bold",
    color: grey,
  },
  discountListStyleDiv: {
    display: "flex",
    padding: 10,
    //placeItems: "center",
  },
  discountListStyleText: {
    width: "50%",
    color: grey,
    fontWeight: "bold",
  },
  laOverviewContainer: {
    width: "100%",
    height: "75px",
    border: "1px solid grey",
    borderRadius: "3px"
  },
  contractDetailsContainer: {
    width: "100%",
    fontSize: "12.8px",
    minHeight: "70px",
    border: "1px solid grey",
    borderRadius: "3px"
  },
  contractDetails: {
    width: "100%",
    height: "100%",
    paddingLeft: "20px",
    marginTop: "10px"
  },
  laOverviewLeftContainer: {
    width: "100%",
    height: "75px",
    display: "flex",
    alignItems: "center",
    fontSize: "0.9 em",
    color: lightGreyColor,
  },
  discountListStyleValue: {
    width: "50%",
    textAlign: "end",

  },

  stepIconRoot: {
    "&.MuiStepIcon-completed": {
      color: "#4B87C5",
    },
    "&.MuiStepIcon-active": {
      color: "#4B87C5",
    },
    "&.MuiStepIcon-disabled": {
      color: "grey",
    },
  },
  helperText: {
    fontFamily: 'Poppins',
    letterSpacing: 'inherit',
  },
  helperText1: {
    position: 'absolute',
    top: pxToEm(52)
  }
};

const StyledStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-active": {
    color: "#4B87C5",
    fontWeight: "bold",
  },
});



const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

class CreateLoadingAdvice extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.anchorEl = React.createRef(null);
    this.overviewContent = React.createRef();
    this.state = {
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: null,
      itemDetailsData: [],
      itemDetailsDataOriginal: [],
      initialItemQty: [],
      deletedItemQty: 0,
      deleteItems: [],
      allOrders: [],
      orderList: null,
      subcontractEnabled: false,
      subcontracts: [],
      orderDetails: {},
      allPayment: [],
      allPaymentTermsList: {
        allotted_payment_method: [],
        not_allotted_payment_method: [],
      },
      disableLCNumber: false,
      disableLCDate: false,
      laDetails: {
        sub_contract: null,
        premium_amount: 0,
        mode_of_transport: null,
        lr_date: moment(new Date()).format("YYYY-MM-DD"),
        lr_number: null,
        fright: null,
        fright_advance: 0,
        cess_rate: 0,
        cess_amount: 0,
        tcs_display: 0,
        tcs_amount: 0,
        freight_charge_rate: 0,
        freight_charge_amount: 0,
        tcs_rate: 0,
        truck_number: null,
        truck_capacity: 0,
        driver_name: null,
        driver_contact_number: 0,
        transporter_contact_number: null,
        driver_licence: null,
        tare_weight: 0,
        gross_weight: 0,
        remark: "",
        customer_remark: "",
        gate_in_date: moment(new Date()).format("YYYY-MM-DD"),
        gate_out_date: null,
        buyer: null,
        consignee: null,
        item_category: null,
        plant: 0,
        payment_method: null,
        transporter: 0,
        order: null,
        is_customer_approval_needed: false,
        is_approved: props.location?.state?.isApproved || false,
        items: [],
        weightsTaken: [],
        taxable_amount: 0,
        discounted_rate: 0,
        gst_amount: 0,
        total_amount: 0,
        la_number: null,
        lc_number: null,
        // lc_date: moment(new Date()).format("YYYY-MM-DD"),
        lc_date: null,
        tran_sup_type: null,
        erp_invoice_number: null,
        doc_date: null,
        payment_detail: {},
        payment_method_name: null,
        payment_status: null,
        la_due_date: null,
        vr_number: null,
        sales_return_amount: null,
        sales_return_date: null
      },
      helperText: {
        never: this.props.overview ? "" : "Not editable",
        allow: this.props.overview ? "" : "Editable without approval",
        approval_change: this.props.overview ? "" : "Editable with approval",
        rollback_status: this.props.overview ? "" : `Editable only if status is rolled back to "PACKED"`,
        replacement_la: this.props.overview ? "" : "Editable with approval and Replacement LA",
        sales_return: this.props.overview ? "" : "Not editable, this is a case of sales return"
      },
      editData: {
        order: "",
        gate_in_date: "",
        item_category: "",
        payment_terms: "",
        plant: "",
        lc_date: "",
        lc_number: "",
        tran_sup_type: "",
        truck_number: "",
        truck_capacity: "",
        transport: "",
        transporter_contact_number: "",
        driver_name: "",
        driver_contact_number: "",
        driver_licence: "",
        remark: "",
        customer_remark: "",
        buyer: "",
        consignee: "",
        item: "",
        quantity: "",
        cess_rate: "",
        tcs_rate: "",
        cess_amount: "",
        tcs_display: "",
        freight_charge_rate: "",
        freight_charge_amount: "",
        fright_basis: "",
        fright_advance: "",
        lr_number: "",
        lr_date: "",
        mode_of_transport: "",
        special_discount: "",
        vr_number: "",
        sales_return_amount: "",
        sales_return_date: ""
      },
      additionalData: {},
      allItemCategoryList: {
        subscribed: [],
        unsubscribed: [],
      },
      plantsList: [],
      transportListLoader: false,
      tranportersList: [],
      allBuyersList: [],
      allConsigneeList: [],
      allItemsList: [],
      laOperation: null,
      discountInitialData: {},
      discountData: {},
      totalItemQtySum: 0,
      categoryPaymentMethodDiscountData: [],
      categoryCityDiscountData: [],
      itemCategoryData: {},
      itemRateDifferenceData: [],
      packingAmount: 0,
      insuranceAmount: 0,
      loadingAmount: 0,
      allPlantsData: [],
      activeStep: 0,
      showError: false,
      showAddItemsError: false,
      addItemsErrorMsg: "Add atleast one item",
      pendingQuantity: [],
      item_qty_error: [],
      item_qty_error_message: [],
      itemLoad: false,
      have_transporter_contact_no: false,
      searchTransportText: '',
      openTransportPopper: false,
      tagsMaster: [],
      finishBtnClicked: false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (!this.props.overview) {
      this.fetchAllOrders();
    }
    this.fetchTagsMaster()
    this.props?.handleFetchFreightBasis()
    // this.props?.handleFetchItemsForCategory(this.props?.itemId)
    this.initializeData(this.props);
    if (this.props.laStatus) {
      this.handleHelperText()
    }
    if (this.props?.location?.pathname?.includes("dispatchPlanning")) {
      this.props?.handleActionMenuClose();
    }

  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(nextProps, prevState) {
    if (
      nextProps.orderDetails !== this.props.orderDetails ||
      nextProps.laDetails !== this.props.laDetails ||
      nextProps.laItemsList !== this.props.laItemsList ||
      nextProps.allItemCategoryList !== this.props.allItemCategoryList ||
      nextProps.allPaymentTermsList !== this.props.allPaymentTermsList ||
      nextProps.allItemsList !== this.props.allItemsList ||
      nextProps.plantsList !== this.props.plantsList ||
      nextProps.categoryPaymentMethodDiscountData !==
      this.props.categoryPaymentMethodDiscountData ||
      nextProps.categoryCityDiscountData !==
      this.props.categoryCityDiscountData ||
      nextProps.itemCategoryData !== this.props.itemCategoryData ||
      nextProps.allPlantsData !== this.props.allPlantsData ||
      nextProps.allBuyersList !== this.props.allBuyersList ||
      nextProps.allConsigneeList !== this.props.allConsigneeList ||
      nextProps.subcontractRows !== this.props.subcontractRows
    ) {
      this.initializeData(this.props);
    }

    if (nextProps.orderDetails !== this.props.orderDetails) {
      if (this.props.orderDetails?.id) {
        this.props.handleFetchSubcontracts(this.props.orderDetails?.id)
      }
      if (this.props.orderDetails?.tag) {
        this.props.handleFetchItemsWithTagsForCategory(this.props.orderDetails?.tag, this.props.orderDetails?.item_category?.id)
      }
    }

    if (nextProps.plantsList !== this.props.plantsList) {
      const plantDetails = this.props.location.pathname?.includes("dispatchPlanning") ?
        this.props?.plantsList.find(p => p.plant == this.props.selectedPlant)
        : this.props?.plantsList.find(p => p.plant == this.props?.laDetails?.plant?.id)
      if (plantDetails?.sub_contract_enabled && this.props.laDetails?.sub_contract) {
        this.props.handleGetSubcontractItemsForCategory(this.props.laDetails?.sub_contract)
      }
    }

    if (nextProps.tcsRate !== this.props.tcsRate) {
      this.handleDiscountCalculation();
      this.handleCalculateTaxableAmount();
    }
    if (nextProps.newSelectedOrder !== this.props.newSelectedOrder) {
      if (this.props.newSelectedOrder) {
        let discountData = this.state.discountData
        discountData.loading_premium = this.props.newSelectedOrder?.discounts?.loading_premium
        discountData.insurance_premium = this.props.newSelectedOrder?.discounts?.insurance_premium
        this.setState({
          discountData: discountData
        })
      }

    }

    if (nextProps.allBuyersList != this.props.allBuyersList ||
      nextProps.allItemsList !== this.props.allItemsList) {
      this.fetchTcsRateData();
    }


    if (["HOLD", "CLOSED"].includes(this?.props?.orderStatus) && nextProps.orderDetails !== this.props.orderDetails && this.state?.orderList === 0) {
      // allOrders: this?.props?.orderStatus === "HOLD" && new Array(whichProps.orderDetails),
      this.setState({
        allOrders: new Array(this.props.orderDetails)
      });
    }
  }

  pageStyle = `@media print {
    @page {
      size: 300mm 400mm;
    }
  }
  `;
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  fetchTcsRateData = () => {
    if (this.props.allBuyersList?.length > 0 && this.props.allItemsList?.length > 0) {
      const buyerId =
        this.props.allBuyersList?.find(a => a.buyerid == this.state.additionalData?.buyer_details?.id)?.buyerid ??
        this.props.allBuyersList?.find(a => a.buyerid == this.state.additionalData?.buyer_details?.buyerid)?.buyerid;
      if (buyerId)
        this.props.handleFetchTcsRateData(buyerId, this.state.itemDetailsData?.length > 0 ? this.state.itemDetailsData[0]?.item : this.props.allItemsList[0]?.id)
    }
  }

  fetchAllOrders = async () => {
    //console.log('LA PAGE : ', this.props.plantId)
    const orders = await this.props.fetchAllOrders(this.props.itemId, this.props.customerId, this.props?.plantId || 0);
    this.setState({
      allOrders: this.props.allOrders,
      orderList: this.props.allOrders.length
    },
      () => {
        console.log(this.state.orderList)
      })
  }

  handleViewTransportName = (text) => {
    this.setState({
      searchTransportText: text
    })
    //console.log(text, 'hereee')
  }
  handleSearchTransport = (searchText, close = false) => {
    if (!close) {
      //console.log(searchText.indexOf('- ('), searchText.slice(0, searchText.indexOf('- (')))
      this.setState({
        searchTransportText: searchText
      }, () => {
        //console.log(this.state.searchTransportText)
        if (this.state.searchTransportText !== '') {
          this.setState({ openTransportPopper: true })
          // this.handleFetchTransporterData(this.state.searchTransportText)
          this.handleFetchTransporterData(searchText.indexOf('- (') !== -1 ? searchText.slice(0, searchText.indexOf('- (')) : searchText)
        }
        else {
          this.setState({ openTransportPopper: false, })
        }
      })
    }
    else if (close) {
      this.setState({ searchTransportText: searchText, openTransportPopper: false })
    }
  }
  handleFetchTransporterData = debounce((searchText = '') => {
    const baseUrl = APIROUTES.TRANSPORTERS;
    const params = searchText !== '' && `?search=${searchText}&active=true`
    const filteredLink = searchText !== '' ? baseUrl + params : `${baseUrl}?active=true`
    this.setState({ transportListLoader: true })
    axios
      .get(filteredLink, this.getOptions(this.props.user))
      .then((response) => {
        // //console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ tranportersList: response.data.results, transportListLoader: false });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching transporters data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({ transportListLoader: false })
        //console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  }, 500)

  fetchTagsMaster = () => {
    if (this.state?.tagsMaster.length <= 0) {
      axios
        .get(`${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}?active=true`, this.getOptions(this.props.user))
        .then((response) => {
          this.setState({
            tagsMaster: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getPendingQuantity = (laOperation) => {
    return laOperation === "edit" ?
      ((parseFloat(Number(this.getAvailableQuantity())) - (parseFloat(Number(this.state.totalItemQtySum)) - parseFloat(Number(this.state.initialItemQty)))) + parseFloat(Number(this.state.deletedItemQty))).toFixed(3)
      : this.getAvailableQuantity() || 0;
  }

  initializeData = (whichProps) => {
    // console.log(whichProps)
    const buyerDetails = whichProps?.laDetails?.buyer
      ? whichProps?.laDetails?.buyer
      :
      whichProps?.orderDetails?.buyer
        ? whichProps?.orderDetails?.buyer
        : {}
    let buyerPinCode = buyerDetails?.address?.pincode;
    let cityPincodeData = whichProps?.categoryCityDiscountData?.find((city) =>
      city?.pincodes.length > 0
        ? city?.pincodes.includes(
          city?.pincodes.find((el) => el.pincode === Number(buyerPinCode))
        )
        : false
    );
    let paymentMethodeId = whichProps?.laDetails?.payment_method;

    let categoryPaymentMethodDiscountData =
      this?.state?.categoryPaymentMethodDiscountData;
    let paymentMethodData = categoryPaymentMethodDiscountData.find(
      (paymentMethod) => paymentMethod.payment_method === paymentMethodeId
    );

    const plantDetails = whichProps.location.pathname?.includes("dispatchPlanning") ?
      whichProps?.plantsList.find(p => p.plant == whichProps.selectedPlant)
      : whichProps?.plantsList.find(p => p.plant == whichProps?.laDetails?.plant?.id)
    if (plantDetails) {
      this.setState({ subcontractEnabled: plantDetails.sub_contract_enabled }, () => {
        if (((whichProps?.laOperation === "edit" && this.props.created_from_subcontract) ||
          (whichProps?.laOperation === "add" && this.state.subcontractEnabled))) {
          const itemDetails = whichProps.laItemsList.map(w =>
          ({
            ...w,
            subcontract_available_item_qty: this.props.allSubcontractItemList?.find(p => p.id == w.item)?.available_quantity || 0
          }))
          this.setState({ itemDetailsData: itemDetails })
          const originalItemDetails = itemDetails.map(i => ({ ...i, total_available_qty: Number(i.item_qty || 0) + Number(i.subcontract_available_item_qty) }))
          this.setState({ itemDetailsDataOriginal: originalItemDetails })
        } else {
          const originalItemDetails = whichProps.laItemsList?.map(i => ({ ...i, total_available_qty: Number(i.item_qty || 0) + Number(i.subcontract_available_item_qty) }))
          this.setState({ itemDetailsData: whichProps.laItemsList })
          this.setState({ itemDetailsDataOriginal: originalItemDetails })
        }
      })
    }

    if (whichProps?.subcontractRows) {
      this.setState({ subcontracts: whichProps?.subcontractRows.filter(s => s.plant == plantDetails?.plant) })
    }
    console.log(whichProps?.orderDetails?.discounts)
    this.setState(
      {
        orderDetails: whichProps.orderDetails,
        allItemsList: whichProps.allItemsList,
        allPaymentTermsList: whichProps.allPaymentTermsList,
        allPayment: whichProps.allPaymentTermsList.allotted_payment_method.concat(
          whichProps.allPaymentTermsList.not_allotted_payment_method
        ),
        tranportersList: whichProps.tranportersList,
        allItemCategoryList: whichProps.allItemCategoryList,
        allBuyersList: whichProps.allBuyersList,
        allConsigneeList: whichProps.allConsigneeList,
        laOperation: whichProps.laOperation,
        plantsList: whichProps.plantsList,
        additionalData: {
          order_quantity: whichProps?.orderDetails?.quantity || 0,
          transporter_number:
            whichProps?.laDetails?.transporter?.mobile || null,
          buyer_details:
            whichProps?.laDetails?.buyer
              ? whichProps?.laDetails?.buyer
              :
              whichProps?.orderDetails?.buyer
                ? whichProps?.orderDetails?.buyer
                : {},
          consignee_details: whichProps?.laDetails?.consignee
            ? whichProps?.laDetails?.consignee
            : whichProps?.orderDetails?.consignee
              ? whichProps?.orderDetails?.consignee
              : {},
          totalDiscountAmount: this.getTotalDiscount(
            whichProps?.orderDetails?.discounts
          ),
          allowGstOption: false,
          gstOptionValue: "SGST",
          igstRate: 0,
          igstAmount: 0,
          sgstRate: 0,
          sgstAmount: 0,
          cgstRate: 0,
          cgstAmount: 0,
          // plant_planning_pending_qty:
          // tcs_percent: whichProps?.laDetails?.tcs_percent
          //   ? whichProps?.laDetails?.tcs_percent
          //   : whichProps?.orderDetails?.tcs_percent
          //     ? whichProps?.orderDetails?.tcs_percent
          //     : 0,
        },
        discountInitialData: {
          customer_discount: whichProps?.laDetails?.discounts?.find(d => d?.discount_type == 'CUSTOMER')?.discount_amount || 0,
          city_discount: whichProps?.laDetails?.discounts?.find(d => d?.discount_type == 'CITY')?.discount_amount || 0,
          payment_discount: whichProps?.laDetails?.discounts?.find(d => d?.discount_type == 'PAYMENT')?.discount_amount || 0,
          special_discount: whichProps?.laDetails?.discounts?.find(d => d?.discount_type == 'SPECIAL')?.discount_amount || 0,
          payment_premium: whichProps?.laDetails?.discounts?.find(d => d?.discount_type == 'PAYMENT_PREMIUM')?.discount_amount || 0,
          loading_premium: whichProps?.laDetails?.discounts?.find(d => d?.discount_type == 'LOADING_PREMIUM')?.discount_amount || 0,
          insurance_premium: whichProps?.laDetails?.discounts?.find(d => d?.discount_type == 'INSURANCE_PREMIUM')?.discount_amount || 0,
          special_premium: Number(whichProps?.orderDetails?.discounts?.special_premium)?.toFixed(2) || 0
        } || {},
        discountData: whichProps?.orderDetails?.discounts || {},
        laDetails: {
          id: whichProps?.laDetails?.id,
          mode_of_transport: whichProps?.laDetails?.mode_of_transport || null,
          tag: whichProps?.laDetails?.tag || "",
          premium_amount: whichProps?.laDetails?.premium_amount || 0,
          lr_date:
            whichProps?.laDetails?.lr_date ||
            moment(new Date()).format("YYYY-MM-DD"),
          lr_number: whichProps?.laDetails?.lr_number || null,
          fright: isNaN(whichProps?.laDetails?.fright) ? this.props.freightBasis?.find(f => f.name == whichProps?.laDetails?.fright)?.id : whichProps?.laDetails?.fright || null,
          fright_advance: whichProps?.laDetails?.fright_advance || 0,
          cess_rate: whichProps?.laDetails?.cess_rate || 0,
          tcs_rate: whichProps?.laDetails?.tcs_rate || 0,
          cess_amount: whichProps?.laDetails?.cess_amount || 0,
          vr_number: whichProps?.laDetails?.sales_vr_number || null,
          sales_return_amount: whichProps?.laDetails?.sales_return_amount || null,
          sales_return_date: whichProps?.laDetails?.sales_return_date || null,
          tcs_display: whichProps?.laDetails?.tcs_display || 0,
          erp_invoice_number: whichProps?.laDetails?.erp_invoice_number || null,
          payment_detail: whichProps?.laDetails?.payment_detail || {},
          payment_method_name: whichProps?.laDetails?.payment_method?.payment_method_name
            ? whichProps?.laDetails?.payment_method?.payment_method_name
            : whichProps?.orderDetails?.payment_method?.payment_method_name
              ? whichProps?.orderDetails?.payment_method?.payment_method_name
              : null,
          doc_date: whichProps?.laDetails?.doc_date || null,
          payment_status: whichProps?.laDetails?.payment_status || null,
          la_due_date: whichProps?.laDetails?.la_due_date || null,
          freight_charge_rate: whichProps?.laDetails?.freight_charge_rate
            ? whichProps?.laDetails?.freight_charge_rate
            : whichProps?.orderDetails?.freight_charge_percent
              ? whichProps?.orderDetails?.freight_charge_percent
              : 0,
          freight_charge_amount:
            whichProps?.laDetails?.freight_charge_amount || 0,
          truck_number: whichProps?.laDetails?.truck_number || null,
          lc_number: whichProps?.laDetails?.lc_number || null,
          lc_date:
            whichProps?.laDetails?.lc_date ||
            null,
          truck_capacity: whichProps?.laDetails?.truck_capacity || 0,
          driver_name: whichProps?.laDetails?.driver_name || null,
          driver_contact_number:
            whichProps?.laDetails?.driver_contact_number || 0,
          transporter_contact_number: whichProps?.laDetails?.transporter_contact_number || null,
          driver_licence: whichProps?.laDetails?.driver_licence || null,
          tare_weight: whichProps?.laDetails?.tare_weight || 0,
          gross_weight: whichProps?.laDetails?.gross_weight || 0,
          remark: whichProps?.laDetails?.remark || "",
          customer_remark: whichProps?.laDetails?.customer_remark || "",
          gate_in_date:
            whichProps?.laDetails?.gate_in_date ||
            moment(new Date()).format("YYYY-MM-DD"),
          gate_out_date: whichProps?.laDetails?.gate_out_date || null,
          buyer: (whichProps?.laDetails?.buyer?.buyer_id || whichProps?.laDetails?.buyer?.id)
            ? (whichProps?.laDetails?.buyer?.buyer_id || whichProps?.laDetails?.buyer?.id)
            : (whichProps?.orderDetails?.buyer?.buyer_id || whichProps?.orderDetails?.buyer?.id)
              ? (whichProps?.orderDetails?.buyer?.buyer_id || whichProps?.orderDetails?.buyer?.id)
              : 0,
          consignee: whichProps?.laDetails?.consignee?.id
            ? whichProps?.laDetails?.consignee?.id
            : whichProps?.orderDetails?.consignee?.id
              ? whichProps?.orderDetails?.consignee?.id
              : 0,
          item_category: whichProps?.laDetails?.item_category?.id
            ? whichProps?.laDetails?.item_category?.id
            : whichProps?.orderDetails?.item_category?.id
              ? whichProps?.orderDetails?.item_category?.id
              : null,
          plant: whichProps?.laDetails?.plant?.id ||
            this.props?.location?.pathname?.includes("dispatchPlanning") &&
            (this.props.plantsList.length > 0 &&
              (this.props.plantsList.find(p => p.plant_name == this.props.plantsName))?.plant) || 0,
          payment_method: whichProps?.laDetails?.payment_method?.id
            ? whichProps?.laDetails?.payment_method?.id
            : whichProps?.orderDetails?.payment_method?.id
              ? whichProps?.orderDetails?.payment_method?.id
              : null,
          transporter: whichProps?.laDetails?.transporter?.id || 0,
          order: whichProps?.laDetails?.order?.id
            ? whichProps?.laDetails?.order?.id
            : whichProps?.laDetails?.order
              ? whichProps?.laDetails?.order
              : whichProps?.orderDetails?.id
                ? whichProps?.orderDetails?.id
                : null,
          sub_contract: whichProps?.laDetails?.sub_contract,
          is_customer_approval_needed:
            whichProps?.laDetails?.is_customer_approval_needed || false,
          is_approved: whichProps?.location?.state?.isApproved || false,
          items: whichProps?.laDetails?.loading_advice_items || [],
          weightsTaken: whichProps?.laDetails?.loading_advice_weighing_items || [],
          taxable_amount: whichProps?.laDetails?.taxable_amount || 0,
          discounted_rate: whichProps?.laDetails?.discounted_rate || 0,
          gst_amount: whichProps?.laDetails?.gst_amount || 0,
          total_amount: whichProps?.laDetails?.total_amount || 0,
          la_number: whichProps?.laDetails?.la_number || null,
          tran_sup_type: whichProps?.laOperation === "add"
            ?
            (whichProps?.orderDetails?.buyer?.gstin_number === null || whichProps?.orderDetails?.buyer?.gstin_number === undefined)
              ? this.state?.laDetails?.buyer
                ? this.state?.additionalData?.buyer_details?.gstin_number
                  ? this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id
                  : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
                : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
              : this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id
            : (whichProps?.laDetails?.buyer?.gstin_number !== null || whichProps?.laDetails?.buyer?.gstin_number !== undefined)
              ? this.state?.laDetails?.buyer
                ? this.state?.additionalData?.buyer_details?.gstin_number
                  ? this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id
                  : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
                : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
              : this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id

          // ? this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id : this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id,
        },
        categoryPaymentMethodDiscountData:
          whichProps?.categoryPaymentMethodDiscountData || [],
        categoryCityDiscountData: whichProps?.categoryCityDiscountData || [],
        itemCategoryData: whichProps?.itemCategoryData || {},
        allPlantsData: whichProps.allPlantsData,
        allTcsRates: whichProps.allTcsRates || [],
        pendingQuantity: whichProps.location?.state?.pendingQuantity || this.props.pendingQuantity || []
      },
      () => {
        this.setState({
          have_transporter_contact_no: Boolean(this.state?.additionalData?.transporter_number),
        })
        this.handleCalculateFreightChargeAmount();
        this.handleIdentifyGST();
        if (whichProps.laOperation === "add") {
          //this.handleSetTcsAmount();
          this.handleDiscountCalculation();
          this.handleCalculateTaxableAmount();
          this.setState({
            initialItemQty: this.itemQtyTotal([]),
          });
        }
        if (whichProps.laOperation === "edit") {
          if (this.state.searchTransportText === '' && whichProps?.laDetails?.transporter?.id) {
            this.handleViewTransportName(
              // whichProps?.laDetails?.transporter?.acc_name
              String(
                whichProps?.laDetails?.transporter?.transporter_id
                  ? `${whichProps?.laDetails?.transporter?.acc_name} - ( ${whichProps?.laDetails?.transporter?.transporter_id} )`
                  : whichProps?.laDetails?.transporter?.gst_number
                    ? `${whichProps?.laDetails?.transporter?.acc_name} - ( ${whichProps?.laDetails?.transporter?.gst_number} )`
                    : whichProps?.laDetails?.transporter?.pan_no
                      ? `${whichProps?.laDetails?.transporter?.acc_name} - ( ${whichProps?.laDetails?.transporter?.pan_no} )`
                      : whichProps?.laDetails?.transporter?.acc_name
              )
            )
          }
          if (this.getBreadcrumbTitle() === 'DISPATCH PLANNING') {
            this.setState((previousState) => {
              const additionalData = {
                ...previousState.additionalData,
                ["buyer_details"]: this.state?.allBuyersList.find(d => d.id === this.state.laDetails?.buyer),
                ["consignee_details"]: this.state?.allConsigneeList.find(d => d.id === this.state.laDetails?.consignee),
              };
              return { additionalData };
            })
          }
          this.handleDisplayAddNewItemButton();
          //console.log(whichProps?.orderDetails?.discounts?.loading_premium)
          //console.log(this.state.discountInitialData)
          this.setState({
            initialItemQty: this.itemQtyTotal(this?.state?.itemDetailsData),
            discountData: this.state.discountInitialData
          },
            () => {
              this.handleDiscountCalculation();
              this.handleCalculateTaxableAmount();
            });
          this.setState((previousState) => {
            const itemDetailsData = [
              ...previousState.itemDetailsData.map(v => ({ ...v, method: 'UNCHANGE' })),
            ];
            return { itemDetailsData };
          })
        }
      }
    );
  };
  // handleSetTcsAmount = () => {
  //   let tcsRate = parseFloat(
  //     this?.state?.laDetails?.tcs_rate || 0
  //   ).toFixed(2);
  //   //console.log(tcsRate)
  //   let totalItemQty = this.itemQtyTotal(this?.state?.itemDetailsData);
  //   this.setState((previousState) => {
  //     const laDetails = {
  //       ...previousState.laDetails,
  //       ["tcs_display"]: parseFloat(
  //         tcsRate * totalItemQty
  //       ).toFixed(2),
  //     };
  //     return { laDetails };
  //   });

  // };
  handleIdentifyGST = () => {
    let buyerGstin = !this?.state?.additionalData?.buyer_details?.gstin_number
      ? null
      : this?.state?.additionalData?.buyer_details?.gstin_number;

    let allPlantsData = this?.state?.allPlantsData;
    let selectedplantData =
      allPlantsData.length > 0
        ? allPlantsData.find(
          (data) => data.id === this?.state?.laDetails?.plant
        )
        : {};
    let supplierGstin = selectedplantData?.plant_gst_number || null;
    let buyerState = !buyerGstin ? this?.state?.additionalData?.buyer_details?.address?.state_code : buyerGstin.slice(0, 2);
    let supplierState = !supplierGstin ? null : supplierGstin.slice(0, 2);

    let allowGstOption = !buyerState || !supplierState ? true : false;
    this.setState((previousState) => {
      const additionalData = {
        ...previousState.additionalData,
        ["allowGstOption"]: allowGstOption,
        ["gstOptionValue"]: buyerState === supplierState ? "SGST" : "IGST",
      };
      return { additionalData };
    });
  };
  handleDiscountCalculation = () => {
    let laDetails = this?.state?.laDetails;
    let additionalData = this?.state?.additionalData;
    let categoryCityDiscountData = this?.state?.categoryCityDiscountData;
    let categoryPaymentMethodDiscountData =
      this?.state?.categoryPaymentMethodDiscountData;
    if (laDetails?.item_category) {
      let paymentMethodeId = laDetails?.payment_method;
      let buyerPinCode = additionalData?.buyer_details?.address?.pincode;
      let cityPincodeData = categoryCityDiscountData.find((city) =>
        city?.pincodes.length > 0
          ? city?.pincodes.includes(
            city?.pincodes.find((el) => el.pincode === Number(buyerPinCode))
          )
          : false
      );
      let paymentMethodData = categoryPaymentMethodDiscountData.find(
        (paymentMethod) => paymentMethod.payment_method === paymentMethodeId
      );
      // //console.log(this.props.paymentMethodId, paymentMethodeId)
      // //console.log(this.props.buyerPinCode, buyerPinCode)
      if (this.state.laOperation === "edit") {
        let paymentDiscount = 0;
        let paymentPremium = 0;
        let cityDiscount = 0;

        if ((paymentMethodeId !== this.props.paymentMethodId) || (buyerPinCode !== this.props.buyerPinCode)) {
          paymentDiscount = this.getPaymentBasisDiscount(paymentMethodData)
          paymentPremium = this.getPaymentBasisPremiumDiscount(paymentMethodData)
          cityDiscount = this.getCityDiscount(cityPincodeData)
        } else {
          paymentDiscount = this.state?.discountInitialData?.payment_discount || 0
          paymentPremium = this.state?.discountInitialData?.payment_premium || 0
          cityDiscount = this.state?.discountInitialData?.city_discount || 0
        }
        this.setState(
          (previousState) => {
            const discountData = {
              ...previousState.discountData,
              ["payment_discount"]: paymentDiscount,
              // parseFloat(
              //   paymentMethodData?.discount_amount || 0
              // ).toFixed(2),
              ["payment_premium"]: paymentPremium,
              // parseFloat(
              //   paymentMethodData?.premium_amount || 0
              // ).toFixed(2),
              ["city_discount"]: cityDiscount,
              ["loading_premium"]: this.state?.discountInitialData?.loading_premium,
              ["insurance_premium"]: this.state?.discountInitialData?.insurance_premium

              // parseFloat(
              //   cityPincodeData?.discount_amount || 0
              // ).toFixed(2),
            };
            return { discountData };
          },
          () => {
            this.setState((previousState) => {
              const additionalData = {
                ...previousState.additionalData,
                ["totalDiscountAmount"]: this.getTotalDiscount(
                  this?.state?.discountData
                ),
              };
              return { additionalData };
            })
          }
        );
        // }
        // else if ((paymentMethodeId !== this.props.paymentMethodId) && (buyerPinCode === this.props.buyerPinCode)) {
        //   // //console.log("Not Equal - payment + Equal - city  CASE:2")

        //   this.setState(
        //     (previousState) => {
        //       const discountData = {
        //         ...previousState.discountData,
        //         ["payment_discount"]: this.getPaymentBasisDiscount(paymentMethodData),
        //         // parseFloat(
        //         //   this.state?.discountInitialData?.payment_discount || 0
        //         // ).toFixed(2),
        //         ["payment_premium"]: this.getPaymentBasisPremiumDiscount(paymentMethodData),
        //         // parseFloat(
        //         //   this.state?.discountInitialData?.payment_premium || 0
        //         //   // paymentMethodData?.premium_amount || 0
        //         // ).toFixed(2),
        //         ["city_discount"]: parseFloat(
        //           this.state?.discountInitialData?.city_discount || 0
        //         ).toFixed(2),
        //       };
        //       return { discountData };
        //     },
        //     () => {
        //       // //console.log(this.state.discountData)
        //       this.setState((previousState) => {
        //         const additionalData = {
        //           ...previousState.additionalData,
        //           ["totalDiscountAmount"]: this.getTotalDiscount(
        //             this?.state?.discountData
        //           ),
        //         };
        //         return { additionalData };
        //       })
        //     }
        //   );
        // }
        // else if ((paymentMethodeId === this.props.paymentMethodId) && (buyerPinCode !== this.props.buyerPinCode)) {
        //   // //console.log("Equal - payment + Not Equal - city  CASE:3")
        //   this.setState(
        //     (previousState) => {
        //       const discountData = {
        //         ...previousState.discountData,
        //         ["payment_discount"]: this.getPaymentBasisDiscount(paymentMethodData),
        //         //  parseFloat(
        //         //   this.state?.discountInitialData?.payment_discount || 0
        //         // ).toFixed(2),
        //         ["payment_premium"]: this.getPaymentBasisPremiumDiscount(paymentMethodData),
        //         // parseFloat(
        //         //   this.state?.discountInitialData?.payment_premium || 0
        //         // ).toFixed(2),
        //         ["city_discount"]: parseFloat(
        //           cityPincodeData?.discount_amount || 0
        //         ).toFixed(2),
        //       };
        //       return { discountData };
        //     },
        //     () => {
        //       // //console.log(this.state.discountData)
        //       this.setState((previousState) => {
        //         const additionalData = {
        //           ...previousState.additionalData,
        //           ["totalDiscountAmount"]: this.getTotalDiscount(
        //             this?.state?.discountData
        //           ),
        //         };
        //         return { additionalData };
        //       })
        //     }
        //   );
        // }
        // else if ((paymentMethodeId === this.props.paymentMethodId) && (buyerPinCode === this.props.buyerPinCode)) {
        //   // //console.log("Equal - payment & city  CASE:4")
        //   this.setState({
        //     discountData: this.state?.discountInitialData
        //   },
        //     () => {
        //       // //console.log(this.state.discountData);
        //       this.setState((previousState) => {
        //         const additionalData = {
        //           ...previousState.additionalData,
        //           ["totalDiscountAmount"]: this.getTotalDiscount(
        //             this?.state?.discountData
        //           ),
        //         };
        //         return { additionalData };
        //       })
        //     }
        //   )
        // }
        // else { }
      }
      else if (this.state.laOperation === "add") {
        this.setState(
          (previousState) => {
            const discountData = {
              ...previousState.discountData,
              ["payment_discount"]: this.getPaymentBasisDiscount(paymentMethodData),
              ["payment_premium"]: this.getPaymentBasisPremiumDiscount(paymentMethodData),
              //  parseFloat(
              //   paymentMethodData?.discount_amount || 0
              // ).toFixed(2),
              ["city_discount"]: this.getCityDiscount(cityPincodeData)
              //  parseFloat(
              //   cityPincodeData?.discount_amount || 0
              // ).toFixed(2),
            };
            return { discountData };
          },
          () =>
            this.setState((previousState) => {
              const additionalData = {
                ...previousState.additionalData,
                ["totalDiscountAmount"]: this.getTotalDiscount(
                  this?.state?.discountData
                ),
              };
              return { additionalData };
            })
        );
      } else {
        const paymentDiscount = this.state?.discountInitialData?.payment_discount || 0
        const paymentPremium = this.state?.discountInitialData?.payment_premium || 0
        const cityDiscount = this.state?.discountInitialData?.city_discount || 0
        this.setState(
          (previousState) => {
            const discountData = {
              ...previousState.discountData,
              ["payment_discount"]: paymentDiscount,
              ["payment_premium"]: paymentPremium,
              ["city_discount"]: cityDiscount,
              ["loading_premium"]: this.state?.discountInitialData?.loading_premium,
              ["insurance_premium"]: this.state?.discountInitialData?.insurance_premium
            };
            return { discountData };
          },
          () => {
            this.setState((previousState) => {
              const additionalData = {
                ...previousState.additionalData,
                ["totalDiscountAmount"]: this.getTotalDiscount(
                  this?.state?.discountData
                ),
              };
              return { additionalData };
            })
          }
        );
      }
    }
  };

  getRateDiff = (data) => {
    const createdAt = new Date(this.state.orderDetails?.created_at);
    const currentItemId = this.props.laOperation === "edit" ? data?.item : data?.id;
    const rateDiff = this.state.allItemsList.find(f => f.id === currentItemId)?.rate_difference;
    const rateDiffs = this.state.allItemsList.find(f => f.id === currentItemId)?.rate_difference_logs;

    const qs1 = rateDiffs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
    const qs2 = rateDiffs?.find((r) =>
      r.valid_upto != null
      && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
      && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
    if (this.props.overview) {
      return rateDiff;
    } else {
      if (qs1) {
        return qs1
      } else if (qs2) {
        return qs2
      } else {
        return rateDiff;
      }
    }
  }

  getCityDiscount = (data) => {
    ////console.log("dataofcity", data)
    const createdAt = new Date(this.state.orderDetails?.created_at);
    ////console.log("craeteAT", createdAt)
    const cityDiscount = data?.discount_amount;
    const cityDiscountLogs = data?.city_discount_logs;

    const qs1 = cityDiscountLogs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
    const qs2 = cityDiscountLogs?.find((r) =>
      r.valid_upto != null
      && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
      && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;

    if (qs1) {
      return qs1
    } else if (qs2) {
      return qs2
    } else {
      return cityDiscount;
    }
    // if (this.props.overview) {
    //   return cityDiscount;
    // } else {
    //   if (qs1) {
    //     return qs1
    //   } else if (qs2) {
    //     return qs2
    //   } else {
    //     return cityDiscount;
    //   }
    // }
  }

  getPaymentBasisDiscount = (data) => {
    // console.log("dataofcity", data)
    const createdAt = new Date(this.state.orderDetails?.created_at);
    ////console.log("craeteAT", createdAt)
    //const currentItemId = this.props.laOperation === "edit" ? data?.item : data?.id;
    const paymentBasisDiscount = checkInvalidValue(data?.discount_amount) ? 0 : data?.discount_amount;
    const paymentBasisDiscountLogs = data?.payment_method_wise_discount_amount_logs;
    const qs1 = paymentBasisDiscountLogs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
    const qs2 = paymentBasisDiscountLogs?.find((r) =>
      r.valid_upto != null
      && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
      && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;

    if (qs1) {
      return qs1
    } else if (qs2) {
      return qs2
    } else {
      return paymentBasisDiscount;
    }
    // if (this.props.overview) {
    //   return paymentBasisDiscount;
    // } else {
    //   if (qs1) {
    //     return qs1
    //   } else if (qs2) {
    //     return qs2
    //   } else {
    //     return paymentBasisDiscount;
    //   }
    // }
  }

  getPaymentBasisPremiumDiscount = (data) => {
    ////console.log("dataofcity", data)
    const createdAt = new Date(this.state.orderDetails?.created_at);
    ////console.log("craeteAT", createdAt)
    //const currentItemId = this.props.laOperation === "edit" ? data?.item : data?.id;
    const paymentBasisPremiumDiscount = checkInvalidValue(data?.premium_amount) ? 0 : data?.premium_amount;
    const paymentBasisPremiumDiscountLogs = data?.payment_method_wise_premium_amount_logs;

    const qs1 = paymentBasisPremiumDiscountLogs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
    const qs2 = paymentBasisPremiumDiscountLogs?.find((r) =>
      r.valid_upto != null
      && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
      && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
    ////console.log(paymentBasisPremiumDiscountLogs)
    paymentBasisPremiumDiscountLogs?.forEach((r) => {
      ////console.log(new Date(r.valid_from), moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))

    })
    ////console.log(this.props.overview);
    ////console.log(qs1);
    ////console.log(qs2);
    // if (this.props.overview) {
    //   return paymentBasisPremiumDiscount;
    // } else {
    //   if (qs1) {
    //     return qs1
    //   } else if (qs2) {
    //     return qs2
    //   } else {
    //     return paymentBasisPremiumDiscount;
    //   }
    // }

    if (qs1) {
      return qs1
    } else if (qs2) {
      return qs2
    } else {
      return paymentBasisPremiumDiscount;
    }
  }

  handleCalculateTaxableAmount = () => {
    // //console.log("handleCalculateTaxableAmount proccessing");
    let itemCategoryData = this?.state?.itemCategoryData || null;
    let tcsRate = (this.props.tcsRate?.tcs_rate) || 0;
    let packingAmount = parseInt(this?.state?.packingAmount || 0);
    let insuranceAmount = parseInt(this?.state?.insuranceAmount || 0);
    let loadingAmount = parseInt(this?.state?.loadingAmount || 0);
    let totalItemQty = parseInt(
      this.itemQtyTotal(this?.state?.itemDetailsData)
    );
    let orderRate = parseFloat(this?.state?.orderDetails?.rate || 0).toFixed(2);
    let totalDiscountAmount = Number(parseFloat(
      this?.state?.additionalData?.totalDiscountAmount).toFixed(2)) || 0
      ;
    let freightChargeAmount = Number(parseFloat(
      this?.state?.laDetails?.freight_charge_amount
    ).toFixed(2));

    let freightChargeRate =
      this?.state?.laDetails?.freight_charge_rate;
    let totalRateDifference = 0;
    if (this.props.laOperation === "edit") {
      this.state.itemDetailsData.map((data, index) => {
        this.setState((previousState) => {
          const itemRateDifferenceData = [
            ...previousState.itemRateDifferenceData,
          ];
          itemRateDifferenceData[index] = {
            ...itemRateDifferenceData[index],
            ["item"]: data?.item,
            ["rate_difference"]: this.getRateDiff(data), //this.state.allItemsList.find(f => f.id === data?.item)?.rate_difference,
            ["item_name"]: this.state.allItemsList.find(f => f.id === data?.item)?.item_name,
            ["return_qty"]: data?.return_qty
          };
          return { itemRateDifferenceData };
        }, () => {
          totalRateDifference = parseInt(
            this?.state?.itemRateDifferenceData.length > 0
              ? this?.state?.itemRateDifferenceData
                ?.map((o) => o?.rate_difference)
                ?.filter(d => d)
                ?.reduce((a, c) => {
                  return Number(a) + Number(c);
                }, 0)
              : 0
          );
        });
      })
    }
    else {
      totalRateDifference = parseInt(
        this?.state?.itemRateDifferenceData.length > 0
          ? this?.state?.itemRateDifferenceData
            ?.map((o) => o?.rate_difference)
            ?.filter(d => d)
            ?.reduce((a, c) => {
              return Number(a) + Number(c);
            }, 0)
          : 0
      );
    }

    let loadingPremiumAmnt =
      Number(this.state.discountData?.loading_premium).toFixed(2);
    let insurancePremiumAmnt =
      Number(this.state.discountData?.insurance_premium).toFixed(2);
    let specialPremiumAmnt =
      Number(this.state.discountData?.special_premium).toFixed(2);
    let paymentPremiumAmnt = this.state.laOperation === "edit"
      ? this.state.discountData?.payment_premium
      : this.state.discountData?.payment_premium;

    let totalDiscountedRate =
      (Number(orderRate) +
        Number(freightChargeRate) +
        Number(paymentPremiumAmnt) +
        Number(loadingPremiumAmnt) +
        Number(insurancePremiumAmnt) +
        Number(specialPremiumAmnt) -
        Number(totalDiscountAmount))


    let totalTaxAmt = this?.state?.itemRateDifferenceData.reduce((totalAmt, item, index) => {
      return totalAmt + (totalDiscountedRate +
        Number(item?.rate_difference)) * this.state.itemDetailsData[index]?.item_qty
    }, 0);
    let gstOption = this?.state?.additionalData?.gstOptionValue || null;
    // //console.log("gstOption : ", gstOption);
    let igstRate =
      gstOption === "IGST"
        ? !itemCategoryData
          ? 0
          : Number(itemCategoryData?.igst)
        : 0;
    let cgstRate =
      gstOption !== "IGST"
        ? !itemCategoryData
          ? 0
          : Number(itemCategoryData?.cgst)
        : 0;
    let sgstRate =
      gstOption !== "IGST"
        ? !itemCategoryData
          ? 0
          : Number(itemCategoryData?.sgst)
        : 0;
    let igstAmount = calculatePercent(igstRate, parseFloat(
      totalTaxAmt || 0
    ));
    let sgstAmount = calculatePercent(sgstRate, parseFloat(
      totalTaxAmt || 0
    ));
    // //console.log("1A" + sgstAmount + ": " + sgstRate + " : " + totalTaxableAmount)
    let cgstAmount = calculatePercent(cgstRate, parseFloat(
      totalTaxAmt || 0
    ));
    // //console.log("itemCategoryData : ", itemCategoryData);
    // let gstPercent = 18; // this?.state?.additionalData?.buyer_details?.address?.state === ;
    let totalGstAmount =
      gstOption === "IGST"
        ? Number(igstAmount)
        : Number(sgstAmount) + Number(cgstAmount); //calculatePercent(gstPercent, totalTaxableAmount);


    let totalTcsAmount = calculatePercent(tcsRate, (totalTaxAmt + (totalGstAmount || 0)));
    let freightAdvance = Number(this?.state?.laDetails?.fright_advance);
    let cessAmt = Number(this?.state?.laDetails?.cess_amount)
    let totalAmount = (totalTaxAmt + (totalGstAmount || 0)) + totalTcsAmount + freightAdvance + cessAmt

    this.setState((previousState) => {
      const laDetails = {
        ...previousState.laDetails,
        ["taxable_amount"]: totalTaxAmt,
        ["discounted_rate"]: totalDiscountedRate,
        ["gst_amount"]: totalGstAmount,
        ["premium_amount"]: paymentPremiumAmnt,
        ["freight_charge_rate"]: freightChargeRate,
        ["tcs_rate"]: tcsRate,
        ["total_amount"]: totalAmount,
        ["tcs_display"]: totalTcsAmount?.toFixed(2),
      };
      return { laDetails };
    });
    this.setState((previousState) => {
      const additionalData = {
        ...previousState.additionalData,
        ["igstRate"]: parseInt(igstRate),
        ["igstAmount"]: parseFloat(igstAmount).toFixed(2),
        ["cgstRate"]: parseInt(cgstRate),
        ["cgstAmount"]: parseFloat(cgstAmount).toFixed(2),
        ["sgstRate"]: parseInt(sgstRate),
        ["sgstAmount"]: parseFloat(sgstAmount).toFixed(2),
      };
      return { additionalData };
    });
  };
  handleChangeHandler = (id) => {
    this.setState((previousState) => {
      const laDetails = {
        ...previousState.laDetails,
        ["tran_sup_type"]: id,
      };
      return { laDetails };
    });
  };
  handleCalculateFreightChargeAmount = () => {
    let frightChargeRate = parseFloat(
      this?.state?.laDetails?.freight_charge_rate || 0
    ).toFixed(2);
    let totalItemQty = this.itemQtyTotal(this?.state?.itemDetailsData);
    this.setState((previousState) => {
      const laDetails = {
        ...previousState.laDetails,
        ["freight_charge_amount"]: parseFloat(
          frightChargeRate * totalItemQty
        ).toFixed(2),
      };
      return { laDetails };
    });
  };
  handleCalculateCessAmount = () => {
    let cessRate = parseFloat(this?.state?.laDetails?.cess_rate || 0).toFixed(
      2
    );
    let totalItemQty = this.itemQtyTotal(this?.state?.itemDetailsData);
    this.setState((previousState) => {
      const laDetails = {
        ...previousState.laDetails,
        ["cess_amount"]: parseFloat(cessRate * totalItemQty).toFixed(2),
      };
      return { laDetails };
    });
  };
  itemQtyTotal = (itemData) => {
    let sum =
      itemData.length > 0
        ? itemData
          .map((o) => o.item_qty)
          .reduce((a, c) => {
            return Number(a) + Number(c);
          })
        : 0;
    return sum;
  };

  getTotalDiscount = (discountData) => {
    let total =
      Number(discountData?.city_discount || 0) +
      Number(discountData?.customer_discount || 0) +
      Number(discountData?.payment_discount || 0) +
      Number(discountData?.special_discount || 0);
    return total;
  };

  handleChange = (title, option) => (event) => {
    const { value, checked, name } = event.target;

    if (name === "cess_rate" || name === "freight_charge_rate") {
      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: value,
        };
        return { laDetails };
      });
    }
    else if (title === "tcs_rate") {
      // this.setState(
      //   (previousState) => {
      //     const laDetails = {
      //       ...previousState.laDetails,
      //       [title]: option.id,
      //     };
      //     return { laDetails };
      //   },
      //   () => //console.log(1)
      // );
      // this.setState(
      //   (previousState) => {
      //     const additionalData = {
      //       ...previousState.additionalData,
      //       [title]: option.tcs_percent,
      //     };
      //     return { additionalData };
      //   },
      //   () => //console.log(1)
      // );

      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: value,
        };
        return { laDetails };
      });
    }
    else if (name === "special_discount") {
      this.setState(
        (previousState) => {
          const discountData = {
            ...previousState.discountData,
            [name]: parseInt(value),
          };
          return { discountData };
        },
        () =>
          this.setState((previousState) => {
            const additionalData = {
              ...previousState.additionalData,
              ["totalDiscountAmount"]: this.getTotalDiscount(
                this?.state?.discountData
              ),
            };
            return { additionalData };
          })
      );
    } else if (name === "payment_method") {
      this.setState(
        (previousState) => {
          const laDetails = {
            ...previousState.laDetails,
            [name]: value,
            payment_method_name: this.state?.allPayment && (this.state?.allPayment.find(d => d.id === value))?.payment_method_name
          };
          return { laDetails };
        },
        () => this.handleDiscountCalculation()
      );
    } else if (name === "gstOptionValue") {
      this.setState(
        (previousState) => {
          const additionalData = {
            ...previousState.additionalData,
            ["gstOptionValue"]: value,
          };
          return { additionalData };
        },
        () => this.handleCalculateTaxableAmount()
      );
    } else if (name === "remark") {
      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: value === "" ? null : value,
        };
        return { laDetails };
      });
    } else if (name === "order") {
      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: value,
        };
        return { laDetails };
      }, () => {
        this.props.fetchOrderDetails(this.state.laDetails?.order)
      })
    } else if (name === "sub_contract") {
      //console.log(this.state.subcontracts)
      const paymentId = this.state.subcontracts.find(s => s.id === value)?.payment_term
      const freightId = this.state.subcontracts.find(s => s.id === value)?.freight_basis
      const freight = this.props.freightBasis.find(f => f.id === freightId)?.id
      const buyer = this.state.subcontracts.find(s => s.id === value)?.buyer_detail
      const cessRate = this.state.subcontracts.find(s => s.id === value)?.cess_rate
      const freightChargeRate = this.state.subcontracts.find(s => s.id === value)?.freight_charge_rate
      const lcDate = this.state.subcontracts.find(s => s.id === value)?.payment_doc_date
      const lcNumber = this.state.subcontracts.find(s => s.id === value)?.payment_doc_number
      if (lcNumber) {
        this.setState({
          disableLCNumber: true
        })
      } else {
        this.setState({
          disableLCNumber: false
        })
      }
      if (lcDate) {
        this.setState({
          disableLCDate: true
        })
      } else {
        this.setState({
          disableLCDate: false
        })
      }
      const transType = buyer?.gstin_number
        ? this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id
        : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
      const consignee = this.state.subcontracts.find(s => s.id === value)?.consignee_detail
      const tag = this.state.subcontracts.find(s => s.id === value)?.tag
      this.props.handleGetSubcontractItemsForCategory(value)
      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: value,
          payment_method: paymentId,
          payment_method_name: this.state?.allPayment && (this.state?.allPayment.find(d => d.id === paymentId))?.payment_method_name,
          fright: freight,
          buyer: buyer?.buyer_id,
          consignee: consignee?.id,
          tran_sup_type: transType,
          cess_rate: cessRate,
          freight_charge_rate: freightChargeRate,
          lc_date: lcDate,
          lc_number: lcNumber
        };
        const additionalData = {
          ...previousState.additionalData,
          buyer_details: buyer,
          consignee_details: consignee
        }
        return { laDetails, additionalData };
      }, () => {
        if (((this.props.laOperation === "edit" && this.props.created_from_subcontract) ||
          (this.props.laOperation === "add" && this.state.subcontractEnabled))) {
          this.handleIdentifyGST()
        }
      });
    }
    else {
      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: value,
        };
        return { laDetails };
      });
    }
  };
  handleDateChange = (name) => (date) => {
    if (date !== "Invalid Date") {
      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: moment(date).format("YYYY-MM-DD"),
        };
        return { laDetails };
      });
    } else {
    }
  };
  handleValueChange = (data, name) => {
    if (name === "plant") {
      this.setState((previousState) => {
        const laDetails = {
          ...previousState.laDetails,
          [name]: data.plant,
        };
        return { laDetails };
      });
      this.setState((previousState) => {
        const additionalData = {
          ...previousState.additionalData,
          ["plant_planning_pending_qty"]: this.state.pendingQuantity.find(d => d.plant_name === data.plant_name)?.plant_pending_quantity,
        };
        return { additionalData };
      });
      // //console.log("Invalid data for handle change.");
    } else {
      // //console.log("Invalid data for handle change.");
    }
  };
  handleDisplayAddNewItemButton = () => {
    this.setState({
      totalItemQtySum: this.itemQtyTotal(this?.state?.itemDetailsData),
    });
  };

  handleSelectedItemChange = (index, name) => (e, v) => {
    const data = v;
    const value = v?.id;
    if (this.state.itemDetailsData.map((i) => i.item).includes(data?.id)) {
      return;
    }
    this.setState((previousState) => {
      const itemDetailsData = [...previousState.itemDetailsData];
      itemDetailsData[index] = {
        ...itemDetailsData[index],
        method: itemDetailsData[index]['method'] === 'ADDED'
          ? 'ADDED'
          : this.props?.laItemsList[index]["item"] == value
            ? 'UNCHANGE'
            : 'UPDATED',
        ["item"]: data?.id,
        remark: data?.remark,
        subcontract_available_item_qty: ((this.props?.laOperation === "edit" && this.props.created_from_subcontract) ||
          (this.props?.laOperation === "add" && this.state.subcontractEnabled)) ?
          this.props.allSubcontractItemList?.find(p => p.id == value)?.available_quantity || null : null
      };
      return { itemDetailsData };
    }, () => {
      if (index == 0 && v?.id) {
        this.fetchTcsRateData();
      }
    });
    // //console.log("RATE " + data?.rate_difference)
    this.setState((previousState) => {
      const itemRateDifferenceData = [
        ...previousState.itemRateDifferenceData,
      ];
      itemRateDifferenceData[index] = {
        ...itemRateDifferenceData[index],
        ["item"]: data?.id,
        ["rate_difference"]: this.getRateDiff(data),
        ["item_name"]: data?.item_name,
        ["return_qty"]: data?.return_qty
      };
      return { itemRateDifferenceData };
    });

  }

  handleItemValueChange = (index, data, label) => (e) => {
    const { value, name } = e.target;
    console.log(this.state.itemDetailsData[index]?.subcontract_available_item_qty)
    if (name === "item_qty") {
      if (value) {
        value.match(/^\d*\.?\d{0,3}$/)
          ? this.setState((prevState) => {
            const item_qty_error = [...prevState.item_qty_error];
            const item_qty_error_message = [...prevState.item_qty_error_message];
            item_qty_error[index] = false;
            item_qty_error_message[index] = ''
            return { item_qty_error, item_qty_error_message };
          })
          : this.setState((prevState) => {
            const item_qty_error = [...prevState.item_qty_error];
            const item_qty_error_message = [...prevState.item_qty_error_message];
            item_qty_error[index] = true;
            item_qty_error_message[index] = "Enter upto 3 decimal digit";
            return { item_qty_error, item_qty_error_message };
          })
        if (((this.props?.laOperation === "edit" && this.props.created_from_subcontract) ||
          (this.props?.laOperation === "add" && this.state.subcontractEnabled))) {
          const originalVal = this.state.itemDetailsDataOriginal.find(f => f.item == this.state.itemDetailsData[index]?.item)?.total_available_qty
            || this.props.allSubcontractItemList.find(f => f.id == this.state.itemDetailsData[index]?.item)?.available_quantity

          value > originalVal ? this.setState((prevState) => {
            const item_qty_error = [...prevState.item_qty_error];
            const item_qty_error_message = [...prevState.item_qty_error_message];
            item_qty_error[index] = true;
            item_qty_error_message[index] = "Quantity cannot be greater than available quantity";
            return { item_qty_error, item_qty_error_message };
          })
            : this.setState((prevState) => {
              const item_qty_error = [...prevState.item_qty_error];
              const item_qty_error_message = [...prevState.item_qty_error_message];
              item_qty_error[index] = false;
              item_qty_error_message[index] = ''
              return { item_qty_error, item_qty_error_message };
            })
        }


      }
      this.setState((previousState) => {
        const itemDetailsData = [...previousState.itemDetailsData];
        itemDetailsData[index] = {
          ...itemDetailsData[index],
          method: itemDetailsData[index]['method'] === 'ADDED'
            ? 'ADDED'
            : this.props?.laItemsList[index]["item_qty"] == value
              ? 'UNCHANGE'
              : 'UPDATED',
          [name]: value
        };
        return { itemDetailsData };
      });
    } else if (label === "item") {
      if (this.state.itemDetailsData.map((i) => i.item).includes(data?.id)) {
        return;
      }
      this.setState((previousState) => {
        const itemDetailsData = [...previousState.itemDetailsData];
        itemDetailsData[index] = {
          ...itemDetailsData[index],
          method: itemDetailsData[index]['method'] === 'ADDED'
            ? 'ADDED'
            : this.props?.laItemsList[index]["item"] == value
              ? 'UNCHANGE'
              : 'UPDATED',
          ["item"]: data?.id
        };
        return { itemDetailsData };
      });
      // //console.log("RATE " + data?.rate_difference)
      this.setState((previousState) => {
        const itemRateDifferenceData = [
          ...previousState.itemRateDifferenceData,
        ];
        itemRateDifferenceData[index] = {
          ...itemRateDifferenceData[index],
          ["item"]: data?.id,
          ["rate_difference"]: data?.rate_difference,
          ["item_name"]: data?.item_name,
          ["return_qty"]: data?.return_qty
        };
        return { itemRateDifferenceData };
      });
    } else if (name == "remark") {
      this.setState((previousState) => {
        const itemDetailsData = [...previousState.itemDetailsData];
        itemDetailsData[index] = {
          ...itemDetailsData[index],
          [name]: value
        };
        return { itemDetailsData };
      });
    } else {
    }
  };

  handleCalculateItemDiscount = () => {
    let totalRateDifference =
      this?.state?.itemRateDifferenceData.length > 0
        ? this?.state?.itemRateDifferenceData
          .map((o) => o?.rate_difference)
          .reduce((a, c) => {
            return Number(a) + Number(c);
          })
        : 0;
    this.setState((previousState) => {
      const discountData = {
        ...previousState.discountData,
        ["item_discount"]: totalRateDifference,
      };
      return { discountData };
    });
  };
  handleSaveLaDetails = debounce((e, isCreatedByCustomer) => {
    this.setState(
      (previousState) => {
        const itemDetailsData = this.state.deleteItems.length > 0
          ? [...previousState.itemDetailsData, ...previousState.deleteItems]
          : [...previousState.itemDetailsData]
        return { itemDetailsData };
      },
      () => {
        this.setState(
          (previousState) => {
            const laDetails = {
              ...previousState.laDetails,
              ["items"]: this?.state?.itemDetailsData,
              ["tcs_amount"]: 0
            };
            return { laDetails };
          },
          () => {
            let updatedData = this?.state?.laDetails;
            updatedData.freight_charge_rate = Number(updatedData.freight_charge_rate).toFixed(2);
            this.props?.location?.pathname?.includes("dispatchPlanning") && this.props.handleItemCategoryName(this.props.itemCategoryName)
            if (this.state.subcontractEnabled) {
              updatedData.sub_contract = this.state.laDetails.sub_contract || 0
              updatedData.created_from_subcontract = this.state.subcontractEnabled || false
            }
            updatedData.fright = this.props.freightBasis.find(i => i.id == updatedData.fright)?.name || '';
            this.props.handleLaDataSaveEdit(updatedData, isCreatedByCustomer, updatedData?.plant, updatedData?.gate_in_date);
          }
        );
      })
  }, 500);
  // updatePendingQuantity = (orderNo) => {
  //   this.props.fetchPendingQuantity(orderNo)
  //     ?.then((response) => {
  //       if (response.status === 200) {
  //         this.setState({
  //           pendingQuantity: response.data || []
  //         },
  //           () => {
  //             this.getPendingQuantity("edit")
  //             //console.log(this.getPendingQuantity("edit"))
  //           })
  //       } else {
  //         // //console.log("error fetching pending qty")
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log(error)
  //     })
  // }
  removeItemDetailsData = (itemDataIndex) => {
    this.setState((prevState) => {
      const item_qty_error = [...prevState.item_qty_error];
      const item_qty_error_message = [...prevState.item_qty_error_message];
      item_qty_error[itemDataIndex] = false;
      item_qty_error_message[itemDataIndex] = ''
      return { item_qty_error, item_qty_error_message };
    })

    if (this.state?.itemDetailsData[itemDataIndex].id !== null) {
      // //console.log("1" + Number(this.state?.itemDetailsData[itemDataIndex].item_qty))
      this.setState({
        initialItemQty: (Number(this.state.initialItemQty) - Number(this.state?.itemDetailsData[itemDataIndex].item_qty)),
        deletedItemQty: (Number(this.state.deletedItemQty) + Number(this.state?.itemDetailsData[itemDataIndex].item_qty))
      })
      this.setState(
        (previousState) => {
          const itemDetailsData = [...previousState.itemDetailsData];
          // //console.log(itemDetailsData[itemDataIndex])
          // itemDetailsData[itemDataIndex].id !== null && (
          (itemDetailsData[itemDataIndex]['method'] = 'DELETED')
          const deleteItems = [
            ...previousState.deleteItems,
            itemDetailsData[itemDataIndex],
          ];
          // //console.log(deleteItems)
          itemDetailsData.splice(itemDataIndex, 1);
          // //console.log(itemDetailsData)
          return { itemDetailsData, deleteItems };
        },
        () => {
          this.handleDisplayAddNewItemButton();
          this.setState((previousState) => {
            const itemRateDifferenceData = [...previousState.itemRateDifferenceData];
            itemRateDifferenceData.splice(itemDataIndex, 1);
            return { itemRateDifferenceData };
          });
          this.handleCalculateFreightChargeAmount();
          this.handleCalculateItemDiscount();
          this.handleCalculateTaxableAmount();
          this.handleCalculateCessAmount();
          this.handleDiscountCalculation();
        }
      );
    }
    else {
      this.setState(
        (previousState) => {
          const itemDetailsData = [...previousState.itemDetailsData];
          itemDetailsData.splice(itemDataIndex, 1);
          return { itemDetailsData };
        },
        () => {
          this.handleDisplayAddNewItemButton();
          this.setState((previousState) => {
            const itemRateDifferenceData = [...previousState.itemRateDifferenceData];
            itemRateDifferenceData.splice(itemDataIndex, 1);
            return { itemRateDifferenceData };
          });
          this.handleCalculateFreightChargeAmount();
          this.handleCalculateItemDiscount();
          this.handleCalculateTaxableAmount();
          this.handleCalculateCessAmount();
          this.handleDiscountCalculation();
        }
      );
    }
    // this.setState((previousState) => {
    //   const itemRateDifferenceData = [...previousState.itemRateDifferenceData];
    //   itemRateDifferenceData.splice(itemDataIndex, 1);
    //   return { itemRateDifferenceData };
    // });
    // this.handleCalculateFreightChargeAmount();
    // this.handleCalculateItemDiscount();
    // this.handleCalculateTaxableAmount();
    // this.handleCalculateCessAmount();
    // this.handleDiscountCalculation();
  };
  addNewItemButtonClick = () => {
    let laID = this.state?.itemDetailsData[0]?.loading_advice
    // console.log(this.state.itemDetailsData, this.props.laId, laID)
    this.setState((previousState) => {
      this.setState({ showAddItemsError: false, addItemsErrorMsg: "" });
      return {
        itemDetailsData: [
          ...previousState.itemDetailsData,
          {
            id: null,
            net_weight: "0.000",
            loading_advice: this?.props?.laId || laID,
            item: "",
            item_qty: 0,
            method: 'ADDED'
          },
        ]
      };
    });
  };
  handleTotalDiscountAmountSet = (e) => {
    this.setState(
      (previousState) => {
        const additionalData = {
          ...previousState.additionalData,
          ["totalDiscountAmount"]: this.getTotalDiscount(
            this.state.discountData
          ),
        };
        return { additionalData };
      },
      () => this.handleCalculateTaxableAmount()
    );
  };
  handleAutocompleteValueChange = (name) => (event, value) => {
    if (!value) {
      if (name === "transporter") {
        this.setState((previousState) => {
          const laDetails = {
            ...previousState.laDetails,
            [name]: null,
          };
          return { laDetails };
        });
        this.setState((previousState) => {
          const additionalData = {
            ...previousState.additionalData,
            ["transporter_number"]: null,
          };
          return { additionalData };
        });
      } else if (name === "buyer") {
        this.setState((previousState) => {
          const laDetails = {
            ...previousState.laDetails,
            [name]: null,
          };
          return { laDetails };
        });
        this.setState((previousState) => {
          const additionalData = {
            ...previousState.additionalData,
            ["buyer_details"]: null,
          };
          return { additionalData };
        }, () => this.handleDiscountCalculation());

      } else if (name === "consignee") {
        this.setState((previousState) => {
          const laDetails = {
            ...previousState.laDetails,
            [name]: null,
          };
          return { laDetails };
        });
        this.setState((previousState) => {
          const additionalData = {
            ...previousState.additionalData,
            ["consignee_details"]: null,
          };
          return { additionalData };
        });
      }
    } else {
      if (name === "transporter") {
        this.setState((previousState) => {
          const laDetails = {
            ...previousState.laDetails,
            [name]: value.id,
          };
          return { laDetails };
        });
        this.setState((previousState) => {
          const additionalData = {
            ...previousState.additionalData,
            ["transporter_number"]: Number(value.mobile),
          };
          return { additionalData };
        });
      } else if (name === "buyer") {
        console.log(value)
        this.setState((previousState) => {
          const laDetails = {
            ...previousState.laDetails,
            [name]: value.id,
          };
          return { laDetails };
        });
        this.setState((previousState) => {
          const additionalData = {
            ...previousState.additionalData,
            ["buyer_details"]: value,
          };
          return { additionalData };
        }, () => {
          this.handleDiscountCalculation();
          if (this.state.itemDetailsData?.length > 0) {
            this.props.handleFetchTcsRateData(value?.buyerid, this.state.itemDetailsData[0]?.item)
          }
          this.handleIdentifyGST();
          if (this.state?.orderDetails?.buyer === null || this.state?.orderDetails?.buyer === undefined) {
            this.setState((previousState) => {
              const laDetails = {
                ...previousState.laDetails,
                ['tran_sup_type']: this.state?.additionalData?.buyer_details?.gstin_number
                  ? this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id
                  : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
                ,
              };
              return { laDetails };
            });
          }
          else if (this.state?.laDetails?.buyer) {
            // //console.log(this.state?.laDetails?.buyer)
            this.setState((previousState) => {
              const laDetails = {
                ...previousState.laDetails,
                ['tran_sup_type']: this.state?.additionalData?.buyer_details?.gstin_number
                  ? this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id
                  : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
                ,
              };
              return { laDetails };
            });
          }
        }
        );
      } else if (name === "consignee") {
        this.setState((previousState) => {
          const laDetails = {
            ...previousState.laDetails,
            [name]: value.id,
          };
          return { laDetails };
        });
        this.setState((previousState) => {
          const additionalData = {
            ...previousState.additionalData,
            ["consignee_details"]: value,
          };
          return { additionalData };
        });
      }
    }
  };
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  handleDisableSubmitButton = () => {
    const { laDetails, itemDetailsData } = this.state;
    let laOperation = this?.state?.laOperation;
    if (
      !laDetails?.mode_of_transport ||
      !laDetails?.fright ||
      !laDetails?.fright_advance ||
      !laDetails?.cess_rate ||
      !laDetails?.tcs_rate ||
      !laDetails?.cess_amount ||
      !laDetails?.tcs_display ||
      !laDetails?.freight_charge_rate ||
      !laDetails?.freight_charge_amount ||
      !laDetails?.truck_number ||
      // !laDetails?.lc_number ||
      !laDetails?.truck_capacity ||
      !laDetails?.driver_name ||
      !laDetails?.driver_contact_number ||
      !laDetails?.transporter_contact_number ||
      !laDetails?.driver_licence ||
      !laDetails?.gate_in_date ||
      !laDetails?.buyer ||
      !laDetails?.consignee ||
      !laDetails?.item_category ||
      !laDetails?.plant ||
      !laDetails?.payment_method ||
      !laDetails?.transporter ||
      !laDetails?.order ||
      !laDetails?.taxable_amount ||
      // !laDetails?.gst_amount ||
      !laDetails?.total_amount ||
      itemDetailsData?.length === 0 ||
      !laDetails?.tran_sup_type
    ) {
      return true;
    } else {
      return false;
    }
    // if (laOperation === "add") {
    // } else if (laOperation === "edit") {
    // } else {
    //   return true;
    // }
  };

  handleItems = () => {
    this.setState({
      showAddItemsError: true,
    });
    const itemsData = [...this.state.itemDetailsData];
    itemsData.forEach((i) => {
      if (i.item == "") {
        i.itemError = true;
      }
      if (i.item_qty == 0) {
        i.qtyError = true;
      }
      if (i.remark == null) {
        i.remark = ""
      }
    });
    this.setState({ itemDetailsData: itemsData });
    const isGood =
      itemsData.every((i) => !i.itemError && !i.qtyError) &&
      itemsData.length > 0;

    const isWithinQtyLimit =
      (Number(this.getAvailableQuantity()) + Number(this.state.deletedItemQty)) >=
      (Number(this.state.totalItemQtySum) - Number(this.state.initialItemQty)) &&
      Number(this.state.laDetails?.truck_capacity) >=
      Number(this.state.totalItemQtySum);
    if (isGood && isWithinQtyLimit) {
      this.handleNext();
    } else if (!isWithinQtyLimit) {
      this.setState({
        showAddItemsError: true,
        addItemsErrorMsg:
          "Please fill total item quantity less than or equal to the pending quantity & truck capacity",
      });
    } else if (itemsData.length == 0) {
      this.setState({ addItemsErrorMsg: "Add atleast one item" });
    }
  };

  handleNext = () => {
    const { activeStep } = this.state;
    const plantDetails = this.state.allPlantsData.find(a => this.state.laDetails.plant === a.id);
    this.setState((previousState) => {
      const additionalData = {
        ...previousState.additionalData,
        ["plant_planning_pending_qty"]: this.state.pendingQuantity.find(d => d.plant_name === plantDetails?.plant_name)?.plant_pending_quantity,
      };
      return { additionalData };
    });

    this.setState({
      activeStep: activeStep + 1,
      showAddItemsError: false,
    });


  };

  handleBack = () => {
    const { activeStep } = this.state;
    if (activeStep === 2 && this.props.laOperation === "edit") {
      if (this.state.searchTransportText === '' && this.state.laDetails.transporter !== 0) {
        this.handleViewTransportName(
          String(
            this.props?.laDetails?.transporter?.transporter_id
              ? `${this.props?.laDetails?.transporter?.acc_name} - ( ${this.props?.laDetails?.transporter?.transporter_id} )`
              : this.props?.laDetails?.transporter?.gst_number
                ? `${this.props?.laDetails?.transporter?.acc_name} - ( ${this.props?.laDetails?.transporter?.gst_number} )`
                : this.props?.laDetails?.transporter?.pan_no
                  ? `${this.props?.laDetails?.transporter?.acc_name} - ( ${this.props?.laDetails?.transporter?.pan_no} )`
                  : this.props?.laDetails?.transporter?.acc_name
          )
        )
      }
    }
    this.setState({
      activeStep: activeStep - 1,
      showAddItemsError: false,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      showAddItemsError: false,
    });
  };

  getPlantAvailableQuantity = (plantName) => {
    var plant = this.state.pendingQuantity.find(d => d.plant_name === plantName);
    // //console.log(this.state.pendingQuantity)
    return plant?.plant_pending_quantity;

  }

  getAvailableQuantity = () => {
    const plantName = this.state.plantsList.find(d => d.plant === this.state.laDetails.plant)
    const plant = this.state.pendingQuantity.find(d => d.plant_name === plantName?.plant_name)

    if ((this.props.laOperation === "edit" && this.props.created_from_subcontract) ||
      (this.props.laOperation === "add" && plantName?.sub_contract_enabled)) {
      return this.state?.subcontracts?.find(x => x.id == this.state.laDetails?.sub_contract)?.dispatch_detail?.available_qty;
    } else {
      return plant?.plant_pending_quantity;
    }
  }

  getPaymentPremium = () => {
    let paymentMethodId = this.state.laDetails?.payment_method;
    const paymentMethod = this.state.categoryPaymentMethodDiscountData.find(
      (paymentMethod) => paymentMethod.payment_method === paymentMethodId
    );
    return Number(paymentMethod?.premium_amount || 0).toFixed(2);
  }
  getOrderAvailableQuantity = () => {
    const plantName = this.state.plantsList.find(d => d.plant === this.state.laDetails.plant)
    const plant = this.state.pendingQuantity.find(d => d.plant_name === plantName?.plant_name)
    // //console.log(plant);
    // //console.log(plant?.order_available_quantity)s
    return plant?.order_available_quantity;
  }

  handleGateInDateDisabled = (date) => {
    // //console.log(date)
    // //console.log(new Date(this.state.orderDetails?.order_validity))
    if (!this.state.orderDetails?.order_validity) {
      // //console.log("a " + Boolean(new Date(date) > this.props.generateOrderValidityDate(new Date(), 10)))
      return Boolean(date > this.props.generateOrderValidityDate(new Date(), 10))
    }
    else {
      // //console.log("b " + Boolean(date > new Date(this.state.orderDetails?.order_validity)))
      return Boolean(date > new Date(this.state.orderDetails?.order_validity))
    }
  }

  handleHelperText = (event) => {
    let data = this.state.editData;
    // //console.log("event" + event)
    // //console.log("LA STATUS " + this.props?.laStatus)


    if (this.props?.laStatus === "PENDING" || this.props?.laStatus === "CREATE") {
      // //console.log("1")
      Object.entries(this.state.editData).map(([k, v], index) => (
        this.setState((previousState) => {
          const editData = {
            ...previousState.editData,
            [k]:
              k === "plant" ||
                k === "item_category" ||
                k === "tran_sup_type" ||
                k === "cess_amount" ||
                k === "freight_charge_amount" ||
                k === "transporter_contact_number" ||
                k === "customer_remark"
                ? this.state.helperText.never : this.state.helperText.allow,
          };
          return { editData }
        })
      ))
    }
    else if (["APPROVED", "GATEIN", "LOADING", "PACKED", "ALLOTED"].includes(this.props?.laStatus)) {
      // //console.log("2")
      Object.entries(this.state.editData).map(([k, v], index) => (
        this.setState((previousState) => {
          const editData = {
            ...previousState.editData,
            [k]: ["plant", "item_category", "customer_remark", "tran_sup_type", "cess_amount", "freight_charge_amount", "transporter_contact_number"].includes(k)
              // k === "plant" ||
              //   k === "item_category" ||
              //   k === "tran_sup_type" ||
              //   k === "cess_amount" ||
              //   k === "freight_charge_amount"
              ? this.state.helperText.never
              : k === ("remark")
                ? this.state.helperText.allow
                : (k === "gate_in_date") && !(["PENDING", "APPROVED"].includes(this.props?.laStatus))
                  ? this.state.helperText.never
                  : (k === "item" || k === "quantity") && !(["PENDING", "APPROVED", "GATEIN"].includes(this.props?.laStatus))
                    ? this.state.helperText.never
                    : this.state.helperText.approval_change,
          };
          return { editData }
        })
      ))
    }
  }

  handlePlantValue = () => {
    this.setState((previousState) => {
      const laDetails = {
        ...previousState.laDetails,
        ['plant']: this.props?.location?.pathname?.includes("dispatchPlanning") &&
          (this.props.plantsList.length > 0 &&
            (this.props.plantsList.find(p => p.plant_name == this.props.plantsName))?.plant),
      };
      return { laDetails };
    });
    // this.setState((previousState) => ({
    //   laDetails: {
    //     ...previousState.laDetails,
    //     plant: this.props?.location?.pathname?.includes("dispatchPlanning") &&
    //       (this.props.plantsList.length > 0 &&
    //         (this.props.plantsList.find(p => p.plant_name == this.props.plantsName))?.plant),
    //   },
    // }));

  }

  handleFunctionPageLoad = (...parameters) => {
    //console.log(...parameters)
    //console.log()
    for (let i = 0; i <= parameters.length - 1; i++) {
      if (this.props.overview && i == 7) {
        return false;
      } else {
        if (parameters[i] !== undefined) {
          //console.log(i, parameters[i], Array.isArray(parameters[i]))
          // if (typeof parameters[i] !== 'object') {
          if (Array.isArray(parameters[i])) {
            if (!(parameters[i].length > 0)) {
              //console.log(i + " -- ", parameters[i])
              return true
            }
          }
          else {
            // if (isEmpty(parameters[i])) {
            if (JSON.stringify(parameters[i]) === '{}') {
              // console.log(i + " -- ", parameters[i])
              return true
            }
          }
          // else { //console.log("else"); return true }
        }

        else return true;
      }
    }
    return false;
  }

  getBreadcrumbTitle = () => {
    if (this.props?.location?.pathname?.includes("dispatchPlanning")) {
      return "DISPATCH PLANNING";
    } else if (this.props?.location?.pathname?.includes("internalContracts")) {
      return "CONTRACTS";
    } else if (this.props?.overview) {
      return "LOADING ADVICE OVERVIEW";
    } else {
      return "LOADING ADVICES"
    }
  }

  getBreadcrumbRoute = () => {
    if (this.props?.location?.pathname?.includes("dispatchPlanning")) {
      return this.props.history.push(INTERNALROUTES.DISPATCH_PLANNING);
    } else if (this.props?.location?.pathname?.includes("internalContracts")) {
      return this.props.history.push(INTERNALROUTES.ORDERS);
    } else {
      return this.props.history.push(INTERNALROUTES.LOADINGADVICE);
    }
  }

  isLoadingAdvices = () => {
    return this.props?.location?.pathname?.includes("internalLoadingAdvices");
  }

  handlePrintLAOverviewBtn = () => {
    handlePrintLAOverviewTable(this.state.laDetails?.la_number, "PRINT")
  }

  render() {
    const {
      openSnackbar,
      snackbarMessage,
      snackbarType,
      itemDetailsData,
      allOrders,
      orderDetails,
      allPaymentTermsList,
      laDetails,
      additionalData,
      allItemCategoryList,
      plantsList,
      tranportersList,
      transportListLoader,
      allBuyersList,
      allConsigneeList,
      allItemsList,
      laOperation,
      discountData,
      totalItemQtySum,
      allTcsRates,
      itemRateDifferenceData,
      editData,
      isCreatedByCustomer
    } = this.state;
    const { classes, transType, itemDataFetchLoader, isMobile } = this.props;

    const initialFormValues = {
      lc_number: "",
      plant: "",
      tran_sup_type: "",
      truck_number: "",

    };
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            {/* {//console.log(this.props)} */}

            {laOperation === "edit" ?
              <div className={classes.navigationBar} style={{ flexWrap: 'wrap' }}>
                <Bread
                  data={[
                    {
                      title: this.getBreadcrumbTitle(),
                      onClick: () => this.getBreadcrumbRoute(),
                      style: {
                        fontSize: isMobile && pxToEm(15),
                      },
                    },
                    this.props?.location?.pathname?.includes("internalContracts")
                      ? {
                        title: `${orderDetails.order_number}`,
                        onClick: () => this.props.history.push(`/internalContracts/singleContracts/${orderDetails.id}`),
                        style: {
                          fontSize: isMobile && pxToEm(15),
                        },
                      }
                      : {
                        title: this.props.plants?.length > 0 ? this?.props?.plants?.map((plant) => { if (plant.id == this.props.plantId) return plant.plant_name }) : this.props.plantName,
                        onClick: this.isLoadingAdvices() ? () => this.props.history.push({
                          pathname: `/internalLoadingAdvices/${this.props.plantId}`,
                          state: {
                            prevDate: this.props.selectedDate
                          }
                        })
                          : () => this.props.history.push({
                            pathname: `${INTERNALROUTES.DISPATCH_PLANNING}/${this.props?.itemId}/${this.props?.plantId}`,
                            state: {
                              prevDate: this.props.selectedDate,
                              prevItemCategoryName: this.props.itemCategoryName
                            }
                          }),
                        style: {
                          fontSize: isMobile && pxToEm(15),
                        },
                      },
                    {

                    }
                    // {
                    //   title: `${this.props?.orderDetails?.customer?.first_name} ${this.props?.orderDetails?.customer?.last_name}`,
                    //   onClick: "",
                    // }

                  ]}
                />
                {this.props?.overview ? "" :
                  <>
                    <div className={classes.headerData} style={{ fontSize: isMobile && pxToEm(15) }}>
                      <span>EDIT</span>
                    </div>

                    <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />
                  </>
                }
                <div className={classes.headerData} style={{ fontSize: isMobile && pxToEm(15) }}>
                  <span>{this.props?.orderDetails?.customer?.first_name} {this.props?.orderDetails?.customer?.last_name}</span>
                </div>
                <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />
                <div className={classes.headerData} style={{ fontSize: isMobile && pxToEm(15) }}>
                  <span>{laDetails?.la_number} ( {this.props?.laStatus} )</span>
                </div>
                {this.props.overview ?

                  <Tooltip title="LA Detail" placement="top">
                    <Print
                      style={{ marginLeft: "3px", color: "#4b87c5", cursor: "pointer" }}
                      onClick={this.handlePrintLAOverviewBtn}

                    />
                  </Tooltip>
                  : ""
                }

              </div>
              :
              this.props?.location?.pathname?.includes("dispatchPlanning") ?
                <div className={classes.navigationBar} style={{ flexWrap: 'wrap' }}>
                  <Bread
                    data={[
                      {
                        title: "DISPATCH PLANNING",
                        onClick: () => this.props.history.push(INTERNALROUTES.DISPATCH_PLANNING),
                        style: {
                          fontSize: isMobile && pxToEm(15),
                        },
                      },
                      {
                        title: `${this.props.plantsName}`,
                        onClick: () => this.props.history.push({
                          pathname: `${INTERNALROUTES.DISPATCH_PLANNING}/${this.props?.itemId}/${this.props?.plantId}`,
                          state: {
                            prevDate: this.props.selectedDate,
                            prevItemCategoryName: this.props.itemCategoryName
                          }
                        }),
                        style: {
                          fontSize: isMobile && pxToEm(15),
                        },
                      },
                      {

                      }

                    ]}
                  />
                  <div className={classes.headerData} style={{ fontSize: isMobile && pxToEm(15), }}>
                    <span>ADD LA</span>
                  </div>
                </div>
                :
                <div className={classes.navigationBar} style={{ flexWrap: 'wrap' }}>
                  <Bread
                    data={[
                      {
                        title: "Contracts",
                        onClick: () => this.props.history.push(INTERNALROUTES.ORDERS),
                        style: {
                          fontSize: isMobile && pxToEm(15),
                        },
                      },
                      {
                        title: `${orderDetails.order_number}`,
                        onClick: () => this.props.history.push(`/internalContracts/singleContracts/${orderDetails.id}`),
                        style: {
                          fontSize: isMobile && pxToEm(15),
                        },
                      },
                      {

                      }

                    ]}
                  />
                  <div className={classes.headerData} style={{ fontSize: isMobile && pxToEm(15), }}>
                    <span>ADD LA</span>
                  </div>
                </div>
            }

            {/* {//console.log(laDetails)} this.props?.location?.pathname?.includes(
      "editLoadingAdvice"
    ) */}

            {
              this.handleFunctionPageLoad(
                laDetails,
                allPaymentTermsList,
                allItemCategoryList,
                plantsList,
                transType,
                // tranportersList,
                allBuyersList,
                allConsigneeList,
                allOrders,
                allItemsList
              )
                ? (<div className={classes.noDataDiv}>
                  <SpinLoader />
                </div>)
                :
                (laOperation === "edit" && !this.props.overview) &&
                  this.handleFunctionPageLoad(
                    laDetails,
                    allBuyersList,
                    allConsigneeList,
                    allOrders,
                    allPaymentTermsList,
                    allItemCategoryList,
                    plantsList,
                    transType,
                    // tranportersList,
                    allItemsList,
                  )
                  ? (<div className={classes.noDataDiv}>
                    <SpinLoader />
                  </div>)
                  :
                  (
                    <div className={classes.paperDiv}>
                      <Paper elevation={5} className={classes.paperStyle} style={{ padding: isMobile && '20px 0' }}>
                        <div className={classes.createLoadingAdviceMainDiv}
                          ref={this.overviewContent}
                        >
                          <Stepper
                            classes={{
                              root: classes.root,
                            }}
                            activeStep={this.props.overview ? -1 : this.state.activeStep}
                            orientation="vertical"
                            className={this.props.overview ? classes.stepperVertical : ""}
                          >
                            <Step key="OrderDetails"
                              expanded={this.props.overview}
                            >
                              <StyledStepLabel
                                StepIconProps={{
                                  classes: {
                                    root: classes.stepIconRoot,
                                    labelContainer: classes.labelContainer,
                                  },
                                }}
                              >
                                CONTRACT DETAILS
                              </StyledStepLabel>
                              <StepContent>
                                <Formik
                                  initialValues={{
                                    lc_date: laDetails.lc_date || "",
                                    lc_number: laDetails.lc_number || "",
                                    plant: laDetails.plant || "",
                                    tran_sup_type: laDetails.tran_sup_type || "",
                                    sub_contract: laDetails.sub_contract || "",
                                    subcontractEnabled: this.state.subcontractEnabled || false,
                                    created_from_subcontract: this.props.created_from_subcontract || false,
                                    laOperation: this.props.laOperation
                                  }}
                                  enableReinitialize={true}
                                  validationSchema={validationsOrderDetails}
                                  validateOnChange={false}
                                  validateOnBlur={false}
                                  onSubmit={(v) => {
                                    this.handleNext();
                                  }}
                                >
                                  {(formik) => (
                                    <Form>
                                      <div className={classes.generalDetailsDisplaySection} style={{ alignItems: 'center', flexDirection: isMobile && 'column' }}>
                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%', marginBottom: isMobile && 15 }}>
                                          <div className={'Mui-disabled'}>
                                            <FormControl
                                              variant="outlined"
                                              className={classes.formControl}
                                              size="small"
                                              style={{ margin: isMobile && 0 }}
                                            >
                                              {this.props.overview && allOrders.length === 0 ?

                                                <div className={classes.laOverviewContainer}>
                                                  <div style={{ width: "100%", height: "75px" }}>
                                                    <div
                                                      className={classes.laOverviewLeftContainer}
                                                    >
                                                      <div style={{ width: "65%" }}>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            paddingLeft: "40px"
                                                          }}
                                                        >
                                                          <div
                                                          >
                                                            <span>Contract No. : {this.state.orderDetails?.order_number}</span>
                                                            <span
                                                              className={
                                                                classes.verticalLineStyle
                                                              }
                                                            />
                                                          </div>
                                                          <span>
                                                            {moment(this.state.orderDetails?.created_at).format(
                                                              "DD-MM-YYYY"
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div style={{ width: "35%" }}>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                          }}

                                                        >
                                                          <span>{this.state.orderDetails?.rate}</span>
                                                          <span>{this.state.orderDetails?.quantity} MT</span>
                                                        </div>
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>
                                                :
                                                <>
                                                  <InputLabel id="orderSelectLable">
                                                    SELECT CONTRACT *
                                                  </InputLabel>
                                                  <Select
                                                    name="order"
                                                    id="orderSelectLable"
                                                    value={laDetails.order || ""}
                                                    style={{ pointerEvents: this.props.overview && "none" }}
                                                    onChange={this.handleChange("order")}
                                                    MenuProps={MenuProps}
                                                    label="SELECT CONTRACT *"
                                                    disabled={this.props?.location?.pathname?.includes("dispatchPlanning") || Boolean(laDetails?.sub_contract)}
                                                  >
                                                    {["HOLD", "CLOSED"].includes(this?.props?.orderStatus) && this?.props?.orderDetails && this.state?.orderList !== 0 &&
                                                      this?.props?.orderDetails !== undefined && [this?.props?.orderDetails].map((data) => (
                                                        <MenuItem
                                                          key={data.id}
                                                          component="div"
                                                          value={data.id}
                                                          // disabled={
                                                          //   data.order_available_quantity <= this.props.itemTotalQuantity
                                                          //     ? data.is_fifo_allowed
                                                          //       ? false
                                                          //       : true
                                                          //     : false
                                                          // }
                                                          disabled={
                                                            orderDetails.order_number && orderDetails.order_number === data.order_number
                                                              ? false
                                                              : data.is_fifo_allowed
                                                                ? data.order_available_quantity >= (this.props?.itemTotalQuantity || 0)
                                                                  ? false
                                                                  : true
                                                                : true
                                                          }
                                                        >
                                                          <div style={{ width: "100%" }}>
                                                            {/* {//console.log("TOTAL Q " + this.props.itemTotalQuantity)} */}
                                                            {/* {//console.log("data" + data.id + "--" + data.is_fifo_allowed + " : " +
                                                      data.order_available_quantity + "==" + this.props.itemTotalQuantity)}
                                                    {//console.log(
                                                      data.is_fifo_allowed
                                                        ? data.order_available_quantity >= this.props.itemTotalQuantity
                                                          ? false
                                                          : true
                                                        : true
                                                    )} */}
                                                            <div
                                                              className={
                                                                classes.orderNameMenuContainer
                                                              }
                                                            >
                                                              <div
                                                                className={
                                                                  classes.lockIconContainer
                                                                }
                                                              >
                                                                {data.is_fifo_allowed ? null : (
                                                                  <Lock
                                                                    style={{ marginRight: "5px" }}
                                                                  />
                                                                )}
                                                              </div>

                                                              <div style={{ width: "55%" }}>
                                                                <div
                                                                  style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                  }}
                                                                >
                                                                  <div
                                                                    className={
                                                                      classes.orderIdShowContainer
                                                                    }
                                                                  >
                                                                    {/* <span>Order Id :{data.id}</span> */}
                                                                    <span>Contract No. : {data.order_number} {'( ' + data.order_status + ' )'}</span>
                                                                    <span
                                                                      className={
                                                                        classes.verticalLineStyle
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <span>
                                                                    {moment(data.created_at).format(
                                                                      "DD-MM-YYYY"
                                                                    )}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: "35%" }}>
                                                                <div
                                                                  className={
                                                                    classes.orderQuantityShowContainer
                                                                  }
                                                                >
                                                                  <span>{parseInt(data.rate)}</span>
                                                                  <span>{data.quantity} MT</span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <Divider variant="middle" />
                                                          </div>
                                                        </MenuItem>
                                                      ))}
                                                    {allOrders && laDetails && allOrders.map((data) => (
                                                      <MenuItem
                                                        key={data.id}
                                                        component="div"
                                                        value={data.id}
                                                        // disabled={
                                                        //   data.order_available_quantity <= this.props.itemTotalQuantity
                                                        //     ? data.is_fifo_allowed
                                                        //       ? false
                                                        //       : true
                                                        //     : false
                                                        // }
                                                        disabled={
                                                          orderDetails.order_number && orderDetails.order_number === data.order_number
                                                            ? false
                                                            : data.is_fifo_allowed
                                                              ? data.order_available_quantity >= (this.props?.itemTotalQuantity || 0)
                                                                ? false
                                                                : true
                                                              : true
                                                        }
                                                      >
                                                        <div style={{ width: "100%" }}>
                                                          {/* {//console.log("TOTAL Q " + this.props.itemTotalQuantity)} */}
                                                          {/* {//console.log("data" + data.id + "--" + data.is_fifo_allowed + " : " +
                                                      data.order_available_quantity + "==" + this.props.itemTotalQuantity)}
                                                    {//console.log(
                                                      data.is_fifo_allowed
                                                        ? data.order_available_quantity >= this.props.itemTotalQuantity
                                                          ? false
                                                          : true
                                                        : true
                                                    )} */}
                                                          <div
                                                            className={
                                                              classes.orderNameMenuContainer
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                classes.lockIconContainer
                                                              }
                                                            >
                                                              {data.is_fifo_allowed ? null : (
                                                                <Lock
                                                                  style={{ marginRight: "5px" }}
                                                                />
                                                              )}
                                                            </div>

                                                            <div style={{ width: "55%" }}>
                                                              <div
                                                                style={{
                                                                  display: "flex",
                                                                  flexDirection: "column",
                                                                }}
                                                              >
                                                                <div
                                                                  className={
                                                                    classes.orderIdShowContainer
                                                                  }
                                                                >
                                                                  {/* <span>Order Id :{data.id}</span> */}
                                                                  <span>Contract No. : {data.order_number} {data.order_status === "HOLD" && '( ' + data.order_status + ' )'}</span>
                                                                  <span
                                                                    className={
                                                                      classes.verticalLineStyle
                                                                    }
                                                                  />
                                                                </div>
                                                                <span>
                                                                  {moment(data.created_at).format(
                                                                    "DD-MM-YYYY"
                                                                  )}
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div style={{ width: "35%" }}>
                                                              <div
                                                                className={
                                                                  classes.orderQuantityShowContainer
                                                                }
                                                              >
                                                                <span>{parseInt(data.rate)}</span>
                                                                <span>{data.quantity} MT</span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <Divider variant="middle" />
                                                        </div>
                                                      </MenuItem>
                                                    ))}
                                                  </Select>
                                                  <FormHelperText>
                                                    {editData.order}
                                                  </FormHelperText>
                                                </>
                                              }
                                            </FormControl>
                                          </div>
                                        </div>
                                        <div className={classes.dataFields} style={{ width: isMobile ? '100%' : "48%", marginBottom: isMobile && 15 }}>
                                          <div className={classes.contractDetailsContainer}>
                                            <div className={classes.contractDetails}>
                                              <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                <div style={{ flexShrink: '0', width: "35%" }}>Contract Remarks: </div>
                                                <div style={{ wordWrap: 'break-word', width: '75%' }}>
                                                  {orderDetails?.internal_remark}
                                                </div>
                                              </div>
                                              <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                <div style={{ flexShrink: '0', width: "35%" }}>Party Reference: </div>
                                                <div style={{ wordWrap: 'break-word', width: '65%' }}>
                                                  {orderDetails?.party_ref_number}
                                                </div>
                                              </div>
                                              <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                <div style={{ flexShrink: '0', width: "35%" }}>Party Reference Date: </div>
                                                <div style={{ wordWrap: 'break-word', width: '75%' }}>
                                                  {moment(orderDetails?.party_ref_date).format("DD-MM-YYYY")}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>
                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            {/* {//console.log("S " + !orderDetails?.order_validity
                                              ? this.props.generateOrderValidityDate(
                                                new Date(),
                                                10
                                              )
                                              : this.props.generateOrderValidityDate(
                                                new Date(),
                                                orderDetails?.order_validity
                                              ))} */}
                                            <Fragment>
                                              <ThemeProvider theme={materialTheme}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                  <KeyboardDatePicker
                                                    className={classes.formControl}
                                                    name="gate_in_date"
                                                    size="small"
                                                    inputVariant="outlined"
                                                    label="GATE IN DATE *"
                                                    format="dd/MM/yyyy"
                                                    style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                                    disabled={
                                                      this.props.laStatus === undefined || this.props.overview ?
                                                        false
                                                        :
                                                        ["PENDING", "APPROVED"].includes(this.props?.laStatus)
                                                          ? false
                                                          : true
                                                    }
                                                    // maxDate={
                                                    //   !orderDetails?.order_validity
                                                    //     ? this.props.generateOrderValidityDate(
                                                    //       new Date(),
                                                    //       10
                                                    //     )
                                                    //     : this.props.generateOrderValidityDate(
                                                    //       new Date(),
                                                    //       orderDetails?.order_validity
                                                    //     )
                                                    // }
                                                    disablePast={true}
                                                    shouldDisableDate={(this.props.laStatus === undefined ||
                                                      ["PENDING", "APPROVED"].includes(this.props?.laStatus))
                                                      ? this.handleGateInDateDisabled
                                                      : false
                                                    }
                                                    value={new Date(laDetails.gate_in_date)}
                                                    onChange={this.handleDateChange(
                                                      "gate_in_date"
                                                    )}
                                                    placeholder="e.g.: DD/MM/YYYY"
                                                    InputAdornmentProps={{
                                                      position: "start",
                                                    }}
                                                    InputProps={{
                                                      className: classes.datePicker,
                                                      readOnly: true
                                                    }}
                                                    helperText={editData.gate_in_date}
                                                    error={
                                                      (this.props.laStatus === undefined ||
                                                        ["PENDING", "APPROVED"].includes(this.props?.laStatus)) && false
                                                    }
                                                    FormHelperTextProps={{
                                                      className: classes.helperText,
                                                    }}
                                                  />
                                                </MuiPickersUtilsProvider>
                                              </ThemeProvider>
                                            </Fragment>
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            {/* {//console.log(allPaymentTermsList,
                                              allPaymentTermsList.allotted_payment_method.find(d => d.id == laDetails.payment_method),
                                              allPaymentTermsList.not_allotted_payment_method.find(d => d.payment_method_name == laDetails.payment_method),
                                              this.props?.orderDetails?.payment_method?.payment_method_name
                                            )} */}
                                            <FormControl
                                              variant="outlined"
                                              className={classes.formControl}
                                              size="small"
                                              style={{ margin: isMobile && 0 }}
                                            >
                                              <InputLabel id="payment_method_selectLable">
                                                SELECT PAYMENT TERMS *
                                              </InputLabel>
                                              <Select
                                                name="payment_method"
                                                id="payment_method_selectLable"
                                                defaultValue={this.props?.orderDetails?.payment_method?.id}
                                                // (allPaymentTermsList.allotted_payment_method.find(d => d.id == laDetails.payment_method)) == undefined ||
                                                //   (allPaymentTermsList.not_allotted_payment_method.find(d => d.payment_method_name == laDetails.payment_method)) == undefined
                                                //   ? this.props?.orderDetails?.payment_method?.payment_method_name
                                                //   : laDetails.payment_method}
                                                value={
                                                  laDetails.payment_method || ""
                                                }
                                                onChange={this.handleChange("payment_method")}
                                                MenuProps={MenuProps}
                                                label="SELECT PAYMENT TERMS *"
                                                style={{ pointerEvents: this.props.overview && "none" }}
                                                disabled={laDetails?.sub_contract}
                                              >
                                                {(allPaymentTermsList.allotted_payment_method.find(d => d.id == laDetails.payment_method)) == undefined ||
                                                  (allPaymentTermsList.not_allotted_payment_method.find(d => d.payment_method_name == laDetails.payment_method)) == undefined
                                                  ? <MenuItem
                                                    value={this.props?.orderDetails?.payment_method?.id}
                                                    style={{ display: 'none', color: "black" }}
                                                    key={this.props?.orderDetails?.payment_method?.id}
                                                  >
                                                    {this.props?.orderDetails?.payment_method?.payment_method_name}
                                                  </MenuItem>
                                                  : null
                                                }
                                                <ListSubheader
                                                  style={{ color: grey }}
                                                  disableSticky={true}
                                                  disabled={true}
                                                >
                                                  Allotted
                                                </ListSubheader>
                                                {allPaymentTermsList?.allotted_payment_method
                                                  .length > 0
                                                  ? allPaymentTermsList?.allotted_payment_method.map(
                                                    (paymentTerm, index) => (
                                                      <MenuItem
                                                        value={paymentTerm.id}
                                                        style={{ color: "black" }}
                                                        key={index}
                                                      >
                                                        {paymentTerm.payment_method_name +
                                                          "  (" +
                                                          paymentTerm.days +
                                                          " days)"}
                                                      </MenuItem>
                                                    )
                                                  )
                                                  : null}

                                                <ListSubheader
                                                  style={{ color: grey }}
                                                  disableSticky={true}
                                                  disabled={true}
                                                >
                                                  Not Allotted
                                                </ListSubheader>

                                                {allPaymentTermsList &&
                                                  allPaymentTermsList
                                                    ?.not_allotted_payment_method.length > 0
                                                  ? allPaymentTermsList?.not_allotted_payment_method.map(
                                                    (option, index) => (
                                                      <MenuItem
                                                        value={option.id}
                                                        style={{ color: "black" }}
                                                        key={index}
                                                      >
                                                        {option.payment_method_name +
                                                          "  (" +
                                                          option.days +
                                                          " days)"}
                                                      </MenuItem>
                                                    )
                                                  )
                                                  : null}
                                              </Select>
                                              <FormHelperText>
                                                {laOperation == "edit" && this.props.created_from_subcontract ? this.state.helperText.never : editData.payment_terms}
                                              </FormHelperText>
                                            </FormControl>
                                          </div>
                                        </div>
                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>

                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <FormControl
                                              variant="outlined"
                                              className={classes.formControl}
                                              size="small"
                                              style={{ margin: isMobile && 0 }}
                                            >
                                              <InputLabel id="itemSelectLable">
                                                SELECT ITEM CATEGORY *
                                              </InputLabel>
                                              {/* {console.log(orderDetails,orderDetails.item_category.id)} */}
                                              <Select
                                                disabled={
                                                  true
                                                }
                                                name="item_category"
                                                id="itemSelectLable"
                                                value={laDetails.item_category || ""}
                                                renderValue={(value) => `${orderDetails.item_category.category_name} ${orderDetails.tag !== null
                                                  ? ' (' + (findValueReturnText(this.state?.tagsMaster, 'id', orderDetails.tag, 'tag_name')) + ')'
                                                  : ""}`}
                                                onChange={this.handleChange("item_category")}
                                                MenuProps={MenuProps}
                                                style={{ pointerEvents: this.props.overview && "none" }}
                                                label="SELECT ITEM CATEGORY *"
                                              >
                                                <ListSubheader
                                                  style={{ color: grey }}
                                                  disableSticky={true}
                                                  disabled={true}
                                                >
                                                  Subscribed
                                                </ListSubheader>
                                                {allItemCategoryList?.subscribed.length > 0
                                                  ? allItemCategoryList?.subscribed.map(
                                                    (option, index) => {
                                                      return (
                                                        <MenuItem
                                                          value={option.id}
                                                          style={{ color: "black" }}
                                                          key={index}
                                                        >
                                                          {option.category_name}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )
                                                  : null}

                                                <ListSubheader
                                                  style={{ color: grey }}
                                                  disableSticky={true}
                                                  disabled={true}
                                                >
                                                  Not Subscribed
                                                </ListSubheader>
                                                {allItemCategoryList?.unsubscribed.length > 0
                                                  ? allItemCategoryList?.unsubscribed.map(
                                                    (option, index) => {
                                                      return (
                                                        <MenuItem
                                                          value={option.id}
                                                          style={{ color: "black" }}
                                                          key={index}
                                                        >
                                                          {option.category_name}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )
                                                  : null}
                                              </Select>
                                              <FormHelperText>
                                                {editData.item_category}
                                              </FormHelperText>
                                            </FormControl>
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <FormControl
                                              variant="outlined"
                                              className={classes.formControl}
                                              size="small"
                                              style={{ margin: isMobile && 0 }}
                                            >
                                              {formik.errors.plant ? (
                                                <InputLabel id="plantSelectLable" error>
                                                  SELECT PLANT *
                                                </InputLabel>
                                              ) : (
                                                <InputLabel id="plantSelectLable">
                                                  SELECT PLANT *
                                                </InputLabel>
                                              )}
                                              <Select
                                                name="plant"
                                                id="plantSelectLable"
                                                value={laDetails.plant || ""}
                                                disabled={(this.props?.location?.pathname?.includes("dispatchPlanning")
                                                  || laOperation == "edit") && !this.props.overview}
                                                MenuProps={MenuProps}
                                                style={{ pointerEvents: this.props.overview && "none" }}
                                                onBlur={() => {
                                                  if (!this.state.subcontractEnabled) {
                                                    this.handleIdentifyGST()
                                                  }
                                                }}
                                                onChange={(event) => {
                                                  this.setState((previousState) => ({
                                                    laDetails: {
                                                      ...previousState.laDetails,
                                                      plant: event.target.value,
                                                      sub_contract: null,
                                                    },
                                                  }));
                                                  const plantDetails = plantsList.find(p => p.plant == event.target.value)
                                                  this.setState({ subcontractEnabled: plantDetails.sub_contract_enabled })
                                                  if (!plantDetails.sub_contract_enabled) {
                                                    this.setState((previousState) => ({
                                                      laDetails: {
                                                        ...previousState.laDetails,
                                                        lc_date: null,
                                                        lc_number: ""
                                                      },
                                                    }));
                                                  }
                                                  this.setState({ subcontracts: this.props.subcontractRows.filter(s => s.plant == event.target.value) })
                                                  formik.setFieldValue(
                                                    "plant",
                                                    event.target.value
                                                  );
                                                }}
                                                label="SELECT PLANT *"
                                                error={Boolean(
                                                  formik.errors.plant && formik.touched.plant
                                                )}
                                              >
                                                {plantsList.length > 0
                                                  ? plantsList.map((option, index) => {
                                                    return (
                                                      <MenuItem
                                                        value={option.plant}
                                                        style={{ color: "black" }}
                                                        key={index}
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.handleValueChange(
                                                            option,
                                                            "plant"
                                                          );
                                                        }}
                                                      >
                                                        {option.plant_short_name}-
                                                        {this.getPlantAvailableQuantity(option.plant_name)}MT
                                                      </MenuItem>
                                                    );
                                                  })
                                                  : null}
                                              </Select>
                                              {
                                                formik.errors.plant &&
                                                  formik.touched.plant
                                                  ?
                                                  (<FormHelperText error>
                                                    {
                                                      formik.errors.plant &&
                                                      formik.touched.plant &&
                                                      String(formik.errors.plant)
                                                    }

                                                  </FormHelperText>)
                                                  :
                                                  (<FormHelperText>
                                                    {editData.plant}
                                                  </FormHelperText>)
                                              }
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                      {console.log(this.props.created_from_subcontract)}
                                      {
                                        this.state.subcontractEnabled && this.state.subcontracts?.length > 0 &&
                                        <div className={classes.generalDetailsDisplaySection} style={{ alignItems: 'center', flexDirection: isMobile && 'column' }}>
                                          <div className={classes.dataDisplay} style={{ width: isMobile && '100%', marginBottom: isMobile && 15 }}>
                                            <div className={'Mui-disabled'}>
                                              <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                size="small"
                                                style={{
                                                  margin: isMobile && 0,
                                                  display: (laOperation == "edit" && !this.props.created_from_subcontract) && "none"
                                                }}
                                              >
                                                {this.props.overview && this.state.subcontracts.length === 0 ?

                                                  <div className={classes.laOverviewContainer}>
                                                    <div style={{ width: "100%", height: "75px" }}>
                                                      <div
                                                        className={classes.laOverviewLeftContainer}
                                                      >
                                                        <div style={{ width: "65%" }}>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection: "column",
                                                              paddingLeft: "40px"
                                                            }}
                                                          >
                                                            <div
                                                            >
                                                              <span>Subcontract No. : {this.state.subcontracts?.sub_contract_number}</span>
                                                              <span
                                                                className={
                                                                  classes.verticalLineStyle
                                                                }
                                                              />
                                                            </div>
                                                            <span>
                                                              {moment(this.state.subcontracts?.created_at).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div style={{ width: "35%" }}>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection: "column",
                                                              alignItems: "center",
                                                              justifyContent: "center"
                                                            }}

                                                          >
                                                            <span>{this.state.subcontracts?.dispatch_detail?.available_qty} MT</span>
                                                            <span>BALANCE</span>
                                                          </div>
                                                        </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                  :
                                                  <>
                                                    <InputLabel id="orderSelectLable">
                                                      SELECT SUBCONTRACT *
                                                    </InputLabel>
                                                    <Select
                                                      name="sub_contract"
                                                      id="subcontractSelectLable"
                                                      value={laDetails.sub_contract || ""}
                                                      onChange={this.handleChange("sub_contract")}
                                                      style={{ pointerEvents: this.props.overview && "none" }}
                                                      disabled={laOperation === "edit" || this.state.itemDetailsData?.length > 0}
                                                      MenuProps={MenuProps}
                                                      label="SELECT SUBCONTRACT *"
                                                      error={this.state.subcontractEnabled ? Boolean(
                                                        formik.errors.sub_contract &&
                                                        formik.touched.sub_contract
                                                      ) : false}
                                                    >
                                                      {
                                                        // subcontract of cancelled status should not display in dropdown
                                                        this.state.subcontracts?.filter((item) => item.status !== 'CANCELLED')?.map((data) => (
                                                          <MenuItem
                                                            key={data.id}
                                                            component="div"
                                                            value={data.id}
                                                            disabled={
                                                              moment(data.payment_doc_valid_upto).isBefore(moment(), 'day')
                                                            }
                                                          >
                                                            <div style={{ width: "100%" }}>
                                                              <div
                                                                className={
                                                                  classes.orderNameMenuContainer
                                                                }
                                                              >
                                                                <div style={{ width: "55%" }}>
                                                                  <div
                                                                    style={{
                                                                      display: "flex",
                                                                      flexDirection: "column",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className={
                                                                        classes.orderIdShowContainer
                                                                      }
                                                                    >
                                                                      <span>Subcontract No. : {data.sub_contract_number}</span>
                                                                      <span
                                                                        className={
                                                                          classes.verticalLineStyle
                                                                        }
                                                                      />
                                                                    </div>
                                                                    <span>
                                                                      {moment(data.created_at).format(
                                                                        "DD-MM-YYYY"
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div style={{ width: "35%" }}>
                                                                  <div
                                                                    className={
                                                                      classes.orderQuantityShowContainer
                                                                    }
                                                                  >
                                                                    <span>{data.dispatch_detail?.available_qty} MT</span>
                                                                    <span>BALANCE</span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <Divider variant="middle" />
                                                            </div>
                                                          </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {this.state.subcontractEnabled ?
                                                      (formik.errors.sub_contract &&
                                                        formik.touched.sub_contract)
                                                        ?
                                                        <FormHelperText error>
                                                          {
                                                            formik.errors.sub_contract &&
                                                            formik.touched.sub_contract &&
                                                            String(formik.errors.sub_contract)
                                                          }
                                                        </FormHelperText>
                                                        :
                                                        <FormHelperText>
                                                          {editData.sub_contract}
                                                        </FormHelperText>

                                                      : ""}
                                                  </>
                                                }
                                              </FormControl>
                                            </div>
                                          </div>
                                        </div>
                                      }

                                      <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>
                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <Fragment>
                                              <ThemeProvider theme={materialTheme}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                  <KeyboardDatePicker
                                                    className={classes.formControl}
                                                    name="lc_date"
                                                    size="small"
                                                    inputVariant="outlined"
                                                    label={laDetails.lc_number === null || laDetails.lc_number === ""
                                                      ? "LC DATE"
                                                      : "LC DATE"
                                                    }
                                                    format="dd/MM/yyyy"
                                                    // value={new Date(laDetails.lc_date)}
                                                    value={laDetails.lc_date ? new Date(laDetails.lc_date) : null}
                                                    onChange={this.handleDateChange(
                                                      "lc_date"
                                                    )}
                                                    placeholder="e.g.: DD/MM/YYYY"
                                                    InputAdornmentProps={{
                                                      position: "start",
                                                    }}
                                                    InputProps={{
                                                      className: classes.datePicker,
                                                      readOnly: true
                                                    }}
                                                    // disabled={
                                                    //   (this.state.subcontractEnabled && (laDetails.lc_number == null && laDetails.lc_number == ""))
                                                    //   || (laOperation == "edit" && this.state.subcontractEnabled)
                                                    //   || laDetails.lc_number === null
                                                    //   || laDetails.lc_number === ""
                                                    // }
                                                    disabled={this.state.disableLCDate ||
                                                      (laOperation == "edit" && this.props.created_from_subcontract) ||
                                                      laDetails.lc_number === null ||
                                                      laDetails.lc_number === ""}
                                                    style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                                    //helperText={editData.lc_date}
                                                    FormHelperTextProps={{ className: classes.helperText }}
                                                    helperText={
                                                      (formik.errors.lc_date &&
                                                        formik.touched.lc_date)
                                                        ? String(formik.errors.lc_date)
                                                        : editData.lc_date
                                                    }
                                                    error={Boolean(
                                                      formik.errors.lc_date &&
                                                      formik.touched.lc_date
                                                    )}

                                                  />
                                                </MuiPickersUtilsProvider>
                                              </ThemeProvider>
                                            </Fragment>
                                          </div>
                                        </div>
                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="lc_number"
                                              type="text"
                                              className={classes.formControl}
                                              label="LC NUMBER"
                                              id="outlined-size-small"
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              // disabled={(this.state.subcontractEnabled && (laDetails.lc_date != null && laDetails.lc_date != ""))
                                              //   || (laOperation == "edit" && this.state.subcontractEnabled)
                                              //   || (laDetails.lc_date != null && laDetails.lc_date != "")
                                              // }
                                              disabled={this.state.disableLCNumber || (laOperation == "edit" && this.props.created_from_subcontract)}
                                              value={laDetails.lc_number || ""}
                                              inputProps={{ maxLength: 20, minLength: 0, max: 20 }}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    lc_number: e.target.value,
                                                    lc_date: e.target.value == "" || e.target.value == null ? null : previousState.laDetails.lc_date
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "lc_number",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.lc_number &&
                                                  formik.touched.lc_number)
                                                  ? String(formik.errors.lc_number)
                                                  : editData.lc_number
                                              }
                                              error={Boolean(
                                                formik.errors.lc_number &&
                                                formik.touched.lc_number
                                              )}
                                              onKeyPress={(event) => {
                                                if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>


                                      {this.props.overview ? "" :
                                        <div className={classes.displayFlex}>
                                          <Button
                                            size="medium"
                                            className={classes.saveButton}
                                            variant="contained"
                                            startIcon={<CheckOutlinedIcon />}
                                            disabled={this.state.subcontractEnabled && this.state.subcontracts?.length == 0}
                                            type="submit"
                                          >
                                            CONTINUE
                                          </Button>
                                          {
                                            this.state.subcontractEnabled && this.state.subcontracts?.length == 0 &&
                                            <span style={{ marginLeft: "5px", color: 'red' }}>LA cannot be created without a subcontract for this plant</span>
                                          }
                                        </div>
                                      }
                                    </Form>
                                  )}
                                </Formik>
                              </StepContent>
                            </Step>

                            <Step
                              key="LogisticDetails"
                              classes={{
                                root: classes.step,
                                completed: classes.completed,
                                active: classes.active,
                              }}
                              expanded={this.props.overview}
                            >
                              <StyledStepLabel
                                StepIconProps={{
                                  classes: { root: classes.stepIconRoot },
                                }}
                              >
                                LOGISTIC DETAILS
                              </StyledStepLabel>
                              <StepContent>
                                <Formik
                                  initialValues={{
                                    truck_number: laDetails.truck_number || "",
                                    transporter: laDetails.transporter || "",
                                    driver_name: laDetails.driver_name || "",
                                    driver_licence: laDetails.driver_licence || "",
                                    driver_contact_number:
                                      laDetails.driver_contact_number || "",
                                    truck_capacity: laDetails.truck_capacity || "",
                                    transporter_number:
                                      additionalData.transporter_number || laDetails?.transporter_contact_number || "",
                                  }}
                                  enableReinitialize={true}
                                  validationSchema={validationsLogisticDetails}
                                  validateOnChange={false}
                                  validateOnBlur={false}
                                  onSubmit={(v) => {
                                    this.handleNext();
                                  }}
                                >
                                  {(formik) => (
                                    <Form>
                                      <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>
                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="truck_number"
                                              type="text"
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              className={classes.formControl}
                                              label="TRUCK NUMBER *"
                                              id="outlined-size-small"
                                              value={laDetails?.truck_number?.toUpperCase()}
                                              // onChange={

                                              //   this.handleChange("truck_number")
                                              // }
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    truck_number: e.target.value?.toUpperCase(),
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "truck_number",
                                                  e.target.value?.toUpperCase()
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.truck_number &&
                                                  formik.touched.truck_number)
                                                  ? String(formik.errors.truck_number)
                                                  : editData.truck_number
                                              }
                                              error={Boolean(
                                                formik.errors.truck_number &&
                                                formik.touched.truck_number
                                              )}
                                              onKeyPress={(event) => {
                                                if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            {/* <Autocomplete
                                              autoSelect
                                              options={
                                                tranportersList.length > 0
                                                  ? tranportersList
                                                  : []
                                              }
                                              getOptionLabel={(option) => option.acc_name}
                                              value={tranportersList?.find(
                                                (f) => f.id == laDetails.transporter
                                              )}
                                              onChange={(e, value) => {

                                                formik.setFieldValue(
                                                  "transporter",
                                                  value?.acc_name
                                                );
                                                if (!value) {
                                                  this.setState((previousState) => ({
                                                    laDetails: {
                                                      ...previousState.laDetails,
                                                      transporter: null,
                                                      transporter_contact_number: additionalData?.transporter_number === null && null,
                                                    },
                                                    additionalData: {
                                                      ...previousState.additionalData,
                                                      transporter_number: null,
                                                    },
                                                  }, () => this.setState({ have_transporter_contact_no: false })));
                                                } else {
                                                  this.setState((previousState) => ({
                                                    laDetails: {
                                                      ...previousState.laDetails,
                                                      transporter: value.id,
                                                      transporter_contact_number: value.id === this.props?.laDetails?.transporter?.id ? this.props?.laDetails?.transporter_contact_number : value.mobile === null ? null : Number(value.mobile),
                                                    },
                                                    additionalData: {
                                                      ...previousState.additionalData,
                                                      transporter_number: value.mobile === null ? null : Number(
                                                        value.mobile
                                                      ),
                                                    },
                                                    have_transporter_contact_no: value.id === this.props?.laDetails?.transporter?.id ? false : value.mobile == null ? false : true
                                                  }));
                                                }
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="SEARCH TRANSPORTER *"
                                                  margin="normal"
                                                  variant="outlined"
                                                  name="transporter"
                                                  style={{ pointerEvents: this.props.overview && "none" }}
                                                  helperText={
                                                    (formik.errors.transporter &&
                                                      formik.touched.transporter)
                                                      ? String(formik.errors.transporter)
                                                      : editData.transport
                                                  }
                                                  error={Boolean(
                                                    formik.errors.transporter &&
                                                    formik.touched.transporter
                                                  )}
                                                />
                                              )}
                                              variant="outlined"
                                              className={classes.formControl}
                                              size="small"
                                            /> */}
                                            <Downshift id="downshift-popper">
                                              {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
                                                <div className={classes.formControl} style={{ margin: isMobile && 0 }}>
                                                  <TextField
                                                    label="SEARCH TRANSPORTER *"
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ fontFamily: 'Poppins', pointerEvents: this.props.overview && "none" }}
                                                    fullWidth
                                                    onChangeCapture={(e) => {
                                                      this.handleSearchTransport(e.target.value)
                                                      formik.setFieldValue(
                                                        "transporter",
                                                        e.target.value
                                                      );
                                                    }}
                                                    inputRef={this.anchorEl}
                                                    InputProps={{
                                                      ...getInputProps({
                                                        value: this.state.searchTransportText,
                                                        placeholder: "",
                                                      }),
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <IconButton
                                                            style={{
                                                              padding: 0,
                                                              display: this.state.searchTransportText === '' || this.props.overview
                                                                ? 'none'
                                                                : 'block'
                                                            }}
                                                            onClick={() => {
                                                              this.handleSearchTransport('', true)
                                                              laOperation === 'add' &&
                                                                formik.setFieldValue(
                                                                  "transporter",
                                                                  ''
                                                                )
                                                            }}
                                                          >
                                                            {this.props.overview ? "" :
                                                              <CloseOutlinedIcon />
                                                            }
                                                          </IconButton>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    helperText={
                                                      (formik.errors.transporter &&
                                                        formik.touched.transporter)
                                                        ? String(formik.errors.transporter)
                                                        : editData.transport
                                                    }
                                                    error={Boolean(
                                                      formik.errors.transporter &&
                                                      formik.touched.transporter
                                                    )}
                                                  />
                                                  {this.anchorEl.current && (
                                                    <Popper
                                                      open={this.state.openTransportPopper}
                                                      anchorEl={this.anchorEl.current}
                                                      placement="bottom-start"
                                                      style={{ zIndex: 100 }}
                                                    >
                                                      <ClickAwayListener
                                                        onClickAway={(e) => this.handleSearchTransport(this.state.searchTransportText, true)}
                                                      >
                                                        <div
                                                          {...(isOpen
                                                            ? getMenuProps({}, { suppressRefError: true })
                                                            : {})}
                                                        >
                                                          <Paper
                                                            square
                                                            style={{
                                                              maxHeight: "195px",
                                                              overflowY: "scroll",
                                                              marginTop: 5,
                                                              padding: '10px 1px',
                                                              width: this.anchorEl.current ? this.anchorEl.current.clientWidth + 48 : null,
                                                            }}
                                                          >
                                                            {!transportListLoader && tranportersList.map((data, index) => (
                                                              <MenuItem
                                                                key={index}
                                                                component="div"
                                                                onClick={() => {
                                                                  //console.log(data)
                                                                  formik.setFieldValue(
                                                                    "transporter",
                                                                    data?.acc_name
                                                                  );
                                                                  this.handleSearchTransport(
                                                                    String(
                                                                      data?.transporter_id
                                                                        ? `${data?.acc_name} - ( ${data?.transporter_id} )`
                                                                        : data?.gst_number
                                                                          ? `${data?.acc_name} - ( ${data?.gst_number} )`
                                                                          : data?.pan_no
                                                                            ? `${data?.acc_name} - ( ${data?.pan_no} )`
                                                                            : data?.acc_name
                                                                    ), true
                                                                  )
                                                                  if (!data) {
                                                                    this.setState((previousState) => ({
                                                                      laDetails: {
                                                                        ...previousState.laDetails,
                                                                        transporter: null,
                                                                        transporter_contact_number: additionalData?.transporter_number === null && null,
                                                                      },
                                                                      additionalData: {
                                                                        ...previousState.additionalData,
                                                                        transporter_number: null,
                                                                      },
                                                                    }, () => this.setState({ have_transporter_contact_no: false })));
                                                                  } else {
                                                                    this.setState((previousState) => ({
                                                                      laDetails: {
                                                                        ...previousState.laDetails,
                                                                        transporter: data.id,
                                                                        transporter_contact_number: data.id === this.props?.laDetails?.transporter?.id ? this.props?.laDetails?.transporter_contact_number : checkInvalidValue(data.mobile) ? null : Number(data.mobile),
                                                                      },
                                                                      additionalData: {
                                                                        ...previousState.additionalData,
                                                                        transporter_number: checkInvalidValue(data.mobile) ? null : Number(
                                                                          data.mobile
                                                                        ),
                                                                      },
                                                                      have_transporter_contact_no: data.id === this.props?.laDetails?.transporter?.id ? false : checkInvalidValue(data.mobile) ? false : true
                                                                    }));
                                                                  }
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    lineHeight: "1.1em",
                                                                    fontFamily: 'Poppins',
                                                                  }}
                                                                >
                                                                  <span>
                                                                    {data?.acc_name}
                                                                  </span>
                                                                  <span style={{ color: grey, fontSize: "0.7em" }}>
                                                                    {data?.transporter_id ? 'ID - ' + data?.transporter_id : ""}
                                                                  </span>
                                                                  <span style={{ color: grey, fontSize: "0.7em" }}>
                                                                    {!data?.gst_number
                                                                      ? !data?.pan_no
                                                                        ? ""
                                                                        : `PAN - ${data?.pan_no}`
                                                                      : `GSTIN - ${data?.gst_number}`}
                                                                  </span>
                                                                </div>
                                                              </MenuItem>
                                                            ))}
                                                          </Paper>
                                                        </div>
                                                      </ClickAwayListener>
                                                    </Popper>
                                                  )}
                                                </div>
                                              )}
                                            </Downshift>
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="driver_name"
                                              type="text"
                                              className={classes.formControl}
                                              label="DRIVER NAME *"
                                              id="outlined-size-small"
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              value={laDetails.driver_name || ""}
                                              //onChange={this.handleChange("driver_name")}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    driver_name: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "driver_name",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.driver_name &&
                                                  formik.touched.driver_name)
                                                  ? String(formik.errors.driver_name)
                                                  : editData.driver_name
                                              }
                                              error={Boolean(
                                                formik.errors.driver_name &&
                                                formik.touched.driver_name
                                              )}
                                            />
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="driver_licence"
                                              type="text"
                                              className={classes.formControl}
                                              label="DRIVER LICENCE *"
                                              id="outlined-size-small"
                                              value={laDetails?.driver_licence?.toUpperCase() || ""}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              //onChange={this.handleChange("driver_licence")}
                                              inputProps={{ maxLength: 16, minLength: 1, max: 16 }}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    driver_licence: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "driver_licence",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.driver_licence &&
                                                  formik.touched.driver_licence)
                                                  ? String(formik.errors.driver_licence)
                                                  : editData.driver_licence
                                              }
                                              error={Boolean(
                                                formik.errors.driver_licence &&
                                                formik.touched.driver_licence
                                              )}
                                            />
                                          </div>
                                        </div>
                                        <div className={classes.additionalDataDisplay} style={{ width: isMobile && '100%' }}>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="truck_capacity"
                                              type="number"
                                              className={classes.formControl}
                                              label="TRUCK CAPACITY *"
                                              id="outlined-size-small"
                                              value={laDetails.truck_capacity || ""}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              //onChange={this.handleChange("truck_capacity")}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    truck_capacity: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "truck_capacity",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.truck_capacity &&
                                                  formik.touched.truck_capacity)
                                                  ? String(formik.errors.truck_capacity)
                                                  : editData.truck_capacity
                                              }
                                              error={Boolean(
                                                formik.errors.truck_capacity &&
                                                formik.touched.truck_capacity
                                              )}
                                              InputProps={{
                                                inputProps: { min: 0 },
                                                endAdornment: (
                                                  <InputAdornment position="start">
                                                    MT
                                                  </InputAdornment>
                                                ),
                                              }}
                                              onKeyDown={(evt) => (
                                                ["e", "E", "+", "-"].includes(evt.key) ||
                                                (evt.which === 38 || evt.which === 40)
                                              ) && evt.preventDefault()}
                                            />
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              disabled={this.state?.have_transporter_contact_no && !this.props.overview}
                                              type="text"
                                              className={classes.formControl}
                                              label="TRANSPORTER CONTACT NO."
                                              id="outlined-size-small"
                                              value={additionalData?.transporter_number || laDetails?.transporter_contact_number || ""}
                                              inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                                              // onChange={this.handleChange}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              onKeyPress={(event) => {
                                                if (event.charCode >= 48 && event.charCode <= 57) {
                                                  // let it happen, don't do anything
                                                }
                                                else { event.preventDefault(); }
                                              }}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  additionalData: {
                                                    ...previousState.additionalData,
                                                    transporter_number: e.target.value,
                                                  },
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    transporter_contact_number: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "transporter_number",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.transporter_number &&
                                                  formik.touched.transporter_number)
                                                  ? String(formik.errors.transporter_number)
                                                  // : editData.transporter_contact_number
                                                  : laOperation === 'edit'
                                                    ? !this.state?.have_transporter_contact_no
                                                      ? this.state.helperText.approval_change
                                                      : this.state.helperText.never
                                                    : ''
                                              }
                                              error={Boolean(
                                                formik.errors.transporter_number &&
                                                formik.touched.transporter_number
                                              )}
                                            //inputProps={{ readOnly: true }}
                                            />
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="driver_contact_number"
                                              type="text"
                                              className={classes.formControl}
                                              label="DRIVER CONTACT NO. *"
                                              id="outlined-size-small"
                                              inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              value={laDetails.driver_contact_number || ""}
                                              //   onChange={this.handleChange(
                                              //     "driver_contact_number"
                                              //   )}
                                              onKeyPress={(event) => {
                                                if (event.charCode >= 48 && event.charCode <= 57) {
                                                  // let it happen, don't do anything
                                                }
                                                else { event.preventDefault(); }
                                              }}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    driver_contact_number: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "driver_contact_number",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.driver_contact_number &&
                                                  formik.touched.driver_contact_number)
                                                  ? String(formik.errors.driver_contact_number)
                                                  : editData.driver_contact_number
                                              }
                                              error={Boolean(
                                                formik.errors.driver_contact_number &&
                                                formik.touched.driver_contact_number
                                              )}
                                            />
                                          </div>

                                        </div>
                                        {this.props.overview ? "" :
                                          <div className={classes.displayFlex}>
                                            <Button
                                              size="medium"
                                              className={classes.saveButton}
                                              variant="contained"
                                              startIcon={<CheckOutlinedIcon />}
                                              //onClick={this.handleNext}
                                              // disabled={laOperation === 'add' && this.state.searchTransportText === ''}
                                              type="submit"
                                            >
                                              CONTINUE
                                            </Button>
                                            <Button
                                              size="medium"
                                              onClick={this.handleBack}
                                              className={classes.backButton}
                                            >
                                              BACK
                                            </Button>
                                          </div>
                                        }
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </StepContent>
                            </Step>

                            <Step
                              key="BuyerDetails"
                              classes={{
                                root: classes.step,
                                completed: classes.completed,
                                active: classes.active,
                              }}
                              expanded={this.props.overview}
                            >
                              <StyledStepLabel
                                StepIconProps={{
                                  classes: { root: classes.stepIconRoot },
                                }}
                              >
                                BUYER AND CONSIGNEE DETAILS
                              </StyledStepLabel>
                              <StepContent>
                                <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>
                                  <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                    <div className={classes.dataFieldsForColumn}>
                                      <Autocomplete
                                        autoSelect
                                        options={
                                          allBuyersList.length > 0 ? allBuyersList : []
                                        }
                                        disabled={((laOperation === "edit" && this.props.created_from_subcontract) ||
                                          (laOperation === "add" && this.state.subcontractEnabled))}
                                        getOptionLabel={(option) =>
                                          option !== 'Select Buyer' && option.first_name + " " + option.last_name
                                        }
                                        style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                        value={!isEmpty(additionalData?.buyer_details)
                                          ? additionalData?.buyer_details
                                          : "Select Buyer"}
                                        onChange={this.handleAutocompleteValueChange("buyer")}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="SEARCH BUYER *"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={laOperation == "edit" && this.props.created_from_subcontract ? this.state.helperText?.never : editData.buyer}
                                            style={{ pointerEvents: this.props.overview && "none" }}
                                          />
                                        )}
                                        variant="outlined"
                                        className={classes.formControl}
                                        size="small"
                                      />
                                      {!isEmpty(additionalData?.buyer_details) ?
                                        <div
                                          className={classes.additionalData}
                                          style={{ width: '100%', margin: 5, padding: '0 5px' }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              NAME:{" "}
                                            </span>
                                            <span style={{ width: "70%" }}>
                                              {additionalData?.buyer_details?.first_name || ""}{" "}
                                              {additionalData?.buyer_details?.last_name || ""}
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              {!additionalData?.buyer_details?.gstin_number
                                                ? !additionalData?.buyer_details
                                                  ?.pan_card_number
                                                  ? ""
                                                  : "PAN: "
                                                : "GSTIN: "}
                                            </span>
                                            <span style={{ width: "70%" }}>
                                              {!additionalData?.buyer_details?.gstin_number
                                                ? !additionalData?.buyer_details
                                                  ?.pan_card_number
                                                  ? ""
                                                  : additionalData?.buyer_details
                                                    ?.pan_card_number
                                                : additionalData?.buyer_details?.gstin_number}
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              PHONE:{" "}
                                            </span>
                                            <span style={{ width: "70%" }}>
                                              {additionalData?.buyer_details?.phone || ""}
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              ADDRESS:{" "}
                                            </span>
                                            <span style={{ width: "70%" }}>
                                              {/* {//console.log(additionalData?.buyer_details)} */}
                                              {!isEmpty(additionalData?.buyer_details) ? (
                                                (
                                                  additionalData?.buyer_details?.address?.address ?
                                                    (
                                                      (additionalData?.buyer_details?.address?.address
                                                        ? additionalData?.buyer_details?.address?.address + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.city
                                                        ? additionalData?.buyer_details?.address?.city + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.state
                                                        ? additionalData?.buyer_details?.address?.state + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.country
                                                        ? additionalData?.buyer_details?.address?.country + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.pincode
                                                        ? additionalData?.buyer_details?.address?.pincode
                                                        : "")
                                                    )
                                                    :
                                                    (
                                                      (additionalData?.buyer_details?.address?.door_number
                                                        ? additionalData?.buyer_details?.address?.door_number + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.floor_number
                                                        ? 'Floor No - ' + additionalData?.buyer_details?.address?.floor_number + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.building_name
                                                        ? additionalData?.buyer_details?.address?.building_name + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.street
                                                        ? additionalData?.buyer_details?.address?.street + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.location
                                                        ? additionalData?.buyer_details?.address?.location + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.district_name
                                                        ? additionalData?.buyer_details?.address?.district_name + ", "
                                                        : "")
                                                      +
                                                      ((additionalData?.buyer_details?.address?.city_name && additionalData?.buyer_details?.address?.district_name)
                                                        ? ((additionalData?.buyer_details?.address?.city_name).trim().toUpperCase() !== (additionalData?.buyer_details?.address?.district_name).trim().toUpperCase())
                                                          ? additionalData?.buyer_details?.address?.city_name + ", "
                                                          : ""
                                                        : additionalData?.buyer_details?.address?.city_name
                                                          ? additionalData?.buyer_details?.address?.city_name + ", "
                                                          : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.state_name
                                                        ? additionalData?.buyer_details?.address?.state_name + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.buyer_details?.address?.pincode
                                                        ? additionalData?.buyer_details?.address?.pincode
                                                        : "")
                                                    )
                                                )
                                              )
                                                :
                                                ""
                                              }

                                            </span>
                                          </div>
                                        </div>
                                        :
                                        null
                                      }
                                    </div>
                                  </div>
                                  <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                    <div className={classes.dataFieldsForColumn}>
                                      {/* {console.log("CONSIGNEE " + JSON.stringify(additionalData?.consignee_details))} */}
                                      <Autocomplete
                                        autoSelect
                                        options={
                                          allConsigneeList.length > 0 ? allConsigneeList : []
                                        }
                                        disabled={((laOperation === "edit" && this.props.created_from_subcontract) ||
                                          (laOperation === "add" && this.state.subcontractEnabled))}
                                        getOptionLabel={(option) =>
                                          option !== 'Select Consignee' && (
                                            (option?.consignee_detail?.first_name || "") +
                                            " " +
                                            (option?.consignee_detail?.last_name || "") +
                                            ", " +
                                            (
                                              option?.consignee_address?.address ?
                                                (
                                                  (option?.consignee_address?.address
                                                    ? option?.consignee_address?.address + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.city
                                                    ? option?.consignee_address?.city + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.state
                                                    ? option?.consignee_address?.state + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.country
                                                    ? option?.consignee_address?.country + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.pincode
                                                    ? option?.consignee_address?.pincode
                                                    : "")
                                                )
                                                :
                                                (
                                                  (option?.consignee_address?.door_number
                                                    ? option?.consignee_address?.door_number + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.floor_number
                                                    ? 'Floor No - ' + option?.consignee_address?.floor_number + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.building_name
                                                    ? option?.consignee_address?.building_name + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.street
                                                    ? option?.consignee_address?.street + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.location
                                                    ? option?.consignee_address?.location + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.district_name
                                                    ? option?.consignee_address?.district_name + ", "
                                                    : "")
                                                  +
                                                  ((option?.consignee_address?.city_name && option?.consignee_address?.district_name)
                                                    ? ((option?.consignee_address?.city_name).trim().toUpperCase() !== (option?.consignee_address?.district_name).trim().toUpperCase())
                                                      ? option?.consignee_address?.city_name + ", "
                                                      : ""
                                                    : option?.consignee_address?.city_name
                                                      ? option?.consignee_address?.city_name + ", "
                                                      : "")
                                                  +
                                                  (option?.consignee_address?.state_name
                                                    ? option?.consignee_address?.state_name + ", "
                                                    : "")
                                                  +
                                                  (option?.consignee_address?.pincode
                                                    ? option?.consignee_address?.pincode
                                                    : "")
                                                )
                                            ))
                                        }
                                        onChange={this.handleAutocompleteValueChange(
                                          "consignee"
                                        )}
                                        style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                        value={!isEmpty(additionalData?.consignee_details)
                                          ? additionalData?.consignee_details
                                          : "Select Consignee"
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="SEARCH CONSIGNEE *"
                                            margin="normal"
                                            variant="outlined"
                                            helperText={laOperation == "edit" && this.props.created_from_subcontract ? this.state.helperText?.never : editData.consignee}
                                            style={{ pointerEvents: this.props.overview && "none" }}
                                          />
                                        )}
                                        variant="outlined"
                                        className={classes.formControl}
                                        size="small"
                                      />
                                      {!isEmpty(additionalData?.consignee_details) ?
                                        <div
                                          className={classes.additionalData}
                                          style={{ width: '100%', margin: 5, padding: '0 5px' }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              NAME:{" "}
                                            </span>
                                            <span style={{ width: "70%" }}>
                                              {additionalData?.consignee_details
                                                ?.consignee_detail?.first_name || ""}{" "}
                                              {additionalData?.consignee_details
                                                ?.consignee_detail?.last_name || ""}
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              {!additionalData?.consignee_details
                                                ?.consignee_detail?.gstin_number
                                                ? !additionalData?.consignee_details
                                                  ?.consignee_detail?.pan_card_number
                                                  ? ""
                                                  : "PAN: "
                                                : "GSTIN: "}
                                            </span>
                                            <span style={{ width: "70%" }}>
                                              {!additionalData?.consignee_details
                                                ?.consignee_detail?.gstin_number
                                                ? !additionalData?.consignee_details
                                                  ?.consignee_detail?.pan_card_number
                                                  ? ""
                                                  : additionalData?.consignee_details
                                                    ?.consignee_detail?.pan_card_number
                                                : additionalData?.consignee_details
                                                  ?.consignee_detail?.gstin_number}
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              PHONE:{" "}
                                            </span>
                                            <span style={{ width: "70%" }}>
                                              {additionalData?.consignee_details
                                                ?.consignee_detail?.phone || ""}
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: "30%",
                                                fontWeight: "bold",
                                                color: grey,
                                              }}
                                            >
                                              ADDRESS:{" "}
                                            </span>
                                            <span style={{ width: "70%" }}>

                                              {!isEmpty(additionalData?.consignee_details) ? (
                                                (
                                                  additionalData?.consignee_details?.consignee_address?.address ?
                                                    (
                                                      (additionalData?.consignee_details?.consignee_address?.address
                                                        ? additionalData?.consignee_details?.consignee_address?.address + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.city
                                                        ? additionalData?.consignee_details?.consignee_address?.city + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.state
                                                        ? additionalData?.consignee_details?.consignee_address?.state + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.country
                                                        ? additionalData?.consignee_details?.consignee_address?.country + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.pincode
                                                        ? additionalData?.consignee_details?.consignee_address?.pincode
                                                        : "")
                                                    )
                                                    :
                                                    (
                                                      (additionalData?.consignee_details?.consignee_address?.door_number
                                                        ? additionalData?.consignee_details?.consignee_address?.door_number + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.floor_number
                                                        ? 'Floor No - ' + additionalData?.consignee_details?.consignee_address?.floor_number + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.building_name
                                                        ? additionalData?.consignee_details?.consignee_address?.building_name + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.street
                                                        ? additionalData?.consignee_details?.consignee_address?.street + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.location
                                                        ? additionalData?.consignee_details?.consignee_address?.location + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.district_name
                                                        ? additionalData?.consignee_details?.consignee_address?.district_name + ", "
                                                        : "")
                                                      +
                                                      ((additionalData?.consignee_details?.consignee_address?.city_name && additionalData?.consignee_details?.consignee_address?.district_name)
                                                        ? ((additionalData?.consignee_details?.consignee_address?.city_name).trim().toUpperCase() !== (additionalData?.consignee_details?.consignee_address?.district_name).trim().toUpperCase())
                                                          ? additionalData?.consignee_details?.consignee_address?.city_name + ", "
                                                          : ""
                                                        : additionalData?.consignee_details?.consignee_address?.city_name
                                                          ? additionalData?.consignee_details?.consignee_address?.city_name + ", "
                                                          : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.state_name
                                                        ? additionalData?.consignee_details?.consignee_address?.state_name + ", "
                                                        : "")
                                                      +
                                                      (additionalData?.consignee_details?.consignee_address?.pincode
                                                        ? additionalData?.consignee_details?.consignee_address?.pincode
                                                        : "")
                                                    )
                                                )
                                              )
                                                :
                                                ""
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        :
                                        null
                                      }
                                    </div>
                                  </div>
                                  <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                    <div className={classes.dataFields}>
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        size="small"
                                        style={{ margin: isMobile && 0 }}
                                      >
                                        <InputLabel id="payment_method_selectLable">
                                          SELECT TRANS TYPE *
                                        </InputLabel>

                                        {/* {//console.log(orderDetails)} */}
                                        {/* {//console.log(laDetails)} */}
                                        {/* {//console.log((orderDetails?.buyer === null || orderDetails?.buyer === undefined)
                                              ? laDetails?.buyer && additionalData?.buyer_details?.gstin_number
                                              : false)} */}
                                        <Select
                                          name="tran_sup_type"
                                          id="payment_method_selectLable"
                                          value={laDetails.tran_sup_type || ""}
                                          style={{ pointerEvents: this.props.overview && "none" }}
                                          disabled={(orderDetails?.buyer === null || orderDetails?.buyer === undefined)
                                            ? laDetails?.buyer
                                              ? additionalData?.buyer_details?.gstin_number
                                                ? this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id
                                                : this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id
                                              : false
                                            : this.props.overview
                                              ? false
                                              : orderDetails?.buyer?.gstin_number === null || orderDetails?.buyer?.gstin_number === undefined ? this.props.transType?.find(t => t.tran_supply_type === "B2C")?.id : this.props.transType?.find(t => t.tran_supply_type === "B2B")?.id}
                                          onChange={(e) => {
                                            // //console.log(e.target.value, this.props.transType)
                                            this.setState((previousState) => ({
                                              laDetails: {
                                                ...previousState.laDetails,
                                                tran_sup_type: e.target.value,
                                              },
                                            }));
                                          }}
                                          MenuProps={MenuProps}
                                          label="SELECT TRANS TYPE *"
                                        >
                                          {transType.length > 0 &&
                                            transType.map((data, index) => (
                                              <MenuItem
                                                value={data.id}
                                                style={{ color: "black" }}
                                                key={index}
                                              >
                                                {data.tran_supply_type} (
                                                {data.description})
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                                {this.props.overview ? "" :
                                  <div className={classes.displayFlex}>
                                    <Button
                                      size="medium"
                                      className={classes.saveButton}
                                      variant="contained"
                                      startIcon={<CheckOutlinedIcon />}
                                      disabled={
                                        isEmpty(additionalData?.buyer_details) ||
                                        isEmpty(additionalData?.consignee_details)
                                      }
                                      onClick={
                                        this.handleNext
                                      }
                                    >
                                      CONTINUE
                                    </Button>
                                    <Button
                                      size="medium"
                                      className={classes.backButton}
                                      onClick={this.handleBack}
                                    >
                                      BACK
                                    </Button>
                                  </div>
                                }
                              </StepContent>
                            </Step>

                            <Step
                              key="AddItems"
                              classes={{
                                root: classes.step,
                                completed: classes.completed,
                                active: classes.active,
                              }}
                              expanded={this.props.overview}
                            >
                              <StyledStepLabel
                                StepIconProps={{
                                  classes: { root: classes.stepIconRoot },
                                }}
                              >
                                ADD ITEMS
                              </StyledStepLabel>
                              <StepContent>
                                <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>
                                  <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                    <div className={classes.dataFields}>
                                      <Typography className={classes.titleLabel} style={{ width: isMobile && '100%' }}>
                                        PENDING QUANTITY
                                      </Typography>
                                      <Typography className={classes.formControl}>
                                        {this.getPendingQuantity("edit")}
                                        MT
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className={classes.additionalDataDisplay} style={{ width: isMobile && '100%' }}>
                                    <Typography className={classes.titleLabelStyle}>
                                      {" "}
                                    </Typography>
                                    <div className={classes.dataFields}>
                                      <Typography className={classes.titleLabel} style={{ width: isMobile && '100%' }}>
                                        TRUCK CAPACITY
                                      </Typography>
                                      <Typography className={classes.formControl}>
                                        {laDetails?.truck_capacity || 0} MT
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className={classes.additionalDataDisplay}>
                                    <Typography className={classes.titleLabelStyle}>
                                      {" "}
                                    </Typography>
                                  </div>
                                </div>
                                <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', paddingBottom: laOperation != "add" ? "" : '7px' }}>
                                  {this.props.laItemsList && itemDetailsData?.length > 0
                                    ? itemDetailsData?.map((itemData, index) => (
                                      <div style={{ paddingBottom: laOperation != "add" ? "" : '12px', width: "100%", display: "flex" }}>
                                        <div className={classes.dataDisplayItemData} style={{ width: isMobile && '100%', marginBottom: isMobile && 15 }}>
                                          <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            size="small"
                                            style={{ margin: isMobile && 0 }}
                                          >
                                            <Autocomplete
                                              name="item"
                                              required
                                              options={
                                                ((laOperation === "edit" && this.props.created_from_subcontract) ||
                                                  (laOperation === "add" && this.state.subcontractEnabled)) ?
                                                  this.props.allSubcontractItemList?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined)
                                                  : this.props.orderDetails?.tag ? this.props.allItemsListWithTag?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined) || []
                                                    : allItemsList?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined)
                                              }
                                              error={itemData?.itemError}
                                              style={{ pointerEvents: this.props.overview && "none", marginRight: "20px" }}
                                              disabled={
                                                this.props.laStatus === undefined || this.props.overview ?
                                                  false
                                                  :
                                                  ["PENDING", "APPROVED", "GATEIN"].includes(this.props?.laStatus)
                                                    ? false
                                                    : (["LOADING"].includes(this.props?.laStatus) &&
                                                      (this.state.laDetails.weightsTaken.find(item2 => item2.item_id === this.props.allItemsList?.find(a => a?.id == itemData?.item)?.id) == null))

                                                      ? false
                                                      : true
                                              }
                                              onBlur={() => {
                                                itemData?.item == ""
                                                  ? (itemData.itemError = true)
                                                  : (itemData.itemError = false);
                                                this.handleDiscountCalculation();
                                              }}
                                              value={this.props.allItemsList?.find(a => a?.id == itemData?.item) || ""}
                                              getOptionLabel={(option) => (option.item_name ? `${option.item_name} - (${option.items_nature})` : "")}
                                              sx={{ width: 300 }}
                                              onChange={
                                                this.handleSelectedItemChange(
                                                  index,
                                                  "item"
                                                )

                                              }
                                              FormHelperTextProps={{ className: laOperation != "add" ? "" : classes.helperText1 }}
                                              renderInput={(params) =>
                                                <TextField {...params}
                                                  label="SELECT ITEM *"
                                                  variant="outlined"
                                                  error={itemData?.itemError}
                                                  size="small" />
                                              }
                                            />

                                            {itemData.itemError ?
                                              <FormHelperText error>
                                                {itemData.itemError && (
                                                  <span>Please select Item</span>
                                                )}
                                              </FormHelperText>
                                              :
                                              <FormHelperText>
                                                {["LOADING"].includes(this.props?.laStatus) ?
                                                  (this.state.laDetails.weightsTaken.find(item2 => item2.item_id === this.props.allItemsList?.find(a => a?.id == itemData?.item)?.id) == null)
                                                    ? this.state.helperText?.approval_change
                                                    : this.state.helperText?.never
                                                  : editData?.item}
                                              </FormHelperText>
                                            }
                                          </FormControl>
                                        </div>
                                        {/* {console.log(this.state.itemDetailsDataOriginal.find(f => f.item == this.state.itemDetailsData[index]?.item)?.total_available_qty)}
                                        {console.log("hh", this.state.itemDetailsData[index])}
                                        {console.log("ori", this.state.itemDetailsDataOriginal)} */}
                                        <div className={classes.dataDisplayItemData} style={{ width: isMobile && '100%', marginBottom: isMobile && 15 }}>
                                          <TextField
                                            name="item_qty"
                                            type="number"
                                            required
                                            style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0, marginRight: "20px" }}
                                            error={itemData?.qtyError || this.state.item_qty_error[index]}
                                            disabled={
                                              this.props.laStatus === undefined || this.props.overview ?
                                                false
                                                :
                                                ["PENDING", "APPROVED", "GATEIN"].includes(this.props?.laStatus)
                                                  ?
                                                  false
                                                  : (["LOADING"].includes(this.props.laStatus) &&
                                                    (this.state.laDetails.weightsTaken.find(item2 => item2.item_id === this.props.allItemsList?.find(a => a?.id == itemData?.item)?.id)?.alloted_weight == null))
                                                    ? false
                                                    : true
                                            }
                                            FormHelperTextProps={{ className: laOperation != "add" ? "" : classes.helperText1 }}
                                            helperText={
                                              !this.state.item_qty_error[index] && !itemData?.qtyError && ((laOperation === "edit" && this.props.created_from_subcontract) ||
                                                (laOperation === "add" && this.state.subcontractEnabled)) ?
                                                laOperation == "add" ?
                                                  `Available Quantity ${(Number(this.props.allSubcontractItemList?.find(f => f.id == this.state.itemDetailsData[index]?.item)?.available_quantity || 0) - Number(itemData.item_qty || 0)).toFixed(3)}`
                                                  : `Available Quantity: 
                                                   ${Number(this.state.itemDetailsDataOriginal?.find(f => f.item == this.state.itemDetailsData[index]?.item)?.total_available_qty || this.props.allSubcontractItemList.find(f => f.id == this.state.itemDetailsData[index]?.item)?.available_quantity || 0)
                                                  - Number(itemData.item_qty || 0)}` :
                                                itemData?.qtyError
                                                  ? "Please enter quantity!"
                                                  : this.state.item_qty_error[index]
                                                    ? this.state.item_qty_error_message[index]
                                                    : ["LOADING"].includes(this.props?.laStatus) ?
                                                      (this.state.laDetails.weightsTaken.find(item2 => item2.item_id === this.props.allItemsList?.find(a => a?.id == itemData?.item)?.id) == null)
                                                        ? this.state.helperText?.approval_change
                                                        : this.state.helperText?.never
                                                      : editData.quantity
                                            }
                                            onKeyDown={(evt) => (
                                              ["e", "E", "+", "-"].includes(evt.key) ||
                                              (evt.which === 38 || evt.which === 40)
                                            ) && evt.preventDefault()}
                                            className={classes.formControl}
                                            label="ENTER QUANTITY"
                                            value={itemData.item_qty || ""}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={this.handleItemValueChange(index)}
                                            onBlur={() => {
                                              itemData?.item_qty == 0
                                                ? (itemData.qtyError = true)
                                                : (itemData.qtyError = false);
                                              this.handleCalculateFreightChargeAmount();
                                              this.handleCalculateItemDiscount();
                                              this.handleCalculateTaxableAmount();
                                              this.handleCalculateCessAmount();
                                              this.handleDiscountCalculation();
                                              this.handleDisplayAddNewItemButton();
                                            }}
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  MT
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </div>
                                        <div className={classes.dataDisplayItemData} style={{ width: isMobile && '100%', marginBottom: isMobile && 15 }}>
                                          <TextField
                                            name="remark"
                                            label="ITEM REMARKS"
                                            className={classes.formControl}
                                            onChange={this.handleItemValueChange(index)}
                                            multiline
                                            variant="outlined"
                                            size="small"
                                            value={itemData.remark || ""}
                                            FormHelperTextProps={{ className: laOperation != "add" ? "" : classes.helperText1 }}
                                            style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0, marginRight: "20px" }}
                                            helperText={editData.remark}
                                          />

                                          {this.props.overview ? "" :
                                            <>
                                              {laOperation === "add" ? (
                                                <IconButton
                                                  className={classes.margin}
                                                  onClick={() =>
                                                    this.removeItemDetailsData(index)
                                                  }
                                                  size="small"
                                                >
                                                  <DeleteIcon fontSize="small" />
                                                </IconButton>
                                              )
                                                : laOperation === "edit" ? (
                                                  <IconButton
                                                    className={classes.margin}
                                                    disabled={
                                                      ["PENDING", "APPROVED", "GATEIN"].includes(this.props?.laStatus)
                                                        // (this.props?.laStatus != "PENDING" ||
                                                        //   this.props?.laStatus != "APPROVED" ||
                                                        //   this.props?.laStatus != "GATEIN")
                                                        ? false
                                                        : (["LOADING"].includes(this.props?.laStatus) &&
                                                          (this.state.laDetails.weightsTaken.find(item2 => item2.item_id === this.props.allItemsList?.find(a => a?.id == itemData?.item)?.id)?.alloted_weight == null))
                                                          ? false
                                                          : true
                                                    }
                                                    onClick={() => {
                                                      this.removeItemDetailsData(index)
                                                      // //console.log(itemData)
                                                      // if (itemData.id !== null) {
                                                      //   this.props.deleteItemsData(itemData.id)
                                                      //   this.updatePendingQuantity(this.props.orderNo)
                                                      // }
                                                    }
                                                    }
                                                    size="small"
                                                    style={{ marginBottom: "30px" }}
                                                  >
                                                    <DeleteIcon fontSize="small" />
                                                  </IconButton>
                                                )
                                                  :
                                                  null}
                                            </>
                                          }
                                        </div>

                                      </div>
                                    ))
                                    :
                                    null
                                  }
                                </div>

                                {this.props.overview ? "" :
                                  <>
                                    {
                                      (this.props?.laStatus === undefined ||
                                        this.props?.laStatus == "PENDING" ||
                                        this.props?.laStatus == "APPROVED" ||
                                        this.props?.laStatus == "GATEIN" ||
                                        this.props?.laStatus == "LOADING"
                                      )
                                        ?
                                        (Number(laDetails?.truck_capacity) >
                                          Number(totalItemQtySum)
                                          ? (
                                            // (Number(this.state.pendingQuantity.find(d => d.order_available_quantity)?.order_available_quantity) + Number(this.state.deletedItemQty)) >
                                            //   (Number(totalItemQtySum) - Number(this.state.initialItemQty))
                                            this.getPendingQuantity("edit") > 0 &&
                                              (((laOperation === "edit" && this.props.created_from_subcontract) ||
                                                (laOperation === "add" && this.state.subcontractEnabled)) ?
                                                this.props.allSubcontractItemList?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined)
                                                : this.props.orderDetails?.tag ? this.props.allItemsListWithTag?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined) || []
                                                  : allItemsList?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined))?.length > 0
                                              ? (
                                                <AddMoreItemButton
                                                  buttonOnClick={this.addNewItemButtonClick} ////// Please fill The truck quantity - first and then add more itmes will pop up
                                                  buttonTitle={"+ ADD MORE ITEMS"}
                                                  module={'contract'}

                                                />
                                              )
                                              :
                                              null
                                          )
                                          :
                                          // (Number(this.state.pendingQuantity.find(d => d.order_available_quantity)?.order_available_quantity) + Number(this.state.deletedItemQty)) >
                                          //   (Number(totalItemQtySum) - Number(this.state.initialItemQty))
                                          this.getPendingQuantity("edit") > 0 &&
                                            (((laOperation === "edit" && this.props.created_from_subcontract) ||
                                              (laOperation === "add" && this.state.subcontractEnabled)) ?
                                              this.props.allSubcontractItemList?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined)
                                              : this.props.orderDetails?.tag ? this.props.allItemsListWithTag?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined) || []
                                                : allItemsList?.filter(a => itemDetailsData?.find(l => l.item == a?.id) == undefined))?.length > 0
                                            ? (
                                              <AddMoreItemButton
                                                buttonOnClick={this.addNewItemButtonClick}
                                                buttonTitle={"+ ADD MORE ITEMS"}
                                                module={'contract'}
                                              />
                                            )
                                            :
                                            null)
                                        :
                                        null
                                    }
                                  </>
                                }
                                <FormHelperText error>
                                  {this.state.showAddItemsError && (
                                    <span>{this.state.addItemsErrorMsg}</span>
                                  )}
                                </FormHelperText>

                                {this.props.overview ? "" :
                                  <div className={classes.displayFlex}>
                                    <Button
                                      size="medium"
                                      className={classes.saveButton}
                                      variant="contained"
                                      startIcon={<CheckOutlinedIcon />}
                                      onClick={this.handleItems}
                                      type="submit"
                                      disabled={
                                        this.state.item_qty_error.find(i => i == true) ||
                                        itemDetailsData.find(i => i.itemError == true) ||
                                        itemDetailsData.find(i => i.qtyError == true)
                                      }
                                    >
                                      CONTINUE
                                    </Button>
                                    <Button
                                      size="medium"
                                      className={classes.backButton}
                                      onClick={this.handleBack}
                                    >
                                      BACK
                                    </Button>
                                  </div>
                                }
                              </StepContent>
                            </Step>

                            <Step
                              key="TaxationDetails"
                              classes={{
                                root: classes.step,
                                completed: classes.completed,
                                active: classes.active,
                              }}
                              expanded={this.props.overview}
                            >
                              <StyledStepLabel
                                StepIconProps={{
                                  classes: { root: classes.stepIconRoot },
                                }}
                              >
                                TAXATION DETAILS
                              </StyledStepLabel>
                              <StepContent>
                                <Formik
                                  initialValues={{
                                    cess_rate: laDetails?.cess_rate === null ? "" : laDetails?.cess_rate,
                                    fright: laDetails.fright || "",
                                    lr_number: laDetails.lr_number || "",
                                    fright_advance: laDetails?.fright_advance === null ? "" : laDetails?.fright_advance,
                                    mode_of_transport: laDetails?.mode_of_transport || "",
                                    tcs_display: laDetails?.tcs_display || "",
                                    tcs_rate: laDetails?.tcs_rate === null ? "" : laDetails?.tcs_rate,
                                  }}
                                  enableReinitialize={true}
                                  validationSchema={validationsTaxationDetails}
                                  validateOnChange={false}
                                  validateOnBlur={false}
                                  onSubmit={(v) => {

                                    this.handleCalculateTaxableAmount();
                                    this.handleNext();
                                  }}
                                >
                                  {(formik) => (
                                    <Form>
                                      <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>
                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>

                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="cess_rate"
                                              type="number"
                                              className={classes.formControl}
                                              label="CESS RATE"
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              value={laDetails?.cess_rate === null ? "" : laDetails?.cess_rate}
                                              //onChange={this.handleChange("cess_rate")}
                                              onKeyDown={(evt) => (
                                                ["e", "E", "+", "-"].includes(evt.key) ||
                                                (evt.which === 38 || evt.which === 40)
                                              ) && evt.preventDefault()}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    cess_rate: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "cess_rate",
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={this.handleCalculateCessAmount}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.cess_rate &&
                                                  formik.touched.cess_rate)
                                                  ? String(formik.errors.cess_rate)
                                                  : editData.cess_rate
                                              }
                                              error={Boolean(
                                                formik.errors.cess_rate &&
                                                formik.touched.cess_rate
                                              )}
                                              InputProps={{
                                                inputProps: { min: 0 },
                                                endAdornment: (
                                                  <InputAdornment position="start">
                                                    ₹/MT
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />

                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="freight_charge_rate"
                                              type="number"
                                              className={classes.formControl}
                                              label="FREIGHT CHARGE RATE"
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              onKeyDown={(evt) => (
                                                ["e", "E", "+", "-"].includes(evt.key) ||
                                                (evt.which === 38 || evt.which === 40)
                                              ) && evt.preventDefault()}
                                              value={laDetails.freight_charge_rate !== null || laDetails.freight_charge_rate !== undefined
                                                ? laDetails.freight_charge_rate
                                                : ""}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={this.handleChange(
                                                "freight_charge_rate"
                                              )}
                                              onBlur={() => {
                                                this.handleCalculateFreightChargeAmount();
                                                this.handleCalculateTaxableAmount();
                                              }}
                                              variant="outlined"
                                              size="small"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="start">
                                                    ₹/MT
                                                  </InputAdornment>
                                                ),
                                              }}
                                              helperText={editData.freight_charge_rate}
                                            />
                                          </div>

                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>

                                            <FormControl
                                              variant="outlined"
                                              className={classes.formControl}
                                              size="small"
                                              style={{ margin: isMobile && 0 }}
                                            >
                                              {formik.errors.fright ? (
                                                <InputLabel
                                                  id="frightSelectLable"
                                                  error
                                                >
                                                  FREIGHT BASIS*
                                                </InputLabel>
                                              ) : (
                                                <InputLabel id="frightSelectLable">
                                                  FREIGHT BASIS*
                                                </InputLabel>
                                              )}
                                              <Select
                                                name="fright"
                                                id="frightSelectLable"
                                                value={laDetails.fright || ""}
                                                style={{ pointerEvents: this.props.overview && "none" }}
                                                onChange={(event) => {
                                                  this.setState((previousState) => ({
                                                    laDetails: {
                                                      ...previousState.laDetails,
                                                      fright: event.target.value,
                                                    },
                                                  }));
                                                }}
                                                MenuProps={MenuProps}
                                                label="SELECT FREIGHT *"
                                                error={Boolean(
                                                  formik.errors.fright &&
                                                  formik.touched.fright
                                                )}
                                              >
                                                {
                                                  this.props.freightBasis.map((item) => {
                                                    return (
                                                      <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                      >
                                                        {item.name}
                                                      </MenuItem>
                                                    )
                                                  })
                                                }
                                              </Select>
                                              {formik.errors.fright &&
                                                formik.touched.fright
                                                ?
                                                <FormHelperText error>
                                                  {
                                                    formik.errors.fright &&
                                                    formik.touched.fright &&
                                                    String(formik.errors.fright)
                                                  }
                                                </FormHelperText>
                                                :
                                                <FormHelperText>
                                                  {editData.fright_basis}
                                                </FormHelperText>
                                              }
                                            </FormControl>
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="lr_number"
                                              type="text"
                                              className={classes.formControl}
                                              label="LR NUMBER"
                                              value={laDetails.lr_number || ""}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              //onChange={this.handleChange("lr_number")}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    lr_number: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "lr_number",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={editData.lr_number}
                                            // helperText={
                                            //   formik.errors.lr_number &&
                                            //   formik.touched.lr_number &&
                                            //   String(formik.errors.lr_number)
                                            // }
                                            // error={Boolean(
                                            //   formik.errors.lr_number &&
                                            //     formik.touched.lr_number
                                            // )}
                                            />
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <FormControl
                                              variant="outlined"
                                              className={classes.formControl}
                                              size="small"
                                              style={{ margin: isMobile && 0 }}
                                            >
                                              {formik.errors.mode_of_transport ? (
                                                <InputLabel
                                                  id="transportModeSelectLable"
                                                  error
                                                >
                                                  MODE OF TRANSPORT *
                                                </InputLabel>
                                              ) : (
                                                <InputLabel id="transportModeSelectLable">
                                                  MODE OF TRANSPORT *
                                                </InputLabel>
                                              )}
                                              <Select
                                                name="mode_of_transport"
                                                id="transportModeSelectLable"
                                                style={{ pointerEvents: this.props.overview && "none" }}
                                                value={
                                                  laDetails.mode_of_transport === 1 ||
                                                    laDetails.mode_of_transport === "ROAD"
                                                    ? "ROAD"
                                                    : laDetails.mode_of_transport === 2 ||
                                                      laDetails.mode_of_transport === "RAIL"
                                                      ? "RAIL"
                                                      : laDetails.mode_of_transport === 3 ||
                                                        laDetails.mode_of_transport === "AIR"
                                                        ? "AIR"
                                                        : laDetails.mode_of_transport === 4 ||
                                                          laDetails.mode_of_transport === "SHIP"
                                                          ? "SHIP"
                                                          : ""
                                                }
                                                //     onChange={this.handleChange(
                                                //       "mode_of_transport"
                                                //     )}
                                                onChange={(event) => {
                                                  this.setState((previousState) => ({
                                                    laDetails: {
                                                      ...previousState.laDetails,
                                                      mode_of_transport: event.target.value,
                                                    },
                                                  }));
                                                  formik.setFieldValue(
                                                    "mode_of_transport",
                                                    event.target.value
                                                  );
                                                }}
                                                MenuProps={MenuProps}
                                                label="SELECT MODE OF TRANSPORT *"
                                                error={Boolean(
                                                  formik.errors.mode_of_transport &&
                                                  formik.touched.mode_of_transport
                                                )}
                                              >
                                                <MenuItem value={"ROAD"}>Road</MenuItem>
                                                <MenuItem value={"RAIL"}>Rail</MenuItem>
                                                <MenuItem value={"AIR"}>Air</MenuItem>
                                                <MenuItem value={"SHIP"}>Ship</MenuItem>
                                              </Select>
                                              {formik.errors.mode_of_transport &&
                                                formik.touched.mode_of_transport
                                                ?
                                                <FormHelperText error>
                                                  {
                                                    formik.errors.mode_of_transport &&
                                                    formik.touched.mode_of_transport &&
                                                    String(formik.errors.mode_of_transport)

                                                  }
                                                </FormHelperText>
                                                :
                                                <FormHelperText>
                                                  {editData.mode_of_transport}
                                                </FormHelperText>
                                              }
                                            </FormControl>
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              label="CUSTOMER REMARK"
                                              className={classes.formControl}
                                              multiline
                                              minRows="3"
                                              disabled={true}
                                              margin="normal"
                                              variant="outlined"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              value={laDetails?.customer_remark || ""}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              helperText={editData.customer_remark}
                                            />
                                          </div>
                                        </div>
                                        <div className={classes.additionalDataDisplay} style={{ width: isMobile && '100%' }}>
                                          <Typography
                                            className={classes.titleLabelStyle}
                                          ></Typography>

                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              disabled={this.props.overview ? false : true}
                                              name="cess_amount"
                                              type="number"
                                              className={classes.formControl}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              label="CESS AMOUNT *"
                                              value={laDetails.cess_amount || ""}
                                              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={this.handleChange("cess_amount")}
                                              variant="outlined"
                                              size="small"
                                              helperText={editData.cess_amount}
                                            />
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              disabled={this.props.overview ? false : true}
                                              name="freight_charge_amount"
                                              type="number"
                                              className={classes.formControl}
                                              label="FREIGHT CHARGE AMOUNT *"
                                              value={laDetails.freight_charge_amount || ""}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={this.handleChange(
                                                "freight_charge_amount"
                                              )}
                                              variant="outlined"
                                              size="small"
                                              helperText={editData.freight_charge_amount}
                                            />
                                          </div>



                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="fright_advance"
                                              type="number"
                                              className={classes.formControl}
                                              label="FREIGHT ADVANCE"
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              value={laDetails?.fright_advance === null ? "" : laDetails?.fright_advance}
                                              // onChange={this.handleChange("fright_advance")}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) => {
                                                this.setState((previousState) => ({
                                                  laDetails: {
                                                    ...previousState.laDetails,
                                                    fright_advance: e.target.value,
                                                  },
                                                }));
                                                formik.setFieldValue(
                                                  "fright_advance",
                                                  e.target.value
                                                );
                                              }}
                                              variant="outlined"
                                              size="small"
                                              helperText={
                                                (formik.errors.fright_advance &&
                                                  formik.touched.fright_advance)
                                                  ? String(formik.errors.fright_advance)
                                                  : editData.fright_advance
                                              }
                                              error={Boolean(
                                                formik.errors.fright_advance &&
                                                formik.touched.fright_advance
                                              )}
                                              InputProps={{
                                                inputProps: { min: 0 },
                                              }}
                                              onKeyDown={(evt) => (
                                                ["e", "E", "+", "-"].includes(evt.key) ||
                                                (evt.which === 38 || evt.which === 40)
                                              ) && evt.preventDefault()}
                                            />
                                          </div>
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <Fragment>
                                              <ThemeProvider theme={materialTheme}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                  <KeyboardDatePicker
                                                    className={classes.formControl}
                                                    size="small"
                                                    inputVariant="outlined"
                                                    label="LR DATE *"
                                                    format="dd/MM/yyyy"
                                                    style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                                    onChange={this.handleDateChange(
                                                      "lr_date"
                                                    )}
                                                    maxDate={new Date()}
                                                    value={new Date(laDetails.lr_date)}
                                                    placeholder="e.g.: DD/MM/YYYY"
                                                    InputAdornmentProps={{
                                                      position: "start",
                                                    }}
                                                    InputProps={{
                                                      className: classes.datePicker,
                                                      readOnly: true
                                                    }}
                                                    helperText={editData.lr_date}
                                                    FormHelperTextProps={{ className: classes.helperText }}
                                                  />
                                                </MuiPickersUtilsProvider>
                                              </ThemeProvider>
                                            </Fragment>
                                          </div>{" "}
                                          <div className={classes.dataFields} style={{ marginBottom: isMobile && 15 }}>
                                            <TextField
                                              name="remark"
                                              label="LA REMARK"
                                              className={classes.formControl}
                                              onChange={this.handleChange("remark")}
                                              multiline
                                              rows="3"
                                              margin="normal"
                                              variant="outlined"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              value={laDetails?.remark || ""}
                                              style={{ pointerEvents: this.props.overview && "none", margin: isMobile && 0 }}
                                              helperText={editData.remark}
                                            />
                                          </div>
                                        </div>
                                        {this.props.overview ? "" :
                                          <div className={classes.displayFlex}>
                                            <Button
                                              size="medium"
                                              className={classes.saveButton}
                                              variant="contained"
                                              startIcon={<CheckOutlinedIcon />}
                                              type="submit"
                                            >
                                              CONTINUE
                                            </Button>
                                            <Button
                                              size="medium"
                                              className={classes.backButton}
                                              onClick={this.handleBack}
                                            >
                                              BACK
                                            </Button>
                                          </div>
                                        }
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </StepContent>
                            </Step>

                            <Step
                              key="DiscountDetails"
                              classes={{
                                root: classes.step,
                                completed: classes.completed,
                                active: classes.active,
                              }}
                              expanded={this.props.overview}
                            >
                              <StyledStepLabel
                                StepIconProps={{
                                  classes: { root: classes.stepIconRoot },
                                }}
                              >
                                DISCOUNT DETAILS
                              </StyledStepLabel>
                              <StepContent>
                                <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>

                                  <div className={classes.dataDisplay} style={{ width: isMobile && '100%', marginBottom: isMobile && 15 }}>
                                    <Grow
                                      in={true}
                                      style={{ transformOrigin: "0 0 0" }}
                                      {...{ timeout: 3000 }}
                                    >
                                      <div className={classes.discountContainerStyleLeft} style={{ width: isMobile && '100%' }}>
                                        <div className={classes.discountHeaderDiv}>
                                          <span className={classes.discountTextStyle}>
                                            DISCOUNT
                                          </span>
                                        </div>


                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            CUSTOMER
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : parseFloat(
                                              discountData?.customer_discount || 0
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            CITY
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders?.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : parseFloat(
                                              discountData?.city_discount || 0
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            PAYMENT BASIS
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : parseFloat(
                                              discountData?.payment_discount || 0
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            SPECIAL DISCOUNT
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : Number(discountData?.special_discount)?.toFixed(2) || 0}
                                          </span>
                                          {/* <TextField
                                            name="special_discount"
                                            disabled="true"
                                            className={classes.textFieldStyles}
                                            type="number"
                                            onChange={this.handleChange("special_discount")}
                                            onBlur={this.handleTotalDiscountAmountSet}
                                            value={allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : discountData?.special_discount || 0}
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="end">
                                                  <span
                                                    style={{
                                                      fontSize: "0.9em",
                                                      color: grey,
                                                    }}
                                                  >
                                                    ₹
                                                  </span>
                                                </InputAdornment>
                                              ),
                                            }}
                                          /> */}
                                        </div>
                                        <Divider variant="fullWidth" />
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            TOTAL DISCOUNT
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : parseFloat(
                                              additionalData?.totalDiscountAmount || 0
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                      </div>
                                    </Grow>


                                    <Grow
                                      in={true}
                                      style={{ transformOrigin: "0 0 0", marginTop: "20px" }}
                                      {...{ timeout: 3000 }}
                                    >
                                      <div className={classes.discountContainerStyleLeft} style={{ width: isMobile && '100%' }}>
                                        <div className={classes.discountHeaderDiv}>
                                          <span className={classes.discountTextStyle}>
                                            TOTAL DISCOUNTED RATE
                                          </span>
                                        </div>


                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            CONTRACT RATE
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : parseFloat(
                                              this?.state?.orderDetails?.rate || 0
                                            ).toFixed(2)}
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            PAYMENT BASIS PREMIUM
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                              ? 0
                                              : laOperation === "edit" ? Number(this.state.discountData?.payment_premium) || 0
                                                : Number(this.state.discountData?.payment_premium) || 0
                                            }
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            LOADING PREMIUM
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                              ? 0
                                              : Number(this.state.discountData?.loading_premium)?.toFixed(2) || 0
                                            }
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            INSURANCE PREMIUM
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                              ? 0
                                              : Number(this.state.discountData?.insurance_premium)?.toFixed(2) || 0
                                            }
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            SPECIAL PREMIUM
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {console.log(this.state.discountData, this.state.discountData?.special_premium)}
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                              ? 0
                                              : Number(this.props?.orderDetails?.discounts?.special_premium)?.toFixed(2) || 0
                                            }
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            FREIGHT RATE
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                              ? 0
                                              :
                                              Number(this?.state?.laDetails?.freight_charge_rate)?.toFixed(2)
                                            }
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            ALL DISCOUNTS
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : parseFloat(
                                              additionalData?.totalDiscountAmount || 0
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                        <Divider variant="fullWidth" />

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            DISCOUNTED RATE
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 : parseFloat(
                                              this?.state?.laDetails?.discounted_rate || 0
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                      </div>
                                    </Grow>

                                    {this.props.overview && laDetails?.vr_number ?
                                      <Grow
                                        in={true}
                                        style={{ transformOrigin: "0 0 0", marginTop: "20px" }}
                                        {...{ timeout: 3000 }}
                                      >
                                        <div className={classes.discountContainerStyleLeft} style={{ width: isMobile && '100%' }}>
                                          <div className={classes.discountHeaderDiv}>
                                            <span className={classes.discountTextStyle}>
                                              SALES RETURN
                                            </span>
                                          </div>


                                          <div className={classes.discountListStyleDiv}>
                                            <span className={classes.discountListStyleText}>
                                              TRANSACTION #
                                            </span>
                                            <span className={classes.discountListStyleValue}>
                                              {laDetails?.vr_number ? laDetails?.vr_number : "-"}
                                            </span>
                                          </div>

                                          <div className={classes.discountListStyleDiv}>
                                            <span className={classes.discountListStyleText}>
                                              DATE
                                            </span>
                                            <span className={classes.discountListStyleValue}>
                                              {laDetails?.sales_return_date ? moment(laDetails?.sales_return_date).format('DD-MMM-YYYY hh:mm A') : "-"}
                                            </span>
                                          </div>
                                          {this?.state?.itemRateDifferenceData?.filter(item => parseFloat(item.return_qty) !== 0).map((item, index) => (
                                            <div className={classes.discountListStyleDiv}>
                                              <div style={{ width: "50%" }}>
                                                <div className={classes.discountListStyleText}>
                                                  {item?.item_name}
                                                </div>
                                              </div>

                                              <span className={classes.discountListStyleValue}>
                                                {item?.return_qty ?
                                                  Number(item?.return_qty).toFixed(2) + " MTS" : "-"
                                                }
                                              </span>

                                            </div>))
                                          }

                                          <div className={classes.discountListStyleDiv}>
                                            <span className={classes.discountListStyleText}>
                                              TOTAL QUANTITY
                                            </span>
                                            <span className={classes.discountListStyleValue}>
                                              {this.state.laDetails?.items.reduce((acc, Obj) => {
                                                return (Number(acc) + Number(Obj?.return_qty));
                                              }, 0)} MTS
                                            </span>
                                          </div>

                                          <div className={classes.discountListStyleDiv}>
                                            <span className={classes.discountListStyleText}>
                                              TOTAL AMOUNT
                                            </span>
                                            <span className={classes.discountListStyleValue}>

                                              {laDetails?.sales_return_amount ? `₹ ${laDetails?.sales_return_amount}` : "-"}

                                            </span>
                                          </div>





                                        </div>
                                      </Grow>
                                      : <></>}
                                  </div>

                                  <div className={classes.additionalDataDisplay} style={{ width: isMobile && '100%' }}>
                                    <Grow
                                      in={true}
                                      style={{ transformOrigin: "0 0 0" }}
                                      {...{ timeout: 5000 }}
                                    >
                                      <div className={classes.discountContainerStyleLeft} style={{ width: isMobile && '100%' }}>
                                        <div className={classes.discountHeaderDiv}>
                                          <span className={classes.discountTextStyle}>
                                            APPROXIMATE AMOUNT
                                          </span>
                                        </div>


                                        <Divider variant="fullWidth" />
                                        {this?.state?.itemRateDifferenceData.map((item, index) => (
                                          <div className={classes.discountListStyleDiv}>
                                            <div style={{ width: "50%" }}>
                                              <div className={classes.discountListStyleText}>
                                                {item?.item_name}
                                              </div>
                                              <div style={{ width: "80%" }}>
                                                <p style={{ fontSize: "12px", fontStyle: "italic", margin: 0, padding: 0 }}>
                                                  {allOrders &&
                                                    parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? `(0 + 0) * ${this.state.itemDetailsData[index]?.item_qty} MTS` : `(${Number(parseFloat(
                                                      this?.state?.laDetails?.discounted_rate).toFixed(2))} + ${Number(item?.rate_difference)}) * ${this.state.itemDetailsData[index]?.item_qty} MTS`}</p>
                                              </div>
                                            </div>

                                            <span className={classes.discountListStyleValue}>
                                              ₹{" "}

                                              {allOrders &&
                                                parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 :
                                                ((this?.state?.laDetails?.discounted_rate +
                                                  Number(item?.rate_difference)) * this.state.itemDetailsData[index]?.item_qty).toFixed(2)
                                              }


                                            </span>

                                          </div>))
                                        }
                                        <Divider variant="fullWidth" />

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            TAXABLE AMOUNT (APPROX)
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders &&
                                              parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0 ? 0 :

                                              Number(this.state.laDetails?.taxable_amount).toFixed(2)}
                                          </span>
                                        </div>

                                        {additionalData?.gstOptionValue === "IGST" ? (
                                          <div className={classes.discountListStyleDiv}>
                                            <span className={classes.discountListStyleText}>
                                              {`IGST ${additionalData?.igstRate || 0} %`}
                                            </span>
                                            <span className={classes.discountListStyleValue}>
                                              ₹{" "}
                                              {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                                ? 0
                                                : parseFloat(
                                                  additionalData?.igstAmount || 0
                                                ).toFixed(2)}
                                            </span>
                                          </div>
                                        ) : (
                                          <>
                                            <div className={classes.discountListStyleDiv}>
                                              <span className={classes.discountListStyleText}>
                                                {`SGST ${additionalData?.sgstRate || 0} %`}
                                              </span>
                                              <span
                                                className={classes.discountListStyleValue}
                                              >
                                                ₹{" "}
                                                {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                                  ? 0
                                                  : parseFloat(
                                                    additionalData?.sgstAmount || 0
                                                  ).toFixed(2)}
                                              </span>
                                            </div>
                                            <div className={classes.discountListStyleDiv}>
                                              <span className={classes.discountListStyleText}>
                                                {`CGST ${additionalData?.cgstRate || 0} %`}
                                              </span>
                                              <span
                                                className={classes.discountListStyleValue}
                                              >
                                                ₹{" "}
                                                {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                                  ? 0
                                                  : parseFloat(
                                                    additionalData?.cgstAmount || 0
                                                  ).toFixed(2)}
                                              </span>
                                            </div>
                                          </>
                                        )}
                                        {/*
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            GST{" "}
                                            {parseFloat(
                                              Number(orderDetails?.item_category?.igst).toFixed(0) ||
                                              0
                                            )}{" "}
                                            %
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                              ? 0
                                              : parseFloat(
                                                additionalData?.igstAmount || 0
                                              ).toFixed(2)}
                                          </span>
                                        </div> */}

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            TCS {" "}
                                            {laDetails?.tcs_rate === null ? "" : laDetails?.tcs_rate}
                                            {" "}
                                            %
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {" "}
                                            {laDetails.tcs_display || 0}
                                          </span>
                                        </div>


                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            FREIGHT ADVANCE
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {" "}
                                            {laDetails?.fright_advance === null ? "" : laDetails?.fright_advance}
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            CESS AMOUNT
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {" "}
                                            {laDetails.cess_amount || ""}
                                          </span>
                                        </div>

                                        <Divider variant="fullWidth" />

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            TOTAL AMOUNT (APPROX)
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            ₹{" "}
                                            {allOrders && parseInt(allOrders.find(a => a.id === laDetails.order)?.rate) === 0
                                              ? 0
                                              : parseFloat(
                                                this?.state?.laDetails?.total_amount || 0
                                              ).toFixed(2)}
                                          </span>
                                        </div>

                                      </div>
                                    </Grow>

                                    <Grow
                                      in={true}
                                      style={{ transformOrigin: "0 0 0", marginTop: "20px" }}
                                      {...{ timeout: 5000 }}
                                    >
                                      <div className={classes.discountContainerStyleLeft} style={{ width: isMobile && '100%' }}>
                                        <div className={classes.discountHeaderDiv}>
                                          <span className={classes.discountTextStyle}>
                                            PAYMENT STATUS
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            INVOICE NUMBER
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.erp_invoice_number || "-"}
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            INVOICE DATE
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.doc_date
                                              ? moment(this.state.laDetails?.doc_date).format('DD-MMM-YY')
                                              : "-"
                                            }
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            INVOICE AMOUNT
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.payment_detail?.invoice_amount
                                              ? `₹` + this.state.laDetails?.payment_detail?.invoice_amount
                                              : "-"}
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            PAYMENT TERMS
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.payment_method_name || "-"}
                                          </span>
                                        </div>

                                        <Divider variant="fullWidth" />

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            PAYMENT STATUS
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.payment_status || "-"}
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            DUE DATE
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.la_due_date
                                              ? moment(this.state.laDetails?.la_due_date).format('DD-MMM-YY')
                                              : "-"
                                            }
                                          </span>
                                        </div>
                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            PAID
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.payment_detail?.paid
                                              ? `₹` + this.state.laDetails?.payment_detail?.paid
                                              : "-"}
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            BALANCE {this.state.laDetails?.erp_invoice_number
                                              && ' (APPROX)'
                                            }
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.payment_detail?.balance
                                              ? `₹` + this.state.laDetails?.payment_detail?.balance
                                              : "-"}
                                          </span>
                                        </div>

                                        <div className={classes.discountListStyleDiv}>
                                          <span className={classes.discountListStyleText}>
                                            SYNCED AT
                                          </span>
                                          <span className={classes.discountListStyleValue}>
                                            {this.state.laDetails?.payment_detail?.synced_at
                                              ? moment(this.state.laDetails?.payment_detail?.synced_at).format('DD-MMM-YY hh:mm A')
                                              : "-"
                                            }
                                          </span>
                                        </div>



                                      </div>
                                    </Grow>
                                  </div>
                                </div>

                                <div className={classes.displayFlex}>
                                  {this.props.overview ? "" :
                                    <>
                                      <Button
                                        size="medium"
                                        className={classes.saveButton}
                                        variant="contained"
                                        startIcon={<CheckOutlinedIcon />}
                                        onClick={e => {
                                          e.persist()
                                          console.log("clicked FINISH 1", this.state.finishBtnClicked)
                                          if (!this.state.finishBtnClicked) {
                                            console.log("clicked FINISH 2")
                                            this.setState({ finishBtnClicked: true })
                                            this.handleSaveLaDetails(e, this.props.location.state?.isCreatedByCustomer)
                                          }
                                        }}
                                        type="submit"
                                      // disabled={!(formik.isValid && formik.dirty)}
                                      // disabled={formik.errors.length != 0} ///////Fill all the details including truck capacity to show up the Save Button
                                      >
                                        {(this.props.location.state?.isCreatedByCustomer && this.props.laStatus === "PENDING") ? "APPROVE" : "FINISH"}
                                        {this.props.isLoading &&
                                          (
                                            <CircularProgress
                                              size={15}
                                              style={{ marginLeft: "10px", color: white }}
                                            />
                                          )}
                                      </Button>
                                      <Button
                                        size="medium"
                                        className={classes.backButton}
                                        onClick={this.handleBack}
                                      >
                                        BACK
                                      </Button>
                                      <Button
                                        size="medium"
                                        className={classes.cancelButton}
                                        onClick={this.handleReset}
                                      >
                                        RESET
                                      </Button>
                                    </>
                                  }
                                  <SnackbarComponent
                                    open={openSnackbar}
                                    handleClose={this.handleSnackbarClose}
                                    snackbarMessage={snackbarMessage}
                                    type={snackbarType}
                                  />
                                </div>
                              </StepContent>
                            </Step>
                          </Stepper>
                        </div>
                      </Paper>
                    </div>
                  )
            }
          </div>
        </div >
      </Layout >
    );
  }
}

export default withRouter(withStyles(styles)(CreateLoadingAdvice));
