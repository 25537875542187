import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  grey,
  successButtonBackground,
  drawerTextColor,
  secondaryBlueText,
  whiteSmoke,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";

import {
  pxToEm,
  checkEmailAddress,
  checkMobileNumber,
  isPanNumberValid,
  validateGstinNumber,
  checkAlphaNumericLength,
  checkNameFieldAlphaNumeric,
  checkUpiId,
  checkIfscCode,
  checkAlphabet,
  checkNumeric,
  checkApiError,
  UpperCaseText,
  // checkUpiId,
} from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import PresentToAllOutlinedIcon from "@material-ui/icons/PresentToAllOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import SaveButton from "../../common/SaveButton";

const useStyles = makeStyles({
  itemCategoryData: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    placeItems: "baseline",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addMoreButton: {
    display: "flex",
    justifyContent: "center",
    width: "30%",
  },
  itemCategoryActionIcon: { marginLeft: 5 },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  topMargin: { marginTop: 30, width: "80%" },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  formControlPanImage: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logoImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
    cursor: "pointer",
  },
  logoImagePreview: {
    height: "100",
    width: "100",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 240
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  titleLabelStylePanImage: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
    cursor: "pointer",
    textDecoration: "underline",
  },
  tableContainer: {
    marginTop: 10,
    width: "100%",
    overflow: "auto",
  },
  table: {
    border: "0px",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.defaultChecked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function ManagePlantItemMaster(props) {
  const classes = useStyles();
  const [plantItemDataDisplay, setPlantItemMasterData] = React.useState({
    plant_name: props?.selectedPlantData?.plant_name || "",
    plant_short_name: props?.selectedPlantData?.plant_short_name || "",
    plant_code: props?.selectedPlantData?.plant_code || "",
    godown_code: props?.selectedPlantData?.godown_code || "",
    plant_address: props?.selectedPlantData?.plant_address || "",
    plant_gst_number: props?.selectedPlantData?.plant_gst_number || "",
    plant_pan_number: props?.selectedPlantData?.plant_pan_number || "",
    plant_mobile: props?.selectedPlantData?.plant_mobile || "",
    plant_email: props?.selectedPlantData?.plant_email || "",
    plant_logo: props?.selectedPlantData?.plant_logo || "",
    plants_address_sr_no: props?.selectedPlantData?.plants_address_sr_no || "",
    plant_upi_id: props?.selectedPlantData?.plant_upi_id || "",
    plant_account_no: props?.selectedPlantData?.plant_account_no || "",
    plant_account_ifsc: props?.selectedPlantData?.plant_account_ifsc || "",
    plant_state: props?.selectedPlantData?.plant_state || "",
    plant_pincode: props?.selectedPlantData?.plant_pincode || "",
    plant_city: props?.selectedPlantData?.plant_city || "",
    is_active: props?.selectedPlantData?.plant_state || "",
    branch_name: props?.selectedPlantData?.branch_name || "",
    bank_name: props?.selectedPlantData?.bank_name || "",
    division: props?.selectedPlantData?.division || "",
    range: props?.selectedPlantData?.range || "",
    commissionerate: props?.selectedPlantData?.commissionerate || "",
    cin: props?.selectedPlantData?.cin || "",
  });
  const [plantItemCategories, setPlantItemCategories] = React.useState(
    props?.selectedPlantData?.item_categories || []
  );
  const [plantItemCategoryData, setItemCategoryData] = React.useState([
    {
      plant_item_category_code: "",
      item_category: "",
      voucher_code: ""
    },
  ]);
  const [logoUploadData, setLogoUploadData] = React.useState({
    logoRawUploadedFile: "",
    logoImageUrl: null,
    logoFileName: null,
  });
  const [logoBannerUploadData, setLogoBannerUploadData] = React.useState({
    logoBannerRawUploadedFile: "",
    logoBannerImageUrl: null,
    logoBannerFileName: null,
  });
  const [isLoading, setIsLoading] = React.useState(false)
  const allStateList = props?.allStateList || [];
  const allItemCategories = props?.itemCategoriesList || [];
  const [filteredAllItemCategories, setFilteredAllItemCategories] =
    React.useState(
      allItemCategories && allItemCategories.length > 0
        ? plantItemCategories && plantItemCategories.length > 0
          ? allItemCategories.filter(
            (x) =>
              !plantItemCategories.filter((y) => {
                return y.id === x.id;
              }).length
          )
          : allItemCategories
        : []
    );
  const [plantMasterOperation, setPlantItemMasterOperation] = React.useState(
    props?.plantMasterOperation || "add"
  );
  const [plantError, setPlantError] = React.useState({
    plant_name: false,
    plant_short_name: false,
    plant_code: false,
    godown_code: false,
    plant_gst_number: false,
    plant_pan_number: false,
    plant_mobile: false,
    plant_email: false,
    plant_upi_id: false,
    plant_account_no: false,
    plant_account_ifsc: false,
    plants_address_sr_no: false,
    plant_address: false,
    plant_pincode: false,
    plant_city: false,
    plant_state: false,
  });
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  React.useEffect(() => {
    let logoImageData = props.selectedPlantData.plant_logo
      ? props.selectedPlantData.plant_logo.split("/")
      : [];
    let logoFileName =
      logoImageData.length > 0
        ? logoImageData[Number(logoImageData.length) - 1]
        : null;
    let updatedLogoData = {
      logoRawUploadedFile: "",
      logoImageUrl: props.selectedPlantData.plant_logo,
      logoFileName: logoFileName,
    };
    setPlantItemCategories(props.selectedPlantData.item_categories);
    setPlantItemMasterData(props.selectedPlantData);
    setPlantItemMasterOperation(props.plantMasterOperation);
    setLogoUploadData(updatedLogoData);

    let logoBannerImageData = props.selectedPlantData.logo_banner
      ? props.selectedPlantData.logo_banner.split("/")
      : [];
    let logoBannerFileName =
      logoBannerImageData.length > 0
        ? logoBannerImageData[Number(logoBannerImageData.length) - 1]
        : null;
    let updatedLogoBannerData = {
      logoBannerRawUploadedFile: "",
      logoBannerImageUrl: props.selectedPlantData.logo_banner,
      logoBannerFileName: logoBannerFileName,
    };
    setLogoBannerUploadData(updatedLogoBannerData);

    handleFilteredAllItemCategories();
  }, [
    props.selectedPlantData,
    props?.selectedPlantData?.item_categories,
    props.plantMasterOperation,
  ]);

  React.useEffect(() => {
    if (props.plantMasterOperation !== 'add') {
      if (props.selectedPlantData.plant_name) {
        !checkNameFieldAlphaNumeric(props.selectedPlantData.plant_name) ?
          setPlantError(prevValue => { return { ...prevValue, plant_name: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_name: false } });
      } if (props.selectedPlantData.plant_short_name) {
        !checkAlphaNumericLength(props.selectedPlantData.plant_short_name) ?
          setPlantError(prevValue => { return { ...prevValue, plant_short_name: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_short_name: false } });
      } if (props.selectedPlantData.plant_code) {
        !checkAlphaNumericLength(props.selectedPlantData.plant_code) ?
          setPlantError(prevValue => { return { ...prevValue, plant_code: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_code: false } });
      }
      // if (props.selectedPlantData.godown_code) {
      //   !checkAlphaNumericLength(props.selectedPlantData.godown_code) ?
      //     setPlantError(prevValue => { return { ...prevValue, godown_code: true } }) :
      //     setPlantError(prevValue => { return { ...prevValue, godown_code: false } });
      // } 
      if (props.selectedPlantData.plant_gst_number) {
        !validateGstinNumber(props.selectedPlantData.plant_gst_number) ?
          setPlantError(prevValue => { return { ...prevValue, plant_gst_number: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_gst_number: false } });
      } if (props.selectedPlantData.plant_pan_number) {
        !isPanNumberValid(props.selectedPlantData.plant_pan_number) ?
          setPlantError(prevValue => { return { ...prevValue, plant_pan_number: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_pan_number: false } });
      } if (props.selectedPlantData.plant_mobile) {
        (checkMobileNumber(props.selectedPlantData.plant_mobile) === "error") ?
          setPlantError(prevValue => { return { ...prevValue, plant_mobile: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_mobile: false } });
      } if (props.selectedPlantData.plant_email) {
        (checkEmailAddress(props.selectedPlantData.plant_email) === "error") ?
          setPlantError(prevValue => { return { ...prevValue, plant_email: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_email: false } });
      } if (props.selectedPlantData.plant_upi_id) {
        !checkUpiId(props.selectedPlantData.plant_upi_id) ?
          setPlantError(prevValue => { return { ...prevValue, plant_upi_id: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_upi_id: false } });
      } if (props.selectedPlantData.plant_account_no) {
        !checkNumeric(props.selectedPlantData.plant_account_no) ?
          setPlantError(prevValue => { return { ...prevValue, plant_account_no: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_account_no: false } });
      } if (props.selectedPlantData.plant_account_ifsc) {
        !checkIfscCode(props.selectedPlantData.plant_account_ifsc) ?
          setPlantError(prevValue => { return { ...prevValue, plant_account_ifsc: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_account_ifsc: false } });
      } if (props.selectedPlantData.plants_address_sr_no) {
        !((props.selectedPlantData.plants_address_sr_no).length === 5) ?
          setPlantError(prevValue => { return { ...prevValue, plants_address_sr_no: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plants_address_sr_no: false } });
      } if (props.selectedPlantData.plant_address) {
        !(props.selectedPlantData.plant_address) ?
          setPlantError(prevValue => { return { ...prevValue, plant_address: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_address: false } });
      } if (props.selectedPlantData.plant_pincode) {
        !((props.selectedPlantData.plant_pincode).toString().length === 6) ?
          setPlantError(prevValue => { return { ...prevValue, plant_pincode: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_pincode: false } });
      } if (props.selectedPlantData.plant_city) {
        !checkAlphabet(props.selectedPlantData.plant_city) ?
          setPlantError(prevValue => { return { ...prevValue, plant_city: true } }) :
          setPlantError(prevValue => { return { ...prevValue, plant_city: false } });
      }
    }
  }, [props.selectedPlantData, props.plantMasterOperation]);

  const handleFilteredAllItemCategories = () => {
    let filteredAllItemCategories =
      allItemCategories && allItemCategories.length > 0
        ? plantItemCategories && plantItemCategories.length > 0
          ? allItemCategories.filter(
            (x) =>
              !plantItemCategories.filter((y) => {
                return y.id === x.id;
              }).length
          )
          : allItemCategories
        : [];
    setFilteredAllItemCategories(filteredAllItemCategories);
  };
  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleChange = (event) => {
    if (event.target.name === 'is_active') {
      setPlantItemMasterData({
        ...plantItemDataDisplay,
        is_active: event.target.checked,
      });
    }
    else {
      setPlantItemMasterData({
        ...plantItemDataDisplay,
        [event.target.name]: ['plant_gst_number', 'plant_pan_number', 'plant_account_ifsc', 'plant_short_name'].includes(event.target.name)
          ? UpperCaseText(event.target.value)
          : event.target.value,
      });
      if (!event.target.value) {
        setPlantError(prevValue => {
          return {
            ...prevValue,
            [event.target.name]: event.target.name === 'plant_short_name' ? false : true
          }
        })
      } else {
        if (event.target.name === 'plant_name') {
          !checkNameFieldAlphaNumeric(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_short_name') {
          !checkAlphaNumericLength(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_code') {
          !checkAlphaNumericLength(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        // else if (event.target.name === 'godown_code') {
        //   !checkAlphaNumericLength(event.target.value) ?
        //     setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
        //     setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        // }
        else if (event.target.name === 'plant_gst_number') {
          !validateGstinNumber(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_pan_number') {
          !isPanNumberValid(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_mobile') {
          (checkMobileNumber(event.target.value) === "error") ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_email') {
          (checkEmailAddress(event.target.value) === "error") ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_upi_id') {
          !checkUpiId(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_account_no') {
          !checkNumeric(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_account_ifsc') {
          !checkIfscCode(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plants_address_sr_no') {
          !((event.target.value).length === 5) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_pincode') {
          !((event.target.value).toString().length === 6) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
        else if (event.target.name === 'plant_city') {
          !checkAlphabet(event.target.value) ?
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: true } }) :
            setPlantError(prevValue => { return { ...prevValue, [event.target.name]: false } })
        }
      }
    }
  };
  const handleCancelButtonClick = () => {
    props.history.push(`${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}`);
  };
  const handleEditSaveButtonClick = () => {
    setIsLoading(true)
    let plantItemMasterData = new FormData();
    plantItemMasterData.append("plant_name", plantItemDataDisplay?.plant_name);
    plantItemMasterData.append("plant_short_name", plantItemDataDisplay?.plant_short_name);
    plantItemMasterData.append(
      "plant_address",
      plantItemDataDisplay?.plant_address
    );
    plantItemMasterData.append(
      "is_active",
      (plantItemDataDisplay.is_active) === undefined ? true : Boolean(plantItemDataDisplay.is_active)
    );
    plantItemMasterData.append(
      "plant_state",
      plantItemDataDisplay?.plant_state
    );
    plantItemMasterData.append(
      "plant_city",
      plantItemDataDisplay?.plant_city
    );
    plantItemMasterData.append(
      "plant_pincode",
      plantItemDataDisplay?.plant_pincode
    );
    plantItemMasterData.append(
      "plants_address_sr_no",
      plantItemDataDisplay?.plants_address_sr_no
    );
    plantItemMasterData.append(
      "plant_upi_id",
      plantItemDataDisplay?.plant_upi_id
    );
    plantItemMasterData.append(
      "plant_account_no",
      plantItemDataDisplay?.plant_account_no
    );
    plantItemMasterData.append(
      "bank_name",
      plantItemDataDisplay?.bank_name
    );
    plantItemMasterData.append(
      "branch_name",
      plantItemDataDisplay?.branch_name
    );
    plantItemMasterData.append(
      "division",
      plantItemDataDisplay?.division
    );
    plantItemMasterData.append(
      "commissionerate",
      plantItemDataDisplay?.commissionerate
    );
    plantItemMasterData.append(
      "range",
      plantItemDataDisplay?.range
    );
    plantItemMasterData.append(
      "division",
      plantItemDataDisplay?.division
    );
    plantItemMasterData.append(
      "cin",
      plantItemDataDisplay?.cin
    );
    plantItemMasterData.append(
      "plant_account_ifsc",
      plantItemDataDisplay?.plant_account_ifsc
    );
    plantItemMasterData.append("plant_code", plantItemDataDisplay?.plant_code);
    plantItemMasterData.append(
      "plant_gst_number",
      plantItemDataDisplay?.plant_gst_number
    );
    plantItemMasterData.append(
      "plant_pan_number",
      plantItemDataDisplay?.plant_pan_number
    );
    plantItemMasterData.append(
      "plant_mobile",
      plantItemDataDisplay?.plant_mobile
    );
    plantItemMasterData.append(
      "plant_email",
      plantItemDataDisplay?.plant_email
    );
    plantItemMasterData.append(
      "godown_code", ""
      // plantItemDataDisplay?.godown_code
    );
    if (plantMasterOperation === "edit") {
      if (typeof plantItemDataDisplay?.plant_logo != "string") {
        plantItemMasterData.append(
          "plant_logo",
          plantItemDataDisplay?.plant_logo
        );
      }
    } else {
      plantItemMasterData.append(
        "plant_logo",
        plantItemDataDisplay?.plant_logo
      );
    }

    if (plantMasterOperation === "edit") {
      if (typeof plantItemDataDisplay?.logo_banner != "string") {
        plantItemMasterData.append(
          "logo_banner",
          plantItemDataDisplay?.logo_banner
        );
      }
    } else {
      plantItemMasterData.append(
        "logo_banner",
        plantItemDataDisplay?.logo_banner
      );
    }
    if (plantMasterOperation === "view") {
      setPlantItemMasterOperation("edit");
    } else if (plantMasterOperation === "edit") {
      props.handlePlantItemMasterDataUpdate(plantItemMasterData)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Plant Item Master Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.push(
                `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}/${response.data.id}`
              );
              handleCloseSnackbar()
            }, 4000);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating plant item master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (plantMasterOperation === "add") {
      if (plantItemCategoryData.length > 0) {

      }
      // console.log("plantItemMasterData : ", plantItemMasterData);
      props
        .handlePlantItemMasterDataAdd(plantItemMasterData)
        .then((response) => {
          if (response.status === 201) {
            setPlantItemMasterOperation("view");
            handleOpenSnackbar(
              `Plant Item Master Created Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.push(
                `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}/${response.data.id}`
              );
              handleCloseSnackbar()
            }, 4000);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while creating plant item master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };
  const handleAddNewAndSaveItemCategory = () => {
    console.log("value: " + plantMasterOperation)
    // console.log("plantMasterOperation : ", plantMasterOperation);
    if (plantMasterOperation === "add") {
      setItemCategoryData([
        ...plantItemCategoryData,
        {
          plant_item_category_code: "",
          item_category: "",
          voucher_code: "",
          plant_logo: "",
        },
      ]);
    } else if (plantMasterOperation === "edit") {
      // console.log("save item category");
      let updatedData = {
        plant: plantItemDataDisplay?.id,
        item_category: plantItemCategoryData,
        plant_logo: plantItemCategoryData,
        // plant_item_category_code
      };
      // console.log("updatedData : ", updatedData);
      props
        .handleAddNewItemsInPlant(updatedData)
        .then((response) => {
          if (response.status === 201) {
            // setMultipleSelectedItems([]);
          } else {
            handleOpenSnackbar(
              `Error occured while updating items in plant please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("invalid operation");
    }
  };
  const handleRemoveItemCategory = (index) => {
    const list = [...plantItemCategoryData];
    list.splice(index, 1);
    setItemCategoryData(list);
  };
  const handlePlantStateValueChange = (id) => (e) => {
    setPlantItemMasterData({
      ...plantItemDataDisplay,
      plant_state: id,
    });
  };

  const handleLogoImageUpload = (e) => {
    if (e.target.files.length) {
      console.log(e.target.files[0]);
      setLogoUploadData({
        ...logoUploadData,
        ["logoImageUrl"]: URL.createObjectURL(e.target.files[0]),
        ["logoRawUploadedFile"]: e.target.files[0],
        ["logoFileName"]: e.target.files[0].name,
      });
      setPlantItemMasterData({
        ...plantItemDataDisplay,
        ["plant_logo"]: e.target.files[0],
      });
    }
  };
  const handleLogoImageRemove = () => {
    console.log("remove image");
    setLogoUploadData({
      ...logoUploadData,
      ["logoImageUrl"]: "",
      ["logoRawUploadedFile"]: "",
      ["logoFileName"]: null,
    });
    setPlantItemMasterData({
      ...plantItemDataDisplay,
      ["plant_logo"]: "",
    });
  };

  const handleLogoBannerImageUpload = (e) => {
    if (e.target.files.length) {
      console.log(e.target.files[0]);
      setLogoBannerUploadData({
        ...logoBannerUploadData,
        ["logoBannerImageUrl"]: URL.createObjectURL(e.target.files[0]),
        ["logoBannerRawUploadedFile"]: e.target.files[0],
        ["logoBannerFileName"]: e.target.files[0].name,
      });
      setPlantItemMasterData({
        ...plantItemDataDisplay,
        ["logo_banner"]: e.target.files[0],
      });
    }
  };
  const handleLogoBannerImageRemove = () => {
    console.log("remove image");
    setLogoBannerUploadData({
      ...logoUploadData,
      ["logoBannerImageUrl"]: "",
      ["logoBannerRawUploadedFile"]: "",
      ["logoBannerFileName"]: null,
    });
    setPlantItemMasterData({
      ...plantItemDataDisplay,
      ["logo_banner"]: "",
    });
  };
  // console.log("plantItemCategoryData : ", plantItemCategoryData);
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Plant Item Master",
                onClick: () =>
                  props.history.push(
                    `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}`
                  ),
              },
              {
                title:
                  plantMasterOperation === "add"
                    ? "Create New"
                    : `Edit ${plantItemDataDisplay.plant_name}`,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT NAME *
              </Typography>
              <TextField
                label="Plant Name"
                name="plant_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter plant name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.plant_name}
                error={plantError.plant_name}
                helperText={plantError.plant_name ? "Invalid Name" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT SHORT NAME
              </Typography>
              <TextField
                label="Plant Short Name"
                name="plant_short_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter plant short name"
                className={classes.textField}
                inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.plant_short_name}
                error={plantError.plant_short_name}
                helperText={plantError.plant_short_name ? "Invalid Name" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT CODE *
              </Typography>

              <TextField
                label="Plant Code"
                name="plant_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter plant code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                value={plantItemDataDisplay.plant_code}
                error={plantError.plant_code}
                helperText={plantError.plant_code ? "Invalid Code" : ""}
              />
            </div>
            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                GODOWN CODE *
              </Typography>

              <TextField
                label="Godown Code"
                name="godown_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter godown code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 5, minLength: 5, max: 5 }}
                value={plantItemDataDisplay.godown_code}
                error={plantError.godown_code}
                helperText={plantError.godown_code ? "Invalid Code" : ""}
              />
            </div> */}
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT GSTIN NUMBER *
              </Typography>
              <TextField
                label="Plant Gstin"
                name="plant_gst_number"
                type="text"
                onChange={handleChange}
                placeholder="Enter Gst"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 15, minLength: 15, max: 15 }}
                value={plantItemDataDisplay.plant_gst_number}
                error={plantError.plant_gst_number}
                helperText={plantError.plant_gst_number ? "Please Enter 15-digit alpha-numeric no. (i.e 22ABCDE1234M1Z5)" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT PAN NUMBER *
              </Typography>
              <TextField
                label="Plant Pan Number"
                name="plant_pan_number"
                type="text"
                onChange={handleChange}
                placeholder="Enter pan"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                value={plantItemDataDisplay.plant_pan_number}
                error={plantError.plant_pan_number}
                helperText={plantError.plant_pan_number ? "Please Enter 10-digit alpha-numeric no. (i.e ABCDE1234M)" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT CIN *
              </Typography>
              <TextField
                label="Plant CIN"
                name="cin"
                type="text"
                onChange={handleChange}
                placeholder="Enter CIN"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.cin}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT RANGE *
              </Typography>
              <TextField
                label="Plant Range"
                name="range"
                type="text"
                onChange={handleChange}
                placeholder="Enter range"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.range}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT DIVISION *
              </Typography>
              <TextField
                label="Plant Division"
                name="division"
                type="text"
                onChange={handleChange}
                placeholder="Enter division"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.division}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT COMMISSIONERATE *
              </Typography>
              <TextField
                label="Plant Commissionerate"
                name="commissionerate"
                type="text"
                onChange={handleChange}
                placeholder="Enter Commissionerate"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.commissionerate}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT MOBILE NUMBER *
              </Typography>
              <TextField
                label="Plant Mobile"
                name="plant_mobile"
                type="text"
                onChange={handleChange}
                placeholder="Enter mobile"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                value={plantItemDataDisplay.plant_mobile}
                error={plantError.plant_mobile}
                helperText={plantError.plant_mobile ? "Invalid Mobile Number" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT EMAIL *
              </Typography>
              <TextField
                label="Plant Email"
                name="plant_email"
                type="email"
                onChange={handleChange}
                placeholder="Enter email"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.plant_email}
                error={plantError.plant_email}
                helperText={plantError.plant_email ? "Invalid email" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT UPI ID *
              </Typography>
              <TextField
                label="Plant UPI Id"
                name="plant_upi_id"
                type="text"
                onChange={handleChange}
                placeholder="Enter UPI Id"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.plant_upi_id}
                error={plantError.plant_upi_id}
                helperText={plantError.plant_upi_id ? "Invalid UPI Id" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT BANK NAME *
              </Typography>
              <TextField
                label="Plant Bank Name"
                name="bank_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter Bank Name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.bank_name}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT BRANCH NAME *
              </Typography>
              <TextField
                label="Plant Branch Name"
                name="branch_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter Branch Name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.branch_name}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT ACCOUNT NUMBER *
              </Typography>
              <TextField
                label="Plant Account Number"
                name="plant_account_no"
                type="text"
                onChange={handleChange}
                placeholder="Enter Account Number"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.plant_account_no}
                error={plantError.plant_account_no}
                helperText={plantError.plant_account_no ? "Invalid Account Number" : ""}
                onKeyPress={(event) => {
                  if (event.charCode >= 48 && event.charCode <= 57) {
                    // let it happen, don't do anything
                  }
                  else { event.preventDefault(); }
                }}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT ACCOUNT IFSC *
              </Typography>
              <TextField
                label="Plant Account IFSC"
                name="plant_account_ifsc"
                type="text"
                onChange={handleChange}
                placeholder="Enter Account IFSC"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 11, max: 11 }}
                value={plantItemDataDisplay.plant_account_ifsc}
                error={plantError.plant_account_ifsc}
                helperText={plantError.plant_account_ifsc ? "Please Enter 11-digit alpha-numeric code (i.e ABCD0123456)" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT ADDRESS SERIAL NUMBER *
              </Typography>
              <TextField
                label="Plant Address Serial Number"
                name="plants_address_sr_no"
                type="text"
                onChange={handleChange}
                placeholder="Enter Address Serial Number"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 5, minLength: 5, max: 5 }}
                onKeyPress={(event) => {
                  if (event.charCode >= 48 && event.charCode <= 57) {
                    // let it happen, don't do anything
                  }
                  else { event.preventDefault(); }
                }}
                value={plantItemDataDisplay.plants_address_sr_no}
                error={plantError.plants_address_sr_no}
                helperText={plantError.plants_address_sr_no ? "Please Enter 5-digit no." : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT ADDRESS *
              </Typography>
              <TextField
                label="Plant Address"
                name="plant_address"
                type="text"
                multiline
                rows={4}
                onChange={handleChange}
                placeholder="Enter plant address"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.plant_address}
                error={plantError.plant_address}
                helperText={plantError.plant_address ? "Invalid address" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT PINCODE *
              </Typography>
              <TextField
                label="Plant Pincode"
                name="plant_pincode"
                type="text"
                onChange={handleChange}
                placeholder="Enter Pincode"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 6, minLength: 6, max: 6 }}
                onKeyPress={(event) => {
                  if (event.charCode >= 48 && event.charCode <= 57) {
                    // let it happen, don't do anything
                  }
                  else { event.preventDefault(); }
                }}
                value={plantItemDataDisplay.plant_pincode}
                error={plantError.plant_pincode}
                helperText={plantError.plant_pincode ? "Invalid Pincode" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT CITY *
              </Typography>
              <TextField
                label="Plant City"
                name="plant_city"
                type="text"
                onChange={handleChange}
                placeholder="Enter City"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={plantItemDataDisplay.plant_city}
                error={plantError.plant_city}
                helperText={plantError.plant_city ? "Invalid City" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PLANT STATE *
              </Typography>
              <FormControl
                margin="dense"
                variant="outlined"
                size="small"
                className={classes.textField}
              >
                <InputLabel id="Plant StateId">
                  Plant State
                </InputLabel>
                {console.log(plantItemDataDisplay.plant_state)}
                <Select
                  name="plant_state"
                  labelId="Plant StateId"
                  value={plantItemDataDisplay.plant_state}
                  placeholder="Enter Plant State"
                  label="Plant State"
                  error={plantError.plant_state}
                  helperText={plantError.plant_state ? "Invalid State" : ""}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="select" disabled={true}>
                    <em>Select State</em>
                  </MenuItem>
                  {allStateList && allStateList.length > 0
                    ? allStateList.map(
                      (allStateListItem, selectIndex) => (
                        <MenuItem
                          key={selectIndex}
                          onClick={handlePlantStateValueChange(allStateListItem.id)}
                          value={allStateListItem.id}
                        >
                          {allStateListItem.name}
                        </MenuItem>
                      )
                    )
                    : null}
                </Select>
              </FormControl>
            </div>
            <div className={classes.dataFields} style={{ justifyContent: 'flex-start' }}>
              <Typography className={classes.titleLabel}>
                ACTIVE
              </Typography>
              <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                <IOSSwitch
                  checked={(plantItemDataDisplay.is_active) === undefined ? true : Boolean(plantItemDataDisplay.is_active)}
                  onChange={handleChange}
                  name="is_active"
                />
              </div>
            </div>

            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                LOGO UPLOAD *
              </Typography>
              <div className={classes.formControlPanImage}>
                {!logoUploadData.logoImageUrl ? (
                  <>
                    <label
                      htmlFor="upload-button"
                      className={classes.fileUploadName}
                    >
                      <div className={classes.logoImageUpload}>
                        <PresentToAllOutlinedIcon
                          className={classes.logoImagePreview}
                        />
                        <Typography className={classes.generalText}>
                          Upload
                        </Typography>
                      </div>
                    </label>
                    <input
                      type="file"
                      accept=".jpg,.png"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleLogoImageUpload(e);
                      }}
                    />
                  </>
                ) : (
                  <span
                    onClick={() =>
                      window.open(logoUploadData.logoImageUrl, "_blank")
                    }
                    className={classes.titleLabelStylePanImage}
                  >
                    {logoUploadData.logoFileName}
                  </span>
                )}
                <DeleteOutlineOutlinedIcon
                  width="20"
                  height="20"
                  onClick={handleLogoImageRemove}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                LOGO BANNER UPLOAD
              </Typography>
              <div className={classes.formControlPanImage}>
                {!logoBannerUploadData.logoBannerImageUrl ? (
                  <>
                    <label
                      htmlFor="upload-button"
                      className={classes.fileUploadName}
                    >
                      <div className={classes.logoImageUpload}>
                        <PresentToAllOutlinedIcon
                          className={classes.logoImagePreview}
                        />
                        <Typography className={classes.generalText}>
                          Upload
                        </Typography>
                      </div>
                    </label>
                    <input
                      type="file"
                      accept=".jpg,.png"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleLogoBannerImageUpload(e);
                      }}
                    />
                  </>
                ) : (
                  <span
                    onClick={() =>
                      window.open(logoBannerUploadData.logoBannerImageUrl, "_blank")
                    }
                    className={classes.titleLabelStylePanImage}
                  >
                    {logoBannerUploadData.logoBannerFileName}
                  </span>
                )}
                <DeleteOutlineOutlinedIcon
                  width="20"
                  height="20"
                  onClick={handleLogoBannerImageRemove}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <div style={{
              marginTop: "10px"
            }}>
            </div>
            <SaveButton
              size="medium"
              disabled={
                plantMasterOperation === "add"
                  ? !plantItemDataDisplay.plant_name ||
                  !plantItemDataDisplay.plant_code ||
                  // !plantItemDataDisplay.godown_code ||
                  !plantItemDataDisplay.plant_gst_number ||
                  !plantItemDataDisplay.plant_pan_number ||
                  !plantItemDataDisplay.cin ||
                  !plantItemDataDisplay.range ||
                  !plantItemDataDisplay.division ||
                  !plantItemDataDisplay.commissionerate ||
                  !plantItemDataDisplay.plant_mobile ||
                  !plantItemDataDisplay.plant_email ||
                  !plantItemDataDisplay.plant_upi_id ||
                  !plantItemDataDisplay.bank_name ||
                  !plantItemDataDisplay.branch_name ||
                  !plantItemDataDisplay.plant_account_no ||
                  !plantItemDataDisplay.plant_account_ifsc ||
                  !plantItemDataDisplay.plants_address_sr_no ||
                  !plantItemDataDisplay.plant_address ||
                  !plantItemDataDisplay.plant_pincode ||
                  !plantItemDataDisplay.plant_city ||
                  !plantItemDataDisplay.plant_state ||
                  !plantItemDataDisplay?.plant_logo ||
                  plantError.plant_name ||
                  plantError.plant_short_name ||
                  plantError.plant_code ||
                  // plantError.godown_code ||
                  plantError.plant_gst_number ||
                  plantError.plant_pan_number ||
                  plantError.plant_mobile ||
                  plantError.plant_email ||
                  plantError.plant_upi_id ||
                  plantError.plant_account_no ||
                  plantError.plant_account_ifsc ||
                  plantError.plants_address_sr_no ||
                  plantError.plant_pincode ||
                  plantError.plant_city ||
                  plantItemDataDisplay.plant_name === "" ||
                  plantItemDataDisplay.plant_code === "" ||
                  // plantItemDataDisplay.godown_code === "" ||
                  plantItemDataDisplay.plant_gst_number === "" ||
                  plantItemDataDisplay.plant_pan_number === "" ||
                  plantItemDataDisplay.cin === "" ||
                  plantItemDataDisplay.range === "" ||
                  plantItemDataDisplay.division === "" ||
                  plantItemDataDisplay.commissionerate === "" ||
                  plantItemDataDisplay.plant_mobile === "" ||
                  plantItemDataDisplay.plant_email === "" ||
                  plantItemDataDisplay.plant_upi_id === "" ||
                  plantItemDataDisplay.bank_name === "" ||
                  plantItemDataDisplay.branch_name === "" ||
                  plantItemDataDisplay.plant_account_no === "" ||
                  plantItemDataDisplay.plant_account_ifsc === "" ||
                  plantItemDataDisplay.plants_address_sr_no === "" ||
                  plantItemDataDisplay.plant_address === "" ||
                  plantItemDataDisplay.plant_pincode === "" ||
                  plantItemDataDisplay.plant_city === "" ||
                  plantItemDataDisplay.plant_state === "" ||
                  plantItemCategoryData.length === 0
                  : !plantItemDataDisplay.plant_name ||
                  !plantItemDataDisplay.plant_code ||
                  // !plantItemDataDisplay.godown_code ||
                  !plantItemDataDisplay.plant_gst_number ||
                  !plantItemDataDisplay.plant_pan_number ||
                  !plantItemDataDisplay.cin ||
                  !plantItemDataDisplay.range ||
                  !plantItemDataDisplay.division ||
                  !plantItemDataDisplay.commissionerate ||
                  !plantItemDataDisplay.plant_mobile ||
                  !plantItemDataDisplay.plant_email ||
                  !plantItemDataDisplay.plant_upi_id ||
                  !plantItemDataDisplay.bank_name ||
                  !plantItemDataDisplay.branch_name ||
                  !plantItemDataDisplay.plant_account_no ||
                  !plantItemDataDisplay.plant_account_ifsc ||
                  !plantItemDataDisplay.plants_address_sr_no ||
                  !plantItemDataDisplay.plant_address ||
                  !plantItemDataDisplay.plant_pincode ||
                  !plantItemDataDisplay.plant_city ||
                  !plantItemDataDisplay.plant_state ||
                  !plantItemDataDisplay?.plant_logo ||
                  plantItemDataDisplay.plant_name === "" ||
                  plantItemDataDisplay.plant_code === "" ||
                  // plantItemDataDisplay.godown_code === "" ||
                  plantItemDataDisplay.plant_gst_number === "" ||
                  plantItemDataDisplay.plant_pan_number === "" ||
                  plantItemDataDisplay.cin === "" ||
                  plantItemDataDisplay.range === "" ||
                  plantItemDataDisplay.division === "" ||
                  plantItemDataDisplay.commissionerate === "" ||
                  plantItemDataDisplay.plant_mobile === "" ||
                  plantItemDataDisplay.plant_email === "" ||
                  plantItemDataDisplay.plant_upi_id === "" ||
                  plantItemDataDisplay.bank_name === "" ||
                  plantItemDataDisplay.branch_name === "" ||
                  plantItemDataDisplay.plant_account_no === "" ||
                  plantItemDataDisplay.plant_account_ifsc === "" ||
                  plantItemDataDisplay.plants_address_sr_no === "" ||
                  plantItemDataDisplay.plant_address === "" ||
                  plantItemDataDisplay.plant_pincode === "" ||
                  plantItemDataDisplay.plant_city === "" ||
                  plantItemDataDisplay.plant_state === "" ||
                  plantError.plant_name ||
                  plantError.plant_short_name ||
                  plantError.plant_code ||
                  // plantError.godown_code ||
                  plantError.plant_gst_number ||
                  plantError.plant_pan_number ||
                  plantError.plant_mobile ||
                  plantError.plant_email ||
                  plantError.plant_upi_id ||
                  plantError.plant_account_no ||
                  plantError.plant_account_ifsc ||
                  plantError.plants_address_sr_no ||
                  plantError.plant_pincode ||
                  plantError.plant_city
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {plantMasterOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>

            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManagePlantItemMaster);
