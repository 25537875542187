import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  OutlinedInput,
  InputAdornment,
  Button,
  TextField,
  IconButton,
  TablePagination,
  ThemeProvider,
  createMuiTheme,
  Typography
} from "@material-ui/core";
import {
  drawerTextColor,
  grey,
  secondaryGrey,
  successButtonBackground,
  whiteSmoke,
  secondaryBlueText,
  earthBlue,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { checkInvalidValue, hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../methods";
import SpinLoader from "../../../common/SpinLoader";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Form, Formik } from "formik";
import { validationsPaymentTypesDetails } from "./ItemCategoryValidationSchema";
import SnackbarComponent from "../../common/SnackbarComponent";
import SaveButton from "../../common/SaveButton";
import Popover from '@mui/material/Popover';
import COLORS from "../../../../constants/internal/colors";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 10,
    width: "100%",
    height: "calc(100vh - 400px)",
    overflow: "auto",
  },
  inputWidth: { width: "14rem", height: 40 },
  tableHead: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    width: "0%",
  },
  tableBodyForCityData: {
    fontSize: pxToEm(14),
    width: "30%",
  },
  tableBodyForPaymentTermData: {
    width: "0%",
    fontSize: pxToEm(14),
  },
  alignText: {
    display: "flex",
    flexWrap: "wrap",
    placeContent: "center",
  },
  alignBodyText: {
    display: "flex",
    alignItems: "center",
  },
  rightSpacing: {
    marginRight: 10,
  },
  stateSelection: {
    marginRight: 10,
    width: "100%",
  },
  arrowDimension: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: "bold",
  },
  container: {
    marginTop: 10,
  },
  removeOption: {
    color: secondaryGrey,
    fontSize: pxToEm(13),
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveCancelButtonStyles: {
    paddingTop: 10,
  },
  paymentTermMainDiv: {
    marginTop: "20px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    color: grey,
  },
  editTextIcon: {
    borderRadius: 100,
    padding: 2,
    marginLeft: "10px",
    width: "70px",
    display: "flex",
    backgroundColor: whiteSmoke,
    color: grey,
    cursor: "pointer",
  },
  editText: {
    flex: 1,
    marginLeft: 5,
    textAlign: "left",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
  },
  popoverBox: {
    '& .MuiPopover-paper': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
      marginBottom: 10,
      // borderRadius: 20,
    }
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '500',
    alignItems: "center"
  },
  discountFieldForCityData: {
    fontSize: pxToEm(14),
    //width: "25%",
    display: "flex",
    alignItems: "center",
  },
});
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },

  },
});

export default function ManageItemCategoryPaymentTermAndCityTab(props) {
  const classes = useStyles();
  const [allSelectionOptions, setAllSelectionOption] = React.useState(
    props?.allPaymentMethodData || []
  );
  const [rows, setRows] = React.useState(
    props?.itemCategoryPaymentTermData || []
  );
  const [title, setTitle] = React.useState(" PAYMENT TERM ");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [paymentTermOrCityTableOperation, setPaymentTermOrCityTableOperation] =
    React.useState("view");

  React.useEffect(() => {
    if (props?.allPaymentMethodData) {
      setAllSelectionOption(
        props.displayDataFor === "paymentTerm"
          ? props?.allPaymentMethodData || []
          : []
      );

    }
    if (props?.itemCategoryPaymentTermData) {
      setRows(
        props.displayDataFor === "paymentTerm"
          ? props?.itemCategoryPaymentTermData || []
          : []
      );
    }
  }, [props?.allPaymentMethodData, props?.itemCategoryPaymentTermData]);

  const handlePriceUpdate = (event, data, index) => {
    // console.log(event)
    console.log(data)
    let updatedData = {
      discount_amount: checkInvalidValue(data.discount_amount) ? null : Number(data.discount_amount),
      premium_amount: checkInvalidValue(data.premium_amount) ? null : Number(data.premium_amount),
      item_category: data.item_category,
      discount_amount_valid_from: moment(new Date(data?.discount_amount_valid_from)).isAfter(data?.premium_amount_valid_from, 'day') ? data?.discount_amount_valid_from : data?.premium_amount_valid_from,
      premium_amount_valid_from: moment(new Date(data?.premium_amount_valid_from)).isAfter(data?.discount_amount_valid_from, 'day') ? data?.premium_amount_valid_from : data?.discount_amount_valid_from,
    };
    // console.log(updatedData)
    props.handleUpdateDiscountAmount(
      updatedData,
      props.displayDataFor,
      data.id
    );
    // handlePaymentTermOrCityTableOperation("view")

    // const updatedRows = [...rows];
    // updatedRows[index]["discount_amount"] = event.target.value;
    // setRows(updatedRows);
  };

  function handlePaymentTermOrCityTableOperation(operation) {
    setPaymentTermOrCityTableOperation(operation);
    if (operation === "add") {
      props.handlePaymentOrCityStateValue("paymentTerm")
    }
  }

  function handlePaymentTermOrCityTableSave(operation) {
    props.handlePaymentTermOrCityTableSaveData(props.displayDataFor);
    setPaymentTermOrCityTableOperation(operation);
    setTimeout(() => {
      if (props?.itemCategoryPaymentTermData) {
        setRows(
          props.displayDataFor === "paymentTerm"
            ? props?.itemCategoryPaymentTermData || []
            : []
        );
      }
    }, 0);
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const filteredSelectionList = allSelectionOptions.filter(
    (x) =>
      !rows.filter((y) => {
        // if (props.customerType === 'DEALER') {
        //   return y.distributor.id === x.id;
        // } else if (props.customerType === 'DISTRIBUTOR') {
        return y.payment_method_name === x.payment_method_name;
        // } else {
        //   return true;
        // }
      }).length
  );

  const handleDiscountValueChange = (index) => (e) => {
    const { value } = e.target;
    // console.log(index, value)
    const list = [...rows];
    list[index].discount_amount = value;
    setRows(list);
    // console.log()
  };

  const handlePremiumValueChange = (index) => (e) => {
    const { value } = e.target;
    // console.log(index, value)
    const list = [...rows];
    list[index].premium_amount = value;
    setRows(list);
    // console.log()
  };

  const addNewEndRef = useRef(null)

  const scrollToBottom = () => {
    addNewEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const handleDiscountValidFromChange = (index) => (value) => {
    const list = [...rows];
    list[index].discount_amount_valid_from = moment(value).format("YYYY-MM-DD");
    setRows(list);
  }

  const handlePremiumValidFromChange = (index) => (value) => {
    const list = [...rows];
    list[index].premium_amount_valid_from = moment(value).format("YYYY-MM-DD");
    setRows(list);
  }

  const Mousehover = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (e) => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            fontFamily: 'Poppins',
            fontWeight: anchorEl === null ? "400" : "500",
            color: anchorEl === null ? "#666" : "#3B69B0",
            cursor: 'pointer',
            marginTop: "2px",
            marginLeft: "5px"
          }}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          className={classes.popoverBox}
        >
          <Typography sx={{ p: 1 }}>
            {props.content}
          </Typography>
        </Popover>
      </div>
    );
  }

  const ClickedDiscountDiffBox = (props) => {
    let data = props.data;
    console.log(data)
    return (
      <div style={{ width: 350, padding: "5%" }}>
        <Typography className={classes.dialogText}>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Discount amount
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid from
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid upto
          </div>
        </Typography>
        <Divider variant='middle' style={{ margin: '2% 0' }} />
        {data?.payment_method_wise_discount_amount_logs?.map((rateDiffData, rdindex) => {
          return (
            <div key={rdindex}>
              <Typography className={classes.dialogText}>
                <div>
                  ₹ {rateDiffData?.value}

                </div>
                <div style={{
                  marginLeft: !rateDiffData?.valid_upto
                    ? null : "70px",
                }}
                >
                  {!rateDiffData?.valid_from
                    ? null
                    : moment(rateDiffData?.valid_from).format("DD-MM-YYYY")}
                </div>
                <div>
                  {!rateDiffData?.valid_upto
                    ? null
                    : moment(rateDiffData?.valid_upto).format("DD-MM-YYYY")}
                </div>
              </Typography>
              <Divider
                variant='middle'
                style={{
                  margin: '2% 0',
                  display:
                    rdindex === (data?.city_discount_logs?.length - 1) ? 'none' :
                      'block'
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const ClickedPremiumDiffBox = (props) => {
    let data = props.data;
    console.log(data)
    return (
      <div style={{ width: 350, padding: "5%" }}>
        <Typography className={classes.dialogText}>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Premium amount
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid from
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid upto
          </div>
        </Typography>
        <Divider variant='middle' style={{ margin: '2% 0' }} />
        {data?.payment_method_wise_premium_amount_logs?.map((rateDiffData, rdindex) => {
          return (
            <div key={rdindex}>
              <Typography className={classes.dialogText}>
                <div>
                  ₹ {rateDiffData?.value}

                </div>
                <div style={{
                  marginLeft: !rateDiffData?.valid_upto
                    ? null : "70px",
                }}
                >
                  {!rateDiffData?.valid_from
                    ? null
                    : moment(rateDiffData?.valid_from).format("DD-MM-YYYY")}
                </div>
                <div>
                  {!rateDiffData?.valid_upto
                    ? null
                    : moment(rateDiffData?.valid_upto).format("DD-MM-YYYY")}
                </div>
              </Typography>
              <Divider
                variant='middle'
                style={{
                  margin: '2% 0',
                  display:
                    rdindex === (data?.city_discount_logs?.length - 1) ? 'none' :
                      'block'
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  // console.log(filteredSelectionList)
  return (
    <div className={classes.paymentTermMainDiv}>
      <div className={classes.iconContainer}>
        <span className={classes.contentTitle}>DISCOUNT BASED ON {title}</span>
        <ToolTipComponent
          title="You have no edit permission on this widget"
          condition={!hasUpdateAccess(props.user, 'item_categories')}
        >
          <div
            className={classes.editTextIcon}
            onClick={() => {
              if (paymentTermOrCityTableOperation === "edit") {
                props.fetchItemCategoryPaymentTerm();
                handlePaymentTermOrCityTableOperation("view")
              } else {
                hasUpdateAccess(props.user, 'item_categories') &&
                  handlePaymentTermOrCityTableOperation("edit")
              }
            }}
          >
            <EditOutlinedIcon className={classes.icon} />
            <div
              className={classes.editText}
              style={{
                color: !hasUpdateAccess(props.user, 'item_categories') && 'rgba(0,0,0,0.26)'
              }}
            >
              {paymentTermOrCityTableOperation === "edit" ? "view" : "edit"}
            </div>
          </div>
        </ToolTipComponent>
      </div>
      <Paper>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableHead}>
                  {props.displayDataFor === "paymentTerm"
                    ? "PAYMENT TERM"
                    : "CITY"}
                </TableCell>
                {props.displayDataFor === "city" ? (
                  <TableCell align="left" className={classes.tableHead}>
                    PIN CODE
                  </TableCell>
                ) : null}
                <TableCell align="left" className={classes.tableHead}>
                  DISCOUNT
                  {props.displayDataFor === "paymentTerm" ? " " : " (PER M.T.)"}
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  DISCOUNT EFFECTIVE FROM
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                </TableCell>
                {props.displayDataFor === "paymentTerm" ? (
                  <TableCell align="left" className={classes.tableHead}>
                    PREMIUM
                  </TableCell>
                ) : null}
                <TableCell align="left" className={classes.tableHead}>
                  PREMIUM EFFECTIVE FROM
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : rows
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={
                      props.displayTableFor === "city"
                        ? classes.tableBodyForCityData
                        : classes.tableBodyForPaymentTermData
                    }
                  >
                    <span
                      className={
                        props.displayTableFor === "city"
                          ? classes.tableBodyForCityData
                          : classes.tableBodyForPaymentTermData
                      }
                    >
                      {props.displayTableFor === "city"
                        ? row.name || ""
                        : row.payment_method_name || ""}
                    </span>
                  </TableCell>
                  {props.displayDataFor === "city" ? (
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={
                        props.displayTableFor === "city"
                          ? classes.tableBodyForCityData
                          : classes.tableBodyForPaymentTermData
                      }
                    >
                      <span
                        className={
                          props.displayTableFor === "city"
                            ? classes.tableBodyForCityData
                            : classes.tableBodyForPaymentTermData
                        }
                      >
                        {row.pin_code}
                      </span>
                    </TableCell>
                  ) : null}
                  <Formik
                    initialValues={{
                      discount_amount: row?.discount_amount || "",
                      premium_amount: row?.premium_amount || "",
                      discount_amount_valid_from: row?.discount_amount_valid_from || "",
                      premium_amount_valid_from: row?.premium_amount_valid_from || ""
                    }}
                    enableReinitialize={true}
                    validationSchema={validationsPaymentTypesDetails}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(e) => {
                      handlePriceUpdate(e, row, ((page * rowsPerPage) + index))
                    }}
                  >
                    {(formik) => (
                      <>
                        <TableCell
                          align="left"
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                          style={{ fontSize: pxToEm(14) }}
                        >
                          {paymentTermOrCityTableOperation === "view" ||
                            paymentTermOrCityTableOperation === "add" ? (

                            <div className={classes.discountFieldForCityData}>

                              {row?.discount_amount != null || "" ? "₹ " + parseFloat(row.discount_amount).toFixed(2) : "----"}
                              <Mousehover
                                content={<ClickedDiscountDiffBox
                                  data={row}
                                />}
                              />
                            </div>
                            // </span>
                          ) : (
                            (props.displayTableFor !== "city" && (row.premium_amount == null || row.premium_amount == "" || row.premium_amount == 0)) ?
                              <Form>
                                <TextField
                                  type="number"
                                  className={classes.inputWidth}
                                  id="outlined-adornment-amount"
                                  // disabled={paymentTermOrCityTableOperation === "add" && (row.premium_amount)}
                                  value={row.discount_amount}
                                  variant="outlined"
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) => {
                                    handleDiscountValueChange((page * rowsPerPage) + index)(e)
                                    formik.setFieldValue(
                                      "discount_amount",
                                      e.target.value
                                    )
                                  }}
                                  startAdornment={
                                    <InputAdornment position="start">₹</InputAdornment>
                                  }
                                  size="small"
                                  onKeyDown={(evt) => (
                                    ["e", "E", "+", "-"].includes(evt.key) ||
                                    (evt.which === 38 || evt.which === 40)
                                  ) && evt.preventDefault()}
                                  helperText={
                                    formik.errors.discount_amount &&
                                    formik.touched.discount_amount &&
                                    String(formik.errors.discount_amount)
                                  }
                                  error={Boolean(
                                    formik.errors.discount_amount &&
                                    formik.touched.discount_amount
                                  )}
                                />
                              </Form>
                              :
                              <span> ---- </span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                        >
                          {paymentTermOrCityTableOperation === "view" ||
                            paymentTermOrCityTableOperation === "add" ? (
                            <span
                              className={classes.tableBodyForPaymentTermData}
                            >
                              {!row.discount_amount_valid_from ? null : moment(row.discount_amount_valid_from).format("DD MMM YYYY")}
                            </span>
                          ) : (
                            // row.premium_amount == null || row.premium_amount == "" ?
                            <>
                              <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name="discount_amount_valid_from"
                                    size="small"
                                    inputVariant="outlined"
                                    label="Effective From"
                                    format="dd/MM/yyyy"
                                    className={classes.inputWidth}
                                    disabled={(!row.discount_amount || Number(row.discount_amount) < 0) || row.premium_amount > 0}
                                    // minDate={row?.discount_amount_valid_from
                                    //   ? new Date(row?.discount_amount_valid_from)
                                    //   : new Date()}
                                    minDate={new Date(row?.discount_amount_valid_from)}
                                    value={!row?.discount_amount_valid_from ? null : new Date(row?.discount_amount_valid_from)}
                                    helperText={null}
                                    error={null}
                                    onChange={handleDiscountValidFromChange((page * rowsPerPage) + index)}
                                    placeholder="e.g.: DD/MM/YYYY"
                                    InputAdornmentProps={{ position: "end", }}
                                    InputProps={{
                                      style: {
                                        paddingRight: 0,
                                      },
                                      readOnly: true
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </ThemeProvider>
                            </>
                            // :
                            // <span> ef-- </span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                        ><IconButton
                          aria-label="delete"
                          className={classes.itemCategoryActionIcon}
                          style={{ textAlign: "left" }}
                          onClick={(e) => handlePriceUpdate(e, row, index)}
                          disabled={paymentTermOrCityTableOperation === "view" || (!row.discount_amount || Number(row.discount_amount) < 0) || row.premium_amount > 0}
                          type="submit"
                        >
                            <CheckCircleOutlineOutlinedIcon fontSize="small" />
                          </IconButton></TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyForPaymentTermData}
                        >
                          {paymentTermOrCityTableOperation === "view" ||
                            paymentTermOrCityTableOperation === "add" ? (
                            <div className={classes.discountFieldForCityData}>
                              {row?.premium_amount != null || "" ? "₹ " + parseFloat(row?.premium_amount).toFixed(2) : "----"}
                              <Mousehover
                                content={<ClickedPremiumDiffBox
                                  data={row}
                                />}
                              />
                            </div>
                          ) : (
                            row.discount_amount == null || row.discount_amount == "" || row.discount_amount == 0 ?
                              <Form>
                                <TextField
                                  type="number"
                                  className={classes.inputWidth}
                                  id="outlined-adornment-amount"
                                  // disabled={paymentTermOrCityTableOperation === "add" && (row.discount_amount)}
                                  value={row.premium_amount}
                                  variant="outlined"
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) => {
                                    handlePremiumValueChange((page * rowsPerPage) + index)(e)
                                    formik.setFieldValue(
                                      "premium_amount",
                                      e.target.value
                                    )
                                  }}
                                  startAdornment={
                                    <InputAdornment position="start">₹</InputAdornment>
                                  }
                                  size="small"
                                  onKeyDown={(evt) => (
                                    ["e", "E", "+", "-"].includes(evt.key) ||
                                    (evt.which === 38 || evt.which === 40)
                                  ) && evt.preventDefault()}
                                  helperText={
                                    formik.errors.premium_amount &&
                                    formik.touched.premium_amount &&
                                    String(formik.errors.premium_amount)
                                  }
                                  error={Boolean(
                                    formik.errors.premium_amount &&
                                    formik.touched.premium_amount
                                  )}
                                />
                              </Form>
                              :
                              <span> ---- </span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyForPaymentTermData}
                        >
                          {paymentTermOrCityTableOperation === "view" ||
                            paymentTermOrCityTableOperation === "add" ? (
                            <span className={classes.tableBodyForPaymentTermData}>
                              {!row.premium_amount_valid_from ? null : moment(row.premium_amount_valid_from).format("DD MMM YYYY")}
                            </span>
                          ) : (
                            // row.discount_amount == null || row.discount_amount == "" ?
                            <>
                              <ThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name="premium_amount_valid_from"
                                    size="small"
                                    inputVariant="outlined"
                                    label="Effective From"
                                    format="dd/MM/yyyy"
                                    className={classes.inputWidth}
                                    // minDate={row?.premium_amount_valid_from
                                    //   ? new Date(row?.premium_amount_valid_from)
                                    //   : new Date()}
                                    minDate={new Date(row?.premium_amount_valid_from)}
                                    disabled={row?.discount_amount > 0 || !row.premium_amount || row.premium_amount == (null || "")}
                                    value={!row?.premium_amount_valid_from ? null : new Date(row?.premium_amount_valid_from)}
                                    helperText={null}
                                    error={null}
                                    onChange={handlePremiumValidFromChange((page * rowsPerPage) + index)}
                                    placeholder="e.g.: DD/MM/YYYY"
                                    InputAdornmentProps={{ position: "end", }}
                                    InputProps={{
                                      style: {
                                        paddingRight: 0,
                                      },
                                      readOnly: true
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </ThemeProvider>
                            </>
                            // :
                            // <span> ab-- </span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                        ><IconButton
                          aria-label="delete"
                          className={classes.itemCategoryActionIcon}
                          onClick={(e) => handlePriceUpdate(e, row, index)}
                          disabled={paymentTermOrCityTableOperation === "view" || (!row.premium_amount || row.premium_amount == (null || "")) || row?.discount_amount > 0}
                          type="submit"
                        >
                            <CheckCircleOutlineOutlinedIcon fontSize="small" />
                          </IconButton></TableCell>
                        <TableCell
                          align="left"
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                        >
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(props.user, 'item_categories')}
                          >
                            <IconButton
                              className={classes.margin}
                              onClick={() =>
                                props.handleDeletePaymentTerm(row?.id)
                              }
                              size="small"
                              disabled={!hasUpdateAccess(props.user, 'item_categories')}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </ToolTipComponent>
                        </TableCell>
                      </>
                    )}
                  </Formik>
                </TableRow>
              ))}
              {paymentTermOrCityTableOperation === "add" ? (
                <>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      min="0"
                      className={
                        props.displayTableFor === "city"
                          ? classes.tableBodyForCityData
                          : classes.tableBodyForPaymentTermData
                      }
                    >
                      <Autocomplete
                        size="small"
                        options={filteredSelectionList}
                        className={props.displayDataFor === "paymentTerm" && classes.inputWidth}
                        getOptionLabel={(option) =>
                          props.displayDataFor === "paymentTerm"
                            ? `${option.payment_method_name}(${option.days} days)`
                            : option.name
                        }
                        onChange={
                          props.displayDataFor === "paymentTerm"
                            ? props.handlePaymentTermTableAutocompleteValueChange
                            : props.handleCityTableAutocompleteValueChange
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Select ${props.displayDataFor === "paymentTerm"
                              ? "payment term"
                              : "city"
                              }`}
                            variant="outlined"
                          />
                        )}
                      />
                    </TableCell>
                    {props.displayDataFor === "city" ? (
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={
                          props.displayTableFor === "city"
                            ? classes.tableBodyForCityData
                            : classes.tableBodyForPaymentTermData
                        }
                      >
                        <span
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                        >
                          {props.pincode}
                        </span>
                      </TableCell>
                    ) : null}
                    <TableCell
                      align="left"
                      className={
                        props.displayTableFor === "city"
                          ? classes.tableBodyForCityData
                          : classes.tableBodyForPaymentTermData
                      }
                    >
                      {props.displayTableFor !== "city" && (props.premium_amount === null || props.premium_amount === "")
                        ?
                        <TextField
                          type="number"
                          className={classes.inputWidth}
                          variant="outlined"
                          value={
                            props.displayTableFor === "city" ?
                              props.cityDiscountAmount : props.paymentTermDiscountAmount
                          }
                          onWheel={(e) => e.target.blur()}
                          onChange={
                            props.displayTableFor === "city"
                              ? props.handleChange("cityDiscountAmount")
                              : props.handleChange("paymentTermDiscountAmount")
                          }
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          size="small"
                          onKeyDown={(evt) => (
                            ["e", "E", "+", "-"].includes(evt.key) ||
                            (evt.which === 38 || evt.which === 40)
                          ) && evt.preventDefault()}
                          error={
                            props.displayTableFor === "city"
                              ? props.cityDiscountAmountError
                              : props.paymentTermDiscountAmountError
                          }
                          helperText={
                            props.cityDiscountAmountError ||
                              props.paymentTermDiscountAmountError
                              ? "Invalid discount amount"
                              : ""
                          }
                        />
                        :
                        <span
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                        >
                          ----
                        </span>
                      }
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableBodyForCityData}
                    >
                      {props.displayTableFor !== "city" && (props.premium_amount === null || props.premium_amount === "")
                        ?
                        <ThemeProvider theme={materialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              name="discount_amount_valid_from"
                              size="small"
                              inputVariant="outlined"
                              label="Effective From"
                              format="dd/MM/yyyy"
                              className={classes.inputWidth}
                              // minDate={props?.effectiveDate
                              //   ? new Date(props.effectiveDate)
                              //   : new Date()}
                              minDate={new Date(props.effectiveDate)}
                              value={!props.effectiveDate ? null : new Date(props.effectiveDate)}
                              onChange={props.handleChange("effectiveDate")}
                              placeholder="e.g.: DD/MM/YYYY"
                              InputAdornmentProps={{ position: "end", }}
                              InputProps={{
                                style: {
                                  paddingRight: 0,
                                },
                                readOnly: true
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                        :
                        <span
                          className={
                            props.displayTableFor === "city"
                              ? classes.tableBodyForCityData
                              : classes.tableBodyForPaymentTermData
                          }
                        >
                          ----
                        </span>
                      }
                    </TableCell>
                    {props.displayDataFor === "paymentTerm" ?
                      <>
                        <TableCell
                          align="left"
                          className={classes.tableBodyForPaymentTermData}
                        >
                          {console.log(props.paymentTermDiscountAmount == "")}
                          {(props.paymentTermDiscountAmount === null || props.paymentTermDiscountAmount === "") ?
                            <TextField
                              type="number"
                              className={classes.inputWidth}
                              variant="outlined"
                              value={props.premium_amount}
                              onWheel={(e) => e.target.blur()}
                              onChange={props.handleChange("premium_amount")}
                              startAdornment={
                                <InputAdornment position="start">₹</InputAdornment>
                              }
                              size="small"
                              onKeyDown={(evt) => (
                                ["e", "E", "+", "-"].includes(evt.key) ||
                                (evt.which === 38 || evt.which === 40)
                              ) && evt.preventDefault()}
                              error={props.paymentTermPremiumError}
                              helperText={props.paymentTermPremiumError
                                ? "Invalid Premium Amount"
                                : ""
                              }
                            />
                            :
                            <span
                              className={classes.tableBodyForPaymentTermData}
                            >
                              ----
                            </span>
                          }
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyForPaymentTermData}
                        >
                          {console.log(props.paymentTermDiscountAmount == "")}
                          {console.log("DATE : ", new Date(props.premiumAmountDate), props.premiumAmountDate)}

                          {(props.paymentTermDiscountAmount === null || props.paymentTermDiscountAmount === "") ?
                            <ThemeProvider theme={materialTheme}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  name="premium_amount_valid_from"
                                  size="small"
                                  inputVariant="outlined"
                                  label="Effective From"
                                  format="dd/MM/yyyy"
                                  className={classes.inputWidth}
                                  // minDate={props?.premiumAmountDate
                                  //   ? new Date(props?.premiumAmountDate)
                                  //   : new Date()}
                                  minDate={new Date(props.premiumAmountDate)}
                                  value={!props.premiumAmountDate ? null : new Date(props.premiumAmountDate)}
                                  onChange={props.handleChange("premiumAmountDate")}
                                  placeholder="e.g.: DD/MM/YYYY"
                                  InputAdornmentProps={{ position: "end", }}
                                  InputProps={{
                                    style: {
                                      paddingRight: 0,
                                    },
                                    readOnly: true
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </ThemeProvider>
                            :
                            <span
                              className={classes.tableBodyForPaymentTermData}
                            >
                              ----
                            </span>
                          }
                        </TableCell>
                      </>
                      : null
                    }
                    <TableCell align="right" className={classes.removeOption} />
                  </TableRow>
                </>
              ) : null}
              <div ref={addNewEndRef} />
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
      {paymentTermOrCityTableOperation === "view" ? (
        <ToolTipComponent
          title="You have no create permission on this widget"
          condition={!hasWriteAccess(props.user, 'item_categories')}
        >
          <Button
            variant="outlined"
            size="medium"
            startIcon={<CheckOutlinedIcon />}
            className={classes.addNewButton}
            disabled={!hasWriteAccess(props.user, 'item_categories')}
            onClick={() => {
              handlePaymentTermOrCityTableOperation("add")
              props.displayDataFor === "paymentTerm"
                ? props.handleCancelButton("paymentTerm") :
                props.handleCancelButton("city")
              setTimeout(() => scrollToBottom(), 10)
            }}
          >
            ADD NEW
          </Button>
        </ToolTipComponent>
      ) : (
        <>
          <div className={classes.saveCancelButtonStyles}>
            {console.log(props.selectedPaymentTermId, props.paymentTermDiscountAmount, props.premium_amount)}
            {paymentTermOrCityTableOperation === "edit" ? null : (
              <SaveButton
                size="medium"
                disabled={
                  props.displayDataFor === "paymentTerm"
                    ? (!props.selectedPaymentTermId ||
                      (props.paymentTermDiscountAmount === null &&
                        props.premium_amount === null ||
                        // props.paymentTermDiscountAmountError &&
                        // props.paymentTermPremiumError
                        props.paymentTermDiscountAmount === "" &&
                        props.premium_amount === "")
                      ||
                      (props.effectiveDate === null &&
                        props.premiumAmountDate === null ||
                        props.effectiveDate === "" &&
                        props.premiumAmountDate === "")
                    )
                    :
                    (!props.selectedCityId ||
                      !props.cityDiscountAmount ||
                      !props.cityDiscountAmountError)
                }
                className={classes.saveButton}
                variant="contained"
                startIcon={<CheckOutlinedIcon />}
                onClick={() => {
                  handlePaymentTermOrCityTableSave("view");
                }}
                isLoading={props?.isLoadingPayment}
              >
                Save
              </SaveButton>
            )}
            <Button
              size="medium"
              className={classes.margin}
              onClick={() => {
                if (paymentTermOrCityTableOperation === "edit") {
                  props.fetchItemCategoryPaymentTerm();
                }
                handlePaymentTermOrCityTableOperation("view")
              }
              }
            >
              Cancel
            </Button>
          </div>
        </>
      )}
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </div>
  );
}
