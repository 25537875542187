import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import DashboardOverviewTab from './DashboardOverviewTab';
import ContractBookingTable from './ContractBookingTable';
import DashboardCustomers from './DashboardCustomers';
import DashboardInPlant from './DashboardInPlant';
import { arraySortBykeyAllBrowser, fetchApiDataAndUpdateLocalStorage } from '../../../../methods';
import APIROUTES from '../../../../constants/internal/InternalApiRoutes';

const useStyles = makeStyles((theme) => ({
    root: {
        "&.MuiTab-root": {
            textTransform: "capitalize",
            fontWeight: 700,
            color: "#BBC5D5",
            fontFamily: 'Poppins',
        },
        "&.MuiTab-root.Mui-selected ": {
            color: "#11141A",
            fontWeight: 700,
            fontFamily: 'Poppins',
        },
    },
}));


function MainTabsDisplay(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={classes.root}
        >
            {value === index && (
                <Box sx={{ p: 3, paddingLeft: 0, paddingRight: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

MainTabsDisplay.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const classes = useStyles();

    const [itemCategoryDropdownList, setItemCategoryDropdownList] = useState([]);
    const [plantDropdownList, setPlantDropdownList] = useState([]);

    const handleItemCategoryLogic = (responseData, place = '') => {
        if (place !== 'error') {
            let responseDataSort = arraySortBykeyAllBrowser(responseData, 'category_name');
            setItemCategoryDropdownList(responseDataSort)
        }
    }

    const handlePlantLogic = (responseData, place = '') => {
        if (place !== 'error') {
            let responseDataSort = arraySortBykeyAllBrowser(responseData, 'plant_short_name');
            setPlantDropdownList(responseDataSort)
        }
    }

    const handleCallbackDataFunction = (receivedData, place = '', callBackLogicFunction = '') => {
        if (place === 'error')
            callBackLogicFunction(receivedData, place)
        else
            callBackLogicFunction(receivedData, place)
    }

    useEffect(() => {
        fetchApiDataAndUpdateLocalStorage(
            `${APIROUTES.GET_ITEM_CATEGORY}?active=${true}`,
            `itemCategoryList`,
            handleCallbackDataFunction,
            handleItemCategoryLogic,
        )
        fetchApiDataAndUpdateLocalStorage(
            `${APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST}?active=${true}`,
            `plantList`,
            handleCallbackDataFunction,
            handlePlantLogic,
        )
    }, [])
    const { value, handleChange } = props;
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{
                    style: {
                        borderBottom: `3px solid #FFA101`,

                    },
                }}>
                    <Tab label="Contracts" {...a11yProps(0)} className={classes.root} />
                    <Tab label="Sales" {...a11yProps(1)} className={classes.root} />
                    <Tab label="Customers" {...a11yProps(2)} className={classes.root} />
                    <Tab label="Live Status" {...a11yProps(3)} className={classes.root} />
                </Tabs>
            </Box>
            <MainTabsDisplay value={value} index={0}>
                <Paper style={{ borderRadius: "20px" }}>
                    <ContractBookingTable
                        tabValue={value}
                        itemCategoryDropdownList={itemCategoryDropdownList}
                        plantDropdownList={plantDropdownList}
                        widget='contract_booking_table'
                    />
                </Paper>
            </MainTabsDisplay>
            <MainTabsDisplay value={value} index={1}>
                <DashboardOverviewTab
                    tabValue={value}
                    itemCategoryDropdownList={itemCategoryDropdownList}
                    plantDropdownList={plantDropdownList}
                />
            </MainTabsDisplay>
            <MainTabsDisplay value={value} index={2}>
                <Paper style={{ borderRadius: "20px" }}>
                    <DashboardCustomers
                        tabValue={value}
                        itemCategoryDropdownList={itemCategoryDropdownList}
                        plantDropdownList={plantDropdownList}
                    />
                </Paper>
            </MainTabsDisplay>
            <MainTabsDisplay value={value} index={3}>
                <Paper style={{ borderRadius: "20px" }}>
                    <DashboardInPlant
                        tabValue={value}
                        itemCategoryDropdownList={itemCategoryDropdownList}
                        plantDropdownList={plantDropdownList}
                    />
                </Paper>
            </MainTabsDisplay>
        </Box>
    );
}
