import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { checkAlphabet, checkAlphaNumericLength, checkApiError, pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveButton from "../../common/SaveButton";
// import { Autocomplete } from "@mui/material";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
function ManageReasonData(props) {
  const classes = useStyles();
  // const [error, setError] = React.useState(false);
  const [reasonData, setReasonData] = React.useState(
    props.selectedReasonData || {
      id: null,
      module: null,
      reason_code: null,
      reason: null,
      is_active: false,
    }
  );
  const [reasonError, setReasonError] = React.useState({
    module: false,
    reason_code: false,
    reason: false,
  })
  const [isLoading, setIsLoading] = React.useState(false);
  const [reasonOperation, setReasonOperation] = React.useState(
    props.reasonOperation
  );
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });

  React.useEffect(() => {
    if (props.reasonOperation !== 'add') {
      if (props.selectedReasonData.reason) {
        !checkAlphabet(props.selectedReasonData.reason) ?
          setReasonError(prevValue => { return { ...prevValue, reason: true } }) :
          setReasonError(prevValue => { return { ...prevValue, reason: false } });
      }
      if (props.selectedReasonData.reason_code) {
        !checkAlphaNumericLength(props.selectedReasonData.reason_code) ?
          setReasonError(prevValue => { return { ...prevValue, reason_code: true } }) :
          setReasonError(prevValue => { return { ...prevValue, reason_code: false } });
      }
    }
  }, [props.selectedReasonData, props.reasonOperation]);

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  // const checkReasonName = (input) => {
  //   const regex = /^[a-zA-Z\s]+[-_]?[a-zA-Z0-9\s]+$/;
  //   if (regex.test(input)) {
  //     return true
  //   }
  //   return false;
  // }

  const handleAutocompleteValueChange = (data) => {
    console.log(data)
    if (data) {
      setReasonData({
        ...reasonData,
        ['module']: (props?.module.find((s) => s.name == data))?.id,
      });
      setReasonError(prevValue => {
        return {
          ...prevValue, ['module']: false
        }
      })
    }
    else {
      setReasonError(prevValue => {
        return {
          ...prevValue, ['module']: true
        }
      })
    }
  }

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    console.log(value, "value");
    if (name === "is_active") {
      setReasonData({
        ...reasonData,
        [name]: checked,
      });
    } else {
      setReasonData({
        ...reasonData,
        [name]: value,
      });
      if (!value) {
        setReasonError(prevValue => {
          return {
            ...prevValue, [name]: true
          }
        })
      } else {
        if (name === 'reason') {
          !checkAlphabet(value) ?
            setReasonError(prevValue => { return { ...prevValue, [name]: true } }) :
            setReasonError(prevValue => { return { ...prevValue, [name]: false } })
        } else if (name === 'reason_code') {
          !checkAlphaNumericLength(value) && value.length == 2 ?
            setReasonError(prevValue => { return { ...prevValue, [name]: true } }) :
            setReasonError(prevValue => { return { ...prevValue, [name]: false } })
        }
      }
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };
  const handleEditSaveButtonClick = () => {
    setIsLoading(true)
    if (reasonOperation === "edit") {
      props
        .handleReasonDataUpdate(reasonData)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Reason Detail Updated Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                props.history.goBack()
              }, 3000)
            }
            // props.history.goBack();
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while updating reason details please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (reasonOperation === "add") {
      props
        .handleReasonDataAdd(reasonData)
        .then((response) => {
          console.log("handleReasonDataAdd response : ", response);
          if (response.status === 201) {
            handleOpenSnackbar(
              `Reason Created Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                props.history.push(`${INTERNALROUTES.REASON}/${response.data.id}`)
              }, 3000)
            }
            // props.history.push(`${INTERNALROUTES.REASON}/${response.data.reason}`);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while creating reason master please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };
  React.useEffect(() => {
    setReasonData(props?.selectedReasonData);
    setReasonOperation(props.reasonOperation);
  }, [props?.selectedReasonData, props.reasonOperation]);
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Reason Master",
                onClick: () => props.history.push(INTERNALROUTES.REASON),
              },
              {
                title: reasonOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                MODULE *
              </Typography>
              {console.log(props?.module)}
              <Autocomplete
                autoSelect
                options={props?.module.length > 0 ? props?.module : []}
                getOptionLabel={(option) => option.name}
                value={props?.module.find(
                  (s) => s.id == reasonData.module
                )}
                onChange={(e) => handleAutocompleteValueChange(e.target.innerText)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Module *"
                    // margin="normal"
                    variant="outlined"
                    error={reasonError.module}
                    helperText={reasonError.module ? "Invalid Field" : ""}
                  />
                )}
                variant="outlined"
                className={classes.textField}
                size="small"
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                REASON CODE *
              </Typography>
              <TextField
                label="Reason Code"
                name="reason_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter reason code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                value={reasonData?.reason_code || ""}
                error={reasonError.reason_code}
                helperText={reasonError.reason_code ? "Invalid Code" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                REASON *
              </Typography>
              <TextField
                label="Reason"
                name="reason"
                type="text"
                onChange={handleChange}
                placeholder="Enter reason"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={reasonData?.reason || ""}
                error={reasonError.reason}
                helperText={reasonError.reason ? "Invalid Text" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>IS ACTIVE</Typography>
              <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                <IOSSwitch
                  checked={Boolean(reasonData.is_active)}
                  onChange={handleChange}
                  name="is_active"
                />
              </div>
            </div>
            <SaveButton
              size="medium"
              disabled={
                !reasonData?.module ||
                !reasonData?.reason ||
                !reasonData?.reason_code ||
                reasonError?.module ||
                reasonError.reason ||
                reasonError.reason_code
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {reasonOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManageReasonData);
