import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Ballot from '@material-ui/icons/Ballot';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import ListNavigator from '../../common/ListNavigator';
import AccessDenied from '../../../common/AccessDenied';
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import { pxToEm, isAccessibleWidget } from '../../../../methods';
import INTERNALROUTES from '../../../../constants/internal/InternalRoutes';
import Bread from '../../common/Breadcrubs';

const styles = (theme) => ({
  title: {
    fontSize: pxToEm(18),
  },
  main: {
    width: '100%',
    paddingBottom: '30px',
    overflowY: 'scroll',
  },
});

function UsersAndPermissions(props) {
  const { classes } = props;
  const [navigations, setNavigations] = React.useState([]);

  React.useEffect(() => {
    const { user } = props;
    const {
      UANDP_ROLES_AND_PERMISSIONS,
      UANDP_INTERNAL_USERS,
      UANDP_ACCESS_AREA_MGMT,
    } = INTERNALROUTES;
    const navigations = [];

    if (user) {
      if (isAccessibleWidget(user, 'rolesAndPermissions')) {
        navigations.push({
          title: 'Roles And Permissions',
          subtitle:
            'Create and edit roles and permissions, assign them to internal users',
          path: UANDP_ROLES_AND_PERMISSIONS,
          icon: () => <ViewListOutlinedIcon />,
          loading: props.loadingRoles,
          error: props.errorRoles,
        });
      }
      if (isAccessibleWidget(user, 'internalUsers')) {
        navigations.push({
          title: 'Internal Users',
          subtitle: 'Add new internal users, assign premade roles',
          path: UANDP_INTERNAL_USERS,
          icon: () => <ContactMailOutlinedIcon />,
          loading: props.loadingUsers,
          error: props.errorUsers,
        });
      }
      if (isAccessibleWidget(user, 'accessAreaManagement')) {
        navigations.push({
          title: 'Access Area Management',
          subtitle: 'Manage IP and platform restrictions',
          path: UANDP_ACCESS_AREA_MGMT,
          icon: () => <Ballot />,
          loading: props.loadingModules,
          error: props.errorModules,
        });
      }
    }

    setNavigations(navigations);
  }, [props]);

  return (
    <Layout {...props}>
      {navigations.length > 0 ? (
        <div className={classes.main}>
          <div style={{ width: '100%' }}>
            {/* <Typography className={classes.title}>
              Users And Permissions
            </Typography> */}
            <Bread
              data={[
                {
                  title: 'Users And Permissions',
                },
              ]}
            />
            <ListNavigator {...props} navigations={navigations} />
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </Layout>
  );
}
export default withStyles(styles)(UsersAndPermissions);
