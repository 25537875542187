import React from 'react'

export function Countdown({ startTime, endTime }) {
    const [counter, setCounter] = React.useState(startTime);
    const [timerText, setTimerText] = React.useState(null)

    React.useEffect(() => {

        // const timer =
        //     counter > endTime && setInterval(() => setCounter(counter - 1), 1000);
        // return () => clearInterval(timer);
        timer(counter)
    }, []);

    const timer = (remaining) => {
        var m = Math.floor(remaining / 60);
        var s = remaining % 60;

        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        setTimerText(m + ':' + s)
        remaining -= 1;

        if (remaining >= 0) {
            setTimeout(() => {
                timer(remaining)
            }, 1000);
            return;
        }
    }

    return (
        <div>
            {/* <span> */}
            {/* {`00:${counter > 9 ? counter : `0${counter}`}`} */}
            {timerText}
            {/* </span> */}
        </div>
    );
}