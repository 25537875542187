import React, { Component } from "react";
import BottomNavigation from "../../components/b2b/mobile/BottomNavigation";
import AppBar from "../../components/b2b/mobile/AppBar";
import B2b_URL from "../../constants/b2b/B2bRoutes";
import axios from "axios";
import withUser from "../../hoc/withUser";
import moment from "moment";
import { StaticAppVersion, compareValues } from "../../methods.js";

class MobileLayout extends Component {
  state = {
    notificationLoader: true,
    fetchNextNotification: "",
    userNotifications: [],
    unreadNotifications: 0,
    logoutPopoverText: ""
  };
  componentWillMount() {
    // console.log(window.location.pathname)
    // if (window.location.pathname !== "/b2bNotification") {
    this.fetchNotificationList()
    // }
  }

  fetchNotificationList = () => {
    var dates = [];
    axios
      .get(`${B2b_URL.NOTIFICATIONS}/?V=${StaticAppVersion()}`, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then((response) => {
        // console.log(response)
        let userNotifications = response.data;
        if (userNotifications.length > 0) {
          let unreadNotifications = 0
          userNotifications.map(not => {
            if (!not.isread) {
              unreadNotifications++
            }
          })
          userNotifications.map((item) => {
            if (dates.includes(moment(item.created_at).format("DD MMMM, dddd"))) {
              return;
            } else {
              dates.push(moment(item.created_at).format("DD MMMM, dddd"));
            }
          });
          this.setState({
            notificationLoader: false,
            userNotifications: userNotifications.sort(
              compareValues("created_at", "desc")
            ),
            dates,
            fetchNextNotification: response.data.next,
            unreadNotifications,
          });
        }
        else if (userNotifications && userNotifications.length === 0) {
          this.setState({
            notificationLoader: false,
          })
        }
      })
      .catch(error => {
        // console.log(error.response, error.response?.data?.detail)
        if (error.response.status === 403) {
          this.setState({
            logoutPopoverText: error.response?.data?.detail
          }, () => console.log(error.response?.data?.detail, this.state?.logoutPopoverText))
        }
      })
  }
  render() {
    const {
      bottomNavigation,
      bottomNavSelected,
      appBar,
      handleBack,
      appBarTitle,
      appBarLeftIcon,
      logoutPopoverText
    } = this.props;
    // console.log(this.props)
    const top = appBar && (
      <AppBar
        handleBack={handleBack}
        appBarTitle={appBarTitle}
        appBarLeftIcon={appBarLeftIcon}

      />
    );
    const bottom = bottomNavigation && (
      <BottomNavigation
        bottomNavSelected={bottomNavSelected && bottomNavSelected}
        userNotifications={this.state.userNotifications}
        unreadNotifications={this.state.unreadNotifications}
        notificationLoader={this.state.notificationLoader}
        dates={this.state.dates}
        logoutPopoverText={this.state?.logoutPopoverText || logoutPopoverText}
      // logout={this.props.logout}
      />
    );
    return (
      <div>
        {top}
        {this.props.children}
        {bottom}
      </div>
    );
  }
}
export default withUser(MobileLayout);
