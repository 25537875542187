import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  IconButton,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {
  secondaryGrey,
  grey,
  // successButtonBackground,
  // drawerTextColor,
  primaryColor,
} from "../../../../../constants/internal/colors";
import { pxToEm } from "../../../../../methods";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
// import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";

const columns = [
  { id: "plant", label: "PLANT", minWidth: 300, maxWidth: 400 },
  {
    id: "allotted",
    label: "ALLOTTED",
    align: "right",
    minWidth: 100,
    maxWidth: 160,
  },
  {
    id: "dispatched",
    label: "DISPATCHED",
    align: "right",
    minWidth: 100,
    maxWidth: 150,
  },
  {
    id: "pending",
    label: "PENDING",
    align: "right",
    minWidth: 100,
    maxWidth: 150,
  },
  {
    id: "actions",
    label: "",
    align: "right",
    minWidth: 50,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "calc(100vh - 350px)",
  },
  tableHead: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white,
    minWidth: 160,
  },
  tableHeadAction: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white,
    minWidth: 40,
  },
  addAndSaveButton: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between",
  },
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
}));
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
export default function PlantPlanningTab(props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState(props?.plantPlanningData);
  const [
    showAddNewButtonInPlantPlanning,
    setShowAddNewButtonInPlantPlanning,
  ] = React.useState(props?.showAddNewButtonInPlantPlanning);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setRows(props?.plantPlanningData);
    setShowAddNewButtonInPlantPlanning(props.showAddNewButtonInPlantPlanning);
  }, [props?.plantPlanningData, props.showAddNewButtonInPlantPlanning]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={
                    column.id === "actions"
                      ? classes.tableHeadAction
                      : classes.tableHead
                  }
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(rows)}
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell>
                      <FormControl
                        variant="outlined"
                        className={classes.root}
                        size="small"
                      >
                        <InputLabel id={index}>Select Plant</InputLabel>
                        <Select
                          id={index}
                          name="plant"
                          value={row.plant}
                          onChange={props.handlePlantPlanningDataValueChange(
                            index,
                            "plant"
                          )}
                          MenuProps={MenuProps}
                          label="Select Plant"
                        >
                          {console.log(props?.plantSelectionList)}
                          {props?.plantSelectionList.length > 0
                            ? props?.plantSelectionList.map((option, index) => (
                              <MenuItem
                                value={option.plant_id}
                                style={{ color: "black" }}
                                key={index}
                                disabled={rows.some(r => r.plant === option.plant_id)}

                              >
                                {option.plant_name}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align={"right"}>
                      <TextField
                        type="number"
                        className={classes.root}
                        name="allotted_qty"
                        id={index}
                        label="Allotted"
                        size="small"
                        variant="outlined"
                        value={row.allotted_qty}
                        onChange={props.handlePlantPlanningDataValueChange(
                          index,
                          "allotted_qty"
                        )}
                        onBlur={props.checkAllotedValue(index, "allotted_qty")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span style={{ fontSize: "0.9em", color: grey }}>
                                MT
                              </span>
                            </InputAdornment>
                          ),
                        }}
                        error={row?.allottedError}
                        helperText={
                          row?.allottedError ? row.allottedError : null
                        }
                      />
                    </TableCell>
                    <TableCell align={"right"}>
                      {row.dispached_qty} MT
                    </TableCell>
                    <TableCell align={"right"}>{row.pending_qty} MT</TableCell>
                    <TableCell align={"right"}>
                      <IconButton>
                        <DeleteOutlineOutlinedIcon
                          onClick={() =>
                            props.removeNewPlantPlanningData(index)
                          }
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
      {showAddNewButtonInPlantPlanning ? (
        <div className={classes.addAndSaveButton}>
          <Button
            variant="outlined"
            size="medium"
            startIcon={<AddOutlinedIcon />}
            className={classes.addNewButton}
            onClick={props.addNewPlantPlanningData}
          >
            ADD NEW
          </Button>
        </div>
      ) : null}
    </Paper>
  );
}
