import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Category from "@material-ui/icons/Category";
import Map from "@material-ui/icons/Map";
import Payment from "@material-ui/icons/Payment";
import Business from "@material-ui/icons/Business";
import AddLocation from "@material-ui/icons/AddLocation";
import Loyalty from "@material-ui/icons/Loyalty";
import Speed from "@material-ui/icons/Speed";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Ballot from "@material-ui/icons/Ballot";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import PersonPinCircleOutlinedIcon from "@material-ui/icons/PersonPinCircleOutlined";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import ListNavigator from "../../common/ListNavigator";
import { isAccessibleWidget, pxToEm } from "../../../../methods";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FeedIcon from '@mui/icons-material/Feed';
import Bread from "../../common/Breadcrubs";

const styles = (theme) => ({
    title: {
        fontSize: pxToEm(18),
    },
    main: {
        width: "100%",
        paddingBottom: "30px",
        overflowY: "scroll",
    },
});

class MastersManagementWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerRequestsNavigations: [],
            masterDataNavigations: [],
        };
    }

    componentDidMount() {
        this.initializeNavigations(this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.openRequests !== this.props.openRequests || prevProps?.newRequests !== this.props.newRequests) {
            this.initializeNavigations(this.props);
        }
    }


    showSubInfo = () => {
        return (
            <>
                <div>Number of Open Requests: {this.props.openRequests}</div>
                <div> New Requests Today : {this.props.newRequests}</div>
            </>
        );
    }

    initializeNavigations = (props) => {
        if (props.user) {
            const customerRequestsNavigations = [];
            const masterDataNavigations = [];
            // Daily
            if (isAccessibleWidget(props.user, 'ledger_request')) {
                customerRequestsNavigations.push({
                    title: "Ledger Requests",
                    subtitle: this.showSubInfo(),

                    path: INTERNALROUTES.LISTS_LEDGER_REQUESTS,
                    //loading: props.itemCategoriesDataFetchLoader,
                    // icon: () => <Category />,
                });
            }
            if (isAccessibleWidget(props.user, "complaints_greivances")) {
                customerRequestsNavigations.push({
                    title: "Complaints & Grievances ",
                    subtitle: "Coming Soon",
                    //path: INTERNALROUTES.LISTSTATEMASTER,
                    //loading: props.fetchingState,
                    // icon: () => <Map />,
                });
            }
            if (isAccessibleWidget(props.user, "sales_enquiry")) {
                customerRequestsNavigations.push({
                    title: "Sales Enquiries",
                    subtitle: "Coming Soon",
                    //path: INTERNALROUTES.LIST_PAYMENT_TERMS,
                    //loading: props.paymentTermsDataFetchLoader,
                    // icon: () => <Payment />,
                });
            }
            this.setState({ customerRequestsNavigations });
        }
    };

    render() {
        const { classes, isMobile } = this.props;
        const { customerRequestsNavigations, masterDataNavigations } = this.state;
        return (
            <Layout {...this.props}>
                <div className={classes.main}>
                    <div style={{ width: "100%" }}>
                        {/* <Typography className={classes.title}>
                Master Management
              </Typography> */}
                        <Bread
                            data={[
                                {
                                    title: 'Customer Requests',
                                },
                            ]}
                        />
                        <>
                            <ListNavigator
                                {...this.props}
                                // classes={delete this.props.classes.main}
                                navigations={customerRequestsNavigations}
                                module="customerRequests"
                                isMobile={isMobile}
                            />
                        </>
                    </div>
                </div>
            </Layout>
        );
    }
}
export default withStyles(styles)(MastersManagementWeb);
