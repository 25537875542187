import React from 'react';
import {
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItem,
  Divider,
  List,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { secondaryBlueText } from '../../../constants/internal/colors';
import { pxToEm } from '../../../methods';

const styles = (theme) => ({
  root: {
    marginTop: 19,
    width: '100%',
    maxWidth: '100%',
    color: secondaryBlueText,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridColumnGap: "10px",
    gridRowGap: "10px",
  },
  tile: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px"
  },
  title: {
    fontSize: pxToEm(18),
  },
  color: {
    color: secondaryBlueText,
    fontSize: pxToEm(15),
  },
  subtitle: {
    fontSize: pxToEm(12),
  },
});

function ListNavigator({ classes, history, navigations, module, isMobile }) {
  return (
    <List className={classes.root}
      style={{
        display: isMobile && 'flex',
        flexDirection: isMobile && 'column',
        alignItems: isMobile && 'center',
      }}>
      {navigations.map((e, index) => (
        <ListItem
          key={`${index}_list`}
          button
          onClick={(evt) => {
            evt.preventDefault();
            history.push(e.path);
          }}
          className={classes.tile}
          style={{
            width: isMobile && '95%'
          }}
        >
          {e.icon
            ? <ListItemAvatar>{e.icon()}</ListItemAvatar>
            : <p style={{ minWidth: 56 }}></p>
          }
          <ListItemText
            component="span"
            primary={
              <Typography className={classes.color}>{e.title}</Typography>
            }
            secondary={
              <>
                <Typography component="span" className={classes.subtitle}>
                  {e.loading
                    ? e.error === ''
                      ? 'Fetching..'
                      : 'Error while fetching data.'
                    : e.subtitle}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
export default withStyles(styles)(ListNavigator);
