import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import RemoveModeratorOutlinedIcon from '@mui/icons-material/RemoveModeratorOutlined';
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
// import CustomerApprovalNotNeeded from "@material-ui/icons/NoAccounts";

import {
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  IconButton,
  Button,
  Typography
} from "@material-ui/core";
import {
  grey,
  successButtonBackground,
  cancelButtonBackground,
  orange,
  rejectedColor,
  successButtonBackgroundHover,
  earthBlue,
} from "../../../../../constants/internal/colors";
import { withRouter } from "react-router";
import moment from "moment";
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import { pxToEm, hasWriteAccess, hasApproveAccess, hasReadAccess } from '../../../../../methods';
import ToolTipComponent from "../../../common/ToolTipComponent";
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});
const styles = {
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    paddingTop: "0px",
  },
  tableContainerStyle: {
    height: "calc(100vh - 350px)",
    overflowY: "scroll",
  },
  container: {
    maxHeight: "45vh",
  },
  iconsDiv: {
    display: "flex",
    width: "100px",
    alignItems: "center",
    justifyContent: "center",
  },
  gray: {
    color: "#666"
  },
  blue: {
    color: "#3B69B0"
  },
  orange: {
    color: "#FFAE42"
  },
  green: {
    color: "#4CAF50"
  },
  adminIcon: (approvalNeeded, approved) => ({
    color: approvalNeeded
      ? approved
        ? successButtonBackground
        : orange
      : grey,
  }),
  clientIcon: (approvalNeeded, approved) => ({
    color: approvalNeeded
      ? approved
        ? successButtonBackground
        : approved === false
          ? rejectedColor
          : orange
      : grey,
  }),
  plantPlanningIcon: (plantPlanning) => ({
    color: plantPlanning ? successButtonBackground : grey
  }),
  statusIcon: (status) => ({
    color: ["CANCELLED", "CLOSED"].includes(status)
      // ? cancelButtonBackground
      ? rejectedColor
      : null,
    transform: status === "CANCELLED"
      ? 'scale(1.2)'
      : 'scale(1.1)'
  }),
};

class CustomPaginationActionsTable extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      rows: this?.props?.rows,
      totalRows: this.props?.totalOrderCount,
      rowsPerPage: 20,
      open: false,
      rowId: -1,
      brokerage: null,
      new_brokerage: null,
      currentPage: '',
      isOrderNoCopied: false,
      copiedOrderNo: ""
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      rows: this?.props?.rows || [],
      totalRows: this.props.totalOrderCount
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.totalOrderCount !== this.props.totalOrderCount) {
      this.setState({
        totalRows: this.props.totalOrderCount
      })
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  static getDerivedStateFromProps(props, state) {
    if (props?.rows !== state?.rows) {
      return { rows: props?.rows };
    }

    return null;
  }

  onCopyText = (orderNo) => {
    navigator.clipboard.writeText(orderNo)
    this.setState({ copiedOrderNo: orderNo })
    this.setState(prevValue => { return { ...prevValue, isOrderNoCopied: !prevValue.isOrderNoCopied } })
    setTimeout(() => {
      this.setState({
        isOrderNoCopied: false,
      });
    }, 1000);
  };

  handleChangePage = (event, page) => {
    console.log(this.props.page, page)
    console.log(this.props.searchDataNext, this.props.searchDataPrev)
    if (page > this.props.page) {
      this.props.fetchNextOrders(this.props.searchDataNext, page)
    }
    else if (page < this.props.page) {
      this.props.fetchNextOrders(this.props.searchDataPrev, page)
    }
  };


  handleOrderApprove = (orderId) => {
    let orderData = {
      is_approved_by_admin: true,
    };
    this.props.approveOrder(orderData, orderId, this.props.tabValue);
  };

  handleClose = () => {
    this.setState({
      open: false,
      rowId: -1,
      brokerage: null,
      new_brokerage: null,
    });
  };

  handleAgree = () => {
    this.handleOrderApprove(this.state.rowId);
    this.setState({
      open: false,
      rowId: -1,
      brokerage: null,
      new_brokerage: null,
    });
  };

  handleConfirmDialog = (rowId, brokerage, new_brokerage) => {
    if (brokerage !== new_brokerage) {
      this.setState({
        open: true,
        rowId: rowId,
        brokerage: brokerage,
        new_brokerage: new_brokerage,
      });
    } else {
      this.handleOrderApprove(rowId);
    }
  };

  render() {
    const { classes, page, } = this.props;
    const { rows, rowsPerPage } = this.state;

    return (
      <Paper className={classes.root} style={{ boxShadow: "none" }}>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to approve?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Brokerage Rate has been changed from {this.state.brokerage} to{" "}
              {this.state.new_brokerage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleAgree} color="primary" autoFocus>
              Approve
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.tableWrapper}>
          <TableContainer className={classes.tableContainerStyle} id="scroll">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    CONTRACT NO.
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    CUSTOMER
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    DATE
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "0%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    REGION
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "10.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    RATE
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "12.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    BOOK
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    PENDING
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "0%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    APPROVE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length > 0
                  ? rows
                    // .slice(
                    //   page * rowsPerPage,
                    //   page * rowsPerPage + rowsPerPage
                    // )
                    .map((row) => (
                      <TableRow
                        style={{ cursor: "pointer" }}
                        key={row.id}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   this.props.history.push(
                      //     `/internalContracts/singleContracts/${row.id}`
                      //   );
                      // }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ padding: "8px" }}
                        >
                          <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            marginLeft: (row.conversion_by !== null || row.conversion_of !== null) ? 5 : 30
                          }}>
                            {(row.conversion_by !== null || row.conversion_of !== null) &&
                              <span>
                                {/* <RepeatOutlinedIcon style={{ paddingBottom: 6, paddingTop: 2 }} /> */}
                                <SwapHorizontalCircleOutlinedIcon style={{ paddingBottom: 6, }} />
                              </span>
                            }
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                if (hasReadAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)) {
                                  this.props.history.push({
                                    pathname: `/internalContracts/singleContracts/${row.id}`,
                                    state: { isApproved: row.is_approved }
                                  });
                                }
                              }}
                            >{row.order_number}</span>
                            <span onClick={() => this.onCopyText(row?.order_number)}>
                              {this.state.isOrderNoCopied && this.state.copiedOrderNo === row?.order_number ? (
                                <Typography
                                  style={{
                                    fontSize: pxToEm(14),
                                    height: 24,
                                    width: 24,
                                    fontWeight: "normal",
                                    color: "#FFB600",
                                    marginLeft: 2,
                                  }}
                                >
                                  Copied!
                                </Typography>
                              ) : (
                                <ContentCopyOutlinedIcon
                                  style={{
                                    padding: '0 0 5px 0',
                                    // transform: 'scale(1)',
                                    marginLeft: 4,
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                            </span>
                          </div>
                          <p
                            className={
                              row.order_status === "HOLD"
                                ? classes.orange
                                : classes.gray
                            }
                            style={{
                              fontSize: pxToEm(12),
                              margin: '0',
                              textDecoration: "underline",
                              fontWeight: "400",
                              cursor: 'pointer'
                            }}
                          >
                            {row.order_status === 'CONVERSION_ALLOTMENT_HOLD'
                              ? 'ALLOTMENT HOLD'
                              : row.order_status
                            }
                          </p>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "8px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (hasReadAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)) {
                              this.props.history.push({
                                pathname: `/internalContracts/singleContracts/${row.id}`,
                                state: { isApproved: row.is_approved }
                              });
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              {row?.customer?.first_name ||
                                "" + " " + row?.customer?.last_name ||
                                ""}
                            </span>
                            <span style={{ color: grey }}>
                              {row?.customer?.phone ?
                                String(row?.customer?.phone).length === 10 ?
                                  `+91 ${row?.customer?.phone}`
                                  : `+${row?.customer?.phone}`
                                : ""}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "8px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (hasReadAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)) {
                              this.props.history.push({
                                pathname: `/internalContracts/singleContracts/${row.id}`,
                                state: { isApproved: row.is_approved }
                              });
                            }
                          }}
                        >
                          {!row?.created_at
                            ? ""
                            : moment(row?.created_at).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "8px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (hasReadAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)) {
                              this.props.history.push({
                                pathname: `/internalContracts/singleContracts/${row.id}`,
                                state: { isApproved: row.is_approved }
                              });
                            }
                          }}
                        >
                          {row?.customer?.state || ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "8px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (hasReadAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)) {
                              this.props.history.push({
                                pathname: `/internalContracts/singleContracts/${row.id}`,
                                state: { isApproved: row.is_approved }
                              });
                            }
                          }}
                        >
                          ₹ {parseInt(row?.rate || 0)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ padding: "8px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (hasReadAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)) {
                              this.props.history.push({
                                pathname: `/internalContracts/singleContracts/${row.id}`,
                                state: { isApproved: row.is_approved }
                              });
                            }
                          }}
                        >
                          {parseFloat(row?.booked_quantity || 0).toFixed(3)} MT
                        </TableCell>
                        <TableCell align="center" style={{ padding: "8px" }}>
                          {parseFloat(row?.order_available_quantity || 0).toFixed(3)} MT
                        </TableCell>
                        <TableCell align="center" style={{ padding: "8px" }}>
                          <div className={classes.iconsDiv}>
                            {row.order_status === "CANCELLED"
                              ?
                              <GppBadOutlinedIcon
                                style={styles.statusIcon(row.order_status)}
                              />
                              :
                              <VerifiedUserOutlinedIcon
                                style={styles.adminIcon(
                                  row.is_approved_by_admin,
                                  row.is_approved
                                )}
                              />
                            }
                            {/* {!row.is_customer_approval_needed ? (
                                <CustomerApprovalNotNeeded
                                  style={styles.clientIcon(
                                    row.is_customer_approval_needed,
                                    row.is_approved_by_customer
                                  )}
                                />
                              ) : ( */}
                            <AccountCircleOutlinedIcon
                              style={styles.clientIcon(
                                row.is_customer_approval_needed,
                                row.is_approved_by_customer
                              )}
                            />
                            <BusinessOutlinedIcon
                              style={styles.plantPlanningIcon(
                                row.plant_planning
                              )}
                            />
                            {/* )} */}
                          </div>
                        </TableCell>
                        <TableCell align="center" style={{ padding: "8px" }}>
                          {row.is_approved && row.is_approved_by_admin && row.order_status !== "PENDING"
                            ? null
                            : row.order_status === "CANCELLED"
                              ? null
                              : (
                                <ToolTipComponent
                                  title="You have no approve permission on this widget"
                                  condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                  title2='Plant planning not done'
                                  condition2={!row.plant_planning}
                                  title3='Customer approval needed'
                                  condition3={row.is_customer_approval_needed && !row.is_approved_by_customer}
                                >
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color={successButtonBackground}
                                    className={classes.margin}
                                    style={{
                                      fontSize: row?.created_by.includes("Customer") && pxToEm(14),
                                      padding: !row?.created_by.includes("Customer") && '4px 16px'
                                      // fontWeight: row?.created_by.includes("Customer") && '600'
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // this.handleOrderApprove(row.id);
                                      row?.created_by.includes("Customer")
                                        ? (this.props.history.push(
                                          {
                                            pathname: `/internalContracts/editContracts/${row?.id}`,
                                            state: {
                                              orderStatus: row?.order_status,
                                              orderCreatedBy: "Customer"
                                            }
                                          }))
                                        :
                                        (this.handleConfirmDialog(
                                          row.id,
                                          row.brokerage,
                                          row.new_brokerage
                                        ))
                                    }}
                                    disabled={hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) && row.plant_planning && !(row.is_approved_by_admin && row.order_status === "PENDING") ? false : true}
                                  >
                                    {
                                      row?.created_by.includes("Customer")
                                        ? "Review & Approve"
                                        : "Approve"
                                    }
                                    {/* APPROVE */}
                                  </Button>
                                </ToolTipComponent>
                              )}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20]}
            colSpan={3}
            count={this.state.totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            component="div"
          />

        </div>
      </Paper>
    );
  }
}

// CustomPaginationActionsTable.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
export default withRouter(withStyles(styles)(CustomPaginationActionsTable));
