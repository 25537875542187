import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
  secondaryColor,
} from "../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = {
  registerPanAddressBody: {
    height: "90%",
    display: "flex",
    padding: "30px 50px 20px 50px",
    maxHeight: "85%",
    overflowY: "scroll",
    alignItems: "center",
    flexDirection: "column",
  },
  registerPanAddressTop: {
    height: "90px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "15px",
  },
  registerPanAddressSecondTop: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  registerPanAddressFormContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  textFieldStyle: {
    width: "100%",
  },
  formControlStyle: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
};

class RegistrationPanAddressWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId: null,
      stateId: null,
    };
  }
  handleSelectCountry = (id) => {
    this.setState({ countryId: id }, () => {
      this.props.getStatesFromMasterData(id);
    });
  };
  handleStateCountry = (id) => {
    this.setState({ stateId: id }, () => {
      this.props.getCityFromMasterData(id);
    });
  };
  handleSelectCity = (id) => {
    this.props.handleSelectCity(id);
  };
  render() {
    const { countryId, panAdressPincodeLengthError } = this.state;
    const {
      classes,
      panAdressPincode,
      handleTextChange,
      countries,
      states,
      stateId,
      cities,
      panNumber,
    } = this.props;
    return (
      <div className={classes.registerPanAddressBody}>
        <div className={classes.registerPanAddressTop}>
          <span style={{ color: dailyRateScreenTextColor, fontSize: "1.2em" }}>
            Address Detail
          </span>
          <span style={{ color: bottomNavigationFontColor }}>
            You can add multiple address later in setting
          </span>
        </div>
        <div className={classes.registerPanAddressSecondTop}>
          <span style={{ color: bottomNavigationFontColor, fontSize: "1.1em" }}>
            Kindly add the address linked with
          </span>
          <span style={{ color: secondaryColor, fontSize: "1.3em" }}>
            PAN: {panNumber}
          </span>
        </div>
        <div className={classes.registerPanAddressFormContainer}>
          <TextField
            name="panAddressLineOne"
            id="outlined-name"
            label="Address Line 1"
            margin="normal"
            variant="outlined"
            multiline
            maxRows={3}
            className={classes.textFieldStyle}
            onChange={handleTextChange}
            inputProps={{
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
          />
          <TextField
            name="panAddressLineTwo"
            id="outlined-name"
            label="Address Line 2 (Optional)"
            margin="normal"
            variant="outlined"
            multiline
            maxRows={3}
            className={classes.textFieldStyle}
            onChange={handleTextChange}
            inputProps={{
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
          />
          <TextField
            name="panAddressLineThree"
            id="outlined-name"
            label="Address Line 3 (Optional)"
            margin="normal"
            variant="outlined"
            multiline
            maxRows={3}
            className={classes.textFieldStyle}
            onChange={handleTextChange}
            inputProps={{
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
          />

          <FormControl
            variant="outlined"
            className={classes.formControlStyle}
            size="small"
          >
            <InputLabel htmlFor="age-simple">Select Country</InputLabel>
            <Select label="Select Country">
              {countries.map((option) => (
                <MenuItem
                  value={option.name}
                  onClick={(e) => {
                    e.preventDefault();

                    this.handleSelectCountry(option.id);
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            className={classes.formControlStyle}
            size="small"
            disabled={countryId === null}
          >
            <InputLabel htmlFor="age-simple">Select State</InputLabel>
            <Select label="Select State">
              {states.map((option) => (
                <MenuItem
                  value={option.name}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleStateCountry(option.id);
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            className={classes.formControlStyle}
            size="small"
            disabled={stateId || countryId === null}
          >
            <InputLabel htmlFor="age-simple">Select City</InputLabel>
            <Select label="Select City">
              {cities.map((option) => (
                <MenuItem
                  value={option.name}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleSelectCity(option.id);
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name="panAdressPincode"
            id="outlined-name"
            label="Pincode"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
            onChange={handleTextChange}
            inputProps={{
              maxLength: 6,
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            onBlur={() => {
              if (panAdressPincode.length < 6) {
                this.setState({ panAdressPincodeLengthError: true });
              }
            }}
            onFocus={() => {
              this.setState({ panAdressPincodeLengthError: false });
            }}
            helperText={
              <span style={{ color: "red" }}>
                {panAdressPincodeLengthError
                  ? "Please enter valid 6 digit pincode"
                  : null}
              </span>
            }
            error={panAdressPincodeLengthError ? true : false}
          />
        </div>
        <Dialog
          open={this.props.noInternetConnection}
          onClose={this.props.noInternetConnectionDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: secondaryColor }}
          >
            {"Connection error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Unable to contact server. Please check your internet connection
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.noInternetConnectionDialog}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(RegistrationPanAddressWeb);
