import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import telephone from "../../internal/dispatchOperation/telephone.json"
import { truncate } from "lodash";
import { ToWords } from 'to-words';
import { numberConvertor } from "../../../methods";


pdfMake.vfs = pdfFonts.pdfMake.vfs;



const handlePrintSubcontractOverviewTable = (subcontractId, action) => {

    axios
        .get(`${APIROUTES.GET_SUBCONTRACT_PDF}${subcontractId}/`)
        .then((response) => {
            if (response.status === 200) {
                console.log(response)
                let tableData = [];
                let taxationTableData = [];
                let footerContainer = []

                if (
                    Number(
                        response.data?.total_values.sgst_amount
                    ) >
                    Number(response.data?.total_values.igst_amount)
                ) {
                    taxationTableData.push([
                        {
                            text: "SGST :",
                            border: [false, false, false, false],
                            style: "fontSevenRight",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, false],

                            text: Number(response.data?.total_values.sgst_percent) + "%",
                            style: "taxDetailCenter",
                            width: "30%",
                        },
                        {
                            text: response.data?.total_values
                                .sgst_amount.toFixed(2),
                            border: [false, false, false, false],
                            // style: "fontEightRight",
                            style: "fontSevenRight",
                            width: "30%",
                        },
                    ]);
                    taxationTableData.push([

                        {
                            text: "CGST :",
                            border: [false, false, false, false],
                            style: "fontSevenRight",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, false],
                            text: Number(response.data?.total_values.cgst_percent) + "%",
                            style: "taxDetailCenter",
                            width: "30%",
                        },
                        {
                            // text: this?.state?.total_cgst_value || "",
                            text: response.data?.total_values
                                .cgst_amount.toFixed(2),
                            border: [false, false, false, false],
                            // style: "fontEightRight",
                            style: "fontSevenRight",
                            width: "30%",
                        },
                    ]);
                } else {
                    if (response.data?.total_values?.igst_amount !== 0) {
                        taxationTableData.push([
                            {
                                text: "IGST :",
                                border: [false, false, false, false],
                                style: "fontSevenRight",
                                width: "40%",
                            },
                            {
                                border: [false, false, false, false],
                                text: Number(response.data?.total_values.igst_percent) + "%",
                                style: "taxDetailCenter",
                                width: "30%",
                            },
                            {
                                // text: this?.state?.total_igst_value,
                                text: response.data?.total_values
                                    .igst_amount,
                                border: [false, false, false, false],
                                // style: "fontEightRight",
                                style: "fontSevenRight",
                                width: "30%",
                            },
                        ]);
                    } else {
                        taxationTableData.push([
                            {
                                text: "",
                                border: [false, false, false, false],
                                style: "taxDetailCenter",
                                width: "40%",
                            },
                            {
                                text: "",
                                border: [false, false, false, false],
                                style: "fontSixRight",
                                width: "30%",
                            },
                            {
                                text: "",
                                border: [false, false, false, false],
                                style: "fontSevenRight",
                                width: "30%",
                            },
                        ])
                    }
                }

                taxationTableData.push([

                    {
                        text: "TCS :",
                        border: [false, false, false, false],
                        style: "fontSevenRight",
                        width: "40%",
                    },
                    {
                        border: [false, false, false, false],
                        text: Number(response.data?.total_values.tcs_percent) + "%",
                        style: "taxDetailCenter",
                        width: "30%",
                    },
                    {
                        text: `${parseFloat(
                            response.data?.total_values?.tcs_amount || 0
                        ).toFixed(2)}`,
                        border: [false, false, false, false],
                        style: "fontSevenRight",
                        width: "30%",
                    },
                ]);
                taxationTableData.push([

                    {
                        text: "FREIGHT ADVANCE :",
                        border: [false, false, false, false],
                        style: "fontSevenRight",
                        width: "40%",
                    },
                    {
                        border: [false, false, false, false],
                        text: ``,
                        style: "taxDetailCenter",
                        width: "30%",
                    },
                    {
                        // text: parseFloat(this?.state?.total_freight_amount || 0).toFixed(2),
                        text: `${Number(response.data?.total_values?.freight_advance_amount) || 0}`,
                        border: [false, false, false, false],
                        // style: "fontEightRight",
                        style: "fontSevenRight",
                        width: "30%",
                    },
                ]);
                taxationTableData.push([

                    {
                        text: "CESS :",
                        border: [false, false, false, false],
                        style: "fontSevenRight",
                        width: "40%",
                    },
                    {
                        border: [false, false, false, false],
                        text: `${Number(response.data?.cess_rate) || 0
                            } /MT`,
                        style: "taxDetailCenter",
                        width: "30%",
                    },
                    {
                        text: `${parseFloat(
                            response.data?.total_values?.cess_amount || 0
                        ).toFixed(2)}`,
                        border: [false, false, false, false],
                        style: "fontSevenRight",
                        width: "30%",
                    },
                ]);
                taxationTableData.push([

                    {
                        text: "ROUND OFF :",
                        border: [false, false, false, false],
                        style: "fontSevenRight",
                        width: "40%",
                    },
                    {
                        border: [false, false, false, false],
                        text: "",
                        style: "fontSevenCenter",
                        width: "30%",
                    },
                    {
                        text: response.data?.total_values
                            ?.round_off?.toFixed(2),
                        border: [false, false, false, false],
                        style: "fontSevenRight",
                        width: "30%",
                    },

                ]);


                tableData.push([
                    {
                        text: "Sr. No.",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    {
                        text: "Item Code",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    {
                        text: "Description and Speci. Of Goods",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    {
                        text: "HSN/SAC No.",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    // {
                    //     text: "No. of Packages",
                    //     style: ["itemsHeader", "center"],
                    //     border: [false, false, false, true],
                    // },
                    {
                        text: "Item Qty.",
                        style: ["itemsHeaderAmnt", "center"],
                        border: [false, false, false, true],
                    },

                    {
                        text: "Balance Qty.",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    // {
                    //     text: "Actual No. of Packages",
                    //     style: "itemsHeader",
                    //     border: [false, false, false, true],
                    // },
                    {
                        text: "Unit",
                        style: "itemsHeaderUnit",
                        border: [false, false, false, true],
                    },
                    {
                        text: "Rate Difference",
                        style: "itemsHeader",
                        border: [false, false, false, true],
                    },
                    {
                        text: "Net Rate(₹)",
                        style: "itemsHeader",
                        alignment: "right",
                        border: [false, false, false, true],
                    },

                    // {
                    //     text: `Freight Charge (${response.data?.freight_charge_rate} /MT)`,
                    //     style: "itemsHeader",
                    //     border: [false, false, false, true],
                    // },
                    {
                        text: "Amount(₹)",
                        style: "itemsHeaderAmnt",
                        alignment: "right",
                        border: [false, false, false, true],
                    },
                ]);

                var dd = {
                    info: {
                        title: `${response.data.sub_contract_number ? response.data.sub_contract_number : ""
                            }_${new Date().toISOString()}`,
                        author: "RIPL",
                        subject: "Loading Advice",
                        keywords: "Loading Advice",
                    },
                    pageMargins: [15, 5, 15, 50],
                    // footer: {
                    //     columns: [
                    //         'Left partalso indknsssssssssssssssssss',
                    //         { text: 'Right partjnadkandknnnnnnnnnnn', }
                    //     ],
                    //     margin: [15, 0, 0, 0]
                    // },
                    footer: {
                        stack: footerContainer,
                        margin: [15, 0, 0, 0]
                    },


                    // background: {
                    //   // image: AegleBase64,
                    //   // width: 200,
                    //   text: ">",
                    //   // opacity: 0.05,
                    //   absolutePosition: { x: 5, y: 250 },
                    // },
                    background: function (currentPage, pageSize) {

                        return {
                            // image: AegleBase64,
                            // width: 200,
                            text: "<-",
                            absolutePosition: { x: 5, y: pageSize.height / 2 },
                        }
                    },
                    content: [
                        "\n",
                        {
                            columns: [
                                {
                                    // image: 'https://i.pinimg.com/originals/71/fc/47/71fc472790aa43f44555d08f4d4904c2.jpg',
                                    // fit: [100, 100],
                                    // margin: [0, 0, 0, 5],
                                    // width: 100,
                                    // height: 10
                                },
                                {
                                    text: "Subcontract Detail",
                                    style: "fontSeventeenCenter",
                                    margin: [0, 0, 0, 5],
                                    width: 200
                                },
                                {

                                    // margin: [0, 0, 0, 5],
                                    // width: 100
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    style: "tableExample",
                                    table: {
                                        headerRows: 1,
                                        widths: [50, 50, 50, 50],
                                        body: [
                                            [
                                                {
                                                    text: "Details of Recipient (Billed to)",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `${response.data.buyer.first_name
                                                        ? response.data.buyer.first_name
                                                        : ""
                                                        } ${response.data.buyer.last_name
                                                            ? response.data.buyer.last_name
                                                            : ""
                                                        }`,
                                                    style: "buyerName",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text:
                                                        response.data?.buyer?.customer_addresses?.address ?
                                                            (
                                                                (response.data?.buyer?.customer_addresses?.address
                                                                    ? response.data?.buyer?.customer_addresses?.address + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.city
                                                                    ? response.data?.buyer?.customer_addresses?.city + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.state
                                                                    ? response.data?.buyer?.customer_addresses?.state + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.country
                                                                    ? response.data?.buyer?.customer_addresses?.country + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.pincode
                                                                    ? response.data?.buyer?.customer_addresses?.pincode
                                                                    : "")
                                                            )
                                                            :
                                                            (
                                                                (response.data?.buyer?.gst_addresses?.door_number
                                                                    ? response.data?.buyer?.gst_addresses?.door_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.floor_number
                                                                    ? response.data?.buyer?.gst_addresses?.floor_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.building_name
                                                                    ? response.data?.buyer?.gst_addresses?.building_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.street
                                                                    ? response.data?.buyer?.gst_addresses?.street + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.location
                                                                    ? response.data?.buyer?.gst_addresses?.location + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.district_name
                                                                    ? response.data?.buyer?.gst_addresses?.district_name + ", "
                                                                    : "")
                                                                +
                                                                ((response.data?.buyer?.gst_addresses?.city_name &&
                                                                    response.data?.buyer?.gst_addresses?.district_name)
                                                                    ? ((response.data?.buyer?.gst_addresses?.city_name).trim().toUpperCase() !==
                                                                        (response.data?.buyer?.gst_addresses?.district_name).trim().toUpperCase())
                                                                        ? response.data?.buyer?.gst_addresses?.city_name + ", "
                                                                        : ""
                                                                    : response.data?.buyer?.gst_addresses?.city_name
                                                                        ? response.data?.buyer?.gst_addresses?.city_name + ", "
                                                                        : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.state_name
                                                                    ? response.data?.buyer?.gst_addresses?.state_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.pincode
                                                                    ? response.data?.buyer?.gst_addresses?.pincode
                                                                    : "")
                                                            ),
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],

                                            [
                                                {
                                                    text: `STATE CODE : ${response.data.buyer?.gst_addresses?.state_code
                                                        ? response.data.buyer?.gst_addresses?.state_code?.substring(0, 2)
                                                        : response.data.buyer?.customer_addresses?.state_code?.substring(0, 2) || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `GSTIN : ${response.data.buyer.gstin_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `PAN : ${response.data.buyer.pan_card_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                                {
                                                    text: `MOBILE NO: ${response.data?.buyer
                                                        ?.customer_addresses?.contact ||
                                                        response.data?.buyer
                                                            ?.gst_addresses?.contact
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                            ],
                                            // Consignee block start
                                            [
                                                {
                                                    text: "Consignee (Ship to)",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `${response.data.consignee.first_name || ""
                                                        } ${response.data.consignee.last_name || ""}`,
                                                    style: "buyerName",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text:
                                                        response.data?.consignee?.consignee_from_customer_addresses?.address ?
                                                            (
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.address
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.address + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.city
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.city + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.state
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.state + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.country
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.country + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.pincode
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.pincode
                                                                    : "")
                                                            )
                                                            :
                                                            (
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.door_number
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.door_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.floor_number
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.floor_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.building_name
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.building_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.street
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.street + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.location
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.location + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.district_name
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.district_name + ", "
                                                                    : "")
                                                                +
                                                                ((response.data?.consignee?.consignee_from_gst_information_addresses?.city_name &&
                                                                    response.data?.consignee?.consignee_from_gst_information_addresses?.district_name)
                                                                    ? ((response.data?.consignee?.consignee_from_gst_information_addresses?.city_name).trim().toUpperCase() !==
                                                                        (response.data?.consignee?.consignee_from_gst_information_addresses?.district_name).trim().toUpperCase())
                                                                        ? response.data?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                                                                        : ""
                                                                    : response.data?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                                        ? response.data?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                                        : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.state_name
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.state_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.pincode
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.pincode
                                                                    : "")
                                                            ),
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `STATE CODE : ${response.data.consignee?.consignee_from_gst_information_addresses?.state_code
                                                        ? response.data.consignee?.consignee_from_gst_information_addresses?.state_code?.substring(0, 2)
                                                        : response.data.consignee?.consignee_from_customer_addresses?.state_code?.substring(0, 2) || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `GSTIN : ${response.data.consignee.gstin_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `PAN : ${response.data.consignee.pan_card_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `MOBILE NO: ${response.data?.consignee
                                                        ?.consignee_from_customer_addresses?.contact ||
                                                        response.data?.consignee
                                                            ?.consignee_from_gst_information_addresses?.contact || ''
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Email : ${response.data?.consignee
                                                        ?.consignee_from_customer_addresses?.email ||
                                                        response.data?.consignee
                                                            ?.consignee_from_gst_information_addresses?.email || ''
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            // broker block
                                            [
                                                {
                                                    text: " ",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Broker Name : ${response.data.order.broker || ""}`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Contract : ${response.data.order?.order_number || ""}`,
                                                    // style: "invoiceDetailLable",
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    // border: [false, false, false, false],
                                                    border: [true, false, false, true],
                                                    // fillColor: "#f5f5f5",
                                                },
                                                {},
                                                {
                                                    text: `DATE : ${moment(
                                                        response.data.order?.created_at,
                                                    ).format("DD-MMM-YYYY") || ""
                                                        }`,
                                                    // style: "invoiceDetailLable",
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    // border: [false, false, false, false],
                                                    border: [false, false, true, true],
                                                    // fillColor: "#f5f5f5",
                                                },
                                            ],

                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },
                                {
                                    margin: [10, 5, 0, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [70, 70],
                                        body: [
                                            //Customer block
                                            [
                                                {
                                                    text: "Customer",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: ``,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `${response.data.customer.first_name
                                                        ? response.data.customer.first_name
                                                        : ""
                                                        } ${response.data.customer.last_name
                                                            ? response.data.customer.last_name
                                                            : ""
                                                        }`,
                                                    style: "buyerName",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},

                                            ],
                                            [
                                                {
                                                    text:
                                                        response.data?.customer?.customer_addresses?.address ?
                                                            (
                                                                (response.data?.customer?.customer_addresses?.address
                                                                    ? response.data?.customer?.customer_addresses?.address + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.city
                                                                    ? response.data?.customer?.customer_addresses?.city + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.state
                                                                    ? response.data?.customer?.customer_addresses?.state + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.country
                                                                    ? response.data?.customer?.customer_addresses?.country + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.pincode
                                                                    ? response.data?.customer?.customer_addresses?.pincode
                                                                    : "")
                                                            )
                                                            :
                                                            (
                                                                (response.data?.customer?.customer_addresses?.door_number
                                                                    ? response.data?.customer?.customer_addresses?.door_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.floor_number
                                                                    ? response.data?.customer?.customer_addresses?.floor_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.building_name
                                                                    ? response.data?.customer?.customer_addresses?.building_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.street
                                                                    ? response.data?.customer?.customer_addresses?.street + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.location
                                                                    ? response.data?.customer?.customer_addresses?.location + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.district_name
                                                                    ? response.data?.customer?.customer_addresses?.district_name + ", "
                                                                    : "")
                                                                +
                                                                ((response.data?.customer?.customer_addresses?.city_name &&
                                                                    response.data?.customer?.customer_addresses?.district_name)
                                                                    ? ((response.data?.customer?.customer_addresses?.city_name).trim().toUpperCase() !==
                                                                        (response.data?.customer?.customer_addresses?.district_name).trim().toUpperCase())
                                                                        ? response.data?.customer?.customer_addresses?.city_name + ", "
                                                                        : ""
                                                                    : response.data?.customer?.customer_addresses?.city_name
                                                                        ? response.data?.customer?.customer_addresses?.city_name + ", "
                                                                        : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.state_name
                                                                    ? response.data?.customer?.customer_addresses?.state_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.customer?.customer_addresses?.pincode
                                                                    ? response.data?.customer?.customer_addresses?.pincode
                                                                    : "")
                                                            ),
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},

                                            ],
                                            [
                                                {
                                                    text: `CUSTOMER TYPE : ${response.data.customer?.customer_type}`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `STATE CODE : ${response.data.customer?.gst_addresses?.state_code
                                                        ? response.data.customer?.gst_addresses?.state_code?.substring(0, 2)
                                                        : response.data.customer?.customer_addresses?.state_code || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `GSTIN : ${response.data.customer.gstin_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},

                                            ],
                                            [
                                                {
                                                    text: `PAN : ${response.data.customer.pan_card_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `MOBILE NO: ${response.data?.customer
                                                        ?.customer_addresses?.contact ||
                                                        response.data?.customer
                                                            ?.gst_addresses?.contact
                                                        || ""}`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                            ],
                                            [

                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],

                                            [
                                                {
                                                    text: "Payment Terms :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.payment_term || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `FROM :`,
                                                    style: "invoiceDetailLable",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {
                                                    text: `${response.data.plant_city || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `TO :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.consignee?.consignee_from_customer_addresses?.city ||
                                                        (response.data?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                            || response.data?.consignee?.consignee_from_gst_information_addresses?.district_name || '')
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `Place Of Supply :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.buyer.customer_addresses.city ||
                                                        (response.data?.buyer
                                                            ?.gst_addresses?.city_name || response.data?.buyer
                                                                ?.gst_addresses?.district_name || '')
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },
                                {
                                    margin: [-55, 5, 0, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [120, 120],
                                        body: [

                                            [
                                                {
                                                    text: `PLANT : ${response.data.plant_name || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `SUBCONTRACT NO : ${response.data?.sub_contract_number}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `SUBCONTRACT DATE :${response.data?.created_at && moment(response.data?.created_at
                                                    ).format("DD-MMM-YYYY") || ""} `,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `SUBCONTRACT STATUS :${response.data?.status}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `PAYMENT DOC VALUE : ${response.data?.payment_doc_value || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `PAYMENT DOC NO : ${response.data?.payment_doc_number || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `PAYMENT DOC DATE : ${response.data?.payment_doc_date && moment(response.data?.payment_doc_date
                                                    ).format("DD-MMM-YYYY") || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `PAYMENT DOC VALID UPTO :${response.data?.payment_doc_valid_upto && moment(response.data?.payment_doc_valid_upto
                                                    ).format("DD-MMM-YYYY") || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `PAYMENT BANK NAME: ${response.data?.payment_bank || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            // [
                                            //     {
                                            //         text: `DRIVER'S NAME :`,
                                            //         style: "invoiceDetailLable",
                                            //         border: [false, false, false, false],
                                            //         fillColor: "#f5f5f5",
                                            //     },
                                            // ],
                                            // [
                                            //     {
                                            //         text: `DRIVER'S NO. :`,
                                            //         style: "invoiceDetailLable",
                                            //         border: [false, false, false, false],
                                            //         fillColor: "#f5f5f5",
                                            //     },
                                            // ],
                                            // [
                                            //     {
                                            //         text: `DRIVER'S LICENCE NO. :`,
                                            //         style: "invoiceDetailLable",
                                            //         border: [false, false, false, false],
                                            //         fillColor: "#f5f5f5",
                                            //     },
                                            // ],

                                            // [
                                            //     {
                                            //         text: `MODE OF TRANSPORT :`,
                                            //         style: "invoiceDetailLable",
                                            //         border: [false, false, false, false],
                                            //         fillColor: "#f5f5f5",
                                            //     },
                                            // ],
                                            [
                                                {
                                                    text: `FREIGHT:${response.data?.freight_basis || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],

                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },

                            ],
                        },
                        {
                            margin: [0, 0, 0, 0],
                            table: {
                                headerRows: 1,
                                widths:
                                    // [25, 85, 45, 55, 35, 45, 35, 45, 50, 55],
                                    //[25, 90, 45, 55, 35, 35, 35, 55, 50, 45],
                                    [25, 40, 90, 50, 40, 40, 30, 40, 50, 65],
                                body: tableData,
                            },
                        },
                        console.log(response.data),
                        response?.data?.sub_contract_item?.map((e, i) => ({
                            margin: [0, 0, 0, 0],
                            table: {
                                widths:
                                    // [25, 85, 45, 55, 35, 35, 35, 45, 50, 55],
                                    //[25, 90, 45, 55, 35, 35, 35, 55, 50, 45],
                                    [25, 40, 90, 50, 40, 40, 30, 40, 50, 65],
                                body: [
                                    [
                                        {
                                            text: i + 1,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: `${e.item.item_code}`,
                                            style: "itemSubTitle",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: `${e.item.item_name}`,
                                            style: "itemSubTitle",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: `${e.item.hsn_code}`,
                                            style: "codeStyle",
                                            border: [false, false, false, false],
                                        },
                                        // {
                                        //     text: "",
                                        //     // `${e.actual_section_count ? (Number(e.actual_section_count).toFixed(0) + " " + e.section_name) : ""}`,
                                        //     style: "codeStyle",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            text: `${Number(e.allotted_qty).toFixed(3)}`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },

                                        {
                                            text:
                                                `${Number(e.available_qty).toFixed(3)}`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        // {
                                        //     text: `${e.actual_section_count ? (Number(e.actual_section_count).toFixed(0) + " " + e.section_name) : ""}`,
                                        //     style: "itemNumber",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            text: `MT`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: `${Number(e.item?.rate_difference).toFixed(2)}`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: `${e.item_rate == 0 ? "" : e.item_rate}`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },

                                        // {
                                        //     text:
                                        //         // `${Number((Number(response.data?.freight_charge_rate).toFixed(2)) * Number(e.alloted_weight) > 0 ? (Number(e.alloted_weight).toFixed(2)) : (Number(e.item_qty).toFixed(2))).toFixed(2)}`,
                                        //         `${Number(e.alloted_weight) > 0 ? (Number(response.data?.freight_charge_rate).toFixed(2) * Number(e.alloted_weight).toFixed(2)).toFixed(2) :
                                        //             (Number(response.data?.freight_charge_rate).toFixed(2) * Number(e.item_qty).toFixed(2)).toFixed(2)}`,
                                        //     style: "itemNumber",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            style: "amountNumber",
                                            text: `${Number(e.item_taxable_amount).toFixed(2)}`,
                                            border: [false, false, false, false],
                                        },
                                    ],
                                ],
                            },
                        })),
                        // {
                        //     columns: [
                        //         {
                        //             text: " ",
                        //             style: "fontSixLeft",
                        //             margin: [0, 0, 0, 5],
                        //             border: [false, false, true, false],
                        //         },
                        //     ],
                        // },

                        {
                            //margin: [5, 0, 0, 0],

                            table: {
                                margin: [5, 0, 0, 0],
                                headerRows: 0,
                                widths: [52, 170, 40, 30, 140, 73],
                                //heights: [40, 40, 40],
                                body: [
                                    [
                                        {
                                            text: `Amount in words :`,
                                            border: [true, true, false, true],
                                            style: "fontSixLeft",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text:
                                                `${numberConvertor(
                                                    response.data.total_values?.taxable_amount, "",
                                                    true)}`
                                            ,

                                            border: [false, true, false, true],
                                            style: "fontSixLeft",
                                            margin: [-8, 0, 0, 0],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${response.data.total_values?.alloted_weight.toFixed(3)}`,
                                            border: [false, true, false, true],
                                            style: "itemNumberRightAligned",
                                            //margin: [12, 0, 0, 0],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${response?.data?.sub_contract_item.reduce((acc, v) => acc + v?.available_qty, 0).toFixed(3)}`,
                                            border: [false, true, false, true],
                                            style: "itemNumberRightAligned",
                                            //margin: [12, 0, 0, 0],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `Total Value of Supply:`,
                                            border: [false, true, false, true],
                                            style: "fontSevenRight",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${response.data.total_values?.taxable_amount.toFixed(2)}`,
                                            border: [false, true, true, true],
                                            style: "fontSevenRight",
                                            fillColor: "#f5f5f5",
                                        },
                                    ],
                                ],
                            }
                        },
                        {
                            margin: [0, 0, 0, 0],

                            columns: [
                                {
                                    text: `Total Tax Value (in words) : ${("",
                                        response.data?.total_values
                                            ?.sgst_amount >
                                            response.data?.total_values
                                                ?.igst_amount
                                            ? numberConvertor((Number(
                                                response.data?.total_values?.sgst_amount
                                            ) +
                                                Number(
                                                    response.data?.total_values?.cgst_amount
                                                )
                                            ).toFixed(2), "", true)
                                            : numberConvertor(
                                                response.data?.total_values
                                                    ?.igst_amount,
                                                "",
                                                true
                                            ))
                                        }.`,
                                    // style: "fontEightLeft",
                                    style: "fontSixLeft",
                                    margin: [0, 5, 10, 5],
                                },
                                {
                                    margin: [0, 0, 5, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [65, "*", 60],
                                        body: taxationTableData,
                                    },
                                },
                            ],
                        },

                        // {
                        //     margin: [0, 0, 0, 0],

                        //     columns: [
                        //         {
                        //             text: `PAYMENT TERMS : ${response.data?.payment_term || ""}`,
                        //             style: "fontSixLeft",
                        //             // style: "fontSevenLeft",
                        //             margin: [0, 0, 0, 5],
                        //         },
                        //     ],
                        // },

                        {
                            margin: [0, 0, 0, 0],
                            table: {
                                //margin: [5, 0, 0, 0],
                                headerRows: 0,
                                widths: [70, 337, 55, 62],
                                //heights: [40, 40, 40],
                                body: [
                                    [
                                        {
                                            text: "Total Amount in words :",
                                            border: [true, true, false, true],
                                            bold: true,
                                            style: "fontSixLeft",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            border: [false, true, false, true],
                                            text: `${numberConvertor(
                                                response.data?.total_values
                                                    ?.total_item_value,
                                                "",
                                                true
                                            )}`,
                                            style: "fontSixLeft",
                                            margin: [-13, 0, 0, 0],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            border: [false, true, false, true],
                                            text: "Total Amount ₹",
                                            style: "fontSevenLeft",
                                            // style: "fontSevenLeft",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            border: [false, true, true, true],
                                            text: response.data?.total_values
                                                ?.total_item_value?.toFixed(2) || "",
                                            style: "fontSevenRight",
                                            fillColor: "#f5f5f5",
                                        },
                                    ],
                                ],
                            }
                        },
                        // {
                        //     columns: [
                        //         {
                        //             text: "This document is computer-generated and does not require signature.",
                        //             style: "itemNumber",
                        //             margin: [0, 20, 0, 20],
                        //         },
                        //     ],
                        // },
                        {
                            columns: [
                                {
                                    text: " ",
                                    style: "fontSixLeft",
                                    margin: [0, 0, 0, 5],
                                    border: [false, false, true, false],
                                },
                            ],
                        },

                        {
                            margin: [10, 5, 0, 0],
                            table: {
                                headerRows: 0,
                                widths: [70, 70],
                                body: [
                                    [
                                        {
                                            text: `DISPATCH DETAILS `,
                                            style: "invoiceDetailLableOverview",
                                            border: [false, false, false, false],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: ``,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `SUBCONTRACT TOTAL QTY `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${Number(response.data?.dispatch_detail?.sub_contract_qty) || 0} MT`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `RUNNING LA QTY `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${Number(response.data?.dispatch_detail?.pending_approved_la) +
                                                Number(response.data?.dispatch_detail?.in_plant_qty) || 0} MT`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "DISPATCHED QTY",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${Number(response.data?.dispatch_detail?.dispatched_la) || 0} MT`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "BALANCE QTY ",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, true],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${Number(response.data?.dispatch_detail?.available_qty) || 0} MT`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, true],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                ],
                            },
                            layout: {
                                hLineColor: function (i, node) {
                                    return "#f5f5f5";
                                },
                                vLineColor: function (i, node) {
                                    return "#f5f5f5";
                                },
                            },
                        },

                        {
                            margin: [182, -73, 0, 0],
                            table: {
                                headerRows: 0,
                                widths: [70, 70],
                                body: [
                                    [
                                        {
                                            text: `DISCOUNT DETAILS `,
                                            style: "invoiceDetailLableOverview",
                                            border: [false, false, false, false],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: ``,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `CUSTOMER `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.discounts?.find(d => d?.discount_type == 'CUSTOMER')?.discount_amount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `CITY `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.discounts?.find(d => d?.discount_type == 'CITY')?.discount_amount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "PAYMENT BASIS",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.discounts?.find(d => d?.discount_type == 'PAYMENT')?.discount_amount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "SPECIAL DISCOUNT ",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, true],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.discounts?.find(d => d?.discount_type == 'SPECIAL')?.discount_amount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, true],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "TOTAL DISCOUNT ",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, true],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.total_values?.total_discount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, true],
                                            fillColor: "#f5f5f5",
                                        },
                                    ],

                                ],
                            },
                            layout: {
                                hLineColor: function (i, node) {
                                    return "#f5f5f5";
                                },
                                vLineColor: function (i, node) {
                                    return "#f5f5f5";
                                },
                            },
                        },

                        {
                            margin: [354, -73, 0, 0],
                            table: {
                                headerRows: 0,
                                widths: [80, 70],
                                body: [
                                    [
                                        {
                                            text: `TOTAL DISCOUNTED RATE `,
                                            style: "invoiceDetailLableOverview",
                                            border: [false, false, false, false],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: ``,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `CONTRACT RATE `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.order?.rate}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `PAYMENT BASIS PREMIUM `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.discounts?.find(d => d?.discount_type == 'PAYMENT_PREMIUM')?.discount_amount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "LOADING PREMIUM",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.discounts?.find(d => d?.discount_type == 'LOADING_PREMIUM')?.discount_amount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "INSURANCE PREMIUM ",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.discounts?.find(d => d?.discount_type == 'INSURANCE_PREMIUM')?.discount_amount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: "FREIGHT RATE ",
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.freight_charge_rate}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, false],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `ALL DISCOUNTS `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, true],
                                            //fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.total_values?.total_discount || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, true],
                                            //fillColor: "#f5f5f5",
                                        },
                                    ],
                                    [
                                        {
                                            text: `DISCOUNTED RATE `,
                                            style: "invoiceDetailLable",
                                            border: [true, false, false, true],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `₹ ${response.data?.net_rate || 0}`,
                                            style: "invoiceValuesLabel",
                                            color: "#333333",
                                            border: [false, false, true, true],
                                            fillColor: "#f5f5f5",
                                        },
                                    ],

                                ],
                            },
                            layout: {
                                hLineColor: function (i, node) {
                                    return "#f5f5f5";
                                },
                                vLineColor: function (i, node) {
                                    return "#f5f5f5";
                                },
                            },


                        },

                        // {
                        //     margin: [0, 15, 0, 0],
                        //     table: {
                        //         //margin: [5, 0, 0, 0],
                        //         headerRows: 0,
                        //         widths: [340, 0, 0, 0],
                        //         //heights: [40, 40, 40],
                        //         body: [
                        //             [
                        //                 {
                        //                     text: `REMARK : ${response.data?.remark || ""
                        //                         }\n\nLC NUMBER:${response.data.lc_number || ""}
                        //                                     \nLC DATE:${response.data.lc_date || ""}
                        //                                     \nLC BANK NAME:${""}
                        //                                     \nLC BANK ADDRESS:${""}`,
                        //                     border: [true, true, true, true],
                        //                     bold: true,
                        //                     style: "fontSixLeft",
                        //                     fillColor: "#f5f5f5",
                        //                 },

                        //             ],
                        //         ],
                        //     }
                        // },


                    ],
                    styles: {
                        tableExample: {
                            margin: [0, 5, 0, 5],
                        },
                        // Taxation Data
                        fontSixLeft: {
                            alignment: "left",
                            fontSize: 6,
                            margin: [0, 0, 0, 0],
                        },
                        fontSixRight: {
                            alignment: "right",
                            fontSize: 6,
                            margin: [0, 0, 0, 0],
                        },
                        fontSixCenter: {
                            alignment: "center",
                            fontSize: 6,
                            margin: [0, 3, 0, 3],
                        },
                        fontSevenLeft: {
                            alignment: "left",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        fontSevenRight: {
                            alignment: "right",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        taxDetailCenter: {
                            alignment: "center",
                            fontSize: 7,
                        },
                        fontSevenCenter: {
                            alignment: "center",
                            fontSize: 7,
                            margin: [0, 3, 0, 3],
                        },
                        fontSeventeenCenter: {
                            alignment: "center",
                            fontSize: 17,
                            margin: [0, 3, 0, 3],
                        },
                        fontEightRight: {
                            alignment: "right",
                            fontSize: 8,
                            margin: [0, 0, 0, 0],
                            bold: true,
                        },
                        fontEightLeft: {
                            alignment: "left",
                            fontSize: 8,
                            bold: true,
                            margin: [0, 0, 0, 0],
                        },
                        buyerName: {
                            bold: true,
                            color: "#333333",
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                            margin: [0, 0, 0, 3],
                        },
                        buyerAddressDetails: {
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                        },
                        // Invoice Detail
                        invoiceDetailLable: {
                            color: "#333333",
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                            //justifyContent: "space-between"
                        },
                        invoiceDetailLableOverview: {
                            color: "#333333",
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                            bold: true
                        },
                        invoiceValuesLabel: {
                            color: "#333333",
                            fontSize: 6,
                            alignment: "right",
                        },
                        itemsHeader: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            //alignment: "center",
                        },
                        itemsHeaderAmnt: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "center",
                        },
                        itemsHeaderUnit: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "center",
                        },
                        itemsHeaderSubTitle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "left",
                        },
                        itemHeaderRightAligned: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "right",
                        },
                        itemSubTitle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                        },
                        OEValuestyle: {
                            fontSize: 8,
                            margin: [1, 1, 0, 0],
                        },
                        OEValueFooterStyle: {
                            fontSize: 8,
                            margin: [0, 1, 0, 0],
                        },
                        OEstyle: {
                            fontSize: 10,
                        },
                        itemNumber: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "center",
                        },
                        codeStyle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                        },
                        itemNumberStyle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "center",
                        },
                        amountNumber: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "right",
                        },
                        itemNumberBelow: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            alignment: "center",
                            borderColor: "#fff"
                        },
                        itemNumberRightAligned: {
                            margin: [10, 0, 0, 0],
                            // fontSize: 8,
                            fontSize: 6.5,
                            alignment: "left",
                        },
                        itemRowEvenNumber: {
                            margin: [0, 2, 0, 2],
                            fontSize: 8,
                            alignment: "center",
                        },
                        tableHeader: {
                            fontSize: 9,
                            bold: true,
                        },
                        tableDetailValues: {
                            fontSize: 9,
                        },
                    },

                };
                footerContainer.push([

                    {
                        columns: [
                            // {
                            //     width: "11%",
                            //     text: "",
                            //     style: "OEstyle"
                            // },
                            // {
                            //     margin: [0, 0, 2, 0],
                            //     columns: [
                            //         {
                            //             width: "20%",
                            //             text: ``,
                            //             style: "OEstyle"
                            //         },
                            //         {
                            //             image: "",
                            //             width: 9,
                            //             height: 9,
                            //             margin: [1.5, 1.5, 0, 0],
                            //             alignment: "left",
                            //             border: [false, false, false, false],
                            //         },
                            //         {
                            //             width: "2%",
                            //             text: " "
                            //         },
                            //         {
                            //             style: "OEValuestyle",
                            //             width: "70%",
                            //             text: ``
                            //         }
                            //     ]
                            // },
                            {
                                margin: [16, 0, 0, 0],
                                columns: [
                                    // {
                                    //     width: "33%",
                                    //     text: `Prepared By :`,
                                    //     style: "OEValuestyle"
                                    // },

                                    {
                                        style: "OEValueFooterStyle",
                                        //width: "60%",
                                        alignment: "center",
                                        text: ` Prepared By : ${response.data?.created_by || ""}`,
                                    }
                                ]
                            },
                            // {
                            //     margin: [12, 0, 0, 0],
                            //     columns: [
                            //         {
                            //             width: "33%",
                            //             text: `Approved By :`,
                            //             style: "OEValuestyle"
                            //         },

                            //         {
                            //             style: "OEValueFooterStyle",
                            //             width: "60%",
                            //             text: ``,
                            //         }
                            //     ]
                            // },


                        ]
                    },
                    { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },
                    {
                        text: "This is computer generated subcontract detail",
                        alignment: "center",
                        fontSize: 7,
                        margin: [0, 2, 0, 0]
                    }
                ])
                if (action === "PRINT") {
                    pdfMake.createPdf(dd).open();
                } else {
                    pdfMake
                        .createPdf(dd)
                        .download(
                            `${response.data?.sub_contract_number}_${new Date().toISOString()}.pdf`
                        );
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export {
    handlePrintSubcontractOverviewTable
}
