import React, { Component } from "react";
import "../../../../styles/Admin/AdminProfileChangePassword.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { bottomNavigationFontColor } from "../../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import MuiAlert from '@mui/material/Alert';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import COLORS from "../../../../constants/internal/colors";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    adminProfileChangePasswordWebMainBody: {
        // paddingTop: "80px",
        // height: window.innerHeight,
        background:
            "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
        opacity: 1,
        width: "100%",
    },
    adminProfileChangePasswordWebInternalBody: {
        height: "100%",
        padding: "40px 0px 20px 0px",
    },
    myProfileButtonStyle: {
        textTransform: "capitalize",
        fontSize: "1.1em",
        color: "#1B388B",
    },
    textFieldStyle: {
        width: "92%",
        backgroundColor: "white",
    },
};

class AdminProfileChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            changePassword: this.props?.location?.props?.changePassword,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            newPasswordLengthError: false
        };
    }
    handleTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
        if (event.target.name === "newPassword") {
            !this.isPasswordValid(event.target.value) ?
                this.setState({ newPasswordLengthError: true, }) : this.setState({ newPasswordLengthError: false, })
        }
    };

    handleClickShowOldPassword = () => {
        this.setState({
            showOldPassword: !this.state.showOldPassword,
        })
    }

    handleClickShowNewPassword = () => {
        this.setState({
            showNewPassword: !this.state.showNewPassword,
        })
    }

    handleClickShowConfirmPassword = () => {
        this.setState({
            showConfirmPassword: !this.state.showConfirmPassword,
        })
    }

    isPasswordValid = (value) => {
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=[a-zA-Z0-9]*[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
        // const regex = /^(?=([a-zA-Z]*|[0-9]*)+[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
        //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)” + “(?=.*[-+_!@#$%^&*., ?]).+$/;
        if (regex.test(value)) {
            console.log("should be true")
            return true;
        } else {
            console.log("still invalid")
            return false;
        }
    };

    handleChangePassword = () => {
        if (navigator.onLine === false) {
            this.setState({
                noInternetConnection: true,
                open: true,
                Transition: "up",
            });
        } else {
            // console.log(this.props, this.props?.location?.props)
            this.props?.location?.props?.changePassword(this.state.oldPassword, this.state.newPassword)
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({ open: true, Transition: "up" }, () => {
                            setTimeout(() => {
                                if (this.props.history.length > 2)
                                    this.props.history.goBack();
                                else
                                    this.props.history.replace("/userProfile");
                            }, 3000);
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.setState({ oldPasswordError: true });
                    }
                });
        }
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    render() {
        const { classes } = this.props;
        const {
            newPasswordLengthError,
            confirmPasswordLenghtError,
            passwordNotMatch,
            oldPasswordError,
            noInternetConnection,
            handleClickShowPassword,
            showOldPassword,
            showNewPassword,
            showConfirmPassword,


        } = this.state;
        return (
            <Layout {...this.props}>

                <div className={classes.adminProfileChangePasswordWebMainBody}>
                    <div className={classes.adminProfileChangePasswordWebInternalBody}>
                        <div className="admin-profile-change-password-web-header-button-container">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push("/userProfile");
                                }}
                                size="small"
                                className={classes.myProfileButtonStyle}
                            >
                                My Profile
                            </Button>
                            <ChevronRight style={{ color: "#A3A3A3" }} />
                            <span style={{ color: "#A3A3A3" }}>Change Password</span>
                        </div>
                        <br />
                        <div className="admin-profile-change-password-web-form-container">
                            <TextField
                                name="oldPassword"
                                required
                                id="outlined-adornment-password"
                                label="Old Password"
                                margin="normal"
                                variant="outlined"
                                type={showOldPassword ? "text" : "password"}
                                className={classes.textFieldStyle}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowOldPassword}
                                            >
                                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>

                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { fontSize: "1.1em", marginTop: "0px" },
                                }}
                                onChange={(e) => this.handleTextChange(e)}
                                onFocus={() => {
                                    this.setState({ oldPasswordError: false });
                                }}
                                helperText={
                                    <span style={{ color: "red" }}>
                                        {oldPasswordError ? "Old Password Is Invalid" : null}
                                    </span>
                                }
                                error={oldPasswordError ? true : false}
                            />
                            <TextField
                                name="newPassword"
                                required
                                id="outlined-adornment-password"
                                label="New Password"
                                margin="normal"
                                variant="outlined"
                                type={showNewPassword ? "text" : "password"}
                                className={classes.textFieldStyle}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowNewPassword}
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>

                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { fontSize: "1.1em", marginTop: "0px" },
                                }}
                                onChange={(e) => this.handleTextChange(e)}
                                error={newPasswordLengthError ? true : false}
                                helperText={
                                    <span style={{ color: "red" }}>
                                        {newPasswordLengthError
                                            ? "Password must be 8 to 15 characters in length with one lowercase,one numeric value and one special character!"
                                            : ""}
                                    </span>
                                }
                            />
                            <div
                                style={{
                                    width: "92%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        color: bottomNavigationFontColor,
                                        fontSize: "0.8em",
                                    }}
                                >
                                    Password strength
                                </span>
                                <span
                                    style={{
                                        backgroundColor:
                                            this.state.newPassword &&
                                                this.state.newPassword.length >= 2 &&
                                                this.state.newPassword.length < 4
                                                ? "#e68948"
                                                : this.state.newPassword &&
                                                    this.state.newPassword.length >= 4 &&
                                                    this.state.newPassword.length < 7
                                                    ? "#4986c2"
                                                    : this.state.newPassword &&
                                                        this.state.newPassword.length >= 7
                                                        ? "#c6d430"
                                                        : "gainsboro",
                                        width: "13%",
                                        height: "10px",
                                        border: "1px solid #9E9E9E",
                                        borderRadius: "10px",
                                    }}
                                />
                                <span
                                    style={{
                                        backgroundColor:
                                            this.state.newPassword &&
                                                this.state.newPassword.length >= 3 &&
                                                this.state.newPassword.length < 5
                                                ? "#e68948"
                                                : this.state.newPassword &&
                                                    this.state.newPassword.length >= 5 &&
                                                    this.state.newPassword.length < 8
                                                    ? "#4986c2"
                                                    : this.state.newPassword &&
                                                        this.state.newPassword.length >= 8
                                                        ? "#c6d430"
                                                        : "gainsboro",
                                        width: "13%",
                                        height: "10px",
                                        border: "1px solid #9E9E9E",
                                        borderRadius: "10px",
                                    }}
                                />
                                <span
                                    style={{
                                        backgroundColor:
                                            this.state.newPassword &&
                                                this.state.newPassword.length >= 4 &&
                                                this.state.newPassword.length < 6
                                                ? "#e68948"
                                                : this.state.newPassword &&
                                                    this.state.newPassword.length >= 6 &&
                                                    this.state.newPassword.length < 8
                                                    ? "#4986c2"
                                                    : this.state.newPassword &&
                                                        this.state.newPassword.length >= 8
                                                        ? "#c6d430"
                                                        : "gainsboro",
                                        width: "13%",
                                        height: "10px",
                                        border: "1px solid #9E9E9E",
                                        borderRadius: "10px",
                                    }}
                                />
                                <span
                                    style={{
                                        backgroundColor:
                                            this.state.newPassword &&
                                                this.state.newPassword.length >= 5 &&
                                                this.state.newPassword.length < 6
                                                ? "#e68948"
                                                : this.state.newPassword &&
                                                    this.state.newPassword.length >= 6 &&
                                                    this.state.newPassword.length < 8
                                                    ? "#4986c2"
                                                    : this.state.newPassword &&
                                                        this.state.newPassword.length >= 8
                                                        ? "#c6d430"
                                                        : "gainsboro",
                                        width: "13%",
                                        height: "10px",
                                        border: "1px solid #9E9E9E",
                                        borderRadius: "10px",
                                    }}
                                />
                            </div>
                            <TextField
                                name="confirmPassword"
                                required
                                id="outlined-name"
                                label="Confirm Password"
                                margin="normal"
                                variant="outlined"
                                type={showConfirmPassword ? "text" : "password"}
                                className={classes.textFieldStyle}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowConfirmPassword}
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>

                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { fontSize: "1.1em", marginTop: "0px" },
                                }}
                                onChange={this.handleTextChange}
                                onFocus={() => {
                                    this.setState({
                                        confirmPasswordLenghtError: false,
                                        passwordNotMatch: false,
                                    });
                                }}
                                onBlur={() => {
                                    if (this.state.confirmPassword.length < 8) {
                                        this.setState({ confirmPasswordLenghtError: true });
                                    }
                                    if (
                                        this.state.newPassword.length >= 8 &&
                                        this.state.confirmPassword.length >= 8 &&
                                        this.state.newPassword !== this.state.confirmPassword
                                    ) {
                                        this.setState({ passwordNotMatch: true });
                                    }
                                }}
                                error={
                                    confirmPasswordLenghtError
                                        ? true
                                        : passwordNotMatch
                                            ? true
                                            : false
                                }
                                helperText={
                                    <span style={{ color: "red" }}>
                                        {confirmPasswordLenghtError
                                            ? "Password must be 8 to 15 characters in length with one lowercase,one numeric value and one special character!"
                                            : passwordNotMatch
                                                ? "Password did not match"
                                                : null}
                                    </span>
                                }
                            />
                            <br />
                            <Button
                                disabled={
                                    this.state.oldPassword === "" ||
                                    this.state.newPassword.length < 8 ||
                                    this.state.confirmPassword.length < 8 ||
                                    this.state.newPassword !== this.state.confirmPassword
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        noInternetConnection: false,
                                    });
                                    this.handleChangePassword();
                                }}
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: this.state.oldPassword === "" ||
                                        this.state.newPassword.length < 8 ||
                                        this.state.confirmPassword.length < 8 ||
                                        this.state.newPassword !== this.state.confirmPassword ? COLORS.grey : COLORS.primaryColor,
                                    fontWeight: "bold",
                                    color: "white",
                                    width: "92%",
                                    padding: "12px 16px",
                                }}
                            >
                                Save
                            </Button>
                            <br />
                            <Button
                                color="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.goBack();
                                }}
                            >
                                Cancel
                            </Button>
                            <br />
                        </div>
                    </div>
                    <Snackbar
                        autoHideDuration={3000}
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={this.state.Transition}
                        ContentProps={{
                            "aria-describedby": "message-id",
                        }}>

                        <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                            {noInternetConnection
                                ? "No Internet Connection"
                                : "Password has been changed"}
                        </Alert>

                    </Snackbar>

                </div>
            </Layout>
        );
    }
}
export default withStyles(styles)(AdminProfileChangePassword);
