import React, { Component } from 'react'
import { Button, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import {
    primaryColor,
    drawerTextColor,
    bottomNavigationFontColor
} from "../../../../constants/internal/colors";
// import { checkValidFloatValue } from '../../../../../methods';
import { checkValidFloatValue, isValidVehicleNumber } from '../../../../methods';
import { ClickAwayListener } from '@mui/base';
import Moment from 'moment'
import APIROUTES from "../../../../constants/internal/InternalApiRoutes"
import axios from 'axios';
import FilterByCustomerOrBroker from './filterComponents/FilterByCustomerOrBroker';
import FilterByPaymentMethod from './filterComponents/FilterByPaymentMethod';
import FilterByQuantityRange from './filterComponents/FilterByQuantityRange';
import FilterBypaymentDueDate from './filterComponents/FilterByPaymentDueDate';
import FilterByLAStatus from './filterComponents/FilterByLAStatus'
import FilterByLAQTY from './filterComponents/FilterByLAQTY';
import FilterByVehicle from './filterComponents/FilterByVehicle';
import FilterByTransporter from './filterComponents/FilterByTransporter';
import FilterByPaymentStatus from './filterComponents/FilterByPaymentStatus';


const dialogStyles = {
    dialogTop: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "20px 26px",
        height: "10%",
        alignItems: "center",
    },
    dialogContentContainer: {
        padding: "0px 10px",
        textAlign: "center",
        display: "flex",
        // justifyContent: "center",
        flexDirection: "column",
        // alignItems: "center",
        fontSize: "1.7em",
        // backgroundColor: "red",
        height: "80%",
        overflowY: "scroll",
        position: "relative",
        // paddingBottom: "10%",

    },
    dialogButtonContainer: {
        display: "flex",
        width: "100%",
        margin: '25px 0'
        // backgroundColor: "blue",
        // bottom: "0px"
    },
    yesButtonStyle: {
        // width: "78px",
        backgroundColor: primaryColor,
        padding: "8px 24px",
    },
    goBackButton: {
        color: primaryColor,
        textTransform: "capitalize",
        marginLeft: 10
    },

    HorizontalDivider: {
        width: "100%",
        height: "4%",
        background: bottomNavigationFontColor,
    },
    fieldValueStyle: {
        display: "flex"
    }
}

export class FIlters extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filters: [],
            contact: "",
            customerSearchOptionEmpty: false,
            customerList: [],
            brokerList: [],
            buyerList: [],
            openCustomerListPopper: false,
            openBuyerListPopper: false,
            openBrokerListPopper: false,
            selectedPaymentMethodFilter: null,
            selectedPaymentStatusFilter: null,
            selectedItemFilter: null,
            selectedBrokerName: "",
            selectedCustomerName: "",
            selectedBuyerName: "",
            minQuantity: null,
            maxQuantity: null,
            paymentDueDate: null,
            selectedPaymentMethodFilterData: null,
            selectedPaymentStatusFilterData: null,
            selectedItemFilterData: null,
            brokerFilterOpen: true,
            customerFilterOpen: true,
            quantityRangeFilterOpen: true,
            paymentMethodFilterOpen: true,
            paymentDueDateFilterOpen: true,
            itemNameFilterOpen: true,
            selectedBrokerFilter: null,
            selectedCustomerFilter: null,
            selectedBuyerFilter: null,
            selectedFilters: null,
            selectedCustomerFilterData: null,
            selectedBuyerFilterData: null,
            selectedBrokerFilterData: null,
            selectedLAStatusFilter: [],
            selectedLAStatusFilterData: null,
            vehicleNumberValidationError: false,
            vehicleNumber: null,
            laQTY: null,
            contractQTY: null,
            selectedTransporterFilter: null,
            selectedTransporterFilterData: null,
            disableLAStatus: false



        }
    }

    componentDidMount() {
        const { selectedFilters,
            paymentMethods,
            paymentStatusList,
            selectedBrokerFilterData,
            selectedCustomerFilterData,
            selectedPaymentMethodFilterData,
            selectedItemFilterData,
            selectedLAStatusFilter,
            laStatus,
            transporters,
            items, } = this.props
        const {
            selectedPaymentMethodFilter,
            selectedPaymentStatusFilter,
            selectedItemFilter,
            selectedCustomerFilter,
            selectedBuyerFilter,
            selectedBrokerFilter,
            minQuantity,
            maxQuantity,
            paymentDueDate,
            laQTY,
            contractQTY,
            vehicleNumber,
            selectedLAStatusFilterData,
            selectedTransporterFilter,
        } = this.state

        // console.log(this.props.selectedFilters)
        if (selectedFilters) {
            if (selectedFilters.payment_method_id && !selectedPaymentMethodFilter) {
                let paymentfilter = paymentMethods && paymentMethods.find(p => p.id === selectedFilters.payment_method_id)
                if (paymentfilter)
                    this.setState({
                        selectedPaymentMethodFilterData: paymentfilter,
                        selectedPaymentMethodFilter: paymentfilter.payment_method_name

                    })
                // console.log(this.props.selectedFilters.payment_method_id)
            }

            if (selectedFilters && selectedFilters.payment_status) {
                this.setState({
                    selectedPaymentStatusFilter: selectedFilters.payment_status,
                    selectedPaymentStatusFilterData: selectedFilters.payment_status
                })
            }

            // if (selectedFilters && !selectedPaymentStatusFilter) {
            //     let paymentfilter = paymentStatusList && paymentStatusList.find(p => p.id === selectedFilters.payment_method_id)
            //     if (paymentfilter)
            //         this.setState({
            //             selectedPaymentMethodFilterData: paymentfilter,
            //             selectedPaymentMethodFilter: paymentfilter.payment_method_name

            //         })
            //     // console.log(this.props.selectedFilters.payment_method_id)
            // }
            if (selectedFilters.transporter_id && !selectedTransporterFilter) {
                let transporterfilter = transporters && transporters.data.find(p => p.id === selectedFilters.transporter_id)

                console.log(transporterfilter)
                if (transporterfilter)
                    this.setState({
                        selectedTransporterFilterData: transporterfilter,
                        selectedTransporterFilter: transporterfilter.acc_name

                    })
                // console.log(this.props.selectedFilters.payment_method_id)
            }
            if (selectedFilters.la_status && !selectedLAStatusFilter) {
                // console.log("here")
                // selectedFilters.la_status.forEach(element => {
                //     laStatus.find
                // });
                // let lafilter = laStatus && laStatus.filter(p => p.id === selectedFilters.selectedLAStatusFilterData.id)
                // console.log
                // if (lafilter)
                this.setState({
                    // selectedPaymentMethodFilterData: paymentfilter,
                    // selectedLAStatusFilterData: lafilter,
                    selectedLAStatusFilter: selectedFilters.la_status
                })
                // console.log(this.props.selectedFilters.payment_method_id)
            }
            if (selectedFilters.item_id && !selectedItemFilter) {
                let itemfilter = items && items.data.find(p => p.id === selectedFilters.item_id)
                if (itemfilter)
                    this.setState({
                        selectedItemFilterData: itemfilter,
                        selectedItemFilter: itemfilter.value

                    })
                // console.log(this.props.selectedFilters.payment_method_id)
            }
            if (selectedFilters.broker_id && !selectedBrokerFilter) {
                // console.log(selectedFilters, selectedBrokerFilterData)
                this.setState({
                    selectedBrokerFilter: selectedFilters.broker_id,
                    selectedBrokerName: selectedFilters.broker,
                    selectedBrokerFilterData: selectedFilters.selectedBrokerFilterData
                })
            }
            if (selectedFilters.customer_id && !selectedCustomerFilter) {
                this.setState({
                    selectedCustomerFilter: selectedFilters.customer_id,
                    selectedCustomerName: selectedFilters.customer,
                    selectedCustomerFilterData: selectedFilters.selectedCustomerFilterData

                })
            }

            if (selectedFilters.buyer_id && !selectedBuyerFilter) {
                this.setState({
                    selectedBuyerFilter: selectedFilters.buyer_id,
                    selectedBuyerName: selectedFilters.buyer,
                    selectedBuyerFilterData: selectedFilters.selectedBuyerFilterData

                })
            }

            if (selectedFilters.min_qty && !minQuantity) {
                this.setState({
                    minQuantity: selectedFilters.min_qty
                })
            }

            if (selectedFilters.la_quantity && !laQTY) {
                this.setState({
                    laQTY: selectedFilters.la_quantity
                })
            }
            if (selectedFilters.contract_quantity && !contractQTY) {
                this.setState({
                    contractQTY: selectedFilters.contract_quantity
                })
            }
            if (selectedFilters.truck_number && !vehicleNumber) {
                this.setState({
                    vehicleNumber: selectedFilters.truck_number
                })
            }

            if (selectedFilters.max_qty && !maxQuantity) {
                this.setState({
                    maxQuantity: selectedFilters.max_qty
                })
            }

            if (selectedFilters.payment_due_date && !paymentDueDate) {
                this.setState({
                    paymentDueDate: selectedFilters.payment_due_date
                })
            }

        }
    }

    handleClosePopper = (name) => {
        // console.log(name)
        if (name === "openCustomerListPopper" && this.state.openCustomerListPopper)
            this.setState({
                [name]: false
            })
        if (name === "openBrokerListPopper" && this.state.openBrokerListPopper)
            this.setState({
                [name]: false
            })
        if (name === "openBuyerListPopper" && this.state.openBuyerListPopper)
            this.setState({
                [name]: false
            })

    }
    // , this.getOptions(this.props.user)
    fetchCustomerData = (value, fetchFor) => {
        axios
            .get(`${APIROUTES.CUSTOMER_SEARCH_REPORTS}?search=${value}`,)
            .then(async (response) => {
                // console.log(response)
                if (response.status === 200 && response.data.count > 0) {
                    if (fetchFor === "customer")
                        this.setState({
                            customerList: response.data.results,
                            customerSearchOptionEmpty: false,
                            openCustomerListPopper: true,
                        });
                    else
                        if (fetchFor === "broker")
                            this.setState({
                                brokerList: response.data.results,
                                brokerSearchOptionEmpty: false,
                                openBrokerListPopper: true,
                            });
                        else
                            if (fetchFor === "buyer")
                                this.setState({
                                    buyerList: response.data.results,
                                    buyerSearchOptionEmpty: false,
                                    openBuyerListPopper: true,
                                });

                } else {
                    if (fetchFor === "customer")
                        this.setState({
                            openCustomerListPopper: false,
                        });
                    else
                        if (fetchFor === "broker")
                            this.setState({
                                openBrokerListPopper: false,
                            });
                        else
                            if (fetchFor === "buyer")
                                this.setState({
                                    openBuyerListPopper: false,
                                });
                }
            })
            .catch((error) => {
                console.log(error)
                // this.setState({
                //     customerSearchOptionEmpty: true,
                //     customerList: [],
                // });
            });
    }

    fetchSearchListForDropDown = async (value) => {
        return await axios
            .get(`${APIROUTES.CUSTOMER_SEARCH_REPORTS}?search=${value}`,)
        // .then(res => { return res })
        // .catch(err => { return err })

    }

    handlePaymentDueDateChange = (event) => {
        this.setState({
            paymentDueDate: Moment(event).format("DD-MMM-YYYY")
        })
    }

    handleChange = (name) => (event) => {
        const { value } = event.target;
        // console.log(name, value)

        // if (!(value === "")) {
        if (name === "customer") {
            if (value === "") {
                this.setState({
                    selectedCustomerFilterData: null,
                    selectedCustomerFilter: null,
                    // isSelectedCustomerValid: true
                })
            }
            this.setState({ selectedCustomerName: value });
            this.fetchCustomerData(value, "customer")
        }
        if (name === "buyer") {
            if (value === "") {
                this.setState({
                    selectedBuyerFilterData: null,
                    selectedBuyerFilter: null,
                    // isSelectedBuyerValid: true
                })
            }
            this.setState({ selectedBuyerName: value });
            this.fetchCustomerData(value, "buyer")
        }
        if (name === "broker") {
            if (value === "") {
                this.setState({
                    selectedBrokerFilterData: null,
                    selectedBrokerFilter: null,
                    // isSelectedCustomerValid: true
                })
            }
            this.setState({ selectedBrokerName: value });
            this.fetchCustomerData(value, "broker")
        }

        if (name === "minQuantity" || name === "maxQuantity" || name === "paymentDueDate" || name === "laQTY" || name === "contractQTY")
            this.setState({
                [name]: value === "" ? null : (value)
            })

        if (name === "laQTY") {
            this.setState({
                laQTYError: value === "" ? false : !checkValidFloatValue(value)
            })

        }
        if (name === "contractQTY") {
            // console.log(!checkValidFloatValue(value))
            this.setState({
                contractQTYError: value === "" ? false : !checkValidFloatValue(value)
            })
        }

        if (name === "vehicleNumber") {
            this.setState({
                [name]: value === "" ? "" : value.toUpperCase()
            })
            this.setState({
                vehicleNumberValidationError: value === "" ? false : !isValidVehicleNumber(value)
            })
            // const truckNumberValidationCase_1 = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/g;
            // const truckNumberValidationCase_2 = /^[A-Z]{2}[0-9]{1}[A-Z]{3}[0-9]{4}$/g;
            // const truckNumberValidationCase_3 = /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/g;
            // const truckNumberValidationCase_4 = /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/g;
            // const truckNumberValidationCase_5 = /^[A-Z]{3}[0-9]{4}$/g;
            // this.setState({
            //     [name]: value === "" ? "" : value.toUpperCase()

            // })

            // if (
            //     (truckNumberValidationCase_1.test(value) ||
            //         truckNumberValidationCase_2.test(value) ||
            //         truckNumberValidationCase_3.test(value)) ||
            //     truckNumberValidationCase_4.test(value) ||
            //     truckNumberValidationCase_5.test(value)
            // ) {
            //     this.setState({ vehicleNumberValidationError: false });
            // } else {
            //     this.setState({ vehicleNumberValidationError: value === "" ? false : true });
            // }
        }




    }

    handleSelect = (props) => {
        const { field, data, value, selectedName, selectedField, filterFor, evt } = props

        console.log(props)
        // console.log(`${field}Data`)
        if (filterFor === "customer" || filterFor === "broker" || filterFor === "buyer") {
            this.setState({
                [`${field}Data`]: data,
                [field]: value,
                [`open${filterFor}ListPopper`]: false,
                [selectedField]: selectedName,
            })
        } else if (filterFor === "laStatus") {
            // this.setState({
            //     [field]: typeof evt.target.value === 'string' ? evt.target.value.split(',') : evt.target.value
            // })

            // if(value.contains)
            this.setState({
                [field]: evt.target.value
            })
        } else {
            this.setState({
                [`${field}Data`]: data,
                // [field]: value,
                // [`open${filterFor}ListPopper`]: false,
                [field]: selectedName,
            })
        }
    }

    handleLAStatusMultiSelect = (evt) => {
        console.log(evt.target.value)
        this.setState({
            selectedLAStatusFilter: evt.target.value,
            // disableLAStatus: false
        })
        // let la = []
        // if (evt.target.value.includes("All")) {
        //     console.log("if")
        //     this.setState({
        //         selectedLAStatusFilter: ["All"],
        //         disableLAStatus: true
        //     })
        // } else {
        //     console.log("else")

        // }
    }
    // handleClick = (name, value) => {
    //     // console.log(name, value)
    //     this.setState({
    //         [name]: value
    //     })
    // };

    handleApplyFilterClick = () => {
        const { selectedPaymentMethodFilterData,
            selectedPaymentStatusFilterData,
            selectedBrokerFilter,
            selectedBrokerFilterData,
            minQuantity,
            maxQuantity,
            paymentDueDate,
            selectedCustomerFilter,
            selectedCustomerFilterData,
            selectedBuyerFilter,
            selectedBuyerFilterData,
            selectedItemFilterData,
            selectedLAStatusFilter,
            selectedLAStatusFilterData,
            selectedTransporterFilterData,
            selectedTransporterFilter,
            laQTY,
            contractQTY,
            contractQTYError,
            laQTYError,
            vehicleNumber,
            selectedBuyerName,
            selectedCustomerName,
            selectedBrokerName,
        } = this.state
        // let payload=[]
        // console.log("contract QTY", contractQTY)
        let selectedFilters = {}
        let selectedFiltersData = []
        let filterCount = 0

        if (selectedPaymentStatusFilterData) {
            selectedFilters.payment_status = selectedPaymentStatusFilterData
            selectedFilters.selectedPaymentStatusFilterData = selectedPaymentStatusFilterData
            filterCount += 1
        }

        if (selectedPaymentMethodFilterData) {
            selectedFilters.payment_method_id = selectedPaymentMethodFilterData.id
            selectedFilters.payment_method_name = selectedPaymentMethodFilterData.payment_method_name
            filterCount += 1
        }
        if (selectedTransporterFilterData) {
            selectedFilters.transporter_id = selectedTransporterFilterData.id
            selectedFilters.transporter_name = selectedTransporterFilterData.acc_name
            filterCount += 1
        }
        if (selectedItemFilterData) {
            selectedFilters.item_id = selectedItemFilterData.id
            selectedFilters.item_name = selectedItemFilterData.item_name
            filterCount += 1
        }
        if (!selectedLAStatusFilter.includes("All") && selectedLAStatusFilter.length > 0) {
            console.log(selectedLAStatusFilter)
            selectedFilters.la_status = selectedLAStatusFilter
            // selectedFilters.item_name = selectedItemFilterData.item_name
            filterCount += 1
        }
        if (selectedBrokerFilter) {
            selectedFilters.broker_id = selectedBrokerFilter
            selectedFilters.broker = selectedBrokerName
            filterCount += 1
        }
        if (minQuantity) selectedFilters.min_qty = minQuantity
        if (maxQuantity) selectedFilters.max_qty = maxQuantity
        if (minQuantity && maxQuantity) filterCount += 1
        if (laQTY) {
            selectedFilters.la_quantity = laQTY
            filterCount += 1
        }
        if (contractQTY) {
            selectedFilters.contract_quantity = contractQTY
            filterCount += 1
        }
        if (vehicleNumber) {
            selectedFilters.truck_number = vehicleNumber
            filterCount += 1
        }
        if (paymentDueDate !== 'Invalid date' && paymentDueDate !== null) {
            selectedFilters.payment_due_date = paymentDueDate
            filterCount += 1
        }
        if (selectedCustomerFilter) {
            // console.log(selectedCustomerFilterData)
            selectedFilters.customer_id = selectedCustomerFilter
            selectedFilters.customer = selectedCustomerName
            filterCount += 1
        }
        if (selectedBuyerFilter) {
            // console.log(selectedBuyerFilterData)
            selectedFilters.buyer_id = selectedBuyerFilter
            selectedFilters.buyer = selectedBuyerName
            filterCount += 1
        }

        if (selectedPaymentMethodFilterData) selectedFilters.selectedPaymentMethodFilterData = selectedPaymentMethodFilterData
        if (selectedItemFilterData) selectedFilters.selectedItemFilterData = selectedItemFilterData
        if (selectedTransporterFilterData) selectedFilters.selectedTransporterFilterData = selectedTransporterFilterData
        if (selectedCustomerFilterData) selectedFilters.selectedCustomerFilterData = selectedCustomerFilterData
        if (selectedLAStatusFilterData) selectedFilters.selectedLAStatusFilterData = selectedLAStatusFilterData
        if (selectedBuyerFilterData) selectedFilters.selectedBuyerFilterData = selectedBuyerFilterData
        if (selectedBrokerFilterData) selectedFilters.selectedBrokerFilterData = selectedBrokerFilterData

        // console.log(selectedFilters)
        selectedFilters.filterCount = filterCount
        this.setState({
            selectedFilters
        })
        console.log(selectedFilters)
        this.props.handleApplyFilterData(selectedFilters)
        // if (this.props.tabValue === 0)
        // if (this.props.tabValue === 1)
        //     this.props.handleApplyContractFilterData(selectedFilters)
        // if (this.props.tabValue === 2)
        //     this.props.handleApplyddItemCategoryFilterData(selectedFilters)
        // if (this.props.tabValue === 2)
        //     this.props.handleApplyddItemFilterData(selectedFilters)
        // if (this.props.tabValue === 1)
        //     this.props.handleApplyWBGFilterData(selectedFilters)

    }

    // clearFieldValues = (fieldName) => {
    //     // console.log(fieldName)

    // }
    // handleClickAway = (name) => {
    //     // if (name === "openBuyerListPopper" && openBuyerListPopper)a
    //     this.setState({
    //         [name]: false
    //     })
    // }


    clearAllFilterState = () => {
        this.setState({
            maxQuantity: "",
            minQuantity: "",
            openBrokerListPopper: false,
            openCustomerListPopper: false,
            openBuyerListPopper: false,
            contact: "",
            brokerList: [],
            customerList: [],
            buyerList: [],
            paymentDueDate: new Date(),
            brokerFilterOpen: true,
            customerFilterOpen: true,
            quantityRangeFilterOpen: true,
            paymentMethodFilterOpen: true,
            paymentDueDateFilterOpen: true,
            itemNameFilterOpen: true,
            selectedBrokerName: "",
            selectedCustomerName: "",
            selectedBuyerName: "",
            selectedPaymentMethodFilter: null,
            selectedPaymentStatusFilter: null,
            selectedPaymentMethodFilterData: null,
            selectedItemFilter: null,
            selectedItemFilterData: null,
            selectedBrokerFilterData: null,
            selectedCustomerFilterData: null,
            selectedBuyerFilterData: null,
            selectedBrokerFilter: null,
            selectedCustomerFilter: null,
            selectedBuyerFilter: null,
            selectedLAStatusFilter: null,
            selectedLAStatusFilterData: null,
            vehicleNumberValidationError: false,
            vehicleNumner: null,
            laQTY: null,
            contractQTY: null,
            selectedTransporterFilter: null,
            selectedTransporterFilterData: null,
        })
        // if (this.props.tabValue === 0)
        this.props.handleApplyFilterData({})
        // if (this.props.tabValue === 1)
        //     this.props.handleApplyContractFilterData({}, {})
    }
    handleDisableApplyButton = () => {
        const { tabValue } = this.props
        const { minQuantity,
            maxQuantity,
            openCustomerListPopper,
            openBuyerListPopper,
            openBrokerListPopper,
            vehicleNumberValidationError,
            contractQTYError,
            laQTYError } = this.state

        if ((tabValue === 0 && (
            Number(minQuantity) > Number(maxQuantity) ||
            openBrokerListPopper || openCustomerListPopper ||
            ((maxQuantity && !(maxQuantity === "")) && (!minQuantity || minQuantity === ""))
        )) ||
            (tabValue === 1 && (
                // minQuantity > maxQuantity ||
                openBrokerListPopper || openCustomerListPopper ||
                contractQTYError
                // ((maxQuantity && !(maxQuantity === "")) && (!minQuantity || minQuantity === "")
            )) ||
            (tabValue === 2 && (
                openBuyerListPopper || openCustomerListPopper || laQTYError
            )) ||
            (tabValue === 3 && (
                Number(minQuantity) > Number(maxQuantity) ||
                openCustomerListPopper || openBuyerListPopper ||
                ((maxQuantity && !(maxQuantity === "")) && (!minQuantity || minQuantity === "")) ||
                vehicleNumberValidationError
            ))

        ) {
            return true
        }
        return false
    }
    render() {
        const { minQuantity, maxQuantity, laQTY,
            laQTYError, contractQTY, contractQTYError,
            selectedLAStatusFilterData, selectedLAStatusFilter, disableLAStatus, } = this.state
        const { tabValue, laStatus, isMobile } = this.props
        // console.log(this.props.selectedFilters)
        return (
            <div
                // tabIndex="0"
                style={{
                    flexDirection: "column",
                }}
            // onBlur={this.handleClosePopper("openCustomerListPopper")}
            >
                {/* <div style={{
                    height: "50vh", display: "flex",
                    width: "100%",
                    // flexWrap: "wrap",
                }}> */}
                <div style={{
                    display: isMobile ? 'flex' : "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gridGap: '0 20px',
                    flexDirection: isMobile && 'column'
                }}>
                    {/* <div style={dialogStyles.dialogContentContainer}> */}
                    {
                        tabValue === 0 || tabValue === 1 || tabValue === 2 || tabValue === 3 ?
                            <div
                            // style={{ width: "30%" }}
                            >
                                <ClickAwayListener onClickAway={() => this.handleClosePopper("openCustomerListPopper")}>
                                    <FilterByCustomerOrBroker
                                        {...this.state}
                                        {...this.props}
                                        textFieldName="customer"
                                        textFieldLabel="Search Customer"
                                        openPopper={this.state.openCustomerListPopper}
                                        dataList={this.state.customerList}
                                        selectParam="selectedCustomerFilter"
                                        handleChange={this.handleChange}
                                        handleSelect={this.handleSelect}
                                        handleCustomSetState={this.handleCustomSetState}
                                        selectedTextFieldValue={this.state.selectedCustomerName}
                                        fiterKey="selectedCustomerName"
                                        handleClosePopper={this.handleClosePopper}
                                        openFilter="openCustomerListPopper"
                                    />
                                </ClickAwayListener>
                            </div> : null
                    }
                    {tabValue === 0 || tabValue === 2 || tabValue === 3 || tabValue === 1 ?
                        <div
                        // style={{ width: "30%" }}
                        >
                            <ClickAwayListener onClickAway={() => this.handleClosePopper("openBuyerListPopper")}>
                                <FilterByCustomerOrBroker
                                    {...this.state}
                                    {...this.props}
                                    textFieldName="buyer"
                                    textFieldLabel="Search Buyer"
                                    openPopper={this.state.openBuyerListPopper}
                                    dataList={this.state.buyerList}
                                    selectParam="selectedBuyerFilter"
                                    handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                    handleCustomSetState={this.handleCustomSetState}
                                    selectedTextFieldValue={this.state.selectedBuyerName}
                                    fiterKey="selectedBuyerName"
                                    handleClosePopper={this.handleClosePopper}
                                    openFilter="openBuyerListPopper"
                                />
                            </ClickAwayListener>
                        </div> : null
                    }
                    {
                        // tabValue === 1 ?
                        //     <div
                        //     // style={{ width: "30%" }}
                        //     >
                        //         <ClickAwayListener onClickAway={() => this.handleClosePopper("openBrokerListPopper")}>
                        //             <FilterByCustomerOrBroker
                        //                 {...this.state}
                        //                 {...this.props}
                        //                 textFieldName="broker"
                        //                 textFieldLabel="Search Broker"
                        //                 openPopper={this.state.openBrokerListPopper}
                        //                 dataList={this.state.brokerList}
                        //                 selectParam="selectedBrokerFilter"
                        //                 handleChange={this.handleChange}
                        //                 handleSelect={this.handleSelect}
                        //                 handleCustomSetState={this.handleCustomSetState}
                        //                 selectedTextFieldValue={this.state.selectedBrokerName}
                        //                 fiterKey="selectedBrokerName"
                        //                 handleClosePopper={this.handleClosePopper}
                        //                 openFilter="openBrokerListPopper"
                        //             />
                        //         </ClickAwayListener>
                        //     </div> : null
                    }
                    {
                        tabValue === 0 || tabValue === 1 || tabValue === 2 ?
                            <div
                                style={{ marginTop: "15px" }}
                            >

                                <FilterByPaymentMethod
                                    {...this.state}
                                    {...this.props}
                                    handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                />
                            </div> : null
                    }

                    {
                        tabValue === 2 ?
                            <div
                                style={{ marginTop: "15px", width: "100%" }}
                            >

                                {/* <MultiSelect
                                    classes={classes}
                                    selectedFilter={plant}
                                    handleSelect={selectMultiPlant}
                                    filters={plants.data}
                                    tabValue={value}
                                    disableOptions={disablePlants}
                                    selectFor="Plants"
                                /> */}

                                <FilterByLAStatus
                                    {...this.state}
                                    {...this.props}
                                    handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                    handleLAStatusMultiSelect={this.handleLAStatusMultiSelect}
                                    // classes={classes}
                                    selectedFilter={selectedLAStatusFilter}
                                    // handleSelect={selectMultiPlant}
                                    filters={laStatus}
                                    // tabValue={value}
                                    disableOptions={disableLAStatus}
                                // selectFor="Plants"
                                />
                            </div> : null
                    }
                    {
                        tabValue === 1 ?
                            <div
                            // style={{ width: "30%" }}
                            >

                                <FilterByLAQTY
                                    // {...this.state}
                                    // {...this.props}
                                    value={contractQTY}
                                    fieldFor="contractQTY"
                                    label="Contract Quantity"
                                    valueError={contractQTYError}
                                    handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                />
                            </div> : null
                    }
                    {
                        tabValue === 2 ?
                            <div
                            // style={{ width: "30%" }}
                            >

                                <FilterByLAQTY
                                    // {...this.state}
                                    // {...this.props}
                                    value={laQTY}
                                    fieldFor="laQTY"
                                    label="LA Quantity"
                                    valueError={laQTYError}
                                    handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                />
                            </div> : null
                    }
                    {
                        tabValue === 3 ?
                            <div
                            // style={{ width: "30%" }}
                            >
                                <FilterByVehicle
                                    {...this.state}
                                    {...this.props}
                                    handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                />
                            </div> : null
                    }

                    {
                        tabValue === 3 ?
                            <div
                                style={{ marginTop: "15px" }}
                            >

                                <FilterByTransporter
                                    {...this.state}
                                    {...this.props}
                                    handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                />
                            </div> : null
                    }
                    {/* </div> */}
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}

                    {
                        tabValue === 0 || tabValue === 3 ?
                            <div
                                style={{ gridColumn: "span 2", }}
                            >
                                <FilterByQuantityRange
                                    maxQuantity={this.state.maxQuantity}
                                    minQuantity={this.state.minQuantity}
                                    handleChange={this.handleChange}
                                />

                            </div> : null
                    }
                    {
                        tabValue === 0 || tabValue === 1 ?
                            <div
                                style={{ marginTop: "15px", minWidth: "100%", alignItems: "center" }}
                            >
                                <FilterBypaymentDueDate
                                    paymentDueDate={this.state.paymentDueDate}
                                    handlePaymentDueDateChange={this.handlePaymentDueDateChange}
                                />

                            </div>
                            : null
                    }
                    {
                        tabValue === 0 ?
                            <div
                                style={{ marginTop: "15px" }}
                            >

                                <FilterByPaymentStatus
                                    {...this.state}
                                    {...this.props}
                                    // handleChange={this.handleChange}
                                    handleSelect={this.handleSelect}
                                />
                            </div> : null
                    }


                    {/* <div style={{ width: "30%", marginTop: "15px" }}>

                        <FilterByItemName
                            {...this.state}
                            {...this.props}
                            handleChange={this.handleChange}
                            handleSelect={this.handleSelect}
                        />


                    </div> */}

                </div>
                <div style={dialogStyles.dialogButtonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={this.handleDisableApplyButton() ? {
                            backgroundColor: "grey",
                            padding: "8px 24px",
                            color: primaryColor,

                        } : {
                            backgroundColor: "#4b87c5",
                            padding: "8px 24px",
                            color: "white",

                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.handleFilterIconClick(false)
                            this.handleApplyFilterClick()
                        }}
                        disabled={this.handleDisableApplyButton()}
                    >
                        Apply Filters
                    </Button>
                    <Button style={dialogStyles.goBackButton}
                        onClick={(e) => {
                            e.preventDefault();
                            this.clearAllFilterState()
                        }}
                    >Clear All</Button>
                </div>
            </div>
        )
    }
}

export default FIlters