import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  poppinsBold,
  poppinsLight,
  poppinsMedium,
  poppinsRegular,
} from "../styles/Fonts";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          poppinsBold,
          poppinsLight,
          poppinsMedium,
          poppinsRegular,
        ],
      },
    },
  },
  palette: {
    primary: {
      light: "rgb(128, 128, 128)",
      main: "rgb(128, 128, 128)",
      dark: "rgb(128, 128, 128)",
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
  },
});

function withMUI(Component) {
  function WithMUI(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithMUI;
}

export default withMUI;
