import { useState } from "react";

import { IconButton, Typography } from "@material-ui/core";
import { Popover } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { pxToEm } from "../../../methods";

const styles = {
  popoverBox: {
    '& .MuiPopover-paper': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
      marginBottom: 10
      // borderRadius: 20,
    }
  },
  popoverContent: {
    pointerEvents: 'auto',
    padding: "2px 8px",
    color: "#eee",
    backgroundColor: '#5A5A5A',
    fontSize: pxToEm(15)
  },
}

const MousehoverPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (e) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  console.log(open, props)
  return (
    <div>
      {props.icon
        ? <div onClick={(e) => e.stopPropagation()}>
          <span style={props?.style}>
            {props.children}
          </span>
          <IconButton
            sx={{
              display: 'inline',
              color: anchorEl === null ? "#666" : "#3B69B0",
              cursor: 'pointer',
            }}
            aria-describedby={'mouse-over-popover'}
            variant="text"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            size="small"
          >
            <InfoOutlinedIcon style={{ transform: 'scale(0.8)' }} />
          </IconButton>
        </div>
        : <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            fontFamily: 'Poppins',
            fontSize: "inherit",
            textDecoration: "underline",
            fontWeight: anchorEl === null ? "400" : "500",
            color: anchorEl === null ? "#666" : "#3B69B0",
            cursor: 'pointer'
          }}
        >
          <span style={props?.style}>
            {props.children}
          </span>
        </Typography>
      }
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        style={styles.popoverBox}
      >
        <Typography style={styles.popoverContent}>
          {props.content}
        </Typography>
      </Popover>
    </div>
  );
}


export default MousehoverPopover;