import React, { Component } from "react";
import "../../../styles/b2b/B2bProfileChangePassword.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  primaryColor,
  bottomNavigationFontColor,
} from "../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
// import Slide from "@material-ui/core/Slide";
import backStack from "cordova-back-stack";
import SnackbarComponent from "../../common/SnackbarComponent";
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { PasswordLoginValid, checkApiError } from "../../../methods";
import SaveButton from "../../internal/common/SaveButton"

// function TransitionUp(props) {
//   return <Slide {...props} direction="up" />;
// }
const styles = {
  b2bProfileChangePasswordMainBody: {
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    padding: "20px",
    position: "relative",
  },
  textFieldStyle: {
    width: "93%",
    background: "white",
  },
  SaveButtonStyle: {
    height: "100%",
    width: "100%",
    color: "white",
    fontWeight: "bold",
    backgroundColor: primaryColor,
  },
};
class B2bProfileChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      newPasswordLengthError: false,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
      isSaveButtonLoading: false,
    };
  }
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "newPassword") {
      !PasswordLoginValid(event.target.value) ?
        this.setState({ newPasswordLengthError: true, }) : this.setState({ newPasswordLengthError: false, })
    }
  };
  componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      this.props.history.goBack();
    });
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: ''
    })
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {/* {item} */}
              {String(item).slice(String(item).indexOf(":") + 1,)}
            </p>
          </>)
      })
    )
  }

  handleClickShowOldPassword = () => {
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    })
  }

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    })
  }

  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    })
  }

  handleChangePassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
        open: true,
        Transition: "up",
      });
    } else {
      this.setState({ isSaveButtonLoading: true })
      this.props
        .changePassword(this.state.oldPassword, this.state.newPassword)
        .then((response) => {
          if (response.status === 200) {
            this.handleOpenSnackbar(
              'Password changed successfully',
              "success"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
              this.setState({ isSaveButtonLoading: false })
              this.props.history.goBack();
            }, 3000)
            // this.setState({ open: true, Transition: "up" }, () => {
            //   setTimeout(() => {
            //     this.props.history.goBack();
            //   }, 3000);
            // });
          }
          else if (response?.response?.status === 400) {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(response.response.data)),
              "error"
            );
            setTimeout(() => {
              this.setState({ isSaveButtonLoading: false });
              this.handleCloseSnackbar();
            }, 4000)
          }
          else {
            this.setState({ isSaveButtonLoading: false })
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
              this.setState({ isSaveButtonLoading: false });
            }, 4000)

          }
        });
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const {
      // newPassword,
      newPasswordLengthError,
      confirmPasswordLenghtError,
      passwordNotMatch,
      oldPasswordError,
      noInternetConnection,
      showOldPassword,
      showNewPassword,
      showConfirmPassword,
      isSaveButtonLoading
    } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.b2bProfileChangePasswordMainBody}>
        <div className="b2b-mobile-change-password-text-container">
          <span>Change Password</span>
        </div>
        <br />
        <div className="b2b-mobile-change-password-form-start-container">
          <TextField
            name="oldPassword"
            required
            id="outlined-name"
            label="Old Password"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
            type={showOldPassword ? "text" : "password"}
            onChange={this.handleTextChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowOldPassword}
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>

                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontSize: "1.1em", marginTop: "0px" },
            }}
            onFocus={() => {
              this.setState({ oldPasswordError: false });
            }}
            helperText={
              <span style={{ color: "red" }}>
                {oldPasswordError ? "Old Password Is Invalid" : null}
              </span>
            }
            error={oldPasswordError ? true : false}
          />
          <br />
          <TextField
            name="newPassword"
            required
            id="outlined-name"
            label="New Password"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
            type={showNewPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowNewPassword}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>

                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontSize: "1.1em", marginTop: "-4px" },
            }}
            onChange={this.handleTextChange}
            onFocus={() => {
              this.setState({
                newPasswordLengthError: false,
                passwordNotMatch: false,
              });
            }}
            onBlur={() => {
              if (this.state.newPassword.length < 6) {
                this.setState({ newPasswordLengthError: true });
              }
            }}
            error={newPasswordLengthError ? true : false}
            helperText={
              <span style={{ color: "red" }}>
                {newPasswordLengthError
                  ? "Password must be 8 to 15 characters, must include minimum 1 character, minimum 1 digit and a special character ( # . @ _ )"
                  : ""}
              </span>
            }
          />
          <br />
          <div
            style={{
              width: "92%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.8em",
              }}
            >
              Password strength
            </span>
            <span
              style={{
                backgroundColor:
                  this.state.newPassword &&
                    this.state.newPassword.length >= 2 &&
                    this.state.newPassword.length < 4
                    ? "#e68948"
                    : this.state.newPassword &&
                      this.state.newPassword.length >= 4 &&
                      this.state.newPassword.length < 7
                      ? "#4986c2"
                      : this.state.newPassword &&
                        this.state.newPassword.length >= 7
                        ? "#c6d430"
                        : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                backgroundColor:
                  this.state.newPassword &&
                    this.state.newPassword.length >= 3 &&
                    this.state.newPassword.length < 5
                    ? "#e68948"
                    : this.state.newPassword &&
                      this.state.newPassword.length >= 5 &&
                      this.state.newPassword.length < 8
                      ? "#4986c2"
                      : this.state.newPassword &&
                        this.state.newPassword.length >= 8
                        ? "#c6d430"
                        : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                backgroundColor:
                  this.state.newPassword &&
                    this.state.newPassword.length >= 4 &&
                    this.state.newPassword.length < 6
                    ? "#e68948"
                    : this.state.newPassword &&
                      this.state.newPassword.length >= 6 &&
                      this.state.newPassword.length < 8
                      ? "#4986c2"
                      : this.state.newPassword &&
                        this.state.newPassword.length >= 8
                        ? "#c6d430"
                        : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                backgroundColor:
                  this.state.newPassword &&
                    this.state.newPassword.length >= 5 &&
                    this.state.newPassword.length < 6
                    ? "#e68948"
                    : this.state.newPassword &&
                      this.state.newPassword.length >= 6 &&
                      this.state.newPassword.length < 8
                      ? "#4986c2"
                      : this.state.newPassword &&
                        this.state.newPassword.length >= 8
                        ? "#c6d430"
                        : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
          </div>

          <TextField
            name="confirmPassword"
            required
            id="outlined-name"
            label="Confirm Password"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
            type={showConfirmPassword ? "text" : "password"}
            onChange={this.handleTextChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowConfirmPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>

                </InputAdornment>
              ),
            }}
            onFocus={() => {
              this.setState({
                confirmPasswordLenghtError: false,
                passwordNotMatch: false,
              });
            }}
            onBlur={() => {
              if (this.state.confirmPassword.length < 6) {
                this.setState({ confirmPasswordLenghtError: true });
              }
              if (
                this.state.newPassword.length >= 6 &&
                this.state.confirmPassword.length >= 6 &&
                this.state.newPassword !== this.state.confirmPassword
              ) {
                this.setState({ passwordNotMatch: true });
              }
            }}
            error={
              confirmPasswordLenghtError
                ? true
                : passwordNotMatch
                  ? true
                  : false
            }
            helperText={
              <span style={{ color: "red" }}>
                {confirmPasswordLenghtError
                  ? "Password must be 8 to 15 characters, must include minimum 1 character, minimum 1 digit and a special character ( # . @ _ )"
                  : passwordNotMatch
                    ? "Password did not match"
                    : null}
              </span>
            }
          />
        </div>
        <div className="b2b-mobile-change-password-save-button-container">
          <SaveButton
            // disabled={
            //   this.state.oldPassword === "" ||
            //   newPasswordLengthError ||
            //   this.state.newPassword !== this.state.confirmPassword
            // }
            onClick={(e) => {
              e.preventDefault();
              if (this.state.oldPassword === "") {
                this.setState({ oldPasswordError: true })
              }
              else if (this.state.newPassword === "" || newPasswordLengthError) {
                this.setState({ newPasswordLengthError: true })
              }
              else if (this.state.confirmPassword === "" || confirmPasswordLenghtError) {
                this.setState({ confirmPasswordLenghtError: true })
              }
              else if (passwordNotMatch) {
                this.setState({ passwordNotMatch: true })
              }
              else { this.handleChangePassword(); }
            }}
            variant="contained"
            color="primary"
            //className={classes.SaveButtonStyle}
            style={{
              height: "100%",
              width: "100%",
              color: "white",
              fontWeight: "bold",
              backgroundColor: primaryColor,
            }}
            isLoading={isSaveButtonLoading}
          >
            Save
          </SaveButton>
        </div>
        <div className="b2b-mobile-change-password-cancel-button-container">
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
            color="primary"
          // className={classes.SaveButtonStyle}
          >
            Cancel
          </Button>
        </div>

        {noInternetConnection ?
          <Snackbar
            autoHideDuration={3000}
            open={this.state.open}
            onClose={this.handleClose}
            TransitionComponent={this.state.Transition}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                {noInternetConnection ? "No Internet Connection" : "Password has been changed"}
              </span>
            }
          /> :
          <SnackbarComponent
            autoHideDuration={3000}
            open={this.state.snackbarOpen}
            // handleClose={this.handleClose}

            // open={this.state.snackbarData.openSnackbar}
            handleClose={this.handleCloseSnackbar}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          // snackbarMessage={noInternetConnection ? "No Internet Connection" : "Password has been changed"}
          // type={noInternetConnection ? "" : "success"}
          />}
        {/* <Snackbar
          autoHideDuration={3000}
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={this.state.Transition}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              {noInternetConnection ? "No Internet Connection" : "Password has been changed"}
            </span>
          }
        /> */}
      </div>
    );
  }
}
export default withStyles(styles)(B2bProfileChangePassword);
