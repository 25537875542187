import { useEffect, useState } from "react";
import {
  checkApiError,
  handleSnackbarMessageBox,
  startOFMonth,
  endOFMonth,
  CaptilizeText,
  arraySortBykeyAllBrowser,
  pxToEm,
} from "../../../../methods";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../common/SnackbarComponent";
import { DropdownContainer, SimpleTable, ToggleButton, WidgetTop } from "./commanWidget";
import DatamapsIndia from "react-datamaps-india";
import { Divider, Typography } from "@material-ui/core";
import moment from "moment";

const styles = {
  mapContainer: (isMobile) => ({
    width: !isMobile ? "45%" : "100%",
  }),
  mapTableContainer: (isMobile) => ({
    width: "100%",
    height: Math.max(window.innerWidth * 0.4, window.innerHeight * 0.4),
    overflowY: 'auto',
    overflowX: 'clip',
    marginBottom: 20,
    borderRadius: 20
  }),
  mapInnerContainer: {
    flex: 1,
    display: "inline-block",
    position: "relative",
    width: "100%",
    paddingBottom: "100%",
    verticalAlign: "top",
    overflow: "hidden",
  },
  stateHover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minWidth: 200,
    maxHeight: 140,
    backgroundColor: '#0F0F0F',
    outline: '8px solid #0F0F0F'
  },
  dialogHeadingText: {
    display: 'flex',
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: pxToEm(14),
    fontWeight: '500',
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(11),
    fontWeight: '400',
  },
  blue: {
    color: "#3B69B0"
  },
  popUpValueRightAlign: {
    textAlign: 'right'
  },
}

const SUPPORTED_STATES = new Set([
  "Andaman & Nicobar Island",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadara & Nagar Haveli",
  "Daman & Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
]);

const MapHover = (props) => {
  const { value, name, key } = props.data;
  // console.log(value, name, key)
  return (
    <div style={{ width: 150, padding: "2% 3%" }}>
      <Typography style={styles.dialogHeadingText}>
        <div style={styles.blue}>
          {name}
        </div>
      </Typography>
      <Divider variant='middle' style={{ margin: '2% 0 3% 0' }} />
      <Typography style={styles.dialogText}>
        <div style={styles.blue}>
          {CaptilizeText(key?.replaceAll("_", " "))}
        </div>
        <div style={styles.popUpValueRightAlign}>
          {value}
        </div>
      </Typography>
    </div>
  )
}

const CountryTable = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mounted, setMounted] = useState(false);
  const [itemcategoryIDSelected, setItemcategoryIDSelected] = useState([])
  const [plantIDSelected, setPlantIDSelected] = useState([])
  const [toggleButton, setToggleButton] = useState('delivered')
  const [selectedDate, setSelectedDate] = useState({
    label: 'M',
    startDate: startOFMonth,
    endDate: endOFMonth
  })
  const [simpleTableData, setSimpleTableData] = useState([])
  const [showProgress, setShowProgress] = useState(false);
  const [mapData, setMapData] = useState({})
  const [mapShownData, setMapShownData] = useState([])
  const [cancelTokenSource, setCancelTokenSource] = useState({
    tableDataApi: null,
    graphDataApi: null,
  });
  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: "",
    snackbarType: ""
  })
  const [dropdownSelection, setDropdownSelection] = useState('net_sales_qty')

  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: ""
    })
  };

  const handleCatchError = (error) => {
    handleOpenSnackbar(
      handleSnackbarMessageBox(checkApiError(error?.response)),
      "error"
    );
    setTimeout(() => {
      handleCloseSnackbar();
    }, 4000);
  }

  const handleTableData = (fromDate, toDate, itemCategoryIdList, plantIdList, filterName) => {
    const newCancelTokenSource = handleCancelToken('tableDataApi')
    setSimpleTableData([])
    setMapData({})
    setMapShownData([])
    setShowProgress(true)
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      filter_name: filterName,
      item_category: itemCategoryIdList,
      plant: plantIdList
    }
    axios.post(
      `${APIROUTES.DELIVERED_BILLED_STATE_DATA}`,
      payload,
      { cancelToken: newCancelTokenSource.token, }
    )
      .then((response) => {
        if (response.status === 200) {
          let data = response.data[filterName === 'delivered' ? 'Delivered State Wise Data' : 'Billed State Wise Data']
          let sorted = arraySortBykeyAllBrowser([...data], dropdownSelection, 'decreasing')
          setSimpleTableData(sorted)
          handleGraphWiseData(sorted)
          setShowProgress(false)
        }
      })
      .catch((error) => {
        setShowProgress(false)
        if (axios.isCancel(error)) {
        } else {
          handleCatchError(error)
        }
      });
  }

  const handleGraphWiseData = (data) => {
    if (data && data.length > 0) {
      let stateKey = toggleButton === 'delivered' ? 'delivered_state' : 'billed_state'
      const sortedData = arraySortBykeyAllBrowser(data, stateKey)
      let allState = [...SUPPORTED_STATES];
      let stateData = {};
      sortedData.map(d => {
        if (!SUPPORTED_STATES.has(d[stateKey] === 'Jammu and Kashmir' ? 'Jammu & Kashmir' : d[stateKey])) {
          return;
        }
        else {
          stateData[
            d[stateKey] === 'Jammu and Kashmir' ? 'Jammu & Kashmir' : d[stateKey]
          ] = {
            value: dropdownSelection === 'net_sales_cr' ? Number((Number(d[dropdownSelection]) / 10000000) || 0) : d[dropdownSelection],
            key: dropdownSelection
          };
          allState = allState.filter(state => state !== (d[stateKey] === 'Jammu and Kashmir' ? 'Jammu & Kashmir' : d[stateKey]))
        }
      })
      let remainingState = {}
      allState.map(a => {
        remainingState[a] = { value: 0, key: dropdownSelection }
      })
      Object.assign(stateData, remainingState)
      setMapData(stateData)
    }
  }

  const dateChange = (data) => {
    setSelectedDate({
      label: data.label,
      startDate: data.startDate,
      endDate: data.endDate
    })
  }

  const handleToggle = (index, toogleKey) => {
    setToggleButton(toogleKey)
  }

  const handleFilterSelection = (idList, filter) => {
    if (filter === 'item_category') {
      setItemcategoryIDSelected(idList)
    }
    else {
      setPlantIDSelected(idList)
    }
  }

  const handlDropdownSelection = (data) => {
    setDropdownSelection(data?.graphValue)
  }

  const handleCancelToken = (key) => {
    if (cancelTokenSource[key]) {
      cancelTokenSource[key].cancel('Operation canceled');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(prevValue => { return { ...prevValue, [key]: newCancelTokenSource } });
    return newCancelTokenSource;
  }


  useEffect(() => {
    if (mounted) {
      handleTableData(selectedDate.startDate,
        selectedDate.endDate,
        itemcategoryIDSelected,
        plantIDSelected,
        toggleButton
      )
    }
  }, [selectedDate, itemcategoryIDSelected, plantIDSelected, toggleButton])

  useEffect(() => {
    if (mounted) {
      handleGraphWiseData(simpleTableData)
    }
  }, [dropdownSelection,])

  useEffect(() => {
    let stateKeys = Object.keys(mapData)
    if (stateKeys) {
      let mapShownArray = []
      Object.entries(mapData).map(([k, v], i) => {
        mapShownArray.push({
          state: k,
          [dropdownSelection]: v.value
        })
      })
      mapShownArray = arraySortBykeyAllBrowser(mapShownArray, dropdownSelection, 'decreasing')
      setMapShownData(mapShownArray)
    }
  }, [mapData])

  useEffect(() => {
    handleTableData(selectedDate.startDate,
      selectedDate.endDate,
      itemcategoryIDSelected,
      plantIDSelected,
      toggleButton
    )
    setMounted(true)
    return () => {
      setMounted(false)
    };
  }, [])

  useEffect(() => {
    // Cleanup function to cancel the previous request when component unmounts
    return () => {
      Object.entries(cancelTokenSource)
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => (cancelTokenSource[key].cancel("Operation canceled")));
    };
  }, [cancelTokenSource]);

  const DATEBUTTONS = [
    { label: 'Y', function: dateChange },
    { label: 'M', function: dateChange, defaultSelected: true },
    { label: 'W', function: dateChange },
    { label: 'T', function: dateChange },
    { label: 'date_range', function: dateChange },
  ]

  const TABLEDATA = [
    { label: '#', key: 'customer', minWidth: 20 },
    { label: 'Country', key: 'country', id: 'no_header', minWidth: 100 },
    {
      label: toggleButton === 'delivered' ? 'Delivered State' : 'Billed State',
      key: toggleButton === 'delivered' ? 'delivered_state' : 'billed_state',
      id: 'no_header',
      minWidth: 180
    },
    { label: 'Net Sales Qty', key: 'net_sales_qty', minWidth: 130 },
    { label: 'Net Sales (Cr)', key: 'net_sales_cr', minWidth: 130 },
  ]

  const GRAPHDROPDOWNDATA = [
    { label: 'Net Sales Qty', key: 'net_sales_qty', graphValue: 'net_sales_qty' },
    { label: 'Net Sales (Cr)', key: 'net_sales_cr', graphValue: 'net_sales_cr' },
  ]

  const TOGGLEFIELD = [
    { label: 'Delivered', key: 'delivered', function: handleToggle },
    { label: 'Billed', key: 'billed', function: handleToggle },
  ]

  const MAPDATA = [
    { label: '#', key: 'customer', },
    {
      label: toggleButton === 'delivered' ? 'Delivered State' : 'Billed State',
      key: 'state',
      id: 'no_header'
    },
    {
      label: CaptilizeText(dropdownSelection?.replaceAll("_", " ")),
      key: dropdownSelection
    },
  ]

  return (
    <div>
      <WidgetTop
        {...props}
        widgetTitle='Territory Sales'
        widgetSubtitle="Excluding Intercompany"
        isMobile={isMobile}
        plantFilter={true}
        itemCategoryFilter={true}
        handleFilterSelection={handleFilterSelection}
        itemCategoryDropdownList={props?.itemCategoryDropdownList}
        plantDropdownList={props?.plantDropdownList}
        dateButtons={DATEBUTTONS}
        selectedDate={selectedDate?.label}
        pdfCsvButton={false}
      />
      <ToggleButton
        isMobile={isMobile}
        toggleField={TOGGLEFIELD}
      />
      <SimpleTable
        {...props}
        isMobile={isMobile}
        tableData={TABLEDATA}
        showProgress={showProgress}
        rows={arraySortBykeyAllBrowser([...simpleTableData], dropdownSelection, 'decreasing')}
        backgroundOddEven={false}
        selectedDate={selectedDate?.label}
        widget="country_delivered_billed_state"
      />
      <DropdownContainer
        isMobile={isMobile}
        graphDropdownList={GRAPHDROPDOWNDATA}
        handlDropdownSelection={handlDropdownSelection}
      />
      <div style={{
        display: 'flex',
        flexDirection: isMobile && 'column',
        justifyContent: isMobile ? 'center' : "space-around",
        alignItems: 'center',
      }}>
        <div style={styles.mapContainer(isMobile)}>
          <div style={styles.mapInnerContainer}>
            <DatamapsIndia
              regionData={mapData}
              mapLayout={{
                title: "",
                width: "",
                legendTitle: `${CaptilizeText(dropdownSelection?.replaceAll("_", " "))}`,
                startColor: "#FFF",
                endColor: "#FF6000",
                hoverTitle: `${CaptilizeText(dropdownSelection?.replaceAll("_", " "))}`,
                noDataColor: "#f5f5f5",
                borderColor: "#8D8D8D",
                hoverBorderColor: "#8D8D8D",
                hoverColor: "green",
              }}
              hoverComponent={({ value }) => <MapHover data={value} />}
            />
          </div>
        </div>
        <div style={{
          padding: isMobile && '0 10px',
          marginBottom: isMobile && 8,
        }}>
          <SimpleTable
            {...props}
            isMobile={isMobile}
            tableData={MAPDATA}
            showProgress={false}
            rows={mapShownData}
            tableContainerStyle={styles.mapTableContainer(isMobile)}
            stickyHeader={true}
            backgroundOddEven={false}
            minwidth={isMobile ? '75vw' : 380}
            widget="country_map_data"
            noOfLoaderRows={12}
          />
        </div>
      </div>
      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </div>
  )
}

export default CountryTable;