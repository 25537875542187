import React from "react";
import axios from "axios";
import AUTH_URL from "../constants/common/LoginApiURLs";
import { getUserIP } from "../methods.js";
import Firebase from "../firebase";
import { DeviceUUID } from "device-uuid";
import moment from "moment";


const log = (mode, message) => console[mode]("AUTOLOGIN: ", message);

const isIPRestrictionEnabled = false;

const AuthUserContext = React.createContext(null);

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      let user = localStorage.getItem("user");
      let authUser = null;
      if (user) {
        const clearUser = (message) => {
          user = null;
          localStorage.removeItem("user");
          log("error", message);
        };
        user = JSON.parse(user);
        const token = user.token || false;
        if (token) {
          const base64Url = token.split(".")[1];
          const base64 = base64Url.replace("-", "+").replace("_", "/");
          const decryptedUser = JSON.parse(atob(base64));
          if (decryptedUser) {
            const exp = new Date(decryptedUser.exp * 1000);
            if (exp < new Date()) clearUser("Token expired!");
            else if (!user.is_admin) {
              authUser = user;
              // log("log", "Found a B2B client!");
            } else {
              let last_action_at = localStorage.getItem("last_action_at");
              if (last_action_at) {
                last_action_at = new Date(last_action_at);
                const allow_time = new Date(new Date().getTime() - 30 * 60000);
                if (last_action_at > allow_time) {
                  authUser = user;
                  // log("log", "Found an admin!");
                } else clearUser("Session was expired!");
              } else clearUser("Last activity unavailable/changed!");
            }
          } else clearUser("Invalid token/changed!");
        } else clearUser("Token unavailable!");
      } else log("error", "User not found!");
      const authEvent = document.createEvent("Event");
      authEvent.initEvent("onAuthStateChanged", true, true);
      this.state = {
        authUser,
        privateIPv4: "",
        isOffline: null,
      };
    }

    componentWillMount() {
      window.addEventListener("offline", this.offlineCheck);
      window.addEventListener("online", this.onlineCheck);
    }

    componentDidMount() {
      if (navigator.onLine === false) {
        this.setState({
          isOffline: true,
        });
      }
      getUserIP((privateIPv4) => {
        if (privateIPv4.length > 0 && privateIPv4.length < 16)
          this.setState({ privateIPv4 });
        // console.log("dataSourabh", privateIPv4);
      });
      document.addEventListener(
        "onAuthStateChanged",
        this.onAuthStateChanged,
        false
      );
      window.onstorage = () => !localStorage.user && this.logout();
      if (this.state.authUser && this.state.authUser.is_admin === false) {
        // this.refreshToken(this.state.authUser);
      }
    }

    componentWillUnmount() {
      document.removeEventListener(
        "onAuthStateChanged",
        this.onAuthStateChanged,
        true
      );
      window.removeEventListener("offline", this.offlineCheck);
      window.removeEventListener("online", this.onlineCheck);
    }

    offlineCheck = () => {
      this.setState({ isOffline: true }, () => {
        localStorage.setItem('isOffline', true)
      });
    };
    onlineCheck = () => {
      this.setState({ isOffline: false }, () => {
        localStorage.setItem('isOffline', false)
      });
    };

    userTokenValidator = (token) => {
      if (token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const user = JSON.parse(window.atob(base64));
        if (user) {
          const exp = new Date(user.exp * 1000);
          if (exp < new Date()) {
            window.localStorage.removeItem("user");
            return null;
          } else {
            return {
              ...user,
              token,
            };
          }
        } else return null;
      } else return null;
    };

    eventDispatcher = (data = null) => {
      const authEvent = new Event("onAuthStateChanged");
      if (data?.token) {
        authEvent.user = {
          ...this.userTokenValidator(data?.token),
          ...data,
        };
      } else {
        authEvent.user = this.userTokenValidator(false);
      }
      document.dispatchEvent(authEvent);
    };
    onAuthStateChanged = ({ user }) => {
      const authUser = user;
      if (authUser) {
        localStorage.setItem("last_action_at", new Date().toISOString());
        const access = this.getAccess(authUser);
        authUser.roles = access.roles;
        authUser.accessibleWidgets = access.accessibleWidgets;
        authUser.accessibleModules = access.accessibleModules;
        authUser.full_name = authUser?.first_name + " " + authUser?.last_name;
        authUser.privateIPv4 = this.state.privateIPv4;
        // console.log(this.state.privateIPv4, "Sourabh");
        this.setState({ authUser }, () => {
          localStorage.setItem("user", JSON.stringify(authUser));
          if (!window.fcm) {
            Firebase.getToken(authUser);
            window.fcm = true;
          }
        });
      } else {
        this.setState({ authUser: null });
      }
    };

    getAccess({ user_accesses: access }) {
      const accessibleModulesSet = new Set();
      const accessibleWidgetsMap = new Map();
      // console.log("initial", accessibleWidgetsMap)
      const rolesSet = new Set();
      if (access?.length > 0) {
        for (let modules of access) {
          if (
            modules.hasOwnProperty("role") &&
            modules.role.hasOwnProperty("role_name")
          ) {
            rolesSet.add(modules.role.role_name);
          }
          if (modules.hasOwnProperty("modules") && modules.modules.length > 0) {
            for (let module of modules.modules) {
              if (
                module.hasOwnProperty("module") &&
                module.module.hasOwnProperty("module_name")
              ) {
                accessibleModulesSet.add(module.module.module_name);
                if (
                  module.hasOwnProperty("widgets") &&
                  module.widgets.length > 0
                ) {
                  for (let widget of module.widgets) {
                    if (
                      widget.hasOwnProperty("widget") &&
                      widget.widget.hasOwnProperty("widget_name")
                    ) {
                      if (accessibleWidgetsMap.has(`${module?.module?.module_name}${widget.widget.widget_name}`)) {
                        // Checking if same widgets is available, overriding access according to different roles.
                        const mappedWidget = accessibleWidgetsMap.get(
                          `${module?.module?.module_name}${widget.widget.widget_name}`
                        );
                        if (mappedWidget) {
                          const permissions = widget.widget?.permissions;
                          const updatedWidget = {};
                          updatedWidget.linked_to = widget.widget.linked_to;
                          updatedWidget.key_code = widget.widget.key_code;
                          updatedWidget.white_listed_ips =
                            widget.widget.white_listed_ips;
                          updatedWidget.widget_name = widget.widget.widget_name;
                          // Read, Write
                          updatedWidget.has_create_permission =
                            Boolean(permissions?.has_create_permission) ||
                            Boolean(mappedWidget?.has_create_permission);
                          updatedWidget.has_delete_permission =
                            Boolean(permissions?.has_delete_permission) ||
                            Boolean(mappedWidget?.has_delete_permission);
                          updatedWidget.has_read_permission =
                            Boolean(permissions?.has_read_permission) ||
                            Boolean(mappedWidget?.has_read_permission);
                          updatedWidget.has_update_permission =
                            Boolean(permissions?.has_update_permission) ||
                            Boolean(mappedWidget?.has_update_permission);
                          updatedWidget.has_approve_permission =
                            Boolean(permissions?.has_approve_permission) ||
                            Boolean(mappedWidget?.has_approve_permission);
                          updatedWidget.has_special_permission =
                            Boolean(permissions?.has_special_permission) ||
                            Boolean(mappedWidget?.has_special_permission);
                          // Device
                          updatedWidget.is_accessible_on_mobile =
                            widget.widget.is_accessible_on_mobile ||
                            Boolean(mappedWidget?.is_accessible_on_mobile);
                          updatedWidget.is_accessible_on_web =
                            widget.widget.is_accessible_on_web ||
                            Boolean(mappedWidget?.is_accessible_on_web);
                          updatedWidget.is_ip_restricted =
                            widget.widget.is_ip_restricted ||
                            Boolean(mappedWidget?.is_ip_restricted);
                          accessibleWidgetsMap.set(
                            `${module?.module?.module_name}${widget.widget.widget_name}`,
                            updatedWidget
                          );
                        }
                      } else {
                        if (
                          isIPRestrictionEnabled &&
                          widget?.widget?.is_ip_restricted
                        ) {
                          if (this.state.privateIPv4) {
                            for (let ip of widget.widget?.white_listed_ips) {
                              if (this.state.privateIPv4 === ip?.ip_address) {
                                accessibleWidgetsMap.set(
                                  `${module?.module?.module_name}${widget.widget.widget_name}`,
                                  {
                                    ...widget.widget,
                                    ...widget.widget.permissions,
                                  }
                                );
                                break;
                              }
                            }
                          } else {
                            console.log(
                              "Something went wrong with IP detection. Getting ",
                              this.state.privateIPv4,
                              " as your private IP on Portal. So, you're unable to get access for \"",
                              widget.widget.widget_name,
                              '"'
                            );
                          }
                        } else {
                          accessibleWidgetsMap.set(`${module?.module?.module_name}${widget.widget.widget_name}`, {
                            ...widget.widget,
                            ...widget.widget.permissions,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return {
        roles: Array.from(rolesSet),
        accessibleWidgets: Array.from(accessibleWidgetsMap.values()),
        accessibleModules: Array.from(accessibleModulesSet),
      };
    }

    refreshToken = (user) =>
      new Promise((resolve, reject) => {
        // console.log(user)
        if (user?.token) {
          axios({
            // data: { token: user.token },
            baseURL: AUTH_URL.REFRESH_TOKEN,
            method: "get",
          })
            .then((response) => {
              const { data, status, statusText } = response;
              if (status === 200) {
                this.eventDispatcher({ ...user, token: data.refresh_token });
                resolve(statusText);
              } else reject(new Error("Error while refreshing token."));
            })
            .catch(reject);
        } else reject(new Error("Invalid/unavailable token."));
      });

    loginArgumentsValidator = (userId, pin) => {
      if (typeof userId !== "undefined" && typeof pin !== "undefined")
        return true;
      return false;
    };

    loginWithUsernamePassword = (username, password) =>
      new Promise((resolve, reject) => {
        let errorMessage = "Invalid arguments.";
        const validBody = this.loginArgumentsValidator(username, password);
        if (!validBody) reject(new Error(errorMessage));
        axios({
          data: { username, password },
          baseURL: AUTH_URL.CREDENTIAL,
          method: "post",
        })
          .then((response) => {
            if (response.status === 200) {
              this.eventDispatcher(response.data.data);
              //this.state.privateIPv4(response.data.data);
              this.setState({ privateIPv4: response.data.data });
              console.log("loggedin", response.data.data);
              resolve(response.data.status);
            } else {
              errorMessage = "Login failed.";
              reject(new Error(errorMessage));
            }
          })
          .catch(reject);
      });

    loginWithPhoneNumber = (phoneNumber, otp) =>
      new Promise((resolve, reject) => {
        let errorMessage = "Invalid arguments.";
        const validBody = this.loginArgumentsValidator(phoneNumber, otp);
        if (!validBody) reject(new Error(errorMessage));
        axios({
          method: "post",
          url: AUTH_URL.PHONE,
          data: {
            phone: phoneNumber,
            otp: otp,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              this.eventDispatcher(response.data.data);
              resolve(response.data.status);
            } else {
              errorMessage = "Login failed.";
              reject(new Error(errorMessage));
            }
          })
          .catch((e) => reject(e.response));
      });

    getMobileOTP = (phoneNumber) =>
      new Promise((resolve, reject) => {
        axios
          .post(AUTH_URL.GET_OTP, {
            phone: phoneNumber,
          })
          .then(resolve)
          .catch(reject);
      });

    getMobileUseOtp = (userID) =>
      new Promise((resolve, reject) => {
        let errorMessage = "Invalid arguments.";
        const validBody = this.loginArgumentsValidator(userID, userID);
        if (!validBody) reject(new Error(errorMessage));
        axios
          .post(AUTH_URL.GET_USE_OTP_LOGIN, {
            credential: userID
          })
          .then(resolve)
          .catch(reject);
      });
    verifyMobileUseOtp = (data) =>
      new Promise((resolve, reject) => {
        let errorMessage = "Invalid arguments.";
        const validBody = this.loginArgumentsValidator(data.credential, data.otp);
        if (!validBody) reject(new Error(errorMessage));
        axios
          .post(AUTH_URL.VERIFY_USE_OTP_LOGIN, data)
          .then((response) => {
            if (response.status === 200) {
              this.eventDispatcher(response.data.data);
              //this.state.privateIPv4(response.data.data);
              this.setState({ privateIPv4: response.data.data });
              console.log("loggedin", response.data.data);
              resolve(response.data.status);
            } else {
              errorMessage = "Login failed.";
              reject(new Error(errorMessage));
            }
          })
          .catch(error => {
            if (error['response']['status'] === 400) {
              resolve(error)
            }
            resolve(error)
          });
      });

    logout = () => {
      console.log("LOGOUT,isOffline =", this.state?.isOffline)
      if (this.state?.isOffline !== true) {
        let userData = localStorage.getItem("user")
        userData = JSON.parse(userData)
        let newUuid = localStorage.getItem('newUuid')
        console.log(newUuid)
        // newUuid = JSON.parse(newUuid)
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("last_action_at");
        window.fcm = false;
        this.eventDispatcher();
        console.log(userData, newUuid)
        const data = {
          device_id: newUuid,
          user_id: userData?.user_id
        }
        axios.post(AUTH_URL.LOGOUT, data)
          .then()
          .catch();
      }
    };

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component
            {...this.props}
            loginWithUsernamePassword={this.loginWithUsernamePassword}
            loginWithPhoneNumber={this.loginWithPhoneNumber}
            getMobileOTP={this.getMobileOTP}
            logout={this.logout}
            refreshToken={this.refreshToken}
            getMobileUseOtp={this.getMobileUseOtp}
            verifyMobileUseOtp={this.verifyMobileUseOtp}
          />
        </AuthUserContext.Provider>
      );
    }
  }
  return WithAuthentication;
};

export { AuthUserContext };
export default withAuthentication;
