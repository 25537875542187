import React, { Component } from "react";
import OrderCard from "../common/OrderCard";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor } from "../../../styles/colors";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  confirmMainBody: {
    width: "100%",
  },
  messageShowContainer: {
    width: "100%",
    // display: "flex",
    // justifyContent: "center",
  },
  messageShow: {
    // width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // padding: "30px",
    fontSize: "1.1em",
    color: bottomNavigationFontColor,

  },

};
class Confirm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      details: null,
    }
  }
  componentDidMount() {
    // console.log(this.props.orders)
    // console.log("render")
    // console.log(this.props)
    let orderId = this.props.match.url.split("/")
    let location = this.props.location
    if (location.state && !this.props.details) {
      this.setState({
        details: location.state.approvedOrder
      })
    } else if (!this.props.details) {
      this.props.handleSingleOrders(null, orderId[3])
    }
    // else if (this.props.orders.length > 0) {
    //   let order = this.props.orders && this.props.orders.find(order => order.order_number === this.props.location.pathname.split("/")[3])
    //   console.log(order)
    //   this.props.handleSingleOrders(order);
    // }
    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = localStorage.getItem("currentUrl")
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
  }
  render() {
    const { classes, details, isMobile, orders } = this.props;
    if (orders && orders.length > 0 && !details) {
      let order = this.props.orders && this.props.orders.find(order => order.order_number === this.props.location.pathname.split("/")[3])
      // console.log(order)
      this.props.handleSingleOrders(order);
    }
    // console.log(this.props)
    return (
      <div className={classes.confirmMainBody}>
        {!isMobile ? (
          <div style={{ padding: "10px 0px 10px 4%" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                if (this.props?.location?.state?.message)
                  this.props.history.push("/home")
                else
                  this.props.history.push("/orders")
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          </div>
        ) : null}
        <OrderCard title="Approval pending" details={details ? details : this.state.details} isMobile={isMobile} />
        <div className={classes.messageShowContainer} style={{}}>
          <div className={classes.messageShow} style={{ padding: !isMobile ? "20px 6% 10px 6%" : "20px 5% 10px 6%", }}>
            <span>The Contract is pending for approval by REAL Group. The details will be available here when the Contract is approved.</span>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Confirm);
