import React from "react";
import { Paper, Typography } from "@material-ui/core";
//import "./styles.css";

import {
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
    BarChart,
    Bar,
    ResponsiveContainer,
    Label
} from "recharts";
import NoChartGraph from "./NoChartGraph";
import { pxToEm } from "../../../../methods";

// const data = [
//     { month: "Apr", months: 520, monthsx: 220 },
//     { month: "May", months: 120, monthsx: 320 },
//     { month: "Jun", months: 150, monthsx: 450 },
//     { month: "Jul", months: 200, monthsx: 500 },
//     { month: "Aug", months: 300, monthsx: 600 },
//     { month: "Sep", months: 200, monthsx: 300 },
//     { month: "Oct", months: 400, monthsx: 700 },
//     { month: "Nov", months: 600, monthsx: 210 },
//     { month: "Dec", months: 600, monthsx: 260 },
//     { month: "Jan", months: 600, monthsx: 300 },
//     { month: "Feb", months: 600, monthsx: 201 },
//     { month: "Mar", months: 600, monthsx: 442 }

// ];

const data2 = [
    { plant: "API", plants: 100, plantx: 200 },
    { plant: "RIPL", plants: 400, plantx: 420 },
    { plant: "NOVA", plants: 1000, plantx: 2100 },
    { plant: "SHIVALAYA", plants: 1500, plantx: 1700 },


];

const CustomTooltip = ({ active, payload, label, }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    border: "1px solid grey",
                    opacity: '0.7',
                    width: '100%',
                    maxHeight: '100%',
                    fontFamily: 'Poppins',
                    fontSize: pxToEm(15),
                    padding: '0 8px'
                }}>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.dataKey} : ${Number(entry.value).toFixed(3)}`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

const formatValueInThousands = (value) => {
    return `${value / 1000}k`;
};

const RenderLegend = (props) => {
    const { payload, graph, extraData } = props;
    return (
        graph === 'order_booking_table' ?
            <div style={{
                display: 'flex',
                flexDirection: props?.isMobile && 'column',
                position: 'absolute',
                // bottom: 30,
                left: !Boolean(props?.isMobile) && '25%',
                backgroundColor: 'white',
                border: "1px solid grey",
                opacity: '0.7',
                width: 'max-content',
                fontFamily: 'Poppins',
                fontSize: pxToEm(15),
                padding: '6px 16px',
                margin: 20
            }}>
                {/* <span style={{ paddingRight: 20 }}>
                    {`Total Quantity (MTS) : ${1554}  |  Discount : ${421}  | ExRate : ${45371}`}
                </span> */}
                <span style={{ padding: props?.isMobile ? '0 20px 20px 0' : '0 20px 0 0', fontWeight: 500 }}>{`Total Quantity (MTS) : ${extraData?.totalQuantity} `}</span>
                <span style={{ display: props?.isMobile && 'none' }}> | </span>
                <span style={{ padding: props?.isMobile ? '0 20px 20px 20px' : '0 20px', fontWeight: 500 }}>{`Discount : ${extraData.discount} `}</span>
                <span style={{ display: props?.isMobile && 'none' }}> | </span>
                <span style={{ padding: props?.isMobile ? '0 0 20px 20px' : '0 0 0 20px', fontWeight: 500 }}>{`ExRate : ${extraData.Ex_rate} `}</span>
            </div>
            : null
    )
}


function LineSalesGraph({
    data, lineColor, yearSelected, monthSelected, weekSelected, todaySelected, yesterdaySelected, hasLineChartData, graph = '', extraData = "", tickFormatter = "", isMobile = ''
}) {
    if (!(yearSelected || monthSelected || weekSelected || todaySelected || yesterdaySelected)) {
        return <div style={{ textAlign: "center" }}>
            <img src="/nochart.png" alt="No Chart" />
        </div>
    } else if (!hasLineChartData && (yearSelected || monthSelected || weekSelected || todaySelected || yesterdaySelected)) {
        return <>
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
                <img src="/nochart2.png" alt="No Chart" />
            </div>
            <div style={{ textAlign: "center", fontSize: "20px", color: "grey" }}>No Data Found</div>
        </>
    }

    return (
        <Paper elevation={3}>
            {/* {console.log(data, extraData)} */}
            <ResponsiveContainer width="100%" height={450}>
                <LineChart
                    data={data}
                    //margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    margin={{ top: 20, right: 40, left: 40, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="chartKey" allowDuplicatedCategory={false} style={{ fontFamily: 'Poppins', }} />
                    <YAxis style={{ fontFamily: 'Poppins', }} tickFormatter={!(todaySelected || yesterdaySelected) && formatValueInThousands}>
                        <Label
                            value={graph === 'order_booking_table'
                                ? 'Quantity (MTS)'
                                : null}
                            angle={-90}
                            position="left"
                            style={{
                                margin: 20,
                                fontFamily: 'Poppins',
                                fontSize: pxToEm(14)
                            }}
                        />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} shared={false} />
                    <Legend content={<RenderLegend graph={graph} extraData={extraData} isMobile={isMobile} />} />
                    {
                        data?.map((d, i) => (<Line type="linear" dataKey={d[i]} stroke={lineColor.find(c => c.id == i)?.color} strokeWidth={3} />))
                    }
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
}



function BarSalesGraph({ data, color, hasPlantChartData }) {
    if (!hasPlantChartData) {
        return <>
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
                <img src="/nochart2.png" alt="No Chart" />
            </div>
            <div style={{ textAlign: "center", fontSize: "20px", color: "grey" }}>No Data Found</div>
        </>
    }
    const plants = Object?.keys(data[0] || {})?.filter(d => d != "plant")
    return (
        <Paper elevation={3}>
            <ResponsiveContainer width="100%" height={450}>
                <BarChart data={data}
                    margin={{ top: 20, right: 40, left: 40, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="" />
                    <XAxis dataKey="plant" style={{ fontFamily: 'Poppins', }} />
                    <YAxis tickFormatter={formatValueInThousands} style={{ fontFamily: 'Poppins', }} />
                    <Tooltip content={<CustomTooltip />} shared={false} />
                    {
                        plants?.map((p) => (<Bar
                            dataKey={p}
                            fill={color?.find(c => c.name == p)?.color}
                            className="bar"
                            barSize={20}
                            radius={[10, 10, 0, 0]}
                        />))
                    }
                </BarChart>
            </ResponsiveContainer>
        </Paper>
    );
}

export {
    LineSalesGraph,
    BarSalesGraph,
}