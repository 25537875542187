const INTERNALROUTES = {
  DASHBOARD: "/internalDashboard",
  DASHBOARD_DISPATCH_QTY: "/internalDashboard/dispatchqty",
  DAILYRATES: "/internalDailyRates",
  ORDERS: "/internalContracts",
  SALESTARGET: "/internalSalesTarget",
  SALESTARGET_DISTRIBUTOR_SCHEME: "/internalSalesTarget/distributor/scheme",
  SALESTARGET_DISTRIBUTOR_SCHEME_CREATE: "/internalSalesTarget/distributor/scheme/addScheme",
  SALESTARGET_DISTRIBUTOR_MANAGE: "/internalSalesTarget/distributor/manage",
  SALESTARGET_DEALER_SCHEME: "/internalSalesTarget/dealer/scheme",
  SALESTARGET_DEALER_SCHEME_CREATE: "/internalSalesTarget/dealer/scheme/addScheme",
  SALESTARGET_DEALER_MANAGE: "/internalSalesTarget/dealer/manage",
  SALESTARGET_SALESPERSON_TARGET: "/internalSalesTarget/salesPerson/target",
  SALESTARGET_SALESPERSON_TARGET_CREATE: "/internalSalesTarget/salesPerson/target/addScheme",
  SALESTARGET_SALESPERSON_MANAGE: "/internalSalesTarget/salesPerson/manage",
  LOADINGADVICE: "/internalLoadingAdvices",
  LOADINGADVICEPLANTID: "/internalLoadingAdvices/:plantId",
  INVOICEANDPAYMENTS: "/internalInvoicesAndPayments",
  CUSTOMERS: "/internalCustomers",
  WEIGHBRIDGES: "/weighbridges",
  USERANDPERMISSION: "/internalUsersAndPermissions",
  UANDP_ROLES_AND_PERMISSIONS:
    "/internalUsersAndPermissions/rolesAndPermissions",
  UANDP_INTERNAL_USERS: "/internalUsersAndPermissions/internalUsers",
  UANDP_ACCESS_AREA_MGMT: "/internalUsersAndPermissions/accessAreaManagement",
  MASTERSMANAGMENT: "/internalMastersManagement",
  LISTITEMCATEGORIESMASTER: "/internalMastersManagement/itemsCategory",
  LISTSTATEMASTER: "/internalMastersManagement/stateDisplay",
  LIST_PAYMENT_TERMS: "/internalMastersManagement/paymentTerms",
  LIST_PLANT_ITEM_MASTER: "/internalMastersManagement/plantItemMaster",
  LIST_TAGS_MASTER: "/internalMastersManagement/tagsMaster",
  LOGIN: "/login",
  LOADER: "/loader",
  DISPATCH_PLANNING: "/dispatchPlanning",
  E_INVOICING_AND_E_WAY_BILLING: "/internalEInvoicingAndEWayBilling",
  CREATE_E_INVOICING: "/internalEInvoicingAndEWayBilling/create/:t_code",
  VIEW_AND_DOWNLOAD_INVOICE:
    "/internalEInvoicingAndEWayBilling/downloadInvoice",
  DISPATCH_OPERATION: "/dispatchOperation",
  CREATE_LOADING_ADVICE_FROM_ORDER:
    "/internalContracts/createLoadingAdviceFromOrder",
  BANK_MASTER: "/internalMastersManagement/bank",
  BANK_MASTER_DATA_DISPLAY: "/internalMastersManagement/bank/displayData",
  TDS_RATE: "/internalMastersManagement/tdsRate",
  TRANSPORTER: "/internalMastersManagement/transporter",
  TCS: "/internalMastersManagement/tcs",
  TCSMATRIX: "/internalMastersManagement/tcsMatrix",
  UNIT: "/internalMastersManagement/unit",
  PINCODE: "/internalMastersManagement/pincode",
  REPORTS: "/reports",
  DYNAMIC_REPORTS: "/dynamicReports",
  ITEM_NATURE: "/internalMastersManagement/itemNature",
  REASON: "/internalMastersManagement/reason",
  CUSTOMER_TYPE: "/internalMastersManagement/customerType",
  APP_BANNER: "/internalMastersManagement/appBanner",
  APP_VERSION: "/internalMastersManagement/appVersion",
  ITEM_SIZE: "/internalMastersManagement/itemSize",
  DISPATCH_TRACKING: "/dispatchTracking",
  CUSTOMER_REQUESTS: "/internalCustomerRequests",
  LISTS_LEDGER_REQUESTS: "/internalCustomerRequests/ledgerRequests",
  USER_PROFILE: "/userProfile",
  USER_PROFILE_CHANGE_PASSWORD: "/userProfile/changePassword",
};

export default INTERNALROUTES;
