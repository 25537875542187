import React, { Component, useState, useEffect } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    Select,
    MenuItem,
    TextField,
    Typography,
    Button,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Tab, Tabs,
    Divider,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    CircularProgress
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import {
    primaryColor,
    drawerTextColor,
    whiteSmoke, grey,
    secondaryGrey
} from "../../../../constants/internal/colors";
import { pxToEm, checkApiError, checkInvalidValue, hasApproveAccess, hasReadAccess, handleSnackbarMessageBox } from "../../../../methods";

import "../../../../styles/internal/scroll.css";
import debounce from "lodash.debounce";
import Bread from "../../common/Breadcrubs";
import ToolTipComponent from "../../common/ToolTipComponent";
import Table from "../../common/Table";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes"
import axios from "axios";
import moment from "moment";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import GetAppIcon from '@mui/icons-material/GetApp';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { handleCustomerRequestsTable } from '../../customerRequets/web/ledgerPdf'
import SnackbarComponent from "../../../common/SnackbarComponent"
import SpinLoader from "../../../common/SpinLoader";




const useStyles = ((theme) => ({
    primaryBackground: {
        backgroundColor: primaryColor,
        color: drawerTextColor,
        fontSize: pxToEm(15),
    },
    filterSection: (isMobile) => ({
        margin: "25px 0px 20px 0px",
        display: "flex",
        flexDirection: isMobile && 'column',
        alignItems: isMobile ? 'flex-start' : "center",
        flex: "1",
    }),
    searchAutoComplete: {
        display: "flex",
        flex: 5,
    },
    filterAlignment: {
        display: "flex",
        alignItems: "center",
        flex: 4,
        placeContent: 'space-evenly'
    },
    addButton: {
        display: "flex",
        flex: 2,
        placeContent: "flex-end",
    },
    searchBox: (isMobile) => ({
        width: isMobile ? '100%' : "65%",
        marginBottom: isMobile && 15
    }),
    formControl: {
        margin: '0',
        width: 200
    },
    mainDiv: {
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        paddingBottom: "30px",
    },
    rightPadding: { paddingRight: "25px" },
    tabContainerStyle: {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: "15px",
        display: "flex",
    },
    menuText: {
        display: 'flex',
        fontFamily: 'Poppins',
        fontWeight: '800',
        color: "#3B69B0",
        fontWeight: '600',
        justifyContent: 'flex-start',
        fontSize: pxToEm(16),
        lineHeight: 1
    },
    tabContainerChildStyle: (tabValue) => ({
        width: tabValue === 2 ? "85%" : "100%",
        display: "flex",
    }),
    tabStyle: (tabValue, currentTab) => ({
        backgroundColor: whiteSmoke,
        color: tabValue === currentTab ? grey : secondaryGrey,
        fontSize: "0.9em",
        fontWeight: "bold",
        width: currentTab === 0 ? "37%" : "33%",
    }),
}));
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    loaderStyle: { color: primaryColor, marginLeft: 10 },
    // noDataDiv: {
    //     width: "100%",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     marginTop: "40px",
    //     display: "flex",
    //     height: "calc(100vh - 250px)",
    // },
};

const customerType = [
    // "All",
    "BROKER",
    "CONSUMER",
    "DEALER",
    "DISTRIBUTOR",
    "TRADER"
]

const CompanyField = (props) => {
    console.log(props)
    return (
        <div style={{ display: 'flex', flexDirection: 'column', }}>
            <span style={{ whiteSpace: 'break-spaces' }}>{props.row.company_name}</span>
            <span style={{ fontSize: pxToEm(14) }}>
                ({!checkInvalidValue(props.row?.gst) ? props.row?.gst : props.row?.pan})
            </span>
        </div>
    )
}

const ActionMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openRejectLedgerDialog, setOpenRejectLedgerDialog] = useState(false);
    const [Ledger_Reject_Reason, setLedger_Reject_Reason] = useState("");
    const [downloadPDFLoading, setDownloadPDFLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(JSON.parse(localStorage.getItem(`customer_request_fetchLoader_${props.row}`)));
    const [shouldDisableClose, setShouldDisableClose] = useState(false);

    // const { fetchLoader } = props;

    const open = Boolean(anchorEl);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadCount = () => {
        props.downloadCountChange(props.row)
    }

    const handleFetchLedgerPDFInAction = () => {
        props.tableActionLoader('fetchLoader', props.row, true);
        setFetchLoading(true)
        props.handleLedgerView(props.row)
    }
    const handleDownloadOpeningPDFLoader = (data = '') => {
        setDownloadPDFLoading(data === ""
            ? props.tableActionLoader('downloadLoader', props.row, false)
            : props.tableActionLoader('downloadLoader', props.row, data)
        )
    }
    const handleErrorSnackbar = (response) => {
        if (response?.status === 500) {
            props.handleOpenSnackbar('server error', 'error')
            setTimeout(() => {
                props.handleCloseSnackbar();
            }, 3000)
        }
        else {
            props.handleOpenSnackbar(
                handleSnackbarMessageBox(checkApiError(response?.data)),
                "error"
            );
            setTimeout(() => {
                props.handleCloseSnackbar();
            }, 4000)
        }
    }
    const snackbarPDFDownload = (dataLoad = '') => {
        props.handleOpenSnackbar('Downloading PDF...' + dataLoad, 'success')
    }
    const handleDownloadLedgerPDFInAction = () => {
        handleDownloadOpeningPDFLoader(true)
        handleCustomerRequestsTable(props.row, "PRINT", snackbarPDFDownload, handleDownloadOpeningPDFLoader, '', handleErrorSnackbar, handleDownloadCount)
        // setTimeout(() => {
        //     props.handlePageData(props.status === "APPROVED" ? "APPROVED" : "NEW")
        // }, 3000);

    }
    const handleOpenRejectledgerDialog = () => {
        setOpenRejectLedgerDialog(true);
    };

    const handleCloseRejectLedgerDialog = () => {
        setOpenRejectLedgerDialog(false);
        setLedger_Reject_Reason("");
    };
    const handleLedgerApproveInAction = () => {
        props.handleLedgerApprove(props.row)
    }
    const handleLedgerRejectInAction = () => {
        props.handleLedgerReject(props.row, Ledger_Reject_Reason)
    }
    const handleLedgerResetInAction = () => {
        props.handleLedgerReset(props.row)
        setTimeout(() => {
            props.handlePageData(props.status === "APPROVED" ? "APPROVED" : "NEW")
        }, 3000);
    }
    const handleDropdownChange = (e) => {
        // console.log(e)
        setLedger_Reject_Reason(e.target.value);
        e.stopPropagation();
    }
    return (
        <div>
            {(props.status === "PENDING" || props.status === "APPROVED") &&
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {props.icon}
                </Button>
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {((props.status === "PENDING") || (props.status === "APPROVED")) ?
                    <ToolTipComponent
                        title="You have no approve permission on this widget"
                        condition={props.status === "PENDING" && !hasApproveAccess(props.user, 'ledger_request')}
                        title2="You have no read permission on this widget"
                        condition2={props.status === "APPROVED" && !hasReadAccess(props.user, 'ledger_request')}
                        title3="Fetch limit exceeded"
                        condition3={!props.fetched && props.noOfFetch + 1 > 5}
                    >
                        <MenuItem
                            disabled={(props.status === "PENDING"
                                ? !hasApproveAccess(props.user, 'ledger_request')
                                : props.status === "APPROVED" && !hasReadAccess(props.user, 'ledger_request')) ||
                                (props.fetched
                                    ? Boolean(localStorage.getItem(`customer_request_downloadLoader_${props.row}`))
                                    : Boolean(fetchLoading)
                                        ? true
                                        : props.noOfFetch + 1 > 5)}
                            className={classes.menuText}

                            style={{
                                fontWeight: '600',
                                justifyContent: 'flex-start',
                                fontSize: pxToEm(16),
                                lineHeight: 1
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (props?.fetched) {
                                    handleDownloadLedgerPDFInAction()
                                }
                                else {
                                    handleFetchLedgerPDFInAction()
                                }
                            }}
                        >
                            {props.item1Icon} {props.fetched
                                ? `${props.item5}#${Number(props.noOfDownloads + 1)}`
                                : props.status === "PENDING" && `${props.item1}#${Number(props.noOfFetch + 1)}`
                            }
                            {
                                ((props.fetched && Boolean(localStorage.getItem(`customer_request_downloadLoader_${props.row}`))) ||
                                    (!props.fetched && Boolean(fetchLoading))) &&
                                <CircularProgress
                                    size={15}
                                    style={{ marginLeft: "10px" }}
                                />
                            }
                        </MenuItem>
                    </ToolTipComponent>
                    : null}
                {(props.status === "PENDING" && props.fetched) ?
                    <ToolTipComponent
                        title="You have no approve permission on this widget"
                        condition={!hasApproveAccess(props.user, 'ledger_request')}
                    >
                        <Divider
                            variant='middle'
                            style={{
                                margin: '2% 0',
                            }}
                        />
                        <MenuItem
                            onClick={(e) => {
                                e.preventDefault();
                                handleLedgerResetInAction()
                            }}
                            className={classes.menuText}
                            disable={!hasApproveAccess(props.user, 'ledger_request')}
                            style={{
                                fontWeight: '600',
                                justifyContent: 'flex-start',
                                fontSize: pxToEm(16),
                                lineHeight: 1
                            }}>{props.item2Icon} {props.item2}</MenuItem>
                    </ToolTipComponent>
                    : null}
                {(props.status === "PENDING") ?
                    <ToolTipComponent
                        title="You have no approve permission on this widget"
                        condition={!hasApproveAccess(props.user, 'ledger_request')}
                        title2="Please Fetch Data First"
                        condition2={!props.fetched}
                    >
                        <Divider
                            variant='middle'
                            style={{
                                margin: '2% 0',
                            }} />
                        <MenuItem
                            onClick={(e) => {
                                e.preventDefault();
                                handleLedgerApproveInAction()
                            }}
                            disabled={!hasApproveAccess(props.user, 'ledger_request') || !props.fetched}
                            className={classes.menuText}
                            style={{
                                fontWeight: '600',
                                justifyContent: 'flex-start',
                                fontSize: pxToEm(16),
                                lineHeight: 1
                            }}>{props.item3Icon} {props.item3}
                        </MenuItem>
                    </ToolTipComponent>
                    : null}
                {(props.status === "PENDING") ?
                    <>
                        <ToolTipComponent
                            title="You have no approve permission on this widget"
                            condition={!hasApproveAccess(props.user, 'ledger_request')}
                        >
                            <Divider
                                variant='middle'
                                style={{
                                    margin: '2% 0',
                                }} />
                            <MenuItem
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (hasApproveAccess(props.user, 'ledger_request'))
                                        handleOpenRejectledgerDialog(true)
                                }}
                                disable={!hasApproveAccess(props.user, 'ledger_request')}
                                className={classes.menuText}
                                style={{
                                    fontWeight: '600',
                                    justifyContent: 'flex-start',
                                    fontSize: pxToEm(16),
                                    lineHeight: 1,
                                    color: !hasApproveAccess(props.user, 'ledger_request') && 'rgba(0, 0, 0, 0.46)'
                                }}
                            >
                                {props.item4Icon} {props.item4}
                            </MenuItem>
                        </ToolTipComponent>
                        <Dialog
                            open={openRejectLedgerDialog}
                            onClose={handleCloseRejectLedgerDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth="xs"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Please Confirm"}
                            </DialogTitle>

                            <DialogContent>
                                <div style={{
                                    minWidth: 340,
                                    margin: '5px 0 15px',
                                }}>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                    >
                                        <InputLabel id="shortClose" style={{ marginLeft: "28px" }}>
                                            Select Cancellation Reason*
                                        </InputLabel>
                                        <Select
                                            id="Cancellation Reason"
                                            labelId="CancelLA"
                                            variant="outlined"
                                            label=" Select Cancellation Reason*"
                                            name="Ledger_Reject_Reason"
                                            style={{ width: 340, marginLeft: "28px" }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 45 * 4 + 10,
                                                    },
                                                },
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                }
                                            }}
                                            value={Ledger_Reject_Reason}
                                            onChange={
                                                handleDropdownChange
                                            }
                                        >
                                            {props.reasonMaster !== undefined && props.reasonMaster.length > 0
                                                ? props.reasonMaster?.map((data) => (
                                                    <MenuItem key={data.reason} value={data?.id}>
                                                        {data?.reason}
                                                    </MenuItem>
                                                ))
                                                :
                                                <CircularProgress
                                                    disableShrink
                                                    style={classes.loaderStyle}
                                                />
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span>
                                        Are you sure you want to Reject this Ledger Request?
                                    </span>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseRejectLedgerDialog}>NO</Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor:
                                            Ledger_Reject_Reason
                                                ? primaryColor
                                                : 'rgba(0, 0, 0, 0.26)',

                                        color: drawerTextColor,
                                        textTransform: 'none',
                                        padding: '6px 25px'
                                    }}
                                    disabled={!Ledger_Reject_Reason}
                                    onClick={() => { handleLedgerRejectInAction() }}>YES</Button>

                            </DialogActions>

                        </Dialog>
                    </>
                    : null}
            </Menu>
        </div>
    )
}

const LedgerRequests = (props) => {
    const _isMounted = false;
    const classes = useStyles();
    const { isMobile } = props;
    const [tabValue, setTabValue] = useState(0);
    //const [tabData, setTabData] = [{id:0, value: "NEW"},{id :1, value: "APPROVED"},{id:2, value:"COMPLETED"}]
    const [snackbarType, setSnackbarType] = useState("")
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [reasonMaster, setReasonMaster] = useState("")
    const [customerTypeSelection, setCustomerTypeSelection] = useState([]);
    const [filters, setFilters] = useState([]); // State to store selected filters
    const [options, setOptions] = useState([]);
    const [totalLedgerCount, setTotalLedgerCount] = useState(0);
    const [searchDataPrev, setSearchDataPrev] = useState("")
    const [searchDataNext, setSearchDataNext] = useState("")
    const [name, setName] = useState()
    const [allCustomers, setAllCustomers] = useState([]);
    const [customerSearchData, setCustomerSearchData] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [filterSelection, setFilterSelection] = useState("all");
    const [allCustomersBackup, setAllCustomersBackup] = useState([]);
    const [allCustomersBackupTwo, setAllCustomersBackupTwo] = useState([]);
    const [customerLoader, setCustomerLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [tableData, setTableData] = React.useState({
        data: []
    });
    const [tableFilterData, setTableFilterData] = React.useState({
        data: []
    });
    const [loading, setLoading] = React.useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const handlePageChangeData = (data) => {
        console.log(data, "hererrere")
        setSearchDataPrev(data?.previous)
        setSearchDataNext(data?.next)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false)
        setSnackbarMessage(null)
        setSnackbarType("")

    };

    const handleOpenSnackbar = (message, type) => {
        setOpenSnackbar(true)
        setSnackbarMessage(message)
        setSnackbarType(type)
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false)
        setSnackbarMessage("")
        setSnackbarType("")
    };

    const fetchReasonCancelLA = async (moduleName) => {
        // console.log("here kk", moduleName)
        return axios.get(
            `${APIROUTES.GET_REASON}?module=${moduleName}&active=${true}`,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    // console.log("reasons", response?.data)
                    setReasonMaster(response?.data)
                } else {
                    setOpenSnackbar(true);
                    setSnackbarMessage(response?.data?.details
                        ? response?.data?.details
                        : response?.data?.message
                            ? response?.data?.message
                            : response?.details
                                ? response?.details
                                : "Error occured while fetching reason master")
                    setSnackbarType("error")
                }
            })
            .catch((error) => {
                setOpenSnackbar(true);
                setSnackbarMessage("Error occured while fetching reason master");
                setSnackbarType("error")
            });
    };

    const handleFilterChange = (event) => {
        if (event.target.value.includes('All')) {
            setFilters(['All']); // If "All" is selected, reset other selections
        } else {
            // If other options are selected, remove "All" from the selection
            const newFilters = event.target.value.filter((filter) => filter !== 'All');
            setFilters(newFilters);
        }
    };
    // new lifecycle method implementation
    // componentDidMount() {
    //     this._isMounted = true;
    //     this.setState(
    //         {
    //             customerLoader: this.props.customerLoader,
    //             allCustomers: this.props.customerSearchData,
    //             customerSearchData: this.props.customerSearchData,
    //             allCustomersBackup: this.props.customerSearchData,
    //             allCustomersBackupTwo: this.props.customerSearchData,
    //             filterSelection: this.props.selectedFilter
    //         });
    //     // if (this.props.selectedFilter === "") {
    //     //   this.setState({
    //     //     filterSelection: "all"
    //     //   }, () => console.log())
    //     //   this.props.getAllCustomersList(this.state.page, "")
    //     // }
    //     if (this.props.selectedFilter === "" || this.props.selectedFilter === "all") {
    //         this.setState({
    //             filterSelection: "all"
    //         })
    //         this.props.getAllCustomersList(this.state.page, "")
    //     }
    //     else
    //         this.props.getNewAndPendingCustomerList(this.state.page, this.props.selectedFilter)
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.props.customerLoader !== this.state.customerLoader) {
    //         this.setState({ customerLoader: this.props.customerLoader });
    //     }
    //     if (this.props.customerSearchData !== this.state.customerSearchData) {
    //         this.setState(
    //             {
    //                 allCustomers: this.props.customerSearchData,
    //                 customerSearchData: this.props.customerSearchData,
    //             });
    //     }
    //     if (this.props.customerSearchData !== this.state.allCustomersBackup) {
    //         this.setState({ allCustomersBackup: this.props.customerSearchData });
    //     }
    //     if (this.props.customerSearchData !== this.state.allCustomersBackupTwo) {
    //         this.setState({ allCustomersBackupTwo: this.props.customerSearchData });
    //     }
    // }
    // componentWillUnmount() {
    //     this._isMounted = false;
    // }
    // handleFilterChange = (event) => {
    //     // console.log(event.target.value)
    //     const { value } = event.target
    //     this.setState({
    //         page: 0
    //     })
    //     this.setState({ filterSelection: value });
    //     if (this.state.searchText === "") {
    //         if (this.state.customerTypeSelection.length === 0) {
    //             if (value !== "all")
    //                 this.props.getNewAndPendingCustomerList(0, value)
    //             else this.props.getAllCustomersList()
    //         }
    //         else {
    //             if (value === 'all') {
    //                 this.props.getCustomerTypeCustomerList(1, value, this.state.customerTypeSelection, this.state.searchText, 0)
    //             }
    //             else {
    //                 this.props.getCustomerTypeCustomerList(1, value, this.state.customerTypeSelection, this.state.searchText, 0)
    //             }
    //         }
    //     }
    //     else {
    //         this.props.getCustomerTypeCustomerList(1, value, this.state.customerTypeSelection, this.state.searchText, 0)
    //     }
    // };

    const handleSelect = (event) => {
        if (event.target.value.includes('All')) {
            setFilters([]);
        } else {
            const newFilters = event.target.value.filter((filter) => filter !== 'All');
            setFilters(newFilters);
        }
    }

    // debouncedSearch = debounce(() => {
    //     // this.setState({ searchText: event.target.value }, () => this.filterData());
    //     //this.filterData();
    //     if (this.state.customerTypeSelection.length === 0) {
    //         if (this.state.filterSelection === 'all') {
    //             this.props.getAllCustomersList(0, this.state.searchText);
    //         }
    //         else {
    //             this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0);
    //         }
    //     }
    //     else {
    //         if (this.state.filterSelection === 'all') {
    //             this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0);
    //         }
    //         else {
    //             this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0);
    //         }
    //     }
    // }, 300);

    // handleCustomerSearch = (event) => {
    //     this.setState(
    //         { searchText: event.target.value, page: 0 },
    //         this.debouncedSearch
    //     );
    // };

    // handleNextCustomerPage = (newLink, newPage) => {
    //     // console.log(this.state.filterSelection)
    //     // console.log(newPage)
    //     const filterSelection = this.state.filterSelection
    //     this.setState(
    //         { page: newPage },
    //     );
    //     // if (filterSelection === "all")
    //     //   this.props.getAllCustomersList(newPage, this.state.searchText)
    //     // else
    //     //   this.props.getNewAndPendingCustomerList(newPage, filterSelection)
    //     if (this.state.searchText === "") {
    //         if (this.state.customerTypeSelection.length === 0) {
    //             if (this.state.filterSelection === 'all') {
    //                 this.props.getAllCustomersList(newPage, this.state.searchText)
    //             }
    //             else {
    //                 this.props.getNewAndPendingCustomerList(newPage, this.state.filterSelection)
    //             }
    //         }
    //         else {
    //             if (this.state.filterSelection === 'all') {
    //                 this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink)
    //             }
    //             else {
    //                 this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink)
    //             }
    //         }
    //     }
    //     else {
    //         if (this.state.customerTypeSelection.length !== 0) {
    //             if (filterSelection === "all") {
    //                 this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink);
    //             }
    //             else {
    //                 this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink);
    //             }
    //         }
    //         else {
    //             if (filterSelection === "all") {
    //                 this.props.getAllCustomersList(newPage, this.state.searchText);
    //             }
    //             else {
    //                 this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink);
    //             }
    //         }
    //         // this.props.getAllCustomersList(0, this.state.searchText)
    //     }
    // }
    const headersNew = [

        {
            minWidth: 160,
            header: "Company Name with GST/PAN",
            key: "company_name",
            disableClickEventBubbling: true,
            renderCell: (params) => <CompanyField row={params.row} />
            // flex: 1,
        },
        {
            minWidth: 120,
            header: "UID",
            key: "uid",
            flex: 1,
        },
        {
            minWidth: 150,
            header: "Request Type With Account Code",
            key: "acc_code",
            flex: 1,
        },
        {
            minWidth: 110,
            header: "From Date",
            key: "from_date",
            flex: 1,
        },
        {
            minWidth: 110,
            header: "To Date",
            key: "to_date",
            flex: 1,
            //renderCell: (params) => <TransportInfo {...params} />
        },
        {
            minWidth: 130,
            header: " Request Date And Time",
            key: "created_at",
            flex: 1,
        },
        {
            minWidth: 110,
            header: "Status",
            key: "status",
            flex: 1,
        },
        {
            minWidth: 125,
            header: "Number Of Fetches",
            key: "number_of_fetch",
            flex: 1,
        },
        {
            minWidth: 120,
            header: "Approved By",
            key: "approved_rejected_by",
            //renderCell: (params) => <EwayState {...params} />,
            flex: 1,
        },
        {
            minWidth: 120,
            header: "Approved On",
            key: "approved_rejected_at",
            // renderCell: (params) => <EwayState {...params} />,
            flex: 1,
        },
        {
            minWidth: 100,
            header: "Action",
            key: 'action',
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => <ActionMenu icon={<MoreVertIcon />} row={params.row.id}
                params={params}
                status={params.row.status}
                fetched={params.row.fetched}
                noOfDownloads={params.row.number_of_downloads}
                noOfFetch={params.row.number_of_fetch}
                fetchLoader={Boolean(params?.row?.fetchLoader)}
                handleLedgerApprove={handleLedgerApprove}
                handleLedgerReject={handleLedgerReject}
                handleLedgerReset={handleLedgerReset}
                handleLedgerView={handleLedgerView}
                handleOnClick={handleOnClick}
                handlePageData={handlePageData}
                handleOpenSnackbar={handleOpenSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                downloadCountChange={downloadCountChange}
                tableActionLoader={tableActionLoader}
                user={props.user}
                reasonMaster={reasonMaster}
                item1="FETCH"
                item1Icon={<GetAppIcon style={{ marginRight: 8 }} />}
                item2="RESET"
                item2Icon={<RestartAltOutlinedIcon style={{ marginRight: 8 }} />}
                item3="APPROVE"
                item3Icon={<CheckCircleOutlinedIcon style={{ marginRight: 8 }} />}
                item4="REJECT"
                item5="DOWNLOAD"
                item4Icon={<HighlightOffOutlinedIcon style={{ marginRight: 8 }} />}
            />,
            flex: 1,
        },
    ];


    const getOptions = (user) => {
        return {
            headers: {
                Authorization: `JWT ${user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    };


    const handlePageData = (status) => {
        setPage(0);
        setInitialLoading(true)
        handleOnClick(status)
    }

    const handleOnClick = (status, page = 0, newLink = "") => {
        if (newLink == "" || newLink == null) {
            let baseURL = `${APIROUTES.GET_LEDGER_REQUESTS}?page=${page + 1}&status=${status}&search=${searchText}&customer_type=${filters}`
            let finalURL = baseURL

            axios({
                url: finalURL,
                method: "get",
                headers: {
                    Authorization: `JWT ${props.user.token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
                .then((response) => {
                    // console.log(response.data)
                    const formattedData = response.data?.results?.map((row, index) => ({
                        id: row.id,
                        company_name: row.company_name,
                        pan: row.pan,
                        gst: row.gst,
                        uid: row.uid,
                        acc_code: row.acc_code,
                        from_date: moment(row.from_date).format("DD-MM-YYYY"),
                        to_date: moment(row.to_date).format("DD-MM-YYYY"),
                        created_at: moment(row.created_at).format("DD-MM-YYYY"),
                        //plant_code: (plantsData.find(d => d?.plant_code === row.plant_code))?.plant_short_name,
                        status: row.status,
                        fetched: row.fetched,
                        number_of_fetch: row.number_of_fetch,
                        number_of_downloads: row.number_of_downloads,
                        approved_rejected_by: row?.approved_rejected_by,
                        approved_rejected_at: checkInvalidValue(row.approved_rejected_at) ? "" : moment(row.approved_rejected_at).format("DD-MM-YYYY"),
                    }))
                    setTableData({
                        ...tableData,
                        data: formattedData
                    })
                    // console.log(formattedData)
                    setTotalLedgerCount(response?.data?.count)
                    handlePageChangeData(response.data)
                    setSearchDataPrev(response?.data?.previous)
                    setSearchDataNext(response?.data?.next)
                    setTableFilterData({
                        ...tableFilterData,
                        data: formattedData
                    })
                    // setTableData(response.data);
                    setLoading(false);
                    setInitialLoading(false);
                })
                .catch((error) => {
                    handleOpenSnackbar(
                        handleSnackbarMessageBox(checkApiError(error.response?.data)),
                        "error"
                    );
                    setTimeout(() => {
                        handleCloseSnackbar();
                    }, 4000)
                });
        } else {
            axios({
                url: newLink,
                method: "get",
                headers: {
                    Authorization: `JWT ${props.user.token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
                .then((response) => {
                    // console.log(response.data)
                    const formattedData = response.data?.results?.map((row, index) => ({
                        id: row.id,
                        company_name: row.company_name,
                        pan: row.pan,
                        gst: row.gst,
                        uid: row.uid,
                        acc_code: row.acc_code,
                        from_date: moment(row.from_date).format("DD-MM-YYYY"),
                        to_date: moment(row.to_date).format("DD-MM-YYYY"),
                        created_at: moment(row.created_at).format("DD-MM-YYYY"),
                        //plant_code: (plantsData.find(d => d?.plant_code === row.plant_code))?.plant_short_name,
                        status: row.status,
                        fetched: row.fetched,
                        number_of_fetch: row.number_of_fetch,
                        number_of_downloads: row.number_of_downloads,
                        approved_rejected_by: row?.approved_rejected_by,
                        approved_rejected_at: checkInvalidValue(row.approved_rejected_at) ? "" : moment(row.approved_rejected_at).format("DD-MM-YYYY"),
                    }))
                    setTableData({
                        ...tableData,
                        data: formattedData
                    })
                    setTotalLedgerCount(response?.data?.count)
                    handlePageChangeData(response.data)
                    setTableFilterData({
                        ...tableFilterData,
                        data: formattedData
                    })
                    // console.log(formattedData)
                    // setTableData(response.data);
                    setLoading(false);
                    setInitialLoading(false);
                })
                .catch((error) => {
                    handleOpenSnackbar(
                        handleSnackbarMessageBox(checkApiError(error.response?.data)),
                        "error"
                    );
                    setTimeout(() => {
                        handleCloseSnackbar();
                    }, 4000)
                });
        }
    };

    const handleLedgerApprove = (row) => {
        const payload = {
            status: "APPROVED"
        }
        axios.post(
            `${APIROUTES.APPROVE_LEDGER_REQ}${row}/`, payload,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    setOpenSnackbar(true)
                    setSnackbarMessage("Ledger Request Approved")
                    setSnackbarType("success")
                    handlePageData("NEW");
                    setTimeout(() => {
                        handleCloseSnackbar();
                    }, 3000)
                } else {
                    setOpenSnackbar(true)
                    setSnackbarMessage("Error occured while approving Ledger Request")
                    setSnackbarType("error")
                }
            })
            .catch((error) => {
                setSnackbarMessage("Error occured while approving Ledger Request")
                handleOpenSnackbar(
                    checkApiError(error.response.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000)
            });
    };

    const downloadCountChange = (row) => {
        let formattedData = tableFilterData.data.map((ele) => (
            ele.id === row ? {
                ...ele,
                number_of_downloads: ele.number_of_downloads + 1,
            }
                : ele
        ))
        setTableFilterData({
            ...tableFilterData,
            data: formattedData
        })
    }

    const tableActionLoader = (loaderName, row, loaderAction) => {
        loaderAction === false
            ? localStorage.removeItem(`customer_request_${loaderName}_${row}`)
            : localStorage.setItem(`customer_request_${loaderName}_${row}`, loaderAction)
    }

    const handleLedgerView = (row) => {
        // tableActionLoader('fetchLoader', row, true)
        axios.get(
            `${APIROUTES.VIEW_LEDGER_PDF}?id=${row}`,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    let formattedData = tableFilterData.data.map((ele) => (
                        ele.id === row ? {
                            ...ele,
                            fetched: true,
                            number_of_fetch: ele.number_of_fetch + 1,
                        }
                            : ele
                    ))
                    setTableFilterData({
                        ...tableFilterData,
                        data: formattedData
                    })
                    tableActionLoader('fetchLoader', row, false)
                    setOpenSnackbar(true)
                    setSnackbarMessage("Ledger Request Fectched")
                    setSnackbarType("success")
                    setTimeout(() => {
                        handleCloseSnackbar();
                    }, 3000)
                } else {
                    tableActionLoader('fetchLoader', row, false)
                    setOpenSnackbar(true)
                    setSnackbarMessage("Error occured while fetching Ledger Request")
                    setSnackbarType("error")
                }
            })
            .catch((error) => {
                tableActionLoader('fetchLoader', row, false)
                setSnackbarMessage("Error occured while fetching Ledger Request")
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000)
            });
    };

    const handleLedgerReject = (row, ledgerRejectReason) => {
        const payload = {
            status: "REJECTED",
            cancel_reason: ledgerRejectReason
        }
        axios.post(
            `${APIROUTES.APPROVE_LEDGER_REQ}${row}/`, payload,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    setOpenSnackbar(true)
                    setSnackbarMessage("Ledger Request Rejected")
                    setSnackbarType("success")
                    handlePageData("NEW");
                    setTimeout(() => {
                        handleCloseSnackbar();
                    }, 3000)
                } else {
                    setOpenSnackbar(true)
                    setSnackbarMessage("Error occured while rejecting Ledger Request")
                    setSnackbarType("error")
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error.response.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000)
            });
    };

    const handleLedgerReset = (row) => {
        axios.delete(
            `${APIROUTES.RESET_LEDGER_REQ}${row}/`,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 204) {
                    setOpenSnackbar(true)
                    setSnackbarMessage("Ledger Request reset done")
                    setSnackbarType("success")
                    setTimeout(() => {
                        handleCloseSnackbar();
                    }, 3000)
                } else {
                    setOpenSnackbar(true)
                    setSnackbarMessage("Error occured while reset Ledger Request")
                    setSnackbarType("error")
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error.response.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000)
            });
    };

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleNextCustomerPage = (link, page) => {
        const status = tabValue == 0 ? "NEW" : tabValue == 1 ? "APPROVED" : "COMPLETED";
        setLoading(true);
        setPage(page)
        handleOnClick(status, page, link)
    };


    useEffect(() => {
        // console.log("snackBar" + props.snackBar)
        setPage(0)
        if (tabValue === 0) {
            setInitialLoading(true)
            handlePageData("NEW");
        }
        if (tabValue === 1) {
            setInitialLoading(true)
            handlePageData("APPROVED")
        }
        if (tabValue === 2) {
            setInitialLoading(true)
            handlePageData("COMPLETED")

        }
        fetchReasonCancelLA("Customer Request");
    }, [searchText, filters]);

    // useEffect(() => {

    //     const apiUrl = `${APIROUTES.GET_LEDGER_REQUESTS}?status=${status}&search=${searchText}`;

    //     // Fetch data from the API
    //     // Replace this with your actual fetch code
    //     fetch(apiUrl)
    //         .then((response) => response.json())
    //         .then((result) => {
    //             setData(result.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching data:', error);
    //         });
    // }, [search]);



    return (
        <Layout {...props}>
            <div style={classes.mainDiv}>
                <div style={classes.rightPadding}>
                    <div>
                        {/* <Typography>Customers</Typography> */}
                        <Bread
                            data={[
                                {
                                    title: "Customer Request",
                                    onClick: (e) => {
                                        e.preventDefault()
                                        props.history.push("/internalCustomerRequests")
                                    }
                                },
                                {
                                    title: "Ledger Request",
                                    onClick: "",
                                },
                            ]}
                        />

                    </div>
                    <div style={classes.filterSection(isMobile)}>
                        <div style={classes.searchAutoComplete}>
                            <TextField
                                style={classes.searchBox(isMobile)}
                                label="Search Customers"
                                autoComplete="off"
                                value={searchText}
                                variant="outlined"
                                size="small"
                                //onChange={handleCustomerSearch}
                                onChange={(e) => setSearchText(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div style={classes.filterAlignment}>
                            <Typography style={{ marginRight: isMobile && 10 }}>
                                Customer Type
                            </Typography>
                            <div>
                                <FormControl
                                    variant="outlined"
                                    style={classes.formControl}
                                    size="small"
                                >
                                    {/* <InputLabel id="customerType-select-labeled">
                      Customer Type
                    </InputLabel> */}
                                    <Select
                                        // labelId="customerType-select-labeled"
                                        id="customerType-select-label"
                                        multiple
                                        autoWidth
                                        displayEmpty
                                        name="customerTypeSelection"
                                        value={filters}
                                        onChange={(e) => handleSelect(e)}
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <span>All</span>;
                                            }

                                            return selected.join(', ');
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: (48 * 4.5) + 6, width: 200
                                                }
                                            },
                                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem
                                            disabled
                                            value="All"
                                            style={{ height: 42 }}
                                        >
                                            <Checkbox
                                                style={{ borderColor: 'gray', color: '#1976d2' }}
                                                checked={filters.length === 0 ? true : false}
                                            />
                                            <ListItemText
                                                primary="All"
                                            />.
                                        </MenuItem>
                                        {
                                            customerType.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item} style={{ height: 42 }}>
                                                        <Checkbox
                                                            style={{ borderColor: 'gray', color: '#1976d2' }}
                                                            checked={filters.indexOf(item) > -1}
                                                        />
                                                        <ListItemText
                                                            primary={item}
                                                        />
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                    </div>
                    <div style={classes.tabContainerStyle}>
                        <>
                            <Tabs
                                TabIndicatorProps={{
                                    style: {
                                        borderBottom: `2px solid ${primaryColor}`,
                                        colur: grey,
                                    },
                                }}
                                value={tabValue}
                                onChange={handleTabChange}
                                style={classes.tabContainerChildStyle(tabValue)}
                                variant="scrollable"
                            >
                                <Tab
                                    label="NEW"
                                    style={classes.tabStyle(tabValue, 0)}
                                    onClick={() => {
                                        setInitialLoading(true);
                                        handlePageData("NEW")
                                    }}
                                />
                                <Tab
                                    label="APPROVED"
                                    style={classes.tabStyle(tabValue, 1)}
                                    onClick={() => {
                                        setInitialLoading(true);
                                        handlePageData("APPROVED")
                                    }}
                                />
                                <Tab
                                    label="COMPLETED"
                                    style={classes.tabStyle(tabValue, 2)}
                                    onClick={() => {
                                        setInitialLoading(true);
                                        handlePageData("COMPLETED")
                                    }}
                                />
                            </Tabs>
                        </>
                    </div>
                    {/* <Table
                        headers={headersNew}
                        data={tableFilterData}
                        totalLedgerCount={totalLedgerCount}
                        handleNextCustomerPage={handleNextCustomerPage}
                        searchDataNext={searchDataNext}
                        page={page}
                        loading={loading}
                        searchDataPrev={searchDataPrev}
                        value="ledgerRequest" /> */}

                    {initialLoading ? <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30vh"
                    }}>
                        <SpinLoader />
                    </div> :
                        <div style={{ marginTop: 10 }}>
                            <Table
                                headers={headersNew}
                                data={tableFilterData}
                                totalLedgerCount={totalLedgerCount}
                                handleNextCustomerPage={handleNextCustomerPage}
                                searchDataNext={searchDataNext}
                                page={page}
                                loading={loading}
                                searchDataPrev={searchDataPrev}
                                tableHeight={"calc(100vh - 260px)"}
                                value="ledgerRequest" />
                        </div>
                    }
                </div>
            </div>
            <SnackbarComponent
                open={openSnackbar}
                handleClose={handleSnackbarClose}
                snackbarMessage={snackbarMessage}
                type={snackbarType}
            />
        </Layout>
    );

}
export default LedgerRequests;
