import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  lightGreyColor,
} from "../../../styles/colors";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Lock from "@material-ui/icons/Lock";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment"
import { Autocomplete } from "@mui/material";
import { pxToEm } from "../../../methods";

const styles = {
  quantityShowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  quantityShow: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  quantity: {
    width: "44%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  quantityText: {
    color: dailyRateScreenTextColor,
    fontSize: "1.25em",
  },
  formControlContainer: {
    width: "100%",
    marginTop: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  orderNameMenuContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
    fontSize: "0.8em",
    color: lightGreyColor,
  },
  orderIdShowContainer: {
    alignItems: "center",
    width: "100%",
    overflow: "hidden",
    display: "inline-block",
    textOverflow: "ellipsis",
  },
  verticalLineStyle: {
    height: "2.3vh",
    width: "1px",
    background: bottomNavigationFontColor,
    margin: "0px 6px",
  },
  orderQuantityShowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textFieldStyle: {
    width: "100%",
  },
  formControlStyle: {
    width: "35%",
  },
  textFieldQuantity: {
    width: "35%",
    marginTop: 10,
    fontSize: window.innerWidth < 375 && pxToEm(15),

    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 4,
      height: 52
    }

  },
  lockIconContainer: {
    width: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  helperText: {
    position: 'absolute',
    top: pxToEm(72)
  }
};
class AddOrderAndItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSaveButton: true,
      selectedItemAvailableQuantity: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props?.laState?.mode === 'edit' && this.state?.selectedItemAvailableQuantity === null && this.props?.rowItemData) {
      console.log('here edit item', this.props.rowItemData)
      Boolean(this.props.subContract) &&
        this.setState({
          selectedItemAvailableQuantity:
            this.props.items && (this.props.items.find(d => d.id === this.props.rowItemData.id))?.available_quantity + Number(this.props.rowItemData.quantity) || 0
        }, () => console.log(this.state?.selectedItemAvailableQuantity))
    }
  }

  checkDisableSave = () => {
    const {
      classes,
      orders,
      items,
      setItemQuantity,
      setItem,
      setOrderId,
      openBuyerListPopper,
      truckCapacity,
      setItemQuantityError
    } = this.props;
    if (setOrderId === "" ||
      setItemQuantity === "" ||
      setItem === "" ||
      setItemQuantityError) {
      return true
    }
    return false
  }
  render() {
    // const {  } = this.state;
    const {
      classes,
      orders,
      items,
      setItemQuantity,
      setItem,
      setOrderId,
      openBuyerListPopper,
      truckCapacity,
      setItemQuantityError,
      capacityError,
      defaultItem,
      laState,
      defaultSetItemQuantity,
      rowItemData,
      subContract,
    } = this.props;
    const { selectedItemAvailableQuantity } = this.state;
    console.log(items)
    return (
      <div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <FormControl variant="outlined" className={classes.formControlStyle}>
            <Autocomplete
              autoSelect
              options={items}
              getOptionLabel={(option) => option.item_name}
              defaultValue={this.props?.defaultItem}
              onChange={(e, newValue) => {
                e.preventDefault();
                this.props.handleSetOrderItem(newValue?.item_name);
                Boolean(subContract) && this.setState({ selectedItemAvailableQuantity: newValue?.available_quantity || 0 })
              }}
              disableClearable
              componentsProps={{
                paper: {
                  sx: {
                    width: 300,
                    fontFamily: 'Poppins',
                    fontSize: window.innerWidth < 375 && pxToEm(15)
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Item"
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    style: { fontSize: "0.9em", },
                  }}
                  helperText=''
                  style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                />
              )}
              variant="outlined"
              style={{ marginBottom: 5, }}
              helperText=""
            // className={classes.formControl}
            // size="small"
            />
          </FormControl>
          {console.log(this.props?.defaultItem)}
          <TextField
            name="setItemQuantity"
            id="outlined-name"
            label="Quantity"
            type="number"
            onKeyDown={(evt) => (
              ["e", "E", "+", "-"].includes(evt.key) ||
              (evt.which === 38 || evt.which === 40)
            ) && evt.preventDefault()}
            margin="normal"
            variant="outlined"
            defaultValue={defaultSetItemQuantity}
            value={setItemQuantity}
            className={classes.textFieldQuantity}
            FormHelperTextProps={{ style: styles.helperText }}
            onKeyPress={(e) => !e.target.value.match(/^\d*\.?\d{0,2}$/) && e.preventDefault()}
            onChange={(e) => {
              if (e.target.value.match(/^\d*\.?\d{0,3}$/)) {
                this.props.handleTextChange(e, laState?.mode === 'edit' && rowItemData);
                // this.props.handleTextChangeErrorCheck(e)
              }
              else { e.preventDefault() }

            }}
            InputProps={{
              endAdornment: <InputAdornment position="end" style={{ transform: window.innerWidth < 375 && 'scale(0.8)', }}>MT</InputAdornment>,
            }}
            InputLabelProps={{
              style: { fontSize: window.innerWidth < 375 ? pxToEm(13.5) : "0.9em" },
            }}
            error={setItemQuantityError}
            helperText={setItemQuantityError
              ? setItemQuantityError
              : Boolean(subContract)
                ? Number(setItemQuantity) > Number(selectedItemAvailableQuantity)
                  ? 'Limit exceed!'
                  : selectedItemAvailableQuantity !== null
                    ? `Available : ${(Number(Number(selectedItemAvailableQuantity).toFixed(3)) - Number(Number(setItemQuantity || 0).toFixed(3))).toFixed(3)} MT`
                    : " "
                : ""}
          // onBlur={() => {

          // }}
          // onFocus={() => {

          // }}
          />
          <Button
            disabled={Boolean(subContract) && Number(setItemQuantity) > Number(selectedItemAvailableQuantity) ||
              this.checkDisableSave() ||
              capacityError
            }
            onClick={(e) => {
              // console.log("here.............................")
              e.preventDefault();
              this.props.handleSaveOrderData(setOrderId, items, laState?.mode === 'edit' && laState?.mode, rowItemData && rowItemData);
              this.props.handleShowAddMoreButton()
              laState?.mode === 'edit' && this?.props?.handleEditItemMode()
            }}
            variant="contained"
            style={{
              height: "100%",
              color: "white",
              fontWeight: "bold",
              backgroundColor: (Boolean(subContract) && Number(setItemQuantity) > Number(selectedItemAvailableQuantity)) ||
                this.checkDisableSave() || capacityError
                ? bottomNavigationFontColor
                : primaryColor,
              padding: "12px 16px",
            }}
          >
            Save
          </Button>
        </div>
        {capacityError ? (
          <span style={{ color: "red", position: 'relative', top: 10 }}>
            Quantity can't be more than truck capacity or Pending quantity
          </span>
        ) : null}
      </div>
    );
  }
}
export default withStyles(styles)(AddOrderAndItem);
