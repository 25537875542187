import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import "../../../styles/b2b/OrdersList.css";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Info from "@material-ui/icons/Info";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  errorMessageColor,
  secondaryColor,
} from "../../../styles/colors";
import { pxToEm } from "../../../methods"
import moment from "moment";
import OrderCard from "./OrderCard";


const styles = {
  listContainerStyle: {
    cursor: "pointer",
    width: "100%",
    // height: "15vh",
    borderRadius: "12px",
    // margin: "10px 0px",
    padding: "6px",
    display: "flex",
    alignItems: "center",
    // boxShadow:
    //   "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  listContainerLeftSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  listContainerMiddleSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "58%",
    height: "100%",
    // alignItems: "center",
    paddingLeft: "5px",
    justifyContent: "center",
  },
  listContainerRightSideStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "22%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  textStyle: {
    color: bottomNavigationFontColor,
    // display: "flex",
    overflowWrap: "anywhere",
    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
  },
  leftIconContainer: (status) => ({
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    transform: 'scale(0.8)',
    border:
      status === "PENDING" || status === "active"
        ? `1px solid ${secondaryColor}`
        : status === "CANCELLED"
          ? "1px solid red"
          : status === "APPROVED" ?
            "1px solid green" :
            `1px solid ${errorMessageColor}`,
  }),
  leftIconText: (status) => ({
    color: bottomNavigationFontColor,
    fontSize: (status.length > 7)
      ? window.innerWidth < 310
        ? pxToEm(9.6)
        : window.innerWidth < 375
          ? pxToEm(10)
          : pxToEm(11.6)
      : window.innerWidth < 310
        ? pxToEm(10.6)
        : window.innerWidth < 375
          ? pxToEm(11.6)
          : pxToEm(13.6),
    textAlign: status === 'CONVERSION_ALLOTMENT_HOLD' && 'center'
  }),
  priceShowContainer: {
    display: "flex",
    alignItems: "center",
    color: bottomNavigationFontColor,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    fontSize: window.innerWidth < 310 ? pxToEm(11.6) : window.innerWidth < 375 && pxToEm(13)
  },
  horizontalLineStyle: {
    width: "2px",
    height: "12px",
    background: "#9E9E9E",
    margin: "0px 6px",
  },
};

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  myref = React.createRef()

  render() {
    const { classes, status, orderId, date, name, weight, rate, order, netRate } = this.props;
    return (
      <div
        className={classes.listContainerStyle}
        onClick={(e) => {
          e.preventDefault();
          if (order.order_status === "PENDING" && order.is_customer_approval_needed && order.is_approved_by_customer === null) {
            this.props.history.push({ pathname: `/orders/action/${orderId}`, state: order })
          }
          else {
            this.props.handleSingleOrders(order);
            if (status === "ONGOING" || status === "APPROVED" || status === "COMPLETED" || status === "PENDING") {
              this.props.fetchSingleOrderPlantPlanning(order)
              this.props.fetchSingleOrderLoadingAdvices(order)
              // this.props.fetchSingleOrderInvoices(order)
              // this.props.fetchInvoices(order)
            }
          }

        }}
        ref={this.myref}
      >
        {/* <div className={classes.listContainerLeftSideStyle}>
          <span style={styles.leftIconContainer(status)}>
            {status === "PENDING" ? (
              <HourglassEmpty style={{ color: secondaryColor }} />
            )
              : status === "CANCELLED" ? (
                <HighlightOffRoundedIcon style={{ color: "red" }} />
              ) :
                status === "APPROVED" ? (
                  <CheckCircleOutlinedIcon style={{ color: "green" }} />
                ) : (
                  <Info style={{ color: errorMessageColor }} />
                )}
          </span>
          <span style={styles.leftIconText(status)}>
            {status === 'CONVERSION_ALLOTMENT_HOLD' ? "ALLOTMENT HOLD" : status}
          </span>
        </div>
        <div className={classes.listContainerMiddleSideStyle}>
          <span
            style={{
              color: dailyRateScreenTextColor,
              fontSize: window.innerWidth < 310
                ? pxToEm(12)
                : window.innerWidth < 375
                  ? pxToEm(13.6)
                  : pxToEm(16)
            }}
          >
            Contract {orderId}
          </span>
          <span className={classes.priceShowContainer}>
            <span>{moment(date).format("DD-MMM-yyyy")}</span>
            <div className={classes.horizontalLineStyle} />
            <span>{`₹${parseInt(netRate).toFixed(0)}`}</span>
          </span>
          <span className={classes.textStyle}>{name}</span>
        </div>
        <div className={classes.listContainerRightSideStyle}>
          {this.props.dealerOrders && this.props.dealerOrders ? (
            <div className="distributor-triangle-shape">D</div>
          ) : null}
          <span style={{ fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 ? pxToEm(13) : "0.9em" }}>
            {weight}
            <span style={{ marginLeft: "4px" }}>MT</span>
          </span>
        </div> */}
        <div style={{ width: '100%' }}>
          <OrderCard
            title={
              order.order_status === "COMPLETED" ? "Completed" :
                order.order_status === "APPROVED" ? "Approved" :
                  order.order_status === "ONGOING" ? "Ongoing" :
                    order.order_status
            }
            details={order}
            isMobile={this.props.isMobile}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(OrdersList));
