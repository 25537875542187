import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import {
  primaryColor,
  drawerTextColor,
} from "../../../../constants/internal/colors";
import DisplayCustomerDetails from "./DisplayCustomerDetails";
import { pxToEm, hasWriteAccess } from "../../../../methods";

import "../../../../styles/internal/scroll.css";
import debounce from "lodash.debounce";
import Bread from "../../common/Breadcrubs";
import ToolTipComponent from "../../common/ToolTipComponent";

const customersStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
  },
  filterSection: {
    margin: "25px 0px 20px 0px",
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  searchAutoComplete: {
    display: "flex",
    flex: 5,
  },
  filterAlignment: {
    display: "flex",
    alignItems: "center",
    flex: 3,
    placeContent: "center",
  },
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  searchBox: {
    width: "85%",
  },
  formControl: {
    minWidth: "100%",
    marginLeft: "10px",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    paddingBottom: "30px",
  },
  rightPadding: { paddingRight: "25px" },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const customerType = [
  // "All",
  "BROKER",
  "CONSUMER",
  "DEALER",
  "DISTRIBUTOR",
  "TRADER"
]
class CustomerWeb extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      allCustomers: [],
      // this?.props?.customerSearchData || [],
      customerSearchData: [],
      // this?.props?.customerSearchData || [],
      selectedCustomerId: null,
      filterSelection: "all",
      customerTypeSelection: [],
      allCustomersBackup: [],
      // this?.props?.customerSearchData || [],
      allCustomersBackupTwo: [],
      // this?.props?.customerSearchData || [],
      customerLoader: true,
      // this.props.customerLoader,
      excludeColumnsFromSearch: [
        "id",
        "approved_at",
        "approved_by",
        "created_at",
        "created_by",
        "modified_at",
      ],
      searchText: "",
      page: 0,
    };
  }
  // new lifecycle method implementation
  componentDidMount() {
    this._isMounted = true;
    this.setState(
      {
        customerLoader: this.props.customerLoader,
        allCustomers: this.props.customerSearchData,
        customerSearchData: this.props.customerSearchData,
        allCustomersBackup: this.props.customerSearchData,
        allCustomersBackupTwo: this.props.customerSearchData,
        filterSelection: this.props.selectedFilter
      });
    // if (this.props.selectedFilter === "") {
    //   this.setState({
    //     filterSelection: "all"
    //   }, () => console.log())
    //   this.props.getAllCustomersList(this.state.page, "")
    // }
    if (this.props.selectedFilter === "" || this.props.selectedFilter === "all") {
      this.setState({
        filterSelection: "all"
      })
      this.props.getAllCustomersList(this.state.page, "")
    }
    else
      this.props.getNewAndPendingCustomerList(this.state.page, this.props.selectedFilter)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.customerLoader !== this.state.customerLoader) {
      this.setState({ customerLoader: this.props.customerLoader });
    }
    if (this.props.customerSearchData !== this.state.customerSearchData) {
      this.setState(
        {
          allCustomers: this.props.customerSearchData,
          customerSearchData: this.props.customerSearchData,
        });
    }
    if (this.props.customerSearchData !== this.state.allCustomersBackup) {
      this.setState({ allCustomersBackup: this.props.customerSearchData });
    }
    if (this.props.customerSearchData !== this.state.allCustomersBackupTwo) {
      this.setState({ allCustomersBackupTwo: this.props.customerSearchData });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleFilterChange = (event) => {
    // console.log(event.target.value)
    const { value } = event.target
    this.setState({
      page: 0
    })
    this.setState({ filterSelection: value });
    if (this.state.searchText === "") {
      if (this.state.customerTypeSelection.length === 0) {
        if (value !== "all")
          this.props.getNewAndPendingCustomerList(0, value)
        else this.props.getAllCustomersList()
      }
      else {
        if (value === 'all') {
          this.props.getCustomerTypeCustomerList(1, value, this.state.customerTypeSelection, this.state.searchText, 0)
        }
        else {
          this.props.getCustomerTypeCustomerList(1, value, this.state.customerTypeSelection, this.state.searchText, 0)
        }
      }
    }
    else {
      this.props.getCustomerTypeCustomerList(1, value, this.state.customerTypeSelection, this.state.searchText, 0)
    }
  };

  handleSelect = (name, value) => {
    this.setState({
      [name]: typeof value === 'string' ? value.split(',') : value,
      page: 0
    },
      () => {
        if (this.state.searchText === "") {
          if (this.state.customerTypeSelection.length === 0) {
            if (this.state.filterSelection === 'all') {
              this.props.getAllCustomersList()
            }
            else {
              this.props.getNewAndPendingCustomerList(0, this.state.filterSelection)
            }
          }
          else {
            if (this.state.filterSelection === 'all') {
              this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0)
            }
            else {
              this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0)
            }
          }
        }
        else {
          this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0)
        }
      })
    // console.log(value)
    // if (value === "All")
    //   this.props.getNewAndPendingCustomerList(0, value)
    // else this.props.getAllCustomersList()
  }

  // handleCustomerSearchChange = (event) => {
  //   this.setState({ searchText: event.target.value, searchPageNumber: 0 }, () =>
  //     this.filterData()
  //   );
  // };

  debouncedSearch = debounce(() => {
    // this.setState({ searchText: event.target.value }, () => this.filterData());
    //this.filterData();
    if (this.state.customerTypeSelection.length === 0) {
      if (this.state.filterSelection === 'all') {
        this.props.getAllCustomersList(0, this.state.searchText);
      }
      else {
        this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0);
      }
    }
    else {
      if (this.state.filterSelection === 'all') {
        this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0);
      }
      else {
        this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, 0);
      }
    }
  }, 300);

  handleCustomerSearch = (event) => {
    this.setState(
      { searchText: event.target.value, page: 0 },
      this.debouncedSearch
    );
  };

  handleNextCustomerPage = (newLink, newPage) => {
    // console.log(this.state.filterSelection)
    // console.log(newPage)
    const filterSelection = this.state.filterSelection
    this.setState(
      { page: newPage },
    );
    // if (filterSelection === "all")
    //   this.props.getAllCustomersList(newPage, this.state.searchText)
    // else
    //   this.props.getNewAndPendingCustomerList(newPage, filterSelection)
    if (this.state.searchText === "") {
      if (this.state.customerTypeSelection.length === 0) {
        if (this.state.filterSelection === 'all') {
          this.props.getAllCustomersList(newPage, this.state.searchText)
        }
        else {
          this.props.getNewAndPendingCustomerList(newPage, this.state.filterSelection)
        }
      }
      else {
        if (this.state.filterSelection === 'all') {
          this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink)
        }
        else {
          this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink)
        }
      }
    }
    else {
      if (this.state.customerTypeSelection.length !== 0) {
        if (filterSelection === "all") {
          this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink);
        }
        else {
          this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink);
        }
      }
      else {
        if (filterSelection === "all") {
          this.props.getAllCustomersList(newPage, this.state.searchText);
        }
        else {
          this.props.getCustomerTypeCustomerList(1, this.state.filterSelection, this.state.customerTypeSelection, this.state.searchText, newLink);
        }
      }
      // this.props.getAllCustomersList(0, this.state.searchText)
    }
  }


  // filterData = () => {
  //   let searchText = this?.state?.searchText.toLowerCase().trim();
  //   let filterSelection = this?.state?.filterSelection || "all";
  //   let tempCustomerSearchData =
  //     this?.state?.customerSearchData.length > 0
  //       ? this.state.customerSearchData
  //       : [];
  //   if (searchText === "") {
  //     if (filterSelection !== "all") {
  //       let allCustomers =
  //         filterSelection === "new"
  //           ? tempCustomerSearchData.filter(
  //               (data) => data.credentials.is_new === true
  //             )
  //           : tempCustomerSearchData.filter(
  //               (data) => data.credentials.is_active === false
  //             );
  //       this.setState({ allCustomers }, () => console.log(1));
  //     } else {
  //       this.setState({ allCustomers: tempCustomerSearchData }, () =>
  //         console.log(1)
  //       );
  //     }
  //   } else {
  //     const filteredData = tempCustomerSearchData.filter((item) => {
  //       return Object.keys(item).some((key) =>
  //         this.state.excludeColumnsFromSearch.includes(key)
  //           ? false
  //           : item[key].toString().toLowerCase().includes(searchText)
  //       );
  //     });
  //     if (filterSelection !== "all") {
  //       let allCustomersFiltered =
  //         filterSelection === "new"
  //           ? filteredData.filter((data) => data.credentials.is_new === true)
  //           : filteredData.filter(
  //               (data) => data.credentials.is_active === false
  //             );

  //       this.setState(
  //         {
  //           allCustomers: allCustomersFiltered,
  //           allCustomersBackup: allCustomersFiltered,
  //         },
  //         () => console.log(1)
  //       );
  //     } else {
  //       this.setState(
  //         {
  //           allCustomers: filteredData,
  //           allCustomersBackup: filteredData,
  //         },
  //         () => console.log(1)
  //       );
  //     }
  //   }
  // };
  render() {
    const { classes } = this.props;
    const { filterSelection, searchText, allCustomers, customerTypeSelection } = this.state;
    // console.log(this.state.page)
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <div>
              {/* <Typography>Customers</Typography> */}
              <Bread
                data={[
                  {
                    title: 'Customers',
                  },
                ]}
              />
            </div>
            <div className={classes.filterSection}>
              <div className={classes.searchAutoComplete}>
                <TextField
                  className={classes.searchBox}
                  label="Search Customers"
                  autoComplete="off"
                  value={searchText}
                  variant="outlined"
                  size="small"
                  onChange={this.handleCustomerSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                className={classes.filterAlignment}
                style={{ width: 200, placeContent: 'flex-start', flex: 2 }}
              >
                <Typography>FILTER</Typography>
                <div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={filterSelection}
                      onChange={this.handleFilterChange}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="NEW">New</MenuItem>
                      <MenuItem value="PENDING">Pending</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.filterAlignment} style={{ flex: 4, placeContent: 'space-evenly' }}>
                <Typography>Customer Type</Typography>
                <div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                    style={{ margin: '0', width: 200 }}
                  >
                    {/* <InputLabel id="customerType-select-labeled">
                      Customer Type
                    </InputLabel> */}
                    <Select
                      // labelId="customerType-select-labeled"
                      id="customerType-select-label"
                      multiple
                      autoWidth
                      displayEmpty
                      name="customerTypeSelection"
                      value={customerTypeSelection}
                      onChange={(e) => this.handleSelect(e.target.name, e.target.value)}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>All</span>;
                        }

                        return selected.join(', ');
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: (48 * 4.5) + 6, width: 200
                          }
                        },
                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem
                        disabled
                        value="All"
                        style={{ height: 42 }}
                      >
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={customerTypeSelection.length === 0 ? true : false}
                        />
                        <ListItemText
                          primary="All"
                        />.
                      </MenuItem>
                      {
                        customerType.map(item => {
                          return (
                            <MenuItem value={item} style={{ height: 42 }}>
                              <Checkbox
                                style={{ borderColor: 'gray', color: '#1976d2' }}
                                checked={customerTypeSelection.indexOf(item) > -1}
                              />
                              <ListItemText
                                primary={item}
                              />
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={classes.addButton}>
                <ToolTipComponent
                  title="You have no create permission on this widget"
                  condition={!hasWriteAccess(this.props.user, "customerManagement")}
                >
                  <Button
                    size="medium"
                    className={classes.primaryBackground}
                    variant="contained"
                    startIcon={<AddOutlinedIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/internalCustomers/new");
                    }}
                    disabled={
                      hasWriteAccess(this.props.user, "customerManagement")
                        ? false
                        : true
                    }
                  >
                    ADD NEW
                  </Button>
                </ToolTipComponent>
              </div>
            </div>
            <div>
              <DisplayCustomerDetails
                {...this.state}
                {...this.props}
                allCustomers={allCustomers}
                totalCustomersCount={this.props.totalCustomersCount}
                getAllCustomersList={this.props.getAllCustomersList}
                handleNextCustomerPage={this.handleNextCustomerPage}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default withStyles(customersStyles)(CustomerWeb);
