import React, { Component } from 'react';
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import { Typography } from '@material-ui/core';
import OpenRates from './OpenRates';
import PastRates from './PastRates';
import LiveRates from './LiveRates';
import { grey } from '../../../../constants/internal/colors';
import { pxToEm, isAccessibleWidget } from '../../../../methods';
import AccessDenied from '../../../common/AccessDenied';
import APIROUTES from '../../../../constants/internal/InternalApiRoutes';
import axios from 'axios';
import moment from "moment";
import Bread from '../../common/Breadcrubs';

const styles = {
  mainDiv: {
    width: '100%',
    display: 'flex',
    placeContent: 'center',
    flexDirection: 'column',
  },
  contentDiv: {
    display: 'flex',
    marginTop: '10px',
  },
  openPastRatesDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '25px',
    height: '50%',
    width: '80%',
  },
  openRatesDiv: {
    display: 'flex',
    flex: 1,
    marginBottom: '25px',
    width: '100%',
  },
  pastRatesDiv: {
    display: 'flex',
    flex: 1,
  },
  liveRatesDiv: {
    display: 'flex',
    width: '20%',
    height: '50%',
  },
  fontStyle: {
    fontSize: pxToEm(18),
    color: grey,
  },
  mainDivDailyRates: {
    width: '100%',
    height: '100%',
    paddingBottom: '30px',
    overflowY: 'scroll',
  },
  rightPadding: { paddingRight: '25px' },
};
class DailyRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveRateValues: null,
      openRatesData: null,
      openRatesDataBackUp: null,
      allCategories: null,
      openRatesLoader: true,
      liveRatesLoader: true,
      filteredPastRateDataToDisplay: [],
      allPastRateDataToDisplay: [],
      csvPastRateData: [],
      csvPastRateLoader: true,
      pastRatesDataCount: null,
    };
  }
  componentDidMount() {
    this.initializeData(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.initializeData(nextProps);
  }
  initializeData(whichProps) {
    this.setState({
      openRatesLoader: whichProps.openRatesLoader,
      liveRatesLoader: whichProps.liveRatesLoader,
    });
    if (whichProps.liveRateValues && whichProps.liveRateValues.length > 0) {
      this.setState({ liveRateValues: whichProps.liveRateValues });
    }
    if (
      whichProps.originalTemlateValues &&
      whichProps.originalTemlateValues.length > 0 &&
      whichProps.originalDailyRatesOpenRateValues &&
      whichProps.originalDailyRatesOpenRateValues.length > 0
    ) {
      let originalTemlateValuesCopy = whichProps.originalTemlateValues;
      let originalDailyRatesOpenRateValuesCopy =
        whichProps.originalDailyRatesOpenRateValues;

      const openRatesData = [];
      if (originalTemlateValuesCopy && originalTemlateValuesCopy.length > 0) {
        let publishedData = originalDailyRatesOpenRateValuesCopy.filter(
          (publishData) => publishData.daily_rates.length > 0
        );
        let publishDataBaseRates = [];
        if (publishedData.length > 0) {
          publishedData.map((data) => {
            let singlePublishedData = {
              id: data.id,
              categoryName: data.category_name,
              baseRateData: data.daily_rates.find(
                (publishedDailyRateData) =>
                  publishedDailyRateData.is_base_rate === true
              ),
            };
            return publishDataBaseRates.push(singlePublishedData);
          });
        }
        originalTemlateValuesCopy.map((templateValue, index) => {
          let parentCategoryBaseRate = 0;
          if (
            templateValue.related_to &&
            templateValue.related_to !== undefined &&
            templateValue.related_to !== null
          ) {
            let parentCategoryData =
              publishDataBaseRates.length > 0
                ? publishDataBaseRates.find(
                  (parentData) =>
                    parentData.id === Number(templateValue.related_to)
                )
                : null;

            if (
              parentCategoryData &&
              parentCategoryData !== null &&
              parentCategoryData !== undefined
            ) {
              parentCategoryBaseRate =
                parentCategoryData.baseRateData.high_rate;
            }
          }
          let singleDailyRateValue =
            originalDailyRatesOpenRateValuesCopy &&
              originalDailyRatesOpenRateValuesCopy.length > 0
              ? originalDailyRatesOpenRateValuesCopy.find(
                (dailyRatesOpenRate) =>
                  dailyRatesOpenRate.id === templateValue.id
              )
              : null;
          singleDailyRateValue.linkedTo = templateValue.related_to;
          singleDailyRateValue.linkedToRateDifference =
            templateValue.rate_difference;
          const templateDailyRateData = [];
          if (templateValue.daily_rates_template.length > 0) {
            templateValue.daily_rates_template.map(
              (templateData, templateDataIndex) => {
                let tempTemplateDailyRateData = templateData;
                tempTemplateDailyRateData.high_rate = !parentCategoryBaseRate
                  ? 0
                  : Number(parentCategoryBaseRate) +
                  Number(templateValue.rate_difference);
                tempTemplateDailyRateData.low_rate =
                  Number(tempTemplateDailyRateData.high_rate) +
                  Number(templateData.low_rate_difference);
                tempTemplateDailyRateData.high_rate_template_difference =
                  templateData.high_rate_difference;
                tempTemplateDailyRateData.low_rate_template_difference =
                  templateData.low_rate_difference;
                return templateDailyRateData.push(tempTemplateDailyRateData);
              }
            );
          }
          singleDailyRateValue.data =
            singleDailyRateValue.daily_rates.length > 0
              ? singleDailyRateValue.daily_rates
              : templateDailyRateData;
          singleDailyRateValue.isPublished =
            singleDailyRateValue.daily_rates.length > 0 ? true : false;
          return openRatesData.push(singleDailyRateValue);
        });
      }
      this.setState({ openRatesData });
    }

    const allCategories = [];
    whichProps?.pastRateValues?.map((pastRateData) => {
      let singlePastRateDataDisplay = {
        categoryId: pastRateData.id,
        categoryName: pastRateData.category_name,
      };
      return allCategories.push(singlePastRateDataDisplay);
    });

    const filteredPastRateDataToDisplay = [];
    whichProps?.filteredPastRatesData?.results?.map((pastRateData) => {
      let singlePastRateDataDisplay = {
        categoryId: pastRateData.item_category.id,
        createdAt: pastRateData.created_at,
        categoryName: pastRateData.item_category.category_name,
        highRate: pastRateData.high_rate,
        lowRate: pastRateData.low_rate,
      };
      return filteredPastRateDataToDisplay.push(singlePastRateDataDisplay);
    });

    const csvPastRateData = [];
    whichProps?.csvPastRatesData?.map((pastRateData) => {
      let singlePastRateDataDisplay = {
        categoryId: pastRateData.item_category.id,
        createdAt: moment(pastRateData.created_at).format("DD-MMMM-YY LT"),
        modifiedAt: moment(pastRateData.modified_at).format("DD-MMMM-YY LT"),
        categoryName: pastRateData.item_category.category_name,
        highRate: pastRateData.high_rate,
        lowRate: pastRateData.low_rate,
        highRateDifference: pastRateData.high_rate_difference,
        lowRateDifference: pastRateData.low_rate_difference,
        isBaseRate: pastRateData.is_base_rate,
        state: pastRateData.state.state_name,
        status: pastRateData.status,
        userId: pastRateData.created_by.employee_id,
        createdByName: pastRateData.created_by?.personal_details?.first_name + " " + pastRateData.created_by?.personal_details?.last_name
      };
      return csvPastRateData.push(singlePastRateDataDisplay);
    });

    this.setState({
      filteredPastRateDataToDisplay,
      allCategories,
      filteredPastRatesLoader: whichProps.filteredPastRatesLoader,
      pastRatesLoader: whichProps.pastRatesLoader,
      csvPastRateData,
      pastRatesDataCount: whichProps.pastRatesDataCount,
      csvPastRateLoader: whichProps.csvPastRatesLoader
    });

    const allPastRateDataToDisplay = [];
    whichProps?.pastRateValues?.results?.map((pastRateData) => {
      let singlePastRateDataDisplay = {
        categoryId: pastRateData.item_category.id,
        createdAt: pastRateData.created_at,
        categoryName: pastRateData.item_category.category_name,
        highRate: pastRateData.high_rate,
        lowRate: pastRateData.low_rate,
      };
      return allPastRateDataToDisplay.push(singlePastRateDataDisplay);
    });
    this.setState({
      allPastRateDataToDisplay,
      pastRatesLoader: whichProps.pastRatesLoader,
    });
  }
  // downloadCsv() {
  //   const csvPastRateData = [];
  //   this.props?.csvPastRatesData?.results?.map((pastRateData) => {
  //     let singlePastRateDataDisplay = {
  //       categoryId: pastRateData.item_category.id,
  //       createdAt: pastRateData.created_at,
  //       categoryName: pastRateData.item_category.category_name,
  //       highRate: pastRateData.high_rate,
  //       lowRate: pastRateData.low_rate,
  //     };
  //     return csvPastRateData.push(singlePastRateDataDisplay);
  //   });

  // }

  render() {
    const { liveRateValues, liveRatesLoader } = this.state;
    const { user } = this.props;
    return (
      <Layout {...this.props}>
        {isAccessibleWidget(user, 'openRates') ||
          isAccessibleWidget(user, 'pastRates') ||
          isAccessibleWidget(user, 'liveRates') ? (
          <div style={styles.mainDivDailyRates}>
            <div style={styles.rightPadding}>
              <div style={styles.titleDiv}>
                {/* <Typography style={styles.fontStyle}>Daily Rates</Typography> */}
                <Bread
                  data={[
                    {
                      title: 'Daily Rates',
                    },
                  ]}
                />
              </div>
              <div style={styles.contentDiv}>
                <div style={styles.openPastRatesDiv}>
                  {isAccessibleWidget(user, 'openRates') && (
                    <div style={styles.openRatesDiv}>
                      <OpenRates {...this.props} {...this.state} />
                    </div>
                  )}
                  {isAccessibleWidget(user, 'pastRates') && (
                    <div style={styles.pastRatesDiv}>
                      <PastRates {...this.props} {...this.state} />
                    </div>
                  )}
                </div>
                {isAccessibleWidget(user, 'liveRates') && (
                  <div style={styles.liveRatesDiv}>
                    <LiveRates
                      liveRateValues={liveRateValues}
                      liveRatesLoader={liveRatesLoader}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <AccessDenied />
        )}
      </Layout>
    );
  }
}
export default DailyRates;
