import React, { Component } from "react";
import OpenInDesktop from "../../../components/internal/common/OpenInDesktop";
import Contracts, {
  ContractsWeb,
  SingleContractsWeb,
  AddContractsWeb,
  SingleContractsWebNew,
  AddContractsWebNew,
  EditContractsWebNew,
} from "../../../components/internal/contracts";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import LoadingAdvicesContainer from "./LoadingAdvices";
import { compareValues, getOptions, arraySortBykeyAllBrowser, checkApiError, handleSnackbarMessageBox } from "../../../methods";
import { TimerSharp } from "@material-ui/icons";
import debounce from "lodash.debounce";
import upiqr from "upiqr";
import { Route, Switch } from "react-router-dom";
import DispatchCounterInvoiceDownload from "../../../components/internal/dispatchOperation/web/DispatchCounterInvoiceDownload";
import moment from "moment";
import AddSubContractsWebNew from "../../../components/internal/contracts/web/newUi/AddSubContractsWebNew";
import SnackbarComponent from "../../../components/common/SnackbarComponent";

class InternalContractsContainer extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      displayNewUi: true,
      allOrders: [],
      itemCategoryTabData: [],
      itemDataFetchLoader: true,
      tagsMaster: [],
      freightBasis: [],
      transType: [],
      totalOrderCount: 0,
      users: [],
      searchDataNext: '',
      searchDataPrev: '',
      date: '',
      itemCategoryCode: null,
      eInvoiceResponse: null,
      eWayBillResponse: null,
      eWayBillSelected: false,
      eWayOptionSelected: false,
      eWayBill: [{ name: "Yes" }, { name: "No" }],
      invoiceMethod: [{ name: "Normal Invoice" }, { name: "Combined Invoice" }],
      invoiceType: "",
      iseWaySelect: false,
      laDetailsInDispatchCounter: null,
      laDispatchCounterLoader: false,
      transporterDistance: 0.0,
      plant_state: null,
      iseWaySelect: false,
      eway: null,
      laNumber: null,
      b2CQrImage: null,
      openSnackbar: false,
      snackbarType: "",
      isLoading: false
    };
  }
  _isMounted = false;
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentDidMount() {
    this.fetchAllCategories();
    this.fetchTransTypeMaster();
    this.fetchTagsMaster();
    this.fetchFreightBasis();
    this.getAllStatesList();
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleTabValueCode = (tabID) => {
    let code = (this.state?.itemCategoryTabData.find(d => d.id === tabID))?.item_category_code
    this.setState({ itemCategoryCode: code }, () => {
      localStorage.setItem("itemCategoryCode", code)
    })
  }

  fetchAllCategories = async () => {
    this.setState({
      itemDataFetchLoader: true,
    });
    await axios
      .get(APIROUTES.GET_ITEM_CATEGORY, this.getOptions(this.props.user))
      .then((response) => {
        const itemCategoryTabData = response.data.sort((a, b) => a.id - b.id);
        this.setState({
          itemCategoryTabData,
          itemDataFetchLoader: false,
        });
      })
      .catch((error) => {
        this.setState({ itemDataFetchLoader: true });
      });
  }

  // api integration setup for new ui changes
  fetchAllOrders = async (pageId = 1, tabId = 5) => {
    this.setState({
      itemDataFetchLoader: true,
    });
    await axios
      .get(`${APIROUTES.CONTRACTS_LIST}&page=${pageId}&item_category=${tabId}`, this.getOptions(this.props.user))
      .then((response) => {
        const { data } = response;
        this.setState({
          allOrders: data?.results,
          totalOrderCount: data?.count,
          itemDataFetchLoader: false,
        });
      })
      .catch((error) => {
        console.error("method error", error)
        this.setState({ itemDataFetchLoader: true });
      });
  };
  fetchSearchOrders = debounce((pageId = 1, tabId = 5, searchText, newLink = 0) => {
    console.log(newLink)
    if (newLink === 0) {
      console.log('if')
      this.setState({
        itemDataFetchLoader: true,
      });
      axios
        .get(`${APIROUTES.GET_SEARCH_CONTRACTS}?search=${searchText}&page=${pageId}&item_category=${tabId}`, this.getOptions(this.props.user))
        .then((response) => {
          const { data } = response;
          this.setState({
            allOrders: data?.results,
            totalOrderCount: data?.count,
            searchDataPrev: data?.previous,
            searchDataNext: data?.next,
            itemDataFetchLoader: false,
          });
        })
        .catch((error) => {
          console.error("method error", error)
          this.setState({ itemDataFetchLoader: true });
        });
    }
    else {
      console.log('else')
      this.setState({
        itemDataFetchLoader: true,
      });
      axios
        .get(`${newLink}`, this.getOptions(this.props.user))
        .then((response) => {
          const { data } = response;
          this.setState({
            allOrders: data?.results,
            totalOrderCount: data?.count,
            searchDataPrev: data?.previous,
            searchDataNext: data?.next,
            itemDataFetchLoader: false,
          });
        })
        .catch((error) => {
          console.error("method error", error)
          this.setState({ itemDataFetchLoader: true });
        });
    }
  }, 500);

  fetchSearchPendingOrders = debounce((tabId = 5, pageId = 1, searchText, newLink = 0) => {
    console.log(newLink)
    if (newLink === 0) {
      console.log('if')
      this.setState({
        itemDataFetchLoader: true,
      });
      axios
        .get(`${APIROUTES.GET_SEARCH_CONTRACTS}?search=${searchText}&page=${pageId}&item_category=${tabId}&pending=true`, this.getOptions(this.props.user))
        .then((response) => {
          const { data } = response;
          this.setState({
            allOrders: data?.results,
            totalOrderCount: data?.count,
            searchDataPrev: data?.previous,
            searchDataNext: data?.next,
            itemDataFetchLoader: false,
          });
        })
        .catch((error) => {
          console.error("method error", error)
          this.setState({ itemDataFetchLoader: true });
        });
    }
    else {
      console.log('else')
      this.setState({
        itemDataFetchLoader: true,
      });
      axios
        .get(`${newLink}`, this.getOptions(this.props.user))
        .then((response) => {
          const { data } = response;
          this.setState({
            allOrders: data?.results,
            totalOrderCount: data?.count,
            searchDataPrev: data?.previous,
            searchDataNext: data?.next,
            itemDataFetchLoader: false,
          });
        })
        .catch((error) => {
          console.error("method error", error)
          this.setState({ itemDataFetchLoader: true });
        });
    }
  }, 500);

  fetchTransTypeMaster = () => {
    axios
      .get(APIROUTES.GET_TRANS_TYPE_MASTER, this.getOptions(this.props.user))
      .then((response) => {
        this.setState({
          transType: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  fetchTagsMaster = () => {
    axios
      .get(
        `${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}?active=true`, this.getOptions(this.props.user))
      .then((response) => {
        this.setState({
          tagsMaster: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  fetchFreightBasis = () => {
    axios
      .get(`${APIROUTES.FREIGHT_BASIS}`, getOptions(this.props.user))
      .then((response) => {
        this.setState({
          freightBasis: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  fetchCustomerPaymentTerms = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_PAYMENT_METHOD}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchItemCategories = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_ITEM_CATEGORIES}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchConversionItemCategories = (orderNo) => {
    return axios.get(
      `${APIROUTES.CONTRACT_CONVERSION_ITEM_CATEGORIES}${orderNo}/`,
      this.getOptions(this.props.user)
    );
  };
  fetchReasonMaster = (moduleName) => {
    return axios.get(
      `${APIROUTES.GET_REASON}?module=${moduleName}&active=${true}`,
      // `${APIROUTES.GET_REASON}`,
      this.getOptions(this.props.user)
    );
  };
  fetchInternalUsers = () => {
    let users = [];
    this.setState({ users });
    axios
      .get(`${APIROUTES.GET_ALL_INTERNAL_USERS}?active=${true}&is_sales_person=${true}`, this.getOptions(this.props.user))
      .then(
        (response) => {
          var data = response.data;
          users = data
            .map((element) => ({
              id: element.id,
              email: element.email,
              is_admin: element.is_admin,
              is_active: element.is_active,
              employee_id: element.employee_id,
              firstName: element.personal_details.first_name,
              lastName: element.personal_details.last_name,
              fullName: `${element.personal_details.first_name} ${element.personal_details.last_name}`,
              createdAt: new Date(element.created_at),
              phone: element.phone,
              roles: element?.roles.map((e, i) => e.role.role_name),
            }))
            .sort(compareValues("firstName"));
          this.setState({
            users: users.filter((e) => e.is_active),
          });
        },
        (errorUsers) => {
          console.error(errorUsers);
          this.setState({ users: [] });
        }
      );
  };
  ContractConversion = (convertOrderData, orderNo) => {
    return axios.post(
      `${APIROUTES.CONTRACT_CONVERSION_ORDER}${orderNo}/`,
      convertOrderData,
      this.getOptions(this.props.user)
    );
  };
  contractShortClose = (data, id) => {
    return axios.patch(
      `${APIROUTES.CONTRACT_SHORT_CLOSE}${id}/`,
      data,
      this.getOptions(this.props.user)
    );
  };
  fetchSearchCustomerOptions = (serchValue) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_CLIENT_SEARCH}?search=${serchValue}`,
      this.getOptions(this.props.user)
    );
  };
  fetchBuyers = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_BUYER}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchConsignees = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_CONSIGNEE}${customerId}/`,
      this.getOptions(this.props.user)
    );
  };
  fetchBrokers = () => {
    return axios.get(
      APIROUTES.CONTRACTS_BROKER,
      this.getOptions(this.props.user)
    );
  };
  createOrder = (orderData) => {
    return axios.post(
      APIROUTES.CONTRACTS_CREATE,
      orderData,
      this.getOptions(this.props.user)
    );
  };
  fetchPlantsForItemCategory = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.PLANTS_FOR_ITEM_CATEGORY}${itemCategoryId}/`,
      this.getOptions(this.props.user)
    );
  };
  fetchOrderDetails = (orderId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_DATA_FETCH}${orderId}/`,
      this.getOptions(this.props.user)
    );
  };

  // fetchOrders = async () => {
  //   this.setState({
  //     itemDataFetchLoader: true,
  //   });
  //   const allOrders = await axios.get(APIROUTES.CONTRACTS_LIST, this.getOptions(this.props.user))
  //     .then((response) => {
  //       return response?.data
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         itemDataFetchLoader: false,
  //       });
  //     })
  //   this.setState({
  //     itemDataFetchLoader: false,
  //   });
  //   return allOrders;
  // }

  fetchOrders = async (itemId, customerId, plantId = 0) => {
    // console.log("ID" + itemId + " : " + customerId)
    // console.log("fetchOrder")
    this.setState({
      itemDataFetchLoader: true,
    });
    // const allOrders = await axios.get(APIROUTES.CONTRACTS_LIST, this.getOptions(this.props.user))
    await axios.get
      (`${APIROUTES.CONTRACTS_DROPDOWN_LIST}?item_category=${itemId}&customer=${customerId}`,
        this.getOptions(this.props.user))
      .then((response) => {
        const { data } = response;
        let tempraryItemCategoryData = data.map(
          (orders) => orders.item_category
        );
        const seen = new Set();
        const itemCategoryTabData = tempraryItemCategoryData.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });
        this.setState({
          allOrders: data,
          itemCategoryTabData,
          itemDataFetchLoader: false,
        });
      })
      .catch((error) => {
        this.setState({
          itemDataFetchLoader: false,
        });
      })
  }

  fetchPendingQuantity = (orderId) => {
    return axios.get(
      `${APIROUTES.CHECK_PENDING_QUANTITY}?order_id=${orderId}`,
      this.getOptions(this.props.user)
    );
  }
  savePlantPlanningData = (plantPlanningData) => {
    return axios.post(
      APIROUTES.CONTRACTS_PLANT_PLANNING,
      plantPlanningData,
      this.getOptions(this.props.user)
    );
  };
  fetchOrderLoadingAdvices = (orderId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_LOADING_ADVICE_LIST}?order_id=${orderId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchOrderPlantPlanning = (orderId) => {
    return axios.get(
      `${APIROUTES.ORDER_PLANT_PLANNING}?order_id=${orderId}`,
      this.getOptions(this.props.user)
    );
  };
  savePlantPlanningData = (plantPlanningData) => {
    return axios.post(
      `${APIROUTES.ORDER_PLANT_PLANNING}V2/`,
      plantPlanningData,
      this.getOptions(this.props.user)
    );
  };
  editPlantPlanningData = (plantPlanningData, orderId) => {
    return axios.patch(
      `${APIROUTES.ORDER_PLANT_PLANNING}update/V2/?order_id=${orderId}`,
      plantPlanningData,
      this.getOptions(this.props.user)
    );
  };
  fetchItemsData = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.ITEM_CATEGORY_MASTER_ITEMS}?id=${itemCategoryId}&active=${true}`,
      this.getOptions(this.props.user)
    );
  };
  updateLa = (laData, laId) => {
    return axios.put(
      `${APIROUTES.LOADING_ADVICE_UPDATE}${laId}/`,
      laData,
      this.getOptions(this.props.user)
    );
  };
  createLa = (laData) => {
    return axios.post(
      APIROUTES.LOADING_ADVICE_CREATE,
      laData,
      this.getOptions(this.props.user)
    );
  };

  fetchTcsRate = (customerId, itemId) => {
    return axios
      .get(
        `${APIROUTES.GET_TCS_RATE}?customer_id=${customerId}&item_id=${itemId}`,
        this.getOptions(this.props.user)
      );
  };

  fetchTcs = () => {
    return axios.get(APIROUTES.TCS, this.getOptions(this.props.user));
  };

  approveOrder = (orderData, orderId, itemCategoryId = 5) =>
    new Promise((resolve, reject) => {
      axios
        .patch(
          `${APIROUTES.CONTRACTS_UPDATE}${orderId}/`,
          orderData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleOpenSnackbar('Contract Approved Successfully', "success");
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 3000);
          this.fetchAllOrders(1, itemCategoryId);
          resolve(response);
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 5000);
        });
    });
  updateOrder = (orderData, orderId) => {
    return axios.patch(
      `${APIROUTES.CONTRACTS_UPDATE}${orderId}/`,
      orderData,
      this.getOptions(this.props.user)
    );
  };
  approveLa = (laData, laId) => {
    return axios.patch(
      `${APIROUTES.LOADING_ADVICE_APPROVE}${laId}/`,
      laData,
      this.getOptions(this.props.user)
    );
  };
  handleLaApprove = (laId, laData) => {
    return axios.patch(
      `${APIROUTES.LOADING_ADVICE_APPROVE}${laId}/`,
      laData,
      this.getOptions(this.props.user)
    );
  };

  handleDateChangeLA = (date) => {
    // console.log("here STATE CHANGE" + date)
    this.setState({
      date: date
    });
  }

  cancelLa = (laData, laId) => {
    return axios.patch(
      `${APIROUTES.LOADING_ADVICE_APPROVE}${laId}/`,
      laData,
      this.getOptions(this.props.user)
    );
  };
  approveModifiedContract = (logId, data) => {
    return axios.patch(
      `${APIROUTES.CONTRACT_MODIFICATION_CONFORMATION}${logId}/`,
      data,
      this.getOptions(this.props.user)
    )
  };
  rejectModifiedContract = (logId, data) => {
    return axios.patch(
      `${APIROUTES.CONTRACT_MODIFICATION_CONFORMATION}${logId}/`,
      data,
      this.getOptions(this.props.user)
    )
  };
  approveModifiedLa = (laId, data) => {
    return axios.patch(
      `${APIROUTES.GET_LA_MODIFICATION_ACCESSIBLE}${laId}/`,
      data,
      this.getOptions(this.props.user)
    )
  };
  rejectModifiedLa = (laId, data) => {
    return axios.patch(
      `${APIROUTES.GET_LA_MODIFICATION_ACCESSIBLE}${laId}/`,
      data,
      this.getOptions(this.props.user)
    )
  };

  onOrderAdded = () => {
    this.fetchAllOrders();
  };

  displayPendingContracts = async (tabId = 5, pageId = 1) => {
    return await axios.get(
      `${APIROUTES.GET_PENDING_CONTRACTS}?page=${pageId}&item_category=${tabId}&pending=true`,
      this.getOptions(this.props.user)
    ).then((response) => {
      const { data } = response;
      this.setState({
        allOrders: data?.results,
        totalOrderCount: data?.count,
        itemDataFetchLoader: false,
      });
    })
      .catch((error) => {
        console.error("method error", error)
        this.setState({ itemDataFetchLoader: false });
      });

  }

  getAllStatesList = (countryId) => {
    axios.get(`${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}`,
      this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            allStateList: responseData,
          });
        },
        (error) => {
          console.log("error", error);
        }
      );

  };

  handleGetDistance = () => {
    axios({
      url: `${APIROUTES.GET_EWAY_DATA_DISTANCE_TRACKING}?source_pincode=${this.state.laDetailsInDispatchCounter?.plant?.plant_pincode
        }&destination_pincode=${this.state.laDetailsInDispatchCounter?.consignee
          ?.consignee_from_customer_addresses?.pincode ||
        this.state.laDetailsInDispatchCounter?.consignee
          ?.consignee_from_gst_information_addresses?.pincode
        }&la_number=${this.state?.laDetailsInDispatchCounter?.la_number}`,
      method: "get",
      headers: {
        Authorization: `JWT ${this.props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({
          transporterDistance:
            response.data.IsSuccess === "true"
              ? parseFloat(response.data.Distance).toFixed(2)
              : 0.0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFetchLoadingAdviceDetails = (laNumber, callfor) => {
    this.setState({ laDispatchCounterLoader: true }, () => {
      axios
        .get(`${APIROUTES.FETCH_LA_DETAILS_IN_DISPATCH_COUNTER}${laNumber}/`)
        .then((response) => {
          if (response.status === 200) {
            console.log(response)
            this.state.allStateList.map(data => {
              if (data['id'] === response.data.plant.plant_state) {
                console.log(data['id'] + ":" + data['name'])
                this.setState({
                  plant_state: data['name']
                })
              }
            })

            let data = response.data

            if (data.created_at && data.created_at_time)
              data.created_at_date_and_time = data.created_at + " " + data.created_at_time
            this.setState(
              {
                laDetailsInDispatchCounter: data,
                laDispatchCounterLoader: false,
                invoiceDownloadLoader: callfor === "download_screen" ? false : true,
                laNumber: laNumber
              },
              async () => {
                this.handleGetDistance();
                if (this.state.b2CQrImage === null) {
                  await upiqr({
                    currency: this?.state?.laDetailsInDispatchCounter?.customer?.currency_code,
                    payeeVPA:
                      this?.state?.laDetailsInDispatchCounter?.plant?.plant_upi_id,
                    payeeName:
                      this?.state?.laDetailsInDispatchCounter?.plant?.plant_name,
                    amount: this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value,
                    transactionRef: this.state?.laDetailsInDispatchCounter?.erp_invoice_number,
                    transactionNote: `SUPGSTN ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number
                      } PAYEE_AC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no
                      } PAYEE_IFSC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc
                      } ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
                        ? "IGST " + this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount
                        : `CGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount} SGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount}`
                      } CESS ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount
                      } TOT_AMT ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value
                      } INV_DATE ${moment(
                        this?.state?.laDetailsInDispatchCounter?.created_at,
                        ["DD/MM/YYYY", "YYYY-MM-DD"]
                      ).format("DD MMM YYYY")}`,
                  })
                    .then((upi) => {
                      // console.log(upi.qr); // data:image/png;base64,eR0lGODP...
                      //console.log("UPI", upi.intent); // upi://pay?pa=john@upi&pn=JOHN DOE
                      this.setState(
                        { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
                        () => console.log(1)


                      );
                      //this.props.history.push("invoices/download");
                    })
                    .catch((err) => {
                      this.setState(
                        { b2CQrImage: null, dataInvoicePostLoader: false },
                        () => console.log("error in upiqr")
                      );
                    });
                }
                axios
                  .get(`${APIROUTES.GET_E_INVOICE_AND_E_WAY}${laNumber}/`)
                  .then((response) => {
                    // console.log(response)
                    this.setState({
                      eInvoiceResponse: response.data,
                      eWayBillResponse: response.data?.is_eway_created
                        ? response.data
                        : null,
                      iseWaySelect: response.data?.is_eway_created,
                      invoiceType: response.data?.invoice_type,
                    });
                    console.log("pushing to ila")
                    this.props.history.push(
                      `/internalContracts/dc/2/details/invoices/download/${laNumber}`
                    );
                  });
              }
            );
          } else {
            this.setState({
              laDetailsInDispatchCounter: null,
              laDispatchCounterLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ laDispatchCounterLoader: false });
        });
    });
  };

  handleBill = (laNumber) => {
    this.handleFetchLoadingAdviceDetails(laNumber);

  }


  pdfpayload = (dd, data, codeFrom) => {
    console.log("indexLA", dd, codeFrom)
    let payload = {}
    if (this.state.iseWaySelect && codeFrom === "eway" && !this.state.eway) {
      this.setState({
        eway: data
      })
      // console.log("eway")
    }
    if (this.state.invoiceType === "Combined Invoice" && codeFrom === "invoice" && !this.state.e_invoice) {
      this.setState({
        e_invoice: data
      })
      // console.log("invoice")
      // payload.e_invoice = 
    }
    if (this.state.invoiceType === "Normal Invoice" && codeFrom === "invoice" && !this.state.normal_invoice) {
      this.setState({
        normal_invoice: data
      })
      // console.log("invoice")
      // payload.normal_invoice = 
    }

    // axios.post(`${APIROUTES.PDF_UPLOAD}${this.state?.laDetailsInDispatchCounter?.la_number}/`, { eway: data.split(",")[1] },
    //   this.getOptions(this.props?.user)
    // )
    //   .then(res => console.log(res))
    //   .catch(err => console.log(err))
  }

  handleSendPdfPayload = () => {
    let payload = {}
    if (this.state.eway && this.state.e_invoice) {
      //console.log("here", this.state.eway, this.state.e_invoice)
      payload = {
        eway: this.state.eway,
        e_invoice: this.state.e_invoice
      }
    } else
      if (this.state.eway && this.state.normal_invoice) {
        payload = {
          eway: this.state.eway,
          normal_invoice: this.state.normal_invoice
        }
      } else if (!this.state.iseWaySelect && this.state.normal_invoice) {
        payload = {
          normal_invoice: this.state.normal_invoice
        }
      } else if (!this.state.iseWaySelect && this.state.e_invoice) {
        payload = {
          e_invoice: this.state.e_invoice
        }
      }
    // console.log(payload)
    this.setState({
      isApiCalled: true
    })
    axios.post(`${APIROUTES.PDF_UPLOAD}${this.state?.laDetailsInDispatchCounter?.la_number}/`, payload,
      this.getOptions(this.props?.user)
    )
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          this.setState({
            eway: null,
            e_invoice: null,
            normal_invoice: null,
            isApiCalled: false
          })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          openSnackbar: true,
          snackbarType: "error",
          snackbarMessage: error?.response?.data?.message ? error.response.data.message : "Error while sending E-mail"
        })
      })

  }

  handleFetchPaymentMethodDiscountForCategory = (itemCategoryId) => {
    axios
      .get(
        `${APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT}?id=${itemCategoryId}`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState(
            { categoryPaymentMethodDiscountData: response.data }
          );
        }
      })
      .catch((error) => {
        // console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching payment method discount",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };



  render() {
    const { isMobile } = this.props;
    const { displayNewUi, itemCategoryCode, tagsMaster, freightBasis, openSnackbar,
      snackbarMessage,
      snackbarType, } = this.state;
    return (
      <>
        <Route
          exact
          path="/internalContracts/dc/:dcId/details/invoices/download/:lanumber"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchCounterInvoiceDownload
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handleFetchLoadingAdviceInDispatchCounter={this.handleFetchLoadingAdviceDetails}
              pdfpayload={this.pdfpayload}
              handleSendPdfPayload={this.handleSendPdfPayload}
            />
            // <p>Coming Soon..</p>
          )
          }
        />
        <Route
          exact
          path="/internalContracts"
          render={(props) =>
          // isMobile ? (
          //   <OpenInDesktop {...this.props} {...this.states} />
          // ) : 
          (
            <ContractsWeb
              {...props}
              user={this.props.user}
              {...this.state}
              isMobile={isMobile}
              approveOrder={this.approveOrder}
              fetchAllOrders={this.fetchAllOrders}
              fetchAllCategories={this.fetchAllCategories}
              displayPendingContracts={this.displayPendingContracts}
              fetchSearchOrders={this.fetchSearchOrders}
              fetchSearchPendingOrders={this.fetchSearchPendingOrders}
              handleTabValueCode={this.handleTabValueCode}
              itemCategoryCode={itemCategoryCode}
              tagsMaster={tagsMaster}
            />
          )
          }
        />
        <Route
          exact
          path={[
            `${INTERNALROUTES.CREATE_LOADING_ADVICE_FROM_ORDER}/:orderId`,
            `${INTERNALROUTES.ORDERS}/order/:orderId/editLoadingAdvice/:laId`,
            // `/internalLoadingAdvices/order/:orderId/editLoadingAdvice/:laId`
          ]}
          render={(props) =>
          // isMobile ? (
          //   <OpenInDesktop {...this.props} {...this.states} />
          // ) : 
          (
            <LoadingAdvicesContainer
              {...props}
              user={this.props.user}
              {...this.state}
              isMobile={isMobile}
              fetchAllOrders={this.fetchOrders}
              fetchOrderData={this.fetchOrderDetails}
              fetchCustomerPaymentTerms={this.fetchCustomerPaymentTerms}
              fetchBuyers={this.fetchBuyers}
              fetchConsignees={this.fetchConsignees}
              fetchItemCategories={this.fetchItemCategories}
              fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
              fetchOrderPlantPlanning={this.fetchOrderPlantPlanning}
              updateLa={this.updateLa}
              createLa={this.createLa}
              fetchItemsData={this.fetchItemsData}
              itemDataFetchLoader={this.state.itemDataFetchLoader}
              itemId={this.props.location?.state?.itemId}
              customerId={this.props.location?.state?.customerId}
              handleLaApprove={this.handleLaApprove}
              handleDateChangeLA={this.handleDateChangeLA}
              fetchTcsRate={this.fetchTcsRate}
              itemCategoryCode={itemCategoryCode}
              tagsMaster={tagsMaster}
              handleBill={this.handleBill}
            />
          )
          }
        />
        <Route
          exact
          path="/internalContracts/singleContracts/:id"
          render={(props) =>
            // isMobile ? (
            //   <OpenInDesktop {...this.props} {...this.states} />
            // ) : 
            displayNewUi ? (
              <SingleContractsWebNew
                {...props}
                user={this.props.user}
                isMobile={isMobile}
                fetchOrderDetails={this.fetchOrderDetails}
                fetchPendingQuantity={this.fetchPendingQuantity}
                fetchOrderLoadingAdvices={this.fetchOrderLoadingAdvices}
                fetchOrderPlantPlanning={this.fetchOrderPlantPlanning}
                fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
                savePlantPlanningData={this.savePlantPlanningData}
                editPlantPlanningData={this.editPlantPlanningData}
                fetchItemsData={this.fetchItemsData}
                approveLa={this.approveLa}
                cancelLa={this.cancelLa}
                approveModifiedLa={this.approveModifiedLa}
                rejectModifiedLa={this.rejectModifiedLa}
                approveOrder={this.approveOrder}
                approveModifiedContract={this.approveModifiedContract}
                rejectModifiedContract={this.rejectModifiedContract}
                fetchConversionItemCategories={this.fetchConversionItemCategories}
                ContractConversion={this.ContractConversion}
                fetchReasonMaster={this.fetchReasonMaster}
                contractShortClose={this.contractShortClose}
                fetchInternalUsers={this.fetchInternalUsers}
                users={this.state?.users}
                fetchTcsRate={this.fetchTcsRate}
                itemCategoryCode={itemCategoryCode}
                tagsMaster={tagsMaster}
                handleBill={this.handleBill}
              />
            ) : (
              <SingleContractsWeb {...props} user={this.props.user} />
            )
          }
        />
        <Route
          exact
          path="/internalContracts/addContracts"
          render={(props) =>
            // isMobile ? (
            //   <OpenInDesktop {...this.props} {...this.states} />
            // ) : 
            displayNewUi ? (
              <AddContractsWebNew
                {...props}
                user={this.props.user}
                isMobile={isMobile}
                fetchCustomerPaymentTerms={this.fetchCustomerPaymentTerms}
                fetchSearchCustomerOptions={this.fetchSearchCustomerOptions}
                fetchBuyers={this.fetchBuyers}
                fetchConsignees={this.fetchConsignees}
                fetchBrokers={this.fetchBrokers}
                fetchItemCategories={this.fetchItemCategories}
                createOrder={this.createOrder}
                fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
                savePlantPlanningData={this.savePlantPlanningData}
                fetchTcs={this.fetchTcs}
                onOrderAdded={this.onOrderAdded}
                fetchInternalUsers={this.fetchInternalUsers}
                users={this.state?.users}
                itemCategoryCode={itemCategoryCode}
                tagsMaster={tagsMaster}
                freightBasis={freightBasis}
              />
            ) : (
              <AddContractsWeb {...props} user={this.props.user} />
            )
          }
        />
        <Route
          exact
          path="/internalContracts/editContracts/:id"
          render={(props) =>
          // isMobile ? (
          //   <OpenInDesktop {...this.props} {...this.states} />
          // ) : 
          (
            <EditContractsWebNew
              {...props}
              user={this.props.user}
              isMobile={isMobile}
              fetchOrderDetails={this.fetchOrderDetails}
              fetchCustomerPaymentTerms={this.fetchCustomerPaymentTerms}
              fetchSearchCustomerOptions={this.fetchSearchCustomerOptions}
              fetchBuyers={this.fetchBuyers}
              fetchConsignees={this.fetchConsignees}
              fetchBrokers={this.fetchBrokers}
              fetchItemCategories={this.fetchItemCategories}
              createOrder={this.createOrder}
              fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
              savePlantPlanningData={this.savePlantPlanningData}
              updateOrder={this.updateOrder}
              fetchTcs={this.fetchTcs}
              orderStatus={this.props.location?.state?.orderStatus}
              orderCreatedBy={this.props.location?.state?.orderCreatedBy}
              fetchInternalUsers={this.fetchInternalUsers}
              users={this.state?.users}
              itemCategoryCode={itemCategoryCode}
              tagsMaster={tagsMaster}
              freightBasis={freightBasis}
            />
          )
          }
        />

        <Route
          exact
          path="/internalContracts/subcontracts/:mode/:id"
          render={(props) =>
          (
            <AddSubContractsWebNew
              {...props}
              {...this.state}
              user={this.props.user}
              isMobile={isMobile}
              fetchOrderDetails={this.fetchOrderDetails}
              fetchCustomerPaymentTerms={this.fetchCustomerPaymentTerms}
              fetchSearchCustomerOptions={this.fetchSearchCustomerOptions}
              fetchBuyers={this.fetchBuyers}
              fetchConsignees={this.fetchConsignees}
              fetchBrokers={this.fetchBrokers}
              fetchItemCategories={this.fetchItemCategories}
              createOrder={this.createOrder}
              fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
              savePlantPlanningData={this.savePlantPlanningData}
              updateOrder={this.updateOrder}
              fetchTcs={this.fetchTcs}
              orderStatus={this.props.location?.state?.orderStatus}
              orderCreatedBy={this.props.location?.state?.orderCreatedBy}
              fetchInternalUsers={this.fetchInternalUsers}
              users={this.state?.users}
              itemCategoryCode={itemCategoryCode}
              tagsMaster={tagsMaster}
              freightBasis={freightBasis}
              customerId={this.props.location?.state?.customerId}
              handleFetchPaymentMethodDiscountForCategory={this.handleFetchPaymentMethodDiscountForCategory}
            />
          )
          }
        />

        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </>
    );
  }
}
export default InternalContractsContainer;
