import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import imageCode from "../../../internal/reports/web/ImageCode.json"
import { checkInvalidValue } from "../../../../methods";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
function savebase64AsPDF(folderpath, filename, content, contentType, snackbarFunction, downloaderLoader) {
    // Convert the base64 string in a Blob
    console.log("here", folderpath)
    let DataBlob = b64toBlob(content, contentType);

    console.log("Starting to write the file :3");
    window.resolveLocalFileSystemURL(folderpath, function (dir) {
        console.log("Access to the directory granted succesfully");
        snackbarFunction()
        setTimeout(() => {
            dir.getFile(filename, { create: true }, function (file) {
                console.log("File created succesfully.");

                file.createWriter(function (fileWriter) {
                    console.log("Writing content to file");
                    fileWriter.write(DataBlob);
                    console.log("Folder Path" + folderpath + filename);
                    let finalPath = folderpath + filename;
                    // window.open(String(finalPath), '_system')
                    // window.open(encodeURI(String(finalPath)), '_system')
                    window?.cordova.plugins.fileOpener2.open(finalPath, 'application/pdf')
                    // window.open(finalPath, '_system');

                }, function () {
                    alert('Unable to save file in path ' + folderpath);
                });
            });
            downloaderLoader !== '' && downloaderLoader();
        }, 2000)
    });
}



const handleCustomerRequestsTable = (id, action, snackbarFunction = '', downloaderLoader = '', isApp = '', handleErrorSnackbar = '', handleExtraFunction = '') => {
    let url = isApp !== '' ? `${APIROUTES.GET_APP_LEDGER_PDF}` : `${APIROUTES.DOWNLOAD_LEDGER_PDF}`
    axios
        .get(`${url}?id=${id}`,
            // {
            // onDownloadProgress: function (progressEvent) {
            // console.log(progressEvent, (progressEvent.loaded / progressEvent.total) * 100 + ' %')
            // }
            // },
        )
        .then((response) => {
            if (response.status === 200) {
                const groupedByEntity = {};

                response.data.data.forEach((e) => {
                    const entity = e.entity;
                    groupedByEntity[entity] = groupedByEntity[entity] || [];
                    groupedByEntity[entity].push(e);
                });

                const plantWiseResultArray = Object.values(groupedByEntity)
                // console.log(plantWiseResultArray, typeof plantWiseResultArray)


                var dd = {
                    info: {
                        title: `${response.data.data[0].entity ? response.data.data[0].entity : ""
                            }_${new Date().toISOString()}`,
                        author: "RIPL",
                        subject: "Loading Advice",
                        keywords: "Loading Advice",
                    },
                    pageMargins: [15, 15, 15, 35],

                    // header: function (currentPage) {
                    //     // console.log(imageCode)
                    //     if (currentPage === 1)
                    //         return {
                    //             columns: [
                    //                 {
                    //                     image: imageCode,
                    //                     width: 50,
                    //                     height: 0,
                    //                     margin: [10, 10, 0, 0]
                    //                 }
                    //             ]
                    //         }
                    // },
                    footer: function (currentPage, pageCount) {
                        return {
                            table: {
                                widths: ["*"],
                                body: [
                                    [
                                        // { text: 'Confidential', alignment: 'center' },
                                        { text: 'page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 5, 35, 0], fontSize: 7, }
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        };
                    },


                    // background: {
                    //   // image: AegleBase64,
                    //   // width: 200,
                    //   text: ">",
                    //   // opacity: 0.05,
                    //   absolutePosition: { x: 5, y: 250 },
                    // },
                    background: function (currentPage, pageSize) {

                        return {
                            // image: AegleBase64,
                            // width: 200,
                            text: "<-",
                            absolutePosition: { x: 5, y: pageSize.height / 2 },
                        }
                    },
                    content: plantWiseResultArray.map((plantData, index) => (
                        [
                            {
                                columns: [
                                    {
                                        image: imageCode,
                                        width: 50,
                                        height: 0,
                                        margin: [10, 0, 0, 0]
                                    },
                                    {
                                        text: "REAL Group",
                                        style: "fontSeventeenCenter",
                                        margin: [0, 10, 0, 0],
                                        width: 470
                                    },
                                ]
                            },

                            // {
                            //     text: "REAL Group",
                            //     style: "fontSeventeenCenter",
                            //     margin: [0, 10, 0, 0],
                            //     width: 450
                            // },
                            // "\n",
                            {
                                columns: [
                                    {
                                        text: "ACCOUNT LEDGER",
                                        style: "fontHeaderStyle2",
                                        margin: [190, 0, 0, 0],
                                        width: 380
                                    },
                                ],
                            },
                            {
                                columns: [
                                    {
                                        text: `${moment(response.data?.request_detail?.from_date).format("DD-MMM-YYYY")} To ${moment(response.data?.request_detail?.to_date).format("DD-MMM-YYYY")}`,
                                        style: "fontHeaderStyle",
                                        margin: [200, 0, 0, 20],
                                        width: 370
                                    },
                                ],
                            },
                            {
                                margin: [0, 0, 0, 0],
                                table: {
                                    //margin: [5, 0, 0, 0],
                                    headerRows: 0,
                                    widths: //[85, 40, 50, 30, 60, 60, 60, 60],
                                        [320, 0, 0, 0, 0, 0, 65, 100],
                                    //heights: [40, 40, 40],
                                    body: [
                                        [
                                            {
                                                text: `Customer Name | UID: ${response.data.request_detail?.company_name || ""} | ${response.data.request_detail?.uid || ""}`,
                                                style: "buyerAddressDetails",
                                                colSpan: 4,
                                                alignment: "left",
                                                border: [true, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "codeStyle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text:
                                                    ``,
                                                style: "codeStyle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemNumber",
                                                border: [false, true, false, false],
                                            },

                                            {
                                                text: ``,
                                                style: "itemNumberStyle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemNumber",
                                                border: [false, true, true, false],
                                            },

                                        ],

                                    ],
                                }
                            },
                            {
                                margin: [0, 0, 0, 0],
                                table: {
                                    //margin: [5, 0, 0, 0],
                                    headerRows: 0,
                                    widths: [140, 40, 50, 20, 15, 65, 65, 90],
                                    //heights: [40, 40, 40],
                                    body: [
                                        [
                                            {
                                                text: (!checkInvalidValue(response.data.request_detail?.gst) && checkInvalidValue(response.data.request_detail?.pan)) ? `GST : ${response.data.request_detail?.gst}`
                                                    : (!checkInvalidValue(response.data.request_detail?.pan) && checkInvalidValue(response.data.request_detail?.gst)) ? `PAN : ${response.data.request_detail?.pan}`
                                                        : (!checkInvalidValue(response.data.request_detail?.gst) && !checkInvalidValue(response.data.request_detail?.pan)) ? `GST | PAN : ${response.data.request_detail?.gst} | ${response.data.request_detail?.pan}` : "",
                                                style: "buyerAddressDetails",
                                                colSpan: 4,
                                                alignment: "left",
                                                border: [true, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "codeStyle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text:
                                                    ``,
                                                style: "codeStyle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemNumber",
                                                border: [false, false, false, false],
                                            },

                                            {
                                                text: ``,
                                                style: "itemNumberStyle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemNumber",
                                                border: [false, false, true, false],
                                            },

                                        ],

                                    ],
                                }
                            },
                            {
                                margin: [0, 0, 0, 0],
                                table: {
                                    //margin: [5, 0, 0, 0],
                                    headerRows: 0,
                                    widths: [485, 0, 0, 0, 0, 0, 0, 0],
                                    //heights: [40, 40, 40],
                                    body: [
                                        [
                                            {
                                                text: action === "CORDOVA" ? `Account Type : ${response.data.request_detail?.request_type == "D" ? "Debtor Account" :
                                                    response.data.request_detail?.request_type == "G" ? "Sales Return/Purchase Account" : "Brokerage Account"}` :

                                                    `Account Type | UID: ${response.data.request_detail?.request_type == "D" ? "Debtor Account" :
                                                        response.data.request_detail?.request_type == "G" ? "Sales Return/Purchase Account" : "Brokerage Account"} | ${response.data.request_detail?.acc_code || ""}`,
                                                style: "buyerAddressDetails",
                                                colSpan: 4,
                                                alignment: "left",
                                                border: [true, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "codeStyle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text:
                                                    ``,
                                                style: "codeStyle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemNumber",
                                                border: [false, false, false, false],
                                            },

                                            {
                                                text: ``,
                                                style: "itemNumberStyle",
                                                border: [false, false, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemNumber",
                                                border: [false, false, true, false],
                                            },

                                        ],

                                    ],
                                }
                            },
                            {
                                margin: [0, 0, 0, 0],
                                table: {
                                    headerRows: 1,
                                    widths:
                                        // [25, 85, 45, 55, 35, 45, 35, 45, 50, 55],
                                        //[25, 90, 45, 55, 35, 35, 35, 55, 50, 45],
                                        [20, 45, 45, 40, 120, 75, 75, 65],
                                    body: [
                                        [
                                            {
                                                text: "Entity",
                                                style: ["itemsHeader", "center"],
                                                border: [true, true, true, true],
                                            },
                                            {
                                                text: "Date",
                                                style: ["itemsHeader", "center"],
                                                border: [true, true, true, true],
                                            },
                                            {
                                                text: "Transaction ID",
                                                style: ["itemsHeader", "center"],
                                                border: [true, true, true, true],
                                            },
                                            {
                                                text: "Type",
                                                style: ["itemsHeader", "center"],
                                                border: [true, true, true, true],
                                            },
                                            {
                                                text: "Description",
                                                style: ["itemsHeader", "center"],
                                                border: [true, true, true, true],
                                            },
                                            {
                                                text: "Debit Amount",
                                                style: ["itemsHeader", "center"],
                                                border: [true, true, true, true],
                                            },
                                            {
                                                text: "Credit Amount",
                                                style: ["itemsHeaderAmnt", "center"],
                                                border: [true, true, true, true],
                                            },

                                            {
                                                text: "Balance Amount",
                                                style: ["itemsHeader", "center"],
                                                border: [true, true, true, true],
                                            },
                                        ],
                                    ],
                                },
                            },
                            // console.log(response.data, plantData),
                            // response.data.data.map((e, i) => ({

                            plantData.map((e, i) => (
                                [
                                    {
                                        margin: [0, 0, 0, 0],
                                        table: {
                                            widths:
                                                // [25, 85, 45, 55, 35, 35, 35, 45, 50, 55],
                                                //[25, 90, 45, 55, 35, 35, 35, 55, 50, 45],
                                                [20, 45, 45, 40, 120, 75, 75, 65],
                                            dontBreakRows: true,
                                            body: [
                                                [
                                                    {
                                                        text: `${e.entity || ""}`,
                                                        border: [true, false, true, false],
                                                        style: "itemNumber",

                                                    },
                                                    {
                                                        text: `${moment(e.date).format("DD-MMM-YYYY") || ""}`,
                                                        style: "itemSubTitle",
                                                        border: [true, false, true, false],

                                                    },
                                                    {
                                                        text: `${e.transaction_number || ""}`,
                                                        style: "itemSubTitle",
                                                        border: [true, false, true, false],

                                                    },
                                                    {
                                                        text: `${e.type || ""}`,
                                                        style: "codeStyle",
                                                        border: [true, false, true, false],

                                                    },
                                                    {
                                                        text: `${e.description || ""}`,
                                                        style: "codeStyle2",
                                                        border: [true, false, true, false],

                                                    },
                                                    {
                                                        text: `${e.debit_amount || ""}`,
                                                        style: "itemNumberAlign",
                                                        border: [true, false, true, false],

                                                    },

                                                    {
                                                        text: `${e.credit_amount || ""}`,
                                                        style: "itemNumberAlign",
                                                        border: [true, false, true, false],

                                                    },
                                                    {
                                                        text: `${e.balance_amount || ""}`,
                                                        style: "itemNumberAlign",
                                                        border: [true, false, true, false],

                                                    },

                                                ],
                                            ],
                                        },
                                    },
                                ]
                            )),
                            // plantWiseResultArray[index].length - 1 === 1 &&
                            {
                                margin: [0, 0, 0, 0],
                                table: {
                                    //margin: [5, 0, 0, 0],
                                    headerRows: 0,
                                    widths: [15, 40, 50, 40, 120, 75, 75, 70],
                                    //heights: [40, 40, 40],
                                    body: [
                                        [
                                            {
                                                text: "",
                                                style: "itemNumber",
                                                border: [true, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                style: "codeStyle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: `OPENING BALANCE: ${plantWiseResultArray[index][0]?.balance_amount}`,
                                                style: "codeStyle",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: `Debits : ${plantWiseResultArray[index]?.reduce((n, { debit_amount }) => Number(Number(n) + Number(debit_amount)), 0).toFixed(2) || ""}`,
                                                style: "creditDebitAlign",
                                                border: [false, true, false, false],
                                            },

                                            {
                                                text: `Credits :  ${plantWiseResultArray[index]?.reduce((n, { credit_amount }) => Number(Number(n) + Number(credit_amount)), 0).toFixed(2) || ""}`,
                                                style: "creditDebitAlign",
                                                border: [false, true, false, false],
                                            },
                                            {
                                                text: ``,
                                                // `${response.data.data[0]?.balance_amount}`,
                                                style: "itemNumberAlign",
                                                border: [false, true, true, false],
                                            },

                                        ],

                                    ],
                                }
                            },
                            {
                                margin: [0, 0, 0, 0],
                                table: {
                                    //margin: [5, 0, 0, 0],
                                    headerRows: 0,
                                    widths: [0, 0, 0, 0, 0, 0, 0, 485],
                                    //heights: [40, 40, 40],
                                    body: [
                                        [
                                            {
                                                text: "",
                                                style: "itemNumber",
                                                border: [true, false, false, true],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, false, false, true],
                                            },
                                            {
                                                text: ``,
                                                style: "itemSubTitle",
                                                border: [false, false, false, true],
                                            },
                                            {
                                                text: ``,
                                                style: "codeStyle",
                                                border: [false, false, false, true],
                                            },
                                            {
                                                text: ``,
                                                style: "codeStyle",
                                                border: [false, false, false, true],
                                            },
                                            {
                                                text: ``,
                                                style: "itemNumber",
                                                border: [false, false, false, true],
                                            },

                                            {
                                                text: ``,
                                                style: "closingBalStyle",
                                                border: [false, false, false, true],
                                            },
                                            {
                                                text: `CLOSING BALANCE : ${plantWiseResultArray[index][plantWiseResultArray[index].length - 1]?.balance_amount}`,
                                                style: "itemNumberAlign",
                                                fontSize: 8,
                                                border: [false, false, true, true],
                                            },

                                        ],

                                    ],
                                }
                            },
                            {
                                columns: [
                                    {
                                        text: " ",
                                        style: "fontSixLeft",
                                        margin: [0, 0, 0, 10],
                                        border: [false, false, true, false],
                                    },
                                ],
                            },

                            { text: 'Organisation Details:', style: 'extraInfoheader' },
                            {
                                type: 'none',
                                style: 'extraInfoheader2',
                                ol: response.data?.request_detail?.plant_detail.map((item, i) =>
                                    plantWiseResultArray[index][0]?.entity === item.plant_name ?
                                        [
                                            `Entity: ${item.plant_name}`,
                                            `CIN: ${item.cin_number}`,
                                        ]
                                        : null

                                )
                            },

                            { text: "E & OE.", style: 'OEstyle' },


                            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },
                            {
                                text: "This is electronically generated document.",
                                alignment: "center",
                                fontSize: 7,
                                margin: [0, 2, 0, 0],
                                pageBreak: index !== plantWiseResultArray.length - 1 && 'after'
                            },
                            // {
                            //     text: "",
                            //     pageBreak: 'after'
                            // }
                            // )
                            // })


                        ]
                    )),


                    styles: {
                        tableExample: {
                            margin: [0, 5, 0, 5],
                        },
                        // Taxation Data
                        fontSixLeft: {
                            alignment: "left",
                            fontSize: 6,
                            margin: [0, 0, 0, 0],
                        },
                        extraInfoheader: {
                            alignment: "left",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                            bold: true
                        },
                        extraInfoheader2: {
                            alignment: "left",
                            fontSize: 7,
                            margin: [0, 5, 0, 20],
                            bold: true
                        },
                        extraInfosubheader: {
                            alignment: "left",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        extraInfosubheader2: {
                            alignment: "left",
                            fontSize: 7,
                            margin: [0, 0, 0, 20],
                        },
                        fontSixRight: {
                            alignment: "right",
                            fontSize: 6,
                            margin: [0, 0, 0, 0],
                        },
                        fontSixCenter: {
                            alignment: "center",
                            fontSize: 6,
                            margin: [0, 3, 0, 3],
                        },
                        fontSevenLeft: {
                            alignment: "left",
                            fontSize: 7,
                        },
                        fontSevenRight: {
                            alignment: "right",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        taxDetailCenter: {
                            alignment: "center",
                            fontSize: 7,
                        },
                        fontSevenCenter: {
                            alignment: "center",
                            fontSize: 7,
                            margin: [0, 3, 0, 3],
                        },
                        fontSeventeenCenter: {
                            alignment: "center",
                            fontSize: 15,
                            margin: [0, 0, 0, 0],
                            bold: true,
                        },
                        fontHeaderStyle: {
                            alignment: "center",
                            fontSize: 7,
                            margin: [0, 3, 0, 3],
                            bold: true
                        },
                        fontHeaderStyleDocNo: {
                            alignment: "center",
                            fontSize: 8,
                            margin: [0, 3, 0, 3],
                        },
                        fontHeaderStyle2: {
                            alignment: "center",
                            fontSize: 9,
                            margin: [0, 3, 0, 3],
                            bold: true
                        },
                        fontEightRight: {
                            alignment: "right",
                            fontSize: 8,
                            margin: [0, 0, 0, 0],
                            bold: true,
                        },
                        fontEightLeft: {
                            alignment: "left",
                            fontSize: 8,
                            bold: true,
                            margin: [0, 0, 0, 0],
                        },
                        buyerName: {
                            bold: true,
                            color: "#333333",
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                            margin: [0, 0, 0, 3],
                        },
                        buyerAddressDetails: {
                            // fontSize: 7,
                            fontSize: 8,
                            alignment: "left",
                            bold: true,
                        },
                        // Invoice Detail
                        invoiceDetailLable: {
                            color: "#333333",
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                            //justifyContent: "space-between"
                        },
                        invoiceDetailLableOverview: {
                            color: "#333333",
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                            bold: true
                        },
                        invoiceValuesLabel: {
                            color: "#333333",
                            fontSize: 6,
                            alignment: "right",
                        },
                        itemsHeader: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#EBEBEB",
                            alignment: "center",
                        },
                        itemsHeader2: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            alignment: "right",
                        },
                        itemsHeader3: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            alignment: "center",
                        },
                        itemsHeaderAmnt: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "center",
                        },
                        itemsHeaderUnit: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "center",
                        },
                        itemsHeaderSubTitle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "left",
                        },
                        itemHeaderRightAligned: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "right",
                        },
                        itemSubTitle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                            bold: true

                        },
                        OEValuestyle: {
                            fontSize: 8,
                            margin: [1, 1, 0, 0],
                        },
                        OEValueFooterStyle: {
                            fontSize: 8,
                            margin: [0, 1, 0, 0],
                        },
                        OEstyle: {
                            fontSize: 10,
                        },
                        itemNumber: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "center",
                            bold: true

                        },
                        itemNumberAlign: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "right",
                            bold: true

                        },
                        itemNumberAlign2: {
                            margin: [0, 2, 2, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "right",
                            bold: true

                        },

                        creditDebitAlign: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                            bold: true

                        },
                        codeStyle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "center",
                            bold: true
                        },
                        codeStyle2: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                            bold: true
                        },
                        closingBalStyle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "right",
                            bold: true
                        },
                        itemNumberStyle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "center",
                            bold: true

                        },
                        disclaimerStyle: {
                            alignment: "left",
                            fontSize: 8,
                            bold: true,
                            // margin: [0, 5, 0, 0],
                        },
                        amountNumber: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "right",
                        },
                        itemNumberBelow: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            alignment: "center",
                            borderColor: "#fff"
                        },
                        itemNumberRightAligned: {
                            margin: [10, 0, 0, 0],
                            // fontSize: 8,
                            fontSize: 6.5,
                            alignment: "right",
                        },
                        itemRowEvenNumber: {
                            margin: [0, 2, 0, 2],
                            fontSize: 8,
                            alignment: "center",
                        },
                        tableHeader: {
                            fontSize: 9,
                            bold: true,
                        },
                        tableDetailValues: {
                            fontSize: 9,
                        },
                    },

                };

                // footerContainer.push([
                //     {
                //         text: "Organisation Details : ",
                //         style: "disclaimerStyle"
                //     },
                //     {
                //         columns: [
                //             [

                //                 {
                //                     text: "Entity:",
                //                     style: "fontSevenLeft",
                //                     width: 210,
                //                     // preserveLeadingSpaces: true
                //                     // border: [true, true, true, true]
                //                 },
                //                 {
                //                     text: "CIN:",
                //                     style: "fontSevenLeft",
                //                     width: 210,
                //                     preserveLeadingSpaces: true
                //                     // border: [true, true, true, true]
                //                 },

                //             ],
                //         ]
                //     },
                // ])

                if (action === "PRINT") {
                    snackbarFunction !== '' && snackbarFunction();
                    // pdfMake.createPdf(dd).open()
                    pdfMake.createPdf(dd).download(
                        `${"ledger"}_${new Date().toISOString()}.pdf`
                    );
                    downloaderLoader !== '' && downloaderLoader();
                    handleExtraFunction !== '' && handleExtraFunction();
                }
                else if (action === 'CORDOVA') {
                    let actualFilename = `Ledger_Request_${id}.pdf`
                    pdfMake.createPdf(dd).getBuffer(function (buffer) {
                        const data = buffer.toString('base64')

                        let contentType = "application/pdf";
                        // if cordova.file is not available use instead :
                        // var folderpath = "file:///storage/emulated/0/";
                        let folderpath = ""
                        if (window.cordova.platformId !== 'android') {
                            folderpath = window?.cordova.file.documentsDirectory
                        }
                        else {
                            folderpath = window?.cordova.file.externalRootDirectory + 'Download/';
                            if (folderpath == null) {
                                folderpath = window?.cordova.file.dataDirectory + 'Download/'
                                if (folderpath == null) {
                                    folderpath = window?.cordova.file.dataDirectory || window?.cordova.file.documentsDirectory + 'Download/' || window?.cordova.file.documentsDirectory
                                }
                            }
                        }
                        if (folderpath === null) {
                            alert(`No Access of Files ${folderpath}`)
                        }
                        else {
                            savebase64AsPDF(folderpath, actualFilename, data, contentType, snackbarFunction, downloaderLoader);
                        }
                    })
                }
                else {
                    pdfMake
                        .createPdf(dd)
                        .download(
                            `${"Ledger"}.pdf`
                        );
                }
            }
        })
        .catch((err) => {
            handleErrorSnackbar !== '' && handleErrorSnackbar(err.response)
            downloaderLoader !== '' && downloaderLoader();
            console.log(err);
        });
};

export {
    handleCustomerRequestsTable
}
