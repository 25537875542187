import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import { Button, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  secondaryBlueText,
  drawerTextColor,
  grey,
  primaryColor,
  whiteSmoke,
  secondaryGrey,
} from "../../../../../constants/internal/colors";
import { checkApiError, pxToEm, checkInvalidValue, hasUpdateAccess, arraySortBykeyAllBrowser } from "../../../../../methods";
//New ui imports
import SnackbarComponent from "../../../common/SnackbarComponent";
import DisplayItemCategoryRateTemplateTab from "../DisplayItemCategoryRateTemplateTab";
import ManageItemCategoryRateTemplateTab from "../ManageItemCategoryRateTemplateTab";
import ManageItemCategoryItemTypesTab from "../ManageItemCategoryItemTypesTab";
import ManageItemCategoryPaymentTermAndCityTab from "../ManageItemCategoryPaymentTermAndCityTab";
import ManageItemCategoryCityTab from "../ManageItemCategoryCityTab";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Bread from "../../../common/Breadcrubs";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import SpinLoader from "../../../../common/SpinLoader";
import { withRouter } from "react-router-dom";
import ToolTipComponent from "../../../common/ToolTipComponent";
const itemCategoryDataStyles = (theme) => ({
  titleWithBlueFont: {
    fontSize: pxToEm(18),
    color: secondaryBlueText,
  },

  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  headerRow: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
  },
  displayData: {
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
  tabsStyles: {
    backgroundColor: whiteSmoke,
    color: secondaryGrey,
    fontSize: pxToEm(13),
  },
  selectedTab: {
    backgroundColor: whiteSmoke,
    color: grey,
    fontSize: pxToEm(13),
  },

  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    margin: "0px 10px 0px 20px",
  },
  cancelButton: {
    marginLeft: 20,
  },
  loaderDiv: {
    display: "flex",
    alignItems: "center",
    placeContent: "center",
    width: "100%",
    height: "calc(100vh - 200px)",
  },
});
class DisplayItemCategoryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemCategoryData: [],
      fetchItemCategoryDataLoader: true,
      categoryName: "",
      displayAllData: false,
      itemOperation: null,
      tabValue: 0,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      selectedItemToDisplay: "",
      itemActiveStatus: false,
      itemRateZeroStatus: false,
      itemConsiderPacking: false,
      itemCategoryName: "",
      itemCategoryNameError: false,
      toleranceShortValue: null,
      toleranceMaxValueError: false,
      toleranceMaxValue: null,
      toleranceShortValueError: false,
      disableSaveButtonForItemCategory: true,
      saveItemUnderCategoryLoader: false,
      newItemUnderCategoryData: [],
      rateTemplateTableAddNewNetRateValue: null,
      rateTemplateTableAddNewNetRateValueError: false,
      rateTemplateTableAddNewOpenRateValue: null,
      rateTemplateTableAddNewOpenRateValueError: false,
      rateTemplateTableAddNewState: null,
      rateLinkingRateDifference: 0,
      rateLinkingItemId: null,
      cityDiscountAmountError: false,
      paymentTermDiscountAmountError: false,
      paymentTermPremiumError: false,
      cityDiscountAmount: null,
      paymentTermDiscountAmount: null,
      premium_amount: null,
      allPaymentMethodData: [],
      allPaymentMethodDataLoader: true,
      selectedPaymentTermId: null,
      selectedCityId: null,

      entityCode: null,
      itemCategoryAddEditLoader: false,
      itemCategoryExistingRateTemplateData: null,
      itemCategoryPaymentTermData: [],
      itemCategoryPaymentTermDataFetchLoader: true,
      itemCategoryCityData: [],
      itemCategoryLogo: null,
      itemCategoryCityDataFetchLoader: true,
      allCities: [],
      allCitiesLoader: true,
      itemsData: [],
      itemDataFetchLoader: true,
      allTagsData: [],
      allTagsDataFetchLoader: true,
      insuranceRate: null,
      loadingRateValidFrom: null,
      insuranceRateValidFrom: null,
      insuranceRateError: false,
      loadingRate: null,
      loadingRateError: false,
      igst: null,
      igstError: false,
      cgst: null,
      cgstError: false,
      sgst: null,
      sgstError: false,
      hsnCode: null,
      hanCodeError: false,
      allUnits: [],
      allItemSize: [],
      allItemNature: [],
      itemTypeAdded: false,
      isLoadingPayment: false,
      isLoadingPaymentamount: false,
      isLoadingCity: false,
      isLoadingCityamount: false,
      rateLinkingData: [],
      rateLinkingOperation: {
        operation: 'view',
        index: -1
      },
      isLoadingRateLinking: false,
      effectiveDate: "",
      premiumAmountDate: ""

    };
  }

  // initializeData(whichProps) {
  //   const itemOperation = this.props.location.pathname.includes("details")
  //     ? "view"
  //     : "add";
  //   this.setState({ itemOperation });
  //   if (itemOperation === "view") {
  //     const id = this.props?.location?.pathname?.split("/")[
  //       this.props?.location?.pathname?.split("/").length - 1
  //     ];
  //     this.setState({ selectedItemId: id }, () =>
  //       this.prepareItemCategoryDataToDisplay()
  //     );
  //   }
  // }

  // Common functions to all tabs
  componentDidMount() {
    const itemOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    const id =
      itemOperation === "view"
        ? this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
        ]
        : null;

    this.setState({ itemOperation, selectedItemId: id }, () =>
      id !== null
        ? this.prepareItemCategoryDataToDisplay()
        : null
    );
  }
  static getDerivedSatteFromProps(props, state) {
    const itemOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    const id =
      itemOperation === "view"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    let updatedData = new Map();
    if (itemOperation !== state?.itemOperation) {
      updatedData.set("itemOperation", itemOperation);
    }
    if (id !== null && id !== state?.selectedItemId) {
      updatedData.set("selectedItemId", id);
      this.prepareItemCategoryDataToDisplay();
    }

    if (updatedData.size > 0) {
      return {
        itemOperation: updatedData.has("itemOperation")
          ? updatedData.get("itemOperation")
          : true,
        selectedItemId: updatedData.has("selectedItemId")
          ? updatedData.get("selectedItemId")
          : null,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate = (nextprops, prevState) => {
    console.log(prevState.itemTypeAdded, this.state.itemTypeAdded)
    if (prevState.itemTypeAdded !== this.state.itemTypeAdded) {
      console.log('componentDidUpdate')
      this.handleTabValue(1)
    }
  }

  handleTabValue = (data) => {
    console.log(data)
    this.setState({ tabValue: data });
    if (data === 1) {
      console.log("data", data)
      // if (this.state.itemsData.length < 1) {
      this.handleFetchAllTagsData();
      this.handleFetchItemsData();
      this.handleFetchAllUnits();
      this.handleFetchAllItemSize();
      this.handleFetchAllItemNature();
      // }
    }
  }

  handleTabChange = (event, tabValue) => {
    // console.log(tabValue)
    this.setState({ tabValue });
    if (tabValue === 1) {
      if (this.state.itemsData.length < 1) {
        this.handleFetchAllTagsData();
        this.handleFetchItemsData();
        this.handleFetchAllUnits();
        this.handleFetchAllItemSize();
        this.handleFetchAllItemNature();
      }
    } else if (tabValue === 2) {
      if (this.state.itemCategoryPaymentTermData.length < 1) {
        this.handleFetchAllAllowedPaymentMethods();
        this.fetchItemCategoryPaymentTerm();
      }
    } else if (tabValue === 3) {
      if (this.state.itemCategoryCityData < 1) {
        this.handleFetchAllCities();
        this.handleFetchItemCategoryCityData();
      }
    } else {
    }
  };
  handleFetchAllTagsData = () => {
    this.props
      .fetchAllTagsData()
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data)
          this.setState({
            allTagsData: response.data,
            allTagsDataFetchLoader: false,
          });
        } else {
          this.setState({
            allTagsDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching tags data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          allTagsDataFetchLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching tags data `,
          snackbarType: "error",
        });
      });
  };
  handleFetchAllUnits = () => {
    this.props
      .fetchAllUnits()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allUnits: response.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching tags data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching tags data `,
          snackbarType: "error",
        });
      });
  };
  handleFetchAllItemSize = () => {
    this.props
      .fetchAllItemSize(this.state.selectedItemId)
      .then((response) => {
        if (response.status === 200) {
          console.log(this.state.selectedItemId)
          let responseData = arraySortBykeyAllBrowser(response.data, 'size')
          this.setState({
            allItemSize: responseData,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching tags data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching tags data `,
          snackbarType: "error",
        });
      });
  };
  handleFetchAllItemNature = () => {
    this.props
      .fetchAllItemNature()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allItemNature: response.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching item nature data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching item nature data `,
          snackbarType: "error",
        });
      });
  };
  handleFetchItemCategoryCityData = () => {
    let itemCategoryId = this?.state?.selectedItemId;
    this.props
      .fetchItemCategoryCityData(itemCategoryId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            itemCategoryCityData: response.data,
            itemCategoryCityDataFetchLoader: false,
          });
        } else {
          this.setState({
            itemCategoryCityDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching item categories payment method `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          itemCategoryCityDataFetchLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching item categories payment method `,
          snackbarType: "error",
        });
      });
  };
  fetchItemCategoryPaymentTerm = () => {
    let itemCategoryId = this.state.selectedItemId;
    this.props
      .fetchItemCategoryPayemntTermData(itemCategoryId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            itemCategoryPaymentTermData: response.data,
            itemCategoryPaymentTermDataFetchLoader: false,
          });
        } else {
          this.setState({
            itemCategoryPaymentTermDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching item categories payment method `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          itemCategoryPaymentTermDataFetchLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching item categories payment method `,
          snackbarType: "error",
        });
      });
  };




  handleChange = (name) => (event) => {
    console.log(name + " : " + event)
    if (name === "effectiveDate" || name === "premiumAmountDate") {
      this.setState({ [name]: moment(new Date(event)).format("YYYY-MM-DD") })
    } else {
      const { value, checked } = event?.target;

      if (name === "itemActiveStatus" || name === "itemRateZeroStatus" || name === "itemConsiderPacking") {
        this.setState({ [name]: checked });
      } else {
        this.setState({ [name]: value });
      }
      if (name === "itemCategoryName") {
        if (!value) {
          this.setState({
            itemCategoryNameError: true,
            itemCategoryName: null,
          });
        } else {
          this.setState({ [name]: value, itemCategoryNameError: false });
        }
      } else if (name === "toleranceMaxValue") {
        // if (value > 0) {
        this.setState({ [name]: Number(value), toleranceMaxValueError: false });
        // } else {
        //   this.setState({ toleranceMaxValueError: true });
        // }
      } else if (name === "toleranceShortValue") {
        // if (value < 0) {
        this.setState({
          [name]: Number(value),
          toleranceShortValueError: false,
        });
        // } else {
        //   this.setState({ toleranceShortValueError: true });
        // }
      } else if (name === "rateTemplateTableAddNewOpenRateValue") {
        if (!value) {
          this.setState({
            rateTemplateTableAddNewOpenRateValueError: true,
            rateTemplateTableAddNewOpenRateValue: 0,
          });
        } else if (value > 0) {
          this.setState({
            [name]: Number(value),
            rateTemplateTableAddNewOpenRateValueError: false,
          });
        } else {
          this.setState({
            rateTemplateTableAddNewOpenRateValueError: true,
            rateTemplateTableAddNewOpenRateValue: 0,
          });
        }
      } else if (name === "rateTemplateTableAddNewNetRateValue") {
        if (value === 0 || value > 0) {
          this.setState({
            [name]: Number(value),
            rateTemplateTableAddNewNetRateValueError: false,
          });
        } else {
          this.setState({ rateTemplateTableAddNewNetRateValueError: true });
        }
      } else if (name === "cityDiscountAmount") {
        if (value == 0 || value > 0) {
          this.setState({
            [name]: Number(value),
            cityDiscountAmountError: false,
          });
        } else {
          this.setState({ cityDiscountAmountError: true });
        }
      } else if (name === "paymentTermDiscountAmount") {
        console.log(value)
        if (value.length > 0) {
          this.setState({
            [name]: Number(value),
            paymentTermDiscountAmountError: false,
          });
        } else {
          // this.setState({ paymentTermDiscountAmountError: true });
        }
      } else if (name === "premium_amount") {
        if (value.length > 0) {
          this.setState({
            [name]: Number(value),
            paymentTermPremiumError: false,
          });
        } else {
          // this.setState({ paymentTermPremiumError: true });
        }
      } else {
      }
      if (
        !this.state.itemCategoryName ||
        !this.state.toleranceMaxValue ||
        !this.state.toleranceShortValue
      ) {
        this.setState({ disableSaveButtonForItemCategory: true });
      } else {
        this.setState({ disableSaveButtonForItemCategory: false });
      }
    }
  };
  handleEditItemOperation = () => {
    // this.props.history.push(
    //   `/internalMastersManagement/itemsCategory/edit/${Number(
    //     this.state.selectedItemId
    //   )}`
    // );
    this.setState({
      itemOperation: "edit",
      tabValue: 0,
    });
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return typeof (data) === Object ?
      (<>
        <p style={{ margin: 0 }}>
          state_name : {data['state_name']}
        </p>
      </>)
      :
      (data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
      )
  }

  prepareRateLinkedItem = (id) => {
    this.props.fetchLinkedItems(id)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            rateLinkingData: response.data
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while fetching rate Linking data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: "Error occured while fetching rate Linking data",
          snackbarType: "error",
        });
      })
  }

  prepareItemCategoryDataToDisplay = () => {
    this.props
      .fetchSelectedItemCategoryData(Number(this.state.selectedItemId))
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let selectedItemCategoryData = response.data;
          this.setState({
            itemCategoryName: selectedItemCategoryData.category_name,
            entityCode: selectedItemCategoryData.entity_code,
            itemActiveStatus: selectedItemCategoryData.is_active,
            itemRateZeroStatus: selectedItemCategoryData.is_allow_rate_zero,
            itemConsiderPacking: selectedItemCategoryData.is_consider_packing,
            toleranceMaxValue: selectedItemCategoryData.max_tolerance_value,
            toleranceShortValue: selectedItemCategoryData.min_tolerance_value,
            rateLinkingItemId: selectedItemCategoryData.related_to,
            rateLinkingRateDifference: selectedItemCategoryData.rate_difference,
            insuranceRate: selectedItemCategoryData.insurance_rate,
            hsnCode: selectedItemCategoryData.hsn_code,
            loadingRate: selectedItemCategoryData.loading_rate,
            igst: selectedItemCategoryData.igst,
            cgst: selectedItemCategoryData.cgst,
            sgst: selectedItemCategoryData.sgst,
            unit: selectedItemCategoryData.unit,
            fetchItemCategoryDataLoader: false,
            item_nature: selectedItemCategoryData.item_nature,
            displayAllData: true,
            div_code: selectedItemCategoryData.div_code,
            item_category_code: selectedItemCategoryData.item_category_code,
            item_category_logo: selectedItemCategoryData.item_category_logo,
            primary_unit: selectedItemCategoryData.primary_unit,
            secondary_unit: selectedItemCategoryData.secondary_unit,
            loadingRateValidFrom: selectedItemCategoryData?.loading_rate_valid_from,
            insuranceRateValidFrom: selectedItemCategoryData?.insurance_rate_valid_from,

          });
          this.handleFetchSelectedItemRateTemplateTableData(
            Number(this.state.selectedItemId)
          );
          this.prepareRateLinkedItem(Number(this.state.selectedItemId))
          // this.handleFetchItemsData(Number(this.state.selectedItemId));
        } else {
          this.setState({
            fetchItemCategoryDataLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer details plaese try again",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          fetchItemCategoryDataLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching cutomer details plaese try again`,
          snackbarType: "error",
        });
      });
  };
  // Functions for rate template tab
  handleSaveItemCategory = () => {
    const { itemOperation } = this?.state;
    let itemCategoryData = {
      category_name: this?.state?.itemCategoryName || "",
      max_tolerance_value: this?.state?.toleranceMaxValue || "",
      min_tolerance_value: this?.state?.toleranceShortValue || "",
      is_active: this?.state?.itemActiveStatus || false,
      is_allow_rate_zero: this?.state?.itemRateZeroStatus || false,
      is_consider_packing: this?.state?.itemConsiderPacking || false,
      hsn_code: "",
      item_category_logo: this.state?.itemCategoryLogo || null,
      cgst: this?.state?.cgst || 0,
      sgst: this?.state?.sgst || 0,
      igst: this?.state?.igst || 0,
      insurance_rate: this?.state?.insuranceRate || 0,
      loading_rate_valid_from: this?.state?.loadingRateValidFrom,
      insurance_rate_valid_from: this?.state?.insuranceRateValidFrom,
      loading_rate: this?.state?.loadingRate || 0,
    };
    console.log("here")
    if (itemOperation === "add") {
      this.props
        .saveItemCategory(itemCategoryData)
        .then((response) => {
          if (response.status === 201) {
            this.props.fetchAllItemCatgories();
            this.props.history.push(
              `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/${response.data.id}`
            );
          } else {
            this.setState({
              displayAllData: false,
              openSnackbar: true,
              snackbarMessage: `Error occured while creating item category`,
              snackbarType: "error",
            });
          }
        })
        .catch((error) => {
          this.setState({
            displayAllData: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while creating item category`,
            snackbarType: "error",
          });
        });
    } else if (itemOperation === "edit") {
      this.props
        .updateItemCategory(itemCategoryData, this?.state?.selectedItemId)
        .then((response) => {
          if (response.status === 200) {
            this.props.fetchAllItemCatgories();

            this.props.history.push(
              `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/${response.data.id}`
            );
          } else {
            this.setState({
              displayAllData: false,
              openSnackbar: true,
              snackbarMessage: `Error occured while updating item category`,
              snackbarType: "error",
            });
          }
        })
        .catch((error) => {
          this.setState({
            displayAllData: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while updating item category`,
            snackbarType: "error",
          });
        });
    } else {
    }

    // this.setState({ displayAllData: true });
  };
  handleAddDailyRateTemplate = () => {
    let dailyRateTemplateData = {
      state: this.state.rateTemplateTableAddNewState,
      high_rate_difference: this.state.rateTemplateTableAddNewOpenRateValue,
      low_rate_difference: this.state.rateTemplateTableAddNewNetRateValue,
      is_base_rate: true,
      item_category: this.state.selectedItemId,
    };
    // this.setState({ displayAllData: true });
  };

  handleRateTemplateTableStateChange = (
    event
    //,value
  ) => {
    const { value, name } = event.target;

    if (value) {
      this.setState({ rateTemplateTableAddNewState: value.id });
    } else {
      this.setState({ rateTemplateTableAddNewState: null });
    }
  };

  addNewItemUnderCategoryData = () => {
    this.setState((previousState) => {
      return {
        newItemUnderCategoryData: [
          ...previousState.newItemUnderCategoryData,
          {
            item_name: "",
            hsn_code: "",
            rate_difference: "",
            discount: "",
            tags: "",
            packing_weight: "",
            tolerance_upto_1mts: "",
            tolerance_from_1to5mts: "",
            tolerance_above_5mts: "",
            section_data: [],
          },
        ],
      };
    });
  };
  removeNewItemUnderCategoryData = (index) => {
    this.setState((previousState) => {
      const newItemUnderCategoryData = [
        ...previousState.newItemUnderCategoryData,
      ];
      newItemUnderCategoryData.splice(index, 1);
      return { newItemUnderCategoryData };
    });
  };
  handleNewItemUnderCategoryDataValueChange =
    (index, name) => async (event) => {
      const { value } = event.target;
      await this.setState((previousState) => {
        const newItemUnderCategoryData = [
          ...previousState.newItemUnderCategoryData,
        ];
        newItemUnderCategoryData[index] = {
          ...newItemUnderCategoryData[index],
          [name]: value,
        };
        return { newItemUnderCategoryData };
      });
      // }
    };
  // Payment term tab and City tab functions
  handleFetchAllAllowedPaymentMethods = () => {
    this.props
      .fetchAllPaymentMethods()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allPaymentMethodData: response.data,
            allPaymentMethodDataLoader: false,
          });
        } else {
          this.setState({
            allPaymentMethodDataLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetch all allowed payment method `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          allPaymentMethodDataLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetch all allowed payment method `,
          snackbarType: "error",
        });
      });
  };
  handleFetchItemsData = () => {
    let itemCategoryId = this?.state?.selectedItemId;
    this.props
      .fetchItemsData(itemCategoryId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            itemsData: response.data,
            itemDataFetchLoader: false,
          });
        } else {
          this.setState({
            itemDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching items data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          itemDataFetchLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetching items data `,
          snackbarType: "error",
        });
      });
  };
  handleFetchAllCities = () => {
    this.props
      .fetchAllCities()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allCities: response.data,
            allCitiesLoader: false,
          });
        } else {
          this.setState({
            allCitiesLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetch all cities data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          allCitiesLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetch all cities data `,
          snackbarType: "error",
        });
      });
  };
  handleFetchSelectedItemRateTemplateTableData = (selectedItemId) => {
    this.props
      .fetchDailyRateTemplate(selectedItemId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            itemCategoryExistingRateTemplateData:
              response.data.daily_rates_template,
            // allPaymentMethodDataLoader: false,
          });
        } else {
          this.setState({
            allPaymentMethodDataLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetch all allowed payment method `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          allPaymentMethodDataLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while fetch all allowed payment method `,
          snackbarType: "error",
        });
      });
  };
  handleAddRateTemplateTableData = (rateTemplateAddData) => {
    this.props
      .saveRateTemplateData(rateTemplateAddData)
      .then((response) => {
        if (response.status === 201) {
          this.handleOpenSnackbar(
            `Rate Template Table Created Successfully`,
            "success"
          );
          setTimeout(() => {
            this.props.getItemCateogriesList()
            this.handleFetchSelectedItemRateTemplateTableData(
              this.state.selectedItemId
            );
            this.handleCloseSnackbar()
          }, 4000);
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while creating rate template entry",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleFetchSelectedItemRateTemplateTableData(
            this.state.selectedItemId
          );
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.message
        //     ? error?.data?.message
        //     : error?.data?.detail
        //       ? error?.data?.detail
        //       : "Error occured while creating rate template entry",
        //   snackbarType: "error",
        // });
      });
  };
  handleUpdateRateTemplateTableData = (updatedData) => {
    let rateTemplateUpdateData = {
      category_name: this?.state?.itemCategoryName,
      related_to: this?.state?.rateLinkingItemId,
      rate_difference: this?.state?.rateLinkingRateDifference,
      daily_rates_template: updatedData,
    };
    this.props
      .updateRateTemplateData(
        rateTemplateUpdateData,
        this?.state?.selectedItemId
      )
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Rate Template Table Updated Successfully`,
            "success"
          );
          setTimeout(() => {
            this.props.getItemCateogriesList()
            this.handleFetchSelectedItemRateTemplateTableData(
              this.state.selectedItemId
            );
            this.handleCloseSnackbar()
          }, 4000);
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while updating rate template data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleFetchSelectedItemRateTemplateTableData(
            this.state.selectedItemId
          );
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.message
        //     ? error?.data?.message
        //     : error?.data?.detail
        //       ? error?.data?.detail
        //       : "Error occured while updating rate template data",
        //   snackbarType: "error",
        // });
      });
  };
  handlePaymentTermTableAutocompleteValueChange = (event, value) => {
    console.log(value)
    if (value) {
      this.setState({ selectedPaymentTermId: value.id });
    } else {
      this.setState({ selectedPaymentTermId: null });
    }
  };
  handleCityTableAutocompleteValueChange = (event, value) => {
    if (value) {
      this.setState({ selectedCityId: value.id });
    } else {
      this.setState({ selectedCityId: null });
    }
  };
  handleCancelButton = (saveDataIn) => {
    if (saveDataIn === "paymentTerm") {
      this.setState({
        paymentTermDiscountAmount: null,
        paymentTermDiscountAmountError: false,
        premium_amount: null,
        paymentTermPremiumError: false,
        effectiveDate: "",
        premiumAmountDate: ""
      })
    }
    else if (saveDataIn === "city") {
      this.setState({
        cityDiscountAmount: null,
        cityDiscountAmountError: false
      })
    }
    else { }
  }
  handlePaymentOrCityStateValue = (saveDataIn) => {
    if (saveDataIn === "paymentTerm") {
      this.setState({
        paymentTermDiscountAmount: null,
        paymentTermDiscountAmountError: false,
        premium_amount: null,
        paymentTermPremiumError: false,
        selectedPaymentTermId: null
      })
    }
    else if (saveDataIn === "city") {
      this.setState({
        selectedCityId: null,
        cityDiscountAmount: null,
        cityDiscountAmountError: false
      })
    }

  }
  handlePaymentTermOrCityTableSaveData = (saveDataIn) => {
    if (saveDataIn === "paymentTerm") {
      let paymentTermSaveData = {
        discount_amount: this?.state?.paymentTermDiscountAmount !== null ? Number(this.state.paymentTermDiscountAmount) : 0,
        premium_amount: this?.state?.premium_amount !== null ? Number(this.state.premium_amount) : 0,
        payment_method: this?.state?.selectedPaymentTermId,
        item_category: this?.state?.selectedItemId,
        discount_amount_valid_from: checkInvalidValue(this.state?.effectiveDate) ? this.state.premiumAmountDate : this.state?.effectiveDate,
        premium_amount_valid_from: checkInvalidValue(this.state?.premiumAmountDate) ? this.state?.effectiveDate : this.state.premiumAmountDate
      };
      this.handlePaymentTermDataSave(paymentTermSaveData);
      this.setState({
        paymentTermDiscountAmount: null,
        paymentTermDiscountAmountError: false,
        premium_amount: null,
        paymentTermPremiumError: false,
        selectedPaymentTermId: null
      })
    } else if (saveDataIn === "city") {
      let citySaveData = {
        city: this?.state?.selectedCityId,
        discount_amount: this?.state?.cityDiscountAmount || 0,
        item_category: this?.state?.selectedItemId,
        valid_from: this.state?.effectiveDate
      };
      this.handleCityDataSave(citySaveData);
      this.setState({
        selectedCityId: null,
        cityDiscountAmount: null,
        cityDiscountAmountError: false
      })
    } else {
    }
  };
  handlePaymentTermDataSave = (paymentTermSaveData) => {
    this.setState({ isLoadingPayment: true })
    this.props
      .paymentTermDataSave(paymentTermSaveData)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.props
            .fetchItemCategoryPayemntTermData(this.state.selectedItemId)
            .then((response) => {
              if (response.status === 200) {
                this.handleOpenSnackbar(
                  `Payment Term Added Successfully`,
                  "success"
                );
                setTimeout(() => {
                  this.setState({
                    itemCategoryPaymentTermData: response.data,
                    itemCategoryPaymentTermDataFetchLoader: false,
                    isLoadingPayment: false
                  });
                  this.handleCloseSnackbar()
                }, 3000);
              } else {
                this.setState({
                  itemCategoryPaymentTermDataFetchLoader: false,
                  openSnackbar: true,
                  snackbarMessage: `Error occured while fetching item categories payment method `,
                  snackbarType: "error",
                  isLoadingPayment: false
                });
              }
            });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Payment Term Saved",
            snackbarType: "success",
            isLoadingPayment: false
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingPayment: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleCityDataSave = (citySaveData) => {
    this.setState({ isLoadingCity: true })
    this.props
      .cityDataSave(citySaveData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.handleOpenSnackbar(
            `City discount Created Successfully`,
            "success"
          );
          setTimeout(() => {
            this.setState({ isLoadingCity: false })
            this.handleFetchItemCategoryCityData();
            this.handleCloseSnackbar()
          }, 3000);
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while creating rate template entry",
            snackbarType: "error",
            isLoadingCity: false
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingCity: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleFetchItemCategoryCityData();
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleCancelButtonClick = () => {
    if (this.state.itemOperation === "add") {
      this.props.history.push("/internalMastersManagement/itemsCategory");
    } else if (this.state.itemOperation === "view") {
      this.props.history.push("/internalMastersManagement/itemsCategory");
    } else {
      this.handleEditItemOperation();
    }
  };
  handleBaseStateChange = (rateTemplateDataId, index) => {
    let rateTemplateData = this?.state?.itemCategoryExistingRateTemplateData;
    if (rateTemplateData.length > 0) {
      rateTemplateData.forEach((d, i) => {
        if (d.is_base_rate) {
          rateTemplateData[i]["is_base_rate"] = false;
        }
      });
    }
    rateTemplateData[index]["is_base_rate"] = true;
    // rateTemplateData[index]["item_category"] = this.state.selectedItemId;
    let updatedDataForBaseState = {
      category_name: this.state.itemCategoryName,
      related_to: this.state.rateLinkingItemId,
      rate_difference: this.state.rateLinkingRateDifference,
      daily_rates_template: rateTemplateData,
    };
    this.props
      .setBaseState(updatedDataForBaseState, this.state.selectedItemId)
      .then((response) => {
        if (response.status === 200) {
          this.handleFetchSelectedItemRateTemplateTableData(
            this.state.selectedItemId
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while changing base state",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.message
            ? error?.data?.message
            : error?.data?.detail
              ? error?.data?.detail
              : "Error occured while changing base state",
          snackbarType: "error",
        });
      });
  };
  handleUpdateDiscountAmount = (updatedData, dataFor, dataId) => {
    if (dataFor !== "city") {
      this.setState({ isLoadingPaymentamount: true })
    }
    else {
      this.setState({ isLoadingCityamount: true })
    }
    this.props
      .updateDiscountAmount(updatedData, dataFor, dataId)
      .then((response) => {
        if (response.status === 200) {
          if (dataFor === "city") {
            this.handleOpenSnackbar(
              `City discount Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              this.setState({ isLoadingCityamount: false })
              this.props.fetchItemCategoryCityData(dataId);
              this.handleCloseSnackbar()
            }, 3000);
          } else {
            this.handleOpenSnackbar(
              `Payment Terms Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              this.setState({ isLoadingPaymentamount: false })
              this.fetchItemCategoryPaymentTerm(this.state.selectedItemId);
              this.handleCloseSnackbar()
            }, 3000);
          }
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while updating discount amount",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoadingPaymentamount: false, isLoadingCityamount: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.message
        //     ? error?.data?.message
        //     : error?.data?.detail
        //       ? error?.data?.detail
        //       : "Error occured while updating discount amount",
        //   snackbarType: "error",
        // });
      });
  };
  handleSetRateLinkingData = (linkedItemCategoryId, rateDifferenceAmount) => {
    let updatedData = {
      entity_code: null,
      category_name: this?.state?.itemCategoryName,
      related_to: linkedItemCategoryId,
      rate_difference: rateDifferenceAmount,
      max_tolerance_value: this?.state?.toleranceMaxValue,
      min_tolerance_value: this?.state?.toleranceShortValue,
      is_active: true,
    };
    this.props
      .setRateLinkingData(this.state.selectedItemId, updatedData)
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Rate Linking Data Updated Successfully`,
            "success"
          );
          setTimeout(() => {
            this.handleCloseSnackbar()
          }, 4000);
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while updating rate linking data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.message
        //     ? error?.data?.message
        //     : error?.data?.detail
        //       ? error?.data?.detail
        //       : "Error occured while updating rate linking data",
        //   snackbarType: "error",
        // });
      });
  };
  handleDeleteRateLinkingData = (linkedItemCategoryId, rateDifferenceAmount) => {
    let updatedData = {
      entity_code: null,
      category_name: this?.state?.itemCategoryName,
      related_to: linkedItemCategoryId,
      rate_difference: rateDifferenceAmount,
      max_tolerance_value: this?.state?.toleranceMaxValue,
      min_tolerance_value: this?.state?.toleranceShortValue,
      is_active: true,
    };
    this.props
      .deleteRateLinkingData(this.state.selectedItemId, updatedData)
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Rate Linking Data Removed Successfully`,
            "success"
          );
          setTimeout(() => {
            this.props.history.push("/internalMastersManagement/itemsCategory")
            this.props.history.push(`/internalMastersManagement/itemsCategory/${this?.state?.selectedItemId}`);
            this.handleCloseSnackbar()
          }, 4000);
        }
        else if (response['response']['status'] === 500) {
          this.handleOpenSnackbar(
            `This Rate Linking cannot be Deleted Because it is referenced to Other Data`,
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar()
          }, 8000);
        }
        else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while removing rate linking data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error.response)
        if (error['response']['status'] === 500) {
          this.handleOpenSnackbar(
            `This Rate Linking cannot be Deleted Because it is referenced to Other Data`,
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar()
          }, 8000);
        }
        else {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        }
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.message
        //     ? error?.data?.message
        //     : error?.data?.detail
        //       ? error?.data?.detail
        //       : "Error occured while removing rate linking data",
        //   snackbarType: "error",
        // });
      });
  };
  handleItemAndSectionDataSave = (itemSectionData) => {
    console.log(this.state.itemConsiderPacking)
    let itemData = {
      item_code: itemSectionData.item_code,
      item_name: itemSectionData.item_name,
      item_size: itemSectionData.item_size,
      hsn_code: itemSectionData.hsn_code,
      rate_difference: itemSectionData.rate_difference,
      //discount_amount: itemSectionData.discount_amount,
      item_category: itemSectionData.item_category,
      tags: [{
        item: itemSectionData.id,
        tag: itemSectionData.tags.id,
      }],
      unit: itemSectionData?.unit,
      items_nature: itemSectionData.items_nature,
      is_active: itemSectionData.is_active,
      valid_from: itemSectionData.valid_from,
      packing_weight: this.state.itemConsiderPacking ? itemSectionData.packing_weight : null,
      tolerance_upto_1mts: this.state.itemConsiderPacking ? itemSectionData.tolerance_upto_1mts : null,
      tolerance_from_1to5mts: this.state.itemConsiderPacking ? itemSectionData.tolerance_from_1to5mts : null,
      tolerance_above_5mts: this.state.itemConsiderPacking ? itemSectionData.tolerance_above_5mts : null,
    };
    let sectionData = itemSectionData.sections;
    this.props
      .saveItemData(itemData)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          // this.handleFetchItemsData();
          if (sectionData?.length > 0) {
            sectionData[0]["item"] = response.data.id;
            let updatedSectionData = sectionData.find(
              (section) => section.item === response.data.id
            );
            if (
              updatedSectionData.section_name != "" &&
              updatedSectionData.section_weight != ""
            ) {
              this.props
                .saveItemSectionData(updatedSectionData)
                .then((response) => {
                  if (response.status === 201 || response.status === 200) {
                    this.handleOpenSnackbar(
                      `Item And Section Data Created Successfully`,
                      "success"
                    );
                    setTimeout(() => {
                      this.handleFetchSelectedItemRateTemplateTableData(
                        this?.state?.selectedItemId
                      );
                      // this.props.history.push(`/internalMastersManagement/itemsCategory`);
                      // this.props.history.push(`/internalMastersManagement/itemsCategory/${this?.state?.selectedItemId}`);
                      // this.setState({ itemTypeAdded: true })
                      this.handleCloseSnackbar()
                    }, 4000);
                    this.setState({ tabValue: 0 },
                      () => { this.handleTabValue(1) })
                    // setTimeout(() => { this.handleTabChange(1) }, 500)
                    // this.setState({ tabValue: 0 },
                    //   () => { this.setState({ tabValue: 1 }) })
                  } else {
                    console.log("else 1")
                    this.setState({
                      openSnackbar: true,
                      snackbarMessage: response?.data?.message
                        ? response?.data?.message
                        : response?.data?.detail
                          ? response?.data?.detail
                          : "Error occured while creating item's section data",
                      snackbarType: "error",
                    });
                  }
                })
                .catch((error) => {
                  console.log("catch 1")
                  this.handleOpenSnackbar(
                    this.handleSnackbarMessageBox(checkApiError(error.response.data)),
                    "error"
                  );
                  setTimeout(() => {
                    this.handleCloseSnackbar();
                  }, 4000);
                  // this.setState({
                  //   openSnackbar: true,
                  //   snackbarMessage: error?.data?.message
                  //     ? error?.data?.message
                  //     : error?.data?.detail
                  //       ? error?.data?.detail
                  //       : "Error occured while creating item's section data",
                  //   snackbarType: "error",
                  // });
                });
            }
          }
          this.handleFetchItemsData();
        } else {
          console.log("else 2")
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while creating item",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("catch 2")
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.message
        //     ? error?.data?.message
        //     : error?.data?.detail
        //       ? error?.data?.detail
        //       : "Error occured while creating item",
        //   snackbarType: "error",
        // });
      });
  };
  handleItemAndSectionDataUpdate = (itemSectionData) => {

    const tag = itemSectionData.tags.map(e => ({
      tag: e.id,
      tag_name: e.tag_name
    }))

    let updatedData = {
      item_code: itemSectionData.item_code,
      item_name: itemSectionData.item_name,
      item_size: itemSectionData.item_size,
      hsn_code: itemSectionData.hsn_code,
      items_nature: itemSectionData.items_nature,
      unit: itemSectionData.unit,
      rate_difference: itemSectionData.rate_difference,
      item_category: itemSectionData.item_category,
      //discount_amount: itemSectionData.discount_amount,
      is_active: itemSectionData.is_active,
      valid_from: itemSectionData.valid_from,
      packing_weight: this.state.itemConsiderPacking ? itemSectionData.packing_weight : null,
      tolerance_upto_1mts: this.state.itemConsiderPacking ? itemSectionData.tolerance_upto_1mts : null,
      tolerance_from_1to5mts: this.state.itemConsiderPacking ? itemSectionData.tolerance_from_1to5mts : null,
      tolerance_above_5mts: this.state.itemConsiderPacking ? itemSectionData.tolerance_above_5mts : null,
      tag,
    };
    let sectionData = itemSectionData.sections;
    this.props
      .updateItemData(updatedData, itemSectionData.id)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (true) {
            sectionData[0]["item"] = response.data.id;
            let updatedSectionData = sectionData.find(
              (section) => section.item === response.data.id
            );
            this.props
              .updateItemSectionData(updatedSectionData)
              .then((response) => {
                if (response.status === 200 || response.status === 201) {
                  this.handleOpenSnackbar(
                    `Item And Section Data Updated Successfully`,
                    "success"
                  );
                  setTimeout(() => {
                    this.handleFetchSelectedItemRateTemplateTableData(
                      this?.state?.selectedItemId
                    );
                    this.handleCloseSnackbar()
                  }, 4000);
                } else {
                  this.setState({
                    openSnackbar: true,
                    snackbarMessage: response?.data?.message
                      ? response?.data?.message
                      : response?.data?.detail
                        ? response?.data?.detail
                        : "Error occured while updating item's section data",
                    snackbarType: "error",
                  });
                }
              })
              .catch((error) => {
                this.handleOpenSnackbar(
                  this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                  "error"
                );
                setTimeout(() => {
                  this.handleCloseSnackbar();
                }, 4000);
                // this.setState({
                //   openSnackbar: true,
                //   snackbarMessage: error?.data?.message
                //     ? error?.data?.message
                //     : error?.data?.detail
                //       ? error?.data?.detail
                //       : "Error occured while updating item's section data",
                //   snackbarType: "error",
                // });
              });
          }
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message
              ? response?.data?.message
              : response?.data?.detail
                ? response?.data?.detail
                : "Error occured while updating item",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.message
        //     ? error?.data?.message
        //     : error?.data?.detail
        //       ? error?.data?.detail
        //       : "Error occured while updating item",
        //   snackbarType: "error",
        // });
      });
    setTimeout(() => {
      this.handleFetchItemsData();
    }, 4000);
  };

  handleRateLinkingOperation = (operation, index) => {
    this.setState({
      rateLinkingOperation: {
        operation: operation,
        index: index
      }
    })
  }

  handleSaveRateLinkingItem = (data, operation, id = null) => {
    let rateData = {};
    this.setState({ isLoadingRateLinking: true })
    if (operation === 'add') {
      rateData = {
        "item_category": data.item_category,
        "linked_item_category": data.linked_item_category,
        "rate_difference": Number(data.rate_difference),
        "is_active": data.is_active,
        "valid_from": data.valid_from,
        // "valid_upto": data.valid_upto
      }
      this.props
        .createLinkedItems(rateData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.handleOpenSnackbar(
              `Rate Linking Created Successfully`,
              "success"
            );
            setTimeout(() => {
              this.prepareRateLinkedItem(this?.state?.selectedItemId)
              this.handleRateLinkingOperation('view', -1)
              this.setState({ isLoadingRateLinking: false })
              this.handleCloseSnackbar()
            }, 4000);
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Error occured while creating rate linking",
              snackbarType: "error",
            });
            this.setState({ isLoadingRateLinking: false })
          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.setState({ isLoadingRateLinking: false })
            this.handleCloseSnackbar();
          }, 4000);
        })
    }
    else if (operation === 'update') {
      rateData = {
        "item_category": data.item_category,
        "linked_item_category": data.linked_item_category,
        "rate_difference": Number(data.rate_difference),
        "is_active": data.is_active,
        "valid_from": data.valid_from,
        // "valid_upto": data.valid_upto
      }
      this.props
        .updateLinkedItems(rateData, id)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.handleOpenSnackbar(
              `Rate Linking Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              this.prepareRateLinkedItem(this?.state?.selectedItemId)
              this.handleRateLinkingOperation('view', -1)
              this.setState({ isLoadingRateLinking: false })
              this.handleCloseSnackbar()
            }, 4000);
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Error occured while updating rate linking",
              snackbarType: "error",
            });
            this.setState({ isLoadingRateLinking: false })
          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.setState({ isLoadingRateLinking: false })
            this.handleCloseSnackbar();
          }, 4000);
        })
    }
    else if (operation === 'delete') {
      this.props
        .deleteLinkedItems(id)
        .then((response) => {
          if (response.status === 204) {
            this.handleOpenSnackbar(
              `Rate Linking Deleted Successfully`,
              "success"
            );
            setTimeout(() => {
              this.prepareRateLinkedItem(this?.state?.selectedItemId)
              this.handleRateLinkingOperation('view', -1)
              this.setState({ isLoadingRateLinking: false })
              this.handleCloseSnackbar()
            }, 4000);
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Error occured while deleting rate linking",
              snackbarType: "error",
            });
            this.setState({ isLoadingRateLinking: false })
          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.setState({ isLoadingRateLinking: false })
            this.handleCloseSnackbar();
          }, 4000);
        })
    }
    else {

    }
  }

  handleDeletePaymentTerm = (orderData) => {
    this.props
      .deletePaymentTerm(orderData)
      .then((response) => {
        if (response.status === 204) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Payment Term deleted successfully",
            snackbarType: "success",
          });
        }

        else {
          this.setState({
            openSnackbar: true,
            isLoading: false,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while deleting payment term",
            snackbarType: "error",
          });
        }
        setTimeout(() => {
          this.fetchItemCategoryPaymentTerm();
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 5000);

      });
  };

  handleDeleteCityDiscount = (orderData) => {
    this.props
      .deleteCityDiscount(orderData)
      .then((response) => {
        if (response.status === 204) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "City discount deleted successfully",
            snackbarType: "success",
          });
        }

        else {
          this.setState({
            openSnackbar: true,
            isLoading: false,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while deleting city discount",
            snackbarType: "error",
          });
        }
        setTimeout(() => {
          this.handleFetchItemCategoryCityData();
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 5000);

      });
  };


  render() {
    const {
      itemOperation,
      tabValue,
      itemCategoryName,
      openSnackbar,
      snackbarMessage,
      snackbarType,
    } = this.state;
    const { classes } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <Bread
              data={[
                {
                  title: "Master Management",
                  onClick: () =>
                    this.props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
                },
                {
                  title: "Item Categories",
                  onClick: () =>
                    this.props.history.push(
                      INTERNALROUTES.LISTITEMCATEGORIESMASTER
                    ),
                },
                {
                  title:
                    itemOperation === "add"
                      ? "Create New Category"
                      : itemCategoryName,
                  onClick: "",
                },
              ]}
            />
            <div className={classes.displayData}>
              <div className={classes.headerRow}>
                <>
                  {itemOperation === "view" ? (
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          borderBottom: `2px solid ${primaryColor}`,
                          colur: grey,
                        },
                      }}
                      value={tabValue}
                      onChange={this.handleTabChange}
                      className={classes.tabsStyles}
                      variant="scrollable"
                    >
                      <Tab
                        label={"RATE TEMPLATE"}
                        className={
                          tabValue === 0
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        label={"ITEM TYPES"}
                        className={
                          tabValue === 1
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        label={"PAYMENT TERM"}
                        className={
                          tabValue === 2
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        label={"CITY DISCOUNT"}
                        className={
                          tabValue === 3
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                    </Tabs>
                  ) : null}
                </>
                <div className={classes.displayFlex}>
                  {itemOperation === "view" && tabValue === 0 ? (
                    <ToolTipComponent
                      title="You have no edit permission on this widget"
                      condition={!hasUpdateAccess(this.props.user, 'item_categories')}
                    >
                      <Button
                        size="medium"
                        className={classes.primaryBackground}
                        variant="contained"
                        startIcon={<EditOutlinedIcon />}
                        disabled={this?.state?.fetchItemCategoryDataLoader || !hasUpdateAccess(this.props.user, 'item_categories')}
                        onClick={
                          () =>
                            this.props.history.push(
                              `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/${this?.state?.selectedItemId}`
                            )
                          // this.handleEditItemOperation()
                        }
                      >
                        EDIT
                      </Button>
                    </ToolTipComponent>
                  ) : null}
                  {/* {itemOperation === "view" ? (
                    <Button
                      size="medium"
                      className={
                        itemOperation === "view" ? null : classes.cancelButton
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}`
                        );
                      }}
                    >
                      CANCEL
                    </Button>
                  ) : null} */}
                </div>
              </div>
              {tabValue === 0 ? (
                // itemOperation === "view" ? (
                this?.state?.fetchItemCategoryDataLoader ? (
                  <div className={classes.loaderDiv}>
                    <SpinLoader />
                  </div>
                ) : (
                  <DisplayItemCategoryRateTemplateTab
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                    handleSaveItemCategory={this.handleSaveItemCategory}
                    handleAddDailyRateTemplate={this.handleAddDailyRateTemplate}
                    handleRateTemplateTableStateChange={
                      this.handleRateTemplateTableStateChange
                    }
                    handleUpdateRateTemplateTableData={
                      this.handleUpdateRateTemplateTableData
                    }
                    handleFetchSelectedItemRateTemplateTableData={
                      this.handleFetchSelectedItemRateTemplateTableData
                    }
                    handleCancelButtonClick={this.handleCancelButtonClick}
                    handleAddRateTemplateTableData={
                      this.handleAddRateTemplateTableData
                    }
                    handleBaseStateChange={this.handleBaseStateChange}
                    handleSetRateLinkingData={this.handleSetRateLinkingData}
                    handleDeleteRateLinkingData={this.handleDeleteRateLinkingData}
                    fetchAllItemCatgories={this.props.fetchAllItemCatgories}
                    rateLinkingData={this.state?.rateLinkingData}
                    selectedItemId={this.state?.selectedItemId}
                    rateLinkingOperation={this.state?.rateLinkingOperation}
                    isLoadingRateLinking={this.state?.isLoadingRateLinking}
                    handleSaveRateLinkingItem={this.handleSaveRateLinkingItem}
                    handleRateLinkingOperation={this.handleRateLinkingOperation}
                  />
                )
              ) : // ) // ) : (
                //   <ManageItemCategoryRateTemplateTab
                //     {...this.state}
                //     {...this.props}
                //     handleChange={this.handleChange}
                //     handleSaveItemCategory={this.handleSaveItemCategory}
                //     handleAddDailyRateTemplate={this.handleAddDailyRateTemplate}
                //     handleRateTemplateTableStateChange={
                //       this.handleRateTemplateTableStateChange
                //     }
                //     handleCancelButtonClick={this.handleCancelButtonClick}
                //     handleAddRateTemplateTableData={
                //       this.handleAddRateTemplateTableData
                //     }
                //     handleBaseStateChange={this.handleBaseStateChange}
                //     handleSetRateLinkingData={this.handleSetRateLinkingData}
                //     saveUpdateButtonTitle={
                //       itemOperation === "add" ? "Save" : "Update"
                //     }
                //   />

                null}
              {tabValue === 1 &&
                (itemOperation === "view" &&
                  this?.state?.itemDataFetchLoader ? (
                  <div className={classes.loaderDiv}>
                    <SpinLoader />
                  </div>
                ) : (
                  <ManageItemCategoryItemTypesTab
                    {...this.state}
                    {...this.props}
                    handleChange={this.handleChange}
                    addNewItemUnderCategoryData={
                      this.addNewItemUnderCategoryData
                    }
                    handleItemAndSectionDataSave={
                      this.handleItemAndSectionDataSave
                    }
                    handleItemAndSectionDataUpdate={
                      this.handleItemAndSectionDataUpdate
                    }
                    handleTabChange={this.handleTabChange}
                  />
                ))}
              {tabValue === 2 &&
                (itemOperation === "view" &&
                  this?.state?.itemCategoryPaymentTermDataFetchLoader ? (
                  <div className={classes.loaderDiv}>
                    <SpinLoader />
                  </div>
                ) : (
                  <ManageItemCategoryPaymentTermAndCityTab
                    {...this.state}
                    handleChange={this.handleChange}
                    handleCancelButton={this.handleCancelButton}
                    displayDataFor={"paymentTerm"}
                    handlePaymentTermOrCityTableSaveData={
                      this.handlePaymentTermOrCityTableSaveData
                    }
                    handlePaymentTermTableAutocompleteValueChange={
                      this.handlePaymentTermTableAutocompleteValueChange
                    }
                    handleUpdateDiscountAmount={this.handleUpdateDiscountAmount}
                    fetchItemCategoryPaymentTerm={this.fetchItemCategoryPaymentTerm}
                    handlePaymentOrCityStateValue={this.handlePaymentOrCityStateValue}
                    handleDeletePaymentTerm={this.handleDeletePaymentTerm}
                    user={this.props.user}
                  />
                ))}
              {tabValue === 3 &&
                (itemOperation === "view" &&
                  this?.state?.itemCategoryCityDataFetchLoader ? (
                  <div className={classes.loaderDiv}>
                    <SpinLoader />
                  </div>
                ) : (
                  <ManageItemCategoryCityTab
                    {...this.state}
                    handleChange={this.handleChange}
                    handleCancelButton={this.handleCancelButton}
                    displayDataFor={"city"}
                    handlePaymentTermOrCityTableSaveData={
                      this.handlePaymentTermOrCityTableSaveData
                    }
                    handleCityTableAutocompleteValueChange={
                      this.handleCityTableAutocompleteValueChange
                    }
                    handleUpdateDiscountAmount={this.handleUpdateDiscountAmount}
                    handleFetchItemCategoryCityData={this.handleFetchItemCategoryCityData}
                    handlePaymentOrCityStateValue={this.handlePaymentOrCityStateValue}
                    handleDeleteCityDiscount={this.handleDeleteCityDiscount}
                    user={this.props.user}
                  />
                ))}
            </div>
            <SnackbarComponent
              open={openSnackbar}
              handleClose={this.handleCloseSnackbar}
              snackbarMessage={snackbarMessage}
              type={snackbarType}
            />
          </div>
        </div>
      </Layout>
    );
  }
}
export default withRouter(
  withStyles(itemCategoryDataStyles)(DisplayItemCategoryData)
);
