import React, { Component } from "react";
import OrderCard from "../common/OrderCard";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor } from "../../../styles/colors";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import { withRouter } from "react-router";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  confirmMainBody: {
    width: "100%",
  },
  messageShowContainer: {
    width: "100%",
    // display: "flex",
    // justifyContent: "center",
  },
  messageShow: {
    // width: "90%",
    // display: "flex",
    // flexDirection: "column",
    // padding: "30px",
    fontSize: "1.1em",
    color: bottomNavigationFontColor,
  },
  backIconForWeb: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
class Confirm extends Component {
  render() {
    const { classes, isMobile } = this.props;

    return (
      <div className={classes.confirmMainBody}>
        {!isMobile ? (
          <div style={{ padding: "10px 0px 10px 4%" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/home");
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          </div>
        ) : null}
        <OrderCard
          title="Approval pending"
          details={this.props.placedOrderDetails}
          isMobile={isMobile}
        />
        <div className={classes.messageShowContainer} style={{}}>
          <div className={classes.messageShow} style={{ padding: !isMobile ? "20px 6% 10px 6%" : "20px 5% 10px 6%", }}>
            <span>The contract is pending for approval by REAL Group. The details will be available here when the contract is approved</span>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Confirm));
