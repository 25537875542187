import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import Cancel from "../Cancel";
import { withStyles } from "@material-ui/core/styles";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {};
class AddCanceledOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    if (this.props?.location?.state?.message)
      this.props.history.push("/home")
    else
      if (this.props.history.length > 2)
        this.props.history.goBack();
      else
        this.props.history.push("/dispatch")
  };
  render() {
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        <Cancel {...this.props} />
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(AddCanceledOrder);
