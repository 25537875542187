import React from "react";
export default class CommerceSVG extends React.Component {
  render() {
    return (
      <svg
        style={{ fill: "white", height: "20px", width: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="22.813"
        height="22.457"
        viewBox="0 0 22.813 22.457"
      >
        <path
          class="a"
          d="M.429,43.5A.428.428,0,0,0,0,43.929v7.714a.428.428,0,0,0,.429.429H8.143a.428.428,0,0,0,.429-.429V43.929a.428.428,0,0,0-.429-.429Zm3.429.857h.857v.85H3.857Zm3.857,6.857H.857V44.357H3v1.278a.428.428,0,0,0,.429.429H5.143a.428.428,0,0,0,.429-.429V44.357H7.714Z"
          transform="translate(0 -29.615)"
        />
        <path
          class="a"
          d="M49.857.5A3.861,3.861,0,0,0,46,4.357c0,3.094,3.426,6.189,3.572,6.32a.429.429,0,0,0,.571,0c.146-.13,3.572-3.225,3.572-6.32A3.861,3.861,0,0,0,49.857.5Zm0,9.264c-.8-.792-3-3.174-3-5.407a3,3,0,0,1,6,0C52.857,6.586,50.656,8.972,49.857,9.764Z"
          transform="translate(-30.901 -0.5)"
        />
        <path
          class="a"
          d="M20.442,35.356H10.914a.438.438,0,0,0,0,.857h9.528c1.623,0,2.943-1.634,2.943-3.644s-1.32-3.644-2.943-3.644H6.929a2.565,2.565,0,0,1-2.251-2.787,2.565,2.565,0,0,1,2.251-2.787.272.272,0,0,0,.082-.021.285.285,0,0,0,.09.023H19.224a.438.438,0,0,0,0-.857H7.1a.272.272,0,0,0-.082.021.285.285,0,0,0-.09-.023c-1.623,0-2.943,1.634-2.943,3.644s1.32,3.644,2.943,3.644H20.442a2.565,2.565,0,0,1,2.251,2.787A2.565,2.565,0,0,1,20.442,35.356Z"
          transform="translate(-2.278 -17.612)"
        />
        <path
          class="a"
          d="M52.708,5.5a1.714,1.714,0,1,0,1.714,1.714A1.716,1.716,0,0,0,52.708,5.5Zm0,2.571a.857.857,0,1,1,.857-.858A.858.858,0,0,1,52.708,8.071Z"
          transform="translate(-33.755 -3.357)"
        />
        <path
          class="a"
          d="M6,55.5H3.429a.429.429,0,0,0,0,.857H6A.429.429,0,1,0,6,55.5Z"
          transform="translate(-1.714 -36.471)"
        />
        <path
          class="a"
          d="M4.714,58.5H3.429a.429.429,0,0,0,0,.857H4.714a.429.429,0,0,0,0-.857Z"
          transform="translate(-1.714 -38.186)"
        />
      </svg>
    );
  }
}
