import React, { Component } from "react";
import "../../../styles/Register.css";
import { withStyles } from "@material-ui/core/styles";
import PanoramaFishEye from "@material-ui/icons/PanoramaFishEye";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { primaryColor, dailyRateScreenTextColor } from "../../../styles/colors";
import { pxToEm } from "../../../methods";

const styles = {
  mainBodyRegisterSelectCategory: {
    height: window.innerHeight - 15,
    display: "flex",
    flexDirection: "column",
  },
  registerFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};
class RegisterSelectCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainBodyRegisterSelectCategory}>
        <div className="register-select-category-header">
          <span className="register-select-category-header-text">
            Select the appropriate Category
          </span>
        </div>
        <div className={classes.registerFormContainer}>
          <div className="register-select-category-main">
            <div
              className="register-select-category"
              style={{
                border: this.state.categoryError ? "1px solid red" : null,
              }}
              onClick={(e) => {
                e.preventDefault();
                this.setState(
                  {
                    category: "BROKER",
                    broker: true,
                    distributer: false,
                    consumer: false,
                    dealer: false,
                  },
                  () => {
                    this.props.handleSelectCategory(this.state.category);
                  }
                );
              }}
            >
              <span className="register-radio-button-span" style={{ transform: window.innerWidth < 375 && 'scale(0.8)', marginTop: window.innerWidth < 375 && 5 }}>
                {this.state.broker ? (
                  <CheckCircle style={{ color: primaryColor }} />
                ) : (
                  <PanoramaFishEye />
                )}
              </span>
              <div className="register-select-category-child">
                <span
                  style={{ color: dailyRateScreenTextColor, fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.1em" }}
                >
                  I am a Broker / Distributor / Trader
                </span>
              </div>
            </div>
          </div>

          <div className="register-select-category-main">
            <div
              className="register-select-category"
              style={{
                border: this.state.categoryError ? "1px solid red" : null,
              }}
              onClick={(e) => {
                e.preventDefault();
                this.setState(
                  {
                    category: "DEALER",
                    broker: false,
                    distributer: false,
                    consumer: false,
                    dealer: true,
                  },
                  () => {
                    this.props.handleSelectCategory(this.state.category);
                  }
                );
              }}
            >
              <span className="register-radio-button-span" style={{ transform: window.innerWidth < 375 && 'scale(0.8)', marginTop: window.innerWidth < 375 && 5 }}>
                {this.state.dealer ? (
                  <CheckCircle style={{ color: primaryColor }} />
                ) : (
                  <PanoramaFishEye />
                )}
              </span>
              <div className="register-select-category-child">
                <span
                  style={{ color: dailyRateScreenTextColor, fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.1em" }}
                >
                  I am a Dealer / Consumer
                </span>
              </div>
            </div>
          </div>
          {/* <div className="register-select-category-main">
            <div
              className="register-select-category"
              style={{
                border: this.state.categoryError ? "1px solid red" : null,
              }}
              onClick={(e) => {
                e.preventDefault();
                this.setState(
                  {
                    category: "DISTRIBUTOR",
                    broker: false,
                    distributer: true,
                    consumer: false,
                    dealer: false,
                  },
                  () => {
                    this.props.handleSelectCategory(this.state.category);
                  }
                );
              }}
            >
              <span className="register-radio-button-span">
                {this.state.distributer ? (
                  <CheckCircle style={{ color: primaryColor }} />
                ) : (
                  <PanoramaFishEye />
                )}
              </span>
              <div className="register-select-category-child">
                <span
                  style={{ color: dailyRateScreenTextColor, fontSize: "1.1em" }}
                >
                  I am a Distributor
                </span>
              </div>
            </div>
          </div> */}
          {/* <div className="register-select-category-main">
            <div
              className="register-select-category"
              style={{
                border: this.state.categoryError ? "1px solid red" : null,
              }}
              onClick={(e) => {
                e.preventDefault();
                this.setState(
                  {
                    category: "DEALER",
                    dealer: true,
                    consumer: false,
                    broker: false,
                    distributer: false,
                  },
                  () => {
                    this.props.handleSelectCategory(this.state.category);
                  }
                );
              }}
            >
              <span className="register-radio-button-span">
                {this.state.dealer ? (
                  <CheckCircle style={{ color: primaryColor }} />
                ) : (
                  <PanoramaFishEye />
                )}
              </span>
              <div className="register-select-category-child">
                <span
                  style={{ color: dailyRateScreenTextColor, fontSize: "1.1em" }}
                >
                  I am a Dealer
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterSelectCategory);
