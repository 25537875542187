import React, { Component } from "react";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  secondaryGrey,
  grey,
  successButtonBackground,
  drawerTextColor,
  secondaryBlueText,
} from "../../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
import { checkBrokerage, hasUpdateAccess, pxToEm } from "../../../../methods";
import { IconButton, ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
  Button,
  Tooltip,
  //CircularProgress,
  // Tooltip,
  // Checkbox,
  TablePagination,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import InternalApiRoutes from "../../../../constants/internal/InternalApiRoutes";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SnackbarComponent from "../../common/SnackbarComponent";
import ToolTipComponent from "../../common/ToolTipComponent";

const styles = {
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginTop: "12px",
  },
  tableBody: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(13),
  },
  formControl: {
    minWidth: 200,
    maxWidth: 500,
    fontFamily: 'Poppins',
  },
  saveCancelButton: {
    display: "flex",
    placeSelf: "strech",
    marginTop: "12px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    fontFamily: 'Poppins',
  },
  customerTypeTitle: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
  },
  alignStateText: {
    display: "flex",
    placeContent: "flex-start",
    alignItems: "center",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
  },
  captionDetails: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    paddingBottom: 16,
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
};
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "10px 5px 10px 5px",
      },
    },
  },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      fontFamily: 'Poppins',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

class ManageCustomerBrokerage extends Component {
  state = {
    page: 0,
    setPage: 0,
    rowsPerPage: 5,
    setRowsPerPage: 5,
    selectedItemCategoryID: false,
    brokerage_amount: 0.0,
    snackbarMessage: null,
    openSnackbar: false,
    snackbarType: "",
    list: [],
    addBrokerage: false,
    brokerageAddedList: [],
    itemCategoryList: [],
    itemCategoryAPI: [],
    showData: [],
    data: {},
    addedCategories: []
  };

  static getDerivedStateFromProps(props, state) {
    return {
      itemCategoryList: [...props.itemCategory],
      brokerageAddedList: [...props.itemCategory],
      itemCategoryAPI: [...props.customerItemCategory],
    };
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  async handleCreateBrokerageItemCatWise(data) {
    const { POST_BROKERAGE_ITEMCAT: url } = InternalApiRoutes;
    return new Promise(async (resolve, reject) => {
      await axios
        .post(url, data)
        .then((response) => {
          resolve();
          // console.log(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }

  async fetchItemCategoryCustomer(customerID) {
    await axios
      .get(`${InternalApiRoutes.BROKERAGE_ITEM_GET}?customer-id=${customerID}`)
      .then((res) => {
        this.setState({ showData: res.data });
        // let response = res.data
        // if(response.length > 0){
        //   let temp
        // }
        let result = res.data.map(a => {
          return a.item_category
        })

        this.setState({
          addedCategories: result
        })
        // console.log(result)


      })
      .catch((rej) => {
        console.log(rej);
      });
  }

  async deleteBrokerage(id) {
    let param1 = this.props.selectedCustomerId;
    await axios
      .delete(
        `${InternalApiRoutes.GET_BROKERAGE_ITEM_CATEGORIES_LIST}${id}/`
      )
      .then((res) => {
        console.log(res);
        this.fetchItemCategoryCustomer(param1);
        this.setState({
          openSnackbar: true,
          snackbarMessage: "Brokerage removed successfully",
          snackbarType: "success",
          removeAdditionalContactLoader: false,
        });
      })
      .catch((rej) => {
        console.log(rej);
      });
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  componentDidMount() {
    let param1 = this.props.selectedCustomerId;
    // console.log(param1)
    this.fetchItemCategoryCustomer(param1);
    // console.log("Added")
  }


  render() {
    const {
      page,
      rowsPerPage,
      list,
      addBrokerage,
      brokerageAddedList,
      itemCategoryList,
      getCustomerItemCategory,
      itemCategoryAPI,
      openSnackbar,
      snackbarType,
      snackbarMessage,
    } = this.state;
    const { classes } = this.props;
    let param1 = this.props.selectedCustomerId
    async function process() { }
    // console.log(this.state.showData)
    // console.log(this.state.brokerage_amount)
    // console.log(this.props.customerBrokerageAmountError)
    return (
      <div style={{ marginBottom: "12px" }}>
        <Typography className={classes.customerTypeTitle} component={"span"}>
          <div className={classes.alignStateText}>
            <Typography className={classes.titleLabelStyle}>
              BROKERAGE MANAGEMENT
            </Typography>
          </div>
        </Typography>
        <Typography className={classes.captionDetails} component={"span"}>
          Item category wise brokerage amount management
        </Typography>
        <ThemeProvider theme={theme}>
          <Paper>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell align="left" className={classes.tableHead}>
                      Item Category
                    </TableCell>
                    <TableCell align="left" className={classes.tableHead}>
                      Brokerage Amount
                    </TableCell>
                    <TableCell align="left" className={classes.tableHead}>
                      {/* Brokerage Amount */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.showData && this.state.showData.length > 0
                    ? this.state.showData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={classes.tableBody}
                          >
                            {
                              // row.brokerageAddedList
                              //   ?
                              row.item_category ? row.item_category : null
                              // : null
                            }
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={classes.tableBody}
                          >
                            {/* {props.props.removeAllowedPaymentMethodLoader ===
                              index ? (
                                <CircularProgress size={15} />
                              ) : (
                                <Tooltip
                                  title={`Click to remove payment method`}
                                  placement="left"
                                  arrow
                                >
                                  <CloseOutlinedIcon
                                    className={classes.removeIconDimensions}
                                  />
                                </Tooltip>
                              )} */}
                            {row.brokerage_amount === undefined
                              ? "No Brokerage Amount"
                              : parseFloat(row.brokerage_amount).toFixed(2)}
                            {/* {console.log(row.brokerageAmount)} */}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableBody}
                          >
                            <ToolTipComponent
                              title="You have no edit permission on this widget"
                              condition={!hasUpdateAccess(this.props.user, "customerManagement")}
                              title2={`Click to remove brokerage`}
                              condition2={hasUpdateAccess(this.props.user, "customerManagement")}
                            >
                              <IconButton
                                className={classes.removeIconDimensions}
                                onClick={() => {
                                  this.deleteBrokerage(row.id);
                                }}
                                disabled={
                                  hasUpdateAccess(this.props.user, "customerManagement")
                                    ? false
                                    : true
                                }
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </ToolTipComponent>
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                  {addBrokerage ? (
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={classes.tableBody}
                      >
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.formControl}
                        >
                          <InputLabel id="paymentMethod">
                            Item Category
                          </InputLabel>
                          <Select
                            labelId="paymentMethod"
                            onChange={(evt) => {
                              evt.preventDefault();
                              this.setState({
                                selectedItemCategoryID: evt.target.value,
                              });
                            }}
                            label="Payment Method"
                            MenuProps={MenuProps}
                          //value={props.props.customerSelectedPaymentMethodId}
                          >
                            <MenuItem value="none" disabled={true}>
                              <em>None</em>
                            </MenuItem>
                            {itemCategoryList && itemCategoryList.length > 0
                              ? itemCategoryList.map((payment) => (
                                <MenuItem value={payment.id}
                                  style={{
                                    display: this.state.addedCategories.includes(payment.category_name) ? "none" : "flex",
                                    fontFamily: 'Poppins',
                                  }}
                                >
                                  {payment.category_name}
                                </MenuItem>
                              ))
                              : null}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="middle" className={classes.tableBody}>
                        <TextField
                          // type="number"
                          onKeyPress={
                            (event) => {
                              if (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46) {

                              } else {
                                event.preventDefault()
                              }
                            }
                          }
                          size="small"
                          id="outlined-uncontrolled"
                          label="Brokerage amount"
                          style={{ fontFamily: 'Poppins', }}
                          InputProps={{
                            style: {
                              fontFamily: 'Poppins',
                            }
                          }}
                          defaultValue={parseFloat(this.state.brokerage_amount).toFixed(2)}
                          // className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ brokerage_amount: e.target.value });
                            this.props.handleChange(e, "CustomerBrokerageAmount")
                          }}
                          // error={this.state?.brokerage_amount?.length === 0 ? true : false}
                          error={!checkBrokerage(this.state?.brokerage_amount) ? true : false}
                          helperText={
                            !checkBrokerage(this.state?.brokerage_amount) ? "Enter brokerage amount b/w 0-9999" : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            {itemCategoryAPI.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={itemCategoryAPI.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            ) : (
              ""
            )}
          </Paper>
        </ThemeProvider>
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
        />
        {!addBrokerage ? (
          <ToolTipComponent
            title="You have no edit permission on this widget"
            condition={!hasUpdateAccess(this.props.user, "customerManagement")}
          >
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddOutlinedIcon />}
              className={classes.addNewButton}
              onClick={() => {
                this.setState({
                  addBrokerage: true,
                });
                this.props.handleChange("customerBrokerageAmountError")
              }}
              disabled={
                hasUpdateAccess(this.props.user, "customerManagement")
                  ? false
                  : true
              }
            >
              ADD NEW
            </Button>
          </ToolTipComponent>
        ) : (
          <div className={classes.saveCancelButton}>
            <Button
              size="medium"
              disabled={!this.state.selectedItemCategoryID || !checkBrokerage(this.state?.brokerage_amount)}
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={async () => {
                console.log("Selected ID: ", this.state.selectedItemCategoryID);

                const amount = this.state.brokerage_amount;
                const newItem = itemCategoryList.find(
                  (e) => e.id === this.state.selectedItemCategoryID
                );
                newItem.brokerage_amount = amount;
                const index = itemCategoryList.findIndex(
                  (e) => e.id === this.state.selectedItemCategoryID
                );
                this.setState({
                  itemCategoryList,
                });
                if (index > -1) {
                  console.log("ID exists in the array: ", itemCategoryList);
                  const itemCategories = itemCategoryList.filter(
                    (e) => e.id !== this.state.selectedItemCategoryID
                  );
                  console.log(
                    "With brokerage amount: ",
                    this.state.brokerage_amount
                  );
                  console.log("New array after removal: ", itemCategories);
                  const FinalData = itemCategoryList.find(
                    (e) => e.id === this.state.selectedItemCategoryID
                  );
                  console.log(FinalData);
                  this.setState(
                    {
                      itemCategoryList: itemCategories.push(newItem),
                    },
                    () =>
                      this.setState({
                        selectedItemCategoryID: false,
                        addBrokerage: false,
                        brokerage_amount: 0.0,
                      })
                  );

                  this.setState({
                    data: {
                      customer: param1,
                      item_category: FinalData.id,
                      brokerage_amount: parseFloat(FinalData.brokerage_amount),
                      is_active: true,
                    },
                  });
                  this.setState({
                    snackbarMessage: "Brokerage added successfully",
                    openSnackbar: true,
                    snackbarType: "success",
                  })

                  setTimeout(async () => {
                    await this.handleCreateBrokerageItemCatWise(
                      this.state.data
                    );
                  }, 200);

                  setTimeout(async () => {
                    await this.fetchItemCategoryCustomer(param1);
                  }, 1000);
                } else {
                  alert("Something went wrong.");
                }
              }}
            >
              Save
            </Button>

            <Button
              size="medium"
              onClick={() => this.setState({ addBrokerage: false })}
            // onClick={() => handleCustomerAllowedPaymentMethodOperation("view")}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(ManageCustomerBrokerage);
