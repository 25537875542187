import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import DisplayUnitData from "../DisplayUnitData";

class UnitMasterWeb extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <DisplayUnitData {...this.props} />
      </Layout>
    );
  }
}
export default UnitMasterWeb;
