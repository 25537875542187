import { Button, Paper } from "@material-ui/core";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import ToolTipComponent from "../../common/ToolTipComponent";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { drawerTextColor, primaryColor } from "../../../../constants/internal/colors";
import Table from "../../common/Table";

const styles = {
  headerData: (isMobile) => ({
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: isMobile ? pxToEm(15) : "18px"
  }),
  navigationBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: 'wrap'
  },
  displayData: {
    marginTop: 10,
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
    marginTop: "2px 20px 20px 20px",
  },
  filterSection: (isMobile) => ({
    display: "flex",
    alignItems: isMobile ? 'flex-start' : "center",
    flex: "1",
    flexDirection: isMobile && 'column',
    marginBottom: 20
  }),
  addButton: (isMobile) => ({
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
    marginBottom: isMobile && 10
  }),
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: "0.9em",
  }
}

const SalesTargetScheme = (props) => {

  const handlePageTitleRoute = (displaySection, addTextTitle, fieldName) => {
    if (fieldName === 'title') {
      return displaySection === 'distributor'
        ? `Distributor ${addTextTitle}`
        : displaySection === 'dealer'
          ? `Dealer ${addTextTitle}`
          : displaySection === 'salesPerson' && `Sales Person ${addTextTitle !== '' ? 'Target' : addTextTitle}`
    }
    else if (fieldName === 'route') {
      return displaySection === 'distributor'
        ? `${INTERNALROUTES.SALESTARGET_DISTRIBUTOR_SCHEME_CREATE}`
        : displaySection === 'dealer'
          ? `${INTERNALROUTES.SALESTARGET_DEALER_SCHEME_CREATE}`
          : displaySection === 'salesPerson' && `${INTERNALROUTES.SALESTARGET_SALESPERSON_TARGET_CREATE}`
    }
  }

  const tableHeader = [
    {
      minWidth: 220,
      header: "Scheme Name",
      key: "scheme_name",
      // flex: 1,
    },
    {
      minWidth: 220,
      header: "Financial Year",
      key: "financial_year",
      flex: 1,
    },
    {
      minWidth: 180,
      header: "Item Category",
      key: "item_category",
      flex: 1,
    },
    {
      minWidth: 180,
      header: `# Of ${handlePageTitleRoute(props.displaySection, "", 'title')}`,
      key: `no_of_${handlePageTitleRoute(props.displaySection, "", 'title')}`,
      flex: 1,
    },
    {
      minWidth: 220,
      header: "Status",
      key: "status",
      // renderCell: (params) => <EwayState {...params} />,
      flex: 1,
    },
    {
      minWidth: 150,
      header: "Action",
      key: 'action',
      sortable: false,
      disableClickEventBubbling: true,
      // renderCell: (params) => <CountButton {...params} handleOnClick={handleOnClick} user={props.user} />,
      flex: 1,
    },
  ];


  const {
    isMobile,
    displaySection,
  } = props;
  return (
    <Layout {...props}>
      <div style={{ width: isMobile ? '98%' : "100%" }}>
        <div style={{ width: "100%" }}>
          <div style={styles.navigationBar}>
            <Bread
              data={[
                {
                  title: `Sales Target`,
                  onClick: () => props.history.push(`${INTERNALROUTES.SALESTARGET}`),
                  style: { fontSize: isMobile && pxToEm(15), },
                },
                {
                  title: handlePageTitleRoute(displaySection, 'Scheme', 'title'),
                  style: { fontSize: isMobile && pxToEm(15), },
                }
              ]}
            />
          </div>
          <div style={styles.displayData}>
            <Paper style={styles.paperStyle}>
              <div style={styles.filterSection(isMobile)}>
                <div style={styles.addButton(isMobile)}>
                  <ToolTipComponent
                  // title="You have no create permission on this widget"
                  // condition={!hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                  >
                    <Button
                      style={styles.primaryBackground}
                      variant="contained"
                      startIcon={<AddOutlinedIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push(
                          handlePageTitleRoute(displaySection, '', 'route')
                        );
                      }}
                    // disabled={hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ? false : true}
                    >
                      CREATE NEW SCHEME
                    </Button>
                  </ToolTipComponent>
                </div>
              </div>
              <Paper>
                <div>
                  <Table
                    data={{ data: [] }}
                    headers={tableHeader}
                    value={'sales_target'}
                    tableHeight={"calc(100vh - 250px)"}
                  />
                </div>
              </Paper>
            </Paper>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SalesTargetScheme;
