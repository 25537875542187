import React, { Component } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from "@material-ui/core/Divider";
import Lock from "@material-ui/icons/Lock";
import moment from "moment"
import { withStyles } from "@material-ui/core/styles";
import {
    primaryColor,
    bottomNavigationFontColor,
    dailyRateScreenTextColor,
    lightGreyColor,
} from "../../../styles/colors";

const styles = {
    quantityShowContainer: {
        display: "flex",
        justifyContent: "center",
    },
    quantityShow: {
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
    },
    quantity: {
        width: "44%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    quantityText: {
        color: dailyRateScreenTextColor,
        fontSize: "1.25em",
    },
    formControlContainer: {
        width: "100%",
        marginTop: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    orderNameMenuContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "10px 0px",
        fontSize: "0.8em",
        color: lightGreyColor,
    },
    orderIdShowContainer: {
        alignItems: "flex-start",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        textOverflow: "ellipsis",
        marginLeft: "5px"
    },
    verticalLineStyle: {
        height: "2.3vh",
        width: "1px",
        background: bottomNavigationFontColor,
        margin: "0px 6px",
    },
    orderQuantityShowContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    textFieldStyle: {
        width: "100%",
    },
    formControlStyle: {
        margin: "20px 0px 6px 0px",
        width: "100%",
    },
    textFieldQuantity: {
        width: "35%",
        margin: "0px",
    },
    lockIconContainer: {
        width: "10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
};

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null

}

export class CreateDispatchOrderSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        // const { capacityError,
        // } = this.state;
        const {
            classes,
            isMobile,
            orders,
            items,
            setItemQuantity,
            setItem,
            setOrderId,
            openBuyerListPopper,
            truckCapacity,
            selectedOrder,
        } = this.props;
        return (
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={10}
                    label="Age"
                // onChange={handleChange}
                >
                    {
                        orders ?
                            orders.map((data) => (
                                <MenuItem
                                    key={data.order_number}
                                    component="div"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (data.is_fifo_allowed || data.open_order_for_LA) {
                                            // this.props.setOrderIdAndAmount(data.order_number);
                                            this.props.handleOrderSelect(data)
                                            // this.props.fetchPlantsList(data)
                                            this.props.handleClosePopper();
                                        }
                                    }}
                                >
                                    <div style={{ width: "100%" }}>
                                        <div className={classes.orderNameMenuContainer}>
                                            <div className={classes.lockIconContainer}>
                                                {(!data.is_fifo_allowed && !data.open_order_for_LA) ? (
                                                    <Lock style={{ marginRight: "5px" }} />
                                                ) : null}
                                            </div>

                                            <div style={{ width: "55%" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div
                                                        className={classes.orderIdShowContainer}
                                                    >
                                                        <span>Contract Id : {data.order_number}</span>
                                                        {/* <span
                                                                                        className={classes.verticalLineStyle}
                                                                                    /> */}
                                                        <span>Rate : ₹{parseFloat(data.rate).toFixed(2)}</span>
                                                        <span>{moment(data.created_at).format("DD-MMM-yyyy")}</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ width: "35%" }}>
                                                <div
                                                    className={
                                                        classes.orderQuantityShowContainer
                                                    }
                                                >
                                                    <span>{data.pending_qty}</span>
                                                    <span style={{ fontSize: "0.7em" }}>
                                                        Balance
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider variant="middle" />
                                    </div>
                                </MenuItem>
                            )) : null
                    }
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>
        )
    }
}

export default CreateDispatchOrderSelect