import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  createMuiTheme,
  Switch,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  secondaryBlueText,
  drawerTextColor,
  grey,
  successButtonBackground,
  primaryColor,
  cancelButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";

import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  checkApiError,
  checkAlphabet,
  checkEmailAddress,
  checkMobileNumber,
  pxToEm,
  isConfirmPasswordValid
} from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SaveButton from "../../common/SaveButton";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";

const manageInternalUsersStyles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "30ch",
  },
  titleWithBlueFont: {
    fontSize: pxToEm(18),
    color: secondaryBlueText,
  },
  displayData: {
    marginTop: 30,
  },
  displayUserData: {
    marginTop: 30,
    width: "50%",
  },
  actionButtonsAlignment: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,

    fontSize: pxToEm(15),
  },
  labelStyle: {
    color: grey,
    fontSize: pxToEm(13),
  },
  lableInputAlignment: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 20px 0px",
    alignItems: "center",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  resetColor: {
    fontSize: pxToEm(14),
    color: secondaryBlueText,
    textDecoration: 'underline',
    margin: "10px 0 30px 0",
    cursor: 'pointer'
  },
  dropdownText: {
    '& .MuiTypography-root': {
      padding: '0',
      fontFamily: 'Poppins',
      // fontSize: pxToEm(12),
      // lineHeight: 1
    },
  },
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

class ManageInternalUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      is_active: true,
      is_sales_person: false,
      // uid: "",
      employee_id: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      newPassword: "",
      newConfirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      personal_details_id: "",
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorPhone: false,
      errorDOB: false,
      errorPassword: false,
      errorConfirmPassword: false,
      uidLengthError: false,
      open: false,
      resetClicked: false,
      openSnackbar: false,
      snackbarMessage: ``,
      snackbarType: "",
      isLoading: false,
      isLoadingDelete: false,
      dataMapped: false,
      itemCategorySelected: [],
      itemCategorySelectedId: [],
      itemCategoryDropdownList: [],
    };
  }

  componentDidMount() {
    const internalUsersOperation = this.props.location.pathname.includes("view")
      ? "view"
      : "new";
    this.handleItemCategoriesList()
    if (internalUsersOperation === "view") {
      const id =
        this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
        ];
      this.props.getInternalUserById(id);
    }
    this.setState({
      internalUsersOperation,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.initializeSingleUser(nextProps);
  }

  initializeSingleUser = (props) => {
    if (
      this.state.internalUsersOperation === "view" &&
      props.errorUser === "" &&
      !props.loadingUser
    ) {
      this.setState({
        personal_details_id: props.singleUser.personal_details_id,
        date_of_birth: props.singleUser.date_of_birth,
        employee_id: props.singleUser.employee_id,
        email: props.singleUser.email,
        first_name: props.singleUser.first_name,
        id: props.singleUser.id,
        last_name: props.singleUser.last_name,
        phone: props.singleUser.phone,
        is_sales_person: props.singleUser?.is_sales_person,
        is_active: props.singleUser?.is_active,
      }, () => this.setState({ dataMapped: true }));
    }
  };

  handleSaveUser = () => {
    this.setState({ isLoading: true })
    const data = {
      personal_details_id: this.state.personal_details_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      employee_id: this.state.employee_id,
      email: this.state.email === "" ? null : this.state.email,
      phone: this.state.phone === "" ? null : this.state.phone,
      password: this.state.password,
      date_of_birth: this.state.date_of_birth,
      is_sales_person: this.state.is_sales_person,
      person_item_categories: this.state.is_sales_person ? this.state.itemCategorySelectedId : null,
      is_active: this.state.is_active,
    };
    const data_1 = {
      // personal_details_id: this.state.personal_details_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      date_of_birth: this.state.date_of_birth,
      is_sales_person: this.state.is_sales_person,
      person_item_categories: this.state.is_sales_person ? this.state.itemCategorySelectedId : null,
      credentials: {
        email: this.state.email === "" ? null : this.state.email,
        phone: this.state.phone === "" ? null : this.state.phone,
        employee_id: this.state.employee_id,
        is_active: this.state.is_active,
      },
    };
    const data_2 = {
      // personal_details_id: this.state.personal_details_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      date_of_birth: this.state.date_of_birth,
      is_sales_person: this.state.is_sales_person,
      person_item_categories: this.state.is_sales_person ? this.state.itemCategorySelectedId : null,
      credentials: {
        email: this.state.email === "" ? null : this.state.email,
        phone: this.state.phone === "" ? null : this.state.phone,
        employee_id: this.state.employee_id,
        password: this.state.newPassword,
        is_active: this.state.is_active,
      },
    };
    // const updatedData = this.clean(data_1)
    const updatedData = this?.state?.newPassword ? Object.entries(data_2).reduce((a, [k, v]) => (v !== null ? (a[k] = v, a) : a), {}) :
      Object.entries(data_1).reduce((a, [k, v]) => (v !== null ? (a[k] = v, a) : a), {})

    if (this.state.internalUsersOperation === "edit") {
      if (!this.isAllInputValid("edit")) {
        const { personal_details_id } = this.state;
        this.props
          .putInternalUser({ ...updatedData, personal_details_id })
          .then((response) => {
            if (response.status === 200) {
              this.handleOpenSnackbar(
                `Internal User Detail Updated Successfully`,
                "success"
              );
              setTimeout(() => {
                this.setState({ isLoading: false })
                this.goBack();
                this.handleCloseSnackbar()
              }, 4000);
            } else {
              this.setState({ isLoading: false })
              this.handleOpenSnackbar(
                `Error occured while updating internal user please try again`,
                "error"
              );
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false })
            if (Object.keys(error.response.data.credentials)[0] == 'employee_id') {
              this.handleOpenSnackbar(
                (`"UID" : ` + "Credential with this Employee UID already exists."),
                "error"
              );
              setTimeout(() => {
                this.handleCloseSnackbar();
              }, 4000);
            }
            else {
              this.handleOpenSnackbar(
                this.handleSnackbarMessageBox(checkApiError(error.response.data.credentials)),
                "error"
              );
              setTimeout(() => {
                this.handleCloseSnackbar()
              }, 6000);
            }
          });
        //   console.log(response);
        //   this.goBack();
        // })
        // .catch((error) => {
        //   console.error(error);
        // });
      } else console.error(`Error(Update): while validating all the fields.`);
    } else {
      if (!this.isAllInputValid("new")) {
        this.props
          .postInternalUser(this.clean(data))
          .then((response) => {
            if (response.status === 200) {
              this.handleOpenSnackbar(
                `Internal User Created Successfully`,
                "success"
              );
              setTimeout(() => {
                this.setState({ isLoading: false })
                this.goBack();
                this.handleCloseSnackbar()
              }, 4000);
            } else {
              this.setState({ isLoading: false })
              this.handleOpenSnackbar(
                `Error occured while creating internal user please try again`,
                "error"
              );
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false })
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 4000);
          });
        //   console.log(response.data);
        //   this.goBack();
        // })
        // .catch((error) => {
        //   console.error(error);
        // });
      } else console.error(`Error(Create): while validating all the fields.`);
    }
  };

  clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      } else if (typeof obj[propName] === "object") {
        // Recurse here if the property is another object.
        this.clean(obj[propName])
      }
    }
    return obj;
  }

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  isAllInputValid = (mode) => {
    const {
      errorFirstName,
      errorLastName,
      errorEmail,
      errorPhone,
      errorDOB,
      errorPassword,
      errorConfirmPassword,
      first_name,
      last_name,
      email,
      phone,
      password,
      newPassword,
      newConfirmPassword,
      date_of_birth,
      is_active,
      is_sales_person,
      confirmPassword,
    } = this.state;
    if (mode === "edit") {
      return (
        !first_name ||
        !last_name ||
        // || !email ||
        //  !phone ||
        !date_of_birth
      );
    } else {
      return (
        errorFirstName ||
        errorLastName ||
        // errorEmail ||
        //  errorPhone ||
        errorDOB ||
        errorPassword ||
        errorConfirmPassword ||
        !first_name ||
        !last_name ||
        // !email ||
        // !phone ||
        !password ||
        // !newPassword ||
        // !newConfirmPassword ||
        !date_of_birth ||
        !confirmPassword
      );
    }
  };

  handleEditUser = () => this.setState({ internalUsersOperation: "edit" });
  handleClickShowPassword = (name) => this.setState(prevState => ({
    [name]: !prevState[name]
  }))
  goBack = (e) => this.props.history.goBack();


  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleResetClick = (event) => {
    this.setState({
      resetClicked: !this.state.resetClicked,
      errorPassword: false,
      errorConfirmPassword: false,
      newPassword: "",
      newConfirmPassword: ""
    })
  }



  handleChange = (event) => {
    if (event.target.name === 'is_active' || event.target.name === 'is_sales_person') {
      this.setState({
        [event.target.name]: event.target.checked,
      });
    }
    else {
      this.setState({
        [event.target.name]: event.target.value,
      });
      if (!event.target.value) {
        if (event.target.name === 'first_name') { this.setState({ errorFirstName: true, }) }
        else if (event.target.name === 'last_name') { this.setState({ errorLastName: true, }) }
        else if (event.target.name === 'employee_id') { this.setState({ uidLengthError: true, }) }
        else if (event.target.name === 'phone') { this.setState({ errorPhone: false, }) }
        else if (event.target.name === 'email') { this.setState({ errorEmail: false, }) }
        else if (event.target.name === 'password') { this.setState({ errorPassword: true, }) }
        else if (event.target.name === 'confirmPassword') { this.setState({ errorconfirmPassword: true, }) }
      } else {
        if (event.target.name === 'first_name') {
          !checkAlphabet(event.target.value) ?
            this.setState({ errorFirstName: true, }) : this.setState({ errorFirstName: false, })
        }
        else if (event.target.name === 'last_name') {
          !checkAlphabet(event.target.value) ?
            this.setState({ errorLastName: true, }) : this.setState({ errorLastName: false, })
        }
        else if (event.target.name === 'employee_id') {
          !this.isUidValid(event.target.value) ?
            this.setState({ uidLengthError: true, }) : this.setState({ uidLengthError: false, })
        }
        else if (event.target.name === 'phone') {
          (checkMobileNumber(event.target.value) === "error") ?
            this.setState({ errorPhone: true, }) : this.setState({ errorPhone: false, })
        }
        else if (event.target.name === 'email') {
          (checkEmailAddress(event.target.value) === "error") ?
            this.setState({ errorEmail: true, }) : this.setState({ errorEmail: false, })
        }
        else if (event.target.name === 'password') {
          !this.isPasswordValid(event.target.value) ?
            this.setState({ errorPassword: true, }) : this.setState({ errorPassword: false, })
          if (this?.state?.confirmPassword) {
            !isConfirmPasswordValid(this?.state?.confirmPassword, event.target.value) ?
              this.setState({ errorConfirmPassword: true, }) : this.setState({ errorConfirmPassword: false, })
          }
        }
        else if (event.target.name === 'confirmPassword') {
          !isConfirmPasswordValid(event.target.value, this?.state?.password) ?
            this.setState({ errorConfirmPassword: true, }) : this.setState({ errorConfirmPassword: false, })
        }
        else if (event.target.name === 'newPassword') {
          !this.isPasswordValid(event.target.value) ?
            this.setState({ errorPassword: true, }) : this.setState({ errorPassword: false, })
          if (this?.state?.newConfirmPassword) {
            !isConfirmPasswordValid(this?.state?.newConfirmPassword, event.target.value) ?
              this.setState({ errorConfirmPassword: true, }) : this.setState({ errorConfirmPassword: false, })
          }
        }
        else if (event.target.name === 'newConfirmPassword') {
          !isConfirmPasswordValid(event.target.value, this?.state?.newPassword) ?
            this.setState({ errorConfirmPassword: true, }) : this.setState({ errorConfirmPassword: false, })
        }
      };
    }
  }

  isCharactersValid = (value, errorState) => {
    this.setState({ [errorState]: !/^[a-zA-Z -./']+$/.test(value) });
  };

  isEmailValid = (value, errorState) => {
    this.setState({
      [errorState]:
        !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(value),
    });
  };

  isPhoneNumberValid = (value, errorState) => {
    this.setState({
      [errorState]:
        value.length !== 10 || value < 4999999999 || value > 9999999999,
    });
  };

  isDOBValid = (value, errorState) => {
    this.setState({
      [errorState]: value.length <= 8 || value.length > 10,
    });
  };

  isPasswordValid = (value) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=[a-zA-Z0-9]*[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
    // const regex = /^(?=([a-zA-Z]*|[0-9]*)+[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
    if (regex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  isUidValid = (value) => {
    const regex = /^(?:(?!00000|0[1-9]{5}|00[1-9]{4}|000[1-9]{3}|0000[1-9]{2})[0-9]{5,7})$/;
    if (regex.test(value)) {
      return true;
    }
    return false;
  }


  goBackBack = (e) => {
    this.props.history.goBack();
    this.props.history.goBack();
  };

  handleDelete = () => {
    this.setState({ isLoadingDelete: true })
    this.props
      .deleteInternalUser(this.state.id)
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Internal User Removed Successfully`,
            "success"
          );
          setTimeout(() => {
            this.setState({ isLoadingDelete: false })
            this.props.history.goBack();
            this.handleCloseSnackbar()
          }, 4000);
        } else {
          this.setState({ isLoadingDelete: false })
          this.handleOpenSnackbar(
            `Error occured while removing internal user please try again`,
            "error"
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoadingDelete: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        // console.log(response);
        // this.props.history.goBack();
      })
    // .catch(console.error);
  }


  handleItemCategoriesList = () => {
    this.props
      .fetchItemcategories()
      .then((response) => {
        if (response.status === 200) {
          this.setState({ itemCategoryDropdownList: response.data })
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response)),
          "error"
        );
      })
  }

  handleItemCategorySelection = (e) => {
    const { value } = e.target;
    const filterValue = value.filter(d => d !== 'ALL')
    console.log(value, filterValue)
    if (filterValue.includes("")) {
      this.setState({ itemcategorySelected: [] }, () =>
        this.setState({ itemCategorySelectedId: [] })
      )
    }
    else {
      this.setState({
        itemCategorySelected: typeof filterValue === "string" ? filterValue.split(",") : filterValue
      }, () => {
        let itemIDList = [];
        this.state.itemCategorySelected.map(d => this.state.itemCategoryDropdownList.find(h => h.category_name === d && itemIDList.push(h.id)))
        this.setState({ itemCategorySelectedId: itemIDList })
      })
    }
  };

  render() {
    const {
      first_name,
      last_name,
      employee_id,
      email,
      phone,
      password,
      newPassword,
      newConfirmPassword,
      date_of_birth,
      is_active,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      internalUsersOperation,
      errorFirstName,
      errorLastName,
      errorEmail,
      errorPhone,
      errorDOB,
      errorPassword,
      errorConfirmPassword,
      uidLengthError,
      is_sales_person,
      itemCategorySelected,
      itemCategoryDropdownList,
    } = this.state;
    const {
      goBack,
      goBackBack,
      handleSaveUser,
      handleDelete,
      isCharactersValid,
      isPhoneNumberValid,
      isEmailValid,
      isDOBValid,
      isPasswordValid,
      isConfirmPasswordValid,
    } = this;
    const { classes, isMobile } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <Typography>
              {
                <>
                  <span
                    className={classes.titleWithBlueFont}
                    onClick={goBackBack}
                  >
                    {`Users And Permissions `}
                  </span>
                  <span className={classes.titleWithBlueFont} onClick={goBack}>
                    {`> Internal Users > `}
                  </span>
                </>
              }
              {internalUsersOperation === "view" ?
                "User Details" :
                internalUsersOperation === "new" ?
                  "Create User" :
                  "Edit Details"
              }
            </Typography>
            <div className={classes.displayData}>
              <div className={classes.displayUserData} style={{ width: isMobile && '100%' }}>
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>FIRST NAME *</span>
                  </div>
                  <div>
                    {internalUsersOperation === "view" ? (
                      <span className={classes.labelStyle}>{first_name}</span>
                    ) : (
                      <TextField
                        name="first_name"
                        type="text"
                        label="First Name"
                        id="outlined-margin-dense"
                        defaultValue={first_name}
                        placeholder="Enter First Name"
                        className={classes.textField}
                        onChange={(e) => this.handleChange(e)}
                        error={errorFirstName}
                        helperText={
                          errorFirstName ? `Invalid first name!` : null
                        }
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </div>
                </div>
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>LAST NAME *</span>
                  </div>
                  <div>
                    {internalUsersOperation === "view" ? (
                      <span className={classes.labelStyle}>{last_name}</span>
                    ) : (
                      <TextField
                        name="last_name"
                        type="text"
                        label="Last Name"
                        id="outlined-margin-dense"
                        defaultValue={last_name}
                        placeholder="Enter Last Name"
                        className={classes.textField}
                        onChange={(e) => this.handleChange(e)}
                        size="small"
                        variant="outlined"
                        error={errorLastName}
                        helperText={errorLastName ? `Invalid last name!` : null}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>UID *</span>
                  </div>
                  <div>
                    {internalUsersOperation === "view" ? (
                      <span className={classes.labelStyle}>{employee_id}</span>
                    ) : (
                      <TextField
                        name="employee_id"
                        label="UID"
                        type="text"
                        id="outlined-margin-dense"
                        // defaultValue={employee_id}
                        value={employee_id}
                        placeholder="Enter UID"
                        className={classes.textField}
                        onChange={(e) => this.handleChange(e)}
                        onKeyPress={(event) => {
                          if (event.charCode >= 48 && event.charCode <= 57) {
                            // let it happen, don't do anything
                          }
                          else { event.preventDefault(); }
                        }}
                        size="small"
                        variant="outlined"
                        error={uidLengthError}
                        helperText={
                          uidLengthError
                            // ? `Please enter valid  digit between 5 - 7`
                            ? `Enter valid digit b/w (00001 - 9999999)`
                            : null
                        }
                      />
                    )}
                  </div>
                </div>
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>PHONE</span>
                  </div>
                  <div>
                    {internalUsersOperation === "view" ? (
                      <span className={classes.labelStyle}>{phone}</span>
                    ) : (
                      <TextField
                        name="phone"
                        type="text"
                        label="Phone"
                        id="outlined-margin-dense"
                        defaultValue={phone}
                        placeholder="Enter Phone"
                        className={classes.textField}
                        inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                        onChange={(e) => this.handleChange(e)}
                        size="small"
                        variant="outlined"
                        error={errorPhone}
                        onKeyPress={(event) => {
                          if (event.charCode >= 48 && event.charCode <= 57) {
                            // let it happen, don't do anything
                          }
                          else { event.preventDefault(); }
                        }}
                        helperText={errorPhone ? `Invalid phone number!` : null}
                      />
                    )}
                    {console.log(phone)}
                  </div>
                </div>
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>EMAIL</span>
                  </div>
                  <div>
                    {internalUsersOperation === "view" ? (
                      <span className={classes.labelStyle}>{email}</span>
                    ) : (
                      <TextField
                        name="email"
                        label="Email"
                        type="email"
                        id="outlined-margin-dense"
                        defaultValue={email}
                        placeholder="Enter Email"
                        className={classes.textField}
                        onChange={(e) => this.handleChange(e)}
                        size="small"
                        variant="outlined"
                        error={errorEmail}
                        helperText={errorEmail ? `Invalid email!` : null}
                      />
                    )}
                  </div>
                </div>
                {internalUsersOperation === "new" && (
                  <div className={classes.lableInputAlignment}>
                    <span className={classes.labelStyle}>PASSWORD *</span>
                    <TextField
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      id="outlined-margin-dense"
                      defaultValue={password}
                      placeholder="Enter Password"
                      className={classes.textField}
                      onChange={(e) => this.handleChange(e)}
                      size="small"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                this.handleClickShowPassword("showPassword")
                              }
                              edge="end"
                              size="small"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errorPassword}
                      helperText={
                        errorPassword ? `Password must be 8 to 15 characters in length!` : null
                      }
                    />
                  </div>
                )}
                {internalUsersOperation === "new" && (
                  <div className={classes.lableInputAlignment}>
                    <span className={classes.labelStyle}>CONFIRM PASSWORD *</span>
                    <TextField
                      name="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      label="Confirm Password"
                      id="outlined-margin-dense"
                      defaultValue={confirmPassword}
                      placeholder="Enter Password Again"
                      className={classes.textField}
                      onChange={(e) => this.handleChange(e)}
                      disabled={!password}
                      size="small"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                this.handleClickShowPassword("showConfirmPassword")
                              }
                              edge="end"
                              size="small"
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errorConfirmPassword}
                      helperText={
                        errorConfirmPassword ? `Password not matching!` : null
                      }
                    />
                  </div>
                )}
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>DATE OF BIRTH *</span>
                  </div>
                  <div>
                    {internalUsersOperation === "view" ? (
                      <span className={classes.labelStyle}>
                        {
                          moment(date_of_birth).format("DD-MM-YYYY") == 'Invalid date'
                            ? ""
                            : moment(date_of_birth).format("DD-MM-YYYY")
                        }
                      </span>
                    ) : (
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            // autoOk
                            open={this.state.open}
                            onOpen={() => this.setState({ open: true })}
                            onClose={() => this.setState({ open: false })}
                            onChange={(value) => this.setState({
                              date_of_birth: moment(value).format("YYYY-MM-DD")
                            })}
                            value={date_of_birth}
                            // name="date_of_birth"
                            format="dd-MM-yyyy"
                            InputProps={{
                              style: { padding: 0 }
                            }}
                            size="small"
                            TextFieldComponent={(props) => (
                              <TextField
                                {...props}
                                variant="outlined"
                                onClick={(e) => this.setState({ open: true })}
                                label="Date of Birth"
                                id="outlined-margin-dense"
                                className={classes.textField}
                                error={errorDOB}
                                helperText={errorDOB ? `Invalid Date of Birth!` : null}
                              />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    )}
                  </div>
                </div>
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>ACTIVE</span>
                  </div>
                  <div>
                    <IOSSwitch
                      disabled={internalUsersOperation === "view"}
                      checked={is_active === undefined ? true : Boolean(is_active)}
                      onChange={(e) => this.handleChange(e)}
                      name="is_active"
                    />
                  </div>
                </div>
                <div className={classes.lableInputAlignment}>
                  <div>
                    <span className={classes.labelStyle}>SALESPERSON / SALES TARGET</span>
                  </div>
                  <div>
                    <IOSSwitch
                      disabled={internalUsersOperation === "view"}
                      checked={is_sales_person === undefined ? true : Boolean(is_sales_person)}
                      onChange={(e) => this.handleChange(e)}
                      name="is_sales_person"
                    />
                  </div>
                </div>
                {console.log(is_sales_person)}
                {/* {Boolean(is_sales_person === undefined ? true : is_sales_person) && (
                  <div className={classes.lableInputAlignment}>
                    <div>
                      <span className={classes.labelStyle}>ITEM CATEGORIES *</span>
                    </div>
                    <div>
                      {internalUsersOperation === "view" ? (
                        <span className={classes.labelStyle}>GK TMT</span>
                      ) : (
                        <FormControl
                          className={classes.textField}
                        >
                          <InputLabel
                            id="item-category-filter-label"
                            style={{ fontFamily: 'Poppins', }}
                          >
                            Item Categories
                          </InputLabel>
                          <Select
                            multiple
                            value={itemCategorySelected?.length === 0 ? ["ALL"] : itemCategorySelected}
                            onChange={this.handleItemCategorySelection}
                            input={<OutlinedInput label="Item Categories*" />}
                            SelectDisplayProps={{ style: { paddingBottom: 14 } }}
                            labelId="item-category-filter-label"
                            id="item-category-filter"
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>All</em>;
                              }

                              return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            style={{
                              fontFamily: 'Poppins',
                            }}
                          >
                            <MenuItem key="ALL" value="">
                              <Checkbox
                                style={{ borderColor: 'gray', color: '#1976d2' }}
                                checked={itemCategorySelected?.length === 0}
                              />
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Poppins',
                                  },
                                }}
                                primary="ALL"
                                className={classes.dropdownText}
                              />
                            </MenuItem>
                            {itemCategoryDropdownList ?
                              itemCategoryDropdownList.map((category) => (
                                <MenuItem key={category.category_name} value={category.category_name}>
                                  <Checkbox
                                    style={{ borderColor: 'gray', color: '#1976d2' }}
                                    checked={itemCategorySelected?.indexOf(category.category_name) > -1}
                                  />
                                  <ListItemText
                                    primaryTypographyProps={{
                                      style: {
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontFamily: 'Poppins',
                                      },
                                    }}
                                    primary={category.category_name}
                                    className={classes.dropdownText}
                                  />
                                </MenuItem>
                              ))
                              :
                              null}
                          </Select>
                        </FormControl>)
                      }
                    </div>
                  </div>)
                } */}
                {internalUsersOperation !== "view" && (
                  <div
                    className={classes.lableInputAlignment}
                    style={{ display: !this.state.resetClicked ? 'none' : 'flex' }}
                  >
                    <span className={classes.labelStyle}>PASSWORD (NEW) *</span>
                    <TextField
                      name="newPassword"
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      id="outlined-margin-dense"
                      value={this.state.newPassword}
                      placeholder="Enter Password"
                      className={classes.textField}
                      onChange={(e) => this.handleChange(e)}
                      size="small"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                this.handleClickShowPassword("showPassword")
                              }
                              edge="end"
                              size="small"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errorPassword}
                      helperText={
                        errorPassword ? `Password must be 8 to 15 characters in length!` : null
                      }
                    />
                  </div>
                )}
                {internalUsersOperation !== "view" && (
                  <div
                    className={classes.lableInputAlignment}
                    style={{ display: !this.state.resetClicked ? 'none' : 'flex' }}
                  >
                    <span className={classes.labelStyle}>CONFIRM PASSWORD (NEW) *</span>
                    <TextField
                      name="newConfirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      label="Confirm Password"
                      id="outlined-margin-dense"
                      value={this.state.newConfirmPassword}
                      placeholder="Enter Password Again"
                      className={classes.textField}
                      onChange={(e) => this.handleChange(e)}
                      disabled={!newPassword}
                      size="small"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                this.handleClickShowPassword("showConfirmPassword")
                              }
                              edge="end"
                              size="small"
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errorConfirmPassword}
                      helperText={
                        errorConfirmPassword ? `Password not matching!` : null
                      }
                    />
                  </div>
                )}
                {(internalUsersOperation !== "view" && internalUsersOperation !== "new") ?
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.resetColor}
                    onClick={this.handleResetClick}
                  >
                    {!this.state.resetClicked ? 'Reset Password' : 'Cancel'}
                  </Typography>
                  :
                  null
                }
              </div>
              <div className={classes.actionButtonsAlignment}>
                {internalUsersOperation === "view" ? (
                  <Button
                    size="medium"
                    className={classes.primaryBackground}
                    variant="contained"
                    startIcon={<EditOutlinedIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleEditUser();
                    }}
                    disabled={!this.state?.dataMapped}
                  >
                    EDIT
                  </Button>
                ) : (
                  <div>
                    <SaveButton
                      disabled={
                        (internalUsersOperation === "new" ?
                          (errorFirstName ||
                            errorLastName ||
                            errorEmail ||
                            errorPhone ||
                            errorDOB ||
                            errorPassword ||
                            errorConfirmPassword ||
                            !first_name ||
                            !last_name ||
                            !employee_id ||
                            uidLengthError ||
                            //employee_id.length < 9 ||
                            // !email ||
                            // !phone ||
                            !password ||
                            !date_of_birth ||
                            !confirmPassword) :
                          (errorFirstName ||
                            errorLastName ||
                            errorEmail ||
                            errorPhone ||
                            errorDOB ||
                            errorPassword ||
                            errorConfirmPassword ||
                            !first_name ||
                            !last_name ||
                            !employee_id ||
                            uidLengthError ||
                            // !newPassword ||
                            // !newConfirmPassword ||
                            //employee_id.length < 9 ||
                            // !email ||
                            // !phone ||
                            !date_of_birth ||
                            this.state?.isLoadingDelete
                          ))
                      }
                      size="medium"
                      className={classes.saveButton}
                      variant="contained"
                      startIcon={<CheckOutlinedIcon />}
                      onClick={handleSaveUser}
                      isLoading={this.state?.isLoading}
                    >
                      SAVE
                    </SaveButton>
                    {/* {internalUsersOperation !== "new" && (
                      <SaveButton
                        size="medium"
                        className={classes.removeButton}
                        variant="contained"
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        disabled={this.state?.isLoading}
                        onClick={handleDelete}
                        isLoading={this.state?.isLoadingDelete}
                      >
                        REMOVE
                      </SaveButton>
                    )} */}
                    <Button
                      size="medium"
                      className={classes.margin}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.goBack();
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
        />
      </Layout>
    );
  }
}
export default withStyles(manageInternalUsersStyles)(ManageInternalUsers);
