import React from "react";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  drawerTextColor,
  primaryColor,
  successButtonBackground,
} from "../../../constants/internal/colors";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  confirmButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  loaderStyle: { color: primaryColor },
});

export default function ActionConfirmationDialog(props) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
    >
      <DialogTitle>{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.removeDataLoader ? (
          <CircularProgress disableShrink className={classes.loaderStyle} />
        ) : (
          <>
            {!props?.cancelButtonTitle ? null : (
              <Button onClick={props.handleClose} className={classes.margin}>
                {props?.cancelButtonTitle || ""}
              </Button>
            )}
            {!props?.confirmButtonTitle ? null : (
              <Button
                onClick={props.handleConfirmationDialogActionConfirmed}
                className={classes.confirmButton}
              >
                {props?.confirmButtonTitle || ""}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
