import { Tab, Tabs } from "@material-ui/core";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import Bread from "../../common/Breadcrubs";
import { useEffect, useState } from "react";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { grey, primaryColor, secondaryGrey, whiteSmoke } from "../../../../constants/internal/colors";
import { pxToEm } from "../../../../methods";
import DefineScheme from "./DefineScheme";
import ApplyScheme from "./ApplyScheme";
import PublishScheme from "./PublishScheme";

const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: (isMobile) => ({ paddingRight: isMobile ? '5px' : "25px" }),
  displayData: {
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    justifyContent: "space-between",
  },
  tabStyle: (tabValue, currentTab) => ({
    backgroundColor: whiteSmoke,
    color: tabValue === currentTab ? grey : secondaryGrey,
    fontSize: pxToEm(15),
  }),
}

const CreateScheme = (props) => {

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    props.fetchItemCategory()
  }, [])

  const handleTabChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const handlePageTitleRoute = (displaySection, addTextTitle, fieldName) => {
    if (fieldName === 'title') {
      return displaySection === 'distributor'
        ? `Distributor ${addTextTitle}`
        : displaySection === 'dealer'
          ? `Dealer ${addTextTitle}`
          : displaySection === 'salesPerson' && `Sales Person ${addTextTitle !== '' ? 'Target' : addTextTitle}`
    }
    else if (fieldName === 'route') {
      return displaySection === 'distributor'
        ? `${INTERNALROUTES.SALESTARGET_DISTRIBUTOR_SCHEME}`
        : displaySection === 'dealer'
          ? `${INTERNALROUTES.SALESTARGET_DEALER_SCHEME}`
          : displaySection === 'salesPerson' && `${INTERNALROUTES.SALESTARGET_SALESPERSON_TARGET}`
    }
  }

  const { isMobile, displaySection } = props;
  return (
    <Layout {...props}>
      <div style={styles.mainDiv}>
        <div style={styles.rightPadding(isMobile)}>
          <Bread
            data={[
              {
                title: `Sales Target`,
                onClick: () => props.history.push(`${INTERNALROUTES.SALESTARGET}`),
                style: { fontSize: isMobile && pxToEm(15), },
              },
              {
                title: handlePageTitleRoute(displaySection, "Scheme", 'title'),
                onClick: () => props.history.push(handlePageTitleRoute(displaySection, "", 'route')),
                style: { fontSize: isMobile && pxToEm(15), },
              },
              {
                title: `Create New Scheme`,
                onClick: "",
                style: { fontSize: isMobile && pxToEm(15), },
              },
            ]}
          />
          <div style={styles.displayData}>
            <>
              <div style={styles.headerRow}>
                <>
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        borderBottom: `2px solid ${primaryColor}`,
                        colur: grey,
                      },
                    }}
                    value={tabValue}
                    onChange={handleTabChange}
                    style={styles.tabStyle(3, 4)}
                    variant="scrollable"
                  >
                    <Tab
                      label={"DEFINE SCHEME"}
                      style={styles.tabStyle(tabValue, 0)}
                    />
                    <Tab
                      label={"APPLY SCHEME"}
                      style={styles.tabStyle(tabValue, 1)}
                    />
                    <Tab
                      label={"PUBLISH SCHEME"}
                      style={styles.tabStyle(tabValue, 2)}
                    />
                  </Tabs>
                </>
              </div>
              {tabValue === 0 && (
                <DefineScheme
                  {...props}
                  handlePageTitleRoute={handlePageTitleRoute}
                />
              )}
              {tabValue === 1 && (
                <ApplyScheme
                  {...props}
                />
              )}
              {tabValue === 2 && (
                <PublishScheme
                  {...props}
                />
              )}
            </>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CreateScheme;