import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import { printPDFInMobile } from "../../../methods";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
function savebase64AsPDF(folderpath, filename, content, contentType, snackFunction, downloadPDFLoader) {
    // Convert the base64 string in a Blob
    console.log("here", folderpath)
    let DataBlob = b64toBlob(content, contentType);

    console.log("Starting to write the file :3");
    window.resolveLocalFileSystemURL(folderpath, function (dir) {
        console.log("Access to the directory granted succesfully");
        snackFunction()
        setTimeout(() => {
            dir.getFile(filename, { create: true }, function (file) {
                console.log("File created succesfully.");

                file.createWriter(function (fileWriter) {
                    console.log("Writing content to file");
                    fileWriter.write(DataBlob);
                    console.log("Folder Path" + folderpath + filename);
                    let finalPath = folderpath + filename;
                    // window.open(String(finalPath), '_system')
                    // window.open(encodeURI(String(finalPath)), '_system')
                    window?.cordova.plugins.fileOpener2.open(finalPath, 'application/pdf')
                    downloadPDFLoader !== '' && downloadPDFLoader()
                    // window.open(finalPath, '_system');

                }, function () {
                    alert('Unable to save file in path ' + folderpath);
                });
            });
        }, 2000)
    });
}


const handlePrintTable = (laNumber, action, snackFunction = '', downloadPDFLoader = '', handleErrorSnackbar = '') => {
    axios
        .get(`${APIROUTES.GET_MORE_LA_DETAILS}${laNumber}/`)
        .then((response) => {
            if (response.status === 200) {
                const LANumber = `${response.data[0].la_number}.pdf`;
                let tableData = [];
                tableData.push([
                    {
                        text: "#",
                        style: "itemsHeader",
                        border: [true, true, true, true],
                    },
                    // {
                    //     text: "Item Code",
                    //     style: "itemsHeaderSubTitle",
                    //     border: [false, false, false, true],
                    // },
                    {
                        text: "Item Name",
                        style: "itemsHeaderSubTitle",
                        border: [false, true, true, true],
                    },
                    {
                        text: "Item Remarks",
                        style: "itemsHeaderSubTitle",
                        border: [false, true, true, true],
                    },
                    // {
                    //     text: "HSN/SAC No.",
                    //     style: "itemsHeader",
                    //     border: [false, false, false, true],
                    // },
                    {
                        text: "LA Qty.",
                        style: "itemsHeader",
                        border: [false, true, true, true],
                    },
                    // {
                    //     text: "Approx No. of Packages",
                    //     style: "itemsHeader",
                    //     border: [false, false, false, true],
                    // },
                    {
                        text: "Tare Weight",
                        style: "itemsHeader",
                        border: [false, true, true, true],
                    },
                    {
                        text: "Gross Weight",
                        style: "itemsHeader",
                        border: [false, true, true, true],
                    },
                    {
                        text: "Net Qty",
                        style: "itemsHeader",
                        border: [false, true, true, true],
                    },
                    {
                        text: "Actual No. of Packages",
                        style: "itemsHeader",
                        border: [false, true, true, true],
                    },
                    {
                        text: "Section Weight",
                        style: "itemsHeader",
                        border: [false, true, true, true],
                    },
                    {
                        text: "LOT #",
                        style: "itemsHeader",
                        border: [false, true, true, true],
                    },
                ]);
                var dd = {
                    info: {
                        title: `${response.data[0].la_number ? response.data[0].la_number : ""
                            }_${new Date().toISOString()}`,
                        author: "RIPL",
                        subject: "Loading Advice",
                        keywords: "Loading Advice",
                    },
                    pageMargins: [15, 0, 15, 0],
                    // background: {
                    //   // image: AegleBase64,
                    //   // width: 200,
                    //   text: ">",
                    //   // opacity: 0.05,
                    //   absolutePosition: { x: 5, y: 250 },
                    // },
                    background: function (currentPage, pageSize) {

                        return {
                            // image: AegleBase64,
                            // width: 200,
                            text: "<-",
                            absolutePosition: { x: 5, y: pageSize.height / 2 },
                        }
                    },
                    content: [
                        "\n",
                        {
                            columns: [
                                {
                                    // image: 'https://i.pinimg.com/originals/71/fc/47/71fc472790aa43f44555d08f4d4904c2.jpg',
                                    // fit: [100, 100],
                                    // margin: [0, 0, 0, 5],
                                    // width: 100,
                                    // height: 10
                                },
                                {
                                    text: "Loading Advice",
                                    style: "fontSeventeenCenter",
                                    margin: [0, 0, 0, 5],
                                    width: '*'
                                },
                                {

                                    // margin: [0, 0, 0, 5],
                                    // width: 100
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    style: "tableExample",
                                    table: {
                                        headerRows: 1,
                                        widths: [50, 50, 50, 50],
                                        body: [
                                            [
                                                {
                                                    text: "Details of Recipient (Billed to)",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `${response.data[0].buyer.first_name
                                                        ? response.data[0].buyer.first_name
                                                        : ""
                                                        } ${response.data[0].buyer.last_name
                                                            ? response.data[0].buyer.last_name
                                                            : ""
                                                        }`,
                                                    style: "buyerName",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text:
                                                        response.data[0]?.buyer?.customer_addresses?.address ?
                                                            (
                                                                (response.data[0]?.buyer?.customer_addresses?.address
                                                                    ? response.data[0]?.buyer?.customer_addresses?.address + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.customer_addresses?.city
                                                                    ? response.data[0]?.buyer?.customer_addresses?.city + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.customer_addresses?.state
                                                                    ? response.data[0]?.buyer?.customer_addresses?.state + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.customer_addresses?.country
                                                                    ? response.data[0]?.buyer?.customer_addresses?.country + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.customer_addresses?.pincode
                                                                    ? response.data[0]?.buyer?.customer_addresses?.pincode
                                                                    : "")
                                                            )
                                                            :
                                                            (
                                                                (response.data[0]?.buyer?.gst_addresses?.door_number
                                                                    ? response.data[0]?.buyer?.gst_addresses?.door_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.gst_addresses?.floor_number
                                                                    ? response.data[0]?.buyer?.gst_addresses?.floor_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.gst_addresses?.building_name
                                                                    ? response.data[0]?.buyer?.gst_addresses?.building_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.gst_addresses?.street
                                                                    ? response.data[0]?.buyer?.gst_addresses?.street + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.gst_addresses?.location
                                                                    ? response.data[0]?.buyer?.gst_addresses?.location + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.gst_addresses?.district_name
                                                                    ? response.data[0]?.buyer?.gst_addresses?.district_name + ", "
                                                                    : "")
                                                                +
                                                                ((response.data[0]?.buyer?.gst_addresses?.city_name &&
                                                                    response.data[0]?.buyer?.gst_addresses?.district_name)
                                                                    ? ((response.data[0]?.buyer?.gst_addresses?.city_name).trim().toUpperCase() !==
                                                                        (response.data[0]?.buyer?.gst_addresses?.district_name).trim().toUpperCase())
                                                                        ? response.data[0]?.buyer?.gst_addresses?.city_name + ", "
                                                                        : ""
                                                                    : response.data[0]?.buyer?.gst_addresses?.city_name
                                                                        ? response.data[0]?.buyer?.gst_addresses?.city_name + ", "
                                                                        : "")
                                                                +
                                                                (response.data[0]?.buyer?.gst_addresses?.state_name
                                                                    ? response.data[0]?.buyer?.gst_addresses?.state_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.buyer?.gst_addresses?.pincode
                                                                    ? response.data[0]?.buyer?.gst_addresses?.pincode
                                                                    : "")
                                                            ),
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `STATE CODE : ${response.data[0].buyer?.gst_addresses?.state_code
                                                        ? response.data[0].buyer?.gst_addresses?.state_code?.substring(0, 2)
                                                        : response.data[0].buyer?.customer_addresses?.state_code?.substring(0, 2) || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `GSTIN : ${response.data[0].buyer.gstin_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `PAN : ${response.data[0].buyer.pan_card_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                                {
                                                    text: `MOBILE NO: ${response.data[0]?.buyer
                                                        ?.customer_addresses?.contact ||
                                                        response.data[0]?.buyer
                                                            ?.gst_addresses?.contact
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                            ],
                                            // Consignee block start
                                            [
                                                {
                                                    text: "Consignee (Ship to)",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `${response.data[0].consignee.first_name || ""
                                                        } ${response.data[0].consignee.last_name || ""}`,
                                                    style: "buyerName",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text:
                                                        response.data[0]?.consignee?.consignee_from_customer_addresses?.address ?
                                                            (
                                                                (response.data[0]?.consignee?.consignee_from_customer_addresses?.address
                                                                    ? response.data[0]?.consignee?.consignee_from_customer_addresses?.address + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_customer_addresses?.city
                                                                    ? response.data[0]?.consignee?.consignee_from_customer_addresses?.city + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_customer_addresses?.state
                                                                    ? response.data[0]?.consignee?.consignee_from_customer_addresses?.state + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_customer_addresses?.country
                                                                    ? response.data[0]?.consignee?.consignee_from_customer_addresses?.country + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_customer_addresses?.pincode
                                                                    ? response.data[0]?.consignee?.consignee_from_customer_addresses?.pincode
                                                                    : "")
                                                            )
                                                            :
                                                            (
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.door_number
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.door_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.floor_number
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.floor_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.building_name
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.building_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.street
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.street + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.location
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.location + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.district_name
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.district_name + ", "
                                                                    : "")
                                                                +
                                                                ((response.data[0]?.consignee?.consignee_from_gst_information_addresses?.city_name &&
                                                                    response.data[0]?.consignee?.consignee_from_gst_information_addresses?.district_name)
                                                                    ? ((response.data[0]?.consignee?.consignee_from_gst_information_addresses?.city_name).trim().toUpperCase() !==
                                                                        (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.district_name).trim().toUpperCase())
                                                                        ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                                                                        : ""
                                                                    : response.data[0]?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                                        ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                                        : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.state_name
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.state_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.pincode
                                                                    ? response.data[0]?.consignee?.consignee_from_gst_information_addresses?.pincode
                                                                    : "")
                                                            ),
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `STATE CODE : ${response.data[0].consignee?.consignee_from_gst_information_addresses?.state_code
                                                        ? response.data[0].consignee?.consignee_from_gst_information_addresses?.state_code?.substring(0, 2)
                                                        : response.data[0].consignee?.consignee_from_customer_addresses?.state_code?.substring(0, 2) || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `GSTIN : ${response.data[0].consignee.gstin_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `PAN : ${response.data[0].consignee.pan_card_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `MOBILE NO: ${response.data[0]?.consignee
                                                        ?.consignee_from_customer_addresses?.contact ||
                                                        response.data[0]?.consignee
                                                            ?.consignee_from_gst_information_addresses?.contact || ''
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Email : ${response.data[0]?.consignee
                                                        ?.consignee_from_customer_addresses?.email ||
                                                        response.data[0]?.consignee
                                                            ?.consignee_from_gst_information_addresses?.email || ''
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            // broker block
                                            [
                                                {
                                                    text: " ",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Broker Name : ${response.data[0].order.broker}`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Contract : ${response.data[0].order?.order_number || ""}`,
                                                    // style: "invoiceDetailLable",
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    // border: [false, false, false, false],
                                                    border: [true, false, false, false],
                                                    // fillColor: "#f5f5f5",
                                                },
                                                {},
                                                {
                                                    text: `DATE : ${moment(
                                                        response.data[0].order?.created_at,
                                                    ).format("DD-MMM-YYYY") || ""
                                                        }`,
                                                    // style: "invoiceDetailLable",
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    // border: [false, false, false, false],
                                                    border: [false, false, true, false],
                                                    // fillColor: "#f5f5f5",
                                                },
                                            ],


                                            [
                                                {
                                                    text: response.data[0]?.sub_contract_detail?.sub_contract_number ?
                                                        `Subcontract No : ${response.data[0]?.sub_contract_detail?.sub_contract_number && response.data[0]?.sub_contract_detail?.sub_contract_number || ""}` : "",
                                                    // style: "invoiceDetailLable",
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    // border: [false, false, false, false],
                                                    border: [true, false, false, true],
                                                    // fillColor: "#f5f5f5",
                                                },
                                                {},

                                                {
                                                    text: response.data[0].sub_contract_created_date ?
                                                        `Subcontract Date : ${response.data[0].sub_contract_created_date && moment(response.data[0].sub_contract_created_date,
                                                        ).format("DD-MMM-YYYY") || ""
                                                        }` : "",
                                                    // style: "invoiceDetailLable",
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    // border: [false, false, false, false],
                                                    border: [false, false, true, true],
                                                    // fillColor: "#f5f5f5",
                                                },
                                            ],

                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },
                                {
                                    margin: [10, 5, 0, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [70, 70],
                                        body: [
                                            [
                                                {
                                                    text: `TRUCK NO :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].truck_number || ""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `L.R. NO. :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].lr_number || ""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "L.R. DATE :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${moment(
                                                        response.data[0].lr_date
                                                    ).format("DD-MMM-YYYY") || ""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "TRANSPORTER NAME :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].transporter.acc_name || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "DRIVER'S NAME :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].driver_name || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "DRIVER'S NO. :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].driver_contact_number || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "DRIVER'S LICENCE NO. :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].driver_licence || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],

                                            [
                                                {
                                                    text: "MODE OF TRANSPORT :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].mode_of_transport || ""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "FRIEGHT:",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].fright || ""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "FROM :",
                                                    style: "invoiceDetailLable",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {
                                                    text: `${response.data[0].plant.plant_city || ""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                            ],
                                            [
                                                {
                                                    text: "TO :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].consignee?.consignee_from_customer_addresses?.city ||
                                                        (response.data[0]?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                            || response.data[0]?.consignee?.consignee_from_gst_information_addresses?.district_name || '')
                                                        }`,

                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Place Of Supply :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].buyer.customer_addresses.city ||
                                                        (response.data[0]?.buyer
                                                            ?.gst_addresses?.city_name || response.data[0]?.buyer
                                                                ?.gst_addresses?.district_name || '')
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "Transaction Type :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].tran_supply_type || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Payment Type :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data[0].payment_method.payment_method_name || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },
                                {
                                    margin: [-55, 5, 0, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [120, 120],
                                        body: [
                                            [
                                                {
                                                    text: `PLANT : ${response.data[0].plant.plant_name || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `LA : ${response.data[0].la_number || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `GATE IN DATE : ${moment(
                                                        response.data[0].gate_in_date,
                                                    ).format("DD-MMM-YYYY") || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    qr: response.data[0].la_number,
                                                    fit: "120",
                                                    width: "120",
                                                    height: "120",
                                                    style: "itemsHeader",
                                                    fillColor: "#ffffff",
                                                    border: [false, false, false, false],
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            margin: [0, 0, 0, 0],
                            table: {
                                headerRows: 1,
                                widths: [15, "*", 95, 40, 37, 37, 37, 45, 40, 45],
                                body: tableData,
                            },
                        },
                        response.data[0].loading_advice_items.map((e, i) => ({
                            margin: [0, 0, 0, 0],
                            table: {
                                widths:
                                    // [25, 60, "*", 45, 45, 85, 45, 85],
                                    //[25, "*", 60, 45, 45, 45, 45, 45, 45, 45],
                                    [15, "*", 95, 40, 37, 37, 37, 45, 40, 45],
                                body: [
                                    [
                                        {
                                            text: i + 1,
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                        // {
                                        //     text: `${e.item.item_code}`,
                                        //     style: "itemSubTitle",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            text: `${e.item.item_name}`,
                                            style: "itemSubTitle",
                                            border: [true, false, true, true],
                                        },
                                        {
                                            text: `${e.remark ? e.remark : ""}`,
                                            style: "itemSubTitle",
                                            border: [true, false, true, true],
                                        },
                                        // {
                                        //     text: `${e.item.hsn_code}`,
                                        //     style: "itemNumber",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            text: `${Number(e.item_qty).toFixed(3)} MTS`,
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                        // {
                                        //     text: `${e.item_qty && e.item.section.section_weight ? Number((e.item_qty / e.item.section.section_weight) - 1).toFixed(0) < 1 ? "0 -" : (Number((e.item_qty / e.item.section.section_weight) - 1).toFixed(0) + " - ") : null} ${e.item_qty && e.item.section.section_weight ? Number(e.item_qty / e.item.section.section_weight) < 1 ? 1 : Number(e.item_qty / e.item.section.section_weight).toFixed(0) : ""} ${e.item.section ? e.item.section.section_name ? e.item.section.section_name : "" : ""}`,
                                        //     style: "itemNumber",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            //text: `${i == 0 ? response.data[0]?.tare_weight : ""}`,
                                            text: `${!!e.item_weights?.tare_weight ?
                                                Number(e.item_weights?.tare_weight).toFixed(3)
                                                : i == 0 ?
                                                    Number(response.data[0]?.tare_weight).toFixed(3) :
                                                    (i != 0 && (!e?.item_weights?.item_weight)) ?
                                                        Number(e?.item_weights?.item_weight).toFixed(3)
                                                        : Number(response.data[0].loading_advice_items
                                                            .filter((v, index) => index <= i - 1)
                                                            .reduce((acc, val) => acc = acc + (val?.item_weights?.item_weight), Number(response.data[0]?.tare_weight))).toFixed(3)} `,
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                        {
                                            text: `${Number(!!e.item_weights?.gross_weight ? e.item_weights?.gross_weight : i == 0 ? Number(response.data[0]?.tare_weight) + Number(e?.item_weights?.item_weight) : i != 0 && (!e?.item_weights?.item_weight) ? e?.item_weights?.item_weight
                                                : response.data[0].loading_advice_items.filter((v, index) => index <= i - 1).reduce((acc, val) => acc = acc + Number(val?.item_weights?.item_weight), Number(response.data[0]?.tare_weight)) + Number(e?.item_weights?.item_weight)).toFixed(3)} `,
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                        {
                                            text: `${e.alloted_weight == 0 ? "" : e.alloted_weight} MTS`,
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                        {
                                            text: `${e.actual_section_count ? (Number(e.actual_section_count).toFixed(0) + " " + (e.section_name !== null ? e.section_name : "")) : ""}`,
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                        {
                                            text: `${(Number((Number(e.alloted_weight) / (Number(e.actual_section_count !== null ? e.actual_section_count : 0).toFixed(0))).toFixed(3)) || Number(0).toFixed(3)) || ""}`,
                                            // text: Number(e.alloted_weight) / (Number(e.actual_section_count !== null ? e.actual_section_count : 0).toFixed(0)),
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                        {
                                            text: ``,
                                            style: "itemNumber",
                                            border: [true, false, true, true],
                                        },
                                    ],
                                ],
                            },
                        })),
                        {
                            columns: [
                                {
                                    text: " ",
                                    style: "fontSixLeft",
                                    margin: [0, 0, 0, 5],
                                    border: [false, false, true, false],
                                },
                            ],
                        },
                        // {
                        //   columns: [
                        //     {
                        //       text: `Approved By ${response.data[0]?.log_details.APPROVED?.user_name}`,
                        //       style: "itemNumberBelow",
                        //       margin: [0, 100, 0, 5],
                        //       border: [true, true, true, true],
                        //       // colSpan: 4,
                        //       height: "120",
                        //       fillColor: "#f5f5f5",
                        //     },
                        //     {
                        //       text: "Loading supervisor",
                        //       style: "itemNumberBelow",
                        //       margin: [0, 100, 0, 5],
                        //       border: [true, true, true, true],
                        //       // colSpan: 4,
                        //       height: "120",
                        //       fillColor: "#f5f5f5",

                        //     },
                        //     {
                        //       text: "WB Operator",
                        //       style: "itemNumberBelow",
                        //       margin: [0, 100, 0, 5],
                        //       border: [true, true, true, true],
                        //       // colSpan: 4,
                        //       height: "120",
                        //       fillColor: "#f5f5f5",
                        //       borderColor: "#f5f5f5"

                        //     },
                        //   ],
                        // },
                        {
                            table: {
                                headerRows: 0,
                                widths: ["*", "*", "*"],
                                heights: [40, 40, 40],
                                body: [
                                    [
                                        {
                                            text: `Approved By : ${response.data[0]?.log_details.APPROVED?.user_name ? response.data[0]?.log_details.APPROVED?.user_name : ""}`,
                                            border: [true, true, false, true],
                                            bold: true,
                                            style: "fontSixLeft",
                                            fillColor: "#ffffff",
                                        },
                                        {
                                            text: `Loading Supervisor`,
                                            border: [true, true, false, true],
                                            bold: true,
                                            style: "fontSixLeft",
                                            fillColor: "#ffffff",
                                        },
                                        {
                                            text: `WB Operator : ${response.data[0]?.log_details.PACKED?.user_name ? response.data[0]?.log_details.PACKED?.user_name : ""}`,
                                            border: [true, true, true, true],
                                            bold: true,
                                            style: "fontSixLeft",
                                            fillColor: "#ffffff",
                                        },
                                    ],
                                ],
                            }
                        },
                        {
                            columns: [
                                {
                                    text: "This document is computer generated and does not require signature.",
                                    style: "itemNumber",
                                    margin: [0, 20, 0, 20],
                                },
                            ],
                        },
                    ],
                    styles: {
                        tableExample: {
                            margin: [0, 5, 0, 5],
                        },
                        // Taxation Data
                        fontSixLeft: {
                            alignment: "center",
                            justify: "center",
                            fontSize: 6,
                            margin: [0, 30, 0, 0],
                        },
                        fontSixRight: {
                            alignment: "right",
                            fontSize: 6,
                            margin: [0, 0, 0, 0],
                        },
                        fontSixCenter: {
                            alignment: "center",
                            fontSize: 6,
                            margin: [0, 3, 0, 3],
                        },
                        fontSevenLeft: {
                            alignment: "left",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        fontSevenRight: {
                            alignment: "right",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        fontSevenCenter: {
                            alignment: "center",
                            fontSize: 7,
                            margin: [0, 3, 0, 3],
                        },
                        fontSeventeenCenter: {
                            alignment: "center",
                            fontSize: 17,
                            margin: [0, 3, 0, 3],
                        },
                        fontEightRight: {
                            alignment: "right",
                            fontSize: 8,
                            margin: [0, 0, 0, 0],
                            bold: true,
                        },
                        fontEightLeft: {
                            alignment: "left",
                            fontSize: 8,
                            bold: true,
                            margin: [0, 0, 0, 0],
                        },
                        buyerName: {
                            bold: true,
                            color: "#333333",
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                            margin: [0, 0, 0, 3],
                        },
                        buyerAddressDetails: {
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                        },
                        // Invoice Detail
                        invoiceDetailLable: {
                            color: "#333333",
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                        },
                        itemsHeader: {
                            padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "center",
                        },
                        itemsHeaderSubTitle: {
                            padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "center",
                        },
                        itemHeaderRightAligned: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "right",
                        },
                        itemSubTitle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            alignment: "left",
                        },
                        itemNumber: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            alignment: "center",
                        },
                        itemNumberBelow: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            alignment: "center",
                            borderColor: "#fff"
                        },
                        itemNumberRightAligned: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            alignment: "right",
                        },
                        itemRowEvenNumber: {
                            margin: [0, 2, 0, 2],
                            fontSize: 8,
                            alignment: "center",
                        },
                        tableHeader: {
                            fontSize: 9,
                            bold: true,
                        },
                        tableDetailValues: {
                            fontSize: 9,
                        },
                    },
                };

                if (action === "PRINT") {
                    downloadPDFLoader !== '' && downloadPDFLoader()
                    pdfMake.createPdf(dd).open();
                } else if (action === 'CORDOVA') {
                    printPDFInMobile(pdfMake, dd, LANumber, snackFunction, downloadPDFLoader)
                }
                else {
                    pdfMake
                        .createPdf(dd)
                        .download(
                            `${response.data[0].la_number}_${new Date().toISOString()}.pdf`
                        );
                }
            }
        })
        .catch((err) => {
            handleErrorSnackbar !== '' && handleErrorSnackbar(err.response)
            downloadPDFLoader !== '' && downloadPDFLoader()
            console.log(err);
        });
};

export {
    handlePrintTable
}