import React, { Component } from "react";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import B2bNotification from "../../components/b2b/mobile/B2bNotification";
import B2bNotificationWeb from "../../components/b2b/web/B2bNotificationWeb";
import B2b_URL from "../../constants/b2b/B2bRoutes";
import axios from "axios";
import withUser from "../../hoc/withUser";
import moment from "moment";
import { StaticAppVersion, compareValues } from "../../methods.js";

class B2bNotificationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // notificationLoader: true,
      fetchNextNotification: "",
      userNotifications: [],
      logoutPopoverText: ""
    };
  }

  offlineCheck = () => {
    this.setState({ isOffline: true });
  };
  onlineCheck = () => {
    this.setState({ isOffline: false });
  };

  componentWillMount() {
    window.addEventListener("offline", this.offlineCheck);
    window.addEventListener("online", this.onlineCheck);

  }

  componentDidMount() {
    if (navigator.onLine === false) {
      this.setState({
        isOffline: true,
      });
    }
    // console.log(this.props)
    this.fetchNotificationList()
    let returnUrl = window.location.pathname;
    localStorage.setItem("returnUrl", returnUrl);
    let currentUrl = window.location.pathname;
    localStorage.setItem("currentUrl", currentUrl);
  }
  fetchNotificationList = () => {
    var dates = [];
    axios
      .get(`${B2b_URL.NOTIFICATIONS}/?V=${StaticAppVersion()}`, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then((response) => {
        // console.log(response)
        let userNotifications = response.data;
        if (userNotifications && userNotifications.length > 0) {
          userNotifications.map((item) => {
            if (dates.includes(moment(item.created_at).format("DD MMMM, dddd"))) {
              return;
            } else {
              dates.push(moment(item.created_at).format("DD MMMM, dddd"));
            }
          });
          this.setState({
            notificationLoader: false,
            userNotifications: userNotifications.sort(
              compareValues("created_at", "desc")
            ),
            dates,
            fetchNextNotification: response.data.next,
          });
        }
        else if (userNotifications && userNotifications.length === 0) {
          this.setState({
            notificationLoader: false,
          })
        }
      })
      .catch(error => {
        // console.log(error.response, error.response?.data?.detail)
        if (error.response.status === 403) {
          this.setState({
            logoutPopoverText: error.response?.data?.detail
          }, () => console.log(error.response?.data?.detail, this.state?.logoutPopoverText))
        }
      })
  }
  handleFetchMoreNotification = (URL) => {
    this.setState({ nextNotificationLoader: true });
    var dates = this.state.dates;

    axios
      .get(`${URL}&V=${StaticAppVersion()}`, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then((response) => {
        var nextNotification,
          nextNotification = response.data.results;
        nextNotification.map((item) => {
          if (dates.includes(moment(item.created_at).format("DD MMMM, dddd"))) {
            return;
          } else {
            dates.push(moment(item.created_at).format("DD MMMM, dddd"));
          }
        });
        this.state.userNotifications.push(...nextNotification);
        this.setState({
          notificationLoader: false,
          userNotifications: this.state.userNotifications.sort(
            compareValues("created_at", "desc")
          ),
          dates,
          fetchNextNotification: response.data.next,
          nextNotificationLoader: false,
        });
      });
  };

  handleNotificationClick = (notification) => {
    // console.log(notification)
    const payload = [{
      id: notification.id,
      is_read: true
    }]
    let link = notification.notification_link.split("/")
    // console.log(link)
    let linkk = `/${link[3]}/${link[4]}/${link[5]}`
    // console.log(linkk)
    this.props.history.push(linkk)
    axios.put(
      `${B2b_URL.READNOTIFICATION}/?V=${StaticAppVersion()}`,
      payload,
      // this.getOptions(this.props.user)

    ).then(response => {
      // console.log(response)
      let link = `${notification.notification_link.split}`
      // console.log(link)
      this.props.history.push({
        pathname: link
      })
      this.fetchNotificationList()
    })
      .catch(err => console.log(err))
  }

  render() {
    const { isMobile } = this.props;
    const { isOffline, logoutPopoverText } = this.state;
    // console.log(this.props)

    return (
      <div>
        {isMobile ? (
          <Route
            exact
            path="/b2bNotification"
            render={(props) => (
              <B2bNotification
                {...this.state}
                {...props}
                userNotifications={
                  this.state.notificationLoader === false
                    ? this.state.userNotifications
                    : null
                }
                dates={
                  this.state.notificationLoader === false
                    ? this.state.dates
                    : null
                }
                handleFetchMoreNotification={this.handleFetchMoreNotification}
                handleNotificationClick={this.handleNotificationClick}
                fetchNotificationList={this.fetchNotificationList}
                isOffline={isOffline}
                logOut={this.props.location.logout}
                logoutPopoverText={logoutPopoverText}
              />
            )}
          />
        ) : (
          <Route
            exact
            path="/b2bNotification"
            render={(props) => <B2bNotificationWeb {...props} isOffline={isOffline} logoutPopoverText={logoutPopoverText} logOut={this.props.location.logout} />}
          />
        )}
      </div>
    );
  }
}
export default withUser(withRouter(B2bNotificationContainer));
