import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import Edit from "@material-ui/icons/Edit";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from "@material-ui/core";
import { grey, secondaryGrey } from "../../../constants/internal/colors";
import { withRouter } from "react-router";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class ContractsPlantPlaningTable extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

ContractsPlantPlaningTable.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

// const ContractsPlantPlaningTableWrapped = withStyles(actionsStyles, {
//   withTheme: true,
// })(ContractsPlantPlaningTable);

let counter = 0;
function createData(name, calories, fat) {
  counter += 1;
  return { id: counter, name, calories, fat };
}

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    paddingTop: "0px",
  },
  tableContainerStyle: {
    overflowY: "scroll",
  },
  container: {
    maxHeight: "45vh",
  },
});

class CustomPaginationActionsTable extends React.Component {
  state = {
    rows: [
      createData("8DS", 305, 3.7),
      createData("5DE", 452, 25.0),
      createData("3ER", 262, 16.0),
    ].sort((a, b) => (a.calories < b.calories ? -1 : 1)),
    page: 0,
    rowsPerPage: 5,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    return (
      <Paper className={classes.root} style={{ boxShadow: "none" }}>
        <div className={classes.tableWrapper}>
          <TableContainer className={classes.tableContainerStyle} id="scroll">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    PLANT
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    COMPLETE LA
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    OPEN LA
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    OPEN DISPATCH
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    PENDING
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: grey,
                      width: "15.9%",
                      fontSize: "0.9em",
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      style={{ cursor: "pointer" }}
                      key={row.id}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `/internalContracts/singleContracts/${row.id}`
                        );
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ padding: "15px" }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="center" style={{ padding: "15px" }}>
                        {row.calories}
                      </TableCell>
                      <TableCell align="center" style={{ padding: "15px" }}>
                        {row.fat}
                      </TableCell>
                      <TableCell align="center" style={{ padding: "15px" }}>
                        {row.calories}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          padding: "15px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {row.calories}
                        <Edit
                          style={{
                            color: secondaryGrey,
                            marginLeft: "6px",
                            width: "0.8em",
                            height: "0.8em",
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" style={{ padding: "15px" }}>
                        {row.fat}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={ContractsPlantPlaningTableWrapped}
            component="div"
          /> */}
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CustomPaginationActionsTable));
