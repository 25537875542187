import React, { forwardRef, useEffect, useRef, useState } from "react";
import NorthIcon from '@mui/icons-material/North';
import { Button } from "@material-ui/core";

// Define ScrollTop component with forwardRef
const ScrollTop = forwardRef(({ type = "", bottom = '' }, ref) => {

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    // console.log(currentScrollY)
    if (currentScrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const scrollListener = () => {
      handleScroll();
    };

    window.addEventListener("scroll", scrollListener, true);
    return () => {
      window.removeEventListener("scroll", scrollListener, true);
    };
  }, []);

  const scrollToRef = () => {
    console.log("clicked");
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  // console.log(isVisible)

  return (
    type === 'RefDiv'
      ? <div ref={ref} onScroll={handleScroll} />
      : (<Button
        style={{
          position: 'sticky',
          bottom: bottom === '' ? '82px' : bottom, // Adjust as needed
          right: 16, // Adjust as needed
          left: '90%',
          opacity: isVisible ? 1 : 0,
          justifyContent: 'flex-end',
          transition: 'opacity 0.2s ease-out',
          zIndex: 1000,
        }}
        onClick={() => { setTimeout(() => scrollToRef(), 10); }}>
        <NorthIcon style={{
          height: 50,
          width: 50,
          padding: 13,
          color: '#333',
          boxShadow: '-6px 6px 12px #a1a1a1, 6px -6px 12px #a1a1a1',
          background: "linear-gradient(225deg, #fff, #fff)",
          borderRadius: '50%',

        }} />
      </Button>)

  );
});

export default ScrollTop;
