import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Spinner = () => {
    return(
        <div style={{width: '100%', textAlign: 'center', marginTop: 50}}>
            <CircularProgress />
        </div>
    )
}

export default Spinner;