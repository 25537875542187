import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import "../../styles/login.css";
import Paper from "@material-ui/core/Paper";
import {
  primaryColor,
  // white,
  // dailyRateScreenTextColor,
  // lightPrimaryColor,
  bottomNavigationFontColor,
  secondaryDarkColor,
  // errorMessageColor,
  secondaryColor,
} from "../../styles/colors";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Help from "@material-ui/icons/Help";
// import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
// import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import Close from "@material-ui/icons/Close";
import Email from "@material-ui/icons/Email";
import Language from "@material-ui/icons/Language";
import Phone from "@material-ui/icons/Phone";
import SpinLoader from "../common/SpinLoader";
import StaticVersionDisplay from "./StaticVersionDisplay";
import backStack from "cordova-back-stack";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularProgressLoader from "../common/CircularProgressLoader";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// import {
//   FormControl,
//   OutlinedInput,
//   InputAdornment,
//   StylesProvider,
// } from "@material-ui/core";
// import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
// import SendIcon from "@material-ui/icons/Send";
// import { errorMessageColor } from "../styles/colors";
// import HistoryIcon from "@material-ui/icons/History";
// import classes from "*.module.css";

import Dialog from "@material-ui/core/Dialog";
// import Avatar from "@material-ui/core/Avatar";
import Slide from "@material-ui/core/Slide";
import InvoiceSVG from "./InvoiceSVG";
import OrderBookingSVG from "./OrderBookingSVG";
import CommerceSVG from "./CommerceSVG";
import PinSVG from "./PinSVG";
import MaskGroupSVG from "./MaskGroupSVG";
import { Avatar } from "@mui/material";
import SnackbarComponent from "../internal/common/SnackbarComponent";
import { checkApiError } from "../../methods";
import SaveButton from "../internal/common/SaveButton";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = {
  loginMainBody: {
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  paperStyle: {
    width: "85%",
    position: "relative",
  },
  textFieldStyle: {
    width: "80%",
  },
  loginButtonStyle: {},
  sentOTPButtonStyle: {
    backgroundColor: "#1B388B",
    color: "white",
    width: "80%",
    padding: "12px 16px",
    borderRadius: "7px",
    marginTop: "20px",
  },
  registerButtonStyle: {
    width: "40%",
    backgroundColor: "white",
    color: secondaryColor,
    fontWeight: "bold",
  },
  exploreButton: {
    color: secondaryColor,
    fontWeight: "bold",
  },
  usOTPButton: {
    color: secondaryColor,
    fontSize: "0.8em",
  },
};
class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      phoneNo: "",
      password: "",
      otp: "",
      newPassword: "",
      newPasswordConfirm: "",
      showUseOTPButton: true,
      phoneError: false,
      mainLoginPage: true,
      otpInvalid: false,
      forgotPasswordUiOpen: false,
      changePasswordCliked: false,
      passwordExpiryNotification: false,
      passwordValid: false,
      userIdError: false,
      passwordError: false,
      timerOn: false,
      timerText: '',
      OTPSecretKey: "",
      OTPValidUntil: "",
      invalidOTPError: false,
      newPasswordLengthError: false,
      newPasswordConfirmLengthError: false,
      snackOpen: false,
      snackbarMessage: '',
      snackbarType: '',
      disabledUseOTPFor360Sec: false,
      ChangePasswordLoading: false,
      benifits: [
        {
          id: "rates",
          title: "Lives Rates",
          subtitle: "24*7 broadcasting live rates",
        },

        {
          id: "booking",
          title: "Contract Booking",
          subtitle: "Book and manage your contract",
        },
        {
          id: "contract",
          title: "Live Contract Tracking",
          subtitle: "Track your contract in real time",
        },

        {
          id: "loading",
          title: "Loading Advice Submission",
          subtitle: "Fast and efficient submission ",
        },
        {
          id: "invoice",
          title: "Automated Invoice and Bill",
          subtitle: "Real time invoice and billing ",
        },

        {
          id: "payment",
          title: "Payment Tracking",
          subtitle: "Track payment in one click",
        },
      ],
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      if (this.state.openAppVersionDialog) {
        // this.setState({ openAppVersionDialog: false });
      } else if (this.state.openFullDialog) {
        this.setState({ openFullDialog: false });
      } else if (this.state.forgotPasswordUiOpen) {
        this.resetAllInputFields();
      } else {
        window.navigator.app.exitApp();
      }
    });
    this.resetAllInputFields()
  }
  componentDidMount() {
    window.addEventListener("keyup", this.onEnterButtonHandler);
    if (window.cordova) {
      if (window.device.platform === "iOS") {
        window.FirebasePlugin.grantPermission((hasPermission) => {
          console.log(
            "Permission was " + (hasPermission ? "granted" : "denied")
          );
        });
      }
      window?.cordova?.plugins?.permissions.hasPermission(window?.cordova?.plugins?.permissions.POST_NOTIFICATIONS, function (status) {
        if (status.hasPermission) {
          console.log("Yes 2 :D ");
        }
        else {
          console.warn("No 2 :( ");
          window.cordova.plugins.permissions.requestPermission(window?.cordova?.plugins?.permissions.POST_NOTIFICATIONS,
            function (status) {
              if (!status.hasPermission) console.warn('POST_NOTIFICATIONS  2 permission is not turned ONn LIne 1');
            },
            console.warn('POST_NOTIFICATIONS 2 permission is not turned on')
          );
        }
      });
    }
    // console.log(localStorage.getItem("currentUrl"), localStorage.getItem("returnUrl"))
    let returnUrl = '/login';
    localStorage.setItem("returnUrl", returnUrl);
    let currentUrl = '/login';
    localStorage.setItem("currentUrl", currentUrl);
    document.addEventListener("backbutton", this.loadOldUrl);
  }

  loadOldUrl = () => {
    this.props.history.push('/')
  }

  timer = (remaining) => {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    this.setState({ timerText: m + ':' + s })
    remaining -= 1;

    if (remaining >= 0 && this.state.timerOn) {
      setTimeout(() => {
        this.timer(remaining)
      }, 1000);
      return;
    }
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      snackOpen: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackOpen: false,
      snackbarMessage: '',
      snackbarType: ''
    })
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  onEnterButtonHandler = (event) => {
    this.setState({
      UnauthorizedUser: false,
      inActiveUser: false,
      noInternetConnection: false,
    });
    if (
      event.keyCode === 13 &&
      this.state.mainLoginPage &&
      this.state.userId === "" &&
      this.state.password === ""
    ) {
      this.setState({ userIdError: true, passwordError: true });
    }
    if (
      event.keyCode === 13 &&
      this.state.mainLoginPage &&
      this.state.userId === ""
    ) {
      this.setState({ userIdError: true });
    }
    if (
      event.keyCode === 13 &&
      this.state.mainLoginPage &&
      this.state.password === ""
    ) {
      this.setState({ passwordError: true });
    }
    if (
      event.keyCode === 13 &&
      this.state.userId !== "" &&
      event.keyCode === 13 &&
      this.state.password !== ""
    ) {
      if (this.state.useOTP && this.state.useOTP) {
        this.handleLoginWithUseOTP();
      } else {
        this.handleLoginWithUserNameAndPassword();
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("keyup", this.onEnterButtonHandler);
  }
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(event.target.name, event.target.value)
    if (event.target.value) {
      if (event.target.name === "newPassword") {
        !this.isPasswordValid(event.target.value) ?
          this.setState({ passwordValid: true, }) : this.setState({ passwordValid: false, })
      }
    }
    if (event.target.name === 'userId') {
      if (this.state.userId !== event.target.value && this.state.disabledUseOTPFor360Sec) {
        this.setState({ disabledUseOTPFor360Sec: false, timerOn: false })
      }
    }
  };
  resetAllInputFields = () => {
    this.setState({
      userId: "",
      phoneNo: "",
      password: "",
      otp: "",
      newPassword: "",
      newPasswordConfirm: "",
      forgotPasswordUiOpen: false,
      forgotPassword: false,
      sentOTP: false,
      confirmOTP: false,
      newPasswordSet: false,
      showUseOTPButton: true,
      noInternetConnection: false,
      phoneNotRegistered: false,
      changePasswordCliked: false,
      passwordExpiryNotification: false,
      passwordValid: false,
      userIdError: false,
      passwordError: false,
      timerOn: false,
      timerText: '',
      disabledResendOTPFor360Sec: false,
      invalidOTPError: false,
      newPasswordLengthError: false,
      newPasswordConfirmLengthError: false
    });
  };

  isPasswordValid = (value) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=[a-zA-Z0-9]*[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
    // const regex = /^(?=([a-zA-Z]*|[0-9]*)+[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
    if (regex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  closeHelpDialog = () => {
    this.setState({ openHelpDialog: false });
  };

  handleClose = () => {
    this.setState({ openFullDialog: false });
  };
  handleClickOpenDialog = () => {
    this.setState({
      openAppVersionDialog: true,
    });
  };

  handleClosePasswordExpiryDialog = () => {
    this.setState({ passwordExpiryNotification: false });
  };

  handleCloseDialog = () => {
    this.setState({ openAppVersionDialog: false });
  };
  clearOTPinput = () => {
    this.setState({ otp: "" });
  };
  handleDisabledUseOTPFor360Sec = () => {
    setTimeout(() => {
      this.setState({ disabledUseOTPFor360Sec: false, timerOn: false });
    }, 360000);
  };
  handleDisabledResendOTPFor360Sec = () => {
    setTimeout(() => {
      this.setState({ disabledResendOTPFor360Sec: false, timerOn: false });
    }, 360000);
  };
  handleLoginWithUserNameAndPassword = () => {
    this.setState({ loginLoader: true });
    if (navigator.onLine === false) {
      this.setState({
        loginLoader: false,
        noInternetConnection: true,
      });
    } else {
      this.props
        .loginWithUsernamePassword(this.state.userId, this.state.password)
        .then((status) => {
          console.log(status);
          if (status === "success") {
            this.props.history.push("/");
            this.setState({ loginLoader: false });
          }
        })
        .catch((e) => {
          if (e?.response?.status === 401) {
            this.setState({ UnauthorizedUser: true });
            this.setState({ loginLoader: false });
          }
          if (e?.response?.status === 403) {
            this.setState({ inActiveUser: true });
            this.setState({ loginLoader: false });
          }
          // if (e?.response?.status === 406) {
          //   console.log(e.response.data.msg)
          //   this.setState({
          //     passwordExpiryNotification: true,
          //     sendOTPLoader: false,
          //     loginLoader: false,
          //   });
          // }
        });
    }
  };
  handleLoginWithUseOTP = () => {
    if (navigator.onLine === false) {
      this.setState({
        loginLoader: false,
        noInternetConnection: true,
      });
    } else {
      this.setState({ loginLoader: true }, () => {
        // this.props.loginWithPhoneNumber(this.state.userId, this.state.password)
        let data = {
          credential: this.state.userId,
          otp: this.state.password,
          otp_secret_key: this.state?.OTPSecretKey,
          otp_valid_until: this.state?.OTPValidUntil
        }
        this.props
          .verifyMobileUseOtp(data)
          .then(response => {
            if (response === 'success') {
              this.setState({ loginLoader: false });
              this.props.history.push("/");
            }
            else if (response['response']['status'] === 400) {
              this.setState({ otpInvalid: true, otpInvalid: response.response?.data?.msg, });
              this.setState({ loginLoader: false });
            }
          })
          .catch((e) => {
            console.log(e)
            if (e?.status === 401 || e.status === 400) {
              this.setState({ otpInvalid: true, otpInvalid: e.response?.data.msg, });
              this.setState({ loginLoader: false });
            }
            if (e?.response?.status === 403) {
              this.setState({ inActiveUser: true });
              this.setState({ loginLoader: false });
            }
          });
      });
    }
  };
  handleGetUseOTP = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.setState({
        useOTP: true,
        useOTPCliked: true,
        timerOn: true,
        password: "",
        UnauthorizedUser: false,
        inActiveUser: false
      });
      // this.props.getMobileOTP(this.state.userId)
      this.props
        .getMobileUseOtp(this.state.userId)
        .then(response => {
          if (response.status === 200) {
            this.setState(
              {
                useOTPCliked: false,
                disabledUseOTPFor360Sec: true,
                OTPSecretKey: response.data?.otp_secret_key,
                OTPValidUntil: response.data?.otp_valid_until,
              },
              () => {
                this.handleDisabledUseOTPFor360Sec()
                this.timer(360)
              }
            )
          }
        })
        .catch((error) => {
          console.log(error)
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
          this.setState({
            // invalidOTPError: error.response?.data,
            useOTPCliked: false,
            useOTP: false,
          });
        });
    }
  };
  handleSendOTPforForgotPassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
        sendOTPLoader: false
      });
    } else {
      if (this.state.timerOn === true) {
        this.setState({ timerOn: false });
      }
      this.setState({ sendOTPLoader: true, timerOn: true });
      // this.props.sendOtpForForgotPassword(this.state.phoneNo)
      this.props.getMobileOTPForgetPassword(this.state.phoneNo)
        .then((response) => {
          console.log(response.response)
          if (response.status === 200) {
            this.setState(
              {
                sentOTP: true,
                forgotPassword: false,
                sendOTPLoader: false,
                OTPSecretKey: response.data?.otp_secret_key,
                OTPValidUntil: response.data?.otp_valid_until,
                disabledResendOTPFor360Sec: true,
              },
              () => {
                this.handleDisabledResendOTPFor360Sec()
                this.timer(360)
              }
            );
          }
          else if ([400, 401].includes(response.response.status)) {
            this.setState({ phoneError: response?.response?.data?.msg, sendOTPLoader: false })
          }
          else {
            this.setState({
              sendOTPLoader: false,
            })
          }
        })
        .catch((error) => {
          console.log(error)
          if (error?.response?.status === 400) {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 4000)
            this.setState({
              sendOTPLoader: false,
            });
          }
          else {
            this.setState({
              sendOTPLoader: false,
            })
          }
        });
    }
  };
  handleResendOTPforForgotPassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.setState({
        invalidOTPError: false,
        resendOTPLoader: true,
      });
      this.clearOTPinput();
      // this.props.sendOtpForForgotPassword(this.state.phoneNo)
      this.props.getMobileOTPForgetPassword(this.state.phoneNo)
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                sentOTP: true,
                forgotPassword: false,
                resendOTPLoader: false,
                OTPSecretKey: response.data?.otp_secret_key,
                OTPValidUntil: response.data?.otp_valid_until,
                disabledResendOTPFor360Sec: true,
              },
              () => {
                this.handleDisabledResendOTPFor360Sec()
                this.timer(360)
              }
            );
          }
          else if ([400, 401].includes(response.response.status)) {
            this.setState({ phoneError: response?.response?.data?.msg })
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.setState({
              phoneNotRegistered: true,
              resendOTPLoader: false,
            });
          }
        });
    }
  };
  handleVerifyOTPforForgotPassword = () => {
    this.setState({
      userIdError: false,
      invalidOTPError: false,
      passwordError: false,
      passwordDidNotMatch: false,
      newPasswordLengthError: false,
      newPasswordConfirmLengthError: false
    })
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      let data = {
        credential: this.state.phoneNo,
        otp: this.state.otp,
        otp_secret_key: this.state?.OTPSecretKey,
        otp_valid_until: this.state?.OTPValidUntil
      }
      // this.props.verifyOtpForForgotPassword(this.state.phoneNo, this.state.otp)
      this.props.verifyMobileOTPForgetPassword(data)
        .then((response) => {
          if (response.status === 200 || response === 'success') {
            this.setState({
              confirmOTP: true,
              sentOTP: false,
              disabledResendOTPFor360Sec: false,
              timerOn: false,
              passwordDidNotMatch: false,
              userIdError: false,
              invalidOTPError: false,
              passwordError: false,
              newPasswordLengthError: false,
              newPasswordConfirmLengthError: false
            });
          }
          else if (response['response']['status'] === 400) {
            this.setState({ invalidOTPError: true, invalidOTPError: response.response?.data?.msg, userIdError: false });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.setState({ invalidOTPError: true });
          }
        });
    }
  };
  handleChangePasswordFromForgotPassword = () => {
    this.setState({
      passwordError: false,
      UnauthorizedUser: false,
      otpInvalid: false,
      invalidOTPError: false,
      passwordDidNotMatch: false,
      newPasswordConfirmLengthError: false,
      inActiveUser: false,
      userIdError: false,
    })
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.setState({ ChangePasswordLoading: true })
      this.props
        .changePasswordFromForgotPassword(
          this.state.newPassword,
          this.state.phoneNo
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              newPasswordSet: true,
              confirmOTP: false,
              showUseOTPButton: true,
              passwordError: false,
              UnauthorizedUser: false,
              otpInvalid: false,
              invalidOTPError: false,
              passwordDidNotMatch: false,
              newPasswordConfirmLengthError: false,
              inActiveUser: false,
              userIdError: false,
              ChangePasswordLoading: false
            });
          }
          else if ([400, 401].includes(response.response.status)) {
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(response?.response?.data),
              "error"
            );
            setTimeout(() => {
              this.setState({ ChangePasswordLoading: false })
              this.handleCloseSnackbar();
            }, 4000)
            // this.setState({ phoneError: response?.response?.data || response?.response?.data?.msg })
          }
          else {
            this.setState({ ChangePasswordLoading: false })
          }
        })
        .catch((error) => {
          this.setState({
            passwordError: error?.response?.data?.msg,
            ChangePasswordLoading: false
          })
          console.log("er", error.response);
        });
    }
  };

  showWebPage = () => {
    this.setState({ openAppVersionDialog: false })
    let options = "location=no,hidden=no,hidenavigationbuttons=yes,hideurlbar=yes";
    let inAppBrowserRef = window.cordova?.InAppBrowser?.open("https://www.realgroup.org", '_blank', options)
    inAppBrowserRef.addEventListener('loadstart', function () {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SpinLoader />
          <h4> Loading...</h4>
        </div>
      )
    });
    inAppBrowserRef.addEventListener('loadstop', function () {
      inAppBrowserRef.show()
    });
    inAppBrowserRef.addEventListener('loaderror', function (params) {
      alert(`Sorry we cannot open that page. Error : ${params.message}  `)
    })
    // inAppBrowserRef.addEventListener("backbutton", function () {
    //   this.props.history.push("/")
    // })
  }

  render() {
    // AFTER NEW SCREEN BELOW---------------------
    const {
      forgotPassword,
      phoneError,
      sentOTP,
      confirmOTP,
      newPasswordSet,
      benifits,

      userId,
      phoneNo,
      password,
      otp,
      //handleTextChange,
      newPassword,
      newPasswordConfirm,

      userIdError,
      passwordError,
      showUseOTPButton,
      mainLoginPage,
      useOTP,
      UnauthorizedUser,
      otpInvalid,
      phoneNotRegistered,
      invalidOTPError,
      newPasswordLengthError,
      loginLoader,
      passwordDidNotMatch,
      newPasswordConfirmLengthError,
      inActiveUser,
      forgotPasswordUiOpen,
      noInternetConnection,
      passwordValid,
      ChangePasswordLoading
    } = this.state;
    // AFTER NEW SCREEN UPER---------------------
    const {
      //handleChange,
      //userId,
      // password,
      //sendOtp,
      // AFTER NEW SCREEN ---------------------
      classes,
      // userId,
      // phoneNo,
      // password,
      // otp,
      // handleTextChange,
      // newPassword,
      // newPasswordConfirm,
    } = this.props;
    // console.log(password)
    return (
      <div className={classes.loginMainBody}>
        <div className="login-header-image">
          <img
            src={
              window.cordova
                ? `${this.image}${"/RealDealWeb2.png"}`
                : "/RealDealWeb2.png"
            }
            alt="img"
            height="90px"
            width="auto"
            style={{
              marginTop: 15,
              marginBottom: 15
            }}
          />
          <span style={{ color: bottomNavigationFontColor }}>
            Online portal for faster deals
          </span>
        </div>
        <br />

        <div className="login-form-container">
          <Paper elevation={1} className={classes.paperStyle}>
            <div
              className="login-text-div"
              style={{
                visibility: newPasswordSet ? "hidden" : "visible",
                minHeight: confirmOTP && 120
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: forgotPasswordUiOpen && 'flex-start',
                  width: forgotPasswordUiOpen && '90%'
                }}
              >
                {forgotPasswordUiOpen ? (
                  <span
                    style={{
                      marginRight: 15
                    }}
                  >
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.resetAllInputFields()}
                    >
                      <ArrowBack />
                    </IconButton>
                  </span>
                ) : null}
                <span style={{ color: secondaryDarkColor, fontSize: "1.5em" }}>
                  {this.state.changePasswordCliked ?
                    "Change Password"
                    : forgotPassword || sentOTP
                      ? "Forgot Password"
                      : confirmOTP
                        ? "Change Password"
                        : "Login"}
                </span>
              </div>
              {forgotPassword || sentOTP || confirmOTP ? (
                <span style={{
                  color: bottomNavigationFontColor,
                  width: confirmOTP && '85%'
                }}>
                  {confirmOTP
                    ? "Password should be 8 to 15 characters, must include minimum 1 character, minimum 1 digit and a special character ( # . @ _ )"
                    : "Verify your email / mobile to set a new password"}
                </span>
              ) : null}

              <span
                style={{
                  color: "red",
                  textAlign: "center",
                  textDecoration: "capitalize",
                }}
              >
                {UnauthorizedUser
                  ? "Invalid Username or Password !!"
                  : inActiveUser
                    ? "Activation is pending for this account, please contact the admin"
                    : noInternetConnection
                      ? "No Internet Connection"
                      : null}
              </span>
            </div>
            <div className="login-form-start">
              {forgotPassword ? (
                <TextField
                  name="phoneNo"
                  id="outlined-name"
                  label="Email / Mobile"
                  margin="normal"
                  variant="outlined"
                  value={phoneNo}
                  // type="tel"
                  // inputProps={{
                  //   maxLength: 10,
                  // }}
                  // onKeyPress={(event) => {
                  //   if (event.charCode >= 48 && event.charCode <= 57) {
                  //     // let it happen, don't do anything
                  //   }
                  //   else { event.preventDefault(); }
                  // }}
                  error={
                    forgotPassword && phoneError
                      ? true
                      : phoneNotRegistered
                        ? true
                        : false
                  }
                  helperText={
                    <span style={{ color: "red" }}>
                      {phoneError
                        ? phoneError
                        : null}
                    </span>
                  }
                  className={classes.textFieldStyle}
                  onChange={this.handleTextChange}
                  onFocus={() => {
                    this.setState({ phoneNotRegistered: false });
                    if (forgotPassword) {
                      this.setState({ phoneError: false });
                    }
                  }}
                  InputProps={{
                    endAdornment: sentOTP ? (
                      <InputAdornment position="end">
                        <Button
                          //className={classes.usOTPButton}
                          onClick={(e) => {
                            e.preventDefault();
                            // alert("cliked");
                          }}
                        >
                          Resend OTP
                        </Button>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {newPasswordSet ? (
                    <div className="login-create-new-password-susscessfull-container">
                      <span className="login-change-password-text">
                        Password Changed
                      </span>
                      <span className="login-change-password-text">
                        Susscessfully
                      </span>
                    </div>
                  ) : (
                    <TextField
                      name={confirmOTP ? "newPassword" : "userId"}
                      id={
                        sentOTP ? "standard-read-only-input" : "outlined-name"
                      }
                      type={confirmOTP ? "password" : "text"}
                      label={
                        sentOTP
                          ? "Email / Mobile"
                          : confirmOTP
                            ? "New Password"
                            : "Email / Mobile / UID"
                      }
                      FormHelperTextProps={{
                        style: { marginLeft: "0px", marginRight: "0px" },
                      }}
                      helperText={
                        <>
                          {<span style={{ color: "red" }}>
                            {confirmOTP && passwordError
                              ? passwordError
                              : newPasswordLengthError
                                ? "Please enter minimum 6 character"
                                : null}
                          </span>
                          }
                          {
                            this.state?.disabledResendOTPFor360Sec
                              ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ color: "green", display: 'flex', alignItems: 'center' }}>
                                  <TaskAltIcon style={{ transform: 'scale(0.8)' }} />  OTP Sent Successfully
                                </span>
                                <span style={{ color: "green" }}>
                                  {this.state?.timerText}
                                </span>
                              </div>
                              : null
                          }
                        </>
                      }
                      error={
                        userIdError
                          ? true
                          : false
                      }
                      onFocus={() => {
                        this.clearOTPinput();
                        this.setState({
                          userIdError: false,
                          useOTP: false,
                          UnauthorizedUser: false,
                          newPasswordLengthError: false,
                          passwordDidNotMatch: false,
                          inActiveUser: false,
                        });
                      }}
                      margin="normal"
                      variant="outlined"
                      value={
                        sentOTP
                          ? phoneNo
                          : confirmOTP
                            ? newPassword
                            : userId.toLowerCase()
                      }
                      className={classes.textFieldStyle}
                      onChange={this.handleTextChange}
                      InputProps={{
                        endAdornment: sentOTP ? (
                          <InputAdornment position="end">
                            {this.state.resendOTPLoader ? (
                              <CircularProgressLoader />
                            ) : (
                              <Button
                                disabled={this.state.disabledResendOTPFor360Sec}
                                className={classes.usOTPButton}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    noInternetConnection: false,
                                  });
                                  this.handleResendOTPforForgotPassword();
                                }}
                              >
                                RESEND OTP
                              </Button>
                            )}
                          </InputAdornment>
                        ) : null,
                      }}
                    />
                  )}
                  {confirmOTP ? (
                    <div className="login-strong-password-div">
                      <span style={{ color: bottomNavigationFontColor }}>
                        Password strength
                      </span>
                      <span
                        style={{
                          backgroundColor:
                            newPassword &&
                              newPassword.length >= 2 && !passwordValid
                              ? "#c6d430"
                              : newPassword.length >= 2
                                ? "#e68948"
                                // : newPassword &&
                                //   newPassword.length >= 4 &&
                                //   newPassword.length < 6
                                //   ? "#4986c2"
                                //   // : newPassword && newPassword.length >= 6
                                //   : passwordValid
                                // ? "#c6d430"
                                : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        style={{
                          backgroundColor:
                            newPassword &&
                              newPassword.length >= 4 && !passwordValid
                              ? "#c6d430"
                              : newPassword.length >= 4
                                ? "#e68948"
                                // : newPassword && !passwordValid
                                // : newPassword &&
                                //   newPassword.length >= 5 &&
                                //   newPassword.length < 7
                                //   ? "#4986c2"
                                //   // : newPassword && newPassword.length >= 7
                                //   : passwordValid
                                // ? "#c6d430"
                                : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        style={{
                          backgroundColor:
                            newPassword &&
                              newPassword.length >= 8 && !passwordValid
                              ? "#c6d430"
                              : newPassword.length >= 8
                                ? "#e68948"
                                // newPassword.length >= 4 &&
                                // newPassword.length < 6
                                // ? "#e68948"
                                // : newPassword &&
                                //   newPassword.length >= 6 &&
                                //   newPassword.length < 8
                                //   ? "#4986c2"
                                //   // : newPassword && newPassword.length >= 8
                                //   : passwordValid
                                // ? "#c6d430"
                                : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        style={{
                          backgroundColor:
                            newPassword &&
                              newPassword && !passwordValid
                              // newPassword.length >= 5 &&
                              // newPassword.length < 7
                              // ? "#e68948"
                              // : newPassword &&
                              //   newPassword.length >= 7 &&
                              //   newPassword.length < 9
                              //   ? "#4986c2"
                              //   // : newPassword && newPassword.length >= 9
                              //   : passwordValid
                              ? "#c6d430"
                              : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              )}
              {forgotPassword ? (
                this.state.sendOTPLoader ? (
                  <div>
                    <SpinLoader />
                  </div>
                ) : (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        { cliked: true, noInternetConnection: false },
                        () => {
                          // if (phoneNo.length === 10) {
                          this.handleSendOTPforForgotPassword();
                          // }
                        }
                      );
                    }}
                    variant="contained"
                    className={classes.sentOTPButtonStyle}
                    style={{
                      backgroundColor: this.state.cliked
                        ? "#1B388B"
                        : " #1B388B",
                    }}
                  >
                    Send OTP
                  </Button>
                )
              ) : newPasswordSet ? null : (
                <TextField
                  disabled={this.state.useOTPCliked}
                  name={
                    sentOTP
                      ? "otp"
                      : confirmOTP
                        ? "newPasswordConfirm"
                        : "password"
                  }
                  inputProps={{
                    maxLength: (useOTP && otpInvalid === false) && 6,
                  }}
                  FormHelperTextProps={{
                    style: { marginLeft: "0px", marginRight: "0px" },
                  }}
                  error={
                    passwordError
                      ? true
                      : otpInvalid
                        ? true
                        : invalidOTPError
                          ? true
                          : newPasswordConfirmLengthError
                            ? true
                            : false
                  }
                  onFocus={() => {
                    this.setState({
                      passwordError: false,
                      UnauthorizedUser: false,
                      otpInvalid: false,
                      invalidOTPError: false,
                      passwordDidNotMatch: false,
                      newPasswordConfirmLengthError: false,
                      inActiveUser: false,
                    });
                  }}
                  helperText={
                    <>
                      {<span style={{ color: "red" }}>
                        {invalidOTPError
                          ? invalidOTPError ? invalidOTPError : "Invalid OTP"
                          : newPasswordConfirmLengthError
                            ? "Please enter minimum 6 character"
                            : passwordDidNotMatch
                              ? "Password didn't match"
                              : null}
                      </span>
                      }
                      {
                        this.state?.disabledUseOTPFor360Sec
                          ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ color: "green", display: 'flex', alignItems: 'center' }}>
                              <TaskAltIcon style={{ transform: 'scale(0.8)' }} />  OTP Sent Successfully
                            </span>
                            <span style={{ color: "green" }}>
                              {this.state?.timerText}
                            </span>
                          </div>
                          : null
                      }
                    </>
                  }
                  InputProps={{
                    endAdornment: !showUseOTPButton ? null : (
                      <InputAdornment position="end">
                        {this.state.useOTPCliked ? (
                          <CircularProgress
                            style={{
                              color: "#4b87c5",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        ) : (
                          <Button
                            disabled={
                              // this.state.userId.length < 10 ||
                              this.state.disabledUseOTPFor360Sec
                            }
                            className={classes.usOTPButton}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                noInternetConnection: false,
                              });
                              if (this.state.userId.length < 10) {
                                this.handleOpenSnackbar('Invalid email of phone number', 'warning')
                              }
                              else { this.handleGetUseOTP(); }
                            }}
                          >
                            USE OTP
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-name"
                  label={
                    sentOTP
                      ? "OTP"
                      : confirmOTP
                        ? "Confirm Password"
                        : useOTP && otpInvalid === false
                          ? "Enter OTP"
                          : otpInvalid
                            ? otpInvalid || "Invalid OTP"
                            : "Password"
                  }
                  value={
                    sentOTP ? otp : confirmOTP ? newPasswordConfirm : password
                  }
                  type={sentOTP ? "number" : "password"}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldStyle}
                  onChange={this.handleTextChange}
                />
              )}
            </div>
            {sentOTP || confirmOTP || newPasswordSet ? null : (
              <div
                className="login-forgot-password-div"
                style={{ visibility: forgotPassword ? "hidden" : "visible" }}
              >
                <span className="login-forgot-password-text">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        forgotPassword: true,
                        userIdError: false,
                        passwordError: false,
                        showUseOTPButton: false,
                        mainLoginPage: false,
                        useOTP: false,
                        otpInvalid: false,
                        userId: "",
                        password: "",
                        forgotPasswordUiOpen: true,
                        UnauthorizedUser: false,
                      });
                    }}
                    style={{
                      color: secondaryColor,
                      fontSize: "1.1em",
                      padding: "0px",
                    }}
                  >
                    FORGOT PASSWORD
                  </Button>
                </span>
              </div>
            )}

            <div
              className="login-button-div"
              style={{ visibility: forgotPassword ? "hidden" : "visible" }}
            >
              {loginLoader ? (
                <div
                  style={{
                    height: "58px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SpinLoader />
                </div>
              ) : (
                <SaveButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      UnauthorizedUser: false,
                      inActiveUser: false,
                      noInternetConnection: false,
                    });
                    if (
                      mainLoginPage &&
                      this.state.userId === "" ||
                      this.state.password === ""
                    ) {
                      this.setState({
                        userIdError: this.state.userId === "" ? true : false,
                        passwordError: this.state.password === "" ? true : false
                      });
                    }
                    if (
                      this.state.userId !== "" &&
                      this.state.password !== ""
                    ) {
                      if (useOTP && useOTP) {
                        this.handleLoginWithUseOTP();
                      } else {
                        this.handleLoginWithUserNameAndPassword();
                      }
                    }

                    this.setState({ loginButtonCliked: true }, () => {
                      if (sentOTP) {
                        this.handleVerifyOTPforForgotPassword();
                      }
                      if (confirmOTP) {
                        if (this.state.newPassword.length < 6) {
                          this.setState({ newPasswordLengthError: true });
                        }
                        if (this.state.newPasswordConfirm.length < 6) {
                          this.setState({
                            newPasswordConfirmLengthError: true,
                          });
                        }
                        if (
                          this.state.newPassword !==
                          this.state.newPasswordConfirm
                        ) {
                          this.setState({ passwordDidNotMatch: true });
                        }
                        if (
                          this.state.newPassword.length >= 6 &&
                          this.state.newPasswordConfirm.length >= 6 &&
                          this.state.newPassword ===
                          this.state.newPasswordConfirm
                        ) {
                          this.handleChangePasswordFromForgotPassword();
                        }
                      }
                      if (newPasswordSet) {
                        this.setState(
                          {
                            newPasswordSet: false,
                            showUseOTPButton: true,
                            passwordError: false,
                            UnauthorizedUser: false,
                            otpInvalid: false,
                            invalidOTPError: false,
                            passwordDidNotMatch: false,
                            newPasswordConfirmLengthError: false,
                            inActiveUser: false,
                            userIdError: false,
                          },
                          () => {
                            this.resetAllInputFields();
                          }
                        );
                      }
                    });
                  }}
                  style={{
                    backgroundColor: this.state.loginButtonCliked
                      ? "#1B388B"
                      : " #1B388B",
                    fontWeight: "bold",
                    color: "white",
                    width: newPasswordSet ? "70%" : "80%",
                    padding: "12px 16px",
                    borderRadius: "7px",
                    marginTop: newPasswordSet ? "0px" : "10px",
                    marginBottom: newPasswordSet ? "10px" : "0px",
                  }}
                  variant="contained"
                  isLoading={ChangePasswordLoading}
                >
                  {sentOTP || confirmOTP ? "Confirm" : " Login "}
                </SaveButton>
              )}
            </div>
            <br />
            <div className="login-logo-container">
              <img
                src={window.cordova ? `${this.image}${"/gk.png"}` : "/gk.png"}
                alt="img"
                height="50px"
                width="auto"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    UnauthorizedUser: false,
                    inActiveUser: false,
                    noInternetConnection: false,
                  });
                  if (
                    mainLoginPage &&
                    this.state.userId === "" &&
                    this.state.password === ""
                  ) {
                    this.setState({ userIdError: true, passwordError: true });
                  }
                  if (mainLoginPage && this.state.userId === "") {
                    this.setState({ userIdError: true });
                  }
                  if (mainLoginPage && this.state.password === "") {
                    this.setState({ passwordError: true });
                  }
                  if (this.state.userId !== "" && this.state.password !== "") {
                    if (useOTP && useOTP) {
                      this.handleLoginWithUseOTP();
                    } else {
                      this.handleLoginWithUserNameAndPassword();
                    }
                  }

                  // this.setState({ loginButtonCliked: true }, () => {
                  //   if (sentOTP) {
                  //     this.setState({ confirmOTP: true, sentOTP: false });
                  //   }
                  //   if (confirmOTP) {
                  //     this.setState({
                  //       newPasswordSet: true,
                  //       confirmOTP: false,
                  //     });
                  //   }
                  //   if (newPasswordSet) {
                  //     this.setState(
                  //       { newPasswordSet: false, showUseOTPButton: true },
                  //       () => {
                  //         this.resetAllInputFields();
                  //       }
                  //     );
                  //   }
                  // });
                }}
              />
              <img
                src={
                  window.cordova
                    ? `${this.image}${"/realwire.png"}`
                    : "/realwire.png"
                }
                alt="img"
                height="50px"
                width="auto"
                style={{ marginLeft: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    UnauthorizedUser: false,
                    inActiveUser: false,
                    noInternetConnection: false,
                  });
                  if (
                    mainLoginPage &&
                    this.state.userId === "" &&
                    this.state.password === ""
                  ) {
                    this.setState({ userIdError: true, passwordError: true });
                  }
                  if (mainLoginPage && this.state.userId === "") {
                    this.setState({ userIdError: true });
                  }
                  if (mainLoginPage && this.state.password === "") {
                    this.setState({ passwordError: true });
                  }
                  if (this.state.userId !== "" && this.state.password !== "") {
                    if (useOTP && useOTP) {
                      this.handleLoginWithUseOTP();
                    } else {
                      this.handleLoginWithUserNameAndPassword();
                    }
                  }

                  // this.setState({ loginButtonCliked: true }, () => {
                  //   if (sentOTP) {
                  //     this.setState({ confirmOTP: true, sentOTP: false });
                  //   }
                  //   if (confirmOTP) {
                  //     this.setState({
                  //       newPasswordSet: true,
                  //       confirmOTP: false,
                  //     });
                  //   }
                  //   if (newPasswordSet) {
                  //     this.setState(
                  //       { newPasswordSet: false, showUseOTPButton: true },
                  //       () => {
                  //         this.resetAllInputFields();
                  //       }
                  //     );
                  //   }
                  // });
                }}
              />
            </div>
            <br />
            <div
              className="login-dont-have-account"
              onClick={() => this.props.history.push("/register")}
            >
              <span style={{ color: bottomNavigationFontColor }}>
                Don't have an account ?
              </span>
            </div>
            <br />
            <br />
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                float: "right",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  console.log('clicked')
                  this.setState({ openAppVersionDialog: true })
                }
                }
              >
                <Help />
              </IconButton>
            </div>
            <Dialog
              open={this.state.openAppVersionDialog}
              PaperProps={{
                style: {
                  width: "90%",
                  height: 360,
                  borderRadius: "20px",
                },
              }}
              TransitionComponent={Transition}
              onClose={this.handleCloseDialog}
            >
              <div>
                <div
                  style={{
                    height: "7vh",
                    background: "#9e9e9e70",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0px 30px",
                  }}
                >
                  <span style={{ fontSize: "1.2em" }}>Contact Us</span>
                  <span>
                    <Close onClick={this.handleCloseDialog} />
                  </span>
                </div>
                <div
                  style={{
                    height: "30vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Avatar
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                    >
                      <Phone />
                    </Avatar>
                    <span style={{ marginLeft: "20px" }}>+91 771 4224 000</span>
                  </div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Avatar
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                    >
                      <Email />
                    </Avatar>
                    <span style={{ marginLeft: "20px" }}>sales@realdeals.pro</span>
                  </div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Avatar
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                    >
                      <Language />
                    </Avatar>

                    <a
                      style={{ marginLeft: "20px" }}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        window.cordova
                          ? this.showWebPage()
                          : window.open("https://www.realgroup.org", '_system')
                      }}
                    // href="https://www.realgroup.org"
                    >
                      www.realgroup.org
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StaticVersionDisplay />
                </div>
              </div>
            </Dialog>
            <div className="login-register-button-container">
              <Fab
                onClick={() => this.props.history.push("/register")}
                variant="extended"
                aria-label="Delete"
                className={classes.registerButtonStyle}
              >
                REGISTER
              </Fab>
            </div>
          </Paper>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <Button
            style={{ color: secondaryColor, fontWeight: "bold" }}
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/enquiry");
            }}
          >
            Get Quote
          </Button>
        </div>

        <div className="login-benifits-button-container">
          <Button
            className={classes.exploreButton}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ openFullDialog: true });
            }}
          >
            Explore Benefits <ArrowDropDown />
          </Button>
        </div>
        <Dialog
          PaperProps={{
            style: {
              background:
                "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
              opacity: 1,
            },
          }}
          fullScreen
          open={this.state.openFullDialog}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div>
            <div className="login-header-image">
              <img
                src={
                  window.cordova
                    ? `${this.image}${"/RealDealWeb2.png"}`
                    : "/RealDealWeb2.png"
                }
                alt="img"
                height="70px"
                width="auto"
                style={{
                  marginTop: 15,
                  marginBottom: 15
                }}
              />
            </div>
            <div className="login-explore-container">
              {benifits.map((data) => (
                <div className="login-explore-list">
                  <Avatar
                    style={{
                      height: "45px",
                      width: "50px",
                      borderRadius: "10%",
                      backgroundColor: primaryColor,
                    }}
                    alt="Remy Sharp"
                  >
                    {data.id === "rates" ? (
                      <MaskGroupSVG />
                    ) : data.id === "booking" ? (
                      <OrderBookingSVG />
                    ) : data.id === "invoice" ? (
                      <InvoiceSVG />
                    ) : data.id === "loading" ? (
                      <MaskGroupSVG />
                    ) : data.id === "contract" ? (
                      <PinSVG />
                    ) : data.id === "payment" ? (
                      <CommerceSVG />
                    ) : null}
                  </Avatar>
                  <div className="login-explore-list-item">
                    <span className="login-explore-title-style">
                      {data.title}
                    </span>
                    <span className="login-explore-subtitle-style">
                      {data.subtitle}
                    </span>
                  </div>
                </div>
              ))}
              <div className="login-benifits-button-container">
                <Button
                  className={classes.exploreButton}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ openFullDialog: false });
                  }}
                >
                  Back To Login <ArrowDropUp />
                </Button>
                <br />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          onClose={this.handleClosePasswordExpiryDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.passwordExpiryNotification}
          PaperProps={{
            style: {
              width: "90vw",
              height: "20vh",
              borderRadius: "20px",
              alignItems: 'flex-start'
            },
          }}
        >
          <div
            style={{
              padding: '10px 30px'
            }}
          >
            <p>
              Your Password is Expired!  Please Click Below Button to Change the Password.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1B388B",
                  fontWeight: "bold",
                  color: "white",
                  width: "68%",
                  padding: "10px 10px",
                  borderRadius: "7px",
                  // marginTop: "20px",
                }}
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    passwordExpiryNotification: false,
                    forgotPassword: true,
                    forgotPasswordCliked: true,
                    changePasswordCliked: true,
                    userIdError: false,
                    passwordError: false,
                    useOTP: false,
                    otpInvalid: false,
                    UnauthorizedUser: false,
                    userId: "",
                    password: "",
                    inActiveUser: false,
                    sendOTPLoader: false,
                    loginLoader: false,
                  });
                }}
              >
                Change Password
              </Button>
            </div>
          </div>
        </Dialog>
        <SnackbarComponent
          open={this.state?.snackOpen}
          handleClose={this.handleCloseSnackbar}
          customAutoHideDuration={3000}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
        />
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(LoginComponent));
