import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SnackbarComponent from "../../common/SnackbarComponent";
import { styled } from "@material-ui/styles";
import SvgIcon from '@mui/material/SvgIcon';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Divider from "@material-ui/core/Divider";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import Colors, {
  grey,
  secondaryGrey,
  secondaryBlueText,
  primaryColor,
  drawerTextColor,
  earthBlue,
  drawerBackground,
  successButtonBackground,
  lightGreyColor,
  bottomNavigationFontColor,
  whiteSmoke,
} from "../../../constants/internal/colors";
import { formHelperTextClasses } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@material-ui/core/TextField";
import COLORS from "../../../constants/internal/colors";
import { pxToEm } from '../../../methods';
import WeighbridgeItemTable from "./WeighbridgeItemTable";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiStepper-root": {
      padding: 0
    }
  },
  cancelButton: {
    marginRight: "10px",
    marginBottom: "20px",
  },
  saveButton: {
    backgroundColor: "#293C79",
    color: drawerTextColor,
    marginRight: "10px",
    marginBottom: "20px",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  helperText: {
    position: "absolute",
    top: pxToEm(45),
  },

  stepIconRoot: {
    "&.MuiStepIcon-completed": {
      color: "#293C79",
    },
    "&.MuiStepIcon-active": {
      color: "#293C79",
    },
    "&.MuiStepIcon-disabled": {
      color: "grey",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200
  },
  "& .MuiStepper-root": {
    padding: 0
  }
  // selectAllText: {
  //   fontWeight: 500
  // },
  // selectedAll: {
  //   backgroundColor: "rgba(0, 0, 0, 0.08)",
  //   "&:hover": {
  //     backgroundColor: "rgba(0, 0, 0, 0.08)"
  //   }
  // }
}));

const StyledStepLabel = styled(StepLabel)({
  "& .MuiStepLabel-active": {
    color: "#293C79",
    fontWeight: "bold",
  },
});

const unloadingSteps = [
  {
    label: 'LA Cancelled',
  },
  {
    label: 'Unloading Weight',
    description:
      'Take unloaoding weights.',
  },
  {
    label: 'Unloading Tare Weight',
    description: `Take Unloading tare weight.`,
  },
  {
    label: 'Mark As Unloaded.',
    //description: `take unloaded weight.`,
  },
];


function VerticalLinearStepper({
  weights,
  handleStepperCaptureWeight,
  capturedWeight,
  capturedCurrentWeight,
  handleCancelButton,
  handleUpdateWeighing,
  handleUpdateUnloadWeighing,
  handleUnloadFinish,
  updated,
  tareWeightStatus,
  totalWeightsTaken,
  markedAsPacked,
  handleFinish,
  dispatchStatus,
  allowTakingWeights,
  currentStep,
  initialiseStepperCount,
  tareWeightDetails,
  isWeightCaptured,
  unloadedWeight,
  unloadedTareWeight,
  isPacked,
  loadingAdviceWeighings,
  allWeighings,
  handleIndividualItemValidation,
  handleResetStep,
  movetoPack,
  itemCategoryDetails
}) {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [disableStepItem, setDisableStepItem] = React.useState(false);
  const [steps, setSteps] = React.useState([]);
  const [isTareWeightCaptured, setIsTareWeightCaptured] =
    React.useState(tareWeightStatus);
  const [buttonActions, setButtonActions] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] =
    React.useState("Submitted Successfully");
  const [snackbarType, setSnackbarType] = React.useState("success");
  const [visible, setVisible] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [firstQuantity, setFirstQuantity] = React.useState(null);
  const [itemCount, setItemCount] = React.useState([]);
  const [allotedWeight, setAllotedWeight] = React.useState(0);
  const [weightToAllot, setWeightToAllot] = React.useState(0);
  const [bundleError, setBundleError] = React.useState([]);
  const [bundleToleranceErr, setBundleToleranceErr] = React.useState([]);
  // const [stepSelectedItems, setStepSelectedItems] = React.useState([]);

  const handleChange = (step, index, data) => {
    let newSelected = [];
    if (selected[index]) {
      newSelected = [...selected];
      newSelected[index] = { ...newSelected, item: data };
      setSelected(newSelected);

    } else {
      newSelected = [...selected, { item: data, item_qty: null, actual_section_count: null }];
      setSelected(newSelected);
    }
    // let stepItems = stepSelectedItems[step - 1];
    // if (!stepItems) {
    //   stepItems = [newSelected]
    //   setStepSelectedItems(stepItems)
    // } else {
    //   const stepsSelectedItems = [...stepSelectedItems];
    //   stepsSelectedItems[step - 1] = newSelected;
    //   setStepSelectedItems(stepsSelectedItems);
    // }
  };

  const handleReset = (step) => {
    setSelected([]);
    setItemCount([]);
    setAllotedWeight(0);
    setWeightToAllot(0);
    setDisableStepItem(false);
    const currentActions = [...buttonActions];
    currentActions[step] = false;
    setButtonActions(currentActions);
    handleResetStep();
    setError([]);
    setBundleError([]);
    setBundleToleranceErr([]);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSnackbarMessage(null);
    setSnackbarType("");
  };

  React.useEffect(() => {
    if (currentStep > -1) {
      const actions = [...buttonActions];

      if (!actions[activeStep]) {
        actions[activeStep] = isWeightCaptured;
      }
      setButtonActions(actions);

      handleQuantityValue(currentStep)
    }
  }, [currentStep, isWeightCaptured]);

  React.useEffect(() => {
    if (selected?.item_qty != '' && selected?.length > 0) {
      const allotedWeight = selected.reduce((total, item) => total + Number(item?.item_qty ?? 0), 0)
      const lastWeight = loadingAdviceWeighings[loadingAdviceWeighings?.length - 1]?.loaded_weight;
      setAllotedWeight(allotedWeight)
      const weightToAllot = (Number(capturedCurrentWeight) - (!isWeightCaptured ? 0 : lastWeight)) - allotedWeight;
      setWeightToAllot(Number(Number(weightToAllot).toFixed(3)))
    }
  }, [selected])

  React.useEffect(() => {
    const lastWeight = loadingAdviceWeighings[loadingAdviceWeighings?.length - 1]?.loaded_weight;
    const weightToAllot = Number(capturedCurrentWeight) - (!isWeightCaptured ? 0 : Number(lastWeight))
    setWeightToAllot(Number(Number(weightToAllot).toFixed(3)))
  }, [capturedCurrentWeight])

  React.useEffect(() => {
    setSelected([])
  }, [activeStep])


  React.useEffect(() => {
    const totalDiscarded = totalWeightsTaken?.filter(w => w.is_discarded)?.length;
    const tareWeightDiscardedCount = tareWeightDetails?.is_discarded ? 1 : 0;
    const stepCount = weights?.length + totalDiscarded + tareWeightDiscardedCount;
    // const weightsTaken = totalWeightsTaken?.filter(w => !w.is_discarded)?.reduce((total, w) => total = total + w.items.length, 0) + totalDiscarded;
    const weightsTaken = totalWeightsTaken?.length;
    const weightSteps = [];
    const actions = [];
    for (let i = 1; i <= stepCount; i++) {
      let label = "";
      if (totalWeightsTaken?.some(w => w.is_discarded) || tareWeightDetails?.is_discarded) {
        let indexCount = i - totalDiscarded - tareWeightDiscardedCount;
        label = "Weight " + (indexCount);
        if (indexCount == 0) {
          label = "Tare Weight";
        }
        weightSteps.push(label);
      } else {
        label = "Weight " + (i - tareWeightDiscardedCount);
        weightSteps.push(label);
      }
    }

    let tempErrors = [];
    for (let i = 1; i <= stepCount; i++) {
      actions.push(false);
      tempErrors.push({});
    }
    setButtonActions(actions);
    const stepLabels =
      stepCount > 0
        ? ["Tare Weight", ...weightSteps, "Mark As Packed"]
        : ["Tare Weight", "Mark As Packed"];
    setSteps(stepLabels);
    const totalStepsCount = stepCount + 2;

    if (dispatchStatus === "UNLOADED") {
      setActiveStep(weightsTaken + 4);
    } else if (dispatchStatus === "UNLOADING") {
      let stepsToAdd = 0;
      if (totalWeightsTaken?.filter(t => t.is_unloaded)?.length > 0) {
        stepsToAdd += 1;
      }
      if (isPacked) {
        stepsToAdd += 1;
      }
      setActiveStep(weightsTaken + 1 + stepsToAdd);
    } else if (dispatchStatus === "GATEIN") {
      let activeStepCount = totalDiscarded + tareWeightDiscardedCount;
      setActiveStep(activeStepCount);
    } else if (totalStepsCount == weightsTaken + 2 && (dispatchStatus === "PACKED" || dispatchStatus === "ALLOTED" || dispatchStatus === "BILLED")) {
      setActiveStep(totalStepsCount);
      initialiseStepperCount(totalStepsCount);
    } else if (totalStepsCount > weightsTaken && isTareWeightCaptured) {
      const total = totalWeightsTaken?.filter(w => !w.is_discarded)?.reduce((total, w) => total = total + w.items.length, 0);
      const tareWeightDiscarded = tareWeightDetails?.is_discarded ? 1 : 0;
      //console.log(totalWeightsTaken)
      //console.log(`main ${total}-${weightsTaken}`)

      if (total == totalStepsCount - 2) {
        //console.log(`one ${total}-${tareWeightDiscarded}-${totalDiscarded}-${weightsTaken}`)
        setActiveStep(total + totalDiscarded + 1);
        initialiseStepperCount(total + totalDiscarded + 1);
      } else if (total + totalDiscarded + tareWeightDiscarded == totalStepsCount - 2) {
        //console.log(`two ${total}-${tareWeightDiscarded}-${totalDiscarded}-${weightsTaken}`)
        setActiveStep(total + totalDiscarded + tareWeightDiscarded + 1);
        initialiseStepperCount(total + totalDiscarded + tareWeightDiscarded + 1);
      } else {
        //console.log(`three ${total}-${tareWeightDiscarded}-${totalDiscarded}-${weightsTaken}`)
        setActiveStep(weightsTaken + 1);
        initialiseStepperCount(weightsTaken + 1);
      }

    } else if (totalStepsCount <= weightsTaken) {
      setActiveStep(totalStepsCount + 1);
      initialiseStepperCount(totalStepsCount + 1);
    } else if ((dispatchStatus === "PACKED" || dispatchStatus === "ALLOTED" || dispatchStatus === "BILLED")) {
      setActiveStep(totalStepsCount);
    }
    else {
      setActiveStep(0);
      initialiseStepperCount(0);
    }
  }, []);

  React.useEffect(() => {
    const totalDiscarded = totalWeightsTaken?.filter(w => w.is_discarded)?.length;
    const tareWeightDiscardedCount = tareWeightDetails?.is_discarded ? 1 : 0;
    const stepCount = weights?.length + totalDiscarded + tareWeightDiscardedCount;
    const totalStepsCount = stepCount + 2;
    if (movetoPack) {
      setActiveStep(totalStepsCount - 1);
      setVisible(true);
    }
  }, [movetoPack])

  const ActiveSvgIcon = styled(SvgIcon)(({ theme }) => ({
    color: "#293C79",
    fontSize: "small",
  }))
  const CustomStepIcon = (props) => {
    return (
      <ActiveSvgIcon shapeRendering="circle">
        <circle cx="12" cy="12" r="12" />
        <HighlightOffOutlinedIcon style={{ color: "#ffffff" }} fontSize="small" />
      </ActiveSvgIcon>
    );
  };

  const getEstBundleSize = (itemQuantity, section_weight) => {
    let val = parseFloat(itemQuantity) % parseFloat(section_weight);
    if (val === 0) {
      return parseFloat(itemQuantity) / parseFloat(section_weight);
    } if (!Number.isFinite(val)) {
      return 0;
    } else {
      return (
        Math.floor(parseFloat(itemQuantity) / parseFloat(section_weight)) +
        "-" +
        Math.ceil(parseFloat(itemQuantity) / parseFloat(section_weight))
      );
    }
  };

  const handleNext = async () => {
    setDisableStepItem(false);
    const weightsAllotted = selected.reduce((t, i) => t + Number(i?.item_qty), 0);
    const lastWeight = loadingAdviceWeighings[loadingAdviceWeighings?.length - 1]?.loaded_weight;
    const isTareWeightTaken = loadingAdviceWeighings.some(l => l.is_tare_weight && !l.is_discarded)
    const itemsWeightTaken = loadingAdviceWeighings.flatMap(w => w.items)?.length;
    if (isWeightCaptured && (Number(Number(weightsAllotted).toFixed(3)) != Number(Number((capturedCurrentWeight - lastWeight)).toFixed(3))) && isTareWeightTaken) {
      setTimeout(() => {
        setSnackbarType('error');
        setSnackbarMessage('Weights Allotted does not match captured weight');
        setOpenSnackbar(true);
        return;
      }, 5000)

    }
    if ((allowTakingWeights && !isWeightCaptured) || (weights.length == itemsWeightTaken)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      let selectedItems = [...selected];
      selectedItems = selectedItems.map((s) => (
        { ...s, item: weights.find((w) => w.item == s.item)?.item_id }
      ))
      const totalAllotedWeights = weights
        .filter(w => w.alloted_weight != "0.000"
          && loadingAdviceWeighings.find(l => !l.is_discarded && l.items.find(l => l.item_id == w.item_id) != undefined) != undefined);
      const enableRoute = weights?.length > (totalAllotedWeights?.length + selected?.length)
      await handleUpdateWeighing(selectedItems, enableRoute);
      setVisible(false);

    }
  };

  const handleAddItems = () => {
    setItemCount([...itemCount, { index: 1 }]);
  }

  const handleDeleteItem = (step, index) => {
    const newItems = [...itemCount]
    const newSelected = [...selected]
    newItems.splice(index, 1);
    newSelected.splice(index + 1, 1)
    setItemCount(newItems)
    setSelected(newSelected)
    // let selectedStepItems = stepSelectedItems[step - 1];
    // selectedStepItems = [newSelected];
    // setStepSelectedItems(selectedStepItems);
  }

  const handleUnloadingNext = async () => {
    await handleUpdateUnloadWeighing();
    setVisible(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleUnloadingAck = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const unloadFinish = async () => {
    await handleUnloadFinish();
    setVisible(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  }

  const handlePacked = async () => {
    await handleFinish();
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
    setVisible(false);
  };

  const handleWeightCapture = () => {
    const qtyErrors = Array.from(selected.length).fill({ error: false, errorMessage: null })
    setError(qtyErrors);
    setBundleError(qtyErrors);
    setDisableStepItem(true);
    handleStepperCaptureWeight(selected);
    setIsTareWeightCaptured(true);
  };

  const handleUnloadWeightCapture = () => {
    handleStepperCaptureWeight();
  }

  const handleQuantityValue = (step) => {
    if (selected.length == 1 && capturedCurrentWeight && !buttonActions[step]) {
      const lastWeight = loadingAdviceWeighings[loadingAdviceWeighings?.length - 1]?.loaded_weight;
      setFirstQuantity(capturedCurrentWeight - lastWeight);
      const items = [...selected]
      let currentItems = items[0]
      currentItems = { ...currentItems, item_qty: Number(Number((capturedCurrentWeight - lastWeight)).toFixed(3)) };
      items[0] = currentItems;
      setSelected(items);
      // const localStepSelectedItems = [...stepSelectedItems];
      // localStepSelectedItems[step - 2][0] = items;
      // setStepSelectedItems(localStepSelectedItems);
    }
  }

  const handleItemsChange = (step, index) => (e) => {
    // const localStepSelectedItems = [...stepSelectedItems];
    // let stepItems = localStepSelectedItems[step][index];
    // stepItems = { ...stepItems, [e.target.name]: Number(e.target.value) };
    // localStepSelectedItems[step][index] = stepItems;
    // setStepSelectedItems(localStepSelectedItems);
    const items = [...selected]
    let currentItems = items[index]
    currentItems = { ...currentItems, [e.target.name]: e.target.value };
    items[index] = currentItems;
    setSelected(items);
    // const localStepSelectedItems = [...stepSelectedItems];
    // localStepSelectedItems[step][index] = items;
    // setStepSelectedItems(localStepSelectedItems);

  }

  const checkBundleCount = (
    toleranceUpto1Mts,
    toleranceUpto5Mts,
    toleranceAbove5Mts, itemQtyForManual, weighbridgeWeight, sectionWeight, packingWeight, name
  ) => (event) => {
    // console.log(toleranceUpto5Mts)
    const { value } = event.target;
    if (name === "actual_section_count") {
      const dispatchedQty = Number(value) * sectionWeight;
      // console.log(sectionWeight)
      const packingWtInMts = packingWeight * Number(value);
      // console.log("sectionCount", Number(value))
      const adjustedWt = Number(Number(weighbridgeWeight - dispatchedQty - packingWtInMts).toFixed(3));
      const finalTolerance = Number(Number((adjustedWt / weighbridgeWeight) * 100).toFixed(3));
      // console.log(finalTolerance)
      // const itemQtyToCompare = itemCount?.length > 0 ? itemQtyForManual : (weights.find((w) => w.item === selected[0]?.item))?.item_qty;
      const itemQtyToCompare = itemQtyForManual;
      // console.log(Number(itemQtyToCompare))
      // console.log(toleranceUpto5Mts)
      // console.log("packingwetin MTS", packingWtInMts)
      // console.log("adjusted weight", adjustedWt)
      // console.log("dispatchedQty", dispatchedQty)
      if (1.000 >= itemQtyToCompare && itemQtyToCompare > 0) {
        if (finalTolerance > toleranceUpto1Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (1.001 <= itemQtyToCompare && itemQtyToCompare <= 5) {
        if (finalTolerance > toleranceUpto5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (itemQtyToCompare > 5) {
        if (finalTolerance > toleranceAbove5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (finalTolerance < 0) {
        return { error: true, errorMessage: 'bundle tolerance error' }
      }
    }
  }


  const handleCheckBundleCount = (
    toleranceUpto1Mts,
    toleranceUpto5Mts,
    toleranceAbove5Mts, itemQtyForManual, weighbridgeWeight, sectionWeight, packingWeight, name, value
  ) => {

    if (name === "actual_section_count") {
      // console.log("section count", value)
      const dispatchedQty = Number(value) * sectionWeight;
      const packingWtInMts = packingWeight * Number(value);
      const adjustedWt = Number(Number(weighbridgeWeight - dispatchedQty - packingWtInMts).toFixed(3));
      const finalTolerance = Number(Number((adjustedWt / weighbridgeWeight) * 100).toFixed(3));
      const itemQtyToCompare = itemQtyForManual;
      if (1.000 >= itemQtyToCompare && itemQtyToCompare > 0) {
        if (finalTolerance > toleranceUpto1Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (1.001 <= itemQtyToCompare && itemQtyToCompare <= 5) {
        if (finalTolerance > toleranceUpto5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (itemQtyToCompare > 5) {
        if (finalTolerance > toleranceAbove5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (finalTolerance < 0) {
        return { error: true, errorMessage: 'bundle tolerance error' }
      }
    }
  }


  // const handleBundleCountChange = (name) => (event) => {
  //   const { value } = event.target;
  //   if (name === "actual_section_count") {
  //     if (value === "" || Number(value) <= 0) {
  //       return { error: true, errorMessage: 'required field' }
  //     }
  //   }
  // }
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div style={{ margin: "10px" }}>

            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={handleWeightCapture}
              disabled={dispatchStatus !== "GATEIN" || buttonActions[0]}
            >
              Take Tare Weight
            </Button>

          </div>
        );
      case steps.length - 1:
        return <></>;
      default:
        return (
          <div>
            {loadingAdviceWeighings?.length == 0 ? <></> :
              <>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}>
                  <div style={{ color: COLORS.orange }}>
                    <span>WEIGHT TO ALLOT</span>
                    <span style={{ marginLeft: "30px", color: weightToAllot.toFixed(3) >= 0 ? COLORS.orange : "red", }}>
                      {weightToAllot.toFixed(3)} MT
                    </span>
                  </div>
                  <div style={{ color: COLORS.grey }}>
                    <span>ALLOTED WEIGHT</span>
                    <span style={{ marginLeft: "30px" }}>{allotedWeight.toFixed(3)} MT</span>
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px", width: "100%" }}>
                  <div style={{ display: "flex", placeItems: "center", flexGrow: 1 }}>
                    <FormControl style={{ width: "100%" }} size="small">
                      <InputLabel id="itemSelectLable" fontSize={10} >Select Item*</InputLabel>
                      <Select
                        //labelId="demo-multiple-checkbox-label"
                        id="itemSelectLable"
                        disabled={disableStepItem}
                        value={selected[0]?.item}
                        renderValue={(r) => selected[0]?.item}
                        MenuProps={MenuProps}
                        input={<OutlinedInput label="Select Item" />}
                        style={{ width: "100%" }}
                        SelectDisplayProps={{ style: { fontSize: "14px" } }}
                      >
                        {weights.filter(w => (!loadingAdviceWeighings?.flatMap(l => l.items)?.map(i => i.item).includes(w.item))
                          && !selected?.map(s => s?.item)?.includes(w.item)).map((data) => (
                            <MenuItem
                              value={data?.item}
                              onClick={() => handleChange(step, 0, data?.item)}
                              style={{ fontSize: "14px" }}
                            >
                              {data?.item}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{ display: "flex", placeItems: "center", width: "20%", marginLeft: "20px" }}
                  >
                    <TextField
                      size="small"
                      type="number"
                      //style={{ width: "20%" }}
                      id="outlined-name"
                      label="No of bundle*"
                      InputLabelProps={{
                        style: { fontSize: "14px" },
                      }}
                      disabled={!isWeightCaptured || !selected[0]?.item_qty}
                      onWheel={(e) => e.target.blur()}
                      //margin="normal"
                      variant="outlined"
                      name="actual_section_count"
                      value={selected[0]?.actual_section_count ?? ""}
                      onKeyDown={(evt) => (
                        ["e", "E", "+", "-"].includes(evt.key) ||
                        (evt.which === 38 || evt.which === 40)
                      ) && evt.preventDefault()}
                      helperText={bundleError[0]?.error && selected[0]?.actual_section_count !== "" ? bundleError[0]?.errorMessage :
                        bundleToleranceErr[0]?.error && selected[0]?.actual_section_count !== "" ? bundleToleranceErr[0]?.errorMessage :
                          (selected[0]?.item_qty && selected[0]?.actual_section_count) ? `Actual bundle weight ${selected[0]?.actual_section_count > 0 ? (
                            Number(selected[0]?.item_qty / selected[0]?.actual_section_count
                            )?.toFixed(3)) : 0} MT` : (selected[0]?.actual_section_count === "" || selected[0]?.actual_section_count <= 0) ? "Est " +
                              getEstBundleSize(
                                Number(selected[0]?.item_qty),
                                (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.section?.section_weight
                              ) +
                              " bundles" : ""}
                      onChange={(e) => {
                        handleItemsChange(step - 1, 0)(e);
                        if (itemCategoryDetails?.is_consider_packing) {
                          const resultOfBundle = checkBundleCount((weights.find((w) => w.item === selected[0]?.item))?.item_detail?.tolerance_upto_1mts,
                            (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.tolerance_from_1to5mts,
                            (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.tolerance_above_5mts, (weights.find((w) => w.item === selected[0]?.item))?.item_qty,
                            selected[0]?.item_qty, (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.section?.section_weight,
                            (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.packing_weight, "actual_section_count")(e);
                          let errorBundle = [...bundleToleranceErr]
                          errorBundle[0] = resultOfBundle;
                          setBundleToleranceErr(errorBundle)
                        }

                        // const result = handleBundleCountChange("actual_section_count")(e);
                        // let errors = [...bundleError]
                        // errors[0] = result;
                        // setBundleError(errors)
                      }}
                      FormHelperTextProps={{
                        className: classes.helperText
                      }}
                      error={(bundleError[0]?.error && selected[0]?.actual_section_count !== "") ||
                        (bundleToleranceErr[0]?.error && selected[0]?.actual_section_count !== "")}
                    />
                  </div>
                  <div
                    style={{ display: "flex", placeItems: "center", width: "20%", marginLeft: "20px" }}
                  >
                    <TextField
                      //type="number"
                      size="small"
                      required
                      helperText={error[0]?.errorMessage}
                      error={error[0]?.error}
                      id="outlined-name"
                      label="Quantity"
                      InputLabelProps={{
                        style: { fontSize: "14px" },
                      }}
                      variant="outlined"
                      name="item_qty"
                      value={selected[0]?.item_qty ?? ""}
                      InputProps={{
                        inputProps: {
                          min: 0
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+') {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        // setFirstQuantity(e.target.value)
                        if (selected[0]?.actual_section_count != null) {
                          if (itemCategoryDetails?.is_consider_packing) {
                            const resultOfBundle = handleCheckBundleCount((weights.find((w) => w.item === selected[0]?.item))?.item_detail?.tolerance_upto_1mts,
                              (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.tolerance_from_1to5mts,
                              (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.tolerance_above_5mts, (weights.find((w) => w.item === selected[0]?.item))?.item_qty,
                              e.target.value, (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.section?.section_weight,
                              (weights.find((w) => w.item === selected[0]?.item))?.item_detail?.packing_weight, "actual_section_count", selected[0]?.actual_section_count);
                            let errorBundle = [...bundleToleranceErr]
                            errorBundle[0] = resultOfBundle;
                            setBundleToleranceErr(errorBundle)
                          }
                        } else {

                        }

                        const result = handleIndividualItemValidation(selected[0]?.item, e.target.value)
                        let errors = [...error]
                        errors[0] = result;
                        setError(errors)
                        handleItemsChange(step - 1, 0)(e)
                      }
                      }
                      disabled={selected.length == 1 || !isWeightCaptured}
                      FormHelperTextProps={{
                        className: classes.helperText
                      }}
                      onFocus={(e) => {
                        if (e.target.value === "") {
                          setFirstQuantity("")
                        }
                      }}
                    //value={selected.length == 1 && capturedCurrentWeight && !buttonActions[step] ? capturedCurrentWeight - capturedWeight : ''}
                    />

                  </div>
                  <div id="addBtn">
                    <IconButton
                      style={{ marginLeft: "7px" }}
                      size="small"
                      onClick={() => {
                        handleAddItems()
                      }}
                      disabled={disableStepItem ||
                        ((loadingAdviceWeighings?.length - 1) + (selected?.length)) === weights?.length
                        || weights?.length === 1
                        || itemCount?.length + 1 === weights?.length
                      }
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                  </div>
                </div>

                {
                  itemCount?.length > 0 && itemCount?.map((item, index) => <>
                    <div style={{ display: "flex", flexWrap: "wrap", marginTop: "60px", width: "100%" }}>
                      <div style={{ display: "flex", placeItems: "center", flexGrow: 1 }}>
                        <FormControl style={{ width: "100%" }} size="small">
                          <InputLabel id="itemSelectLable">Select Item*</InputLabel>
                          <Select
                            id="itemSelectLable"
                            disabled={disableStepItem}
                            size="small"
                            value={selected[index + 1]?.item}
                            renderValue={(r) => selected[index + 1]?.item}
                            MenuProps={MenuProps}
                            input={<OutlinedInput label="Select Item" />}
                            style={{ width: "100%" }}
                            SelectDisplayProps={{ style: { fontSize: "14px" } }}
                          >
                            {weights.filter(w => (!loadingAdviceWeighings?.flatMap(l => l.items)?.map(i => i.item).includes(w.item))
                              && !selected?.map(s => s?.item)?.includes(w.item)).map((data) => (
                                <MenuItem
                                  value={data?.item}
                                  key={index}
                                  onClick={() => handleChange(step, index + 1, data?.item)}
                                  style={{ fontSize: "14px" }}
                                >
                                  {data?.item}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        style={{ display: "flex", placeItems: "center", width: "20%", marginLeft: "20px" }}
                      >
                        <TextField
                          size="small"
                          type="number"
                          // style={{ width: "70%", marginLeft: "25px" }}
                          id="outlined-name"
                          label="No of bundle*"
                          //margin="normal"
                          variant="outlined"
                          name="actual_section_count"
                          disabled={!isWeightCaptured || !selected[index + 1]?.item_qty}
                          onWheel={(e) => e.target.blur()}
                          InputLabelProps={{
                            style: { fontSize: "14px" },
                          }}
                          value={selected[index + 1]?.actual_section_count}
                          onKeyDown={(evt) => (
                            ["e", "E", "+", "-"].includes(evt.key) ||
                            (evt.which === 38 || evt.which === 40)
                          ) && evt.preventDefault()}
                          helperText={bundleError[index + 1]?.error && selected[index + 1]?.actual_section_count !== "" ? bundleError[index + 1]?.errorMessage :
                            bundleToleranceErr[index + 1]?.error && selected[index + 1]?.actual_section_count !== "" ? bundleToleranceErr[index + 1]?.errorMessage :
                              (selected[index + 1]?.item_qty && selected[index + 1]?.actual_section_count) ? `Actual bundle weight ${selected[index + 1]?.actual_section_count > 0 ? (
                                selected[index + 1]?.item_qty / selected[index + 1]?.actual_section_count
                              )?.toFixed(3) : 0} MT` : (selected[index + 1]?.actual_section_count === "" || selected[index + 1]?.actual_section_count <= 0) ? "Est " +
                                getEstBundleSize(
                                  Number(selected[index + 1]?.item_qty),
                                  (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.section?.section_weight
                                ) +
                                " bundles" : ""}
                          onChange={(e) => {
                            handleItemsChange(step - 1, index + 1)(e);
                            if (itemCategoryDetails?.is_consider_packing) {
                              const resultOfBundle = checkBundleCount((weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.tolerance_upto_1mts,
                                (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.tolerance_from_1to5mts,
                                (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.tolerance_above_5mts, (weights.find((w) => w.item === selected[index + 1]?.item))?.item_qty,
                                selected[index + 1]?.item_qty, (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.section?.section_weight,
                                (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.packing_weight, "actual_section_count")(e);
                              let errorBundle = [...bundleToleranceErr]
                              errorBundle[index + 1] = resultOfBundle;
                              setBundleToleranceErr(errorBundle)
                            }

                            // const result = handleBundleCountChange("actual_section_count")(e);
                            // let errors = [...bundleError]
                            // errors[index + 1] = result;
                            // setBundleError(errors)
                          }
                          }
                          error={(bundleError[index + 1]?.error && selected[index + 1]?.actual_section_count !== "") ||
                            (bundleToleranceErr[index + 1]?.error && selected[index + 1]?.actual_section_count !== "")}
                          FormHelperTextProps={{
                            className: classes.helperText
                          }}
                        />
                      </div>
                      <div
                        style={{ display: "flex", placeItems: "center", width: "20%", marginLeft: "20px" }}
                      >
                        <TextField
                          //type="number"
                          size="small"
                          required
                          error={error[index + 1]?.error}
                          id="outlined-name"
                          label="Quantity"
                          name="item_qty"
                          InputProps={{
                            inputProps: {
                              min: 0
                            }
                          }}
                          onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (selected[index + 1]?.actual_section_count != null) {
                              if (itemCategoryDetails?.is_consider_packing) {
                                const resultOfBundle = handleCheckBundleCount((weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.tolerance_upto_1mts,
                                  (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.tolerance_from_1to5mts,
                                  (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.tolerance_above_5mts, (weights.find((w) => w.item === selected[index + 1]?.item))?.item_qty,
                                  e.target.value, (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.section?.section_weight,
                                  (weights.find((w) => w.item === selected[index + 1]?.item))?.item_detail?.packing_weight, "actual_section_count", selected[index + 1]?.actual_section_count);
                                let errorBundle = [...bundleToleranceErr]
                                errorBundle[index + 1] = resultOfBundle;
                                setBundleToleranceErr(errorBundle)
                              }

                            } else {

                            }

                            const result = handleIndividualItemValidation(selected[index + 1]?.item, e.target.value)
                            let errors = [...error]
                            errors[index + 1] = result;
                            setError(errors)
                            handleItemsChange(step - 1, index + 1)(e)
                          }}
                          value={selected[index + 1]?.item_qty}
                          disabled={!isWeightCaptured}
                          helperText={error[index + 1]?.errorMessage}
                          variant="outlined"
                          InputLabelProps={{
                            style: { fontSize: "14px" },
                          }}
                          //value={actual_section_count}
                          FormHelperTextProps={{
                            className: classes.helperText
                          }}
                          onFocus={(e) => {
                            if (e.target.value === "") {
                              setFirstQuantity("")
                            }
                          }}
                        />

                      </div>
                      <>
                        <IconButton
                          style={{ marginLeft: "7px" }}
                          size="small"
                          onClick={() => handleDeleteItem(step, index)}
                          disabled={disableStepItem}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
                    </div>
                  </>)
                }
                <br />
              </>
            }

            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={handleWeightCapture}
              disabled={(dispatchStatus !== "LOADING" && dispatchStatus !== "GATEIN") || buttonActions[step] || (selected.length === 0 && loadingAdviceWeighings?.length != 0) || Number(weightToAllot) != 0}
            >
              {(dispatchStatus === "LOADING" || dispatchStatus === "GATEIN") &&
                loadingAdviceWeighings?.length === 0 && allWeighings?.[0]?.is_discarded ? "TAKE TARE WEIGHT" :
                `Take Weight`}
            </Button>
          </div>


        );
    }
  };

  const handleNextButtonDisabled = () => {
    if (activeStep == 0) {
      return !isWeightCaptured;
    } else if (
      activeStep !== 0 &&
      activeStep !== steps.length - 1 &&
      (dispatchStatus !== "LOADING" && dispatchStatus !== "GATEIN")
    ) {
      return true;
    }

    if (error?.some((e) => e?.error)) {
      return true;
    }

    if (selected?.some((s) => (s.item_qty == '' || s.item_qty == null) || (s.actual_section_count == '' || s.actual_section_count == null))) {
      return true;
    }
    if ((weightToAllot < 0 || weightToAllot !== 0) && loadingAdviceWeighings.length != 0) {
      return true;
    }
    if (bundleError?.some(b => b?.error))
      return true;

    if (bundleToleranceErr?.some(b => b?.error))
      return true;

    return !allowTakingWeights || (selected?.length == 0 && loadingAdviceWeighings.length != 0);
  };

  const handleUnloadNextButtonDisabled = (label) => {
    if (isWeightCaptured || label == "Mark As Unloaded." || label == "LA Cancelled") {
      return false
    }
    return true;
  }

  const getStepHeader = (isTareWeightCaptured,
    totalWeightsTaken,
    capturedWeight,
    index,
    label) => {
    if (index === 0 && tareWeightDetails) {
      return (<Paper
        style={{
          width: "85%",
          padding: "10px 5px 5px",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
        }}
      >
        <div style={{ padding: "5px" }}>
          <span style={{ color: "#839192" }}>Weighslip Number</span>
          <span style={{ float: "right" }}>
            {tareWeightDetails
              ? tareWeightDetails?.id
              : "PENDING"}
          </span>
        </div>
        <div style={{ padding: "5px" }}>
          <span style={{ color: "#839192" }}>Date and Time</span>
          <span style={{ float: "right" }}>
            {tareWeightDetails
              ? moment(
                tareWeightDetails?.created_at
              ).format("DD-MMM-YYYY LT")
              : "PENDING"}
          </span>
        </div>
        <div style={{ padding: "5px" }}>
          <span style={{ color: "#839192" }}>Tare Weight</span>
          <span style={{ float: "right" }}>
            {tareWeightDetails?.loaded_weight} MT
          </span>
        </div>
        {tareWeightDetails?.is_discarded ?
          <>
            <Divider style={{ width: "100%" }} />
            <div style={{ padding: "5px" }}>
              <span style={{ color: "#839192" }}>Discarded By</span>
              <span style={{ float: "right" }}>
                {tareWeightDetails
                  ? tareWeightDetails?.discarded_by
                  : ""}
              </span>
            </div>
            <div style={{ padding: "5px" }}>
              <span style={{ color: "#839192" }}>Discarded At</span>
              <span style={{ float: "right" }}>
                {tareWeightDetails
                  ? moment(tareWeightDetails?.discarded_at).format("DD-MMM-YYYY LT")
                  : ""}
              </span>
            </div>
          </>
          : ""
        }
      </Paper>)

    } else if ((index === 0 && !tareWeightDetails) || totalWeightsTaken[index - 1] === undefined) {
      return (<>
        {label}
        {index !== steps.length - 1 ? " - " : ""}
        {getStepLabel(
          isTareWeightCaptured,
          totalWeightsTaken,
          capturedWeight,
          index
        )}
      </>)
    } else {
      return (
        <>
          <Paper
            style={{
              width: "85%",
              padding: "10px 5px 5px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
            }}
          >
            <div style={{ padding: "5px" }}>
              <span style={{ color: "#839192" }}>Weighslip Number</span>
              <span style={{ float: "right" }}>
                {totalWeightsTaken[index - 1]
                  ? totalWeightsTaken[index - 1]?.id
                  : "PENDING"}
              </span>
            </div>
            <div style={{ padding: "5px" }}>
              <span style={{ color: "#839192" }}>Date and Time</span>
              <span style={{ float: "right" }}>
                {totalWeightsTaken[index - 1]
                  ? moment(
                    totalWeightsTaken[index - 1]?.created_at
                  ).format("DD-MMM-YYYY LT")
                  : "PENDING"}
              </span>
            </div>
            <div style={{ padding: "5px" }}>
              <span style={{ color: "#839192" }}>{allWeighings?.[index]?.is_tare_weight ? "Tare Weight" : "Weight"}</span>
              <span style={{ float: "right" }}>
                {getStepLabel(
                  isTareWeightCaptured,
                  totalWeightsTaken,
                  capturedWeight,
                  index
                )}
              </span>
            </div>
            {totalWeightsTaken[index - 1]?.is_discarded ?
              <>
                <Divider style={{ width: "100%" }} />
                <div style={{ padding: "5px" }}>
                  <span style={{ color: "#839192" }}>Discarded By</span>
                  <span style={{ float: "right" }}>
                    {totalWeightsTaken[index - 1]
                      ? totalWeightsTaken[index - 1]?.discarded_by
                      : ""}
                  </span>
                </div>
                <div style={{ padding: "5px" }}>
                  <span style={{ color: "#839192" }}>Discarded At</span>
                  <span style={{ float: "right" }}>
                    {totalWeightsTaken[index - 1]
                      ? moment(totalWeightsTaken[index - 1]?.discarded_at).format("DD-MMM-YYYY LT")
                      : ""}
                  </span>
                </div>
              </>
              : ""
            }

            {
              totalWeightsTaken[index - 1]?.is_discarded ? <></> :
                <WeighbridgeItemTable weighslipId={totalWeightsTaken[index - 1]?.id}
                  allWeighings={allWeighings}
                />
            }

          </Paper>
        </>
      )
    }
  }

  const getStepLabel = (
    isTareWeightCaptured,
    totalWeightsTaken,
    capturedWeight,
    index
  ) => {
    if (index === steps.length - 1) {
      return "";
    } else if (index === 0) {
      if (
        capturedWeight == "" ||
        capturedWeight === undefined ||
        capturedWeight === null ||
        dispatchStatus === "GATEIN" ||
        dispatchStatus === "APPROVED" ||
        dispatchStatus === "PENDING"
      ) {
        return <span>PENDING</span>;
      }
      return <span>{capturedWeight} MT</span>;
    } else if (totalWeightsTaken && totalWeightsTaken.length > 0) {
      if (
        (totalWeightsTaken[index - 1]?.loaded_weight == "" ||
          totalWeightsTaken[index - 1]?.loaded_weight === undefined ||
          totalWeightsTaken[index - 1]?.loaded_weight === null) &&
        index === activeStep
      ) {
        return <span>PENDING</span>;
      } else if (index > activeStep) {
        return <span>PENDING</span>;
      } else if (
        (totalWeightsTaken[index - 1]?.loaded_weight == "" ||
          totalWeightsTaken[index - 1]?.loaded_weight === undefined ||
          totalWeightsTaken[index - 1]?.loaded_weight === null) &&
        // totalWeightsTaken.length < activeStep
        index < activeStep
      ) {
        return <span>SKIPPED</span>;
      }
      return <span>{totalWeightsTaken[index - 1]?.loaded_weight} MT</span>;
    } else if (totalWeightsTaken && totalWeightsTaken.length == 0) {
      return <span>PENDING</span>;
    } else {
      return "";
    }
  };

  const showStep = (index, label) => {
    if (dispatchStatus !== "UNLOADING" && dispatchStatus !== "UNLOADED") {
      return true;
    } else if (label === "Mark As Packed" && (dispatchStatus === "UNLOADING" || dispatchStatus === "UNLOADED")) {
      if (isPacked) {
        return true;
      } else {
        return false;
      }
    } else {
      if (totalWeightsTaken?.filter(t => !t.is_unloaded)?.[index - 1] === undefined && index !== 0 && index !== steps.length - 1) {
        return false;
      }
      else if (index === 0 && (tareWeightDetails === undefined || tareWeightDetails === null)) {
        return false;
      } else if (index === steps.length - 1) {
        return true;
      }
    }
    return true;
  }

  const getUnloadingStepLabel = (index) => {
    let unloadedWeighs = totalWeightsTaken?.filter(w => w.is_unloaded);
    let totalWeightsTakenBeforeUnload = totalWeightsTaken?.filter(w => !w.is_unloaded)
    let uw = unloadedWeighs?.[0] ?? 0;
    let utw = unloadedWeighs?.[1] ?? 0;

    if (totalWeightsTakenBeforeUnload?.length == 0) {
      utw = unloadedWeighs?.[0] ?? 0;
    }
    if (totalWeightsTaken.some(w => w.is_unloaded && w.is_tare_weight)) {
      utw = totalWeightsTaken.find(w => w.is_unloaded && w.is_tare_weight)
    }

    if ((index == 1 && unloadedWeight <= 0 && uw == 0) || (index == 2 && unloadedTareWeight <= 0 && utw == 0)) {
      return <span>{unloadingSteps[index].label} - Pending</span>
    } else if ((index == 1 && (unloadedWeight > 0 || uw?.loaded_weight > 0))) {
      return (
        <Paper
          style={{
            width: "85%",
            padding: "10px 5px 5px",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
          }}
        >
          <div style={{ padding: "5px" }}>
            <span style={{ color: "#839192" }}>Weighslip Number</span>
            <span style={{ float: "right" }}>
              {uw?.loaded_weight > 0 ? uw?.id
                : "PENDING"}
            </span>
          </div>
          <div style={{ padding: "5px" }}>
            <span style={{ color: "#839192" }}>Date and Time</span>
            <span style={{ float: "right" }}>
              {uw?.loaded_weight > 0 ? moment(uw?.created_at
              ).format("DD-MMM-YYYY LT")
                : "PENDING"}
            </span>
          </div>
          <div style={{ padding: "5px" }}>
            <span style={{ color: "#839192" }}>Unloaded Weight</span>
            <span style={{ float: "right" }}>
              {index == 1 ? (unloadedWeight === 0 ? uw?.loaded_weight : unloadedWeight) : (unloadedTareWeight === 0 ? utw?.loaded_weight : unloadedTareWeight)} MT
            </span>
          </div>
        </Paper>
      )
    } else if ((index == 2 && (unloadedTareWeight > 0 || utw?.loaded_weight > 0))) {
      return (
        <Paper
          style={{
            width: "85%",
            padding: "10px 5px 5px",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
          }}
        >
          <div style={{ padding: "5px" }}>
            <span style={{ color: "#839192" }}>Weighslip Number</span>
            <span style={{ float: "right" }}>
              {utw?.loaded_weight > 0 ? utw?.id
                : "PENDING"}
            </span>
          </div>
          <div style={{ padding: "5px" }}>
            <span style={{ color: "#839192" }}>Date and Time</span>
            <span style={{ float: "right" }}>
              {utw?.loaded_weight > 0 ? moment(utw?.created_at
              ).format("DD-MMM-YYYY LT")
                : "PENDING"}
            </span>
          </div>
          <div style={{ padding: "5px" }}>
            <span style={{ color: "#839192" }}>Unloaded Tare Weight</span>
            <span style={{ float: "right" }}>
              {index == 1 ? (unloadedWeight === 0 ? uw?.loaded_weight : unloadedWeight) : (unloadedTareWeight === 0 ? utw?.loaded_weight : unloadedTareWeight)} MT
            </span>
          </div>
        </Paper>
      )
    }

    else {
      return <span>{unloadingSteps[index].label}</span>
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => showStep(index, label) && (
          <Step key={label}>

            <StyledStepLabel
              StepIconComponent={
                ((index === 0 && tareWeightDetails?.is_discarded) ||
                  totalWeightsTaken?.[index - 1]?.is_discarded) && CustomStepIcon
              }
              StepIconProps={{
                classes: {
                  root: classes.stepIconRoot,
                  labelContainer: classes.labelContainer,
                },
              }}
            >

              {

                getStepHeader(isTareWeightCaptured,
                  totalWeightsTaken.filter(t => !t.is_unloaded),
                  capturedWeight,
                  index,
                  label)
              }

            </StyledStepLabel>

            <StepContent>
              {visible &&
                <>
                  {getStepContent(index)}

                  <div style={{ display: "flex", padding: "10px" }}>
                    {activeStep === 0 ? (
                      ""
                    ) : (
                      <Button
                        size="medium"
                        onClick={handleCancelButton}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                    )}

                    {activeStep === steps.length - 1 && visible ? (

                      (<Button
                        variant="contained"
                        color="primary"
                        className={classes.saveButton}
                        disabled={!markedAsPacked}
                        onClick={handlePacked}
                      >
                        Mark As Packed
                      </Button>)

                    ) : activeStep !== 0 && loadingAdviceWeighings.length != 0 ? (
                      <>
                        <Button
                          size="medium"
                          onClick={() => handleReset(index)}
                          className={classes.cancelButton}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          disabled={handleNextButtonDisabled()}
                          onClick={handleNext}
                          className={classes.saveButton}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        disabled={handleNextButtonDisabled()}
                        onClick={handleNext}
                        className={classes.saveButton}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </>
              }
            </StepContent>
          </Step>
        ))}
        {(dispatchStatus === "UNLOADING" || dispatchStatus === "UNLOADED")
          && unloadingSteps.map((step, index) =>
            (step.label !== "Unloading Weight" ||
              (step.label === "Unloading Weight"
                && (
                  loadingAdviceWeighings?.length > 1
                )
              )
            ) &&
            (
              <Step key={step.label}>
                <StyledStepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.stepIconRoot,
                      labelContainer: classes.labelContainer,
                    },
                  }}
                >
                  {getUnloadingStepLabel(index)}
                </StyledStepLabel>
                <StepContent>
                  {
                    index === 0 || index === unloadingSteps.length - 1 ? (<>{step.description}</>)
                      : index === unloadingSteps.length - 2 ? (
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.saveButton}
                            onClick={handleUnloadWeightCapture}
                            disabled={unloadedTareWeight > 0 || isWeightCaptured}
                          >
                            Take Tare Weight
                          </Button>
                        </div>
                      ) :

                        (
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.saveButton}
                              onClick={handleUnloadWeightCapture}
                              disabled={unloadedWeight > 0 || isWeightCaptured}
                            >
                              Take Weight
                            </Button>
                          </div>
                        )
                  }
                  <div style={{ display: "flex", padding: "10px" }}>
                    <Button
                      size="medium"
                      onClick={handleCancelButton}
                      className={classes.cancelButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={handleUnloadNextButtonDisabled(step.label)}
                      onClick={index === 0 ? handleUnloadingAck : index === unloadingSteps.length - 1 ? unloadFinish : handleUnloadingNext}
                      className={classes.saveButton}
                    >
                      {index === unloadingSteps.length - 1 ? 'MARK AS UNLOADED' : index === 0 ? "CONTINUE" : `SAVE`}
                    </Button>
                  </div>
                </StepContent>
              </Step>
            ))}

      </Stepper>
      <SnackbarComponent
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        snackbarMessage={snackbarMessage}
        type={snackbarType}
        customAutoHideDuration={1000}
      />
    </div>
  );
}

export default VerticalLinearStepper;
