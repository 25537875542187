import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Confirm from "../Confirm";

class ConfirmOrderWeb extends Component {
  render() {
    return (
      <WebLayout appBar dailyRates>
        <Confirm {...this.props} />
      </WebLayout>
    );
  }
}
export default ConfirmOrderWeb;
