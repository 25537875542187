// Mobile Imports
import Home from "./mobile";
import CreateOrder from "./mobile/CreateOrder";
import ConfirmOrder from "./mobile/ConfirmOrder";
import TermsAndConditions from "./mobile/TermsAndConditions";

// Web Imports
import HomeWeb from "./web";
import CreateOrderWeb from "./web/CreateOrder";
import ConfirmOrderWeb from "./web/ConfirmOrder";
import TermsAndConditionsWeb from "./mobile/TermsAndConditions";

// Exports
export default Home;
export {
  HomeWeb,
  CreateOrder,
  CreateOrderWeb,
  ConfirmOrder,
  ConfirmOrderWeb,
  TermsAndConditions,
  TermsAndConditionsWeb,
};
