import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import {
  grey,
  primaryColor,
  drawerTextColor,
} from "../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },

  topMargin: { marginTop: 30, width: "50%" },

  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
});
function DisplayTdsRateData(props) {
  const classes = useStyles();
  const [tdsRateDisplayData, setTdsRateDisplayData] = React.useState(
    props?.selectedTdsRateData || []
  );
  React.useEffect(() => {
    setTdsRateDisplayData(props?.selectedTdsRateData);
  }, [props?.selectedTdsRateData]);
  const handleCancelButtonClick = () => {
    props.history.push(INTERNALROUTES.TDS_RATE);
  };
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "TDS Rate Master",
                onClick: () => props.history.push(INTERNALROUTES.TDS_RATE),
              },
              {
                title: tdsRateDisplayData?.tds_code || "",
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS CODE</Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.tds_code || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS NAME</Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.tds_name || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS RATE</Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.tds_rate || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>BASIC RATE</Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.basic_rate || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS ACCOUNT CODE
              </Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.tds_acc_code || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS SECTION
              </Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.tds_section || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS VALID FROM
              </Typography>
              <Typography className={classes.titleLabel}>
                {!tdsRateDisplayData.tds_valid_from
                  ? null
                  : moment(tdsRateDisplayData.tds_valid_from).format(
                    "DD MMM YYYY"
                  )}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS VALID TO
              </Typography>
              <Typography className={classes.titleLabel}>
                {!tdsRateDisplayData.tds_valid_upto
                  ? null
                  : moment(tdsRateDisplayData.tds_valid_upto).format(
                    "DD MMM YYYY"
                  )}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>CODE TYPE</Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.code_type || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>USER CODE</Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.user_code || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PARENT CODE
              </Typography>
              <Typography className={classes.titleLabel}>
                {tdsRateDisplayData.parent_code || ""}
              </Typography>
            </div>
            <ToolTipComponent
              title="You have no edit permission on this widget"
              condition={!hasUpdateAccess(props.user, 'tds_rate_master')}
            >
              <Button
                size="medium"
                className={classes.primaryBackground}
                variant="contained"
                startIcon={<EditOutlinedIcon />}
                disabled={isEmpty(tdsRateDisplayData) || !hasUpdateAccess(props.user, 'tds_rate_master')}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(
                    `${INTERNALROUTES.TDS_RATE}/edit/${tdsRateDisplayData?.id}`
                  );
                }}
              >
                EDIT
              </Button>
            </ToolTipComponent>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(DisplayTdsRateData);
