import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { white } from "../../../styles/colors";


const SaveButton = (props) => {
  let isLoading = props.isLoading || false;

  return (
    <Button
      {...props}
      disabled={isLoading || props.disabled}
    >
      {props.children}
      {
        isLoading
          ?
          (
            <CircularProgress
              size={props.circularSize || 15}
              style={{ marginLeft: "10px", color: white }}
            />
          )
          :
          null
      }
    </Button>
  )
}

export default SaveButton;