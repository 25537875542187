import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import DisplayReasonData from "../DisplayReasonData";

class ReasonWeb extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <DisplayReasonData {...this.props} />
      </Layout>
    );
  }
}
export default ReasonWeb;
