import React from "react";
export default class DesktopImg extends React.Component {
  render() {
    return (
      <svg
        style={{ fill: "#4B87C5", height: "45px", width: "45px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="57.328"
        height="51.951"
        viewBox="0 0 57.328 51.951"
      >
        <path
          class="a"
          d="M55.924,38.011a4.5,4.5,0,0,0-3.374-1.468H4.777A4.5,4.5,0,0,0,1.4,38.011,4.922,4.922,0,0,0,0,41.538V75.507a4.923,4.923,0,0,0,1.4,3.528A4.5,4.5,0,0,0,4.777,80.5H21.021a6.789,6.789,0,0,1-.478,2.435,22.581,22.581,0,0,1-.955,2.185A3.558,3.558,0,0,0,19.11,86.5a1.962,1.962,0,0,0,.567,1.4,1.8,1.8,0,0,0,1.344.594H36.308a1.8,1.8,0,0,0,1.344-.594,1.961,1.961,0,0,0,.568-1.4,3.679,3.679,0,0,0-.478-1.358,23.431,23.431,0,0,1-.955-2.216,6.841,6.841,0,0,1-.478-2.42H52.551a4.5,4.5,0,0,0,3.373-1.467,4.922,4.922,0,0,0,1.4-3.528V41.538A4.92,4.92,0,0,0,55.924,38.011Zm-2.418,29.5a.983.983,0,0,1-.284.7.9.9,0,0,1-.672.3H4.777a.9.9,0,0,1-.672-.3.984.984,0,0,1-.284-.7V41.538a.981.981,0,0,1,.284-.7.9.9,0,0,1,.672-.3H52.551a.9.9,0,0,1,.671.3.982.982,0,0,1,.284.7V67.514Z"
          transform="translate(0 -36.543)"
        />
      </svg>
    );
  }
}
