import React, { Component } from "react";
import AccessDenied from "../../../components/common/AccessDenied";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import withUser from "../../../hoc/withUser";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import { Route } from "react-router-dom";
import AllLedgerList from "../../../components/internal/customerRequets/web/AllLedgerList"
import { isAccessibleModule, arraySortBykeyAllBrowser } from "../../../methods";
import debounce from "lodash.debounce";
import LedgerRequests from "../../../components/internal/customerRequets/web/LedgerRequests";
class MastersManagementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openRequests: 0,
            newRequests: 0
        };
    }

    getOptions = (user) => {
        return {
            headers: {
                Authorization: `JWT ${user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    };
    componentDidMount() {
        this.getLedgerCount()
    }

    getLedgerCount = () => {
        axios.get(
            `${APIROUTES.GET_LEDGER_COUNT}`,
            this.getOptions(this.props.user)
        )
            .then((response) => {
                console.log(response.data)
                if (response.status === 200) {
                    this.setState({
                        openRequests: response.data.pending_requests,
                        newRequests: response.data.new_request
                    })
                } else {

                }
            })
            .catch((error) => {

            });
    };

    render() {

        return (
            <>
                <Route
                    exact
                    path={[
                        `${INTERNALROUTES.LISTS_LEDGER_REQUESTS}`,
                    ]}
                    render={(props) => (
                        <LedgerRequests
                            {...props}
                            {...this.props}
                            {...this.state}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${INTERNALROUTES.CUSTOMER_REQUESTS}`}
                    render={(props) => (
                        <AllLedgerList {...this.props} {...this.state}

                        />
                    )}
                />

            </>
        );

    }
}

export default withUser(MastersManagementContainer);
