import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../../styles/b2b/B2bProfileSettings.css";
import Button from "@material-ui/core/Button";
import { primaryColor } from "../../../styles/colors";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import withAuthentication from "../../../hoc/withAuthentication";
import StaticVersionDisplay from "../../common/StaticVersionDisplay";
import backStack from "cordova-back-stack";

const styles = {
  b2bProfileSettingsMainBody: {
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  b2bProfileSettingInternalBody: {
    height: window.innerHeight - 74,
    padding: "30px 20px 0px 20px",
    overflow: "scroll",
  },
  backButtonStyle: {
    height: "100%",
    width: "45%",
    borderRadius: "0px 25px 0px 0px",
    backgroundColor: primaryColor,
  },
  logOutButtonStyle: {
    height: "80%",
    width: "65%",
    color: "white",
    fontWeight: "bold",
    backgroundColor: primaryColor,
  },
};
class B2bProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      this.props.history.goBack();
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.b2bProfileSettingsMainBody}>
        <div className={classes.b2bProfileSettingInternalBody}>
          <div className="b2b-mobile-profile-setting-setting-text">
            <span>Settings</span>
          </div>
          <div className="b2b-mobile-profile-setting-list-start">
            {/* <List
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/b2bProfile/settings/editProfile");
              }}
            >
              <ListItem>
                <ListItemText
                  primary="Edit Profile"
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.3em",
                      color: primaryColor,
                    },
                  }}
                />
                <ListItemSecondaryAction>
                  <img
                    src={
                      window.cordova
                        ? `${this.image}${"/arrowRight.svg"}`
                        : "/arrowRight.svg"
                    }
                    alt="img"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List> */}
            {/* <List>
              <ListItem>
                <ListItemText
                  primary="Notifications"
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.3em",
                      color: primaryColor,
                    },
                  }}
                />
                <ListItemSecondaryAction>
                  <img
                    src={
                      window.cordova
                        ? `${this.image}${"/arrowRight.svg"}`
                        : "/arrowRight.svg"
                    }
                    alt="img"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List> */}
            <List
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/b2bProfile/settings/changePassword");
              }}
            >
              <ListItem>
                <ListItemText
                  primary="Change Password"
                  primaryTypographyProps={{
                    style: {
                      fontSize: "1.3em",
                      color: primaryColor,
                    },
                  }}
                />
                <ListItemSecondaryAction>
                  <img
                    src={
                      window.cordova
                        ? `${this.image}${"/arrowRight.svg"}`
                        : "/arrowRight.svg"
                    }
                    alt="img"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
          <br />
          <br />
          <div className="b2b-mobile-profile-setting-logout-button-container">
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.props.logout();
              }}
              variant="contained"
              color="primary"
              className={classes.logOutButtonStyle}
            >
              Logout
            </Button>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StaticVersionDisplay />
          </div>
        </div>

        <div className="b2b-mobile-profile-setting-bottom-navigation">
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/b2bProfile");
            }}
            variant="contained"
            color="primary"
            className={classes.backButtonStyle}
          >
            <KeyboardArrowLeft /> Back
          </Button>
        </div>
      </div>
    );
  }
}
export default withAuthentication(withStyles(styles)(B2bProfileSettings));
