import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Confirm from "../Confirm";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    ConfirmDispatchWebMainBody: {
        width: "55%",
    },
};
class ConfirmOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { classes } = this.props;
        return (
            <WebLayout appBar dispatchSelect>
                <div className={classes.ConfirmDispatchWebMainBody}>
                    <Confirm {...this.props} />
                </div>
            </WebLayout>
        );
    }
}
export default withStyles(styles)(ConfirmOrderWeb);
