import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Pending from "../Pending";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  pendingDispatchWebMainBody: {
    width: "100%",
  },
};
class PendingDispatchWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar dispatchSelect>
        <div className={classes.pendingDispatchWebMainBody}>
          <Pending {...this.props} />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(PendingDispatchWeb);
