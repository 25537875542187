import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  Checkbox,
  Paper,
  Card,
} from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
} from "../../../../constants/internal/colors";
import PresentToAllOutlinedIcon from "@material-ui/icons/PresentToAllOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { pxToEm } from "../../../../methods";
import CircularProgress from "@material-ui/core/CircularProgress";
import ManageCustomerAdditionalAddressesDisplay from "./ManageCustomerAdditionalAddressesDisplay";
import AddMoreDottedButtonComponent from "./AddMoreDottedButtonComponent";
import moment from "moment";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  formControlPanImage: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
    cursor: "pointer",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  titleLabelStylePanImage: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
    cursor: "pointer",
    textDecoration: "underline",
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
});
const CustomCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function ManageCustomerGeneralDetails(props) {
  const classes = useStyles();
  const [gstDataValidated, setGstDataValidated] = React.useState(
    props?.gstDataValidated
  );
  const [showButton, setShowButton] = React.useState(false);
  const [customerDontHaveGSTIN, setCustomerDontHaveGSTIN] = React.useState(
    props?.customerDontHaveGSTIN
  );

  React.useEffect(() => {
    setGstDataValidated(props?.gstDataValidated);
    setCustomerDontHaveGSTIN(props?.customerDontHaveGSTIN);
  }, [props?.gstDataValidated, props?.customerDontHaveGSTIN]);

  // console.log(props)
  // console.log(props?.principalBuisnessAddressStateName)
  // console.log(props?.dateOfCancellation ? moment(props?.dateOfCancellation).format("DD-MMM-YYYY") : "NA")

  return (
    <Paper className={classes.paperStyles}>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation}>
          Provide the general company related information.
        </Typography>
        <Typography className={classes.fillingInformation}>
          &nbsp;Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <div
          className={
            gstDataValidated ? classes.dataDisplayForGstin : classes.dataDisplay
          }
        >
          {gstDataValidated ? (
            <Typography className={classes.titleLabelStyle}>GSTIN</Typography>
          ) : (
            ""
          )}
          {!customerDontHaveGSTIN ? (
            <>
              <div
                className={
                  gstDataValidated
                    ? classes.dataFieldsForGstin
                    : classes.dataFields
                }
              >
                <Typography className={classes.titleLabel}>GSTIN *</Typography>
                {props?.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props?.gstinNumber}
                  </Typography>
                ) : (
                  <TextField
                    className={classes.formControl}
                    disabled={
                      props?.customerOperation === "edit" ||
                      props?.customerOperation === "view" ||
                      showButton
                    }
                    label="GSTIN"
                    autoComplete="off"
                    value={props?.gstinNumber || ""}
                    variant="outlined"
                    size="small"
                    name="gstin"
                    onChange={props.handleChange("gstinNumber")}
                    InputProps={{
                      endAdornment:
                        props?.customerOperation === "add" ? (
                          <InputAdornment position="end">
                            {props?.validGstinNumber ? (
                              props?.gstDataFetchLoader ? (
                                <CircularProgress
                                  disableShrink
                                  size={30}
                                  className={classes.loaderStyle}
                                />
                              ) : (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.getCustomerGSTINData(
                                      props?.gstinNumber
                                    );
                                    setShowButton(true);
                                  }}
                                  edge="end"
                                >
                                  VERIFY
                                </Button>
                              )
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        ) : (
                          ""
                        ),
                    }}
                    error={
                      props?.customerOperation === "add"
                        ? props?.validGstinNumber !== ""
                          ? !props?.validGstinNumber
                          : false
                        : false
                    }
                    helperText={
                      props?.customerOperation === "add"
                        ? props?.validGstinNumber !== ""
                          ? !props?.validGstinNumber
                            ? "GSTIN number invalid"
                            : ""
                          : ""
                        : ""
                    }
                  />
                )}
              </div>
              {gstDataValidated ? null : (
                <div className={classes.dataFieldsForCheckbox}>
                  <CustomCheckbox
                    checked={customerDontHaveGSTIN}
                    onChange={(evt) => {
                      props.handleCustomerDontHaveGSTINChange(evt);
                    }}
                    name="customerDontHaveGSTIN"
                  />
                  <Typography className={classes.generalText}>
                    I don't have GSTIN
                  </Typography>
                </div>
              )}
            </>
          ) : null}
          {!customerDontHaveGSTIN ? (
            gstDataValidated ? (
              <Card className={classes.gstDetails}>
                {gstDataValidated ? (
                  <Typography className={classes.titleLabelStyle}>
                    GST DETAILS
                  </Typography>
                ) : (
                  ""
                )}
                <div className={classes.setDisplayWidth}>
                  <div className={classes.dataDisplay}>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        TRADE NAME
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.tradeName || ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        LEGAL NAME
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.legalNameOfBusiness || ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        CONSTITUTION OF BUISNESS
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.constitutionOfBusiness || ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        TAXPAYER TYPE
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.taxpayerType || ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        NATURE OF BUISNESS ACTIVITY
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.natureOfBuisnessActivity || ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        GSTN STATUS
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.GSTINStatus || ""}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.dataDisplay}>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        STATE JURISDICTION
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.stateJurisdiction || ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        CENTER JURISDICTION
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.centreJurisdiction || ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        DATE OF REGISTRATION
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.dateOfRegistration ? moment(props?.dateOfRegistration).format("DD-MMM-YYYY") : ""}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        DATE OF CANCELLATION
                      </Typography>
                      <Typography className={classes.generalText}>
                        {moment(props?.dateOfCancellation).format("DD-MMM-YYYY") === "Invalid date" ? "N/A" :
                          moment(props?.dateOfCancellation).format("DD-MMM-YYYY")
                        }
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        LAST UPDATED DATE
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.lastUpdatedDate ? moment(props?.lastUpdatedDate).format("DD-MMM-YYYY") : ""}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.generalDetailsDisplaySection}>
                  {props?.gstAdditionalBuisnessAddressData &&
                    props?.gstAdditionalBuisnessAddressData.length > 0
                    ? props?.gstAdditionalBuisnessAddressData.map(
                      (
                        additionalBuisnessAddressData,
                        additionalBuisnessAddressDataIndex
                      ) => (
                        <div className={classes.dataDisplay}>
                          <Typography className={classes.titleLabelStyle}>
                            ADDITIONAL PLACE OF BUISNESS ADDRESS{" "}
                            {Number(additionalBuisnessAddressDataIndex) + 1}
                          </Typography>

                          <div className={classes.dataFields}>
                            <Typography className={classes.titleLabel}>
                              D Serial number
                            </Typography>
                            <Typography className={classes.formControl}>
                              {additionalBuisnessAddressData.D_sr_no}
                            </Typography>
                          </div>
                          <div className={classes.dataFields}>
                            <Typography className={classes.titleLabel}>
                              G Serial number
                            </Typography>
                            <Typography className={classes.formControl}>
                              {additionalBuisnessAddressData.G_sr_no}
                            </Typography>
                          </div>
                          <div className={classes.dataFields}>
                            <Typography className={classes.titleLabel}>
                              K Serial number
                            </Typography>
                            <Typography className={classes.formControl}>
                              {additionalBuisnessAddressData.K_sr_no}
                            </Typography>
                          </div>
                          <div className={classes.dataFields}>
                            <Typography className={classes.titleLabel}>
                              ADDRESS LINE 1
                            </Typography>
                            <Typography className={classes.generalText}>
                              {(additionalBuisnessAddressData.door_number ||
                                "") +
                                (additionalBuisnessAddressData.floor_number ||
                                  "") +
                                (additionalBuisnessAddressData.building_name ||
                                  "")}
                            </Typography>
                          </div>
                          <div className={classes.dataFields}>
                            <Typography className={classes.titleLabel}>
                              ADDRESS LINE 2
                            </Typography>
                            <Typography className={classes.generalText}>
                              {(additionalBuisnessAddressData.street || "") +
                                (additionalBuisnessAddressData.location ||
                                  "")}
                            </Typography>
                          </div>
                          <div className={classes.dataFields}>
                            <Typography className={classes.titleLabel}>
                              STATE NAME
                            </Typography>
                            <Typography className={classes.generalText}>
                              {additionalBuisnessAddressData.state_name || ""}
                            </Typography>
                          </div>
                          <div className={classes.dataFields}>
                            <Typography className={classes.titleLabel}>
                              PIN CODE
                            </Typography>
                            <Typography className={classes.generalText}>
                              {additionalBuisnessAddressData.pincode || ""}
                            </Typography>
                          </div>
                        </div>
                      )
                    )
                    : null}
                  <div className={classes.dataDisplay}>
                    <Typography className={classes.titleLabelStyle}>
                      PRINCIPAL PLACE OF BUISNESS ADDRESS
                    </Typography>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        ADDRESS LINE 1
                      </Typography>
                      <Typography className={classes.generalText}>
                        {(props?.principalBuisnessDoorNumber || "") +
                          (props?.principalBuisnessFloorNumber || "") +
                          (props?.principalBuisnessBuildingName || "")}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        ADDRESS LINE 2
                      </Typography>
                      <Typography className={classes.generalText}>
                        {(props?.principalBuisnessStreet || "") +
                          (props?.principalBuisnessLocation || "")}
                      </Typography>
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        STATE NAME
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.principalBuisnessAddressStateName || ""}
                      </Typography>
                    </div>
                    {props?.principalBuisnessAddressDistrictName && (
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          DISTRICT NAME
                        </Typography>
                        <Typography className={classes.generalText}>
                          {props?.principalBuisnessAddressDistrictName || ""}
                        </Typography>
                      </div>)}
                    {props?.principalBuisnessAddressCityName && (
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          CITY NAME
                        </Typography>
                        <Typography className={classes.generalText}>
                          {props?.principalBuisnessAddressCityName || ""}
                        </Typography>
                      </div>)}
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>
                        PIN CODE
                      </Typography>
                      <Typography className={classes.generalText}>
                        {props?.principalBuisnessAddressPinCode || ""}
                      </Typography>
                    </div>

                    {props?.principalBuisnessAddressD_sr_no && (
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          ERP SLNO (D)
                          {/* D_sr_no */}
                        </Typography>
                        <Typography className={classes.generalText}>
                          {props?.principalBuisnessAddressD_sr_no || ""}
                        </Typography>
                      </div>
                    )}
                    {props?.principalBuisnessAddressG_sr_no && (
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          ERP SLNO (G)
                          {/* G_sr_no */}
                        </Typography>
                        <Typography className={classes.generalText}>
                          {props?.principalBuisnessAddressG_sr_no || ""}
                        </Typography>
                      </div>)
                    }
                    {props?.principalBuisnessAddressK_sr_no && (
                      <div className={classes.dataFields}>
                        <Typography className={classes.titleLabel}>
                          ERP SLNO (K)
                          {/* K_sr_no */}
                        </Typography>
                        <Typography className={classes.generalText}>
                          {props?.principalBuisnessAddressK_sr_no || ""}
                        </Typography>
                      </div>
                    )}

                  </div>
                </div>
              </Card>
            ) : null
          ) : (
            <>
              <Typography className={classes.titleLabelStyle}>
                PAN DETAILS
              </Typography>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  PAN NUMBER *
                </Typography>
                {props?.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props?.panNumber}
                  </Typography>
                ) : (
                  <TextField
                    disabled={props?.customerOperation === "edit"}
                    autoComplete="off"
                    className={classes.formControl}
                    label="PAN NUMBER"
                    id="outlined-size-small"
                    value={props?.panNumber || ""}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("panNumber")}
                    error={props?.panNumberError}
                    helperText={
                      props?.panNumberError ? "Invalid pan number" : ""
                    }
                  />
                )}
              </div>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  PAN HOLDER NAME
                </Typography>
                {props?.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props?.panHolderName}
                  </Typography>
                ) : (
                  <TextField
                    disabled={props?.customerOperation === "edit"}
                    autoComplete="off"
                    className={classes.formControl}
                    label="PAN HOLDER NAME"
                    id="outlined-size-small"
                    value={props?.panHolderName || ""}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("panHolderName")}
                    error={props?.panHolderNameError}
                    helperText={
                      props?.panHolderNameError ? "Invalid pan holder name" : ""
                    }
                  />
                )}
              </div>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  PAN CARD IMAGE *
                </Typography>
                <div className={classes.formControlPanImage}>
                  {props?.customerOperation === "view" ||
                    props?.customerOperation === "edit" ? (
                    !props?.panImageUrl ? (
                      <>
                        <label
                          htmlFor="upload-button"
                          className={classes.fileUploadName}
                        >
                          <div className={classes.panImageUpload}>
                            <PresentToAllOutlinedIcon
                              className={classes.panImagePreview}
                            />
                            <Typography className={classes.generalText}>
                              Upload
                            </Typography>
                          </div>
                        </label>
                        <input
                          disabled={
                            props?.customerOperation === "view" ||
                            props?.customerOperation === "edit"
                          }
                          type="file"
                          accept="application/pdf, image/*"
                          id="upload-button"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            e.preventDefault();
                            props.handlePanImageChange(e);
                          }}
                        />
                      </>
                    ) : (
                      <span
                        onClick={() =>
                          window.open(props?.panImageUrl, "_blank")
                        }
                        className={classes.titleLabelStylePanImage}
                      >
                        {props?.panFileName}
                      </span>
                    )
                  ) : (
                    <>
                      <label
                        htmlFor="upload-button"
                        className={classes.fileUploadName}
                      >
                        {props?.uploadFileName ? (
                          <div className={classes.generalAlign}>
                            <Typography className={classes.generalText}>
                              {props?.uploadFileName}
                            </Typography>
                          </div>
                        ) : (
                          <div className={classes.panImageUpload}>
                            <PresentToAllOutlinedIcon
                              className={classes.panImagePreview}
                            />
                            <Typography className={classes.generalText}>
                              Upload
                            </Typography>
                          </div>
                        )}
                      </label>
                      <input
                        disabled={
                          props?.customerOperation === "view" ||
                          props?.customerOperation === "edit"
                        }
                        type="file"
                        accept="application/pdf, image/*"
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          e.preventDefault();
                          props.handlePanImageChange(e);
                        }}
                      />
                    </>
                  )}
                  {!props?.PanImagePreview ? null : (
                    <DeleteOutlineOutlinedIcon
                      width="20"
                      height="20"
                      onClick={props?.handleRemovePanImage}
                    />
                  )}
                </div>
              </div>
              {props.panCardFileTypeError && <span
                style={{ color: "red", fontSize: "12px" }}>
                {"Invalid File type"}
              </span>}
              <span
                style={{ fontSize: "10px" }}>
                {"Accepted File Types : pdf/jpg/jpeg/png"}
              </span>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  COMPANY NAME *
                </Typography>
                {props?.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props?.companyName}
                  </Typography>
                ) : (
                  <TextField
                    disabled={props?.customerOperation === "edit"}
                    autoComplete="off"
                    className={classes.formControl}
                    label="COMPANY NAME"
                    id="outlined-size-small"
                    value={props?.companyName || ""}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("companyName")}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {customerDontHaveGSTIN || gstDataValidated ? (
          <>
            {props?.customerAddressBox.length > 0 ? (
              <ManageCustomerAdditionalAddressesDisplay
                {...props}
                customerAddressBox={props?.customerAddressBox}
                removeNewCustomerAddressBox={props?.removeNewCustomerAddressBox}
                handleCustomerAddressBoxValueChange={
                  props?.handleCustomerAddressBoxValueChange
                }
                handleAddressRefreshButtonClick={props.handleAddressRefreshButtonClick}
                showPincodeRefreshButton={props?.showPincodeRefreshButton}
                pinCodeError={props.pinCodeError}
                country_codes={props.country_codes}
                customerSelectedCountryId={props.customerSelectedCountryId}
                selectedAddressState={props.selectedAddressState}
                selectedAddressCity={props.selectedAddressCity}
                showCustomerAddressEditIcon={props.showCustomerAddressEditIcon}
                handleUpdateAddressBoxOperation={
                  props?.handleUpdateAddressBoxOperation
                }
                selectedCountryAllStates={props?.selectedCountryAllStates}
                citiesAndStatesData={props?.citiesAndStatesData}
                handleSaveCustomerAddresses={props?.handleSaveCustomerAddresses}
                customerOperation={props?.customerOperation}
                saveLoader={props?.saveLoader}
              />
            ) : null}
            {props?.displayAddMoreAddressButton ? (
              <AddMoreDottedButtonComponent
                {...props}
                buttonOnClick={props?.addNewCustomerAddressBox}
                buttonTitle={"+ ADD MORE ADDRESS"}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </Paper>
  );
}
