import React, { Component } from "react";
import WebLayout from "../../../layouts/b2b/WebLayout";
import "../../../styles/b2b/web/B2bProfileWeb.css";
import { withStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import { primaryColor } from "../../../styles/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { MenuItem, MenuList } from "@material-ui/core";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const styles = {
  b2bProfileWebMainBody: {
    // paddingTop: "80px",
    //  height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "100%",
  },
  b2bProfileWebInternalBody: {
    height: "100%",
    width: "auto",
    padding: "50px 25px 10px 20px",
  },
  menuItemStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 0
  },
  menuItemText: {
    fontSize: "1em",
    fontWeight: 500,
    color: primaryColor
  }
};
class B2bProfileWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, user } = this.props;
    // console.log(this.props.user)
    return (
      <WebLayout appBar profileSelect>
        <div className={classes.b2bProfileWebMainBody}>
          <div className={classes.b2bProfileWebInternalBody}>
            <div className="b2b-profile-web-header-name-container">
              <p className="b2b-profile-web-header-name-text">
                {user.first_name} {user.last_name}
              </p>
              {/* <span
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile/settings/editProfile")
                }}
              >
                <Edit />
              </span> */}

            </div>
            <div className="b2b-profile-web-email-header">
              <div className="b2b-profile-web-email-contanier">
                <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                  Email
                </span>
                <span>{user.email}</span>
              </div>
              <div className="b2b-profile-web-gst-container">
                <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                  {user.gst_information ? "GSTIN" : "PAN"}
                </span>
                <span>{user?.gst_information ? user?.gst_information?.gstin_number
                  : user?.pan_card_information?.pan_card_number
                }</span>
              </div>
            </div>
            <br />
            <div className="b2b-profile-web-email-header">
              <div className="b2b-profile-web-email-contanier">
                <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                  Contact
                </span>
                <span>+91 {user.phone}</span>
              </div>

              <div className="b2b-profile-web-gst-container">
                <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                  Address
                </span>
                <span>
                  {
                    user?.address?.address ?
                      `${user?.address?.address} - ${user?.address?.pincode}`
                      :
                      `${user?.address?.door_number && `#${user?.address?.door_number},`}
                      ${user?.address?.floor_number &&
                      `${user?.address?.floor_number === 0 ? null :
                        user?.address?.floor_number}${user?.address?.floor_number === "0" ?
                          null : user?.address?.floor_number === "1" ? "st" :
                            user?.address?.floor_number === "2" ? "nd" : "rd"
                      } floor,`}
                      ${user?.address?.building_name && `,${user?.address?.building_name},`}
                      ${user?.address?.street && `${user?.address?.street},`}
                      ${user?.address?.location && `${user?.address?.location},`}
                      ${user?.address?.city_name && `${user?.address?.city_name}`}
                      ${user?.address?.pincode && `-${user?.address?.pincode}`}`
                  }
                </span>
              </div>
            </div>
            <br />
            {/* <div className="b2b-profile-web-email-header">
              <div className="b2b-profile-web-email-contanier">
                <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                  UID
                </span>
                <span>{
                  user.credentials ?
                    user.credentials.employee_id ?
                      user.credentials.employee_id
                      : ""
                    : ""
                }</span>
              </div>
            </div> */}
            <br />

            <div style={{
              display: 'flex',
              flexDirection: 'column'
              // width: "20%" 
            }}>
              <div
                style={{ width: "30%" }}
              >
                <MenuList style={styles.menuListStyle}>
                  <MenuItem
                    style={styles.menuItemStyle}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.fetchLedgerRequest()
                      this.props.history.push("/b2bProfile/ledger")
                    }}
                  >
                    <span style={styles.menuItemText}>
                      Ledger Request
                    </span>
                    <KeyboardArrowRightOutlinedIcon />
                  </MenuItem>
                </MenuList>
              </div>
              <div
                style={{ width: "30%" }}
              >
                <MenuList style={styles.menuListStyle}>
                  <MenuItem
                    style={styles.menuItemStyle}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/b2bProfile/settings/changePassword");
                    }}
                  >
                    <span style={styles.menuItemText}>
                      Change Password
                    </span>
                    <KeyboardArrowRightOutlinedIcon />
                  </MenuItem>
                </MenuList>
              </div>
              {/* <List
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    "/b2bProfile/settings/changePassword"
                  );
                }}
              >
                <ListItem style={{ paddingLeft: "0px" }}>
                  <ListItemText
                    primary="Change Password"
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.2em",
                        color: primaryColor,
                      },
                    }}
                  />
                  <ListItemSecondaryAction style={{ display: "flex" }}>
                    <img
                      style={{ width: "1em", height: "1em" }}
                      src={
                        window.cordova
                          ? `${this.image}${"/arrowRight.svg"}`
                          : "/arrowRight.svg"
                      }
                      alt="img"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List> */}
            </div>
          </div>
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(B2bProfileWeb);
