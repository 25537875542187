import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  OutlinedInput,
  InputAdornment,
  Button,
  TextField,
  TablePagination,
  IconButton,
  ThemeProvider,
  createMuiTheme,
  Typography
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  successButtonBackground,
  whiteSmoke,
  drawerTextColor,
  secondaryBlueText,
  earthBlue,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../methods";
import SaveButton from "../../common/SaveButton";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Popover from '@mui/material/Popover';
import COLORS from "../../../../constants/internal/colors";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from "@material-ui/icons/Delete";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 10,
    width: "100%",
    height: "calc(100vh - 400px)",
    overflow: "auto",
  },
  inputWidth: { width: "25ch", maxWidth: 200, height: 40 },
  tableHead: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    width: "0%",
  },
  tableBodyForCityData: {
    fontSize: pxToEm(14),
    width: "0%",
  },
  discountFieldForCityData: {
    fontSize: pxToEm(14),
    //width: "25%",
    display: "flex",
    alignItems: "center",
  },
  tableBodyForPaymentTermData: {
    fontSize: pxToEm(14),
  },
  alignText: {
    display: "flex",
    flexWrap: "wrap",
    placeContent: "center",
  },
  alignBodyText: {
    display: "flex",
    alignItems: "center",
  },
  rightSpacing: {
    marginRight: 10,
  },
  stateSelection: {
    marginRight: 10,
    width: "100%",
  },
  arrowDimension: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: "bold",
  },
  container: {
    marginTop: 10,
  },
  removeOption: {
    color: secondaryGrey,
    fontSize: pxToEm(13),
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveCancelButtonStyles: {
    paddingTop: 10,
  },
  paymentTermMainDiv: {
    marginTop: "20px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    color: grey,
  },
  editTextIcon: {
    borderRadius: 100,
    padding: 2,
    marginLeft: "10px",
    width: "70px",
    display: "flex",
    backgroundColor: whiteSmoke,
    color: grey,
    cursor: "pointer",
  },
  editText: {
    flex: 1,
    marginLeft: 5,
    textAlign: "left",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
  },
  itemCategoryActionIcon: { marginLeft: 5 },
  popoverBox: {
    '& .MuiPopover-paper': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
      marginBottom: 10,
      // borderRadius: 20,
    }
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '500',
    alignItems: "center"
  },
  DiscountValidFromFieldWidth: { width: "45%" },
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },

  },
});

export default function ManageItemCategoryCityTab(props) {
  const classes = useStyles();
  const [paymentTermOrCityTableOperation, setPaymentTermOrCityTableOperation] =
    React.useState("view");
  const handlePriceUpdate = (data, index) => {
    let updatedData = {
      discount_amount: data.discount_amount,
      item_category: data.item_category,
      valid_from: data?.valid_from
    };
    props.handleUpdateDiscountAmount(
      updatedData,
      props.displayDataFor,
      data.id
    );

    // const updatedRows = [...rows];
    // updatedRows[index]["discount_amount"] = event.target.value;
    // setRows(updatedRows);
  };

  function handlePaymentTermOrCityTableOperation(operation) {
    setPaymentTermOrCityTableOperation(operation);
    if (operation === "add") {
      props.handlePaymentOrCityStateValue("city")
    }
  }

  function handlePaymentTermOrCityTableSave(operation) {
    props.handlePaymentTermOrCityTableSaveData(props.displayDataFor);
    setPaymentTermOrCityTableOperation(operation);
  }

  const handleDiscountValueChange = (index) => (e) => {
    const { value, name } = e.target;
    const list = [...rows];
    list[index].discount_amount = value;
    setRows(list);
  };

  const handleDiscountValidFromChange = (index) => (value) => {
    const list = [...rows];
    list[index].valid_from = moment(value).format("YYYY-MM-DD");
    setRows(list);
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [allSelectionOptions, setAllSelectionOption] = React.useState(
    props?.allCities || []
  );

  const [rows, setRows] = React.useState(props?.itemCategoryCityData || []);

  const [title, setTitle] = React.useState(" CITY ");

  // props.dailyRateTemplateData;
  const filteredSelectionsList = allSelectionOptions.filter(
    (x) =>
      !rows.filter((y) => {
        // if (props.customerType === 'DEALER') {
        //   return y.distributor.id === x.id;
        // } else if (props.customerType === 'DISTRIBUTOR') {
        return y.city_name === x.name;
        // } else {
        //   return true;
        // }
      }).length
  );

  const ClickedRateDiffBox = (props) => {
    let data = props.data;
    console.log(data)
    return (
      <div style={{ width: 350, padding: "5%" }}>
        <Typography className={classes.dialogText}>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Discount amount
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid from
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid upto
          </div>
        </Typography>
        <Divider variant='middle' style={{ margin: '2% 0' }} />
        {data?.city_discount_logs?.map((rateDiffData, rdindex) => {
          return (
            <div key={rdindex}>
              <Typography className={classes.dialogText}>
                <div>
                  ₹ {rateDiffData?.value}

                </div>
                <div style={{
                  marginLeft: !rateDiffData?.valid_upto
                    ? null : "70px",
                }}
                >
                  {!rateDiffData?.valid_from
                    ? null
                    : moment(rateDiffData?.valid_from).format("DD-MM-YYYY")}
                  {/* 12 aug 2021 */}
                </div>
                <div>
                  {!rateDiffData?.valid_upto
                    ? null
                    : moment(rateDiffData?.valid_upto).format("DD-MM-YYYY")}
                  {/* 23 april 2022 */}
                </div>
              </Typography>
              <Divider
                variant='middle'
                style={{
                  margin: '2% 0',
                  display:
                    rdindex === (data?.city_discount_logs?.length - 1) ? 'none' :
                      'block'
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const Mousehover = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (e) => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            fontFamily: 'Poppins',
            fontWeight: anchorEl === null ? "400" : "500",
            color: anchorEl === null ? "#666" : "#3B69B0",
            cursor: 'pointer',
            marginTop: "2px",
            marginLeft: "5px"
          }}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          className={classes.popoverBox}
        >
          <Typography sx={{ p: 1 }}>
            {props.content}
          </Typography>
        </Popover>
      </div>
    );
  }

  React.useEffect(() => {
    if (props?.allCities) {
      setAllSelectionOption(props?.allCities || []);
    }

    if (props?.itemCategoryCityData) {
      setRows(props?.itemCategoryCityData || []);
    }
  }, [props?.allCities, props?.itemCategoryCityData]);

  const addNewEndRef = useRef(null)

  const scrollToBottom = () => {
    addNewEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <div className={classes.paymentTermMainDiv}>
      <div className={classes.iconContainer}>
        <span className={classes.contentTitle}>DISCOUNT BASED ON {title}</span>
        <ToolTipComponent
          title="You have no edit permission on this widget"
          condition={!hasUpdateAccess(props.user, 'item_categories')}
        >
          <div
            className={classes.editTextIcon}
            // onClick={() => handlePaymentTermOrCityTableOperation("edit")}
            onClick={() => {
              if (paymentTermOrCityTableOperation === "edit") {
                props.handleFetchItemCategoryCityData();
                handlePaymentTermOrCityTableOperation("view")
              } else {
                hasUpdateAccess(props.user, 'item_categories') &&
                  handlePaymentTermOrCityTableOperation("edit")
              }
            }}
          >
            <EditOutlinedIcon className={classes.icon} />
            {/* <div className={classes.editText}>edit</div> */}
            <div
              className={classes.editText}
              style={{
                color: !hasUpdateAccess(props.user, 'item_categories') && 'rgba(0,0,0,0.26)'
              }}
            >
              {paymentTermOrCityTableOperation === "edit" ? "view" : "edit"}
            </div>
          </div>
        </ToolTipComponent>
      </div>

      <Paper>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableHead}>
                  CITY
                </TableCell>

                <TableCell align="left" className={classes.tableHead}>
                  PIN CODE
                </TableCell>

                <TableCell align="left" className={classes.tableHead}>
                  DISCOUNT (PER M.T.)
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  EFFECTIVE FROM
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : rows
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    <span className={classes.tableBodyForCityData}>
                      {row?.city_name || ""}
                    </span>
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    <span className={classes.tableBodyForCityData}>
                      {row?.pincodes[0]?.pincode || ""}
                    </span>
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.tableBodyForCityData}
                    style={{ fontSize: pxToEm(14) }}
                  >
                    {paymentTermOrCityTableOperation === "view" ||
                      paymentTermOrCityTableOperation === "add" ? (
                      <div className={classes.discountFieldForCityData}>
                        <span>₹ {parseFloat(row?.discount_amount).toFixed(3)}</span>
                        <span><Mousehover
                          content={<ClickedRateDiffBox
                            data={row}
                          />}
                        /></span>
                      </div>
                    ) : (
                      <>
                        <OutlinedInput
                          type="number"
                          className={classes.inputWidth}
                          id="outlined-adornment-amount"
                          value={row?.discount_amount || ""}
                          onWheel={(e) => e.target.blur()}
                          onChange={handleDiscountValueChange((page * rowsPerPage) + index)}
                          onKeyDown={(evt) => (
                            ["e", "E", "+", "-"].includes(evt.key) ||
                            (evt.which === 38 || evt.which === 40)
                          ) && evt.preventDefault()}
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          size="small"
                          error={!row.discount_amount || Number(row.discount_amount) < 0}
                          helperText={(!row.discount_amount || Number(row.discount_amount) < 0)
                            ?
                            "Invalid Discount" : " "
                          }
                        />
                        {/* <IconButton
                          aria-label="delete"
                          className={classes.itemCategoryActionIcon}
                          onClick={(e) => handlePriceUpdate(row, (page * rowsPerPage) + index)}
                          disabled={!row?.discount_amount || Number(row?.discount_amount) < 0}
                        >
                          <CheckCircleOutlineOutlinedIcon fontSize="small" />
                        </IconButton> */}
                      </>
                    )}
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    {paymentTermOrCityTableOperation === "view" ||
                      paymentTermOrCityTableOperation === "add" ? (
                      <span className={classes.tableBodyForCityData}>
                        {!row.valid_from ? null : moment(row.valid_from).format("DD MMM YYYY")}
                      </span>
                    ) : (
                      <>
                        <ThemeProvider theme={materialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              name="valid_from"
                              size="small"
                              inputVariant="outlined"
                              label="Effective From"
                              format="dd/MM/yyyy"
                              className={classes.inputWidth}
                              //disablePast={new Date(row?.valid_from) > new Date() ? new Date(row?.valid_from) : new Date()}
                              // minDate={row?.valid_from
                              //   ? new Date(row?.valid_from)
                              //   : new Date()}
                              minDate={new Date(row?.valid_from)}
                              value={!row?.valid_from ? null : new Date(row?.valid_from)}
                              //helperText={(formik.errors.insurance_rate_valid_from && formik.touched.insurance_rate_valid_from) ? String(formik.errors.insurance_rate_valid_from) : " "}
                              // onChange={(e) => {
                              //   handleInsuranceRateValidFromChange(e)
                              //   formik.setFieldValue(
                              //     "insurance_rate_valid_from",
                              //     e
                              //   );
                              // }}
                              helperText={null}
                              error={null}
                              onChange={handleDiscountValidFromChange((page * rowsPerPage) + index)}
                              placeholder="e.g.: DD/MM/YYYY"
                              InputAdornmentProps={{ position: "end", }}
                              InputProps={{
                                style: {
                                  paddingRight: 0,
                                },
                                readOnly: true
                              }}
                            // error={Boolean(
                            //   formik.errors.insurance_rate_valid_from &&
                            //   formik.touched.insurance_rate_valid_from
                            // )}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                        <IconButton
                          aria-label="delete"
                          className={classes.itemCategoryActionIcon}
                          onClick={(e) => handlePriceUpdate(row, (page * rowsPerPage) + index)}
                          disabled={!row?.discount_amount || Number(row?.discount_amount) < 0 || !row?.valid_from}
                        >
                          <CheckCircleOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    <ToolTipComponent
                      title="You have no edit permission on this widget"
                      condition={!hasUpdateAccess(props.user, 'item_categories')}
                    >
                      <IconButton
                        className={classes.margin}
                        onClick={() =>
                          props.handleDeleteCityDiscount(row?.id)
                        }
                        size="small"
                        disabled={!hasUpdateAccess(props.user, 'item_categories')}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ToolTipComponent>
                  </TableCell>

                </TableRow>
              ))}
              {paymentTermOrCityTableOperation === "add" ? (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    {paymentTermOrCityTableOperation === "view" ? (
                      <span className={classes.tableBodyForCityData}>
                        ₹ {parseInt(Math.random())}
                      </span>
                    ) : (
                      <Autocomplete
                        size="small"
                        options={filteredSelectionsList}
                        getOptionLabel={(option) => option.name}
                        onChange={props.handleCityTableAutocompleteValueChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Select city`}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    <span className={classes.tableBodyForCityData}>
                      {props?.pincode}
                    </span>
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    {paymentTermOrCityTableOperation === "view" ? (
                      <span className={classes.tableBodyForCityData}>
                        ₹ {parseInt(Math.random())}
                      </span>
                    ) : (
                      <OutlinedInput
                        type="number"
                        className={classes.inputWidth}
                        value={props.cityDiscountAmount}
                        onWheel={(e) => e.target.blur()}
                        onChange={props.handleChange("cityDiscountAmount")}
                        onKeyDown={(evt) => (
                          ["e", "E", "+", "-"].includes(evt.key) ||
                          (evt.which === 38 || evt.which === 40)
                        ) && evt.preventDefault()}
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        size="small"
                        error={props.cityDiscountAmountError}
                        helperText={
                          props?.cityDiscountAmountError ?
                            "Invalid discount amount"
                            :
                            ""
                        }
                      />
                    )}
                  </TableCell>

                  <TableCell
                    align="left"
                    className={classes.tableBodyForCityData}
                  >
                    {paymentTermOrCityTableOperation === "view" ? (
                      <span className={classes.tableBodyForCityData}>
                        {/* {!row.valid_from ? null : moment(row.valid_from).format("DD MMM YYYY")} */}
                        16 aug 2023
                      </span>
                    ) : (
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            name="valid_from"
                            size="small"
                            inputVariant="outlined"
                            label="Effective From"
                            format="dd/MM/yyyy"
                            className={classes.inputWidth}
                            //disablePast={new Date(props.effectiveDate) > new Date() ? new Date(props.effectiveDate) : new Date()}
                            // minDate={props?.effectiveDate
                            //   ? new Date(props?.effectiveDate)
                            //   : new Date()}
                            minDate={new Date(props.effectiveDate)}
                            value={!props.effectiveDate ? null : new Date(props.effectiveDate)}
                            //helperText={(formik.errors.insurance_rate_valid_from && formik.touched.insurance_rate_valid_from) ? String(formik.errors.insurance_rate_valid_from) : " "}
                            // onChange={(e) => {
                            //   handleInsuranceRateValidFromChange(e)
                            //   formik.setFieldValue(
                            //     "insurance_rate_valid_from",
                            //     e
                            //   );
                            // }}
                            onChange={props.handleChange("effectiveDate")}
                            placeholder="e.g.: DD/MM/YYYY"
                            InputAdornmentProps={{ position: "end", }}
                            InputProps={{
                              style: {
                                paddingRight: 0,
                              },
                              readOnly: true
                            }}
                          // error={Boolean(
                          //   formik.errors.insurance_rate_valid_from &&
                          //   formik.touched.insurance_rate_valid_from
                          // )}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    )}
                  </TableCell>
                  {/* <TableCell align="right" className={classes.removeOption} /> */}
                </TableRow>
              ) : null}
              <div ref={addNewEndRef} />
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
      {paymentTermOrCityTableOperation === "view" ? (
        <ToolTipComponent
          title="You have no create permission on this widget"
          condition={!hasWriteAccess(props.user, 'item_categories')}
        >
          <Button
            variant="outlined"
            size="medium"
            startIcon={<CheckOutlinedIcon />}
            className={classes.addNewButton}
            disabled={!hasWriteAccess(props.user, 'item_categories')}
            onClick={() => {
              handlePaymentTermOrCityTableOperation("add")
              setTimeout(() => scrollToBottom(), 10)
            }}
          >
            ADD NEW
          </Button>
        </ToolTipComponent>
      ) : (
        <>
          <div className={classes.saveCancelButtonStyles}>
            {console.log(props.selectedCityId, props.cityDiscountAmount)}
            {paymentTermOrCityTableOperation === "edit" ? null : (
              <SaveButton
                size="medium"
                disabled={
                  !props.selectedCityId ||
                  !props.cityDiscountAmount ||
                  props.cityDiscountAmountError ||
                  !props.effectiveDate
                }
                className={classes.saveButton}
                variant="contained"
                startIcon={<CheckOutlinedIcon />}
                onClick={() => {
                  handlePaymentTermOrCityTableSave("view");
                }}
                isLoading={props?.isLoadingCity}
              >
                Save
              </SaveButton>
            )}
            <Button
              size="medium"
              className={classes.margin}
              onClick={() => {
                if (paymentTermOrCityTableOperation === "edit") {
                  props.handleFetchItemCategoryCityData();
                }
                handlePaymentTermOrCityTableOperation("view")
                props.handleCancelButton("city")
              }
              }
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
