import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SendIcon from '@material-ui/icons/Send';
import HistoryIcon from '@material-ui/icons/History';
import BaseStateIcon from '@material-ui/icons/StarBorderOutlined';
import {
  whiteSmoke,
  primaryColor,
  drawerTextColor,
  orange,
  grey,
} from '../../../../constants/internal/colors';
import {
  Typography,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import { pxToEm, hasWriteAccess } from '../../../../methods';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  orangeInput: {
    color: orange,
    borderColor: orange,
  },
  inputWidth: {
    maxWidth: 130,
    height: 35,
  },
  productsStatePriceMainDiv: { alignItems: 'center', justifyContent: 'center' },
  publishDiv: {
    display: 'flex',
    padding: 10,
    placeItems: 'center',
    justifyContent: 'space-between',
  },
  publishDetailsDiv: {
    display: 'flex',

    justifyContent: 'flex-start',
  },
  publishActionDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightSpacing: {
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
  },
  alignText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignStateText: {
    display: 'flex',
    placeContent: 'flex-start',
  },
  arrowDimension: {
    height: 18,
    width: 18,
    margin: '0px 5px 0px 5px',
  },
  orangeText: {
    color: orange,
    borderColor: `${orange} ! important`,
    placeContent: 'center',
    placeItems: 'center',
  },
  captionText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: pxToEm(13),
  },
  loaderStyle: { color: primaryColor },
  loaderDiv: {
    margin: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: whiteSmoke,
    fontWeight: 'bold',
    fontSize: pxToEm(15),
    color: grey,
  },
  body: {
    fontSize: pxToEm(15),
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '10px 5px 10px 5px',
      },
    },
  },
});

export default function ProductsStatePrice({
  selectedCategoryIdBaseState,
  selectedCategoryIdStatesWithoutBaseState,
  isPublished,
  selectedCategoryId,
  handlePublishRates,
  handleResetRates,
  publishDate,
  handleProductPriceChange,
  handleBaseRateChange,
  publishLoader,
  disablePublishButton,
  selectedCategoryIdBaseStateLoader,
  handleUnpublishDailyRates,
  user,
}) {
  const classes = useStyles();
  return (
    <div className={classes.productsStatePriceMainDiv}>
      {selectedCategoryIdBaseStateLoader ? (
        <div className={classes.loaderDiv}>
          <CircularProgress disableShrink className={classes.loaderStyle} />
        </div>
      ) : (selectedCategoryIdBaseState &&
        selectedCategoryIdBaseState.length < 1) ||
        selectedCategoryIdBaseState === undefined ? (
        <div className={classes.loaderDiv}>
          <Typography className={classes.loaderStyle}>
            State details not found in master plase try updating master.
          </Typography>
        </div>
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <TableContainer
              id="scroll"
              component={Paper}
              className={classes.container}
            >
              <Table
                stickyHeader
                className={classes.table}
                aria-label="products-state-price-table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">STATE</StyledTableCell>
                    <StyledTableCell align="center">
                      STATE DIFFERENCE
                    </StyledTableCell>
                    <StyledTableCell align="center">OPEN RATE</StyledTableCell>
                    <StyledTableCell align="center">
                      RATE DIFFERENCE
                    </StyledTableCell>
                    <StyledTableCell align="center">NET RATE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCategoryIdBaseState ? (
                    <StyledTableRow
                      key={selectedCategoryIdBaseState?.state?.state_code}
                    >
                      <StyledTableCell component="th" scope="row">
                        <div className={classes.alignStateText}>
                          <div>
                            <BaseStateIcon className={classes.arrowDimension} />
                          </div>
                          <div className={classes.rightSpacing}>
                            {selectedCategoryIdBaseState?.state?.state_name}
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align={isPublished ? 'right' : 'center'}>
                        <div
                          className={
                            isPublished
                              ? classes.publishActionDiv
                              : classes.alignText
                          }
                        >
                          <div>BASE RATE</div>
                          <div className={classes.alignText}>
                            <ArrowForwardIcon
                              className={classes.arrowDimension}
                            />
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align={isPublished ? 'right' : 'center'}>
                        <div>
                          <div>
                            {isPublished ||
                              !hasWriteAccess(user, 'openRates') ? (
                              ` ₹ ${parseFloat(selectedCategoryIdBaseState.high_rate).toFixed(2)}`
                            ) : (
                              <OutlinedInput
                                type="number"
                                className={classes.inputWidth}
                                value={selectedCategoryIdBaseState.high_rate}
                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                onWheel={(e) => e.target.blur()}
                                onChange={handleProductPriceChange(
                                  'onChange',
                                  selectedCategoryId,
                                  'high_rate',
                                  selectedCategoryIdBaseState?.state?.id,
                                  true
                                )}
                                onBlur={handleBaseRateChange(
                                  selectedCategoryId,
                                  'high_rate',
                                  selectedCategoryIdBaseState?.state?.id,
                                  true
                                )}
                                startAdornment={
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                }
                                size="small"
                              />
                            )}
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align={isPublished ? 'right' : 'center'}>
                        {isPublished || !hasWriteAccess(user, 'openRates') ? (
                          `₹ ${parseFloat(selectedCategoryIdBaseState.low_rate_difference).toFixed(2)}`
                        ) : (
                          <div className={classes.orangeText}>
                            <div>
                              <OutlinedInput
                                type="number"
                                className={classes.inputWidth}
                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                onWheel={(e) => e.target.blur()}
                                value={
                                  selectedCategoryIdBaseState.low_rate_difference
                                }
                                variant="outlined"
                                onChange={handleProductPriceChange(
                                  'onChange',
                                  selectedCategoryId,
                                  'low_rate_difference',
                                  selectedCategoryIdBaseState?.state?.id,
                                  true
                                )}
                                onBlur={handleProductPriceChange(
                                  'onBlur',
                                  selectedCategoryId,
                                  'low_rate_difference',
                                  selectedCategoryIdBaseState?.state?.id,
                                  true
                                )}
                                startAdornment={
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                }
                                // InputProps={{
                                //   className: classes.orangeInput,
                                // }}
                                size="small"
                              />
                              <div className={classes.captionText}>
                                {selectedCategoryIdBaseState.low_rate_difference !==
                                  selectedCategoryIdBaseState?.low_rate_template_difference
                                  ? `Template :
                          ${selectedCategoryIdBaseState?.low_rate_template_difference}`
                                  : ''}
                              </div>
                            </div>
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align={isPublished ? 'right' : 'center'}>
                        ₹{' '}
                        {parseFloat(
                          Number(selectedCategoryIdBaseState?.low_rate || 0)
                        ).toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : null}
                  {selectedCategoryIdStatesWithoutBaseState &&
                    selectedCategoryIdStatesWithoutBaseState.length > 0
                    ? selectedCategoryIdStatesWithoutBaseState.map(
                      (states, index) => (
                        <StyledTableRow key={states?.state?.state_code}>
                          <StyledTableCell component="th" scope="row">
                            <div className={classes.alignStateText}>
                              <div className={classes.arrowDimension}></div>
                              <div className={classes.rightSpacing}>
                                {states?.state?.state_name}
                              </div>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align={isPublished ? 'right' : 'center'}
                          >
                            {isPublished ||
                              !hasWriteAccess(user, 'openRates') ? (
                              `₹ ${parseFloat(states.high_rate_difference).toFixed(2)}`
                            ) : (
                              <div className={classes.orangeText}>
                                <div>
                                  <OutlinedInput
                                    type="number"
                                    className={classes.inputWidth}
                                    value={states.high_rate_difference}
                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleProductPriceChange(
                                      'onChange',
                                      selectedCategoryId,
                                      'high_rate_difference',
                                      states?.state?.id,
                                      false
                                    )}
                                    onBlur={handleProductPriceChange(
                                      'onBlur',
                                      selectedCategoryId,
                                      'high_rate_difference',
                                      states?.state?.id,
                                      false
                                    )}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        ₹
                                      </InputAdornment>
                                    }
                                    size="small"
                                  />
                                  <div className={classes.captionText}>
                                    {states.high_rate_difference !==
                                      states?.high_rate_template_difference
                                      ? `Template :
                          ${states?.high_rate_template_difference}`
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align={isPublished ? 'right' : 'center'}
                          >
                            ₹{' '}
                            {parseFloat(
                              Number(states?.high_rate || 0)
                            ).toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell
                            align={isPublished ? 'right' : 'center'}
                          >
                            {isPublished ||
                              !hasWriteAccess(user, 'openRates') ? (
                              `₹ ${parseFloat(states.low_rate_difference).toFixed(2)}`
                            ) : (
                              <div className={classes.orangeText}>
                                {' '}
                                <div>
                                  <OutlinedInput
                                    type="number"
                                    className={classes.inputWidth}
                                    value={states.low_rate_difference}
                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleProductPriceChange(
                                      'onChange',
                                      selectedCategoryId,
                                      'low_rate_difference',
                                      states?.state?.id,
                                      false
                                    )}
                                    onBlur={handleProductPriceChange(
                                      'onBlur',
                                      selectedCategoryId,
                                      'low_rate_difference',
                                      states?.state?.id,
                                      false
                                    )}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        ₹
                                      </InputAdornment>
                                    }
                                    size="small"
                                  />
                                  <div className={classes.captionText}>
                                    {states.low_rate_difference !==
                                      states?.low_rate_template_difference
                                      ? `Template :
                              ${states?.low_rate_template_difference}`
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align={isPublished ? 'right' : 'center'}
                          >
                            ₹{' '}
                            {parseFloat(
                              Number(states?.low_rate || 0)
                            ).toFixed(2)}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </ThemeProvider>

          {selectedCategoryId ? (
            <div className={classes.publishDiv}>
              <div className={classes.publishDetailsDiv}>
                <Typography variant="caption">
                  {isPublished
                    ? 'Rates published at ' +
                    moment(publishDate).format('MMMM Do YYYY, h:mm:ss a')
                    : 'No rates published today'}
                </Typography>
              </div>
              <div className={classes.publishActionDiv}>
                <>
                  {' '}
                  {hasWriteAccess(user, 'openRates') && (
                    <>
                      {isPublished ? null : (
                        <Button
                          startIcon={<HistoryIcon />}
                          onClick={() => handleResetRates(selectedCategoryId)}
                          className={classes.rightSpacing}
                        >
                          RESET
                        </Button>
                      )}
                      {publishLoader ? (
                        <CircularProgress
                          disableShrink
                          className={classes.loaderStyle}
                        />
                      ) : (
                        <Button
                          className={classes.primaryBackground}
                          variant="contained"
                          size="small"
                          disabled={disablePublishButton}
                          startIcon={<SendIcon />}
                          onClick={() =>
                            isPublished === true
                              ? handleUnpublishDailyRates(selectedCategoryId)
                              : handlePublishRates(selectedCategoryId)
                          }
                        >
                          {isPublished === true ? 'UN-' : ''}PUBLISH RATES
                        </Button>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
