import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
} from "../../../styles/colors";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import PaymentOptionCard from "./PaymentOptionCard";
import { checkInvalidValue, pxToEm } from "../../../methods";

const styles = {
  createDispatchBuyerDetailsMainContainer: {
    width: "100%",
    height: window.innerHeight - 126,
    maxHeight: window.innerHeight - 126,
    padding: "0px 20px",
    overflowY: "scroll",
  },
  buyerDetailShowContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  },
  buyerDetailTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.19em",
    fontWeight: 500,
  },
  formControlStyle: {
    margin: "20px 0px 6px 0px",
    width: "100%",
  },
  paymentTermContainer: {
    display: "flex",
    flexDirection: "column",
  },
  paymentOptionCardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "22px",
    flexDirection: "column",
    marginBottom: 20
  },
  selectedBuyerStyle: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
    fontSize: window.innerWidth < 375 && pxToEm(13)
  },
  buyerNameTextStyle: {
    color: bottomNavigationFontColor,
    fontSize: "0.9em",
    fontWeight: 600
  },
};
class CreateDispatchBuyerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerSelected: null,
      selectedBuyer: null,
      // consignee,
      selectedConsignee: null,
      consigneeSelected: null,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!checkInvalidValue(this.props?.subContract) &&
      (prevProps.selectedBuyerDataForLA !== this.props.selectedBuyerDataForLA ||
        prevProps.selectedConsigneeDataForLA !== this.props.selectedConsigneeDataForLA)) {
      if (!checkInvalidValue(this.props?.subContract) && !checkInvalidValue(this.props?.selectedBuyerDataForLA) && !checkInvalidValue(this.props?.selectedConsigneeDataForLA)) {
        console.log("called BUYER - ", this.props.selectedBuyerDataForLA, " CONSIGNEE -", this.props.selectedConsigneeDataForLA, "PAYMENT - ", this.props.selectedPaymentMethodDataForLA)
        this.setState({ selectedBuyer: this.props.selectedBuyerDataForLA, buyerSelected: true });
        this.props.handleSelectBuyerName(this.props.selectedBuyerDataForLA)
        this.setState({ selectedConsignee: this.props.selectedConsigneeDataForLA, consigneeSelected: true });
        this.props.handleSelectConsigneeName(this.props.selectedConsigneeDataForLA);
      }
    }
    if (this.props.buyers && this.props.selectedBuyerDataForLA && !this.state.selectedBuyer) {
      // console.log(this.props.selectedBuyerDataForLA)
      this.setState({ selectedBuyer: this.props.selectedBuyerDataForLA, buyerSelected: true });
      this.props.handleSelectBuyerName(this.props.selectedBuyerDataForLA);
    }
    if (this.props.consignee && this.props.selectedConsigneeDataForLA && !this.state.consigneeSelected) {
      console.log(this.props.selectedConsigneeDataForLA, "here1")
      this.setState({ selectedConsignee: this.props.selectedConsigneeDataForLA, consigneeSelected: true });
      this.props.handleSelectConsigneeName(this.props.selectedConsigneeDataForLA);
    }
    if (this.props.buyers && this.props.buyers.length === 1 && !this.state.buyerSelected) {
      this.setState({ selectedBuyer: this.props.buyers[0], buyerSelected: true });
      this.props.handleSelectBuyerName(this.props.buyers[0]);
    }
    if (this.props.consignee && this.props.consignee.length === 1 && !this.state.consigneeSelected) {
      console.log(this.props.consignee[0], "here2")
      this.setState({ selectedConsignee: this.props.consignee[0], consigneeSelected: true });
      this.props.handleSelectConsigneeName(this.props.consignee[0]);
    }
  }
  handleSelectBuyer = (data) => {
    this.setState({ selectedBuyer: data, buyerSelected: true });
  };
  handleSelectConsignee = (data) => {
    this.setState({ selectedConsignee: data, consigneeSelected: true });
  };
  render() {
    const {
      // buyers,
      buyerSelected,
      selectedBuyer,
      // consignee,
      selectedConsignee,
      consigneeSelected,
      isMobile,
    } = this.state;
    const {
      classes,
      buyers,
      consignee,
      paymentMethods,
      consigneeName,
      buyerName,
      paymentMethodsDiscount,
      laState,
      subContract,
      subContractNumber,
      selectedSubContractPayment,
      selectedEditSubContractNumber,
    } = this.props;

    return (
      <div className={classes.createDispatchBuyerDetailsMainContainer}>
        <div className={classes.buyerDetailShowContainer}>
          <span className={classes.buyerDetailTextStyle}>Buyer Details</span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            The company to be invoiced
          </span>
          <FormControl variant="outlined" className={classes.formControlStyle}>
            <InputLabel
              id="label-id"
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              style={{ fontSize: window.innerWidth < 375 && pxToEm(14) }}
              htmlFor="outlined-age-simple"
            >
              Buyer*
            </InputLabel>
            <Select
              label="Buyer"
              labelId="label-id"
              labelWidth={this.state.labelWidth}
              name="buyer"
              id="outlined-age-simple"
              value={selectedBuyer && selectedBuyer.id || ""}
              style={{ fontSize: window.innerWidth < 375 && pxToEm(15), }}
              disabled={Boolean(selectedEditSubContractNumber) || Boolean(subContract)}
            >
              {buyers &&
                buyers.map((data) => (
                  <MenuItem
                    key={data.id}
                    value={data.id}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSelectBuyer(data);
                      this.props.handleSelectBuyerName(data);
                    }}
                    style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                  >
                    {data.first_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {/* {console.log(buyerSelected, checkInvalidValue(buyerSelected))} */}
          {!checkInvalidValue(buyerSelected) && buyerSelected ? (
            <div style={{ marginTop: "6px", padding: "0px 20px" }}>
              <div className={classes.selectedBuyerStyle}>
                <span className={classes.buyerNameTextStyle}>Buyer</span>
                <span>{selectedBuyer?.first_name}</span>
              </div>
              <div className={classes.selectedBuyerStyle}>{ }
                <span className={classes.buyerNameTextStyle}>
                  {selectedBuyer?.gstin_number ? "GSTIN" : "PAN"}</span>
                <span>{selectedBuyer.gstin_number ?
                  selectedBuyer?.gstin_number :
                  selectedBuyer?.pan_card_number}</span>
              </div>
              <div className={classes.selectedBuyerStyle}>
                <span className={classes.buyerNameTextStyle}>Contact No.</span>
                <p style={{ margin: '2px 0' }}>
                  {selectedBuyer?.phone
                    ? selectedBuyer.phone && selectedBuyer.phone.length === 10
                      ? `+91 ${selectedBuyer.phone}`
                      : selectedBuyer.phone === null
                        ? ``
                        : `+ ${selectedBuyer.phone}`
                    : laState?.laDetails?.buyer?.customer_addresses?.contact ?
                      laState?.laDetails?.buyer?.customer_addresses?.contact :
                      laState?.laDetails?.buyer?.gst_addresses?.contact &&
                      laState?.laDetails?.buyer?.gst_addresses?.contact
                  }
                </p>
              </div>
              <div className={classes.selectedBuyerStyle}>
                <span className={classes.buyerNameTextStyle}>Address</span>
                <span>
                  {selectedBuyer?.address
                    ? (((selectedBuyer?.address?.address ? selectedBuyer?.address?.address + ", " : "") || (selectedBuyer?.address?.building_name ? selectedBuyer?.address?.building_name + ", " : " ") + (selectedBuyer?.address?.street ? selectedBuyer?.address?.street + ", " : " ") + (selectedBuyer?.address?.location ? selectedBuyer?.address?.location + ", " : " ")) +

                      " " +
                      ((selectedBuyer?.address?.city ? selectedBuyer?.address?.city + ", " : "") || (selectedBuyer?.address?.district_name ? selectedBuyer?.address?.district_name + ", " : " ")) +
                      " " +
                      ((selectedBuyer?.address?.state ? selectedBuyer?.address?.state + ", " : "") || (selectedBuyer?.address?.state_name ? selectedBuyer?.address?.state_name + ", " : "")) +
                      " " +
                      (selectedBuyer?.address?.pincode))
                    :
                    laState?.laDetails?.buyer?.customer_addresses?.address ?
                      (
                        (laState?.laDetails?.buyer?.customer_addresses?.address
                          ? laState?.laDetails?.buyer?.customer_addresses?.address + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.customer_addresses?.city
                          ? laState?.laDetails?.buyer?.customer_addresses?.city + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.customer_addresses?.state
                          ? laState?.laDetails?.buyer?.customer_addresses?.state + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.customer_addresses?.country
                          ? laState?.laDetails?.buyer?.customer_addresses?.country + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.customer_addresses?.pincode
                          ? laState?.laDetails?.buyer?.customer_addresses?.pincode
                          : "")
                      )
                      :
                      (
                        (laState?.laDetails?.buyer?.gst_addresses?.door_number
                          ? laState?.laDetails?.buyer?.gst_addresses?.door_number + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.gst_addresses?.floor_number
                          ? 'Floor No - ' + laState?.laDetails?.buyer?.gst_addresses?.floor_number + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.gst_addresses?.building_name
                          ? laState?.laDetails?.buyer?.gst_addresses?.building_name + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.gst_addresses?.street
                          ? laState?.laDetails?.buyer?.gst_addresses?.street + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.gst_addresses?.location
                          ? laState?.laDetails?.buyer?.gst_addresses?.location + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.gst_addresses?.district_name
                          ? laState?.laDetails?.buyer?.gst_addresses?.district_name + ", "
                          : "")
                        +
                        ((laState?.laDetails?.buyer?.gst_addresses?.city_name && laState?.laDetails?.buyer?.gst_addresses?.district_name)
                          ? ((laState?.laDetails?.buyer?.gst_addresses?.city_name).trim().toUpperCase() !== (laState?.laDetails?.buyer?.gst_addresses?.district_name).trim().toUpperCase())
                            ? laState?.laDetails?.buyer?.gst_addresses?.city_name + ", "
                            : ""
                          : laState?.laDetails?.buyer?.gst_addresses?.city_name
                            ? laState?.laDetails?.buyer?.gst_addresses?.city_name + ", "
                            : "")
                        +
                        (laState?.laDetails?.buyer?.gst_addresses?.state_name
                          ? laState?.laDetails?.buyer?.gst_addresses?.state_name + ", "
                          : "")
                        +
                        (laState?.laDetails?.buyer?.gst_addresses?.pincode
                          ? laState?.laDetails?.buyer?.gst_addresses?.pincode
                          : "")
                      )
                  }
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.buyerDetailShowContainer}>
          <span className={classes.buyerDetailTextStyle}>
            Consignee Details*
          </span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            Delivery address of this Loading Advice
          </span>
          <FormControl variant="outlined" className={classes.formControlStyle}>
            <InputLabel
              id="label-id"
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              style={{ fontSize: window.innerWidth < 375 && pxToEm(14) }}
              htmlFor="outlined-age-simple"
            >
              Consignee
            </InputLabel>
            <Select
              label="Consignee"
              labelId="label-id"
              labelWidth={this.state.labelWidth}
              name="Consignee"
              id="outlined-age-simple"
              value={selectedConsignee && selectedConsignee.id || ""}
              style={{ whiteSpace: 'normal', wordBreak: 'break-word', fontSize: window.innerWidth < 375 && pxToEm(15) }}
              disabled={Boolean(selectedEditSubContractNumber) || Boolean(subContract)}
            >
              {consignee &&
                consignee.map((data) => (
                  <MenuItem
                    value={data.id}
                    // style={{ whiteSpace: 'normal' }}
                    // selected={consignee.length === 1 ? data.consignee_detail.first_name : null}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSelectConsignee(data);
                      this.props.handleSelectConsigneeName(data);
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ margin: 0 }}>

                        {
                          data.consignee_detail.first_name
                        }
                      </p>
                      <p style={{ fontSize: "12px", margin: 0, whiteSpace: 'initial' }}>
                        {
                          ((data?.consignee_address?.address ? data?.consignee_address?.address + ", " : "") || (data?.consignee_address?.door_number ? data?.consignee_address?.door_number + ", " : " ") + (data?.consignee_address?.building_name ? data?.consignee_address?.building_name + ", " : " ") + (data?.consignee_address?.street ? data?.consignee_address?.street + ", " : " ") + (data?.consignee_address?.location ? data?.consignee_address?.location + ", " : " ")) +

                          "" +
                          ((data?.consignee_address?.city ? data?.consignee_address?.city + ", " : "") || (data?.consignee_address?.district_name ? data?.consignee_address?.district_name + ", " : " ")) +
                          "" +
                          ((data?.consignee_address?.state ? data?.consignee_address?.state + ", " : "") || (data?.consignee_address?.state_name ? data?.consignee_address?.state_name + ", " : "")) +
                          "" +
                          (data?.consignee_address?.pincode)
                        }
                      </p>
                    </div>
                  </MenuItem>
                ))}
              {/* <MenuItem value={10}>100MT</MenuItem>
              <MenuItem value={20}>200MT</MenuItem>
              <MenuItem value={30}>300MT</MenuItem> */}
            </Select>
          </FormControl>
          {consigneeSelected && consigneeSelected ? (
            <div style={{ marginTop: "6px", padding: "0px 20px" }}>
              <div className={classes.selectedBuyerStyle}>
                <span className={classes.buyerNameTextStyle}>Consignee</span>
                <span>{
                  selectedConsignee?.consignee_detail
                    ? selectedConsignee.consignee_detail.first_name
                    : selectedConsignee?.first_name
                }</span>
              </div>
              <div className={classes.selectedBuyerStyle}>
                <span className={classes.buyerNameTextStyle}>
                  {
                    selectedConsignee &&
                      selectedConsignee?.consignee_detail
                      ? selectedConsignee.consignee_detail.gstin_number ? "GSTIN" : "PAN"
                      : selectedConsignee?.gstin_number ? "GSTIN" : "PAN"
                  }
                </span>
                <span>
                  {selectedConsignee?.consignee_detail
                    ? selectedConsignee.consignee_detail.gstin_number
                      ? selectedConsignee.consignee_detail.gstin_number
                      : selectedConsignee.consignee_detail.pan_card_number
                    : selectedConsignee?.gstin_number
                      ? selectedConsignee?.gstin_number
                      : selectedConsignee?.pan_card_number
                  }
                </span>
              </div>
              <div className={classes.selectedBuyerStyle}>
                <span className={classes.buyerNameTextStyle}>Contact No.</span>
                <p style={{ margin: '2px 0' }}>
                  {selectedConsignee?.consignee_detail?.phone
                    ? selectedConsignee?.consignee_detail?.phone && selectedConsignee?.consignee_detail?.phone.length === 10
                      ? `+91 ${selectedConsignee?.consignee_detail?.phone}`
                      : selectedConsignee?.consignee_detail?.phone === null
                        ? ``
                        : `+ ${selectedConsignee?.consignee_detail?.phone}`
                    : laState?.laDetails?.consignee?.consignee_from_customer_addresses
                      ? laState?.laDetails?.consignee?.consignee_from_customer_addresses?.contact
                      : laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.contact
                  }
                </p>
              </div>
              <div className={classes.selectedBuyerStyle}>
                <span className={classes.buyerNameTextStyle}>Address</span>
                <span>
                  {selectedConsignee?.consignee_address
                    ? (((selectedConsignee?.consignee_address?.address ? selectedConsignee?.consignee_address?.address + ", " : "") || (selectedConsignee?.consignee_address?.door_number ? selectedConsignee?.consignee_address?.door_number + ", " : " ") + (selectedConsignee?.consignee_address?.building_name ? selectedConsignee?.consignee_address?.building_name + ", " : " ") + (selectedConsignee?.consignee_address?.street ? selectedConsignee?.consignee_address?.street + ", " : " ") + (selectedConsignee?.consignee_address?.location ? selectedConsignee?.consignee_address?.location + ", " : " ")) +

                      " " +
                      ((selectedConsignee?.consignee_address?.city ? selectedConsignee?.consignee_address?.city + ", " : "") || (selectedConsignee?.consignee_address?.district_name ? selectedConsignee?.consignee_address?.district_name + ", " : " ")) +
                      " " +
                      ((selectedConsignee?.consignee_address?.state ? selectedConsignee?.consignee_address?.state + ", " : "") || (selectedConsignee?.consignee_address?.state_name ? selectedConsignee?.consignee_address?.state_name + ", " : "")) +
                      " " +
                      (selectedConsignee?.consignee_address?.pincode))
                    :
                    laState?.laDetails?.consignee?.consignee_from_customer_addresses?.address ?
                      (
                        (laState?.laDetails?.consignee?.consignee_from_customer_addresses?.address
                          ? laState?.laDetails?.consignee?.consignee_from_customer_addresses?.address + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_customer_addresses?.city
                          ? laState?.laDetails?.consignee?.consignee_from_customer_addresses?.city + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_customer_addresses?.state
                          ? laState?.laDetails?.consignee?.consignee_from_customer_addresses?.state + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_customer_addresses?.country
                          ? laState?.laDetails?.consignee?.consignee_from_customer_addresses?.country + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_customer_addresses?.pincode
                          ? laState?.laDetails?.consignee?.consignee_from_customer_addresses?.pincode
                          : "")
                      )
                      :
                      (
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.door_number
                          ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.door_number + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.floor_number
                          ? 'Floor No - ' + laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.floor_number + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.building_name
                          ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.building_name + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.street
                          ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.street + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.location
                          ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.location + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.district_name
                          ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.district_name + ", "
                          : "")
                        +
                        ((laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.city_name && laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.district_name)
                          ? ((laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.city_name).trim().toUpperCase() !== (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.district_name).trim().toUpperCase())
                            ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                            : ""
                          : laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.city_name
                            ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                            : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.state_name
                          ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.state_name + ", "
                          : "")
                        +
                        (laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.pincode
                          ? laState?.laDetails?.consignee?.consignee_from_gst_information_addresses?.pincode
                          : "")
                      )
                  }
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.paymentTermContainer}>
          <span className={classes.buyerDetailTextStyle}>
            Select Payment Terms
          </span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            Select appropriate payment option
          </span>
          <div className={classes.paymentOptionCardContainer} style={{ marginBottom: !isMobile && 65 }}>
            <PaymentOptionCard
              handleSelectPaymentMethod={this.props.handleSelectPaymentMethod}
              paymentMethods={paymentMethods}
              selectedPaymentMethodDataForLA={this.props.selectedPaymentMethodDataForLA}
              paymentMethodsDiscount={paymentMethodsDiscount}
              laState={laState}
              subContract={subContract}
              subContractNumber={subContractNumber}
              selectedSubContractPayment={selectedSubContractPayment}
              selectedEditSubContractNumber={selectedEditSubContractNumber}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CreateDispatchBuyerDetails);
