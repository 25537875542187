import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import { withStyles } from "@material-ui/core/styles";
import Orders from "../Orders";

const styles = {
  ordersWebMainBody: {
    width: "55vw",
    height: window.innerHeight - 120
  },
};
class OrdersWeb extends Component {
  render() {
    const { classes, isMobile } = this.props;

    return (
      <WebLayout appBar orderSelect>
        <div className={classes.ordersWebMainBody}>
          <Orders isMobile={isMobile} {...this.props} />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(OrdersWeb);
