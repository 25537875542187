import React, { Component } from "react";
import "../../../styles/Register.css";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
} from "../../../styles/colors";

import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { PasswordLoginValid } from "../../../methods";

const styles = {
  registerAddressDetailsbody: {
    height: window.innerHeight - 56,
    overflow: "scroll",
  },
  paperStyle: {
    // height: "95%",
    width: "29%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    // boxShadow:
    //   "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  passwardInfoText: {
    color: bottomNavigationFontColor,
    fontSize: "0.9em",
    marginTop: "2px",
  },
  textFieldStyle: {
    width: "90%",
    backgroundColor: "white",
  },
};

class RegisterCreatePassward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessagePassword: false,
      showErrorMessageConfirmPassword: false,
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }

  handleTextChange = (e) => {
    const { value } = e.target
    // console.log(value, PasswordLoginValid(value))
    this.setState({
      passwordss: value,
      showErrorMessagePassword: !PasswordLoginValid(value)
    }, () => {
      this.props.handleTextChange(e)
      this.props.handleStepError(this.state.showErrorMessagePassword)
    })
    // console.log()
  }

  render() {
    const {
      showErrorMessagePassword,
      showErrorMessageConfirmPassword, passwordss
    } = this.state;
    const { classes, password, confirmPassword } = this.props;

    return (
      <div className={classes.registerCreatePasswardbody}>
        <div className="register-create-passward-image-header">
          <div className={classes.paperStyle}>
            <img
              src={
                window.cordova
                  ? `${this.image}${"/RealDealWeb2.png"}`
                  : "/RealDealWeb2.png"
              }
              className="logo-img-style-in-login-create-pass"
              alt="img"
            />
          </div>
        </div>
        <br />
        <div className="register-create-passward-type-passward-div">
          <span style={{ color: dailyRateScreenTextColor }}>
            Type a strong password
          </span>
          <span className={classes.passwardInfoText}>
            The password must be between 8 to 15 characters
          </span>
        </div>
        <br />
        <div className="register-create-passward-form-container">
          <TextField
            name="password"
            id="outlined-name"
            type={
              this.state.showPassword && this.state.showPassword
                ? "text"
                : "password"
            }
            label="Password"
            margin="normal"
            variant="outlined"
            value={password}
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px" },
            }}
            error={showErrorMessagePassword ? true : false}
            helperText={
              <span style={{ color: "red" }}>
                {showErrorMessagePassword
                  ? "The password must be between 8 to 15 characters, must include minimum 1 character, minimum 1 digit and a special character ( # . @ _ )"
                  : null}
              </span>
            }
            // onFocus={() => {
            //   this.setState({ showErrorMessagePassword: false });
            // }}
            // onBlur={() => {
            //   if (password.length < 6) {
            //     this.setState({ showErrorMessagePassword: true });
            //   }
            // }}
            onChange={(e) => {
              this.props.handleTextChange(e)
              this.setState({
                showErrorMessagePassword: !PasswordLoginValid(e.target.value)
              })
            }}
            className={classes.textFieldStyle}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    {this.state.showPassword ? (
                      <Visibility
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showPassword: false });
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showPassword: true });
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <div className="register-strong-password-div">
            <span style={{ color: bottomNavigationFontColor }}>
              Password strength
            </span>
            <span
              style={{
                backgroundColor:
                  showErrorMessagePassword ?
                    "gainsboro" :
                    this.props.password &&
                      this.props.password.length >= 2 &&
                      this.props.password.length < 4
                      ? "#e68948"
                      : this.props.password &&
                        this.props.password.length >= 4 &&
                        this.props.password.length < 6
                        ? "#4986c2"
                        : this.props.password && this.props.password.length >= 6
                          ? "#c6d430"
                          : "gainsboro",
                width: "15%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
                borderBottom: "1px solid #000000c4",
              }}
            />
            <span
              style={{
                backgroundColor:
                  showErrorMessagePassword ?
                    "gainsboro" :
                    this.props.password &&
                      this.props.password.length >= 3 &&
                      this.props.password.length < 5
                      ? "#e68948"
                      : this.props.password &&
                        this.props.password.length >= 5 &&
                        this.props.password.length < 7
                        ? "#4986c2"
                        : this.props.password && this.props.password.length >= 7
                          ? "#c6d430"
                          : "gainsboro",
                width: "15%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
                borderBottom: "1px solid #000000c4",
              }}
            />
            <span
              style={{
                backgroundColor:
                  showErrorMessagePassword ?
                    "gainsboro" :
                    this.props.password &&
                      this.props.password.length >= 4 &&
                      this.props.password.length < 6
                      ? "#e68948"
                      : this.props.password &&
                        this.props.password.length >= 6 &&
                        this.props.password.length < 8
                        ? "#4986c2"
                        : this.props.password && this.props.password.length >= 8
                          ? "#c6d430"
                          : "gainsboro",
                width: "15%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
                borderBottom: "1px solid #000000c4",
              }}
            />
            <span
              style={{
                backgroundColor:
                  showErrorMessagePassword ?
                    "gainsboro" :
                    this.props.password &&
                      this.props.password.length >= 5 &&
                      this.props.password.length < 7
                      ? "#e68948"
                      : this.props.password &&
                        this.props.password.length >= 7 &&
                        this.props.password.length < 9
                        ? "#4986c2"
                        : this.props.password && this.props.password.length >= 9
                          ? "#c6d430"
                          : "gainsboro",
                width: "15%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
                borderBottom: "1px solid #000000c4",
              }}
            />
          </div>
          <br />
          <TextField
            name="confirmPassword"
            id="outlined-name"
            label="Confirm password"
            margin="normal"
            variant="outlined"
            onChange={this.props.handleTextChange}
            value={confirmPassword}
            className={classes.textFieldStyle}
            type={
              this.state.showConfirmPassword && this.state.showConfirmPassword
                ? "text"
                : "password"
            }
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px" },
            }}
            error={showErrorMessageConfirmPassword ? true : false}
            helperText={
              <span style={{ color: "red" }}>
                {showErrorMessageConfirmPassword
                  ? "Password do not match"
                  : null}
              </span>
            }
            onFocus={() => {
              this.setState({ showErrorMessageConfirmPassword: false });
            }}
            onBlur={() => {
              if (password !== confirmPassword) {
                this.setState({ showErrorMessageConfirmPassword: true });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    {this.state.showConfirmPassword ? (
                      <Visibility
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showConfirmPassword: false });
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showConfirmPassword: true });
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterCreatePassward);
