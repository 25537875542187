import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import { checkInvalidValue, pxToEm } from '../../../methods';
import moment from 'moment';
import { drawerTextColor } from '../../../constants/internal/colors';

const styles = {
  rupeeIcon: {
    transform: 'scale(0.7)',
    marginRight: '-7px',
    marginLeft: '-7px',
    fontWeight: 400,
    color: '#9d9c9c'
  },
  paymentValue: {
    display: 'inline-flex',
    paddingLeft: 10,
    alignItems: 'center'
  }
}

const handlePaymentLogValue = (allPaymentData, rowDataName, rowDataID, date, fieldName) => {
  if ((allPaymentData && rowDataName) && rowDataID) {
    let paymentItemMasterData = (allPaymentData.find(d =>
      Number(d.payment_method) === Number(rowDataID))
    );
    if (fieldName === 'discount_amount') {
      if (paymentItemMasterData?.payment_method_wise_discount_amount_logs?.length > 0) {
        let paymentData = null
        paymentData = paymentItemMasterData?.payment_method_wise_discount_amount_logs.find(d => (
          ![null, 0, '0.00', '0', '0.0', '0.000'].includes(Number(d.value)) &&
          (moment(date).format('YYYY-MM-DD') >= moment(d.valid_from).format('YYYY-MM-DD') &&
            moment(date).format('YYYY-MM-DD') <= moment(d.valid_upto === null ? new Date() : d.valid_upto).format('YYYY-MM-DD'))
        ))
        return checkInvalidValue(paymentData) ? null : paymentData.value
      }
      else {
        return Number(paymentItemMasterData?.discount_amount) === Number(0) || paymentItemMasterData?.discount_amount === null
          ? null
          : paymentItemMasterData?.discount_amount
      }
    }
    else if (fieldName === 'premium_amount') {
      if (paymentItemMasterData?.payment_method_wise_premium_amount_logs?.length > 0) {
        let paymentData = null
        paymentData = paymentItemMasterData?.payment_method_wise_premium_amount_logs.find(d => (
          ![null, 0, '0.00', '0', '0.0', '0.000'].includes(Number(d.value)) &&
          (moment(date).format('YYYY-MM-DD') >= moment(d.valid_from).format('YYYY-MM-DD') &&
            moment(date).format('YYYY-MM-DD') <= moment(d.valid_upto === null ? new Date() : d.valid_upto).format('YYYY-MM-DD'))
        ))
        return checkInvalidValue(paymentData) ? null : paymentData.value
      }
      else {
        return Number(paymentItemMasterData?.premium_amount) === Number(0) || paymentItemMasterData?.premium_amount === null
          ? null
          : paymentItemMasterData?.premium_amount
      }
    }


  }
}

const PaymentCard = (props) => {
  const { allPaymentData, rowDataName, rowDataID, dateCheck, subContract, isUnsubscribed } = props
  let date = dateCheck || new Date()
  return (
    <div
      style={{
        marginLeft: "14px",
        width: '100%',
        fontSize: window.innerWidth < 375 && pxToEm(15),
        display: 'flex',
        justifyContent: 'space-between',
        color: Boolean(subContract) && 'rgba(0,0,0,0.5)'
      }}
    >
      {dateCheck || isUnsubscribed
        ? (<span>
          <div>{rowDataName}</div>
          <div
            style={{
              padding: '3px 8px',
              width: 'max-content',
              alignItems: "center",
              fontSize: pxToEm(10),
              backgroundColor: "red",
              color: drawerTextColor,
              borderRadius: "45px"
            }}
          >unsubscribed</div>
        </span>)
        :
        rowDataName
      }
      {
        allPaymentData &&
          (allPaymentData.find(d =>
            Number(d.payment_method) === Number(rowDataID))
          )
          ?
          Boolean(handlePaymentLogValue(allPaymentData, rowDataName, rowDataID, date, 'discount_amount'))
            ? <span
              style={{
                display: 'inline-flex',
                paddingLeft: 10,
                fontWeight: !(Number(handlePaymentLogValue(allPaymentData, rowDataName, rowDataID, date, 'discount_amount')) == Number(0)) && 600,
                alignItems: 'center'
              }}
            >
              {Number(handlePaymentLogValue(allPaymentData, rowDataName, rowDataID, date, 'discount_amount')) == Number(0)
                ? "  "
                : "  -"
              }
              <CurrencyRupeeRoundedIcon style={styles.rupeeIcon} />
              {handlePaymentLogValue(allPaymentData, rowDataName, rowDataID, date, 'discount_amount') + "/MTS"}
            </span>
            :
            Boolean(handlePaymentLogValue(allPaymentData, rowDataName, rowDataID, date, 'premium_amount'))
              ? <span style={styles.paymentValue}>
                {Number(handlePaymentLogValue(allPaymentData, rowDataName, rowDataID, date, 'premium_amount')) === Number(0)
                  ? "  "
                  : "  +"
                }
                <CurrencyRupeeRoundedIcon style={styles.rupeeIcon} />
                {handlePaymentLogValue(allPaymentData, rowDataName, rowDataID, date, 'premium_amount') + "/MTS"}
              </span>
              :
              <span style={styles.paymentValue}>
                {"  "}
                <CurrencyRupeeRoundedIcon style={styles.rupeeIcon} />
                {"0.00/MTS"}
              </span>
          :
          <span style={styles.paymentValue}>
            {"  "}
            <CurrencyRupeeRoundedIcon style={styles.rupeeIcon} />
            {"0.00/MTS"}
          </span>
      }
    </div>
  )
}

export default PaymentCard;