import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManagePincodeData from "../../../../components/internal/masterManagement/pincode/ManagePincodeData";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { checkApiError, isAccessibleModule } from "../../../../methods";
import { Route } from "react-router-dom";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayPincodeData from "../../../../components/internal/masterManagement/pincode/web";

class ManagePincodeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      pincodeOperation: null,
      selectedPincodeId: null,
      selectedPincodeData: [],
      selectedPincodeDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      allCitiesList: [],
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let pincodeOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState(
      { pincodeOperation, allCitiesList: this.props.allCitiesList });
    if (pincodeOperation !== "add") {
      const id =
        this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
        ];
      this.props.tabValue === '0'
        ? this.handleFetchSelectedPincodeData(id)
        :
        this.props.tabValue === '1'
          ? this.handleFetchSelectedCityData(id)
          :
          this.handleFetchSelectedStateData(id)
      this.setState({ selectedPincodeId: id });
    }
    // console.log("TABVALUE IN PIN CONTAINER : " + this.props.tabValue)
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let pincodeOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedPincodeId =
      pincodeOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (pincodeOperation !== state.pincodeOperation) {
      updatedData.set("pincodeOperation", pincodeOperation);
    }
    if (selectedPincodeId !== state.selectedPincodeId) {
      updatedData.set("selectedPincodeId", selectedPincodeId);
    }
    if (updatedData.size > 0) {
      return {
        pincodeOperation: updatedData.has("pincodeOperation")
          ? updatedData.get("pincodeOperation")
          : pincodeOperation,
        selectedPincodeId: updatedData.has("selectedPincodeId")
          ? updatedData.get("selectedPincodeId")
          : [],
      };
    }
    if (props.allCitiesList !== state.allCitiesList) {
      return { allCitiesList: props.allCitiesList };
    }
    return null;
  }
  handleFetchSelectedPincodeData = async (selectedPincodeId) => {
    await this.setState({ selectedPincodeDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.PINCODE}${selectedPincodeId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedPincodeData: response.data,
              selectedPincodeDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedPincodeDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected pincode data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedPincodeDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching selected pincode data try again!",
            snackbarType: "error",
          });
        }
      );
  };
  handleFetchSelectedCityData = async (selectedPincodeId) => {
    await this.setState({ selectedPincodeDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.GET_CITY_LIST_DETAIL}${selectedPincodeId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedPincodeData: response.data,
              selectedPincodeDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedPincodeDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected pincode city data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
          // this.setState({
          //   selectedPincodeDataFetchLoader: false,
          //   openSnackbar: true,
          //   snackbarMessage:
          //     "Something went wrong while fetching selected pincode data try again!",
          //   snackbarType: "error",
          // });
        }
      );
  };
  handleFetchSelectedStateData = async (selectedPincodeId) => {
    await this.setState({ selectedPincodeDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.GET_STATES_LIST_DETAIL}${selectedPincodeId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedPincodeData: response.data,
              selectedPincodeDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedPincodeDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected pincode state data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
          // this.setState({
          //   selectedPincodeDataFetchLoader: false,
          //   openSnackbar: true,
          //   snackbarMessage:
          //     "Something went wrong while fetching selected pincode data try again!",
          //   snackbarType: "error",
          // });
        }
      );
  };

  handlePincodeMasterDataAdd = (pincodeData) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.PINCODE, pincodeData, this.getOptions(this.props.user))
        .then((response) => {
          // this.handleFetchSelectedPincodeData(response.data.id);
          // this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });
  handlePincodeMasterCityDataAdd = (cityData) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.GET_CITY_LIST, cityData, this.getOptions(this.props.user))
        .then((response) => {
          // this.handleFetchSelectedPincodeData(response.data.id);
          // this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });
  handlePincodeMasterStateDataAdd = (stateData) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.GET_STATES_LIST, stateData, this.getOptions(this.props.user))
        .then((response) => {
          // this.handleFetchSelectedPincodeData(response.data.id);
          // this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });
  handlePincodeMasterDataUpdate = (pincodeData, pincodeId) =>
    new Promise((resolve, reject) => {
      axios
        .patch(
          `${APIROUTES.PINCODE}${pincodeId}/`,
          pincodeData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          // this.handleFetchSelectedPincodeData(pincodeId);
          // this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });
  handlePincodeMasterCityDataUpdate = (cityData, cityId) =>
    new Promise((resolve, reject) => {
      axios
        .put(
          `${APIROUTES.GET_CITY_LIST_DETAIL}${cityId}/`,
          cityData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          // this.handleFetchSelectedPincodeData(pincodeId);
          // this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });
  handlePincodeMasterStateDataUpdate = (stateData, stateId) =>
    new Promise((resolve, reject) => {
      axios
        .put(
          `${APIROUTES.GET_STATES_LIST_DETAIL}${stateId}/`,
          stateData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          // this.handleFetchSelectedPincodeData(pincodeId);
          // this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });


  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }



  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  render() {
    const { pincodeOperation } = this.state;
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    // if (isAccessibleModule(this.props?.user, "Banks"))
    else {
      return (
        <>
          {pincodeOperation === "view" ? (
            <DisplayPincodeData {...this.props} {...this.state} />
          ) : (
            <ManagePincodeData
              {...this.props}
              {...this.state}
              handlePincodeMasterDataUpdate={this.handlePincodeMasterDataUpdate}
              handlePincodeMasterCityDataUpdate={this.handlePincodeMasterCityDataUpdate}
              handlePincodeMasterStateDataUpdate={this.handlePincodeMasterStateDataUpdate}
              handlePincodeMasterDataAdd={this.handlePincodeMasterDataAdd}
              handlePincodeMasterCityDataAdd={this.handlePincodeMasterCityDataAdd}
              handlePincodeMasterStateDataAdd={this.handlePincodeMasterStateDataAdd}
              handleFetchSelectedPincodeData={this.handleFetchSelectedPincodeData}
              handleFetchSelectedCityData={this.handleFetchSelectedCityData}
              handleFetchSelectedStateData={this.handleFetchSelectedStateData}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleCloseSnackbar}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManagePincodeContainer);
