import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import "../../../styles/b2b/OrdersList.css";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CancelPresentation from "@material-ui/icons/CancelPresentation";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import Info from "@material-ui/icons/Info";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  secondaryColor,
  errorMessageColor,
} from "../../../styles/colors";
import moment from "moment";
import { pxToEm } from "../../../methods";
import { lightGreen } from "../../../constants/internal/colors";

const styles = {
  listContainerStyle: {
    width: "90%",
    // height: "10vh",
    borderRadius: "12px",
    margin: "10px 0px",
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    boxShadow:
      "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  listContainerLeftSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  listContainerMiddleSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "58%",
    height: "100%",
    paddingLeft: "5px",
    justifyContent: "center",
  },
  listContainerRightSideStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "22%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  timeTextStyle: {
    // position: "absolute",
    bottom: "8px",
    color: bottomNavigationFontColor,
    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
  },
  nameTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: "1.1em",
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: window.innerWidth < 310 ? pxToEm(13) : window.innerWidth < 375 && pxToEm(14)
  },
  textStyle: {
    color: bottomNavigationFontColor,
    fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 ? pxToEm(12) : "0.85em"
  },
  leftIconCircleStyle: (status) => ({
    backgroundColor: ['GATEIN', 'LOADING', 'PACKED', 'ALLOTED', 'BILLED'].includes(status)
      ? secondaryColor
      : ['UNLOADING', 'UNLOADED'].includes(status) && 'red',
    borderRadius: ['GATEIN', 'LOADING', 'PACKED', 'ALLOTED', 'BILLED', 'UNLOADING', 'UNLOADED'].includes(status) && 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: status === "PENDING"
      ? 'scale(1.5)'
      : status === "CANCELLED" || status === "REJECTED"
        ? 'scale(1.1)'
        : 'scale(1)',
    color: ['GATEIN', 'LOADING', 'PACKED', 'ALLOTED', 'BILLED', 'UNLOADING', 'UNLOADED'].includes(status)
      ? '#FFF'
      : bottomNavigationFontColor,
    padding: ['GATEIN', 'LOADING', 'PACKED', 'ALLOTED', 'BILLED', 'UNLOADING', 'UNLOADED'].includes(status) && 6
    // border:
    //   status === "BILLED" || status === "DISPATCHED"
    //     ? `1px solid ${secondaryColor}`
    //     : status === "CANCELLED"
    //       ? "1px solid red"
    //       : status === "APPROVED" ?
    //         "1px solid green"
    //         : status === "PENDING" ?
    //           "none"
    //           : `1px solid ${errorMessageColor}`,
  }),
  leftIconText: (status) => ({
    color: ["APPROVED", 'GATEIN', 'LOADING', 'PACKED', 'ALLOTED', 'BILLED'].includes(status)
      ? secondaryColor
      : ['UNLOADING', 'UNLOADED'].includes(status)
        ? 'red'
        : status === "DISPATCHED"
          ? lightGreen
          : bottomNavigationFontColor,
    paddingTop: !["PENDING"].includes(status) && 5,
    fontSize: (status.length > 7)
      ? window.innerWidth < 310
        ? pxToEm(9.4)
        : window.innerWidth < 375
          ? pxToEm(10)
          : pxToEm(11.6)
      : window.innerWidth < 310
        ? pxToEm(10.6)
        : window.innerWidth < 375
          ? pxToEm(11.6)
          : pxToEm(13.6),
  })
};
class DispatchList extends Component {

  render() {
    const {
      classes,
      status,
      laNumber,
      itemCategory,
      quantity,
      plant,
      loadingAdvice,
      truckNumber,
      createdDate
    } = this.props;

    return (
      <div
        className={classes.listContainerStyle}
        onClick={(e) => {
          e.preventDefault();
          if (this?.props?.module !== "LADetail") {
            this.props.fetchLADetails(laNumber)
          }
        }}
      >
        <div className={classes.listContainerLeftSideStyle}>
          <span style={styles.leftIconCircleStyle(status)}>
            {status === "PENDING" ?
              (
                <div style={{ position: 'relative', transform: 'scale(0.8)' }}>
                  <span><ShieldOutlinedIcon /></span>
                  <span style={{ position: 'absolute', left: 0, transform: 'scale(0.7)' }}>
                    <PersonOutlinedIcon />
                  </span>
                </div>
              )
              : status === "APPROVED" ?
                (<LocalShippingOutlinedIcon style={{ color: secondaryColor }} />)
                : status === "CANCELLED" || status === "REJECTED" ? (
                  <HighlightOffRoundedIcon style={{ color: bottomNavigationFontColor }} />
                )
                  : status === "LAPSED" ? (
                    <AccessTimeRoundedIcon style={{ color: bottomNavigationFontColor }} />
                  ) : status === "BILLED" ? (
                    <AssignmentOutlinedIcon style={{ transform: 'scale(0.8)' }} />
                  ) : status === "DISPATCHED" ? (
                    <AssignmentOutlinedIcon style={{ color: lightGreen }} />
                  )
                    : ['GATEIN', 'LOADING', 'PACKED', 'ALLOTED', 'UNLOADING', 'UNLOADED'].includes(status)
                      ? (<LocalShippingOutlinedIcon style={{ transform: 'scale(0.8)' }} />)
                      :
                      (
                        <Info style={{ color: errorMessageColor }} />
                      )}
          </span>
          <span style={styles.leftIconText(status)}>
            {['GATEIN', 'LOADING', 'PACKED', 'ALLOTED'].includes(status)
              ? 'GATE-IN'
              : ['UNLOADING', 'UNLOADED'].includes(status)
                ? "UNLOAD"
                : status}
          </span>
        </div>
        <div className={classes.listContainerMiddleSideStyle}>
          <span className={classes.nameTextStyle}>{itemCategory + ' | ' + plant}
            {/* <span style={styles.textStyle}>{' ' + plant}</span> */}
          </span>
          <span className={classes.timeTextStyle}>{truckNumber}</span>
          <span style={styles.textStyle}>{laNumber + ' | ' + moment(createdDate).format("DD-MMM-yyyy")}</span>
          {/* <span style={styles.textStyle}>{moment(createdDate).format("DD-MMM-yyyy")}</span> */}

        </div>
        <div className={classes.listContainerRightSideStyle}>
          <span style={{ fontSize: window.innerWidth < 310 ? pxToEm(12.5) : window.innerWidth < 375 ? pxToEm(13.5) : "1em", }}>
            {Number(quantity).toFixed(3)}
            <span style={{ marginLeft: "4px" }}>MT</span>
          </span>
          {/* <span className={classes.timeTextStyle}>{truckNumber}</span> */}
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(DispatchList));
