import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Switch, withStyles } from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import {
  checkApiError,
  checkAlphabet,
  checkAlphaNumericLength,
  pxToEm
} from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import SaveButton from "../../common/SaveButton";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: '70%'
  }
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function ManageBankDetails(props) {
  const classes = useStyles();


  // Saving the Data and values on Change State

  const [bankData, setBankData] = React.useState(
    props.selectedBankData || {
      bank_code: null,
      bank_name: null,
      code_type: null,
      user_code: null,
      address: null,
    }
  );

  const [bankError, setBankError] = React.useState({
    bank_name: false,
    bank_code: false,
    address: false,
    user_code: false,
    code_type: false,
  })
  const [isLoading, setIsLoading] = React.useState(false);
  const [bankOperation, setBankOperation] = React.useState(
    props.bankOperation || "add"
  );
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [removeConfirmationDialogData, setRemoveConfirmationDialogData] =
    React.useState({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });

  React.useEffect(() => {
    if (props.bankOperation !== 'add') {
      if (props.selectedBankData.bank_name) {
        !checkAlphabet(props.selectedBankData.bank_name) ?
          setBankError(prevValue => { return { ...prevValue, bank_name: true } }) :
          setBankError(prevValue => { return { ...prevValue, bank_name: false } });
      } if (props.selectedBankData.bank_code) {
        !checkAlphabet(props.selectedBankData.bank_code) ?
          setBankError(prevValue => { return { ...prevValue, bank_code: true } }) :
          setBankError(prevValue => { return { ...prevValue, bank_code: false } });
      } if (!props.selectedBankData.address) {
        setBankError(prevValue => { return { ...prevValue, address: true } })
      } if (!props.selectedBankData.user_code) {
        setBankError(prevValue => { return { ...prevValue, user_code: true } })
      } if (props.selectedBankData.code_type) {
        !checkAlphabet(props.selectedBankData.code_type) ?
          setBankError(prevValue => { return { ...prevValue, code_type: true } }) :
          setBankError(prevValue => { return { ...prevValue, code_type: false } });
      }
    }
  }, [props.bankOperation, props.selectedBankData])

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    setBankData({
      ...bankData,
      [name]: name === "is_active" ? checked : value,
    });

    if (!value) {
      setBankError(prevValue => {
        return {
          ...prevValue, [name]: true
        }
      })
    } else {
      if (name === 'bank_name') {
        !checkAlphabet(value) ?
          setBankError(prevValue => { return { ...prevValue, [name]: true } }) :
          setBankError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'bank_code') {
        !checkAlphabet(value) ?
          setBankError(prevValue => { return { ...prevValue, [name]: true } }) :
          setBankError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'code_type') {
        !checkAlphabet(value) ?
          setBankError(prevValue => { return { ...prevValue, [name]: true } }) :
          setBankError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else {
        setBankError(prevValue => { return { ...prevValue, [name]: false } })
      }
    }
  };
  // console.log("data", bankData);
  const handleCancelButtonClick = () => {
    props.history.goBack();
  };
  const handleEditSaveButtonClick = () => {
    let addData = {
      bank_name: bankData.bank_name,
      bank_code: bankData.bank_code,
      address: bankData.address,
      user_code: bankData.user_code,
      code_type: bankData.code_type,
      is_active: (bankData.is_active) === undefined ? true : Boolean(bankData.is_active),
    }
    setIsLoading(true)
    if (bankOperation === "edit") {
      props
        .handleBankMasterDataUpdate(bankData, bankData.id)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Bank Master Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.goBack();
              handleCloseSnackbar()
            }, 4000);
            // props.history.goBack();
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating bank details please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
          // handleOpenSnackbar(
          //   `Error occured while updating bank details please try again`,
          //   "error"
          // );
        });
    } else if (bankOperation === "add") {
      props
        .handleBankMasterDataAdd(addData)
        .then((response) => {
          console.log("bank Data :", response);
          if (response.status === 201) {
            handleOpenSnackbar(
              `Bank Master Created Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              setBankOperation("view");
              props.history.push(
                `${INTERNALROUTES.BANK_MASTER}/${response.data.id}`
              );
              handleCloseSnackbar()
            }, 4000);
            // setBankOperation("view");
            // props.history.push(
            //   `${INTERNALROUTES.BANK_MASTER}/${response.data.id}`
            // );
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : `Error occured while creating plant item master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
          // handleOpenSnackbar(
          //   error?.data?.details
          //     ? error?.data?.details
          //     : error?.data?.message
          //     ? error?.data?.message
          //     : `Error occured while creating plant item master please try again`,
          //   "error"
          // );
        });
    } else {
      console.log("Invalid operation");
    }
  };
  React.useEffect(() => {
    setBankData(props?.selectedBankData);
    setBankOperation(props.bankOperation);
  }, [props?.selectedBankData, props.bankOperation]);
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Bank Master",
                onClick: () => props.history.push(INTERNALROUTES.BANK_MASTER),
              },
              {
                title:
                  bankOperation === "add"
                    ? "Create New"
                    : `Edit ${bankData.bank_name}`,

                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                BANK NAME *
              </Typography>
              <TextField
                label="Bank Name"
                name="bank_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter bank name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={bankData?.bank_name}
                error={bankError.bank_name}
                helperText={bankError.bank_name ? "Invalid Bank Name" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                BANK CODE *
              </Typography>
              <TextField
                label="Bank Code"
                name="bank_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter bank code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 4, minLength: 4, max: 4 }}
                value={bankData?.bank_code}
                error={bankError.bank_code}
                helperText={bankData?.bank_code === "" ?
                  "Invalid Bank Code"
                  :
                  bankError.bank_code
                    ? "Bank Code should be alphabetic"
                    : ""
                }
              />
            </div>
            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                IFSC CODE *
              </Typography>
              <TextField
                label="IFSC Code"
                name="ifsc_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter IFSC code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={bankData?.ifsc_code || ""}
                inputProps={{ maxLength: 20 }}
                error={ifscCodeError}
                helperText={ifscCodeError ? "Please Fill IFSC Code" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>BRANCH *</Typography>
              <TextField
                label="Branch"
                name="branch"
                type="text"
                onChange={handleChange}
                placeholder="Enter branch name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={bankData?.branch || ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                BRANCH CODE *
              </Typography>

              <TextField
                label="Branch Code"
                name="branch_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter branch code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={bankData?.branch_code || ""}
                error={BranchCodeError || BranchCodeLengthError}
                helperText={
                  BranchCodeError
                    ? "Please Fill Branch Code"
                    : "" || BranchCodeLengthError
                      ? "Ensure this field has no more than 10 characters."
                      : ""
                }
              />
            </div> */}
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ADDRESS *</Typography>
              <TextField
                label="Address"
                name="address"
                type="text"
                multiline
                rows={4}
                onChange={handleChange}
                placeholder="Enter Address"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={bankData?.address}
                error={bankError.address}
                helperText={
                  bankError.address
                    ? "Invalid Address"
                    : ""
                }
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                USER CODE *
              </Typography>

              <TextField
                label="User Code"
                name="user_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter user code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                value={bankData?.user_code}
                error={bankError.user_code}
                helperText={
                  bankError.user_code
                    ? "Invalid User Code"
                    : ""
                }
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                CODE TYPE *
              </Typography>
              <TextField
                label="Code Type"
                name="code_type"
                type="text"
                onChange={handleChange}
                placeholder="Enter code type"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 1, minLength: 1, max: 1 }}
                value={bankData?.code_type}
                error={bankError.code_type}
                helperText={bankData?.code_type === "" ?
                  "Invalid Code Type"
                  :
                  bankError.code_type
                    ? "Code Type Should be alphabetic"
                    : ""
                }
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACTIVE
              </Typography>
              <div className={classes.isActive}>
                <IOSSwitch
                  checked={(bankData.is_active) === undefined
                    ? true
                    : Boolean(bankData.is_active)
                  }
                  onChange={handleChange}
                  name="is_active"
                />
              </div>
            </div>
            <SaveButton
              size="medium"
              disabled={
                !bankData.bank_name ||
                !bankData.bank_code ||
                !bankData.address ||
                !bankData.user_code ||
                !bankData.code_type ||
                bankError.bank_name ||
                bankError.bank_code ||
                bankError.address ||
                bankError.user_code ||
                bankError.code_type ||
                bankData.bank_name === "" ||
                bankData.bank_code === "" ||
                bankData.address === "" ||
                bankData.user_code === "" ||
                bankData.code_type === ""
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {bankOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
      {/* <RemoveConfirmationDialog
        open={removeConfirmationDialogData.openDialog}
        dialogTitle={removeConfirmationDialogData.dialogTitle}
        dialogContent={removeConfirmationDialogData.dialogContent}
        removeDataLoader={removePlantItemMasterLoader}
        handleClose={handleRemoveConfirmationDialogClose}
        handleConfirmationDialogDeleteClick={
          handleConfirmationDialogDeleteClick
        }
      /> */}
    </Layout>
  );
}
export default withRouter(ManageBankDetails);
