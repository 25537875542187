import React, { Component } from 'react'
import {
    TextField,
    MenuItem,
    Paper,
    Popper,
} from "@material-ui/core";
import {
    grey
} from "../../../../../constants/internal/colors";
import Downshift from "downshift";

const styles = {
    container: {
        width: "100%",
    },
}

export class FilterByCustomerOrBroker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            anchorEl: null,
        }
    }
    render() {
        const { customerList, anchorEl, } = this.state
        const { selectParam, textFieldName,
            textFieldLabel, openPopper,
            dataList, selectedTextFieldValue,
            fiterKey, openFilter } = this.props

        // console.log(dataList)
        return (
            <Downshift id="downshift-popper">
                {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
                    <div style={styles.container}>
                        <TextField
                            name={textFieldName}
                            label={textFieldLabel}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChangeCapture={this.props.handleChange(textFieldName)}
                            inputRef={(node) => (this.inpRef1 = node)}
                            // onFocus={console.log(openFilter)}
                            InputProps={{
                                ...getInputProps({
                                    value: selectedTextFieldValue,
                                    placeholder: textFieldLabel,
                                }),
                            }}
                        // onBlur={this.props.handleClosePopper(openFilter)}
                        />
                        {(
                            <Popper
                                open={openPopper}
                                anchorEl={this.inpRef1}
                                style={{ zIndex: 10 }}
                            // onBlur={this.props.handleClosePopper(openFilter)}
                            >
                                <div
                                    {...(isOpen
                                        ? getMenuProps({}, { suppressRefError: true })
                                        : {})}
                                >
                                    <Paper
                                        square
                                        style={{
                                            maxHeight: "155px",
                                            overflowY: "scroll",
                                            marginTop: 8,
                                            width: this.inpRef1 ? this.inpRef1.clientWidth : null,
                                            // zIndex: 111
                                        }}
                                    >
                                        {dataList && dataList.map((data) => (
                                            <MenuItem
                                                key={data.id}
                                                component="div"
                                                value={data.id}
                                                onClick={() => {
                                                    this.props.handleSelect({
                                                        field: selectParam,
                                                        data,
                                                        value: data.id,
                                                        selectedField: fiterKey,
                                                        selectedName: data ?
                                                            data.gst_information ?
                                                                data?.gst_information?.trade_name || data?.gst_information?.legal_name_of_business :
                                                                data.pan_card_information ?
                                                                    data?.pan_card_information?.company_name != "" || data?.pan_card_information?.company_name ?
                                                                        data.pan_card_information.company_name :

                                                                        `${data.first_name} ${data.last_name}` : "" : "",
                                                        filterFor: textFieldName,
                                                    })
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        lineHeight: "1em",
                                                    }}
                                                >
                                                    <span>
                                                        {data ?
                                                            data.gst_information ?
                                                                data?.gst_information?.trade_name || data?.gst_information?.legal_name_of_business :
                                                                data.pan_card_information ?
                                                                    data?.pan_card_information?.company_name != "" || data?.pan_card_information?.company_name ?
                                                                        data.pan_card_information.company_name :

                                                                        `${data.first_name} ${data.last_name}` : "" : ""}
                                                    </span>
                                                    <span style={{ color: grey, fontSize: "0.7em" }}>
                                                        {!data?.gst_information
                                                            ? !data?.pan_card_information
                                                                ? ""
                                                                : `PAN - ${data?.pan_card_information?.pan_card_number}`
                                                            : `GSTIN - ${data?.gst_information?.gstin_number}`}
                                                    </span>
                                                    {/* <span style={{ color: grey, fontSize: "0.7em" }}>
                                                        PHONE - {data?.phone || ""}
                                                    </span> */}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Paper>
                                </div>
                            </Popper>
                        )}
                    </div>
                )}
            </Downshift>
        )
    }
}

export default FilterByCustomerOrBroker