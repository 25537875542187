
import React, { useState, useEffect, createRef } from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import axios from "axios";
import ReportsWeb, {
  ReportsMobile,
} from "../../../components/internal/reports";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import AccessDenied from "../../../components/common/AccessDenied";
import {
  isAccessibleModule,
  generateCSV,
  isRequired,
  options,
  arraySortBykeyAllBrowser,
  checkApiError,
  fetchRecursivePaginatedData,
  checkInvalidValue,
  saveCSVInMobile,
} from "../../../methods";
import withUser from "../../../hoc/withUser";
import moment from "moment";

import { FeedbackTwoTone } from "@mui/icons-material";
import SnackbarComponent from "../../../components/common/SnackbarComponent"
import { isArray } from 'lodash';
import { plantTableHeaders, contractsHeaders, subContractsHeaders, subContractsCsvHeaders } from '../../../components/internal/reports/headers';
import {
  invoiceCsvHeaders,
  newContractsCsvHeaders,
  contractsCsvHeaders,
  itemCategoryDDCsvHeaders,
  itemDDCsvHeaders,
  loadingAdvicesCSVHeaders,
  WBGCSVHeaders,
  DPTCSVHeaders
} from '../../../components/internal/reports/headers'

const filters = [
  {
    value: "invoice",
    label: "Invoices",
    disabled: false,
  },
  {
    value: "contracts",
    label: "New Contracts",
    disabled: false,
  },
  {
    value: "contracts",
    label: "Pending Contracts",
    disabled: false,
  },
  {
    value: "contracts",
    label: "Closed Contracts",
    disabled: false,
  },
  {
    value: "dispatchDetails",
    label: "Dispatch Details",
    disabled: false,
  },
];
function Reports(props) {

  useEffect(fetchPlants, []);
  useEffect(fetchItemCategories, []);
  useEffect(fetchPaymentMethods, [])
  useEffect(fetchItems, [])
  useEffect(fetchTransporters, [])
  useEffect(fetchCustomerTypeWithL1, [])
  useEffect(fetchItemTag, [])
  useEffect(fetchSubContractStatus, [])
  // useEffect(() => getImg(), [])

  // Common Hooks 

  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: '',
    snackbarType: ''
  })
  const [dataUrl, setDataUrl] = useState("")

  const [selectedInvoiceStartDate, setSelectedInvoiceStartDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [selectedInvoiceEndDate, setSelectedInvoiceEndDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [selectedContractStartDate, setSelectedContractStartDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [selectedContractEndDate, setSelectedContractEndDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [selectedDDItemStartDate, setSelectedDDItemStartDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [selectedDDItemEndDate, setSelectedDDItemEndDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [selectedDDItemCategoryStartDate, setSelectedDDItemCategoryStartDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [selectedDDItemCategoryEndDate, setSelectedDDItemCategoryEndDate] = useState((moment(new Date()).format('YYYY-MM-DD')));
  const [showInvoiceTable, setShowInvoiceTable] = useState(false)
  const [showContractTable, setShowContractTable] = useState(false)
  // const [showOCTable, setShowOCTable] = useState(false)
  // const [showCCTable, setShowCCTable] = useState(false)
  const [showDDPlantTable, setShowDDPlantTable] = useState(false)
  const [showDDBrokerTable, setShowBrokerTable] = useState(false)
  const [showDDItemTable, setShowDDItemTable] = useState(false)
  const [showDDItemCategoryTable, setShowDDItemCategoryTable] = useState(false)

  const [paymentMethods, setPaymentMethods] = useState([])
  const [paymentStatusList, setPaymentStatusList] = useState(["Paid", "Unpaid", "Due Today", "Overdue"])
  const [tabValue, setTabValue] = useState(0)


  // Common state Object

  const [state, setState] = useState({
    // Common selector states
    plants: { data: ["All"], loading: false, error: "" },
    allModules: { data: ["customer"], loading: false, error: "" },
    itemCategories: { data: ["All"], loading: false, error: "" },
    TCLCategories: { data: ["credit limit provided"], loading: false, error: "" },
    //invoice states
    plant: ["All"],
    invoiceItemCategorySelected: false,
    disablePlants: true,
    disableItemCats: true,
    invoiceItemCategoryPayload: [],
    invoiceItemCategory: ["All"],
    invoicePlantPayload: [],
    selectedInvoiceFilter: { value: "default" },
    invoices: { data: [], loading: false, error: "" },
    showMoreFilters: false,
    plantSelected: false,
    originalInvoiceResponse: { data: [], loading: false, error: "" },
    items: { data: [], loading: false, error: "" },
    selectedContractCustomerFilterID: null,
    selectedContractCustomerFilterData: null,
    selectedBrokerFilterData: null,
    selectedItemFilterData: null,
    selectedCustomerFilterID: null,
    selectedCustomerFilterData: null,
    selectedContractFilters: null,
    selectedInvoiceFilters: null,
    contractFilterCount: 0,
    invoiceFilterCount: 0,
    selectedPaymentMethodFilterData: null,
    selectedPaymentStatusFilterData: null,
    selectedContractPaymentMethodFilterData: null,
    selectedContractBrokerFilterData: null,
    selectedContractItemFilterData: null,
    originalContractResponse: { data: [], loading: false, error: "" },
    contracts: { data: [], loading: false, error: "" },
    selectedContractFilter: { value: "default" },
    selectedContractTypes: ["All"],
    disableContractTypes: true,
    contractTypes: [
      { id: 1, label: "PENDING", value: "PENDING" },
      { id: 2, label: "APPROVED", value: "APPROVED" },
      { id: 3, label: "ONGOING", value: "ONGOING" },
      { id: 4, label: "CLOSED", value: "CLOSED" },
      { id: 5, label: "HOLD", value: "HOLD" },
      { id: 6, label: "CANCELLED", value: "CANCELLED" }],
    contractDisableItemCats: true,
    contractItemCatPayload: ["All"],
  })

  // console.log(state)


  // Invoice Hooks

  const [plants, setPlants] = React.useState({
    data: ["All"],
    loading: false,
    error: "",
  });
  const [allModules, setAllModules] = React.useState({
    data: ["Customers"],
    loading: false,
    error: "",
  });
  const [plant, setPlant] = React.useState(["All"]);
  const [contractsplant, setContractsPlant] = React.useState(["All"]);
  const [invoicePlantPayload, setInvoicePlantPayload] = React.useState([])
  const [contractsPlantPayload, setContractsPlantPayload] = React.useState([])
  const [invoiceItemCategory, setInvoiceItemCategory] = React.useState(["All"]);
  const [invoiceItemCategoryPayload, setInvoiceItemCategoryPayload] = React.useState([]);
  const [disableItemCats, setDisableItemCats] = React.useState(true);
  const [disableItems, setDisableItems] = React.useState(true);
  const [disablePlants, setDisablePlants] = React.useState(true)
  const [invoiceItemCategorySelected, setInvoiceItemCategorySelected] = useState(false)
  const [disableContractsPlants, setDisableContractsPlants] = useState(true)
  const [contractsPlants, setContractsPlants] = useState(["All"])
  const [contractItemtag, setContractItemTag] = useState(["All"])
  const [contractDisableItemtag, setContractDisableItemtag] = useState(true)
  const [contractItemtagPayload, setContractItemTagPayload] = useState(["All"])


  const [invoiceHiddenColumns, setInvoiceHiddenColumns] = useState(null)
  const [contractHiddenColumns, setcontractHiddenColumns] = useState(null)
  const [ddItemHiddenColumns, setddItemHiddenColumns] = useState(null)
  const [ddItemCategoryHiddenColumns, setddItemCategoryHiddenColumns] = useState(null)
  const [wbgHiddenColumns, setwbgHiddenColumns] = useState(null)
  const [exrHiddenColumns, setexrHiddenColumns] = useState(null)

  const [selectedInvoiceFilter, setSelectedInvoiceFilter] = React.useState({
    value: "default",
  });
  const [invoices, setInvoices] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [originalInvoiceResponse, setOriginalInvoiceResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [invoiceCSVData, setInvoiceCSVData] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [invoicePDFData, setInvoicePDFData] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [plantSelected, setPlantSelected] = useState(false)
  const [contractsPlantSelected, setContractsPlantSelected] = useState(false)
  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [showContractMoreFilters, setShowContractMoreFilters] = useState(false)
  const [showddItemMoreFilters, setShowddItemMoreFilters] = useState(false)
  const [showddItemCategoryMoreFilters, setShowddItemCategoryMoreFilters] = useState(false)
  const [showWBGMoreFilters, setShowWBGMoreFilters] = useState(false)
  const [showEXRMoreFilters, setShowEXRMoreFilters] = useState(false)


  const [invoiceFilters, setInvoiceFilters] = useState(new Map())
  const [invoiceFilterKeys, setInvoiceFilterKeys] = useState(["customer", "itemCategory", "broker", "paymentDueDate", "PaymentTerm", "QuantityRange", "PaymentStatus"])
  const [itemCategories, setItemCategories] = useState({
    data: [],
    loading: false,
    error: "",
  })
  const [TCLCategories, setTCLCategories] = useState({
    data: [],
    loading: false,
    error: "",
  })
  const [transporters, setTransporters] = useState({
    data: [],
    loading: false,
    error: "",
  })
  const [items, setItems] = useState()
  const [customerType, setCustomerType] = useState({
    data: [],
    loading: false,
    error: "",
  })
  const [customerTypeL1, setCustomerTypeL1] = useState({
    data: [],
    loading: false,
    error: "",
  })
  const [itemTag, setItemTag] = useState({
    data: [],
    loading: false,
    error: "",
  })
  const [SubContractStatusDropdown, setSubContractStatusDropdown] = useState({
    data: [],
    loading: false,
    error: "",
  })
  const [selectedInvoiceFilters, setSelectedInvoiceFilters] = useState(null)
  const [invoiceFilterCount, setInvoiceFilterCount] = useState(0)
  const [selectedContractFilters, setSelectedContractFilters] = useState(null)
  const [contractPlannedPlants, setContractPlannedPlants] = useState(null)
  const [contractFilterCount, setContractFilterCount] = useState(0)
  const [selectedddItemCategoryFilters, setSelectedddItemCategoryFilters] = useState(null)
  const [ddItemCategoryFilterCount, setddItemCategoryFilterCount] = useState(0)

  const [selectedddItemFilters, setSelectedddItemFilters] = useState(null)
  const [ddItemFilterCount, setddItemFilterCount] = useState(0)
  const [selectedWBGFilters, setSelectedWBGFilters] = useState(null)
  const [WBGFilterCount, setWBGFilterCount] = useState(0)
  const [selectedEXRFilters, setSelectedEXRFilters] = useState(null)
  const [EXRFilterCount, setEXRFilterCount] = useState(0)

  //  New Contracts Hooks 

  // const [contractCategory, setContractCategory] = useState(["All"])
  const [contractItemCatPayload, setContractItemCatPayload] = useState(["All"])
  const [contractDisableItemCats, setContractDisableItemCats] = useState(true)
  const [contractTypes, setContractTypes] = useState([
    { id: 1, label: "PENDING", value: "PENDING" },
    { id: 2, label: "APPROVED", value: "APPROVED" },
    { id: 3, label: "ONGOING", value: "ONGOING" },
    { id: 4, label: "CLOSED", value: "CLOSED" },
    { id: 4, label: "AUTOCLOSED", value: "AUTOCLOSED" },
    { id: 5, label: "HOLD", value: "HOLD" },
    { id: 6, label: "CANCELLED", value: "CANCELLED" }])

  const [laStatus, setLAStatus] = useState([
    { id: 1, label: "PENDING", value: "PENDING" },
    { id: 2, label: "APPROVED", value: "APPROVED" },
    { id: 3, label: "GATEIN", value: "GATEIN" },
    { id: 4, label: "LOADING", value: "LOADING" },
    { id: 5, label: "PACKED", value: "PACKED" },
    { id: 6, label: "ALLOTED", value: "ALLOTED" },
    { id: 7, label: "BILLED", value: "BILLED" },
    { id: 8, label: "DISPATCHED", value: "DISPATCHED" },
    { id: 9, label: "LAPSED", value: "LAPSED" },
    { id: 10, label: "UNLOADING", value: "UNLOADING" },
    { id: 11, label: "HOLD", value: "HOLD" },
    { id: 12, label: "UNLOADED", value: "UNLOADED" },
    { id: 13, label: "CANCELLED", value: "CANCELLED" },
    { id: 14, label: "REJECTED", value: "REJECTED" },
  ])
  const [disableContractTypes, setDisableContractTypes] = useState(true)
  const [selectedContractTypes, setselectedContractTypes] = useState(["All"])
  const [selectedContractFilter, setselectedContractFilter] = React.useState({
    value: "default",
  });
  const [contracts, setContracts] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [originalContractResponse, setOriginalContractResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [contractCSVData, setContractCSVData] = React.useState({
    data: [],
    loading: false,
    error: "",
  });

  const [contractSelected, setContractSelected] = useState(true)

  //Closed Contracts Hooks

  const [contractCategory, setContractCategory] = useState(["All"])

  // Dispatch Details Hooks

  const [searchByTypeCategories, setSearchByTypeCategories] = useState([
    {
      id: 1,
      value: "Item Category",
      label: "Item Category"
    },
    {
      id: 2,
      value: "Item",
      label: "Item"
    }])
  const [searchByTypeCategory, setSearchByTypeCategory] = useState({
    id: 1,
    value: "Item Category",
    label: "Item Category"
  })
  const [selectedSearchByTypeFilter, setSelectedSearchByType] = useState({ value: "default" })

  const [ddItemCategories, setDDItemCategories] = useState({
    data: [],
    loading: false,
    error: "",
  });

  const [ddItemCategory, setDDItemCategory] = useState(["All"])
  const [ddItemCategoryPlant, setDDItemCategoryPlant] = useState(["All"])
  const [ddItemCategoryPlantPayload, setDDItemCategoryPlantPayload] = useState(["All"])
  const [ddItemPlantPayload, setDDItemPlantPayload] = useState(["All"])
  const [disableddItemCategoryPlants, setDisableddItemCategoryPlants] = useState(true)
  const [disableddItemPlants, setDisableddItemPlants] = useState(true)
  const [disableddItem, setDisableddItem] = useState(true)
  const [ddItemCategoryPayload, setddItemCategoryPayload] = useState(["All"])
  const [ddItemPayload, setddItemPayload] = useState(["All"])
  const [ddItemCatdisableItemCats, setddItemCatdisableItemCats] = useState(true)

  const [ddItem, setDDItem] = React.useState(["All"]);
  const [ddItemPlant, setDDItemPlant] = useState(["All"])

  const [selectedDDItemFilter, setSelectedDDItemFilter] = React.useState({
    value: "default",
  });
  const [originalItemDDetailsResponse, setoriginalItemDDetailsResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [itemDDetails, setItemDDetails] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [itemDDetailsCSV, setItemDDetailsCSV] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [ddItemSelected, setDDItemSelected] = useState(true)
  const [ddItemCategorySelected, setDDItemCategorySelected] = useState(true)
  const [selectedDDItemCategoryFilter, setSelectedDDItemCategoryFilter] = React.useState({
    value: "default",
  });
  const [itemCategoryDDetails, setItemCategoryDDetails] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [originalItemCategoryDDetailsResponse, setoriginalItemCategoryDDetailsResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [itemCategoryDDetailsCSV, setItemCategoryDDetailsCSV] = React.useState({
    data: [],
    loading: false,
    error: "",
  });

  // WeighBridge and gates

  const [WBGSelected, setWBGSelected] = useState(true)
  const [WBGResponse, setWBGResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [originalWBGResponse, setOriginalWBGResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [WBGResponseCSV, setWBGResponseCSV] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [showWBGTable, setShowWBGTable] = useState(false)

  const [WBGPlant, setWBGPlant] = React.useState(["All"]);
  const [WBGCategory, setWBGCategory] = useState(["All"])
  const [selectedWBGStartDate, setSelectedWBGStartDate] = React.useState(
    (moment(new Date()).format('YYYY-MM-DD')))
    ;

  const [selectedWBGEndDate, setSelectedWBGEndDate] = React.useState(
    (moment(new Date()).format('YYYY-MM-DD'))
  );
  const [disableWBGItemCats, setDisableWBGItemCats] = useState(true)
  const [disableWBGPlants, setDisableWBGPlants] = useState(true)
  const [WBGPlantPayload, setWBGPlantPayload] = useState(["All"])
  const [WBGCategoryPayload, setWBGCategoryPayload] = useState(["All"])

  // EXCEPTION REPORTS


  const [EXRSelected, setEXRSelected] = useState(true)
  const [EXRResponse, setEXRResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [originalEXRResponse, setOriginalEXRResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [EXRResponseCSV, setEXRResponseCSV] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [showEXRTable, setShowEXRTable] = useState(false)

  const [EXRModule, setEXRModule] = React.useState(["customer"]);
  const [EXRChangeTCLCategory, setEXRChangeTCLCategory] = useState(["credit limit provided"])
  const [selectedEXRStartDate, setSelectedEXRStartDate] = React.useState(
    (moment(new Date()).format('YYYY-MM-DD')))
    ;

  const [selectedEXREndDate, setSelectedEXREndDate] = React.useState(
    (moment(new Date()).format('YYYY-MM-DD'))
  );
  const [disableEXRChangeTCL, setDisableEXRChangeTCL] = useState(true)
  const [disableEXRModules, setDisableEXRModules] = useState(true);
  const [EXRModulePayload, setEXRModulePayload] = useState(["customer"])
  const [EXRChangeTCLPayload, setEXRChangeTCLPayload] = useState(["credit limit provided"])
  const [EXRLoader, setEXRLoader] = useState(false)

  // Dispatch Tracking

  const [DPTSelected, setDPTSelected] = useState(false)
  const [DPTResponse, setDPTResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [DPTResponseCSV, setDPTResponseCSV] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [showDPTTable, setShowDPTTable] = useState(false)
  // const []

  const [DPTPlant, setDPTPlant] = React.useState({ value: "default" });
  const [DPTPlantSelected, setDPTPlantSelected] = useState(false)
  const [DPTCategory, setDPTCategory] = useState(["All"])
  const [disableDPTItemCats, setDisableDPTItemCats] = useState(true)
  const [disableDPTPlants, setDisableDPTPlants] = useState(true)
  const [DPTPlantPayload, setDPTPlantPayload] = useState(["All"])
  const [DPTCategoryPayload, setDPTCategoryPayload] = useState(["All"])


  const [invoiceLoader, setInvoiceLoader] = useState(false)
  const [contractsLoader, setContractsLoader] = useState(false)
  const [ddItemLoader, setddItemLoader] = useState(false)
  const [ddItemCategoryLoader, setddItemCategoryLoader] = useState(false)
  const [WBGLoader, setWBGLoader] = useState(false)

  // SubContract
  const [SubContractResponse, setSubContractResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [subContractsLoader, setSubContractsLoader] = useState(false)
  const [originalSubContractResponse, setOriginalSubContractResponse] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [SubContractResponseCSV, setSubContractResponseCSV] = React.useState({
    data: [],
    header: [],
    loading: false,
    error: "",
  });
  const [showSubContractTable, setShowSubContractTable] = useState(false)
  const [subContractFilterCount, setSubContractFilterCount] = useState(0)
  const [selectedSubContractFilters, setSelectedSubContractFilters] = useState(null)
  const [showSubContractMoreFilters, setShowSubContractMoreFilters] = useState(false)
  const [subContractHiddenColumns, setSubContractHiddenColumns] = useState(null)

  const [SubContractCategory, setSubContractCategory] = useState(["All"])
  const [SubContractCustomerType, setSubContractCustomerType] = useState(["All"])
  const [SubContractCustomerTypeL1, setSubContractCustomerTypeL1] = useState(["All"])
  const [SubContractItemtag, setSubContractItemTag] = useState(["All"])
  const [SubContractStatus, setSubContractStatus] = useState(["All"])
  const [selectedSubContractStartDate, setSelectedSubContractStartDate] = React.useState(
    (moment(new Date()).format('YYYY-MM-DD')))
    ;

  const [selectedSubContractEndDate, setSelectedSubContractEndDate] = React.useState(
    (moment(new Date()).format('YYYY-MM-DD'))
  );
  const [SubContractCategoryPayload, setSubContractCategoryPayload] = useState(["All"])
  const [SubContractCustomerTypePayload, setSubContractCustomerTypePayload] = useState(["All"])
  const [SubContractCustomerTypeL1Payload, setSubContractCustomerTypeL1Payload] = useState(["All"])
  const [SubContractItemtagPayload, setSubContractItemTagPayload] = useState(["All"])
  const [SubContractStatusPayload, setSubContractStatusPayload] = useState(["All"])

  const [subContractDisableItemCats, setSubContractDisableItemCats] = useState(true)
  const [subContractDisableCustomerType, setSubContractDisableCustomerType] = useState(true)
  const [subContractDisableCustomerTypeL1, setSubContractDisableCustomerTypeL1] = useState(true)
  const [subContractDisableItemtag, setSubContractDisableItemtag] = useState(true)
  const [subContractDisableStatus, setSubContractDisableStatus] = useState(true)
  const [plannedUniqueItemSize, setPlannedUniqueItemSize] = useState({
    plannedItemSize: [],
    updatedHeader: []
  })
  const refCSVLink = createRef();
  const [fetchSubContractCSVReportProgress, setFetchSubContractCSVReportProgress] = useState(0);

  const [fetchReportProgress, setFetchReportProgress] = useState(0);

  useEffect(() => {
    console.log("SubContractResponseCSV", SubContractResponseCSV)
    if (SubContractResponseCSV.data.length > 0) {
      window.cordova
        ? saveCSVInMobile(
          SubContractResponseCSV.data,
          SubContractResponseCSV.header,
          `SubContract${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`,
          '', '')
        : refCSVLink.current.link.click();
    }
  }, [SubContractResponseCSV?.data])

  useEffect(() => {
    console.log("SubContractResponse => ", SubContractResponse)
  }, [SubContractResponse])


  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: '',
      snackbarType: ''
    })
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data?.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  function fetchItemCategories() {

    setItemCategories({ ...itemCategories, loading: true })

    axios
      .get(`${APIROUTES.GET_ITEM_CATEGORIES_LIST}?active=true`, options.call(props.user))
      .then(
        (response) => {
          let data = [];
          // console.log(response)
          if (response.status === 200) data = response.data;
          setItemCategories({
            error: "",
            loading: false,
            data: data?.map((e) => ({
              id: e.id,
              value: e.category_name,
              label: e.category_name,
            })),
          });

        })
      .catch((error) => {
        setItemCategories({
          error: error?.message,
          loading: false,
          data: [],
        });
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);

        console.error(error);
      });
  }

  function fetchTransporters() {

    setTransporters({ ...transporters, loading: true })

    axios
      .get(`${APIROUTES.TRANSPORTERS}?active=true`, options.call(props.user))
      .then(
        (response) => {
          let data = [];
          // console.log(response)
          if (response.status === 200) {
            data = response.data.results;
          }
          setTransporters({
            error: "",
            loading: false,
            data
          });

        })
      .catch((error) => {
        setTransporters({
          error: error?.message,
          loading: false,
          data: [],
        });
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);

        console.error(error);
      });
  }

  function fetchItems() {

    setItems({ ...items, loading: true })

    axios
      .get(APIROUTES.GET_ALL_ITEMS_MASTERS, options.call(props.user))
      .then(
        (response) => {
          // console.log(response)
          let data = [];
          if (response.status === 200) data = response.data;
          setItems({
            error: "",
            loading: false,
            data: data?.map((e) => ({
              id: e.id,
              value: e.item_name,
              label: e.item_name,
              item_category: e.item_category,
            })),
          });

        })
      .catch((error) => {
        setItems({
          error: error?.message,
          loading: false,
          data: [],
        });
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);

        console.error(error);
      });

  }

  function fetchPaymentMethods() {
    axios.get(APIROUTES.GET_ALL_PAYMENT_METHODS, options.call(props.user))
      .then(response => {
        if (response.status === 200)
          setPaymentMethods(response.data)
        // console.log(response)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function fetchCustomerTypeWithL1() {
    const customerType = [
      "BROKER",
      "CONSUMER",
      "DEALER",
      "DISTRIBUTOR",
      "TRADER"
    ]

    setCustomerType({
      error: "",
      loading: false,
      data: customerType?.map((d) => ({ id: d, value: d, label: d, })),
    });

    // Customer Type L1
    setCustomerTypeL1({ ...customerTypeL1, loading: true })
    axios
      .get(`${APIROUTES.GET_CUSTOMER_TYPE}?active=true`, options.call(props.user))
      .then(
        (response) => {
          let data = [];
          if (response.status === 200) data = response.data;
          setCustomerTypeL1({
            error: "",
            loading: false,
            data: data?.map((e) => ({
              id: e.id,
              value: e.type_name,
              label: e.type_name,
            })),
          });

        })
      .catch((error) => {
        setCustomerTypeL1({
          error: error?.message,
          loading: false,
          data: [],
        });
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000);
      });
  }

  function fetchItemTag() {
    setItemTag({ ...itemTag, loading: true })
    axios
      .get(`${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}?active=true`, options.call(props.user))
      .then(
        (response) => {
          let data = [];
          if (response.status === 200) data = response.data;
          setItemTag({
            error: "",
            loading: false,
            data: data?.map((e) => ({
              id: e.id,
              value: e.tag_name,
              label: e.tag_name,
            })),
          });

        })
      .catch((error) => {
        setItemTag({
          error: error?.message,
          loading: false,
          data: [],
        });
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000);
      });
  }

  function fetchSubContractStatus() {
    let SubContractStatusArray = ["ONGOING", "CLOSED", "CANCELLED"]
    setSubContractStatusDropdown({
      error: "",
      loading: false,
      data: SubContractStatusArray?.map((e) => ({
        id: e,
        value: e,
        label: e,
      })),
    });

  }

  const handleApplyFilter = (filters) => {
    console.log(filters)

  }

  const handleTabValue = (value) => {
    setTabValue(value)
  }

  const handleTableDataSorting = (tabValue, sortedData) => {
    console.log(tabValue, sortedData)

    sortedData?.forEach((data, i) => {
      data.index = i + 1
    })
    filterDatainFE({ filter: selectedInvoiceFilters, data: sortedData })
    // setInvoices({ ...invoices, data: sortedData })
  }

  const handleStartDateChange = (date, tabValue) => {
    // console.log(date, tabValue)
    if (tabValue === 0) {
      setSelectedInvoiceStartDate((moment(date).format('YYYY-MM-DD')))
      setInvoices({ data: [], loading: false, error: '' })
    }
    if (tabValue === 1) {
      setSelectedContractStartDate((moment(date).format('YYYY-MM-DD')))
      setContracts({ data: [], loading: false, error: '' })
    }
    if (tabValue === 2) {
      if (searchByTypeCategory.value === "Item Category") {
        setSelectedDDItemCategoryStartDate((moment(date).format('YYYY-MM-DD')))
      }
      if (searchByTypeCategory.value === "Item") {
        setSelectedDDItemStartDate((moment(date).format('YYYY-MM-DD')))
      }
    }
    if (tabValue === 3) {
      console.log(date)
      setSelectedWBGStartDate((moment(date).format('YYYY-MM-DD')))
    }
    if (tabValue === 4) {
      console.log(date)
      setSelectedEXRStartDate((moment(date).format('YYYY-MM-DD')))
    }
    if (tabValue === 5) {
      console.log(date)
      setSelectedSubContractStartDate((moment(date).format('YYYY-MM-DD')))
    }
  }

  const handleEndDateChange = (date, tabValue) => {
    // console.log(date, tabValue)
    if (tabValue === 0) {
      setSelectedInvoiceEndDate((moment(date).format('YYYY-MM-DD')))
      setInvoices({ data: [], loading: false, error: '' })
    }
    if (tabValue === 1) {
      setSelectedContractEndDate((moment(date).format('YYYY-MM-DD')))
      setContracts({ data: [], loading: false, error: '' })
    }
    if (tabValue === 2) {
      if (searchByTypeCategory.value === "Item Category") {
        setSelectedDDItemCategoryEndDate((moment(date).format('YYYY-MM-DD')))
      } if (searchByTypeCategory.value === "Item") {
        setSelectedDDItemEndDate((moment(date).format('YYYY-MM-DD')))
      }
    }
    if (tabValue === 3) {
      setSelectedWBGEndDate((moment(date).format('YYYY-MM-DD')))
      console.log(date)
    }
    if (tabValue === 4) {
      setSelectedEXREndDate((moment(date).format('YYYY-MM-DD')))
      console.log(date)
    }
    if (tabValue === 5) {
      setSelectedSubContractEndDate((moment(date).format('YYYY-MM-DD')))
      console.log(date)
    }
  }


  // Invoice Handles
  function fetchPlants() {
    setPlants({ ...plants, loading: true });
    axios
      .get(`${APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST}?active=true`, options.call(props.user))
      .then(
        (response) => {
          let data = [];
          if (response.status === 200) data = response.data;

          setPlants({
            error: "",
            loading: false,
            data: data?.map((e) => ({
              id: e.plant_code,
              value: e.plant_short_name,
              label: e.plant_short_name,
            })),
          });
          // console.log(plants)
        })
      .catch((error) => {
        setPlants({ error: error?.message, loading: false, data: [] });
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
        console.error(error);
      });
  }

  const selectPlant = (e) => {
    console.log(e.target.value)
    // if (e.target.value === "All") {
    //   setPlant({ value: "All" })
    //   setPlantSelected(false)
    // }
    // else {
    setDPTPlant(plants.data.find((el) => el.value === e.target.value));
    setDPTSelected(true)
    // }
    // setInvoices({ data: [], loading: false, error: '' })

  };

  const selectMultiPlant = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value, tabValue, searchByTypeCategory)
    if (tabValue === 0) {

      let sitems = []
      setPlant(typeof value === 'string' ? value.split(',') : value,)
      if (value.includes("All")) {
        setPlant(["All"])
        setPlantSelected(false)
        setDisablePlants(true)
      }
      else {
        // setPlant(plants.data.find((el) => el.value === e.target.value));
        e.target.value?.map(val => {
          let sitem = plants.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setDisablePlants(false)
        setPlantSelected(true)
      }
      // console.log(sitems)
      setInvoicePlantPayload(sitems)
      setInvoices({ data: [], loading: false, error: '' })
    }

    if (tabValue === 1) {

      let sitems = []
      setContractsPlants(typeof value === 'string' ? value.split(',') : value,)
      if (value.includes("All")) {
        setContractsPlants(["All"])
        setContractsPlantSelected(false)
        setDisableContractsPlants(true)
      }
      else {
        // setPlant(plants.data.find((el) => el.value === e.target.value));
        e.target.value?.map(val => {
          let sitem = plants.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setDisableContractsPlants(false)
        setContractsPlantSelected(true)
      }
      console.log(sitems)
      setContractsPlantPayload(sitems?.length <= 0 ? ["All"] : sitems)
      setContracts({ data: [], loading: false, error: '' })
    }


    if (tabValue === 2) {
      // console.log("here")
      let sitems = []
      setDDItemCategoryPlant(typeof value === 'string' ? value.split(',') : value,)
      if (value.includes("All")) {
        setDDItemCategoryPlant(["All"])
        // setPlantSelected(false)
        setDisableddItemCategoryPlants(true)
      }
      else {
        // setPlant(plants.data.find((el) => el.value === e.target.value));
        e.target.value?.map(val => {
          let sitem = plants.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        // setDDItemCategoryPlantPayload(sitems)
        // setDisablePlants(false)
        // setPlantSelected(true)

        setDisableddItemCategoryPlants(false)
      }
      // console.log(sitems)
      setDDItemCategoryPlantPayload(sitems)
      // setInvoices({ data: [], loading: false, error: '' })
    }

    // if (tabValue === 2 && searchByTypeCategory.value === "Item") {
    //   let sitems = []
    //   setDDItemPlant(typeof value === 'string' ? value.split(',') : value,)
    //   if (value.includes("All")) {
    //     setDDItemPlant(["All"])
    //     setDisableddItemPlants(true)
    //     // setPlantSelected(false)
    //     // setDisablePlants(true)
    //   }
    //   else {
    //     // setPlant(plants.data.find((el) => el.value === e.target.value));
    //     e.target.value?.map(val => {
    //       let sitem = plants.data.find((el) => el.value === val)
    //       if (sitem)
    //         sitems.push(sitem.id)
    //     })
    //     // setDDItemPlantPayload(sitems)
    //     setDisableddItemPlants(false)
    //     // setDisablePlants(false)
    //     // setPlantSelected(true)
    //   }
    //   // console.log(sitems)
    //   setDDItemPlantPayload(sitems)
    //   // setInvoices({ data: [], loading: false, error: '' })
    // }


    if (tabValue === 3) {
      let sitems = []
      setWBGPlant(typeof value === 'string' ? value.split(',') : value,)
      if (value.includes("All")) {
        setWBGPlant(["All"])
        setDisableWBGPlants(true)
        // setPlantSelected(false)
        // setDisablePlants(true)
      }
      else {
        setDisableWBGPlants(false)
        // setPlant(plants.data.find((el) => el.value === e.target.value));
        e.target.value?.map(val => {
          let sitem = plants.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setWBGPlantPayload(sitems)
        // setDisablePlants(false) 
        // setPlantSelected(true)
      }
      // console.log(sitems)
      setWBGPlantPayload(sitems)
      // setInvoices({ data: [], loading: false, error: '' })
    }

    if (tabValue === 4) {
      let sitems = []
      setEXRModule(typeof value === 'string' ? value.split(',') : value,)
      if (value.includes("customer")) {
        setEXRModule(["customer"])
        setDisableEXRModules(true)
        // setPlantSelected(false)
        // setDisablePlants(true)
      }
      else {
        setDisableEXRModules(false)
        // setPlant(plants.data.find((el) => el.value === e.target.value));
        e.target.value?.map(val => {
          let sitem = allModules.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setEXRModulePayload(sitems)
        // setDisablePlants(false) 
        // setPlantSelected(true)
      }
      // console.log(sitems)
      setEXRModulePayload(sitems)
      // setInvoices({ data: [], loading: false, error: '' })
    }
  };


  const handleHiddenColumns = (hiddenColumns) => {

    if (tabValue === 0) {
      setInvoiceHiddenColumns(hiddenColumns)
    } else if (tabValue === 1) {
      setcontractHiddenColumns(hiddenColumns)
    } else if (tabValue === 2 && searchByTypeCategory.value === "Item Category") {
      setddItemCategoryHiddenColumns(hiddenColumns)
    } else if (tabValue === 2 && searchByTypeCategory.value === "Item") {
      setddItemHiddenColumns(hiddenColumns)
    } else if (tabValue === 3) {
      setwbgHiddenColumns(hiddenColumns)
    } else if (tabValue === 4) {
      setexrHiddenColumns(hiddenColumns)
    } else if (tabValue === 5) {
      setSubContractHiddenColumns(hiddenColumns)
    }
  }

  const selectCustomerType = (e) => {
    const { target: { value } } = e;
    if (tabValue === 5) {
      setSubContractCustomerType(typeof value === 'string' ? value.split(',') : value);
      let sitems = []
      if (value.includes("All")) {
        setSubContractDisableCustomerType(true)
        setSubContractCustomerType(["All"])
      } else if (value?.length === 0) {
        setSubContractDisableCustomerType(false)
      } else {
        e.target.value?.map(val => {
          let sitem = customerType.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
      }
      setSubContractCustomerTypePayload(sitems)
    }
  }

  const selectCustomerTypeL1 = (e) => {
    const { target: { value } } = e;
    if (tabValue === 5) {
      setSubContractCustomerTypeL1(typeof value === 'string' ? value.split(',') : value);
      let sitems = []
      if (value.includes("All")) {
        setSubContractDisableCustomerTypeL1(true)
        setSubContractCustomerTypeL1(["All"])
      } else if (value?.length === 0) {
        setSubContractDisableCustomerTypeL1(false)
      } else {
        e.target.value?.map(val => {
          let sitem = customerTypeL1.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
      }
      setSubContractCustomerTypeL1Payload(sitems)
    }
  }

  const selectItemtag = (e) => {
    const { target: { value } } = e;
    if (tabValue === 1) {
      setContractItemTag(typeof value === 'string' ? value.split(',') : value);
      let sitems = []
      if (value.includes("All")) {
        setContractDisableItemtag(true)
        setContractItemTag(["All"])
      } else if (value?.length === 0) {
        setContractDisableItemtag(false)
      } else {
        e.target.value?.map(val => {
          let sitem = itemTag.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
      }
      setContractItemTagPayload(sitems)
    }
    if (tabValue === 5) {
      setSubContractItemTag(typeof value === 'string' ? value.split(',') : value);
      let sitems = []
      if (value.includes("All")) {
        setSubContractDisableItemtag(true)
        setSubContractItemTag(["All"])
      } else if (value?.length === 0) {
        setSubContractDisableItemtag(false)
      } else {
        e.target.value?.map(val => {
          let sitem = itemTag.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
      }
      setSubContractItemTagPayload(sitems)
    }
  }

  const selectSubContractStatus = (e) => {
    const { target: { value } } = e;
    if (tabValue === 5) {
      setSubContractStatus(typeof value === 'string' ? value.split(',') : value);
      let sitems = []
      if (value.includes("All")) {
        setSubContractDisableStatus(true)
        setSubContractStatus(["All"])
      } else if (value?.length === 0) {
        setSubContractDisableStatus(false)
      } else {
        e.target.value?.map(val => {
          let sitem = SubContractStatusDropdown.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
      }
      setSubContractStatusPayload(sitems)
    }
  }

  const selectItemCategory = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value)
    if (tabValue === 0) {
      setInvoiceItemCategory(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      let sitems = []
      if (value.includes("All")) {
        setDisableItemCats(true)
        setInvoiceItemCategory(["All"])
        setInvoiceItemCategorySelected(false)
      } else if (value?.length === 0) {
        setDisableItemCats(false)
        setInvoiceItemCategorySelected(false)
      } else {
        e.target.value?.map(val => {
          let sitem = itemCategories.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setInvoiceItemCategorySelected(true)
      }
      // console.log(isArray(sitems))
      setInvoiceItemCategoryPayload(sitems)
    }


    if (tabValue === 1) {
      setContractCategory(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      let sitems = []
      if (value.includes("All")) {
        setContractDisableItemCats(true)
        setContractCategory(["All"])
        setContractSelected(true)
      } else if (value?.length === 0) {
        setContractDisableItemCats(false)
        setContractSelected(false)
      } else {
        e.target.value?.map(val => {
          let sitem = itemCategories.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setContractSelected(true)
      }
      // console.log(sitems)
      setContractItemCatPayload(sitems)
    }

    if (tabValue === 2) {
      setDDItemCategory(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      let sitems = []
      if (value.includes("All")) {
        setddItemCatdisableItemCats(true)
        setDDItemCategory(["All"])
        setDDItemCategorySelected(true)
      } else if (value?.length === 0) {
        setddItemCatdisableItemCats(false)
        setDDItemCategorySelected(false)
      } else {
        e.target.value?.map(val => {
          let sitem = itemCategories.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setDDItemCategorySelected(true)
      }
      // console.log(sitems)
      setddItemCategoryPayload(sitems)
    }

    if (tabValue === 3) {
      setWBGCategory(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      let sitems = []
      if (value.includes("All")) {
        setDisableWBGItemCats(true)
        setWBGCategory(["All"])
        setWBGSelected(true)
      } else if (value?.length === 0) {
        setDisableWBGItemCats(false)
        setWBGSelected(false)
      } else {

        setDisableWBGItemCats(false)
        e.target.value?.map(val => {
          let sitem = itemCategories.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setWBGSelected(true)
      }
      // console.log(sitems)
      setWBGCategoryPayload(sitems)
    }

    if (tabValue === 4) {
      setEXRChangeTCLCategory(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      let sitems = []
      if (value.includes("credit limit provided")) {
        setDisableEXRChangeTCL(true)
        setEXRChangeTCLCategory(["credit limit provided"])
        setEXRSelected(true)
      } else if (value?.length === 0) {
        setDisableEXRChangeTCL(false)
        setEXRSelected(false)
      } else {

        setDisableEXRChangeTCL(false)
        e.target.value?.map(val => {
          let sitem = TCLCategories.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
        setEXRSelected(true)
      }
      // console.log(sitems)
      setEXRChangeTCLPayload(sitems)
    }

    if (tabValue === 5) {
      setSubContractCategory(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      let sitems = []
      if (value.includes("All")) {
        setSubContractDisableItemCats(true)
        setSubContractCategory(["All"])
      } else if (value?.length === 0) {
        setSubContractDisableItemCats(false)
      } else {
        e.target.value?.map(val => {
          let sitem = itemCategories.data.find((el) => el.value === val)
          if (sitem)
            sitems.push(sitem.id)
        })
      }
      // console.log(sitems)
      setSubContractCategoryPayload(sitems)
    }

  };

  const handleInvoiceSelect = (e) => {
    setSelectedInvoiceFilter(filters.find((el) => el.value === e.target.value));
    // console.log(selectedInvoiceFilter)
  };


  const onInvoiceButtonClick = () => {
    // setPlantSelected(false)
    setShowInvoiceTable(true)
    setShowMoreFilters(false)
    // if()
    setInvoices({ data: [], loading: false, error: "" });
    fetchInvoices()
  }

  const handleCountStateUpdate = (data) => {
    setFetchReportProgress(data)
  }

  const handleCSVCountStateUpdate = (data) => {
    setFetchSubContractCSVReportProgress(data)
  }

  const fetchInvoices = (filters) => {
    setInvoices({ ...invoices, loading: true });
    setInvoiceLoader(true)

    let data = {
      start_date: selectedInvoiceStartDate,
      end_date: selectedInvoiceEndDate,
    }
    // if (invoicePlantPayload?.length > 0) {
    if (invoicePlantPayload.includes("All") || invoicePlantPayload?.length === 0) { }
    else
      data.plant_code = invoicePlantPayload
    // }
    if (invoiceItemCategorySelected || invoiceItemCategoryPayload?.length !== 0) {
      data.item_category = invoiceItemCategoryPayload
    }
    // axios
    //   .post(
    //     `${APIROUTES.GET_INVOICES}`,
    //     data,
    //     options.call(props.user),
    //   )
    fetchRecursivePaginatedData(
      `${APIROUTES.GET_INVOICES}`,
      data,
      options.call(props.user),
      handleCountStateUpdate)
      .then(({ responseData }) => {
        handleInvoiceData(responseData)
      })
      .catch(({ error, responseData }) => {
        if (responseData === undefined) {
          setOriginalInvoiceResponse({ error: error?.message, loading: false, data: [] });
          setInvoices({ error: error?.message, loading: false, data: [] });
        }
        else {
          handleInvoiceData(responseData, 'error')
        }
        setInvoiceLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      }
      );

  };

  const handleInvoiceData = (responseData, error = "") => {
    console.log(error)
    setFetchReportProgress(0)
    setInvoiceLoader(false)
    console.log(responseData)
    if (error !== "") {
      handleOpenSnackbar("Something went wrong", "error");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    let resdata = [];
    resdata = responseData
    let calculations = {}
    // data = []
    if (resdata?.length > 0) {

      resdata?.forEach((d, i) => {
        d.index = i + 1
        d.invoice_date = d.doc_date ? moment(d.doc_date).format('DD-MMM-YYYY')
          : d.ack_date ? (moment(d.ack_date).format('DD-MMM-YYYY'))
            : d.modified_at ? (moment(d.modified_at, "DD-MM-YYYY HH:mm").format('DD-MMM-YYYY'))
              : "-"
        d.payment_due_date = d.payment_due_date ? moment(d.payment_due_date).format('DD-MMM-YYYY') : ""
        d.party_ref_date = d.party_ref_date ? moment(d.party_ref_date).format('DD-MMM-YYYY') : ""

      })
      filterDatainFE({ filter: selectedInvoiceFilters, data: resdata, isInitialRender: true })
    }
    else {
      setOriginalInvoiceResponse({ loading: false, data: resdata, error: "", calculations })
      setInvoices({ loading: false, data: resdata, error: "", calculations },)

    }
  }

  const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});


  const handleTableCalculations = (tabledata, isInitialRender) => {
    console.log(tabledata, tabValue)
    const { filters, data, planned_plants } = tabledata
    let headers = plantTableHeaders(tabValue)
    let tableCalculations = []
    let calculations = []
    // Logic for GrandTotal Only
    headers?.forEach((header) => {
      if (header.calculation) {
        let calValue = 0
        if (header.calculation === "total_trips") {
          data?.forEach(d => {
            if (d[header.calculation]) {
              calValue += Number(d[header.calculation])
            }
            else {
              calValue += 0
            }
          })
        } else {
          data?.forEach(d => {
            if (["net_rate", "dispatched_quantity", "cancelled_quantity", "total_invoice_amount"].includes(header.calculation)) {
              calValue += Number(d[header.calculation])
              // console.log(header.calculation, calValue)
            }
            else {
              if (d[header.key]) {
                calValue += Number(d[header.key])
              } else {
                calValue += 0
              }
            }
          })
          if (header.calculation === "net_rate") {
            calValue /= data?.length
          }
        }
        // console.log(calValue)
        if (header.calculation === "avg_rate"
          || header.calculation === "avg_discount"
          || header.calculation === "avg_tare_weight") {
          calValue /= data?.length
        }
        let obj = {}
        obj["calc_text"] = header.calc_text
        obj["header"] = header.header
        obj["calculation"] = parseFloat(header.dec_places ? calValue.toFixed(header.dec_places) : calValue)
        obj["key"] = header.key
        // console.log(obj)
        tableCalculations.push(obj)
        calculations[header.calculation] = parseFloat(header.dec_places ? calValue.toFixed(header.dec_places) : calValue)
      }
    })
    handleCSVDataGeneration(data, calculations, planned_plants)

    switch (tabValue) {
      case 0:
        if (isInitialRender) {
          setOriginalInvoiceResponse({
            loading: false,
            data,
            error: "",
            calculations, tableCalculations
          })
        }
        setInvoices({
          loading: false,
          data,
          error: "",
          calculations, tableCalculations
        })

        // setInvoiceCSVData({ ...invoiceCSVData, calculations })
        break;

      case 1: console.log("hereeeeeeeeeeeeeee")
        if (isInitialRender) {
          setOriginalContractResponse({
            loading: false,
            data,
            error: "",
            calculations,
            planned_plants
          })
        }
        setContracts({
          ...contracts,
          error: "",
          loading: false,
          data,
          calculations,
          planned_plants
        })
        break;

      case 2:
        if (searchByTypeCategory.value === "Item Category") {
          if (isInitialRender) {
            setoriginalItemCategoryDDetailsResponse({ error: "", loading: false, data, calculations });
          }
          setItemCategoryDDetails({ error: "", loading: false, data, calculations });
        }
        if (searchByTypeCategory.value === "Item") {
          if (isInitialRender) {
            setoriginalItemDDetailsResponse({ error: "", loading: false, data, calculations });
          }

          setItemDDetails({ error: "", loading: false, data, calculations });
        }
        break;

      case 3:
        if (isInitialRender) {
          setOriginalWBGResponse({ data, loading: false, error: "", calculations })
        }
        setWBGResponse({ data, loading: false, error: "", calculations })
        break;

      case 4:
        if (isInitialRender) {
          setOriginalEXRResponse({ data, loading: false, error: "", calculations })
        }
        setEXRResponse({ data, loading: false, error: "", calculations })
        break;

      case 5: console.log("here subcontract")
        if (isInitialRender) {
          setOriginalSubContractResponse({
            loading: false,
            data,
            error: "",
            calculations,
            planned_plants
          })
        }
        setSubContractResponse({
          ...SubContractResponse,
          error: "",
          loading: false,
          data,
          calculations,
          planned_plants
        })
        break;



      default:
        break;
    }
    // }, 1000);

  }
  const handleCSVDataGeneration = (resdata, calculations, planned_plants) => {
    // console.log("here", resdata, searchByTypeCategory.value)
    // console.log(calculations)
    if (tabValue === 0) {
      let data = []
      if (resdata?.length === 0) {
        console.log("here")
        setInvoiceCSVData({ ...invoiceCSVData, data: resdata, calculations });
        setInvoicePDFData({ ...invoicePDFData, data: resdata, calculations });
      } else {
        // console.log(calculations)
        let lafields = ["unit_discount", "tcs_rate", "tcs_amount", "loading_premium",
          "payment_basis", "customer_discount", "freight_charge_amount", "fright", "insurance_amount", "la_number"
        ]
        let itemFields = ["item_code", "igst_amount", "cgst_amount", "sgst_amount",
          "item", "tag", "net_item_material_amount", "item_material_amount", "item_total_amount",
          "item_net_rate", "item_nature", "item_dispatch_qty", "item_cancel_qty",
          "item_tcs_amount", "actual_section_count"]

        let obj
        resdata?.map(d => {
          d.loading_advice_items?.map((item, i) => {
            obj = JSON.parse(JSON.stringify(d));
            itemFields?.map(field => {
              if (field === "net_item_material_amount")
                obj[field] = item["item_material_amount"] + item["sgst_amount"] + item["cgst_amount"] + item["igst_amount"]
              else obj[field] = item[field]

            })
            obj.item_details = item
            if (obj.irn) {
              obj.irn_date = obj.doc_date
            }
            obj.consignee_state_name = obj?.consignee_state?.name
            obj.consignee_state_code = obj?.consignee_state?.state_code
            obj.eway_new_number = obj.eway_number ? Number(obj.eway_number) : null
            if (i <= d?.weighing_slip_no?.length - 1) {
              obj.weighing_slip_number = d.weighing_slip_no[i].weighbridge
            }
            else {
              obj.weighing_slip_number = null
            }
            data.push(obj)
          })
        })

        setInvoiceCSVData({ ...invoiceCSVData, data, calculations })
        setInvoicePDFData({ ...invoicePDFData, data, calculations })
      }
    }

    if (tabValue === 1) {
      let data = []
      let lafields = ["unit_discount", "tcs_rate", "tcs_amount", "loading_premium",
        "payment_basis", "customer_discount", "freight_charge_amount", "fright", "insurance_amount", "la_number", "payment_date"
      ]
      let itemFields = ["item_code", "igst_amount", "net_rate", "cgst_amount", "sgst_amount", "item", "material_amount"]
      let plantPlanningFields = ["sgst", "plant_name", "pending_qty", "igst", "dispached_qty", "cgst", "allotted_qty", "plant", "plant_code", "balance_qty", "cancelled_qty", "sub_contract_enabled"]
      if (resdata?.length === 0) {
        setContractCSVData({ ...contractCSVData, data: resdata })
      } else {

        resdata?.map(d => {
          if (d.days_running < 0) d.days_running = 0
          let obj = JSON.parse(JSON.stringify(d));
          if (d.plant_plannings && d.plant_plannings?.length > 0) {
            d.plant_plannings?.map(plan => {
              let obj = JSON.parse(JSON.stringify(d));
              plantPlanningFields?.map(field => {
                obj[field] = plan[field]
              })

              data.push(obj)
            })
          } else {
            data.push(d)
          }
        })
        setContractCSVData({ ...contractCSVData, data })
      }
    }

    if (tabValue === 2) {
      let totalQty = 0
      let data = []
      let lafields = ["unit_discount", "tcs_rate", "tcs_amount", "loading_premium",
        "payment_basis", "customer_discount", "freight_charge_amount", "fright", "insurance_amount", "la_number", "payment_date"
      ]
      let itemFields = ["item_code", "igst_amount", "cgst_amount", "sgst_amount",
        "item", "tag", "net_item_material_amount", "item_material_amount", "item_qty",
        "item_net_rate", "item_nature", "item_dispatch_qty", "item_cancel_qty",
        "item_tcs_amount", "rate_difference", "actual_section_count", "item_total_amount"]

      resdata?.map((d, j) => {
        if (d.loading_advice_items) {
          d.loading_advice_items?.map((item, i) => {
            let obj = JSON.parse(JSON.stringify(d));
            itemFields?.map(field => {
              if (field === "net_item_material_amount")
                obj[field] = item["item_material_amount"] + item["sgst_amount"] + item["cgst_amount"] + item["igst_amount"]
              else obj[field] = item[field]
            })
            obj.item_details = item
            obj.weighbridge_in_date = obj.weighbridge_in ? moment(obj.weighbridge_in.split(" ")[0], "yyyy-MM-DD").format("DD-MMM-yyyy") : ""
            obj.weighbridge_in_time = obj.weighbridge_in ? moment(obj.weighbridge_in.split(" ")[1], "HH:mm").format("LT") : ""
            obj.weighbridge_out_date = obj.weighbridge_out ? moment(obj.weighbridge_out.split(" ")[0], "yyyy-MM-DD").format("DD-MMM-yyyy") : ""
            obj.weighbridge_out_time = obj.weighbridge_out ? moment(obj.weighbridge_out.split(" ")[1], "HH:mm").format("LT") : ""
            if (i <= d?.weighings?.length - 1) {
              obj.wslip_no = d.weighings[i].id
              obj.wslip_date = d.weighings[i].created_at
              obj.w_loaded_weight = d.weighings[i].loaded_weight
            }
            else {
              obj.wslip_no = null
              obj.wslip_date = null
              obj.w_loaded_weight = null
            }
            data.push(obj)
          })
        }
      })

      if (searchByTypeCategory.value === "Item Category")
        setItemCategoryDDetailsCSV({ error: "", loading: false, data });
      if (searchByTypeCategory.value === "Item")
        setItemDDetailsCSV({ error: "", loading: false, data });
    }
    if (tabValue === 3) {
      setWBGResponseCSV({ data: resdata, loading: false, error: "" })
    }

    if (tabValue === 4) {
      setEXRResponseCSV({ data: resdata, loading: false, error: "" })
    }
    // if (tabValue === 5) {
    //   let data = []
    //   let mappingHeader = []
    //   let topHeader = []
    //   if (resdata?.length === 0) {
    //     setSubContractResponseCSV({ ...SubContractResponseCSV, data: resdata })
    //   } else {
    //     console.log(SubContractResponseCSV, resdata, calculations, planned_plants)
    //     // header Preparation
    //     subContractsCsvHeaders.forEach(h => {
    //       if (h?.splitCol === true) {
    //         planned_plants && planned_plants.forEach(item => {
    //           mappingHeader.push({ label: item.itemSize, key: item.itemSize })
    //           topHeader.push({ label: 'Balance Subcontract Quantity', key: item.itemSize })
    //         })
    //       }
    //       else {
    //         mappingHeader.push({ label: h.header, key: h.key })
    //         topHeader.push({ label: '', key: h.key })
    //       }
    //     })
    //     let SecondHeaderRow = {};
    //     mappingHeader.map(head => {
    //       SecondHeaderRow[head.key] = head.label
    //     })
    //     data.push(SecondHeaderRow)

    //     // data Preparation
    //     resdata.map(d => {
    //       let obj = {}
    //       mappingHeader.map(head => {
    //         obj[head.key] = d[head.key]
    //       })
    //       data.push(obj)
    //     })

    //     // data Total Sum Preparation
    //     let AvgField = ['ex_rate', 'freight_rate']
    //     let grandTotalObj = {}
    //     subContractsCsvHeaders.forEach(head => {
    //       if (Boolean(head.calculation) && Boolean(head.splitCol !== true)) {
    //         console.log(head.key, AvgField.includes(head.key) && Number(Number(Number(resdata.reduce((a, c) => { return +a + Number(c[head.key] || 0) }, 0)) / Number(resdata.length || 0)).toFixed(2)))
    //         grandTotalObj[head.key] = AvgField.includes(head.key)
    //           ? Number(Number(Number(resdata.reduce((a, c) => { return +a + Number(c[head.key] || 0) }, 0)) / Number(resdata.length || 0)).toFixed(2))
    //           : Number(resdata.reduce((a, c) => { return +a + Number(c[head.key] || 0) }, 0))
    //       } else if (head.key === 'tag_name') {
    //         grandTotalObj[head.key] = 'TOTAL CALCULATION'
    //       } else {
    //         grandTotalObj[head.key] = ''
    //       }
    //     })

    //     planned_plants && planned_plants.forEach(item => {
    //       grandTotalObj[item.itemSize] = resdata.reduce((a, c) => { return +a + Number(c[item.itemSize] || 0) }, 0)
    //     })

    //     data.push(grandTotalObj)

    //   }
    //   setSubContractResponseCSV({ ...SubContractResponseCSV, data, header: topHeader })
    // }
  }

  const handleFilterIconClick = (key) => {
    // console.log(key, searchByTypeCategory)
    if (tabValue === 0)
      setShowMoreFilters(!showMoreFilters)
    if (tabValue === 1)
      setShowContractMoreFilters(!showContractMoreFilters)
    if (tabValue === 2 && searchByTypeCategory.value === "Item")
      setShowddItemMoreFilters(!showddItemMoreFilters)
    if (tabValue === 2 && searchByTypeCategory.value === "Item Category")
      setShowddItemCategoryMoreFilters(!showddItemCategoryMoreFilters)
    if (tabValue === 3)
      setShowWBGMoreFilters(!showWBGMoreFilters)
    if (tabValue === 4)
      setShowEXRMoreFilters(!showEXRMoreFilters)
    if (tabValue === 5)
      setShowSubContractMoreFilters(!showEXRMoreFilters)

  }

  const handleApplyInvoiceFilterData = (filters) => {
    if (tabValue === 0) {
      setInvoiceFilterCount(filters.filterCount || 0)
      setSelectedInvoiceFilters(filters)
    }
    if (Object.keys(filters)?.length === 0) {
      if (tabValue === 0)
        setShowMoreFilters(false)
      if (tabValue == 1)
        setShowContractMoreFilters(false)
    }
    if (filters) filterDatainFE({ filters, data: originalInvoiceResponse.data })

  }

  const handleApplyContractFilterData = (filters) => {
    // console.log("here", filters)
    setContractFilterCount(filters.filterCount || 0)
    setSelectedContractFilters(filters)
    if (Object.keys(filters)?.length === 0) {
      setShowContractMoreFilters(false)
    }
    if (filters) {
      filterDatainFE({ filters, data: originalContractResponse.data })
    }
  }

  const handleApplyddItemFilterData = (filters) => {
    // console.log(filters, tabValue)

    setddItemFilterCount(filters.filterCount || 0)
    setSelectedddItemFilters(filters)
    if (Object.keys(filters)?.length === 0) {
      setShowddItemMoreFilters(false)
    }
    if (filters) {
      filterDatainFE({ filters, data: originalItemDDetailsResponse.data })
    }
  }

  const handleApplyddItemCategoryFilterData = (filters) => {
    // console.log(filters, tabValue)
    setddItemCategoryFilterCount(filters.filterCount || 0)
    setSelectedddItemCategoryFilters(filters)
    if (Object.keys(filters)?.length === 0) {
      setShowddItemCategoryMoreFilters(false)
    }
    if (filters) {
      filterDatainFE({ filters, data: originalItemCategoryDDetailsResponse.data })
    }
  }

  const handleApplyWBGFilterData = (filters) => {
    // console.log(filters, tabValue)
    // console.log(filters, tabValue)
    setWBGFilterCount(filters.filterCount || 0)
    setSelectedWBGFilters(filters)
    if (Object.keys(filters)?.length === 0) {
      setShowWBGMoreFilters(false)
    }
    if (filters) {
      filterDatainFE({ filters, data: originalWBGResponse.data })
    }
  }

  const handleApplyEXRFilterData = (filters) => {
    // console.log(filters, tabValue)
    // console.log(filters, tabValue)
    setEXRFilterCount(filters.filterCount || 0)
    setSelectedEXRFilters(filters)
    if (Object.keys(filters)?.length === 0) {
      setShowEXRMoreFilters(false)
    }
    if (filters) {
      filterDatainFE({ filters, data: originalEXRResponse.data })
    }
  }

  const handleApplySubContractFilterData = (filters) => {
    // console.log("here", filters)
    setSubContractFilterCount(filters.filterCount || 0)
    setSelectedSubContractFilters(filters)
    if (Object.keys(filters)?.length === 0) {
      setShowSubContractMoreFilters(false)
    }
    if (filters) {
      filterDatainFE({ filters, data: originalSubContractResponse.data })
    }
  }


  const filterDatainFE = (list) => {
    console.log(list)
    // console.log(arraySortBykeyAllBrowser(list.data, 'customer_code'))
    const { filters, data, isInitialRender, planned_plants } = list
    // let sortedData = arraySortBykeyAllBrowser(list.data, 'customer_code')
    let sortedData = arraySortBykeyAllBrowser(list.data, 'customer', '', 'customer_code')
    if (filters && Object.keys(filters)?.length > 0) {
      // console.log("here")
      let filteredData = []
      if (tabValue === 2 && filters.filterCount === 1 && filters.la_status) {
        // if () {
        if (filteredData?.length > 0) {
          filteredData = filterByMultiSelect(filteredData, filters.la_status)
        } else {
          filteredData = filterByMultiSelect(sortedData, filters.la_status)
        }
        // }
      } else {
        console.log("here")
        filteredData = sortedData.filter(d => {

          // Broker
          if (tabValue === 1) {
            if (filters.broker_id && !d.broker)
              return false
            if (filters.broker_id && d.broker.toLowerCase() !== filters.broker.toLowerCase())
              return false
          }

          // Customer
          if (tabValue === 0 || tabValue === 1 || tabValue === 2) {
            if (filters.customer_id && !d.customer)
              return false
            if (filters.customer_id && d.customer.toLowerCase() !== filters.customer.toLowerCase())
              return false
          }

          // Buyer

          if (tabValue === 0 || tabValue === 2) {
            if (filters.buyer) {
              if (filters.buyer && !d.buyer)
                return false
              if (filters.buyer_id && d.buyer.toLowerCase() !== filters.buyer.toLowerCase())
                return false
            }
          }

          // Payment Method 
          if (tabValue === 0 || tabValue === 1 || tabValue === 2) {
            if (filters.payment_method_id && d.payment_method) {
              if (!(d.payment_method === filters.payment_method_name))
                return false
            } else
              if (filters.payment_method_id && !d.payment_method)
                return false
          }

          // Payment Status

          if (tabValue === 0 && filters.payment_status) {
            if (filters.payment_status == 'Unpaid' && (d.payment_status == 'PENDING' || d.payment_status == 'PARTIAL')) {
              return true;
            } else if (filters.payment_status == 'Paid' && d.payment_status == 'PAID') {
              return true;
            } else if (filters.payment_status == 'Overdue' && ((d.payment_status == 'PENDING' || d.payment_status == 'PARTIAL') && moment(d.payment_due_date).isBefore(new Date(), 'day'))) {
              return true;
            } else if (filters.payment_status == 'Due Today' && ((d.payment_status == 'PENDING' || d.payment_status == 'PARTIAL') && moment(d.payment_due_date).isSame(new Date(), 'day'))) {
              return true;
            } else {
              return false;
            }
          }

          // MIN MAX QTY
          if (tabValue === 0) {
            if (filters.max_qty && filters.min_qty) {
              if (Number(d.dispatched_quantity) < Number(filters.min_qty) || Number(d.dispatched_quantity) > Number(filters.max_qty) || !d.dispatched_quantity)
                return false
            } else
              if (filters.max_qty && filters.min_qty) {
                if (!d.quantity)
                  return false
              }
          }


          // PAYMENT DUE Date
          if (tabValue === 0) {
            if (filters.payment_due_date) {
              if (!(moment(d.payment_due_date).format("DD-MMM-YYYY") === moment(filters.payment_due_date).format("DD-MMM-YYYY")))
                return false
            }
            if (filters.payment_due_date && !d.payment_due_date) {
              return false
            }
          }
          if (tabValue === 1 || tabValue == 2) {
            if (filters.payment_due_date) {
              if (!(moment(d.payment_date).format("DD-MMM-YYYY") === moment(filters.payment_due_date).format("DD-MMM-YYYY")))
                return false
            }
            if (filters.payment_due_date && !d.payment_date) {
              return false
            }
          }

          // LA QTY

          if (tabValue === 2) {
            if (filters.la_quantity) {
              // console.log(Number(filters.la_quantity), Number(d.la_quantity))
              if (filters.la_quantity && !d.la_quantity)
                return false
              if (Number(filters.la_quantity) !== Number(d.la_quantity))
                return false
            }
          }

          //Contract Qty

          if (tabValue === 1) {
            if (filters.contract_quantity) {
              if (filters.contract_quantity && !d.quantity)
                return false
              if (Number(filters.contract_quantity) !== Number(d.quantity))
                return false
            }
          }

          // Vehicle #
          if (tabValue === 3) {
            if (filters.truck_number) {
              if (filters.truck_number && !d.truck_number)
                return false
              if (!(filters.truck_number.toLowerCase() === (d.truck_number.toLowerCase())))
                return false
            }
            if (filters.customer) {
              if (filters.customer_id && !d.customer_name)
                return false
              if (filters.customer_id && d.customer_name.toLowerCase() !== filters.customer.toLowerCase())
                return false
            }
            if (filters.transporter_name) {
              if (filters.transporter_name && !d.transporter)
                return false
              if (!(filters.transporter_name.toLowerCase() === (d.transporter.toLowerCase())))
                return false
            }
            if (filters.max_qty && filters.min_qty) {
              // console.log(Number(d.net_weight), Number(filters.min_qty), Number(filters.max_qty))
              if (Number(d.net_weight) < Number(filters.min_qty) || Number(d.net_weight) > Number(filters.max_qty) || !d.net_weight)
                return false
            } else
              if (filters.max_qty && filters.min_qty) {
                if (!d.net_weight)
                  return false
              }
          }
          // console.log(filteredData)
          return d
        })
      }

      if (tabValue === 3) {
        let transporters = []
        filteredData?.forEach(d => {
          if (!transporters.includes(d.transporter_code)) {
            transporters.push(d.transporter_code)
            d.total_trips = d.total_trip
            // count++
          }
        })
      }

      handleTableCalculations({ ...list, data: filteredData }, isInitialRender)

    }
    else {
      console.log("here")
      if (tabValue === 3) {
        let transporters = []
        data?.forEach(d => {
          if (!transporters.includes(d.transporter_code)) {
            transporters.push(d.transporter_code)
            d.total_trips = d.total_trip
            // count++
          }
        })
      }

      handleTableCalculations(list, isInitialRender)

    }
  }

  let filterByMultiSelect = (data, filter) => {
    let filteredMultiData = []
    console.log(data, filter)

    data?.forEach(d =>
      filter?.forEach(f => {
        // console.log(f)
        if (d.dispatch_status.toLowerCase() === f.toLowerCase())
          filteredMultiData.push(d)
      })
    )
    // for (let i = 0; i < data?.length; i++) {
    //   // console.log(data[i].dispatch_status)
    //   for (let j = 0; j < filter?.length; j++) {
    //     console.log(data.dispatch_status)
    //     if (data[i].dispatch_status.toLowerCase() === filter[j].toLowerCase()) {
    //       console.log(data.dispatch_status)
    //       filteredMultiData.push(data[i])
    //     }
    //   }
    // }
    // console.log(filteredMultiData)
    return filteredMultiData
  }
  // New Contracts Handles

  const selectContractTypes = (e) => {
    // console.log(e.target.value)
    const {
      target: { value },
    } = e;
    let sitems = []
    setselectedContractTypes(typeof value === 'string' ? value.split(',') : value,)
    setContracts({ data: [], loading: false, error: "" })
    if (value.includes("All")) {
      setselectedContractTypes(["All"])
      // setPlantSelected(false)
      // setDisablePlants(true)
      setDisableContractTypes(true)
    }
    else {
      // setPlant(plants.data.find((el) => el.value === e.target.value));
      e.target.value?.map(val => {
        let sitem = plants.data.find((el) => el.value === val)
        if (sitem)
          sitems.push(sitem.id)
      })
      // setDisablePlants(false)
      // setPlantSelected(true)
      setDisableContractTypes(false)

    }
  }


  const selectContractCategory = (e) => {
    setContractCategory(itemCategories.data.find((el) => el.value === e.target.value));
    setContractSelected(true)
    // console.log(contractCategory)
    setContracts({ data: [], loading: false, error: '' })
  };

  const onContractButtonClick = () => {
    // if (tabValue === 1) {
    setShowContractTable(true)
    // console.log("End Date on Button Click", selectedContractEndDate)
    setContracts({ data: [], loading: false, error: "" });
    // console.log(contractCategory)
    fetchContracts()
  }


  const fetchContracts = (itemCategory, report_type) => {
    let payload = {}
    setContracts({ ...contracts, loading: true })
    setContractsLoader(true)
    payload = {
      start_date: selectedContractStartDate,
      end_date: selectedContractEndDate,
      // report_type: report_type,
    }
    if (contractItemCatPayload.includes("All") || contractItemCatPayload?.length === 0) {

    } else {
      payload.item_category = contractItemCatPayload
    }

    if (contractsPlantPayload.includes("All") || contractsPlantPayload?.length === 0) {
      setContractsPlantPayload(['All'])
    } else {
      payload.plant = contractsPlantPayload
    }
    if (selectedContractTypes.includes("All") || selectedContractTypes?.length === 0) {

    } else {
      payload.report_type = selectedContractTypes
    }
    if (contractItemtagPayload.includes("All") || contractItemtagPayload?.length === 0) {
    } else { payload.item_tag = contractItemtagPayload }

    fetchRecursivePaginatedData(
      `${APIROUTES.GET_CONTRACT_REPORTS}`,
      payload,
      options.call(props.user),
      handleCountStateUpdate)
      .then(({ responseData }) => {
        handleContractData(responseData)
      })
      .catch(({ error, responseData }) => {
        console.log(error)
        if (responseData === undefined) {
          setContracts({ error: error?.message, loading: false, data: [] });
          setOriginalContractResponse({ error: error?.message, loading: false, data: [] })
        }
        else { handleContractData(responseData, "error") }
        setContractsLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      }
      );
  }

  const handleContractData = (responseData, error = "") => {
    setFetchReportProgress(0)
    let resdata = [];
    setContractsLoader(false)
    if (error !== "") {
      handleOpenSnackbar("Something went wrong", "error");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    resdata = responseData;
    let lafields = ["unit_discount", "tcs_rate", "tcs_amount", "loading_premium",
      "payment_basis", "customer_discount", "freight_charge_amount", "fright", "insurance_amount", "la_number", "payment_date"
    ]
    let itemFields = ["item_code", "igst_amount", "net_rate", "cgst_amount", "sgst_amount", "item", "material_amount"]
    let plantPlanningFields = ["sgst", "plant_name", "pending_qty", "igst", "dispached_qty", "cgst", "cancelled_quantity", "allotted_qty", "plant"]
    // let data = []
    let planned_plants = []
    let columnSpansList = []
    contractsHeaders?.forEach(header => {
      if (header.splitCol) {
        columnSpansList.push(header)
      }
    })
    let spansNeeded = []
    if (resdata?.length > 0) {
      console.log(resdata)
      resdata?.forEach((data, i) => {
        data.id = i
        // totalQty += Number(data.quantity)
        if (data.plant_plannings) {
          data.plant_plannings?.forEach(d => {


            if (planned_plants?.length === 0) {
              // console.log(i)
              planned_plants.push(d.plant_code)
            } else {
              if (planned_plants.includes(d.plant_code)) {
                // console.log("here")
              } else {
                planned_plants.push(d.plant_code)
                columnSpansList?.forEach(col => {
                  data[`${col.key}_${d.plant_code}`] = d[`${col.plant_planning_header}`]
                  // console.log(data[`${col.key}_${d.plant_code}`], d[`${col.plant_planning_header}`])
                })
              }
            }
            // planned_plants.push(d.plant_code)
            columnSpansList?.forEach(col => {
              data[`${col.key}_${d.plant_code}`] = d[`${col.plant_planning_header}`]
              // console.log(data[`${col.key}_${d.plant_code}`], d[`${col.plant_planning_header}`])
            })
          })

        }
        let created_at_date = data.created_at ? moment(data.created_at, "DD-MM-YYYY HH:mm").format("DD-MMM-yyyy") : ""
        data.created_at_date = created_at_date
        let party_ref_date = data.party_ref_date ? moment(data.party_ref_date).format("DD-MMM-yyyy") : ""
        data.party_ref_date = party_ref_date
      })

      // resdata?.forEach(data => {
      if (planned_plants?.length > 0) {
        let planned = []
        planned_plants?.forEach(plant => {
          planned.push({ plant_code: plant })
        })
        planned_plants = planned

      }
      console.log(planned_plants)
    }

    // if (Object.keys(selectedContractFilters)?.length > 0) {
    filterDatainFE({ filters: selectedContractFilters, data: resdata, isInitialRender: true, planned_plants })

  }

  // Dispatch Details Handles

  // Search By Item 
  const selectDDItem = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value)
    setDDItem(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    let sitems = []
    if (value.includes("All")) {
      setDisableItems(true)
      setDDItem(["All"])
      // setInvoiceItemCategorySelected(false)
    } else if (value?.length === 0) {
      setDisableItems(false)
      // setdisadd(false)
      // setDDItemSelected(false)
    } else {
      e.target.value?.map(val => {
        let sitem = items.data.find((el) => el.value === val)
        if (sitem)
          sitems.push(sitem.id)
      })
      // setDDItem(true)
    }
    // console.log(isArray(sitems))
    setddItemPayload(sitems)
    // setDDItem(items.data.find((el) => el.value === e.target.value));
    // setDDItemSelected(true)
    // setItemDDetails({ data: [], loading: false, error: '' })
  };

  const onDDItemButtonClick = () => {
    setShowDDItemTable(true)
    setItemDDetails({ data: [], loading: false, error: "" });
    fetchDispatchDetails("ITEMS")
  }

  // console.log(moment(selectedInvoiceStartDate).isAfter(selectedInvoiceEndDate))
  // Search By Item Category


  const onDDItemCategoryButtonClick = () => {
    // setContractSelected(!newContractSelected)
    setShowDDItemCategoryTable(true)
    setItemCategoryDDetails({ data: [], loading: false, error: "" });
    // console.log(contractCategory)
    fetchDispatchDetails("CATEGORIES")
  }
  function fetchDispatchDetails(fetchFor) {
    // console.log(ddItem)
    let payload = {}
    let params = ""
    if (ddItemCategoryPlantPayload.includes("All") || ddItemCategoryPlantPayload?.length === 0) {

    } else {
      payload.plant = ddItemCategoryPlantPayload
    }
    if (fetchFor === "CATEGORIES") {
      setItemCategoryDDetails({ ...itemCategoryDDetails, loading: true })
      setddItemCategoryLoader(true)
      payload.start_date = selectedDDItemCategoryStartDate
      payload.end_date = selectedDDItemCategoryEndDate
      // payload = {
      //   start_date: selectedDDItemCategoryStartDate,
      //   end_date: selectedDDItemCategoryEndDate
      // }
      if (ddItemCategoryPayload.includes("All") || ddItemCategoryPayload?.length === 0) {

      } else {
        payload.item_category = ddItemCategoryPayload
      }
      params = `item_category_id=${ddItemCategory.id}&start_date=${selectedDDItemCategoryStartDate}&end_date=${selectedDDItemCategoryEndDate}`
    }
    if (fetchFor === "ITEMS") {
      setItemDDetails({ ...itemDDetails, loading: true })
      setddItemLoader(true)
      payload.start_date = selectedDDItemStartDate
      payload.end_date = selectedDDItemEndDate
      // payload = {
      //   start_date: selectedDDItemStartDate,
      //   end_date: selectedDDItemEndDate
      // }
      // if (ddItemPlantPayload.includes("All") || ddItemPlantPayload??.length === 0) {

      // } else {
      //   payload.plant = ddItemPlantPayload
      // }
      if (ddItemPayload.includes("All") || ddItemPayload?.length === 0) {

      } else {
        payload.item_id = ddItemPayload
      }
      params = `item_id=${ddItem.id}&start_date=${selectedDDItemStartDate}&end_date=${selectedDDItemEndDate}`
    }

    fetchRecursivePaginatedData(
      `${APIROUTES.GET_DISPATCH_DETAILS_REPORTS}`,
      payload,
      options.call(props.user),
      handleCountStateUpdate)
      .then(({ responseData }) => {
        handleLoadingAdviceData(responseData, fetchFor)
      })
      .catch(({ error, responseData }) => {
        console.log(error.response)
        if (fetchFor === "CATEGORIES") {
          setddItemCategoryLoader(false)
          setItemCategoryDDetails({ error: error?.message, loading: false, data: [] });
        }
        if (fetchFor === "ITEMS") {
          setddItemLoader(false)
          setItemDDetails({ error: error?.message, loading: false, data: [] });
        }
        if (responseData !== undefined) {
          handleLoadingAdviceData(responseData, fetchFor, "error")
        }
        if (error?.response?.status !== 500) {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 5000);
        }
        else {
          handleOpenSnackbar('Server Timeout', "error");
          setTimeout(() => {
            handleCloseSnackbar();
          }, 5000);
        }
      });
  }

  const handleLoadingAdviceData = (responseData, fetchFor, error = "") => {
    setFetchReportProgress(0)
    let data = [];
    let resdata = [];
    if (error !== "") {
      handleOpenSnackbar("Something went wrong", "error");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    // let data = []
    if (fetchFor === "CATEGORIES") {
      setddItemCategoryLoader(false)
    } else if (fetchFor === "ITEMS") {
      setddItemLoader(false)
    }

    data = [];
    let totalQty = 0
    resdata = responseData;
    resdata?.forEach((d, i) => {
      let created_at_date = d.created_at && moment(d.created_at, [
        "DD/MM/YYYY hh:mm:ss A",
        "DD/MM/YYYY hh:mm:ss",
        "DD/MM/YYYY",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD",
        "YYYY-MM-DD hh:mm:ss", "yyyy-MM-DD HH:mm"
      ]).format("DD-MMM-YYYY")
      d.created_at_date = created_at_date
      let created_at = d.created_at && moment(d.created_at, [
        "DD/MM/YYYY hh:mm:ss A",
        "DD/MM/YYYY hh:mm:ss",
        "DD/MM/YYYY",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD",
        "YYYY-MM-DD hh:mm:ss", "yyyy-MM-DD HH:mm"
      ]).format("DD-MMM-YYYY HH:mm")
      d.created_at = created_at
      d.id = i
    })
    filterDatainFE({ filters: searchByTypeCategory.value === "Item" ? selectedddItemFilters : selectedddItemCategoryFilters, data: resdata, isInitialRender: true })

  }

  const selectSearchByTypeCategory = (e) => {
    setSearchByTypeCategory(searchByTypeCategories.find((el) => el.value === e.target.value));
    setItemDDetails({ error: "", loading: false, data: [] })
    setItemCategoryDDetails({ error: "", loading: false, data: [] })
    setDDItem(["All"])
    setDDItemCategory(["All"])

    // setDDItemSelected(false)
    // setDDItemCategorySelected(true)
  };

  const onWBGButtonClick = () => {
    // console.log("clicked!!!")

    setShowWBGTable(true)
    setWBGResponse({ data: [], loading: false, error: "" });
    fetchWBGData()

  }

  const onEXRButtonClick = () => {
    // console.log("clicked!!!")

    setShowEXRTable(true)
    setEXRResponse({ data: [], loading: false, error: "" });
    fetchEXRData()

  }


  const fetchWBGData = () => {
    let payload = {}
    setWBGResponse({ ...WBGResponse, loading: true })
    setWBGLoader(true)
    payload = {
      start_date: selectedWBGStartDate,
      end_date: selectedWBGEndDate,
      // report_type: report_type,
    }
    if (WBGCategoryPayload.includes("All") || WBGCategoryPayload?.length === 0) {

    } else {
      payload.item_category = WBGCategoryPayload
    }
    if (WBGPlantPayload.includes("All") || WBGPlantPayload?.length === 0) {

    } else {
      payload.plant = WBGPlantPayload
    }

    // console.log(payload)

    // axios.post(APIROUTES.GET_WEIGHBIDGE_AND_GATE_REPORTS,
    //   payload,
    //   options.call(props.user),
    // )
    fetchRecursivePaginatedData(`${APIROUTES.GET_WEIGHBIDGE_AND_GATE_REPORTS}`,
      payload,
      options.call(props.user),
      handleCountStateUpdate)
      .then(({ responseData }) => {
        handleWBGData(responseData)
      })
      .catch(({ error, responseData }) => {
        if (responseData !== undefined) {
          handleWBGData(responseData, "error")
        }
        setWBGLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      })
  }

  const handleWBGData = (responseData, error = "") => {
    setFetchReportProgress(0)
    if (error !== "") {
      handleOpenSnackbar("Something went wrong", "error");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    let data = responseData
    let transporters = []
    let total_trips
    let totalQty = 0
    let count = 0
    setWBGLoader(false)
    data?.map((d, i) => {

      totalQty += d.net_weight

      d.actual_gate_in_time = d.actual_gate_in_time && moment(d.actual_gate_in_time, [
        "DD/MM/YYYY hh:mm:ss A",
        "DD/MM/YYYY hh:mm:ss",
        "DD/MM/YYYY",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD",
        "YYYY-MM-DD hh:mm:ss",
        "YYYY-MM-DD hh:mm:ss.SSS"
      ]).format("DD-MMM-YYYY hh:mm A")
      d.gate_out_date = d.gate_out_date && moment(d.gate_out_date, [
        "DD/MM/YYYY hh:mm:ss A",
        "DD/MM/YYYY hh:mm:ss",
        "DD/MM/YYYY",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD",
        "YYYY-MM-DD hh:mm:ss",
        "YYYY-MM-DD hh:mm:ss.SSS"
      ]).format("DD-MMM-YYYY hh:mm A")

      d.id = i
      if (!transporters.includes(d.transporter_code)) {
        transporters.push(d.transporter_code)
        d.total_trips = d.total_trip
        count++
      }
    })

    filterDatainFE({ filters: selectedWBGFilters, data, isInitialRender: true })
  }

  const fetchEXRData = () => {
    let payload = {}
    setEXRResponse({ ...EXRResponse, loading: true })
    setEXRLoader(true)
    payload = {
      start_date: selectedEXRStartDate,
      end_date: selectedEXREndDate,
      module: "customer",
      field: "TCL"
      // report_type: report_type,
    }
    if (EXRChangeTCLPayload.includes("credit limit provided") || EXRChangeTCLPayload?.length === 0) {

    } else {
      payload.field = EXRChangeTCLPayload
    }
    if (EXRModulePayload.includes("customer") || EXRModulePayload?.length === 0) {

    } else {
      payload.module = EXRModulePayload
    }

    // console.log(payload)

    // axios.post(APIROUTES.GET_EXCEPTION_REPORTS,
    //   payload,
    //   options.call(props.user),
    // )
    fetchRecursivePaginatedData(`${APIROUTES.GET_EXCEPTION_REPORTS}`,
      payload,
      options.call(props.user),
      handleCountStateUpdate)
      .then(({ responseData }) => {
        handleEXRData(responseData)
      })
      .catch(({ error, responseData }) => {
        if (responseData !== undefined) {
          handleEXRData(responseData, "error")
        }
        setEXRLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      })
  }

  const handleEXRData = (responseData, error = "") => {
    setFetchReportProgress(0)
    if (error !== "") {
      handleOpenSnackbar("Something went wrong", "error");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    let data = responseData
    setEXRLoader(false)
    data?.forEach((d, i) => {
      // totalQty += Number(d?.quantity)
      let created_at_date = d.created_at && moment(d.created_at, [
        "DD/MM/YYYY hh:mm:ss A",
        "DD/MM/YYYY hh:mm:ss",
        "DD/MM/YYYY",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD hh:mm:ss", "yyyy-MM-DD hh:mm:ss A"
      ]).format("DD-MMM-YYYY")
      d.created_at_date = created_at_date
      let created_at = d.created_at && moment(d.created_at, [
        "DD/MM/YYYY hh:mm:ss A",
        "DD/MM/YYYY hh:mm:ss",
        "DD/MM/YYYY",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD hh:mm:ss", "yyyy-MM-DD hh:mm:ss A"
      ]).format("DD-MMM-YYYY HH:mm")
      d.created_at = created_at
      d.id = i
    })

    filterDatainFE({ filters: selectedEXRFilters, data, isInitialRender: true })
  }

  const onDPTButtonClick = () => {
    // console.log("clicked!!!")

    setShowDPTTable(true)
    setDPTResponse({ data: [], loading: false, error: "" });
    fetchDPTData()

  }

  const fetchDPTData = () => {
    let payload = {}
    setDPTResponse({ ...DPTResponse, loading: true })

    payload = {
      // start_date: selectedDPTStartDate,
      // end_date: selectedDPTEndDate,
      plant: DPTPlant.id
      // report_type: report_type,
    }
    if (DPTCategoryPayload.includes("All") || DPTCategoryPayload?.length === 0) {

    } else {
      payload.item_category = DPTCategoryPayload
    }
  }

  const onSubContractButtonClick = () => {
    setShowSubContractTable(true)
    setSubContractResponse({ data: [], loading: false, error: "" });
    fetchSubContracts()
  }


  const fetchSubContracts = (itemCategory, report_type) => {
    let payload = {}
    setSubContractResponse({ ...SubContractResponse, loading: true })
    setSubContractsLoader(true)
    payload = {
      start_date: selectedSubContractStartDate,
      end_date: selectedSubContractEndDate,
    }
    if (SubContractCategoryPayload.includes("All") || SubContractCategoryPayload?.length === 0) {
    } else { payload.item_category = SubContractCategoryPayload }

    if (SubContractCustomerTypePayload.includes("All") || SubContractCustomerTypePayload?.length === 0) {
    } else { payload.customer_type = SubContractCustomerTypePayload }

    if (SubContractCustomerTypeL1Payload.includes("All") || SubContractCustomerTypeL1Payload?.length === 0) {
    } else { payload.customer_l1 = SubContractCustomerTypeL1Payload }

    if (SubContractItemtagPayload.includes("All") || SubContractItemtagPayload?.length === 0) {
    } else { payload.item_tag = SubContractItemtagPayload }
    if (SubContractStatusPayload.includes("All") || SubContractStatusPayload?.length === 0) {
    } else { payload.status = SubContractStatusPayload }


    fetchRecursivePaginatedData(
      `${APIROUTES.GET_SUBCONTRACT_REPORTS}`,
      payload,
      options.call(props.user),
      handleCountStateUpdate)
      .then(({ responseData }) => {
        handleSubContractData(responseData)
      })
      .catch(({ error, responseData }) => {
        console.log(error)
        if (responseData === undefined) {
          setSubContractResponse({ error: error?.message, loading: false, data: [] });
          setOriginalSubContractResponse({ error: error?.message, loading: false, data: [] })
        }
        else { handleSubContractData(responseData, "error") }
        setSubContractsLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      });
  }

  const handleSubContractData = (responseData, error = "") => {
    setFetchReportProgress(0)
    let resdata = [];
    setSubContractsLoader(false)
    if (error !== "") {
      handleOpenSnackbar("Something went wrong", "error");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    resdata = responseData;

    let planned_ItemSize = []
    let columnSpansList = []
    subContractsHeaders?.forEach(header => {
      if (header.splitCol) {
        columnSpansList.push(header)
      }
    })

    if (resdata?.length > 0) {
      resdata?.forEach((data, i) => {
        data.id = i
        if (data?.subcontract_item_details && data?.subcontract_item_details.length > 0) {
          data.subcontract_item_details?.forEach(d => {
            if (planned_ItemSize?.length === 0) {
              !checkInvalidValue(d.subcontract_item_size) && planned_ItemSize.push(d.subcontract_item_size)
            } else {
              if (planned_ItemSize.includes(d.subcontract_item_size)) {
              } else {
                !checkInvalidValue(d.subcontract_item_size) && planned_ItemSize.push(d.subcontract_item_size)
              }
            }
            columnSpansList?.forEach(col => {
              if (!checkInvalidValue(d.subcontract_item_size))
                data[(`${d.subcontract_item_size}`).replace(/ +/g, "")] = d[`${col.item_size_header}`]
            })
          })

        }

        if (!checkInvalidValue(data?.before_subcontract_la)) {
          subContractsHeaders?.forEach(h => {
            data[`${h.key}`] = !checkInvalidValue(data[`${h.key}`])
              ? data[`${h.key}`]
              : h.key === "subcontract_number"
                ? 'BEFORE SUBCONTRACT ENABLED'
                : h.key === "subcontract_quantity"
                  ? data.before_subcontract_la?.all_status_qty
                  : h.key === "subcontract_dispatch_quantity"
                    ? data.before_subcontract_la?.dispatch_billed_qty
                    : ""
          })
        }
        if (!checkInvalidValue(data?.not_alloted_quantity)) {
          subContractsHeaders?.forEach(h => {
            data[`${h.key}`] = !checkInvalidValue(data[`${h.key}`])
              ? data[`${h.key}`]
              : h.key === "subcontract_number"
                ? 'NOT ALLOTED'
                : h.key === "subcontract_quantity"
                  ? data.not_alloted_quantity
                  : h.key === "subcontract_dispatch_quantity"
                    ? 0
                    : ""
          })
        }
        data.subcontract_date = !checkInvalidValue(data.subcontract_date) ? moment(data.subcontract_date, "YYYY-MM-DD").format("DD-MMM-YYYY") : ""

      })

      let updatedHeader = [...subContractsHeaders.slice(0, -1)]
      if (planned_ItemSize?.length > 0) {
        let planned = []
        planned_ItemSize.sort().forEach(size => {
          planned.push({ itemSize: size.replace(/ +/g, "") })
          updatedHeader.push({
            minWidth: 100,
            header: size,
            key: size.replace(/ +/g, ""),
            calculation: `total_itemSize_qty_${size.replace(/ +/g, "")}`,
            calc_text: "SUM QTY (UM)",
            dec_places: 3,
          })
        })
        planned_ItemSize = arraySortBykeyAllBrowser(planned, 'itemSize')
      }
      setPlannedUniqueItemSize({
        plannedItemSize: planned_ItemSize,
        updatedHeader: updatedHeader
      })
      console.log(planned_ItemSize, updatedHeader, resdata)
    }

    filterDatainFE({ filters: selectedSubContractFilters, data: resdata, isInitialRender: true, planned_plants: planned_ItemSize })

  }

  const handleSubContractCSVClick = () => {
    console.log("clicked Here CSV")
    let payload = {}
    payload = {
      start_date: selectedSubContractStartDate,
      end_date: selectedSubContractEndDate,
    }
    if (SubContractCategoryPayload.includes("All") || SubContractCategoryPayload?.length === 0) {
    } else { payload.item_category = SubContractCategoryPayload }

    if (SubContractCustomerTypePayload.includes("All") || SubContractCustomerTypePayload?.length === 0) {
    } else { payload.customer_type = SubContractCustomerTypePayload }

    if (SubContractCustomerTypeL1Payload.includes("All") || SubContractCustomerTypeL1Payload?.length === 0) {
    } else { payload.customer_l1 = SubContractCustomerTypeL1Payload }

    if (SubContractItemtagPayload.includes("All") || SubContractItemtagPayload?.length === 0) {
    } else { payload.item_tag = SubContractItemtagPayload }

    if (SubContractStatusPayload.includes("All") || SubContractStatusPayload?.length === 0) {
    } else { payload.status = SubContractStatusPayload }
    setSubContractResponseCSV({ ...SubContractResponseCSV, loading: true })

    fetchRecursivePaginatedData(
      `${APIROUTES.GET_SUBCONTRACT_CSV_REPORTS}`,
      payload,
      options.call(props.user),
      handleCSVCountStateUpdate)
      .then(({ responseData }) => {
        handleSubContractCSVData(responseData)
      })
      .catch(({ error, responseData }) => {
        console.log(error)
        if (responseData === undefined) {
          setSubContractResponseCSV({ error: error?.message, loading: false, data: [], header: [], });
        }
        else { handleSubContractCSVData(responseData, "error") }
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      });
  }

  const handleSubContractCSVData = (responseData, error = "") => {
    console.log(responseData, error)
    setFetchSubContractCSVReportProgress(0)
    let resdata = [];
    setSubContractResponseCSV({ ...SubContractResponseCSV, loading: false })
    if (error !== "") {
      handleOpenSnackbar("Something went wrong", "error");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000);
    }
    resdata = responseData;
    console.log(resdata)

    if (resdata?.length === 0) {
      setSubContractResponseCSV({ ...SubContractResponseCSV, data: resdata, loading: false })
    } else {
      console.log("fdsf")
      let data = []
      let planned_ItemSize = []
      let columnSpansList = []
      subContractsCsvHeaders?.forEach(header => {
        if (header.splitCol) {
          columnSpansList.push(header)
        }
      })

      resdata?.map(d => {
        let created_at_date = d.created_at ? moment(d.created_at, "DD-MM-YYYY HH:mm").format("DD-MMM-yyyy") : ""
        d.created_at_date = created_at_date
        let party_ref_date = d.party_ref_date ? moment(d.party_ref_date).format("DD-MMM-yyyy") : ""
        d.party_ref_date = party_ref_date
        let order_validity = d.order_validity ? moment(d.order_validity).format("DD-MMM-yyyy") : ""
        d.order_validity = order_validity
        if (d.days_running < 0)
          d.days_running = 0

        let obj = JSON.parse(JSON.stringify(d));
        // if (
        //   d.before_subcontract_la.length > 0 ||
        //   d.not_alloted_quantity.length > 0 ||
        //   d.subcontract_details.length > 0
        // ) {
        // before subcontract / not alloted
        if (d.before_subcontract_la.length > 0) {
          d.before_subcontract_la.map(item => {
            let newObj = {}
            subContractsCsvHeaders.filter(d => d?.splitCol !== true).forEach(h => {
              newObj[h.key] = h.key === "subcontract_quantity"
                ? item?.all_status_qty
                : h.key === "subcontract_dispatch_quantity"
                  ? item?.dispatch_billed_qty
                  : h.key === 'plant'
                    ? item.plant_name
                    : h.key === 'plant_code'
                      ? item.plant_code
                      : h.key === 'subcontract_number'
                        ? 'BEFORE SUBCONTRACT ENABLED'
                        : !checkInvalidValue(obj[h.key])
                          ? obj[h.key]
                          : ''
            })
            data.push(newObj)
          })
        }
        if (d.not_alloted_quantity.length > 0) {
          d.not_alloted_quantity.map(item => {
            let newObj = {}
            subContractsCsvHeaders.filter(d => d?.splitCol !== true).forEach(h => {
              newObj[h.key] = h.key === 'subcontract_quantity'
                ? item.not_alloted_quantity
                : h.key === 'subcontract_dispatch_quantity'
                  ? 0
                  : h.key === 'plant'
                    ? item.plant_name
                    : h.key === 'plant_code'
                      ? item.plant_code
                      : h.key === 'subcontract_number'
                        ? 'NOT ALLOTED'
                        : !checkInvalidValue(obj[h.key])
                          ? obj[h.key]
                          : ''
            })
            data.push(newObj)
          })
        }

        // subcontract data push
        if (d.subcontract_details.length > 0) {
          d.subcontract_details.map(sub => {
            let newObj = JSON.parse(JSON.stringify(obj));
            Object.assign(newObj, sub)
            if (sub?.subcontract_item_details && sub?.subcontract_item_details.length > 0) {
              sub.subcontract_item_details?.map(item => {
                if (planned_ItemSize?.length === 0) {
                  !checkInvalidValue(item.subcontract_item_size) && planned_ItemSize.push(item.subcontract_item_size)
                } else {
                  if (planned_ItemSize.includes(item.subcontract_item_size)) {
                  } else {
                    !checkInvalidValue(item.subcontract_item_size) && planned_ItemSize.push(item.subcontract_item_size)
                  }
                }
                columnSpansList?.forEach(col => {
                  if (!checkInvalidValue(item.subcontract_item_size))
                    newObj[(`${item.subcontract_item_size}`).replace(/ +/g, "")] = item[`${col.item_size_header}`]
                })
              })
            }
            newObj.subcontract_date = !checkInvalidValue(sub.subcontract_date)
              ? moment(sub.subcontract_date, "YYYY-MM-DD").format("DD-MMM-YYYY")
              : ""
            data.push(newObj)
          })
        }
        // } else {
        // data.push(obj)
        // }
      })

      // header Prepare
      let mappingHeader = []
      subContractsCsvHeaders.forEach(h => {
        if (h?.splitCol === true) {
          planned_ItemSize && planned_ItemSize.sort().forEach(item => {
            console.log(planned_ItemSize, item.replace(/ +/g, ""))
            mappingHeader.push({ label: `Balance Subcontract Quantity ${item}`, key: item.replace(/ +/g, "") })
          })
        }
        else {
          mappingHeader.push({ label: h.label, key: h.key })
        }
      })
      console.log(data, mappingHeader)
      setSubContractResponseCSV({ ...SubContractResponseCSV, data, header: mappingHeader })
    }
  }


  if (!isAccessibleModule(props.user, "Reports")) return <AccessDenied />;

  // if (props.isMobile) return <ReportsMobile {...props} />;
  return (
    <>
      <ReportsWeb
        {...props}
        isMobile={props.isMobile}
        showInvoiceTable={showInvoiceTable}
        showContractTable={showContractTable}
        // showOCTable={showOCTable}
        // showCCTable={showCCTable}
        handleApplyFilter={handleApplyFilter}
        paymentMethods={paymentMethods}
        paymentStatusList={paymentStatusList}
        itemCategories={itemCategories}
        transporters={transporters}
        items={items}
        selectedInvoiceFilters={selectedInvoiceFilters}
        invoiceFilterCount={invoiceFilterCount}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        dataUrl={dataUrl}
        handleTabValue={handleTabValue}
        handleTableDataSorting={handleTableDataSorting}
        //Invoices
        plant={plant}
        invoiceItemCategory={invoiceItemCategory}
        disableItemCats={disableItemCats}
        disableItems={disableItems}
        disablePlants={disablePlants}
        selectedInvoiceStartDate={selectedInvoiceStartDate}
        setSelectedInvoiceStartDate={setSelectedInvoiceStartDate}
        selectedInvoiceEndDate={selectedInvoiceEndDate}
        setSelectedInvoiceEndDate={setSelectedInvoiceEndDate}
        selectedInvoiceFilter={selectedInvoiceFilter}
        plants={plants}
        invoices={invoices}
        selectPlant={selectPlant}
        selectMultiPlant={selectMultiPlant}
        handleInvoiceSelect={handleInvoiceSelect}
        invoiceCSVData={invoiceCSVData}
        invoicePDFData={invoicePDFData}
        // handleInvoiceStartDateChange={handleInvoiceStartDateChange}
        // handleInvoiceEndDateChange={handleInvoiceEndDateChange}
        onInvoiceButtonClick={onInvoiceButtonClick}
        plantSelected={plantSelected}
        handleFilterIconClick={handleFilterIconClick}
        showMoreFilters={showMoreFilters}
        showContractMoreFilters={showContractMoreFilters}
        showWBGMoreFilters={showWBGMoreFilters}
        showddItemCategoryMoreFilters={showddItemCategoryMoreFilters}
        showddItemMoreFilters={showddItemMoreFilters}
        invoiceFilterKeys={invoiceFilterKeys}
        handleApplyInvoiceFilterData={handleApplyInvoiceFilterData}
        selectItemCategory={selectItemCategory}
        // handleClearAllClick={handleClearAllClick}
        originalInvoiceResponse={originalInvoiceResponse}
        handleApplyWBGFilterData={handleApplyWBGFilterData}
        handleApplyddItemCategoryFilterData={handleApplyddItemCategoryFilterData}
        handleApplyddItemFilterData={handleApplyddItemFilterData}
        invoiceLoader={invoiceLoader}
        // contracts
        contractCategory={contractCategory}
        selectedContractFilter={selectedContractFilter}
        selectContractCategory={selectContractCategory}
        selectContractTypes={selectContractTypes}
        onContractButtonClick={onContractButtonClick}
        contractTypes={contractTypes}
        selectedContractTypes={selectedContractTypes}
        disableContractTypes={disableContractTypes}
        // newContractSelected={newContractSelected}
        contracts={contracts}
        contractCSVData={contractCSVData}
        handleApplyContractFilterData={handleApplyContractFilterData}
        setContracts={setContracts}
        contractItemtag={contractItemtag}
        contractDisableItemtag={contractDisableItemtag}
        selectedContractStartDate={selectedContractStartDate}
        selectedContractEndDate={selectedContractEndDate}
        contractDisableItemCats={contractDisableItemCats}
        contractFilterCount={contractFilterCount}
        selectedddItemFilters={selectedddItemFilters}
        ddItemFilterCount={ddItemFilterCount}
        selectedddItemCategoryFilters={selectedddItemCategoryFilters}
        ddItemCategoryFilterCount={ddItemCategoryFilterCount}
        selectedWBGFilters={selectedWBGFilters}
        WBGFilterCount={WBGFilterCount}
        selectedContractFilters={selectedContractFilters}
        // selectedContractCustomerFilterData={selectedContractCustomerFilterData}
        // selectedContractPaymentMethodFilterData={selectedContractPaymentMethodFilterData}
        // selectedContractBrokerFilterData={selectedContractBrokerFilterData}
        originalContractResponse={originalContractResponse}
        laStatus={laStatus}
        contractsPlants={contractsPlants}
        contractsPlantSelected={contractsPlantSelected}
        disableContractsPlants={disableContractsPlants}
        contractsLoader={contractsLoader}
        ddItemCategoryPlantPayload={ddItemCategoryPlantPayload}
        invoicePlantPayload={invoicePlantPayload}
        contractsPlantPayload={contractsPlantPayload}
        WBGPlantPayload={WBGPlantPayload}
        ddItemPlantPayload={ddItemPlantPayload}
        invoiceItemCategoryPayload={invoiceItemCategoryPayload}
        contractSelected={contractSelected}
        searchByTypeCategories={searchByTypeCategories}
        searchByTypeCategory={searchByTypeCategory}
        selectSearchByTypeCategory={selectSearchByTypeCategory}
        ddItemLoader={ddItemLoader}
        ddItemCategoryLoader={ddItemCategoryLoader}
        WBGLoader={WBGLoader}
        // Search By Items

        ddItemCategory={ddItemCategory}
        ddItemCatdisableItemCats={ddItemCatdisableItemCats}
        disableddItemPlants={disableddItemPlants}
        disableddItem={disableddItem}
        disableddItemCategoryPlants={disableddItemCategoryPlants}
        selectedDDItemFilter={selectedDDItemFilter}
        ddItemCategories={ddItemCategories}
        onDDItemButtonClick={onDDItemButtonClick}
        ddItemSelected={ddItemSelected}
        ddItemCategorySelected={ddItemCategorySelected}
        itemDDetails={itemDDetails}
        itemDDetailsCSV={itemDDetailsCSV}
        itemCategoryDDetails={itemCategoryDDetails}
        itemCategoryDDetailsCSV={itemCategoryDDetailsCSV}
        selectedDDItemStartDate={selectedDDItemStartDate}
        selectedDDItemEndDate={selectedDDItemEndDate}
        selectedDDItemCategoryEndDate={selectedDDItemCategoryEndDate}
        selectedDDItemCategoryStartDate={selectedDDItemCategoryStartDate}
        showDDItemTable={showDDItemTable}
        showDDItemCategoryTable={showDDItemCategoryTable}
        onDDItemCategoryButtonClick={onDDItemCategoryButtonClick}
        selectDDItem={selectDDItem}
        ddItem={ddItem}
        ddItemPlant={ddItemPlant}
        ddItemCategoryPlant={ddItemCategoryPlant}

        // Weighbridge and Gate Tab

        selectedWBGEndDate={selectedWBGEndDate}
        selectedWBGStartDate={selectedWBGStartDate}
        WBGPlant={WBGPlant}
        WBGResponse={WBGResponse}
        originalWBGResponse={originalWBGResponse}
        WBGResponseCSV={WBGResponseCSV}
        showWBGTable={showWBGTable}
        WBGSelected={WBGSelected}
        WBGCategory={WBGCategory}
        onWBGButtonClick={onWBGButtonClick}
        disableWBGPlants={disableWBGPlants}
        disableWBGItemCats={disableWBGItemCats}

        // selectedDPTEndDate={selectedDPTEndDate}
        // selectedDPTStartDate={selectedDPTStartDate}
        DPTPlant={DPTPlant}
        DPTResponse={DPTResponse}
        DPTResponseCSV={DPTResponseCSV}
        showDPTTable={showDPTTable}
        DPTSelected={DPTSelected}
        DPTCategory={DPTCategory}
        onDPTButtonClick={onDPTButtonClick}
        disableDPTPlants={disableDPTPlants}
        disableDPTItemCats={disableDPTItemCats}
        handleHiddenColumns={handleHiddenColumns}
        invoiceHiddenColumns={invoiceHiddenColumns}
        contractHiddenColumns={contractHiddenColumns}
        ddItemCategoryHiddenColumns={ddItemCategoryHiddenColumns}
        ddItemHiddenColumns={ddItemHiddenColumns}
        wbgHiddenColumns={wbgHiddenColumns}

        // EXCEPTION REPORTS
        EXRSelected={EXRSelected}
        module={module}
        EXRResponse={EXRResponse}
        originalEXRResponse={originalEXRResponse}
        EXRResponseCSV={EXRResponseCSV}
        showEXRTable={showEXRTable}
        EXRModule={EXRModule}
        EXRChangeTCLCategory={EXRChangeTCLCategory}
        selectedEXRStartDate={selectedEXRStartDate}
        selectedEXREndDate={selectedEXREndDate}
        disableEXRChangeTCL={disableEXRChangeTCL}
        disableEXRModules={disableEXRModules}
        EXRModulePayload={EXRModulePayload}
        EXRLoader={EXRLoader}
        onEXRButtonClick={onEXRButtonClick}
        EXRFilterCount={EXRFilterCount}
        handleApplyEXRFilterData={handleApplyEXRFilterData}
        selectedEXRFilters={selectedEXRFilters}
        showEXRMoreFilters={showEXRMoreFilters}
        exrHiddenColumns={exrHiddenColumns}
        customerType={customerType}
        customerTypeL1={customerTypeL1}
        itemTag={itemTag}
        SubContractStatusDropdown={SubContractStatusDropdown}
        subContractDisableItemCats={subContractDisableItemCats}
        subContractDisableCustomerType={subContractDisableCustomerType}
        subContractDisableCustomerTypeL1={subContractDisableCustomerTypeL1}
        subContractDisableItemtag={subContractDisableItemtag}
        subContractDisableStatus={subContractDisableStatus}
        plannedUniqueItemSize={plannedUniqueItemSize}
        SubContractCategory={SubContractCategory}
        SubContractCustomerType={SubContractCustomerType}
        SubContractCustomerTypeL1={SubContractCustomerTypeL1}
        SubContractItemtag={SubContractItemtag}
        SubContractStatus={SubContractStatus}
        selectedSubContractStartDate={selectedSubContractStartDate}
        selectedSubContractEndDate={selectedSubContractEndDate}
        SubContractResponse={SubContractResponse}
        subContractsLoader={subContractsLoader}
        originalSubContractResponse={originalSubContractResponse}
        SubContractResponseCSV={SubContractResponseCSV}
        selectedSubContractFilters={selectedSubContractFilters}
        subContractFilterCount={subContractFilterCount}
        showSubContractMoreFilters={showSubContractMoreFilters}
        subContractHiddenColumns={subContractHiddenColumns}
        showSubContractTable={showSubContractTable}
        selectSubContractStatus={selectSubContractStatus}
        selectItemtag={selectItemtag}
        selectCustomerTypeL1={selectCustomerTypeL1}
        selectCustomerType={selectCustomerType}
        onSubContractButtonClick={onSubContractButtonClick}
        handleApplySubContractFilterData={handleApplySubContractFilterData}
        refCSVLink={refCSVLink}
        handleSubContractCSVClick={handleSubContractCSVClick}
        fetchReportProgress={fetchReportProgress}
        fetchSubContractCSVReportProgress={fetchSubContractCSVReportProgress}
      />

      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </>
  )
}


export default withUser(Reports);
