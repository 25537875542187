import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, withStyles, Switch } from "@material-ui/core";
import {
  grey,
  primaryColor,
  drawerTextColor,
  secondaryBlueText,
} from "../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },

  topMargin: { marginTop: 30, width: "50%" },

  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  }
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


function DisplayPincodeData(props) {
  const classes = useStyles();
  const [pincodeDisplayData, setPincodeDisplayData] = React.useState(
    props?.selectedPincodeData || []
  );
  const [allCitiesList, setAllCities] = React.useState(
    props?.allCitiesList || []
  );
  const [allStatesList, setAllStates] = React.useState(
    props?.allStateList || []
  );
  const [tabValue, setTabValue] = React.useState(
    props?.tabValue
  );
  // const [cityData, setCityData] = React.useState(findCity());
  // const findCity = () => {
  //   let city = allCitiesList.find(
  //     (city) => city.id === pincodeDisplayData.city
  //   );
  //   setCityData(city);
  // };
  React.useEffect(() => {
    setPincodeDisplayData(props?.selectedPincodeData);
    setAllCities(props?.allCitiesList || []);
    // findCity();
  }, [props?.selectedPincodeData, props?.allCitiesList]);
  React.useEffect(() => {
    setPincodeDisplayData(props?.selectedPincodeData);
    setAllStates(props?.allStateList || []);
    setTabValue(props?.tabValue || '0');
    // findCity();
  }, [props?.selectedPincodeData, props?.allStateList, props?.tabValue]);

  const handleCancelButtonClick = () => {
    props.history.push(INTERNALROUTES.PINCODE);
  };
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Pincode Master",
                onClick: () => props.history.push(INTERNALROUTES.PINCODE),
              },
              {
                title: pincodeDisplayData?.pincode || "",
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            {tabValue === '0' ?
              <>
                {console.log(pincodeDisplayData)}
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>PINCODE</Typography>
                  <Typography className={classes.titleLabel}>
                    {pincodeDisplayData.pincode || ""}
                  </Typography>
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>CITY</Typography>
                  <Typography className={classes.titleLabel}>
                    {pincodeDisplayData?.city_detail?.name || ""}
                  </Typography>
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>ACTIVE</Typography>
                  <div className={classes.isActive}>
                    <IOSSwitch
                      disabled={true}
                      checked={(pincodeDisplayData.is_active) === undefined
                        ? true
                        : Boolean(pincodeDisplayData.is_active)
                      }
                    />
                  </div>
                </div>
              </>
              :
              tabValue === '1' ?
                <>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>CITY</Typography>
                    <Typography className={classes.titleLabel}>
                      {pincodeDisplayData.name || ""}
                    </Typography>
                  </div>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>STATE</Typography>
                    <Typography className={classes.titleLabel}>
                      {pincodeDisplayData.state_name || ""}
                    </Typography>
                  </div>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>ACTIVE</Typography>
                    <div className={classes.isActive}>
                      <IOSSwitch
                        disabled={true}
                        checked={(pincodeDisplayData.is_active) === undefined
                          ? true
                          : Boolean(pincodeDisplayData.is_active)
                        }
                      />
                    </div>
                  </div>
                </>
                :
                <>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>STATE</Typography>
                    <Typography className={classes.titleLabel}>
                      {pincodeDisplayData.name || ""}
                    </Typography>
                  </div>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>STATE CODE</Typography>
                    <Typography className={classes.titleLabel}>
                      {pincodeDisplayData.gst_state_code || ""}
                    </Typography>
                  </div>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>ACTIVE</Typography>
                    <div className={classes.isActive}>
                      <IOSSwitch
                        disabled={true}
                        checked={(pincodeDisplayData.is_active) === undefined
                          ? true
                          : Boolean(pincodeDisplayData.is_active)
                        }
                      />
                    </div>
                  </div>
                </>
            }
            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>CITY</Typography>
              <Typography className={classes.titleLabel}>
                {cityData.name || ""}
              </Typography>
            </div> */}
            <ToolTipComponent
              title="You have no edit permission on this widget"
              condition={!hasUpdateAccess(props.user, 'pincode_master')}
            >
              <Button
                size="medium"
                className={classes.primaryBackground}
                variant="contained"
                startIcon={<EditOutlinedIcon />}
                disabled={isEmpty(pincodeDisplayData) || !hasUpdateAccess(props.user, 'pincode_master')}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(
                    `${INTERNALROUTES.PINCODE}/edit/${pincodeDisplayData?.id}`
                  );
                }}
              >
                EDIT
              </Button>
            </ToolTipComponent>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(DisplayPincodeData);
