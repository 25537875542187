import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { isAccessibleModule } from "../../../../methods";
import { Route } from "react-router-dom";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayTcsMatrixData from "../../../../components/internal/masterManagement/tcsMatrix/web";
import ManageTcsMatrixData from "../../../../components/internal/masterManagement/tcsMatrix/ManageTcsMatrixData";

class ManageTcsMatrixContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      tcsOperation: null,
      selectedTcsId: null,
      selectedTcsData: [],
      selectedTcsDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let tcsOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ tcsOperation }, () => console.log(1));
    if (tcsOperation !== "add") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedTcsData(id);
      this.setState({ selectedTcsId: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let tcsOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedTcsId =
      tcsOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (tcsOperation !== state.tcsOperation) {
      updatedData.set("tcsOperation", tcsOperation);
    }
    if (selectedTcsId !== state.selectedTcsId) {
      updatedData.set("selectedTcsId", selectedTcsId);
    }
    if (updatedData.size > 0) {
      return {
        tcsOperation: updatedData.has("tcsOperation")
          ? updatedData.get("tcsOperation")
          : tcsOperation,
        selectedTcsId: updatedData.has("selectedTcsId")
          ? updatedData.get("selectedTcsId")
          : [],
      };
    }
    return null;
  }
  handleFetchSelectedTcsData = async (selectedTcsMatrixId) => {
    await this.setState({ selectedTcsDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.TCSMATRIX}item_natures/${selectedTcsMatrixId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedTcsData: response.data,
              selectedTcsDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedTcsDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected bank data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedTcsDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching selected bank data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleTcsMatrixMasterDelete = (tcsMatrixDataId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`${APIROUTES.TCSMATRIX}${tcsMatrixDataId}/`, this.getOptions(this.props.user))
        .then((response) => {
          this.props.getTcsMatrixList();
          resolve(response);
        })
        .catch(reject);
    });

  getTcsRates = () =>
    new Promise((resolve, reject) => {
      axios
        .get(APIROUTES.TCSRATES, this.getOptions(this.props.user))
        .then((response) => {
          resolve(response);
        })
        .catch(reject);
    })

  handleTcsMatrixMasterDataAdd = (tcsMatrixData) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.TCSMATRIXSINGLE, tcsMatrixData, this.getOptions(this.props.user))
        .then((response) => {
          this.handleFetchSelectedTcsData(tcsMatrixData[0].item_nature);
          this.props.getTcsMatrixList();
          resolve(response);
        })
        .catch(reject);
    });
  handleTcsMatrixMasterDataUpdate = (tcsMatrixData, tcsMatrixDataId, code) =>
    new Promise((resolve, reject) => {
      axios
        .patch(
          `${APIROUTES.TCSMATRIXSINGLE}${tcsMatrixDataId}/`,
          tcsMatrixData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedTcsData(code);
          this.props.getTcsMatrixList();
          resolve(response);
        })
        .catch(reject);
    });
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  render() {
    const { tcsOperation } = this.state;
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    // if (isAccessibleModule(this.props?.user, "Banks"))
    else {
      return (
        <>
          {tcsOperation === "view" ? (
            <DisplayTcsMatrixData {...this.props} {...this.state} getTcsRates={this.getTcsRates} />
          ) : (
            <ManageTcsMatrixData
              {...this.props}
              {...this.state}
              handleTcsMatrixMasterDataUpdate={this.handleTcsMatrixMasterDataUpdate}
              handleTcsMatrixMasterDataAdd={this.handleTcsMatrixMasterDataAdd}
              getTcsRates={this.getTcsRates}
              handleTcsMatrixMasterDelete={this.handleTcsMatrixMasterDelete}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManageTcsMatrixContainer);
