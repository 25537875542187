import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Category from "@material-ui/icons/Category";
import Map from "@material-ui/icons/Map";
import Payment from "@material-ui/icons/Payment";
import Business from "@material-ui/icons/Business";
import AddLocation from "@material-ui/icons/AddLocation";
import Loyalty from "@material-ui/icons/Loyalty";
import Speed from "@material-ui/icons/Speed";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Ballot from "@material-ui/icons/Ballot";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import PersonPinCircleOutlinedIcon from "@material-ui/icons/PersonPinCircleOutlined";
import Layout from "../../../layouts/internalLayout/InternalLayout";
import ListNavigator from "../common/ListNavigator";
import AccessDenied from "../../common/AccessDenied";
import { isAccessibleWidget, pxToEm } from "../../../methods";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import Bread from "../common/Breadcrubs";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FeedIcon from '@mui/icons-material/Feed';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';

const styles = (theme) => ({
  title: {
    fontSize: pxToEm(18),
  },
  main: {
    width: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
});

class MastersManagementWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyRatesNavigations: [],
      masterDataNavigations: [],
    };
  }

  componentDidMount() {
    this.initializeNavigations(this.props);
  }

  initializeNavigations = (props) => {
    if (props.user) {
      const dailyRatesNavigations = [];
      const masterDataNavigations = [];
      // Daily
      if (isAccessibleWidget(props.user, 'item_categories')) {
        dailyRatesNavigations.push({
          title: "Item Categories",
          subtitle:
            "Manage item categories, daily rate templates and item rate differences",
          path: INTERNALROUTES.LISTITEMCATEGORIESMASTER,
          loading: props.itemCategoriesDataFetchLoader,
          icon: () => <Category />,
        });
      }
      if (isAccessibleWidget(props.user, "daily_rate_regions")) {
        dailyRatesNavigations.push({
          title: "Daily Rate Regions",
          subtitle: "Manage regions for opening daily rates",
          path: INTERNALROUTES.LISTSTATEMASTER,
          loading: props.fetchingState,
          icon: () => <Map />,
        });
      }
      // Masters
      if (isAccessibleWidget(props.user, "payment_terms")) {
        masterDataNavigations.push({
          title: "Payment Terms",
          subtitle: "Add & manage different payment terms",
          path: INTERNALROUTES.LIST_PAYMENT_TERMS,
          loading: props.paymentTermsDataFetchLoader,
          icon: () => <Payment />,
        });
      }
      if (isAccessibleWidget(props.user, "plant_item_master")) {
        masterDataNavigations.push({
          title: "Plant Item Master",
          subtitle: "Add & manage different item categories and plants",
          path: INTERNALROUTES.LIST_PLANT_ITEM_MASTER,
          loading: props.plantItemMasterDataFetchLoader,
          icon: () => <Business />,
        });
      }
      if (isAccessibleWidget(props.user, "tags_master")) {
        masterDataNavigations.push({
          title: "Tags Master",
          subtitle: "Add & manage different tags used in item types",
          path: INTERNALROUTES.LIST_TAGS_MASTER,
          loading: props.tagsMasterDataFetchLoader,

          icon: () => <Loyalty />,
        });
      }
      if (isAccessibleWidget(props.user, "bank_master")) {
        masterDataNavigations.push({
          title: "Bank Master",
          subtitle: "Add & manage different bank details",
          path: INTERNALROUTES.BANK_MASTER,
          loading: props.bankMasterDataFetchLoader,

          icon: () => <AccountBalanceOutlinedIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "tds_rate_master")) {
        masterDataNavigations.push({
          title: "TDS Rate Master",
          subtitle: "Add & manage TDS rate details",
          path: INTERNALROUTES.TDS_RATE,
          loading: props.tdsRateDataFetchLoader,

          icon: () => <AssignmentOutlinedIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "transporter_master")) {
        masterDataNavigations.push({
          title: "Transporter Master",
          subtitle: "Add & manage Transporter details",
          path: INTERNALROUTES.TRANSPORTER,
          loading: props.transporterDataFetchLoader,

          icon: () => <LocalShipping />,
        });
      }
      if (isAccessibleWidget(props.user, "tcs_master")) {
        masterDataNavigations.push({
          title: "TCS Master",
          subtitle: "Add & manage TCS details",
          path: INTERNALROUTES.TCS,
          loading: props.tcsListDataFetchLoader,
          icon: () => <SpeakerNotesIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "tcs_matrix")) {
        masterDataNavigations.push({
          title: "TCS Matrix",
          subtitle: "Add & manage TCS matrix",
          path: INTERNALROUTES.TCSMATRIX,
          loading: props.tcsListDataFetchLoader,
          icon: () => <SpeakerNotesIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "unit_master")) {
        masterDataNavigations.push({
          title: "Unit Master",
          subtitle: "Add & manage Unit details",
          path: INTERNALROUTES.UNIT,
          loading: props.unitListDataFetchLoader,
          icon: () => <LocalOfferOutlinedIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "pincode_master")) {
        masterDataNavigations.push({
          title: "Pincode Master",
          subtitle: "Add & manage Pincode details",
          path: INTERNALROUTES.PINCODE,
          loading: props.pincodeListDataFetchLoader,
          icon: () => <PersonPinCircleOutlinedIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "item_nature_master")) {
        masterDataNavigations.push({
          title: "Item Nature Master",
          subtitle: "Add & manage item nature details",
          path: INTERNALROUTES.ITEM_NATURE,
          loading: props.itemNatureListDataFetchLoader,
          icon: () => <PersonPinCircleOutlinedIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "reason_master")) {
        masterDataNavigations.push({
          title: "Reason Master",
          subtitle: "Add & manage reason details",
          path: INTERNALROUTES.REASON,
          loading: props.reasonListDataFetchLoader,
          icon: () => <AppRegistrationIcon />,
        });
      }
      if (isAccessibleWidget(props.user, "customer_types_master")) {
        masterDataNavigations.push({
          title: "Customer Types Master",
          subtitle: "Add & manage customer types details",
          path: INTERNALROUTES.CUSTOMER_TYPE,
          loading: props.customerTypeListDataFetchLoader,
          icon: () => <RecentActorsIcon />,
        });
      }
      // if (isAccessibleWidget(props.user, "app_banner_master"))
      if (true) {
        masterDataNavigations.push({
          title: "App Banner Master",
          subtitle: "Add & manage News and Updates",
          path: INTERNALROUTES.APP_BANNER,
          loading: props.appBannerListDataFetchLoader,
          icon: () => <FeedIcon />
        });
      };
      if (true) {
        masterDataNavigations.push({
          title: "App Version Master",
          subtitle: "Add & Manage App Versions",
          path: INTERNALROUTES.APP_VERSION,
          loading: props.appVersionListDataFetchLoader,
          icon: () => <SmartphoneIcon />
        });
      };
      if (true) {
        masterDataNavigations.push({
          title: "Item Size Master",
          subtitle: "Add & Manage Item Size",
          path: INTERNALROUTES.ITEM_SIZE,
          loading: props.appVersionListDataFetchLoader,
          icon: () => <StraightenOutlinedIcon />
        });
      };
      // masterDataNavigations.push({
      //   title: "State & City Master",
      //   subtitle: "Add & manage different city and state",
      //   // path: INTERNALROUTES.LISTSTATEMASTER,
      //   icon: () => <AddLocation />,
      // });
      // masterDataNavigations.push({
      //   title: "Transporters & Vehicles",
      //   subtitle: "Add & manage transporters and vehicles",
      //   // path: INTERNALROUTES.LISTSTATEMASTER,
      //   icon: () => <LocalShipping />,
      // });
      // masterDataNavigations.push({
      //   title: "Tax Master",
      //   subtitle: "Add & manage different types of taxes",
      //   // path: INTERNALROUTES.LISTSTATEMASTER,
      //   icon: () => <Ballot />,
      // });
      this.setState({ dailyRatesNavigations, masterDataNavigations });
    }
  };

  render() {
    const { classes } = this.props;
    const { dailyRatesNavigations, masterDataNavigations } = this.state;
    return (
      <Layout {...this.props}>
        <div style={{
          width: "100%",
          paddingBottom: "30px",
          overflowY: "scroll",
        }}>
          <div style={{ width: "100%" }}>
            {/* <Typography className={classes.title}>
                Master Management
              </Typography> */}
            <Bread
              data={[
                {
                  title: 'Master Management',
                },
              ]}
            />
            <>
              <Typography className="listHeader">
                Daily Rates Configurations
              </Typography>
              <ListNavigator
                {...this.props}
                navigations={dailyRatesNavigations}
              />
              <Typography className="listHeader">
                Master Data Configurations
              </Typography>
              <ListNavigator
                {...this.props}
                navigations={masterDataNavigations}
              />
            </>
          </div>
        </div>
      </Layout>
    );
  }
}
export default withStyles(styles)(MastersManagementWeb);
