import React, { Component } from "react";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import B2bProfile from "../../components/b2b/mobile/B2bProfile";
import B2bProfileWeb from "../../components/b2b/web/B2bProfileWeb";
import B2bProfileSettings from "../../components/b2b/mobile/B2bProfileSettings";
import B2bProfileSettingsWeb from "../../components/b2b/web/B2bProfileSettingsWeb";
import B2bEditProfile from "../../components/b2b/mobile/B2bEditProfile";
import B2bEditProfileWeb from "../../components/b2b/web/B2bEditProfileWeb";
import B2bProfileChangePassword from "../../components/b2b/mobile/B2bProfileChangePassword";
import B2bProfileChangePasswordWeb from "../../components/b2b/web/B2bProfileChangePasswordWeb";
import LedgerMobile, {
  LedgerWeb
} from "../../components/b2b/ledger";
import withUser from "../../hoc/withUser";
import B2b_URL from "../../constants/b2b/B2bRoutes";
import axios from "axios";
import moment from "moment";
import { checkApiError, getOptions } from "../../methods";
import SnackbarComponent from "../../components/common/SnackbarComponent";
import { handleCustomerRequestsTable } from "../../components/internal/customerRequets/web/ledgerPdf";

class B2bProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ledgerRequestStatus: null,
      ledgerRequestData: {},
      ledgerType: null,
      fromDate: moment().subtract(1, 'months').startOf('month'),
      toDate: moment().subtract(1, 'months').endOf('month'),
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
      isLoading: false
    };
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleClearState = () => {
    this.setState({
      ledgerRequestStatus: null,
      ledgerRequestData: {},
      ledgerType: null,
      fromDate: moment().subtract(1, 'months').startOf('month'),
      toDate: moment().subtract(1, 'months').endOf('month'),
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    })
  }

  changePassword = (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
      // console.log(this.props.user)
      axios
        .post(B2b_URL.CHANGE_PASSWORD, {
          oldPassword: oldPassword,
          newPassword: newPassword,
          credential: this.props.user.employee_id,
        })
        .then(resolve)
        .catch(error => {
          if (error['response']['status'] === 400) {
            resolve(error)
          }
          resolve(error)
        });
    });

  handleDateChange = (name, date) => {
    console.log(name, date)
    if (date !== "Invalid Date") {
      this.setState({
        [name]: moment(date).format("YYYY-MM-DD"),
      })
    } else {
      console.log("invalid date");
    }
  }


  handleLedgerType = (data) => {
    this.setState({ ledgerType: data })
  }

  fetchLedgerRequest = () => {
    axios
      .get(B2b_URL.GET_LEDGER_REQUEST, getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            ledgerRequestData: response.data
          }, () => {
            if (response.data?.acc_code_all && response.data?.acc_code_all?.length === 1) {
              this.setState({ ledgerType: response.data?.acc_code_all[0]?.account_type })
            }
          })
        }
      })
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  }


  handleSubmitButton = () => {
    let data = {
      request_type: this.state?.ledgerType,
      from_date: moment(this.state?.fromDate).format("YYYY-MM-DD"),
      to_date: moment(this.state?.toDate).format("YYYY-MM-DD"),
    }
    console.log(data)
    axios
      .post(B2b_URL.GET_LEDGER_REQUEST, data, getOptions(this.props.user))
      .then((response) => {
        if (response.status === 201) {
          this.handleOpenSnackbar('Ledger Requested Successfully', "success")
          setTimeout(() => {
            this.props.history.push('/b2bProfile')
            this.handleCloseSnackbar();
          }, 3000)
        }
      })
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  }

  handleResetLedgerRequest = () => {
    axios
      .delete(B2b_URL.RESET_LEDGER_REQUEST, getOptions(this.props.user))
      .then((response) => {
        if (response.status === 204) {
          this.handleOpenSnackbar('Ledger Requested Reset Successfully', "success")
          setTimeout(() => {
            this.props.history.push('/b2bProfile')
            this.handleCloseSnackbar();
          }, 3000)
        }
      })
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  }

  snackbarPDFDownload = () => {
    this.handleOpenSnackbar('Downloading PDF...', 'success')
  }

  handleDownloadOpeningPDFLoader = (data = '') => {
    this.setState({ isLoading: data === "" ? false : data })
  }

  handleErrorSnackbar = (response) => {
    if (response.status === 500) {
      this.handleOpenSnackbar('server error', 'error')
      setTimeout(() => {
        this.handleCloseSnackbar();
      }, 3000)
    }
    else {
      this.handleOpenSnackbar(
        this.handleSnackbarMessageBox(checkApiError(response?.data)),
        "error"
      );
      setTimeout(() => {
        this.handleCloseSnackbar();
      }, 4000)
    }
  }

  handleDownloadPDFButton = (id) => {
    this.handleDownloadOpeningPDFLoader(true)
    setTimeout(() => {
      !window.cordova
        ? handleCustomerRequestsTable(id, "PRINT", this.snackbarPDFDownload, this.handleDownloadOpeningPDFLoader, 'mobile', this.handleErrorSnackbar)
        : handleCustomerRequestsTable(id, "CORDOVA", this.snackbarPDFDownload, this.handleDownloadOpeningPDFLoader, 'mobile', this.handleErrorSnackbar)
    }, 500)
  }


  render() {
    const { isMobile, user } = this.props;
    const {
      ledgerRequestData,
      ledgerRequestStatus,
      ledgerType,
      fromDate,
      toDate,
      openSnackbar,
      snackbarMessage,
      snackbarType,
      isLoading,
    } = this.state;
    console.log(this.props)
    return (
      <div>
        <Route
          exact
          path="/b2bProfile"
          render={(props) =>
            isMobile ? (
              <B2bProfile
                {...props}
                user={this.props.user}
                fetchLedgerRequest={this.fetchLedgerRequest}
              />
            ) : (
              <B2bProfileWeb
                {...props}
                user={this.props.user}
                fetchLedgerRequest={this.fetchLedgerRequest}
              />
            )
          }
        />
        <Route
          exact
          path="/b2bProfile/settings"
          render={(props) =>
            isMobile ? (
              <B2bProfileSettings {...props} />
            ) : (
              <B2bProfileSettingsWeb {...props} />
            )
          }
        />
        <Route
          exact
          path="/b2bProfile/settings/editProfile"
          render={(props) =>
            isMobile ? (
              <B2bEditProfile {...props} />
            ) : (
              <B2bEditProfileWeb {...props} />
            )
          }
        />
        <Route
          exact
          path="/b2bProfile/settings/changePassword"
          render={(props) =>
            isMobile ? (
              <B2bProfileChangePassword
                {...props}
                changePassword={this.changePassword}
              />
            ) : (
              <B2bProfileChangePasswordWeb
                {...props}
                changePassword={this.changePassword}
              />
            )
          }
        />
        <Route
          exact
          path="/b2bProfile/ledger"
          render={(props) =>
            isMobile ? (
              <LedgerMobile
                {...props}
                isMobile={isMobile}
                ledgerRequestData={ledgerRequestData}
                ledgerType={ledgerType}
                ledgerRequestStatus={ledgerRequestStatus}
                fromDate={fromDate}
                toDate={toDate}
                isLoading={isLoading}
                user={user}
                handleDateChange={this.handleDateChange}
                handleLedgerType={this.handleLedgerType}
                handleSubmitButton={this.handleSubmitButton}
                handleResetLedgerRequest={this.handleResetLedgerRequest}
                handleClearState={this.handleClearState}
                handleDownloadPDFButton={this.handleDownloadPDFButton}
              />
            ) : (
              <LedgerWeb
                {...props}
                isMobile={isMobile}
                ledgerRequestData={ledgerRequestData}
                ledgerType={ledgerType}
                ledgerRequestStatus={ledgerRequestStatus}
                fromDate={fromDate}
                toDate={toDate}
                isLoading={isLoading}
                user={user}
                handleDateChange={this.handleDateChange}
                handleLedgerType={this.handleLedgerType}
                handleSubmitButton={this.handleSubmitButton}
                handleResetLedgerRequest={this.handleResetLedgerRequest}
                handleClearState={this.handleClearState}
                handleDownloadPDFButton={this.handleDownloadPDFButton}
              />
            )
          }
        />
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </div>
    );
  }
}
export default withUser(withRouter(B2bProfileContainer));
