import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import Close from "@material-ui/icons/Close";
import Create from "../Create";

class createOrder extends Component {
  handleBack = () => {
    // console.log(this.props.paymentMethods)
    this.props.handleCloseOrderForm()
    // this.props.history.push("/home");
  };
  render() {
    // console.log(this.props)
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<Close items />}
        appBarTitle="New Contract"
      >
        <Create isMobile {...this.props} />
      </MobileLayout>
    );
  }
}
export default createOrder;
