import React from 'react';
import { Breadcrumbs, Typography, withStyles } from '@material-ui/core';
import COLORS from '../../../constants/internal/colors';

import { pxToEm } from '../../../methods';

const styles = (theme) => ({
  title: {
    fontSize: pxToEm(18),
  },
  blue: {
    color: COLORS.secondaryBlueText,
    cursor: 'pointer',
  },
});

const Bread = ({ data, classes }) => {
  if (!data) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb" separator=">">
        {data.length &&
          data.map((d, i) => {
            if (data.length - 1 === i) {
              return (
                <Typography key={`finaltitle`} className={classes.title} style={d.style}>
                  {d.title}
                </Typography>
              );
            } else {
              return (
                <Typography
                  key={`Brad${i}`}
                  className={`${classes.blue} ${classes.title}`}
                  onClick={d.onClick}
                  style={d.style}
                >
                  {d.title}
                </Typography>
              );
            }
          })}
      </Breadcrumbs>
    </div>
  );
};

export default withStyles(styles)(Bread);
