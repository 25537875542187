import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManageTagsMaster from "../../../../components/internal/masterManagement/tagsMaster/web";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { isAccessibleModule } from "../../../../methods";
class TagsContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tagsMasterList: [],
      tagsMasterDataFetchLoader: false,
      tagOperation: null,
      selectedTagId: null,
      selectedTagData: [],
      selectedTagDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;

    if (isAccessibleModule(this.props?.user, "Masters")) {
      this.getTagsMasterList();
    }
    let tagOperation = this.props.location.pathname.includes("view")
      ? "view"
      : "add";
    this.setState({ tagOperation });
    if (tagOperation === "view") {
      const id =
        this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
        ];
      this.handleFetchSelectedTagData(id);
      this.setState({ selectedTagId: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let propsTagOperation = props.location.pathname.includes("view")
      ? "view"
      : "add";

    if (propsTagOperation !== state.tagOperation) {
      return { tagOperation: propsTagOperation };
    }
    if (propsTagOperation === "view") {
      const propsSelectedTagId =
        props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ];
      if (propsSelectedTagId !== state.selectedTagId) {
        this.handleFetchSelectedTagData(propsSelectedTagId);
        return { selectedTagId: propsSelectedTagId };
      }
    }
    return null;
  }

  getTagsMasterList = () => {
    this.setState({ tagsMasterDataFetchLoader: true });
    axios
      .get(
        APIROUTES.GET_ALL_TAGS_MASTERS_LIST,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          var responseData = response.data;
          this.setState({
            tagsMasterList: responseData,
            tagsMasterDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ tagsMasterDataFetchLoader: false });
        }
      );
  };
  handleFetchSelectedTagData = async (selectedTagId) => {
    await this.setState({ selectedTagDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}${selectedTagId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedTagData: response.data,
              selectedTagDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedTagDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected tag data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedTagDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  render() {
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    else if (isAccessibleModule(this.props?.user, "Masters")) {
      return (
        <>
          <ManageTagsMaster {...this.state} {...this.props} />
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    } else return <AccessDenied />;
  }
}
export default withUser(TagsContainer);
