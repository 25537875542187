import React, { useEffect } from 'react'
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Paper,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import AddMoreDottedButtonComponent from "./AddMoreDottedButtonComponent";

const useStyles = makeStyles({
  numbersContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    // justifyContent: "space-around",
    padding: "10px 0px 10px 0px",
    // flex: "50%"
  },
  extraNumber: {
    marginRight: 30,
    marginBottom: "20px"
  },
  eachItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "220px",

  }
})

function ExtraPhoneNumbersDisplay({ classes, ...props }) {

  const classess = useStyles()

  useEffect(() => {

  }, [])
  useEffect(() => {

  }, [])

  // console.log(props.extraPhones)
  // console.log(props.extraPhones.length)
  return (
    <div>
      {/* <Typography className={classes.titleLabelStyle}>
        EXTRA PHONE NUMBERS
      </Typography> */}
      <div>
        {
          (props?.extraPhones?.length > 0) ? (
            <div className={classess.numbersContainer} >
              {
                props.extraPhones.map((phone, i) =>
                  <div className={classess.extraNumber}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography className={classes.titleLabelStyle}>
                        EXTRA PHONE NUMBER {i + 1}
                      </Typography>

                      <div>
                        <Tooltip
                          title={`Delete Extra Phone Number ${i + 1}
                          
                            `}
                          placement="left"
                          arrow
                        >
                          <DeleteOutlineOutlinedIcon
                            style={{ cursor: "pointer", height: "18px", width: "18px" }}
                            onClick={() =>
                              props.extraPhoneDelete(i)
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className={classess.eachItem}>
                      <Typography className={classes.titleLabel}>
                        COUNTRY CODE
                      </Typography>
                      <Typography style={{ float: "right" }}>
                        +{phone.country_code[0] === '+' ?
                          phone.country_code.slice(1)
                          : phone.country_code
                        }
                      </Typography>
                    </div>
                    <div className={classess.eachItem}>
                      <Typography className={classes.titleLabel}>
                        PHONE NUMBER
                      </Typography>

                      <Typography style={{ float: "right" }}>
                        {phone.phone_number}
                      </Typography>
                    </div>

                    <div className={classess.eachItem}>
                      <Typography className={classes.titleLabel}>
                        STD CODE
                      </Typography>
                      <Typography style={{ float: "right" }}>
                        {phone.std_code}
                      </Typography>
                    </div>


                  </div>
                )
              }

            </div>
          ) : ""
        }
      </div>


    </div>
  )
}



export default ExtraPhoneNumbersDisplay