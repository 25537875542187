import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {
  orange,
  drawerTextColor,
  primaryColor,
} from '../../../../constants/internal/colors';

import moment from 'moment';
import { pxToEm } from '../../../../methods';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  inline: {
    display: 'inline',
    fontSize: pxToEm(12),
  },
  dividerWidth: {
    margin: '0px 15px 0px 15px',
  },

  fontStyle: {
    fontSize: pxToEm(18),
  },
  listFontStyle: {
    fontSize: pxToEm(15),
  },
  priceDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 2px',
    backgroundColor: orange,
    color: drawerTextColor,
    fontWeight: 'bold',
  },
  loaderDiv: {
    margin: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderStyle: { color: primaryColor },
}));
function getPublishCount(liveRateValues) {
  if (liveRateValues && liveRateValues.length > 0) {
    let publishedliveRateValues = liveRateValues
      ? liveRateValues.filter(
        (category) => category?.daily_rates[0]?.status === 'ACTIVE'
      )
      : [];
    return `${publishedliveRateValues.length} / ${liveRateValues.length}`;
  } else {
    return null;
  }
}
export default function LiveRates(props) {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {props.liveRatesLoader ? (
        <div className={classes.loaderDiv}>
          <CircularProgress className={classes.loaderStyle} />
        </div>
      ) : props.liveRateValues && props.liveRateValues.length === 0 ? (
        <div className={classes.loaderDiv}>
          <Typography className={classes.loaderStyle}>
            Category data not found try updating master
          </Typography>
        </div>
      ) : (
        <>
          <ListItem alignItems="flex-start" key={1111111}>
            <ListItemText
              className={classes.fontStyle}
              primary="Live Rates"
              secondary={
                <React.Fragment>
                  <Typography component="span" className={classes.inline}>
                    {getPublishCount(props.liveRateValues)}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          {props.liveRateValues &&
            props.liveRateValues.map((liveRate, index) => (
              <>
                <ListItem alignItems="flex-start" key={liveRate}>
                  <ListItemText
                    className={classes.listFontStyle}
                    primary={liveRate.category_name}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" className={classes.inline}>
                          {liveRate.daily_rates.length > 0 ? (
                            <div className={classes.priceDisplay}>
                              <div>₹{parseFloat(liveRate?.daily_rates[0]?.high_rate).toFixed(2)}</div>
                              <div>
                                {moment(
                                  liveRate?.daily_rates[0]?.created_at
                                ).format('LT')}
                              </div>
                            </div>
                          ) : (
                            'Pending'
                          )}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {index < props.liveRateValues.length - 1 && (
                  <Divider className={classes.dividerWidth} />
                )}
              </>
            ))}
        </>
      )}
    </List>
  );
}
