import React from "react";
export default class OrderBookingSVG extends React.Component {
  render() {
    return (
      <svg
        style={{ fill: "white", height: "20px", width: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="19.319"
        height="18.149"
        viewBox="0 0 19.319 18.149"
      >
        <path
          class="a"
          d="M15.346,15.5H3.973a3.973,3.973,0,1,0,0,7.946H6.866v3.21l-.141-.118a1.839,1.839,0,0,0-2.435.106,1.588,1.588,0,0,0,.1,2.365l2.473,2.075v2a.566.566,0,0,0,.566.566h6.792a.566.566,0,0,0,.566-.566v-6.36A1.7,1.7,0,0,0,13.3,25.038l-3.04-.379V23.446h5.085a3.973,3.973,0,1,0,0-7.946ZM8,32.517V31.385h5.66v1.132Zm5.164-6.356a.567.567,0,0,1,.5.562v3.53H7.638L5.12,28.141a.458.458,0,0,1-.03-.7A.7.7,0,0,1,6,27.4l1.071.9A.566.566,0,0,0,8,27.869V20.632a.566.566,0,0,1,1.132,0V25.16a.566.566,0,0,0,.5.562Zm2.185-3.848H10.261V20.632a1.7,1.7,0,0,0-3.4,0v1.682H3.973a2.841,2.841,0,1,1,0-5.682H15.346a2.841,2.841,0,0,1,0,5.682Z"
          transform="translate(0 -15.5)"
        />
        <path
          class="a"
          d="M304.461,75.666,302.6,77.529l-.732-.732a.566.566,0,0,0-.8.8L302.2,78.73a.566.566,0,0,0,.8,0l2.264-2.264a.566.566,0,0,0-.8-.8Z"
          transform="translate(-289.546 -73.236)"
        />
      </svg>
    );
  }
}
