import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Box,
    TablePagination,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Button,
    TextField,
    InputAdornment,
    Chip,
    FormControl,
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
    Switch,
    InputLabel,
    FormHelperText,
    ThemeProvider,
    createMuiTheme
} from "@material-ui/core";
import {
    grey,
    secondaryGrey,
    successButtonBackground,
    drawerTextColor,
    secondaryBlueText,
    whiteSmoke,
    orange,
    earthBlue,
    drawerBackground,
} from "../../../../constants/internal/colors";
import { pxToEm, UpperCaseText } from "../../../../methods";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Form, Formik, useFormik } from "formik";
import { validationsItemTypesDetails } from "./ItemCategoryValidationSchema";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import COLORS from "../../../../constants/internal/colors";
import Divider from "@material-ui/core/Divider";
import ItemDialog from "./ItemDialog";


const CustomCheckbox = withStyles({
    root: {
        color: secondaryBlueText,
        "&$checked": {
            color: secondaryBlueText,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: secondaryBlueText,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {},
        },
        MuiPickersDay: {
            day: {
                color: earthBlue,
            },
            daySelected: {
                backgroundColor: secondaryBlueText,
            },
            dayDisabled: {
                color: secondaryGrey,
            },
            current: {
                color: drawerBackground,
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: earthBlue,
            },
        },
    },
});

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        // margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: secondaryBlueText,
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "secondaryBlueText",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            checked={props.defaultChecked}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    deleteAndExpandActionStyle: { display: "flex" },
    sectionNameAndWeightStyles: {
        display: "flex",
        alignItems: "center",
        placeContent: "flex-start",
        justifyContent: "space-evenly",
    },
    inputWidth: {
        // maxWidth: 200,
        width: "10rem"
    },
    unitSelect: {
        width: "10rem",
        position: "relative"
    },
    helperText: {
        position: "absolute"
    },
    tableCellWidth: {
        minWidth: "10rem"
    },
    contentTitle: {
        color: grey,
        fontSize: pxToEm(16),
        fontWeight: "bold",
    },
    itemTypeMainDiv: {
        marginTop: 30,
    },
    tableContainer: {
        marginTop: 10,
        width: "100%",
        height: "calc(100vh - 400px)",
        overflow: "auto",
    },
    table: {
        width: "100%",
    },
    tableHead: {
        width: "10rem",
        color: grey,
        fontSize: pxToEm(13),
        fontWeight: "bold",
    },
    actionsDiv: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "row",
    },
    addAndSaveButton: {
        display: "flex",
        alignItems: "center",
        margin: "15px 0px 0px 0px",
        justifyContent: "space-between",
    },
    addNewButton: {
        width: "181px",
        height: "43px",
        border: `1px dashed ${secondaryGrey}`,
        borderRadius: "4px",
        opacity: "1",
        color: grey,
        fontSize: pxToEm(13),
        fontWeight: "bold",
    },
    saveCancelButton: {
        display: "flex",
        placeSelf: "strech",
    },
    saveButton: {
        backgroundColor: successButtonBackground,
        color: drawerTextColor,
        marginRight: 10,
    },
    iconContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        color: grey,
    },
    editTextIcon: {
        borderRadius: 100,
        padding: 2,
        marginLeft: "10px",
        width: "70px",
        display: "flex",
        backgroundColor: whiteSmoke,
        color: grey,
        cursor: "pointer",
    },
    editText: {
        flex: 1,
        marginLeft: 5,
        textAlign: "left",
    },
    icon: {
        height: 18,
        width: 18,
        margin: "0px 5px 0px 5px",
        color: grey,
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            backgroundColor: 'rgba(255,255,255,1)',
            color: '#000',
            marginBottom: 10,
            // borderRadius: 20,
        }
    },
    dialogText: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: pxToEm(14),
        fontWeight: '500',
        alignItems: "center"
    },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
};
const styles = {
    chips: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",
    },
    chip: {
        margin: 2,
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: "80%"
    },
};


export default function Row(props) {
    const {
        parentRow,
        itemTypeTableOperation,
        identifier,
        index,
        allTagsData,
        allUnits,
        allItemSize,
        allItemNature,
        getItemTypes,
        rows,
        setRows,
        newItemUnderCategoryData,
        handleChange,
        handleUpdateItemData,
        handleClickOpenItemTypes,
        setOpenItemTypes,
        handleClickEditItemTypes,
        openItemTypes,
        scroll,
        setScroll,
    } = props;
    const [row, setRow] = React.useState(parentRow);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [packingError, setPackingError] = React.useState(false);
    const [packingErrMsg, setPackingErrMsg] = React.useState("");
    const [tags, selectedTags] = React.useState([]);

    const classes = useStyles();

    React.useEffect(() => {
        const receivedRow = parentRow;
        receivedRow["operation"] = "view";
        const filteredTags = allTagsData?.filter((f) => receivedRow.tags?.some((a) => a.tag_name === f.tag_name))
        setRow(receivedRow);
        selectedTags(filteredTags)
    }, [parentRow])

    const handleCloseItemTypes = () => {
        setOpen(false);
    }

    const Mousehover = (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handlePopoverOpen = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = (e) => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        return (
            <div id={props.id}>
                <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: anchorEl === null ? "400" : "500",
                        color: anchorEl === null ? "#666" : "#3B69B0",
                        cursor: 'pointer',
                        marginTop: "3px",
                        marginLeft: "5px"
                    }}
                >
                    <InfoOutlinedIcon fontSize="small" />
                </Typography>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    className={classes.popoverBox}
                >
                    <Typography sx={{ p: 1 }}>
                        {props.content}
                    </Typography>
                </Popover>
            </div>
        );
    }

    const ClickedRateDiffBox = (props) => {
        let data = props.data;
        return (
            <div style={{ width: 350, padding: "5%" }}>
                <Typography className={classes.dialogText}>
                    <div style={{ color: COLORS.secondaryBlueText }}>
                        Rate difference
                    </div>
                    <div style={{ color: COLORS.secondaryBlueText }}>
                        Valid from
                    </div>
                    <div style={{ color: COLORS.secondaryBlueText }}>
                        Valid upto
                    </div>
                </Typography>
                <Divider variant='middle' style={{ margin: '2% 0' }} />
                {data?.map((rateDiffData, rdindex) => {
                    return (
                        <div key={rdindex}>
                            <Typography className={classes.dialogText}>
                                <div>
                                    {rateDiffData?.value}
                                </div>
                                <div style={{
                                    marginLeft: !rateDiffData?.valid_upto
                                        ? null : "70px",
                                }}>
                                    {!rateDiffData?.valid_from
                                        ? null
                                        : moment(rateDiffData?.valid_from).format("DD-MM-YYYY")}
                                </div>
                                <div>
                                    {!rateDiffData?.valid_upto
                                        ? null
                                        : moment(rateDiffData?.valid_upto).format("DD-MM-YYYY")}
                                </div>
                            </Typography>
                            <Divider
                                variant='middle'
                                style={{
                                    margin: '2% 0',
                                    display: rdindex === (data?.length - 1) ? 'none' : 'block'
                                }}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }

    const handleSectionError = (sectionWeight) => {
        setError(false);
        setErrorMsg("");
        if (props.itemConsiderPacking) {
            if (sectionWeight <= 0) {
                setError(true);
                setErrorMsg("Invalid input")
            }
        }
    }

    const handlePackingWeightError = (packingWeight) => {
        setPackingError(false);
        setPackingErrMsg("");
        if (props.itemConsiderPacking) {
            if (packingWeight <= 0) {
                setPackingError(true);
                setPackingErrMsg("Invalid input")
            }
        }
    }

    // const handleSetItemTypeOperation = (operation, index) => {
    //     if (operation === "add") {
    //         setNewItemUnderCategoryData({
    //             item_code: "",
    //             item_name: "",
    //             rate_difference: "",
    //             discount_amount: "",
    //             item_category: props?.selectedItemId,
    //             unit: "",
    //             items_nature: "",
    //             packing_weight: "",
    //             tolerance_upto_1mts: "",
    //             tolerance_from_1to5mts: "",
    //             tolerance_above_5mts: "",
    //             tags: [],
    //             sections: [
    //                 {
    //                     section_name: "",
    //                     section_weight: "",
    //                     item: "",
    //                 },
    //             ],
    //             operation: "add",
    //             // nature_name: "",
    //             is_service: "",
    //         });
    //     } else if (operation === "edit") {
    //         const updatedRowsValues = [...rows];
    //         const updatedRowValue = updatedRowsValues.find((u) => u.id === index);
    //         updatedRowValue["operation"] = "edit";
    //         setRows(updatedRowsValues);
    //         setOpen(true);
    //     } else {
    //         // setItemTypeTableOperation(operation);
    //         const updatedRowsValues = [...rows];
    //         const updatedRowValue = updatedRowsValues.find((u) => u.id === index);
    //         if (updatedRowValue === undefined || updatedRowValue === null) {
    //         } else {
    //             updatedRowValue["operation"] = "view";
    //             setRows(updatedRowsValues);
    //         }
    //     }
    // };

    return (
        <React.Fragment>
            <TableRow className={classes.root}
                onClick={(e) => {
                    console.log(e)
                    // handleSetItemTypeOperation("edit", row?.id);
                    setOpen(true)
                }
                }
            >
                <Formik
                    initialValues={{
                        item_name: row?.item_name || "",
                        item_size: row?.item_size || "",
                        item_code: row?.item_code || "",
                        hsn_code: row?.hsn_code || "",
                        rate_difference: row?.rate_difference || "",
                        discount_amount: row?.discount_amount || "",
                        unit: row?.unit || "",
                        items_nature: row?.items_nature || "",
                        tags: row?.tags || "",
                        section_name: row?.sections[0]?.section_name || "",
                        section_weight: row?.sections[0]?.section_weight || "",
                        packing_weight: row?.packing_weight || "",
                        tolerance_upto_1mts: row?.tolerance_upto_1mts || "",
                        tolerance_from_1to5mts: row?.tolerance_from_1to5mts || "",
                        tolerance_above_5mts: row?.tolerance_above_5mts || "",
                        valid_from: row?.valid_from || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={validationsItemTypesDetails}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={() => {
                        props.handleUpdateItemData(row, index)
                        props.handleSetItemTypeOperation("view", index)
                    }}
                >
                    {(formik) => (
                        <>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <form>
                                    {row?.operation === "add" ? (
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            className={classes.inputWidth}
                                            value={row.item_name}
                                            label="ITEM NAME"
                                            // onChange={
                                            //   (e) => {
                                            //     props.handleChange(index, "item_name", identifier)(e)
                                            //     formik.setFieldValue(
                                            //       "item_name",
                                            //       e.target.value
                                            //     )
                                            //   }
                                            // }
                                            helperText={
                                                (formik.errors.item_name && formik.touched.item_name) ? String(formik.errors.item_name) : " "
                                            }
                                            error={Boolean(
                                                formik.errors.item_name &&
                                                formik.touched.item_name
                                            )}
                                            size="small"
                                        />
                                    ) : (
                                        row.item_name
                                    )
                                    }
                                </form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <form>
                                    {row?.operation === "add" ? (
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            className={classes.inputWidth}
                                            value={row.item_code}
                                            label="ITEM CODE"
                                            onChange={
                                                (e) => {
                                                    props.handleChange(index, "item_code", identifier)(e)
                                                    formik.setFieldValue(
                                                        "item_code",
                                                        e.target.value
                                                    )
                                                }
                                            }
                                            size="small"
                                            helperText={
                                                (formik.errors.item_code && formik.touched.item_code) ? String(formik.errors.item_code) : " "
                                            }
                                            error={Boolean(
                                                formik.errors.item_code &&
                                                formik.touched.item_code
                                            )}
                                        />
                                    ) : (
                                        row.item_code
                                    )}
                                </form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {
                                        row?.operation === "add" ? (
                                            <FormControl size="small" variant="outlined" error={Boolean(
                                                formik.errors.item_size &&
                                                formik.touched.item_size
                                            )}>
                                                <InputLabel
                                                    htmlFor="outlined-item_size"
                                                >
                                                    ITEM SIZE
                                                </InputLabel>
                                                <Select
                                                    id="outlined-item_size"
                                                    disabled={
                                                        row?.operation === "edit" || row?.operation === "add"
                                                            ? false
                                                            : true
                                                    }
                                                    variant="outlined"
                                                    label="ITEM SIZE"
                                                    className={classes.unitSelect}
                                                    MenuProps={MenuProps}
                                                    value={row?.item_size || ""}
                                                    onChange={
                                                        (e) => {
                                                            props.handleChange(index, "item_size", identifier)(e)
                                                            formik.setFieldValue(
                                                                "item_size",
                                                                e.target.value
                                                            )
                                                        }}
                                                >
                                                    {allItemSize.length > 0
                                                        ? allItemSize.map((data) => (
                                                            <MenuItem value={data.id}>{data.size}</MenuItem>
                                                        ))
                                                        : null}
                                                </Select>
                                                <FormHelperText>{(formik.errors.item_size && formik.touched.item_size) ? String(formik.errors.item_size) : " "}</FormHelperText>
                                            </FormControl>
                                        ) : (
                                            allItemSize.map((e) => {
                                                if (e.id == row?.item_size) return e.size
                                            })
                                        )}
                                </Form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <form>
                                    {row?.operation === "add" ? (
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            className={classes.inputWidth}
                                            value={row.hsn_code}
                                            label="HSN CODE"
                                            onChange={
                                                (e) => {
                                                    props.handleChange(index, "hsn_code", identifier)(e)
                                                    formik.setFieldValue(
                                                        "hsn_code",
                                                        e.target.value
                                                    )
                                                }
                                            }
                                            helperText={
                                                (formik.errors.hsn_code && formik.touched.hsn_code) ? String(formik.errors.hsn_code) : " "
                                            }
                                            error={Boolean(
                                                formik.errors.hsn_code &&
                                                formik.touched.hsn_code
                                            )}
                                            size="small"
                                        />
                                    ) : (
                                        row.hsn_code
                                    )
                                    }
                                </form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {row?.operation === "add" ? (
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            className={classes.inputWidth}
                                            value={row.rate_difference}
                                            label="RATE DIFFERENCE"
                                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={
                                                (e) => {
                                                    props.handleChange(index, "rate_difference", identifier)(e)
                                                    formik.setFieldValue(
                                                        "rate_difference",
                                                        e.target.value
                                                    )
                                                }
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">₹</InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            helperText={
                                                (formik.errors.rate_difference && formik.touched.rate_difference) ? String(formik.errors.rate_difference) : " "
                                            }
                                            error={Boolean(
                                                formik.errors.rate_difference &&
                                                formik.touched.rate_difference
                                            )}
                                        />
                                    ) : (
                                        <div id="rate_difference" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            ₹  {Number(row?.rate_difference)}
                                            <Mousehover
                                                content={<ClickedRateDiffBox
                                                    data={row?.rate_difference_logs}
                                                    id="rate_difference_hover"
                                                />}
                                            />
                                        </div>
                                    )}
                                </Form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {row?.operation === "add" ? (
                                        <ThemeProvider theme={materialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    className={classes.inputWidth}
                                                    name="valid_from"
                                                    size="small"
                                                    inputVariant="outlined"
                                                    label="Effective From *"
                                                    format="dd/MM/yyyy"
                                                    // style={{ width: 180 }}
                                                    disablePast={new Date(row.valid_from) > new Date() ? new Date(row.valid_from) : new Date()}
                                                    // minDate={row?.valid_from
                                                    //     ? new Date(row?.valid_from)
                                                    //     : new Date()
                                                    // }
                                                    minDate={new Date(row.valid_from)}
                                                    value={!row.valid_from ? null : new Date(row.valid_from)}
                                                    helperText={null}
                                                    onChange={
                                                        (e) => {
                                                            props.handleChange(index, "valid_from", identifier)(e)
                                                        }}
                                                    placeholder="e.g.: DD/MM/YYYY"
                                                    InputAdornmentProps={{ position: "end", }}
                                                    InputProps={{
                                                        style: {
                                                            paddingRight: 0,
                                                        },
                                                        readOnly: true
                                                    }}
                                                    error={Boolean(
                                                        formik.errors.valid_from &&
                                                        formik.touched.valid_from
                                                    )}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <FormHelperText error={Boolean(
                                                formik.errors.valid_from &&
                                                formik.touched.valid_from
                                            )}>{(formik.errors.valid_from && formik.touched.valid_from) ? String(formik.errors.valid_from) : " "}</FormHelperText>
                                        </ThemeProvider>)
                                        :
                                        (!row.valid_from ? null : moment(row.valid_from).format("DD MMM YYYY"))
                                    }
                                </Form>
                            </TableCell>
                            {/* <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {row?.operation === "add" ? (
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            className={classes.inputWidth}
                                            value={row.discount_amount}
                                            label="DISCOUNT AMOUNT"
                                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={
                                                (e) => {
                                                    props.handleChange(index, "discount_amount", identifier)(e)
                                                    formik.setFieldValue(
                                                        "discount_amount",
                                                        e.target.value
                                                    )
                                                }
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">₹</InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            helperText={
                                                (formik.errors.discount_amount && formik.touched.discount_amount) ? String(formik.errors.discount_amount) : " "
                                            }
                                            error={Boolean(
                                                formik.errors.discount_amount &&
                                                formik.touched.discount_amount
                                            )}
                                        />
                                    ) : (
                                        "₹ " + Number(row?.discount_amount).toFixed(0)
                                    )}
                                </Form>
                            </TableCell> */}
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {
                                        row?.operation === "add" ? (
                                            <FormControl size="small" variant="outlined" error={Boolean(
                                                formik.errors.unit &&
                                                formik.touched.unit
                                            )}>
                                                <InputLabel
                                                    htmlFor="outlined-unit"
                                                >
                                                    UNIT
                                                </InputLabel>
                                                <Select
                                                    id="outlined-unit"
                                                    disabled={
                                                        row?.operation === "edit" || row?.operation === "add"
                                                            ? false
                                                            : true
                                                    }
                                                    variant="outlined"
                                                    label="UNIT"
                                                    className={classes.unitSelect}
                                                    MenuProps={MenuProps}
                                                    value={row?.unit || ""}
                                                    onChange={
                                                        (e) => {
                                                            props.handleChange(index, "unit", identifier)(e)
                                                            formik.setFieldValue(
                                                                "unit",
                                                                e.target.value
                                                            )
                                                        }}
                                                >
                                                    {allUnits.length > 0
                                                        ? allUnits.map((data) => (
                                                            <MenuItem value={data.id}>{data.unit_code}</MenuItem>
                                                        ))
                                                        : null}
                                                </Select>
                                                <FormHelperText>{(formik.errors.unit && formik.touched.unit) ? String(formik.errors.unit) : " "}</FormHelperText>
                                            </FormControl>
                                        ) : (
                                            allUnits.map((e) => {
                                                if (e.id == row?.unit) return e.unit_code
                                            })
                                        )}
                                </Form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {
                                        row?.operation === "add" ? (
                                            <FormControl
                                                className={classes.unitSelect}
                                                variant="outlined"
                                                size="small"
                                                error={Boolean(
                                                    formik.errors.items_nature &&
                                                    formik.touched.items_nature
                                                )}
                                            >
                                                <InputLabel
                                                    htmlFor="outlined-items-nature"
                                                >
                                                    ITEM NATURE
                                                </InputLabel>
                                                <Select
                                                    label="ITEM NATURE"
                                                    id="outlined-items-nature"
                                                    variant="outlined"
                                                    className={classes.unitSelect}
                                                    MenuProps={MenuProps}
                                                    size="small"
                                                    value={row?.items_nature || ""}
                                                    onChange={(e) => {
                                                        props.handleChange(index, "items_nature", identifier)(e)
                                                        formik.setFieldValue(
                                                            "items_nature",
                                                            e.target.value
                                                        )
                                                    }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            height: "0px"
                                                        }
                                                    }}
                                                    helperText={
                                                        formik.errors.items_nature &&
                                                        formik.touched.items_nature &&
                                                        String(formik.errors.items_nature)
                                                    }
                                                >
                                                    {allItemNature.length > 0
                                                        ? allItemNature.map((data, itemNatureIndex) => (
                                                            <MenuItem value={data.item_nature} key={itemNatureIndex}>
                                                                {data.item_nature}
                                                            </MenuItem>
                                                        ))
                                                        : null}
                                                </Select>
                                                <FormHelperText>{(formik.errors.items_nature && formik.touched.items_nature) ? String(formik.errors.items_nature) : " "}</FormHelperText>
                                            </FormControl>
                                        ) : (
                                            row?.items_nature
                                        )}
                                </Form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {row?.operation === "add" ? (
                                        <FormControl className={classes.unitSelect} variant="outlined" size="small">
                                            <InputLabel
                                                htmlFor="outlined-items-nature"
                                            >
                                                TAGS
                                            </InputLabel>
                                            <Select
                                                disabled={
                                                    row?.operation === "edit" || row?.operation === "add"
                                                        ? false
                                                        : true
                                                }
                                                variant="outlined"
                                                className={classes.unitSelect}
                                                multiple
                                                label="TAGS"
                                                value={tags}
                                                MenuProps={MenuProps}
                                                size="small"
                                                // onChange={props.handleChange(index, "tags", identifier)}
                                                onChange={(e) => {
                                                    selectedTags(e.target.value);
                                                    props.handleChange(index, "tags", identifier)(e);
                                                }}
                                                renderValue={(selected) => (
                                                    <div style={styles.chips}>
                                                        {selected.map((value, index) => {
                                                            console.log(allTagsData, value)
                                                            let data = allTagsData.find(
                                                                (tagsData) => tagsData?.tag_name === value?.tag_name
                                                            );

                                                            return (
                                                                <Chip
                                                                    size="small"
                                                                    key={data.id}
                                                                    label={data?.tag_name || ""}
                                                                    style={styles.chip}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            >
                                                {allTagsData && allTagsData.length > 0
                                                    ? allTagsData.map((allTagsData, index) => (
                                                        <MenuItem key={index} value={allTagsData}>
                                                            <CustomCheckbox
                                                                checked={tags.some(
                                                                    (t) => t?.tag_name === allTagsData?.tag_name
                                                                )}
                                                            />
                                                            <ListItemText primary={allTagsData?.tag_name} />
                                                        </MenuItem>
                                                    ))
                                                    : null}
                                            </Select>
                                            <FormHelperText>{" "}</FormHelperText>
                                        </FormControl>
                                    ) : (
                                        row.tags.length > 0 ? row.tags[0]?.tag_name : row.tags[0]?.tag_name
                                    )}
                                </Form>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left" className={classes.tableCellWidth}>
                                <Form>
                                    {
                                        row?.operation === "add" ? (
                                            <FormControl variant="outlined" size="small" error={Boolean(
                                                formik.errors.section_name &&
                                                formik.touched.section_name
                                            )}>
                                                <InputLabel
                                                    htmlFor="outlined-unit"
                                                >
                                                    SECTION NAME
                                                </InputLabel>
                                                <Select
                                                    id="outlined-unit"
                                                    variant="outlined"
                                                    label="SECTION NAME"
                                                    className={classes.unitSelect}
                                                    MenuProps={MenuProps}
                                                    size="small"
                                                    value={row.sections[0]?.section_name}
                                                    onChange={
                                                        (e) => {
                                                            props.handleChange(index, "section_name", identifier, 0)(e)
                                                            formik.setFieldValue(
                                                                "section_name",
                                                                e.target.value
                                                            )
                                                        }}
                                                >
                                                    {allUnits.length > 0
                                                        ? allUnits.map((data) => (
                                                            <MenuItem value={data.unit_code}>{data.unit_code}</MenuItem>
                                                        ))
                                                        : null}
                                                </Select>
                                                <FormHelperText>{(formik.errors.section_name && formik.touched.section_name) ? String(formik.errors.section_name) : " "}</FormHelperText>
                                            </FormControl>
                                        ) : (
                                            row.sections[0]?.section_name
                                        )}
                                </Form>
                            </TableCell>
                            <TableCell>
                                <Form>
                                    {
                                        row?.operation === "add" ? (
                                            <TextField
                                                label="SECTION WEIGHT"
                                                required
                                                type="number"
                                                variant="outlined"
                                                className={classes.unitSelect}
                                                value={row.sections[0]?.section_weight}
                                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    handleSectionError(e.target.value)
                                                    props.handleChange(index, "section_weight", identifier, 0)(e)
                                                    formik.setFieldValue(
                                                        "section_weight",
                                                        e.target.value
                                                    )
                                                }}
                                                size="small"
                                                helperText={row.sections[0]?.section_weight ? errorMsg :
                                                    (formik.errors.section_weight && formik.touched.section_weight) ?
                                                        String(formik.errors.section_weight) : ""
                                                }
                                                onKeyPress={(event) => {
                                                    if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                InputProps={{
                                                    inputProps: { min: props.itemConsiderPacking ? 1 : 0 },
                                                }}
                                                error={error || Boolean(
                                                    formik.errors.section_weight &&
                                                    formik.touched.section_weight
                                                )}
                                            />
                                        ) : (
                                            row.sections[0]?.section_weight
                                        )}
                                </Form>
                            </TableCell>
                            {props.itemConsiderPacking ?
                                <>
                                    <TableCell>
                                        <Form>
                                            {
                                                row?.operation === "add" ? (
                                                    <TextField
                                                        label="PACKING WEIGHT"
                                                        required
                                                        type="number"
                                                        variant="outlined"
                                                        className={classes.unitSelect}
                                                        value={row?.packing_weight}
                                                        defaultValue={0}
                                                        onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) => {
                                                            handlePackingWeightError(e.target.value);
                                                            props.handleChange(index, "packing_weight", identifier, 0)(e)
                                                            formik.setFieldValue(
                                                                "packing_weight",
                                                                e.target.value
                                                            )
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        InputProps={{
                                                            inputProps: { min: props.itemConsiderPacking ? 1 : 0 },
                                                        }}
                                                        size="small"
                                                        helperText={row?.packing_weight ? packingErrMsg :
                                                            (formik.errors.packing_weight && formik.touched.packing_weight) ? String(formik.errors.packing_weight) : " "
                                                        }
                                                        error={packingError || Boolean(
                                                            formik.errors.packing_weight &&
                                                            formik.touched.packing_weight
                                                        )}
                                                    />
                                                ) : (
                                                    row?.packing_weight
                                                )}
                                        </Form>
                                    </TableCell>
                                    <TableCell>
                                        <Form>
                                            {
                                                row?.operation === "add" ? (
                                                    <TextField
                                                        label="TOL. UPTO 1 MTS"
                                                        required
                                                        type="number"
                                                        variant="outlined"
                                                        className={classes.unitSelect}
                                                        value={row.tolerance_upto_1mts}
                                                        defaultValue={0}
                                                        onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) => {
                                                            props.handleChange(index, "tolerance_upto_1mts", identifier, 0)(e)
                                                            formik.setFieldValue(
                                                                "tolerance_upto_1mts",
                                                                e.target.value
                                                            )
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        size="small"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        }}
                                                        helperText={
                                                            (formik.errors.tolerance_upto_1mts && formik.touched.tolerance_upto_1mts) ? String(formik.errors.tolerance_upto_1mts) : " "
                                                        }
                                                        error={Boolean(
                                                            formik.errors.tolerance_upto_1mts &&
                                                            formik.touched.tolerance_upto_1mts
                                                        )}
                                                    />
                                                ) : (
                                                    row?.tolerance_upto_1mts
                                                )}
                                        </Form>
                                    </TableCell>
                                    <TableCell>
                                        <Form>
                                            {
                                                row?.operation === "add" ? (
                                                    <TextField
                                                        label="TOL. UPTO 5 MTS"
                                                        required
                                                        type="number"
                                                        variant="outlined"
                                                        className={classes.unitSelect}
                                                        value={row?.tolerance_from_1to5mts}
                                                        defaultValue={0}
                                                        onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) => {
                                                            props.handleChange(index, "tolerance_from_1to5mts", identifier, 0)(e)
                                                            formik.setFieldValue(
                                                                "tolerance_from_1to5mts",
                                                                e.target.value
                                                            )
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        size="small"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        }}
                                                        helperText={
                                                            (formik.errors.tolerance_from_1to5mts && formik.touched.tolerance_from_1to5mts) ? String(formik.errors.tolerance_from_1to5mts) : " "
                                                        }
                                                        error={Boolean(
                                                            formik.errors.tolerance_from_1to5mts &&
                                                            formik.touched.tolerance_from_1to5mts
                                                        )}
                                                    />
                                                ) : (
                                                    row?.tolerance_from_1to5mts
                                                )}
                                        </Form>
                                    </TableCell>
                                    <TableCell>
                                        <Form>
                                            {
                                                row?.operation === "add" ? (
                                                    <TextField
                                                        label="TOL.ABOVE 5 MTS"
                                                        required
                                                        type="number"
                                                        variant="outlined"
                                                        className={classes.unitSelect}
                                                        value={row?.tolerance_above_5mts}
                                                        defaultValue={0}
                                                        onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) => {
                                                            props.handleChange(index, "tolerance_above_5mts", identifier, 0)(e)
                                                            formik.setFieldValue(
                                                                "tolerance_above_5mts",
                                                                e.target.value
                                                            )
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        size="small"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        }}
                                                        helperText={
                                                            (formik.errors.tolerance_above_5mts && formik.touched.tolerance_above_5mts) ? String(formik.errors.tolerance_above_5mts) : " "
                                                        }
                                                        error={Boolean(
                                                            formik.errors.tolerance_above_5mts &&
                                                            formik.touched.tolerance_above_5mts
                                                        )}
                                                    />
                                                ) : (
                                                    row?.tolerance_above_5mts
                                                )}
                                        </Form>
                                    </TableCell>
                                </>
                                : <></>}
                            <TableCell
                                style={{
                                    paddingTop: (row?.operation === "edit" || row?.operation === "add") && 0
                                }}
                            >
                                <Form>
                                    {
                                        row?.operation === "add" ? (
                                            <IOSSwitch
                                                checked={(identifier === "addNewData" && row.is_active === undefined)
                                                    ? true
                                                    : Boolean(row.is_active)
                                                }
                                                // checked={Boolean(row.is_active)}
                                                onChange={(e) => props.handleChange(index, "is_active", identifier)(e)}
                                                name="is_active"
                                            />
                                        )
                                            :
                                            (row.is_active
                                                ? (<CheckCircle style={{ color: successButtonBackground }} />)
                                                : (<Cancel style={{ color: orange }} />)
                                            )
                                    }
                                </Form>
                            </TableCell>
                            {/* <TableCell className={classes.tableCellWidth}>
                                <Form>
                                    {!row.operation || row.operation === "view" ? (
                                        <div className={classes.deleteAndExpandActionStyle}>
                                            <IconButton
                                                size="small"
                                            //onClick={() => props.handleSetItemTypeOperation("edit", index)}
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    ) : null}
                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                        <>
                                            <div className={classes.deleteAndExpandActionStyle}>
                                                <IconButton
                                                    size="small"
                                                    type="submit"
                                                    // disabled={
                                                    //   formik.errors.item_name ||
                                                    //   formik.errors.item_code ||
                                                    //   formik.errors.rate_difference ||
                                                    //   formik.errors.discount_amount ||
                                                    //   formik.errors.unit ||
                                                    //   formik.errors.items_nature ||
                                                    //   // formik.errors.tags ||
                                                    //   formik.errors.section_name ||
                                                    //   formik.errors.section_weight}
                                                    //disabled={formik.errors.valid_from}
                                                    disabled={error || packingError}
                                                >
                                                    <CheckCircleOutlineOutlinedIcon />
                                                </IconButton>
                                            </div>
                                            <div className={classes.deleteAndExpandActionStyle}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        props.handleSetItemTypeOperation("view", index)
                                                    }
                                                >
                                                    <CancelOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        </>
                                    ) : null}
                                </Form>
                            </TableCell> */}

                        </>
                    )}

                </Formik>

            </TableRow>
            {
                open ?
                    <ItemDialog record={row} open={open}
                        handleCloseItemTypes={handleCloseItemTypes}
                        tags={tags}
                        allTagsData={allTagsData}
                        index={index}
                        allUnits={allUnits}
                        allItemSize={allItemSize}
                        allItemNature={allItemNature}
                        Mousehover={Mousehover}
                        identifier={"dataFromDatabase"}
                        scroll={scroll}
                        ClickedRateDiffBox={ClickedRateDiffBox}
                        handleSetItemTypeOperation={props.handleSetItemTypeOperation}
                        handleItemAndSectionDataUpdate={props.handleItemAndSectionDataUpdate}
                        itemConsiderPacking={props.itemConsiderPacking}
                        user={props.user}
                    /> : <></>
            }
        </React.Fragment >

    );

}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            })
        ).isRequired,
        itemName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
    }).isRequired,
};
