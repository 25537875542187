import React, { useState } from "react";
import axios from "axios";
import ReportsWeb, {
  ReportsMobile,
} from "../../../components/internal/DispatchTracking";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import AccessDenied from "../../../components/common/AccessDenied";
import {
  isAccessibleModule,
  generateCSV,
  isRequired,
  options,
} from "../../../methods";
import withUser from "../../../hoc/withUser";

function Reports(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [plant, setPlant] = React.useState({ value: "default" });
  const [plants, setPlants] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [invoices, setInvoices] = React.useState({
    data: [],
    loading: false,
    error: "",
  });
  const [plantItemMasterList, setPlantItemMasterList] = React.useState([])
  const [itemCategoryMasterList, setItemCategoryMasterList] = React.useState([])
  const [freightBasisMaster, setFreightBasisMaster] = React.useState([])

  React.useEffect(fetchPlants, []);
  React.useEffect(() => {
    if (plant.id) {
      fetchInvoices(plant.id, selectedDate);
    }
  }, [plant]);
  React.useEffect(() => {
    getPlantItemMasterList();
    fetchItemCategories();
    fetchFreightBasis();
  }, []);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSelect = (e) => {
    setInvoices({ data: [], loading: false, error: "" });
  };
  const handleSelectedMoreFilter = (e) => {
    setInvoices({ data: [], loading: false, error: "" });
  };
  const selectPlant = (e) => {
    setPlant(plants.data.find((el) => el.value === e.target.value));
  };

  const download = () => {
    console.log("heer")
    generateCSV(invoices.data);
    setPlant({ value: "default" });
  };

  const fetchItemCategories = () => {
    axios
      .get(`${APIROUTES.GET_ITEM_CATEGORIES_LIST}?active=${true}`, options.call(props.user))
      .then((response) => {
        if (response.status === 200) {
          setItemCategoryMasterList(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const fetchFreightBasis = () => {
    axios
      .get(`${APIROUTES.FREIGHT_BASIS}`, options.call(props.user))
      .then((response) => {
        if (response.status === 200) {
          setFreightBasisMaster(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function fetchPlants() {
    setPlants({ ...plants, loading: true });
    axios.get(APIROUTES.GET_EWAY_DATA_TRACKING, options.call(props.user)).then(
      (response) => {
        let data = [];
        if (response.status === 200) data = response.data;

        setPlants({
          error: "",
          loading: false,
          data: data.map((e) => ({
            id: e.plant_code,
            value: e.plant_name,
            label: e.plant_name,
          })),
        });
      },
      (error) => {
        setPlants({ error: error.message, loading: false, data: [] });
        console.error(error);
      }
    );
  }

  const fetchInvoices = (plantCode = isRequired("plantCode"), date) => {
    setInvoices({ ...invoices, loading: true });
    // &created_at=${date}
    axios
      .get(
        `${APIROUTES.GET_EWAY_DATA_TRACKING}?plant_code=${plantCode}`,
        options.call(props.user)
      )
      .then(
        (response) => {
          let data = [];
          if (response.status === 200) data = response.data;
          setInvoices({ error: "", loading: false, data });
        },
        (error) => {
          setInvoices({ error: error.message, loading: false, data: [] });
          console.error(error);
        }
      );
  };

  const getPlantItemMasterList = () => {
    axios
      .get(
        APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST,
        options.call(props.user)
      )
      .then(
        (response) => {
          setPlantItemMasterList(response.data)
        },
        (error) => {
          console.log("error", error);
        }
      );
  };

  if (!isAccessibleModule(props.user, "Dispatch Tracking"))
    return <AccessDenied />;

  // if (props.isMobile) return <ReportsMobile {...props} />;

  return (
    <ReportsWeb
      {...props}
      isMobile={props.isMobile}
      plant={plant}
      selectedDate={selectedDate}
      plants={plants}
      invoices={invoices}
      download={download}
      selectPlant={selectPlant}
      handleSelect={handleSelect}
      handleDateChange={handleDateChange}
      handleSelectedMoreFilter={handleSelectedMoreFilter}
      plantItemMasterList={plantItemMasterList}
      itemCategoryMasterList={itemCategoryMasterList}
      freightBasisMaster={freightBasisMaster}
    />
  );
}

export default withUser(Reports);
