import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import SalesExplorerTable from './SalesExplorerTable';
import Divider from '@material-ui/core/Divider';
import ContractBookingTable from './ContractBookingTable';
import CountryTable from './CountryTable';
import DashboardSalesPerson from './DashboardSalesPerson';
import DashboardItemDifferenceTable from './DashboardItemDifferenceTable';


export default function DashboardOverviewTab(props) {
    const { itemCategoryDropdownList, plantDropdownList } = props;

    return (
        <>
            <Paper style={{ borderRadius: "20px" }}>
                <ContractBookingTable tabValue={props?.tabValue} widget='la_booking_table' itemCategoryDropdownList={itemCategoryDropdownList} />
                <br />
            </Paper>
            <Paper style={{ borderRadius: "20px", margin: '30px 0' }}>
                <SalesExplorerTable plantDropdownList={plantDropdownList} />
                <br />
            </Paper>
            <Paper style={{ borderRadius: "20px", margin: '30px 0' }}>
                <ContractBookingTable tabValue={props?.tabValue} widget='item_tags' itemCategoryDropdownList={itemCategoryDropdownList} />
                <br />
            </Paper>
            <Paper style={{ borderRadius: "20px", margin: '30px 0' }}>
                <CountryTable tabValue={props?.tabValue} itemCategoryDropdownList={itemCategoryDropdownList} plantDropdownList={plantDropdownList} />
            </Paper>
            <Paper style={{ borderRadius: "20px", margin: '30px 0' }}>
                <DashboardSalesPerson tabValue={props?.tabValue} itemCategoryDropdownList={itemCategoryDropdownList} plantDropdownList={plantDropdownList} />
            </Paper>
            <Paper style={{ borderRadius: "20px", margin: '30px 0' }}>
                <DashboardItemDifferenceTable tabValue={props?.tabValue} itemCategoryDropdownList={itemCategoryDropdownList} />
            </Paper>
        </>
    );
}
