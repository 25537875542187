import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  errorMessageColor,
  greenColor,
  primaryColor,
  secondaryColor,
} from "../../../styles/colors";
import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import moment from 'moment'
import RiplInvoicePdfDownload from "../../internal/dispatchOperation/RiplInvoicePdfDownload";
import { handlePaymentStatusText, pxToEm } from "../../../methods";
import { lightGreen } from "../../../constants/internal/colors";
import { CircularProgress } from "@material-ui/core";

const styles = {
  invoiceListContainer: {
    width: "90%",
    minHeight: "90px",
    borderRadius: "12px",
    backgroundColor: '#FFF',
    margin: "10px 0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    boxShadow:
      "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  listLeftContainer: {
    width: "18%",
    height: "100%",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  listMiddleContainer: {
    width: "82%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  listMiddleTopContainer: {
    width: "95%",
    // height: "100%",
    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
  },
  listMiddleTopRightContainer: {
    // width: "25%",
    // height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    fontSize: "1em",
    paddingBottom: 3
  },
  listMiddleTextContainer: {
    color: bottomNavigationFontColor,
    display: "flex",
    alignItems: "center",
    fontSize: '0.92em'
  },
  hrLine: {
    width: "2px",
    height: "10px",
    backgroundColor: bottomNavigationFontColor,
    margin: "0px 8px",
  },
  leftIcon: (status) => ({
    transform: status !== "" && 'scale(0.7)',
    width: "1.6em",
    height: "1.6em",
    color: status === 'CANCELLED'
      ? 'red'
      : status === ''
        ? secondaryColor
        : status === 'DUE TODAY' || status === "OVERDUE"
          ? errorMessageColor
          : status === 'PAID' && greenColor,
  }),
  leftIconText: (status) => ({
    transform: 'scale(0.95)',
    textAlign: 'center',
    color: status === 'PAID'
      ? greenColor
      : errorMessageColor,
    fontSize: (status.length > 7)
      ? window.innerWidth < 310
        ? pxToEm(9.4)
        : window.innerWidth < 375
          ? pxToEm(10)
          : pxToEm(11.6)
      : window.innerWidth < 310
        ? pxToEm(10.6)
        : window.innerWidth < 375
          ? pxToEm(11.6)
          : pxToEm(13.6),
  })
};

class InvoiceCard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      classes,
      data,
      isLoading,
      module
    } = this.props;
    return (
      <>
        <div className={classes.listLeftContainer}>
          {data?.dispatch_status === 'CANCELLED'
            ? <>
              <HighlightOffRoundedIcon style={styles.leftIcon('CANCELLED')} />
              <div style={styles.leftIconText('CANCELLED')}>CANCELLED</div>
            </>
            : handlePaymentStatusText(data.la_due_date, data.payment_status) > 0
              ?
              <Assignment style={styles.leftIcon('')} />
              : handlePaymentStatusText(data.la_due_date, data.payment_status) === 0
                ?
                <>
                  <AssignmentLateOutlinedIcon style={styles.leftIcon('DUE TODAY')} />
                  <div style={styles.leftIconText('DUE TODAY')}>DUE TODAY</div>
                </>
                : handlePaymentStatusText(data.la_due_date, data.payment_status) < 0
                  ?
                  <>
                    <AssignmentLateOutlinedIcon style={styles.leftIcon('OVERDUE')} />
                    <div style={styles.leftIconText('OVERDUE')}>
                      {module === ''
                        ? 'OVERDUE'
                        : Math.abs(handlePaymentStatusText(data.la_due_date, data.payment_status)) + ' Days'
                      }
                    </div>
                  </>
                  : handlePaymentStatusText(data.la_due_date, data.payment_status) === 'PAID' &&
                  <>
                    <AssignmentTurnedInOutlinedIcon style={styles.leftIcon('PAID')} />
                    <div style={styles.leftIconText('PAID')}>PAID</div>
                  </>
          }
        </div>
        <div className={classes.listMiddleContainer}>
          <div className={classes.listMiddleTopContainer}>
            <div>
              <span style={{ fontSize: window.innerWidth < 310 ? pxToEm(12.5) : window.innerWidth < 375 ? pxToEm(14) : "1.05em" }}>
                {data.doc_no} | {moment(data.created_at, "DD-MM-YYYY").format("DD-MMM-YYYY")}
              </span>
              <div className={classes.listMiddleTextContainer}>
                <span style={{ fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(12) }}>
                  {data.plant_short_name} | {data.truck_number}
                </span>
              </div>
            </div>
            <div
              className={classes.listMiddleTopRightContainer}
              style={{ fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13) }}
            >
              {data.quantity} MT
            </div>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <span
              className={classes.listMiddleTextContainer}
              style={{ fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(12) }}
            >
              {data?.dispatch_status === 'CANCELLED' ? ""
                : handlePaymentStatusText(data.la_due_date, data.payment_status) > 0
                  ? `Due in ${handlePaymentStatusText(data.la_due_date, data.payment_status)} Days`
                  : handlePaymentStatusText(data.la_due_date, data.payment_status) == 0
                    ? `Due Today`
                    : handlePaymentStatusText(data.la_due_date, data.payment_status) < 0
                      ? module !== '' ? `₹ ${new Intl.NumberFormat('en-IN', { style: 'decimal' }).format(data.due_amount)} due since`
                        : `Overdue by ${Math.abs(handlePaymentStatusText(data.la_due_date, data.payment_status))} Days`
                      : handlePaymentStatusText(data.la_due_date, data.payment_status) === 'PAID' &&
                      'Paid'
              }
              {handlePaymentStatusText(data.la_due_date, data.payment_status) < 0
                ? module === '' ? " | " : " "
                : data?.dispatch_status !== 'CANCELLED' && " | "}
              {moment(data.la_due_date).format("DD-MMM-YYYY")}
            </span>
            {isLoading === data.doc_no &&
              (<span>
                <CircularProgress
                  size={15}
                  style={{ margin: "5px 30px 0 0", color: primaryColor }}
                />
              </span>
              )
            }
          </div>
        </div>
      </>
    )
  }
}
class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  handleDownloadOpeningPDFLoader = (data = '') => {
    this.setState({ isLoading: data === "" ? false : data })
  }


  payload = () => {
  }
  render() {
    const {
      classes,
      singleOrderInvoices,
      module = '',
    } = this.props;
    const { isLoading } = this.state;
    // console.log(singleOrderInvoices)
    return (singleOrderInvoices.map((data) => (
      data.downloadReady ?

        <div
          className={classes.invoiceListContainer}
          style={{ opacity: isLoading === data.doc_no && '0.6' }}
        >
          <RiplInvoicePdfDownload
            // pdfOperation={"CUSTOMERDOWNLOAD"}
            {...this.props}
            pdfpayload={this.payload}
            handleOpenSnackBar={this.props.handleOpenSnackBar}
            isLogoRequired={true}
            invoiceForPlatform="customer app"
            device="cordova"
            docNumber={data.doc_no}
            invoiceData={data}
            handleDownloadLoader={this.handleDownloadOpeningPDFLoader}
            onClick={() => {
              if (!(isLoading === data.doc_no)) {
                this.props.handleInvoiceClick(data)
              }
            }}
            handlePostInvoiceDownload={this.props.handlePostInvoiceDownload}
            b2CQrImage={this.props.b2CQrImage}
            documentFor={
              this.props.invoiceType ?
                this.props.invoiceType !== "Normal Invoice" ?
                  this.props?.laDetailsInDispatchCounter?.item_category
                    ?.is_allow_rate_zero &&
                    Number(
                      this.props?.laDetailsInDispatchCounter?.order?.rate
                    ) === 0
                    ? "Delivery Challan"
                    : "E-Invoice" :
                  this.props?.laDetailsInDispatchCounter?.item_category
                    ?.is_allow_rate_zero &&
                    Number(
                      this.props?.laDetailsInDispatchCounter?.order?.rate
                    ) === 0
                    ? "Delivery Challan"
                    : "Invoice"
                : null
            }
          />
          <InvoiceCard
            {...this.props}
            classes={classes}
            data={data}
            isLoading={isLoading}
            module={module}
          />
        </div>
        // </RiplInvoicePdfDownload >
        :
        <div
          className={classes.invoiceListContainer}
          style={{
            opacity: isLoading === data.doc_no && '0.6'
          }}
          onClick={() => {
            if (!(isLoading === data.doc_no)) {
              this.props.handleInvoiceClick(data)
              this.handleDownloadOpeningPDFLoader(data.doc_no)
            }
          }
          }>
          <InvoiceCard
            {...this.props}
            classes={classes}
            data={data}
            isLoading={isLoading}
            module={module}
          />
        </div>
    )))
  }
}
export default withStyles(styles)(InvoiceList);
