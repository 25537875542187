import { useEffect, useState } from "react";
import {
  checkApiError,
  handleSnackbarMessageBox,
  startOFMonth,
  endOFMonth,
  arraySortBykeyAllBrowser,
} from "../../../../methods";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../common/SnackbarComponent";
import { LineChartTable, SimpleTable, ToggleButton, WidgetTop } from "./commanWidget";
import { Paper } from "@mui/material";

const styles = {
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '96%',
    margin: '0px auto'
  },
  tableContainer: (isMobile) => ({
    width: "100%",
    maxHeight: 400,
    overflowY: 'auto',
  }),
}

const DashboardSalesPerson = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mounted, setMounted] = useState(false);
  const [itemcategoryIDSelected, setItemcategoryIDSelected] = useState([])
  const [plantIDSelected, setPlantIDSelected] = useState([])
  const [selectedDate, setSelectedDate] = useState({
    label: 'M',
    startDate: startOFMonth,
    endDate: endOFMonth
  })
  const [salesPersonData, setSalesPersonData] = useState([])
  const [graphApiData, setGraphApiData] = useState({ data: [], graphType: '' })
  const [showProgress, setShowProgress] = useState(false);
  const [tableRowSelection, setTableRowSelection] = useState(null)
  const [dropdownSelection, setDropdownSelection] = useState('contract_qty')
  const [cancelTokenSource, setCancelTokenSource] = useState({
    tableDataApi: null,
    graphDataApi: null,
  });
  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: "",
    snackbarType: ""
  })

  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: ""
    })
  };

  const handleCatchError = (error) => {
    handleOpenSnackbar(
      handleSnackbarMessageBox(checkApiError(error?.response)),
      "error"
    );
    setTimeout(() => {
      handleCloseSnackbar();
    }, 4000);
  }

  const handleTableData = (fromDate, toDate, itemCategoryIdList, plantIdList) => {
    const newCancelTokenSource = handleCancelToken('tableDataApi')
    setShowProgress(true)
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      item_category: itemCategoryIdList,
      plant: plantIdList
    }
    axios.post(`${APIROUTES.SALES_PERSON_DATA}`,
      payload,
      { cancelToken: newCancelTokenSource.token, }
    )
      .then((response) => {
        if (response.status === 200) {

          let data = arraySortBykeyAllBrowser(response.data['Sales Person Wise Data'], 'net_sales_qty', 'decreasing')
          console.log(data)
          setSalesPersonData(data)
          setShowProgress(false)
        }
      })
      .catch((error) => {
        setShowProgress(false)
        if (axios.isCancel(error)) {
        } else {
          handleCatchError(error)
        }
      });
  }

  const handleGraphWiseData = (fromDate, toDate, itemID, plantID, dropdown, rowID, graphType) => {
    if (rowID) {
      const newCancelTokenSource = handleCancelToken('graphDataApi')
      const payload = {
        from_date: fromDate,
        to_date: toDate,
        item_category: itemID,
        plant: plantID,
        filter_name: dropdown,
        sales_person: rowID
      }
      const url = `${APIROUTES.SALES_PERSON_GRAPH_DATA}`
      axios.post(url, payload, { cancelToken: newCancelTokenSource.token, })
        .then((response) => {
          if (response.status === 200) {
            let data = response.data[dropdown === 'contract_qty'
              ? 'Sales Person Contract Quantity Graph Data'
              : dropdown === 'net_sales_qty'
                ? 'Sales Person Net Sales Quantity Graph Data'
                : dropdown === 'net_sales_cr' && 'Sales Person Net Sales Cr Graph Data']
            setGraphApiData({ data: data, graphType: graphType })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
          } else {
            handleCatchError(error)
          }
        });
    }
  }

  const handlTableRowSelection = (rowID) => {
    setTableRowSelection(rowID)
  }

  const dateChange = (data) => {
    setSelectedDate({
      label: data.label,
      startDate: data.startDate,
      endDate: data.endDate
    })
  }

  const handleFilterSelection = (idList, filter) => {
    if (filter === 'item_category') {
      setItemcategoryIDSelected(idList)
    }
    else {
      setPlantIDSelected(idList)
    }
  }

  const handlDropdownSelection = (data) => {
    setDropdownSelection(data?.graphValue)
  }

  const handleCancelToken = (key) => {
    if (cancelTokenSource[key]) {
      cancelTokenSource[key].cancel('Operation canceled');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(prevValue => { return { ...prevValue, [key]: newCancelTokenSource } });
    return newCancelTokenSource;
  }


  useEffect(() => {
    if (mounted) {
      console.log("called 2")
      handleTableData(selectedDate.startDate,
        selectedDate.endDate,
        itemcategoryIDSelected,
        plantIDSelected
      )
      handleGraphWiseData(
        selectedDate.startDate,
        selectedDate.endDate,
        itemcategoryIDSelected,
        plantIDSelected,
        dropdownSelection,
        tableRowSelection,
        selectedDate.label
      )
    }
  }, [selectedDate, itemcategoryIDSelected, plantIDSelected])

  useEffect(() => {
    if (mounted) {
      handleGraphWiseData(
        selectedDate.startDate,
        selectedDate.endDate,
        itemcategoryIDSelected,
        plantIDSelected,
        dropdownSelection,
        tableRowSelection,
        selectedDate.label
      )
    }
  }, [tableRowSelection, dropdownSelection])

  useEffect(() => {
    console.log("called 1")
    handleTableData(selectedDate.startDate,
      selectedDate.endDate,
      itemcategoryIDSelected,
      plantIDSelected,
    )
    handleGraphWiseData(
      selectedDate.startDate,
      selectedDate.endDate,
      itemcategoryIDSelected,
      plantIDSelected,
      dropdownSelection,
      tableRowSelection,
      selectedDate.label
    )
    setMounted(true)
    return () => {
      setMounted(false)
    };
  }, [])

  useEffect(() => {
    // Cleanup function to cancel the previous request when component unmounts
    return () => {
      Object.entries(cancelTokenSource)
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => (cancelTokenSource[key].cancel("Operation canceled")));
    };
  }, [cancelTokenSource]);

  const DATEBUTTONS = [
    { label: 'Y', function: dateChange },
    { label: 'M', function: dateChange, defaultSelected: true },
    { label: 'W', function: dateChange },
    { label: 'T', function: dateChange },
    { label: 'date_range', function: dateChange },
  ]

  const TABLEDATA = [
    { label: 'Uid', key: 'employee_id', id: 'sales_person', id2: 'no_header', minWidth: 80 },
    { label: 'Sales Person', key: 'name', id: 'no_header', minWidth: 150 },
    { label: 'Contract Qty', key: 'total_booked_quantity', minWidth: 130 },
    { label: 'Net Sales Qty', key: 'net_sales_qty', minWidth: 130 },
    { label: 'Net Sales (Cr)', key: 'net_sales_cr', minWidth: 130 },
  ]

  const GRAPHDROPDOWNDATA = [
    { label: 'Contract Qty', key: 'total_booked_quantity', graphValue: 'contract_qty' },
    { label: 'Net Sales Qty', key: 'net_sales_qty', graphValue: 'net_sales_qty' },
    { label: 'Net Sales (Cr)', key: 'net_sales_cr', graphValue: 'net_sales_cr' },
  ]

  return (
    <div style={{ paddingBottom: 30 }}>
      <WidgetTop
        {...props}
        widgetTitle='Sales Leaderboard'
        widgetSubtitle="Excluding Intercompany"
        isMobile={isMobile}
        plantFilter={true}
        itemCategoryFilter={true}
        handleFilterSelection={handleFilterSelection}
        itemCategoryDropdownList={props?.itemCategoryDropdownList}
        plantDropdownList={props?.plantDropdownList}
        dateButtons={DATEBUTTONS}
        selectedDate={selectedDate?.label}
        pdfCsvButton={false}
      />
      <Paper style={styles.paperContainer}>
        <SimpleTable
          {...props}
          isMobile={isMobile}
          tableData={TABLEDATA}
          showProgress={showProgress}
          rows={salesPersonData}
          backgroundOddEven={false}
          tableContainerStyle={styles.tableContainer(isMobile)}
          handlTableRowSelection={handlTableRowSelection}
          selectedDate={selectedDate?.label}
          stickyHeader={true}
          widget="dashboard_sales_person"
        />
      </Paper>
      <div style={{ width: '98%', margin: '10px auto 0px' }}>
        <LineChartTable
          {...props}
          isMobile={isMobile}
          graphDropdownList={GRAPHDROPDOWNDATA}
          selectedDate={selectedDate?.label}
          graphApiData={graphApiData}
          handlDropdownSelection={handlDropdownSelection}
          singleRowSelection={true}
        />
      </div>
      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </div>
  )
}

export default DashboardSalesPerson;