import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  Switch,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import {
  checkAlphabet,
  checkAlphaNumericLength,
  checkApiError,
  pxToEm,
} from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import SaveButton from "../../common/SaveButton";
import DateFnsUtils from "@date-io/date-fns";
// import { Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

function ManageAppBannerData(props) {
  const classes = useStyles();
  const { handleappBannerDataUpdate, handleappBannerDataAdd } = props;
  const [appBannerData, setAppBannerData] = useState(
    props.selectedAppBannerData || {
      news: null,
      valid_from: null,
      valid_upto: null,
    }
  );
  const [allBannerNotUseDateList, setAllBannerNotUseDateList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [appBannerError, setAppBannerError] = useState({
    news: false,
    valid_from: false,
    valid_upto: false,
  });
  const [appBannerOperation, setAppBannerOperation] = useState(
    props.appBannerOperation
  );
  const [snackbarData, setSnackbarData] = useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });

  useEffect(() => {
    setAppBannerOperation(props.appBannerOperation)
    setAppBannerData(props.selectedappBannerData)
  }, [props.appBannerOperation, props.selectedappBannerData])

  useEffect(() => {
    props.appBannerList.map(d => {
      setAllBannerNotUseDateList((prevValue) => [...prevValue, moment(d.valid_from).format('DD-MMM-YYYY')])
    })
  }, [props.appBannerList])

  useEffect(() => {
    console.log(allBannerNotUseDateList)
  }, [allBannerNotUseDateList])


  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, appBanner) => {
    if (appBanner === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return data.map((item, index) => {
      return (
        <>
          <p style={{ margin: 0 }}>{item}</p>
        </>
      );
    });
  };

  const handleDateChange = (name) => (date) => {
    if (date !== "Invalid Date") {
      let valid_upto = (moment(date).add(1, 'days'))?._d.toString()
      // console.log(valid_upto, typeof valid_upto, moment(date).add(1, 'days'))
      setAppBannerData((prevData) => ({
        ...prevData,
        ['valid_from']: moment(date).format("YYYY-MM-DDT12:00:00"),
        ['valid_upto']: moment(valid_upto).format("YYYY-MM-DDT12:00:00"),
      }));
      setAppBannerError(prevValue => { return { ...prevValue, [name]: false } })
    } else {
      setAppBannerError(prevValue => { return { ...prevValue, [name]: true } })
    }
  };

  const handleChange = (event) => {
    const { value, name, } = event.target;
    const trimmedValue = value.trim();

    setAppBannerData({
      ...appBannerData,
      [name]: value,
    });

    if (!trimmedValue) {
      setAppBannerError((prevValue) => ({
        ...prevValue,
        [name]: true,
      }));
    } else {
      if (name === 'news') {
        if (!/[A-Za-z]/.test(trimmedValue)) {
          setAppBannerError((prevValue) => ({ ...prevValue, [name]: true }));
        } else {
          setAppBannerError((prevValue) => ({ ...prevValue, [name]: false }));
        }
      }
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };

  const handleEditSaveButtonClick = () => {
    setIsLoading(true);
    // console.log(appBannerData)
    if (appBannerOperation === "edit") {
      handleappBannerDataUpdate(appBannerData)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `App Banner Content Updated Successfully`,
              "success"
            );
            if (!snackbarData?.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false);
                props.history.goBack();
              }, 3000);
            }
            // props.history.goBack();
          } else {
            setIsLoading(false);
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while updating App Banner Content please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          console.log(error)
          setIsLoading(false);
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (appBannerOperation === "add") {
      handleappBannerDataAdd(appBannerData)
        .then((response) => {
          console.log("handleAppBannerDataAdd response : ", response);
          if (response.status === 201) {
            handleOpenSnackbar(`App Banner Created Successfully`, "success");
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false);
                props.history.push(
                  `${INTERNALROUTES.APP_BANNER_DETAIL}/${response.data.id}`
                );
              }, 3000);
            }
            // props.history.push(`${INTERNALROUTES.CUSTOMER_TYPE}/${response.data.appBanner}`);
          } else {
            setIsLoading(false);
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while creating App Banner master please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "App Banner Master",
                onClick: () =>
                  props.history.push(INTERNALROUTES.APP_BANNER),
              },
              {
                title: appBannerOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                CONTENT *
              </Typography>
              <TextField
                label="content"
                name="news"
                onChange={handleChange}
                multiline
                maxRows={3}
                placeholder="Enter App Banner Text"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={appBannerData?.news || ""}
                error={appBannerError.news}
                helperText={appBannerError.news ? "Invalid Field" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                WITH EFFECT FROM *
              </Typography>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="valid_from"
                    size="small"
                    inputVariant="outlined"
                    label="Valid From"
                    format="dd/MM/yyyy"
                    style={{ width: "70%", marginBottom: "14px" }}
                    shouldDisableDate={(d) => allBannerNotUseDateList?.includes(moment(d).format('DD-MMM-YYYY'))}
                    minDate={
                      appBannerOperation === 'add'
                        ? new Date()
                        : new Date(
                          new Date(appBannerData?.valid_from) > new Date()
                            ? appBannerData?.valid_from
                            : new Date()
                        )
                    }
                    value={
                      !appBannerData?.valid_from
                        ? null
                        : new Date(appBannerData?.valid_from)
                    }
                    onChange={handleDateChange("valid_from")}
                    placeholder="DD/MM/YYYY"
                    InputAdornmentProps={{ position: "end" }}
                    InputProps={{
                      style: { paddingRight: 0, },
                      readOnly: true,
                    }}
                    error={appBannerError.valid_from}
                    helperText={appBannerError.valid_from ? "Invalid Date" : ""}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </div>

            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                WITH EFFECT UPTO *
              </Typography>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="valid_upto"
                    size="small"
                    inputVariant="outlined"
                    label="Valid Upto"
                    format="dd/MM/yyyy"
                    style={{ width: "70%", marginBottom: "14px" }}
                    minDate={
                      appBannerOperation === 'add'
                        ? appBannerData?.valid_from
                          ? new Date(appBannerData?.valid_from)
                          : new Date()
                        : new Date(appBannerData?.valid_from)
                    }
                    value={
                      !appBannerData?.valid_upto
                        ? null
                        : new Date(appBannerData?.valid_upto)
                    }
                    onChange={handleDateChange("valid_upto")}
                    placeholder="DD/MM/YYYY"
                    InputAdornmentProps={{ position: "end" }}
                    InputProps={{
                      style: { paddingRight: 0, },
                      readOnly: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </div> */}
            <SaveButton
              size="medium"
              disabled={
                !appBannerData?.news ||
                appBannerError?.news ||
                !appBannerData?.valid_from ||
                appBannerError?.valid_from
                // appBannerError?.valid_upto
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {appBannerOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManageAppBannerData);
