import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import Confirm from "../Confirm";
import Details from "../../orders/Details";

class ConfirmOrder extends Component {
  handleBack = () => {
    this.props.history.push("/home");
  };
  render() {
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        {/* <Confirm isMobile {...this.props} /> */}
        <Details isMobile {...this.props} />
      </MobileLayout>
    );
  }
}
export default ConfirmOrder;
