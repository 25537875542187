import React, { Component } from "react";
import COLORS, { grey, primaryColor } from "../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
import Stop from "@material-ui/icons/Stop";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Divider, Paper, Popover, Typography } from "@material-ui/core";
import moment from "moment";
import { pxToEm } from "../../../methods";
import Backdrop from '@mui/material/Backdrop';
import Bread from "../common/Breadcrubs";
import { Print } from "@material-ui/icons";
import { handlePrintPlantDetails } from "./PrintContents";
import SnackbarComponent from "../common/SnackbarComponent";
import DispatchSkeleton from "./DispatchSkeleton";

const styles = {
  headingText: {
    color: COLORS.secondaryBlueText,
    fontSize: "1.2em",
  },
  plantDetails: {
    width: "100%",
    height: "18vh",
    margin: "6px 24px 12px 6px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  semiCircleMainContainer: {
    width: "35%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
  },
  dispatchedDetailContainer: {
    width: "65%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    lineHeight: "2.6em",
  },
  dispatchedTextStyle: {
    display: "flex",
    alignItems: "center",
    color: COLORS.grey,
  },
  paperStyle: {
    width: "360px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    margin: "10px",
    padding: "10px 5px"
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    padding: "6px 15px",
    color: "#eee",
    backgroundColor: '#5A5A5A'
  },
};

class PopOverComponent extends React.Component {
  state = {
    anchorEl: null,
    open: null,
  }

  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { hoverText, classes, fontSize, fontWeight, width, plantName, itemName } = this.props;
    const { anchorEl, } = this.state;
    const open = Boolean(this.state.anchorEl);
    // console.log(anchorEl, open)
    return (
      <>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
          style={{
            fontSize: pxToEm(fontSize),
            fontWeight: anchorEl === null ? fontWeight : String(Number(fontWeight) + 100),
            color: anchorEl === null ? grey : "#3B69B0",
            width: width && width
          }}
        >
          {this.props.children}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'auto',
          }}
          className={classes.popover}
          classes={{
            paper: classes.popoverContent
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            style={{
              fontSize: pxToEm(14),
              fontWeight: '500',
            }}
          >
            {hoverText}
          </Typography>
        </Popover>
      </>
    )
  }
}

class DispatchPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      loader_name: '',
      dispatchPlanningLoader: false,
      dispatch_loader_name: '',
      snackOpen: false,
    };
    this.divref = React.createRef()
    const { state } = this.props.location
    // console.log(state)
    if (this.props.location.state) {
      // console.log(state)
      sessionStorage.setItem('itemCategoryID', state.item_category_id)
      sessionStorage.setItem('itemCategoryNAME', state.name)
      this.props.handleFetchDispatchDetails(state.name, state.item_category_id, state.plantId);
    }
  }

  handleOpenSnackClick = () => {
    this.setState({ snackOpen: true })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackOpen: false })
  };

  render() {
    const { classes, plants, plantLoader, dispatchLoader, isMobile } = this.props;
    return (this.props.Title !== 'Loading Advices') ? (
      <div style={{ width: isMobile ? '98%' : "100%" }}>
        {/* {
          // dispatchLoader &&
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={dispatchLoader}
            onClick={() => this.props.handleCloseBackDrop()}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        } */}
        <div style={{ width: "100%" }}>

          {/* <p className={classes.headingText}>{this.props.Title}</p> */}
          <Bread
            data={[
              {
                title: `${this.props.Title}`,
              },
            ]}
          />
          {plantLoader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )
            :
            (
              plants?.map((ele) => {
                if (ele.item_category?.length !== 0) return (
                  <div ref={this.divref} style={{ marginBottom: 15, }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          color: COLORS.grey,
                          fontSize: "1.2em",
                          fontWeight: "bold",
                        }}
                      >
                        {ele.plant_name}
                      </p>
                      <span
                        onClick={() =>
                          handlePrintPlantDetails(ele, this.props.user?.full_name, this.handleOpenSnackClick, "PRINT")
                        }
                      >
                        <Print style={{ margin: '6px 0 0 15px', color: COLORS.grey, cursor: "pointer" }} />
                      </span>
                      <SnackbarComponent
                        open={this.state.snackOpen}
                        handleClose={this.handleClose}
                        customAutoHideDuration={3000}
                        snackbarMessage="Downloading PDF..."
                      />
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {ele.item_category.map((data) => (
                        <Paper
                          elevation={2}
                          style={{
                            marginLeft: "10px",
                            marginBottom: "10px",
                            width: "375px",
                            opacity: this.state.dispatch_loader_name === (ele.plant_name + data.name) && (this.state.dispatchPlanningLoader || this.props.dispatchLoader) && 0.7
                          }}
                          disabled={this.state.dispatch_loader_name === (ele.plant_name + data.name) && (this.state.dispatchPlanningLoader || this.props.dispatchLoader) && true}
                          className={classes.paperStyle}
                          onClick={(e) => {
                            if (this.state.dispatch_loader_name === (ele.plant_name + data.name) && (this.state.dispatchPlanningLoader || this.props.dispatchLoader)) {
                              e.preventDefault()
                            }
                            else {
                              e.preventDefault();
                              sessionStorage.setItem('itemCategoryID', data.item_category_id)
                              sessionStorage.setItem('itemCategoryNAME', data.name)
                              this.props.handleFetchDispatchDetails(data.name, data.item_category_id, ele.id);
                              this.setState({ dispatchPlanningLoader: true, dispatch_loader_name: (ele.plant_name + data.name) })
                            }
                          }}
                        >
                          <div
                            className={classes.plantDetails}
                            style={{ height: '20vh', margin: "6px 12px 12px 6px", }}
                          >
                            <div style={{ display: 'flex', wordBreak: 'break-word', justifyContent: 'space-between' }}>
                              <div style={{ display: 'flex', }}>
                                <Typography
                                  style={{
                                    // paddingLeft: "10px",
                                    fontWeight: '400',
                                    // transform: 'scale(1.4,1.4)',
                                    fontSize: pxToEm(26)
                                  }}
                                  variant="subtitle1"
                                >
                                  {data.name}
                                </Typography>
                              </div>
                              <div style={{
                                display: this.state.dispatch_loader_name === (ele.plant_name + data.name) && (this.state.dispatchPlanningLoader || this.props.dispatchLoader) ? "flex" : 'none',
                              }}>
                                <CircularProgress style={{ height: 25, width: 25, color: primaryColor }} />
                              </div>
                            </div>
                            <div style={{ display: 'flex', height: '100%', marginTop: 10 }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  width: '40%',
                                  height: '100%',
                                  paddingTop: 10
                                }}
                              >
                                <Typography
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: '500',
                                    textAlign: 'center',
                                    fontSize: String(data.pending_qty).length > 7
                                      ? pxToEm(19)
                                      : pxToEm(24),
                                    transform: String(data.pending_qty).length > 7 &&
                                      'scale(1.1,1.3)'
                                  }}
                                >
                                  {data.pending_qty} MTS
                                </Typography>
                                <PopOverComponent
                                  classes={classes}
                                  fontWeight='600'
                                  fontSize={11}
                                  plantName={ele.plant_name}
                                  itemName={data.name}
                                  hoverText="Plant Planned Qty - Dispatched Qty"
                                >
                                  TOTAL PENDING QTY
                                </PopOverComponent>
                              </div>
                              <Divider orientation="vertical" style={{ width: 2 }} />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  width: '60%',
                                  height: '100%',
                                  paddingLeft: 10,
                                  paddingTop: 10
                                }}
                              >
                                <Typography
                                  style={{
                                    display: 'flex',
                                    fontSize: pxToEm(14),
                                    fontWeight: '600',
                                    color: grey,
                                    width: '100%',
                                    marginBottom: 5,
                                  }}
                                >
                                  {moment(new Date()).format('DD-MMM-YYYY')}
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end',
                                    width: '100%',
                                  }}
                                >
                                  <PopOverComponent
                                    classes={classes}
                                    fontWeight='500'
                                    fontSize={16}
                                    width='100%'
                                    plantName={ele.plant_name}
                                    itemName={data.name}
                                    hoverText="Total quantity marked as Ready today"
                                  >
                                    Ready Qty
                                  </PopOverComponent>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <span
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        fontSize: pxToEm(16),
                                        fontWeight: '600',
                                        lineHeight: '1.2'
                                      }}
                                    >
                                      {data.ready_qty}
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: '600',
                                        color: grey,
                                        fontSize: pxToEm(12),
                                        paddingLeft: 5,
                                      }}
                                    >
                                      MTS
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end',
                                    width: '100%',
                                  }}
                                >
                                  <PopOverComponent
                                    classes={classes}
                                    fontWeight='500'
                                    fontSize={16}
                                    width='100%'
                                    plantName={ele.plant_name}
                                    itemName={data.name}
                                    hoverText="Total quantity of today LA ( Pending to Billed )"
                                  >
                                    Running LA
                                  </PopOverComponent>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <span
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        fontSize: pxToEm(16),
                                        fontWeight: '600',
                                        lineHeight: '1.2'
                                        // width: '100%',
                                      }}
                                    >
                                      {data.running_qty}
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: '600',
                                        color: grey,
                                        fontSize: pxToEm(12),
                                        paddingLeft: 5,
                                      }}
                                    >
                                      MTS
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end',
                                    width: '100%',
                                  }}
                                >
                                  <PopOverComponent
                                    classes={classes}
                                    fontWeight='500'
                                    fontSize={16}
                                    width='100%'
                                    plantName={ele.plant_name}
                                    itemName={data.name}
                                    hoverText="Total quantity dispatched today"
                                  >
                                    Dispatched
                                  </PopOverComponent>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <span
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        fontSize: pxToEm(16),
                                        fontWeight: '600',
                                        lineHeight: '1.2'
                                        // width: '100%',
                                      }}
                                    >
                                      {data.dispatched_qty}
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: '600',
                                        color: grey,
                                        fontSize: pxToEm(12),
                                        paddingLeft: 5,
                                      }}
                                    >
                                      MTS
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Paper>
                      ))}
                    </div>
                  </div>
                )
              }
              )
            )
          }
          <p className={classes.headingText}>Plants with No Dispatch Planning</p>
          {plants?.map((ele) => {
            if (ele.item_category?.length === 0) return (
              <p
                style={{
                  color: COLORS.grey,
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              >
                {ele.plant_name}
              </p>
            )
          })}
        </div>
      </div>
    )
      :
      (<div style={{ width: "100%" }}>
        {/* <p
          className={classes.headingText}
          style={{
            color: COLORS.secondaryBlueText
          }}
        >{this.props.Title}</p> */}
        <Bread
          data={[
            {
              title: `${this.props.Title}`,
            },
          ]}
        />
        {plantLoader ? (
          <div
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexWrap: 'wrap'
            }}
          >
            {/* <DispatchSkeleton skeletonType="loading_advice_plant" /> */}
            <CircularProgress />
          </div>
        )
          :
          (
            <div style={{ display: "flex", flexWrap: 'wrap' }}>
              {console.log(isMobile)}
              {plants?.map((ele) => {
                if (ele.item_category?.length !== 0) return (
                  <div style={{ width: isMobile && "100%" }}>
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                      <Paper
                        elevation={1}
                        id={ele.plant_name}
                        disabled={this.state.loader_name === ele.plant_name && (this.state.loader || this.props.dispatchLoader) && true}
                        style={{
                          marginLeft: "10px",
                          marginBottom: "10px",
                          width: "385px",
                          opacity: this.state.loader_name === ele.plant_name && (this.state.loader || this.props.dispatchLoader) && 0.7
                        }}
                        className={classes.paperStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.loader_name === ele.plant_name && (this.state.loader || this.props.dispatchLoader)) {
                            e.preventDefault()
                          }
                          else {
                            this.props.handleFetchDispatchLADetails(moment(new Date()).format("YYYY-MM-DD"), ele.id);
                            this.props.handleFetchPlantWeighbridge(ele.id);
                            this.setState({ loader: true, loader_name: ele.plant_name })
                          }
                        }}
                      >
                        <div
                          className={classes.plantDetails}
                          style={{
                            margin: "6px 8px 12px 6px"
                          }}
                        >
                          <div style={{ display: 'flex', wordBreak: 'break-word', justifyContent: 'space-between' }}>
                            <div>
                              <Typography style={{ fontWeight: '700' }} variant="subtitle1">
                                {ele.plant_name}
                              </Typography>
                            </div>
                            <div style={{
                              display: this.state.loader_name === ele.plant_name && (this.state.loader || this.props.dispatchLoader) ? "flex" : 'none',
                              zIndex: 100,
                            }}>
                              <CircularProgress style={{ height: 25, width: 25, color: primaryColor }} />
                            </div>

                          </div>
                          <Divider variant='middle' style={{ margin: '2% 0' }} />
                          {/* <div style={{
                            display: this.state.loader_name === ele.plant_name && (this.state.loader || this.props.dispatchLoader) ? "flex" : 'none',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: "75%",
                            marginTop: "10px",
                            zIndex: 100,
                          }}>
                            <CircularProgress />
                          </div> */}
                          <div style={{ display: "flex", height: "75%", marginTop: "10px" }}>
                            <div className={classes.semiCircleMainContainer}>
                              <span style={{ color: "black", marginBottom: "10px", marginRight: '25px', fontWeight: '600', textTransform: 'uppercase' }}>
                                {moment(new Date()).format("MMM Do ")}
                              </span>
                              <SemiCircleProgressBar
                                percentage={ele.dispatch_details.dispatched_la_number / (ele.dispatch_details.total_la_number) * 100}
                                // percentage={(4 / 5) * 100}
                                style={{ width: "100%", color: "red" }}
                                diameter={60}
                                strokeWidth={4}
                                stroke={COLORS.secondaryBlueText}
                              />
                            </div>
                            <div className={classes.dispatchedDetailContainer} style={{ alignItems: "center", lineHeight: 1.8, justifyContent: 'center' }}>
                              {/* <span style={{ color: "black", fontWeight: '600', textTransform: 'uppercase' }}>
                                {moment(new Date()).format("MMM Do ")}
                              </span> */}
                              {/* <span className={classes.dispatchedTextStyle} style={{ fontWeight: '700', marginBottom: 22 }}>
                                {ele.dispatch_details.dispatched_la_number} / {ele.dispatch_details.total_la_number}   LA
                              </span> */}
                              <span className={classes.dispatchedTextStyle} style={{ fontWeight: '700', fontSize: pxToEm(12), }}>
                                <Stop
                                  style={{
                                    marginRight: "2px",
                                    // marginBottom: '10px',
                                    color: COLORS.lightGreen_1,
                                    fontWeight: '500',

                                  }}
                                />
                                Dispatched LA /
                                <Stop
                                  style={{
                                    marginRight: "2px",
                                    // marginBottom: '10px',
                                    color: COLORS.pink,
                                    fontWeight: '500'
                                  }}
                                />
                                Total LA
                              </span>
                              <span className={classes.dispatchedTextStyle} style={{ fontWeight: '700' }}>
                                {/* <Stop
                                  style={{
                                    marginRight: "2px",
                                    color: COLORS.lightGreen_1,
                                    fontWeight: '700'
                                  }}
                                /> */}
                                {ele.dispatch_details.dispatched_la_number} / {ele.dispatch_details.total_la_number}
                                {/* <Stop
                                  style={{
                                    marginRight: "2px",
                                    color: COLORS.pink,
                                    fontWeight: '700'
                                  }}
                                /> */}

                              </span>
                              {/* <span className={classes.dispatchedTextStyle}>
                                <Stop
                                  style={{
                                    marginRight: "2px",
                                    color: COLORS.pink,
                                  }}
                                />
                                {ele.dispatch_details.total_la_number}  (Total LA)
                              </span> */}
                            </div>
                          </div>
                        </div>

                      </Paper>
                    </div>
                  </div>
                )
              })}
            </div>)
        }
      </div>)
  }
}
export default withRouter(withStyles(styles)(DispatchPlanning));
