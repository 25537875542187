module.exports = {
  primaryColor: "#293C79",
  lightPrimaryColor: "#3D73DD",
  secondaryColor: "#4B87C5",
  secondaryDarkColor: "#3B69B0",
  white: "white",
  errorMessageColor: "#F58634",
  dailyRateMainDivColor: "#f7f9fd",
  dailyRateScreenTextColor: "#35425A",
  bottomNavigationFontColor: "#B6BCD1",
  bottomNavigationIconColor: "#8c98be",
  activeBottomNavigationIconColor: "#fdfcfd",
  darkGreyColor: "#979797",
  lightGreyColor: "#8B959A",
  webPrimaryColor: "#1B388B",
  logoBackground: "#FFF200",
  orangeColor: '#FFAE42',
  greenColor: '#4CAF50'
};
