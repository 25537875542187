import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  StepConnector,
  StepLabel,
  Step,
  Stepper,
  makeStyles,
  withStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      // borderColor: "#784af4",
      // borderColor: "#4caf50",
      borderColor: "#FFAE42",
    },
  },
  completed: {
    "& $line": {
      // borderColor: "#784af4",
      borderColor: "#4caf50",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
  dateDisplay: {
    display: "flex",
    alignItems: "center",
    placeContent: "center",
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    padding: 5,
  },
  active: {
    // color: "#784af4",
    color: "#FFAE42",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    // color: "#784af4",
    color: "#4caf50",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  if (props.laStatus === "LAPSED" || props.laStatus === "DISPATCHED") {
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <CloseIcon className={classes.completed} style={{ color: "#FF0000" }} />
        ) : (
          <div className={classes.circle} style={{ color: "#FF0000" }} />
        )}
      </div>
    )
  }
  else {

  }
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ?
        (props.laStatus === "LAPSED" || props.laStatus === "DISPATCHED") ?
          <CloseIcon className={classes.completed} style={{ color: "#FF0000" }} />
          : (
            <Check className={classes.completed} />
          ) : (
          <div className={classes.circle} />
        )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
function getCreatedAtDate(statusData, status) {
  let data =
    statusData.length > 0
      ? statusData.find((data) => data.loading_advice_status === status)
      : null;
  return !data ? null : moment(data.created_at).format("DD-MM-YYYY");
}
function getStatusDetails(laData, status) {
  let data =
    laData?.status_history.length > 0
      ? laData?.status_history.find(
        (data) => data.loading_advice_status === status
      )
      : null;
  let statusDetail =
    laData?.status_history.length > 0
      ? status === "CANCELLED"
        ? `${data?.status_remark || ""}`
        : status === "LAPSED"
          ? `${data?.status_remark || ""}`
          : status === "DISPATCHED"
            ? `Dispatch process completed at gate - ${laData?.gate_out_gate || ""}`
            : status === "BILLED"
              ? `${data?.status_remark || ""}`
              : status === "ALLOTED"
                ? `${data?.status_remark || ""}`
                : status === "PACKED"
                  ? `${data?.status_remark || ""}`
                  : status === "LOADING"
                    ? `Vehicle capacity is - ${laData?.truck_capacity || ""} MT`
                    : status === "GATEIN"
                      ? `Gate-In done from gate - ${laData?.gate_in_gate || ""}`
                      : status === "APPROVED"
                        ? `${laData?.is_customer_approval_needed
                          ? laData?.is_approved_by_customer === true ||
                            laData?.is_approved_by_admin === true
                            ? "Admin and Customer both approved"
                            : laData?.is_approved_by_customer === true ||
                              laData?.is_approved_by_admin === false
                              ? "Customer approved but Admin approval pending"
                              : laData?.is_approved_by_customer === false ||
                                laData?.is_approved_by_admin === true
                                ? "Admin approved but Customer approval pending"
                                : laData?.is_approved_by_customer === false ||
                                  laData?.is_approved_by_admin === false
                                  ? "Admin and Customer both approval pending"
                                  : ""
                          : laData?.is_approved_by_admin
                            ? "Admin approval done"
                            : "Admin approval pending"
                        }`
                        : status === "PENDING"
                          ? `LA created by ${data?.created_by?.first_name || ""} ${data?.created_by?.last_name || ""
                          } (${data?.created_by?.is_admin ? " Admin " : " Customer "})`
                          : ""
      : "";
  return statusDetail;
}
function getCurrrentActiveStep(statusData) {
  let activeStepValue =
    statusData.length > 0
      ? statusData.some((ele) => ele.loading_advice_status === "CANCELLED")
        ? 9
        : statusData.some((ele) => ele.loading_advice_status === "LAPSED")
          ? 8
          : statusData.some((ele) => ele.loading_advice_status === "DISPATCHED")
            ? 7
            : statusData.some((ele) => ele.loading_advice_status === "BILLED")
              ? 6
              : statusData.some((ele) => ele.loading_advice_status === "ALLOTED")
                ? 5
                : statusData.some((ele) => ele.loading_advice_status === "PACKED")
                  ? 4
                  : statusData.some((ele) => ele.loading_advice_status === "LOADING")
                    ? 3
                    : statusData.some((ele) => ele.loading_advice_status === "GATEIN")
                      ? 2
                      : statusData.some((ele) => ele.loading_advice_status === "APPROVED")
                        ? 1
                        : statusData.some((ele) => ele.loading_advice_status === "PENDING")
                          ? 0
                          : null
      : null;
  return activeStepValue;
}
function getSteps(props) {
  return [
    {
      status: "Pending",
      created_at: getCreatedAtDate(props?.laData?.status_history, "PENDING"),
      detail: getStatusDetails(props?.laData, "PENDING"),
    },
    {
      status: "Approved",
      created_at: getCreatedAtDate(props?.laData?.status_history, "APPROVED"),
      detail: getStatusDetails(props?.laData, "APPROVED"),
    },
    {
      status: "Gate-In",
      created_at: getCreatedAtDate(props?.laData?.status_history, "GATEIN"),
      detail: getStatusDetails(props?.laData, "GATEIN"),
    },
    {
      status: "Loading",
      created_at: getCreatedAtDate(props?.laData?.status_history, "LOADING"),
      detail: getStatusDetails(props?.laData, "LOADING"),
    },
    {
      status: "Packed",
      created_at: getCreatedAtDate(props?.laData?.status_history, "PACKED"),
      detail: getStatusDetails(props?.laData, "PACKED"),
    },
    {
      status: "Alloted",
      created_at: getCreatedAtDate(props?.laData?.status_history, "ALLOTED"),
      detail: getStatusDetails(props?.laData, "ALLOTED"),
    },
    {
      status: "Billed",
      created_at: getCreatedAtDate(props?.laData?.status_history, "BILLED"),
      detail: getStatusDetails(props?.laData, "BILLED"),
    },
    {
      status: "Dispatched",
      created_at: getCreatedAtDate(props?.laData?.status_history, "DISPATCHED"),
      detail: getStatusDetails(props?.laData, "DISPATCHED"),
    },
    {
      status: "Lapsed",
      created_at: getCreatedAtDate(props?.laData?.status_history, "LAPSED"),
      detail: getStatusDetails(props?.laData, "LAPSED"),
    },
    {
      status: "Cancelled",
      created_at: getCreatedAtDate(props?.laData?.status_history, "CANCELLED"),
      detail: getStatusDetails(props?.laData, "CANCELLED"),
    },
  ];
}

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  // const [activeStep, setActiveStep] = React.useState(
  //   getCurrrentActiveStep(props?.laData?.status_history)
  // );
  const [activeStep, setActiveStep] = useState();
  const [laStatus, setLAStatus] = useState(props.laStatus)
  const steps = getSteps(props);

  useEffect(() => {
    getStatusInNumber(laStatus)
  }, [laStatus])


  function getStatusInNumber(statusString) {
    return (
      statusString.length > 0 ?
        statusString === 'PENDING' ? setActiveStep(1) :
          statusString === 'APPROVED' ? setActiveStep(2) :
            statusString === 'GATEIN' ? setActiveStep(3) :
              statusString === 'LOADING' ? setActiveStep(4) :
                statusString === 'PACKED' ? setActiveStep(5) :
                  statusString === 'ALLOTED' ? setActiveStep(6) :
                    statusString === 'BILLED' ? setActiveStep(7) :
                      statusString === 'DISPATCHED' ? setActiveStep(8) : null
        : null
    )
  }

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((data, index) => (
          <HtmlTooltip
            arrow
            title={
              <React.Fragment>
                <Typography color="inherit">{data.detail}</Typography>
              </React.Fragment>
            }
          >
            <Step className={classes.dateDisplay} key={index}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                {data.status}
              </StepLabel>
              <Typography variant="caption" id={"dateDisplay" + index}>
                {data.created_at}
              </Typography>
            </Step>
          </HtmlTooltip>
        ))}
      </Stepper>
    </div>
  );
}
