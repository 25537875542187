import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
  Tooltip,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  grey,
  secondaryBlueText,
  drawerTextColor,
  successButtonBackground,
} from "../../../../constants/internal/colors";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles({
  formControl: {
    width: "65%",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  pincode: {
    width: "65%",
    // background: theme.palette.secondary.main,
    border: `1px solid white`,
    flex: 1,
    padding: '8px',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});
const CustomCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
      defaultChecked
    />
  );
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
export default function ManageCustomerAdditionalAddressesDisplay(props) {
  //console.log(props)
  const [customerAddressBox, setCustomerAddressBox] = React.useState(
    props.customerAddressBox
  );
  React.useEffect(() => {
    setCustomerAddressBox(props.customerAddressBox);
  }, [props?.customerAddressBox]);
  const classes = useStyles();


  const pincodeHasError = (customerAddressData) => {
    if (!props.isDirty) {
      return false;
    }

    if ((customerAddressData.pincode == "" || customerAddressData.pincode == null)) {
      return true;
    }

    if (props.customerDontHaveGSTIN) {
      //this.state.customerOperation === "add"
      if (props.customerOperation === "add") {
        return customerAddressBox?.length > 1 && [...new Set(customerAddressBox.map(c => (c.state)))]?.length != 1
      } else {
        return props.selectedAddressState !== props.customerAddressBox[0]?.state_detail?.id
      }
    } else if (!props.customerDontHaveGSTIN) {
      console.log(props.stateCodeFromResponse !== props.gstinNumberOfCustomer)
      return props.stateCodeFromResponse !== props.gstinNumberOfCustomer;
    }


  }

  // const handlePincodeChange = (addressIndex, customerAddressData) => {
  //   //setIsDirty(true)
  //   props.handleCustomerAddressBoxValueChange(
  //     addressIndex,
  //     "pincode",
  //     customerAddressData,
  //   )
  // }

  return customerAddressBox.length > 0
    ? customerAddressBox.map((customerAddressData, addressIndex) => (
      <div className={classes.dataDisplay}>
        <div className={classes.titileLabelDataFields}>
          <Typography className={classes.titleLabelStyle}>
            ADDRESS BOX {Number(addressIndex) + 1}
          </Typography>
          <div>
            {(!customerAddressData.operation ||
              customerAddressData.operation === "view") && props.showCustomerAddressEditIcon ? (
              <ToolTipComponent
                title="You have no edit permission on this widget"
                condition={!hasUpdateAccess(props.user, "customerManagement")}
                title2={`Edit Address Box ${Number(addressIndex) + 1}`}
                condition2={hasUpdateAccess(props.user, "customerManagement")}
              >
                <IconButton
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.handleIsDirty(false)
                    props.handleUpdateAddressBoxOperation(
                      addressIndex,
                      "edit"
                    )
                  }

                  }
                  disabled={
                    hasUpdateAccess(props.user, "customerManagement")
                      ? false
                      : true
                  }
                >
                  <EditOutlinedIcon />
                </IconButton>
              </ToolTipComponent>
            ) : null}
            {props?.customerOperation === "add" ? (
              <ToolTipComponent
                title="You have no edit permission on this widget"
                condition={!hasUpdateAccess(props.user, "customerManagement")}
                title2={`Delete Address Box ${Number(addressIndex) + 1}`}
                condition2={hasUpdateAccess(props.user, "customerManagement")}
              >
                <IconButton
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.removeNewCustomerAddressBox(
                      addressIndex,
                      customerAddressData
                    )
                  }
                  disabled={
                    hasUpdateAccess(props.user, "customerManagement")
                      ? false
                      : true
                  }
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </ToolTipComponent>
            ) : null}
          </div>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>
            DEFAULT STATUS
          </Typography>
          <div className={classes.dataFieldsForCheckboxCustomerAddress}>
            <CustomCheckbox
              disabled={
                !customerAddressData.operation ||
                  customerAddressData.operation === "view"
                  // || customerAddressData.is_default_address
                  ? true
                  : customerAddressBox.length == 1 ? true : false
              }
              checked={customerAddressData.is_default_address}
              onChange={props.handleCustomerAddressBoxValueChange(
                addressIndex,
                "is_default_address",
                customerAddressData,
              )}
              name="is_default_address"
            />

            <Typography className={classes.generalText}>
              Default Address
            </Typography>
          </div>
        </div>
        <div className={classes.dataFields}>

          <Typography className={classes.titleLabel}>
            ACTIVE STATUS
          </Typography>
          <div className={classes.dataFieldsForCheckboxCustomerAddress}>
            <IOSSwitch
              disabled={
                !customerAddressData.operation ||
                  customerAddressData.operation === "view"
                  ? true
                  : false
              }
              checked={customerAddressData.is_active}
              // defaultChecked
              onChange={props.handleCustomerAddressBoxValueChange(
                addressIndex,
                "is_active",
                customerAddressData,
              )}
              name="is_active"
            />
          </div>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>ADDRESS LINE*</Typography>
          {!customerAddressData.operation ||
            customerAddressData.operation === "view" ? (
            <Typography >
              {customerAddressData.address}
            </Typography>
          ) : (
            <TextField
              type="text"
              autoComplete="off"
              className={classes.formControl}
              label="Address line"
              value={customerAddressData.address || ""}
              variant="outlined"
              onChange={props.handleCustomerAddressBoxValueChange(
                addressIndex,
                "address",
                customerAddressData,
              )}
              size="small"
            />
          )}
        </div>

        < div className={classes.dataFields} >
          <Typography className={classes.titleLabel}>PIN CODE*</Typography>
          {!customerAddressData.operation ||
            customerAddressData.operation === "view" ? (
            <Typography >
              {customerAddressData.pincode}
            </Typography>
          ) : (
            <>
              <TextField
                // type="number"
                onKeyPress={
                  (event) => {
                    if (event.charCode >= 48 && event.charCode <= 57) {

                    } else {
                      event.preventDefault()
                    }
                    if (!props.isDirty) {
                      props.handleIsDirty(true)
                    }
                  }
                }
                autoComplete="off"
                className={classes.formControl}
                label="Pin code"
                id="outlined-size-small"
                value={customerAddressData.pincode || ""}
                variant="outlined"
                onChange={props.handleCustomerAddressBoxValueChange(
                  addressIndex,
                  "pincode",
                  customerAddressData,
                )}
                error={props.customerOperation != "add" ? pincodeHasError(customerAddressData) : props.pincodeErrors[addressIndex]}
                helperText={props.customerOperation != "add" ?
                  pincodeHasError(customerAddressData) ? "Same state pincode is only allowed" : "" || ""
                  : props.pincodeErrors[addressIndex] ? "Same state pincode is only allowed" : "" || ""
                }
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {
                        props.showPincodeRefreshButton ?
                          <IconButton
                            onClick={() => props.handleAddressRefreshButtonClick(addressIndex)

                            }
                          >
                            <RefreshIcon />


                          </IconButton>
                          : ""
                      }
                    </InputAdornment>


                  )
                }}

              /></>)}
        </div >

        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>CITY NAME*</Typography>
          {
            // customerAddressData.operation === "add" ?

            //   props.citiesAndStatesData && props.citiesAndStatesData.length > 0 ?
            //     <FormControl
            //       variant="outlined"
            //       className={classes.formControl}
            //       size="small"
            //     >
            //       <InputLabel id="city_label">Select City</InputLabel>
            //       <Select
            //         // disabled={
            //         //   !customerAddressData.operation ||
            //         //     customerAddressData.operation === "view"
            //         //     ? true
            //         //     : false
            //         // }
            //         labelId="city_label"
            //         value={
            //           customerAddressData.city ?
            //             customerAddressData.city :
            //             customerAddressData?.city_detail?.id
            //               ? customerAddressData.city_detail.id
            //               : ""

            //         }
            //         onChange={props.handleCustomerAddressBoxValueChange(
            //           addressIndex,
            //           "city"
            //         )}
            //         label="Select City"
            //         MenuProps={MenuProps}
            //       >
            //         <MenuItem value={"none"} disabled>
            //           Select
            //         </MenuItem>

            //         {
            //           props.citiesAndStatesData.map((cityData, key) => (
            //             <MenuItem value={cityData.id}>
            //               {cityData.name}

            //             </MenuItem>
            //           ))
            //         }
            //       </Select>
            //     </FormControl> : null :
            !customerAddressData.operation || customerAddressData.operation === "view" ?

              <Typography >
                {customerAddressData?.city_detail?.name}
              </Typography>



              :
              // customerAddressData.operation === "edit" ?
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel id="city_label">Select City</InputLabel>
                <Select
                  disabled={true}
                  labelId="city_label"
                  value={
                    props?.customerOperation === "add" ? customerAddressData.city :
                      props.selectedAddressCity
                    // props.customerOperation !== "add" ?
                    //   props.selectedAddressCity !== "" ?
                    //     props.selectedAddressCity :
                    //     customerAddressData.city !== "" ?
                    //       customerAddressData.city :

                    //       customerAddressData?.city_detail?.id
                    //         ? customerAddressData.city_detail.id


                    //         : "" : ""

                  }
                  onChange={props.handleCustomerAddressBoxValueChange(
                    addressIndex,
                    "city",
                    customerAddressData,
                  )}
                  label="Select City"
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"none"} disabled>
                    Select
                  </MenuItem>

                  {props.citiesAndStatesData.length > 0 ?
                    // props.citiesAndStatesData.length === 1 ?
                    //   props.citiesAndStatesData
                    //     .map((cityData, key) => (
                    //       <MenuItem value={cityData.id}>
                    //         {cityData.name}
                    //         {/* {console.log(cityData)} */}
                    //       </MenuItem>
                    //     ))
                    //   : 
                    props.citiesAndStatesData.map((array) =>
                      array.map((cityData, key) => (
                        <MenuItem value={cityData.id}>
                          {cityData.name}
                          {/* {console.log(cityData)} */}
                        </MenuItem>
                      ))
                    ) :
                    <MenuItem value={"none"} disabled>
                      Select
                    </MenuItem>
                    //   : <MenuItem value={"none"} disabled>
                    //   Select
                    // </MenuItem>
                  }
                </Select>
              </FormControl>
            // : null
          }
        </div>

        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>STATE NAME*</Typography>
          {

            !customerAddressData.operation || customerAddressData.operation === "view" ?


              <Typography >
                {customerAddressData.state_detail.name}
              </Typography>


              :
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel id="state_label">Select State</InputLabel>
                <Select
                  disabled={true}
                  value={
                    props?.customerOperation !== "add" ?
                      props.selectedAddressState : customerAddressData.state


                    // customerAddressData?.state_detail?.id
                    //   ? customerAddressData.state_detail.id
                    //   :
                    // props.selectedAddressState !== "" ?
                    //   props.selectedAddressState :


                  }
                  onChange={props.handleCustomerAddressBoxValueChange(
                    addressIndex,
                    "state",
                    customerAddressData,
                  )}
                  labelId="state_label"
                  label="Select State"
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"none"} disabled>
                    Select
                  </MenuItem>
                  {
                    props.selectedCountryAllStates.length > 0
                      ? props.selectedCountryAllStates.map(
                        (allStatesList, stateIndex) => (
                          <MenuItem value={allStatesList.id}>
                            {allStatesList.name}
                          </MenuItem>
                        )
                      )
                      : null}
                </Select>
              </FormControl>
          }
        </div>

        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>COUNTRY NAME*</Typography>
          {

            !customerAddressData.operation || customerAddressData.operation === "view" ?

              <Typography >
                {"India"}
              </Typography>



              :
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel id="state_label">Select Country</InputLabel>
                <Select
                  disabled={true}
                  value={props.customerSelectedCountryId
                    // customerAddressData?.country ?
                    //   customerAddressData?.state :
                    //   props.selectedAddressState !== "" ?
                    //     props.selectedAddressState :
                    //     customerAddressData?.state_detail?.id
                    //       ? customerAddressData.state_detail.id
                    //       : ""

                  }
                  onChange={props.handleCustomerAddressBoxValueChange(
                    addressIndex,
                    "country",
                    customerAddressData,
                  )}
                  labelId="state_label"
                  label="Select State"
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"none"} disabled>
                    Select
                  </MenuItem>

                  {
                    props.country_codes.map((country) =>
                      <MenuItem value={country.id}>
                        {country.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
          }
        </div>





        {/* {(
          props.selectedCountryAllStates.length > 0) ||
          props.customerOperation === "add" ? (
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>STATE NAME</Typography>

            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small"
            >
              <InputLabel id="state_label">Select State</InputLabel>
              <Select
                disabled={
                  !customerAddressData.operation ||
                    customerAddressData.operation === "view"
                    ? true
                    : false
                }
                value={
                  !customerAddressData.operation ||
                    customerAddressData.operation === "view"
                    ? customerAddressData.state_detail.id
                      ? customerAddressData.state_detail.id
                      : ""
                    : customerAddressData.state
                      ? customerAddressData.state
                      : customerAddressData?.state_detail?.id || ""
                }
                onChange={props.handleCustomerAddressBoxValueChange(
                  addressIndex,
                  "state"
                )}
                labelId="state_label"
                label="Select State"
                MenuProps={MenuProps}
              >
                <MenuItem value={"none"} disabled>
                  Select
                </MenuItem>
                {props.selectedCountryAllStates &&
                  props.selectedCountryAllStates.length > 0
                  ? props.selectedCountryAllStates.map(
                    (allStatesList, stateIndex) => (
                      <MenuItem value={allStatesList.id}>
                        {allStatesList.name}
                      </MenuItem>
                    )
                  )
                  : null}
              </Select>
            </FormControl>
          </div>
        ) : !customerAddressData.operation ||
          customerAddressData.operation === "view" ?
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>STATE NAME</Typography>
            <Typography className={classes.formControl}>
              {customerAddressData.state_detail.name}
            </Typography>
          </div> : null
        } */}
        {/* {props.citiesAndStatesData && props.citiesAndStatesData.size > 0 ? (
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>CITY NAME</Typography> */}


        {/* <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>DISTRICT</Typography>
            {!customerAddressData.operation ||
            customerAddressData.operation === "view" ? (
              <Typography className={classes.formControl}>
                {customerAddressData.district}
              </Typography>
            ) : (
              <>
                <TextField
                  type="text"
                  autoComplete="off"
                  className={classes.formControl}
                  label="DISTRICT"
                  id="outlined-size-small"
                  value={customerAddressData.district || ""}
                  variant="outlined"
                  onChange={props.handleCustomerAddressBoxValueChange(
                    addressIndex,
                    "district"
                  )}
                  />
                  size="small"
              </>
            )}
          </div> */}

        {
          customerAddressData.D_sr_no && (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ERP SLNO (D)</Typography>
              <Typography >
                {customerAddressData.D_sr_no}
              </Typography>
            </div>
          )
        }

        {
          customerAddressData.G_sr_no && (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ERP SLNO (G)</Typography>
              <Typography >
                {customerAddressData.G_sr_no}
              </Typography>
            </div>
          )
        }

        {
          customerAddressData.K_sr_no && (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ERP SLNO (K)</Typography>
              <Typography >
                {customerAddressData.K_sr_no}
              </Typography>
            </div>
          )
        }
        {
          props.customerOperation === "add" ||
            !customerAddressData.operation ||
            customerAddressData.operation === "view" ? null : (
            <div className={classes.titileLabelDataFields} style={{ marginTop: 5 }}>
              <Button
                size="medium"
                className={classes.saveButton}
                variant="contained"
                startIcon={!props?.saveLoader && <CheckOutlinedIcon />}
                disabled={
                  (props.customerAddressBox?.length > 1 && props.customerDontHaveGSTIN) &&
                  ((customerAddressData.address == "" || customerAddressData.address == null)
                    || ((!props.customerDontHaveGSTIN && props.stateCodeFromResponse !== props.gstinNumberOfCustomer && props.isDirty)
                      || props.customerDontHaveGSTIN && props.selectedAddressState !== props.customerAddressBox[0]?.state_detail?.id))}
                onClick={(e) => {
                  e.preventDefault();
                  props.handleSaveCustomerAddresses(customerAddressData);
                }}
              >
                {
                  props?.saveLoader ?
                    <div style={{
                      width: "60px",
                      display: "flex", justifyContent: "center"
                    }}>
                      <CircularProgress size={20} color="#FFFFFF" />
                    </div>
                    : "SAVE"
                }

              </Button>
              <Button
                size="medium"
                className={classes.margin}
                onClick={(e) => {
                  e.preventDefault();
                  customerAddressData.operation === "add"
                    ? props.removeNewCustomerAddressBox(
                      addressIndex,
                      customerAddressData
                    )
                    : props.handleUpdateAddressBoxOperation(
                      addressIndex,
                      "view"
                    );
                }}
              >
                CANCEL
              </Button>
            </div>
          )
        }
      </div >
    ))
    : null;
}
