import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Select, FormControl, MenuItem } from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { checkApiError, pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridTemplateRows: "repeat(1, 1fr)",
    margin: "20px"
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    textAlign: "center"
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "100%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "50%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});



function ManageTcsMatrixData(props) {
  const initialData = [{
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "NO",
    declaration: "NO",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "NO",
    declaration: "NO",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "NO",
    declaration: "YES",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "NO",
    declaration: "YES",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "YES",
    declaration: "NO",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "YES",
    declaration: "NO",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "YES",
    declaration: "YES",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "NO",
    specified_person: "YES",
    declaration: "YES",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "NO",
    declaration: "NO",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "NO",
    declaration: "NO",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "NO",
    declaration: "YES",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "NO",
    declaration: "YES",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "YES",
    declaration: "NO",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "YES",
    declaration: "NO",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "YES",
    declaration: "YES",
    buyer_turnover_gt_10cr: "NO",
    tcs_rate: "",
    item_nature: props.match.params.id
  },
  {
    buyer_payment_gt_50l_fy: "YES",
    specified_person: "YES",
    declaration: "YES",
    buyer_turnover_gt_10cr: "YES",
    tcs_rate: "",
    item_nature: props.match.params.id
  },]
  const classes = useStyles();
  const [tcsMatrixData, setTcsMatrixData] = React.useState(
    initialData
  );
  //props.selectedTcsData ||  
  const [tcsOperation, setTcsOperation] = React.useState(props.tcsOperation);
  const [tcsRatesSelectList, setTcsRatesSelectList] = React.useState([])
  const [tcsRates, setTcsRates] = React.useState([]);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [
    removeConfirmationDialogData,
    setRemoveConfirmationDialogData,
  ] = React.useState({
    openDialog: false,
    dialogTitle: ``,
    dialogContent: "",
  });

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleChange = (event, location) => {
    const { value, name } = event.target;
    setTcsRatesSelectList(prevValue => [...prevValue, value])
    setTcsMatrixData(
      (prevState => (prevState.map(
        (obj, i) => (i === location ? Object.assign(obj, { tcs_rate: value }) : obj)
      )))
    );
    console.log(tcsMatrixData);
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };

  const handleEditSaveButtonClick = () => {

    if (tcsOperation === "edit") {
      props
        .handleTcsMatrixMasterDataUpdate(tcsMatrixData, 123, props.match.params.id)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `TCS Matrix Updated Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => props.history.goBack(), 3000)
            }
            // props.history.goBack();
          } else {
            handleOpenSnackbar(
              `Error occured while updating tcs matrix details please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
        });
    } else if (tcsOperation === "add") {
      props
        .handleTcsMatrixMasterDataAdd(tcsMatrixData)
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `TCS Matrix Created Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() =>
                props.history.push(`${INTERNALROUTES.TCSMATRIX}/${props.match.params.id}`)
                , 3000)
            }
            // props.history.push(`${INTERNALROUTES.TCSMATRIX}/${props.match.params.id}`);
          } else {
            handleOpenSnackbar(
              `Error occured while creating tcs matrix please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
        });
    } else {
      console.log("Invalid operation");
    }
  };
  React.useEffect(() => {
    if (props?.selectedTcsData.length > 0) {
      setTcsMatrixData(props?.selectedTcsData);
    }
    setTcsOperation(props.tcsOperation);
    props.getTcsRates().then((response) => {
      if (response.status === 200) {
        setTcsRates(response.data)
        // console.log(tcsRates);
      } else {
        handleOpenSnackbar(
          `Error occured while fetching Tcs Matrix details please try again`,
          "error"
        );
      }
    })
      .catch((error) => {
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000);
      });
  }, [props?.selectedTcsData, props.tcsOperation]);
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "TCS Matrix",
                onClick: () => props.history.push(INTERNALROUTES.TCSMATRIX),
              },
              {
                title: props.match.params.id,
                onClick: () => props.history.push(INTERNALROUTES.TCSMATRIX + "/" + props.match.params.id),
              },
              {
                title: tcsOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                BUYER PAYMENT &gt; 50 L
              </Typography>
              <Typography className={classes.titleLabel}>
                SPECIFIED
              </Typography>
              <Typography className={classes.titleLabel}>
                DECLARATION
              </Typography>
              <Typography className={classes.titleLabel}>
                TO &gt; 10 CRORES
              </Typography>
              <Typography className={classes.titleLabel}>
                TCS RATE
              </Typography>
            </div>
            {tcsMatrixData?.map((data, i) => (
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  {data.buyer_payment_gt_50l_fy || ""}
                </Typography>
                <Typography className={classes.titleLabel}>
                  {data.specified_person || ""}
                </Typography>
                <Typography className={classes.titleLabel}>
                  {data.declaration || ""}
                </Typography>
                <Typography className={classes.titleLabel}>
                  {data.buyer_turnover_gt_10cr || ""}
                </Typography>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tcsRates?.find(element => element.id == data.tcs_rate)?.id || ""}
                    onChange={(e) => handleChange(e, i)}
                    name="tcs_rate"
                  >
                    {tcsRates?.map(ele => (
                      <MenuItem value={ele.id}>
                        {ele.tcs_percent}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ))}
            <Button
              size="medium"
              disabled={
                tcsOperation === "add" ?
                  tcsRatesSelectList.length < 16 ? true
                    :
                    false
                  :
                  false}
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
            >
              {tcsOperation === "add" ? "SAVE" : "UPDATE"}
            </Button>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
      {/* <RemoveConfirmationDialog
        open={removeConfirmationDialogData.openDialog}
        dialogTitle={removeConfirmationDialogData.dialogTitle}
        dialogContent={removeConfirmationDialogData.dialogContent}
        removeDataLoader={removePlantItemMasterLoader}
        handleClose={handleRemoveConfirmationDialogClose}
        handleConfirmationDialogDeleteClick={
          handleConfirmationDialogDeleteClick
        }
      /> */}
    </Layout>
  );
}
export default withRouter(ManageTcsMatrixData);
