import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Bread from "../../common/Breadcrubs"
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import { isAccessibleModule, pxToEm } from '../../../../methods';
import PasswordExpiry from './PasswordExpiry';
import { primaryColor } from '../../../../styles/colors';
import Moment from 'moment'
import { secondaryGrey } from '../../../../constants/internal/colors';
import TodaysUpdateDisplay from './TodaysUpdateDisplay';
import MainTabsDisplay from './MainTabsDisplay';
import ComingSoon from "../../../common/CoomingSoon"
import AccessDenied from "../../../common/AccessDenied"
import { Paper } from '@material-ui/core';

const dashboardStyle = makeStyles((theme) => ({
  paper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    backgroundColor: '#f8f8f8',
    justifyContent: 'space-between'
  },
  upperContainer: {
    display: 'flex',
    width: '100%',
    padding: '0 0 2% 0',
    justifyContent: 'flex-start'
  },
  lowerContainer: {
    display: 'flex',
    width: '100%',
    padding: '0',
    justifyContent: 'flex-start'
  },
  title: {
    fontWeight: '500',
    color: '#333',
    padding: '10px 0',
    margin: '5px 0 0 10px',
    fontSize: pxToEm(24),
  },
}));




function DashboardWeb(props) {

  const [date, setDate] = useState(new Date())
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = dashboardStyle();
  return (
    <Layout {...props}>
      <div style={{ minWidth: "98%", marginRight: 30 }}>
        <Bread

          data={[
            {
              title: "Dashboard",
              style: {
                fontSize: pxToEm(32),
                color: primaryColor,
                fontWeight: 550
              },
            }]}
        />
        {/* <div style={{ fontSize: pxToEm(32) }}>{new Date()}</div> */}
        <div style={{ color: secondaryGrey, fontSize: pxToEm(16) }}>{Moment(date.toISOString()).format("DD-MMM-YY")}</div>

        <div style={{ color: "#707070", marginTop: "2%", fontWeight: 500 }}>Today's Update</div>
        <TodaysUpdateDisplay value={value} handleChange={handleChange} />
        {isAccessibleModule(props?.user, 'Dashboard') && <MainTabsDisplay value={value} handleChange={handleChange} />}



        <PasswordExpiry {...props} />
      </div>
    </Layout>
  )
}

export default DashboardWeb;