export let READYQTYMODEHeader = [
  { label: "Date", key: "date" },
  { label: "Customer", key: "first_name" },
  { label: "Customer Code", key: "customer_id" },
  { label: "Open Qty", key: "open_quantity.open_quantity" },
  { label: "Today Open Qty", key: "today_open_quantity" },
  { label: "Ready Qty", key: "ready_quantity" },
  { label: "Today LA Qty", key: "loading_advice_pending.pending_quantity" },
  { label: "Today LA", key: "loading_advice_pending.total_la" },
  { label: "Appproved LA Qty", key: "loading_advice_approved.approved_quantity" },
  { label: "Appproved LA ", key: "loading_advice_approved.total_la" },
  { label: "Inplant LA Qty", key: "loading_advice_inplant.in_plant_quantity" },
  { label: "Inplant LA ", key: "loading_advice_inplant.total_la" },
  { label: "Dispatched LA Qty", key: "dispatched_quantity.dispatch_quantity" },
  { label: "Dispatched LA ", key: "dispatched_quantity.total_la" },
]

export let DISPATCHMODEHeader = [
  { label: "Date", key: "date" },
  { label: "Contract #", key: "order_number" },
  { label: "Created At", key: "created_at" },
  { label: "Customer", key: "first_name" },
  { label: "Today LA", key: "loading_advices_length" },
  { label: "LA (Approved)", key: "loading_advice_approved" },
  { label: "LA (IN-Plant)", key: "loading_advice_inplant" },
  { label: "Dispatched", key: "gate_out" },
  { label: "Open Qty", key: "total_pending" },
]

export let plantItemTableHeaders = (mode) => {
  if (mode === 'ready_qty_mode')
    return READYQTYMODEHeader
  else if (mode === 'dispatch_mode')
    return DISPATCHMODEHeader
}