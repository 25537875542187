import React, { Component } from "react";
import DispatchOperation, {
  DispatchOperationWeb,
  DispatchOperationsGateWeb,
  DispatchOperationWeighbridgeWeb,
  DispatchCounterWeb,
  DispatchCounterDetailsWeb,
  DispatchCounterInvoicingWeb,
  DispatchCounterInvoiceDownloadWeb,
} from "../../../components/internal/dispatchOperation";
import { Route } from "react-router-dom";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import upiqr from "upiqr";
import moment from "moment";
import SnackbarComponent from '../../../components/internal/common/SnackbarComponent';
import { isAccessibleModule, isAccessibleWidget, arraySortBykeyAllBrowser } from "../../../methods";
import { debounce, indexOf } from "lodash";


class DispatchOperationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laDetails: null,
      laLoader: false,
      laDetailsInWeighbridge: null,
      laWeighbridgeLoader: false,
      gatesDetail: [],
      weighbridgeDetails: [],
      laDetailsInDispatchCounter: null,
      laDispatchCounterLoader: false,
      dispatchCounterDetails: [],
      dispatchCounterLoader: false,
      invoiceDownloadLoader: true,
      plantName: "",
      dcName: "",
      laNumber: "",
      //invoiceType: "Invoice",
      eInvoiceResponse: null,
      eWayBillResponse: null,
      eWayBillSelected: false,
      eWayOptionSelected: false,
      eWayBill: [{ name: "Yes" }, { name: "No" }],
      invoiceMethod: [{ name: "Normal Invoice" }, { name: "Combined Invoice" }],
      invoiceType: "",
      iseWaySelect: false,
      einvoiveErrorMessage: null,
      errorWhilePostForInvoice: false,
      b2CQrImage: null,
      transporterDistance: 0.0,
      errorMessage: null,
      availableStock: 0.0,
      stockAndPayment: {},
      stockError: "",
      paymentError: "",
      snackbarMessage: "",
      snackbarType: "",
      openSnackbar: false,
      e_invoice: null,
      eway: null,
      normal_invoice: null,
      isApiCalled: false,
      reasonMaster: "",
      actualDistance: null,
    };
  }
  // componentWillUnmount() {
  //   this.setState({
  //     eway: null,
  //     e_invoice: null,
  //     normal_invoice: null
  //   })
  // }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  handleSelectEnvoiceType = (type) => {
    this.setState({ invoiceType: type });
  };
  handleSelectEway = (type) => {
    this.setState({ iseWaySelect: type === "Yes" ? true : false });
    this.setState({ eWayOptionSelected: true })
  };

  handleSnackbarClose = () => {
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: ""
    })
  }

  handleResetData = () => {
    this.setState({
      errorWhilePostForInvoice: false,
      einvoiveErrorMessage: null,
      stockError: "",
      paymentError: "",
      eWayOptionSelected: false,
      invoiceType: ""
    });
  };

  componentDidMount() {
    // Can get Module and Widget's keyCode detail from README.md
    if (isAccessibleModule(this.props.user, "Dispatch Operations")) {
      this.fetchReasonGateOut("Dispatch Operations")
      // isAccessibleModule(user, moduleName)
      if (isAccessibleWidget(this.props.user, "gate")) this.handelFetchGates();
      // isAccessibleWidget(user, keyCode)
      if (isAccessibleWidget(this.props.user, "wb"))
        this.handleFetchWeighbridge();
      if (isAccessibleWidget(this.props.user, "dc"))
        this.handleFetchDispatchCounter();
    }
    document.addEventListener("keydown", (event) => {
      if (event.keyCode == 13 || event.keyCode == 17) event.preventDefault();
    });
    this.getAllStatesList()
  }

  handleActualDistance = (data) => {
    this.setState({ actualDistance: data !== '' ? Number(data) : data }, console.log(this.state?.actualDistance))
  }

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.name === 'laNumber'
        ? (event.target.value).trim()
        : event.target.value
    });
  };
  handleRemoveListener = () => {
    // console.log("Remove");
  };
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleRemoveListener());
  }
  handleSetPlantName = (plant, dc) => {
    this.setState({ plantName: plant, dcName: dc });
  };

  getAllStatesList = (countryId) => {
    axios.get(`${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}`,
      this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            allStateList: responseData,
            // allStateListDataFetchLoader: false,
          });
          // console.log("STATE LIST" + this.state.allStateList)
          // for (let key in responseData) {
          //   console.log(key + ":", responseData[key]);
          // }
        },
        (error) => {
          console.log("error", error);
          // this.setState({ allStateListDataFetchLoader: false, });
        }
      );

  };

  handleFetchLoadingAdvice = (laNumber) => {
    this.setState({ laLoader: true, errorMessage: null }, () => {
      axios
        .get(`${APIROUTES.GET_LA_DETAILS}${laNumber}/`)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              laDetails: response.data,
              laLoader: false,
              errorMessage: null,
            });
          } else {
            this.setState({
              laDetails: null,
              laLoader: false,
              errorMessage: null,
            });
          }
        })
        .catch((err) => {
          this.setState({
            laLoader: false,
            errorMessage: err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.details
                ? err?.response?.data?.details
                : "Something went wrong",
          });
        });
    });
  };

  fetchReasonGateOut = async (moduleName) => {
    // console.log("here kk", moduleName)
    return axios.get(
      `${APIROUTES.GET_REASON}?module=${moduleName}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          // console.log("reasons", response?.data)
          this.setState({
            reasonMaster: response?.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching reason master",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: "Error occured while fetching reason master",
          snackbarType: "error",
        });
        // this.handleOpenSnackbar(
        //   this.handleSnackbarMessageBox(checkApiError(error.response.data)),
        //   "error"
        // );
        // setTimeout(() => {
        //   this.handleCloseSnackbar();
        // }, 4000);
      });
  };


  handleFetchLoadingAdviceInWeighbridge = (laNumber) => {
    this.setState({ laWeighbridgeLoader: true, errorMessage: null }, () => {
      axios
        .get(`${APIROUTES.FETCH_LA_DETAILS_IN_WEIGHBRIDGE}${laNumber}/`)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              laDetailsInWeighbridge: response.data,
              laWeighbridgeLoader: false,
              errorMessage: null,
            });
          } else {
            this.setState({
              laDetailsInWeighbridge: null,
              laWeighbridgeLoader: false,
              errorMessage: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            laWeighbridgeLoader: false,
            errorMessage: err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.details
                ? err?.response?.data?.details
                : err?.response?.data?.error
                  ? err?.response?.data?.error
                  : "Something went wrong",
          });
        });
    });
  };
  handleUpdateLoadingAdvice = (laId, gateId, gateOutReason) =>
    new Promise((resolve, reject) => {
      let data = {
        gate: gateId,
        gate_out_reason: gateOutReason
      };
      axios
        .put(
          `${APIROUTES.UPDATE_LA_STATUS_GATE_IN + laId + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });

  handleClearLoadingAdvice = () => {
    this.setState({
      laDetails: null,
      laLoader: false,
      laDetailsInWeighbridge: null,
      laWeighbridgeLoader: false,
      laDetailsInDispatchCounter: null,
      laDispatchCounterLoader: false,
      laNumber: "",
      // plantName: "",
      // dcName: "",
    });
  };
  handelFetchGates = () =>
    new Promise((resolve, reject) => {
      axios
        .get(`${APIROUTES.GET_GATES}${this.props.user.client_ip}`)
        .then((response) => {
          this.setState({ gatesDetail: response.data }, () => {
            resolve(response.data);
          });
        })
        .catch((err) => {
          reject(err.response);
          console.log(err.response);
        });
    });

  pdfpayload = (dd, data, codeFrom) => {
    console.log("indexdispatchOperation", dd, codeFrom)
    let payload = {}
    if (this.state.iseWaySelect && codeFrom === "eway" && !this.state.eway) {
      this.setState({
        eway: data
      })
      // console.log("eway")
    }
    if (this.state.invoiceType === "Combined Invoice" && codeFrom === "invoice" && !this.state.e_invoice) {
      this.setState({
        e_invoice: data
      })
      // console.log("invoice")
      // payload.e_invoice = 
    }
    if (this.state.invoiceType === "Normal Invoice" && codeFrom === "invoice" && !this.state.normal_invoice) {
      this.setState({
        normal_invoice: data
      })
      // console.log("invoice")
      // payload.normal_invoice = 
    }

    // axios.post(`${APIROUTES.PDF_UPLOAD}${this.state?.laDetailsInDispatchCounter?.la_number}/`, { eway: data.split(",")[1] },
    //   this.getOptions(this.props?.user)
    // )
    //   .then(res => console.log(res))
    //   .catch(err => console.log(err))
  }

  handleSendPdfPayload = () => {
    let payload = {}
    if (this.state.eway && this.state.e_invoice) {
      //console.log("here", this.state.eway, this.state.e_invoice)
      payload = {
        eway: this.state.eway,
        e_invoice: this.state.e_invoice
      }
    } else
      if (this.state.eway && this.state.normal_invoice) {
        payload = {
          eway: this.state.eway,
          normal_invoice: this.state.normal_invoice
        }
      } else if (!this.state.iseWaySelect && this.state.normal_invoice) {
        payload = {
          normal_invoice: this.state.normal_invoice
        }
      } else if (!this.state.iseWaySelect && this.state.e_invoice) {
        payload = {
          e_invoice: this.state.e_invoice
        }
      }
    // console.log(payload)
    this.setState({
      isApiCalled: true
    })
    axios.post(`${APIROUTES.PDF_UPLOAD}${this.state?.laDetailsInDispatchCounter?.la_number}/`, payload,
      this.getOptions(this.props?.user)
    )
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          this.setState({
            eway: null,
            e_invoice: null,
            normal_invoice: null,
            isApiCalled: false
          })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          openSnackbar: true,
          snackbarType: "error",
          snackbarMessage: error?.response?.data?.message ? error.response.data.message : "Error while sending E-mail"
        })
      })

  }
  handleFetchWeighbridge = () =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${APIROUTES.GET_WEIGHBRIDGE_IN_DISPATCH_OPERATION}${this.props.user.client_ip}`
        )
        .then((response) => {
          this.setState({ weighbridgeDetails: response.data }, () => {
            resolve(response.data);
          });
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  handleFetchLoadingAdviceInDispatchCounter = (laNumber, callfor) => {
    console.log('hereeee', this.state?.invoiceDownloadLoader)
    this.setState({ laDispatchCounterLoader: true }, () => {
      axios
        .get(`${APIROUTES.FETCH_LA_DETAILS_IN_DISPATCH_COUNTER}${laNumber}/`)
        .then((response) => {
          if (response.status === 200) {
            this.state.allStateList.map(data => {
              if (data['id'] === response.data.plant.plant_state) {
                // console.log(data['id'] + ":" + data['name'])
                this.setState({
                  plant_state: data['name']
                })
              }
            })
            let data = response.data
            if (data.created_at && data.created_at_time)
              data.created_at_date_and_time = data.created_at + " " + data.created_at_time
            this.setState(
              {
                laDetailsInDispatchCounter: data,
                laDispatchCounterLoader: false,
                invoiceDownloadLoader: callfor === "download_screen" ? false : true,
              },
              () => {
                this.handleGetDistance();
              }
            );
          } else {
            this.setState({
              laDetailsInDispatchCounter: null,
              laDispatchCounterLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ laDispatchCounterLoader: false });
        });
    });
  };

  handleGetDistance = () => {
    axios({
      url: `${APIROUTES.GET_EWAY_DATA_DISTANCE_TRACKING}?source_pincode=${this.state.laDetailsInDispatchCounter?.plant?.plant_pincode
        }&destination_pincode=${this.state.laDetailsInDispatchCounter?.consignee
          ?.consignee_from_customer_addresses?.pincode ||
        this.state.laDetailsInDispatchCounter?.consignee
          ?.consignee_from_gst_information_addresses?.pincode
        }&la_number=${this.state?.laDetailsInDispatchCounter?.la_number}`,
      method: "get",
      headers: {
        Authorization: `JWT ${this.props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({
          transporterDistance:
            response.data.IsSuccess === "true"
              ? parseFloat(response.data.Distance).toFixed(2)
              : 0.0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFetchDispatchCounter = () => {
    this.setState({ dispatchCounterLoader: true });
    axios
      .get(`${APIROUTES.GET_DISPATCH_COUNTERS}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            dispatchCounterDetails: response.data,
            dispatchCounterLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          dispatchCounterLoader: false,
        });
      });
  };

  handleFetchVRNumberFromERP = debounce((la_number) =>
    this.setState({ isFetchingVRNumber: true }, () => {
      axios
        .get(
          `${APIROUTES.GET_VR_NUM_FROM_ERP}?la_number=${la_number}`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.handleFetchLoadingAdviceInDispatchCounter(la_number);
        })
        .catch(error => {
          console.log(error.response)
          this.setState({
            openSnackbar: true,
            snackbarType: "error",
            snackbarMessage: error?.response?.data?.message ? error.response.data.message : "Error while fetching invoice number"
          })
        })
        .finally(() => this.setState({ isFetchingVRNumber: false }));
    }), 500);

  handlePostInvoiceIntoERP = (data) =>
    this.setState({ isPostingInvoice: true }, () => {
      axios
        .post(
          APIROUTES.POST_INVOICE_INTO_ERP,
          data,
          this.getOptions(this.props?.user)
        )
        .then(console.log)
        .catch(console.error)
        .finally(() => this.setState({ isPostingInvoice: false }));
    });

  handleUpdateLaWithWeightAllotment = (data, la_number, dcId, la_remark) =>
    new Promise((resolve, reject) => {
      console.log(data, la_number, dcId, la_remark)
      const encodedLaRemark = encodeURIComponent(la_remark);
      axios
        .put(
          `${APIROUTES.UPDATE_WEIGHT_ALLOTMENT + la_number + `/?dispatch_counter=${dcId}&la_remark=${encodedLaRemark}`}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          if (response.status === 200)
            this.handleFetchLoadingAdviceInDispatchCounter(la_number);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  // handleUpdateLaWithWeightAllotment = (allotment, la_number) =>
  //   new Promise((resolve, reject) => {
  //     let data = allotment;
  //     axios
  //       .put(
  //         `${APIROUTES.UPDATE_WEIGHT_ALLOTMENT + la_number + "/"}`,
  //         data,
  //         this.getOptions(this.props?.user)
  //       )
  //       .then(resolve)
  //       .catch(reject);
  //   });

  handleRandomWtWeighing = (randomWeightdata) =>
    new Promise((resolve, reject) => {
      let payloadData = randomWeightdata;
      axios
        .post(
          `${APIROUTES.RANDOM_WEIGHT_WEIGHBRIDGE}`,
          payloadData,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });

  handleWeighing = (weighingItems, la_id) =>
    new Promise((resolve, reject) => {
      let data = weighingItems;
      axios
        .put(
          `${APIROUTES.UPDATE_WEIGHING + la_id + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });

  handleUnloadWeighing = (unloadedWeighingItems, laNumber) =>
    new Promise((resolve, reject) => {
      let data = unloadedWeighingItems;
      axios
        .post(`${APIROUTES.UNLOAD_WEIGH_SLIPS}${laNumber}/`, data, this.getOptions(this.props?.user))
        .then(resolve)
        .catch(reject);
    });

  handleDispatchOperationWeighingComplete = (weighingItems, la_id) =>
    new Promise((resolve, reject) => {
      let data = weighingItems;
      axios
        .put(
          `${APIROUTES.UPDATE_LA_STATUS_IN_DISPATCH_PLANING + la_id + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });

  handleGenerateEinvoiceAndEway = (la_number, is_create_eway) => {
    let data = {
      create_eway: is_create_eway,
      distance: this.state?.actualDistance
    };
    axios
      .post(
        `${APIROUTES.GENERATE_E_INVOICE_AND_E_WAY + la_number + "/"}`,
        data,
        this.getOptions(this.props?.user)
      )
      .then((response) => {
        this.setState({
          eInvoiceResponse: response.data,
          eWayBillResponse: is_create_eway ? response.data : null,
        });
        // this.setState({ weighbridgeDetails: response.data });
        this.props.history.push("invoices/download/" + la_number);
      })
      .catch((err) => {
        console.log(err.response.data);
        this.setState({
          einvoiveErrorMessage: err?.response?.data?.e_way_error_message
            ? err?.response?.data?.e_way_error_message
            : err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.details
                ? err?.response?.data?.details
                : "Something went wrong",
          errorWhilePostForInvoice: true,
        });
      });
  };
  handleGenerateEWAY = (la_number) => {
    let data = {
      distance: this.state?.actualDistance
    };
    axios
      .post(
        `${APIROUTES.GENERATE__E_WAY + la_number + "/"}`,
        data,
        this.getOptions(this.props?.user)
      )
      .then((response) => {
        this.setState({
          eWayBillResponse: response.data,
        });
        this.props.history.push("invoices/download/" + la_number);
      })
      .catch((err) => {
        this.setState({
          einvoiveErrorMessage: err?.response?.data?.message
            ? err?.response?.data?.message
            : err?.response?.data?.details
              ? err?.response?.data?.details
              : "Something went wrong",
          errorWhilePostForInvoice: true,
        });
      });
  };

  handleLoadingAdviceStatusChange = (laNumber) => {
    axios
      .get(`${APIROUTES.CHANGE_STATUS_NORMAL_INVOICE}${laNumber}/`, this.getOptions(this.props?.user))
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          this.props.history.push("invoices/download/" + laNumber);
        }
      });
  }
  handleCreateB2cInvoiceQr = () => {
    //alert("QR Genrating");
    this.setState({ dataInvoicePostLoader: true });
    let supplierAccountDetails = this?.state?.plantAccountDetails;
    if (
      this?.state?.laDetailsInDispatchCounter?.payment_method
        ?.payment_method_name === "Advance"
    ) {
      this.props.history.push("download");
    } else {
      upiqr({
        currency: this?.state?.laDetailsInDispatchCounter?.customer?.currency_code,
        payeeVPA:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_upi_id,
        payeeName:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_name,
        amount: this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value,
        transactionRef: this.state?.laDetailsInDispatchCounter?.erp_invoice_number,
        transactionNote: `SUPGSTN ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number
          } PAYEE_AC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no
          } PAYEE_IFSC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc
          } ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
            ? "IGST " + this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount
            : `CGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount} SGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount}`
          } CESS ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount
          } TOT_AMT ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value
          } INV_DATE ${moment(
            this?.state?.laDetailsInDispatchCounter?.created_at,
            ["DD/MM/YYYY", "YYYY-MM-DD"]
          ).format("DD MMM YYYY")}`,
      })
        .then((upi) => {
          // console.log(upi.qr); // data:image/png;base64,eR0lGODP...
          //console.log("UPI", upi.intent); // upi://pay?pa=john@upi&pn=JOHN DOE
          this.setState(
            { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
            () => console.log(1)
          );
          //this.props.history.push("invoices/download");
        })
        .catch((err) => {
          this.setState(
            { b2CQrImage: null, dataInvoicePostLoader: false },
            () => console.log("error in upiqr")
          );
        });
    }
  };

  handleCheckStockBeforeBilling = (la_number) => {
    axios
      .get(
        `${APIROUTES.CHECK_STOCK_BEFORE_BILLING + la_number + "/"}`,
        this.getOptions(this.props?.user)
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.setState({
            availableStock: response.data?.stock?.available_stock,
            stockAndPayment: response.data
          })
        }
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          this.setState({
            availableStock: error.response.data?.stock?.available_stock,
            stockError: error.response.data?.stock?.error,
            paymentError: error.response.data?.payment?.error
          });
        }
      });
  };


  handleBilledNext = async (laNumber) => {
    // console.log(this?.state)
    // console.log(this.props)
    if (this.state.b2CQrImage === null) {
      await upiqr({
        currency: this?.state?.laDetailsInDispatchCounter?.customer?.currency_code,
        payeeVPA:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_upi_id,
        payeeName:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_name,
        amount: this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value,
        transactionRef: this.state?.laDetailsInDispatchCounter?.erp_invoice_number,
        transactionNote: `SUPGSTN ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number
          } PAYEE_AC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no
          } PAYEE_IFSC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc
          } ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
            ? "IGST " + this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount
            : `CGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount} SGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount}`
          } CESS ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount
          } TOT_AMT ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value
          } INV_DATE ${moment(
            this?.state?.laDetailsInDispatchCounter?.created_at,
            ["DD/MM/YYYY", "YYYY-MM-DD"]
          ).format("DD MMM YYYY")}`,
      })
        .then((upi) => {
          // console.log(upi.qr); // data:image/png;base64,eR0lGODP...
          //console.log("UPI", upi.intent); // upi://pay?pa=john@upi&pn=JOHN DOE
          this.setState(
            { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
            () => console.log(1)


          );
          //this.props.history.push("invoices/download");
        })
        .catch((err) => {
          this.setState(
            { b2CQrImage: null, dataInvoicePostLoader: false },
            () => console.log("error in upiqr")
          );
        });
    }
    // console.log(this.state)
    await axios
      .get(`${APIROUTES.GET_E_INVOICE_AND_E_WAY}${laNumber}/`)
      .then((response) => {
        // console.log(response)
        this.setState({
          eInvoiceResponse: response.data,
          eWayBillResponse: response.data?.is_eway_created
            ? response.data
            : null,
          iseWaySelect: response.data?.is_eway_created,
          invoiceType: response.data?.invoice_type,
        });
        this.props.history.push(
          `/dispatchOperation/dc/2/details/invoices/download/${laNumber}`
        );
      });
    // this.props.history.push(
    //   "/dispatchOperation/dc/2/details/invoices/download",
    //   this.state
    // );
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ errorMessage: null });
    }
  }


  render() {
    const { isMobile } = this.props;

    // console.log(this.state)
    return (
      <>
        <Route
          exact
          path="/dispatchOperation"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchOperationWeb
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handelFetchGates={this.handelFetchGates}
              handleFetchWeighbridge={this.handleFetchWeighbridge}
              fetchReasonGateOut={this.fetchReasonGateOut}
              handleClearLoadingAdvice={this.handleClearLoadingAdvice}
              handleSetPlantName={this.handleSetPlantName}
            />
          )
          }
        />
        <Route
          exact
          path="/dispatchOperation/gate/:gateId"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchOperationsGateWeb
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handleFetchLoadingAdvice={this.handleFetchLoadingAdvice}
              handleClearLoadingAdvice={this.handleClearLoadingAdvice}
              handelFetchGates={this.handelFetchGates}
              fetchReasonGateOut={this.fetchReasonGateOut}
              handleFetchWeighbridge={this.handleFetchWeighbridge}
              handleUpdateLoadingAdvice={this.handleUpdateLoadingAdvice}
            />
          )
          }
        />
        <Route
          exact
          path="/dispatchOperation/weighbridge/:wbId"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchOperationWeighbridgeWeb
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handleFetchLoadingAdviceInWeighbridge={
                this.handleFetchLoadingAdviceInWeighbridge
              }
              handleClearLoadingAdvice={this.handleClearLoadingAdvice}
              weighbridgeDetails={this.state.weighbridgeDetails}
              handleWeighing={this.handleWeighing}
              handleUnloadWeighing={this.handleUnloadWeighing}
              handleDispatchOperationWeighingComplete={
                this.handleDispatchOperationWeighingComplete
              }
              handleRandomWtWeighing={this.handleRandomWtWeighing}
              LANo={this.props?.location?.state?.LANo}
              PlantFullName={this.props?.location?.state?.PlantFullName}
            />
          )
          }
        />
        <Route
          exact
          path="/dispatchOperation/dc/:dcId"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchCounterWeb
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handleFetchLoadingAdviceInDispatchCounter={
                this.handleFetchLoadingAdviceInDispatchCounter
              }
              handleClearLoadingAdvice={this.handleClearLoadingAdvice}
              handleTextChange={this.handleTextChange}
              handleBilledNext={this.handleBilledNext}
            />
          )
          }
        />
        <Route
          exact
          path="/dispatchOperation/dc/:dcId/details"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchCounterDetailsWeb
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handleUpdateLaWithWeightAllotment={
                this.handleUpdateLaWithWeightAllotment
              }
              handleClearLoadingAdvice={this.handleClearLoadingAdvice}
            />
          )
          }
        />
        <Route
          exact
          path="/dispatchOperation/dc/:dcId/details/invoices"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchCounterInvoicingWeb
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              actualDistance={this.state?.actualDistance}
              handleFetchVRNumberFromERP={this.handleFetchVRNumberFromERP}
              handleGenerateEinvoiceAndEway={
                this.handleGenerateEinvoiceAndEway
              }
              handleSelectEnvoiceType={this.handleSelectEnvoiceType}
              handleSelectEway={this.handleSelectEway}
              handleGenerateEWAY={this.handleGenerateEWAY}
              handleCreateB2cInvoiceQr={this.handleCreateB2cInvoiceQr}
              handleResetData={this.handleResetData}
              handleLoadingAdviceStatusChange={this.handleLoadingAdviceStatusChange}
              handleCheckStockBeforeBilling={this.handleCheckStockBeforeBilling}
              handleActualDistance={this.handleActualDistance}
            />
          )
          }
        />
        <Route
          exact
          path="/dispatchOperation/dc/:dcId/details/invoices/download/:lanumber"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchCounterInvoiceDownloadWeb
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handleClearLoadingAdvice={this.handleClearLoadingAdvice}
              handleFetchLoadingAdviceInDispatchCounter={this.handleFetchLoadingAdviceInDispatchCounter}
              pdfpayload={this.pdfpayload}
              handleSendPdfPayload={this.handleSendPdfPayload}
            />
          )
          }
        />
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
        />
      </>
    );
  }
}
export default DispatchOperationContainer;
