import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router";
import COLORS, { drawerTextColor } from "../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import WeighbridgeInfoCard from "./WeighbridgeInfoCard";
import ReactToPrint from "react-to-print";
import Print from "@material-ui/icons/Print";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarComponent from "../../common/SnackbarComponent";
import moment from "moment";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
//import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import VerticalLinearStepper from "./WeighbridgeStepper";
import { InfoOutlined } from "@mui/icons-material";
import { IconButton, ListSubheader, MenuItem, Tooltip } from '@material-ui/core';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  pxToEm, checkApiError
} from '../../../methods';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RandomWeightTable from "./RandomWeightTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const styles = {
  breadcrumbsStyle: {
    color: COLORS.secondaryBlueText,
    cursor: "pointer",
    margin: "10px 0px 0px 10px",
  },
  paperStyle: {
    width: "96%",
    marginTop: "2%",
  },
  plantNameShowContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px",
  },
  popoverBox: {
    '& .MuiPopover-paper': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
      marginBottom: 10,
      // borderRadius: 20,
    }

  },
  laNumberInputStyle: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 18px",
    justifyContent: "center",
    alignItems: "center",
    height: "24vh",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
  },
  InfoCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  rightSideContainer: {
    width: "44%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  verticalStepperStyle: {
    width: "44%",
  },
  tareWeightContainer: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "92%",
  },
  tareWeightCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    boxShadow:
      "0px 2px 20px -1px rgba(0,0,0,0.2), 1px 6px 20px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)",
  },
  laNumberShow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '500',
    alignItems: "center"
  },
  randomWtLabel: {
    color: "#293C79",
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "10px"
  },
  randomWtButton: {
    backgroundColor: "#293C79",
    color: COLORS.drawerTextColor,
  },
  randomWtSaveButton: {
    backgroundColor: "#293C79",
    color: COLORS.drawerTextColor,
    marginTop: "10px"
  }
};




let flag = false;
class Weighbridge extends Component {
  constructor(props) {
    super(props);
    this.WBPrint = React.createRef();

    let weighbridges = props.weighbridgeDetails.filter(
      (g) => g.id === props.location.id
    );
    this.state = {
      laNumberLengthError: false,
      errorMessage: null,
      laNumber: "",
      weighbridge_AllStatus: [],
      Weighbridge_Status: [
        { id: 1, name: "Loading Status", title: "Ongoing", status: true },
        { id: 2, name: "Weighbridge", title: "#WB-27GG", status: true },
        { id: 3, name: "Plant Status", title: "#WB-27GG", status: true },
        {
          id: 4,
          name: "Truck Capacity - Item Weight",
          title: "80MT",
          status: true,
        },
      ],
      Weighbridge_randomWtStatus: [
        { id: 1, name: "Loading Status", title: "Ongoing", status: true },
        { id: 2, name: "Weighbridge", title: "#WB-27GG", status: true },
        { id: 3, name: "Plant Status", title: "#WB-27GG", status: true },
        {
          id: 4,
          name: "Truck Capacity - Item Weight",
          title: "80MT",
          status: true,
        },
        {
          id: 5,
          name: "Last Random Weight",
          title: "#WB-27GG",
          status: true,
        },
      ],
      weight: 0.0,
      capturedCurrentWeight: 0.0,
      currentLoadedWeight: 0.0,
      unloadedWeight: 0.0,
      unloadedTareWeight: 0.0,
      tareWeight: 0.0,
      updated: false,
      markedAsPacked: false,
      capturingWeight: false,
      capturedWeight: false,
      updateLoader: false,
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
      weighbridgeDetails: weighbridges.length > 0 ? weighbridges[0] : null,
      allowTakingWeights: false,
      currentStep: -1,
      isWeightCaptured: false,
      stepperHasError: false,
      anchorEl: null,
      openTareWeightBox: false,
      selectedItems: [],
      movetoPack: false,
      isRandomWtCaptured: false,
      capturedRandomWeight: 0.0,
      randomWeightData: {},
      isRandomWtToleranceHigher: false,
      isCopied: false,
    };
  }

  componentDidMount() {
    this.props.handleClearLoadingAdvice();
    navigator?.serial?.addEventListener("connect", (event) => {
      // TODO: Automatically open event.target or warn user a port is available.
    });

    navigator?.serial?.addEventListener("disconnect", (event) => {
      // TODO: Remove |event.target| from the UI.
      // If the serial port was opened, a stream error would be observed as well.
    });
    if (this.props?.laDetailsInWeighbridge?.dispatch_status !== "GATEIN") {
      this.setState({ currentStep: 0 });
    }
  }

  initialiseStepperCount = (c) => {
    this.setState({ currentStep: c });
  };

  resetStepperErrorState = () => {
    this.setState({ stepperHasError: false });
  };

  serial = async (mode) => {
    flag = mode;
    if (flag) {
      let port = false;
      let readableStreamClosed = false;
      if ("serial" in navigator) {
        try {
          // Prompt user to select any serial port.
          port = await navigator.serial.requestPort();

          // Wait for the serial port to open.
          await port.open({ baudRate: 2400 });
          const textDecoder = new window.TextDecoderStream();
          readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
          const reader = textDecoder.readable.getReader();

          // Listen to data coming from the serial device.
          try {
            while (true) {
              const { value, done } = await reader.read(); // value is a Uint8Array.
              if (done) {
                // reader.cancel() has been called.
                break;
              }
              if (!flag) {
                reader.cancel();
              }
              if (value.length === 8) {
                let weight = value.substr(2, value.length - 1);
                if (isNaN(weight)) {
                  weight = 0;
                } else {
                  weight = parseFloat(weight);
                  if (weight > 0) {
                    weight = weight / 1000;
                  } else weight = 0;
                  this.setState({ weight }, () => { });
                }
              }
            }
          } catch (error) {
          } finally {
            // Allow the serial port to be closed later.
            reader.releaseLock();
          }
        } catch (err) {
          console.error("There was an error opening the serial port:", err);
        }
      } else {
        console.error(
          "Web serial doesn't seem to be enabled in your browser. Try enabling it by visiting:",
          "\nchrome://flags/#enable-experimental-web-platform-features",
          "\nopera://flags/#enable-experimental-web-platform-features",
          "\nedge://flags/#enable-experimental-web-platform-features"
        );
      }

      if (readableStreamClosed)
        await readableStreamClosed.catch(() => console.log());
      if (port) var temp = port?.close().then(console.log).catch(console.error);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.laDetailsInWeighbridge != this.props.laDetailsInWeighbridge) {
      this.handleAllowTakingWeights();
      const totalWeightsCaptured =
        this.props.laDetailsInWeighbridge?.loading_advice_weighings?.length - 1;

      if (totalWeightsCaptured > 0) {
        const { la_quantity, tare_weight, item_category } =
          this.props.laDetailsInWeighbridge;
        let capturedLastWeight =
          this.props.laDetailsInWeighbridge?.loading_advice_weighings.slice(1, this.props.laDetailsInWeighbridge?.loading_advice_weighings?.length)[
            totalWeightsCaptured - 1
          ].loaded_weight;
        let laQuantityWithMaxTolerance =
          Number(la_quantity) +
          Number(tare_weight) +
          Number(item_category.max_tolerance_value);
        let laQuantityWithMinTolerance =
          Number(la_quantity) +
          Number(tare_weight) -
          Number(item_category.min_tolerance_value);

        if (
          capturedLastWeight <= laQuantityWithMaxTolerance &&
          capturedLastWeight >= laQuantityWithMinTolerance
        ) {
          this.setState({ markedAsPacked: true });
        }
      }

      if (this.props.laDetailsInWeighbridge?.loading_advice_weighings.length <= 1) {
        this.setState({
          unloadedTareWeight: this.props.laDetailsInWeighbridge?.all_weighings?.filter(f => f.is_unloaded)?.[0]?.loaded_weight ?? 0.0
        })
      } else {
        this.setState({
          unloadedWeight: this.props.laDetailsInWeighbridge?.all_weighings?.filter(f => f.is_unloaded)?.[0]?.loaded_weight ?? 0.0,
          unloadedTareWeight: this.props.laDetailsInWeighbridge?.all_weighings?.filter(f => f.is_unloaded)?.[1]?.loaded_weight ?? 0.0
        })
      }
      this.setState({
        weighbridge_AllStatus: this.props?.laDetailsInWeighbridge?.dispatch_status === "PACKED"
          || this.props?.laDetailsInWeighbridge?.dispatch_status === "ALLOTED" ||
          this.props?.laDetailsInWeighbridge?.dispatch_status === "BILLED" ?
          this.state.Weighbridge_randomWtStatus : this.state.Weighbridge_Status
      })
      this.checkRandomWtWithTolerance(this.props.laDetailsInWeighbridge?.gross_weight,
        this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_0to20_mts,
        this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_20to30_mts,
        this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_above_30_mts,
        this.props.laDetailsInWeighbridge?.random_weights[this.props.laDetailsInWeighbridge?.random_weights.length - 1]?.weight)

    }
    if (prevProps.laDetailsInWeighbridge?.gross_weight != this.props.laDetailsInWeighbridge?.gross_weight) {
      if (this.props?.laDetailsInWeighbridge?.dispatch_status == "PACKED" ||
        this.props?.laDetailsInWeighbridge?.dispatch_status == "ALLOTED" ||
        this.props?.laDetailsInWeighbridge?.dispatch_status == "BILLED") {

      }
      this.chooseTolerance(this.props.laDetailsInWeighbridge?.gross_weight);
    }
    if (this.state?.laNumber === "" && this.props.LANo) {
      let la = this.props.LANo
      this.setState({
        laNumber: la
      }, () => {
        setTimeout(() => {
          this.props.handleFetchLoadingAdviceInWeighbridge(la);
          this.chooseTolerance(this.props.laDetailsInWeighbridge?.gross_weight);
          this.checkRandomWtWithTolerance(this.props.laDetailsInWeighbridge?.gross_weight,
            this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_0to20_mts,
            this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_20to30_mts,
            this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_above_30_mts,
            this.props.laDetailsInWeighbridge?.random_weights[this.props.laDetailsInWeighbridge?.random_weights.length - 1]?.weight)
        }, 500);
      })
    }
  }

  handleTextChange = (event) => {
    this.setState({ [event.target.name]: (event.target.value).trim() });
  };
  handleCaptureWeight = () => {
    this.setState({ capturingWeight: true });
    this.handleAllowTakingWeights();
  };

  handleTakeRandomWeight = () => {
    this.setState({ capturingWeight: true });
    this.serial(true);
  }

  handleAllowTakingWeights = () => {
    if (this.props?.laDetailsInWeighbridge !== null) {
      const { la_quantity, tare_weight, item_category } =
        this.props?.laDetailsInWeighbridge;
      const totalWeightsTaken =
        this.props?.laDetailsInWeighbridge?.loading_advice_weighings?.length - 1;
      let currentWeightTaken = this.state.capturedCurrentWeight;
      if (totalWeightsTaken > 0) {
        const lastWeightTaken =
          this.props?.laDetailsInWeighbridge?.loading_advice_weighings.slice(1, this.props?.laDetailsInWeighbridge?.loading_advice_weighings?.length)[
            totalWeightsTaken - 1
          ].loaded_weight;
        currentWeightTaken =
          lastWeightTaken > currentWeightTaken
            ? lastWeightTaken
            : currentWeightTaken;
      }

      const minWeight =
        (Number(la_quantity) +
          Number(tare_weight) -
          Number(item_category.min_tolerance_value)).toFixed(3);

      const maxWeight =
        (Number(la_quantity) +
          Number(tare_weight) +
          Number(item_category.max_tolerance_value)).toFixed(3);

      if (minWeight <= currentWeightTaken && currentWeightTaken <= maxWeight) {
        this.setState({ allowTakingWeights: true });
      }
    }
  };

  handleStepperCaptureWeight = (selectedItems) => {
    // e?.preventDefault();
    this.setState({ selectedItems: selectedItems }, () => {
      this.handleCaptureWeight();
      this.serial(true);
    });
  };

  getPermittedNumber = (num) => {
    return Number(Number(num).toFixed(3));
  };

  isValid = (num) => {
    return (
      this.decimalCount(num) < 3 ||
      (this.decimalCount(num) === 3 &&
        (Number(num.toString().substr(-1, 1)) === 5 ||
          Number(num.toString().substr(-1, 1)) === 0))
    );
  };

  handleIndividualItemValidation = (item, weight) => {
    const { la_quantity, tare_weight, item_category } =
      this.props.laDetailsInWeighbridge;
    const selectedItem = this.props.laDetailsInWeighbridge?.loading_advice_items.find(i => i.item == item)
    const max = Number(item_category.max_tolerance_value) + Number(selectedItem?.item_qty)
    const min = Number(selectedItem?.item_qty) - Number(item_category.min_tolerance_value)

    if (weight > max || weight < min) {
      if (weight > max)
        return { error: true, errorMessage: 'Net weight above threshold' };
      else if (weight < min)
        return { error: true, errorMessage: 'Net weight below threshold' }
    } else if (!this.isValid(weight)) {
      return { error: true, errorMessage: 'Please check quantity' }
    } else {
      return { error: false, errorMessage: '' }
    }
  }

  handleSelectedItemsCheck = () => {
    const { la_quantity, tare_weight, item_category } =
      this.props.laDetailsInWeighbridge;
    const selectedItem = this.props.laDetailsInWeighbridge?.loading_advice_items.find(i => i.item == this.state.selectedItems[0]?.item)
    const totalWeighingsTaken = this.props.laDetailsInWeighbridge?.loading_advice_weighings?.length;
    const loadedWeight = this.props.laDetailsInWeighbridge?.loading_advice_weighings[totalWeighingsTaken - 1].loaded_weight;
    if (this.state.selectedItems.length == 1) {
      if (this.state.weight > Number(loadedWeight) + Number(selectedItem?.item_qty) + Number(item_category.max_tolerance_value)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage:
            "New weight is above threshold",
          snackbarType: "error",
          isWeightCaptured: false,
        });
        return false;
      } else if (this.state.weight < Number(loadedWeight) + Number(selectedItem?.item_qty) - Number(item_category.min_tolerance_value)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage:
            "New weight is less than threshold",
          snackbarType: "error",
          isWeightCaptured: false,
        });
        return false;
      }
    } else {
      const selectedItems = this.props.laDetailsInWeighbridge?.loading_advice_items
        .filter(i => this.state.selectedItems?.map(m => m?.item)?.includes(i.item))
      let minToleranceValue
      let maxToleranceValue

      const totalItemQtywithMaxTolerance = (selectedItems, loadedWeight) => {
        let maxValue = 0;
        for (let i = 0; i < selectedItems.length; i++) {
          maxValue += Number(selectedItems[i].item_qty)
        }
        return maxValue + Number(loadedWeight) + Number(item_category.max_tolerance_value)
      };
      const totalItemQtywithMinTolerance = (selectedItems, loadedWeight) => {
        let minValue = 0;
        for (let i = 0; i < selectedItems.length; i++) {
          minValue += Number(selectedItems[i].item_qty)
        }

        return minValue + Number(loadedWeight) - Number(item_category.min_tolerance_value)
      };
      if (this.state.weight > totalItemQtywithMaxTolerance(selectedItems, loadedWeight)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage:
            "New weight is above threshold",
          snackbarType: "error",
          isWeightCaptured: false,
        });
        return false;
      } else if (this.state.weight < totalItemQtywithMinTolerance(selectedItems, loadedWeight)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage:
            "New weight is less than threshold",
          snackbarType: "error",
          isWeightCaptured: false,
        });
        return false;
      }
    }
    return true;
  }

  handleWeightCapture = () => {

    if (this.props.laDetailsInWeighbridge?.dispatch_status === "UNLOADING") {

      const netWeight = Number(this.props.laDetailsInWeighbridge?.gross_weight);
      const percentChangedWeight = 0.003 * netWeight;
      const minAllowedWeight = Number(Number(netWeight - percentChangedWeight).toFixed(3));
      const maxAllowedWeight = Number(Number(netWeight + percentChangedWeight).toFixed(3));

      const percentChangedWarningWeight = 0.005 * netWeight;
      const minAllowedWeightWarning = Number(Number(netWeight - percentChangedWarningWeight).toFixed(3));
      const maxAllowedWeightWarning = Number(Number(netWeight + percentChangedWarningWeight).toFixed(3));

      if (Number(this.state.unloadedWeight) <= 0 && this.props.laDetailsInWeighbridge?.all_weighings?.length > 1) {
        if (this.state.weight >= minAllowedWeight && this.state.weight <= maxAllowedWeight) {
          this.setState({
            capturedCurrentWeight: this.state.weight,
            capturedWeight: true,
            isWeightCaptured: true
          })
        } else if (this.state.weight >= minAllowedWeightWarning && this.state.weight <= maxAllowedWeightWarning) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Weight captured allowed with warning",
            snackbarType: "warning",
            capturedCurrentWeight: this.state.weight,
            capturedWeight: true,
            isWeightCaptured: true
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Weight captured is not within tolerance",
            snackbarType: "error"
          })
        }
      } else if (Number(this.state.unloadedTareWeight) <= 0) {
        if (this.state.weight >= minAllowedWeight && this.state.weight <= maxAllowedWeight) {
          this.setState({
            capturedCurrentWeight: this.state.weight,
            capturedWeight: true,
            isWeightCaptured: true
          })
        } else if (this.state.weight >= minAllowedWeightWarning && this.state.weight <= maxAllowedWeightWarning) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Weight captured allowed with warning",
            snackbarType: "warning",
            capturedCurrentWeight: this.state.weight,
            capturedWeight: true,
            isWeightCaptured: true
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Weight captured is not within tolerance",
            snackbarType: "error"
          })
        }
      }
      return;
    }

    if (Number(this.state.weight) <= 0) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "Weight captured cannot be less than or equal to 0",
        snackbarType: "error",
        isWeightCaptured: false,
      });
      return;
    }

    this.setState({ isWeightCaptured: true });
    const { la_quantity, tare_weight, item_category } =
      this.props.laDetailsInWeighbridge;

    if (tare_weight != "0.000") {
      if (!this.handleSelectedItemsCheck()) {
        return;
      }
    }

    const countTotalWeights =
      this.props.laDetailsInWeighbridge?.loading_advice_weighings?.length - 1;

    const currentWeight =
      countTotalWeights > 0
        ? this.props.laDetailsInWeighbridge?.loading_advice_weighings[
          countTotalWeights - 1
        ]?.loaded_weight
        : tare_weight;

    const totalWeight = Number(currentWeight);

    if (this.decimalCount(Number(this.state.weight)) > 3) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "Ensure that there are no more than 3 decimal places.",
        snackbarType: "error",
        isWeightCaptured: false,
      });
      return;
    }

    let weightsAfterDiscard = this.props.laDetailsInWeighbridge?.all_weighings.filter(weigh => !weigh?.is_discarded)?.length;
    if (this.props.laDetailsInWeighbridge?.dispatch_status === "LOADING" && this.props?.laDetailsInWeighbridge?.loading_advice_weighings?.length === 0) {
      this.setState({
        currentStepAfterDiscard: weightsAfterDiscard
      })
    }

    if (this.state.currentStep > 0 && (this.props.laDetailsInWeighbridge?.dispatch_status === "LOADING" && this.props?.laDetailsInWeighbridge?.loading_advice_weighings?.length > 0)) {
      if (
        this.state.weight >
        Number(la_quantity) +
        Number(tare_weight) +
        Number(item_category.max_tolerance_value)
      ) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: "Total Truck Weight is exceeding Max Tolerance",
          snackbarType: "error",
          isWeightCaptured: false,
        });
      } else if (
        this.state.weight <
        Number(la_quantity) +
        Number(tare_weight) -
        Number(item_category.min_tolerance_value) &&
        this.props.laDetailsInWeighbridge?.loading_advice_items?.length - 1 ===
        countTotalWeights
      ) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: "Total Truck Weight is below Min Tolerance",
          snackbarType: "error",
          isWeightCaptured: false,
        });

      } else if (Number(this.state.weight) <= totalWeight) {
        this.setState({
          openSnackbar: true,
          snackbarMessage:
            "New weight cannot be less than or equal to last weight",
          snackbarType: "error",
          isWeightCaptured: false,
        });
      } else {
        this.setState((prev) => ({
          currentStep: prev.currentStep + 1,
        }));
        this.setState(
          {
            capturedWeight: true,
            capturedCurrentWeight: this.state.weight,
          },

          () => {
            const { capturedCurrentWeight } = this.state;
            if (
              this.props.laDetailsInWeighbridge?.dispatch_status === "LOADING"
            ) {
              this.handleLoadedWeight();
            }
            this.setState({ allowTakingWeights: true });

            let laQuantityWithMaxTolerance =
              Number(la_quantity) +
              Number(tare_weight) +
              Number(item_category.max_tolerance_value);
            let laQuantityWithMinTolerance =
              Number(la_quantity) +
              Number(tare_weight) -
              Number(item_category.min_tolerance_value);
            if (
              capturedCurrentWeight <= laQuantityWithMaxTolerance &&
              capturedCurrentWeight >= laQuantityWithMinTolerance
            ) {
              this.setState({ markedAsPacked: true });
            }
          }
        );
      }
    } else {
      this.setState((prev) => ({
        currentStep: prev.currentStep + 1,
      }));
      this.setState(
        {
          capturedWeight: true,
          capturedCurrentWeight: this.state.weight,
        },

        () => {
          const { capturedCurrentWeight } = this.state;
          if (
            this.props.laDetailsInWeighbridge?.dispatch_status === "LOADING"
          ) {
            this.handleLoadedWeight();
          }
          this.setState({ allowTakingWeights: true });

          let laQuantityWithMaxTolerance =
            Number(la_quantity) +
            Number(tare_weight) +
            Number(item_category.max_tolerance_value);
          let laQuantityWithMinTolerance =
            Number(la_quantity) +
            Number(tare_weight) -
            Number(item_category.min_tolerance_value);
          if (
            capturedCurrentWeight <= laQuantityWithMaxTolerance &&
            capturedCurrentWeight >= laQuantityWithMinTolerance
          ) {
            this.setState({ markedAsPacked: true });
          }
        }
      );
    }
  };

  handleRandomWt = () => {
    const { capturedRandomWeight } = this.state;
    return parseFloat(capturedRandomWeight);
  }

  handleUpdateRandomWeight = async () => {
    let data = {
      weight:
        this.handleRandomWt(),
      loading_advice: this.props.laDetailsInWeighbridge?.id
    }

    await this.props
      .handleRandomWtWeighing(data)
      .then(
        (data) => {
          if (data.status === 200 || data.status === 201) {
            this.setState({
              randomWeightData: data,
              capturingWeight: false,
              isRandomWtCaptured: false,
              updateLoader: false,
              openSnackbar: true,
              snackbarMessage: "Random Weight submitted successfully ",
              snackbarType: "success",
            })
          }
          {
            setTimeout(() => {
              this.props.handleFetchLoadingAdviceInWeighbridge(
                this.props.laDetailsInWeighbridge?.la_number
              );
            }, 3000)
          }
        },
        (error) => {
          this.setState({
            capturingWeight: false,
            isRandomWtCaptured: false,
            updateLoader: false,
            openSnackbar: true,
            snackbarMessage: error?.response?.data?.msg
              ? error?.response?.data?.msg : "Something went wrong. Please try again",
            snackbarType: "error",
          })
        }

      );
  }

  handleRandomWtCapture = () => {
    this.setState({
      isRandomWtCaptured: true,
      capturedRandomWeight: parseFloat(this.state.weight),
    })
  }

  checkRandomWtWithTolerance = (grossWt, tolerance_from_0to20_mts, tolerance_from_20to30_mts, tolerance_above_30_mts, capturedWeight) => {
    const randomWtTolerance = ((capturedWeight - grossWt) / grossWt) * 100;
    if (20.000 >= grossWt && grossWt > 0) {
      if (randomWtTolerance > tolerance_from_0to20_mts) {
        return this.setState({
          isRandomWtToleranceHigher: true
        })
      }
    }
    if (20.001 <= grossWt && grossWt <= 30.000) {
      if (randomWtTolerance > tolerance_from_20to30_mts) {
        return this.setState({
          isRandomWtToleranceHigher: true
        })
      }
    }
    if (grossWt >= 30.001) {
      if (randomWtTolerance > tolerance_above_30_mts) {
        return this.setState({
          isRandomWtToleranceHigher: true
        })
      }
    }
    if (randomWtTolerance < 0) {
      return this.setState({
        isRandomWtToleranceHigher: true
      })
    }

    return this.setState({ isRandomWtToleranceHigher: false })
  }

  handleDisabledCaptureButton = () => {
    if (this.props.laDetailsInWeighbridge?.dispatch_status === "LOADING" ||
      this.props.laDetailsInWeighbridge?.dispatch_status === "PACKED" ||
      this.props.laDetailsInWeighbridge?.dispatch_status === "ALLOTED" ||
      this.props.laDetailsInWeighbridge?.dispatch_status === "BILLED") {
      return false;
    } else if (
      this.props.laDetailsInWeighbridge?.dispatch_status === "GATEIN"
    ) {
      return false;
    } else if (
      this.props.laDetailsInWeighbridge?.dispatch_status === "UNLOADING"
    ) {
      return false;
    }
    else return true;
  };
  handleLoadedWeight = () => {
    const { laDetailsInWeighbridge } = this.props;
    const { capturedCurrentWeight } = this.state;
    return parseFloat(capturedCurrentWeight);
    // if (laDetailsInWeighbridge?.loading_advice_weighings.length === 0) {
    //   this.setState({
    //     currentLoadedWeight: (
    //       Number(capturedCurrentWeight) -
    //       Number(laDetailsInWeighbridge.tare_weight)
    //     ).toFixed(3),
    //   });
    //   return (
    //     Number(capturedCurrentWeight) -
    //     Number(laDetailsInWeighbridge.tare_weight)
    //   ).toFixed(3);
    // } else {
    //   let total = 0.0;
    //   laDetailsInWeighbridge?.loading_advice_weighings.map(
    //     (data) => (total = total + Number(data.loaded_weight))
    //   );
    //   this.setState({
    //     currentLoadedWeight: (
    //       Number(capturedCurrentWeight) -
    //       (Number(total) + Number(laDetailsInWeighbridge.tare_weight))
    //     ).toFixed(3),
    //   });
    //   return (
    //     Number(capturedCurrentWeight) -
    //     (Number(total) + Number(laDetailsInWeighbridge.tare_weight))
    //   ).toFixed(3);
    //}
  };

  handleUpdateUnloadWeighing = async () => {
    let data = {
      unloaded_weight:
        this.handleLoadedWeight(),
    };
    await this.props
      .handleUnloadWeighing(data, this.props.laDetailsInWeighbridge?.la_number)
      .then(
        (data) => {
          if (data.status === 200 || data.status === 201) {
            this.props.handleFetchLoadingAdviceInWeighbridge(
              this.props.laDetailsInWeighbridge?.la_number
            );

            if (Number(this.state.unloadedWeight) <= 0 && this.props.laDetailsInWeighbridge?.all_weighings?.length > 1) {
              this.setState({
                unloadedWeight: this.state.capturedCurrentWeight
              })
            } else if (Number(this.state.unloadedTareWeight) <= 0) {
              this.setState({
                unloadedTareWeight: this.state.capturedCurrentWeight
              })
            }

            this.setState({
              capturingWeight: false,
              capturedWeight: false,
              updated: true,
              // markedAsPacked: false,
              isWeightCaptured: false,
              updateLoader: false,
              openSnackbar: true,
              snackbarMessage: "Submitted successfully ",
              snackbarType: "success",
              stepperHasError: false,
            })
            {
              (this.state.unloadedTareWeight > 0) ? <></> :
                setTimeout(() => {
                  this.setState({
                    openSnackbar: false,
                  })
                  this.props.history.push("/dispatchOperation");
                }, 5000)
            }
          }
        },
        (error) => {
          this.setState({
            capturingWeight: false,
            capturedWeight: false,
            updated: false,
            // markedAsPacked: false,
            isWeightCaptured: false,
            updateLoader: false,
            openSnackbar: true,
            snackbarMessage: error?.response?.data?.msg
              ? error?.response?.data?.msg : "Something went wrong. Please try again",
            snackbarType: "error",
            stepperHasError: true,
          })
          setTimeout(() => {
            this.setState({
              openSnackbar: false,
            })
            this.props.history.push("/dispatchOperation");
          }, 5000)
        }
      );

  }

  handleSnackbarMessageBox = (data) => {
    return (
      data
    )
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };


  handleResetStep = () => {
    this.setState({
      isWeightCaptured: false,
      capturedCurrentWeight: 0,
      capturingWeight: false,
      capturedWeight: false,
      weight: 0
    })
  }

  handleUpdateWeighing = async (selectedItems, enableRoute) => {
    this.setState({ selectedItems: selectedItems }, async () => {
      this.setState({ updateLoader: true, allowTakingWeights: false });
      this.handleAllowTakingWeights();
      if (!this.state.isWeightCaptured) {
        return;
      }
      this.setState({ isWeightCaptured: false });
      let data = {
        tare_weight: this.state.capturedCurrentWeight,
        gross_weight: this.state.capturedCurrentWeight,
        loaded_weight:
          this.props.laDetailsInWeighbridge?.dispatch_status === "GATEIN"
            ? 0.0
            : // this.props.laDetailsInWeighbridge.loading_advice_weighings.length === 0 ?

            // Number(this.props.laDetailsInWeighbridge.loading_advice_weighings[0].loaded_weight) +  Number(this.props.laDetailsInWeighbridge.tare_weight)
            this.handleLoadedWeight(),
        // this.state.capturedCurrentWeight -
        //   this.props.laDetailsInWeighbridge?.tare_weight,
        is_packed: false,
        weighbridge: this.state.weighbridgeDetails?.id,
        items: this.state.selectedItems
      };
      // return;
      await this.props
        .handleWeighing(data, this.props.laDetailsInWeighbridge?.id)
        .then(
          (data) => {
            if (data.status === 200) {
              this.props.handleFetchLoadingAdviceInWeighbridge(
                this.props.laDetailsInWeighbridge?.la_number
              );

              this.setState({
                capturingWeight: false,
                capturedWeight: false,
                updated: true,
                // markedAsPacked: false,
                updateLoader: false,
                openSnackbar: true,
                snackbarMessage: "Submitted successfully",
                snackbarType: "success",
                stepperHasError: false,
              })

              if (!enableRoute) {
                this.setState({ movetoPack: true })
              }

              if (enableRoute) {
                setTimeout(() => {
                  this.setState({
                    openSnackbar: false,
                  })
                  this.props.history.push("/dispatchOperation");
                }, 5000)
              }

            }
          }
        )
        .catch((error) => {
          this.setState({
            capturingWeight: false,
            capturedWeight: false,
            updated: true,
            // markedAsPacked: false,
            updateLoader: false,
            openSnackbar: true,
            snackbarMessage: error?.response?.data?.message
              ? error?.response?.data?.message :
              "Something went wrong. Please try again",
            snackbarType: "error",
            stepperHasError: true,
          }, () => {
            this.handleOpenSnackbar(this.state.snackbarMessage, "error")
          })

          setTimeout(() => {
            this.setState({
              openSnackbar: false,
            })
            this.props.history.push("/dispatchOperation");
          }, 5000)
        })
    })

  }

  handleFinish = () => {
    if (this.state.markedAsPacked) {
      this.setState({ updateLoader: true });
      let data = {
        dispatch_status: "PACKED",
        status_remark: this.state.weighbridgeDetails?.id,
      };
      this.props
        .handleDispatchOperationWeighingComplete(
          data,
          this.props.laDetailsInWeighbridge?.id
        )
        .then((data) => {
          if (data.status === 200) {
            this.props.handleFetchLoadingAdviceInWeighbridge(
              this.props.laDetailsInWeighbridge?.la_number
            );
            this.setState({
              capturingWeight: false,
              capturedWeight: false,
              updated: true,
              //markedAsPacked: false,
              updateLoader: false,
              openSnackbar: true,
              snackbarMessage: "Submitted successfully ",
              snackbarType: "success",
            })
            setTimeout(() => {
              this.setState({
                openSnackbar: false,
              })
              this.props.history.push("/dispatchOperation");
            }, 5000)
          }
        },
          (error) => {
            this.setState({
              capturingWeight: false,
              capturedWeight: false,
              updated: true,
              // markedAsPacked: false,
              updateLoader: false,
              openSnackbar: true,
              snackbarMessage: error?.response?.data?.msg
                ? error?.response?.data?.msg : "Something went wrong. Please try again",
              snackbarType: "error",
              stepperHasError: true,
            })
            setTimeout(() => {
              this.setState({
                openSnackbar: false,
              })
              this.props.history.push("/dispatchOperation");
            }, 5000)
          }
        )
    }
  };

  handleUnloadFinish = () => {

    this.setState({ updateLoader: true });
    let data = {
      dispatch_status: "UNLOADED",
      status_remark: this.state.weighbridgeDetails?.id,
    };
    this.props
      .handleDispatchOperationWeighingComplete(
        data,
        this.props.laDetailsInWeighbridge?.id
      )
      .then((data) => {
        if (data.status === 200) {
          this.props.handleFetchLoadingAdviceInWeighbridge(
            this.props.laDetailsInWeighbridge?.la_number
          );
          this.setState({
            capturingWeight: false,
            capturedWeight: false,
            updated: true,
            //markedAsPacked: false,
            updateLoader: false,
            openSnackbar: true,
            snackbarMessage: "Submitted successfully ",
            snackbarType: "success",
          })
          setTimeout(() => {
            this.setState({
              openSnackbar: false,
            })
            this.props.history.push("/dispatchOperation");
          }, 5000)
        }
      },
        (error) => {
          this.setState({
            capturingWeight: false,
            capturedWeight: false,
            updated: true,
            // markedAsPacked: false,
            updateLoader: false,
            openSnackbar: true,
            snackbarMessage: error?.response?.data?.msg
              ? error?.response?.data?.msg : "Something went wrong. Please try again",
            snackbarType: "error",
            stepperHasError: true,
          })
          setTimeout(() => {
            this.setState({
              openSnackbar: false,
            })
            this.props.history.push("/dispatchOperation");
          }, 5000)
        }
      )

  };

  componentWillUnmount() {
    this.serial(false);
  }
  handleDisableFinishButton = () => {
    if (this.state.capturingWeight && !this.state.capturedWeight) return true;
  };
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  isStatusGood = (name, weighbridge) => {
    let status = false;
    console.log(this.state.weighbridgeDetails?.plant === weighbridge?.plant_name, this.props.PlantFullName, name, weighbridge, this.props.match, this.state.weighbridgeDetails)
    switch (name.toUpperCase()) {
      case "LOADING STATUS":
        if (
          weighbridge?.dispatch_status?.toUpperCase() === "GATEIN" ||
          weighbridge?.dispatch_status?.toUpperCase() === "LOADING" ||
          weighbridge?.dispatch_status?.toUpperCase() === "UNLOADING"
        ) {
          status = true;
        }
        break;
      case "WEIGHBRIDGE":
        if (
          weighbridge?.is_weighbridge_locked == null ||
          !weighbridge?.is_weighbridge_locked ||
          (weighbridge?.is_weighbridge_locked &&
            (weighbridge?.weighbridge?.id === this.props.location.id) ||
            this.props.LANo && weighbridge?.weighbridge?.id === Number(this.props.match.params.wbId))
        ) {
          status = true;
        }

        break;
      case "PLANT STATUS":
        if ((this.state.weighbridgeDetails?.plant === weighbridge?.plant_name) ||
          this.props.LANo && this.props.PlantFullName === weighbridge?.plant_name
        ) {
          status = true;
        }
        break;
      case "TRUCK CAPACITY - ITEM WEIGHT":
        if (
          (
            (Number(weighbridge?.truck_capacity) + Number(weighbridge?.item_category?.max_tolerance_value)) -
            (Number(
              weighbridge?.gross_weight
            ).toFixed(3) -
              Number(
                weighbridge?.tare_weight
              ).toFixed(3))
          ).toFixed(3) >= 0
        ) {
          status = true;
        }
        break;
      case "LAST RANDOM WEIGHT":
        if (this.state.isRandomWtToleranceHigher !== true) {
          status = true;
        }
        break;
      default:
        break;
    }
    return status;
  };

  handleCancelButton = () => {
    // this.setState({
    //   laNumber: "",
    //   updated: false,
    //   markedAsPacked: false,
    //   capturingWeight: false,
    //   capturedWeight: false,
    // });
    // this.props.handleClearLoadingAdvice();
    // this.serial(false);
    this.props.history.push("/dispatchOperation");

  };

  decimalCount = (num) => {
    // Convert to String
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
  };

  handleTareWtBoxClick = (event) => {
    this.setState((state) => ({
      anchorEl: state.anchorEl ? null : event.currentTarget,
      openTareWeightBox: !state.openTareWeightBox
    }));
  };

  onCopyText = () => {
    this.setState(prevValue => { return { ...prevValue, isCopied: !prevValue.isCopied } })
    setTimeout(() => {
      this.setState({
        isCopied: false,
      });
    }, 1000);
  }

  chooseTolerance = (grossWt) => {
    let actualTolerance = 0;
    if (20.000 >= grossWt && grossWt > 0) {
      return actualTolerance = Number(this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_0to20_mts).toFixed(2);
    }
    if (20.001 <= grossWt && grossWt <= 30.000) {
      return actualTolerance = Number(this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_20to30_mts).toFixed(2);
    }
    if (grossWt >= 30.001) {
      return actualTolerance = Number(this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_above_30_mts).toFixed(2);
    }
    console.log(actualTolerance)
    return actualTolerance;
  }

  render() {
    const { laNumber, weight, errorMessage, openTareWeightBox, anchorEl } = this.state;
    const {
      classes,
      laDetailsInWeighbridge,
      laWeighbridgeLoader,
      weighbridgeDetails,
    } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          className={classes.breadcrumbsStyle}
        >
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
          >
            Dispatch Operations
          </Link>
          <Link color="inherit">Weighbridge</Link>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: '25%' }}>
            <Tooltip title="Print" placement="top">
              <ReactToPrint
                trigger={() => <Print style={{ marginRight: "3px", color: "#4b87c5", cursor: "pointer" }} />}
                content={() => this.WBPrint.current}
              />
            </Tooltip>

          </div>
        </Breadcrumbs>
        <Paper elevation={1} className={classes.paperStyle} ref={this.WBPrint}>
          <div className={classes.plantNameShowContainer}>
            <span>
              {this.state.weighbridgeDetails?.plant} -
              {this.state.weighbridgeDetails?.weighbridge_name}
            </span>
            <span>{moment().format("lll")}</span>
          </div>
          <Divider variant="middle" />
          <div
            style={{
              display: "flex",
              padding: "12px 12px 40px 12px",
              marginBottom: "22px",
              height: !laDetailsInWeighbridge ? "66vh" : null,
            }}
          >
            <div style={{ width: "50%" }}>
              <Paper className={classes.laNumberInputStyle} elevation={1}>
                {!this.state.capturingWeight && !this.state.capturedWeight ? (
                  <TextField
                    disabled={
                      this.props.laDetailsInWeighbridge !== null ||
                      this.state.updated
                    }
                    name="laNumber"
                    autoFocus
                    style={{ width: "85%" }}
                    id="outlined-adornment-amount"
                    variant="outlined"
                    label="Enter LA Number here"
                    value={laNumber.toUpperCase()}
                    helperText={
                      this.state.errorMessage ? (
                        <span style={{ color: COLORS.orange }}>
                          {errorMessage}
                        </span>
                      ) : this.props.errorMessage &&
                        !this.state.laNumberLengthError ? (
                        <span style={{ color: COLORS.orange }}>
                          {this.props.errorMessage}
                        </span>
                      ) : null
                    }
                    onChange={(e) => {
                      this.handleTextChange(e);
                      let la = (e.target.value).trim();
                      if (la.length >= 14 && la.length <= 15) {
                        this.setState({
                          laNumberLengthError: false,
                          errorMessage: "",
                        });
                        this.props.handleFetchLoadingAdviceInWeighbridge(la);
                        this.chooseTolerance(this.props.laDetailsInWeighbridge?.gross_weight);
                        this.checkRandomWtWithTolerance(this.props.laDetailsInWeighbridge?.gross_weight,
                          this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_0to20_mts,
                          this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_20to30_mts,
                          this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_above_30_mts,
                          this.props.laDetailsInWeighbridge?.random_weights[this.props.laDetailsInWeighbridge?.random_weights.length - 1]?.weight)
                      }
                    }}
                    onFocus={() => {
                      this.setState({ laNumberLengthError: false });
                    }}
                    onBlur={() => {
                      if (
                        !(
                          this.state.laNumber.length >= 14 &&
                          this.state.laNumber.length <= 15
                        )
                      ) {
                        this.setState({
                          laNumberLengthError: true,
                          errorMessage:
                            "Please fill valid 14 or 15 digit LA number",
                        });
                      } else {
                        this.setState({
                          laNumberLengthError: false,
                          errorMessage: null,
                        });
                      }
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: "2em", marginBottom: "12px" }}>
                      {this.state.capturedWeight
                        ? Number(this.state.capturedCurrentWeight).toFixed(3)
                        : Number(this.state.weight).toFixed(3)}
                      MT
                    </span>
                    {this.state.capturingWeight &&
                      (this.state.capturedWeight || this.state.isRandomWtCaptured) ? null : (
                      <Button
                        disabled={this.handleDisabledCaptureButton()}
                        variant="contained"
                        style={{
                          padding: "12px 30px",
                          backgroundColor: this.handleDisabledCaptureButton()
                            ? COLORS.grey
                            : COLORS.primaryColor,
                          color: "white",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          {
                            !(laDetailsInWeighbridge?.dispatch_status === "PACKED" || laDetailsInWeighbridge?.dispatch_status === "ALLOTED"
                              || laDetailsInWeighbridge?.dispatch_status === "BILLED") ? this.handleWeightCapture() : this.handleRandomWtCapture()
                          }

                        }}
                      >
                        Capture
                      </Button>
                    )}
                  </div>
                )}
                {/* {this.state.capturingWeight && !this.state.capturedWeight ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: "2em", marginBottom: "12px" }}>
                      {this.state.weight} MT
                    </span>
                    <Button
                      variant="contained"
                      style={{
                        padding: "12px 30px",
                        backgroundColor: COLORS.primaryColor,
                        color: "white",
                      }}
                      onClick={(e) => {
                        e.preventDefault();

                        this.handleWeightCapture();
                      }}
                    >
                      Capture
                    </Button>
                  </div>
                ) : (
                  <TextField
                    disabled={this.props.laDetails !== null}
                    name="laNumber"
                    autoFocus
                    style={{ width: "85%" }}
                    id="outlined-adornment-amount"
                    variant="outlined"
                    label="Enter LA Number here"
                    value={laNumber.toUpperCase()}
                    onChange={(e) => {
                      this.handleTextChange(e);
                      let la = e.target.value;
                      if (la.length === 11) {
                        this.props.handleFetchLoadingAdviceInWeighbridge(la);
                      }
                    }}
                  />
                )} */}
              </Paper>
              <div className={classes.InfoCardContainer} >
                {laDetailsInWeighbridge === null &&
                  laWeighbridgeLoader === false ? null : (
                  <WeighbridgeInfoCard
                    title_1="LOADING"
                    title_2="ADVICE"
                    title_value={
                      laDetailsInWeighbridge && laDetailsInWeighbridge.la_number
                    }
                    key_1="Item Category"
                    value_1={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.category_name
                    }
                    key_2="Gate In Date"
                    value_2={moment(
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.gate_in_date
                    ).format("DD/MM/YYYY")}
                    key_3="La Quantity"
                    value_3={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.la_quantity + "MT"
                    }
                    key_4="LA Status"
                    value_4={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.dispatch_status
                    }
                    infoLoader={laWeighbridgeLoader}
                  />
                )}
                {laDetailsInWeighbridge === null &&
                  this.props.laLoader === false ? null : (
                  <WeighbridgeInfoCard
                    title_1="TRUCK"
                    title_2="DETAILS"
                    title_value={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.truck_number
                    }
                    key_1="Transporter Name"
                    value_1={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.transporter_name
                    }
                    key_2="Driver Name"
                    value_2={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.driver_name
                    }
                    key_3="Truck Capacity"
                    value_3={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.truck_capacity + "MT"
                    }
                    key_4="Driver Number"
                    value_4={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.driver_contact_number
                    }
                    infoLoader={laWeighbridgeLoader}
                  />
                )}

                {laDetailsInWeighbridge === null &&
                  this.props.laLoader === false ? null : (
                  <WeighbridgeInfoCard
                    title_1="CUSTOMER"
                    title_2="DETAILS"
                    key_1="Booked by"
                    value_1={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.booked_by.first_name + " " +
                      laDetailsInWeighbridge.booked_by.last_name
                    }
                    key_2="Buyer"
                    value_2={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.buyer?.first_name + " " +
                      laDetailsInWeighbridge.buyer?.last_name
                    }
                    key_3="Consignee"
                    value_3={
                      laDetailsInWeighbridge &&
                      laDetailsInWeighbridge.consignee?.first_name + " " +
                      laDetailsInWeighbridge.consignee?.last_name
                    }
                    infoLoader={laWeighbridgeLoader}
                  />
                )}
                {laDetailsInWeighbridge === null &&
                  this.props.laLoader === false ? null : (
                  <WeighbridgeInfoCard
                    title_1="ITEM"
                    title_2="DETAILS"
                    // key_1="Booked by"
                    // value_1={
                    //   laDetailsInWeighbridge &&
                    //   laDetailsInWeighbridge.booked_by.first_name +
                    //     laDetailsInWeighbridge.booked_by.last_name
                    // }
                    // key_2="Buyer"
                    // value_2={
                    //   laDetailsInWeighbridge && laDetailsInWeighbridge.buyer
                    // }
                    // key_3="Consignee"
                    // value_3={
                    //   laDetailsInWeighbridge && laDetailsInWeighbridge.consignee
                    // }
                    infoLoader={laWeighbridgeLoader}
                    itemShow={true}
                    laDetailsInWeighbridge={
                      laDetailsInWeighbridge && laDetailsInWeighbridge
                    }
                  />
                )}
              </div>
            </div>
            <div className={classes.rightSideContainer}>
              {laDetailsInWeighbridge === null ? null : (
                <>
                  <div style={{ width: "92%", marginTop: "20px" }}>
                    <div className={classes.laNumberShow}>
                      <span style={{ marginTop: "5px" }}>

                        {laDetailsInWeighbridge &&
                          laDetailsInWeighbridge.la_number}
                      </span>
                      <span style={{ display: "flex" }}>
                        {laDetailsInWeighbridge &&
                          laDetailsInWeighbridge.truck_number}
                        {laDetailsInWeighbridge?.last_tare_weights?.length > 0 ?
                          <>
                            <Typography
                              aria-owns={Boolean(anchorEl) ? 'mouse-over-popover' : undefined}
                              aria-haspopup="true"
                              onMouseEnter={this.handleTareWtBoxClick}
                              onMouseLeave={this.handleTareWtBoxClick}
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: "inherit",
                                fontWeight: anchorEl === null ? "400" : "500",
                                color: anchorEl === null ? "#666" : "#3B69B0",
                                cursor: 'pointer',
                                display: "inline"
                              }}
                            >

                              <InfoOutlinedIcon />
                            </Typography>

                            <Popover
                              id="mouse-over-popover"
                              sx={{
                                pointerEvents: 'none',
                              }}
                              open={openTareWeightBox}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 130,
                                horizontal: 1150,
                              }}
                              disableRestoreFocus
                              className={classes.popoverBox}
                            >
                              {/* <Typography sx={{ p: 1 }}>
                              <p>
                                {laDetailsInWeighbridge?.last_tare_weights?.map((m) => m.toString().concat(" MT")).join(",")}
                              </p>
                            </Typography> */}
                              <div style={{ width: 200, padding: "5%" }}>
                                <Typography className={classes.dialogText}>
                                  <div style={{ color: COLORS.secondaryBlueText }}>
                                    Tare Weight
                                  </div>
                                  <div style={{ color: COLORS.secondaryBlueText }}>
                                    Date
                                  </div>
                                </Typography>
                                <Divider variant='middle' style={{ margin: '2% 0' }} />
                                {laDetailsInWeighbridge?.last_tare_weights?.map((tareWtData, index) => {
                                  return (
                                    <div key={index}>
                                      <Typography className={classes.dialogText}>
                                        <div className={classes.blue}>
                                          {tareWtData[0]} MT
                                        </div>
                                        <div>
                                          {moment(tareWtData[1]).format("DD-MM-YYYY")}
                                        </div>
                                      </Typography>
                                      <Divider
                                        variant='middle'
                                        style={{
                                          margin: '2% 0',
                                          display: index === ((laDetailsInWeighbridge?.last_tare_weights).length - 1) ? 'none' : 'block'
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </Popover>
                          </>
                          : ""}
                      </span>

                    </div>
                    <Divider variant="middle" />
                    <div>
                      {this.state.weighbridge_AllStatus.map((data) => {
                        let randomTolerance = Number(((laDetailsInWeighbridge?.random_weights[laDetailsInWeighbridge?.random_weights.length - 1]?.weight
                          - laDetailsInWeighbridge?.gross_weight) / laDetailsInWeighbridge?.gross_weight) * 100).toFixed(2);
                        let secondaryText = data.id === 5 ? laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) > Number(this.chooseTolerance(this.props.laDetailsInWeighbridge?.gross_weight)) ? `Vehicle is Overweight (${randomTolerance}%)`
                          : laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) < 0 ? `Vehicle is underweight (${randomTolerance}%)`
                            : laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) < Number(this.chooseTolerance(this.props.laDetailsInWeighbridge?.gross_weight)) ? `Weight in Tolerance (${randomTolerance}%)`
                              : "No Random Weight Done" : "";
                        return <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                style={
                                  this.isStatusGood(
                                    data.name,
                                    laDetailsInWeighbridge
                                  )
                                    ? { background: COLORS.veryLightGreen }
                                    : { background: COLORS.lightPink }
                                }
                              >
                                {this.isStatusGood(
                                  data.name,
                                  laDetailsInWeighbridge
                                ) ?
                                  (data.id === 2 && laDetailsInWeighbridge?.is_weighbridge_locked === false ?
                                    <Tooltip title="Weighbridge has been unlocked, vehicle can be loaded from any weighbridge in this plant" placement="top">

                                      <LockOpenOutlinedIcon style={{ color: COLORS.lightGreen_1 }} />
                                    </Tooltip>
                                    :
                                    <Check style={{ color: COLORS.lightGreen_1 }} />
                                  ) : (
                                    <Cancel style={{ color: COLORS.pink }} />
                                  )}
                              </Avatar>
                            </ListItemAvatar>
                            <Tooltip title={data.id === 5 && laDetailsInWeighbridge?.random_weights?.length > 0 ? "Tolerance is " + this.chooseTolerance(this.props.laDetailsInWeighbridge?.gross_weight) + "%" : ''} placement="top-start">
                              <ListItemText
                                primary={data.name}
                                secondary={secondaryText}
                                primaryTypographyProps={{
                                  style: { fontSize: "0.9em" },
                                }}
                                secondaryTypographyProps={{
                                  style: { fontSize: "0.9em" },
                                }}
                              />

                            </Tooltip>
                            {data.id === 5 ?
                              <>
                                <CopyToClipboard text={secondaryText}
                                  onCopy={() => this.onCopyText()}>
                                  {this.state.isCopied ? (<span style={{
                                    marginRight: laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) < 0 ?
                                      90 : laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) < Number(this.chooseTolerance(laDetailsInWeighbridge?.gross_weight)) ?
                                        110 : laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) > Number(this.chooseTolerance(laDetailsInWeighbridge?.gross_weight)) ? 90 : 130, fontSize: "10px", marginTop: 13, color: "#FFB600"
                                  }}>Copied!</span>) : (
                                    <ContentCopyOutlinedIcon
                                      style={{
                                        fontSize: "small",
                                        cursor: 'pointer',
                                        marginTop: 13,
                                        marginRight: laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) < 0 ?
                                          120 : laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) < Number(this.chooseTolerance(laDetailsInWeighbridge?.gross_weight)) ?
                                            150 : laDetailsInWeighbridge?.random_weights?.length > 0 && Number(randomTolerance) > Number(this.chooseTolerance(laDetailsInWeighbridge?.gross_weight)) ? 130 : 160
                                      }}
                                    />)
                                  }
                                </CopyToClipboard>
                              </>
                              : ""}

                            <ListItemSecondaryAction>
                              <span style={{ color: COLORS.grey }}>
                                {laDetailsInWeighbridge && data.id === 1 ? (
                                  laDetailsInWeighbridge.dispatch_status
                                ) : data.id === 2 ? (
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      {
                                        laDetailsInWeighbridge?.weighbridge
                                          ?.weighbridge_name
                                      }
                                    </div>

                                    <div>
                                      {laDetailsInWeighbridge?.is_weighbridge_locked ? (
                                        <LockOutlinedIcon fontSize="small" />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                ) : laDetailsInWeighbridge && data.id === 3 ? (
                                  laDetailsInWeighbridge.plant_name
                                ) : laDetailsInWeighbridge && data.id === 4 ? (
                                  (
                                    (Number(laDetailsInWeighbridge?.truck_capacity) +
                                      Number(laDetailsInWeighbridge?.item_category?.max_tolerance_value)) -
                                    (Number(
                                      laDetailsInWeighbridge?.gross_weight
                                    ).toFixed(3) -
                                      Number(
                                        laDetailsInWeighbridge?.tare_weight
                                      ).toFixed(3))
                                  ).toFixed(3)
                                ) : laDetailsInWeighbridge && data.id === 5 ? (
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      {laDetailsInWeighbridge?.random_weights?.length > 0 ?
                                        laDetailsInWeighbridge?.random_weights[laDetailsInWeighbridge?.random_weights.length - 1]?.weight : "--"
                                      }
                                    </div>

                                    {/* <div>

                                      {
                                        <Tooltip title={`random weight tolernace is ${laDetailsInWeighbridge?.random_weights?.length > 0 && Number(((laDetailsInWeighbridge?.random_weights[laDetailsInWeighbridge?.random_weights.length - 1]?.weight
                                          - laDetailsInWeighbridge?.gross_weight) / laDetailsInWeighbridge?.gross_weight) * 100).toFixed(3) || 0}`} placement="top">
                                          <InfoOutlinedIcon fontSize="small" />
                                        </Tooltip>

                                      }
                                    </div> */}
                                  </div>
                                ) : (
                                  data.title
                                )}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      }
                      )}
                    </div>
                  </div>
                  <div style={{ width: "130%", float: "left", marginBottom: "5%" }}>
                    {(this.isStatusGood("LOADING STATUS", laDetailsInWeighbridge) && this.isStatusGood("WEIGHBRIDGE", laDetailsInWeighbridge) &&
                      this.isStatusGood("PLANT STATUS", laDetailsInWeighbridge) && this.isStatusGood("TRUCK CAPACITY - ITEM WEIGHT", laDetailsInWeighbridge)) || (laDetailsInWeighbridge?.dispatch_status === "PACKED") ||
                      (laDetailsInWeighbridge?.dispatch_status === "ALLOTED") || (laDetailsInWeighbridge?.dispatch_status === "BILLED") ||
                      laDetailsInWeighbridge.dispatch_status === "UNLOADED" && (laDetailsInWeighbridge?.all_weighings.length !== 0 && laDetailsInWeighbridge?.dispatch_status === "UNLOADED") ?
                      <VerticalLinearStepper
                        weights={laDetailsInWeighbridge?.loading_advice_items}
                        totalWeightsTaken={
                          laDetailsInWeighbridge?.all_weighings?.slice(1, laDetailsInWeighbridge?.all_weighings?.length)
                        }
                        tareWeightDetails={
                          laDetailsInWeighbridge?.all_weighings?.slice(0, 1)?.[0]
                        }
                        handleStepperCaptureWeight={(selectedItems) => this.handleStepperCaptureWeight(selectedItems)}
                        handleWeightCapture={this.handleWeightCapture}
                        handleUpdateWeighing={(selectedItems, enableRoute) => this.handleUpdateWeighing(selectedItems, enableRoute)}
                        handleUpdateUnloadWeighing={this.handleUpdateUnloadWeighing}
                        capturedWeight={
                          this.props?.laDetailsInWeighbridge?.tare_weight
                        }
                        handleCancelButton={this.handleCancelButton}
                        tareWeightStatus={
                          laDetailsInWeighbridge.dispatch_status === "LOADING"
                        }
                        markedAsPacked={this.state.markedAsPacked}
                        handleFinish={this.handleFinish}
                        dispatchStatus={laDetailsInWeighbridge?.dispatch_status}
                        isPacked={laDetailsInWeighbridge?.is_packed}
                        allowTakingWeights={this.state.allowTakingWeights}
                        currentStep={this.state.currentStep}
                        initialiseStepperCount={(c) =>
                          this.initialiseStepperCount(c)
                        }
                        stepperHasError={this.state.stepperHasError}
                        isWeightCaptured={this.state.isWeightCaptured}
                        unloadedWeight={this.state.unloadedWeight}
                        unloadedTareWeight={this.state.unloadedTareWeight}
                        handleUnloadFinish={this.handleUnloadFinish}
                        updated={this.state.updated}
                        loadingAdviceWeighings={laDetailsInWeighbridge?.loading_advice_weighings}
                        allWeighings={laDetailsInWeighbridge?.all_weighings}
                        capturedCurrentWeight={this.state.capturedCurrentWeight}
                        handleIndividualItemValidation={this.handleIndividualItemValidation}
                        handleResetStep={this.handleResetStep}
                        movetoPack={this.state.movetoPack}
                        itemCategoryDetails={laDetailsInWeighbridge?.item_category}
                      /> : " "}
                    {(laDetailsInWeighbridge?.dispatch_status === "PACKED" || laDetailsInWeighbridge?.dispatch_status === "ALLOTED"
                      || laDetailsInWeighbridge?.dispatch_status === "BILLED") &&
                      <>
                        <div style={{ float: "Left", marginTop: "5%" }}>
                          <div style={{ display: "flex" }}>
                            <span><FiberManualRecordIcon style={{ fontSize: "35px", color: "#293C79" }} /></span>
                            <span className={classes.randomWtLabel}>Random Weight</span>
                          </div>
                          <div style={{
                            display: "flex",
                            flexDirection: "column"
                          }}>
                            <div style={{ float: "left", marginLeft: "20px", padding: "10px" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.randomWtButton}
                                onClick={this.handleTakeRandomWeight}
                                disabled={this.state.isRandomWtCaptured || this.props.LANo}
                              >
                                Take Random Weight
                              </Button>
                            </div>
                            <div style={{ float: "left", marginLeft: "20px", padding: "10px" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.randomWtSaveButton}
                                disabled={!this.state.isRandomWtCaptured}
                                onClick={() => {
                                  this.handleUpdateRandomWeight()
                                  this.chooseTolerance(this.props.laDetailsInWeighbridge?.gross_weight);
                                  this.checkRandomWtWithTolerance(laDetailsInWeighbridge?.gross_weight,
                                    this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_0to20_mts,
                                    this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_20to30_mts,
                                    this.props.laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_above_30_mts,
                                    this.state.capturedRandomWeight)
                                }
                                }
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                          {laDetailsInWeighbridge?.random_weights?.length > 0 &&
                            <Paper
                              style={{
                                //width: "200%",
                                minWidth: "160%",
                                padding: "10px 5px 5px",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
                                marginLeft: "30px"
                              }}
                            >
                              <div style={{ textAlign: "center" }}>Random Weight Check</div>
                              <RandomWeightTable randomWeights={laDetailsInWeighbridge?.random_weights}
                                grossWt={laDetailsInWeighbridge?.gross_weight}
                                tolerance_from_0to20_mts={laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_0to20_mts}
                                tolerance_from_20to30_mts={laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_from_20to30_mts}
                                tolerance_above_30_mts={laDetailsInWeighbridge?.loading_advice_items[0]?.item_detail?.item_natures?.tolerance_above_30_mts} />
                            </Paper>
                          }
                        </div>
                      </>
                    }
                  </div>
                </>
              )}
            </div>
          </div>
        </Paper >
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
          customAutoHideDuration={5000}
        />
      </div >
    );
  }
}
export default withRouter(withStyles(styles)(Weighbridge));
