import React, { useState } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableBody,
  Paper,
  Tooltip,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import COLORS from "../../../../constants/internal/colors";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import Spinner from "../../common/Spinner";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import InputAdornment from "@material-ui/core/InputAdornment";
import InfoIcon from "@material-ui/icons/Info";

function ItemCategoryDetailsComponent(props) {
  const {
    RateTemplate,
    classes,
    FetchingRateTemplate,
    FetchingItemType,
    FetchingRateLinking,
    RateLinking,
    ItemType,
    StateList,
  } = props;

  const [Snack, setSnack] = useState({ open: false, message: "" });
  const [ShowRateTemplateInput, setShowRateTemplateInput] = useState(false);
  const [ShowItemTypeInput, setShowItemTypeInput] = useState(false);
  const [ShowRateLinkingInput, setShowRateLinkingInput] = useState(false);

  const [RateTemplateInputError, setRateTemplateInputError] = useState({
    item_name: false,
    high_rate_difference: false,
    low_rate_difference: false,
  });
  const [ItemTypeInputError, setItemTypeInputError] = useState({
    item_name: false,
    section_name: false,
    section_weight: false,
    rate_difference: false,
  });
  const [RateLinkingInputError, setRateLinkingInputError] = useState({
    related_to: false,
    rate_difference: false,
  });

  const [RateTemplateAddForm, setRateTemplateAddForm] = useState({
    state: "",
    is_base_rate: false,
    high_rate_difference: "",
    low_rate_difference: "",
  });
  const [ItemTypeAddForm, setItemTypeAddForm] = useState({
    item_name: "",
    section_name: "",
    section_weight: "",
    rate_difference: "",
  });
  const [RateLinkinAddForm, setRateLinkinAddForm] = useState({
    related_to: "",
    rate_difference: "",
  });

  const helperClearAllAddInputs = () => {
    setShowRateTemplateInput(false);
    setShowItemTypeInput(false);
  };

  const helperResetRateTemplateAddForm = () => {
    setRateTemplateAddForm({
      state: "",
      is_base_rate: false,
      high_rate_difference: "",
      low_rate_difference: "",
    });
    setRateTemplateInputError({
      state: false,
      high_rate_difference: false,
      low_rate_difference: false,
    });
  };

  const helperResetItemTypeForm = () => {
    setItemTypeInputError({
      item_name: false,
      section_name: false,
      section_weight: false,
      rate_difference: false,
    });
    setItemTypeAddForm({
      item_name: "",
      section_name: "",
      section_weight: "",
      rate_difference: "",
    });
  };

  const helperResetRateLinkingForm = () => {
    setRateLinkingInputError({
      related_to: false,
      rate_difference: false,
    });
    setRateLinkinAddForm({
      related_to: "",
      rate_difference: "",
    });
  };

  const handleAddRateTemplateInput = (key, value) => {
    let newData = { ...RateTemplateAddForm, [key]: value };
    setRateTemplateAddForm(newData);
  };

  const handleAddItemTypeInput = (key, value) => {
    let newData = { ...ItemTypeAddForm, [key]: value };
    setItemTypeAddForm(newData);
  };

  const handleAddRateTemplateTable = () => {
    helperClearAllAddInputs();
    setShowRateTemplateInput(true);
  };

  const handleAddItemTypeTable = () => {
    helperClearAllAddInputs();
    setShowItemTypeInput(true);
  };

  const renderStateList = () => {
    let existingState = RateTemplate?.daily_rates_template.map(
      (d) => d.state?.state_name
    );
    let filterdState = StateList.filter(
      (d) => existingState.indexOf(d.state_name) === -1
    );
    return filterdState;
  };

  const handleValidationRateTemplateInput = () => {
    const current = { ...RateTemplateInputError };
    let allOk = true;

    if (
      isNaN(RateTemplateAddForm.state) ||
      RateTemplateAddForm.state.length <= 0
    ) {
      current.state = true;
      allOk = false;
    } else {
      current.state = false;
    }

    if (
      isNaN(RateTemplateAddForm.high_rate_difference) ||
      RateTemplateAddForm.high_rate_difference.length <= 0
    ) {
      current.high_rate_difference = true;
      allOk = false;
    } else {
      current.high_rate_difference = false;
    }
    if (
      isNaN(RateTemplateAddForm.low_rate_difference) ||
      RateTemplateAddForm.low_rate_difference.length <= 0 ||
      parseFloat(RateTemplateAddForm.low_rate_difference) > 0.0
    ) {
      current.low_rate_difference = true;
      allOk = false;
    } else {
      current.low_rate_difference = false;
    }

    setRateTemplateInputError(current);
    return allOk;
  };

  const handleValidationItemTypeInput = () => {
    const current = { ...ItemTypeInputError };
    let allOk = true;

    if (!ItemTypeAddForm.item_name || ItemTypeAddForm.item_name.length <= 0) {
      current.item_name = true;
      allOk = false;
    } else {
      current.item_name = false;
    }

    if (
      !ItemTypeAddForm.section_name ||
      ItemTypeAddForm.section_name.length <= 0
    ) {
      current.section_name = true;
      allOk = false;
    } else {
      current.section_name = false;
    }
    if (
      isNaN(ItemTypeAddForm.section_weight) ||
      ItemTypeAddForm.section_weight.length <= 0
    ) {
      current.section_weight = true;
      allOk = false;
    } else {
      current.section_weight = false;
    }
    if (
      isNaN(ItemTypeAddForm.rate_difference) ||
      ItemTypeAddForm.rate_difference.length <= 0
    ) {
      current.rate_difference = true;
      allOk = false;
    } else {
      current.rate_difference = false;
    }

    setItemTypeInputError(current);
    return allOk;
  };

  const handleValidationRateLinkingInput = () => {
    let current = { ...RateLinkingInputError };
    let allOk = true;

    if (isNaN(RateLinkinAddForm.related_to)) {
      current.related_to = true;
      allOk = false;
    } else {
      current.related_to = false;
    }

    if (
      isNaN(RateLinkinAddForm.rate_difference) ||
      RateLinkinAddForm.rate_difference.length <= 0
    ) {
      current.rate_difference = true;
      allOk = false;
    } else {
      current.rate_difference = false;
    }

    setRateLinkingInputError(current);
    return allOk;
  };

  const parseRateTemplateAddForm = () => {
    const currentData = { ...RateTemplateAddForm };
    const newData = {};
    newData.is_base_rate = currentData.is_base_rate;
    if (currentData.is_base_rate === false) {
      if (RateTemplate.daily_rates_template.length <= 0) {
        newData.is_base_rate = true;
      }
    }
    newData.state = parseInt(currentData.state);
    newData.high_rate_difference = parseFloat(
      currentData.high_rate_difference
    ).toFixed(3);
    newData.low_rate_difference = parseFloat(
      currentData.low_rate_difference
    ).toFixed(3);
    newData.item_category = parseInt(RateTemplate.id);
    return newData;
  };

  const parseItemTypeAddForm = () => {
    const currentData = { ...ItemTypeAddForm };
    const newData = {};
    newData.item_name = currentData.item_name;
    newData.section_name = currentData.section_name;
    newData.section_weight = parseFloat(currentData.section_weight).toFixed(3);
    newData.rate_difference = parseFloat(currentData.rate_difference).toFixed(
      3
    );
    newData.item_category = parseInt(RateTemplate.id);
    return newData;
  };

  const parseRateLinkingForm = () => {
    const currentData = { ...RateLinkinAddForm };
    const newData = {};
    newData.related_to = parseInt(currentData.related_to);
    newData.rate_difference = parseFloat(currentData.rate_difference).toFixed(
      3
    );
    newData.category_name = RateTemplate.category_name;
    newData.daily_rates_template = RateTemplate.daily_rates_template;
    return newData;
  };

  const handleSubmitRateTemplateInput = () => {
    if (!handleValidationRateTemplateInput()) {
      return;
    }
    let data = parseRateTemplateAddForm();
    props.postToRateTemplateTable(data);
    setShowRateTemplateInput(false);
    helperResetRateTemplateAddForm();
  };

  const handleSubmitItemTypeInput = () => {
    if (!handleValidationItemTypeInput()) {
      return;
    }
    let data = parseItemTypeAddForm();
    props.postToItemTypeTable(data);
    setShowItemTypeInput(false);
    helperResetItemTypeForm();
  };

  const handleSubmitRateLinkingInput = () => {
    if (!handleValidationRateLinkingInput()) {
      return;
    }
    let data = parseRateLinkingForm();
    props.putRateLinkingData(data, RateTemplate.id);
    setShowRateLinkingInput(false);
    helperResetRateLinkingForm();
  };

  const handleRateTemplateAddBaseRate = () => {
    let baseRateExist = false;
    RateTemplate.daily_rates_template.forEach((d, i) => {
      if (d.is_base_rate === true) {
        // show cannot mark as base rate already exists
        baseRateExist = true;
      }
    });
    if (baseRateExist) {
      setSnack({
        open: true,
        message: "Base Rate already exist! You can change it in edit mode",
      });
    } else {
      handleAddRateTemplateInput(
        "is_base_rate",
        !RateTemplateAddForm.is_base_rate
      );
    }
  };

  const renderTitleWithEditButton = (title, route) => (
    <Link to={route} style={{ textDecoration: "none" }}>
      <div
        style={{
          marginTop: 50,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <span className={classes.tableHeaderCell}>{title}</span>
        <IconButton aria-label="delete" disabled color="primary">
          <CreateOutlinedIcon style={{ height: 18, width: 18 }} />
          <span style={{ fontSize: 14 }}>edit</span>
        </IconButton>
      </div>
    </Link>
  );

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        {FetchingItemType || FetchingRateTemplate || FetchingRateLinking ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Bread
              data={[
                {
                  title: "Master Management",
                  onClick: () => props.history.goBack(),
                },
                {
                  title: "Item Categories",
                  onClick: () => props.history.goBack(),
                },
                {
                  title: RateTemplate?.category_name || "null",
                  onClick: () => props.history.goBack(),
                },
              ]}
            />
            <div style={{ marginTop: 50 }}>
              <span className={classes.tableHeaderCell}>Name</span>
              <span style={{ marginLeft: 50 }}>
                {RateTemplate?.category_name || ""}
                <Link
                  to={`${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/categoryName/${RateTemplate.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <IconButton aria-label="delete" disabled color="primary">
                    <CreateOutlinedIcon style={{ height: 18, width: 18 }} />
                    <span style={{ fontSize: 14 }}>edit</span>
                  </IconButton>
                </Link>
              </span>
            </div>
            {renderTitleWithEditButton(
              "RATE TEMPLATE TABLE",
              `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/rateTemplateTable/${RateTemplate.id}`
            )}
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderCell}>
                      STATE
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderCell}
                    >
                      HR DIFFERENCE
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderCell}
                    >
                      LR DIFFERENCE
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {RateTemplate &&
                    RateTemplate.daily_rates_template.map((d, index) => (
                      <TableRow
                        hover
                        key={`s${d.id}`}
                        className={classes.bodyTableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.productFont}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title={`${d.is_base_rate ? "Base Rate" : ""}`}
                            >
                              <StarBorderIcon
                                className={`${classes.IconStyle} ${d.is_base_rate ? classes.fullOpacity : ""
                                  }`}
                              />
                            </Tooltip>
                            {d.state?.state_name}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          {d.is_base_rate
                            ? "BASE RATE"
                            : "₹ " + d.high_rate_difference}
                        </TableCell>
                        <TableCell align="right">
                          ₹ {d.low_rate_difference}
                        </TableCell>
                      </TableRow>
                    ))}
                  {ShowRateTemplateInput && (
                    <TableRow hover={false} key={`rateTemplateinput`}>
                      <TableCell className={classes.productFont}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title={`Mark as Base Rate`}>
                            <StarBorderIcon
                              onClick={handleRateTemplateAddBaseRate}
                              className={`${classes.IconStyle} ${RateTemplateAddForm.is_base_rate
                                ? classes.fullOpacity
                                : ""
                                }`}
                            />
                          </Tooltip>
                          <Autocomplete
                            id="State"
                            size="small"
                            style={{ minWidth: 200, maxWidth: 300 }}
                            options={renderStateList()}
                            onChange={(event, newValue) =>
                              handleAddRateTemplateInput("state", newValue?.id)
                            }
                            getOptionLabel={(option) => option.state_name}
                            renderInput={(params) => (
                              <TextField
                                className={classes.formControl}
                                {...params}
                                label="State"
                                variant="outlined"
                                error={
                                  RateTemplateInputError.state ? true : false
                                }
                              />
                            )}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 250 }}>
                        {RateTemplateAddForm.is_base_rate ? (
                          "BASE RATE"
                        ) : (
                          <TextField
                            className={classes.formControl}
                            label="HR DIFFERENCE"
                            autoComplete="off"
                            variant="outlined"
                            size="small"
                            name="high_rate_difference"
                            error={
                              RateTemplateInputError.high_rate_difference
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleAddRateTemplateInput(
                                "high_rate_difference",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 250 }}>
                        <TextField
                          className={classes.formControl}
                          label="LR DIFFERENCE"
                          autoComplete="off"
                          variant="outlined"
                          size="small"
                          id="input-with-icon-textfield"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <Tooltip title="The value must be negative or zero">
                                  <InfoIcon
                                    style={{ color: "rgb(188, 188, 188)" }}
                                  />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            RateTemplateInputError.low_rate_difference
                              ? true
                              : false
                          }
                          name="low_rate_difference"
                          onChange={(e) =>
                            handleAddRateTemplateInput(
                              "low_rate_difference",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {ShowRateTemplateInput ? (
              <div style={{ marginTop: 15 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveButton}
                  onClick={() => {
                    handleSubmitRateTemplateInput();
                  }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setShowRateTemplateInput(false);
                    helperResetRateTemplateAddForm();
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                variant="outlined"
                size="medium"
                startIcon={<AddOutlinedIcon />}
                className={classes.addNewButton}
                onClick={handleAddRateTemplateTable}
              >
                ADD NEW
              </Button>
            )}
            {renderTitleWithEditButton(
              `ITEMS UNDER ${RateTemplate?.category_name.toUpperCase() || ""}`,
              `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/ItemTypeTable/${RateTemplate.id}`
            )}
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderCell}>
                      ITEM NAME
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderCell}
                    >
                      RATE DIFFERENCE
                    </TableCell>
                    <TableCell align="left" className={classes.tableHeaderCell}>
                      SECTION NAME
                    </TableCell>
                    <TableCell align="left" className={classes.tableHeaderCell}>
                      SECTION WEIGHT
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ItemType &&
                    ItemType.map((d, index) => (
                      <TableRow
                        hover
                        key={`s${d.id}`}
                        className={classes.bodyTableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.productFont}
                        >
                          {d.item_name || ""}
                        </TableCell>
                        <TableCell align="right">
                          ₹ {d.rate_difference}
                        </TableCell>
                        <TableCell align="left">{d.section_name}</TableCell>
                        <TableCell align="left">
                          {d.section_weight} MT
                        </TableCell>
                      </TableRow>
                    ))}
                  {ShowItemTypeInput && (
                    <TableRow hover={false} key={`ItemTypeTemplateinput`}>
                      <TableCell className={classes.productFont}>
                        <TextField
                          className={classes.formControl}
                          label="Item Name"
                          autoComplete="off"
                          variant="outlined"
                          size="small"
                          name="item_name"
                          error={ItemTypeInputError.item_name ? true : false}
                          onChange={(e) =>
                            handleAddItemTypeInput("item_name", e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 250 }}>
                        {RateTemplateAddForm.is_base_rate ? (
                          "BASE RATE"
                        ) : (
                          <TextField
                            className={classes.formControl}
                            label="Rate Difference"
                            autoComplete="off"
                            variant="outlined"
                            size="small"
                            name="rate_difference"
                            error={
                              ItemTypeInputError.rate_difference ? true : false
                            }
                            onChange={(e) =>
                              handleAddItemTypeInput(
                                "rate_difference",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 250 }}>
                        <TextField
                          className={classes.formControl}
                          label="Section Name"
                          autoComplete="off"
                          variant="outlined"
                          size="small"
                          error={ItemTypeInputError.section_name ? true : false}
                          name="section_name"
                          onChange={(e) =>
                            handleAddItemTypeInput(
                              "section_name",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 250 }}>
                        <TextField
                          className={classes.formControl}
                          label="Section Weight"
                          autoComplete="off"
                          variant="outlined"
                          size="small"
                          error={
                            ItemTypeInputError.section_weight ? true : false
                          }
                          name="section_weight"
                          onChange={(e) =>
                            handleAddItemTypeInput(
                              "section_weight",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {ShowItemTypeInput ? (
              <div style={{ marginTop: 15 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveButton}
                  onClick={() => handleSubmitItemTypeInput()}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setShowItemTypeInput(false);
                    helperResetItemTypeForm();
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                variant="outlined"
                size="medium"
                startIcon={<AddOutlinedIcon />}
                className={classes.addNewButton}
                onClick={handleAddItemTypeTable}
              >
                ADD NEW
              </Button>
            )}
            {renderTitleWithEditButton(
              `RATE LINKING`,
              `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/RateLinkingTable/${RateTemplate.id}`
            )}
            {ShowRateLinkingInput ? (
              <React.Fragment>
                <div
                  style={{ marginTop: 15, marginBottom: 30, marginRight: 30 }}
                >
                  <div>
                    {`If you link ${RateTemplate.category_name} to a parent category, its daily rates can be derived from the parent category's Base Rate.`}
                  </div>
                  <div>
                    {`This does not allows auto publish rates, user wil still have to manually approve and publish daily rates.`}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 15,
                  }}
                >
                  <span>Category to link &nbsp;&nbsp;</span>
                  <Autocomplete
                    id="ItemCategory"
                    size="small"
                    style={{ minWidth: 200, maxWidth: 300 }}
                    options={props.AllCategories}
                    onChange={(event, newValue) =>
                      setRateLinkinAddForm({
                        ...RateLinkinAddForm,
                        related_to: newValue?.id,
                      })
                    }
                    getOptionLabel={(option) => option.category_name}
                    renderInput={(params) => (
                      <TextField
                        className={classes.formControl}
                        {...params}
                        label="Item Category"
                        variant="outlined"
                        error={false}
                      />
                    )}
                  />
                  <span style={{ marginLeft: 50 }}>
                    Rate Difference &nbsp;&nbsp;
                  </span>
                  <TextField
                    className={classes.formControl}
                    label="Rate Difference"
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    name="rate_difference"
                    value={RateLinkinAddForm.rate_difference}
                    error={false}
                    onChange={(e) =>
                      setRateLinkinAddForm({
                        ...RateLinkinAddForm,
                        rate_difference: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={() => {
                      handleSubmitRateLinkingInput();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setShowRateLinkingInput(false);
                      helperResetRateLinkingForm();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {RateLinking ? (
                  <div style={{ marginTop: 5 }}>
                    <span className={classes.tableHeaderCell}>LINKED WITH</span>
                    <span style={{ marginLeft: 50 }}>{RateLinking.name}</span>
                    <span
                      style={{ marginLeft: 50 }}
                      className={classes.tableHeaderCell}
                    >
                      RATE DIFFERENCE
                    </span>
                    <span style={{ marginLeft: 50 }}>
                      ₹ {RateTemplate.rate_difference}
                    </span>
                  </div>
                ) : (
                  <React.Fragment>
                    No rates linked
                    <br />
                    <Button
                      variant="outlined"
                      size="medium"
                      startIcon={<AddOutlinedIcon />}
                      className={classes.addNewButton}
                      onClick={() => setShowRateLinkingInput(true)}
                    >
                      ADD NEW
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div style={{ height: 100 }} />
      </div>
      <SnackbarComponent
        open={Snack.open}
        handleClose={() => setSnack({ ...Snack, open: false })}
        type={"warning"}
        snackbarMessage={Snack.message}
      />
    </Layout>
  );
}

const styles = (theme) => ({
  displayData: {
    marginTop: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
    paddingRight: "25px",
  },
  tableContainer: {
    marginTop: 5,
  },
  tableHeader: {
    backgroundColor: COLORS.whiteSmoke,
  },
  tableHeaderCell: {
    color: COLORS.tableHeaderText,
    fontWeight: "bold",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: COLORS.grey,
  },
  rightItemFix: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    float: "right",
  },
  bodyTableRow: {
    "&:hover": {
      backgroundColor: COLORS.tableHoverColor + " !important",
      cursor: "pointer",
    },
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: COLORS.whiteSmoke,
    overflow: "hidden",
    color: COLORS.grey,
    "&:hover": {
      backgroundColor: COLORS.whiteSmoke,
      width: 70,
      transition: "width 0.3s",
      cursor: "pointer",
    },
  },
  addButton: {
    float: "right",
    marginBottom: 30,
  },
  IconStyle: {
    color: COLORS.secondaryGrey,
    width: 20,
    height: 20,
    opacity: 0.2,
    marginRight: 2,
  },
  fullOpacity: {
    opacity: 1,
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${COLORS.secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: COLORS.grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveButton: {
    backgroundColor: COLORS.successButtonBackground,
    color: COLORS.drawerTextColor,
    marginRight: 10,
    "&:hover": {
      backgroundColor: COLORS.successButtonBackgroundHover,
    },
  },
});

export default withStyles(styles)(ItemCategoryDetailsComponent);
