import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import {
  whiteSmoke,
  primaryColor,
  drawerTextColor,
  grey,
} from '../../../../constants/internal/colors';
import Typography from '@material-ui/core/Typography';
import SingleProductPastRatesDialog from './SingleProductPastRatesDialog';
import moment from 'moment';
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { pxToEm } from '../../../../methods';
import DownlodPdf from './DownloadPdf';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tooltip,
  TablePagination,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
const useStyles = makeStyles({
  inputWidth: {
    maxWidth: 130,
    height: 35,
  },
  container: {
    maxHeight: 440,
    width: '100%',
  },
  printDownloadDiv: {
    display: 'flex',
    padding: 10,
    placeItems: 'center',
    justifyContent: 'flex-end',
  },
  publishDetailsDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  publishActionDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightSpacing: {
    marginRight: 10,
    fontSize: pxToEm(14),
    textDecoration: "none"
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
  },
  noDataFoud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 30,
  },
  clickDetailsIcon: { cursor: 'pointer' },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: whiteSmoke,
    fontWeight: 'bold',
    color: grey,
    fontSize: pxToEm(13),
  },
  body: {
    fontSize: pxToEm(13),
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

export default function DisplayPastRatesData({
  pastRateDataToDisplay,
  csvPastRateData,
  csvPastRateLoader,
  handleGetSelectedCategoryData,
  selectedCategoryData,
  pastRatePublishedBy,
  pastRatePublishedByUID,
  pastRatePublishDate,
  selectedFilteredCategoryName,
  selectedCategoryDataLoader,
  startDate,
  endDate,
  handleChangePage,
  handleCsvDownload,
  page,
  pastRatesDataCount
}) {
  const classes = useStyles();
  const [showSingleProductPastRate, setOpen] = React.useState(false);

  const handleCsvDownloadClick = () => {
    // handleCsvDownload()
  }

  const handleChangePageDPRD = (event, newPage) => {
    handleChangePage(newPage);
  };
  const handleSingleProductPastRateDialogOpen = (categoryId, date) => {
    setOpen(true);
    handleGetSelectedCategoryData(categoryId, date);
  };
  const handleSingleProductPastRateDialogClose = () => {
    setOpen(false);
  };
  const handlePrintTable = () => {
    let printContents = document.getElementById('scroll_table').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML =
      '<html><head><title></title></head><body>' + printContents + '</body>';
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  return (
    <div className={classes.pastRatePriceDiv}>
      {pastRateDataToDisplay && pastRateDataToDisplay.length > 0 ? (
        <>
          <TableContainer
            id="scroll_table"
            component={Paper}
            className={classes.container}
          >
            <Table
              stickyHeader
              className={classes.table}
              aria-label="products-state-price-table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">DATE</StyledTableCell>
                  <StyledTableCell align="left">ITEM CATEGORY</StyledTableCell>
                  <StyledTableCell align="right">OPEN RATE</StyledTableCell>
                  <StyledTableCell align="right">NET RATE</StyledTableCell>
                  <StyledTableCell align="left">TIME</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pastRateDataToDisplay.map(
                  (pastRateDetails, pastRateDetailsIndex) => (
                    <StyledTableRow
                      key={pastRateDetailsIndex}
                      onClick={() =>
                        handleSingleProductPastRateDialogOpen(
                          pastRateDetails.categoryId,
                          pastRateDetails.createdAt
                        )
                      }
                    >
                      <StyledTableCell component="th" scope="row" align="left">
                        {moment(pastRateDetails.createdAt).format('DD/MM/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {pastRateDetails.categoryName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        ₹{parseFloat(pastRateDetails.highRate).toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        ₹{parseFloat(pastRateDetails.lowRate).toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(pastRateDetails.createdAt).format('LT')}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.clickDetailsIcon}
                      >
                        <Tooltip
                          title={`Click to view ${pastRateDetails.categoryName
                            } - ${moment(pastRateDetails.createdAt).format(
                              'DD/MM/YYYY'
                            )} details`}
                          placement="right"
                          arrow
                        >
                          <ErrorOutlineIcon />
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100]}
            component="div"
            count={pastRatesDataCount}
            rowsPerPage={100}
            page={page}
            onChangePage={handleChangePageDPRD}
          // onChangeRowsPerPage={handleChangePageDPRD}
          />
          <div className={classes.printDownloadDiv}>
            <Button
              startIcon={<PrintOutlinedIcon />}
              onClick={() => handlePrintTable()}
              className={classes.rightSpacing}
            >
              PRINT
            </Button>

            {!csvPastRateLoader ? (
              <Tooltip title="Download CSV" placement="top">
                <CSVLink
                  data={csvPastRateData}
                  filename={`past_rate_${new Date().toISOString()}.csv`}
                >
                  <Button
                    startIcon={<CloudDownloadOutlinedIcon />}
                    className={classes.rightSpacing}
                  >
                    DOWNLOAD
                  </Button>
                </CSVLink>
              </Tooltip>
            ) : (
              <Button
                startIcon={<CloudDownloadOutlinedIcon />}
                disabled={true}
              >
                LOADING...
              </Button>
            )}


            {/* <DownlodPdf
              fileName={`Past_Rate_Details_${moment(new Date()).format(
                'DD/MM/YYYY_LT'
              )}`}
              printSection="scroll_table"
              headerDate={'Past Rate Details'}
            /> */}
          </div>
          <SingleProductPastRatesDialog
            selectedCategoryData={selectedCategoryData}
            showSingleProductPastRate={showSingleProductPastRate}
            handleSingleProductPastRateDialogClose={
              handleSingleProductPastRateDialogClose
            }
            pastRatePublishedBy={pastRatePublishedBy}
            pastRatePublishedByUID={pastRatePublishedByUID}
            pastRatePublishDate={pastRatePublishDate}
            selectedFilteredCategoryName={selectedFilteredCategoryName}
            selectedCategoryDataLoader={selectedCategoryDataLoader}
          />
        </>
      ) : (
        <div className={classes.noDataFoud}>
          <Typography className={classes.body}>
            No data found! Try changing category or date..
          </Typography>
        </div>
      )}
    </div>
  );
}
