import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "../../../../../constants/internal/colors";
import { Divider, Paper } from "@material-ui/core";
import ContractsPlantPlaningTable from "./ContractsPlantPlaningTable";
import SpinLoader from "../../../../common/SpinLoader";

const styles = {
  customerDetailContainer: {
    paddingTop: "10px",
    width: "100%",
    paddingLeft: "32px",
    paddingBottom: "55px",
  },
  customerDetailChildDiv: {
    paddingTop: "32px",
  },
  mainDiv: { height: "100%" },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "100%",
    paddingBottom: "20px",
    height: "calc(100vh - 320px)",
  },
  customerNameTextStyle: {
    color: grey,
    fontWeight: "bold",
    width: "25%",
  },

  checkboxContainerStyle: {
    display: "flex",
    paddingTop: "20px",
    paddingLeft: "22px",
    alignItems: "center",
  },

  recentActivityPaper: {
    padding: "10px 10px 10px 30px",
    width: "45%",
    marginTop: "30px",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
  recentActivityTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    alignItems: "center",
  },
  recentActivityTextDivLeft: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.6em",
  },
  loaderDiv: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    placeContent: "center",
  },
};
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
class SingleContractsItemAndPlantPanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddPlant: false,
      plantTableEditFiledIndex: null,
      totalCompletedQty: 0,
      totalPendingQty: 0,
      orderDetails: [],
      orderQuantity: 0,
      orderPlantPlanningData: [],
    };
  }
  handleChange = (name) => (event) => {
    const { value } = event.target;
    this.setState({ [name]: value });
  };
  componentDidMount() {
    this.setState({
      orderPlantPlanningData: this?.props?.orderPlantPlanningData || [],
      orderDetails: this?.props?.orderDetails || [],
      orderQuantity: this?.props?.orderDetails?.quantity || 0,
    });
    this.handlePendingAndCompletedQuantity();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.orderPlantPlanningData !== this.props.orderPlantPlanningData
    ) {
      this.setState({ rows: this.props.orderPlantPlanningData });
      this.handlePendingAndCompletedQuantity();
    }
    if (prevProps.orderDetails !== this.props.orderDetails) {
      this.setState({
        orderDetails: this.props.orderDetails,
        orderQuantity: this.props.orderDetails.quantiity,
      });
    }
  }
  handlePendingAndCompletedQuantity = () => {
    let totalCompletedQty =
      this?.state?.orderPlantPlanningData.length > 0
        ? this?.state?.orderPlantPlanningData
          .map((o) => o.dispached_qty)
          .reduce((a, c) => {
            return Number(a) + Number(c);
          })
        : 0;

    let totalPendingQty =
      Number(this?.state?.orderDetails?.quantity) - totalCompletedQty;
    this.setState({ totalPendingQty, totalCompletedQty });
  };

  render() {
    const { classes, itemCategoryCode } = this.props;
    const { orderDetails, totalCompletedQty, totalPendingQty } = this.state;
    return (
      <div className={classes.mainDiv}>
        <Paper elevation={1} className={classes.paperStyle}>
          {this?.props?.orderPlantPlanningDataLoader ? (
            <div className={classes.loaderDiv}>
              <SpinLoader />
            </div>
          ) : (
            <>
              <div className={classes.customerDetailContainer}>
              </div>
              <div id="test" style={{ paddingBottom: 45, }}>
                <ContractsPlantPlaningTable
                  user={this.props.user}
                  itemCategoryCode={itemCategoryCode}
                  {...this.state}
                  {...this.props} />
              </div>
            </>
          )}
        </Paper>
      </div>
    );
  }
}
export default withRouter(
  withStyles(styles)(SingleContractsItemAndPlantPanning)
);
