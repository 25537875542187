import React, { Component } from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from '@mui/material/InputLabel';
// import FormControl from "@material-ui/core/FormControl";
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

const styles = {
    formControlStyle: {
        width: "100%",
    },
}
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null

}

export class FilterByLAStatus extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }
    render() {
        const { laStatus, selectedLAStatusFilter, selectedFilter, disableOptions, filters } = this.props
        console.log(selectedLAStatusFilter)
        return (
            <div style={styles.formControlStyle}>
                <FormControl fullWidth>
                    <InputLabel id="label-id"
                        ref={(ref) => {
                            this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple">Select LA Status</InputLabel>
                    <Select
                        labelId="label-id"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedFilter}
                        onChange={this.props.handleLAStatusMultiSelect}
                        input={<OutlinedInput label="  . Select LA Status" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {
                            // tabValue === 0 ?
                            // <MenuItem value="All">
                            //     <Checkbox checked={selectedFilter.indexOf("All") > -1} />
                            //     <ListItemText primary="All" />
                            // </MenuItem>
                            // :
                            // <MenuItem disabled value="default">Select {selectFor}</MenuItem>
                        }
                        {/* <MenuItem value="All">All</MenuItem> */}
                        {filters.map(({ label, value, disabled }, i) => (
                            <MenuItem key={"s" + i} disabled={disableOptions} value={value} >
                                <Checkbox checked={selectedFilter.indexOf(value) > -1} />
                                <ListItemText primary={label} />
                            </MenuItem>
                            // <MenuItem key={"s" + i} disabled={disabled} value={value}>
                            //   {label}
                            // </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* <FormControl variant="outlined" style={styles.formControlStyle}>
                    <InputLabel
                        id="label-id"
                        ref={(ref) => {
                            this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                    >
                        LA Status
                    </InputLabel>
                    <Select
                        // id="demo-simple-select-outlined"
                        // value={this.state.age}
                        // onChange={this.handleChange}
                        input={
                            <OutlinedInput
                                label="LA Status"
                                labelWidth={this.state.labelWidth}
                                name="PaymentTerm"
                                id="outlined-age-simple"
                                value={selectedLAStatusFilter && selectedLAStatusFilter || ""}
                            />
                        }
                        MenuProps={MenuProps}
                    >
                        {laStatus &&
                            laStatus.map((data) => (
                                <MenuItem
                                    key={data.id}
                                    value={data.value}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleSelect({ field: "selectedLAStatusFilter", data, selectedName: data.value })
                                    }}
                                >
                                    {data.label}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl> */}
            </div>
        )
    }
}

export default FilterByLAStatus