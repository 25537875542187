import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor, secondaryColor, errorMessageColor, primaryColor, } from "../../../styles/colors";
import Info from "@material-ui/icons/Info";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import moment from "moment";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Assignment from "@material-ui/icons/Assignment";
import { LottieAnimation } from "react-lottie-tools";
import Dispatched from "../../../styles/b2b/Animations/Dispatched.json"
import GreenCheck from "../../../styles/b2b/Animations/GreenCheck.json"
import { pxToEm } from "../../../methods";
// import CancelPresentation from "@material-ui/icons/CancelPresentation";
// import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
// import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
// import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// import Info from "@material-ui/icons/Info";
// import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';



const styles = {
  orderDetailMainContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  orderDetailChildContainer: {
    borderRadius: "12px",
    paddingBottom: window.innerWidth < 310 && 12,
    width: "90%",
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  },
  orderDetailChildTopBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 20px 0px",
    color: bottomNavigationFontColor,
  },
  orderShowMainDetail: {
    padding: "0px 20px 15px 20px",
    display: "flex",
    justifyContent: "space-around",
    height: "80%",
    alignItems: "center",
  },
  orderShowMainDetailLeft: {
    width: window.innerWidth < 375 ? "25%" : "35%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  orderShowMainDetailRight: {
    width: window.innerWidth < 375 ? "70%" : "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  orderShowHorizontalLine: {
    width: "0.4%",
    height: "80%",
    background: bottomNavigationFontColor,
  },
  orderShowMainDetailRightTop: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 0px 3px 10px",
  },
  orderStatusIcon: {

    height: "0.8em",
    width: "0.8em",
    marginRight: "3px",
    top: "2px",
    // animation: "spin 1s infinite linear",
  }
};

class OrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // details: {},
      // previousDetails: {}
      // || {
      //   count: 0
      // }
    };
  }
  // componentDidMount() {
  //   let details = this.props.details
  //   if (details === {}) {
  //     // do nothing
  //     this.setState({
  //       details: this.state.previousDetails
  //     })
  //   } else {
  //     this.setState({
  //       previousDetails: details
  //     })
  //   }
  // }
  render() {
    const { classes, itemCategory, quantity, LANumber, plantName, truckNumber, dispatchStatus, orderNumber, orderId } = this.props;
    // console.log(details)
    // console.log(this.state.previousDetails)
    // console.log(this.props)
    return (
      <div>
        <div className={classes.orderDetailMainContainer}>
          <div className={classes.orderDetailChildContainer}>
            <div className={classes.orderDetailChildTopBar}>
              <span>
                <span style={{ fontSize: window.innerWidth < 375 && pxToEm(13.5) }}>Contract </span>
                <span
                  style={{
                    color: primaryColor,
                    fontSize: window.innerWidth < 375 && pxToEm(13.5)
                  }}
                  onClick={(e) => {
                    // e.preventDefault();
                    this.props?.history?.push("/dispatch")
                    this.props?.history?.push("/")
                    this.props.history.push({ pathname: `/orders/active/${orderId}` })
                  }}
                >
                  {orderNumber}
                </span>
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: window.innerWidth < 375 && pxToEm(13.5)
                }}
              >{(dispatchStatus === "PENDING") ?
                <HourglassEmpty
                  style={{ color: secondaryColor, marginRight: "4px", transform: window.innerWidth < 375 && 'scale(0.8)' }}
                />
                : dispatchStatus === "DISPATCHED" ?
                  // <LottieAnimation
                  //   animation={Dispatched}
                  //   style={{ width: "24px", height: "24px" }}
                  //   loop
                  // />
                  <LocalShippingOutlinedIcon style={{ color: secondaryColor, marginRight: 5, transform: window.innerWidth < 375 && 'scale(0.8)' }} />
                  : dispatchStatus === "APPROVED" ?
                    <LottieAnimation
                      animation={GreenCheck}
                      style={{ width: "24px", height: "24px", transform: window.innerWidth < 375 && 'scale(0.8)' }}

                    />
                    // <CheckCircleOutlinedIcon style={{ color: "green", marginRight: 5 }} />
                    : dispatchStatus === "BILLED" ?
                      <Assignment style={{ color: secondaryColor, marginRight: 5, transform: window.innerWidth < 375 && 'scale(0.8)' }} />
                      : (dispatchStatus === "CANCELLED" || dispatchStatus === "REJECTED") ?
                        <HighlightOffRoundedIcon style={{ color: "red", marginRight: 5, transform: window.innerWidth < 375 && 'scale(0.8)' }} />
                        :
                        (dispatchStatus === "GATEIN" ||
                          dispatchStatus === "PACKED" ||
                          dispatchStatus === "ALLOTED" ||
                          dispatchStatus === "LOADING" ||
                          dispatchStatus === "HOLD" ||
                          dispatchStatus === "LAPSED" ||
                          dispatchStatus === "UNLOADING" ||
                          dispatchStatus === "UNLOADED"
                        ) ?
                          <Info style={{ color: errorMessageColor, marginRight: 5, transform: window.innerWidth < 375 && 'scale(0.8)' }} />
                          : null
                }
                {` ${dispatchStatus}`}
              </span>
            </div>
            <div className={classes.orderShowMainDetail}>
              <div className={classes.orderShowMainDetailLeft}>
                <span
                  style={{
                    // color: bottomNavigationFontColor,
                    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.2em",
                  }}
                >
                  {itemCategory}
                </span>
                {/* <span style={{ fontSize: "1.2em" }}>{`₹${parseInt(details.rate).toFixed(0)}`}</span> */}
                <span
                  style={{
                    fontSize: "0.7em",
                    color: bottomNavigationFontColor,
                    marginTop: "3px",
                  }}
                >
                  {plantName}
                </span>
              </div>
              <div className={classes.orderShowHorizontalLine}></div>
              <div className={classes.orderShowMainDetailRight}>
                {

                  <div className={classes.orderShowMainDetailRightTop} style={{
                    marginTop: "2vh",
                    fontSize: window.innerWidth < 400 ? "0.9em" : "1em",
                  }}>
                    <span style={{ color: bottomNavigationFontColor }}>
                      LA No.
                    </span>
                    <span style={{ fontSize: window.innerWidth < 400 ? "0.9em" : "1em", }}>{LANumber}</span>
                  </div>
                }
                {/* {
                                        title !== "Action needed" &&

                                        <div className={classes.orderShowMainDetailRightTop}>
                                            <span style={{ color: bottomNavigationFontColor }}>
                                                Dispatched
                                            </span>
                                            <span>{details.dispatch_detail.dispatched_qty}MT</span>
                                        </div>
                                    } */}
                <div className={classes.orderShowMainDetailRightTop}>
                  <span style={{
                    color: bottomNavigationFontColor,
                    fontSize: window.innerWidth < 400 ? "0.9em" : "1em",
                  }}>
                    Truck No.
                  </span>
                  <span style={{ fontSize: window.innerWidth < 400 ? "0.8em" : "1em", }}>{truckNumber.toUpperCase()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default withStyles(styles)(OrderCard);
