import React, { Component } from 'react';
import DashboardWeb, {
  DashboardMobile,
} from '../../../components/internal/dashboard/index';
class InvoiceAndPaymentsContainer extends Component {
  render() {
    return this?.props?.isMobile ? (
      <DashboardMobile {...this.props} />
    ) : (
      <DashboardWeb {...this.props} />
    );
  }
}
export default InvoiceAndPaymentsContainer;
