import React, { createRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CaptilizeText, arraySortBykeyAllBrowser, checkApiError, fetchApiDataAndUpdateLocalStorage, handleSnackbarMessageBox, hasReadAccess, pxToEm, saveCSVInMobile } from '../../../../methods';
import Divider from '@material-ui/core/Divider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import DateRangeIcon from '@mui/icons-material/DateRange';
import moment from "moment";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import { MuiDateRangePicker } from "./DateRangePicker";
import { CircularProgress, Collapse, Skeleton } from "@mui/material";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import SnackbarComponent from "../../common/SnackbarComponent";
import { LineSalesGraph } from "./BarAndLineSalesGraph";
import { IconButton, Paper, Typography } from "@material-ui/core";
import PdfCsvButton from "../../common/PdfCsvButton";
import { handlePrintPDF } from "./PDFPrintContent";
import { tableHeaders } from "./header";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { primaryColor } from "../../../../styles/colors";
import { ToggleButton } from "./commanWidget";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: 'separate',
    // borderSpacing: '5px 4px'
  },
  paper: {
    position: 'fixed',
    top: '60%',
    left: '70%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '65%',
    zIndex: theme.zIndex.modal + 1,
  },
  underline: {
    content: "''",
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '50%', // Set the length of the underline
    borderBottom: '1px solid #000', // Style and color of the underline
  },
  root: {
    "&.MuiButtonGroup-root": {
      borderColor: "grey !important",
    },
    "&.MuiTableCell-root": {
      padding: "0 10px"
    },
    '&::-webkit-scrollbar': {
      height: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(75, 135, 197, 0.9)',
      borderRadius: 10,
      border: '2px solid transparent',
      backgroundClip: 'padding-box', // <== make the border work
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(75, 135, 197, 0.9)',
      // height: 9,
      // border: 0,
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
  },
  sticky: {
    "&.MuiTableCell-root": {
      padding: "2px"
    },
    position: window?.innerWidth > 768 ? "sticky" : "static",
    left: "200px",
    background: "white",
    //zIndex: 2,
    width: "100px",
    //boxShadow: "5px 2px 5px grey",
    //borderRight: "2px solid black"
  },
  fixed: {
    "&.MuiTableCell-root": {
      padding: "2px"
    },
    position: "sticky",
    background: "white",
    //zIndex: 3,
    width: window?.innerWidth < 768 ? "120px" : "200px",
    left: 0,
    //boxShadow: "5px 2px 5px grey",
    //borderRight: "2px solid black"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noCaret: {
    caretColor: 'transparent',
  },
  loaderStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: '100%',
  }
}));

const styles = {
  tablePaper: {
    // boxShadow:
    //   "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
  },
  firstTableHeader: (data) => ({
    padding: '10px 4px',
    whiteSpace: 'wrap',
    fontWeight: 600,
    minWidth: data?.minWidth
  }),
  firstTableBody: {
    padding: '10px 4px',
  },
  secondTableHeader: (data) => ({
    minWidth: data?.minWidth,
    fontWeight: 600,
    backgroundColor: '#FFF',
    padding: '10px 4px',
    whiteSpace: 'wrap',
  }),
  secondTableBody: {
    padding: '6px 4px',
  },
  thirdTableHeader: (index) => ({
    width: index === 0 ? 100 : 200,
    fontWeight: 600,
    backgroundColor: '#FFF',
    padding: '10px 4px',
    whiteSpace: 'wrap',
  }),
  thirdTableBody: {
    padding: '6px 4px',
  },
  fourthTableHeader: (index) => ({
    width: index === 0 ? 30 : 200,
    fontWeight: 600,
    backgroundColor: '#FFF',
    padding: '10px 4px',
    whiteSpace: 'wrap',
  }),
  fourthTableBody: {
    padding: '6px 4px',
  },
  tableHeader: (tableHeaderColumnWidth, position) => ({
    borderBottom: "none",
    color: "#293C79",
    whiteSpace: 'wrap',
    minWidth: tableHeaderColumnWidth[position],
    paddingTop: 10,
    paddingBottom: 10,
  }),
  tableBodyRow: (index) => ({
    backgroundColor: index % 2 === 0 && '#eeeeee',
  }),
  tableBodyRowCell: (contractBookingRows, i, padding,) => ({
    borderBottom: contractBookingRows?.length - 1 == i
      ? "2px solid #808080"
      : "none",
    paddingTop: 10,
    paddingBottom: 10,
    // paddingRight: padding && "5px"
  }),
  tableFooter: {
    paddingTop: 14,
    paddingBottom: 14,
    color: "#293C79",
    fontSize: pxToEm(15)
  },
  SelectContainer: (isMobile) => ({
    display: 'flex',
    width: isMobile ? '98%' : 324,
    margin: "0 10px",
    paddingTop: 8,
  }),
  dropdownText: {
    '& .MuiTypography-root': {
      padding: '0',
      fontFamily: 'Poppins',
      fontSize: pxToEm(12),
      lineHeight: 1
    },
  },
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const TableRowSkeleton = (props) => {
  const classes = useStyles();
  const { skeletonName, colSpan, noOfRows } = props
  return (
    [...Array(noOfRows || 2).keys()].map((components, index) => {
      return (
        <TableRow>
          <TableCell
            align="center"
            className={classes.root}
            style={{ padding: '6px 4px', }}
          >
            {['BottomTable'].includes(skeletonName)
              ? <Skeleton animation="wave" variant="text" width={50} height={25} />
              : <Skeleton variant="circular" width={25} height={25} style={{ marginLeft: '40%', display: skeletonName === 'fourthTable' && 'none' }} />
            }
          </TableCell>
          {[...Array((colSpan - 1)).keys()].map((components, index) => {
            return (
              <TableCell
                align="center"
                className={classes.root}
                style={{ padding: '6px 4px', }}
              >
                <Skeleton animation="wave" variant="text" width={50} height={25} />
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  )
}

const CollapseTableNoData = (props) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={8}>
        {props?.title === 'first_table'
          ? <div className={classes.loaderStyle}>
            <img src="/nodata-found.png" alt="No Chart" />
          </div>
          : <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: primaryColor
            }}>
            {`No ${props?.title || 'Data'}`}
          </Typography>
        }
      </TableCell>
    </TableRow>
  )
}

const FourthTable = (props) => {
  const [openFourthTable, setOpenFourthTable] = useState(false);
  const [fourthRowsData, setFourthRowsData] = useState([]);
  const [fourthRowsShowProgress, setFourthRowsShowProgress] = useState(false);


  const handleFourthDataFetch = (fromDate, toDate, itemCategoryID, customerType, customerID) => {
    setFourthRowsShowProgress(true)
    const url = props.tabValue === 1 && props.widget === 'la_booking_table'
      ? `${APIROUTES.LA_BOOKING_CONSIGNEEDATA_DATA}`
      : `${APIROUTES.ORDER_BOOKING_CONSIGNEEDATA_DATA}`
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      item_category: [itemCategoryID],
      customer_type: customerType,
      customer: customerID
    }
    axios.post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          let responseData = props.tabValue === 1 && props.widget === 'la_booking_table'
            ? response?.data['CustomerConsignee Wise LoadingAdvice']
            : response?.data['CustomerConsignee Wise Contracts']
          let sortedData = arraySortBykeyAllBrowser([...responseData], 'item_qty', 'decreasing')
          setFourthRowsData(sortedData)
          setFourthRowsShowProgress(false)
        }
      }).catch((error) => {
        setFourthRowsShowProgress(false)
        props?.handleCatchError(error)
      });
  }

  const {
    classes,
    styles,
    thirdTableRow,
    selectedID,
    selectedCustomerType,
    CollapseTableField,
    selectedDatePDFCSV
  } = props;
  return (
    <>
      <TableRow key={thirdTableRow}>
        {CollapseTableField.thirdTableField.map((thirdBody, i) => {
          return (
            i === 0
              ? <TableCell
                align="center"
                className={classes.root}
                style={styles.thirdTableBody}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpenFourthTable(!openFourthTable);
                    if (!openFourthTable === true) {
                      handleFourthDataFetch(
                        moment(selectedDatePDFCSV.start).format('YYYY-MM-DD'),
                        moment(selectedDatePDFCSV.end).format('YYYY-MM-DD'),
                        selectedID,
                        selectedCustomerType,
                        thirdTableRow[CollapseTableField.thirdTableField[0].id]
                      )
                    }
                  }}
                >
                  {openFourthTable ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                </IconButton>
              </TableCell>
              : <TableCell align="left" className={classes.root} style={styles.thirdTableBody}>
                {thirdTableRow[thirdBody.value] ?? ""}
              </TableCell>
          )
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={CollapseTableField.thirdTableField.length}>
          <Collapse in={openFourthTable} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '30px 0 20px 0', width: window.innerWidth * 0.78, }}>
              <Typography variant="subtitle1" gutterBottom component="div" style={{ fontWeight: 500 }}>
                {thirdTableRow[CollapseTableField.thirdTableField[0].value] ?? ""}
              </Typography>
              <Table className={classes.table} aria-label="simple table-Fourth" stickyHeader component={Paper}>
                <TableHead>
                  <TableRow style={{ verticalAlign: 'top' }} className={classes.root}>
                    {CollapseTableField.fourthTableField.map((fourthHeader, index) => {
                      return (
                        <TableCell align="left" style={styles.fourthTableHeader(index)} className={classes.root}>
                          {fourthHeader.key}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fourthRowsShowProgress
                    ? <TableRowSkeleton skeletonName='fourthTable' colSpan={8} />
                    : fourthRowsData.length > 0
                      ? fourthRowsData?.map((data, i) => {
                        return (
                          <TableRow key={thirdTableRow}>
                            {CollapseTableField.fourthTableField.map((fourthBody, index) => {
                              return (
                                index === 0
                                  ? <TableCell
                                    align="center"
                                    className={classes.root}
                                    style={styles.fourthTableBody}
                                  >
                                  </TableCell>
                                  : <TableCell align="left" className={classes.root} style={styles.fourthTableBody}>
                                    {data[fourthBody.value]}
                                  </TableCell>
                              )
                            })}
                          </TableRow>)
                      })
                      : <CollapseTableNoData title={CollapseTableField.fourthTableField[1].key} />
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const ThirdTable = (props) => {
  const [openThirdTable, setOpenThirdTable] = useState(false);
  const [thirdRowsData, setThirdRowsData] = useState([]);
  const [thirdRowsShowProgress, setThirdRowsShowProgress] = useState(false);


  const handleThirdDataFetch = (fromDate, toDate, itemCategoryID, customerType) => {
    setThirdRowsShowProgress(true)
    const url = props.tabValue === 1 && props.widget === 'la_booking_table'
      ? `${APIROUTES.LA_BOOKING_CUSTOMERGROUP_DATA}`
      : `${APIROUTES.ORDER_BOOKING_CUSTOMERGROUP_DATA}`
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      item_category: [itemCategoryID],
      customer_type: customerType
    }
    axios.post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          let responseData = props.tabValue === 1 && props.widget === 'la_booking_table'
            ? response?.data['CustomerGroup Wise LoadingAdvice']
            : response?.data['CustomerGroup Wise Contracts']
          let sortedData = arraySortBykeyAllBrowser([...responseData], 'total_booked_quantity', 'decreasing')
          setThirdRowsData(sortedData)
          setThirdRowsShowProgress(false)
        }
      }).catch((error) => {
        setThirdRowsShowProgress(false)
        props?.handleCatchError(error)
      });
  }

  const {
    classes,
    styles,
    secondTableRow,
    selectedID,
    CollapseTableField,
    handleCatchError,
    selectedDatePDFCSV,
  } = props;
  return (
    <>
      <TableRow key={secondTableRow}>
        {CollapseTableField.secondTableField.map((secondBody, i) => {
          return (
            i === 0
              ? <TableCell
                align="center"
                className={classes.root}
                style={styles.secondTableBody}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpenThirdTable(!openThirdTable);
                    if (!openThirdTable === true) {
                      handleThirdDataFetch(
                        moment(selectedDatePDFCSV.start).format('YYYY-MM-DD'),
                        moment(selectedDatePDFCSV.end).format('YYYY-MM-DD'),
                        props.selectedID,
                        secondTableRow[CollapseTableField.secondTableField[0].value])
                    }
                  }}
                >
                  {openThirdTable ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                </IconButton>
              </TableCell>
              : <TableCell align="left" className={classes.root} style={styles.secondTableBody}>
                {secondTableRow[secondBody.value] ?? ""}
              </TableCell>
          )
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={CollapseTableField.secondTableField.length}>
          <Collapse in={openThirdTable} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '30px 0 20px 0', width: window.innerWidth * 0.80, }}>
              <Typography variant="subtitle1" gutterBottom component="div" style={{ fontWeight: 500 }}>
                {secondTableRow[CollapseTableField.secondTableField[0].value] ?? ""}
              </Typography>
              <Table className={classes.table} aria-label="simple table-Third" stickyHeader component={Paper}>
                <TableHead>
                  <TableRow style={{ verticalAlign: 'top' }} className={classes.root}>
                    {CollapseTableField.thirdTableField.map((thirdHeader, index) => {
                      return (
                        <TableCell align="left" style={styles.thirdTableHeader(index)} className={classes.root}>
                          {thirdHeader.key}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                {thirdRowsShowProgress
                  ? <TableRowSkeleton skeletonName='thirdTable' colSpan={8} />
                  : thirdRowsData.length > 0
                    ? thirdRowsData.map((thirdTableRow, i) => (
                      thirdTableRow &&
                      <FourthTable
                        {...props}
                        classes={classes}
                        styles={styles}
                        thirdTableRow={thirdTableRow}
                        selectedID={selectedID}
                        selectedCustomerType={secondTableRow[CollapseTableField.secondTableField[0].id]}
                        CollapseTableField={CollapseTableField}
                        handleCatchError={handleCatchError}
                        selectedDatePDFCSV={selectedDatePDFCSV}
                      />
                    ))
                    : <CollapseTableNoData title={CollapseTableField.thirdTableField[1].key} />
                }
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const SecondTable = (props) => {
  const [openSecondTable, setOpenSecondTable] = useState(false);
  const [customerTypeRows, setCustomerTypeRows] = useState([]);
  const [customerTypeShowProgress, setCustomerTypeShowProgress] = useState(false);


  const handleSecondDataFetch = (fromDate, toDate, itemCategoryID) => {
    setCustomerTypeShowProgress(true)
    const url = props.tabValue === 1 && props.widget === 'la_booking_table'
      ? `${APIROUTES.LA_BOOKING_CUSTOMERTYPE_DATA}`
      : `${APIROUTES.ORDER_BOOKING_CUSTOMERTYPE_DATA}`
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      item_category: [itemCategoryID],
    }
    axios.post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          let responseData = props.tabValue === 1 && props.widget === 'la_booking_table'
            ? response?.data['CustomerType Wise LoadingAdvice']
            : response?.data['CustomerType Wise Contracts']
          let sortedData = arraySortBykeyAllBrowser([...responseData], 'total_booked_quantity', 'decreasing')
          setCustomerTypeRows(sortedData)
          setCustomerTypeShowProgress(false)
        }
      }).catch((error) => {
        setCustomerTypeShowProgress(false)
        props?.handleCatchError(error)
      });
  }

  const {
    classes,
    styles,
    firstTableRow,
    selectedDatePDFCSV,
    CollapseTableField,
    handleCatchError,
  } = props;
  return (
    <>
      <TableRow key={firstTableRow}>
        {CollapseTableField.firstTableField.map((firstBody, i) => {
          return (
            i === 0
              ? <TableCell
                align="center"
                className={classes.root}
                style={styles.firstTableBody}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpenSecondTable(!openSecondTable);
                    if (!openSecondTable === true) {
                      handleSecondDataFetch(
                        moment(selectedDatePDFCSV.start).format('YYYY-MM-DD'),
                        moment(selectedDatePDFCSV.end).format('YYYY-MM-DD'),
                        firstTableRow[firstBody.id])
                    }
                  }}
                >
                  {openSecondTable ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                </IconButton>
              </TableCell>
              : <TableCell align="left" className={classes.root} style={styles.firstTableBody}>
                {firstTableRow[firstBody.value] ?? ""}
              </TableCell>
          )
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={24}>
          <Collapse in={openSecondTable} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '30px 0 20px 0', width: window.innerWidth * 0.82, }}>
              <Typography variant="h6" gutterBottom component="div">
                {firstTableRow[CollapseTableField.firstTableField[0].value] ?? ""}
              </Typography>
              <Table className={classes.table} aria-label="simple table-second" stickyHeader component={Paper}>
                <TableHead>
                  <TableRow style={{ verticalAlign: 'top' }} className={classes.root}>
                    {CollapseTableField.secondTableField.map((secondHeader, index) => {
                      return (
                        <TableCell
                          align="left"
                          style={styles.secondTableHeader(secondHeader)}
                          className={classes.root}
                        >
                          {secondHeader.key}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerTypeShowProgress
                    ? <TableRowSkeleton skeletonName='secondTable' colSpan={8} />
                    : customerTypeRows.length > 0
                      ? customerTypeRows.map((secondTableRow, i) => (
                        secondTableRow &&
                        <ThirdTable
                          {...props}
                          classes={classes}
                          styles={styles}
                          secondTableRow={secondTableRow}
                          selectedID={firstTableRow[CollapseTableField.firstTableField[0].id]}
                          CollapseTableField={CollapseTableField}
                          handleCatchError={handleCatchError}
                          selectedDatePDFCSV={selectedDatePDFCSV}
                        />
                      ))
                      : <CollapseTableNoData title={CollapseTableField.secondTableField[1].key} />
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const FirstTable = (props) => {

  const {
    classes,
    styles,
    CollapseTableField,
    showProgress,
    itemWiseRows,
    handleCatchError,
  } = props;

  return (
    <div style={styles.tablePaper}>
      <TableContainer style={{ maxHeight: "45rem", }} component={Paper}>
        <Table className={classes.table} aria-label="simple table-top" stickyHeader>
          <TableHead>
            <TableRow style={{ verticalAlign: 'top' }} className={classes.root}>
              {CollapseTableField.firstTableField.map(firstHeader => {
                return (
                  <TableCell
                    align="left"
                    style={styles.firstTableHeader(firstHeader)}
                    className={classes.root}
                  >
                    {firstHeader.key}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {showProgress
              ? <TableRowSkeleton skeletonName='firstTable' colSpan={8} />
              : itemWiseRows.length > 0
                ? itemWiseRows.map((firstTableRow, i) => (
                  <SecondTable
                    {...props}
                    classes={classes}
                    styles={styles}
                    firstTableRow={firstTableRow}
                    CollapseTableField={CollapseTableField}
                    handleCatchError={handleCatchError}
                  />
                ))
                : <CollapseTableNoData title={'first_table'} />
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const BottomTable = (props) => {
  const TableKeyValue = [
    { key: 'CUSTOMER TYPE', value: 'customer_type', calculation: "" },
    { key: props?.toggleIndex === 0 ? `CUSTOMER L1` : `STATE`, value: props?.toggleIndex === 0 ? `customer_l1` : `customer_state_code`, calculation: "" },
    { key: 'CUSTOMER NAME', value: 'customer_name', calculation: "Total" },
    { key: 'CUSTOMER TAG', value: 'customer_tag', calculation: "" },
    { key: 'QUANTITY', value: 'quantity', calculation: "", calculation_type: 'sum' },
    { key: 'RATE', value: 'rate', calculation: "", calculation_type: 'weighted_avg' },
    { key: 'DISCOUNTS', value: 'discount', calculation: "", calculation_type: 'weighted_avg' },
    { key: 'PREMIUM RATE', value: 'premium_charge', calculation: "", calculation_type: 'weighted_avg' },
    { key: 'PAYMENT PREMIUM', value: 'payment_premium', calculation: "", },
    { key: 'PAYMENT TERMS', value: 'payment_method', calculation: "", },
    { key: 'LOADING AND INSURANCE PREMIUM', value: 'loading_insurance_premium', calculation: "", },
    { key: 'EX RATE', value: 'Ex_rate', calculation: "", calculation_type: 'weighted_avg' },
    { key: 'FREIGHT BASIS', value: 'freight_basis', calculation: "", },
    { key: 'FREIGHT RATE', value: 'freight_charge_percent', calculation: "", },
    { key: 'BROKERAGE RATE', value: 'new_brokerage', calculation: "", calculation_type: 'weighted_avg' },
    { key: 'INTERNAL REMARK', value: 'internal_remark', calculation: "", },
  ]

  const {
    classes,
    styles,
    contractBookingRows,
    BottomCalculation,
    tableHeaderColumnWidth,
    availablePlants,
    showBottomTableProgress,
  } = props;

  return (
    <TableContainer className={classes.root} style={{ margin: '20px', width: '96%', maxHeight: "30rem", overflowX: 'auto' }} component={Paper}>
      <Table className={classes.table} aria-label="simple table2" stickyHeader component={Paper}>
        <TableHead>
          <TableRow style={{ verticalAlign: 'top' }} className={classes.root}>
            {TableKeyValue.map((d, tableIndex) => {
              return (
                <>
                  <TableCell
                    align="left"
                    style={styles.tableHeader(tableHeaderColumnWidth, tableIndex > 11 ? tableIndex - TableKeyValue?.length : tableIndex)}
                    className={classes.root}
                  >
                    {d.key}
                  </TableCell>
                  {tableIndex === 11 && availablePlants.map((a, i) => (
                    <>
                      <TableCell
                        align="left"
                        style={styles.tableHeader(tableHeaderColumnWidth, 11 + i + 1)}
                        className={classes.root}
                      >
                        {a}
                      </TableCell>
                    </>
                  ))
                  }
                </>)
            })
            }
          </TableRow>
        </TableHead>
        {showBottomTableProgress
          ? <TableRowSkeleton
            skeletonName='BottomTable'
            colSpan={TableKeyValue.length + (availablePlants?.length || 0)}
            noOfRows={10}
          />
          : contractBookingRows?.length > 0 ?
            <TableBody>
              {contractBookingRows.map((row, i) => (
                <TableRow key={row} style={styles.tableBodyRow(i)}>
                  {TableKeyValue.map((d, tableIndex) => {
                    return (
                      <>
                        <TableCell
                          align="left"
                          style={styles.tableBodyRowCell(contractBookingRows, i, true)}
                          className={classes.root}
                        >
                          {row[d.value] ?? ""}
                        </TableCell>
                        {tableIndex === 11 &&
                          availablePlants.map(a => (
                            <>
                              <TableCell
                                align="right"
                                style={styles.tableBodyRowCell(contractBookingRows, i, true)}
                                className={classes.root}
                              >
                                {row?.plant_planning?.find(p => p.plant?.toUpperCase() == a)?.allotted_qty ?? ""}
                              </TableCell>
                            </>
                          ))
                        }
                      </>
                    )
                  })}
                </TableRow>
              ))}
              <TableRow style={{
                bottom: 0,
                zIndex: 2,
                position: 'sticky',
                backgroundColor: '#fafafa'
              }}>
                {TableKeyValue.map((d, tableIndex) => {
                  return (
                    <>
                      <TableCell
                        align="left"
                        className={classes.root}
                        style={styles.tableFooter}
                      >
                        {d?.calculation_type
                          ? d?.calculation_type === 'sum'
                            ? (BottomCalculation(contractBookingRows, d.value, '', 'sum')).toFixed(3)
                            : Math.round(BottomCalculation(contractBookingRows, 'quantity', d.value, 'weighted_avg'))
                          : d?.calculation
                        }
                      </TableCell>
                      {tableIndex === 11 &&
                        availablePlants.map(a => (
                          <TableCell
                            align="right"
                            className={classes.root}
                            style={styles.tableFooter}
                          >
                            {(BottomCalculation(contractBookingRows, a, 'plant_planning', 'sum')).toFixed(3)}
                          </TableCell>
                        ))
                      }
                    </>
                  )
                })}
              </TableRow>
            </TableBody>
            : <TableRow>
              <TableCell colSpan={13} className={classes.root}>
                <div className={classes.loaderStyle}>
                  <img src="/nodata-found.png" alt="No Chart" />

                </div>
              </TableCell>
            </TableRow>}
      </Table>
    </TableContainer>
  )
}

export default function ContractBookingTable(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  const currentMonth = moment().month()
  const currentYear = currentMonth > 2 ? moment().year() : moment().year() - 1
  const nextYear = currentYear + 1;
  const startDateOfYear = `${currentYear}-04-01`
  const endDateOfyear = `${nextYear}-03-31`
  const monthKeys = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"]
  const weekKeys = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
  const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
  const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
  const startDateOfToday = moment().format('YYYY-MM-DD');
  const endDateOfToday = moment().format('YYYY-MM-DD');
  const startDateOfYesterday = moment((new Date()).setDate((new Date()).getDate() - 1)).format('YYYY-MM-DD');
  const endDateOfYesterday = moment((new Date()).setDate((new Date()).getDate() - 1)).format('YYYY-MM-DD');
  const [title, setTitle] = useState('')
  const [color, setColor] = useState([])
  const [itemWiseRows, setItemWiseRows] = useState([]);
  const [contractBookingRows, setContractBookingRows] = useState([]);
  const [financialData, setFinancialData] = useState([]);
  const [toggleIndex, setToggleIndex] = useState(0);
  const [yearSelected, setYearSelected] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [weekSelected, setWeekSelected] = useState(false);
  const [customSelected, setCustomSelected] = useState(false);
  const [yesterdaySelected, setYesterdaySelected] = useState(true);
  const [lineChartData, setLineChartData] = useState([]);
  const [masterLineChartData, setMasterLineChartData] = useState([]);
  const [lineColor, setLineColor] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [showBottomTableProgress, setShowBottomTableProgress] = useState(false);
  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: "",
    snackbarType: ""
  })
  const [hasLineChartData, setHasLineChartData] = useState(false)
  const [graphBottomCalculation, setGraphBottomCalculation] = useState({
    totalQuantity: 0,
    discount: 0,
    Ex_rate: 0
  })
  const [cancelTokenSource, setCancelTokenSource] = useState({
    collapseFirstTableApi: null,
    graphDataApi: null,
    bottomTableApi: null,
  });
  const [itemcategoryIDSelected, setItemcategoryIDSelected] = useState([])
  const [itemCategorySelected, setItemcategorySelected] = useState([])
  const [itemCategoryDropdownList, setItemCategoryDropdownList] = useState([]);
  const [allAccessesItemcategory, setAllAccessesItemcategory] = useState([]);
  const [pdfCsvData, setPdfCsvData] = useState([]);
  const [pdfCsvHeader, setPdfCsvHeader] = useState([]);
  const [collapseTablePdfCsv, setCollapseTablePdfCsv] = useState({
    pdfCsvHeader: [],
    pdfCsvData: [],
  });
  const [laCollapseTablePdf, setLACollapseTablePdf] = useState({
    pdfHeader: [],
    pdfData: [],
  });
  const [laCollapseTableCsv, setLACollapseTableCsv] = useState({
    csvHeader: [],
    csvData: [],
  });
  const [laCollapseTablePdfCsvLoader, setLaCollapseTablePdfCsvLoader] = useState(false)
  const [tableHeaderColumnWidth, setTableHeaderColumnWidth] = useState([])
  const [availablePlants, setAvailablePlants] = useState([])
  const [selectedContractBookingGraph, setSelectedContractBookingGraph] = useState('customer_l1');
  const [CollapseTableField, setCollapseTableField] = useState({
    firstTableField: [],
    secondTableField: [],
    thirdTableField: [],
    fourthTableField: [],
  });
  const [selectedDatePDFCSV, setSelectedDatePDFCSV] = useState({ start: '', end: '' });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mounted, setMounted] = useState(false);
  const [snackbarPDFOpen, setSnackbarPDFOpen] = useState(false);
  const refCSVLink = createRef();

  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: ""
    })
  };

  const handleCatchError = (error) => {
    handleOpenSnackbar(
      handleSnackbarMessageBox(checkApiError(error?.response)),
      "error"
    );
    setTimeout(() => {
      handleCloseSnackbar();
    }, 4000);
  }

  const handleGraphWiseData = (fromDate, toDate, filterName, graphType) => {
    if (['contract_booking_table', 'item_tags'].includes(props?.widget)) {
      const newCancelTokenSource = handleCancelToken('graphDataApi')
      const payload = itemcategoryIDSelected.length > 0
        ? {
          from_date: fromDate,
          to_date: toDate,
          filter_name: filterName,
          item_category: itemcategoryIDSelected,
        }
        : {
          from_date: fromDate,
          to_date: toDate,
          filter_name: filterName,
        }
      axios.post(
        `${APIROUTES.ORDER_BOOKING_GRAPH_DATA}`,
        payload,
        { cancelToken: newCancelTokenSource.token, }
      )
        .then((response) => {
          if (response.status === 200) {
            let data = response.data[filterName];
            handleGraphTableData(data, graphType)
            if (props.tabValue === 0) {
              axios
                .post(`${APIROUTES.ORDER_BOOKING_WEIGHTED_DATA}`, payload)
                .then((response) => {
                  handleGraphBottomCalc(response.data['Weighted Average Rate'], 'contract')
                })
                .catch((error) => {
                  console.log(error)
                })
            }
            else if (props.tabValue === 1) {
              axios
                .post(`${APIROUTES.LA_BOOKING_WEIGHTED_DATA}`, payload)
                .then((response) => {
                  handleGraphBottomCalc(response.data['Weighted Average Rate'], 'tags')
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
          } else {
            handleCatchError(error)
          }
        });
    }
  };

  const handleDateButtonChange = (fromDate, toDate,) => {
    if (['contract_booking_table', 'la_booking_table'].includes(props?.widget)) {
      setContractBookingRows([])
      setCollapseTablePdfCsv({ pdfCsvHeader: [], pdfCsvData: [], })
      const newCancelTokenSource = handleCancelToken('collapseFirstTableApi')
      setShowProgress(true)
      handleBottomDataFetch(fromDate, toDate)
      const url = props.tabValue === 1 && props.widget === 'la_booking_table'
        ? `${APIROUTES.LA_BOOKING_ITEMWISE_DATA}`
        : `${APIROUTES.ORDER_BOOKING_ITEMWISE_DATA}`
      const payload = itemcategoryIDSelected.length > 0
        ? {
          from_date: fromDate,
          to_date: toDate,
          item_category: itemcategoryIDSelected,
        }
        : {
          from_date: fromDate,
          to_date: toDate,
        }
      axios.post(url, payload, { cancelToken: newCancelTokenSource.token, })
        .then((response) => {
          if (response.status === 200) {
            setShowProgress(false);
            let data = props.tabValue === 1 && props.widget === 'la_booking_table'
              ? response?.data['Item Category Wise LoadingAdvice']
              : response?.data['Item Category Wise Contracts']
            let sortedData = arraySortBykeyAllBrowser([...data], 'total_booked_quantity', 'decreasing')
            setItemWiseRows(sortedData)
          }
        }).catch((error) => {
          if (axios.isCancel(error)) {
            setShowProgress(false);
          } else {
            handleCatchError(error)
          }
        });
    }
  }

  const handleBottomDataFetch = (fromDate, toDate) => {
    if (['contract_booking_table',].includes(props?.widget)) {
      const newCancelTokenSource = handleCancelToken('bottomTableApi')
      setPdfCsvData([])
      setShowBottomTableProgress(true)
      const url = `${APIROUTES.ORDER_BOOKING_DATA}`
      const payload = itemcategoryIDSelected.length > 0
        ? {
          from_date: fromDate,
          to_date: toDate,
          item_category: itemcategoryIDSelected,
        }
        : {
          from_date: fromDate,
          to_date: toDate,
        }
      axios.post(url, payload, { cancelToken: newCancelTokenSource.token, })
        .then((response) => {
          if (response.status === 200) {
            setShowBottomTableProgress(false)
            handleAvailablePlant(response?.data)
            setContractBookingRows(response?.data)
          }
        }).catch((error) => {
          if (axios.isCancel(error)) {
            setShowBottomTableProgress(false)
          } else {
            handleCatchError(error)
          }
        });
    }
  }

  const BottomCalculation = (allRows, field1, field2 = '', method) => {
    if (allRows) {
      if (method === 'sum') {
        if (field2 === "plant_planning") {
          let sum = 0;
          allRows.map(d => {
            sum = Number(Number(sum) + Number(d?.plant_planning?.length > 0
              ? Number((d?.plant_planning?.find(p => p?.plant?.toUpperCase() === field1))?.allotted_qty || 0)
              : 0))
          })
          return Number(sum);
        }
        else {
          let sum = allRows?.reduce((sumTotal, item) => Number(Number(sumTotal) + Number(item[field1] || 0)), 0)
          return Number(sum);
        }
      }
      else if (method === 'weighted_avg') {
        const totalWeightedRate = allRows?.reduce((sumTotal, item) => Number(Number(sumTotal) + Number(Number(item[field1] || 0) * Number(item[field2] || 0))), 0);
        const totalWeight = allRows?.reduce((sumTotal, item) => Number(Number(sumTotal) + Number(item[field1] || 0)), 0);
        const weightedAverageRate = Number(Number(totalWeightedRate) / Number(totalWeight));
        // console.log(weightedAverageRate === NaN, isNaN(weightedAverageRate) ? 'Yes is NaN' : weightedAverageRate)
        return isNaN(weightedAverageRate) ? 0 : weightedAverageRate;
      }
    }
    else {
      return ''
    }
  }

  const handlePDFCSVData = (data, action = '') => {
    // console.log(data)
    if (props?.widget === 'contract_booking_table') {
      if (data && data.length > 0) {
        const collapseHeader = tableHeaders('collapseTableTab0')
        let finalCollapsePDFCSVArray = []
        data.map((d, index) => {
          let obj0 = {};
          collapseHeader.map(h => {
            Object.assign(obj0, { [h.key]: h.key === 'net_amount' ? Math.round(Number(d['net_rate'] * d['quantity']) || 0) : d[h.key] })
          })
          finalCollapsePDFCSVArray.push(obj0)
        })
        setCollapseTablePdfCsv(prevValue => {
          return {
            ...prevValue,
            pdfCsvData: finalCollapsePDFCSVArray
          }
        });
        let finalPDFCSVArray = []
        data.map((d, index) => {
          let obj = {};
          Object.assign(obj, d)
          delete obj.plant_planning;
          availablePlants.length > 0 && availablePlants.map((a, i) => {
            Object.assign(obj, {
              [a]: d?.plant_planning !== undefined && d?.plant_planning.length > 0
                ? (d?.plant_planning?.find(p => p.plant === a))?.allotted_qty
                : 0
            })
          })
          finalPDFCSVArray.push(obj)
        })
        let noCalcField = ['customer_type', 'customer_l1', 'customer_state_code', 'customer_tag', 'payment_premium', 'payment_method', 'loading_insurance_premium', 'freight_basis', 'freight_charge_percent', 'internal_remark']
        let weightedAvgField = ["rate", "discount", "premium_charge", "Ex_rate", "new_brokerage"]
        let bottomCalculation = {};
        let keys = Object.keys(finalPDFCSVArray[0])
        keys.map(k => k === 'customer_name'
          ? Object.assign(bottomCalculation, { [k]: 'Total Calculation' })
          : weightedAvgField.includes(k)
            ? Object.assign(bottomCalculation, { [k]: Math.round(BottomCalculation(finalPDFCSVArray, 'quantity', k, 'weighted_avg')) })
            : noCalcField.includes(k)
              ? Object.assign(bottomCalculation, { [k]: '' })
              : Object.assign(bottomCalculation, { [k]: (BottomCalculation(finalPDFCSVArray, k, '', 'sum')).toFixed(3) })
        )
        finalPDFCSVArray.push(bottomCalculation)
        // console.log(finalPDFCSVArray)
        setPdfCsvData(finalPDFCSVArray)
      }
    }
    else if (props?.widget === 'la_booking_table') {
      if (data && data.length > 0) {
        const collapseHeader = tableHeaders('collapseTableTab1')
        let finalCollapsePDFCSVArray = []
        data.map((d, index) => {
          let obj0 = {};
          collapseHeader.map(h => {
            Object.assign(obj0, { [h.key]: d[h.key] })
          })
          finalCollapsePDFCSVArray.push(obj0)
        })
        action === 'print'
          ? setLACollapseTablePdf(prevValue => { return { ...prevValue, pdfData: finalCollapsePDFCSVArray } })
          : setLACollapseTableCsv(prevValue => { return { ...prevValue, csvData: finalCollapsePDFCSVArray } })
      }
    }
  }

  const handleHeader = (availablePlants = '', action = '') => {
    if (props?.widget === 'contract_booking_table') {
      const collapseHeader = tableHeaders('collapseTableTab0')
      setCollapseTablePdfCsv(prevValue => {
        return {
          ...prevValue,
          pdfCsvHeader: collapseHeader
        }
      });
      const header = tableHeaders('contract_booking')
      let startheader = header.slice(0, 12)
      startheader[1].label = CaptilizeText(selectedContractBookingGraph === 'customer_l1' ? 'customer L1' : selectedContractBookingGraph)
      startheader[1].key = selectedContractBookingGraph === 'state' ? 'customer_state_code' : selectedContractBookingGraph
      let plantHeader = [];
      let lastHeader = header.slice(12)
      availablePlants?.forEach((a) => {
        plantHeader.push({ label: a, key: a })
        // console.log(plantHeader)
      })
      let newHeader = startheader.concat(plantHeader, lastHeader);
      setPdfCsvHeader(newHeader);
    }
    else if (props?.widget === 'la_booking_table') {
      const collapseHeader = tableHeaders('collapseTableTab1')
      console.log(collapseHeader)
      action === 'print'
        ? setLACollapseTablePdf(prevValue => { return { ...prevValue, pdfHeader: collapseHeader } })
        : setLACollapseTableCsv(prevValue => { return { ...prevValue, csvHeader: collapseHeader } })
    }
  }

  const handleAvailablePlant = (data) => {
    const plantsMaster = data.flatMap(d => d?.plant_planning)
    const plants = new Set()
    plantsMaster?.forEach(p => {
      if (p?.plant) {
        plants.add(p?.plant?.toUpperCase())
      }
    })
    const plantArray = Array.from(plants)
    setAvailablePlants(plantArray)
    setPdfCsvHeader([])
    let startingStaticColumns = [120, 100, 128, 100, 100, 100, 100, 100, 100, 120, 170, 100,]
    let plantColumns = Array(plantArray.length).fill(100)
    let lastStaticColumns = [100, 100, 100, 100]
    let finalArray = startingStaticColumns.concat(plantColumns, lastStaticColumns)
    setTableHeaderColumnWidth(finalArray)
    handleHeader(plantArray)
  }

  const handleSnackbarPDFOpen = () => {
    setSnackbarPDFOpen(true)
  }

  const handleSnackbarPDFClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarPDFOpen(false)
  }

  const handlePDFCSVButtonClick = (buttonClicked) => {
    let fromDate = moment(selectedDatePDFCSV.start, ['DD-MMM-YY']).format('YYYY-MM-DD');
    let toDate = moment(selectedDatePDFCSV.end, ['DD-MMM-YY']).format('YYYY-MM-DD');
    if (buttonClicked === 'print') {
      handleHeader('la_booking_table', 'print')
      setLaCollapseTablePdfCsvLoader('print')
      const url = (props.tabValue === 1 && props.widget === 'la_booking_table') && `${APIROUTES.LA_BOOKING_CONSIGNEEDATA_DATA}`
      const payload = {
        from_date: fromDate,
        to_date: toDate,
        item_category: itemcategoryIDSelected,
      }
      axios.post(url, payload)
        .then((response) => {
          if (response.status === 200) {
            let responseData = (props.tabValue === 1 && props.widget === 'la_booking_table') && response?.data['CustomerConsignee Wise LoadingAdvice']
            handlePDFCSVData(responseData, buttonClicked)
            setLaCollapseTablePdfCsvLoader(false)
            if (responseData.length === 0) {
              handleOpenSnackbar("No Data Found", "error");
              setTimeout(() => {
                handleCloseSnackbar();
              }, 3000);
            }
          }
        }).catch((error) => {
          setLaCollapseTablePdfCsvLoader(false)
          handleCatchError(error)
        });
    }
    else if (buttonClicked === 'csv') {
      handleHeader('la_booking_table', 'csv')
      setLaCollapseTablePdfCsvLoader('csv')
      const url = (props.tabValue === 1 && props.widget === 'la_booking_table') && `${APIROUTES.LA_BOOKING_CONSIGNEEDATA_DATA}`
      const payload = {
        from_date: fromDate,
        to_date: toDate,
        item_category: itemcategoryIDSelected,
      }
      axios.post(url, payload)
        .then((response) => {
          if (response.status === 200) {
            let responseData = (props.tabValue === 1 && props.widget === 'la_booking_table') && response?.data['CustomerConsignee Wise LoadingAdvice']
            handlePDFCSVData(responseData, buttonClicked)
            setLaCollapseTablePdfCsvLoader(false)
          }
        }).catch((error) => {
          setLaCollapseTablePdfCsvLoader(false)
          handleCatchError(error)
        });
    }
  }

  const toggle = () => setOpen(!open);

  const handleDataRangeChange = (range) => {
    setDateRange(range)
  }

  const handleCallbackDataFunction = (receivedData, place = '', callBackLogicFunction = '') => {
    if (place === 'error') {
      callBackLogicFunction(receivedData, place)
      handleOpenSnackbar(
        handleSnackbarMessageBox(checkApiError(receivedData?.response)),
        "error"
      );
      setTimeout(() => {
        handleCloseSnackbar();
      }, 4000);
    }
    else {
      callBackLogicFunction(receivedData, place)
    }
  }

  const handleCancelToken = (key) => {
    if (cancelTokenSource[key]) {
      // console.log(key)
      cancelTokenSource[key].cancel('Operation canceled');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(prevValue => { return { ...prevValue, [key]: newCancelTokenSource } });
    return newCancelTokenSource;
  }

  const handleItemCategorySelection = (e) => {
    const { value } = e.target;
    const filterValue = value.filter(d => d !== 'ALL')
    // console.log(value, filterValue)
    if (filterValue.includes("")) {
      setItemcategorySelected([])
    }
    else {
      setItemcategorySelected(
        typeof filterValue === "string" ? filterValue.split(",") : filterValue
      )
    }
  };

  const handleGraphTableData = (data, graphType) => {
    setFinancialData([])
    const _financialData = [];
    setHasLineChartData(Object.keys(data)?.length > 0)
    let LinesKey = Object.keys(data)
    let _colors = []
    for (let i = 0; i < LinesKey.length; i++) {
      _colors.push({ name: LinesKey[i], color: generateRandomColor(), isSelected: false })
    }
    setColor(_colors)
    if (graphType === 'today') {
      let _datakeys = [];
      const numberOfDays = 1
      const ky = moment(new Date()).format("DD/MM");
      _datakeys.push(ky)
      for (const k in data) {
        let plant = { name: k };
        let plantTotal = 0;

        for (let i = 1; i <= numberOfDays; i++) {
          const dt = moment(new Date()).format("YYYY-MM-DD");
          const ky = moment(new Date()).format("DD/MM");
          const dayData = Number(Number(data[k][dt] ?? 0).toFixed(3));
          plantTotal += Number(Number(data[k][dt] ?? 0).toFixed(3));
          plant = { ...plant, [ky]: dayData }
        }
        plant = { ...plant, total: Number((Number(plantTotal)).toFixed(3)) };
        _financialData.push(plant);
        console.log(_financialData)
      }
      setDataKeys(_datakeys);
    } else if (graphType === 'yesterday') {
      let _datakeys = [];
      const numberOfDays = 1
      const ky = moment(startDateOfYesterday).format("DD/MM");
      _datakeys.push(ky)
      for (const k in data) {
        let plant = { name: k };
        let plantTotal = 0;

        for (let i = 1; i <= numberOfDays; i++) {
          const dt = moment(startDateOfYesterday).format("YYYY-MM-DD");
          const ky = moment(startDateOfYesterday).format("DD/MM");
          const dayData = Number(Number(data[k][dt] ?? 0).toFixed(3));
          plantTotal += Number(Number(data[k][dt] ?? 0).toFixed(3));
          plant = { ...plant, [ky]: dayData }
        }
        plant = { ...plant, total: Number((Number(plantTotal)).toFixed(3)) };
        _financialData.push(plant);
        console.log(_financialData)
      }
      setDataKeys(_datakeys);
    } else if (graphType === 'week') {
      setDataKeys(weekKeys)
      for (const k in data) {
        let plant = { name: k };
        let plantTotal = 0;
        for (var d = new Date(startOfWeek); d <= new Date(endOfWeek); d.setDate(d.getDate() + 1)) {
          const dayOfWeek = moment(d).format('dddd');
          const dow = moment(d).format('YYYY-MM-DD');
          const val = data[k][dow] ?? 0
          plantTotal += val;
          plant = { ...plant, [dayOfWeek]: val }
        }
        plant = { ...plant, total: Number((Number(plantTotal)).toFixed(3)) };
        _financialData.push(plant);
      }

    } else if (graphType === 'month') {
      let _datakeys = [];
      const numberOfDays = moment(endOfMonth).daysInMonth();
      const yr = moment(endOfMonth).year();
      const mnth = moment(endOfMonth).month();
      for (let i = 1; i <= numberOfDays; i++) {
        const ky = moment(new Date(yr, mnth, i)).format("DD/MM");
        _datakeys.push(ky);
      }
      for (const k in data) {
        let plant = { name: k };
        let plantTotal = 0;

        for (let i = 1; i <= numberOfDays; i++) {
          const dt = moment(new Date(yr, mnth, i)).format("YYYY-MM-DD");
          const ky = moment(new Date(yr, mnth, i)).format("DD/MM");
          const dayData = Number(Number(data[k][dt] ?? 0).toFixed(3));
          plantTotal += Number(Number(data[k][dt] ?? 0).toFixed(3));
          plant = { ...plant, [ky]: dayData }
        }
        plant = { ...plant, total: Number((Number(plantTotal)).toFixed(3)) };
        _financialData.push(plant);
      }
      setDataKeys(_datakeys);
    } else if (graphType === 'year') {
      setDataKeys(monthKeys)
      for (const k in data) {
        let plant = { name: k };
        let plantTotal = 0;
        for (let i = 1; i <= 12; i++) {
          const monthName = moment(`${i}`, 'M').format('MMMM');
          let total = 0;
          for (const d in data[k]) {
            if (moment(d).month() == i - 1) {
              total += data[k][d]
            }
          }
          const monthData = Number(Number(total).toFixed(3));
          plantTotal += monthData;
          plant = { ...plant, [monthName]: monthData }
        }
        plant = { ...plant, total: Number((Number(plantTotal)).toFixed(3)) };
        _financialData.push(plant);
      }
    }
    // console.log(graphType, _financialData)
    setFinancialData(_financialData)
  }

  const getYearlyData = () => {
    let chartData = [];
    let jan = { chartKey: 'Jan' };
    let feb = { chartKey: 'Feb' };
    let mar = { chartKey: 'Mar' };
    let apr = { chartKey: 'Apr' };
    let may = { chartKey: 'May' };
    let jun = { chartKey: 'Jun' };
    let jul = { chartKey: 'Jul' };
    let aug = { chartKey: 'Aug' };
    let sep = { chartKey: 'Sep' };
    let oct = { chartKey: 'Oct' };
    let nov = { chartKey: 'Nov' };
    let dec = { chartKey: 'Dec' };
    let plantInfo = [];
    financialData.forEach((f, i) => {
      const selectedColor = color.find(c => c.name == f.name)?.color
      const _plant = { id: i, color: selectedColor }
      plantInfo.push(_plant)
      jan = { ...jan, [i]: f.name, [f.name]: f.January }
      feb = { ...feb, [i]: f.name, [f.name]: f.February }
      mar = { ...mar, [i]: f.name, [f.name]: f.March }
      apr = { ...apr, [i]: f.name, [f.name]: f.April }
      may = { ...may, [i]: f.name, [f.name]: f.May }
      jun = { ...jun, [i]: f.name, [f.name]: f.June }
      jul = { ...jul, [i]: f.name, [f.name]: f.July }
      aug = { ...aug, [i]: f.name, [f.name]: f.August }
      sep = { ...sep, [i]: f.name, [f.name]: f.September }
      oct = { ...oct, [i]: f.name, [f.name]: f.October }
      nov = { ...nov, [i]: f.name, [f.name]: f.November }
      dec = { ...dec, [i]: f.name, [f.name]: f.December }
    })
    chartData.push(apr)
    chartData.push(may)
    chartData.push(jun)
    chartData.push(jul)
    chartData.push(aug)
    chartData.push(sep)
    chartData.push(oct)
    chartData.push(nov)
    chartData.push(dec)
    chartData.push(jan)
    chartData.push(feb)
    chartData.push(mar)
    setMasterLineChartData(chartData)
    setLineChartData(chartData)
    setLineColor(plantInfo);
  }

  const getMonthlyData = () => {
    let chartDataKeys = dataKeys.map(d => ({ chartKey: d }))
    let chartData = []
    let plantInfo = [];
    for (const c of chartDataKeys) {
      let _chartData = { ...c };
      financialData.forEach((f, i) => {
        _chartData = { ..._chartData, [i]: f.name, [f.name]: f[c["chartKey"]] }
      })
      chartData.push(_chartData)
    }
    financialData.forEach((f, i) => {
      const selectedColor = color.find(c => c.name == f.name)?.color
      const _plant = { id: i, color: selectedColor }
      plantInfo.push(_plant)
    })
    setMasterLineChartData(chartData)
    setLineChartData(chartData)
    setLineColor(plantInfo);
  }

  const getWeeklyData = () => {
    let chartDataKeys = dataKeys.map(d => ({ chartKey: d }))
    let chartData = []
    let plantInfo = [];

    for (const c of chartDataKeys) {
      let _chartData = { ...c };
      financialData.forEach((f, i) => {
        _chartData = { ..._chartData, [i]: f.name, [f.name]: f[c["chartKey"]] }
      })
      chartData.push(_chartData)
    }

    financialData.forEach((f, i) => {
      const selectedColor = color.find(c => c.name == f.name)?.color
      const _plant = { id: i, color: selectedColor }
      plantInfo.push(_plant)
    })
    setMasterLineChartData(chartData)
    setLineChartData(chartData)
    setLineColor(plantInfo);
  }

  const generateRandomColor = () => {
    // Generate a random color code
    const colorCode = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    return colorCode;
  }

  const checkIsItemSelected = (name) => {
    return color.find(c => c.name == name)?.isSelected
  }

  const handleItemSelection = (name) => {
    const _color = [...color]
    const existingItem = _color.find(c => c.name == name);
    const isSelected = !existingItem.isSelected;
    existingItem.isSelected = isSelected
    let plants = [...selectedPlants]
    if (isSelected) {
      plants.push(name)
    } else {
      plants = plants.filter(p => p != name)
    }
    setSelectedPlants(plants);
    setColor(_color);

  }

  const showRoundIcon = (name) => {
    const existingColor = color.find(c => c.name == name)?.color;
    const isItemSelected = checkIsItemSelected(name);
    if (isItemSelected) {
      return <svg viewBox="0 0 20 20" width="20" height="20">
        <circle style={{ fill: "#FFFFFF", stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
      </svg>
    }
    if (existingColor) {
      return <svg viewBox="0 0 20 20" width="20" height="20">
        <circle style={{ fill: existingColor, stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
      </svg>
    } else {
      return <svg viewBox="0 0 20 20" width="20" height="20">
        <circle style={{ fill: "#FFFFFF", stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
      </svg>
    }
  }

  const handleResetPlantSelection = () => {
    setSelectedPlants([]);
    const _color = color.map(c => ({ ...c, isSelected: false }))
    setColor(_color)
  }

  const handleClickToggle = (index) => {
    handleResetPlantSelection()
    setToggleIndex(index);
    setPdfCsvData([])
    setSelectedContractBookingGraph(index === 0 ? 'customer_l1' : 'state')
  }

  const handleGraphBottomCalc = (contractBookingRows, graphType = '') => {
    if (contractBookingRows) {
      if (graphType === 'contract') {
        setGraphBottomCalculation(prevValue => {
          return {
            ...prevValue,
            discount: contractBookingRows?.discount,
            Ex_rate: contractBookingRows?.ex_rate
          }
        })
        setGraphBottomCalculation({
          totalQuantity: (BottomCalculation(contractBookingRows, 'quantity', '', 'sum')).toFixed(3),
          discount: Math.round(BottomCalculation(contractBookingRows, 'quantity', 'discount', 'weighted_avg')) || 0,
          Ex_rate: Math.round(BottomCalculation(contractBookingRows, 'quantity', 'Ex_rate', 'weighted_avg')) || 0
        })
      }
      else if (graphType === 'tags') {
        setGraphBottomCalculation(prevValue => {
          return {
            totalQuantity: contractBookingRows?.total_weight,
            discount: contractBookingRows?.discount,
            Ex_rate: contractBookingRows?.ex_rate
          }
        })
      }
    }
  }

  const classes = useStyles();

  const handleYearSelection = () => {
    handleGraphWiseData(startDateOfYear, endDateOfyear, selectedContractBookingGraph, "year");
    handleDateButtonChange(startDateOfYear, endDateOfyear)
    handleResetPlantSelection();
    setTitle(`Financial Year ${moment(startDateOfYear).year()}-${moment(endDateOfyear).year()}`)
    setSelectedDatePDFCSV({ start: moment(startDateOfYear).format('DD-MMM-YY'), end: moment(endDateOfyear).format('DD-MMM-YY') })
    setYearSelected(true)
    setMonthSelected(false)
    setWeekSelected(false)
    setCustomSelected(false)
    setYesterdaySelected(false)
    setDateRange(null)
  }

  const handleYesterdaySelection = () => {
    handleDateButtonChange(startDateOfYesterday, endDateOfYesterday)
    handleGraphWiseData(startDateOfYesterday, endDateOfYesterday, selectedContractBookingGraph, "yesterday");
    handleResetPlantSelection();
    // setTitle(`${startDateOfYesterday}`)
    setTitle(`Yesterday ${moment(startDateOfYesterday).format('DD-MMM-YY')}`)
    setSelectedDatePDFCSV({ start: moment(startDateOfYesterday).format('DD-MMM-YY'), end: moment(endDateOfYesterday).format('DD-MMM-YY') })
    setYesterdaySelected(true)
    setYearSelected(false)
    setMonthSelected(false)
    setWeekSelected(false)
    setCustomSelected(false)
    setDateRange(null)
  }

  const handleMonthSelection = () => {
    handleDateButtonChange(startOfMonth, endOfMonth)
    handleGraphWiseData(startOfMonth, endOfMonth, selectedContractBookingGraph, "month");
    handleResetPlantSelection()
    setTitle(`Month ${moment(startOfMonth).format('MMM YYYY')}`)
    setSelectedDatePDFCSV({ start: moment(startOfMonth).format('DD-MMM-YY'), end: moment(endOfMonth).format('DD-MMM-YY') })
    setMonthSelected(true)
    setYearSelected(false)
    setWeekSelected(false)
    setCustomSelected(false)
    setYesterdaySelected(false)
    setDateRange(null)
  }

  const handleWeekSelection = () => {
    handleDateButtonChange(startOfWeek, endOfWeek)
    handleGraphWiseData(startOfWeek, endOfWeek, selectedContractBookingGraph, "week");
    handleResetPlantSelection()
    setTitle(`Week ${moment(startOfWeek).format('DD-MMM')} to ${moment(endOfWeek).format('DD-MMM')}`)
    setSelectedDatePDFCSV({ start: moment(startOfWeek).format('DD-MMM-YY'), end: moment(endOfWeek).format('DD-MMM-YY') })
    setWeekSelected(true)
    setYearSelected(false)
    setMonthSelected(false)
    setCustomSelected(false)
    setYesterdaySelected(false)
    setDateRange(null)
  }

  const handleDateRangePicker = () => {
    // console.log("customer called")
    handleResetPlantSelection()
    // const sDt = moment(dateRange?.startDate)?.format('YYYY-MM-DD')
    // const eDt = moment(dateRange?.endDate)?.format('YYYY-MM-DD')
    // handleDateButtonChange(sDt, eDt);
    setCustomSelected(true);
    setWeekSelected(false)
    setYearSelected(false)
    setMonthSelected(false)
    setYesterdaySelected(false)
    setOpen(!open)
  }

  useEffect(() => {
    setMounted(true);
    if (props.tabValue === 1) {
      setSelectedContractBookingGraph('tags')
      setCollapseTableField({
        firstTableField: tableHeaders('firstTableTab1'),
        secondTableField: tableHeaders('secondTableTab1'),
        thirdTableField: tableHeaders('thirdTableTab1'),
        fourthTableField: tableHeaders('fourthTableTab1'),
      })
    }
    else {
      setSelectedContractBookingGraph('customer_l1')
      setCollapseTableField({
        firstTableField: tableHeaders('firstTableTab0'),
        secondTableField: tableHeaders('secondTableTab0'),
        thirdTableField: tableHeaders('thirdTableTab0'),
        fourthTableField: tableHeaders('fourthTableTab0'),
      })

    }
    handleYesterdaySelection()
    return () => {
      setMounted(false)
    };
  }, [])

  useEffect(() => {
    // if (mounted) {
    setItemCategoryDropdownList(props?.itemCategoryDropdownList)
    const AllItemCategory = props?.itemCategoryDropdownList.filter(d => hasReadAccess(user, `${d.item_category_code}-dashboard`))
    setAllAccessesItemcategory(AllItemCategory)
    setItemcategorySelected([])
    // }
  }, [props?.itemCategoryDropdownList])

  useEffect(() => {
    // Cleanup function to cancel the previous request when component unmounts
    return () => {
      Object.entries(cancelTokenSource)
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => (cancelTokenSource[key].cancel("Operation canceled")));
    };
  }, [cancelTokenSource]);

  useEffect(() => {
    if (mounted) {
      console.log("here itemcategoryIDSelected", props.tabValue)
      if (yearSelected) {
        handleDateButtonChange(startDateOfYear, endDateOfyear)
        handleGraphWiseData(startDateOfYear, endDateOfyear, selectedContractBookingGraph, "year");
      } else if (monthSelected) {
        handleDateButtonChange(startOfMonth, endOfMonth)
        handleGraphWiseData(startOfMonth, endOfMonth, selectedContractBookingGraph, "month");
      } else if (weekSelected) {
        handleDateButtonChange(startOfWeek, endOfWeek)
        handleGraphWiseData(startOfWeek, endOfWeek, selectedContractBookingGraph, "week");
      } else if (yesterdaySelected) {
        handleDateButtonChange(startDateOfYesterday, endDateOfYesterday)
        handleGraphWiseData(startDateOfYesterday, endDateOfYesterday, selectedContractBookingGraph, "yesterday");
      } else if (customSelected) {
        handleDateButtonChange(
          moment(selectedDatePDFCSV.start, ['DD-MMM-YY']).format('YYYY-MM-DD'),
          moment(selectedDatePDFCSV.end, ['DD-MMM-YY']).format('YYYY-MM-DD'))
      }
    }
  }, [itemcategoryIDSelected])

  useEffect(() => {
    let itemIDList = [];
    if (itemCategorySelected.length === 0) {
      allAccessesItemcategory.map(d => itemIDList.push(d.id))
    } else {
      itemCategorySelected.map(d => itemCategoryDropdownList.find(h => h.category_name === d && itemIDList.push(h.id)))
    }
    setItemcategoryIDSelected(itemIDList)
  }, [itemCategorySelected])

  useEffect(() => {
    if (customSelected) {
      const sDt = moment(dateRange?.startDate)?.format('YYYY-MM-DD')
      const eDt = moment(dateRange?.endDate)?.format('YYYY-MM-DD')
      setSelectedDatePDFCSV({ start: moment(sDt).format('DD-MMM-YY'), end: moment(eDt).format('DD-MMM-YY') })

      if (sDt == eDt) {
        setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')}`)
      } else {
        setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')} to ${moment(eDt).format('DD-MMM\'YY')}`)
      }
    }
  }, [dateRange])

  useEffect(() => {
    if (mounted && (!open && customSelected)) {
      if (!dateRange?.startDate) {
        //handleYearSelection()
        // handleYesterdaySelection()
      } else {
        const sDt = moment(dateRange?.startDate)?.format('YYYY-MM-DD')
        const eDt = moment(dateRange?.endDate)?.format('YYYY-MM-DD')
        setSelectedDatePDFCSV({ start: moment(sDt).format('DD-MMM-YY'), end: moment(eDt).format('DD-MMM-YY') })

        if (sDt == eDt) {
          setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')}`)
        } else {
          setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')} to ${moment(eDt).format('DD-MMM\'YY')}`)
        }
        setMasterLineChartData([])
        setLineChartData([])
        handleDateButtonChange(sDt, eDt)
      }
    }
  }, [open])

  useEffect(() => {
    // if (financialData.length > 0) {
    setMasterLineChartData([])
    setLineChartData([])
    if (props.tabValue === 0) {
      setGraphBottomCalculation(prevValue => {
        return {
          ...prevValue,
          totalQuantity: Number(financialData?.reduce((t, v) => t + v?.total, 0)).toFixed(3)
        }
      })
    }
    if (yearSelected) {
      getYearlyData();
    } else if (monthSelected) {
      getMonthlyData();
    } else if (weekSelected) {
      getWeeklyData();
    } else if (yesterdaySelected) {
      getWeeklyData();
    }
    // }
  }, [financialData])

  useEffect(() => {
    setContractBookingRows(contractBookingRows)
    handlePDFCSVData(contractBookingRows)
    handleGraphBottomCalc(contractBookingRows)
  }, [contractBookingRows])

  useEffect(() => {
    if (availablePlants) {
      handlePDFCSVData(contractBookingRows)
      handleHeader(availablePlants)
    }
  }, [selectedContractBookingGraph])

  useEffect(() => {
    if (mounted & props.tabValue === 0) {
      // if (availablePlants) {
      //     handlePDFCSVData(contractBookingRows)
      //     handleHeader(availablePlants)
      // }
      if (yearSelected) {
        handleGraphWiseData(startDateOfYear, endDateOfyear, selectedContractBookingGraph, "year");
      } else if (monthSelected) {
        handleGraphWiseData(startOfMonth, endOfMonth, selectedContractBookingGraph, "month");
      } else if (weekSelected) {
        handleGraphWiseData(startOfWeek, endOfWeek, selectedContractBookingGraph, "week");
      } else if (yesterdaySelected) {
        handleGraphWiseData(startDateOfYesterday, endDateOfYesterday, selectedContractBookingGraph, "yesterday");
      }
    }
  }, [selectedContractBookingGraph])


  useEffect(() => {
    if (mounted) {
      if (laCollapseTablePdf.pdfData.length > 0) {
        handlePrintPDF(
          laCollapseTablePdf.pdfData,
          laCollapseTablePdf.pdfHeader,
          '',
          {
            startDate: selectedDatePDFCSV.start,
            endDate: selectedDatePDFCSV.end,
            item_category_name: itemCategorySelected
          },
          handleSnackbarPDFOpen,
          'la_booking_collapse_table'
        )
      }
    }
  }, [laCollapseTablePdf.pdfData,])

  useEffect(() => {
    if (mounted) {
      if (laCollapseTableCsv.csvData.length > 0) {
        window.cordova
          ? saveCSVInMobile(
            laCollapseTableCsv.csvData,
            laCollapseTableCsv.csvHeader,
            `la_booking_collapse_table${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`,
            handleSnackbarPDFOpen, '')
          : refCSVLink.current.link.click();
      }
    }
  }, [laCollapseTableCsv.csvData])

  useEffect(() => {
    const selectedLineChartData = masterLineChartData.map(m => {
      const _data = { ...m }
      selectedPlants.forEach(p => delete _data[p])
      return _data
    })
    setLineChartData(selectedLineChartData)
  }, [selectedPlants])

  const TOGGLEFIELD = [
    { label: 'Customer L1', key: 'customer_l1', function: handleClickToggle },
    { label: 'State', key: 'state', function: handleClickToggle },
  ]


  return (
    <>
      <div style={{ paddingLeft: 30, paddingTop: 40, paddingRight: 30, paddingBottom: ['contract_booking_table', 'la_booking_table'].includes(props?.widget) && 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile && 'column' }}>
          <div>
            <div style={{ fontSize: pxToEm(20), fontFamily: 'Poppins', }}>
              {props.tabValue === 0
                ? `Contract Booking`
                : props?.widget === 'la_booking_table'
                  ? 'Sales Details'
                  : `Item Tags`}
            </div>
            <div style={{ fontSize: pxToEm(12), color: "#707070", fontFamily: 'Poppins', }}>
              {['Item Tags', 'la_booking_table'].includes(props?.widget)
                ? "Excluding Intercompany"
                : "Excluding Intercompany"}
            </div>

          </div>
          <div style={{ float: "right" }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: isMobile && 'column', marginTop: isMobile && 30 }}>
              <FormControl style={styles.SelectContainer(isMobile)} size="small">
                <InputLabel
                  id="item-category-filter-label"
                  style={{ fontFamily: 'Poppins', paddingTop: 8, }}
                >
                  Item Categories*
                </InputLabel>
                <Select
                  multiple
                  value={itemCategorySelected?.length === 0 ? ["ALL"] : itemCategorySelected}
                  onChange={handleItemCategorySelection}
                  input={<OutlinedInput label="Item Categories*" />}
                  SelectDisplayProps={{ style: { paddingBottom: 14 } }}
                  labelId="item-category-filter-label"
                  id="item-category-filter"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>All</em>;
                    }

                    return selected.join(', ');
                  }}
                  MenuProps={MenuProps}
                  style={{
                    fontFamily: 'Poppins',
                  }}
                >
                  <MenuItem key="ALL" value="">
                    <Checkbox
                      style={{ borderColor: 'gray', color: '#1976d2' }}
                      checked={itemCategorySelected?.length === 0}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Poppins',
                        },
                      }}
                      primary="ALL"
                      style={styles.dropdownText}
                    />
                  </MenuItem>
                  {itemCategoryDropdownList ?
                    itemCategoryDropdownList.map((category) => (
                      <MenuItem
                        key={category.category_name}
                        value={category.category_name}
                        disabled={!hasReadAccess(user, `${category.item_category_code}-dashboard`)}
                      >
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={itemCategorySelected?.indexOf(category.category_name) > -1}
                        />
                        <ToolTipComponent
                          title="You have no read permission"
                          condition={!hasReadAccess(user, `${category.item_category_code}-dashboard`)}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontFamily: 'Poppins',
                              },
                            }}
                            primary={category.category_name}
                            style={styles.dropdownText}
                          />
                        </ToolTipComponent>
                      </MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '& > *': {
                    m: 1,
                  },
                }}
              >
                <ButtonGroup variant="outlined" aria-label="outlined button group" color="inherit">
                  <Button style={{ width: 45, height: 45, backgroundColor: yearSelected ? "#293C79" : "white", color: yearSelected ? "white" : "grey" }}
                    onClick={handleYearSelection}
                  >
                    Y
                  </Button>
                  <Button style={{ width: 45, height: 45, backgroundColor: monthSelected ? "#293C79" : "white", color: monthSelected ? "white" : "grey" }}
                    onClick={handleMonthSelection}
                  >
                    M
                  </Button>
                  <Button style={{ width: 45, height: 45, backgroundColor: weekSelected ? "#293C79" : "white", color: weekSelected ? "white" : "grey" }}
                    onClick={handleWeekSelection}
                  >
                    W
                  </Button>
                  {/* <Button style={{ width: 45, height: 45, backgroundColor: todaySelected ? "#293C79" : "white", color: todaySelected ? "white" : "grey" }}
                    onClick={handleTodaySelection}
                  >
                    T
                  </Button> */}
                  <Button style={{ width: 50, height: 45, backgroundColor: yesterdaySelected ? "#293C79" : "white", color: yesterdaySelected ? "white" : "grey", padding: '5px 8px' }}
                    onClick={handleYesterdaySelection}
                  >
                    T - 1
                  </Button>
                  {['contract_booking_table', 'la_booking_table'].includes(props?.widget) &&
                    <Button style={{ width: 45, height: 45, backgroundColor: customSelected ? "#293C79" : "white", color: customSelected ? "white" : "grey" }}
                      onClick={handleDateRangePicker}

                    >
                      <DateRangeIcon color="grey" />
                    </Button>
                  }

                </ButtonGroup>
                <MuiDateRangePicker
                  open={open}
                  toggle={toggle}
                  dateRange={dateRange}
                  onDateRangeChange={handleDataRangeChange}
                />
                <div style={{ width: '100%', textAlign: 'end', paddingRight: 10, fontSize: pxToEm(10), color: "grey", float: "right", margin: 0 }}>{title}</div>
              </Box>
              {['contract_booking_table', 'la_booking_table'].includes(props?.widget) &&
                <div style={{ padding: ['contract_booking_table',].includes(props?.widget) ? '15px 0 0 10px' : '10px 0 0 10px' }}>
                  {props?.widget === 'contract_booking_table'
                    ? <PdfCsvButton
                      handlePrintClick={() => handlePrintPDF(
                        collapseTablePdfCsv.pdfCsvData,
                        collapseTablePdfCsv.pdfCsvHeader,
                        '',
                        {
                          startDate: selectedDatePDFCSV.start,
                          endDate: selectedDatePDFCSV.end,
                          item_category_name: itemCategorySelected
                        },
                        handleSnackbarPDFOpen,
                        'contract_booking_collapse_table'
                      )}
                      csvData={collapseTablePdfCsv.pdfCsvData}
                      csvHeader={collapseTablePdfCsv.pdfCsvHeader}
                      mobileCSVFilename={`contract_booking_collapse_table${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                      webCSVFilename={`contract_booking_collapse_table${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                      handleSnackbarPDFOpen={handleSnackbarPDFOpen}
                    />
                    : props?.widget === 'la_booking_table' &&
                    <PdfCsvButton
                      handlePrintClick={() => { handlePDFCSVButtonClick('print') }}
                      handleCSVClick={() => { handlePDFCSVButtonClick('csv') }}
                      onClickApiCall={true}
                      refCSVLink={refCSVLink}
                      csvData={laCollapseTableCsv.csvData}
                      csvHeader={laCollapseTableCsv.csvHeader}
                      csvLoader={laCollapseTablePdfCsvLoader === 'csv' ? true : false}
                      pdfLoader={laCollapseTablePdfCsvLoader === 'print' ? true : false}
                      mobileCSVFilename={`la_booking_collapse_table${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                      webCSVFilename={`la_booking_collapse_table${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                      handleSnackbarPDFOpen={handleSnackbarPDFOpen}
                    />
                  }
                  <SnackbarComponent
                    open={snackbarPDFOpen}
                    handleClose={handleSnackbarPDFClose}
                    customAutoHideDuration={3000}
                    snackbarMessage="Downloading ..."
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {['contract_booking_table', 'la_booking_table'].includes(props?.widget) &&
        <FirstTable
          {...props}
          classes={classes}
          styles={styles}
          CollapseTableField={CollapseTableField}
          showProgress={showProgress}
          itemWiseRows={itemWiseRows}
          selectedDatePDFCSV={selectedDatePDFCSV}
          handleCatchError={handleCatchError}
        />
      }
      {props?.tabValue === 0 &&
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 30px' }}>
            <ToggleButton
              isMobile={isMobile}
              toggleField={TOGGLEFIELD}
              module="contract_booking"
            />
            <div style={{ padding: '10px 0 0 10px' }}>
              <PdfCsvButton
                handlePrintClick={() => handlePrintPDF(
                  pdfCsvData,
                  pdfCsvHeader,
                  tableHeaderColumnWidth,
                  {
                    startDate: selectedDatePDFCSV.start,
                    endDate: selectedDatePDFCSV.end,
                    item_category_name: itemCategorySelected
                  },
                  handleSnackbarPDFOpen,
                  'contract_booking'
                )}
                csvData={pdfCsvData}
                csvHeader={pdfCsvHeader}
                mobileCSVFilename={`contract_booking_${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                webCSVFilename={`contract_booking_${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                handleSnackbarPDFOpen={handleSnackbarPDFOpen}
              />
              <SnackbarComponent
                open={snackbarPDFOpen}
                handleClose={handleSnackbarPDFClose}
                customAutoHideDuration={3000}
                snackbarMessage="Downloading ..."
              />
            </div>
          </div>
          <div style={{ paddingBottom: 10 }}>
            <BottomTable
              {...props}
              classes={classes}
              styles={styles}
              toggleIndex={toggleIndex}
              showBottomTableProgress={showBottomTableProgress}
              contractBookingRows={contractBookingRows}
              BottomCalculation={BottomCalculation}
              handleGraphBottomCalc={handleGraphBottomCalc}
              selectedContractBookingGraph={selectedContractBookingGraph}
              tableHeaderColumnWidth={tableHeaderColumnWidth}
              availablePlants={availablePlants}
            />
          </div>
        </>
      }
      {['contract_booking_table', 'item_tags'].includes(props?.widget) &&
        <>
          <br />
          <Divider variant="fullWidth" />
          <div style={{ width: "98%", alignItems: "center", margin: isMobile ? "50px 0px 100px" : "50px 10px", paddingBottom: 80 }}>
            <Paper style={{ borderRadius: "20px" }}>
              <LineSalesGraph
                data={lineChartData}
                lineColor={lineColor}
                yearSelected={yearSelected}
                monthSelected={monthSelected}
                weekSelected={weekSelected}
                // todaySelected={todaySelected}
                yesterdaySelected={yesterdaySelected}
                hasLineChartData={hasLineChartData}
                extraData={graphBottomCalculation}
                isMobile={isMobile}
                graph={'order_booking_table'}
              />
            </Paper>
          </div>
          {(hasLineChartData && !customSelected && financialData.length > 0) &&
            <div style={{ width: "100%", paddingTop: 20 }}>
              <TableContainer className={classes.root} style={{ width: "98%", position: "relative", margin: '0 10px' }}>
                {financialData.length > 0 ?
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow className={classes.root}>
                        <TableCell className={classes.fixed} style={{ borderBottom: "none" }}></TableCell>
                        <TableCell align="right" style={{ borderBottom: "none", color: "#293C79" }} className={classes.sticky}>Total</TableCell>
                        {
                          dataKeys.map((d) => (<TableCell align="right" style={{ borderBottom: "none", width: "100px", color: "#293C79" }} className={classes.root}>{d}</TableCell>))
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {financialData?.map((row) => (
                        <TableRow key={row.name} className={classes.root}>
                          <TableCell component="th" scope="row" className={classes.fixed} style={{ borderBottom: "none", display: "flex", alignItems: "center" }} onClick={() => handleItemSelection(row.name)}>
                            <span style={{ marginTop: "5px" }}>{showRoundIcon(row.name)}</span>
                            <span style={{ fontSize: pxToEm(18), marginLeft: "10px" }}>
                              {row.name}
                            </span>
                          </TableCell>
                          <TableCell align="right" style={{ borderBottom: row.name == "Fly Ash" ? "2px solid #808080" : "none" }} className={classes.sticky}>{row.total}</TableCell>
                          {
                            dataKeys.map((d) => (<TableCell align="right" style={{ borderBottom: row.name == "Fly Ash" ? "2px solid #808080" : "none", width: "100px" }} className={classes.root}>{row[d]}</TableCell>))
                          }
                        </TableRow>
                      ))}
                      <TableRow className={classes.root}>
                        <TableCell component="th" scope="row" className={classes.fixed} style={{ borderBottom: 'none' }}>

                        </TableCell>
                        <TableCell align="right" className={classes.sticky} style={{ borderTop: "2px solid grey", color: "#293C79", borderBottom: 'none' }}>{Number(financialData?.reduce((t, v) => t + v?.total, 0)).toFixed(3)}</TableCell>
                        {
                          dataKeys.map((d) => (<TableCell align="right" className={classes.root} style={{ borderTop: "2px solid grey", color: "#293C79", width: "100px", borderBottom: 'none' }}>{Number(financialData?.reduce((t, v) => t + v[d] ?? 0, 0)).toFixed(3)}</TableCell>))
                        }

                      </TableRow>
                    </TableBody>
                  </Table>
                  : <></>}
              </TableContainer>
            </div>
          }
        </>
      }
      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </>
  )

}
