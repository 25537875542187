import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import DisplayCustomerTypeData from "../DisplayCustomerTypeData";

class ReasonWeb extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <DisplayCustomerTypeData {...this.props} />
      </Layout>
    );
  }
}
export default ReasonWeb;
