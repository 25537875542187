import React, { Component } from "react";
import OrderCard from "./OrderCard";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor } from "../../../styles/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import backStack from "cordova-back-stack";

const styles = {
  CancelMainBody: {
    width: "100%",
  },
  messageShowContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10px 4%"
  },
  messageShow: {
    width: "100%",
    // display: "flex",
    // flexDirection: "column",
    paddingTop: "10px",
    paddingLeft: "15px",
    fontSize: "1.1em",
    color: bottomNavigationFontColor,
  },
};

class Cancel extends Component {
  componentDidMount() {
    // console.log(this.props.orders)
    // console.log("render")
    // let location = this.props.location
    let returnUrl = "/orders"
    localStorage.setItem("returnUrl", returnUrl)
    let currentUrl = window.location.pathname;
    localStorage.setItem("currentUrl", currentUrl)
    let orderId = this.props.match.url.split("/")
    let location = this.props.location
    if (location.state && !this.props.details) {
      this.setState({
        details: location.state.approvedOrder
      })
    } else if (!this.props.details) {
      this.props.handleSingleOrders(null, orderId[3])
    }
    // else if (this.props.orders.length > 0) {
    //   let order = this.props.orders && this.props.orders.find(order => order.order_number === this.props.location.pathname.split("/")[3])
    //   console.log(order)
    //   this.props.handleSingleOrders(order);
    // }
  }

  UNSAFE_componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      if ((String(localStorage.getItem("currentUrl"))).includes('orders/cancelled')) {
        localStorage.setItem("returnUrl", "/orders")
        this.props.history.push("/orders");
      }
      else {
        this.props.history.push(String(localStorage.getItem("returnUrl")));
      }
    });
  }

  // componentDidUpdate() {
  //   let returnUrl = String(window.location.pathname).slice(0, String(window.location.pathname).indexOf('/active'));
  //     localStorage.setItem("returnUrl", returnUrl)
  //     let currentUrl = window.location.pathname;
  //     localStorage.setItem("currentUrl", currentUrl);
  //   document.addEventListener("backbutton", this.loadOldUrl);
  // }
  // loadOldUrl = () => {
  //   this.props.history.push(String(localStorage.getItem("returnUrl")))
  // }
  render() {
    const { classes, details, orders } = this.props;
    if (orders && orders.length > 0 && !details) {
      let order = this.props.orders && this.props.orders.find(order => order.order_number === this.props.location.pathname.split("/")[3])
      // console.log(order)
      this.props.handleSingleOrders(order);
    }
    // console.log(this.props)
    return (
      <div className={classes.CancelMainBody}>
        {!this.props.isMobile ? (
          <div style={{ padding: "10px 0px 10px 4%" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                if (this.props?.location?.state?.message)
                  this.props.history.push("/home")
                else
                  if (this.props.history.length > 2)
                    this.props.history.goBack();
                  else
                    this.props.history.push("/orders")
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          </div>
        ) : null}
        <OrderCard title="Cancelled" details={details ? details : this.props.location.state} />
        <div className={classes.messageShowContainer}>
          <div className={classes.messageShow}>
            <span>This contract is cancelled due to </span>
            <span>{details?.reason}</span>
          </div>
        </div>
        <div
          style={{
            height: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: "vh",
          }}
        >
          <CancelIcon
            style={{
              height: "90%",
              width: "40%",
              color: bottomNavigationFontColor,
            }}
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Cancel);
