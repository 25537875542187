import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormHelperText } from "@material-ui/core";


export class FilterByVehicle extends Component {

    render() {
        const { vehicleNumber, vehicleNumberValidationError } = this.props
        // console.log(maxQuantity && !(maxQuantity === ""))
        return (
            // <div style={{
            //     display: 'flex',
            //     justifyContent: 'space-between',
            //     width: '100%',
            // }}>
            <TextField
                name="minQuantity"
                id="outlined-name"
                label="Vehicle Number"
                value={vehicleNumber}
                style={{ width: "100%" }}
                margin="normal"
                variant="outlined"

                error={vehicleNumberValidationError}
                helperText={vehicleNumberValidationError &&
                    "Invalid input"
                    // :
                    // minQuantity > maxQuantity
                    // && "invalid input"
                }

                // InputLabelProps={{
                //     style: { fontSize: "0.9em" },
                // }}
                onChange={this.props.handleChange("vehicleNumber")}
            />
            // </div>
        )
    }
}

export default FilterByVehicle