import React, { Component, lazy } from "react";
import Orders, {
  OrdersWeb,
  DealerOrdersMobile,
  DealerOrdersWeb,
  PendingOrders,
  PendingOrdersWeb,
  SingleOrder,
  SingleOrderWeb,
  SingleOrderDetails,
  SingleOrderDetailsWeb,
  AddRequirements,
  AddRequirementsWeb,
  CanceledOrder,
  CanceledOrderWeb,

} from "../../../components/b2b/orders";
import ConfirmOrder from "../../../components/b2b/orders/mobile/ConfirmOrder"
import ConfirmOrderWeb from "../../../components/b2b/orders/web/ConfirmOrder"
import TermsAndConditions from "../../../components/b2b/common/TermsAndConditions";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import B2b_URL from "../../../constants/b2b/B2bRoutes";
import SnackbarComponent from "../../../components/common/SnackbarComponent"
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import { StaticAppVersion, arraySortBykeyAllBrowser, checkApiError } from "../../../methods";
import withUser from "../../../hoc/withUser";
import { withRouter } from "react-router";
import { Snackbar } from "@material-ui/core";
import upiqr from "upiqr";
import moment from "moment";

class B2bOrdersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSingleOrder: null,
      singleOrderPlantPlanning: null,
      singleOrderLA: null,
      singleOrderInvoices: null,
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
      orders: null,
      ordersListLoader: true,
      plantPlanningLoader: true,
      orderDetailsLoader: true,
      selectedOrder: null,
      laLoader: false,
      invoiceLoader: false,
      totalLAQuantity: 0,
      orderLazyLoader: false,
      singleOrderLoader: false,
      showOrder: "ONGOING",
      approveContractLoader: false,
      reasonMaster: [],
      b2CQrImage: null,
      plantSubcontractDetail: [],
      plantSubcontractDetailLoader: false,
    };
  }
  cancelToken = '';

  componentDidMount() {
    if (navigator.onLine === false) {
      this.setState({
        isOffline: true,
      });
    }
    // console.log(this.props?.location)
    // this.fetchOrderList("all")
    if (this.props?.location?.state?.ORDERID) {
      // console.log(ORDERID)
      this.handleContractSelectChip("PENDING")
      this.handleSingleOrders(false, this.props?.location?.state?.ORDERID)
    }
    this.fetchOrderListForDropdown()
    this.getAllStatesList()
    // console.log('here LINK 1', String(localStorage.getItem("currentUrl")), String(localStorage.getItem("returnUrl")))
    if (String(localStorage.getItem("currentUrl")) === String(localStorage.getItem("returnUrl"))) {
      // let returnUrl = '/home';
      // localStorage.setItem("returnUrl", returnUrl)
      // console.log('here LINK 2', String(localStorage.getItem("currentUrl")), String(localStorage.getItem("returnUrl")))
    }
  }

  componentDidUpdate() {
    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = window.location.pathname;
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
    document.addEventListener("backbutton", this.loadOldUrl, false);
  }

  offlineCheck = () => {
    this.setState({ isOffline: true });
  };
  onlineCheck = () => {
    this.setState({ isOffline: false });
  };

  componentWillMount() {
    window.addEventListener("offline", this.offlineCheck);
    window.addEventListener("online", this.onlineCheck);
  }

  loadOldUrl = () => {
    this.props.history.push(String(localStorage.getItem("returnUrl")))
  }

  handleContractSelectChip = (key) => {
    this.setState({ showOrder: key },
      () => this.fetchOrderList(key, null)
    )
  }
  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  getAllStatesList = (countryId) => {
    axios.get(`${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}&V=${StaticAppVersion()}`,
      this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            allStateList: responseData,
            // allStateListDataFetchLoader: false,
          });
          // console.log("STATE LIST" + this.state.allStateList)
          // for (let key in responseData) {
          //   console.log(key + ":", responseData[key]);
          // }
        },

      )
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
        // this.setState({ allStateListDataFetchLoader: false, });
      })

  };
  // componentDidUpdate(prevProps) {
  //   console.log(prevProps)
  // }

  fetchOrderList = (filter, orderId) => {
    // console.log(filter, orderId)
    let url = ""
    if (!filter) {
      // console.log("here")
      url = `${B2b_URL.GET_ORDERS_LIST}${this.props.user.id}/?order_by=created_at`
    }
    else {
      // console.log("here 2")
      url = `${B2b_URL.GET_ORDERS_LIST}${this.props.user.id}/?order_by=created_at&filter_by=${filter}`
    }
    if (!this.state.ordersListLoader)
      this.setState({
        ordersListLoader: true
      })
    if (typeof this.cancelToken != typeof undefined) {
      this?.cancelToken && this.cancelToken.cancel("Operation canceled")
    }
    this.cancelToken = axios.CancelToken.source()
    axios.get(`${url}&V=${StaticAppVersion()}`, { cancelToken: this.cancelToken.token }, this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        // if (orderId || url === `${B2b_URL.FETCH_ALL_ORDERS}?customer=${this.props.user.id}`) {
        //   let order = response.data.find(order => order.order_number === orderId)

        //   this.handleSingleOrders(order);
        //   this.fetchSingleOrderLoadingAdvices(order)
        //   this.setState({
        //     orders: response.data.results,
        //     ordersListLoader: false,
        //     // nextURL: response.data.next,
        //   }, console.log())

        // } else {
        this.setState({
          orders: response.data.results,
          ordersListLoader: false,
          nextURL: response.data.next,
        })
        // }
      })
      .catch(err => {
        if (JSON.stringify(err) === '{"message":"Operation canceled"}') {
          this.setState({ dispatchLoader: true, })
        }
        else {
          // this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: "Error occured while fetching Contracts",
          //   snackbarType: "error",
          //   // ordersListLoader: false
          // })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        }
      })
  }

  fetchNextOrders = (filter, url) => {
    // console.log("called")
    if (url) {
      if (!this.state.orderLazyLoader)
        this.setState({
          orderLazyLoader: true,
        })
      if (url === this.state.nextURL)
        axios.get(`${url}&V=${StaticAppVersion()}`, this.getOptions(this.props.user))
          .then(response => {
            // console.log(response)
            let prevOrders = this.state.orders
            let res = response.data.results
            let orders = prevOrders.concat(res)
            this.setState({
              nextURL: response.data.next,
              orders,
              orderLazyLoader: false,
            })
          })
          .catch(err => {
            // console.log(err)
            this.setState({
              // openSnackbar: true,
              // snackbarMessage: "Error occured while fetching Contracts",
              // snackbarType: "error",
              orderLazyLoader: false
            })
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 4000)
          })
    }

  }

  fetchOrderListForDropdown = () => {
    // console.log(this.props.user)
    axios.get(`${B2b_URL.FETCH_ORDERS_FOR_DROPDOWN}?customer=${this.props.user.id}&V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response.data)
        let data = response.data
        let fifoOrder = null
        // if (data.length > 0) {
        // fifoOrder = response.data.find(d => d.is_fifo_allowed)

        // if (!fifoOrder) {
        //   console.log(response.data[data.length - 1])
        // response.data[data.length - 1].open_order_for_LA = true
        // }
        // }
        // console.log(fifoOrder)
        this.setState({
          ordersList: response.data
        })

      })
      .catch(err => {
        // console.log(err)
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: "Error occured while fetching Contracts",
        //   snackbarType: "error"
        // })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })

  }

  handleCloseBackDrop = () => {
    this.setState({ singleOrderLoader: false })
  }

  handleSingleOrders = (order, orderId) => {
    // if(!this.state.orderDetailsLoader){
    //   this.setState({
    //     orderDetailsLoader:true
    //   })
    // }
    // console.log(order)
    this.setState({
      // selectedSingleOrder: response,
      singleOrderLoader: true
    })
    let url = `${B2b_URL.RETRIEVE_ORDER_DETAILS}${order ? order.id : orderId && orderId}/`

    axios.get(`${url}?V=${StaticAppVersion()}`)
      .then(res => {
        // console.log(res)
        let response = res.data

        // response[data.length - 1].open_order_for_LA = true

        if (
          [
            "APPROVED",
            "ONGOING",
            "COMPLETED",
            "CLOSED",
            "AUTOCLOSED",
            "CONVERSION_ALLOTMENT_HOLD",
            "HOLD",
            "PENDING"
          ].includes(response.order_status)
        ) {
          this.setState({
            selectedSingleOrder: response,
            singleOrderLoader: false
          })
          // console.log("here")
          this.fetchSingleOrderPlantPlanning(response)

          // console.log(this.props.location.pathname)
          if (this.props.location.pathname !== `/orders/active/${response.id}` || !this.props.location.pathname)
            this.props.history.push({ pathname: `/orders/active/${response.id}`, state: response });
          else
            this.props.history.replace({ pathname: `/orders/active/${response.id}`, state: response });
        }
        // if (response.order_status === "PENDING") {
        //   this.setState({
        //     selectedSingleOrder: response,
        //     singleOrderLoader: false
        //   })
        //   // console.log(response)
        //   // console.log(this.props.location.pathname)
        //   if (this.props.location.pathname !== `/orders/pending/${response.id}` || !this.props.location.pathname)
        //     this.props.history.push({ pathname: `/orders/pending/${response.id}`, state: response });
        //   else
        //     this.props.history.replace({ pathname: `/orders/pending/${response.id}`, state: response });
        // }

        if (response.order_status === "CANCELLED") {
          this.setState({
            selectedSingleOrder: response,
            singleOrderLoader: false
          })
          // console.log(this.props.location.pathname)
          if (this.props.location.pathname !== `/orders/cancelled/${response.id}` || !this.props.location.pathname)
            this.props.history.push({ pathname: `/orders/cancelled/${response.id}`, state: response });
          else
            this.props.history.replace({ pathname: `/orders/cancelled/${response.id}`, state: response });
        }
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })

  };

  fetchSingleOrderPlantPlanning = (order) => {
    if (!this.state.plantPlanningLoader) {
      this.setState({
        plantPlanningLoader: true
      })
    }
    axios.get(`${B2b_URL.GET_PLANT_PLANNING_DETAILS}?order_id=${order.id}&V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        let plantPlanning = response.data.results
        this.setState({
          singleOrderPlantPlanning: plantPlanning,
          plantPlanningLoader: false
        })
      })
      .catch(err => {
        this.setState({ plantPlanningLoader: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  fetchSingleOrderLoadingAdvices = (order) => {
    if (!this.state.laLoader) {
      this.setState({
        laLoader: true
      })
    }
    // console.log(order)
    axios.get(`${B2b_URL.GET_SINGLE_ORDER_LA}${order.id}/?V=${StaticAppVersion()}/`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        // let plantPlanning = response.data.results
        if (response.data.length > 0) {
          let data = response.data
          let totalLAQuantity = 0
          data.map(q => {
            // console.log(q)
            if (!(q.dispatch_status === "CANCELLED" || q.dispatch_status === "LAPSED")) {
              // console.log("here")
              totalLAQuantity += q.quantity
            }
          })

          // console.log(totalLAQuantity)
          this.setState({
            totalLAQuantity
          })


        }
        this.setState({
          singleOrderLA: response.data,
          laLoader: false,
          // totalLAQuantity
        })
      })
      .catch(err => {
        // console.log(err)
        this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: `Error while Loading Advices for this Contract`,
          //   snackbarType: "error",
          laLoader: false,
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  fetchSingleOrderInvoices = (order) => {
    if (!this.state.invoiceLoader) {
      this.setState({
        invoiceLoader: true
      })
    }
    // axios.get(`${B2b_URL.GET_SINGLE_ORDER_INVOICES}${order.id}/?V=${StaticAppVersion()}/`
    axios.get(`${B2b_URL.GET_SINGLE_ORDER_INVOICES}?order_id=${order.id}&V=${StaticAppVersion()}/`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        let data = response.data

        data.forEach(element => {
          element.downloadReady = false
        });
        // let plantPlanning = response.data.results
        this.setState({
          singleOrderInvoices: data,
          invoiceLoader: false,
        })
      })
      .catch(err => {
        // console.log(err)
        this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: `Error while fetching Invoice details`,
          //   snackbarType: "error",
          invoiceLoader: false,
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      }
      )
  }

  handleOpenSnackBar = () => {
    // console.log(this.state.singleOrderInvoices)
    this.setState({
      openSnackbar: true,
      snackbarMessage: "Downloading PDF....",
      snackbarType: "success"
    })
  }
  fetchLADetails = (LANumber) => {
    // console.log(LANumber)
    if (LANumber)
      return axios.get(`${B2b_URL.GET_LA_DETAILS}${LANumber}/?V=${StaticAppVersion()}/`
        , this.getOptions(this.props.user))

    else return "NO LA NUMBER FOUND"
  }

  handleInvoiceClick = (invoice) => {
    // console.log(invoice)
    let la_number = "LANV2209269822"
    this.handleOpenSnackBar()
    axios
      .get(`${APIROUTES.FETCH_LA_DETAILS_IN_DISPATCH_COUNTER}${invoice.la_number}/?V=${StaticAppVersion()}/`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          this.state.allStateList && this.state.allStateList.map(data => {
            if (data['id'] === response.data.plant.plant_state) {
              // console.log(data['id'] + ":" + data['name'])
              this.setState({
                plant_state: data['name']
              })
            }
          })
          let data = response.data
          // if (data.created_at && data.created_at_time)
          //   data.created_at_date_and_time = data.created_at + " " + data.created_at_time
          this.setState(
            {
              laDetailsInDispatchCounter: data,
              laDispatchCounterLoader: false,
              downloadedInvoice: invoice
              // invoiceDownloadLoader: callfor === "download_screen" ? false : true,
            },
            () => {
              this.getEinvoiceEwayDetails(invoice)
              this.handleCreateB2cInvoiceQr()
            }
            // () => {
            //   this.handleGetDistance();
            // }
          );


        } else {
          this.setState({
            laDetailsInDispatchCounter: null,
            laDispatchCounterLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ laDispatchCounterLoader: false });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  }

  handlePostInvoiceDownload = () => {
    // let { downloadedInvoice } = this.state
    let invoice = { ...this.state.downloadedInvoice }
    // console.log(invoice.la_number, this.state.singleOrderInvoices)
    let downloadedInvoice = this.state.singleOrderInvoices.find(inv => inv.la_number === invoice.la_number)
    downloadedInvoice.downloadReady = false
    // console.log(downloadedInvoice.downloadReady)
    // this.setState({
    //   downloadedInvoice
    // })
  }
  getEinvoiceEwayDetails = (data) => {
    axios
      .get(`${APIROUTES.GET_E_INVOICE_AND_E_WAY}${data.la_number}/?V=${StaticAppVersion()}/`)
      .then((response) => {

        data.downloadReady = true
        // console.log(response)
        this.setState({
          eInvoiceResponse: response.data,
          eWayBillResponse: response.data?.is_eway_created
            ? response.data
            : null,
          iseWaySelect: response.data?.is_eway_created,
          invoiceType: response.data?.invoice_type,
        });
        // this.props.history.push(
        //   `/dispatchOperation/dc/2/details/invoices/download/${laNumber}`
        // );
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  handleCreateB2cInvoiceQr = () => {
    //alert("QR Genrating");
    this.setState({ dataInvoicePostLoader: true });
    let supplierAccountDetails = this?.state?.plantAccountDetails;
    if (
      this?.state?.laDetailsInDispatchCounter?.payment_method
        ?.payment_method_name === "Advance"
    ) {
      // this.props.history.push("download");
    } else {
      upiqr({
        currency: this?.state?.laDetailsInDispatchCounter?.customer?.currency_code,
        payeeVPA:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_upi_id,
        payeeName:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_name,
        amount: this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value,
        transactionRef: this.state?.laDetailsInDispatchCounter?.erp_invoice_number,
        transactionNote: `SUPGSTN ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number
          } PAYEE_AC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no
          } PAYEE_IFSC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc
          } ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
            ? "IGST " + this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount
            : `CGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount} SGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount}`
          } CESS ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount
          } TOT_AMT ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value
          } INV_DATE ${moment(
            this?.state?.laDetailsInDispatchCounter?.created_at,
            ["DD/MM/YYYY", "YYYY-MM-DD"]
          ).format("DD MMM YYYY")}`,
      })
        .then((upi) => {
          console.log(upi.qr); // data:image/png;base64,eR0lGODP...
          this.setState(
            { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
            () => console.log(1)
          );
        })
        .catch((err) => {
          this.setState({ b2CQrImage: null, dataInvoicePostLoader: false })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        });
    }
  };

  handleOrderApproveClick = (order, operation, reason = "") => {
    // console.log(order)
    let payload = {}
    if (operation === "approve") {
      payload = { is_approved_by_customer: true }
      this.setState({ approveContractLoader: true })
    } else {
      payload = { is_approved_by_customer: false, reason: reason }
    }

    // this.handleApproveOrder(order)
    // this.fetchSingleOrderPlantPlanning(order)
    // this.fetchSingleOrderLoadingAdvices(order)
    // this.fetchSingleOrderInvoices(order)
    // console.log(payload)
    axios.patch(`${B2b_URL.APPROVE_CONTRACT}${order.id}/?V=${StaticAppVersion()}/`, payload, this.getOptions(this.props.user))
      .then(resp => {
        // console.log(resp)
        if (resp.status == 200) {
          if (operation === "approve") {
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Contract Approved",
              snackbarType: "success"
            })
            this.setState({ approveContractLoader: false })

          }
          else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Contract Cancelled",
              snackbarType: "success"
            })

          }
          let run = setTimeout(() => {
            this.handleApproveOrder(order, operation)
            this.props.history.push(`/home`)
            clearInterval(run);
          }
            , 2000)
          // this.setState({ selectedSingleOrder: order })
          // this.fetchOrderList()
          // console.log(this.state.orders)
          // this.fetchSingleOrderPlantPlanning(order)
          // this.fetchSingleOrderLoadingAdvices(order)
          // this.fetchSingleOrderInvoices(order)
          // this.setState({ selectedSingleOrder: order },
          //   this.props.history.push({ pathname: `/orders/active/${order.id}`, state: order }))
        }
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.setState({ approveContractLoader: false })
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  NoMatch = () => {
    return <h3>No match found</h3>
  }

  handleApproveOrder = (order, operation) => {
    // console.log(order, operation)
    axios.get(`${B2b_URL.RETRIEVE_ORDER_DETAILS}${order.id}/?V=${StaticAppVersion()}/`
      , this.getOptions(this.props.user)
    )
      .then(response => {
        // console.log(response)
        let approvedOrder = response.data
        if (approvedOrder.order_status === "APPROVED") {
          this.fetchSingleOrderPlantPlanning(approvedOrder)
          this.fetchSingleOrderLoadingAdvices(approvedOrder)
          this.fetchSingleOrderInvoices(approvedOrder)
          this.setState({ selectedSingleOrder: approvedOrder },
            operation === "approve" ?
              this.props.history.push({ pathname: `/orders/active/${order.id}`, state: { approvedOrder, message: "orderCreated" } })
              : this.props.history.push({ pathname: `/orders/cancelled/${order.id}`, state: { approvedOrder, message: "orderCreated" } })
          )
        }
        else if (approvedOrder.order_status === "PENDING") {
          this.props.history.push({ pathname: `/orders/pending/${order.id}`, state: { approvedOrder, message: "orderCreated" } })
        }

      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  fetchShortCloseReasonMaster = (moduleName) => {
    // await this.props
    //   .fetchReasonMaster("Contracts")
    axios.get(`${B2b_URL.GET_REASON}?module=${moduleName}&active=${true}&V=${StaticAppVersion()}`, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          // console.log("data for category", response)
          this.setState({
            reasonMaster: response.data
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching reason master",
            snackbarType: "error",
          });
        }
      })
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  fetchPlantSubContractDetail = (plantID, orderID) => {
    const dummyData = [
      {
        "subcontract_id": 1,
        "subcontract_number": "RD2403261501-AA",
        "subcontract_date": "2024-03-26",
        "subcontract_total_qty": 30.000,
        "subcontract_available_quantity": 20.400,
        "subcontract_item_details": [
          {
            "subcontarct_item_id": 476213553,
            "subcontract_item_name": "REAL 10 GI SWG-10",
            "subcontract_item_alloted_qty": 18.000,
            "subcontract_item_available_quantity": 15.400
          },
          {
            "subcontarct_item_id": 1328401634,
            "subcontract_item_name": "HB SWG - 07",
            "subcontract_item_alloted_qty": 12.000,
            "subcontract_item_available_quantity": 5.000
          }
        ]
      },
      {
        "subcontract_id": 2,
        "subcontract_number": "RD2403124356-AD",
        "subcontract_date": "2024-03-24",
        "subcontract_total_qty": 12,
        "subcontract_available_quantity": 0,
        "subcontract_item_details": []
      }
    ]
    this.setState({ plantSubcontractDetailLoader: true })
    axios.get(`${B2b_URL.GET_PLANT_SUBCONTRACT_DETAIL}?order_id=${orderID}&plant_id=${plantID}&V=${StaticAppVersion()}`,
      this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          let responseData = response.data['SubContract List Stepper-Wise data']
          // let responseData = String(plantID).includes('303055004') ? [] : dummyData
          this.setState({
            plantSubcontractDetail: responseData,
            plantSubcontractDetailLoader: false
          })
        }
      })
      .catch((err) => {
        this.setState({
          plantSubcontractDetailLoader: false,
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  clearOrderDetails = () => {
    this.setState({
      selectedSingleOrder: null,
      singleOrderPlantPlanning: null,
      singleOrderPlannedItem: null,
      singleOrderLA: null,
      singleOrderInvoices: null,
    })
  }
  render() {
    const { isMobile, user } = this.props;
    const {
      snackbarMessage,
      snackbarType,
      openSnackbar,
      orders,
      nextURL,
      ordersListLoader,
      selectedSingleOrder,
      singleOrderPlantPlanning,
      orderLazyLoader,
      singleOrderLoader,
      showOrder,
      isOffline,
      b2CQrImage,
      plantSubcontractDetail,
      plantSubcontractDetailLoader,
    } = this.state
    // console.log(this.state)
    // console.log(this.props)
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/orders"
            render={(props) =>
              isMobile ? (
                <Orders {...props} user={this.props.user}
                  fetchOrderList={this.fetchOrderList}
                  handleSingleOrders={this.handleSingleOrders}
                  fetchSingleOrderPlantPlanning={this.fetchSingleOrderPlantPlanning}
                  fetchSingleOrderLoadingAdvices={this.fetchSingleOrderLoadingAdvices}
                  fetchSingleOrderInvoices={this.fetchSingleOrderInvoices}
                  orders={orders}
                  ordersListLoader={ordersListLoader}
                  nextURL={nextURL}
                  fetchNextOrders={this.fetchNextOrders}
                  orderLazyLoader={orderLazyLoader}
                  singleOrderLoader={singleOrderLoader}
                  handleCloseBackDrop={this.handleCloseBackDrop}
                  showOrder={showOrder}
                  handleContractSelectChip={this.handleContractSelectChip}
                  isOffline={isOffline}
                />
              ) : (
                <OrdersWeb
                  {...props} user={user}
                  fetchOrderList={this.fetchOrderList}
                  handleSingleOrders={this.handleSingleOrders}
                  fetchSingleOrderPlantPlanning={this.fetchSingleOrderPlantPlanning}
                  fetchSingleOrderLoadingAdvices={this.fetchSingleOrderLoadingAdvices}
                  fetchSingleOrderInvoices={this.fetchSingleOrderInvoices}
                  orders={orders}
                  ordersListLoader={ordersListLoader}
                  nextURL={nextURL}
                  fetchNextOrders={this.fetchNextOrders}
                  orderLazyLoader={orderLazyLoader}
                  singleOrderLoader={singleOrderLoader}
                  handleCloseBackDrop={this.handleCloseBackDrop}
                  showOrder={showOrder}
                  handleContractSelectChip={this.handleContractSelectChip}
                  isOffline={isOffline}
                />
              )
            }
          />
          <Route
            exact
            path="/orders/action/:id"
            render={(props) =>
              isMobile ? (
                <ConfirmOrder {...props} {...this.props}
                  handlePendingActionCardClick={this.handlePendingActionCardClick}
                  handleOrderApproveClick={this.handleOrderApproveClick}
                  approveContractLoader={this.state.approveContractLoader}
                  fetchShortCloseReasonMaster={this.fetchShortCloseReasonMaster}
                  reasonMaster={this.state?.reasonMaster}
                />
              ) : (
                <ConfirmOrderWeb {...props} {...this.props}
                  handlePendingActionCardClick={this.handlePendingActionCardClick}
                  handleOrderApproveClick={this.handleOrderApproveClick}
                  approveContractLoader={this.state.approveContractLoader}
                  fetchShortCloseReasonMaster={this.fetchShortCloseReasonMaster}
                  reasonMaster={this.state?.reasonMaster}
                />
              )
            }
          />
          <Route
            exact
            path="/orders/dealer"
            render={(props) =>
              isMobile ? (
                <DealerOrdersMobile {...props} />
              ) : (
                <DealerOrdersWeb {...props} />
              )
            }
          />
          {/* <Route
            exact
            path="/orders/pending/:orderId"
            render={(props) =>
              isMobile ? (
                <PendingOrders {...props} {...this.props}
                  details={selectedSingleOrder}
                  orders={orders}
                  handleSingleOrders={this.handleSingleOrders}
                  fetchOrderList={this.fetchOrderList}

                />
              ) : (
                <PendingOrdersWeb {...props} {...this.props}
                  details={selectedSingleOrder}
                  orders={orders}
                  handleSingleOrders={this.handleSingleOrders}
                  fetchOrderList={this.fetchOrderList}

                />
              )
            }
          /> */}
          {/* <Route
          exact
          path="/orders/action/:orderId"
          render={(props) =>
            isMobile ? (
              <SingleOrder {...props} {...this.props}

              />
            ) : (
              <SingleOrderWeb {...props} {...this.props}

              />
            )
          }
        /> */}
          <Route
            exact
            path={["/orders/active/:orderId", "/orders/pending/:orderId"]}
            render={(props) =>
              isMobile ? (
                <SingleOrderDetails {...props}
                  {...this.props}
                  {...this.state}
                  details={selectedSingleOrder}
                  fetchSingleOrderPlantPlanning={this.fetchSingleOrderPlantPlanning}
                  singleOrderPlantPlanning={singleOrderPlantPlanning}
                  singleOrderLA={this.state.singleOrderLA}
                  fetchLADetails={this.fetchLADetails}
                  fetchSingleOrderLoadingAdvices={this.fetchSingleOrderLoadingAdvices}
                  fetchSingleOrderInvoices={this.fetchSingleOrderInvoices}
                  singleOrderInvoices={this.state.singleOrderInvoices}
                  handleSingleOrders={this.handleSingleOrders}
                  plantPlanningLoader={this.state.plantPlanningLoader}
                  laLoader={this.state.laLoader}
                  invoiceLoader={this.state.invoiceLoader}
                  clearOrderDetails={this.clearOrderDetails}
                  fetchOrderList={this.fetchOrderList}
                  orders={this.state.orders}
                  totalLAQuantity={this.state.totalLAQuantity}
                  handleInvoiceClick={this.handleInvoiceClick}
                  b2CQrImage={b2CQrImage}
                  handlePostInvoiceDownload={this.handlePostInvoiceDownload}
                  handleOpenSnackBar={this.handleOpenSnackBar}
                  isOffline={isOffline}
                  fetchShortCloseReasonMaster={this.fetchShortCloseReasonMaster}
                  reasonMaster={this.state?.reasonMaster}
                  handleOrderApproveClick={this.handleOrderApproveClick}
                  fetchPlantSubContractDetail={this.fetchPlantSubContractDetail}
                  plantSubcontractDetail={plantSubcontractDetail}
                  plantSubcontractDetailLoader={plantSubcontractDetailLoader}
                />
              ) : (
                <SingleOrderDetailsWeb {...props}
                  {...this.props}
                  {...this.state}
                  details={selectedSingleOrder}
                  fetchSingleOrderPlantPlanning={this.fetchSingleOrderPlantPlanning}
                  singleOrderPlantPlanning={singleOrderPlantPlanning}
                  singleOrderLA={this.state.singleOrderLA}
                  fetchLADetails={this.fetchLADetails}
                  fetchSingleOrderLoadingAdvices={this.fetchSingleOrderLoadingAdvices}
                  fetchSingleOrderInvoices={this.fetchSingleOrderInvoices}
                  singleOrderInvoices={this.state.singleOrderInvoices}
                  handleSingleOrders={this.handleSingleOrders}
                  plantPlanningLoader={this.state.plantPlanningLoader}
                  laLoader={this.state.laLoader}
                  invoiceLoader={this.state.invoiceLoader}
                  clearOrderDetails={this.clearOrderDetails}
                  fetchOrderList={this.fetchOrderList}
                  orders={this.state.orders}
                  totalLAQuantity={this.state.totalLAQuantity}
                  handleInvoiceClick={this.handleInvoiceClick}
                  b2CQrImage={b2CQrImage}
                  handlePostInvoiceDownload={this.handlePostInvoiceDownload}
                  handleOpenSnackBar={this.handleOpenSnackBar}
                  fetchShortCloseReasonMaster={this.fetchShortCloseReasonMaster}
                  reasonMaster={this.state?.reasonMaster}
                  handleOrderApproveClick={this.handleOrderApproveClick}
                  fetchPlantSubContractDetail={this.fetchPlantSubContractDetail}
                  plantSubcontractDetail={plantSubcontractDetail}
                  plantSubcontractDetailLoader={plantSubcontractDetailLoader}
                />
              )
            }
          />
          <Route
            exact
            path="/orders/cancelled/:orderId"
            render={(props) =>
              isMobile ? (
                <CanceledOrder {...props} {...this.props}
                  details={this.state.selectedSingleOrder}
                  orders={this.state.orders}
                  handleSingleOrders={this.handleSingleOrders}
                  fetchOrderList={this.fetchOrderList}
                  handleInvoiceClick={this.handleInvoiceClick}
                  b2CQrImage={b2CQrImage}
                  handlePostInvoiceDownload={this.handlePostInvoiceDownload}

                />
              ) : (
                <CanceledOrderWeb {...props} {...this.props}
                  details={this.state.selectedSingleOrder}
                  orders={this.state.orders}
                  handleSingleOrders={this.handleSingleOrders}
                  fetchOrderList={this.fetchOrderList}
                  handleInvoiceClick={this.handleInvoiceClick}
                  b2CQrImage={b2CQrImage}
                  handlePostInvoiceDownload={this.handlePostInvoiceDownload}

                />
              )
            }
          />
          <Route
            exact
            path="/orders/orderDetails/:orderId/addRequirement"
            render={(props) =>
              isMobile ? (
                <AddRequirements {...props} />
              ) : (
                <AddRequirementsWeb {...props} />
              )
            }
          />
          <Route
            exact
            path="/orders/terms-and-conditions"
            render={(props) => (
              <TermsAndConditions {...this.props} {...this.state} />
            )}
          />

          {/* <Route path='*'>
            <Nomatch />
          </Route> */}
        </Switch>
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
        <Snackbar
          open={isOffline}
          onClose={this.handleClose}
          autoHideDuration={4000}
          TransitionComponent={"up"}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          message={<span id="message-id">No Internet Connection</span>}
        />
      </div>
    );
  }
}
export default withUser(withRouter(B2bOrdersContainer));
