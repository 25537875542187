import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import Details from "../Details";
import DetailsOld from "../DetailsOld";

class DispatchCounterDetails extends Component {
  render() {
    return (
      <Layout {...this.props}>
        {this.props.laDetailsInDispatchCounter?.loading_advice_items?.length >
          this.props.laDetailsInDispatchCounter?.weigh_slips?.length - 1 ? (
          <DetailsOld {...this.props} />
        ) : (
          <Details {...this.props} />
          // <DetailsOld {...this.props} />
        )}
      </Layout>
    );
  }
}
export default withRouter(DispatchCounterDetails);
