import React, { Component } from 'react';
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import {
  Typography,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  TablePagination,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  secondaryBlueText,
  primaryColor,
  drawerTextColor,
  whiteSmoke,
  grey,
} from '../../../../constants/internal/colors';
import AccessAreaManagementDisplay from '../web/AccessAreaManagementDisplay';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import { pxToEm, hasWriteAccess } from '../../../../methods';
import '../../../../styles/internal/scroll.css';

const userAndPermisionDetailsStyles = (theme) => ({
  titleWithBlueFont: {
    fontSize: pxToEm(18),
    color: secondaryBlueText,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
  },
  table: {
    minWidth: 650,
    border: '0px',
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: 'bold',
    color: grey,
  },
  productFont: { fontSize: pxToEm(13), },
  dataFont: {
    fontSize: pxToEm(13),
    color: grey,
  },
  alignText: {
    display: 'flex',
    placeContent: 'flex-end',
  },
  rightSpacing: {
    marginRight: 10,
  },
  arrowDimension: {
    height: 18,
    width: 18,
    margin: '0px 5px 0px 5px',
  },
  addNewButtonAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20,
  },
  displayData: {
    marginTop: 10,
  },
  searchBox: {
    minWidth: 300,
  },
  mainDiv: {
    width: '100%',
    height: '100%',
    paddingBottom: '30px',
    overflowY: 'scroll',
  },
  rightPadding: { paddingRight: '25px' },
  container: {
    width: '100%',
    height: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  inactiveChipStyle: {
    padding: 3,
    alignItems: "center",
    fontSize: pxToEm(10),
    backgroundColor: "red",
    color: drawerTextColor,
    borderRadius: "45px",
    marginLeft: 10
  },
});
class UserAndPermisionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedData: [],
      data: [],
      dataBackup: [],
      page: 0,
      rowsPerPage: 10,
      searchText: '',
      excludeColumnsFromSearch: [
        'id',
        'created_at',
        'is_active',
        'is_admin',
        'modifiedAt',
      ],
    };
  }

  componentDidMount() {
    const { displaySection } = this.props;
    let data =
      displaySection === 'rolesAndPermissions'
        ? this.props.roles
        : displaySection === 'internalUsers'
          ? this.props.users
          : displaySection === 'accessAreaManagement'
            ? this.props.modules
            : [];
    this.setState({ data, dataBackup: data });
  }

  static getDerivedStateFromProps(props, state) {
    const { displaySection } = props;
    return {
      data:
        displaySection === 'rolesAndPermissions'
          ? props.roles
          : displaySection === 'internalUsers'
            ? props.users
            : displaySection === 'accessAreaManagement'
              ? props.modules
              : [],
      dataBackup:
        displaySection === 'rolesAndPermissions'
          ? props.roles
          : displaySection === 'internalUsers'
            ? props.users
            : displaySection === 'accessAreaManagement'
              ? props.modules
              : [],
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };
  searchFilterChange = (event) => {
    this.setState({ searchText: event.target.value });
    this.setState({ page: 0 });
    const lowercasedValue = event.target.value.toLowerCase().trim();
    let allData = (this.state.data && this.state.data.length > 0) ? this.state.data : [];
    if (lowercasedValue !== '') {
      // console.log(this.state.searchText, allData)
      const filteredData = allData.filter((item) => {
        return Object.keys(item).some((key) =>
          this.state.excludeColumnsFromSearch.includes(key)
            ? false
            : item[key] !== null &&
              typeof (event.target.value) === typeof ('')
              ? item[key]?.toString().toLowerCase().trim().includes(lowercasedValue)
              : typeof (event.target.value) === typeof (9)
                ? item[key]?.toString().includes((event.target.value).toString())
                : item[key]?.toString().includes((event.target.value).toString())
        );
      });
      this.setState({ searchedData: filteredData });
    } else {
      this.setState({ searchedData: this.state.dataBackup });
    }
  };
  render() {
    const { classes, displaySection, isMobile } = this.props;
    let { data } = this.state;
    data = this.state.searchedData.length > 0 ? this.state.searchedData : data;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv} id="scroll">
          <div className={classes.rightPadding}>
            <Typography className={classes.fontStyles}>
              {
                <span
                  className={classes.titleWithBlueFont}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.goBack();
                  }}
                >
                  {`Users And Permissions > `}
                </span>
              }
              {displaySection === 'rolesAndPermissions'
                ? 'Roles And Permissions'
                : displaySection === 'internalUsers'
                  ? 'Internal Users'
                  : displaySection === 'accessAreaManagement'
                    ? 'Access Area Management'
                    : null}
            </Typography>
            {displaySection === 'accessAreaManagement' ? (
              <AccessAreaManagementDisplay modules={data} />
            ) : (
              <div className={classes.displayData} id="scroll">
                <div className={classes.addNewButtonAlign}
                  style={{
                    flexDirection: isMobile && 'column',
                    alignItems: isMobile && 'flex-start',
                  }}>
                  <TextField
                    className={classes.searchBox}
                    style={{
                      marginBottom: isMobile && 10
                    }}
                    label={
                      displaySection === 'rolesAndPermissions'
                        ? 'Search Roles And Permissions'
                        : 'Search Internal Users'
                    }
                    autoComplete="off"
                    value={this.state.searchText}
                    variant="outlined"
                    size="small"
                    onChange={this.searchFilterChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    className={classes.primaryBackground}
                    variant="contained"
                    startIcon={<AddOutlinedIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        this.props.displaySection === 'rolesAndPermissions'
                          ? '/internalUsersAndPermissions/rolesAndPermissions/new'
                          : '/internalUsersAndPermissions/internalUsers/new'
                      );
                    }}
                    disabled={this.props.displaySection === 'rolesAndPermissions' ? hasWriteAccess(this.props.user, 'rolesAndPermissions') ? false : true : hasWriteAccess(this.props.user, 'internalUsers') ? false : true}
                  >
                    ADD NEW
                  </Button>
                </div>
                <Paper>
                  <TableContainer className={classes.container} id="scroll">
                    <Table
                      stickyHeader
                      className={classes.table}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow className={classes.tableHeader}>
                          <TableCell align="left" className={classes.tableHead}>
                            {this.props.displaySection === 'rolesAndPermissions'
                              ? 'ROLE'
                              : 'NAME'}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableHead}
                          >
                            {this.props.displaySection === 'rolesAndPermissions'
                              ? 'ASSIGNED TO'
                              : 'EMAIL'}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableHead}
                          >
                            {this.props.displaySection === 'internalUsers'
                              ? 'ROLE'
                              : ''}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(this.state.rowsPerPage > 0
                          ? data?.slice(
                            Number(this.state.page) *
                            Number(this.state.rowsPerPage),
                            Number(this.state.page) *
                            Number(this.state.rowsPerPage) +
                            Number(this.state.rowsPerPage)
                          )
                          : data
                        )?.map((displayData, index) => (
                          <TableRow
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.props.displaySection ===
                                'rolesAndPermissions') {
                                if (hasWriteAccess(this.props.user, 'rolesAndPermissions')) {
                                  this.props.history.push(
                                    this.props.displaySection ===
                                      'rolesAndPermissions'
                                      ? `/internalUsersAndPermissions/rolesAndPermissions/view/${displayData.id}`
                                      : `/internalUsersAndPermissions/internalUsers/view/${displayData.id}`
                                  );
                                }
                              }
                              else {
                                if (hasWriteAccess(this.props.user, 'internalUsers')) {
                                  this.props.history.push(
                                    this.props.displaySection ===
                                      'rolesAndPermissions'
                                      ? `/internalUsersAndPermissions/rolesAndPermissions/view/${displayData.id}`
                                      : `/internalUsersAndPermissions/internalUsers/view/${displayData.id}`
                                  );
                                }
                              }
                            }
                            }

                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.productFont}
                              align="left"
                            >
                              {this.props.displaySection === 'internalUsers'
                                ? <span style={{ paddingTop: 10 }}>{displayData.firstName + " " + displayData.lastName}</span>
                                : displayData.name}
                              {this.props.displaySection === 'internalUsers' && displayData.is_active == false
                                ? <span className={classes.inactiveChipStyle}>inactive</span>
                                : null
                              }
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.dataFont}
                            >
                              {this.props.displaySection ===
                                'rolesAndPermissions'
                                ? displayData.assignedToCounts + ' User'
                                : displayData.email}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.dataFont}
                            >
                              <div className={classes.alignText}>
                                <div className={classes.rightSpacing}>
                                  {displayData?.roles?.map((e) => `${e}, `)}
                                </div>
                                <div>
                                  {this.props.displaySection ===
                                    'rolesAndPermissions' ? (
                                    <ArrowForwardIosOutlinedIcon
                                      className={classes.arrowDimension}
                                    />
                                  ) : (
                                    <EditOutlinedIcon
                                      className={classes.arrowDimension}
                                    />
                                  )}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    SelectProps={{
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={(e) => {
                      e.preventDefault();
                      this.handleChangeRowsPerPage(e);
                    }}
                  />
                </Paper>
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}
export default withStyles(userAndPermisionDetailsStyles)(
  UserAndPermisionDetails
);
