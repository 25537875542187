import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Bread from "../common/Breadcrubs";
import { withRouter } from "react-router";
import {
  darkGrey,
  primaryColor,
  grey,
  secondaryBlueText,
} from "../../../constants/internal/colors";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";

import { isAccessibleWidget } from "../../../methods";
import { Divider } from "@material-ui/core";

const dashboardStyles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "20px",
  },
  EInvoiceContainer: {
    width: "100%",
    // padding: "30px",
    padding: "0 30px 30px 0"
  },
  plantNameText: {
    color: darkGrey,
    margin: "8px 0px",
    fontSize: "1.2em",
  },
  tabLabelContainer: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.3em",
  },
  tabLabelSecondaryText: {
    fontSize: "0.9em",
    color: grey,
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "30px",
    width: "100%",
    marginBottom: 10,
  },
  headerData: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
});

class EInvoicing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      plants: [
        {
          name: "Nova",
          code: "NV",
          EInvUserName: "AAACN0407F_API_MAH",
          EInvPassword: "Nisl@1234567",
          companyName: "Nova Iron And Steel Limited",
          bankName: "ICICI BANK",
          accountNumber: "016105009326",
          ifscCode: "ICIC0000161",
          upiId: "nova.iron@icici",
        },
        {
          name: "Borjhara",
          code: "RB",
          EInvUserName: "realispat1_API_MAH",
          EInvPassword: "Realispat1234@",
          companyName: "Real Ispat & Power Limited",
          bankName: "State Bank of India",
          accountNumber: "30011973134",
          ifscCode: "SBIN0008536",
          upiId: "realispat@sbi", // realispatandpowerltd@sbi, MAB0450047A0000178@Yesbank
        },
        {
          name: "Urla Plant",
          code: "RU",
          EInvUserName: "realispat1_API_MAH",
          EInvPassword: "Realispat1234@",
          companyName: "Real Ispat & Power Limited",
          bankName: "State Bank of India",
          accountNumber: "30011973134",
          ifscCode: "SBIN0008536",
          upiId: "realispat@sbi", // MAB0450047A0000178@Yesbank
        },
        {
          name: "Shivalay",
          code: "SH",
          EInvUserName: "SHIVALAYIS_API_MAH",
          EInvPassword: "Ispat@202012",
          companyName: "Shivalay Ispat & Power Private Limited",
          bankName: "State Bank of India",
          accountNumber: "37993289611",
          ifscCode: "SBIN0008536",
          upiId: "sippl@sbi", // shivalayispatandpower@sbi
        },
        {
          name: "API Ispat",
          code: "AP",
          EInvUserName: "apigstn123_API_MAH",
          EInvPassword: "Sudhir#19923",
          companyName: "API Ispat & Power Private Limited",
          bankName: "State Bank of India",
          accountNumber: "35297672702",
          ifscCode: "SBIN0008536",
          upiId: "apiipl@sbi", // apiispatandpower@sbi, MAB0450047A0000175@Yesbank
        },
      ],
    };
  }

  handleTabChange = (e, value) => {
    this.setState({ tabValue: value });
  };

  render() {
    const { classes, plantName, plantCode, allPlants } = this.props;
    const { tabValue, plants } = this.state;
    return (
      <div className={classes.EInvoiceContainer}>
        {/* <div>
          <Bread
            data={[
              {
                title: "E-invoicing",
                onClick: () =>
                  this.props.history.push("/internalEInvoicingAndEWayBilling"),
              },
              {
                title: "Plants",
                onClick: () =>
                  this.props.history.push("/internalEInvoicingAndEWayBilling"),
              },
            ]}
          /> */}
        <div className={classes.navigationBar}>
          <Bread
            data={[
              {
                title: "E-Invoicing",
              },
            ]}
          />
          {/* <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />
          <div className={classes.headerData}>
            <span>Plant</span>
          </div> */}
          <Divider
            light={true}
            orientation="vertical"
            flexItem
            style={{
              display: plantName ? 'block' : 'none',
              width: 0.11 + "em",
              marginLeft: "10px"
            }}
          />
          <div className={classes.headerData}>
            <span>{plantName} | {plantCode}</span>
          </div>
          {/* <p className={classes.plantNameText}>
            {plantName} {plantCode}
          </p> */}
        </div>
        <Paper
          className={classes.paper}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "80%" }}>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    borderBottom: `2px solid ${primaryColor}`,
                    colur: grey,
                  },
                }}
                value={tabValue}
                onChange={this.handleTabChange}
                className={classes.tabsStyles}
                variant="scrollable"
              >
                {allPlants.map((data) => {
                  if (isAccessibleWidget(this.props.user, data.plant_code))
                    return (
                      <Tab
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.handleSetPlantNameAndPlantCode(data);
                        }}
                        style={{ width: "16%" }}
                        label={
                          <div className={classes.tabLabelContainer}>
                            <span>{data.plant_short_name}</span>
                          </div>
                        }
                      />
                    );
                  else return null;
                })}
              </Tabs>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}></div>
          </div>

          {plantCode && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                paddingRight: "34px",
              }}
            >
              <Button
                disabled={!plantCode}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: secondaryBlueText,
                  marginRight: "3%",
                }}
                onClick={(e) => {
                  this.props.history.push(
                    "internalEInvoicingAndEWayBilling/create/P"
                  );
                }}
              >
                SALES RETURN
              </Button>
              <Button
                disabled={!plantCode}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: secondaryBlueText,
                  marginRight: "3%",
                }}
                onClick={(e) => {
                  this.props.history.push(
                    "internalEInvoicingAndEWayBilling/create/M"
                  );
                }}
              >
                CREDIT/DEBIT NOTES
              </Button>
              <Button
                disabled={!plantCode}
                variant="contained"
                color="primary"
                style={{ backgroundColor: secondaryBlueText }}
                onClick={(e) => {
                  this.props.history.push(
                    "internalEInvoicingAndEWayBilling/create/S"
                  );
                }}
              >
                INVOICE
              </Button>
            </div>
          )}
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(dashboardStyles)(EInvoicing));
