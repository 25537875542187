import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Pending from "../Pending";
import { withStyles } from "@material-ui/core/styles";

const styles = {};
class PendingOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar orderSelect>
        <Pending {...this.props} />
      </WebLayout>
    );
  }
}
export default withStyles(styles)(PendingOrders);
