import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import IconButton from '@mui/material/IconButton';
import { checkInvalidValue } from "../../methods";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiAlert-icon": {
      padding: JSON.parse(localStorage.getItem("user"))?.is_admin ? "15px 0px" : "7px 0px"
    }
  },

}));

export default function SnackbarComponent({
  open,
  handleClose,
  snackbarMessage,
  type,
  customAutoHideDuration = 5000,
}) {
  const UserDetail = localStorage.getItem("user")
  const userDet = JSON.parse(UserDetail);
  const classes = useStyles();
  // const handleCopy = (snackbarMessage) => {
  //   console.log(snackbarMessage)
  //   navigator.clipboard.writeText(snackbarMessage);
  // };


  const handleCopy = (data) => {
    let messageToCopy = "";
    console.log("1", data, typeof data, typeof data === typeof {}, Array.isArray(data))
    // Check if the snackbar message is an object, array, or string
    if (typeof data === typeof {}) {
      // messageToCopy = JSON.stringify(data);
      messageToCopy = checkInvalidValue(data[0].props.children.props) ? data[0].props.children : data[0].props.children.props.children
      console.log("O", messageToCopy,)
    } else if (Array.isArray(data)) {
      messageToCopy = data.toString();
      console.log("A", messageToCopy)
    } else {
      messageToCopy = data;
    }

    navigator.clipboard.writeText(messageToCopy);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={customAutoHideDuration}
        onClose={handleClose}

      >
        <Alert onClose={handleClose} severity={type}>
          {snackbarMessage}
          <IconButton
            color="inherit"
            onClick={() => handleCopy(snackbarMessage)}
            aria-label="Copy"
            style={{ fontSize: "small", display: !userDet?.is_admin && "none" }}
          >
            <ContentCopyOutlined />
          </IconButton>
        </Alert>
      </Snackbar>
    </div>
  );
}
