import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import OrderCard from "./OrderCard";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import { secondaryColor, primaryColor } from "../../../styles/colors";
import Divider from "@material-ui/core/Divider";
import PlantPlanning from "../common/PlantPlanning";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import DispatchList from "../dispatch/DispatchList";
import LAList from "./LAList";
import InvoiceList from "./InvoiceList";
import SpinLoader from "../../common/SpinLoader";
import { BorderBottom } from "@material-ui/icons";
import SingleContractOverview from "./SingleContractOverview";
import SnackbarComponent from "../../common/SnackbarComponent";
import backStack from "cordova-back-stack";
import SwipeComponent from "../common/SwipeComponent";
import TileSkeleton from "../common/TileSkeleton";
import { pxToEm } from "../../../methods";
import DispatchLAList from "../common/DispatchLAList";
import ScrollTop from "../common/ScrollTop";
// import Lottie from 'react-lottie';
// import GreenCheck from '../../../styles/b2b/Animations/GreenCheck'

const styles = {
  navigationTabContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  navigationTab: {
    width: "89%",
    height: "56px",
    margin: "20px 0px 20px 0px",
    display: "flex",
    alignItems: "center",
  },
  myOrderCircleStyle: {
    width: "8px",
    background: "black",
    height: "8px",
    borderRadius: "50%",
    marginLeft: "10px",
    marginTop: "-8px",
  },
  buttonStyle: {
    textTransform: "capitalize",
    fontSize: "1.2em",
    marginRight: "10px",
    borderRadius: "0px",
  },
  orderDescriptionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  orderDescription: {
    width: "89%",
    // padding: "10px 0px 10px 4%"
  },
  addRequirements: {
    height: "36px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    color: secondaryColor,
    marginBottom: "15px",
  },
  fullDialogAppBarContainer: {
    height: "10vh",
    display: "flex",
    alignItems: "center",
    padding: "0px 30px",
  },
  buyerNameContainer: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  laLoader: (isMobile) => ({
    width: isMobile ? "100%" : '65%',
    paddingLeft: !isMobile && '5%',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center'
  }),
  buyerNameTextStyle: {
    display: "flex",
    flexDirection: "column",
  },
  buyerGstContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 0px",
  },
  loadingAdviceContainer: (isMobile) => ({
    width: isMobile ? "100%" : "65vw",
    paddingLeft: isMobile ? "0px" : "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: isMobile ? 110 : 'inherit'
  }),
  invoiceListContainerStyle: (isMobile) => ({
    width: isMobile ? "100%" : "65vw",
    paddingLeft: isMobile ? "0px" : "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  not: (isMobile) => ({
    height: isMobile ? "100%" : "210px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  addDispatchFaviconContainer: (isMobile) => ({
    position: "fixed",
    bottom: isMobile ? "42px" : "20px",
    height: "70px",
    width: isMobile ? "30%" : "10%",
    // backgroundColor: "green",
    right: "0px",
    display: "flex",
    justifyContent: "center",
    paddingRight: isMobile ? "0px" : "0px",
    marginLeft: isMobile ? "0%" : "7%",
  }),

};

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overview: true,
      loadingAdvice: false,
      invoice: false,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
      cancelButtonClick: false,
    };
    this.addNewEndRef = React.createRef(null)
  }

  UNSAFE_componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      if ((String(localStorage.getItem("currentUrl"))).includes('orders/active')) {
        localStorage.setItem("returnUrl", "/orders")
        this.props.history.push("/orders");
      }
      else {
        this.props.history.push(String(localStorage.getItem("returnUrl")) !== '/home' && String(localStorage.getItem("returnUrl")));
      }
    });
  }

  componentDidMount() {
    // console.log("here")
    let orderId = this.props.match.url.split("/")
    // console.log(orderId[3])
    if (!this.props.details) {
      this.props.handleSingleOrders(null, orderId[3])
    }
    localStorage.setItem("prevUrl", 'NaN')
  }

  componentDidUpdate() {
    if (window.location.pathname !== String(localStorage.getItem("returnUrl"))) {
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
    else {
      let returnUrl = String(window.location.pathname).slice(0, String(window.location.pathname).indexOf('/active'));
      localStorage.setItem("returnUrl", returnUrl)
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
    document.addEventListener("backbutton", this.loadOldUrl, false);
  }
  loadOldUrl = () => {
    this.props.history.push(String(localStorage.getItem("returnUrl")) !== '/home' && String(localStorage.getItem("returnUrl")))
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleCancelButtonClick = (clicked) => {
    this.setState({ cancelButtonClick: clicked })
  }

  handleScreenSlide = (screenDir) => {
    if (!this.state?.cancelButtonClick) {
      if (screenDir === 'right') {
        if (this.state?.overview) {
          this.setState({ overview: false, loadingAdvice: true, invoice: false, })
        }
        else if (this.state?.loadingAdvice) {
          this.setState({ overview: false, loadingAdvice: false, invoice: true, })
        }
        else {
          return ''
        }
      }
      else {
        if (this.state?.overview) {
          this.props.history.push("/orders")
        }
        else if (this.state?.loadingAdvice) {
          this.setState({ overview: true, loadingAdvice: false, invoice: false, })
        }
        else {
          this.setState({ overview: false, loadingAdvice: true, invoice: false, })
        }
      }
    }
    else {
      this.handleCancelButtonClick(false)
    }
  }
  handleSingleOrders = (order, laNumber) => {
    if (order.order_status === "PENDING") {
      this.props.history.push(`/dispatch/pending/${order.id}`);
    } else if (order.order_status === "action") {
      this.props.history.push(`/dispatch/action/${order.id}`);
    } else if (order.order_status === "cancelled") {
      this.props.history.push(`/dispatch/cancel/${order.id}`);
    } else {
      this.props.fetchLADetails(laNumber)
        .then(response => {
          // console.log(response)
          let LADetails = response.data[0]
          // console.log(LADetails)
          let logDetails = LADetails.log_details
          let logs = []
          // let statuses =
          Object.keys(logDetails).map(key => {
            // console.log(key, logDetails[key])
            logs.push({ [key]: logDetails[key] })
            // statuses.push(key)
          })
          let LADetailsrev = logs.reverse()
          this.props.history
            .push({
              pathname: `/dispatch/singleDispatch/${order.id}`,
              state: { order, LADetails, LADetailsrev }
            });

        })
        .catch(err => console.log(err))
    }
  }

  handleCancelDisableButton = (reason) => {
    if (reason) {
      this.handleOpenSnackbar(reason, "warning")
      setTimeout(() => {
        this.handleCloseSnackbar()
      }, 3000)
    }
  }

  render() {
    const { overview, loadingAdvice, invoice, dispatch, invoices, openSnackbar, snackbarMessage, snackbarType } = this.state;
    const { classes, details, singleOrderPlantPlanning, singleOrderLA,
      singleOrderInvoices, isMobile, plantPlanningLoader, laLoader,
      invoiceLoader, fetchPlantSubContractDetail, plantSubcontractDetail, plantSubcontractDetailLoader,
    } = this.props;
    console.log(this.props)

    return (
      <div>
        <ScrollTop type="RefDiv" ref={this.addNewEndRef} />
        {
          details ?
            <div>
              {!this.props.isMobile ? (
                <div style={{ padding: "10px 0px 10px 4%" }}>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.props?.location?.state?.message)
                        this.props.history.push("/home")
                      else
                        if (this.props.history.length > 2)
                          this.props.history.goBack();
                        else
                          this.props.history.push("/orders")
                      this.props.clearOrderDetails()
                    }}
                  >
                    <KeyboardBackspace />
                  </IconButton>
                </div>
              ) : null}
              {
                details &&
                <OrderCard
                  title={
                    details.order_status === "COMPLETED" ? "Completed" :
                      details.order_status === "APPROVED" ? "Approved" :
                        details.order_status === "ONGOING" ? "Ongoing" :
                          details.order_status
                  } details={details}
                  isMobile={isMobile}
                />
              }

              <div className={classes.navigationTabContainer}>
                <div className={classes.navigationTab}>
                  <div>
                    <Button
                      className={classes.buttonStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          overview: true,
                          loadingAdvice: false,
                          invoice: false,
                        });
                        this.props.fetchSingleOrderPlantPlanning(details)
                      }}
                      style={{
                        fontSize: window.innerWidth < 310
                          ? pxToEm(11.8)
                          : window.innerWidth < 375
                            ? pxToEm(13)
                            : window.innerWidth < 400
                              ? "0.9em"
                              : "1em",
                        borderBottom: overview ? `2px solid ${secondaryColor}` : null,
                        minWidth: 'max-content'
                      }}
                    >
                      Overview
                    </Button>
                    {/* {overview ? <div className={classes.myOrderCircleStyle} /> : null} */}
                  </div>
                  <div>
                    <Button
                      className={classes.buttonStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          overview: false,
                          loadingAdvice: true,
                          invoice: false,
                        })
                        this.props.fetchSingleOrderLoadingAdvices(details)
                      }}
                      style={{
                        fontSize: window.innerWidth < 310
                          ? pxToEm(11.8)
                          : window.innerWidth < 375
                            ? pxToEm(13)
                            : window.innerWidth < 400
                              ? "0.9em"
                              : "1em",
                        borderBottom: loadingAdvice ? `2px solid ${secondaryColor}` : null,
                        minWidth: 'max-content'
                      }}
                    >
                      Loading Advices
                    </Button>
                    {/* {loadingAdvice ? (
                      <div className={classes.myOrderCircleStyle} />
                    ) : null} */}
                  </div>
                  <div>
                    <Button
                      className={classes.buttonStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          overview: false,
                          loadingAdvice: false,
                          invoice: true,
                        });
                        this.props.fetchSingleOrderInvoices(details)
                      }}
                      style={{
                        fontSize: window.innerWidth < 310
                          ? pxToEm(11.8)
                          : window.innerWidth < 375
                            ? pxToEm(13)
                            : window.innerWidth < 400
                              ? "0.9em"
                              : "1em",
                        borderBottom: invoice ? `2px solid ${secondaryColor}` : null,
                        minWidth: 'max-content'
                      }}
                    >
                      Invoices
                    </Button>
                    {/* {invoice ? <div className={classes.myOrderCircleStyle} /> : null} */}
                  </div>
                </div>
              </div>
              <SwipeComponent
                rightScreen={() => this.handleScreenSlide('right')}
                leftScreen={() => this.handleScreenSlide('left')}
              >
                {overview && !loadingAdvice && !invoice ? (
                  <div className={classes.orderDescriptionContainer}>
                    <div className={classes.orderDescription}>
                      <PlantPlanning
                        singleOrderPlantPlanning={singleOrderPlantPlanning}
                        plantPlanningLoader={plantPlanningLoader}
                        isMobile={isMobile}
                        orderDetails={details}
                        fetchPlantSubContractDetail={fetchPlantSubContractDetail}
                        plantSubcontractDetail={plantSubcontractDetail}
                        plantSubcontractDetailLoader={plantSubcontractDetailLoader}
                      />
                      <SingleContractOverview
                        orderDetails={details}
                        user={this.props.user}
                        isMobile={isMobile}
                        fetchShortCloseReasonMaster={this.props.fetchShortCloseReasonMaster}
                        reasonMaster={this.props?.reasonMaster}
                        handleOrderApproveClick={this.props.handleOrderApproveClick}
                        handleCancelButtonClick={this.handleCancelButtonClick}
                        handleCancelDisableButton={this.handleCancelDisableButton}
                      />
                    </div>
                  </div>
                ) : null}
                {
                  laLoader && loadingAdvice ?
                    <div style={styles.laLoader(isMobile)}>
                      <TileSkeleton skeletonType='dispatch' tileLength={3} />
                    </div> :
                    loadingAdvice && !overview && !invoice && !laLoader ? (
                      <div style={styles.loadingAdviceContainer(this.props.isMobile)}>
                        {singleOrderLA && singleOrderLA.length > 0 ?
                          singleOrderLA.map((data) => (
                            <DispatchLAList
                              key={data.id}
                              status={data.dispatch_status}
                              laNumber={data.la_number}
                              itemCategory={data.item_category}
                              quantity={data.quantity}
                              // plant={data.plant.plant_short_name}
                              plant={data.plant?.plant_short_name}
                              truckNumber={data.truck_number}
                              createdDate={data.created_at}
                              loadingAdvice={data}
                              fetchLADetails={this.props.fetchLADetails}
                              module="orderLA"
                              handleSingleOrders={this.handleSingleOrders}
                            />
                          )) :
                          <span style={{
                            marginTop: 30,
                            textAlign: "center",
                            width: '100%',
                            height: '100vw'
                          }}>
                            No Loading advices available
                          </span>
                        }
                      </div>
                    ) : null}
                {
                  invoiceLoader && invoice ?
                    <div style={styles.laLoader(isMobile)}>
                      <TileSkeleton skeletonType='invoices' tileLength={3} />
                    </div> :
                    invoice && !loadingAdvice && !overview ? (
                      <div style={styles.invoiceListContainerStyle(this.props.isMobile)}>
                        {singleOrderInvoices && singleOrderInvoices.length > 0 ?
                          // singleOrderInvoices.map((data) => (
                          <InvoiceList
                            {...this.props}
                            singleOrderInvoices={singleOrderInvoices}
                            // invoiceData={data}
                            // invoiceId={data.doc_no}
                            // invoiceDate={data.created_at}
                            // dueDate={data.eway_valid_upto}
                            // itemCategory={data.item_category}
                            // quantity={data.quantity}
                            // laNumber={data.la_number}
                            handleInvoiceClick={this.props.handleInvoiceClick}
                            b2CQrImage={this.props.b2CQrImage}
                            handlePostInvoiceDownload={this.props.handlePostInvoiceDownload}
                            handleOpenSnackBar={this.props.handleOpenSnackBar}
                          />
                          // )
                          // )
                          : <span
                            style={{
                              marginTop: 30,
                              textAlign: "center",
                              width: '100%',
                              height: '100vw'
                            }}
                          >
                            No Invoices available
                            {/* {console.log(window)} */}
                          </span>
                        }
                      </div>
                    ) : null}
              </SwipeComponent>
            </div>
            : <div style={styles.not(isMobile)}>
              <SpinLoader />
            </div>
        }
        {
          (loadingAdvice && !overview && !invoice && !laLoader)
            ?
            <div style={styles.addDispatchFaviconContainer(this.props.isMobile)}>
              <Tooltip title="Create Loading Advice" placement="top" arrow>
                <Fab
                  aria-label="Add"
                  style={{
                    backgroundColor: primaryColor,
                    color: '#fff',
                    display: this.props?.user?.customer_type === 'DEALER' && 'none'
                  }}
                  // disabled={
                  //   ![
                  //     "APPROVED",
                  //     "ONGOING"
                  //   ].includes(details.order_status)
                  // }
                  onClick={(e) => {
                    e.preventDefault();
                    if (["PENDING", "HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED", "CONVERSION_ALLOTMENT_HOLD"].includes(details?.order_status)) {
                      this.handleOpenSnackbar(
                        `Contract status is ${details?.order_status === "CONVERSION_ALLOTMENT_HOLD"
                          ? "HOLD"
                          : details?.order_status
                        }`, "warning")
                      setTimeout(() => {
                        this.handleCloseSnackbar()
                      }, 3000)
                    }
                    else if ((new Date(details?.order_validity) < new Date())) {
                      this.handleOpenSnackbar(`Contract validity expired`, "warning")
                      setTimeout(() => {
                        this.handleCloseSnackbar()
                      }, 3000)
                    }
                    else if (singleOrderPlantPlanning && singleOrderPlantPlanning.length > 0) {
                      if ((singleOrderPlantPlanning?.filter(d => d.order_available_quantity > 0)).length == 0) {
                        this.handleOpenSnackbar(`There is no open qty`, "warning")
                        setTimeout(() => {
                          this.handleCloseSnackbar()
                        }, 3000)
                      }
                      else {
                        this.props.history.push({ pathname: "/dispatch/create", state: { type: "from order", order: details } })
                      }
                    }
                    else {
                      this.props.history.push({ pathname: "/dispatch/create", state: { type: "from order", order: details } })
                    }
                  }}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div> : null
        }
        <ScrollTop type="Button" ref={this.addNewEndRef} bottom="5px" />
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </div>

    );
  }
}
export default withRouter(withStyles(styles)(Details));
