import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "../../../constants/internal/colors";

const styles = {
  gstDetailsContainer: {
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
  },
  titleTestStyle: {
    width: "40%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  rightSideTextValue: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  totalTextInvoiceText: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",

  },
  totalTextInvoiceTextValue: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
class GstDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, laDetailsInDispatchCounter } = this.props;

    return (
      <div className={classes.gstDetailsContainer}>
        {laDetailsInDispatchCounter && laDetailsInDispatchCounter?.total_values?.igst_amount ? (
          <div style={{ width: "60%", lineHeight: "3em", color: grey }}>
            {Number(laDetailsInDispatchCounter?.freight_charge_amount) === 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>
                  FREIGHT CHARGES(Included)
                </span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    laDetailsInDispatchCounter?.freight_charge_rate}
                  / MT
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.freight_charge_amount}
                </span>
              </div>
            )}

            {laDetailsInDispatchCounter?.total_values?.igst_amount ===
              0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>IGST</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {Number(
                    laDetailsInDispatchCounter?.item_category?.igst
                  ).toFixed(0)}
                  %
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.igst_amount}
                </span>
              </div>
            )}


            {laDetailsInDispatchCounter?.total_values?.cess_amount === 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>CESS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    laDetailsInDispatchCounter?.cess_rate}
                  / MT
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.cess_amount}

                </span>
              </div>
            )}
            {laDetailsInDispatchCounter?.total_values?.tcs_amount ===
              0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>TCS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    laDetailsInDispatchCounter?.tcs_percent}
                  %
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.tcs_amount}
                </span>
              </div>
            )}
            {Number(laDetailsInDispatchCounter?.fright_advance) === 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>FREIGHT ADVANCE </span>

                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.fright_advance}
                </span>
              </div>
            )}
            {
              laDetailsInDispatchCounter?.total_values?.round_off && laDetailsInDispatchCounter?.total_values?.round_off != 0 ?
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span className={classes.titleTestStyle}>
                    ROUND OFF
                  </span>
                  <span
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {laDetailsInDispatchCounter?.total_values?.round_off}
                  </span>
                </div> : null
            }
            {/* </div> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className={classes.titleTestStyle}>
                TOTAL INVOICE VALUE
              </span>
              <span
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {laDetailsInDispatchCounter?.total_values?.total_item_value}
              </span>
            </div>
          </div>
        ) : (
          <div style={{ width: "60%", lineHeight: "3em", color: grey }}>
            {Number(laDetailsInDispatchCounter?.freight_charge_amount) === 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>
                  FREIGHT CHARGES(Included)
                </span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    laDetailsInDispatchCounter?.freight_charge_rate}
                  / MT
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.freight_charge_amount}
                </span>
              </div>
            )}
            {laDetailsInDispatchCounter?.total_values?.sgst_amount ===
              0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>SGST</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    Number(
                      laDetailsInDispatchCounter?.item_category?.sgst
                    ).toFixed(0)}
                  %
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.sgst_amount}
                </span>
              </div>
            )}
            {laDetailsInDispatchCounter?.total_values?.cgst_amount ===
              0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>CGST</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    Number(
                      laDetailsInDispatchCounter?.item_category?.cgst
                    ).toFixed(0)}
                  %
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.cgst_amount}
                </span>
              </div>
            )}
            {laDetailsInDispatchCounter?.total_values?.cess_amount ===
              0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>CESS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    laDetailsInDispatchCounter?.cess_rate}
                  / MT
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.cess_amount}
                </span>
              </div>
            )}
            {laDetailsInDispatchCounter?.total_values?.tcs_amount ===
              0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>TCS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {laDetailsInDispatchCounter &&
                    laDetailsInDispatchCounter?.tcs_percent}
                  %
                </span>
                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.total_values?.tcs_amount}
                </span>
              </div>
            )}
            {Number(laDetailsInDispatchCounter?.fright_advance) === 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>FREIGHT ADVANCE </span>

                <span className={classes.rightSideTextValue}>
                  {laDetailsInDispatchCounter?.fright_advance}
                </span>
              </div>
            )}

            {
              laDetailsInDispatchCounter?.total_values?.round_off && laDetailsInDispatchCounter?.total_values?.round_off != 0 ?
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span className={classes.titleTestStyle}>
                    ROUND OFF
                  </span>
                  <span
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {laDetailsInDispatchCounter?.total_values?.round_off}
                  </span>
                </div> : null
            }
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className={classes.totalTextInvoiceText}>
                TOTAL INVOICE VALUE
              </span>
              <span className={classes.totalTextInvoiceTextValue}>
                {laDetailsInDispatchCounter?.total_values?.total_item_value}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(GstDetails);
