import firebase from "firebase/app";
import axios from "axios";
import { DeviceUUID } from "device-uuid";
import "firebase/messaging";
import AUTH_URL from "../constants/common/LoginApiURLs";
import moment from "moment";

const config = {
  apiKey: "AIzaSyDU5rP4aMxBY0Eo3RYuuFAPSAZbN3YciaU",
  authDomain: "ripl-274911.firebaseapp.com",
  databaseURL: "https://ripl-274911.firebaseio.com",
  projectId: "ripl-274911",
  storageBucket: "ripl-274911.appspot.com",
  messagingSenderId: "442755058325",
  appId: "1:442755058325:web:63d29705f68b7db50da99c",
  measurementId: "G-7HRRQ9X1DD",
};

let instance;
let creating = false;
let messaging = false;

class Firebase {
  constructor(key) {
    if (!creating) {
      throw new Error(
        `Can't create instance of singleton class with new keyword. Use getInstance() static method instead`
      );
    }
    this.app = firebase.initializeApp(config);
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
      messaging.usePublicVapidKey(
        "BI_2YRqC2nsmcHI6xG8I9CJ5Wzmx9knBxdGSO5yWr-9ygZwTuRAZwU8iOy4gwUMbSk1gv2HpHor00BPM-QgUSv0"
      );
      messaging.onMessage((payload) => {
        // console.log("Notification Received", payload);
      });
    } else {
      return null;
    }
  }

  static getInstance() {
    if (!instance) {
      creating = true;
      instance = new Firebase();
      creating = false;
    }
    return instance;
  }

  getToken = (user) => {
    const uuid = new DeviceUUID().get()
    const timestamp = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
    const newUuid = `${uuid}.${timestamp}`;
    localStorage.setItem('newUuid', newUuid)
    const data = {
      name: user?.first_name + " " + user?.last_name,
      device_id: newUuid,
      active: true,
      type: window.cordova
        ? window.device.platform === "iOS"
          ? "ios"
          : "android"
        : "web",
    };
    const options = {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    if (window.cordova) {
      if (window.device.platform === "iOS") {
        window.FirebasePlugin.hasPermission((hasPermission) => {
          window.FirebasePlugin.getToken((token) => {
            data.registration_id = token;
            axios.post(AUTH_URL.POST_NOTIFICATION_TOKEN, data, options);
          }, console.error);
          window.FirebasePlugin.onTokenRefresh((token) => {
            data.registration_id = token;
            axios.post(AUTH_URL.POST_NOTIFICATION_TOKEN, data, options);
          }, console.error);
        });
      } else {
        window.FirebasePlugin.getToken((token) => {
          data.registration_id = token;
          axios.post(AUTH_URL.POST_NOTIFICATION_TOKEN, data, options);
        }, console.error);
      }
    } else {
      messaging
        .requestPermission()
        .then(() => {
          console.log("Have Permission");
          return messaging.getToken();
        })
        .then((token) => {
          data.registration_id = token;
          axios
            .post(AUTH_URL.POST_NOTIFICATION_TOKEN, data, options)
            .then((res) => console.log(res))
            .catch(console.error);
        })
        .catch((error) => {
          if (error.code === "messaging/permission-blocked") {
            console.log("Please Unblock Notification Request Manually");
          } else {
            console.log("Error Occurred", error);
          }
        });
    }
  };
}

export default Firebase.getInstance();
