import React, { Component } from "react";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import {
  grey,
  darkGrey,
  successButtonBackground,
} from "../../../constants/internal/colors";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  einvoiceAndEwayCardContainer: {
    marginTop: "50px",
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  invoicetypeTextContainer: {
    fontSize: "1.4em",
    fontWeight: "bold",
    padding: "10px 20px",
  },
  errorOrSuccessMessageContainer: (status) => ({
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8em",
    color: successButtonBackground,
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8em",
  }),
  errorOrSuccessEWAYMessageContainer: (status) => ({
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8em",
    color: status ? "Red" : successButtonBackground,
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.8em",
  }),
  errorText: {
    width: "0.8em",
    height: "0.8em",
    marginRight: "4px",
  },
};
class EinvoiceAndEwayCard extends Component {
  missingField = [];
  missingInfo = [];
  missingInvoiceData = [];
  plantItemData = [];
  missingEinvoiceData = [];
  invoiceType = null;
  constructor(props) {
    super(props);

    this.state = {
      eWayBill: [{ name: "Yes" }, { name: "No" }],
    };
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value }, () => {
      this.props.handleSetInvoiceType(this.state.value);
      if (
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].DOCNO) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].DOCDATE) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].ENTITY) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].T_CODE) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].RECNAME) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].RECADD1) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].RECSTATE) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].RECCITY) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].CONSIGNEENAME) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].CONSIGNEEADD1) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].CONSIGNEECITY) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].CONSIGNEESTATE) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].SUPGSTIN) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].SUPNAME) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].SUPADD1) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].SUPCITY) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantItemDetails &&
          !this.props.plantItemDetails[0].SUPPINCODE) ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantCode === "SH") ||
        (this.state.value === "Invoice with e-invoice" &&
          this.props.plantCode === "NV")
      ) {
      } else {
        this.props.handleSetInvoiceType(this.state.value);
      }
    });
  };
  handleSelectEWay = (select) => {
    this.props.handleEWayBillSelected(select);
  };

  handleValidationInvoiceType = (data) => {
    if (
      (data === "Invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].tran_sup_type === "B2C" &&
        this.props.plantItemDetails[0]?.PAYMENT_MODE === null) ||
      (data === "Invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].tran_sup_type === "B2C" &&
        this.props.plantItemDetails[0]?.PAYMENT_MODE === "AA" &&
        (this.props.plantItemDetails[0]?.PAYMENTAMOUNT === null ||
          this.props.plantItemDetails[0]?.REFRENCENUMBER === null)) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].tran_sup_type === "B2C") ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].DOCNO) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].DOCDATE) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].ENTITY) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].T_CODE) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].RECNAME) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].RECADD1) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].RECSTATE) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].RECCITY) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].CONSIGNEENAME) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].CONSIGNEEADD1) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].CONSIGNEECITY) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !this.props.plantItemDetails[0].CONSIGNEESTATE) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].SUPGSTIN) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].SUPNAME) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].SUPADD1) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].SUPCITY) ||
      (data === "Invoice with e-invoice" &&
        this.props.plantItemDetails &&
        !this.props.plantItemDetails[0].SUPPINCODE) ||
      // (data === "Invoice with e-invoice" && this.props.plantCode === "SH") ||
      // (data === "Invoice with e-invoice" && this.props.plantCode === "NV") ||
      (data === "Invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].tran_sup_type !== "B2C" &&
        this.props.plantCode === "RB") ||
      (data === "Invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].tran_sup_type !== "B2C" &&
        this.props.plantCode === "RU") ||
      (data === "Invoice" &&
        this.props.plantItemDetails &&
        this.props.plantItemDetails[0].tran_sup_type !== "B2C" &&
        this.props.plantCode === "AP")
    ) {
      return true;
    } else {
      return false;
    }
  };

  checkEWayValidation = (data) => {
    if (data === "Yes" && this.props.plantItemDetails) {
      this.missingField = [];
      if (this.props.plantItemDetails[0].DOCTYPE !== "INV") {
        this.missingField.push("DOCTYPE is not INV")
      } if (this.props.plantItemDetails[0].DOCTYPE === "CRN") {
        this.missingField.push("DOCTYPE should not be CRN")
      } if (!this.props.plantItemDetails[0].DOCNO) {
        this.missingField.push("DOCNO")
      } if (!this.props.plantItemDetails[0].ENTITY) {
        this.missingField.push("ENTITY")
      } if (!this.props.plantItemDetails[0].T_CODE) {
        this.missingField.push("T_CODE")
      } if (!this.props.plantItemDetails[0].SUPPLYTYPE) {
        this.missingField.push("SUPPLYTYPE")
      } if (!this.props.plantItemDetails[0].SUBTYPE) {
        this.missingField.push("SUBTYPE")
      } if (!this.props.plantItemDetails[0].TRANSDOCDATE) {
        this.missingField.push("TRANSDOCDATE")
      } if (!this.props.plantItemDetails[0].DOCTYPE) {
        this.missingField.push("DOCTYPE")
      } if (!this.props.plantItemDetails[0].SUPGSTIN) {
        this.missingField.push("SUPGSTIN")
      } if (!this.props.plantItemDetails[0].SUPPINCODE) {
        this.missingField.push("SUPPINCODE")
      } if (!this.props.plantItemDetails[0].bill_to_gstin) {
        this.missingField.push("bill_to_gstin")
      } if (!this.props.plantItemDetails[0].RECPINCODE) {
        this.missingField.push("RECPINCODE")
      }
      if (this.missingField.length > 0) {
        return true
      }
      return false;
    }
    return false;
  }


  checkInvoiceValidation = () => {
    this.invoiceType = null;
    this.plantItemData = [];
    const { plantItemDetails } = this.props

    if ((this.props.plantItemDetails &&
      this.props.plantItemDetails[0].tran_sup_type === "B2C") ||
      (plantItemDetails && !plantItemDetails[0].DOCNO) ||
      (plantItemDetails && !plantItemDetails[0].DOCDATE) ||
      (plantItemDetails && !plantItemDetails[0].ENTITY) ||
      (plantItemDetails && !plantItemDetails[0].T_CODE) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECNAME) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECADD1) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECSTATE) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECCITY) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].bill_to_gstin) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEENAME) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEEADD1) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEECITY) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEESTATE) ||
      (plantItemDetails &&
        this.props.plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].VEHICLENO) ||
      (plantItemDetails && !plantItemDetails[0].SUPGSTIN) ||
      (plantItemDetails && !plantItemDetails[0].SUPNAME) ||
      (plantItemDetails && !plantItemDetails[0].SUPADD1) ||
      (plantItemDetails && !plantItemDetails[0].SUPCITY) ||
      (plantItemDetails && !plantItemDetails[0].SUPPINCODE) ||
      this.props.plantCode === "SH" ||
      this.props.plantCode === "NV"

    ) {
      this.invoiceType = "Invoice";
      if (plantItemDetails && !plantItemDetails[0].DOCNO) {
        this.plantItemData.push("DOCNO")
      }
      if (plantItemDetails && !plantItemDetails[0].DOCDATE) {
        this.plantItemData.push("DOCDATE")
      }
      if (plantItemDetails && !plantItemDetails[0].ENTITY) {
        this.plantItemData.push("ENTITY")
      }
      if (plantItemDetails && !plantItemDetails[0].T_CODE) {
        this.plantItemData.push("T_CODE")
      }
      if (plantItemDetails && !plantItemDetails[0].RECNAME) {
        this.plantItemData.push("RECNAME")
      }
      if (plantItemDetails && !plantItemDetails[0].RECADD1) {
        this.plantItemData.push("RECADD1")
      }
      if (plantItemDetails && !plantItemDetails[0].RECSTATE) {
        this.plantItemData.push("RECSTATE")
      }
      if (plantItemDetails && !plantItemDetails[0].bill_to_gstin) {
        this.plantItemData.push("bill_to_gstin")
      }
      if (plantItemDetails && !plantItemDetails[0].CONSIGNEENAME) {
        this.plantItemData.push("CONSIGNEENAME")
      }
      if (plantItemDetails && !plantItemDetails[0].CONSIGNEEADD1) {
        this.plantItemData.push("CONSIGNEEADD1")
      }
      if (plantItemDetails && !plantItemDetails[0].CONSIGNEECITY) {
        this.plantItemData.push("CONSIGNEECITY")
      }
      if (plantItemDetails && !plantItemDetails[0].CONSIGNEESTATE) {
        this.plantItemData.push("CONSIGNEESTATE")
      }

      if (this.plantItemData.length > 0) {
        return true;
      } else {
        return false;
      }

    } else {
      this.invoiceType = "EInvoice";
      return false;
    }
  }


  erpDataCheck = (data) => {
    if (data === "Invoice with e-invoice" && this.props.plantItemDetails) {
      this.missingEinvoiceData = []
      const { plantItemDetails } = this.props;
      if (!plantItemDetails[0].DOCNO) {
        this.missingEinvoiceData.push("DOCNO")
      } if (!plantItemDetails[0].DOCDATE) {
        this.missingEinvoiceData.push("DOCDATE")
      } if (!plantItemDetails[0].ENTITY) {
        this.missingEinvoiceData.push("ENTITY")
      } if (!plantItemDetails[0].T_CODE) {
        this.missingEinvoiceData.push("T_CODE")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECNAME) {
        this.missingEinvoiceData.push("RECNAME")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECADD1) {
        this.missingEinvoiceData.push("RECADD1")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECSTATE) {
        this.missingEinvoiceData.push("RECSTATE")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].RECCITY) {
        this.missingEinvoiceData.push("RECCITY")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEENAME) {
        this.missingEinvoiceData.push("CONSIGNEENAME")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEEADD1) {
        this.missingEinvoiceData.push("CONSIGNEEADD1")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEECITY) {
        this.missingEinvoiceData.push("CONSIGNEECITY")
      } if (plantItemDetails[0].DOCTYPE === "INV" &&
        !plantItemDetails[0].CONSIGNEESTATE) {
        this.missingEinvoiceData.push("CONSIGNEESTATE")
      } if (!plantItemDetails[0].SUPGSTIN) {
        this.missingEinvoiceData.push("SUPGSTIN")
      } if (!plantItemDetails[0].SUPNAME) {
        this.missingEinvoiceData.push("SUPNAME")
      } if (!plantItemDetails[0].SUPADD1) {
        this.missingEinvoiceData.push("SUPADD1")
      } if (!plantItemDetails[0].SUPCITY) {
        this.missingEinvoiceData.push("SUPCITY")
      } if (!plantItemDetails[0].SUPPINCODE) {
        this.missingEinvoiceData.push("SUPPINCODE")
      } if (this.missingEinvoiceData.length > 0) {
        return true;
      }
    }
    return false;
  }

  render() {
    const { classes, invoiceMethod, plantItemDetails, plantCode } = this.props;
    const { eWayBill } = this.state;
    return (
      <div className={classes.einvoiceAndEwayCardContainer}>
        <Paper style={{ width: "38%", padding: "20px 0px 0px" }}>
          <div className={classes.invoicetypeTextContainer}>
            <span>
              Invoice Type<span style={{ color: "red" }}>*</span>
            </span>
          </div>
          <Divider />
          <div id="test">
            <FormControl
              component="fieldset"
              style={{ width: "100%", padding: "10px 24px" }}
            >
              {console.log(invoiceMethod)}
              <RadioGroup
                aria-label="gender"
                name="gender2"
                value={this.state.value}
                onChange={this.handleChange}
              >
                {invoiceMethod.map((data) => (
                  <FormControlLabel
                    value={data.name}
                    style={{ color: darkGrey }}
                    key={data.name}

                    control={
                      <Radio
                        disabled={this.handleValidationInvoiceType(data.name)}
                        style={{
                          color: grey,
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={
                      <div
                        style={{
                          marginLeft: "14px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>{data.name}</span>
                        {
                          this.erpDataCheck(data.name)
                            ? (
                              <span style={{ color: "red", fontSize: "0.5em" }}>
                                Some ERP data is missing, Please check
                                Missing Info {this.missingEinvoiceData.join(',')}
                                {console.log(plantItemDetails[0])}
                              </span>
                            ) : null}
                      </div>
                    }
                    onChange={this.handleChange}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Divider />
            <div className={classes.errorOrSuccessMessageContainer}>
              <span>
                {
                  this.checkInvoiceValidation()
                    ? (
                      <span style={{ color: "red" }}>
                        Data are missing to generate invoice
                        {this.plantItemData.length > 0
                          ? this.plantItemData.toString()
                          : ""
                        }
                      </span>
                    )
                    : this.invoiceType == "Invoice"
                      ? (
                        "Data is ready to generate invoice"
                      ) :
                      (
                        "Data is ready to generate e-invoice"
                      )
                }
              </span>
            </div>
          </div>
        </Paper>
        <Paper style={{ width: "38%", padding: "20px 0px 0px" }}>
          <div
            style={{
              fontSize: "1.4em",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <span>
              E-Way Bill<span style={{ color: "red" }}>*</span>
            </span>
          </div>
          <Divider />
          <div id="test">
            <FormControl
              component="fieldset"
              style={{ width: "100%", padding: "10px 24px" }}
            >
              <RadioGroup
                aria-label="gender"
                name="gender2"
                value={this.state.value}
              // onChange={this.handleChange}
              >
                {eWayBill.map((data) => (
                  <FormControlLabel
                    style={{ color: darkGrey }}
                    key={data.name}
                    value={data.name}
                    control={
                      <Radio
                        disabled={
                          this.checkEWayValidation(data.name) ||
                          this.props.checkTransportIdValidation(
                            this.props.plantItemDetails[0].TRANSPORTERID
                          ) != null
                        }
                        style={{
                          color: grey,
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={
                      <div style={{ marginLeft: "14px" }}>{data.name}</div>
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.handleSelectEWay(data.name);
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Divider />
            <div
              style={styles.errorOrSuccessEWAYMessageContainer(
                this.checkEWayValidation("Yes")
              )}
            >
              <span>
                {this.checkEWayValidation("Yes") ? <span style={{ color: "red" }}>
                  Following data is missing: {this.missingField.join(',')}
                </span> : ""
                }
                <span style={{ color: "red" }}>
                  {
                    this.props.checkTransportIdValidation(
                      this.props.plantItemDetails[0].TRANSPORTERID
                    ) ?? ""
                  }
                </span>

                {
                  !this.checkEWayValidation("Yes") && this.props.checkTransportIdValidation(
                    this.props.plantItemDetails[0].TRANSPORTERID
                  ) == null ? "Data is ready to generate e-way" : ""
                }
              </span>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
export default withStyles(styles)(EinvoiceAndEwayCard);
