import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Divider, Switch, } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  primaryColor,
  drawerTextColor,
  secondaryBlueText,
} from "../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
const useStyles = makeStyles({
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "30px",
    width: "100%",
  },
  headerData: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
  topMargin: { marginTop: 30, width: "45%" },

  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function DisplayAppVersionData(props) {
  const classes = useStyles();
  const [appVersionData, setAppVersionDisplayData] = useState(
    props.selectedAppVersionData
  );

  useEffect(() => {
    // console.log(props?.selectedAppVersionData)
    setAppVersionDisplayData(props?.selectedAppVersionData)
  }, [props?.selectedAppVersionData])

  const handleCancelButtonClick = () => {
    props.history.push(INTERNALROUTES.APP_VERSION);
  };
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <div className={classes.navigationBar}>
            <Bread
              data={[
                {
                  title: "Master Management",
                  onClick: () =>
                    props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
                },
                {
                  title: "App Version Master",
                  onClick: () => props.history.push(INTERNALROUTES.APP_VERSION),
                },
                {
                  title: appVersionData?.app_version || " ",
                  onClick: "",
                },
              ]}
            />
            <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />
            <div className={classes.headerData}>
              <span>{moment(appVersionData?.created_at).format('DD-MMM-YY') || ""}</span>
            </div>
          </div>
          <div className={classes.topMargin}>
            <div className={classes.dataFields} style={{ alignItems: 'flex-start' }}>
              <Typography className={classes.titleLabel}>
                VERSION
              </Typography>
              <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                {appVersionData?.app_version || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                WHAT's NEW
              </Typography>
              <Typography className={classes.titleLabel} style={{ paddingRight: 10, textAlign: 'end', width: '50%', wordWrap: 'break-word' }}>
                {appVersionData?.new_updates || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                LAST EDITED
              </Typography>
              <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                {moment(appVersionData?.created_at).format('DD-MMM-YY') || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                IS ACTIVE
              </Typography>
              <div>
                <IOSSwitch
                  disabled={true}
                  checked={Boolean(appVersionData?.is_active)}
                />
              </div>
            </div>

            <Button
              size="medium"
              className={classes.primaryBackground}
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              disabled={isEmpty(appVersionData)}
              onClick={(e) => {
                e.preventDefault();
                props.history.push(
                  `${INTERNALROUTES.APP_VERSION}/edit/${appVersionData?.id}`
                );
              }}
            >
              EDIT
            </Button>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(DisplayAppVersionData);
