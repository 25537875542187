import React, { Component } from "react";
import LoginMobile from "../../components/common/LoginMobile";
import Login from "../../components/common/Login";
import axios from "axios";
import routes from "../../constants/b2b/routes";
import { checkMobileNumber, checkEmailAddress } from "../../methods";
import withAuthentication from "../../hoc/withAuthentication";
import AUTH_URL from "../../constants/common/LoginApiURLs";
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      userId: "",
      emailError: false,
      phoneNumberError: false,
      userNotExist: false,
      sendOtp: false,
      emailNotExist: false,
      phoneNumberNotExist: false,
      sendEmailOtp: false,
      sendNumberOtp: false,
      incorrectNumber: false,
      incorrectEmail: false,

      // AFTER NEW SCREEN ---------------------
      email: "",
      phoneNo: "",
      otp: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  }

  handleChange = (name) => (event) => {
    if (name !== "password") {
      this.setState(
        {
          [name]: event.target.value,
          emailError: false,
          phoneNumberError: false,
          userNotExist: false,
          sendOtp: false,
          emailNotExist: false,
          phoneNumberNotExist: false,
          sendEmailOtp: false,
          sendNumberOtp: false,
          incorrectNumber: false,
          incorrectEmail: false,
        },
        () => {
          this.props.setStateWithAuthData();
        }
      );
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleSubmitLogin = (userId, password) => {
    let loginFor = this.state.sendOtp ? "phone" : "username";
    this.props
      .login(userId, password, loginFor)
      .then((response) => { })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  sendOtpFunction = (userId) => {
    if (
      (userId.includes("@") || userId.endsWith(".com")) &&
      checkEmailAddress(userId) === "error"
    ) {
      this.setState({ emailError: true });
    } else if (checkEmailAddress(userId) !== "error") {
      this.getOpt(userId);
    } else if (checkMobileNumber(userId) === "error") {
      this.setState({ phoneNumberError: true });
    } else if (checkMobileNumber(userId) !== "error") {
      this.getOpt(userId);
    }
  };

  getOpt = (userId) => {
    const dataUrl = routes.ApiRoutes + "/customer/auth/mobile_login/";
    const axiosData = {
      phone: this.state.userId,
    };
    return axios({
      method: "post",
      baseURL: `${dataUrl}`,
      data: axiosData,
    })
      .then((response) => {
        if (
          (userId.includes("@") || userId.endsWith(".com")) &&
          response.data.error
        ) {
          this.setState({ emailNotExist: true });
        } else if (response.data.error) {
          this.setState({ phoneNumberNotExist: true });
        } else if (response.data.type) {
          if (
            (userId.includes("@") || userId.endsWith(".com")) &&
            response.data.type === "success"
          ) {
            this.setState({ sendEmailOtp: true, sendOtp: true });
          } else {
            this.setState({ sendNumberOtp: true, sendOtp: true });
          }
        }
      })
      .catch(function (error) {
        console.log("error:", error);
      });
  };

  // AFTER NEW SCREEN -BELOW CODE--------------------
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  resetAllInputFields = () => {
    this.setState({
      email: "",
      phoneNo: "",
      password: "",
      otp: "",
      newPassword: "",
      newPasswordConfirm: "",
    });
  };
  sendOtpForForgotPassword = (phoneNumber) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTH_URL.SENT_OTP_FOR_FORGOT_PASSWORD, {
          phone: phoneNumber,
        })
        .then(resolve)
        .catch(reject);
    });

  verifyOtpForForgotPassword = (phoneNumber, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTH_URL.VERIFY_OTP_FOR_FORGOT_PASSWORD, {
          phone: phoneNumber,
          otp: otp,
        })
        .then(resolve)
        .catch(reject);
    });
  changePasswordFromForgotPassword = (newPassword, phoneNumber) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTH_URL.CHANGE_PASSWORD_FROM_FORGOT_PASSWORD, {
          password: newPassword,
          phone: phoneNumber,
        })
        .then(resolve)
        .catch(error => {
          if (error['response']['status'] === 400) {
            resolve(error)
          }
          resolve(error)
        });
    });

  getMobileOTPForgetPassword = (userID) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTH_URL.GET_FORGOTPASSWORD_OTP_LOGIN, {
          credential: userID
        })
        .then(resolve)
        .catch(error => {
          if (error['response']['status'] === 400) {
            resolve(error)
          }
          resolve(error)
        });
    });
  verifyMobileOTPForgetPassword = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTH_URL.VERIFY_FORGOTPASSWORD_OTP_LOGIN, data)
        .then(resolve)
        .catch(error => {
          if (error['response']['status'] === 400) {
            resolve(error)
          }
          resolve(error)
        });
    });

  render() {
    const {
      email,
      phoneNo,
      password,
      otp,
      newPassword,
      newPasswordConfirm,
    } = this.state;
    const { isMobile } = this.props;
    return isMobile ? (
      <LoginMobile
        handleChange={this.handleChange}
        {...this.state}
        {...this.props}
        sendOtpFunction={this.sendOtpFunction}
        handleSubmitLogin={this.handleSubmitLogin}
        email={email}
        phoneNo={phoneNo}
        password={password}
        otp={otp}
        handleTextChange={this.handleTextChange}
        newPassword={newPassword}
        newPasswordConfirm={newPasswordConfirm}
        resetAllInputFields={this.resetAllInputFields}
        sendOtpForForgotPassword={this.sendOtpForForgotPassword}
        verifyOtpForForgotPassword={this.verifyOtpForForgotPassword}
        changePasswordFromForgotPassword={this.changePasswordFromForgotPassword}
        getMobileOTPForgetPassword={this.getMobileOTPForgetPassword}
        verifyMobileOTPForgetPassword={this.verifyMobileOTPForgetPassword}
      />
    ) : (
      <Login
        handleChange={this.handleChange}
        {...this.state}
        {...this.props}
        sendOtpFunction={this.sendOtpFunction}
        handleSubmitLogin={this.handleSubmitLogin}
        email={email}
        phoneNo={phoneNo}
        password={password}
        otp={otp}
        handleTextChange={this.handleTextChange}
        newPassword={newPassword}
        newPasswordConfirm={newPasswordConfirm}
        resetAllInputFields={this.resetAllInputFields}
        sendOtpForForgotPassword={this.sendOtpForForgotPassword}
        verifyOtpForForgotPassword={this.verifyOtpForForgotPassword}
        changePasswordFromForgotPassword={this.changePasswordFromForgotPassword}
      />
    );
  }
}

export default withAuthentication(LoginContainer);
