import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor, secondaryColor } from "../../../styles/colors";
import Info from "@material-ui/icons/Info";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import moment from "moment";
import "../orders/styles.css"
import { LottieAnimation } from "react-lottie-tools";
import PendingHourGlass from "../../../styles/b2b/Animations/PendingHourGlass.json"


const styles = {
  orderDetailMainContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  orderDetailChildContainer: {
    borderRadius: "12px",
    width: "90%",
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  },
  orderDetailChildTopBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 20px 0px",
    color: bottomNavigationFontColor,
  },
  orderShowMainDetail: {
    padding: "15px 20px 25px 20px",
    display: "flex",
    justifyContent: "space-around",
    height: "80%",
    alignItems: "center",
  },
  orderShowMainDetailLeft: {
    width: "35%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  orderShowMainDetailRight: {
    width: "60%",

    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  orderShowHorizontalLine: {
    width: "0.4%",
    height: "100%",
    background: bottomNavigationFontColor,
  },
  orderShowMainDetailRightTop: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 10px 3px",
  },
};

class OrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // details: {},
      // previousDetails: {}
      // || {
      //   count: 0
      // }
    };
  }

  render() {
    const { classes, title, details, isMobile } = this.props;
    // console.log(details)
    // console.log(this.state.previousDetails)
    // console.log(this.props)
    return (
      <div>
        {details &&
          <div className={classes.orderDetailMainContainer}>
            <div className={classes.orderDetailChildContainer}>
              <div className={classes.orderDetailChildTopBar}>
                <span>Contract {details.order_number}</span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >{
                    title === "Approval pending" ?
                      <LottieAnimation
                        animation={PendingHourGlass}
                        style={{ width: "24px", height: "24px" }}
                        loop
                      />
                      // <HourglassEmpty style={{
                      //   color: secondaryColor,
                      //   height: "0.8em",
                      //   width: "0.8em",
                      //   marginRight: "7px",
                      //   top: "2px",
                      //   animation: "spin 1s infinite linear",
                      // }} />
                      :
                      <Info
                        style={{ color: "#f8a669", height: "0.8em", width: "0.8em" }}
                      />
                  }
                  {title ? title : "Action Needed"}
                </span>
              </div>
              <div className={classes.orderShowMainDetail}>
                <div className={classes.orderShowMainDetailLeft}>
                  <span
                    style={{
                      color: bottomNavigationFontColor,
                      fontSize: "1.2em",
                    }}
                  >
                    {details.item_category.category_name}
                  </span>
                  <span style={{ fontSize: "1.2em" }}>{`₹${parseInt(details.rate).toFixed(0)}`}</span>
                  {

                    isMobile ?
                      <span style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: bottomNavigationFontColor,
                            marginTop: "3px",
                          }}
                        >
                          Created 0n
                        </span>
                        <span style={{
                          fontSize: "0.8em",
                          color: bottomNavigationFontColor,
                          marginTop: "3px",
                        }}>
                          {moment(details.created_at).format("DD-MMM-yyyy")}
                        </span>
                      </span>
                      :
                      <span
                        style={{
                          fontSize: "0.8em",
                          color: bottomNavigationFontColor,
                          marginTop: "3px",
                        }}
                      >
                        Created 0n {moment(details.created_at).format("DD-MMM-yyyy")}
                      </span>
                  }
                </div>
                <div className={classes.orderShowHorizontalLine}></div>
                <div className={classes.orderShowMainDetailRight}>
                  <div className={classes.orderShowMainDetailRightTop}>
                    <span style={{ color: bottomNavigationFontColor }}>
                      Pending
                    </span>
                    <span>{`${parseInt(details.dispatch_detail ? details.dispatch_detail.pending : details?.pending_qty).toFixed(0)}`}MT</span>
                  </div>
                  <div className={classes.orderShowMainDetailRightTop}>
                    <span style={{ color: bottomNavigationFontColor }}>
                      Dispatched
                    </span>
                    <span>{`${parseInt(details.dispatch_detail ? details.dispatch_detail.dispatched_qty : details?.dispatched_qty).toFixed(0)}`}MT</span>
                  </div>
                  <div className={classes.orderShowMainDetailRightTop}>
                    <span style={{ color: bottomNavigationFontColor }}>
                      Total
                    </span>
                    <span>{`${parseInt(details.quantity).toFixed(0)}`}MT</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
export default withStyles(styles)(OrderCard);
