import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import EInvoicing from "../EInvoicing";

class EinvoicingAndEwayBillingWeb extends Component {
  componentWillMount() {
    if (this.props.match.path === "/internalEInvoicingAndEWayBilling") {
      this.props.handleResetAllData();
    }
  }

  render() {
    return (
      <Layout {...this.props}>
        <div style={{ height: "100%", display: "flex", width: "100%" }}>
          <EInvoicing {...this.props} />
        </div>
      </Layout>
    );
  }
}
export default EinvoicingAndEwayBillingWeb;
