// Mobile Imports
import Dispatch from "./mobile";
import DispatchCreate from "./mobile/DispatchCreate";
import SingleDispatch from "./mobile/SingleDispatch";
import PendingDispatch from "./mobile/PendingDispatch";
import CancelDispatch from "./mobile/CancelDispatch";
import ConfirmDispatch from "./mobile/ConfirmDispatch";

// Web Imports
import DispatchWeb from "./web";
import DispatchCreateWeb from "./web/DispatchCreate";
import SingleDispatchWeb from "./web/SingleDispatch";
import PendingDispatchWeb from "./web/PendingDispatch";
import CancelDispatchWeb from "./web/CancelDispatch";
import ConfirmDispatchWeb from "./web/ConfirmDispatch";

// Exports
export default Dispatch;
export {
  DispatchWeb,
  DispatchCreate,
  DispatchCreateWeb,
  SingleDispatch,
  SingleDispatchWeb,
  PendingDispatch,
  PendingDispatchWeb,
  CancelDispatch,
  CancelDispatchWeb,
  ConfirmDispatch,
  ConfirmDispatchWeb,
};
