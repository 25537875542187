import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  InputAdornment,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import {
  drawerTextColor,
  grey,
  secondaryGrey,
  successButtonBackground,
  whiteSmoke,
} from "../../../../constants/internal/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import DeleteIcon from '@material-ui/icons/Delete';
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Loader from "../../common/Spinner";
import { hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../methods";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import SnackbarComponent from "../../common/SnackbarComponent";
import SaveButton from "../../common/SaveButton";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  inputWidth: {
    width: "25ch",
  },
  tableHead: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  tableBody: {
    fontSize: pxToEm(14),
  },
  alignText: {
    display: "flex",
    flexWrap: "wrap",
    placeContent: "center",
  },
  alignBodyText: {
    display: "flex",
    alignItems: "center",
    // flexWrap: 'wrap',
  },
  rightSpacing: {
    marginRight: 10,
  },
  stateSelection: {
    marginRight: 10,
    width: "100%",
  },
  arrowDimension: {
    height: 22,
    width: 22,
    margin: "0px 5px 0px 5px",
    color: secondaryGrey,
    cursor: "pointer",
  },
  arrowDimensionSelected: {
    height: 22,
    width: 22,
    margin: "0px 5px 0px 5px",
    color: "black",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: "bold",
  },
  container: {
    marginTop: 10,
  },
  removeOption: {
    color: secondaryGrey,
    fontSize: pxToEm(13),
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveCancelButtonStyles: {
    paddingTop: 10,
  },
  iconContainer: {
    height: 30,
    width: 250,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    color: grey,
  },
  editTextIcon: {
    borderRadius: 100,
    padding: 2,
    marginLeft: "10px",
    width: "70px",
    display: "flex",
    backgroundColor: whiteSmoke,
    color: grey,
    cursor: "pointer",
  },
  editText: {
    flex: 1,
    marginLeft: 5,
    textAlign: "left",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
  },
});
export default function RateTemplateTable(props) {
  const classes = useStyles();
  const [rateTemplateTableOperation, setRateTemplateTableOperation] =
    React.useState("view");
  const [rateTemplateTableDataSaveUpdateLoader, setDataSaveUpdateLoader] =
    React.useState(false);
  const [snackBarValue, setSnackBarValue] = React.useState({
    isSnackOpen: false,
    snackMessage: "",
    snackeType: "",
  });
  const handleSnackBarMessages = (message, type = "warning") => {
    setSnackBarValue({
      isSnackOpen: true,
      snackMessage: message,
      snackeType: type,
    })
  }

  function handleSetBaseState(idx, currentValue) {
    if (currentValue === false) {
      const currentRateTemplate = [...itemCategoryExistingRateTemplateData];
      currentRateTemplate.map((d, i) => {
        if (d.is_base_rate) {
          currentRateTemplate[i]["is_base_rate"] = false;
        }
      });
      currentRateTemplate[idx]["is_base_rate"] = true;
      setAddNewRateTemplateData(currentRateTemplate);
    }
  }
  function handleRateTemplateTableOperation(operation) {
    setRateTemplateTableOperation(operation);
    // console.log("operation : ", operation);
  }
  const [itemCategoryExistingRateTemplateData, setAddNewRateTemplateData] =
    React.useState(props?.props?.itemCategoryExistingRateTemplateData || []);

  function handleAddNewRateTemplate() {
    let newData =
      itemCategoryExistingRateTemplateData.length > 0 ? false : true;
    setAddNewRateTemplateData((itemCategoryExistingRateTemplateData) => [
      ...itemCategoryExistingRateTemplateData,
      {
        state: null,
        highRateDifference: 0,
        lowRateDifference: 0,
        isBaseRate: newData ? true : false,
        operation: "add",
      },
    ]);
    setRateTemplateTableOperation("add");
  }

  function handleRemoveNewRateTemplate(index) {
    let tempItemCategoryExistingRateTemplateData =
      itemCategoryExistingRateTemplateData.splice(
        itemCategoryExistingRateTemplateData[index],
        1
      );
    setAddNewRateTemplateData(tempItemCategoryExistingRateTemplateData);
    setRateTemplateTableOperation("view");
  }
  function handleCancelButtonClick() {
    let finalData = itemCategoryExistingRateTemplateData.filter(
      (data) => data.operation !== "add"
    );
    setAddNewRateTemplateData(finalData);
    setRateTemplateTableOperation("view");
  }
  const handleRateTemplateTableValueChange = (index) => (event) => {
    const { value, name } = event.target;
    let newArr = [...itemCategoryExistingRateTemplateData];
    newArr[index][name] = value;
    setAddNewRateTemplateData(newArr);

  };
  function handleStateInputChange(value, index) {
    // console.log("value : ", value);
    // console.log("index : ", index);
    let newArr = [...itemCategoryExistingRateTemplateData];
    if (!value) {
      newArr[index].state = null;
    } else {
      newArr[index].state = value?.id;
    }
    setAddNewRateTemplateData(newArr);
  }
  const handleSaveNewRateTemplate = () => {
    setDataSaveUpdateLoader(true);
    if (rateTemplateTableOperation === "add") {
      let dataToBeAdded = itemCategoryExistingRateTemplateData.find(
        (x) => x.operation === "add"
      );
      let rateTemplateAddData = {
        state: dataToBeAdded.state,
        high_rate_difference: dataToBeAdded.high_rate_difference,
        low_rate_difference: dataToBeAdded.low_rate_difference,
        is_base_rate: dataToBeAdded.isBaseRate,
        item_category: props?.props?.selectedItemId,
      };
      props.props.handleAddRateTemplateTableData(rateTemplateAddData);
      // setDataSaveUpdateLoader(!rateTemplateTableDataSaveUpdateLoader);
      // setDataSaveUpdateLoader(false);
      setRateTemplateTableOperation("view");
    } else {
      for (let i = 0; i < itemCategoryExistingRateTemplateData.length; i++) {
        itemCategoryExistingRateTemplateData[i]["item_category"] = props?.props?.selectedItemId
      }
      props.props.handleUpdateRateTemplateTableData(
        itemCategoryExistingRateTemplateData
      );

      // setDataSaveUpdateLoader(!rateTemplateTableDataSaveUpdateLoader);
      // setDataSaveUpdateLoader(false);
      setRateTemplateTableOperation("view");
    }
  };

  const handleRemoveDailyRates = (id) => {
    axios.delete(`${APIROUTES.DAILY_RATE_TEMPLATE_DELETE}${id}/`,
      {
        headers: {
          Authorization: `JWT ${props.props.user.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        }
      })
      .then((response) => {
        handleSnackBarMessages("Rate Template Deleted Successfully", "success");
        props.props.getItemCateogriesList()
        props.props.handleFetchSelectedItemRateTemplateTableData(
          props?.props?.selectedItemId
        );
      })
      .catch((error) => {
        handleSnackBarMessages("Rate template linked to other data so can't be deleted", "error")
      })
  }

  let dailyRatesState = props?.props?.allRegions || [];
  let rows = itemCategoryExistingRateTemplateData || [];
  const filteredDailyRatesStateSelectionsList = dailyRatesState.filter(
    (x) =>
      !rows.filter((y) => {
        if (y?.state?.id !== null || y?.state?.id !== undefined) {
          return y?.state?.id === x?.id;
        } else {
          return true;
        }
      }).length
  );
  React.useEffect(() => {
    setAddNewRateTemplateData(
      props?.props?.itemCategoryExistingRateTemplateData || []
    );
    setDataSaveUpdateLoader(false);
  }, [props?.props?.itemCategoryExistingRateTemplateData]);
  console.log("dataaaaaaaa", itemCategoryExistingRateTemplateData, props);
  return (
    <>
      <div className={classes.iconContainer}>
        <span className={classes.contentTitle}>RATE TEMPLATE TABLE</span>
        <ToolTipComponent
          title="You have no edit permission on this widget"
          condition={!hasUpdateAccess(props.props.user, 'item_categories')}
        >
          <div
            className={classes.editTextIcon}
            onClick={() => {
              hasUpdateAccess(props.props.user, 'item_categories') &&
                setRateTemplateTableOperation("edit")
            }
            }
          >
            <EditOutlinedIcon className={classes.icon} />
            <div
              className={classes.editText}
              style={{
                color: !hasUpdateAccess(props.props.user, 'item_categories') && 'rgba(0,0,0,0.26)'
              }}
            >
              edit</div>
          </div>
        </ToolTipComponent>
      </div>
      <TableContainer component={Paper} className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="products-state-price-table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableHead}>
                STATE
              </TableCell>
              <TableCell align="center" className={classes.tableHead}>
                <div className={classes.alignText}>
                  <div className={classes.rightSpacing}>OPEN RATE</div>
                  <div>
                    <HelpOutlineOutlinedIcon
                      className={classes.arrowDimension}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="center" className={classes.tableHead}>
                <div className={classes.alignText}>
                  <div className={classes.rightSpacing}>NET RATE</div>
                  <div>
                    <HelpOutlineOutlinedIcon
                      className={classes.arrowDimension}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {itemCategoryExistingRateTemplateData &&
              itemCategoryExistingRateTemplateData.length > 0
              ? itemCategoryExistingRateTemplateData.map((data, index) => (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={classes.tableBody}
                  >
                    <div className={classes.alignBodyText}>
                      <div>
                        {rateTemplateTableOperation === "add" ? null : (
                          <StarBorderOutlinedIcon
                            disabled={!data.is_base_rate || !data.isBaseRate}
                            className={
                              data.is_base_rate || data.isBaseRate
                                ? classes.arrowDimensionSelected
                                : classes.arrowDimension
                            }
                            onClick={(e) =>
                              rateTemplateTableOperation === "edit"
                                ? handleSetBaseState(index, data.is_base_rate)
                                : // handleBaseStateChange(index)
                                null
                            }
                          />
                        )}
                      </div>
                      <div className={classes.stateSelection}>
                        {!data.operation || data.operation === "view" ? (
                          <Typography className={classes.formControl}>
                            {!data?.state?.state_name
                              ? !data?.state
                                ? null
                                : data?.state
                              : data?.state?.state_name}
                          </Typography>
                        ) : (
                          <Autocomplete
                            size="small"
                            name="state"
                            options={filteredDailyRatesStateSelectionsList}
                            getOptionLabel={(option) => option.state_name}
                            onChange={(event, value) =>
                              handleStateInputChange(value, index)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select State"
                                variant="outlined"
                                name="state"
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tableBody}>
                    {rateTemplateTableOperation === "view" ? (
                      <Typography className={classes.formControl}>
                        {!data?.high_rate_difference
                          ? !data?.highRateDifference
                            ? 0
                            : `₹ ${Number(data?.highRateDifference).toFixed(2)}`
                          : `₹ ${Number(data?.high_rate_difference).toFixed(2)}`}
                      </Typography>
                    ) : rateTemplateTableOperation === "edit" ? (
                      <TextField
                        type="number"
                        className={classes.inputWidth}
                        name="high_rate_difference"
                        value={
                          props?.props
                            ?.rateTemplateTableAddNewOpenRateValue ||
                          data?.high_rate_difference
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={handleRateTemplateTableValueChange(index)}
                        onKeyDown={(evt) => (
                          ["e", "E", "+", "-"].includes(evt.key) ||
                          (evt.which === 38 || evt.which === 40)
                        ) && evt.preventDefault()}
                        // pattern="[0-9]+(?:[.]\d+)?"
                        // onInput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                        // error={
                        //   isRateValid(e.target.value) ? true : false
                        //   // props.props.rateTemplateTableAddNewOpenRateValueError
                        // }
                        // helperText={
                        //   // props.props.rateTemplateTableAddNewOpenRateValueError
                        //   isRateValid(e.target.value)
                        //     ? "Invalid open rate"
                        //     : ""
                        // }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              ₹
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        size="small"
                      />
                    ) : !data.operation ? (
                      <Typography className={classes.formControl}>
                        {!data?.high_rate_difference
                          ? !data?.highRateDifference
                            ? 0
                            : `₹ ${Number(data?.highRateDifference).toFixed(2)}`
                          : `₹ ${Number(data?.high_rate_difference).toFixed(2)}`}
                      </Typography>
                    ) : (
                      <TextField
                        type="number"
                        className={classes.inputWidth}
                        name="high_rate_difference"
                        value={
                          props?.props
                            ?.rateTemplateTableAddNewOpenRateValue ||
                          data?.high_rate_difference
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={handleRateTemplateTableValueChange(index)}
                        onKeyDown={(evt) => (
                          ["e", "E", "+", "-"].includes(evt.key) ||
                          (evt.which === 38 || evt.which === 40)
                        ) && evt.preventDefault()}
                        // pattern="[0-9]+(?:[.]\d+)?"
                        // onInput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                        // error={isRateValid(e.target.value) ? true : false
                        //   // props.props.rateTemplateTableAddNewOpenRateValueError
                        // }
                        // helperText={
                        //   // props.props.rateTemplateTableAddNewOpenRateValueError
                        //   isRateValid(e.target.value)
                        //     ? "Invalid open rate"
                        //     : ""
                        // }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              ₹
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </TableCell>

                  <TableCell align="center" className={classes.tableBody}>
                    {rateTemplateTableOperation === "view" ? (
                      <Typography className={classes.formControl}>
                        {!data?.low_rate_difference
                          ? !data?.lowRateDifference
                            ? 0
                            : `₹ ${data?.lowRateDifference}`
                          : `₹ ${data?.low_rate_difference}`}
                      </Typography>
                    ) : rateTemplateTableOperation === "edit" ? (
                      <TextField
                        type="number"
                        name="low_rate_difference"
                        className={classes.inputWidth}
                        value={
                          props.lowRateDifference || data.low_rate_difference
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={handleRateTemplateTableValueChange(index)}
                        onKeyDown={(evt) => (
                          ["e", "E", "+", "-"].includes(evt.key) ||
                          (evt.which === 38 || evt.which === 40)
                        ) && evt.preventDefault()}
                        // pattern="[0-9]\d+(?:[.]\d+)?"
                        // onInput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                        // error={
                        //   isRateValid(e.target.value) ? true : false
                        //   // props.props.rateTemplateTableAddNewNetRateValueError
                        // }
                        // helperText={
                        //   // props.props.rateTemplateTableAddNewNetRateValueError
                        //   isRateValid(e.target.value)
                        //     ? "Invalid net rate"
                        //     : ""
                        // }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              ₹
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        size="small"
                      />
                    ) : !data.operation ? (
                      <Typography className={classes.formControl}>
                        {!data?.low_rate_difference
                          ? !data?.lowRateDifference
                            ? 0
                            : `₹ ${data?.lowRateDifference}`
                          : `₹ ${data?.low_rate_difference}`}
                      </Typography>
                    ) : (
                      <TextField
                        type="number"
                        name="low_rate_difference"
                        className={classes.inputWidth}
                        value={
                          props.lowRateDifference || data.low_rate_difference
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={handleRateTemplateTableValueChange(index)}
                        onKeyDown={(evt) => (
                          ["e", "E", "+", "-"].includes(evt.key) ||
                          (evt.which === 38 || evt.which === 40)
                        ) && evt.preventDefault()}
                        // pattern="[0-9]+(?:[.]\d+)?"
                        // onInput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                        // error={
                        //   isRateValid(e.target.value) ? true : false
                        //   // props.props.rateTemplateTableAddNewNetRateValueError
                        // }
                        // helperText={
                        //   // props.props.rateTemplateTableAddNewNetRateValueError
                        //   isRateValid(e.target.value)
                        //     ? "Invalid net rate"
                        //     : ""
                        // }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              ₹
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </TableCell>
                  <TableCell align="right" className={classes.removeOption}>
                    {!data?.operation ? null : data.operation === "add" ? (
                      <span
                        // onClick={() => handleRemoveNewRateTemplate(index)}
                        onClick={() => {
                          handleCancelButtonClick();
                        }}
                        disabled={props.isLoading}
                      >
                        REMOVE
                      </span>
                    ) : null}
                  </TableCell>
                  <TableCell align="right" className={classes.tableBody}>
                    <ToolTipComponent
                      title="You have no edit permission on this widget"
                      condition={!hasUpdateAccess(props.props.user, 'item_categories')}
                    >
                      <IconButton
                        disabled={props.isLoading || !hasUpdateAccess(props.props.user, 'item_categories')}
                        onClick={() => handleRemoveDailyRates(data.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ToolTipComponent>
                  </TableCell>
                </TableRow>
              ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {rateTemplateTableDataSaveUpdateLoader ? (
        <Loader />
      ) : rateTemplateTableOperation === "view" ? (
        <ToolTipComponent
          title="You have no create permission on this widget"
          condition={!hasWriteAccess(props.props.user, 'item_categories')}
        >
          <Button
            variant="outlined"
            size="medium"
            disabled={!hasWriteAccess(props.props.user, 'item_categories')}
            startIcon={<CheckOutlinedIcon />}
            className={classes.addNewButton}
            onClick={() => handleAddNewRateTemplate()} //handleRateTemplateTableOperation("add")}
          >
            ADD NEW
          </Button>
        </ToolTipComponent>
      ) : (
        <div className={classes.saveCancelButtonStyles}>
          <SaveButton
            size="medium"
            className={classes.saveButton}
            variant="contained"
            startIcon={<CheckOutlinedIcon />}
            // disabled={isLoadingRemove}
            onClick={(e) => {
              e.preventDefault();
              handleSaveNewRateTemplate();
            }}
            isLoading={props.isLoading}
          >
            Save
          </SaveButton>

          <Button
            size="medium"
            className={classes.margin}
            onClick={() => {
              handleCancelButtonClick();
            }}
          >
            Cancel
          </Button>

        </div>
      )}
      <SnackbarComponent open={snackBarValue.isSnackOpen} snackbarMessage={snackBarValue.snackMessage} type={snackBarValue.snackeType} handleClose={() => {
        setSnackBarValue({
          isSnackOpen: false,
          snackMessage: "",
          snackeType: "",
        })
      }} />
    </>
  );
}
