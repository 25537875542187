import * as Yup from "yup";

const validationsOrderDetails = Yup.object().shape({
  plant: Yup.string().required("This field is Required"),
  tran_sup_type: Yup.string().required("This field is Required"),
  // sub_contract: Yup.string().when('subcontractEnabled', 'created_from_subcontract', 'laOperation',
  //   (subcontractEnabled, created_from_subcontract, laOperation, schema) => {
  //     if (subcontractEnabled) {
  //       return schema.required('This field is Required');
  //     } else if (laOperation == "edit" && created_from_subcontract) {
  //       return schema.required('This field is Required');
  //     }
  //     else {
  //       return schema;
  //     }
  //   }),
  sub_contract:
    Yup.string().when(['subcontractEnabled', 'laOperation'], {
      is: (subcontractEnabled, laOperation) => {
        return (subcontractEnabled && laOperation == "add");
      },
      then: Yup.string().required('This field is Required')
    }).when(['created_from_subcontract', 'laOperation'], {
      is: (created_from_subcontract, laOperation) => {
        return laOperation === "edit" && created_from_subcontract;
      },
      then: Yup.string().required('This field is Required'),
      otherwise: Yup.string()
    }),

  lc_date: Yup.string().when('lc_number',
    (lc_number, schema) => {
      if (lc_number) {
        return schema.required('This field is Required');
      }
      else {
        return schema;
      }
    }),
});

const validationsLogisticDetails = Yup.object().shape({
  truck_number: Yup.string().required("This field is Required")
    // .matches(/^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{1,4}$/, 'Vehicle Number should be valid with capital alphanumeric number.'),
    .matches(/^[a-zA-Z]{2}[0-9]{1,2}[a-zA-Z0-9]{2,}$/, 'Vehicle Number should be valid alphanumeric number without space.'),
  transporter: Yup.string().required("This field is Required"),
  driver_name: Yup.string().required("This field is Required").matches(/^[a-zA-Z\s]+$/, 'Invalid name'),
  driver_licence: Yup.string().required("This field is Required").matches(/^[a-zA-Z0-9]+$/, 'Invalid License'),
  driver_contact_number: Yup.string()
    .required("This field is Required")
    .matches(/^[5-9]\d{2}\d{3}\d{4}$/, 'Contact number is not valid'),
  // .min(10, "Contact number should be 10 digits")
  // .max(10, "Contact number should be 10 digits"),

  truck_capacity: Yup.string().required("This field is Required"),
  transporter_number: Yup.string().matches(/^[5-9]\d{2}\d{3}\d{4}$/, 'Contact number is not valid'),
});

const validationsTaxationDetails = Yup.object().shape({
  cess_rate: Yup.string().required("This field is Required"),
  fright: Yup.string().required("This field is Required"),
  // lr_number: Yup.string().required("This field is Required"),
  fright_advance: Yup.string().required("This field is Required"),
  mode_of_transport: Yup.string().required("This field is Required"),
  // tcs_amount: Yup.string().required("This field is Required"),
});

const validationsAddItems = Yup.object().shape({
  item_qty: Yup.string().required("This field is Required").matches(/^[0-9]+.[0-9]{0,3}$/, "Positive value upto 3 decimal places."),
  item: Yup.string().required("This field is Required"),
});

export {
  validationsOrderDetails,
  validationsLogisticDetails,
  validationsTaxationDetails,
  validationsAddItems,
};
