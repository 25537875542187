import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Button, Tabs, Tab } from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  primaryColor,
  whiteSmoke,
  drawerTextColor,
  successButtonBackground,
} from "../../../../../constants/internal/colors";
import ContractDetailsAddEditTab from "./ContractDetailsAddEditTab";
import PlantPlanningTab from "./PlantPlanningTab";
import OrderConfirmationScreen from "./OrderConfirmationScreen";
import Bread from "../../../common/Breadcrubs";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import SnackbarComponent from "../../../common/SnackbarComponent";
import { pxToEm, calculatePercent, checkApiError, checkBrokerage, checkInvalidValue } from "../../../../../methods";
import moment from "moment";
import { debounce } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { white } from "../../../../../styles/colors";


const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  headerRow: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    justifyContent: "space-between",
  },
  displayData: {
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
  cancelButton: {
    marginLeft: 20,
  },
  tabStyle: (tabValue, currentTab) => ({
    backgroundColor: whiteSmoke,
    color: tabValue === currentTab ? grey : secondaryGrey,
    fontSize: pxToEm(15),
  }),
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    margin: "0px 10px 0px 20px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
  },
  displayFlex: {
    paddingTop: 10,
  },
};

const validOrderQuantityLimit = 10000;

// const freighBasis = [
//   { id: "FOR", label: "FOR" },
//   { id: "TO PAY", label: "TO PAY" },
// ]

class AddContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: "",
      rates: 0,
      originalRate: 0,
      isAllowedRateZero: false,
      quantity: 0,
      orderValidity: 10,
      orderValidityDate: this.generateOrderValidityDate(new Date(), 10),
      showCustomerDetail: false,
      openCustomerListPopper: false,
      customer: [
        {
          id: 4,
          first_name: "Ramesh",
          last_name: "Traders",
          phone: "909090909090",
          gstin_number: "WWR434343EEE222",
          pan_card_number: "343EEE222",
        },
        {
          id: 2,
          first_name: "Akash",
          last_name: "Traders",
          phone: "8088878787",
          gstin_number: "S333ERRW#3333",
          pan_card_number: "ERRW#3333",
        },
        {
          id: 2,
          first_name: "Rahul",
          last_name: "Traders",
          phone: "8088878787",
          gstin_number: "S333ERRW#3333",
          pan_card_number: "ERRW#3",
        },
      ],

      // new ui states
      tabValue: 0,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      contractOperation: null,
      adminApprovalCheckbox: false,
      allowBreakingFifoCheckbox: false,
      customerApprovalCheckbox: false,
      adminRemark: null,
      orderValidity: 10,
      selectedCustomerData: null,
      showCustomerDetailLoader: false,
      selectedCustomerPaymentTermsAllotted: [],
      selectedCustomerPaymentTermsNotAllotted: [],
      selectedCustomerItemCategoriesSubscribed: [],
      selectedCustomerItemCategoriesNotSubscribed: [],
      paymentTerm: null,
      itemCategory: null,
      itemCategoryId: null,
      tag: null,
      plantPlanningData: [],
      laTruckCapacity: 0,
      consigneeSelectionList: [],
      buyerSelectionList: [],
      specialDiscount: 0,
      specialPremium: 0,
      totalDiscount: 0,
      selectedCustomerDiscount: 0,
      selectedBuyer: null,
      selectedConsignee: null,
      selectedBroker: null,
      brokerSelectionList: [],
      brokerage: 0,
      new_brokerage: 0,
      selectedItemCategoryBrokerage: 0,
      disableUserToInputRate: false,
      currentOrderId: null,
      customerRemark: null,
      disableLoadingPremium: false,
      disableInsurancePremium: false,
      loadingPremiumErrorMsg: false,
      loadingPremiumError: false,
      insurancePremiumErrorMsg: false,
      insurancePremiumError: false,
      displayOrderConfirmationScreen: false,
      quantityError: false,
      ratesError: false,
      rateErrorMessage: "",
      specialDiscountError: false,
      specialPremiumError: false,
      brokerageError: false,
      plantSelectionList: [],
      tcsRate: 0,
      tcsAmount: 0,
      // tcs_percent: 0,
      freightChargeRate: 0,
      freightChargeAmount: 0,
      partyRefNo: null,
      partyRefDate: moment(new Date()).format("YYYY-MM-DD"),
      allTcsRates: [],
      // [
      //   { id: 1, tcs_percent: 0.075 },
      //   { id: 2, tcs_percent: 0.5 },
      //   { id: 3, tcs_percent: 1.02 },
      //   { id: 4, tcs_percent: 1.075 },
      // ],
      remainingPlantPlanningQuantity: 0,
      itemDailyRateId: null,
      showAddNewButtonInPlantPlanning: true,
      updateBrokerage: false,
      freight_basis: null,
      publishAt: null,
      loadingPremium: 0,
      insurancePremium: 0,
      salesPerson: null,
      isLoading: false,
      finishBtnClicked: false,
    };
  }
  handleTextChangeBrokerage = (event) => {
    this.setState({ new_brokerage: event.target.value }, () => {
      if (checkBrokerage(event.target.value)) {
        if (event.target.value === "") {
          this.setState({ brokerageError: `Enter brokerage amount b/w 0-9999` });
        } else if (!checkInvalidValue(this.state?.itemCategory)) {
          if (Number(event.target.value) === Number(this.state?.selectedItemCategoryBrokerage) || Number(event.target.value) === Number(0))
            this.setState({ brokerageError: false })
          else
            this.setState({ brokerageError: `Entered Value should be ${this.state?.selectedItemCategoryBrokerage} or 0` });

        }
        else {
          this.setState({ brokerageError: false });
        }
      }
      else {
        this.setState({ brokerageError: `Enter brokerage amount b/w 0-9999` });
      }
    });
  };
  handleUnlockBrokerage = () => {
    this.setState({
      updateBrokerage: !this.state.updateBrokerage,
    });
  };
  generateOrderValidityDate = (dateObj, numberOfDaysToAdd) => {
    dateObj.setDate(dateObj.getDate() + Number(numberOfDaysToAdd));
    return dateObj;
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleChange = (name) => (event) => {
    const { value, checked } = event.target;
    if (
      name === "adminApprovalCheckbox" ||
      name === "allowBreakingFifoCheckbox" ||
      name === "customerApprovalCheckbox"
    ) {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
    if (name === "contact" && value !== "") {
      this.setState({ openCustomerListPopper: true });
      this.handleFetchSearchCustomerOptions(value);
    } else if (name === "contact" && (value !== "" || !value)) {
      this.handleResetAllStates();
    } else if (name === "orderValidity") {
      let toDayDate = new Date();
      this.setState({
        orderValidityDate: this.generateOrderValidityDate(
          toDayDate,
          !value ? 0 : value
        ),
      });
    } else if (name === "specialDiscount") {
      let currentTotalDiscount =
        Number(this?.state?.selectedCustomerDiscount) + Number(value);
      this.setState({
        totalDiscount: currentTotalDiscount,
        specialDiscountError: Number(value) < 0 ? true : false,
      });
    } else if (name === 'specialPremium') {
      this.setState({
        specialPremiumError: Number(value) < 0 ? true : false,
      });
    } else if (name === "quantity") {
      this.setState({
        quantityError:
          Number(value) > validOrderQuantityLimit || Number(value) <= 0
            ? true
            : false,
        remainingPlantPlanningQuantity: Number(value) + 1,
      });
    } else if (name === "rates") {
      const originalRt = isNaN(this.state.originalRate) || this.state.originalRate === null ? "" : this.state.originalRate;
      console.log(originalRt);
      let errorMessage = "";
      if (Number(value) <= 0 && !this.state.isAllowedRateZero) {
        errorMessage = "Invalid input rate"
      } else if (Number(value) < Number(originalRt)) {
        errorMessage = "Input rate should not be less than published rate."
      }
      this.setState({
        ratesError: (Number(value) <= 0 && !this.state.isAllowedRateZero) || Number(value) < Number(originalRt) ? true : false,
        rateErrorMessage: errorMessage
      });
    } else if (name === "loadingPremium") {
      let errorMessage = "";
      if (Number(value === "" || Number(value) < 0)) {
        errorMessage = "Invalid Input field"
        this.setState({
          loadingPremiumError: Number(value === "" || Number(value) < 0),
          loadingPremiumErrorMsg: errorMessage
        });
      }
      else if (!(value.match(/^\d*\.?\d{0,2}$/))) {
        this.setState({
          loadingPremiumError: !(value.match(/^\d*\.?\d{0,2}$/)),
          loadingPremiumErrorMsg: "Enter upto 2 decimal digit"
        });
      }
      else {
        this.setState({
          loadingPremiumError: false,
          loadingPremiumErrorMsg: ""
        })
      }
    } else if (name === "insurancePremium") {
      let errorMessage = "";
      if (Number(value === "" || Number(value) < 0)) {
        errorMessage = "Invalid Input field"
        this.setState({
          insurancePremiumError: (Number(value === "" || Number(value) < 0)),
          insurancePremiumErrorMsg: errorMessage
        });
      }
      else if (!(value.match(/^\d*\.?\d{0,2}$/))) {
        this.setState({
          insurancePremiumError: !(value.match(/^\d*\.?\d{0,2}$/)),
          insurancePremiumErrorMsg: "Enter upto 2 decimal digit"
        });
      }
      else {
        this.setState({
          insurancePremiumError: false,
          insurancePremiumErrorMsg: ""
        });
      }
    } else if (name === "brokerage") {
      this.setState({
        brokerageError: Number(value) < 0 ? `Enter brokerage amount b/w 0-9999` : false,
      });
    } else if (name === "itemCategory") {
      if (!value) {
        console.log("undefined value ");
      } else {
        const itemData = value.split("/");
        console.log(itemData);
        let disableUserToInputRate =
          itemData[6] === "Subscribed" ? true : false;
        const disableLoadingPremium = itemData[7] > 0 ? true : false;
        const disableInsurancePremium = itemData[8] > 0 ? true : false;

        this.setState({
          itemCategoryId: Number(itemData[0]),
          rates: Number(itemData[1]) > 0 ? Number(itemData[1]).toFixed(3) : 0,
          originalRate: Number(itemData[1]).toFixed(3),
          disableUserToInputRate,
          itemDailyRateId: Number(itemData[2]),
          brokerage: Number(itemData[3]) || 0,
          publishAt: itemData[4],
          new_brokerage: Number(itemData[3]) || 0,
          selectedItemCategoryBrokerage: Number(itemData[3]) || 0,
          ratesError: false,
          loadingPremiumError: false,
          insurancePremiumError: false,
          isAllowedRateZero: itemData[5],
          loadingPremium: Number(itemData[7]) > 0 ? Number(itemData[7]).toFixed(3) : 0,
          insurancePremium: Number(itemData[8]) > 0 ? Number(itemData[8]).toFixed(3) : 0,
          disableLoadingPremium,
          disableInsurancePremium
        });
      }
    }
    // else if (name === "tcsRate") {
    //   this.setState({
    //     tcsAmount: parseFloat(
    //       calculatePercent(
    //         value.tcs_percent,
    //         Number(this.state.rates) * Number(this.state.quantity)
    //       )
    //     ).toFixed(3),
    //     tcsRate: value,
    //     tcs_percent: value.tcs_percent,
    //   });
    // } 
    else if (name === "freightChargeRate") {
      // this.setState({
      //   freightChargeAmount: parseFloat(
      //     calculatePercent(
      //       value,
      //       Number(this.state.rates) * Number(this.state.quantity)
      //     )
      //   ).toFixed(3),
      // });
      this.setState({
        freightChargeAmount: parseFloat(Number(this.state.freightChargeRate) * Number(this.state.quantity)).toFixed(3)
      });
    }
  };
  handleDateChange = (date) => {
    if (date !== "Invalid Date") {
      this.setState({ partyRefDate: date });
    } else {
      this.setState({ partyRefDate: null });
    }
  };
  handleResetAllStates = () => {
    this.setState({
      contact: "",
      rates: 0,
      loadingPremium: 0,
      insurancePremium: 0,
      originalRate: 0,
      ratesError: false,
      loadingPremiumError: false,
      insurancePremiumError: false,
      quantity: 0,
      quantityError: false,
      orderValidity: 10,
      orderValidityDate: this.generateOrderValidityDate(new Date(), 10),
      showCustomerDetail: false,
      openCustomerListPopper: false,
      // new ui states
      allowBreakingFifoCheckbox: false,
      customerApprovalCheckbox: false,
      adminRemark: null,
      selectedCustomerData: null,
      selectedCustomerPaymentTermsAllotted: [],
      selectedCustomerPaymentTermsNotAllotted: [],
      selectedCustomerItemCategoriesSubscribed: [],
      selectedCustomerItemCategoriesNotSubscribed: [],
      paymentTerm: null,
      itemCategory: null,
      itemCategoryId: null,
      plantPlanningData: [],
      laTruckCapacity: 0,
      consigneeSelectionList: [],
      buyerSelectionList: [],
      specialDiscount: 0,
      specialPremium: 0,
      totalDiscount: 0,
      selectedCustomerDiscount: 0,
      selectedBuyer: null,
      selectedConsignee: null,
      selectedBroker: null,
      brokerSelectionList: [],
      brokerage: 0,
      new_brokerage: 0,
      disableUserToInputRate: false,
      currentOrderId: null,
      customerRemark: null,
      disableLoadingPremium: false,
      disableInsurancePremium: false,
      displayOrderConfirmationScreen: false,
      specialDiscountError: false,
      specialPremiumError: false,
      brokerageError: false,
      plantSelectionList: [],
      //tcsRate: 0,
      tcsAmount: 0,
      // tcs_percent: 0,
      freightChargeRate: 0,
      freightChargeAmount: 0,
      remainingPlantPlanningQuantity: 0,
      itemDailyRateId: null,
      publishAt: null,
      finishBtnClicked: false,
    });
  };
  handleSearchSalesPerson = (e, newInputValue, data) => {
    console.log(newInputValue)
    if (!newInputValue) {
      // console.log("null 1")
      this.setState({ salesPerson: null })
    }
    else if (newInputValue) {
      const user = data.find((e) => e.fullName === newInputValue);
      if (user) {
        console.log(user.id)
        this.setState({ salesPerson: user.id })
      }
    }
  }
  handleAutoCompleteSearch = (e, newInputValue, data, field, searchField) => {
    console.log(newInputValue)
    if (!newInputValue) {
      this.setState({ [field]: null })
    }
    else if (newInputValue) {
      const user = data.find((e) => e[searchField] === newInputValue);
      if (user) {
        console.log(user.id, field, newInputValue)
        this.setState({ [field]: user.id })
      }
    }
  }
  handleAutocompleteValueChange = (name) => (event, value) => {
    if (!value) {
      this.setState({ [name]: null });
      if (name === "selectedBroker") {
        this.setState({ brokerage: 0, new_brokerage: 0 });
      }
    } else {
      this.setState({ [name]: value });
      if (name === "selectedBroker") {
        this.setState({
          brokerage: !value.brokerage ? 0 : value.brokerage,
          new_brokerage: !value.brokerage ? 0 : value.brokerage,
        });
      }
    }
  };
  handleFetchSearchCustomerOptions = (searchValue) => {
    this.props
      .fetchSearchCustomerOptions(searchValue)
      .then((response) => {
        if (response.status === 200 && response.data.count > 0) {
          this.setState({
            customer: response.data.results,
            customerSearchOptionEmpty: false,
          });
        } else {
          this.setState({
            customerSearchOptionEmpty: true,
            customer: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          customerSearchOptionEmpty: true,
          customer: [],
        });
      });
  };
  handleCustomSetState = (name, value) => {
    this.setState({ [name]: value });
    if (name === "selectedCustomerData") {
      this.setState(
        {
          showCustomerDetailLoader: true,
          selectedCustomerDiscount: value?.customer_discount || 0,
        },
        () => {
          this.setState({
            brokerage: this.state.selectedCustomerData.brokerage,
          });
        }
      );
      this.fetchSelectedCustomerRelatedDetails(value?.id);
    }
  };
  fetchSelectedCustomerRelatedDetails = async (customerId) => {
    await this.handleFetchBuyers(customerId);
    await this.handleFetchConsignees(customerId);
    await this.fetchSelectedCustomerItemCategories(customerId);
    await this.fetchSelectedCustomerPaymentTerms(customerId);
    await this.fetchTcsData();
    await this.handleFetchBrokers();
    await this.props.fetchInternalUsers();
    await this.setState({ showCustomerDetailLoader: false });
  };
  fetchSelectedCustomerPaymentTerms = (customerId) => {
    this.props
      .fetchCustomerPaymentTerms(customerId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            selectedCustomerPaymentTermsAllotted:
              response.data.allotted_payment_method,
            selectedCustomerPaymentTermsNotAllotted:
              response.data.not_allotted_payment_method,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching payment terms for customer",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching payment terms for customer",
          snackbarType: "error",
        });
      });
  };
  fetchSelectedCustomerItemCategories = (customerId) => {
    this.props
      .fetchItemCategories(customerId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            selectedCustomerItemCategoriesSubscribed: response.data.subscribed,
            selectedCustomerItemCategoriesNotSubscribed:
              response.data.unsubscribed,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching item categories",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching item categories",
          snackbarType: "error",
        });
      });
  };
  handleFetchBuyers = (customerId) => {
    this.props
      .fetchBuyers(customerId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ buyerSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching buyers list",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching buyers list",
          snackbarType: "error",
        });
      });
  };
  fetchTcsData = () => {
    this.props
      .fetchTcs()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allTcsRates: response.data,
            // tcs_percent: response.data[0].id,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching tcs data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching tcs data",
          snackbarType: "error",
        });
      });
  };
  handleFetchBrokers = () => {
    this.props
      .fetchBrokers()
      .then((response) => {
        if (response.status === 200) {
          this.setState({ brokerSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.data?.msg
                  ? response?.data?.msg
                  : "Error occured while fetching brokers list",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : error?.data?.msg
                ? error?.data?.msg
                : "Error occured while fetching brokers list",
          snackbarType: "error",
        });
      });
  };
  handleFetchConsignees = (customerId) => {
    this.props
      .fetchConsignees(customerId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ consigneeSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching consignee list",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : error?.data
                ? error?.data
                : "Error occured while fetching consignee list",
          snackbarType: "error",
        });
      });
  };
  handleClosePopper = () => {
    this.setState({ openCustomerListPopper: false });
  };
  handleTabChange = (event, tabValue) => {
    if (!this.state.currentOrderId) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "Contract must be created before plant planning",
        snackbarType: "warning",
      });
    } else {
      this.setState({ tabValue });
    }
  };
  handleSaveAndNextClick = debounce((e) => {
    this.setState({
      isLoading: true
    })
    let currentTabValue = this.state.tabValue;
    if (currentTabValue === 0) {
      let orderData = {
        rate: this?.state?.rates == "NaN" ? 0 : this?.state?.rates,
        loading_premium: this?.state?.loadingPremium == "NaN" ? 0 : Number(this?.state?.loadingPremium).toFixed(2),
        insurance_premium: this?.state?.insurancePremium == "NaN" ? 0 : Number(this?.state?.insurancePremium).toFixed(2),
        quantity: this?.state?.quantity,
        order_validity: this?.state?.orderValidityDate,
        internal_remark: this?.state?.adminRemark,
        customer_remark: this?.state?.customerRemark,
        is_approved: this?.state?.adminApprovalCheckbox,
        //is_customer_approval_needed: this?.state?.isClientApproved,
        is_fifo_allowed: this?.state?.allowBreakingFifoCheckbox,
        is_customer_approval_needed: this?.state?.customerApprovalCheckbox,
        payment_status: "",
        brokerage: this?.state?.brokerage,
        new_brokerage: this?.state?.new_brokerage,
        daily_rate: this?.state?.itemDailyRateId, //23, //published daily rate id to be passed here after field with value updated.
        customer: this?.state?.selectedCustomerData?.id,
        broker: this?.state?.selectedBroker?.id,
        buyer: this?.state?.selectedBuyer?.id == undefined ? null : this?.state?.selectedBuyer?.id,
        consignee: this?.state?.selectedConsignee?.id == undefined ? null : this?.state?.selectedConsignee?.id,
        item_category: this?.state?.itemCategoryId,
        // item_category_whole: this?.state?.itemCategory,
        payment_method: this?.state?.paymentTerm,
        special_discount: this?.state?.specialDiscount == '' ? 0 : this?.state?.specialDiscount,
        special_premium: this?.state?.specialPremium == '' ? 0 : this?.state?.specialPremium,
        party_ref_number: this?.state?.partyRefNo,
        party_ref_date: moment(this?.state?.partyRefDate).format("YYYY-MM-DD"),
        // tcs_percent: this?.state?.tcsRate.id,
        freight_charge_percent: this?.state?.freightChargeRate,
        freight_charge_amount: Number(this?.state?.quantity * this?.state?.freightChargeRate).toFixed(2),
        sales_person: this.state?.salesPerson == null ? null : this.state?.salesPerson,
        freight_basis: this.state?.freight_basis == null ? null : this.state?.freight_basis,
        tag: this.state?.tag == null ? null : this.state?.tag,
      };
      // console.log(orderData)
      this.handleCreateOrder(orderData);
    } else if (currentTabValue === 1) {
      // this.setState({ displayOrderConfirmationScreen: true });
      let plantPlanningData = this?.state?.plantPlanningData.map((data) => {
        delete data.allottedError;
        return data;
      });
      this.handleSavePlantPlanningData(plantPlanningData);
    }
  }, 500);
  handleViewOrderDetails = () => {
    this.setState({ displayOrderConfirmationScreen: false }, () =>
      this.props.history.push(
        `/internalContracts/singleContracts/${this?.state?.currentOrderId}`
      )
    );
  };
  addNewPlantPlanningData = () => {
    this.setState((previousState) => {
      return {
        plantPlanningData: [
          ...previousState.plantPlanningData,
          {
            order: this?.state?.currentOrderId,
            plant: null,
            allotted_qty: 0,
            dispached_qty: 0,
            pending_qty: 0,
            allottedError: null,
          },
        ],
      };
    });
  };
  removeNewPlantPlanningData = (plantPlanningDataIndex) => {
    this.setState((previousState) => {
      const plantPlanningData = [...previousState.plantPlanningData];
      plantPlanningData.splice(plantPlanningDataIndex, 1);
      return { plantPlanningData };
    });
  };
  handlePlantPlanningDataValueChange = (index, name) => async (event) => {
    const { value } = event.target;
    if (name === "allotted_qty") {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          [name]: value,
          ["pending_qty"]: value,
        };
        return { plantPlanningData };
      });
    } else {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          [name]: value,
        };
        return { plantPlanningData };
      });
    }
  };
  checkAllotedValue = (index, name) => async (event) => {
    const { value } = event.target;

    let totalPlantPlaningQuantity = this?.state?.plantPlanningData
      .map((o) => o.allotted_qty)
      .reduce((a, c) => {
        return Number(a) + Number(c);
      });

    let totalOrderQuantity = Number(this?.state?.quantity);

    if (totalPlantPlaningQuantity > totalOrderQuantity) {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          [name]: 0,
          ["allottedError"]:
            Math.abs(totalPlantPlaningQuantity - value - totalOrderQuantity) ===
              0
              ? "all quantity already assigned"
              : `Only ${Math.abs(
                totalPlantPlaningQuantity - value - totalOrderQuantity
              )} MT are left allot`,
        };
        return { plantPlanningData };
      });
      await this.setState({ showAddNewButtonInPlantPlanning: false }, () =>
        console.log(1)
      );
    } else if (totalPlantPlaningQuantity === totalOrderQuantity) {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          ["allottedError"]: null,
        };
        return { plantPlanningData };
      });
      await this.setState({ showAddNewButtonInPlantPlanning: false }, () =>
        console.log(1)
      );
    } else {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          ["allottedError"]: null,
        };
        return { plantPlanningData };
      });
      await this.setState({ showAddNewButtonInPlantPlanning: true }, () =>
        console.log(1)
      );
    }
  };
  handleCreateOrder = (orderData) => {
    let currentTabValue = this.state.tabValue;
    this.props
      .createOrder(orderData)
      .then((response) => {
        if (response.status === 201) {
          this.setState({
            currentOrderId: response.data.id,
            openSnackbar: true,
            snackbarMessage: "Contract created successfully",
            snackbarType: "success",
          });
          this.handleFetchPlantsForSelectedItemCategory();
          this.props?.onOrderAdded();
          this.props.history.push({
            pathname: `/internalContracts/singleContracts/${response.data.id}`,
            state: { tabValue: 1 }
          });
        } else {
          this.setState({
            openSnackbar: true,
            isLoading: false,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while creating contract",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 5000);

      });
  };
  handleSavePlantPlanningData = (plantPlanningData) => {
    this.props
      .savePlantPlanningData(plantPlanningData)
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push(
            `/internalContracts/singleContracts/${this?.state?.currentOrderId}`
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while saving plant planning data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while saving plant planning data",
          snackbarType: "error",
        });
      });
  };
  handleFetchPlantsForSelectedItemCategory = () => {
    let itemCategoryId = this?.state?.itemCategoryId;
    this.props
      .fetchPlantsForItemCategory(itemCategoryId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ plantSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching plants",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching plants",
          snackbarType: "error",
        });
      });
  };
  checkDataValidityForSaveAndTabChange = (currentTabValue, ratesError, insurancePremiumError, loadingPremiumError, quantityError) => {
    if (currentTabValue === 0) {
      if (ratesError
        || insurancePremiumError
        || loadingPremiumError
        || quantityError
        || (!this?.state?.quantity || this?.state?.quantity === "")
        || (!this?.state?.orderValidityDate || this?.state?.orderValidityDate === "")
        || (!this?.state?.selectedCustomerData?.id || this?.state?.selectedCustomerData?.id === "")
        // || (!this?.state?.selectedBuyer?.id || this?.state?.selectedBuyer?.id === "")
        // || (!this?.state?.selectedConsignee?.id || this?.state?.selectedConsignee?.id === "")
        || (!this?.state?.itemCategoryId || this?.state?.itemCategoryId === "")
        || (!this?.state?.paymentTerm || this?.state?.paymentTerm === "")
        || (!this?.state?.salesPerson || this.state?.salesPerson === "")
        || (!this?.state?.freight_basis || this.state?.freight_basis === "")
        || this.state.brokerageError
        || this.state.updateBrokerage
      ) {
        return true;
      }
      if (this?.state.itemCategory?.split("/")[5] == "false") {
        if (this?.state?.rates == 0 || this?.state?.rates == "NaN") {
          return true
        }
      }
      return false;

    };
  }
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  render() {
    const {
      tabValue,
      openSnackbar,
      snackbarMessage,
      snackbarType,
      displayOrderConfirmationScreen,
    } = this.state;
    const { classes, isMobile } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <Bread
              data={[
                {
                  title: "Contracts",
                  onClick: () => this.props.history.push(INTERNALROUTES.ORDERS),
                },
                {
                  title: "Create New",
                  onClick: "",
                },
              ]}
            />
            <div className={classes.displayData}>
              {displayOrderConfirmationScreen ? (
                <OrderConfirmationScreen
                  {...this.state}
                  handleViewOrderDetails={this.handleViewOrderDetails}
                />
              ) : (
                <>
                  <div className={classes.headerRow}>
                    <>
                      <Tabs
                        TabIndicatorProps={{
                          style: {
                            borderBottom: `2px solid ${primaryColor}`,
                            colur: grey,
                          },
                        }}
                        value={tabValue}
                        onChange={this.handleTabChange}
                        style={styles.tabStyle(3, 4)}
                        variant="scrollable"
                      >
                        <Tab
                          label={"CONTRACT DETAILS"}
                          style={styles.tabStyle(tabValue, 0)}
                        />
                        <Tab
                          label={"PLANT PLANNING"}
                          style={styles.tabStyle(tabValue, 1)}
                        />
                      </Tabs>
                    </>
                  </div>
                  {tabValue === 0 && (
                    <ContractDetailsAddEditTab
                      {...this.state}
                      {...this.props}
                      isMobile={isMobile}
                      handleChange={this.handleChange}
                      handleSearchSalesPerson={this.handleSearchSalesPerson}
                      handleAutoCompleteSearch={this.handleAutoCompleteSearch}
                      handleRateChange={this.handleRateChange}
                      handleClosePopper={this.handleClosePopper}
                      handleCustomSetState={this.handleCustomSetState}
                      handleAutocompleteValueChange={
                        this.handleAutocompleteValueChange
                      }
                      handleDateChange={this.handleDateChange}
                      handleTextChangeBrokerage={this.handleTextChangeBrokerage}
                      handleUnlockBrokerage={this.handleUnlockBrokerage}
                    />
                  )}
                  {tabValue === 1 && (
                    <PlantPlanningTab
                      {...this.state}
                      handleChange={this.handleChange}
                      addNewPlantPlanningData={this.addNewPlantPlanningData}
                      removeNewPlantPlanningData={
                        this.removeNewPlantPlanningData
                      }
                      handlePlantPlanningDataValueChange={
                        this.handlePlantPlanningDataValueChange
                      }
                      checkAllotedValue={this.checkAllotedValue}
                    />
                  )}
                  {this?.state?.showCustomerDetail ? (
                    <div className={classes.displayFlex}>
                      <Button
                        disabled={this.checkDataValidityForSaveAndTabChange(
                          tabValue, this.state.ratesError, this.state.insurancePremiumError, this.state.loadingPremiumError, this.state.quantityError
                        )}
                        size="medium"
                        className={classes.saveButton}
                        variant="contained"
                        startIcon={<CheckOutlinedIcon />}
                        onClick={(e) => {
                          e.persist()
                          if (!this.state.finishBtnClicked) {
                            console.log("clicked FINISH 2")
                            this.setState({ finishBtnClicked: true })
                            this.handleSaveAndNextClick(e);
                          }
                        }}
                      >
                        SAVE{tabValue === 1 ? null : " & NEXT"}
                        {this.state.isLoading &&
                          (
                            <CircularProgress
                              size={15}
                              style={{ marginLeft: "10px", color: white }}
                            />
                          )}
                      </Button>
                      <Button
                        size="medium"
                        className={classes.cancelButton}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(INTERNALROUTES.ORDERS);
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  ) : (
                    false
                  )}
                </>
              )}
            </div>
            <SnackbarComponent
              open={openSnackbar}
              handleClose={this.handleSnackbarClose}
              snackbarMessage={snackbarMessage}
              type={snackbarType}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(withStyles(styles)(AddContracts));
