import React, { Component } from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import DailyRatesWeb, {
  DailyRatesMobile,
} from "../../../components/internal/dailyRates/index";
import AccessDenied from "../../../components/common/AccessDenied";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../../components/internal/common/SnackbarComponent";
import { checkApiError, isAccessibleModule, isAccessibleWidget } from '../../../methods';
import WebSocketWrapper from "../../../components/internal/dailyRates/web/webSocketComponent";
import HOST from "../../../constants/common/host";

class DailyRatesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalTemlateValues: [],
      originalDailyRatesOpenRateValues: [],
      originalTemlateValuesBackup: [],
      liveRateValues: [],
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      pastRateValues: [],
      filteredPastRatesData: [],
      PastRatesDataCount: null,
      filteredPastRatesLoader: false,
      csvPastRatesData: [],
      csvPastRatesLoader: true,
      openRatesLoader: true,
      pastRatesLoader: true,
      liveRatesLoader: true,
      userSelectedCustomerId: null,
      socketReponse: []
    };
    this.handleMessage = this.handleMessage.bind(this);
  }
  handleMessage(message) {
    console.log(message)
    if (message.length > 1)
      this.setState({
        socketReponse: message
      }, () => {
        if (isAccessibleModule(this.props.user, "Daily Rates")) {
          this.fetchOriginalTemlateValues();
          this.fetchOriginalDailyRatesOpenRateValues();
          this.fetchLiveRateValues();
          this.fetchPastRateValues();
        }
      })

  }

  componentDidMount() {
    if (isAccessibleModule(this.props.user, "Daily Rates")) {
      this.fetchOriginalTemlateValues();
      this.fetchOriginalDailyRatesOpenRateValues();
      this.fetchLiveRateValues();
      this.fetchPastRateValues();
    }
  }

  fetchPastRateValues = () => {
    axios
      .get(APIROUTES.DAILY_RATE_TEMPLATE_DETAILS, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              pastRateValues: response.data,
              pastRatesLoader: false,
            });
          } else {
            this.setState({
              pastRatesLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching data for Past Rates please try again.",
              snackbarType: "error",
            });
          }
        },
        (error) => {
          this.setState({
            pastRatesLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching data for Past Rates please try again.",
            snackbarType: "error",
          });
        }
      );
  };

  fetchOriginalTemlateValues = () => {
    const { accessibleWidgets } = this.props?.user;
    // const isAccessibleWidget = (id) =>
    //   accessibleWidgets?.find((widget) => widget.linked_to === id);
    axios
      .get(APIROUTES.DAILY_RATE_TEMPLATE_DETAILS, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            const templates = [];
            for (let template of response.data) {
              if (
                isAccessibleWidget(this.props.user, `${template.category_name}-DailyRates`)
                // isAccessibleWidget(template.id)?.has_create_permission ||
                // isAccessibleWidget(template.id)?.has_read_permission
              )
                templates.push(template);
            }
            this.setState({
              originalTemlateValues: templates,
              openRatesLoader: false,
            });
            localStorage.setItem(
              "originalTemlateValuesBackup",
              JSON.stringify(response.data)
            );
          } else {
            this.setState({
              openRatesLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching data for Open Rates please try again.",
              snackbarType: "error",
            });
          }
        },
        (error) => {
          this.setState({
            openRatesLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching data for Open Rates please try again.",
            snackbarType: "error",
          });
        }
      );
  };
  fetchOriginalDailyRatesOpenRateValues = () => {
    axios
      .get(APIROUTES.DAILY_RATE_OPEN_RATE_V2, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              originalDailyRatesOpenRateValues: response.data,
              openRatesLoader: false,
            });
          } else {
            this.setState({
              openRatesLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching data for Open Rates please try again.",
              snackbarType: "error",
            });
          }
        },
        (error) => {
          this.setState({
            openRatesLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching data for Open Rates please try again.",
            snackbarType: "error",
          });
        }
      );
  };
  fetchLiveRateValues = () => {
    axios
      .get(APIROUTES.DAILY_RATE_LIVE_RATE_V2, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              liveRateValues: response.data,
              liveRatesLoader: false,
            });
          } else {
            this.setState({
              liveRatesLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching data for Live Rates please try again.",
              snackbarType: "error",
            });
          }
        },
        (error) => {
          this.setState({
            liveRatesLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching data for Live Rates please try again.",
            snackbarType: "error",
          });
        }
      );
  };
  handleUnpublishDailyRates = (dataToUnpublish, selectedCustomerId) => {
    axios
      .put(
        APIROUTES.DAILY_RATE_UNPUBLISH_V2,
        { id: selectedCustomerId },
        {
          headers: {
            Authorization: `JWT ${this.props.user.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Daily rate un-published successfully.",
            snackbarType: "success",
            userSelectedCustomerId: selectedCustomerId,
          });
          this.fetchOriginalTemlateValues();
          this.fetchOriginalDailyRatesOpenRateValues();
          this.fetchLiveRateValues();
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while un-publishing data please try again.",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleSnackbarClose();
        }, 6000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while un-publishing data please try again.",
        //   snackbarType: "error",
        // });
      });
  };
  handlePublishDailyRates = (dataToPublish, selectedCustomerId) => {
    axios
      .post(APIROUTES.DAILY_RATE_PUBLISH_V2, dataToPublish, {
        headers: {
          Authorization: `JWT ${this.props.user.token}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Daily rate published successfully.",
            snackbarType: "success",
            userSelectedCustomerId: selectedCustomerId,
          });
          this.fetchOriginalTemlateValues();
          this.fetchOriginalDailyRatesOpenRateValues();
          this.fetchLiveRateValues();
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while publishing data please try again.",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleSnackbarClose();
        }, 6000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while publishing data please try again.",
        //   snackbarType: "error",
        // });
      });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  getCsvPastRateData = async (selectedCategories, startDate, endDate) => {
    this.setState({ csvPastRatesLoader: true });
    let url =
      selectedCategories?.length > 0 || startDate !== null || endDate !== null
        ? APIROUTES.DAILY_RATE_PAST_RATE_V2 + "csv/" +
        (selectedCategories?.length > 0
          ? `${selectedCategories.map((categoryId, index) =>
            index === 0 ? `?ids=${categoryId}` : `&ids=${categoryId}`
          )}`
          : "") +
        (!startDate
          ? ""
          : `${selectedCategories.length > 0 ? "&" : "?"}start_date=${moment(
            startDate
          ).format("YYYY-MM-DD")}`) +
        (!startDate
          ? ""
          : `${!startDate ? "?" : "&"}end_date=${moment(
            !endDate ? startDate : endDate
          ).format("YYYY-MM-DD")}`)
        : "";

    let cleanedUrl = url.replace(/,/g, "");
    if (cleanedUrl || cleanedUrl !== "") {
      await axios
        .get(cleanedUrl, {
          headers: {
            Authorization: `JWT ${this.props.user.token}`,
          },
        })
        .then(
          (response) => {
            if (response.status === 200) {
              this.setState({
                csvPastRatesData: response.data,
                csvPastRatesLoader: false,
              });
            } else {
              this.setState({
                openSnackbar: true,
                snackbarMessage: "Error occured while fetching data try again",
                snackbarType: "error",
                csvPastRatesLoader: false,
              });
            }
          },
          (error) => {
            this.setState({
              csvPastRatesData: null,
              csvPastRatesLoader: false,
              openSnackbar: true,
              snackbarMessage: "Error occured while fetching data try again.",
              snackbarType: "error",
            });
          }
        );
    }
  };

  getPastRateFilteredData = async (selectedCategories, startDate, endDate, page) => {
    this.setState({ filteredPastRatesLoader: true, pastRatesLoader: true });
    let url =
      selectedCategories?.length > 0 || startDate !== null || endDate !== null || page
        ? APIROUTES.DAILY_RATE_PAST_RATE_V2 +
        (selectedCategories?.length > 0
          ? `${selectedCategories.map((categoryId, index) =>
            index === 0 ? `?ids=${categoryId}` : `&ids=${categoryId}`
          )}`
          : "") +
        (!startDate
          ? ""
          : `${selectedCategories.length > 0 ? "&" : "?"}start_date=${moment(
            startDate
          ).format("YYYY-MM-DD")}`) +
        (!startDate
          ? ""
          : `${!startDate ? "?" : "&"}end_date=${moment(
            !endDate ? startDate : endDate
          ).format("YYYY-MM-DD")}`) +
        (!page
          ? ""
          : `${!page ? "?" : "&"}page=${page + 1}`)
        : "";

    let cleanedUrl = url.replace(/,/g, "");
    if (cleanedUrl || cleanedUrl !== "") {
      await axios
        .get(cleanedUrl, {
          headers: {
            Authorization: `JWT ${this.props.user.token}`,
          },
        })
        .then(
          (response) => {
            if (response.status === 200) {
              this.setState({
                filteredPastRatesData: response.data,
                pastRatesDataCount: response.data.count,
                filteredPastRatesLoader: false,
                pastRatesLoader: false
              });
            } else {
              this.setState({
                openSnackbar: true,
                snackbarMessage: "Error occured while fetching data try again",
                snackbarType: "error",
                filteredPastRatesLoader: false,
                pastRatesLoader: false
              });
            }
          },
          (error) => {
            this.setState({
              filteredPastRatesData: null,
              filteredPastRatesLoader: false,
              pastRatesLoader: false,
              openSnackbar: true,
              snackbarMessage: "Error occured while fetching data try again.",
              snackbarType: "error",
            });
          }
        );
    }
    this.getCsvPastRateData(selectedCategories, startDate, endDate)
  };

  render() {
    const { openSnackbar, snackbarMessage, snackbarType } = this.state;
    // if (this?.props?.isMobile) return <DailyRatesMobile {...this.props} />;
    // else 
    if (isAccessibleModule(this.props.user, "Daily Rates")) {
      return (
        <>
          <Route
            exact
            path="/internalDailyRates"
            render={(props) => (
              <DailyRatesWeb
                {...props}
                {...this.props}
                {...this.state}
                isMobile={this.props.isMobile}
                handlePublishDailyRates={this.handlePublishDailyRates}
                getPastRateFilteredData={this.getPastRateFilteredData}
                getCsvPastRateData={this.getCsvPastRateData}
                handleUnpublishDailyRates={this.handleUnpublishDailyRates}
              />
            )}
          />
          <WebSocketWrapper
            url={`wss://${HOST}/ws/dailyrates/`}
            onMessage={this.handleMessage}
          />
          <SnackbarComponent
            open={openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={snackbarMessage}
            type={snackbarType}
          />
        </>
      );
    } else {
      return <AccessDenied />;
    }
  }
}
export default DailyRatesContainer;
