import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import COLORS from "../../../../constants/internal/colors";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { Button } from "@material-ui/core";
import ToolTipComponent from "../../common/ToolTipComponent";
import { hasWriteAccess } from "../../../../methods";

const styles = {
  addNewButton: {
    marginTop: 10,
    // width: "14%",
    height: "43px",
    border: `1px dashed ${COLORS.secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: COLORS.grey,
    fontWeight: "bold",
    marginBottom: 15,
    padding: "5px 22px",
  },
  paperStyle: {
    display: 'grid',
    alignItems: 'center',
    alignContent: 'space-between',
    cursor: "pointer",
    padding: "10px",
    width: "12vw",
    height: "100%",
    textAlign: "center",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 11px 0px, rgba(0, 0, 0, 0.14) 0px 1px 20px 0px, rgba(0, 0, 0, 0.12) 0px 1px 0px 0px",
  },
  cardNameContainer: {
    fontWeight: "bold",
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  gateInTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px",
  },
  gateOutTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px",
  },
  activeStatusContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10px",
  },
};
class Cards extends Component {
  handleUpdate = (item) => {
    this.props.handleOpenUpdateDialog(item);
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 style={{ color: COLORS.grey }}>{this.props.title}</h4>
        <div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.props.data.map((item) => (
              <div style={{ margin: "10px" }}>
                <Paper
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleUpdate(item);
                  }}
                  elevation={1}
                  className={classes.paperStyle}
                >
                  <div className={classes.cardNameContainer}>
                    {item.gate_name
                      ? item.gate_name
                      : item.weighbridge_name
                        ? item.weighbridge_name
                        : item.dispatch_counter_name}
                  </div>
                  {this.props.title === "GATES" ? (
                    <div style={{ marginTop: "16px" }}>
                      <div className={classes.gateInTextContainer}>
                        <span>Gate In</span>
                        {item.is_gate_in_allowed ? (
                          <CheckCircle
                            style={{ color: COLORS.successButtonBackground }}
                          />
                        ) : (
                          <Cancel style={{ color: COLORS.orange }} />
                        )}
                      </div>
                      <div className={classes.gateOutTextContainer}>
                        <span>Gate Out</span>
                        {item.is_gate_out_allowed ? (
                          <CheckCircle
                            style={{ color: COLORS.successButtonBackground }}
                          />
                        ) : (
                          <Cancel style={{ color: COLORS.orange }} />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={classes.activeStatusContainer}>
                      <span>Active</span>
                      {item.is_active ? (
                        <CheckCircle
                          style={{ color: COLORS.successButtonBackground }}
                        />
                      ) : (
                        <Cancel style={{ color: COLORS.orange }} />
                      )}
                    </div>
                  )}
                </Paper>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ToolTipComponent
              title="You have no create permission on this widget"
              condition={!hasWriteAccess(this.props.user, 'plant_item_master')}
            >
              <Button
                variant="outlined"
                size="medium"
                startIcon={<CheckOutlinedIcon />}
                className={classes.addNewButton}
                disabled={!hasWriteAccess(this.props.user, 'plant_item_master')}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleAddEvent();
                }}
              >
                ADD HERE
              </Button>
            </ToolTipComponent>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Cards);
