import React from 'react';

const NoChartGraph = () => {
    return (
        <div>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="273.000000pt" height="273.000000pt" viewBox="0 0 273.000000 273.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,273.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M720 1915 l0 -25 140 0 140 0 0 25 0 25 -140 0 -140 0 0 -25z" />
                    <path d="M716 1854 c-11 -29 7 -35 105 -32 86 3 94 5 94 23 0 18 -8 20 -96 23
-83 2 -97 0 -103 -14z"/>
                    <path d="M1887 1724 c-10 -21 -15 -45 -11 -57 5 -15 3 -19 -8 -14 -10 3 -65
-56 -172 -183 -95 -114 -163 -187 -170 -184 -7 3 -25 -9 -40 -26 -28 -34 -32
-45 -16 -55 8 -5 8 -11 0 -20 -15 -18 4 -29 36 -21 l24 6 0 -90 0 -90 -190 0
-189 0 -4 73 -3 72 -2 -72 -2 -73 -190 0 c-145 0 -190 3 -190 13 0 9 -2 9 -8
0 -4 -6 -21 -13 -37 -16 l-30 -4 32 -2 c21 0 33 -7 36 -18 4 -17 5 -17 6 0 1
16 17 17 190 17 l190 0 4 -27 4 -28 2 28 1 27 423 2 c232 0 408 3 390 5 l-32
4 -4 107 -3 107 -2 -107 -2 -108 -190 0 -190 0 0 84 c0 47 -5 97 -10 112 -10
25 -8 30 20 52 16 13 30 29 30 35 0 21 90 128 102 121 7 -4 8 -3 4 4 -9 15
113 164 128 155 6 -3 8 -3 4 2 -8 8 28 66 40 63 4 -1 16 2 27 6 75 28 100 67
71 110 -23 37 -49 33 -69 -10z m53 -54 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
                    <path d="M1554 1605 c0 -55 1 -76 3 -47 2 29 2 74 0 100 -2 26 -3 2 -3 -53z" />
                    <path d="M1160 1612 c0 -67 -3 -80 -21 -92 -24 -17 -54 -70 -40 -70 5 0 13 -9
16 -20 5 -15 15 -20 45 -20 38 0 70 -18 217 -123 36 -25 45 -28 58 -17 14 11
13 15 -7 29 -23 16 -22 16 32 22 l55 7 -62 -2 c-61 -1 -63 0 -162 69 -56 39
-101 77 -101 86 0 8 -5 20 -11 26 -8 8 -11 44 -10 97 1 47 0 86 -3 86 -3 0 -6
-35 -6 -78z"/>
                    <path d="M773 1570 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z" />
                    <path d="M1943 1605 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
                    <path d="M1934 1375 c0 -55 1 -76 3 -47 2 29 2 74 0 100 -2 26 -3 2 -3 -53z" />
                    <path d="M882 1259 c-170 -169 -182 -182 -167 -199 8 -9 13 -24 9 -34 -9 -24
13 -19 42 10 18 17 24 32 21 46 -5 17 25 52 148 176 96 96 152 159 148 168 -3
8 -8 14 -12 14 -3 0 -88 -82 -189 -181z"/>
                    <path d="M1538 1375 c-1 -30 0 -55 5 -55 4 0 7 25 7 55 0 71 -7 71 -12 0z" />
                    <path d="M1154 1305 c0 -55 1 -76 3 -47 2 29 2 74 0 100 -2 26 -3 2 -3 -53z" />
                    <path d="M763 1295 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />
                </g>
            </svg>
        </div>
    );
};

export default NoChartGraph;
