import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    bottomNavigationFontColor,
    dailyRateScreenTextColor,
} from "../../../styles/colors";
import "../../../styles/b2b/DispatchTracking.css";
import Done from "@material-ui/icons/Done";
import moment from "moment";
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { isObjectLike } from "lodash";
import { LensTwoTone } from "@mui/icons-material";
import { pxToEm } from "../../../methods";

const styles = {
    dispatchTrackingMainBody: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "16px",
    },
    container: { width: "90%" },
    textStyle: {
        color: dailyRateScreenTextColor,
        fontSize: window.innerWidth < 375 ? pxToEm(14) : "1.12em"
    },
    dateStyle: {
        color: bottomNavigationFontColor,
        fontSize: window.innerWidth < 375 && pxToEm(12)
    },
};
class DispatchTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            classes,
            loadingAdvice,
            gateIn,
            loadingCompleted,
            dispatched,
            dispatchStatus,
            logDetails,
            laDetails,
            isMobile,
        } = this.props;


        let trackingStatus = new Map()
        logDetails && logDetails.map(log => {
            // console.log(det)
            trackingStatus.set(log.status, log)

        })
        // console.log(trackingStatus)
        return (
            <div className={classes.dispatchTrackingMainBody} style={{ marginBottom: isMobile ? "" : 15 }}>
                <div className={classes.container}>
                    {trackingStatus &&
                        Array.from(trackingStatus.entries()).map(
                            ([key, value]) => (key === "LOADING" || key === "PACKED" ?
                                null :
                                <div className="step completed">
                                    <div className="v-stepper">
                                        {
                                            key === "CANCELLED" ||
                                                key === "LAPSED" ||
                                                key === "REJECTED"
                                                // key === "HOLD" 
                                                ?
                                                <div className="cancel">
                                                    <CloseOutlinedIcon
                                                        style={{ color: "white", height: "0.9em", width: "0.9em" }}
                                                    />
                                                </div>
                                                : <div className="circle">
                                                    <Done
                                                        style={{ color: "white", height: "0.8em", width: "0.8em" }}
                                                    />
                                                </div>
                                        }
                                        {
                                            trackingStatus.size === 1 ?
                                                null :
                                                <div className={key === "CANCELLED" ||
                                                    key === "LAPSED" ||
                                                    key === "REJECTED"
                                                    // key === "HOLD" 
                                                    ? "cancel-line" : "line"}></div>

                                        }

                                    </div>
                                    <div className="content">
                                        <span className={classes.textStyle}>{key}</span>
                                        <span className={classes.dateStyle}>{moment(value?.created_at, "DD-MM-yyyy h:mm A").format("DD-MMM-yyyy h:mm A")}</span>
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(DispatchTracking);
