import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import {
  whiteSmoke,
  earthBlue,
  orange,
  drawerTextColor,
  primaryColor,
} from '../../../../constants/internal/colors';
import moment from 'moment';

import { pxToEm } from '../../../../methods';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';

const productsTabStyles = (theme) => ({
  mainDiv: {
    width: '100%',
  },
  productsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    MsOverflowStyle: '-ms-autohiding-scrollbar',
  },
  singleProduct: {
    display: 'flex',
    flexDirection: 'column',
    width: '13%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    padding: '10px 0px 10px 0px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectedSingleProduct: {
    display: 'flex',
    flexDirection: 'column',
    width: '13%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    padding: '10px 0px 10px 0px',
    backgroundColor: whiteSmoke,
    borderBottom: `2px solid ${earthBlue}`,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  generalSpacing: {
    padding: 15,
    fontSize: pxToEm(18),
  },
  border: {
    marginTop: 10,
    padding: '0px 2px 0px 2px',
    border: '1px solid',
    fontSize: pxToEm(10),
  },
  categoryTitle: {
    display: 'flex',
    placeItems: 'center',
    fontSize: pxToEm(15),
  },
  selectedProductCaption: {
    marginTop: 10,
    padding: '0px 2px 0px 2px',
    backgroundColor: orange,
    color: drawerTextColor,
    fontSize: pxToEm(10),
    fontWeight: 'bold',
  },
  loaderStyle: { color: primaryColor },
  loaderDiv: {
    margin: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignStateText: {
    display: 'flex',
    placeContent: 'flex-start',
  },
  rightSpacing: {
    marginRight: 5,
  },
  linkedToIcon: {
    heigth: '20px',
    width: '20px',
  },
  alignText: {
    display: 'flex',
    height: '20px',
    alignItems: 'center',
  },
});
class ProductsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProductIndex: false,
      date: new Date(),
    };
  }
  componentDidMount() {
    this.handleProductClick(0);
  }
  handleProductClick = (index) => {
    this.setState((state) => ({
      selectedProductIndex:
        index === this.state.selectedProductIndex ? false : index,
    }));
  };
  getLinkedCategoryData = (currentCategory) => {
    let linkedCategory = this.props.productPricesDisplayData.find(
      (categoryData) => categoryData.id === currentCategory.linkedTo
    );
    let linkedCategoryName = !linkedCategory
      ? null
      : linkedCategory.category_name;
    let toolTipTitle = `${currentCategory.category_name} Linked To ${linkedCategoryName} with Rate Difference ₹${currentCategory.linkedToRateDifference}`;
    return toolTipTitle;
  };
  render() {
    const {
      classes,
      handleCategorySelect,
      productPricesDisplayData,
      productPricesDisplayDataLoader,
    } = this.props;
    const { selectedProductIndex, date } = this.state;
    return (
      <div className={classes.mainDiv}>
        {productPricesDisplayDataLoader ? (
          <div className={classes.loaderDiv}>
            <CircularProgress disableShrink className={classes.loaderStyle} />
          </div>
        ) : productPricesDisplayData && productPricesDisplayData.length > 0 ? (
          <>
            <Typography className={classes.generalSpacing}>
              Open Rates - {moment(date).format('DD MMM')}
            </Typography>
            <div id="scroll" className={classes.productsContainer}>
              {productPricesDisplayData.map((value, index) => (
                <div
                  className={
                    selectedProductIndex === index
                      ? classes.selectedSingleProduct
                      : classes.singleProduct
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleProductClick(index);
                    handleCategorySelect(value.id);
                  }}
                  key={value.id}
                >
                  <Tooltip
                    title={
                      !value.linkedTo
                        ? value.category_name
                        : this.getLinkedCategoryData(value)
                    }
                    placement="top"
                    arrow
                  >
                    <Typography
                      className={classes.categoryTitle}
                      component={'span'}
                    >
                      <div className={classes.alignText}>
                        <div className={classes.rightSpacing}>
                          {!value.linkedTo ? (
                            ''
                          ) : (
                            <LinkOutlinedIcon
                              className={classes.linkedToIcon}
                            />
                          )}
                        </div>

                        <div>{value.category_name}</div>
                      </div>
                    </Typography>
                  </Tooltip>
                  <Typography
                    variant="caption"
                    className={
                      value.isPublished
                        ? classes.selectedProductCaption
                        : classes.border
                    }
                  >
                    {value.isPublished
                      ? `BR @ ₹ ${parseFloat(value?.baseHighRate).toFixed(2)}`
                      : 'PENDING'}
                  </Typography>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={classes.loaderDiv}>
            <Typography className={classes.loaderStyle}>
              Category details not found please update master.
            </Typography>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(productsTabStyles)(ProductsTab);
