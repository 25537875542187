import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import DispatchPlanning from "../DispatchPlanning";

class DispatchOperationWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout {...this.props}>
        <DispatchPlanning {...this.props} Title="Dispatch Planning" />
      </Layout>
    );
  }
}
export default DispatchOperationWeb;
