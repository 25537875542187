import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import CustomerBuyerConsigneeDataDisplayTable from "./CustomerBuyerConsigneeDataDisplayTable";
const useStyles = makeStyles({
  paperStyles: { padding: 20 },
  generalSpacing: {
    paddingBottom: 20,
  },
});
export default function ManageCustomerBuyerConsigneeDetailsNewUi(props) {
  const classes = useStyles();
  return (
    // <Paper className={classes.paperStyles}>
    <>
      <div className={classes.generalSpacing}>
        <CustomerBuyerConsigneeDataDisplayTable
          props={props}
          displayTableFor={"buyer"}
        />
      </div>
      <div className={classes.generalSpacing}>
        <CustomerBuyerConsigneeDataDisplayTable
          props={props}
          displayTableFor={"consignee"}
        />
      </div>
    </>
    // </Paper>
  );
}
