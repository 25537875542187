import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
} from "../../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {
  pxToEm,
  checkNameFieldAlphaNumeric,
  checkAlphaNumericLength,
  checkApiError,
  hasUpdateAccess
} from "../../../../../methods";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
// import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarComponent from "../../../common/SnackbarComponent";
import Bread from "../../../common/Breadcrubs";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import RemoveConfirmationDialog from "../../../common/RemoveConfirmationDialog";
import SaveButton from "../../../common/SaveButton";
import ToolTipComponent from "../../../common/ToolTipComponent";
const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  formControlPanImage: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    // width: '32%'
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: {
    color: primaryColor,
  },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function ManagePaymentTerms(props) {
  const classes = useStyles();
  const [paymentTermDataDisplay, setPaymentTermDataDisplay] = React.useState(
    props?.selectedPaymentTermData || {
      payment_method_name: "",
      days: "",
      payment_method_code: "",
      proforma_invoice: "",
      is_vcl_enabled: "",
      is_active: "",
    }
  );
  const [paymentName, setPaymentName] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = React.useState(false);
  const [paymentTermOperation, setPaymentTermOperation] = React.useState(
    props?.paymentTermOperation || "add"
  );
  const [removePaymentTermLoader, setRemovePaymentTermLoader] =
    React.useState(false);
  const [paymentTermNameError, setPaymentTermNameError] = React.useState({
    errorStatus: false,
    errorText: ''
  });
  const [paymentTermDaysError, setPaymentTermDaysError] = React.useState({
    errorStatus: false,
    errorText: ''
  });
  const [paymentTermCodeError, setPaymentTermCodeError] = React.useState({
    errorStatus: false,
    errorText: ''
  });
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [removeConfirmationDialogData, setRemoveConfirmationDialogData] =
    React.useState({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });
  React.useEffect(() => {
    props.paymentTermsNamesLists.map(data =>
      setPaymentName(prevState => [(data.payment_method_name).toUpperCase(), ...prevState])
    )
    setPaymentTermDataDisplay(props.selectedPaymentTermData);
    setPaymentTermOperation(props.paymentTermOperation);
    !checkPaymentName(props?.selectedPaymentTermData?.payment_method_name) ?
      setPaymentTermNameError({ errorStatus: true, errorText: 'Invalid name' }) :
      setPaymentTermNameError({ errorStatus: false, errorText: '' });
    if (props.paymentTermOperation === "view") {
      !checkAlphaNumericLength(props?.selectedPaymentTermData?.payment_method_code) ?
        setPaymentTermCodeError({ errorStatus: true, errorText: 'Invalid Code' }) :
        setPaymentTermCodeError({ errorStatus: false, errorText: '' });
    }
  }, [props.selectedPaymentTermData, props.paymentTermOperation]);
  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
    console.log("payment" + paymentName)
  };

  // React.useEffect(() => {
  //   console.log("name =" + props.selectedPaymentTermData.payment_method_name)
  //   !checkNameFieldAlphaNumeric(props.selectedPaymentTermData.payment_method_name) ?
  //     setPaymentTermNameError({ errorStatus: true, errorText: 'Invalid name' }) :
  //     setPaymentTermNameError({ errorStatus: false, errorText: '' });
  // }, [])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };
  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const checkPaymentName = (input) => {
    const regex = /^[a-zA-Z0-9\s]+[-]?[a-zA-Z0-9\s]+$/;
    if (regex.test(input)) {
      return true
    }
    return false;
  }

  const handleChange = (event) => {
    if (["proforma_invoice", "is_vcl_enabled"].includes(event.target.name)) {
      setPaymentTermDataDisplay({
        ...paymentTermDataDisplay,
        [event.target.name]: event.target.checked,
      });
    }
    else if (event.target.name === "payment_method_name") {
      setPaymentTermDataDisplay({
        ...paymentTermDataDisplay,
        [event.target.name]: event.target.value,
      });
    }
    else if (event.target.name === "days") {
      setPaymentTermDataDisplay({
        ...paymentTermDataDisplay,
        [event.target.name]: event.target.value,
      });
    }
    else if (event.target.name === "payment_method_code") {
      setPaymentTermDataDisplay({
        ...paymentTermDataDisplay,
        [event.target.name]: event.target.value,
      });
    }
    else if (event.target.name === "is_active") {
      setPaymentTermDataDisplay({
        ...paymentTermDataDisplay,
        [event.target.name]: event.target.checked,
      });
    }
    // else {
    //   setPaymentTermDataDisplay({
    //     ...paymentTermDataDisplay,
    //     [event.target.name]: event.target.value.replace(/\D/g, '')
    //   });
    // }

    if (!event.target.value) {
      if (event.target.name === "payment_method_name") {
        setPaymentTermNameError({ errorStatus: true, errorText: 'Invalid name' });
      } else if (event.target.name === "days") {
        setPaymentTermDaysError({ errorStatus: true, errorText: 'Invalid days' });
      } else if (event.target.name === "payment_method_code") {
        setPaymentTermCodeError({ errorStatus: true, errorText: 'Invalid code' });
      }
    } else {
      if (event.target.name === "payment_method_name") {
        !checkPaymentName(event.target.value) ?
          setPaymentTermNameError({ errorStatus: true, errorText: 'Invalid name' }) :
          paymentName.includes((event.target.value).toUpperCase()) ?
            setPaymentTermNameError({ errorStatus: true, errorText: 'Already exist' }) :
            setPaymentTermNameError({ errorStatus: false, errorText: '' })
      } else if (event.target.name === "days") {
        (event.target.value) >= 0 ?
          setPaymentTermDaysError({ errorStatus: false, errorText: '' }) :
          setPaymentTermDaysError({ errorStatus: true, errorText: 'Invalid days' })
      } else if (event.target.name === "payment_method_code") {
        !checkAlphaNumericLength(event.target.value) ?
          setPaymentTermCodeError({ errorStatus: true, errorText: 'Invalid Code' }) :
          setPaymentTermCodeError({ errorStatus: false, errorText: '' })
      } else {
        setPaymentTermDaysError({ errorStatus: false, errorText: '' });
        setPaymentTermNameError({ errorStatus: false, errorText: '' });
        setPaymentTermCodeError({ errorStatus: false, errorText: '' });
      }
    }
  };
  const handleCancelButtonClick = () => {
    props.history.push(INTERNALROUTES.LIST_PAYMENT_TERMS);
  };
  const handleEditSaveButtonClick = () => {
    let paymentTermData = {
      payment_method_name: paymentTermDataDisplay.payment_method_name,
      days: Number(paymentTermDataDisplay.days),
      payment_method_code: paymentTermDataDisplay.payment_method_code,
      proforma_invoice: Boolean(paymentTermDataDisplay.proforma_invoice),
      is_vcl_enabled: Boolean(paymentTermDataDisplay.is_vcl_enabled),
      is_active: (paymentTermDataDisplay.is_active) === undefined ? true : Boolean(paymentTermDataDisplay.is_active),
    };
    if (paymentTermOperation === "view") {
      setPaymentTermOperation("edit");
    } else if (paymentTermOperation === "edit") {
      setIsLoading(true)
      props
        .handlePaymentTermDataUpdate(paymentTermData)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Payment Term Updated`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                setPaymentTermOperation("view")
              }, 3000)
            }
            // setPaymentTermOperation("view");
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating payment term please try again`,
              "error"
            )
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (paymentTermOperation === "add") {
      setIsLoading(true)
      props
        .handlePaymentTermDataAdd(paymentTermData)
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Payment Term Created`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                setPaymentTermOperation("view")
              }, 3000)
            }
            // setPaymentTermOperation("view");
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while creating payment term please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };
  const handleRemovePaymentTerm = () => {
    setRemoveConfirmationDialogData({
      ...removeConfirmationDialogData,
      openDialog: true,
      dialogTitle: `Remove ${paymentTermDataDisplay.payment_method_name} ?`,
      dialogContent: `Are you sure you want to delete payment term ${paymentTermDataDisplay.payment_method_name}`,
    });
  };
  const handleRemoveConfirmationDialogClose = () => {
    setRemoveConfirmationDialogData({
      ...removeConfirmationDialogData,
      openDialog: false,
      dialogTitle: "",
      dialogContent: "",
    });
  };
  const handleConfirmationDialogDeleteClick = () => {
    setIsLoadingRemove(true)
    handleRemoveConfirmationDialogClose()
    setRemovePaymentTermLoader(true);
    props
      .handleRemovePaymentTerm()
      .then((response) => {
        if (response.status === 204) {
          handleOpenSnackbar(
            `Payment Term Removed`,
            "success"
          )
          if (!snackbarData.openSnackbar) {
            setTimeout(() => {
              setIsLoadingRemove(false)
              setRemovePaymentTermLoader(false)
              props.history.goBack()
            }, 3000)
          }
        }
        else if (response['response']['status'] === 500) {
          setIsLoadingRemove(false)
          handleOpenSnackbar(
            `This Payment Method cannot be Deleted Because it is referenced to Other Data`,
            "error"
          );
          if (!snackbarData.openSnackbar) {
            setTimeout(() => {
              setRemovePaymentTermLoader(false)
            }, 8000)
          }
        }
        else {
          setIsLoadingRemove(false)
          handleOpenSnackbar(
            `Error occured while removing payment term please try again`,
            "error"
          )
          if (!snackbarData.openSnackbar) {
            setTimeout(() => {
              setRemovePaymentTermLoader(false)
            }, 3000)
          }
          // setRemovePaymentTermLoader(false);
          // handleOpenSnackbar(
          //   `Error occured while removing payment term please try again`,
          //   "error"
          // );
        }
      })
      .catch((error) => {
        setIsLoadingRemove(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          setRemovePaymentTermLoader(false)
          handleCloseSnackbar();
        }, 4000);
        // for (let key in error.response.data) {
        //   handleOpenSnackbar(
        //     `${key + "  :  " + error.response.data[key]}`,
        //     "error"
        //   );
        // }
        // if (!snackbarData.openSnackbar) {
        //   setTimeout(() => {
        //     setRemovePaymentTermLoader(false)
        //   }, 3000)
        // }
        // setRemovePaymentTermLoader(false);
        // handleOpenSnackbar(
        //   `Error occured while removing payment term please try again`,
        //   "error"
        // );
      });
  };
  return (
    <Layout {...props}>
      {props.selectedPaymentTermDataFetchLoader ? (
        <CircularProgress disableShrink className={classes.loaderStyle} />
      ) : (
        <>
          <div className={classes.mainDiv}>
            <div className={classes.rightPadding}>
              <Bread
                data={[
                  {
                    title: "Master Management",
                    onClick: () =>
                      props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
                  },
                  {
                    title: "Payment Terms",
                    onClick: () => props.history.goBack(),
                  },
                  {
                    title:
                      paymentTermOperation === "add"
                        ? "Create New"
                        : paymentTermDataDisplay.payment_method_name,
                    onClick: "",
                  },
                ]}
              />
              <div className={classes.topMargin}>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>NAME* </Typography>
                  {paymentTermOperation === "view" ? (
                    <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                      {paymentTermDataDisplay.payment_method_name}
                    </Typography>
                  ) : (
                    <TextField
                      label="Name"
                      name="payment_method_name"
                      type="text"
                      style={{
                        width: "400px"
                      }}
                      onChange={handleChange}
                      placeholder="Enter payment term name"
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={paymentTermDataDisplay.payment_method_name}
                      // error={
                      //   !paymentTermDataDisplay.payment_method_name
                      //     ? false
                      //     : !checkNameFieldAlphaNumeric(paymentTermDataDisplay.payment_method_name)
                      // }
                      // helperText={!paymentTermNameError.errorText && paymentTermNameError.errorText ||
                      //   !paymentTermDataDisplay.payment_method_name
                      //   ? ""
                      //   : checkNameFieldAlphaNumeric(paymentTermDataDisplay.payment_method_name)
                      //     ? ""
                      //     : "Invalid Name"
                      // }
                      error={paymentTermNameError.errorStatus}
                      helperText={paymentTermNameError.errorText}
                    // onKeyPress={(event) => {
                    //   if (event.charCode >= 48 && event.charCode <= 57) {
                    //     // let it happen, don't do anything
                    //   }
                    //   else if (event.charCode >= 65 && event.charCode <= 90) {
                    //     // let it happen, don't do anything
                    //   }
                    //   else if (event.charCode >= 97 && event.charCode <= 122) {
                    //     // let it happen, don't do anything
                    //   }
                    //   else { event.preventDefault(); }
                    // }}
                    />
                  )}
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>DAYS*</Typography>
                  {paymentTermOperation === "view" ? (
                    <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                      {paymentTermDataDisplay.days}
                    </Typography>
                  ) : (
                    <TextField
                      label="Days"
                      onChange={handleChange}
                      placeholder="Enter days"
                      className={classes.textField}
                      style={{
                        width: "400px"
                      }}
                      name='days'
                      margin="dense"
                      variant="outlined"
                      value={paymentTermDataDisplay.days}
                      error={paymentTermDaysError.errorStatus}
                      helperText={paymentTermDaysError.errorText}
                      type="text"
                      onKeyPress={(event) => {
                        if (event.charCode >= 48 && event.charCode <= 57) {
                          // let it happen, don't do anything
                        }
                        else { event.preventDefault(); }
                      }}
                    />
                  )}
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>PAYMENT METHOD CODE* </Typography>
                  {paymentTermOperation === "view" ? (
                    <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                      {paymentTermDataDisplay.payment_method_code}
                    </Typography>
                  ) : (
                    <TextField
                      label="Payment Method Code"
                      name="payment_method_code"
                      type="text"
                      style={{
                        width: "400px"
                      }}
                      onChange={handleChange}
                      placeholder="Enter Payment Method Code"
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                      value={paymentTermDataDisplay.payment_method_code}
                      // error={
                      //   !paymentTermDataDisplay.payment_method_code
                      //     ? false
                      //     : !checkAlphaNumericLength(paymentTermDataDisplay.payment_method_code, 2)
                      // }
                      // helperText={
                      //   !paymentTermDataDisplay.payment_method_code
                      //     ? ""
                      //     : checkAlphaNumericLength(paymentTermDataDisplay.payment_method_code, 2)
                      //       ? ""
                      //       : "Invalid code"
                      // }
                      error={paymentTermCodeError.errorStatus}
                      helperText={paymentTermCodeError.errorText}
                    />
                  )}
                </div>
                <div className={classes.dataFields} style={{ padding: 0 }}>
                  <Typography className={classes.titleLabel}>
                    PROFORMA INVOICE
                  </Typography>
                  <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                    <IOSSwitch
                      disabled={paymentTermOperation === "view"}
                      checked={Boolean(paymentTermDataDisplay.proforma_invoice)}
                      onChange={handleChange}
                      name="proforma_invoice"
                    />
                  </div>
                </div>
                <div className={classes.dataFields} style={{ padding: 0 }}>
                  <Typography className={classes.titleLabel}>
                    ENABLE VCL
                  </Typography>
                  <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                    <IOSSwitch
                      disabled={paymentTermOperation === "view"}
                      checked={Boolean(paymentTermDataDisplay.is_vcl_enabled)}
                      onChange={handleChange}
                      name="is_vcl_enabled"
                    />
                  </div>
                </div>
                <div className={classes.dataFields} style={{ padding: 0 }}>
                  <Typography className={classes.titleLabel}>
                    ACTIVE
                  </Typography>
                  <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                    {console.log((paymentTermDataDisplay.is_active))}
                    <IOSSwitch
                      disabled={paymentTermOperation === "view"}
                      checked={(paymentTermDataDisplay.is_active) === undefined ? true : Boolean(paymentTermDataDisplay.is_active)}
                      // checked={(paymentTermDataDisplay.is_active)}
                      onChange={handleChange}
                      name="is_active"
                    />
                  </div>
                </div>
                <ToolTipComponent
                  title="You have no edit permission on this widget"
                  condition={paymentTermOperation === "view" && !hasUpdateAccess(props.user, 'payment_terms')}
                >
                  <SaveButton
                    size="medium"
                    disabled={
                      paymentTermOperation === "view"
                        ? !hasUpdateAccess(props.user, 'payment_terms') || isLoadingRemove || false
                        : !paymentTermDataDisplay.payment_method_name ||
                        !paymentTermDataDisplay.days ||
                        !paymentTermDataDisplay.payment_method_code ||
                        paymentTermDataDisplay.payment_method_name === "" ||
                        paymentTermDataDisplay.days === "" ||
                        !paymentTermDataDisplay.payment_method_code === "" ||
                        paymentTermNameError.errorStatus ||
                        paymentTermDaysError.errorStatus ||
                        paymentTermCodeError.errorStatus ||
                        isLoadingRemove
                    }
                    className={
                      paymentTermOperation === "view"
                        ? classes.primaryBackground
                        : classes.saveButton
                    }
                    variant="contained"
                    startIcon={
                      paymentTermOperation === "view" ? (
                        <EditOutlinedIcon />
                      ) : (
                        <CheckOutlinedIcon />
                      )
                    }
                    onClick={handleEditSaveButtonClick}
                    isLoading={isLoading}
                  >
                    {paymentTermOperation === "view"
                      ? "EDIT"
                      : paymentTermOperation === "add"
                        ? "SAVE"
                        : "UPDATE"}
                  </SaveButton>
                </ToolTipComponent>
                {paymentTermOperation === "add" ? null : (
                  <ToolTipComponent
                    title="You have no edit permission on this widget"
                    condition={!hasUpdateAccess(props.user, 'payment_terms')}
                  >
                    <SaveButton
                      size="medium"
                      className={classes.removeButton}
                      variant="contained"
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={handleRemovePaymentTerm}
                      disabled={isLoading || !hasUpdateAccess(props.user, 'payment_terms')}
                      isLoading={isLoadingRemove}
                    >
                      REMOVE
                    </SaveButton>
                  </ToolTipComponent>
                )}
                <Button
                  size="medium"
                  className={classes.margin}
                  onClick={handleCancelButtonClick}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
          <SnackbarComponent
            open={snackbarData.openSnackbar}
            handleClose={handleCloseSnackbar}
            snackbarMessage={snackbarData.snackbarMessage}
            type={snackbarData.snackbarType}
            customAutoHideDuration={3000}
          />
          <RemoveConfirmationDialog
            open={removeConfirmationDialogData.openDialog}
            dialogTitle={removeConfirmationDialogData.dialogTitle}
            dialogContent={removeConfirmationDialogData.dialogContent}
            removeDataLoader={removePaymentTermLoader}
            handleClose={handleRemoveConfirmationDialogClose}
            handleConfirmationDialogDeleteClick={
              handleConfirmationDialogDeleteClick
            }
          />
        </>
      )}
    </Layout>
  );
}
