import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  errorMessageColor,
  secondaryColor,
  darkGreyColor,
} from "../../../styles/colors";
import SpinLoader from "../../common/SpinLoader";
import { withRouter } from "react-router";
import TileSkeleton from "../common/TileSkeleton";
import { pxToEm } from "../../../methods";

const styles = {
  dailyRatesCardMainBody: (isMobile, subscribedDailyRatesLoader) => ({
    display: "flex",
    width: isMobile ? "90%" : "100%",
    minHeight: "13vh",
    flexWrap: "wrap",
    justifyContent: isMobile ? subscribedDailyRatesLoader ? "center" : "space-between" : "flex-start",
    padding: isMobile ? subscribedDailyRatesLoader ? 0 : "0px 10px" : "0px",
    marginLeft: !isMobile && 10
  }),
  dailyRatesCard: (isMobile, data) => ({
    position: "relative",
    marginTop: "10px",
    width: isMobile ? "46%" : 120,
    height: isMobile ? "10vh" : 120,
    marginRight: isMobile ? "0px" : "22px",
    borderRadius: "10px",
    cursor: "pointer",
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  }),
  threeLineImageStyle: {
    borderRadius: "0px 10px",
    position: "absolute",
    top: "0px",
    right: "0px",
    width: "24%",
    height: "40%",
  },
  cardTextContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "6px 10px",
    justifyContent: "center",
  },
  clickHereToOrderCircle: (isPending) => ({
    width: "8px",
    height: "8px",
    background: isPending ? bottomNavigationFontColor : errorMessageColor,
    borderRadius: "50%",
  }),
  clickHereToOrderText: {
    // color: bottomNavigationFontColor,
    marginLeft: "5px",
    whiteSpace: "nowrap",
    width: "98%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: window.innerWidth < 375 ? pxToEm(11.8) : "0.8em",
  },
  color: secondaryColor,
  fontWeight: "bold",
};
class DailyRatesCard extends Component {
  render() {
    const {
      classes,
      isMobile,
      subscribedDailyRates,
      subscribedDailyRatesLoader,
      user
    } = this.props;
    // console.log(user.customer_type)
    return (
      <div style={styles.dailyRatesCardMainBody(isMobile, subscribedDailyRatesLoader)}>
        {subscribedDailyRatesLoader ? (
          <div
            style={{
              width: window.innerWidth > 768 ? "75%" : "90%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: 'wrap'
            }}
          >
            {/* <SpinLoader /> */}
            <TileSkeleton skeletonType='rates' />
          </div>
        ) : subscribedDailyRates && subscribedDailyRates.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <span
              style={{
                width: "100%",
                textAlign: "center",
                color: "#8B959A",
              }}
            >
              You have not Subscribed yet!
            </span>
          </div>
        ) : (
          subscribedDailyRates &&
          subscribedDailyRates.map((data) => (
            <div
              style={{
                position: "relative",
                marginTop: "10px",
                width: isMobile ? "46%" : 140,
                height: !isMobile && 80,
                marginRight: isMobile ? "0px" : "22px",
                borderRadius: "8px",
                opacity: data.rate === null && 0.5,
                cursor: "pointer",
                boxShadow:
                  "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (user.customer_type !== "DEALER")
                  if (data.rate !== null) {
                    this.props.fetchItemCategoriesPaymentDiscount(data.item_category_id)
                    this.props.handleSelectDailyRates(
                      data?.category_name,
                      parseInt(data.rate).toFixed(0), data.daily_rate, data.item_category_id, data.brokerage
                    );
                    this.props.history.push("home/createOrder");
                  }
              }}
            >
              {isMobile ? null : (
                <div>
                  <img
                    style={{ borderRadius: "0px 10px " }}
                    src={
                      window.cordova
                        ? `${this.image}${"/threeLineIcon.png"}`
                        : "/threeLineIcon.png"
                    }
                    className={classes.threeLineImageStyle}
                    alt="img"
                  />
                </div>
              )}
              <div
                className={classes.cardTextContainer}
                style={{
                  // width: !isMobile && '75%',
                }}
              >
                <span style={{
                  color: data.rate !== null && secondaryColor,
                  fontWeight: 500,
                  width: !isMobile && '75%',
                  fontSize: window.innerWidth < 375 && pxToEm(13)
                }}>
                  {data?.category_name}
                </span>
                <span style={{
                  fontSize: window.innerWidth < 375 ? pxToEm(12.2) : "0.9em",
                  color: data.rate === null && 'red'
                }}>
                  {data.rate === null
                    ? "Booking Closed"
                    : `₹ ${parseInt(data.rate).toFixed(0)}`}
                </span>
                {
                  user.customer_type !== "DEALER" ?
                    data.rate !== null ?
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={styles.clickHereToOrderCircle(data.rate === null)}
                        />
                        <span className={classes.clickHereToOrderText}>
                          click to book
                        </span>
                      </span> : null
                    : null
                }

              </div>
            </div>
          ))
        )
        }
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DailyRatesCard));
