import "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';

import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Paper,
  IconButton,
} from "@material-ui/core";
import {
  grey,
  primaryColor,
  drawerTextColor,
  secondaryBlueText,
  earthBlue,
  drawerBackground,
  secondaryGrey,
} from "../../../../constants/internal/colors";
import {
  isPhoneNumberValid,
  isValidCountryCode,
  isValidStdCode,
  isValidIFSCCode,
  hasUpdateAccess,
} from "../../../../methods";

import { pxToEm, options } from "../../../../methods";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ManageCustomerExtraPhoneNumberDisplay from "./ManageCustomerExtraPhoneNumberDisplay";
import AddMoreDottedButtonComponent from "./AddMoreDottedButtonComponent";
import ManageCustomerAccountAdditionalContacts from "./ManageCustomerAccountAdditionalContacts";
import PresentToAllOutlinedIcon from "@material-ui/icons/PresentToAllOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import moment from "moment";
import axios from "axios";
import ExtraPhoneNumbersDisplay from "./ExtraPhoneNumbersDisplay";
import { Tooltip } from "@material-ui/core";
import ManageNewExtraPhone from "./ManageNewExtraPhone";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import { type } from "os";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    '& .MuiFormHelperText-root': {
      height: '0',
      marginTop: '0'
    },
    width: "60%",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "green",
    fontWeight: "bold",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
  },
  datePicker: {
    color: primaryColor,
    textColor: drawerTextColor,
    calendarTextColor: drawerTextColor,
    selectColor: primaryColor,
    selectTextColor: drawerTextColor,
    calendarYearBackgroundColor: primaryColor,
    headerColor: primaryColor,
  },
  dataDisplay:
  {
    display: "flex",
    flexDirection: "column",
    width: "45%"
  },
  additionalDataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    paddingTop: "15px",
  },
  paperStyles: { padding: 20 },
  generalSpacing: {
    paddingTop: 20,
  },
  formControlPanImage: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
    cursor: "pointer",
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  titleLabelStylePanImage: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
    cursor: "pointer",
    textDecoration: "underline",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  addMoreButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  addMoreButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  formControl: {
    width: "60%",
  },
  dataFields: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "nowrap",
    paddingBottom: 30,
  },
  // titleLabel: {
  //   fontSize: pxToEm(15),
  //   color: grey,
  //   fontWeight: "bold",
  // },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
  },

});

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },

  },
});

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
export default function ManageCustomerPersonalDetails(props) {


  // console.log(props.customerOperation === "view" && props?.customerTdsCode != "")
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [extraPhones, setExtraPhones] = useState([])
  const [postExtraPhones, setPostExtraPhones] = useState({
    std_code: "", country_code: "", customer: props.selectedCustomerId, phone_number: ""
  })

  let [showExtraPhonesForm, setShowExtraPhonesForm] = useState(false)
  let [showAddExtraPhonesForm, setShowAddExtraPhonesForm] = useState(false)
  let [showExtraPhoneButton, setShowExtraPhoneButton] = useState(true)

  // Extra Phones while creating new Customer
  let [newExtraPhones, setNewExtraPhones] = useState([])
  let [fieldUpdated, setFieldUpdated] = useState(true)
  let [extraPhonesCountryError, setExtraPhonesCountryError] = useState([])
  let [extraPhonesSTDError, setExtraPhonesSTDError] = useState([])
  let [extraPhonesNumberError, setExtraPhonesNumberError] = useState([])
  let [fieldName, setFieldName] = useState("")
  let [extraIndex, setExtraIndex] = useState()
  let [isDirty, setIsDirty] = useState(false)
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false)
  // console.log(extraPhones)
  // console.log(showExtraPhoneButton)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { history, page } = props;

  let [openSnackBar, setOpenSnackBar] = useState(false)
  let [snackbarMessage, setSnackBarMessage] = useState("")
  let [snackBarType, setSnackBarType] = useState("")

  // console.log(snackbarMessage, snackBarType, openSnackBar)
  // let [extraphoness, setExtraPhoness] = useState([])
  // let extraphones = []

  useEffect(() => {
    if (props.customerOperation !== "add") {
      console.log("4")
      extraphoneCall();
    }

    // console.log("Second render")
  }, [])

  useEffect(() => {
    if (props?.getExtraPhoneData) {
      console.log("5")
      extraphoneCall();
      props.setgetExtraPhoneData()
    }

  }, [props?.getExtraPhoneData])

  // useEffect(() => {


  // }, [extraphones])

  // useEffect(() => {

  //   console.log(showExtraPhonesForm)
  // }, [showExtraPhonesForm])


  useEffect(() => {
    // console.log("Third")
  }, [])

  useEffect(() => {

  }, [postExtraPhones])

  useEffect(() => {
    // console.log("after save")
  }, [showExtraPhonesForm])

  useEffect(() => {
    // console.log("newExtra - ", newExtraPhones)
  }, [newExtraPhones])

  useEffect(() => {
    // console.log("newExtra - ", extraPhones)
  }, [extraPhones])

  useEffect(() => {
    // if (fieldName === "std_code")
    //   props.checkValidityForExtraPhoneFields(extraPhonesSTDError, "std_code", extraIndex)
    // if (fieldName === "phone_number")
    //   props.checkValidityForExtraPhoneFields(extraPhonesNumberError, "phone_number", extraIndex)
    // if (fieldName === "country_code")
    //   props.checkValidityForExtraPhoneFields(extraPhonesCountryError, "country_code", extraIndex)
  }, [extraPhonesCountryError, extraPhonesNumberError, extraPhonesSTDError, fieldName])



  // Extra Phones while creating new Customer

  let handleAddNewPhone = () => {
    // console.log(props)
    setShowExtraPhonesForm(true)
    // console.log(showExtraPhonesForm)
    setNewExtraPhones([...newExtraPhones,
    {
      country_code: "",
      std_code: "",
      phone_number: ""
    },
    ])
    if (extraPhonesNumberError.length === 0)
      setExtraPhonesNumberError([false])
    else
      setExtraPhonesNumberError([
        ...extraPhonesNumberError, false
      ])

    if (extraPhonesSTDError.length === 0)
      setExtraPhonesSTDError([false])
    else
      setExtraPhonesSTDError(
        [
          ...extraPhonesSTDError, false
        ]
      )
    if (extraPhonesCountryError.length === 0) {
      setExtraPhonesCountryError([true])
      props.onErrorChange(true);
    }

    else
      setExtraPhonesCountryError(
        [
          ...extraPhonesCountryError, true
        ]
      )
  }

  let removeNewExtraPhoneNumber = (phoneNumberIndex) => {
    // console.log(phoneNumberIndex)


    let extraPhoneNumbers = [...newExtraPhones];
    extraPhoneNumbers.splice(phoneNumberIndex, 1);
    // console.log(extraPhoneNumbers)
    setNewExtraPhones(extraPhoneNumbers)

    let extraPhoneError = [...extraPhonesNumberError]
    extraPhoneError.splice(phoneNumberIndex, 1)
    setExtraPhonesNumberError(extraPhoneError)

    let extraSTDError = [...extraPhonesSTDError]
    extraSTDError.splice(phoneNumberIndex, 1)
    setExtraPhonesSTDError(extraSTDError)

    let extracountryerror = [...extraPhonesCountryError]
    extracountryerror.splice(phoneNumberIndex, 1)
    setExtraPhonesCountryError(extracountryerror)
  };

  let handleAddNewExtraPhoneField = (e, name, index) => {
    // console.log(e.target.value)
    let { value } = e.target;
    // console.log(value)

    setExtraIndex(index)

    let extras = [...newExtraPhones]
    let phoneError = [...extraPhonesNumberError]
    let stdError = [...extraPhonesSTDError]
    // console.log(stdError)

    // console.log(extras)
    if (name === "country_code") {
      setFieldName(name)
      let countryError = [...extraPhonesCountryError]
      countryError.splice(index, 1)
      setExtraPhonesCountryError([...countryError.slice(0, index), false, ...countryError.slice(index)])
      extras[index] = {
        ...extras[index],
        country_code: value
      }
    }

    if (name === "phone_number") {
      setFieldName(name)
      extras[index] = {
        ...extras[index],
        phone_number: value
      }
      if (extras[index].phone_number.length === 0 ||
        extras[index]?.phone_number?.length !== 10 ||
        extras[index]?.phone_number < 0) {
        if (phoneNumberTouched) {
          phoneError.splice(index, 1)

          setExtraPhonesNumberError([...phoneError.slice(0, index), true, ...phoneError.slice(index)])
          props.onErrorChange(true);
        }
      }
      // else if (extras[index]?.phone_number?.length === 10) {
      //   const isPhoneNumberAlreadyPresent = extraPhones?.some(p => p.phone_number === extras[index]?.phone_number)
      //   console.log(extraPhones)
      //   console.log(extras[index])
      //   phoneError.splice(index, 1)

      //   setExtraPhonesNumberError([...phoneError.slice(0, index), isPhoneNumberAlreadyPresent, ...phoneError.slice(index)])
      // }
      else {
        phoneError.splice(index, 1)

        setExtraPhonesNumberError([...phoneError.slice(0, index), false, ...phoneError.slice(index)])
        props.onErrorChange(false)

      }
    }

    if (name === "std_code") {
      setFieldName(name)
      console.log(extras[index].std_code.length)
      extras[index] = {
        ...extras[index],
        std_code: value
      }
      if (extras[index].std_code.length < 2 || extras[index].std_code.length > 8) {
        if (extras[index].std_code.length === 0) {
          stdError.splice(index, 1)
          setExtraPhonesSTDError([...stdError.slice(0, index), false, ...stdError.slice(index)])
        }

        else {
          stdError.splice(index, 1)
          setExtraPhonesSTDError([...stdError.slice(0, index), true, ...stdError.slice(index)])
        }

      } else {
        stdError.splice(index, 1)
        setExtraPhonesSTDError([...stdError.slice(0, index), false, ...stdError.slice(index)])
      }
    }
    // console.log(extras)
    setNewExtraPhones(extras)
    setFieldUpdated(!fieldUpdated)
    // console.log(extras)
    props.handleAddNewExtraPhones(extras)
  }

  // const handleExtraState = () => {
  //   console.log("saved")
  // }


  let extraphoneCall = () => {
    let extraphones = []
    // console.log("api called")
    // console.log(props)
    console.log('called HERE 3')
    axios
      .get(`${APIROUTES.CUSTOMER_EXTRA_PHONES}?customer_id=${props.selectedCustomerId}`,
        // options.call(props.user)
      )
      .then(
        (response) => {
          let data = [];
          if (response.status === 200)
            extraphones = response.data
          setExtraPhones(extraphones)
          // console.log(extraPhones)
        },
        (error) => {
          // setNewContracts({ error: error.message, loading: false, data: [] });
          console.error(error);
        }
      )
  }



  const extraPhoneDelete = (index) => {
    axios
      .delete(`${APIROUTES.DELETE_EXTRA_PHONE_NUMBER}${extraPhones[index].id}/`)
      .then((response) => {
        if (response.status === 204) {
          props.extraPhonesDelete("success")
          extraphoneCall()
        }

      })
      .catch((error) => {
        props.extraPhonesDelete("error")
      })


  }

  function handleAddPhone() {
    setIsDirty(false)
    setShowExtraPhonesForm(true)
    setShowAddExtraPhonesForm(true)
  }

  const handlePhoneNumberIsDirty = () => {
    setIsDirty(true)
  }

  return (
    <Paper className={classes.paperStyles}>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation}>
          This information will be used to LOGIN in the RIPL app.
        </Typography>
        <Typography className={classes.fillingInformation}>
          Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <div className={classes.dataDisplay}>
          <Typography className={classes.titleLabelStyle}>
            PERSONAL DETAILS
          </Typography>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>FIRST NAME *</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerFirstName}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="FIRST NAME"
                id="outlined-size-small"
                value={props.customerFirstName}
                onChange={props.handleChange("customerFirstName")}
                variant="outlined"
                size="small"
                error={props.customerFirstNameError}
                helperText={
                  props.customerFirstNameError ? "Invalid first name" : ""
                }
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>LAST NAME *</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerLastName}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="LAST NAME"
                id="outlined-size-small"
                value={props.customerLastName}
                onChange={props.handleChange("customerLastName")}
                variant="outlined"
                size="small"
                error={props.customerLastNameError}
                helperText={
                  props.customerLastNameError ? "Invalid last name" : ""
                }
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              DATE OF BIRTH
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerDateOfBirth !== null ?
                  moment(props.customerDateOfBirth).format("DD-MMM-YYYY") || ""
                  : " "}
              </Typography>
            ) : (
              <Fragment>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.formControl}
                      size="small"
                      inputVariant="outlined"
                      label="DATE OF BIRTH"
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      value={props.customerDateOfBirth}
                      onChange={props.handleDateOfBirthChange}
                      placeholder="e.g.: DD/MM/YYYY"
                      InputAdornmentProps={{ position: "start" }}
                      InputProps={{ className: classes.datePicker, readOnly: true }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Fragment>
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              CUSTOMER TYPE *
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerType}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.customerType}
                  disabled={props.accountApproved}
                  onChange={(evt) => props.handleCustomerTypeChange(evt, "", "customerType")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"DEALER"}>Dealer</MenuItem>
                  <MenuItem value={"DISTRIBUTOR"}>Distributor</MenuItem>
                  <MenuItem value={"BROKER"}>Broker</MenuItem>
                  <MenuItem value={"CONSUMER"}>Consumer</MenuItem>
                  <MenuItem value={"TRADER"}>Trader</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              CUSTOMER TYPE L1
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerTypeL1_name}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.customerTypeL1}
                  // disabled={props.customerOperation === "edit"}
                  // onChange={(evt) => props.handleCustomerTypeChange(evt)}
                  MenuProps={MenuProps}
                >
                  {
                    props?.allCustomerTypesData.map(type => (
                      <MenuItem
                        value={type.id}
                        key={type.id}
                        onClick={(evt) => props.handleCustomerTypeChange(evt, type, "L1")}
                      >{type.type_name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              CUSTOMER TYPE L2
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerTypeL2_name}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.customerTypeL2}
                  // disabled={props.customerOperation === "edit"}
                  // onChange={(evt) => props.handleCustomerTypeChange(evt)}
                  MenuProps={MenuProps}
                >
                  {
                    props?.allCustomerTypesData.map(type => (
                      <MenuItem
                        value={type.id}
                        key={type.id}
                        onClick={(evt) => props.handleCustomerTypeChange(evt, type, "L2")}
                      >{type.type_name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              CUSTOMER TAG
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerTag_name}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.customerTag}
                  // disabled={props.customerOperation === "edit"}
                  // onChange={(evt) => props.handleCustomerTypeChange(evt, tag, "tag")}
                  MenuProps={MenuProps}
                >
                  {
                    props?.allCustomerTagsData.map(tag => (
                      <MenuItem
                        value={tag.id}
                        key={tag.id}
                        onClick={(evt) => props.handleCustomerTypeChange(evt, tag, "tag")}
                      >{tag.tag_name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            )}
          </div>
        </div>
        <div className={classes.dataDisplay}>
          <Typography className={classes.titleLabelStyle}>
            LOGIN CREDENTIALS
          </Typography>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>EMAIL</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.email}
              </Typography>
            ) : (
              <TextField
                type="email"
                className={classes.formControl}
                label="Email"
                onChange={props.handleChange("email")}
                variant="outlined"
                size="small"
                autoComplete="off"
                value={props.email}
                error={props.emailError}
                helperText={props.emailError ? "Invalid email" : ""}
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>PHONE NO.</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.phoneNumber === "" ? null : `${String(props.phoneCountryCode).includes('+') ? props.phoneCountryCode + " " + props.phoneNumber : "+" + props.phoneCountryCode + " " + props.phoneNumber} `}
              </Typography>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between", width: "60%" }}>
                <FormControl
                  variant="outlined"
                  // className={classes.formControl}
                  size="small"
                  style={{ width: "35%" }}
                >
                  <Select
                    value={props?.phoneCountryCode || "91"}
                    // disabled={props.customerOperation === "view"}
                    onChange={props.handleChange("phoneCountryCode")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={null} disabled={true}>
                      Select Any
                    </MenuItem>
                    {
                      props.country_codes.map((country) =>
                        <MenuItem value={country.phone_code}>
                          {`+${(country.phone_code[0] === '+' ?
                            country.phone_code.slice(1)
                            : country.phone_code)} (${country.name})`}
                        </MenuItem>
                      )
                    }
                  </Select>
                  {/* {extraPhoneCountryError && <FormHelperText style={{ color: "red" }}>This is required!</FormHelperText>} */}
                </FormControl>
                <TextField
                  // type="number"
                  onKeyPress={
                    (event) => {
                      if (event.charCode >= 48 && event.charCode <= 57) {

                      } else {
                        event.preventDefault()
                      }
                    }
                  }
                  // className={classes.formControl}
                  style={{ width: "60%" }}
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  onChange={props.handleChange("phoneNumber")}
                  autoComplete="off"
                  value={props.phoneNumber}
                  error={props.phoneNumberError}
                  helperText={
                    props.phoneNumberError ? "Invalid phone number" : ""
                  }
                />
              </div>
            )}
          </div>
          {props.customerOperation === "view" ? null : (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>PASSWORD *</Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.password}
                </Typography>
              ) : (
                <TextField
                  // type="text"
                  type={showPassword ? "text" : "password"}
                  className={classes.formControl}
                  label="Password"
                  autoComplete="off"
                  value={props.password || ""}
                  variant="outlined"
                  size="small"
                  name="password"
                  onChange={props.handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <>
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              props.handleGeneratePassword();
                            }}
                            edge="end"
                            className={classes.titleLabelStyle}
                          >
                            GENERATE
                          </Button>
                        </>
                      </InputAdornment>
                    ),
                  }}
                  error={props.passwordError}
                  helperText={props.passwordError ? "Invalid password" : ""}
                />
              )}
            </div>
          )}
          {
            props.customerOperation === "view" ?
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>CUSTOMER ID</Typography>
                {/* {props.customerOperation === "view" ? ( */}
                <Typography className={classes.formControl}>
                  {props.employeeID}
                </Typography>
                {/* ) : null} */}
              </div> : null
          }
        </div>
      </div>

      <div className={classes.generalDetailsDisplaySection}>
        <div className={classes.dataDisplay}>
          <Typography className={classes.titleLabelStyle}>
            ADDITIONAL (ERP) DETAILS
          </Typography>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              CUSTOMER TITLE*
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerTitle}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerTitle || "MR."}
                  disabled={props.customerOperation === "view"}
                  onChange={props.handleChange("customerTitle")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null} disabled={true}>
                    Select Any
                  </MenuItem>
                  <MenuItem value={"MR."}>Mr.</MenuItem>
                  <MenuItem value={"MS."}>Ms.</MenuItem>
                  <MenuItem value={"MRS."}>Mrs.</MenuItem>
                  <MenuItem value={"MISS."}>Miss.</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              NAME ON CHEQUE{Boolean(props.customerBankCode) && '*'}
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerNameOnCheque}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="NAME ON CHEQUE"
                id="outlined-size-small"
                disabled={!Boolean(props.customerBankCode)}
                value={props.customerNameOnCheque}
                onChange={props.handleChange("customerNameOnCheque")}
                variant="outlined"
                size="small"
                required={Boolean(props.customerBankCode) ? true : false}
              />
            )}
          </div>

          {/* {props.customerType === "DISTRIBUTOR" ||
          props.customerType === "BROKER" ? (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS CODE</Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.customerTdsCode}
                </Typography>
              ) : (
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <Select
                    value={props.customerTdsCode}
                    disabled={props.customerOperation === "view"}
                    onChange={props.handleChange("customerTdsCode")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={null} disabled={true}>
                      Select Any
                    </MenuItem>
                    {props.allTdsData.map((tds) => (
                      <MenuItem value={tds.id}>{tds.tds_rate}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          ) : null} */}
          {/* <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>TDS LESS</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerTdsLess}
              </Typography>
            ) : (
              <TextField
                type="number"
                className={classes.formControl}
                label="TDS LESS"
                id="outlined-size-small"
                value={props.customerTdsLess}
                onChange={props.handleChange("customerTdsLess")}
                variant="outlined"
                size="small"
              />
            )}
          </div> */}
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel} sx={{ backgroundColor: "green" }}>
              CURRENCY CODE*
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerCurrencyCode}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerCurrencyCode || "INR"}
                  disabled={props.customerOperation === "view"}
                  onChange={props.handleChange("customerCurrencyCode")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null} disabled={true}>
                    Select Any
                  </MenuItem>
                  <MenuItem value={"INR"}>INR</MenuItem>
                  <MenuItem value={"USD"}>USD</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>

          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              CHECKBOOK UPLOAD{Boolean(props.customerBankCode) && '*'}
            </Typography>
            <div className={classes.formControlPanImage} style={{ color: !Boolean(props.customerBankCode) && 'rgba(0,0,0,0.26)' }}>
              {props?.customerOperation === "view" ||
                props?.customerOperation === "edit" ? (
                props?.checkbookFileName || props?.checkBookUploadFileName ? (
                  <div style={{ display: "flex", justifyContent: "space-between", width: "95%", wordBreak: "break-all" }}>
                    <span
                      onClick={() =>
                        window.open(props?.checkbookFileName || props?.checkBookImagePreview, "_blank")
                      }
                      className={classes.titleLabelStylePanImage}
                    >
                      {props?.checkbookFileName || props?.checkBookUploadFileName || ""}
                    </span>
                    {props?.customerOperation === "edit" || props?.customerOperation === "add" ?
                      <DeleteOutlineOutlinedIcon
                        width="20"
                        height="20"
                        onClick={() => props?.handleRemoveCheckbookImage()}
                      /> : null
                    }
                  </div>
                ) : (
                  <>
                    <label
                      htmlFor="upload-button"
                      className={classes.fileUploadName}
                    >
                      <div className={classes.panImageUpload}>
                        <PresentToAllOutlinedIcon
                          className={classes.checkBookImagePreview}
                        />
                        <Typography className={classes.generalText}>
                          Upload
                        </Typography>
                      </div>
                    </label>
                    <input
                      disabled={
                        props?.customerOperation === "view" ||
                        !Boolean(props.customerBankCode)
                        // ||
                        // props?.customerOperation === "edit"
                      }
                      type="file"
                      accept="application/pdf, image/*"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        e.preventDefault();
                        props.handleCheckbookImageChange(e);
                      }}
                      required={Boolean(props.customerBankCode) ? true : false}
                    />
                  </>
                )
              ) : (
                <>
                  <label
                    htmlFor="upload-button"
                    className={classes.fileUploadName}
                  >
                    {props?.checkBookUploadFileName ? (
                      <div className={classes.generalAlign}>
                        <Typography className={classes.generalText}>
                          {props?.checkBookUploadFileName}
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.panImageUpload}>
                        <PresentToAllOutlinedIcon
                          className={classes.checkBookImagePreview}
                        />
                        <Typography className={classes.generalText}>
                          Upload
                        </Typography>
                      </div>
                    )}
                  </label>
                  <input
                    disabled={
                      props?.customerOperation === "view" ||
                      !Boolean(props.customerBankCode)
                      // ||
                      // props?.customerOperation === "edit"
                    }
                    type="file"
                    accept="application/pdf, image/*"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      e.preventDefault();
                      props.handleCheckbookImageChange(e);
                    }}
                    required={Boolean(props.customerBankCode) ? true : false}
                  />
                </>
              )}
              {props?.checkBookImagePreview && props?.customerOperation === "add" ? (
                <DeleteOutlineOutlinedIcon
                  width="20"
                  height="20"
                  onClick={props?.handleRemoveCheckbookImage}
                />)
                : null
              }
            </div>

          </div>
          {props.checkBookFileTypeError && <span
            style={{ color: "red", fontSize: "12px" }}>
            {"Invalid File type"}
          </span>}
          <span
            style={{ fontSize: "10px" }}>
            {"Accepted File Types : pdf/jpg/jpeg/png"}
          </span>
          {/* <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>ACCOUNT CLASS CODE D</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerClassCodeD}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="ACCOUNT CLASS CODE D"
                id="outlined-size-small"
                value={props.customerClassCodeD}
                onChange={props.handleChange("customerClassCodeD")}
                variant="outlined"
                size="small"
                error={props.classCodeDError}
                helperText={
                  props.classCodeDError ? "Invalid Class Code D" : ""
                }
                inputProps={{
                  maxLength: 4,
                }}
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>ACCOUNT CLASS CODE G</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerClassCodeG}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="ACCOUNT CLASS CODE G"
                id="outlined-size-small"
                value={props.customerClassCodeG}
                onChange={props.handleChange("customerClassCodeG")}
                variant="outlined"
                size="small"
                error={props.classCodeGError}
                helperText={
                  props.classCodeGError ? "Invalid Class Code G" : ""
                }
                inputProps={{
                  maxLength: 4,
                }}
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>ACCOUNT CLASS CODE K</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerClassCodeK}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="ACCOUNT CLASS CODE K"
                id="outlined-size-small"
                value={props.customerClassCodeK}
                onChange={props.handleChange("customerClassCodeK")}
                variant="outlined"
                size="small"
                error={props.classCodeKError}
                helperText={
                  props.classCodeKError ? "Invalid Class Code K" : ""
                }
                inputProps={{
                  maxLength: 4,
                }}
              />
            )}
          </div> */}
          {/* <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              SERVICE TAX NUMBER
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerServiceTaxNumber}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="SERVICE TAX NUMBER"
                id="outlined-size-small"
                value={props.customerServiceTaxNumber}
                onChange={props.handleChange("customerServiceTaxNumber")}
                variant="outlined"
                size="small"
              />
            )}
          </div> */}

        </div>

        <div className={classes.additionalDataDisplay}>
          <Typography className={classes.titleLabelStyle}> </Typography>
          {/* <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              CUSTOMER TITLE
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerTitle}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerTitle}
                  disabled={props.customerOperation === "view"}
                  onChange={props.handleChange("customerTitle")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null} disabled={true}>
                    Select Any
                  </MenuItem>
                  <MenuItem value={"MR."}>Mr.</MenuItem>
                  <MenuItem value={"MS."}>Ms.</MenuItem>
                  <MenuItem value={"MRS."}>Mrs.</MenuItem>
                  <MenuItem value={"MISS."}>Miss.</MenuItem>
                </Select>
              </FormControl>
            )}
          </div> */}
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>BANK NAME</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {/* {console.log(props?.allBankData, props.customerBankName, typeof props.customerBankName, typeof props.customerBankName === 'string', Number(props.customerBankName), Number(props.customerBankName) == NaN, (props.allBankData.find(d => d.id === Number(props.customerBankName)))?.bank_name)} */}
                {props?.allBankData &&
                  (props.allBankData.find(d => d.id === Number(props.customerBankName)))?.bank_name ||
                  props.customerBankName
                }
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerBankCode}
                  disabled={props.customerOperation === "view"}
                  MenuProps={MenuProps}
                // label="BANK NAME"
                // labelId="BANK NAME"
                // onChange={props.handleChange("customerBankName")}
                >
                  <MenuItem value={null} disabled={true}>
                    Select Any
                  </MenuItem>
                  {props.allBankData.map((data) => (
                    <MenuItem
                      value={data.id}
                      name={data.id}
                      onClick={props.handleChange(
                        "customerBankName",
                        data.id,
                        data.bank_code,
                        data.ifsc_code,
                        data.bank_name
                      )}
                    >
                      {data.bank_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>



            )}
          </div>

          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              BANK ACCOUNT NUMBER{Boolean(props.customerBankCode) && '*'}
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerBankAccountNumber}
              </Typography>
            ) : (
              <TextField
                // type="number"
                // onKeyPress={
                //   (event) => {
                //     if (event.charCode >= 48 && event.charCode <= 57) {

                //     } else {
                //       event.preventDefault()
                //     }
                //   }
                // }
                disabled={!Boolean(props.customerBankCode)}
                inputProps={{ maxLength: 20 }}
                className={classes.formControl}
                label="BANK ACCOUNT NUMBER"
                id="outlined-size-small"
                value={props.customerBankAccountNumber}
                onChange={props.handleChange("customerBankAccountNumber")}
                variant="outlined"
                size="small"
                error={props.customerBankAccountNumberError}
                helperText={props.customerBankAccountNumberError ? "Invalid Input" : ""}
                required={Boolean(props.customerBankCode) ? true : false}
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>IFSC NUMBER{Boolean(props.customerBankCode) && '*'}</Typography>

            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props?.ifsc || ""}
              </Typography>
            ) : (
              <TextField
                // type="text"
                onKeyPress={
                  (event) => {
                    if (event.charCode >= 48 && event.charCode <= 57 ||
                      event.charCode >= 65 && event.charCode <= 90 ||
                      event.charCode >= 97 && event.charCode <= 122) {

                    } else {
                      event.preventDefault()
                    }
                  }
                }
                disabled={!Boolean(props.customerBankCode)}
                className={classes.formControl}
                label="IFSC NUMBER"
                placeholder="ABCD0125889"
                value={props.ifsc}
                onChange={props.handleChange("ifsc")}
                variant="outlined"
                size="small"
                error={props.ifscCodeError}
                helperText={props.ifscCodeError ? "Invalid Input" : ""}
                inputProps={{ maxLength: 11 }}
                required={Boolean(props.customerBankCode) ? true : false}
              />
            )}
          </div>
          {/* <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>BANK NAME</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerBankName}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerBankName}
                  disabled={props.customerOperation === "view"}
                  // onChange={props.handleChange("customerBankName")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null} disabled={true}>
                    Select Any
                  </MenuItem>
                  {props.allBankData.map((data) => (
                    <MenuItem
                      value={data.id}
                      name={data.id}
                      onClick={props.handleChange(
                        "customerBankName",
                        data.id,
                        data.bank_code
                      )}
                    >
                      {data.bank_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div> */}
          {/* <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>BANK CODE</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerBankCode}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="BANK CODE"
                id="outlined-size-small"
                value={props.customerBankCode}
                onChange={props.handleChange("customerBankCode")}
                variant="outlined"
                size="small"
              />
            )}
          </div> */}

          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>TAN No.</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.tanNumber}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="TAN NO."
                id="outlined-size-small"
                value={props.tanNumber}
                onChange={props.handleChange("tanNumber")}
                variant="outlined"
                size="small"
                error={props.tanNumberError}
                helperText={
                  props.tanNumberError ? "Invalid TAN No." : ""
                }
                inputProps={{
                  maxLength: 10
                }}
              />
            )}
          </div>

          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>TDS CODE</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerTdsCode}
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerTdsValue}
                  disabled={props.customerOperation === "view"}
                  // onChange={props.handleChange("customerTdsCode")}
                  MenuProps={MenuProps}
                >
                  {/* <MenuItem value="none" onClick={props.handleChange("customerTdsCode", "none")}> */}
                  <MenuItem value={null} disabled={true}>
                    None
                  </MenuItem>
                  {props.allTdsData.map((tds) => (
                    <MenuItem key={tds.id}
                      onClick={props.handleChange("customerTdsCode", tds)}
                      value={`${tds.tds_code}-${tds.tds_rate}-${tds.tds_section}`}
                    >{tds.tds_code}-{tds.tds_rate}-{tds.tds_section}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          {props.customerOperation === "view" && props?.customerTdsCode != "" ? (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS NAME</Typography>
              <Typography className={classes.formControl}>
                {props.customerTdsName}
              </Typography>
            </div>
          ) : null}
          {props.customerOperation === "view" && props?.customerTdsCode != "" ? (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS RATE</Typography>
              <Typography className={classes.formControl}>
                {props.customerTdsRate}
              </Typography>
            </div>

          ) : null}
          {props.customerOperation === "view" && props?.customerTdsCode != "" ? (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS SECTION</Typography>
              <Typography className={classes.formControl}>
                {props.customerTdsSection}
              </Typography>
            </div>

          ) : null}
        </div>

      </div>
      {/* <div
        className={
          // generalSpacing
          classes.generalSpacing
        }
      >
        <ManageCustomerAccountAdditionalContacts props={props} />
      </div> */}
      <ExtraPhoneNumbersDisplay
        {...props}
        classes={classes}
        extraPhones={extraPhones}
        extraphoneCall={extraphoneCall}
        extraPhoneDelete={extraPhoneDelete}
      />

      {
        (props.customerOperation === "add") ?

          <div>
            <ToolTipComponent
              placement='top-start'
              title="You have no edit permission on this widget"
              condition={!hasUpdateAccess(props.user, "customerManagement")}
            >
              <Button
                className={classes.addMoreButton}
                onClick={() => {
                  handleAddNewPhone()
                  props.handleAddNewExtraPhones(newExtraPhones)
                }}
                disabled={
                  hasUpdateAccess(props.user, "customerManagement")
                    ? false
                    : true
                }
              >
                + Add More Phone Number
              </Button>
            </ToolTipComponent>
            {
              (showExtraPhonesForm) ?

                newExtraPhones.map((newPhone, index) =>
                  <div className={classes.dataDisplay} style={{ marginTop: 5 }}>
                    <div className={classes.titileLabelDataFields}>
                      <Typography className={classes.titleLabelStyle}>
                        {` NEW EXTRA PHONE NUMBER ${index + 1}`}
                      </Typography>

                      <div>
                        <Tooltip
                          title={`Delete Extra Phone Number ${index + 1}
                      
                        `}
                          placement="left"
                          arrow
                        >
                          <DeleteOutlineOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              removeNewExtraPhoneNumber(index)
                            }
                          />
                        </Tooltip>
                      </div>

                    </div>

                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>COUNTRY CODE*</Typography>
                      {props.operation === "view" ? "" : (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          size="small"
                        >
                          {/* <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Select Country Code
                          </InputLabel> */}
                          <Select
                            labelId="demo-simple-select-error-label"
                            id="demo-simple-select-error"
                            displayEmpty={true}
                            value={
                              (newPhone?.country_code?.length) === 0 ? ""
                                : newPhone.country_code}
                            // disabled={props.customerOperation === "view"}
                            onChange={
                              // (props.customerOperation === "add") ?
                              //   props.handleExtraPhoneNumbersValueChange(index, "phone_number") :
                              (e) => handleAddNewExtraPhoneField(e,
                                "country_code", index
                              )}
                            name="country_code"
                            // label="Country Code"
                            label="STD CODE"
                            MenuProps={MenuProps}
                          >
                            {/* <MenuItem default value="" disabled={true}>
                              Select Any
                            </MenuItem> */}
                            {
                              props.country_codes.map((country) =>
                                <MenuItem value={country.phone_code}
                                >
                                  {`${country.name}(+${country.phone_code})`}
                                </MenuItem>
                              )
                            }
                          </Select>
                        </FormControl>
                      )}
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>PHONE NUMBER*</Typography>
                      {props.operation === "view" ? (
                        ""
                      ) : (
                        <TextField
                          // type="number"
                          onKeyPress={
                            (event) => {
                              if (event.charCode >= 48 && event.charCode <= 57) {

                              } else {
                                event.preventDefault()
                              }
                            }
                          }
                          autoComplete="off"
                          className={classes.formControl}
                          label="PHONE NUMBER"
                          id="outlined-size-small"
                          defaultValue={newPhone.phone_number || ""}
                          variant="outlined"
                          onChange={
                            // (props.customerOperation === "add") ?
                            //   props.handleExtraPhoneNumbersValueChange(index, "phone_number") :
                            (e) => {
                              setPhoneNumberTouched(true);
                              handlePhoneNumberIsDirty();
                              handleAddNewExtraPhoneField(e,
                                "phone_number", index
                              )
                            }
                          }
                          size="small"
                          error={extraPhonesNumberError[index]}
                          helperText={
                            extraPhonesNumberError[index] ? "Invalid phone number" : ""
                          }
                        />
                      )}
                    </div>
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>STD CODE</Typography>

                      <TextField
                        // type="number"
                        onKeyPress={
                          (event) => {
                            if (event.charCode >= 48 && event.charCode <= 57) {

                            } else {
                              event.preventDefault()
                            }
                          }
                        }
                        autoComplete="off"
                        className={classes.formControl}
                        label="STD CODE"
                        id="outlined-size-small"
                        defaultValue={newPhone.std_code}
                        variant="outlined"
                        onChange={
                          // (props.customerOperation === "add") ?
                          //   props.handleExtraPhoneNumbersValueChange(index, "std_code") :
                          (e) => handleAddNewExtraPhoneField(e,
                            "std_code", index
                          )}
                        size="small"
                      // error={extraPhonesSTDError[index]}
                      // helperText={
                      //   extraPhonesSTDError[index] ? "Invalid STD code" : ""
                      // }
                      />

                    </div>



                  </div>
                ) : ""

            }
          </div>


          :

          <div>
            {
              (showExtraPhoneButton) ? (
                <ToolTipComponent
                  placement='top-start'
                  title="You have no edit permission on this widget"
                  condition={!hasUpdateAccess(props.user, "customerManagement")}
                >
                  <Button
                    className={classes.addMoreButton}
                    onClick={() => {
                      handleAddPhone()
                      props.addNewExtraPhoneNumber()

                    }}
                    disabled={
                      hasUpdateAccess(props.user, "customerManagement")
                        ? false
                        : true
                    }
                  >
                    + Add More Phone Number
                  </Button>
                </ToolTipComponent>
              ) : ""
            }

            {
              (showExtraPhonesForm) ?
                <ManageCustomerExtraPhoneNumberDisplay
                  {...props}
                  // handleExtraState={handleExtraState}
                  postExtraPhones={postExtraPhones}
                  extraPhones={extraPhones}
                  handleCountryCodeSelect={props.handleCountryCodeSelect}
                  selectedCountryCode={props.selectedCountryCode}
                  handleAddNewPhoneField={props.handleAddNewPhoneField}
                  showExtraPhonesForm={props.showExtraPhonesForm}
                  handleExtraPhoneCancelClick={props.handleExtraPhoneCancelClick}

                  // handleExtraPhoneSave={handleExtraPhoneSave}
                  // handleAddNewPhoneField={handleAddNewPhoneField}
                  extraphoneCall={extraphoneCall}
                  setShowExtraPhonesForm={setShowExtraPhonesForm}
                  handleExtraPhoneNumbersValueChange={props.handleExtraPhoneNumbersValueChange}
                  handlePhoneNumberIsDirty={handlePhoneNumberIsDirty}
                // extraPhoneCountryError={extraPhoneCountryError}
                // extraPhoneNumberError={extraPhoneNumberError}
                // extraPhoneSTDError={extraPhoneSTDError}
                /> : ""
            }


          </div>
      }

      {/* <div>
        <Dummy />
      </div> */}












    </Paper >
  );
}
