import React, { Component } from "react";
import AccessDenied from "../../../components/common/AccessDenied";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import withUser from "../../../hoc/withUser";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import { Route } from "react-router-dom";
// Old Imports
// import ManageStateCategoryData from "../../../components/internal/masterManagement/web/dailyRateRegions/index";

import ItemCategoryView from "./masterItemCategory/ItemCategoryView";
import ItemCategoryDetails from "./masterItemCategory/ItemCategoryDetails";
import ItemCategoryCreate from "./masterItemCategory/ItemCategoryCreate";
import ItemCategoryEdit from "./masterItemCategory/ItemCategoryEdit";
// New Imports
import AllMastersList, {
  DisplayMastersDataDetailsList,
} from "../../../components/internal/masterManagement";
import ItemCategoryContainer from "./itemCategoryNewUi";
import TagsContainer from "./tags";
import PaymentTermContainer from "./paymentTerm";
import PlantContainer from "./plant";
import BankContainer from "./bank";
import TdaRateContainer from "./tdsRate";
import TransporterContainer from "./transporter";
import TcsContainer from "./tcs";
import TcsMatrixContainer from "./tcsMatrix";
import UnitContainer from "./unit";
import PincodeContainer from "./pincode";
import ItemNatureContainer from "./itemNature";
import ReasonContainer from "./reason";
import CustomerTypeContainer from "./customerType";
import AppBannerContainer from "./appBanner";
import AppVersionContainer from "./appVersion";
import ItemSizeContainer from "./itemSize";
import DailyRateRegionsContainer from "./dailyRatesRegions"; //to be used in ManageStateCategoryData import
import { isAccessibleModule, arraySortBykeyAllBrowser } from "../../../methods";
import debounce from "lodash.debounce";
class MastersManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNewUi: true,
      displayItemMasterNewUi: true,
      itemOperation: null,
      stateOperation: null,
      selectedOptionToDisplay: null,
      fetchingState: false,
      itemCategoriesDataFetchLoader: false,
      itemCategoriesList: [],
      paymentTermsList: [],
      paymentTermsDataFetchLoader: false,
      plantItemMasterList: [],
      plantItemMasterDataFetchLoader: false,
      tagsMasterList: [],
      tagsMasterDataFetchLoader: false,
      bankMasterList: [],
      bankMasterDataFetchLoader: false,
      tdsRateList: [],
      tdsRateDataFetchLoader: false,
      totaltransportCount: 0,
      transporterList: [],
      transporterDataFetchLoader: false,
      tcsList: [],
      tcsMAtrix: [],
      tcsListDataFetchLoader: false,
      unitList: [],
      unitListDataFetchLoader: false,
      allStateList: [],
      allStateListDataFetchLoader: false,
      allCitiesList: [],
      allCitiesListDataFetchLoader: false,
      defaultCityStateList: [],
      cityStatesList: [],
      cityStatesListDataFetchLoader: false,
      defaultPincodeList: [],
      pincodesList: [],
      pincodesListDataFetchLoader: false,
      searchedPincode: [],
      searchedPincodeFetchLoader: false,
      searchedCity: [],
      tabValue: '0',
      itemNatureList: [],
      itemNatureListDataFetchLoader: false,
      reasonList: [],
      reasonListDataFetchLoader: false,
      customerTypeList: [],
      customerTypeListDataFetchLoader: false,
      appBannerList: [],
      appBannerListDataFetchLoader: false,
      appVersionList: [],
      appVersionListDataFetchLoader: false,
      itemSizeList: [],
      itemSizeListDataFetchLoader: false,
      modules: [],
      loadingModules: false,
      errorModules: "",
      searchTransportText: '',
      prevLink: '',
      nextLink: '',
    };
  }

  componentDidMount() {
    if (isAccessibleModule(this.props?.user, "Masters")) {
      this.getDailyRatesState();
      this.getItemCateogriesList();
      this.getPaymentTermsList();
      this.getTagsMasterList();
      this.getPlantItemMasterList();
      this.getBankMasterList();
      this.getTdsRateList();
      this.getTransporterList();
      this.getTcsList();
      this.getTcsMatrixList();
      this.getUnitList();
      this.getAllCities();
      this.getPincodesList('153054');
      this.getStateCityList('4040');
      this.getAllStatesList();
      this.getItemNatureList();
      this.getCustomerTypeList();
      this.getReasonList();
      this.getModule();
      this.getAppBannerList();
      this.getAppVersionList();
      this.getItemSizeList();
    }
  }

  componentDidUpdate() {
    if (this.props.location.pathname === "/internalMastersManagement" && this.state?.searchTransportText !== '') {
      this.setState({ searchTransportText: '' })
      // console.log(this.props)
    }

  }

  handleTagMasterDataAdd = (tagMasterAddData) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          APIROUTES.GET_ALL_TAGS_MASTERS_LIST,
          tagMasterAddData,
          this.getOptions(this.props?.user)
        )
        .then(async (response) => {
          await this.setState({ selectedTagId: response.data.id });
          await this.getTagsMasterList();
          await resolve(response);
        })
        .catch(reject);
    });

  handleTagMasterDataUpdate = (tagId, tagMasterUpdateData) =>
    new Promise((resolve, reject) => {
      // console.log(tagMasterUpdateData);
      // console.log(
      //   `${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}${this.state.selectedTagId}/tagMasterUpdateData`
      // );
      axios
        .put(
          `${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}${tagId}/`,
          tagMasterUpdateData,
          this.getOptions(this.props?.user)
        )
        .then(async (response) => {
          await this.getTagsMasterList();
          resolve(response);
        })
        .catch(reject);
    });
  handleRemoveTag = (tagId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}${tagId}/`,
          this.getOptions(this.props?.user)
        )
        .then(async (response) => {
          await this.getTagsMasterList();
          resolve(response);
        })
        .catch(error => {
          if (error['response']['status'] === 500) {
            this.getTagsMasterList();
            resolve(error)
          }
          reject(error);
        });
    });
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  handleSearchTransport = (newLink, text) => {
    this.setState({ searchTransportText: text }, () => {
      text !== ""
        ? this.handleNextTransportPage(newLink, text)
        : this.getTransporterList()
    })
  }

  handleNextTransportPage = debounce((newLink, searchText) => {
    // console.log(newLink)
    const baseUrl = APIROUTES.TRANSPORTER;
    const params = searchText !== '' && `?search=${searchText}`
    const filteredLink = newLink === 0 ? baseUrl + params : newLink
    this.setState({ transporterDataFetchLoader: true });
    axios.get(filteredLink, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data?.results, 'acc_name');
        this.setState({
          transporterList: responseData,
          totaltransportCount: response.data?.count,
          prevLink: response.data?.previous,
          nextLink: response.data?.next,
          transporterDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ transporterDataFetchLoader: false, totaltransportCount: 0, });
      }
    );
  }, 500)

  // New ui fuctions
  getDailyRatesState = () => {
    this.setState({ fetchingState: true });
    axios
      .get(APIROUTES.DAILY_RATES_STATE, this.getOptions(this.props.user))
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'state_name');
          this.setState({
            dailyRatesState: responseData,
            fetchingState: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ fetchingState: false });
        }
      );
  };
  getItemCateogriesList = () => {
    this.setState({ itemCategoriesDataFetchLoader: true });
    axios
      .get(APIROUTES.GET_ITEM_CATEGORIES_LIST, this.getOptions(this.props.user))
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'category_name');
          this.setState({
            itemCategoriesList: responseData,
            itemCategoriesDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ itemCategoriesDataFetchLoader: false });
        }
      );
  };
  getPaymentTermsList = () => {
    this.setState({ paymentTermsDataFetchLoader: true });
    axios
      .get(
        APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'payment_method_name');
          this.setState({
            paymentTermsList: responseData,
            paymentTermsDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ paymentTermsDataFetchLoader: false });
        }
      );
  };
  getTagsMasterList = () => {
    this.setState({ tagsMasterDataFetchLoader: true });
    axios
      .get(
        APIROUTES.GET_ALL_TAGS_MASTERS_LIST,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'tag_name');
          this.setState({
            tagsMasterList: responseData,
            tagsMasterDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ tagsMasterDataFetchLoader: false });
        }
      );
  };
  getPlantItemMasterList = () => {
    this.setState({ plantItemMasterDataFetchLoader: true });
    axios
      .get(
        APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'plant_name');
          this.setState({
            plantItemMasterList: responseData,
            plantItemMasterDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ plantItemMasterDataFetchLoader: false });
        }
      );
  };
  getTransporterList = () => {
    this.setState({ transporterDataFetchLoader: true });
    axios.get(APIROUTES.TRANSPORTER, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data?.results, 'acc_name');
        this.setState({
          transporterList: responseData,
          totaltransportCount: response.data?.count,
          prevLink: response.data?.previous,
          nextLink: response.data?.next,
          transporterDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ transporterDataFetchLoader: false, totaltransportCount: 0, });
      }
    );
  };
  getBankMasterList = () => {
    this.setState({ bankMasterDataFetchLoader: true });
    axios.get(APIROUTES.BANK_MASTER, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data, 'bank_name');
        this.setState({
          bankMasterList: responseData,
          bankMasterDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ bankMasterDataFetchLoader: false });
      }
    );
  };
  getTdsRateList = () => {
    this.setState({ tdsRateDataFetchLoader: true });
    axios.get(APIROUTES.TDS_RATE, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data, 'tds_name');
        this.setState({
          tdsRateList: responseData,
          tdsRateDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ tdsRateDataFetchLoader: false });
      }
    );
  };
  getTcsList = () => {
    this.setState({ tcsListDataFetchLoader: true });
    axios.get(APIROUTES.TCS, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data, 'tcs_percent');
        this.setState({
          tcsList: responseData,
          tcsListDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ tcsListDataFetchLoader: false });
      }
    );
  };
  getTcsMatrixList = () => {
    this.setState({ tcsListDataFetchLoader: true });
    axios.get(APIROUTES.TCSMATRIX + "item_natures/", this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data, 'item_nature');
        this.setState({
          tcsMatrixList: responseData,
          tcsListDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ tcsListDataFetchLoader: false });
      }
    );
  };
  getUnitList = () => {
    this.setState({ unitListDataFetchLoader: true });
    axios.get(APIROUTES.UNIT, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data, 'unit_name');
        this.setState({
          unitList: responseData,
          unitListDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ unitListDataFetchLoader: false });
      }
    );
  };
  getAllStatesList = (countryId) => {
    this.setState({ allStateListDataFetchLoader: true, })
    axios.get(`${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}`,
      this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            allStateList: responseData,
            allStateListDataFetchLoader: false,
          });
          // console.log("STATE LIST" + this.state.allStateList)
          // for (let key in responseData) {
          //   console.log(key + ":", responseData[key]);
          // }
        },
        (error) => {
          console.log("error", error);
          this.setState({ allStateListDataFetchLoader: false, });
        }
      );

  };

  getAllCities = () => {
    this.setState({ allCitiesListDataFetchLoader: true });
    axios
      .get(
        `${APIROUTES.GET_CITY_LIST}?country_id=101`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState(
            {
              allCitiesList: responseData,
              allCitiesListDataFetchLoader: false,
            },
            // () =>
            //   responseData[0] == undefined
            //     ? null
            //     : this.getPincodesList('153054'),
            // this.getStateCityList('4040'),
            // this.getAllStatesList()

            // : this.getPincodesList(responseData[0].id)
          );
        },
        (error) => {
          console.log("error", error);
          this.setState({ allCitiesListDataFetchLoader: false });
        }
      );
  };
  getAllCitiesList = () => {
    this.setState({ allCitiesListDataFetchLoader: true });
    axios
      .get(
        `${APIROUTES.GET_CITY_LIST}?country_id=101`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState(
            {
              allCitiesList: responseData,
              allCitiesListDataFetchLoader: false,
            });
        },
        (error) => {
          console.log("error", error);
          this.setState({ allCitiesListDataFetchLoader: false });
        }
      );
  };
  getStateCityList = (id) => {
    this.setState({ allCitiesListDataFetchLoader: true });
    axios
      .get(
        `${APIROUTES.GET_CITY_LIST}?state_id=${id}`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          if (id === '4040') {
            this.setState({
              defaultCityStateList: id === '4040'
                ? responseData
                : this.state?.defaultCityStateList,
            })
          }
          this.setState({
            cityStatesList: responseData,
            allCitiesListDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ allCitiesListDataFetchLoader: false });
        }
      );
  };

  getPincodesList = (id) => {
    this.setState({ pincodesListDataFetchLoader: true });
    axios
      .get(
        `${APIROUTES.PINCODE}?city_id=${id}`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'pincode');
          // for (let k in responseData) {
          //   console.log(responseData[k]);
          // }
          if (id === '153054') {
            this.setState({
              defaultPincodeList: id === '153054'
                ? responseData
                : this.state?.defaultPincodeList,
            });
          }
          this.setState({
            pincodesList: responseData,
            pincodesListDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ pincodesListDataFetchLoader: false });
        }
      );
  };

  getPincode = (pincode) => {
    // return axios.get(`${APIROUTES.GET_PINCODE}?search=${pincode}`, this.getOptions(this.props.user))
    this.setState({ pincodesListDataFetchLoader: true });
    axios
      .get(`${APIROUTES.GET_PINCODE}?search=${pincode}`, this.getOptions(this.props.user))
      .then(
        (response) => {
          // console.log(response)
          let responseData = arraySortBykeyAllBrowser(response.data.results, 'pincode');
          this.setState({
            searchedPincode: responseData,
            // searchedPincode: response.data,
            pincodesListDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ pincodesListDataFetchLoader: false });
        }
      );
  }

  getCity = (city) => {
    this.setState({ allCitiesListDataFetchLoader: true });
    axios
      .get(`${APIROUTES.GET_CITY}?search=${city}`, this.getOptions(this.props.user))
      .then(
        (response) => {
          // console.log(response)
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            searchedCity: responseData,
            allCitiesListDataFetchLoader: false
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ allCitiesListDataFetchLoader: false });
        }
      );
  }

  getItemNatureList = () => {
    this.setState({ itemNatureListDataFetchLoader: true });
    axios.get(APIROUTES.ITEM_NATURE, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data, 'item_nature');
        this.setState({
          itemNatureList: responseData,
          itemNatureListDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ itemNatureListDataFetchLoader: false });
      }
    );
  };
  getCustomerTypeList = () => {
    this.setState({ customerTypeListDataFetchLoader: true });
    axios
      .get(APIROUTES.GET_CUSTOMER_TYPE, this.getOptions(this.props.user))
      .then((response) => {
        // let responseData = response.data;
        let responseData = arraySortBykeyAllBrowser(response.data, 'type_name');
        this.setState({
          customerTypeList: responseData,
          customerTypeListDataFetchLoader: false,
        });
      },
        (error) => {
          console.log("error", error);
          this.setState({ customerTypeListDataFetchLoader: false });
        }
      );
  };
  getAppBannerList = () => {
    this.setState({ appBannerListDataFetchLoader: true });
    axios
      .get(APIROUTES.GET_APP_BANNER, this.getOptions(this.props.user))
      .then((response) => {
        let responseData = [];
        responseData = arraySortBykeyAllBrowser(response.data, 'valid_upto');
        this.setState({
          appBannerList: responseData.reverse(),
          appBannerListDataFetchLoader: false,
        });
      },
        (error) => {
          console.log("error", error);
          this.setState({ customerTypeListDataFetchLoader: false });
        }
      );
  };
  getAppVersionList = () => {
    this.setState({ appVersionListDataFetchLoader: true });
    axios
      .get(APIROUTES.GET_APP_VERSION, this.getOptions(this.props.user))
      .then((response) => {
        // let responseData = response.data;
        let responseData = arraySortBykeyAllBrowser(response.data, 'type_description');
        this.setState({
          appVersionList: responseData,
          appVersionListDataFetchLoader: false,
        });
      },
        (error) => {
          console.log("error", error);
          this.setState({ appVersionListDataFetchLoader: false });
        }
      );
  };
  getItemSizeList = () => {
    this.setState({ itemSizeListDataFetchLoader: true });
    axios
      .get(APIROUTES.GET_ITEM_SIZE, this.getOptions(this.props.user))
      .then((response) => {
        // let responseData = response.data;
        let responseData = arraySortBykeyAllBrowser(response.data, 'item_name', "", 'size');
        this.setState({
          itemSizeList: responseData,
          itemSizeListDataFetchLoader: false,
        });
      },
        (error) => {
          console.log("error", error);
          this.setState({ itemSizeListDataFetchLoader: false });
        }
      );
  };
  getReasonList = () => {
    this.setState({ reasonListDataFetchLoader: true });
    axios
      .get(APIROUTES.GET_REASON, this.getOptions(this.props.user))
      .then((response) => {
        // let responseData = response.data;
        let responseData = arraySortBykeyAllBrowser(response.data, 'module');
        this.setState({
          reasonList: responseData,
          reasonListDataFetchLoader: false,
        });
      },
        (error) => {
          console.log("error", error);
          this.setState({ reasonListDataFetchLoader: false });
        }
      );
  };
  getModule = () => {
    let modules = [];
    axios
      .get(APIROUTES.GET_ALL_ACCESS_AREA, this.getOptions(this.props.user))
      .then(
        (response) => {
          var data = response.data;
          modules = data.map((element) => ({
            id: element.id,
            createdAt: new Date(element.created_at),
            modifiedAt: new Date(element.modified_at),
            name: element.module_name,
            // widgets: element.widgets,
          }));
          this.setState({ modules, loadingModules: false, errorModules: "" });
        },
        (errorModules) => {
          console.error(errorModules);
          this.setState({
            modules: [],
            loadingModules: false,
            errorModules,
          });
        }
      );
  };
  handlePincodeTabValue = (newValue) => {
    this.setState({
      tabValue: newValue
    })
    if (newValue === '0') {
      this.setState({ pincodesList: this.state?.defaultPincodeList })
    }
    else if (newValue === '1') {
      this.setState({ cityStatesList: this.state?.defaultCityStateList })
    }
  }
  // old ui functions
  selectedState = (id) => {
    const selectedStateDetails = this.state.dailyRatesState.filter(
      (data) => data.id === id
    );
    this.setState({ selectedStateDetails });
  };

  handleUpdateState = async (stateName, stateCode, stateId, callback) => {
    const data = {
      state_name: stateName,
      state_code: stateCode,
    };

    this.setState({ fetchingState: true });
    let response = await axios.put(
      `${APIROUTES.MASTER_STATE_UPDATE}${stateId}/`,
      data,
      this.getOptions(this.props.user)
    );
    // console.log("response ", response);
    if (response.status === 200) {
      this.getDailyRatesState();
      if (callback) callback();
    } else {
      console.log("err occ isn state add ");
      if (callback) callback();
      this.getDailyRatesState();
    }
  };

  handleAddState = async (stateName, stateCode, callback) => {
    const data = {
      state_name: stateName,
      state_code: stateCode,
    };

    this.setState({ fetchingState: true });
    let response = await axios.post(
      APIROUTES.ADD_MASTER_STATE,
      data,
      this.getOptions(this.props.user)
    );

    if (response.status === 201) {
      this.getDailyRatesState();
      if (callback) callback();
    } else {
      console.log("err occ isn state add ");
      if (callback) callback();
      this.getDailyRatesState();
    }
  };

  handleDeleteState = async (stateId, callback) => {
    try {
      this.setState({ fetchingState: true });
      this.getDailyRatesState();
      if (callback) callback();
    } catch (err) {
      console.log("err in state delete ", err);
      this.getDailyRatesState();
      if (callback) callback();
    }
  };

  render() {
    const { selectedStateDetails, displayNewUi, displayItemMasterNewUi } =
      this.state;
    // const { user } = this.props;
    // if (this?.props?.isMobile) return <MasterManagmentMobile />;
    // else
    if (isAccessibleModule(this.props?.user, "Masters")) {
      return (
        <>
          {displayNewUi ? (
            <>
              {displayItemMasterNewUi ? (
                <Route
                  exact
                  path={[
                    `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/:id`,
                    `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/:mode`,
                    `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/:mode/:id`,
                  ]}
                  render={(props) => (
                    <ItemCategoryContainer
                      {...props}
                      {...this.props}
                      {...this.state}
                      itemCategoriesList={this.state.itemCategoriesList}
                      unitList={this.state.unitList}
                      getItemCateogriesList={this.getItemCateogriesList}
                    />
                  )}
                />
              ) : (
                <>
                  <Route
                    exact
                    path={[`${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/create`]}
                    render={(props) => (
                      <ItemCategoryCreate {...props} {...this.props} />
                    )}
                  />
                  <Route
                    exact
                    path={[
                      `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/details/:id`,
                    ]}
                    render={(props) => (
                      <ItemCategoryDetails {...props} {...this.props} />
                    )}
                  />
                  <Route
                    exact
                    path={[
                      `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/:table/:id`,
                      `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/:id`,
                    ]}
                    render={(props) => (
                      <ItemCategoryEdit {...props} {...this.props} />
                    )}
                  />
                  <Route
                    exact
                    path={[`${INTERNALROUTES.LISTITEMCATEGORIESMASTER}`]}
                    render={(props) => (
                      <ItemCategoryView {...props} {...this.props} />
                    )}
                  />
                </>
              )}
              <Route
                exact
                path={[
                  `${INTERNALROUTES.LIST_PAYMENT_TERMS}/:mode`,
                  `${INTERNALROUTES.LIST_PAYMENT_TERMS}/:mode/:id`,
                ]}
                render={(props) => (
                  <PaymentTermContainer
                    {...props}
                    {...this.props}
                    {...this.state}
                    getPaymentTermsList={this.getPaymentTermsList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}/:id`,
                  `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}/:mode`,
                  `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}/:mode/:id`,
                ]}
                render={(props) => (
                  <PlantContainer
                    {...props}
                    {...this.props}
                    itemCategoriesList={this.state.itemCategoriesList}
                    getPlantItemMasterList={this.getPlantItemMasterList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.LIST_TAGS_MASTER}/:mode`,
                  `${INTERNALROUTES.LIST_TAGS_MASTER}/:mode/:id`,
                ]}
                render={(props) => (
                  <TagsContainer
                    {...props}
                    {...this.props}
                    handleTagMasterDataAdd={this.handleTagMasterDataAdd}
                    handleTagMasterDataUpdate={this.handleTagMasterDataUpdate}
                    handleRemoveTag={this.handleRemoveTag}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.BANK_MASTER}/:id`,
                  `${INTERNALROUTES.BANK_MASTER}/:mode`,
                  `${INTERNALROUTES.BANK_MASTER}/:mode/:id`,
                ]}
                render={(props) => (
                  <BankContainer
                    {...props}
                    {...this.props}
                    getBankMasterList={this.getBankMasterList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.TDS_RATE}/:id`,
                  `${INTERNALROUTES.TDS_RATE}/:mode`,
                  `${INTERNALROUTES.TDS_RATE}/:mode/:id`,
                ]}
                render={(props) => (
                  <TdaRateContainer
                    {...props}
                    {...this.props}
                    getTdsRateList={this.getTdsRateList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.TRANSPORTER}/:id`,
                  `${INTERNALROUTES.TRANSPORTER}/:mode`,
                  `${INTERNALROUTES.TRANSPORTER}/:mode/:id`,
                ]}
                render={(props) => (
                  <TransporterContainer
                    {...props}
                    {...this.props}
                    getTransporterList={this.getTransporterList}
                    tdsRateList={this.state?.tdsRateList}
                    transporterList={this.state?.transporterList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.TCS}/:id`,
                  `${INTERNALROUTES.TCS}/:mode`,
                  `${INTERNALROUTES.TCS}/:mode/:id`,
                ]}
                render={(props) => (
                  <TcsContainer
                    {...props}
                    {...this.props}
                    getTcsList={this.getTcsList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.TCSMATRIX}/:id`,
                  `${INTERNALROUTES.TCSMATRIX}/:mode`,
                  `${INTERNALROUTES.TCSMATRIX}/:mode/:id`,
                ]}
                render={(props) => (
                  <TcsMatrixContainer
                    {...props}
                    {...this.props}
                    getTcsMatrixList={this.getTcsMatrixList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.UNIT}/:id`,
                  `${INTERNALROUTES.UNIT}/:mode`,
                  `${INTERNALROUTES.UNIT}/:mode/:id`,
                ]}
                render={(props) => (
                  <UnitContainer
                    {...props}
                    {...this.props}
                    unitList={this.state.unitList}
                    getUnitList={this.getUnitList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.REASON}/:id`,
                  `${INTERNALROUTES.REASON}/:mode`,
                  `${INTERNALROUTES.REASON}/:mode/:id`,
                ]}
                render={(props) => (
                  <ReasonContainer
                    {...props}
                    {...this.props}
                    modules={this.state?.modules}
                    getReasonList={this.getReasonList}
                    getModule={this.getModule}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.CUSTOMER_TYPE}/:id`,
                  `${INTERNALROUTES.CUSTOMER_TYPE}/:mode`,
                  `${INTERNALROUTES.CUSTOMER_TYPE}/:mode/:id`,
                ]}
                render={(props) => (
                  <CustomerTypeContainer
                    {...props}
                    {...this.props}
                    getCustomerTypeList={this.getCustomerTypeList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.APP_BANNER}/:id`,
                  `${INTERNALROUTES.APP_BANNER}/:mode`,
                  `${INTERNALROUTES.APP_BANNER}/:mode/:id`,
                ]}
                render={(props) => (
                  <AppBannerContainer
                    {...props}
                    {...this.props}
                    appBannerList={this.state?.appBannerList}
                    getAppBannerList={this.getAppBannerList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.APP_VERSION}/:id`,
                  `${INTERNALROUTES.APP_VERSION}/:mode`,
                  `${INTERNALROUTES.APP_VERSION}/:mode/:id`,
                ]}
                render={(props) => (
                  <AppVersionContainer
                    {...props}
                    {...this.props}
                    getAppVersionList={this.getAppVersionList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.ITEM_SIZE}/:id`,
                  `${INTERNALROUTES.ITEM_SIZE}/:mode`,
                  `${INTERNALROUTES.ITEM_SIZE}/:mode/:id`,
                ]}
                render={(props) => (
                  <ItemSizeContainer
                    {...props}
                    {...this.props}
                    getItemSizeList={this.getItemSizeList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.ITEM_NATURE}/:id`,
                  `${INTERNALROUTES.ITEM_NATURE}/:mode`,
                  `${INTERNALROUTES.ITEM_NATURE}/:mode/:id`,
                ]}
                render={(props) => (
                  <ItemNatureContainer
                    {...props}
                    {...this.props}
                    getItemNatureList={this.getItemNatureList}
                  />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.PINCODE}/:id`,
                  `${INTERNALROUTES.PINCODE}/:mode`,
                  `${INTERNALROUTES.PINCODE}/:mode/:id`,
                ]}
                render={(props) => (
                  <PincodeContainer
                    {...props}
                    {...this.props}
                    {...this.state}
                    getAllCities={this.getAllCities}
                    getAllStatesList={this.getAllStatesList}
                    getAllCitiesList={this.getAllCitiesList}
                    getPincodesList={this.getPincodesList}
                    getStateCityList={this.getStateCityList}
                  // handlePincodeTabValue={this.handlePincodeTabValue}
                  />
                )}
              />
            </>
          ) : (
            <>
              <Route
                exact
                path={[`${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/create`]}
                render={(props) => (
                  <ItemCategoryCreate {...props} {...this.props} />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/details/:id`,
                ]}
                render={(props) => (
                  <ItemCategoryDetails {...props} {...this.props} />
                )}
              />
              <Route
                exact
                path={[
                  `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/:table/:id`,
                  `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/edit/:id`,
                ]}
                render={(props) => (
                  <ItemCategoryEdit {...props} {...this.props} />
                )}
              />
              <Route
                exact
                path={[`${INTERNALROUTES.LISTITEMCATEGORIESMASTER}`]}
                render={(props) => (
                  <ItemCategoryView {...props} {...this.props} />
                )}
              />
            </>
          )}
          {/* <Route
            exact
            path={[
              `${INTERNALROUTES.LISTSTATEMASTER}`,
              `${INTERNALROUTES.LISTSTATEMASTER}/:mode`,
              `${INTERNALROUTES.LISTSTATEMASTER}/:mode/:id`,
            ]}
            render={(props) => (
              <ManageStateCategoryData
                {...props}
                {...this.props}
                {...this.state}
                selectedStateDetails={selectedStateDetails}
                handleUpdateState={this.handleUpdateState}
                handleAddState={this.handleAddState}
                handleDeleteState={this.handleDeleteState}
              />
            )}
          /> */}

          <Route
            exact
            path={[
              `${INTERNALROUTES.LISTSTATEMASTER}`,
              `${INTERNALROUTES.LISTSTATEMASTER}/:mode`,
              `${INTERNALROUTES.LISTSTATEMASTER}/:mode/:id`,
            ]}
            render={(props) => (
              <DailyRateRegionsContainer
                {...props}
                {...this.props}
                {...this.state}
                selectedStateDetails={selectedStateDetails}
                handleUpdateState={this.handleUpdateState}
                handleAddState={this.handleAddState}
                handleDeleteState={this.handleDeleteState}
              />
            )}
          />
          <Route
            exact
            path={`${INTERNALROUTES.MASTERSMANAGMENT}`}
            render={(props) => (
              <AllMastersList {...props} {...this.props} {...this.state} />
            )}
          />
          <Route
            exact
            path={[
              `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}`,
              `${INTERNALROUTES.LIST_PAYMENT_TERMS}`,
              `${INTERNALROUTES.LIST_PLANT_ITEM_MASTER}`,
              `${INTERNALROUTES.LIST_TAGS_MASTER}`,
              `${INTERNALROUTES.BANK_MASTER}`,
              `${INTERNALROUTES.TDS_RATE}`,
              `${INTERNALROUTES.TRANSPORTER}`,
              `${INTERNALROUTES.TCS}`,
              `${INTERNALROUTES.TCSMATRIX}`,
              `${INTERNALROUTES.UNIT}`,
              `${INTERNALROUTES.PINCODE}`,
              `${INTERNALROUTES.ITEM_NATURE}`,
              `${INTERNALROUTES.REASON}`,
              `${INTERNALROUTES.CUSTOMER_TYPE}`,
              `${INTERNALROUTES.APP_BANNER}`,
              `${INTERNALROUTES.APP_VERSION}`,
              `${INTERNALROUTES.ITEM_SIZE}`
            ]}
            render={(props) => (
              <DisplayMastersDataDetailsList
                {...props}
                {...this.props}
                {...this.state}
                displaySection={
                  props.location.pathname.split("/")[
                  props.location.pathname.split("/").length - 1
                  ]
                }
                getPincodesList={this.getPincodesList}
                getStateCityList={this.getStateCityList}
                getPincode={this.getPincode}
                getCity={this.getCity}
                handlePincodeTabValue={this.handlePincodeTabValue}
                handleNextTransportPage={this.handleNextTransportPage}
                handleSearchTransport={this.handleSearchTransport}
              />
            )}
          />
        </>
      );
    } else {
      return <AccessDenied />;
    }
  }
}

export default withUser(MastersManagementContainer);
