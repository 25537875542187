import React, { Component } from 'react';
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import DispatchPlanning from '../../dispatchPlanning/DispatchPlanning';

class LoadingAdviceOperationWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout {...this.props}>
        <DispatchPlanning {...this.props} Title="Loading Advices" />
      </Layout>
    );
  }
}
export default LoadingAdviceOperationWeb;
