module.exports = {
  whiteSmoke: "#f5f5f5",
  earthBlue: "#0000A0",
  primaryColor: "#293c79",
  primaryColorBackground: "#1E3559 0% 0% no-repeat padding-box",
  drawerTextColor: "#fff",
  drawerBackground:
    "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
  highlitedTextColor: "#4B87C5",
  tableHeaderText: "#A3A6B4",
  tableHoverColor: "#fbfbfb",
  orange: "#F58634",
  grey: "#839192",
  secondaryGrey: "#A3A6B4",
  secondaryBlueText: "#4B87C5",
  iOpenInDesktopBackgroundGradient:
    "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  sloganTextColor: "#35425A",
  successButtonBackground: "#329232",
  successButtonBackgroundHover: "#3ab03a",
  cancelButtonBackground: "#AA2929",
  notificationIconBackground: "#1B388B",
  lightGreen: "#00A42B",
  veryLightGreen: "#DAF7E8",
  lightGreen_1: "#4AD991",
  lightPink: "#FFE2E6",
  pink: "#FF7285",
  rejectedColor: "#DC143C",
  logoBackground: "#FFF200"
};
