import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  Switch,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import {
  checkAlphabet,
  checkAlphaNumericLength,
  checkApiError,
  pxToEm,
} from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import SaveButton from "../../common/SaveButton";
import DateFnsUtils from "@date-io/date-fns";
// import { Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    width: '70%',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function ManageAppVersionData(props) {
  const classes = useStyles();
  const { handleappVersionDataUpdate, handleappVersionDataAdd } = props;
  const [appVersionData, setAppVersionData] = useState(
    props.selectedAppVersionData
  );
  const [isLoading, setIsLoading] = useState(false);
  const [appVersionError, setAppVersionError] = useState({
    new_updates: false,
    app_version: false,
  });
  const [appVersionOperation, setAppVersionOperation] = useState(
    props.appVersionOperation
  );
  const [snackbarData, setSnackbarData] = useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });

  useEffect(() => {
    setAppVersionOperation(props.appVersionOperation)
    setAppVersionData(props.selectedAppVersionData)
  }, [props.appVersionOperation, props.selectedAppVersionData])


  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, appVersion) => {
    if (appVersion === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return data.map((item, index) => {
      return (
        <>
          <p style={{ margin: 0 }}>{item}</p>
        </>
      );
    });
  };


  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    const trimmedValue = value.trim();
    if (name === "is_active") {
      setAppVersionData({
        ...appVersionData,
        [name]: checked,
      });
    } else {
      setAppVersionData({
        ...appVersionData,
        [name]: value,
      });
      setAppVersionError((prevValue) => ({
        ...prevValue,
        [name]: !trimmedValue ? true : false,
      }));
    }
    if (name === 'app_version') {
      if (!/[A-Za-z]/.test(trimmedValue)) {
        setAppVersionError((prevValue) => ({ ...prevValue, [name]: true }));
      } else {
        setAppVersionError((prevValue) => ({ ...prevValue, [name]: false }));
      }
    }
  };

  const handleVersionChange = (event) => {
    const { value, name, } = event.target;
    const trimmedValue = value.trim();

    setAppVersionData({
      ...appVersionData,
      [name]: value,
    });
    if (!trimmedValue) {
      setAppVersionError((prevValue) => ({
        ...prevValue,
        [name]: true,
      }));
    } else {
      if (name === 'app_version') {
        if (!/[A-Za-z]/.test(trimmedValue)) {
          setAppVersionError((prevValue) => ({ ...prevValue, [name]: true }));
        } else {
          setAppVersionError((prevValue) => ({ ...prevValue, [name]: false }));
        }
      }
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };

  const handleEditSaveButtonClick = () => {
    setIsLoading(true);
    if (appVersionOperation === "edit") {
      handleappVersionDataUpdate(appVersionData)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `App Version Content Updated Successfully`,
              "success"
            );
            if (!snackbarData?.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false);
                props.history.goBack();
              }, 3000);
            }
            // props.history.goBack();
          } else {
            setIsLoading(false);
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while updating App Version Content please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          // console.log(error)
          setIsLoading(false);
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (appVersionOperation === "add") {
      handleappVersionDataAdd(appVersionData)
        .then((response) => {
          // console.log("handleAppVersionDataAdd response : ", response);
          if (response.status === 201) {
            handleOpenSnackbar(`App Version Created Successfully`, "success");
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false);
                props.history.push(
                  `${INTERNALROUTES.APP_VERSION}/${response.data.id}`
                );
              }, 3000);
            }
            // props.history.push(`${INTERNALROUTES.CUSTOMER_TYPE}/${response.data.appVersion}`);
          } else {
            setIsLoading(false);
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while creating App Version master please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "App Version Master",
                onClick: () =>
                  props.history.push(INTERNALROUTES.APP_VERSION),
              },
              {
                title: appVersionOperation === "add" ? "Create New" : `Edit`,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                VERSION *
              </Typography>
              <TextField
                label="New Version"
                name="app_version"
                value={appVersionData?.app_version || ""}
                onChange={handleVersionChange}
                placeholder="Enter App Version"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                error={appVersionError.app_version}
                helperText={appVersionError.app_version ? "Invalid Field" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                WHAT's NEW *
              </Typography>
              <TextField
                label="Changes In The App"
                name="new_updates"
                onChange={handleChange}
                placeholder="Enter App Changes Details"
                className={classes.textField}
                multiline
                minRows={4}
                maxRows={10}
                margin="dense"
                variant="outlined"
                value={appVersionData?.new_updates || ""}
                error={appVersionError.new_updates}
                helperText={appVersionError.new_updates ? "Invalid Field" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>IS ACTIVE</Typography>
              <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                <IOSSwitch
                  checked={Boolean(appVersionData.is_active)}
                  onChange={handleChange}
                  name="is_active"
                />
              </div>
            </div>
            <SaveButton
              size="medium"
              disabled={
                !appVersionData?.app_version ||
                appVersionError?.app_version ||
                appVersionError?.new_updates
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {appVersionOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManageAppVersionData);
