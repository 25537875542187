import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Checkbox,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  grey,
  whiteSmoke,
  secondaryBlueText,
  drawerTextColor,
} from '../../../../constants/internal/colors';
import { arraySortBykeyAllBrowser, checkApiError, pxToEm } from '../../../../methods';
import Delete from '@material-ui/icons/Delete';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import SnackbarComponent from '../../common/SnackbarComponent';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  tableHeaderRow: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: 'bold',
  },
  tableBody: {
    fontSize: pxToEm(13),
  },
  headingOne: {
    display: 'flex',
    alignItems: 'center',
    fontSize: pxToEm(13),
    flexBasis: '15%',
    flexShrink: 0,
  },
  headingTwo: {
    fontSize: pxToEm(13),
    flexBasis: '30%',
    color: theme.palette.text.secondary,
  },
  headingThree: {
    display: 'flex',
    alignItems: 'center',
    fontSize: pxToEm(13),
    flexBasis: '50%',
    flexShrink: 0,
  },
  titleWithBlueFont: {
    fontSize: pxToEm(13),
    color: secondaryBlueText,
  },
  expandPanelSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  generalSpacingRight: {
    paddingRight: 10,
  },
  restrictionIcons: {
    paddingRight: 10,
  },
  ipRestriction: {
    width: '90px',
    height: '21px',
    borderRadius: '10px',
    opacity: '1',
    fontSize: pxToEm(11),
    display: 'flex',
    alignItems: 'center',
    placeContent: 'space-evenly',
    backgroundColor: secondaryBlueText,
    color: drawerTextColor,
  },
  ipRestrictedIcon: {
    width: '14px',
    height: '14px',
  },
  mainDiv: { width: '100%' },
  alignTitleCheckboxes: { display: 'flex', justifyContent: 'space-around' },
  alignTitleItems: { display: 'flex', alignItems: 'center' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
  },
  buttonStyle: {
    textTransform: "capitalize",
    fontSize: "1.2em",
    marginRight: "15px",
  },
  dropbtn: {
    backgroundColor: "#f7f7f7",
    padding: "16px",
    borderRadius: "5px",
    color: "gray",
    fontSize: pxToEm(18),
    minWidth: '20ch',
    overflow: "hidden",
    border: "none"
  },
  dropdownContent: {
    display: "none",
    position: "absolute",
    backgroundColor: "#f1f1f1",
    minWidth: '20ch',
    // boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    borderRadius: "5px",
    zIndex: 100
  },
  dropdownContenta: {
    color: "gray",
    padding: "10px 12px",
    textDecoration: "none",
    display: "block",

  },
  dropdown: {
    position: "relative",
    display: "inline-block",

    "&:hover": {
      "& $dropdownContent": { display: "block" }
    }

  },
  helperText: {
    position: 'absolute',
    top: pxToEm(48),
    color: '#FF0000'
  }
}));

const CutomColorCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    '&$checked': {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function AccessAreaManageTableComponent({
  modules,
  updateAWidget,
  addIpsInBulk,
  deleteIp
}) {
  const classes = useStyles();
  const [allModules, setAllModules] = React.useState(modules || []);
  const [responseData, setResponseData] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [ip, setIp] = React.useState('');
  const [rowId, setRowId] = React.useState(null);
  const [moduleId, setModuleId] = React.useState(null);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  // let textInput = React.useRef('')

  React.useEffect(() => {
    setAllModules(modules);
  }, [modules]);

  React.useEffect(() => {
    if (responseData) {
      console.log("inside UseEffect response")
      console.log(responseData)
      for (let i = 0; i < allModules.length; i++) {
        if (moduleId === allModules[i].id) {
          console.log("inside UseEffect response IF 1")
          for (let j = 0; j < (allModules[i].widgets).length; j++) {
            console.log(responseData[0].widget + ":" + allModules[i].widgets[j].widget.id)
            if (responseData[0].widget === allModules[i].widgets[j].widget.id) {
              console.log("inside UseEffect response IF 2")
              let newData = [...allModules];
              newData[i].widgets[j].widget.white_listed_ips.push(responseData[0])
              setAllModules(newData)
              console.log(newData)
            }
          }
        }
      }
    }
  }, [responseData]);

  const handleCheckboxChange = (module, widget, event) => {
    const updatedModules = allModules.filter((m) => m !== module);
    const updatedWidgets = module.widgets;
    const widgetIndex = updatedWidgets.findIndex((w) => w === widget);
    if (widgetIndex > -1) {
      updatedWidgets.splice(widgetIndex, 1);
      const updatedWidget = {
        ...widget,
        widget: {
          ...widget.widget,
          [event.target.name]: !widget.widget[event.target.name],
        },
      };
      updatedWidgets.splice(widgetIndex, 0, updatedWidget);
      updatedModules.push({ ...module, widgets: updatedWidgets });
      // setAllModules(updatedModules);
      setAllModules(arraySortBykeyAllBrowser(updatedModules, "name"));
      updateAWidget({
        id: updatedWidget.widget.id,
        widget_name: updatedWidget.widget.widget_name,
        is_ip_restricted: updatedWidget.widget.is_ip_restricted,
        is_accessible_on_web: updatedWidget.widget.is_accessible_on_web,
        is_accessible_on_mobile: updatedWidget.widget.is_accessible_on_mobile,
        module: module.id,
      })
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Widget Access Area Changed Successfully`,
              "success"
            );
            setTimeout(() => {
              handleCloseSnackbar()
            }, 3000);
          }
          else {
            handleOpenSnackbar(
              `Error occured while changing accessing area of widget please try again`,
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar()
            }, 4000);
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
      //   console.log(response);
      // })
      // .catch(console.error);
    }
  };

  const handleChange = (listItem) => (event, isExpanded) => {
    setExpanded(isExpanded ? listItem : false);
  };

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleChangeIpNumber = (e, id) => {
    setIp(e.target.value);
    setRowId(id)
    console.log("ID: " + id);
  };

  const postAllIps = (ips) => {
    setIp('')
    addIpsInBulk(ips)
      .then((response) => {
        if (response.status === 201) {
          setResponseData(response.data)
          handleOpenSnackbar(
            `IP Address Added Successfully`,
            "success"
          );
          setTimeout(() => {
            setIp('')
            handleCloseSnackbar()
          }, 3000);
        }
        else {
          handleOpenSnackbar(
            `Error occured while adding IP address please try again`,
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar()
          }, 4000);
        }
      })
      .catch((error) => {
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error.response.data[0])),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      });
    // console.log(res);
    // })
    // .catch(console.error);
  };

  const checkIpField = (input) => {
    const regex = /^([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(\d{1,3}\.){3}\d{1,3}$/;
    if (regex.test(input)) {
      return true
    }
    return false;
  }

  const handleSaveIPs = (widget, module_id) => {

    // const data = {
    //   ip_address: ip,
    //   widget: widget
    // }

    // postAllIps(data)

    console.log(widget)
    setModuleId(module_id);
    console.log(module_id)

    const ipRanges = ip.split('-');
    if (ipRanges.length === 1) {
      const splited = ipRanges[0].split('.');
      if (splited.length === 4) {
        if (
          !isNaN(splited[0]) &&
          !isNaN(splited[1]) &&
          !isNaN(splited[2]) &&
          !isNaN(splited[3])
        ) {
          const singleIp = {
            ip_address: ipRanges[0],
            widget,
          };
          postAllIps([singleIp]);
        }
      }
    } else if (ipRanges.length === 2) {
      const splited = ipRanges[0].split('.');
      if (splited.length === 4) {
        if (
          !isNaN(splited[0]) &&
          !isNaN(splited[1]) &&
          !isNaN(splited[2]) &&
          !isNaN(splited[3]) &&
          ipRanges[ipRanges.length - 1]
        ) {
          if (splited[3] < ipRanges[ipRanges.length - 1]) {
            const ips = [];
            for (let i = splited[3]; i <= ipRanges[ipRanges.length - 1]; i++) {
              ips.push({
                ip_address: `${splited[0]}.${splited[1]}.${splited[2]}.${i}`,
                widget,
              });
            }
            postAllIps(ips);
          }
        }
      }
    }
  };

  const handleDelete = (deleteData, widget_id, module_id) => {
    for (let i = 0; i < allModules.length; i++) {
      if (module_id === allModules[i].id) {
        console.log("inside delete IF 1")
        for (let j = 0; j < (allModules[i].widgets).length; j++) {
          if (widget_id === allModules[i].widgets[j].widget.id) {
            console.log("inside delete IF 2")
            let newData = [...allModules];
            (newData[i].widgets[j].widget.white_listed_ips).splice(newData[i].widgets[j].widget.white_listed_ips.findIndex(a => a.id === deleteData), 1)
            setAllModules(newData)
            console.log(newData)
          }
        }
      }
    }
    deleteIp(deleteData)
      .then((response) => {
        if (response.status === 204) {
          handleOpenSnackbar(
            `IP Address Removed Successfully`,
            "success"
          );
          setTimeout(() => {
            setIp('')
            handleCloseSnackbar()
          }, 4000);
        }
        else {
          handleOpenSnackbar(
            `Error occured while removing IP address please try again`,
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar()
          }, 4000);
        }
      })
      .catch((error) => {
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 5000);
      });
  }

  return (
    <div className={classes.mainDiv}>
      {allModules && allModules.length > 0
        ? allModules.map((data, index) => (
          <ExpansionPanel
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <div className={classes.expandPanelSummary}>
                <Typography className={classes.headingOne}>
                  {data.name}
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                      {
                        data.name == "Dispatch Operations" || data.name == "Dispatch Planning" || data.name == "Dispatch Tracking" ? (
                          <TableCell align="center" className={classes.tableHead}>
                            PLANT
                          </TableCell>
                        ) : null
                      }
                      <TableCell align="center" className={classes.tableHead}>
                        WIDGET
                      </TableCell>
                      <TableCell align="center" className={classes.tableHead}>
                        VISIBLE ON
                      </TableCell>
                      <TableCell align="center" className={classes.tableHead}>
                        IP RESTRICTION
                      </TableCell>
                      <TableCell align="center" className={classes.tableHead}>
                        ADD IP ADDRESS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {data.widgets.map((row, i) => ( */}
                    {data.widgets !== undefined && data?.widgets.map((row, i) => (
                      <TableRow key={i}>
                        {
                          data.name == "Dispatch Operations" || data.name == "Dispatch Planning" || data.name == "Dispatch Tracking" ? (
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={classes.tableBody}
                            >
                              {row.widget.plant_name}
                            </TableCell>
                          ) : null
                        }
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.tableBody}
                        >
                          {row.widget.widget_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableBody}
                        >
                          <div className={classes.alignTitleCheckboxes}>
                            <div className={classes.titleWithBlueFont}>
                              <span>DESKTOP</span>
                              <CutomColorCheckbox
                                checked={Boolean(
                                  row.widget.is_accessible_on_web
                                )}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(data, row, e);
                                }}
                                name="is_accessible_on_web"
                              />
                            </div>
                            <div className={classes.titleWithBlueFont}>
                              <span>MOBILE</span>
                              <CutomColorCheckbox
                                checked={Boolean(
                                  row.widget.is_accessible_on_mobile
                                )}
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(data, row, e);
                                }}
                                name="is_accessible_on_mobile"
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.tableBody}
                        >
                          {row.widget.white_listed_ips.length === 0 ? null :
                            row.widget.white_listed_ips.length === 1 ?
                              <button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  textAlign: "center"
                                }}
                                className={classes.dropbtn}
                              >
                                {row.widget.white_listed_ips[0].ip_address}
                                <Delete
                                  style={{ width: "16px", height: "16px", marginLeft: "10px", cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleDelete(row.widget.white_listed_ips[0].id, row?.widget?.id, data?.id)
                                    // deleteIp(row.widget.white_listed_ips[0].id)
                                  }} />
                              </button> : (
                                <div className={classes.dropdown}>
                                  <button className={classes.dropbtn}>{`${row.widget.white_listed_ips.length} IPS`}</button>
                                  <div className={classes.dropdownContent}>
                                    {row.widget.white_listed_ips.map((widgetdata) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center"
                                        }} >
                                        <p className={classes.dropdownContenta} key={widgetdata}>{widgetdata.ip_address}</p>
                                        <IconButton
                                          onClick={(e) => {
                                            e.preventDefault()
                                            handleDelete(widgetdata.id, row?.widget?.id, data?.id)
                                            // deleteIp(data.id)
                                          }}
                                          aria-label="Delete"
                                        >
                                          <Delete style={{ width: "16px", height: "16px" }} />
                                        </IconButton>
                                      </div>

                                    ))}
                                  </div>
                                </div>
                              )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableBody}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <TextField
                            type="text"
                            id="outlined-margin-dense"
                            placeholder="Eg. 176.3.54.23"
                            className={classes.textField}
                            value={
                              row?.widget?.id === rowId
                                ? ip.length === 0
                                  ? ''
                                  : ip
                                : ''
                            }
                            onChange={(e) => {
                              e.preventDefault();
                              handleChangeIpNumber(e, row?.widget?.id);
                            }}
                            size="small"
                            variant="outlined"
                            FormHelperTextProps={{ className: classes.helperText }}
                            helperText={
                              row?.widget?.id === rowId
                                ? ip.length === 0
                                  ? ''
                                  : !checkIpField(ip) ?
                                    "Invalid Ip Address"
                                    : ''
                                : ''
                            }
                          />
                          <IconButton
                            disabled={
                              row?.widget?.id === rowId
                                ? ip.length === 0
                                  ? true
                                  : !checkIpField(ip) ?
                                    true
                                    : false
                                : true
                            }
                            id={row?.widget?.id}
                            onClick={(e) => {
                              e.preventDefault();
                              if (row.widget.white_listed_ips.filter((data) => data.ip_address == ip).length == 0) {
                                handleSaveIPs(row?.widget?.id, data?.id);
                              } else {
                                handleOpenSnackbar(
                                  `IP address already exist`,
                                  "error"
                                );
                                setTimeout(() => {
                                  handleCloseSnackbar()
                                }, 3000);
                              }
                              console.log("clicked")
                            }}
                            aria-label="Delete"
                          >
                            <CheckCircleOutlineOutlinedIcon
                            // style={{
                            //   color: "lightgrey"
                            // }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
        : null
      }
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </div >
  );
}
