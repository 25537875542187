import React, { Component } from "react";
import WebLayout from "../../../layouts/b2b/WebLayout";
import "../../../styles/b2b/web/B2bProfileSettingsWeb.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { primaryColor } from "../../../styles/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import withAuthentication from "../../../hoc/withAuthentication";

const styles = {
  b2bProfileSettingsWebMainBody: {
    paddingTop: "80px",
    height: window.innerHeight,
  },
  b2bProfileSettingsWebInterBody: {
    height: "100%",
    padding: "40px 30px 20px 30px",
  },
  myProfileButtonStyle: {
    textTransform: "capitalize",
    fontSize: "1.1em",
    color: "#1B388B",
  },
};

class B2bProfileSettingsWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar profileSelect>
        <div className={classes.b2bProfileSettingsWebMainBody}>
          <div className={classes.b2bProfileSettingsWebInterBody}>
            <div className="b2b-profile-setting-web-header-button-container">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile");
                }}
                size="small"
                className={classes.myProfileButtonStyle}
              >
                My Profile
              </Button>
              <ChevronRight style={{ color: "#A3A3A3" }} />
              <span style={{ color: "#A3A3A3" }}>Settings</span>
            </div>
            <br />

            <div className="b2b-profile-setting-web-list-container">
              {/* <List
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/b2bProfile/settings/editProfile");
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Edit Profile"
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.2em",
                        color: primaryColor,
                      },
                    }}
                  />
                  <ListItemSecondaryAction style={{ display: "flex" }}>
                    <img
                      style={{ width: "1em", height: "1em" }}
                      src={
                        window.cordova
                          ? `${this.image}${"/arrowRight.svg"}`
                          : "/arrowRight.svg"
                      }
                      alt="img"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List> */}
              {/* <List
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Notifications"
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.2em",
                        color: primaryColor,
                      },
                    }}
                  />
                  <ListItemSecondaryAction style={{ display: "flex" }}>
                    <img
                      style={{ width: "1em", height: "1em" }}
                      src={
                        window.cordova
                          ? `${this.image}${"/arrowRight.svg"}`
                          : "/arrowRight.svg"
                      }
                      alt="img"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List> */}
              <List
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    "/b2bProfile/settings/changePassword"
                  );
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Change Password"
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1.2em",
                        color: primaryColor,
                      },
                    }}
                  />
                  <ListItemSecondaryAction style={{ display: "flex" }}>
                    <img
                      style={{ width: "1em", height: "1em" }}
                      src={
                        window.cordova
                          ? `${this.image}${"/arrowRight.svg"}`
                          : "/arrowRight.svg"
                      }
                      alt="img"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>

            <br />
            <div className="b2b-profile-setting-web-logout-button-container">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.logout();
                }}
                variant="contained"
                style={{
                  backgroundColor: "#1B388B",
                  fontWeight: "bold",
                  color: "white",
                  width: "75%",
                  height: "82%",
                  padding: "12px 16px",
                }}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </WebLayout>
    );
  }
}
export default withAuthentication(withStyles(styles)(B2bProfileSettingsWeb));
