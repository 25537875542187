import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import DispatchOperations from "../DispatchOperations";
class DispatchPlanningWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Layout {...this.props}>
        <DispatchOperations {...this.props} />
      </Layout>
    );
  }
}
export default DispatchPlanningWeb;
