export let CONTRACTBOOKINGHEADER = [
  { label: "Customer Type", key: "customer_type", },
  { label: "Customer L1", key: "customer_l1" },
  { label: "Customer Name", key: "customer_name" },
  { label: "Customer Tag", key: "customer_tag" },
  { label: "Quantity", key: "quantity" },
  { label: "Rate", key: "rate" },
  { label: "Discount", key: "discount" },
  { label: "Premium Rate", key: "premium_charge" },
  { label: "Payment Premium", key: "payment_premium" },
  { label: "Payment Terms", key: "payment_method" },
  { label: "Loading & Insurance Premium", key: "loading_insurance_premium" },
  { label: "Ex Rate", key: "Ex_rate" },
  { label: "Freight Basis", key: "freight_basis" },
  { label: "Freight Rate", key: "freight_charge_percent" },
  { label: "Brokerage Rate", key: "new_brokerage" },
  { label: "Internal Remark", key: "internal_remark" },
]

export let SALESEXPLORERHEADER = [
  { label: "Item Category", key: "item_category_name", },
  { label: "Sales Qty", key: "sales_qty" },
  { label: "Sales Return", key: "sales_return" },
  { label: "Net Sales", key: "net_sale" },
  { label: "Avg Price", key: "avg_price" },
  { label: "Net Sales (Cr)", key: "net_sale_cr" },
]

const FIRSTTABLETAB0 = [
  { key: '', value: 'category_name', id: 'item_category', minWidth: 90 },
  { key: 'ITEM CATEGORY', value: 'category_name', minWidth: 200 },
  { key: 'QUANTITY', value: 'total_booked_quantity', minWidth: 150 },
  { key: 'RATE', value: 'average_weighted_rate', minWidth: 150 },
  { key: 'DISCOUNT', value: 'average_weighted_discount', minWidth: 150 },
  { key: 'PREMIUM', value: 'average_weighted_premium', minWidth: 150 },
  { key: 'NET RATE', value: 'average_weighted_net_rate', minWidth: 150 },
  { key: 'NET AMOUNT', value: 'average_net_amount', minWidth: 150 },
]

const SECONDTABLETAB0 = [
  { key: '', value: 'customer_type', id: 'customer_type', minWidth: 90 },
  { key: 'Customer Type', value: 'customer_type', minWidth: 180 },
  { key: 'Quantity', value: 'total_booked_quantity', minWidth: 150 },
  { key: 'Rate', value: 'average_weighted_rate', minWidth: 150 },
  { key: 'Discount', value: 'average_weighted_discount', minWidth: 150 },
  { key: 'Premium', value: 'average_weighted_premium', minWidth: 150 },
  { key: 'Net Rate', value: 'average_weighted_net_rate', minWidth: 150 },
  { key: 'Net Amount', value: 'average_net_amount', minWidth: 150 },
]

const THIRDTABLETAB0 = [
  { key: '', value: 'customer_name', id: 'customer', minWidth: 90 },
  { key: 'Customer Name', value: 'customer_name', minWidth: 180 },
  { key: 'Quantity', value: 'total_booked_quantity', minWidth: 130 },
  { key: 'Rate', value: 'average_weighted_rate', minWidth: 130 },
  { key: 'Discount', value: 'average_weighted_discount', minWidth: 130 },
  { key: 'Premium', value: 'average_weighted_premium', minWidth: 130 },
  { key: 'Net Rate', value: 'average_weighted_net_rate', minWidth: 130 },
  { key: 'Net Amount', value: 'average_net_amount', minWidth: 130 },
]

const FOURTHTABLETAB0 = [
  { key: '', value: 'customer_name', id: 'id', minWidth: 0 },
  { key: 'Consignee Name', value: 'customer_name', minWidth: 180 },
  { key: 'Quantity', value: 'quantity', minWidth: 130 },
  { key: 'Rate', value: 'rate', minWidth: 110 },
  { key: 'Discount', value: 'discount', minWidth: 110 },
  { key: 'Premium', value: 'premium', minWidth: 110 },
  { key: 'Net Rate', value: 'net_rate', minWidth: 110 },
  { key: 'Net Amount', value: 'net_amount', minWidth: 130 },
]

const COLLAPSETABLETAB0 = [
  { label: 'Item Category', key: 'category_name', minWidth: 80 },
  { label: 'Customer Type', key: 'customer_type', minWidth: 80 },
  { label: 'Customer Name', key: 'customer_name', minWidth: 80 },
  { label: 'Consignee Name', key: 'consignee_name', minWidth: 80 },
  { label: 'Contract Quantity', key: 'quantity', minWidth: 80 },
  { label: 'Rate', key: 'rate', minWidth: 80 },
  { label: 'Discount', key: 'discount', minWidth: 50 },
  { label: 'Premium', key: 'payment_premium', minWidth: 50 },
  { label: 'Net Rate', key: 'net_rate', minWidth: 50 },
  { label: 'Net Amount', key: 'net_amount', minWidth: 80 },
]

const FIRSTTABLETAB1 = [
  { key: '', value: 'category_name', id: 'item_category', minWidth: 90 },
  { key: 'ITEM CATEGORY', value: 'category_name', minWidth: 200 },
  { key: 'NET SALES QTY', value: 'total_booked_quantity', minWidth: 150 },
  { key: 'RATE', value: 'average_weighted_rate', minWidth: 150 },
  { key: 'DISCOUNT', value: 'average_weighted_discount', minWidth: 150 },
  { key: 'PREMIUM', value: 'average_weighted_premium', minWidth: 150 },
  { key: 'NET RATE', value: 'average_weighted_net_rate', minWidth: 150 },
  { key: 'NET AMOUNT', value: 'average_net_amount', minWidth: 150 },
]

const SECONDTABLETAB1 = [
  { key: '', value: 'customer_type', id: 'customer_type', minWidth: 90 },
  { key: 'Customer Type', value: 'customer_type', minWidth: 180 },
  { key: 'Net Sales Qty', value: 'total_booked_quantity', minWidth: 150 },
  { key: 'Rate', value: 'average_weighted_rate', minWidth: 150 },
  { key: 'Discount', value: 'average_weighted_discount', minWidth: 150 },
  { key: 'Premium', value: 'average_weighted_premium', minWidth: 150 },
  { key: 'Net Rate', value: 'average_weighted_net_rate', minWidth: 150 },
  { key: 'Net Amount', value: 'average_net_amount', minWidth: 150 },
]

const THIRDTABLETAB1 = [
  { key: '', value: 'customer_name', id: 'customer', minWidth: 90 },
  { key: 'Customer Name', value: 'customer_name', minWidth: 180 },
  { key: 'Net Sales Qty', value: 'total_booked_quantity', minWidth: 130 },
  { key: 'Rate', value: 'average_weighted_rate', minWidth: 130 },
  { key: 'Discount', value: 'average_weighted_discount', minWidth: 130 },
  { key: 'Premium', value: 'average_weighted_premium', minWidth: 130 },
  { key: 'Net Rate', value: 'average_weighted_net_rate', minWidth: 130 },
  { key: 'Net Amount', value: 'average_net_amount', minWidth: 130 },
]

const FOURTHTABLETAB1 = [
  { key: '', value: 'customer_name', id: 'id', minWidth: 0 },
  { key: 'Consignee Name', value: 'consignee_name', minWidth: 180 },
  { key: 'Net Sales Qty', value: 'item_qty', minWidth: 130 },
  { key: 'Rate', value: 'order__rate', minWidth: 110 },
  { key: 'Discount', value: 'total_discount', minWidth: 110 },
  { key: 'Premium', value: 'premium', minWidth: 110 },
  { key: 'Net Rate', value: 'net_rate', minWidth: 110 },
  { key: 'Net Amount', value: 'net_amount', minWidth: 130 },
]

const COLLAPSETABLETAB1 = [
  { label: 'Item Category', key: 'category_name', minWidth: 80 },
  { label: 'Customer Type', key: 'customer_type', minWidth: 80 },
  { label: 'Customer Name', key: 'customer_name', minWidth: 80 },
  { label: 'Consignee Name', key: 'consignee_name', minWidth: 80 },
  { label: 'Net Sales Quantity', key: 'item_qty', minWidth: 80 },
  { label: 'Rate', key: 'order__rate', minWidth: 80 },
  { label: 'Discount', key: 'total_discount', minWidth: 50 },
  { label: 'Premium', key: 'premium', minWidth: 50 },
  { label: 'Net Rate', key: 'net_rate', minWidth: 50 },
  { label: 'Net Amount', key: 'net_amount', minWidth: 80 },
]




export let tableHeaders = (mode) => {
  if (mode === 'contract_booking')
    return CONTRACTBOOKINGHEADER
  else if (mode === 'sales_explorer')
    return SALESEXPLORERHEADER
  else if (mode === 'firstTableTab0')
    return FIRSTTABLETAB0
  else if (mode === 'secondTableTab0')
    return SECONDTABLETAB0
  else if (mode === 'thirdTableTab0')
    return THIRDTABLETAB0
  else if (mode === 'fourthTableTab0')
    return FOURTHTABLETAB0
  else if (mode === 'collapseTableTab0')
    return COLLAPSETABLETAB0
  else if (mode === 'firstTableTab1')
    return FIRSTTABLETAB1
  else if (mode === 'secondTableTab1')
    return SECONDTABLETAB1
  else if (mode === 'thirdTableTab1')
    return THIRDTABLETAB1
  else if (mode === 'fourthTableTab1')
    return FOURTHTABLETAB1
  else if (mode === 'collapseTableTab1')
    return COLLAPSETABLETAB1
}