import React, { Component, Suspense } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import OrdersList from "./OrdersList";
import Button from "@material-ui/core/Button";
import { secondaryColor, bottomNavigationFontColor, } from "../../../styles/colors";
import Chip from "@material-ui/core/Chip";
import SpinLoader from "../../common/SpinLoader";
import CircularProgressLoader from '../../common/CircularProgressLoader'
import CircularProgress from "@material-ui/core/CircularProgress";
// import CircularProgressLoader from '../../common/CircularProgressLoader'
import Backdrop from '@mui/material/Backdrop';
import backStack from "cordova-back-stack";
import SwipeComponent from "../common/SwipeComponent";
import { Skeleton, Stack } from "@mui/material";
import TileSkeleton from "../common/TileSkeleton";
import { pxToEm } from "../../../methods";
import NorthIcon from '@mui/icons-material/North';

const styles = {
  ordersMainBody: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ordersNavigationContainer: {
    width: "100%",
    display: "flex",
    padding: "20px 0px 10px 20px",
    // justifyContent: "center",
  },
  ordersNavigationStyle: {
    width: "90%",
    height: "56px",
    // margin: "30px 0px 30px 0px",
    display: "flex",
    alignItems: "center",
  },
  orderListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    paddingBottom: "10px",
    maxHeight: window.innerHeight - 190,
    height: window.innerHeight - 190,
    overflowY: "scroll",
  },
  buttonStyle: {
    textTransform: "capitalize",
    fontSize: "1.2em",
    marginRight: "15px",
  },
  myOrderCircleStyle: {
    width: "8px",
    background: "black",
    height: "8px",
    borderRadius: "50%",
    marginLeft: "10px",
    marginTop: "-8px",
  },
  NoOrderMessage: {
    fontSize: "1.2em",
    marginTop: "30vh",
    alignItems: "center",
    color: bottomNavigationFontColor,
  },
};

// class OrderListSkeleton extends React.Component {
//   render() {
//     return (
//       [...Array(6).keys()].map((components, index) => {
//         return (
//           <div style={styles.listContainerStyle}>
//             <div style={styles.listContainerLeftSideStyle}>
//               <Stack spacing={0} style={{ alignItems: 'center' }}>
//                 <Skeleton animation="wave" variant="circular" width={40} height={40} />
//                 <Skeleton animation="wave" variant="text" width={40} height={15} />
//               </Stack>
//             </div>
//             <div style={styles.listContainerMiddleSideStyle}>
//               <Stack spacing={0} style={{ alignItems: 'start' }}>
//                 <Skeleton animation="wave" variant="text" width="90%" height={25} />
//                 <Skeleton animation="wave" variant="text" width="60%" height={20} />
//                 <Skeleton animation="wave" variant="text" width="40%" height={15} />
//               </Stack>
//             </div>
//             <div style={styles.listContainerRightSideStyle}>
//               <Skeleton animation="wave" variant="text" width="80%" height={20} />
//             </div>
//           </div>
//         )
//       })
//     )
//   }
// }
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myOrders: true,
      // showOrder: "ONGOING",
      orders: [],
      pendingOrders: [],
      onGoingOrders: [],
      loadingMoreItems: false,
      isVisible: false,

    };
    this.divRef = React.createRef()
  }

  UNSAFE_componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      if ((String(localStorage.getItem("currentUrl"))).includes('orders/active')) {
        console.log('hjdghdghjgsd')
        localStorage.setItem("returnUrl", "/orders")
        this.props.history.push("/orders");
      }
      else if (String(localStorage.getItem("returnUrl")) === '/orders' && String(localStorage.getItem("currentUrl")) === '/orders') {
        this.props.history.push("/home");
      }
      else {
        this.props.history.push(String(localStorage.getItem("returnUrl")));
      }
    });
  }

  componentDidMount() {
    // this.props.fetchOrderList("ONGOING")
    if (this.props?.showOrder) {
      this.props.fetchOrderList(this.props?.showOrder, null)
    }
    if (this.state.orders.length === 0)
      if (this.props.orders) {
        // console.log(this.props.orders)
        this.setState({
          orders: this.props.orders
        })
      }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   // console.log(this.divRef.current)
  //   if (prevProps?.showOrder !== this.props.showOrder) {
  //     // this.divRef.current.scrollTo(-200, -200)
  //   }
  // }


  handleSelectChip = (key) => {
    // console.log(key)
    this.divRef.current.scrollTo(0, 0);
    let orders = this.props.orders
    this.setState({
      orders,
      // showOrder: key
    }, () => {
      this.props.handleContractSelectChip(key);
    })
    // this.props.fetchOrderList(key, null)
  };

  onScroll = (listFilter, nextURL) => {

    const { scrollTop, scrollHeight, clientHeight, offsetHeight } = this.divRef.current;
    // if (scrollTop > 300) {
    //   this.setState({ isVisible: true });
    // } else {
    //   this.setState({ isVisible: false });
    // }
    const reachedBottom = () => scrollHeight - scrollTop - 350 < offsetHeight;
    const loadMoreItems = () => {
      if (nextURL) {
        this.props.fetchNextOrders(listFilter, nextURL)
      }
    };
    if ((!this.state.loadingMoreItems && reachedBottom())) {
      this.setState({
        loadingMoreItems: true
      })
      loadMoreItems();
    }
    if (this.state.loadingMoreItems) {
      setTimeout(() => {
        this.setState({
          loadingMoreItems: false
        })
      }, 800);
    }
  };

  // scrollToRef = () => {
  //   console.log("clicked");
  //   this.divRef?.current?.scrollTo({ top: 0, behavior: "smooth" })
  // };

  render() {
    const {
      classes,
      showOrder,
      orders,
      ordersListLoader,
      nextURL,
      orderLazyLoader,
      singleOrderLoader,
      isMobile
    } = this.props;
    // console.log(singleOrderLoader)

    return (
      <div className={classes.ordersMainBody}>
        {
          // dispatchLoader &&
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={singleOrderLoader}
            onClick={() => this.props.handleCloseBackDrop()}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        <div className={classes.ordersNavigationContainer} style={{ paddingTop: showOrder === "CLOSED" && 55 }}>
          <div className={classes.ordersNavigationStyle}>
            <div>
              <Button
                className={classes.buttonStyle}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                My Contracts
              </Button>
              {/* {myOrders ? <div className={classes.myOrderCircleStyle} /> : null} */}
            </div>
            {/* <div>
              <Button
                className={classes.buttonStyle}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/orders/dealer");
                }}
              >
                Dealer Orders
              </Button>
              {dealerOrders ? (
                <div className={classes.myOrderCircleStyle} />
              ) : null}
            </div> */}
          </div>
        </div>
        <div style={{ width: "90%", marginBottom: "10px", }}>
          <Chip
            onClick={(e) => {
              e.preventDefault();
              this.handleSelectChip("PENDING");
            }}
            label="New"
            style={{
              // width: "25%",
              width: "max-content",
              minWidth: '25%',
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
              backgroundColor:
                showOrder === "PENDING" ? secondaryColor : "#fff",
              color: showOrder === "PENDING" ? "white" : "black",
              fontWeight: showOrder === "PENDING" ? "bold" : "normal",
              fontSize: window.innerWidth < 375 ? pxToEm(12) : window.innerWidth < 310 && pxToEm(11)
            }}
          />
          <Chip
            onClick={(e) => {
              e.preventDefault();
              this.handleSelectChip("ONGOING");
            }}
            label="Ongoing"
            style={{
              // width: "25%",
              width: "max-content",
              minWidth: '25%',
              marginLeft: "6%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
              backgroundColor:
                showOrder === "ONGOING" ? secondaryColor : "#fff",
              color: showOrder === "ONGOING" ? "white" : "black",
              fontWeight: showOrder === "ONGOING" ? "bold" : "normal",
              fontSize: window.innerWidth < 375 ? pxToEm(12) : window.innerWidth < 310 && pxToEm(11)
            }}
          />
          <Chip
            onClick={(e) => {
              e.preventDefault();
              this.handleSelectChip("CLOSED");
            }}
            label="Completed"
            style={{
              // width: "30%",
              width: "max-content",
              minWidth: '30%',
              marginLeft: "6%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
              backgroundColor: showOrder === "CLOSED" ? secondaryColor : "#fff",
              color: showOrder === "CLOSED" ? "white" : "black",
              fontWeight: showOrder === "CLOSED" ? "bold" : "normal",
              fontSize: window.innerWidth < 375 ? pxToEm(12) : window.innerWidth < 310 && pxToEm(11)
            }}
          />
        </div>
        {showOrder === "CLOSED" &&
          <div style={{ marginTop: "5px", width: '90%', marginBottom: "10px" }}>
            <span style={{ fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 ? pxToEm(13.6) : "0.9em" }}>
              Showing contract completed in the last 14 days
            </span>
          </div>
        }
        <SwipeComponent
          style={{ width: "100%" }}
          rightScreen={() => {
            if (showOrder === "PENDING")
              return this.handleSelectChip("ONGOING")
            else if (showOrder === "ONGOING")
              return this.handleSelectChip("CLOSED")
            else return ''
          }}
          leftScreen={() => {
            if (showOrder === "PENDING")
              return ''
            else if (showOrder === "CLOSED")
              return this.handleSelectChip("ONGOING")
            else return this.handleSelectChip("PENDING")
          }}
        >
          <div
            className={classes.orderListContainer}
            onScroll={() => {
              this.onScroll(showOrder, nextURL)
            }
            }
            ref={this.divRef}
            style={{
              paddingBottom: showOrder === "CLOSED" && 50,
            }}
          >
            {
              ordersListLoader ?
                <div style={{
                  // marginTop: "27.5vh",
                }}>
                  {/* <SpinLoader /> */}
                  <TileSkeleton skeletonType='contracts' />
                </div>
                : orders && orders.length > 0 ?
                  <>
                    {
                      orders.map((data, i) => (
                        <OrdersList
                          {...this.props}
                          handleSingleOrders={this.props.handleSingleOrders}
                          fetchSingleOrderPlantPlanning={this.props.fetchSingleOrderPlantPlanning}
                          fetchSingleOrderPlannedItem={this.props.fetchSingleOrderPlannedItem}
                          fetchSingleOrderLoadingAdvices={this.props.fetchSingleOrderLoadingAdvices}
                          fetchSingleOrderInvoices={this.props.fetchSingleOrderInvoices}
                          order={data}
                          key={data.orderId}
                          status={data.order_status}
                          orderId={data.order_number}
                          date={data.created_at}
                          name={data.item_category.category_name}
                          weight={data.quantity}
                          rate={data.rate}
                          netRate={data?.net_rate}
                        />
                      ))
                    }
                    <div style={{ height: "14vh", marginBottom: "20px", }}>
                      {
                        // orderLazyLoader && <CircularProgressLoader />
                        orderLazyLoader && <TileSkeleton skeletonType='contracts' />
                      }

                    </div>
                  </>
                  : orders && orders.length === 0 ?
                    <span className={classes.NoOrderMessage}>
                      {`No ${showOrder === "all" ? "" : showOrder.charAt(0).toUpperCase() + showOrder.slice(1).toLowerCase()} Contracts available`}
                    </span>
                    : null
            }
            {/* <Button
              style={{
                position: 'sticky',
                bottom: 5, // Adjust as needed
                right: 16, // Adjust as needed
                left: '90%',
                opacity: this.state.isVisible ? 1 : 0,
                justifyContent: 'flex-end',
                transition: 'opacity 0.2s ease-out',
                zIndex: 1000,
              }}
              onClick={() => { setTimeout(() => this.scrollToRef(), 10); }}>
              <NorthIcon style={{
                height: 50,
                width: 50,
                padding: 13,
                color: '#333',
                boxShadow: '-6px 6px 12px #a1a1a1, 6px -6px 12px #a1a1a1',
                background: "linear-gradient(225deg, #fff, #fff)",
                borderRadius: '50%',

              }} />
            </Button> */}
          </div>
        </SwipeComponent>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Orders));
