import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import {
  MenuList,
  MenuItem,
  Popper,
  Paper,
  ClickAwayListener,
  ListItemIcon,
  Tooltip,
  Avatar,
  Divider,
  Typography,
  ListItem,
  List,
  Button,
  ListItemText,
  Grow,
} from '@material-ui/core';
import Badge from '@mui/material/Badge';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from "@material-ui/icons/Info";
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import withUser from '../../../hoc/withUser';
import {
  drawerTextColor,
  notificationIconBackground,
  secondaryGrey,
  secondaryBlueText,
  sloganTextColor,
} from '../../../constants/internal/colors';
import withAuthentication from '../../../hoc/withAuthentication';
import PersonOutline from "@material-ui/icons/PersonOutline";
import moment from "moment";
import { StaticAppVersion, pxToEm, compareValues } from '../../../methods';
import { primaryColor } from '../../../styles/colors';
import B2b_URL from '../../../constants/b2b/B2bRoutes';
import AdminNotificationList from './AdminNotificationList';


const styles = {
  b2bNotificationMainBody: {
    height: window.innerHeight - 74,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    padding: "15px 2px 0px 20px",
  },
  listItemTextStyle: {
    marginLeft: "20px",
    fontSize: "0.8em",
  },
  avatarStyle: {
    height: "45px",
    width: "45px",
    borderRadius: "23%",
    backgroundColor: primaryColor,
    transform: window.innerWidth < 375 && 'scale(0.7)'
  },
  div: {
    position: "absolute",
    top: "-10px",
    right: "20px",
    height: "0px",
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderBottom: "10px solid white",
  },
  pad: {
    paddingLeft: "0px",
    paddingRight: "0px",
    cursor: "pointer",
  },
  fnt: {
    style: { fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.1em" },
  },
  cont: (isMobile) => ({
    padding: isMobile ? "0px" : "20px",
    height: isMobile ? "92%" : "55vh",
    overflowY: "scroll",

  }),
  not: (isMobile) => ({
    height: isMobile ? "100%" : "210px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  loadMoreButtonContainer: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonStyle: {
    textTransform: "capitalize",
    fontSize: "1.2em",
    marginRight: "15px",
  },
  ordersNavigationContainer: {
    width: "100%",
    display: "flex",
    padding: "5px 0px 0px 0px",
    // justifyContent: "center",
  },
  ordersNavigationStyle: {
    width: "100%",
    height: "56px",
    // margin: "30px 0px 30px 0px",
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
  },
  icon: {
    height: "25px",
    width: "25px"
  }
};


const useStyles = makeStyles((theme) => ({
  profile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  mianDivRightIcon: {
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  padding: { padding: '10px 10px' },
  userImage: {
    borderRadius: '50%',
    backgroundColor: drawerTextColor,
    color: secondaryBlueText,
  },
  userName: { fontWeight: 'bold', padding: '5px 15px' },
  userInformationDiv: {
    display: 'flex',
    padding: '5px',
    placeContent: 'center',
    placeItems: 'center',
    cursor: 'pointer',
  },
  dividerColor: {
    backgroundColor: drawerTextColor,
    margin: '0px 12px',
    opacity: 0.5,
  },
  avatarStyle: {
    height: '38px',
    width: '38px',
    borderRadius: '10px',
    backgroundColor: notificationIconBackground,
    color: drawerTextColor,
  },
  listItemTextStyle: {
    marginLeft: '20px',
    fontSize: '0.8em',
  },
  clearAllButton: {
    fontSize: '0.9em',
    color: secondaryBlueText,
  },
  clearAllFont: {
    fontSize: '0.9em',
  },
  clearAllAlignment: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  notificationMainDiv: { padding: '16px', width: '350px', height: '600px', maxHeight: '600px', overflowY: 'auto' },
  noNotification: {
    height: '180px',
    padding: '16px',
    width: '350px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noNotificationIcon: { color: sloganTextColor, marginRight: '10px' },
  popperArrowLogout: {
    position: 'absolute',
    top: '-10px',
    left: '10px',
    height: '0px',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '10px solid white',
  },
  // popperArrowNotification: {
  //   position: 'absolute',
  //   top: '0px',
  //   right: '8px',
  //   height: '0px',
  //   borderLeft: '5px solid transparent',
  //   borderRight: '5px solid transparent',
  //   borderBottom: '10px solid white',
  // },
  popperStyles: {
    paddingTop: '10px',
    zIndex: '1100',
  },
  logOutPoperStyle: {
    zIndex: '1100',
  },
}));

function RightIcon(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [openPopperFor, setOpenPopperFor] = useState(null);
  const [clearAllNotification, setClearAllNotifications] = useState(
    false
  );
  const [notifications, setUserNotifications] = useState([])
  const [notificationsLoader, setNotificationsLoader] = useState(false);
  const [dates, setDates] = useState();
  const [fetchNextNotification, setFetchNextNotification] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState([])
  const history = useHistory();

  React.useEffect(() => {
    fetchNotificationList();
  }, [])

  const fetchNotificationList = () => {
    var dates = [];
    axios
      .get(`${B2b_URL.NOTIFICATIONS}/?V=${StaticAppVersion()}`, {
        headers: {
          Authorization: `JWT ${props.user.token}`,
        },
      })
      .then((response) => {
        let userNotifications = response.data;
        if (userNotifications.length > 0) {
          let unreadNotifications = 0
          userNotifications.map(not => {
            if (!not.isread) {
              unreadNotifications++
            }
          })
          userNotifications.map((item) => {
            if (dates.includes(moment(item.created_at).format("DD MMMM, dddd"))) {
              return;
            } else {
              dates.push(moment(item.created_at).format("DD MMMM, dddd"));
            }
          });
          setNotificationsLoader(false);
          setUserNotifications(userNotifications.sort(
            compareValues("created_at", "desc")
          ))
          setDates(dates)
          setFetchNextNotification(response.data.next)
          setUnreadNotifications(unreadNotifications)
          // this.setState({
          //   notificationLoader: false,
          //   userNotifications: userNotifications.sort(
          //     compareValues("created_at", "desc")
          //   ),
          //   dates,
          //   fetchNextNotification: response.data.next,
          //   unreadNotifications,
          // });
        }
        else if (userNotifications && userNotifications.length === 0) {
          setNotificationsLoader(false)
          // this.setState({
          //   notificationLoader: false,
          // })
        }
      })
      .catch(error => {
        // console.log(error.response, error.response?.data?.detail)
        // if (error.response.status === 403) {
        //   this.setState({
        //     logoutPopoverText: error.response?.data?.detail
        //   }, () => console.log(error.response?.data?.detail, this.state?.logoutPopoverText))
        // }
      })
  }

  const handleToggle = (callingPopper) => (event) => {
    setAnchorEl(event.currentTarget);
    if (openPopperFor === null) {
      setOpen((prevOpen) => !prevOpen);
      setOpenPopperFor(callingPopper);
    } else if (openPopperFor !== null && openPopperFor !== callingPopper) {
      setOpenPopperFor(callingPopper);
    } else if (openPopperFor === callingPopper) {
      setOpen((prevOpen) => !prevOpen);
      setOpenPopperFor(null);
    }
    fetchNotificationList();
  };
  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) return;
    setOpen(false);
    setOpenPopperFor(null);
  };
  const handleClearAllNotification = () => {
    axios.put(`${B2b_URL.MARK_ALL_READ_NOTIFICATION}?id=${props.user?.id}&V=${StaticAppVersion()}`)
      .then(response => {
        setUnreadNotifications((notifications.filter(d => d.is_read === false)).length)
        fetchNotificationList()
      })
      .catch(err => console.log(err))
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      setOpenPopperFor(null);
    }
  }

  const handleProfile = () => {
    history.push('/userProfile');
  }

  return (
    <div className={classes.mianDivRightIcon}>
      <div className={classes.padding}>
        <Tooltip title="Notifications" placement="left">
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                color: primaryColor,
                backgroundColor: "white"
              }
            }}
            badgeContent={
              notifications && (notifications.filter(d => d.is_read === false)).length
            }
          >
            <NotificationsOutlinedIcon
              onClick={
                handleToggle('openNotifications')
              }
            />
          </Badge>
        </Tooltip>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        className={classes.dividerColor}
      />
      <div
        className={classes.userInformationDiv}
        onClick={handleToggle('openProfileDetails')}
      >
        {' '}
        <Tooltip title="User information" placement="bottom">
          <>
            <Avatar
              className={classes.userImage}
              alt={
                props?.user?.full_name ||
                props?.user?.first_name ||
                props?.user?.username
              }
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
            >
              {(
                props?.user?.full_name ||
                props?.user?.first_name ||
                props?.user?.username
              )?.charAt(0) || 'RD'}
            </Avatar>
            <Typography variant="body1" className={classes.userName}>
              {props?.user?.full_name ||
                props?.user?.first_name ||
                props?.user?.username}
            </Typography>
          </>
        </Tooltip>
        {openPopperFor === 'openProfileDetails' ? (
          open ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )
        ) : (
          null
          //<ArrowDropDownIcon />
        )}
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-end'}
        transition
        className={
          openPopperFor === 'openNotifications'
            ? classes.popperStyles
            : classes.logOutPoperStyle
        }
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              {openPopperFor === 'openProfileDetails' ? (
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={handleProfile}
                    >
                      <ListItemIcon>
                        <PersonOutline />
                      </ListItemIcon>
                      My Profile
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        props.logout();
                      }}
                    >
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              ) : openPopperFor === 'openNotifications' ? (
                <ClickAwayListener onClickAway={handleClose}>
                  <div className={classes.b2bNotificationMainBody} style={{ height: window.innerWidth <= 768 && 600, padding: window.innerWidth <= 768 && '12px 6px' }}>
                    <AdminNotificationList {...props} notifications={notifications} dates={dates} />
                  </div>
                </ClickAwayListener>
              ) : null}
              <div
                className={
                  openPopperFor === 'openNotifications'
                    ? classes.popperArrowNotification
                    : openPopperFor === 'openProfileDetails'
                      ? classes.popperArrowLogout
                      : null
                }
              />
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default withAuthentication(withUser(RightIcon));
