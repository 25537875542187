import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  errorMessageColor,
} from "../../../styles/colors";
import "../../../styles/b2b/DispatchTracking.css";
import Done from "@material-ui/icons/Done";
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import moment from "moment";
import { isObjectLike } from "lodash";
import { LensTwoTone } from "@mui/icons-material";
import { handlePaymentStatusText, pxToEm } from "../../../methods";

const styles = {
  dispatchTrackingMainBody: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "16px",
  },
  container: { width: "90%" },
  textStyle: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(14) : "1.12em"
  },
  dateStyle: {
    color: bottomNavigationFontColor,
    fontSize: window.innerWidth < 375 && pxToEm(12)
  },
};
class DispatchTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      laDetails,
      gateIn,
      loadingCompleted,
      dispatched,
      dispatchStatus,
      // laDetails,
      logDetails,
      isMobile,
      details
    } = this.props;
    // console.log(laDetails)
    let LADate = "06-13-2020"
    let approvedDate = ""
    let gateInDate = ""
    let loadingDate = ""
    let packedDate = ""
    let billedDate = ""
    let dispatchedDate = ""
    logDetails && logDetails.map(log => {


      if (log.status === "APPROVED") {
        approvedDate = log.created_at
      }
      if (log.status === "GATEIN") {
        gateInDate = (log.created_at)
      }
      if (log.status === "LOADING") {
        loadingDate = (log.created_at)
      }
      if (log.status === "PACKED") {
        packedDate = (log.created_at)
      }
      if (log.status === "BILLED") {
        billedDate = (log.created_at)
      }
      if (log.status === "DISPATCHED") {
        dispatchedDate = (log.created_at)
      }
    })

    console.log(laDetails, details)



    return (
      <div className={classes.dispatchTrackingMainBody}>
        <div className={classes.container}>
          {dispatchStatus === "DISPATCHED" ? (
            <>
              <div className={handlePaymentStatusText(details.la_due_date, details.payment_status) > 0
                ? "step active"
                : "step completed"}>
                <div className="v-stepper">
                  <div
                    className="circle"
                    style={{
                      borderColor: handlePaymentStatusText(details.la_due_date, details.payment_status) <= 0 && errorMessageColor,
                      backgroundColor: handlePaymentStatusText(details.la_due_date, details.payment_status) <= 0 && '#FFF'
                    }}
                  >
                    {
                      handlePaymentStatusText(details.la_due_date, details.payment_status) <= 0
                        ? <PriorityHighOutlinedIcon style={{ color: errorMessageColor, height: "0.8em", width: "0.8em" }} />
                        : handlePaymentStatusText(details.la_due_date, details.payment_status) === 'PAID' &&
                        <Done
                          style={{ color: "white", height: "0.8em", width: "0.8em" }}
                        />
                    }
                  </div>
                  <div
                    className="line"
                    style={{ borderLeftColor: handlePaymentStatusText(details.la_due_date, details.payment_status) <= 0 && errorMessageColor }}
                  ></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>
                    {
                      handlePaymentStatusText(details.la_due_date, details.payment_status) > 0
                        ? `Due in ${handlePaymentStatusText(details.la_due_date, details.payment_status)} Days`
                        : handlePaymentStatusText(details.la_due_date, details.payment_status) === 0
                          ? `Due Today`
                          : handlePaymentStatusText(details.la_due_date, details.payment_status) < 0
                            ? `Overdue by ${Math.abs(handlePaymentStatusText(details.la_due_date, details.payment_status))} Days`
                            : handlePaymentStatusText(details.la_due_date, details.payment_status) === 'PAID' && 'Paid'
                    }
                  </span>
                  <span className={classes.dateStyle}>
                    {
                      (handlePaymentStatusText(details.la_due_date, details.payment_status) <= 0 ||
                        handlePaymentStatusText(details.la_due_date, details.payment_status) > 0) &&
                      `Due Date: `
                    }
                    {handlePaymentStatusText(details.la_due_date, details.payment_status) === 'PAID'
                      ? moment(details.payment_date).format("DD-MMM-YYYY")
                      : moment(details.la_due_date).format("DD-MMM-YYYY")
                    }
                  </span>
                </div>
              </div>
              <div className="step completed">
                <div className="v-stepper">
                  <div className="circle">
                    <Done
                      style={{ color: "white", height: "0.8em", width: "0.8em" }}
                    />
                  </div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Dispatched</span>
                  <span className={classes.dateStyle}>{moment(dispatchedDate, "DD-MM-yyyy hh:mm A").format("DD-MMM-yyyy hh:mm A")}</span>
                </div>
              </div>
            </>
          ) : (
            <div className="step active">
              <div className="v-stepper">
                <div className="circle"></div>
                <div className="line"></div>
              </div>
              <div className="content">
                <span className={classes.textStyle}>Dispatched</span>
                <span className={classes.dateStyle}>--</span>
              </div>
            </div>
          )}

          {dispatchStatus === "DISPATCHED"
            || dispatchStatus === "BILLED"
            ? (
              <div className="step completed">
                <div className="v-stepper">
                  <div className="circle">
                    <Done
                      style={{ color: "white", height: "0.8em", width: "0.8em" }}
                    />
                  </div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Billed</span>
                  <span className={classes.dateStyle}>{moment(billedDate, "DD-MM-yyyy hh:mm A").format("DD-MMM-yyyy hh:mm A")}</span>
                </div>
              </div>
            ) : (
              <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Billed</span>
                  <span className={classes.dateStyle}>--</span>
                </div>
              </div>
            )}

          {/* {dispatchStatus === "PACKED" ||
            // dispatchStatus === "LOADING" ||
            dispatchStatus === "ALLOTED"
            || dispatchStatus === "DISPATCHED"
            || dispatchStatus === "BILLED"
            ? (
              <div className="step completed">
                <div className="v-stepper">
                  <div className="circle">
                    <Done
                      style={{ color: "white", height: "0.8em", width: "0.8em" }}
                    />
                  </div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Packed</span>
                  <span className={classes.dateStyle}>{moment(packedDate, "DD-MM-yyyy hh:mm A").format("DD-MMM-yyyy h:mm A")}</span>
                </div>
              </div>
            ) : (
              <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Packed</span>
                  <span className={classes.dateStyle}>--</span>
                </div>
              </div>
            )} */}
          {/* {dispatchStatus === "LOADING" ||
            dispatchStatus === "PACKED" ||
            dispatchStatus === "ALLOTED"
            || dispatchStatus === "DISPATCHED"
            || dispatchStatus === "BILLED"
            ? (
              <div className="step completed">
                <div className="v-stepper">
                  <div className="circle">
                    <Done
                      style={{ color: "white", height: "0.8em", width: "0.8em" }}
                    />
                  </div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Loading</span>
                  <span className={classes.dateStyle}>{moment(loadingDate, "DD-MM-yyyy hh:mm A").format("DD-MMM-yyyy hh:mm A")}</span>
                </div>
              </div>
            ) : (
              <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Loading</span>
                  <span className={classes.dateStyle}>--</span>
                </div>
              </div>
            )} */}
          {dispatchStatus === "GATEIN"
            || dispatchStatus === "ALLOTED"
            || dispatchStatus === "DISPATCHED"
            || dispatchStatus === "BILLED"
            || dispatchStatus === "LOADING"
            || dispatchStatus === "PACKED"
            ? (
              <div className="step completed">
                <div className="v-stepper">
                  <div className="circle">
                    <Done
                      style={{ color: "white", height: "0.8em", width: "0.8em" }}
                    />
                  </div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Gate In</span>
                  <span className={classes.dateStyle}>{moment(gateInDate, "DD-MM-yyyy hh:mm A").format("DD-MMM-yyyy hh:mm A")}</span>
                </div>
              </div>
            ) : (
              <div className="step active">
                <div className="v-stepper">
                  <div className="circle"></div>
                  <div className="line"></div>
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Gate In</span>
                  <span className={classes.dateStyle}>--</span>
                </div>
              </div>
            )
          }
          {dispatchStatus === "GATEIN"
            || dispatchStatus === "ALLOTED"
            || dispatchStatus === "DISPATCHED"
            || dispatchStatus === "BILLED"
            || dispatchStatus === "LOADING"
            || dispatchStatus === "PACKED"
            || dispatchStatus === "APPROVED"
            ? (
              <div className="step completed"
                style={{ marginBottom: isMobile ? "" : 30 }}
              >
                <div className="v-stepper">
                  <div className="circle">
                    <Done
                      style={{ color: "white", height: "0.8em", width: "0.8em" }}
                    />
                  </div>
                  {/* <div className="line"></div> */}
                </div>
                <div className="content">
                  <span className={classes.textStyle}>
                    Approved
                  </span>
                  <span className={classes.dateStyle}>{moment(approvedDate, "DD-MM-yyyy hh:mm A").format("DD-MMM-yyyy hh:mm A")}</span>
                </div>
              </div>
            ) : (
              <div className="step active"
                style={{ marginBottom: isMobile ? "" : 30 }}
              >
                <div className="v-stepper">
                  <div className="circle"></div>
                  {/* <div className="line"></div> */}
                </div>
                <div className="content">
                  <span className={classes.textStyle}>Approval Pending</span>
                  <span className={classes.dateStyle}>--</span>
                </div>
              </div>)
          }
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(DispatchTracking);
