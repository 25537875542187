import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManageUnitData from "../../../../components/internal/masterManagement/unit/ManageUnitData";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { isAccessibleModule } from "../../../../methods";
import { Route } from "react-router-dom";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayUnitData from "../../../../components/internal/masterManagement/unit/web";

class ManageUnitContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      unitOperation: null,
      selectedUnitId: null,
      selectedUnitData: [],
      selectedUnitDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let unitOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ unitOperation }, () => console.log(1));
    if (unitOperation !== "add") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedUnitData(id);
      this.setState({ selectedUnitId: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let unitOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedUnitId =
      unitOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (unitOperation !== state.unitOperation) {
      updatedData.set("unitOperation", unitOperation);
    }
    if (selectedUnitId !== state.selectedUnitId) {
      updatedData.set("selectedUnitId", selectedUnitId);
    }
    if (updatedData.size > 0) {
      return {
        unitOperation: updatedData.has("unitOperation")
          ? updatedData.get("unitOperation")
          : unitOperation,
        selectedUnitId: updatedData.has("selectedUnitId")
          ? updatedData.get("selectedUnitId")
          : [],
      };
    }
    return null;
  }
  handleFetchSelectedUnitData = async (selectedUnitId) => {
    await this.setState({ selectedUnitDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.UNIT}${selectedUnitId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedUnitData: response.data,
              selectedUnitDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedUnitDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected bank data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedUnitDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching selected bank data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleUnitMasterDataAdd = (unitData) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.UNIT, unitData, this.getOptions(this.props.user))
        .then((response) => {
          this.handleFetchSelectedUnitData(response.data.id);
          this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });
  handleUnitMasterDataUpdate = (unitData, unitId) =>
    new Promise((resolve, reject) => {
      axios
        .put(
          `${APIROUTES.UNIT}${unitId}/`,
          unitData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedUnitData(unitId);
          this.props.getUnitList();
          resolve(response);
        })
        .catch(reject);
    });
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  render() {
    const { unitOperation } = this.state;
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    // if (isAccessibleModule(this.props?.user, "Banks"))
    else {
      return (
        <>
          {unitOperation === "view" ? (
            <DisplayUnitData {...this.props} {...this.state} />
          ) : (
            <ManageUnitData
              {...this.props}
              {...this.state}
              unitNamesLists={this.props?.unitList}
              handleUnitMasterDataUpdate={this.handleUnitMasterDataUpdate}
              handleUnitMasterDataAdd={this.handleUnitMasterDataAdd}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManageUnitContainer);
