import { useEffect, useState } from "react";
import { grey } from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import Bread from "../../common/Breadcrubs";
import { isAccessibleWidget } from "../../../../methods";
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { Typography } from "@material-ui/core";
import ListNavigator from "../../common/ListNavigator";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";


const SalesTarget = (props) => {

  const [distributorsCard, setDistributorsCard] = useState([]);
  const [dealersCard, setDealersCard] = useState([]);
  const [salesPersonCard, setSalesPersonCard] = useState([]);

  useEffect(() => {
    console.log(props)
    if (props.user) {
      const distributorsCard = [];
      const dealersCard = [];
      const salesPersonCard = [];

      if (1 > 0
        // isAccessibleWidget(props.user, 'distributor_scheme_sales_target')
      ) {
        distributorsCard.push({
          title: "Scheme",
          subtitle: "Add distributor scheme",
          path: INTERNALROUTES.SALESTARGET_DISTRIBUTOR_SCHEME,
          // loading: props.itemCategoriesDataFetchLoader,
          icon: () => <SchemaOutlinedIcon />,
        });
      }
      if (1 > 0
        // isAccessibleWidget(props.user, 'distributor_manage_sales_target')
      ) {
        distributorsCard.push({
          title: "Manage Distributor",
          subtitle: "Manage distributpor scheme",
          path: INTERNALROUTES.SALESTARGET_DISTRIBUTOR_MANAGE,
          // loading: props.itemCategoriesDataFetchLoader,
          icon: () => <ManageAccountsOutlinedIcon />,
        });
      }
      if (1 > 0
        // isAccessibleWidget(props.user, 'dealer_scheme_sales_target')
      ) {
        dealersCard.push({
          title: "Scheme",
          subtitle: "Add dealer scheme",
          path: INTERNALROUTES.SALESTARGET_DEALER_SCHEME,
          // loading: props.itemCategoriesDataFetchLoader,
          icon: () => <SchemaOutlinedIcon />,
        });
      }
      if (1 > 0
        // isAccessibleWidget(props.user, 'dealer_manage_sales_target')
      ) {
        dealersCard.push({
          title: "Manage Dealer",
          subtitle: "Manage dealer scheme",
          path: INTERNALROUTES.SALESTARGET_DEALER_MANAGE,
          // loading: props.itemCategoriesDataFetchLoader,
          icon: () => <ManageAccountsOutlinedIcon />,
        });
      }
      if (1 > 0
        // isAccessibleWidget(props.user, 'salesPerson_target_sales_target')
      ) {
        salesPersonCard.push({
          title: "Target",
          subtitle: "Add sales person target",
          path: INTERNALROUTES.SALESTARGET_SALESPERSON_TARGET,
          // loading: props.itemCategoriesDataFetchLoader,
          icon: () => <SchemaOutlinedIcon />,
        });
      }
      if (1 > 0
        // isAccessibleWidget(props.user, 'salesPerson_manage_sales_target')
      ) {
        salesPersonCard.push({
          title: "Manage Sales Person",
          subtitle: "Manage sales person",
          path: INTERNALROUTES.SALESTARGET_SALESPERSON_MANAGE,
          // loading: props.itemCategoriesDataFetchLoader,
          icon: () => <ManageAccountsOutlinedIcon />,
        });
      }
      setDistributorsCard(distributorsCard);
      setDealersCard(dealersCard);
      setSalesPersonCard(salesPersonCard);
    }
  }, [])

  const {
    isMobile,
  } = props;
  return (
    <Layout {...props}>
      <div style={{ width: isMobile ? '98%' : "100%" }}>
        <div style={{ width: "100%" }}>
          <Bread
            data={[
              {
                title: `Sales Target`,
              },
            ]}
          />
          <>
            <Typography className="listHeader">
              Distributor
            </Typography>
            <ListNavigator
              {...props}
              navigations={distributorsCard}
            />
            <Typography className="listHeader">
              Dealer
            </Typography>
            <ListNavigator
              {...props}
              navigations={dealersCard}
            />
            <Typography className="listHeader">
              Sales Person
            </Typography>
            <ListNavigator
              {...props}
              navigations={salesPersonCard}
            />
          </>
        </div>
      </div>
    </Layout>
  );
}

export default SalesTarget;
