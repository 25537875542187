import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField } from "@material-ui/core";
import {
  grey,
  primaryColor,
  drawerTextColor,
} from "../../../../constants/internal/colors";
import axios from "axios";
import { options } from "../../../../methods";
import { pxToEm } from "../../../../methods";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  datePicker: {
    color: primaryColor,
    textColor: drawerTextColor,
    calendarTextColor: drawerTextColor,
    selectColor: primaryColor,
    selectTextColor: drawerTextColor,
    calendarYearBackgroundColor: primaryColor,
    headerColor: primaryColor,
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "45%" },
});
export default function ManageCustomerPersonalDetails(props) {
  const classes = useStyles();
  // console.log(props)




  return (
    <>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation}>
          This information will be used to LOGIN in the RIPL app.
        </Typography>
        <Typography className={classes.fillingInformation}>
          Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <div className={classes.dataDisplay}>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>FIRST NAME *</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerFirstName}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="FIRST NAME"
                id="outlined-size-small"
                value={props.customerFirstName}
                onChange={props.handleChange("customerFirstName")}
                variant="outlined"
                size="small"
                error={props.customerFirstNameError}
                helperText={
                  props.customerFirstNameError ? "Invalid first name" : ""
                }
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>LAST NAME *</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerLastName}
              </Typography>
            ) : (
              <TextField
                type="text"
                className={classes.formControl}
                label="LAST NAME"
                id="outlined-size-small"
                value={props.customerLastName}
                onChange={props.handleChange("customerLastName")}
                variant="outlined"
                size="small"
                error={props.customerLastNameError}
                helperText={
                  props.customerLastNameError ? "Invalid last name" : ""
                }
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>
              DATE OF BIRTH
            </Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.customerDateOfBirth}
              </Typography>
            ) : (
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                className={classes.formControl}
              >
                <KeyboardDatePicker
                  className={classes.formControl}
                  size="small"
                  inputVariant="outlined"
                  label="DATE OF BIRTH"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  value={props.customerDateOfBirth}
                  onChange={props.handleDateOfBirthChange}
                  placeholder="e.g.: DD/MM/YYYY"
                  InputAdornmentProps={{ position: "start" }}
                  InputProps={{ className: classes.datePicker }}
                />
              </MuiPickersUtilsProvider>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
