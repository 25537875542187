import React, { Component } from "react";
import "../../../../styles/b2b/web/DrawerContent.css";
import Home from "@material-ui/icons/Home";
import PersonOutline from "@material-ui/icons/PersonOutline";
import { withRouter } from "react-router";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import StaticVersionDisplay from "../../../common/StaticVersionDisplay";
import withAuthentication from "../../../../hoc/withAuthentication";
import Assignment from "@material-ui/icons/Assignment";
import LocalShipping from "@material-ui/icons/LocalShipping";
import { drawerTextColor, logoBackground } from "../../../../constants/internal/colors";
import { Button, Dialog } from "@material-ui/core";
import { primaryColor } from "../../../../styles/colors";

const styles = {
  dialogContainer: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "15px 20px 20px 30px",
    width: 450
  },
  dialogInnerContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  logOutButton: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    textTransform: 'none',
  }
}
class DrawerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { profileSelect, dailyRates, orderSelect, dispatchSelect, logoutPopoverText } =
      this.props;
    const { home, profile, order, dispatch } = this.state;
    return (
      <div>
        <div className="b2b-web-drawer-header-container" style={{ height: (window.innerWidth > 768 && window.innerWidth <= 1025) && '15vh' }}>
          <div
            className="b2b-web-drawer-header-image-container"
            style={{
              backgroundColor: logoBackground
            }}
          >
            <img
              className="b2b-web-drawer-header-image-style"
              src={
                window.cordova
                  ? `${this.image}${"/RealDealWeb2.png"}`
                  : "/RealDealWeb2.png"
              }
              alt="img"
            />
          </div>
        </div>
        <List
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <ListItem
            button
            style={{
              width: "95%",
              backgroundColor: home || dailyRates ? "#0969B3" : "#1B388B",
              borderRadius: home || dailyRates ? "20px" : "20px",
              transform: 'scale(0.9)'
              //height: "42px",
            }}
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                {
                  home: true,
                  order: false,
                  dispatch: false,
                  brokerage: false,
                  profile: false,
                },
                () => {
                  this.props.history.push("/home");
                }
              );
            }}
          >
            <ListItemIcon
              style={{
                minWidth: "42px",
              }}
            >
              <Home style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary={"Home"} style={{ fontSize: "1.17em" }} />
          </ListItem>

          {/* <ListItem
            button
            style={{
              width: "95%",
              backgroundColor: profile || profileSelect ? "#0969B3" : "#1B388B",
              borderRadius: profile || profileSelect ? "20px" : "20px",
              // height: "42px",
            }}
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                {
                  profile: true,
                  brokerage: false,
                  dispatch: false,
                  home: false,
                  order: false,
                },
                () => {
                  this.props.history.push("/b2bProfile");
                }
              );
            }}
          >
            <ListItemIcon style={{ minWidth: "42px" }}>
              <PersonOutline style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary={"My Profile"}
              primaryTypographyProps={{
                style: { fontSize: "0.99em" },
              }}
            />
          </ListItem> */}
          <ListItem
            button
            style={{
              width: "95%",
              backgroundColor: order || orderSelect ? "#0969B3" : "#1B388B",
              borderRadius: order || orderSelect ? "20px" : "20px",
              transform: 'scale(0.9)'
              // height: "42px",
            }}
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                {
                  profile: false,
                  brokerage: false,
                  dispatch: false,
                  home: false,
                  order: true,
                },
                () => {
                  this.props.history.push("/orders");
                }
              );
            }}
          >
            <ListItemIcon style={{ minWidth: "42px" }}>
              <Assignment style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary={"Contracts"}
              primaryTypographyProps={{
                style: { fontSize: "0.99em" },
              }}
            />
          </ListItem>
          <ListItem
            button
            style={{
              width: "95%",
              backgroundColor:
                dispatch || dispatchSelect ? "#0969B3" : "#1B388B",
              borderRadius: dispatch || dispatchSelect ? "20px" : "20px",
              transform: 'scale(0.9)'
              // height: "42px",
            }}
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                {
                  profile: false,
                  brokerage: false,
                  dispatch: true,
                  home: false,
                  order: false,
                },
                () => {
                  this.props.history.push("/dispatch");
                }
              );
            }}
          >
            <ListItemIcon style={{ minWidth: "42px" }}>
              <LocalShipping style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary={"Dispatch"}
              primaryTypographyProps={{
                style: { fontSize: "0.99em" },
              }}
            />
          </ListItem>
        </List>

        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>
            <StaticVersionDisplay />
          </span>
        </div>
        <Dialog
          keepMounted
          open={Boolean(logoutPopoverText)}
          PaperProps={{
            style: {
              borderRadius: "15px",
              alignItems: 'flex-start',
              padding: 8
            },
          }}
        >
          <div style={styles.dialogContainer}>
            <p>
              {logoutPopoverText}
            </p>
            <div style={styles.dialogInnerContainer}>
              <Button
                style={styles.logOutButton}
                // style={{ textTransform: 'none', backgroundColor: primaryColor, }}
                onClick={(e) => this.props.logout()}
                variant="outlined"
              >
                Logout
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withAuthentication(withRouter(DrawerContent));
