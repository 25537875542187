import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
    Paper,
    Divider,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Typography,
    Button,
    TextField,
    CircularProgress,
    Tooltip,
    Checkbox,
    TablePagination,
    IconButton,
    Menu,
    MenuItem,
    DialogActions,
    Select,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl
} from "@material-ui/core";
import {
    grey,
    secondaryGrey,
    whiteSmoke,
    drawerTextColor,
    successButtonBackground,
    secondaryBlueText,
    primaryColor
} from "../../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { pxToEm } from "../../../../../methods";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import "../../../../../styles/internal/scroll.css";
import ToolTipComponent from "../../../common/ToolTipComponent";
import { TableFooter } from "@mui/material";
import { useHistory } from "react-router-dom";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import APIROUTES from "../../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import moment from "moment";
import SnackbarComponent from "../../../common/SnackbarComponent";
import { checkApiError, hasApproveAccess, hasSpecialAccess, hasUpdateAccess, hasWriteAccess } from '../../../../../methods';
import { handlePrintInvoicingpreview } from "../../../dispatchOperation/InvoicingPdf";
import { Print } from "@mui/icons-material";
import { constant } from "lodash";
import { handlePrintSubcontractOverviewTable } from "../../../common/SubcontractPdf";

const CustomCheckbox = withStyles({
    root: {
        color: secondaryBlueText,
        "&$checked": {
            color: secondaryBlueText,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles({
    // table: {
    //     minWidth: 650,
    // },
    formControl: {
        width: "80%",
    },
    contractStatusCustomerBox: {

        border: "1px solid gainsboro",
        borderRadius: "15px",
        padding: "5px 20px 20px 20px",
    },
    loaderStyle: {
        color: primaryColor
    },
    removeIconDimensions: {
        height: 18,
        width: 18,
        margin: "0px 5px 0px 5px",
        color: grey,
        cursor: "pointer",
    },
    tableHeader: {
        backgroundColor: whiteSmoke,
        color: grey,
        fontFamily: 'Poppins',
    },
    tableHead: {
        fontFamily: 'Poppins',
        fontSize: pxToEm(13),
        fontWeight: "bold",
        color: grey,
        textAlign: "center"
    },
    tableBody: {
        fontSize: pxToEm(13),
        fontFamily: 'Poppins',
    },
    addNewButton: {
        width: "181px",
        height: "43px",
        border: `1px dashed ${secondaryGrey}`,
        borderRadius: "4px",
        opacity: "1",
        color: grey,
        fontSize: pxToEm(13),
        fontWeight: "bold",
    },
    customerTypeTitle: {
        fontWeight: "bold",
        margin: "20px 0px 0px 0px",
        fontSize: pxToEm(16),
        color: secondaryBlueText,
    },
    saveButton: {
        backgroundColor: successButtonBackground,
        color: drawerTextColor,
        marginRight: 10,
    },
    addAndSaveButton: {
        display: "flex",
        alignItems: "center",
        margin: "0px 0px 30px 20px",
        justifyContent: "space-between",
    },
    saveCancelButton: {
        display: "flex",
        placeSelf: "strech",
    },
    alignStateText: {
        display: "flex",
        placeContent: "flex-start",
        alignItems: "center",
        paddingLeft: "20px",
    },
    rightSpacing: {
        display: "flex",
        marginLeft: "10px",
        fontSize: "0.8em",
        alignItems: "center",
        fontWeight: "normal",
    },
    editIcon: {
        heigth: "15px",
        width: "15px",
    },
    iconContainer: {
        height: 30,
        width: 30,
        background: "yellow",
        borderRadius: 100,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: whiteSmoke,
        overflow: "hidden",
        color: grey,
    },
    dataFieldsForCheckbox: {
        display: "flex",
        alignItems: "center",
        color: secondaryBlueText,
    },
    captionDetails: {
        fontSize: pxToEm(13),
        fontWeight: "bold",
        color: grey,
        marginBottom: 10,
    },
    popoverText: {
        display: "inline",
        fontFamily: 'Poppins',
        fontSize: "inherit",
        fontWeight: '400',
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            backgroundColor: 'rgba(255,255,255,1)',
            color: '#000',
            marginBottom: 10
            // borderRadius: 20,
        }

    },
});
const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: "10px 5px 10px 5px",
            },
        },
    },
});

export default function SubContractsPlantPlanningTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isSubcontractNoCopied, setIsSubcontractNoCopied] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [copiedRows, setCopiedRows] = useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        const _rows = props.subcontractRows?.map(row => ({ id: row.id, isSubcontractNoCopied: false }))
        setCopiedRows(_rows)
        setRows(props.subcontractRows)
    }, [props?.subcontractRows])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const classes = useStyles();
    const history = useHistory();
    // const rows =
    //   props.props.customerAdditionalContacts.length > 0
    //     ? props.props.customerAdditionalContacts
    //     : [];
    const [
        customerAdditionalContactOperation,
        setCustomerAdditionalContactOperation,
    ] = React.useState("view");
    const handleCustomerAdditionalContactOperationChange = (operation) => {
        setCustomerAdditionalContactOperation(operation);
    };


    // const rows = [
    //     { id: 1, Date: "12-Mar-2024", payment_doc_number: "DOC123", payment_bank_name: "STATE BANK OF INDIA", payment_doc_date: "20-Mar-2024", payment_doc_value: "Rs. 1,00,00,000", buyer_name: "SOLANKI FREEZCO", consignee_name: "SOLANKI FREEZCO", customer_uid: "1876", payment_terms: "LC", type: "VCL", Total_quantity: "70.000", la_pending: "2.000", la_inPlant: "6.000", la_dispatched: "", la_cancelled: "2.000", open_qty: "2.000" },
    //     { id: 2, Date: "13-Mar-2024", payment_doc_number: "", payment_bank_name: "RIPL BANK", payment_doc_date: "15-Mar-2024", payment_doc_value: "", buyer_name: "NITESH SINGH", consignee_name: "NITESH SINGH", payment_terms: "Advance", type: "Non-VCL", customer_uid: "1796", Total_quantity: "70.000", la_pending: "1.000", la_inPlant: "2.000", la_dispatched: "", la_cancelled: "", open_qty: "1.000" },
    // ];

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [selectedPaymentRow, setSelectedPaymentRow] = React.useState({});
    const [rejectOrderDialogOpen, setRejectOrderDialogOpen] = React.useState(false);
    const [reasonMaster, setReasonMaster] = React.useState([]);
    const [snackbarData, setSnackbarData] = React.useState({
        openSnackbar: false,
        snackbarMessage: ``,
        snackbarType: "",
    });
    const [reject_order_reason, setReject_order_reason] = React.useState(null)

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarData({
            ...snackbarData,
            openSnackbar: false,
            snackbarMessage: "",
            snackbarType: "",
        });
    };


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handlePopoverOpen3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handlePopoverClose3 = () => {
        setAnchorEl3(null);
    };

    const open3 = Boolean(anchorEl3);

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (e) => {
        e.stopPropagation()
        setMenuAnchorEl(null);
    };

    const handlePopoverOpen2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handlePopoverClose2 = () => {
        setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);


    const onCopyText = (subcontractNo, row) => {
        navigator.clipboard.writeText(subcontractNo)
        const _rows = copiedRows.map(c => ({ id: c.id, isSubcontractNoCopied: c.id == row.id }))
        setCopiedRows(_rows)
        setTimeout(() => {
            const _rrows = copiedRows.map(c => ({ id: c.id, isSubcontractNoCopied: false }))
            setCopiedRows(_rrows)
        }, 1000);
    };

    const getOptions = (user) => {
        return {
            headers: {
                Authorization: `JWT ${user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    };

    const handleOpenSnackbar = (message, type) => {
        setSnackbarData({
            openSnackbar: true,
            snackbarMessage: message,
            snackbarType: type,
        });
    };

    const handleSnackbarMessageBox = (data) => {
        return (
            data.map((item, index) => {
                return (
                    <>
                        <p style={{ margin: 0 }}>
                            {item}
                        </p>
                    </>)
            })
        )
    }

    const fetchReasonMaster = (moduleName) => {
        return axios.get(
            `${APIROUTES.GET_REASON}?module=${moduleName}&active=${true}`,
            // `${APIROUTES.GET_REASON}`,
            getOptions(props.user)
        );
    };

    const fetchSubContractReasonMaster = async () => {
        await fetchReasonMaster("Contracts")
            .then((response) => {
                if (response.status === 200) {
                    setReasonMaster(response.data);
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching reason master",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    handleSnackbarMessageBox(checkApiError(error.response.data)),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const cancelSubContract = (subcontractData, subcontractId) => {
        return axios
            .patch(
                `${APIROUTES.CANCEL_SUB_CONTRACT}${subcontractId}/`,
                subcontractData,
                getOptions(props.user)
            )
    }

    const reopenSubContract = (subcontractData, subcontractId) => {
        return axios
            .patch(
                `${APIROUTES.REOPEN_SUB_CONTRACT}${subcontractId}/`,
                subcontractData,
                getOptions(props.user)
            )
    }

    const handleSubContractCancel = (subcontractId, status) => {
        let cancelSubcontractData = {
            reason: reject_order_reason,
            cancel: true
        };
        cancelSubContract(cancelSubcontractData, subcontractId)
            .then((response) => {
                if (response.status === 200) {
                    handleOpenSnackbar(
                        `Subcontract cancelled successfully`,
                        "success"
                    );
                    setTimeout(() => {
                        props.handleFetchPlantPlanningData()
                        handleCloseSnackbar();
                    }, 3000);

                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const handleSubContractReOpen = (subcontractId, status) => {
        let reopenSubcontractData = {
            reopen: true
        };
        reopenSubContract(reopenSubcontractData, subcontractId)
            .then((response) => {
                if (response.status === 200) {
                    handleOpenSnackbar(
                        `Subcontract reopened successfully`,
                        "success"
                    );
                    setTimeout(() => {
                        props.handleFetchPlantPlanningData()
                        handleCloseSnackbar();
                    }, 3000);

                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const handleConfirmSubcontractCancel = (id, orderStatus = 'reject') => {
        setRejectOrderDialogOpen(false);
        handleSubContractCancel(id, orderStatus)
    }

    const handleDropdownChange = (e) => {
        setReject_order_reason(e.target.value)
    }

    const handleCloseOrderReject = () => {
        setRejectOrderDialogOpen(false);
        setReject_order_reason(null);
        setReasonMaster([])
    }

    const handlePrintSubcontractPreviewBtn = (subcontractId) => {
        handlePrintSubcontractOverviewTable(subcontractId, "PRINT")
    }

    return (
        <>
            <Typography className={classes.customerTypeTitle} component={"span"}>
                {/* <div style={{ color: "grey", marginLeft: "20px", fontSize: pxToEm(17), marginBottom: "10px" }}>{props.detail}</div> */}
                <div className={classes.alignStateText}>
                    {props.plantShortName}
                </div>
            </Typography>
            <ThemeProvider theme={theme}>
                <div style={{ padding: "5px 20px 10px 20px" }}>
                    <Paper>
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.tableHeader}>
                                        <TableCell className={classes.tableHead}><div style={{ fontSize: "13px" }}>Date</div></TableCell>
                                        <TableCell className={classes.tableHead}><div style={{ fontSize: "13px" }}>Subcontract #</div><div>Status</div></TableCell>
                                        <TableCell className={classes.tableHead}><div style={{ fontSize: "13px" }}>Payment Terms</div><div>Type</div></TableCell>
                                        <TableCell className={classes.tableHead}><div style={{ fontSize: "13px" }}>Payment Doc #</div><div>Total Value</div></TableCell>
                                        <TableCell className={classes.tableHead}><div style={{ fontSize: "13px" }}>Buyer</div><div>Consignee</div></TableCell>
                                        <TableCell className={classes.tableHead}>Total Quantity</TableCell>
                                        <TableCell className={classes.tableHead}>LA (Pending/Approved)</TableCell>
                                        <TableCell className={classes.tableHead}>LA (In Plant)</TableCell>
                                        <TableCell className={classes.tableHead}>LA (Dispatched)</TableCell>
                                        <TableCell className={classes.tableHead}>LA (Cancelled)</TableCell>
                                        <TableCell className={classes.tableHead}>Open Quantity</TableCell>
                                        <TableCell className={classes.tableHead}>Action</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow style={{ cursor: "pointer" }} key={row.id}
                                            //overview
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/internalContracts/subcontracts/overview/${row.id}`,
                                                    state: {
                                                        plantId: props.plantId,
                                                        name: props.name,
                                                        subcontractAvailableQty: props.subcontractAvailableQty,
                                                        orderPlantPlanningData: props.orderPlantPlanningData[0],
                                                        orderDetails: props.orderDetails,
                                                        subContractDetails: row,
                                                        subContractDiscounts: row.sub_contract_discounts
                                                    }
                                                })}
                                        >
                                            <TableCell align="center" className={classes.tableBody}>{moment(row.created_at).format("DD-MMM-YYYY")} <br />

                                            </TableCell>
                                            <TableCell align="center" className={classes.tableBody}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                    <span>{row?.sub_contract_number}</span>
                                                    <span onClick={(event) => {
                                                        event.stopPropagation();
                                                        onCopyText(row?.sub_contract_number, row)
                                                    }
                                                    }
                                                    >
                                                        {copiedRows.find(c => c.id == row?.id)?.isSubcontractNoCopied ? (
                                                            <Typography
                                                                style={{
                                                                    fontSize: pxToEm(14),
                                                                    height: 24,
                                                                    width: 24,
                                                                    fontWeight: "normal",
                                                                    color: "#FFB600",
                                                                    marginLeft: 2,
                                                                    marginRight: 15,
                                                                    marginTop: 5
                                                                }}
                                                            >
                                                                Copied!
                                                            </Typography>
                                                        ) : (
                                                            <ContentCopyOutlinedIcon
                                                                style={{
                                                                    paddingTop: '2px',
                                                                    // transform: 'scale(1)',
                                                                    marginLeft: 4,
                                                                    cursor: 'pointer',
                                                                    fontSize: "15px",
                                                                }}
                                                            />
                                                        )}

                                                    </span>
                                                </div>
                                                <div style={{ color: "#666666" }}>{row?.status}</div>
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableBody}>{row.payment_detail?.payment_name}<br /><div style={{ color: "#666666" }}>{row.payment_detail?.vcl_enabled ? "VCL" : "NON-VCL"}</div></TableCell>
                                            <TableCell align="center" className={classes.tableBody}>
                                                {row?.payment_doc_number ?
                                                    <>
                                                        <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: "5px" }}>
                                                            {row?.payment_doc_number}

                                                            <InfoOutlinedIcon fontSize="small" style={{ color: "#666", marginLeft: '5px' }}
                                                                //onMouseEnter={handlePopoverOpen2}
                                                                //onMouseLeave={handlePopoverClose}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    setSelectedPaymentRow(row)
                                                                    handlePopoverOpen2(event)
                                                                }} />

                                                        </span><br />
                                                        <span style={{ display: 'inline-flex', alignItems: 'center' }}
                                                        //onMouseEnter={handlePopoverOpen}
                                                        //onMouseLeave={handlePopoverClose}
                                                        //onClick={handlePopoverOpen}
                                                        >
                                                            {row?.payment_doc_value}
                                                        </span>
                                                    </>
                                                    : ""
                                                }
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableBody}>
                                                <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: "5px" }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSelectedPaymentRow(row)
                                                        handlePopoverOpen3(event)
                                                    }}>
                                                    {row?.buyer_detail?.first_name}
                                                    <InfoOutlinedIcon fontSize="small" style={{ color: "#666", marginLeft: '5px' }} />
                                                </span>
                                                <br />
                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}
                                                    //onMouseEnter={handlePopoverOpen}
                                                    //onMouseLeave={handlePopoverClose}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSelectedPaymentRow(row)
                                                        handlePopoverOpen(event)
                                                    }}>
                                                    {row?.consignee_detail?.consignee_detail?.first_name}
                                                    <InfoOutlinedIcon fontSize="small" style={{ color: "#666", marginLeft: '5px' }} />
                                                </span>
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableBody}>{Number(row.dispatch_detail?.sub_contract_qty).toFixed(3)}</TableCell>
                                            <TableCell align="center" className={classes.tableBody}>{Number(row.dispatch_detail?.pending_approved_la).toFixed(3)}</TableCell>
                                            <TableCell align="center" className={classes.tableBody}>{Number(row.dispatch_detail?.in_plant_qty).toFixed(3)}</TableCell>
                                            <TableCell align="center" className={classes.tableBody}>{Number(row.dispatch_detail?.dispatched_la).toFixed(3)}</TableCell>
                                            <TableCell align="center" className={classes.tableBody}>{Number(row.dispatch_detail?.cancelled_la).toFixed(3)}</TableCell>
                                            <TableCell align="center" className={classes.tableBody}>{Number(row.dispatch_detail?.available_qty).toFixed(3)}</TableCell>
                                            <TableCell align="center" className={classes.tableBody}>
                                                <IconButton onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedRow(row)
                                                    handleMenuClick(event)
                                                }}
                                                    style={{ width: "24px", height: "24px" }} >
                                                    <MoreVertIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {
                                    rows.length > 0 ?
                                        (<TableFooter>
                                            <TableRow className={classes.tableHeader}>
                                                <TableCell className={classes.tableHead}>
                                                    Total
                                                </TableCell>
                                                <TableCell className={classes.tableHead}>
                                                    {/* {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.allotted_qty), 0.0)).toFixed(3)} */}

                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>

                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>

                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>

                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>
                                                    {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.dispatch_detail?.sub_contract_qty), 0.0)).toFixed(3)}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>
                                                    {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.dispatch_detail?.pending_approved_la), 0.0)).toFixed(3)}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>
                                                    {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.dispatch_detail?.in_plant_qty), 0.0)).toFixed(3)}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>
                                                    {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.dispatch_detail?.dispatched_la), 0.0)).toFixed(3)}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>
                                                    {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.dispatch_detail?.cancelled_la), 0.0)).toFixed(3)}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}>
                                                    {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.dispatch_detail?.available_qty), 0.0)).toFixed(3)}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableHead}></TableCell>
                                            </TableRow>
                                        </TableFooter>)
                                        : ""
                                }
                            </Table>
                        </TableContainer>

                        {/* Buyer */}
                        {/* Consignee */}
                        <div autoFocus onClick={(e) => e.stopPropagation()}>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            //className={classes.popoverBox}
                            >
                                <div
                                    className={classes.additionalData}
                                    style={{
                                        width: '100%', margin: 5, padding: '5px', fontFamily: 'Poppins',
                                        fontSize: "inherit",
                                        fontWeight: '500',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",

                                            }}
                                        >
                                            Name{" "}
                                        </span>
                                        <span style={{
                                            width: "70%",
                                        }}>
                                            {`${selectedPaymentRow?.consignee_detail?.consignee_detail
                                                ?.first_name || ""
                                                } ${selectedPaymentRow?.consignee_detail?.consignee_detail
                                                    ?.last_name || ""
                                                }`}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            Type
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {selectedPaymentRow?.consignee_detail?.consignee_detail
                                                ?.customer_type || ""}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            {!selectedPaymentRow?.consignee_detail?.consignee_detail
                                                ?.gstin_number
                                                ? "PAN"
                                                : "GSTIN"}
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {!selectedPaymentRow?.consignee_detail?.consignee_detail
                                                ?.gstin_number
                                                ? !selectedPaymentRow?.consignee_detail?.consignee_detail
                                                    ?.pan_card_number
                                                    ? ""
                                                    : selectedPaymentRow?.consignee_detail?.consignee_detail
                                                        ?.pan_card_number || ""
                                                : selectedPaymentRow?.consignee_detail?.consignee_detail
                                                    ?.gstin_number || ""}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            Phone{" "}
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {selectedPaymentRow?.consignee_detail?.consignee_detail?.phone || ""}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            Address{" "}
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {
                                                (
                                                    selectedPaymentRow?.consignee_detail?.consignee_address?.address ?
                                                        (
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.address
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.address + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.city
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.city + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.state
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.state + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.country
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.country + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.pincode
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.pincode
                                                                : "")
                                                        )
                                                        :
                                                        (
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.door_number
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.door_number + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.floor_number
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.floor_number + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.building_name
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.building_name + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.street
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.street + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.location
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.location + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.district_name
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.district_name + ", "
                                                                : "")
                                                            +
                                                            ((selectedPaymentRow?.consignee_detail?.consignee_address?.city_name && selectedPaymentRow?.consignee_detail?.consignee_address?.district_name)
                                                                ? ((selectedPaymentRow?.consignee_detail?.consignee_address?.city_name).trim().toUpperCase() !== (selectedPaymentRow?.consignee_detail?.consignee_address?.district_name).trim().toUpperCase())
                                                                    ? selectedPaymentRow?.consignee_detail?.consignee_address?.city_name + ", "
                                                                    : ""
                                                                : selectedPaymentRow?.consignee_detail?.consignee_address?.city_name
                                                                    ? selectedPaymentRow?.consignee_detail?.consignee_address?.city_name + ", "
                                                                    : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.state_name
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.state_name + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.consignee_detail?.consignee_address?.pincode
                                                                ? selectedPaymentRow?.consignee_detail?.consignee_address?.pincode
                                                                : "")
                                                        )
                                                )
                                            }
                                        </span>
                                    </div>
                                </div>

                            </Popover>
                        </div>
                        <div autoFocus onClick={(e) => e.stopPropagation()}>
                            <Popover
                                open={open3}
                                anchorEl={anchorEl3}
                                onClose={handlePopoverClose3}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            //className={classes.popoverBox}
                            >
                                <div
                                    className={classes.additionalData}
                                    style={{
                                        width: '100%', margin: 5, padding: '5px', fontFamily: 'Poppins',
                                        fontSize: "inherit",
                                        fontWeight: '500',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",

                                            }}
                                        >
                                            Name{" "}
                                        </span>
                                        <span style={{
                                            width: "70%",
                                        }}>
                                            {`${selectedPaymentRow?.buyer_detail?.first_name || ""} ${selectedPaymentRow?.buyer_detail?.last_name || ""
                                                } (${selectedPaymentRow?.buyer_detail?.customer_uid || ""})`}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            Type
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {selectedPaymentRow?.buyer_detail?.customer_type || ""}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            {!selectedPaymentRow?.buyer_detail?.gstin_number ? "PAN" : "GSTIN"}
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {!selectedPaymentRow?.buyer_detail?.gstin_number
                                                ? !selectedPaymentRow?.buyer_detail?.pan_card_number
                                                    ? ""
                                                    : selectedPaymentRow?.buyer_detail?.pan_card_number || ""
                                                : selectedPaymentRow?.buyer_detail?.gstin_number || ""}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            Phone{" "}
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {selectedPaymentRow?.buyer_detail?.phone || ""}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "30%",
                                                color: "#3B69B0",
                                            }}
                                        >
                                            Address{" "}
                                        </span>
                                        <span style={{ width: "70%" }}>
                                            {
                                                (
                                                    selectedPaymentRow?.buyer_detail?.address?.address ?
                                                        (
                                                            (selectedPaymentRow?.buyer_detail?.address?.address
                                                                ? selectedPaymentRow?.buyer_detail?.address?.address + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.city
                                                                ? selectedPaymentRow?.buyer_detail?.address?.city + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.state
                                                                ? selectedPaymentRow?.buyer_detail?.address?.state + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.country
                                                                ? selectedPaymentRow?.buyer_detail?.address?.country + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.pincode
                                                                ? selectedPaymentRow?.buyer_detail?.address?.pincode
                                                                : "")

                                                        )
                                                        :
                                                        (
                                                            (selectedPaymentRow?.buyer_detail?.address?.door_number
                                                                ? selectedPaymentRow?.buyer_detail?.address?.door_number + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.floor_number
                                                                ? selectedPaymentRow?.buyer_detail?.address?.floor_number + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.building_name
                                                                ? selectedPaymentRow?.buyer_detail?.address?.building_name + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.street
                                                                ? selectedPaymentRow?.buyer_detail?.address?.street + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.location
                                                                ? selectedPaymentRow?.buyer_detail?.address?.location + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.district_name
                                                                ? selectedPaymentRow?.buyer_detail?.address?.district_name + ", "
                                                                : "")
                                                            +
                                                            ((selectedPaymentRow?.buyer_detail?.address?.city_name && selectedPaymentRow?.buyer_detail?.address?.district_name)
                                                                ? ((selectedPaymentRow?.buyer_detail?.address?.city_name).trim().toUpperCase() !== (selectedPaymentRow?.buyer_detail?.address?.district_name).trim().toUpperCase())
                                                                    ? selectedPaymentRow?.buyer_detail?.address?.city_name + ", "
                                                                    : ""
                                                                : selectedPaymentRow?.buyer_detail?.address?.city_name
                                                                    ? selectedPaymentRow?.buyer_detail?.address?.city_name + ", "
                                                                    : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.state_name
                                                                ? selectedPaymentRow?.buyer_detail?.address?.state_name + ", "
                                                                : "")
                                                            +
                                                            (selectedPaymentRow?.buyer_detail?.address?.pincode
                                                                ? selectedPaymentRow?.buyer_detail?.address?.pincode
                                                                : "")
                                                        )
                                                )
                                            }
                                        </span>
                                    </div>
                                </div>

                            </Popover>
                        </div>
                        {selectedPaymentRow?.payment_doc_number &&
                            <div autoFocus onClick={(e) => e.stopPropagation()}>
                                <Popover
                                    open={open2}
                                    anchorEl={anchorEl2}
                                    onClose={handlePopoverClose2}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                //className={classes.popoverBox}
                                >
                                    <div
                                        className={classes.additionalData}
                                        style={{
                                            width: '350px', margin: 5, padding: '5px', fontFamily: 'Poppins',
                                            fontSize: "inherit",
                                            fontWeight: '500',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: "10px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    //width: "30%",
                                                    color: "#3B69B0",

                                                }}
                                            >
                                                Payment Doc Number{" "}
                                            </span>
                                            <span style={{
                                                //width: "70%",
                                            }}>
                                                {selectedPaymentRow?.payment_doc_number}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: "10px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    // width: "30%",
                                                    color: "#3B69B0",
                                                }}
                                            >
                                                Payment Doc Date
                                            </span>
                                            <span style={{
                                                //width: "70%",
                                            }}>
                                                {moment(selectedPaymentRow?.payment_doc_date).format("DD-MMM-YYYY")}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: "10px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    //width: "30%",
                                                    color: "#3B69B0",
                                                }}
                                            >
                                                Payment Bank Name{" "}
                                            </span>
                                            <span style={{}}>
                                                {selectedPaymentRow?.payment_detail?.payment_bank_name}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: "10px",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    //width: "30%",
                                                    color: "#3B69B0",
                                                }}
                                            >
                                                Payment Doc Value{" "}
                                            </span>
                                            <span style={{}}>
                                                {selectedPaymentRow?.payment_doc_value}
                                            </span>
                                        </div>
                                    </div>

                                </Popover>
                            </div>
                        }
                        <Menu
                            id="action-menu"
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                style={{
                                    fontWeight: '600',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    fontSize: pxToEm(16),
                                    lineHeight: 1,
                                    color: grey
                                }}
                                disabled={!["APPROVED", "ONGOING"].includes(props?.order_status) || selectedRow?.status == "CANCELLED" ||
                                    !hasUpdateAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    history.push({
                                        pathname: `/internalContracts/subcontracts/editSubContracts/${selectedRow.id}`,
                                        state: {
                                            plantId: props.plantId,
                                            name: props.name,
                                            subcontractAvailableQty: props.subcontractAvailableQty,
                                            orderPlantPlanningData: props.orderPlantPlanningData[0],
                                            orderDetails: props.orderDetails,
                                            subContractDetails: selectedRow,
                                            subContractDiscounts: selectedRow?.sub_contract_discounts
                                        }
                                    })
                                }}>

                                <EditOutlinedIcon fontSize="small" style={{ marginRight: "5px" }} /> EDIT
                            </MenuItem>
                            <Divider
                                variant='middle'
                                style={{
                                    margin: '2% 0',
                                    display: selectedRow?.status == "CANCELLED" && 'none'
                                }}
                            />
                            <MenuItem
                                style={{
                                    fontWeight: '600',
                                    display: selectedRow?.status == "CANCELLED" ? 'none' : "flex",
                                    justifyContent: 'flex-start',
                                    fontSize: pxToEm(16),
                                    lineHeight: 1,
                                    color: grey
                                }}
                                disabled={!["APPROVED", "ONGOING"].includes(props?.order_status) || !hasApproveAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                onClick={() => {
                                    setRejectOrderDialogOpen(true)
                                    fetchSubContractReasonMaster();
                                }
                                }><CancelOutlinedIcon fontSize="small" style={{ marginRight: "5px" }} />CANCEL</MenuItem>
                            <Divider
                                variant='middle'
                                style={{
                                    margin: '2% 0',
                                }}
                            />
                            <ToolTipComponent
                                title="You have no special permission on this widget"
                                condition={!hasSpecialAccess(props.user, `${props.itemCategoryCode}-contracts`)}
                                title2={"Order status should be ONGOING to reopen"}
                                condition2={!["ONGOING"].includes(props?.order_status)}
                                title3={"Subcontract status should be CLOSED to reopen"}
                                condition3={!["CLOSED"].includes(selectedRow?.status)}

                            >
                                <MenuItem
                                    style={{
                                        fontWeight: '600',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        fontSize: pxToEm(16),
                                        lineHeight: 1,
                                        color: grey
                                    }}
                                    disabled={!hasSpecialAccess(props.user, `${props.itemCategoryCode}-contracts`)
                                        || !["ONGOING"].includes(props?.order_status) || !["CLOSED"].includes(selectedRow?.status)}
                                    onClick={() => handleSubContractReOpen(selectedRow.id)}>
                                    <CheckOutlinedIcon fontSize="small" style={{ marginRight: "5px" }} />REOPEN</MenuItem>
                            </ToolTipComponent>
                            <MenuItem
                                style={{
                                    fontWeight: '600',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    fontSize: pxToEm(16),
                                    lineHeight: 1,
                                    color: grey
                                }}
                                onClick={() => handlePrintSubcontractPreviewBtn(selectedRow.id)}>
                                <Print fontSize="small" style={{ marginRight: "5px" }} />SUBCONTRACT PDF</MenuItem>
                        </Menu>
                    </Paper>

                </div>
            </ThemeProvider >
            <div className={classes.addAndSaveButton}>
                <ToolTipComponent
                    title="You have no create permission on this widget"
                    condition={!hasWriteAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                    title2={"You cannot create new subcontract for this plant as open quantity is zero"}
                    condition2={props.SCOpenQuantity == 0}
                    title3={"Parent contract should be Approved or ongoing"}
                    condition3={!["APPROVED", "ONGOING"].includes(props?.order_status)}
                >
                    <Button
                        variant="outlined"
                        size="medium"
                        startIcon={<AddOutlinedIcon />}
                        className={classes.addNewButton}
                        onClick={() => {
                            history.push(
                                {
                                    pathname: `/internalContracts/subcontracts/addSubContracts/${props.orderId}`,
                                    state: {
                                        plantId: props.plantId,
                                        name: props.name,
                                        subcontractAvailableQty: props.subcontractAvailableQty,
                                        orderPlantPlanningData: props.orderPlantPlanningData[0],
                                        orderDetails: props.orderDetails
                                    }
                                });
                        }
                        }

                        disabled={!["APPROVED", "ONGOING"].includes(props?.order_status) || !hasWriteAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) || props.SCOpenQuantity == 0}
                    >
                        ADD SUBCONTRACT
                    </Button>
                </ToolTipComponent>
            </div>

            <Dialog
                open={rejectOrderDialogOpen}
                onClose={handleCloseOrderReject}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <div style={{
                    borderRadius: 16,
                    padding: 8,
                }}>
                    <div className={classes.contractStatusCustomerBox}>
                        <DialogTitle id="alert-dialog-title" style={{ padding: '16px 0' }}>
                            <span>Cancel</span>
                            <p style={{ fontSize: pxToEm(10), margin: 0 }}>
                                {'Subcontract | ' + selectedRow?.sub_contract_number}
                            </p>
                        </DialogTitle>
                        <DialogContent style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'column',
                            height: 100,
                            alignContent: 'center',
                            padding: '0'
                        }}>
                            {(selectedRow.dispatch_detail?.in_plant_qty == 0
                                && selectedRow.dispatch_detail?.pending_approved_la == 0) ?
                                <>
                                    <p>
                                        Please select reason for Subcontract Cancel
                                    </p>
                                    <div>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                        >
                                            <InputLabel id="rejectReason">
                                                Select Reject Reason*
                                            </InputLabel>
                                            <Select
                                                id="Reject Reason"
                                                labelId="rejectReason"
                                                variant="outlined"
                                                label="Select Reject Reason*"
                                                name="reject_order_reason"
                                                style={{ width: 340 }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 45 * 4 + 10,
                                                        },
                                                    },
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                }}
                                                value={reject_order_reason}
                                                onChange={handleDropdownChange}
                                            >
                                                {reasonMaster !== undefined && reasonMaster.length > 0
                                                    ? reasonMaster.map((data) => (
                                                        <MenuItem key={data.reason} value={data.id}>
                                                            {data.reason}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    <CircularProgress
                                                        disableShrink
                                                        className={classes.loaderStyle}
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </>
                                :
                                <p>
                                    Subcontract cannot be cancel because of open loading advices.
                                    <div>
                                        Please cancel open LA first
                                    </div>
                                </p>
                            }
                        </DialogContent>
                        <DialogActions
                            style={{
                                padding: '16px 0 0 0',
                                display: !(selectedRow.dispatch_detail?.in_plant_qty == 0
                                    && selectedRow.dispatch_detail?.pending_approved_la == 0) && 'none'
                            }}>
                            <Button onClick={handleCloseOrderReject} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleConfirmSubcontractCancel(selectedRow.id)}
                                style={{ color: reject_order_reason && "#34619C", }}
                                autoFocus
                                disabled={!reject_order_reason ? true : false}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
            <SnackbarComponent
                open={snackbarData.openSnackbar}
                handleClose={handleCloseSnackbar}
                snackbarMessage={snackbarData.snackbarMessage}
                type={snackbarData.snackbarType}
            />

        </>
    )
}
