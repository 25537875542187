import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
// import ComingSoon from '../../../../components/common/CoomingSoon';
// import Layout from '../../../../layouts/internalLayout/InternalLayout';
import { Route } from "react-router-dom";
import ManagePlantItemMaster from "../../../../components/internal/masterManagement/plantItemMaster/web";
import ManagePlantMasterAddEdit from "../../../../components/internal/masterManagement/plantItemMaster/ManagePlantMasterAddEdit";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { arraySortBykeyAllBrowser, isAccessibleModule } from "../../../../methods";

class ManagePlantMasterDataContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      plantMasterOperation: null,
      selectedPlantId: null,
      selectedPlantData: [],
      selectedPlantDataFetchLoader: true,
      itemCategoriesList: [],
      allStateList: [],
      allStateListDataFetchLoader: true,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      gatesList: [],
      gatesListLoader: true,
      weighbridgeList: [],
      weighbridgeListLoader: true,
      dispatchCounterList: [],
      dispatchCounterListLoader: true,
      itemCategoriesDataFetchLoader: false,
      itemNatureData: []
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleFetchAllStatesList();
    this.getItemCateogriesList();
    let plantMasterOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    this.setState({
      plantMasterOperation,
      // itemCategoriesList: this.props.itemCategoriesList,
    });
    if (plantMasterOperation === "view" || plantMasterOperation === "edit") {
      const id =
        this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
        ];
      this.handleFetchSelectedPlantData(id);
      this.handleFetchSelectedItemNatureData(id);
      this.handleFetchAllItemNatureData()
      this.setState({ selectedPlantId: id, selectedPlantItemNatureId: id });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let propsPlantMasterOperation = nextProps.location.pathname.includes("add")
      ? "add"
      : nextProps.location.pathname.includes("edit")
        ? "edit"
        : "view";
    if (propsPlantMasterOperation !== this.state.plantMasterOperation) {
      this.setState({ plantMasterOperation: propsPlantMasterOperation });
    }
    // if (nextProps.itemCategoriesList !== this.state.itemCategoriesList) {
    //   this.setState({ itemCategoriesList: nextProps.itemCategoriesList });
    // }
    if (propsPlantMasterOperation === "view") {
      const propsSelectedPlantId =
        nextProps?.location?.pathname?.split("/")[
        nextProps?.location?.pathname?.split("/").length - 1
        ];
      if (propsSelectedPlantId !== this.state.selectedPlantId) {
        this.handleFetchSelectedPlantData(propsSelectedPlantId);
        this.setState({ selectedPlantId: propsSelectedPlantId });
      }
    }
    if (propsPlantMasterOperation === "view") {
      const propsSelectedPlantItemNatureId =
        nextProps?.location?.pathname?.split("/")[
        nextProps?.location?.pathname?.split("/").length - 1
        ];
      if (propsSelectedPlantItemNatureId !== this.state.selectedPlantItemNatureId) {

        this.handleFetchSelectedItemNatureData(propsSelectedPlantItemNatureId);
        this.setState({ selectedPlantItemNatureId: propsSelectedPlantItemNatureId });
      }
    }
  }

  getItemCateogriesList = () => {
    this.setState({ itemCategoriesDataFetchLoader: true });
    axios
      .get(`${APIROUTES.GET_ITEM_CATEGORIES_LIST}?active=${true}`, this.getOptions(this.props.user))
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'category_name');
          this.setState({
            itemCategoriesList: responseData,
            itemCategoriesDataFetchLoader: false,
          });
        },
        (error) => {
          console.log("error", error);
          this.setState({ itemCategoriesDataFetchLoader: false });
        }
      );
  };

  // componentDidUpdate(prevProps, prevState) {
  //   let propsPlantMasterOperation = this.props.location.pathname.includes("add")
  //     ? "add"
  //     : this.props.location.pathname.includes("edit")
  //     ? "edit"
  //     : "view";
  //   if (prevProps.plantMasterOperation !== propsPlantMasterOperation) {
  //     this.setState({ plantMasterOperation: propsPlantMasterOperation });
  //   }
  //   if (prevProps.itemCategoriesList !== this.props.itemCategoriesList) {
  //     this.setState({ itemCategoriesList: prevProps.itemCategoriesList });
  //   }
  //   if (propsPlantMasterOperation === "view") {
  //     const propsSelectedPlantId = this?.props?.location?.pathname?.split("/")[
  //       this?.props?.location?.pathname?.split("/").length - 1
  //     ];
  //     if (prevProps.selectedPlantId !== propsSelectedPlantId) {
  //       this.handleFetchSelectedPlantData(propsSelectedPlantId);
  //       this.setState({ selectedPlantId: propsSelectedPlantId });
  //     }
  //   }
  // }
  handleFetchSelectedPlantData = async (selectedPlantId) => {
    await this.setState({ selectedPlantDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST}${selectedPlantId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          console.log(response)
          if (response.status === 200) {
            this.setState({
              selectedPlantData: response.data,
              selectedPlantDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedPlantDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected plant data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedPlantDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching plant data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handlePlantItemMasterDataAdd = (plantItemMasterAddData) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST,
          plantItemMasterAddData,
          this.getOptions(this.props?.user)
        )
        .then(async (response) => {
          await this.setState({ selectedPlantId: response.data.id });
          await this.props.getPlantItemMasterList();
          await resolve(response);
        })
        .catch(reject);
    });
  handlePlantItemMasterDataUpdate = (plantItemMasterUpdateData) =>
    new Promise((resolve, reject) => {
      axios
        .patch(
          `${APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST}${this.state.selectedPlantId}/`,
          plantItemMasterUpdateData,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.props.getPlantItemMasterList();
          this.handleFetchSelectedPlantData(this.state.selectedPlantId)
          resolve(response);
        })
        .catch(reject);
    });
  handleRemovePlantItemMaster = () =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST}${this.state.selectedPlantId}/`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.props.getPlantItemMasterList();
          resolve(response);
        })
        .catch(reject);
    });
  handleAddNewItemsInPlant = (itemsTobeAddedInPlant) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          APIROUTES.ITEMS_IN_PLANT_MASTER,
          itemsTobeAddedInPlant,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.props.getPlantItemMasterList();
          this.handleFetchSelectedPlantData(this.state.selectedPlantId);
          resolve(response);
        })
        .catch(reject);
    });
  handleUpdateItemsInPlant = (updatedItem, itemId) =>
    new Promise((resolve, reject) => {
      this.setState({
        selectedPlantDataFetchLoader: false,
      })
      axios
        .patch(
          `${APIROUTES.UPDATE_ITEMS_IN_PLANT_MASTER}${itemId}/`,
          updatedItem,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.handleFetchSelectedPlantData(this.state.selectedPlantId)
          this.setState({
            selectedPlantDataFetchLoader: true,
          })
          resolve(response);
        })
        .catch(reject);
    });
  handleRemoveItemsFromPlant = (itemIdToRemove) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.ITEMS_IN_PLANT_MASTER}${itemIdToRemove}/`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.props.getPlantItemMasterList();
          this.handleFetchSelectedPlantData(this.state.selectedPlantId);
          resolve(response);
        })
        .catch(reject);
    });


  handleLinkItemNature = (itemsNatureTobeAddedInPlant) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          APIROUTES.LINK_ITEM_NATURE,
          itemsNatureTobeAddedInPlant,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.props.getPlantItemMasterList();
          this.handleFetchAllItemNatureData();
          this.handleFetchSelectedItemNatureData(this.state.selectedPlantId);
          resolve(response);
        })
        .catch(reject);
    });


  handleFetchSelectedItemNatureData = async (selectedPlantItemNatureId) => {
    console.log(selectedPlantItemNatureId)
    await this.setState({ selectedPlantDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.LINK_ITEM_NATURE_LIST}${selectedPlantItemNatureId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            console.log(response.data)
            this.setState({
              itemNatureData: response.data,
              // .map((d) => ({ ...d.item_nature, id: d.id, plant: d.plant, godown_code: d.godown_code, is_active: d.is_active })),
              selectedPlantDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedPlantDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected plant data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedPlantDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching plant data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleFetchAllItemNatureData = async () => {
    await axios
      .get(
        `${APIROUTES.ITEM_NATURE}?active=${true}`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            console.log(response.data)
            this.setState({
              allItemNatureList: response.data,
            });
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching all item nature data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedPlantDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching all item nature data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleUpdateItemsNatureInPlant = (updatedItem, itemNatureId) =>
    new Promise((resolve, reject) => {
      this.setState({
        selectedPlantDataFetchLoader: false,
      })
      axios
        .patch(
          `${APIROUTES.UPDATE_REMOVE_ITEMS_NATURE_IN_PLANT_MASTER}${itemNatureId}/`,
          updatedItem,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.handleFetchSelectedItemNatureData(this.state.selectedPlantId)
          this.handleFetchAllItemNatureData();
          this.setState({
            selectedPlantDataFetchLoader: true,
          })
          resolve(response);
        })
        .catch(reject);
    });
  handleRemoveItemsNatureFromPlant = (itemNatureIdToRemove) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.UPDATE_REMOVE_ITEMS_NATURE_IN_PLANT_MASTER}${itemNatureIdToRemove}/`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.handleFetchSelectedItemNatureData(this.state.selectedPlantId);
          this.handleFetchAllItemNatureData();
          this.props.getPlantItemMasterList();
          resolve(response);
        })
        .catch(reject);
    });
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  fetchGateMaster = (selectedPlantId) => {
    this.setState({ gatesListLoader: true }, () => {
      axios
        .get(
          `${APIROUTES.GET_GATES_IN_PLANT}${selectedPlantId && selectedPlantId}`
        )
        .then((response) => {
          this.setState({ gatesList: response.data, gatesListLoader: false });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({ gatesListLoader: false });
        });
    });
  };

  handleAddGatesInPlantMaster = (gateName, gateIn, gateOut) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.GATES_ADD_IN_PLANT, {
          gate_name: gateName,
          is_gate_in_allowed: gateIn,
          is_gate_out_allowed: gateOut,
          plant: this.state.selectedPlantId,
        })
        .then(resolve)
        .catch(reject);
    });
  handleUpdateGatesInPlantMaster = (gateName, gateIn, gateOut, gateId) =>
    new Promise((resolve, reject) => {
      let data = {
        gate_name: gateName,
        is_gate_in_allowed: gateIn,
        is_gate_out_allowed: gateOut,
        plant: this.state.selectedPlantId,
      };
      axios
        .put(
          `${APIROUTES.GATES_UPDATE_IN_PLANT + gateId + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });
  handleDeleteGates = (gateId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.DELETE_GATE}${gateId}/`,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });
  fetchWeighbridgeMaster = (selectedPlantId) => {
    this.setState({ weighbridgeListLoader: true }, () => {
      axios
        .get(
          `${APIROUTES.GET_WEIGHBRIDGE}${selectedPlantId && selectedPlantId}`
        )
        .then((response) => {
          console.log(response);
          this.setState({
            weighbridgeList: response.data,
            weighbridgeListLoader: false,
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({
            weighbridgeListLoader: false,
          });
        });
    });
  };

  handleAddWeighbridgeInPlantMaster = (weighbridgeName, activeStatus) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.WEIGHBRIDGE_ADD_IN_PLANT, {
          weighbridge_name: weighbridgeName,
          is_active: activeStatus,
          plant: this.state.selectedPlantId,
        })
        .then(resolve)
        .catch(reject);
    });
  handleUpdateWeighbridgeInPlantMaster = (
    weighbridgeName,
    activeStatus,
    weighbridgeId
  ) =>
    new Promise((resolve, reject) => {
      let data = {
        weighbridge_name: weighbridgeName,
        is_active: activeStatus,
        plant: this.state.selectedPlantId,
      };
      axios
        .put(
          `${APIROUTES.UPDATE_WEIGHBRIDGE_IN_PLANT + weighbridgeId + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });
  handleDeleteWeighbridge = (weighbridgeId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.DELETE_WEIGHBRIDGE}${weighbridgeId}/`,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });
  fetchDispatchCounterMaster = (selectedPlantId) => {
    this.setState({ dispatchCounterListLoader: true }, () => {
      axios
        .get(
          `${APIROUTES.GET_DISPATCH_COUNTER}${selectedPlantId && selectedPlantId
          }`
        )
        .then((response) => {
          console.log(response);
          this.setState({
            dispatchCounterList: response.data,
            dispatchCounterListLoader: false,
          });
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({
            dispatchCounterListLoader: false,
          });
        });
    });
  };
  handleAddDispatchCounterInPlantMaster = (dispatchCounterName, activeStatus) =>
    new Promise((resolve, reject) => {
      axios
        .post(APIROUTES.ADD_DISPATCH_COUNTER, {
          dispatch_counter_name: dispatchCounterName,
          is_active: activeStatus,
          plant: this.state.selectedPlantId,
        })
        .then(resolve)
        .catch(reject);
    });
  handleUpdateDispatchCounterInPlantMaster = (
    dispatchCounterName,
    activeStatus,
    dispatchCounterId
  ) =>
    new Promise((resolve, reject) => {
      let data = {
        dispatch_counter_name: dispatchCounterName,
        is_active: activeStatus,
        plant: this.state.selectedPlantId,
      };
      axios
        .put(
          `${APIROUTES.UPDATE_DISPATCH_COUNTER + dispatchCounterId + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });
  handleDeleteDispatchCounter = (dispatchCounterId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.DELETE_DISPATCH_COUNTER}${dispatchCounterId}/`,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });
  handleFetchAllStatesList = (countryId) => {
    axios
      .get(
        `${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId
        }`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              allStateList: response.data,
              allStateListDataFetchLoader: false,
            });
          } else {
            this.setState({
              allStateListDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching all states data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            allStateListDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching all states data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  render() {
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    else if (isAccessibleModule(this.props?.user, "Masters")) {
      return (
        <>
          {/* {console.log(this.state.itemNatureData)} */}
          {this?.state?.plantMasterOperation === "view" ? (

            <ManagePlantItemMaster
              {...this.state}
              {...this.props}
              itemCategoriesList={this.state.itemCategoriesList}
              allStateList={this.state.allStateList}
              allStateListDataFetchLoader={this.state.allStateListDataFetchLoader}
              handlePlantItemMasterDataAdd={this.handlePlantItemMasterDataAdd}
              handlePlantItemMasterDataUpdate={
                this.handlePlantItemMasterDataUpdate
              }
              handleUpdateItemsInPlant={this.handleUpdateItemsInPlant}
              handleRemovePlantItemMaster={this.handleRemovePlantItemMaster}
              handleAddNewItemsInPlant={this.handleAddNewItemsInPlant}
              handleRemoveItemsFromPlant={this.handleRemoveItemsFromPlant}
              handleAddGatesInPlantMaster={this.handleAddGatesInPlantMaster}
              fetchGateMaster={this.fetchGateMaster}
              handleUpdateGatesInPlantMaster={
                this.handleUpdateGatesInPlantMaster
              }
              handleDeleteGates={this.handleDeleteGates}
              fetchWeighbridgeMaster={this.fetchWeighbridgeMaster}
              handleAddWeighbridgeInPlantMaster={
                this.handleAddWeighbridgeInPlantMaster
              }
              handleUpdateWeighbridgeInPlantMaster={
                this.handleUpdateWeighbridgeInPlantMaster
              }
              handleDeleteWeighbridge={this.handleDeleteWeighbridge}
              fetchDispatchCounterMaster={this.fetchDispatchCounterMaster}
              handleAddDispatchCounterInPlantMaster={
                this.handleAddDispatchCounterInPlantMaster
              }
              handleUpdateDispatchCounterInPlantMaster={
                this.handleUpdateDispatchCounterInPlantMaster
              }
              handleDeleteDispatchCounter={this.handleDeleteDispatchCounter}
              handleFetchAllStatesList={this.handleFetchAllStatesList}
              handleLinkItemNature={this.handleLinkItemNature}
              handleFetchSelectedItemNatureData={this.handleFetchSelectedItemNatureData}
              handleFetchAllItemNatureData={this.handleFetchAllItemNatureData}
              handleUpdateItemsNatureInPlant={this.handleUpdateItemsNatureInPlant}
              handleRemoveItemsNatureFromPlant={this.handleRemoveItemsNatureFromPlant}
            />
          ) : (
            <ManagePlantMasterAddEdit
              {...this.state}
              {...this.props}
              itemCategoriesList={this.state.itemCategoriesList}
              allStateList={this.state.allStateList}
              handlePlantItemMasterDataAdd={this.handlePlantItemMasterDataAdd}
              handlePlantItemMasterDataUpdate={
                this.handlePlantItemMasterDataUpdate
              }
              handleRemovePlantItemMaster={this.handleRemovePlantItemMaster}
              handleAddNewItemsInPlant={this.handleAddNewItemsInPlant}
              handleRemoveItemsFromPlant={this.handleRemoveItemsFromPlant}
              handleFetchAllStatesList={this.handleFetchAllStatesList}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    } else return <AccessDenied />;
  }
}
export default withUser(ManagePlantMasterDataContainer);
