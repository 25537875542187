import { IconButton, SwipeableDrawer, Typography } from "@mui/material";
import {
  ModuleHeaderSelectionTable,
  SaveTemplatesTable,
  SearchBar,
  SelectHeader,
} from "./comman";
import { grey, secondaryGrey } from "../../../../constants/internal/colors";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Slide,
} from "@material-ui/core";
import { primaryColor, secondaryColor } from "../../../../styles/colors";
import debounce from "lodash.debounce";
import CircularProgressLoader from "../../../common/CircularProgressLoader";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../common/NoDataFound";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      height: 9,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(75, 135, 197, 0.9)",
      borderRadius: 10,
      border: "2px solid transparent",
      backgroundClip: "padding-box", // <== make the border work
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(75, 135, 197, 0.9)",
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowAllHeadersDialog = ({
  open,
  handleClose,
  selectedHeader,
  showAllArray,
  allHeaderLoader,
  moduleFieldHeader,
  handleModuleManyHeaderAddRemove,
  handleTableDataChangeValidation,
}) => {
  const classes = useStyles();
  const [filteredHeader, setFilteredHeader] = useState({
    data: [],
    filteredLength: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [headerAddRemove, setHeaderAddRemove] = useState({
    checked: [],
    unchecked: [],
  });

  const [checkboxStates, setCheckboxStates] = useState({});

  useEffect(() => {
    const initialCheckboxStates = {};
    selectedHeader.forEach((id) => {
      initialCheckboxStates[id] = true;
    });
    setCheckboxStates(initialCheckboxStates);
  }, [selectedHeader]);

  const handleSearchFilterHeaderDebounce = debounce((searchText) => {
    // console.log("HERE =", searchText)
    if (searchValue === "") {
      setFilteredHeader({ data: [], filteredLength: 0 });
    } else {
      let filteredData = [];
      let filteredDataLength = 0;
      const formattedSearchText = searchText.replace(/\s+/g, '').toUpperCase();
      showAllArray.forEach((ele) => {
        let filterItems = ele?.items
          ? ele.items.filter((d) => {
            const formattedGeneralName = d.general_name.replace(/\s+/g, '').toUpperCase();
            return formattedGeneralName.includes(formattedSearchText);
          })
          : [];
        filteredDataLength = filteredDataLength + filterItems.length;
        // console.log(ele.category, filterItems)
        if (filterItems.length > 0) {
          filteredData.push({ category: ele.category, items: filterItems });
        }
      });
      setFilteredHeader({
        data: filteredData,
        filteredLength: filteredDataLength,
      });
    }
  }, 300);

  useEffect(() => {
    handleSearchFilterHeaderDebounce(searchValue);
    return () => {
      handleSearchFilterHeaderDebounce.cancel();
    };
  }, [searchValue]);

  const handleSearchFilterHeader = (searchText) => {
    setSearchValue(searchText);
  };

  const handleHeaderCheckUnchecked = (data, type) => {
    // console.log(data, type)
    if (type === "checked") {
      // console.log(!Boolean(selectedHeader.find(d => d === data.id)))
      if (!Boolean(selectedHeader.find((d) => d === data.id))) {
        setHeaderAddRemove({
          ...headerAddRemove,
          checked: [...headerAddRemove.checked, data],
        });
      } else {
        setHeaderAddRemove({
          ...headerAddRemove,
          unchecked: headerAddRemove.unchecked.filter((d) => d !== data.id),
        });
      }
    } else if (type === "unchecked") {
      if (selectedHeader.find((d) => d === data)) {
        setHeaderAddRemove({
          ...headerAddRemove,
          unchecked: [...headerAddRemove.unchecked, data],
        });
      } else {
        setHeaderAddRemove({
          ...headerAddRemove,
          checked: headerAddRemove.checked.filter((d) => d.id !== data),
        });
      }
    }
  };

  const handleResetState = () => {
    setCheckboxStates({});
    setHeaderAddRemove({ checked: [], unchecked: [] });
    setFilteredHeader({ data: [], filteredLength: 0 })
    setSearchValue('')
  };

  const handleAddClick = async () => {
    let checkValidation = await handleTableDataChangeValidation()
    if (checkValidation) {
      handleModuleManyHeaderAddRemove(
        headerAddRemove.checked,
        headerAddRemove.unchecked
      );
      handleResetState();
      handleClose();
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
      keepMounted
      onClose={() => {
        handleResetState();
        handleClose();
      }}
    >
      <div
        style={{
          borderRadius: 16,
          padding: 8,
        }}
      >
        <div
          style={{
            minHeight: 200,
          }}
        >
          <DialogTitle>Select Headers</DialogTitle>
          <div style={{ marginLeft: 10 }}>
            <SearchBar
              searchValue={searchValue}
              placeholder=""
              handleSearch={handleSearchFilterHeader}
            />
          </div>
          <DialogContent className={classes.root}>
            {/* {console.log(filteredHeader, moduleFieldHeader)} */}
            <div>
              <MenuList
                style={{
                  margin: (allHeaderLoader || showAllArray.length <= 0) && "0 auto",
                  columnGap: 0,
                  columnCount:
                    filteredHeader.filteredLength <= 0
                      ? Math.ceil(moduleFieldHeader.length / 10)
                      : filteredHeader.filteredLength > 0 &&
                        filteredHeader.filteredLength <= 9
                        ? 1
                        : filteredHeader.filteredLength > 9 &&
                          filteredHeader.filteredLength <= 15
                          ? 2
                          : Math.ceil(filteredHeader.filteredLength / 15),

                  width: "fit-content",
                  minHeight: 350,
                }}
              >
                {allHeaderLoader ? (
                  <div style={{ position: "absolute", top: "20vh" }}>
                    <CircularProgressLoader />
                  </div>
                ) : showAllArray.length <= 0 ? (
                  <div
                    style={{ position: "absolute", left: "-12vw", width: 350 }}
                  >
                    <NoDataFound
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: 120,
                      }}
                    />
                  </div>
                ) : (
                  (searchValue.length > 0
                    ? filteredHeader.data
                    : showAllArray
                  ).map((value) => {
                    return (
                      <div
                        style={{
                          margin: "5px 0",
                          boxSizing:
                            "border-box" /* Include padding/border in width */,
                          padding: "5px 0",
                        }}
                      >
                        <span style={{ paddingLeft: 10, fontWeight: 500 }}>
                          {value?.category?.toUpperCase()}
                        </span>
                        <div style={{ marginBottom: 10, }}>
                          {value.items.map((d) => {
                            return (
                              <MenuItem
                                style={{
                                  paddingTop: "4px",
                                  paddingBottom: "4px",
                                }}
                              >
                                <Checkbox
                                  edge="start"
                                  checked={checkboxStates[d.id] || false}
                                  tabIndex={-1}
                                  disableRipple
                                  onChange={(e) => {
                                    setCheckboxStates({
                                      ...checkboxStates,
                                      [d.id]: e.target.checked,
                                    });

                                    if (Boolean(e.target.checked))
                                      handleHeaderCheckUnchecked(d, "checked");
                                    else
                                      handleHeaderCheckUnchecked(
                                        d.id,
                                        "unchecked"
                                      );
                                  }}
                                  style={{
                                    transform: "scale(0.8)",
                                    padding: "0 2px 0 0",
                                    color: secondaryColor,
                                  }}
                                  inputProps={{
                                    "aria-labelledby": `list-label-${d.general_name}`,
                                  }}
                                />
                                <span
                                  style={{
                                    width: "max-content",
                                    fontSize: "0.9em",
                                    fontWeight: 400,
                                  }}
                                  onClick={() => {
                                    setCheckboxStates({
                                      ...checkboxStates,
                                      [d.id]: !Boolean(checkboxStates[d.id]),
                                    });

                                    if (!Boolean(checkboxStates[d.id]))
                                      handleHeaderCheckUnchecked(d, "checked");
                                    else
                                      handleHeaderCheckUnchecked(
                                        d.id,
                                        "unchecked"
                                      );
                                  }}
                                >
                                  {d.general_name}
                                </span>
                              </MenuItem>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                )}
              </MenuList>
            </div>
          </DialogContent>
          <DialogActions>
            {/* {console.log(headerAddRemove)} */}
            <Button
              variant="contained"
              style={{
                backgroundColor:
                  headerAddRemove.checked.length <= 0 &&
                    headerAddRemove.unchecked.length <= 0
                    ? "rgba(0,0,0,0.26)"
                    : primaryColor,
                color: "white",
              }}
              disabled={
                headerAddRemove.checked.length <= 0 &&
                headerAddRemove.unchecked.length <= 0
              }
              onClick={() => {
                handleAddClick()
              }}
            >
              ADD
            </Button>
            <Button
              onClick={() => {
                handleResetState();
                handleClose();
              }}
            >
              CANCEL
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

const SingleDynamicReports = ({
  user,
  handleTabValueTitle,
  isMobile,

  //selectHeader
  allHeaderLoader,
  moduleFieldHeader,
  handleModuleHeaderAdd,
  handleModuleManyHeaderAddRemove,
  selectedHeader,
  showAllArray,
  tabActivetemplate,
  savedTemplate,
  buttonClickLoader,
  filterValueApiLoader,

  //SelectedHeadertable
  tableDataLoader,
  selectedHeaderTableData,
  filterType,
  handleFilterTypes,
  handleCalculationTypes,
  handleModuleHeaderRemove,
  handleTableDataOrder,
  handleRequestReportClick,
  handleCancelResetRequestReport,
  handleSaveTemplateClick,
  appliedTemplateData,
  handleOpenSnackbar,
  handleCloseSnackbar,
  handleTableDataChangeValidation,
  FetchMasterFilterValueSearchApiData,
  orderByData,
  handleTableOrderBy,

  //saveTemplateTable
  templateSearchbar,
  handleTemplateSearchBar,
  handleApplyTemplate,
  handleSavedTemplatePinUnpin,
  handleDeleteSavedTemplate,
  handleTableDataIsChangedValidation,
}) => {
  const [open, setOpen] = useState(false);
  const [showAllOpen, setShowAllOpen] = useState(false);
  const drawerRef = useRef(null);

  const handleShowAllOpen = () => {
    setShowAllOpen(true);
  };
  const handleShowAllClose = () => {
    setShowAllOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClearAll = async () => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation)
      handleModuleHeaderRemove("clear");
  }

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (drawerRef.current && !drawerRef.current.contains(event.target)) {
  //       handleDrawerClose();
  //     }
  //   };

  //   if (open) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [open, handleDrawerClose]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
          flexDirection: isMobile && "column",
          margin: "10px 0 10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
            flexWrap: isMobile && "wrap",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <div
              style={{
                width: isMobile ? "100%" : 250,
                marginBottom: isMobile && 10,
              }}
            >
              <SelectHeader
                user={user}
                handleTabValueTitle={handleTabValueTitle}
                disableOptions={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                allHeaderLoader={allHeaderLoader}
                dropdownData={moduleFieldHeader}
                handleHeaderSelect={handleModuleHeaderAdd}
                selectedHeader={selectedHeader}
                handleTableDataChangeValidation={handleTableDataChangeValidation}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "fit-content",
                margin: "8px 0 0 20px",
              }}
            >
              <Button
                style={{
                  color:
                    ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)
                      ? "rgba(0,0,0,0.26)"
                      : secondaryColor,
                }}
                variant="text"
                onClick={handleShowAllOpen}
                disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
              >
                Show All
              </Button>
              <ShowAllHeadersDialog
                user={user}
                handleTabValueTitle={handleTabValueTitle}
                open={showAllOpen}
                handleClose={handleShowAllClose}
                selectedHeader={selectedHeader}
                showAllArray={showAllArray}
                allHeaderLoader={allHeaderLoader}
                moduleFieldHeader={moduleFieldHeader}
                handleModuleManyHeaderAddRemove={
                  handleModuleManyHeaderAddRemove
                }
                handleTableDataChangeValidation={handleTableDataChangeValidation}
              />
              <Button
                style={{
                  color:
                    selectedHeaderTableData.length <= 0 ||
                      ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)
                      ? "rgba(0,0,0,0.26) "
                      : secondaryColor,
                }}
                variant="text"
                disabled={
                  selectedHeaderTableData.length <= 0 ||
                  ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)
                }
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            </div>
          </div>
          <div
            style={{
              // width: '33%',
              display: "flex",
              justifyContent: isMobile ? "flex-start" : "flex-end",
              alignItems: "center",
              width: 300,
              position: "relative",
              cursor: "pointer",
            }}
          // onClick={handleDrawerOpen}
          >
            <Button
              startIcon={<ChevronLeftIcon />}
              onClick={handleDrawerOpen}
              variant="text"
              style={{ color: secondaryColor }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  // color: grey,
                  fontSize: "1.1em",
                  fontWeight: 600,
                  // marginLeft: 10,
                  // color: secondaryColor
                }}
              >
                SAVED TEMPLATES
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingBottom: 30,
        }}
      >
        <ModuleHeaderSelectionTable
          user={user}
          handleTabValueTitle={handleTabValueTitle}
          tableDataLoader={tableDataLoader}
          buttonClickLoader={buttonClickLoader}
          filterValueApiLoader={filterValueApiLoader}
          selectedHeaderTableData={selectedHeaderTableData}
          filterType={filterType}
          handleFilterTypes={handleFilterTypes}
          handleCalculationTypes={handleCalculationTypes}
          handleModuleHeaderRemove={handleModuleHeaderRemove}
          handleTableDataOrder={handleTableDataOrder}
          handleRequestReportClick={handleRequestReportClick}
          handleCancelResetRequestReport={handleCancelResetRequestReport}
          handleSaveTemplateClick={handleSaveTemplateClick}
          handleOpenSnackbar={handleOpenSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          tabActivetemplate={tabActivetemplate}
          appliedTemplateData={appliedTemplateData}
          handleTableDataChangeValidation={handleTableDataChangeValidation}
          FetchMasterFilterValueSearchApiData={FetchMasterFilterValueSearchApiData}
          orderByData={orderByData}
          handleTableOrderBy={handleTableOrderBy}
          handleTableDataIsChangedValidation={handleTableDataIsChangedValidation}
        />
        <SwipeableDrawer
          ref={drawerRef}
          anchor="right"
          open={open}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 380,
              position: "fixed",
              zIndex: 1000,
              paddingTop: "65px",
            },
          }}
          variant="persistent"
          transitionDuration={{
            enter: 400,
            exit: 500,
          }}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          <div style={{ padding: "6px 0 10px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 8,
                // padding: '0 20px'
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerClose}
                sx={{ ...(!open && { display: "none" }) }}
                style={{ color: secondaryColor }}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider style={{ margin: "10px 0" }} />
            <SaveTemplatesTable
              user={user}
              handleTabValueTitle={handleTabValueTitle}
              buttonClickLoader={buttonClickLoader}
              templateSearchbar={templateSearchbar}
              handleTemplateSearchBar={handleTemplateSearchBar}
              savedTemplate={savedTemplate}
              selectedHeader={selectedHeader}
              tabActivetemplate={tabActivetemplate}
              handleApplyTemplate={handleApplyTemplate}
              handleSavedTemplatePinUnpin={handleSavedTemplatePinUnpin}
              handleDeleteSavedTemplate={handleDeleteSavedTemplate}
              appliedTemplateData={appliedTemplateData}
            />
          </div>
        </SwipeableDrawer>
      </div>
    </>
  );
};

export default SingleDynamicReports;
