import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  dailyRateScreenTextColor,
  secondaryColor,
} from "../../../styles/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import SpinLoader from "../../common/SpinLoader";
import Checkbox from "@material-ui/core/Checkbox";
import "../../../styles/b2b/web/RegistrationWeb.css";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = {
  registerSelectCategoryWebMainBody: {
    height: "90%",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "85%",
    overflowY: "scroll",
    padding: "30px 50px 20px 50px",
  },
  textFieldStyle: {
    width: "100%",
  },
  textFieldStyle_1: {
    width: "100%",
  },
  selectTextConatainer: {
    width: "90%",
    color: dailyRateScreenTextColor,
    fontSize: "0.9em",
  },
  panNumberContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  panCardImageContainer: {
    width: "90%",
    display: "flex",
    //flexDirection: "column",
    alignItems: "flex-end",
  },

  panCardImageStyle: {
    width: "40%",
    minHeight: "100px",
    border: "1px dashed #636363",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#636363",
    cursor: "pointer",
  },
};
class RegisterGstNumberWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstGetDetails: false,
      dontHaveGST: false,
      vertical: "top",
      horizontal: "center",
    };
  }
  handleGetGST = () => {
    this.props.handleGetGSTInfo(this.props.Gst_number.toUpperCase());
  };
  handleChangeCheckBox = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
        GST_LengthError: false,
        gstNumberCheck: false,
        gstNumberAlreadyRegistered: false,
      },
      () => {
        this.props.handleDontHaveGST();
        if (this.state.dontHaveGST) {
          this.props.resetGstDetail();
        }
      }
    );
  };
  handleChangeCheckBoxPanActiveStatus = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
      },
      this.props.handlePancardActiveStatus()
    );
  };
  handlePanImageChange = (e) => {
    if (e.target.files.length) {
      this.setState(
        {
          PanImagePreview: URL.createObjectURL(e.target.files[0]),
          rawPanImage: e.target.files[0],
          PanImagePreviewLoader: false,
        },
        () => {
          this.props.handlePanCardImageFile(this.state.rawPanImage);
        }
      );
    }
  };
  handleDeletePanImage = () => {
    this.setState(
      {
        PanImagePreview: "",
        rawPanImage: null,
        PanImagePreviewLoader: true,
      },
      () => {
        this.props.handlePanCardImageFile(this.state.rawPanImage);
      }
    );
  };
  render() {
    const {
      GST_LengthError,
      dontHaveGST,
      panCardActiveStatus,
      panNumberLengthError,
    } = this.state;
    const {
      classes,
      Gst_number,
      handleTextChange,
      gstNumberCheck,
      gstLoader,
      gstData,
      gstNumberAlreadyRegistered,
      state,
      pincode,
      addressLine_one,
      panNumber,
      gstErrorMessage,
    } = this.props;
    return (
      <div className={classes.registerSelectCategoryWebMainBody}>
        <div className={classes.selectTextConatainer}>
          <span>GST Information</span>
        </div>
        <TextField
          disabled={dontHaveGST}
          name="Gst_number"
          id="outlined-name"
          label="GST Number"
          margin="normal"
          variant="outlined"
          value={Gst_number.toUpperCase()}
          className={classes.textFieldStyle}
          onChange={handleTextChange}
          inputProps={{
            maxLength: 15,
            style: { padding: "13px 14px" },
          }}
          InputLabelProps={{
            style: { fontSize: "1em", marginTop: "-4px" },
          }}
          onFocus={() => {
            this.props.resetGstDetail();
            this.props.handleResetGSTdetails();
            this.setState({ GST_LengthError: false, gstGetDetails: false });
          }}
          onBlur={() => {
            if (Gst_number.length < 15) {
              this.setState({ GST_LengthError: true });
            }
          }}
          FormHelperTextProps={{
            style: { marginLeft: "0px", marginRight: "0px" },
          }}
          helperText={
            <span style={{ color: "red" }}>
              {GST_LengthError
                ? "Please enter valid 15 digit GST number"
                : Gst_number.length === 15 && gstNumberCheck === "invalid"
                  ? gstErrorMessage
                  : null}
            </span>
          }
          error={GST_LengthError ? true : false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disabled={dontHaveGST}
                  style={{
                    fontSize: "0.7em",
                    textTransform: "capitalize",
                    padding: "6px 0px",
                    color: secondaryColor,
                  }}
                  onClick={(e) => {
                    e.preventDefault();

                    if (Gst_number.length < 15) {
                      this.setState({ GST_LengthError: true });
                    } else {
                      this.handleGetGST();
                      //this.setState({ gstGetDetails: true });

                      // this.props.handleShowGSTdetails();
                    }
                  }}
                >
                  Get Details
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "90%" }}>
            <Checkbox
              style={{
                color: dontHaveGST ? secondaryColor : null,
              }}
              checked={this.state.dontHaveGST}
              onChange={this.handleChangeCheckBox("dontHaveGST")}
              value="dontHaveGST"
            />
            <span>I don't have GSTIN</span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {gstLoader ? (
            <div>
              <SpinLoader />
            </div>
          ) : null}
        </div>
        {gstNumberCheck === "pending" || gstNumberCheck === "invalid" ? null : (
          <div style={{ width: "90%", marginTop: "12px" }}>
            <div className="b2b-register-gst-detail-web-legal-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                Legal Name
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {gstData.lgnm}
              </span>
            </div>

            <div className="b2b-register-gst-detail-web-trade-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                Trade Name
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {gstData.tradeNam}
              </span>
            </div>
            <div className="b2b-register-gst-detail-web-central-jurisdiction-container">
              <div className="b2b-register-gst-detail-web-central-jurisdiction-child">
                <span className="b2b-register-gst-detail-web-legal-name-text">
                  Central Jurisdiction
                </span>
                <span>{gstData.ctj}</span>
              </div>
              <div className="b2b-register-gst-detail-web-central-jurisdiction-child">
                <span className="b2b-register-gst-detail-web-legal-name-text">
                  State Jurisdiction
                </span>
                <span>{gstData.stj}</span>
              </div>
            </div>
            <div className="b2b-register-gst-detail-web-trade-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                Date of Registration
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {gstData.rgdt}
              </span>
            </div>

            <div className="b2b-register-gst-detail-web-trade-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                Constitution of Business
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {gstData.ctb}
              </span>
            </div>
            <div className="b2b-register-gst-detail-web-trade-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                GSTIN Status
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {gstData.sts}
              </span>
            </div>
            <div className="b2b-register-gst-detail-web-trade-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                Address
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {addressLine_one}
              </span>
            </div>
            <div className="b2b-register-gst-detail-web-trade-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                State
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {state}
              </span>
            </div>
            <div className="b2b-register-gst-detail-web-trade-name-container">
              <span className="b2b-register-gst-detail-web-legal-name-text">
                Pincode
              </span>
              <span className="b2b-register-gst-detail-web-legal-company-name-text">
                {pincode}
              </span>
            </div>
          </div>
        )}
        {dontHaveGST ? (
          <div className={classes.panNumberContainer}>
            <TextField
              name="panNumber"
              id="outlined-name"
              label="PAN NUMBER"
              margin="normal"
              variant="outlined"
              onChange={handleTextChange}
              value={panNumber.toUpperCase()}
              onBlur={() => {
                if (panNumber.length < 10) {
                  this.setState({ panNumberLengthError: true });
                }
              }}
              onFocus={() => {
                this.setState({ panNumberLengthError: false });
              }}
              inputProps={{
                maxLength: 10,
                style: { padding: "13px 14px" },
              }}
              helperText={
                <span style={{ color: "red" }}>
                  {panNumberLengthError
                    ? "Please enter valid 10 digit PAN number"
                    : null}
                </span>
              }
              error={panNumberLengthError ? true : false}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
              className={classes.textFieldStyle}
            />
            <TextField
              name="panCardHolderName"
              id="outlined-name"
              label="PAN CARD HOLDER NAME"
              margin="normal"
              variant="outlined"
              onChange={handleTextChange}
              inputProps={{
                style: { padding: "13px 14px" },
              }}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
              className={classes.textFieldStyle}
            />
            <TextField
              name="companyName"
              id="outlined-name"
              label="Company Name"
              margin="normal"
              variant="outlined"
              onChange={handleTextChange}
              inputProps={{
                style: { padding: "13px 14px" },
              }}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
              className={classes.textFieldStyle}
            />
            <div
              style={{ width: "100%", margin: "10px 0px", paddingLeft: "15px" }}
            >
              <span style={{ padding: "12px 0px" }}>Pan card image</span>
              <p
                style={{ fontSize: "12px", textAlign: "left" }}>
                {"Accepted File Types : pdf/jpg/jpeg/png"}
              </p>
              {this.props.panImageFileTypeError && <p
                style={{ color: "red", fontSize: "12px" }}>
                {"Invalid File type"}
              </p>}
            </div>
            <div className={classes.panCardImageContainer}>
              <div className={classes.panCardImageStyle}>
                {this.state.PanImagePreviewLoader === false ? (
                  <div style={{ height: "90%" }}>
                    {this.state.rawPanImage.type === "image/png" ||
                      this.state.rawPanImage.type === "image/jpg" ||
                      this.state.rawPanImage.type === "image/jpeg" ? (
                      <img
                        src={this.state.PanImagePreview}
                        alt="img"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <span style={{ wordBreak: "break-all", display: 'flex', padding: '0 10px' }}>{this.state.rawPanImage.name}</span>
                    )}
                  </div>
                ) : (
                  <label className="pan-img-label">
                    <img
                      src={
                        window.cordova
                          ? `${this.image}${"/uploadImg.svg"}`
                          : "/uploadImg.svg"
                      }
                      style={{
                        height: "35%",
                        width: "auto",
                        objectFit: "contain",
                      }}
                      alt="img"
                    />
                    UPLOAD
                    <input
                      type="file"
                      size="60"
                      className="pan-img-input"
                      inputProps={{ accept: "image/*" }}
                      onChange={(e) => {
                        e.preventDefault();
                        this.handlePanImageChange(e);
                      }}
                    />
                  </label>
                )}
              </div>
              {this.state.PanImagePreviewLoader === false ? (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleDeletePanImage();
                  }}
                  style={{ paddingLeft: "15px", width: "20%" }}
                  aria-label="Delete"
                >
                  <Delete />
                </IconButton>
              ) : null}
            </div>
            <div
              style={{
                padding: "12px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Checkbox
                style={{
                  color: panCardActiveStatus ? secondaryColor : null,
                }}
                checked={this.state.panCardActiveStatus}
                onChange={this.handleChangeCheckBoxPanActiveStatus(
                  "panCardActiveStatus"
                )}
                value="panCardActiveStatus"
              />
              <span>The pan card provided is correct and active </span>
            </div>
          </div>
        ) : null}

        <Dialog
          open={this.props.noInternetConnection}
          onClose={this.props.noInternetConnectionDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: secondaryColor }}
          >
            {"Connection error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Unable to contact server. Please check your internet connection
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.noInternetConnectionDialog}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterGstNumberWeb);
