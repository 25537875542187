import React, { Component } from 'react';
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import RoleAccessComponent from './RoleAccessComponent';
import RoleAssignedToTable from './RoleAssignedToTable';
import { Typography, TextField, Button, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  secondaryBlueText,
  drawerTextColor,
  grey,
  successButtonBackground,
  primaryColor,
  cancelButtonBackground,
} from '../../../../constants/internal/colors';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { checkAlphabet, checkApiError, checkNumeric, pxToEm } from '../../../../methods';
import moment from 'moment';
import SnackbarComponent from '../../common/SnackbarComponent';
import SaveButton from '../../common/SaveButton';

const manageRolesAndPermissionsStyles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '27ch',
  },
  titleWithBlueFont: {
    fontSize: pxToEm(18),
    color: secondaryBlueText,
  },
  displayData: {
    marginTop: 10,
  },
  roleNameSection: {
    display: 'flex',
    placeContent: 'space-between',
    marginTop: 20,
    alignItems: 'baseline',
    // height: 52,
    width: '44%'
    // minWidth: 650,
  },
  roleNameAlignment: {
    display: 'flex',
    placeItems: 'baseline',
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: 'bold',
  },
  displayItemTitle: {
    paddingLeft: 15,
    color: grey,
    fontSize: pxToEm(14),
  },
  generalTopMargin: { marginTop: 40 },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
  },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  mainDiv: {
    width: '100%',
    height: '100%',
    paddingBottom: '30px',
    overflowY: 'scroll',
  },
  rightPadding: { paddingRight: '25px' },
});
class ManageRolesAndPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleName: this.props.location.pathname.includes('new')
        ? ""
        : this.props?.role?.name,
      rolesAndPermissionOperation: 'view',
      role: null,
      password_valid_upto: this.props.location.pathname.includes('new')
        ? ""
        : this.props?.role?.password_valid_upto || "",
      // password_valid_upto_days: 0,
      openSnackbar: false,
      snackbarMessage: ``,
      snackbarType: "",
      errorPasswordStatus: false,
      errorRoleNameStatus: false,
      errorRoleNameText: '',
      rolesNameList: this.props?.rolesNameList,
      isLoading: false,
      isLoadingDelete: false
    };
  }

  componentDidMount() {
    let rolesAndPermissionOperation = 'new';
    if (this.props.location.pathname.includes('view'))
      rolesAndPermissionOperation = 'view';
    if (this.props.location.pathname.includes('edit')) {
      if (this.state.roleName === undefined) {
        rolesAndPermissionOperation = 'view'
        this.props.history.push(
          '/internalUsersAndPermissions/rolesAndPermissions/view/' + this.props?.location?.pathname?.split('/')[
          this.props?.location?.pathname?.split('/').length - 1
          ]
        )
      }
      else
        rolesAndPermissionOperation = 'edit';
    }
    if (
      this.state?.rolesAndPermissionOperation === 'view' ||
      this.state?.rolesAndPermissionOperation === 'edit'
    ) {
      const id = this.props?.location?.pathname?.split('/')[
        this.props?.location?.pathname?.split('/').length - 1
      ];
      this.props.getARoleAndPermissions(id);

    }
    this.setState({
      rolesAndPermissionOperation,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.rolesAndPermissionOperation === 'view' ||
      (state.rolesAndPermissionOperation === 'edit' &&
        props.errorRole === '' &&
        !props.loadingRole)
    ) {
      return { role: props.role };
    } else return null;
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  checkNameField = (input) => {
    const regex = /^[a-zA-Z\s]+[-]?[a-zA-Z0-9\s]+$/;
    if (regex.test(input)) {
      return true
    }
    return false;
  }

  handleChange = (event) => {
    if (event.target.name === "passwordValidity") {
      this.setState({
        password_valid_upto: String(event.target.value)
      })
    }
    else if (event.target.name === "role_name") {
      this.setState({ roleName: event.target.value });
    }
    console.log(this.state.rolesNameList)

    if (!event.target.value) {
      if (event.target.name === "passwordValidity") {
        this.setState({ errorPasswordStatus: true });
      } else if (event.target.name === "role_name") {
        this.setState({ errorRoleNameStatus: true, errorRoleNameText: 'Invalid name' });
      }
    } else {
      if (event.target.name === "role_name") {
        console.log(this.state.rolesNameList.includes((event.target.value).toUpperCase()))
        !this.checkNameField(event.target.value) ?
          this.setState({ errorRoleNameStatus: true, errorRoleNameText: 'Invalid name' }) :
          this.state.rolesNameList.includes((event.target.value).toUpperCase()) ?
            this.setState({ errorRoleNameStatus: true, errorRoleNameText: 'Already exist' }) :
            this.setState({ errorRoleNameStatus: false, errorRoleNameText: '' })
      } else if (event.target.name === "passwordValidity") {
        !checkNumeric(event.target.value) ?
          this.setState({ errorPasswordStatus: true }) :
          this.setState({ errorPasswordStatus: false })
      }
    }
  };

  handleSave = (role_name, password_valid_upto) => {
    this.setState({ isLoading: true })
    this.props
      .addARole({
        role_name,
        password_valid_upto
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.handleOpenSnackbar(
            `Roles & Permission Created Successfully`,
            "success"
          );
          setTimeout(() => {
            this.setState({ isLoading: false })
            this.setState({ roleName: '', password_valid_upto: 0 });
            this.props.history.goBack()
            this.handleCloseSnackbar()
          }, 4000);
        } else {
          this.setState({ isLoading: false })
          this.handleOpenSnackbar(
            `Error occured while creating roles & permission please try again`,
            "error"
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
    //   this.setState({ roleName: '', password_valid_upto: 0 });
    //   this.props.history.goBack()
    //   // this.props.history.push(
    //   //   '/internalUsersAndPermissions/rolesAndPermissions/view/' + response.id
    //   // );
    // })
    // .catch(console.error);
  };

  handleUpdate = (role_name, password_valid_upto) => {
    this.setState({ isLoading: true })
    this.props
      .updateARole({
        role_name,
        password_valid_upto,
        id: this.state?.role.id,
      })
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Roles & Permission Updated Successfully`,
            "success"
          );
          setTimeout(() => {
            this.setState({ isLoading: false })
            this.props.history.goBack()
            this.handleCloseSnackbar()
          }, 4000);
        } else {
          this.setState({ isLoading: false })
          this.handleOpenSnackbar(
            `Error occured while updating roles & permission please try again`,
            "error"
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
    // this.props.history.goBack())
    // })
    // .catch(console.error);
  };

  handleRemove = (id) => {
    this.setState({ isLoadingDelete: true })
    this.props
      .deleteARole(id)
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          this.handleOpenSnackbar(
            `Roles & Permission Removed Successfully`,
            "success"
          );
          setTimeout(() => {
            this.setState({ isLoadingDelete: false })
            this.props.history.push('/internalUsersAndPermissions/rolesAndPermissions');
            this.handleCloseSnackbar()
          }, 4000);
        } else if (response['response']['status'] === 500) {
          this.setState({ isLoadingDelete: false })
          this.handleOpenSnackbar(
            `This Roles cannot be Deleted Because it is referenced to Other Data`,
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar()
          }, 4000);
        }
        else {
          this.setState({ isLoadingDelete: false })
          this.handleOpenSnackbar(
            `Error occured while removing roles & permission please try again`,
            "error"
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoadingDelete: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
    // this.props.history.goBack();
    // this.props.history.goBack();
    // })
    // .catch(console.error);
  };

  handleEdit = (id) => {
    console.log(id)
    this.props.history.push(
      '/internalUsersAndPermissions/rolesAndPermissions/edit/' + id
    );
  };

  generateOrderValidityDate = (dateObj, numberOfDaysToAdd) => {
    dateObj.setDate(dateObj.getDate() + Number(numberOfDaysToAdd));
    return dateObj;
  };

  render() {
    const {
      roleName,
      role,
      rolesAndPermissionOperation,
      password_valid_upto,
    } = this.state;
    const { handleEdit } = this;
    const {
      classes,
      users,
      modules,
      addAUserToARole,
      deleteAUserFromARole,
      getARoleAndPermissions,
      addModuleToARole,
      deleteAModuleFromRole,
      updateWidgetPermissionForRole,
      isMobile,
    } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          {' '}
          <div className={classes.rightPadding}>
            <Typography>
              {
                <>
                  <span
                    className={classes.titleWithBlueFont}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.goBack();
                      this.props.history.goBack();
                    }}
                  >
                    {`Users And Permissions`}
                  </span>
                  <span
                    className={classes.titleWithBlueFont}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.goBack();
                    }}
                  >
                    {` > Roles And Permissions > `}
                  </span>
                </>
              }
              {rolesAndPermissionOperation === 'view'
                ? 'Role Details'
                : rolesAndPermissionOperation === 'edit'
                  ? 'Edit Role'
                  : 'New Role'}
            </Typography>
            <div className={classes.displayData}>
              <div className={classes.roleNameSection} style={{ width: isMobile && '90%' }}>
                <span className={classes.contentTitle}>ROLE NAME</span>
                <div className={classes.roleNameAlignment}>
                  {rolesAndPermissionOperation === 'view' ? (
                    <span className={classes.displayItemTitle}>
                      {role?.name}
                    </span>
                  ) : (
                    <TextField
                      label='Role Name'
                      name="role_name"
                      id="outlined-margin-dense"
                      // defaultValue={roleName}
                      value={roleName}
                      placeholder="Enter Role Name"
                      className={classes.textField}
                      onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                      error={this.state.errorRoleNameStatus}
                      helperText={this.state.errorRoleNameText}
                    />
                  )}
                </div>
              </div>
              <div className={classes.roleNameSection} style={{ width: isMobile && '90%' }}>
                <span className={classes.contentTitle}>
                  {rolesAndPermissionOperation === 'view'
                    ? "PASSWORD VALID UPTO" : "PASSWORD VALIDITY"
                  }
                </span>
                <div className={classes.roleNameAlignment}>
                  {rolesAndPermissionOperation === 'view' ? (
                    <>
                      <span
                        className={classes.displayItemTitle}
                        style={{
                          fontSize: "0.9em",
                          color: grey,
                          paddingRight: 8
                        }}
                      >
                        {role?.password_valid_upto
                          ? role?.password_valid_upto
                          : ""
                        }
                      </span>
                      <span style={{ fontSize: "0.9em", color: grey }}>Days</span>
                    </>
                  ) : (
                    <TextField
                      label='Password validity'
                      name="passwordValidity"
                      id="outlined-margin-dense"
                      type="text"
                      inputProps={{ maxLength: 3, minLength: 3, max: 3 }}
                      // onKeyPress={(event) => {
                      //   if (event.charCode >= 48 && event.charCode <= 57) {
                      //     // let it happen, don't do anything
                      //   }
                      //   else { event.preventDefault(); }
                      // }}
                      value={this.state?.password_valid_upto}
                      className={classes.textField}
                      onChange={this.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span style={{ fontSize: "0.9em", color: grey }}>Days</span>
                          </InputAdornment>
                        ),
                      }}
                      margin="dense"
                      variant="outlined"
                      error={this.state.errorPasswordStatus}
                      helperText={this.state.errorPasswordStatus ? "Invalid Days" : ""}
                    />
                  )}
                </div>
              </div>
              <div className={classes.roleNameSection}>
                {rolesAndPermissionOperation === 'view' ? (
                  <Button
                    size="medium"
                    className={classes.primaryBackground}
                    variant="contained"
                    startIcon={<EditOutlinedIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      handleEdit(role?.id);
                    }}
                    disabled={!role}
                  >
                    EDIT
                  </Button>
                ) : (
                  <div>
                    <SaveButton
                      // disabled={
                      //   !roleName ||
                      //   !this.state?.password_valid_upto ||
                      //   this.state?.password_valid_upto === ""}
                      disabled={
                        !roleName ||
                        !this.state?.password_valid_upto ||
                        this.state?.password_valid_upto === "" ||
                        this.state.errorPasswordStatus ||
                        this.state.errorRoleNameStatus ||
                        this.state?.isLoadingDelete
                      }
                      size="medium"
                      className={classes.saveButton}
                      variant="contained"
                      startIcon={<CheckOutlinedIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        if (rolesAndPermissionOperation === 'new')
                          this.handleSave(roleName, password_valid_upto);
                        if (rolesAndPermissionOperation === 'edit')
                          this.handleUpdate(roleName, password_valid_upto);
                      }}
                      isLoading={this.state?.isLoading}
                    >
                      SAVE
                    </SaveButton>
                    {rolesAndPermissionOperation !== 'new' && (
                      <SaveButton
                        size="medium"
                        className={classes.removeButton}
                        variant="contained"
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleRemove(role.id);
                        }}
                        disabled={this.state?.isLoading}
                        isLoading={this.state?.isLoadingDelete}
                      >
                        REMOVE
                      </SaveButton>
                    )}
                    <Button
                      size="medium"
                      className={classes.margin}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.goBack();
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                )}
              </div>
              {rolesAndPermissionOperation === 'view' ||
                rolesAndPermissionOperation === 'edit' ? (
                <>
                  <div className={classes.generalTopMargin}>
                    <RoleAccessComponent
                      role={role}
                      modules={modules}
                      addModuleToARole={addModuleToARole}
                      getARoleAndPermissions={getARoleAndPermissions}
                      deleteAModuleFromRole={deleteAModuleFromRole}
                      updateWidgetPermissionForRole={
                        updateWidgetPermissionForRole
                      }
                      history={this.props.history}
                    />
                  </div>
                  <div className={classes.generalTopMargin}>
                    <RoleAssignedToTable
                      rolesAndPermissionOperation={rolesAndPermissionOperation}
                      deleteAUserFromARole={deleteAUserFromARole}
                      getARoleAndPermissions={getARoleAndPermissions}
                      role={role}
                      users={users}
                      addAUserToARole={addAUserToARole}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
        />
      </Layout>
    );
  }
}
export default withStyles(manageRolesAndPermissionsStyles)(
  ManageRolesAndPermissions
);
