export let LAHeaders = [
   // { //    minWidth: 50, //    header: "#", //    key: "key" // }, 
   { minWidth: 120, header: "Date", key: "created_at_date" },
   // { //    minWidth: 150, //    header: "Invoice no.", //    key: "doc_no" // }, 
   { minWidth: 80, header: "Plant Code", key: "plant_code" },
   { minWidth: 150, header: "LA #", key: "la_number" },
   { minWidth: 150, header: "Status", key: "dispatch_status" },
   { minWidth: 150, header: "Vehicle #", key: "truck_number" },
   { minWidth: 150, header: "Buyer", key: "buyer" },
   { minWidth: 150, header: "Customer", key: "customer" },
   // { minWidth: 300, header: "Broker", key: "broker" },
   { minWidth: 150, header: "Payment Terms", key: "payment_method" },
   { minWidth: 150, header: "Consignee", key: "consignee" },
   { minWidth: 150, header: "Item Category Name", key: "item_category" },
   { minWidth: 150, header: "Net Rate", key: "net_rate", calculation: "avg_rate", calc_text: "AVG Rate", dec_places: 2 },
   { minWidth: 150, header: "LA Quantity(UM)", key: "la_quantity", calculation: "total_la_qty", calc_text: "Total LA QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "Dispatched Quantity(UM)", key: "dispatched_quantity", calculation: "total_dispatched_qty", calc_text: "Total Dispatched QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "Cancelled Quantity(UM)", key: "cancelled_quantity", calculation: "total_cancelled_qty", calc_text: "Cancelled QTY (UM)", dec_places: 3 },
   {
      minWidth: 150, header: "Dispatch Time(Hrs)", key: "dispatch_time",
      // calculation: "total_time", calc_text: "Total Time (Hrs)", dec_places: 0
   },
   { minWidth: 150, header: "Net Amount", key: "amount" },
   { minWidth: 150, header: "Payment Status", key: "payment_status" },
]

export const invoiceHeaders = [
   { minWidth: 60, header: "#", key: "index" },
   { minWidth: 150, header: "Date", key: "invoice_date" },
   { minWidth: 120, header: "Plant Code", key: "plant_code" },
   { minWidth: 150, header: "LA #", key: "la_number" },
   { minWidth: 150, header: "Invoice #", key: "erp_invoice_number" },
   { minWidth: 150, header: "Buyer", key: "buyer" },
   { minWidth: 150, header: "Customer", key: "customer" },
   { minWidth: 150, header: "Payment Terms", key: "payment_method" },
   { minWidth: 150, header: "Payment Due Date", key: "payment_due_date" },
   { minWidth: 150, header: "Consignee", key: "consignee_name" },
   // { minWidth: 150, header: "Quantity", key: "quantity" },
   // { minWidth: 150, header: "Item Category Name", key: "item_category" },
   { minWidth: 180, header: "Item Name", key: "item" },
   { minWidth: 120, header: "Net Rate", key: "item_net_rate", calculation: "net_rate", calc_text: "AVG Rate", },
   { minWidth: 120, header: "Discount", key: "discount", calculation: "avg_discount", calc_text: "AVG Discount", dec_places: 2 },
   { minWidth: 120, header: "Dispatched Quantity", key: "item_dispatch_qty", calculation: "dispatched_quantity", calc_text: "Total Dispatched QTY (UM)", dec_places: 3 },
   { minWidth: 120, header: "Cancelled Quantity", key: "item_cancel_qty", calculation: "cancelled_quantity", calc_text: "Cancelled QTY (UM)", dec_places: 3 },
   { minWidth: 120, header: "Net Amount", key: "item_total_amount", calculation: "total_invoice_amount", calc_text: "Total Item Amount ", dec_places: 3 },
   { minWidth: 150, header: "Party Ref Date", key: "party_ref_date" },
   { minWidth: 120, header: "Status", key: "dispatch_status" },
   { minWidth: 120, header: "Payment Status", key: "payment_status" },

]
export const contractsHeaders = [
   { minWidth: 60, header: "#", key: "index" },
   { minWidth: 200, header: "Customer", key: "customer" },
   { minWidth: 130, header: "Customer Type", key: "customer_type" },
   { minWidth: 190, header: "Buyer", key: "buyer" },
   { minWidth: 120, header: "Date", key: "created_at_date" },
   { minWidth: 140, header: "Contract #", key: "order_number" },
   { minWidth: 170, header: "Item Category Name", key: "item_category" },
   { minWidth: 130, header: "Rate", key: "rate", calculation: "avg_rate", calc_text: "AVG Rate", dec_places: 2 },
   { minWidth: 120, header: "Discount", key: "discount", calculation: "avg_discount", calc_text: "AVG Discount", dec_places: 2 },
   { minWidth: 120, header: "Net Rate", key: "net_rate", calculation: "avg_net_rate", calc_text: "AVG Net Rate", dec_places: 2 },
   { minWidth: 150, header: "Contract Quantity", key: "booked_quantity", calculation: "total_qty", calc_text: "Total QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "Plant Quantity", plant_planning_header: "allotted_qty", key: "allotted_qty", calculation: "total_plant_qty", calc_text: "Plant QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Dispatched Quantity", plant_planning_header: "dispached_qty", key: "dispached_qty", calculation: "total_dispatched_qty", calc_text: "Total Dispatched QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Cancelled Quantity", plant_planning_header: "cancelled_qty", key: "cancelled_qty", calculation: "total_cancelled_qty", calc_text: "Cancelled QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Balance Quantity", plant_planning_header: "balance_qty", key: "balance_qty", calculation: "total_balance_qty", calc_text: "Balance QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Days Running", key: "days_running" },
   { minWidth: 150, header: "Payment Terms", key: "payment_method" },
   { minWidth: 150, header: "Brokerage", key: "new_brokerage" },
   { minWidth: 150, header: "Party Reference", key: "party_ref_number" },
   { minWidth: 150, header: "Internal Remarks", key: "internal_remark" },
   { minWidth: 150, header: "Status", key: "order_status" },

]

export const contractsPdfHeaders = [
   // { minWidth: 60, header: "#", key: "index" },
   { minWidth: 150, header: "Customer Name", key: "customer" },
   { minWidth: 150, header: "Buyer Name", key: "buyer" },
   { minWidth: 150, header: "Consignee Name", key: "consignee_name" },
   { minWidth: 150, header: "Contract #", key: "order_number" },
   { minWidth: 150, header: "Brokerage", key: "new_brokerage" },
   { minWidth: 140, header: "Item Category", key: "item_category" },
   { minWidth: 150, header: "Contract Date", key: "created_at_date" },
   { minWidth: 150, header: "Booked | Contract", key: "booked_quantity", calculation: "total_qty", key2: "quantity", calculation2: "total_contract_qty", calc_text: "Total QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "Net Rate", key: "net_rate", calculation: "avg_net_rate", calc_text: "AVG Net Rate", dec_places: 2 },
   { minWidth: 150, header: "Plant Quantity", plant_planning_header: "allotted_qty", key: "allotted_qty", calculation: "total_plant_qty", calc_text: "Plant QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Dispatch Quantity", plant_planning_header: "dispached_qty", key: "dispached_qty", calculation: "total_dispatched_qty", calc_text: "Total Dispatched QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Cancelled Quantity", plant_planning_header: "cancelled_qty", key: "cancelled_qty", calculation: "total_cancelled_qty", calc_text: "Cancelled QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Balance Quantity", plant_planning_header: "balance_qty", key: "balance_qty", calculation: "total_balance_qty", calc_text: "Balance QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Balance Contract", key: "balance_quantity", calculation: "total_balance_qty", calc_text: "Total Balance QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "Days Running", key: "days_running" },
   { minWidth: 150, header: "Payment Terms", key: "payment_method" },
   { minWidth: 150, header: "Party Reference", key: "party_ref_number" },
   { minWidth: 150, header: "Status", key: "order_status" },
   { minWidth: 150, header: "Internal Remarks", key: "internal_remark" },

]
export let contractsSubHeaders = [
   { minWidth: 150, header: "", key: "created_at_date" },
   { minWidth: 150, header: " no.", key: "order_number" },
   { minWidth: 150, header: "", key: "buyer" },
   { minWidth: 150, header: "", key: "customer" },
   { minWidth: 150, header: " Type", key: "customer_type" },
   { minWidth: 150, header: "", key: "item_category" },
   { minWidth: 150, header: "", key: "net_rate", calculation: "avg_rate", calc_text: "AVG Rate", dec_places: 2 },
   { minWidth: 150, header: "", key: "discount", calculation: "avg_discount", calc_text: "AVG Discount", dec_places: 2 },
   { minWidth: 150, header: "", key: "booked_quantity", calculation: "total_qty", calc_text: "Total QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "", key: "plant_quantity", calculation: "total_plant_qty", calc_text: "Plant QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "NV", key: "NV_dispatched_qty", calculation: "total_dispatched_qty", calc_text: "Total Dispatched QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "API", key: "AP_dispatched_qty", calculation: "total_cancelled_qty", calc_text: "Cancelled QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "", key: "balance_quantity", calculation: "total_balance_qty", calc_text: "Balance QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "", key: "days_running" },
   { minWidth: 150, header: "", key: "payment_method" },
   { minWidth: 150, header: "", key: "new_brokerage" },
   { minWidth: 150, header: "", key: "order_status" },
   { minWidth: 150, header: "", key: "internal_remark" },

]

export let WBGHeaders = [
   { minWidth: 150, header: "Plant Code", key: "plant_code" },
   { minWidth: 150, header: "LA #", key: "la_number" },
   { minWidth: 150, header: "WB #", key: "weighbridge" },
   { minWidth: 150, header: "Vehicle #", key: "truck_number" },
   { minWidth: 150, header: "Customer Name", key: "customer_name" },
   { minWidth: 150, header: "Buyer Name", key: "buyer_name" },
   { minWidth: 150, header: "Transporter Name", key: "transporter" },
   { minWidth: 150, header: "Item Category Name", key: "item_category" },
   { minWidth: 150, header: "Gate-In Name", key: "gate_in_gate" },
   { minWidth: 150, header: "Gate-Out Name", key: "gate_out_gate" },
   { minWidth: 150, header: "Tare Weight", key: "tare_weight", calculation: "avg_tare_weight", calc_text: "AVG Tare Weight", dec_places: 3 },
   { minWidth: 150, header: "Net Weight", key: "net_weight", calculation: "total_qty", calc_text: "Total QTY", dec_places: 3 },
   { minWidth: 150, header: "Total Trip #", key: "total_trip", calculation: "total_trips", calc_text: "Total Trips", dec_places: 0 },
]

export let EXRHeaders = [
   { minWidth: 150, header: "Module Name", key: "module_name" },
   { minWidth: 150, header: "Name & ID", key: "customer" },
   { minWidth: 150, header: "Field Description", key: "field_name" },
   { minWidth: 150, header: "Previous Value", key: "old_value" },
   { minWidth: 150, header: "New Value", key: "new_value" },
   { minWidth: 150, header: "Type", key: "type" },
   { minWidth: 150, header: "Remark", key: "remark" },
   { minWidth: 150, header: "Modified By", key: "created_by" },
   { minWidth: 150, header: "Modified At", key: "created_at_date" },
   { minWidth: 150, header: "Approved By", key: "approved_by" },
   { minWidth: 150, header: "Approved On", key: "approved_on" },
]

let DPTHeaders = [
   { minWidth: 150, header: "Date", key: "date" },
   { minWidth: 150, header: "Invoice #", key: "erp_invoice_number" },
   { minWidth: 150, header: "Plant Code", key: "plant_code" },
   { minWidth: 150, header: "Receiver's Name", key: "receiver_name" },
   { minWidth: 150, header: "Transporter Info", key: "transporter_info" },
   { minWidth: 150, header: "Quantity", key: "quantity" },
   { minWidth: 150, header: "EWB #", key: "eway_no" },
   { minWidth: 150, header: "EWB Expiry", key: "eway_expiry" },
   { minWidth: 150, header: "Eway Status", key: "eway_status" },
   { minWidth: 150, header: "Action", key: "action" },
]

export const subContractsHeaders = [
   { minWidth: 200, header: "Customer", key: "customer" },
   { minWidth: 150, header: "Party Reference", key: "party_ref_number" },
   { minWidth: 140, header: "Contract #", key: "contract" },
   { minWidth: 140, header: "Plant", key: "plant" },
   { minWidth: 150, header: "Sub Contract", key: "subcontract_number" },
   { minWidth: 150, header: "Payment Terms", key: "payment_term" },
   { minWidth: 140, header: "Sub Contract Qty", key: "subcontract_quantity", calculation: "subcontract_quantity", calc_text: "SUM QTY (UM)", dec_places: 3, },
   { minWidth: 150, header: "Dispatched Quantity", key: "subcontract_dispatch_quantity", calculation: "total_dispatched_qty", calc_text: "SUM QTY (UM)", dec_places: 3, },
   { minWidth: 150, header: "Balance Quantity", key: "subcontract_available_quantity", calculation: "total_balance_qty", calc_text: "SUM QTY (UM)", dec_places: 3, },
   { minWidth: 140, header: "Item Tag", key: "tag_name" },
   { minWidth: 140, header: "Balance", item_size_header: "subcontract_item_size_available_quantity", key: "subcontract_item_details", splitCol: true },
]

export const subContractsPdfHeaders = [
   // { minWidth: 150, header: "Customer Name", key: "customer" },
   { minWidth: 150, header: "Buyer Name", key: "buyer" },
   { minWidth: 150, header: "Consignee Name", key: "consignee" },
   // { minWidth: 150, header: "Contract #", key: "contract" },
   { minWidth: 150, header: "Sub Contract #", key: "subcontract_number" },
   { minWidth: 150, header: "Sub Contract Date", key: "subcontract_date" },
   { minWidth: 140, header: "Item Tag", key: "tag_name", totalText: true },
   { minWidth: 150, header: "Contract Quantity", key: "order_quantity", dec_places: 3 },
   { minWidth: 150, header: "Sub Contract Quantity", key: "subcontract_quantity", calculation: "total_subcontract_qty", calc_text: "SUM QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "ExRate", key: "EX_rate", calculation: "avg_ex_rate", calc_text: "AVG Ex Rate", dec_places: 2 },
   { minWidth: 150, header: "Freight Rate", key: "freight_rate", calculation: "avg_freight_rate", calc_text: "AVG Freight Rate", dec_places: 2 },
   { minWidth: 150, header: "Dispatch Quantity", key: "subcontract_dispatch_quantity", calculation: "total_dispatched_qty", calc_text: "SUM QTY (UM)", dec_places: 3, },
   { minWidth: 150, header: "Balance Sub Contract Quantity", item_size_header: "subcontract_item_size_available_quantity", key: "subcontract_item_details", calculation: "balance_subcontract_item_details", calc_text: "SUM QTY (UM)", dec_places: 3, splitCol: true },
   { minWidth: 150, header: "Balance Sub Contract", key: "subcontract_available_quantity", calculation: "total_balance_qty", calc_text: "SUM QTY (UM)", dec_places: 3 },
   { minWidth: 150, header: "Payment Terms", key: "payment_term" },
   { minWidth: 150, header: "Party Reference", key: "party_ref_number" },
   { minWidth: 150, header: "Status", key: "status" },
   { minWidth: 150, header: "Internal Remarks", key: "internal_remark" },
]

export const invoiceCsvHeaders = [
   { label: "Date", key: "invoice_date", },
   { label: "Invoice Number", key: "erp_invoice_number", },
   { label: "Plant Code", key: "plant_code" },
   { label: "Plant Name", key: "plant" },
   { label: "Contract Number", key: "contract_no", },
   { label: "Contract Date", key: "contract_date", },
   { label: "LA #", key: "la_number" },
   { label: "Customer Code", key: "customer_code" },
   { label: "Customer", key: "customer" },
   { label: "Buyer Code", key: "buyer_code" },
   { label: "Buyer", key: "buyer" },
   { label: "Broker Code", key: "broker_code" },
   { label: "Broker", key: "broker" },
   { label: "Customer Type", key: "customer_type" },
   { label: "Customer Type L1", key: "customer_l1" },
   { label: "Customer Type L2", key: "customer_l2" },
   { label: "Customer Tag", key: "customer_tag" },
   { label: "Brokerage", key: "brokerage_rate" },
   { label: "Payment Terms", key: "payment_method" },
   { label: "Payment Due Date", key: "payment_due_date" },
   { label: "Item Category Code", key: "item_category_code" },
   { label: "Item Category Name", key: "item_category" },
   { label: "Item Code", key: "item_code" },
   { label: "Item Name", key: "item" },
   { label: "Item Tag", key: "tag" },
   { label: "HSN/SAC", key: "hsn" },
   { label: "Dispatched Quantity", key: "item_dispatch_qty" },
   { label: "Cancelled Quantity", key: "item_cancel_qty" },
   { label: "Primary UM", key: "primary_unit" },
   { label: "Secondary UM", key: "secondary_unit" },
   // { label: "Quantity", key: "quantity", },
   { label: "Rate", key: "rate" },
   { label: "Loading Rate", key: "loading_rate" },
   { label: "Insurance rate", key: "insurance_rate" },
   { label: "Payment Basis Rate", key: "payment_basis" },
   { label: "Customer Discount Rate", key: "customer_discount" },
   { label: "Special Discount Rate", key: "special_discount" },
   { label: "Freight Rate", key: "freight_charge_rate" },
   { label: "Net Rate", key: "item_net_rate" },
   { label: "Material Amount", key: "item_material_amount" },
   { label: "No. of Packages", key: "actual_section_count" },
   { label: "Freight Advance", key: "fright_advance" },
   { label: "SGST Amount", key: "sgst_amount" },
   { label: "CGST Amount", key: "cgst_amount" },
   { label: "IGST Amount", key: "igst_amount" },
   { label: "TCS Rate", key: "tcs_rate" },
   { label: "TCS Amount", key: "item_tcs_amount" },
   { label: "CESS", key: "cess_rate" },
   // { //    label: "Amount", //    key: "amount" // }, 
   { label: "Net Amount", key: "item_total_amount" },
   { label: "Eway Bill Number", key: "eway_new_number", },
   { label: "Eway Bill Expiry Date Time", key: "eway_valid_upto" },
   { label: "Party Reference", key: "party_reference", },
   { label: "Party Reference Date", key: "party_ref_date", },
   { label: "IRN #", key: "irn", },
   { label: "IRN Date", key: "irn_date" },
   { label: "Buyer GST", key: "buyer_gst_number" },
   { label: "Seller GST", key: "seller_gst" },
   { label: "Created By", key: "user" },
   { label: "Created Date Time", key: "created_at" },
   // { //    label: "Approved By", //    key: "approved_by" // },
   // { //    label: "Approved Date Time", //    key: "approved_at" // }, 
   { label: "Modified By", key: "modified_by" },
   { label: "Modified Date Time", key: "modified_at" },
   { label: "Transaction Type", key: "tran_sup_type" },
   { label: "Item Nature", key: "item_nature" },
   { label: "Invoice Reason", key: "invoice_reason" },
   { label: "Customer State Code", key: "customer_state_code" },
   { label: "Customer State Name", key: "customer_state_name" },
   { label: "Buyer State Code", key: "buyer_state_code" },
   { label: "Buyer State Name", key: "buyer_state_name" },
   { label: "Consignee State Code", key: "consignee_state_code" },
   { label: "Consignee State Name", key: "consignee_state_name" },
   // { label: "Customer Code", key: "customer_codes" },
   // { label: "Bill To State", key: "bill_to_state_name" },
   // { label: "Bill To State Code", key: "bill_to_state_code" },
   // { label: "Month", key: "month" },
   // { label: "Broker Code", key: "broker_code" },
   // { label: "Trans Nature", key: "tran_sup_type" },
   // { //    label: "UM", //    key: "um" // },
   { label: "Month", key: "month" },
   { label: "Status", key: "dispatch_status" },
   // { //    label: "Item Class", //    key: "item_class" // },
   // { //    label: "Item Class Name", //    key: "item_class_name" // }, 
   { label: "WB #", key: "weighing_slip_number" },
   { label: "Godown", key: "godown" },
   { label: "Vehicle #", key: "truck_number" },
   { label: "Transporter Name", key: "transporter" },
   { label: "Transporter Code", key: "transporter_code" },
   { label: "Consignee Name", key: "consignee_name" },
   { label: "Consignee Code", key: "consignee_code" },
   { label: "Internal Remark", key: "remark" },
   { label: "DC Name", key: "DC_name" },
   { label: "Payment Status", key: "payment_status" }

];

export const contractsCsvHeaders = [
   { label: "Date", key: "created_at_date" },
   { label: "Contract Number", key: "order_number" },
   { label: "Customer", key: "customer" },
   { label: "Customer Code", key: "customer_code" },
   { label: "Buyer", key: "buyer" },
   { label: "Buyer Code", key: "buyer_code" },
   { label: "Consignee Code", key: "consignee_code" },
   { label: "Consignee Name", key: "consignee_name" },
   { label: "Broker", key: "broker" },
   { label: "Broker Code", key: "broker_code" },
   // { //    label: "Brokerage Rate", //    key: "new_brokerage" // }, 
   { label: "Customer Type", key: "customer_type" },
   { label: "Customer Type L1", key: "customer_type_l1" },
   { label: "Customer Type L2", key: "customer_type_l2" },
   { label: "Customer Tag", key: "customer_tag" },
   { label: "Brokerage", key: "new_brokerage" },
   { label: "Payment Terms", key: "payment_method" },
   { label: "Item Category Code", key: "item_category_code" },
   { label: "Item Category Name", key: "item_category" },
   { label: "Booked Quantity", key: "booked_quantity" },
   { label: "Contract Quantity", key: "quantity" },
   { label: "Primary UM", key: "primary_unit" },
   { label: "Secondary UM", key: "secondary_unit" },
   { label: "Plant Quantity (UM)", key: "allotted_qty" },
   { label: "Dispatched Quantity", key: "dispached_qty" },
   { label: "Cancelled Quantity", key: "cancelled_qty" },
   { label: "Balance Quantity", key: "balance_qty" },
   { label: "Days Running", key: "days_running" },
   { label: "Validity", key: "order_validity" },
   { label: "Status", key: "order_status" },
   { label: "Internal Remarks", key: "internal_remark" },
   { label: "Customer Remark", key: "customer_remark" },
   { label: "Party Reference", key: "party_ref_number" },
   { label: "Party Reference Date", key: "party_ref_date" },
   { label: "Platform(Portal/App)", key: "platform" },
   { label: "Created By", key: "created_by" },
   { label: "Created Date/Time", key: "created_at" },
   { label: "Approved By", key: "approved_by" },
   { label: "Approved Date/Time", key: "approved_at" },
   { label: "Modified By", key: "modified_by" },
   { label: "Modified Date/Time", key: "modified_at" },
   { label: "Customer State Code", key: "customer_state_code" },
   { label: "Customer State Name", key: "customer_state_name" },
   { label: "Broker State Code", key: "broker_state_code" },
   { label: "Broker State Name", key: "broker_state_name" },
   { label: "Consignee State Code", key: "consignee_state_code" },
   { label: "Consignee State Name", key: "consignee_state_name" },
   { label: "Rate", key: "rate" },
   { label: "Loading Rate", key: "loading_rate" },
   { label: "Insurance rate", key: "insurance_rate" },
   { label: "Payment Basis Rate", key: "payment_basis" },
   { label: "Payment Premium Rate", key: "payment_premium" },
   { label: "City Discount Rate", key: "city_discount" },
   { label: "Customer Discount Rate", key: "customer_discount" },
   { label: "Special Discount Rate", key: "special_discount" },
   { label: "Freight Rate", key: "freight_charge_percent" },
   { label: "Ex Rate", key: "Ex_rate" },
   { label: "Net Rate", key: "net_rate" },
   { label: "Material Amount", key: "material_amount" },
   { label: "SGST Amount", key: "sgst" },
   { label: "CGST Amount", key: "cgst" },
   { label: "IGST Amount", key: "igst" },
   // { //    label: "TCS Amount", //    key: "tcs_amount" // }, 
   { label: "Approximate Net Amount", key: "approx_net_amount" },
   { label: "Contract Reason", key: "reason" },
   { label: "Sales Person", key: "sales_person" },
   // { //    label: "Sales Person Name", //    key: "sales_person_name" // }, 
   { label: "Allow Breaking FIFO", key: "is_fifo_allowed" },
   { label: "Customer Approval Date/Time", key: "approved_at_client" },
   { label: "Plant Code", key: "plant_code" },
   { label: "Plant Name", key: "plant_name" },
   { label: "Plant Quantity", key: "allotted_qty" },
   { label: "Linked Contract", key: "is_linked_contract" },
   { label: "Linked Contract #", key: "linked_contract_numbers" },
   { label: "SubContract Enabled", key: "sub_contract_enabled" },
]

export let loadingAdvicesCSVHeaders = [
   { label: "Date", key: "created_at_date" },
   { label: "Plant Code", key: "plant_code" },
   { label: "Plant Name", key: "plant" },
   { label: "LA Number", key: "la_number" },
   { label: "Status", key: "dispatch_status" },
   { label: "Vehicle No", key: "truck_number" },
   { label: "Contract No", key: "contract_number" },
   { label: "Contract Date", key: "contract_date" },
   { label: "Customer", key: "customer" },
   { label: "Customer Code", key: "customer_code" },
   { label: "Customer Type", key: "customer_type" },
   { label: "Buyer Code", key: "buyer_code" },
   { label: "Buyer", key: "buyer" },
   { label: "Broker", key: "broker" },
   { label: "Broker Code", key: "broker_code" },
   { label: "Brokerage", key: "brokerage_rate" },
   { label: "Payment Terms", key: "payment_method" },
   { label: "Contract Payment Term", key: "contract_payment_term" },
   { label: "Payment Due Date", key: "payment_date" },
   { label: "Item Catergory Code", key: "item_category_code" },
   { label: "Item Catergory Name", key: "item_category" },
   { label: "LA Quantity", key: "item_qty" },
   { label: "Dispatched Quantity", key: "item_dispatch_qty" },
   { label: "Cancelled Quantity", key: "item_cancel_qty" },
   // { //    label: "Balance Quantity", //    key: "balance_quantity" // }, 
   { label: "Primary UM", key: "primary_unit" },
   { label: "Secondary UM", key: "secondary_unit" },
   { label: "Rate", key: "rate" },
   { label: "Loading Rate", key: "loading_rate" },
   { label: "Insurance Rate", key: "insurance_rate" },
   { label: "Payment Basis Rate", key: "payment_basis" },
   { label: "Payment Premium Rate", key: "payment_premium" },
   { label: "Item Rate Difference", key: "rate_difference" },
   { label: "City Discount Rate", key: "city_discount" },
   { label: "Customer Discount Rate", key: "customer_discount" },
   { label: "Special Discount Rate", key: "special_discount" },
   { label: "Freight Rate", key: "freight_charge_rate" },
   { label: "Net Rate", key: "item_net_rate" },
   { label: "Material Amount", key: "item_material_amount" },
   { label: "Freight Advance", key: "fright_advance" },
   { label: "SGST Amount", key: "sgst_amount" },
   { label: "CGST Amount", key: "cgst_amount" },
   { label: "IGST Amount", key: "igst_amount" },
   { label: "TCS Rate", key: "tcs_rate" },
   { label: "TCS Amount", key: "item_tcs_amount" },
   { label: "CESS", key: "cess_rate" },
   { label: "Net Material Amount", key: "item_total_amount" },
   { label: "No. of Packages", key: "actual_section_count" },
   { label: "Consignee Code", key: "consignee_code" },
   { label: "Consignee Name", key: "consignee" },
   { label: "Internal Remarks", key: "remark" },
   // { //    label: "Customer Remark", //    key: "customer_remark" // },
   // { //    label: "Party Reference", //    key: "party_reference" // }, 
   // { //    label: "Portal/App", //    key: "created_from" // }, 
   // { //    label: "User Type", //    key: "user_type" // }, 
   { label: "Created By", key: "created_by" },
   { label: "Created Date/Time", key: "created_at" },
   { label: "Approved By", key: "approved_by" },
   { label: "Approved Date/Time", key: "approved_at" },
   { label: "Modified By", key: "modified_by" },
   { label: "Modified Date/Time", key: "modified_at" },
   // { //    label: "LA Number", //    key: "la_number" // }, 
   { label: "Item Code", key: "item_code" },
   { label: "Item Name", key: "item" },
   { label: "Item Tag", key: "tag" },
   { label: "HSN/SAC", key: "hsn" },
   { label: "Gate Out Reason", key: "gate_out_reason" },
   { label: "LA Reason", key: "la_reason" },
   { label: "WB #", key: "weighbridge_number" },
   { label: "WB Final Name", key: "weighbridge_final_name" },
   { label: "WB Final Date/Time", key: "weighbridge_final_time" },
   { label: "Gate in Name", key: "gatein_name" },
   { label: "Gate in Date/Time", key: "gate_in_date" },
   { label: "Gate Out Name", key: "gateout_name" },
   { label: "Gate Out Date/Time", key: "gate_out_date" },
   { label: "LR #", key: "lr_number" },
   { label: "LR Date", key: "lr_date" },
   { label: "Transporter Code", key: "transporter_code" },
   { label: "Transporter Name", key: "transporter" },
   { label: "Dispatch Time", key: "dispatch_time" },
   { label: "Payment Status", key: "payment_status" }
   // { //    label: "CESS", //    key: "cess" // },
   // { //    label: "CESS", //    key: "cess" // },

]


export let WBGCSVHeaders = [
   { label: "Date", key: "created_at" },
   { label: "Plant Code", key: "plant_code" },
   { label: "Plant Name", key: "plant_name" },
   { label: "LA #", key: "la_number" },
   { label: "WB #", key: "weighbridge" },
   { label: "Vehicle #", key: "truck_number" },
   { label: "Customer Name", key: "customer_name" },
   { label: "Customer Code", key: "customer_code" },
   { label: "Buyer Name", key: "buyer_name" },
   { label: "Buyer Code", key: "buyer_code" },
   { label: "Consignee Name", key: "consignee_name" },
   { label: "Consignee Code", key: "consignee_code" },
   { label: "Transporter Name", key: "transporter" },
   { label: "Transporter Code", key: "transporter_code" },
   { label: "Item Category Name", key: "item_category" },
   { label: "Item Category Code", key: "item_category_code" },
   { label: "Gate-In Name", key: "gate_in_gate" },
   { label: "Gate-In Date Time", key: "actual_gate_in_time" },
   { label: "Tare Weight", key: "tare_weight" },
   { label: "Tare Weight Date/Time", key: "tare_weight_date_time" },
   { label: "Gross Weight (MTS)", key: "gross_weight" },
   { label: "Net Weight", key: "net_weight" },
   { label: "Net Weight Date/Time", key: "net_weight_date_time" },
   { label: "Gate-Out Name", key: "gate_out_gate" },
   { label: "Gate-Out Date Time", key: "gate_out_date" },
   // { //    label: "Net Weight Date Time", //    key: "" // }, 
   { label: "Total Trip #", key: "total_trip" },
   { label: "Status", key: "dispatch_status" },
   { label: "Gate Out Reason", key: "gate_out_reason" },
]


export let EXRCSVHeaders = [
   { label: "Module Name", key: "module_name" },
   { label: "Name & ID", key: "customer" },
   { label: "Field Description", key: "field_name" },
   { label: "Previous Value", key: "old_value" },
   { label: "New Value", key: "new_value" },
   { label: "Type", key: "type" },
   { label: "Remark", key: "remark" },
   { label: "Modified By", key: "created_by" },
   { label: "Modified At", key: "created_at_date" },
   { label: "Approved By", key: "approved_by" },
   { label: "Approved On", key: "approved_on" },
]


export let DPTCSVHeaders = [
   { label: "Plant Code", key: "plant_code" },
   { label: "Invoice Date", key: "invoice_date" },
   { label: "Invoice Number", key: "erp_invoice_number" },
   { label: "Supplier GSTIN", key: "supplier_gstin" },
   { label: "Receiver GSTIN", key: "receiver_gstin" },
   { label: "Receiver Name", key: "receiver_name" },
   { label: "Receiver Pincode", key: "receiver_pincode" },
   { label: "Transport Distance", key: "transporter_distance" },
   { label: "Transporter Name", key: "transporter" },
   { label: "Vehicle Number", key: "truck_number" },
   { label: "Eway Expiry", key: "eway_expiry" },
   { label: "Eway Status", key: "eway_status" },
   { label: "Eway Number", key: "eway_number" },
   { label: "Eway Created At", key: "eway_created_at" },
   { label: "Eway Remark", key: "eway_remark" },
   { label: "Eway Action By", key: "eway_action_by" },
   { label: "Eway Action Date / Time", key: "eway_action_date" },
   { label: "Dispatched Quantity", key: "dispatched_quantity" },
   { label: "HSN Code", key: "hsn_code" },
   { label: "IRN", key: "irn" },
   { label: "IRN Date", key: "irn_date" },
   { label: "Days Running", key: "days_running" },

]

export const subContractsCsvHeaders = [
   { label: "Date", key: "created_at_date" },
   { label: "Sub Contract Date", key: "subcontract_date" },
   { label: "Contract Number", key: "order_number" },
   { label: "Sub Contract #", key: "subcontract_number" },
   { label: "Customer", key: "customer" },
   { label: "Customer Code", key: "customer_code" },
   { label: "Buyer", key: "buyer" },
   { label: "Buyer Code", key: "buyer_code" },
   { label: "Consignee Name", key: "consignee" },
   { label: "Consignee Code", key: "consignee_code" },
   { label: "Broker", key: "broker" },
   { label: "Broker Code", key: "broker_code" },
   { label: "Customer Type", key: "customer_type" },
   { label: "Customer Type L1", key: "customer_type_l1" },
   { label: "Customer Type L2", key: "customer_type_l2" },
   { label: "Customer Tag", key: "customer_tag" },
   { label: "Brokerage", key: "new_brokerage" },
   { label: "Payment Terms", key: "payment_term" },
   { label: "Payment Doc #", key: "subcontract_payment_detail.payment_doc_number" },
   { label: "Payment Doc Date", key: "subcontract_payment_detail.payment_doc_date" },
   { label: "Payment Bank Name", key: "subcontract_payment_detail.payment_bank_name" },
   { label: "Payment Doc Value", key: "subcontract_payment_detail.payment_doc_value" },
   { label: "Payment Doc Remark", key: "subcontract_payment_detail.payment_doc_remark" },
   { label: "Payment Doc Valid Upto", key: "subcontract_payment_detail.payment_doc_valid_upto" },
   { label: "Item Category Code", key: "item_category_code" },
   { label: "Item Category Name", key: "item_category" },
   { label: "Item Tag", key: "tag_name" },
   { label: "Contract Quantity", key: "booked_quantity" },
   { label: "Sub Contract Quantity", key: "subcontract_quantity", dec_places: 3 },
   { label: "Dispatch Quantity", key: "subcontract_dispatch_quantity", dec_places: 3, },
   { label: "Balance SubContract Quantity", key: "subcontract_item_details", item_size_header: "subcontract_item_size_available_quantity", dec_places: 3, splitCol: true },
   { label: "Balance Sub Contract", key: "subcontract_available_quantity", dec_places: 3 },
   { label: "Primary UM", key: "primary_unit" },
   { label: "Secondary UM", key: "secondary_unit" },
   // { label: "Plant Quantity (UM)", key: "allotted_qty" },
   // { label: "Dispatched Quantity", key: "dispached_qty" },
   // { label: "Cancelled Quantity", key: "cancelled_qty" },
   // { label: "Balance Quantity", key: "balance_qty" },
   { label: "Days Running", key: "days_running" },
   { label: "Validity", key: "order_validity" },
   { label: "Contract Status", key: "order_status" },
   { label: "SubContract Status", key: "status" },
   { label: "Internal Remarks", key: "internal_remark" },
   { label: "Customer Remark", key: "customer_remark" },
   { label: "Party Reference", key: "party_ref_number" },
   { label: "Party Reference Date", key: "party_ref_date" },
   { label: "Platform(Portal/App)", key: "platform" },
   { label: "Created By", key: "created_by" },
   { label: "Created Date/Time", key: "created_at" },
   // { label: "Approved By", key: "approved_by" },
   // { label: "Approved Date/Time", key: "approved_at" },
   { label: "Modified By", key: "modified_by" },
   { label: "Modified Date/Time", key: "modified_at" },
   { label: "Customer State Code", key: "customer_state_code" },
   { label: "Customer State Name", key: "customer_state_name" },
   { label: "Broker State Code", key: "broker_state_code" },
   { label: "Broker State Name", key: "broker_state_name" },
   { label: "Consignee State Code", key: "consignee_state_code" },
   { label: "Consignee State Name", key: "consignee_state_name" },
   { label: "Rate", key: "rate" },
   { label: "Loading Rate", key: "loading_rate" },
   { label: "Insurance rate", key: "insurance_rate" },
   { label: "Payment Basis Rate", key: "payment_basis" },
   { label: "Payment Premium Rate", key: "payment_premium" },
   { label: "City Discount Rate", key: "city_discount" },
   { label: "Customer Discount Rate", key: "customer_discount" },
   { label: "Special Discount Rate", key: "special_discount" },
   { label: "SubContract Freight Rate", key: "freight_rate", },
   { label: "SubContract Ex Rate", key: "Ex_rate" },
   { label: "Net Rate", key: "net_rate" },
   { label: "Material Amount", key: "material_amount" },
   { label: "SGST Amount", key: "sgst" },
   { label: "CGST Amount", key: "cgst" },
   { label: "IGST Amount", key: "igst" },
   { label: "Approximate Net Amount", key: "approx_net_amount" },
   { label: "Contract Reason", key: "reason" },
   { label: "Sales Person", key: "sales_person" },
   { label: "Allow Breaking FIFO", key: "is_fifo_allowed" },
   { label: "Customer Approval Date/Time", key: "approved_at_client" },
   { label: "Plant Code", key: "plant_code" },
   { label: "Plant Name", key: "plant" },
   { label: "Linked Contract", key: "is_linked_contract" },
   { label: "Linked Contract #", key: "linked_contract_numbers" },
]

export let plantTableHeaders = (tabValue, type, dataFor) => {
   // console.log(tabValue)
   if (tabValue === 0)
      return invoiceHeaders
   else if (tabValue === 1)
      return contractsHeaders
   else if (tabValue === 2)
      return LAHeaders
   else if (tabValue === 3)
      return WBGHeaders
   else if (tabValue === 4)
      return EXRHeaders
   else if (tabValue === 5)
      return subContractsHeaders
}