import React, { Component } from 'react';
import ProductsTab from './ProductsTab';
import ProductStatePrice from './ProductStatePrice';
import Paper from '@material-ui/core/Paper';
import APIROUTES from '../../../../constants/internal/InternalApiRoutes';
import axios from 'axios';
import SnackbarComponent from '../../common/SnackbarComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  openRatesMainDiv: { display: 'flex', flexDirection: 'column', width: '100%' },
  productsTabDiv: {
    display: 'flex',
  },
  productStatesPriceDiv: { width: '100%' },
};
const userInputValueLimit = 1000000000;
class OpenRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRatesData: null,
      selectedCategoryId: null,
      isPublished: false,
      publishDate: null,
      productPricesDisplayData: null,
      productPricesDisplayDataLoader: true,
      selectedCategoryIdBaseState: null,
      selectedCategoryIdBaseStateLoader: true,
      selectedCategoryIdStatesWithoutBaseState: null,
      linkedCategoryBaseRate: 0,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: '',
      publishLoader: false,
      snackbarAutoHideDuration: 5000,
    };
  }
  componentDidMount() {
    this.initializeData(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.initializeData(nextProps);
  }
  initializeData(whichProps) {
    if (whichProps.openRatesData && whichProps.openRatesData.length > 0) {
      this.setState({
        openRatesData: whichProps.openRatesData,
        publishLoader: false,
      });
      const productPricesDisplayData = [];
      if (whichProps.openRatesData.length > 0) {
        whichProps.openRatesData.forEach((productPrices, index) => {
          let productBaseRateData =
            productPrices.data.length > 0
              ? productPrices.data.find(
                (productAllData) => productAllData.is_base_rate === true
              )
              : null;
          if (!productBaseRateData) {
            const singleProductData = {
              id: productPrices.id,
              category_name: productPrices.category_name,
              isPublished: productPrices.isPublished,
              date: null,
              baseHighRate: null,
              baseLowRate: null,
              linkedTo: productPrices.linkedTo,
              linkedToRateDifference: productPrices.linkedToRateDifference,
            };
            productPricesDisplayData.push(singleProductData);
          } else {
            const singleProductData = {
              id: productPrices.id,
              category_name: productPrices.category_name,
              isPublished: productPrices.isPublished,
              date: productBaseRateData.created_at,
              baseHighRate: productBaseRateData.high_rate,
              baseLowRate: productBaseRateData.low_rate,
              linkedTo: productPrices.linkedTo,
              linkedToRateDifference: productPrices.linkedToRateDifference,
            };
            productPricesDisplayData.push(singleProductData);
          }
        });
        this.setState({
          productPricesDisplayData,
          productPricesDisplayDataLoader: false,
        });
      }
      if (!whichProps.userSelectedCustomerId) {
        this.handleCategorySelect(whichProps.openRatesData[0].id);
      } else {
        this.handleCategorySelect(whichProps.userSelectedCustomerId);
      }
    }
  }
  handleCategorySelect = async (categoryId) => {
    let selectedCategoryId = categoryId;
    await this.setState({
      selectedCategoryId,
    });
    let selectedCategoryIdPrices =
      this.state.openRatesData && this.state.openRatesData.length > 0
        ? this.state.openRatesData.find(
          (dailyRatesProducts) => dailyRatesProducts.id === selectedCategoryId
        )
        : null;
    if (selectedCategoryIdPrices) {
      let selectedCategoryIdBaseState =
        selectedCategoryIdPrices.data.length > 0
          ? selectedCategoryIdPrices.data.find(
            (selectedCategoryIdPrices) =>
              selectedCategoryIdPrices.is_base_rate === true
          )
          : [];
      let selectedCategoryIdStatesWithoutBaseState =
        selectedCategoryIdPrices.data.length > 0
          ? selectedCategoryIdPrices.data.filter(
            (selectedCategoryIdPrices) =>
              selectedCategoryIdPrices.is_base_rate === false
          )
          : [];
      if (selectedCategoryIdBaseState) {
        let isPublished = !selectedCategoryIdPrices.isPublished
          ? false
          : selectedCategoryIdPrices.isPublished;
        let publishDate = !selectedCategoryIdBaseState.created_at
          ? null
          : selectedCategoryIdBaseState.created_at;
        this.setState({
          selectedCategoryIdBaseRate: Number(
            selectedCategoryIdBaseState.high_rate
          ),
          isPublished,
          publishDate,
        });
      }
      await this.setState({
        selectedCategoryIdBaseState,
        selectedCategoryIdBaseStateLoader: false,
        selectedCategoryIdStatesWithoutBaseState,
      });
    }
  };
  handleBaseRateChange = (selectedCategoryId, name, state, isBaseState) => (
    event
  ) => {
    let baseRateValue = !event.target.value
      ? 0
      : event.target.value < 0
        ? 0
        : event.target.value;
    let newPriceValues = this.state.openRatesData;
    this.state.openRatesData.forEach((dailyRatesPrices, allProductIndex) => {
      if (dailyRatesPrices.id === selectedCategoryId) {
        dailyRatesPrices.data.forEach(
          (selectedCategoryIdPrices, selectedCategoryIdPricesIndex) => {
            if (selectedCategoryIdPrices.state.id === state) {
              newPriceValues[allProductIndex].data[
                selectedCategoryIdPricesIndex
              ]['high_rate'] = baseRateValue;
              this.HandleNonBaseStateValues(
                baseRateValue,
                selectedCategoryId,
                state
              );
              this.handleLinkedCategoryBaseRateUpdate(
                selectedCategoryId,
                baseRateValue
              );

              if (isBaseState) {
                newPriceValues[allProductIndex].data[
                  selectedCategoryIdPricesIndex
                ]['low_rate'] = parseFloat(
                  Number(
                    newPriceValues[allProductIndex].data[
                    selectedCategoryIdPricesIndex
                    ]['high_rate'] || 0
                  ) -
                  Number(
                    newPriceValues[allProductIndex].data[
                    selectedCategoryIdPricesIndex
                    ]['low_rate_difference'] ||
                    newPriceValues[allProductIndex].data[
                    selectedCategoryIdPricesIndex
                    ]['low_rate_template_difference']
                  )
                ).toFixed(2);
              }

              this.setState({
                openRatesData: newPriceValues,
              });
              this.handleCategorySelect(selectedCategoryId);
            }
          }
        );
      }
    });
  };
  HandleNonBaseStateValues = (currentBaseRate, selectedCategoryId, state) => {
    let newPriceValues = this.state.openRatesData;
    this.state.openRatesData.forEach((dailyRatesPrices, allProductIndex) => {
      if (dailyRatesPrices.id === selectedCategoryId) {
        dailyRatesPrices.data.forEach(
          (selectedCategoryIdPrices, selectedCategoryIdPricesIndex) => {
            if (selectedCategoryIdPrices.state.id !== state) {
              newPriceValues[allProductIndex].data[
                selectedCategoryIdPricesIndex
              ]['high_rate'] =
                Number(currentBaseRate) +
                Number(
                  newPriceValues[allProductIndex].data[
                  selectedCategoryIdPricesIndex
                  ]['high_rate_difference']
                );
              newPriceValues[allProductIndex].data[
                selectedCategoryIdPricesIndex
              ]['low_rate'] =
                Number(
                  newPriceValues[allProductIndex].data[
                  selectedCategoryIdPricesIndex
                  ]['high_rate']
                ) +
                Number(
                  newPriceValues[allProductIndex].data[
                  selectedCategoryIdPricesIndex
                  ]['low_rate_difference']
                );
              this.setState({
                openRatesData: newPriceValues,
              });
              this.handleCategorySelect(selectedCategoryId);
            }
          }
        );
      }
    });
  };
  handleProductPriceChange = (
    calledfrom,
    selectedCategoryId,
    name,
    state,
    isBaseState
  ) => (event) => {
    let newPriceValues = this.state.openRatesData;
    let inputValue =
      calledfrom === 'onChange'
        ? event.target.value
        : !event.target.value
          ? 0
          : event.target.value;
    let lowRateDifferenceValue =
      name === 'low_rate_difference'
        ? event.target.value >= 0
          ? event.target.value
          : 0
        : event.target.value;
    if (inputValue < userInputValueLimit && inputValue > -userInputValueLimit) {
      this.state.openRatesData.forEach((dailyRatesPrices, allProductIndex) => {
        if (dailyRatesPrices.id === selectedCategoryId) {
          dailyRatesPrices.data.forEach(
            (selectedCategoryIdPrices, selectedCategoryIdPricesIndex) => {
              if (selectedCategoryIdPrices.state.id === state) {
                newPriceValues[allProductIndex].data[
                  selectedCategoryIdPricesIndex
                ][name] =
                  name === 'low_rate_difference'
                    ? lowRateDifferenceValue
                    : inputValue;
                if (isBaseState) {
                  newPriceValues[allProductIndex].data[
                    selectedCategoryIdPricesIndex
                  ]['low_rate'] = parseFloat(
                    Number(
                      newPriceValues[allProductIndex].data[
                      selectedCategoryIdPricesIndex
                      ]['high_rate'] || 0
                    ) -
                    Number(
                      newPriceValues[allProductIndex].data[
                      selectedCategoryIdPricesIndex
                      ]['low_rate_difference'] ||
                      newPriceValues[allProductIndex].data[
                      selectedCategoryIdPricesIndex
                      ]['low_rate_template_difference']
                    )
                  ).toFixed(2);
                } else {
                  newPriceValues[allProductIndex].data[
                    selectedCategoryIdPricesIndex
                  ]['high_rate'] = parseFloat(
                    Number(this.state.selectedCategoryIdBaseRate) +
                    Number(
                      newPriceValues[allProductIndex].data[
                      selectedCategoryIdPricesIndex
                      ]['high_rate_difference']
                    )
                  ).toFixed(2);
                  newPriceValues[allProductIndex].data[
                    selectedCategoryIdPricesIndex
                  ]['low_rate'] = parseFloat(
                    Number(
                      newPriceValues[allProductIndex].data[
                      selectedCategoryIdPricesIndex
                      ]['high_rate']
                    ) +
                    Number(
                      newPriceValues[allProductIndex].data[
                      selectedCategoryIdPricesIndex
                      ]['low_rate_difference']
                    )
                  ).toFixed(2);
                }
                this.setState({
                  openRatesData: newPriceValues,
                });
                this.handleCategorySelect(selectedCategoryId);
              }
            }
          );
        }
      });
    } else {
      this.setState({
        publishLoader: false,
        openSnackbar: true,
        snackbarMessage: 'Entered value excceds allowed value range',
        snackbarType: 'warning',
        snackbarAutoHideDuration: 1000,
      });
    }
  };
  handleLinkedCategoryBaseRateUpdate = (parentCategoryId, currentBaseRate) => {
    let newOpenRateDataValues = this.state.openRatesData;
    this.state.openRatesData.map((openRateData, openRateDataIndex) => {
      if (openRateData.related_to === parentCategoryId) {
        let calculatedBaseRateValue =
          Number(currentBaseRate) + Number(openRateData.rate_difference);
        let childBaseRate =
          calculatedBaseRateValue < 0 ? 0 : calculatedBaseRateValue;
        openRateData.data.map((childStateData, childStateDataIndex) => {
          if (openRateData.isPublished === false) {
            if (childStateData.is_base_rate === true) {
              newOpenRateDataValues[openRateDataIndex].data[
                childStateDataIndex
              ].high_rate = Number(parseFloat(childBaseRate).toFixed(2));

              newOpenRateDataValues[openRateDataIndex].data[
                childStateDataIndex
              ].low_rate =
                Number(
                  newOpenRateDataValues[openRateDataIndex].data[
                    childStateDataIndex
                  ].high_rate
                ) +
                Number(
                  newOpenRateDataValues[openRateDataIndex].data[
                    childStateDataIndex
                  ].low_rate_difference
                );
            } else {
              newOpenRateDataValues[openRateDataIndex].data[
                childStateDataIndex
              ].high_rate =
                Number(childBaseRate) +
                Number(
                  newOpenRateDataValues[openRateDataIndex].data[
                    childStateDataIndex
                  ].high_rate_difference
                    ? newOpenRateDataValues[openRateDataIndex].data[
                      childStateDataIndex
                    ].high_rate_difference
                    : newOpenRateDataValues[openRateDataIndex].data[
                      childStateDataIndex
                    ].high_rate_template_difference
                );

              newOpenRateDataValues[openRateDataIndex].data[
                childStateDataIndex
              ].low_rate =
                Number(
                  newOpenRateDataValues[openRateDataIndex].data[
                    childStateDataIndex
                  ].high_rate
                ) +
                Number(
                  newOpenRateDataValues[openRateDataIndex].data[
                    childStateDataIndex
                  ].low_rate_difference
                    ? newOpenRateDataValues[openRateDataIndex].data[
                      childStateDataIndex
                    ].low_rate_difference
                    : newOpenRateDataValues[openRateDataIndex].data[
                      childStateDataIndex
                    ].low_rate_template_difference
                );
            }
          }
          return 1;
        });
      }
      return this.setState({
        openRatesData: newOpenRateDataValues,
      });
    });
  };
  handlePublishRates = async (selectedCategoryId) => {
    this.setState({ publishLoader: true });
    let selectedDailyRateData = this.state.openRatesData.find(
      (dailyRatesPublish) => dailyRatesPublish.id === selectedCategoryId
    );
    let publishData = [];
    let dataReadyToPublish = true;
    selectedDailyRateData.data.map((dailyRateData, dailyRateDataIndex) => {
      let singlePublishData = {
        high_rate: Number(dailyRateData.high_rate),
        low_rate: Number(dailyRateData.low_rate),
        high_rate_template_difference:
          Number(dailyRateData.high_rate_template_difference),
        low_rate_template_difference:
          Number(dailyRateData.low_rate_template_difference),
        high_rate_difference: Number(dailyRateData.high_rate_difference),
        low_rate_difference: Number(dailyRateData.low_rate_difference),
        status: 'ACTIVE',
        is_base_rate: dailyRateData.is_base_rate,
        daily_rates_template: dailyRateData.id,
        item_category: selectedDailyRateData.id,
        state: dailyRateData.state.id,
      };
      if (dailyRateData.low_rate < 0 || dailyRateData.high_rate < 0) {
        dataReadyToPublish = false;
      }
      return publishData.push(singlePublishData);
    });
    if (dataReadyToPublish === true) {
      await this.props.handlePublishDailyRates(publishData, selectedCategoryId);
      await this.setState({
        selectedCategoryIdBaseStateLoader: true,
      });
    } else {
      await this.setState({
        publishLoader: false,
        openSnackbar: true,
        snackbarMessage:
          'Negative values in OPEN RATE or NET RATE cannot be published try again',
        snackbarType: 'error',
      });
    }
  };
  handleResetRates = (selectedCategoryId) => {
    let publishedData =
      this.props.originalDailyRatesOpenRateValues &&
        this.props.originalDailyRatesOpenRateValues.length > 0
        ? this.props.originalDailyRatesOpenRateValues.filter(
          (publishData) => publishData.daily_rates.length > 0
        )
        : [];
    let publishDataBaseRates = [];
    if (publishedData.length > 0) {
      publishedData.map((data) => {
        let singlePublishedData = {
          id: data.id,
          categoryName: data.category_name,
          baseRateData: data.daily_rates.find(
            (publishedDailyRateData) =>
              publishedDailyRateData.is_base_rate === true
          ),
        };
        return publishDataBaseRates.push(singlePublishedData);
      });
    }
    let backupValues = JSON.parse(
      localStorage.getItem('originalTemlateValuesBackup')
    );
    const backupValuesCopy = [];
    backupValues.map((backupData) => {
      let singleBackupData = backupData;
      let parentCategoryBaseRate = 0;
      if (
        backupData.related_to &&
        backupData.related_to !== undefined &&
        backupData.related_to !== null
      ) {
        let parentCategoryData =
          publishDataBaseRates.length > 0
            ? publishDataBaseRates.find(
              (parentData) => parentData.id === Number(backupData.related_to)
            )
            : null;
        if (parentCategoryData && parentCategoryData !== null) {
          parentCategoryBaseRate = parentCategoryData.baseRateData.high_rate;
        }
      }

      backupData.daily_rates_template.forEach(
        (templateData, templateDataIndex) => {
          singleBackupData.daily_rates_template[
            templateDataIndex
          ].high_rate = !parentCategoryBaseRate
              ? 0
              : Number(parentCategoryBaseRate) +
              Number(backupData.rate_difference);
          singleBackupData.daily_rates_template[templateDataIndex].low_rate =
            Number(
              singleBackupData.daily_rates_template[templateDataIndex].high_rate
            ) + Number(templateData.low_rate_difference);
          singleBackupData.daily_rates_template[
            templateDataIndex
          ].high_rate_template_difference = templateData.high_rate_difference;
          singleBackupData.daily_rates_template[
            templateDataIndex
          ].low_rate_template_difference = templateData.low_rate_difference;
        }
      );
      return backupValuesCopy.push(singleBackupData);
    });

    if (!backupValues) {
      axios
        .get(APIROUTES.DAILY_RATE_TEMPLATE_DETAILS, {
          headers: {
            Authorization: `JWT ${this.props.user.token}`,
          },
        })
        .then(
          (response) => {
            if (response.status === 201) {
              let originalTemplateValues = response.data;

              const originalTemplateValuesCopy = [];
              originalTemplateValues.map((backupData) => {
                let parentCategoryBaseRate = 0;
                if (
                  backupData.related_to &&
                  backupData.related_to !== undefined &&
                  backupData.related_to !== null
                ) {
                  let parentCategoryData =
                    publishDataBaseRates.length > 0
                      ? publishDataBaseRates.find(
                        (parentData) =>
                          parentData.id === Number(backupData.related_to)
                      )
                      : null;
                  if (parentCategoryData && parentCategoryData !== null) {
                    parentCategoryBaseRate =
                      parentCategoryData.baseRateData.high_rate;
                  }
                }

                let singleBackupData = backupData;
                backupData.daily_rates_template.forEach(
                  (templateData, templateDataIndex) => {
                    singleBackupData.high_rate_template[
                      templateDataIndex
                    ].high_rate = !parentCategoryBaseRate
                        ? 0
                        : Number(parentCategoryBaseRate) +
                        Number(backupData.rate_difference);
                    singleBackupData.high_rate_template[
                      templateDataIndex
                    ].low_rate =
                      Number(
                        singleBackupData.high_rate_template[templateDataIndex]
                          .high_rate
                      ) + Number(templateData.low_rate_difference);

                    singleBackupData.daily_rates_template[
                      templateDataIndex
                    ].high_rate_template_difference =
                      templateData.high_rate_difference;
                    singleBackupData.daily_rates_template[
                      templateDataIndex
                    ].low_rate_template_difference =
                      templateData.low_rate_difference;
                  }
                );
                return originalTemplateValuesCopy.push(singleBackupData);
              });

              let originalDailyRatesPrices = originalTemplateValues.find(
                (originalPrices) => originalPrices.id === selectedCategoryId
              );
              let newDailyRatesPrices = this.state.openRatesData;
              this.state.openRatesData.forEach((dailyRatesPrices, index) => {
                if (dailyRatesPrices.id === selectedCategoryId) {
                  newDailyRatesPrices[index].data =
                    originalDailyRatesPrices.daily_rates_template;
                  this.setState({ openRatesData: newDailyRatesPrices });
                }
              });
              this.handleCategorySelect(selectedCategoryId);
            } else {
              this.setState({
                openSnackbar: true,
                snackbarMessage:
                  'Error occured while fetching data plase try again',
                snackbarType: 'error',
              });
            }
          },
          (error) => {
            this.setState({
              openSnackbar: true,
              snackbarMessage:
                'Error occured while fetching data plase try again',
              snackbarType: 'error',
            });
          }
        );
    } else {
      let originalDailyRatesPrices = backupValuesCopy.find(
        (originalPrices) => originalPrices.id === selectedCategoryId
      );
      let newDailyRatesPrices = this.state.openRatesData;
      this.state.openRatesData.forEach((dailyRatesPrices, index) => {
        if (dailyRatesPrices.id === selectedCategoryId) {
          newDailyRatesPrices[index].data =
            originalDailyRatesPrices.daily_rates_template;
          this.setState({ openRatesData: newDailyRatesPrices });
        }
      });
      this.handleCategorySelect(selectedCategoryId);
    }
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: '',
    });
  };

  handleUnpublishDailyRates = async (selectedCategoryId) => {
    this.setState({ publishLoader: true });
    let selectedDailyRateData = this.state.openRatesData.find(
      (dailyRatesPublish) => dailyRatesPublish.id === selectedCategoryId
    );
    let unPublishData = [];
    selectedDailyRateData.daily_rates.map(
      (dailyRateData, dailyRateDataIndex) => {
        let singleUnpublishData = {
          id: dailyRateData.id,
          status: 'PUBLISHED',
        };
        return unPublishData.push(singleUnpublishData);
      }
    );
    await this.props.handleUnpublishDailyRates(
      unPublishData,
      selectedCategoryId
    );
    await this.setState({
      selectedCategoryIdBaseStateLoader: true,
    });
  };

  render() {
    const {
      selectedCategoryIdBaseState,
      selectedCategoryIdStatesWithoutBaseState,
      selectedCategoryId,
      isPublished,
      publishDate,
      productPricesDisplayData,
      openSnackbar,
      snackbarMessage,
      snackbarType,
      publishLoader,
      openRatesLoader,
      productPricesDisplayDataLoader,
      selectedCategoryIdBaseStateLoader,
      snackbarAutoHideDuration,
    } = this.state;

    return (
      <Paper style={styles.openRatesMainDiv}>
        {openRatesLoader ? (
          <div style={styles.loaderDiv}>
            <CircularProgress disableShrink style={styles.loaderStyle} />
          </div>
        ) : (
          <>
            <div style={styles.productsTabDiv}>
              <ProductsTab
                {...this.props}
                productPricesDisplayData={productPricesDisplayData}
                handleCategorySelect={this.handleCategorySelect}
                productPricesDisplayDataLoader={productPricesDisplayDataLoader}
              />
            </div>
            <div style={styles.productStatesPriceDiv}>
              <ProductStatePrice
                {...this.props}
                selectedCategoryIdBaseState={selectedCategoryIdBaseState}
                selectedCategoryIdStatesWithoutBaseState={
                  selectedCategoryIdStatesWithoutBaseState
                }
                isPublished={isPublished}
                selectedCategoryId={selectedCategoryId}
                handlePublishRates={this.handlePublishRates}
                handleResetRates={this.handleResetRates}
                publishDate={publishDate}
                handleProductPriceChange={this.handleProductPriceChange}
                handleBaseRateChange={this.handleBaseRateChange}
                publishLoader={publishLoader}
                selectedCategoryIdBaseStateLoader={
                  selectedCategoryIdBaseStateLoader
                }
                handleUnpublishDailyRates={this.handleUnpublishDailyRates}
              />
            </div>
            <SnackbarComponent
              open={openSnackbar}
              handleClose={this.handleSnackbarClose}
              snackbarMessage={snackbarMessage}
              type={snackbarType}
              customAutoHideDuration={snackbarAutoHideDuration}
            />
          </>
        )}
      </Paper>
    );
  }
}
export default OpenRates;
