import React, { Component } from "react";
import { withRouter } from "react-router";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import COLORS, { successButtonBackground } from "../../../constants/internal/colors";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Check from "@material-ui/icons/Check";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InvoicingAndEwayCard from "./InvoicingAndEwayCard";
import GstDetails from "./GstDetails";
import CircularProgressLoader from '../../common/CircularProgressLoader'
import ItemTable from "./ItemTable";
import copy from "copy-to-clipboard";
import Print from "@material-ui/icons/Print";
import { debounce } from "lodash";
import { handlePrintInvoicingpreview } from "./InvoicingPdf";
import { InputAdornment, TextField } from "@material-ui/core";
import InvoiceAndPaymentDetailsCard from "./InvoiceAndPaymentDetailsCard";




const styles = {
  breadcrumbsStyle: {
    color: COLORS.secondaryBlueText,
    cursor: "pointer",
    margin: "10px 0px 20px 10px",
  },
  infoMainContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  infoLeftContainer: {
    width: "46%",
  },
  infoRightContainer: {
    width: "46%",
  },
  infoTitleHeadingTextContainer: {
    padding: "4px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItem: "center",
    marginBottom: "6px",
  },
  infoTitleTextContainer: {
    padding: "10px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItem: "center",
  },
  infoHeadingStyle: {
    color: COLORS.secondaryBlueText,
    fontWeight: "bold",
  },
  infoTitleTextStyle: {
    color: COLORS.grey,
    fontWeight: "bold",
    paddingRight: "100px",
  },
  nextButtonContainer: {
    margin: "26px 10px",
    display: "flex",
    // float: "right",
    // right: 0,
    justifyContent: "space-between",
    width: "45%"
  },
};
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
class Invoicing extends Component {
  state = {
    docType: "",
    isPrevStateAlloted: false,
    isLoading: false,
    stockAvailable: true
    // eWayBill: [{ name: "Yes" }, { name: "No" }],
    // invoiceMethod: [{ name: "Normal Invoice" }, { name: "Combined Invoice" }],
    // invoiceType: "",
    // iseWaySelect: false,
  };
  componentDidMount() {
    const { laDetailsInDispatchCounter, match, handleActualDistance } = this.props;
    handleActualDistance('')
    this.props.handleResetData();
    this.props.handleCheckStockBeforeBilling(laDetailsInDispatchCounter?.la_number);
    this.setState({
      isPrevStateAlloted:
        laDetailsInDispatchCounter?.dispatch_status === "ALLOTED",
      dcId: this.props.location.state?.dcId,
    });
    if (
      match.url === "/dispatchOperation/dc/2/details/invoices" &&
      laDetailsInDispatchCounter?.dispatch_status !== "ALLOTED"
    ) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        this.window.history.pushState(
          null,
          document.title,
          window.location.href
        );
      });
    }

    // if (
    //   match.url === "/dispatchOperation/dc/2/details/invoices" &&
    //   laDetailsInDispatchCounter?.dispatch_status !== "ALLOTED"
    // ) {
    //   console.log("here called");
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener("popstate", function (event) {
    //     this.window.history.go(-2);
    //   });
    // }

    if (laDetailsInDispatchCounter === null) {
      this.props.history.push("/dispatchOperation");
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  // handleSelectEnvoiceType = (type) => {
  //   this.setState({ invoiceType: type });
  // };
  // handleSelectEway = (type) => {
  //   this.setState({ iseWaySelect: type === "Yes" ? true : false });
  // };
  handleGenerate = debounce((e) => {
    const { laDetailsInDispatchCounter, iseWaySelect, invoiceType } =
      this.props;
    this.setState({ isLoading: true });
    if (invoiceType === "Combined Invoice") {
      this.props.handleGenerateEinvoiceAndEway(
        laDetailsInDispatchCounter?.la_number,
        iseWaySelect
      );
    } else if (invoiceType === "Normal Invoice" && iseWaySelect) {
      this.props.handleGenerateEWAY(laDetailsInDispatchCounter?.la_number);
      this.props.handleCreateB2cInvoiceQr();
    } else if (invoiceType === "Normal Invoice" && !iseWaySelect) {
      this.props.handleCreateB2cInvoiceQr();
      this.props.handleLoadingAdviceStatusChange(laDetailsInDispatchCounter?.la_number);
    } else {
      this.props.history.push("invoices/download/" + laDetailsInDispatchCounter?.la_number);
    }
  }, 1000);

  isStockAvailable = (netWeight, totalStock) => {
    this.setState({
      stockAvailable: totalStock >= netWeight
    });
    return totalStock >= netWeight;
  }



  handlePrintInvoicingPreviewBtn = () => {
    handlePrintInvoicingpreview(this.props?.laDetailsInDispatchCounter?.la_number, "PRINT", this.props.user?.full_name)
  }

  render() {
    // const { invoiceType } = this.state;
    const {
      classes,
      laDetailsInDispatchCounter,
      invoiceType,
      id,
      eWayOptionSelected,
      actualDistance,
      handleActualDistance
    } = this.props;

    return (
      <div style={{ width: "95%" }}>
        <Paper
          elevation={1}
          style={{ padding: "12px 18px 50px", marginBottom: "18px" }}
        >
          <div style={{ float: "right" }}>
            <Button onClick={this.handlePrintInvoicingPreviewBtn}>
              <Print style={{ marginRight: "3px" }}

              />
            </Button>
          </div>
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            className={classes.breadcrumbsStyle}
          >
            <Link
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/dispatchOperation");
              }}
            >
              Dispatch Operations
            </Link>
            <Link
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                // if (!this.state.isPrevStateAlloted) {
                //   console.log("no here");
                //   this.props.history.push("/dispatchOperation");
                // } else {
                //   console.log("yes here");
                //   this.props.history.goBack();
                // }
                console.log(this.state.dcId);
                this.props.history.push(
                  `/dispatchOperation/dc/${this.state.dcId}`
                );
              }}
            >
              Dispatch Counter
            </Link>
            <Link color="inherit">Invoicing</Link>
          </Breadcrumbs>
          <div style={{ margin: "26px 0px" }}>
            {/* <FormControl
              variant="outlined"
              size="small"
              style={{ width: "20%" }}
            >
              <InputLabel
                id="outlined-age-simple"
                ref={(ref) => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-age-simple"
              >
                Doc Type
              </InputLabel>
              <Select
                label="DocType"
                id="outlined-age-simple"
                value={this.state.docType}
                onChange={this.handleChange}
                MenuProps={MenuProps}
                inputProps={{
                  name: "docType",
                  id: "outlined-age-simple",
                }}
              >
                <MenuItem value={10}>INV</MenuItem>
                <MenuItem value={20}>CRN</MenuItem>
                <MenuItem value={30}>DBN</MenuItem>
              </Select>
            </FormControl> */}
          </div>
          <div>
            <div className={classes.infoMainContainer}>
              <div className={classes.infoLeftContainer}>
                <div className={classes.infoTitleHeadingTextContainer}>
                  <span className={classes.infoHeadingStyle}>
                    SUPPLIER DETAILS
                  </span>
                  <CheckCircle />
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Legal Name</span>
                  <span>{laDetailsInDispatchCounter?.plant.plant_name}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Address</span>
                  <span>{laDetailsInDispatchCounter?.plant.plant_address}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>City</span>
                  <span>{laDetailsInDispatchCounter?.plant.plant_city}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Pincode</span>
                  <span>{laDetailsInDispatchCounter?.plant?.plant_pincode}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>State</span>
                  <span>{laDetailsInDispatchCounter?.plant?.state_name}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>GSTIN</span>
                  <span>
                    {laDetailsInDispatchCounter?.plant.plant_gst_number === null ? "N/A" : laDetailsInDispatchCounter?.plant.plant_gst_number}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>PAN</span>
                  <span>
                    {
                      laDetailsInDispatchCounter?.plant.plant_pan_number ?
                        laDetailsInDispatchCounter?.plant.plant_pan_number :
                        laDetailsInDispatchCounter?.plant.plant_gst_number ?
                          laDetailsInDispatchCounter?.plant.plant_gst_number.slice(2, 12)
                          : ""
                    }
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Contact</span>
                  <span>{laDetailsInDispatchCounter?.plant.plant_mobile}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Email</span>
                  <span>{laDetailsInDispatchCounter?.plant.plant_email}</span>
                </div>
              </div>

              <div className={classes.infoRightContainer}>
                <div className={classes.infoTitleHeadingTextContainer}>
                  <span className={classes.infoHeadingStyle}>BUYER DETAILS</span>
                  <CheckCircle />
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Legal Name</span>
                  <span>
                    {laDetailsInDispatchCounter?.buyer?.first_name +
                      " " +
                      laDetailsInDispatchCounter?.buyer?.last_name}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Address</span>
                  <span>
                    {laDetailsInDispatchCounter?.buyer?.customer_addresses
                      ?.address ||
                      laDetailsInDispatchCounter?.buyer?.gst_addresses
                        ?.door_number +
                      " " +
                      laDetailsInDispatchCounter?.buyer?.gst_addresses
                        ?.floor_number +
                      " " +
                      laDetailsInDispatchCounter?.buyer?.gst_addresses
                        ?.building_name +
                      " " +
                      laDetailsInDispatchCounter?.buyer?.gst_addresses?.street +
                      " " +
                      laDetailsInDispatchCounter?.buyer?.gst_addresses
                        ?.location}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>City</span>
                  <span>
                    {laDetailsInDispatchCounter?.buyer?.customer_addresses
                      ?.city ||
                      laDetailsInDispatchCounter?.buyer?.gst_addresses
                        ?.district_name}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Pincode</span>
                  <span>
                    {laDetailsInDispatchCounter?.buyer?.customer_addresses
                      ?.pincode ||
                      laDetailsInDispatchCounter?.buyer?.gst_addresses?.pincode}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>State</span>
                  <span>
                    {laDetailsInDispatchCounter?.buyer?.customer_addresses
                      ?.state ||
                      laDetailsInDispatchCounter?.buyer?.gst_addresses
                        ?.state_name}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>GSTIN</span>
                  <span>{laDetailsInDispatchCounter?.buyer?.gstin_number === null ? "N/A" : laDetailsInDispatchCounter?.buyer?.gstin_number}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>PAN</span>
                  <span>
                    {
                      laDetailsInDispatchCounter?.buyer?.pan_card_number ?
                        laDetailsInDispatchCounter?.buyer?.pan_card_number :
                        laDetailsInDispatchCounter?.buyer?.gstin_number ?
                          laDetailsInDispatchCounter?.buyer?.gstin_number.slice(2, 12)
                          : ""
                    }
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Contact</span>
                  <span>
                    {" "}
                    {laDetailsInDispatchCounter?.buyer?.customer_addresses
                      ?.contact ||
                      laDetailsInDispatchCounter?.buyer?.gst_addresses?.contact}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Email</span>
                  <span>
                    {" "}
                    {laDetailsInDispatchCounter?.buyer?.customer_addresses
                      ?.email ||
                      laDetailsInDispatchCounter?.buyer?.gst_addresses?.email}
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.infoMainContainer}>
              <div className={classes.infoLeftContainer}>
                <div className={classes.infoTitleHeadingTextContainer}>
                  <span className={classes.infoHeadingStyle}>
                    TRANSPORTER DETAILS
                  </span>
                  <CheckCircle />
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Legal Name</span>
                  <span>{laDetailsInDispatchCounter?.transporter.acc_name}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Address</span>
                  <span>
                    {laDetailsInDispatchCounter?.transporter?.addr1 ||
                      laDetailsInDispatchCounter?.transporter?.addr2}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>City</span>
                  <span>{laDetailsInDispatchCounter?.transporter.city_name}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Pincode</span>
                  <span>{laDetailsInDispatchCounter?.transporter.pin}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>State</span>
                  <span>
                    {laDetailsInDispatchCounter?.transporter.state_name}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>PAN NO.</span>
                  <span>{
                    laDetailsInDispatchCounter?.transporter.pan_no ?
                      laDetailsInDispatchCounter?.transporter.pan_no :
                      laDetailsInDispatchCounter?.transporter.gst_number ?
                        laDetailsInDispatchCounter?.transporter?.gst_number.slice(2, 12)
                        : ""
                  }</span>
                </div>
                {/* <div className={classes.infoTitleTextContainer}>
                <span className={classes.infoTitleTextStyle}>PAN</span>
                <span>ADR56555GGG</span>
              </div> */}
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Contact</span>
                  <span>{laDetailsInDispatchCounter?.transporter.mobile}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Email</span>
                  <span>{laDetailsInDispatchCounter?.transporter.email}</span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span
                    className={classes.infoTitleTextStyle}
                    style={{ paddingRight: 70 }}
                  >
                    Actual Distance
                  </span>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <TextField
                      type="number"
                      className={classes.formControl}
                      style={{
                        width: '60%'
                      }}
                      variant="outlined"
                      size="small"
                      value={actualDistance}
                      onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                      onWheel={(e) => e.target.blur()}
                      name="actual_distance"
                      onChange={(e) => handleActualDistance(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ fontSize: '0.7em', transform: 'scale(0.9)' }}
                          >
                            KM
                          </InputAdornment>
                        ),
                        style: {
                          height: '30px',
                          fontSize: '1em',
                          paddingRight: 6
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className={classes.infoRightContainer}>
                <div className={classes.infoTitleHeadingTextContainer}>
                  <span className={classes.infoHeadingStyle}>
                    CONSIGNEE DETAILS
                  </span>
                  <CheckCircle />
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Legal Name</span>
                  <span>
                    {laDetailsInDispatchCounter?.consignee?.first_name +
                      " " +
                      laDetailsInDispatchCounter?.consignee?.last_name}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Address</span>
                  <span>
                    {laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_customer_addresses?.address ||
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.door_number +
                      " " +
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses
                        ?.floor_number +
                      " " +
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses
                        ?.building_name +
                      " " +
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.street +
                      " " +
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.location}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>City</span>
                  <span>
                    {" "}
                    {laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_customer_addresses?.city ||
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.district_name}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Pincode</span>
                  <span>
                    {laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_customer_addresses?.pincode ||
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.pincode}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>State</span>
                  <span>
                    {laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_customer_addresses?.state ||
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.state_name}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>GSTIN</span>
                  <span>
                    {laDetailsInDispatchCounter?.consignee?.gstin_number === null ? "N/A" : laDetailsInDispatchCounter?.consignee?.gstin_number}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>PAN</span>
                  <span>
                    {
                      laDetailsInDispatchCounter?.consignee?.pan_card_number ?
                        laDetailsInDispatchCounter?.consignee?.pan_card_number :
                        laDetailsInDispatchCounter?.consignee?.gstin_number ?
                          laDetailsInDispatchCounter?.consignee?.gstin_number.slice(2, 12)
                          : ""
                    }
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Contact</span>
                  <span>
                    {" "}
                    {laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_customer_addresses?.contact ||
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.contact}
                  </span>
                </div>
                <div className={classes.infoTitleTextContainer}>
                  <span className={classes.infoTitleTextStyle}>Email</span>
                  <span>
                    {laDetailsInDispatchCounter?.consignee
                      ?.consignee_from_customer_addresses?.email ||
                      laDetailsInDispatchCounter?.consignee
                        ?.consignee_from_gst_information_addresses?.email}
                  </span>
                </div>
              </div>
            </div>
            {laDetailsInDispatchCounter !== null ? (
              <div style={{ marginTop: "10%" }}>
                <div style={{ margin: "20px 0px" }}>
                  <ItemTable {...this.props} />
                  <GstDetails {...this.props} />
                </div>
                {/* <ItemDetails {...this.props} /> */}
              </div>
            ) : null}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <InvoiceAndPaymentDetailsCard
              {...this.props}
              {...this.state}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "40px" }}>
            {

              <Button
                disabled={this.props.isFetchingVRNumber || laDetailsInDispatchCounter?.erp_invoice_number !== null
                  || !(this.props.availableStock >= this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight)
                  || (this.props.stockAndPayment?.payment?.non_advance_credit_limit < 0
                    // || this.props.stockAndPayment?.payment?.advance_credit_limit - this.props.laDetailsInDispatchCounter?.total_values?.total_item_value < 0)
                    || this.props.stockAndPayment?.payment?.advance_credit_limit < 0)
                  || this.props.stockAndPayment?.payment?.virtual_credit_limit < 0
                  || this.props.stockAndPayment?.stock?.error
                  || this.props.stockAndPayment?.payment?.error
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.persist()
                  this.props.handleFetchVRNumberFromERP(
                    laDetailsInDispatchCounter?.la_number
                  );
                }}
                variant="contained"
                size="small"
                color="primary"
                style={{
                  backgroundColor:
                    laDetailsInDispatchCounter?.erp_invoice_number !== null
                      || !(this.props.availableStock >= this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight)
                      || (this.props.stockAndPayment?.payment?.non_advance_credit_limit < 0
                        // || this.props.stockAndPayment?.payment?.advance_credit_limit - this.props.laDetailsInDispatchCounter?.total_values?.total_item_value < 0)
                        || this.props.stockAndPayment?.payment?.advance_credit_limit < 0)
                      || this.props.stockAndPayment?.payment?.virtual_credit_limit < 0
                      || this.props.stockAndPayment?.stock?.error
                      || this.props.stockAndPayment?.payment?.error
                      ? COLORS.grey
                      : COLORS.primaryColor,
                  width: "200px"
                }}

              >{
                  this.props.isFetchingVRNumber ?
                    <CircularProgress
                      size={25}
                      style={{ color: "#FFFFFF" }}
                    />
                    :
                    laDetailsInDispatchCounter?.erp_invoice_number !== null ?
                      "Invoice Number Obtained" : "Obtain Invoice Number"
                }
              </Button>
              // </div>
            }
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>

            <InvoicingAndEwayCard
              {...this.props}
              {...this.state}
            //handleSelectEnvoiceType={this.handleSelectEnvoiceType}
            //handleSelectEway={this.handleSelectEway}
            />
          </div>
          {this.props.errorWhilePostForInvoice &&
            this.props.errorWhilePostForInvoice ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "18px",
              }}
            >
              <img
                alt="img"
                src="/contentCopy.svg"
                height="36px"
                width="36px"
                style={{ cursor: "pointer", marginRight: "16px" }}
                onClick={(e) => {
                  e.preventDefault();
                  copy(
                    this.props.einvoiveErrorMessage &&
                    this.props.einvoiveErrorMessage
                  );
                }}
              />
              <span style={{ color: "red", marginRight: "5px" }}>
                {this.props.einvoiveErrorMessage &&
                  this.props.einvoiveErrorMessage}
              </span>
            </div>
          ) : null}
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", paddingTop: "40px" }}>
            <Button
              style={{ marginRight: "30px" }}
              onClick={(e) => this.props.history.push("/dispatchOperation")}
            >
              Cancel
            </Button>

            <Button
              disabled={
                this.state.isLoading ||
                laDetailsInDispatchCounter?.erp_invoice_number === null ||
                invoiceType === "" || !eWayOptionSelected
                || (this.props.stockAndPayment?.payment?.non_advance_credit_limit < 0
                  || this.props.stockAndPayment?.payment?.advance_credit_limit < 0)
                || this.props.stockAndPayment?.payment?.virtual_credit_limit < 0
                || this.props.stockAndPayment?.stock?.error
                || this.props.stockAndPayment?.payment?.error
              }
              variant="contained"
              color="primary"
              style={{
                backgroundColor:
                  laDetailsInDispatchCounter?.erp_invoice_number === null ||
                    invoiceType === "" || !eWayOptionSelected ||
                    (this.props.stockAndPayment?.payment?.non_advance_credit_limit < 0
                      || this.props.stockAndPayment?.payment?.advance_credit_limit < 0)
                    || this.props.stockAndPayment?.payment?.virtual_credit_limit < 0
                    || this.props.stockAndPayment?.stock?.error
                    || this.props.stockAndPayment?.payment?.error ||
                    (this.state.isLoading &&
                      !(
                        this.props.errorWhilePostForInvoice &&
                        this.props.errorWhilePostForInvoice
                      ))
                    ? COLORS.grey
                    : COLORS.primaryColor,
              }}
              onClick={(e) => {
                e.preventDefault()
                e.persist()
                this.handleGenerate(e);
              }}
            >
              <Check />
              Next
              {this.state.isLoading &&
                !(
                  this.props.errorWhilePostForInvoice &&
                  this.props.errorWhilePostForInvoice
                ) && (
                  <CircularProgress
                    size={15}
                    style={{ marginLeft: "10px", color: COLORS.primaryColor }}
                  />
                )}
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              height: 5,
              position: 'relative',
              bottom: 60
            }}
          >
            <div className={classes.nextButtonContainer} style={{ marginRight: 0 }}>
              <div style={{ marginRight: "30px", width: '60px', }}></div>
              <div style={{ width: '200px', marginRight: "30px" }}>
                <p style={{
                  display: this.props?.isFetchingVRNumber && laDetailsInDispatchCounter?.loading_advice_items?.length > 1
                    ? "flex"
                    : 'none',
                  fontSize: "0.8em",
                  color: successButtonBackground,
                }}>
                  Please wait it will take sometime
                </p>
              </div>
              <div style={{ width: '100px', }}>
                <p style={{
                  display: laDetailsInDispatchCounter?.loading_advice_items?.length > 1 && (this.state.isLoading && !(this.props.errorWhilePostForInvoice && this.props.errorWhilePostForInvoice))
                    ? "flex"
                    : 'none',
                  fontSize: "0.75em",
                  color: successButtonBackground,
                  paddingBottom: 10,
                  marginTop: 45,
                  marginLeft: 10
                }}>
                  Please wait it will take sometime
                </p>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Invoicing));
