import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import Print from "@material-ui/icons/Print";
import { CSVLink } from "react-csv";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { saveCSVInMobile } from "../../../methods";
import ToolTipComponent from "./ToolTipComponent";

const PdfCsvButton = ({
  handlePrintClick,
  csvData,
  csvHeader,
  webCSVFilename,
  mobileCSVFilename,
  handleOpenSnackClick,
  onClickApiCall = false,
  pdfLoader = false,
  csvLoader = false,
  refCSVLink = '',
  handleCSVClick = ''
}) => {

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
    }}>
      {!onClickApiCall
        ? <>
          <ToolTipComponent
            title="No Data Found"
            condition={csvData?.length <= 0}
            title2="Print"
            condition2={csvData?.length > 0}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 5,
              }}
            // onClick={() => handlePrintClick()}
            >
              <IconButton
                style={{ display: 'flex' }}
                disabled={csvData?.length <= 0}
                size="small"
                onClick={() => handlePrintClick()}
              >
                <Print style={{ color: csvData?.length <= 0 ? "rgba(0,0,0,0.4)" : "#4b87c5", cursor: "pointer" }} />
              </IconButton>

            </span>
          </ToolTipComponent>
          <ToolTipComponent
            title="No Data Found"
            condition={csvData?.length <= 0}
            title2="Download csv"
            condition2={csvData?.length > 0}
          >
            <div style={{
              margin: '0 10px',
            }}>
              <IconButton
                style={{ display: 'flex' }}
                size="small"
                disabled={csvData?.length <= 0}
              >
                <CSVLink
                  data={csvData || []}
                  headers={csvHeader}
                  onClick={(event, done) => {
                    if (csvData) {
                      window.cordova && saveCSVInMobile(
                        csvData || [],
                        csvHeader,
                        mobileCSVFilename,
                        handleOpenSnackClick,
                        ''
                      )
                    }
                  }}
                  filename={webCSVFilename}
                >
                  <CloudDownloadOutlinedIcon
                    style={{ color: csvData?.length <= 0 ? "rgba(0,0,0,0.4)" : "#4b87c5" }}
                  />
                </CSVLink>
              </IconButton>
            </div>
          </ToolTipComponent>
        </>
        : <>
          <Tooltip title="Print" placement="top">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 5,
              }}
            >
              <IconButton
                style={{ display: 'flex' }}
                disabled={(pdfLoader || csvLoader)}
                size="small"
                onClick={() => handlePrintClick()}
              >
                <Print style={{ color: (pdfLoader || csvLoader) ? "rgba(0,0,0,0.4)" : "#4b87c5", cursor: "pointer" }} />
              </IconButton>
              {
                pdfLoader &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: 'scale(0.5)'
                  }}
                >
                  <CircularProgress />
                </div>
              }
            </span>
          </Tooltip>
          <Tooltip title="Download CSV" placement="top">
            <div style={{ paddingBottom: 5 }}>
              <IconButton
                style={{ display: 'flex' }}
                disabled={(pdfLoader || csvLoader)}
                onClick={() => {
                  if (!(pdfLoader || csvLoader))
                    handleCSVClick()
                }}
              >
                <CloudDownloadOutlinedIcon style={{ color: (pdfLoader || csvLoader) ? "rgba(0,0,0,0.4)" : "#4b87c5" }} />
                {
                  csvLoader &&
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: 'scale(0.5)'
                    }}
                  >
                    <CircularProgress />
                  </div>
                }
              </IconButton>
              <CSVLink
                data={csvData || []}
                headers={csvHeader}
                filename={webCSVFilename}
                className="hidden"
                ref={refCSVLink}
                target="_blank"
              />
            </div>
          </Tooltip>
        </>
      }
    </div>
  )

}

export default PdfCsvButton;