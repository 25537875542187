import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import Invoicing from "../Invoicing";

class DispatchCounterInvoicing extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <Invoicing
          {...this.props}
          actualDistance={this.props?.actualDistance}
          handleActualDistance={this.props?.handleActualDistance}
        />
      </Layout>
    );
  }
}
export default withRouter(DispatchCounterInvoicing);
