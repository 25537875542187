import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  lightPrimaryColor,
  dailyRateScreenTextColor,
  grey,
} from "../../../styles/colors";
// import TextField from '@mui/material/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import { isValidDriverLicense, checkNameFieldAlphaNumeric, options, pxToEm } from "../../../methods"
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from "@material-ui/core/Button";
import Downshift from "downshift";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
// import {
//   secondaryGrey,
//   secondaryBlueText,
//   primaryColor,
//   drawerTextColor,
//   earthBlue,
//   drawerBackground,
//   successButtonBackground,
//   lightGreyColor,
//   bottomNavigationFontColor,
//   whiteSmoke,
// } from "../../../constants/internal/colors"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {
  Typography,
  TextField,
  MenuItem,
  Divider,
  Paper,
  FormControl,
  Select,
  // InputAdornment,
  InputLabel,
  IconButton,
  // Button,
  ListSubheader,
  Grow,
  Tooltip,
  FormHelperText,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import B2b_URL from "../../../constants/b2b/B2bRoutes";

const styles = {
  createDispatchLogisticContainer: {
    width: "100%",
    height: window.innerHeight - 126,
    maxHeight: window.innerHeight - 126,
    padding: "0px 20px 56px",
    overflowY: "scroll",
  },
  logisticTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.19em",
    fontWeight: 500,
  },
  logisticDetailsTextContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  },
  textFieldStyle: {
    paddingLeft: "3px",
    width: "98%",
    marginBottom: "0px",
  },
  truckDetailsContainer: {
    marginBottom: "25px",
  },
  headingTextStyle: {
    color: lightPrimaryColor,
    fontSize: window.innerWidth < 375 && pxToEm(14)
  },
  addMoreButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    border: "1px dashed",
    width: "100%",
    height: "7%",
  },
};

const truckNumberValidationCase_1 = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/g;
const truckNumberValidationCase_2 = /^[A-Z]{2}[0-9]{1}[A-Z]{3}[0-9]{4}$/g;
const truckNumberValidationCase_3 = /^[A-Z]{3}[0-9]{4}$/g;
const truckNumberValidationCase_4 = /^[A-Z]{2}[0-9]{6}$/g;


const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null

}
const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
]
const filter = createFilterOptions();
class CreateDispatchLogisticDetail extends Component {
  constructor(props) {
    super(props);
    this.anchorEl = React.createRef(null);
    this.state = {
      truckNumberValidationError: false,
      driverLicenseNumberError: false,
      driverNameError: false,
      transporterNameError: false,
      showAddTransporterButton: false,
      showTransporterDropdown: true,
      openTransportPopper: false,
      transportListLoader: false,
      searchTransportText: null
    };
  }


  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  componentDidUpdate(prevProp, nextState) {
    if (this.props?.laState?.mode === 'edit' && this.state?.searchTransportText === null) {
      console.log(this.props?.laState, this.props?.transporterName)
      this.setState({ searchTransportText: this.props?.transporterName })
    }
  }

  checkError = (e, name) => {
    // console.log(e.target.value, name)
    const { value } = e.target
    if (name === "driverContactNumber") {
      // console.log(value)
      if (value.length < 10) {
        this.setState({ driverContactNumberLengthError: true });
      }
      else {
        this.setState({ driverContactNumberLengthError: false });
      }
    }
    if (name === "driverName") {
      if (!checkNameFieldAlphaNumeric(value)) {
        this.setState({ driverNameError: true });
      }
      else {
        this.setState({ driverNameError: false });
      }
    }

    if (name === "transporterName") {
      // console.log(value)
      if (!checkNameFieldAlphaNumeric(value)) {
        this.setState({ transporterNameError: true });
      }
      else {
        this.setState({ transporterNameError: false });
      }
    }

    if (name === "driverLicenseNumber") {
      if (value.length < 15 || !isValidDriverLicense(value)) {
        this.setState({ driverLicenseNumberError: true });
      }
      else {
        this.setState({ driverLicenseNumberError: false });
      }
    }
    if (name === "transporterContactNumber") {
      if (value.length < 10) {
        this.setState({ transporterContactNumberLengthError: true });
      }
      else {
        this.setState({ transporterContactNumberLengthError: false });
      }
    }
    if (name === "truckNumber") {
      if (
        truckNumberValidationCase_1.test(value) ||
        truckNumberValidationCase_2.test(value) ||
        truckNumberValidationCase_3.test(value) ||
        truckNumberValidationCase_4.test(value)
      ) {
        this.setState({ truckNumberValidationError: false });
      } else {
        this.setState({ truckNumberValidationError: true });
      }
    }
  }

  handlefiltering = (e) => {
    const { value } = e.target
    // console.log(value)
    let filt = []
    top100Films.forEach(item => {
      if (item.label.toLowerCase().includes(value.toLowerCase())) {
        filt.push(item)
      }
    })
    // console.log(filt)

    this.setState({
      showAddTransporterButton: filt.length > 0 ? false : true
    })
  }

  handleAddMoreButtonClick = () => {
    this.setState({
      // showAddTransporterButton: false,
      showTransporterDropdown: !this.state.showTransporterDropdown
    }, () => this.props.handleShowTransporterTextFields(this.state.showTransporterDropdown))
  }

  handleSearchTransport = (searchText) => {
    // console.log(searchText)

    const baseUrl = B2b_URL.GET_TRANSPORTERS;
    const params = searchText !== '' && `?search=${searchText}`
    const filteredLink = searchText !== '' ? baseUrl + params : baseUrl
    this.setState({ transportListLoader: true })
    axios
      .get(filteredLink, this.getOptions(this.props.user))
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({
            tranportersList: response.data.results,
            transportListLoader: false,
            openTransportPopper: response.data.results.length > 0 ? true : false,
            showAddTransporterButton: response.data.results.length > 0 ? false : true
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching transporters data",
            snackbarType: "error",
            openTransportPopper: false,
            showAddTransporterButton: true
          });
        }
      })
      .catch((error) => {
        this.setState({
          transportListLoader: false,
          openTransportPopper: false,
          showAddTransporterButton: true
        })
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  }

  handleSelectTransporter = (data) => {
    // console.log(data)
    if (data !== 'Not Available') {
      this.setState({
        openTransportPopper: false,
        searchTransportText: data.acc_name
      }, () => this.props.handleShowTransporterTextFields(data, "transporterSelect"))
    }
    else if (data === 'Not Available') {
      this.setState({
        openTransportPopper: false,
        searchTransportText: 'Not Available'
      }, () => this.props.handleShowTransporterTextFields(data, "transporterSelect"))
    }
  }

  render() {
    const {
      showAddTransporterButton,
      showTransporterDropdown,
      transportListLoader,
      tranportersList,
      openTransportPopper,
      searchTransportText
    } = this.state;
    const {
      classes,
      truckNumber,
      truckCapacity,
      transporterName,
      transporterContactNumber,
      driverName,
      driverContactNumber,
      handleTextChange,
      driverLicenseNumber,
      transporterContactNumberLengthError,
      driverContactNumberLengthError,
      truckNumberValidationError,
      driverLicenseNumberError,
      driverNameError,
      transporterNameError,
      isTransporterAvailable,
      truckCapacityError
    } = this.props;

    // console.log(isTransporterAvailable)
    return (
      <div className={classes.createDispatchLogisticContainer}>
        <div className={classes.logisticDetailsTextContainer}>
          <span className={classes.logisticTextStyle}>Logistic Details</span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            The date at which your truck will be reach at the point
          </span>
        </div>
        <div className={classes.truckDetailsContainer}>
          <span style={styles.headingTextStyle}>Truck Details*</span>
          <TextField
            name="truckNumber"
            id="outlined-name"
            label="Truck No."
            // placeholder="CG16CG1989"
            className={classes.textFieldStyle}
            value={truckNumber.toUpperCase()}
            onChange={(e) => {
              this.props.handleTextChange(e)
              this.props.checkError(e, "truckNumber")
            }}
            margin="normal"
            variant="outlined"
            // onFocus={() => {
            //   this.setState({ truckNumberValidationError: false });
            // }}
            // onBlur={() => {
            //   if (
            //     truckNumberValidationCase_1.test(this.props.truckNumber) ||
            //     truckNumberValidationCase_2.test(this.props.truckNumber) ||
            //     truckNumberValidationCase_3.test(this.props.truckNumber)
            //   ) {
            //     this.setState({ truckNumberValidationError: false });
            //   } else {
            //     this.setState({ truckNumberValidationError: true });
            //   }
            // }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(14)
              }
            }}
            error={truckNumberValidationError}
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px" },
            }}
            helperText={
              <span style={{ color: "red" }}>
                {truckNumberValidationError
                  ? "Please enter valid Vehicle Number"
                  : null}
              </span>
            }
          />
          <TextField
            name="truckCapacity"
            id="outlined-name"
            label="Truck Capacity."
            className={classes.textFieldStyle}
            value={truckCapacity}
            type="number"
            onKeyDown={(evt) => (
              ["e", "E", "+", "-"].includes(evt.key) ||
              (evt.which === 38 || evt.which === 40)
            ) && evt.preventDefault()}
            margin="normal"
            variant="outlined"
            onKeyPress={(e) => e.target.value.match(/^[0]{1,}$/) || !e.target.value.match(/^\d*\.?\d{0,2}$/) && e.preventDefault()}
            onChange={(e) => {
              if (!e.target.value.match(/^[0]{1,}$/) && e.target.value.match(/^\d*\.?\d{0,3}$/)) {
                this.props.handleTextChange(e);
              }
              else { e.preventDefault() }
            }
            }
            // onChange={(e) => {
            //   const value = e.target.value.replace(/[^0-9]/g, "");
            //   this.props.handleTextChange({
            //     target: { name: "truckCapacity", value },
            //   });
            // }}
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(14)
              }
            }}
            InputProps={{
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(14)
              },
              endAdornment: <InputAdornment position="end">MT</InputAdornment>,
            }}
            error={truckCapacityError}
            helperText={truckCapacityError ? truckCapacityError : null}
          />
        </div>
        <div className={classes.truckDetailsContainer}>
          <span style={styles.headingTextStyle}>Transport Details*</span>

          {showTransporterDropdown &&
            <Downshift id="downshift-popper">
              {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
                <div className={classes.textFieldStyle} style={{ marginTop: "20px", fontSize: window.innerWidth < 375 && pxToEm(14) }}>
                  <TextField
                    label="SEARCH TRANSPORTER *"
                    variant="outlined"
                    style={{
                      fontFamily: 'Poppins',
                      pointerEvents: this.props.overview && "none",
                      fontSize: window.innerWidth < 375 && pxToEm(14)
                    }}
                    fullWidth
                    onChangeCapture={(e) => {
                      // console.log("here")
                      this.handleSearchTransport(e.target.value)
                      this.setState({
                        searchTransportText: e.target.value
                      })
                      // formik.setFieldValue(
                      //   "transporter",
                      //   e.target.value
                      // );
                    }}
                    InputLabelProps={{ shrink: this.state.searchTransportText }}
                    inputProps={{
                      style: {
                        fontSize: window.innerWidth < 375 && pxToEm(14),
                        wordWrap: 'break-word'
                      }
                    }}
                    inputRef={this.anchorEl}
                    InputProps={{
                      ...getInputProps({
                        value: searchTransportText,
                        placeholder: "",
                      }),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              padding: 0,
                              display: searchTransportText === '' || searchTransportText === null
                                ? 'none'
                                : 'block'
                            }}
                            onClick={() => {
                              // console.log("here")
                              // this.handleSearchTransport('', true)
                              this.setState({
                                searchTransportText: "",
                                openTransportPopper: false,
                                tranportersList: null
                              })
                              this.props.handleTransportCancel()
                              // laOperation === 'add' &&
                              //   formik.setFieldValue(
                              //     "transporter",
                              //     ''
                              //   )
                            }}
                          >
                            {this.props.overview ? "" :
                              <CloseOutlinedIcon />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  // helperText={
                  //   (formik.errors.transporter &&
                  //     formik.touched.transporter)
                  //     ? String(formik.errors.transporter)
                  //     : editData.transport
                  // }
                  // error={Boolean(
                  //   formik.errors.transporter &&
                  //   formik.touched.transporter
                  // )}
                  />
                  {(tranportersList && tranportersList.length === 0) &&
                    <p>Transporter not found. Please add...</p>
                  }
                  {this.anchorEl.current && (
                    <Popper
                      open={this.state.openTransportPopper}
                      anchorEl={this.anchorEl?.current}
                      placement="bottom-start"
                      style={{ zIndex: 100 }}
                    >
                      <ClickAwayListener
                        onClickAway={(e) => {
                          // this.handleSearchTransport(this.state.searchTransportText, true)
                          this.setState({ openTransportPopper: false })
                        }}
                      >
                        <div
                          {...(isOpen
                            ? getMenuProps({}, { suppressRefError: true })
                            : {})}
                        >
                          <Paper
                            square
                            style={{
                              maxHeight: "195px",
                              overflowY: "scroll",
                              marginTop: 5,
                              padding: '10px 1px',
                              width: this.anchorEl.current ? this.anchorEl.current.clientWidth + 48 : null,
                            }}
                          >
                            <MenuItem
                              key='-1'
                              component="div"
                              onClick={(e) => {
                                this.handleSelectTransporter('Not Available')
                              }}
                            >
                              Not Available
                            </MenuItem>
                            {openTransportPopper && tranportersList && tranportersList.map((data, index) => (
                              <MenuItem
                                key={index}
                                component="div"
                                onClick={(e) => {
                                  // console.log(e)
                                  this.handleSelectTransporter(data)
                                  // formik.setFieldValue(
                                  //   "transporter",
                                  //   data?.acc_name
                                  // );
                                  // this.handleSearchTransport(
                                  //   String(
                                  //     data?.transporter_id
                                  //       ? `${data?.acc_name} - ( ${data?.transporter_id} )`
                                  //       : data?.gst_number
                                  //         ? `${data?.acc_name} - ( ${data?.gst_number} )`
                                  //         : data?.pan_no
                                  //           ? `${data?.acc_name} - ( ${data?.pan_no} )`
                                  //           : data?.acc_name
                                  //   ), true
                                  // )
                                  // if (!data) {
                                  //   this.setState((previousState) => ({
                                  //     laDetails: {
                                  //       ...previousState.laDetails,
                                  //       transporter: null,
                                  //       transporter_contact_number: additionalData?.transporter_number === null && null,
                                  //     },
                                  //     additionalData: {
                                  //       ...previousState.additionalData,
                                  //       transporter_number: null,
                                  //     },
                                  //   }, () => this.setState({ have_transporter_contact_no: false })));
                                  // } else {
                                  //   this.setState((previousState) => ({
                                  //     laDetails: {
                                  //       ...previousState.laDetails,
                                  //       transporter: data.id,
                                  //       transporter_contact_number: data.id === this.props?.laDetails?.transporter?.id ? this.props?.laDetails?.transporter_contact_number : data.mobile === null ? null : Number(data.mobile),
                                  //     },
                                  //     additionalData: {
                                  //       ...previousState.additionalData,
                                  //       transporter_number: data.mobile === null ? null : Number(
                                  //         data.mobile
                                  //       ),
                                  //     },
                                  //     have_transporter_contact_no: data.id === this.props?.laDetails?.transporter?.id ? false : data.mobile == null ? false : true
                                  //   }));
                                  // }
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    lineHeight: "1.1em",
                                    fontFamily: 'Poppins',
                                    fontSize: window.innerWidth < 375 && pxToEm(14)
                                  }}
                                >
                                  <span>
                                    {data?.acc_name}
                                  </span>
                                  <span style={{ color: grey, fontSize: "0.7em" }}>
                                    {data?.transporter_id ? 'ID - ' + data?.transporter_id : ""}
                                  </span>
                                  <span style={{ color: grey, fontSize: "0.7em" }}>
                                    {!data?.gst_number
                                      ? !data?.pan_no
                                        ? ""
                                        : `PAN - ${data?.pan_no}`
                                      : `GSTIN - ${data?.gst_number}`}
                                  </span>
                                </div>
                              </MenuItem>
                            ))}
                          </Paper>
                        </div>
                      </ClickAwayListener>
                    </Popper>
                  )}
                </div>
              )}
            </Downshift>
          }
          {/* {showTransporterDropdown &&
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              className={classes.textFieldStyle}
              options={top100Films}
              onChange={() => {
                this.setState({
                  showAddTransporterButton: false
                })
              }}
              // isOptionEqualToValue={(option, value) =)}
              // sx={{ width: "100%" }}
              onInputChange={(e) => {
                this.handlefiltering(e)
                // console.log("changed", e, top100Films)
              }}
              renderInput={(params) => <TextField {...params} label="Movie" />}
              noOptionsText="Transporter not found. Please add..."
            // open={!showAddTransporterButton}
            // filterOptions={(options, params) => {
            //   const filtered = filter(options, params);

            //   // Suggest the creation of a new value
            //   if (params.inputValue !== '') {
            //     filtered.push({
            //       inputValue: params.inputValue,
            //       title: `Add "${params.inputValue}"`,
            //     });
            //   }

            //   return filtered;
            // }}

            // filterOptions={(options) =>
            //   options.filter(({ place_name }) => place_name.includes(query))
            // }
            />
          } */}
          {
            showAddTransporterButton &&
            <Button
              className={classes.addMoreButton}
              onClick={() => this.handleAddMoreButtonClick()}
              style={{
                margin: "20px 0px",
              }}
            >
              {showTransporterDropdown ? "+ ADD TRANSPORTER" : "SELECT TRANSPORTER"}
            </Button>
          }
          {!isTransporterAvailable &&
            <TextField
              name="transporterName"
              id="outlined-name"
              label="Transporter Name"
              className={classes.textFieldStyle}
              value={transporterName}
              onChange={(e) => {
                this.props.handleTextChange(e)
                this.props.checkError(e, "transporterName")
                // console.log(checkNameFieldAlphaNumeric(e.target.value))
              }}
              error={transporterNameError}
              margin="normal"
              variant="outlined"
              FormHelperTextProps={{
                style: { marginLeft: "0px", marginRight: "0px" },
              }}
              inputProps={{
                style: {
                  fontSize: window.innerWidth < 375 && pxToEm(14)
                }
              }}
              helperText={
                <span style={{ color: "red" }}>
                  {transporterNameError
                    ? "Invalid Input"
                    : null}
                </span>
              }
            />
          }
          {!isTransporterAvailable &&
            <TextField
              name="transporterContactNumber"
              id="outlined-name"
              label="Contact Number"
              className={classes.textFieldStyle}
              value={transporterContactNumber}
              error={transporterContactNumberLengthError}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                this.props.handleTextChange({
                  target: { name: "transporterContactNumber", value },
                });
                this.props.checkError(e, "transporterContactNumber")
              }}
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 10,
                style: {
                  fontSize: window.innerWidth < 375 && pxToEm(14)
                }
              }}
              // onBlur={() => {
              //   if (transporterContactNumber.length < 10) {
              //     this.setState({ transporterContactNumberLengthError: true });
              //   }
              // }}
              // onFocus={() => {
              //   this.setState({ transporterContactNumberLengthError: false });
              // }}
              FormHelperTextProps={{
                style: { marginLeft: "0px", marginRight: "0px" },
              }}
              helperText={
                <span style={{ color: "red" }}>
                  {transporterContactNumberLengthError
                    ? "Please enter valid 10 digit contact number"
                    : null}
                </span>
              }
            />
          }
        </div>
        <div className={classes.truckDetailsContainer}>
          <span style={styles.headingTextStyle}>Driver Details</span>
          <TextField
            name="driverName"
            id="outlined-name"
            label="Driver Name"
            className={classes.textFieldStyle}
            value={driverName || ''}
            onChange={(e) => {
              this.props.handleTextChange(e)
              this.props.checkError(e, "driverName")
              // console.log(checkNameFieldAlphaNumeric(e.target.value))
            }}
            margin="normal"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(14)
              }
            }}
            error={driverNameError}
            helperText={
              // <span style={{ color: "red" }}>
              driverNameError
                ? "Invalid input"
                : null
              // </span>
            }
          />
          <TextField
            name="driverContactNumber"
            id="outlined-name"
            label="Contact Number"
            className={classes.textFieldStyle}
            value={driverContactNumber || ''}
            error={driverContactNumberLengthError}
            margin="normal"
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");
              this.props.handleTextChange({
                target: { name: "driverContactNumber", value },
              });
              this.props.checkError(e, "driverContactNumber")

            }}
            inputProps={{
              maxLength: 10,
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(14)
              }
            }}
            // onBlur={() => {
            //   if (driverContactNumber.length < 10) {
            //     this.setState({ driverContactNumberLengthError: true });
            //   }
            // }}
            // onFocus={() => {
            //   this.setState({ driverContactNumberLengthError: false });
            // }}
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px" },
            }}
            helperText={
              <span style={{ color: "red" }}>
                {driverContactNumberLengthError
                  ? "Please enter valid 10 digit contact number"
                  : null}
              </span>
            }
          />
          <TextField
            name="driverLicenseNumber"
            id="outlined-name"
            label="License Number"
            placeholder="Enter License Number"
            className={classes.textFieldStyle}
            value={driverLicenseNumber || ''}
            margin="normal"
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value
              this.props.handleTextChange({
                target: { name: "driverLicenseNumber", value },
              });
              this.props.checkError(e, "driverLicenseNumber")
            }}
            inputProps={{
              maxLength: 16,
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(14)
              }
            }}
            // onBlur={() => {
            //   if (driverContactNumber.length < 10) {
            //     this.setState({ driverContactNumberLengthError: true });
            //   }
            // }}
            // onFocus={() => {
            //   this.setState({ driverContactNumberLengthError: false });
            // }}
            error={driverLicenseNumberError}
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px" },
            }}
            helperText={
              <span style={{ color: "red" }}>
                {driverLicenseNumberError
                  ? "Please enter valid License number"
                  : null}
              </span>
            }
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CreateDispatchLogisticDetail);
