import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import Dispatch from "../Dispatch";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  B2bDispatchMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  },
};
class B2bDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <MobileLayout bottomNavigation bottomNavSelected={2}>
        <div className={classes.B2bDispatchMainBody}>
          <Dispatch {...this.props} />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(B2bDispatch);
