// test server ip for GCP
// const HOST = "http://130.211.120.175/";

// Copied same as production
// const HOST = "http://35.223.77.122/";

const HOST = "https://sales.realdeals.pro/";
// const HOST = "http://182.74.0.14:4443/"
// const HOST =
//   process.env.NODE_ENV === "production"
//     ? "https://sales.realdeals.pro/"
//     : 'http://130.211.120.175/';

// test server ip for forticlient
// const HOST = "http://192.168.3.213/";

// for 26-mar-Demo
// const HOST = "https://920b388a846d4f.localhost.run/";

// Lokendra System
// const HOST = "https://1619577b9f94e3.localhost.run/";
// const HOST = "https://367ed989191c1d.localhost.run/";
export default HOST;
