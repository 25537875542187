import { forwardRef, useCallback, useEffect, useState } from "react";
import withUser from "../../../hoc/withUser";
import SnackbarComponent from "../../../components/common/SnackbarComponent"
import DynamicReportsWeb from '../../../components/internal/dynamicReports/web';
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import { arraySortBykeyAllBrowser, checkApiError, checkInvalidValue, getOptions, handleSnackbarMessageBox, isAccessibleModule } from "../../../methods";
import axios from "axios";
import moment from "moment";
import { Button, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";
import { primaryColor } from "../../../styles/colors";
import { drawerTextColor } from "../../../constants/internal/colors";


const MASTERDROPDOWN = [
  { label: 'ITEM CATEGORY', value: 'ItemsCategory' },
  { label: 'LINKED ITEM CATEGORY', value: 'LinkedItemCategory' },
  { label: 'ITEMS', value: 'Items' },
  { label: 'PAYMENT TERMS', value: 'PaymentTerms' },
  { label: 'PLANT ITEM MASTER', value: 'PlantMaster' },
  { label: 'TAGS MASTER', value: 'TagsMaster' },
  { label: 'BANK MASTER', value: 'Bank' },
  { label: 'TDS RATE MASTER', value: 'TdsRate' },
  { label: 'TRANSPORTER MASTER', value: 'Transporters' },
  { label: 'TCS MASTER', value: 'Tcs' },
  { label: 'TCS MATRIX', value: 'TcsMatrix' },
  { label: 'PINCODE MASTER', value: 'Pincode' },
  { label: 'ITEM NATURE MASTER', value: 'ItemNature' },
  { label: 'REASON MASTER', value: 'Reason' },
  { label: 'APP BANNER MASTER', value: 'AppBanner' },
  { label: 'ITEM SIZE MASTER', value: 'ItemSize' },
  {label:  'PAYMENT DISCOUNT & PREMIUM', value:'ItemCategoryWisePaymentMethodWiseDiscounts'},
  {label:  'CITY DISCOUNT', value:'ItemCategoryWiseCityWiseDiscounts'},
]

const useConditionalDialog = () => {
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    resolve: null,
  });

  const showDialog = () => {
    return new Promise((resolve) => {
      setDialogConfig({ open: true, resolve });
    });
  };

  const handleClose = (result) => {
    setDialogConfig((prevState) => {
      if (prevState.resolve) prevState.resolve(result);
      return { ...prevState, open: false, resolve: null };
    });
  };

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const DialogComponent = () => (
    <Dialog
      open={dialogConfig.open}
      TransitionComponent={Transition}
      maxWidth="md"
      onClose={() => handleClose('')}
    >
      <DialogContent>
        Applying this changes will clear generated report. Are you sure you want to proceed?
      </DialogContent>
      <DialogActions style={{ margin: "15px 0 10px" }}>
        <Button
          onClick={() => handleClose('apply')}
          variant="contained"
          style={{ backgroundColor: primaryColor, color: drawerTextColor }}
        >
          APPLY
        </Button>
        <Button
          onClick={() => handleClose('')}
          variant="text">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { showDialog, DialogComponent };
};

const useConditionalMultiRowWarningDialog = () => {
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    resolve: null,
    array: []
  });

  const showMultiRowWarningDialog = (resultArr) => {
    return new Promise((resolve) => {
      setDialogConfig({ open: true, array: resultArr, resolve });
    });
  };

  const handleClose = (result) => {
    setDialogConfig((prevState) => {
      if (prevState.resolve) prevState.resolve(result);
      return { ...prevState, open: false, array: [], resolve: null };
    });
  };

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const DialogMultiRowWarningComponent = () => (
    <Dialog
      open={dialogConfig.open}
      TransitionComponent={Transition}
      maxWidth="md"
      onClose={() => handleClose('')}
    >
      <DialogContent>
        Applying selected headers will generate duplicate row data in csv and pdf for
        <p>
          (
          {
            dialogConfig.array.map(d => {
              return (
                <span style={{ fontStyle: 'italic' }}> {d.general_name},  </span>
              )
            })

          }
          )
        </p>
      </DialogContent>
      <DialogActions style={{ margin: "15px 0 10px" }}>
        <Button
          onClick={() => handleClose('proceed')}
          variant="contained"
          style={{ backgroundColor: primaryColor, color: drawerTextColor }}
        >
          Proceed
        </Button>
        <Button
          onClick={() => handleClose('')}
          variant="text">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { showMultiRowWarningDialog, DialogMultiRowWarningComponent };
};


function DynamicReports(props) {

  const { showDialog, DialogComponent } = useConditionalDialog();
  const { showMultiRowWarningDialog, DialogMultiRowWarningComponent } = useConditionalMultiRowWarningDialog();

  const [tabValue, setTabValue] = useState(0)
  const [templateSearchbar, setTemplateSearchbar] = useState('')
  const [filterType, setFilterType] = useState([])
  const [savedTemplate, setSavedTemplate] = useState({})
  const [showAllArray, setShowAllArray] = useState([])

  const [allHeaderLoader, setAllHeaderLoader] = useState(false)
  const [tabActivetemplate, setTabActiveTemplate] = useState({})
  const [tabAppliedTemplate, setTabAppliedTemplate] = useState({})
  const [tabAllHeadersData, setTabAllHeadersData] = useState({
    DAILYRATES: { allHeaders: [] },
    CUSTOMERS: { allHeaders: [] },
    CONTRACTS: { allHeaders: [] },
    SUBCONTRACTS: { allHeaders: [] },
    LOADINGADVICES: { allHeaders: [] },
    INVOICES: { allHeaders: [] },
    EINVOICES: { allHeaders: [] },
    DISPATCHTRACKINGS: { allHeaders: [] },
    USERPERMISSIONS: { allHeaders: [] },
    MASTERS: { allHeaders: [] },
  })

  const [tabSelectedHeadersIdData, setTabSelectedHeadersIdData] = useState({
    DAILYRATES: { selectedHeaderId: [] },
    CUSTOMERS: { selectedHeaderId: [] },
    CONTRACTS: { selectedHeaderId: [] },
    SUBCONTRACTS: { selectedHeaderId: [] },
    LOADINGADVICES: { selectedHeaderId: [] },
    INVOICES: { selectedHeaderId: [] },
    EINVOICES: { selectedHeaderId: [] },
    DISPATCHTRACKINGS: { selectedHeaderId: [] },
    USERPERMISSIONS: { selectedHeaderId: [] },
    MASTERS: { selectedHeaderId: [] },
  })

  const [tabSelectedHeadersTableData, setTabSelectedHeadersTableData] = useState({
    DAILYRATES: { selectedHeaderTableData: [] },
    CUSTOMERS: { selectedHeaderTableData: [] },
    CONTRACTS: { selectedHeaderTableData: [] },
    SUBCONTRACTS: { selectedHeaderTableData: [] },
    LOADINGADVICES: { selectedHeaderTableData: [] },
    INVOICES: { selectedHeaderTableData: [] },
    EINVOICES: { selectedHeaderTableData: [] },
    DISPATCHTRACKINGS: { selectedHeaderTableData: [] },
    USERPERMISSIONS: { selectedHeaderTableData: [] },
    MASTERS: { selectedHeaderTableData: [] },
  })

  const [masterDropdownValue, setMasterDropdownValue] = useState(MASTERDROPDOWN[0])
  const [orderByData, setOrderByData] = useState({})
  const [isTemplateDataChanged, setIsTemplateDataChanged] = useState({})

  const [tableDataLoader, setTableDataLoader] = useState(false)
  const [buttonClickLoader, setButtonClickLoader] = useState(false)
  const [filterValueApiLoader, setFilterValueApiLoader] = useState({})


  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: '',
    snackbarType: ''
  })

  const handleTabValueTitle = () => {
    return tabValue === 0
      ? 'DAILYRATES'
      : tabValue === 1
        ? 'CUSTOMERS'
        : tabValue === 2
          ? 'CONTRACTS'
          : tabValue === 3
            ? 'SUBCONTRACTS'
            : tabValue === 4
              ? 'LOADINGADVICES'
              : tabValue === 5
                ? 'INVOICES'
                : tabValue === 6
                  ? 'EINVOICES'
                  : tabValue === 7
                    ? 'DISPATCHTRACKINGS'
                    : tabValue === 8
                      ? 'USERPERMISSIONS'
                      : tabValue === 9 && 'MASTERS'
  }

  useEffect(() => {
    let tabTitle = handleTabValueTitle()
    fetchModuleField(tabTitle)
    handleActiveTemplate()
    fetchFilterType()
    fetchSavedTemplate(tabTitle)
    if (tabTitle === 'MASTERS') {
      if (masterDropdownValue === null || masterDropdownValue === undefined)
        handleMasterDropdownChange(MASTERDROPDOWN[0])
    }
  }, [tabValue])

  useEffect(() => {
    let tabTitle = handleTabValueTitle()
    if (tabTitle === 'MASTERS' && Boolean(masterDropdownValue)) {
      // console.log("here after proceed", masterDropdownValue)
      fetchModuleField(tabTitle)
      handleModuleHeaderRemove('clear')
      fetchSavedTemplate(tabTitle)
    }
  }, [masterDropdownValue])

  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: '',
      snackbarType: ''
    })
  };

  const handleMasterDropdownChange = (value) => {
    setMasterDropdownValue(value)
  }


  const handleTableDataChangeValidation = async () => {
    let res = tabActivetemplate[handleTabValueTitle()].activeTemplateData?.status === 'COMPLETED'
    console.log(res)
    if (res) {
      const result = await showDialog();
      console.log("result", result, res)
      if (result === 'apply') {
        // handleTemplateChange(type)
        handleCancelResetRequestReport('reset', 'is_revised')
      }
      return result === 'apply' ? true : false;
    }
    else {
      // handleTemplateChange(type)
      console.log("Efwe", res)
      return true
    }
  }

  const handleMultiRowWarningValidationLogic = (allData, addedHeader) => {
    let newAllData = [...allData]
    let popupArr = []
    addedHeader.map(d => {
      if (!d.multi_row_field) {
        newAllData.push(d)
      }
      else {
        if (!Boolean(newAllData.find(n => n?.headerData ? n?.headerData?.multi_row_field : n?.multi_row_field))) {
          newAllData.push(d)
        }
        else {
          let filterMultiRowData = newAllData.filter(p => p?.headerData ? p?.headerData?.multi_row_field : p?.multi_row_field)
          let matchData = Boolean(filterMultiRowData.find(n => n?.headerData ? Boolean(n?.headerData?.table === d.table) : Boolean(n.table === d.table)))

          if (matchData) {
            newAllData.push(d)
          }
          else {
            newAllData.push(d)
            popupArr.push(d)
          }
        }
      }
    })
    return popupArr
  }

  const handleTableDataChangeMultiRowWarningValidation = async (allData, addedHeader) => {
    // logic
    let resultArr = handleMultiRowWarningValidationLogic(allData, addedHeader)
    let res = resultArr.length > 0

    if (res) {
      const result = await showMultiRowWarningDialog(resultArr);
      console.log("result MultiRowWarning", result, res)
      return result === 'proceed' ? true : false;
    }
    else {
      console.log("Else MultiRowWarning", res)
      return true
    }
  }


  const handleTableDataOrder = async (data) => {
    let tabTitle = handleTabValueTitle()
    setTabSelectedHeadersTableData(prevValue => {
      return { ...prevValue, [tabTitle]: { selectedHeaderTableData: data } }
    })
  }

  const handleTemplateSearchBar = (value,) => {
    setTemplateSearchbar(value)
  }

  const InitialHeaderData = (headerData) => {
    return {
      id: String(headerData?.id),
      is_filter: false,
      is_calculation: false,
      field_name: headerData.general_name,
      filter_type: "",
      filter_value: "",
      filter_query: "",
      calculation_type: "",
      headerData: headerData,
      filterData: ""
    }
  }

  const handleModuleManyHeaderAddRemove = async (addedHeader, removedHeader) => {
    // console.log(addedHeader, removedHeader)
    let tabTitle = handleTabValueTitle()
    if (removedHeader?.length > 0 && addedHeader?.length > 0) {

      //removing
      let newArr = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData.filter(d => !removedHeader.includes(Number(d.id)));
      let newIdArr = tabSelectedHeadersIdData[tabTitle].selectedHeaderId.filter(d => !removedHeader.includes(d))

      let checkValidation = await handleTableDataChangeMultiRowWarningValidation(newArr, addedHeader);
      if (checkValidation) {
        //adding
        addedHeader.map((headerData) => {
          let Obj = InitialHeaderData(headerData)
          newIdArr.push(headerData.id)
          newArr.push(Obj)
        })

        setTabSelectedHeadersTableData(prevValue => {
          return { ...prevValue, [tabTitle]: { selectedHeaderTableData: newArr } }
        })
        setTabSelectedHeadersIdData(prevValue => {
          return { ...prevValue, [tabTitle]: { selectedHeaderId: newIdArr } }
        })
      }
    }

    else if (removedHeader?.length > 0 && addedHeader?.length <= 0) {
      let newArr = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData.filter(d => !removedHeader.includes(Number(d.id)))
      // console.log(newArr, "after remove")
      setTabSelectedHeadersTableData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderTableData: newArr } }
      })

      let newSelectedheaderID = tabSelectedHeadersIdData[tabTitle].selectedHeaderId.filter(d => !removedHeader.includes(d))
      // console.log(newSelectedheaderID, "after remove")
      setTabSelectedHeadersIdData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderId: newSelectedheaderID } }
      })
    }

    else if (addedHeader?.length > 0 && removedHeader?.length <= 0) {
      let newArr = [...tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData]
      let newIdArr = [...tabSelectedHeadersIdData[tabTitle].selectedHeaderId]

      let checkValidation = await handleTableDataChangeMultiRowWarningValidation(newArr, addedHeader);
      if (checkValidation) {
        addedHeader.map((headerData) => {
          let Obj = InitialHeaderData(headerData)
          newIdArr.push(headerData.id)
          newArr.push(Obj)
        })
        // console.log(newArr, "after add", newIdArr, "after add")
        setTabSelectedHeadersTableData(prevValue => {
          return {
            ...prevValue,
            [tabTitle]: { selectedHeaderTableData: newArr }
          }
        })
        setTabSelectedHeadersIdData(prevValue => {
          return {
            ...prevValue,
            [tabTitle]: { selectedHeaderId: newIdArr }
          }
        })
      }
    }
  }

  const handleModuleHeaderAdd = async (headerData) => {
    // console.log(headerData)
    if (headerData) {
      let Obj = {
        id: String(headerData?.id),
        // is_added: true,
        is_filter: false,
        is_calculation: false,
        field_name: headerData.general_name,
        filter_type: "",
        filter_value: "",
        filter_query: "",
        calculation_type: "",
        headerData: headerData,
        filterData: ""
      }

      let tabTitle = handleTabValueTitle()
      let newArr = [...tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData]

      let checkValidation = await handleTableDataChangeMultiRowWarningValidation(newArr, [headerData]);
      if (checkValidation) {

        newArr.push(Obj)
        let newIdArr = [...tabSelectedHeadersIdData[tabTitle].selectedHeaderId]
        newIdArr.push(headerData.id)

        setTabSelectedHeadersTableData(prevValue => {
          return {
            ...prevValue,
            [tabTitle]: { selectedHeaderTableData: newArr }
          }
        })
        setTabSelectedHeadersIdData(prevValue => {
          return {
            ...prevValue,
            [tabTitle]: { selectedHeaderId: newIdArr }
          }
        })
      }
    }
  }

  const handleModuleHeaderRemove = (id) => {
    let tabTitle = handleTabValueTitle()

    if (id === 'clear') {
      setTabSelectedHeadersTableData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderTableData: [] } }
      })
      setTabSelectedHeadersIdData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderId: [] } }
      })
      setOrderByData(prevValue => {
        return {
          ...prevValue,
          [tabTitle]: { id: null, field_name: null, order_by: null }
        }
      })
      setTabActiveTemplate(prevValue => {
        return {
          ...prevValue,
          [tabTitle]: { activeTemplateData: {} }
        }
      })
      setTabAppliedTemplate(prevValue => {
        return {
          ...prevValue,
          [tabTitle]: { appiledtemplateData: {} }
        }
      })

    }
    else {
      let newArr = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData.filter(d => d.id !== id)
      setTabSelectedHeadersTableData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderTableData: newArr } }
      })

      let newSelectedheaderID = tabSelectedHeadersIdData[tabTitle].selectedHeaderId.filter(d => d !== Number(id))
      setTabSelectedHeadersIdData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderId: newSelectedheaderID } }
      })

      // console.log(newArr, newSelectedheaderID)
    }
  }

  const FetchMasterFilterValueSearchApiData = useCallback(async (url, searchText) => {
    try {
      const response = await axios.get(`${url}?search=${searchText}`, getOptions(props.user));
      if (response.status === 200) {
        return response.data?.results ? response.data.results : response.data;
      }
    } catch (error) {
      console.log("0")
      handleOpenSnackbar(
        handleSnackbarMessageBox(checkApiError(error?.response)),
        "error"
      );
      setTimeout(() => {
        handleCloseSnackbar();
      }, 4000);
    }
  }, [props.user, handleOpenSnackbar, handleSnackbarMessageBox, checkApiError, handleCloseSnackbar, getOptions]);

  const FetchMasterFilterValueApiData = useCallback(async (url) => {
    try {
      const response = await axios.get(url, getOptions(props.user));
      if (response.status === 200) {
        return response.data?.results ? response.data.results : response.data;
      }
    } catch (error) {
      console.log("1")
      handleOpenSnackbar(
        handleSnackbarMessageBox(checkApiError(error?.response)),
        "error"
      );
      setTimeout(() => {
        handleCloseSnackbar();
      }, 4000);
    }
  }, [props.user, handleOpenSnackbar, handleSnackbarMessageBox, checkApiError, handleCloseSnackbar, getOptions]);

  const handleFilterTypes = async (data, rowID, field = '', fieldValue = '', rowData = '', type = '') => {
    // console.log(data, rowID, field, fieldValue, rowData)

    let tabTitle = handleTabValueTitle()

    if (field === 'filter_value') {
      let newArr = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData.map((item) => {
        if (item.id === rowID) {
          return {
            ...item,
            filter_value: fieldValue,
            filter_query: type === ''
              ? `${data.headerData.query_field_name}:${checkInvalidValue(data?.filterData?.filter_query) ? '' : data?.filterData?.filter_query}:${fieldValue}`
              : type === 'master' && `${data.headerData.query_field_name}:__in`
          };
        } else {
          return item;
        }
      })
      console.log(fieldValue, newArr)
      setTabSelectedHeadersTableData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderTableData: newArr } }
      })
    }
    else {
      let apiData = []
      if (rowData.headerData.filter_master && !rowData.headerData.searchable_api) {
        setFilterValueApiLoader(prevValue => { return { ...prevValue, [rowID]: true } })
        apiData = await FetchMasterFilterValueApiData(rowData.headerData.api_url)
      }

      let newArr = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData.map((item) => {
        if (item.id === rowID) {
          return {
            ...item,
            is_filter: data === null ? false : true,
            filter_type: data === null ? "" : data?.filter_type,
            filterData: data,
            apiData: apiData,
            filter_value: ""
          };
        } else { return item }
      })
      setFilterValueApiLoader(prevValue => { return { ...prevValue, [rowID]: false } })
      setTabSelectedHeadersTableData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderTableData: newArr } }
      })
    }
  }

  const handleCalculationTypes = (data, rowID) => {

    let tabTitle = handleTabValueTitle()

    let newArr = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData.map((item) => {
      if (item.id === rowID) {
        return {
          ...item,
          is_calculation: data === null ? false : true,
          calculation_type: data === null ? "" : data?.calculation_type
        };
      } else {
        return item;
      }
    })

    setTabSelectedHeadersTableData(prevValue => {
      return { ...prevValue, [tabTitle]: { selectedHeaderTableData: newArr } }
    })
  }

  const handleTableOrderBy = (id, fieldName, orderType) => {
    let tabTitle = handleTabValueTitle()
    setOrderByData(prevValue => {
      return {
        ...prevValue,
        [tabTitle]: {
          id: id,
          field_name: fieldName,
          order_by: orderType,
        }
      }
    })
  }

  const checkRowData = (prevDataEle, ele) => {
    let checkID = Boolean(prevDataEle.field.id === Number(ele.id))
    // console.log(checkID, "checkID", prevDataEle, ele)
    if (checkID) {
      let checkFilterType = ''
      let prevDataFilterType = checkInvalidValue(prevDataEle?.filter_types[0]?.filter_type?.filter_type) ? '' : prevDataEle?.filter_types[0]?.filter_type?.filter_type
      let rowDataFilterType = checkInvalidValue(ele.filter_type) ? '' : ele.filter_type
      checkFilterType = Boolean(prevDataFilterType === rowDataFilterType)
      if (checkFilterType) {
        let checkFilterValue = ''
        if (Array.isArray(ele.filter_value)) {
          let prevDataFilterValue = checkInvalidValue(prevDataEle?.filter_types[0]?.filter_values) ? '' : prevDataEle?.filter_types[0]?.filter_values
          let rowDataFilterValue = checkInvalidValue(ele.filter_value) ? '' : ele.filter_value

          if (rowDataFilterValue.length !== prevDataFilterValue.length) {
            checkFilterValue = false
          }
          rowDataFilterValue.map((d, i) => {
            if (d.filter_value_name !== prevDataFilterValue[i]?.display_name ||
              (isNaN(d.value) ? d.value : Number(d.value)) !== (isNaN(prevDataFilterValue[i]?.value) ? prevDataFilterValue[i]?.value : Number(prevDataFilterValue[i]?.value))) {
              checkFilterValue = false
            }
            else {
              checkFilterValue = true
            }
          })
        } else if (ele.filter_type === "In between") {
          let prevDataFilterValue = [prevDataEle?.filter_types[0]?.filter_value, prevDataEle?.filter_types[1]?.filter_value]
          let rowDataFilterValue = checkInvalidValue(ele.filter_value) ? '' : ele.filter_value.split(' to ')
          // console.log(prevDataFilterValue, rowDataFilterValue, (prevDataFilterValue[0] === rowDataFilterValue[0]), (prevDataFilterValue[1] === rowDataFilterValue[1]))
          checkFilterValue = Boolean((prevDataFilterValue[0] === rowDataFilterValue[0]) && (prevDataFilterValue[1] === rowDataFilterValue[1]))

        }
        else {
          let prevDataFilterValue = checkInvalidValue(prevDataEle?.filter_types[0]?.filter_value)
            ? ''
            : prevDataEle?.filter_types[0]?.filter_value === 'True'
              ? true
              : prevDataEle?.filter_types[0]?.filter_value === 'False'
                ? false
                : prevDataEle?.filter_types[0]?.filter_value

          let rowDataFilterValue = checkInvalidValue(ele.filter_value)
            ? ''
            : ele.filter_value
          checkFilterValue = Boolean(prevDataFilterValue === rowDataFilterValue)
        }
        if (checkFilterValue) {
          let checkCalcValue = ''
          let prevDataCalcValue = checkInvalidValue(prevDataEle?.calculation?.calculation_type) ? '' : prevDataEle?.calculation?.calculation_type
          let rowDataCalcValue = checkInvalidValue(ele.calculation_type) ? '' : ele.calculation_type
          checkCalcValue = Boolean(prevDataCalcValue === rowDataCalcValue)
          return !checkCalcValue
        }
        else {
          console.log('fliter_value')
          return true
        }
      }
      else {
        console.log('fliter_type')
        return true
      }
    }
    else {
      console.log('id')
      return true
    }
  }



  const handleTableDataIsChangedValidation = async (tableData, order_by, type = '') => {
    const tabTitle = handleTabValueTitle();
    const prevData = tabAppliedTemplate[tabTitle]?.appiledtemplateData || tabActivetemplate[tabTitle]?.activeTemplateData || {};
    const prevFieldData = prevData.field_data || [];
    // console.log(prevData, tableData, "prevData", order_by)
    const orderByDATA = checkInvalidValue(prevData.template_detail)
      ? { order_by_field: prevData.order_by_field, order_by: prevData.order_by }
      : { order_by_field: prevData.template_detail.order_by_field, order_by: prevData.template_detail.order_by }

    // console.log(order_by, prevData, "prevData")

    const updateIsTemplateDataChanged = (isChanged) => {
      setIsTemplateDataChanged(prevValue => ({
        ...prevValue,
        [tabTitle]: { is_changed: isChanged }
      }));
    };

    if (tableData.length !== prevFieldData.length) {
      console.log("table length changed")
      updateIsTemplateDataChanged(true);
      return 'is_changed';
    }

    if (order_by.order_by_field !== orderByDATA.order_by_field || order_by.order_by !== orderByDATA.order_by) {
      console.log("order by changed")
      updateIsTemplateDataChanged(true);
      return 'is_changed';
    }

    for (let index = 0; index < tableData.length; index++) {
      const prevDataEle = prevFieldData[index];
      if (!prevDataEle || checkRowData(prevDataEle, tableData[index])) {
        updateIsTemplateDataChanged(true);
        return 'is_changed';
      }
    }

    updateIsTemplateDataChanged(false);
    return '';
  };

  const handleRequestReportClick = async (orderData) => {
    setButtonClickLoader('requestReport')
    let tabTitle = handleTabValueTitle()
    let fields = []
    let filters = []
    let calculations = []
    let order_by = ''
    if (!checkInvalidValue(orderData.id)) {
      order_by = {
        order_by_field: orderData.id,
        order_by: orderData.order_by
      }
    }
    // console.log(orderData, orderData !== '', order_by, order_by.order_by !== '' || order_by.order_by !== null)
    let data = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData

    if (data.length > 0) {
      data.forEach(ele => {
        fields.push({
          id: Number(ele.id),
          is_filter: ele.is_filter,
          is_calculation: ele.is_calculation,
        })

        if (ele.is_filter) {
          console.log(ele, ele.filter_type === "In between", ele.filterData?.field_type === 'number')
          let filterQueryData = ele.filter_query.split(':')
          if (ele.filter_type === "In between" && ele.filterData?.field_type === 'date') {
            let dateRange = ele.filter_value.split(' to ')
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__gte`,
              filter_value: `${moment(dateRange[0]).format('YYYY-MM-DD')}`,
              filter_id: ele?.filterData?.id
            })
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__lte`,
              filter_value: `${moment(dateRange[1]).format('YYYY-MM-DD')}`,
              filter_id: ele?.filterData?.id
            })
          }
          else if (ele.filter_value === "start date" || ele.filter_value === "end date") {
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}${filterQueryData[1]}`,
              filter_value: `${moment(ele.filter_value).format('YYYY-MM-DD')}`,
              filter_id: ele?.filterData?.id
            })
          }
          else if (ele.filter_type === "In between" && ele.filterData?.field_type === 'number') {
            let numberRange = ele.filter_value.split(' to ')
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__gte`,
              filter_value: Number(numberRange[0]),
              filter_id: ele?.filterData?.id
            })
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__lte`,
              filter_value: Number(numberRange[1]),
              filter_id: ele?.filterData?.id
            })
          }
          else {
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}${filterQueryData[1]}`,
              filter_value: ele.filter_value,
              filter_id: ele?.filterData?.id
            })
          }
        }
        if (ele.is_calculation) {
          calculations.push({
            id: Number(ele.id),
            calculation_type: ele.calculation_type
          })
        }
      })

      let templateID = tabAppliedTemplate[tabTitle]?.appiledtemplateData?.id || tabActivetemplate[tabTitle]?.activeTemplateData?.template || null
      let is_changed = false
      if (templateID) {
        let res = await handleTableDataIsChangedValidation(data, order_by)
        console.log(res)
        is_changed = res === 'is_changed' ? true : false
      }

      let payloadData = order_by !== '' ?
        {
          module: tabTitle,
          fields: fields,
          filters: filters,
          calculations: calculations,
          order_by: order_by,
          template: templateID,
          // is_changed: Boolean(templateID && isTemplateDataChanged[tabTitle]?.is_changed)
          is_changed: Boolean(templateID && is_changed)
        }
        :
        {
          module: tabTitle,
          fields: fields,
          filters: filters,
          calculations: calculations,
          template: templateID,
          // is_changed: Boolean(templateID && isTemplateDataChanged[tabTitle]?.is_changed),
          is_changed: Boolean(templateID && is_changed)
        }



      axios
        .post(`${APIROUTES.POST_DYNAMIC_REPORTS}`, payloadData, getOptions(props.user))
        .then((response) => {
          if (response.status === 200) {
            setButtonClickLoader(false)
            handleActiveTemplate()
            handleOpenSnackbar("Dynamic report requested successfully", "success");
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000)
          }
        })
        .catch((error) => {
          console.log("2")
          setAllHeaderLoader(false)
          setButtonClickLoader(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000)
        });
    }

  }

  const handleSaveTemplateClick = async (templateName, orderData) => {
    setButtonClickLoader('saveTemplate')
    let tabTitle = handleTabValueTitle()
    let fields = []
    let filters = []
    let calculations = []
    let order_by = {
      order_by_field: orderData.id,
      order_by: orderData.order_by
    }
    let data = tabSelectedHeadersTableData[tabTitle].selectedHeaderTableData
    if (data.length > 0) {
      data.forEach(ele => {
        fields.push({
          id: Number(ele.id),
          is_filter: ele.is_filter,
          is_calculation: ele.is_calculation,
        })

        if (ele.is_filter) {
          let filterQueryData = ele.filter_query.split(':')
          if (ele.filter_type === "In between" && ele.filterData?.field_type === 'date') {
            let dateRange = ele.filter_value.split(' to ')
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__gte`,
              filter_value: `${moment(dateRange[0]).format('YYYY-MM-DD')}`,
              filter_id: ele?.filterData?.id
            })
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__lte`,
              filter_value: `${moment(dateRange[1]).format('YYYY-MM-DD')}`,
              filter_id: ele?.filterData?.id
            })
          }
          else if (ele.filter_value === "start date" || ele.filter_value === "end date") {
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}${filterQueryData[1]}`,
              filter_value: `${moment(ele.filter_value).format('YYYY-MM-DD')}`,
              filter_id: ele?.filterData?.id
            })
          }
          else if (ele.filter_type === "In between" && ele.filterData?.field_type === 'number') {
            let numberRange = ele.filter_value.split(' to ')
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__gte`,
              filter_value: Number(numberRange[0]),
              filter_id: ele?.filterData?.id
            })
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}__lte`,
              filter_value: Number(numberRange[1]),
              filter_id: ele?.filterData?.id
            })
          }
          else {
            filters.push({
              id: Number(ele.id),
              filter_query: `${filterQueryData[0]}${filterQueryData[1]}`,
              filter_value: ele.filter_value,
              filter_id: ele?.filterData?.id
            })
          }
        }
        if (ele.is_calculation) {
          calculations.push({
            id: Number(ele.id),
            calculation_type: ele.calculation_type
          })
        }
      })
      let templateID = tabAppliedTemplate[tabTitle]?.appiledtemplateData?.id || tabActivetemplate[tabTitle]?.activeTemplateData?.template || null

      let payloadData = !checkInvalidValue(order_by.order_by) ? {
        module: tabTitle,
        fields: fields,
        filters: filters,
        calculations: calculations,
        order_by: order_by,
        template_name: templateName,
        template: templateID,
        is_changed: Boolean(templateID && isTemplateDataChanged[tabTitle]?.is_changed)
        // is_changed: Boolean(templateID && is_changed)
      }
        :
        {
          module: tabTitle,
          fields: fields,
          filters: filters,
          calculations: calculations,
          template_name: templateName,
          template: templateID,
          is_changed: Boolean(templateID && isTemplateDataChanged[tabTitle]?.is_changed)
          // is_changed: Boolean(templateID && is_changed)
        }

      axios
        .post(`${APIROUTES.SAVE_TEMPLATE_DYNAMIC_REPORTS}`, payloadData, getOptions(props.user))
        .then((response) => {
          if (response.status === 200) {
            // console.log(tabAppliedTemplate[tabTitle]?.appiledtemplateData, !tabAppliedTemplate[tabTitle]?.appiledtemplateData?.id, tabActivetemplate[tabTitle]?.activeTemplateData?.status)
            if (!tabAppliedTemplate[tabTitle]?.appiledtemplateData?.id && tabActivetemplate[tabTitle]?.activeTemplateData?.status === undefined) {
              handleModuleHeaderRemove('clear')
            }
            fetchSavedTemplate(tabTitle)
            if (['IN_PROGRESS', 'COMPLETED'].includes(tabActivetemplate[tabTitle]?.activeTemplateData?.status)) {
              handleActiveTemplate()
            }
            handleOpenSnackbar("Template saved successfully", "success");
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000)
            setButtonClickLoader(false)
          }
        })
        .catch((error) => {
          console.log("3")
          setAllHeaderLoader(false)
          setButtonClickLoader(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000)
        });
    }
  }


  const handleNonSearchableApiFilterValue = async (rowID, apiUrl) => {
    let apiData = []
    setFilterValueApiLoader(prevValue => { return { ...prevValue, [rowID]: true } })
    apiData = await FetchMasterFilterValueApiData(apiUrl)
    setFilterValueApiLoader(prevValue => { return { ...prevValue, [rowID]: false } })
    return apiData
  }


  const handlePrepareTableData = async (data, allData) => {
    let tabTitle = handleTabValueTitle()
    // console.log(data, allData)
    if (data) {
      let newData = []
      let apiFields = []
      let newDataId = []
      data.forEach(ele => {
        if (ele.filter_types.length > 0 && ele.filter_types[0].filter_query && ele.filter_types[0].filter_query.includes('__in') && !ele.field.searchable_api) {
          apiFields.push({ id: ele.field.id, apiUrl: ele.field.api_url })
        }
        let Obj = {
          id: String(ele.field.id),
          is_filter: ele.filter_types.length > 0 ? true : false,
          is_calculation: ele.calculation.calculation_type !== "" ? true : false,
          field_name: ele.field.general_name,
          filter_type: ele.filter_types.length > 0 ? ele.filter_types[0].filter_type.filter_type : "",
          filter_value: ele.filter_types.length > 0
            ? ele.filter_types[0].filter_query && ele.filter_types[0].filter_query.includes('__in')
              ? ele.filter_types[0].filter_values.map(d => {
                return {
                  value: isNaN(d.value) ? d.value : Number(d.value),
                  display_name: d.filter_value_name
                }
              })
              : ele.filter_types.length === 2
                ? ele.filter_types[0].filter_query && ele.filter_types[0].filter_query.includes('__gte')
                  ? ele.filter_types[0].filter_value + ' to ' + ele.filter_types[1].filter_value
                  : ele.filter_types[1].filter_value + ' to ' + ele.filter_types[0].filter_value
                : ele.filter_types[0].filter_value === 'True'
                  ? true
                  : ele.filter_types[0].filter_value === 'False'
                    ? false
                    : ele.filter_types[0].filter_value
            : "",
          filter_query: ele.filter_types.length > 0
            ? ele.filter_types[0].filter_type.filter_query === null
              ? `${ele.field.query_field_name}:`
              : `${ele.field.query_field_name}:${ele.filter_types[0].filter_type.filter_query}`
            : "",
          calculation_type: ele.calculation.calculation_type,
          headerData: ele.field,
          filterData: ele.filter_types.length > 0 ? ele.filter_types[0].filter_type : ""
        }
        newData.push(Obj)
        newDataId.push(ele.field.id)
      })
      let apiCalls = ''
      if (apiFields.length > 0) {
        apiCalls = apiFields.map(async (ele) => {
          let apiData = await handleNonSearchableApiFilterValue(ele.id, ele.apiUrl)
          newData.map((item) => {
            if (String(item.id) === String(ele.id)) {
              item.apiData = apiData
            }
            return item
          })
        })
      }

      // Step 2: Use Promise.all to wait for all promises to resolve
      Promise.all(apiCalls).then(() => {

        setTabSelectedHeadersTableData(prevValue => {
          return { ...prevValue, [tabTitle]: { selectedHeaderTableData: newData } }
        })
        setTabSelectedHeadersIdData(prevValue => {
          return { ...prevValue, [tabTitle]: { selectedHeaderId: newDataId } }
        })
        // console.log(allData?.order_by_field, allData?.order_by, "allData")
        setOrderByData(prevValue => {
          return {
            ...prevValue,
            [tabTitle]: {
              id: allData?.order_by_field,
              field_name: data.find(d => d.field.id === allData.order_by_field)?.field.general_name,
              order_by: allData?.order_by,
            }
          }
        })
        setTableDataLoader(false)
      })
    }
    else {
      setTabSelectedHeadersTableData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderTableData: [] } }
      })
      setTabSelectedHeadersIdData(prevValue => {
        return { ...prevValue, [tabTitle]: { selectedHeaderId: [] } }
      })
      setOrderByData(prevValue => {
        return {
          ...prevValue,
          [tabTitle]: {
            id: null,
            field_name: null,
            order_by: null,
          }
        }
      })
      setTableDataLoader(false)
    }
  }

  const checkActiveTemplateValidation = (previousData, newData, isRevised) => {
    // console.log(previousData, newData)
    let tabTitle = handleTabValueTitle()
    if (!checkInvalidValue(previousData)) {
      // console.log(newData.status === "COMPLETED" , tabAppliedTemplate[tabTitle]?.appiledtemplateData, !checkInvalidValue(tabAppliedTemplate[tabTitle]?.appiledtemplateData?.id))
      if ((newData.status === "COMPLETED" && !checkInvalidValue(tabAppliedTemplate[tabTitle]?.appiledtemplateData?.id))) {
        return false
      }
      else if ((previousData?.status === newData?.status) ||
        (previousData.status === "IN_PROGRESS" && newData.status !== "COMPLETED") ||
        (previousData.status === "COMPLETED" && newData.status === undefined && isRevised) ||
        (previousData.status === "COMPLETED" && newData.status === 'CANCELLED' && isRevised)
      ) {
        return false
      }
      else if (["IN_PROGRESS", "COMPLETED"].includes(newData.status) ||
        ((previousData.status === "COMPLETED" && newData.status === undefined && !isRevised))) {
        return true
      }
      else
        return true
    }
    else
      return true
  }



  const handleActiveTemplate = (isRevised = '') => {
    let tabTitle = handleTabValueTitle()
    setTableDataLoader(true)
    let url = tabTitle !== 'MASTERS'
      ? `${APIROUTES.GET_DYNAMIC_REPORTS_ACTIVE_TEMPLATE}?module=${tabTitle}`
      : `${APIROUTES.GET_DYNAMIC_REPORTS_ACTIVE_TEMPLATE}?module=${tabTitle}&master=${masterDropdownValue === null || masterDropdownValue === undefined ? MASTERDROPDOWN[0] : masterDropdownValue.value}`

    axios.get(url, getOptions(props.user))
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          let prevData = tabActivetemplate[handleTabValueTitle()]?.activeTemplateData
          if (checkActiveTemplateValidation(prevData, data, isRevised)) {
            handlePrepareTableData(data.field_data, data?.template_detail)
          }
          else {
            setTableDataLoader(false)
          }
          setTabActiveTemplate(prevValue => {
            return {
              ...prevValue,
              [tabTitle]: { activeTemplateData: data }
            }
          })
        }
      })
      .catch((error) => {
        console.log("4", error, error.response)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000)
      });
  }

  const handleTabValue = (value) => {
    setTabValue(value)
  }

  const handleSortWithGroup = (data) => {
    const sortedAndGrouped = arraySortBykeyAllBrowser(data, 'general_name')
    // console.log(arraySortBykeyAllBrowser(data, 'group'))
    return Object.entries(sortedAndGrouped.reduce((groups, item) => {
      // const firstLetter = item.general_name[0].toUpperCase();
      groups[item.group === null ? 'OTHERS' : item.group?.toUpperCase()] =
        (groups[item.group === null ? 'OTHERS' : item.group?.toUpperCase()] || []).concat(item);
      return groups;
    }, {})).map(([category, items]) => ({ category, items }))
  }

  const fetchModuleField = (module, master = '') => {
    let url = module !== 'MASTERS'
      ? `${APIROUTES.GET_DYNAMIC_REPORTS_MODULE_FIELD}?module=${module}`
      : `${APIROUTES.GET_DYNAMIC_REPORTS_MODULE_FIELD}?module=${module}&master=${masterDropdownValue === null || masterDropdownValue === undefined ? MASTERDROPDOWN[0] : masterDropdownValue.value}`
    setAllHeaderLoader(true)
    axios
      .get(url, getOptions(props.user))
      .then((response) => {
        if (response.status === 200) {
          let data = response.data
          const groupedDataSorted = arraySortBykeyAllBrowser(handleSortWithGroup(data), 'category')

          setShowAllArray(groupedDataSorted)
          let tabTitle = handleTabValueTitle()
          setTabAllHeadersData(prevValue => {
            return {
              ...prevValue,
              [tabTitle]: { allHeaders: arraySortBykeyAllBrowser(data, 'group') }
            }
          })
          setAllHeaderLoader(false)
        }
      })
      .catch((error) => {
        console.log("5")
        setAllHeaderLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000)
      });
  }

  const fetchFilterType = (module) => {
    axios
      .get(`${APIROUTES.GET_DYNAMIC_REPORTS_FILTER_TYPES}`, getOptions(props.user))
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setFilterType(data)
        }
      })
      .catch((error) => {
        console.log("6")
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000)
      });
  }

  const handleCancelResetRequestReport = (type, isRevised = '') => {
    setTableDataLoader(true)
    let tabTitle = handleTabValueTitle()
    let url = isRevised === 'is_revised'
      ? `${APIROUTES.CANCEL_RESET_DYNAMIC_REPORTS}?module=${tabTitle}&revised=true`
      : `${APIROUTES.CANCEL_RESET_DYNAMIC_REPORTS}?module=${tabTitle}`

    axios.get(url, getOptions(props.user))
      .then((response) => {
        if (response.status === 200) {
          handleActiveTemplate(isRevised === 'is_revised')
          handleOpenSnackbar(`${type === 'cancel'
            ? 'Requested report cancel'
            : 'Requested report reset'} successfully`, "success");
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000)
        }
      })
      .catch((error) => {
        console.log("7")
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000)
      });
  }

  const fetchSavedTemplate = (module) => {
    let url = module !== 'MASTERS'
      ? `${APIROUTES.GET_DYNAMIC_REPORTS_SAVED_TEMPLATE}?module=${module}`
      : `${APIROUTES.GET_DYNAMIC_REPORTS_SAVED_TEMPLATE}?module=${module}&master=${masterDropdownValue === null || masterDropdownValue === undefined ? MASTERDROPDOWN[0] : masterDropdownValue.value}`
    setButtonClickLoader('fetchSavedTemplate')
    setSavedTemplate([])
    axios
      .get(url, getOptions(props.user))
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setSavedTemplate(data)
          setButtonClickLoader(false)
        }
      })
      .catch((error) => {
        console.log("8")
        setButtonClickLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000)
      });
  }

  const handleApplyTemplate = (tempId) => {
    let tabTitle = handleTabValueTitle()
    setTableDataLoader(true)

    axios.get(`${APIROUTES.APPLY_TEMPLATE_DYNAMIC_REPORTS}${tempId}/`, getOptions(props.user))
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          handlePrepareTableData(data.field_data, data)
          setIsTemplateDataChanged(prevValue => { return { ...prevValue, [handleTabValueTitle()]: { is_changed: false } } })
          setTabAppliedTemplate(prevValue => {
            return {
              ...prevValue,
              [tabTitle]: { appiledtemplateData: data }
            }
          })
          setTabActiveTemplate(prevValue => {
            return {
              ...prevValue,
              [tabTitle]: { activeTemplateData: [] }
            }
          })
        }
      })
      .catch((error) => {
        console.log("9")
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000)
      });
  }

  const handleSavedTemplatePinUnpin = (id, type) => {
    // console.log(id, type,)
    let tabTitle = handleTabValueTitle()
    if (type === 'pinned') {
      let data =
      {
        module: tabTitle,
        template: id,
        user: props.user.user_id
      }

      axios.post(`${APIROUTES.PINNED_TEMPLATE_DYNAMIC_REPORTS}`, data, getOptions(props.user))
        .then((response) => {
          if (response.status === 201) {
            fetchSavedTemplate(tabTitle)
            handleOpenSnackbar("Template pinned successfully", "success");
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000)
          }
        })
        .catch((error) => {
          console.log("10")
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000)
        });
    }
    else if (type === 'unpinned') {
      axios.get(`${APIROUTES.UNPINNED_TEMPLATE_DYNAMIC_REPORTS}${id}/`, getOptions(props.user))
        .then((response) => {
          if (response.status === 200) {
            fetchSavedTemplate(tabTitle)
            handleOpenSnackbar("Template unpinned successfully", "success");
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000)
          }
        })
        .catch((error) => {
          console.log("11")
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error?.response)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000)
        });
    }
  }


  const handleDeleteSavedTemplate = (id) => {
    console.log("called", id)
    setButtonClickLoader('deleteTemplate')
    let tabTitle = handleTabValueTitle()
    // console.log(id)
    axios.get(`${APIROUTES.DELETE_TEMPLATE_DYNAMIC_REPORTS}${id}/`, getOptions(props.user))
      .then((response) => {
        if (response.status === 200) {
          fetchSavedTemplate(tabTitle)
          handleOpenSnackbar("Template deleted successfully", "success");
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000)
          setButtonClickLoader(false)
        }
      })
      .catch((error) => {
        console.log("12")
        setButtonClickLoader(false)
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error?.response)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000)
      });
  }

  // if (!isAccessibleModule(props.user, "Dynamic Report")) return <AccessDenied />;

  return (
    <>
      <DynamicReportsWeb
        {...props}
        user={props.user}
        isMobile={props.isMobile}
        handleTabValue={handleTabValue}
        filterType={filterType}
        showAllArray={showAllArray}
        savedTemplate={savedTemplate}
        tabActivetemplate={tabActivetemplate}
        handleFilterTypes={handleFilterTypes}
        handleCalculationTypes={handleCalculationTypes}
        handleModuleHeaderAdd={handleModuleHeaderAdd}
        handleModuleHeaderRemove={handleModuleHeaderRemove}
        handleModuleManyHeaderAddRemove={handleModuleManyHeaderAddRemove}
        templateSearchbar={templateSearchbar}
        handleTemplateSearchBar={handleTemplateSearchBar}
        handleTableDataOrder={handleTableDataOrder}
        handleRequestReportClick={handleRequestReportClick}
        handleCancelResetRequestReport={handleCancelResetRequestReport}
        handleSaveTemplateClick={handleSaveTemplateClick}
        handleApplyTemplate={handleApplyTemplate}
        handleSavedTemplatePinUnpin={handleSavedTemplatePinUnpin}
        handleDeleteSavedTemplate={handleDeleteSavedTemplate}
        handleTableDataIsChangedValidation={handleTableDataIsChangedValidation}
        tabAppliedTemplate={tabAppliedTemplate}
        handleTableDataChangeValidation={handleTableDataChangeValidation}
        FetchMasterFilterValueSearchApiData={FetchMasterFilterValueSearchApiData}
        orderByData={orderByData}
        handleTableOrderBy={handleTableOrderBy}
        buttonClickLoader={buttonClickLoader}
        filterValueApiLoader={filterValueApiLoader}

        handleTabValueTitle={handleTabValueTitle}
        allHeaderLoader={allHeaderLoader}
        tabAllHeadersData={tabAllHeadersData}
        tableDataLoader={tableDataLoader}
        tabSelectedHeadersTableData={tabSelectedHeadersTableData}
        tabSelectedHeadersIdData={tabSelectedHeadersIdData}

        MASTERDROPDOWN={MASTERDROPDOWN}
        masterDropdownValue={masterDropdownValue}
        handleMasterDropdownChange={handleMasterDropdownChange}

        handleOpenSnackbar={handleOpenSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <DialogComponent />
      <DialogMultiRowWarningComponent />

      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </>
  )
}


export default withUser(DynamicReports);
