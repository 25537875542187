import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
  secondaryColor,
} from "../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = {
  registerPanAddressBody: {
    height: window.innerHeight - 56,
    padding: "0px 20px ",
    overflowY: "scroll",
  },
  registerPanAddressTop: {
    height: "90px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "15px",
  },
  registerPanAddressSecondTop: {
    display: "flex",
    flexDirection: "column",
  },
  registerPanAddressFormContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  textFieldStyle: {
    width: "100%",
  },
  formControlStyle: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
};
class RegistrationPanAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId: null,
      stateId: null,
    };
  }
  handleSelectCountry = (id) => {
    this.setState({ countryId: id }, () => {
      this.props.getStatesFromMasterData(id);
    });
  };
  handleStateCountry = (id) => {
    // console.log(id)
    // this.setState({ stateId: id }, () => {
    //   this.props.getCityFromMasterData(id);
    // });
  };
  handleSelectCity = (id) => {
    this.props.handleSelectCity(id);
  };
  render() {
    const { countryId, stateId, panAdressPincodeLengthError } = this.state;
    const {
      classes,
      panAddressLineOne,
      panAddressLineTwo,
      panAddressLineThree,
      panAdressPincode,
      countries,
      states,
      cities,
      panNumber,
      panAddresssCity,
      panAddresssState,
    } = this.props;
    // console.log(panAddresssCity, panAddresssState)
    return (
      <div className={classes.registerPanAddressBody}>
        <div className={classes.registerPanAddressTop}>
          <span style={{ color: dailyRateScreenTextColor, fontSize: "1.2em" }}>
            Address Detail
          </span>
          <span style={{ color: bottomNavigationFontColor }}>
            You can add multiple address later in setting
          </span>
        </div>
        <div className={classes.registerPanAddressSecondTop}>
          <span style={{ color: bottomNavigationFontColor, fontSize: "1.1em" }}>
            Kindly add the address linked with
          </span>
          <span style={{ color: secondaryColor, fontSize: "1.3em" }}>
            PAN: {panNumber}
          </span>
        </div>
        <div className={classes.registerPanAddressFormContainer}>
          <TextField
            name="panAddressLineOne"
            id="outlined-name"
            label="Address Line 1*"
            margin="normal"
            multiline
            maxRows={3}
            variant="outlined"
            value={panAddressLineOne}
            className={classes.textFieldStyle}
            onChange={this.props.handleTextChange}
          />
          <TextField
            name="panAddressLineTwo"
            id="outlined-name"
            label="Address Line 2 (Optional)"
            margin="normal"
            multiline
            maxRows={3}
            variant="outlined"
            value={panAddressLineTwo}
            className={classes.textFieldStyle}
            onChange={this.props.handleTextChange}
          />
          <TextField
            name="panAddressLineThree"
            id="outlined-name"
            label="Address Line 3 (Optional)"
            margin="normal"
            multiline
            maxRows={3}
            variant="outlined"
            value={panAddressLineThree}
            className={classes.textFieldStyle}
            onChange={this.props.handleTextChange}
          />

          <TextField
            name="panAdressPincode"
            id="outlined-name"
            label="Pincode*"
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 6,
            }}
            value={panAdressPincode}
            className={classes.textFieldStyle}
            onChange={(e) => {
              this.props.handleTextChange(e)

            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            // onBlur={() => {
            //   if (panAdressPincode.length < 6) {
            //     this.setState({ panAdressPincodeLengthError: true });
            //   }
            // }}
            // onFocus={() => {
            //   this.setState({ panAdressPincodeLengthError: false });
            // }}
            helperText={
              <span style={{ color: "red" }}>
                {panAdressPincode.length === 0
                  ? null
                  : panAdressPincode.length === 6 && (panAddresssState === '' && panAddresssCity === '')
                    ? 'Pincode not available'
                    : panAdressPincode.length === 6 && (panAddresssState !== '' && panAddresssCity !== '')
                      ? null
                      : "Please enter valid 6 digit pincode number"
                }
              </span>
            }
            error={panAdressPincode.length === 6 || panAdressPincode.length === 0 ? false : true}
          />
          <FormControl
            variant="outlined"
            className={classes.formControlStyle}
            size="small"
          >
            <InputLabel htmlFor="age-simple" style={{ marginTop: "7px" }}>
              Select Country*
            </InputLabel>
            <Select label="Select Country*" style={{ height: "54px" }} value={"India"} disabled>
              {countries.map((option) => (
                <MenuItem
                  value={option.name}
                  onClick={(e) => {
                    e.preventDefault();

                    this.handleSelectCountry(option.id);
                  }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            className={classes.formControlStyle}
            size="small"
          // disabled={countryId === null}
          >
            <InputLabel htmlFor="age-simple" style={{ marginTop: "7px" }} >
              State*
            </InputLabel>
            <Select
              name="panAddresssState"
              label="State*"
              style={{ height: "54px" }}
              value={panAddresssState || ""}
              onChange={(e) => {
                e.preventDefault();
                this.props.handleSelect(e);
              }}
              disabled>
              {states.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                // onClick={(e) => {
                //   e.preventDefault();
                // this.handleStateCountry(option.id);
                // }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            className={classes.formControlStyle}
            size="small"
          // disabled={stateId === null}
          >
            <InputLabel htmlFor="age-simple" style={{ marginTop: "7px" }}>
              City*
            </InputLabel>
            <Select
              name="panAddresssCity"
              label="City*"
              style={{ height: "54px" }}
              value={panAddresssCity || ""}
              onChange={(e) => {
                e.preventDefault();
                this.props.handleSelect(e);
              }}
              disabled>
              {cities.map((option) => (
                <MenuItem
                  value={option.id}
                // onClick={(e) => {
                //   e.preventDefault();
                //   this.handleSelectCity(option.id);
                // }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField
            name="panAdressPincode"
            id="outlined-name"
            label="Pincode"
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 6,
            }}
            value={panAdressPincode}
            className={classes.textFieldStyle}
            onChange={(e) => {
              this.props.handleTextChange(e)

            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            // onBlur={() => {
            //   if (panAdressPincode.length < 6) {
            //     this.setState({ panAdressPincodeLengthError: true });
            //   }
            // }}
            // onFocus={() => {
            //   this.setState({ panAdressPincodeLengthError: false });
            // }}
            helperText={
              <span style={{ color: "red" }}>
                {panAdressPincode.length === 6 || panAdressPincode.length === 0 ? null
                  : "Please enter valid 6 digit pincode number"}
              </span>
            }
            error={panAdressPincode.length === 6 || panAdressPincode.length === 0 ? false : true}
          /> */}
          {/* <TextField
            name="panAddressLineOne"
            id="outlined-name"
            label="Country"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          /> */}
          {/* <TextField
            name="panAddressLineOne"
            id="outlined-name"
            label="State"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          /> */}
          {/* <TextField
            name="panAddressLineOne"
            id="outlined-name"
            label="City"
            margin="normal"
            variant="outlined"
            className={classes.textFieldStyle}
          /> */}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegistrationPanAddress);
