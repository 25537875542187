import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  Button,
  TextField,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  secondaryGrey,
  drawerTextColor,
  grey,
  whiteSmoke,
  successButtonBackground,
} from '../../../../constants/internal/colors';

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import moment from 'moment';
import { checkApiError, pxToEm } from '../../../../methods';
import SnackbarComponent from '../../common/SnackbarComponent';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: 'bold',
  },
  tableBody: {
    fontSize: pxToEm(14),
  },
  tableBodyWithIcon: {
    fontSize: pxToEm(14),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alignText: {
    display: 'flex',
    placeContent: 'baseline',
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: 'bold',
  },
  container: {
    marginTop: 10,
  },
  removeOption: {
    color: secondaryGrey,
    fontSize: pxToEm(13),
  },
  addNewButton: {
    marginTop: 10,
    width: '181px',
    height: '43px',
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: '4px',
    opacity: '1',
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tableRowAlignment: {
    placeItems: 'baseline',
  },
  icon: {
    height: 18,
    width: 18,
    margin: '0px 5px 0px 5px',
    color: grey,
  },
  iconContainer: {
    height: 30,
    width: 30,
    // background: 'yellow',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: whiteSmoke,
    overflow: 'hidden',
    transition: 'width 0.3s ease-in-out',
    cursor: 'pointer',
    color: grey,
    '&:hover': {
      backgroundColor: whiteSmoke,
      width: 90,
      // overflow: 'visible',
    },
  },
  actionLabel: {
    flex: 1,
    marginLeft: 5,
    textAlign: 'left',
  },
});
export default function RoleAssignedToTable({
  role,
  users,
  addAUserToARole,
  deleteAUserFromARole,
  getARoleAndPermissions,
}) {
  const classes = useStyles();
  const [addNew, toggleAddNew] = React.useState(false);
  const [assignedUsers, setAssignedUsers] = React.useState(
    role && role.users ? role.users : []
  );
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });

  React.useEffect(() => {
    if (role && role.users) setAssignedUsers(role.users);
  }, [role, role?.users]);

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  function handleAddNewUser(e) {
    toggleAddNew(true);
  }

  function handleCancel(e) {
    toggleAddNew(false);
  }

  const handleSearch = (e, newInputValue, data) => {
    const user = data.find((e) => e.fullName === newInputValue);
    if (user) {
      const newUsersList = assignedUsers;
      newUsersList.push({
        ...user,
        first_name: user.firstName,
        last_name: user.lastName,
        status: 'new',
      });
      setAssignedUsers(newUsersList);
      toggleAddNew(false);
      addAUserToARole({
        role: role.id,
        assigned_to: user.id,
      })
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Role Assigned To User Successfully`,
              "success"
            );
            setTimeout(() => {
              getARoleAndPermissions(role.id);
              handleCloseSnackbar()
            }, 4000);
          }
          else {
            handleOpenSnackbar(
              `Error occured while assigning role to user please try again`,
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar()
            }, 4000);
          }
        })
        .catch((error) => {
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
      //   getARoleAndPermissions(role.id);
      // })
      // .catch((e) => {
      //   console.error(e);
      //   let removedList = assignedUsers;
      //   removedList = removedList.filter((e) => !Boolean(e.status));
      //   setAssignedUsers(removedList);
      // });
    }
  };

  function removeAUserFromARole(id) {
    let removedList = assignedUsers;
    removedList = removedList.map((e) => ({
      ...e,
      status: e.id === id ? 'removed' : undefined,
    }));
    setAssignedUsers(removedList);
    deleteAUserFromARole(id)
      .then((response) => {
        if (response.status === 204) {
          handleOpenSnackbar(
            `Role Assigned To User Removed Successfully`,
            "success"
          );
          setTimeout(() => {
            getARoleAndPermissions(role.id);
            handleCloseSnackbar()
          }, 4000);
        }
        else {
          handleOpenSnackbar(
            `Error occured while removing role assigned to user please try again`,
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar()
          }, 4000);
        }
      })
      .catch((error) => {
        handleOpenSnackbar(
          handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          handleCloseSnackbar();
        }, 4000);
      });
    // getARoleAndPermissions(role.id);
    // })
    // .catch(console.error);
  }

  return (
    <>
      <span className={classes.contentTitle}>ROLE ASSIGNED TO</span>
      <TableContainer component={Paper} className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="products-state-price-table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableHead}>
                NAME
              </TableCell>
              <TableCell align="left" className={classes.tableHead}>
                EMAIL
              </TableCell>
              <TableCell align="left" className={classes.tableHead}>
                ASSIGNED ON
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignedUsers.length > 0
              ? assignedUsers.map((employee, index) => {
                return (
                  <TableRow key={index} className={classes.tableRowAlignment}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.tableBody}
                    >
                      {`${employee.first_name} ${employee.last_name}`}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      <span className={classes.tableBody}>
                        {employee.email}
                      </span>
                    </TableCell>

                    <TableCell align="left" className={classes.tableBody}>
                      <div className={classes.tableBodyWithIcon}>
                        <span className={classes.tableBody}>
                          {employee.status === 'new'
                            ? 'Adding..'
                            : employee.status === 'removed'
                              ? 'Removing..'
                              : moment(employee.created_at).format('DD MMM YYYY')}
                        </span>
                        {employee.status !== 'new' &&
                          employee.status !== 'removed' && (
                            <div
                              className={classes.iconContainer}
                              onClick={(e) => {
                                e.preventDefault();
                                removeAUserFromARole(employee.id);
                              }}
                            >
                              <CancelIcon className={classes.icon} />
                              <div
                                style={{
                                  flex: 1,
                                  marginLeft: 5,
                                  textAlign: 'left',
                                }}
                              >
                                remove
                              </div>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
              : null}
            {addNew && (
              <TableRow key={'new'} className={classes.tableRowAlignment}>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  className={classes.tableBody}
                >
                  <Autocomplete
                    options={users.filter(
                      (user) =>
                        !Boolean(
                          assignedUsers.find(
                            (assigned) => assigned.employee_id === user.employee_id
                          ) ||
                          !user.is_active
                        )
                    )}
                    getOptionLabel={(opt) => opt.fullName}
                    onInputChange={(e, value) => handleSearch(e, value, users)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={'Search users'}
                        margin="none"
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tableBody}>
                  <span className={classes.tableBody}>{null}</span>
                </TableCell>

                <TableCell align="left" className={classes.tableBody}>
                  <div className={classes.tableBodyWithIcon}>
                    <span onClick={handleCancel} className={classes.tableBody}>
                    </span>
                    <div className={classes.iconContainer} onClick={handleCancel}>
                      <CancelIcon className={classes.icon} />
                      <div className={classes.actionLabel}>cancel</div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!addNew && (
        <Button
          variant="outlined"
          size="medium"
          startIcon={<CheckOutlinedIcon />}
          className={classes.addNewButton}
          onClick={handleAddNewUser}
        >
          ADD NEW
        </Button>
      )}
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </>
  );
}
