import HOST from "../common/host";

const B2b_URL = {
  HOST,
  DAILY_RATES: HOST + "api/daily-rates/v2/client/", //"api/daily-rates/client/",
  PHONE_EXIST: HOST + "api/registration/check/phone/",
  EMAIL_EXIST: HOST + "api/registration/check/email/",
  CUSTOMER_REGISTRATION: HOST + "api/registration/customer/",
  PHONE_OTP_SEND: HOST + "api/registration/phone/verification/",
  CHANGE_PASSWORD: HOST + "api/auth/change-password/",
  OTP_SEND_IN_EMAIL: HOST + "api/registration/email/verification/",
  GET_GST_INFO: HOST + "api/registration/check/gst/",
  NOTIFICATIONS:
    // "https://cors-anywhere.herokuapp.com/" +
    HOST + "api/notifications/V2/notification",
  CUSTOMER_PHONE_GET_OTP: HOST + "/api/auth/customer-phone-auth/get-otp/",
  CUSTOMER_EMAIL_GET_OTP: HOST + "/api/auth/customer-email-auth/get-otp/",
  CUSTOMER_PHONE_VERIFY_OTP: HOST + "/api/auth/customer-phone-auth/verify-otp/",
  CUSTOMER_EMAIL_VERIFY_OTP: HOST + "/api/auth/customer-email-auth/verify-otp/",
  CUSTOMER_REGISTRATION_GET_OTP: HOST + "/api/auth/auth-for-register/get-otp-apk/",
  CUSTOMER_REGISTRATION_VERIFY_OTP: HOST + "/api/auth/auth-for-register/verify-otp-apk/",
  BANK_MASTER: HOST + "api/masters/bank/",
  GET_COUNTRIES: HOST + "api/masters/countries/",
  GET_STATES: HOST + "api/masters/states/?country-id=",
  GET_CITIES: HOST + "api/masters/cities/?state_id=",
  CONTACT_PERSON: HOST + "api/customers/contact-person/bulk-create/",
  GET_PAYMENT_METHOD: HOST + "api/customers/payment-method/?id=",
  GET_ORDERS_LIST: HOST + "api/contracts/customer/list/order/",
  GET_PENDING_ORDERS: HOST + "api/contracts/customer/pending/list/order/",
  CREATE_ORDER: HOST + "api/contracts/customer/create/order/",
  GET_ORDER_DETAILS: HOST + "api/contracts/customer/retrieve/order/",
  GET_PLANT_PLANNING_DETAILS: HOST + "api/contracts/customer/plant-planning/",
  GET_PLANNED_ITEM_DETAILS: HOST + "api/contracts/customer/sub-contract/data/",
  GET_SINGLE_ORDER_LA: HOST + "api/contracts/customer/list/loading-advice/",
  GET_LA_DETAILS: HOST + "api/contracts/customer/retrieve/loading-advice/",
  GET_PENDING_DISPATCH_LIST: HOST + "api/contracts/customer/plant-planning/all/",
  GET_SINGLE_PLANT_PLANNING: HOST + "api/contracts/customer/plant-planning/",
  GET_BUYERS_LIST: HOST + "api/contracts/buyers/",
  GET_CONSIGNEES_LIST: HOST + "api/contracts/consignee/",
  CREATE_LA: HOST + "api/contracts/customer/create/loading-advice/",
  UPDATE_LA: HOST + "api/contracts/customer/loading-advice/update/detail/",
  CANCEL_LA: HOST + "api/contracts/customer/loading-advice/cancel/",
  GET_ITEMS: HOST + "api/masters/item-categories/items/",
  GET_TAG_WISE_ITEMS: HOST + "api/contracts/customer/tag/list/items/",
  GET_SINGLE_ORDER_INVOICES: HOST + "api/contracts/customer/list/invoices/",
  GET_INVOICES_PAYMENT_DETAIL: HOST + "api/contracts/customer/payment/detail/",
  APPROVE_CONTRACT: HOST + "api/contracts/customer/update-status/order/",
  GET_ALL_LA: HOST + "api/contracts/customer/list/loading-advice/all/",
  GET_TRANS_TYPE_MASTER: HOST + "api/masters/trantype/",
  GET_TRANSPORTERS: HOST + "api/masters/transporter/",
  GET_PLANTS: HOST + "api/masters/plants/",
  READNOTIFICATION: HOST + "api/notifications/notification/bulk/",
  MARK_ALL_READ_NOTIFICATION: HOST + "api/notifications/notification/mark_all_read/",
  FETCH_ORDERS_FOR_DROPDOWN: HOST + "api/contracts/dropdown/list/",
  RETRIEVE_ORDER_DETAILS: HOST + "api/contracts/customer/retrieve/order/",
  GET_STATE_AND_CITY: HOST + "api/masters/pincode/pincode_detail/",
  GET_REASON: HOST + "api/masters/reason/",
  GET_BANNER: HOST + "api/masters/news/",
  GET_ITEM_CATEGORIES_PAYMENT_DISCOUNT: HOST + "api/masters/item-categories/payment-method-wise-discount/",
  GET_LEDGER_REQUEST: HOST + "api/ledger/customer/request/",
  RESET_LEDGER_REQUEST: HOST + "api/ledger/customer/ledger_reset/",
  GET_PLANT_SUBCONTRACT_DETAIL: HOST + "api/contracts/customer/subcontarct/stepper-data/",
  GET_SUBCONTRACT_LIST: HOST + "api/contracts/customer/list/subcontract/",
  GET_SUBCONTRACT_ITEM_LIST: HOST + "api/contracts/customer/master/item-data/",
};

export default B2b_URL;
