import React, { Component } from "react";
// import OpenInDesktop from "../../../components/internal/common/OpenInDesktop";
// import { Route } from "react-router-dom";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import OpenInDesktop from "../../../components/internal/common/OpenInDesktop";
import CreateLoadingAdvice from "../../../components/internal/contracts/web/newUi/CreateLoadingAdvice";
import { getThemeProps } from "@mui/system";
import { checkApiError } from "../../../methods";
import SnackbarComponent from "../../../components/internal/common/SnackbarComponent";



class InternalLoadingAdvicesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderValidity: 10,
      orderValidityDate: this.generateOrderValidityDate(new Date(), 10),
      selectedCustomerPaymentTerms: [],
      buyerSelectionList: [],
      consigneeSelectionList: [],
      itemDetailsData: [],
      driverLicenceNumber: null,
      selectedOrderId: null,
      allOrders: [],
      orderDetails: [],
      orderDetailsLoader: true,
      modeOfTransport: null,
      gateInDate: new Date(),
      paymentTerm: null,
      laDetails: [],
      allPaymentTermsList: {
        allotted_payment_method: [],
        not_allotted_payment_method: [],
      },
      allPaymentTermsListLoader: true,
      allBuyersList: [],
      allConsigneeList: [],
      allItemCategoryList: { subscribed: [], unsubscribed: [] },
      plantsList: [],
      allItemsList: [],
      tranportersList: [],
      laItemsList: [],
      laItemsListLoader: false,
      laOperation: null,
      itemCategoryData: {},
      categoryCityDiscountData: [],
      categoryPaymentMethodDiscountData: [],
      allPlantsData: [],
      tcsRate: {},
      allTcsRates: [],
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      isLoading: false,
      subcontractRows: [],
      freightBasis: []
    };
  }

  _isMounted = false;
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentDidMount() {
    console.log(this.props)
    let orderId = this.props.match.params.orderId;
    let laId = this.props.match.params.laId;
    let laRowData = this.props?.location?.state?.laRowData;
    let laOperation = this.props?.location?.pathname?.includes(
      "editLoadingAdvice"
    ) || this.props?.location?.pathname?.includes(
      "overviewLoadingAdvice"
    )
      ? "edit"
      : "add";


    this.setState({ selectedOrderId: orderId, laOperation }, () => {
      this.fetchOrderData();
      this.hanldleFetchOrderPlantPlanning();
    });
    if (laOperation === "edit") {
      this.setState({ selectedLaId: laId }, () => {
        this.handleFetchLoadingAdviceFromOrderId(laRowData);
      });
    }
    this.handleFetchTransporterData();
    this.handleFetchAllPlantData();
    this.handleFetchAllTcsData();
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  fetchOrderDetails = (orderId) => {
    this.props
      ?.fetchOrderData(orderId)
      ?.then((response) => {
        if (response.status === 200) {
          let orderData = response.data;
          this.setState(
            {
              newSelectedOrder: orderData,
              orderDetailsLoader: false,
              // laDetails: { ...this.state.laDetails, order: orderData?.id }
            }
          );
        }
      })
  }

  fetchOrderData = () => {
    let orderId = this?.state?.selectedOrderId;
    this.props
      ?.fetchOrderData(orderId)
      ?.then((response) => {
        if (response.status === 200) {
          let orderData = response.data;
          //console.log(orderData)
          this.setState(
            {
              orderDetails: orderData,
              orderDetailsLoader: false,
            }
          );
          this.handleFetchAllPaymentTermsList(orderData?.customer?.id);
          this.handleFetchBuyerForCustomer(orderData?.customer?.id);
          this.handleFetchConsigneeForCustomer(orderData?.customer?.id);
          this.fetchAllItemCategories(orderData?.customer?.id);
          //this.handleFetchTcsRateData()
          // if (this?.state?.laOperation === "add") {\
          this.handleFetchItemCategory(orderData?.item_category?.id);
          this.handleFetchItemsForCategory(orderData?.item_category?.id);
          this.handleFetchPaymentMethodDiscountForCategory(
            orderData?.item_category?.id
          );
          this.handleFetchCityDiscountForCategory(orderData?.item_category?.id);
          // }
        } else {
          this.setState({
            orderDetailsLoader: false,
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching contract data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          orderDetailsLoader: false,
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching contract data `,
          snackbarType: "error",
        });
      });
  };
  handleFetchAllTcsData = () => {
    axios
      .get(APIROUTES.TCS, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          this.setState({ allTcsRates: response.data });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching all tcs rates",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchLoadingAdviceFromOrderId = (laRowData) => {
    this.setState(
      { laDetails: laRowData },
      () => {
        this.handleFetchItemsForCategory(this.props?.location?.pathname?.includes("dispatchPlanning") ? this.props?.location?.state?.itemId : laRowData?.item_category?.id);
        this.handleFetchLaItemsList();
      })
  }

  handleFetchLaItemsList = () => {
    let laId = this.state.selectedLaId;
    this.setState({ laItemsListLoader: true })
    axios
      .get(
        `${APIROUTES.CONTRACTS_LA_ITEMS_LIST}?loading_advice_id=${laId}`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log(
        //   "handleFetchLoadingAdviceFromOrderId response : ",
        //   response
        // );
        if (response.status === 200) {
          let laItemsList = response.data;
          this.setState({ laItemsList, laItemsListLoader: false });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching loading advice items",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
      });
  };
  handleFetchAllPlantData = () => {
    axios
      .get(
        APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          console.log(response.data)
          this.setState({ allPlantsData: response.data });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching all plants data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchTransporterData = () => {
    axios
      .get(`${APIROUTES.TRANSPORTERS}?active=true`, this.getOptions(this.props.user))
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ tranportersList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching transporters data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleCreateItemData = (itemData) => {
    axios
      .post(
        APIROUTES.CREATE_LOADING_ADVICE_ITEM,
        itemData,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleCreateItemData response : ", response);
        if (response.status === 200) {
          // this.setState({ tranportersList: response.data }, () =>
          //   console.log(1)
          // );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while creating loading advice item ",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };

  handleFetchTcsRateData = (customerId, itemId) => {
    this.props?.fetchTcsRate(customerId, itemId)
      .then((response) => {
        if (response.status === 200) {
          //console.log(response);
          this.setState({
            tcsRate: response.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching tcs rate or plants",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.response?.data?.details
            ? error?.response?.data?.details
            : error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error occured while fetching tcs rate or plants",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };

  handleUpdateItemData = (itemData) => {
    let laNumber = this?.state?.laDetails?.la_number;
    // console.log("handleUpdateItemData : ", itemData);
    // console.log("handleUpdateItemData : ", laNumber);
    axios
      .patch(
        `${APIROUTES.UPDATE_LOADING_ADVICE_ITEM}${laNumber}/`,
        [itemData],
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleUpdateItemData response : ", response);
        if (response.status === 200) {
          // this.setState({ tranportersList: response.data }, () =>
          //   console.log(1)
          // );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while creating loading advice item ",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error.response", error.response);
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  fetchAllItemCategories = (customerId) => {
    this.props
      .fetchItemCategories(customerId)
      .then((response) => {
        // console.log("fetchItemCategories : ", response.data);
        if (response.status === 200) {
          this.setState(
            {
              allItemCategoryList: response.data,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all consignee list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all consignee list`,
          snackbarType: "error",
        });
      });
  };
  handleFetchConsigneeForCustomer = (customerId) => {
    this.props
      .fetchConsignees(customerId)
      .then((response) => {
        // console.log("fetchConsignees : ", response.data.results);
        if (response.status === 200) {
          this.setState(
            {
              allConsigneeList: response.data.results,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all consignee list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all consignee list`,
          snackbarType: "error",
        });
      });
  };
  handleFetchItemsForCategory = (itemCategoryId) => {
    console.log(itemCategoryId)
    this.props
      ?.fetchItemsData(itemCategoryId)
      ?.then((response) => {
        // console.log("fetchItemsData : ", response.data);
        if (response.status === 200) {
          this.setState(
            {
              allItemsList: response.data,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all items list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all items list`,
          snackbarType: "error",
        });
      });
  };
  handleFetchPaymentMethodDiscountForCategory = (itemCategoryId) => {
    axios
      .get(
        `${APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT}?id=${itemCategoryId}`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState(
            { categoryPaymentMethodDiscountData: response.data }
          );
        }
      })
      .catch((error) => {
        // console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching payment method discount",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchCityDiscountForCategory = (itemCategoryId) => {
    axios
      .get(
        `${APIROUTES.ITEM_CATEGORY_CITY_DISCOUNT}?id=${itemCategoryId}`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ categoryCityDiscountData: response.data }
          );
        }
      })
      .catch((error) => {
        // console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching city discount data",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  handleFetchItemCategory = (itemCategoryId) => {
    axios
      .get(
        `${APIROUTES.MM_CREATE_NEW_CATEGORY}${itemCategoryId}/`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        // console.log("handleFetchTransporterData response : ", response);
        if (response.status === 200) {
          this.setState({ itemCategoryData: response.data }
          );
        }
      })
      .catch((error) => {
        // console.log("error.response", error.response);
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching item category",
          snackbarType: "error",
        });
        // this.setState({ itemDataFetchLoader: true });
      });
  };
  hanldleFetchOrderPlantPlanning = () => {
    let orderId = this?.state?.selectedOrderId;
    this.props
      ?.fetchOrderPlantPlanning(orderId)
      ?.then((response) => {
        // console.log("plantsList : ", response.data);
        if (response.status === 200) {
          this.setState(
            {
              plantsList: response.data.results,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all buyers list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all buyers list`,
          snackbarType: "error",
        });
      });
  };
  handleFetchBuyerForCustomer = (customerId) => {
    this.props
      .fetchBuyers(customerId)
      .then((response) => {
        // console.log("fetchBuyers : ", response.data.results);
        if (response.status === 200) {
          this.setState(
            {
              allBuyersList: response.data.results,
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all buyers list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all buyers list`,
          snackbarType: "error",
        });
      });
  };
  handleLaDataSaveEdit = (laDetails, isCreatedByCustomer = false, plantId = null, selectedDate = null) => {
    this.setState({
      isLoading: true
    })

    let laOperation = this?.state?.laOperation;
    //console.log("laOperation: ", this.props);
    // console.log("laDetails : ", laDetails);
    if (laOperation === "add") {
      this.props
        .createLa(laDetails)
        .then((response) => {
          // console.log("createLa : ", response);
          if (response.status === 201) {
            this.props.history.push(
              {
                pathname: `/internalContracts/singleContracts/${this?.state?.selectedOrderId}`,
                state: { isApproved: laDetails.is_approved }
              }
            );
          } else {
            this.setState({
              openSnackbar: true,
              isLoading: false,
              snackbarMessage: response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : `Error occured while fetching all buyers list`,
              snackbarType: "error",
            });
          }
        })
        .catch((error) => {
          // console.log(error.response.data)
          this.setState({
            isLoading: false
          })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 5000);
          // this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: error?.data?.details
          //     ? error?.data?.details
          //     : error?.data?.message
          //       ? error?.data?.message
          //       : `Error occured while fetching all buyers list`,
          //   snackbarType: "error",
          // })
        });
    } else if (laOperation === "edit") {

      if (laDetails.items.length > 0) {
        this.props
          ?.updateLa(laDetails, this.state.selectedLaId)
          .then(async (response) => {
            // console.log("fetchBuyers : ", response.data.results);
            if (response.status === 200) {
              if (isCreatedByCustomer && (this.props.status === "PENDING" || this.props?.location?.state?.status === "PENDING")) {
                let laStatusUpdateData = {
                  dispatch_status: "APPROVED",
                  dispatch_remark: "",
                };
                this.props.handleLaApprove(this.state.selectedLaId, laStatusUpdateData);
              }
              let localPlantId = this.props.plantId ?? plantId;
              let localSelectedDate = this.props.date ?? selectedDate;
              if (this.props.location.pathname.indexOf('internalContracts') > -1 ||
                this.props.location.pathname.indexOf('dispatchPlanning') > -1) {
                if (this.props.location.pathname.indexOf('internalContracts') > -1) {
                  this.setState({
                    openSnackbar: true,
                    snackbarMessage: "LA Approved Successfully",
                    snackbarType: "success",
                  });
                }

                await new Promise(r => setTimeout(r, 3000));

                this.props.history.goBack();

              } else {
                this.props.history.push(
                  `/internalLoadingAdvices/${localPlantId}`
                );
              }

              this.props.handleDateChangeLA(localSelectedDate)
            } else {
              this.setState({
                openSnackbar: true,
                snackbarMessage: response?.data?.details
                  ? response?.data?.details
                  : response?.data?.message
                    ? response?.data?.message
                    : `Error occured while fetching all buyers list`,
                snackbarType: "error",
              });
            }
          })
          .catch((error) => {
            this.setState({
              isLoading: false
            })
            console.log(error);
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 5000);
            // this.setState({
            //   openSnackbar: true,
            //   snackbarMessage: error?.data?.details
            //     ? error?.data?.details
            //     : error?.data?.message
            //       ? error?.data?.message
            //       : `Error occured while fetching all buyers list`,
            //   snackbarType: "error",
            // });
          });
      } else {
        console.log("items data in la is ");
      }
    } else {
      console.log("Invalid La operation for data handelling");
    }
  };
  handleFetchAllPaymentTermsList = (customerId) => {
    this.props
      .fetchCustomerPaymentTerms(customerId)
      .then((response) => {
        // console.log("fetchCustomerPaymentTerms : ", response);
        if (response.status === 200) {
          this.setState(
            {
              allPaymentTermsList: response.data,
              allPaymentTermsListLoader: false,
            });
        } else {
          this.setState({
            allPaymentTermsListLoader: false,
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all payment terms`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   allPaymentTermsListLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.details
        //     ? error?.data?.details
        //     : error?.data?.message
        //       ? error?.data?.message
        //       : `Error occured while fetching all payment terms`,
        //   snackbarType: "error",
        // });
      });
  };
  generateOrderValidityDate = (dateObj, numberOfDaysToAdd) => {
    // console.log("TO" + Number(numberOfDaysToAdd))
    dateObj.setDate(dateObj.getDate() + Number(numberOfDaysToAdd));
    return dateObj;
  };
  handleChange = (name) => (event) => {
    const { value, checked } = event.target;
    this.setState({ [name]: value });
    if (name === "orderValidity") {
      let toDayDate = new Date();
      this.setState(
        {
          orderValidityDate: this.generateOrderValidityDate(
            toDayDate,
            !value ? 0 : value
          ),
        }
      );
    } else if (name === "order") {
      if (this?.state?.itemDetailsData.length < 1) {
        this.addNewItemDetailsData();
      }
    } else {
      console.log("invalid operation");
    }
  };
  addNewItemDetailsData = () => {
    this.setState((previousState) => {
      return {
        itemDetailsData: [
          ...previousState.itemDetailsData,
          {
            item: "",
            quantity: "",
            operation: "add",
          },
        ],
      };
    });
  };
  handleDateChange = (date) => {
    if (date !== "Invalid Date") {
      this.setState({ gateInDate: date });
    } else {
      this.setState({ gateInDate: null });
    }
  };
  removeItemDetailsData = (itemDataIndex) => {
    this.setState((previousState) => {
      const itemDetailsData = [...previousState.itemDetailsData];
      itemDetailsData.splice(itemDataIndex, 1);
      return { itemDetailsData };
    });
  };

  handleFetchSubcontracts = (order_id) => {
    axios.get(
      `${APIROUTES.GET_SUBCONTRACTS}?order_id=${order_id}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data
          const result = []
          for (const k of Object.keys(responseData)) {
            if (response.data[k]?.length > 0) {
              const _data = responseData[k]?.filter((_, i) => i != 0)
              _data.forEach((i) => result.push(i))
            }
          }
          console.log(result)
          this.setState({
            subcontractRows: result
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching subcontracts",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : error?.details
                ? error?.details
                : "Error occured while fetching subcontracts",
          snackbarType: "error",
        });
      });
  };

  handleFetchItemsWithTagsForCategory = (tagId, categoryId) => {
    axios.get(
      `${APIROUTES.GET_ITEMS_PER_TAGS}?tag=${tagId}&category_id=${categoryId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allItemsListWithTag: response.data
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all items list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all items list`,
          snackbarType: "error",
        });
      });
  };


  handleGetSubcontractItemsForCategory = (subcontractId) => {
    axios.get(
      `${APIROUTES.GET_SUB_CONTRACT_ITEMS}?id=${subcontractId}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allSubcontractItemList: response.data
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching all subcontract items list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching all subcontract item list`,
          snackbarType: "error",
        });
      });
  };

  handleFetchFreightBasis = () => {
    axios.get(
      `${APIROUTES.FREIGHT_BASIS}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            freightBasis: response.data
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : `Error occured while fetching freight basis`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : `Error occured while fetching freight basis`,
          snackbarType: "error",
        });
      });
  };

  render() {
    const {
      openSnackbar,
      snackbarMessage,
      snackbarType,
    } = this.state;
    const { isMobile } = this.props;
    return (
      // isMobile ? (
      //   <OpenInDesktop {...this.props} {...this.states} />
      // ) : 
      // (
      <>
        <CreateLoadingAdvice
          {...this.state}
          {...this.props}
          isMobile={isMobile}
          fetchOrderDetails={this.fetchOrderDetails}
          laOperation={this.state.laOperation}
          selectedOrderId={this.state.selectedOrderId}
          laDetails={this.state.laDetails}
          laItemsList={this.state.laItemsList}
          updateLa={this.props.updateLa}
          generateOrderValidityDate={this.generateOrderValidityDate}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          addNewItemDetailsData={this.addNewItemDetailsData}
          removeItemDetailsData={this.removeItemDetailsData}
          handleLaDataSaveEdit={this.handleLaDataSaveEdit}
          fetchItemCategoryPaymentTermDiscount={
            this.fetchItemCategoryPaymentTermDiscount
          }
          fetchItemCategoryPincodeDiscount={this.fetchItemCategoryPincodeDiscount}
          fetchAllOrders={this.props.fetchAllOrders}
          laStatus={this.props?.status ?? this.props?.location?.state?.status}
          laQty={this.props.laQty}
          plantId={this.props.plantId}
          plants={this.props.plants}
          itemId={this.props.itemId}
          customerId={this.props.customerId}
          laId={this.props.laId}
          itemTotalQuantity={this.props.itemTotalQuantity}
          orderNo={this.props.orderNo}
          paymentMethodId={this.props.paymentMethodId}
          buyerPinCode={this.props.buyerPinCode}
          fetchPendingQuantity={this.props.fetchPendingQuantity}
          selectedDate={this.props.date}
          orderStatus={this.props.orderStatus}
          plantName={this.props.location.state.plantName}
          handleActionMenuClose={this.props.handleActionMenuClose}
          handleFetchTcsRateData={this.handleFetchTcsRateData}
          handleFetchItemsForCategory={this.handleFetchItemsForCategory}
          isOverview={this.props?.location?.pathname?.includes("overviewLoadingAdvice")}
          tagsMaster={this.props?.tagsMaster}
          handleFetchSubcontracts={this.handleFetchSubcontracts}
          handleFetchItemsWithTagsForCategory={this.handleFetchItemsWithTagsForCategory}
          handleGetSubcontractItemsForCategory={this.handleGetSubcontractItemsForCategory}
          handleFetchFreightBasis={this.handleFetchFreightBasis}
          created_from_subcontract={this.props.location.state.created_from_subcontract}
        />
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </>
    );
  }
}
export default InternalLoadingAdvicesContainer;
