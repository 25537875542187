import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  primaryColor,
} from "../../../styles/colors";
import Button from "@material-ui/core/Button";
import AddOrderAndItem from "./AddOrderAndItem";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import B2b_URL from "../../../constants/b2b/B2bRoutes";
import { pxToEm } from "../../../methods";

const styles = {
  createDispatchAddOrderAndItemsMainBody: {
    width: "100%",
    height: window.innerHeight - 126,
    maxHeight: window.innerHeight - 126,
    padding: "0px 20px 56px",
    overflowY: "scroll",
  },
  addOrderShowContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  },
  addOrderTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.19em",
    fontWeight: 500,
  },
  quantityShowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  quantityShow: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  quantity: {
    width: "44%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  quantityText: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(18) : "1.25em",
  },
  orderIdContainer: {
    padding: "2px 8px 2px 8px;",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${bottomNavigationFontColor} `,
    borderRadius: "4px",
    marginTop: "10px",
  },
  itemAndQuantityShowContainer: {
    margin: "10px 0px",
    padding: "4px 8px",
    display: "flex",
    justifyContent: "space-between",
  },
  itemAndQuantity: {
    width: "84%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  formControlAndInputContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
  },

  textFieldQuantity: {
    width: "35%",
    marginTop: "0px",
    marginBottom: "0px",
  },
  addMoreButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    border: "1px dashed",
    width: window.innerWidth < 375 ? 'fit-content' : "45%",
    fontSize: window.innerWidth < 375 && pxToEm(13),
    height: "7%",
  },
  customerRemarkCountStyle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end'
  }
};
class CreateDispatchAddOrderAndItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOrderId: "",
      setItem: "",
      setItemQuantity: "",
      setOrderAndItem: new Map(),
      selectedOrderQuantity: null,
      categoryItems: null,
      itemQtyError: false,
      editItem: {
        mode: '',
        editID: ''
      }
      // showItemForm: false,
      // showAddMoreButton: false,

    };
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.openBuyerListPopper) {
      this.setState({ openBuyerListPopper: false });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState?.editItem?.editID !== this.state?.editItem?.editID) {
      console.log('here')
    }
  }
  handleClosePopper = (e) => {
    this.setState({ openBuyerListPopper: false });
  };
  handleOpenListPopper = (e) => {
    e.stopPropagation();
    this.setState({ openBuyerListPopper: true });
  };
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleEditItemMode = () => {
    console.log('clicked here')
    this.setState({
      editItem: {
        mode: '',
        editID: ''
      }
    })
  }

  handleSaveClick = (items) => {
    // console.log("clicked")
    // console.log(items)
  }

  checkItemQtyError = (operation, quantity) => {
    const itemqty = this.props.setOrderAndItem
    let totalqty = 0
    // console.log("here")
    itemqty &&
      Array.from(itemqty.entries()).map(
        ([key, value]) => (
          value.map(item => {
            totalqty += parseFloat(item.quantity)
          })
        ))
    // console.log(totalqty, Number(this.props.truckCapacity))

    if (parseFloat(totalqty) > parseFloat(this.props.selectedPlantPendingQuantity) ||
      parseFloat(totalqty) > parseFloat(this.props.truckCapacity)
    ) {
      // console.log("true")
      let itemQtyError = true
      this.setState({
        itemQtyError,
        totalqty
      }, this.props.checkItemQtyError(itemQtyError, totalqty))
    } else {
      // console.log("false")
      let itemQtyError = false
      this.setState({
        itemQtyError,
        totalqty
      }, this.props.checkItemQtyError(itemQtyError))
    }
  }

  handleShowAddMoreButton = () => {
    this.setState({
      showAddMoreButton: true,
      showItemForm: false,
    })
  }

  handleAddMoreButtonClick = () => {
    this.setState({
      showAddMoreButton: true,
      showItemForm: true,
    })
  }

  checkDisableSave = () => {
    const {
      classes,
      orders,
      items,
      setItemQuantity,
      setItem,
      setOrderId,
      openBuyerListPopper,
      truckCapacity,
      setItemQuantityError,
    } = this.props;
    if (setOrderId === "" ||
      setItemQuantity === "" ||
      setItem === "" ||
      setItemQuantityError) {
      return true
    }
    return false
  }

  // checkTotalQtyError

  render() {
    const {
      openBuyerListPopper,
      categoryItems,
      selectedLAItems,
      itemQtyError,
      editItem,
    } = this.state;
    const { classes, orders, items, truckCapacity, setOrderId,
      setItem,
      setItemQuantity,
      setOrderAndItem,
      capacityError,
      addAnotherOrder,
      selectedOrderQuantity,
      selectedPlantPendingQuantity,
      showAddMoreButton,
      showItemForm,
      laState,
      customerRemark,
      subContract,
    } = this.props;
    // console.log(setOrderAndItem)
    return (
      <div
        className={classes.createDispatchAddOrderAndItemsMainBody}
        onClick={(e) => {
          e.preventDefault();
          this.handleClosePopper();
        }}
      >
        <div className={classes.addOrderShowContainer}>
          <span className={classes.addOrderTextStyle}>Add  Items*</span>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            Select item type and mention correct quantity
          </span>
        </div>
        <div className={classes.quantityShowContainer}>
          <div className={classes.quantityShow}>
            <div className={classes.quantity}>
              <span className={classes.quantityText}>
                {selectedPlantPendingQuantity >= 0 ? selectedPlantPendingQuantity : "-"}
                {selectedPlantPendingQuantity >= 0 ? <span style={{ marginLeft: "4px" }}>MT</span> : ""}
              </span>
              <span
                style={{ color: bottomNavigationFontColor, fontSize: window.innerWidth < 375 ? pxToEm(13.8) : "0.9em" }}
              >
                Pending Qty
              </span>
            </div>
            <div className={classes.quantity}>
              <span className={classes.quantityText}>
                {truckCapacity}<span style={{ marginLeft: "4px" }}>MT</span>
              </span>
              <span
                style={{ color: bottomNavigationFontColor, fontSize: window.innerWidth < 375 ? pxToEm(13.8) : "0.9em" }}
              >
                Truck Capacity
              </span>
            </div>
          </div>
        </div>
        {setOrderAndItem && setOrderAndItem.size === 0 ? (
          <AddOrderAndItem
            items={items}
            orders={orders}
            handleTextChange={this.props.handleTextChange}
            handleTextChangeErrorCheck={this.props.handleTextChangeErrorCheck}
            setItemQuantity={setItemQuantity}
            setItem={setItem}
            setOrderId={setOrderId}
            setItemQuantityError={this.props.setItemQuantityError}
            handleClosePopper={this.handleClosePopper}
            handleOpenListPopper={this.handleOpenListPopper}
            openBuyerListPopper={openBuyerListPopper}
            truckCapacity={truckCapacity}
            handleOrderSelect={this.props.handleOrderSelect}
            setOrderIdAndAmount={this.props.setOrderIdAndAmount}
            handleSaveOrderData={this.props.handleSaveOrderData}
            handleSetOrderItem={this.props.handleSetOrderItem}
            setOrderAndItem={setOrderAndItem}
            handleShowAddMoreButton={this.props.handleShowAddMoreButton}
            handleShowQuantityError={this.props.handleShowQuantityError}
            capacityError={this.props.capacityError}
            subContract={subContract}
          />
        ) : null}
        {
          <div style={{ paddingTop: "5px" }}>
            {
              Array.from(setOrderAndItem.entries()).map(
                ([key, value]) => (
                  <div>
                    <div>
                      {
                        value.map((data, index) => (
                          <div
                            className={classes.itemAndQuantityShowContainer}
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {(editItem?.mode === 'edit' && editItem?.editID == data?.id) ?
                              <AddOrderAndItem
                                items={items}
                                orders={orders}
                                handleTextChange={this.props.handleTextChange}
                                handleTextChangeErrorCheck={this.props.handleTextChangeErrorCheck}
                                defaultSetItemQuantity={data?.quantity}
                                setItemQuantity={setItemQuantity}
                                defaultItem={{ item_name: data?.item, id: data?.id }}
                                setItem={setItem}
                                setOrderId={setOrderId}
                                setItemQuantityError={this.props.setItemQuantityError}
                                handleClosePopper={this.handleClosePopper}
                                handleOpenListPopper={this.handleOpenListPopper}
                                openBuyerListPopper={openBuyerListPopper}
                                truckCapacity={truckCapacity}
                                handleOrderSelect={this.props.handleOrderSelect}
                                setOrderIdAndAmount={this.props.setOrderIdAndAmount}
                                handleSaveOrderData={this.props.handleSaveOrderData}
                                handleSetOrderItem={this.props.handleSetOrderItem}
                                setOrderAndItem={setOrderAndItem}
                                handleShowAddMoreButton={this.props.handleShowAddMoreButton}
                                handleShowQuantityError={this.props.handleShowQuantityError}
                                capacityError={this.props.capacityError}
                                handleEditItemMode={this.handleEditItemMode}
                                laState={laState}
                                rowItemData={data}
                                subContract={subContract}
                              />
                              :
                              <>
                                <div className={classes.itemAndQuantity}>
                                  <span style={{ width: '55%', wordBreak: "break-word", fontSize: window.innerWidth < 375 && pxToEm(13) }}>{data.item}</span>
                                  <span style={{ fontSize: window.innerWidth < 375 && pxToEm(13) }}>{data.quantity} MT</span>
                                </div>
                                <IconButton
                                  aria-label="Delete"
                                  style={{ display: laState?.mode !== 'edit' && 'none' }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ editItem: { mode: 'edit', editID: data?.id } },
                                      () => this.props?.handleItemEdit(data, index))
                                  }}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="Delete"
                                  onClick={(e) => {

                                    // console.log("here..............................")
                                    e.preventDefault();
                                    this.props.deleteOrderItem(key, index, data, laState?.mode === 'edit' && 'edit');
                                    this.props.handleItemDelete()
                                    // this.checkItemQtyError()
                                    if (value.length === 0) {
                                      this.props.deleteOrder()
                                    }

                                  }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </>
                            }
                          </div>
                        ))}
                      {
                        (showAddMoreButton) &&
                        <Button
                          className={classes.addMoreButton}
                          onClick={this.props.handleAddMoreButtonClick}
                          style={{
                            margin: "20px 0px",
                          }}
                        >
                          +ADD MORE ITEMS
                        </Button>
                      }

                      {showItemForm &&
                        items && items.length > 0 &&
                        <AddOrderAndItem
                          items={items}
                          orders={orders}
                          handleTextChange={this.props.handleTextChange}
                          handleTextChangeErrorCheck={this.props.handleTextChangeErrorCheck}
                          setItemQuantity={setItemQuantity}
                          setItem={setItem}
                          setOrderId={setOrderId}
                          setItemQuantityError={this.props.setItemQuantityError}
                          handleClosePopper={this.handleClosePopper}
                          handleOpenListPopper={this.handleOpenListPopper}
                          openBuyerListPopper={openBuyerListPopper}
                          truckCapacity={truckCapacity}
                          handleOrderSelect={this.props.handleOrderSelect}
                          setOrderIdAndAmount={this.props.setOrderIdAndAmount}
                          handleSaveOrderData={this.props.handleSaveOrderData}
                          handleSetOrderItem={this.props.handleSetOrderItem}
                          setOrderAndItem={setOrderAndItem}
                          handleShowAddMoreButton={this.props.handleShowAddMoreButton}
                          handleShowQuantityError={this.props.handleShowQuantityError}
                          capacityError={this.props.capacityError}
                          subContract={subContract}
                        />
                      }
                      <div style={{ marginTop: "8px" }}>
                        {itemQtyError ? (
                          <span style={{ color: "red" }}>
                            Total Quantity can't not be more than truck capacity or Pending qty
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>

        }
        {addAnotherOrder ? (
          <AddOrderAndItem
            items={items}
            orders={orders}
            handleTextChange={this.props.handleTextChange}
            setItemQuantity={setItemQuantity}
            setItem={setItem}
            setOrderId={setOrderId}
            handleSaveOrderData={this.props.handleSaveOrderData}
            setOrderIdAndAmount={this.props.setOrderIdAndAmount}
            handleSetOrderItem={this.props.handleSetOrderItem}
            handleClosePopper={this.handleClosePopper}
            handleOpenListPopper={this.handleOpenListPopper}
            openBuyerListPopper={openBuyerListPopper}
            subContract={subContract}
          />
        ) : null}
        <div className={classes.addOrderShowContainer} style={{ marginTop: "40px", }}>
          <span className={classes.addOrderTextStyle}>Customer Remark</span>
          <TextField
            name="customerRemark"
            id="outlined-name"
            // label="Customer Remark"
            multiline
            minRows={2}
            maxRows={3}
            value={customerRemark}
            className={classes.textFieldStyle}
            onChange={this.props.handleTextChange}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true, }}
            inputProps={{
              maxLength: 100,
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(14)
              }
            }}
          />
          <span style={styles.customerRemarkCountStyle}>
            {`${customerRemark?.length || 0}`} / 100
          </span>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CreateDispatchAddOrderAndItems);
