import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
  secondaryColor,
} from "../../../styles/colors";
import "../../../styles/b2b/web/RegistrationWeb.css";
import Add from "@material-ui/icons/Add";
import moment from "moment";
import * as EmailValidator from "email-validator";

const styles = {
  registerPersonalInformationWebMainBody: {
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 50px 20px 50px",
    maxHeight: "85%",
    overflowY: "scroll",
  },
  registerPersonalInformationWebInternalBody: {
    width: "90%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  textFieldStyle: {
    width: "100%",
  },
  additionalTextContactTextDiv: {
    width: "100%",
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
  },
  additionalContactFormContainer: {
    marginTop: "12px",
  },
  addMoreContactButtonDiv: {
    border: "1px dashed grey",
    height: "45px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    cursor: "pointer",
  },
};
class RegisterPersonalInformationWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.setState({
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
    });
  }

  render() {
    const {
      contactPersonEmailTypeError,
      contactNumberLengthError,
    } = this.state;
    const {
      classes,
      firstName,
      lastName,
      dob,
      contactPersonName,
      contactPersonEmail,
      contactPersonContactNumber,
      contactPersonArray,
    } = this.props;

    return (
      <div
        className={classes.registerPersonalInformationWebMainBody}
        id="registerPersonalInformationWebMainBody"
      >
        <div className={classes.registerPersonalInformationWebInternalBody}>
          <div style={{ width: " 100%" }}>
            <span style={{ color: dailyRateScreenTextColor }}>
              Personal Information
            </span>
          </div>
          <TextField
            //id="firstNameInput"
            name="firstName"
            id="outlined-name"
            label="First Name"
            margin="normal"
            variant="outlined"
            value={firstName}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            inputProps={{
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            onFocus={() => {
              this.props.handleOnFocusFirstName();
            }}
            onBlur={() => {
              this.props.handleOnBlurFirstName();
            }}
          />
          <TextField
            name="lastName"
            id="outlined-name"
            label="Last Name"
            margin="normal"
            variant="outlined"
            value={lastName}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            inputProps={{
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            onFocus={() => {
              this.props.handleOnFucusLastName();
            }}
            onBlur={() => {
              this.props.handleOnBlurLastName();
            }}
          />
          <TextField
            name="dob"
            id="date"
            type="date"
            // id="outlined-name"
            label="Date of Birth"
            margin="normal"
            variant="outlined"
            value={dob}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            InputProps={{
              inputProps: {
                max: this.state.dateToday,
                style: { padding: "13px 14px" },
              },
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
          />
        </div>
        <div style={{ width: "90%" }}>
          <div className={classes.additionalTextContactTextDiv}>
            <span
              style={{ color: dailyRateScreenTextColor, fontSize: "1.1em" }}
            >
              Additional Contact
            </span>
            <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.9em",
                lineHeight: "1.6em",
              }}
            >
              All contacts added here will be notified through SMS about
            </span>
            <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.9em",
                lineHeight: "1.6em",
              }}
            >
              Daily Rates , Orders and Dispatches
            </span>
          </div>
          {contactPersonArray &&
            contactPersonArray.map((data, index) => (
              <div className={classes.additionalContactFormContainer}>
                <span style={{ color: secondaryColor, fontSize: "1.1em" }}>
                  Contact Person {index + 1}
                </span>
                <TextField
                  disabled
                  name="contactPersonName"
                  id="outlined-name"
                  label="Name"
                  margin="normal"
                  variant="outlined"
                  // onChange={this.props.handleTextChangeContactPerson}
                  value={data.name}
                  className={classes.textFieldStyle}
                  inputProps={{
                    style: { padding: "13px 14px" },
                  }}
                  InputLabelProps={{
                    style: { fontSize: "1em", marginTop: "-4px" },
                  }}
                />
                <TextField
                  disabled
                  name="contactPersonEmail"
                  id="outlined-name"
                  label="Email "
                  margin="normal"
                  variant="outlined"
                  // onChange={this.props.handleTextChangeContactPerson}
                  value={data.email_id}
                  className={classes.textFieldStyle}
                  inputProps={{
                    style: { padding: "13px 14px" },
                  }}
                  InputLabelProps={{
                    style: { fontSize: "1em", marginTop: "-4px" },
                  }}
                />
                <TextField
                  disabled
                  name="contactPersonContactNumber"
                  id="outlined-name"
                  label="Contact Number"
                  margin="normal"
                  variant="outlined"
                  // onChange={this.props.handleTextChangeContactPerson}
                  value={data.phone_number}
                  className={classes.textFieldStyle}
                  inputProps={{
                    maxLength: 10,
                    style: { padding: "13px 14px" },
                  }}
                  InputLabelProps={{
                    style: { fontSize: "1em", marginTop: "-4px" },
                  }}
                />
              </div>
            ))}
          <div className={classes.additionalContactFormContainer}>
            <span style={{ color: secondaryColor, fontSize: "1.1em" }}>
              Contact Person
              <span style={{ marginLeft: "4px" }}>
                {contactPersonArray && contactPersonArray.length + 1}
              </span>
            </span>
            <TextField
              name="contactPersonName"
              id="outlined-name"
              label="Name"
              margin="normal"
              variant="outlined"
              onChange={this.props.handleTextChangeContactPerson}
              value={contactPersonName}
              className={classes.textFieldStyle}
              inputProps={{
                style: { padding: "13px 14px" },
              }}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
            />
            <TextField
              name="contactPersonEmail"
              id="outlined-name"
              label="Email "
              margin="normal"
              variant="outlined"
              onChange={this.props.handleTextChangeContactPerson}
              value={contactPersonEmail}
              className={classes.textFieldStyle}
              inputProps={{
                style: { padding: "13px 14px" },
              }}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
              onFocus={() => {
                this.setState({ contactPersonEmailTypeError: false });
              }}
              onBlur={() => {
                if (EmailValidator.validate(contactPersonEmail) === false) {
                  this.setState({ contactPersonEmailTypeError: true });
                }
              }}
              helperText={
                <span
                  style={{
                    color: "red",
                    fontSize: "0.8em",
                  }}
                >
                  {contactPersonEmailTypeError
                    ? "Please enter valid email address"
                    : null}
                </span>
              }
              error={contactPersonEmailTypeError ? true : false}
            />
            <TextField
              name="contactPersonContactNumber"
              id="outlined-name"
              label="Contact Number"
              margin="normal"
              variant="outlined"
              onChange={this.props.handleTextChangeContactPerson}
              value={contactPersonContactNumber}
              className={classes.textFieldStyle}
              inputProps={{
                maxLength: 10,
                style: { padding: "13px 14px" },
              }}
              InputLabelProps={{
                style: { fontSize: "1em", marginTop: "-4px" },
              }}
              onFocus={() => {
                this.setState({ contactNumberLengthError: false });
              }}
              onBlur={() => {
                if (contactPersonContactNumber.length < 10) {
                  this.setState({ contactNumberLengthError: true });
                }
              }}
              helperText={
                <span
                  style={{
                    color: "red",
                    fontSize: "0.8em",
                  }}
                >
                  {contactNumberLengthError
                    ? "Please enter valid 10 digit contact number"
                    : null}
                </span>
              }
              error={contactNumberLengthError ? true : false}
            />
          </div>
          <div
            className={classes.addMoreContactButtonDiv}
            onClick={(e) => {
              e.preventDefault();
              if (
                contactPersonName !== "" &&
                contactPersonEmail !== "" &&
                contactPersonContactNumber !== "" &&
                contactPersonEmailTypeError === false &&
                contactNumberLengthError === false
              ) {
                this.props.handleAddMoreContactPerson();
              }
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "grey",
              }}
            >
              <Add />
              Add More
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterPersonalInformationWeb);
