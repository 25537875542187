import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { pxToEm } from "../../../methods";
import moment from "moment";
import { lightGreyColor } from "../../../styles/colors";
import { Divider } from "@material-ui/core";

const styles = {
  orderNameMenuContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    padding: "10px 0px",
    fontSize: "0.9em",
    color: lightGreyColor,
    fontFamily: 'Poppins'
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "5px"
  },
  contentQuantityBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }
}

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
}

class CreateDispatchSubContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubContract: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props?.selectedEditSubContractNumber !== "") {
      console.log(this.props?.selectedEditSubContractNumber, this.props?.selectedEditSubContractNumber && this.props.orders.find(d => d.sub_contract_number === this.props?.selectedEditSubContractNumber))
      this.props?.selectedEditSubContractNumber &&
        this.props.handleSubcontractFormDetail(this.props.orders.find(d => d.sub_contract_number === this.props?.selectedEditSubContractNumber), "edit")
    }
  }
  render() {
    const { } = this.state;
    const { orders, laState, subContractNumber } = this.props;

    return (
      <FormControl fullWidth>
        <InputLabel
          id="demo-simple-select-label"
          style={{ fontSize: window.innerWidth < 375 && pxToEm(14) }}
        >
          Select Sub Contract
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Select Sub Contract"
          value={subContractNumber || ""}
          disabled={laState && laState?.mode === "edit"}
          MenuProps={MenuProps}
          // disabled={location?.state?.type}
          style={{
            fontSize: window.innerWidth < 375 && pxToEm(15),
            fontFamily: 'Poppins'
          }}
        >
          {
            orders && orders.length > 0 ?
              orders.map((data, i) => (
                <MenuItem
                  key={data.subcontract_id}
                  value={data.sub_contract_number}
                  component="div"
                  disabled={Number(data.available_quantity) <= Number(0)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleSubcontractFormDetail(data)
                  }}
                  style={{
                    fontSize: window.innerWidth < 375 && pxToEm(15),
                    fontFamily: 'Poppins'
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div style={styles.orderNameMenuContainer}>
                      <div
                        style={{
                          width: window.innerWidth < 375 ? "65%" : "60%",
                          color: 'black'
                        }}
                      >
                        <div style={styles.contentBox}>
                          <span>{data.sub_contract_number}</span>
                          <span style={{ fontSize: "0.9em" }}>
                            {moment(data.sub_contract_date).format("DD-MMM-yyyy")}
                          </span>
                        </div>
                      </div>
                      <div style={{ width: "30%", color: 'black' }}>
                        <div style={styles.contentQuantityBox}>
                          <span>{data.available_quantity} MT</span>
                          <span style={{ fontSize: "0.8em" }}>Balance</span>
                        </div>
                      </div>
                    </div>
                    <Divider variant="middle" />
                  </div>
                </MenuItem>
              )) : null
          }
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(styles)(CreateDispatchSubContract);