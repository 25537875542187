import { useEffect, useState } from "react";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { drawerTextColor, successButtonBackground, } from "../../../../constants/internal/colors";
import { Button, Divider, Paper, TextField, styled } from "@material-ui/core";
import { Autocomplete, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { checkInvalidValue, pxToEm } from "../../../../methods";

const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: (isMobile) => ({ paddingRight: !isMobile && "25px" }),
  displayData: {
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "100%",
    marginTop: 10,
    paddingBottom: 10,
  },
  rowContainer: (isMobile) => ({
    alignItems: isMobile && "center",
    display: isMobile && "flex",
    flexDirection: isMobile && 'column',
    width: isMobile && '100%'
  }),
  rowInnerContainer: (isMobile) => ({
    width: "98%",
    alignItems: isMobile ? 'center' : "flex-start",
    display: "flex",
    flexDirection: isMobile && 'column',
    justifyContent: isMobile ? "space-around" : "flex-start",
  }),
  textfieldContainer: (isMobile) => ({
    display: 'flex',
    width: isMobile ? '85%' : "30%",
    margin: isMobile ? '20px 0' : "20px",
  }),
  textfieldDescriptionContainer: (isMobile) => ({
    width: isMobile ? '85%' : "65%",
    margin: isMobile ? '20px 0' : "20px",
  }),
  SelectContainer: (isMobile) => ({
    display: 'flex',
    width: isMobile ? '85%' : "30%",
    margin: isMobile ? '30px' : "35px 30px 0 10px",
  }),
  buttonContainer: {
    paddingTop: 20
  },
  cancelButton: {
    marginLeft: 20,
  },
  dropdownText: {
    '& .MuiTypography-root': {
      padding: '0',
      fontFamily: 'Poppins',
      fontSize: pxToEm(12),
      lineHeight: 1
    },
  },
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const DefineScheme = (props) => {

  const [schemeState, setSchemeState] = useState({
    scheme_name: '',
    description: '',
    customer_type: null,
    applicable_FY: null,
    item_category: [],
    target_type: null,
    incentive_type: null
  })
  const [itemCategorySelected, setItemcategorySelected] = useState([])

  const [itemCategoryDropdownList, setItemCategoryDropdownList] = useState([]);

  const handleDisplayType = (displaySection) => {
    return displaySection === 'distributor'
      ? `Distributor`
      : displaySection === 'dealer'
        ? `Dealer`
        : displaySection === 'salesPerson' && `Sales Person`
  }

  const handleYearValueShown = (addYear) => {
    let today = new Date()
    return today.getFullYear() + addYear
  }

  useEffect(() => {
    setItemCategoryDropdownList(props?.itemCategoryList)
  }, [props?.itemCategoryList])


  useEffect(() => {
    setSchemeState({
      scheme_name: null,
      description: null,
      customer_type: handleDisplayType(displaySection),
      applicable_FY: `01 Apr ${handleYearValueShown(0)} - 31 Mar ${handleYearValueShown(1)}`,
      item_category: [],
      target_type: null,
      incentive_type: null
    })
  }, [])

  const type = [
    'Distributor',
    'Dealer',
    'Sales Target',
  ]

  const financialYear = [
    `01 Apr ${handleYearValueShown(-1)} - 31 Mar ${handleYearValueShown(0)}`,
    `01 Apr ${handleYearValueShown(0)} - 31 Mar ${handleYearValueShown(1)}`,
    `01 Apr ${handleYearValueShown(1)} - 31 Mar ${handleYearValueShown(2)}`,
  ]

  const targetType = [
    'Annual Sale Targets - Same for All',
    'Annual Sale Targets - Personalised',
    'Quarterly Sale Targets - Personalised',
    'APM Sale Targets - Same for All',
    'Monthly Sale Targets - Personalised',
  ]
  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) ~ :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  const incentiveType = ['Bonus calculation per MTS', 'Other Incentive']


  const handleSchemeStateValueChange = (e) => {
    const { name, value } = e.target;
    setSchemeState(prevValue => ({
      ...prevValue,
      [name]: value
    }))
  }

  const handleSchemeStateDropdownValueChange = (name) => (e, value) => {
    console.log("herre dropdown change " + name + " = " + value,)
    setSchemeState(prevValue => ({
      ...prevValue,
      [name]: value
    }))
  }

  const handleItemCategorySelection = (e) => {
    const { value } = e.target;
    const filterValue = value.filter(d => d !== 'ALL')
    console.log(value, filterValue)
    if (filterValue.includes("")) {
      setItemcategorySelected([])
    }
    else {
      setItemcategorySelected(
        typeof filterValue === "string" ? filterValue.split(",") : filterValue
      )
    }
  };

  const DividerStyled = (props) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', width: props.isMobile && '84%' }}>
        <Divider variant="middle" style={{ width: '10%' }} />
        <span style={{ margin: '0 5px' }}>{props.content}</span>
        <Divider ariant="middle" style={{ width: props.isMobile ? "60%" : '75%' }} />
      </div>

    )
  }

  useEffect(() => {
    let itemIDList = [];
    itemCategorySelected.map(d => itemCategoryDropdownList.find(h => h.category_name === d && itemIDList.push(h.id)))
    setSchemeState(prevValue => ({
      ...prevValue,
      item_category: itemIDList
    }))
  }, [itemCategorySelected])

  const { isMobile, displaySection, } = props;
  return (
    <div style={styles.mainDiv}>
      <div style={styles.rightPadding(isMobile)}>
        <Paper elevation={5} style={styles.paperStyle}>
          <div style={styles.rowContainer(isMobile)}>
            <div style={styles.rowInnerContainer(isMobile)}>
              <TextField
                name="scheme_name"
                style={styles.textfieldContainer(isMobile)}
                value={schemeState.scheme_name}
                id="outlined-name"
                label="Scheme Name*"
                variant="outlined"
                onChange={handleSchemeStateValueChange}
              />
              <TextField
                name="description"
                style={styles.textfieldDescriptionContainer(isMobile)}
                value={schemeState.description}
                id="outlined-name"
                label="Description*"
                variant="outlined"
                multiline
                minRows={2}
                maxRows={4}
                onChange={handleSchemeStateValueChange}
              />
            </div>
            <DividerStyled content="Applicability" isMobile={isMobile} />
            <div style={styles.rowInnerContainer(isMobile)}>
              <Autocomplete
                name='customer_type'
                style={styles.textfieldContainer(isMobile)}
                value={schemeState.customer_type}
                options={type}
                getOptionLabel={(option) => option}
                onChange={handleSchemeStateDropdownValueChange('customer_type')}
                ListboxProps={{ style: { fontFamily: 'Poppins', } }}
                disabled={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=" Customer Type*"
                    margin="normal"
                    variant="outlined"
                  // helperText={editData.buyer}
                  />
                )}
              />
              <Autocomplete
                name="applicable_FY"
                style={styles.textfieldContainer(isMobile)}
                value={schemeState.applicable_FY}
                options={financialYear}
                getOptionLabel={(option) => option}
                onChange={handleSchemeStateDropdownValueChange('applicable_FY')}
                ListboxProps={{ style: { fontFamily: 'Poppins', } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Applicable Financial Year*"
                    margin="normal"
                    variant="outlined"
                  // helperText={editData.buyer}
                  />
                )}
              />
              <FormControl style={styles.SelectContainer(isMobile)}>
                <InputLabel
                  id="item-category-filter-label"
                  style={{ fontFamily: 'Poppins', }}
                >
                  Item Categories*
                </InputLabel>
                {console.log(itemCategorySelected, itemCategorySelected.length === 0)}
                <Select
                  multiple
                  value={itemCategorySelected?.length === 0 ? ["ALL"] : itemCategorySelected}
                  onChange={handleItemCategorySelection}
                  input={<OutlinedInput label="Item Categories*" />}
                  SelectDisplayProps={{ style: { paddingBottom: 14 } }}
                  labelId="item-category-filter-label"
                  id="item-category-filter"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>All</em>;
                    }

                    return selected.join(', ');
                  }}
                  MenuProps={MenuProps}
                  style={{
                    fontFamily: 'Poppins',
                  }}
                >
                  <MenuItem key="ALL" value="">
                    <Checkbox
                      style={{ borderColor: 'gray', color: '#1976d2' }}
                      checked={itemCategorySelected?.length === 0}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Poppins',
                        },
                      }}
                      primary="ALL"
                      style={styles.dropdownText}
                    />
                  </MenuItem>
                  {itemCategoryDropdownList ?
                    itemCategoryDropdownList.map((category) => (
                      <MenuItem key={category.category_name} value={category.category_name}>
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={itemCategorySelected?.indexOf(category.category_name) > -1}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontFamily: 'Poppins',
                            },
                          }}
                          primary={category.category_name}
                          style={styles.dropdownText}
                        />
                      </MenuItem>
                    ))
                    :
                    null}
                </Select>
              </FormControl>
            </div>
            <DividerStyled content="Applicability" isMobile={isMobile} />
            <div style={styles.rowInnerContainer(isMobile)}>
              <Autocomplete
                name="target_type"
                style={styles.textfieldContainer(isMobile)}
                value={schemeState.target_type}
                options={targetType}
                getOptionLabel={(option) => option}
                onChange={handleSchemeStateDropdownValueChange('target_type')}
                ListboxProps={{ style: { fontFamily: 'Poppins', } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Target Type*"
                    margin="normal"
                    variant="outlined"
                  // helperText={editData.buyer}
                  />
                )}
              />
              <Autocomplete
                name="incentive_type"
                style={styles.textfieldContainer(isMobile)}
                value={schemeState.incentive_type}
                options={incentiveType}
                getOptionLabel={(option) => option}
                onChange={handleSchemeStateDropdownValueChange('incentive_type')}
                ListboxProps={{ style: { fontFamily: 'Poppins', } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Incentive Type*"
                    margin="normal"
                    variant="outlined"
                  // helperText={editData.buyer}
                  />
                )}
              />
              <div style={styles.textfieldContainer(isMobile)}></div>
            </div>
          </div>
        </Paper>
        <div style={styles.buttonContainer}>
          {console.log(Boolean(Object.entries(schemeState).find(([k, v]) => console.log(k, v, checkInvalidValue(v)))))}
          <Button
            size="medium"
            style={{
              backgroundColor: Boolean(Object.entries(schemeState).find(([k, v]) => checkInvalidValue(v)))
                ? 'rgba(0,0,0,0.2)' : successButtonBackground,
              color: Boolean(Object.entries(schemeState).find(([k, v]) => checkInvalidValue(v)))
                ? 'rgba(0,0,0,0.2)' : drawerTextColor,
            }}
            variant="contained"
            startIcon={<CheckOutlinedIcon />}
            disabled={Boolean(
              Object.entries(schemeState).find(([k, v]) => checkInvalidValue(v))
            )}
            onClick={() => {
              props.handleCreateScheme(schemeState, handleDisplayType(displaySection))
            }}
          >
            SAVE
          </Button>
          <Button
            size="medium"
            style={styles.cancelButton}
            onClick={(e) => {
              e.preventDefault();
              props.history.push(props.handlePageTitleRoute(displaySection, "", 'route'));
            }}
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DefineScheme;