import React, { Component } from "react";
import InfoIcon from "@material-ui/icons/Info";
import Notifications from "@material-ui/icons/NotificationsNone";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import withUser from '../../../hoc/withUser';
import Button from "@material-ui/core/Button";
import moment from "moment";
import { withRouter } from "react-router";
import axios from "axios";
import { StaticAppVersion, pxToEm, compareValues } from '../../../methods';
import { primaryColor } from '../../../styles/colors';
import B2b_URL from '../../../constants/b2b/B2bRoutes';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Snackbar } from "@material-ui/core";
import SnackbarComponent from "../common/SnackbarComponent";
import withAuthentication from "../../../hoc/withAuthentication";
import TileSkeleton from "../../b2b/common/TileSkeleton";

const styles = {
    listItemTextStyle: {
        marginLeft: "20px",
        fontSize: "0.8em",
    },
    avatarStyle: {
        height: "45px",
        width: "45px",
        borderRadius: "23%",
        backgroundColor: primaryColor,
        transform: window.innerWidth < 375 && 'scale(0.7)'
    },
    div: {
        position: "absolute",
        top: "-3px",
        right: "7px",
        height: "0px",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: "10px solid white",
    },
    pad: {
        paddingLeft: "0px",
        paddingRight: "0px",
        cursor: "pointer",
    },
    fnt: {
        style: { fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.1em" },
    },
    cont: (isMobile) => ({
        padding: isMobile ? "0px" : "20px",
        height: isMobile ? "92%" : "55vh",
        overflowY: "scroll",

    }),
    not: (isMobile) => ({
        height: isMobile ? "100%" : "210px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }),
    loadMoreButtonContainer: {
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonStyle: {
        textTransform: "capitalize",
        fontSize: "1.2em",
        marginRight: "15px",
    },
    ordersNavigationContainer: {
        width: "100%",
        display: "flex",
        padding: "5px 0px 0px 0px",
        // justifyContent: "center",
    },
    ordersNavigationStyle: {
        width: "100%",
        height: "56px",
        // margin: "30px 0px 30px 0px",
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
    },
    icon: {
        height: "25px",
        width: "25px"
    }
};
class AdminNotificationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationsLoader: true,
            notifications: [],
            dates: [],
            no_unread_notification: false,
            unreadNotification: 0
        };
    }

    offlineCheck = () => {
        this.setState({ isOffline: true, snackbarMessage: 'No Internet Connection' });
    };
    onlineCheck = () => {
        this.setState({ isOffline: false });
    };

    componentWillMount() {
        window.addEventListener("offline", this.offlineCheck);
        window.addEventListener("online", this.onlineCheck);
    }

    componentDidMount() {
        if (navigator.onLine === false) {
            this.setState({
                isOffline: true,
            });
        }
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            no_unread_notification: false,
            unreadNotification: false,
            isOffline: false,
            snackbarMessage: "",
        });
    };

    handleNotificationSnackbar = () => {
        if (this.props.notifications && this.props.notifications.length === 0) {
            this.setState({ no_unread_notification: true, snackbarMessage: 'No unread notifications' })
            return false;
        }
        else if (this.props.notifications.length > 0) {
            if (!Boolean(this.props.notifications.find(d => d.is_read === false))) {
                this.setState({ no_unread_notification: true, snackbarMessage: 'No unread notifications' })
                return false;
            }
            else {
                this.setState({ no_unread_notification: false })
                return true;
            }
        }
    }

    handleNotificationMarkAllRead = () => {
        if (navigator.onLine === false) {
            this.setState({
                isOffline: true,
            });
        }
        else {
            if (this.handleNotificationSnackbar()) {
                if (!this.state?.no_unread_notification) {
                    axios.put(`${B2b_URL.MARK_ALL_READ_NOTIFICATION}?id=${this.props.user?.id}&V=${StaticAppVersion()}`)
                        .then(response => {
                            this.setState({
                                unreadNotification: (this.props.notifications.filter(d => d.is_read === false)).length
                            }, () => this.setState({ snackbarMessage: `${this.state.unreadNotification} notification marked as read` }))
                            this.props.fetchNotificationList()
                        })
                        .catch(err => console.log(err))
                }
            }
        }
    }

    handleNotificationClick = (notification) => {
        if (navigator.onLine === false) {
            this.setState({
                isOffline: true,
            });
        }
        else {
            // console.log(notification)
            const payload = [{
                id: notification.id,
                is_read: true
            }]

            axios.put(
                `${B2b_URL.READNOTIFICATION}/?V=${StaticAppVersion()}`,
                payload,
                // this.getOptions(this.props.user)

            ).then(response => {
                // console.log(response)
                let link = notification.notification_link.split("/")
                // console.log(link)
                let linkk = `/${link[3]}/${link[4]}/${link[5]}`
                // console.log(linkk)
                this.props.history.push(linkk)
                // this.props.fetchNotificationList()
            })
                .catch(err => console.log(err))
        }
    }
    render() {
        const { classes, isMobile } = this.props;
        const { isOffline, no_unread_notification, unreadNotification, snackbarMessage } = this.state;
        // console.log(this.props)
        return (
            <div style={{ height: "100%" }}>
                <div className={classes.ordersNavigationContainer} style={{ paddingLeft: !isMobile && "15px", paddingRight: !isMobile && "15px" }}>
                    <div className={classes.ordersNavigationStyle}>
                        <Button
                            className={classes.buttonStyle}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ paddingLeft: 0 }}
                        >
                            Notifications
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleNotificationMarkAllRead()
                            }}
                            style={{ fontSize: "0.9em", textTransform: "inherit", marginRight: 10 }}
                            variant="outlined"
                            startIcon={<CheckRoundedIcon />}
                            disabled={!this.props.notifications}
                        >
                            Mark all read
                        </Button>
                    </div>
                </div>
                {!this.props.notifications ? (
                    <div
                    // style={styles.not(isMobile)}
                    >
                        {/* <SpinLoader /> */}
                        <TileSkeleton skeletonType='notification' />
                    </div>
                ) : this.props.notifications.length === 0 ? (
                    <div style={styles.not(isMobile)}>
                        <Notifications style={{ color: "#35425A", marginRight: "10px" }} />
                        No Notifications
                    </div>
                ) : (
                    <div style={styles.cont(isMobile)}>
                        <div>
                            {this.props.dates.map((date) => (
                                <div>
                                    <span style={{ fontSize: "12px", color: "#989FAB" }}>
                                        {date === moment(new Date()).format("DD MMMM, dddd")
                                            ? "Today"
                                            : date}
                                    </span>
                                    <List>
                                        {this.props.notifications
                                            .filter((item) => {
                                                return (
                                                    moment(item.created_at).format("DD MMMM, dddd") === date
                                                );
                                            })
                                            .map((data) => (
                                                <ListItem
                                                    style={styles.pad}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (data.is_read === false) {
                                                            this.handleNotificationClick(data)
                                                        }
                                                    }}
                                                    disabled={data.is_read === true}
                                                >
                                                    <Avatar
                                                        style={styles.avatarStyle}
                                                        alt="Remy Sharp"
                                                    >
                                                        {
                                                            data.notification_topic === "dailyRates"
                                                                ? <span style={styles.icon}><CurrencyRupeeOutlinedIcon /></span>
                                                                : data.notification_topic === "Order"
                                                                    ? <span style={styles.icon}><AssignmentOutlinedIcon /></span>
                                                                    : data.notification_topic === "" || data.notification_topic === "Loading Advice"
                                                                        ? <span style={styles.icon}><LocalShippingOutlinedIcon /></span>
                                                                        : <span style={styles.icon}><InfoIcon /></span>
                                                        }
                                                    </Avatar>
                                                    <ListItemText
                                                        primary={data.notification_body}
                                                        secondary={moment(data.created_at).format("h:mm a")}
                                                        className={classes.listItemTextStyle}
                                                        primaryTypographyProps={styles.fnt}
                                                        secondaryTypographyProps={styles.fnt}
                                                    />
                                                </ListItem>
                                            ))}
                                    </List>
                                </div>
                            ))}
                            {/* <div className={classes.loadMoreButtonContainer}>
                {this.props.nextNotificationLoader ? (
                  <CircularProgress />
                ) : this.props.fetchNextNotification ? (
                  <Button
                    fullWidth
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.props.fetchNextNotification) {
                        this.props.handleFetchMoreNotification(
                          this.props.fetchNextNotification
                        );
                      }
                    }}
                  >
                    More
                  </Button>
                ) : null}
              </div> */}
                        </div>
                    </div>
                )}
                <div style={styles.div} />
                <SnackbarComponent
                    open={unreadNotification}
                    handleClose={this.handleCloseSnackbar}
                    snackbarMessage={snackbarMessage}
                    type='success'
                />
                <Snackbar
                    open={isOffline || no_unread_notification}
                    onClose={this.handleCloseSnackbar}
                    autoHideDuration={3000}
                    TransitionComponent={"up"}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        style: {
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                    }}
                    message={<span id="message-id">{snackbarMessage}</span>}
                />
            </div>
        );
    }
}
export default withUser(
    withAuthentication(withRouter(withStyles(styles)(AdminNotificationList)))
);
