import * as Yup from "yup";

const validationsRateTemplateDetails = Yup.object().shape({
    category_name: Yup.string().required("This field is Required").matches(/^[aA-zZ\s]+[a-zA-Z0-9\s]+$/, "Invalid Name"),
    // hsn_code: Yup.number().min(0, "The hsn code amount must be positive").required("This field is Required"),
    min_tolerance_value: Yup.string().required("This field is Required")
    // .matches(/^[0-9]+.[0-9]{0,2}$/, "Positive value upto 2 decimal places.")
    ,
    max_tolerance_value: Yup.string().required("This field is Required")
    // .matches(/^[0-9]+.[0-9]{0,2}$/, "Positive value upto 2 decimal places.")
    ,
    cgst: Yup.string().required("This field is Required")
    // .matches(/^[0-9]+.[0-9]{0,2}$/, "Positive value upto 2 decimal places.")
    ,
    sgst: Yup.string().required("This field is Required")
    // .matches(/^[0-9]+.[0-9]{0,2}$/, "Positive value upto 2 decimal places.")
    ,
    igst: Yup.string().required("This field is Required")
    // .matches(/^[0-9]+.[0-9]{0,2}$/, "Positive value upto 2 decimal places.")
    ,
    loading_rate: Yup.string().required("This field is Required")
    // .matches(/^[0-9]+.[0-9]{0,2}$/, "Positive value upto 2 decimal places.")
    ,
    // item_category_logo: Yup.required("Logo is required"),
    insurance_rate: Yup.string().required("This field is Required")
    // .matches(/^[0-9]+.[0-9]{0,2}$/, "Positive value upto 2 decimal places.")
    ,
    div_code: Yup.string().required("This field is Required").matches(/^[aA-zZ]{2}$/, "Maximum two character"),
    item_category_code: Yup.string().required("This field is Required").matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, "Only Alphanumeric"),
    primary_unit: Yup.string().required("This field is Required"),
    secondary_unit: Yup.string().required("This field is Required"),
    loading_rate_valid_from: Yup.string().required("This field is Required"),
    insurance_rate_valid_from: Yup.string().required("This field is Required"),
});

const validationsItemTypesDetails = Yup.object().shape({
    item_name: Yup.string().required("This field is Required").matches(/[A-Za-z0-9]/, "Invalid Item Name"),
    item_code: Yup.string().required("This field is Required"),
    hsn_code: Yup.string().required("This field is Required"),
    rate_difference: Yup.number().required("This field is Required"),
    //discount_amount: Yup.number().min(0, "The discount amount must be positive").required("This field is Required"),
    unit: Yup.number().required("This field is Required"),
    item_size: Yup.number().required("This field is Required"),
    items_nature: Yup.string().required("This field is Required"),
    section_name: Yup.string().required("This field is Required"),
    section_weight: Yup.string().required("This field is Required").matches(/[A-Za-z0-9]/, "Invalid section weight"),
    valid_from: Yup.string().required("This field is Required"),
    packing_weight: Yup.string().required("This field is Required"),
    tolerance_upto_1mts: Yup.string().required("This field is Required"),
    tolerance_from_1to5mts: Yup.string().required("This field is Required"),
    tolerance_above_5mts: Yup.string().required("This field is Required"),
});

const validationsItemTypesDetailsWithoutPackaging = Yup.object().shape({
    item_name: Yup.string().required("This field is Required").matches(/[A-Za-z0-9]/, "Invalid Item Name"),
    item_code: Yup.string().required("This field is Required"),
    hsn_code: Yup.string().required("This field is Required"),
    item_size: Yup.number().required("This field is Required"),
    rate_difference: Yup.number().required("This field is Required"),
    //discount_amount: Yup.number().min(0, "The discount amount must be positive").required("This field is Required"),
    unit: Yup.number().required("This field is Required"),
    items_nature: Yup.string().required("This field is Required"),
    section_name: Yup.string().required("This field is Required"),
    section_weight: Yup.string().required("This field is Required").matches(/[A-Za-z0-9]/, "Invalid section weight"),
    valid_from: Yup.string().required("This field is Required"),
});

const validationsPaymentTypesDetails = Yup.object().shape({
    discount_amount: Yup.number().min(0, "The discount amount must be positive"),
    premium_amount: Yup.number().min(0, "The premium amount must be positive"),
});
export {
    validationsRateTemplateDetails,
    validationsItemTypesDetails,
    validationsItemTypesDetailsWithoutPackaging,
    validationsPaymentTypesDetails
}