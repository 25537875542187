import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Switch } from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  successButtonBackground,
  drawerTextColor,
} from "../../../../constants/internal/colors";
import { pxToEm } from "../../../../methods";
import RateTemplateTable from "./RateTemplateTable";
import RateLinkingSectionComponent from "./RateLinkingSectionComponent";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import Popover from '@mui/material/Popover';
import COLORS from "../../../../constants/internal/colors";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  toleranceFieldStyles: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
  },
  loadingRateFieldStyles: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
  },
  formControl: {
    width: "65%",
  },
  toleranceFieldWidth: { width: "45%" },
  formControlPanImage: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    justifyContent: "space-between",
    width: "100%",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    width: "17%",
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "100%" },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    width: "40%",
  },
  titleLabelStylePanImage: {
    fontSize: pxToEm(15),
    color: secondaryBlueText,
    fontWeight: "bold",
    // marginBottom: 15,
    cursor: "pointer",
    textDecoration: "underline",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  generalTopMargin: {
    paddingTop: 20,
  },
  popoverBox: {
    '& .MuiPopover-paper': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
      marginBottom: 10,
      // borderRadius: 20,
    }
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '500',
    alignItems: "center"
  },
});
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

export default function ManageItemCategoryRateTemplateTab(props) {
  const classes = useStyles();
  const [listData, setListData] = useState("");
  useEffect(() => {
    axios
      .get(APIROUTES.DAILY_RATE_TEMPLATE_GET)
      .then((response) => {
        if (response.status === 200) setListData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(props)

  const Mousehover = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (e) => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            fontFamily: 'Poppins',
            fontWeight: anchorEl === null ? "400" : "500",
            color: anchorEl === null ? "#666" : "#3B69B0",
            cursor: 'pointer',
            marginTop: "2px",
            marginLeft: "5px"
          }}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          className={classes.popoverBox}
        >
          <Typography sx={{ p: 1 }}>
            {props.content}
          </Typography>
        </Popover>
      </div>
    );
  }

  const ClickedRateDiffBox = (props) => {
    let data = props.data;
    return (
      <div style={{ width: 350, padding: "5%" }}>
        <Typography className={classes.dialogText}>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Rate difference
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid from
          </div>
          <div style={{ color: COLORS.secondaryBlueText }}>
            Valid upto
          </div>
        </Typography>
        <Divider variant='middle' style={{ margin: '2% 0' }} />
        {data?.map((rateDiffData, rdindex) => {
          return (
            <div key={rdindex}>
              <Typography className={classes.dialogText}>
                <div>
                  {rateDiffData?.value}
                </div>
                <div style={{
                  marginLeft: !rateDiffData?.valid_upto
                    ? null : "70px",
                }}>
                  {!rateDiffData?.valid_from
                    ? null
                    : moment(rateDiffData?.valid_from).format("DD-MM-YYYY")}
                </div>
                <div>
                  {!rateDiffData?.valid_upto
                    ? null
                    : moment(rateDiffData?.valid_upto).format("DD-MM-YYYY")}
                </div>
              </Typography>
              <Divider
                variant='middle'
                style={{
                  margin: '2% 0',
                  display: rdindex === (data?.length - 1) ? 'none' : 'block'
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={classes.generalDetailsDisplaySection}>
      <div className={classes.dataDisplay}>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>CATEGORY NAME</Typography>

          <Typography className={classes.formControl}>
            {props.itemCategoryName}
          </Typography>
        </div>
        {/* <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>HSN CODE</Typography>

          <Typography className={classes.formControl}>
            {props.hsnCode}
          </Typography>
        </div> */}

        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>TOLERANCE</Typography>
          <div className={classes.toleranceFieldStyles}>
            <Typography className={classes.formControl}>
              Short Value {Number(props.toleranceShortValue).toFixed(3)} MT
            </Typography>
            <Typography className={classes.formControl}>
              Max Value {Number(props.toleranceMaxValue).toFixed(3)} MT
            </Typography>
          </div>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>GST %</Typography>
          <div className={classes.toleranceFieldStyles}>
            <Typography className={classes.formControl}>CGST {Number(props.cgst).toFixed(2)}</Typography>
            <Typography className={classes.formControl}>SGST {Number(props.sgst).toFixed(2)}</Typography>
            <Typography className={classes.formControl}>IGST {Number(props.igst).toFixed(2)}</Typography>
          </div>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>LOADING RATE</Typography>
          <div className={classes.loadingRateFieldStyles}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>
                ₹ {Number(props.loadingRate).toFixed(2)}

              </Typography>
              <Mousehover
                content={<ClickedRateDiffBox
                  data={props.selectedItemCategoryData?.loading_rate_logs}
                />}
              />
            </div>
            <Typography style={{ width: "50%" }}>
              valid From {props?.loadingRateValidFrom && moment(props?.loadingRateValidFrom).format("DD-MMM-YYYY") || ""}
            </Typography>
          </div>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>INSURANCE RATE</Typography>
          <div className={classes.loadingRateFieldStyles}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>
                ₹ {Number(props.insuranceRate).toFixed(2)}

              </Typography>
              <Mousehover
                content={<ClickedRateDiffBox
                  data={props.selectedItemCategoryData?.insurance_rate_logs}
                />}
              />
            </div>
            <Typography style={{ width: "50%" }}>
              valid From {props?.insuranceRateValidFrom && moment(props?.insuranceRateValidFrom).format("DD-MMM-YYYY") || ""}
            </Typography>
          </div>
        </div>
        {/* <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>RATE DIFFERENCE</Typography>
          <Typography className={classes.formControl}>
            {Number(props.rate_difference).toFixed(2)}
          </Typography>
        </div> */}
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>DIV CODE</Typography>
          <Typography className={classes.formControl}>
            {props.div_code}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>ITEM CATEGORY CODE</Typography>
          <Typography className={classes.formControl}>
            {props.item_category_code}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>ITEM CATEGORY LOGO*</Typography>
          <Typography
            onClick={() =>
              window.open(props.item_category_logo, "_blank")
            }
            className={classes.titleLabelStylePanImage}
          >
            {props.item_category_logo}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>PRIMARY UNIT</Typography>
          <Typography className={classes.formControl}>
            {/* {props.unitList.map((e) => {
              if (e.id == props.primary_unit) return e.unit_code
              else return null
            })
            } || */}
            {props.primary_unit}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>SECONDARY UNIT</Typography>
          <Typography className={classes.formControl}>
            {/* {

              props.unitList.map((e) => {
                if (e.id == props.secondary_unit) return e.unit_code
                else return null
              })
            } || */}
            {props.secondary_unit}
            {console.log(typeof +props.secondary_unit)}
          </Typography>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>ACTIVE STATUS</Typography>
          <div className={classes.dataFieldsForCheckboxCustomerAddress}>
            <IOSSwitch
              disabled={props?.itemOperation === "view" ? true : false}
              checked={props.itemActiveStatus}
              onChange={props.handleChange("itemActiveStatus")}
              name="itemActiveStatus"
            />
          </div>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>ALLOW 0 RATE CONTRACT</Typography>
          <div className={classes.dataFieldsForCheckboxCustomerAddress}>
            <IOSSwitch
              disabled={props?.itemOperation === "view" ? true : false}
              checked={props.itemRateZeroStatus}
              onChange={props.handleChange("itemRateZeroStatus")}
              name="itemRateZeroStatus"
            />
          </div>
        </div>
        <div className={classes.dataFields}>
          <Typography className={classes.titleLabel}>CONSIDER PACKING WEIGHT</Typography>
          <div className={classes.dataFieldsForCheckboxCustomerAddress}>
            <IOSSwitch
              disabled={props?.itemOperation === "view" ? true : false}
              checked={props.itemConsiderPacking}
              onChange={props.handleChange("itemConsiderPacking")}
              name="itemConsiderPacking"
            />
          </div>
        </div>
      </div>
      {props.displayAllData ? (
        <>
          <div className={classes.generalTopMargin}>
            <RateTemplateTable props={props} />
          </div>
          <div className={classes.generalTopMargin}>
            <RateLinkingSectionComponent
              props={props}
              handleSaveRateLinkingItem={props.handleSaveRateLinkingItem}
              handleRateLinkingOperation={props?.handleRateLinkingOperation}
              rateLinkingData={props?.rateLinkingData}
              allItemCategories={props?.allItemCategories}
              selectedItemId={props?.selectedItemId}
              rateLinkingOperation={props?.rateLinkingOperation}
              isLoadingRateLinking={props?.isLoadingRateLinking}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
