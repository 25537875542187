import React, { useEffect, useState } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import ItemCategoryCreateComponent from "../../../../components/internal/masterManagement/itemCategory/ItemCategoryCreateComponent";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import AccessDenied from "../../../../components/common/AccessDenied";

// container for Master Management -> Item Category -> View
const ItemCategoryCreate = (props) => {
  const { user, isMobile } = props;
  const [Fetching, setFetching] = useState(true);
  const [Item, setItem] = useState(null);

  const createNewCategory = async (data) => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };

      setFetching(true);
      try {
        let response = await axios.post(
          APIROUTES.MM_CREATE_NEW_CATEGORY,
          data,
          options
        );
        props.history.push(
          INTERNALROUTES.LISTITEMCATEGORIESMASTER +
          "/details/" +
          response.data.id
        );
      } catch (err) {
        console.log("err occcure in createing new category", err);
        setFetching(false);
        props.history.goBack();
      } finally {
        setFetching(false);
      }
    }
  };

  useEffect(() => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };
      setFetching(false);
    } else {
      setFetching(false);
    }
  }, [user]);

  if (isMobile) return <OpenInDesktop {...this.props} />;

  if (!user.is_admin && user.status !== "approved") {
    return <AccessDenied />;
  }

  return (
    <div>
      <ItemCategoryCreateComponent
        {...props}
        data={Item}
        Fetching={Fetching}
        createNewCategory={createNewCategory}
      />
    </div>
  );
};

export default ItemCategoryCreate;
