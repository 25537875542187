import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../../styles/b2b/web/RegistrationWeb.css";
import Button from "@material-ui/core/Button";
import {
  secondaryColor,
  bottomNavigationFontColor,
  primaryColor,
  dailyRateScreenTextColor,
} from "../../../styles/colors.js";
import InvoiceSVG from "../../common/InvoiceSVG";
import OrderBookingSVG from "../../common/OrderBookingSVG";
import CommerceSVG from "../../common/CommerceSVG";
import PinSVG from "../../common/PinSVG";
import MaskGroupSVG from "../../common/MaskGroupSVG";
import Avatar from "@material-ui/core/Avatar";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import RegisterSelectCategoryWeb from "./RegisterSelectCategoryWeb";
import RegisterGstNumberWeb from "./RegisterGstNumberWeb";
import RegisterPersonalInformationWeb from "./RegisterPersonalInformationWeb";
import RegisterLoginCredentialWeb from "./RegisterLoginCredentialWeb";
import RegisterCreatePasswordWeb from "./RegisterCreatePasswordWeb";
import RegistrationPanAddressWeb from "./RegistrationPanAddressWeb";
import RegisterSuccessfullWeb from "./RegisterSuccessfullWeb";
import * as EmailValidator from "email-validator";
import SaveButton from "../../internal/common/SaveButton";
const styles = {
  loginWebBody: {
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    display: "flex",
  },
  loginWebRightContainer: {
    height: window.innerHeight,
    width: (window.innerWidth > 768 && window.innerWidth <= 1030) ? "80%" : "45%",
  },
  loginWebFormContaine: {
    height: (window.innerWidth > 768 && window.innerWidth <= 1030) ? window.innerHeight * 0.8 : window.innerHeight - 150,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-end",
  },

  exploreBenifitsButton: {
    backgroundColor: "white",
    color: secondaryColor,
    fontWeight: "bold",
  },
  scrollUpButton: {
    color: secondaryColor,
    fontWeight: "bold",
    marginTop: "10px",
  },
};

class RegistrationWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBenifits: false,
      activeStep: 0,
      dontHaveGST: false,
      pancardActiveStatus: false,
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonContactNumber: "",
      contactPersonArray: [],
      OtpEmailText: '',
      OtpPhoneText: '',
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  handleOtpValiadationText = (type, message) => {
    this.setState({ [type]: message })
  }
  handleAddMoreContactPerson = () => {
    let arr = this.state.contactPersonArray;
    const contact = {
      name: this.state.contactPersonName,
      email_id: this.state.contactPersonEmail,
      phone_number: this.state.contactPersonContactNumber,
      is_active: true,
      is_notification_enabled: true,
    };
    arr.push(contact);
    this.setState(
      {
        contactPersonArray: arr,
      },
      () => {
        this.props.handleContactPerson(this.state.contactPersonArray);
        this.setState({
          contactPersonName: "",
          contactPersonEmail: "",
          contactPersonContactNumber: "",
        });
      }
    );
  };
  handleTextChangeContactPerson = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    // document.addEventListener("keydown", (e) =>
    //   e.which == 9 ? e.preventDefault() : null
    // );

    document.addEventListener("keydown", this.handleTabButton);

    setTimeout(() => {
      this.setState({ showBenifits: true });
    }, 5000);
  }
  handleTabButton = (e) => {
    if (e.which === 9 && this.state.activeStep === 1) {
      if (
        this.props.email !== "" &&
        EmailValidator.validate(this.props.email) === true &&
        this.state.emailFocus
      ) {
      } else if (
        this.props.email !== "" &&
        this.props.emailOTP !== "" &&
        EmailValidator.validate(this.props.email) === true &&
        this.state.emailFocus === false &&
        this.state.verifyEmailCliked
      ) {
      } else if (
        this.props.email !== "" &&
        this.props.emailOTP !== "" &&
        this.props.contactNumber.length >= 10 &&
        EmailValidator.validate(this.props.email) === true &&
        this.state.phoneFocus
      ) {
      } else {
        e.preventDefault();
      }
    }
    if (e.which === 9 && this.state.activeStep === 2) {
      if (
        this.props.password &&
        this.props.password !== "" &&
        this.state.onFocusPassword
      ) {
      } else {
        e.preventDefault();
      }
    }
    if (e.which === 9 && this.state.activeStep === 3) {
      if (this.props.firstName !== "" && this.state.onFocusFirstName) {
      } else if (
        this.state.onFocusFirstName === false &&
        this.props.lastName !== "" &&
        this.props.firstName !== "" &&
        this.state.onFocusLastName
      ) {
      } else if (
        this.props.firstName !== "" &&
        this.props.lastName !== "" &&
        this.state.onFocusLastName === false
      ) {
        e.preventDefault();
      } else {
        e.preventDefault();
      }
    }
    if (e.which === 9 && this.state.activeStep === 4) {
      e.preventDefault();
    }
  };
  handleOnFocusFirstName = () => {
    this.setState({ onFocusFirstName: true });
  };
  handleOnBlurFirstName = () => {
    this.setState({ onFocusFirstName: false });
  };
  handleOnFucusLastName = () => {
    this.setState({ onFocusLastName: true });
  };
  handleOnBlurLastName = () => {
    this.setState({ onFocusLastName: false });
  };
  verifyEmailCliked = () => {
    this.setState({ verifyEmailCliked: true });
  };
  handleOnBlurEmailOTP = () => {
    this.setState({ onBlurEmail: true, verifyEmailCliked: false });
  };
  handleOnFocusPassword = () => {
    this.setState({ onFocusPassword: true });
  };
  handleOnBlurPassword = () => {
    this.setState({ onFocusPassword: false });
  };
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleTabButton);
  }
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleNextActiveStep = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };
  handleBackActiveStep = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };
  handleSwipeableIndex = (index) => {
    this.setState({
      activeStep: index,
    });
  };
  handleShowGSTdetails = () => {
    this.setState({ showGstDetails: true });
  };
  handleResetGSTdetails = () => {
    this.setState({ showGstDetails: false });
  };
  emailExistCheck = () => {
    this.setState({ emailAlreadyExist: true });
  };
  resetEmailExistCheck = () => {
    this.setState({ emailAlreadyExist: false });
  };
  activeStep4EmailFocus = () => {
    this.setState({ emailFocus: true });
  };
  activeStep4EmailBlur = () => {
    this.setState({ emailFocus: false });
  };
  activeStep4PhoneFocus = () => {
    this.setState({ phoneFocus: true });
  };
  activeStep4PhoneBlur = () => {
    this.setState({ phoneFocus: false });
  };
  handleChangeEmailOTPNumber = (emailOTPNumber) => {
    this.setState({ emailOTPNumber });
  };
  handleChangeOtpNumber = (otpNumber) => {
    this.setState({ otpNumber });
  };
  handleDontHaveGST = () => {
    this.setState({ dontHaveGST: !this.state.dontHaveGST });
  };
  handlePancardActiveStatus = () => {
    this.setState({ pancardActiveStatus: !this.state.pancardActiveStatus });
  };
  handleRegistration = () => {
    if (
      this.state.activeStep === 3 &&
      this.state.contactPersonName !== "" &&
      this.state.contactPersonEmail !== "" &&
      EmailValidator.validate(this.state.contactPersonEmail) &&
      this.state.contactPersonContactNumber !== "" &&
      this.state.contactPersonContactNumber.length === 10
    ) {
      this.handleAddMoreContactPerson();
      this.handleNextActiveStep();
    }
    if (this.state.activeStep === 4 && this.props.gstNumberCheck === "valid") {
      if (navigator.onLine === false) {
        this.setState({
          noInternetConnection: true,
        });
      } else {
        this.props.handleSubmitCustomerRegistration("GST");
        this.handleNextActiveStep();
      }
    } else if (this.state.activeStep === 5) {
      if (navigator.onLine === false) {
        this.setState({
          noInternetConnection: true,
        });
      } else {
        this.props.handleSubmitCustomerRegistration("PAN");
        this.handleNextActiveStep();
      }
    } else {
      this.handleNextActiveStep();
    }
  };
  noInternetConnectionDialog = () => {
    this.setState({
      noInternetConnection: false,
    });
  };
  render() {
    const {
      showBenifits,
      activeStep,

      emailAlreadyExist,
      emailFocus,
      phoneFocus,
      dontHaveGST,
      pancardActiveStatus,
      contactPerson,
      contactPersonName,
      contactPersonEmail,
      contactPersonContactNumber,
      contactPersonArray,
      OtpEmailText,
      OtpPhoneText
    } = this.state;
    const {
      classes,
      theme,
      handleSelectCategory,
      category,
      Gst_number,
      handleTextChange,
      addressLine_one,

      pincode,
      firstName,
      lastName,
      dob,
      contactNumber,
      email,
      otp,
      password,
      confirmPassword,
      legal_name_of_business,
      date_of_registration,

      emailOTP,
      gstNumberCheck,
      state,
      panNumber,
      panCardHolderName,
      companyName,
      panCardImageFile,
      panAddressLineOne,
      panAddressLineTwo,
      panAddressLineThree,
      panAddressCountry,
      panAddressState,
      panAddressCity,
      panAdressPincode,
      countries,
      states,
      cities,
      panImageFileTypeError,
      registrationSubmitLoader
    } = this.props;
    return (
      <div>
        <div className={classes.loginWebBody} style={{}}>
          <div className="registration-web-view-left-container" style={{ height: (window.innerWidth > 768 && window.innerWidth <= 1030) && 180 }}>
            {!showBenifits && !(window.innerWidth > 768 && window.innerWidth <= 1030) ? (
              <div className="registration-web-view-left-container-child">
                <div className="registration-web-view-left-image-container">
                  <img
                    src={
                      window.cordova
                        ? `${this.image}${"/RealDealWeb2.png"}`
                        : "/RealDealWeb2.png"
                    }
                    alt="img"
                    height="50%"
                    width="auto"
                    style={{
                      marginBottom: 15
                    }}
                  />
                  <span className="registration-web-view-left-portal-text">
                    Online portal for faster deals
                  </span>
                </div>
                <div className="registration-web-view-left-about-app-text">
                  <span>A modern one-window portal to book orders,</span>
                  <span>manage dispatch, and all sales related activities</span>
                  <span>with REAL Group</span>
                </div>
              </div>
            ) : (
              <div className="registration-web-view-left-show-benifits-main-container">
                <div className="registration-web-view-left-show-benifits-image-container" style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <img
                    src={
                      window.cordova
                        ? `${this.image}${"/RealDealWeb2.png"}`
                        : "/RealDealWeb2.png"
                    }
                    alt="img"
                    height="70px"
                    width="auto"
                    style={{
                      marginBottom: 15
                    }}
                  />
                  <span className="registration-web-view-left-portal-text">
                    Online portal for faster deals
                  </span>
                </div>

                <div className="registration-web-view-left-booking-benifits-container" style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="registration-web-view-left-explore-list-booking">
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <MaskGroupSVG />
                    </Avatar>
                    <div className="registration-web-view-left-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Live Rates
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        24*7 broadcasting live rates
                      </span>
                    </div>
                  </div>
                </div>

                <div className="registration-web-view-left-booking-benifits-container" style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="registration-web-view-left-explore-list-booking">
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <OrderBookingSVG />
                    </Avatar>
                    <div className="registration-web-view-left-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Contract Booking
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Book and manage your contract
                      </span>
                    </div>
                  </div>
                </div>
                <div className="registration-web-view-left-booking-benifits-container" style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="registration-web-view-left-explore-list-booking">
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <PinSVG />
                    </Avatar>
                    <div className="registration-web-view-left-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Live Contract Tracking
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Track your contract in real time
                      </span>
                    </div>
                  </div>
                </div>
                <div className="registration-web-view-left-booking-benifits-container" style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="registration-web-view-left-explore-list-booking">
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <OrderBookingSVG />
                    </Avatar>
                    <div className="registration-web-view-left-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Loading advice submission
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Fast and efficient submission
                      </span>
                    </div>
                  </div>
                </div>

                <div className="registration-web-view-left-booking-benifits-container" style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="registration-web-view-left-explore-list-booking">
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <InvoiceSVG />
                    </Avatar>
                    <div className="registration-web-view-left-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Automated Invoice and Bill
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Real time invoice and billing
                      </span>
                    </div>
                  </div>
                </div>
                <div className="registration-web-view-left-booking-benifits-container" style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="registration-web-view-left-explore-list-booking">
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <CommerceSVG />
                    </Avatar>
                    <div className="registration-web-view-left-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Payment Tracking
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Track payment in one click
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classes.loginWebRightContainer}>
            <div className="registration-web-view-right-header ">
              <span>{/* <Help /> */}</span>
            </div>
            <div className={classes.loginWebFormContaine}>
              <div className="registration-web-view-right-form-start">
                <SwipeableViews
                  index={this.state.activeStep}
                  onChangeIndex={this.handleSwipeableIndex}
                  disabled
                  containerStyle={{ height: "100%" }}
                  style={{ height: "100%" }}
                >
                  <RegisterSelectCategoryWeb
                    handleSelectCategory={handleSelectCategory}
                  />
                  <RegisterLoginCredentialWeb
                    contactNumber={contactNumber}
                    email={email}
                    otp={otp}
                    handleTextChange={handleTextChange}
                    phoneExist={this.props.phoneExist}
                    emailExist={this.props.emailExist}
                    emailExistCheck={this.emailExistCheck}
                    resetEmailExistCheck={this.resetEmailExistCheck}
                    activeStep4EmailFocus={this.activeStep4EmailFocus}
                    activeStep4EmailBlur={this.activeStep4EmailBlur}
                    activeStep4PhoneFocus={this.activeStep4PhoneFocus}
                    activeStep4PhoneBlur={this.activeStep4PhoneBlur}
                    resetOTP={this.props.resetOTP}
                    sendOTP={this.props.sendOTP}
                    otpSendInEmail={this.props.otpSendInEmail}
                    emailOTP={emailOTP}
                    resetEmailOtp={this.props.resetEmailOtp}
                    verifyEmailCliked={this.verifyEmailCliked}
                    handleOnBlurEmailOTP={this.handleOnBlurEmailOTP}
                    handleChangeEmailOTPNumber={this.handleChangeEmailOTPNumber}
                    handleChangeOtpNumber={this.handleChangeOtpNumber}
                    timerText={this.props.timerText}
                    timerOn={this.props.timerOn}
                    handleTimerOn={this.props.handleTimerOn}
                    timer={this.props.timer}
                    GetOtpEmailRegistration={this.props.GetOtpEmailRegistration}
                    VerifyOtpEmailRegistration={this.props.VerifyOtpEmailRegistration}
                    GetOtpPhoneRegistration={this.props.GetOtpPhoneRegistration}
                    VerifyOtpPhoneRegistration={this.props.VerifyOtpPhoneRegistration}
                    OtpEmailText={OtpEmailText}
                    OtpPhoneText={OtpPhoneText}
                    handleOtpValiadationText={this.handleOtpValiadationText}
                  />
                  <RegisterCreatePasswordWeb
                    password={password}
                    confirmPassword={confirmPassword}
                    handleTextChange={handleTextChange}
                    handleOnFocusPassword={this.handleOnFocusPassword}
                    handleOnBlurPassword={this.handleOnBlurPassword}
                  />
                  <RegisterPersonalInformationWeb
                    firstName={firstName}
                    lastName={lastName}
                    dob={dob}
                    handleTextChange={handleTextChange}
                    handleOnFocusFirstName={this.handleOnFocusFirstName}
                    handleOnBlurFirstName={this.handleOnBlurFirstName}
                    handleOnFucusLastName={this.handleOnFucusLastName}
                    handleOnBlurLastName={this.handleOnBlurLastName}
                    handleContactPerson={this.props.handleContactPerson}
                    contactPerson={contactPerson}
                    contactPersonName={contactPersonName}
                    contactPersonEmail={contactPersonEmail}
                    contactPersonContactNumber={contactPersonContactNumber}
                    handleTextChangeContactPerson={
                      this.handleTextChangeContactPerson
                    }
                    handleAddMoreContactPerson={this.handleAddMoreContactPerson}
                    contactPersonArray={contactPersonArray}
                  />
                  <RegisterGstNumberWeb
                    Gst_number={Gst_number}
                    handleTextChange={handleTextChange}
                    handleShowGSTdetails={this.handleShowGSTdetails}
                    handleResetGSTdetails={this.handleResetGSTdetails}
                    legal_name_of_business={this.props.legal_name_of_business}
                    date_of_registration={this.props.date_of_registration}
                    country={this.props.country}
                    centre_jurisdiction={this.props.centre_jurisdiction}
                    state_jurisdiction={this.props.state_jurisdiction}
                    constitution_of_business={
                      this.props.constitution_of_business
                    }
                    taxpayer_type={this.props.taxpayer_type}
                    GSTIN_or_UIN_Status={this.props.GSTIN_or_UIN_Status}
                    date_of_cancellation={this.props.date_of_cancellation}
                    field_visit_conducted={this.props.field_visit_conducted}
                    trade_name={this.props.trade_name}
                    handleGetGSTInfo={this.props.handleGetGSTInfo}
                    gstNumberCheck={gstNumberCheck}
                    gstErrorMessage={this.props.gstErrorMessage}
                    gstLoader={this.props.gstLoader}
                    resetGstDetail={this.props.resetGstDetail}
                    gstData={this.props.gstData}
                    gstNumberAlreadyRegistered={
                      this.props.gstNumberAlreadyRegistered
                    }
                    state={state}
                    pincode={pincode}
                    addressLine_one={addressLine_one}
                    handleDontHaveGST={this.handleDontHaveGST}
                    panNumber={panNumber}
                    panCardHolderName={panCardHolderName}
                    companyName={companyName}
                    panCardImageFile={panCardImageFile}
                    panImageFileTypeError={panImageFileTypeError}
                    handlePanCardImageFile={this.props.handlePanCardImageFile}
                    handlePancardActiveStatus={this.handlePancardActiveStatus}
                    noInternetConnection={this.state.noInternetConnection}
                    noInternetConnectionDialog={this.noInternetConnectionDialog}
                  />
                  {this.state.activeStep === 4 ||
                    (this.state.activeStep === 5 &&
                      gstNumberCheck === "valid") ? (
                    <RegisterSuccessfullWeb
                      {...this.props}
                      legal_name_of_business={legal_name_of_business}
                      date_of_registration={date_of_registration}
                      Gst_number={Gst_number}
                      email={email}
                      contactNumber={contactNumber}
                      registrationLoader={this.props.registrationLoader}
                      panNumber={panNumber}
                    />
                  ) : (
                    <RegistrationPanAddressWeb
                      panAddressLineOne={panAddressLineOne}
                      panAddressLineTwo={panAddressLineTwo}
                      panAddressLineThree={panAddressLineThree}
                      panAddressCountry={panAddressCountry}
                      panAddressState={panAddressState}
                      panAddressCity={panAddressCity}
                      panAdressPincode={panAdressPincode}
                      handleTextChange={handleTextChange}
                      countries={countries}
                      getStatesFromMasterData={
                        this.props.getStatesFromMasterData
                      }
                      states={states}
                      getCityFromMasterData={this.props.getCityFromMasterData}
                      cities={cities}
                      handleSelectCity={this.props.handleSelectCity}
                      panNumber={panNumber}
                      noInternetConnection={this.state.noInternetConnection}
                      noInternetConnectionDialog={
                        this.noInternetConnectionDialog
                      }
                    />
                  )}
                  <RegisterSuccessfullWeb
                    {...this.props}
                    legal_name_of_business={legal_name_of_business}
                    date_of_registration={date_of_registration}
                    Gst_number={Gst_number}
                    email={email}
                    contactNumber={contactNumber}
                    registrationLoader={this.props.registrationLoader}
                    panNumber={panNumber}
                  />
                </SwipeableViews>
                {(this.state.activeStep === 5 && gstNumberCheck === "valid") ||
                  this.state.activeStep === 6 ? null : (
                  <MobileStepper
                    style={{
                      position: "absolute",
                      bottom: "3px",
                      width: "100%",
                    }}
                    variant="dots"
                    steps={
                      this.state.activeStep === 4 && gstNumberCheck === "valid"
                        ? 5
                        : 6
                    }
                    position="static"
                    activeStep={this.state.activeStep}
                    className={classes.root}
                    nextButton={
                      (activeStep === 4 && gstNumberCheck === "valid") || activeStep === 5 ? (
                        <SaveButton
                          // variant="contained"
                          disabled={
                            this.state.activeStep === 0 && category === ""
                              ? true
                              : false ||
                              (this.state.activeStep === 1 &&
                                !(OtpEmailText === "OTP Verified Successfully" ||
                                  OtpPhoneText === "OTP Verified Successfully")) ||
                              (this.state.activeStep === 2 &&
                                password === "") ||
                              (this.state.activeStep === 2 &&
                                password.length < 6) ||
                              (this.state.activeStep === 2 &&
                                password !== confirmPassword) ||
                              (this.state.activeStep === 3 &&
                                firstName === "") ||
                              (this.state.activeStep === 3 &&
                                lastName === "") ||
                              (this.state.activeStep === 3 && dob === "") ||
                              (this.state.activeStep === 3 &&
                                contactPersonEmail !== "" &&
                                EmailValidator.validate(contactPersonEmail) ===
                                false) ||
                              (this.state.activeStep === 3 &&
                                contactPersonEmail !== "" &&
                                EmailValidator.validate(contactPersonEmail) ===
                                true &&
                                contactPersonContactNumber === "") ||
                              (contactPersonContactNumber !== "" &&
                                contactPersonContactNumber.length < 10) ||
                              (dontHaveGST === false &&
                                activeStep === 4 &&
                                Gst_number.length < 15) ||
                              (dontHaveGST === false &&
                                this.state.activeStep === 4 &&
                                gstNumberCheck === "pending") ||
                              (dontHaveGST === false &&
                                this.state.activeStep === 4 &&
                                gstNumberCheck === "invalid") ||
                              (dontHaveGST === true &&
                                activeStep === 4 &&
                                panNumber === "") ||
                              (dontHaveGST === true &&
                                activeStep === 4 &&
                                panNumber.length < 10) ||
                              (dontHaveGST === true &&
                                activeStep === 4 &&
                                panCardHolderName === "") ||
                              (dontHaveGST === true &&
                                activeStep === 4 &&
                                companyName === "") ||
                              (dontHaveGST === true &&
                                activeStep === 4 &&
                                panCardImageFile === null) ||
                              (dontHaveGST === true &&
                                activeStep === 4 &&
                                panImageFileTypeError) ||
                              (dontHaveGST === true &&
                                activeStep === 4 &&
                                pancardActiveStatus === false) ||
                              (this.state.activeStep === 5 &&
                                panAddressCountry === "") ||
                              (this.state.activeStep === 5 &&
                                panAddressState === "") ||
                              (this.state.activeStep === 5 &&
                                panAddressCity === "") ||
                              (this.state.activeStep === 5 &&
                                panAdressPincode === "") ||
                              (this.state.activeStep === 5 &&
                                panAdressPincode.length < 6)
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.handleActiveStep()
                          }}
                          isLoading={registrationSubmitLoader}
                        >
                          Submit
                        </SaveButton>
                      )
                        :
                        (
                          <Button
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleRegistration();
                            }}
                            disabled={
                              this.state.activeStep === 0 && category === ""
                                ? true
                                : false ||
                                // (this.state.activeStep === 1 && email === "") ||
                                // (this.state.activeStep === 1 &&
                                //   EmailValidator.validate(email) === false) ||
                                // (this.state.activeStep === 1 &&
                                //   emailAlreadyExist) ||
                                // (this.state.activeStep === 1 && emailFocus) ||
                                // (this.state.activeStep === 1 && phoneFocus) ||
                                // (this.state.activeStep === 1 &&
                                //   this.state.otpNumber !== otp) ||
                                // (this.state.activeStep === 1 &&
                                //   this.state.emailOTPNumber !== emailOTP) ||
                                // (this.state.activeStep === 1 &&
                                //   contactNumber.length < 10) ||
                                // (this.state.activeStep === 1 &&
                                //   contactNumber === "") ||
                                // (this.state.activeStep === 1 && otp === "") ||
                                (this.state.activeStep === 1 &&
                                  !(OtpEmailText === "OTP Verified Successfully" ||
                                    OtpPhoneText === "OTP Verified Successfully")) ||
                                (this.state.activeStep === 2 &&
                                  password === "") ||
                                (this.state.activeStep === 2 &&
                                  password.length < 6) ||
                                (this.state.activeStep === 2 &&
                                  password !== confirmPassword) ||
                                (this.state.activeStep === 3 &&
                                  firstName === "") ||
                                (this.state.activeStep === 3 &&
                                  lastName === "") ||
                                (this.state.activeStep === 3 && dob === "") ||
                                (this.state.activeStep === 3 &&
                                  contactPersonEmail !== "" &&
                                  EmailValidator.validate(contactPersonEmail) ===
                                  false) ||
                                (this.state.activeStep === 3 &&
                                  contactPersonEmail !== "" &&
                                  EmailValidator.validate(contactPersonEmail) ===
                                  true &&
                                  contactPersonContactNumber === "") ||
                                (contactPersonContactNumber !== "" &&
                                  contactPersonContactNumber.length < 10) ||
                                (dontHaveGST === false &&
                                  activeStep === 4 &&
                                  Gst_number.length < 15) ||
                                (dontHaveGST === false &&
                                  this.state.activeStep === 4 &&
                                  gstNumberCheck === "pending") ||
                                (dontHaveGST === false &&
                                  this.state.activeStep === 4 &&
                                  gstNumberCheck === "invalid") ||
                                (dontHaveGST === true &&
                                  activeStep === 4 &&
                                  panNumber === "") ||
                                (dontHaveGST === true &&
                                  activeStep === 4 &&
                                  panNumber.length < 10) ||
                                (dontHaveGST === true &&
                                  activeStep === 4 &&
                                  panCardHolderName === "") ||
                                (dontHaveGST === true &&
                                  activeStep === 4 &&
                                  companyName === "") ||
                                (dontHaveGST === true &&
                                  activeStep === 4 &&
                                  panCardImageFile === null) ||
                                (dontHaveGST === true &&
                                  activeStep === 4 &&
                                  panImageFileTypeError) ||
                                (dontHaveGST === true &&
                                  activeStep === 4 &&
                                  pancardActiveStatus === false) ||
                                (this.state.activeStep === 5 &&
                                  panAddressCountry === "") ||
                                (this.state.activeStep === 5 &&
                                  panAddressState === "") ||
                                (this.state.activeStep === 5 &&
                                  panAddressCity === "") ||
                                (this.state.activeStep === 5 &&
                                  panAdressPincode === "") ||
                                (this.state.activeStep === 5 &&
                                  panAdressPincode.length < 6)
                            }
                          >
                            {this.state.activeStep === 4 &&
                              gstNumberCheck === "valid"
                              ? "SUBMIT"
                              : this.state.activeStep === 5
                                ? "Submit"
                                : "Next"}
                            {theme.direction === "rtl" ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>
                        )
                    }
                    backButton={
                      <Button
                        style={{
                          color: this.state.activeStep === 0 ? "#000000" : null,
                        }}
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.activeStep === 0) {
                            this.props.history.push("/login");
                          } else {
                            this.handleBackActiveStep();
                          }
                        }}
                      //disabled={this.state.activeStep === 0}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        {activeStep === 0 ? "Cancel" : "Back"}
                      </Button>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(RegistrationWeb);
