import { useEffect, useState } from "react";
import {
  checkApiError,
  handleSnackbarMessageBox,
  startOFMonth,
  endOFMonth,
  arraySortBykeyAllBrowser,
  startOFWeek,
  endOFWeek,
  monthKeys,
  weekKeys,
  checkInvalidValue,
} from "../../../../methods";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../common/SnackbarComponent";
import { SimpleTable, ToggleButton, WidgetTop } from "./commanWidget";
import { Paper } from "@mui/material";
import moment from "moment";

const styles = {
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '96%',
    margin: '0px auto'
  },
  tableContainer: (isMobile) => ({
    width: "100%",
    maxHeight: 400,
    overflowY: 'auto',
  }),
}

const ItemCategoryCodelist = [
  "FRM101002",
  "FHBW01067",
  "FWD101013",
  "FWD102001",
  "FHG101011",
]

const DashboardItemDifferenceTable = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mounted, setMounted] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    label: 'M',
    startDate: startOFMonth,
    endDate: endOFMonth
  })
  const [financialData, setFinancialData] = useState([]);
  const [itemCategoryColumnFields, setItemCategoryColumnFields] = useState([]);
  const [itemCategorySalesDiffData, setItemCategorySalesDiffData] = useState([])
  const [showProgress, setShowProgress] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState({
    tableDataApi: null,
  });
  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: "",
    snackbarType: ""
  })

  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: ""
    })
  };

  const handleCatchError = (error) => {
    handleOpenSnackbar(
      handleSnackbarMessageBox(checkApiError(error?.response)),
      "error"
    );
    setTimeout(() => {
      handleCloseSnackbar();
    }, 4000);
  }

  const handleItemCategoryCalDiff = (item1, item2) => {
    if (Number(item1) === Number(0) || Number(item2) === Number(0)) {
      return null
    }
    else {
      const netAmountPerQty1 = item1 !== 0
        ? Number(item1.net_sales_qty) !== Number(0)
          ? Number((Number(item1.net_sales_amt) / Number(item1.net_sales_qty)) || 0)
          : 0
        : 0;
      const netAmountPerQty2 = item2 !== 0
        ? Number(item2.net_sales_qty) !== Number(0)
          ? Number((Number(item2.net_sales_amt) / Number(item2.net_sales_qty)) || 0)
          : 0
        : 0;
      // console.log(netAmountPerQty1, netAmountPerQty2, Number(netAmountPerQty1 - netAmountPerQty2))
      return Number(netAmountPerQty1 - netAmountPerQty2)
    }
  }

  const transformTableData = (data, graphType) => {
    const _financialData = [];

    if (['week', 'W'].includes(graphType)) {
      for (var d = new Date(startOFWeek); d <= new Date(endOFWeek); d.setDate(d.getDate() + 1)) {
        let objRow = {};
        itemCategoryColumnFields.forEach(k => {
          let obj1 = data[k.split(' - ')[0]]
          let obj2 = data[k.split(' - ')[1]]
          const dayOfWeek = moment(d).format('dddd');
          Object.assign(objRow, { date: dayOfWeek })
          const dow = moment(d).format('YYYY-MM-DD');
          const data1 = obj1?.hasOwnProperty(dow) ? obj1[dow] : 0;
          const data2 = obj2?.hasOwnProperty(dow) ? obj2[dow] : 0;
          // console.log(dow, k, obj1, obj2, data1, data2, obj1?.hasOwnProperty(dow))
          const val = handleItemCategoryCalDiff(data1, data2)
          // console.log(val)
          Object.assign(objRow, { [k]: val })
        });
        _financialData.push(objRow);
      }
    } else if (['month', 'M', 'today', 'T', 'date_range'].includes(graphType)) {
      for (var d = new Date(selectedDate.startDate); d <= new Date(selectedDate.endDate); d.setDate(d.getDate() + 1)) {
        let objRow = {};
        itemCategoryColumnFields.forEach(k => {
          let obj1 = data[k.split(' - ')[0]]
          let obj2 = data[k.split(' - ')[1]]
          const rowDate = moment(d).format('DD-MMM-YY');
          Object.assign(objRow, { date: rowDate })
          const dow = moment(d).format('YYYY-MM-DD');
          const data1 = obj1?.hasOwnProperty(dow) ? obj1[dow] : 0;
          const data2 = obj2?.hasOwnProperty(dow) ? obj2[dow] : 0;
          const val = handleItemCategoryCalDiff(data1, data2)
          Object.assign(objRow, { [k]: val })
        });
        _financialData.push(objRow);
      }
    } else if (['year', 'Y'].includes(graphType)) {
      let _formatedData = []
      for (var d = new Date(selectedDate.startDate); d <= new Date(selectedDate.endDate); d.setDate(d.getDate() + 1)) {
        let objRow = {};
        itemCategoryColumnFields.forEach(k => {
          let obj1 = data[k.split(' - ')[0]]
          let obj2 = data[k.split(' - ')[1]]
          const rowDate = moment(d).format('DD-MMM-YY');
          Object.assign(objRow, { date: rowDate })
          const dow = moment(d).format('YYYY-MM-DD');
          const data1 = obj1?.hasOwnProperty(dow) ? obj1[dow] : 0;
          const data2 = obj2?.hasOwnProperty(dow) ? obj2[dow] : 0;
          const val = handleItemCategoryCalDiff(data1, data2)
          Object.assign(objRow, { [k]: val })
        });
        _formatedData.push(objRow);
      }
      // console.log(_formatedData)
      const monthSums = {}
      const NotNullMonthColumns = Array.from({ length: 12 }, (_, i) => []);
      for (const obj of _formatedData) {
        const month = new Date(obj.date).getMonth()
        if (!monthSums[month]) {
          monthSums[month] = {};
        }
        for (const key in obj) {
          if (key !== "date") {
            if (obj[key] !== null) {
              NotNullMonthColumns[month]?.push(key)
              // console.log(obj[key], month, key)
            }
            monthSums[month][key] = (monthSums[month][key] || 0) + obj[key]
          }
          else if (key === "date") {
            monthSums[month][key] = moment(obj.date).format('MMMM')
          }
        }
      }
      // console.log(monthSums)
      const monthlyData = [];
      for (let month = 0; month < 12; month++) {
        for (const key in monthSums[0]) {
          if (key !== 'date') {
            if (!NotNullMonthColumns[month].includes(key)) {
              monthSums[month][key] = null
            }
          }
        }
        if (monthSums[month]) {
          monthlyData.push({ ...monthSums[month], });
        }
      }
      _financialData.push(...monthlyData.slice(3, 12))
      _financialData.push(...monthlyData.slice(0, 3))
    }
    console.log("hdjhf", _financialData)
    setFinancialData(_financialData)
  }

  const handleTableData = (fromDate, toDate,) => {
    const newCancelTokenSource = handleCancelToken('tableDataApi')
    setShowProgress(true)
    const payload = {
      from_date: fromDate,
      to_date: toDate,
    }
    axios.post(`${APIROUTES.ITEM_CATEGORY_SALES_DIFFERENCES_DATA}`,
      payload,
      { cancelToken: newCancelTokenSource.token, }
    )
      .then((response) => {
        if (response.status === 200) {

          let data = response.data['Sales ItemCategory Club-Wise Data']
          setItemCategorySalesDiffData(data)
          setShowProgress(false)
        }
      })
      .catch((error) => {
        setShowProgress(false)
        if (axios.isCancel(error)) {
        } else {
          handleCatchError(error)
        }
      });
  }

  const dateChange = (data) => {
    setSelectedDate({
      label: data.label,
      startDate: data.startDate,
      endDate: data.endDate
    })
  }

  const handleCancelToken = (key) => {
    if (cancelTokenSource[key]) {
      cancelTokenSource[key].cancel('Operation canceled');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(prevValue => { return { ...prevValue, [key]: newCancelTokenSource } });
    return newCancelTokenSource;
  }

  useEffect(() => {
    transformTableData(itemCategorySalesDiffData, selectedDate.label)
  }, [itemCategorySalesDiffData])

  useEffect(() => {
    if (mounted) {
      handleTableData(selectedDate.startDate, selectedDate.endDate,)
    }
  }, [selectedDate])

  useEffect(() => {
    if (props.itemCategoryDropdownList && props.itemCategoryDropdownList.length > 0) {
      let itemCategoryList = []
      ItemCategoryCodelist.map(d => itemCategoryList.push(props.itemCategoryDropdownList.find(item => item.item_category_code === d)?.category_name))
      // console.log(itemCategoryList)
      let columnList = []
      columnList.push(`${itemCategoryList[0]} - ${itemCategoryList[1]}`)
      columnList.push(`${itemCategoryList[2]} - ${itemCategoryList[1]}`)
      columnList.push(`${itemCategoryList[3]} - ${itemCategoryList[2]}`)
      columnList.push(`${itemCategoryList[4]} - ${itemCategoryList[2]}`)
      // console.log(columnList)
      setItemCategoryColumnFields(columnList)
    }
  }, [props.itemCategoryDropdownList])

  useEffect(() => {
    // console.log("called 1")
    handleTableData(selectedDate.startDate, selectedDate.endDate,)
    setMounted(true)
    return () => {
      setMounted(false)
    };
  }, [])

  useEffect(() => {
    // Cleanup function to cancel the previous request when component unmounts
    return () => {
      Object.entries(cancelTokenSource)
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => (cancelTokenSource[key].cancel("Operation canceled")));
    };
  }, [cancelTokenSource]);

  const DATEBUTTONS = [
    { label: 'Y', function: dateChange },
    { label: 'M', function: dateChange, defaultSelected: true },
    { label: 'W', function: dateChange },
    { label: 'T', function: dateChange },
    { label: 'date_range', function: dateChange },
  ]

  const TABLEDATA = [
    {
      label: selectedDate.label === 'Y'
        ? "Month"
        : selectedDate.label === 'M'
          ? "Date"
          : selectedDate.label === 'W'
            ? "Week"
            : selectedDate.label === 'T'
              ? "Today"
              : "Date Range",
      key: 'date',
      id: 'no_header',
      minWidth: 160
    },
    { label: itemCategoryColumnFields[0], key: itemCategoryColumnFields[0], minWidth: 130 },
    { label: itemCategoryColumnFields[1], key: itemCategoryColumnFields[1], minWidth: 130 },
    { label: itemCategoryColumnFields[2], key: itemCategoryColumnFields[2], minWidth: 130 },
    { label: itemCategoryColumnFields[3], key: itemCategoryColumnFields[3], minWidth: 130 },
  ]
  // console.log(dataKeys)
  return (
    <div style={{ paddingBottom: 30 }}>
      <WidgetTop
        {...props}
        widgetTitle='Item Category Sales Differences'
        widgetSubtitle="Excluding Intercompany"
        isMobile={isMobile}
        plantFilter={false}
        itemCategoryFilter={false}
        dateButtons={DATEBUTTONS}
        itemCategoryDropdownList={props?.itemCategoryDropdownList}
        selectedDate={selectedDate?.label}
        pdfCsvButton={false}
      />
      <Paper style={styles.paperContainer}>
        {console.log(TABLEDATA, financialData)}
        <SimpleTable
          {...props}
          isMobile={isMobile}
          tableData={TABLEDATA}
          showProgress={showProgress}
          rows={financialData}
          backgroundOddEven={false}
          tableContainerStyle={styles.tableContainer(isMobile)}
          selectedDate={selectedDate?.label}
          stickyHeader={true}
          widget="dashboard_item_category_sales_difference"
        />
      </Paper>
      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </div>
  )
}

export default DashboardItemDifferenceTable;