import React from "react";
export default class CommerceSVG extends React.Component {
  render() {
    return (
      <svg
        style={{ fill: "white", height: "20px", width: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="18.537"
        height="18.535"
        viewBox="0 0 18.537 18.535"
      >
        <g transform="translate(5.53 13.897)">
          <path
            class="a"
            d="M153.467,384.148a.379.379,0,0,0-.021-.068.368.368,0,0,0-.033-.062.352.352,0,0,0-.1-.1.357.357,0,0,0-.063-.033.341.341,0,0,0-.068-.021.357.357,0,0,0-.141,0,.351.351,0,0,0-.068.021.366.366,0,0,0-.062.033.362.362,0,0,0-.133.162.352.352,0,0,0-.021.068.347.347,0,0,0,0,.141.34.34,0,0,0,.021.068.334.334,0,0,0,.033.063.351.351,0,0,0,.1.1.364.364,0,0,0,.062.033.351.351,0,0,0,.068.021.348.348,0,0,0,.141,0,.341.341,0,0,0,.068-.021.357.357,0,0,0,.063-.033.352.352,0,0,0,.1-.1.356.356,0,0,0,.033-.063.366.366,0,0,0,.021-.068.348.348,0,0,0,0-.141Z"
            transform="translate(-152.75 -383.857)"
          />
        </g>
        <g transform="translate(0 11.22)">
          <path
            class="a"
            d="M16.654,313l0-.011a1.506,1.506,0,0,0-2.04-.752l-2.522.7a1.653,1.653,0,0,0-1.024-1.008l-.01,0-2.773-.941a2.86,2.86,0,0,0-2.307-1.062,2.9,2.9,0,0,0-2.261,1.356H.362A.362.362,0,0,0,0,311.64V316.2a.362.362,0,0,0,.362.362H2.515a.362.362,0,0,0,.362-.362v-.313h1.01a.362.362,0,0,0,.362-.362v-.1a2.567,2.567,0,0,1,1.323.475,21.915,21.915,0,0,0,2.175,1.111l.016.007a3.25,3.25,0,0,0,1.165.22,2.886,2.886,0,0,0,1.2-.257l5.575-2,.029-.012A1.545,1.545,0,0,0,16.654,313Zm-14.5,2.841H.724V312H2.153Zm1.372-.675H2.877V312h.647Zm11.917-.86-5.573,2-.016.006-.015.006a2.347,2.347,0,0,1-1.8.031,20.972,20.972,0,0,1-2.1-1.074,3.259,3.259,0,0,0-1.684-.573v-2.916a2.236,2.236,0,0,1,1.726-1.138,2.089,2.089,0,0,1,1.772.833.362.362,0,0,0,.192.153l2.877.977a.812.812,0,0,1,.591.958.705.705,0,0,1-.867.487L7.3,313.026a.362.362,0,1,0-.219.69l3.249,1.03.01,0a1.421,1.421,0,0,0,1.087-.126,1.42,1.42,0,0,0,.679-.858q.012-.044.022-.088l2.718-.753c.393-.134.908-.182,1.137.346C16.219,313.891,15.6,314.232,15.441,314.308Z"
            transform="translate(0 -309.922)"
          />
        </g>
        <g transform="translate(12.283 4.47)">
          <path
            class="a"
            d="M339.967,123.789a.35.35,0,0,0-.021-.068.342.342,0,0,0-.033-.062.352.352,0,0,0-.1-.1.368.368,0,0,0-.062-.033.361.361,0,0,0-.209-.021.339.339,0,0,0-.068.021.357.357,0,0,0-.063.033.353.353,0,0,0-.1.1.369.369,0,0,0-.033.062.349.349,0,0,0-.021.068.347.347,0,0,0,0,.141.338.338,0,0,0,.021.068.358.358,0,0,0,.033.063.353.353,0,0,0,.1.1.357.357,0,0,0,.063.033.366.366,0,0,0,.068.021.356.356,0,0,0,.071.007.363.363,0,0,0,.071-.007.383.383,0,0,0,.068-.021.368.368,0,0,0,.062-.033.352.352,0,0,0,.1-.1.334.334,0,0,0,.033-.063.339.339,0,0,0,.021-.068.365.365,0,0,0,0-.141Z"
            transform="translate(-339.25 -123.498)"
          />
        </g>
        <g transform="translate(1.075 0)">
          <path
            class="a"
            d="M46.791,1.249H44.638a.362.362,0,0,0-.362.362v.313h-1.01a.362.362,0,0,0-.362.362v.1a2.567,2.567,0,0,1-1.322-.475A21.908,21.908,0,0,0,39.407.805L39.391.8a3.08,3.08,0,0,0-2.364.037l-1.457.522L34.342.129a.362.362,0,0,0-.512,0L29.8,4.161a.359.359,0,0,0,0,.512l6.889,6.889a.391.391,0,0,0,.512,0L40.88,7.882c.1.008.2.013.3.013A2.9,2.9,0,0,0,43.44,6.538h3.351a.362.362,0,0,0,.362-.362V1.611A.362.362,0,0,0,46.791,1.249ZM34.086.9l.825.825a1.578,1.578,0,0,1-1.65,0ZM31.391,5.242l-.825-.825.825-.825a1.579,1.579,0,0,1,0,1.65Zm5.552,5.552-.825-.825a1.576,1.576,0,0,1,1.65,0ZM38.29,9.448a2.3,2.3,0,0,0-2.693,0L31.913,5.764a2.3,2.3,0,0,0,0-2.694l.826-.826a2.3,2.3,0,0,0,2.694,0l.783.783a1.435,1.435,0,0,0-1.077.789,2.064,2.064,0,1,0,2.373,2.554l1.222.415a2.312,2.312,0,0,0,.384,1.835Zm-2.2-3.559.01,0,.724.246A1.34,1.34,0,1,1,35,4.607,1.6,1.6,0,0,0,36.089,5.888ZM39.639,8.1a1.584,1.584,0,0,1-.231-.753,2.815,2.815,0,0,0,.639.346ZM42.9,6.032A2.236,2.236,0,0,1,41.178,7.17a2.635,2.635,0,0,1-.378-.027h0a2.074,2.074,0,0,1-1.341-.734l-.044-.059a.361.361,0,0,0-.2-.166l-2.879-.977a.812.812,0,0,1-.591-.958h0a.705.705,0,0,1,.866-.486L39.856,4.79a.362.362,0,0,0,.219-.69L37.5,3.282l-.023-.023h0L36.136,1.924l1.148-.411.012,0,0,0,.015-.006a2.346,2.346,0,0,1,1.8-.031,20.972,20.972,0,0,1,2.1,1.074,3.258,3.258,0,0,0,1.684.573Zm1.372-.218h-.647V2.648h.647Zm2.153,0H45V1.974h1.429Z"
            transform="translate(-29.691 -0.023)"
          />
        </g>
      </svg>
    );
  }
}
