import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  CardContent,
  CardActions,
  Card,
  Grow,
  CardActionArea,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import RiplEwayBillPdfDownload from "./RiplEwayBillPdfDownload";
import RiplInvoicePdfDownload from "./RiplInvoicePdfDownload";
import ExitPassDownload from "./ExitPassDownload";
import moment from "moment";
import {
  drawerTextColor,
  primaryColor,
} from "../../../constants/internal/colors";
const useStyles = makeStyles({
  cardStyle: {
    borderRadius: "30px",
    minWidth: 275,
    maxWidth: 400,
    height: 250,
  },
  cardHeaderTitle: { display: "flex", justifyContent: "space-between" },
  pdfTitleWayBill: {
    paddingTop: "20px",
    width: "100%",
    justifyContent: "space-evenly",
  },
  pdfTitleInvoice: {
    paddingTop: "45px",
    width: "100%",
    justifyContent: "space-evenly",
  },
  eInvoiceDetails: {
    display: "flex",
    flexDirection: "column",
    background: drawerTextColor,
    placeContent: "center",
    placeItems: "center",
    justifyContent: "space-evenly",
  },
  pdfActions: {
    display: "flex",
    placeItems: "center",
    placeContent: "center",
    justifyContent: "space-around",
  },
  loaderDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderStyle: { color: primaryColor },
});
export default function InvoiceWayBillEinvoiceViewDownloadCard(props) {
  const classes = useStyles();
  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(props.documentFor === "Exit Pass"
        ? { timeout: 3000 }
        : props.documentFor === "E-Way Bill"
          ? { timeout: 2000 }
          : { timeout: 1000 })}
    >
      <Card className={classes.cardStyle} elevation={5}>
        <CardHeader
          title={
            <div className={classes.cardHeaderTitle}>
              <Typography
                style={{
                  opacity: 0.4,
                  fontSize: "1rem",
                }}
              >
                {props?.documentFor || ""}
              </Typography>
              {props.documentFor === "E-Invoice" ? (
                <Typography
                  style={{
                    fontSize: "1rem",
                    color:
                      props?.eInvoiveResponse?.IrnStatus === "ACT"
                        ? "green"
                        : "red",
                  }}
                >
                  {props?.eInvoiveResponse?.IrnStatus === "ACT"
                    ? "Active"
                    : props?.eInvoiveResponse?.IrnStatus === "CNL"
                      ? "Cancelled"
                      : ""}
                </Typography>
              ) : null}
            </div>
          }
        />
        {props?.documentFor === "E-Way Bill" ? (
          props?.ewayPostDataLoader ? (
            <div className={classes.loaderDiv}>
              <CircularProgress disableShrink className={classes.loaderStyle} />
            </div>
          ) : !props?.eWayBillResponse?.EwbNo ? (
            !props?.eWayBillResponse?.EWayBill ? (
              <CardActionArea style={{ padding: 20 }}>
                <Typography
                  style={{
                    opacity: 0.4,
                    fontSize: "1rem",
                    color: "red",
                  }}
                >
                  {props?.ewayErrorResponseMessage ||
                    "Error occured while creating e-way bill."}
                </Typography>
              </CardActionArea>
            ) : (
              <>
                <CardActionArea>
                  <CardContent>
                    <div className={classes.eInvoiceDetails}>
                      <Typography
                        style={{
                          opacity: 0.4,
                          fontSize: "1rem",
                        }}
                      >
                        {`${props?.eWayBillResponse?.EWayBill
                          ? `Number : ${props?.eWayBillResponse?.EWayBill || ""
                          }`
                          : ""
                          }`}
                      </Typography>
                      <Typography
                        style={{
                          opacity: 0.4,
                          fontSize: "1rem",
                        }}
                      >
                        {`${props?.eWayBillResponse?.Date
                          ? `Date & Time : ${props?.eWayBillResponse?.Date}`
                          : ""
                          }
                            `}
                      </Typography>
                      <Typography
                        style={{
                          opacity: 0.4,
                          fontSize: "1rem",
                        }}
                      >
                        {props?.eWayBillResponse?.ValidUpTo
                          ? `Valid Upto :  ${props?.eWayBillResponse?.ValidUpTo}`
                          : ""}
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div className={classes.pdfTitleWayBill}>
                    <div className={classes.pdfActions}>
                      <RiplEwayBillPdfDownload
                        pdfOperation={"VIEW"}
                        {...props}
                      />
                      <RiplEwayBillPdfDownload
                        pdfOperation={"DOWNLOAD"}
                        {...props}
                      />
                    </div>
                  </div>
                </CardActions>
              </>
            )
          ) : (
            <>
              <CardActionArea>
                <CardContent>
                  <div className={classes.eInvoiceDetails}>
                    <Typography
                      style={{
                        opacity: 0.4,
                        fontSize: "1rem",
                      }}
                    >
                      {`${props?.eWayBillResponse?.EwbNo
                        ? `Number : ${props?.eWayBillResponse?.EwbNo || ""}`
                        : ""
                        }`}
                    </Typography>
                    <Typography
                      style={{
                        opacity: 0.4,
                        fontSize: "1rem",
                      }}
                    >
                      {`${props?.eWayBillResponse?.EwbDt
                        ? `Date & Time : ${moment(
                          props?.eWayBillResponse?.EwbDt,
                          "YYYY-MM-DD h:mm:ss"
                        ).format("DD/MM/YYYY LTS")}`
                        : ""
                        }
                            `}
                    </Typography>
                    <Typography
                      style={{
                        opacity: 0.4,
                        fontSize: "1rem",
                      }}
                    >
                      {props?.eWayBillResponse?.EwbValidTill
                        ? `Valid Upto :  ${moment(
                          props?.eWayBillResponse?.EwbValidTill,
                          "YYYY-MM-DD h:mm:ss"
                        ).format("DD/MM/YYYY LTS")}`
                        : ""}
                    </Typography>
                  </div>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <div className={classes.pdfTitleWayBill}>
                  <div className={classes.pdfActions}>
                    <RiplEwayBillPdfDownload pdfOperation={"VIEW"} {...props} />
                    <RiplEwayBillPdfDownload
                      pdfOperation={"DOWNLOAD"}
                      {...props}
                    />
                  </div>
                </div>
              </CardActions>
            </>
          )
        ) : props?.documentFor === "Exit Pass" ? (
          <>
            <CardActionArea>
              <CardContent>
                <div className={classes.eInvoiceDetails}>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {`${props?.eInvoiveResponse?.AckNo
                      ? `Exit pass for E-invoice : ${props?.eInvoiveResponse?.AckNo || ""
                      }`
                      : props?.plantItemDetails[0]?.DOCNO
                        ? `Exit pass for invoice : ${props?.plantItemDetails[0]?.DOCNO || ""
                        }`
                        : ""
                      }
                    `}
                  </Typography>
                </div>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <div className={classes.pdfTitleInvoice}>
                <div className={classes.pdfActions}>
                  <ExitPassDownload pdfOperation={"VIEW"} {...props} />
                  <ExitPassDownload pdfOperation={"DOWNLOAD"} {...props} />
                </div>
              </div>
            </CardActions>
          </>
        ) : (
          <>
            <CardActionArea>
              <CardContent>
                <div className={classes.eInvoiceDetails}>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {`${props.documentFor === "Invoice"
                      ? props?.plantItemDetails[0]?.DOCNO
                        ? `Number : ${props?.plantItemDetails[0]?.DOCNO || ""
                        }`
                        : ""
                      : props.documentFor === "E-Invoice"
                        ? props?.eInvoiveResponse?.AckNo
                          ? `Acknowledgement No : ${props?.eInvoiveResponse?.AckNo || ""
                          }`
                          : ""
                        : ""
                      }
                    `}
                  </Typography>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {`${props.documentFor === "Invoice"
                      ? props?.plantItemDetails[0]?.DOCDATE
                        ? `Date & Time : ${moment(
                          props?.plantItemDetails[0]?.DOCDATE,
                          "DD/MM/YYYY"
                        ).format("DD-MMM-YYYY")}`
                        : ""
                      : props.documentFor === "E-Invoice"
                        ? props?.eInvoiveResponse?.AckDate
                          ? `Date & Time : ${moment(
                            props?.eInvoiveResponse?.AckDate,
                            "YYYY-MM-DD h:mm:ss"
                          ).format("DD-MMM-YYYY LT")}`
                          : ""
                        : ""
                      }
                    `}
                  </Typography>
                  <Typography
                    style={{
                      opacity: 0.4,
                      fontSize: "1rem",
                    }}
                  >
                    {""}
                  </Typography>
                </div>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <div className={classes.pdfTitleInvoice}>
                <div className={classes.pdfActions}>
                  <RiplInvoicePdfDownload pdfOperation={"VIEW"} {...props} />
                  <RiplInvoicePdfDownload
                    pdfOperation={"DOWNLOAD"}
                    {...props}
                  />
                </div>
              </div>
            </CardActions>
          </>
        )}
      </Card>
    </Grow>
  );
}
