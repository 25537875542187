import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import { CssBaseline, Toolbar, AppBar, Snackbar } from '@material-ui/core';
import MuiDrawer from '@mui/material/Drawer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import DrawerContents from '../../components/internal/layoutComponents/DrawerContents';
import RightIcon from '../../components/internal/layoutComponents/RightIcon';
import {
  drawerBackground,
  primaryColorBackground,
} from '../../constants/internal/colors';
import '../../styles/internal/scroll.css';
import withAuthentication from '../../hoc/withAuthentication';
import StaticVersionDisplay from "../../components/common/StaticVersionDisplay";
import MenuIcon from '@mui/icons-material/Menu';
const drawerWidth = '240px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: primaryColorBackground,
  },
  paper: {
    border: 'none',
  },
  drawerStyle: {
    background: drawerBackground,
    height: '100%',
    width: window.innerWidth <= 768 ? 215 : '17%',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerContent: {
    background: drawerBackground,
    height: '100%',
  },
  rightIcon: {
    position: 'absolute',
    right: 5,
  },
  logoAvatar: {
    width: 60,
  },
  childrenComponents: {
    width: '100%',

    height: '100vh',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: '90px',
    paddingLeft: '3%',
    paddingRight: '0px',
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(64px + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


function Layout(props) {
  const classes = useStyles();
  const [isDialogOpen, setDialogState] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [isExtendInProgress, setExtendProgress] = React.useState(false);
  const [hideDrawer, setHideDrawer] = React.useState(false);
  const [open, setOpen] = React.useState(JSON.parse(localStorage.getItem('drawerOpen')));
  const [isOffline, setIsOffline] = React.useState(JSON.parse(localStorage.getItem('isOffline')));
  React.useEffect(() => {
    if (isExtendInProgress) {
      clearInterval(window.timer);
      window.timer = undefined;
      setProgress(0);
      props
        .refreshToken(props.user)
        .then((status) => {
          reset();
          setExtendProgress(false);
          if (status !== 'OK') props.logout();
        })
        .catch(props.logout);
    }
  }, [isExtendInProgress]);

  React.useEffect(() => {
    setIsOffline(JSON.parse(localStorage.getItem('isOffline')))
  }, [localStorage.getItem('isOffline')])

  const handleDrawerOpen = () => {
    localStorage.setItem('drawerOpen', !open)
    setOpen(!open)
  }
  const handleClickOpen = () => setDialogState(true);

  const handleClose = () => setDialogState(false);

  const handleLogout = () => {
    handleClose();
    props.logout();
  };

  const handleExtendSession = () => {
    handleClose();
    setExtendProgress(true);
  };

  const onAction = (e) =>
    window.localStorage.setItem('last_action_at', new Date().toISOString());

  const onActive = (e) => {
    // Please don't remove - Bharat
    // console.log("user is active", e);
    console.log('time remaining', getRemainingTime());
  };

  const onIdle = (e) => {
    // Please don't remove - Bharat
    // console.log("user is idle", e);
    if ((new Date() - new Date(getLastActiveTime())) / 1000 / 60 > 31) {
      props.logout();
    } else {
      handleClickOpen();
      window.timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(window.timer);
            window.timer = undefined;
            handleClose();
            props.logout();
            return 0;
          }
          return Math.min(oldProgress + 1.66666667, 100);
        });
      }, 1000);
    }
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * 29, // 29Min no activity
    onIdle,
    onActive,
    onAction,
    debounce: 250,
  });

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        // disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Session'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component="span">
            <>
              You will be automatically logout in a minute, we have noticed no
              action from last 30 minutes.
              <>
                <LinearProgress variant="determinate" value={progress} />
              </>
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
          <Button onClick={handleExtendSession} color="primary" autoFocus>
            Extend Session
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Button
            startIcon={<MenuIcon />}
            onClick={handleDrawerOpen}
            style={{
              display: (window.innerWidth > 768 || open) && 'none',
              color: '#fff',
            }}
          />
          <div className={classes.rightIcon}>
            <RightIcon {...props} />
          </div>
        </Toolbar>
      </AppBar>
      <CssBaseline />
      <div style={{ height: '100vh', display: 'flex', width: '100%' }}>
        <Drawer
          className={classes.drawerStyle}
          variant="permanent"
          PaperProps={{
            className: classes.drawerStyle,
          }}
          // style={{
          //   display: window.innerWidth < 768 && !open && 'none'
          // }}
          open={open}
        >
          <div role="button">
            <Button
              startIcon={<MenuIcon />}
              onClick={handleDrawerOpen}
              style={{
                //display: window.innerWidth > 768 && 'none',
                color: '#fff',
                paddingLeft: 24,
                paddingTop: 20
              }}
            />
            <DrawerContents {...props} open={open} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <StaticVersionDisplay Version="2.9.8" />
            </div>
          </div>
        </Drawer>
        <div className={classes.childrenComponents} id="scroll">
          {props.children}
        </div>
      </div>
      <Snackbar
        open={isOffline}
        autoHideDuration={3000}
        TransitionComponent={"up"}
        ContentProps={{
          "aria-describedby": "message-id",
          style: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        message={<span id="message-id">No Internet Connection</span>}
      />
    </div>
  );
}

export default withAuthentication(Layout);
