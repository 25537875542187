import React, { Component } from "react";
import Cards from "./Cards";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SnackbarComponent from "../../common/SnackbarComponent";

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "18vh",
  },
  gateInTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gateOutTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
class ManageGatesMaster extends Component {
  state = {
    open: false,
    gateName: "",
    gateIn: false,
    gateOut: false,
    updateGate: false,
    openSnackbar: false,
    snackbarMessage: null,
    snackbarType: "",
  };
  handleTextChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleClose = () => {
    this.setState({
      open: false,
      gateName: "",
      gateIn: false,
      gateOut: false,
      updateGate: false,
      gateId: "",
    });
  };
  handleAddEvent = () => {
    this.setState({ open: true });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  componentDidMount() {
    setTimeout(() => {
      this.props.fetchGateMaster(this.props.selectedPlantId);
    }, 2000);
  }

  handleGatesInPlantMasterAction = (updateGate, gateName, gateIn, gateOut, gateId) => {
    if (updateGate) {
      this.props
        .handleUpdateGatesInPlantMaster(
          gateName,
          gateIn,
          gateOut,
          gateId
        )
        .then((data) => {
          if (data.status === 201) {
            this.setState({
              open: false,
              gateName: "",
              gateIn: false,
              gateOut: false,
              openSnackbar: true,
              snackbarMessage: `Updated successfully `,
              snackbarType: "success",
            });
            this.props.fetchGateMaster(
              this.props.selectedPlantId
            );
          }
        })
        .catch((error) => {
          // console.log(e.response);
          for (let key in error.response.data) {
            this.setState({
              openSnackbar: true,
              // snackbarMessage: `Something went wrong `,
              snackbarMessage: `${key + "  :  " + error.response.data[key]}`,
              snackbarType: "error",
            });
          }
        });
    } else {
      this.props
        .handleAddGatesInPlantMaster(gateName, gateIn, gateOut)
        .then((data) => {
          if (data.status === 201) {
            this.setState({
              open: false,
              openSnackbar: true,
              snackbarMessage: `Added successfully `,
              snackbarType: "success",
            });
            this.props.fetchGateMaster(
              this.props.selectedPlantId
            );
          }
        })
        .catch((error) => {
          // console.log(e.response);
          for (let key in error.response.data) {
            this.setState({
              openSnackbar: true,
              // snackbarMessage: `Something went wrong `,
              snackbarMessage: `${error.response.data[key]}`,
              snackbarType: "error",
            });
          }
        });
    }
  }

  handleOpenUpdateDialog = (selectedUpdateGate) => {
    this.setState({
      open: true,
      gateName: selectedUpdateGate.gate_name,
      gateIn: selectedUpdateGate.is_gate_in_allowed,
      gateOut: selectedUpdateGate.is_gate_out_allowed,
      updateGate: true,
      gateId: selectedUpdateGate.id,
    });
  };
  handleCloseDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      open: false,
      gateName: "",
      gateIn: false,
      gateOu: false,
      gateId: "",
    });
  };
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  render() {
    const { gateName, gateIn, gateOut, updateGate, gateId } = this.state;
    const { classes } = this.props;
    return (
      <>
        {this.props.gatesListLoader ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <Cards
            title="GATES"
            data={this.props.gatesList}
            handleAddEvent={this.handleAddEvent}
            handleOpenUpdateDialog={this.handleOpenUpdateDialog}
            user={this.props.user}
          />
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {updateGate === true ? "UPDATE GATE " : "ADD GATE"}
          </DialogTitle>
          <DialogContent>
            <TextField
              name="gateName"
              autoFocus
              margin="dense"
              id="name"
              label="Enter Gate Name"
              fullWidth
              value={this.state.gateName}
              onChange={(e) => this.handleTextChange(e)}
            />
            <div className={classes.gateInTextContainer}>
              <span>GATE IN</span>
              <Checkbox
                checked={this.state.gateIn}
                onChange={this.handleChange("gateIn")}
                value="gateIn"
              />
            </div>
            <div className={classes.gateOutTextContainer}>
              <span>GATE OUT</span>
              <Checkbox
                checked={this.state.gateOut}
                onChange={this.handleChange("gateOut")}
                value="gateOut"
              />
            </div>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              // justifyContent: updateGate ? "space-around" : "flex-end",
            }}
          >
            <div>
              {/* {updateGate ? (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    padding: "4px 10px",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      open: false,
                      updateGate: false,
                      openDeleteDialog: true,
                    });
                  }}
                >
                  <DeleteIcon />
                  Delete
                </Button>
              ) : null} */}
            </div>
            <div>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={this.state.gateName === ""}
                color="primary"
                onClick={(e) => {
                  this.handleGatesInPlantMasterAction(updateGate, gateName, gateIn, gateOut, gateId);
                  this.handleClose()
                  // e.preventDefault();
                  // if (updateGate) {
                  //   this.props
                  //     .handleUpdateGatesInPlantMaster(
                  //       gateName,
                  //       gateIn,
                  //       gateOut,
                  //       gateId
                  //     )
                  //     .then((data) => {
                  //       if (data.status === 200) {
                  //         this.setState({
                  //           open: false,
                  //           gateName: "",
                  //           gateIn: false,
                  //           gateOu: false,
                  //           openSnackbar: true,
                  //           snackbarMessage: "Updated successfully ",
                  //           snackbarType: "success",
                  //         });
                  //         this.props.fetchGateMaster(
                  //           this.props.selectedPlantId
                  //         );
                  //       }
                  //     })
                  //     .catch((e) => {
                  //       console.log(e.response);
                  //       this.setState({
                  //         openSnackbar: true,
                  //         snackbarMessage: "Something went wrong",
                  //         snackbarType: "error",
                  //       });
                  //     });
                  // } else {
                  //   this.props
                  //     .handleAddGatesInPlantMaster(gateName, gateIn, gateOut)
                  //     .then((data) => {
                  //       if (data.status === 201) {
                  //         this.setState({
                  //           open: false,
                  //           openSnackbar: true,
                  //           snackbarMessage: "Added successfully ",
                  //           snackbarType: "success",
                  //         });
                  //         this.props.fetchGateMaster(
                  //           this.props.selectedPlantId
                  //         );
                  //       }
                  //     })
                  //     .catch((e) => {
                  //       console.log(e.response);
                  //       this.setState({
                  //         openSnackbar: true,
                  //         snackbarMessage: "Something went wrong",
                  //         snackbarType: "error",
                  //       });
                  //     });
                  // }
                }
                }
              >
                {updateGate === true ? "UPDATE" : "ADD"}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">DELETE</DialogTitle>
          <DialogContent>Are you sure you want to delete this ?</DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px 20px",
            }}
          >
            <Button variant="contained" onClick={this.handleCloseDeleteDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                this.props
                  .handleDeleteGates(gateId)
                  .then((data) => {
                    if (data.status === 204) {
                      this.setState({
                        open: false,
                        gateName: "",
                        gateIn: false,
                        gateOu: false,
                        gateId: "",
                        openDeleteDialog: false,
                        openSnackbar: true,
                        snackbarMessage: "Deleted successfully ",
                        snackbarType: "success",
                      });
                      this.props.fetchGateMaster(this.props.selectedPlantId);
                    }
                  })
                  .catch((error) => {
                    for (let key in error.response.data) {
                      this.setState({
                        openSnackbar: true,
                        // snackbarMessage: `Something went wrong `,
                        snackbarMessage: `${key + "  :  " + error.response.data[key]}`,
                        snackbarType: "error",
                      });
                    }
                  });
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
          customAutoHideDuration={4000}
        />
      </>
    );
  }
}
export default withStyles(styles)(ManageGatesMaster);
