import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { pxToEm } from '../../../../methods';
import APIROUTES from '../../../../constants/internal/InternalApiRoutes';
import SnackbarComponent from '../../common/SnackbarComponent';
import axios from "axios";
import ToolTipComponent from '../../common/ToolTipComponent';

const styles = {
    paperStyle: (index, hoverRow) => ({
        transition: 'transform 0.5s ease-in-out',
        transform: hoverRow === index ? 'scale(1.1)' : 'scale(1)'
    })
}


export default function TodaysUpdateDisplay(props) {
    const [todaysData, setTodaysData] = useState({});
    const [snackbarType, setSnackbarType] = useState("")
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [hoverRow, setHoverRow] = useState(null);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false)
        setSnackbarMessage(null)
        setSnackbarType("")

    };

    const handleOpenSnackbar = (message, type) => {
        setOpenSnackbar(true)
        setSnackbarMessage(message)
        setSnackbarType(type)
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false)
        setSnackbarMessage("")
        setSnackbarType("")
    };

    useEffect(() => {
        handleFetchTodaysUpdate()
    }, [])

    const handleFetchTodaysUpdate = () => {
        axios
            .get(
                `${APIROUTES.TODAY_TOTAL_DATA}`,
                //this.getOptions(this.props.user)
            )
            .then((response) => {
                if (response.status === 200) {
                    setTodaysData(response.data);

                } else {
                }
            })
            .catch((error) => {
                console.log("error.response", error.response);
                setOpenSnackbar(true)
                setSnackbarMessage("Error occured while fetching Today's data")
                setSnackbarType("error")
                // this.setState({ itemDataFetchLoader: true });
            });
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingLeft: '0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& > :not(style)': {
                        m: 5,
                        marginLeft: 0,
                        marginTop: 2,
                        minWidth: 200,
                        height: 110,
                        borderRadius: 2,
                    },
                }}
            >
                <Paper
                    elevation={3}
                    style={styles.paperStyle(0, hoverRow)}
                >
                    <div
                        style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center", padding: '25px 4px',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => props?.handleChange(e, 0)}
                        onMouseEnter={(e) => setHoverRow(0)}
                        onMouseLeave={(e) => setHoverRow(null)}
                    >
                        <div style={{ fontSize: pxToEm(16), fontWeight: 500 }}>
                            New Contracts
                        </div>
                        <ToolTipComponent
                            title="Total / Unapproved (MT)"
                            condition={true}
                            arrow={false}
                            fontSize='18px'
                            placement='bottom-start'
                        >
                            <div style={{ fontSize: pxToEm(30), fontWeight: 550 }}>
                                <span>{todaysData['New contracts']?.today_contracts_quantity}</span>
                                <span style={{ fontWeight: 400 }}>/</span>
                                <span style={{ color: "#F35162" }}>
                                    {todaysData['New contracts']?.today_contracts_unapproved}
                                </span>
                                <span style={{ fontWeight: 500 }}> MT</span>
                            </div>
                        </ToolTipComponent>
                    </div>
                </Paper>
                <Paper
                    elevation={3}
                    style={styles.paperStyle(1, hoverRow)}
                >
                    <div
                        style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center", padding: '25px 12px',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => props?.handleChange(e, 3)}
                        onMouseEnter={(e) => setHoverRow(1)}
                        onMouseLeave={(e) => setHoverRow(null)}
                    >
                        <div style={{ fontSize: pxToEm(16), fontWeight: 500 }}>
                            Vehicles In Plant
                        </div>
                        <ToolTipComponent
                            title="Gate-In / Approved"
                            condition={true}
                            arrow={false}
                            fontSize='18px'
                            placement='bottom-start'
                        >
                            <div style={{ fontSize: pxToEm(30), fontWeight: 550 }}>
                                <span>{todaysData['Vehicles In Plant']?.today_trucks_in_plant}</span>
                                <span style={{ fontWeight: 400 }}>/</span>
                                <span>
                                    {todaysData['Vehicles In Plant']?.today_approved_truck}
                                </span>
                            </div>
                        </ToolTipComponent>
                    </div>
                </Paper>
                <Paper
                    elevation={3}
                    style={styles.paperStyle(2, hoverRow)}
                >
                    <div
                        style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center", padding: '25px 4px',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => props?.handleChange(e, 3)}
                        onMouseEnter={(e) => setHoverRow(2)}
                        onMouseLeave={(e) => setHoverRow(null)}
                    >
                        <div style={{ fontSize: pxToEm(16), fontWeight: 500 }}>
                            In Plant
                        </div>
                        <ToolTipComponent
                            title="Gate-In / Approved (MT)"
                            condition={true}
                            arrow={false}
                            fontSize='18px'
                            placement='bottom-start'
                        >
                            <div style={{ fontSize: pxToEm(30), fontWeight: 550 }}>
                                <span>{todaysData['In Plant']?.today_total_in_plant_qty}</span>
                                <span style={{ fontWeight: 400 }}>/</span>
                                <span>
                                    {todaysData['In Plant']?.today_approve_plant_qty}
                                </span>
                                <span style={{ fontWeight: 500 }}> MT</span>
                            </div>
                        </ToolTipComponent>
                    </div>
                </Paper>
                <Paper
                    elevation={3}
                    style={styles.paperStyle(3, hoverRow)}
                >
                    <div
                        style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center", padding: '25px 10px',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => props?.handleChange(e, 1)}
                        onMouseEnter={(e) => setHoverRow(3)}
                        onMouseLeave={(e) => setHoverRow(null)}
                    >
                        <ToolTipComponent
                            title="Dispatched (MT)"
                            condition={true}
                            arrow={false}
                            fontSize='18px'
                            placement='bottom-start'
                        >
                            <div style={{ fontSize: pxToEm(16), fontWeight: 500 }}>
                                Dispatched
                            </div>
                            <div style={{ fontSize: pxToEm(30), fontWeight: 550 }}>
                                <span>{todaysData?.today_dispatched_net_weight}</span>
                                <span style={{ fontWeight: 500 }}> MT</span>
                            </div>
                        </ToolTipComponent>
                    </div>
                </Paper>
            </Box>
            <SnackbarComponent
                open={openSnackbar}
                handleClose={handleSnackbarClose}
                snackbarMessage={snackbarMessage}
                type={snackbarType}
            />
        </>
    );
}
