import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import { primaryColor } from "../../../styles/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import { pxToEm } from "../../../methods";
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import { FormControlLabel } from "@mui/material";
import { drawerTextColor } from "../../../constants/internal/colors";
import PaymentCard from "../common/PaymentCard";

const styles = {
  paymentOptionCardMainBody: {
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
    height: window.innerWidth < 375 ? "8vh" : "10vh",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "24px",
    fontSize: window.innerWidth < 375 && pxToEm(14),
    margin: "0px 0px 10px 0px",
    fontFamily: 'Poppins',
    fontSize: 16
  },
  rupeeIcon: {
    transform: 'scale(0.7)',
    marginRight: '-7px',
    marginLeft: '-7px',
    fontWeight: 400,
    color: '#9d9c9c'
  }
};
class PaymentOptionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentOption: [
        { name: "Advanced" },
        { name: "N + 1" },
        { name: "COD" },
        { name: "Cheque" },
      ],
      value: null,
      subContractPayment: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedSubContractPayment !== this.props.selectedSubContractPayment && this.props.selectedSubContractPayment !== undefined) {
      let subContractPayment = [];
      subContractPayment.push({
        "payment_method_detail": {
          "id": this.props.selectedSubContractPayment.payment_id,
          "payment_method_name": this.props.selectedSubContractPayment.payment_method_name,
          "payment_method_code": this.props.selectedSubContractPayment.payment_method_code,
          "days": this.props.selectedSubContractPayment.days
        }
      })
      this.setState({
        value: this.props.selectedSubContractPayment.payment_method_name,
        subContractPayment: subContractPayment
      });
    }
    if (this.props.paymentMethods && this.props.selectedPaymentMethodDataForLA && !this.state.value) {
      // console.log("render", this.props.selectedPaymentMethodDataForLA)
      this.setState({ value: this.props.selectedPaymentMethodDataForLA.payment_method_name }, () => {
        this.props.handleSelectPaymentMethod(this.props.selectedPaymentMethodDataForLA)
      });
    }
  }

  handleChange = (event) => {
    let { value } = event.target
    const { classes, paymentMethods, selectedPaymentMethodDataForLA } = this.props;
    let payment = paymentMethods.find(method => {
      if (method.payment_method_detail.payment_method_name === value)
        return method
    })

    // console.log(payment)
    this.setState({ value: event.target.value }, () => {
      this.props.handleSelectPaymentMethod(payment.payment_method_detail);
    });
  };
  render() {
    const { paymentOption, value, subContractPayment } = this.state;
    const { classes, paymentMethods,
      paymentMethodsDiscount, laState, subContract,
      selectedSubContractPayment, selectedEditSubContractNumber, subContractNumber
    } = this.props;
    // console.log(value)
    return (
      <div style={{ width: "100%", marginBottom: 15 }}>
        <FormControl
          component="fieldset"
          className={classes.formControl}
          style={{ width: "100%", fontSize: window.innerWidth < 375 && pxToEm(15) }}
        >
          <RadioGroup
            aria-label="gender"
            name="gender2"
            className={classes.group}
            value={this.state.value}
            onChange={this.handleChange}
          >
            {
              paymentMethods ?
                (Boolean(subContract) || Boolean(subContractNumber)
                  ? subContractPayment
                  : paymentMethods)
                  .map((data) => (
                    <FormControlLabel
                      // style={{ fontFamily: 'Poppins', fontSize: 16 }}
                      disabled={Boolean(subContract)}
                      disableTypography={true}
                      key={data.payment_method_detail.id}
                      style={styles.paymentOptionCardMainBody}
                      value={data.payment_method_detail.payment_method_name}
                      control={
                        <Radio
                          style={{
                            color: Boolean(subContract) ? 'rgba(0,0,0,0.5)' : primaryColor,
                            transform: window.innerWidth < 375 && 'scale(0.8)'
                          }}
                          disabled={Boolean(subContract)}
                        />}
                      checked={(data.payment_method_detail.payment_method_name === this.state.value)
                        ? true : false}
                      labelPlacement="end"
                      label={
                        <PaymentCard
                          allPaymentData={paymentMethodsDiscount}
                          rowDataName={data.payment_method_detail.payment_method_name}
                          rowDataID={data?.payment_method_detail?.id}
                          subContract={subContract}
                          isUnsubscribed={Boolean(subContract) && paymentMethods.find(d => d.payment_method_detail.id === selectedSubContractPayment.payment_id) === undefined}
                        />
                      }
                      onChange={this.handleChange}
                    />
                  )) : null
            }
          </RadioGroup>
          {laState?.mode === 'edit' && !Boolean(subContractNumber) && !Boolean(paymentMethods.find(d =>
            d?.payment_method_detail.payment_method_name === laState?.laDetails?.payment_method?.payment_method_name)) &&
            <RadioGroup
              aria-label="gender"
              name="gender2"
              className={classes.group}
              value={this.state.value}
              // onChange={this.handleChange}
              disabled={true}
              style={{ opacity: 0.5 }}
            >
              {
                <FormControlLabel
                  // style={{ fontFamily: 'Poppins', fontSize: 16 }}
                  disableTypography={true}
                  disable={true}
                  key={laState.laDetails?.payment_method?.id}
                  style={styles.paymentOptionCardMainBody}
                  value={laState.laDetails?.payment_method?.payment_method_name}
                  control={<Radio style={{ color: primaryColor, transform: window.innerWidth < 375 && 'scale(0.8)' }} />}
                  checked={laState.laDetails?.payment_method?.payment_method_name === this.state.value ? true : false}
                  labelPlacement="end"
                  label={
                    <PaymentCard
                      allPaymentData={paymentMethodsDiscount}
                      rowDataName={laState.laDetails?.payment_method?.payment_method_name}
                      rowDataID={laState.laDetails?.payment_method?.id}
                      dateCheck={laState?.laDetails?.created_at}
                    />
                  }
                  onChange={this.handleChange}
                  disabled
                />
              }
            </RadioGroup>
          }
        </FormControl>
      </div>
    );
  }
}
export default withStyles(styles)(PaymentOptionCard);
