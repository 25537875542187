import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import { withStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Create from "../Create";

const styles = {
  DispatchCreateCreateMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  },
};
class DispatchCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    // this.props.history.push("/dispatch");
    this.props.handleCloseLAForm("goBack")
    // this.props.clearAllCreateDispatchData();
  };

  render() {
    const {
      classes,
      orders,
      items,
      gateInDate,
      selectedPlant,
      truckNumber,
      truckCapacity,
      transporterName,
      transporterContactNumber,
      driverName,
      driverContactNumber,
      buyerName,
      consigneeName,
      paymentMethod,
      handleTextChange,
      handleDateChange,
      pageHeight,
      LASubmitLoader,
      customerRemark,
      subContract,
      selectedEditSubContractNumber,
      subContractNumber,
      subContractDropdownList,
      subContractDropdownListLoader,
      plantSubContractEnabled,
      ordersListLoader,
      selectedSubContractDetail,
      selectedSubContractPayment,
    } = this.props;
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<Close items />}
        appBarTitle={this.props?.mode === "edit" ? `Edit | ${this.props?.laDetails?.la_number}` : "New Loading Advice"}
      >
        <div className={classes.DispatchCreateCreateMainBody}>
          <Create
            {...this.props}
            pageHeight={pageHeight}
            orders={orders}
            items={items}
            handleTextChange={handleTextChange}
            handleDateChange={handleDateChange}
            gateInDate={gateInDate}
            selectedPlant={selectedPlant}
            truckNumber={truckNumber}
            truckCapacity={truckCapacity}
            transporterName={transporterName}
            transporterContactNumber={transporterContactNumber}
            driverName={driverName}
            driverContactNumber={driverContactNumber}
            buyerName={buyerName}
            consigneeName={consigneeName}
            paymentMethod={paymentMethod}
            customerRemark={customerRemark}
            handleSelectBuyerName={this.props.handleSelectBuyerName}
            handleSelectConsigneeName={this.props.handleSelectConsigneeName}
            handleSelectPaymentMethod={this.props.handleSelectPaymentMethod}
            selectedPlantAndItem={this.props.selectedPlantAndItem}
            LASubmitLoader={LASubmitLoader}
            laDetails={this.props?.laDetails}
            mode={this.props?.mode}
            handleEditData={this.props?.handleEditData}
            handleItemEdit={this.props?.handleItemEdit}
            fetchLADetails={this.props?.fetchLADetails}
            fetchOrderList={this.props?.fetchOrderList}
            handleSubcontractFormDetail={this.props.handleSubcontractFormDetail}
            subContract={subContract}
            selectedEditSubContractNumber={selectedEditSubContractNumber}
            subContractNumber={subContractNumber}
            subContractDropdownList={subContractDropdownList}
            subContractDropdownListLoader={subContractDropdownListLoader}
            plantSubContractEnabled={plantSubContractEnabled}
            ordersListLoader={ordersListLoader}
            selectedSubContractDetail={selectedSubContractDetail}
            selectedSubContractPayment={selectedSubContractPayment}
          />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(DispatchCreate);
