import React, { Component } from "react";
import Bread from "../common/Breadcrubs";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Lock from "@material-ui/icons/Lock";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { secondaryBlueText, grey } from "../../../constants/internal/colors";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import EinvoiceData from "./EinvoiceData";
import EinvoiceAndEwayCard from "./EinvoiceAndEwayCard";
import EwayData from "./EwayData";
import Divider from "@material-ui/core/Divider";
import ItemTable from "./ItemTable";
import GstDetails from "./GstDetails";
import SpinLoader from "../../common/SpinLoader";
import copy from "copy-to-clipboard";

const styles = {
  mainPaperContainer: (showErpData) => ({
    padding: "20px 30px",
    height: showErpData ? null : "96%",
    marginBottom: "20px",
  }),
  topFormContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "20vh",
    alignItems: "center",
    width: "100%",
  },
  fetchButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "16px",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "30px",
    width: "100%",
    marginBottom: 10,
  },
  headerData: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
};
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.erpFetchError === false) {
      this.setState({ showErpData: true });
    }
  }
  componentDidMount() {
    if (!this.props.plantCode) {
      this.props.history.goBack();
    }
  }
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  checkTransportIdValidation = (transportId) => {
    const specialCharCheck = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (transportId == null) {
      return null;
    } else {
      if (transportId.length == 15) {
        if (specialCharCheck.test(transportId)) {
          console.log("i m here");
          return "Transporter Id has special characters";
        } else {
          return null;
        }
      } else {
        return "Transporter Id must be 15 characters";
      }
    }
  };

  render() {
    const {
      classes,
      vrNumber,
      plantName,
      plantCode,
      invoiceType,
      plantItemDetails,
      handleFetchERPData,
      erpFetchError,
      handlePOSTdataForInvoive,
      erpFetchLoader,
      handleVRNumberTextChange,
      eWayBillSelected,
    } = this.props;
    return (
      <div
        style={{
          width: "100%",
          // padding: "30px"
          padding: "0 30px 30px 0"
        }}
      >
        <div className={classes.navigationBar}>
          <Bread
            data={[
              {
                title: "E-Invoicing",
                onClick: () => {
                  this.props.handleResetAllData();
                  this.props.history.goBack();
                },
              },
              {

              }
              // {
              //   title: plantName,
              //   onClick: () => {
              //     this.props.handleResetAllData();
              //     this.props.history.goBack();
              //   },
              // },
              // {
              //   title: "New Invoice",
              //   onClick: () => {
              //     this.props.handleResetAllData();
              //     this.props.history.goBack();
              //   },
              // },
            ]}
          />
          <div className={classes.headerData}>
            <span>{plantName}</span>
          </div>
          <Divider
            light={true}
            orientation="vertical"
            flexItem
            style={{
              display: plantName ? 'block' : 'none',
              width: 0.11 + "em",
              marginLeft: "10px"
            }}
          />
          <div className={classes.headerData}>
            <span>New Invoice</span>
          </div>
        </div>
        <Paper style={styles.mainPaperContainer(this.state.showErpData)}>
          <div className={classes.topFormContainer}>
            <TextField
              disabled
              id="outlined-name"
              label="Plant code"
              margin="normal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock />
                  </InputAdornment>
                ),
              }}
              value={plantName && plantName}
              style={{ width: "30%" }}
            />
            <TextField
              disabled
              id="outlined-name"
              label="Voucher code"
              margin="normal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock />
                  </InputAdornment>
                ),
              }}
              value={plantCode && plantCode}
              style={{ width: "30%" }}
            />
            <TextField
              disabled={this.state.showErpData}
              name="vrNumber"
              id="outlined-name"
              label="VR Number"
              margin="normal"
              variant="outlined"
              fullWidth
              style={{ width: "30%" }}
              onChange={handleVRNumberTextChange}
              value={vrNumber}
            />
          </div>
          <div className={classes.fetchButtonContainer}>
            {erpFetchError ? (
              <span
                style={{
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <ErrorOutline />
                Error fetching data from ERP
              </span>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: secondaryBlueText,
                padding: "6px 40px",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleFetchERPData(this.props.match.params.t_code);
              }}
            >
              Fetch
            </Button>
          </div>
          {erpFetchLoader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SpinLoader />
            </div>
          ) : null}
          {plantItemDetails.length > 0 && this.state.showErpData ? (
            <div>
              <EinvoiceData
                {...this.props}
                {...this.state}
                checkTransportIdValidation={this.checkTransportIdValidation}
                actualDistance={this.props?.actualDistance}
                handleActualDistance={this.props.handleActualDistance}
              />
              <ItemTable {...this.props} {...this.state} />
              <GstDetails {...this.props} {...this.state} />
              <Divider />
              <EwayData {...this.props} {...this.state} />

              <Divider />
              <EinvoiceAndEwayCard
                {...this.props}
                {...this.state}
                checkTransportIdValidation={this.checkTransportIdValidation}
              />
              <div
                style={{
                  height: "10vh",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingRight: "30px",
                  marginTop: "20px",
                }}
              >
                {this.props.errorWhilePostForInvoice &&
                  this.props.errorWhilePostForInvoice ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      alt="img"
                      src="/contentCopy.svg"
                      height="60px"
                      width="60px"
                      style={{ cursor: "pointer", marginRight: "16px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        copy(
                          this.props.einvoiveErrorMessage &&
                          this.props.einvoiveErrorMessage
                        );
                      }}
                    />
                    <span style={{ color: "red", marginRight: "5px" }}>
                      {this.props.einvoiveErrorMessage &&
                        this.props.einvoiveErrorMessage}
                    </span>
                  </div>
                ) : null}

                {this.props.dataInvoicePostLoader ? null : (
                  <Button
                    style={{ marginRight: "16px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleResetAllData();
                      this.props.history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {this?.props?.dataInvoicePostLoader ||
                  this?.props?.ewayPostDataLoader ? (
                  <CircularProgress size={30} />
                ) : (
                  <Button
                    disabled={
                      invoiceType === "" ||
                      eWayBillSelected === "" ||
                      this.checkTransportIdValidation(
                        plantItemDetails[0]?.TRANSPORTEID
                      ) != null
                    }
                    variant="contained"
                    style={{
                      padding: "6px 40px",
                      backgroundColor:
                        invoiceType === "" || eWayBillSelected === ""
                          ? "grey"
                          : secondaryBlueText,
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (invoiceType !== "") {
                        if (invoiceType === "Invoice with e-invoice") {
                          // handlePOSTdataForInvoive(this.props.match.params.t_code);
                          handlePOSTdataForInvoive();
                        } else if (invoiceType === "Invoice") {
                          if (plantItemDetails[0]?.tran_sup_type === "B2C") {
                            if (this.props.eWayBillSelected === "Yes") {
                              this.props.handlePOSTEWayData();
                            }
                            this.props.handleCreateB2cInvoiceQr();
                            this.props.history.push(
                              "/internalEInvoicingAndEWayBilling/downloadInvoice"
                            );
                          } else {
                            if (this.props.eWayBillSelected === "Yes") {
                              this.props.handlePOSTEWayData();
                            }
                            this.props.history.push(
                              "/internalEInvoicingAndEWayBilling/downloadInvoice"
                            );
                          }
                        }
                      }
                    }}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          ) : null}
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Create));
