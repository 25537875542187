import React, { Component, Fragment } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  ListSubheader,
  TextField,
  Checkbox,
  InputAdornment,
  MenuItem,
  Divider,
  Paper,
  Popper,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import Downshift from "downshift";
import {
  grey,
  secondaryGrey,
  primaryColor,
  whiteSmoke,
  drawerTextColor,
  successButtonBackground,
  secondaryBlueText,
  earthBlue,
  drawerBackground,
} from "../../../../../constants/internal/colors";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import ContractDetailsAddEditTab from "./ContractDetailsAddEditTab";
import PlantPlanningTab from "./PlantPlanningTab";
import OrderConfirmationScreen from "./OrderConfirmationScreen";
import Bread from "../../../common/Breadcrubs";
import SpinLoader from "../../../../common/SpinLoader";
import Autocomplete from "@material-ui/lab/Autocomplete";

import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import SnackbarComponent from "../../../common/SnackbarComponent";
import { pxToEm, calculatePercent, checkApiError, checkBrokerage, checkInvalidValue } from "../../../../../methods";
import moment from "moment";
import APIROUTES from "../../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import { isEmpty } from "lodash";
import Lock from "@material-ui/icons/Lock";
import UnLock from "@material-ui/icons/LockOpen";
import ToolTipComponent from "../../../common/ToolTipComponent";


const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  headerRow: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    justifyContent: "space-between",
  },
  displayData: {
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
  cancelButton: {
    marginLeft: 20,
  },
  tabStyle: (tabValue, currentTab) => ({
    backgroundColor: whiteSmoke,
    color: tabValue === currentTab ? grey : secondaryGrey,
    fontSize: pxToEm(15),
  }),
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    margin: "0px 10px 0px 20px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
  },
  displayFlex: {
    paddingTop: 10,
  },
  formControl: {
    margin: "2px",
    width: "45%",
  },
  formControl3: {
    // margin: "2px",
    width: "30%",
  },
  contactTextFieldContainer: {
    width: "90%",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: '10px',
    marginBottom: "20px",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "100%",
    marginTop: 10,
    paddingBottom: 10,
  },
  spinLoaderContiner: {
    width: "30%",
    paddingTop: 20,
    marginTop: "20px",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  customerInfoContainer: {
    // width: "32%",
    display: "flex",
    flexDirection: "column",
  },
  customerInfoContainerCenter: {
    margin: "0px 30px",
    width: "32%",
    display: "flex",
    flexDirection: "column",
  },

  checkBoxLabelText: {
    color: secondaryGrey,
    fontWeight: "bold",
    marginRight: "6px",
  },
  clientApprovalDiv: { display: "flex", alignItems: "center", width: "65%" },
  discountContainerStyle: {
    display: "flex",
    paddingTop: "20px",
    paddingBottom: "30px",
  },
  discountContainerStyleLeft: {
    width: "50%",
  },
  discountContainerStyleRight: {
    width: "50%",
  },
  discountHeaderDiv: {
    padding: "10px 0px 10px 22px",
    background: whiteSmoke,
    width: "80%",
    marginBottom: "20px",
  },
  helperText: {
    position: 'absolute',
    top: pxToEm(72)
  },
  dateHelperText: {
    fontFamily: 'Poppins',
    letterSpacing: 'inherit',
    position: 'absolute',
    top: pxToEm(72)
  },
  discountTextStyle: {
    fontWeight: "bold",
    color: grey,
  },
  discountListStyleDiv: {
    display: "flex",
    width: "90%",
    marginBottom: "18px",
    alignItems: "center",
  },
  discountListStyleText: {
    width: "50%",
    color: grey,
    fontWeight: "bold",
  },
  discountListStyleValue: {
    width: "50%",
  },
  container: {
    width: "30%",
  },
  width45: {
    width: "45%",
  },
  noDataDiv: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    display: "flex",
    height: "calc(100vh - 200px)",
  },
  helperText: {
    position: 'absolute',
    top: pxToEm(72)
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const validOrderQuantityLimit = 10000;

class EditContracts extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      contact: "",
      rates: 0,
      ratePublishedAt: null,
      quantityEditable: '',
      originalRate: 0,
      isAllowedRateZero: false,
      quantity: 0,
      orderValidity: 10,
      orderValidityDate: this.generateOrderValidityDate(new Date(), 10),
      showCustomerDetail: false,
      openCustomerListPopper: false,
      customer: [
        {
          id: 4,
          first_name: "Ramesh",
          last_name: "Traders",
          phone: "909090909090",
          gstin_number: "WWR434343EEE222",
          pan_card_number: "343EEE222",
        },
        {
          id: 2,
          first_name: "Akash",
          last_name: "Traders",
          phone: "8088878787",
          gstin_number: "S333ERRW#3333",
          pan_card_number: "ERRW#3333",
        },
        {
          id: 2,
          first_name: "Rahul",
          last_name: "Traders",
          phone: "8088878787",
          gstin_number: "S333ERRW#3333",
          pan_card_number: "ERRW#3",
        },
      ],
      allTcsRates: [],
      // [
      //   { id: 1, tcs_rate: 0.075 },
      //   { id: 2, tcs_rate: 0.5 },
      //   { id: 3, tcs_rate: 1.02 },
      //   { id: 4, tcs_rate: 1.075 },
      // ],

      // new ui states
      tabValue: 0,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      contractOperation: null,
      adminApprovalCheckbox: false,
      allowBreakingFifoCheckbox: false,
      customerApprovalCheckbox: false,
      isCustomerApproved: false,
      adminRemark: null,
      selectedCustomerData: null,
      showCustomerDetailLoader: false,
      selectedCustomerPaymentTermsAllotted: [],
      selectedCustomerPaymentTermsNotAllotted: [],
      selectedCustomerItemCategoriesSubscribed: [],
      selectedCustomerItemCategoriesNotSubscribed: [],
      paymentTerm: null,
      itemCategory: null,
      itemCategoryId: null,
      plantPlanningData: [],
      laTruckCapacity: 0,
      consigneeSelectionList: [],
      buyerSelectionList: [],
      specialDiscount: 0,
      specialPremium: 0,
      totalDiscount: 0,
      selectedCustomerDiscount: 0,
      selectedBuyer: null,
      selectedConsignee: null,
      selectedBroker: null,
      brokerSelectionList: [],
      brokerage: 0,
      newBrokerage: 0,
      selectedItemCategoryBrokerage: 0,
      disableUserToInputRate: false,
      currentOrderId: null,
      customerRemark: null,
      salesPerson: null,
      salesPersonShow: null,
      tag: null,
      tagName: null,
      freight_basis: null,
      freight_basis_show: null,
      displayOrderConfirmationScreen: false,
      quantityError: false,
      ratesError: false,
      loadingPremiumErrorMsg: false,
      loadingPremiumError: false,
      insurancePremiumErrorMsg: false,
      insurancePremiumError: false,
      specialDiscountError: false,
      specialPremiumError: false,
      brokerageError: false,
      plantSelectionList: [],
      //tcsRate: 0,
      tcsAmount: 0,
      freightChargeRate: 0,
      freightChargeAmount: 0,
      partyRefNo: null,
      partyRefDate: null,
      remainingPlantPlanningQuantity: 0,
      itemDailyRateId: null,
      confirmationDialogOpen: false,
      publishAt: null,
      orderNumber: "",
      loadingPremium: 0,
      insurancePremium: 0,
      disableloadingPremium: false,
      disableInsurancePremium: false,
      editHelperText: {
        never: "Not editable",
        allow: "Editable without approval",
        approval_change: "Editable with approval",
        allow_with_restriction: "Editable with some restrictions",
        conversion_allow: "Editable only in conversion"
      },
      editData: {
        contact: "",
        buyer: "",
        consignee: "",
        itemCategory: "",
        tag: "",
        rate: "",
        quantity: "",
        paymentTerm: "",
        orderValidity: "",
        specialDiscount: "",
        specialPremium: "",
        internalRemark: "",
        customerRemark: "",
        allowBreakingFifo: "",
        customerApproval: "",
        brokerage: "",
        freightBasis: "",
        loadingPremium: "",
        insurancePremium: "",
        freightChargeRate: "",
        salesPerson: "",
        partyRefNo: "",
        partyRefDate: "",

      },
      finishBtnClicked: false
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentDidMount() {
    this._isMounted = true;

    const id =
      this.props?.location?.pathname?.split("/")[
      this.props?.location?.pathname?.split("/").length - 1
      ];
    this.setState({ selectedOrderId: id }, () =>
      this.prepareSelectedOrderDataToDisplay(this.state.selectedOrderId)
    );
    // if (this.props?.orderStatus) {
    //   this.handleEditHelperText()
    // }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleBrokerageField = () => {
    console.log("BROKERAGE", this.state.newBrokerage)
    if (checkBrokerage(this.state.newBrokerage)) {
      this.setState({ brokerageError: false })
    }
    else {
      this.setState({ brokerageError: true })
    }
  }

  handleEditHelperText = (event) => {
    if (this.props?.orderStatus === "PENDING") {
      // console.log("1")
      Object.entries(this.state.editData).map(([k, v], index) => (
        this.setState((previousState) => {
          const editData = {
            ...previousState.editData,
            [k]:
              ["itemCategory", "loadingPremium", "insurancePremium", "contact", "customerRemark"].includes(k)
                ? this.state.editHelperText.never
                : k === "rate"
                  ? this.state.editHelperText.allow_with_restriction
                  : (k === "brokerage" && this?.state?.selectedBroker?.brokerage)
                    ? this.state.editHelperText.never
                    : this.state.editHelperText.allow,
          };
          return { editData }
        })
      ))
    }
    else if (["APPROVED", "ONGOING"].includes(this.props?.orderStatus)) {
      // console.log("2")
      console.log(this.state.selectedBroker?.brokerage)
      Object.entries(this.state.editData).map(([k, v], index) => (
        this.setState((previousState) => {
          const editData = {
            ...previousState.editData,
            [k]: ["allowBreakingFifo"].includes(k)
              ? this.state.editHelperText.approval_change
              : ["itemCategory", "quantity"].includes(k)
                ? this.state.editHelperText.conversion_allow
                : (k === "brokerage" && this.state.selectedBroker?.brokerage)
                  ? this.state.editHelperText.never
                  : ["rate", "specialDiscount", "specialPremium", "customerApproval", "loadingPremium", "insurancePremium", "contact", "customerRemark"].includes(k)
                    ? this.state.editHelperText.never
                    : (k === "brokerage" && !this.state.selectedBroker?.brokerage)
                      ? this.state.editHelperText.approval_change
                      : this.state.editHelperText.allow,
          };
          return { editData }
        })
      ))
    }
  }

  getDays = (dateOne) => {
    const oneDay = 1000 * 60 * 60 * 24;
    let dateTwo = new Date();
    const difference = Math.abs(dateOne - dateTwo);
    return Math.round(difference / oneDay);
  };
  prepareSelectedOrderDataToDisplay = (orderId) => {
    this.props
      .fetchOrderDetails(orderId)
      .then(async (response) => {
        if (response.status === 200) {
         
          let orderDetails = response.data;
          await this.fetchSelectedCustomerItemCategories(
            orderDetails?.customer?.id
          );
          await this.fetchSelectedCustomerRelatedDetails(
            orderDetails?.customer?.id
          );
          await this.fetchTcsData();
          await this.props.fetchInternalUsers();
          var unSubscribed =
            this.state.selectedCustomerItemCategoriesNotSubscribed.filter(
              (a) =>
                a.id === orderDetails?.item_category?.id &&
                a.category_name === orderDetails?.item_category?.category_name
            );
          var subscribed =
            this.state.selectedCustomerItemCategoriesSubscribed.filter(
              (a) =>
                a.id === orderDetails?.item_category?.id &&
                a.category_name === orderDetails?.item_category?.category_name
            );
          if (unSubscribed.length > 0) {
            await this.setState({
              publishAt: unSubscribed[0].publish_at,
              disableUserToInputRate: false,
              originalRate: unSubscribed[0].rate,
              selectedItemCategoryBrokerage: Number(unSubscribed[0].brokerage) || 0,
              itemCategory: unSubscribed[0].id +
                "/" +
                unSubscribed[0].rate +
                "/" +
                unSubscribed[0].dailyrate_id +
                "/" +
                unSubscribed[0].brokerage +
                "/" +
                unSubscribed[0].publish_at +
                "/" +
                unSubscribed[0].is_allow_rate_zero +
                "/" +
                "Not Subscribed" +
                "/" +
                unSubscribed[0].loading_rate +
                "/" +
                unSubscribed[0].insurance_rate
            }, () => {
              console.log(this.state.itemCategory)
            });
          } else if (subscribed.length > 0) {
            await this.setState({
              publishAt: subscribed[0].publish_at,
              disableUserToInputRate: true,
              originalRate: subscribed[0].rate,
              selectedItemCategoryBrokerage: Number(subscribed[0].brokerage) || 0,
              itemCategory: subscribed[0].id +
                "/" +
                subscribed[0].rate +
                "/" +
                subscribed[0].dailyrate_id +
                "/" +
                subscribed[0].brokerage +
                "/" +
                subscribed[0].publish_at +
                "/" +
                subscribed[0].is_allow_rate_zero +
                "/" +
                "Subscribed" +
                "/" +
                subscribed[0].loading_rate +
                "/" +
                subscribed[0].insurance_rate
            }, () => {
              console.log(this.state.itemCategory)
            });
          }
          if (orderDetails?.discounts?.loading_premium > 0) {
            await this.setState({
              disableloadingPremium: true,
            })
          }
          if (orderDetails?.discounts?.insurance_premium > 0) {
            await this.setState({
              disableInsurancePremium: true,
            })
          }
          await this.setState({
            orderDetails,
            orderDetailsLoader: false,
            contact:
              orderDetails?.customer?.first_name +
              " " +
              orderDetails?.customer?.last_name,
            rates: orderDetails?.rate,
            tag: orderDetails?.tag,
            tagName: orderDetails?.tag_name,
            freight_basis: orderDetails?.freight_basis,
            ratePublishedAt: orderDetails?.daily_rate_published?.published_at,
            quantityEditable: orderDetails?.daily_rate_published?.is_new_rate_published,
            // ratesError: false,
            quantity: orderDetails?.quantity,
            loadingPremium: orderDetails?.discounts?.loading_premium,
            insurancePremium: orderDetails?.discounts?.insurance_premium,
            //disableInsurancePremium: this.state.disableInsurancePremium,
            //disableloadingPremium: this.state.disableloadingPremium,
            // quantityError: false,
            orderValidity: orderDetails?.validity_days,
            orderValidityDate: orderDetails?.order_validity,
            // this.generateOrderValidityDate(new Date(), 10),
            showCustomerDetail: true,
            // openCustomerListPopper: false,
            // new ui states
            allowBreakingFifoCheckbox: orderDetails?.is_fifo_allowed,
            isCustomerApproved: orderDetails?.is_approved_by_customer,
            customerApprovalCheckbox: orderDetails?.is_customer_approval_needed,
            adminRemark: orderDetails?.internal_remark,
            selectedCustomerData: orderDetails?.customer,
            // selectedCustomerPaymentTermsAllotted: [],
            // selectedCustomerPaymentTermsNotAllotted: [],
            // selectedCustomerItemCategoriesSubscribed: [],
            // selectedCustomerItemCategoriesNotSubscribed: [],
            paymentTerm: orderDetails?.payment_method?.id,
            // itemCategory:
            //   orderDetails?.item_category?.id
            //   +
            //   "/" +
            //   orderDetails?.item_category?.category_name,
            itemCategoryId: orderDetails?.item_category?.id,
            // plantPlanningData: [],
            // consigneeSelectionList: [],
            // buyerSelectionList: [],
            specialDiscount: orderDetails?.discounts?.special_discount || 0,
            specialPremium: orderDetails?.discounts?.special_premium || 0,
            totalDiscount:
              Number(orderDetails?.discounts?.item_discount || 0) +
              Number(orderDetails?.discounts?.city_discount || 0) +
              Number(orderDetails?.discounts?.customer_discount || 0) +
              Number(orderDetails?.discounts?.payment_discount || 0) +
              Number(orderDetails?.discounts?.special_discount || 0),
            selectedCustomerDiscount:
              orderDetails?.discounts?.customer_discount || 0,
            selectedBuyer: orderDetails?.buyer,
            selectedConsignee: orderDetails?.consignee,
            selectedBroker: orderDetails?.broker,
            // brokerSelectionList: [],
            brokerage: orderDetails?.broker?.brokerage || 0,
            //disableUserToInputRate: orderDetails?.rate > 0 ? false : true,
            currentOrderId: orderDetails?.id,
            customerRemark: orderDetails?.customer_remark,
            // displayOrderConfirmationScreen: false,
            // specialDiscountError: false,
            // brokerageError: false,
            // plantSelectionList: [],
            // tcsRate: this.state.allTcsRates.filter(
            //   (a) => a.id == orderDetails?.tcs_percent
            // )?.[0]?.id, //orderDetails?.tcs_percent,
            freightChargeRate: orderDetails?.freight_charge_percent,
            freightChargeAmount: (orderDetails?.freight_charge_percent * orderDetails?.quantity).toFixed(2),
            partyRefNo: orderDetails?.party_ref_number,
            partyRefDate: orderDetails?.party_ref_date,
            newBrokerage: orderDetails?.new_brokerage,
            orderNumber: orderDetails?.order_number,
            salesPerson: orderDetails?.sales_person,
          }, () => {
            this.handleBrokerageField();
            this.props?.orderStatus && this.handleEditHelperText()
          }
          );
        } else {
          this.setState({
            orderDetailsLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching contract data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          orderDetailsLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while  fetching contract data `,
          snackbarType: "error",
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.users !== this.props.users) {
      this.setState({
        salesPersonShow: (this.props?.users.find(d => (d.id == this.state?.salesPerson)))?.fullName
      }, () => console.log(this.state?.salesPersonShow))
    }
  }

  //   static getDerivedStateFromProps(props, state) {}
  generateOrderValidityDate = (dateObj, numberOfDaysToAdd) => {
    dateObj.setDate(dateObj.getDate() + Number(numberOfDaysToAdd));
    return dateObj;
  };
  handleSearchSalesPerson = (e, newInputValue, data) => {
    console.log(newInputValue)
    if (!newInputValue) {
      console.log("null 1")
      this.setState({ salesPerson: null, salesPersonShow: null, })
    }
    else if (newInputValue) {
      const user = data.find((e) => e.fullName === newInputValue);
      if (user) {
        console.log(user.id)
        this.setState({ salesPerson: user.id, salesPersonShow: user.fullName })
      }
    }
  }
  handleChange = (name) => (event) => {
    const { value, checked } = event.target;
    // console.log("name : ", name);
    // console.log("value : ", value);
    // console.log("checked : ", checked);
    if (
      name === "adminApprovalCheckbox" ||
      name === "allowBreakingFifoCheckbox" ||
      name === "customerApprovalCheckbox"
    ) {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
    if (name === "contact" && value !== "") {
      this.setState({ openCustomerListPopper: true });
      this.handleFetchSearchCustomerOptions(value);
    } //  else if (name === "contact" && (value !== "" || !value)) {
    //   this.handleResetAllStates();
    // }
    else if (name === "orderValidity") {
      let toDayDate = new Date();
      this.setState({
        orderValidityDate: this.generateOrderValidityDate(
          toDayDate,
          !value ? 0 : value
        ),
      });
    } else if (name === "specialDiscount") {
      let currentTotalDiscount =
        Number(this?.state?.selectedCustomerDiscount) + Number(value);
      this.setState({
        totalDiscount: currentTotalDiscount,
        specialDiscountError: Number(value) < 0 ? true : false,
      });
    } else if (name === "specialPremium") {
      this.setState({ specialPremiumError: Number(value) < 0 ? true : false })
    } else if (name === "quantity") {
      this.setState({
        quantityError:
          Number(value) > validOrderQuantityLimit || Number(value) <= 0
            ? true
            : false,
        remainingPlantPlanningQuantity: Number(value) + 1,
      });
    } else if (name === "rates") {
      const originalRt = isNaN(this.state.originalRate) || this.state.originalRate === null ? "" : this.state.originalRate;
      console.log(originalRt);
      let errorMessage = "";
      if (Number(value) <= 0 && !this.state.isAllowedRateZero) {
        errorMessage = "Invalid input rate"
      } else if (Number(value) < Number(originalRt)) {
        errorMessage = "Input rate should not be less than published rate."
      }
      this.setState({
        ratesError: (Number(value) <= 0 && !this.state.isAllowedRateZero) || Number(value) < Number(originalRt) ? true : false,
        rateErrorMessage: errorMessage
      });
    } else if (name === "newBrokerage") {
      if (checkBrokerage(value)) {
        console.log("newBrokerage", Number(value), Number(this.state?.selectedItemCategoryBrokerage))
        this.setState({
          brokerageError: !checkInvalidValue(this.state?.itemCategory)
            ? Number(value) === Number(this.state?.selectedItemCategoryBrokerage) || Number(value) === Number(0)
              ? false
              : `Entered Value should be ${this.state?.selectedItemCategoryBrokerage} or 0`
            : value === 0 || Number(value) < 0
              ? `Enter brokerage amount b/w 0-9999`
              : false,
        });
      }
      else {
        this.setState({
          brokerageError: true,
        })
      }
    } else if (name === "loadingPremium") {
      let errorMessage = "";
      if (Number(value === "" || Number(value) < 0)) {
        errorMessage = "Invalid Input field"
        this.setState({
          loadingPremiumError: Number(value === "" || Number(value) < 0),
          loadingPremiumErrorMsg: errorMessage
        });
      }
      else if (!(value.match(/^\d*\.?\d{0,2}$/))) {
        this.setState({
          loadingPremiumError: !(value.match(/^\d*\.?\d{0,2}$/)),
          loadingPremiumErrorMsg: "Enter upto 2 decimal digit"
        });
      }
      else {
        this.setState({
          loadingPremiumError: false,
          loadingPremiumErrorMsg: ""
        })
      }

    } else if (name === "insurancePremium") {
      let errorMessage = "";
      if (Number(value === "" || Number(value) < 0)) {
        errorMessage = "Invalid Input field"
        this.setState({
          insurancePremiumError: (Number(value === "" || Number(value) < 0)),
          insurancePremiumErrorMsg: errorMessage
        });
      }
      else if (!(value.match(/^\d*\.?\d{0,2}$/))) {
        this.setState({
          insurancePremiumError: !(value.match(/^\d*\.?\d{0,2}$/)),
          insurancePremiumErrorMsg: "Enter upto 2 decimal digit"
        });
      }
      else {
        this.setState({
          insurancePremiumError: false,
          insurancePremiumErrorMsg: ""
        });
      }

    } else if (name === "itemCategory") {
      if (!value) {
        console.log("undefined value ");
      } else {
        const itemData = value.split("/");
        console.log(itemData);
        let disableUserToInputRate =
          itemData[6] === "Subscribed" ? true : false;
        const disableloadingPremium = itemData[7] > 0 ? true : false;
        const disableInsurancePremium = itemData[8] > 0 ? true : false;
        console.log("itemData", itemData)
        this.setState({
          itemCategoryId: Number(itemData[0]),
          rates: Number(itemData[1]) > 0 ? Number(itemData[1]).toFixed(3) : 0,
          originalRate: Number(itemData[1]).toFixed(3),
          disableUserToInputRate,
          itemDailyRateId: Number(itemData[2]),
          brokerage: Number(itemData[3]) || 0,
          publishAt: itemData[4],
          new_brokerage: Number(itemData[3]) || 0,
          selectedItemCategoryBrokerage: Number(itemData[3]) || 0,
          ratesError: false,
          loadingPremiumError: false,
          insurancePremiumError: false,
          isAllowedRateZero: itemData[5],
          loadingPremium: Number(itemData[7]) > 0 ? Number(itemData[7]).toFixed(3) : 0,
          insurancePremium: Number(itemData[8]) > 0 ? Number(itemData[8]).toFixed(3) : 0,
          disableloadingPremium,
          disableInsurancePremium
        });
      }
    }
    // else if (name === "tcsRate") {
    //   let tcs = this?.state?.allTcsRates.filter((f) => f.id == value);
    //   if (tcs.length > 0) {
    //     let selectedTcsRate = tcs[0];
    //     this.setState({
    //       tcsAmount: parseFloat(
    //         calculatePercent(
    //           selectedTcsRate.tcs_percent,
    //           Number(this.state.rates) * Number(this.state.quantity)
    //         )
    //       ).toFixed(3),
    //       tcsRate: selectedTcsRate.id,
    //       tcs_percent: selectedTcsRate.tcs_percent,
    //     });
    //   }
    // }
    else if (name === "freightChargeRate") {
      this.setState({
        freightChargeAmount: parseFloat(Number(value) * Number(this.state.quantity)).toFixed(2)
      })
    }
  };
  handleDateChange = (date) => {
    if (date !== "Invalid Date") {
      this.setState({ partyRefDate: date });
    } else {
      this.setState({ partyRefDate: null });
    }
  };
  handleResetAllStates = () => {
    this.setState({
      contact: "",
      rates: 0,
      ratePublishedAt: null,
      quantityEditable: '',
      loadingPremium: 0,
      insurancePremium: 0,
      disableloadingPremium: false,
      disableInsurancePremium: false,
      originalRate: 0,
      ratesError: false,
      loadingPremiumError: false,
      insurancePremiumError: false,
      quantity: 0,
      quantityError: false,
      orderValidity: 10,
      orderValidityDate: this.generateOrderValidityDate(new Date(), 10),
      showCustomerDetail: false,
      openCustomerListPopper: false,
      // new ui states
      allowBreakingFifoCheckbox: false,
      customerApprovalCheckbox: false,
      adminRemark: null,
      selectedCustomerData: null,
      selectedCustomerPaymentTermsAllotted: [],
      selectedCustomerPaymentTermsNotAllotted: [],
      selectedCustomerItemCategoriesSubscribed: [],
      selectedCustomerItemCategoriesNotSubscribed: [],
      paymentTerm: null,
      itemCategory: null,
      itemCategoryId: null,
      plantPlanningData: [],
      laTruckCapacity: 0,
      consigneeSelectionList: [],
      buyerSelectionList: [],
      specialDiscount: 0,
      specialPremium: 0,
      totalDiscount: 0,
      selectedCustomerDiscount: 0,
      selectedBuyer: null,
      selectedConsignee: null,
      selectedBroker: null,
      brokerSelectionList: [],
      brokerage: 0,
      newBrokerage: 0,
      disableUserToInputRate: false,
      currentOrderId: null,
      customerRemark: null,

      displayOrderConfirmationScreen: false,
      specialDiscountError: false,
      specialPremiumError: false,
      brokerageError: false,
      plantSelectionList: [],
      //tcsRate: 0,
      tcsAmount: 0,
      freightChargeRate: 0,
      freightChargeAmount: 0,
      remainingPlantPlanningQuantity: 0,
      itemDailyRateId: null,
      publishAt: null,
      freight_basis: null,
      freight_basis_show: null,
      tag: null,
      tagName: null,
      finishBtnClicked: false,
    });
  };
  handleAutocompleteValueChange = (name) => (event, value) => {
    if (!value) {
      this.setState({ [name]: null });
      if (name === "selectedBroker") {
        this.setState({ brokerage: 0 });
      }
    } else {
      console.log(value)
      this.setState({ [name]: value });
      if (name === "selectedBroker") {
        this.setState({ brokerage: !value.brokerage ? 0 : value.brokerage });
      }
    }
  };

  handleAutoCompleteSearch = (e, newInputValue, data, field, searchField) => {
    console.log(newInputValue)
    if (!newInputValue) {
      this.setState({ [field]: null })
    }
    else if (newInputValue) {
      const user = data.find((e) => e[searchField] === newInputValue);
      if (user) {
        console.log(user.id, field, newInputValue)
        this.setState({ [field]: user.id })
      }
    }
  }

  handleFetchSearchCustomerOptions = (searchValue) => {
    this.props
      .fetchSearchCustomerOptions(searchValue)
      .then((response) => {
        // console.log("fetchSearchCustomerOptions response ====> ", response);
        if (response.status === 200 && response.data.count > 0) {
          this.setState({
            customer: response.data.results,
            customerSearchOptionEmpty: false,
          });
        } else {
          this.setState({
            customerSearchOptionEmpty: true,
            customer: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          customerSearchOptionEmpty: true,
          customer: [],
        });
      });
  };
  handleCustomSetState = (name, value) => {
    this.setState({ [name]: value });
    if (name === "selectedCustomerData") {
      this.setState({
        showCustomerDetailLoader: true,
        selectedCustomerDiscount: value?.customer_discount || 0,
      });
      this.fetchSelectedCustomerRelatedDetails(value?.id);
    }
  };
  fetchSelectedCustomerRelatedDetails = async (customerId) => {
    await this.handleFetchBuyers(customerId);
    await this.handleFetchConsignees(customerId);
    await this.fetchSelectedCustomerItemCategories(customerId);
    await this.fetchSelectedCustomerPaymentTerms(customerId);
    await this.fetchTcsData();
    await this.handleFetchBrokers();
    await this.setState({ showCustomerDetailLoader: false });
  };
  fetchSelectedCustomerPaymentTerms = (customerId) => {
    this.props
      .fetchCustomerPaymentTerms(customerId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            selectedCustomerPaymentTermsAllotted:
              response.data.allotted_payment_method,
            selectedCustomerPaymentTermsNotAllotted:
              response.data.not_allotted_payment_method,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching payment terms for customer",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching payment terms for customer",
          snackbarType: "error",
        });
      });
  };
  fetchSelectedCustomerItemCategories = async (customerId) => {
    await this.props
      .fetchItemCategories(customerId)
      .then((response) => {
        if (response.status === 200) {
          console.log("data for category", response)
          this.setState({
            selectedCustomerItemCategoriesSubscribed: response.data.subscribed,
            selectedCustomerItemCategoriesNotSubscribed:
              response.data.unsubscribed,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching item categories",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching item categories",
          snackbarType: "error",
        });
      });
  };
  handleFetchBuyers = (customerId) => {
    this.props
      .fetchBuyers(customerId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ buyerSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching buyers list",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching buyers list",
          snackbarType: "error",
        });
      });
  };
  handleFetchBrokers = () => {
    this.props
      .fetchBrokers()
      .then((response) => {
        console.log("fetchBrokers response : ", response);
        if (response.status === 200) {
          this.setState({ brokerSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.data?.msg
                  ? response?.data?.msg
                  : "Error occured while fetching brokers list",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : error?.data?.msg
                ? error?.data?.msg
                : "Error occured while fetching brokers list",
          snackbarType: "error",
        });
      });
  };
  handleFetchConsignees = (customerId) => {
    this.props
      .fetchConsignees(customerId)
      .then((response) => {
        // console.log("fetchConsignees response : ", response);
        if (response.status === 200) {
          this.setState({ consigneeSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching consignee list",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : error?.data
                ? error?.data
                : "Error occured while fetching consignee list",
          snackbarType: "error",
        });
      });
  };
  fetchTcsData = async () => {
    await this.props
      .fetchTcs()
      .then((response) => {
        //console.log("fetchTcs : ", response);
        if (response.status === 200) {
          this.setState({ allTcsRates: response.data });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching tcs data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching tcs data",
          snackbarType: "error",
        });
      });
  };

  handleClosePopper = () => {
    this.setState({ openCustomerListPopper: false });
  };
  handleTabChange = (event, tabValue) => {
    if (!this.state.currentOrderId) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "Contract must be created before plant planning",
        snackbarType: "warning",
      });
    } else {
      this.setState({ tabValue });
    }
  };
  handleUpdateOrder = () => {
    console.log(this.state);
    let orderData = {
      rate: this?.state?.rates == "NaN" ? 0 : this?.state?.rates,
      loading_premium: this?.state?.loadingPremium == "NaN" ? 0 : Number(this?.state?.loadingPremium),
      insurance_premium: this?.state?.insurancePremium == "NaN" ? 0 : Number(this?.state?.insurancePremium),
      quantity: this?.state?.quantity,
      order_validity: this?.state?.orderValidityDate,
      internal_remark: this?.state?.adminRemark,
      validity_days: this?.state?.orderValidity,
      customer_remark: this?.state?.customerRemark,
      // is_approved_by_admin: this?.state?.adminApprovalCheckbox,
      // is_approved_by_customer: this?.state?.isCustomerApproved,
      is_customer_approval_needed: this?.state?.isClientApproved,
      is_fifo_allowed: this?.state?.allowBreakingFifoCheckbox,
      is_customer_approval_needed: this?.state?.customerApprovalCheckbox,
      // payment_status: "",
      brokerage: this?.state?.brokerage,
      new_brokerage: this?.state?.newBrokerage,
      // daily_rate: this?.state?.itemDailyRateId, //23, //published daily rate id to be passed here after field with value updated.
      customer: this?.state?.selectedCustomerData?.id,
      broker: this?.state?.selectedBroker?.id,
      buyer: !(this?.state?.selectedBuyer?.buyer_id || this?.state?.selectedBuyer?.id) ? null : (this?.state?.selectedBuyer?.buyer_id || this?.state?.selectedBuyer?.id),
      consignee: this?.state?.selectedConsignee?.id == undefined ? null : this?.state?.selectedConsignee?.id,
      item_category: this?.state?.itemCategoryId,
      payment_method: this?.state?.paymentTerm,
      special_discount: this?.state?.specialDiscount,
      special_premium: this?.state?.specialPremium,
      party_ref_number: this?.state?.partyRefNo,
      party_ref_date: moment(this?.state?.partyRefDate).format("YYYY-MM-DD"),
      //tcs_percent: this?.state?.tcsRate,
      // tcs_amount: this?.state?.tcsAmount,
      freight_charge_percent: this?.state?.freightChargeRate,
      freight_charge_amount: this?.state?.freightChargeAmount,
      publish_at: this?.state?.publishAt,
      sales_person: this.state?.salesPerson == null ? null : this.state?.salesPerson,
      freight_basis: this.state?.freight_basis == null ? null : this.state?.freight_basis,
      tag: this.state?.tag == null ? null : this.state?.tag,
    };
    // console.log(orderData)
    // console.log(this?.state?.selectedBuyer)
    if (this.props.orderCreatedBy?.includes("Customer")) {
      orderData.is_approved_by_admin = true
    }
    this.handleOrderUpdate(orderData, this?.state?.selectedOrderId);
  };
  handleViewOrderDetails = () => {
    this.setState({ displayOrderConfirmationScreen: false }, () =>
      this.props.history.push(
        `/internalContracts/singleContracts/${this?.state?.currentOrderId}`
      )
    );
  };
  addNewPlantPlanningData = () => {
    this.setState((previousState) => {
      return {
        plantPlanningData: [
          ...previousState.plantPlanningData,
          {
            order: this?.state?.currentOrderId,
            plant: null,
            allotted_qty: 0,
            dispached_qty: 0,
            pending_qty: 0,
            allottedError: null,
          },
        ],
      };
    });
  };
  removeNewPlantPlanningData = (plantPlanningDataIndex) => {
    this.setState((previousState) => {
      const plantPlanningData = [...previousState.plantPlanningData];
      plantPlanningData.splice(plantPlanningDataIndex, 1);
      return { plantPlanningData };
    });
  };
  handlePlantPlanningDataValueChange = (index, name) => async (event) => {
    const { value } = event.target;
    if (name === "allotted_qty") {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          [name]: value,
          ["pending_qty"]: value,
        };
        return { plantPlanningData };
      });
    } else {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          [name]: value,
        };
        return { plantPlanningData };
      });
    }
  };
  checkAllotedValue = (index, name) => async (event) => {
    const { value } = event.target;

    let totalPlantPlaningQuantity = this?.state?.plantPlanningData
      .map((o) => o.allotted_qty)
      .reduce((a, c) => {
        return Number(a) + Number(c);
      });

    let totalOrderQuantity = Number(this?.state?.quantity);

    if (totalPlantPlaningQuantity > totalOrderQuantity) {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          [name]: 0,
          ["allottedError"]:
            Math.abs(totalPlantPlaningQuantity - value - totalOrderQuantity) ===
              0
              ? "all quantity already assigned"
              : `Only ${Math.abs(
                totalPlantPlaningQuantity - value - totalOrderQuantity
              )} MT are left allot`,
        };
        return { plantPlanningData };
      });
    } else {
      await this.setState((previousState) => {
        const plantPlanningData = [...previousState.plantPlanningData];
        plantPlanningData[index] = {
          ...plantPlanningData[index],
          ["allottedError"]: null,
        };
        return { plantPlanningData };
      });
    }
  };
  approveOrder = (orderData, orderId) => {
    return axios
      .patch(
        `${APIROUTES.CONTRACTS_UPDATE}${orderId}/`,
        orderData,
        this.getOptions(this.props.user)
      )
  }
  handleOrderApprove = (orderId) => {
    let orderData = {
      is_approved_by_admin: true,
    };
    this.approveOrder(orderData, orderId)
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Contract Reviewed & Approved Successfully`,
            "success"
          );
          setTimeout(() => {
            // this.props.prepareSelectedOrderDataToDisplay(orderId)
            // this.props.fetchPendingQuantity(orderId)'
            this.props.history.push(
              `/internalContracts/singleContracts/${this.state.selectedOrderId}`
            )
            this.handleCloseSnackbar();
          }, 3000);
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleOrderUpdate = async (orderData, orderId) => {
    console.log("i am here");
    let currentTabValue = this.state.tabValue;
    this.props
      .updateOrder(orderData, orderId)
      .then((response) => {
        //console.log("createOrder : ", response);
        if (response.status === 200) {
          if (this.props.orderCreatedBy?.includes("Customer")) {
            // this.handleOrderApprove(orderId)
            this.handleOpenSnackbar(
              `Contract Reviewed & Approved Successfully`,
              "success"
            );
            setTimeout(() => {
              this.props.history.push(
                `/internalContracts/singleContracts/${this.state.selectedOrderId}`
              )
              this.handleCloseSnackbar();
            }, 3000);
          }
          else {
            this.setState({
              currentOrderId: response.data.id,
              tabValue: currentTabValue + 1,
              openSnackbar: true,
              snackbarMessage: "Contract updated successfully",
              snackbarType: "success",
            });
            setTimeout(() => {
              this.props.history.push(
                `/internalContracts/singleContracts/${this.state.selectedOrderId}`
              );
            }, 2000);
          }
        } else {
          if (this.props.orderCreatedBy?.includes("Customer")) {
            this.handleOpenSnackbar(
              `Error occured while Contract Reviewing & Approving `,
              "error"
            );
          }
          else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while updating contract",
              snackbarType: "error",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data)
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 5000);

        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: error?.data?.details
        //     ? error?.data?.details
        //     : error?.data?.message
        //       ? error?.data?.message
        //       : "Error occured while creating order",
        //   snackbarType: "error",
        // });
      });
  };
  handleSavePlantPlanningData = (plantPlanningData) => {
    this.props
      .savePlantPlanningData(plantPlanningData)
      .then((response) => {
        //console.log("savePlantPlanningData : ", response);
        if (response.status === 200) {
          this.props.history.push(
            `/internalContracts/singleContracts/${this?.state?.currentOrderId}`
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while saving plant planning data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while saving plant planning data",
          snackbarType: "error",
        });
      });
  };
  handleFetchPlantsForSelectedItemCategory = () => {
    let itemCategoryId = this?.state?.itemCategoryId;
    this.props
      .fetchPlantsForItemCategory(itemCategoryId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ plantSelectionList: response.data.results });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : "Error occured while fetching plants",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : "Error occured while fetching plants",
          snackbarType: "error",
        });
      });
  };

  handleUnlockBrokerage = () => {
    this.setState({
      updateBrokerage: !this.state.updateBrokerage,
    });
  };

  checkDataValidityForSaveAndTabChange = (currentTabValue, ratesError, loadingPremiumError, insurancePremiumError, quantityError) => {
    if (currentTabValue === 0) {
      // console.log("rate", this?.state?.rates == "NaN", this?.state.itemCategory?.split("/")[5] == "true", this?.state?.rates == 0, this?.state?.rates == "NaN")
      if (ratesError
        || loadingPremiumError
        || insurancePremiumError
        || quantityError
        || (!this?.state?.quantity || this?.state?.quantity === "")
        || (!this?.state?.orderValidityDate || this?.state?.orderValidityDate === "")
        || (!this?.state?.selectedCustomerData?.id || this?.state?.selectedCustomerData?.id === "")
        // || (!this?.state?.selectedBuyer?.id || this?.state?.selectedBuyer?.id === "")
        // || (!this?.state?.selectedConsignee?.id || this?.state?.selectedConsignee?.id === "")
        || (!this?.state?.itemCategoryId || this?.state?.itemCategoryId === "")
        || (!this?.state?.salesPerson || this.state?.salesPerson === "")
        || (!this?.state?.partyRefDate || this.state?.partyRefDate === "")
        || (!this?.state?.paymentTerm || this.state?.paymentTerm === "")
        || (!this.state?.freight_basis || this.state?.freight_basis === "")
        || this.state.brokerageError
        || this.state.updateBrokerage
      ) {
        return true;
      }
      if (this?.state.itemCategory?.split("/")[5] == "false") {
        if (this?.state?.rates == 0 || this?.state?.rates == "NaN") {
          return true
        }
      }
      return false;

    };
  }
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  render() {
    const {
      tabValue,
      openSnackbar,
      snackbarMessage,
      snackbarType,
      displayOrderConfirmationScreen,
      openCustomerListPopper,
      confirmationDialogOpen,
      contact,
      customer,
      itemCategory,
      rates,
      ratePublishedAt,
      quantityEditable,
      paymentTerm,
      tcsRate,
      partyRefDate,
      partyRefNo,
      orderNumber,
      adminRemark,
      customerRemark,
      buyer,
      newBrokerage,
      editData,
      editHelperText,
    } = this.state;
    const { classes, isMobile } = this?.props;
    // console.log("orderDetails : ", this.state.orderDetails);
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <Bread
              data={[
                {
                  title: "Contracts",
                  style: {
                    fontSize: isMobile && pxToEm(15),
                  },
                  onClick: () => this.props.history.push(INTERNALROUTES.ORDERS),
                },
                {
                  title: this.props.orderCreatedBy?.includes("Customer")
                    ? `Review & Approve | Contract ${orderNumber}`
                    : `Edit | Contract ${orderNumber}`,
                  style: {
                    fontSize: isMobile && pxToEm(15),
                  },
                  onClick: "",
                },
              ]}
            />

            <Paper elevation={5} className={classes.paperStyle}>
              {console.log(isEmpty(this.state?.orderDetails))}
              {isEmpty(this.state?.orderDetails)
                ?
                (<div className={classes.noDataDiv}>
                  <SpinLoader />
                </div>)
                :
                (<>
                  <div className={classes.contactTextFieldContainer} style={{ width: isMobile && '100%' }}>
                    <Downshift id="downshift-popper">
                      {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
                        <div className={classes.container}>
                          <TextField
                            name="contact"
                            id="outlined-name"
                            label="Customer*"
                            variant="outlined"
                            disabled={true}
                            margin="normal"
                            fullWidth
                            // style={{ width: "90%" }}
                            onChangeCapture={this.handleChange("contact")}
                            inputRef={(node) => (this.inpRef1 = node)}
                            helperText={editData.contact}
                            InputProps={{
                              ...getInputProps({
                                value: contact,
                                placeholder: "",
                              }),
                            }}
                          />

                          {this.inpRef1 && (
                            <Popper
                              open={openCustomerListPopper}
                              anchorEl={this.inpRef1}
                            >
                              <div
                                {...(isOpen
                                  ? getMenuProps({}, { suppressRefError: true })
                                  : {})}
                              >
                                <Paper
                                  square
                                  style={{
                                    maxHeight: "155px",

                                    overflowY: "scroll",
                                    marginTop: 8,
                                    width: this.inpRef1
                                      ? this.inpRef1.clientWidth
                                      : null,
                                  }}
                                >
                                  {customer.map((data) => (
                                    <MenuItem
                                      key={data.gstNo}
                                      component="div"
                                      onClick={() => {
                                        this.handleCustomSetState(
                                          "contact",
                                          (data?.first_name || " ") +
                                          " " +
                                          (data?.last_name || "")
                                        );
                                        this.handleCustomSetState(
                                          "selectedCustomerData",
                                          data
                                        );
                                        this.handleCustomSetState(
                                          "openCustomerListPopper",
                                          false
                                        );
                                        this.handleCustomSetState(
                                          "showCustomerDetail",
                                          true
                                        );
                                        this.handleCustomSetState(
                                          "showCustomerDetailLoader",
                                          true
                                        );
                                        this.handleCustomSetState(
                                          "showCustomerDetailLoader",
                                          false
                                        );
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          lineHeight: "1em",
                                        }}
                                      >
                                        <span>
                                          {data?.first_name || " "}{" "}
                                          {data?.last_name || ""}
                                        </span>
                                        <span
                                          style={{ color: grey, fontSize: "0.7em" }}
                                        >
                                          {!data?.gstin_number
                                            ? !data?.pan_card_number
                                              ? ""
                                              : `PAN - ${data?.pan_card_number}`
                                            : `GSTIN - ${data?.gstin_number}`}
                                        </span>
                                        <span
                                          style={{ color: grey, fontSize: "0.7em" }}
                                        >
                                          PHONE - {data?.phone || ""}
                                        </span>
                                      </div>
                                    </MenuItem>
                                  ))}
                                </Paper>
                              </div>
                            </Popper>
                          )}
                        </div>
                      )}
                    </Downshift>

                    {this?.state?.showCustomerDetail &&
                      this?.state?.showCustomerDetailLoader === false ? (
                      <>
                        <div className={classes.container}>
                          <Autocomplete
                            autoSelect
                            value={this.state.selectedBuyer}
                            options={
                              this?.state?.buyerSelectionList.length > 0
                                ? this?.state?.buyerSelectionList
                                : []
                            }
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            onChange={this.handleAutocompleteValueChange(
                              "selectedBuyer"
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Buyer"
                                margin="normal"
                                variant="outlined"
                                helperText={editData.buyer}
                              />
                            )}
                          />
                        </div>
                        <div className={classes.container}>
                          <Autocomplete
                            autoSelect
                            value={this.state?.consigneeSelectionList?.find((v) =>
                              v.id === this.state?.selectedConsignee?.id)}
                            onChange={this.handleAutocompleteValueChange(
                              "selectedConsignee"
                            )}
                            options={
                              this?.state?.consigneeSelectionList.length > 0
                                ? this?.state?.consigneeSelectionList
                                : []
                            }
                            getOptionLabel={(option) =>
                              (option?.consignee_detail?.first_name || "") +
                              " " +
                              (option?.consignee_detail?.last_name || "") +
                              ", " +
                              (
                                option?.consignee_address?.address ?
                                  (
                                    (option?.consignee_address?.address
                                      ? option?.consignee_address?.address + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.city
                                      ? option?.consignee_address?.city + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.state
                                      ? option?.consignee_address?.state + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.country
                                      ? option?.consignee_address?.country + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.pincode
                                      ? option?.consignee_address?.pincode
                                      : "")
                                  )
                                  :
                                  (
                                    (option?.consignee_address?.door_number
                                      ? option?.consignee_address?.door_number + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.floor_number
                                      ? 'Floor No - ' + option?.consignee_address?.floor_number + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.building_name
                                      ? option?.consignee_address?.building_name + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.street
                                      ? option?.consignee_address?.street + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.location
                                      ? option?.consignee_address?.location + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.district_name
                                      ? option?.consignee_address?.district_name + ", "
                                      : "")
                                    +
                                    ((option?.consignee_address?.city_name && option?.consignee_address?.district_name)
                                      ? ((option?.consignee_address?.city_name).trim().toUpperCase() !== (option?.consignee_address?.district_name).trim().toUpperCase())
                                        ? option?.consignee_address?.city_name + ", "
                                        : ""
                                      : option?.consignee_address?.city_name
                                        ? option?.consignee_address?.city_name + ", "
                                        : "")
                                    +
                                    (option?.consignee_address?.state_name
                                      ? option?.consignee_address?.state_name + ", "
                                      : "")
                                    +
                                    (option?.consignee_address?.pincode
                                      ? option?.consignee_address?.pincode
                                      : "")
                                  )
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Consignee"
                                margin="normal"
                                variant="outlined"
                                helperText={editData.consignee}
                              />
                            )}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className={classes.contactTextFieldContainer} style={{ width: isMobile && '100%' }}>
                    <div className={classes.container} style={{ paddingLeft: 5 }}>
                      <div className={classes.customerInfoContainer}>
                        {this?.state?.showCustomerDetail ? (
                          <>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                NAME:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedCustomerData?.first_name || ""}{" "}
                                {this?.state?.selectedCustomerData?.last_name || ""}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                {!this?.state?.selectedCustomerData?.gstin_number
                                  ? !this?.state?.selectedCustomerData
                                    ?.pan_card_number
                                    ? ""
                                    : "PAN: "
                                  : "GSTIN: "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {!this?.state?.selectedCustomerData?.gstin_number
                                  ? !this?.state?.selectedCustomerData
                                    ?.pan_card_number
                                    ? ""
                                    : this?.state?.selectedCustomerData
                                      ?.pan_card_number
                                  : this?.state?.selectedCustomerData?.gstin_number}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                TYPE:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>{this?.state?.selectedCustomerData?.customer_type ||
                                "Direct Customer"}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                DISCOUNT:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                ₹{" "}
                                {parseFloat(
                                  this?.state?.selectedCustomerDiscount || 0
                                ).toFixed(3)}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                STATE:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedCustomerData?.address.address
                                  ? this?.state?.selectedCustomerData?.address?.state
                                  : this?.state?.selectedCustomerData?.address?.state_name}
                              </span>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className={classes.container} style={{ paddingLeft: 5 }}>
                      <div className={classes.customerInfoContainer}>
                        {!this?.state?.selectedBuyer ? null : (
                          <>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                NAME:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedBuyer?.first_name || ""}{" "}
                                {this?.state?.selectedBuyer?.last_name || ""}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                {!this?.state?.selectedBuyer?.gstin_number
                                  ? !this?.state?.selectedBuyer?.pan_card_number
                                    ? ""
                                    : "PAN: "
                                  : "GSTIN: "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {!this?.state?.selectedBuyer?.gstin_number
                                  ? !this?.state?.selectedBuyer?.pan_card_number
                                    ? ""
                                    : this?.state?.selectedBuyer?.pan_card_number
                                  : this?.state?.selectedBuyer?.gstin_number}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                PHONE:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedBuyer?.phone || ""}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                ADDRESS:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {
                                  (
                                    this?.state?.selectedBuyer?.address?.address ?
                                      (
                                        (this?.state?.selectedBuyer?.address?.address
                                          ? this?.state?.selectedBuyer?.address?.address + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.city
                                          ? this?.state?.selectedBuyer?.address?.city + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.state
                                          ? this?.state?.selectedBuyer?.address?.state + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.country
                                          ? this?.state?.selectedBuyer?.address?.country + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.pincode
                                          ? this?.state?.selectedBuyer?.address?.pincode
                                          : "")

                                      )
                                      :
                                      (
                                        (this?.state?.selectedBuyer?.address?.door_number
                                          ? this?.state?.selectedBuyer?.address?.door_number + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.floor_number
                                          ? 'Floor No - ' + this?.state?.selectedBuyer?.address?.floor_number + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.building_name
                                          ? this?.state?.selectedBuyer?.address?.building_name + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.street
                                          ? this?.state?.selectedBuyer?.address?.street + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.location
                                          ? this?.state?.selectedBuyer?.address?.location + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.district_name
                                          ? this?.state?.selectedBuyer?.address?.district_name + ", "
                                          : "")
                                        +
                                        ((this?.state?.selectedBuyer?.address?.city_name && this?.state?.selectedBuyer?.address?.district_name)
                                          ? ((this?.state?.selectedBuyer?.address?.city_name).trim().toUpperCase() !== (this?.state?.selectedBuyer?.address?.district_name).trim().toUpperCase())
                                            ? this?.state?.selectedBuyer?.address?.city_name + ", "
                                            : ""
                                          : this?.state?.selectedBuyer?.address?.city_name
                                            ? this?.state?.selectedBuyer?.address?.city_name + ", "
                                            : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.state_name
                                          ? this?.state?.selectedBuyer?.address?.state_name + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedBuyer?.address?.pincode
                                          ? this?.state?.selectedBuyer?.address?.pincode
                                          : "")
                                      )
                                  )
                                }
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                STATE:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedBuyer?.address?.address
                                  ? this?.state?.selectedBuyer?.address?.state
                                  : this?.state?.selectedBuyer?.address?.state_name}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={classes.container} style={{ paddingLeft: 5 }}>
                      <div className={classes.customerInfoContainer}>
                        {!this?.state?.selectedConsignee ? null : (
                          <>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                NAME:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedConsignee?.consignee_detail
                                  ?.first_name || ""}{" "}
                                {this?.state?.selectedConsignee?.consignee_detail
                                  ?.last_name || ""}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                {!this?.state?.selectedConsignee?.consignee_detail
                                  ?.gstin_number
                                  ? !this?.state?.selectedConsignee?.consignee_detail
                                    ?.pan_card_number
                                    ? ""
                                    : "PAN: "
                                  : "GSTIN: "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {!this?.state?.selectedConsignee?.consignee_detail
                                  ?.gstin_number
                                  ? !this?.state?.selectedConsignee?.consignee_detail
                                    ?.pan_card_number
                                    ? ""
                                    : this?.state?.selectedConsignee?.consignee_detail
                                      ?.pan_card_number
                                  : this?.state?.selectedConsignee?.consignee_detail
                                    ?.gstin_number}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                PHONE:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedConsignee?.consignee_detail
                                  ?.phone || ""}
                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                ADDRESS:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {
                                  (
                                    this?.state?.selectedConsignee?.consignee_address?.address ?
                                      (
                                        (this?.state?.selectedConsignee?.consignee_address?.address
                                          ? this?.state?.selectedConsignee?.consignee_address?.address + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.city
                                          ? this?.state?.selectedConsignee?.consignee_address?.city + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.state
                                          ? this?.state?.selectedConsignee?.consignee_address?.state + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.country
                                          ? this?.state?.selectedConsignee?.consignee_address?.country + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.pincode
                                          ? this?.state?.selectedConsignee?.consignee_address?.pincode
                                          : "")
                                      )
                                      :
                                      (
                                        (this?.state?.selectedConsignee?.consignee_address?.door_number
                                          ? this?.state?.selectedConsignee?.consignee_address?.door_number + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.floor_number
                                          ? 'Floor No - ' + this?.state?.selectedConsignee?.consignee_address?.floor_number + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.building_name
                                          ? this?.state?.selectedConsignee?.consignee_address?.building_name + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.street
                                          ? this?.state?.selectedConsignee?.consignee_address?.street + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.location
                                          ? this?.state?.selectedConsignee?.consignee_address?.location + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.district_name
                                          ? this?.state?.selectedConsignee?.consignee_address?.district_name + ", "
                                          : "")
                                        +
                                        ((this?.state?.selectedConsignee?.consignee_address?.city_name && this?.state?.selectedConsignee?.consignee_address?.district_name)
                                          ? ((this?.state?.selectedConsignee?.consignee_address?.city_name).trim().toUpperCase() !== (this?.state?.selectedConsignee?.consignee_address?.district_name).trim().toUpperCase())
                                            ? this?.state?.selectedConsignee?.consignee_address?.city_name + ", "
                                            : ""
                                          : this?.state?.selectedConsignee?.consignee_address?.city_name
                                            ? this?.state?.selectedConsignee?.consignee_address?.city_name + ", "
                                            : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.state_name
                                          ? this?.state?.selectedConsignee?.consignee_address?.state_name + ", "
                                          : "")
                                        +
                                        (this?.state?.selectedConsignee?.consignee_address?.pincode
                                          ? this?.state?.selectedConsignee?.consignee_address?.pincode
                                          : "")
                                      )
                                  )
                                }

                              </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                              <span
                                style={{
                                  width: "30%",
                                  fontWeight: "bold",
                                  color: grey,
                                }}
                              >
                                STATE:{" "}
                              </span>
                              <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                                {this?.state?.selectedConsignee?.consignee_address?.address
                                  ? this?.state?.selectedConsignee?.consignee_address?.state
                                  : this?.state?.selectedConsignee?.consignee_address?.state_name
                                }
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {this?.state?.showCustomerDetailLoader ? (
                    <div className={classes.spinLoaderContiner}>
                      <SpinLoader />
                      <span
                        style={{
                          marginTop: "40px",
                          fontWeight: "bold",
                          color: grey,
                        }}
                      >
                        LOADING SUBSCRIPTION
                      </span>
                    </div>
                  ) : this?.state?.showCustomerDetail &&
                    this?.state?.showCustomerDetailLoader === false ? (
                    <div style={{
                      alignItems: isMobile && "center",
                      display: isMobile && "flex",
                      flexDirection: isMobile && 'column',
                      width: isMobile && '100%'
                    }}>
                      <div
                        style={{
                          width: "90%",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: isMobile && 'column',
                          justifyContent: "space-around",
                          marginBottom: "50px",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl3}
                          style={{
                            width: isMobile && '100%',
                            margin: isMobile && '30px 0'
                          }}
                        >
                          <InputLabel id="categorySelectLable">
                            Select Category*
                          </InputLabel>
                          <Select
                            id="categorySelectLable"
                            value={itemCategory}
                            // disabled={this?.state?.contractOperation === "edit"}
                            disabled={true}
                            onChange={this.handleChange("itemCategory")}
                            MenuProps={MenuProps}
                            label="Select Category*"
                          >
                            <ListSubheader
                              style={{ color: grey }}
                              disableSticky={true}
                              disabled={true}
                            >
                              Subscribed
                            </ListSubheader>
                            {this?.state?.selectedCustomerItemCategoriesSubscribed.map(
                              (option, index) => {
                                return (
                                  <MenuItem
                                    value={
                                      option.id +
                                      "/" +
                                      option.rate +
                                      "/" +
                                      option.dailyrate_id +
                                      "/" +
                                      option.brokerage +
                                      "/" +
                                      option.publish_at +
                                      "/" +
                                      option.is_allow_rate_zero +
                                      "/" +
                                      "Subscribed"
                                      +
                                      "/" +
                                      option.loading_rate +
                                      "/" +
                                      option.insurance_rate
                                    }
                                    //value={option.id + "/" + option.category_name}

                                    style={{ color: "black" }}
                                    key={index}
                                  >
                                    {option.category_name}
                                  </MenuItem>
                                );
                              }
                            )}

                            <ListSubheader
                              style={{ color: grey }}
                              disableSticky={true}
                              disabled={true}
                            >
                              Not Subscribed
                            </ListSubheader>
                            {console.log(this?.state?.selectedCustomerItemCategoriesNotSubscribed)}
                            {this?.state?.selectedCustomerItemCategoriesNotSubscribed.map(
                              (option, index) => {
                                return (
                                  <MenuItem
                                    value={
                                      option.id +
                                      "/" +
                                      option.rate +
                                      "/" +
                                      option.dailyrate_id +
                                      "/" +
                                      option.brokerage +
                                      "/" +
                                      option.publish_at +
                                      "/" +
                                      option.is_allow_rate_zero +
                                      "/" +
                                      "Not Subscribed"
                                      +
                                      "/" +
                                      option.loading_rate +
                                      "/" +
                                      option.insurance_rate
                                    }
                                    //value={option.id + "/" + option.category_name}

                                    style={{ color: "black" }}
                                    key={index}
                                  >
                                    {option.category_name}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                          <FormHelperText className={classes.helperText}>
                            {editData.itemCategory}
                          </FormHelperText>
                        </FormControl>
                        {console.log(this.state?.tag
                          ? (this.props?.tagsMaster?.find(t => t.id === this.state?.tag))?.tag_name
                          : "")}
                        <Autocomplete
                          autoSelect
                          style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0, marginTop: 9 }}
                          name="Tag"
                          options={this.props?.tagsMaster.map((opt) => String(opt.tag_name))}
                          onInputChange={(e, value) => this.handleAutoCompleteSearch(e, value, this.props?.tagsMaster, 'tag', 'tag_name')}
                          defaultValue={!Boolean((this.props?.tagsMaster?.find(t => t.id === this.state?.tag))?.tag_name) && this.state?.tagName}
                          value={this.state?.tag
                            ? (this.props?.tagsMaster?.find(t => t.id === this.state?.tag))?.tag_name
                            : ""}
                          ListboxProps={{ style: { maxHeight: '100px', } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Tag'}
                              margin="normal"
                              variant="outlined"
                              style={{ width: isMobile && '100%', marginBottom: 0 }}
                              helperText={editData.tag}
                            />
                          )}
                        />
                        <TextField
                          name="rates"
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "0px",
                          }}
                          disabled={true}
                          // disabled={this?.state?.disableUserToInputRate}
                          // disabled={this.props.orderStatus !== "PENDING"}
                          value={this?.state?.rates == 0 ? this?.state?.rates : Number(this?.state?.rates).toFixed(0)}
                          id="outlined-name"
                          label="Rate*"
                          // margin="normal"
                          variant="outlined"
                          onChange={this.handleChange("rates")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span style={{ fontSize: "0.9em", color: grey }}>
                                  ₹
                                </span>
                              </InputAdornment>
                            ),
                          }}
                          error={this?.state?.ratesError}
                          FormHelperTextProps={{ className: classes.helperText }}
                          helperText={
                            this?.state?.ratesError
                              ? this?.state?.rateErrorMessage
                              : (this?.state?.ratePublishedAt !== null && this?.state?.ratePublishedAt !== "undefined")
                                ? `Published At : ${moment(this?.state?.ratePublishedAt).format("DD/MM/YYYY")}  ( ${editData.rate} )`
                                : editData.rate
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: "90%",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: isMobile && 'column',
                          justifyContent: "space-around",
                          paddingTop: 30,
                          paddingBottom: 30,
                          marginBottom: "50px",
                        }}
                      >
                        <div style={{
                          width: isMobile ? '100%' : "30%",
                          marginTop: isMobile ? '30px' : "0px",
                          marginBottom: isMobile ? '30px' : "0px",
                        }}>
                          <ToolTipComponent
                            title="New rate published"
                            condition={Boolean(this.state?.quantityEditable)}
                          >
                            <TextField
                              type="number"

                              name="quantity"
                              style={{
                                width: '100%',
                              }}
                              id="outlined-name"
                              label="Quantity*"
                              margin="normal"
                              variant="outlined"
                              disabled={this.props.orderStatus !== "PENDING" ||
                                Boolean(this.state?.orderDetails?.conversion_of) ||
                                Boolean(this.state?.quantityEditable)
                              }
                              value={this?.state?.quantity}
                              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                              onWheel={(e) => e.target.blur()}
                              onChange={this.handleChange("quantity")}
                              error={this?.state?.quantityError}
                              FormHelperTextProps={{ className: classes.helperText }}
                              helperText={
                                this?.state?.quantityError
                                  ? "Input valid quantity"
                                  : this.props.orderStatus === "PENDING" && (Boolean(this.state?.orderDetails?.conversion_of) || Boolean(this.state?.quantityEditable))
                                    ? this.state.editHelperText.never
                                    : editData.quantity
                              }
                              InputProps={{
                                pattern: "[0-9]*",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <span style={{ fontSize: "0.9em", color: grey }}>
                                      MT
                                    </span>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </ToolTipComponent>
                        </div>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl3}
                          style={{
                            width: isMobile && '100%',
                            margin: isMobile && '30px 0'
                          }}
                        >
                          <InputLabel id="paymentTermSelectLable">
                            Select Payment Terms*
                          </InputLabel>
                          <Select
                            id="paymentTermSelectLable"
                            value={paymentTerm}
                            disabled={this?.state?.contractOperation === "edit"}
                            onChange={this.handleChange("paymentTerm")}
                            MenuProps={MenuProps}
                            label="Select Payment Terms*"
                          >
                            {(this?.state?.selectedCustomerPaymentTermsAllotted.find(d => d.id == paymentTerm)) == undefined ||
                              (this?.state?.selectedCustomerPaymentTermsNotAllotted.find(d => d.payment_method_name == paymentTerm)) == undefined
                              ? <MenuItem
                                value={this.state?.orderDetails?.payment_method?.id}
                                style={{ display: 'none', color: "black" }}
                                key={this.state?.orderDetails?.payment_method?.id}
                              >
                                {this.state?.orderDetails?.payment_method?.payment_method_name}
                              </MenuItem>
                              : null
                            }
                            <ListSubheader
                              style={{ color: grey }}
                              disableSticky={true}
                            >
                              Allotted
                            </ListSubheader>
                            {this?.state?.selectedCustomerPaymentTermsAllotted.map(
                              (option, index) => (
                                <MenuItem
                                  value={option.id}
                                  style={{ color: "black" }}
                                  key={index}
                                >
                                  {option.payment_method_name + "  (" + option.days + " days)"}
                                </MenuItem>
                              )
                            )}

                            <ListSubheader
                              style={{ color: grey }}
                              disableSticky={true}
                            >
                              Not Allotted
                            </ListSubheader>

                            {this?.state?.selectedCustomerPaymentTermsNotAllotted.map(
                              (option, index) => (
                                <MenuItem
                                  value={option.id}
                                  style={{ color: "black" }}
                                  key={index}
                                >
                                  {option.payment_method_name + "  (" + option.days + " days)"}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          <FormHelperText className={classes.helperText}>
                            {editData.paymentTerm}
                          </FormHelperText>
                        </FormControl>
                        <TextField
                          type="number"
                          name="orderValidity"
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "0px",
                          }}
                          id="outlined-name"
                          label="Contract validity (Days)"
                          margin="normal"
                          variant="outlined"
                          value={this?.state?.orderValidity < 0 ? 0 : this?.state?.orderValidity}
                          placeholder="Enter Number of Days"
                          FormHelperTextProps={{ className: classes.helperText }}
                          helperText={`Contract valid upto : ${moment(
                            this?.state?.orderValidityDate
                          ).format("DD/MM/YYYY")}  ( ${editData.orderValidity} )`}
                          onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                          //onWheel={(e) => e.target.blur()}
                          onChange={this.handleChange("orderValidity")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ fontSize: "0.9em", color: grey }}>
                                  Days
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "90%",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: isMobile && 'column',
                          justifyContent: "space-around",
                        }}
                      >
                        <TextField
                          label="Internal Remark"
                          onChange={this.handleChange("adminRemark")}
                          value={adminRemark}
                          multiline
                          rows="4"
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "0px",
                          }}
                          margin="normal"
                          variant="outlined"
                          helperText={editData.internalRemark}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <>
                          <TextField
                            label="Customer Remark"
                            multiline
                            rows="4"
                            style={{
                              width: isMobile ? '100%' : "30%",
                              marginTop: isMobile ? '30px' : "0px",
                              marginBottom: isMobile ? '30px' : "0px",
                            }}
                            margin="normal"
                            variant="outlined"
                            value={customerRemark}
                            helperText={editData.customerRemark}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={true}
                          />
                        </>
                        <div
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "0px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this?.state?.allowBreakingFifoCheckbox}
                                onChange={this.handleChange(
                                  "allowBreakingFifoCheckbox"
                                )}
                                name="allowBreakingFifoCheckbox"
                                style={{ color: "blue" }}
                              />
                            }
                            label="ALLOW BREAKING FIFO"
                          />
                          <FormHelperText>
                            {editData.allowBreakingFifo}
                          </FormHelperText>
                          {/* <FormControlLabel
                        control={
                          <Checkbox
                            onChange={this.handleChange(
                              "adminApprovalCheckbox"
                            )}
                            checked={this?.state?.adminApprovalCheckbox}
                            name="adminApprovalCheckbox"
                            style={{ color: "blue" }}
                          />
                        }
                        label=" ADMIN APPROVAL"
                      />
                      {console.log(this.state.isCustomerApproved)} */}
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={this.handleChange(
                                  "customerApprovalCheckbox"
                                )}
                                checked={this?.state?.customerApprovalCheckbox}
                                name="customerApprovalCheckbox"
                                style={{ color: "blue" }}
                                disabled={
                                  this.state.isCustomerApproved !== null ||
                                  ["APPROVED", "ONGOING"].includes(this.props?.orderStatus)
                                }
                              />
                            }
                            label=" CUSTOMER APPROVAL"
                          />
                          <FormHelperText>
                            {editData.customerApproval}
                          </FormHelperText>
                          {!this?.state?.selectedCustomerData
                            ?.is_approval_needed ? null : this?.state
                              ?.selectedCustomerData?.is_approval_needed ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ color: "blue" }}
                                  //   onChangeCapture={
                                  //     this?.state?.isClientApproved
                                  //       ? null
                                  //       : handleConfirmationDialogOpen
                                  //   }
                                  checked={
                                    this?.state?.isClientApproved ? true : false
                                  }
                                  name="isClientApproved"
                                  disabled={this?.state?.isClientApproved}
                                />
                              }
                              label="CLIENT APPROVAL"
                            />
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          width: '90%',
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-around",
                          marginBottom: "30px",
                          marginTop: "30px"
                        }}
                      >
                        {this?.state?.selectedCustomerData?.customer_type === "DISTRIBUTOR" ||
                          this?.state?.selectedCustomerData?.customer_type === "BROKER" ?
                          <div
                            style={{
                              width: isMobile ? '100%' : "30%",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div style={{ width: '100%' }}>
                              <TextField
                                name="brokerage"
                                style={{ width: "100%" }}
                                label="Brokerage"
                                margin="normal"
                                variant="outlined"
                                placeholder="Enter Brokerage Amount"
                                value={newBrokerage}
                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                onWheel={(e) => e.target.blur()}
                                onChange={this.handleChange("newBrokerage")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <span style={{ fontSize: "0.9em", color: grey }}>
                                        ₹
                                      </span>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ cursor: "pointer" }}
                                      disabled={editData.brokerage == editHelperText.never}
                                      onClick={() => {
                                        if (editData.brokerage !== editHelperText.never)
                                          this.handleUnlockBrokerage();
                                      }}
                                    >
                                      {this.state.updateBrokerage ? <UnLock /> : <Lock />}
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={
                                  //!this?.state?.selectedBroker?.brokerage ? false : true
                                  !this.state.updateBrokerage
                                }
                                error={this?.state?.brokerageError}
                                helperText={
                                  this?.state?.brokerageError
                                    ? this.state?.brokerageError
                                    : editData.brokerage
                                }
                              />
                            </div>
                          </div>
                          : null}
                        <TextField
                          type="number"
                          name="specialDiscount"
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "9px",
                          }}
                          label="Special Discount"
                          margin="normal"
                          variant="outlined"
                          disabled={this.props.orderStatus !== "PENDING"}
                          placeholder="Enter Additional Discount Amount"
                          value={this?.state?.specialDiscount}
                          onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                          onChange={this.handleChange("specialDiscount")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span style={{ fontSize: "0.9em", color: grey }}>
                                  ₹
                                </span>
                              </InputAdornment>
                            ),
                          }}
                          error={this?.state?.specialDiscountError}
                          FormHelperTextProps={{ className: classes.helperText }}
                          helperText={
                            this?.state?.specialDiscountError
                              ? "Input valid discount amount"
                              : editData.specialDiscount
                          }
                        />
                        {!this.state?.disableUserToInputRate
                          ? <TextField
                            type="number"
                            name="specialPremium"
                            style={{
                              width: isMobile ? '100%' : "30%",
                              marginTop: isMobile ? '30px' : "0px",
                              marginBottom: isMobile ? '30px' : "9px",
                            }}
                            label="Special Premium"
                            margin="normal"
                            variant="outlined"
                            disabled={this.props.orderStatus !== "PENDING"}
                            placeholder="Enter Additional Discount Amount"
                            value={this?.state?.specialPremium}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onChange={this.handleChange("specialPremium")}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span style={{ fontSize: "0.9em", color: grey }}>
                                    ₹
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                            error={this?.state?.specialPremiumError}
                            FormHelperTextProps={{ className: classes.helperText }}
                            helperText={
                              this?.state?.specialPremiumError
                                ? "Input valid discount amount"
                                : editData.specialPremium
                            }
                          />
                          :
                          <Autocomplete
                            autoSelect
                            style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0, marginBottom: 0 }}
                            name="freight_basis"
                            options={this.props.freightBasis.map((opt) => String(opt.name))}
                            onInputChange={(e, value) => this.handleAutoCompleteSearch(e, value, this.props.freightBasis, 'freight_basis', 'name')}
                            value={this.state?.freight_basis
                              ? (this.props?.freightBasis?.find(t => t.id === this.state?.freight_basis))?.name
                              : ""}
                            ListboxProps={{ style: { maxHeight: '100px', } }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={'Freight Basis*'}
                                margin="normal"
                                variant="outlined"
                                style={{ width: isMobile && '100%', marginBottom: 0 }}
                                helperText={editData.freightBasis}
                              />
                            )}
                          />
                        }
                        <div style={{
                          display: (isMobile || this?.state?.selectedCustomerData?.customer_type === "DISTRIBUTOR" ||
                            this?.state?.selectedCustomerData?.customer_type === "BROKER") && 'none', width: "30%",
                        }}></div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: isMobile && 'column',
                          justifyContent: "space-around",
                          marginBottom: "30px",
                          marginTop: "35px"
                        }}
                      >
                        {/* <FormControl variant="outlined" className={classes.width45}>
                      <InputLabel id="tcsRate">Select TCS Rate</InputLabel>
                      <Select
                        id="tcsRate"
                        value={tcsRate}
                        onChange={this.handleChange("tcsRate")}
                        MenuProps={MenuProps}
                        label="Select TCS Rate"
                      >
                        {this?.state?.allTcsRates &&
                          this?.state?.allTcsRates.length > 0
                          ? this?.state?.allTcsRates.map((option, index) => (
                            <MenuItem
                              value={option.id}
                              style={{ color: "black" }}
                              key={index}
                            >
                              {option.tcs_percent}
                            </MenuItem>
                          ))
                          : null}
                        {/* {this?.state?.allTcsRates.map((option, index) => (
                          <MenuItem
                            value={option}
                            style={{ color: "black" }}
                            key={index}
                          >
                            {option.tcs_percent}
                          </MenuItem>
                        ))} */}
                        {/* </Select>
                    </FormControl> */}

                        <TextField
                          type="number"
                          name="freightChargeRate"
                          //className={classes.width45}
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "0px",
                          }}
                          label="Freight Charge Rate"
                          margin="normal"
                          variant="outlined"
                          placeholder="Enter Freight Charge Rate"
                          value={this?.state?.freightChargeRate}
                          onChange={this.handleChange("freightChargeRate")}
                          onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                          onWheel={(e) => e.target.blur()}
                          helperText={editData.freightChargeRate}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <InputAdornment position="start">
                                <span style={{ fontSize: "0.9em", color: grey }}>
                                  Per MT
                                </span>
                              </InputAdornment>
                            ),
                          }}
                          onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                        />
                        <TextField
                          type="number"
                          name="loadingPremium"
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "0px",
                          }}
                          disabled={this?.state?.disableloadingPremium}
                          value={this?.state?.loadingPremium == 0 ? this?.state?.loadingPremium : Number(this?.state?.loadingPremium)}
                          id="outlined-name"
                          label="Loading Premium"
                          margin="normal"
                          variant="outlined"
                          onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                          onChange={this.handleChange("loadingPremium")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span style={{ fontSize: "0.9em", color: grey }}>₹</span>
                              </InputAdornment>
                            ),
                          }}
                          error={this?.state?.loadingPremiumError}
                          // FormHelperTextProps={{ className: classes.helperText }}
                          helperText={
                            this?.state?.loadingPremiumError
                              ? this?.state?.loadingPremiumErrorMsg
                              : this?.state?.disableloadingPremium
                                ? editData.loadingPremium
                                : this.props?.orderStatus === 'PENDING'
                                  ? this.state.editHelperText.allow
                                  : this.state?.editHelperText.approval_change
                            // : this?.state?.disableloadingPremium ? "Not editable" : "Editable without approval"
                          }
                        />

                        <TextField
                          type="number"
                          name="insurancePremium"
                          style={{
                            width: isMobile ? '100%' : "30%",
                            marginTop: isMobile ? '30px' : "0px",
                            marginBottom: isMobile ? '30px' : "0px",
                          }}
                          disabled={this?.state?.disableInsurancePremium}
                          value={this?.state?.insurancePremium == 0 ? this?.state?.insurancePremium : Number(this?.state?.insurancePremium)}
                          id="outlined-name"
                          label="Insurance Premium"
                          margin="normal"
                          variant="outlined"
                          onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                          onChange={this.handleChange("insurancePremium")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span style={{ fontSize: "0.9em", color: grey }}>₹</span>
                              </InputAdornment>
                            ),
                          }}
                          error={this?.state?.insurancePremiumError}
                          // FormHelperTextProps={{ className: classes.helperText }}
                          helperText={
                            this?.state?.insurancePremiumError
                              ? this?.state?.insurancePremiumErrorMsg
                              : this?.state?.disableInsurancePremium
                                ? editData.insurancePremium
                                : this.props?.orderStatus === 'PENDING'
                                  ? this.state.editHelperText.allow
                                  : this.state?.editHelperText.approval_change
                            // : this?.state?.disableInsurancePremium ? "Not editable" : "Editable without approval"
                          }
                        />
                        {/* {console.log((this.props?.users.find(d => d.fullName == this.state.salesPersonShow))?.fullName)} */}

                        {/* <div className={classes.width45}></div> */}
                      </div>
                      <div className={classes.width45}></div>

                      <div
                        style={{
                          width: "90%",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: isMobile && 'column',
                          justifyContent: "space-around",
                          marginBottom: "30px",
                        }}
                      >
                        <Autocomplete
                          // autoSelect
                          style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0 }}
                          options={this.props?.users.map((opt) => String(opt.fullName))}
                          // defaultValue={String(this.state.salesPersonShow)}
                          value={this.state?.salesPerson ? String(this.props?.users.find(d => (Number(d.id) == Number(this.state.salesPerson)))?.fullName) : ""}
                          onChange={(e, value) => this.handleSearchSalesPerson(e, value, this.props.users)}
                          // getOptionLabel={(opt) => opt.fullName}
                          ListboxProps={{ style: { maxHeight: '100px', } }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Sales Person*'}
                              margin="normal"
                              variant="outlined"
                              helperText={editData?.salesPerson}
                              style={{ width: isMobile && '100%' }}
                            />
                          )}
                        />

                        <TextField
                          type="text"
                          name="partyRefNo"
                          style={{ width: isMobile ? '100%' : '30%', marginBottom: "15px", marginTop: isMobile && '30px' }}
                          label="Party Ref Number"
                          // margin="normal"
                          variant="outlined"
                          placeholder="Enter Party Ref Number"
                          value={partyRefNo}
                          onChange={this.handleChange("partyRefNo")}
                          FormHelperTextProps={{ className: classes.helperText }}
                          helperText={editData.partyRefNo}
                          onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                              event.preventDefault();
                            }
                          }}
                        />

                        <Fragment>
                          <ThemeProvider theme={materialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                style={{ width: isMobile ? '100%' : '30%', marginBottom: "15px", marginTop: isMobile && '30px' }}
                                inputVariant="outlined"
                                label="Party Ref Date* "
                                format="dd/MM/yyyy"
                                value={partyRefDate}
                                onChange={this.handleDateChange}
                                placeholder="e.g.: DD/MM/YYYY"
                                InputAdornmentProps={{ position: "start" }}
                                helperText={editData.partyRefDate}
                                FormHelperTextProps={{ className: classes.dateHelperText }}
                                InputProps={{ className: classes.datePicker, readOnly: true }}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </Fragment>
                      </div>
                      {!this.state?.disableUserToInputRate &&
                        <div
                          style={{
                            width: "90%",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: isMobile && 'column',
                            justifyContent: "space-around",
                            marginBottom: "30px",
                          }}
                        >
                          <Autocomplete
                            autoSelect
                            style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0, marginBottom: 0 }}
                            name="freight_basis"
                            options={this.props.freightBasis.map((opt) => String(opt.name))}
                            onInputChange={(e, value) => this.handleAutoCompleteSearch(e, value, this.props.freightBasis, 'freight_basis', 'name')}
                            value={this.state?.freight_basis
                              ? (this.props?.freightBasis?.find(t => t.id === this.state?.freight_basis))?.name
                              : ""}
                            ListboxProps={{ style: { maxHeight: '100px', } }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={'Freight Basis*'}
                                margin="normal"
                                variant="outlined"
                                style={{ width: isMobile && '100%', marginBottom: 0 }}
                                helperText={editData.freightBasis}
                              />
                            )}
                          />
                          <div style={{ width: '63%' }}></div>
                        </div>
                      }
                    </div>
                  ) : null}
                </>)}
            </Paper>


            {this?.state?.showCustomerDetail ? (
              <div className={classes.displayFlex}>
                {console.log(
                  this.state?.paymentTerm,
                  this.state?.partyRefDate,
                  this.state?.salesPerson
                )}
                <Button
                  disabled={
                    (
                      this.state?.partyRefDate === null ||
                      this.state?.partyRefDate === "" ||
                      this.state?.paymentTerm === null ||
                      this.state?.paymentTerm === "" ||
                      this.state?.salesPerson === null ||
                      this.state?.salesPerson === "" ||
                      this.state?.freight_basis === null ||
                      this.state?.freight_basis === ""
                    )
                    ||
                    this.checkDataValidityForSaveAndTabChange(
                      tabValue, this.state.ratesError, this.state.loadingPremiumError, this.state.insurancePremiumError, this.state.quantityError
                    )
                  }
                  size="medium"
                  className={classes.saveButton}
                  variant="contained"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={() => {
                    if (!this.state.finishBtnClicked) {
                      console.log("clicked FINISH 2")
                      this.setState({ finishBtnClicked: true })
                      this.handleUpdateOrder();
                    }
                  }}
                >
                  {console.log(this.props.orderCreatedBy)}
                  {
                    this.props.orderCreatedBy?.includes("Customer")
                      ? "APPROVE"
                      : "UPDATE"
                  }
                  {/* UPDATE */}
                </Button>
                <Button
                  size="medium"
                  className={classes.cancelButton}
                  onClick={(e) => {
                    e.preventDefault();
                    // this.props.history.push(INTERNALROUTES.ORDERS);
                    this.props.history.push(
                      `/internalContracts/singleContracts/${this?.state?.currentOrderId}`
                    );
                  }}
                >
                  CANCEL
                </Button>
              </div>
            ) : (
              false
            )}
          </div>
          <SnackbarComponent
            open={openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={snackbarMessage}
            type={snackbarType}
          />
        </div>
      </Layout>
    );
  }
}

export default withRouter(withStyles(styles)(EditContracts));
