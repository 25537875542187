import React, { useState, useEffect } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
  TextField,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import COLORS from "../../../../constants/internal/colors";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import Spinner from "../../common/Spinner";
import { pxToEm } from "../../../../methods";
import InputAdornment from "@material-ui/core/InputAdornment";
import InfoIcon from "@material-ui/icons/Info";

function ItemCategoryEditComponent(props) {
  const {
    RateTemplate,
    classes,
    FetchingRateTemplate,
    FetchingItemType,
    RateLinking,
    ItemType,
  } = props;

  const [CategoryNameState, setCategoryNameState] = useState(
    RateTemplate?.category_name
  );

  useEffect(() => {
    setCategoryNameState(RateTemplate?.category_name);
  }, [RateTemplate?.category_name]);

  const [TableToEdit, setTableToEdit] = useState("");
  const [RateLinkinAddForm, setRateLinkinAddForm] = useState({
    related_to: "",
    rate_difference: "",
  });

  const helperClearAllAddInputs = () => {};

  const handleAddItemType = () => {
    helperClearAllAddInputs();
  };

  useEffect(() => {
    const table =
      props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 2
      ];
    if (table && table.length > 0) {
      if (
        [
          "rateTemplateTable",
          "ItemTypeTable",
          "RateLinkingTable",
          "categoryName",
        ].indexOf(table) > -1
      ) {
        setTableToEdit(table);
        helperClearAllAddInputs();
      }
    }
  }, [props]);

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        {FetchingRateTemplate || FetchingItemType ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Bread
              data={[
                {
                  title: "Master Management",
                  onClick: () =>
                    props.history.push(`${INTERNALROUTES.MASTERSMANAGMENT}`),
                },
                {
                  title: "Item Categories",
                  onClick: () => props.history.goBack(),
                },
                {
                  title: RateTemplate?.category_name || "",
                  onClick: () => props.history.goBack(),
                },
              ]}
            />
            <div
              style={{
                marginTop: 50,
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <span className={classes.tableHeaderCell}>Name</span>
              {TableToEdit === "categoryName" ? (
                <React.Fragment>
                  <TextField
                    className={classes.formControl}
                    style={{ marginLeft: 50 }}
                    label="Category Name"
                    autoComplete="off"
                    type="text"
                    variant="outlined"
                    error={false}
                    size="small"
                    value={CategoryNameState || ""}
                    onChange={(e) => setCategoryNameState(e.target.value)}
                    name="catgoryName"
                    // onChange={(e) => props.changeCategoryName(RateTemplate.id, CategoryNameState)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    style={{ marginLeft: 50 }}
                    onClick={() =>
                      props.changeCategoryName(
                        RateTemplate.id,
                        CategoryNameState,
                        RateTemplate.related_to,
                        RateTemplate.rate_difference
                      )
                    }
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    onClick={() => props.history.goBack()}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              ) : (
                <span style={{ marginLeft: 50 }}>
                  {RateTemplate?.category_name || ""}
                </span>
              )}
            </div>

            <div style={{ marginTop: 50 }}>
              <span className={classes.tableHeaderCell}>
                RATE TEMPLATE TABLE
              </span>
            </div>
            {TableToEdit === "rateTemplateTable" ? (
              <React.Fragment>
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                >
                  <Table className={classes.table} aria-label="simple table">
                    <THead
                      heading={[
                        "STATE",
                        "HR DIFFERENCE",
                        "LR DIFFERENCE",
                        "  ",
                      ]}
                    />
                    <TableBody>
                      {RateTemplate &&
                        RateTemplate.daily_rates_template.map((d, index) => (
                          <TableRow
                            hover
                            key={`s${d.id}`}
                            className={classes.bodyTableRow}
                          >
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title={`${d.is_base_rate ? "Base Rate" : ""}`}
                                >
                                  <StarBorderIcon
                                    onClick={() =>
                                      props.handleRateTemplateTableEditStar(
                                        d.id,
                                        index,
                                        d.is_base_rate
                                      )
                                    }
                                    className={`${classes.IconStyle} ${
                                      d.is_base_rate ? classes.fullOpacity : ""
                                    }`}
                                  />
                                </Tooltip>
                                {d.state?.state_name}
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              {d.is_base_rate ? (
                                "BASE RATE"
                              ) : (
                                <TextField
                                  className={classes.formControl}
                                  label="HR DIFFERENCE"
                                  autoComplete="off"
                                  type="number"
                                  variant="outlined"
                                  error={
                                    props.RateTemplateEditError[index]
                                      ?.high_rate_difference
                                      ? true
                                      : false
                                  }
                                  size="small"
                                  value={d.high_rate_difference}
                                  name="high_rate_difference"
                                  onChange={(e) =>
                                    props.handleRateTemplateTableEdit(
                                      d.id,
                                      index,
                                      "high_rate_difference",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                className={classes.formControl}
                                label="LR DIFFERENCE"
                                autoComplete="off"
                                variant="outlined"
                                size="small"
                                type="number"
                                id="input-with-icon-textfield"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <Tooltip title="The value must be negative or zero">
                                        <InfoIcon
                                          style={{
                                            color: "rgb(188, 188, 188)",
                                          }}
                                        />
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  props.RateTemplateEditError[index]
                                    ?.low_rate_difference
                                    ? true
                                    : false
                                }
                                defaultValue={d.low_rate_difference}
                                name="low_rate_difference"
                                onChange={(e) =>
                                  props.handleRateTemplateTableEdit(
                                    d.id,
                                    index,
                                    "low_rate_difference",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                onClick={() =>
                                  props.deleteRateTemplateRow(d.id)
                                }
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={props.handleSaveRateTemplate}
                  >
                    Save
                  </Button>
                  <Button onClick={() => props.history.goBack()}>Cancel</Button>
                </div>
              </React.Fragment>
            ) : (
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <THead
                    heading={["STATE", "HR DIFFERENCE", "LR DIFFERENCE"]}
                  />
                  <TableBody>
                    {RateTemplate &&
                      RateTemplate.daily_rates_template.map((d, index) => (
                        <TableRow
                          hover
                          key={`s${d.id}`}
                          className={classes.bodyTableRow}
                        >
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip
                                title={`${d.is_base_rate ? "Base Rate" : ""}`}
                              >
                                <StarBorderIcon
                                  className={`${classes.IconStyle} ${
                                    d.is_base_rate ? classes.fullOpacity : ""
                                  }`}
                                />
                              </Tooltip>
                              {d.state?.state_name}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            {d.is_base_rate
                              ? "BASE RATE"
                              : d.high_rate_difference}
                          </TableCell>
                          <TableCell align="left">
                            {d.low_rate_difference}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <div style={{ marginTop: 50 }}>
              <span className={classes.tableHeaderCell}>
                ITEMS UNDER {RateTemplate?.category_name || ""}
              </span>
            </div>
            {TableToEdit === "ItemTypeTable" ? (
              <React.Fragment>
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                >
                  <Table className={classes.table} aria-label="simple table">
                    <THead
                      heading={[
                        "ITEM NAME",
                        "RATE DIFFERENCE",
                        "SECTION NAME",
                        "SECTION WEIGHT",
                        "  ",
                      ]}
                    />
                    <TableBody>
                      {ItemType &&
                        ItemType.map((d, index) => (
                          <TableRow
                            hover
                            key={`s${d.id}`}
                            className={classes.bodyTableRow}
                          >
                            <TableCell scope="row">
                              <TextField
                                className={classes.formControl}
                                label="Item Name"
                                autoComplete="off"
                                type="text"
                                variant="outlined"
                                error={
                                  props.ItemTypeEditError[index]?.item_name
                                    ? true
                                    : false
                                }
                                size="small"
                                value={d.item_name}
                                name="item_name"
                                onChange={(e) =>
                                  props.handleItemTypeTableEdit(
                                    d.id,
                                    index,
                                    "item_name",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                className={classes.formControl}
                                label="Rate Difference"
                                autoComplete="off"
                                type="number"
                                variant="outlined"
                                error={
                                  props.ItemTypeEditError[index]
                                    ?.rate_difference
                                    ? true
                                    : false
                                }
                                size="small"
                                value={d.rate_difference}
                                name="rate_difference"
                                onChange={(e) =>
                                  props.handleItemTypeTableEdit(
                                    d.id,
                                    index,
                                    "rate_difference",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                className={classes.formControl}
                                label="Section Name"
                                autoComplete="off"
                                type="text"
                                variant="outlined"
                                error={
                                  props.ItemTypeEditError[index]?.section_name
                                    ? true
                                    : false
                                }
                                size="small"
                                value={d.section_name}
                                name="section_name"
                                onChange={(e) =>
                                  props.handleItemTypeTableEdit(
                                    d.id,
                                    index,
                                    "section_name",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                className={classes.formControl}
                                label="Section Weight"
                                autoComplete="off"
                                type="number"
                                variant="outlined"
                                error={
                                  props.ItemTypeEditError[index]?.section_weight
                                    ? true
                                    : false
                                }
                                size="small"
                                value={d.section_weight}
                                name="section_weight"
                                onChange={(e) =>
                                  props.handleItemTypeTableEdit(
                                    d.id,
                                    index,
                                    "section_weight",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                onClick={() =>
                                  props.handleDeleteItemTypeRow(d.id)
                                }
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={props.handleSaveItemType}
                  >
                    Save
                  </Button>
                  <Button onClick={() => props.history.goBack()}>Cancel</Button>
                </div>
              </React.Fragment>
            ) : (
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <THead
                    heading={[
                      "ITEM NAME",
                      "RATE DIFFERENCE",
                      "SECTION NAME",
                      "SECTION WEIGHT",
                    ]}
                  />
                  <TableBody>
                    {ItemType &&
                      ItemType.map((d, index) => (
                        <TableRow
                          hover
                          key={`s${d.id}`}
                          className={classes.bodyTableRow}
                        >
                          <TableCell scope="row">{d.item_name || ""}</TableCell>
                          <TableCell align="left">
                            ₹ {d.rate_difference}
                          </TableCell>
                          <TableCell align="left">{d.section_name}</TableCell>
                          <TableCell align="left">
                            {d.section_weight} MT
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <div style={{ marginTop: 50 }}>
              <span className={classes.tableHeaderCell}>
                RATE LINKING {RateTemplate?.category_name || ""}
              </span>
            </div>
            {TableToEdit === "RateLinkingTable" ? (
              <React.Fragment>
                <div
                  style={{ marginTop: 15, marginBottom: 30, marginRight: 30 }}
                >
                  <div>
                    {`If you link ${RateTemplate.category_name} to a parent category, its daily rates can be derived from the parent category's Base Rate.`}
                  </div>
                  <div>
                    {`This does not allows auto publish rates, user wil still have to manually approve and publish daily rates.`}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 15,
                  }}
                >
                  <span>Category to link &nbsp;&nbsp;</span>
                  <Autocomplete
                    defaultValue={props.AllCategories.find(
                      (d) => d.id === RateTemplate.related_to
                    )}
                    id="ItemCategory"
                    size="small"
                    style={{ minWidth: 200, maxWidth: 300 }}
                    options={props.AllCategories}
                    onChange={(event, newValue) =>
                      props.handleRateLinkingEdit("related_to", newValue?.id)
                    }
                    getOptionLabel={(option) => option.category_name}
                    renderInput={(params) => (
                      <TextField
                        className={classes.formControl}
                        {...params}
                        label="Item Category"
                        variant="outlined"
                        error={props.RateLinkingError.related_to ? true : false}
                      />
                    )}
                  />
                  <span style={{ marginLeft: 50 }}>
                    Rate Difference &nbsp;&nbsp;
                  </span>
                  <TextField
                    className={classes.formControl}
                    label="Rate Difference"
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    name="rate_difference"
                    value={RateTemplate.rate_difference}
                    error={
                      props.RateLinkingError.rate_difference ? true : false
                    }
                    onChange={(e) =>
                      props.handleRateLinkingEdit(
                        "rate_difference",
                        e.target.value
                      )
                    }
                  />
                  <IconButton
                    onClick={() => props.deleteRateLinking(RateTemplate.id)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                <div style={{ marginTop: 15 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={() => props.putRateLinkingData(RateTemplate.id)}
                  >
                    Save
                  </Button>
                  <Button onClick={() => props.history.goBack()}>Cancel</Button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {RateLinking ? (
                  <div style={{ marginTop: 5 }}>
                    <span className={classes.tableHeaderCell}>LINKED WITH</span>
                    <span style={{ marginLeft: 50 }}>{RateLinking.name}</span>
                    <span
                      style={{ marginLeft: 50 }}
                      className={classes.tableHeaderCell}
                    >
                      RATE DIFFERENCE
                    </span>
                    <span style={{ marginLeft: 50 }}>
                      ₹ {RateTemplate.rate_difference}
                    </span>
                  </div>
                ) : (
                  "No rates linked"
                )}
              </React.Fragment>
            )}

            <div style={{ height: 50 }}></div>
          </React.Fragment>
        )}
      </div>
    </Layout>
  );
}

const styles = (theme) => ({
  displayData: {
    marginTop: 10,
  },
  mainDiv: { width: "100%", height: "100%" },
  tableContainer: {
    marginTop: 5,
  },
  tableHeader: {
    backgroundColor: COLORS.whiteSmoke,
  },
  tableHeaderCell: {
    color: COLORS.tableHeaderText,
    fontWeight: "bold",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: COLORS.grey,
  },
  rightItemFix: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    float: "right",
  },
  bodyTableRow: {
    "&:hover": {
      backgroundColor: COLORS.tableHoverColor + " !important",
      cursor: "pointer",
    },
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: COLORS.whiteSmoke,
    overflow: "hidden",
    color: COLORS.grey,
    "&:hover": {
      backgroundColor: COLORS.whiteSmoke,
      width: 70,
      transition: "width 0.3s",
      cursor: "pointer",
    },
  },
  addButton: {
    float: "right",
    marginBottom: 30,
  },
  IconStyle: {
    color: COLORS.secondaryGrey,
    width: 20,
    height: 20,
    opacity: 0.2,
    marginRight: 2,
  },
  fullOpacity: {
    opacity: 1,
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${COLORS.secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: COLORS.grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  formControl: {
    height: 40,
  },
  saveButton: {
    backgroundColor: COLORS.successButtonBackground,
    color: COLORS.drawerTextColor,
    marginRight: 10,
    "&:hover": {
      backgroundColor: COLORS.successButtonBackgroundHover,
    },
  },
});

const RenderHeader = ({ heading = [], classes }) => (
  <TableHead>
    <TableRow className={classes.tableHeader}>
      {heading.map((h, hi) => (
        <TableCell
          key={`${hi + h}`}
          align="left"
          className={classes.tableHeaderCell}
        >
          {h}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
const THead = withStyles(styles)(RenderHeader);
export default withStyles(styles)(ItemCategoryEditComponent);
