import React, { useState } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import {
  Button,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  withStyles,
} from "@material-ui/core";
import COLORS from "../../../../constants/internal/colors";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { pxToEm } from "../../../../methods";

function ItemCategoryDetailsComponent(props) {
  const { RateTemplate, classes } = props;

  const [CategoryName, setCategoryName] = useState("");

  const handleSave = () => {
    if (CategoryName.length >= 0) {
      let data = {
        category_name: CategoryName,
        related_to: null,
        rate_difference: 0,
      };
      props.createNewCategory(data);
    }
  };

  const handleCancel = () => {
    props.history.goBack();
  };

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <React.Fragment>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(`${INTERNALROUTES.MASTERSMANAGMENT}`),
              },
              {
                title: "Item Categories",
                onClick: () => props.history.goBack(),
              },
              {
                title: "Create New",
                onClick: () => props.history.goBack(),
              },
            ]}
          />
          <div style={{ marginTop: 50, display: "flex", alignItems: "center" }}>
            <span className={classes.tableHeaderCell}>Category Name</span>
            <span style={{ marginLeft: 50 }}>
              <TextField
                className={classes.formControl}
                label="Category Name"
                autoComplete="off"
                variant="outlined"
                value={CategoryName}
                size="small"
                name="rate_difference"
                error={false}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </span>
          </div>
          <div style={{ marginTop: 25 }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>

          <div style={{ height: 50 }}></div>
        </React.Fragment>
      </div>
    </Layout>
  );
}

const styles = (theme) => ({
  displayData: {
    marginTop: 10,
  },
  mainDiv: { width: "100%", height: "100%" },
  tableContainer: {
    marginTop: 5,
  },
  tableHeader: {
    backgroundColor: COLORS.whiteSmoke,
  },
  tableHeaderCell: {
    color: COLORS.tableHeaderText,
    fontWeight: "bold",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: COLORS.grey,
  },
  rightItemFix: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    float: "right",
  },
  bodyTableRow: {
    "&:hover": {
      backgroundColor: COLORS.tableHoverColor + " !important",
      cursor: "pointer",
    },
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: COLORS.whiteSmoke,
    overflow: "hidden",
    color: COLORS.grey,
    "&:hover": {
      backgroundColor: COLORS.whiteSmoke,
      width: 70,
      transition: "width 0.3s",
      cursor: "pointer",
    },
  },
  addButton: {
    float: "right",
    marginBottom: 30,
  },
  IconStyle: {
    color: COLORS.secondaryGrey,
    width: 20,
    height: 20,
    opacity: 0.2,
    marginRight: 2,
  },
  fullOpacity: {
    opacity: 1,
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${COLORS.secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: COLORS.grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveButton: {
    backgroundColor: COLORS.successButtonBackground,
    color: COLORS.drawerTextColor,
    marginRight: 10,
    "&:hover": {
      backgroundColor: COLORS.successButtonBackgroundHover,
    },
  },
});

const RenderHeader = ({ heading = [], classes }) => (
  <TableHead>
    <TableRow className={classes.tableHeader}>
      {heading.map((h, hi) => (
        <TableCell
          key={`${hi + h}`}
          align="left"
          className={classes.tableHeaderCell}
        >
          {h}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
const THead = withStyles(styles)(RenderHeader);
export default withStyles(styles)(ItemCategoryDetailsComponent);
