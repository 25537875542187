import React, { Component } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import {
  secondaryBlueText,
  grey,
  darkGrey,
  successButtonBackground,
  orange,
  red,
} from "../../../constants/internal/colors";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
// import CheckCircle from "@material-ui/icons/CheckCircle";
// import Info from "@material-ui/icons/Info";
// import moment from "moment";

const styles = {
  fourElementContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "10vh",
    alignItems: "center",
  },
  fourElementChild: {
    width: "25%",
    display: "flex",
    alignItems: "center",
  },
  fourElementContainer75: {
    display: "flex",
    justifyContent: "space-between",
    height: "10vh",
    alignItems: "center",
    width: "75%",
  },
  fourElementChild33: {
    width: "33%",
    display: "flex",
    alignItems: "center",
  },
  twoDivSeparationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleTextStyle: {
    color: secondaryBlueText,
    fontWeight: "bold",
  },
  iconStyleCheckCircle: {
    color: successButtonBackground,
    height: "0.9em",
    width: "0.9em",
  },
  leftKeyStyle: {
    color: grey,
    fontWeight: "bold",
    margin: "4px 0px",
  },
  addressStyle: {
    color: darkGrey,
    width: "77%",
    // display: "flex",
    textAlign: "end",
  },
  fourElementChildLeftKeyStyle: {
    color: grey,
    fontWeight: "bold",
    margin: "4px 0px",
  },
};

class EinvoiceData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setSupplyCode: "",
      setDocumentType: "",
      whetherService: "",

      supplyCode: [
        { name: "B2B" },
        { name: "B2C" },
        { name: "SEZWP" },
        { name: "SEZWOP" },
        { name: "EXPWP" },
        { name: "EXPWOP" },
        { name: "DEXP" },
        { name: "ISD" },
        { name: "BOS" },
        { name: "DC" },
      ],
      documentType: [
        { name: "Regular" },
        { name: "CreditNote" },
        { name: "DebitNote" },
      ],
      service: [{ name: "Y" }, { name: "N" }],
      tranSupType: "",
    };
  }
  componentDidMount() {
    this.props.handleActualDistance('');
    this.setState({
      tranSupType: this.props.plantItemDetails[0]?.tran_sup_type,
    });
  }
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  // handleChange = (event) => {
  //   this.setState({ value: event.target.value });
  // };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    console.log(this.state.tranSupType);
    const { classes, plantItemDetails, actualDistance, handleActualDistance } = this.props;
    const { supplyCode, documentType, service } = this.state;
    return plantItemDetails?.length > 0 ? (
      <div>
        <p className={classes.titleTextStyle}>E-Invoicing Data</p>
        <div className={classes.fourElementContainer}>
          <div className={classes.fourElementChild}>
            <Typography className={classes.fourElementChildLeftKeyStyle}>
              SUPPLY CODE
            </Typography>
            <Typography style={{ marginLeft: "20px" }}>
              {plantItemDetails[0]?.tran_sup_type}
            </Typography>
            {/* <FormControl style={{ width: "80%" }}>
              <InputLabel htmlFor="age-simple">SUPPLY CODE</InputLabel>
              <Select
                value={this.state.tranSupType}
                renderValue={(value) => this.state.tranSupType}
                onChange={this.handleChange}
                inputProps={{
                  name: "tranSupType",
                  id: "age-simple",
                }}
              >
                {supplyCode.map((data) => (
                  <MenuItem
                    value={data.name}
                    key={data.name}
                    
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>

          {/* <div className={classes.fourElementChild}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel htmlFor="age-simple">DOCUMENT TYPE</InputLabel>
              <Select
                value={this.state.setDocumentType}
                onChange={this.handleTextChange}
                inputProps={{
                  name: "setDocumentType",
                  id: "age-simple",
                }}
              >
                {documentType.map((data) => (
                  <MenuItem
                    key={data.name}
                    value={data.name}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
          {/* <div className={classes.fourElementChild}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel htmlFor="age-simple">SERVICE ?</InputLabel>
              <Select
                value={this.state.whetherService}
                onChange={this.handleTextChange}
                inputProps={{
                  name: "whetherService",
                  id: "age-simple",
                }}
              >
                {service.map((data) => (
                  <MenuItem
                    key={data.name}
                    value={data.name}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
          <div className={classes.fourElementChild}>
            <span className={classes.fourElementChildLeftKeyStyle}>
              INVOICE NUMBER{" "}
            </span>
            <span style={{ marginLeft: "20px" }}>
              {plantItemDetails.length > 0 && plantItemDetails[0]?.DOCNO}
            </span>
          </div>
          <div className={classes.fourElementChild}>
            <span className={classes.fourElementChildLeftKeyStyle}>
              PLACE OF SUPPLY CODE
            </span>
            <span style={{ marginLeft: "20px" }}>
              {plantItemDetails.length > 0 &&
                plantItemDetails[0]?.SUPGSTIN?.substring(0, 2)}
            </span>
          </div>
          <div className={classes.fourElementChild}>
            <span className={classes.fourElementChildLeftKeyStyle}>
              INVOICE DATE
            </span>
            <span style={{ marginLeft: "20px" }}>
              {plantItemDetails.length > 0 &&
                moment(plantItemDetails[0]?.DOCDATE, "DD/MM/YYYY").format(
                  "DD-MMM-YYYY"
                )}
            </span>
          </div>
        </div>

        <div className={classes.twoDivSeparationContainer}>
          <div style={{ width: "45%", padding: "24px 0px" }}>
            <div className={classes.twoDivSeparationContainer}>
              <p className={classes.titleTextStyle}>BUYER DETAIL</p>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}> NAME</span>
              <span className={classes.addressStyle}>
                {plantItemDetails[0].RECNAME}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span
                style={{
                  color:
                    plantItemDetails.length > 0 &&
                      (plantItemDetails[0]?.bill_to_gstin?.length === 15 ||
                        plantItemDetails[0]?.bill_to_gstin?.length === 3)
                      ? grey
                      : orange,
                  fontWeight: "bold",
                }}
              >
                GSTIN
              </span>
              <span
                style={{
                  color:
                    plantItemDetails.length > 0 &&
                      (plantItemDetails[0]?.bill_to_gstin?.length === 15 ||
                        plantItemDetails[0]?.bill_to_gstin?.length === 3)
                      ? darkGrey
                      : orange,
                }}
              >
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].bill_to_gstin}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>ADDRESS</span>
              <span className={classes.addressStyle}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].RECADD1 + plantItemDetails[0].RECADD2}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>CITY</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 && plantItemDetails[0].RECCITY}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>STATE</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 && plantItemDetails[0].RECSTATE}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span
                style={{
                  color:
                    plantItemDetails.length > 0 &&
                      !plantItemDetails[0]?.RECPINCODE
                      ? orange
                      : grey,
                  fontWeight: "bold",
                }}
              >
                PINCODE
              </span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 && plantItemDetails[0]?.RECPINCODE}
              </span>
            </div>
          </div>
          <div style={{ width: "45%", padding: "24px 0px" }}>
            <div className={classes.twoDivSeparationContainer}>
              <p className={classes.titleTextStyle}>SUPPLIER DETAIL</p>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}> NAME</span>
              <span className={classes.addressStyle}>
                {plantItemDetails.length > 0 && plantItemDetails[0].SUPNAME}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span
                style={{
                  color:
                    plantItemDetails.length > 0 &&
                      !plantItemDetails[0]?.SUPGSTIN
                      ? orange
                      : grey,
                  fontWeight: "bold",
                }}
              >
                GSTIN
              </span>
              <span
                style={{
                  color:
                    (plantItemDetails.length > 0 &&
                      plantItemDetails[0]?.SUPGSTIN?.length < 15) ||
                      (plantItemDetails.length > 0 &&
                        plantItemDetails[0]?.SUPGSTIN?.length > 15)
                      ? orange
                      : darkGrey,
                }}
              >
                {plantItemDetails?.length > 0 && plantItemDetails[0]?.SUPGSTIN}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>ADDRESS</span>
              <span className={classes.addressStyle}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].SUPADD1 + plantItemDetails[0].SUPADD2}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>CITY</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 && plantItemDetails[0].SUPCITY}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>STATE CODE</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0]?.SUPGSTIN?.substring(0, 2)}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span
                style={{
                  color:
                    plantItemDetails.length > 0 &&
                      !plantItemDetails[0]?.SUPPINCODE
                      ? orange
                      : grey,
                  fontWeight: "bold",
                }}
              >
                PINCODE
              </span>
              <span
                style={{
                  color:
                    (plantItemDetails.length > 0 &&
                      plantItemDetails[0]?.SUPPINCODE?.length < 6) ||
                      (plantItemDetails.length > 0 &&
                        plantItemDetails[0]?.SUPPINCODE?.length > 6)
                      ? orange
                      : darkGrey,
                }}
              >
                {plantItemDetails[0]?.SUPPINCODE}
              </span>
            </div>
          </div>
        </div>

        <div className={classes.twoDivSeparationContainer}>
          <div style={{ width: "45%", padding: "24px 0px" }}>
            <div className={classes.twoDivSeparationContainer}>
              <p className={classes.titleTextStyle}>CONSIGNEE DETAIL</p>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}> NAME</span>
              <span className={classes.addressStyle}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].CONSIGNEENAME}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>GSTIN</span>
              <span
                style={{
                  color:
                    (plantItemDetails.length > 0 &&
                      plantItemDetails[0]?.CONSIGNEEGSTIN?.length < 15) ||
                      (plantItemDetails.length > 0 &&
                        plantItemDetails[0]?.CONSIGNEEGSTIN?.length > 15)
                      ? orange
                      : darkGrey,
                }}
              >
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0]?.CONSIGNEEGSTIN}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>ADDRESS</span>
              <span className={classes.addressStyle}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].CONSIGNEEADD1 +
                  plantItemDetails[0].CONSIGNEEADD2}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>CITY</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].CONSIGNEECITY}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>STATE</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].CONSIGNEESTATE}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>PINCODE</span>
              <span
                style={{
                  color:
                    (plantItemDetails.length > 0 &&
                      plantItemDetails[0].CONSIGNEEPINCODE?.length < 6) ||
                      (plantItemDetails.length > 0 &&
                        plantItemDetails[0].CONSIGNEEPINCODE?.length > 6)
                      ? orange
                      : darkGrey,
                }}
              >
                {plantItemDetails[0].CONSIGNEEPINCODE}
              </span>
            </div>
          </div>
          <div style={{ width: "45%", padding: "24px 0px" }}>
            <div className={classes.twoDivSeparationContainer}>
              <p className={classes.titleTextStyle}>OTHER DETAIL</p>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}> TRANSPORTER NAME</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails[0].TRANSPORTERNAME}
              </span>
            </div>

            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>
                TRANSPORTER DOCUMENT DATE
              </span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].TRANSDOCDATE}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>VEHICLE NUMBER</span>
              <span style={{ color: darkGrey }}>
                {plantItemDetails.length > 0 && plantItemDetails[0].VEHICLENO}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>TRANSPORTER DISTANCE</span>
              <span style={{ color: darkGrey }}>
                {this.props.transporterDistance &&
                  this.props.transporterDistance + " KM"}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>TRANSPORTER ID</span>
              {console.log(this.props.checkTransportIdValidation())}
              <span
                style={{
                  color:
                    this.props.checkTransportIdValidation(
                      plantItemDetails[0].TRANSPORTERID
                    ) == null
                      ? darkGrey
                      : red,
                }}
              >
                {plantItemDetails.length > 0 &&
                  plantItemDetails[0].TRANSPORTERID}
              </span>
            </div>
            <div className={classes.twoDivSeparationContainer}>
              <span className={classes.leftKeyStyle}>ACTUAL DISTANCE</span>
              <span style={{ color: darkGrey, display: 'flex', justifyContent: 'flex-end' }}>
                <TextField
                  type="number"
                  className={classes.formControl}
                  style={{
                    width: '60%'
                  }}
                  variant="outlined"
                  size="small"
                  value={actualDistance}
                  onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                  onWheel={(e) => e.target.blur()}
                  name="actual_distance"
                  onChange={(e) => handleActualDistance(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ fontSize: '0.7em', transform: 'scale(0.9)' }}
                      >
                        KM
                      </InputAdornment>
                    ),
                    style: {
                      height: '30px',
                      fontSize: '1em',
                      paddingRight: 6
                    }
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default withRouter(withStyles(styles)(EinvoiceData));
