import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import Details from "../Details";
import { withStyles } from "@material-ui/core/styles";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {};
class SingleOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    if (this.props?.location?.state?.message)
      this.props.history.push("/home")
    else if (window.location.pathname.includes('/orders/active') && (localStorage.getItem("currentUrl").includes('orders') || localStorage.getItem("returnUrl").includes('orders'))) {
      this.props.history.push("/orders")
    }
    else
      if (this.props.history.length > 2)
        this.props.history.goBack();
      else
        this.props.history.push("/orders")
    this.props.clearOrderDetails()
  };
  render() {
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        <Details {...this.props} />
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(SingleOrderDetails);
