import { useEffect, useState } from "react";
import { grey, primaryColor, secondaryGrey, whiteSmoke } from "../../../../constants/internal/colors";
import { pxToEm } from "../../../../methods";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@material-ui/core";
import { Checkbox, FormControlLabel, Tab, Tabs, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import NoDataFound from "../../../common/NoDataFound";

const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: (isMobile) => ({ paddingRight: !isMobile && "25px" }),
  displayData: {
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
    marginTop: "2px 20px 20px 20px",
  },
  filterSection: (isMobile) => ({
    display: "flex",
    alignItems: isMobile ? 'flex-start' : "center",
    // flex: "1",
    justifyContent: 'space-between',
    flexDirection: isMobile && 'column',
    marginBottom: 20
  }),
  searchStateAlign: (isMobile) => ({
    width: isMobile ? "90%" : "40%",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: 'Poppins',
  }),
  tabStyle: (tabValue, currentTab) => ({
    backgroundColor: '#FFF',
    color: tabValue === currentTab ? grey : secondaryGrey,
    fontSize: pxToEm(15),
    fontFamily: 'Poppins',
    // height: 48
  }),
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  bottomContainer: (data) => ({
    display: data?.length === 0 ? 'none' : 'flex',
    justifyContent: 'center',
    width: '100%',
  }),
  bottomInnerContainer: (data) => ({
    padding: 8,
    // backgroundColor: '#000',
    position: 'absolute',
    bottom: 50,
    zIndex: 10000
  }),
  bottomInnerContentContainer: {
    border: '1px solid #2f2f2f',
    borderRadius: 10,
    padding: '12px 16px',
    color: '#dadada',
    backgroundColor: '#333535',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actionButtonIcons: {
    margin: ' 0 8px',
    color: '#adadad',

    '&:hover': {
      backgroundColor: '#FFF !important'
    }
  },
  tableContainerStyle: {
    overflowY: "scroll",
    maxHeight: "calc(100vh - 300px)"
  },
  tableCell: (width) => ({
    color: grey,
    width: width,
    fontSize: "0.9em",
    fontWeight: "bold",
  }),
  noDataDiv: (isMobile) => ({
    display: "flex",
    position: 'relative',
    // left: '25vw',
    left: '32vw',
    width: 'max-content',
    height: '100%',
    zIndex: 9999,
    transform: 'scale(0.9)'
  }),
}


const ApplyScheme = (props) => {

  const [tableTabValue, setTableTabValue] = useState(0);

  const [dealerDistributorAllListData, setDealerDistributorAllListData] = useState({
    data: []
  })
  const [dealerDistributorAppliedListData, setDealerDistributorAppliedListData] = useState({
    data: []
  })
  const [dealerDistributorLeftListData, setDealerDistributorLeftListData] = useState({
    data: []
  })

  const [selectedRow, setSelectedRow] = useState([])

  const [tableDataCount, setTableDataCount] = useState({
    all: 0,
    applied: 0,
    left: 0
  })

  useEffect(() => {
    setTableDataCount({
      all: dealerDistributorAllListData?.data?.length || 0,
      applied: dealerDistributorAppliedListData?.data?.length || 0,
      left: dealerDistributorLeftListData?.data?.length || 0
    })
  }, [dealerDistributorAllListData, dealerDistributorAppliedListData, dealerDistributorLeftListData])

  useEffect(() => {
    props?.getAllDealersDistributors(String(props?.displaySection).toUpperCase())
    props.getAllStatesList()
  }, [])

  useEffect(() => {
    setDealerDistributorAllListData({ data: props?.dealerDistributorList })
    console.log(props?.dealerDistributorList)
  }, [props?.dealerDistributorList])

  const handleRowAdd = (data) => {
    console.log("ADD ID : = ", data)
    let selectedData = [];
    selectedData = props?.dealerDistributorList.filter(d => data.includes(d.id))
    const ids = new Set(dealerDistributorAppliedListData?.data.map(d => d.id));
    const rowAppliedData = [...dealerDistributorAppliedListData?.data,
    ...selectedData.filter(d => !ids.has(d.id))];
    // console.log(rowAppliedData)
    const rowLeftData = props?.dealerDistributorList.filter(x => !rowAppliedData.includes(x));
    setDealerDistributorAppliedListData({ data: rowAppliedData })
    setDealerDistributorLeftListData({ data: rowLeftData })
    setSelectedRow([])
  }

  const handleRowRemove = (data) => {
    console.log("REMOVE ID : = ", data)
    // let selectedData = [];
    const rowAppliedData = dealerDistributorAppliedListData?.data.filter(d => !data.includes(d.id))
    const ids = new Set(rowAppliedData.map(d => d.id));
    const rowLeftData = props?.dealerDistributorList.filter(d => !ids.has(d.id))
    console.log(rowAppliedData, rowLeftData)
    setDealerDistributorAppliedListData({ data: rowAppliedData })
    setDealerDistributorLeftListData({ data: rowLeftData })
    setSelectedRow([])
  }

  const handleTableTabChange = (event, tabValue) => {
    setTableTabValue(tabValue);
  };

  const handleStateChangeTableData = (event, value) => {
    if (value) {
      let filterData = dealerDistributorAllListData?.data.filter(d => d.state === value.name)
      console.log(value)
      setDealerDistributorAllListData({
        data: filterData
      })
    }
    else {
      setDealerDistributorAllListData({ data: props?.dealerDistributorList })
    }
  }

  const handleCheckboxClick = (e, checked, id) => {
    console.log(e, id)
    e.stopPropagation();
    e.preventDefault(); // Prevent default checkbox behavior
    const newSelectionModel = [...selectedRow];

    if (newSelectionModel.includes(id)) {
      // If the item is already selected, unselect it
      const index = newSelectionModel.indexOf(id);
      newSelectionModel.splice(index, 1);
    } else {
      // If the item is not selected, select it
      newSelectionModel.push(id);
    }

    setSelectedRow(newSelectionModel);
  };

  const handleSelectAllRow = (e, checked) => {
    let checkedValue = e.target.checked
    if (checkedValue) {
      const ids = dealerDistributorAllListData.data.map(d => d.id);
      setSelectedRow(ids)
    }
    else {
      setSelectedRow([])
    }
    console.log(e, checked)
  }



  const ActionCell = (props) => {
    return (
      <div style={styles.actionContainer}>
        <IconButton
          aria-label="delete"
          size="small"
          style={{ margin: ' 0 8px 0 0' }}
          onClick={(e) => {
            e.preventDefault();
            props.handleRowAdd([props.id])
          }}
        >
          <PlaylistAddOutlinedIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          style={{ margin: ' 0 0 0 8px' }}
          onClick={(e) => {
            e.preventDefault();
            props.handleRowRemove([props.id])
          }}
        >
          <PlaylistRemoveIcon />
        </IconButton>
      </div>
    )
  }



  const { isMobile, displaySection } = props;
  return (
    <div style={styles.mainDiv}>
      <div style={styles.rightPadding(isMobile)}>
        <div style={styles.displayData}>
          <Paper style={styles.paperStyle}>
            <div style={styles.filterSection(isMobile)}>
              <Autocomplete
                style={styles.searchStateAlign(isMobile)}
                size="small"
                id="size-small-outlined"
                options={props?.allStateList}
                getOptionLabel={(option) => option.name}
                ListboxProps={{ style: { fontFamily: 'Poppins', } }}
                onChange={handleStateChangeTableData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{ ...params.InputProps, style: { fontFamily: 'Poppins' } }}
                    InputLabelProps={{ ...params.InputLabelProps, style: { fontFamily: 'Poppins' } }}
                    label={"Search State"}
                    variant="outlined"
                  />
                )}
              />
              <div>
                <>
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        borderBottom: `2px solid ${primaryColor}`,
                        color: grey,
                      },
                    }}
                    value={tableTabValue}
                    onChange={handleTableTabChange}
                    style={styles.tabStyle(3, 4)}
                  >
                    <Tab
                      label={`ALL (${tableDataCount.all})`}
                      // icon={<ListOutlinedIcon />}
                      iconPosition="start"
                      style={styles.tabStyle(tableTabValue, 0)}
                    />
                    <Tab
                      label={`APPLIED (${tableDataCount.applied})`}
                      // icon={<CheckBoxOutlinedIcon />}
                      iconPosition="start"
                      style={styles.tabStyle(tableTabValue, 1)}
                    />
                    <Tab
                      label={`LEFT (${tableDataCount.left})`}
                      // icon={<CheckBoxOutlineBlankOutlinedIcon />}
                      iconPosition="start"
                      style={styles.tabStyle(tableTabValue, 2)}
                    />
                  </Tabs>
                </>
              </div>
            </div>
            <Paper>
              <div>
                <TableContainer style={styles.tableContainerStyle} id="scroll">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow style={styles.tableHeader}>
                        <TableCell
                          align="center"
                          style={styles.tableCell('10%')}
                        >
                          <div>
                            <Checkbox
                              checked={selectedRow.length > 0}
                              indeterminate={selectedRow.length > 0 && selectedRow.length < dealerDistributorAllListData.data?.length}
                              onChange={handleSelectAllRow}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={styles.tableCell('16%')}
                        >
                          NAME
                        </TableCell>
                        <TableCell
                          align="center"
                          style={styles.tableCell('13%')}
                        >
                          STATE
                        </TableCell>
                        <TableCell
                          align="center"
                          style={styles.tableCell('14%')}
                        >
                          LAST YEAR SALE
                        </TableCell>
                        <TableCell
                          align="center"
                          style={styles.tableCell('14%')}
                        >
                          THIS YEAR SALE
                        </TableCell>
                        <TableCell
                          align="center"
                          style={styles.tableCell('14%')}
                        >
                          STATUS
                        </TableCell>
                        <TableCell
                          align="center"
                          style={styles.tableCell('14%')}
                        >
                          ACTION
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableTabValue === 0 && dealerDistributorAllListData.data.length === 0
                        ? <TableRow style={styles.noDataDiv(isMobile)}>
                          <NoDataFound />
                        </TableRow>
                        : (tableTabValue === 0
                          ? dealerDistributorAllListData.data
                          : tableTabValue === 1
                            ? dealerDistributorAppliedListData.data
                            : tableTabValue === 2 && dealerDistributorLeftListData.data
                        ).map((rowData, index) => (
                          <TableRow key={rowData.id}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: "5px" }}
                            >
                              <div>
                                <Checkbox
                                  checked={selectedRow.includes(rowData.id)}
                                  onChange={(e, checked) => handleCheckboxClick(e, checked, rowData.id)}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: "5px" }}
                            >
                              {rowData.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: "5px" }}
                            >
                              {rowData.state}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: "5px" }}
                            >
                              {rowData.last_year_sale}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: "5px" }}
                            >
                              {rowData.this_year_sale}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: "5px" }}
                            >
                              {rowData.status}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ padding: "5px" }}
                            >
                              <ActionCell
                                {...props}
                                id={rowData.id}
                                handleRowAdd={handleRowAdd}
                                handleRowRemove={handleRowRemove}
                              />
                            </TableCell>

                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          </Paper>
        </div>
      </div>
      <div style={styles.bottomContainer(selectedRow)}>
        <div style={styles.bottomInnerContainer(selectedRow)}>
          <div style={styles.bottomInnerContentContainer}>
            <IconButton
              aria-label="delete"
              size="small"
              style={styles.actionButtonIcons}
              onClick={() => {
                setSelectedRow([])
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>

            <span
              style={{
                margin: '0 30px 0 10px',
                color: '#FFF'
              }}
            >
              {selectedRow.length} selected
            </span>

            <span style={{ margin: '0 10px 0 40px' }}>
              <IconButton
                aria-label="delete"
                size="small"
                style={styles.actionButtonIcons}
                onClick={() => {
                  handleRowAdd(selectedRow)
                }}
              >
                <PlaylistAddOutlinedIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                size="small"
                style={styles.actionButtonIcons}
                onClick={() => {
                  handleRowRemove(selectedRow)
                }}
              >
                <PlaylistRemoveIcon />
              </IconButton>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyScheme;