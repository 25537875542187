import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import imageCode from "../../reports/web/ImageCode.json"
import moment from "moment";
import { CaptilizeText, handleTotalQtySumCalculation, printPDFInMobile } from "../../../../methods";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const handleColumnWidth = (header, module) => {
  if (module === 'contract_booking') {
    let startingStaticColumns = [47, 30, 45, 30, 38, 35, 30, 35, 35, 35, 45, 30,]
    let lastStaticColumns = [30, 25, 35, 38]
    let plantColumns = Array(header.length - (startingStaticColumns.length + lastStaticColumns.length)).fill(header.length > 16 && header.length <= 19 ? 25 : 18)
    return startingStaticColumns.concat(plantColumns, lastStaticColumns)
  }
  else {
    let widthArray = []
    header.map(h => widthArray.push(h.minWidth ?? "*"))
    return widthArray
  }
}

const tableMargin = (header, module) => {
  // if (module === 'contract_booking'){
  return header.length > 19
    ? [-20, 0, 0, 0]
    : header.length === 19
      ? [-12, 0, 0, 0]
      : header.length === 18
        ? [10, 0, 0, 0]
        : header.length >= 16
          ? [30, 0, 0, 0]
          : [0, 0, 0, 0]
  // }
}

const handlePrintPDF = (data, header, tableHeaderColumnWidth = '', extraData = '', snackbarFunction, module) => {
  if (data) {
    // console.log("extraData", extraData)
    let userName = JSON.parse(localStorage.getItem('user'))?.full_name;
    console.log(header, data, tableHeaderColumnWidth, module, userName)
    var dd = {
      info: {
        title: ` Dashboard ${CaptilizeText(module?.replaceAll("_", " "))}`,
        author: "RIPL",
        subject: `${CaptilizeText(module?.replaceAll("_", " "))}`,
        keywords: module,
      },
      pageOrientation: 'landscape',
      pageMargins: [20, 20, 20, 40],
      header: function (currentPage) {
        // console.log(imageCode)
        if (currentPage === 1)
          return {
            margin: [0, 0, 0, 10],
            columns: [
              {
                image: imageCode,
                width: 50,
                height: 50,
                margin: [10, 0, 10, 10]
              }
            ]
          }
      },
      footer: function (currentPage, pageCount) {
        return {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  alignment: 'right',
                  margin: [0, 0, 35, 50],
                  fontSize: 8,
                }
              ]
            ]
          },
          layout: 'noBorders'
        };
      },
      content: [
        {
          margin: [0, 10, 0, 0],
          text: '\n'
        },
        {
          margin: [25, 10, 0, 0],
          columns: [
            {
              text: [
                "Report Name -  ",
                { text: ` Dashboard ${CaptilizeText(module?.replaceAll("_", " "))} `, fontSize: 10, }
              ],
              style: "subHeading",
              alignment: "left",
              width: '*'
            },
            {
              text: `Date - From ${extraData.startDate} To ${extraData.endDate}`,
              style: "subHeading",
              alignment: "right",
              width: '*'
            }
          ],
        },
        {
          margin: (extraData?.item_category_name || extraData?.plant) ? [25, 2, 0, 0] : [25, 2, 0, 20],
          columns: [
            {
              text: `Date of Generation - ${(moment(new Date()).format('DD-MMM-yyyy'))}`,
              style: "subHeading",
              alignment: "left",
              width: '*'
            },
            {
              text: `User Name - ${userName}`,
              style: "subHeading",
              alignment: "right",
              width: '*'
            }
          ],
        },
        (extraData?.item_category_name || extraData?.plant) ?
          {
            margin: [25, 2, 0, 20],
            columns: [
              {
                text: `Item Category Name - ${extraData?.item_category_name?.length > 0 ? extraData?.item_category_name : 'ALL'}`,
                style: "subHeading",
                alignment: "left",
                width: '*'
              },
              extraData?.plant ?
                {
                  text: `Plant Name - ${extraData?.plant?.length > 0 ? extraData?.plant : 'ALL'}`,
                  style: "subHeading",
                  alignment: "right",
                  width: '*'
                } : null,
            ],
          }
          : null,
        {
          margin: tableMargin(header, module),
          table: {
            headerRows: 1,
            widths: handleColumnWidth(header, module),
            heights: function (row) { return 15; },
            body: [
              header.map(d => (
                {
                  text: d.label,
                  style: "headerStyle",
                  fontSize: ['contract_booking'].includes(module) ? 7 : 8,
                  margin: ['sales_explorer'].includes(module) ? [8, 8, 8, 8] : [0, 4, 0, 4],
                  border: [false, false, false, true],
                }
              ))

            ],
          },
          layout: {
            hLineColor: '#ddd',
            vLineColor: '#ddd',
            vLineHeight: 0,
          },
        },
        data.map((item, index) => (
          {
            margin: tableMargin(header, module),
            table: {
              widths: handleColumnWidth(header, module),
              heights: function (row) { return 5; },
              dontBreakRows: true,
              body: [
                header.map(h => ({
                  text: ['rate'].includes(h.key) && index !== data.length - 1 ? (Number(item[h.key])).toFixed(2) : item[h.key],
                  style: "bodyStyle",
                  bold: (['contract_booking', 'sales_explorer'].includes(module) && index === data.length - 1) && true,
                  fontSize: ['contract_booking'].includes(module)
                    ? index === data.length - 1 ? 7 : 6
                    : ['sales_explorer'].includes(module) && index === data.length - 1 ? 8 : 7,
                  margin: ['sales_explorer'].includes(module) ? [8, 8, 8, 12] : [1, 0, 1, 0],
                  border: [false, false, false, true],
                }))
              ],
            },
            layout: {
              hLineColor: '#ddd'
            },
          }
        )),

      ],
      styles: {
        heading: {
          color: '#000',
          fontSize: 15,
          bold: true,
          margin: [0, 3, 0, 3],
        },
        subHeading: {
          // color: '#000',
          fontSize: 10,
          // bold: false,
          margin: [0, 3, 0, 3],
        },
        itemsHeader: {
          padding: [0, 5, 0, 5],
          hLineColor: '#808080',
          fillColor: "#f5f5f5",
          // color: '#839192',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        itemsBody: {
          padding: [0, 5, 0, 5],
          hLineColor: '#808080',
          fillColor: '#FFF',
          color: '#333',
          fontSize: 10,
          alignment: "center",
        },
        itemsFooter: {
          hLineColor: '#808080',
          // fillColor: "#f5f5f5",
          color: '#000',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        dateStyle: {
          fontSize: 7,
          // decoration: 'underline'
        },
        readydateStyle: {
          fontSize: 7,
          color: '#839192'
        },
        headerStyle: {
          padding: [0, 5, 0, 5],
          hLineColor: '#808080',
          fillColor: "#f5f5f5",
          // color: '#839192',
          fontSize: 8,
          bold: true,
          alignment: "center",
        },
        bodyStyle: {
          padding: [0, 1, 0, 1],
          hLineColor: '#808080',
          fillColor: '#FFF',
          color: '#333',
          fontSize: 7,
          alignment: "center",
        },
        dispatchFooter: {
          hLineColor: '#808080',
          // fillColor: "#f5f5f5",
          color: '#000',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        readyBody: {
          padding: [0, 1, 0, 1],
          hLineColor: '#808080',
          fillColor: '#FFF',
          color: '#333',
          fontSize: 8,
          alignment: "center",
        },
        readyFooter: {
          hLineColor: '#808080',
          // fillColor: "#f5f5f5",
          color: '#000',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        readyFooterdateStyle: {
          padding: [0, 2, 0, 1],
          fontSize: 8,
          color: '#839192'
        }
      }
    }
    // if (module === 'contract_booking') {
    const reportName = `DashBoard ${CaptilizeText(module?.replaceAll("_", " "))}_${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`
    // pdfMake.createPdf(dd).open();
    if (!window.cordova) {
      pdfMake.createPdf(dd).open()
      // pdfMake.createPdf(dd).download(reportName, snackbarFunction());
    }
    else {
      let fileName = `DashBoard ${CaptilizeText(module?.replaceAll("_", " "))}`
      printPDFInMobile(pdfMake, dd, fileName, snackbarFunction, '')
    }
    // }
  }
}



export {
  handlePrintPDF,
}