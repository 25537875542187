import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  primaryColor,
  whiteSmoke,
  secondaryGrey,
  drawerTextColor,
} from "../../../constants/internal/colors";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ContractsCustomersTable from "./ContractsCustomersTable";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Paper from "@material-ui/core/Paper";

const customersStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: "0.9em",
  },
  filterSection: {
    //margin: "10px 0px 10px 0px",
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  searchAutoComplete: {
    display: "flex",
    flex: 5,
  },
  filterAlignment: {
    display: "flex",
    alignItems: "center",
    flex: 3,
    placeContent: "center",
  },
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  searchBox: {
    width: "85%",
  },
  formControl: {
    minWidth: "100%",
    marginLeft: "10px",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingRight: "25px",
    overflowY: "scroll",
    paddingBottom: "30px",
  },
  selectedTab: {
    backgroundColor: whiteSmoke,
    color: grey,
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tabsStyles: {
    backgroundColor: whiteSmoke,
    color: secondaryGrey,
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tabContainerStyle: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "15px",
    display: "flex",
  },
  tabContainerChildStyle: {
    width: "100%",
  },
  tableMainContainerStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableChildContainerStyle: {
    width: "92%",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
    marginTop: "2px 20px 20px 20px",
  },
  displayData: {
    marginTop: 10,
  },
});

class Contracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      searchText: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };
  render() {
    const { tabValue, searchText } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <div>
            <Typography>Orders</Typography>
          </div>

          <div className={classes.displayData}>
            <Paper className={classes.paperStyle}>
              <div className={classes.filterSection}>
                <div className={classes.searchAutoComplete}>
                  <TextField
                    name="searchText"
                    className={classes.searchBox}
                    label="Search "
                    autoComplete="off"
                    value={searchText}
                    variant="outlined"
                    size="small"
                    onChange={this.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={classes.filterAlignment}>
                  <Typography>FILTER</Typography>
                  <div style={{ width: "45%" }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      size="small"
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        //  value={filterSelection}
                        // onChange={this.handleFilterChange}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="new">New</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={classes.addButton}>
                  <Button
                    className={classes.primaryBackground}
                    variant="contained"
                    startIcon={<AddOutlinedIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        "/internalContracts/addContracts"
                      );
                    }}
                  >
                    ADD NEW 
                  </Button>
                </div>
              </div>
              <Paper>
                <div className={classes.tabContainerStyle}>
                  <div className={classes.tabContainerChildStyle}>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          borderBottom: `2px solid ${primaryColor}`,
                          colur: grey,
                        },
                      }}
                      value={tabValue}
                      onChange={this.handleTabChange}
                      // fullWidth
                      className={classes.tabsStyles}
                      variant="scrollable"
                    >
                      <Tab
                        style={{ width: "24.5%" }}
                        label="GK TMT"
                        className={
                          tabValue === 0
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        style={{ width: "24.5%" }}
                        label="STRAIGHT"
                        className={
                          tabValue === 1
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        style={{ width: "24.5%" }}
                        label="WIRE ROAD"
                        className={
                          tabValue === 2
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        style={{ width: "24.5%" }}
                        label="GB WIRE"
                        className={
                          tabValue === 3
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        style={{ width: "24.5%" }}
                        label="Shahid"
                        className={
                          tabValue === 3
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        style={{ width: "24.5%" }}
                        label="Hussain"
                        className={
                          tabValue === 3
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                    </Tabs>
                  </div>
                </div>
                <div>
                  <ContractsCustomersTable tabValue={tabValue} />
                </div>
              </Paper>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(customersStyles)(Contracts));
