import React, { Component } from "react";
import COLORS from "../../../constants/internal/colors";

class WeighingStepper extends Component {
  render() {
    return (
      <div style={{ display: "flex", marginTop: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "20%" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <div style={{ width: "40%", height: "1px" }} />
            <div
              style={{
                background: COLORS.orange,
                width: "20px",
                height: "20px",
                borderRadius: "50%",
              }}
            />
            <div
              style={{ width: "40%", height: "1px", background: COLORS.grey }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "12px",
              fontSize: "0.7rem",
            }}
          >
            <span style={{ color: COLORS.grey }}>Tare Weight</span>
            <span style={{ color: COLORS.grey }}>
              {parseFloat(this.props.tareWeight)?.toFixed(3)} MT
            </span>
          </div>
        </div>
        {/* REPEATED VALUE ------------------ */}
        {this.props.weigh_slips?.map((data, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "20%",
              marginLeft: "-3px",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <div
                style={{ width: "40%", height: "1px", background: COLORS.grey }}
              />
              <div
                style={{
                  background: "#CDDC39",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
              />
              <div
                style={{
                  width: "40%",
                  height: "1px",
                  background:
                    this.props.weigh_slips?.length - 1 > index
                      ? COLORS.grey
                      : "none",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "12px",
                fontSize: "0.7rem",
              }}
            >
              <span style={{ color: COLORS.grey }}>Weight #{index + 1}</span>
              <span style={{ color: COLORS.grey }}>
                {parseFloat(data.loaded_weight)?.toFixed(3)} MT
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
export default WeighingStepper;
