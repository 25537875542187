import React, { Component } from "react";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import SpinLoader from "../common/SpinLoader";
import backStack from "cordova-back-stack";
class Enquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }
  componentDidMount() {
    this.setState({ loader: true });
    backStack((e) => {
      e.preventDefault();
      this.props.history.push("/login");
    });
  }
  render() {
    return (
      <div>
        <div
          style={{
            height: "56px",
            display: "flex",
            alignItems: "center",
            padding: "0px 30px",
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/login");
            }}
          >
            <KeyboardBackspace />
          </Button>
        </div>
        {this.state.loader || this.state.loader === undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinLoader />
          </div>
        ) : null}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            // src="https://docs.google.com/forms/d/e/1FAIpQLSeCEAIfqNdO1uVTIMH0jY4LD3HUER3L2hQhxKGirl0QsX39Rg/viewform?embedded=true"
            src="https://www.realgroup.org/enquiry"
            width="640"
            height="1277"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="Enquiry Google Form"
            onLoad={(e) => e && this.setState({ loader: false })}
          >
            Loading…
          </iframe>
        </div>
      </div>
    );
  }
}
export default withRouter(Enquiry);
