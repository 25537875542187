import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Divider, } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  primaryColor,
  drawerTextColor,
  secondaryBlueText,
  cancelButtonBackground,
} from "../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import RemoveConfirmationDialog from "../../common/RemoveConfirmationDialog";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "30px",
    width: "100%",
  },
  headerData: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
  topMargin: { marginTop: 30, width: "45%" },

  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
});

function DisplayAppBannerData(props) {
  const classes = useStyles();
  const {
    openDialog,
    dialogTitle,
    dialogContent,
    isLoadingRemove,
    removeAppBannerLoader,
    handleRemoveAppBanner,
    handleRemoveConfirmationDialogClose,
    handleConfirmationDialogDeleteClick,
  } = props;
  const [appBannerData, setAppBannerDisplayData] = useState(
    props.selectedAppBannerData
  );

  useEffect(() => {
    console.log(props?.selectedappBannerData)
    setAppBannerDisplayData(props?.selectedappBannerData)
  }, [props?.selectedappBannerData])

  const handleCancelButtonClick = () => {
    props.history.push(INTERNALROUTES.APP_BANNER);
  };
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <div className={classes.navigationBar}>
            <Bread
              data={[
                {
                  title: "Master Management",
                  onClick: () =>
                    props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
                },
                {
                  title: "App Banner Master",
                  onClick: () => props.history.push(INTERNALROUTES.APP_BANNER),
                },
                {
                  title: "View",
                  onClick: "",
                },
              ]}
            />
            <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />
            <div className={classes.headerData}>
              <span>{moment(appBannerData?.valid_from).format('DD-MMM-YYYY') || ""}</span>
            </div>
          </div>
          <div className={classes.topMargin}>
            <div className={classes.dataFields} style={{ alignItems: 'flex-start' }}>
              <Typography className={classes.titleLabel}>
                CONTENT
              </Typography>
              <Typography className={classes.titleLabel} style={{ paddingRight: 10, textAlign: 'end', width: '50%', wordWrap: 'break-word' }}>
                {appBannerData?.news || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                WITH EFFECT FROM
              </Typography>
              <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                {moment(appBannerData?.valid_from).format('DD-MMM-YYYY') || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                WITH EFFECT TO
              </Typography>
              <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                {appBannerData?.valid_upto
                  ? moment(appBannerData?.valid_upto).format('DD-MMM-YYYY')
                  : "-"}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                LAST EDITED
              </Typography>
              <Typography className={classes.titleLabel} style={{ paddingRight: 10 }}>
                {moment(appBannerData?.modified_at).format('DD-MMM-YYYY') || ""}
              </Typography>
            </div>
            {/* <ToolTipComponent
              title="You have no edit permission on this widget"
              condition={!hasUpdateAccess(props.user, 'app_banner_master')}
            > */}
            <Button
              size="medium"
              className={classes.primaryBackground}
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              disabled={isEmpty(appBannerData) || moment(appBannerData.valid_from).isBefore(moment(), 'day')
                // || !hasUpdateAccess(props.user, 'app_banner_master')
              }
              onClick={(e) => {
                e.preventDefault();
                props.history.push(
                  `${INTERNALROUTES.APP_BANNER}/edit/${appBannerData?.id}`
                );
              }}
            >
              EDIT
            </Button>
            {/* </ToolTipComponent> */}
            <Button
              size="medium"
              className={classes.removeButton}
              variant="contained"
              startIcon={<DeleteOutlineOutlinedIcon />}
              disabled={isEmpty(appBannerData)}
              onClick={handleRemoveAppBanner}
            >
              REMOVE
            </Button>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
        <RemoveConfirmationDialog
          open={openDialog}
          dialogTitle={dialogTitle}
          dialogContent={dialogContent}
          removeDataLoader={removeAppBannerLoader}
          handleClose={handleRemoveConfirmationDialogClose}
          handleConfirmationDialogDeleteClick={
            handleConfirmationDialogDeleteClick
          }
        />
      </div>
    </>
  );
}
export default withRouter(DisplayAppBannerData);
