import React, { Component } from "react";
import { withRouter } from "react-router";
import { primaryColor } from "../../../styles/colors";
import Home from "@material-ui/icons/Home";
import Notifications from "@material-ui/icons/NotificationsNone";
import Badge from '@mui/material/Badge';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Assignment from "@material-ui/icons/Assignment";
import LocalShipping from "@material-ui/icons/LocalShipping";
import { pxToEm } from "../../../methods";
import { Button, Dialog, Slide } from "@material-ui/core";
import withAuthentication from "../../../hoc/withAuthentication";
import { drawerTextColor } from "../../../constants/internal/colors";

const styles = {
  navigationLabel: {
    fontSize: window.innerWidth < 375 && pxToEm(13)
  },
  dialogContainer: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "15px 20px 20px 20px"
  },
  dialogInnerContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  logOutButton: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    textTransform: 'none',
    marginRight: 10
  }
}

class BottomNavigationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }
  componentWillMount() {
    // console.log(this.props)
    this.getProps(this.props);
    // if (!window.cordova) {
    // document.addEventListener('keyup', function (event) {
    //   // Alt+Ctrl+<
    //   if (event.altKey && event.ctrlKey && event.keyCode === 188) {
    //     console.log('CLICKED', this.props)
    //   }
    // });
    // }
  }
  componentWillReceiveProps(nextProps) {
    this.getProps(nextProps);
  }
  getProps = (currentProps) => {
    this.setState({
      value: currentProps.bottomNavSelected,
    });
  };
  badgeVisibility = () => {
    return false
  }
  render() {
    const { value } = this.state;
    const { userNotifications, unreadNotifications, logoutPopoverText } = this.props;
    // console.log(this.props)
    return (
      <>
        <BottomNavigation
          value={value}
          style={{
            backgroundColor: primaryColor,
            color: "white",
            position: "fixed",
            bottom: "0px",
            width: "100%",
            height: "74px",
            borderRadius: "10px 10px 0px 0px",
            display: "flex",
            justifyContent: "space-around",
            // alignItems: "center",
          }}
          onChange={this.handleChange}
          showLabels
        >
          <BottomNavigationAction
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/home");
            }}
            label={<span style={styles.navigationLabel}>Home</span>}
            icon={<Home />}
            style={{
              height: value === 0 ? "62px" : null,
              background: value === 0 ? "#f6f8fc" : primaryColor,
              borderRadius: "0px 0px 10px 10px",
              color: value === 0 ? primaryColor : "white",
              maxWidth: "22%",
            }}
          />
          <BottomNavigationAction
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/orders");
            }}
            label={<span style={styles.navigationLabel}>Contracts</span>}
            icon={<Assignment />}
            style={{
              height: value === 1 ? "62px" : null,
              background: value === 1 ? "#f6f8fc" : primaryColor,
              borderRadius: "0px 0px 10px 10px",
              color: value === 1 ? primaryColor : "white",
              maxWidth: "22%",
            }}
          />
          <BottomNavigationAction
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/dispatch");
            }}
            label={<span style={styles.navigationLabel}>Dispatch</span>}
            icon={<LocalShipping />}
            style={{
              height: value === 2 ? "62px" : null,
              background: value === 2 ? "#f6f8fc" : primaryColor,
              borderRadius: "0px 0px 10px 10px",
              color: value === 2 ? primaryColor : "white",
              maxWidth: "22%",
            }}
          />
          <BottomNavigationAction
            label={<span style={styles.navigationLabel}>Notification</span>}
            icon={
              <Badge
                // color="secondary"
                sx={{
                  "& .MuiBadge-badge": {
                    color: value !== 3 ? primaryColor : "white",
                    backgroundColor: value === 3 ? primaryColor : "white"
                  }
                }}
                // variant="dot"
                badgeContent={
                  userNotifications && (userNotifications.filter(d => d.is_read === false)).length
                }
              // invisible={this.badgeVisibility}
              >
                <Notifications />
              </Badge>
            }
            style={{
              height: value === 3 ? "62px" : null,
              background: value === 3 ? "#f6f8fc" : primaryColor,
              borderRadius: "0px 0px 10px 10px",
              color: value === 3 ? primaryColor : "white",
              maxWidth: "22%",
            }}
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push({
                pathname: "/b2bNotification",
                ...this.props
              });
            }}
          />
        </BottomNavigation>
        <Dialog
          keepMounted
          open={Boolean(logoutPopoverText)}
          PaperProps={{
            style: {
              borderRadius: "15px",
              alignItems: 'flex-start',
              padding: 8
            },
          }}
        >
          <div style={styles.dialogContainer}>
            <p>
              {logoutPopoverText}
            </p>
            <div style={styles.dialogInnerContainer}>
              <Button
                style={styles.logOutButton}
                onClick={(e) => {
                  this.props.logout()
                  if (window.location.pathname === "/b2bNotification") {
                    this.props.history.push('/login')
                  }
                }
                }
                variant="outlined"
              >
                Logout
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}
export default withAuthentication(withRouter(BottomNavigationComponent));
