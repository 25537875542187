import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import Contracts from "../Contracts";
import ContractsNewUi from "./newUi/Contracts";

class ContractsComponent extends Component {
  render() {
    return (
      <Layout {...this.props}>
        {this?.props?.displayNewUi ? (
          <ContractsNewUi {...this.props} />
        ) : (
          <Contracts />
        )}
      </Layout>
    );
  }
}
export default withRouter(ContractsComponent);
