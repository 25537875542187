import React, { useEffect, useState } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import ItemCategoryViewComponent from "../../../../components/internal/masterManagement/itemCategory/ItemCategoryViewComponent";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import AccessDenied from "../../../../components/common/AccessDenied";

// container for Master Management -> Item Category -> View
const ItemCategoryView = (props) => {
  const { user, isMobile } = props;
  const [Fetching, setFetching] = useState(true);
  const [Item, setItem] = useState(null);

  const getItemCateogries = async (options) => {
    axios.get(APIROUTES.DAILY_RATE_TEMPLATE_GET, options).then(
      (response) => {
        let result = response.data;
        console.log("response ", result);
        setItem(result);
        setFetching(false);
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };
      getItemCateogries(options);
    } else {
      setFetching(false);
    }
  }, [user]);

  if (isMobile) return <OpenInDesktop {...this.props} />;

  if (!user.is_admin && user.status !== "approved") {
    return <AccessDenied />;
  }

  return (
    <div>
      <ItemCategoryViewComponent {...props} data={Item} Fetching={Fetching} />
    </div>
  );
};

export default ItemCategoryView;
