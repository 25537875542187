import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import telephone from "../../internal/dispatchOperation/telephone.json"
import { truncate } from "lodash";
import { ToWords } from 'to-words';
import { numberConvertor } from "../../../methods";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const handlePrintInvoicingpreview = (laNumber, action, user = null) => {
    axios
        .get(`${APIROUTES.FETCH_LA_DETAILS_IN_DISPATCH_COUNTER}${laNumber}/`)
        .then(async (response) => {
            if (response.status === 200) {
                let tableData = [];
                let taxationTableData = [];
                let footerContainer = [];
                let headerContainer = [];
                let divisionTable = []
                let gstTable = []
                let panTable = []
                console.log(response.data)
                let logo = await getBase64ImageFromURL("plant", response.data?.plant?.plant_logo);
                let itemLogo = await getBase64ImageFromURL("item", response.data?.item_category?.item_category_logo);
                headerContainer.push([
                    {
                        columns: [
                            [
                                response.data?.plant?.plant_logo &&
                                {
                                    image: logo,
                                    width: 120,
                                    height: 50,
                                    alignment: "left",
                                },
                                {
                                    text: `Works : ${response.data?.plant?.plant_address}${response.data?.plant?.plant_city ?
                                        `, ${response.data?.plant?.plant_city}` : ""} ${response.data?.plant?.state_name ?
                                            `, ${response.data?.plant?.state_name}` : ""}${response.data?.plant?.plant_pincode ?
                                                `- ${response.data?.plant?.plant_pincode}` : ""}`,
                                    fontSize: 7,
                                    margin: [0, 2, 0, 0]
                                },
                                {
                                    text: `Ph. No. : ${response.data?.plant?.plant_mobile ? response.data?.plant?.plant_mobile : ""}`,
                                    fontSize: 7
                                },
                                {
                                    text: `e-mail : ${response.data?.plant?.plant_email}`,
                                    fontSize: 7
                                }
                            ],
                            {
                                text: "TAX INVOICE PREVIEW",
                                width: 170,
                                fontSize: 8,
                                bold: true,
                                decoration: "underline",
                                alignment: "center",
                                border: [true, true, true, true],
                            },

                            {
                                columns: [

                                    [
                                        {
                                            text: '',
                                            width: 290,
                                            style: "topRightTextStyle",
                                            border: [true, true, true, true],
                                        },


                                        response.data?.item_category?.item_category_logo &&
                                        {
                                            image: itemLogo,
                                            width: 60,
                                            height: 60,
                                            alignment: "right",
                                            margin: [0, 5, 5, 0],
                                        },
                                    ]
                                ]
                            },
                        ]
                    },
                    {
                        columns: [
                            {
                                style: 'tableExample',
                                table: {
                                    body: divisionTable,
                                    widths: [75, 5, 75],
                                    // dontBreakRows: true,
                                },
                                layout: "headerLayout"
                                // margin: [0, 15, 0, 0]
                            },
                            {
                                style: 'tableExample',
                                table: {
                                    body: gstTable,
                                    widths: [50, 5, 100],
                                    // dontBreakRows: true,
                                },
                                layout: "headerLayout"
                                // margin: [0, 15, 0, 0]
                            },
                            {
                                style: 'tableExample',
                                table: {
                                    body: panTable,
                                    widths: [20, 5, 125],
                                    // dontBreakRows: true,
                                },
                                layout: "headerLayout"
                                // margin: [0, 15, 0, 0]
                            },
                        ]
                    },
                ])

                divisionTable.push([
                    {
                        text: "Range",
                        style: "divisionHeader",
                        border: [true, true, false, false],
                    },
                    {
                        text: ":",
                        style: "divisionHeader",
                        border: [false, true, false, false],
                    },
                    {
                        text: response.data?.plant?.range,
                        style: "divisionHeader",
                        border: [false, true, true, false],
                    },
                ],
                    [{
                        text: "Division",
                        style: "divisionHeader",
                        border: [true, false, false, false],
                    },
                    {
                        text: ":",
                        style: "divisionHeader",
                        border: [false, false, false, false],
                    },
                    {
                        text: response.data?.plant?.division,
                        style: "divisionHeader",
                        border: [false, false, true, false],
                    },
                    ],
                    [{
                        text: "Commissionerate",
                        style: "divisionHeader",
                        border: [true, false, false, true],
                    },
                    {
                        text: ":",
                        style: "divisionHeader",
                        border: [false, false, false, true],
                    },
                    {
                        text: response.data?.plant?.commissionerate,
                        style: "divisionHeader",
                        border: [false, false, true, true],
                    },
                    ],)

                gstTable.push([{
                    text: "GSTIN",
                    style: "divisionHeader",
                    border: [true, true, false, false],
                },
                {
                    text: ":",
                    style: "divisionHeader",
                    border: [false, true, false, false],
                },
                {
                    text: response.data?.plant?.plant_gst_number,
                    style: "divisionHeader",
                    border: [false, true, true, false],
                },
                ],
                    [{
                        text: "State Code",
                        style: "divisionHeader",
                        border: [true, false, false, false],
                    },
                    {
                        text: ":",
                        style: "divisionHeader",
                        border: [false, false, false, false],
                    },
                    {
                        text: response.data?.plant?.plant_gst_number
                            && `${response.data?.plant?.plant_gst_number[0]}${response.data?.plant?.plant_gst_number[1]}`,
                        style: "divisionHeader",
                        border: [false, false, true, false],
                    },
                    ],
                    [{
                        text: "State Name",
                        style: "divisionHeader",
                        border: [true, false, false, true],
                    },
                    {
                        text: ":",
                        style: "divisionHeader",
                        border: [false, false, false, true],
                    },
                    {
                        text: response.data?.plant?.state_name,
                        style: "divisionHeader",
                        border: [false, false, true, true],
                    },
                    ],)

                panTable.push([{
                    text: "Pan",
                    style: "divisionHeader",
                    border: [true, true, false, false],
                },
                {
                    text: ":",
                    style: "divisionHeader",
                    border: [false, true, false, false],
                },
                {
                    text: response.data?.plant?.plant_pan_number,
                    style: "divisionHeader",
                    border: [false, true, true, false],
                },
                ],
                    [{
                        text: "CIN",
                        style: "divisionHeader",
                        border: [true, false, false, false],
                    },
                    {
                        text: ":",
                        style: "divisionHeader",
                        border: [false, false, false, false],
                    },
                    {
                        text: response.data?.plant?.cin,
                        style: "divisionHeader",
                        border: [false, false, true, false],
                    },
                    ],
                    [{
                        text: "Tax is payable on reverse charges : No",
                        colSpan: 3,
                        style: "divisionHeader",
                        border: [true, false, true, true],
                    },
                    {
                        text: "",
                        style: "divisionHeader",
                        border: [false, false, false, true],
                    },
                    {
                        text: "",
                        style: "divisionHeader",
                        border: [false, false, true, true],
                    },
                    ],)

                if (
                    Number(
                        response.data?.total_values.sgst_amount
                    ) >
                    Number(response.data?.total_values.igst_amount)
                ) {
                    taxationTableData.push([
                        {
                            text: "SGST :",
                            border: [false, false, false, false],
                            style: "fontSevenRight",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, false],

                            text:
                                Number(response.data?.loading_advice_taxes.find(
                                    (o) => o.tax_type === "SGST"
                                ).tax_percent).toFixed(2) + "%",
                            style: "taxDetailCenter",
                            width: "30%",
                        },
                        {
                            text: response.data?.total_values
                                .sgst_amount.toFixed(2),
                            border: [false, false, false, false],
                            // style: "fontEightRight",
                            style: "fontSevenRight",
                            width: "30%",
                        },
                    ]);
                    taxationTableData.push([

                        {
                            text: "CGST :",
                            border: [false, false, false, false],
                            style: "fontSevenRight",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, false],
                            text:
                                Number(response.data?.loading_advice_taxes.find(
                                    (o) => o.tax_type === "CGST"
                                ).tax_percent).toFixed(2) + "%",
                            style: "taxDetailCenter",
                            width: "30%",
                        },
                        {
                            // text: this?.state?.total_cgst_value || "",
                            text: response.data?.total_values
                                .cgst_amount.toFixed(2),
                            border: [false, false, false, false],
                            // style: "fontEightRight",
                            style: "fontSevenRight",
                            width: "30%",
                        },
                    ]);
                } else {
                    if (response.data?.total_values?.igst_amount !== 0) {
                        taxationTableData.push([
                            {
                                text: "IGST :",
                                border: [false, false, false, false],
                                style: "fontSevenRight",
                                width: "40%",
                            },
                            {
                                border: [false, false, false, false],
                                text:
                                    Number(response.data?.loading_advice_taxes.find(
                                        (o) => o.tax_type === "IGST"
                                    )?.tax_percent).toFixed(2) + "%",

                                style: "taxDetailCenter",
                                width: "30%",
                            },
                            {
                                // text: this?.state?.total_igst_value,
                                text: response.data?.total_values
                                    .igst_amount,
                                border: [false, false, false, false],
                                // style: "fontEightRight",
                                style: "fontSevenRight",
                                width: "30%",
                            },
                        ]);
                    } else {
                        taxationTableData.push([
                            {
                                text: "",
                                border: [false, false, false, false],
                                style: "taxDetailCenter",
                                width: "40%",
                            },
                            {
                                text: "",
                                border: [false, false, false, false],
                                style: "fontSixRight",
                                width: "30%",
                            },
                            {
                                text: "",
                                border: [false, false, false, false],
                                style: "fontSevenRight",
                                width: "30%",
                            },
                        ])
                    }
                }
                if (response.data?.total_values?.cess_amount !== 0) {
                    taxationTableData.push([
                        {
                            text: "CESS :",
                            border: [false, false, false, false],
                            style: "fontSevenRight",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, false],
                            text: `${Number(response.data?.cess_rate).toFixed(2) || 0} /MT`,
                            style: "taxDetailCenter",
                            width: "30%",
                        },
                        {
                            text: `${parseFloat(
                                response.data?.cess_amount || 0
                            ).toFixed(2)}`,
                            border: [false, false, false, false],
                            style: "fontSixRight",
                            width: "30%",
                        },
                    ])
                }

                if (response.data?.total_values?.tcs_amount !== 0) {
                    taxationTableData.push([

                        {
                            text: "TCS :",
                            border: [false, false, false, false],
                            style: "fontSevenRight",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, false],
                            text: `${Number(response.data?.tcs_percent) || 0
                                } %`,
                            style: "taxDetailCenter",
                            width: "30%",
                        },
                        {
                            text: `${parseFloat(
                                response.data?.total_values?.tcs_amount || 0
                            ).toFixed(2)}`,
                            border: [false, false, false, false],
                            style: "fontSevenRight",
                            width: "30%",
                        },
                    ]);
                }

                if (Number(response.data?.fright_advance) !== 0) {
                    taxationTableData.push([

                        {
                            text: "FREIGHT ADVANCE :",
                            border: [false, false, false, false],
                            style: "fontSevenLeft",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, false],
                            text: ``,
                            style: "taxDetailCenter",
                            width: "40%",
                        },
                        {
                            // text: parseFloat(this?.state?.total_freight_amount || 0).toFixed(2),
                            text: `${Number(response.data?.fright_advance).toFixed(2) || 0}`,
                            border: [false, false, false, false],
                            // style: "fontEightRight",
                            style: "fontSevenRight",
                            width: "30%",
                        },
                    ]);
                }

                if (response.data?.total_values
                    ?.round_off && response.data?.total_values
                        ?.round_off !== 0) {

                    taxationTableData.push([

                        {
                            text: "ROUND OFF :",
                            border: [false, false, false, true],
                            style: "fontSevenRight",
                            width: "40%",
                        },
                        {
                            border: [false, false, false, true],
                            text: "",
                            style: "fontSevenCenter",
                            width: "30%",
                        },
                        {
                            text: response.data?.total_values
                                ?.round_off?.toFixed(2),
                            border: [false, false, false, true],
                            style: "fontSevenRight",
                            width: "30%",
                        },

                    ])
                }


                tableData.push([
                    {
                        text: "Sr. No.",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    {
                        text: "Description and Speci. Of Goods",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    {
                        text: "HSN/SAC No.",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    {
                        text: "No. Of Packages",
                        style: ["itemsHeader", "center"],
                        border: [false, false, false, true],
                    },
                    {
                        text: "Total Qty.",
                        style: "itemsHeader",
                        alignment: "right",
                        border: [false, false, false, true],
                    },
                    {
                        text: "Unit",
                        style: "itemsHeader",
                        alignment: "center",
                        border: [false, false, false, true],
                    },
                    {
                        text: "Unit Rate (₹)",
                        style: "itemsHeader",
                        alignment: "right",
                        border: [false, false, false, true],
                    },
                    // {
                    //   text: "Net Rate",
                    //   style: "itemsHeader",
                    //   alignment: "right",
                    //   border: [false, false, false, true],
                    // },
                    {
                        text: `Freight Charge (${response.data?.freight_charge_rate} /MT)`,
                        style: "itemsHeader",
                        alignment: "right",
                        border: [false, false, false, true],
                    },
                    {
                        text: "Amount(Rs.)",
                        style: "itemsHeader",
                        alignment: "right",
                        border: [false, false, false, true],
                    },
                ]);

                var dd = {
                    info: {
                        title: `${response.data.la_number ? response.data.la_number : ""
                            }_${new Date().toISOString()}`,
                        author: "RIPL",
                        subject: "Loading Advice",
                        keywords: "Loading Advice",
                    },
                    pageMargins: [15, 160, 15, 225],
                    header: {
                        stack: headerContainer,
                        margin: [15, 25, 15, 0]
                    },
                    footer: {
                        stack: footerContainer,
                        margin: [15, 0, 0, 0]
                    },
                    background: function (currentPage, pageSize) {

                        return {
                            // image: AegleBase64,
                            // width: 200,
                            text: "<",
                            absolutePosition: { x: 15, y: pageSize.height / 2 - 4 },
                        }
                    },
                    content: [
                        {
                            columns: [
                                {
                                    style: "tableExample",
                                    table: {
                                        headerRows: 1,
                                        widths: [50, 50, 50, 50],
                                        body: [
                                            [
                                                {
                                                    text: "Bill To",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `${response.data.buyer.first_name
                                                        ? response.data.buyer.first_name
                                                        : ""
                                                        } ${response.data.buyer.last_name
                                                            ? response.data.buyer.last_name
                                                            : ""
                                                        }`,
                                                    style: "buyerName",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text:
                                                        response.data?.buyer?.customer_addresses?.address ?
                                                            (
                                                                (response.data?.buyer?.customer_addresses?.address
                                                                    ? response.data?.buyer?.customer_addresses?.address + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.city
                                                                    ? response.data?.buyer?.customer_addresses?.city + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.state
                                                                    ? response.data?.buyer?.customer_addresses?.state + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.country
                                                                    ? response.data?.buyer?.customer_addresses?.country + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.customer_addresses?.pincode
                                                                    ? response.data?.buyer?.customer_addresses?.pincode
                                                                    : "")
                                                            )
                                                            :
                                                            (
                                                                (response.data?.buyer?.gst_addresses?.door_number
                                                                    ? response.data?.buyer?.gst_addresses?.door_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.floor_number
                                                                    ? response.data?.buyer?.gst_addresses?.floor_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.building_name
                                                                    ? response.data?.buyer?.gst_addresses?.building_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.street
                                                                    ? response.data?.buyer?.gst_addresses?.street + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.location
                                                                    ? response.data?.buyer?.gst_addresses?.location + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.district_name
                                                                    ? response.data?.buyer?.gst_addresses?.district_name + ", "
                                                                    : "")
                                                                +
                                                                ((response.data?.buyer?.gst_addresses?.city_name &&
                                                                    response.data?.buyer?.gst_addresses?.district_name)
                                                                    ? ((response.data?.buyer?.gst_addresses?.city_name).trim().toUpperCase() !==
                                                                        (response.data?.buyer?.gst_addresses?.district_name).trim().toUpperCase())
                                                                        ? response.data?.buyer?.gst_addresses?.city_name + ", "
                                                                        : ""
                                                                    : response.data?.buyer?.gst_addresses?.city_name
                                                                        ? response.data?.buyer?.gst_addresses?.city_name + ", "
                                                                        : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.state_name
                                                                    ? response.data?.buyer?.gst_addresses?.state_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.buyer?.gst_addresses?.pincode
                                                                    ? response.data?.buyer?.gst_addresses?.pincode
                                                                    : "")
                                                            ),
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],

                                            [
                                                {
                                                    text: `STATE CODE : ${response.data.buyer?.gst_addresses?.state_code
                                                        ? response.data.buyer?.gst_addresses?.state_code?.substring(0, 2)
                                                        : response.data.buyer?.customer_addresses?.state_code?.substring(0, 2) || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `GSTIN : ${response.data.buyer.gstin_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `PAN NO: ${response.data.consignee.pan_card_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `MOBILE NO: ${response.data?.buyer
                                                        ?.customer_addresses?.contact ||
                                                        response.data?.buyer
                                                            ?.gst_addresses?.contact || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Email : ${response.data?.buyer
                                                        ?.customer_addresses?.email ||
                                                        response.data?.buyer
                                                            ?.gst_addresses?.email || ""
                                                        } `,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 4,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            // Consignee block start
                                            [
                                                {
                                                    text: "Consignee (Ship to)",
                                                    style: "invoiceDetailLable",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `${response.data.consignee.first_name || ""
                                                        } ${response.data.consignee.last_name || ""}`,
                                                    style: "buyerName",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text:
                                                        response.data?.consignee?.consignee_from_customer_addresses?.address ?
                                                            (
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.address
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.address + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.city
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.city + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.state
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.state + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.country
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.country + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_customer_addresses?.pincode
                                                                    ? response.data?.consignee?.consignee_from_customer_addresses?.pincode
                                                                    : "")
                                                            )
                                                            :
                                                            (
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.door_number
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.door_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.floor_number
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.floor_number + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.building_name
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.building_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.street
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.street + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.location
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.location + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.district_name
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.district_name + ", "
                                                                    : "")
                                                                +
                                                                ((response.data?.consignee?.consignee_from_gst_information_addresses?.city_name &&
                                                                    response.data?.consignee?.consignee_from_gst_information_addresses?.district_name)
                                                                    ? ((response.data?.consignee?.consignee_from_gst_information_addresses?.city_name).trim().toUpperCase() !==
                                                                        (response.data?.consignee?.consignee_from_gst_information_addresses?.district_name).trim().toUpperCase())
                                                                        ? response.data?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                                                                        : ""
                                                                    : response.data?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                                        ? response.data?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                                        : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.state_name
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.state_name + ", "
                                                                    : "")
                                                                +
                                                                (response.data?.consignee?.consignee_from_gst_information_addresses?.pincode
                                                                    ? response.data?.consignee?.consignee_from_gst_information_addresses?.pincode
                                                                    : "")
                                                            ),
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `STATE CODE : ${response.data.consignee?.consignee_from_gst_information_addresses?.state_code
                                                        ? response.data.consignee?.consignee_from_gst_information_addresses?.state_code?.substring(0, 2)
                                                        : response.data.consignee?.consignee_from_customer_addresses?.state_code?.substring(0, 2) || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 2,
                                                    alignment: "left",
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `GSTIN : ${response.data.consignee.gstin_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `PAN : ${response.data.consignee.pan_card_number || ""
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [true, false, false, false],
                                                },
                                                {},
                                                {
                                                    text: `MOBILE NO: ${response.data?.consignee
                                                        ?.consignee_from_customer_addresses?.contact ||
                                                        response.data?.consignee
                                                            ?.consignee_from_gst_information_addresses?.contact || ''
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                    border: [false, false, true, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `Email : ${response.data?.consignee
                                                        ?.consignee_from_customer_addresses?.email ||
                                                        response.data?.consignee
                                                            ?.consignee_from_gst_information_addresses?.email || ''
                                                        }`,
                                                    style: "buyerAddressDetails",
                                                    colSpan: 4,
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 4,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                                {},
                                                {},
                                            ],
                                            // contract block
                                            [
                                                {
                                                    text: "CONTRACT NO. :",
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    border: [true, true, false, true],
                                                    colSpan: 2,
                                                },
                                                {},
                                                {
                                                    text: response.data?.order
                                                        ?.order_number,
                                                    style: "buyerAddressDetails",
                                                    alignment: "left",
                                                    fillColor: "#f5f5f5",
                                                    color: "#333333",
                                                    border: [true, true, false, true],
                                                    colSpan: 2,
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "CONTRACT DATE :",
                                                    style: "buyerAddressDetails",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                },
                                                {},
                                                {
                                                    text:
                                                        response.data &&
                                                            response.data?.order
                                                                ?.order_validity
                                                            ? moment(
                                                                new Date(
                                                                    response.data?.order?.created_at
                                                                )
                                                            ).format("DD-MMM-YYYY")
                                                            : "",

                                                    style: "buyerAddressDetails",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                    color: "#333333",

                                                    alignment: "left",
                                                    colSpan: 2,
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "LA NO :",
                                                    style: "buyerAddressDetails",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                },
                                                {},
                                                {
                                                    text:
                                                        response.data &&
                                                            response.data?.la_number
                                                            ?
                                                            response.data?.la_number
                                                            : "",

                                                    style: "buyerAddressDetails",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                    color: "#333333",

                                                    alignment: "left",
                                                    colSpan: 2,
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "LA DATE :",
                                                    style: "buyerAddressDetails",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                    alignment: "left",
                                                    colSpan: 2,
                                                },
                                                {},
                                                {
                                                    text:
                                                        response.data &&
                                                            response.data?.created_at
                                                            ? moment(
                                                                new Date(
                                                                    response.data?.created_at
                                                                )
                                                            ).format("DD-MMM-YYYY")
                                                            : "",

                                                    style: "buyerAddressDetails",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                    color: "#333333",

                                                    alignment: "left",
                                                    colSpan: 2,
                                                },
                                                {},
                                            ],

                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },
                                {
                                    margin: [10, 5, 0, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [70, 70],
                                        body: [
                                            //transporter block
                                            [
                                                {
                                                    text: `TRUCK NO :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.truck_number || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `L.R. NO. :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.lr_number || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `L.R. DATE :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${moment(
                                                        response.data.lr_date
                                                    ).format("DD-MMM-YYYY") || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `TRANSPORTER NAME :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.transporter.acc_name || ""
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `MODE OF TRANSPORT :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.mode_of_transport || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `FREIGHT:`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.fright || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [

                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],


                                            [
                                                {
                                                    text: `FROM :`,
                                                    style: "invoiceDetailLable",
                                                    fillColor: "#f5f5f5",
                                                    border: [false, false, false, false],
                                                },
                                                {
                                                    text: `${response.data.plant.plant_city || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `TO :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.consignee?.consignee_from_customer_addresses?.city ||
                                                        (response.data?.consignee?.consignee_from_gst_information_addresses?.city_name
                                                            || response.data?.consignee?.consignee_from_gst_information_addresses?.district_name || '')
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `Place Of Supply :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.buyer.customer_addresses.city ||
                                                        (response.data?.buyer
                                                            ?.gst_addresses?.city_name || response.data?.buyer
                                                                ?.gst_addresses?.district_name || '')
                                                        }`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [

                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: `AGENT NAME. :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: response.data?.customer
                                                        ?.customer_type === "BROKER" || response.data?.customer
                                                            ?.customer_type === "DISTRIBUTOR"
                                                        ? response.data?.customer
                                                            ?.first_name + " " +
                                                        response.data?.customer
                                                            ?.last_name
                                                        : "",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `PARTY REF NO. :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: response.data?.order
                                                        ?.party_ref_number || "",

                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `PARTY REF DATE :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: moment(
                                                        response.data?.order
                                                            ?.party_ref_date
                                                    ).format("DD-MMM-YYYY") || "",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `LC NO. :`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.lc_number || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: `LC DATE:`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: `${response.data.lc_date && moment(response.data.lc_date).format("DD-MMM-YYYY") || ""}`,
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                            [
                                                {
                                                    text: "E-WAY BILL NO. : ",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: response.data?.EwbNo || response.data?.EWayBill || " ",

                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],
                                            [
                                                {
                                                    text: "E-WAY BILL DATE :",
                                                    style: "invoiceDetailLable",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                                {
                                                    text: moment(response.data?.EwbDt || response.data?.Date, [
                                                        "DD/MM/YYYY hh:mm:ss A",
                                                        "YYYY-MM-DD hh:mm:ss A",
                                                    ]).format(
                                                        "DD-MMM-YYYY"
                                                    ) === "Invalid date" ? " " : moment(response.data?.EwbDt || response.data?.Date, [
                                                        "DD/MM/YYYY hh:mm:ss A",
                                                        "YYYY-MM-DD hh:mm:ss A",
                                                    ]).format(
                                                        "DD-MMM-YYYY"
                                                    ),

                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    border: [false, false, false, false],
                                                    fillColor: "#f5f5f5",
                                                },
                                            ],


                                            [

                                                {
                                                    text: "",
                                                    colSpan: 2,
                                                    border: [false, false, false, false],
                                                },
                                                {},
                                            ],
                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },
                                {
                                    margin: [-55, 5, 0, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [120, 120],
                                        body: [

                                            [
                                                {
                                                    text: `INVOICE NO. : ${""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    fillColor: "#f5f5f5",
                                                    margin: [5, 0, 0, 0],
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                            ],
                                            [
                                                {
                                                    text: `INVOICE DATE : ${""}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    fillColor: "#f5f5f5",
                                                    margin: [5, 0, 0, 0],
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                            ],
                                            [
                                                {
                                                    text: `TYPE : ${"TAX INVOICE PREVIEW"}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    fillColor: "#f5f5f5",
                                                    margin: [5, 0, 0, 0],
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                            ],
                                            [
                                                {
                                                    text: `CATEGORY : ${response.data?.tran_supply_type}`,
                                                    style: "invoiceDetailLable",
                                                    color: "#333333",
                                                    fillColor: "#f5f5f5",
                                                    margin: [5, 0, 0, 0],
                                                    alignment: "left",
                                                    border: [true, false, true, true],
                                                },
                                            ],
                                        ],
                                    },
                                    layout: {
                                        hLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                        vLineColor: function (i, node) {
                                            return "#f5f5f5";
                                        },
                                    },
                                },

                            ],
                        },
                        {
                            margin: [5, 5, 0, 0],
                            table: {
                                headerRows: 1,
                                widths:
                                    [25, 130, 45, 55, 35, 35, 45, 55, 45],
                                body: tableData,
                            },
                        },
                        response.data.loading_advice_items.map((e, i) => ({
                            margin: [5, -2, 0, 0],
                            table: {
                                headerRows: 1,
                                widths: [25, 130, 45, 55, 35, 35, 45, 55, 45],
                                body: [
                                    [
                                        {
                                            text: i + 1,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        // {
                                        //     text: `${e.item.item_code}`,
                                        //     style: "itemSubTitle",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            text: `${e.item.item_name || ""}`,
                                            style: "itemSubTitle",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: `${e.item.hsn_code || ""}`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text:
                                                `${e.actual_section_count ? (Number(e.actual_section_count).toFixed(0) + " " + e.section_name) : ""}`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        // {
                                        //     text: `${Number(e.item_qty).toFixed(3)}`,
                                        //     style: "itemNumber",
                                        //     border: [false, false, false, false],
                                        // },

                                        {
                                            text: `${e.alloted_weight == 0 ? "" : e.alloted_weight}`,
                                            style: "itemNumberRightAligned",
                                            border: [false, false, false, false],
                                        },
                                        // {
                                        //     text: `${e.actual_section_count ? (Number(e.actual_section_count).toFixed(0) + " " + e.section_name) : ""}`,
                                        //     style: "itemNumber",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            text: `MT`,
                                            style: "itemNumber",
                                            border: [false, false, false, false],
                                        },
                                        // {
                                        //     text: `${Number(e.item.rate_difference).toFixed(2)}`,
                                        //     style: "itemNumber",
                                        //     border: [false, false, false, false],
                                        // },
                                        {
                                            text: `${e.item_rate == 0 ? "" : e.item_rate.toFixed(2)}`,
                                            style: "itemNumberRightAligned",
                                            border: [false, false, false, false],
                                        },

                                        {
                                            text:
                                                // `${Number((Number(response.data?.freight_charge_rate).toFixed(2)) * Number(e.alloted_weight) > 0 ? (Number(e.alloted_weight).toFixed(2)) : (Number(e.item_qty).toFixed(2))).toFixed(2)}`,
                                                (response.data?.freight_charge_rate * e.alloted_weight).toFixed(2),
                                            style: "itemNumberRightAligned",
                                            border: [false, false, false, false],
                                        },
                                        {
                                            style: "itemNumberRightAligned",
                                            text: `${Number(e.item_taxable_amount).toFixed(2)}`,
                                            border: [false, false, false, false],
                                        },
                                    ],
                                ],
                            },
                        })),

                        {
                            margin: [5, 0, 0, 0],
                            table: {
                                headerRows: 0,
                                widths: [50, 223, 35, 143, 55],
                                //heights: [40, 40, 40],
                                body: [
                                    [
                                        {
                                            text: `Amount in words :`,
                                            border: [true, true, false, true],
                                            style: "fontSixLeft",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text:
                                                `${numberConvertor(
                                                    response.data.total_values?.taxable_amount, "",
                                                    true)}`
                                            ,

                                            border: [false, true, false, true],
                                            style: "fontSixLeft",
                                            margin: [-8, 0, 0, 0],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${response.data.total_values?.alloted_weight.toFixed(3) || 0}`,
                                            border: [false, true, false, true],
                                            style: "itemNumberRightAligned",
                                            margin: [12, 0, 0, 0],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `Total Value of Supply:`,
                                            border: [false, true, false, true],
                                            style: "fontSevenRight",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            text: `${response.data.total_values?.taxable_amount.toFixed(2)}`,
                                            border: [false, true, true, true],
                                            style: "fontSevenRight",
                                            alignment: "right",
                                            fillColor: "#f5f5f5",
                                        },
                                    ],
                                ],
                            }
                        },
                        {
                            margin: [5, 0, 5, 0],
                            columns: [
                                {
                                    text: `Total Tax Value (in words) : ${("",
                                        response.data?.total_values
                                            ?.sgst_amount >
                                            response.data?.total_values
                                                ?.igst_amount
                                            ? numberConvertor((Number(
                                                response.data?.total_values?.sgst_amount
                                            ) +
                                                Number(
                                                    response.data?.total_values?.cgst_amount
                                                )
                                            ).toFixed(2), "", true)
                                            : numberConvertor(
                                                response.data?.total_values
                                                    ?.igst_amount,
                                                "",
                                                true
                                            ))
                                        }.`,
                                    // style: "fontEightLeft",
                                    style: "fontSixLeft",
                                    margin: [0, 5, 10, 5],
                                },
                                {
                                    margin: [0, 0, 5, 0],
                                    table: {
                                        headerRows: 0,
                                        widths: [65, "*", 60],
                                        body: taxationTableData,
                                    },
                                },
                            ],
                        },

                        {
                            margin: [5, 0, 0, 0],

                            columns: [
                                {
                                    text: `REMARK : ${response.data?.remark || ""
                                        }\nPAYMENT TERMS : ${response.data?.payment_method
                                            ?.payment_method_name || ""
                                        }`,
                                    style: "fontSixLeft",
                                    // style: "fontSevenLeft",
                                    margin: [0, 0, 0, 5],
                                },
                            ],
                        },

                        {
                            margin: [5, 0, 0, 0],
                            table: {
                                headerRows: 0,
                                widths: [70, 335, 55, 55],
                                body: [
                                    [
                                        {
                                            text: "Total Amount in words :",
                                            border: [true, true, false, true],
                                            bold: true,
                                            style: "fontSixLeft",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            border: [false, true, false, true],
                                            text: `${numberConvertor(
                                                response.data?.total_values
                                                    ?.total_item_value,
                                                "",
                                                true
                                            )}`,
                                            style: "fontSixLeft",
                                            margin: [-13, 0, 0, 0],
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            border: [false, true, false, true],
                                            text: "Total Amount ₹",
                                            style: "fontSevenLeft",
                                            // style: "fontSevenLeft",
                                            fillColor: "#f5f5f5",
                                        },
                                        {
                                            border: [false, true, true, true],
                                            text: response.data?.total_values
                                                ?.total_item_value?.toFixed(2) || "",
                                            style: "fontSevenRight",
                                            fillColor: "#f5f5f5",
                                        },
                                    ],
                                ],
                            }
                        },
                        {
                            columns: [
                                {
                                    text: " ",
                                    style: "fontSixLeft",
                                    margin: [0, 0, 0, 5],
                                    border: [false, false, true, false],
                                },
                            ],
                        },
                    ],
                    styles: {
                        tableExample: {
                            margin: [0, 5, 0, 5],
                        },
                        // Taxation Data
                        fontSixLeft: {
                            alignment: "left",
                            fontSize: 6,
                            margin: [0, 0, 0, 0],
                        },
                        fontSixRight: {
                            alignment: "right",
                            fontSize: 6,
                            margin: [0, 0, 0, 0],
                        },
                        fontSixCenter: {
                            alignment: "center",
                            fontSize: 6,
                            margin: [0, 3, 0, 3],
                        },
                        fontSevenLeft: {
                            alignment: "left",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        fontSevenRight: {
                            alignment: "right",
                            fontSize: 7,
                            margin: [0, 0, 0, 0],
                        },
                        taxDetailCenter: {
                            alignment: "center",
                            fontSize: 7,
                        },
                        fontSevenCenter: {
                            alignment: "center",
                            fontSize: 7,
                            margin: [0, 3, 0, 3],
                        },
                        fontSeventeenCenter: {
                            alignment: "center",
                            fontSize: 17,
                            margin: [0, 3, 0, 3],
                        },
                        fontEightRight: {
                            alignment: "right",
                            fontSize: 8,
                            margin: [0, 0, 0, 0],
                            bold: true,
                        },
                        fontEightLeft: {
                            alignment: "left",
                            fontSize: 8,
                            bold: true,
                            margin: [0, 0, 0, 0],
                        },
                        buyerName: {
                            bold: true,
                            color: "#333333",
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                            margin: [0, 0, 0, 3],
                        },
                        buyerAddressDetails: {
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                        },
                        // Invoice Detail
                        invoiceDetailLable: {
                            color: "#333333",
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                            //justifyContent: "space-between"
                        },
                        invoiceDetailLableOverview: {
                            color: "#333333",
                            // fontSize: 7,
                            fontSize: 6,
                            alignment: "left",
                            bold: true
                        },
                        invoiceValuesLabel: {
                            color: "#333333",
                            fontSize: 6,
                            alignment: "right",
                        },
                        itemsHeader: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                        },
                        itemsHeaderItemAndHsn: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            //alignment: "center",
                        },
                        itemsHeaderAmnt: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "right",
                        },
                        divisionHeader: {
                            fontSize: 8,
                            alignment: "left",
                        },
                        itemsHeaderUnit: {
                            //padding: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "center",
                        },
                        itemsHeaderSubTitle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "left",
                        },
                        itemHeaderRightAligned: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            bold: true,
                            fillColor: "#f5f5f5",
                            alignment: "right",
                        },
                        itemSubTitle: {
                            //margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                        },
                        OEValuestyle: {
                            fontSize: 8,
                            margin: [1, 1, 0, 0],
                        },
                        OEstyle: {
                            fontSize: 10,
                        },
                        itemNumber: {
                            //margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "center",
                        },
                        codeStyle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "left",
                        },
                        itemNumberStyle: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "center",
                        },
                        amountNumber: {
                            margin: [0, 2, 0, 0],
                            // fontSize: 8,
                            fontSize: 7,
                            alignment: "right",
                        },
                        itemNumberBelow: {
                            margin: [0, 2, 0, 2],
                            // fontSize: 8,
                            fontSize: 6,
                            alignment: "center",
                            borderColor: "#fff"
                        },
                        itemNumberRightAligned: {
                            // margin: [10, 0, 0, 0],
                            // fontSize: 8,
                            fontSize: 6.5,
                            alignment: "right",
                        },
                        itemRowEvenNumber: {
                            margin: [0, 2, 0, 2],
                            fontSize: 8,
                            alignment: "center",
                        },
                        tableHeader: {
                            fontSize: 9,
                            bold: true,
                        },
                        tableDetailValues: {
                            fontSize: 9,
                        },
                        disclaimerStyle: {
                            alignment: "left",
                            fontSize: 7,
                            bold: true,
                            // margin: [0, 5, 0, 0],
                        },
                        forSignatureCenter: {
                            alignment: "center",
                            fontSize: 11
                        },
                        SignatureCenter: {
                            alignment: "center",
                            fontSize: 9
                        },
                    },

                };

                footerContainer.push([
                    {
                        text: "Disclaimer : ",
                        style: "disclaimerStyle"
                    },
                    {
                        columns: [
                            [

                                {
                                    text: "(1)  We declare that the particulars given above are true and correct.",
                                    style: "fontSevenLeft",
                                    width: 310,
                                    // preserveLeadingSpaces: true
                                    // border: [true, true, true, true]
                                },
                                {
                                    text: "(2)  The amount indicated / represents the price actually charged and that there is not flow of\n\u200B\t\tadditional consideration directly or indirectly from the buyer.",
                                    style: "fontSevenLeft",
                                    width: 310,
                                    preserveLeadingSpaces: true
                                    // border: [true, true, true, true]
                                },

                                {
                                    text: "Notes :",
                                    style: "disclaimerStyle",
                                    border: [false, false, false, false],

                                },
                                {
                                    text: "(1)  Payment to be strictly on scheduled time, otherwise interest will be charged @18% P.A.",
                                    style: "fontSevenLeft",
                                    border: [false, false, false, false],
                                    width: 310
                                },
                                {
                                    text: "(2)  We are not responsible for any breakage and shortage once the goods leave our \n\u200B\t\tpremises/handed over to Railway or Motor Transport or other carriers.",
                                    style: "fontSevenLeft",
                                    border: [false, false, false, false],
                                    width: 310
                                },
                                {
                                    text: "(3)  Any variation in our supply should bought to our notice within 3 days from the receipt\n\u200B\t\tof the goods.",
                                    style: "fontSevenLeft",
                                    border: [false, false, false, false],
                                    width: 310
                                },
                                {
                                    text: "(4)  Subject to Raipur Jurisdiction.",
                                    style: "fontSevenLeft",
                                    border: [false, false, false, false],
                                    width: 310
                                },
                                {
                                    text: "(5)  Tax Invoice under Section 31 with Rule 46 of CGST Act 2017",
                                    style: "fontSevenLeft",
                                    border: [false, false, false, false],
                                    width: 310
                                },

                            ],
                            [
                                {
                                    text: `For, ${response.data?.plant?.plant_name}`,
                                    style: "forSignatureCenter",
                                    border: [false, false, false, false],
                                    margin: [0, 25, 0, 0],
                                    color: "blue",
                                    width: 300
                                },
                                {
                                    text: `Authorized Signature`,
                                    style: "SignatureCenter",
                                    border: [false, false, false, false],
                                    margin: [35, 50, 0, 0],
                                    width: 300
                                },
                            ]
                        ]
                    },
                    {
                        text: "Bank Details :",
                        style: "disclaimerStyle",
                        border: [false, false, false, false],
                        margin: [0, -9, 0, 0]
                    },
                    {
                        text: `Bank : ${response.data?.plant?.bank_name}`,
                        style: "fontSevenLeft",
                        border: [false, false, false, false],
                        // bold: true,
                    },
                    {
                        text: `A/C # : ${response.data?.plant?.plant_account_no}`,
                        style: "fontSevenLeft",
                        border: [false, false, false, false],
                        // bold: true,
                    },
                    {
                        text: `Branch and IFSC Code : ${response.data?.plant?.branch_name} and ${response.data?.plant?.plant_account_ifsc}`,
                        style: "fontSevenLeft",
                        border: [false, false, false, false],
                        // bold: true,
                    },
                    {
                        columns: [
                            {
                                text: "Corporate Office :",
                                style: "fontSevenLeft",
                                border: [true, true, true, true],
                                bold: true,
                                width: 57
                            },
                            {
                                text: `“Vrindavan”, Near IDBI Bank, Civil Lines, Raipur (C.G.) - 492001`,
                                style: "fontSevenLeft",
                                border: [true, true, true, true],
                                width: 200

                            }
                        ],
                        width: 300
                    },

                    {
                        margin: [0, 10, 0, 0],

                        columns: [
                            {
                                width: "25%",
                                text: "E & OE.",
                                style: "OEstyle"
                            },
                            {
                                columns: [
                                    {
                                        width: "16%",
                                        text: `Driver /`,
                                        style: "OEstyle"
                                    },
                                    {
                                        image: telephone,
                                        width: 9,
                                        height: 9,
                                        margin: [0, 1.5, 0, 0],
                                        alignment: "left",
                                        border: [false, false, false, false],
                                    },
                                    {
                                        width: "2%",
                                        text: " "
                                    },
                                    {
                                        style: "OEValuestyle",
                                        width: "52%",
                                        text: `${" "} : ${response.data?.driver_name} / ${response.data?.driver_contact_number}`
                                    }
                                ]
                            },
                            {
                                //margin: [4, 0, 0, 0],
                                columns: [
                                    {
                                        width: "38%",
                                        text: `Prepared/Checked By :`,
                                        style: "OEValuestyle"
                                    },

                                    {
                                        style: "OEValuestyle",
                                        width: "60%",
                                        text: ` ${user}`,
                                    }
                                ]
                            },
                        ]
                    },
                    { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },
                    response.data?.plant?.logo_banner ?
                        {
                            image: await getBase64ImageFromURL("banner", response.data?.plant?.logo_banner),
                            width: 500,
                            height: 22,
                            alignment: "center",
                            margin: [0, 2, 0, 2],
                            // border: [false, false, false, false]
                        } :
                        {
                            text: "",
                            margin: [0, 26, 0, 0]
                        },
                    { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },
                    {
                        text: "This is computer generated tax invoice preview",
                        alignment: "center",
                        fontSize: 7,
                        margin: [0, 2, 0, 0]
                    }
                ])
                if (action === "PRINT") {
                    pdfMake.createPdf(dd).open();
                } else {
                    pdfMake
                        .createPdf(dd)
                        .download(
                            `${response.data.la_number}_${new Date().toISOString()}.pdf`
                        );
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
};


const getBase64ImageFromURL = (ford, url) => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");
            //console.log(ford, dataURL)
            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    });
}
export {
    handlePrintInvoicingpreview
}
