import UserAndPermissionsWeb from './web';

import UserAndPermissionsMobile from './mobile';

import UsersAndPermissionDetails from './web/UserAndPermisionDetails';

import ManageAccessArea from './web/ManageAccessArea';

import ManageInternalUsers from './web/ManageInternalUsers';

import ManageRolesAndPermissions from './web/ManageRolesAndPermissions';

export default UserAndPermissionsWeb;

export {
  UserAndPermissionsMobile,
  UsersAndPermissionDetails,
  ManageAccessArea,
  ManageInternalUsers,
  ManageRolesAndPermissions,
};
