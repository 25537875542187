import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';

function WebSocketWrapper({ url, onMessage }) {
  const [messages, setMessages] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(url);

  useEffect(() => {
    if (lastMessage !== null) {
      setMessages((prevMessages) => [...prevMessages, lastMessage.data]);
    }
  }, [lastMessage, onMessage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onMessage(messages);
    }, 3000);

    return () => clearTimeout(timer);
  }, [messages]);
  return null; // This component doesn't render anything
}

export default WebSocketWrapper;