import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Create from "../Create";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  b2bDispatchCreateMainBodyWeb: {
    width: "50%",
  },
};
class DispatchCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      classes,
      orders,
      items,
      gateInDate,
      selectedPlant,
      truckNumber,
      truckCapacity,
      transporterName,
      transporterContactNumber,
      driverName,
      driverContactNumber,
      buyerName,
      consigneeName,
      paymentMethod,
      handleTextChange,
      handleDateChange,
      LASubmitLoader,
      customerRemark,
      subContract,
      selectedEditSubContractNumber,
      subContractNumber,
      subContractDropdownList,
      subContractDropdownListLoader,
      plantSubContractEnabled,
      ordersListLoader,
      selectedSubContractDetail,
      selectedSubContractPayment
    } = this.props;

    // console.log(this.props.showLACreateError)
    return (
      <WebLayout appBar dispatchSelect>
        <div className={classes.b2bDispatchCreateMainBodyWeb}>
          <Create
            {...this.props}
            webView
            orders={orders}
            items={items}
            handleTextChange={handleTextChange}
            handleDateChange={handleDateChange}
            gateInDate={gateInDate}
            selectedPlant={selectedPlant}
            truckNumber={truckNumber}
            truckCapacity={truckCapacity}
            transporterName={transporterName}
            transporterContactNumber={transporterContactNumber}
            driverName={driverName}
            driverContactNumber={driverContactNumber}
            buyerName={buyerName}
            consigneeName={consigneeName}
            paymentMethod={paymentMethod}
            customerRemark={customerRemark}
            handleSelectBuyerName={this.props.handleSelectBuyerName}
            handleSelectConsigneeName={this.props.handleSelectConsigneeName}
            handleSelectPaymentMethod={this.props.handleSelectPaymentMethod}
            selectedPlantAndItem={this.props.selectedPlantAndItem}
            LASubmitLoader={LASubmitLoader}
            laDetails={this.props?.laDetails}
            mode={this.props?.mode}
            handleEditData={this.props?.handleEditData}
            handleItemEdit={this.props?.handleItemEdit}
            fetchLADetails={this.props?.fetchLADetails}
            fetchOrderList={this.props?.fetchOrderList}
            handleSubcontractFormDetail={this.props.handleSubcontractFormDetail}
            subContract={subContract}
            selectedEditSubContractNumber={selectedEditSubContractNumber}
            subContractNumber={subContractNumber}
            subContractDropdownList={subContractDropdownList}
            subContractDropdownListLoader={subContractDropdownListLoader}
            plantSubContractEnabled={plantSubContractEnabled}
            ordersListLoader={ordersListLoader}
            selectedSubContractDetail={selectedSubContractDetail}
            selectedSubContractPayment={selectedSubContractPayment}
          />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(DispatchCreate);
