import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Checkbox } from "@material-ui/core";
import { grey, secondaryBlueText } from "../../../../constants/internal/colors";

import ManageCustomerDealerDistributor from "./ManageCustomerDealerDistributor";
import { pxToEm } from "../../../../methods";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
});
const GreenCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function ManageCustomerAccountDetails(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation}>
          Provide the general company related information.
        </Typography>
        <Typography className={classes.fillingInformation}>
          &nbsp;Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <div className={classes.dataDisplay}>
          <div className={classes.dataFieldsForCheckbox}>
            <Typography className={classes.titleLabel}>
              ACOUNT VERIFIED
            </Typography>
            <GreenCheckbox
              checked={props.accountVerify}
              onChange={(evt) => {
                props.handleAccountVerifyChange(evt);
              }}
              name="accountVerify"
              disabled={props.customerOperation === "view"}
            />
          </div>
          {/* {props.customerType === "BROKER" ||
          props.customerType === "DISTRIBUTOR" ? (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                BROKERAGE PERCENT
              </Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.brokeragePercent}
                </Typography>
              ) : (
                <TextField
                  className={classes.formControl}
                  label="Brokerage Percent"
                  variant="outlined"
                  size="small"
                  type="number"
                  autoComplete="off"
                  value={props.brokeragePercent}
                  onChange={props.handleChange("brokeragePercent")}
                />
              )}
            </div>
          ) : null} */}
        </div>
      </div>
      {props.customerType === "BROKER" ||
        props.customerType === "DISTRIBUTOR" ||
        props.customerType === "CONSUMER" ? null : (
        <div>
          <ManageCustomerDealerDistributor
            customerType={props.customerType}
            customerDealersDistributors={props.customerDealersDistributors}
            allDealers={props.allDealers}
            allDistributors={props.allDistributors}
            customerSelectedDealerOrDistributor={
              props.customerSelectedDealerOrDistributor
            }
            handleChangeCustomerSelectedDealerOrDistributor={
              props.handleChangeCustomerSelectedDealerOrDistributor
            }
            handleSaveDealerOrDistributor={props.handleSaveDealerOrDistributor}
            handleRemoveClickInDealerOrDistributorTable={
              props.handleRemoveClickInDealerOrDistributorTable
            }
            dealerDistributorName={props.dealerDistributorName}
            dealerDistributorId={props.dealerDistributorId}
            dealerDistributorGstinNumber={props.dealerDistributorGstinNumber}
            dealerDistributorPhoneNumber={props.dealerDistributorPhoneNumber}
            saveDealerDistributorLoader={props.saveDealerDistributorLoader}
            handleRemoveDealerDistributorCircularLoadingShow={
              props.handleRemoveDealerDistributorCircularLoadingShow
            }
            removeDealerDistributorLoader={props.removeDealerDistributorLoader}
          />
        </div>
      )}
    </>
  );
}
