import { useEffect, useState } from "react";
import {
  checkApiError,
  handleSnackbarMessageBox,
  startOFMonth,
  endOFMonth,
} from "../../../../methods";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../common/SnackbarComponent";
import { BottomPagination, LineChartTable, SimpleTable, ToggleButton, WidgetTop } from "./commanWidget";
import moment from "moment";

const DashboardCustomers = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mounted, setMounted] = useState(false);
  const [itemcategoryIDSelected, setItemcategoryIDSelected] = useState([])
  const [plantIDSelected, setPlantIDSelected] = useState([])
  const [toggleButton, setToggleButton] = useState('customer')
  const [selectedDate, setSelectedDate] = useState({
    label: 'M',
    startDate: startOFMonth,
    endDate: endOFMonth
  })
  const [customerBrokerData, setCustomerBrokerData] = useState([])
  const [showProgress, setShowProgress] = useState(false);
  const [graphApiData, setGraphApiData] = useState({ data: [], graphType: '' })
  const [cancelTokenSource, setCancelTokenSource] = useState({
    tableDataApi: null,
    graphDataApi: null,
  });
  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: "",
    snackbarType: ""
  })
  const [tableRowSelection, setTableRowSelection] = useState(null)
  const [dropdownSelection, setDropdownSelection] = useState('contract_qty')
  const [page, setPage] = useState(1)
  const [pageDetail, setPageDetail] = useState({
    total_pages: null,
    current_page: null,
    next_page: null,
    previous_page: null,
    count: null
  })

  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: ""
    })
  };

  const handleCatchError = (error) => {
    handleOpenSnackbar(
      handleSnackbarMessageBox(checkApiError(error?.response)),
      "error"
    );
    setTimeout(() => {
      handleCloseSnackbar();
    }, 4000);
  }

  const handleTableData = (fromDate, toDate, itemCategoryIdList, plantIdList, filterName) => {
    const newCancelTokenSource = handleCancelToken('tableDataApi')
    setShowProgress(true)
    const payload = {
      from_date: fromDate,
      to_date: toDate,
      filter_name: filterName,
      item_category: itemCategoryIdList,
      plant: plantIdList
    }
    axios.post(
      page === 1 ? `${APIROUTES.CUSTOMER_BROKER_DATA}` : `${APIROUTES.CUSTOMER_BROKER_DATA}?page=${page}`,
      payload,
      { cancelToken: newCancelTokenSource.token, }
    )
      .then((response) => {
        if (response.status === 200) {
          setPageDetail({
            total_pages: response.data.total_pages,
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            previous_page: response.data.previous_page,
            count: response.data.count
          })
          let data = response.data[filterName === 'customer' ? 'Customer Wise Data' : 'Broker Wise Data'];
          setCustomerBrokerData(data)
          setShowProgress(false)
        }
      })
      .catch((error) => {
        setShowProgress(false)
        if (axios.isCancel(error)) {
        } else {
          handleCatchError(error)
        }
      });
  }

  const handleGraphWiseData = (fromDate, toDate, itemID, plantID, toggle, dropdown, rowID, graphType) => {
    if (rowID) {
      const newCancelTokenSource = handleCancelToken('graphDataApi')
      const payload = {
        from_date: fromDate,
        to_date: toDate,
        item_category: itemID,
        plant: plantID,
        filter_name: dropdown,
        customer: rowID
      }
      const url = toggle === 'customer'
        ? `${APIROUTES.CUSTOMER_GRAPH_DATA}`
        : `${APIROUTES.BROKER_GRAPH_DATA}`
      axios.post(url, payload, { cancelToken: newCancelTokenSource.token, })
        .then((response) => {
          if (response.status === 200) {
            let data = response.data[toggle === 'customer'
              ? dropdown === 'contract_qty'
                ? 'Contract Quantity Customer Graph Data'
                : dropdown === 'net_sales_qty'
                  ? 'Net Sales Quantity Customer Graph Data'
                  : dropdown === 'net_sales_cr' && 'Net Sales Cr Customer Graph Data'
              : dropdown === 'contract_qty'
                ? 'Contract Quantity Broker Graph Data'
                : dropdown === 'net_sales_qty'
                  ? 'Net Sales Quantity Broker Graph Data'
                  : dropdown === 'net_sales_cr' && 'Net Sales Cr Broker Graph Data'
            ];
            setGraphApiData({ data: data, graphType: graphType })
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
          } else {
            handleCatchError(error)
          }
        });
    }
  }

  const dateChange = (data) => {
    setPage(1)
    setSelectedDate({
      label: data.label,
      startDate: data.startDate,
      endDate: data.endDate
    })
  }

  const handleToggle = (index, toogleKey) => {
    setPage(1)
    setToggleButton(toogleKey)
  }

  const handleFilterSelection = (idList, filter) => {
    setPage(1)
    if (filter === 'item_category') {
      setItemcategoryIDSelected(idList)
    }
    else {
      setPlantIDSelected(idList)
    }
  }

  const handlTableRowSelection = (rowID) => {
    setTableRowSelection(rowID)
  }
  const pageChangeCallBack = (newPage) => {
    setPage(newPage)
  }

  const handlDropdownSelection = (data) => {
    setPage(1)
    setDropdownSelection(data?.graphValue)
  }

  const handleCancelToken = (key) => {
    if (cancelTokenSource[key]) {
      cancelTokenSource[key].cancel('Operation canceled');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(prevValue => { return { ...prevValue, [key]: newCancelTokenSource } });
    return newCancelTokenSource;
  }


  useEffect(() => {
    if (mounted) {
      handleTableData(selectedDate.startDate,
        selectedDate.endDate,
        itemcategoryIDSelected,
        plantIDSelected,
        toggleButton)
      handleGraphWiseData(selectedDate.startDate,
        selectedDate.endDate,
        itemcategoryIDSelected,
        plantIDSelected,
        toggleButton,
        dropdownSelection,
        tableRowSelection,
        selectedDate.label)
    }
  }, [selectedDate, itemcategoryIDSelected, plantIDSelected, toggleButton, page])

  useEffect(() => {
    if (mounted) {
      handleGraphWiseData(selectedDate.startDate,
        selectedDate.endDate,
        itemcategoryIDSelected,
        plantIDSelected,
        toggleButton,
        dropdownSelection,
        tableRowSelection,
        selectedDate.label)
    }
  }, [tableRowSelection, dropdownSelection,])

  useEffect(() => {
    handleTableData(selectedDate.startDate,
      selectedDate.endDate,
      itemcategoryIDSelected,
      plantIDSelected,
      toggleButton)
    handleGraphWiseData(selectedDate.startDate,
      selectedDate.endDate,
      itemcategoryIDSelected,
      plantIDSelected,
      toggleButton,
      dropdownSelection,
      tableRowSelection,
      selectedDate.label)
    setPage(1)
    setMounted(true)
    return () => {
      setMounted(false)
    };
  }, [])

  useEffect(() => {
    // Cleanup function to cancel the previous request when component unmounts
    return () => {
      Object.entries(cancelTokenSource)
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => (cancelTokenSource[key].cancel("Operation canceled")));
    };
  }, [cancelTokenSource]);

  const DATEBUTTONS = [
    { label: 'Y', function: dateChange },
    { label: 'M', function: dateChange, defaultSelected: true },
    { label: 'W', function: dateChange },
    { label: 'T', function: dateChange },
    { label: 'date_range', function: dateChange },
  ]

  const TABLEDATA = [
    { label: '#', key: 'customer', id: 'customer', minWidth: 20 },
    { label: toggleButton === 'customer' ? 'Customer Name' : 'Broker Name', key: 'customer_name', id: 'no_header', minWidth: 180 },
    { label: 'Contract Qty', key: 'total_booked_quantity', defaultSelectedHeader: true, minWidth: 130 },
    { label: 'Net Sales Qty', key: 'net_sales_qty', minWidth: 130 },
    { label: 'Net Sales (Cr)', key: 'net_sales_cr', minWidth: 130 },
  ]

  const GRAPHDROPDOWNDATA = [
    { label: 'Contract Qty', key: 'total_booked_quantity', graphValue: 'contract_qty' },
    { label: 'Net Sales Qty', key: 'net_sales_qty', graphValue: 'net_sales_qty' },
    { label: 'Net Sales (Cr)', key: 'net_sales_cr', graphValue: 'net_sales_cr' },
  ]

  const TOGGLEFIELD = [
    { label: 'Customer', key: 'customer', function: handleToggle },
    { label: 'Broker', key: 'broker', function: handleToggle },
  ]

  return (
    <div>
      <WidgetTop
        {...props}
        widgetTitle='Customers'
        widgetSubtitle="Customer-Broker Details"
        isMobile={isMobile}
        plantFilter={true}
        itemCategoryFilter={true}
        handleFilterSelection={handleFilterSelection}
        itemCategoryDropdownList={props?.itemCategoryDropdownList}
        plantDropdownList={props?.plantDropdownList}
        dateButtons={DATEBUTTONS}
        selectedDate={selectedDate?.label}
        pdfCsvButton={false}
      />
      <ToggleButton
        isMobile={isMobile}
        toggleField={TOGGLEFIELD}
      />
      <SimpleTable
        {...props}
        isMobile={isMobile}
        tableData={TABLEDATA}
        showProgress={showProgress}
        rows={customerBrokerData}
        backgroundOddEven={false}
        handlTableRowSelection={handlTableRowSelection}
        selectedDate={selectedDate?.label}
        widget="dashboard_customer_broker"
        page={page}
        pageDetail={pageDetail}
        currentPageRows={customerBrokerData.length}
      />
      <BottomPagination
        page={page}
        pageChangeCallBack={pageChangeCallBack}
        pageDetail={pageDetail}
        currentPageRows={customerBrokerData.length}
      />
      <LineChartTable
        {...props}
        isMobile={isMobile}
        graphDropdownList={GRAPHDROPDOWNDATA}
        selectedDate={selectedDate?.label}
        graphApiData={graphApiData}
        handlDropdownSelection={handlDropdownSelection}
        singleRowSelection={true}
      />
      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </div>
  )
}

export default DashboardCustomers;