import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManagePaymentTerms from "../../../../components/internal/masterManagement/paymentTerms/web";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { isAccessibleModule } from "../../../../methods";
class ManagePaymentTermDataContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      paymentTermsNameList: [],
      paymentTermOperation: null,
      selectedPaymentTermId: null,
      selectedPaymentTermData: [],
      selectedPaymentTermDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    // if ((this.props.paymentTermsList).length > 0) {
    //   this.props.paymentTermsList.map(data => {
    //     // console.log("name :" + data.payment_method_name)
    //     this.setState({ paymentTermsNameList: [...this.state.paymentTermsNameList, data.payment_method_name] })
    //   })
    // }
    let paymentTermOperation = this.props.location.pathname.includes("view")
      ? "view"
      : "add";
    this.setState({ paymentTermOperation });
    if (paymentTermOperation === "view") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedPaymentTermData(id);
      this.setState({ selectedPaymentTermId: id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let propsPaymentTermOperation = this.props.location.pathname.includes(
      "view"
    )
      ? "view"
      : "add";
    if (propsPaymentTermOperation !== prevState.paymentTermOperation) {
      this.setState({ paymentTermOperation: propsPaymentTermOperation });
    }
    if (propsPaymentTermOperation === "view") {
      const propsSelectedPaymentTermId = this.props?.location?.pathname?.split(
        "/"
      )[this.props?.location?.pathname?.split("/").length - 1];
      if (propsSelectedPaymentTermId !== prevState.selectedPaymentTermId) {
        this.handleFetchSelectedPaymentTermData(propsSelectedPaymentTermId);
        this.setState({ selectedPaymentTermId: propsSelectedPaymentTermId });
      }
    }
  }
  handleFetchSelectedPaymentTermData = async (selectedPaymentTermId) => {
    await this.setState({ selectedPaymentTermDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD}${selectedPaymentTermId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedPaymentTermData: response.data,
              selectedPaymentTermDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedPaymentTermDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected payment term data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedPaymentTermDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching payment term data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handlePaymentTermDataUpdate = (paymentTermUpdateData) =>
    new Promise((resolve, reject) => {
      axios
        .put(
          `${APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD}${this.state.selectedPaymentTermId}/`,
          paymentTermUpdateData,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.props.getPaymentTermsList();
          resolve(response);
        })
        .catch(reject);
    });

  handlePaymentTermDataAdd = (paymentTermAddData) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD,
          paymentTermAddData,
          this.getOptions(this.props?.user)
        )
        .then(async (response) => {
          await this.setState({ selectedPaymentTermId: response.data.id });
          await this.props.getPaymentTermsList();
          await resolve(response);
        })
        .catch(reject);
    });
  handleRemovePaymentTerm = () =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD}${this.state.selectedPaymentTermId}/`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.props.getPaymentTermsList();
          resolve(response);
        })
        .catch(error => {
          if (error['response']['status'] === 500) {
            this.props.getPaymentTermsList();
            resolve(error)
          }
          reject(error);
        })
    });
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  render() {
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    else if (isAccessibleModule(this.props?.user, "Masters")) {
      return (
        <>
          <ManagePaymentTerms
            {...this.props}
            {...this.state}
            paymentTermsNamesLists={this.props?.paymentTermsList}
            handlePaymentTermDataUpdate={this.handlePaymentTermDataUpdate}
            handlePaymentTermDataAdd={this.handlePaymentTermDataAdd}
            handleRemovePaymentTerm={this.handleRemovePaymentTerm}
          />
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    } else return <AccessDenied />;
  }
}
export default withUser(ManagePaymentTermDataContainer);
