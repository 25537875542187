import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import "../../../styles/b2b/OrdersList.css";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import Assignment from "@material-ui/icons/Assignment";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Receipt from "@material-ui/icons/Receipt";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  errorMessageColor,
  secondaryColor,
} from "../../../styles/colors";
import moment from "moment";
import { pxToEm } from "../../../methods";
import OrderCard from "../orders/OrderCard";


const styles = {
  listContainerStyle: (isMobile) => ({
    width: "100%",
    // margin: "10px 0px",
    // height: "11vh",
    borderRadius: "12px",
    padding: "10px 6px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    // boxShadow:
    //   "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  }),
  listContainerLeftSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  listContainerMiddleSideStyle: {
    display: "flex",
    flexDirection: "column",
    width: "58%",
    height: "100%",
    justifyContent: "center",
    paddingLeft: "5px",
  },
  listContainerRightSideStyle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "22%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  textStyle: {
    // color: bottomNavigationFontColor,
    // whiteSpace: "nowrap",
    // width: "96%",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    color: bottomNavigationFontColor,
    // display: "flex",
    overflowWrap: "anywhere",
    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)

  },
  statusStyle: {
    color: bottomNavigationFontColor,
  },
  leftIconContainer: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    transform: 'scale(0.8)',
    border: `1px solid ${secondaryColor}`,
  },
  leftIconText: {
    color: bottomNavigationFontColor,
    fontSize: window.innerWidth < 310
      ? pxToEm(10.6)
      : window.innerWidth < 375
        ? pxToEm(11.6)
        : pxToEm(13.6),
  },
  priceShowContainer: {
    display: "flex",
    alignItems: "center",
    color: bottomNavigationFontColor,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
  },
  horizontalLineStyle: {
    width: "2px",
    height: "12px",
    background: "#9E9E9E",
    margin: "0px 6px",
  },
  actionCircleStyle: {
    width: "8px",
    height: "8px",
    background: errorMessageColor,
    borderRadius: "50%",
  },
  orderIdTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: "1.1em",
    whiteSpace: "nowrap",
    width: "96%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  weightTextStyle: {
    fontSize: "1.2em",
    whiteSpace: "nowrap",
    width: "98%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
  clickToPlanText: {
    fontSize: window.innerWidth < 310 ? pxToEm(11.2) : window.innerWidth < 375 ? pxToEm(12) : pxToEm(12.8),
    marginRight: "3px",
    color: bottomNavigationFontColor,
  },
};
class PendingActionList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSingleOrders = (orderId, status) => {
    // console.log(orderId, status)
    if (status === "APPROVED") {
      this.props.history.push(`/orders/active/${orderId}`);
    }
    if (status === "dispatched") {
      this.props.history.push(`/orders/pending/${orderId}`);
    }
    if (status === "canceled") {
      alert("canceled");
    }
    if (status === "ordered") {
      this.props.history.push(`/orders/action/${orderId}`);
    }
  };
  render() {
    const {
      classes,
      status,
      orderId,
      date,
      name,
      weight,
      isMobile,
      order,
      netRate
    } = this.props;
    return (
      <div
        style={styles.listContainerStyle(isMobile)}
        onClick={(e) => {
          e.preventDefault();
          this.props.history.push({ pathname: `/orders/action/${orderId}`, state: order });
          // this.props.handlePendingActionCardClick(order);
        }}
      >
        {/* <div className={classes.listContainerLeftSideStyle}>
          <span style={styles.leftIconContainer}>
            {
              status === "PENDING" && (
                <HourglassEmpty style={{ color: secondaryColor }} />
              )
            }
          </span>
          <span style={styles.leftIconText}>
            {status}
          </span>
        </div>
        <div className={classes.listContainerMiddleSideStyle}>
          <span
            style={{
              color: dailyRateScreenTextColor,
              fontSize: window.innerWidth < 310
                ? pxToEm(12)
                : window.innerWidth < 375
                  ? pxToEm(13.2)
                  : pxToEm(16)
            }}
          >
            Contract {orderId}
          </span>
          <span className={classes.priceShowContainer}>
            <span>{moment(date).format("DD-MMM-yyyy")}</span>
            <div className={classes.horizontalLineStyle} />
            <span>{`₹${parseInt(netRate).toFixed(0)}`}</span>
          </span>
          <span className={classes.textStyle}>{name}</span>
          <span style={{ display: "flex", alignItems: "center" }}>
            <span className={classes.clickToPlanText}>click to approve</span>
            <span className={classes.actionCircleStyle} />
          </span>
        </div>
        <div className={classes.listContainerRightSideStyle}>
          <span style={{ fontSize: "0.9em" }}>
            {weight}
            <span style={{ marginLeft: "4px" }}>MT</span>
          </span>

        </div> */}
        <div style={{ width: '100%', padding: "0 6px" }}>
          <OrderCard
            title={
              order.order_status === "COMPLETED" ? "Completed" :
                order.order_status === "APPROVED" ? "Approved" :
                  order.order_status === "ONGOING" ? "Ongoing" :
                    order.order_status
            }
            details={order}
            isMobile={this.props.isMobile}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(PendingActionList));
