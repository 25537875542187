import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  primaryColor,
  drawerTextColor,
  secondaryBlueText
} from "../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },

  topMargin: { marginTop: 30, width: "50%" },

  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function DisplayTransporterData(props) {
  console.log(props?.selectedTranporterData, props)
  const classes = useStyles();
  const [transporterDisplayData, setTransporterDisplayData] = React.useState(
    props?.selectedTranporterData || []
  );
  React.useEffect(() => {
    setTransporterDisplayData(props?.selectedTranporterData);
  }, [props?.selectedTranporterData]);
  const handleCancelButtonClick = () => {
    props.history.push(INTERNALROUTES.TRANSPORTER);
  };
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Transporter Master",
                onClick: () => props.history.push(INTERNALROUTES.TRANSPORTER),
              },
              {
                title: transporterDisplayData?.acc_name || "",
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACCOUNT CODE
              </Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.acc_code || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACCOUNT NAME
              </Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.acc_name || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ADDRESS</Typography>
              <Typography
                className={classes.titleLabel}
                style={{
                  width: '50%',
                  wordBreak: 'break-all',
                  textAlign: 'end'
                }}
              >
                {transporterDisplayData?.addr1 || " "},{" "}
                {transporterDisplayData?.addr2 || " "},{" "}
                {transporterDisplayData?.addr3 || " "}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>CITY</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.city_name || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>PINCODE</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.pin || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>DISTRICT</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.district || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>STATE</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.state_name || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>MOBILE</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.mobile || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>EMAIL</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.email || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>GST NUMBER</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.gst_number || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>PAN NUMBER</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.pan_no || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TRANSPORTER ID
              </Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.transporter_id || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS CODE</Typography>
              <Typography className={classes.titleLabel}>
                {transporterDisplayData.tds_code || ""}
              </Typography>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ACTIVE STATUS</Typography>
              <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                <IOSSwitch
                  disabled={props?.tranporterOperation === "view" ? true : false}
                  checked={Boolean(transporterDisplayData.is_active)}
                />
              </div>
            </div>
            <ToolTipComponent
              title="You have no edit permission on this widget"
              condition={!hasUpdateAccess(props.user, 'transporter_master')}
            >
              <Button
                size="medium"
                className={classes.primaryBackground}
                variant="contained"
                startIcon={<EditOutlinedIcon />}
                disabled={isEmpty(transporterDisplayData) || !hasUpdateAccess(props.user, 'transporter_master')}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(
                    `${INTERNALROUTES.TRANSPORTER}/edit/${transporterDisplayData?.id}`
                  );
                }}
              >
                EDIT
              </Button>
            </ToolTipComponent>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(DisplayTransporterData);
