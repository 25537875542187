import React, { Component, Fragment } from "react";
import "../../../styles/Register.css";
import { withStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
  secondaryColor,
} from "../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import * as EmailValidator from "email-validator";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { drawerBackground, earthBlue, secondaryBlueText, secondaryGrey } from "../../../constants/internal/colors";
import { Typography } from "@material-ui/core";

const styles = {
  registerAddressDetailsbody: {
    height: window.innerHeight - 56,
    overflow: "scroll",
    padding: "0px 20px ",
  },
  personalInfoTextDiv: {
    height: "85px",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  personalInfoText: {
    color: dailyRateScreenTextColor,
    fontSize: "1.1em",
  },
  textFieldStyle: {
    width: "100%",
    backgroundColor: "white",
  },
  containerStyle: {
    width: "100%",
    marginTop: "12px"
  },
  additionalTextContactTextDiv: {
    width: "100%",
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
  },
  additionalContactFormContainer: {
    marginTop: "12px",
  },
  addMoreContactButtonDiv: {
    width: "100%",
    border: "1px dashed grey",
    height: "52px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
  },
  panNumberContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  panCardImageContainer: {
    width: "90%",
    display: "flex",
    // flexDirection: "column",
    alignItems: "flex-end",
  },
  panCardImageStyle: {
    width: "40%",
    minHeight: "100px",
    border: "1px dashed #636363",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#636363",
  },
};

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});
class RegisterPersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // contactPerson: [
      //   {
      //     contactPersonName: "contactPersonName_1",
      //     contactPersonEmail: "contactPersonEmail_1",
      //     contactPersonContactNumber: "contactPersonContactNumber_1",
      //     contactPersonL: "1",
      //   },
      // ],
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() {
    this.setState({
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
    });
  }

  handleSelect = (e) => {
    // console.log(e)
  }

  handleCheckBookImageChange = (e) => {
    if (e.target.files.length) {
      this.setState(
        {
          CheckBookImagePreview: URL.createObjectURL(e.target.files[0]),
          rawCheckBookImage: e.target.files[0],
          CheckBookImagePreviewLoader: false,
          checkBookFileTypeError: this.handleFileTypeError(e.target.files[0].type)
        },
        () => {
          this.props.handleCheckBookCardImageFile(this.state.rawCheckBookImage);
        }
      );
    }
  };
  handleDeleteCheckBookImage = () => {
    this.setState(
      {
        CheckBookImagePreview: "",
        rawCheckBookImage: null,
        CheckBookImagePreviewLoader: true,
        checkBookFileTypeError: false
      },
      () => {
        this.props.handleCheckBookCardImageFile(this.state.rawCheckBookImage);
      }
    );
  };

  handleFileTypeError = (type) => {
    /// accepted file types
    // let fileTypes = ["application/pdf", "image/png", "image/jpeg"]
    // let isError = false
    // console.log(type)
    if (type === "application/pdf" || type === "image/png" || type === "image/jpeg" || type === "image/jpg") {
      return false
    } else return true
    // fileTypes.forEach(fileType => {
    //   if (type === fileType) {
    //     console.log(fileType, "if")
    //     isError = false
    //     return
    //   } else {
    //     console.log(fileType, "else")
    //     isError = true
    //   }
    // })
    // return isError
  }

  // handleAddMoreContactPerson = () => {
  //   let contactPersonLength = this.state.contactPerson.length;

  //   const contactPersonName = "contactPersonName_" + (contactPersonLength + 1);
  //   const contactPersonEmail =
  //     "contactPersonEmail_" + (contactPersonLength + 1);
  //   const contactPersonContactNumber =
  //     "contactPersonContactNumber_" + (contactPersonLength + 1);
  //   const contactPersonL = contactPersonLength + 1;
  //   const contactP = [this.state.contactPerson];
  //   const contactPersonObject = {
  //     contactPersonName,
  //     contactPersonEmail,
  //     contactPersonContactNumber,
  //     contactPersonL,
  //   };
  //   this.state.contactPerson.push(contactPersonObject);
  //   this.setState({ contactP });
  // };
  render() {
    //const { contactPerson } = this.state;
    const {
      contactPersonEmailTypeError,
      contactNumberLengthError,
    } = this.state;
    const {
      classes,
      firstName,
      lastName,
      dob,
      contactPersonName,
      contactPersonEmail,
      contactPersonContactNumber,
      contactPersonArray,
      nameOnCheque,
      ifsc,
      bankAccNo,
      banks, cename_Error, party_bank_acc_no_Error, ifsc_Error, firstName_Error, lastName_Error,
    } = this.props;
    // console.log(ifsc)
    return (
      <div className={classes.registerAddressDetailsbody}>
        <div className={classes.personalInfoTextDiv}>
          <span className={classes.personalInfoText}>Personal information</span>
        </div>
        <div className="register-personal-info-form-container">
          <FormControl
            variant="outlined"
            // className={classes.formControlStyle}
            size="small"
            className={classes.textFieldStyle}
            margin="normal"
          >
            <InputLabel htmlFor="age-simple" style={{ marginTop: "7px" }}>
              Select Title *
            </InputLabel>
            <Select
              name="title"
              label="Select Title *"
              style={{ height: "54px" }}
              onChange={(e) => {
                e.preventDefault();
                this.props.handleSelect(e);
              }}>
              <MenuItem value={null} disabled={true}>
                Select Any
              </MenuItem>
              <MenuItem value={"MR."}>Mr.</MenuItem>
              <MenuItem value={"MS."}>Ms.</MenuItem>
              <MenuItem value={"MRS."}>Mrs.</MenuItem>
              <MenuItem value={"MISS."}>Miss.</MenuItem>
            </Select>
          </FormControl>

          <TextField
            name="firstName"
            id="outlined-name"
            label="First Name *"
            margin="normal"
            variant="outlined"
            value={firstName}
            className={classes.textFieldStyle}
            onChange={this.props.handleTextChange}
            error={firstName_Error}
            helperText={firstName_Error ? "Invalid Input" : ""}
          />

          <TextField
            name="lastName"
            id="outlined-name"
            label="Last Name *"
            margin="normal"
            variant="outlined"
            value={lastName}
            className={classes.textFieldStyle}
            onChange={this.props.handleTextChange}
            error={lastName_Error}
            helperText={lastName_Error ? "Invalid Input" : ""}
          />

          {/* <TextField
            name="dob"
            type="date"
            id="date"
            label="Date Of Birth *"
            margin="normal"
            variant="outlined"
            value={dob}
            InputProps={{
              inputProps: { max: this.state.dateToday },
            }}
            className={classes.textFieldStyle}
            onChange={this.props.handleTextChange}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
          <div className={classes.textFieldStyle}>
            <Fragment>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.textField}
                    name="dob"
                    style={{ width: '100%' }}
                    inputVariant="outlined"
                    label="Date Of Birth *"
                    format="dd/MM/yyyy"
                    maxDate={new Date()}
                    margin="normal"
                    value={dob}
                    onChange={this.props.handleDateChange('dob')}
                    // onChange={handleDateChange("tds_valid_from")}
                    placeholder="e.g.: DD/MM/YYYY"
                    InputAdornmentProps={{ position: "end" }}
                    InputProps={{ className: classes.datePicker, readOnly: true }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Fragment>
          </div>
          <div className={classes.additionalTextContactTextDiv}>
            <span
              style={{ color: dailyRateScreenTextColor, fontSize: "1.1em" }}
            >
              Bank Details
            </span>
            {/* <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.9em",
                lineHeight: "1.6em",
              }}
            >
              All contacts added here will be notified through SMS about
            </span> */}
            {/* <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.9em",
                lineHeight: "1.6em",
              }}
            >
              Daily Rates , Orders and Dispatches
            </span> */}
          </div>
          <div className={classes.containerStyle}>


            <FormControl
              variant="outlined"
              // className={classes.formControlStyle}
              size="small"
              className={classes.textFieldStyle}
            >
              <InputLabel htmlFor="age-simple" style={{ marginTop: "7px" }}>
                Bank Name
              </InputLabel>
              <Select
                name="party_bank_name"
                label="Select Title"
                style={{ height: "54px" }}
                onChange={(e) => {
                  e.preventDefault();
                  this.props.handleSelect(e);
                }}>
                <MenuItem value={null} disabled={true}>
                  Select Any
                </MenuItem>
                {
                  banks && banks.map(bank => <MenuItem key={bank.id} value={bank.bank_name}>{bank.bank_name}</MenuItem>)
                }
              </Select>
            </FormControl>

            <TextField
              name="ifsc"
              id="outlined-name"
              label="IFSC Code"
              margin="normal"
              variant="outlined"
              value={ifsc}
              className={classes.textFieldStyle}
              onChange={this.props.handleTextChange}
              error={ifsc_Error}
              helperText={ifsc_Error ? "Invalid Input" : ""}
              inputProps={{
                maxLength: 11,
              }}
            />

            <TextField
              name="party_bank_acc_no"
              id="outlined-name"
              label="Bank Account Number"
              margin="normal"
              variant="outlined"
              value={bankAccNo}
              className={classes.textFieldStyle}
              onChange={this.props.handleTextChange}
              error={party_bank_acc_no_Error}
              helperText={party_bank_acc_no_Error ? "Invalid Input" : ""}
            />

            <TextField
              name="cename"
              errorCheckType="cenameerror"
              id="outlined-name"
              label="Name On Cheque"
              margin="normal"
              variant="outlined"
              value={nameOnCheque}
              className={classes.textFieldStyle}
              onChange={this.props.handleTextChange}
              error={cename_Error}
              helperText={cename_Error ? "Invalid Input" : ""}
            />

            <div
              style={{ width: "100%", margin: "10px 0px", }}
            >
              <span style={{ padding: "12px 0px" }}>Chequebook Image</span>
              <p
                style={{ fontSize: "12px", textAlign: "left" }}>
                {"Accepted File Types : pdf/jpg/jpeg/png"}
              </p>
              {this.state.checkBookFileTypeError && <p
                style={{ color: "red", fontSize: "12px" }}>
                {"Invalid File type"}
              </p>}
            </div>
            <div className={classes.panCardImageContainer}>
              <div className={classes.panCardImageStyle}>
                {this.state.CheckBookImagePreviewLoader === false ? (
                  <div style={{ height: "90%" }}>
                    {this.state.rawCheckBookImage.type === "image/png" ||
                      this.state.rawCheckBookImage.type === "image/jpg" ||
                      this.state.rawCheckBookImage.type === "image/jpeg" ? (
                      <img
                        src={this.state.CheckBookImagePreview}
                        alt="img"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <span style={{ wordBreak: "break-all", display: 'flex', padding: '0 10px' }}>{this.state.rawCheckBookImage.name}</span>
                    )}
                  </div>
                ) : (
                  <label className="pan-img-label">
                    <img
                      src={
                        window.cordova
                          ? `${this.image}${"/uploadImg.svg"}`
                          : "/uploadImg.svg"
                      }
                      style={{
                        height: "35%",
                        width: "auto",
                        objectFit: "contain",
                      }}
                      alt="img"
                    />
                    UPLOAD
                    <input
                      type="file"
                      size="60"
                      className="pan-img-input"
                      inputProps={{ accept: "image/*" }}
                      onChange={(e) => {
                        e.preventDefault();
                        this.handleCheckBookImageChange(e);
                      }}
                    />
                  </label>
                )}
              </div>
              {this.state.CheckBookImagePreviewLoader === false ? (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleDeleteCheckBookImage();
                  }}
                  style={{ paddingLeft: "15px", width: "20%" }}
                  aria-label="Delete"
                >
                  <Delete />
                </IconButton>
              ) : null}
              <br></br>

            </div>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", backgroundColor: "green", }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
              {this.state.checkBookFileTypeError && <p
                style={{ color: "red", fontSize: "12px" }}>
                {"Invalid File type"}
              </p>}
              <p
                style={{ fontSize: "12px", textAlign: "left" }}>
                {"Accepted File Types : pdf/jpg/jpeg/png"}
              </p>
            </div>
          </div> */}
          <div className={classes.additionalTextContactTextDiv}>
            <span
              style={{ color: dailyRateScreenTextColor, fontSize: "1.1em" }}
            >
              Additional Contact
            </span>
            <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.9em",
                lineHeight: "1.6em",
              }}
            >
              All contacts added here will be notified through SMS about
            </span>
            <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.9em",
                lineHeight: "1.6em",
              }}
            >
              Daily Rates , Contracts and Dispatches
            </span>
          </div>
          {contactPersonArray &&
            contactPersonArray.map((data, index) => (
              <div className={classes.additionalContactFormContainer}>
                <span style={{ color: secondaryColor, fontSize: "1.1em" }}>
                  Contact Person {index + 1}
                </span>
                <TextField
                  disabled
                  name="contactPersonName"
                  id="outlined-name"
                  label="Name"
                  margin="normal"
                  variant="outlined"
                  // onChange={this.props.handleTextChangeContactPerson}
                  value={data.name}
                  className={classes.textFieldStyle}
                />
                <TextField
                  disabled
                  name="contactPersonEmail"
                  id="outlined-name"
                  label="Email "
                  margin="normal"
                  variant="outlined"
                  // onChange={this.props.handleTextChangeContactPerson}
                  value={data.email_id}
                  className={classes.textFieldStyle}
                />
                <TextField
                  disabled
                  name="contactPersonContactNumber"
                  id="outlined-name"
                  label="Contact Number"
                  margin="normal"
                  variant="outlined"
                  // onChange={this.props.handleTextChangeContactPerson}
                  value={data.phone_number}
                  className={classes.textFieldStyle}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </div>
            ))}
          <div className={classes.additionalContactFormContainer}>
            <span style={{ color: secondaryColor, fontSize: "1.1em" }}>
              Contact Person
              <span style={{ marginLeft: "4px" }}>
                {contactPersonArray && contactPersonArray.length + 1}
              </span>
            </span>
            <TextField
              name="contactPersonName"
              id="outlined-name"
              label="Name"
              margin="normal"
              variant="outlined"
              onChange={this.props.handleTextChangeContactPerson}
              value={contactPersonName}
              className={classes.textFieldStyle}
            />
            <TextField
              name="contactPersonEmail"
              id="outlined-name"
              label="Email "
              margin="normal"
              variant="outlined"
              onChange={this.props.handleTextChangeContactPerson}
              value={contactPersonEmail}
              className={classes.textFieldStyle}
              onFocus={() => {
                this.setState({ contactPersonEmailTypeError: false });
              }}
              onBlur={() => {
                if (EmailValidator.validate(contactPersonEmail) === false) {
                  this.setState({ contactPersonEmailTypeError: true });
                }
              }}
              helperText={
                <span
                  style={{
                    color: "red",
                    fontSize: "0.8em",
                  }}
                >
                  {contactPersonEmailTypeError
                    ? "Please enter valid email address"
                    : null}
                </span>
              }
              error={contactPersonEmailTypeError ? true : false}
            />
            <TextField
              name="contactPersonContactNumber"
              id="outlined-name"
              label="Contact Number"
              margin="normal"
              variant="outlined"
              onChange={this.props.handleTextChangeContactPerson}
              value={contactPersonContactNumber}
              className={classes.textFieldStyle}
              inputProps={{
                maxLength: 10,
              }}
              onFocus={() => {
                this.setState({ contactNumberLengthError: false });
              }}
              onBlur={() => {
                if (contactPersonContactNumber.length < 10) {
                  this.setState({ contactNumberLengthError: true });
                }
              }}
              helperText={
                <span
                  style={{
                    color: "red",
                    fontSize: "0.8em",
                  }}
                >
                  {contactNumberLengthError
                    ? "Please enter valid 10 digit contact number"
                    : null}
                </span>
              }
              error={contactNumberLengthError ? true : false}
            />
          </div>
          <div
            className={classes.addMoreContactButtonDiv}
            onClick={(e) => {
              e.preventDefault();
              if (
                contactPersonName !== "" &&
                contactPersonEmail !== "" &&
                contactPersonContactNumber !== "" &&
                contactPersonEmailTypeError === false &&
                contactNumberLengthError === false
              ) {
                this.props.handleAddMoreContactPerson();
              }
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "grey",
              }}
            >
              <Add />
              Add More
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterPersonalInformation);
