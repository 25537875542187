import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
  secondaryColor,
} from "../../../styles/colors";
import SpinLoader from "../../common/SpinLoader";
import TileSkeleton from "./TileSkeleton";
import { pxToEm } from "../../../methods";
import { whiteSmoke } from "../../../constants/internal/colors";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = {
  plantPlaningContainer: {
    width: "100%",
    padding: "10px 0px 10px 5px"
  },
  plantPlaning: {
    display: "flex",
    justifyContent: "space-between",
    color: dailyRateScreenTextColor,
    fontSize: "1.1em",
    width: '98%',
    padding: '0px 0px 10px 5px',
  },
  plantPlanningLoader: (isMobile) => ({
    width: "100%",
    padding: "10px 0px 10px 5px",
    display: "flex",
    justifyContent: "center",
    // marginTop: "50px"
    marginBottom: 20,
  }),
  itemTypeText: {
    width: "50%",
    color: secondaryColor,
    fontSize: "0.9em",
  },
  itemPendingText: {
    width: "25%",
    display: "flex",
    justifyContent: "flex-end",
    color: secondaryColor,
    fontSize: "0.9em",
  },
  itemTotalText: {
    width: "25%",
    display: "flex",
    justifyContent: "flex-end",
    color: secondaryColor,
    fontSize: "0.9em",
  },
  itemTotalTextValue: {
    width: "33%",
    display: "flex",
    justifyContent: "center",
    fontSize: window.innerWidth < 375 && pxToEm(13)
  },
  itemPendingTextValue: {
    width: "33%",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: window.innerWidth < 375 && pxToEm(13)
  },
  accordion: {
    borderRadius: 16,

    '&::before': {
      display: 'none',
    },
  },
  titlePlantInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    width: '98%'
  },
  titleInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    width: '100%'
  },
  titleContainerBoxTitleLeft: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    padding: "2px 0px",
    backgroundColor: "white",
    fontSize: window.innerWidth < 375 ? pxToEm(15) : 15
  },
  titleContainerBoxTitleRight: {
    color: "rgb(88,88,88)",
    fontWeight: 500,
    width: '25%',
    padding: "2px 0",
    textAlign: 'end',
    backgroundColor: "white",
    fontSize: window.innerWidth < 375 && pxToEm(14)
  },
  singleDiv: {
    padding: "10px 15px 15px 15px",
  },
  keyAndValueContainerBox: {
    background: whiteSmoke,
    // border: "1px solid gainsboro",
    border: `1px solid ${whiteSmoke}`,
    borderRadius: "4px",
    width: '100%'
  },
  keyAndValueContainerItemBox: {
    background: whiteSmoke,
    borderRadius: "4px",
    width: '100%'
  },
  keyAndValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: '100%',
    padding: 0,
  },
  keyAndValueItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: '100%',
    padding: "4px 0",
  },
  buyerConsigneeCustomerNameText: {
    display: 'flex',
    alignItems: 'center',
    color: "rgb(88,88,88)",
    fontWeight: 700,
    fontSize: window.innerWidth < 375 && pxToEm(14),
    wordWrap: 'break-word',
    width: '50%'
  },
  itemBox: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    color: "rgb(88,88,88)",
    fontWeight: 600,
    fontSize: window.innerWidth < 375 && pxToEm(14),
    wordWrap: 'break-word',
    width: '50%'
  },
  buyerTextStyle: {
    color: "rgb(112,112,112)",
    textAlign: "right",
    // padding: "2px 0px 2px 30px",
    wordBreak: 'break-word',
    fontSize: window.innerWidth < 375 && pxToEm(14),
    width: '25%'
  },
};
class PlantPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      expandedChild: false,
      subContractData: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.plantSubcontractDetail !== prevProps.plantSubcontractDetail) {
      this.setState({ subContractData: this.props.plantSubcontractDetail })
    }
  }

  handleChange = (panel, plantID, subContractEnabled) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false, expandedChild: false },
      () => (this.state.expanded && subContractEnabled) && this.props?.fetchPlantSubContractDetail(plantID, this.props?.orderDetails?.id))
  };

  handleChangeChild = (panel) => (event, isExpanded) => {
    this.setState({ expandedChild: isExpanded ? panel : false })
  };

  render() {
    const { expanded, expandedChild, subContractData } = this.state;
    const { singleOrderPlantPlanning, plantPlanningLoader, isMobile } = this.props;
    return (plantPlanningLoader ?
      <div style={styles.plantPlanningLoader(isMobile)}>
        <TileSkeleton skeletonType='plant planning' />
      </div> :
      singleOrderPlantPlanning &&
      <div>
        <div style={styles.plantPlaningContainer}>
          <span style={{ color: bottomNavigationFontColor }}>
            Plant Planning
          </span>
          {
            singleOrderPlantPlanning.length > 0 ?
              singleOrderPlantPlanning.map((data, index) => (
                <div>
                  <Accordion
                    expanded={expanded === (index + 1)}
                    onChange={this.handleChange(index + 1, data.plant, Boolean(data.sub_contract_enabled))}
                    key={index}
                    style={{
                      borderRadius: 16,
                      margin: '10px 0',
                    }}
                  >
                    <AccordionSummary
                      // expandIcon={data.sub_contract_enabled && <ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: '0 12px',
                        '& .MuiAccordionSummary-content': {
                          width: '100%'
                        }
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <div style={styles.plantPlaning}>
                          <span style={styles.itemTypeText}>Plant</span>
                          <span style={styles.itemPendingText}>Balance</span>
                          <span style={styles.itemTotalText}>Total</span>
                        </div>
                        <div style={styles.titlePlantInnerContainer}>
                          <span style={styles.titleContainerBoxTitleLeft}>
                            <ExpandMoreIcon />
                            {data.plant_short_name}
                          </span>
                          <span style={styles.titleContainerBoxTitleRight}>
                            {Number((data.order_available_quantity)).toFixed(3)} MT
                          </span>
                          <span style={styles.titleContainerBoxTitleRight}>
                            {data.allotted_qty} MT
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {!data.sub_contract_enabled
                        ? <span>Subcontract not enabled</span>
                        : this.props?.plantSubcontractDetailLoader
                          ? <span>Loading...</span>
                          : this.props?.plantSubcontractDetail.length === 0
                            ? <span>No Subcontract Available</span>
                            : subContractData.map((d, i) => (
                              <Accordion
                                expanded={expandedChild === (i + 1)}
                                onChange={this.handleChangeChild((i + 1))}
                                disableGutters={true}
                                key={i}
                                style={{
                                  // border: "1px solid gainsboro",
                                  // margin: '10px 0',
                                }}
                              >
                                <div style={styles.titleInnerContainer}>
                                  <div style={styles.keyAndValueContainerBox}>
                                    <AccordionSummary
                                      // expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel2bh-content"
                                      id="panel2bh-header"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '0 4px'
                                      }}
                                    >

                                      <div style={styles.keyAndValueContainer}>
                                        <span style={styles.buyerConsigneeCustomerNameText}>
                                          <ExpandMoreIcon />
                                          {d.subcontract_number}
                                        </span>
                                        <span style={styles.buyerTextStyle}>
                                          {d.subcontract_available_quantity} MT
                                        </span>
                                        <span style={styles.buyerTextStyle}>
                                          {d.subcontract_total_qty} MT
                                        </span>
                                      </div>
                                    </AccordionSummary>
                                  </div>
                                </div>
                                <AccordionDetails
                                  style={{
                                    background: whiteSmoke,
                                    borderRadius: "4px",
                                    padding: '8px 6px 8px 12px'
                                  }}>
                                  <div style={{ marginBottom: 10 }}>
                                    <div style={styles.keyAndValueContainerItemBox}>
                                      {d?.subcontract_item_details?.length > 0
                                        ? d.subcontract_item_details.map((item, j) => (
                                          <div style={styles.keyAndValueItemContainer}>
                                            <span style={styles.itemBox}>
                                              {item.subcontract_item_name}
                                            </span>
                                            <span style={styles.buyerTextStyle}>
                                              {item.subcontract_item_available_quantity} MT
                                            </span>
                                            <span style={styles.buyerTextStyle}>
                                              {item.subcontract_item_alloted_qty} MT
                                            </span>
                                          </div>
                                        ))
                                        : <span> No Planned Items</span>
                                      }
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
              : null
          }
        </div>
        {singleOrderPlantPlanning.length === 0 ?
          <span style={{
            // padding: 15
            display: "flex",
            marginTop: 10,
            justifyContent: "center",
            marginBottom: 20
          }}>
            No Plant Planning Data Available
          </span>
          : null
        }
      </div>
    );
  }
}
export default withStyles(styles)(PlantPlanning);