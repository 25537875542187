// import React from 'react'
import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import RiplInvoicePdfDownload from "./RiplInvoicePdfDownload";
import RiplEwayBillPdfDownload from "./RiplEwayBillPdfDownload";
import ExitPassDownload from "./ExitPassDownload";
import Divider from '@mui/material/Divider';

import {
    grey,
    highlitedTextColor,
    successButtonBackground,
    whiteSmoke,
    secondaryBlueText,
    lightGreen
} from "../../../constants/internal/colors";
import { Typography, CardActionArea, CircularProgress, } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paperStyle: {
        boxShadow:
            "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
        width: "99%",
        paddingBottom: "20px",
    },
    ValueDetails: {
        display: "flex",
        marginBottom: "14px",
        // backgroundColor: "green",
        // placeContent: "center",
        placeItems: "center",
        justifyContent: "space-between",
    },
    itemDetailsCustomerBox: {

        border: "1px solid gainsboro",
        borderRadius: "15px",
        width: "98%",
        height: "100%",
        padding: "10px"
    },
    buyerTextStyle: {
        color: "rgb(112,112,112)",
        //alignItems: "right",
        textAlign: "right",
        //justifyContent: "flex-start"
        padding: "2px 0px 2px 30px"
    },
    basicInfoBoxTitle: {
        color: "rgb(88,88,88)",
        fontWeight: "bold",
        padding: "0px 0px 15px 0px",

        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
    },
    itemDetailContainer: {
        display: "flex",
        // minWidth: 275,
        // maxWidth: 400,
        // flex: "0 1 35%",
        flexDirection: "column",

        // backgroundColor: "green",
        //width: "48%",
        // paddingTop: "20px",
        // paddingLeft: "20px"
        padding: "10px",
        height: "100%"
        // backgroundColor: "green"

    },
    itemDetailContainerLeft: {
        //width: "90%",
        padding: "10px 10px",
        background: whiteSmoke,
        border: "1px solid gainsboro",
        borderRadius: "4px"

        //justifyContent: "space-between",

    },
    itemTextStyle: {
        color: "rgb(88,88,88)",
        fontWeight: "bold",
        width: "80%",
    },
    approvalListStyleText: {
        width: "100%",
        color: lightGreen,
        alignItems: "center",
        fontSize: "1em"
    },
    approvalListStyleDiv: {
        display: "flex",
        //placeItems: "center",
        padding: "10px 5px",
        justifyContent: "space-between",
        //width: "40%"
    },
    fieldAndIcon: {
        display: "flex",
        placeItems: "right",
        justifyContent: "right",
        // backgroundColor: "green",
        overflowWrap: 'anywhere',
        // textAlign: "right",
        width: "80%",
        color: "rgb(112,112,112)",
        overflowY: "visible"

    },
    pdfTitleWayBill: {
        // paddingTop: "20px",
        width: "55%",
        // backgroundColor: "green",
        justifyContent: "space-between",
    },
    pdfActions: {
        display: "flex",
        placeItems: "center",
        placeContent: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        // backgroundColor: "green"
    },
    loaderDiv: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const CardContents = (props) => {
    const classes = useStyles()
    const dateFormats = ["DD/MM/YYYY hh:mm:ss A", "DD/MM/YYYY hh:mm:ss", "DD/MM/YYYY", "YYYY-MM-DD hh:mm:ss A",
        "YYYY-MM-DD", "YYYY-MM-DD hh:mm:ss", "yyyy-MM-dd HH:mm:ss.SSS", "yyyy-MM-dd hh:mm:ss.SSS", "DD-MM-YYYY HH:mm:ss.SSS"]

    return (<div className={classes.itemDetailContainer}>
        <div className={classes.itemDetailsCustomerBox}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    className={classes.basicInfoBoxTitle}
                >
                    {props?.documentFor || ""}
                </Typography>
                {props.documentFor === "E-Invoice" ? (
                    <Typography
                        style={{
                            fontSize: "1rem",
                            color:
                                props?.eInvoiceResponse?.IrnStatus === "ACT"
                                    ? "green"
                                    : "red",
                        }}
                    >
                        {props?.eInvoiceResponse?.IrnStatus === "ACT"
                            ? "Active"
                            : props?.eInvoiceResponse?.IrnStatus === "CNL"
                                ? "Cancelled"
                                : ""}
                    </Typography>
                ) : null}
            </div>
            {props?.documentFor === "Delivery Challan" ||
                props?.documentFor === "E-Invoice" ||
                props?.documentFor === "Invoice" ?
                <div className={classes.itemDetailContainerLeft}>
                    <div className={classes.ValueDetails} >
                        <span className={classes.itemTextStyle}>
                            {`${props.documentFor === "Invoice" ||
                                props.documentFor === "Delivery Challan"
                                ? props?.laDetailsInDispatchCounter?.la_number
                                    ? `LA Number`
                                    : ""
                                : props.documentFor === "E-Invoice"
                                    ? props?.eInvoiceResponse?.AckNo
                                        ? `Acknowledgement No`
                                        : ""
                                    : ""
                                }
                    `}
                        </span>
                        {/* <div className={classes.fieldAndIcon}> */}
                        <span
                        // style={{ width: "90%", marginLeft: "10%" }}
                        >
                            {`${props.documentFor === "Invoice" ||
                                props.documentFor === "Delivery Challan"
                                ? props?.laDetailsInDispatchCounter?.la_number
                                    ? `${props?.laDetailsInDispatchCounter?.la_number || ""
                                    }`
                                    : ""
                                : props.documentFor === "E-Invoice"
                                    ? props?.eInvoiceResponse?.AckNo
                                        ? `${props?.eInvoiceResponse?.AckNo || ""
                                        }`
                                        : ""
                                    : ""
                                }
                    `}
                        </span>
                    </div>
                    {/* </div> */}

                    <div className={classes.ValueDetails}>
                        <div style={{ width: "40%" }}>
                            {
                                // props.documentFor === "E-Invoice"
                                //     ? props?.eInvoiceResponse?.AckDate
                                // ? 
                                <span className={classes.itemTextStyle}>Date & Time</span>
                                //     : ""
                                // : ""
                            }
                        </div>
                        <div className={classes.fieldAndIcon}>
                            <span
                            // style={{ width: "90%", marginLeft: "10%" }}
                            >
                                {`${props.documentFor === "Invoice" ||
                                    props.documentFor === "Delivery Challan"
                                    ?
                                    props?.laDetailsInDispatchCounter?.log_details?.BILLED?.created_at
                                        ? `${moment(
                                            props?.laDetailsInDispatchCounter?.log_details?.BILLED?.created_at,
                                            "yyyy-MM-DD HH:mm:ss.SSS"
                                        ).format("DD-MMM-YYYY LT")}`
                                        : props?.eWayBillResponse?.Date ||
                                            props?.eWayBillResponse?.EwbDt
                                            ? `${moment(
                                                props?.eWayBillResponse?.Date ||
                                                props?.eWayBillResponse?.EwbDt || "",
                                                ["DD/MM/YYYY hh:mm:ss A",
                                                    "DD/MM/YYYY hh:mm:ss",
                                                    "DD/MM/YYYY",
                                                    "YYYY-MM-DD hh:mm:ss A",
                                                    "YYYY-MM-DD",
                                                    "YYYY-MM-DD hh:mm:ss",
                                                    "yyyy-MM-dd HH:mm:ss.SSS"]
                                            ).format("DD-MMM-YYYY LT")}`
                                            : ""
                                    : props.documentFor === "E-Invoice"
                                        ? props?.eInvoiceResponse?.AckDate
                                            ? `${moment(
                                                props?.eInvoiceResponse?.AckDate,
                                                ["DD/MM/YYYY hh:mm:ss A",
                                                    "DD/MM/YYYY hh:mm:ss",
                                                    "DD/MM/YYYY",
                                                    "YYYY-MM-DD hh:mm:ss A",
                                                    "YYYY-MM-DD",
                                                    "YYYY-MM-DD hh:mm:ss",
                                                    "yyyy-MM-dd HH:mm:ss.SSS"]
                                            ).format("DD-MMM-YYYY LT")}`
                                            : ""
                                        : ""
                                    }
                                `}
                            </span>
                        </div>
                    </div>
                </div> : props?.documentFor === "E-Way Bill" ?
                    (
                        props?.ewayPostDataLoader ? (
                            <div className={classes.loaderDiv}>
                                <CircularProgress disableShrink className={classes.loaderStyle} />
                            </div>
                        ) : props?.eWayBillResponse?.e_way_message ? (
                            <CardActionArea style={{ padding: 20 }}>
                                <Typography
                                    style={{
                                        opacity: 0.4,
                                        fontSize: "1rem",
                                        color: "red",
                                    }}
                                >
                                    {console.log("props 2=", props, props?.eWayBillResponse?.e_way_message)}
                                    {props?.eWayBillResponse?.e_way_message ||
                                        "Error occured while creating e-way bill."}
                                </Typography>
                            </CardActionArea>
                        ) : (
                            <div className={classes.itemDetailContainerLeft}>
                                <div className={classes.ValueDetails}>
                                    <span className={classes.itemTextStyle}>
                                        E-way Bill
                                    </span>
                                    <span>
                                        {props?.eWayBillResponse?.EwbNo ? props?.eWayBillResponse?.EwbNo : props?.eWayBillResponse?.EWayBill ? props?.eWayBillResponse?.EWayBill : ""}
                                    </span>
                                </div>

                                <div className={classes.ValueDetails}>
                                    <div style={{ width: "40%" }}>
                                        <span className={classes.itemTextStyle}>Date & Time</span>
                                    </div>
                                    <div className={classes.fieldAndIcon}>
                                        <span
                                        // style={{ width: "90%", marginLeft: "10%" }}
                                        >
                                            {`${props?.eWayBillResponse?.Date
                                                ? `${moment(
                                                    props?.eWayBillResponse?.Date,
                                                    [
                                                        "DD/MM/YYYY hh:mm:ss A",
                                                        "DD/MM/YYYY hh:mm:ss",
                                                        "DD/MM/YYYY",
                                                        "YYYY-MM-DD hh:mm:ss A",
                                                        "YYYY-MM-DD",
                                                        "YYYY-MM-DD hh:mm:ss",
                                                        "yyyy-MM-dd HH:mm:ss.SSS",
                                                    ]
                                                ).format("DD-MMM-YYYY LT")}`
                                                : props?.eWayBillResponse?.EwbDt ?
                                                    `${moment(
                                                        props?.eWayBillResponse?.EwbDt,
                                                        [
                                                            "DD/MM/YYYY hh:mm:ss A",
                                                            "DD/MM/YYYY hh:mm:ss",
                                                            "DD/MM/YYYY",
                                                            "YYYY-MM-DD hh:mm:ss A",
                                                            "YYYY-MM-DD",
                                                            "YYYY-MM-DD hh:mm:ss",
                                                            "yyyy-MM-dd HH:mm:ss.SSS"
                                                        ]
                                                    ).format("DD-MMM-YYYY LT")}` : ""
                                                }
                            `}
                                        </span>
                                    </div>
                                </div>

                                <div className={classes.ValueDetails}>
                                    <div style={{ width: "40%" }}>
                                        <span className={classes.itemTextStyle}>Valid Upto</span>
                                    </div>
                                    <div className={classes.fieldAndIcon}>
                                        <span
                                        // style={{ width: "90%", marginLeft: "10%" }}
                                        >
                                            {props?.eWayBillResponse?.ValidUpTo
                                                ? `${moment(
                                                    props?.eWayBillResponse?.ValidUpTo, [
                                                    "DD/MM/YYYY hh:mm:ss A",
                                                    "DD/MM/YYYY hh:mm:ss",
                                                    "DD/MM/YYYY",
                                                    "YYYY-MM-DD hh:mm:ss A",
                                                    "YYYY-MM-DD",
                                                    "YYYY-MM-DD hh:mm:ss",
                                                    "YYYY-MM-DD hh:mm:ss LT",
                                                    "yyyy-MM-dd HH:mm:ss.SSS",
                                                ]
                                                ).format("DD-MMM-YYYY LT")}`
                                                : props?.eWayBillResponse?.EwbValidTill ?
                                                    `${moment(
                                                        props?.eWayBillResponse?.EwbValidTill,
                                                        [
                                                            "DD/MM/YYYY hh:mm:ss A",
                                                            "DD/MM/YYYY hh:mm:ss",
                                                            "DD/MM/YYYY",
                                                            "YYYY-MM-DD hh:mm:ss A",
                                                            "YYYY-MM-DD",
                                                            "YYYY-MM-DD hh:mm:ss",
                                                            "YYYY-MM-DD hh:mm:ss LT",
                                                            "yyyy-MM-dd HH:mm:ss.SSS",
                                                        ]
                                                    ).format("DD-MMM-YYYY LT")}`
                                                    : ""
                                            }
                                        </span>
                                    </div>
                                </div>

                            </div>
                        )
                    ) : props?.documentFor === "Exit Pass" ?
                        <div className={classes.itemDetailContainerLeft}>
                            <div className={classes.ValueDetails}>
                                <span className={classes.itemTextStyle}>
                                    {
                                        props?.eInvoiceResponse?.AckNo ? "Exit pass for E-invoice"
                                            : (props.documentFor === "Invoice" || props.documentFor === "Delivery Challan")
                                                ? "LA Number" : "LA Number"}
                                </span>
                                <span>
                                    {
                                        props?.eInvoiceResponse?.AckNo ? props?.eInvoiceResponse?.AckNo
                                            : props?.laDetailsInDispatchCounter?.la_number
                                                ? props?.laDetailsInDispatchCounter?.la_number
                                                : ""
                                    }
                                </span>
                            </div>

                            <div className={classes.ValueDetails} >
                                <div style={{ width: "40%" }}>
                                    <span className={classes.itemTextStyle}>Date & Time</span>
                                </div>
                                <div className={classes.fieldAndIcon}>
                                    <span
                                    // style={{ width: "90%", marginLeft: "10%", fontSize: "1rem" }}
                                    >
                                        {
                                            moment(
                                                props?.eInvoiceResponse?.AckDate || props?.eInvoiceResponse?.EwbDt || props?.eWayBillResponse?.EwbDt || props?.eWayBillResponse?.Date || props?.eWayBillResponse?.AckDate ||
                                                props?.laDetailsInDispatchCounter?.created_at_date_and_time || "",
                                                ["DD/MM/YYYY hh:mm a", "YYYY-MM-DD hh:mm a",
                                                    "DD/MM/YYYY hh:mm:ss A",
                                                    "DD/MM/YYYY hh:mm:ss",
                                                    "DD/MM/YYYY",
                                                    "YYYY-MM-DD hh:mm:ss A",
                                                    "YYYY-MM-DD",
                                                    "YYYY-MM-DD hh:mm:ss",
                                                    "yyyy-MM-dd HH:mm:ss.SSS"]
                                            ).format("DD-MMM-YYYY LT")
                                        }
                                    </span>

                                </div>
                            </div>
                        </div>
                        : props?.documentFor === "Email" ?
                            <div className={classes.itemDetailContainerLeft}>
                                <div className={classes.ValueDetails}>
                                    <div style={{ width: "30%" }}>
                                        <span className={classes.itemTextStyle}>
                                            Customer
                                        </span>
                                    </div>
                                    <div className={classes.fieldAndIcon}>
                                        <span
                                        // className={classes.buyerTextStyle}
                                        // style={{ width: "90%", marginLeft: "10%" }}
                                        >
                                            {props?.laDetailsInDispatchCounter?.customer?.customer_addresses?.email || "NA"}
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.ValueDetails}>
                                    <div style={{ width: "20%" }}>
                                        <span className={classes.itemTextStyle}>
                                            Status
                                        </span>
                                    </div>
                                    <div className={classes.fieldAndIcon}>
                                        {
                                            props?.laDetailsInDispatchCounter?.customer?.customer_addresses?.email ?

                                                <span
                                                // style={{ width: "90%", marginLeft: "10%" }}
                                                >
                                                    sent on {

                                                        props?.eInvoiceResponse?.AckDate
                                                            || props?.eInvoiceResponse?.EwbDt
                                                            || props?.eWayBillResponse?.EwbDt
                                                            || props?.eWayBillResponse?.Date
                                                            || props?.eWayBillResponse?.AckDate
                                                            ?
                                                            moment(
                                                                props?.eInvoiceResponse?.AckDate || props?.eInvoiceResponse?.EwbDt
                                                                || props?.eWayBillResponse?.EwbDt
                                                                || props?.eWayBillResponse?.Date
                                                                || props?.eWayBillResponse?.AckDate
                                                                ,
                                                                ["DD/MM/YYYY hh:mm a", "YYYY-MM-DD hh:mm a", "DD/MM/YYYY hh:mm:ss A",
                                                                    "DD/MM/YYYY hh:mm:ss",
                                                                    "DD/MM/YYYY",
                                                                    "YYYY-MM-DD hh:mm:ss A",
                                                                    "YYYY-MM-DD",
                                                                    "YYYY-MM-DD hh:mm:ss",
                                                                    "yyyy-MM-dd HH:mm:ss.SSS"]
                                                            ).format("DD-MMM-YYYY LT")
                                                            : props?.laDetailsInDispatchCounter?.log_details?.BILLED?.created_at
                                                                ? `${moment(
                                                                    props?.laDetailsInDispatchCounter?.log_details?.BILLED?.created_at,
                                                                    "yyyy-MM-DD HH:mm:ss.SSS"
                                                                ).format("DD-MMM-YYYY LT")}` : "-" ||
                                                                "-"

                                                    }
                                                </span>
                                                : <span>NA</span>}
                                    </div>
                                </div>
                                <Divider orientation="horizontal" />
                                <br></br>
                                <div className={classes.ValueDetails}>
                                    <div style={{ width: "20%" }}>
                                        <span className={classes.itemTextStyle}>
                                            Buyer
                                        </span>
                                    </div>
                                    <div className={classes.fieldAndIcon}>
                                        <span
                                        // className={classes.buyerTextStyle}
                                        // style={{ width: "90%", marginLeft: "10%" }}
                                        >
                                            {props?.laDetailsInDispatchCounter?.buyer?.gstin_number ?
                                                props?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.email :
                                                props?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.email || ""}
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.ValueDetails}>
                                    <div style={{ width: "20%" }}>
                                        <span className={classes.itemTextStyle}>
                                            Status
                                        </span>
                                    </div>
                                    <div className={classes.fieldAndIcon}>
                                        {
                                            props?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.email ||
                                                props?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.email ?

                                                <span
                                                // className={classes.buyerTextStyle}
                                                // style={{ width: "90%", marginLeft: "10%" }}
                                                >

                                                    sent on {
                                                        props?.eInvoiceResponse?.AckDate
                                                            || props?.eInvoiceResponse?.EwbDt
                                                            || props?.eWayBillResponse?.EwbDt
                                                            || props?.eWayBillResponse?.Date
                                                            || props?.eWayBillResponse?.AckDate
                                                            ?
                                                            moment(
                                                                props?.eInvoiceResponse?.AckDate || props?.eInvoiceResponse?.EwbDt
                                                                || props?.eWayBillResponse?.EwbDt
                                                                || props?.eWayBillResponse?.Date
                                                                || props?.eWayBillResponse?.AckDate
                                                                ,
                                                                ["DD/MM/YYYY hh:mm a", "YYYY-MM-DD hh:mm a", "DD/MM/YYYY hh:mm:ss A",
                                                                    "DD/MM/YYYY hh:mm:ss",
                                                                    "DD/MM/YYYY",
                                                                    "YYYY-MM-DD hh:mm:ss A",
                                                                    "YYYY-MM-DD",
                                                                    "YYYY-MM-DD hh:mm:ss",
                                                                    "yyyy-MM-dd HH:mm:ss.SSS"]
                                                            ).format("DD-MMM-YYYY LT")
                                                            : props?.laDetailsInDispatchCounter?.log_details?.BILLED?.created_at
                                                                ? `${moment(
                                                                    props?.laDetailsInDispatchCounter?.log_details?.BILLED?.created_at,
                                                                    "yyyy-MM-DD HH:mm:ss.SSS"
                                                                ).format("DD-MMM-YYYY LT")}` : "-" ||
                                                                "-"
                                                    }
                                                    {/* {moment(new Date()).format("DD-MMM-YYYY LT")} */}
                                                </span>
                                                : ""}
                                    </div>
                                </div>
                            </div>
                            : null
            }
            {/* <Grow
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{ timeout: 3000 }}
                    > */}
            {
                <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "10px" }}>
                    {/* <div style={{ width: "100%", }}> */}
                    {/* <div className={classes.ApprovalContainerBox}> */}
                    <div className={classes.approvalListStyleDiv}
                        style={{
                            marginTop: props?.documentFor === "Exit Pass" ? "37px"
                                : props?.documentFor === "E-Way Bill" ? "5px"
                                    : ""
                        }}
                    >
                        <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center", }}>
                            <span className={classes.approvalListStyleText}>
                                {props?.documentFor === "Delivery Challan" ||
                                    props?.documentFor === "E-Invoice" ||
                                    props?.documentFor === "Invoice" ? "Without Logos" : " "}
                            </span>
                        </div>
                        <div className={classes.pdfTitleWayBill}>
                            {
                                props?.documentFor === "Email" ?
                                    "" :
                                    props?.documentFor === "E-Way Bill" ?
                                        <div className={classes.pdfActions} >
                                            <RiplEwayBillPdfDownload pdfOperation={"VIEW"} ewayError={props?.eWayBillResponse?.e_way_message} {...props} />
                                            <RiplEwayBillPdfDownload
                                                pdfOperation={"DOWNLOAD"}
                                                ewayError={props?.eWayBillResponse?.e_way_message}
                                                {...props}
                                            />
                                        </div> : props?.documentFor === "Exit Pass" ?
                                            <div className={classes.pdfActions} style={{ bottom: "0px" }}>
                                                <ExitPassDownload pdfOperation={"VIEW"} {...props} />
                                                <ExitPassDownload pdfOperation={"DOWNLOAD"} {...props} />
                                            </div> :
                                            <div className={classes.pdfActions}>
                                                <RiplInvoicePdfDownload pdfOperation={"VIEW"} {...props} />
                                                <RiplInvoicePdfDownload
                                                    pdfOperation={"DOWNLOAD"}
                                                    {...props}
                                                />
                                            </div>
                            }
                        </div>
                        {/* </div> */}
                        {/* </div> */}

                    </div>
                    {props?.documentFor === "Delivery Challan" ||
                        props?.documentFor === "E-Invoice" ||
                        props?.documentFor === "Invoice" ?
                        <div style={{ width: "100%", }}>
                            {/* <div className={classes.ApprovalContainerBox}> */}
                            <div className={classes.approvalListStyleDiv}>
                                <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <span className={classes.approvalListStyleText}>
                                        With Logos
                                    </span>
                                </div>

                                <div className={classes.pdfTitleWayBill}>
                                    <div className={classes.pdfActions}>
                                        <RiplInvoicePdfDownload pdfOperation={"VIEW"} {...props} isLogoRequired={true} />
                                        <RiplInvoicePdfDownload
                                            pdfOperation={"DOWNLOAD"}
                                            {...props}
                                            isLogoRequired={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div> : null
                    }
                </div>
            }

            {/* </Grow> */}
            <div
                style={{
                    width: "45%",
                }}
            ></div>

        </div>
    </div>
    )
}

function NewInvoiceWayBillEinvoiceViewDownloadCard(props) {
    const classes = useStyles()
    return (
        <CardContents {...props} classes={classes} />
    )
}

export default NewInvoiceWayBillEinvoiceViewDownloadCard