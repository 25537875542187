import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ToolTipComponent from "../../common/ToolTipComponent";
import { hasUpdateAccess } from "../../../../methods";
const useStyles = makeStyles({
  addMoreButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
});

export default function AddMoreDottedButton(props) {
  const classes = useStyles();

  return (
    <div className={classes.addMoreButton}>
      <ToolTipComponent
        title="You have no edit permission on this widget"
        condition={!(props?.module === 'contract') && !hasUpdateAccess(props.user, "customerManagement")}
      >
        <Button
          // className={classes.addMoreButton}
          onClick={props.buttonOnClick}
          disabled={
            props?.module === 'contract'
              ? false
              : hasUpdateAccess(props.user, "customerManagement")
                ? false
                : true
          }
        >
          {props.buttonTitle}
        </Button>
      </ToolTipComponent>
    </div>
  );
}
