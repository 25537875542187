import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import imageCode from "../reports/web/ImageCode.json"
import moment from "moment";
import { printPDFInMobile } from "../../../methods";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const handleTotalSumCalculation = (arr, fieldName, method = '') => {
  if (arr) {
    if (method === '') {
      let sum = arr?.reduce((a, c) => {
        return Number(a) + Number(c[`${fieldName}`] !== undefined ? c[`${fieldName}`] : 0)
      }, 0)
      return String(sum).match(/^\d*\.?\d{0,3}$/) ? sum : sum.toFixed(3)
    }
    else if (method === 'length') {
      let totalLength = 0;
      arr?.forEach(element => {
        totalLength = totalLength + element[`${fieldName}`].length
      });

      return totalLength;
    }
  }
  return 0;
}

const handlePrintPlantDetails = (data, userName, snackbarFunction, action) => {
  if (data) {
    var dd = {
      info: {
        title: `Dispatch Planning ${action === 'PRINT READY QTY' && ' - Ready Qty '} | ${action !== 'PRINT'
          ? userName.plant_name + " | " + userName.item_category_name
          : data.plant_name}`,
        author: "RIPL",
        subject: "Dispatch Planning",
        keywords: "Dispatch Planning",
      },
      pageOrientation: 'landscape',
      pageMargins: [20, 20, 20, 40],
      header: function (currentPage) {
        // console.log(imageCode)
        if (currentPage === 1)
          return {
            margin: [0, 0, 0, 10],
            columns: [
              {
                image: imageCode,
                width: 50,
                height: 50,
                margin: [10, 0, 10, 10]
              }
            ]
          }
      },
      footer: function (currentPage, pageCount) {
        return {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  alignment: 'right',
                  margin: [0, 0, 35, 50],
                  fontSize: ['PRINT DISPATCH', "PRINT READY QTY"].includes(action) ? 8 : 12,
                }
              ]
            ]
          },
          layout: 'noBorders'
        };
      },
      content: action === 'PRINT DISPATCH'
        ? [
          {
            margin: [0, 10, 0, 0],
            text: '\n'
          },
          {
            margin: [25, 10, 0, 0],
            columns: [
              {
                text: [
                  "Report Name -  ",
                  { text: " Dispatch Planning ", fontSize: 10, }
                ],
                style: "subHeading",
                alignment: "left",
                width: '*'
              },
              // {
              //   text: `Date - ${(moment(new Date(userName.selectedDate)).format('DD-MMM-yyyy'))}`,
              //   style: "subHeading",
              //   alignment: "right",
              //   width: '*'
              // }
            ],
          },
          {
            margin: [25, 2, 0, 0],
            columns: [
              {
                text: `Plant Name - ${userName.plant_name}`,
                style: "subHeading",
                alignment: "left",
                width: '*'
              },
              {
                text: `Item Category Name - ${userName.item_category_name}`,
                style: "subHeading",
                alignment: "right",
                width: '*'
              }
            ],
          },
          {
            margin: [25, 2, 0, 0],
            columns: [
              {
                text: `Date of Generation - ${(moment(new Date()).format('DD-MMM-yyyy'))}`,
                style: "subHeading",
                alignment: "left",
                width: '*'
              },
              {
                text: `User Name - ${userName.userName}`,
                style: "subHeading",
                alignment: "right",
                width: '*'
              }
            ],
          },
          {
            margin: [25, 20, 0, 0],
            table: {
              headerRows: 1,
              widths: ['*', 200, '*', '*', '*', '*', '*',],
              heights: function (row) { return 15; },
              body: [
                [{
                  text: "CONTRACT NO.",
                  style: "dispatchHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: "CUSTOMER",
                  style: "dispatchHeader",
                  margin: [1, 8, 1, 8],
                  alignment: 'left',
                  border: [false, false, false, true],
                },
                {
                  text: [
                    "TODAY'S LA\n",
                    { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "dispatchHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: [
                    "LA(APPROVED)\n",
                    { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "dispatchHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: [
                    "LA(IN PLANT)\n",
                    { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "dispatchHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: [
                    "DISPATCHED\n",
                    { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "dispatchHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: [
                    "OPEN QTY\n",
                    { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "dispatchHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                ]
              ],
            },
            layout: {
              hLineColor: '#ddd',
              vLineColor: '#ddd',
              vLineHeight: 0,
            },
          },
          data.map(item => (
            {
              margin: [25, 3, 0, 0],
              table: {
                widths: ['*', 200, '*', '*', '*', '*', '*',],
                heights: function (row) { return 5; },
                dontBreakRows: true,
                body: [
                  [{
                    text: [
                      `${item.order_number}\n`,
                      { text: `${moment(new Date(item.created_at)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                    ],
                    style: "dispatchBody",
                    margin: [1, 0, 1, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: item.first_name + " " + item.last_name,
                    style: "dispatchBody",
                    margin: [1, 0, 1, 0],
                    alignment: 'left',
                    border: [false, false, false, true],
                  },
                  {
                    text: item?.loading_advices?.length > 0 ? item.loading_advices.length + ' LA' : null,
                    style: "dispatchBody",
                    color: 'blue',
                    decoration: "underline",
                    margin: [1, 0, 1, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: item.loading_advice_approved > 0 ? (item.loading_advice_approved + " MT") : "",
                    style: "dispatchBody",
                    margin: [1, 0, 1, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: item.loading_advice_inplant > 0 ? (item.loading_advice_inplant + " MT") : "",
                    style: "dispatchBody",
                    margin: [1, 0, 1, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: item.gate_out > 0 ? (item.gate_out + " MT") : "",
                    style: "dispatchBody",
                    margin: [1, 0, 1, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: item.total_pending > 0 ? (item.total_pending + " MT") : "",
                    style: "dispatchBody",
                    margin: [1, 0, 1, 0],
                    border: [false, false, false, true],
                  },
                  ]
                ],
              },
              layout: {
                hLineColor: '#ddd'
              },
            }
          )),
          {
            margin: [25, 0, 0, 0],
            table: {
              headerRows: 1,
              widths: ['*', 200, '*', '*', '*', '*', '*',],
              heights: 40,
              body: [
                [{
                  text: "",
                  style: "dispatchFooter",
                  margin: [5, 12, 5, 5],
                  border: [false, false, false, true],
                },
                {
                  text: "Calculation",
                  style: "dispatchFooter",
                  margin: [1, 12, 1, 5],
                  alignment: 'left',
                  border: [false, false, false, true],
                },
                {
                  text: `${handleTotalSumCalculation(data, 'loading_advices', 'length')} LA`,
                  style: "dispatchFooter",
                  margin: [5, 12, 5, 5],
                  border: [false, false, false, true],
                },
                {
                  text: `${handleTotalSumCalculation(data, 'loading_advice_approved')} MT`,
                  style: "dispatchFooter",
                  margin: [5, 12, 5, 5],
                  border: [false, false, false, true],
                },
                {
                  text: `${handleTotalSumCalculation(data, 'loading_advice_inplant')} MT`,
                  style: "dispatchFooter",
                  margin: [5, 12, 5, 5],
                  border: [false, false, false, true],
                },
                {
                  text: `${handleTotalSumCalculation(data, 'gate_out')} MT`,
                  style: "dispatchFooter",
                  margin: [5, 12, 5, 5],
                  border: [false, false, false, true],
                },
                {
                  text: `${handleTotalSumCalculation(data, 'total_pending')} MT`,
                  style: "dispatchFooter",
                  margin: [5, 12, 5, 5],
                  border: [false, false, false, true],
                },
                ]
              ],
            },
            layout: {
              hLineColor: '#ddd'
            },
          },
        ]
        : action === "PRINT READY QTY"
          ? [
            {
              margin: [0, 10, 0, 0],
              text: '\n'
            },
            {
              margin: [25, 10, 0, 0],
              columns: [
                {
                  text: [
                    "Report Name -  ",
                    { text: " Dispatch Planning - Ready Qty ", fontSize: 10, }
                  ],
                  style: "subHeading",
                  alignment: "left",
                  width: '*'
                },
                // {
                //   text: `Date - ${(moment(new Date(userName.selectedDate)).format('DD-MMM-yyyy'))}`,
                //   style: "subHeading",
                //   alignment: "right",
                //   width: '*'
                // }
              ],
            },
            {
              margin: [25, 2, 0, 0],
              columns: [
                {
                  text: `Plant Name - ${userName.plant_name}`,
                  style: "subHeading",
                  alignment: "left",
                  width: '*'
                },
                {
                  text: `Item Category Name - ${userName.item_category_name}`,
                  style: "subHeading",
                  alignment: "right",
                  width: '*'
                }
              ],
            },
            {
              margin: [25, 2, 0, 0],
              columns: [
                {
                  text: `Date of Generation - ${(moment(new Date()).format('DD-MMM-yyyy'))}`,
                  style: "subHeading",
                  alignment: "left",
                  width: '*'
                },
                {
                  text: `User Name - ${userName.userName}`,
                  style: "subHeading",
                  alignment: "right",
                  width: '*'
                }
              ],
            },
            {
              margin: [25, 20, 0, 0],
              table: {
                headerRows: 1,
                widths: [200, '*', '*', '*', '*', '*', '*',],
                heights: function (row) { return 15; },
                body: [
                  [{
                    text: "CUSTOMER",
                    style: "dispatchHeader",
                    margin: [1, 8, 1, 8],
                    alignment: 'left',
                    border: [false, false, false, true],
                  },
                  {
                    text: [
                      "OPEN QTY\n",
                      { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                    ],
                    style: "dispatchHeader",
                    margin: [8, 8, 8, 8],
                    border: [false, false, false, true],
                  },
                  {
                    text: [
                      "READY QTY\n",
                      { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                    ],
                    style: "dispatchHeader",
                    margin: [8, 8, 8, 8],
                    border: [false, false, false, true],
                  },
                  {
                    text: [
                      "TODAY'S LA\n",
                      { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                    ],
                    style: "dispatchHeader",
                    margin: [8, 8, 8, 8],
                    border: [false, false, false, true],
                  },
                  {
                    text: [
                      "LA(APPROVED)\n",
                      { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                    ],
                    style: "dispatchHeader",
                    margin: [8, 8, 8, 8],
                    border: [false, false, false, true],
                  },
                  {
                    text: [
                      "LA(IN PLANT)\n",
                      { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                    ],
                    style: "dispatchHeader",
                    margin: [8, 8, 8, 8],
                    border: [false, false, false, true],
                  },
                  {
                    text: [
                      "DISPATCHED\n",
                      { text: `${moment(new Date(userName.selectedDate)).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                    ],
                    style: "dispatchHeader",
                    margin: [8, 8, 8, 8],
                    border: [false, false, false, true],
                  },
                  ]
                ],
              },
              layout: {
                hLineColor: '#ddd',
                vLineColor: '#ddd',
                vLineHeight: 0,
              },
            },
            data.slice(0, data.length - 1).map((item, index) => (
              {
                margin: [25, 3, 0, 0],
                table: {
                  widths: [200, '*', '*', '*', '*', '*', '*',],
                  heights: function (row) { return 5; },
                  dontBreakRows: true,
                  body: [
                    [{
                      text: [
                        `${item.first_name + item.last_name}\n`,
                        { text: item.customer_id, style: "readydateStyle" }
                      ],
                      style: "readyBody",
                      margin: [1, 0, 1, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.open_quantity?.open_quantity}  MT\n`,
                        {
                          text: (item.today_open_quantity || Number(item.today_open_quantity) == Number(0)
                            ? item.today_open_quantity
                            : item.today_open_quantity) + ' MTS',
                          style: "readydateStyle"
                        }
                      ],
                      style: "readyBody",
                      margin: [1, 0, 1, 0],
                      border: [false, false, false, true],
                    },
                    {
                      text: (item.ready_quantity !== '' && Number(item.ready_quantity).toFixed(3) !== Number(0).toFixed(3)) ? item.ready_quantity + ' MT' : '',
                      style: "readyBody",
                      margin: [1, 0, 1, 0],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.loading_advice_pending?.pending_quantity + ' MT'}\n`,
                        { text: item.loading_advice_pending?.total_la + ' LA', style: "readydateStyle" }
                      ],
                      style: "readyBody",
                      margin: [1, 0, 1, 0],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.loading_advice_approved?.approved_quantity + ' MT'}\n`,
                        { text: item.loading_advice_approved?.total_la + ' LA', style: "readydateStyle" }
                      ],
                      style: "readyBody",
                      margin: [1, 0, 1, 0],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.loading_advice_inplant?.in_plant_quantity + ' MT'}\n`,
                        { text: item.loading_advice_inplant?.total_la + ' LA', style: "readydateStyle" }
                      ],
                      style: "readyBody",
                      margin: [1, 0, 1, 0],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.dispatched_quantity?.dispatch_quantity + ' MT'}\n`,
                        { text: item.dispatched_quantity?.total_la + ' LA', style: "readydateStyle" }
                      ],
                      style: "readyBody",
                      margin: [1, 0, 1, 0],
                      border: [false, false, false, true],
                    },
                    ]
                  ],
                },
                layout: {
                  hLineColor: '#ddd'
                },
              }
            )),
            data.slice(-1).map((item, index) => (
              {
                margin: [25, 0, 0, 0],
                table: {
                  headerRows: 1,
                  widths: [200, '*', '*', '*', '*', '*', '*',],
                  heights: 40,
                  body: [
                    [{
                      text: 'Calculation',
                      style: "readyFooter",
                      bold: true,
                      margin: [1, 12, 1, 5],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.open_quantity?.open_quantity}  MT\n`,
                        {
                          text: (item.today_open_quantity || Number(item.today_open_quantity) == Number(0)
                            ? item.today_open_quantity
                            : item.today_open_quantity) + ' MTS',
                          style: "readyFooterdateStyle"
                        }
                      ],
                      style: "readyFooter",
                      margin: [5, 12, 5, 5],
                      border: [false, false, false, true],
                    },
                    {
                      text: (item.ready_quantity !== '' && Number(item.ready_quantity).toFixed(3) !== Number(0).toFixed(3)) ? item.ready_quantity + ' MT' : '',
                      style: "readyFooter",
                      margin: [5, 12, 5, 5],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.loading_advice_pending?.pending_quantity + ' MT'}\n`,
                        { text: item.loading_advice_pending?.total_la + ' LA', style: "readyFooterdateStyle" }
                      ],
                      style: "readyFooter",
                      margin: [5, 12, 5, 5],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.loading_advice_approved?.approved_quantity + ' MT'}\n`,
                        { text: item.loading_advice_approved?.total_la + ' LA', style: "readyFooterdateStyle" }
                      ],
                      style: "readyFooter",
                      margin: [5, 12, 5, 5],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.loading_advice_inplant?.in_plant_quantity + ' MT'}\n`,
                        { text: item.loading_advice_inplant?.total_la + ' LA', style: "readyFooterdateStyle" }
                      ],
                      style: "readyFooter",
                      margin: [5, 12, 5, 5],
                      border: [false, false, false, true],
                    },
                    {
                      text: [
                        `${item.dispatched_quantity?.dispatch_quantity + ' MT'}\n`,
                        { text: item.dispatched_quantity?.total_la + ' LA', style: "readyFooterdateStyle" }
                      ],
                      style: "readyFooter",
                      margin: [5, 12, 5, 5],
                      border: [false, false, false, true],
                    },
                    ]
                  ],
                },
                layout: {
                  hLineColor: '#ddd'
                },
              }))
          ]
          : [{
            margin: [0, 10, 0, 0],
            text: '\n'
          },
          {
            margin: [25, 10, 0, 0],
            columns: [
              {
                text: [
                  "Report Name -  ",
                  { text: " Dispatch Planning ", fontSize: 10, }
                ],
                style: "subHeading",
                alignment: "left",
                width: '*'
              },
              {
                text: `Plant Name - ${data.plant_name}`,
                style: "subHeading",
                alignment: "right",
                width: '*'
              }
            ],
          },
          {
            margin: [25, 2, 0, 0],
            columns: [
              {
                text: `Date of Generation - ${(moment(new Date()).format('DD-MMM-yyyy'))}`,
                style: "subHeading",
                alignment: "left",
                width: '*'
              },
              {
                text: `User Name - ${userName}`,
                style: "subHeading",
                alignment: "right",
                width: '*'
              }
            ],
          },
          {
            margin: [25, 20, 0, 0],
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*"],
              heights: function (row) { return 25; },
              body: [
                [{
                  text: "ITEM CATEGORY",
                  style: "itemsHeader",
                  alignment: "left",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: "TOTAL PENDING",
                  style: "itemsHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, true, true],
                },
                {
                  text: [
                    "READY QTY\n",
                    { text: `${moment(new Date()).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "itemsHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: [
                    "RUNNING LA\n",
                    { text: `${moment(new Date()).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "itemsHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: [
                    "DISPATCHED\n",
                    { text: `${moment(new Date()).format('DD-MMM-YYYY')}`, style: "dateStyle" }
                  ],
                  style: "itemsHeader",
                  margin: [8, 8, 8, 8],
                  border: [false, false, false, true],
                },
                ]
              ],
            },
            layout: {
              hLineColor: '#ddd',
              vLineColor: '#ddd',
              vLineHeight: 0,
            },
          },
          data.item_category.map(item => (
            {
              margin: [25, 10, 0, 0],
              table: {
                widths: ["*", "*", "*", "*", "*"],
                heights: function (row) { return 20; },
                body: [
                  [{
                    text: item.name,
                    style: "itemsBody",
                    margin: [8, 8, 8, 12],
                    alignment: "left",
                    border: [false, false, false, true],
                  },
                  {
                    text: item.pending_qty,
                    style: "itemsBody",
                    margin: [8, 8, 8, 12],
                    border: [false, false, false, true],
                  },
                  {
                    text: (item?.ready_qty || 0),
                    style: "itemsBody",
                    margin: [8, 8, 8, 12],
                    border: [false, false, false, true],
                  },
                  {
                    text: (item?.running_qty || 0),
                    style: "itemsBody",
                    margin: [8, 8, 8, 12],
                    border: [false, false, false, true],
                  },
                  {
                    text: item.dispatched_qty,
                    style: "itemsBody",
                    margin: [8, 8, 8, 12],
                    border: [false, false, false, true],
                  },
                  ]
                ],
              },
              layout: {
                hLineColor: '#ddd'
              },
            }
          )),
          {
            margin: [25, 0, 0, 0],
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*"],
              heights: 50,
              body: [
                [{
                  text: "",
                  style: "itemsFooter",
                  margin: [8, 16, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: handleTotalSumCalculation(data.item_category, 'pending_qty'),
                  style: "itemsFooter",
                  margin: [8, 16, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: handleTotalSumCalculation(data.item_category, 'ready_qty'),
                  style: "itemsFooter",
                  margin: [8, 16, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: handleTotalSumCalculation(data.item_category, 'running_qty'),
                  style: "itemsFooter",
                  margin: [8, 16, 8, 8],
                  border: [false, false, false, true],
                },
                {
                  text: handleTotalSumCalculation(data.item_category, 'dispatched_qty'),
                  style: "itemsFooter",
                  margin: [8, 16, 8, 8],
                  border: [false, false, false, true],
                },
                ]
              ],
            },
            layout: {
              hLineColor: '#ddd'
            },
          },
          ],
      styles: {
        heading: {
          color: '#000',
          fontSize: 15,
          bold: true,
          margin: [0, 3, 0, 3],
        },
        subHeading: {
          // color: '#000',
          fontSize: 10,
          // bold: false,
          margin: [0, 3, 0, 3],
        },
        itemsHeader: {
          padding: [0, 5, 0, 5],
          hLineColor: '#808080',
          fillColor: "#f5f5f5",
          // color: '#839192',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        itemsBody: {
          padding: [0, 5, 0, 5],
          hLineColor: '#808080',
          fillColor: '#FFF',
          color: '#333',
          fontSize: 10,
          alignment: "center",
        },
        itemsFooter: {
          hLineColor: '#808080',
          // fillColor: "#f5f5f5",
          color: '#000',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        dateStyle: {
          fontSize: 7,
          // decoration: 'underline'
        },
        readydateStyle: {
          fontSize: 7,
          color: '#839192'
        },
        dispatchHeader: {
          padding: [0, 5, 0, 5],
          hLineColor: '#808080',
          fillColor: "#f5f5f5",
          // color: '#839192',
          fontSize: 8,
          bold: true,
          alignment: "center",
        },
        dispatchBody: {
          padding: [0, 1, 0, 1],
          hLineColor: '#808080',
          fillColor: '#FFF',
          color: '#333',
          fontSize: 7,
          alignment: "center",
        },
        dispatchFooter: {
          hLineColor: '#808080',
          // fillColor: "#f5f5f5",
          color: '#000',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        readyBody: {
          padding: [0, 1, 0, 1],
          hLineColor: '#808080',
          fillColor: '#FFF',
          color: '#333',
          fontSize: 8,
          alignment: "center",
        },
        readyFooter: {
          hLineColor: '#808080',
          // fillColor: "#f5f5f5",
          color: '#000',
          fontSize: 10,
          bold: true,
          alignment: "center",
        },
        readyFooterdateStyle: {
          padding: [0, 2, 0, 1],
          fontSize: 8,
          color: '#839192'
        }
      }
    }
    if (action === "PRINT") {
      const reportName = `Dispatch_Planning_${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`
      // pdfMake.createPdf(dd).open();
      if (!window.cordova) {
        pdfMake.createPdf(dd).download(reportName, snackbarFunction());
      }
      else {
        let fileName = `Dispatch_Planning`
        printPDFInMobile(pdfMake, dd, fileName, snackbarFunction, '')
      }
    }
    else if (action === "PRINT DISPATCH") {
      const reportName = `Dispatch_Planning_${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`
      // pdfMake.createPdf(dd).open();
      if (!window.cordova) {
        pdfMake.createPdf(dd).download(reportName, snackbarFunction());
      } else {
        let fileName = `Dispatch_Planning_Contract_Wise`
        printPDFInMobile(pdfMake, dd, fileName, snackbarFunction, '')
      }
    }
    else if (action === "PRINT READY QTY") {
      const reportName = `Dispatch_Planning_Ready_Qty_${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`
      // pdfMake.createPdf(dd).open();
      if (!window.cordova) {
        pdfMake.createPdf(dd).download(reportName, snackbarFunction());
      } else {
        let fileName = `Dispatch_Planning_Ready_Qty`
        printPDFInMobile(pdfMake, dd, fileName, snackbarFunction, '')
      }
    }
  }
}



export {
  handlePrintPlantDetails,
}