import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemText, ListItemIcon, ListItem, List, ListSubheader, Divider, } from "@material-ui/core";
import { GlobalHotKeys } from "react-hotkeys";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import DailyRatesIcon from "@material-ui/icons/LocalOfferOutlined";
import OrdersIcon from "@material-ui/icons/AssignmentOutlined";
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
// import LoadinAdvicesIcon from "@material-ui/icons/LocalActivityOutlined";
import LoadinAdvicesIcon from '@mui/icons-material/LocalShippingOutlined';
import PaymentsAndInvoicesIcon from "@material-ui/icons/ListAltOutlined";
import CustomersIcon from "@material-ui/icons/DomainOutlined";
import ReportsIcon from "@material-ui/icons/AssessmentOutlined";
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import UsersAndPermissionsIcon from "@material-ui/icons/AssignmentIndOutlined";
import MastersManagmentIcon from "@material-ui/icons/LayersOutlined";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import EinvoicingAndEwayBillingIcon from "@material-ui/icons/ReceiptOutlined";
import LocalActivity from "@material-ui/icons/LocalActivity";
import Dvr from "@material-ui/icons/Dvr";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import {
  drawerTextColor,
  highlitedTextColor,
  logoBackground,
} from "../../../constants/internal/colors";
import { pxToEm } from "../../../methods";

const useStyles = makeStyles((theme) => ({
  iconColor: {
    color: drawerTextColor,
    minWidth: "35px",
  },
  root: {
    width: "100%",
    color: drawerTextColor,
  },
  listMainDiv: {
    display: "flex",
    flexDirection: "column",
    placeItems: "center",
    padding: '10px 7px 10px 10px',
  },
  logoImg: {
    width: "100%",
    height: "15vh",
    borderRadius: 20,
    objectFit: "contain",
    backgroundColor: drawerTextColor,
  },
  logoDiv: {
    //padding: '20px 5px',
    cursor: "pointer",
  },
  fontStyle: {
    fontSize: pxToEm(14),
  },
  listItem: {
    paddingLeft: '10px',
  },
  subHeaderStyle: {
    color: 'inherit',
    backgroundColor: 'inherit',
    fontWeight: 400,
    fontSize: '0.875rem',
  }
}));

const keyMap = {
  DASHBOARD: { name: "Expand square area", sequence: "0" },
  DAILYRATES: { name: "Expand square area", sequence: "1" },
  CONTRACTS: { name: "Expand square area", sequence: "2" },
  LOADINGADVICES: { name: "Expand square area", sequence: "3" },
  CUSTOMERS: { name: "Expand square area", sequence: "4" },
  USERANDPERMISSION: { name: "Expand square area", sequence: "5" },
  DISPATCHTRACKING: { name: "Expand square area", sequence: "6" },
  CUSTOMERREQUESTS: { name: "Expand square area", sequence: "7" },
  MASTERSMANAGEMENT: { name: "Expand square area", sequence: "8" },
  EINVOICE: { name: "Expand square area", sequence: "9" },
  WEIGHBRIDGES: { name: "Expand square area", sequence: "10" },
  REPORTS: { name: "Expand square area", sequence: "11" },
  INVOICESANDPAYMENTS: { name: "Expand square area", sequence: "-" },
};

function DrawerContent(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [groupedItemList, setGroupedItemList] = React.useState({});
  const [image, setImage] = React.useState('');
  const handlePageTitle = (title, index) => setSelectedIndex(index);



  React.useEffect(() => {
    if (window.cordova) {
      setImage(`${window.cordova.file.applicationDirectory}www`)
    }
  }, [])

  const handleShortcut = {
    DASHBOARD: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Dashboard")
          ? INTERNALROUTES.DASHBOARD
          : "/"
      ),
    DAILYRATES: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Daily Rates")
          ? INTERNALROUTES.DAILYRATES
          : "/"
      ),
    CONTRACTS: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Orders")
          ? INTERNALROUTES.ORDERS
          : "/"
      ),
    LOADINGADVICES: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Loading Advices")
          ? INTERNALROUTES.LOADINGADVICE
          : "/"
      ),
    INVOICESANDPAYMENTS: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find(
          (e) => e === "Invoices And Payments"
        )
          ? INTERNALROUTES.INVOICEANDPAYMENTS
          : "/"
      ),
    CUSTOMERS: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Customer Management")
          ? INTERNALROUTES.CUSTOMERS
          : "/"
      ),
    USERANDPERMISSION: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find(
          (e) => e === "Users And Permissions"
        )
          ? INTERNALROUTES.USERANDPERMISSION
          : "/"
      ),
    EWAYBILLTRACKING: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Dispatch Tracking")
          ? INTERNALROUTES.DISPATCH_TRACKING
          : "/"
      ),
    CUSTOMERREQUESTS: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Customer Request")
          ?
          INTERNALROUTES.CUSTOMER_REQUESTS
          : "/"
      ),
    MASTERSMANAGEMENT: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Masters")
          ? INTERNALROUTES.MASTERSMANAGMENT
          : "/"
      ),
    EINVOICE: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "E-Invoicing")
          ? INTERNALROUTES.E_INVOICING_AND_E_WAY_BILLING
          : "/"
      ),
    DISPATCHPLANNING: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Dispatch Planning")
          ? INTERNALROUTES.DISPATCH_PLANNING
          : "/"
      ),
    DISPATCHOPERATIONS: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Dispatch Operations")
          ? INTERNALROUTES.DISPATCH_OPERATION
          : "/"
      ),
    REPORTS: (e) =>
      props.history.push(
        props?.user?.accessibleModules?.find((e) => e === "Reports")
          ? INTERNALROUTES.REPORTS
          : "/"
      ),
  };
  const selectedStyle = (path) => ({
    borderRadius: 30,
    backgroundColor: props.location.pathname.includes(path)
      ? highlitedTextColor
      : null,
  });

  const listItems = [
    {
      path: INTERNALROUTES.DASHBOARD,
      title: "Dashboard",
      access: "Dashboard",
      icon: (path) => <DashboardIcon style={selectedStyle(path)} />,
      group: '',
    },
    {
      path: INTERNALROUTES.DAILYRATES,
      title: "Daily Rates",
      access: "Daily Rates",
      icon: (path) => <DailyRatesIcon style={selectedStyle(path)} />,
      group: 'Sales',
    },
    {
      path: INTERNALROUTES.ORDERS,
      title: "Contracts",
      access: "Contracts",
      icon: (path) => <OrdersIcon style={selectedStyle(path)} />,
      group: 'Sales'
    },
    // {
    //   path: INTERNALROUTES.SALESTARGET,
    //   title: "Sales Target Management",
    //   access: "Sales Target",
    //   icon: (path) => <AssistantOutlinedIcon style={selectedStyle(path)} />,
    //   group: 'Sales'
    // },
    {
      path: INTERNALROUTES.DISPATCH_PLANNING,
      title: "Dispatch Planning",
      access: "Dispatch Planning",
      icon: (path) => <LocalActivity style={selectedStyle(path)} />,
      group: 'Dispatch',
    },
    {
      path: INTERNALROUTES.DISPATCH_OPERATION,
      title: "Dispatch Operations",
      access: "Dispatch Operations",
      icon: (path) => <Dvr style={selectedStyle(path)} />,
      group: 'Dispatch',
    },
    {
      path: INTERNALROUTES.LOADINGADVICE,
      title: "Loading Advices",
      access: "Loading Advices",
      icon: (path) => <LoadinAdvicesIcon style={selectedStyle(path)} />,
      group: 'Dispatch',
    },
    {
      path: INTERNALROUTES.DISPATCH_TRACKING,
      title: "Dispatch Tracking",
      access: "Dispatch Tracking",
      icon: (path) => <FileCopyOutlinedIcon style={selectedStyle(path)} />,
      group: 'Dispatch'
    },
    {
      path: INTERNALROUTES.E_INVOICING_AND_E_WAY_BILLING,
      title: "E-Invoicing",
      access: "E-Invoicing",
      icon: (path) => (
        <EinvoicingAndEwayBillingIcon style={selectedStyle(path)} />
      ),
      group: 'Dispatch',
    },
    // {
    //   path: INTERNALROUTES.INVOICEANDPAYMENTS,
    //   title: "Invoices And Payments",
    //   access: "Invoices And Payments",
    //   icon: (path) => <PaymentsAndInvoicesIcon style={selectedStyle(path)} />,
    // },
    {
      path: INTERNALROUTES.CUSTOMERS,
      title: "All Customers",
      access: "Customer Management",
      icon: (path) => <CustomersIcon style={selectedStyle(path)} />,
      group: 'Customers',
    },
    {
      path: INTERNALROUTES.CUSTOMER_REQUESTS,
      title: "Customer Request",
      access: "Customer Request",
      icon: (path) => <ReceiptLongOutlinedIcon style={selectedStyle(path)} />,
      group: 'Customers',
    },
    {
      path: INTERNALROUTES.USERANDPERMISSION,
      title: "Users And Permissions",
      access: "Users And Permissions",
      icon: (path) => <UsersAndPermissionsIcon style={selectedStyle(path)} />,
      group: 'Admin Configurations',
    },
    {
      path: INTERNALROUTES.MASTERSMANAGMENT,
      title: "Masters Management",
      access: "Masters",
      icon: (path) => <MastersManagmentIcon style={selectedStyle(path)} />,
      group: 'Admin Configurations',
    },
    {
      path: INTERNALROUTES.REPORTS,
      title: "Standard Reports",
      access: "Reports",
      icon: (path) => <ReportsIcon style={selectedStyle(path)} />,
      group: 'Reports',
    },
    {
      path: INTERNALROUTES.DYNAMIC_REPORTS,
      title: "Dynamic Reports",
      access: "Dynamic Report",
      icon: (path) => <BallotOutlinedIcon style={selectedStyle(path)} />,
      group: 'Reports',
    },
  ];

  const groupedItems = listItems.reduce((acc, item) => {
    if (!acc[item.group]) {
      acc[item.group] = [];
    }
    if (
      // ["Sales Target"].includes(item.access) ||
      props?.user?.accessibleModules?.find((a) => a === item.access))
      acc[item.group].push(item);
    return acc;
  }, {});

  useEffect(() => {
    if (Object.keys(groupedItemList).length === 0) {
      setGroupedItemList(groupedItems)
    }
  }, [props.user])


  const history = useHistory()
  return (
    <div>
      <GlobalHotKeys
        // keyMap={keyMap} 
        handlers={handleShortcut}
      />
      <div className={classes.listMainDiv}>
        <div className={classes.logoDiv}
          onClick={() => history.push(INTERNALROUTES.DASHBOARD)}
        >
          <img
            src={(window.cordova || !props.open)
              ? `${image}${"/RealDealWeb2.png"}`
              : "/RealDealWeb2.png"
            }
            className={classes.logoImg}
            alt="REAL Deals"
            height={(window.cordova || !props.open) && "100%"}
            width={(window.cordova || !props.open) && "100%"}
            style={{
              // marginBottom: 15,
              backgroundColor: logoBackground,
              height: !props.open ? '6vh' : "15vh",
              borderRadius: !props.open ? 6 : 20,
              width: props.open && 215
            }}
          />
        </div>
        <List disablePadding className={classes.root}>
          {listItems.map((list, index) => {
            if (
              // list.access === "Sales Target"
              //  ? true :
              props?.user?.accessibleModules?.find((a) => a === list.access)

            ) {
              return (
                <div key={`ListItemContainer-${index}`}>
                  {
                    ['Sales', 'Dispatch', 'Customers', 'Admin Configurations', 'Reports'].map(h => {
                      return (
                        list.access === groupedItemList[h]?.[0]?.access && (
                          props.open
                            ? <ListSubheader
                              className={classes.subHeaderStyle}
                              style={{ display: !props.open && 'none' }}
                            >
                              {h}
                            </ListSubheader>
                            : <Divider
                              style={{ margin: '10px 0', backgroundColor: '#eee' }}
                              variant="middle"
                            />
                        )
                      )
                    })
                  }
                  <ListItem
                    title={list.title}
                    button
                    key={`ListItem-${index}`}
                    selected={selectedIndex === index}
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageTitle(list.title, index);
                      props.history.push(list.path);
                    }}
                    style={selectedStyle(list.path)}
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.iconColor}>
                      {list.icon(list.path)}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.fontStyle}
                      primary={list.title}
                      primaryTypographyProps={{
                        style: { fontSize: "0.99em" },
                      }}
                      style={{ display: !props.open && 'none' }}
                    />
                  </ListItem>
                </div>
              );
            } else return null;
          })}
        </List>
      </div>
    </div>
  );
}

export default withRouter(DrawerContent);
