import { Fade, Slide } from "@mui/material";
import { useState } from "react";

const SwipeComponent = (props) => {
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState('right');
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    // console.log("START : ", e.targetTouches[0].clientX)
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    // console.log("Move : ", e.targetTouches[0].clientX)
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (String(touchStart) !== String(touchEnd))
      if (touchEnd > 40) {
        if (touchStart - touchEnd > 120) {
          // do your stuff here for left swipe
          onArrowClick("right");
          // moveSliderRight();
        }

        if (touchStart - touchEnd < -120) {
          // do your stuff here for right swipe
          onArrowClick("left");
          // moveSliderLeft();
        }
      }
  };

  const onArrowClick = (dir) => {
    // const increment = dir === "left" ? -1 : 1;
    const oppDirection = (dir === "left") ? "right" : "left";
    // console.log(dir, oppDirection)
    // setSlideDirection(dir);
    // setSlideIn(false);

    setTimeout(() => {
      if (dir === 'left') {
        // console.log(props?.leftScreen)
        // setSlideDirection(oppDirection);
        props?.leftScreen !== null ? setSlideIn(true) : setSlideIn(false)
        props?.leftScreen !== null && props?.leftScreen()
        setTouchStart(0)
        setTouchEnd(0)
      }
      else {
        // console.log(props?.rightScreen)
        // setSlideDirection(oppDirection);
        props?.rightScreen !== null ? setSlideIn(true) : setSlideIn(false)
        props?.rightScreen !== null && props?.rightScreen()
        setTouchStart(0)
        setTouchEnd(0)
      }
    }, 100);
  };

  return (
    <>
      <Fade
        in={slideIn}
        // direction={slideDirection}
        mountOnEnter
        unmountOnExit
        easing={
          {
            enter: "cubic-bezier(0, 0.2, 0.5, 1)",
            exit: "linear"
          }
        }
        timeout={{
          enter: 300,
          exit: 200
        }}
      >
        <div
          {...props}
          onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
          onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
          onTouchEnd={() => handleTouchEnd()}
        >
          {props.children}
        </div>
      </Fade>
    </>
  );
};

export default SwipeComponent;
