import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
  // Checkbox,
  TablePagination,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  whiteSmoke,
  drawerTextColor,
  successButtonBackground,
  secondaryBlueText,
} from "../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import "../../../../styles/internal/scroll.css";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  formControl: {
    minWidth: 200,
    maxWidth: 500,
    fontFamily: 'Poppins',
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    fontFamily: 'Poppins',
  },
  tableBody: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(13),
  },
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  customerTypeTitle: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  addAndSaveButton: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0px 20px 0px",
    justifyContent: "space-between",
  },
  saveCancelButton: {
    display: "flex",
    placeSelf: "strech",
  },
  alignStateText: {
    display: "flex",
    placeContent: "flex-start",
    alignItems: "center",
  },
  rightSpacing: {
    display: "flex",
    marginLeft: "10px",
    fontSize: "0.8em",
    alignItems: "center",
    fontWeight: "normal",
  },
  editIcon: {
    heigth: "15px",
    width: "15px",
  },
  iconContainer: {
    height: 30,
    width: 30,
    background: "yellow",
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: whiteSmoke,
    overflow: "hidden",
    color: grey,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    color: secondaryBlueText,
  },
  captionDetails: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    paddingBottom: 10,
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "10px 5px 10px 5px",
      },
    },
  },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      fontFamily: 'Poppins',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
export default function ManageCustomerAccountAllowedPaymentMethods(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes = useStyles();
  const allAllowedPaymentMethods =
    props.props.allAllowedPaymentMethods &&
      props.props.allAllowedPaymentMethods.length > 0
      ? props.props.allAllowedPaymentMethods
      : [];
  const customerPaymentMethods =
    props.props.customerAllowedPaymentMethods &&
      props.props.customerAllowedPaymentMethods.length > 0
      ? props.props.customerAllowedPaymentMethods
      : [];
  const filteredAllAllowedPaymentMethods = allAllowedPaymentMethods.filter(
    (x) =>
      !customerPaymentMethods.filter((y) => y.payment_method_detail.id === x.id)
        .length
  );

  const [
    customerAllowedPaymentMethodOperation,
    setCustomerAllowedPaymentMethodOperation,
  ] = React.useState("view");
  const handleCustomerAllowedPaymentMethodOperation = (operation) => {
    setCustomerAllowedPaymentMethodOperation(operation);
  };

  React.useEffect(() => {
    if (props.props.changePaymentMethodOperation && customerAllowedPaymentMethodOperation === "add") {
      // console.log("payment")
      handleCustomerAllowedPaymentMethodOperation("view")
      setTimeout(() => {
        props.props.handleOperationChange("changePaymentMethodOperation", false)
      }, 2000);
    }
    // console.log(props)
  }, [props.props.changePaymentMethodOperation])
  return (
    <>
      <Typography className={classes.customerTypeTitle} component={"span"}>
        <div className={classes.alignStateText}>
          <Typography className={classes.titleLabelStyle}>
            ALLOWED PAYMENT METHODS
          </Typography>
        </div>
      </Typography>
      <Typography className={classes.captionDetails} component={"span"}>
        These payment methods will be visible to customer when creating an order
      </Typography>
      <ThemeProvider theme={theme}>
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="left" className={classes.tableHead}>
                    PAYMENT METHOD
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    DAYS
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableHead}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerPaymentMethods && customerPaymentMethods.length > 0
                  ? customerPaymentMethods
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          className={classes.tableBody}
                        >
                          {row.payment_method_detail
                            ? row.payment_method_detail
                              ? row.payment_method_detail.payment_method_name
                              : null
                            : null}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.tableBody}
                        >
                          {row.payment_method_detail
                            ? row.payment_method_detail
                              ? row.payment_method_detail.days
                              : null
                            : null}

                        </TableCell>

                        <TableCell
                          align="right"
                          className={classes.tableBody}
                        >
                          {props.props.removeAllowedPaymentMethodLoader ===
                            index ? (
                            <CircularProgress size={15} />
                          ) : (
                            <ToolTipComponent
                              title="You have no edit permission on this widget"
                              condition={!hasUpdateAccess(props.props.user, "customerManagement")}
                              title2={`Click to remove payment method`}
                              condition2={hasUpdateAccess(props.props.user, "customerManagement")}
                            >
                              <IconButton
                                className={classes.removeIconDimensions}
                                onClick={(evt) => {
                                  props.props.handleRemoveAllowedPaymentMethod(
                                    row.id
                                  );
                                  props.props.handleRemoveAllowedPaymentMethodCircularLoadingShow(
                                    index
                                  );
                                }}
                                disabled={
                                  hasUpdateAccess(props.props.user, "customerManagement")
                                    ? false
                                    : true
                                }
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </ToolTipComponent>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
                {customerAllowedPaymentMethodOperation === "add" ? (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.tableBody}
                    >
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.formControl}
                      >
                        <InputLabel id="paymentMethod">
                          Payment Methods
                        </InputLabel>
                        <Select
                          labelId="paymentMethod"
                          onChange={(evt) => {
                            evt.preventDefault();
                            props.props.handleAllowedPaymentMethodChange(evt);
                          }}
                          label="Payment Method"
                          MenuProps={MenuProps}
                          value={props.props.customerSelectedPaymentMethodId}
                        >
                          <MenuItem value="none" disabled={true}>
                            <em>None</em>
                          </MenuItem>
                          {filteredAllAllowedPaymentMethods &&
                            filteredAllAllowedPaymentMethods.length > 0
                            ? filteredAllAllowedPaymentMethods.map(
                              (payment, index) => (
                                <MenuItem value={payment.id} style={{ fontFamily: 'Poppins', }}>
                                  {payment.payment_method_name} ({payment.days} days)
                                </MenuItem>
                              )
                            )
                            : null}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableBody}
                    ></TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableBody}
                    ></TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {customerPaymentMethods.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={customerPaymentMethods.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )}
        </Paper>
      </ThemeProvider>

      <div className={classes.addAndSaveButton}>
        {customerAllowedPaymentMethodOperation === "view" ? (
          <ToolTipComponent
            placement='top-start'
            title="You have no edit permission on this widget"
            condition={!hasUpdateAccess(props.props.user, "customerManagement")}
          >
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddOutlinedIcon />}
              className={classes.addNewButton}
              onClick={() => {
                handleCustomerAllowedPaymentMethodOperation("add");
              }}
              disabled={
                hasUpdateAccess(props.props.user, "customerManagement")
                  ? false
                  : true
              }
            >
              ADD NEW
            </Button>
          </ToolTipComponent>
        ) : null}
        {customerAllowedPaymentMethodOperation === "view" ? null : props.props
          .saveAllowedPaymetMethodLoader ? (
          <CircularProgress disableShrink />
        ) : (
          <div className={classes.saveCancelButton}>
            <Button
              size="medium"
              disabled={!props.props.customerSelectedPaymentMethodId}
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={(evt) => {
                props.props.handleSaveCustomerAllowedPaymentMethod(evt)
              }
              }
            >
              Save
            </Button>

            <Button
              size="medium"
              onClick={() =>
                handleCustomerAllowedPaymentMethodOperation("view")
              }
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
