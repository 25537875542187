import React, { Component, forwardRef } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";

import {
  grey,
  highlitedTextColor,
  successButtonBackground,
  whiteSmoke,
  secondaryBlueText,
  lightGreen,
  primaryColor,
  drawerTextColor,
  cancelButtonBackground
} from "../../../../../constants/internal/colors";
import {
  Checkbox,
  Divider,
  Paper,
  Typography,
  Grow,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  FormHelperText,
  CircularProgress,
  ListSubheader,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Popover
} from "@material-ui/core";
import { PieChart } from "react-minimal-pie-chart";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ClearOutlinedIcon from "@material-ui/icons/CancelOutlined";
import axios from "axios";
import APIROUTES from "../../../../../constants/internal/InternalApiRoutes";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { fontWeight, textAlign } from "@mui/system";
import { lightBlue } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import { checkApiError, hasWriteAccess, hasSpecialAccess, pxToEm, hasUpdateAccess, hasApproveAccess } from "../../../../../methods";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { startCase } from "lodash";
import SnackbarComponent from "../../../common/SnackbarComponent";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ToolTipComponent from "../../../common/ToolTipComponent";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const styles = {
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "99%",
    paddingBottom: "20px",
  },
  orderOverviewCards: {
    display: "flex",
    //width: "99%",
    justifyContent: "space-evenly",
    // display: "flex",
    // gridTemplateColumns: "repeat(auto-fit, 99%)",
    gridGap: "1rem",
    // justifyContent: "space-between",
    //alignContent: "flex-start"


  },
  buyerConsigneeCustomerContainer: {
    display: "inline",
    //flex: "0 1 38%",
    //width: "50%",
    justifyContent: "space-evenly",
    padding: "20px 0px",
    width: "38%"
  },
  contractStatusCustomerBox: {

    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "5px 20px 20px 20px",
  },
  contractStatusHeadingBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10
  },
  contractStatusText: {
    color: "rgb(112,112,112)",
    fontWeight: "bold",
    fontSize: pxToEm(15),

  },
  contractStatusContainer: {
    padding: "15px 10px",
    background: whiteSmoke,
    border: "1px solid gainsboro",
    borderRadius: "4px",
    marginBottom: 20
  },
  contractStatusListStyleValue: {
    color: "rgb(112,112,112)",
    fontSize: pxToEm(14)
  },
  contractStatusItemTextStyle: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    width: "80%",
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '500',
  },
  gray: {
    color: "#666"
  },
  blue: {
    color: "#3B69B0"
  },
  orange: {
    color: "#FFAE42"
  },
  green: {
    color: "#4CAF50"
  },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
    '&:hover': {
      backgroundColor: cancelButtonBackground,
    }
  },
  successButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,

    '&:hover': {
      backgroundColor: successButtonBackground,
    }
  },
  loaderStyle: {
    color: primaryColor
  },
  buyerConsigneeCustomerBox: {

    border: "1px solid gainsboro",
    borderRadius: "15px",
  },
  itemDetailsCustomerBox: {

    border: "1px solid gainsboro",
    borderRadius: "15px",
    //width: "99%"
    padding: "15px 20px 20px 20px"
  },
  buyerConsigneeCustomerNameText: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",

  },
  buyerConsigneeCustomerBoxTitle: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    padding: "12px 0px 12px 15px",

    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  buyerTextStyle: {
    color: "rgb(112,112,112)",
    //alignItems: "right",
    textAlign: "right",
    //justifyContent: "flex-start"
    padding: "2px 0px 2px 30px"
  },
  basicInfoBoxTitle: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    padding: "0px 0px 15px 0px",

    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  keyAndValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    //paddingBottom: "8px",
    alignItems: "left",
    padding: "8px 8px",
    // textAlign: "left"

  },
  TotalDiscountAmountBox: {

    padding: "40px 40px 40px 40px",
    textAlign: "center",
    justifyContent: "space-evenly",

    fontSize: "18px",
    color: "#34619C",
    background: "#F6F7FB",
  },

  TotalApproximateAmountBox: {
    padding: "40px 40px 40px 40px",
    textAlign: "center",
    justifyContent: "space-evenly",
    fontSize: "18px",
    color: lightGreen,
    background: "#F3FAF3",
  },
  keyAndValueContainerBox: {
    background: whiteSmoke,
    border: "1px solid gainsboro",
    borderRadius: "4px",
  },
  singleDiv: {
    padding: "10px 15px 15px 15px",

  },

  singleDiscountDiv: {
    padding: "10px 15px 15px 15px",
  },

  singleApproximateAmntDiv: {
    padding: "10px 15px 15px 15px",
  },
  itemDetailContainer: {
    display: "flex",
    flex: "0 1 35%",
    flexDirection: "column",
    //width: "48%",
    paddingTop: "20px",
    paddingLeft: "20px"

  },
  itemDetailContainerLeft: {
    //width: "90%",
    padding: "20px 10px",
    background: whiteSmoke,
    border: "1px solid gainsboro",
    borderRadius: "4px"

    //justifyContent: "space-between",

  },
  itemDetailContainerRight: {
    width: "90%",
    display: "flex",
  },
  itemTextStyle: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    width: "80%",
  },
  checkboxContainerStyle: {
    display: "flex",
    // paddingTop: "20px",
    paddingLeft: "22px",
    alignItems: "center",
    marginBottom: "15px",
  },
  // discountContainerStyle: {
  //   display: "inline",
  //   paddingTop: "20px",
  //   paddingBottom: "60px",
  //   width: "38%",
  //   justifyContent: "space-evenly",
  // },
  approximateContainerStyle: {
    paddingTop: "20px",
    paddingBottom: "60px",
    width: "99%",
    justifyContent: "space-evenly",
  },
  wrapDiscountContainerStyle: {
    display: "inline",
    //flex: "0 1 33%",
    paddingTop: "20px",
    //paddingBottom: "60px",
    width: "33%",
    justifyContent: "space-evenly",

  },
  discountContainerStyleLeft: {
    //width: "50%",
    // paddingLeft: "50px",
    width: "96%",
    border: "1px solid gainsboro",
    borderRadius: "15px",

  },

  approvalContainerStyleLeft: {
    //width: "90%",
  },
  discountContainerStyleRight: {
    // width: "50%",
    //paddingTop: "20px",
    width: "96%",
    border: "1px solid gainsboro",
    borderRadius: "15px",
  },
  discountHeaderDiv: {
    padding: "10px",

    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  discountTextStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "rgb(88,88,88)"
  },
  discountListStyleDiv: {
    display: "flex",
    //placeItems: "center",
    padding: "10px 4.5px 8px 8px",
    //justifyContent: "space-between",
    //width: "40%"


  },
  approvalListStyleDiv: {
    display: "flex",
    //placeItems: "center",
    padding: "10px 8px",
    //justifyContent: "space-between",
    //width: "40%"
  },

  ApprovalContainerBox: (plantPlanning) => ({
    color: plantPlanning ? "#F3FAF3" : grey,
    background: plantPlanning ? "#F3FAF3" : whiteSmoke,
    marginBottom: "15px",

  }),

  discountListStyleText: {
    width: "70%",
    color: "rgb(88,88,88)",
    fontWeight: "bold",

  },


  approvalListStyleText: (plantPlanning) => ({
    width: "90%",
    color: plantPlanning ? lightGreen : "rgb(112,112,112)"
  }),

  discountListStyleValue: {
    width: "30%",
    color: "rgb(112,112,112)",
    textAlign: "end",
  },

  basicInfoListStyleValue: {
    width: "73%",
    color: "rgb(112,112,112)",
    alignItems: "center"
  },
  editButtonDiv: {
    padding: "45px 65px 20px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  recentActivityPaper: {
    padding: "10px 10px 10px 30px",
    width: "45%",
    marginTop: "30px",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
  recentActivityTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    alignItems: "center",
  },
  recentActivityTextDivLeft: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.6em",
  },
  peiChartContainerStyle: {
    width: "50%",
    padding: "25px",
  },
  peiChartInfoContainerStyle: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    flexDirection: "column",
    justifyContent: "center",
  },
  squareDispatchedStyle: {
    height: "20px",
    width: "20px",
    background: highlitedTextColor,
    marginRight: "10px",
  },
  squareInProcessStyle: {
    height: "20px",
    width: "20px",
    background: "#b2df8a",
    marginRight: "10px",
  },
  squareInPendingStyle: {
    height: "20px",
    width: "20px",
    background: "#a5cee3",
    marginRight: "10px",
  },
  checkboxLabelLeft: {
    color: grey,
    fontWeight: "bold",
    width: "90%",
  },
  editIcons: {
    fontSize: "small",
    marginLeft: "50%",
    cursor: "pointer",
    border: 0,
    color: "#34619C",
    fontWeight: 600,

  },
  saveIcons: { fontSize: "large", marginLeft: "5px", cursor: "pointer" },
  fieldAndIcon: {
    display: "flex",
    placeItems: "center",
    width: "90%",
    color: "rgb(112,112,112)",
    overflowY: "visible"

  },
  fieldAndIconSpecialDiscount: {
    //display: "flex",
    placeItems: "center",
    width: "30%",
    color: "rgb(112,112,112)",
    textAlign: "end"
  },
  lowPadding: {
    padding: "10px",
  },
  checkIcon: (plantPlanning) => ({
    color: plantPlanning ? successButtonBackground : grey,
  }),
  conversionDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 20px',
    marginTop: 20,
    // width: 400,
    border: "1px solid gainsboro",
    borderRadius: "15px",
  },
  conversionOrderNumberHover: {
    fontWeight: 500,
    color: "#3B69B0",

    '&:hover': {
      color: "#3B69B0",
      fontWeight: 600
    }
  }
};
const inputPropsPadding = {
  padding: "5px 5px 5px 10px",
};
class SingleContractsOrderOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editValidityDays: false,
      validityDays: this.props.orderDetails?.validity_days,
      editInternalRemark: false,
      internalRemark: null,
      editSpecialDiscountAmount: false,
      specialDiscountAmount:
        this?.props?.orderDetails?.discounts?.special_discount || 0,
      orderDetails: this.props.orderDetails,
      adminApproval: false,
      clientApproval: false,
      clientApproved: false,
      allowBreakingFifo: false,
      editAllowBreakingFIFO: false,
      rowId: this.props.orderDetails?.id,
      brokerage: null,
      new_brokerage: null,
      open: false,
      Short_Close_Reason: '',
      Item_Category: null,
      orderStatus: this.props.orderDetails?.order_status,
      modificationRemark: null,
      modificationRemarkFocus: false,
      modificationRemarkError: false,
      approveContract: false,
      rejectContract: false,
      openDialog: false,
      dialogBoxName: null,
      openContractModificationDialog: false,
      contractModifiedDataLoader: false,
      contractModifiedData: [],
      dispatchCalculation: null,
      selectedCustomerItemCategories: false,
      selectedConversionItemCategories: [],
      convertableQty: null,
      selectedCustomerItemCategoriesNotSubscribed: [],
      reasonMaster: [],
      rejectOrderDialogOpen: false,
      reject_order_reason: null,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      conversionOverviewDialog: false,
      new_rate: 0,
      convertedQuantity: null,
      convertedQuantityError: false,
      shortCloseQuantity: null,
      shortCloseQuantityError: false,
      convertedOrderData: [],
      convertedNewOrderDialog: false,
      contractCancelValid: true,
      Transition: React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      }),
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: 45 * 4 + 10,
          },
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      },
      finishBtnClicked: false,
    };
  }

  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  componentDidMount() {
    this.setState({
      validityDays: this?.props?.orderDetails?.validity_days,
      internalRemark: this?.props?.orderDetails?.internal_remark || "",
      specialDiscountAmount:
        this?.props?.orderDetails?.discounts?.special_discount || 0,
      orderDetails: this?.props?.orderDetails,
      adminApproval: this?.props?.orderDetails?.is_approved_by_admin,
      clientApproved: this?.props?.orderDetails?.is_approved_by_customer,
      clientApproval: this?.props?.orderDetails?.is_customer_approval_needed,
      allowBreakingFifo: this?.props?.orderDetails?.is_fifo_allowed,
      dispatchCalculation: this.handleDispatchedTotalQty(this.props?.dispatchCalculation)
    },
    )
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.orderDetails !== state.orderDetails) {
      return {
        validityDays: this?.props?.orderDetails?.validity_days,
        internalRemark: props?.orderDetails?.internal_remark,
        specialDiscountAmount: props?.orderDetails?.discounts?.special_discount,
        orderDetails: props?.orderDetails,
        adminApproval: props?.orderDetails?.is_approved_by_admin,
        clientApproved: props?.orderDetails?.is_approved_by_customer,
        clientApproval: props?.orderDetails?.is_customer_approval_needed,
        allowBreakingFifo: props?.orderDetails?.is_fifo_allowed,
      };
    }

    return null;
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.openContractModificationDialog !== this.state.openContractModificationDialog) {
      if (this.state.openContractModificationDialog === true) {
        // console.log("inside update")
        this.setState({ contractModifiedDataLoader: false })
        axios
          .get(`${APIROUTES.GET_CONTRACT_MODIFICATION_DETAILS}order_id=${this.props.orderDetails.id}`,
            this.getOptions(this.props.user))
          .then((response) => {
            this.setState({
              contractModifiedData: response.data
            }, () => { this.setState({ contractModifiedDataLoader: true }) })
          })
      }
    }
    if (prevProps.dispatchCalculation !== this.props.dispatchCalculation) {
      this.setState({
        dispatchCalculation: this.handleDispatchedTotalQty(this.props?.dispatchCalculation)
      })
    }
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleOpenContractModificationDialog = () => {
    this.setState({ openContractModificationDialog: true })
  }

  handleCloseContractModificationDialog = () => {
    this.setState({
      openContractModificationDialog: false,
      modificationRemark: null,
      modificationRemarkFocus: false,
      modificationRemarkError: false
    })
  }

  handleConfirmationContractModification = (logId, data) => {
    if (data === "approve") {
      this.props.approveContractModification(logId, this.props.orderDetails.id, this.state?.modificationRemark)
      this.handleCloseContractModificationDialog()
    }
    else if (data === "reject") {
      if (this.state?.modificationRemark !== null) {
        this.props.rejectContractModification(logId, this.props.orderDetails.id, this.state?.modificationRemark)
        this.handleCloseContractModificationDialog()
      }
      else {
        this.setState({ modificationRemarkFocus: true, modificationRemarkError: true })
      }
    }
  };

  handleModificationRemark = (e) => {
    console.log("hit", e.target.value)
    if (e.target.value !== '') {
      this.setState({
        modificationRemark: e.target.value,
        modificationRemarkFocus: true,
        modificationRemarkError: false,
      })
    }
    else {
      this.setState({
        modificationRemark: null,
        modificationRemarkFocus: true,
        modificationRemarkError: true
      })
    }

  }

  approveOrder = (orderData, orderId) => {
    return axios
      .patch(
        `${APIROUTES.CONTRACTS_UPDATE}${orderId}/`,
        orderData,
        this.getOptions(this.props.user)
      )
  }
  rejectOrder = (orderData, orderId) => {
    return axios
      .patch(
        `${APIROUTES.CONTRACTS_REJECT}${orderId}/`,
        orderData,
        this.getOptions(this.props.user)
      )
  }

  handleOrderApprove = (orderId) => {
    let orderData = {
      is_approved_by_admin: true,
    };
    this.approveOrder(orderData, orderId)
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Contract Approved Successfully`,
            "success"
          );
          setTimeout(() => {
            this.props.prepareSelectedOrderDataToDisplay(orderId)
            this.props.fetchPendingQuantity(orderId)
            this.handleCloseSnackbar();
          }, 3000);
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleOrderReject = (orderId, orderStatus) => {
    let cancelOrderData = {
      reason: this.state.reject_order_reason,
      order_status: 'CANCELLED'
    };
    let rejectOrderData = {
      reason: this.state.reject_order_reason,
      is_approved_by_admin: false,
      order_status: 'CANCELLED'
    }
    if (orderStatus === 'cancel') {
      this.rejectOrder(cancelOrderData, orderId)
        .then((response) => {
          if (response.status === 200) {
            this.handleOpenSnackbar(
              `Contract Cancel Successfully`,
              "success"
            );
            setTimeout(() => {
              this.props.prepareSelectedOrderDataToDisplay(orderId)
              this.props.fetchPendingQuantity(orderId)
              this.props.handleFetchPlantPlanningData()
              this.handleCloseSnackbar();
            }, 3000);

          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            checkApiError(error.response.data),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
    else {
      this.rejectOrder(rejectOrderData, orderId)
        .then((response) => {
          if (response.status === 200) {
            this.handleOpenSnackbar(
              `Contract Rejected Successfully`,
              "success"
            );
            setTimeout(() => {
              this.props.prepareSelectedOrderDataToDisplay(orderId)
              this.props.fetchPendingQuantity(orderId)
              this.handleCloseSnackbar();
            }, 3000);

          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            checkApiError(error.response.data),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
  };

  handleCloseApprove = () => {
    this.setState({
      open: false,
      brokerage: null,
      new_brokerage: null,
    });
  };

  handleAgree = () => {
    this.handleOrderApprove(this.state.rowId);
    this.setState({
      open: false,
      brokerage: null,
      new_brokerage: null,
    });
  };

  handleConfirmOrderReject = (id, orderStatus = 'reject') => {
    this.setState({
      rejectOrderDialogOpen: false,
    },
      () => { this.handleOrderReject(id, orderStatus) }
    )
  }

  handleCancelContractValid = () => {
    if (this.props?.dispatchCalculation) {
      for (let i = 0; i < this.props?.dispatchCalculation.length; i++) {
        if (this.props?.dispatchCalculation[i].planned_quantity !== null || this.props?.dispatchCalculation[i].in_plant_quantity !== null) {
          this.setState({ contractCancelValid: false })
          break
        }
        else {
          this.setState({ contractCancelValid: true })
        }
      }
    }
  }

  handleCloseOrderReject = () => {
    this.setState({
      rejectOrderDialogOpen: false,
      reject_order_reason: null,
      reasonMaster: []
    })
  }

  handleConfirmDialog = (brokerage, new_brokerage) => {
    if (brokerage !== new_brokerage) {
      this.setState({
        open: true,
        brokerage: brokerage,
        new_brokerage: new_brokerage,
      });
    } else {
      if (!this.state.finishBtnClicked) {
        console.log("clicked FINISH 2")
        this.setState({ finishBtnClicked: true })
        this.handleOrderApprove(this.state.rowId);
      }
    }
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true, convertedQuantity: '' })
  }
  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      dialogBoxName: null,
      Short_Close_Reason: '',
      Item_Category: '',
      convertedQuantity: '',
      convertedQuantityError: false,
      shortCloseQuantity: null,
      shortCloseQuantityError: false
    })
  }

  handleOpenConversionOverviewDialog = () => {
    this.setState({ conversionOverviewDialog: true, openDialog: false, })
  }
  handleCloseConversionOverviewDialog = () => {
    this.setState({
      conversionOverviewDialog: false,
      dialogBoxName: null,
      Short_Close_Reason: '',
      // Item_Category: null,
      convertedQuantity: '',
      convertedQuantityError: false
    })
  }

  handleOpenConvertedNewOrderDialog = () => {
    this.setState({
      convertedNewOrderDialog: true
    })
  }
  handleCloseConvertedNewOrderDialog = () => {
    this.setState({
      convertedNewOrderDialog: false
    })
  }

  handleNewRate = (newRate) => {
    if (Number(newRate) >= 0) {
      return Number(this.state.orderDetails.rate) + Number(newRate)
    }
    else {
      return Number(this.state.orderDetails.rate) + Number(newRate)
    }
  }

  handleOrderConversion = (orderNo) => {
    let convertOrderData = {
      item_category: this?.state?.Item_Category,
      quantity: Number(this?.state?.convertedQuantity),
      rate: this.handleNewRate(this.state?.new_rate)
    }
    // console.log(convertOrderData, orderNo)
    this.props
      .ContractConversion(convertOrderData, orderNo)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            convertedOrderData: response.data,
          })
          this.handleOpenSnackbar(
            `New Contract Created Successfully`,
            "success"
          );
          this.setState({ convertedNewOrderDialog: true })
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 3000);
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  // handleNewConvertedOrderPage = () => {
  //   this.props.history.push(`/internalContracts/singleContracts/${this.state.convertedOrderData?.id}`);
  //   this.handleCloseConvertedNewOrderDialog();
  // }

  fetchSelectedConversionItemCategories = async (orderNo) => {
    await this.props
      .fetchConversionItemCategories(orderNo)
      .then((response) => {
        if (response.status === 200) {
          // console.log("data for category", response)
          this.setState({
            selectedConversionItemCategories: response.data?.item_categories,
            convertableQty: response.data?.convertible_qty,
            selectedCustomerItemCategories: true
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching item categories",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  fetchShortCloseReasonMaster = async () => {
    await this.props
      .fetchReasonMaster("Contracts")
      .then((response) => {
        if (response.status === 200) {
          // console.log("data for category", response)
          this.setState({
            reasonMaster: response.data
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching reason master",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };


  handleShortClose = async (quantity, id) => {
    const data = {
      short_close_status: "CLOSED",
      short_close_quantity: quantity,
      reason: this.state.Short_Close_Reason,
    }
    // console.log(data)
    // console.log(id)
    await this.props
      .contractShortClose(data, id)
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Contract Short Close Successfully`,
            "success"
          );
          this.setState({
            openDialog: false,
          })
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 3000);
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while short close contract try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleShortCloseConvertContractValid = (data) => {
    let shortCloseValid = false;
    if (data.length == 0) {
      return true;
    }
    // console.log("24 ->" + moment().diff(moment(data[0]?.eway_detail?.eway_date), 'hours'))
    if (data.length > 0) {
      data.map(la => {
        la?.eway_detail !== null
          ? moment().diff(moment(la?.eway_detail.eway_date), 'hours') > 24
            ? shortCloseValid = true
            : shortCloseValid = false
          : moment().diff(moment(la?.created_at), 'hours') > 24
            ? shortCloseValid = true
            : shortCloseValid = false
        // : shortCloseValid = true
      })
      return shortCloseValid;
    }
    else if (data.length == 0) {
      return shortCloseValid;
    }
  }

  handleDropdownChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    if (e.target.name === "Item_Category") {
      console.log((this.state?.selectedConversionItemCategories.find(d => (d.id === e.target.value)))?.rate_difference)
      this.setState({ new_rate: (this.state?.selectedConversionItemCategories.find(d => (d.id === e.target.value)))?.rate_difference })
    }
    // console.log(e.target.name, e.target.value)
  }

  handleDateTimeShow = (data) => {
    return data !== null ? (
      moment(data).format("DD-MMM-YYYY") + " | " + moment(data).format("hh:mm A")
    )
      :
      "-"
  }

  handleDispatchedTotalQty = (data) => {
    let sum =
      data.length > 0
        ? data
          .map((o) => o.dispatched_quantity)
          .reduce((a, c) => {
            return Number(a) + Number(c);
          }, 0)
        : 0;
    return sum;
  };

  handleChange = (name) => (event) => {
    const { checked, value } =
      event.target < 0 ? (event.target = 0) : event.target;

    this.setState({ [name]: value });
    if (name === 'convertedQuantity') {
      // console.log(typeof value)
      this.setState({
        convertedQuantityError:
          Number(value) > Number((this.state?.convertableQty) || 0).toFixed(3)
            || Number(value) == 0
            ? true
            : false
      })
    }
    else if (name === 'shortCloseQuantity') {
      // console.log(typeof value)
      this.setState({
        shortCloseQuantityError: Number(value) > Number((this.props?.dispatchCalculation[0]?.order_available_quantity) || 0).toFixed(3)
          ? true
          : false
      })
    }
  };

  updateOrder = (orderData, orderId) => {
    return axios.patch(
      `${APIROUTES.CONTRACTS_UPDATE}${orderId}/`,
      orderData,
      this.getOptions(this.props.user)
    );
  };

  handleSaveEditIconClick = (operation, field) => {
    if (operation === "edit" && field === "validityDays") {
      //console.log("save the validity days");
      this.setState({ editValidityDays: !this.state.editValidityDays });
      // console.log(this.state.validityDays);
      let orderData = {
        customer: this.state.orderDetails?.customer?.id,
        item_category: this.state.orderDetails?.item_category?.id,
        special_discount: this.state.orderDetails?.discounts?.special_discount,
        validity_days: this.state.validityDays,
      };
      this.updateOrder(orderData, this.state.orderDetails?.id);
      setTimeout(() => {
        this.props.handleEditButton(this.state.orderDetails?.id)
      }, 1000)
    } else if (
      (operation === "cancel" || operation === "update") &&
      field === "validityDays"
    ) {
      this.setState({ editValidityDays: !this.state.editValidityDays });
    } else if (operation === "edit" && field === "internalRemark") {
      // console.log("save the internal remark");
      //console.log(this.state.orderDetails);
      this.setState({ editInternalRemark: !this.state.editInternalRemark });
      let orderData = {
        customer: this.state.orderDetails?.customer?.id,
        item_category: this.state.orderDetails?.item_category?.id,
        special_discount: this.state.orderDetails?.discounts?.special_discount,
        internal_remark: this.state.internalRemark,
      };
      this.updateOrder(orderData, this.state.orderDetails?.id);
      setTimeout(() => {
        this.props.handleEditButton(this.state.orderDetails?.id)
      }, 1000)
    } else if (
      (operation === "cancel" || operation === "update") &&
      field === "internalRemark"
    ) {
      this.setState({ editInternalRemark: !this.state.editInternalRemark });
    } else if (operation === "edit" && field === "allowBreakingFifo") {
      this.setState({ editAllowBreakingFIFO: !this.state.editAllowBreakingFIFO });
      let orderData = {
        customer: this.state.orderDetails?.customer?.id,
        item_category: this.state.orderDetails?.item_category?.id,
        special_discount: this.state.orderDetails?.discounts?.special_discount,
        internal_remark: this.state.internalRemark,
        validity_days: this.state.validityDays,
        is_fifo_allowed: this.state.allowBreakingFifo
      };
      this.updateOrder(orderData, this.state.orderDetails?.id);
      setTimeout(() => {
        this.props.handleEditButton(this.state.orderDetails?.id)
      }, 1000)
    } else if (
      (operation === "cancel" || operation === "update") &&
      field === "allowBreakingFifo"
    ) {
      this.setState({ editAllowBreakingFIFO: !this.state.editAllowBreakingFIFO });
    } else if (operation === "edit" && field === "specialDiscountAmount") {
      let orderData = {
        customer: this.state.orderDetails?.customer?.id,
        item_category: this.state.orderDetails?.item_category?.id,
        special_discount: this.state.specialDiscountAmount,
      };
      this.updateOrder(orderData, this.state.orderDetails?.id);
      setTimeout(() => {
        this.props.handleEditButton(this.state.orderDetails?.id)
      }, 1000)
      this.setState({
        editSpecialDiscountAmount: !this.state.editSpecialDiscountAmount,
      });
    } else if (
      (operation === "cancel" || operation === "update") &&
      field === "specialDiscountAmount"
    ) {
      this.setState({
        editSpecialDiscountAmount: !this.state.editSpecialDiscountAmount,
      });
    } else {
      console.log("invalid days");
    }
  };
  totalDiscount = (orderDetails) => {
    const discounts = orderDetails;
    return (
      parseFloat(discounts?.customer_discount || 0) +
      parseFloat(discounts?.city_discount || 0) +
      parseFloat(discounts?.payment_discount || 0) +
      parseFloat(this.state.specialDiscountAmount || 0)
    ).toFixed(2);
  };

  render() {
    const { classes, isMobile, } = this.props;
    const {
      editValidityDays,
      validityDays,
      editInternalRemark,
      internalRemark,
      editSpecialDiscountAmount,
      specialDiscountAmount,
      orderDetails,
      editAllowBreakingFIFO,
      allowBreakingFifo,
      dialogBoxName,
      openContractModificationDialog,
      contractModifiedDataLoader,
      contractModifiedData,
      convertedOrderData,
      openSnackbar,
      snackbarMessage,
      snackbarType,
      convertedQuantity,
      Item_Category,
      convertableQty,
    } = this.state;
    return (
      <div className={classes.SingleContractsOrderOverviewMainBody}>
        <Paper elevation={1} className={classes.paperStyle}>
          {!this.props.orderDetailsLoader &&
            <div
              className={classes.orderOverviewCards}
              style={{
                flexDirection: isMobile && 'column',
                width: isMobile && '98%',
                alignItems: isMobile && 'center'
              }}
            >
              <div
                className={classes.itemDetailContainer}
                style={{
                  width: isMobile && '100%',
                  paddingLeft: isMobile && 0,
                }}
              >
                <div className={classes.itemDetailsCustomerBox}>
                  <Typography
                    className={classes.basicInfoBoxTitle}
                  >
                    Basic Info
                  </Typography>
                  <div className={classes.itemDetailContainerLeft}>



                    <div style={{ display: "flex", marginBottom: "14px" }}>
                      <span className={classes.itemTextStyle}>Payment Term</span>
                      <span className={classes.basicInfoListStyleValue}>
                        {orderDetails?.payment_method?.payment_method_name || ""}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginBottom: "14px",
                        // placeContent: "center",
                        placeItems: "center",
                      }}
                    >
                      <span className={classes.itemTextStyle}>Validity</span>

                      {editValidityDays ? (
                        <div className={classes.fieldAndIcon}>
                          <TextField
                            //className={classes.textFieldStyles}
                            style={{ color: "rgb(190,190,190)" }}
                            fullWidth
                            type="number"
                            min="0"
                            onKeyPress={(event) => {
                              if (event?.key === "-" || event?.key === "+") {
                                event.preventDefault();
                              }
                            }}
                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                            onWheel={(e) => e.target.blur()}
                            onChange={this.handleChange("validityDays")}
                            defaultValue={this.state.validityDays < 0 ? 0 : this.state.validityDays}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              inputProps: { min: 0 },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span style={{ fontSize: "0.9em", color: grey }}>
                                    Days
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{ inputmode: "numeric", pattern: "[0-9]*" }}
                          />
                          <Tooltip title="Save" placement="bottom" arrow>
                            <IconButton className={classes.margin} size="small">
                              <CheckOutlinedIcon
                                // className={classes.saveIcons}
                                onClick={() =>
                                  this.handleSaveEditIconClick(
                                    "edit",
                                    "validityDays"
                                  )
                                }
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel" placement="bottom" arrow>
                            <IconButton className={classes.margin} size="small">
                              <ClearOutlinedIcon
                                // className={classes.saveIcons}
                                onClick={() => {
                                  this.handleSaveEditIconClick(
                                    "cancel",
                                    "validityDays"
                                  )
                                  this.props.prepareSelectedOrderDataToDisplay(this.state.orderDetails?.id);
                                }
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className={classes.fieldAndIcon}>
                          <span
                            style={{ width: "90%", marginLeft: "10%" }}
                          >
                            {validityDays < 0 ? "Expired" : `${validityDays} Days`}{" "}
                          </span>
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                            title2={`Contract status is ${this.state.orderStatus === 'CONVERSION_ALLOTMENT_HOLD' ? 'ALLOTMENT HOLD' : this.state.orderStatus}`}
                            condition2={!["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                            backgroundColor={whiteSmoke}
                          >
                            <IconButton
                              className={classes.margin}
                              size="small"
                              disabled={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) || !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                            >
                              {/* <EditOutlinedIcon
                              className={classes.editIcons}
                              onClick={() =>
                                this.handleSaveEditIconClick(
                                  "update",
                                  "validityDays"
                                )
                              }
                            /> */}
                              <button
                                className={classes.editIcons}
                                onClick={() =>
                                  this.handleSaveEditIconClick(
                                    "update",
                                    "validityDays"
                                  )
                                }
                                style={{
                                  color: !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus) && 'rgba(0, 0, 0, 0.26)',
                                }}
                              >
                                Edit
                              </button>
                            </IconButton>
                          </ToolTipComponent>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginBottom: "14px",

                      }}
                    >
                      <span className={classes.itemTextStyle}>Internal Remark</span>
                      {editInternalRemark ? (
                        <div className={classes.fieldAndIcon}>
                          <TextField
                            onChange={this.handleChange("internalRemark")}
                            defaultValue={this.state.internalRemark}
                            multiline
                            rows="2"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{ className: classes.lowPadding }}
                          />
                          <Tooltip title="Save" placement="bottom" arrow>
                            <IconButton className={classes.margin} size="small">
                              <CheckOutlinedIcon
                                onClick={() =>
                                  this.handleSaveEditIconClick(
                                    "edit",
                                    "internalRemark"
                                  )
                                }
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel" placement="bottom" arrow>
                            <IconButton className={classes.margin} size="small">
                              <ClearOutlinedIcon
                                onClick={() => {
                                  this.handleSaveEditIconClick(
                                    "cancel",
                                    "internalRemark"
                                  )
                                  this.props.prepareSelectedOrderDataToDisplay(this.state.orderDetails?.id);
                                }
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className={classes.fieldAndIcon}>
                          <span
                            style={{ width: "90%", marginLeft: "10%", wordBreak: 'break-word' }}
                          >
                            {internalRemark || "None"}
                          </span>
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                            title2={`Contract status is ${this.state.orderStatus === 'CONVERSION_ALLOTMENT_HOLD' ? 'ALLOTMENT HOLD' : this.state.orderStatus}`}
                            condition2={!["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                            backgroundColor={whiteSmoke}
                          >
                            <IconButton
                              aria-label="delete"
                              className={classes.margin}
                              size="small"
                              disabled={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) || !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                            >
                              {/* <EditOutlinedIcon
                              className={classes.editIcons}
                              onClick={() =>
                                this.handleSaveEditIconClick(
                                  "update",
                                  "internalRemark"
                                )
                              }
                            /> */}
                              <button className={classes.editIcons}
                                onClick={() =>
                                  this.handleSaveEditIconClick(
                                    "update",
                                    "internalRemark"
                                  )
                                }
                                style={{
                                  color: !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus) && 'rgba(0, 0, 0, 0.26)',
                                }}
                              >
                                Edit
                              </button>
                            </IconButton>
                          </ToolTipComponent>
                        </div>
                      )}
                    </div>

                    <div style={{ display: "flex", marginBottom: "14px" }}>
                      <span className={classes.itemTextStyle}>
                        Allow Breaking FIFO
                      </span>
                      {editAllowBreakingFIFO ? (
                        <div className={classes.fieldAndIcon}>
                          <FormControl
                            variant="outlined"
                            style={{
                              width: "90%"
                            }}
                            size="small"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Select
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              value={this.state.allowBreakingFifo}
                              label="Select"
                              onChange={this.handleChange("allowBreakingFifo")}
                              defaultValue={this.state.allowBreakingFifo}
                              MenuProps={this.state.MenuProps}
                            >
                              <MenuItem value={true}>YES</MenuItem>
                              <MenuItem value={false}>NO</MenuItem>
                            </Select>
                          </FormControl>
                          <Tooltip title="Save" placement="bottom" arrow>
                            <IconButton className={classes.margin} size="small">
                              <CheckOutlinedIcon
                                onClick={() =>
                                  this.handleSaveEditIconClick(
                                    "edit",
                                    "allowBreakingFifo"
                                  )
                                }
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel" placement="bottom" arrow>
                            <IconButton className={classes.margin} size="small">
                              <ClearOutlinedIcon
                                onClick={() => {
                                  this.handleSaveEditIconClick(
                                    "cancel",
                                    "allowBreakingFifo"
                                  )
                                  this.props.prepareSelectedOrderDataToDisplay(this.state.orderDetails?.id);
                                }
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className={classes.fieldAndIcon}>
                          <span
                            style={{ width: "90%", marginLeft: "10%" }}
                          >
                            {this.state.allowBreakingFifo ? "YES" : "NO"}
                          </span>
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                            title2={`Contract status is ${this.state.orderStatus === 'CONVERSION_ALLOTMENT_HOLD' ? 'ALLOTMENT HOLD' : this.state.orderStatus}`}
                            condition2={!["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                            backgroundColor={whiteSmoke}
                          >
                            <IconButton
                              aria-label="delete"
                              className={classes.margin}
                              size="small"
                              disabled={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) || !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                            >

                              <button className={classes.editIcons}
                                onClick={() =>
                                  this.handleSaveEditIconClick(
                                    "update",
                                    "allowBreakingFifo"
                                  )
                                }
                                style={{
                                  color: !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus) && 'rgba(0, 0, 0, 0.26)',
                                }}
                              >
                                Edit
                              </button>
                            </IconButton>
                          </ToolTipComponent>
                        </div>
                      )}
                    </div>

                    <div style={{ display: "flex", marginBottom: "14px" }}>
                      <span className={classes.itemTextStyle}>Broker Name</span>
                      <span className={classes.basicInfoListStyleValue}>
                        {orderDetails?.customer?.customer_type === "DISTRIBUTOR" || orderDetails?.customer?.customer_type === "BROKER" ?
                          `${orderDetails?.broker?.first_name + " " + orderDetails?.broker?.last_name}` : `—`}
                      </span>
                    </div>

                    <div style={{ display: "flex", marginBottom: "14px" }}>
                      <span className={classes.itemTextStyle}>Brokerage</span>
                      <span className={classes.basicInfoListStyleValue}>
                        {orderDetails?.customer?.customer_type === "DISTRIBUTOR" || orderDetails?.customer?.customer_type === "BROKER" ?
                          `${orderDetails?.new_brokerage || ""}` : `—`}
                      </span>
                    </div>

                    <div style={{ display: "flex", marginBottom: "14px" }}>
                      <span className={classes.itemTextStyle}>Customer Remark</span>
                      <span className={classes.basicInfoListStyleValue}>
                        {orderDetails?.customer_remark || ""}
                      </span>
                    </div>

                  </div>
                </div>

                <div style={{ paddingTop: "20px" }}>
                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{ timeout: 3000 }}
                  >
                    <div className={classes.contractStatusCustomerBox}>
                      <div className={classes.contractStatusHeadingBox}>
                        <Typography
                          className={classes.buyerConsigneeCustomerBoxTitle}
                          style={{
                            paddingLeft: 0,
                            fontSize: this.state?.orderStatus === "CONVERSION_ALLOTMENT_HOLD" && pxToEm(16)
                          }}
                        >
                          Contract Status
                        </Typography>
                        <Typography
                          className={classes.contractStatusText}
                          style={{
                            fontSize: this.state?.orderStatus === "CONVERSION_ALLOTMENT_HOLD" && pxToEm(14),
                            whiteSpace: this.state?.orderStatus === "CONVERSION_ALLOTMENT_HOLD" && 'initial',
                            width: this.state?.orderStatus === "CONVERSION_ALLOTMENT_HOLD" && '60%',
                            textAlign: this.state?.orderStatus === "CONVERSION_ALLOTMENT_HOLD" && 'end',
                            padding: this.state?.orderStatus === "CONVERSION_ALLOTMENT_HOLD" && '15px 0 12px'
                          }}
                        >
                          {this.state?.orderStatus === "CONVERSION_ALLOTMENT_HOLD"
                            ? 'ALLOTMENT HOLD'
                            : this.state?.orderStatus
                          }
                        </Typography>
                      </div>
                      <div>
                        <div className={classes.contractStatusContainer}>
                          <div
                            style={{
                              display: "flex",
                              width: '100%',
                            }}
                          >
                            <span
                              className={classes.contractStatusItemTextStyle}
                              style={{ width: '50%' }}
                            >
                              Created By
                            </span>
                            <div style={{
                              width: '100%',
                              textAlign: 'end'
                            }}>
                              <span className={classes.contractStatusListStyleValue}>
                                {orderDetails?.created_by && (orderDetails?.created_by).replace('-', ' (') + ")"}
                              </span>
                              <p
                                className={classes.contractStatusListStyleValue}
                                style={{ fontSize: pxToEm(12), margin: 0 }}
                              >
                                {this.handleDateTimeShow(orderDetails.created_at)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className={classes.contractStatusContainer}>
                          <div
                            style={{
                              display: "flex",
                              width: '100%',
                            }}
                          >
                            <span
                              className={classes.contractStatusItemTextStyle}
                              style={{ width: '65%' }}
                            >
                              {!this.state.adminApproval
                                ? "Approval Status"
                                : "Approved By"
                              }
                            </span>
                            <div style={{
                              width: '100%',
                              textAlign: 'end'
                            }}>
                              <span className={classes.contractStatusListStyleValue}>
                                {!this.state.adminApproval
                                  ? "PENDING"
                                  : orderDetails.approved_by_admin + " (Admin)"
                                }
                              </span>
                              <p
                                className={classes.contractStatusListStyleValue}
                                style={{ fontSize: pxToEm(12), margin: 0 }}
                              >
                                {!this.state.adminApproval
                                  ? "-"
                                  : this.handleDateTimeShow(orderDetails.approved_at_admin)
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className={classes.contractStatusContainer}>
                          <div
                            style={{
                              display: "flex",
                              width: '100%',
                            }}
                          >
                            <span
                              className={classes.contractStatusItemTextStyle}
                              style={{ width: '65%' }}
                            >
                              {!this.state.clientApproval
                                ? "Client Approval"
                                // : !this.state.adminApproval
                                // ? "Client Approval"
                                : this.state.clientApproved === null
                                  ? "Client Approval"
                                  : !this.state.clientApproved
                                    ? "Rejected By"
                                    : "Approved By"
                              }
                            </span>
                            <div style={{
                              width: '100%',
                              textAlign: 'end'
                            }}>
                              <span className={classes.contractStatusListStyleValue}>
                                {!this.state.clientApproval
                                  ? "Not Needed"
                                  //: !this.state.adminApproval
                                  // ? "After Admin Approval"
                                  : this.state.clientApproved == null
                                    ? "PENDING"
                                    : !this.state.clientApproved
                                      ? orderDetails.approved_by_customer !== null ? (orderDetails.approved_by_customer + " (Customer)") : " "
                                      : orderDetails.approved_by_customer !== null ? (orderDetails.approved_by_customer + " (Customer)") : " "
                                }
                              </span>
                              <p
                                className={classes.contractStatusListStyleValue}
                                style={{ fontSize: pxToEm(12), margin: 0 }}
                              >
                                {!this.state.clientApproval
                                  ? "-"
                                  //: !this.state.adminApproval
                                  // ? "-"
                                  : this.state.clientApproved == null
                                    ? "-"
                                    : !this.state.clientApproved
                                      ? this.handleDateTimeShow(orderDetails?.approved_at_client)
                                      : this.handleDateTimeShow(orderDetails?.approved_at_client)
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        {
                          this.state.orderStatus === "HOLD" &&
                          (
                            <>
                              <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '2px 0 10px' }}>
                                <ToolTipComponent
                                  title="You have no edit permission on this widget"
                                  condition={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    style={{ borderRadius: 0, }}
                                    disabled={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                      ? true
                                      : false
                                    }
                                  >
                                    <button
                                      className={classes.editIcons}
                                      style={{
                                        backgroundColor: drawerTextColor,
                                        marginLeft: 0,
                                        color: !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : "#34619C"
                                      }}
                                      onClick={() => this.handleOpenContractModificationDialog()}
                                    >
                                      Review Modification
                                    </button>
                                  </IconButton>
                                </ToolTipComponent>
                              </div>
                              <Dialog
                                open={openContractModificationDialog}
                                TransitionComponent={this.state.Transition}
                                keepMounted
                                onClose={this.handleCloseContractModificationDialog}
                              >
                                <DialogTitle>
                                  <p style={{ fontSize: pxToEm(12), margin: 0 }}>{`${orderDetails.order_number}`}</p>
                                  <p style={{ fontSize: pxToEm(10), margin: 0 }}>Edited by {
                                    contractModifiedDataLoader && contractModifiedData?.length > 0
                                      ? (contractModifiedData[0].requested_by) + " | " + moment(contractModifiedData[0].created_at).format("hh:mm A") + " " + moment(contractModifiedData[0].created_at).format("Do-MMM-YY")
                                      : null
                                  }
                                  </p>
                                </DialogTitle>
                                <DialogContent className={classes.dialogBox}>
                                  <div className={classes.dialogText}>
                                    <span className={classes.blue} style={{ width: 150, fontSize: pxToEm(16), fontWeight: 800 }}>
                                      Fields Name
                                    </span>
                                    <span className={classes.blue} style={{ width: 190, textAlign: 'center', fontSize: pxToEm(16), fontWeight: 800 }}>
                                      Old Value
                                    </span>
                                    <span className={classes.blue} style={{ width: 170, textAlign: 'end', fontSize: pxToEm(16), fontWeight: 800, marginRight: 16 }}>
                                      New Value
                                    </span>
                                  </div>
                                  <Divider variant='middle' style={{ margin: '2% 0', height: 3, marginRight: 16 }} />
                                  {contractModifiedDataLoader && contractModifiedData?.length > 0 ?
                                    <>
                                      <div
                                        tabIndex={0}
                                        style={{
                                          // width: "100%",
                                          height: 200,
                                          overflowY: "scroll",
                                        }}
                                      >
                                        {contractModifiedData[0].fields.slice(1,).map((item) => {
                                          return (
                                            <div style={{ marginRight: 10 }}>
                                              <div className={classes.dialogText}>
                                                <span
                                                  className={classes.blue}
                                                  style={{ width: 150 }}
                                                >
                                                  {item[0] === "is_fifo_allowed"
                                                    ? 'Is FIFO Allowed'
                                                    : startCase(item[0] === "freight_charge_percent"
                                                      ? "freight_charge_rate"
                                                      : item[0]
                                                    )}
                                                </span>
                                                <span style={{ width: 190, textAlign: 'center' }}>
                                                  {
                                                    item[1] === "" || item[1] === null
                                                      ? "---"
                                                      : ["party_ref_date"].includes(item[0])
                                                        ? moment(item[1]).format("Do MMM YY")
                                                        : item[0] === "truck_capacity"
                                                          ? item[1] + " MT"
                                                          : item[0] === "order_validity"
                                                            ? this.handleDateTimeShow(item[1])
                                                            : item[1]
                                                  }
                                                </span>
                                                <span
                                                  className={classes.orange}
                                                  style={{ width: 170, textAlign: 'end' }}
                                                >
                                                  {
                                                    item[2] === "" || item[2] === null
                                                      ? "---"
                                                      : ["party_ref_date"].includes(item[0])
                                                        ? moment(item[2]).format("Do MMM YY")
                                                        : item[0] === "truck_capacity"
                                                          ? item[2] + " MT"
                                                          : item[0] === "order_validity"
                                                            ? this.handleDateTimeShow(item[2])
                                                            : item[2]
                                                  }
                                                </span>
                                              </div>
                                              <Divider variant='middle' style={{ margin: '2% 0' }} />
                                            </div>
                                          )
                                        })
                                        }
                                      </div>
                                      <Divider variant='middle' style={{ margin: '2% 0', height: 4 }} />
                                      <div>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Remark"
                                          variant="outlined"
                                          focused={this.state?.modificationRemarkFocus || false}
                                          multiline
                                          minRows={2}
                                          onChange={this.handleModificationRemark}
                                          value={this.state?.modificationRemark}
                                          style={{
                                            width: '100%'
                                          }}
                                          error={this.state?.modificationRemarkError}
                                          placeholder="Remark is compulsory to reject changes"
                                        />
                                      </div>
                                    </>
                                    :
                                    (<CircularProgress
                                      disableShrink
                                      className={classes.loaderStyle}
                                    />)
                                  }
                                </DialogContent>
                                <DialogActions>
                                  <div>
                                    <Button
                                      onClick={this.handleCloseContractModificationDialog}
                                      style={{
                                        color: '#000',
                                        backgroundColor: drawerTextColor,
                                        fontFamily: 'Poppins',
                                      }}
                                    >
                                      CANCEL
                                    </Button>
                                    <ToolTipComponent
                                      title="You have no approve permission on this widget"
                                      condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                      title2="Contract changes is loading..."
                                      condition2={contractModifiedData?.length < 0}
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        style={{ borderRadius: 0, }}
                                        disabled={
                                          !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                            ? true
                                            : contractModifiedData?.length > 0
                                              ? false
                                              : true
                                        }
                                      >
                                        <Button
                                          type="submit"
                                          className={classes.removeButton}
                                          onClick={() => this.handleConfirmationContractModification(contractModifiedData[0].id, "reject")}
                                          style={{
                                            backgroundColor: !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                              ? 'rgba(0, 0, 0, 0.12)'
                                              : contractModifiedData?.length <= 0
                                                ? 'rgba(0, 0, 0, 0.12)'
                                                : cancelButtonBackground,
                                            color: drawerTextColor,
                                            // color: !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                            //   ? 'rgba(0, 0, 0, 0.12)'
                                            //   : contractModifiedData?.length <= 0
                                            //     ? 'rgba(0, 0, 0, 0.12)'
                                            //     : drawerTextColor
                                          }}

                                        >
                                          REJECT CHANGES
                                        </Button>
                                      </IconButton>
                                    </ToolTipComponent>
                                    <ToolTipComponent
                                      title="You have no approve permission on this widget"
                                      condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                      title2="Contract changes is loading..."
                                      condition2={contractModifiedData?.length < 0}
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        style={{ borderRadius: 0, }}
                                        disabled={
                                          !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                            ? true
                                            : contractModifiedData?.length > 0
                                              ? false
                                              : true
                                        }
                                      >
                                        <Button
                                          type="submit"
                                          className={classes.successButton}
                                          onClick={() => this.handleConfirmationContractModification(contractModifiedData[0].id, "approve")}
                                          style={{
                                            backgroundColor: !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                              ? 'rgba(0, 0, 0, 0.12)'
                                              : contractModifiedData?.length <= 0
                                                ? 'rgba(0, 0, 0, 0.12)'
                                                : successButtonBackground,
                                            color: drawerTextColor,
                                            // color: !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                            //   ? 'rgba(0, 0, 0, 0.12)'
                                            //   : contractModifiedData?.length <= 0
                                            //     ? 'rgba(0, 0, 0, 0.12)'
                                            //     : drawerTextColor
                                          }}
                                        >
                                          APPROVE CHANGES
                                        </Button>
                                      </IconButton>
                                    </ToolTipComponent>
                                  </div>

                                </DialogActions>
                              </Dialog>
                            </>
                          )
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                            title2={`Contract status is ${this.state.orderStatus === 'CONVERSION_ALLOTMENT_HOLD' ? 'ALLOTMENT HOLD' : this.state.orderStatus}`}
                            condition2={!["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                          >
                            <IconButton
                              aria-label="delete"
                              size="small"
                              style={{ borderRadius: 0, }}
                              disabled={!hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                ? true
                                : ["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)
                                  ? false
                                  : true
                              }
                            >
                              <button
                                className={classes.editIcons}
                                style={{
                                  color: !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus) && 'rgba(0, 0, 0, 0.26)',
                                  backgroundColor: drawerTextColor,
                                  marginLeft: 0
                                }}
                                onClick={() =>
                                  this.props.history.push(
                                    {
                                      pathname: `/internalContracts/editContracts/${orderDetails.id}`,
                                      state: {
                                        orderStatus: this.state?.orderStatus
                                      }
                                    }

                                  )
                                }
                              >
                                Edit Contract
                              </button>
                            </IconButton>
                          </ToolTipComponent>
                        </div>
                        {
                          this.state?.orderStatus === "PENDING" ?
                            (
                              <>
                                <Divider
                                  style={{ margin: '25px 0' }}
                                />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <span className={classes.contractStatusListStyleValue}>
                                    Do you want to approve this contract ?
                                  </span>
                                </div>
                                <div style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  margin: '30px 0',
                                  padding: '0 40px'
                                }}>
                                  <ToolTipComponent
                                    title="You have no approve permission on this widget"
                                    condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                  >
                                    <Button
                                      variant="text"
                                      disabled={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                      className={classes.contractStatusText}
                                      style={{
                                        color: grey,
                                        backgroundColor: drawerTextColor,
                                        marginLeft: 0,
                                        marginRight: 20,
                                        border: 0,
                                        fontSize: pxToEm(15),
                                        fontWeight: 600,
                                        textTransform: 'none',
                                      }}
                                      onClick={() =>
                                        this.setState({ rejectOrderDialogOpen: true },
                                          () => {
                                            this.fetchShortCloseReasonMaster()
                                          })
                                      }
                                    >
                                      Reject
                                    </Button>
                                  </ToolTipComponent>
                                  <Dialog
                                    open={this.state.rejectOrderDialogOpen}
                                    onClose={this.handleCloseOrderReject}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <div style={{
                                      borderRadius: 16,
                                      padding: 8,
                                    }}>
                                      <div className={classes.contractStatusCustomerBox}>
                                        <DialogTitle id="alert-dialog-title">
                                          Reject
                                          <p style={{ fontSize: pxToEm(10), margin: 0 }}>
                                            {'Contract | ' + orderDetails.order_number}
                                          </p>
                                        </DialogTitle>
                                        <DialogContent style={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          flexDirection: 'column',
                                          height: 120,
                                          alignContent: 'center'
                                        }}>
                                          <p>
                                            Please select reason for Contract reject
                                          </p>
                                          <div>
                                            <FormControl
                                              fullWidth
                                              size="small"
                                              variant="outlined"
                                            >
                                              <InputLabel id="rejectReason">
                                                Select Reject Reason*
                                              </InputLabel>
                                              <Select
                                                id="Reject Reason"
                                                labelId="rejectReason"
                                                variant="outlined"
                                                label="Select Reject Reason*"
                                                name="reject_order_reason"
                                                style={{ width: 340 }}
                                                MenuProps={this.state.MenuProps}
                                                value={this.state.reject_order_reason}
                                                onChange={this.handleDropdownChange}
                                              >
                                                {this.state?.reasonMaster !== undefined && this.state?.reasonMaster.length > 0
                                                  ? this.state?.reasonMaster.map((data) => (
                                                    <MenuItem key={data.reason} value={data.id}>
                                                      {data.reason}
                                                    </MenuItem>
                                                  ))
                                                  :
                                                  <CircularProgress
                                                    disableShrink
                                                    className={classes.loaderStyle}
                                                    style={{ marginLeft: 10 }}
                                                  />
                                                }
                                              </Select>
                                            </FormControl>
                                          </div>
                                        </DialogContent>
                                        <DialogActions
                                          style={{
                                            padding: '8px 24px'
                                          }}>
                                          <Button onClick={this.handleCloseOrderReject} color="primary">
                                            Cancel
                                          </Button>
                                          <Button
                                            onClick={() => this.handleConfirmOrderReject(orderDetails.id)}
                                            style={{ color: this.state.reject_order_reason && "#34619C", }}
                                            autoFocus
                                            disabled={!this.state.reject_order_reason ? true : false}
                                          >
                                            Confirm
                                          </Button>
                                        </DialogActions>
                                      </div>
                                    </div>
                                  </Dialog>
                                  <ToolTipComponent
                                    title="You have no approve permission on this widget"
                                    condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                    title2="Contract plant planning not done"
                                    condition2={!orderDetails.plant_planning}
                                    title3='Customer approval needed'
                                    condition3={orderDetails.is_customer_approval_needed && !orderDetails.is_approved_by_customer}
                                  // title3="Contract is not approved by admin"
                                  // condition3={!orderDetails.is_approved_by_admin}
                                  >
                                    <Button
                                      variant="contained"
                                      // disabled={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) || orderDetails.is_approved_by_admin}
                                      disabled={hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) && orderDetails.plant_planning && !(orderDetails.is_approved_by_admin && orderDetails.order_status === "PENDING") ? false : true}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        orderDetails?.created_by.includes("Customer")
                                          ? (this.props.history.push(
                                            {
                                              pathname: `/internalContracts/editContracts/${orderDetails.id}`,
                                              state: {
                                                orderStatus: this.state?.orderStatus,
                                                orderCreatedBy: "Customer"
                                              }
                                            }))
                                          : (this.handleConfirmDialog(
                                            this.props.orderDetails?.brokerage,
                                            this.props.orderDetails?.new_brokerage
                                          ))
                                      }}
                                      style={{
                                        // backgroundColor: hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) && !orderDetails.is_approved_by_admin ? primaryColor : 'rgba(0, 0, 0, 0.12)',
                                        // color: hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) && !orderDetails.is_approved_by_admin ? drawerTextColor : 'rgba(0, 0, 0, 0.12)',
                                        backgroundColor: hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) && orderDetails.plant_planning
                                          ? !orderDetails.is_approved_by_admin
                                            ? primaryColor
                                            : 'rgba(0, 0, 0, 0.12)'
                                          : 'rgba(0, 0, 0, 0.12)',
                                        color: hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) && orderDetails.plant_planning
                                          ? !orderDetails.is_approved_by_admin
                                            ? drawerTextColor
                                            : 'rgba(0, 0, 0, 0.12)'
                                          : 'rgba(0, 0, 0, 0.12)',
                                        fontSize: orderDetails?.created_by.includes("Customer") ? pxToEm(15) : pxToEm(18),
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        padding: orderDetails?.created_by.includes("Customer") ? '6px 12px' : '6px 25px'
                                      }}
                                    >
                                      {
                                        orderDetails?.created_by.includes("Customer")
                                          ? "Review & Approve"
                                          : "Approve"
                                      }
                                      {/* Approve */}
                                    </Button>
                                  </ToolTipComponent>
                                  <Dialog
                                    open={this.state.open}
                                    onClose={this.handleCloseApprove}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                  >
                                    <DialogTitle id="alert-dialog-title">
                                      {"Are you sure you want to approve?"}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Brokerage Rate has been changed from {this.state.brokerage} to{" "}
                                        {this.state.new_brokerage}
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={this.handleCloseApprove} color="primary">
                                        Cancel
                                      </Button>
                                      <Button onClick={this.handleAgree} color="primary" autoFocus>
                                        Approve
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              </>
                            )
                            :
                            (
                              <>
                                <div
                                  className={classes.contractStatusContainer}
                                  style={{
                                    marginTop: 30,
                                    // display: ['APPROVED', 'ONGOING', 'HOLD', 'CONVERSION_ALLOTMENT_HOLD'].includes(orderDetails.order_status) ? "flex" : 'none',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: '100%',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <span
                                      className={classes.contractStatusItemTextStyle}
                                      style={{ width: '68%' }}
                                    >
                                      {!["APPROVED", "ONGOING"].includes(orderDetails.order_status) ? 'Contract cancel unavailable' : 'Contract Cancel'}
                                    </span>
                                    <div style={{ textAlign: 'end' }}>
                                      <span className={classes.contractStatusListStyleValue}>
                                        {["APPROVED", "ONGOING"].includes(orderDetails.order_status) &&
                                          <ToolTipComponent
                                            title="You have no approve permission on this widget"
                                            condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                            title2={`Contract status is ${this.state.orderStatus === 'CONVERSION_ALLOTMENT_HOLD' ? 'ALLOTMENT HOLD' : this.state.orderStatus}`}
                                            condition2={!["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus)}
                                            title3={'Dispatch Qty is grater than 0 MT'}
                                            condition3={(Number(this.state.dispatchCalculation)).toFixed(3) > Number(0).toFixed(3)}
                                          >
                                            <IconButton
                                              aria-label="delete"
                                              size="small"
                                              style={{ borderRadius: 0, }}
                                              disabled={
                                                !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                                                !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus) ||
                                                (Number(this.state.dispatchCalculation)).toFixed(3) > Number(0).toFixed(3)

                                              }
                                            >

                                              <button
                                                className={classes.editIcons}
                                                style={{
                                                  marginLeft: 0,
                                                  padding: 0,
                                                  color: !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) || (Number(this.state.dispatchCalculation)).toFixed(3) > Number(0).toFixed(3)
                                                    ? 'rgba(0, 0, 0, 0.26)'
                                                    : !["PENDING", "APPROVED", "ONGOING"].includes(this.state.orderStatus) && 'rgba(0, 0, 0, 0.26)',
                                                }}
                                                onClick={() => {
                                                  this.setState({ rejectOrderDialogOpen: true },
                                                    () => {
                                                      this.fetchShortCloseReasonMaster()
                                                      this.handleCancelContractValid()
                                                    })
                                                }
                                                }
                                              >
                                                Cancel
                                              </button>
                                            </IconButton>
                                          </ToolTipComponent>
                                        }
                                        <Dialog
                                          open={this.state.rejectOrderDialogOpen}
                                          onClose={this.handleCloseOrderReject}
                                          aria-labelledby="alert-dialog-title"
                                          aria-describedby="alert-dialog-description"

                                        >
                                          <div style={{
                                            borderRadius: 16,
                                            padding: 8,
                                          }}>
                                            <div className={classes.contractStatusCustomerBox}>
                                              <DialogTitle id="alert-dialog-title" style={{ padding: '16px 0' }}>
                                                {orderDetails.order_status !== 'PENDING' ? 'Cancel' : 'Reject'}
                                                <p style={{ fontSize: pxToEm(10), margin: 0 }}>
                                                  {'Contract | ' + orderDetails.order_number}
                                                </p>
                                              </DialogTitle>
                                              <DialogContent style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                flexDirection: isMobile ? 'row' : 'column',
                                                height: isMobile ? 140 : 100,
                                                alignContent: 'center',
                                                padding: '0'
                                              }}>
                                                {this.state?.contractCancelValid ?
                                                  <>
                                                    <p>
                                                      {orderDetails.order_status !== 'PENDING'
                                                        ? 'Please select reason for Contract Cancel'
                                                        : 'Please select reason for Contract Reject'
                                                      }
                                                      {/* Please select reason for Contract reject */}
                                                    </p>
                                                    <div style={{ width: isMobile && '100%' }}>
                                                      <FormControl
                                                        fullWidth
                                                        size="small"
                                                        variant="outlined"
                                                        style={{ width: isMobile && '100%' }}
                                                      >
                                                        <InputLabel id="rejectReason">
                                                          Select Reject Reason*
                                                        </InputLabel>
                                                        <Select
                                                          id="Reject Reason"
                                                          labelId="rejectReason"
                                                          variant="outlined"
                                                          label="Select Reject Reason*"
                                                          name="reject_order_reason"
                                                          style={{ width: isMobile ? '98%' : 340 }}
                                                          MenuProps={this.state.MenuProps}
                                                          value={this.state.reject_order_reason}
                                                          onChange={this.handleDropdownChange}
                                                        >
                                                          {this.state?.reasonMaster !== undefined && this.state?.reasonMaster.length > 0
                                                            ? this.state?.reasonMaster.map((data) => (
                                                              <MenuItem key={data.reason} value={data.id}>
                                                                {data.reason}
                                                              </MenuItem>
                                                            ))
                                                            :
                                                            <CircularProgress
                                                              disableShrink
                                                              className={classes.loaderStyle}
                                                              style={{ marginLeft: 10 }}
                                                            />
                                                          }
                                                        </Select>
                                                      </FormControl>
                                                    </div>
                                                  </>
                                                  :
                                                  <p>
                                                    Contract cannot be cancel because of open loading advices.
                                                    <div>
                                                      Please cancel open LA first
                                                    </div>
                                                  </p>
                                                }
                                              </DialogContent>
                                              <DialogActions
                                                style={{
                                                  padding: '16px 0 0 0',
                                                  display: !this.state?.contractCancelValid && 'none'
                                                }}>
                                                <Button onClick={this.handleCloseOrderReject} color="primary">
                                                  Cancel
                                                </Button>
                                                <Button
                                                  onClick={() => this.handleConfirmOrderReject(orderDetails.id, 'cancel')}
                                                  color="primary"
                                                  autoFocus
                                                  disabled={!this.state.reject_order_reason ? true : false}
                                                >
                                                  Confirm
                                                </Button>
                                              </DialogActions>
                                            </div>
                                          </div>
                                        </Dialog>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.contractStatusContainer}>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: '100%',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <span
                                      className={classes.contractStatusItemTextStyle}
                                      style={{ width: '80%' }}
                                    >
                                      {this.state.orderStatus === "APPROVED" || this.state.orderStatus === "ONGOING"
                                        ? "Conversion available"
                                        : "Conversion unavailable"
                                      }
                                    </span>
                                    <div style={{ textAlign: 'end' }}>
                                      <span className={classes.contractStatusListStyleValue}>
                                        {this.state.orderStatus === "APPROVED" || this.state.orderStatus === "ONGOING"
                                          ? (
                                            <>
                                              <ToolTipComponent
                                                title="You have no special permission on this widget"
                                                condition={!hasSpecialAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                                title2="Contract rate is less than or equal to 0"
                                                condition2={!Number(orderDetails.rate) > 0}
                                                title3="Open Qty is less than or equal to 0"
                                                condition3={Number(this.props?.dispatchCalculation[0]?.order_available_quantity) <= 0}
                                              >
                                                <IconButton
                                                  aria-label="delete"
                                                  size="small"
                                                  style={{ borderRadius: 0, }}
                                                  disabled={
                                                    !hasSpecialAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                                                    !Number(orderDetails.rate) > 0 ||
                                                    Number(this.props?.dispatchCalculation[0]?.order_available_quantity) <= 0
                                                  }
                                                >

                                                  <button
                                                    className={classes.editIcons}
                                                    style={{
                                                      marginLeft: 0,
                                                      padding: 0,
                                                      color: !(hasSpecialAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) &&
                                                        Number(orderDetails.rate) > 0 &&
                                                        Number(this.props?.dispatchCalculation[0]?.order_available_quantity) > 0)
                                                        ? 'rgba(0, 0, 0, 0.26)'
                                                        : "#34619C"
                                                    }}
                                                    onClick={() => {
                                                      this.handleOpenDialog();
                                                      this.props.handleFetchLoadingAdvices();
                                                      this.fetchSelectedConversionItemCategories(orderDetails?.order_number)
                                                      this.setState({ dialogBoxName: "convert" })
                                                    }
                                                    }
                                                  >
                                                    Convert
                                                  </button>
                                                </IconButton>
                                              </ToolTipComponent>
                                            </>
                                          )
                                          : " "
                                        }
                                      </span>
                                      <p
                                        className={classes.contractStatusListStyleValue}
                                        style={{ fontSize: pxToEm(12), margin: 0 }}
                                      >
                                        {this.state.orderStatus === "APPROVED" || this.state.orderStatus === "ONGOING"
                                          ? " "
                                          : " "
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        }
                      </div>
                    </div>
                  </Grow>
                  <Dialog
                    open={this.state.openDialog}
                    TransitionComponent={this.state.Transition}
                    keepMounted
                    onClose={this.handleCloseDialog}
                  >
                    <DialogContent style={{
                      borderRadius: 15,
                      padding: 8,
                      width: isMobile ? '100%' : 400
                    }}>
                      <div className={classes.contractStatusCustomerBox}>
                        <div className={classes.contractStatusHeadingBox}>
                          <Typography
                            className={classes.buyerConsigneeCustomerBoxTitle}
                            style={{ paddingLeft: 0 }}
                          >
                            Contract Conversion
                          </Typography>
                        </div>
                        <div>
                          <div className={classes.contractStatusContainer}>
                            <div style={{ display: "flex", width: '100%' }}>
                              <span className={classes.contractStatusItemTextStyle}>
                                Contract Quantity
                              </span>
                              <div style={{
                                width: '100%',
                                textAlign: 'end'
                              }}>
                                <span className={classes.contractStatusListStyleValue}>
                                  {(Number(orderDetails.quantity)).toFixed(3)} MT
                                </span>
                                {/* <p
                                  className={classes.contractStatusListStyleValue}
                                  style={{ fontSize: pxToEm(12), margin: 0 }}
                                >
                                  -
                                </p> */}
                              </div>
                            </div>
                          </div>
                          <div className={classes.contractStatusContainer}>
                            <div style={{ display: "flex", width: '100%' }}>
                              <span className={classes.contractStatusItemTextStyle}>
                                Dispatched
                              </span>
                              <div style={{
                                width: '100%',
                                textAlign: 'end'
                              }}>
                                <span className={classes.contractStatusListStyleValue}>
                                  {(Number(this.state.dispatchCalculation)).toFixed(3)} MT
                                </span>
                                {/* <p
                                  className={classes.contractStatusListStyleValue}
                                  style={{ fontSize: pxToEm(12), margin: 0 }}
                                >
                                  -
                                </p> */}
                              </div>
                            </div>
                          </div>
                          <div className={classes.contractStatusContainer}>
                            <div style={{ display: "flex", width: '100%' }}>
                              <span className={classes.contractStatusItemTextStyle}>
                                Remaining
                              </span>
                              <div style={{
                                width: '100%',
                                textAlign: 'end'
                              }}>
                                <span className={classes.contractStatusListStyleValue}>
                                  {((convertableQty) || 0).toFixed(3) + 'MT'}
                                </span>
                              </div>
                            </div>
                          </div>
                          {
                            (
                              <>
                                <Divider style={{ margin: '25px 0' }} />
                                {
                                  Number(convertableQty) <= Number(0)
                                    ? <p style={{ textAlign: 'center' }}>There is no remaining quantity</p>
                                    :
                                    <>
                                      <div style={{
                                        minWidth: isMobile ? '85%' : 340,
                                        margin: '5px 0 15px'
                                      }}>
                                        <FormControl
                                          fullWidth
                                          size="small"
                                          variant="outlined"
                                        >
                                          <InputLabel id="convert">
                                            Select Category
                                          </InputLabel>
                                          <Select
                                            id="convert-select"
                                            labelId="convert"
                                            name="Item_Category"
                                            value={Item_Category ? Item_Category : ''}
                                            // onChange={this.handleChange("itemCategory")}
                                            onChange={this.handleDropdownChange}
                                            MenuProps={this.state.MenuProps}
                                            label="Select Category"
                                            style={{ width: isMobile ? '100%' : 340 }}
                                          >
                                            {this.state.selectedCustomerItemCategories ?
                                              this?.state?.selectedConversionItemCategories.map(
                                                (option, index) => {
                                                  return (
                                                    <MenuItem
                                                      key={option.id}
                                                      value={option.id}
                                                      style={{ color: "black" }}
                                                    >
                                                      {option.category_name}
                                                    </MenuItem>
                                                  );
                                                }
                                              )
                                              :
                                              <CircularProgress
                                                disableShrink
                                                className={classes.loaderStyle}
                                                style={{ marginLeft: 10 }}
                                              />}
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <div style={{
                                        minWidth: isMobile ? '85%' : 340,
                                        margin: '5px 0 15px'
                                      }}>
                                        <TextField
                                          label="Conversion Quantity"
                                          size="small"
                                          variant="outlined"
                                          value={convertedQuantity ? convertedQuantity : ''}
                                          onChange={this.handleChange("convertedQuantity")}
                                          error={this?.state?.convertedQuantityError}
                                          helperText={this?.state?.convertedQuantityError
                                            && convertedQuantity == 0
                                            ? 'Enter quantity should be greater than 0'
                                            : (convertedQuantity !== 0 && this?.state?.convertedQuantityError) && `Enter quantity should not greater than ${((convertableQty) || 0).toFixed(3)} MT`

                                          }
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <span style={{ fontSize: "0.9em", color: grey }}>
                                                  MT
                                                </span>
                                              </InputAdornment>
                                            ),
                                          }}
                                          fullWidth
                                        />
                                      </div>
                                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span className={classes.contractStatusListStyleValue}>
                                          {dialogBoxName === 'shortClose'
                                            ? "Are you sure you want to Short Close this contract?"
                                            : dialogBoxName === 'convert'
                                              ? "Are you sure you want to Convert this contract?"
                                              : null
                                          }
                                        </span>
                                      </div>
                                      <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        margin: '30px 0 0 0',
                                        padding: '0 40px'
                                      }}>
                                        <Button
                                          variant="text"
                                          // disabled={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                          className={classes.contractStatusText}
                                          style={{
                                            color: grey,
                                            backgroundColor: drawerTextColor,
                                            marginLeft: 0,
                                            marginRight: 20,
                                            border: 0,
                                            fontSize: pxToEm(15),
                                            fontWeight: 600,
                                            textTransform: 'none',
                                          }}
                                          onClick={this.handleCloseDialog}
                                        >
                                          No
                                        </Button>
                                        <ToolTipComponent
                                          title="You have no approve permission on this widget"
                                          condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                          title2="Please select item category"
                                          condition2={!this.state?.Item_Category}
                                          title3="Please enter quantity"
                                          condition3={!this.state?.convertedQuantity}
                                        >
                                          <Button
                                            variant="contained"
                                            disabled={
                                              !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                                              this.state.convertedQuantity === null ||
                                              this.state.convertedQuantity.length == 0 ||
                                              !this.state.Item_Category
                                            }
                                            style={{
                                              backgroundColor: hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                                ? this.state.Item_Category
                                                  ? (this.state.convertedQuantity !== null && this.state.convertedQuantity.length > 0)
                                                    ? primaryColor
                                                    : 'rgba(0, 0, 0, 0.26)'
                                                  : 'rgba(0, 0, 0, 0.26)'
                                                : 'rgba(0, 0, 0, 0.26)',
                                              color: drawerTextColor,
                                              fontSize: pxToEm(18),
                                              fontWeight: 600,
                                              textTransform: 'none',
                                              padding: '6px 25px'
                                            }}
                                            onClick={this.handleOpenConversionOverviewDialog}
                                          >
                                            Yes
                                          </Button>
                                        </ToolTipComponent>
                                      </div>
                                    </>
                                }
                                <Dialog
                                  open={this.state.conversionOverviewDialog}
                                  TransitionComponent={this.state.Transition}
                                  keepMounted
                                  onClose={this.handleCloseConversionOverviewDialog}
                                >
                                  <DialogContent style={{
                                    borderRadius: 15,
                                    padding: 8,
                                    width: isMobile ? '100%' : 400
                                  }}>
                                    <div className={classes.contractStatusCustomerBox}>
                                      <div className={classes.contractStatusHeadingBox}>
                                        <Typography
                                          className={classes.buyerConsigneeCustomerBoxTitle}
                                          style={{ paddingLeft: 0 }}
                                        >
                                          Conversion Details
                                        </Typography>
                                      </div>
                                      <>
                                        <div className={classes.contractStatusContainer}>
                                          <div style={{ display: "flex", width: '100%' }}>
                                            <span className={classes.contractStatusItemTextStyle}>
                                              New Item Category
                                            </span>
                                            <div style={{ width: '100%', textAlign: 'end' }}>
                                              <span className={classes.contractStatusListStyleValue} style={{ fonSize: pxToEm(18) }}>
                                                {(this.state?.selectedConversionItemCategories.find(d => (d.id === this.state.Item_Category)))?.category_name}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={classes.contractStatusContainer}>
                                          <div style={{ display: "flex", width: '100%' }}>
                                            <span className={classes.contractStatusItemTextStyle} style={{ display: "flex", alignItems: 'center' }}>
                                              New Rate
                                            </span>
                                            <div style={{ width: '100%', textAlign: 'end' }}>
                                              <span className={classes.contractStatusListStyleValue} style={{ fonSize: pxToEm(18) }}>
                                                ₹ {this.handleNewRate(this.state?.new_rate)}
                                              </span>
                                              <p style={{ margin: '2px 0 0 0', fontSize: pxToEm(10) }}>
                                                ({Number(this.state?.new_rate) >= 0
                                                  ? '₹ ' + Number(orderDetails.rate) + "  +  ₹ " + Number(this.state.new_rate)
                                                  : '₹ ' + Number(orderDetails.rate) + "  -  ₹ " + (this.state.new_rate).toString().replace("-", "")
                                                })
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={classes.contractStatusContainer}>
                                          <div style={{ display: "flex", width: '100%' }}>
                                            <span className={classes.contractStatusItemTextStyle} style={{ width: '100%' }}>
                                              Converted Quantity
                                            </span>
                                            <div style={{ width: '100%', textAlign: 'end' }}>
                                              <span className={classes.contractStatusListStyleValue} style={{ fonSize: pxToEm(18) }}>
                                                {Number(this.state.convertedQuantity).toFixed(3)} MT
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          margin: '40px 0 0 0',
                                        }}>
                                          <Button
                                            variant="text"
                                            // disabled={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                            className={classes.contractStatusText}
                                            style={{
                                              color: grey,
                                              backgroundColor: drawerTextColor,
                                              marginLeft: 0,
                                              marginRight: 20,
                                              border: 0,
                                              fontSize: pxToEm(15),
                                              fontWeight: 600,
                                              textTransform: 'none',
                                            }}
                                            onClick={this.handleCloseConversionOverviewDialog}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor: hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                                ? primaryColor
                                                : 'rgba(0, 0, 0, 0.26)',
                                              color: drawerTextColor,
                                              fontSize: pxToEm(18),
                                              fontWeight: 600,
                                              textTransform: 'none',
                                              padding: '6px 25px'
                                            }}
                                            onClick={() => {
                                              this.handleOrderConversion(orderDetails.order_number);
                                              this.handleCloseConversionOverviewDialog()
                                            }}
                                          >
                                            Confirm
                                          </Button>
                                        </div>
                                      </>
                                    </div>
                                  </DialogContent>
                                </Dialog>
                              </>
                            )
                          }
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                  <Dialog
                    open={this.state.convertedNewOrderDialog}
                    TransitionComponent={this.state.Transition}
                    keepMounted
                    onClose={() => {
                      this.handleCloseConvertedNewOrderDialog()
                      this.props.prepareSelectedOrderDataToDisplay(this.state.orderDetails.id)
                      this.props.fetchPendingQuantity(this.state.orderDetails.id)
                    }
                    }
                  >
                    <DialogContent style={{
                      borderRadius: 15,
                      padding: 8,
                      width: isMobile ? '100%' : 400
                    }}>
                      <div className={classes.contractStatusCustomerBox}>
                        <div className={classes.contractStatusHeadingBox}>
                          <Typography
                            className={classes.buyerConsigneeCustomerBoxTitle}
                            style={{ paddingLeft: 0 }}
                          >
                            Converted Contract
                          </Typography>
                        </div>
                        <>
                          <div className={classes.contractStatusContainer}>
                            <div style={{ display: "flex", width: '100%' }}>
                              <span className={classes.contractStatusItemTextStyle}>
                                Contract Number
                              </span>
                              <div style={{ width: '100%', textAlign: 'end' }}>
                                <span className={classes.contractStatusListStyleValue} style={{ fonSize: pxToEm(18) }}>
                                  {convertedOrderData?.order_number}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={classes.contractStatusContainer}>
                            <div style={{ display: "flex", width: '100%' }}>
                              <span className={classes.contractStatusItemTextStyle}>
                                Item Category
                              </span>
                              <div style={{ width: '100%', textAlign: 'end' }}>
                                <span className={classes.contractStatusListStyleValue} style={{ fonSize: pxToEm(18) }}>
                                  {(this.state?.selectedConversionItemCategories.find(d => (d.id === this.state.Item_Category)))?.category_name}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={classes.contractStatusContainer}>
                            <div style={{ display: "flex", width: '100%' }}>
                              <span className={classes.contractStatusItemTextStyle} style={{ display: "flex", alignItems: 'center' }}>
                                Quantity
                              </span>
                              <div style={{ width: '100%', textAlign: 'end' }}>
                                <span className={classes.contractStatusListStyleValue} style={{ fonSize: pxToEm(18) }}>
                                  {convertedOrderData?.quantity} MT
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={classes.contractStatusContainer}>
                            <div style={{ display: "flex", width: '100%' }}>
                              <span className={classes.contractStatusItemTextStyle} style={{ width: '100%' }}>
                                Rate
                              </span>
                              <div style={{ width: '100%', textAlign: 'end' }}>
                                <span className={classes.contractStatusListStyleValue} style={{ fonSize: pxToEm(18) }}>
                                  ₹ {convertedOrderData?.rate}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '40px 0 0 0',
                          }}>
                            <Button
                              variant="contained"
                              style={{
                                color: drawerTextColor,
                                backgroundColor: successButtonBackground,
                                marginLeft: 0,
                                marginRight: 12,
                                fontSize: pxToEm(16),
                                fontWeight: 600,
                                padding: '6px 8px',
                                textTransform: 'none',
                              }}
                              onClick={() => {
                                this.handleCloseConvertedNewOrderDialog()
                                this.props.prepareSelectedOrderDataToDisplay(this.state.orderDetails.id)
                                this.props.fetchPendingQuantity(this.state.orderDetails.id)
                                this.props.handleTabValueChanged(1);
                              }
                              }
                              startIcon={<ArrowBackIcon />}
                            >
                              Edit Plant Planning
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: primaryColor,
                                color: drawerTextColor,
                                fontSize: pxToEm(16),
                                fontWeight: 600,
                                textTransform: 'none',
                                padding: '6px 16px'
                              }}
                              onClick={() => {
                                this.handleCloseConvertedNewOrderDialog();
                                setTimeout(() => {
                                  this.props.history.push(`/internalContracts/singleContracts/${convertedOrderData?.id}`)
                                  this.props.prepareSelectedOrderDataToDisplay(convertedOrderData?.id)
                                  this.props.handleFetchPlantPlanningData(convertedOrderData?.id)
                                }, 1000);
                              }
                              }
                              endIcon={<ArrowForwardIcon />}
                            >
                              {convertedOrderData?.order_number}
                            </Button>
                          </div>
                        </>
                      </div>
                    </DialogContent>
                  </Dialog>
                  {orderDetails.converted_contracts.length > 0 &&
                    <Grow
                      in={true}
                      style={{ transformOrigin: "0 0 0" }}
                      {...{ timeout: 3500 }}
                    >
                      <div className={classes.conversionDetailBox}>
                        <div className={classes.contractStatusHeadingBox} style={{ alignItems: 'baseline', paddingBottom: 20 }}>
                          <Typography
                            className={classes.buyerConsigneeCustomerBoxTitle}
                            style={{ paddingLeft: 0, }}
                          >
                            Conversion Detail
                          </Typography>
                          <div style={{ textAlign: 'end' }}>
                            <Typography
                              className={classes.contractStatusText}
                            >
                              {`${orderDetails.order_number}`}
                            </Typography>
                            <Typography
                              className={classes.contractStatusText}
                            >
                              {`${orderDetails.item_category?.category_name}`}
                            </Typography>
                          </div>
                        </div>
                        {orderDetails.converted_contracts.map(data => {
                          return (
                            <div className={classes.contractStatusContainer}>
                              <div
                                style={{
                                  display: "flex",
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <span
                                  className={classes.contractStatusItemTextStyle}
                                  style={{ width: '20%' }}
                                >
                                  {
                                    data.contract_no === orderDetails.order_number
                                      ? 'To'
                                      : 'From'
                                  }
                                </span>
                                <div style={{
                                  width: '100%',
                                  textAlign: 'end'
                                }}>
                                  <span
                                    className={classes.conversionOrderNumberHover}
                                    style={{
                                      cursor: 'pointer'
                                    }}
                                    onClick={(e) => {
                                      // this.handleConversionInfoClose()
                                      setTimeout(() => {
                                        this.props.history.push(`/internalContracts/singleContracts/${data.contract_no === orderDetails.order_number
                                          ? data.converted_into
                                          : data.contract
                                          }`)
                                        this.props.prepareSelectedOrderDataToDisplay(
                                          data.contract_no === orderDetails.order_number
                                            ? data.converted_into
                                            : data.contract
                                        )
                                        this.props.handleFetchPlantPlanningData(
                                          data.contract_no === orderDetails.order_number
                                            ? data.converted_into
                                            : data.contract
                                        )
                                      }, 1000)
                                    }
                                    }
                                  >
                                    {
                                      data.contract_no === orderDetails.order_number
                                        ? data.converted_into_contract_no
                                        : data.contract_no
                                    }

                                  </span>
                                  <p
                                    className={classes.contractStatusListStyleValue}
                                    style={{ fontSize: pxToEm(14), margin: 0 }}
                                  >
                                    {data.quantity} MTS |
                                    {data.contract_no === orderDetails.order_number
                                      ? " ₹ " + data?.converted_into_rate
                                      : " ₹ " + data?.converted_from_rate
                                    } |
                                    {data.contract_no === orderDetails.order_number
                                      ? " " + data?.item_category
                                      : " " + data?.converted_from_item_category
                                    }
                                  </p>
                                  <p
                                    className={classes.contractStatusListStyleValue}
                                    style={{ fontSize: pxToEm(12), margin: 0 }}
                                  >
                                    by {data.created_by} {this.handleDateTimeShow(data.created_at)}
                                  </p>
                                </div>
                              </div>
                              <Divider style={{ margin: '6px 0' }} />
                              <div
                                className={classes.contractStatusListStyleValue}
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  fontSize: pxToEm(12)
                                }}>
                                <span style={{ textAlign: 'start', fontSize: pxToEm(18) }}>
                                  Modified:
                                </span>
                                <span style={{ textAlign: 'end' }}>
                                  by {data?.modified_by} {this.handleDateTimeShow(data?.modified_at)}
                                </span>
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    </Grow>
                  }
                </div>
              </div>
              <div
                className={classes.buyerConsigneeCustomerContainer}
                style={{ width: isMobile && '100%' }}
              >
                {!orderDetails?.customer ? null : (
                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{ timeout: 1000 }}
                  >
                    <div className={classes.buyerConsigneeCustomerBox}>
                      <Typography
                        className={classes.buyerConsigneeCustomerBoxTitle}
                      >
                        Booked By
                      </Typography>
                      <div className={classes.singleDiv}>
                        <div className={classes.keyAndValueContainerBox}>
                          <div className={classes.keyAndValueContainer}>
                            <span
                              className={classes.buyerConsigneeCustomerNameText}
                            >
                              Name
                            </span>
                            <span className={classes.buyerTextStyle}>{`${orderDetails?.customer?.first_name} ${orderDetails?.customer?.last_name}`}</span>
                          </div>
                          <div className={classes.keyAndValueContainer}>
                            <span
                              className={classes.buyerConsigneeCustomerNameText}
                            >
                              Type
                            </span>
                            <span className={classes.buyerTextStyle}>
                              {orderDetails?.customer?.customer_type || ""}
                            </span>
                          </div>
                          <div className={classes.keyAndValueContainer}>
                            <span
                              className={classes.buyerConsigneeCustomerNameText}
                            >
                              {!orderDetails?.customer?.gstin_number
                                ? "PAN"
                                : "GSTIN"}
                            </span>
                            <span className={classes.buyerTextStyle}>
                              {!orderDetails?.customer?.gstin_number
                                ? !orderDetails?.customer?.pan_card_number
                                  ? ""
                                  : orderDetails?.customer?.pan_card_number || ""
                                : orderDetails?.customer?.gstin_number || ""}
                            </span>
                          </div>

                          <div className={classes.keyAndValueContainer}>
                            <span className={classes.buyerConsigneeCustomerNameText}>
                              Address
                            </span>

                            <span className={classes.buyerTextStyle}>
                              {
                                (
                                  orderDetails?.customer?.address?.address ?
                                    (
                                      (orderDetails?.customer?.address?.address
                                        ? orderDetails?.customer?.address?.address + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.city
                                        ? orderDetails?.customer?.address?.city + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.state
                                        ? orderDetails?.customer?.address?.state + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.country
                                        ? orderDetails?.customer?.address?.country + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.pincode
                                        ? orderDetails?.customer?.address?.pincode
                                        : "")
                                    )
                                    :
                                    (
                                      (orderDetails?.customer?.address?.door_number
                                        ? orderDetails?.customer?.address?.door_number + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.floor_number
                                        ? orderDetails?.customer?.address?.floor_number + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.building_name
                                        ? orderDetails?.customer?.address?.building_name + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.street
                                        ? orderDetails?.customer?.address?.street + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.location
                                        ? orderDetails?.customer?.address?.location + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.district_name
                                        ? orderDetails?.customer?.address?.district_name + ", "
                                        : "")
                                      +
                                      ((orderDetails?.customer?.address?.city_name && orderDetails?.customer?.address?.district_name)
                                        ? ((orderDetails?.customer?.address?.city_name).trim().toUpperCase() !== (orderDetails?.customer?.address?.district_name).trim().toUpperCase())
                                          ? orderDetails?.customer?.address?.city_name + ", "
                                          : ""
                                        : orderDetails?.customer?.address?.city_name
                                          ? orderDetails?.customer?.address?.city_name + ", "
                                          : "")
                                      +
                                      (orderDetails?.customer?.address?.state_name
                                        ? orderDetails?.customer?.address?.state_name + ", "
                                        : "")
                                      +
                                      (orderDetails?.customer?.address?.pincode
                                        ? orderDetails?.customer?.address?.pincode
                                        : "")
                                    )
                                )
                              }
                            </span>

                          </div>
                          <div className={classes.keyAndValueContainer}>
                            <span
                              className={classes.buyerConsigneeCustomerNameText}
                            >
                              State
                            </span>
                            <span className={classes.buyerTextStyle}>
                              {orderDetails?.customer?.address?.address
                                ? orderDetails?.customer?.address?.state
                                : orderDetails?.customer?.address?.state_name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grow>
                )}
                <div style={{ display: !orderDetails?.buyer && 'none', paddingTop: "20px" }}>
                  <div className={classes.buyerConsigneeCustomerBox}>
                    {!orderDetails?.buyer ? null : (

                      <div>
                        <Typography
                          className={classes.buyerConsigneeCustomerBoxTitle}
                        >
                          Buyer Info
                        </Typography>
                        <div className={classes.singleDiv}>
                          <div className={classes.keyAndValueContainerBox}>
                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                Name
                              </span>
                              <span className={classes.buyerTextStyle}>{`${orderDetails?.buyer?.first_name || ""} ${orderDetails?.buyer?.last_name || ""
                                }`}</span>
                            </div>

                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                Type
                              </span>
                              <span className={classes.buyerTextStyle}>{orderDetails?.buyer?.customer_type || ""}</span>
                            </div>
                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                {!orderDetails?.buyer?.gstin_number ? "PAN" : "GSTIN"}
                              </span>
                              <span className={classes.buyerTextStyle}>
                                {!orderDetails?.buyer?.gstin_number
                                  ? !orderDetails?.buyer?.pan_card_number
                                    ? ""
                                    : orderDetails?.buyer?.pan_card_number || ""
                                  : orderDetails?.buyer?.gstin_number || ""}
                              </span>
                            </div>

                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                Address
                              </span>
                              <span className={classes.buyerTextStyle}>
                                {
                                  (
                                    orderDetails?.buyer?.address?.address ?
                                      (
                                        (orderDetails?.buyer?.address?.address
                                          ? orderDetails?.buyer?.address?.address + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.city
                                          ? orderDetails?.buyer?.address?.city + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.state
                                          ? orderDetails?.buyer?.address?.state + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.country
                                          ? orderDetails?.buyer?.address?.country + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.pincode
                                          ? orderDetails?.buyer?.address?.pincode
                                          : "")

                                      )
                                      :
                                      (
                                        (orderDetails?.buyer?.address?.door_number
                                          ? orderDetails?.buyer?.address?.door_number + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.floor_number
                                          ? orderDetails?.buyer?.address?.floor_number + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.building_name
                                          ? orderDetails?.buyer?.address?.building_name + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.street
                                          ? orderDetails?.buyer?.address?.street + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.location
                                          ? orderDetails?.buyer?.address?.location + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.district_name
                                          ? orderDetails?.buyer?.address?.district_name + ", "
                                          : "")
                                        +
                                        ((orderDetails?.buyer?.address?.city_name && orderDetails?.buyer?.address?.district_name)
                                          ? ((orderDetails?.buyer?.address?.city_name).trim().toUpperCase() !== (orderDetails?.buyer?.address?.district_name).trim().toUpperCase())
                                            ? orderDetails?.buyer?.address?.city_name + ", "
                                            : ""
                                          : orderDetails?.buyer?.address?.city_name
                                            ? orderDetails?.buyer?.address?.city_name + ", "
                                            : "")
                                        +
                                        (orderDetails?.buyer?.address?.state_name
                                          ? orderDetails?.buyer?.address?.state_name + ", "
                                          : "")
                                        +
                                        (orderDetails?.buyer?.address?.pincode
                                          ? orderDetails?.buyer?.address?.pincode
                                          : "")
                                      )
                                  )
                                }
                              </span>
                            </div>
                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                State
                              </span>
                              <span className={classes.buyerTextStyle}>
                                {orderDetails?.buyer?.address?.address
                                  ? orderDetails?.buyer?.address?.state
                                  : orderDetails?.buyer?.address?.state_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <div className={classes.buyerConsigneeCustomerBox}>
                    {!orderDetails?.consignee ? null : (

                      <div>
                        <Typography
                          className={classes.buyerConsigneeCustomerBoxTitle}
                        >
                          Consignee
                        </Typography>
                        <div className={classes.singleDiv}>
                          <div className={classes.keyAndValueContainerBox}>
                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                Name
                              </span>
                              <span className={classes.buyerTextStyle}>{`${orderDetails?.consignee?.consignee_detail
                                ?.first_name || ""
                                } ${orderDetails?.consignee?.consignee_detail
                                  ?.last_name || ""
                                }`}</span>
                            </div>
                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                Type
                              </span>
                              <span className={classes.buyerTextStyle}>
                                {orderDetails?.consignee?.consignee_detail
                                  ?.customer_type || ""}
                              </span>
                            </div>
                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                {!orderDetails?.consignee?.consignee_detail
                                  ?.gstin_number
                                  ? "PAN"
                                  : "GSTIN"}
                              </span>
                              <span className={classes.buyerTextStyle}>
                                {!orderDetails?.consignee?.consignee_detail
                                  ?.gstin_number
                                  ? !orderDetails?.consignee?.consignee_detail
                                    ?.pan_card_number
                                    ? ""
                                    : orderDetails?.consignee?.consignee_detail
                                      ?.pan_card_number || ""
                                  : orderDetails?.consignee?.consignee_detail
                                    ?.gstin_number || ""}
                              </span>
                            </div>

                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                Address
                              </span>
                              <span className={classes.buyerTextStyle}>
                                {
                                  (
                                    orderDetails?.consignee?.consignee_address?.address ?
                                      (
                                        (orderDetails?.consignee?.consignee_address?.address
                                          ? orderDetails?.consignee?.consignee_address?.address + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.city
                                          ? orderDetails?.consignee?.consignee_address?.city + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.state
                                          ? orderDetails?.consignee?.consignee_address?.state + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.country
                                          ? orderDetails?.consignee?.consignee_address?.country + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.pincode
                                          ? orderDetails?.consignee?.consignee_address?.pincode
                                          : "")
                                      )
                                      :
                                      (
                                        (orderDetails?.consignee?.consignee_address?.door_number
                                          ? orderDetails?.consignee?.consignee_address?.door_number + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.floor_number
                                          ? orderDetails?.consignee?.consignee_address?.floor_number + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.building_name
                                          ? orderDetails?.consignee?.consignee_address?.building_name + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.street
                                          ? orderDetails?.consignee?.consignee_address?.street + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.location
                                          ? orderDetails?.consignee?.consignee_address?.location + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.district_name
                                          ? orderDetails?.consignee?.consignee_address?.district_name + ", "
                                          : "")
                                        +
                                        ((orderDetails?.consignee?.consignee_address?.city_name && orderDetails?.consignee?.consignee_address?.district_name)
                                          ? ((orderDetails?.consignee?.consignee_address?.city_name).trim().toUpperCase() !== (orderDetails?.consignee?.consignee_address?.district_name).trim().toUpperCase())
                                            ? orderDetails?.consignee?.consignee_address?.city_name + ", "
                                            : ""
                                          : orderDetails?.consignee?.consignee_address?.city_name
                                            ? orderDetails?.consignee?.consignee_address?.city_name + ", "
                                            : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.state_name
                                          ? orderDetails?.consignee?.consignee_address?.state_name + ", "
                                          : "")
                                        +
                                        (orderDetails?.consignee?.consignee_address?.pincode
                                          ? orderDetails?.consignee?.consignee_address?.pincode
                                          : "")
                                      )
                                  )
                                }
                              </span>
                            </div>
                            <div className={classes.keyAndValueContainer}>
                              <span
                                className={classes.buyerConsigneeCustomerNameText}
                              >
                                State
                              </span>
                              <span className={classes.buyerTextStyle}>
                                {orderDetails?.consignee?.consignee_address?.address
                                  ? orderDetails?.consignee?.consignee_address?.state
                                  : orderDetails?.consignee?.consignee_address?.state_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <div className={classes.buyerConsigneeCustomerBox}>
                    {!orderDetails?.sales_person ? null : (
                      <Grow
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{ timeout: 1000 }}
                      >
                        <div>
                          <Typography
                            className={classes.buyerConsigneeCustomerBoxTitle}
                          >
                            Sales Person
                          </Typography>
                          <div className={classes.singleDiv}>
                            <div className={classes.keyAndValueContainerBox}>
                              <div className={classes.keyAndValueContainer}>
                                <span
                                  className={classes.buyerConsigneeCustomerNameText}
                                >
                                  Sales Person
                                </span>
                                <span className={classes.buyerTextStyle}>
                                  {
                                    (this.props?.users.find(d => d.id == orderDetails?.sales_person))?.fullName
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grow>
                    )}
                  </div>
                </div>
              </div>



              <div
                className={classes.wrapDiscountContainerStyle}
                style={{ width: isMobile && '100%' }}
              >

                <Grow
                  in={true}
                  style={{ transformOrigin: "0 0 0" }}
                  {...{ timeout: 3000 }}
                >


                  <div
                    className={classes.discountContainerStyleLeft}
                    style={{ width: isMobile && '100%' }}
                  >
                    <div className={classes.discountHeaderDiv} style={{ paddingLeft: 15 }}>
                      <span className={classes.discountTextStyle}>Discount</span>
                    </div>
                    <div className={classes.singleDiscountDiv}>
                      <div className={classes.TotalDiscountAmountBox}>

                        <div style={{ fontWeight: 600, fontSize: "24px" }}> ₹ {this.totalDiscount(orderDetails?.discounts)}</div>

                        <div style={{ fontWeight: 500 }}>Discount Amount</div>
                      </div>
                      <div className={classes.keyAndValueContainerBox}>

                        <div className={classes.discountListStyleDiv}>
                          <span className={classes.discountListStyleText}>
                            Customer
                          </span>
                          <span className={classes.discountListStyleValue}>
                            ₹{" "}
                            {parseFloat(
                              orderDetails?.discounts?.customer_discount || 0
                            ).toFixed(2)}
                          </span>
                        </div>
                        <div className={classes.discountListStyleDiv}>
                          <span className={classes.discountListStyleText}>City</span>
                          <span className={classes.discountListStyleValue}>
                            ₹{" "}
                            {parseFloat(
                              orderDetails?.discounts?.city_discount || 0
                            ).toFixed(2)}
                          </span>
                        </div>
                        <div className={classes.discountListStyleDiv}>
                          <span className={classes.discountListStyleText}>
                            Payment Basis
                          </span>
                          <span className={classes.discountListStyleValue}>
                            ₹{" "}
                            {parseFloat(
                              orderDetails?.discounts?.payment_discount || 0
                            ).toFixed(2)}
                          </span>
                        </div>
                        <div className={classes.discountListStyleDiv}>
                          <span className={classes.discountListStyleText}>
                            Special Discount
                          </span>
                          {editSpecialDiscountAmount ? (
                            <div className={classes.fieldAndIconSpecialDiscount}>
                              <TextField
                                className={classes.textFieldStyles}
                                type="number"
                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                onWheel={(e) => e.target.blur()}
                                onChange={this.handleChange("specialDiscountAmount")}
                                defaultValue={this.state.specialDiscountAmount.toFixed(2)}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="end">
                                      <span
                                        style={{ fontSize: "0.9em", color: grey }}
                                      >
                                        ₹
                                      </span>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <Tooltip title="Save" placement="bottom" arrow>
                                <IconButton className={classes.margin} size="small">
                                  <CheckOutlinedIcon
                                    onClick={() =>
                                      this.handleSaveEditIconClick(
                                        "edit",
                                        "specialDiscountAmount"
                                      )
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              {/* <Tooltip title="Cancel" placement="bottom" arrow>
                              <IconButton className={classes.margin} size="small">
                                <ClearOutlinedIcon
                                  onClick={() =>
                                    this.handleSaveEditIconClick(
                                      "cancel",
                                      "specialDiscountAmount"
                                    )
                                  }
                                />
                              </IconButton>
                            </Tooltip> */}
                            </div>
                          ) : (
                            <div className={classes.fieldAndIconSpecialDiscount}>
                              <span
                              // style={{ width: "50%" }}
                              >
                                ₹ {specialDiscountAmount.toFixed(2)}
                              </span>

                              {/* <Tooltip title="Edit" placement="bottom" arrow>
                              <IconButton className={classes.margin} size="small">
                                <EditOutlinedIcon
                                  className={classes.editIcons}
                                  onClick={() =>
                                    this.handleSaveEditIconClick(
                                      "update",
                                      "specialDiscountAmount"
                                    )
                                  }
                                />
                              </IconButton>
                            </Tooltip> */}
                            </div>
                          )}
                        </div>
                        <Divider variant="fullWidth" />
                        <div className={classes.discountListStyleDiv}>
                          <span className={classes.discountListStyleText}>
                            Total Discount
                          </span>
                          <span className={classes.discountListStyleValue} style={{ color: "#34619C", fontWeight: 500 }}>
                            ₹ {this.totalDiscount(orderDetails?.discounts)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grow>

                <div className={classes.approximateContainerStyle}>

                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{ timeout: 5000 }}
                  >
                    <div
                      className={classes.discountContainerStyleRight}
                      style={{ width: isMobile && '100%' }}
                    >
                      <div className={classes.discountHeaderDiv} style={{ paddingLeft: 15 }}>
                        <span className={classes.discountTextStyle}>
                          Approximate Amount
                        </span>
                      </div>
                      <div className={classes.singleApproximateAmntDiv}>
                        <div className={classes.TotalApproximateAmountBox}>

                          <div style={{ fontWeight: 600, fontSize: "24px" }}>₹{" "}
                            {((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.discounts?.special_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                              (parseFloat(
                                this.totalDiscount(orderDetails?.discounts))
                              )) *
                              parseFloat(
                                Number(orderDetails?.quantity).toFixed(3) || 0
                              ) +
                              (parseFloat(
                                Number(orderDetails?.rate).toFixed(2) || 0
                              ) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.discounts?.special_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                parseFloat(
                                  this.totalDiscount(orderDetails?.discounts)
                                )) *
                              parseFloat(
                                Number(orderDetails?.quantity).toFixed(3) || 0
                              ) *
                              (parseFloat(
                                Number(orderDetails?.item_category?.igst).toFixed(
                                  2
                                ) || 0
                              ) /
                                100)).toFixed(2)}</div>

                          <div style={{ fontWeight: 500 }}>Total Approximate Amount</div>
                        </div>
                        <div className={classes.keyAndValueContainerBox}>
                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Contract Rate
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹ {Number(orderDetails?.rate).toFixed(2) || 0}
                            </span>
                          </div>

                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Payment Basis Premium
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹ {Number(orderDetails?.discounts?.payment_premium).toFixed(2) || 0}
                            </span>
                          </div>

                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Loading Premium
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹ {orderDetails?.discounts?.loading_premium.toFixed(2)}
                            </span>
                          </div>

                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Insurance Premium
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹ {orderDetails?.discounts?.insurance_premium.toFixed(2)}
                            </span>
                          </div>

                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Special Premium
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹ {orderDetails?.discounts?.special_premium.toFixed(2)}
                            </span>
                          </div>

                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Freight Rate
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹ {Number(orderDetails?.freight_charge_percent).toFixed(2)}
                            </span>
                          </div>


                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              All Discounts
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹ {this.totalDiscount(orderDetails?.discounts)}
                            </span>
                          </div>
                          <Divider variant="fullWidth" />

                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Discounted Rate
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹{" "}
                              {(parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.discounts?.special_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                parseFloat(
                                  this.totalDiscount(orderDetails?.discounts)
                                )).toFixed(2)}{" "}
                            </span>
                          </div>
                          <Divider variant="fullWidth" />

                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              Taxable Amount
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹{" "}
                              {((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.discounts?.special_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                (parseFloat(
                                  this.totalDiscount(orderDetails?.discounts))
                                )) *
                                parseFloat(
                                  Number(orderDetails?.quantity).toFixed(3) || 0
                                )).toFixed(2)}{" "}
                            </span>
                          </div>
                          <div className={classes.discountListStyleDiv}>
                            <span className={classes.discountListStyleText}>
                              GST{" "}
                              {parseFloat(
                                Number(orderDetails?.item_category?.igst).toFixed(0) ||
                                0
                              )}{" "}
                              %
                            </span>
                            <span className={classes.discountListStyleValue}>
                              ₹{" "}
                              {Number((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.discounts?.special_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                (parseFloat(
                                  this.totalDiscount(orderDetails?.discounts))
                                )) *
                                parseFloat(
                                  Number(orderDetails?.quantity).toFixed(3) || 0
                                ) *
                                (parseFloat(
                                  Number(orderDetails?.item_category?.igst).toFixed(
                                    2
                                  ) || 0
                                ) /
                                  100)).toFixed(2)}
                            </span>
                          </div>
                          <Divider variant="fullWidth" />

                          <div className={classes.discountListStyleDiv}>

                            <span className={classes.discountListStyleText}>
                              Total Approximate Amount
                            </span>
                            <span className={classes.discountListStyleValue} style={{ color: "#34619C", fontWeight: 500 }}>
                              ₹{" "}
                              {((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.discounts?.special_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                (parseFloat(
                                  this.totalDiscount(orderDetails?.discounts))
                                )) *
                                parseFloat(
                                  Number(orderDetails?.quantity).toFixed(3) || 0
                                ) +
                                (parseFloat(
                                  Number(orderDetails?.rate).toFixed(2) || 0
                                ) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.discounts?.special_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                  parseFloat(
                                    this.totalDiscount(orderDetails?.discounts)
                                  )) *
                                parseFloat(
                                  Number(orderDetails?.quantity).toFixed(3) || 0
                                ) *
                                (parseFloat(
                                  Number(orderDetails?.item_category?.igst).toFixed(
                                    2
                                  ) || 0
                                ) /
                                  100)).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grow>
                </div>
              </div>
            </div>
          }
        </Paper>
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(SingleContractsOrderOverview));
