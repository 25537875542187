import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "./Stepper";
import CreateDispatchLogisticDetail from "./CreateDispatchLogisticDetail";
import moment from "moment";
import CreateDispatchGateIn from "./CreateDispatchGateIn";
import CreateDispatchBuyerDetails from "./CreateDispatchBuyerDetails";
import CreateDispatchAddOrderAndItems from "./CreateDispatchAddOrderAndItems";
import CreateDispatchReviewAndSubmit from "./CreateDispatchReviewAndSubmit";
import SwipeableViews from "react-swipeable-views";
import backStack from "cordova-back-stack";
import { withRouter } from "react-router";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { bottomNavigationFontColor, primaryColor } from "../../../styles/colors";
import { isThisHour } from "date-fns";
import { pxToEm } from "../../../methods";
import B2b_URL from "../../../constants/b2b/B2bRoutes";
import axios from "axios";

const styles = {
  // createDispatchBodyMain: {
  //   height: window.innerHeight - 126,
  //   display: "flex",
  //   width: "100%",
  //   justifyContent: "center",
  // },
  createDispatchMainBody: (webView, pageHeight) => ({
    height: webView ? "70%" : pageHeight - 126,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    // overflowY: "scroll",
    // paddingBottom: "56px",
  }),
  stepperContainer: (webView) => ({
    position: "fixed",
    bottom: "0px",
    width: webView ? "42%" : "100%",
    height: "56px",
  }),
  dialogTop: {
    display: "flex",
    justifyContent: "flex-end",
    // padding: "10px 26px",
    height: "5vh",
    alignItems: "center",
  },
  dialogContentContainer: {
    padding: "0px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.2em",
    height: "10vh",
  },
  dialogContainer: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "15px 20px 20px 20px"
  },
  dialogButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "8vh",
  },
  dialogButtonShow: {
    width: "90%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  yesButtonStyle: {
    width: "78px",
    backgroundColor: primaryColor,
    padding: "8px 24px",
  },
};
class CreateDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      openDialog: false,
      itemQtyError: false,
      showItemForm: false,
      showAddMoreButton: false,
      paymentMethodsDiscount: []
    };
  }
  UNSAFE_componentWillMount() {
    this.setState({
      dateToday: moment(new Date()).format("DD-MMM-yyyy"),
    });
    backStack((e) => {
      e.preventDefault();
      if (this?.state?.activeStep > 0) {
        this.handleBackStepper();
      } else {
        // this.props.history.push("/dispatch");
        if (String(localStorage.getItem("returnUrl")).includes('/dispatch/singleDispatch')) {
          this.props.history.push(String(localStorage.getItem("returnUrl")))
        }
        else if (String(localStorage.getItem("returnUrl")).includes('/dispatch')) {
          this.props.history.push("/dispatch");
        }
        else {
          this.props.history.push(String(localStorage.getItem("returnUrl")));
        }
      }
    });
  }
  componentDidMount() {
    const { state } = this.props.location
    console.log(state)
    if (!state) {
      this.props?.fetchOrderList()
    }
    if (state) {
      if (state.mode === "edit") {
        console.log(state.laDetails)
        this.props?.fetchOrderList(state.laDetails?.item_category?.id, state.laDetails?.plant?.id)
        this.props?.handleEditData(state.laDetails, state?.mode)
        this.fetchItemCategoriesPaymentDiscount(state?.laDetails?.item_category?.id)
        this.setState({ showAddMoreButton: true, showItemForm: false, })
      }
      else {
        this.props?.fetchOrderList(state.order?.item_category?.id, 0)
        this.props.fetchBuyerList()
        this.props.fetchConsigneeList()
        this.props.handleOrderSelect(state.order, state.type,)
        this.props.fetchPlantsList(state.order, state.type)
      }
    }
    console.log(window.location.pathname, String(localStorage.getItem("currentUrl")))

    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = localStorage.getItem("currentUrl")
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
    console.log(String(localStorage.getItem("returnUrl")), String(localStorage.getItem("returnUrl")).includes('/dispatch'))
    document.addEventListener("backbutton", this.loadOldUrl, false);
  }

  loadOldUrl = () => {
    this.props.history.push(String(localStorage.getItem("returnUrl")))
  }

  componentDidUpdate() {
    // if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
    //   let returnUrl = localStorage.getItem("currentUrl")
    //   localStorage.setItem("returnUrl", returnUrl);
    //   let currentUrl = window.location.pathname;
    //   localStorage.setItem("currentUrl", currentUrl);
    // }
    // document.addEventListener("backbutton", this.loadOldUrl);
  }

  handleNextStepper = () => {
    if (this.state.activeStep === 3) {
      if (!this.state.itemQtyError)
        this.setState(
          (state) => ({
            activeStep: state.activeStep + 1,
          }),
          () => {
            this.setState({ openBuyerListPopper: false });
          }
        );
    } else {
      this.setState(
        (state) => ({
          activeStep: state.activeStep + 1,
        }),
        () => {
          this.setState({ openBuyerListPopper: false });
        }
      );
    }

  };

  handleBackStepper = () => {
    this.setState(
      (state) => ({
        activeStep: state.activeStep - 1,
      }),
      () => {
        this.setState({ openBuyerListPopper: false });
      }
    );
  };

  checkQtyError = () => {
    const itemqty = this.props.setOrderAndItem
    let totalqty = 0
    itemqty &&
      Array.from(itemqty.entries()).map(
        ([key, value]) => (
          value.map(item => {
            totalqty += Number(item.quantity)
          })
        ))

    if (Number(totalqty) > Number(this.props.selectedPlantPendingQuantity) ||
      Number(totalqty) > Number(this.props.truckCapacity)
    ) {
      this.setState({
        itemQtyError: true
      }, this.handleNextStepper)
    }
    else {
      this.setState({
        itemQtyError: false
      }, this.handleNextStepper)
    }
  }

  handleShowAddMoreButton = () => {
    if (this.props.items) {
      if (this.props.items.length >= 1 && this.props.selectedPlantPendingQuantity > 0) {
        this.setState({
          showAddMoreButton: true,
          showItemForm: false,
        })
      }
      else {
        this.setState({
          showAddMoreButton: false,
          showItemForm: false,
        })
      }
    }

  }

  handleAddMoreButtonClick = () => {
    this.setState({
      showAddMoreButton: false,
      showItemForm: true,
    })
  }
  handleItemDelete = () => {
    this.setState({
      showAddMoreButton: true,
      showItemForm: false,
    })
  }

  fetchItemCategoriesPaymentDiscount = (itemID) => {
    if (itemID) {
      axios
        .get(`${B2b_URL.GET_ITEM_CATEGORIES_PAYMENT_DISCOUNT}?id=${itemID}`)
        .then((response) => {
          this.setState({ paymentMethodsDiscount: response.data });
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  render() {
    const { activeStep, itemQtyError, showAddMoreButton, showItemForm, paymentMethodsDiscount } = this.state;
    const {
      isMobile,
      classes,
      webView,
      orders,
      user,
      items,
      gateInDate,
      selectedPlant,
      truckNumber,
      truckCapacity,
      transporterName,
      transporterContactNumber,
      driverName,
      driverContactNumber,
      buyerName,
      consigneeName,
      paymentMethod,
      handleTextChange,
      handleDateChange,
      handleTextChangeErrorCheck,
      pageHeight,
      ordersList,
      ordersListLoader,
      selectedLAItems,
      setItem,
      setItemQuantity,
      setOrderId,
      selectedBuyer,
      selectedConsignee,
      plantsList,
      setOrderAndItem,
      selectedOrderQuantity,
      addAnotherOrder,
      openDialog,
      pendingCategories,
      showKeys,
      selectedOrder,
      selectedOrderId,
      selectedOrderItemCategory,
      selectedPlantPendingQuantity,
      plantPlanningLoader,
      transType,
      selectedTransType,
      driverLicenseNumber,
      showLACreateError,
      capacityError,
      transporterContactNumberLengthError,
      driverContactNumberLengthError,
      truckNumberValidationError,
      driverLicenseNumberError,
      driverNameError,
      transporterNameError,
      isTransporterAvailable,
      truckCapacityError,
      LASubmitLoader,
      customerRemark,
      mode,
      subContract,
      selectedEditSubContractNumber,
      subContractNumber,
      selectedSubContractDetail,
      selectedSubContractPayment,
    } = this.props;
    const { state } = this.props.location
    // console.log(this.props?.mode)
    // console.log(this.state)
    return (
      <div>
        {!isMobile ? (
          <div style={{ padding: "10px 0px 10px 0px" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                this.props.handleCloseLAForm()
              }}
            >
              <Close />
            </IconButton>
            <span style={{ color: bottomNavigationFontColor }}>
              {state?.mode === 'edit'
                ? `Edit | ${state?.laDetails?.la_number}`
                : "New Loading Advice"
              }
            </span>
          </div>
        ) : null}
        <div style={styles.createDispatchMainBody(webView, pageHeight)}>
          <SwipeableViews
            index={this.state.activeStep}
            onChangeIndex={this.handleSwipeableIndex}
            disabled
          >
            <CreateDispatchGateIn
              {...this.props}
              location={this.props.location}
              laState={state}
              isMobile={isMobile}
              handleTextChange={handleTextChange}
              handleDateChange={handleDateChange}
              gateInDate={gateInDate}
              selectedPlantAndItem={this.props.selectedPlantAndItem}
              pageHeight={pageHeight}
              plants={plantsList}
              pendingCategories={pendingCategories}
              showKeys={showKeys}
              fetchPlantsList={this.props.fetchPlantsList}
              // For Order Select
              selectedOrderId={selectedOrderId}
              selectedOrder={selectedOrder}
              openBuyerListPopper={this.state.openBuyerListPopper}
              orders={ordersList}
              ordersListLoader={ordersListLoader}
              items={items}
              // handleTextChange={handleTextChange}
              truckCapacity={truckCapacity}
              // pageHeight={pageHeight}
              selectedLAItems={selectedLAItems}
              setOrderIdAndAmount={this.props.setOrderIdAndAmount}
              handleSaveOrderData={this.props.handleSaveOrderData}
              handleSetOrderItem={this.props.handleSetOrderItem}
              handleOrderSelect={this.props.handleOrderSelect}
              deleteOrder={this.props.deleteOrder}
              deleteOrderItem={this.props.deleteOrderItem}
              handleSelectOrderId={this.props.handleSelectOrderId}
              setItem={setItem}
              setItemQuantity={setItemQuantity}
              setOrderId={setOrderId}
              selectedOrderQuantity={selectedOrderQuantity}
              setOrderAndItem={setOrderAndItem}
              addAnotherOrder={addAnotherOrder}
              selectedOrderItemCategory={selectedOrderItemCategory}
              plantPlanningLoader={plantPlanningLoader}
              transType={transType}
              handleTransTypeSelect={this.props.handleTransTypeSelect}
              selectedTransType={selectedTransType}
              createLAAllowed={this.props.createLAAllowed}
              showLACreateError={showLACreateError}
              createLAType={this.props.location.state}
              fetchItemCategoriesPaymentDiscount={this.fetchItemCategoriesPaymentDiscount}
              user={user}
              laDetails={this.props?.laDetails}
              mode={this.props?.laDetails}
              handleSubcontractFormDetail={this.props.handleSubcontractFormDetail}
              subContractDropdownList={this.props.subContractDropdownList}
              subContractDropdownListLoader={this.props.subContractDropdownListLoader}
              plantSubContractEnabled={this.props.plantSubContractEnabled}
              selectedEditSubContractNumber={selectedEditSubContractNumber}
              subContractNumber={subContractNumber}
            />
            <CreateDispatchLogisticDetail
              truckNumber={truckNumber}
              truckCapacity={truckCapacity}
              truckCapacityError={truckCapacityError}
              transporterName={transporterName}
              transporterContactNumber={transporterContactNumber}
              driverName={driverName}
              driverContactNumber={driverContactNumber}
              handleTextChange={handleTextChange}
              pageHeight={pageHeight}
              driverLicenseNumber={driverLicenseNumber}
              checkError={this.props.checkError}
              transporterContactNumberLengthError={transporterContactNumberLengthError}
              driverContactNumberLengthError={driverContactNumberLengthError}
              truckNumberValidationError={truckNumberValidationError}
              driverLicenseNumberError={driverLicenseNumberError}
              driverNameError={driverNameError}
              transporterNameError={transporterNameError}
              isTransporterAvailable={isTransporterAvailable}
              handleShowTransporterTextFields={this.props.handleShowTransporterTextFields}
              handleTransportCancel={this.props.handleTransportCancel}
              user={user}
              laState={state}
            />
            <CreateDispatchBuyerDetails
              buyerName={buyerName}
              consigneeName={consigneeName}
              paymentMethod={paymentMethod}
              handleSelectBuyerName={this.props.handleSelectBuyerName}
              handleSelectConsigneeName={this.props.handleSelectConsigneeName}
              handleSelectPaymentMethod={this.props.handleSelectPaymentMethod}
              pageHeight={pageHeight}
              buyers={this.props.buyersList}
              consignee={this.props.consigneesList}
              selectedBuyerDataForLA={this.props.selectedBuyerDataForLA}
              selectedConsigneeDataForLA={this.props.selectedConsigneeDataForLA}
              paymentMethods={this.props.paymentMethods}
              selectedPaymentMethodDataForLA={this.props.selectedPaymentMethodDataForLA}
              paymentMethodsDiscount={paymentMethodsDiscount}
              isMobile
              laState={state}
              subContract={subContract}
              subContractNumber={subContractNumber}
              selectedEditSubContractNumber={selectedEditSubContractNumber}
              selectedSubContractPayment={selectedSubContractPayment}
            />
            <CreateDispatchAddOrderAndItems
              openBuyerListPopper={this.state.openBuyerListPopper}
              orders={ordersList}
              items={items}
              handleTextChange={handleTextChange}
              handleTextChangeErrorCheck={handleTextChangeErrorCheck}
              setItemQuantityError={this.props.setItemQuantityError}
              truckCapacity={truckCapacity}
              pageHeight={pageHeight}
              selectedLAItems={selectedLAItems}
              customerRemark={customerRemark}
              setOrderIdAndAmount={this.props.setOrderIdAndAmount}
              handleSaveOrderData={this.props.handleSaveOrderData}
              handleSetOrderItem={this.props.handleSetOrderItem}
              handleOrderSelect={this.props.handleOrderSelect}
              deleteOrder={this.props.deleteOrder}
              deleteOrderItem={this.props.deleteOrderItem}
              handleSelectOrderId={this.props.handleSelectOrderId}
              setItem={setItem}
              setItemQuantity={setItemQuantity}
              setOrderId={setOrderId}
              selectedOrderQuantity={selectedOrderQuantity}
              setOrderAndItem={setOrderAndItem}
              addAnotherOrder={addAnotherOrder}
              selectedPlantPendingQuantity={selectedPlantPendingQuantity}
              itemQtyError={itemQtyError}
              checkQtyError={this.checkQtyError}
              showAddMoreButton={showAddMoreButton}
              showItemForm={showItemForm}
              handleAddMoreButtonClick={this.handleAddMoreButtonClick}
              handleShowAddMoreButton={this.handleShowAddMoreButton}
              handleItemDelete={this.handleItemDelete}
              checkItemQtyError={this.props.checkItemQtyError}
              handleShowQuantityError={this.props.handleShowQuantityError}
              capacityError={capacityError}
              laState={state}
              handleItemEdit={this.props?.handleItemEdit}
              subContract={subContract}
            />
            <CreateDispatchReviewAndSubmit
              {...this.props}
            />
          </SwipeableViews>
        </div>

        <div style={styles.stepperContainer(webView)}>
          <Stepper
            {...this.props}
            activeStep={activeStep}
            handleNextStepper={this.handleNextStepper}
            handleBackStepper={this.handleBackStepper}
            checkQtyError={this.checkQtyError}
            gateInDate={gateInDate}
            selectedPlant={selectedPlant}
            truckNumber={truckNumber}
            truckCapacity={truckCapacity}
            transporterName={transporterName}
            transporterContactNumber={transporterContactNumber}
            driverName={driverName}
            driverContactNumber={driverContactNumber}
            buyerName={buyerName}
            consigneeName={consigneeName}
            paymentMethod={paymentMethod}
            selectedBuyer={selectedBuyer}
            selectedConsignee={selectedConsignee}
            handleLASubmit={this.props.handleLASubmit}
            setOrderId={setOrderId}
            setOrderAndItem={setOrderAndItem}
            handleCloseLAForm={this.props.handleCloseLAForm}
            LASubmitLoader={LASubmitLoader}
            laState={state}
            subContractDropdownList={this.props.subContractDropdownList}
            plantSubContractEnabled={this.props.plantSubContractEnabled}
            subContract={subContract}
          />
        </div>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          PaperProps={{
            style: {
              width: !isMobile && "312px",
              height: !isMobile && '235px',
              overflowY: "inherit",
              borderRadius: "15px",
              alignItems: 'flex-start',
              padding: 8
            },
          }}
        >
          <div style={styles.dialogContainer}>
            <div className={classes.dialogTop} style={{ height: !isMobile && 30 }}>
              <Close
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  // this.setState({ openDialog: false });
                  this.props.handleCloseLAForm("goBack")
                }}
              />
            </div>
            <div className={classes.dialogContentContainer} style={{ height: !isMobile && 120 }}>
              <span>Are you sure want to cancel?</span>
            </div>
            <div className={classes.dialogButtonContainer} style={{ height: !isMobile && 30 }}>
              <div className={classes.dialogButtonShow}>
                <Button className={classes.goBackButton}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleCloseLAForm("goBack")
                  }}
                >Go Back</Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.yesButtonStyle}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleCloseLAForm("clearData")
                    this.props.clearAllCreateDispatchData()
                    if (state?.mode === 'edit') {
                      this.props.history.push("/dispatch/")
                      this.props?.fetchLADetails(state?.laDetails?.la_number)
                      // this.props.history.push(`/dispatch/singleDispatch/${state?.laDetails?.la_number}`)
                      // this.props.history.push(String(localStorage.getItem("returnUrl")))
                    }
                    else {
                      this.props.history.goBack();
                    }
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(CreateDispatch));
