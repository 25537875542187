import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import {
  dailyRateScreenTextColor,
  secondaryColor,
} from "../../../styles/colors";
import SpinLoader from "../../common/SpinLoader";
import "../../../styles/Register.css";
import Checkbox from "@material-ui/core/Checkbox";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { checkInvalidValue } from "../../../methods";

const styles = {
  registerGstNumberbody: {
    height: window.innerHeight - 56,
    padding: "0px 20px ",
    overflowY: "scroll",
  },
  textFieldStyle: {
    width: "90%",
    backgroundColor: "white",
  },
  textFieldStyle_one: {
    width: "100%",
  },
  gstNumberStyle: {
    color: dailyRateScreenTextColor,
    fontSize: " 1.3em",
    marginLeft: "30px",
    textTransform: "uppercase",
  },
  panNumberContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  panCardImageContainer: {
    width: "90%",
    display: "flex",
    // flexDirection: "column",
    alignItems: "flex-end",
  },
  panCardImageStyle: {
    width: "40%",
    minHeight: "100px",
    border: "1px dashed #636363",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#636363",
  },
};
class RegisterGstNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GST_LengthError: false,
      dontHaveGST: false,
      panCardActiveStatus: false,
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  handleGetGST = () => {
    this.props.handleGetGSTInfo(this.props.Gst_number.toUpperCase());
  };
  handleChangeCheckBox = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
        GST_LengthError: false,
        gstNumberCheck: false,
        gstNumberAlreadyRegistered: false,
      },
      () => {
        this.props.handleDontHaveGST();
        if (this.state.dontHaveGST) {
          this.props.resetGstDetail();
        }
      }
    );
  };
  handleFileTypeError = (type) => {
    // console.log(type)
    if (type === "application/pdf" || type === "image/png" || type === "image/jpeg" || type === "image/jpg") {
      return false
    } else return true

  }
  handleChangeCheckBoxPanActiveStatus = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
      },
      this.props.handlePancardActiveStatus()
    );
  };
  handlePanImageChange = (e) => {
    if (e.target.files.length) {
      this.setState(
        {
          PanImagePreview: URL.createObjectURL(e.target.files[0]),
          rawPanImage: e.target.files[0],
          PanImagePreviewLoader: false,
          // panImageFileTypeError: this.handleFileTypeError(e.target.files[0].type)
        },
        () => {
          this.props.handlePanCardImageFile(this.state.rawPanImage);
        }
      );
    }
  };
  handleDeletePanImage = () => {
    this.setState(
      {
        PanImagePreview: "",
        rawPanImage: null,
        PanImagePreviewLoader: true,
        // panImageFileTypeError: false
      },
      () => {
        this.props.handlePanCardImageFile(this.state.rawPanImage);
      }
    );
  };
  render() {
    const {
      GST_LengthError,
      dontHaveGST,
      panCardActiveStatus,
      panNumberLengthError,
    } = this.state;
    const {
      classes,
      Gst_number,
      gstNumberCheck,
      gstLoader,
      gstData,
      gstNumberAlreadyRegistered,
      state,
      pincode,
      addressLine_one,
      panNumber,
      gstErrorMessage,
      panNumber_Error,
      Gst_number_Error,
      panCardHolderName_Error,
      companyName_Error,
    } = this.props;
    return (
      <div className={classes.registerGstNumberbody}>
        <div className="register-gst-number-header">
          <span className="register-gst-number-header-text">
            {dontHaveGST ? "PAN Information" : "GST Information"}
          </span>
        </div>
        {!dontHaveGST && <div className="gst-input-fiels-start">
          <TextField
            disabled={dontHaveGST}
            name="Gst_number"
            id="outlined-name"
            label="GST NO. *"
            margin="normal"
            variant="outlined"
            value={Gst_number.toUpperCase()}
            error={GST_LengthError ? true : false}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            inputProps={{
              maxLength: 15,
            }}
            FormHelperTextProps={{
              style: { marginLeft: "5px", marginRight: "0px" },
            }}
            helperText={
              <span style={{ color: "red" }}>
                {Gst_number_Error
                  ? "Please enter valid 15 digit GST number"
                  : Gst_number.length === 15 && gstNumberCheck === "invalid"
                    ? gstErrorMessage
                    : null}
              </span>
            }
            onFocus={() => {
              this.props.resetGstDetail();
              this.props.handleResetGSTdetails();
              this.setState({ GST_LengthError: false });
              if (window.cordova) {
                document.querySelector("BODY").style.position = "fixed";
                document.querySelector("BODY").style.width = "100vw";
              }
            }}
            onBlur={() => {
              // if (Gst_number.length < 15) {
              //   this.setState({ GST_LengthError: true });
              // }
              if (window.cordova) {
                document.querySelector("BODY").style.position = "unset";
                document.querySelector("BODY").style.width = null;
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disabled={dontHaveGST}
                    style={{
                      fontSize: "0.7em",
                      textTransform: "capitalize",
                      padding: "6px 0px",
                      color: secondaryColor,
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      if (Gst_number.length < 15) {
                        this.setState({ GST_LengthError: true });
                      } else {
                        // this.props.handleShowGSTdetails();
                        this.handleGetGST();
                      }
                    }}
                  >
                    Get Details
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "90%" }}>
            <Checkbox
              style={{
                color: dontHaveGST ? secondaryColor : null,
              }}
              checked={this.state.dontHaveGST}
              onChange={this.handleChangeCheckBox("dontHaveGST")}
              value="dontHaveGST"
            />
            <span>I don't have GSTIN</span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {gstLoader ? (
            <div>
              <SpinLoader />
            </div>
          ) : null}
        </div>
        {gstNumberCheck === "pending" ||
          (gstNumberCheck === "invalid" && dontHaveGST === false) ? null : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "15px",
            }}
          >
            <div style={{ width: "90%" }}>
              <div className="gst-legal-name-container">
                <span className="legal-name-text">Legal name</span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {gstData.lgnm}
                </span>
              </div>

              <div className="gst-legal-name-container">
                <span className="legal-name-text">Trade name</span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {gstData.tradeNam}
                  {/* {checkInvalidValue(gstData.tradeNam) ? gstData.lgnm : gstData.tradeNam} */}
                </span>
              </div>

              <div className="gst-jurisdiction-container">
                <div className="gst-legal-name-container">
                  <span className="legal-name-text">Center Jurisdiction</span>
                  <span
                    className={classes.gstCompanyNameText}
                    style={{
                      color: dailyRateScreenTextColor,
                      fontSize: " 1.2em",
                    }}
                  >
                    {gstData.ctj}
                  </span>
                </div>
                <div className="gst-state-juridiction-container">
                  <span className="legal-name-text">State Jurisdiction</span>
                  <span
                    className={classes.gstCompanyNameText}
                    style={{
                      color: dailyRateScreenTextColor,
                      fontSize: " 1.2em",
                    }}
                  >
                    {gstData.stj}
                  </span>
                </div>
              </div>

              <div className="gst-legal-name-container">
                <span className="legal-name-text">Date of registration</span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {gstData.rgdt}
                </span>
              </div>

              <div className="gst-legal-name-container">
                <span className="legal-name-text">
                  Constitution of bussiness
                </span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {gstData.ctb}
                </span>
              </div>

              <div className="gst-legal-name-container">
                <span className="legal-name-text">GSTIN Status</span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {gstData.sts}
                </span>
              </div>
              <div className="gst-legal-name-container">
                <span className="legal-name-text"> Address</span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {addressLine_one}
                </span>
              </div>
              <div className="gst-legal-name-container">
                <span className="legal-name-text">State</span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {state}
                </span>
              </div>
              <div className="gst-legal-name-container">
                <span className="legal-name-text">Pincode</span>
                <span
                  className={classes.gstCompanyNameText}
                  style={{
                    color: dailyRateScreenTextColor,
                    fontSize: " 1.2em",
                  }}
                >
                  {pincode}
                </span>
              </div>
            </div>
          </div>
        )}
        {dontHaveGST ? (
          <div className={classes.panNumberContainer}>
            <TextField
              name="panNumber"
              id="outlined-name"
              label="PAN NUMBER *"
              margin="normal"
              variant="outlined"
              className={classes.textFieldStyle}
              inputProps={{
                maxLength: 10,
              }}
              onChange={this.props.handleTextChange}
              value={panNumber.toUpperCase()}
              // onBlur={() => {
              //   if (panNumber.length < 10) {
              //     this.setState({ panNumberLengthError: true });
              //   }
              // }}
              // onFocus={() => {
              //   this.setState({ panNumberLengthError: false });
              // }}
              helperText={
                <span style={{ color: "red" }}>
                  {panNumber_Error
                    ? "Please enter valid 10 digit PAN number"
                    : null}
                </span>
              }
              error={panNumber_Error ? true : false}
            />
            <TextField
              name="panCardHolderName"
              id="outlined-name"
              label="PAN CARD HOLDER NAME *"
              margin="normal"
              variant="outlined"
              className={classes.textFieldStyle}
              onChange={this.props.handleTextChange}
              error={panCardHolderName_Error ? true : false}
              helperText={panCardHolderName_Error ? "Invalid Input" : ""}
            />
            <TextField
              name="companyName"
              id="outlined-name"
              label="COMPANY NAME *"
              margin="normal"
              variant="outlined"
              className={classes.textFieldStyle}
              onChange={this.props.handleTextChange}
              error={companyName_Error ? true : false}
              helperText={companyName_Error ? "Invalid Input" : ""}
            />
            <div
              style={{ width: "100%", margin: "10px 0px", paddingLeft: "20px" }}
            >
              <span style={{ padding: "12px 0px" }}>Pan card image*</span>
              <p
                style={{ fontSize: "12px", textAlign: "left" }}>
                {"Accepted File Types : pdf/jpg/jpeg/png"}
              </p>
              {this.props.panImageFileTypeError && <p
                style={{ color: "red", fontSize: "12px" }}>
                {"Invalid File type"}
              </p>}
            </div>
            <div className={classes.panCardImageContainer}>
              <div className={classes.panCardImageStyle}>
                {this.state.PanImagePreviewLoader === false ? (
                  <div style={{ height: "90%" }}>
                    {this.state.rawPanImage.type === "image/png" ||
                      this.state.rawPanImage.type === "image/jpg" ||
                      this.state.rawPanImage.type === "image/jpeg" ? (
                      <img
                        src={this.state.PanImagePreview}
                        alt="img"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <span style={{ wordBreak: "break-all", display: 'flex', padding: '0 10px' }}>{this.state.rawPanImage.name}</span>
                    )}
                  </div>
                ) : (
                  <label className="pan-img-label">
                    <img
                      src={
                        window.cordova
                          ? `${this.image}${"/uploadImg.svg"}`
                          : "/uploadImg.svg"
                      }
                      style={{
                        height: "35%",
                        width: "auto",
                        objectFit: "contain",
                      }}
                      alt="img"
                    />
                    UPLOAD
                    <input
                      type="file"
                      size="60"
                      className="pan-img-input"
                      inputProps={{ accept: "image/*" }}
                      onChange={(e) => {
                        e.preventDefault();
                        this.handlePanImageChange(e);
                      }}
                    />
                  </label>
                )}
              </div>
              {this.state.PanImagePreviewLoader === false ? (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleDeletePanImage();
                  }}
                  style={{ paddingLeft: "15px", width: "20%" }}
                  aria-label="Delete"
                >
                  <Delete />
                </IconButton>
              ) : null}
            </div>
            {/* <div className={classes.panCardImageContainer}>
              <span style={{ padding: "12px 0px" }}>Pan card image</span>
              <div className={classes.panCardImageStyle}>
                <img
                  src={
                    window.cordova
                      ? `${this.image}${"/uploadImg.svg"}`
                      : "/uploadImg.svg"
                  }
                  style={{ height: "35%", width: "auto", objectFit: "contain" }}
                  alt="img"
                />
                <span>UPLOAD</span>
              </div>
            </div> */}
            <div
              style={{
                padding: "12px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Checkbox
                style={{
                  color: panCardActiveStatus ? secondaryColor : null,
                }}
                checked={this.state.panCardActiveStatus}
                onChange={this.handleChangeCheckBoxPanActiveStatus(
                  "panCardActiveStatus"
                )}
                value="panCardActiveStatus"
              />
              <span>The pan card provided is correct and active </span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default withStyles(styles)(RegisterGstNumber);
