import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Create from "@material-ui/icons/Create";
import {
  grey,
  highlitedTextColor,
  whiteSmoke,
  primaryColor,
} from "../../../constants/internal/colors";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { PieChart } from "react-minimal-pie-chart";

const styles = {
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "96%",
    paddingBottom: "20px",
  },
  buyerConsigneeCustomerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "12px 0px",
  },
  buyerConsigneeCustomerNameText: {
    color: grey,
    fontWeight: "bold",
  },
  keyAndValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
  },
  singleDiv: {
    padding: "14px 0px",
  },
  itemDetailContainer: {
    display: "flex",
    paddingLeft: "22px",
    paddingTop: "20px",
  },
  itemDetailContainerLeft: {
    width: "50%",
  },
  itemDetailContainerRight: {
    width: "50%",
    display: "flex",
  },
  itemTextStyle: {
    color: grey,
    fontWeight: "bold",
    width: "40%",
  },
  checkboxContainerStyle: {
    display: "flex",
    paddingTop: "20px",
    paddingLeft: "22px",
    alignItems: "center",
  },
  discountContainerStyle: {
    display: "flex",
    paddingTop: "20px",
    paddingBottom: "30px",
  },
  discountContainerStyleLeft: {
    width: "50%",
    paddingLeft: "50px",
  },
  discountContainerStyleRight: {
    width: "50%",
  },
  discountHeaderDiv: {
    padding: "10px 0px 10px 22px",
    background: whiteSmoke,
    width: "80%",
    marginBottom: "20px",
  },
  discountTextStyle: {
    fontWeight: "bold",
    color: grey,
  },
  discountListStyleDiv: {
    display: "flex",
    width: "90%",
    marginBottom: "18px",
  },
  discountListStyleText: {
    width: "50%",
    color: grey,
    fontWeight: "bold",
  },
  discountListStyleValue: {
    width: "50%",
  },
  editButtonDiv: {
    padding: "45px 65px 20px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  recentActivityPaper: {
    padding: "10px 10px 10px 30px",
    width: "45%",
    marginTop: "30px",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
  recentActivityTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    alignItems: "center",
  },
  recentActivityTextDivLeft: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.6em",
  },
  peiChartContainerStyle: {
    width: "50%",
    padding: "25px",
  },
  peiChartInfoContainerStyle: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    flexDirection: "column",
    justifyContent: "center",
  },
  squareDispatchedStyle: {
    height: "20px",
    width: "20px",
    background: highlitedTextColor,
    marginRight: "10px",
  },
  squareInProcessStyle: {
    height: "20px",
    width: "20px",
    background: "#b2df8a",
    marginRight: "10px",
  },
  squareInPendingStyle: {
    height: "20px",
    width: "20px",
    background: "#a5cee3",
    marginRight: "10px",
  },
  checkboxLabelLeft: {
    color: grey,
    fontWeight: "bold",
    width: "18%",
  },
};
class SingleContractsOrderOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.SingleContractsOrderOverviewMainBody}>
        <Paper elevation={1} className={classes.paperStyle}>
          <div className={classes.buyerConsigneeCustomerContainer}>
            <div style={{ width: "25%" }}>
              <p className={classes.buyerConsigneeCustomerNameText}>CUSTOMER</p>
              <div className={classes.singleDiv}>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    Name
                  </span>
                  <span>Shahid Hussain</span>
                </div>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    GSTIN
                  </span>
                  <span>SS777BGGS88HHH</span>
                </div>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    Type
                  </span>
                  <span>Direct Customer</span>
                </div>
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <p className={classes.buyerConsigneeCustomerNameText}>BUYER</p>
              <div className={classes.singleDiv}>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    Name
                  </span>
                  <span>Shahid Hussain</span>
                </div>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    GSTIN
                  </span>
                  <span>SS777BGGS88HHH</span>
                </div>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    Type
                  </span>
                  <span>Direct Customer</span>
                </div>
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <p className={classes.buyerConsigneeCustomerNameText}>
                CONSIGNEE
              </p>
              <div className={classes.singleDiv}>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    Name
                  </span>
                  <span>Shahid Hussain</span>
                </div>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    GSTIN
                  </span>
                  <span>SS777BGGS88HHH</span>
                </div>
                <div className={classes.keyAndValueContainer}>
                  <span className={classes.buyerConsigneeCustomerNameText}>
                    Type
                  </span>
                  <span>Direct Customer</span>
                </div>
              </div>
            </div>
          </div>

          <Divider variant="middle" />
          <div className={classes.itemDetailContainer}>
            <div className={classes.itemDetailContainerLeft}>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>ITEM CATEGORY</span>
                <span style={{ width: "60%%" }}>TMT</span>
              </div>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>ITEM CATEGORY</span>
                <span style={{ width: "60%%" }}>TMT</span>
              </div>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>VALIDITY</span>
                <span style={{ width: "60%%" }}>20 days</span>
              </div>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>INTERNAL REMARK</span>
                <span style={{ width: "60%%" }}>none</span>
              </div>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>CUSTOMER REMARK</span>
                <span style={{ width: "60%%" }}>none</span>
              </div>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>RATE</span>
                <span style={{ width: "60%%" }}>₹300</span>
              </div>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>PAYMENT TERM </span>
                <span style={{ width: "60%%" }}>advance</span>
              </div>
              <div style={{ display: "flex", marginBottom: "14px" }}>
                <span className={classes.itemTextStyle}>BOOKING DATE</span>
                <span style={{ width: "60%%" }}>29/01/2010</span>
              </div>
            </div>
            <div className={classes.itemDetailContainerRight}>
              <div className={classes.peiChartContainerStyle}>
                <PieChart
                  lineWidth={20}
                  paddingAngle={0.2}
                  //rounded
                  data={[
                    {
                      title: "Dispatched",
                      value: 5,
                      color: highlitedTextColor,
                    },
                    { title: "Pending", value: 4, color: "#a5cee3" },
                    { title: "In Process", value: 1, color: "#b2df8a" },
                  ]}
                />
              </div>
              <div className={classes.peiChartInfoContainerStyle}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <span className={classes.squareDispatchedStyle} />
                  <span>Dispatched</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <span className={classes.squareInProcessStyle} />
                  <span>In Process</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <span className={classes.squareInPendingStyle} />
                  <span>Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.checkboxContainerStyle}>
            <span className={classes.checkboxLabelLeft}>ADMIN APPROVAL</span>
            <Checkbox
              style={{ color: "blue" }}
              checked={true}
              onChange={this.handleChange("checkedA")}
              value="checkedA"
            />
            <span style={{ color: highlitedTextColor }}>ADMIN APPROVAL</span>
          </div>
          <div className={classes.checkboxContainerStyle}>
            <span className={classes.checkboxLabelLeft}>CLIENT APPROVA</span>
            <Checkbox
              style={{ color: "blue" }}
              // checked={true}
              onChange={this.handleChange("checkedA")}
              value="checkedA"
            />
            <span style={{ color: highlitedTextColor }}>CLIENT APPROVAL</span>
          </div>
          <div className={classes.checkboxContainerStyle}>
            <span className={classes.checkboxLabelLeft}>
              ALLOW BREAKING FIFO
            </span>
            <Checkbox
              style={{ color: "blue" }}
              checked={true}
              onChange={this.handleChange("checkedA")}
              value="checkedA"
            />
          </div>
          <div className={classes.discountContainerStyle}>
            <div className={classes.discountContainerStyleLeft}>
              <div className={classes.discountHeaderDiv}>
                <span className={classes.discountTextStyle}>DISCOUNT</span>
              </div>

              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>
                  ITEM CATEGORY
                </span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>ITEM TYPE</span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>REGION</span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>
                  TOTAL AMOUNT
                </span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
            </div>
            <div className={classes.discountContainerStyleRight}>
              <div className={classes.discountHeaderDiv}>
                <span className={classes.discountTextStyle}>
                  APPROXIMATE AMOUNT
                </span>
              </div>

              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>
                  TOTAL AMOUNT
                </span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>
                  TAXABLE AMOUNT
                </span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>GST 18%</span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
              <div className={classes.discountListStyleDiv}>
                <span className={classes.discountListStyleText}>DISCOUNT</span>
                <span className={classes.discountListStyleValue}>₹200</span>
              </div>
            </div>
          </div>
          <Divider variant="middle" />
          <div className={classes.editButtonDiv}>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: primaryColor,
                width: "11%",
              }}
            >
              <Create style={{ marginRight: "5px" }} />
              EDIT
            </Button>
          </div>
        </Paper>
        <Paper elevation={1} className={classes.recentActivityPaper}>
          <span className={classes.buyerConsigneeCustomerNameText}>
            RECENT ACTIVITY
          </span>
          <div className={classes.recentActivityTextDiv}>
            <div className={classes.recentActivityTextDivLeft}>
              <span>TMT 5mm equipment updated to 40MT</span>
              <span style={{ color: grey }}>Mohan prasad (sales head)</span>
            </div>
            <span style={{ color: grey }}>2 hours ago</span>
          </div>
          <div className={classes.recentActivityTextDiv}>
            <div className={classes.recentActivityTextDivLeft}>
              <span>TMT 5mm equipment updated to 40MT</span>
              <span style={{ color: grey }}>Mohan prasad (sales head)</span>
            </div>
            <span style={{ color: grey }}>2 hours ago</span>
          </div>
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(SingleContractsOrderOverview));
