import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import DealerOrders from "../DealerOrders";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  b2bDealerOrderMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    width: "50%",
  },
};
class B2bDealerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar orderSelect>
        <div className={classes.b2bDealerOrderMainBody}>
          <DealerOrders />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(B2bDealerOrders);
