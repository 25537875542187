import { Divider } from "@material-ui/core";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { pxToEm } from "../../../../methods";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";

const styles = {
  headerData: (isMobile) => ({
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: isMobile ? pxToEm(15) : "18px"
  }),
  navigationBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: 'wrap'
  },
}


const SalesTargetManage = (props) => {

  const {
    isMobile,
    displaySection,
  } = props;
  return (
    <Layout {...props}>
      <div style={{ width: isMobile ? '98%' : "100%" }}>
        <div style={{ width: "100%" }}>
          <div style={styles.navigationBar}>
            <Bread
              data={[
                {
                  title: `Sales Target`,
                  onClick: () => props.history.push(`${INTERNALROUTES.SALESTARGET}`),
                  style: { fontSize: isMobile && pxToEm(15), },
                },
                {
                  title: displaySection === 'distributor'
                    ? 'Distributor Manage'
                    : displaySection === 'dealer'
                      ? 'Dealer Manage'
                      : displaySection === 'salesPerson' && 'Sales Person Manage',
                  style: { fontSize: isMobile && pxToEm(15), },
                }
              ]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SalesTargetManage;
