import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import { withStyles } from "@material-ui/core/styles";
import RegisterPersonalInformation from "./RegisterPersonalInformation";
import RegisterCreatePassward from "./RegisterCreatePassward";
import RegisterSelectCategory from "./RegisterSelectCategory";
import RegisterSuccessfull from "./RegisterSuccessfull";
import RegisterGstNumber from "./RegisterGstNumber";
import RegistrationPanAddress from "./RegistrationPanAddress";
import RegisterLoginCredential from "./RegisterLoginCredential";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import * as EmailValidator from "email-validator";
import backStack from "cordova-back-stack";
import SaveButton from "../../internal/common/SaveButton";
import { primaryColor } from "../../../styles/colors";

const styles = {
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  mobileStepper: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
  },
  submitButton: {
    // height: "100%",
    color: "white",
    fontWeight: "bold",
    backgroundColor: primaryColor,
  }
};

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      activeMobileStep: 0,
      maxSteps: 7,
      pageHeight: window.innerHeight,
      dontHaveGST: false,
      pancardActiveStatus: false,
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonContactNumber: "",
      contactPersonArray: [],
      contactPerson: [
        {
          contactPersonL: "1",
        },
      ],
      stepError: false,
      verficationFieldOpen: false,
    };
  }
  componentWillMount() {
    backStack((e) => {
      if (this.props?.activestep <= 5) {
        this.props.handleBackStep();
      }
      else {
        if (!['/home', '/orders', '/dispatch', '/b2bNotification'].includes(String(localStorage.getItem("currentUrl")))) {
          this.props.history.push('/login')
        }
        else {
          this.props.history.push(String(localStorage.getItem("returnUrl")))
        }
      }
    });
    document.addEventListener("backbutton", this.loadOldUrl);
  }

  loadOldUrl = () => {
    if (this.props?.activestep <= 5) {
      this.props.handleBackStep();
    }
    else {
      if (!['/home', '/orders', '/dispatch', '/b2bNotification'].includes(String(localStorage.getItem("currentUrl")))) {
        this.props.history.push('/login')
      }
      else {
        this.props.history.push(String(localStorage.getItem("returnUrl")))
      }
    }
  }

  handleRegistrationOtpfield = (data) => {
    this.setState({ verficationFieldOpen: data })
  }
  handleAddMoreContactPerson = () => {
    let arr = this.state.contactPersonArray;
    const contact = {
      name: this.state.contactPersonName,
      email_id: this.state.contactPersonEmail,
      phone_number: this.state.contactPersonContactNumber,
      is_active: true,
      is_notification_enabled: true,
    };
    arr.push(contact);
    this.setState(
      {
        contactPersonArray: arr,
      },
      () => {
        this.props.handleContactPerson(this.state.contactPersonArray);
        this.setState({
          contactPersonName: "",
          contactPersonEmail: "",
          contactPersonContactNumber: "",
        });
      }
    );
  };
  handleTextChangeContactPerson = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };


  // handleActiveStep = () => {
  //   console.log(activeStep)
  //   if (
  //     activeStep === 4 &&
  //     this.props.gstNumberCheck === "valid"
  //   ) {

  //     console.log("here")
  //     console.log(this.props.handleSubmitCustomerRegistration("GST"))
  //       .then(response => {
  //         console.log(response)
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //     // this.props.handleActiveStep();
  //   } else if (activeStep === 5) {
  //     console.log("here")
  //     this.props.handleSubmitCustomerRegistration("PAN")
  //       .then(response => {
  //         console.log(response)
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //     // this.props.handleActiveStep();
  //   } else {
  //     this.setState({
  //       activeStep: activeStep + 1,
  //     });
  //   }
  // };
  // handleBackStep = () => {
  //   if (activeStep > 0) {
  //     this.setState({
  //       activeStep: activeStep - 1,
  //     });
  //   } else {
  //     this.props.history.push("/login");
  //   }
  // };
  handleShowGSTdetails = () => {
    this.setState({ showGstDetails: true });
  };
  handleResetGSTdetails = () => {
    this.setState({ showGstDetails: false });
  };
  emailExistCheck = () => {
    this.setState({ emailAlreadyExist: true });
  };
  resetEmailExistCheck = () => {
    this.setState({ emailAlreadyExist: false });
  };
  activeStep4EmailFocus = () => {
    this.setState({ emailFocus: true });
  };
  activeStep4EmailBlur = () => {
    this.setState({ emailFocus: false });
  };
  handleChangeEmailOTPNumber = (emailOTPNumber) => {
    this.setState({ emailOTPNumber });
  };
  handleChangeOtpNumber = (otpNumber) => {
    this.setState({ otpNumber });
  };
  handleDontHaveGST = () => {
    this.setState({ dontHaveGST: !this.state.dontHaveGST });
  };
  handlePancardActiveStatus = () => {
    this.setState({ pancardActiveStatus: !this.state.pancardActiveStatus });
  };
  handleStepError = (data) => {
    this.setState({ stepError: data })
  }
  render() {
    const {
      pageHeight,
      // activeMobileStep,
      // maxSteps,
      // activeStep,
      showGstDetails,
      emailAlreadyExist,
      emailFocus,
      dontHaveGST,
      pancardActiveStatus,
      contactPerson,
      contactPersonName,
      contactPersonEmail,
      contactPersonContactNumber,
      contactPersonArray,
      stepError,
      verficationFieldOpen
    } = this.state;
    const {
      theme,
      classes,
      category,
      activeStep,
      Gst_number,
      addressLine_one,
      pincode,
      firstName,
      lastName,
      dob,
      contactNumber,
      email,
      otp,
      password,
      confirmPassword,
      legal_name_of_business,
      date_of_registration,
      emailOTP,
      gstNumberCheck,
      state,
      panNumber,
      panCardImageFile,
      panCardHolderName,
      companyName,
      panAddressLineOne,
      panAddressLineTwo,
      panAddressLineThree,
      countries,
      states,
      cities,
      panAddressCountry,
      panAddressState,
      panAddressCity,
      panAdressPincode,
      banks,
      isPhoneVerified,
      isEmailVerified,
      panNumber_Error,
      Gst_number_Error,
      email_Error,
      contactNumber_Error,
      panAddresssState,
      panAddresssCity,
      contactCountryCode,
      country_code,
      panImageFileTypeError,
      registrationSubmitLoader
    } = this.props;
    // console.log(activeStep, activeStep === 1 && !isEmailVerified, activeStep === 1 && !isPhoneVerified)
    return (
      <div
        style={{
          height: pageHeight,
          background:
            "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
          opacity: 1,
        }}
      >
        <div>
          <SwipeableViews
            index={activeStep}
            onChangeIndex={this.props.handleSwipeableIndex}
            disabled
          >
            <RegisterSelectCategory
              handleActiveStep={this.props.handleActiveStep}
              handleSelectCategory={this.props.handleSelectCategory}
              handleBackStep={this.props.handleBackStep}
            />
            <RegisterLoginCredential
              handleTextChange={this.props.handleTextChange}
              isPhoneVerified={this.props.isPhoneVerified}
              isEmailVerified={this.props.isEmailVerified}
              contactNumber={contactNumber}
              email={email}
              otp={otp}
              emailExist={this.props.emailExist}
              emailExistCheck={this.emailExistCheck}
              resetEmailExistCheck={this.resetEmailExistCheck}
              activeStep4EmailFocus={this.activeStep4EmailFocus}
              activeStep4EmailBlur={this.activeStep4EmailBlur}
              phoneExist={this.props.phoneExist}
              sendOTP={this.props.sendOTP}
              clearOTPInput={this.props.clearOTPInput}
              otpSendInEmail={this.props.otpSendInEmail}
              emailOTP={emailOTP}
              resetEmailOtp={this.props.resetEmailOtp}
              resetOTP={this.props.resetOTP}
              handleChangeOtpNumber={this.handleChangeOtpNumber}
              handleChangeEmailOTPNumber={this.handleChangeEmailOTPNumber}
              handleEmailVerification={this.props.handleEmailVerification}
              handlePhoneVerification={this.props.handlePhoneVerification}
              fieldVerified={this.props.fieldVerified}
              contactNumber_Error={contactNumber_Error}
              email_Error={email_Error}
              country_code={country_code}
              handleSelect={this.props.handleSelect}
              countries={countries}
              handleRegistrationOtpfield={this.handleRegistrationOtpfield}
            />
            <RegisterCreatePassward
              handleTextChange={this.props.handleTextChange}
              password={password}
              confirmPassword={confirmPassword}
              handleStepError={this.handleStepError}
            />
            <RegisterPersonalInformation
              {...this.props}
              firstName={firstName}
              lastName={lastName}
              handleTextChange={this.props.handleTextChange}
              handleDateChange={this.props.handleDateChange}
              dob={dob}
              contactPerson={contactPerson}
              contactPersonName={contactPersonName}
              contactPersonEmail={contactPersonEmail}
              contactPersonContactNumber={contactPersonContactNumber}
              handleTextChangeContactPerson={this.handleTextChangeContactPerson}
              handleAddMoreContactPerson={this.handleAddMoreContactPerson}
              contactPersonArray={contactPersonArray}
              handleCheckBookCardImageFile={this.props.handleCheckBookCardImageFile}
              banks={banks}
              handleSelect={this.props.handleSelect}
            />
            <RegisterGstNumber
              Gst_number={Gst_number}
              Gst_number_Error={Gst_number_Error}
              handleTextChange={this.props.handleTextChange}
              showGstDetails={showGstDetails}
              handleShowGSTdetails={this.handleShowGSTdetails}
              handleResetGSTdetails={this.handleResetGSTdetails}
              centre_jurisdiction={this.props.centre_jurisdiction}
              state_jurisdiction={this.props.state_jurisdiction}
              constitution_of_business={this.props.constitution_of_business}
              taxpayer_type={this.props.taxpayer_type}
              GSTIN_or_UIN_Status={this.props.GSTIN_or_UIN_Status}
              date_of_cancellation={this.props.date_of_cancellation}
              field_visit_conducted={this.props.field_visit_conducted}
              trade_name={this.props.trade_name}
              legal_name_of_business={this.props.legal_name_of_business}
              date_of_registration={this.props.date_of_registration}
              // handleTextChange={this.props.handleTextChange}
              handleGetGSTInfo={this.props.handleGetGSTInfo}
              gstNumberCheck={gstNumberCheck}
              gstErrorMessage={this.props.gstErrorMessage}
              gstLoader={this.props.gstLoader}
              resetGstDetail={this.props.resetGstDetail}
              gstData={this.props.gstData}
              gstNumberAlreadyRegistered={this.props.gstNumberAlreadyRegistered}
              state={state}
              pincode={pincode}
              addressLine_one={addressLine_one}
              handleDontHaveGST={this.handleDontHaveGST}
              panNumber={panNumber}
              panNumber_Error={panNumber_Error}
              panCardHolderName={panCardHolderName}
              companyName={companyName}
              panImageFileTypeError={panImageFileTypeError}
              handlePanCardImageFile={this.props.handlePanCardImageFile}
              handlePancardActiveStatus={this.handlePancardActiveStatus}
            />
            {activeStep === 4 ||
              (activeStep === 5 && gstNumberCheck === "valid") ? (
              <RegisterSuccessfull
                {...this.props}
                legal_name_of_business={legal_name_of_business}
                date_of_registration={date_of_registration}
                Gst_number={Gst_number}
                email={email}
                contactNumber={contactNumber}
                registrationLoader={this.props.registrationLoader}
                panNumber={panNumber}
              />
            ) : (
              <RegistrationPanAddress
                panAddressLineOne={panAddressLineOne}
                panAddressLineTwo={panAddressLineTwo}
                panAddressLineThree={panAddressLineThree}
                handleTextChange={this.props.handleTextChange}
                getStatesFromMasterData={this.props.getStatesFromMasterData}
                countries={countries}
                states={states}
                getCityFromMasterData={this.props.getCityFromMasterData}
                cities={cities}
                panAdressPincode={panAdressPincode}
                handleSelectCity={this.props.handleSelectCity}
                panNumber={panNumber}
                handleFetchStateAndCities={this.props.handleFetchStateAndCities}
                handleSelect={this.props.handleSelect}
                panAddresssCity={panAddresssCity}
                panAddresssState={panAddresssState}
              />
            )}
            <RegisterSuccessfull
              {...this.props}
              legal_name_of_business={legal_name_of_business}
              date_of_registration={date_of_registration}
              Gst_number={Gst_number}
              email={email}
              contactNumber={contactNumber}
              registrationLoader={this.props.registrationLoader}
              panNumber={panNumber}
            />
          </SwipeableViews>
        </div>
        <div className="mobile-stepper-in-registration">
          {(activeStep === 5 && gstNumberCheck === "valid") ||
            activeStep === 6 ? null : (
            <MobileStepper
              className={classes.mobileStepper}
              variant="dots"
              steps={
                activeStep === 4 && gstNumberCheck === "valid"
                  ? 5
                  : 6
              }
              position="static"
              activeStep={activeStep}
              nextButton={
                (activeStep === 4 && gstNumberCheck === "valid") || activeStep === 5 ? (
                  <SaveButton
                    variant="contained"
                    style={styles.submitButton}
                    disabled={
                      (activeStep === 0 && category === "") ||
                      (activeStep === 1 && !(isEmailVerified || isPhoneVerified)) ||
                      (activeStep === 2 && password.length < 6) ||
                      (activeStep === 2 && confirmPassword.length < 6) ||
                      (activeStep === 2 && password !== confirmPassword) ||
                      (activeStep === 2 && stepError) ||
                      (activeStep === 3 && firstName === "") ||
                      (activeStep === 3 && lastName === "") ||
                      (activeStep === 3 && (dob === "" || dob === null)) ||
                      (activeStep === 3 &&
                        contactPersonEmail !== "" &&
                        EmailValidator.validate(contactPersonEmail) === false) ||
                      (activeStep === 3 &&
                        contactPersonEmail !== "" &&
                        EmailValidator.validate(contactPersonEmail) === true &&
                        contactPersonContactNumber === "") ||
                      (contactPersonContactNumber !== "" &&
                        contactPersonContactNumber.length < 10) ||
                      (dontHaveGST === false &&
                        activeStep === 4 &&
                        Gst_number.length < 15) ||
                      (dontHaveGST === false &&
                        activeStep === 4 &&
                        gstNumberCheck === "pending") ||
                      (dontHaveGST === false &&
                        activeStep === 4 &&
                        gstNumberCheck === "invalid") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panNumber === "") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panNumber.length < 10) ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panCardHolderName === "") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        companyName === "") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panCardImageFile === null) ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panImageFileTypeError) ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        pancardActiveStatus === false) ||
                      (activeStep === 5 && panAddressLineOne === "") ||
                      (activeStep === 5 && panAddressCountry === "") ||
                      (activeStep === 5 && panAddresssState === "") ||
                      (activeStep === 5 && panAddresssCity === "") ||
                      (activeStep === 5 && panAdressPincode === "") ||
                      (activeStep === 5 && panAdressPincode.length < 6)
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleActiveStep()
                    }}
                    isLoading={registrationSubmitLoader}
                  >
                    Submit
                  </SaveButton>
                )
                  :
                  (<Button
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        activeStep === 3 &&
                        contactPersonName !== "" &&
                        contactPersonEmail !== "" &&
                        EmailValidator.validate(contactPersonEmail) &&
                        contactPersonContactNumber !== "" &&
                        contactPersonContactNumber.length === 10
                      ) {
                        this.handleAddMoreContactPerson();
                        this.props.handleActiveStep();
                      }
                      // if (
                      //   activeStep === 4 &&
                      //   gstNumberCheck === "valid"
                      // ) {
                      //   this.props.handleSubmitCustomerRegistration("GST");
                      //   this.props.handleActiveStep();
                      // } else if (activeStep === 5) {
                      //   this.props.handleSubmitCustomerRegistration("PAN");
                      //   this.props.handleActiveStep();
                      // }
                      else {
                        if ((activeStep === 1 && (verficationFieldOpen))) {
                          // do nothing
                        }
                        else {
                          this.props.handleActiveStep();
                        }
                      }
                    }}
                    disabled={
                      (activeStep === 0 && category === "") ||
                      // (activeStep === 1 && email === "") ||
                      // (activeStep === 1 && EmailValidator.validate(email) === false) ||
                      // (activeStep === 1 && contactNumber === "") ||
                      // (activeStep === 1 && contactNumber.length < 10) ||
                      // (activeStep === 1 && otp === "") ||
                      // (activeStep === 1 && emailAlreadyExist) ||
                      // (activeStep === 1 && emailFocus) ||
                      // (activeStep === 1 && this.state.otpNumber !== otp) ||
                      // (activeStep === 1 &&
                      //   this.state.emailOTPNumber !== emailOTP) ||
                      (activeStep === 1 && !(isEmailVerified || isPhoneVerified)) ||
                      (activeStep === 1 && (verficationFieldOpen)) ||
                      // (activeStep === 1 && !country_code) ||
                      (activeStep === 2 && password.length < 6) ||
                      (activeStep === 2 && confirmPassword.length < 6) ||
                      (activeStep === 2 && password !== confirmPassword) ||
                      (activeStep === 2 && stepError) ||
                      (activeStep === 3 && firstName === "") ||
                      (activeStep === 3 && lastName === "") ||
                      (activeStep === 3 && (dob === "" || dob === null)) ||
                      (activeStep === 3 &&
                        contactPersonEmail !== "" &&
                        EmailValidator.validate(contactPersonEmail) === false) ||
                      (activeStep === 3 &&
                        contactPersonEmail !== "" &&
                        EmailValidator.validate(contactPersonEmail) === true &&
                        contactPersonContactNumber === "") ||
                      (contactPersonContactNumber !== "" &&
                        contactPersonContactNumber.length < 10) ||
                      (dontHaveGST === false &&
                        activeStep === 4 &&
                        Gst_number.length < 15) ||
                      (dontHaveGST === false &&
                        activeStep === 4 &&
                        gstNumberCheck === "pending") ||
                      (dontHaveGST === false &&
                        activeStep === 4 &&
                        gstNumberCheck === "invalid") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panNumber === "") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panNumber.length < 10) ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panCardHolderName === "") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        companyName === "") ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panCardImageFile === null) ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        panImageFileTypeError) ||
                      (dontHaveGST === true &&
                        activeStep === 4 &&
                        pancardActiveStatus === false) ||
                      (activeStep === 5 && panAddressLineOne === "") ||
                      (activeStep === 5 && panAddressCountry === "") ||
                      (activeStep === 5 && panAddresssState === "") ||
                      (activeStep === 5 && panAddresssCity === "") ||
                      (activeStep === 5 && panAdressPincode === "") ||
                      (activeStep === 5 && panAdressPincode.length < 6)
                    }
                  >
                    {activeStep === 4 && gstNumberCheck === "valid"
                      ? "SUBMIT"
                      : activeStep === 5
                        ? "Submit"
                        : "Next"}
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>)
              }
              backButton={
                <Button
                  size="small"
                  disabled={registrationSubmitLoader}
                  style={{
                    color: activeStep === 0 ? "#989FAB" : null,
                  }}
                  // onClick={this.handleBackStep}
                  onClick={(e) => {
                    e.preventDefault();
                    if (activeStep === 0) {
                      this.props.history.push("/login");
                    } else {
                      this.props.handleBackStep();
                    }
                  }}
                //disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  {activeStep === 0 ? "Cancel" : "Back"}
                </Button>
              }
            />
          )}
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Registration);
