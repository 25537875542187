import React, { Component } from "react";
import Home, {
  HomeWeb,
  CreateOrder,
  CreateOrderWeb,
  ConfirmOrder,
  ConfirmOrderWeb,
  TermsAndConditions,
  TermsAndConditionsWeb,
} from "../../../components/b2b/home";
import axios from "axios";
import B2b_URL from "../../../constants/b2b/B2bRoutes";
import withUser from "../../../hoc/withUser";
import { Route } from "react-router-dom";
import SnackbarComponent from "../../../components/common/SnackbarComponent"
import { StaticAppVersion, arraySortBykeyAllBrowser, checkApiError, checkQtyLogic, } from "../../../methods";
import upiqr from "upiqr";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import moment from "moment";

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribedDailyRatesLoader: false,
      lastUpdatedTime: [],
      dailyRatesLastUpdatedAt: [],
      orderQuantity: '',
      orderRemark: "",
      selectedDailyRates: "",
      selectedDailyRatesAmount: 0,
      paymentMethod: "",
      paymentMethods: [],
      orderList: [],
      orders: [],
      pendingActionList: [],
      orderQuantityError: false,
      selectedDailyRatesId: null,
      selectedDailyRatesItemCategoryId: null,
      selectedDailyRatesBrokerage: null,
      placedOrderDetails: {},
      pendingDispatchList: null,
      pendingItemCategories: null,
      openDialog: false,
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
      buyers: null,
      consignees: null,
      pendingActionLoader: false,
      pendingDispatchListLoader: false,
      isContractConfirmLoader: false,
      paymentMethodsDiscount: [],
      bannerData: [],
      invoiceLoader: false,
      invoicesList: [],
      invoicesPaymentDetail: [],
      b2CQrImage: null,
    };
  }


  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillMount() {
    this.setState({ subscribedDailyRatesLoader: true, pendingActionLoader: true, pendingDispatchListLoader: true });
    window.addEventListener("offline", this.offlineCheck);
    window.addEventListener("online", this.onlineCheck);

  }
  offlineCheck = () => {
    this.setState({ isOffline: true });
  };
  onlineCheck = () => {
    this.setState({ isOffline: false }, () => {
      this.fetchSubscribedDailyRates();
    });
  };
  componentDidMount() {
    this.setState({ subscribedDailyRatesLoader: true });
    if (navigator.onLine === false) {
      this.setState({
        isOffline: true,
        // subscribedDailyRatesLoader: false,
      });
    } else {
      this.fetchPendingOrderList()
      this.fetchSubscribedDailyRates();
      this.fetchPaymentMethod();
      this.fetchPendingDispatches()
      this.fetchInvoices()
      this.fetchBuyerList()
      this.fetchConsigneeList()
      this.fetchTodayBanner()
      this.getAllStatesList()
      // this.retrieveOrderData()
    }
    let returnUrl = '/home';
    localStorage.setItem("returnUrl", returnUrl);
    let currentUrl = '/home';
    localStorage.setItem("currentUrl", currentUrl);
    document.addEventListener("backbutton", this.loadOldUrl);
  }

  loadOldUrl = () => {
    this.props.history.push(String(localStorage.getItem("returnUrl")))
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }


  fetchTodayBanner = () => {
    axios
      .get(`${B2b_URL.GET_BANNER}?today=True&V=${StaticAppVersion()}`, this.getOptions(this.props.user))
      .then(response => {
        this.setState({ bannerData: response.data });
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  fetchPendingOrderList = () => {
    // console.log(this.props.user)
    axios.get(`${B2b_URL.GET_PENDING_ORDERS}${this.props.user.id}/?filter_by=PENDING&order_by=-created_at&V=${StaticAppVersion()}`
      , this.getOptions(this.props.user)
    )
      .then(response => {
        // console.log(response)
        let pendingActionList = []
        response.data.map(data => {
          if (data.order_status === "PENDING" && data.is_customer_approval_needed && data.is_approved_by_customer === null) {
            pendingActionList.push(data)
          }
        })
        this.setState({
          orders: response.data,
          pendingActionList,
          pendingActionLoader: false

        })
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.setState({ pendingActionLoader: false })
          this.handleCloseSnackbar();
        }, 4000)
      })
    // .then(
    //   response => {
    //     console.log(response)
    //     this.setState({
    //       orderList: response.data
    //     })
    //   }
    // )
    // .catch((err) => {
    //   console.log(err);
    // });
  }



  fetchSubscribedDailyRates = () => {
    this.setState({ subscribedDailyRatesLoader: true });
    axios.get(`${B2b_URL.DAILY_RATES}?V=${StaticAppVersion()}`).then(
      (response) => {
        var response = response.data;
        this.setState(
          {
            subscribedDailyRates: response,
            subscribedDailyRatesLoader: false,
          },
          () => {
            if (this.state.subscribedDailyRates.length > 0) {
              const lastUpdatedTime = this.state.lastUpdatedTime;
              this.state.subscribedDailyRates.map((data) => {
                if (data.rate !== null) {
                  lastUpdatedTime.push(data.rate);
                }
              });
              this.setState({ lastUpdatedTime }, () => {
                const dailyRatesLastUpdatedAt =
                  this.state.lastUpdatedTime &&
                  this.state.lastUpdatedTime.sort(
                    (a, b) => new Date(b.modified_at) - new Date(a.modified_at)
                  );
                this.setState({ dailyRatesLastUpdatedAt });
              });
            }
          }
        );
      },
      (error) => { }
    );

  };
  fetchPaymentMethod = () => {
    axios
      .get(`${B2b_URL.GET_PAYMENT_METHOD + this.props.user.id}&V=${StaticAppVersion()}`)
      .then((response) => {
        // console.log(response);
        this.setState({ paymentMethods: response.data });
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  };

  fetchItemCategoriesPaymentDiscount = (itemID) => {
    if (itemID) {
      axios
        .get(`${B2b_URL.GET_ITEM_CATEGORIES_PAYMENT_DISCOUNT}?id=${itemID}&V=${StaticAppVersion()}`)
        .then((response) => {
          // console.log(response);
          this.setState({ paymentMethodsDiscount: response.data });
        })
        .catch(err => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        })
    }
  }

  fetchPendingDispatches = () => {
    this.setState({
      pendingDispatchListLoader: true
    })
    axios
      .get(`${B2b_URL.GET_PENDING_DISPATCH_LIST}?customer_id=${this.props.user.id}&V=${StaticAppVersion()}`)
      .then((response) => {
        // console.log(response);
        let list = response.data
        let items = []

        list.map(item => {
          if (item.category.length > 0) {
            item.category.map(cat => {
              if (cat.pending !== 0)
                items.push(cat.item_category)
            })

          }
        })

        let uniqueItems = items.filter((c, index) => {
          return items.indexOf(c) === index;
        });

        this.setState({ pendingDispatchListLoader: false, pendingDispatchList: response.data, pendingItemCategories: uniqueItems });
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.setState({ pendingDispatchListLoader: false })
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  getAllStatesList = (countryId) => {
    axios.get(`${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}&V=${StaticAppVersion()}`,
      this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            allStateList: responseData,
          });
        },

      )
      .catch((err) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  };

  fetchInvoices = () => {
    this.setState({ invoiceLoader: true })
    axios.get(`${B2b_URL.GET_SINGLE_ORDER_INVOICES}?payment=due&V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        let data = response.data
        this.fetchInvoicesPaymentDetail()
        data.forEach(element => { element.downloadReady = false });
        this.setState({
          invoicesList: data,
          invoiceLoader: false,
        })
      })
      .catch(err => {
        this.setState({
          invoiceLoader: false,
        })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      }
      )
  }
  fetchInvoicesPaymentDetail = () => {
    this.setState({ invoiceLoader: true })
    axios.get(`${B2b_URL.GET_INVOICES_PAYMENT_DETAIL}?V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        let data = response.data
        this.setState({
          invoicesPaymentDetail: data,
        })
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      }
      )
  }

  handleDownloadOpenSnackBar = () => {
    // console.log(this.state.singleOrderInvoices)
    this.setState({
      openSnackbar: true,
      snackbarMessage: "Downloading PDF...",
      snackbarType: "success"
    })
  }

  handleInvoiceClick = (invoice) => {
    // console.log(invoice)
    let la_number = "LANV2209269822"
    this.handleDownloadOpenSnackBar()
    axios
      .get(`${APIROUTES.FETCH_LA_DETAILS_IN_DISPATCH_COUNTER}${invoice.la_number}/?V=${StaticAppVersion()}/`)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          this.state.allStateList && this.state.allStateList.map(data => {
            if (data['id'] === response.data.plant.plant_state) {
              // console.log(data['id'] + ":" + data['name'])
              this.setState({
                plant_state: data['name']
              })
            }
          })
          let data = response.data
          // if (data.created_at && data.created_at_time)
          //   data.created_at_date_and_time = data.created_at + " " + data.created_at_time
          this.setState(
            {
              laDetailsInDispatchCounter: data,
              laDispatchCounterLoader: false,
              downloadedInvoice: invoice
              // invoiceDownloadLoader: callfor === "download_screen" ? false : true,
            },
            () => {
              this.getEinvoiceEwayDetails(invoice)
              this.handleCreateB2cInvoiceQr()
            }
            // () => {
            //   this.handleGetDistance();
            // }
          );


        } else {
          this.setState({
            laDetailsInDispatchCounter: null,
            laDispatchCounterLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ laDispatchCounterLoader: false });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  }

  handlePostInvoiceDownload = () => {
    // let { downloadedInvoice } = this.state
    let invoice = { ...this.state.downloadedInvoice }
    // console.log(invoice.la_number, this.state.singleOrderInvoices)
    let downloadedInvoice = this.state.invoicesList.find(inv => inv.la_number === invoice.la_number)
    downloadedInvoice.downloadReady = false
    // console.log(downloadedInvoice.downloadReady)
    // this.setState({
    //   downloadedInvoice
    // })
  }
  getEinvoiceEwayDetails = (data) => {
    axios
      .get(`${APIROUTES.GET_E_INVOICE_AND_E_WAY}${data.la_number}/?V=${StaticAppVersion()}/`)
      .then((response) => {

        data.downloadReady = true
        // console.log(response)
        this.setState({
          eInvoiceResponse: response.data,
          eWayBillResponse: response.data?.is_eway_created
            ? response.data
            : null,
          iseWaySelect: response.data?.is_eway_created,
          invoiceType: response.data?.invoice_type,
        });
        // this.props.history.push(
        //   `/dispatchOperation/dc/2/details/invoices/download/${laNumber}`
        // );
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  handleCreateB2cInvoiceQr = () => {
    //alert("QR Genrating");
    this.setState({ dataInvoicePostLoader: true });
    let supplierAccountDetails = this?.state?.plantAccountDetails;
    if (
      this?.state?.laDetailsInDispatchCounter?.payment_method
        ?.payment_method_name === "Advance"
    ) {
      // this.props.history.push("download");
    } else {
      upiqr({
        currency: this?.state?.laDetailsInDispatchCounter?.customer?.currency_code,
        payeeVPA:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_upi_id,
        payeeName:
          this?.state?.laDetailsInDispatchCounter?.plant?.plant_name,
        amount: this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value,
        transactionRef: this.state?.laDetailsInDispatchCounter?.erp_invoice_number,
        transactionNote: `SUPGSTN ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number
          } PAYEE_AC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no
          } PAYEE_IFSC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc
          } ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
            ? "IGST " + this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount
            : `CGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount} SGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount}`
          } CESS ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount
          } TOT_AMT ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value
          } INV_DATE ${moment(
            this?.state?.laDetailsInDispatchCounter?.created_at,
            ["DD/MM/YYYY", "YYYY-MM-DD"]
          ).format("DD MMM YYYY")}`,
      })
        .then((upi) => {
          console.log(upi.qr); // data:image/png;base64,eR0lGODP...
          this.setState(
            { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
            () => console.log(1)
          );
        })
        .catch((err) => {
          this.setState({ b2CQrImage: null, dataInvoicePostLoader: false })
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("offline", this.offlineCheck);
    window.removeEventListener("online", this.onlineCheck);
  }
  handleTextChange = (event) => {
    if (event.target.name === "orderQuantity") {
      if (event.target.value > 10000) {
        this.setState({
          orderQuantityError: "Limit - 10,000 MT"
        })
      }
      else if (!checkQtyLogic(event.target.value)) {
        this.setState({
          orderQuantityError: 'Last digit should be 0 or 5'
        })
      }
      else if (checkQtyLogic(event.target.value)) {
        this.setState({
          orderQuantityError: null
        })
      }
    }
    else {
      this.setState({
        orderQuantityError: false
      })
    }
    console.log(event.target.name, event.target.value)
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  fetchBuyerList = () => {
    // console.log(this.props.user)
    axios.get(`${B2b_URL.GET_BUYERS_LIST}?customer_id=${this.props.user.id}&V=${StaticAppVersion()}`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        this.setState({
          buyers: response.data.results
        })
        // let plantPlanning = response.data.results
        // this.setState({
        //   singleOrderPlantPlanning: plantPlanning
        // })
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  fetchConsigneeList = () => {
    axios.get(`${B2b_URL.GET_CONSIGNEES_LIST}${this.props.user.id}/?V=${StaticAppVersion()}/`
      , this.getOptions(this.props.user))
      .then(response => {
        // console.log(response)
        this.setState({
          consignees: response.data.results
        })
        // let plantPlanning = response.data.results
        // this.setState({
        //   singleOrderPlantPlanning: plantPlanning
        // })
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      })
  }

  handleSelectBuyerName = (buyer) => {
    this.setState({ buyerName: buyer.first_name, buyerId: buyer.id, selectedBuyer: buyer });
  };

  handleSelectConsigneeName = (consignee) => {
    this.setState({
      consigneeName: consignee.consignee_detail.first_name,
      consigneeId: consignee.id,
      selectedConsignee: consignee
    });
  };

  handleCloseOrderForm = (type) => {
    // console.log(this.state.openDialog)
    this.setState({
      openDialog: !this.state.openDialog
    })
    if (type === "clearData") {
      this.setState({
        orderQuantity: '',
        orderRemark: "",
      })
    }
    // this.props.history.push("/home");
  }
  handleSelectDailyRates = (name, rate, id, itemId, brokerage) => {
    // console.log(id)
    this.setState({
      selectedDailyRates: name,
      selectedDailyRatesAmount: rate,
      selectedDailyRatesId: id,
      selectedDailyRatesItemCategoryId: itemId,
      selectedDailyRatesBrokerage: brokerage,
    });
  };
  generateOrderValidityDate = (dateObj, numberOfDaysToAdd) => {
    dateObj.setDate(dateObj.getDate() + Number(numberOfDaysToAdd));
    return dateObj;
  };
  handleSelectPaymentMethod = (method) => {
    this.setState({ paymentMethod: method });
  };
  handleCreateOrder = () => {
    console.log(this.state.consigneeId, this.state.buyerId)
    let orderDetails = {
      quantity: this.state.orderQuantity,
      rate:
        this.state.selectedDailyRatesAmount,
      daily_rate: this.state.selectedDailyRatesId,
      customer_remark: this.state.orderRemark,
      payment_method: this.state.paymentMethod,
      customer: this.props.user.id,
      item_category: this.state.selectedDailyRatesItemCategoryId,
      buyer: this.state.buyerId || null,
      consignee: this.state.consigneeId || null,
      order_validity: this.generateOrderValidityDate(new Date(), 10),
      brokerage: this.state.selectedDailyRatesBrokerage,
      new_brokerage: this.state.selectedDailyRatesBrokerage,
    }
    this.setState({ isContractConfirmLoader: true })
    // console.log(orderDetails)
    axios.post(`${B2b_URL.CREATE_ORDER}?V=${StaticAppVersion()}`, orderDetails)
      .then(response => {
        // console.log(response)
        if (response.status === 201) {

          this.setState({
            openSnackbar: true,
            snackbarMessage: "Contract Created Successfully",
            snackbarType: "success",
            // orderQuantity: '',
            // orderRemark: "",
            // selectedDailyRates: "",
            // selectedDailyRatesAmount: 0,
            // paymentMethod: "",
            // selectedDailyRatesId: null,
            // selectedDailyRatesItemCategoryId: null,
            // selectedDailyRatesBrokerage: null,
            // placedOrderDetails: {},
            // isContractConfirmLoader: false
          }, () => {
            setTimeout(() => {
              this.setState({
                orderQuantity: '',
                orderRemark: "",
                selectedDailyRates: "",
                selectedDailyRatesAmount: 0,
                paymentMethod: "",
                selectedDailyRatesId: null,
                selectedDailyRatesItemCategoryId: null,
                selectedDailyRatesBrokerage: null,
                placedOrderDetails: {},
                isContractConfirmLoader: false
              })
              this.retrieveOrderData(response.data.id)
            }, 4000)
          })

        }
      })
      .catch(err => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(err?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.setState({ isContractConfirmLoader: false })
          this.handleCloseSnackbar();
        }, 4000)
      })
  }
  retrieveOrderData = (placedOrderId) => {
    // axios
    //   .get(`${B2b_URL.RETRIEVE_ORDER_DETAILS}${placedOrderId}/`, this.getOptions(this.props.user))
    //   .then(response => {
    //     if (response.status === 200) {
    //       this.setState({
    //         placedOrderDetails: response
    //       })
    //       console.log('here')
    // this.props.history.push("createOrder/confirmOrder");
    this.props.history.push("/home")
    this.props.history.push({ pathname: `/orders/active/${placedOrderId}`, state: { ORDERID: placedOrderId } })
    // this.props.history.push({ pathname: `/orders/active/${response.id}`, state: response });
    // }
    // else {
    // console.log(response.statusText)
    // }
    // })
    // .catch(err => {
    //   console.log(err)
    //   this.setState({
    //     openSnackbar: true,
    //     snackbarMessage: "Error occured while fetching contract details",
    //     snackbarType: "error"
    //   })
    // })

  }
  render() {
    const {
      subscribedDailyRates,
      subscribedDailyRatesLoader,
      dailyRatesLastUpdatedAt,
      isOffline,
      bannerData,
      snackbarMessage,
      snackbarType,
      openSnackbar,
      invoicesList,
      invoicesPaymentDetail,
      invoiceLoader,
      b2CQrImage,
      laDetailsInDispatchCounter
    } = this.state;
    const { isMobile } = this.props;
    // console.log(this.props.user.customer_type)
    // console.log(this.state.selectedDailyRatesBrokerage)
    return (
      <div>
        <Route
          exact
          path="/home"
          render={(props) =>
            isMobile ? (
              <Home
                {...this.props}
                {...this.state}
                subscribedDailyRates={subscribedDailyRates}
                subscribedDailyRatesLoader={subscribedDailyRatesLoader}
                dailyRatesLastUpdatedAt={dailyRatesLastUpdatedAt}
                handleSelectDailyRates={this.handleSelectDailyRates}
                fetchPendingOrderList={this.fetchPendingOrderList}
                pendingDispatchList={this.state.pendingDispatchList}
                fetchItemCategoriesPaymentDiscount={this.fetchItemCategoriesPaymentDiscount}
                user={this.props.user}
                isOffline={isOffline}
                isMobile={isMobile}
                bannerData={bannerData}
                invoicesList={invoicesList}
                invoicesPaymentDetail={invoicesPaymentDetail}
                invoiceLoader={invoiceLoader}
                handleOpenSnackbar={this.handleDownloadOpenSnackBar}
                handleInvoiceClick={this.handleInvoiceClick}
                b2CQrImage={b2CQrImage}
                laDetailsInDispatchCounter={laDetailsInDispatchCounter}
                handlePostInvoiceDownload={this.handlePostInvoiceDownload}
                eInvoiceResponse={this.state?.eInvoiceResponse}
                eWayBillResponse={this.state?.eWayBillResponse}
                iseWaySelect={this.state?.iseWaySelect}
                plant_state={this.state?.plant_state}
                invoiceType={this.state?.invoiceType}
              />
            ) : (
              <HomeWeb
                {...this.props}
                {...this.state}
                subscribedDailyRates={subscribedDailyRates}
                subscribedDailyRatesLoader={subscribedDailyRatesLoader}
                dailyRatesLastUpdatedAt={dailyRatesLastUpdatedAt}
                handleSelectDailyRates={this.handleSelectDailyRates}
                fetchPendingOrderList={this.fetchPendingOrderList}
                fetchItemCategoriesPaymentDiscount={this.fetchItemCategoriesPaymentDiscount}
                isOffline={isOffline}
                isMobile={isMobile}
                bannerData={bannerData}
                invoicesList={invoicesList}
                invoicesPaymentDetail={invoicesPaymentDetail}
                invoiceLoader={invoiceLoader}
                handleOpenSnackbar={this.handleDownloadOpenSnackBar}
                handleInvoiceClick={this.handleInvoiceClick}
                b2CQrImage={b2CQrImage}
                laDetailsInDispatchCounter={laDetailsInDispatchCounter}
                handlePostInvoiceDownload={this.handlePostInvoiceDownload}
                eInvoiceResponse={this.state?.eInvoiceResponse}
                eWayBillResponse={this.state?.eWayBillResponse}
                iseWaySelect={this.state?.iseWaySelect}
                plant_state={this.state?.plant_state}
                invoiceType={this.state?.invoiceType}
              />
            )
          }
        />
        <Route
          exact
          path="/home/createOrder"
          render={(props) =>
            isMobile ? (
              <CreateOrder
                {...this.props}
                {...this.state}
                isMobile={isMobile}
                handleTextChange={this.handleTextChange}
                handleSelectPaymentMethod={this.handleSelectPaymentMethod}
                handleCreateOrder={this.handleCreateOrder}
                handleCloseOrderForm={this.handleCloseOrderForm}
                handleSelectBuyerName={this.handleSelectBuyerName}
                handleSelectConsigneeName={this.handleSelectConsigneeName}
                isContractConfirmLoader={this.state.isContractConfirmLoader}
                paymentMethodsDiscount={this.state?.paymentMethodsDiscount}
              />
            ) : (
              <CreateOrderWeb
                {...this.props}
                {...this.state}
                isMobile={isMobile}
                handleTextChange={this.handleTextChange}
                handleSelectPaymentMethod={this.handleSelectPaymentMethod}
                handleCreateOrder={this.handleCreateOrder}
                handleCloseOrderForm={this.handleCloseOrderForm}
                handleSelectBuyerName={this.handleSelectBuyerName}
                handleSelectConsigneeName={this.handleSelectConsigneeName}
                isContractConfirmLoader={this.state.isContractConfirmLoader}
                paymentMethodsDiscount={this.state?.paymentMethodsDiscount}
              />
            )
          }
        />
        <Route
          exact
          path="/home/terms-and-conditions"
          render={(props) =>
            isMobile ? (
              <TermsAndConditions
                {...this.props}
                {...this.state}
                isMobile={isMobile}
              />
            ) : (
              <TermsAndConditionsWeb
                {...this.props}
                {...this.state}
                isMobile={isMobile}
              />
            )
          }
        />
        <Route
          exact
          path="/home/createOrder/confirmOrder"
          render={(props) =>
            isMobile ? (
              <ConfirmOrder {...this.props} isMobile={isMobile}
                placedOrderDetails={this.state.placedOrderDetails}
              />
            ) : (
              <ConfirmOrderWeb {...this.props} isMobile={isMobile}
                placedOrderDetails={this.state.placedOrderDetails}
              />
            )
          }
        />
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </div>
    );
  }
}
export default withUser(HomeContainer);
