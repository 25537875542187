import React from 'react';
import Layout from '../../../../layouts/internalLayout/InternalLayout';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  secondaryBlueText,
  drawerTextColor,
  grey,
  successButtonBackground,
  primaryColor,
  cancelButtonBackground,
} from '../../../../constants/internal/colors';

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import AccessAreaManageTableComponent from './AccessAreaManageTableComponent';
import { pxToEm } from '../../../../methods';

const manageAccessAreaStyles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  titleWithBlueFont: {
    fontSize: pxToEm(18),
    color: secondaryBlueText,
  },
  displayData: {
    marginTop: 10,
  },
  titleActionButtonSection: {
    display: 'flex',
    placeContent: 'space-between',
    marginTop: 50,
    alignItems: 'baseline',
  },
  roleNameAlignment: {
    display: 'flex',
    placeItems: 'baseline',
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: 'bold',
  },
  displayItemTitle: {
    paddingLeft: 15,
    color: grey,
    fontSize: pxToEm(14),
  },
  generalTopMargin: { marginTop: 40, width: '100%' },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
  },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  mainDiv: {
    width: '100%',
    height: '100%',
    paddingBottom: '30px',
    overflowY: 'scroll',
  },
  rightPadding: { paddingRight: '25px' },
});
class ManageAccessArea extends React.Component {
  goBack = (e) => this.props.history.goBack();
  goBackBack = (e) => {
    this.props.history.goBack();
    this.props.history.goBack();
  };

  render() {
    const { classes, modules, updateAWidget, addIpsInBulk, deleteIp } = this.props;
    const { goBack, goBackBack } = this;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <Typography>
              {
                <>
                  <span
                    className={classes.titleWithBlueFont}
                    onClick={goBackBack}
                  >
                    {`Users And Permissions > `}
                  </span>
                  <span className={classes.titleWithBlueFont} onClick={goBack}>
                    {`Access Area Management > `}
                  </span>
                </>
              }
              {`Edit Access Area`}
            </Typography>
            <div className={classes.displayData}>
              <div className={classes.titleActionButtonSection}>
                <div className={classes.roleNameAlignment}>
                  <span className={classes.contentTitle}>
                    ACCESS AREA MANAGEMENT
                  </span>
                </div>
                <div>
                  <Button
                    size="medium"
                    className={classes.margin}
                    onClick={this.props.history.goBack}
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
              <div className={classes.generalTopMargin}>
                <AccessAreaManageTableComponent
                  modules={modules}
                  addIpsInBulk={addIpsInBulk}
                  updateAWidget={updateAWidget}
                  deleteIp={deleteIp}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default withStyles(manageAccessAreaStyles)(ManageAccessArea);
