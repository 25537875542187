import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Switch, withStyles } from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { checkApiError, checkTcsPercentage, pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import SaveButton from "../../common/SaveButton";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: '70%'
  }
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function ManageTcsData(props) {
  const classes = useStyles();
  const [tcsData, setTcsData] = React.useState(
    props.selectedTcsData || { tcs_percent: 0, tcs_code: "" }
  );
  const [tcsOperation, setTcsOperation] = React.useState(props.tcsOperation);
  const [tcsError, setTcsError] = React.useState({
    tcs_percent: false,
    tcs_code: false,
  })
  const [isLoading, setIsLoading] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [
    removeConfirmationDialogData,
    setRemoveConfirmationDialogData,
  ] = React.useState({
    openDialog: false,
    dialogTitle: ``,
    dialogContent: "",
  });

  React.useEffect(() => {
    if (props.tcsOperation !== 'add') {
      if (props.selectedTcsData.tcs_percent) {
        !checkTcsPercentage(props.selectedTcsData.tcs_percent) ?
          setTcsError(prevValue => { return { ...prevValue, tcs_percent: true } }) :
          setTcsError(prevValue => { return { ...prevValue, tcs_percent: false } });
      }
      if (props.selectedTcsData.tcs_code) {
        !checkTcsCode(props.selectedTcsData.tcs_code) ?
          setTcsError(prevValue => { return { ...prevValue, tcs_code: true } }) :
          setTcsError(prevValue => { return { ...prevValue, tcs_code: false } });
      }
    }
  }, [props.selectedTcsData, props.tcsOperation]);

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const checkTcsCode = (input) => {
    const regex = /^[a-zA-Z\s]+[._]?[a-zA-Z0-9\s]+$/;
    if (regex.test(input)) {
      return true
    }
    return false;
  }

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    setTcsData({
      ...tcsData,
      [name]: name === "is_active" ? checked : value,
    });
    if (!value) {
      setTcsError(prevValue => {
        return {
          ...prevValue, [name]: true
        }
      })
    } else {
      if (name === 'tcs_percent') {
        !checkTcsPercentage(value) ?
          setTcsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTcsError(prevValue => { return { ...prevValue, [name]: false } })
      } else if (name === 'tcs_code') {
        !checkTcsCode(value) ?
          setTcsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTcsError(prevValue => { return { ...prevValue, [name]: false } })
      }
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };
  const handleEditSaveButtonClick = () => {
    let addData = {
      tcs_percent: tcsData.tcs_percent,
      tcs_code: tcsData.tcs_code,
      is_active: (tcsData.is_active) === undefined ? true : Boolean(tcsData.is_active)
    }
    setIsLoading(true)
    if (tcsOperation === "edit") {
      props
        .handleTcsMasterDataUpdate(tcsData, tcsData.id)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Tcs Master Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.goBack();
              handleCloseSnackbar()
            }, 4000);
            // props.history.goBack();
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating tcs master details please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (tcsOperation === "add") {
      props
        .handleTcsMasterDataAdd(addData)
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Tcs Master Created Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.push(`${INTERNALROUTES.TCS}/${response.data.id}`);
              handleCloseSnackbar()
            }, 4000);
            // props.history.push(`${INTERNALROUTES.TCS}/${response.data.id}`);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while creating tcs master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };
  React.useEffect(() => {
    setTcsData(props?.selectedTcsData);
    setTcsOperation(props.tcsOperation);
  }, [props?.selectedTcsData, props.tcsOperation]);
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "TCS Master",
                onClick: () => props.history.push(INTERNALROUTES.TCS),
              },
              {
                title: tcsOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TCS PERCENT *
              </Typography>
              <TextField
                label="Tcs Percent"
                name="tcs_percent"
                type="text"
                onChange={handleChange}
                placeholder="Enter tcs percent"
                className={classes.textField}
                // inputProps={{ maxLength: 5, minLength: 5, max: 5 }}
                margin="dense"
                variant="outlined"
                value={tcsData?.tcs_percent || ""}
                error={tcsError.tcs_percent}
                helperText={tcsError.tcs_percent ? "Invalid Percentage" : ""}
              // onKeyPress={(event) => {
              //   if (event.charCode >= 48 && event.charCode <= 57) {
              //     // let it happen, don't do anything
              //   }
              //   else if (event.charCode === 46) {
              //     // let it happen, don't do anything
              //   }
              //   else { event.preventDefault(); }
              // }}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TCS CODE *
              </Typography>
              <TextField
                label="Tcs Code"
                name="tcs_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter tcs code"
                className={classes.textField}
                inputProps={{ maxLength: 4, minLength: 4, max: 4 }}
                margin="dense"
                variant="outlined"
                value={tcsData?.tcs_code || ""}
                error={tcsError.tcs_code}
                helperText={tcsError.tcs_code ? "Invalid Code" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACTIVE
              </Typography>
              <div className={classes.isActive}>
                <IOSSwitch
                  checked={(tcsData.is_active) === undefined
                    ? true
                    : Boolean(tcsData.is_active)
                  }
                  onChange={handleChange}
                  name="is_active"
                />
              </div>
            </div>
            <SaveButton
              size="medium"
              disabled={
                !tcsData?.tcs_percent ||
                !tcsData?.tcs_code ||
                tcsError.tcs_code ||
                tcsError.tcs_percent
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {tcsOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
      {/* <RemoveConfirmationDialog
        open={removeConfirmationDialogData.openDialog}
        dialogTitle={removeConfirmationDialogData.dialogTitle}
        dialogContent={removeConfirmationDialogData.dialogContent}
        removeDataLoader={removePlantItemMasterLoader}
        handleClose={handleRemoveConfirmationDialogClose}
        handleConfirmationDialogDeleteClick={
          handleConfirmationDialogDeleteClick
        }
      /> */}
    </Layout>
  );
}
export default withRouter(ManageTcsData);
