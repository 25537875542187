import React, { Component } from "react";
// import { Route } from "react-router-dom";
import Registration from "../../components/b2b/mobile/Registration";
import RegistrationWeb from "../../components/b2b/web/RegistrationWeb";
import axios from "axios";
import B2b_URL from "../../constants/b2b/B2bRoutes";
import SnackbarComponent from "../../components/common/SnackbarComponent"
import {
  isCharactersValid,
  isValidIFSCCode,
  isValidNumber,
  isPanNumberValid,
  validateGstinNumber,
  checkEmailAddress,
  isPhoneNumberValid,
  checkInvalidValue
} from "../../methods";
import AUTH_URL from "../../constants/common/LoginApiURLs"
import moment from "moment";
import HOST from "../../constants/common/host";
class RegistrationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {

      activeStep: 0,
      category: "",
      Gst_number: "",
      addressLine_one: "",
      addressLine_two: "",
      addressLine_three: "",
      city: "",
      pincode: "",
      firstName: "",
      lastName: "",
      dob: null,
      email: "",
      contactNumber: "",
      contactCountryCode: "",
      otp: "",
      password: "",
      confirmPassword: "",
      numberExist: false,
      emailOTP: "",
      gstNumberCheck: "pending",
      panNumber: "",
      panCardHolderName: "",
      companyName: "",
      panCardImageFile: null,
      panImageFileTypeError: false,
      panAddressLineOne: "",
      panAddressLineTwo: "",
      panAddressLineThree: "",
      panAddressCountry: "",
      panAddresssState: "",
      panAddresssCity: "",
      panAdressPincode: "",
      countries: [],
      states: [],
      cities: [],
      contactPerson: [],
      banks: [],
      ifsc: null,
      title: null,
      cename: null,
      party_bank_acc_no: null,
      party_bank_name: null,
      isEmailVerified: false,
      isPhoneVerified: false,
      timerOn: false,
      timerText: '',
      registrationSubmitLoader: false
    };
  }
  componentDidMount() {
    this.getCountriesFromMasterData();
    this.fetchAllBanks()
    this.getStatesFromMasterData(101)

  }

  handleFileTypeError = (type) => {
    // console.log(type)
    if (type === "application/pdf" || type === "image/png" || type === "image/jpeg" || type === "image/jpg") {
      return false
    } else return true
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  handleSnackbarMessage = (data) => {
    if (data) {
      let message = ""
      // console.log(Object.keys(data))
      Object.keys(data).forEach(key => {
        if (key === "pan_information") {
          let d = data[key]
          Object.keys(d).forEach(k => {
            // console.log("here")
            let m = d[k]
            message += `\n${k} : ${m[0]}`
          })
        } else {
          let m = data[key]
          // console.log(message.concat(message, m[0]))
          message += `\n${key} : ${m[0]}`
        }
      })
      return message
      // console.log(message)
    }
  }

  fetchAllBanks = () => {
    axios
      .get(B2b_URL.BANK_MASTER)
      .then((response) => {
        // console.log(response)
        this.setState({
          banks: response.data
        })
      })
      .catch(error => console.log(error))
  }
  getCountriesFromMasterData = () => {
    axios
      .get(B2b_URL.GET_COUNTRIES)
      .then((resolve) => {
        let countries = [];
        // console.log(resolve)
        if (resolve?.status === 200) {
          // const country = resolve.data.find((element) => element.id === 101);
          // countries.push(country);
          this.setState({ countries: resolve.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getStatesFromMasterData = (id) => {
    this.setState({ panAddressCountry: id });
    axios
      .get(B2b_URL.GET_STATES + id)
      .then((resolve) => {
        let states = [];
        if (resolve?.status === 200) {
          resolve.data.map((doc) => states.push(doc));
        }
        this.setState({ states });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getCityFromMasterData = (id) => {
    this.setState({ panAddresssState: id }, () => {
      axios
        .get(B2b_URL.GET_CITIES + id)
        .then((resolve) => {
          let cities = [];
          if (resolve?.status === 200) {
            cities = resolve.data.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
            // cities = resolve.data
          }
          // console.log(cities)
          this.setState({ cities });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  handleSelectCity = (id) => {
    this.setState({ panAddresssCity: id });
  };

  handleDateChange = (name) => (date) => {
    if (date !== "Invalid Date") {
      // console.log(name, date)
      this.setState({
        [name]: moment(date).format("YYYY-MM-DD"),
      })
    } else {
      console.log("invalid date");
    }
  }

  handleTextChange = (event) => {
    const { name, value } = event.target
    // console.log(event, name, value)
    this.setState({
      [name]: (name === "ifsc" || name === "panNumber") ? value.toUpperCase() : value,
      [`${name}_Error`]: value === "" ? false
        : name === "firstName" ||
          name === "lastName" ||
          name === "cename" ||
          name === "panCardHolderName" ||
          name === "companyName" ? !isCharactersValid(value)
          : name === "ifsc" ? !isValidIFSCCode(value)
            : name === "party_bank_acc_no" ? !isValidNumber(value)
              : name === "panNumber" ? !isPanNumberValid(value)
                : name === "Gst_number" ? !validateGstinNumber(value)
                  : name === "email" ? checkEmailAddress(value) === "error" ? true : false
                    : name === "contactNumber" ? !isPhoneNumberValid(value)
                      : false
    });
    if (name === "panAdressPincode" && value.length === 6) {
      this.handleFetchStateAndCities(event)
    }
    else if (name === "panAdressPincode" && value.length !== 6) {
      this.setState({
        panAddresssState: "",
        panAddresssCity: ""
      })
    }
  };
  handleSelectCategory = (category) => {
    this.setState({ category: category });
  };

  handleSelect = (event) => {
    const { name, value } = event.target
    // console.log(name, value)
    this.setState({
      [name]: value,
    });
    if (name === "panAddresssState") {
      this.getCityFromMasterData(value)
    }
  }
  phoneExist = (contactNumber) =>
    new Promise((resolve, reject) => {
      axios
        .post(B2b_URL.PHONE_EXIST, {
          phone: contactNumber,
        })
        .then(resolve)
        .catch(reject);
    });
  emailExist = (email) =>
    new Promise((resolve, reject) => {
      axios
        .post(B2b_URL.EMAIL_EXIST, {
          email: email,
        })
        .then(resolve)
        .catch(reject);
    });
  resetOTP = () => {
    this.setState({ otp: "" });
  };
  resetEmailOtp = () => {
    this.setState({ emailOTP: "" });
  };

  sendOTP = (contactNumber) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTH_URL.SENT_OTP_FOR_FORGOT_PASSWORD, {
          phone: contactNumber,
        })
        .then(resolve)
        .catch(reject);
    });

  clearOTPInput = () => {
    this.setState({ otp: "" });
  };

  handleTimerOn = (data) => {
    this.setState({ timerOn: data })
  }

  timer = (remaining) => {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    this.setState({ timerText: m + ':' + s })
    remaining -= 1;

    if (remaining >= 0 && this.state.timerOn) {
      setTimeout(() => {
        this.timer(remaining)
      }, 1000);
      return;
    }
  }

  GetOtpEmailRegistration = (email) => {
    let data = { email: email }
    return axios.post(AUTH_URL.GET_OTP_EMAIL_REGISTERATION, data);
  }
  VerifyOtpEmailRegistration = (email, otp) => {
    let data = { email: email, otp: otp }
    return axios.post(AUTH_URL.VERIFY_OTP_EMAIL_REGISTERATION, data);
  }
  GetOtpPhoneRegistration = (phone) => {
    let data = { phone: phone }
    return axios.post(AUTH_URL.GET_OTP_PHONE_REGISTERATION, data);
  }
  VerifyOtpPhoneRegistration = (phone, otp) => {
    let data = { phone: phone, otp: otp }
    return axios.post(AUTH_URL.VERIFY_OTP_PHONE_REGISTERATION, data);
  }

  otpSendInEmail = (email) =>
    new Promise((resolve, reject) => {
      axios
        .post(AUTH_URL.SENT_OTP_FOR_FORGOT_PASSWORD_EMAIL, {
          email: email,
        })
        .then(resolve)
        .catch(reject);
    });

  handleEmailVerification = (email, otp) => {
    return axios.
      post(`${HOST}api/auth/reset-password/email-verification-verify-otp-for-reset-password/`,
        { email, otp })
  }

  handlePhoneVerification = (phone, otp) => {
    return axios.
      post(`${HOST}api/auth/reset-password/phone-verification-verify-otp-for-reset-password/`,
        { phone, otp })
  }

  fieldVerified = (name, value) => {
    // console.log(name, value)
    this.setState({
      [name]: value
    })
  }

  handleFetchStateAndCities = (e) => {
    const { name, value } = e.target
    // console.log(name, value)

    axios.get(`${B2b_URL.GET_STATE_AND_CITY}${value}/`)
      .then(response => {
        // console.log(response)
        if (response.status === 200 && response.data.length > 0) {

          this.getCityFromMasterData(response.data[0].state_detail?.id, "from_pincode")
          this.setState({
            panAddresssState: response.data[0].state_detail?.id,
            panAddresssCity: response.data[0].city_detail?.id
          })
          // console.log("here")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleGetGSTInfo = (Gst_number) => {
    this.setState({ gstLoader: true, Gst_number });
    axios
      .post(B2b_URL.GET_GST_INFO, {
        gstin_number: Gst_number,
      })
      .then((resolve) => {
        // if (resolve.data.msg === "Already exist") {
        //   this.setState({ gstNumberAlreadyRegistered: true, gstLoader: false });
        // }
        // if (resolve.data.msg.error === false) {
        let gstData = {};
        gstData = resolve.data.message;

        if (gstData.sts == "Cancelled") {
          this.setState({
            gstNumberCheck: "invalid",
            gstLoader: false,
            gstNumberAlreadyRegistered: false,
            openSnackbar: true,
            snackbarMessage: "Gst number is cancelled",
            snackbarType: "error",
          });
          setTimeout(() => {
            this.handleSnackbarClose()
          }, 3000)
        }
        else {
          let gstRes_date = gstData.rgdt;
          let gstRdate = gstRes_date.split("/");
          let gst_last_updated_date = gstData.lstupdt.split("/");
          if (gstData.cxdt !== "") {
            let gstCDate = gstData.cxdt;
            let gstCancleDate = gstCDate.split("/");
            this.setState({
              gstCancelationDate:
                gstCancleDate[2] +
                "-" +
                gstCancleDate[1] +
                "-" +
                gstCancleDate[0],
            });
          }

          this.setState(
            {
              gstData,
              gstNumberCheck: "valid",
              gstLoader: false,
              gstNumberAlreadyRegistered: false,
              gstRegistarionDate:
                gstRdate[2] + "-" + gstRdate[1] + "-" + gstRdate[0],
              gst_last_updated_date:
                gst_last_updated_date[2] +
                "-" +
                gst_last_updated_date[1] +
                "-" +
                gst_last_updated_date[0],
            },
            () => {
              let gstAddress = [];
              const gstPrimaryAddress = {
                building_name: this.state.gstData.pradr.addr.bnm,
                street: this.state.gstData.pradr.addr.st,
                location: this.state.gstData.pradr.addr.loc,
                door_number: this.state.gstData.pradr.addr.bno,
                state_name: this.state.gstData.pradr.addr.stcd,
                floor_number: this.state.gstData.pradr.addr.flno,
                pincode: this.state.gstData.pradr.addr.pncd,
                is_default_address: true,
                district_name: this.state.gstData.pradr.addr.dst,
                city_name: this.state.gstData.pradr.addr.city,
                nature_of_place_of_business: this.state.gstData.pradr.ntr,
              };
              gstAddress.push(gstPrimaryAddress);
              this.state.gstData.adadr.slice(0, 6).map((data) =>
                gstAddress.push({
                  building_name: data.addr.bnm,
                  street: data.addr.st,
                  location: data.addr.loc,
                  door_number: data.addr.bno,
                  state_name: data.addr.stcd,
                  floor_number: data.addr.flno,
                  pincode: data.addr.pncd,
                  is_default_address: false,
                  district_name: data.addr.dst,
                  city_name: data.addr.city,
                  nature_of_place_of_business: data.ntr,
                })
              );

              this.setState({
                gstAddress,
                state: this.state.gstData.pradr.addr.stcd,
                pincode: this.state.gstData.pradr.addr.pncd,
                addressLine_one:
                  this.state.gstData.pradr.addr.bnm +
                  this.state.gstData.pradr.addr.st +
                  this.state.gstData.pradr.addr.loc +
                  this.state.gstData.pradr.addr.bno +
                  this.state.gstData.pradr.addr.bno,
              });
            }
          );
        }
        // }
        // if (resolve.data.msg.error === true) {
        //   this.setState({
        //     gstNumberCheck: "invalid",
        //     gstLoader: false,
        //     gstNumberAlreadyRegistered: false,
        //   });
        // }
      })
      .catch((reject) => {
        this.setState({
          gstNumberCheck: "invalid",
          gstLoader: false,
          gstNumberAlreadyRegistered: false,
          gstErrorMessage: reject.response.data.message,
        });
      });
  };
  resetGstDetail = () => {
    this.setState({
      gstNumberCheck: "pending",
      gstData: {},
      gstNumberAlreadyRegistered: false,
    });
  };
  handlePanCardImageFile = (file) => {
    this.setState({
      panCardImageFile: file,
      panImageFileTypeError: file !== null ? this.handleFileTypeError(file.type) : false
    });
  };
  handleCheckBookCardImageFile = (file) => {
    // console.log(file)
    this.setState({
      checkbookImageFile: file,
    });
  };

  handleSwipeableIndex = (index, oldIndex, meta) => {
    this.setState({
      activeStep: index,
    });
  };

  handleActiveStep = () => {
    // console.log(this.state.activeStep)
    if (
      this.state.activeStep === 4 &&
      this.state.gstNumberCheck === "valid"
    ) {

      // console.log("here")
      this.handleSubmitCustomerRegistration("GST")
      // this.handleActiveStep();
    } else if (this.state.activeStep === 5) {
      // console.log("here")
      this.handleSubmitCustomerRegistration("PAN")

      // this.handleActiveStep();
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };
  handleBackStep = () => {
    if (this.state.activeStep > 0) {
      this.setState({
        activeStep: this.state.activeStep - 1,
      });
    } else {
      this.props.history.push("/login");
    }
  };

  handleSubmitCustomerRegistration = (doc) => {
    // console.log("heere")
    const { ifsc, title, cename, party_bank_acc_no, party_bank_name, checkbookImageFile } = this.state
    // console.log(ifsc, title, cename, party_bank_acc_no, party_bank_name, checkbookImageFile)
    let address = ""
    var pan_card_image = new FormData();
    let checkbook_image = new FormData()
    let data = new FormData();
    if (doc === "PAN") {

      pan_card_image.append("image", this.state.panCardImageFile);
      address =
        this.state.panAddressLineOne +
        " " +
        this.state.panAddressLineTwo +
        " " +
        this.state.panAddressLineThree;
    }
    if (checkbookImageFile) {
      checkbook_image.append("image", checkbookImageFile);
    }
    this.setState({ registrationLoader: true });
    // console.log(this.state.gstAddress)
    if (doc === "GST") {
      data.append("first_name", checkInvalidValue(this.state.firstName) ? "" : this.state.firstName);
      data.append("last_name", checkInvalidValue(this.state.lastName) ? "" : this.state.lastName);
      data.append("date_of_birth", checkInvalidValue(this.state.dob) ? "" : this.state.dob);
      data.append("customer_type", checkInvalidValue(this.state.category) ? "" : this.state.category);
      data.append("customer_discount", 0);
      data.append("ifsc", checkInvalidValue(ifsc) ? null : ifsc);
      data.append("title", checkInvalidValue(title) ? "" : title);
      data.append("cename", checkInvalidValue(cename) ? "" : cename);
      data.append("party_bank_acc_no", checkInvalidValue(party_bank_acc_no) ? "" : party_bank_acc_no);
      data.append("party_bank_name", checkInvalidValue(party_bank_name) ? "" : party_bank_name);
      checkbookImageFile !== undefined && data.append("checkbook_image", checkbookImageFile);
      data.append("credentials.email", checkInvalidValue(this.state.email) ? "" : this.state.email);
      data.append("credentials.phone", checkInvalidValue(this.state.contactNumber) ? "" : this.state.contactNumber);
      data.append("credentials.country_code", checkInvalidValue(this.state.country_code) ? "" : this.state.country_code);
      data.append("credentials.password", checkInvalidValue(this.state.password) ? "" : this.state.password);
      data.append("gst_information.gstin_number", checkInvalidValue(this.state.Gst_number) ? "" : this.state.Gst_number)
      data.append("gst_information.legal_name_of_business", this.state.gstData.lgnm && this.state.gstData.lgnm)
      data.append("gst_information.trade_name", this.state.gstData && checkInvalidValue(this.state.gstData.tradeNam) ? null : this.state.gstData.tradeNam)
      data.append("gst_information.state_jurisdiction", this.state.gstData && this.state.gstData.stj)
      data.append("gst_information.centre_jurisdiction", this.state.gstData && this.state.gstData.ctj)
      data.append("gst_information.date_of_registration", this.state.gstRegistarionDate && this.state.gstRegistarionDate)
      data.append("gst_information.constitution_of_business", this.state.gstData && this.state.gstData.ctb)
      data.append("gst_information.taxpayer_type", this.state.gstData && this.state.gstData.dty)
      data.append("gst_information.nature_of_business_activity", this.state.gstData &&
        this.state.gstData.nba.toString().split(",").join(", "))
      data.append("gst_information.gstin_status", this.state.gstData && this.state.gstData.sts)
      data.append("gst_information.date_of_cancellation", this.state.gstData && this.state.gstData.cxdt === ""
        ? ""
        : this.state.gstCancelationDate ? this.state.gstCancelationDate : "")
      data.append("gst_information.last_updated_date", this.state.gst_last_updated_date)
      for (let i = 0; i < this.state.gstAddress.length; i++) {
        data.append(
          `gst_addresses[${i}]building_name`, this.state.gstAddress[i].building_name
        )
        data.append(
          `gst_addresses[${i}]street`, this.state.gstAddress[i].street
        )
        data.append(
          `gst_addresses[${i}]location`, this.state.gstAddress[i].location
        )
        data.append(
          `gst_addresses[${i}]door_number`, this.state.gstAddress[i].door_number
        )
        data.append(
          `gst_addresses[${i}]state_name`, this.state.gstAddress[i].state_name
        )
        data.append(
          `gst_addresses[${i}]floor_number`, this.state.gstAddress[i].floor_number
        )
        data.append(
          `gst_addresses[${i}]pincode`, this.state.gstAddress[i].pincode
        )
        data.append(
          `gst_addresses[${i}]is_default_address`, this.state.gstAddress[i].is_default_address
        )
        data.append(
          `gst_addresses[${i}]district_name`, this.state.gstAddress[i].district_name || this.state.gstAddress[i].city_name
        )
        data.append(
          `gst_addresses[${i}]city_name`, this.state.gstAddress[i].city_name || this.state.gstAddress[i].district_name
        )
        data.append(
          `gst_addresses[${i}]nature_of_place_of_business`, this.state.gstAddress[i].nature_of_place_of_business
        )
      }
      // building_name: data.addr.bnm,
      //           street: data.addr.st,
      //           location: data.addr.loc,
      //           door_number: data.addr.bno,
      //           state_name: data.addr.stcd,
      //           floor_number: data.addr.flno,
      //           pincode: data.addr.pncd,
      //           is_default_address: false,
      //           district_name: data.addr.dst,
      //           city_name: data.addr.city,
      //           nature_of_place_of_business: data.ntr
      // data.append("gst_addresses", JSON.stringify(this.state.gstAddress))

      // var data = {
      //   gst_information: {
      //     // gstin_number: this.state.Gst_number,
      //     // legal_name_of_business:
      //     //   this.state.gstData.lgnm && this.state.gstData.lgnm,
      //     // trade_name: this.state.gstData && this.state.gstData.tradeNam,
      //     // state_jurisdiction: this.state.gstData && this.state.gstData.stj,
      //     // centre_jurisdiction: this.state.gstData && this.state.gstData.ctj,
      //     // date_of_registration:
      //     //   this.state.gstRegistarionDate && this.state.gstRegistarionDate,
      //     // constitution_of_business:
      //     //   this.state.gstData && this.state.gstData.ctb,
      //     // taxpayer_type: this.state.gstData && this.state.gstData.dty,
      //     // nature_of_business_activity:
      //     //   this.state.gstData &&
      //     //   this.state.gstData.nba.toString().split(",").join(", "),

      //     // gstin_status: this.state.gstData && this.state.gstData.sts,
      //     // date_of_cancellation:
      //     //   this.state.gstData && this.state.gstData.cxdt === ""
      //     //     ? null
      //     //     : this.state.gstCancelationDate && this.state.gstCancelationDate,
      //     last_updated_date: this.state.gst_last_updated_date,
      //   },
      //   gst_addresses: this.state.gstAddress,
      // };

      // if (checkbookImageFile) {
      //   data.append("checkbook_image", checkbookImageFile);
      // }
    } else {
      // data = new FormData();
      data.append("first_name", checkInvalidValue(this.state.firstName) ? "" : this.state.firstName);
      data.append("last_name", checkInvalidValue(this.state.lastName) ? "" : this.state.lastName);
      data.append("date_of_birth", checkInvalidValue(this.state.dob) ? "" : this.state.dob);
      data.append("customer_type", checkInvalidValue(this.state.category) ? "" : this.state.category);
      data.append("customer_discount", 0);
      data.append("ifsc", checkInvalidValue(ifsc) ? null : ifsc);
      data.append("title", checkInvalidValue(title) ? "" : title);
      data.append("cename", checkInvalidValue(cename) ? "" : cename);
      data.append("party_bank_acc_no", checkInvalidValue(party_bank_acc_no) ? "" : party_bank_acc_no);
      data.append("party_bank_name", checkInvalidValue(party_bank_name) ? "" : party_bank_name);
      checkbookImageFile !== undefined && data.append("checkbook_image", checkbookImageFile);
      data.append("customer_addresses[0]address", address);
      data.append("customer_addresses[0]pincode", this.state.panAdressPincode);
      data.append("customer_addresses[0]is_default_address", true);
      data.append(`customer_addresses[0]is_active`, true);
      data.append("customer_addresses[0]country", this.state.panAddressCountry);
      data.append("customer_addresses[0]state", this.state.panAddresssState);
      data.append("customer_addresses[0]city", this.state.panAddresssCity);
      data.append("credentials.email", checkInvalidValue(this.state.email) ? "" : this.state.email);
      data.append("credentials.phone", checkInvalidValue(this.state.contactNumber) ? "" : this.state.contactNumber);
      data.append("credentials.country_code", checkInvalidValue(this.state.country_code) ? "" : this.state.country_code);
      data.append("credentials.password", checkInvalidValue(this.state.password) ? "" : this.state.password);
      data.append(
        "pan_information.pan_card_holder_name",
        this.state.panCardHolderName
      );
      data.append(
        "pan_information.pan_card_number",
        this.state.panNumber.toUpperCase()
      );
      data.append("pan_information.company_name", this.state.companyName);
      data.append("pan_information.pan_card_file", this.state.panCardImageFile);
    }
    this.setState({ registrationSubmitLoader: true })
    console.log(data)
    axios({
      method: "post",
      url: B2b_URL.CUSTOMER_REGISTRATION,
      data: data,
    })
      .then((response) => {
        if (response.status === 201) {
          if (this.state.contactPerson.length > 0) {
            this.handleAddContactPerson(response.data.id);
          }
          this.setState({
            registrationLoader: false,
            registrationSubmitLoader: false
          });
          this.setState({
            activeStep: this.state.activeStep + 1,
          });
        }
      })
      .catch((e) => {
        // console.log(e?.response?.data)
        this.setState({
          openSnackbar: true,
          snackbarMessage: e?.response?.data ? this.handleSnackbarMessage(e?.response?.data) : "Error occured while registering",
          snackbarType: "error",
          registrationSubmitLoader: false
        });


      });
  };
  handleAddContactPerson = (id) => {
    var data = this.state.contactPerson.map((data) => {
      var o = Object.assign({}, data);
      o.customer = id;
      return o;
    });

    axios({
      method: "post",
      url: B2b_URL.CONTACT_PERSON,
      data: data,
    })
      .then(() => { })
      .catch((e) => console.log(e.response));
  };
  handleContactPerson = (person) => {
    this.setState({ contactPerson: person });
  };
  render() {
    const { isMobile } = this.props;
    const {
      category,
      Gst_number,
      addressLine_one,
      addressLine_two,
      addressLine_three,
      city,
      pincode,
      firstName,
      lastName,
      dob,
      contactNumber,
      email,
      otp,
      password,
      confirmPassword,
      otpNumber,
      legal_name_of_business,
      date_of_registration,
      emailOTP,
      gstNumberCheck,
      gstErrorMessage,
      gstLoader,
      gstData,
      gstNumberAlreadyRegistered,
      panNumber,
      panCardHolderName,
      companyName,
      panCardImageFile,
      panAddressLineOne,
      panAddressLineTwo,
      panAddressLineThree,
      panAddressCountry,
      panAddresssState,
      panAddresssCity,
      panAdressPincode,
      countries,
      states,
      cities, cename_Error,
      snackbarType,
      snackbarMessage,
      openSnackbar,
      timerOn,
      timerText,
      panImageFileTypeError
    } = this.state;
    // console.log(cename_Error)
    // console.log(this.state.gstCancelationDate)
    return (
      <div>
        {isMobile ? (
          <Registration
            {...this.props}
            {...this.state}
            handleTextChange={this.handleTextChange}
            handleDateChange={this.handleDateChange}
            handleSelectCategory={this.handleSelectCategory}
            category={category}
            Gst_number={Gst_number}
            addressLine_one={addressLine_one}
            addressLine_two={addressLine_two}
            addressLine_three={addressLine_three}
            city={city}
            pincode={pincode}
            firstName={firstName}
            lastName={lastName}
            dob={dob}
            contactNumber={contactNumber}
            email={email}
            otp={otp}
            password={password}
            confirmPassword={confirmPassword}
            emailExist={this.emailExist}
            phoneExist={this.phoneExist}
            sendOTP={this.sendOTP}
            otpNumber={otpNumber}
            clearOTPInput={this.clearOTPInput}
            legal_name_of_business={legal_name_of_business}
            date_of_registration={date_of_registration}
            handleSubmitCustomerRegistration={
              this.handleSubmitCustomerRegistration
            }
            centre_jurisdiction={this.state.centre_jurisdiction}
            state_jurisdiction={this.state.state_jurisdiction}
            constitution_of_business={this.state.constitution_of_business}
            taxpayer_type={this.state.taxpayer_type}
            GSTIN_or_UIN_Status={this.state.GSTIN_or_UIN_Status}
            date_of_cancellation={this.state.date_of_cancellation}
            field_visit_conducted={this.state.field_visit_conducted}
            trade_name={this.state.trade_name}
            registrationLoader={this.state.registrationLoader}
            otpSendInEmail={this.otpSendInEmail}
            emailOTP={emailOTP}
            resetEmailOtp={this.resetEmailOtp}
            handleGetGSTInfo={this.handleGetGSTInfo}
            gstNumberCheck={gstNumberCheck}
            gstErrorMessage={gstErrorMessage}
            gstLoader={gstLoader}
            resetGstDetail={this.resetGstDetail}
            gstData={gstData}
            gstNumberAlreadyRegistered={gstNumberAlreadyRegistered}
            state={this.state.state}
            resetOTP={this.resetOTP}
            panNumber={panNumber}
            handlePanCardImageFile={this.handlePanCardImageFile}
            panCardHolderName={panCardHolderName}
            companyName={companyName}
            panCardImageFile={panCardImageFile}
            panImageFileTypeError={panImageFileTypeError}
            panAddressLineOne={panAddressLineOne}
            panAddressLineTwo={panAddressLineTwo}
            panAddressLineThree={panAddressLineThree}
            getStatesFromMasterData={this.getStatesFromMasterData}
            countries={countries}
            states={states}
            getCityFromMasterData={this.getCityFromMasterData}
            cities={cities}
            panAddressCountry={panAddressCountry}
            panAddresssState={panAddresssState}
            panAddresssCity={panAddresssCity}
            panAdressPincode={panAdressPincode}
            handleSelect={this.handleSelect}
            handleSelectCity={this.handleSelectCity}
            handleContactPerson={this.handleContactPerson}
            handleCheckBookCardImageFile={this.handleCheckBookCardImageFile}
            handleEmailVerification={this.handleEmailVerification}
            handlePhoneVerification={this.handlePhoneVerification}
            fieldVerified={this.fieldVerified}
            handleFetchStateAndCities={this.handleFetchStateAndCities}
            handleBackStep={this.handleBackStep}
            handleActiveStep={this.handleActiveStep}
            handleSwipeableIndex={this.handleSwipeableIndex}
            registrationSubmitLoader={this.state.registrationSubmitLoader}
          />
        ) : (
          <RegistrationWeb
            {...this.props}
            {...this.state}
            handleSelectCategory={this.handleSelectCategory}
            category={category}
            Gst_number={Gst_number}
            handleTextChange={this.handleTextChange}
            handleDateChange={this.handleDateChange}
            addressLine_one={addressLine_one}
            addressLine_two={addressLine_two}
            addressLine_three={addressLine_three}
            city={city}
            pincode={pincode}
            firstName={firstName}
            lastName={lastName}
            dob={dob}
            contactNumber={contactNumber}
            email={email}
            otp={otp}
            password={password}
            confirmPassword={confirmPassword}
            phoneExist={this.phoneExist}
            emailExist={this.emailExist}
            resetOTP={this.resetOTP}
            handleSubmitCustomerRegistration={
              this.handleSubmitCustomerRegistration
            }
            sendOTP={this.sendOTP}
            otpNumber={otpNumber}
            legal_name_of_business={legal_name_of_business}
            date_of_registration={date_of_registration}
            country={this.state.country}
            state={this.state.state}
            centre_jurisdiction={this.state.centre_jurisdiction}
            state_jurisdiction={this.state.state_jurisdiction}
            constitution_of_business={this.state.constitution_of_business}
            taxpayer_type={this.state.taxpayer_type}
            GSTIN_or_UIN_Status={this.state.GSTIN_or_UIN_Status}
            date_of_cancellation={this.state.date_of_cancellation}
            field_visit_conducted={this.state.field_visit_conducted}
            trade_name={this.state.trade_name}
            registrationLoader={this.state.registrationLoader}
            otpSendInEmail={this.otpSendInEmail}
            emailOTP={emailOTP}
            resetEmailOtp={this.resetEmailOtp}
            handleGetGSTInfo={this.handleGetGSTInfo}
            gstNumberCheck={gstNumberCheck}
            gstErrorMessage={gstErrorMessage}
            gstLoader={gstLoader}
            resetGstDetail={this.resetGstDetail}
            gstData={gstData}
            gstNumberAlreadyRegistered={gstNumberAlreadyRegistered}
            panNumber={panNumber}
            panCardHolderName={panCardHolderName}
            companyName={companyName}
            panCardImageFile={panCardImageFile}
            panImageFileTypeError={panImageFileTypeError}
            handlePanCardImageFile={this.handlePanCardImageFile}
            panAddressLineOne={panAddressLineOne}
            panAddressLineTwo={panAddressLineTwo}
            panAddressLineThree={panAddressLineThree}
            panAddressCountry={panAddressCountry}
            panAddresssState={panAddresssState}
            panAddresssCity={panAddresssCity}
            panAdressPincode={panAdressPincode}
            countries={countries}
            getStatesFromMasterData={this.getStatesFromMasterData}
            states={states}
            getCityFromMasterData={this.getCityFromMasterData}
            cities={cities}
            handleSelectCity={this.handleSelectCity}
            handleContactPerson={this.handleContactPerson}
            handleCheckBookCardImageFile={this.handleCheckBookCardImageFile}
            handleEmailVerification={this.handleEmailVerification}
            handlePhoneVerification={this.handlePhoneVerification}
            fieldVerified={this.fieldVerified}

            handleFetchStateAndCities={this.handleFetchStateAndCities}
            handleBackStep={this.handleBackStep}
            handleActiveStep={this.handleActiveStep}
            handleSwipeableIndex={this.handleSwipeableIndex}
            timerText={timerText}
            timerOn={timerOn}
            handleTimerOn={this.handleTimerOn}
            timer={this.timer}
            GetOtpEmailRegistration={this.GetOtpEmailRegistration}
            VerifyOtpEmailRegistration={this.VerifyOtpEmailRegistration}
            GetOtpPhoneRegistration={this.GetOtpPhoneRegistration}
            VerifyOtpPhoneRegistration={this.VerifyOtpPhoneRegistration}
            registrationSubmitLoader={this.state.registrationSubmitLoader}
          />
        )}
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </div>
    );
  }
}
export default RegistrationContainer;
