import React from "react";
export default class MaskGroupSVG extends React.Component {
  render() {
    return (
      <svg
        style={{ fill: "white", height: "20px", width: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="18.455"
        height="14"
        viewBox="0 0 18.455 14"
      >
        <g class="b">
          <g transform="translate(1.293 -0.128)">
            <path
              class="c"
              d="M3.575,72.848a7.015,7.015,0,0,1,0-10.975A.465.465,0,0,0,3,61.145a7.945,7.945,0,0,0,0,12.43.465.465,0,0,0,.579-.727Z"
              transform="translate(0 -61.044)"
            />
            <g transform="translate(12.118 0)">
              <path
                class="c"
                d="M918.757,61.149a.465.465,0,0,0-.579.727,7.015,7.015,0,0,1,0,10.975.465.465,0,0,0,.58.727,7.945,7.945,0,0,0,0-12.429Z"
                transform="translate(-918.003 -61.047)"
              />
            </g>
            <g transform="translate(2.479 1.94)">
              <path
                class="c"
                d="M190.471,215.937a4.526,4.526,0,0,1,0-7.095.465.465,0,0,0-.579-.728,5.456,5.456,0,0,0,0,8.55.465.465,0,0,0,.579-.728Z"
                transform="translate(-187.826 -208.014)"
              />
            </g>
            <g transform="translate(10.568 1.94)">
              <path
                class="c"
                d="M801.366,208.121a.465.465,0,0,0-.579.728,4.526,4.526,0,0,1,0,7.095.465.465,0,0,0,.579.728,5.456,5.456,0,0,0,0-8.55Z"
                transform="translate(-800.612 -208.02)"
              />
            </g>
            <g transform="translate(5.609 3.998)">
              <path
                class="c"
                d="M427.281,363.913a2.324,2.324,0,0,0-.465,4.6v5.191a.465.465,0,1,0,.93,0v-5.191a2.324,2.324,0,0,0-.465-4.6Zm0,3.719a1.395,1.395,0,1,1,1.395-1.395A1.4,1.4,0,0,1,427.281,367.632Z"
                transform="translate(-424.957 -363.913)"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
