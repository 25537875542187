import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
  Paper,
} from "@material-ui/core";
import {
  whiteSmoke,
  grey,
  secondaryBlueText,
  orange,
  drawerTextColor,
  primaryColor,
} from "../../../../constants/internal/colors";
import {
  isAccessibleModule,
  generateCSV,
  isRequired,
  options,
} from "../../../../methods";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { pxToEm, hasWriteAccess } from "../../../../methods";
import SpinnerLoader from "../../../common/SpinLoader";
import "../../../../styles/internal/scroll.css";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import axios from 'axios'

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "calc(100vh - 300px)",
    overflow: "auto",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
  },
  tableRow: {
    cursor: "pointer",
  },
  alignText: {
    display: "flex",
    placeContent: "flex-end",
  },
  arrowDimension: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
  },
  productFont: {
    fontSize: pxToEm(13),
    paddingRight: 10,
  },
  newChipStyle: {
    alignItems: "center",
    padding: 3,
    marginRight: 10,
    fontSize: pxToEm(10),
    backgroundColor: orange,
    color: drawerTextColor,
    borderRadius: "45px",
  },
  pendingChipStyle: {
    padding: 3,
    alignItems: "center",
    fontSize: pxToEm(10),
    backgroundColor: secondaryBlueText,
    color: drawerTextColor,
    borderRadius: "45px",
  },
  inactiveChipStyle: {
    padding: 3,
    alignItems: "center",
    fontSize: pxToEm(10),
    backgroundColor: "red",
    color: drawerTextColor,
    borderRadius: "45px",
  },

  dataFont: {
    fontSize: pxToEm(13),
    color: grey,
  },
  loaderStyle: { color: primaryColor },
  loaderDiv: {
    padding: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 250px)",
  },
});

function DisplayCustomerDetails(props) {

  const classes = useStyles();
  const { history, page } = props;
  //const [page, setPage] = React.useState(props.searchPageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(props.page, newPage)
    // console.log(props.nextLink, props.prevLink)
    if (newPage > props.page) {
      props.handleNextCustomerPage(props.nextLink, newPage)
    }
    else if (newPage < props.page) {
      props.handleNextCustomerPage(props.prevLink, newPage)
    }
    // props.handleNextCustomerPage(newPage);
  };
  const [customerLoader, setCustomerLoader] = React.useState(
    props?.customerLoader
  );
  const [allCustomers, setAllCustomers] = React.useState(props?.allCustomers);
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(1);
  // };

  const [selectedRowID, setSelectedRowID] = useState(0)





  React.useEffect(() => {
    setCustomerLoader(props?.customerLoader);
    setAllCustomers(props?.allCustomers);
  }, [props?.allCustomers, props?.customerLoader]);

  // console.log(allCustomers)
  return (
    <Paper className={classes.root}>
      {customerLoader ? (
        <div className={classes.loaderDiv}>
          <SpinnerLoader className={classes.loaderStyle} />
        </div>
      ) : allCustomers?.length < 1 ? (
        <div className={classes.loaderDiv}>
          <Typography className={classes.loaderStyle}>
            Customers data not found.
          </Typography>
        </div>
      ) : (
        <>
          <TableContainer className={classes.container} id="scroll">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="left" className={classes.tableHead}>
                    COMPANY NAME
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    EMAIL
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    TRADE NAME
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    LEGAL NAME
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    PERSONAL NAME
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    CUSTOMER TYPE
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    GSTIN / PAN
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? allCustomers?.slice(0, rowsPerPage)
                  : allCustomers
                )?.map((customers) => (
                  <TableRow
                    className={classes.tableRow}
                    key={customers.id}
                    onClick={(evt) => {
                      evt.preventDefault();

                      // if( hasWriteAccess(props.user, 'customerManagement'))
                      // {
                      history.push(`/internalCustomers/view/${customers.id}`);
                      // extraphoneCall(customers.id); 
                    }}
                  // }
                  >
                    <TableCell component="th" scope="row">
                      <span className={classes.productFont}>
                        {
                          customers.customerHaveGstin
                            // ? customers.gst_information
                            ? customers.trade_name
                              ? customers.trade_name
                              : customers.legal_name_of_business
                                ? customers.legal_name_of_business
                                : customers.display_name
                            // : customers.display_name
                            : customers.company_name ?
                              customers.company_name :
                              customers.trade_name
                                ? customers.trade_name
                                : customers.legal_name_of_business
                                  ? customers.legal_name_of_business
                                  : customers.display_name
                                    ? customers.display_name
                                    : ""}
                      </span>
                      {console.log(customers)}
                      {customers?.credentials?.is_new ? (
                        <span className={classes.newChipStyle}>new</span>
                      ) : null}
                      {customers?.is_rejected ? (
                        <span className={classes.inactiveChipStyle}>rejected</span>
                      ) : null}
                      {!customers?.is_rejected && customers?.credentials?.is_active &&
                        !customers?.credentials?.is_approved ?
                        <span className={classes.pendingChipStyle}>
                          pending
                        </span> : !customers?.is_rejected && !customers?.credentials?.is_active &&
                          !customers?.credentials?.is_approved
                          ?
                          <span className={classes.pendingChipStyle}>
                            pending
                          </span> : !customers?.is_rejected && !customers?.credentials?.is_active &&
                            customers?.credentials?.is_approved
                            ?
                            <span className={classes.inactiveChipStyle}>
                              inactive
                            </span> : null
                      }
                    </TableCell>
                    <TableCell className={classes.dataFont} align="left">
                      {customers.email}
                    </TableCell>
                    <TableCell className={classes.dataFont} align="left">
                      {customers.trade_name}
                    </TableCell>
                    <TableCell className={classes.dataFont} align="left">
                      {customers.legal_name_of_buisness}
                    </TableCell>
                    <TableCell className={classes.dataFont} align="left">
                      {customers.display_name}
                    </TableCell>
                    <TableCell className={classes.dataFont} align="left">
                      {customers.customer_type}
                    </TableCell>
                    <TableCell className={classes.dataFont} align="right">
                      <div className={classes.alignText}>
                        <div className={classes.rightSpacing}>
                          {customers.panGstinNumber
                            ? customers.panGstinNumber
                            : customers.gst_information
                              ? customers.gst_information.gstin_number
                                ? customers.gst_information.gstin_number
                                : ""
                              : customers.pan_card_information
                                ? customers.pan_card_information.pan_card_number
                                  ? customers.pan_card_information.pan_card_number
                                  : ""
                                : ""}
                        </div>
                        <div>
                          <EditOutlinedIcon
                            className={classes.arrowDimension}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={props?.totalCustomersCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
          //onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
}
export default withRouter(DisplayCustomerDetails);
