import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { primaryColor, bottomNavigationFontColor, } from "../../../styles/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { secondaryColor } from "../../../styles/colors";
import Chip from "@material-ui/core/Chip";
import SpinLoader from "../../common/SpinLoader";
import CircularProgressLoader from '../../common/CircularProgressLoader'
import Backdrop from '@mui/material/Backdrop';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import backStack from "cordova-back-stack";
import SwipeComponent from "../common/SwipeComponent";
import TileSkeleton from "../common/TileSkeleton";
import { pxToEm } from "../../../methods";
import DispatchLAList from "../common/DispatchLAList";

const styles = {
  ordersMainBody: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dispatchNavContainer: {
    width: "90%",
    // height: "8vh",
    // display: "flex",
    // alignItems: "flex-end",
    // justifyContent: "center",
    marginBottom: "10px",
    // position: "fixed",
  },
  orderListContainer: (isMobile, showDispatch) => ({
    cursor: "pointer",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: window.innerHeight - 190,
    height: isMobile && window.innerHeight - 190,
    overflowY: "scroll",
    paddingBottom: isMobile ? showDispatch === "ongoing" ? 98 : 70 : 'inherit'
    // background:
    //   "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% repeat padding-box",
  }),
  buttonStyle: {
    textTransform: "capitalize",
    fontSize: "1.2em",
    marginRight: "15px",
  },
  ordersNavigationContainer: {
    width: "100%",
    display: "flex",
    padding: "20px 0px 10px 20px",
    // justifyContent: "center",
  },
  ordersNavigationStyle: {
    width: "90%",
    height: "56px",
    // margin: "30px 0px 30px 0px",
    display: "flex",
    alignItems: "center",
  },
  myOrderCircleStyle: {
    width: "8px",
    background: "black",
    height: "8px",
    borderRadius: "50%",
    marginLeft: "10px",
    marginTop: "-8px",
  },
  addDispatchFaviconContainer: (isMobile) => ({
    position: "fixed",
    bottom: isMobile ? "82px" : "20px",
    height: "70px",
    width: isMobile ? "30%" : "10%",
    right: "0px",
    display: "flex",
    justifyContent: "center",
    paddingRight: isMobile ? "0px" : "0px",
    marginLeft: isMobile ? "0%" : "7%",
  }),

  fabButtonStyle: {
    backgroundColor: primaryColor,
    color: "white",
  },
  NoDispatchAvailableMessage: {
    fontSize: "1.2em",
    marginTop: "30vh",
    alignItems: "center",
    color: bottomNavigationFontColor,
  },
  // dispatchSubHeader : {
  //   width:
  // }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: 15,
      fontFamily: 'Poppins',
      fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13.6)
    },
  },
}
class Dispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showDispatch: "all",
      plantList: [],
      plantViewList: [],
      plantDropdownOpen: false
    };
    this.divRef = React.createRef()
  }
  handleDispatchPush = () => {
    this.props.history.push("/dispatch/create");
  };
  handleSelectChip = (key) => {
    this.props.handleDispatchSelectChip(key)
    this.props.fetchAllLA(key)
    this.divRef?.current?.scrollTo(0, 0)
    // if (key !== this.props.showDispatch) {
    this.setState({
      plantList: [],
      plantViewList: [],
    })
    // }
  };

  UNSAFE_componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      if (String(localStorage.getItem("returnUrl")) === '/dispatch' && String(localStorage.getItem("currentUrl")) === '/dispatch') {
        // window?.confirm("called Dispatch file if block also: " + String(localStorage.getItem("returnUrl")))
        this.props.history.push("/home");
      }
      else {
        // window?.confirm("called Dispatch file else block also: " + String(localStorage.getItem("returnUrl")))
        this.props.history.push(String(localStorage.getItem("returnUrl")));
      }
    });
  }

  componentDidMount() {
    if (this.props?.showDispatch) {
      this.props.fetchAllLA(this.props?.showDispatch)
    }
    // this.props.fetchAllLA("all")
  }

  componentDidUpdate() {
    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = window.location.pathname;
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
  }

  // loadOldUrl = (e) => {
  //   e.preventDefault()
  //   this.props.history.push(String(localStorage.getItem("returnUrl")))
  // }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      plantList: typeof value === 'string' ? value.split(',') : value,
    }, () => {
      let plantViewList = []
      this.state.plantList.map(d =>
        plantViewList.push((this.props?.allPlantList?.find(item => item.plant_short_name === d))?.plant_code)
      )
      this.setState({
        plantViewList: plantViewList
      }, () => this.props.fetchAllLA("ongoing", this.state.plantViewList))
    });
  };

  onScroll = (listFilter, nextURL) => {
    const { scrollTop, scrollHeight, clientHeight, offsetHeight } = this.divRef.current;
    // console.log(nextURL, scrollHeight - scrollTop - 350 < offsetHeight)
    const reachedBottom = () => scrollHeight - scrollTop - 350 < offsetHeight;
    const loadMoreItems = () => {
      if (nextURL) {
        this.props.fetchNextLA(listFilter, nextURL, this.state.plantViewList)
      }
    };
    if ((!this.state.loadingMoreItems && reachedBottom()) || !nextURL) {
      this.setState({
        loadingMoreItems: true
      })
      loadMoreItems();
    }
    if (this.state.loadingMoreItems) {
      setTimeout(() => {
        this.setState({
          loadingMoreItems: false
        })
      }, 600);
    }
  };


  render() {
    const { dispatch, plantList, plantViewList } = this.state;
    const {
      classes,
      loadingAdviceList,
      loadingAdviceLoader,
      isMobile,
      nextURL,
      LALazyLoader,
      singleLALoader,
      allPlantList,
      showDispatch
    } = this.props;
    // console.log(this.props.loadingAdviceList)
    return (
      <div className={classes.ordersMainBody}
        style={{
          justifyContent: !isMobile && "flex-start",
        }}>
        {
          // dispatchLoader &&
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={singleLALoader}
          // onClick={() => this.props.handleCloseBackDrop()}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        <div className={classes.ordersNavigationContainer}
          style={{
            paddingTop: !isMobile && 0
            // !isMobile && showDispatch === "ongoing"
            //   ? 130
            //   : !isMobile && showDispatch === "complete" && 80,
          }}>
          <div className={classes.ordersNavigationStyle}>
            <Button
              className={classes.buttonStyle}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              My Loading Advices
            </Button>
            {/* {myOrders ? <div className={classes.myOrderCircleStyle} /> : null} */}

          </div>
        </div>
        <div className={classes.dispatchNavContainer}>
          {/* <Chip
              onClick={(e) => {
                e.preventDefault();
                this.handleSelectChip("all");
              }}
              label="All"
              style={{
                width: "20%",
                cursor: "pointer",
                border: `2px solid ${secondaryColor}`,
                backgroundColor:
                  showDispatch === "all" ? secondaryColor : "#fff",
                color: showDispatch === "all" ? "white" : "black",
                fontWeight: showDispatch === "all" ? "bold" : "normal",
                fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(12)
              }}
            /> */}
          <Chip
            onClick={(e) => {
              e.preventDefault();
              this.handleSelectChip("new");
            }}
            label="New"
            style={{
              width: "20%",
              // marginLeft: "3%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
              backgroundColor:
                showDispatch === "new" ? secondaryColor : "#fff",
              color: showDispatch === "new" ? "white" : "black",
              fontWeight: showDispatch === "new" ? "bold" : "normal",
              fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(12)
            }}
          />
          <Chip
            onClick={(e) => {
              e.preventDefault();
              this.handleSelectChip("ongoing");
            }}
            label="In Plant"
            style={{
              width: "25%",
              marginLeft: "3%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
              backgroundColor:
                showDispatch === "ongoing" ? secondaryColor : "#fff",
              color: showDispatch === "ongoing" ? "white" : "black",
              fontWeight: showDispatch === "ongoing" ? "bold" : "normal",
              fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(12)
            }}
          />
          <Chip
            onClick={(e) => {
              e.preventDefault();
              this.handleSelectChip("complete");
            }}
            label="Completed"
            style={{
              width: "30%",
              marginLeft: "3%",
              cursor: "pointer",
              border: `2px solid ${secondaryColor}`,
              backgroundColor:
                showDispatch === "complete" ? secondaryColor : "#fff",
              color: showDispatch === "complete" ? "white" : "black",
              fontWeight: showDispatch === "complete" ? "bold" : "normal",
              fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(12)
            }}
          />
        </div>
        {showDispatch === "complete" &&
          <div className={classes.dispatchNavContainer} style={{ marginTop: "5px", width: '90%' }}>
            <span style={{ fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 ? pxToEm(13.6) : "0.9em" }}>
              Showing loading advices completed in the last 14 days
            </span>
          </div>
        }
        {showDispatch === "ongoing" &&
          <FormControl sx={{ m: 1, width: '90%' }} size="small">
            <InputLabel id="demo-multiple-checkbox-label" style={{ fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13.6) }}>
              Select Plant
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={plantList}
              onChange={this.handleChange}
              input={
                <OutlinedInput
                  label="Select Plant"
                  style={{
                    borderRadius: 25,
                    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13.6)
                  }}
                  onFocusCapture={(e) => {
                    e?.relatedTarget !== null
                      ? this.setState({ plantDropdownOpen: true })
                      : this.setState({ plantDropdownOpen: false })
                  }}
                />
              }
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              <MenuItem
                key={'all'}
                value={'all'}
                disabled={true}
                style={{
                  fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13.6)
                }}
              >
                <Checkbox checked={plantList?.length === 0 ? true : false} />
                <ListItemText primary={'All'} />
              </MenuItem>
              {allPlantList?.length > 0
                ? allPlantList.map((item) => (
                  <MenuItem
                    key={item.plant_short_name}
                    value={item.plant_short_name}
                    style={{ fontFamily: 'Poppins' }}
                  >
                    <Checkbox checked={plantList.indexOf(item.plant_short_name) > -1} />
                    <ListItemText primary={item.plant_short_name} style={{ fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13.6) }} />
                  </MenuItem>
                ))
                :
                <CircularProgress color="inherit" />
              }
            </Select>
          </FormControl>
        }
        <SwipeComponent
          style={{ width: "100%" }}
          rightScreen={() => {
            if (showDispatch === "new")
              return this.handleSelectChip("ongoing")
            else if (showDispatch === "ongoing") {
              return (this.state.plantDropdownOpen === true
                ? ''
                : this.handleSelectChip("complete")
              )
            }
            else return ''
          }}
          leftScreen={() => {
            if (showDispatch === "new")
              return ''
            else if (showDispatch === "ongoing") {
              return (this.state.plantDropdownOpen === true
                ? ''
                : this.handleSelectChip("new")
              )
            }
            else return this.handleSelectChip("ongoing")
          }}
        >
          {
            loadingAdviceLoader ?
              <div style={styles.orderListContainer(isMobile)}>
                <div style={{
                  // marginTop: "27.5vh",
                }}>
                  {/* <SpinLoader /> */}
                  <TileSkeleton skeletonType='dispatch' />
                </div>
              </div>
              : loadingAdviceList?.length === 0 ?
                <div style={styles.orderListContainer(isMobile)}>
                  <div className={classes.NoDispatchAvailableMessage}>
                    <span>
                      No Loading Advices available
                    </span>
                  </div>
                </div>

                :
                <div
                  style={styles.orderListContainer(isMobile, showDispatch)}
                  // style={{ paddingBottom: isMobile ? showDispatch === "ongoing" ? 98 : 70 : 'inherit' }}
                  onScroll={() => {
                    // if (lazyLoad)
                    this.onScroll(showDispatch, nextURL)
                  }
                  }
                  ref={this.divRef}
                >
                  {
                    loadingAdviceList?.length > 0 &&
                    loadingAdviceList.map((data) => (
                      <DispatchLAList
                        key={data.id}
                        status={data.dispatch_status}
                        laNumber={data.la_number}
                        itemCategory={data.item_category}
                        quantity={data.quantity}
                        // plant={data.plant.plant_short_name}
                        plant={data.plant?.plant_short_name}
                        truckNumber={data.truck_number}
                        createdDate={data.created_at}
                        loadingAdvice={data}
                        module="dispatchList"
                        fetchLADetails={this.props.fetchLADetails}
                      />
                    ))}
                  <div style={{ height: "14vh", marginBottom: "20px", }}>
                    {
                      // LALazyLoader && <CircularProgressLoader />
                      LALazyLoader && <TileSkeleton skeletonType='dispatch' />
                    }

                  </div>
                </div>

          }
          <div style={styles.addDispatchFaviconContainer(this.props.isMobile)}>
            <Tooltip title="Create Loading Advice" placement="top" arrow>
              <Fab
                aria-label="Add"
                className={classes.fabButtonStyle}
                onClick={(e) => {
                  e.preventDefault();
                  // console.log("clicked button!!!")
                  this.handleDispatchPush();
                }}
                style={{
                  display: this.props?.user?.customer_type === 'DEALER' && 'none'
                }}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
        </SwipeComponent>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Dispatch));
