import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router";
import COLORS from "../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Check from "@material-ui/icons/Check";
import WeighingStepper from "./WeighingStepper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { pxToEm } from '../../../methods';


const styles = {
  breadcrumbsStyle: {
    color: COLORS.secondaryBlueText,
    cursor: "pointer",
    margin: "10px 0px 0px 10px",
  },
  formControl: {
    margin: 5,
    width: "100%",
  },
  helperText: {
    position: 'absolute',
    top: pxToEm(50)
  },
};

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

class Details extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.laDetailsInDispatchCounter.loading_advice_items.map(
    //   (data) => data
    // ))
    this.state = {
      activeStep: 0,
      laItemDetails: [],
      selectedItemId: null,
      itemQuantity: this.props.laDetailsInDispatchCounter.loading_advice_items.map(
        (data) => data?.alloted_weight
      ),
      allSelectedItem: [],
      allotedWeight: 0.0,
      weightToAllot: 0.0,
      actualWeightToAllot: 0.0,
      actualSectionCount: 0.0,
      actualSectionWeight: 0.0,
      calculatedSectionCount: 0.0,
      markAsPackedLoader: false,
      maxInputAllowed: 0.0,
      minInputAllowed: 0.0,
      itemQuantityValidation: [],
      itemQuantityInputError: false,
      itemQuantityErrorMsg: "Please check quantity",
      selectedItem: null,
      dcId: props.location.state?.dcId,
      weightConsidered: [],
      laRemark: null,
      bundleToleranceErr: []
    };
  }

  componentDidMount() {
    const weightConsidered = this.props.laDetailsInDispatchCounter?.weigh_slips?.slice(1, this.props.laDetailsInDispatchCounter?.weigh_slips?.length)
    const totalWeights =
      weightConsidered?.length;
    this.setState({
      laItemDetails:
        this.props.laDetailsInDispatchCounter &&
          this.props.laDetailsInDispatchCounter?.loading_advice_items[0]?.alloted_weight === "0.000" ?
          this.props.laDetailsInDispatchCounter?.loading_advice_weighing_items :
          this.props.laDetailsInDispatchCounter?.loading_advice_items?.map(
            (data) => data
          ),
      allotedWeight: 0.0,
      laRemark: this.props.laDetailsInDispatchCounter?.remark,
      weightToAllot: 0.0,
      weightConsidered
    }, () => {
      const totalAllotedWeight = this.state.laItemDetails?.reduce((total, w) => total + Number(w?.alloted_weight), 0)
      this.setState({
        allotedWeight: totalAllotedWeight,
        actualWeightToAllot: totalAllotedWeight
      })
    });
  }

  decimalCount = (num) => {
    // Convert to String
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
  };

  isValid = (num) => {
    return (
      this.decimalCount(num) < 3 ||
      (this.decimalCount(num) === 3 &&
        (Number(num.toString().substr(-1, 1)) === 5 ||
          Number(num.toString().substr(-1, 1)) === 0))
    );
  };

  handleLARemarkChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value === "" ? null : value,
    });
  }

  handleSelectItem = (id) => {
    const { laDetailsInDispatchCounter } = this.props;
    let selected = this.state.laItemDetails.find((o) => o.id === id);
    // let max =
    //   Number(selected.item_qty) +
    //   Number(laDetailsInDispatchCounter?.item_category.max_tolerance_value);
    // let min =
    //   Number(selected.item_qty) -
    //   Number(laDetailsInDispatchCounter?.item_category.min_tolerance_value);

    this.setState({
      selectedItem: selected,
      selectedItemId: id,
      // maxInputAllowed: max,
      // minInputAllowed: min,
    });
  };
  // handleTextChange = (event) => {
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   });
  // };

  handleTextChange = (index, data) => {
    const currentItemQuatity = [...this.state.laItemDetails];
    currentItemQuatity[index].alloted_weight = data;
    this.setState({
      laItemDetails: currentItemQuatity
    }, () => {
      const totalAllotedWeight = this.state.laItemDetails?.reduce((total, w) => total + Number(w?.alloted_weight), 0)
      const weightConsidered = this.state.actualWeightToAllot - totalAllotedWeight

      this.setState({
        allotedWeight: totalAllotedWeight,
        weightToAllot: weightConsidered
      })
    })
  }
  handleSaveItem = () => {
    let index = this.state.laItemDetails.findIndex(
      (data) => data.id === this.state.selectedItemId
    );

    this.state.laItemDetails.splice(index, 1);
    this.setState({ laItemDetails: this.state.laItemDetails }, () => {
      Object.assign(this.state.selectedItem, {
        allotWeight: this.state.itemQuantity,
        actualSectionCount: this.state.actualSectionCount,
        actualSectionWeight:
          parseFloat(this.state.itemQuantity) /
          parseFloat(this.state.actualSectionCount),

        calculatedSectionCount:
          parseFloat(this.state.selectedItem.item_qty) /
          parseFloat(this.state.selectedItem.item.section.section_weight),
      });
      this.state.allSelectedItem.push(this.state.selectedItem);

      this.setState({ allSelectedItem: this.state.allSelectedItem }, () => {
        this.setState({
          itemQuantity: 0,
          selectedItem: null,
          actualSectionCount: 0.0,
          actualSectionWeight: 0.0,
        });
        let weight = [];
        this.state.allSelectedItem.map((data) =>
          weight.push(parseFloat(data.allotWeight))
        );

        this.setState((prevState) => ({
          allotedWeight:
            Number(Number(prevState.allotedWeight).toFixed(3)) +
            weight[weight.length - 1],
          weightToAllot:
            Number(Number(prevState.weightToAllot).toFixed(3)) -
            weight[weight.length - 1],
        }));
      });
    });
  };
  handleRemoveItem = (data, index) => {
    this.state.allSelectedItem.splice(index, 1);
    this.state.laItemDetails.push(data);
    this.setState({
      allSelectedItem: this.state.allSelectedItem,
    });

    this.setState((prevState) => ({
      allotedWeight:
        Number(Number(prevState.allotedWeight).toFixed(3)) -
        Number(Number(data.allotWeight).toFixed(3)),
      weightToAllot:
        Number(Number(prevState.weightToAllot).toFixed(3)) +
        Number(Number(data.allotWeight).toFixed(3)),
    }));
  };
  handleMarkAsPacked = () => {
    this.setState({ markAsPackedLoader: true }, () => {
      // var allotment = [];

      var allotment = this.state.laItemDetails.map((l) => ({
        item: l.item?.id,
        section_name: l.item.section.section_name,
        alloted_weight: this.props?.laDetailsInDispatchCounter?.item_category?.is_consider_packing ?
          Number(l?.actual_section_count) * Number(l?.item?.section?.section_weight).toFixed(3) :
          l.alloted_weight,
        actual_section_count: l.actual_section_count,
        remark: l.remark,
        section_weight: l.item.section.section_weight
      }))

      // this.state.laItemDetails.map(
      //   ({
      //     item: {
      //       id: item,
      //       section: { section_name },
      //     },
      //     actualSectionWeight: section_weight,
      //     actualSectionCount: actual_section_count,
      //     allotWeight: alloted_weight,
      //     remark: remark
      //   }) =>
      //     allotment.push({
      //       item,
      //       section_weight,
      //       section_name,
      //       actual_section_count,
      //       alloted_weight,
      //       remark
      //     })
      // );
      this.props
        .handleUpdateLaWithWeightAllotment(
          allotment,
          this.props.laDetailsInDispatchCounter &&
          this.props.laDetailsInDispatchCounter.la_number, this.state.dcId, this.state.laRemark
        )
        .then((response) => {
          if (response.status === 200) {
            this.props.history.push("details/invoices", {
              dcId: this.state.dcId,
            });
            this.props.handleFetchLoadingAdviceInDispatchCounter(
              this.props.laDetailsInDispatchCounter &&
              this.props.laDetailsInDispatchCounter.la_number
            );
            this.setState({ markAsPackedLoader: false });
          }
        })
        .catch((err) => {
          this.setState({ markAsPackedLoader: false });
        });
    });
  };

  getEstBundleSize = (allotWeight, section_weight) => {
    let val = parseFloat(allotWeight) % parseFloat(section_weight);
    if (val === 0) {
      return parseFloat(allotWeight) / parseFloat(section_weight);
    } if (!Number.isFinite(val)) {
      return 0;
    } else {
      return (
        Math.floor(parseFloat(allotWeight) / parseFloat(section_weight)) +
        "-" +
        Math.ceil(parseFloat(allotWeight) / parseFloat(section_weight))
      );
    }
  };

  getPermittedNumber = (num) => {
    return Number(Number(num).toFixed(3));
  };

  checkBundleCount = (
    toleranceUpto1Mts,
    toleranceUpto5Mts,
    toleranceAbove5Mts, itemQtyForManual, weighbridgeWeight, sectionWeight, packingWeight, name
  ) => (event) => {
    // console.log(toleranceUpto5Mts)
    const { value } = event.target;
    if (name === "actualSectionCount") {
      const dispatchedQty = Number(value) * sectionWeight;
      // console.log(sectionWeight)
      const packingWtInMts = packingWeight * Number(value);
      // console.log("sectionCount", Number(value))
      const adjustedWt = Number(weighbridgeWeight - dispatchedQty - packingWtInMts).toFixed(3);
      const finalTolerance = Number((adjustedWt / weighbridgeWeight) * 100).toFixed(3);
      // console.log(finalTolerance)
      // const itemQtyToCompare = itemCount?.length > 0 ? itemQtyForManual : (weights.find((w) => w.item === selected[0]?.item))?.item_qty;
      const itemQtyToCompare = itemQtyForManual;
      // console.log(Number(itemQtyToCompare))
      // console.log("packingwetin MTS", packingWtInMts)
      // console.log("adjusted weight", adjustedWt)
      // console.log("dispatchedQty", dispatchedQty)
      if (1.000 >= itemQtyToCompare && itemQtyToCompare > 0) {
        if (finalTolerance > toleranceUpto1Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (1.001 <= itemQtyToCompare && itemQtyToCompare <= 5) {
        if (finalTolerance > toleranceUpto5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (itemQtyToCompare > 5) {
        if (finalTolerance > toleranceAbove5Mts) {
          return { error: true, errorMessage: 'bundle tolerance error' }
        }
      }
      if (finalTolerance < 0) {
        return { error: true, errorMessage: 'bundle tolerance error' }
      }
    }
  }

  handleCheckBundleCount = (
    toleranceUpto1Mts,
    toleranceUpto5Mts,
    toleranceAbove5Mts, itemQtyForManual, weighbridgeWeight, sectionWeight, packingWeight, name, value
  ) => {

    if (name === "actualSectionCount") {
      const dispatchedQty = Number(value) * sectionWeight;
      const packingWtInMts = packingWeight * Number(value);
      const adjustedWt = Number(weighbridgeWeight - dispatchedQty - packingWtInMts).toFixed(3);
      const finalTolerance = Number((adjustedWt / weighbridgeWeight) * 100).toFixed(3);
      const itemQtyToCompare = itemQtyForManual;
      if (1.000 >= itemQtyToCompare && itemQtyToCompare > 0) {
        if (finalTolerance > toleranceUpto1Mts) {
          return { error: true, errorMessage: 'error1' }
        }
      }
      if (1.001 <= itemQtyToCompare && itemQtyToCompare <= 5) {
        if (finalTolerance > toleranceUpto5Mts) {
          return { error: true, errorMessage: 'error2' }
        }
      }
      if (itemQtyToCompare > 5) {
        if (finalTolerance > toleranceAbove5Mts) {
          return { error: true, errorMessage: 'error 3' }
        }
      }
      if (finalTolerance < 0) {
        return { error: true, errorMessage: 'bundle error' }
      }
    }
  }

  render() {
    const { classes, laDetailsInDispatchCounter, plantName, dcName } =
      this.props;
    const {
      activeStep,
      steps,
      laItemDetails,
      selectedItemId,
      actualSectionCount,
      itemQuantity,
      weightConsidered
    } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          className={classes.breadcrumbsStyle}
        >
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/dispatchOperation");
            }}
          >
            Dispatch Operations
          </Link>
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Dispatch Counter
          </Link>
          <Link color="inherit">Details</Link>
        </Breadcrumbs>
        <div style={{ margin: "12px 8px" }}>
          {plantName} - {dcName}
        </div>
        <Paper
          elevation={1}
          style={{
            width: "96%",
            display: "flex",
            flexDirection: "column",
            height: "auto",
            marginBottom:
              "25px",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
          }}
        >
          <div style={{ height: "34%", display: "flex" }}>
            <div style={{ width: "60%", padding: "18px 28px 0px" }}>
              <span style={{ fontSize: "2em" }}>
                {this.props.laDetailsInDispatchCounter &&
                  this.props.laDetailsInDispatchCounter?.la_number}
              </span>
              <div>
                <WeighingStepper
                  weigh_slips={
                    weightConsidered
                  }
                  tareWeight={
                    this.props.laDetailsInDispatchCounter &&
                    this.props.laDetailsInDispatchCounter?.tare_weight
                  }
                />
              </div>
            </div>
            <div style={{ width: "50%", paddingTop: "6px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color: COLORS.grey,
                }}
              >
                <span>LA QUANTITY</span>
                <span>
                  {this.props.laDetailsInDispatchCounter &&
                    Number(
                      this.props.laDetailsInDispatchCounter?.la_quantity
                    )}{" "}
                  MT
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color: COLORS.grey,
                }}
              >
                <span>ALLOTED WEIGHT</span>
                <span>{Number(this.state.allotedWeight).toFixed(3)} MT</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color:
                    Number(Number(this.state.weightToAllot).toFixed(3)) > 0
                      ? COLORS.orange
                      : "rgb(205, 220, 57)",
                }}
              >
                <span>WEIGHT TO ALLOT</span>
                <span>{Number(this.state.weightToAllot).toFixed(3)} MT</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  margin: "10px 0px",
                  color:
                    COLORS.grey,
                }}
              >
                <span>LOADING ADVICE REMARKS</span>
                <span>
                  <TextField
                    name="laRemark"
                    label="LA REMARK"
                    className={classes.formControl}
                    onChange={this.handleLARemarkChange}
                    rows="3"
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 200 }}
                    variant="outlined"
                    size="small"
                    value={this.state.laRemark}
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
              fontWeight: "bold",
              background: "rgba(163, 166, 180, 0.44)",
              color: COLORS.grey,
              height: "7vh",
              alignItems: "center",
              margin: "16px 16px 0px",
            }}
          >
            <span
              style={{
                width: "15%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              ITEM
            </span>
            <span
              style={{
                width: "15%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              LA QUANTITY
            </span>
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              SECTION DETAIL
            </span>
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "right",
              }}
            >
              BUNDLE COUNT
            </span>
            {this.props?.laDetailsInDispatchCounter?.item_category?.is_consider_packing &&
              <span
                style={{
                  width: "20%",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                BUNDLED WEIGHT
              </span>
            }
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "right",
              }}
            >
              ALLOT WEIGHT
            </span>
            <span
              style={{
                width: "20%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "right",
              }}
            >
              ITEM REMARKS
            </span>
            <span
              style={{
                width: "10%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            ></span>
          </div>
          {/* {console.log(this.state.laItemDetails)} */}
          {this.state.laItemDetails.map((data, index) => (
            <div
              id="test upper"
              style={{
                margin: "16px 16px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "15%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {data.item.item_name}
              </div>
              <div
                style={{
                  width: "12%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Number(data.item_qty)}
              </div>
              <div
                style={{
                  width: "23%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  padding: "0px 12px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <CssTextField
                    size="small"
                    style={{ marginLeft: "25px" }}
                    id="outlined-name"
                    // label="Section info"
                    margin="normal"
                    variant="outlined"
                    multiline
                    maxRows={Infinity}
                    removeAllotedItem
                    helperText={`Section weight ${Number(data?.item?.section?.section_weight).toFixed(
                      3
                    ) +
                      " " +
                      "MT/bundle"
                      } `}
                    // FormHelperTextProps={{ className: classes.helperText }}
                    value={
                      "Est " +
                      this.getEstBundleSize(
                        data?.alloted_weight,
                        data?.item?.section?.section_weight
                      ) +
                      " bundles"
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  padding: "0px 12px 12px 0px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    type="number"
                    id="outlined-dense"
                    style={{ width: "80%", marginLeft: "13px" }}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    error={Number(data?.actual_section_count) < 0 || this.state.bundleToleranceErr[index]?.error}
                    onChange={(e) => {
                      const currentItemQuatity = [...this.state.laItemDetails];
                      currentItemQuatity[index].actual_section_count = e.target.value;
                      this.setState({
                        laItemDetails: currentItemQuatity
                      })

                      if (this.props?.laDetailsInDispatchCounter?.item_category?.is_consider_packing) {
                        const resultOfBundle = this.checkBundleCount(data?.item?.tolerance_upto_1mts,
                          data?.item?.tolerance_from_1to5mts,
                          data?.item?.tolerance_above_5mts, data?.item_qty,
                          data?.alloted_weight, data?.item?.section?.section_weight,
                          data?.item?.packing_weight, "actualSectionCount")(e);
                        let errorBundle = [...this.state.bundleToleranceErr]
                        errorBundle[index] = resultOfBundle;
                        this.setState({
                          bundleToleranceErr: errorBundle
                        })
                      }

                    }}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    value={data?.actual_section_count}
                    helperText={
                      Number(data?.actual_section_count) < 0 ? "Invalid Bundle Count" :
                        this.state.bundleToleranceErr[index]?.error ? this.state.bundleToleranceErr[index]?.errorMessage :
                          Number(data?.actual_section_count) > 0 ? `Actual bundle weight ${(Number(data?.alloted_weight) / Number(data?.actual_section_count))?.toFixed(3)} MT`
                            : 'Actual bundle weight 0.000 MT'
                    }
                    // FormHelperTextProps={{
                    //   style: {
                    //     marginLeft: "0px",
                    //     marginRight: "0px",
                    //     marginTop: "1px",
                    //   },
                    // }}
                    FormHelperTextProps={{ className: classes.helperText }}
                  />
                </div>
              </div>
              {this?.props?.laDetailsInDispatchCounter?.item_category?.is_consider_packing &&
                <div
                  style={{
                    width: "20%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "0px 12px",
                    //marginLeft: "20px"
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <TextField
                      type="number"
                      size="small"
                      style={{ width: "80%", pointerEvents: "none" }}
                      id="outlined-name"
                      //label="Bundled Weight"
                      variant="outlined"
                      name="itemQuantity"
                      value={Number(data?.actual_section_count) * Number(data?.item?.section?.section_weight).toFixed(3)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">MT</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              }
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px 12px",
                  //marginLeft: "20px"
                }}
              >
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <TextField
                    //disabled={this.state.selectedItem === null}
                    error={this.state.itemQuantityValidation[index]?.itemQuantityInputError}
                    size="small"
                    style={{ width: "80%" }}
                    id="outlined-name"
                    label="Quantity"
                    //margin="normal"
                    variant="outlined"
                    name="itemQuantity"
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9\.]/g, "");
                      const laQty = Number(data?.item_qty);
                      const minTolerance = laQty - Number(this.props?.laDetailsInDispatchCounter?.item_category?.min_tolerance_value);
                      const maxTolerance = laQty + Number(this.props?.laDetailsInDispatchCounter?.item_category?.max_tolerance_value);
                      const newVal = Number(value);
                      if (
                        Number(value) <= 0.0
                      ) {
                        const validations = [...this.state.itemQuantityValidation];
                        validations[index] = { itemQuantityInputError: true, itemQuantityErrorMsg: "Please check quantity" }
                        this.setState({ itemQuantityValidation: validations });
                      }
                      else if (!this.isValid(Number(value))) {
                        const validations = [...this.state.itemQuantityValidation];
                        validations[index] = { itemQuantityInputError: true, itemQuantityErrorMsg: "Please check quantity" }
                        this.setState({ itemQuantityValidation: validations });
                      } else if (
                        (Number(value) < minTolerance || Number(value) > maxTolerance)
                      ) {
                        const validations = [...this.state.itemQuantityValidation];
                        validations[index] = { itemQuantityInputError: true, itemQuantityErrorMsg: "Quantity is not within tolerance" }
                        this.setState({ itemQuantityValidation: validations });

                      } else {
                        const validations = [...this.state.itemQuantityValidation];
                        validations[index] = { itemQuantityInputError: false, itemQuantityErrorMsg: "" }
                        this.setState({ itemQuantityValidation: validations });
                      }
                      // this.handleTextChange({
                      //   target: { name: "itemQuantity", value },
                      // });

                      this.handleTextChange(index, value);

                      if (this.props?.laDetailsInDispatchCounter?.item_category?.is_consider_packing) {
                        const resultOfBundle = this.handleCheckBundleCount(data?.item?.tolerance_upto_1mts,
                          data?.item?.tolerance_from_1to5mts,
                          data?.item?.tolerance_above_5mts, data?.item_qty,
                          data?.alloted_weight, data?.item?.section?.section_weight,
                          data?.item?.packing_weight, "actualSectionCount", data?.actual_section_count);
                        console.log("firsterr", resultOfBundle)
                        let errorBundle = [...this.state.bundleToleranceErr]
                        errorBundle[index] = resultOfBundle;
                        this.setState({
                          bundleToleranceErr: errorBundle
                        })
                      }

                    }}
                    // onBlur={(e) => {
                    //   this.setState({
                    //     itemQuantityInputError: false,
                    //   });
                    // }}
                    onFocus={(e) => {
                      if (e.target.value === 0) {
                        this.setState({ itemQuantity: "" });
                      }
                    }}
                    // FormHelperTextProps={{
                    //   style: { marginLeft: "0px", marginRight: "0px" },
                    // }}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    helperText={
                      <span style={{ color: "red" }}>
                        {this.state.itemQuantityValidation[index]?.itemQuantityErrorMsg
                          ? this.state.itemQuantityValidation[index]?.itemQuantityErrorMsg
                          : "."}
                      </span>
                    }
                    value={data?.alloted_weight}
                    // value={this.getPermittedNumber(
                    //   data?.alloted_weight
                    // )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">MT</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px 30px 0px 0px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    name="itemRemark"
                    label="Item remark"
                    className={classes.formControl}
                    style={{ pointerEvents: "none" }}
                    multiline
                    margin="normal"
                    size="small"
                    variant="outlined"
                    value={
                      data.remark}
                  />
                </div>
              </div>
              {/* <div
                style={{
                  width: "10%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  aria-label="Delete"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleRemoveItem(data, index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div> */}
            </div>
          ))}

          {/* {this.props.laDetailsInDispatchCounter &&
            this.state.laItemDetails.length === 0 ? null : (
            <div
              id="test"
              style={{
                margin: "16px 16px 0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                size="small"
                style={{ width: "15%" }}
                variant="outlined"
              >
                <InputLabel
                  id="label-id"
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Item
                </InputLabel>
                <Select
                  value={
                    this.state.selectedItem &&
                    this.state.selectedItem?.item?.item_name
                  }
                  input={
                    <OutlinedInput
                      label="Item"
                      labelId="label-id"
                      name="age"
                      id="outlined-age-simple"
                      InputLabelProps={{
                        style: { fontSize: "0.9em" },
                      }}
                    />
                  }
                >
                  {this.state.laItemDetails &&
                    this.state.laItemDetails.map((data, index) => (
                      <MenuItem
                        value={data.item.item_name}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleSelectItem(data.id);
                        }}
                      >
                        {data.item.item_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div
                style={{
                  width: "12%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {this.state.selectedItem
                  ? Number(this.state.selectedItem.item_qty)
                  : null}
              </div>

              <div
                style={{
                  width: "23%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0px 12px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    color: COLORS.secondaryBlueText,
                  }}
                >
                  {this.state.selectedItem ? (
                    <CssTextField
                      size="small"
                      style={{ marginLeft: "25px" }}
                      id="outlined-name"
                      // label="Section info"
                      margin="normal"
                      variant="outlined"
                      multiline
                      maxRows={Infinity}
                      removeAllotedItem
                      name="actualSectionCount"
                      helperText={`Section weight ${parseFloat(
                        laItemDetails.find((e) => e.id === selectedItemId)?.item
                          .section?.section_weight
                      )} MT/bundle`}
                      // FormHelperTextProps={{
                      //   style: {
                      //     float: "left",
                      //   },
                      // }}
                      value={
                        itemQuantity
                          ? "Est " +
                          this.getEstBundleSize(
                            itemQuantity,
                            laItemDetails.find((e) => e.id === selectedItemId)
                              ?.item.section?.section_weight
                          ) +
                          " bundles"
                          : "Est 0 bundles"
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  disabled={!Boolean(selectedItemId)}
                  size="small"
                  style={{ width: "80%", marginLeft: "25px" }}
                  id="outlined-name"
                  label="No of bundle*"
                  margin="normal"
                  variant="outlined"
                  name="actualSectionCount"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    this.handleTextChange({
                      target: { name: "actualSectionCount", value },
                    });
                  }}
                  helperText={`Actual Bundle Weight ${Number(actualSectionCount) === 0
                    ? 0
                    : (
                      parseFloat(itemQuantity) /
                      parseFloat(Number(actualSectionCount))
                    ).toFixed(3)
                    } `}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  value={actualSectionCount}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">MT</InputAdornment>
                //   ),
                // }}
                />
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  disabled={this.state.selectedItem === null}
                  error={this.state.itemQuantityInputError}
                  size="small"
                  style={{ width: "80%" }}
                  id="outlined-name"
                  label="Quantity"
                  margin="normal"
                  variant="outlined"
                  name="itemQuantity"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9\.]/g, "");
                    const laQty = Number(Number(this.state.selectedItem?.item_qty).toFixed(0));
                    const minTolerance = laQty - Number(this.props?.laDetailsInDispatchCounter?.item_category?.min_tolerance_value);
                    const maxTolerance = laQty + Number(this.props?.laDetailsInDispatchCounter?.item_category?.max_tolerance_value);
                    const newVal = Number(value);
                    if (
                      Number(value) >
                      Number(this.state.weightToAllot).toFixed(3) ||
                      Number(value) <= 0.0
                    ) {
                      this.setState({ itemQuantityInputError: true, itemQuantityErrorMsg: "Please check quantity" });
                    } else if (!this.isValid(Number(value))) {
                      this.setState({ itemQuantityInputError: true, itemQuantityErrorMsg: "Please check quantity" });
                    } else if (
                      (newVal < minTolerance || newVal > maxTolerance)
                    ) {
                      this.setState({ itemQuantityInputError: true, itemQuantityErrorMsg: "Quantity is not within tolerance" });

                    } else {
                      this.setState({ itemQuantityInputError: false });
                    }
                    this.handleTextChange({
                      target: { name: "itemQuantity", value },
                    });
                  }}
                  // onBlur={(e) => {
                  //   this.setState({
                  //     itemQuantityInputError: false,
                  //   });
                  // }}
                  onFocus={(e) => {
                    if (e.target.value === 0) {
                      this.setState({ itemQuantity: "" });
                    }
                  }}
                  // FormHelperTextProps={{
                  //   style: { marginLeft: "0px", marginRight: "0px" },
                  // }}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  helperText={
                    <span style={{ color: "red" }}>
                      {this.state.itemQuantityInputError
                        ? this.state.itemQuantityErrorMsg
                        : "."}
                    </span>
                  }
                  value={this.state.itemQuantity}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">MT</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  name="itemRemark"
                  label="Item remark"
                  style={{ pointerEvents: "none" }}
                  className={classes.formControl}
                  multiline
                  margin="normal"
                  size="small"
                  variant="outlined"
                  value={
                    this.state.selectedItem?.remark || ""}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={
                    this.state.itemQuantityInputError ||
                    this.state.selectedItemId === null ||
                    this.state.itemQuantity === 0 ||
                    !Boolean(Number(actualSectionCount)) ||
                    this.state.itemQuantity === ""
                  }
                  style={{
                    marginBottom: "8px", backgroundColor: this.state.itemQuantityInputError ||
                      this.state.selectedItemId === null ||
                      this.state.itemQuantity === 0 ||
                      !Boolean(Number(actualSectionCount)) ||
                      this.state.itemQuantity === "" ? " " : COLORS.successButtonBackground
                  }}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleSaveItem();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )} */}



          {this.props.laDetailsInDispatchCounter &&
            this.state.laItemDetails.length > 0 ? (
            <div
              style={{
                //   marginTop: "10%",
                display: "flex",
                float: "right",
                paddingRight: "38px",
                marginTop: "18px",
                justifyContent: "flex-end",
                marginBottom: "18px",
              }}
            >
              <Button color="primary" style={{ marginRight: "12px" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/dispatchOperation");
                }}
              >
                Cancel
              </Button>
              {this.state.markAsPackedLoader ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleMarkAsPacked();
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor:
                      Number(Number(this.state.weightToAllot).toFixed(3)) > 0 ||
                        Number(Number(this.state.weightToAllot).toFixed(3)) < 0 || this.state.laItemDetails.some(a => Number(a.actual_section_count) < 0) ||
                        this.state.bundleToleranceErr?.some(b => b?.error)
                        ? COLORS.grey
                        : COLORS.successButtonBackground,
                  }}
                  disabled={
                    Number(Number(this.state.weightToAllot).toFixed(3)) > 0 || Number(Number(this.state.weightToAllot).toFixed(3)) < 0 ||
                    this.state.laItemDetails.some(a => Number(a.actual_section_count) < 0) ||
                    this.state.bundleToleranceErr?.some(b => b?.error)
                  }
                >
                  <Check />
                  Next
                </Button>
              )}
            </div>
          ) : null}
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Details));
