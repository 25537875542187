import React, { Component } from "react";
import Stop from "@material-ui/icons/Stop";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import COLORS from "../../../constants/internal/colors";
import Bread from "./../common/Breadcrubs";
import SnackbarComponent from "../../common/SnackbarComponent";
import Paper from "@material-ui/core/Paper";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";

const styles = {
  paperStyle: {
    cursor: "pointer",
    padding: "10px",
    width: "12vw",
    height: "100%",
    textAlign: "center",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 11px 0px, rgba(0, 0, 0, 0.14) 0px 1px 20px 0px, rgba(0, 0, 0, 0.12) 0px 1px 0px 0px",
  },
  cardNameContainer: {
    textAlign: "left",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  gateInTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px",
  },
  gateOutTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px",
  },

  plantNameContainer: {
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  activeStatusContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10px",
  },
  cardContainer: {
    width: "14vw",
    height: "20vh",
    marginTop: "20px",
    background: "white",
    padding: "12px",
    cursor: "pointer",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 0px -1px, rgba(0, 0, 0, 0.01) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
  },
  contentTitle: {
    color: "grey",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "20px",
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    color: COLORS.grey,
  },
  cardContainerDC: {
    width: "14vw",
    height: "20vh",
    marginTop: "20px",
    background: "white",
    padding: "12px",
    cursor: "pointer",
    marginRight: "24px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 0px -1px, rgba(0, 0, 0, 0.01) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
  },
};
class DispatchOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.handleClearLoadingAdvice();
  }
  handleOpenGate = (gatesDetail) => {
    if (gatesDetail?.is_gate_in_allowed || gatesDetail?.is_gate_out_allowed) {
      console.log(gatesDetail.id);
      this.props.history.push({
        pathname: `/dispatchOperation/gate/${gatesDetail?.id}`,
        id: gatesDetail?.id,
      });
    } else {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "GATE IN, GATE OUT NOT ALLOWED",
        snackbarType: "error",
      });
    }
  };
  handleOpenWeighbridge = (weighbridgeDetails) => {
    if (weighbridgeDetails?.is_active) {
      this.props.history.push({
        pathname: `/dispatchOperation/weighbridge/${weighbridgeDetails?.id}`,
        id: weighbridgeDetails?.id,
      });
    } else {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "WEIGHBRIDGE NOT ACTIVE",
        snackbarType: "error",
      });
    }
  };
  handleOpenDispatchCounter = (status, id) => {
    if (status) {
      this.props.history.push(`/dispatchOperation/dc/${id}`);
    } else {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "DISPATCH COUNTER NOT ACTIVE",
        snackbarType: "error",
      });
    }
  };
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  render() {
    const { classes, dispatchCounterDetails, isMobile } = this.props;
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Bread
          data={[
            {
              title: "Dispatch Operations",
              onClick: "",
            },
          ]}
        />
        {this.props.gatesDetail?.length === 0 ? null : (
          <>
            <h4 style={{ color: COLORS.grey }}>Gates</h4>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: isMobile && 'flex-start' }}>
              {this.props.gatesDetail.map((gatesDetail) => (
                <div style={{ margin: "10px", width: isMobile && '45%' }}>
                  <Paper
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleOpenGate(gatesDetail);
                    }}
                    elevation={1}
                    className={classes.paperStyle}
                    style={{ width: isMobile && '95%' }}
                  >
                    <div className={classes.cardNameContainer}>
                      {gatesDetail?.gate_name}
                    </div>
                    <div className={classes.plantNameContainer}>
                      {gatesDetail?.plant}
                    </div>

                    <div style={{ marginTop: "16px" }}>
                      <div className={classes.gateInTextContainer}>
                        <span>Gate In</span>
                        {gatesDetail?.is_gate_in_allowed ? (
                          <CheckCircle
                            style={{ color: COLORS.successButtonBackground }}
                          />
                        ) : (
                          <Cancel style={{ color: COLORS.orange }} />
                        )}
                      </div>
                      <div className={classes.gateOutTextContainer}>
                        <span>Gate Out</span>
                        {gatesDetail?.is_gate_out_allowed ? (
                          <CheckCircle
                            style={{ color: COLORS.successButtonBackground }}
                          />
                        ) : (
                          <Cancel style={{ color: COLORS.orange }} />
                        )}
                      </div>
                    </div>
                  </Paper>
                </div>
              ))}
            </div>
          </>
        )
        }
        {
          this.props.weighbridgeDetails?.length === 0 ? null : (
            <>
              <h4 style={{ color: COLORS.grey }}>Weighbridges</h4>
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: isMobile && 'flex-start' }}>
                {this.props.weighbridgeDetails.map((weighbridgeDetails) => (
                  <div style={{ margin: "10px", width: isMobile && '45%' }}>
                    <Paper
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleOpenWeighbridge(weighbridgeDetails);
                      }}
                      elevation={1}
                      className={classes.paperStyle}
                      style={{ width: isMobile && '95%' }}
                    >
                      <div className={classes.cardNameContainer}>
                        {weighbridgeDetails?.weighbridge_name}
                      </div>
                      <div className={classes.plantNameContainer}>
                        {weighbridgeDetails?.plant}
                      </div>

                      <div style={{ marginTop: "16px" }}>
                        <div className={classes.gateInTextContainer}>
                          <span>Active</span>
                          {weighbridgeDetails?.is_active ? (
                            <CheckCircle
                              style={{ color: COLORS.successButtonBackground }}
                            />
                          ) : (
                            <Cancel style={{ color: COLORS.orange }} />
                          )}
                        </div>
                      </div>
                    </Paper>
                  </div>
                ))}
              </div>
            </>
          )
        }
        {
          dispatchCounterDetails?.length === 0 ? null : (
            <>
              <h4 style={{ color: COLORS.grey }}>Dispatch Counters</h4>
              <div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: isMobile && 'flex-start' }}>
                  {dispatchCounterDetails.map((data) => (
                    <div style={{ margin: "10px", width: isMobile && '45%' }}>
                      <Paper
                        elevation={1}
                        className={classes.paperStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.handleSetPlantName(
                            data.plant,
                            data.dispatch_counter_name
                          );
                          this.handleOpenDispatchCounter(data.is_active, data.id);
                        }}
                        style={{ width: isMobile && '95%' }}
                      >
                        <div className={classes.cardNameContainer}>
                          {data.dispatch_counter_name}
                        </div>
                        <div className={classes.plantNameContainer}>
                          {data.plant}
                        </div>
                        <div style={{ marginTop: "16px" }}>
                          <div className={classes.gateInTextContainer}>
                            <span>Active</span>
                            {data.is_active ? (
                              <CheckCircle
                                style={{ color: COLORS.successButtonBackground }}
                              />
                            ) : (
                              <Cancel style={{ color: COLORS.orange }} />
                            )}
                          </div>
                        </div>
                      </Paper>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )
        }

        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
          customAutoHideDuration={1000}
        />
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(DispatchOperations));
