import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import Create from "../Create";
class EinvoicingCreateWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Layout {...this.props}>
        <Create
          {...this.props}
          actualDistance={this.props?.actualDistance}
          handleActualDistance={this.props.handleActualDistance}
        />
      </Layout>
    );
  }
}
export default EinvoicingCreateWeb;
