import React, { Component } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManageReasonData from "../../../../components/internal/masterManagement/reason/ManageReasonData";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayReasonData from "../../../../components/internal/masterManagement/reason/web";
import { checkApiError } from "../../../../methods";

class ManageReasonContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      reasonOperation: null,
      selectedReason: null,
      selectedReasonData: [],
      selectedReasonDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let reasonOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ reasonOperation });
    if (reasonOperation !== "add") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedReasonData(id);
      this.setState({ selectedReason: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let reasonOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedReason =
      reasonOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (reasonOperation !== state.reasonOperation) {
      updatedData.set("reasonOperation", reasonOperation);
    }
    if (selectedReason !== state.selectedReason) {
      updatedData.set("selectedReason", selectedReason);
    }
    if (updatedData.size > 0) {
      return {
        reasonOperation: updatedData.has("reasonOperation")
          ? updatedData.get("reasonOperation")
          : reasonOperation,
        selectedReason: updatedData.has("selectedReason")
          ? updatedData.get("selectedReason")
          : [],
      };
    }
    return null;
  }
  handleFetchSelectedReasonData = async (selectedReason) => {
    await this.setState({ selectedReasonDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.GET_REASON}${selectedReason}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedReasonData: response.data,
              selectedReasonDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedReasonDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected item nature data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          // this.setState({
          //   selectedReasonDataFetchLoader: false,
          //   openSnackbar: true,
          //   snackbarMessage:
          //     "Something went wrong while fetching selected item nature data try again!",
          //   snackbarType: "error",
          // });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        }
      );
  };

  handleReasonDataAdd = (reasonData) =>
    new Promise((resolve, reject) => {
      // console.log("reasonData : ", reasonData);
      axios
        .post(
          APIROUTES.GET_REASON,
          reasonData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedReasonData(response.data.id);
          this.props.getReasonList();
          resolve(response);
        })
        .catch(reject);
    });
  handleReasonDataUpdate = (reasonData) =>
    new Promise((resolve, reject) => {
      // console.log("reasonData : ", reasonData);
      // console.log("reasonId : ", reasonId);
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      axios
        .patch(
          `${APIROUTES.GET_REASON}${id}/`,
          reasonData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedReasonData(id);
          // this.handleFetchSelectedReasonData(response.data.id);
          this.props.getReasonList();
          resolve(response);
        })
        .catch(reject);
    });
  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  render() {
    const { reasonOperation } = this.state;
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    // if (isAccessibleModule(this.props?.user, "Banks"))
    else {
      return (
        <>
          {reasonOperation === "view" ? (
            <DisplayReasonData {...this.props} {...this.state} />
          ) : (
            <ManageReasonData
              {...this.props}
              {...this.state}
              module={this.props?.modules}
              handleReasonDataUpdate={this.handleReasonDataUpdate}
              handleReasonDataAdd={this.handleReasonDataAdd}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleCloseSnackbar}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManageReasonContainer);
