import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import Edit from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import SpinLoader from "../../../../common/SpinLoader";

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
  Divider,
  CircularProgress,
  Slide,
  ThemeProvider,
  Toolbar
} from "@material-ui/core";
import { grey, secondaryGrey, successButtonBackground, drawerTextColor, primaryColor, whiteSmoke } from "../../../../../constants/internal/colors";
import { withRouter } from "react-router";
import Add from "@material-ui/icons/Add";
import Alert from "@mui/material/Alert";
import { ButtonBase, TableFooter } from "@mui/material";
import SnackbarComponent from "../../../common/SnackbarComponent";
import ToolTipComponent from "../../../common/ToolTipComponent";
import { checkApiError, hasApproveAccess, hasSpecialAccess, hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../../methods";
import SaveButton from "../../../common/SaveButton";
import SubContractsPlantPlanningTable from "./SubContractsPlantPlanningTable";
import { CheckCircleOutline } from "@material-ui/icons";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { createMuiTheme } from "@material-ui/core/styles";
import APIROUTES from "../../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

class ContractsPlantPlaningTable extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

ContractsPlantPlaningTable.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

let counter = 0;
function createData(plant, allotted, dispatched, pending) {
  counter += 1;
  return { id: counter, plant, allotted, dispatched, pending };
}

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    paddingTop: "0px",
  },
  tableContainerStyle: {
    overflowY: "scroll",
  },
  container: {
    maxHeight: "45vh",
  },
  loaderDiv: {
    display: "flex",
    width: "100%",
    height: "calc(100vh - 200px)",
    alignItems: "center",
    justifyContent: "center",
  },
  contractStatusCustomerBox: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "5px 20px 20px 20px",
  },
  contractStatusHeadingBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10
  },
  buyerConsigneeCustomerBoxTitle: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    padding: "12px 0px 12px 15px",

    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  contractStatusContainer: {
    padding: "15px 10px",
    background: whiteSmoke,
    border: "1px solid gainsboro",
    borderRadius: "4px",
    marginBottom: 20
  },
  contractStatusItemTextStyle: {
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    width: "80%",
  },
  contractStatusListStyleValue: {
    color: "rgb(112,112,112)",
    fontSize: pxToEm(14)
  },
  loaderStyle: {
    color: primaryColor
  },
  contractStatusText: {
    color: "rgb(112,112,112)",
    fontWeight: "bold",
    fontSize: pxToEm(15),
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
    fontFamily: 'Poppins',
  },
  tableHead: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    textAlign: "center"
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "10px 5px 10px 5px",
      },
    },
  },
});

class CustomPaginationActionsTable extends React.Component {
  state = {
    rows: this?.props?.orderPlantPlanningData || [],
    prevRows: this?.props?.orderPlantPlanningData || [],
    page: 0,
    allPlantsList: this?.props?.allPlantsList,
    rowsPerPage: 5,
    totalCompletedQty: 0,
    totalPendingQty: 0,
    orderQuantity: 0,
    displayAddNewButton: true,
    currentAllottedQty: 0,
    isEditMode: false,
    isShortCloseMode: false,
    openSnackbar: false,
    snackbarType: "error",
    snackbarMessage: null,
    showLoader: false,
    openDialog: false,
    allotedError: false,
    reasonMaster: [],
    selectedPlantId: 0,
    Short_Close_Reason: '',
    isShortCloseQtyValid: [...Array(10).keys()].map((components, index) => { return 'true' }),
    shortCloseValue: '',
    Transition: React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    }),
    isLoader: false,
    openConfirmDialog: false,
    menuAnchorEl: null,
    subcontractRows: []
  };
  componentDidMount() {
    this.setPlantPlanningDetails();
    this.handleFetchSubcontracts(this.props.orderDetails?.id);
    this.setState({
      totalCompletedQty: this?.props?.totalCompletedQty || 0,
      totalPendingQty: this?.props?.totalPendingQty || 0,
      orderQuantity: this?.props?.orderDetails?.quantity || 0,
    });
    this.setState({ currentAllottedQty: this.state.allotted_qty });
    console.log(this.state.rows)
    this.setState({
      allPlantsList: this?.props?.allPlantsList || [],
    }, () => {
      if (this.props.orderPlantPlanningData?.length === 0) {
        let tempRows = [];
        this.state.allPlantsList?.forEach(plant => {
          let newRow = {
            order: this?.props?.orderDetails?.id,
            plant: plant.plant_id,
            plant_name: plant.plant_name,
            plant_short_name: plant.plant_short_name,
            allotted_qty: "0.000",
            dispached_qty: "0.000",
            cancelled_qty: "0.000",
            pending_qty: "0.000",
            operation: "view"
          };
          tempRows.push(newRow);
        });

        this.setState({ rows: tempRows });
      }
    })
    if (this.props.orderDetails?.order_status === "CONVERSION_ALLOTMENT_HOLD") {
      this.setState({
        isEditMode: true
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.orderPlantPlanningData !== this.props.orderPlantPlanningData
    ) {
      this.setPlantPlanningDetails();
    }
    if (prevProps.allPlantsList !== this.props.allPlantsList) {
      if (prevState.allPlantsList !== this.props.allPlantsList) {
        this.setState({ allPlantsList: this.props.allPlantsList }, () => {
          if (this.props.orderPlantPlanningData?.length === 0) {
            let tempRows = [];
            this.state.allPlantsList?.forEach(plant => {
              let newRow = {
                order: this?.props?.orderDetails?.id,
                plant: plant.plant_id,
                plant_name: plant.plant_name,
                plant_short_name: plant.plant_short_name,
                allotted_qty: "0.000",
                dispached_qty: "0.000",
                cancelled_qty: "0.000",
                pending_qty: "0.000",
                operation: "view"
              };
              tempRows.push(newRow);
            });

            this.setState({ rows: tempRows });
          }
        });
      }
    }
    if (prevProps.totalCompletedQty !== this.props.totalCompletedQty) {
      if (prevState.rows !== this.props.orderPlantPlanningData) {
        this.setState({ totalCompletedQty: this.props.totalCompletedQty });
      }
    }
    if (prevProps.totalPendingQty !== this.props.totalPendingQty) {
      if (prevState.totalCompletedQty !== this.props.totalCompletedQty) {
        this.setState({ totalPendingQty: this.props.totalPendingQty });
      }
    }
    if (prevProps.orderDetails !== this.props.orderDetails) {
      if (prevState.orderQuantity !== this.props.orderDetails?.quantity) {
        this.setState({ orderQuantity: this.props.orderDetails?.quantity });
      }
    }
    return null;
  }


  handleFetchSubcontracts = (order_id) => {
    axios.get(
      `${APIROUTES.GET_SUBCONTRACTS}?order_id=${order_id}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.data
          const result = []
          for (const k of Object.keys(responseData)) {
            if (response.data[k]?.length > 0) {
              const _data = responseData[k]?.filter((_, i) => i != 0)
              _data.forEach((i) => result.push(i))
            }
          }
          console.log(result)
          this.setState({
            subcontractRows: result
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching subcontracts",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.data?.details
            ? error?.data?.details
            : error?.data?.message
              ? error?.data?.message
              : error?.details
                ? error?.details
                : "Error occured while fetching subcontracts",
          snackbarType: "error",
        });
      });
  };

  setPlantPlanningDetails = () => {
    let tempRows = [];
    this.state.allPlantsList?.forEach(plant => {
      let newRow = {
        order: this?.props?.orderDetails?.id,
        id: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.id,
        plant: plant.plant_id,
        plant_name: plant.plant_name,
        plant_short_name: plant.plant_short_name,
        allotted_qty: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.allotted_qty || "0.000",
        dispached_qty: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.dispached_qty || "0.000",
        cancelled_qty: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.cancelled_qty || "0.000",
        pending_qty: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.pending_qty || "0.000",
        sub_contract_enabled: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.sub_contract_enabled || false,
        sub_contract_available_quantity: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.sub_contract_available_quantity || "0.000",
        quantity_with_subcontract: this.props.orderPlantPlanningData.find(p => p.plant === plant.plant_id)?.quantity_with_subcontract || "0.000",
      };
      tempRows.push(newRow);
    });

    const ids = tempRows.filter(t => t.id !== undefined).map(t => t.id)
    const deactivatedPlants = this?.props?.orderPlantPlanningData.filter(t => !ids.includes(t.id))
    deactivatedPlants.forEach(plant => { tempRows.push(plant) })
    this.setState({ rows: tempRows });
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  handleChange = (name) => (event) => {
    const { value } = event.target;
    this.setState({ [name]: value });
  };
  handleEditClick = async (index) => {
    let tempRows = this?.state?.rows;
    tempRows[index].operation = "edit";
    await this.setState({
      rows: tempRows,
    });
  };
  handlePlantPlanningAllottedValueChangeCheck =
    (index, name) => {
      const value = this.state.currentAllottedQty;
      const { rows } = this.state;
      let dispatched = rows[index]["dispached_qty"];
      if (
        parseFloat(value).toFixed(3) > parseFloat(dispatched).toFixed(3) ||
        parseFloat(value).toFixed(3) === parseFloat(dispatched).toFixed(3)
      ) {
        rows[index][name] = value;
        rows[index]["pending_qty"] = value;
      } else {
        rows[index]["allotted_qty"] = dispatched;
        rows[index]["pending_qty"] = dispatched;
      }
      this.setState({ rows });
    };
  handlePlantPlanningDataValueChange = (index, name) => async (event) => {
    const { value } = event.target;
    const { rows } = this.state;
    if (name === "plant_id") {
      rows[index][name] = value;
    } else {
      rows[index][name] = value;
      rows[index]["pending_qty"] = value;
    }
    this.setState({ rows });
  };
  handleSaveUpdatedValue = async (updatedData, index) => {
    if (updatedData.operation === "add") {
      let dataToBeAdded = {
        order: updatedData.order,
        plant: updatedData.plant_id,
        allotted_qty: updatedData.allotted_qty,
        dispached_qty: updatedData.dispached_qty,
        pending_qty: updatedData.pending_qty,
      };
      this.props.handlePlantPlanningData(dataToBeAdded);
    } else if (updatedData.operation === "edit") {
      let dataToBeUpdated = {
        plant: updatedData.plant_id,
        allotted_qty: updatedData.allotted_qty,
      };
      if (updatedData.id) {
        this.props.updatePlantPlanningData(dataToBeUpdated, updatedData.id);
      }
    } else {
      console.log("invalid Operation");
    }
    await this.setState((previousState) => {
      const rows = [...previousState.rows];
      rows[index] = {
        ...rows[index],
        operation: "view",
      };
      return { rows };
    });
  };

  handleCancelData = () => {
    const filteredRows = this.state.rows.filter(r => r.plant !== null);
    this.setState({ rows: filteredRows }, () => console.log(filteredRows))
  }
  itemQtyTotal = (itemData) => {
    let sum =
      itemData.length > 0
        ? itemData
          .map((o) => o.allotted_qty)
          .reduce((a, c) => {
            return Number(a) + Number(c);
          })
        : 0;
    return sum;
  };

  fetchShortCloseReasonMaster = async () => {
    await this.props
      .fetchReasonMaster("Contracts")
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            reasonMaster: response.data
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching reason master",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleDropdownChange = (e) => {
    this.setState({
      Short_Close_Reason: e.target.value
    })
  }

  handleCancelButton = () => {
    this.setState({
      isShortCloseMode: false,
      isEditMode: false,
      openDialog: false,
      Short_Close_Reason: '',
      isShortCloseQtyValid: [...Array(10).keys()].map((components, index) => { return 'true' }),
    }, () => this.setPlantPlanningDetails())
  }

  handleShortCloseButton = () => {
    this.setState((prevState) => ({
      isShortCloseMode: !prevState.isShortCloseMode,
      // showLoader: false
    }), () => {
      !this.state?.isShortCloseMode && this.fetchShortCloseReasonMaster()
      this.setState({
        isEditMode: this.state?.isShortCloseMode && false,
        openDialog: !this.state?.isShortCloseMode && true
      })
    })
  }

  isShortCloseQtyValid = (index, currentValue, initialValue, rowOpenQty) => {
    let isShortCloseQtyValid = [...this.state.isShortCloseQtyValid]
    if ((Number(currentValue)) <= (Number(rowOpenQty?.available_qty))) {
      isShortCloseQtyValid[index] = true
      this.setState({ isShortCloseQtyValid })
    }
    else {
      isShortCloseQtyValid[index] = false
      this.setState({ isShortCloseQtyValid })
    }
  }

  handleShortClose = (rowData, initialData, id) => {
    this.setState({ isLoader: true })
    let updatedRows = []
    for (let i = 0; i < rowData.length; i++) {
      for (let j = 0; j < initialData.length; j++) {
        if (Number(rowData[i].plant) === Number(initialData[j].plant)) {
          if (rowData[i].cancelled_qty !== initialData[j].cancelled_qty) {
            updatedRows.push({
              plant_id: rowData[i].plant,
              cancelled_qty: (Number(rowData[i].cancelled_qty) - Number(initialData[j].cancelled_qty)).toFixed(3)
            })
          }
        }
      }
    }
    const shortCloseData = {
      plants: updatedRows,
      reason: this.state.Short_Close_Reason,
    }
    // console.log(id)
    this.props
      .contractShortClose(shortCloseData, id)
      .then((response) => {
        if (response.status === 200) {
          this.handleOpenSnackbar(
            `Contract Short Close Successfully`,
            "success"
          );
          this.setState({
            openDialog: false,
          }, () => this.setState({ isLoader: false }))
          setTimeout(() => {
            this.props.prepareSelectedOrderDataToDisplay(id)
            this.props.handleFetchPlantPlanningData()
            this.handleCloseSnackbar();
          }, 3000);
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while short close contract try again!`,
            snackbarType: "error",
            isLoader: false
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoader: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleSave = () => {
    let error = null;
    if (this.state.isEditMode) {
      if (this.itemQtyTotal(this.state.rows) >
        parseFloat(this.props.orderDetails.quantity)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `Please fill allotted quantity less than the total contract quantity i.e. ${parseFloat(
            this.props.orderDetails.quantity
          ).toFixed(3)} MT`,
          snackbarType: "error",
        });
        return;
      }
      if (this.itemQtyTotal(this.state.rows) <
        parseFloat(this.props.orderDetails.quantity)) {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `Allotted quantity is less than the total contract quantity i.e. ${parseFloat(
            this.props.orderDetails.quantity
          ).toFixed(3)} MT`,
          snackbarType: "error",
        });
        return;
      }

      this.state.rows.forEach(row => {
        error = this.isAllottedQtyLess(row) ? "Allotted quantity is less than permissible quantity" : null
        if (error !== null) {
          this.setState({
            openSnackbar: true,
            snackbarType: "error",
            snackbarMessage: error
          })
        }
      })
    }
    if (error != null) {
      return;
    }
    this.setState({ showLoader: true })
    if (this.state.isEditMode) {
      let initDataRows = [];
      let patchDataRows = [];
      this.state.rows.forEach(row => {
        let isQtyChangedtoZero = this.state.prevRows.find(p => p.plant === row.plant)?.allotted_qty > 0;
        if (this.props.orderPlantPlanningData?.length === 0) {
          let dataToBeAdded = {
            order: row.order,
            plant: row.plant,
            plant_short_name: row.plant_short_name,
            allotted_qty: parseFloat(row.allotted_qty),
            dispached_qty: parseFloat(row.dispached_qty),
            cancelled_qty: parseFloat(row.cancelled_qty),
            pending_qty: parseFloat(row.allotted_qty), // initial alloted = pending qty
          };
          if (Number(row.allotted_qty) > 0 || isQtyChangedtoZero) {
            initDataRows.push(dataToBeAdded);
          }
        } else {
          // let dataToBeUpdated = {
          //   plant: row.plant,
          //   allotted_qty: row.allotted_qty,
          // };
          let data = {}
          if (Number(row.allotted_qty) > 0 || isQtyChangedtoZero) {
            if (row.id) {
              data = {
                plant: row.plant,
                allotted_qty: parseFloat(row.allotted_qty),
                id: row.id,
                method: row.id ? "UPDATED" : "ADDED",
              }
            } else {
              data = {
                plant: row.plant,
                allotted_qty: parseFloat(row.allotted_qty),
                id: row.id,
                method: row.id ? "UPDATED" : "ADDED",
                order: row.order,
                dispached_qty: parseFloat(row.dispached_qty),
                cancelled_qty: parseFloat(row.cancelled_qty),
                pending_qty: parseFloat(row.allotted_qty),
              }
            }
            patchDataRows.push(data)
          }
        }

      })
      if (initDataRows.length > 0) {
        this.props.handlePlantPlanningData(initDataRows);
      }
      if (patchDataRows.length > 0) {
        this.props.updatePlantPlanningData(patchDataRows);
      }
    }
    this.setState((prevState) => ({
      isEditMode: !prevState.isEditMode,
      showLoader: false
    }))
  }

  handlCheckAllotedError = () => {
    let error = null;
    for (let i = 0; i < this.state.rows.length; i++) {
      error = this.isAllottedQtyLess(this.state.rows[i])
      if (error) {
        this.setState({ allotedError: true })
        break;
      }
      else if (!error) {
        this.setState({ allotedError: false })
      }
    }
  }

  isAllottedQtyLess = (row) => {
    console.log(row)
    const laPendingApproved = parseFloat(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.planned_quantity) || 0;
    const laInPlant = parseFloat(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.in_plant_quantity) || 0;
    const laDispatched = parseFloat(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.dispatched_quantity) || 0;

    if (row.allotted_qty < row.quantity_with_subcontract?.total_used_qty) {
      return true;
    }
    return false;
  }

  comparePlantName = (a, b) => {
    if (a.plant_name < b.plant_name) {
      return -1;
    }
    if (a.plant_name > b.plant_name) {
      return 1;
    }
    return 0;
  }

  handlConfirmCloseDialog = () => {
    this.setState({
      openConfirmDialog: false
    })
  }


  handleConfirmOpenDialog = () => {
    this.setState({
      openConfirmDialog: true
    })
  }

  handleMenuClick = (event) => {
    this.setState({
      menuAnchorEl: event.currentTarget
    })
  };

  handleMenuClose = (e) => {
    this.setState({
      menuAnchorEl: null
    })
  };



  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  handleEnableSubcontract = (plantPlanningID) => {
    const payload = {
      'enable_sub_contract': true
    }
    axios.patch(`${APIROUTES.ENABLE_SUB_CONTRACT}${plantPlanningID}/`, payload,
      this.getOptions(this?.props?.user)
    )
      .then(res => {
        if (res.status === 200) {
          this.handleOpenSnackbar(
            `subcontract enabled Successfully`,
            "success"
          );
          setTimeout(() => {
            this.props.handleFetchPlantPlanningData();
          }, 4000);
        }
        else {
          this.handleOpenSnackbar(
            `Error occured while enabling subcontract`,
            "error"
          );
        }
      })
      .catch(error => {
        this.handleOpenSnackbar(
          checkApiError(error?.response?.data),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);

      })
  }

  render() {
    const { classes, } = this.props;
    const {
      rows,
      isEditMode,
      snackbarType,
      snackbarMessage,
      openSnackbar,
      showLoader,
      isShortCloseMode,
      shortCloseValue,
      isLoader
    } = this.state;
    return (
      <Paper className={classes.root} style={{ boxShadow: "none" }}>
        {
          this.state.showLoader ? (
            <div className={classes.loaderDiv}>
              <SpinLoader />
            </div>
          ) : ""
        }
        <ThemeProvider theme={theme}>
          <div style={{ padding: "5px 20px 10px 20px" }} >
            <Paper>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          Width: "12%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          alignContent: "center",
                          fontFamily: "Poppins"
                        }}
                      >
                        PLANT
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          width: "13.6%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        ALLOTTED
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          maxWidth: "15%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        LA(PENDING/APPROVED)
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          maxWidth: "16%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        LA(IN PLANT)
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          maxWidth: "15%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        DISPATCHED
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          width: "12%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        CANCELLED
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          maxWidth: "16.6%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        OPEN QTY
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          maxWidth: "16.6%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        SC OPEN QTY
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: grey,
                          maxWidth: "16.6%",
                          fontSize: "0.9em",
                          fontWeight: "bold",
                          fontFamily: "Poppins"
                        }}
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {console.log("row", rows)}
                    {rows
                      .sort(this.comparePlantName)
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ padding: "15px", fontFamily: "Poppins" }}
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ width: "25px" }}>
                                    {row?.sub_contract_enabled ? <span><CheckCircleOutlineOutlined style={{ marginTop: "5px" }} fontSize="small" /></span> : <></>}
                                  </td>
                                  <td>
                                    {row.plant_short_name}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              padding: "15px 0px",
                              display: isEditMode && "flex",
                              justifyContent: isEditMode && "center",
                              alignItems: isEditMode && "center",
                              fontFamily: "Poppins"
                            }}
                          >
                            {!isEditMode ? (
                              row.allotted_qty
                            ) : (
                              <TextField
                                className={classes.root}

                                name="allotted"
                                id={index}
                                label="Allotted"
                                size="small"
                                variant="outlined"
                                defaultValue={row.allotted_qty}
                                onChange={(e) => {
                                  this.setState((previousState) => {
                                    const tempRows = [...previousState.rows];
                                    tempRows[index] = {
                                      ...tempRows[index],
                                      allotted_qty: e.target.value,
                                    };

                                    return {
                                      rows: tempRows,
                                    };
                                  }, () => this.handlCheckAllotedError())
                                }}
                                error={
                                  this.isAllottedQtyLess(row)
                                }
                                helperText={
                                  this.isAllottedQtyLess(row)
                                    ? `Allotted quantity is less than permissible quantity`
                                    : ""
                                }
                                onKeyPress={(event) => {
                                  if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                    event.preventDefault();
                                  }
                                }}
                                InputProps={{
                                  inputProps: { min: 0 },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span
                                        style={{ fontSize: "0.9em", color: grey }}
                                      >
                                        MT
                                      </span>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center" style={{ padding: "15px", fontFamily: "Poppins" }}>
                            {(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.planned_quantity) == null ? "" :
                              Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.planned_quantity).toFixed(3)}
                          </TableCell>
                          <TableCell align="center" style={{ padding: "15px", fontFamily: "Poppins" }}>
                            {(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.in_plant_quantity) == null ? "" :
                              Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.in_plant_quantity).toFixed(3)}
                          </TableCell>
                          <TableCell align="center" style={{ padding: "15px", fontFamily: "Poppins" }}>
                            {(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.dispatched_quantity) == null ? "" :
                              Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.dispatched_quantity).toFixed(3)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              padding: isEditMode
                                ? "25px 0"
                                : "15px 0",
                              fontFamily: "Poppins",
                              // display: (isShortCloseMode || !((Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3) == "NaN" ? "0.000" : Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3)) === (Number(0).toFixed(3)))) && "flex",
                              // justifyContent: (isShortCloseMode || !((Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3) == "NaN" ? "0.000" : Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3)) === (Number(0).toFixed(3)))) && "center",
                              // alignItems: (isShortCloseMode || !((Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3) == "NaN" ? "0.000" : Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3)) === (Number(0).toFixed(3)))) && "center",
                            }}
                          >
                            {!isShortCloseMode ||
                              (Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3) == "NaN"
                                ? '0.000'
                                : Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3)) === (Number(0).toFixed(3))
                              ? (row.cancelled_qty == null ? "0.000" : row.cancelled_qty)
                              : (
                                <TextField
                                  className={classes.root}
                                  style={{ marginLeft: 2.5 }}
                                  name="Cancelled"
                                  id={index}
                                  // label="Cancelled"
                                  size="small"
                                  variant="outlined"
                                  // defaultValue={row.cancelled_qty == null
                                  //   ? '0.000'
                                  //   : row.cancelled_qty}
                                  defaultValue={shortCloseValue}
                                  onChange={(e) => {
                                    this.isShortCloseQtyValid(
                                      index,
                                      e.target.value,
                                      this.props.orderPlantPlanningData.find(p => p.plant === row.plant)?.cancelled_qty == null
                                        ? 0
                                        : this.props.orderPlantPlanningData.find(p => p.plant === row.plant)?.cancelled_qty,
                                      this.props.orderPlantPlanningData.find(d => d.plant === row.plant)?.quantity_with_subcontract
                                    )
                                    this.setState((previousState) => {
                                      const tempRows = [...previousState.rows];
                                      tempRows[index] = {
                                        ...tempRows[index],
                                        cancelled_qty: (Number(this.props.orderPlantPlanningData.find(p => p.plant === row.plant)?.cancelled_qty) + Number(e.target.value)).toFixed(3),
                                        allotted_qty: String((Number(this.props.orderPlantPlanningData.find(p => p.plant === row.plant)?.allotted_qty) - Number(e.target.value)).toFixed(3))
                                      };

                                      return {
                                        rows: tempRows,
                                      };
                                    })
                                  }}
                                  error={
                                    this.state?.isShortCloseQtyValid[index] == undefined
                                      ? false
                                      : !this.state?.isShortCloseQtyValid[index]
                                  }
                                  helperText={
                                    this.state?.isShortCloseQtyValid[index] == undefined
                                      ? `Qty : ${row.cancelled_qty} MT`
                                      : !this.state?.isShortCloseQtyValid[index]
                                        ? `Exceed available qty 
                                        ${this.props.orderPlantPlanningData.find(d => d.plant === row.plant)?.quantity_with_subcontract.available_qty} MT`
                                        : `Qty : ${row.cancelled_qty} MT`
                                  }
                                  onKeyPress={(event) => {
                                    if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                      event.preventDefault();
                                    }
                                  }}
                                  FormHelperTextProps={{
                                    style: {
                                      color: this.state?.isShortCloseQtyValid[index] && "green"
                                    }
                                  }}
                                  InputProps={{
                                    inputProps: { min: 0 },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <span
                                          style={{ fontSize: "0.9em", color: grey }}
                                        >
                                          MT
                                        </span>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                          </TableCell>
                          <TableCell align="center" style={{ padding: "15px", fontFamily: "Poppins" }}>
                            {Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3) == "NaN" ? "" : Number(this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.plant_available_quantity).toFixed(3)}
                          </TableCell>
                          <TableCell align="center" style={{ padding: "15px", fontFamily: "Poppins" }}>
                            {Number(this.props.orderPlantPlanningData.find(d =>
                              d.plant === row.plant)?.quantity_with_subcontract.available_qty).toFixed(3) == "NaN"
                              ? ""
                              : Number(this.props.orderPlantPlanningData.find(d =>
                                d.plant === row.plant)?.quantity_with_subcontract.available_qty).toFixed(3)
                            }
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: pxToEm(13), fontFamily: 'Poppins' }}>
                            {
                              (["APPROVED", "ONGOING"].includes(this.props?.order_status) && row.id && !row?.sub_contract_enabled && (this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.planned_quantity) == null
                                && (this.props.dispatchCalculation.find(d => d.plant_name === row.plant_name)?.in_plant_quantity) == null) ?
                                <IconButton onClick={(e) => {
                                  this.setState({ selectedPlantId: row.id })
                                  this.handleMenuClick(e)
                                }}
                                  style={{ width: "24px", height: "24px" }} >
                                  <MoreVertIcon fontSize="small" />
                                </IconButton>
                                : <></>
                            }

                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  {
                    rows.length > 0 ?
                      (<TableFooter>
                        <TableRow className={classes.tableHeader}>
                          <TableCell align="center" style={{ padding: "15px", fontWeight: "bold", fontFamily: "Poppins" }}>
                            Total
                          </TableCell>
                          <TableCell align="center" style={{ fontFamily: "Poppins", padding: "15px", fontWeight: "bold", color: Number(rows.reduce((agg, curr) => agg + parseFloat(curr.allotted_qty), 0.0)).toFixed(3) === this.props?.orderDetails?.quantity ? "green" : "red" }}>
                            {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.allotted_qty), 0.0)).toFixed(3)}
                          </TableCell>
                          <TableCell align="center" style={{ fontFamily: "Poppins", fontWeight: "bold", padding: "15px" }}>
                            {this.props.dispatchCalculation
                              .reduce((agg, curr) => agg +
                                parseFloat(curr.planned_quantity == null ? 0.0 : curr.planned_quantity), 0.0) === 0
                              ? ""
                              : Number(this.props.dispatchCalculation
                                .reduce((agg, curr) => agg +
                                  parseFloat(curr.planned_quantity == null ? 0.0 : curr.planned_quantity), 0.0)).toFixed(3)
                            }
                          </TableCell>
                          <TableCell align="center" style={{ fontFamily: "Poppins", fontWeight: "bold", padding: "15px" }}>
                            {this.props.dispatchCalculation
                              .reduce((agg, curr) => agg +
                                parseFloat(curr.in_plant_quantity == null ? 0.0 : curr.in_plant_quantity), 0.0) === 0
                              ? ""
                              : Number(this.props.dispatchCalculation
                                .reduce((agg, curr) => agg +
                                  parseFloat(curr.in_plant_quantity == null ? 0.0 : curr.in_plant_quantity), 0.0)).toFixed(3)
                            }
                          </TableCell>
                          <TableCell align="center" style={{ fontFamily: "Poppins", fontWeight: "bold", padding: "15px" }}>
                            {this.props.dispatchCalculation
                              .reduce((agg, curr) => agg +
                                parseFloat(curr.dispatched_quantity == null ? 0.0 : curr.dispatched_quantity), 0.0) === 0
                              ? ""
                              : Number(this.props.dispatchCalculation
                                .reduce((agg, curr) => agg +
                                  parseFloat(curr.dispatched_quantity == null ? 0.0 : curr.dispatched_quantity), 0.0)).toFixed(3)
                            }
                          </TableCell>
                          <TableCell align="center" style={{ fontFamily: "Poppins", fontWeight: "bold", padding: "15px", }}>
                            {Number(rows.reduce((agg, curr) => agg + parseFloat(curr.cancelled_qty), 0.0)).toFixed(3)}
                          </TableCell>
                          <TableCell align="center" style={{ fontFamily: "Poppins", fontWeight: "bold", padding: "15px" }}>
                            {this.props.dispatchCalculation
                              .reduce((agg, curr) => agg +
                                parseFloat(curr.plant_available_quantity == null ? 0.0 : curr.plant_available_quantity), 0.0) === 0
                              ? ""
                              : Number(this.props.dispatchCalculation
                                .reduce((agg, curr) => agg +
                                  parseFloat(curr.plant_available_quantity == null ? 0.0 : curr.plant_available_quantity), 0.0)).toFixed(3)
                            }
                          </TableCell>
                          <TableCell align="center" style={{ fontFamily: "Poppins", fontWeight: "bold", padding: "15px" }}>
                            {this.props.orderPlantPlanningData
                              .reduce((agg, curr) => agg +
                                parseFloat(curr.quantity_with_subcontract.available_qty == null ? 0.0 : curr.quantity_with_subcontract.available_qty), 0.0) === 0
                              ? ""
                              : Number(this.props.orderPlantPlanningData
                                .reduce((agg, curr) => agg +
                                  parseFloat(curr.quantity_with_subcontract.available_qty == null ? 0.0 : curr.quantity_with_subcontract.available_qty), 0.0)).toFixed(3)
                            }
                          </TableCell>
                          <TableCell align="center" className={classes.tableHead}></TableCell>

                        </TableRow>
                      </TableFooter>)
                      : ""
                  }
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </ThemeProvider>
        <Dialog
          open={this.state.openConfirmDialog}
          onClose={this.handlConfirmCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title">
            {"Please Confirm"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action is irreversible, do you want to confirm?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              this.handlConfirmCloseDialog();
              this.handleMenuClose();
            }}>No</Button>
            <Button onClick={() => {
              this.handleEnableSubcontract(this.state.selectedPlantId)
              this.handlConfirmCloseDialog();
              this.handleMenuClose();
            }} autoFocus
              style={{
                backgroundColor:
                  primaryColor,
                color: drawerTextColor,
                textTransform: 'none',
                padding: '6px 25px'
              }}
            >
              Yes
            </Button>

          </DialogActions>
        </Dialog>
        <Menu
          id="action-menu"
          anchorEl={this.state.menuAnchorEl}
          keepMounted
          open={Boolean(this.state.menuAnchorEl)}
          onClose={this.handleMenuClose}
        >
          <MenuItem
            style={{
              fontWeight: '600',
              display: 'flex',
              justifyContent: 'flex-start',
              fontSize: pxToEm(16),
              lineHeight: 1,
              color: grey
            }}
            onClick={this.handleConfirmOpenDialog}>
            <CheckCircleOutline fontSize="small" style={{ marginRight: "5px" }} /> ENABLE SUBCONTRACT
          </MenuItem>
        </Menu>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 25
          }}
        >
          <Button
            style={{
              display: (!isShortCloseMode && !isEditMode) && 'none',
              padding: "8px 22px",
              float: "right",
              marginTop: "10px",
              marginRight: "10px"
            }}
            onClick={this.handleCancelButton}
          >
            Cancel
          </Button>
          {console.log(!hasSpecialAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`),
            ["PENDING", "CLOSED", "CANCELLED", "AUTOCLOSED", "CONVERSION_ALLOTMENT_HOLD"].includes(this.props?.order_status),
            isEditMode,
            this.state?.isShortCloseQtyValid.some(d => d === false),
            !(Number(this.props.dispatchCalculation[0]?.order_available_quantity).toFixed(3) !== Number(0).toFixed(3)))}
          <ToolTipComponent
            title="You have no special permission on this widget"
            condition={!hasSpecialAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
            title2={`Contract is ${this.props?.order_status === 'CONVERSION_ALLOTMENT_HOLD' ? 'ALLOTMENT HOLD' : this.props?.order_status}`}
            condition2={["PENDING", "CLOSED", "CANCELLED", "AUTOCLOSED", "CONVERSION_ALLOTMENT_HOLD"].includes(this.props?.order_status)}
            title3={`There is no open qty`}
            condition3={(Number(this.props.dispatchCalculation[0]?.order_available_quantity).toFixed(3) === Number(0).toFixed(3))}
          >
            <Button
              disabled={
                !hasSpecialAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                ["PENDING", "CLOSED", "CANCELLED", "AUTOCLOSED", "CONVERSION_ALLOTMENT_HOLD"].includes(this.props?.order_status) ||
                isEditMode ||
                this.state?.isShortCloseQtyValid.some(d => d === false) ||
                !(Number(this.props.dispatchCalculation[0]?.order_available_quantity).toFixed(3) !== Number(0).toFixed(3))
              }
              style={{
                //border: `1px dashed ${secondaryGrey}`,
                color: hasSpecialAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                  ? !["PENDING", "CLOSED", "CANCELLED", "AUTOCLOSED", "CONVERSION_ALLOTMENT_HOLD"].includes(this.props?.order_status)
                    ? !isEditMode
                      ? !this.state?.isShortCloseQtyValid.some(d => d === false)
                        ? (Number(this.props.dispatchCalculation[0]?.order_available_quantity).toFixed(3) !== Number(0).toFixed(3))
                          ? "#34619C"
                          : 'rgba(0, 0, 0, 0.26)'
                        : 'rgba(0, 0, 0, 0.26)'
                      : 'rgba(0, 0, 0, 0.26)'
                    : 'rgba(0, 0, 0, 0.26)'
                  : 'rgba(0, 0, 0, 0.26)',
                backgroundColor: drawerTextColor,
                fontWeight: 600,
                padding: "8px 22px",
                float: "right",
                marginTop: "10px",
                marginRight: "10px"
              }}
              onClick={this.handleShortCloseButton}
            >
              {isShortCloseMode ? 'CONFIRM' : 'SHORT CLOSE'}
            </Button>
          </ToolTipComponent>
          <Dialog
            open={this.state.openDialog}
            TransitionComponent={this.state.Transition}
            keepMounted
            onClose={this.handleCancelButton}
          >
            <DialogContent style={{
              borderRadius: 15,
              padding: 8,
              width: 400
            }}>
              <div className={classes.contractStatusCustomerBox}>
                <div className={classes.contractStatusHeadingBox}>
                  <Typography
                    className={classes.buyerConsigneeCustomerBoxTitle}
                    style={{ paddingLeft: 0 }}
                  >
                    Short Close
                  </Typography>
                </div>
                <div>
                  <div className={classes.contractStatusContainer}>
                    <div style={{ display: "flex", width: '100%' }}>
                      <span className={classes.contractStatusItemTextStyle}>
                        Contract Quantity
                      </span>
                      <div style={{
                        width: '100%',
                        textAlign: 'end'
                      }}>
                        <span className={classes.contractStatusListStyleValue}>
                          {(Number(this.props.orderDetails.quantity)).toFixed(3)} MT
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.contractStatusContainer}>
                    <div style={{ display: "flex", width: '100%' }}>
                      <span className={classes.contractStatusItemTextStyle}>
                        Dispatched
                      </span>
                      <div style={{
                        width: '100%',
                        textAlign: 'end'
                      }}>
                        <span className={classes.contractStatusListStyleValue}>
                          {/* {(Number(this.state.dispatchCalculation)).toFixed(3)} MT */}
                          {this.props.dispatchCalculation
                            .reduce((agg, curr) => agg +
                              parseFloat(curr.dispatched_quantity == null ? 0.0 : curr.dispatched_quantity), 0.0) === 0
                            ? "0.000 MT"
                            : Number(this.props.dispatchCalculation
                              .reduce((agg, curr) => agg +
                                parseFloat(curr.dispatched_quantity == null ? 0.0 : curr.dispatched_quantity), 0.0)).toFixed(3) + ' MT'
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.contractStatusContainer}>
                    <div style={{ display: "flex", width: '100%' }}>
                      <span className={classes.contractStatusItemTextStyle}>
                        Remaining
                      </span>
                      <div style={{
                        width: '100%',
                        textAlign: 'end'
                      }}>
                        <span className={classes.contractStatusListStyleValue}>
                          {((this.props?.dispatchCalculation[0]?.order_available_quantity) || 0).toFixed(3) + 'MT'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.contractStatusContainer}>
                    <div style={{ display: "flex", width: '100%' }}>
                      <span className={classes.contractStatusItemTextStyle}>
                        Total Short Close
                      </span>
                      <div style={{
                        width: '100%',
                        textAlign: 'end'
                      }}>
                        <span className={classes.contractStatusListStyleValue}>
                          {String(Number(rows.reduce((agg, curr) => agg + parseFloat(curr.cancelled_qty), 0.0)).toFixed(3))} MT
                        </span>
                      </div>
                    </div>
                  </div>
                  <Divider style={{ margin: '25px 0', }} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        minWidth: 340,
                        margin: '5px 0 15px',
                        display: (Number((this.props?.dispatchCalculation[0]?.order_available_quantity) || 0).toFixed(3) === Number(0).toFixed(3)) && 'none'
                      }}
                    >
                      <div style={{
                        minWidth: 340,
                        margin: '5px 0 15px',
                      }}>
                        <FormControl
                          fullWidth
                          size="small"
                          variant="outlined"
                        >
                          <InputLabel id="shortClose">
                            Select Short Close Reason*
                          </InputLabel>
                          <Select
                            id="Short Close Reason"
                            labelId="shortClose"
                            variant="outlined"
                            label=" Select Short Close Reason*"
                            name="Short_Close_Reason"
                            style={{ width: 340, textAlign: 'start' }}
                            MenuProps={MenuProps}
                            value={this.state.Short_Close_Reason}
                            onChange={this.handleDropdownChange}
                          >
                            {this.state?.reasonMaster !== undefined && this.state?.reasonMaster.length > 0
                              ? this.state?.reasonMaster.map((data) => (
                                <MenuItem key={data.reason} value={data.id}>
                                  {data.reason}
                                </MenuItem>
                              ))
                              :
                              <CircularProgress
                                disableShrink
                                className={classes.loaderStyle}
                                style={{ marginLeft: 10 }}
                              />
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span className={classes.contractStatusListStyleValue}>
                          Are you sure you want to Short Close this contract?
                        </span>
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '30px 0 0 0',
                        padding: '0 40px'
                      }}>
                        <Button
                          variant="text"
                          className={classes.contractStatusText}
                          style={{
                            color: grey,
                            backgroundColor: drawerTextColor,
                            marginLeft: 0,
                            marginRight: 20,
                            border: 0,
                            fontSize: pxToEm(15),
                            fontWeight: 600,
                            textTransform: 'none',
                          }}
                          onClick={this.handleCancelButton}
                        >
                          No
                        </Button>
                        <ToolTipComponent
                          title="You have no approve permission on this widget"
                          condition={!hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                          title2="Please select short close reason"
                          condition2={!this.state?.Short_Close_Reason}
                        >
                          <SaveButton
                            variant="contained"
                            style={{
                              backgroundColor: hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
                                ? this.state?.Short_Close_Reason
                                  ? primaryColor
                                  : 'rgba(0, 0, 0, 0.26)'
                                : 'rgba(0, 0, 0, 0.26)',
                              color: drawerTextColor,
                              fontSize: pxToEm(18),
                              fontWeight: 600,
                              textTransform: 'none',
                              padding: '6px 25px'
                            }}
                            disabled={
                              !hasApproveAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                              !this.state?.Short_Close_Reason
                            }
                            onClick={() =>
                              this.handleShortClose(
                                this.state?.rows,
                                this.props?.orderPlantPlanningData,
                                this.props.orderDetails?.id
                              )
                            }
                            isLoading={isLoader}
                          >
                            Yes
                          </SaveButton>
                        </ToolTipComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <ToolTipComponent
            title="You have no create permission on this widget"
            condition={["PENDING",].includes(this.props?.order_status) && !hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
            title2="You have no edit permission on this widget"
            condition2={!["PENDING",].includes(this.props?.order_status) && !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
            title3={`Contract is ${this.props?.order_status}`}
            condition3={["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.props?.order_status)}
          >
            <Button
              disabled={
                ["PENDING",].includes(this.props?.order_status) && !hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                !["PENDING",].includes(this.props?.order_status) && !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                ["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.props?.order_status) ||
                isShortCloseMode ||
                this.state?.allotedError
              }
              style={{
                //border: `1px dashed ${secondaryGrey}`,
                color: ["PENDING",].includes(this.props?.order_status) && !hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                  !["PENDING",].includes(this.props?.order_status) && !hasUpdateAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                  ["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.props?.order_status) ||
                  isShortCloseMode || this.state?.allotedError
                  ? 'rgba(0, 0, 0, 0.26)'
                  : "#34619C",
                backgroundColor: drawerTextColor,
                fontWeight: 600,
                padding: "8px 22px",
                float: "right",
                marginTop: "10px",
                marginRight: "20px"
              }}
              onClick={this.handleSave}
            >
              {isEditMode ? "SAVE" : "EDIT"}
            </Button>
          </ToolTipComponent>
        </div>
        {this.state.rows.some(row => row.sub_contract_enabled) ?
          <div style={{ color: "grey", marginLeft: "20px", fontSize: pxToEm(18), marginBottom: "10px" }}>SUBCONTRACT DETAILS</div>
          : ""
        }
        {
          this.state.rows.filter(r => r.sub_contract_enabled).map(r => (
            <div>
              <SubContractsPlantPlanningTable
                user={this.props.user}
                itemCategoryCode={localStorage.getItem("itemCategoryCode")}
                {...this.state}
                {...this.props}
                name={r.plant_name}
                plantShortName={r.plant_short_name}
                plantId={r.plant}
                subcontractRows={this.state.subcontractRows.filter((e) => e.plant === r.plant)}
                orderId={this?.props?.orderDetails?.id}
                subcontractAvailableQty={r.sub_contract_available_quantity}
                SCOpenQuantity={r.quantity_with_subcontract?.available_qty}
                plantAndAvailableQty={r.plant_name - r.sub_contract_available_quantity}
                detail="SUBCONTRACT DETAILS" />
            </div>
          ))
        }
        <SnackbarComponent
          open={openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CustomPaginationActionsTable));
// CONVERSION_ALLOTMENT_HOLD