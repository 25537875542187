import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import numberToText from "number2text";
import {
  primaryColor,
  drawerTextColor,
} from "../../../constants/internal/colors";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const exitPassStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    borderRadius: "30px",
  },
});

class ExitPassDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plantItemDetails: {},
      total_invoice_value: 0.0,
      total_taxable_value: 0.0,
      total_value: 0.0,
      total_cess_value: 0.0,
      total_cgst_value: 0.0,
      total_sgst_value: 0.0,
      total_igst_value: 0.0,
      total_cess_non_advol_value: 0.0,
      total_freight_advance_amount: 0.0,
      total_quantity: 0.0,
      total_tcs_amount: 0.0,
      total_freight_amount: 0.0,
      total_tax_on_amount: 0.0,
      eInvoiveResponse: null,
      eWayBillResponse: null,
      plantName: null,
      plantAccountDetails: {},
      plantCode: "",
    };
  }

  componentDidMount() {
    this.setState({
      plantItemDetails: this.props.laDetailsInDispatchCounter,
      total_invoice_value: this.props.total_invoice_value,
      total_taxable_value: this.props.total_taxable_value,
      total_value: this.props.total_value,
      total_cess_value: this.props.total_cess_value,
      total_cgst_value: this.props.total_cgst_value,
      total_sgst_value: this.props.total_sgst_value,
      total_igst_value: this.props.total_igst_value,
      eInvoiveResponse: this.props.eInvoiveResponse,
      eWayBillResponse: this.props.eWayBillResponse,
      total_cess_non_advol_value: this.props.total_cess_non_advol_value,
      total_freight_advance_amount: this.props.total_freight_advance_amount,
      total_freight_amount: this.props.total_freight_amount,
      total_quantity: this.props.total_quantity,
      total_tcs_amount: this.props.total_tcs_amount,
      total_tax_on_amount: this.props.total_tax_on_amount,
      plantName: this.props.plantName,
      plantAccountDetails: this.props.plantAccountDetails,
      plantCode: this.props.plantCode,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.laDetailsInDispatchCounter !== state.plantItemDetails) {
      return { plantItemDetails: props.laDetailsInDispatchCounter };
    }
    if (props.total_invoice_value !== state.total_invoice_value) {
      return { total_invoice_value: props.total_invoice_value };
    }
    if (props.total_taxable_value !== state.total_taxable_value) {
      return { total_taxable_value: props.total_taxable_value };
    }
    if (props.total_value !== state.total_value) {
      return { total_value: props.total_value };
    }
    if (props.total_cess_value !== state.total_cess_value) {
      return { total_cess_value: props.total_cess_value };
    }
    if (props.total_cgst_value !== state.total_cgst_value) {
      return { total_cgst_value: props.total_cgst_value };
    }
    if (props.total_sgst_value !== state.total_sgst_value) {
      return { total_sgst_value: props.total_sgst_value };
    }
    if (props.total_igst_value !== state.total_igst_value) {
      return { total_igst_value: props.total_igst_value };
    }
    if (props.eInvoiveResponse !== state.eInvoiveResponse) {
      return { eInvoiveResponse: props.eInvoiveResponse };
    }
    if (props.eWayBillResponse !== state.eWayBillResponse) {
      return { eWayBillResponse: props.eWayBillResponse };
    }
    if (props.total_cess_non_advol_value !== state.total_cess_non_advol_value) {
      return { total_cess_non_advol_value: props.total_cess_non_advol_value };
    }
    if (
      props.total_freight_advance_amount !== state.total_freight_advance_amount
    ) {
      return {
        total_freight_advance_amount: props.total_freight_advance_amount,
      };
    }
    if (props.total_freight_amount !== state.total_freight_amount) {
      return { total_freight_amount: props.total_freight_amount };
    }
    if (props.total_quantity !== state.total_quantity) {
      return { total_quantity: props.total_quantity };
    }
    if (props.total_tcs_amount !== state.total_tcs_amount) {
      return { total_tcs_amount: props.total_tcs_amount };
    }
    if (props.total_tax_on_amount !== state.total_tax_on_amount) {
      return { total_tax_on_amount: props.total_tax_on_amount };
    }
    if (props.plantName !== state.plantName) {
      return { plantName: props.plantName };
    }
    if (props.plantAccountDetails !== state.plantAccountDetails) {
      return { plantAccountDetails: props.plantAccountDetails };
    }
    if (props.plantCode !== state.plantCode) {
      return { plantCode: props.plantCode };
    }

    return null;
  }

  handleViewOrDownloadPdf = (pdfOperation) => {
    let sgstCgstSum = parseFloat(
      Number(this?.state?.total_sgst_value) +
      Number(this?.state?.total_cgst_value)
    ).toFixed(2);
    let tableData = [];
    let roundOffSum = 0;
    tableData.push([
      {
        text: "Sr. No.",
        style: "itemsHeader",
        border: [false, false, false, true],
      },
      {
        text: "Description and Speci. Of Goods",
        style: "itemsHeaderSubTitle",
        border: [false, false, false, true],
      },
      {
        text: "HSN/SAC No.",
        style: "itemsHeader",
        border: [false, false, false, true],
      },
      {
        text: "No. Of Packages",
        style: "itemsHeader",
        border: [false, false, false, true],
      },
      {
        text: "Total Qty.",
        style: "itemHeaderRightAligned",
        border: [false, false, false, true],
      },
      {
        text: "Unit",
        style: "itemsHeader",
        border: [false, false, false, true],
      },
    ]);
    this.state.plantItemDetails?.loading_advice_items?.map(
      (itemDetails, index) => {
        roundOffSum =
          Number(roundOffSum) + Number(itemDetails?.ROUNDOFFAMOUNT1 || 0);
        tableData.push([
          {
            text: index + 1,
            style: "itemNumber",
            border: [false, false, false, false],
          },
          {
            text: itemDetails?.item?.item_name || "",
            style: "itemSubTitle",
            border: [false, false, false, false],
          },
          {
            // text: itemDetails?.HSNCODE || "",
            text: this?.state?.plantItemDetails?.item_category?.hsn_code || "",
            style: "itemNumber",
            border: [false, false, false, false],
          },
          {
            // text:
            //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
            //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
            //     ? 0
            //     : itemDetails?.REMARK || "",
            text: `${itemDetails?.actual_section_count || 0} ${itemDetails?.section_name || ""
              }`,
            style: "itemNumber",
            border: [false, false, false, false],
          },
          {
            //text: parseFloat(itemDetails?.QUANTITY || 0).toFixed(3),
            text: `${Number.isNaN(
              Number(
                this.state.plantItemDetails?.loading_advice_items[index]
                  ?.alloted_weight
              )
            )
              ? 0
              : Number(
                this.state.plantItemDetails?.loading_advice_items[index]
                  ?.alloted_weight
              )
              }`,
            style: "itemNumberRightAligned",
            border: [false, false, false, false],
          },
          {
            // text: itemDetails?.QTYUNIT || "",
            text: itemDetails?.item?.unit?.unit_code || "",
            style: "itemNumber",
            border: [false, false, false, false],
          },
        ]);
      }
    );
    let taxationTableData = [];
    let eInvoiceDataTable = [];
    eInvoiceDataTable.push([
      {
        // text: `PLANT : ${this?.state?.plantAccountDetails?.companyName || ""} ${
        //   this?.state?.plantCode === "RB" || this?.state?.plantCode === "RU"
        //     ? `, ${this?.state?.plantName}`
        //     : ""
        // }`,
        text: `PLANT : ${this?.state?.plantItemDetails?.plant?.plant_name}`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: "",
        border: [false, false, false, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: `INVOICE NO. : ${this?.state?.plantItemDetails?.erp_invoice_number || ""
          }`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: `INVOICE DATE : ${moment(this?.state?.plantItemDetails?.doc_date, "YYYY-MM-DD").format(
          "DD-MMM-YYYY"
        ) || ""
          }`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: "",
        border: [false, false, false, false],
      },
    ]);

    eInvoiceDataTable.push([
      {
        text: `L.C. NO. : ${this?.state?.plantItemDetails?.lc_number || ""}`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);


    eInvoiceDataTable.push([
      {
        text: `L.C. DATE : ${this?.state?.plantItemDetails?.lc_date ? moment(
          this?.state?.plantItemDetails?.lc_date
        ).format("DD-MMM-YYYY") : ""}`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);

    if (!this?.state?.eInvoiveResponse) {
      // eInvoiceDataTable.push([
      //   {
      //     // text: `${
      //     //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
      //     //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
      //     //     ? `AGAINST INVOICE NO. : ${this?.state?.plantItemDetails[0]?.PARTYBILLNO}`
      //     //     : `E-WAY BILL NO. : ${
      //     //         !this.state.eWayBillResponse
      //     //           ? this?.state?.plantItemDetails[0]?.WAYBILLNO || ""
      //     //           : this?.state?.eWayBillResponse?.EwbNo
      //     //           ? this?.state?.eWayBillResponse?.EwbNo
      //     //           : this?.state?.eWayBillResponse?.EWayBill
      //     //           ? this?.state?.eWayBillResponse?.EWayBill
      //     //           : this?.state?.plantItemDetails[0]?.WAYBILLNO || ""
      //     //       }`
      //     // }`,
      //     text: "Test Text",
      //     style: "invoiceDetailLable",
      //     color: "#333333",
      //     fillColor: "#f5f5f5",
      //     margin: [5, 0, 0, 0],
      //     width: 125,
      //     border: [true, false, true, false],
      //   },
      // ]);
      // eInvoiceDataTable.push([
      //   {
      // text: `${
      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
      //     ? `INVOICE DATE : ${
      //         moment(
      //           new Date(this?.state?.plantItemDetails[0]?.PARTYBILLDATE)
      //         ).format("DD-MMM-YYYY") || ""
      //       }`
      //     : `E-WAY BILL DATE : ${
      //         !this?.state?.eWayBillResponse
      //           ? this?.state?.plantItemDetails &&
      //             this?.state?.plantItemDetails[0]?.WAYBILL_DATE
      //             ? moment(
      //                 new Date(
      //                   this?.state?.plantItemDetails[0]?.WAYBILL_DATE
      //                 )
      //               ).format("DD-MMM-YYYY")
      //             : ""
      //           : this?.state?.eWayBillResponse?.EwbDt
      //           ? moment(
      //               this.state.eWayBillResponse.EwbDt,
      //               "YYYY-MM-DD h:mm:ss"
      //             ).format("DD-MMM-YYYY")
      //           : this.state.eWayBillResponse.Date
      //           ? moment(
      //               this.state.eWayBillResponse.Date,
      //               "DD/MM/YYYY h:mm:ss"
      //             ).format("DD-MMM-YYYY")
      //           : this.state.plantItemDetails &&
      //             this.state.plantItemDetails[0].WAYBILL_DATE
      //           ? moment(
      //               new Date(this?.state?.plantItemDetails[0]?.WAYBILL_DATE)
      //             ).format("DD-MMM-YYYY")
      //           : ""
      //       }`
      // }`,
      //     text: "Testing",
      //     style: "invoiceDetailLable",
      //     color: "#333333",
      //     fillColor: "#f5f5f5",
      //     margin: [5, 0, 0, 0],
      //     width: 125,
      //     border: [true, false, true, false],
      //   },
      // ]);

    }

    eInvoiceDataTable.push([
      {
        text: "",
        border: [false, false, false, false],
      },
    ]);

    eInvoiceDataTable.push([
      {
        qr: `${this?.state?.plantItemDetails?.la_number
          }`,
        style: "itemsHeader",
        color: "#333333",
        fillColor: "#ffffff",
        margin: [5, 0, 0, 0],
        fit: '100',
        border: [false, false, false, false],
      },
    ]);

    eInvoiceDataTable.push([
      {
        text: "",
        border: [false, false, false, false],
      },
    ]);

    eInvoiceDataTable.push([
      {
        text: `LA NUMBER : ${this?.state?.plantItemDetails?.la_number || ""
          }`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);

    eInvoiceDataTable.push([
      {
        text: `GATE IN DATE : ${moment(
          this?.state?.plantItemDetails?.gate_in_date
        ).format("DD-MMM-YYYY")}`,

        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);

    console.log("printing invoice ....");
    console.log(this?.state?.plantItemDetails);
    const dd = {
      info: {
        title: `${this?.state?.plantItemDetails?.la_number
          }_${new Date().toISOString()}`,
        author: "RIPL",
        subject: "Exit Pass",
        keywords: "Exit Pass",
      },
      pageMargins: [15, 0, 15, 0],
      background: function (currentPage, pageSize) {

        return {
          // image: AegleBase64,
          // width: 200,
          text: "<-",
          absolutePosition: { x: 5, y: pageSize.height / 2 },
        }
      },
      content: [
        "\n",
        {
          columns: [
            {
              text: "Exit Pass",
              style: "fontSeventeenCenter",
              margin: [0, 0, 0, 5],
            },
          ],
        },
        {
          columns: [
            {
              style: "tableExample",
              table: {
                headerRows: 1,
                widths: [50, 50, 50, 50],
                body: [
                  [
                    {
                      // text: `${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? "Details of Recipient (Billed to)"
                      //     : "Bill To"
                      // }`,
                      text: "Details of Recipient (Billed to)",
                      style: "invoiceDetailLable",
                      colSpan: 4,
                      alignment: "left",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text:
                        this?.state?.plantItemDetails?.buyer?.first_name || "",
                      style: "buyerName",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      // text: `${(this?.state?.plantItemDetails?.buyer
                      //   ?.customer_addresses?.address
                      //   ? this?.state?.plantItemDetails?.buyer
                      //     ?.customer_addresses?.address + ","
                      //   : "") ||
                      //   (this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //     ?.building_name
                      //     ? this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //       ?.building_name + ","
                      //     : " ") +
                      //   (this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //     ?.street
                      //     ? this?.state?.plantItemDetails?.buyer
                      //       ?.gst_addresses?.street + ","
                      //     : " ") +
                      //   (this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //     ?.location
                      //     ? this?.state?.plantItemDetails?.buyer
                      //       ?.gst_addresses?.location + ","
                      //     : " ")
                      //   } 
                      // ${""
                      //   // this?.state?.plantItemDetails?.buyer?.customer_addresses
                      //   //   ?.address || ""
                      //   }
                      // ${this?.state?.plantItemDetails?.buyer?.customer_addresses
                      //     .city ||
                      //   this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //     ?.district_name ||
                      //   this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //     ?.location
                      //   } ${this?.state?.plantItemDetails?.buyer.customer_addresses
                      //     ?.state ||
                      //   this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //     ?.state_name ||
                      //   ""
                      //   } ${this?.state?.plantItemDetails?.buyer.customer_addresses
                      //     ?.pincode ||
                      //   this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //     ?.pincode ||
                      //   ""
                      //   }`,
                      text:
                        `${this?.state?.plantItemDetails?.buyer?.customer_addresses?.address ?
                          (
                            (this?.state?.plantItemDetails?.buyer?.customer_addresses?.address
                              ? this?.state?.plantItemDetails?.buyer?.customer_addresses?.address + ", "
                              : "")
                          )
                          :
                          (
                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.door_number
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.door_number + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.floor_number
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.floor_number + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.building_name
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.building_name + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.street
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.street + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.location
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.location + ", "
                              : "")
                          )
                        }
                        ${""}
                    ${this?.state?.plantItemDetails?.buyer?.customer_addresses?.address ?
                          (
                            (this?.state?.plantItemDetails?.buyer?.customer_addresses?.city
                              ? this?.state?.plantItemDetails?.buyer?.customer_addresses?.city + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.customer_addresses?.state
                              ? this?.state?.plantItemDetails?.buyer?.customer_addresses?.state + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.customer_addresses?.country
                              ? this?.state?.plantItemDetails?.buyer?.customer_addresses?.country + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.customer_addresses?.pincode
                              ? this?.state?.plantItemDetails?.buyer?.customer_addresses?.pincode
                              : "")
                          )
                          :
                          (

                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.district_name
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.district_name + ", "
                              : "")
                            +
                            ((this?.state?.plantItemDetails?.buyer?.gst_addresses?.city_name &&
                              this?.state?.plantItemDetails?.buyer?.gst_addresses?.district_name)
                              ? ((this?.state?.plantItemDetails?.buyer?.gst_addresses?.city_name).trim().toUpperCase() !==
                                (this?.state?.plantItemDetails?.buyer?.gst_addresses?.district_name).trim().toUpperCase())
                                ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.city_name + ", "
                                : ""
                              : this?.state?.plantItemDetails?.buyer?.gst_addresses?.city_name
                                ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.city_name + ", "
                                : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.state_name
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.state_name + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.buyer?.gst_addresses?.pincode
                              ? this?.state?.plantItemDetails?.buyer?.gst_addresses?.pincode
                              : "")
                          )
                        }`,
                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `STATE CODE: ${this?.state?.plantItemDetails?.buyer?.customer_addresses?.state_code || this?.state?.plantItemDetails?.buyer?.gst_addresses?.state_code
                        || ""
                        }`,

                      style: "buyerAddressDetails",
                      colSpan: 2,
                      alignment: "left",
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      // text: `GSTIN: ${
                      //   this?.state?.plantItemDetails[0]?.bill_to_gstin || ""
                      // }`,
                      text: `GSTIN: ${this?.state?.plantItemDetails?.buyer?.gstin_number === null ? "N/A" : this?.state?.plantItemDetails?.buyer?.gstin_number}`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: `PAN NO:  ${this?.state?.plantItemDetails?.buyer?.pan_card_number ?
                        this?.state?.plantItemDetails?.buyer?.pan_card_number :
                        this?.state?.plantItemDetails?.buyer?.gstin_number ?
                          this?.state?.plantItemDetails?.buyer?.gstin_number.slice(2, 12)
                          : ""}`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      text: `MOBILE NO: ${this?.state?.plantItemDetails?.buyer?.customer_addresses
                        ?.contact ||
                        this?.state?.plantItemDetails?.buyer?.gst_addresses
                          ?.contact || ""
                        }`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: `Email : ${this?.state?.plantItemDetails?.buyer?.customer_addresses
                        ?.email ||
                        this?.state?.plantItemDetails?.buyer?.gst_addresses
                          ?.email || ""
                        } `,
                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, true],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      colSpan: 4,
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      // text: `${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? "Details of Supplier"
                      //     : "Consignee (Ship To)"
                      // }`,
                      text: "Consignee (Ship To)",
                      style: "invoiceDetailLable",
                      colSpan: 4,
                      alignment: "left",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      // text:
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPNAME || ""
                      //     : this?.state?.plantItemDetails[0]?.CONSIGNEENAME ||
                      //       "",
                      text:
                        this?.state?.plantItemDetails?.consignee?.first_name +
                        " " +
                        this?.state?.plantItemDetails?.consignee?.last_name,
                      style: "buyerName",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      // text: `${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPADD1 || ""
                      //     : this?.state?.plantItemDetails[0]?.CONSIGNEEADD1 ||
                      //       ""
                      // }, ${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPADD2 || ""
                      //     : this?.state?.plantItemDetails[0]?.CONSIGNEEADD2 ||
                      //       ""
                      // }, ${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPCITY || ""
                      //     : this?.state?.plantItemDetails[0]?.CONSIGNEECITY ||
                      //       ""
                      // }, ${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.STATE || ""
                      //     : this?.state?.plantItemDetails[0]?.CONSIGNEESTATE ||
                      //       ""
                      // }, ${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPPINCODE || ""
                      //     : this?.state?.plantItemDetails[0]
                      //         ?.CONSIGNEEPINCODE || ""
                      // }`,
                      // text:
                      //   ((this?.state?.plantItemDetails?.consignee
                      //     ?.consignee_from_customer_addresses?.address
                      //     ? this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_customer_addresses?.address
                      //     : "") ||
                      //     (this?.state?.plantItemDetails?.buyer?.gst_addresses
                      //       ?.building_name
                      //       ? this?.state?.plantItemDetails?.consignee
                      //         ?.consignee_from_gst_information_addresses
                      //         ?.building_name + ","
                      //       : " ") +
                      //     (this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_gst_information_addresses?.street
                      //       ? this?.state?.plantItemDetails?.consignee
                      //         ?.consignee_from_gst_information_addresses
                      //         ?.street + ","
                      //       : " ") +
                      //     (this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_gst_information_addresses
                      //       ?.location
                      //       ? this?.state?.plantItemDetails?.consignee
                      //         ?.consignee_from_gst_information_addresses
                      //         ?.location
                      //       : " ")) +
                      //   " " +
                      //   ((this?.state?.plantItemDetails?.consignee
                      //     ?.consignee_from_customer_addresses?.city
                      //     ? this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_customer_addresses?.city
                      //     : "") ||
                      //     (this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_gst_information_addresses
                      //       ?.district_name
                      //       ? this?.state?.plantItemDetails?.consignee
                      //         ?.consignee_from_gst_information_addresses
                      //         ?.district_name
                      //       : " ")) +
                      //   " " +
                      //   ((this?.state?.plantItemDetails?.consignee
                      //     ?.consignee_from_customer_addresses?.state
                      //     ? this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_customer_addresses?.state
                      //     : "") ||
                      //     (this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_gst_information_addresses
                      //       ?.state_name
                      //       ? this?.state?.plantItemDetails?.consignee
                      //         ?.consignee_from_gst_information_addresses
                      //         ?.state_name
                      //       : " ")) +
                      //   " " +
                      //   ((this?.state?.plantItemDetails?.consignee
                      //     ?.consignee_from_customer_addresses?.pincode
                      //     ? this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_customer_addresses?.pincode
                      //     : "") ||
                      //     (this?.state?.plantItemDetails?.consignee
                      //       ?.consignee_from_gst_information_addresses?.pincode
                      //       ? this?.state?.plantItemDetails?.consignee
                      //         ?.consignee_from_gst_information_addresses
                      //         ?.pincode
                      //       : " ")),
                      text:
                        `${this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.address ?
                          (
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.address
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.address + ", "
                              : "")
                          )
                          :
                          (
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.door_number
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.door_number + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.floor_number
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.floor_number + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.building_name
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.building_name + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.street
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.street + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.location
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.location + ", "
                              : "")
                          )
                        }
                  ${this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.address ?
                          (
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.city
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.city + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.state
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.state + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.country
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.country + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.pincode
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.pincode
                              : "")
                          )
                          :
                          (

                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.district_name
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.district_name + ", "
                              : "")
                            +
                            ((this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.city_name &&
                              this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.district_name)
                              ? ((this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.city_name).trim().toUpperCase() !==
                                (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.district_name).trim().toUpperCase())
                                ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                                : ""
                              : this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.city_name
                                ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                                : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.state_name
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.state_name + ", "
                              : "")
                            +
                            (this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.pincode
                              ? this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.pincode
                              : "")
                          )

                        }`,
                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      // text: `STATE CODE: ${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPSTATE || ""
                      //     : this?.state?.plantItemDetails[0]?.SHIPTOSTATECODE ||
                      //       ""
                      // }`,
                      text: `STATE CODE : ${this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses?.state_code || this?.state?.plantItemDetails?.consignee?.consignee_from_gst_information_addresses?.state_code || ""}`,
                      style: "buyerAddressDetails",
                      colSpan: 2,
                      alignment: "left",
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      // text: `GSTIN: ${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPGSTIN || ""
                      //     : this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN ||
                      //       ""
                      // }`,
                      text: `GSTIN : ${this?.state?.plantItemDetails?.consignee?.gstin_number === null ? "N/A" : this?.state?.plantItemDetails?.consignee?.gstin_number}`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      // text: `PAN NO: ${
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                      //   this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                      //     ? this?.state?.plantItemDetails[0]?.SUPPAN || ""
                      //     : this?.state?.plantItemDetails[0]?.CONSIGNEEPAN || ""
                      // }`,
                      text: `PAN NO: ${this?.state?.plantItemDetails?.consignee?.pan_card_number ?
                        this?.state?.plantItemDetails?.consignee?.pan_card_number :
                        this?.state?.plantItemDetails?.consignee?.gstin_number ?
                          this?.state?.plantItemDetails?.consignee?.gstin_number.slice(2, 12)
                          : ""}  `,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      text: `MOBILE NO: ${this?.state?.plantItemDetails?.consignee
                        ?.consignee_from_customer_addresses?.contact ||
                        this?.state?.plantItemDetails?.consignee
                          ?.consignee_from_gst_information_addresses?.contact || ""
                        }`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: `Email : ${this?.state?.plantItemDetails?.consignee
                        ?.consignee_from_customer_addresses?.email ||
                        this?.state?.plantItemDetails?.consignee
                          ?.consignee_from_gst_information_addresses?.email || ""
                        } `,
                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, true],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      colSpan: 4,
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                ],
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#f5f5f5";
                },
                vLineColor: function (i, node) {
                  return "#f5f5f5";
                },
              },
            },
            {
              margin: [10, 5, 0, 0],
              table: {
                headerRows: 0,
                widths: [70, 70],
                body: [
                  [
                    {
                      text: "TRUCK NO :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails?.truck_number.toUpperCase() ||
                        "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "L.R. NO. :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.plantItemDetails?.lr_number || "",

                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "L.R. DATE :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this.state.plantItemDetails
                        ? moment(
                          new Date(this?.state?.plantItemDetails?.lr_date)
                        ).format("DD-MMM-YYYY")
                        : "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "TRANSPORTER NAME :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails?.transporter_name || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],

                  [
                    {
                      text: "MODE OF TRANSPORT :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails?.mode_of_transport || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "FRIEGHT",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      // text:
                      //   this?.state?.plantItemDetails[0]?.FREIGHT_BASIS ===
                      //   "FOR-CPT"
                      //     ? "PREPAID"
                      //     : this?.state?.plantItemDetails[0]?.FREIGHT_BASIS ||
                      //       "",
                      text: this?.state?.plantItemDetails?.fright,
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "",
                      colSpan: 2,
                      border: [false, false, false, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: "FROM :",
                      style: "invoiceDetailLable",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                    {
                      text: this?.state?.plantItemDetails?.plant?.plant_city || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "TO :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses
                          ?.city_name || this?.state?.plantItemDetails?.consignee?.consignee_from_customer_addresses
                          ?.city ||
                        (this?.state?.plantItemDetails?.consignee
                          ?.consignee_from_gst_information_addresses?.city_name || this?.state?.plantItemDetails?.consignee
                            ?.consignee_from_gst_information_addresses?.district_name),

                      style: "invoiceDetailLable",
                      color: "#333333",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "Place Of Supply :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails?.buyer?.customer_addresses
                          ?.city_name || this?.state?.plantItemDetails?.buyer?.customer_addresses
                          ?.city ||
                        (this?.state?.plantItemDetails?.buyer
                          ?.gst_addresses?.city_name || this?.state?.plantItemDetails?.buyer
                            ?.gst_addresses?.district_name),

                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "",
                      colSpan: 2,
                      border: [false, false, false, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: "ORDER NO. :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.plantItemDetails?.order?.order_number,

                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "ORDER DATE :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this.state.plantItemDetails &&
                          this.state.plantItemDetails.order?.order_validity
                          ? moment(
                            new Date(
                              this?.state?.plantItemDetails.order?.created_at
                            )
                          ).format("DD-MMM-YYYY")
                          : "",

                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],

                  [
                    {
                      text: "",
                      colSpan: 2,
                      border: [false, false, false, false],
                    },
                    {},
                  ],

                  [
                    {
                      text: "E-WAY BILL NO. : ",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.eWayBillResponse?.EwbNo || this?.state?.eWayBillResponse?.EWayBill || " ",

                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "E-WAY BILL DATE :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: moment(this?.state?.eWayBillResponse?.EwbDt || this?.state?.eWayBillResponse?.Date, [
                        "DD/MM/YYYY hh:mm:ss A",
                        "YYYY-MM-DD hh:mm:ss A",
                      ]).format(
                        "DD-MMM-YYYY"
                      ) === "Invalid date" ? " " : moment(this?.state?.eWayBillResponse?.EwbDt || this?.state?.eWayBillResponse?.Date, [
                        "DD/MM/YYYY hh:mm:ss A",
                        "YYYY-MM-DD hh:mm:ss A",
                      ]).format(
                        "DD-MMM-YYYY"
                      ),

                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],

                  [
                    {
                      text: "",
                      colSpan: 2,
                      border: [false, false, false, false],
                    },
                    {},
                  ],

                  [
                    {
                      text: "AGENT NAME : ",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: `${this?.state?.plantItemDetails?.customer
                        ?.customer_type === "BROKER" || this?.state?.plantItemDetails?.customer
                          ?.customer_type === "DISTRIBUTOR"
                        ? this?.state?.plantItemDetails?.customer
                          ?.first_name + " " +
                        this?.state?.plantItemDetails?.customer
                          ?.last_name
                        : " "}`,

                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],

                  [
                    {
                      text: "PARTY REF NO : ",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: `${this?.state?.plantItemDetails?.order?.party_ref_number || ""
                        }`,

                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],

                  [
                    {
                      text: "PARTY REF DATE : ",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: `${moment(
                        new Date(this?.state?.plantItemDetails?.order?.party_ref_date)
                      ).format("DD-MMM-YYYY")}`,

                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],









                ],
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#f5f5f5";
                },
                vLineColor: function (i, node) {
                  return "#f5f5f5";
                },
              },
            },
            {
              // style: 'tableExample',
              margin: [-55, 5, 0, 0],
              table: {
                headerRows: 1,
                widths: [130],
                body: eInvoiceDataTable,
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#f5f5f5";
                },
                vLineColor: function (i, node) {
                  return "#f5f5f5";
                },
              },
            },
          ],
        },
        // Items Data
        {
          margin: [0, 5, 0, 0],
          table: {
            headerRows: 1,
            widths: [25, "*", 85, 95, 75, 85],
            body: tableData,
          },
        },
        {
          columns: [
            {
              text: "",
              style: "fontSixLeft",
              margin: [0, 0, 0, 5],
            },
          ],
        },

        {
          columns: [
            {
              text: `REMARK : ${this?.state?.plantItemDetails?.remark || ""
                }\nPAYMENT TERMS : ${this?.state?.plantItemDetails?.payment_method
                  ?.payment_method_name || ""
                }`,
              // style: "fontEightLeft",
              style: "fontSixLeft",
              margin: [0, 0, 0, 5],
              border: [true, false, false, false],
            },
          ],
        },
        {
          columns: [
            {
              text: "This document is computer-generated and does not require signature.",
              style: "itemNumber",
              margin: [0, 0, 0, 5],
            },
          ],
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 5, 0, 5],
        },
        // Taxation Data
        fontSixLeft: {
          alignment: "left",
          fontSize: 6,
          margin: [0, 0, 0, 0],
        },
        fontSixRight: {
          alignment: "right",
          fontSize: 6,
          margin: [0, 0, 0, 0],
        },
        fontSixCenter: {
          alignment: "center",
          fontSize: 6,
          margin: [0, 3, 0, 3],
        },
        fontSevenLeft: {
          alignment: "left",
          fontSize: 7,
          margin: [0, 0, 0, 0],
        },
        fontSevenRight: {
          alignment: "right",
          fontSize: 7,
          margin: [0, 0, 0, 0],
        },
        fontSevenCenter: {
          alignment: "center",
          fontSize: 7,
          margin: [0, 3, 0, 3],
        },
        fontSeventeenCenter: {
          alignment: "center",
          fontSize: 17,
          margin: [0, 3, 0, 3],
        },
        fontEightRight: {
          alignment: "right",
          fontSize: 8,
          margin: [0, 0, 0, 0],
          bold: true,
        },
        fontEightLeft: {
          alignment: "left",
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 0],
        },
        buyerName: {
          bold: true,
          color: "#333333",
          // fontSize: 8,
          fontSize: 7,
          alignment: "left",
          margin: [0, 0, 0, 3],
        },
        buyerAddressDetails: {
          // fontSize: 7,
          fontSize: 6,
          alignment: "left",
        },
        // Invoice Detail
        invoiceDetailLable: {
          color: "#333333",
          // fontSize: 7,
          fontSize: 6,
          alignment: "left",
        },
        itemsHeader: {
          padding: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 6,
          bold: true,
          fillColor: "#f5f5f5",
          alignment: "center",
        },
        itemsHeaderSubTitle: {
          margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 6,
          bold: true,
          fillColor: "#f5f5f5",
          alignment: "left",
        },
        itemHeaderRightAligned: {
          margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 6,
          bold: true,
          fillColor: "#f5f5f5",
          alignment: "right",
        },
        itemSubTitle: {
          margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 6,
          alignment: "left",
        },
        itemNumber: {
          margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 6,
          alignment: "center",
        },
        itemNumberRightAligned: {
          margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 6,
          alignment: "right",
        },
        itemRowEvenNumber: {
          margin: [0, 2, 0, 2],
          fontSize: 8,
          alignment: "center",
        },
      },
    };

    if (pdfOperation === "VIEW") {
      pdfMake.createPdf(dd).open();
    } else if (pdfOperation === "DOWNLOAD") {
      pdfMake
        .createPdf(dd)
        .download(
          `Exit Pass ${this?.state?.plantItemDetails?.la_number
          }_${new Date().toISOString()}.pdf`
        );
    } else {
      console.log("invalid pdf operation");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Button
        className={classes.primaryBackground}
        variant="outlined"
        // size="medium"
        style={{ width: this.props.pdfOperation === "VIEW" ? "30%" : "55%", height: "24px" }}
        // startIcon={<PictureAsPdfOutlinedIcon />}
        onClick={() => this.handleViewOrDownloadPdf(this.props.pdfOperation)}
      >
        <span style={{ fontSize: "11.2px" }}>
          {this.props.pdfOperation}
        </span>
      </Button>
    );
  }
}

export default withStyles(exitPassStyles)(ExitPassDownload);
