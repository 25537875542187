import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  color: "#4b87c5",
  height: "50px",
  width: "50px",
};

export default function SpinLoader() {
  return <CircularProgress style={styles} />;
}
