import { useEffect, useState } from "react";
import {
  checkApiError,
  handleSnackbarMessageBox,
  startDateOFToday,
} from "../../../../methods";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../common/SnackbarComponent";
import { SimpleTable, WidgetTop } from "./commanWidget";
import { Paper } from "@mui/material";

const styles = {
  paperContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '98%',
    // borderRadius: "20px",
    margin: '0px auto'
  },
  tableContainer: (isMobile) => ({
    width: "100%",
    maxHeight: 400,
    overflowY: 'auto',
  })
}

const DashboardInPlant = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mounted, setMounted] = useState(false);
  const [inPlantData, setInPlantData] = useState([])
  const [showProgress, setShowProgress] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState({
    tableDataApi: null,
  });
  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    snackbarMessage: "",
    snackbarType: ""
  })

  const handleOpenSnackbar = (message, type) => {
    setSnackbar({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: ""
    })
  };

  const handleCatchError = (error) => {
    handleOpenSnackbar(
      handleSnackbarMessageBox(checkApiError(error?.response)),
      "error"
    );
    setTimeout(() => {
      handleCloseSnackbar();
    }, 4000);
  }

  const handleTableDataCalculation = (data) => {
    if (data) {
      let calculationArrayField = ['approved_count', 'approved_qty', 'vehicle_gate_in_count', 'in_plant_qty', 'billed_count', 'billed_qty', 'dispatched_count', 'dispatched_qty', 'todays_vehicle', 'today_vehicle_qty']
      let ItemKeys = Object.keys(data)
      let newdata = []
      for (let D = 0; D < ItemKeys.length; D++) {
        data[ItemKeys[D]].map((p, i) => {
          let obj = {}
          obj = {
            item: D,
            s_no: i === 0 ? D + 1 : '',
            item_category: i === 0 ? ItemKeys[D] : "",
            plant_short_name: p?.plant_short_name || '',
            approved_count: p.approved_count,
            approved_qty: p.approved_qty,
            vehicle_gate_in_count: p.vehicle_gate_in_count,
            in_plant_qty: p.in_plant_qty,
            billed_count: p.billed_count,
            billed_qty: p.billed_qty,
            dispatched_count: p.dispatched_count,
            dispatched_qty: p.dispatched_qty,
            todays_vehicle: Number(p.approved_count) + Number(p.vehicle_gate_in_count) + Number(p.billed_count) + Number(p.dispatched_count),
            today_vehicle_qty: Number(Number(Number(p.approved_qty) + Number(p.in_plant_qty) + Number(p.billed_qty) + Number(p.dispatched_qty)).toFixed(3)),
          }
          newdata.push(obj)
        })
      }
      // console.log(newdata)
      for (let i = 0; i < ItemKeys.length; i++) {
        let subTotalObj = {};
        let fromIndex = newdata.findIndex(ele => ele.item === i)
        let toIndex = newdata.findLastIndex(ele => ele.item === i)
        calculationArrayField.forEach(d => {
          subTotalObj[d] = d.includes('qty')
            ? Number(Number(newdata.slice(fromIndex, toIndex + 1).reduce((t, v) => t + v[d], 0)).toFixed(3))
            : Number(newdata.slice(fromIndex, toIndex + 1).reduce((t, v) => t + v[d], 0))
        })
        subTotalObj.plant_short_name = 'SUB TOTAL'
        subTotalObj.item_category = ''
        subTotalObj.colouredRow = true
        newdata.splice(toIndex + 1, 0, subTotalObj);
        // console.log(newdata)
      }
      console.log(newdata)
      setInPlantData(newdata)
      setShowProgress(false)
    }
  }

  const handleTableData = () => {
    setInPlantData([])
    const newCancelTokenSource = handleCancelToken('tableDataApi')
    setShowProgress(true)
    const payload = {
      from_date: startDateOFToday,
      to_date: startDateOFToday,
    }
    axios.post(`${APIROUTES.IN_PLANT_DATA}`,
      payload,
      { cancelToken: newCancelTokenSource.token, }
    )
      .then((response) => {
        if (response.status === 200) {
          let data = response.data['In Plant Data'];
          handleTableDataCalculation(data)
        }
      })
      .catch((error) => {
        console.log(error)
        setShowProgress(false)
        if (axios.isCancel(error)) {
        } else {
          handleCatchError(error)
        }
      });
  }

  const handleCancelToken = (key) => {
    if (cancelTokenSource[key]) {
      cancelTokenSource[key].cancel('Operation canceled');
    }
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(prevValue => { return { ...prevValue, [key]: newCancelTokenSource } });
    return newCancelTokenSource;
  }

  useEffect(() => {
    handleTableData()
    setMounted(true)
    return () => {
      setMounted(false)
    };
  }, [])

  useEffect(() => {
    // Cleanup function to cancel the previous request when component unmounts
    return () => {
      Object.entries(cancelTokenSource)
        .filter(([, value]) => value !== null)
        .forEach(([key, value]) => (cancelTokenSource[key].cancel("Operation canceled")));
    };
  }, [cancelTokenSource]);

  const TABLEDATA = [
    // { label: '#', key: 's_no', id: 's_no', minWidth: 20 },
    { label: 'Item Category', key: 'item_category', id: 'no_header', minWidth: 130 },
    { label: 'Plant', key: 'plant_short_name', id: 'no_header', minWidth: 130 },
    { label: 'READY (Count/MT)', key: 'approved_count', key2: 'approved_qty', minWidth: 110, decimalValueUpto: 3 },
    { label: 'GATE-IN (Count/MT)', key: 'vehicle_gate_in_count', key2: 'in_plant_qty', minWidth: 110, decimalValueUpto: 3 },
    { label: 'BILLED (Count/MT)', key: 'billed_count', key2: 'billed_qty', minWidth: 110, decimalValueUpto: 3 },
    { label: 'DISPATCHED (Count/MT)', key: 'dispatched_count', key2: 'dispatched_qty', minWidth: 110, decimalValueUpto: 3 },
    { label: `TOTAL (Count/MT)`, key: 'todays_vehicle', key2: 'today_vehicle_qty', minWidth: 110, decimalValueUpto: 3 },
  ]

  return (
    <div style={{ paddingBottom: 20 }}>
      <WidgetTop
        {...props}
        widgetTitle='In Plant'
        widgetSubtitle="In Plant Details"
        isMobile={isMobile}
        plantFilter={false}
        itemCategoryFilter={false}
        pdfCsvButton={false}
      />
      <Paper style={styles.paperContainer}>
        <SimpleTable
          {...props}
          isMobile={isMobile}
          tableData={TABLEDATA}
          showProgress={showProgress}
          rows={inPlantData}
          tableContainerStyle={styles.tableContainer(isMobile)}
          backgroundOddEven={false}
          stickyHeader={true}
          widget="dashboard_live_status"
        />
      </Paper>
      <SnackbarComponent
        open={snackbar.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbar.snackbarMessage}
        type={snackbar.snackbarType}
      />
    </div>
  )
}

export default DashboardInPlant;