import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  secondaryGrey,
  successButtonBackgroundHover,
} from "../../../constants/internal/colors";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ContractsPlantPlaningTable from "./ContractsPlantPlaningTable";
import Add from "@material-ui/icons/Add";
import Done from "@material-ui/icons/Done";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const styles = {
  customerDetailContainer: {
    paddingTop: "25px",
    width: "96%",
    paddingLeft: "32px",
    paddingBottom: "55px",
  },
  customerDetailChildDiv: {
    paddingTop: "32px",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "96%",
    paddingBottom: "20px",
  },
  customerNameTextStyle: {
    color: grey,
    fontWeight: "bold",
    width: "25%",
  },

  checkboxContainerStyle: {
    display: "flex",
    paddingTop: "20px",
    paddingLeft: "22px",
    alignItems: "center",
  },

  recentActivityPaper: {
    padding: "10px 10px 10px 30px",
    width: "45%",
    marginTop: "30px",
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
  recentActivityTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    alignItems: "center",
  },
  recentActivityTextDivLeft: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.6em",
  },
};
class SingleContractsItemAndPlantPanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddPlant: false,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper elevation={1} className={classes.paperStyle}>
          <div className={classes.customerDetailContainer}>
            <div className={classes.customerDetailChildDiv}>
              <div style={{ display: "flex", marginBottom: "7px" }}>
                <span className={classes.customerNameTextStyle}>
                  REQUIREMENT SUBMITED
                </span>
                <span>SHAHID HUSSAIN</span>
              </div>
              <div style={{ display: "flex", marginBottom: "7px" }}>
                <span className={classes.customerNameTextStyle}>
                  IN PROCESS
                </span>
                <span>30MT</span>
              </div>
              <div style={{ display: "flex", marginBottom: "7px" }}>
                <span className={classes.customerNameTextStyle}>COMPLETED</span>
                <span>100MT</span>
              </div>
            </div>
          </div>
          <Divider variant="middle" />
          <div id="test">
            <ContractsPlantPlaningTable />
          </div>
          {this.state.openAddPlant ? (
            <div style={{ padding: "20px 0px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <FormControl
                  style={{ width: "30%" }}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel
                    id="label-id"
                    ref={(ref) => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                  >
                    Select Plant
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    labelId="label-id"
                    label="Select Plant"
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="new">New</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  type="number"
                  id="outlined-name"
                  label="Pending Quantity"
                  margin="normal"
                  variant="outlined"
                  style={{ width: "30%", margin: "0px 0px 0px 22px" }}
                  inputProps={{
                    style: { padding: "11px 14px" },
                  }}
                  InputLabelProps={{
                    style: { fontSize: "1.1em", marginTop: "-5px" },
                  }}
                />
              </div>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "space-around",
                  paddingTop: "25px",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: successButtonBackgroundHover,
                    color: "#fff",
                    padding: "6px 30px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ openAddPlant: false });
                  }}
                >
                  <Done />
                  Save
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ openAddPlant: false });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className={classes.checkboxContainerStyle}>
              <Button
                style={{
                  border: `1px dashed ${secondaryGrey}`,
                  color: secondaryGrey,
                  padding: "8px 22px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ openAddPlant: true });
                }}
              >
                <Add />
                ADD PLANT
              </Button>
            </div>
          )}

          {/* <div className={classes.checkboxContainerStyle}>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: primaryColor,
                width: "20%",
              }}
            >
              ADD REQUIREMENT
            </Button>
          </div> */}
        </Paper>
        {/* <Paper elevation={1} className={classes.recentActivityPaper}>
          <span style={{ color: grey, fontWeight: 'bold' }}>
            RECENT ACTIVITY
          </span>
          <div className={classes.recentActivityTextDiv}>
            <div className={classes.recentActivityTextDivLeft}>
              <span>TMT 5mm equipment updated to 40MT</span>
              <span style={{ color: grey }}>Mohan prasad (sales head)</span>
            </div>
            <span style={{ color: grey }}>2 hours ago</span>
          </div>
          <div className={classes.recentActivityTextDiv}>
            <div className={classes.recentActivityTextDivLeft}>
              <span>TMT 5mm equipment updated to 40MT</span>
              <span style={{ color: grey }}>Mohan prasad (sales head)</span>
            </div>
            <span style={{ color: grey }}>2 hours ago</span>
          </div>
        </Paper> */}
      </div>
    );
  }
}
export default withRouter(
  withStyles(styles)(SingleContractsItemAndPlantPanning)
);
