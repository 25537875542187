import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import "../../../styles/b2b/OrdersList.css";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Assignment from "@material-ui/icons/Assignment";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelPresentation from "@material-ui/icons/CancelPresentation";
import Info from "@material-ui/icons/Info";
import {
    bottomNavigationFontColor,
    dailyRateScreenTextColor,
    secondaryColor,
    errorMessageColor,
} from "../../../styles/colors";
import axios from "axios";
import B2b_URL from "../../../constants/b2b/B2bRoutes";
import moment from "moment";
import { pxToEm } from "../../../methods";

const styles = {
    listContainerStyle: {
        cursor: "pointer",
        width: "90%",
        // height: "14vh",
        borderRadius: "12px",
        margin: "10px 0px",
        padding: "10px 0px",
        display: "flex",
        alignItems: "center",
        boxShadow:
            "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
    },
    listContainerLeftSideStyle: {
        display: "flex",
        flexDirection: "column",
        width: "20%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    listContainerMiddleSideStyle: {
        display: "flex",
        flexDirection: "column",
        width: "58%",
        height: "100%",
        paddingLeft: "5px",
        justifyContent: "center",
    },
    listContainerRightSideStyle: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "22%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    timeTextStyle: {
        // position: "absolute",
        bottom: "8px",
        color: bottomNavigationFontColor,
        fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
    },
    nameTextStyle: {
        color: dailyRateScreenTextColor,
        fontSize: "1.1em",
        whiteSpace: "nowrap",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: window.innerWidth < 310 ? pxToEm(13) : window.innerWidth < 375 && pxToEm(14)
    },
    textStyle: {
        color: bottomNavigationFontColor,
        fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 ? pxToEm(12) : "0.85em"
    },
    leftIconCircleStyle: (status) => ({
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        transform: 'scale(0.8)',
        border:
            status === "PENDING" || status === "BILLED" || status === "DISPATCHED"
                ? `1px solid ${secondaryColor}`
                : status === "CANCELLED"
                    ? "1px solid red"
                    : status === "APPROVED" ?
                        "1px solid green"
                        : `1px solid ${errorMessageColor}`,
    }),
    leftIconText: (status) => ({
        color: bottomNavigationFontColor,
        fontSize: (status.length > 7)
            ? window.innerWidth < 310
                ? pxToEm(9.4)
                : window.innerWidth < 375
                    ? pxToEm(10)
                    : pxToEm(11.6)
            : window.innerWidth < 310
                ? pxToEm(10.6)
                : window.innerWidth < 375
                    ? pxToEm(11.6)
                    : pxToEm(13.6),
    })
};
class LAList extends Component {


    getOptions = (user) => {
        return {
            headers: {
                Authorization: `JWT ${user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    };

    handleSingleOrders = (order, laNumber) => {
        if (order.order_status === "PENDING") {
            this.props.history.push(`/dispatch/pending/${order.id}`);
        } else if (order.order_status === "action") {
            this.props.history.push(`/dispatch/action/${order.id}`);
        } else if (order.order_status === "cancelled") {
            this.props.history.push(`/dispatch/cancel/${order.id}`);
        } else {
            this.props.fetchLADetails(laNumber)
                .then(response => {
                    // console.log(response)
                    let LADetails = response.data[0]
                    // console.log(LADetails)
                    let logDetails = LADetails.log_details
                    let logs = []
                    // let statuses =
                    Object.keys(logDetails).map(key => {
                        // console.log(key, logDetails[key])
                        logs.push({ [key]: logDetails[key] })
                        // statuses.push(key)
                    })
                    // console.log(logs)
                    let LADetailsrev = logs.reverse()
                    // console.log(LADetailsrev)
                    this.props.history
                        .push({
                            pathname: `/dispatch/singleDispatch/${order.id}`,
                            state: { order, LADetails, LADetailsrev }
                        });

                })
                .catch(err => console.log(err))
            // console.log(laNumber)


        }
        // if (this.props.dealerOrders && this.props.dealerOrders) {
        //   this.props.history.push(`/dispatch/singleDispatch/${orderId}`);
        // } else {
        //   this.props.history.push(`/dispatch/singleDispatch/${orderId}`);
        // }
    };

    render() {
        const {
            classes,
            status,
            laNumber,
            quantity,
            plant,
            itemCategory,
            order,
            truckNumber,
            createdDate
        } = this.props;
        // console.log(this.props)
        return (
            <div
                className={classes.listContainerStyle}
                onClick={(e) => {
                    e.preventDefault();
                    this.handleSingleOrders(order, laNumber);
                }}
            >
                <div className={classes.listContainerLeftSideStyle}>
                    <span style={styles.leftIconCircleStyle(status)}>
                        {/* {status === "pending" ? (
                            <HourglassEmpty style={{ color: secondaryColor }} />
                        ) : status === "cancelled" ? (
                            <CancelPresentation style={{ color: "red" }} />
                        ) : (
                            <Info style={{ color: errorMessageColor }} />
                        )} */}
                        {status === "PENDING" ? (
                            <HourglassEmpty style={{ color: secondaryColor }} />
                        ) : status === "CANCELLED" ? (
                            <HighlightOffRoundedIcon style={{ color: "red" }} />
                        ) : status === "BILLED" ? (
                            <Assignment style={{ color: secondaryColor }} />
                        ) : status === "DISPATCHED" ? (
                            <LocalShippingOutlinedIcon style={{ color: secondaryColor }} />
                        ) : status === "APPROVED" ? (
                            <CheckCircleOutlinedIcon style={{ color: "green" }} />
                        ) :
                            (
                                <Info style={{ color: errorMessageColor }} />
                            )}
                    </span>
                    <span style={styles.leftIconText(status)}>{status}</span>
                </div>
                <div className={classes.listContainerMiddleSideStyle}>
                    <span className={classes.nameTextStyle}>{itemCategory + ' | ' + plant}
                        {/* <span style={styles.textStyle}>{' ' + plant}</span> */}
                    </span>
                    <span className={classes.timeTextStyle}>{truckNumber}</span>
                    <span style={styles.textStyle}>{laNumber + ' | ' + moment(createdDate).format("DD-MMM-yyyy")}</span>
                    {/* <span style={styles.textStyle}>{moment(createdDate).format("DD-MMM-yyyy")}</span> */}

                </div>
                <div className={classes.listContainerRightSideStyle}>
                    <span style={{ fontSize: window.innerWidth < 310 ? pxToEm(12.5) : window.innerWidth < 375 ? pxToEm(13.5) : "1em", }}>
                        {Number(quantity).toFixed(3)}
                        <span style={{ marginLeft: "4px" }}>MT</span>
                    </span>
                    {/* <span className={classes.timeTextStyle}>{truckNumber}</span> */}
                </div>
            </div>
        );
    }
}
export default withRouter(withStyles(styles)(LAList));
