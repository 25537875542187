import React, { useEffect, useState } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import ItemCategoryEditComponent from "../../../../components/internal/masterManagement/itemCategory/ItemCategoryEditComponent";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import AccessDenied from "../../../../components/common/AccessDenied";

// container for Master Management -> RateTemplate Category -> View
const ItemCategoryEdit = (props) => {
  const { user, isMobile } = props;
  const [AllCategories, setAllCategories] = useState([]);
  const [FetchingRateTemplate, setFetchingRateTemplate] = useState(true);
  const [FetchingItemType, setFetchingItemType] = useState(true);
  const [FetchingRateLinking, setFetchingRateLinking] = useState(true);
  const [RateTemplate, setRateTemplate] = useState(null);
  const [RateLinking, setRateLinking] = useState(null);
  const [ItemType, setItemType] = useState(null);
  const [RateTemplateEditError, setRateTemplateEditError] = useState([]);
  const [ItemTypeEditError, setItemTypeEditError] = useState([]);
  const [RateLinkingError, setRateLinkingError] = useState({
    related_to: false,
    rate_difference: false,
  });

  const getAllCateogries = async () => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };
      // const id = props?.location?.pathname?.split("/")[props?.location?.pathname?.split("/").length - 1];
      axios.get(APIROUTES.DAILY_RATE_TEMPLATE_GET, options).then(
        (response) => {
          let result = response.data;
          console.log("response ", result);
          setAllCategories(result);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  };

  const getCategoryDetails = () => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };
      const id =
        props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ];
      axios.get(APIROUTES.DAILY_RATE_TEMPLATE_DETAILS + id + "/", options).then(
        (response) => {
          let result = response.data;
          setRateTemplate(result);
          if (result.related_to) {
            getRateLinking(options, result.related_to);
          } else {
            setFetchingRateLinking(false);
          }
          setFetchingRateTemplate(false);
        },
        (error) => {
          console.log("error", error);
          setFetchingRateTemplate(false);
          setFetchingRateLinking(false);
        }
      );
    }
  };

  const getItemTypes = async () => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };
      const id =
        props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ];
      if (!FetchingItemType) setFetchingItemType(true);
      try {
        const { data } = await axios.get(
          APIROUTES.MM_ITEM_TYPE_GET + id,
          options
        );
        setItemType(data);
        setFetchingItemType(false);
      } catch (err) {
        console.log("err ", err);
        props.history.goBack();
        setFetchingItemType(false);
      }
    }
  };

  const getRateLinking = async (options, id) => {
    try {
      let { data } = await axios.get(
        APIROUTES.DAILY_RATE_TEMPLATE_DETAILS + id + "/",
        options
      );
      setRateLinking({ name: data.category_name });
      setFetchingRateLinking(false);
    } catch (err) {
      console.log("err in rate link ", err);
      setFetchingRateLinking(false);
    }
  };

  useEffect(() => {
    getAllCateogries();
    getCategoryDetails();
    getItemTypes();
    // eslint disable
  }, [user]);

  // methods for component
  const handleRateTemplateTableEdit = (rowId, arrIndex, keyName, value) => {
    const currentRateTemplate = { ...RateTemplate };
    const currentDailyRates = currentRateTemplate.daily_rates_template;
    currentDailyRates[arrIndex][keyName] = value;
    currentRateTemplate.daily_rates_template = currentDailyRates;
    setRateTemplate(currentRateTemplate);
  };

  const handleRateTemplateTableEditStar = (rowId, arrIndex, currentValue) => {
    if (currentValue === false) {
      // selected is not base rate but user wants to make it base rate
      const currentRateTemplate = { ...RateTemplate };
      const currentDailyRates = currentRateTemplate.daily_rates_template;
      //find active base rate and make it inactive
      currentDailyRates.forEach((d, i) => {
        if (d.is_base_rate) {
          currentDailyRates[i]["is_base_rate"] = false;
        }
      });
      currentDailyRates[arrIndex]["is_base_rate"] = true;
      currentRateTemplate.daily_rates_template = currentDailyRates;
      setRateTemplate(currentRateTemplate);
    }
  };

  const handleRateLinkingEdit = (key, value) => {
    const currentRateTemplate = { ...RateTemplate };
    currentRateTemplate[key] = value;
    setRateTemplate(currentRateTemplate);
  };

  const handleItemTypeTableEdit = (rowId, arrIndex, keyName, value) => {
    const currentItemType = [...ItemType];
    currentItemType[arrIndex][keyName] = value;
    setItemType(currentItemType);
  };

  const handleValidationRateTemplateEdit = () => {
    let allOk = true;
    const errorRateTemplateEdit = RateTemplate.daily_rates_template.map(
      (d, i) => [{ high_rate_difference: false, low_rate_difference: false }]
    );
    RateTemplate.daily_rates_template.forEach((d, i) => {
      if (isNaN(d.high_rate_difference) || d.high_rate_difference.length <= 0) {
        errorRateTemplateEdit[i]["high_rate_difference"] = true;
        allOk = false;
      } else {
        errorRateTemplateEdit[i]["high_rate_difference"] = false;
      }

      if (
        isNaN(d.low_rate_difference) ||
        d.low_rate_difference.length <= 0 ||
        parseFloat(d.low_rate_difference) > 0.0
      ) {
        errorRateTemplateEdit[i]["low_rate_difference"] = true;
        allOk = false;
      } else {
        errorRateTemplateEdit[i]["low_rate_difference"] = false;
      }
    });

    setRateTemplateEditError(errorRateTemplateEdit);
    return allOk;
  };

  const handleValidationItemTypeEdit = () => {
    let allOk = true;
    const errorItemTypeEdit = ItemType.map((d, i) => [
      {
        item_name: false,
        rate_difference: false,
        section_name: false,
        section_weight: false,
      },
    ]);
    ItemType.forEach((d, i) => {
      if (!d.item_name || d.item_name.length <= 0) {
        errorItemTypeEdit[i]["item_name"] = true;
        allOk = false;
      } else {
        errorItemTypeEdit[i]["item_name"] = false;
      }

      if (isNaN(d.rate_difference) || d.rate_difference.length <= 0) {
        errorItemTypeEdit[i]["item_name"] = true;
        allOk = false;
      } else {
        errorItemTypeEdit[i]["item_name"] = false;
      }

      if (isNaN(d.section_weight) || d.section_weight.length <= 0) {
        errorItemTypeEdit[i]["section_weight"] = true;
        allOk = false;
      } else {
        errorItemTypeEdit[i]["section_weight"] = false;
      }

      if (!d.section_name || d.section_name.length <= 0) {
        errorItemTypeEdit[i]["section_name"] = true;
        allOk = false;
      } else {
        errorItemTypeEdit[i]["section_name"] = false;
      }
    });

    setItemTypeEditError(errorItemTypeEdit);
    return allOk;
  };

  const handleSaveRateTemplate = async () => {
    let allOk = handleValidationRateTemplateEdit();
    if (!allOk) {
      return;
    }
    setFetchingRateTemplate(true);

    try {
      if (user?.token) {
        const options = {
          headers: {
            Authorization: `JWT ${user.token}`,
          },
        };
        const id =
          props?.location?.pathname?.split("/")[
          props?.location?.pathname?.split("/").length - 1
          ];
        await axios.put(
          APIROUTES.DAILY_RATE_TEMPLATE_DETAILS + id + "/",
          RateTemplate,
          options
        );
        props.history.goBack();
        getCategoryDetails();
      }
    } catch (err) {
      console.log("err occured in handleSaveRateTemplate ", err);
      props.history.goBack();
      getCategoryDetails();
    }
  };

  const handleSaveItemType = async () => {
    let allOk = handleValidationItemTypeEdit();
    if (!allOk) {
      return;
    }
    // setFetchingItemType(true);
    try {
      if (user?.token) {
        const options = {
          headers: {
            Authorization: `JWT ${user.token}`,
          },
        };
        // const id = props?.location?.pathname?.split("/")[props?.location?.pathname?.split("/").length - 1];
        await axios.put(APIROUTES.MM_ITEM_TYPE_EDIT, ItemType, options);
        props.history.goBack();
        getCategoryDetails();
      }
    } catch (err) {
      console.log("err occured in handleSaveRateTemplate ", err);
      props.history.goBack();
      getCategoryDetails();
    }
  };

  const deleteRateTemplateRow = async (id) => {
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };

      try {
        setFetchingRateTemplate(true);
        await axios.delete(
          `${APIROUTES.DAILY_RATE_TEMPLATE_DELETE}${id}`,
          options
        );
        getCategoryDetails();
      } catch (err) {
        console.log("err occur in del rate template row ", err);
      } finally {
        setFetchingRateTemplate(false);
      }
    }
  };

  const handleDeleteItemTypeRow = async (id) => {
    alert("called");
    if (user?.token) {
      const options = {
        headers: {
          Authorization: `JWT ${user.token}`,
        },
      };

      try {
        setFetchingItemType(true);
        await axios.delete(`${APIROUTES.MM_ITEM_TYPE_DELETE}${id}`, options);
        getItemTypes();
      } catch (err) {
        console.log("err occur in del rate template row ", err);
        getItemTypes();
      }
    }
  };

  const putRateLinkingData = async (id) => {
    if (!RateTemplate.related_to || RateTemplate.related_to.length <= 0) {
      setRateLinkingError({ ...RateLinkingError, related_to: true });
      return;
    } else {
      setRateLinkingError({ ...RateLinkingError, related_to: false });
    }
    if (
      !RateTemplate.rate_difference ||
      RateTemplate.rate_difference.length <= 0
    ) {
      setRateLinkingError({ ...RateLinkingError, rate_difference: true });
      return;
    } else {
      setRateLinkingError({ ...RateLinkingError, rate_difference: false });
    }

    setFetchingRateLinking(true);
    new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `JWT ${props.user.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      axios
        .put(
          `${APIROUTES.DAILY_RATE_TEMPLATE_DETAILS}${id}/`,
          RateTemplate,
          options
        )
        .then((response) => {
          getRateLinking(options, id);
          props.history.goBack();
          resolve(response);
        })
        .catch((err) => {
          console.log("err ", err);
          getRateLinking(options, id);
          props.history.goBack();
        });
    });
  };

  const deleteRateLinking = async (id) => {
    setFetchingRateLinking(true);
    let currentRateTemplate = { ...RateTemplate };
    currentRateTemplate.related_to = null;
    currentRateTemplate.rate_difference = 0;
    new Promise((resolve, reject) => {
      const options = {
        headers: {
          Authorization: `JWT ${props.user.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      axios
        .put(
          `${APIROUTES.DAILY_RATE_TEMPLATE_DETAILS}${id}/`,
          currentRateTemplate,
          options
        )
        .then((response) => {
          getRateLinking(options, id);
          props.history.goBack();
          resolve(response);
        })
        .catch((err) => {
          console.log("err ", err);
          getRateLinking(options, id);
          props.history.goBack();
        });
    });
  };

  const changeCategoryName = async (
    id,
    newName,
    related_to,
    rate_difference
  ) => {
    let name = newName;
    if (newName.length <= 0) {
      name = RateTemplate?.category_name;
    }

    setFetchingRateTemplate(true);

    let data = {
      category_name: name,
      related_to: related_to,
      rate_difference: rate_difference,
    };
    try {
      if (user?.token) {
        const options = {
          headers: {
            Authorization: `JWT ${user.token}`,
          },
        };
        await axios.put(APIROUTES.MM_CREATE_NEW_CATEGORY + id, data, options);
        props.history.goBack();
        getCategoryDetails();
      }
    } catch (err) {
      console.log("err occured in handleSaveRateTemplate ", err);
      props.history.goBack();
      getCategoryDetails();
    }
  };

  if (isMobile) return <OpenInDesktop {...this.props} />;

  if (!user.is_admin && user.status !== "approved") {
    return <AccessDenied />;
  }
  return (
    <div>
      <ItemCategoryEditComponent
        FetchingItemType={FetchingItemType}
        FetchingRateTemplate={FetchingRateTemplate}
        FetchingRateLinking={FetchingRateLinking}
        RateTemplate={RateTemplate}
        ItemType={ItemType}
        RateLinking={RateLinking}
        handleRateTemplateTableEdit={handleRateTemplateTableEdit}
        deleteRateTemplateRow={deleteRateTemplateRow}
        handleSaveRateTemplate={handleSaveRateTemplate}
        RateTemplateEditError={RateTemplateEditError}
        handleItemTypeTableEdit={handleItemTypeTableEdit}
        handleSaveItemType={handleSaveItemType}
        ItemTypeEditError={ItemTypeEditError}
        handleDeleteItemTypeRow={handleDeleteItemTypeRow}
        handleRateLinkingEdit={handleRateLinkingEdit}
        AllCategories={AllCategories}
        putRateLinkingData={putRateLinkingData}
        RateLinkingError={RateLinkingError}
        handleRateTemplateTableEditStar={handleRateTemplateTableEditStar}
        deleteRateLinking={deleteRateLinking}
        changeCategoryName={changeCategoryName}
        {...props}
      />
    </div>
  );
};

export default ItemCategoryEdit;
