import React, { Component } from 'react'
import { Button, makeStyles } from "@material-ui/core";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Print from "@material-ui/icons/Print";
import Tooltip from "@mui/material/Tooltip";
import Moment from 'moment'
import imageCode from "./ImageCode.json"
import image from "../../../../styles/icon.png"
import { plantTableHeaders } from '../headers'
import { printPDFInMobile } from '../../../../methods';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
})

export class ReportsPDFDownload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tableData: [],
            tabValue: "",
        }
    }

    fetchHeaders = (pdfProps) => {
        let tableHeaders = [{
            text: "#",
            style: ["itemsHeader", "center"],
            border: [false, false, false, true],
        }]
        let headers = []
        // if (pdfProps.tabValue === 1)
        //     headers = plantTableHeaders(pdfProps.tabValue, pdfProps?.datafor, "pdf")
        // else
        headers = plantTableHeaders(pdfProps.tabValue, pdfProps?.datafor)
        console.log(headers)
        headers.map(head => {
            tableHeaders.push({
                text: head.header,
                style: ["itemsHeader", "center"],
                border: [false, false, false, true],
            })
        })

        // console.log(tableHeaders)
        if (headers) {
            this.handlePDFDownload(pdfProps, tableHeaders, headers)
        }
    }
    handlePDFDownload = (pdfProps, tableHeaders, headers) => {
        // console.log("Download Started......", pdfProps)
        console.log(pdfProps)
        const { tabValue } = pdfProps
        let tableDetails = []
        let tableData = []

        // total width in landscape = 660
        let columnWidths = []
        let table2Widths = []
        let tableData2 = []
        let headerColumnWidths = ["50%", "50%"]
        tableDetails.push([
            {
                text: `Report name - ${pdfProps.reportName}`,
                style: "leftAlignDetails",
                border: [false, false, false, false],
            },
            {
                text: `Date Range - From ${(Moment(pdfProps.startDate).format('DD-MMM-yyyy'))} To ${(Moment(pdfProps.endDate).format('DD-MMM-yyyy'))}`,
                style: "rightAlignDetails",
                border: [false, false, false, false],
            },
        ])

        tableDetails.push([
            {
                text: `Date of Generation - ${(Moment(new Date()).format('DD-MMM-yyyy'))}`,
                style: "leftAlignDetails",
                border: [false, false, false, false],
            },
            {
                text: `User Name - ${pdfProps.user.first_name} ${pdfProps.user.last_name}`,
                style: "rightAlignDetails",
                border: [false, false, false, false],
            },
        ])
        // tableDetails.push([
        //     {
        //         text: pdfProps?.state?.calculations?.total_qty ? `Total Quantity - ${parseFloat(pdfProps?.state?.calculations?.total_qty).toFixed(3)} MT` : "",
        //         style: "leftAlignDetails",
        //         border: [false, false, false, false],
        //     },
        //     {
        //         text: ``,
        //         style: "rightAlignDetails",
        //         border: [false, false, false, false],
        //     },
        // ])

        // tableData.push(tableHeaders)
        columnWidths = tabValue === 0 ? [15, 50, 20, 50, 45, 45, 45, 30, 30, 45, 45, 30, 27, 35, 35, 40, 40, 35, 35] :
            tabValue === 1 ? [10, 30, 40, 40, 35, 35, 35, 30, 30, 35, 40, 35, 35, 30, 30, 30, 30, 30, 40] :
                tabValue === 2 ? [15, 30, 24, 45, 40, 35, 40, 40, 35, 40, 40, 35, 40, 40, 40, 35, 40, 40] :
                    tabValue === 3 ? [15, 30, 50, 40, 50, 80, 80, 70, 60, 60, 55, 50, 25, 30] :
                        tabValue === 4 ? [15, 40, 50, 70, 70, 70, 70, 60, 65, 60, 40, 40] :
                            []
        // console.log(tableData)


        // if (pdfProps.tabValue === 0) {
        //     columnWidths = [15, 50, 30, 50, 50, 50, 50, 50, 55, 50, 40, 45, 40, 40, 40]
        tableData.push([
            {
                text: "#",
                border: [false, false, false, true],
                style: ["itemsHeader", "center"],
            },
        ])
        headers.forEach(head => {
            // console.log(tableData.length)
            tableData[0].push(
                {
                    text: head.header,
                    style: ["itemsHeader", "center"],
                    border: [false, false, false, true],
                }
            )
        })
        pdfProps.data.map((item, i) => {
            tableData.push([
                {
                    text: i + 1,
                    border: [false, false, false, true],
                    style: ["tableItems", "center"],
                },
            ])
            if (i === pdfProps.data.length - 1) {
                tableData.push([
                    {
                        text: "",
                        border: [false, false, false, true],
                        style: ["tableItems", "center"],
                    },
                ])
            }
            // if (i === pdfProps.data.length - 1 && tabValue === 3) {

            // } else {
            headers.forEach(head => {
                // console.log(tableData.length)
                tableData[i + 1].push(
                    {
                        text: item[head.key],
                        style: ["tableItems", "center"],
                        border: [false, false, false, true],
                    }
                )
                if (i === pdfProps.data.length - 1) {
                    if (head.calculation) {
                        tableData[i + 2].push({
                            text: pdfProps?.state?.calculations[head.calculation] || pdfProps?.state?.calculations[head.calculation] === 0 ? `${head.calc_text} = ${pdfProps?.state?.calculations[head.calculation]}` : "",
                            style: ["tableItems", "center"],
                            border: [false, false, false, true],
                        })

                    } else {
                        tableData[i + 2].push({
                            text: "",
                            style: ["tableItems", "center"],
                            border: [false, false, false, true],
                        })
                    }
                }
            })
            // }
            // console.log(tableData)
        })
        // }

        // console.log(pdfProps)
        // console.log(tableData)
        const dd = this.docdef(pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths, tabValue)

        // pdfProps.tabValue === 1 ?
        // this.contractsDocdef(pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths) :
        const date = new Date()
        const reportName = `${pdfProps.reportName}_${Moment(date).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`
        if (!window.cordova) {
            console.log("herere3", reportName)
            pdfMake
                .createPdf(dd)
                .open()
            // .download(reportName, pdfProps.handleOpenSnackClick());
        }
        else {
            let fileName = `${pdfProps.reportName}`
            printPDFInMobile(pdfMake, dd, fileName, pdfProps.handleOpenSnackClick, '')
        }
    }

    // contractsDocdef = (pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths) => {
    //     // console.log("called")
    //     return {
    //         // pageSize: "A2",
    //         pageOrientation: 'landscape',
    //         header: function (currentPage) {
    //             // console.log(pdfProps.tabValue)
    //             if (currentPage === 1)
    //                 return {
    //                     columns: [
    //                         {
    //                             image: imageCode,
    //                             width: 50,
    //                             height: 50,
    //                             margin: [10, 0, 0, 0]
    //                         }
    //                     ]
    //                 }

    //         },
    //         footer: function (currentPage, pageCount) {
    //             return {
    //                 table: {
    //                     widths: ["*"],
    //                     body: [
    //                         [
    //                             // { text: 'Confidential', alignment: 'center' },
    //                             { text: 'page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 5, 35, 0], fontSize: 7, }
    //                         ]
    //                     ]
    //                 },
    //                 layout: 'noBorders'
    //             };
    //         },
    //         // footer: {
    //         //     text: "Footer",
    //         //     alignment: "center"
    //         // },

    //         content:
    //             // function (currentPage) {
    //             //     console.log(pdfProps.tabValue)
    //             //     if (currentPage === 1)
    //             //         return 
    //             [
    //                 "full width ccsaiuobjh unhje hemkbj hmbj ndbncmhj sdbnjhvdsm b jmsmf",
    //                 {
    //                     // style: 'tableExample',
    //                     table: {
    //                         body: tableDetails,
    //                         widths: headerColumnWidths,
    //                         dontBreakRows: true,
    //                     },
    //                     margin: [0, 15, 0, 0]
    //                 }, {
    //                     // style: 'tableExample',
    //                     table: {
    //                         body: tableData,
    //                         widths: columnWidths,
    //                         dontBreakRows: true,

    //                     },
    //                     margin: [0, 15, 0, 0],
    //                     pageBreak: "after",
    //                 },
    //                 {
    //                     // style: 'tableExample',
    //                     table: {
    //                         body: tableData2,
    //                         widths: table2Widths,
    //                         dontBreakRows: true,
    //                     },
    //                     margin: [0, 15, 0, 0],
    //                 },

    //             ],

    //         styles: {
    //             itemsHeader: {
    //                 padding: [0, 2, 0, 2],
    //                 // fontSize: 8,
    //                 fontSize: 6,
    //                 bold: true,
    //                 fillColor: "#f5f5f5",
    //                 alignment: "center",
    //             },
    //             tableItems: {
    //                 padding: [0, 2, 0, 2],
    //                 fontSize: 5,
    //                 alignment: "center",

    //             },
    //             leftAlignDetails: {
    //                 alignment: "left",
    //                 // margin: [0, 15, 0, 0],
    //                 fontSize: 10,
    //             },
    //             rightAlignDetails: {
    //                 alignment: "right",
    //                 // margin: [0, 15, 0, 0],
    //                 fontSize: 10,
    //             }

    //         },
    //     };
    // }

    docdef = (pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths, tabValue) => {
        // console.log("called")
        return {
            // pageSize: "A2",
            pageOrientation: 'landscape',
            header: function (currentPage) {
                // console.log(imageCode)
                if (currentPage === 1)
                    return {
                        columns: [
                            {
                                image: imageCode,
                                width: 50,
                                height: 50,
                                margin: [10, 0, 0, 0]
                            }
                        ]
                    }
            },
            // footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
            footer: function (currentPage, pageCount) {
                return {
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                // { text: 'Confidential', alignment: 'center' },
                                { text: 'page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 5, 35, 0], fontSize: 7, }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                };
            },

            content:
                // function (currentPage) {
                //     console.log(pdfProps.tabValue)
                //     if (currentPage === 1)
                //         return 
                [
                    // "dbuhgieckhn iojhmwgmni fkjkenbfk jnemfnkujen .c",
                    {
                        // style: 'tableExample',
                        table: {
                            body: tableDetails,
                            widths: headerColumnWidths,
                            dontBreakRows: true,
                        },
                        margin: [0, 10, 0, 0]
                    },
                    {
                        // style: 'tableExample',s
                        table: {
                            body: tableData,
                            widths: columnWidths,
                            dontBreakRows: true,

                        },
                        margin: tabValue === 2 ? [-30, 10, 0, 0] : tabValue === 3 ? [-30, 10, 0, 0] : [0, 10, 0, 0],
                        // pageBreak: "after",
                    },
                    // {
                    //     style: 'tableExample',
                    //     table: {
                    //         body: tableData2,
                    //         widths: table2Widths,
                    //         dontBreakRows: true,
                    //     },
                    //     margin: [0, 15, 0, 0],
                    // },


                    // }
                    ,
                    // {
                    //     style: 'tableExample',
                    //     table: {
                    //         body: tableData,
                    //         widths: columnWidths,
                    //         dontBreakRows: true,

                    //     },
                    //     margin: [0, 15, 0, 0],
                    //     pageBreak: "after",
                    // },
                ],
            // pdfProps.tabValue === 1 || pdfProps.tabValue === 2 || pdfProps.tabValue === 3 &&

            styles: {
                itemsHeader: {
                    padding: [0, 2, 0, 2],
                    // fontSize: 8,
                    fontSize: 6,
                    bold: true,
                    fillColor: "#f5f5f5",
                    alignment: "center",
                },
                tableItems: {
                    padding: [0, 2, 0, 2],
                    fontSize: 5,
                    alignment: "center",

                },
                leftAlignDetails: {
                    alignment: "left",
                    // margin: [0, 15, 0, 0],
                    fontSize: 10,
                },
                rightAlignDetails: {
                    alignment: "right",
                    // margin: [0, 15, 0, 0],
                    fontSize: 10,
                }

            },
        };
    }
    render() {
        // console.log(code)
        // const classes = useStyles()
        return (
            <Tooltip title="Download PDF" placement="top">
                <div>
                    {/* <Button
                        onClick={() => this.handlePDFDownload(this.props)}
                        style={{ backgroundColor: 'green', width: "20px" }}
                    > */}
                    <Print onClick={() => {
                        this.fetchHeaders(this.props)
                    }}
                        style={{ color: "#4b87c5", cursor: "pointer", marginTop: 9, }} />
                    {/* </Button> */}

                </div>

            </Tooltip>
        )
    }
}

export default ReportsPDFDownload