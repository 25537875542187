import 'date-fns';
import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  secondaryBlueText,
  earthBlue,
  drawerBackground,
  secondaryGrey,
} from '../../../../constants/internal/colors';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});
function DatePickerComponentPastRateEndDate({
  handleDateChange,
  displayDate,
  startDate,
}) {
  return (
    <Fragment>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <KeyboardDatePicker
              autoOk
              size="small"
              variant="inline"
              format="dd/MM/yyyy"
              value={displayDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputAdornmentProps={{ position: 'start' }}
              InputProps={{ size: 'small', disableUnderline: true }}
              onChange={handleDateChange('endDate')}
              minDate={startDate}
              maxDate={new Date()}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Fragment>
  );
}
export default DatePickerComponentPastRateEndDate;
