import React, { Component } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import UnLock from "@material-ui/icons/LockOpen";

class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { brokerageError, new_brokerage, updateBrokerage, brokerage, isMobile } =
      this.props;
    return (
      <div style={{ width: '100%' }}>
        <TextField
          name="brokerage"
          style={{ width: "100%" }}
          label="Brokerage"
          margin="normal"
          variant="outlined"
          placeholder="Enter Brokerage Amount"
          value={new_brokerage}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, "");
            this.props.handleTextChangeBrokerage({
              target: { name: "brokerage", value },
            });
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ fontSize: "0.9em", color: "grey" }}>₹</span>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="start"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.handleUnlockBrokerage();
                }}
              >
                {updateBrokerage ? <UnLock /> : <Lock />}
              </InputAdornment>
            ),
          }}
          disabled={!updateBrokerage}
          error={brokerageError}
          helperText={brokerageError ? brokerageError : null}
        />
      </div>
    );
  }
}
export default InputField;
