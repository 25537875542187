import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Info from "@material-ui/icons/Info";
import {
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from "@material-ui/core";
import { grey } from "../../../constants/internal/colors";
import { withRouter } from "react-router";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class ContractsCustomersTable extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

ContractsCustomersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const ContractsCustomersTableWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(ContractsCustomersTable);

let counter = 0;
function createData(
  vrNumber,
  vrDate,
  gateIn,
  gateOut,
  totalCapacity,
  laQuantity,
  loaded,
  status
) {
  counter += 1;
  return {
    id: counter,
    vrNumber,
    vrDate,
    gateIn,
    gateOut,
    totalCapacity,
    laQuantity,
    loaded,
    status,
  };
}

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    paddingTop: "0px",
  },
  tableContainerStyle: {
    // height: "44vh",
    // maxHeight: "44vh",
    // overflowY: "scroll",
  },
  container: {
    maxHeight: "45vh",
  },
  tableCellStyle: {
    color: grey,
    width: "12%",
    fontSize: "0.8em",
    fontWeight: "bold",
  },
  tableCellValueStyle: {
    padding: "14px",
    color: grey,
  },
});

class ItemTableList extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 10,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  render() {
    const { classes, plantItemDetails } = this.props;
    const { rows, rowsPerPage, page } = this.state;

    return (
      <Paper className={classes.root} style={{ boxShadow: "none" }}>
        <div className={classes.tableWrapper}>
          <TableContainer className={classes.tableContainerStyle} id="scroll">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      width: "10%",
                      color: grey,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                    }}
                  >
                    Serial No.
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: "25%",
                      color: grey,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                    }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    HSN/SAC Code
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Quantity
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Unit
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Net Rate
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Taxable Value
                  </TableCell>
                  {/* <TableCell align="center" className={classes.tableCellStyle}>
                    Total Amount
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {plantItemDetails &&
                  plantItemDetails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        style={{ cursor: "pointer" }}
                        key={row.id}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            `/internalContracts/singleContracts/${row.id}`
                          );
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.SERIAL_NO}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.PRODUCTNAME}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.HSNCODE}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.QUANTITY}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.QTYUNIT}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.DISCOUNT_RATE}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          {row.TAXABLEVALUE}
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          className={classes.tableCellValueStyle}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {row.TOTALVALUE}
                          </div>
                        </TableCell> */}
                      </TableRow>
                    ))}
              </TableBody>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell
                    align="center"
                    style={{
                      width: "10%",
                      color: grey,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: "25%",
                      color: grey,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCellStyle}
                  ></TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCellStyle}
                  >{this.props.total_quantity}</TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCellStyle}
                  >{plantItemDetails && plantItemDetails.length > 0 ? plantItemDetails[0].QTYUNIT : ""}</TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCellStyle}
                  >{plantItemDetails && plantItemDetails.length > 0 ? plantItemDetails[0].DISCOUNT_RATE : ""}</TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    {this.props.total_taxable_value}
                  </TableCell>
                  {/* <TableCell align="center" className={classes.tableCellStyle}>
                    {this.props.total_invoice_value}
                  </TableCell> */}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={ContractsCustomersTableWrapped}
            component="div"
          /> */}
        </div>
      </Paper>
    );
  }
}

ItemTableList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ItemTableList));
