import React, { Component, createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  secondaryColor,
  bottomNavigationFontColor,
  errorMessageColor,
  logoBackground,
} from "../../../styles/colors";
import DailyRatesCard from "./DailyRatesCard";
import PendingActionList from "./PendingActionList";
import { withRouter } from "react-router";
import moment from "moment";
import PendingDispatches from "./PendingDispatches";
import SpinLoader from "../../common/SpinLoader";
import backStack from "cordova-back-stack";
import TileSkeleton from "../common/TileSkeleton";
import { pxToEm } from "../../../methods";
import "../../../styles/b2b/Banner.css"
import InvoiceList from "../orders/InvoiceList";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ScrollTop from "../common/ScrollTop";

const styles = {
  homeContainer: {
    height: "100%",
  },
  headerContainer: (isMobile) => ({
    height: "14vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: isMobile ? "90%" : "98%",
  }),
  headerContainerLeft:
  {
    display: "flex",
    flexDirection: "column",
    // width: isMobile ? "78%" : "90%"
  }
  ,
  welcomeText: { fontWeight: "600", fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.2em" },
  nameTextStyle: {
    color: secondaryColor,
    textDecoration: "underline",
    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.2em",
  },
  headerContainerRight: (isMobile) => ({
    height: "60%",
    // width: isMobile ? "22%" : "10%",
    float: "right",
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    transform: 'scale(0.7)'
    // boxShadow:
    //   "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  }),
  dailyRatesLastUpdatesMessageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  PendingDispatchesBoxContainer: (isMobile) => ({
    display: "flex",
    flexDirection: 'column',
    alignItems: isMobile ? 'center' : 'flex-start',
    justifyContent: "center",
    width: '100%',
    borderRadius: 12,
    padding: isMobile ? "10px 6px" : "10px 10px 20px 10px",
  }),
  dailyRatesMessage: (isMobile) => ({
    width: isMobile ? "90%" : "98%",
    display: "flex",
    flexDirection: "column",
  }),
  dailyRatesShowContainer: (isMobile) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: isMobile ? "space-around" : "end",
    padding: "0px 6px",
    marginBottom: "18px",
  }),
  pendingActionContainer: (isMobile) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10px",
    width: isMobile ? "100%" : "50%",
    marginBottom: "22px",
    // maxHeight: window.innerHeight - 190,
    // height: window.innerHeight - 190,
    // overflowY: "scroll",
  }),
  orderListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: window.innerHeight - 190,
    height: window.innerHeight - 190,
    overflowY: "scroll",
  },

  pendingDispatchesContainer: (isMobile) => ({
    width: isMobile ? "100%" : "50%",
    display: "flex",
    justifyContent: "center",
  }),
  plantNameStyle: {
    margin: "10px 0px",
    color: secondaryColor,
    // fontWeight: "bold",
  },
  plantNameValueStyle: {
    display: "flex",
    justifyContent: "space-between",
    margin: "4px 0px",
  },
};

const myOverdueCardData = [
  { label: 'overdue', key: 'due_amount' },
  { label: 'not due yet', key: 'paid_amount' },
  { label: 'total due', key: 'total_amount' },
]

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      pendingActionList: [],
    };
    this.addNewEndRef = createRef(null)
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }

  UNSAFE_componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      if ((String(window.location.pathname)).includes('home/createOrder')) {
        this.props.history.push("/home")
      }
      else if (String(localStorage.getItem("returnUrl")) === '/home' && String(localStorage.getItem("currentUrl")) === '/home') {
        // this.props.history.push("/home");
        window.navigator.app.exitApp();
      }
      else {
        this.props.history.push(String(localStorage.getItem("returnUrl")));
      }
    });
  }

  componentDidMount() {
    // this.appUpdateCheck();
    // if (window?.cordova) {
    //   if (window?.AppUpdate) {
    //     window?.AppUpdate?.needsUpdate(function (appUpdateObj) {
    //       if (appUpdateObj.update_available == 1) {
    //         // App Update Detected
    //         var appUpdateMsg = "Update Available !\n Redirecting to update page...";
    //         if (appUpdateObj.force_update == 1) {
    //           appUpdateMsg = `${appUpdateMsg}`;
    //           // window.open('https://play.google.com/store/apps/details?id=com.realdeals.gktmt', 'system')
    //         }
    //         // this.UpdateDialog()
    //         alert("Update Available !\nPlease update for better experience")
    //         if (window?.cordova?.platformId !== 'android') {
    //           let options = "location=no,hidden=no,hidenavigationbuttons=yes,hideurlbar=yes";
    //           let inAppBrowserRef = window.cordova?.InAppBrowser?.open("https://apps.apple.com/in/app/real-deals-gk-tmt/id1521663199", `_system`)
    //           inAppBrowserRef.addEventListener('loadstart', function () {
    //             return (
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "center",
    //                 }}
    //               >
    //                 <SpinLoader />
    //                 <h4> Loading...</h4>
    //               </div>
    //             )
    //           });
    //           inAppBrowserRef.addEventListener('loadstop', function () {
    //             inAppBrowserRef.show()
    //           });
    //           inAppBrowserRef.addEventListener('loaderror', function (params) {
    //             alert(`Sorry we cannot open that page. Error : ${params.message}  `)
    //           })
    //         }
    //         else {
    //           // this.showWebPage()
    //           let options = "location=no,hidden=no,hidenavigationbuttons=yes,hideurlbar=yes";
    //           let inAppBrowserRef = window.cordova?.InAppBrowser?.open("https://play.google.com/store/apps/details?id=com.realdeals.gktmt", '_system')
    //           inAppBrowserRef.addEventListener('loadstart', function () {
    //             return (
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "center",
    //                 }}
    //               >
    //                 <SpinLoader />
    //                 <h4> Loading...</h4>
    //               </div>
    //             )
    //           });
    //           inAppBrowserRef.addEventListener('loadstop', function () {
    //             inAppBrowserRef.show()
    //           });
    //           inAppBrowserRef.addEventListener('loaderror', function (params) {
    //             alert(`Sorry we cannot open that page. Error : ${params.message}  `)
    //           })
    //         }
    //       } else {
    //         // NO App Update Detected
    //         // alert("No App Update 2 Available");
    //       }
    //     }, function (error) {
    //       // alert("App Update 2 ERROR:", error);
    //     });
    //   }
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subscribedDailyRates !== this.props.subscribedDailyRates) {
      let data = { is_forced_update: false, version: 1 }
      this.appUpdateCheck(this.props.subscribedDailyRates[0])
    }
    // if (window) {
    //   const scrollToTopButton = document.getElementById('scroll-to-top');
    //   console.log(window)
    //   // Show button when user scrolls down
    //   window.addEventListener('scroll', () => {
    //     console.log("heree", window.pageYOffset)
    //     if (window.pageYOffset > 100) {
    //       scrollToTopButton.style.display = 'block';
    //     } else {
    //       scrollToTopButton.style.display = 'none';
    //     }
    //   });
    // }
  }

  // scrollToTop = () => {
  //   console.log("clicked")
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // }

  appUpdateCheck = (data) => {
    if (window?.cordova) {
      if (window?.AppUpdate) {
        window?.AppUpdate?.needsUpdate(function (appUpdateObj) {
          if (appUpdateObj.update_available == 1) {
            let appUpdateMsg = "Update Available !\nPlease update for better experience";
            if (appUpdateObj.force_update == 1) {
              appUpdateMsg = `${appUpdateMsg}`;
            }
            // alert("Update Available !\nPlease update for better experience")
            if (data?.is_forced_update) {
              alert(appUpdateMsg)
              if (window?.cordova?.platformId !== 'android') {
                let options = "location=no,hidden=no,hidenavigationbuttons=yes,hideurlbar=yes";
                let inAppBrowserRef = window.cordova?.InAppBrowser?.open("https://apps.apple.com/in/app/real-deals-gk-tmt/id1521663199", `_system`)
                inAppBrowserRef.addEventListener('loadstart', function () {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SpinLoader />
                      <h4> Loading...</h4>
                    </div>
                  )
                });
                inAppBrowserRef.addEventListener('loadstop', function () {
                  inAppBrowserRef.show()
                });
                inAppBrowserRef.addEventListener('loaderror', function (params) {
                  alert(`Sorry we cannot open that page. Error : ${params.message}  `)
                })
              }
              else {
                // this.showWebPage()
                let options = "location=no,hidden=no,hidenavigationbuttons=yes,hideurlbar=yes";
                let inAppBrowserRef = window.cordova?.InAppBrowser?.open("https://play.google.com/store/apps/details?id=com.realdeals.gktmt", '_system')
                inAppBrowserRef.addEventListener('loadstart', function () {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SpinLoader />
                      <h4> Loading...</h4>
                    </div>
                  )
                });
                inAppBrowserRef.addEventListener('loadstop', function () {
                  inAppBrowserRef.show()
                });
                inAppBrowserRef.addEventListener('loaderror', function (params) {
                  alert(`Sorry we cannot open that page. Error : ${params.message}  `)
                })
              }
            }
            else if (window?.confirm(appUpdateMsg)) {
              if (window?.cordova?.platformId !== 'android') {
                let options = "location=no,hidden=no,hidenavigationbuttons=yes,hideurlbar=yes";
                let inAppBrowserRef = window.cordova?.InAppBrowser?.open("https://apps.apple.com/in/app/real-deals-gk-tmt/id1521663199", `_system`)
                inAppBrowserRef.addEventListener('loadstart', function () {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SpinLoader />
                      <h4> Loading...</h4>
                    </div>
                  )
                });
                inAppBrowserRef.addEventListener('loadstop', function () {
                  inAppBrowserRef.show()
                });
                inAppBrowserRef.addEventListener('loaderror', function (params) {
                  alert(`Sorry we cannot open that page. Error : ${params.message}  `)
                })
              }
              else {
                // this.showWebPage()
                let options = "location=no,hidden=no,hidenavigationbuttons=yes,hideurlbar=yes";
                let inAppBrowserRef = window.cordova?.InAppBrowser?.open("https://play.google.com/store/apps/details?id=com.realdeals.gktmt", '_system')
                inAppBrowserRef.addEventListener('loadstart', function () {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SpinLoader />
                      <h4> Loading...</h4>
                    </div>
                  )
                });
                inAppBrowserRef.addEventListener('loadstop', function () {
                  inAppBrowserRef.show()
                });
                inAppBrowserRef.addEventListener('loaderror', function (params) {
                  alert(`Sorry we cannot open that page. Error : ${params.message}  `)
                })
              }
            }
            else {
              // nothing
            }
          } else {
            // NO App Update Detected
            // alert("No App Update Available");
          }
        }, function (error) {
          // alert("App Update 2 ERROR:", error);
        });
      }
    }
  }

  payload = () => {
  }

  render() {
    const { } = this.state;
    const {
      classes,
      isMobile,
      subscribedDailyRates,
      subscribedDailyRatesLoader,
      dailyRatesLastUpdatedAt,
      pendingDispatchList,
      pendingActionList,
      orders,
      pendingActionLoader,
      user,
      fetchItemCategoriesPaymentDiscount,
      bannerData,
      invoicesList,
      invoiceLoader,
      invoicesPaymentDetail,
    } = this.props;
    // console.log(this.props)
    // console.log(this.state)
    return (
      <div className={classes.homeContainer} style={{ paddingTop: !isMobile && 20 }}>
        <ScrollTop type="RefDiv" ref={this.addNewEndRef} />
        {isMobile ? (
          <div style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <div style={styles.headerContainer(isMobile)}>
              <div className={classes.headerContainerLeft}
                style={{
                  width: isMobile ? "78%" : "90%"
                }}
              >
                <span className={classes.welcomeText}>Welcome</span>
                <span
                  className={classes.nameTextStyle}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/b2bProfile");
                  }}
                >
                  {user.full_name}

                </span>
              </div>
              <div style={styles.headerContainerRight(isMobile)}>
                <img
                  src={
                    window.cordova
                      ? `${this.image}${"/gk.png"}`
                      : "/gk.png"
                  }
                  height="50px"
                  width="auto"
                  // style={{ height: "50%", width: "auto", objectFit: "cover", marginRight: 10 }}
                  alt="img"
                />
                <img
                  src={
                    window.cordova
                      ? `${this.image}${"/realwire.png"}`
                      : "/realwire.png"
                  }
                  // style={{ objectFit: "cover" }}
                  height="50px"
                  width="auto"
                  style={{ marginLeft: "10px" }}
                  alt="img"
                />
              </div>
            </div>
          </div>
        ) : null}
        <>
          {bannerData?.length > 0 &&
            <div className="marqueeInnerDiv"
              style={{
                maxWidth: '100%',
                background: bannerData?.background_color || secondaryColor,
                padding: '10px 1px 5px 1px',
                marginBottom: 10,
                overflow: 'hidden'
              }}
            >
              <div className="marqueeText"
                style={{
                  color: bannerData?.text_color || "#FFF",
                  whiteSpace: 'nowrap',
                  paddingTop: 2,
                  paddingBottom: 7,
                }}
              >
                {bannerData[0].news}
              </div>
            </div>
          }

          <div className={classes.dailyRatesLastUpdatesMessageContainer}>
            <div style={styles.dailyRatesMessage(isMobile)}>
              <span>Rates</span>
              <span style={{ color: bottomNavigationFontColor, fontSize: window.innerWidth < 375 && pxToEm(13) }}>
                {dailyRatesLastUpdatedAt && dailyRatesLastUpdatedAt ? (
                  <span>
                    {this.props.dailyRatesLastUpdatedAt.length === 0 ? null : (
                      <>
                        <span>Last updated at </span>
                        <span>
                          {moment(
                            dailyRatesLastUpdatedAt &&
                            dailyRatesLastUpdatedAt[0].modified_at
                          ).format("MMMM Do YYYY, h:mm a")}
                        </span>
                      </>
                    )}
                  </span>
                ) : null}
              </span>
            </div>
          </div>
        </>
        <div style={styles.dailyRatesShowContainer(isMobile)}>
          <DailyRatesCard
            {...this.props}
            isMobile={isMobile}
            subscribedDailyRates={subscribedDailyRates}
            subscribedDailyRatesLoader={subscribedDailyRatesLoader}
            dailyRatesLastUpdatedAt={dailyRatesLastUpdatedAt}
            fetchItemCategoriesPaymentDiscount={fetchItemCategoriesPaymentDiscount}
          />
        </div>
        {
          pendingActionLoader ?
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px 6px' }}>
              <TileSkeleton skeletonType='pending actions' tileLength={1} />
            </div>
            :
            pendingActionList.length > 0 &&
            <>
              <div className={classes.dailyRatesLastUpdatesMessageContainer}>
                <div style={styles.dailyRatesMessage(isMobile)}>
                  <span>My Approvals</span>
                  <span style={{ color: bottomNavigationFontColor, fontSize: window.innerWidth < 375 && pxToEm(13) }}>
                    The following contracts need your attention
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  // marginBottom: "34px"
                }}
              >
                <div style={styles.pendingActionContainer(isMobile)}>
                  {
                    pendingActionList.length > 0 ?
                      pendingActionList.map((data) => (

                        <PendingActionList
                          status={data.order_status}
                          orderId={data.order_number}
                          date={data.created_at}
                          name={data.item_category.category_name}
                          weight={data.quantity}
                          isMobile={isMobile}
                          order={data}
                          netRate={data?.net_rate}
                        />
                      )) : <span style={{ marginTop: 30, color: "#8B959A" }}>
                        No pending actions available
                      </span>
                  }
                </div>

              </div>
            </>
        }
        <div style={styles.PendingDispatchesBoxContainer(isMobile)}>
          <div
            style={{
              width: isMobile ? "98%" : "50vw",
              padding: '10px 6px',


            }}
          >
            {this.props.pendingDispatchListLoader ?
              <>
                <div style={styles.dailyRatesMessage(isMobile)}>
                  <span style={{ paddingLeft: 5 }}>My Live Contracts</span>
                </div>
                <div style={{
                  marginTop: "30px",
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '10px 6px',
                  borderRadius: 12,
                  backgroundColor: '#FFF',
                  boxShadow:
                    "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
                }}
                >
                  <div style={{ width: '95%' }}>
                    <TileSkeleton skeletonType='pending dispatches' />
                  </div>
                </div>
              </>
              :
              this.props?.pendingItemCategories && this.props?.pendingItemCategories?.length > 0 &&
              <>
                <div style={styles.dailyRatesMessage(isMobile)}>
                  <span style={{ paddingLeft: 5 }}>My Live Contract</span>
                </div>
                <PendingDispatches
                  {...this.props}
                  isMobile={isMobile}
                />
              </>
            }
          </div>
        </div>
        {
          invoiceLoader ?
            <>
              <div className={classes.dailyRatesLastUpdatesMessageContainer}>
                <div style={styles.dailyRatesMessage(isMobile)}>
                  <span>My Overdues</span>
                  <span style={{ color: bottomNavigationFontColor, fontSize: window.innerWidth < 375 && pxToEm(13) }}>
                    If you have made the payment recently, it might takes 1-2 days to update here
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px 6px' }}>
                <TileSkeleton skeletonType='invoices' tileLength={3} />
              </div>
            </>
            :
            (invoicesList && invoicesList.length > 0) &&
            <>
              {console.log(invoicesList, this.props)}
              <div className={classes.dailyRatesLastUpdatesMessageContainer}>
                <div style={styles.dailyRatesMessage(isMobile)}>
                  <span>My Overdues</span>
                  <span style={{ color: bottomNavigationFontColor, fontSize: window.innerWidth < 375 && pxToEm(13) }}>
                    If you have made the payment recently, it might takes 1-2 days to update here
                  </span>
                </div>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: window.innerWidth > 768 ? "65%" : '100%',
                margin: '20px 0 10px 0'
              }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: window.innerWidth < 768 ? '90%' : '96%',
                    paddingLeft: '0px',
                    justifyContent: window.innerWidth < 385
                      ? 'center'
                      : window.innerWidth < 768
                        ? 'space-between'
                        : 'flex-start',
                    alignItems: 'center',
                    '& > :not(style)': {
                      height: window.innerWidth < 385 ? 75 : 85,
                      borderRadius: 2,
                      margin: window.innerWidth < 385
                        ? '0 10px 10px 0'
                        : window.innerWidth < 768
                          ? 0
                          : '3px'
                    },
                  }}
                >
                  {invoicesPaymentDetail && myOverdueCardData.map((d, i) => {
                    return (
                      <Paper
                        elevation={2}
                        style={{
                          width: window.innerWidth < 385
                            ? 130
                            : window.innerWidth < 768
                              ? '31%'
                              : 'max-content',
                        }}>
                        <div
                          style={{
                            textAlign: "start",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: window.innerWidth < 420 ? '15px 5px' : '15px 10px',
                          }}
                        >
                          <div style={{
                            fontSize: window.innerWidth < 385
                              ? pxToEm(19)
                              : window.innerWidth < 400
                                ? pxToEm(17)
                                : window.innerWidth < 480
                                  ? pxToEm(18)
                                  : pxToEm(21),
                            fontWeight: 600,
                            color: i === 0 && errorMessageColor,
                            letterSpacing: 0.8
                          }}>
                            ₹ {new Intl.NumberFormat('en-IN', { style: 'decimal' }).format(invoicesPaymentDetail[d.key])}
                          </div>
                          <div style={{
                            fontSize: pxToEm(14),
                            fontWeight: 400,
                            color: bottomNavigationFontColor,
                            paddingLeft: window.innerWidth < 480
                              ? 15
                              : 18,
                          }}>
                            {d.label}
                          </div>
                        </div>
                      </Paper>
                    )
                  })}
                </Box>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  // marginBottom: "34px"
                }}
              >
                <div style={styles.pendingActionContainer(isMobile)}>
                  {
                    invoicesList.length > 0 ?
                      <InvoiceList
                        {...this.props}
                        singleOrderInvoices={invoicesList}
                        handleInvoiceClick={this.props.handleInvoiceClick}
                        b2CQrImage={this.props.b2CQrImage}
                        handlePostInvoiceDownload={this.props.handlePostInvoiceDownload}
                        handleOpenSnackBar={this.props.handleOpenSnackBar}
                        laDetailsInDispatchCounter={this.props.laDetailsInDispatchCounter}
                        eInvoiceResponse={this.props?.eInvoiceResponse}
                        eWayBillResponse={this.props?.eWayBillResponse}
                        iseWaySelect={this.props?.iseWaySelect}
                        plant_state={this.props?.plant_state}
                        invoiceType={this.props?.invoiceType}
                        module="Home"
                      />
                      : <span style={{ marginTop: 30, color: "#8B959A" }}>
                        No overdues available
                      </span>
                  }
                </div>

              </div>
            </>
        }
        <div style={{ padding: '15px 0', margin: '15px 0' }} />
        <ScrollTop type="Button" ref={this.addNewEndRef} />
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Home));
