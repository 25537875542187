import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Checkbox,
  Paper,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Box,
  IconButton,
  Popover,
  Divider,
  Tooltip,
  CircularProgress,
  ThemeProvider,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TablePagination,
  Button
} from "@material-ui/core";
import { grey, secondaryBlueText, secondaryGrey, successButtonBackground, drawerTextColor } from "../../../../constants/internal/colors";

import ManageCustomerDealerDistributor from "./ManageCustomerDealerDistributor";
import ManageCustomerAccountAdditionalContacts from "./ManageCustomerAccountAdditionalContacts";
import ManageCustomerAccountAllowedPaymentMethod from "./ManageCustomerAccountAllowedPaymentMethod";
import ManageCustomerAccountSubcontractCreditLimit from "./ManageCustomerAccountSubcontractCreditLimit";
import ManageCustomerSubscriptionDetailsNewUi from "./ManageCustomerSubscriptionDetailsNewUi";
import ManageCustomerBuyerConsigneeDetailsNewUi from "./ManageCustomerBuyerConsigneeDetailsNewUi";
import ManageCustomerBrokerage from "./ManageCustomerBrokerage";
import { checkApiError, handleSnackbarMessageBox, hasApproveAccess, hasSpecialAccess, hasUpdateAccess, pxToEm } from "../../../../methods";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ToolTipComponent from "../../common/ToolTipComponent";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import moment from "moment";
import { primaryColor } from "../../../../styles/colors";
import ManageCustomerAccountCreditLimit from "./ManageCustomerAccountCreditLimit";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { createMuiTheme } from "@material-ui/core/styles";
import SnackbarComponent from "../../common/SnackbarComponent";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import InternalApiRoutes from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  formControlText: {
    width: "25%",
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  generalDetailsDisplaySection: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "5px 0px 5px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    width: "50%",
    marginRight: 10
  },
  paperStyles: { padding: 20 },
  generalSpacing: {
    paddingBottom: 20,
  },
  captionDetails: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    paddingBottom: 10,
  },
  customerTypeTitle: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
  },
  accountTypeCheckbox: {
    flexDirection: "column",
    width: "100%"

  },
  accountCodeLabelStyle: {
    fontSize: "13px",
    marginTop: "20px",
    display: "inline-block",
    width: "100%",
  },
  creditLimitCard: {
    marginTop: "5px",
    display: 'flex',
    width: 655,
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: 4,
    backgroundColor: '#FFF',
    // boxShadow:
    //   "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginTop: "12px",
  },
  tableBody: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(13),
  },
  saveCancelButton: {
    display: "flex",
    placeSelf: "strech",
    marginTop: "12px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    fontFamily: 'Poppins',
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "10px 5px 10px 5px",
      },
    },
  },
});

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      fontFamily: 'Poppins',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};



const GreenCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function ManageCustomerAccountDetails(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [itemCategoryList, setItemCategoryList] = useState(props.itemCategory);
  const [itemCategoryAPI, setItemCategoryAPI] = useState([props.customerItemCategory]);
  const [addDiscount, setAddDiscount] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarType, setSnackbarType] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedItemCategoryID, setSelectedItemCategoryID] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [data, setData] = useState({});
  const [addedCategories, setAddedCategories] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedRowIndex, setEditedRowIndex] = useState(null);

  let param1 = props.selectedCustomerId
  console.log(param1)

  useEffect(() => {
    // console.log("rendered")
    let param1 = props.selectedCustomerId;
    fetchItemCategoryCustomer(param1);
    if (props?.customerOperation === "add" || (props?.customerOperation === "edit" && !props?.selectedCustomerData?.credentials?.is_approved)) {
      props?.handleAccountTypeCheckboxValues("isDBoxChecked", true)
      if (props?.customerType === "DISTRIBUTOR" || props?.customerType === "BROKER") {
        props?.handleAccountTypeCheckboxValues("isKBoxChecked", true)
      }
    }

  }, [props?.customerOperation])

  const handleCreateDiscountItemCatWise = (data) => {
    const { POST_DISCOUNT_ITEMCAT: url } = InternalApiRoutes;
    return new Promise(async (resolve, reject) => {
      await axios
        .post(url, data)
        .then((response) => {
          setSnackbarMessage("Discount added successfully");
          setOpenSnackbar(true);
          setSnackbarType("success");
          resolve();
          // console.log(response);
        })
        .catch((e) => {
          setSnackbarMessage(handleSnackbarMessageBox(checkApiError(e?.response)));
          setOpenSnackbar(true);
          setSnackbarType("error");
          console.log(e);
          reject(e);
        });
    });
  }

  const handleEdit = (index, row) => {
    setDiscountAmount(row.discount_amount)
    setSelectedItemCategoryID(row.item_category_id)
    setIsEditing(true);
    setEditedRowIndex(index);
  };

  const handleUpdateDiscountItemCatWise = async (rowID, data) => {
    await axios
      .patch(`${InternalApiRoutes.GET_CUSTOMER_DISCOUNT}${rowID}/`, data)
      .then((res) => {
        setShowData(res.data);
        // let response = res.data
        // if(response.length > 0){
        //   let temp
        // }
        let result = res.data.map(a => {
          return a.item_category
        })

        setAddedCategories(result)
        // console.log(result)


      })
      .catch((rej) => {
        console.log(rej);
      });
  }

  const fetchItemCategoryCustomer = async (customerID) => {
    await axios
      .get(`${InternalApiRoutes.GET_CUSTOMER_DISCOUNT}?customer-id=${customerID}`)
      .then((res) => {
        setShowData(res.data);
        // let response = res.data
        // if(response.length > 0){
        //   let temp
        // }
        let result = res.data.map(a => {
          return a.item_category
        })

        setAddedCategories(result)
        // console.log(result)


      })
      .catch((rej) => {
        console.log(rej);
      });
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setSnackbarMessage(null);
    setSnackbarType("");
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const deleteDiscount = async (id) => {
    let param1 = props.selectedCustomerId;
    await axios
      .delete(
        `${InternalApiRoutes.DELETE_DISCOUNT_ITEM_CATEGORIES_LIST}${id}/`
      )
      .then((res) => {
        console.log(res);
        fetchItemCategoryCustomer(param1);
        setOpenSnackbar(true);
        setSnackbarMessage("Discount removed successfully");
        setSnackbarType("success");
      })
      .catch((rej) => {
        console.log(rej);
      });
  }

  // console.log(props.accountType[0].account_code)
  // console.log(props.customerDiscountError)
  console.log(props.customerType)
  // console.log(props?.isDBoxChecked, props?.isGBoxChecked, props?.isKBoxChecked)
  // console.log(props?.accountType)
  // console.log((!(props.accountType.find(
  //   (a) => a.account_type === "K"
  // )?.account_code)))
  return (
    <Paper className={classes.paperStyles}>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation} component={"span"}>
          Provide the general company related information.
        </Typography>
        <Typography className={classes.fillingInformation} component={"span"}>
          &nbsp;Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <div className={classes.dataDisplay}>
          <div>
            <Card
              sx={{
                width: 655,
                marginBottom: 2
              }}
            >
              <CardContent style={{ display: "flex", justifyContent: "space-between" }}>

                <div style={{ width: 330 }}>
                  {props.buyerPayment === "" ||
                    props.specifiedPerson === "" ||
                    props.declaration === "" ||
                    props.buyerTurnover === "" ? (
                    ""
                  ) :

                    <>


                      <div className={classes.dataFieldsForCheckbox} style={{ marginLeft: 20 }}>
                        <Typography className={classes.titleLabel} component={"span"}>
                          ACCOUNT ACTIVE
                        </Typography>
                        <ToolTipComponent
                          title="customer is rejected"
                          condition={props?.customerRejected}
                          title2="You have no approve permission on this widget"
                          condition2={props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")}
                        >
                          <GreenCheckbox
                            checked={props.accountVerify}
                            onChange={(evt) => {
                              props.handleCheckboxChange(evt);
                            }}
                            name="accountVerify"
                            style={{
                              color: (props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement"))
                                && 'rgba(0, 0, 0, 0.26)'
                            }}
                            disabled={
                              props.customerOperation === "view" ||
                              props?.customerRejected ||
                              (props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement"))
                            }
                          />
                        </ToolTipComponent>
                      </div>
                      <div className={classes.dataFieldsForCheckbox} style={{ marginLeft: 20 }}>
                        <Typography className={classes.titleLabel} component={"span"}>
                          ACCOUNT APPROVED
                        </Typography>
                        <ToolTipComponent
                          title="customer is rejected"
                          condition={props.customerOperation !== "view" && props?.customerRejected}
                          title2="You have no approve permission on this widget"
                          condition2={props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")}
                          title3="customer is approved"
                          condition3={props.customerOperation !== "view" && props.isAccountApproved}
                        >
                          <GreenCheckbox
                            checked={props.accountApproved}
                            onChange={(evt) => {
                              props.handleCheckboxChange(evt);
                            }}
                            name="accountApproved"
                            style={{
                              color: ((props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")) ||
                                (props.customerOperation !== "view" && props.isAccountApproved) ||
                                (props.customerOperation !== "view" && props?.customerRejected))
                                && 'rgba(0, 0, 0, 0.26)'
                            }}
                            disabled={
                              props.customerOperation === "view" ||
                              props?.customerRejected ||
                              props.isAccountApproved ||
                              (props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement"))
                            }
                          />
                        </ToolTipComponent>
                      </div>
                    </>
                  }


                  <div className={classes.dataFieldsForCheckbox} style={{ marginLeft: 20 }}>
                    <Typography className={classes.titleLabel} component={"span"}>
                      CUSTOMER APPROVAL NEEDED
                    </Typography>
                    <ToolTipComponent
                      title="customer is rejected"
                      condition={props?.customerRejected}
                      title2="You have no approve permission on this widget"
                      condition2={props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")}
                    >
                      <GreenCheckbox
                        checked={props.isCustomerApprovalNeeded}
                        onChange={(evt) => {
                          props.handleCheckboxChange(evt);
                        }}
                        name="isCustomerApprovalNeeded"
                        style={{
                          color: (props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement"))
                            && 'rgba(0, 0, 0, 0.26)'
                        }}
                        disabled={
                          props.customerOperation === "view" ||
                          props?.customerRejected ||
                          (props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement"))
                        }
                      />
                    </ToolTipComponent>
                  </div>
                </div>

              </CardContent>
              <CardActions></CardActions>
            </Card>
          </div>
          <div>
            <Card
              sx={{
                marginBottom: 2
              }}
            >
              <CardContent>
                <div style={{ width: 330 }}>
                  <div style={{ marginBottom: "20px" }}>
                    <Typography className={classes.titleLabel} component={"span"}>
                      TCS MATRIX -
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Typography className={classes.titleLabel}>
                      {"TCS Payment > 50L *"}
                    </Typography>
                    {props.customerOperation === "view" ? (
                      <Typography className={classes.formControlText}>
                        {props.buyerPayment}
                      </Typography>
                    ) : (

                      <FormControl
                        variant="outlined"
                        style={{
                          width: "50%"
                        }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <ToolTipComponent
                          title="You have no approve permission on this widget"
                          condition={props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")}
                        >
                          <Select
                            // labelId="demo-simple-select-filled-label"
                            // id="demo-simple-select-filled"
                            // value={props.customerType}
                            // disabled={props.customerOperation === "edit"}
                            // onChange={(evt) => props.handleCustomerTypeChange(evt)}
                            // MenuProps={MenuProps}
                            labelId="demo-simple-select-label"
                            value={props.buyerPayment}
                            label="Select"
                            onChange={props.handleChange("buyerPayment")}
                            style={{
                              width: '100%',
                              color: (!hasApproveAccess(props.user, "customerManagement"))
                                && 'rgba(0, 0, 0, 0.26)'
                            }}
                            disabled={!hasApproveAccess(props.user, "customerManagement")}
                          >
                            <MenuItem value={"YES"}>YES</MenuItem>
                            <MenuItem value={"NO"}>NO</MenuItem>
                          </Select>
                        </ToolTipComponent>
                      </FormControl>
                    )}
                    {/* <Box> 
                    <FormControl
                      fullWidth
                      disabled={props.customerOperation === "view"}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Buyer Payment {">"} 50 L in current FY
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={props.buyerPayment}
                        label="Buyer Payment > 50 L in current FY"
                        onChange={props.handleChange("buyerPayment")}
                      >
                        <MenuItem value={"YES"}>YES</MenuItem>
                        <MenuItem value={"NO"}>NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Typography className={classes.titleLabel}>
                      Specified Person *
                    </Typography>
                    {props.customerOperation === "view" ? (
                      <Typography className={classes.formControlText}>
                        {props.specifiedPerson}
                      </Typography>
                    ) : (
                      <FormControl
                        variant="outlined"
                        style={{
                          width: "50%"
                        }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <ToolTipComponent
                          title="You have no approve permission on this widget"
                          condition={props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            value={props.specifiedPerson}
                            label="Select"
                            onChange={props.handleChange("specifiedPerson")}
                            style={{
                              width: '100%',
                              color: (!hasApproveAccess(props.user, "customerManagement"))
                                && 'rgba(0, 0, 0, 0.26)'
                            }}
                            disabled={!hasApproveAccess(props.user, "customerManagement")}
                          >
                            <MenuItem value={"YES"}>YES</MenuItem>
                            <MenuItem value={"NO"}>NO</MenuItem>
                          </Select>
                        </ToolTipComponent>
                      </FormControl>
                    )}
                    {/* <Box>
                    <FormControl
                      fullWidth
                      disabled={props.customerOperation === "view"}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Specified Person
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={props.specifiedPerson}
                        label="Specified Person"
                        onChange={props.handleChange("specifiedPerson")}
                      >
                        <MenuItem value={"YES"}>YES</MenuItem>
                        <MenuItem value={"NO"}>NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Typography className={classes.titleLabel}>
                      Declaration *
                    </Typography>
                    {props.customerOperation === "view" ? (
                      <Typography className={classes.formControlText}>
                        {props.declaration}
                      </Typography>
                    ) : (
                      <FormControl
                        variant="outlined"
                        style={{
                          width: "50%"
                        }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <ToolTipComponent
                          title="You have no approve permission on this widget"
                          condition={props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            value={props.declaration}
                            label="Select"
                            onChange={props.handleChange("declaration")}
                            style={{
                              width: '100%',
                              color: (!hasApproveAccess(props.user, "customerManagement"))
                                && 'rgba(0, 0, 0, 0.26)'
                            }}
                            disabled={!hasApproveAccess(props.user, "customerManagement")}
                          >
                            <MenuItem value={"YES"}>YES</MenuItem>
                            <MenuItem value={"NO"}>NO</MenuItem>
                          </Select>
                        </ToolTipComponent>
                      </FormControl>
                    )}
                    {/* <Box>
                    <FormControl
                      fullWidth
                      disabled={props.customerOperation === "view"}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Declaration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={props.declaration}
                        label="Declaration"
                        onChange={props.handleChange("declaration")}
                      >
                        <MenuItem value={"YES"}>YES</MenuItem>
                        <MenuItem value={"NO"}>NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Typography className={classes.titleLabel}>
                      Buyer Turnover {">"} 10 Cr *
                    </Typography>
                    {props.customerOperation === "view" ? (
                      <Typography className={classes.formControlText}>
                        {props.buyerTurnover}
                      </Typography>
                    ) : (
                      <FormControl
                        variant="outlined"
                        style={{
                          width: "50%"
                        }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select
                        </InputLabel>
                        <ToolTipComponent
                          title="You have no approve permission on this widget"
                          condition={props.customerOperation !== "view" && !hasApproveAccess(props.user, "customerManagement")}
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            value={props.buyerTurnover}
                            label="Select"
                            onChange={props.handleChange("buyerTurnover")}
                            style={{
                              width: '100%',
                              color: (!hasApproveAccess(props.user, "customerManagement"))
                                && 'rgba(0, 0, 0, 0.26)'
                            }}
                            disabled={!hasApproveAccess(props.user, "customerManagement")}
                          >
                            <MenuItem value={"YES"}>YES</MenuItem>
                            <MenuItem value={"NO"}>NO</MenuItem>
                          </Select>
                        </ToolTipComponent>
                      </FormControl>
                    )}
                    {/* <Box>
                    <FormControl
                      fullWidth
                      disabled={props.customerOperation === "view"}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Buyer Turnover {">"} 10 Cr
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        value={props.buyerTurnover}
                        label="Buyer Turnover > 10 Cr"
                        onChange={props.handleChange("buyerTurnover")}
                      >
                        <MenuItem value={"YES"}>YES</MenuItem>
                        <MenuItem value={"NO"}>NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>


          <div className={classes.dataFieldsForCheckbox}>
            <Card
              sx={{
                width: 655,
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
              }}
            >
              <CardContent style={{ width: "100%", }}>
                <Typography
                  className={classes.titleLabel}
                  component={"span"}
                  style={{ display: "inline-block", width: "150px" }}
                >
                  ACCOUNT TYPE -
                </Typography>
                <br></br>
              </CardContent>

              <CardActions sx={{ width: "90%", marginLeft: "10px" }}>
                {" "}
                <FormGroup
                  // row
                  aria-label="position"
                  name="accountType"
                  className={classes.accountTypeCheckbox}
                >
                  <div >
                    <FormControlLabel
                      style={{ width: "80%" }}
                      control={
                        <GreenCheckbox
                          defaultChecked
                          onChange={(evt) => {
                            props.handleAccountTypeCheckboxChange(evt, "isDBoxChecked");
                          }}
                          name="D"
                          disabled={true}
                        />
                      }
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "550px",

                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", width: "65%", }}>
                            <p style={{ display: "inline-grid" }}>
                              D - Debtor {props?.dCode?.account_code &&
                                `(Account Code - ${props?.dCode?.account_code})`
                              }
                              <span
                                style={{
                                  display: "inline-block",
                                  fontSize: "11px",
                                }}
                              >
                                True for all Customers{" "}
                              </span>
                            </p>
                          </div>
                          <div style={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>

                            {
                              props?.customerOperation === "view" ?
                                !props.customerClassCodeD || props.customerClassCodeD != "" &&
                                `Class Code : ${props.customerClassCodeD}` :
                                props?.customerOperation === "edit" && props?.dCode?.account_class_code ?
                                  `Class Code : ${props.customerClassCodeD}` :
                                  (!(props.accountType.find(
                                    (a) => a.account_type === "D"
                                  )?.account_code)) && props?.isDBoxChecked ?
                                    <TextField
                                      type="text"

                                      // className={classes.formControl}
                                      label="CLASS CODE"
                                      id="outlined-size-small"
                                      value={props.customerClassCodeD}
                                      onChange={props.handleChange("customerClassCodeD")}
                                      variant="outlined"
                                      size="small"
                                      error={props.classCodeDError}
                                      helperText={
                                        props.classCodeDError ? "Invalid Class Code D" : ""
                                      }
                                      inputProps={{
                                        maxLength: 4,
                                      }}
                                    /> : ""
                            }
                          </div>
                        </div>
                      }
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={props.accountType.find(
                            (a) => a.account_type === "G"
                          )}
                          onChange={(evt) => {
                            props.handleAccountTypeCheckboxChange(evt, "isGBoxChecked");
                          }}
                          name="G"
                          disabled={
                            props?.gCode?.account_code ||
                            props.customerOperation === "view"
                          }
                        />
                      }
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "550px",

                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", width: "65%", }}>
                            <p style={{ display: "inline-grid" }}>
                              G - Creditor {props?.gCode?.account_code ?
                                `(Account Code - ${props?.gCode?.account_code})` : null}
                              <span
                                style={{
                                  display: "inline-block",
                                  fontSize: "11px",
                                }}
                              >
                                Select this to insert G account in ERP{" "}
                              </span>
                            </p>
                          </div>
                          <div style={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            {props?.customerOperation === "view" ?
                              !props.customerClassCodeG || props.customerClassCodeG != "" &&
                              `Class Code : ${props.customerClassCodeG}` :
                              props?.customerOperation === "edit" && props?.gCode?.account_class_code ?
                                `Class Code : ${props.customerClassCodeG}` :
                                (!(props.accountType.find(
                                  (a) => a.account_type === "G"
                                ))?.account_code) && props?.isGBoxChecked ?
                                  <TextField
                                    type="text"

                                    // className={classes.formControl}
                                    label="CLASS CODE"
                                    id="outlined-size-small"
                                    value={props.customerClassCodeG}
                                    onChange={props.handleChange("customerClassCodeG")}
                                    variant="outlined"
                                    size="small"
                                    error={props.classCodeGError}
                                    helperText={
                                      props.classCodeGError ? "Invalid Class Code G" : ""
                                    }
                                    inputProps={{
                                      maxLength: 4,
                                    }}
                                  /> : ""
                            }
                          </div>
                        </div>
                      }
                      labelPlacement="end"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={props.accountType.find(
                            (a) => a.account_type === "K"
                          ) || props.customerType === "DISTRIBUTOR" || props.customerType === "BROKER"}
                          onChange={(evt) => {
                            props.handleAccountTypeCheckboxChange(evt, "isKBoxChecked");
                          }}
                          name="K"
                          disabled={true}
                        // (props.customerOperation === "edit" &&
                        // (props.initialAccountTypesLength !== 0 ||
                        //   props?.accountType[2]?.account_code !== undefined)) ||
                        // props.customerOperation === "view" ||
                        // props.customerOperation === "add"


                        />
                      }
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "550px",

                          }}
                        >
                          <div style={{ display: "flex", justifyContent: "space-between", width: "65%", }}>
                            <p style={{ display: "inline-grid" }}>
                              K - BROKER {
                                props?.kCode?.account_code
                                  ?
                                  `(Account Code - ${props?.kCode?.account_code})` : null
                              }
                              <span
                                style={{
                                  display: "inline-block",
                                  fontSize: "11px",
                                }}
                              >
                                True by default if customer is Broker or Distributor{" "}
                              </span>
                            </p>
                          </div>
                          <div style={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            {props?.customerOperation === "view" ?
                              !props.customerClassCodeK || props.customerClassCodeK != "" &&
                              `Class Code : ${props.customerClassCodeK}` :
                              props?.customerOperation === "edit" && props?.kCode?.account_class_code ?
                                `Class Code : ${props.customerClassCodeK}` :
                                ((!(props.accountType.find(
                                  (a) => a.account_type === "K"
                                )?.account_code))
                                  && (props.customerType === "DISTRIBUTOR" || props.customerType === "BROKER"))
                                  && props?.isKBoxChecked ?
                                  <TextField
                                    type="text"

                                    // className={classes.formControl}
                                    label="CLASS CODE"
                                    id="outlined-size-small"
                                    value={props.customerClassCodeK}
                                    onChange={props.handleChange("customerClassCodeK")}
                                    variant="outlined"
                                    size="small"
                                    error={props.classCodeKError}
                                    helperText={
                                      props.classCodeKError ? "Invalid Class Code K" : ""
                                    }
                                    inputProps={{
                                      maxLength: 4,
                                    }}
                                  /> : ""
                            }
                          </div>
                        </div>
                      }
                      labelPlacement="end"
                    />
                  </div>
                </FormGroup>
              </CardActions>
            </Card>
          </div>
          { }

        </div>
      </div>
      <div className={classes.generalSpacing}>
        <Typography className={classes.customerTypeTitle} component={"span"}>
          <div className={classes.alignStateText}>
            <Typography className={classes.titleLabelStyle}>
              CUSTOMER DISCOUNT
            </Typography>
          </div>
        </Typography>
        <Typography className={classes.captionDetails} component={"span"}>
          This discount will be automatically applied whenever a new bill is
          generated.
        </Typography>

        <ThemeProvider theme={theme}>
          <Paper>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell align="left" className={classes.tableHead}>
                      Item Category
                    </TableCell>
                    <TableCell align="left" className={classes.tableHead}>
                      Discount Amount
                    </TableCell>
                    <TableCell align="left" className={classes.tableHead}>
                      {/* Brokerage Amount */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showData && showData.length > 0
                    ? showData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={row.id}>
                          {isEditing && editedRowIndex === index ? (
                            //<TableRow>
                            <>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={classes.tableBody}
                              >
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="paymentMethod">
                                    Item Category
                                  </InputLabel>
                                  <Select
                                    labelId="paymentMethod"
                                    defaultValue={row.item_category_id}
                                    onChange={(evt) => {
                                      evt.preventDefault();
                                      setSelectedItemCategoryID(evt.target.value);
                                    }}
                                    label="Payment Method"
                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem value="none" disabled={true}>
                                      <em>None</em>
                                    </MenuItem>
                                    {itemCategoryList && itemCategoryList.length > 0
                                      ? itemCategoryList.map((payment) => (
                                        <MenuItem value={payment.id}
                                          style={{
                                            display: addedCategories.includes(payment.category_name) ? "none" : "flex",
                                            fontFamily: 'Poppins',
                                          }}
                                        >
                                          {payment.category_name}
                                        </MenuItem>
                                      ))
                                      : null}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="middle" className={classes.tableBody}>
                                <TextField
                                  // type="number"
                                  onKeyPress={
                                    (event) => {
                                      if (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46) {

                                      } else {
                                        event.preventDefault()
                                      }
                                    }
                                  }
                                  size="small"
                                  id="outlined-uncontrolled"
                                  label="Discount amount"
                                  style={{ fontFamily: 'Poppins', }}
                                  InputProps={{
                                    style: {
                                      fontFamily: 'Poppins',
                                    }
                                  }}
                                  defaultValue={parseFloat(row.discount_amount).toFixed(2)}
                                  margin="normal"
                                  variant="outlined"
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setDiscountAmount(e.target.value);
                                    props.handleChange(e, "CustomerDiscountAmount")
                                  }}
                                //error={!checkBrokerage(this.state?.brokerage_amount) ? true : false}
                                // helperText={
                                //   !checkBrokerage(this.state?.brokerage_amount) ? "Enter brokerage amount b/w 0-9999" : ""
                                // }
                                />
                              </TableCell>
                            </>
                            //</TableRow>
                          ) :
                            <>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={classes.tableBody}
                              >
                                {
                                  row.item_category ? row.item_category : null
                                }
                              </TableCell>

                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                className={classes.tableBody}
                              >

                                {row.discount_amount === undefined
                                  ? "No Discount Amount"
                                  : parseFloat(row.discount_amount).toFixed(2)}
                                {/* {console.log(row.brokerageAmount)} */}
                              </TableCell>
                            </>
                          }


                          <TableCell
                            align="left"
                            className={classes.tableBody}
                          // style={{ width: '30%' }}
                          >
                            {isEditing && editedRowIndex === index
                              ?
                              (
                                <>
                                  <ToolTipComponent
                                    title="Invalid value"
                                    condition={props.totalCreditLimitAdvanceError}
                                    title2="Save"
                                    condition2={props?.customerCreditLimitAdvanceOperation !== "view"}

                                    spanWidth="25%"
                                  >
                                    <IconButton
                                      style={{
                                        display: 'inline',
                                        // transform: 'scale(0.8)',
                                        // marginBottom: 1,
                                        paddingTop: 0
                                      }}
                                      variant="text"
                                      onClick={() => {
                                        setIsEditing(false);
                                        let payloadData = {
                                          customer: param1,
                                          item_category: selectedItemCategoryID,
                                          discount_amount: parseFloat(discountAmount).toFixed(2),
                                          is_active: true
                                        }
                                        setSnackbarMessage("Discount updated successfully");
                                        setOpenSnackbar(true);
                                        setSnackbarType("success");
                                        setTimeout(async () => {
                                          await handleUpdateDiscountItemCatWise(row?.id, payloadData);

                                        }, 200);
                                        setTimeout(async () => {
                                          await fetchItemCategoryCustomer(param1);
                                        }, 2000);
                                      }
                                      }
                                      size="small"
                                    >
                                      <CheckOutlinedIcon />
                                    </IconButton>
                                  </ToolTipComponent>
                                  <ToolTipComponent
                                    title="Cancel"
                                    condition={props?.customerCreditLimitAdvanceOperation !== "view"}
                                    spanWidth="25%"
                                  >
                                    <IconButton
                                      style={{
                                        display: 'inline',
                                        // transform: 'scale(0.8)',
                                        // marginBootom: 1,
                                        paddingTop: 0
                                      }}
                                      variant="text"
                                      onClick={() => {
                                        setIsEditing(false);
                                        setEditedRowIndex(null);
                                        setAddDiscount(false);
                                      }}
                                      size="small"
                                    >
                                      <CloseOutlinedIcon />
                                    </IconButton>
                                  </ToolTipComponent>
                                </>
                              )

                              :
                              (
                                <ToolTipComponent
                                  title="You have no special permission on this widget"
                                  condition={props?.customerCreditLimitAdvanceOperation === "view" && !hasSpecialAccess(props.user, "customerManagement")}
                                  spanWidth="25%"
                                >
                                  <IconButton
                                    style={{
                                      display: 'inline',
                                      // transform: 'scale(0.8)',
                                      marginBootom: 1,
                                      paddingTop: 0
                                    }}
                                    variant="text"
                                    onClick={() => handleEdit(index, row)}
                                    size="small"
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </ToolTipComponent>
                              )
                            }
                          </TableCell>
                          {!isEditing ?
                            <TableCell
                              align="left"
                              className={classes.tableBody}
                            >
                              <ToolTipComponent
                                title="You have no edit permission on this widget"
                                condition={!hasUpdateAccess(props.user, "customerManagement")}
                                title2={`Click to remove discount`}
                                condition2={hasUpdateAccess(props.user, "customerManagement")}
                              >
                                <IconButton
                                  className={classes.removeIconDimensions}
                                  onClick={() => {
                                    deleteDiscount(row.id);
                                  }}
                                  disabled={
                                    hasUpdateAccess(props.user, "customerManagement")
                                      ? false
                                      : true
                                  }
                                >
                                  <DeleteOutlinedIcon />
                                </IconButton>
                              </ToolTipComponent>
                            </TableCell>
                            : null}

                        </TableRow>
                      ))
                    : null}
                  {addDiscount ? (
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={classes.tableBody}
                      >
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.formControl}
                        >
                          <InputLabel id="paymentMethod">
                            Item Category
                          </InputLabel>
                          <Select
                            labelId="paymentMethod"
                            onChange={(evt) => {
                              evt.preventDefault();
                              setSelectedItemCategoryID(evt.target.value);
                            }}
                            label="Payment Method"
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="none" disabled={true}>
                              <em>None</em>
                            </MenuItem>
                            {itemCategoryList && itemCategoryList.length > 0
                              ? itemCategoryList.map((payment) => (
                                <MenuItem value={payment.id}
                                  style={{
                                    display: addedCategories.includes(payment.category_name) ? "none" : "flex",
                                    fontFamily: 'Poppins',
                                  }}
                                >
                                  {payment.category_name}
                                </MenuItem>
                              ))
                              : null}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="middle" className={classes.tableBody}>
                        <TextField
                          // type="number"
                          onKeyPress={
                            (event) => {
                              if (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46) {

                              } else {
                                event.preventDefault()
                              }
                            }
                          }
                          size="small"
                          id="outlined-uncontrolled"
                          label="Discount amount"
                          style={{ fontFamily: 'Poppins', }}
                          InputProps={{
                            style: {
                              fontFamily: 'Poppins',
                            }
                          }}
                          defaultValue={parseFloat(discountAmount).toFixed(2)}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => {
                            e.preventDefault();
                            setDiscountAmount(e.target.value);
                            props.handleChange(e, "CustomerDiscountAmount")
                          }}
                        //error={!checkBrokerage(this.state?.brokerage_amount) ? true : false}
                        // helperText={
                        //   !checkBrokerage(this.state?.brokerage_amount) ? "Enter brokerage amount b/w 0-9999" : ""
                        // }
                        />
                      </TableCell>
                    </TableRow>
                  ) : null}

                </TableBody>
              </Table>
            </TableContainer>
            {showData.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={showData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : (
              ""
            )}
          </Paper>
        </ThemeProvider>
        <SnackbarComponent
          open={openSnackbar}
          handleClose={handleSnackbarClose}
          snackbarMessage={snackbarMessage}
          type={snackbarType}
        />
        {!addDiscount ? (
          <ToolTipComponent
            title="You have no edit permission on this widget"
            condition={!hasUpdateAccess(props.user, "customerManagement")}
          >
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddOutlinedIcon />}
              className={classes.addNewButton}
              onClick={() => {
                setAddDiscount(true);
                setDiscountAmount(0.0)
                props.handleChange("customerDiscountAmount")
              }}
              disabled={
                hasUpdateAccess(props.user, "customerManagement")
                  ? false
                  : true
              }
            >
              ADD NEW
            </Button>
          </ToolTipComponent>
        ) : (
          <div className={classes.saveCancelButton}>
            <Button
              size="medium"
              disabled={
                !selectedItemCategoryID
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={async () => {
                console.log("Selected ID: ", selectedItemCategoryID);

                const amount = discountAmount;
                const newItem = itemCategoryList.find(
                  (e) => e.id === selectedItemCategoryID
                );
                newItem.discountAmount = amount;
                const index = itemCategoryList.findIndex(
                  (e) => e.id === selectedItemCategoryID
                );
                setItemCategoryList(itemCategoryList)
                console.log(itemCategoryList)
                if (index > -1) {
                  console.log("ID exists in the array: ", itemCategoryList);
                  const itemCategories = itemCategoryList.filter(
                    (e) => e.id !== selectedItemCategoryID
                  );

                  console.log("New array after removal: ", itemCategories);
                  const FinalData = itemCategoryList.find(
                    (e) => e.id === selectedItemCategoryID
                  );
                  console.log(FinalData);

                  //setItemCategoryList(itemCategories.push(newItem));
                  setItemCategoryList([...itemCategories, newItem]);
                  console.log(itemCategoryList)

                  setSelectedItemCategoryID(false);
                  setAddDiscount(false);
                  setDiscountAmount(0.0);
                  let payloadData = {
                    customer: param1,
                    item_category: FinalData.id,
                    discount_amount: parseFloat(FinalData.discountAmount),
                    is_active: true
                  }
                  setData(payloadData)
                  // setSnackbarMessage("Discount added successfully");
                  // setOpenSnackbar(true);
                  // setSnackbarType("success");

                  setTimeout(async () => {
                    await handleCreateDiscountItemCatWise(
                      payloadData
                    );
                  }, 200);

                  setTimeout(async () => {
                    await fetchItemCategoryCustomer(param1);
                  }, 600);
                } else {
                  alert("Something went wrong.");
                }
              }}
            >
              Save
            </Button>

            <Button
              size="medium"
              onClick={() => setAddDiscount(false)}
            // onClick={() => handleCustomerAllowedPaymentMethodOperation("view")}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
      {props?.customerType !== "TRADER" ?
        <div>
          <ManageCustomerBrokerage
            getCustomerItemCategory={props.getCustomerItemCategory}

            {...props}
          />
        </div> : null
      }
      {
        props.customerType === "BROKER" ||
          props.customerType === "CONSUMER" || props.customerType === "TRADER" ? null : (
          <div className={classes.generalSpacing}>
            <ManageCustomerDealerDistributor
              {...props}
              customerType={props.customerType}
              customerDealersDistributors={props.customerDealersDistributors}
              customerBuyersList={props?.customerBuyersList}
              allDealers={props.allDealers}
              allDistributors={props.allDistributors}
              customerSelectedDealerOrDistributor={
                props.customerSelectedDealerOrDistributor
              }
              handleChangeCustomerSelectedDealerOrDistributor={
                props.handleChangeCustomerSelectedDealerOrDistributor
              }
              handleSaveDealerOrDistributor={props.handleSaveDealerOrDistributor}
              handleRemoveClickInDealerOrDistributorTable={
                props.handleRemoveClickInDealerOrDistributorTable
              }
              dealerDistributorName={props.dealerDistributorName}
              dealerDistributorId={props.dealerDistributorId}
              dealerDistributorGstinNumber={props.dealerDistributorGstinNumber}
              dealerDistributorPhoneNumber={props.dealerDistributorPhoneNumber}
              dealerDistributorPanCardNumber={
                props.dealerDistributorPanCardNumber
              }
              saveDealerDistributorLoader={props.saveDealerDistributorLoader}
              handleRemoveDealerDistributorCircularLoadingShow={
                props.handleRemoveDealerDistributorCircularLoadingShow
              }
              removeDealerDistributorLoader={props.removeDealerDistributorLoader}
            />
          </div>
        )
      }
      <div className={classes.generalSpacing}>
        <ManageCustomerAccountAdditionalContacts
          props={props}
          handleAdditionalContactOperation={props.handleAdditionalContactOperation}
          handleCustomerAdditionalContactEditValueChange={props.handleCustomerAdditionalContactEditValueChange}
          additionalContactOperation={props.additionalContactOperation}
          additionalContactRowEditData={props.additionalContactRowEditData}
          additionalContactEditError={props.additionalContactEditError}
          handleEditSaveCustomerAdditionalContact={props.handleEditSaveCustomerAdditionalContact}
        />
      </div>
      <div className={classes.generalSpacing}>
        <ManageCustomerAccountAllowedPaymentMethod props={props} />
      </div>
      <div className={classes.generalSpacing}>
        <ManageCustomerAccountCreditLimit
          {...props}
          props={props}
          user={props.user}
          handleSyncEffectiveCreditLimit={props?.handleSyncEffectiveCreditLimit}
          handleTotalCreditLimitSaveButton={props?.handleTotalCreditLimitSaveButton}
          handleTotalCreditLimitCancelButton={props?.handleTotalCreditLimitCancelButton}
          handleCustomerCreditLimitMethodOperation={props?.handleCustomerCreditLimitMethodOperation}
          totalCreditLimitAdvanceLogDetail={props.totalCreditLimitAdvanceLogDetail}
          totalCreditLimitOtherLogDetail={props.totalCreditLimitOtherLogDetail}
          totalCreditLimitAdvance={props.totalCreditLimitAdvance}
          totalCreditLimitAdvanceVisible={props.totalCreditLimitAdvanceVisible}
          totalCreditLimitAdvanceError={props.totalCreditLimitAdvanceError}
          creditLimitLogDataLoader={props.creditLimitLogDataLoader}
          effectiveCreditLimitAdvance={props.effectiveCreditLimitAdvance}
          effectiveCreditLimitAdvanceLastUpdate={props.effectiveCreditLimitAdvanceLastUpdate}
          handleChange={props.handleChange}
        />
      </div>
      <div className={classes.generalSpacing}>
        <ManageCustomerAccountSubcontractCreditLimit
          {...props}
          props={props}
          user={props.user}
          handleSyncEffectiveCreditLimit={props?.handleSyncEffectiveCreditLimit}
          handleSubcontractVclLimit={props?.handleSubcontractVclLimit}
          handleTotalCreditLimitSaveButton={props?.handleTotalCreditLimitSaveButton}
          handleTotalCreditLimitCancelButton={props?.handleTotalCreditLimitCancelButton}
          handleCustomerCreditLimitMethodOperation={props?.handleCustomerCreditLimitMethodOperation}
          totalCreditLimitAdvanceLogDetail={props.totalCreditLimitAdvanceLogDetail}
          totalCreditLimitOtherLogDetail={props.totalCreditLimitOtherLogDetail}
          totalCreditLimitAdvance={props.totalCreditLimitAdvance}
          totalCreditLimitAdvanceVisible={props.totalCreditLimitAdvanceVisible}
          totalCreditLimitAdvanceError={props.totalCreditLimitAdvanceError}
          creditLimitLogDataLoader={props.creditLimitLogDataLoader}
          effectiveCreditLimitAdvance={props.effectiveCreditLimitAdvance}
          effectiveCreditLimitAdvanceLastUpdate={props.effectiveCreditLimitAdvanceLastUpdate}
          handleChange={props.handleChange}
        />
      </div>
      <div className={classes.generalSpacing}>
        <ManageCustomerSubscriptionDetailsNewUi {...props} />
      </div>
      <div className={classes.generalSpacing}>
        <ManageCustomerBuyerConsigneeDetailsNewUi {...props} />
      </div>
    </Paper >
  );
}
