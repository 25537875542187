import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Tooltip,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  grey,
  secondaryGrey,
  whiteSmoke,
  drawerTextColor,
  successButtonBackground,
  secondaryBlueText,
} from "../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import "../../../../styles/internal/scroll.css";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  formControl: {
    width: "80%",
    fontFamily: 'Poppins',
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    fontFamily: 'Poppins',
  },
  tableBody: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(13),
  },
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  customerTypeTitle: {
    fontSize: pxToEm(15),
    color: secondaryBlueText,
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  addAndSaveButton: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0px 20px 0px",
    justifyContent: "space-between",
  },
  saveCancelButton: {
    display: "flex",
    placeSelf: "strech",
  },
  alignStateText: {
    display: "flex",
    placeContent: "flex-start",
    alignItems: "center",
  },
  rightSpacing: {
    display: "flex",
    marginLeft: "10px",
    fontSize: "0.8em",
    alignItems: "center",
    fontWeight: "normal",
  },
  editIcon: {
    heigth: "15px",
    width: "15px",
  },
  iconContainer: {
    height: 30,
    width: 30,
    background: "yellow",
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: whiteSmoke,
    overflow: "hidden",
    color: grey,
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "10px 5px 10px 5px",
      },
    },
  },
});
export default function ManageCustomerDealerDistributor(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes = useStyles();

  const [
    customerDealerDistributorOperation,
    setCustomerDealerDistributorOperation,
  ] = React.useState("view");
  React.useEffect(() => { }, [props?.customerBuyersList])
  const rows = props.customerDealersDistributors
    ? props.customerDealersDistributors
    : [];
  const selectionOptions =
    props.customerType === "DEALER"
      ? props.allDistributors
      : props.customerType === "DISTRIBUTOR"
        ? props.allDealers
        : [];
  const filteredSelectionOption = selectionOptions.filter(
    (x) =>
      !rows.filter((y) => {
        if (props.customerType === "DEALER") {
          return y.distributor.id === x.id;
        } else if (props.customerType === "DISTRIBUTOR") {
          return y.dealer.id === x.id;
        } else {
          return true;
        }
      }).length
  );
  const dealerfilteredSelectionOption =
    filteredSelectionOption.length > 0 && props?.customerBuyersList.length > 0
      ? filteredSelectionOption.filter(
        (x) =>
          !props?.customerBuyersList.filter((y) => {
            if (props.customerType === "DISTRIBUTOR") {
              return Number(y.buyer_detail.id) === Number(x.id);
            } else {
              return filteredSelectionOption;
            }
          }).length
      )
      : filteredSelectionOption;
  // { console.log(selectionOptions, filteredSelectionOption, dealerfilteredSelectionOption, props?.customerBuyersList) }
  const handleCustomerDealerDistributorOperationChange = (operation) => {
    setCustomerDealerDistributorOperation(operation);
  };
  return (
    <>
      <Typography className={classes.customerTypeTitle} component={"span"}>
        {props.customerType === "DISTRIBUTOR"
          ? "DEALER"
          : props.customerType === "DEALER"
            ? "DISTRIBUTOR"
            : ""}
      </Typography>
      <ThemeProvider theme={theme}>
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="left" className={classes.tableHead}>
                    NAME
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    GSTIN / PAN
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    CONTACT
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableHead}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.length > 0
                  ? rows
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          className={classes.tableBody}
                        >
                          {props.customerType === "DISTRIBUTOR"
                            ? row.dealer
                              ? row.dealer.gst_information !== null
                                ? row.dealer.gst_information.trade_name
                                  ? row.dealer.gst_information.trade_name
                                  : row.dealer.gst_information
                                    .legal_name_of_business
                                    ? row.dealer.gst_information
                                      .legal_name_of_business
                                    : (row.dealer.first_name
                                      ? row.dealer.first_name
                                      : "") +
                                    (row.dealer.last_name
                                      ? row.dealer.last_name
                                      : "")
                                : (row.dealer.first_name
                                  ? row.dealer.first_name
                                  : "") +
                                " " +
                                (row.dealer.last_name
                                  ? row.dealer.last_name
                                  : "")
                              : row.dealer.pan_card_information !== null
                                ? row.dealer.pan_card_information.company_name
                                  ? row.dealer.pan_card_information.company_name
                                  : (row.dealer.first_name
                                    ? row.dealer.first_name
                                    : "") +
                                  " " +
                                  (row.dealer.last_name
                                    ? row.dealer.last_name
                                    : "")
                                : (row.dealer.first_name
                                  ? row.dealer.first_name
                                  : "") +
                                " " +
                                (row.dealer.last_name
                                  ? row.dealer.last_name
                                  : "")
                            : props.customerType === "DEALER"
                              ? row.distributor
                                ? row.distributor.gst_information !== null
                                  ? row.distributor.gst_information.trade_name
                                    ? row.distributor.gst_information.trade_name
                                    : row.distributor.gst_information
                                      .legal_name_of_business
                                      ? row.distributor.gst_information
                                        .legal_name_of_business
                                      : (row.distributor.first_name
                                        ? row.distributor.first_name
                                        : "") +
                                      " " +
                                      (row.distributor.last_name
                                        ? row.distributor.last_name
                                        : "")
                                  : (row.distributor.first_name
                                    ? row.distributor.first_name
                                    : "") +
                                  " " +
                                  (row.distributor.last_name
                                    ? row.distributor.last_name
                                    : "")
                                : row.distributor.pan_card_information !== null
                                  ? row.distributor.pan_card_information
                                    .company_name
                                    ? row.distributor.pan_card_information
                                      .company_name
                                    : (row.distributor.first_name
                                      ? row.distributor.first_name
                                      : "") +
                                    " " +
                                    (row.distributor.last_name
                                      ? row.distributor.last_name
                                      : "")
                                  : (row.distributor.first_name
                                    ? row.distributor.first_name
                                    : "") +
                                  " " +
                                  (row.distributor.last_name
                                    ? row.distributor.last_name
                                    : "")
                              : ""}
                        </TableCell>
                        <TableCell align="left" className={classes.tableBody}>
                          {props.customerType === "DISTRIBUTOR"
                            ? row.dealer
                              ? row.dealer.gst_information !== null
                                ? row.dealer.gst_information.gstin_number
                                  ? row.dealer.gst_information.gstin_number
                                  : ""
                                : row.dealer.pan_card_information !== null
                                  ? row.dealer.pan_card_information
                                    .pan_card_number
                                    ? row.dealer.pan_card_information
                                      .pan_card_number
                                    : ""
                                  : ""
                              : ""
                            : props.customerType === "DEALER"
                              ? row.distributor
                                ? row.distributor.gst_information !== null
                                  ? row.distributor.gst_information.gstin_number
                                    ? row.distributor.gst_information
                                      .gstin_number
                                    : ""
                                  : row.distributor.pan_card_information !==
                                    null
                                    ? row.distributor.pan_card_information
                                      .pan_card_number
                                      ? row.distributor.pan_card_information
                                        .pan_card_number
                                      : ""
                                    : ""
                                : ""
                              : ""}
                        </TableCell>
                        <TableCell align="left" className={classes.tableBody}>
                          {props.customerType === "DISTRIBUTOR"
                            ? row.dealer
                              ? row.dealer.credentials
                                ? row.dealer.credentials.phone
                                  ? row.dealer.credentials.phone
                                  : ""
                                : ""
                              : ""
                            : props.customerType === "DEALER"
                              ? row.distributor
                                ? row.distributor.credentials
                                  ? row.distributor.credentials.phone
                                    ? row.distributor.credentials.phone
                                    : ""
                                  : ""
                                : ""
                              : ""}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBody}
                        >
                          {/* {console.log(row)} */}
                          {props.removeDealerDistributorLoader === index ? (
                            <CircularProgress size={15} />
                          ) : (
                            <ToolTipComponent
                              title="You have no edit permission on this widget"
                              condition={!hasUpdateAccess(props.user, "customerManagement")}
                              title2={`Click to remove ${props.customerType === "DISTRIBUTOR"
                                ? "DEALER"
                                : props.customerType === "DEALER"
                                  ? "DISTRIBUTOR"
                                  : ""
                                }`}
                              condition2={hasUpdateAccess(props.user, "customerManagement")}
                            >
                              <IconButton
                                className={classes.removeIconDimensions}
                                onClick={(evt) => {
                                  props.handleRemoveClickInDealerOrDistributorTable(
                                    row.id
                                  );
                                  props.handleRemoveDealerDistributorCircularLoadingShow(
                                    index
                                  );
                                }}
                                disabled={
                                  hasUpdateAccess(props.user, "customerManagement")
                                    ? false
                                    : true
                                }
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </ToolTipComponent>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
                {props.customerType === "DEALER" &&
                  rows.length > 0 ? null : customerDealerDistributorOperation ===
                    "add" ? (
                  <TableRow key={props.dealerDistributorId}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.tableBody}
                    >
                      <Autocomplete
                        id="scroll"
                        options={props.customerType === "DISTRIBUTOR" ? dealerfilteredSelectionOption : filteredSelectionOption}
                        className={classes.formControl}
                        onChange={
                          props.handleChangeCustomerSelectedDealerOrDistributor
                        }
                        // getOptionLabel={(option) =>
                        //   option.first_name + " " + option.last_name
                        // }
                        getOptionLabel={(option) =>
                          `
                          ${option.first_name} 
                          ${option.last_name} 
                          ${option.first_name + " " + option.last_name} 
                          ${option.gst_information?.gstin_number}
                          ${option.gst_information?.trade_name}
                          ${option.gst_information?.legal_name_of_business}
                          ${option.pan_card_information?.pan_card_number}
                          ${option.pan_card_information?.company_name}
                          `
                        }
                        renderOption={(option) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "1.2em",
                              fontFamily: 'Poppins',
                            }}
                          >
                            <span style={{ fontSize: "1em" }}>
                              {option.first_name + " " + option.last_name}
                            </span>
                            <span style={{ color: grey, fontSize: "0.7em" }}>
                              {!((option?.gst_information?.gstin_number)?.length > 10)
                                ? `PAN - ${option?.pan_card_information?.pan_card_number || "N/A"}`
                                : `GSTIN - ${option?.gst_information?.gstin_number}`}
                            </span>
                            <span style={{ color: grey, fontSize: "0.7em" }}>
                              PHONE - {option?.credentials?.phone || "N/A"}
                            </span>
                          </div>
                        )}
                        value={filteredSelectionOption?.find(
                          (v) => v.id === props.dealerDistributorId
                        )}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="nomal"
                            size="small"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      {!props.dealerDistributorGstinNumber
                        ? props.dealerDistributorPanCardNumber
                          ? props.dealerDistributorPanCardNumber
                          : null
                        : props.dealerDistributorGstinNumber
                          ? props.dealerDistributorGstinNumber
                          : ""}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      {props.dealerDistributorPhoneNumber}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableBody}
                    ></TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {props.customerType === "DEALER" &&
            rows.length > 0 ? null : rows.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : (
            ""
          )}
        </Paper>
      </ThemeProvider>
      <div className={classes.addAndSaveButton}>
        {props.customerType === "DEALER" &&
          rows.length > 0 ? null : customerDealerDistributorOperation ===
            "view" ? (
          <ToolTipComponent
            title="You have no edit permission on this widget"
            condition={!hasUpdateAccess(props.user, "customerManagement")}
          >
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddOutlinedIcon />}
              className={classes.addNewButton}
              onClick={() => {
                handleCustomerDealerDistributorOperationChange("add");
              }}
              disabled={
                hasUpdateAccess(props.user, "customerManagement")
                  ? false
                  : true
              }
            >
              ADD NEW
            </Button>
          </ToolTipComponent>
        ) : null}
        {props.customerType === "DEALER" &&
          rows.length > 0 ? null : customerDealerDistributorOperation ===
            "view" ? null : props.saveDealerDistributorLoader ? (
              <CircularProgress disableShrink />
            ) : (
          <div className={classes.saveCancelButton}>
            <Button
              size="medium"
              disabled={!props.dealerDistributorId}
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={(evt) => {
                props.handleSaveDealerOrDistributor(evt)
                handleCustomerDealerDistributorOperationChange("view");
              }
              }
            >
              Save
            </Button>

            <Button
              size="medium"
              onClick={() =>
                handleCustomerDealerDistributorOperationChange("view")
              }
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
