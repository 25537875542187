import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import {
  whiteSmoke,
  primaryColor,
} from '../../../../constants/internal/colors';

import { pxToEm } from '../../../../methods';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownlodPdf from './DownloadPdf';

const useStyles = makeStyles({
  inputWidth: {
    maxWidth: 130,
    height: 35,
  },
  container: {
    maxHeight: 440,
    width: '100%',
  },
  printDownloadDiv: {
    display: 'flex',
    padding: 10,
    placeItems: 'center',
    justifyContent: 'flex-end',
  },
  publishDetailsDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  publishActionDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightSpacing: {
    marginRight: 10,
  },
  fontStyle: {
    fontSize: pxToEm(20),
  },
  captionFontStyle: {
    fontSize: pxToEm(14),
  },
  loaderDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 50,
  },
  loaderStyle: { color: primaryColor },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: whiteSmoke,
    fontWeight: 'bold',
    fontSize: pxToEm(13),
  },
  body: {
    fontSize: pxToEm(14),
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    fontFamily: 'roboto',
  },
}))(TableRow);
export default function SingleProductPastRatesDialog({
  selectedCategoryData,
  showSingleProductPastRate,
  handleSingleProductPastRateDialogClose,
  pastRatePublishedBy,
  pastRatePublishedByUID,
  pastRatePublishDate,
  selectedFilteredCategoryName,
  selectedCategoryDataLoader,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={() => handleSingleProductPastRateDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={showSingleProductPastRate}
      >
        {!selectedCategoryData ? (
          <div className={classes.loaderDiv}>
            {selectedCategoryDataLoader ? (
              <CircularProgress disableShrink className={classes.loaderStyle} />
            ) : (
              <Typography className={classes.loaderStyle}>
                Data not found.
              </Typography>
            )}
          </div>
        ) : (
          <>
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => handleSingleProductPastRateDialogClose()}
            >
              <Typography gutterBottom className={classes.fontStyle}>
                {selectedFilteredCategoryName}
              </Typography>
              <Typography
                display="block"
                gutterBottom
                className={classes.captionFontStyle}
              >
                {!pastRatePublishDate
                  ? null
                  : moment(pastRatePublishDate).format('DD/MM/YYYY') +
                  ' : ' +
                  moment(pastRatePublishDate).format('LT')}
              </Typography>
              <Typography display="block" gutterBottom variant="caption">
                {`Published By : ${!pastRatePublishedByUID
                  ? '(UID not found)'
                  : pastRatePublishedByUID
                  }`}
              </Typography>
            </DialogTitle>
            <DialogContent dividers id="scroll_table_selectd">
              <Table
                stickyHeader
                className={classes.container}
                aria-label="products-state-price-table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">STATE</StyledTableCell>
                    <StyledTableCell align="left">
                      {/* HIGH RATE(HR) */}
                      OPEN RATE
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {/* LOW RATE(LR) */}
                      NET RATE
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCategoryData.map((statesData, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" align="left">
                        {statesData.state.state_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        ₹{parseFloat(statesData.high_rate).toFixed(2)}{' '}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        ₹{parseFloat(statesData.low_rate).toFixed(2)}{' '}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <DownlodPdf
                fileName={`${selectedFilteredCategoryName}_Published_At_${moment(
                  pastRatePublishDate
                ).format('DD/MM/YYYY')}`}
                printSection="scroll_table_selectd"
                headerTitle={selectedFilteredCategoryName}
                headerDate={` Published At :- ${moment(
                  pastRatePublishDate
                ).format('DD/MM/YYYY')} : ${moment(pastRatePublishDate).format(
                  'LT'
                )}`}
                headerPublisedBy={` Published By :- ${pastRatePublishedByUID}`}
              />
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
