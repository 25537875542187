import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  primaryColor,
  whiteSmoke,
  secondaryGrey,
  drawerTextColor,
  successButtonBackground,
  primaryColorBackground,
} from "../../../../../constants/internal/colors";
import { pxToEm, hasWriteAccess } from '../../../../../methods';

import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  Tab,
  InputAdornment,
  Tabs,
  Paper,
  Switch,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import ContractsCustomersTable from "./ContractsCustomersTable";
import SpinLoader from "../../../../common/SpinLoader";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import NoDataFound from "../../../../common/NoDataFound";
import Bread from "../../../common/Breadcrubs";
import ToolTipComponent from "../../../common/ToolTipComponent";
import { hasReadAccess } from "../../../../../methods";

const customersStyles = {
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: "0.9em",
  },
  filterSection: (isMobile) => ({
    display: "flex",
    alignItems: isMobile ? 'flex-start' : "center",
    flex: "1",
    flexDirection: isMobile && 'column',
  }),
  searchAutoComplete: {
    display: "flex",
    flex: 5,
  },
  filterAlignment: {
    display: "flex",
    alignItems: "center",
    flex: 3,
    placeContent: "center",
  },
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  searchBox: {
    width: "85%",
  },
  formControl: {
    minWidth: "100%",
    marginLeft: "10px",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingRight: "25px",
    overflowY: "scroll",
    paddingBottom: "30px",
  },
  tabsStyle: (tabValue, currentTab) => ({
    backgroundColor: whiteSmoke,
    color: tabValue === currentTab ? grey : secondaryGrey,
    fontSize: "0.9em",
    fontWeight: "bold",
    width: "100%",
  }),
  tabStyle: (tabValue, currentTab, isMobile) => ({
    backgroundColor: whiteSmoke,
    color: tabValue === currentTab ? grey : secondaryGrey,
    fontSize: "0.9em",
    fontWeight: "bold",
    width: isMobile ? '40%' : "100%",
  }),
  tabContainerStyle: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    display: "flex",
  },
  tabContainerChildStyle: {
    width: "100%",
  },
  tableMainContainerStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableChildContainerStyle: {
    width: "92%",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
    marginTop: "2px 20px 20px 20px",
  },
  displayData: {
    marginTop: 10,
  },
  noDataDiv: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    display: "flex",
    height: "calc(100vh - 250px)",
  },
  pendingApprovalDiv: {
    display: "flex",
    width: "100%",
  },
};

const PurpleSwitch = withStyles({
  switchBase: {
    color: "",
    "&$checked": {
      color: successButtonBackground,
    },
    // "&$checked + $track": {
    //   backgroundColor: successButtonBackground,
    // },
  },
  checked: {},
  track: {},
})(Switch);
class Contracts extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      tabValue: this?.props?.itemCategoryTabData?.id || 0,
      searchText: "",
      itemCategoryTabData: this?.props?.itemCategoryTabData || [],
      itemDataFetchLoader: this?.props?.itemDataFetchLoader || true,
      allOrders: this?.props?.allOrders || [],
      allOrdersBackupOne: this?.props?.allOrders || [],
      allOrdersBackupTwo: this?.props?.allOrders || [],
      rows: this?.props?.allOrders || [],
      page: 0,
      displayPendingOnlySwitch: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.props.fetchAllCategories();
    this.setState({
      itemCategoryTabData: this?.props?.itemCategoryTabData,
      itemDataFetchLoader: this?.props?.itemDataFetchLoader,
      allOrders: this?.props?.allOrders,
      rows: this?.props?.allOrders || []
    }, () => {
      let index = undefined;
      this.state.itemCategoryTabData.every((itemData, i) => {
        if (hasReadAccess(this.props.user, `${itemData?.item_category_code}-contracts`)) {
          index = i;
          return false;
        }
        return true;
      })
      //let index = this.state.itemCategoryTabData.findIndex((itemData) => hasReadAccess(this.props.user, `${itemData?.item_category_code}-contracts`));
      let itemData = this.state.itemCategoryTabData[index];
      this.setState({ tabValue: itemData?.id }, () => this.props?.handleTabValueCode(itemData?.id));
      if (!!itemData?.id)
        this.props.fetchAllOrders(1, itemData?.id)
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.itemCategoryTabData != this.props.itemCategoryTabData) {

      this.setState({
        itemCategoryTabData: this.props.itemCategoryTabData,
      }, () => {
        let index = undefined;
        this.state.itemCategoryTabData.every((itemData, i) => {
          if (hasReadAccess(this.props.user, `${itemData?.item_category_code}-contracts`)) {
            index = i;
            return false;
          }
          return true;
        })
        // let index = this.state.itemCategoryTabData.findIndex((itemData) => hasReadAccess(this.props.user, `${itemData?.item_category_code}-contracts`));
        let itemData = this.state.itemCategoryTabData[index];
        this.setState({ tabValue: itemData?.id }, () => this.props?.handleTabValueCode(itemData?.id));
        if (!!itemData?.id)
          this.props.fetchAllOrders(1, itemData?.id)
      })
    }
    if (prevProps.itemDataFetchLoader != this.props.itemDataFetchLoader) {
      this.setState({
        itemDataFetchLoader: this.props.itemDataFetchLoader
      })
    }
    if (prevProps.allOrders != this.props.allOrders) {
      this.setState({
        allOrders: this.props.allOrders,
        rows: this.props.allOrders
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = async (event) => {
    const { name, value, checked } = event.target;
    if (name === "displayPendingOnlySwitch") {
      if (this.state.searchText === '') {
        this.setState({ [name]: checked });
        if (checked) {
          await this.props.displayPendingContracts(this.state.tabValue, 1);
        } else {
          await this.props.fetchAllOrders(1, this.state.tabValue);
        }
      }
      else {
        this.setState({ [name]: checked, page: 0 });
        if (checked) {
          await this.props.fetchSearchPendingOrders(this.state.tabValue, 1, this.state.searchText, 0);
        } else {
          await this.props.fetchSearchOrders(1, this.state.tabValue, this.state.searchText, 0);
        }
      }
    } else {
      this.setState({ page: 0 })
      if (value !== '') {
        if (this.state.displayPendingOnlySwitch === true) {
          this.setState({ [name]: value });
          await this.props.fetchSearchPendingOrders(this.state.tabValue, 1, value, 0);
        }
        else {
          this.setState({ [name]: value });
          await this.props.fetchSearchOrders(1, this.state.tabValue, value, 0);
        }
      }
      else if (value === '') {
        this.setState({ [name]: value });
        if (this.state.displayPendingOnlySwitch) {
          this.props.displayPendingContracts(this.state.tabValue, 1);
        } else {
          this.props.fetchAllOrders(1, this.state.tabValue);
        }
      }
      // let filteredRows = this.props.allOrders.filter(
      //   (c) =>
      //     c?.customer?.first_name.toUpperCase().indexOf(value.toUpperCase()) >
      //     -1 ||
      //     c?.customer?.last_name.toUpperCase().indexOf(value.toUpperCase()) >
      //     -1 ||
      //     Number(c?.id) === Number(value)
      // );
      // this.setState({ rows: filteredRows });
    }
  };


  fetchNextOrders = (newLink, newPage) => {
    this.setState({
      page: newPage
    }, async () => {
      if (this.state.searchText === '') {
        if (this.state.displayPendingOnlySwitch) {
          await this.props.displayPendingContracts(this.state.tabValue, newPage + 1);
        } else {
          await this.props.fetchAllOrders(newPage + 1, this.state.tabValue);
        }
      }
      else {
        if (this.state.displayPendingOnlySwitch) {
          await this.props.fetchSearchPendingOrders(this.state.tabValue, newPage + 1, this.state.searchText, newLink);
        } else {
          await this.props.fetchSearchOrders(newPage + 1, this.state.tabValue, this.state.searchText, newLink);
        }
      }
    });
  }

  handleTabChange = (event, value) => {
    this.setState({
      tabValue: value,
      page: 0
    }, async () => {
      this.props?.handleTabValueCode(value)
      if (this.state.searchText === '') {
        if (this.state.displayPendingOnlySwitch) {
          await this.props.displayPendingContracts(value, 1);
        } else {
          this.props.fetchAllOrders(1, value);
        }
      }
      else {
        if (this.state.displayPendingOnlySwitch) {
          await this.props.fetchSearchPendingOrders(value, 1, this.state.searchText, 0);
        } else {
          await this.props.fetchSearchOrders(1, value, this.state.searchText, 0);
        }
      }
    });

  };
  // static getDerivedStateFromProps(props, state) {
  //   let updatedData = new Map();
  //   if (state.itemCategoryTabData !== props.itemCategoryTabData) {
  //     updatedData.set("itemCategoryTabData", props.itemCategoryTabData);
  //   }
  //   if (state.allOrders !== props.allOrders) {
  //     let rows = props?.allOrders.filter(
  //       (orders) =>
  //         Number(orders?.item_category?.id) ===
  //         Number(props?.allOrders[0]?.item_category?.id)
  //     );
  //     updatedData.set("allOrders", props.allOrders);
  //     updatedData.set("allOrdersBackupOne", props.allOrders);
  //     updatedData.set("allOrdersBackupTwo", props.allOrders);
  //     updatedData.set("tabValue", props?.allOrders[0]?.item_category?.id);
  //     updatedData.set("rows", rows);
  //   }
  //   if (state.itemDataFetchLoader !== props.itemDataFetchLoader) {
  //     updatedData.set("itemDataFetchLoader", props.itemDataFetchLoader);
  //   }
  //   if (updatedData.size > 0) {
  //     return {
  //       itemCategoryTabData: updatedData.has("itemCategoryTabData")
  //         ? updatedData.get("itemCategoryTabData")
  //         : [],
  //       allOrders: updatedData.has("allOrders")
  //         ? updatedData.get("allOrders")
  //         : [],
  //       allOrdersBackupOne: updatedData.has("allOrdersBackupOne")
  //         ? updatedData.get("allOrdersBackupOne")
  //         : [],
  //       allOrdersBackupTwo: updatedData.has("allOrdersBackupTwo")
  //         ? updatedData.get("allOrdersBackupTwo")
  //         : [],
  //       tabValue: updatedData.has("tabValue") ? updatedData.get("tabValue") : 0,
  //       rows: updatedData.has("rows") ? updatedData.get("rows") : [],

  //       itemDataFetchLoader: updatedData.has("itemDataFetchLoader")
  //         ? updatedData.get("itemDataFetchLoader")
  //         : true,
  //     };
  //   } else {
  //     return null;
  //   }
  // }
  render() {
    const {
      tabValue,
      searchText,
      itemDataFetchLoader,
      itemCategoryTabData,
      rows,
      allOrders,
      displayPendingOnlySwitch,
      page
    } = this.state;
    const { classes, isMobile, } = this.props;
    return (
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          {/* <Typography>Orders</Typography> */}
          <Bread
            data={[
              {
                title: 'Contracts',
              },
            ]}
          />

          <div className={classes.displayData}>
            <Paper className={classes.paperStyle}>
              <div style={customersStyles.filterSection(isMobile)}>
                <div
                  className={classes.searchAutoComplete}
                  style={{ marginBottom: isMobile && 10 }}
                >
                  <TextField
                    name="searchText"
                    className={classes.searchBox}
                    label="Search "
                    autoComplete="off"
                    variant="outlined"
                    value={this.state.searchText}
                    size="small"
                    onChange={this.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ padding: 0, display: this.state.searchText === '' ? 'none' : 'block' }}
                            onClick={() => {
                              this.setState({ searchText: '', },
                                () => {
                                  if (this.state.displayPendingOnlySwitch) {
                                    this.props.displayPendingContracts(this.state.tabValue, 1);
                                  } else {
                                    this.props.fetchAllOrders(1, this.state.tabValue);
                                  }
                                }
                              )

                            }}
                          >
                            <CloseOutlinedIcon />
                          </IconButton>
                          <IconButton>
                            <SearchOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <SearchOutlinedIcon />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                </div>
                {/* <div className={classes.filterAlignment}>
                  <Typography>FILTER</Typography>
                  <div style={{ width: "45%" }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      size="small"
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        //  value={filterSelection}
                        // onChange={this.handleFilterChange}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="new">New</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> */}
                <div className={classes.filterAlignment} style={{ marginBottom: isMobile && 30 }}>
                  <FormControlLabel
                    control={
                      <PurpleSwitch
                        checked={displayPendingOnlySwitch}
                        onChange={this.handleChange}
                        name="displayPendingOnlySwitch"
                        color="primary"
                      />
                    }
                    label="Display Pending Only"
                  />
                </div>
                <div className={classes.addButton} style={{ marginBottom: isMobile && 10 }}>
                  <ToolTipComponent
                    title="You have no create permission on this widget"
                    condition={!hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                  >
                    <Button
                      className={classes.primaryBackground}
                      variant="contained"
                      startIcon={<AddOutlinedIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          "/internalContracts/addContracts"
                        );
                      }}
                      disabled={hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ? false : true}
                    >
                      ADD NEW
                    </Button>
                  </ToolTipComponent>
                </div>
              </div>
              <Paper>
                {/* {allOrders.length > 0 ? (
                  <>
                    <div className={classes.tabContainerStyle}>
                      <div className={classes.tabContainerChildStyle}>
                        <Tabs
                          TabIndicatorProps={{
                            style: {
                              borderBottom: `2px solid ${primaryColor}`,
                              colur: grey,
                            },
                          }}
                          value={tabValue}
                          onChange={this.handleTabChange}
                          style={customersStyles.tabStyle(1, 2)}
                          variant="scrollable"
                        >
                          {itemCategoryTabData.length > 0
                            ? itemCategoryTabData.map((itemData, index) => (
                              <Tab
                                key={itemData?.id}
                                value={itemData?.id}
                                label={itemData?.category_name}
                                style={customersStyles.tabStyle(
                                  tabValue,
                                  itemData?.id
                                )}
                              />
                            ))
                            : null}
                        </Tabs>
                      </div>
                    </div>
                    <div>
                      <ContractsCustomersTable
                        tabValue={tabValue}
                        rows={rows}
                        {...this.props}
                      />
                    </div>
                  </>
                ) : itemDataFetchLoader ? (
                  <div className={classes.noDataDiv}>
                    <SpinLoader />
                  </div>
                ) : (
                  <div className={classes.noDataDiv}>
                    <NoDataFound />
                  </div>
                )} */}

                {
                  itemDataFetchLoader ? (
                    <div className={classes.noDataDiv}>
                      <SpinLoader />
                    </div>
                  ) : itemCategoryTabData.length > 0 ? (
                    <>
                      <div className={classes.tabContainerStyle}>
                        <div className={classes.tabContainerChildStyle}>
                          <Tabs
                            TabIndicatorProps={{
                              style: {
                                borderBottom: `2px solid ${primaryColor}`,
                                colur: grey,
                              },
                            }}
                            value={tabValue}
                            onChange={this.handleTabChange}
                            style={customersStyles.tabsStyle(1, 2)}
                            variant="scrollable"
                          >

                            {itemCategoryTabData.length > 0
                              ? itemCategoryTabData.map((itemData, index) => (
                                hasReadAccess(this.props.user, `${itemData?.item_category_code}-contracts`) &&
                                <Tab
                                  key={itemData?.id}
                                  value={itemData?.id}
                                  label={itemData?.category_name}
                                  style={customersStyles.tabStyle(
                                    tabValue,
                                    itemData?.id,
                                    isMobile
                                  )}
                                />
                              ))
                              : null}
                          </Tabs>
                        </div>
                      </div>
                      <div>
                        <ContractsCustomersTable
                          tabValue={tabValue}
                          page={page}
                          rows={rows}
                          fetchNextOrders={this.fetchNextOrders}
                          {...this.props}
                        />

                      </div>
                    </>
                  ) : (
                    <div className={classes.noDataDiv}>
                      <NoDataFound />
                    </div>
                  )
                }

              </Paper>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(customersStyles)(Contracts));
