import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import DisplayAppBannerData from "../DisplayAppBannerData";

class AppBannerWeb extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <DisplayAppBannerData
          {...this.props}
          selectedappBannerData={this.props.selectedappBannerData}
          appBannerOperation={this.props.appBannerOperation}
          selectedappBannerDataFetchLoader={this.props.selectedappBannerDataFetchLoader}
          selectedappBanner={this.props.selectedappBanner}
          openDialog={this.props?.openDialog}
          dialogTitle={this.props?.dialogTitle}
          dialogContent={this.props?.dialogContent}
          isLoadingRemove={this.props?.isLoadingRemove}
          removeAppBannerLoader={this.props?.removeAppBannerLoader}
          handleRemoveAppBanner={this.props?.handleRemoveAppBanner}
          handleRemoveConfirmationDialogClose={this.props?.handleRemoveConfirmationDialogClose}
          handleConfirmationDialogDeleteClick={this.props?.handleConfirmationDialogDeleteClick}
        />
      </Layout>
    );
  }
}
export default AppBannerWeb;
