import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { dailyRateScreenTextColor, primaryColor } from "../../../styles/colors";
import PanoramaFishEye from "@material-ui/icons/PanoramaFishEye";
import CheckCircle from "@material-ui/icons/CheckCircle";

const styles = {
  registerSelectCategoryWebMainBody: {
    height: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "85%",
    overflowY: "scroll",
  },
  selectContanier: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "22px",
    width: "80%",
    borderRadius: "10px",
    height: "15%",
    marginBottom: "20px",
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  },
  selectTextConatainer: {
    width: "70%",
    color: dailyRateScreenTextColor,
    fontSize: "0.9em",
  },
};
class RegisterSelectCategoryWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
    };
  }
  render() {
    const { broker, dealer } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.registerSelectCategoryWebMainBody}>
        <div className={classes.selectTextConatainer}>
          <span>Select the appropriate category</span>
        </div>

        <div
          className={classes.selectContanier}
          onClick={(e) => {
            e.preventDefault();
            this.setState(
              {
                category: "BROKER",
                broker: true,
                distributer: false,
                consumer: false,
                dealer: false,
              },
              () => {
                this.props.handleSelectCategory(this.state.category);
              }
            );
          }}
        >
          {broker ? (
            <CheckCircle style={{ color: primaryColor }} />
          ) : (
            <PanoramaFishEye />
          )}
          <span
            style={{
              color: dailyRateScreenTextColor,
              fontSize: "1.1em",
              paddingLeft: "10px",
            }}
          >
            I am a Broker / Distributor / Trader
          </span>
        </div>
        <div
          className={classes.selectContanier}
          onClick={(e) => {
            e.preventDefault();
            this.setState(
              {
                category: "DEALER",
                broker: false,
                distributer: false,
                consumer: false,
                dealer: true,
              },
              () => {
                this.props.handleSelectCategory(this.state.category);
              }
            );
          }}
        >
          {dealer ? (
            <CheckCircle style={{ color: primaryColor }} />
          ) : (
            <PanoramaFishEye />
          )}
          <span
            style={{
              color: dailyRateScreenTextColor,
              fontSize: "1.1em",
              paddingLeft: "10px",
            }}
          >
            I am a Dealer/ Consumer
          </span>
        </div>
        {/* <div
          className={classes.selectContanier}
          onClick={(e) => {
            e.preventDefault();
            this.setState(
              {
                category: "DISTRIBUTOR",
                broker: false,
                distributer: true,
                consumer: false,
                dealer: false,
              },
              () => {
                this.props.handleSelectCategory(this.state.category);
              }
            );
          }}
        >
          {distributer ? (
            <CheckCircle style={{ color: primaryColor }} />
          ) : (
            <PanoramaFishEye />
          )}
          <span
            style={{
              color: dailyRateScreenTextColor,
              fontSize: "1.1em",
              paddingLeft: "10px",
            }}
          >
            I am a Distributor
          </span>
        </div> */}
        {/* <div
          className={classes.selectContanier}
          onClick={(e) => {
            e.preventDefault();
            this.setState(
              {
                category: "DEALER",
                dealer: true,
                consumer: false,
                broker: false,
                distributer: false,
              },
              () => {
                this.props.handleSelectCategory(this.state.category);
              }
            );
          }}
        >
          {dealer ? (
            <CheckCircle style={{ color: primaryColor }} />
          ) : (
            <PanoramaFishEye />
          )}
          <span
            style={{
              color: dailyRateScreenTextColor,
              fontSize: "1.1em",
              paddingLeft: "10px",
            }}
          >
            I am a Dealer
          </span>
        </div> */}
      </div>
    );
  }
}
export default withStyles(styles)(RegisterSelectCategoryWeb);
