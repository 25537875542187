import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import { Popover } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { pxToEm } from "../../../methods";

const styles = {
    popoverBox: {
        '& .MuiPopover-paper': {
            backgroundColor: 'rgba(255,255,255,1)',
            color: '#000',
            marginBottom: 10
            // borderRadius: 20,
        }
    },
    popoverContent: {
        pointerEvents: 'auto',
        padding: "2px 8px",
        color: "#eee",
        backgroundColor: '#5A5A5A',
        fontSize: pxToEm(15)
    },
}

class PopOverComponent extends React.Component {
    state = {
        anchorEl: null,
        open: null,
    }

    handlePopoverOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl, } = this.state;
        const open = Boolean(this.state.anchorEl);
        return (
            <div>
                <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={this.handlePopoverOpen}
                    onMouseLeave={this.handlePopoverClose}
                    style={{
                        fontFamily: 'Poppins',
                        fontSize: "inherit",
                        textDecoration: "underline",
                        fontWeight: anchorEl === null ? "400" : "500",
                        color: anchorEl === null ? "#666" : "#3B69B0",
                        cursor: 'pointer'
                    }}
                >
                    {this.props.children}
                </Typography>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={this.handlePopoverClose}
                    disableRestoreFocus
                    style={styles.popoverBox}
                >
                    <Typography sx={{ p: 1 }}>
                        {this.props.content}
                    </Typography>
                </Popover>
            </div>
        )
    }
}

export default PopOverComponent;