import React, { Component } from 'react';
import Weighbridges from '../../../components/internal/weighbridges';
import OpenInDesktop from '../../../components/internal/common/OpenInDesktop';

class WeighbridgesContainer extends Component {
  render() {
    return this?.props?.isMobile ? (
      <OpenInDesktop {...this.props} />
    ) : (
      <Weighbridges {...this.props} {...this.states} />
    );
  }
}
export default WeighbridgesContainer;
