import React, { Component } from "react";
import "../../styles/common/SpinLoader.css";

class SpinLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="loader">Loading...</div>
      </div>
    );
  }
}
export default SpinLoader;
