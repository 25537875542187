import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";

import {
    grey,
    highlitedTextColor,
    successButtonBackground,
    whiteSmoke,
    secondaryBlueText,
    lightGreen
} from "../../../constants/internal/colors";
import {
    Checkbox,
    Divider,
    Paper,
    Typography,
    Grow,
    TextField,
    InputAdornment,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    Select,
    DialogActions,
    Button,
    MenuItem,
    CircularProgress,
    InputLabel,
} from "@material-ui/core";
import { PieChart } from "react-minimal-pie-chart";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ClearOutlinedIcon from "@material-ui/icons/CancelOutlined";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { fontWeight, textAlign } from "@mui/system";
import { lightBlue } from "@material-ui/core/colors";
import { pxToEm } from "../../../methods";
import { primaryColor } from "../../../styles/colors";

const styles = {
    plantPlaningContainer: {
        width: "100%",
        padding: "10px 0px 20px 5px"
    },
    paperStyle: {
        boxShadow:
            "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
        width: "100%",
        paddingBottom: "20px",
    },
    orderOverviewCards: {
        display: "flex",
        //width: "99%",
        justifyContent: "space-evenly",
        // display: "flex",
        // gridTemplateColumns: "repeat(auto-fit, 99%)",
        gridGap: "1rem",
        // justifyContent: "space-between",
        //alignContent: "flex-start"


    },
    buyerConsigneeCustomerContainer: {
        display: "inline",
        //flex: "0 1 38%",
        //width: "50%",
        justifyContent: "space-evenly",
        // padding: "20px 0px",
        width: "38%"
    },
    buyerConsigneeCustomerBox: {
        backgroundColor: "white",
        border: "1px solid gainsboro",
        borderRadius: "15px",
    },
    itemDetailsCustomerBox: {
        backgroundColor: "white",
        border: "1px solid gainsboro",
        borderRadius: "15px",
        marginBottom: "20px",
        //width: "99%"
        // padding: "15px 20px 10px 20px"
    },
    buyerConsigneeCustomerNameText: {
        color: "rgb(88,88,88)",
        fontWeight: "bold",
        fontSize: window.innerWidth < 375 && pxToEm(14)

    },
    buyerConsigneeCustomerBoxTitle: {
        color: "rgb(88,88,88)",
        fontWeight: "bold",
        padding: "12px 0px 12px 15px",
        backgroundColor: "white",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        fontSize: window.innerWidth < 375 && pxToEm(15)
    },
    buyerTextStyle: {
        color: "rgb(112,112,112)",
        //alignItems: "right",
        textAlign: "right",
        //justifyContent: "flex-start"
        padding: "2px 0px 2px 30px",
        wordBreak: 'break-word',
        fontSize: window.innerWidth < 375 && pxToEm(14)
    },
    basicInfoBoxTitle: {
        color: "rgb(88,88,88)",
        fontWeight: "bold",


        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
    },
    keyAndValueContainer: {
        display: "flex",
        justifyContent: "space-between",
        //paddingBottom: "8px",
        alignItems: "left",
        padding: "8px 8px",
        // textAlign: "left"

    },
    TotalDiscountAmountBox: {

        padding: "40px 40px 40px 40px",
        textAlign: "center",
        justifyContent: "space-evenly",

        fontSize: "18px",
        color: "#34619C",
        background: "#F6F7FB",
    },

    TotalApproximateAmountBox: {
        padding: window.innerWidth < 375 ? 20 : 40,
        textAlign: "center",
        justifyContent: "space-evenly",
        fontSize: "18px",
        color: lightGreen,
        background: "#F3FAF3",
    },
    keyAndValueContainerBox: {
        background: whiteSmoke,
        border: "1px solid gainsboro",
        borderRadius: "4px",
    },
    singleDiv: {
        padding: "10px 15px 15px 15px",

    },

    singleDiscountDiv: {
        padding: "10px 15px 15px 15px",
    },

    singleApproximateAmntDiv: {
        padding: "10px 15px 15px 15px",
    },
    itemDetailContainer: {
        display: "flex",
        flex: "0 1 35%",
        flexDirection: "column",
        width: "48%",
        paddingTop: "20px",
        // paddingLeft: "20px"

    },
    itemDetailContainerLeft: {
        //width: "90%",
        // padding: "20px 10px",
        padding: "10px 15px 15px 15px",
        background: whiteSmoke,
        border: "1px solid gainsboro",
        borderRadius: "4px"

        //justifyContent: "space-between",

    },
    itemDetailContainerRight: {
        width: "90%",
        display: "flex",
    },
    itemTextStyle: {
        color: "rgb(88,88,88)",
        fontWeight: "bold",
        width: "80%",
    },
    checkboxContainerStyle: {
        display: "flex",
        // paddingTop: "20px",
        paddingLeft: "22px",
        alignItems: "center",
        marginBottom: "15px",
    },
    // discountContainerStyle: {
    //   display: "inline",
    //   paddingTop: "20px",
    //   paddingBottom: "60px",
    //   width: "38%",
    //   justifyContent: "space-evenly",
    // },
    approximateContainerStyle: {
        paddingTop: "20px",
        paddingBottom: "60px",
        width: "99%",
        justifyContent: "space-evenly",
    },
    wrapDiscountContainerStyle: {
        display: "inline",
        //flex: "0 1 33%",
        paddingTop: "20px",
        //paddingBottom: "60px",
        width: "33%",
        justifyContent: "space-evenly",

    },
    discountContainerStyleLeft: {
        //width: "50%",
        // paddingLeft: "50px",
        width: "96%",
        border: "1px solid gainsboro",
        borderRadius: "15px",

    },

    approvalContainerStyleLeft: {
        backgroundColor: "white",
        //width: "90%",
    },
    discountContainerStyleRight: {
        // width: "50%",
        //paddingTop: "20px",
        // width: "96%",
        border: "1px solid gainsboro",
        borderRadius: "15px",
    },
    discountHeaderDiv: {
        padding: "10px 0px",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
    },
    discountTextStyle: {
        fontWeight: "bold",
        fontSize: "16px",
        color: "rgb(88,88,88)",
        fontSize: window.innerWidth < 375 && pxToEm(15)
    },
    discountListStyleDiv: {
        display: "flex",
        //placeItems: "center",
        padding: "10px 4.5px 8px 8px",
        //justifyContent: "space-between",
        //width: "40%"


    },
    approvalListStyleDiv: {
        display: "flex",
        //placeItems: "center",
        padding: "10px 8px",
        //justifyContent: "space-between",
        //width: "40%"
    },

    ApprovalContainerBox: (plantPlanning) => ({
        color: plantPlanning ? "#F3FAF3" : grey,
        background: plantPlanning ? "#F3FAF3" : whiteSmoke,
        marginBottom: "15px",

    }),

    discountListStyleText: {
        width: "90%",
        color: "rgb(88,88,88)",
        fontWeight: "bold",
        fontSize: window.innerWidth < 375 && pxToEm(14)
    },


    approvalListStyleText: (plantPlanning) => ({
        width: "90%",
        color: plantPlanning ? lightGreen : "rgb(112,112,112)",
        fontSize: window.innerWidth < 375 && pxToEm(14)
    }),

    discountListStyleValue: {
        width: "30%",
        //textAlign: "right",
        color: "rgb(112,112,112)",
        textAlign: "end",
        fontSize: window.innerWidth < 375 && pxToEm(14)
        //marginRight: "50px"
    },

    basicInfoListStyleValue: {
        width: "70%",
        //textAlign: "right",
        color: "rgb(112,112,112)",
        alignItems: "center"
    },
    editButtonDiv: {
        padding: "45px 65px 20px 0px",
        display: "flex",
        justifyContent: "flex-end",
    },
    recentActivityPaper: {
        padding: "10px 10px 10px 30px",
        width: "45%",
        marginTop: "30px",
        boxShadow:
            "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    },
    recentActivityTextDiv: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
        alignItems: "center",
    },
    recentActivityTextDivLeft: {
        display: "flex",
        flexDirection: "column",
        lineHeight: "1.6em",
    },
    peiChartContainerStyle: {
        width: "50%",
        padding: "25px",
    },
    peiChartInfoContainerStyle: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px",
        flexDirection: "column",
        justifyContent: "center",
    },
    squareDispatchedStyle: {
        height: "20px",
        width: "20px",
        background: highlitedTextColor,
        marginRight: "10px",
    },
    squareInProcessStyle: {
        height: "20px",
        width: "20px",
        background: "#b2df8a",
        marginRight: "10px",
    },
    squareInPendingStyle: {
        height: "20px",
        width: "20px",
        background: "#a5cee3",
        marginRight: "10px",
    },
    checkboxLabelLeft: {
        color: grey,
        fontWeight: "bold",
        width: "90%",
    },
    editIcons: {
        fontSize: "small",
        marginLeft: "50%",
        cursor: "pointer",
        border: 0,
        color: "#34619C",
        fontWeight: 600,

    },
    saveIcons: { fontSize: "large", marginLeft: "5px", cursor: "pointer" },
    fieldAndIcon: {
        display: "flex",
        placeItems: "center",
        width: "90%",
        color: "rgb(112,112,112)",
        overflowY: "visible"

    },
    fieldAndIconSpecialDiscount: {
        //display: "flex",
        placeItems: "center",
        width: "30%",
        color: "rgb(112,112,112)",
        textAlign: "end"
    },
    lowPadding: {
        padding: "10px",
    },
    checkIcon: (plantPlanning) => ({
        color: plantPlanning ? successButtonBackground : grey,
        transform: window.innerWidth < 375 && 'scale(0.8)'
    }),
};
const inputPropsPadding = {
    padding: "5px 5px 5px 10px",
};
class SingleContractsOrderOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editValidityDays: false,
            validityDays: this.props.orderDetails?.validity_days,
            editInternalRemark: false,
            internalRemark: null,
            editSpecialDiscountAmount: false,
            specialDiscountAmount:
                this?.props?.orderDetails?.discounts?.special_discount || 0,
            orderDetails: this.props.orderDetails,
            adminApproval: false,
            clientApproval: false,
            allowBreakingFifo: false,
            openDialog: false,
            cancel_reason: '',
            MenuProps: {
                PaperProps: {
                    style: {
                        maxHeight: 45 * 4 + 10,
                    },
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            },
        };
    }

    getOptions = (user) => {
        return {
            headers: {
                Authorization: `JWT ${user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    };

    componentDidMount() {
        this.setState({
            validityDays: this?.props?.orderDetails?.validity_days,
            internalRemark: this?.props?.orderDetails?.internal_remark || "",
            specialDiscountAmount:
                this?.props?.orderDetails?.discounts?.special_discount || 0,
            orderDetails: this?.props?.orderDetails,
            adminApproval: this?.props?.orderDetails?.is_approved_by_admin,
            clientApproval: this?.props?.orderDetails?.is_approved_by_customer,
            allowBreakingFifo: this?.props?.orderDetails?.is_fifo_allowed,
        });
    }
    static getDerivedStateFromProps(props, state) {
        if (props?.orderDetails !== state.orderDetails) {
            return {
                validityDays: this?.props?.orderDetails?.validity_days,
                internalRemark: props?.orderDetails?.internal_remark,
                specialDiscountAmount: props?.orderDetails?.discounts?.special_discount,
                orderDetails: props?.orderDetails,
                adminApproval: props?.orderDetails?.is_approved_by_admin,
                clientApproval: props?.orderDetails?.is_approved_by_customer,
                allowBreakingFifo: props?.orderDetails?.is_fifo_allowed,
            };
        }

        return null;
    }
    handleChange = (name) => (event) => {
        const { checked, value } =
            event.target < 0 ? (event.target = 0) : event.target;

        this.setState({ [name]: value });
    };

    updateOrder = (orderData, orderId) => {
        return axios.patch(
            `${APIROUTES.CONTRACTS_UPDATE}${orderId}/`,
            orderData,
            this.getOptions(this.props.user)
        );
    };

    handleSaveEditIconClick = (operation, field) => {
        if (operation === "edit" && field === "validityDays") {
            //console.log("save the validity days");
            this.setState({ editValidityDays: !this.state.editValidityDays });
            // console.log(this.state.validityDays);
            let orderData = {
                customer: this.state.orderDetails?.customer?.id,
                item_category: this.state.orderDetails?.item_category?.id,
                special_discount: this.state.orderDetails?.discounts?.special_discount,
                validity_days: this.state.validityDays,
            };
            this.updateOrder(orderData, this.state.orderDetails?.id);
        } else if (
            (operation === "cancel" || operation === "update") &&
            field === "validityDays"
        ) {
            this.setState({ editValidityDays: !this.state.editValidityDays });
        } else if (operation === "edit" && field === "internalRemark") {
            // console.log("save the internal remark");
            //console.log(this.state.orderDetails);
            this.setState({ editInternalRemark: !this.state.editInternalRemark });
            let orderData = {
                customer: this.state.orderDetails?.customer?.id,
                item_category: this.state.orderDetails?.item_category?.id,
                special_discount: this.state.orderDetails?.discounts?.special_discount,
                internal_remark: this.state.internalRemark,
            };
            this.updateOrder(orderData, this.state.orderDetails?.id);
        } else if (
            (operation === "cancel" || operation === "update") &&
            field === "internalRemark"
        ) {
            this.setState({ editInternalRemark: !this.state.editInternalRemark });
        } else if (operation === "edit" && field === "specialDiscountAmount") {
            let orderData = {
                customer: this.state.orderDetails?.customer?.id,
                item_category: this.state.orderDetails?.item_category?.id,
                special_discount: this.state.specialDiscountAmount,
            };
            this.updateOrder(orderData, this.state.orderDetails?.id);
            this.setState({
                editSpecialDiscountAmount: !this.state.editSpecialDiscountAmount,
            });
        } else if (
            (operation === "cancel" || operation === "update") &&
            field === "specialDiscountAmount"
        ) {
            this.setState({
                editSpecialDiscountAmount: !this.state.editSpecialDiscountAmount,
            });
        } else {
            console.log("invalid days");
        }
    };

    totalDiscount = (data) => {
        const discounts = data;
        return (
            parseFloat(discounts?.item_discount || 0) +
            parseFloat(discounts?.customer_discount || 0) +
            parseFloat(discounts?.city_discount || 0) +
            parseFloat(discounts?.payment_discount || 0) +
            parseFloat(this.state.specialDiscountAmount || 0)
        ).toFixed(0);
    };

    render() {
        const { classes, user, isMobile, } = this.props;
        const {
            editValidityDays,
            validityDays,
            editInternalRemark,
            internalRemark,
            editSpecialDiscountAmount,
            specialDiscountAmount,
            orderDetails,

        } = this.state;
        // console.log(this.props)
        return (
            <div style={{
                marginBottom: "20px"
            }}>
                {/* <Paper elevation={1} className={classes.paperStyle}> */}
                <div style={{
                    display: "flex",
                    flexDirection: isMobile && "column",
                    // width: "98%",
                    padding: isMobile && "0px 5px",
                    justifyContent: "space-between",
                    flexWrap: 'wrap'
                    // display: "flex",
                    // gridTemplateColumns: "repeat(auto-fit, 99%)",
                    // gridGap: "1rem",
                }}>
                    <div style={{
                        // backgroundColor: "green",
                        display: "inline",
                        //flex: "0 1 38%",
                        //width: "50%",
                        justifyContent: "space-evenly",
                        // padding: "20px 0px",
                        width: !isMobile && "48%"
                    }}>
                        <div className={classes.itemDetailContainer} style={{ width: "100%" }}>
                            <div className={classes.itemDetailsCustomerBox}>
                                <Typography
                                    className={classes.basicInfoBoxTitle}
                                    style={{
                                        padding: "12px 0px 8px 15px",
                                        fontSize: window.innerWidth < 375 && pxToEm(15)
                                    }}
                                >
                                    Basic Info
                                </Typography>
                                <div className={classes.singleDiv}>
                                    <div className={classes.keyAndValueContainerBox}>
                                        <div className={classes.keyAndValueContainer}>
                                            <span
                                                className={classes.buyerConsigneeCustomerNameText}
                                            >
                                                Payment Term
                                            </span>
                                            <span className={classes.buyerTextStyle}>{orderDetails?.payment_method?.payment_method_name || ""}</span>
                                        </div>
                                        <div className={classes.keyAndValueContainer}>
                                            <span
                                                className={classes.buyerConsigneeCustomerNameText}
                                            >
                                                Customer Remark
                                            </span>
                                            <span className={classes.buyerTextStyle}>{orderDetails?.customer_remark || ""}</span>
                                        </div>
                                    </div>
                                    <Grow
                                        in={true}
                                        style={{ transformOrigin: "0 0 0" }}
                                        {...{ timeout: 3000 }}
                                    >
                                        <div className={classes.approvalContainerStyleLeft}>
                                            <div className={classes.discountHeaderDiv}>
                                                <span className={classes.basicInfoBoxTitle}
                                                    style={{ fontSize: "16px", padding: "112px 0px 8px 0px", fontSize: window.innerWidth < 375 && pxToEm(15) }}>
                                                    Approval Status
                                                </span>
                                            </div>
                                            <div style={styles.ApprovalContainerBox(this.state.adminApproval)}>
                                                <div className={classes.approvalListStyleDiv}>
                                                    <span style={styles.approvalListStyleText(this.state.adminApproval)}>
                                                        {this.state.adminApproval ? "Admin Approved" : "Admin Approval"}
                                                    </span>
                                                    <CheckCircleOutlineOutlinedIcon
                                                        style={styles.checkIcon(this.state.adminApproval)}
                                                    />

                                                </div>
                                            </div>

                                        </div>

                                    </Grow>
                                </div>

                            </div>
                            <Grow
                                in={true}
                                style={{ transformOrigin: "0 0 0" }}
                                {...{ timeout: 1000 }}
                            >
                                <div className={classes.buyerConsigneeCustomerBox}>
                                    <Typography
                                        className={classes.buyerConsigneeCustomerBoxTitle}
                                    >
                                        Booked By
                                    </Typography>
                                    <div className={classes.singleDiv}>
                                        <div className={classes.keyAndValueContainerBox}>
                                            <div className={classes.keyAndValueContainer}>
                                                <span
                                                    className={classes.buyerConsigneeCustomerNameText}
                                                >
                                                    Name
                                                </span>
                                                <span className={classes.buyerTextStyle}>{`${user?.first_name} ${user?.last_name}`}</span>
                                            </div>
                                            {/* <div className={classes.keyAndValueContainer}>
                                                <span
                                                    className={classes.buyerConsigneeCustomerNameText}
                                                >
                                                    Type
                                                </span>
                                                <span className={classes.buyerTextStyle}>
                                                    {user?.customer_type || ""}
                                                </span>
                                            </div> */}
                                            <div className={classes.keyAndValueContainer}>
                                                <span
                                                    className={classes.buyerConsigneeCustomerNameText}
                                                >
                                                    {!user?.gst_information?.gstin_number
                                                        ? "PAN"
                                                        : "GSTIN"}
                                                </span>
                                                <span className={classes.buyerTextStyle}>
                                                    {!user?.gst_information
                                                        ? !user?.pan_card_information?.pan_card_number
                                                            ? ""
                                                            : user?.pan_card_information?.pan_card_number || ""
                                                        : user?.gst_information?.gstin_number || ""}
                                                </span>
                                            </div>

                                            <div className={classes.keyAndValueContainer}>
                                                <span className={classes.buyerConsigneeCustomerNameText}>
                                                    Address
                                                </span>

                                                <span className={classes.buyerTextStyle}>
                                                    {
                                                        user?.address?.address ?
                                                            `${user?.address?.address} - ${user?.address?.pincode}`
                                                            :
                                                            `${user?.address?.door_number && `#${user?.address?.door_number},`}
                      ${user?.address?.floor_number &&
                                                            `${user?.address?.floor_number === 0 ? null :
                                                                user?.address?.floor_number}${user?.address?.floor_number === "0" ?
                                                                    null : user?.address?.floor_number === "1" ? "st" :
                                                                        user?.address?.floor_number === "2" ? "nd" : "rd"
                                                            } floor,`}
                      ${user?.address?.building_name && `,${user?.address?.building_name},`}
                      ${user?.address?.street && `${user?.address?.street},`}
                      ${user?.address?.location && `${user?.address?.location},`}
                      ${user?.address?.city_name && `${user?.address?.city_name}`}
                      ${user?.address?.pincode && `-${user?.address?.pincode}`}`
                                                    }




                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grow>
                        </div>
                    </div>
                    {orderDetails?.consignee || orderDetails?.buyer ?
                        (<div style={{
                            display: "inline",
                            //flex: "0 1 38%",
                            //width: "50%",
                            justifyContent: "space-evenly",
                            // padding: "20px 0px",
                            width: !isMobile && "48%"
                        }}>

                            <div style={{ paddingTop: "20px" }}>
                                <div className={classes.buyerConsigneeCustomerBox}>
                                    {!orderDetails?.buyer ? null : (

                                        <div>
                                            <Typography
                                                className={classes.buyerConsigneeCustomerBoxTitle}
                                            >
                                                Buyer Info
                                            </Typography>
                                            <div className={classes.singleDiv}>
                                                <div className={classes.keyAndValueContainerBox}>
                                                    <div className={classes.keyAndValueContainer}>
                                                        <span
                                                            className={classes.buyerConsigneeCustomerNameText}
                                                        >
                                                            Name
                                                        </span>
                                                        <span className={classes.buyerTextStyle}>{`${orderDetails?.buyer?.first_name || ""} ${orderDetails?.buyer?.last_name || ""
                                                            }`}</span>
                                                    </div>

                                                    {/* <div className={classes.keyAndValueContainer}>
                                                    <span
                                                        className={classes.buyerConsigneeCustomerNameText}
                                                    >
                                                        Type
                                                    </span>
                                                    <span className={classes.buyerTextStyle}>{orderDetails?.buyer?.customer_type || ""}</span>
                                                </div> */}
                                                    <div className={classes.keyAndValueContainer}>
                                                        <span
                                                            className={classes.buyerConsigneeCustomerNameText}
                                                        >
                                                            {!orderDetails?.buyer?.gstin_number ? "PAN" : "GSTIN"}
                                                        </span>
                                                        <span className={classes.buyerTextStyle}>
                                                            {!orderDetails?.buyer?.gstin_number
                                                                ? !orderDetails?.buyer?.pan_card_number
                                                                    ? ""
                                                                    : orderDetails?.buyer?.pan_card_number || ""
                                                                : orderDetails?.buyer?.gstin_number || ""}
                                                        </span>
                                                    </div>
                                                    {orderDetails?.buyer?.phone ?

                                                        <div className={classes.keyAndValueContainer}>
                                                            <span
                                                                className={classes.buyerConsigneeCustomerNameText}
                                                            >
                                                                Phone
                                                            </span>
                                                            <span className={classes.buyerTextStyle}>{`+${orderDetails?.buyer?.phone.length === 10 ?
                                                                `91${orderDetails?.buyer?.phone}` : orderDetails?.buyer?.phone}`}</span>
                                                        </div> : ""
                                                    }
                                                    {orderDetails?.buyer?.email ?
                                                        <div className={classes.keyAndValueContainer}>
                                                            <span
                                                                className={classes.buyerConsigneeCustomerNameText}
                                                            >
                                                                Email
                                                            </span>
                                                            <span className={classes.buyerTextStyle}>{`${orderDetails?.buyer?.email || ""}`} </span>
                                                        </div> : ""
                                                    }
                                                    <div className={classes.keyAndValueContainer}>
                                                        <span
                                                            className={classes.buyerConsigneeCustomerNameText}
                                                        >
                                                            Address
                                                        </span>
                                                        <span className={classes.buyerTextStyle}>
                                                            {((orderDetails?.buyer?.address?.address
                                                                ? orderDetails?.buyer?.address?.address + ","
                                                                : "") ||
                                                                (orderDetails?.buyer?.address?.building_name
                                                                    ? orderDetails?.buyer?.address?.building_name + ","
                                                                    : " ") +
                                                                (orderDetails?.buyer?.address?.street
                                                                    ? orderDetails?.buyer?.address?.street +
                                                                    ","
                                                                    : " ") +
                                                                (orderDetails?.buyer?.address?.location
                                                                    ? orderDetails?.buyer?.address?.location +
                                                                    ","
                                                                    : " ")) +
                                                                " " +
                                                                ((orderDetails?.buyer?.city_name
                                                                    ? orderDetails?.buyer?.address?.city_name + ","
                                                                    : " ") ||
                                                                    (orderDetails?.buyer?.address?.district_name
                                                                        ? orderDetails?.buyer?.address
                                                                            ?.district_name + ","
                                                                        : " ")) +
                                                                " " +
                                                                ((orderDetails?.buyer?.address?.state
                                                                    ? orderDetails?.buyer?.address?.state + ","
                                                                    : "") ||
                                                                    (orderDetails?.buyer?.address?.state_name
                                                                        ? orderDetails?.buyer?.address
                                                                            ?.state_name + ","
                                                                        : "")) +
                                                                " " +
                                                                orderDetails?.buyer?.address?.pincode}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                    {!orderDetails?.consignee ? null : (

                                        <div>
                                            <Typography
                                                className={classes.buyerConsigneeCustomerBoxTitle}
                                            >
                                                Consignee
                                            </Typography>
                                            <div className={classes.singleDiv}>
                                                <div className={classes.keyAndValueContainerBox}>
                                                    <div className={classes.keyAndValueContainer}>
                                                        <span
                                                            className={classes.buyerConsigneeCustomerNameText}
                                                        >
                                                            Name
                                                        </span>
                                                        <span className={classes.buyerTextStyle}>{`${orderDetails?.consignee?.consignee_detail
                                                            ?.first_name || ""
                                                            } ${orderDetails?.consignee?.consignee_detail
                                                                ?.last_name || ""
                                                            }`}</span>
                                                    </div>
                                                    {/* <div className={classes.keyAndValueContainer}>
                                                    <span
                                                        className={classes.buyerConsigneeCustomerNameText}
                                                    >
                                                        Type
                                                    </span>
                                                    <span className={classes.buyerTextStyle}>
                                                        {orderDetails?.consignee?.consignee_detail
                                                            ?.customer_type || ""}
                                                    </span>
                                                </div> */}
                                                    <div className={classes.keyAndValueContainer}>
                                                        <span
                                                            className={classes.buyerConsigneeCustomerNameText}
                                                        >
                                                            {!orderDetails?.consignee?.consignee_detail
                                                                ?.gstin_number
                                                                ? "PAN"
                                                                : "GSTIN"}
                                                        </span>
                                                        <span className={classes.buyerTextStyle}>
                                                            {!orderDetails?.consignee?.consignee_detail
                                                                ?.gstin_number
                                                                ? !orderDetails?.consignee?.consignee_detail
                                                                    ?.pan_card_number
                                                                    ? ""
                                                                    : orderDetails?.consignee?.consignee_detail
                                                                        ?.pan_card_number || ""
                                                                : orderDetails?.consignee?.consignee_detail
                                                                    ?.gstin_number || ""}
                                                        </span>
                                                    </div>

                                                    {orderDetails?.consignee && orderDetails?.consignee?.consignee_detail && orderDetails?.consignee?.consignee_detail?.phone ?
                                                        <div className={classes.keyAndValueContainer}>
                                                            <span
                                                                className={classes.buyerConsigneeCustomerNameText}
                                                            >
                                                                Phone
                                                            </span>
                                                            <span className={classes.buyerTextStyle}>{`${orderDetails?.consignee?.consignee_detail
                                                                ?.phone || ""
                                                                } ${orderDetails?.consignee?.consignee_detail
                                                                    ?.last_name || ""
                                                                }`}</span>
                                                        </div>
                                                        : ""
                                                    }

                                                    {orderDetails?.consignee && orderDetails?.consignee?.consignee_detail && orderDetails?.consignee?.consignee_detail?.email ?
                                                        <div className={classes.keyAndValueContainer}>
                                                            <span
                                                                className={classes.buyerConsigneeCustomerNameText}
                                                            >
                                                                Email
                                                            </span>
                                                            <span className={classes.buyerTextStyle}>{`${orderDetails?.consignee?.consignee_detail
                                                                ?.email || ""} `}</span>
                                                        </div>
                                                        : ""
                                                    }

                                                    <div className={classes.keyAndValueContainer}>
                                                        <span
                                                            className={classes.buyerConsigneeCustomerNameText}
                                                        >
                                                            Address
                                                        </span>
                                                        <span className={classes.buyerTextStyle}>
                                                            {((orderDetails?.consignee?.consignee_address?.address
                                                                ? orderDetails?.consignee?.consignee_address
                                                                    ?.address + ","
                                                                : "") ||
                                                                (orderDetails?.consignee?.consignee_address
                                                                    ?.building_name
                                                                    ? orderDetails?.consignee?.consignee_address
                                                                        ?.building_name + ","
                                                                    : " ") +
                                                                (orderDetails?.consignee?.consignee_address
                                                                    ?.street
                                                                    ? orderDetails?.consignee?.consignee_address
                                                                        ?.street + ","
                                                                    : " ") +
                                                                (orderDetails?.consignee?.consignee_address
                                                                    ?.location
                                                                    ? orderDetails?.consignee?.consignee_address
                                                                        ?.location + ","
                                                                    : " ")) +
                                                                " " +
                                                                ((orderDetails?.consignee?.consignee_address?.city_name
                                                                    ? orderDetails?.consignee?.consignee_address
                                                                        ?.city_name + ","
                                                                    : " ") ||
                                                                    (orderDetails?.consignee?.consignee_address
                                                                        ?.district_name
                                                                        ? orderDetails?.consignee?.consignee_address
                                                                            ?.district_name + ","
                                                                        : " ")) +
                                                                " " +
                                                                ((orderDetails?.consignee?.consignee_address?.state
                                                                    ? orderDetails?.consignee?.consignee_address
                                                                        ?.state + ","
                                                                    : "") ||
                                                                    (orderDetails?.consignee?.consignee_address
                                                                        ?.state_name
                                                                        ? orderDetails?.consignee?.consignee_address
                                                                            ?.state_name + ","
                                                                        : "")) +
                                                                " " +
                                                                orderDetails?.consignee?.consignee_address?.pincode}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>) : null}
                    <div style={{
                        display: "inline",
                        //flex: "0 1 38%",
                        //width: "50%",
                        justifyContent: "space-evenly",
                        // padding: "20px 0px",
                        width: !isMobile && "48%"
                    }}>

                        <div style={{ paddingTop: "20px" }}>
                            <div className={classes.buyerConsigneeCustomerBox}>
                                <Grow
                                    in={true}
                                    style={{ transformOrigin: "0 0 0" }}
                                    {...{ timeout: 1000 }}
                                >
                                    <div className={classes.discountContainerStyleRight}>
                                        <div className={classes.discountHeaderDiv} style={{ paddingLeft: 15 }}>
                                            <span className={classes.discountTextStyle}>
                                                Approximate Amount
                                            </span>
                                        </div>
                                        <div className={classes.singleApproximateAmntDiv}>
                                            <div className={classes.TotalApproximateAmountBox}>

                                                <div style={{ fontWeight: 600, fontSize: window.innerWidth < 375 ? pxToEm(20) : "24px", wordBreak: 'break-word' }}>₹{" "}
                                                    {((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                                        (parseFloat(
                                                            this.totalDiscount(orderDetails?.discounts))
                                                        )) *
                                                        parseFloat(
                                                            Number(orderDetails?.quantity).toFixed(3) || 0
                                                        ) +
                                                        (parseFloat(
                                                            Number(orderDetails?.rate).toFixed(2) || 0
                                                        ) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                                            parseFloat(
                                                                this.totalDiscount(orderDetails?.discounts)
                                                            )) *
                                                        parseFloat(
                                                            Number(orderDetails?.quantity).toFixed(3) || 0
                                                        ) *
                                                        (parseFloat(
                                                            Number(orderDetails?.item_category?.igst).toFixed(
                                                                2
                                                            ) || 0
                                                        ) /
                                                            100)).toFixed(2)}</div>

                                                <div style={{ fontWeight: 500, fontSize: window.innerWidth < 375 && pxToEm(16), wordBreak: 'break-word' }}>Total Approximate Amount</div>
                                            </div>
                                            <div className={classes.keyAndValueContainerBox}>
                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText} style={{ width: '50%' }}>
                                                        Contract Rate
                                                    </span>
                                                    <span className={classes.discountListStyleValue} style={{ width: '50%' }}>
                                                        ₹ {Number(orderDetails?.rate).toFixed(2) || 0}
                                                    </span>
                                                </div>

                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText}>
                                                        Payment Basis Premium
                                                    </span>
                                                    <span className={classes.discountListStyleValue}>
                                                        ₹ {Number(orderDetails?.discounts?.payment_premium).toFixed(2) || 0}
                                                    </span>
                                                </div>

                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText}>
                                                        Loading Premium
                                                    </span>
                                                    <span className={classes.discountListStyleValue}>
                                                        ₹ {orderDetails?.discounts?.loading_premium.toFixed(2)}
                                                    </span>
                                                </div>

                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText}>
                                                        Insurance Premium
                                                    </span>
                                                    <span className={classes.discountListStyleValue}>
                                                        ₹ {orderDetails?.discounts?.insurance_premium.toFixed(2)}
                                                    </span>
                                                </div>

                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText}>
                                                        Freight Rate
                                                    </span>
                                                    <span className={classes.discountListStyleValue}>
                                                        ₹ {Number(orderDetails?.freight_charge_percent).toFixed(2)}
                                                    </span>
                                                </div>


                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText}>
                                                        All Discounts
                                                    </span>
                                                    <span className={classes.discountListStyleValue}>
                                                        ₹ {this.totalDiscount(orderDetails?.discounts)}
                                                    </span>
                                                </div>
                                                <Divider variant="fullWidth" />

                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText} style={{ width: '50%' }}>
                                                        Discounted Rate
                                                    </span>
                                                    <span className={classes.discountListStyleValue} style={{ width: '50%' }}>
                                                        ₹{" "}
                                                        {(parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                                            parseFloat(
                                                                this.totalDiscount(orderDetails?.discounts)
                                                            )).toFixed(2)}{" "}
                                                    </span>
                                                </div>
                                                <Divider variant="fullWidth" />

                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText} style={{ width: '50%' }}>
                                                        Taxable Amount
                                                    </span>
                                                    <span className={classes.discountListStyleValue} style={{ width: '50%' }}>
                                                        ₹{" "}
                                                        {((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                                            (parseFloat(
                                                                this.totalDiscount(orderDetails?.discounts))
                                                            )) *
                                                            parseFloat(
                                                                Number(orderDetails?.quantity).toFixed(3) || 0
                                                            )).toFixed(2)}{" "}
                                                    </span>
                                                </div>
                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText} style={{ width: '50%' }}>
                                                        GST{" "}
                                                        {parseFloat(
                                                            Number(orderDetails?.item_category?.igst).toFixed(0) ||
                                                            0
                                                        )}{" "}
                                                        %
                                                    </span>
                                                    <span className={classes.discountListStyleValue} style={{ width: '50%' }}>
                                                        ₹{" "}
                                                        {Number((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                                            (parseFloat(
                                                                this.totalDiscount(orderDetails?.discounts))
                                                            )) *
                                                            parseFloat(
                                                                Number(orderDetails?.quantity).toFixed(3) || 0
                                                            ) *
                                                            (parseFloat(
                                                                Number(orderDetails?.item_category?.igst).toFixed(
                                                                    2
                                                                ) || 0
                                                            ) /
                                                                100)).toFixed(2)}
                                                    </span>
                                                </div>
                                                <Divider variant="fullWidth" />

                                                <div className={classes.discountListStyleDiv}>

                                                    <span className={classes.discountListStyleText} style={{ width: '60%' }}>
                                                        Total Approximate Amount
                                                    </span>
                                                    <span className={classes.discountListStyleValue} style={{ color: "#34619C", fontWeight: 500, width: '40%' }}>
                                                        ₹{" "}
                                                        {((parseFloat(Number(orderDetails?.rate).toFixed(2) || 0) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                                            (parseFloat(
                                                                this.totalDiscount(orderDetails?.discounts))
                                                            )) *
                                                            parseFloat(
                                                                Number(orderDetails?.quantity).toFixed(3) || 0
                                                            ) +
                                                            (parseFloat(
                                                                Number(orderDetails?.rate).toFixed(2) || 0
                                                            ) + parseFloat(Number(orderDetails?.discounts?.payment_premium)) + parseFloat(Number(orderDetails?.discounts?.loading_premium)) + parseFloat(Number(orderDetails?.discounts?.insurance_premium)) + parseFloat(Number(orderDetails?.freight_charge_percent)) -
                                                                parseFloat(
                                                                    this.totalDiscount(orderDetails?.discounts)
                                                                )) *
                                                            parseFloat(
                                                                Number(orderDetails?.quantity).toFixed(3) || 0
                                                            ) *
                                                            (parseFloat(
                                                                Number(orderDetails?.item_category?.igst).toFixed(
                                                                    2
                                                                ) || 0
                                                            ) /
                                                                100)).toFixed(2)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: "inline",
                        justifyContent: "space-evenly",
                        width: !isMobile && "48%"
                    }}>

                        <div style={{ paddingTop: "20px", display: orderDetails?.created_from === 'PORTAL' && 'none' }}>
                            <div className={classes.buyerConsigneeCustomerBox}>
                                <Grow
                                    in={true}
                                    style={{ transformOrigin: "0 0 0" }}
                                    {...{ timeout: 1000 }}
                                >
                                    <div className={classes.discountContainerStyleRight}>
                                        <div className={classes.discountHeaderDiv} style={{ paddingLeft: 15 }}>
                                            <span className={classes.discountTextStyle}>
                                                Action
                                            </span>
                                        </div>
                                        <div className={classes.singleDiv}>
                                            <div className={classes.keyAndValueContainerBox}>
                                                <div className={classes.discountListStyleDiv}>
                                                    <span className={classes.discountListStyleText} style={{ width: '50%' }}>
                                                        {!["PENDING"].includes(orderDetails.order_status) ? 'Contract cancel unavailable' : 'Contract Cancel'}
                                                    </span>
                                                    <span className={classes.discountListStyleValue} style={{ width: '50%' }}>
                                                        <button
                                                            className={classes.editIcons}
                                                            style={{
                                                                fontSize: "small",
                                                                cursor: "pointer",
                                                                border: 0,
                                                                fontWeight: 600,
                                                                marginLeft: 0,
                                                                padding: 0,
                                                                color: ["PENDING",].includes(orderDetails.order_status) && orderDetails?.created_from !== 'PORTAL' ? "#34619C" : 'rgba(0, 0, 0, 0.26)',
                                                            }}
                                                            disabled={!["PENDING"].includes(orderDetails.order_status) || orderDetails?.created_from === 'PORTAL'}
                                                            onClick={() => {
                                                                this.setState({ openDialog: true },
                                                                    () => {
                                                                        this.props?.handleCancelButtonClick(true);
                                                                        this.props?.fetchShortCloseReasonMaster('Contracts')
                                                                    }
                                                                );
                                                            }
                                                            }
                                                        >
                                                            Cancel
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grow>
                                <Dialog
                                    open={this.state.openDialog}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <div style={{
                                        borderRadius: 16,
                                        padding: 8,
                                    }}>
                                        <div style={{

                                            border: "1px solid gainsboro",
                                            borderRadius: "15px",
                                            padding: "5px",
                                        }}>
                                            <DialogTitle id="alert-dialog-title" style={{ padding: 8 }}>
                                                Cancel
                                            </DialogTitle>
                                            <DialogContent style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                flexDirection: 'column',
                                                minHeight: 70,
                                                alignContent: 'center',
                                                padding: 16
                                            }}>
                                                <p>
                                                    Please select reason for Contract cancel
                                                </p>
                                                <div>
                                                    <FormControl
                                                        fullWidth
                                                        size="small"
                                                        variant="outlined"
                                                    >
                                                        <InputLabel id="cancelReason">
                                                            Select Cancel Reason*
                                                        </InputLabel>
                                                        <Select
                                                            id="Cancel Reason"
                                                            labelId="cancelReason"
                                                            variant="outlined"
                                                            label="Select Cancel Reason*"
                                                            name="cancel_reason"
                                                            // style={{ width: 340 }}
                                                            MenuProps={this.state.MenuProps}
                                                            value={this.state.cancel_reason}
                                                            onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                        >
                                                            {this.props?.reasonMaster !== undefined && this.props?.reasonMaster.length > 0
                                                                ? this.props?.reasonMaster.map((data) => (
                                                                    <MenuItem key={data.reason} value={data.id}>
                                                                        {data.reason}
                                                                    </MenuItem>
                                                                ))
                                                                :
                                                                <CircularProgress
                                                                    disableShrink
                                                                    className={classes.loaderStyle}
                                                                    style={{ marginLeft: 10 }}
                                                                />
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </DialogContent>
                                            <DialogActions
                                                style={{
                                                    padding: '8px'
                                                }}>
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            cancel_reason: '',
                                                            openDialog: false,
                                                        })
                                                    }}
                                                    style={{
                                                        color: grey,
                                                        textTransform: "capitalize"
                                                    }}
                                                >Go Back</Button>
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            openDialog: false,
                                                        });
                                                        // console.log(this.props.location, this.props.location.state, this.state.cancel_reason)
                                                        this.props.handleOrderApproveClick(this.props.location.state, "discard", this.state.cancel_reason)
                                                    }}
                                                    style={{ backgroundColor: !this.state.cancel_reason ? 'rgba(0,0,0,0.26)' : primaryColor, color: '#FFF' }}
                                                    variant="contained"
                                                    autoFocus
                                                    disabled={!this.state.cancel_reason ? true : false}
                                                >
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </Paper> */}
            </div>
        );
    }
}
export default withRouter(withStyles(styles)(SingleContractsOrderOverview));
