import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../styles/login.css";

import Button from "@material-ui/core/Button";
import {
  secondaryColor,
  bottomNavigationFontColor,
  primaryColor,
  dailyRateScreenTextColor,
} from "../../styles/colors";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import InputAdornment from "@material-ui/core/InputAdornment";
import InvoiceSVG from "./InvoiceSVG";
import OrderBookingSVG from "./OrderBookingSVG";
import CommerceSVG from "./CommerceSVG";
import PinSVG from "./PinSVG";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import Help from "@material-ui/icons/Help";
import MaskGroupSVG from "./MaskGroupSVG";
import Avatar from "@material-ui/core/Avatar";
import Close from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Email from "@material-ui/icons/Email";
import Language from "@material-ui/icons/Language";
import Phone from "@material-ui/icons/Phone";
import Dialog from "@material-ui/core/Dialog";
import SpinLoader from "../common/SpinLoader";
import StaticVersionDisplay from "./StaticVersionDisplay";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularProgressLoader from "../common/CircularProgressLoader";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Slide } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const styles = {
  loginWebBody: {
    height: window.innerHeight,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    display: "flex",
    // flexDirection: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'column',
    // alignItems: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'center',
  },
  loginWebRightContainer: {
    height: window.innerHeight,
    width: (window.innerWidth > 768 && window.innerWidth <= 1030) ? "80%" : "45%",
  },
  loginWebFormContaine: {
    maxHeight: 650,
    height: (window.innerWidth > 768 && window.innerWidth <= 1024) ? 600 : window.innerHeight - 150,
    flexDirection: "column",
    display: "flex",
    justifyContent: (window.innerWidth > 768 && window.innerWidth <= 1024) ? "flex-start" : "flex-end",
  },
  contactUsButton: {
    color: secondaryColor,
    textTransform: "capitalize",
  },
  registerButtonStyle: {
    backgroundColor: "white",
    width: "30%",
    color: secondaryColor,
    fontWeight: "bold",
  },
  usOTPButton: {
    color: secondaryColor,
    fontSize: "0.8em",
  },
  exploreBenifitsButton: {
    color: secondaryColor,
    fontWeight: "bold",
  },
  scrollUpButton: {
    color: secondaryColor,
    fontWeight: "bold",
    // fontSize: "1.5em",
    marginTop: "10px",
  },
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.referLogin = React.createRef();
    this.state = {
      userId: "",
      password: "",
      phoneNumber: "",
      otp: "",
      newPassword: "",
      newConfirmPassword: "",
      forgotPassword: false,
      showBenifits: false,
      forgotPasswordCliked: false,
      useOTP: false,
      otpInvalid: false,
      changePasswordCliked: false,
      passwordExpiryNotification: false,
      passwordValid: false,
      timerOn: false,
      timerText: '',
      benifits: [
        {
          id: "rates",
          title: "Lives Rates",
          subtitle: "24*7 broadcasting live rates",
        },

        {
          id: "booking",
          title: "Contract Booking",
          subtitle: "Book and manage your contract",
        },
        {
          id: "contract",
          title: "Live Contract Tracking",
          subtitle: "Track your contract in real time",
        },

        {
          id: "loading",
          title: "Loading Advice Submission",
          subtitle: "Fast and efficient submission ",
        },
        {
          id: "invoice",
          title: "Automated Invoice and Bill",
          subtitle: "Real time invoice and billing ",
        },

        {
          id: "payment",
          title: "Payment Tracking",
          subtitle: "Track payment in one click",
        },
      ],
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() { }

  componentDidMount() {
    window.addEventListener("keyup", this.onEnterButtonHandler);
    setTimeout(() => {
      this.setState({ showBenifits: true });
    }, 5000);
  }
  onEnterButtonHandler = (event) => {
    this.setState({
      UnauthorizedUser: false,
      inActiveUser: false,
      noInternetConnection: false,
    });
    if (
      event.keyCode === 13 &&
      this.state.forgotPassword === false &&
      this.state.userId !== "" &&
      this.state.password !== ""
    ) {
      if (this.state.useOTP && this.state.useOTP) {
        this.handleLoginWithPhoneNumber();
      } else {
        this.handleLoginWithUserAndPassword();
      }
    }
    if (
      event.keyCode === 13 &&
      this.state.forgotPassword === false &&
      this.state.userId === "" &&
      this.state.forgotPasswordCliked === false
    ) {
      this.setState({
        userIdError: true,
      });
    }
    if (
      event.keyCode === 13 &&
      this.state.forgotPassword === false &&
      this.state.password === "" &&
      this.state.forgotPasswordCliked === false
    ) {
      this.setState({
        passwordError: true,
      });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("keyup", this.onEnterButtonHandler);
  }
  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.value) {
      if (event.target.name === "newPassword") {
        !this.isPasswordValid(event.target.value) ?
          this.setState({ passwordValid: true, }) : this.setState({ passwordValid: false, })
      }
    }
  };
  clearAllData = () => {
    this.setState({
      userId: "",
      password: "",
      phoneNumber: "",
      otp: "",
      newPassword: "",
      newConfirmPassword: "",
      forgotPasswordCliked: false,
      forgotPassword: false,
      sentOTP: false,
      verifyOTP: false,
      confirmPassword: false,
      noInternetConnection: false,
      phoneNotRegistered: false,
      changePasswordCliked: false,
      passwordExpiryNotification: false,
      passwordValid: false
    });
  };

  isPasswordValid = (value) => {
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=[a-zA-Z0-9]*[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
    // const regex = /^(?=([a-zA-Z]*|[0-9]*)+[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
    if (regex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  handleClickOpenDialog = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };
  handleClosePasswordExpiryDialog = () => {
    this.setState({ passwordExpiryNotification: false });
  };
  clearOTPinput = () => {
    this.setState({ otp: "" });
  };
  handleDisabledUseOTPFor360Sec = () => {
    setTimeout(() => {
      this.setState({ disabledUseOTPFor360Sec: false, timerOn: false });
    }, 360000);
  };
  handleDisabledResendOTPFor360Sec = () => {
    setTimeout(() => {
      this.setState({ disabledResendOTPFor360Sec: false });
    }, 360000);
  };
  handleLoginWithPhoneNumber = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.setState({ loginLoader: true }, () => {
        this.props
          .loginWithPhoneNumber(this.state.userId, this.state.password)
          .then((status) => {
            if (status === "success") {
              this.props.history.push("/");
              this.setState({ loginLoader: false });
            }
          })
          .catch((e) => {
            if (e?.status === 401 || e?.status === 400) {
              console.log(e)
              this.setState({ otpInvalid: e?.status === 400 ? e?.data?.msg : true });
              this.setState({ loginLoader: false });
            }
            if (e?.response?.status === 403) {
              this.setState({ inActiveUser: true });
              this.setState({ loginLoader: false });
            }
          });
      });
    }
  };
  handleLoginWithUserAndPassword = () => {
    this.setState({ loginLoader: true });
    if (navigator.onLine === false) {
      this.setState({
        loginLoader: false,
        noInternetConnection: true,
      });
    } else {
      this.props
        .loginWithUsernamePassword(this.state.userId, this.state.password)
        .then((status) => {
          if (status === "success") {
            this.props.history.push("/");
            this.setState({ loginLoader: false });
          }
        })
        .catch((e) => {
          console.log("error--->", e.response);
          if (e?.response?.status === 401) {
            this.setState({
              UnauthorizedUser: true,
              loginLoader: false,
            });
          }
          if (e?.response?.status === 403) {
            this.setState({ inActiveUser: true });
            this.setState({ loginLoader: false });
          }
          // if (e?.response?.status === 406) {
          //   console.log(e.response.data.msg)
          //   this.setState({
          //     passwordExpiryNotification: true,
          //     sendOTPLoader: false,
          //     loginLoader: false,
          //   });
          // }
        });
    }
  };

  timer = (remaining) => {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    this.setState({ timerText: m + ':' + s })
    remaining -= 1;

    if (remaining >= 0 && this.state.timerOn) {
      setTimeout(() => {
        this.timer(remaining)
      }, 1000);
      return;
    }
  }


  handleGetMobileOTP = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.setState({
        useOTP: true,
        useOTPCliked: true,
        timerOn: true
      });
      this.props
        .getMobileOTP(this.state.userId)
        .then((status) => {
          console.log(status);
          this.setState(
            {
              useOTPCliked: false,
              disabledUseOTPFor360Sec: true,
            },
            () => {
              this.handleDisabledUseOTPFor360Sec()
              this.timer(360)
            }
          );
        })
        .catch((e) => {
          if (e?.response?.status === 401) {
            this.setState({
              useOTPCliked: false,
              useOTP: false,
            });
          }
          if (e?.response?.status === 403) {
            this.setState({ inActiveUser: true });
            this.setState({
              loginLoader: false,
              useOTPCliked: false,
            });
          }
        });
    }
  };
  handleSendOTPforForgotPassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.setState({ sendOTPLoader: true });
      this.props
        .sendOtpForForgotPassword(this.state.phoneNumber)
        .then((res) => {
          if (res.status === 200) {
            this.setState(
              {
                sentOTP: true,
                forgotPassword: false,
                sendOTPLoader: false,
                disabledResendOTPFor360Sec: true,
              },
              () => this.handleDisabledResendOTPFor360Sec()
            );
          }
        })
        .catch((error) => {
          this.setState({
            phoneNotRegistered: true,
            sendOTPLoader: false,
          });
        });
    }
  };
  handleResendOTPforForgotPassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.clearOTPinput();
      this.setState({ resendOTPLoader: true });
      this.props
        .sendOtpForForgotPassword(this.state.phoneNumber)
        .then((res) => {
          if (res.status === 200) {
            this.setState(
              {
                sentOTP: true,
                forgotPassword: false,
                resendOTPLoader: false,
                disabledResendOTPFor360Sec: true,
              },
              () => this.handleDisabledResendOTPFor360Sec()
            );
          }
        })
        .catch((error) => {
          this.setState({
            phoneNotRegistered: true,
            resendOTPLoader: false,
          });
        });
    }
  };
  handleVerifyOTPforForgotPassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.props
        .verifyOtpForForgotPassword(this.state.phoneNumber, this.state.otp)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              verifyOTP: true,
              sentOTP: false,
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            this.setState({ invalidOTPError: true });
          }
        });
    }
  };
  handleChangePasswordFromForgotPassword = () => {
    if (navigator.onLine === false) {
      this.setState({
        noInternetConnection: true,
      });
    } else {
      this.props
        .changePasswordFromForgotPassword(
          this.state.newPassword,
          this.state.phoneNumber
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              confirmPassword: true,
              verifyOTP: false,
            });
          }
        })
        .catch((error) => {
          console.log("er", error.response);
        });
    }
  };
  render() {
    const {
      forgotPassword,
      phoneNumber,
      phoneError,
      sentOTP,
      verifyOTP,
      userId,
      password,
      otp,
      newPassword,
      newConfirmPassword,
      otpError,
      confirmPasswordError,
      passwordLengthError,
      confirmPassword,
      userIdError,
      passwordError,
      showBenifits,
      forgotPasswordCliked,
      useOTP,
      otpInvalid,
      UnauthorizedUser,
      phoneNotRegistered,
      invalidOTPError,
      loginLoader,
      inActiveUser,
      noInternetConnection,
      passwordValid,
      benifits,
    } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.loginWebBody} style={{
          flexDirection: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'column',
          alignItems: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'center',
        }}>
          <div className="login-web-left-container" style={{ height: (window.innerWidth > 768 && window.innerWidth <= 1030) && 180 }}>
            {!showBenifits && !(window.innerWidth > 768 && window.innerWidth <= 1030) ? (
              <div className="login-web-left-container-child">
                <div className="login-web-left-image-container">
                  <img
                    src={
                      window.cordova
                        ? `${this.image}${"/RealDealWeb2.png"}`
                        : "/RealDealWeb2.png"
                    }
                    alt="img"
                    height="50%"
                    width="auto"
                    style={{
                      marginBottom: 15
                    }}
                  />
                  <span className="login-web-left-portal-text">
                    Online portal for faster deals
                  </span>
                </div>
                <div className="login-web-left-about-app-text">
                  <span>A modern one-window portal to book orders,</span>
                  <span>manage dispatch, and all sales related activities</span>
                  <span>with REAL Group</span>
                </div>
                {/* <div className="login-web-explore-benifits-container">
                  <Fab
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showBenifits: true });
                    }}
                    variant="extended"
                    aria-label="Delete"
                    className={classes.exploreBenifitsButton}
                  >
                    Explore Benifits <ArrowDropDown />
                  </Fab>
                </div> */}
              </div>
            ) : (
              <div className="login-web-show-benifits-main-container">
                <div className="login-web-show-benifits-image-container">
                  <img
                    src={
                      window.cordova
                        ? `${this.image}${"/RealDealWeb2.png"}`
                        : "/RealDealWeb2.png"
                    }
                    alt="img"
                    height={(window.innerWidth > 768 && window.innerWidth <= 1030) ? "120px" : "70px"}
                    width="auto"
                    style={{
                      marginBottom: 15
                    }}
                  />
                  <span className="login-web-left-portal-text">
                    Online portal for faster deals
                  </span>
                </div>
                <div className="login-web-booking-benifits-container" onClick={() => {
                  this.referLogin.current.focus()
                }} style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="login-web-explore-list-booking" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1200) && 250 }}>
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <MaskGroupSVG />
                    </Avatar>
                    <div className="login-web-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Live Rates
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        24*7 broadcasting live rates
                      </span>
                    </div>
                  </div>
                </div>

                <div className="login-web-booking-benifits-container" onClick={() => {
                  this.referLogin.current.focus()
                }} style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="login-web-explore-list-booking" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1200) && 250 }}>
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <OrderBookingSVG />
                    </Avatar>
                    <div className="login-web-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Contract Booking
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Book and manage your contract
                      </span>
                    </div>
                  </div>
                </div>

                <div className="login-web-booking-benifits-container" onClick={() => {
                  this.referLogin.current.focus()
                }} style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="login-web-explore-list-booking" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1200) && 250 }}>
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <PinSVG />
                    </Avatar>
                    <div className="login-web-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Live Contract Tracking
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Track your contract in real time
                      </span>
                    </div>
                  </div>
                </div>

                <div className="login-web-booking-benifits-container" onClick={() => {
                  this.referLogin.current.focus()
                }} style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="login-web-explore-list-booking" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1200) && 250 }}>
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <OrderBookingSVG />
                    </Avatar>
                    <div className="login-web-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Loading advice submission
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Fast and efficient submission
                      </span>
                    </div>
                  </div>
                </div>
                <div className="login-web-booking-benifits-container" onClick={() => {
                  this.referLogin.current.focus()
                }} style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="login-web-explore-list-booking" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1200) && 250 }}>
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <InvoiceSVG />
                    </Avatar>
                    <div className="login-web-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Automated Invoice and Bill
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Real time invoice and billing
                      </span>
                    </div>
                  </div>
                </div>
                <div className="login-web-booking-benifits-container" onClick={() => {
                  this.referLogin.current.focus()
                }} style={{ display: (window.innerWidth > 768 && window.innerWidth <= 1030) && 'none' }}>
                  <div className="login-web-explore-list-booking" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1200) && 250 }}>
                    <Avatar
                      style={{
                        height: "35px",
                        width: "40px",
                        borderRadius: "10%",
                        backgroundColor: primaryColor,
                      }}
                      alt="Remy Sharp"
                    >
                      <CommerceSVG />
                    </Avatar>
                    <div className="login-web-explore-primary-container">
                      <span
                        style={{
                          fontSize: "0.9em",
                          color: dailyRateScreenTextColor,
                        }}
                      >
                        Payment Tracking
                      </span>
                      <span
                        style={{
                          fontSize: "0.7em",
                          color: bottomNavigationFontColor,
                        }}
                      >
                        Track payment in one click
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classes.loginWebRightContainer}>
            <div className="login-web-right-header">
              <span>{/* <Help /> */}</span>
            </div>
            <div className={classes.loginWebFormContaine}>
              <div className="login-web-form-start" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1024) && '100%', height: (window.innerWidth > 768 && window.innerWidth <= 1024) && 600 }}>
                <div className="login-web-login-text-10vh" style={{ height: (window.innerWidth > 768 && window.innerWidth <= 1024) && '20%' }}>
                  {forgotPasswordCliked ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "2px",
                        top: "10px",
                      }}
                    >
                      <IconButton
                        aria-label="Delete"
                        onClick={() => this.clearAllData()}
                      >
                        <ArrowBack />
                      </IconButton>
                    </div>
                  ) : null}
                  {confirmPassword ? null : (
                    <span className="login-web-text">
                      {this.state.changePasswordCliked ?
                        "Change Password"
                        : forgotPassword || sentOTP
                          ? "Forgot Password"
                          : verifyOTP
                            ? "Change Password"
                            : "Login"}
                    </span>
                  )}
                  {forgotPassword || sentOTP || verifyOTP ? (
                    <span
                      className="login-web-verify-text"
                      style={{ color: passwordLengthError ? "red" : "#b6bcd1" }}
                    >
                      {verifyOTP
                        ? "Password should be 8 to 15 characters in length"
                        : forgotPassword || sentOTP
                          ? "Verify your number to set a new password"
                          : null}
                    </span>
                  ) : null}

                  <span
                    style={{
                      color: "red",
                      textAlign: "center",
                      textDecoration: "capitalize",
                    }}
                  >
                    {UnauthorizedUser
                      ? "Invalid Username or Password !!"
                      : inActiveUser
                        ? "Activation is pending for this account, please contact the admin"
                        : noInternetConnection
                          ? "No Internet Connection"
                          : null}
                  </span>
                </div>
                <div className="login-web-form-container">
                  {forgotPassword || sentOTP ? (
                    <TextField
                      // disabled={sentOTP ? true : false}
                      name="phoneNumber"
                      id="outlined-name"
                      label="Phone Number"
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleTextChange}
                      style={{ width: "65%" }}
                      value={phoneNumber}
                      error={
                        phoneError ? true : phoneNotRegistered ? true : false
                      }
                      type="tel"
                      inputProps={{
                        maxLength: 10,
                        style: { padding: "13px 14px" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "1.1em", marginTop: "-4px" },
                      }}
                      FormHelperTextProps={{
                        style: { marginLeft: "0px", marginRight: "0px" },
                      }}
                      helperText={
                        <span style={{ color: "red" }}>
                          {phoneError
                            ? "Please enter valid 10 digit number"
                            : phoneNotRegistered
                              ? "Phone number not registered"
                              : null}
                        </span>
                      }
                      onFocus={() => {
                        this.clearOTPinput();
                        this.setState({
                          phoneError: false,
                          phoneNotRegistered: false,
                          sentOTP: false,
                          forgotPassword: true,
                        });
                      }}
                      onBlur={() => {
                        if (phoneNumber.length < 10) {
                          this.setState({ phoneError: true });
                        }
                      }}
                      InputProps={{
                        endAdornment: sentOTP ? (
                          <InputAdornment position="end">
                            {this.state.resendOTPLoader ? (
                              <CircularProgressLoader />
                            ) : (
                              <Button
                                disabled={this.state.disabledResendOTPFor360Sec}
                                className={classes.usOTPButton}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    noInternetConnection: false,
                                  });
                                  this.handleResendOTPforForgotPassword();
                                }}
                              >
                                RESEND OTP
                              </Button>
                            )}
                          </InputAdornment>
                        ) : null,
                      }}
                    />
                  ) : confirmPassword ? (
                    <div className="login-web-password-changed-conatiner">
                      <span className="login-web-password-changed-text">
                        Password Changed{" "}
                      </span>
                      <span className="login-web-password-changed-text">
                        Successfully!
                      </span>
                    </div>
                  ) : (
                    <TextField
                      name={verifyOTP ? "newPassword" : "userId"}
                      inputRef={this.referLogin}
                      id="outlined-name"
                      label={
                        verifyOTP ? "New Password" : "Email / Mobile / UID"
                      }
                      error={userIdError ? true : false}
                      margin="normal"
                      variant="outlined"
                      value={verifyOTP ? newPassword : userId.toLowerCase()}
                      type={verifyOTP ? "password" : "text"}
                      onChange={this.handleTextChange}
                      style={{ width: "65%" }}
                      inputProps={{
                        style: { padding: "13px 14px" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "1.1em", marginTop: "-4px" },
                      }}
                      onFocus={() => {
                        this.setState({
                          useOTP: false,
                          UnauthorizedUser: false,
                          inActiveUser: false,
                          noInternetConnection: false,
                        });
                        if (forgotPassword === false) {
                          this.setState({ userIdError: false });
                        }
                        if (verifyOTP) {
                          this.setState({ passwordLengthError: false });
                        }
                      }}
                    />
                  )}
                  {verifyOTP ? (
                    <div className="login-web-strong-password-div">
                      <span style={{ color: bottomNavigationFontColor }}>
                        Password strength
                      </span>
                      <span
                        style={{
                          backgroundColor:
                            newPassword &&
                              newPassword.length >= 2 && !passwordValid
                              ? "#c6d430"
                              : newPassword.length >= 2
                                ? "#e68948"
                                // : newPassword &&
                                //   newPassword.length >= 4 &&
                                //   newPassword.length < 6
                                //   ? "#4986c2"
                                //   // : newPassword && newPassword.length >= 6
                                //   : passwordValid
                                // ? "#c6d430"
                                : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        style={{
                          backgroundColor:
                            newPassword &&
                              newPassword.length >= 4 && !passwordValid
                              ? "#c6d430"
                              : newPassword.length >= 4
                                ? "#e68948"
                                // : newPassword && !passwordValid
                                // : newPassword &&
                                //   newPassword.length >= 5 &&
                                //   newPassword.length < 7
                                //   ? "#4986c2"
                                //   // : newPassword && newPassword.length >= 7
                                //   : passwordValid
                                // ? "#c6d430"
                                : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        style={{
                          backgroundColor:
                            newPassword &&
                              newPassword.length >= 8 && !passwordValid
                              ? "#c6d430"
                              : newPassword.length >= 8
                                ? "#e68948"
                                // newPassword.length >= 4 &&
                                // newPassword.length < 6
                                // ? "#e68948"
                                // : newPassword &&
                                //   newPassword.length >= 6 &&
                                //   newPassword.length < 8
                                //   ? "#4986c2"
                                //   // : newPassword && newPassword.length >= 8
                                //   : passwordValid
                                // ? "#c6d430"
                                : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                      <span
                        style={{
                          backgroundColor:
                            newPassword && !passwordValid
                              // newPassword.length >= 5 &&
                              // newPassword.length < 7
                              // ? "#e68948"
                              // : newPassword &&
                              //   newPassword.length >= 7 &&
                              //   newPassword.length < 9
                              //   ? "#4986c2"
                              //   // : newPassword && newPassword.length >= 9
                              //   : passwordValid
                              ? "#c6d430"
                              : "gainsboro",
                          width: "11%",
                          height: "10px",
                          border: "1px solid #35425A",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  ) : null}
                  {forgotPassword || sentOTP || confirmPassword ? null : (
                    <TextField
                      disabled={this.state.useOTPCliked}
                      name={verifyOTP ? "newConfirmPassword" : "password"}
                      id="outlined-name"
                      label={
                        verifyOTP
                          ? "Confirm Password"
                          : useOTP && otpInvalid === false
                            ? "Enter OTP"
                            : otpInvalid && useOTP
                              ? "Invalid OTP"
                              : "Password"
                      }
                      margin="normal"
                      variant="outlined"
                      type="password"
                      error={
                        confirmPasswordError
                          ? true
                          : passwordError
                            ? true
                            : otpInvalid
                              ? true
                              : false
                      }
                      value={verifyOTP ? newConfirmPassword : password}
                      onChange={this.handleTextChange}
                      style={{ width: "65%" }}
                      inputProps={{
                        style: { padding: "13px 14px" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "1.1em", marginTop: "-4px" },
                      }}
                      FormHelperTextProps={{
                        style: { marginLeft: "0px", marginRight: "0px", },
                      }}
                      helperText={
                        <>
                          {
                            <span style={{ color: "red" }}>
                              {otpInvalid
                                ? otpInvalid
                                : confirmPasswordError
                                  ? "Password didn't match"
                                  : null}
                            </span>
                          }
                          {
                            this.state?.disabledUseOTPFor360Sec
                              ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ color: "green", display: 'flex', alignItems: 'center' }}>
                                  <TaskAltIcon style={{ transform: 'scale(0.8)' }} />  OTP Sent Successfully
                                </span>
                                <span style={{ color: "green" }}>
                                  {this.state?.timerText}
                                </span>
                              </div>
                              : null
                          }
                        </>
                      }
                      onFocus={() => {
                        this.setState({
                          otpInvalid: false,
                          UnauthorizedUser: false,
                          inActiveUser: false,
                          noInternetConnection: false,
                        });
                        if (forgotPassword === false) {
                          this.setState({ passwordError: false });
                        }
                        if (verifyOTP) {
                          this.setState({ confirmPasswordError: false });
                        }
                        if (verifyOTP) {
                          this.setState({ passwordLengthError: false });
                        }
                      }}
                      onBlur={() => {
                        if (verifyOTP && newPassword !== newConfirmPassword) {
                          this.setState({ confirmPasswordError: true });
                        }
                      }}
                      InputProps={{
                        endAdornment: verifyOTP ? null : (
                          <InputAdornment position="end">
                            {this.state.useOTPCliked ? (
                              <CircularProgress
                                style={{
                                  color: "#4b87c5",
                                  height: "25px",
                                  width: "25px",
                                }}
                              />
                            ) : (
                              <Button
                                disabled={
                                  this.state.userId.length < 10 ||
                                  this.state.disabledUseOTPFor360Sec
                                }
                                className={classes.usOTPButton}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    noInternetConnection: false,
                                  });
                                  this.handleGetMobileOTP();
                                }}
                              >
                                USE OTP
                              </Button>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {sentOTP ? (
                    <TextField
                      name="otp"
                      id="outlined-name"
                      label="OTP"
                      margin="normal"
                      variant="outlined"
                      type="password"
                      value={otp}
                      error={otpError ? true : invalidOTPError ? true : false}
                      onChange={this.handleTextChange}
                      style={{ width: "65%" }}
                      inputProps={{
                        style: { padding: "13px 14px" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: "1.1em", marginTop: "-4px" },
                      }}
                      onFocus={() => {
                        this.setState({
                          otpError: false,
                          invalidOTPError: false,
                        });
                      }}
                      helperText={
                        <span style={{ color: "red" }}>
                          {otpError
                            ? "Please enter valid OTP  "
                            : invalidOTPError
                              ? "Invalid OTP"
                              : null}
                        </span>
                      }
                    />
                  ) : null}
                  <div className="logon-web-forgot-password-container">
                    {verifyOTP || confirmPassword ? null : (
                      <div className="logon-web-forgot-password-div">
                        <Button
                          style={{
                            color: secondaryColor,
                            fontSize: "0.8em",
                            visibility:
                              forgotPassword || sentOTP || verifyOTP
                                ? "hidden"
                                : "visible",
                          }}
                          // className={classes.forgotPassword}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              forgotPassword: true,
                              forgotPasswordCliked: true,
                              userIdError: false,
                              passwordError: false,
                              useOTP: false,
                              otpInvalid: false,
                              UnauthorizedUser: false,
                              userId: "",
                              password: "",
                              inActiveUser: false,
                              sendOTPLoader: false,
                            });
                          }}
                        >
                          FORGOT PASSWORD
                        </Button>
                      </div>
                    )}
                  </div>
                  {loginLoader || this.state.sendOTPLoader ? (
                    <div>
                      <SpinLoader />
                    </div>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: this.state.loginButtonCliked
                          ? "#1B388B"
                          : " #1B388B",
                        fontWeight: "bold",
                        color: "white",
                        width: "65%",
                        padding: "10px 16px",
                        borderRadius: "7px",
                        // marginTop: "20px",
                      }}
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          UnauthorizedUser: false,
                          inActiveUser: false,
                          noInternetConnection: false,
                        });
                        if (
                          forgotPassword === false &&
                          userId !== "" &&
                          password !== ""
                        ) {
                          if (useOTP && useOTP) {
                            this.handleLoginWithPhoneNumber();
                          } else {
                            this.handleLoginWithUserAndPassword();
                          }
                        }
                        if (
                          forgotPassword === false &&
                          userId === "" &&
                          forgotPasswordCliked === false
                        ) {
                          this.setState({
                            userIdError: true,
                          });
                        }
                        if (
                          forgotPassword === false &&
                          password === "" &&
                          forgotPasswordCliked === false
                        ) {
                          this.setState({
                            passwordError: true,
                          });
                        }

                        if (forgotPassword && phoneNumber.length === 10) {
                          this.handleSendOTPforForgotPassword();
                        }
                        if (sentOTP && otp === "") {
                          this.setState({ otpError: true });
                        }
                        if (sentOTP && otp !== "") {
                          this.handleVerifyOTPforForgotPassword();
                        }
                        if (
                          verifyOTP &&
                          newPassword.length < 6 &&
                          newConfirmPassword.length < 6
                        ) {
                          this.setState({ passwordLengthError: true });
                        }
                        if (
                          verifyOTP &&
                          newPassword.length >= 6 &&
                          newConfirmPassword.length >= 6 &&
                          newPassword === newConfirmPassword
                        ) {
                          this.handleChangePasswordFromForgotPassword();
                        }
                        if (confirmPassword) {
                          this.setState({ confirmPassword: false }, () => {
                            this.clearAllData();

                            this.setState({ forgotPasswordCliked: false });
                          });
                        }
                      }}
                    >
                      {forgotPassword
                        ? "Send OTP"
                        : sentOTP
                          ? "Verify"
                          : verifyOTP
                            ? "Confirm"
                            : " Login "}
                    </Button>
                  )}

                  <div className="login-web-logo-container">
                    <img
                      src={
                        window.cordova ? `${this.image}${"/gk.png"}` : "/gk.png"
                      }
                      alt="img"
                      height="40px"
                      width="auto"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          UnauthorizedUser: false,
                          inActiveUser: false,
                          noInternetConnection: false,
                        });
                        if (
                          forgotPassword === false &&
                          userId !== "" &&
                          password !== ""
                        ) {
                          if (useOTP && useOTP) {
                            this.handleLoginWithPhoneNumber();
                          } else {
                            this.handleLoginWithUserAndPassword();
                          }
                        }
                        if (
                          forgotPassword === false &&
                          userId === "" &&
                          forgotPasswordCliked === false
                        ) {
                          this.setState({
                            userIdError: true,
                          });
                        }
                        if (
                          forgotPassword === false &&
                          password === "" &&
                          forgotPasswordCliked === false
                        ) {
                          this.setState({
                            passwordError: true,
                          });
                        }
                      }}
                    />
                    <img
                      src={
                        window.cordova
                          ? `${this.image}${"/realwire.png"}`
                          : "/realwire.png"
                      }
                      alt="img"
                      height="40px"
                      width="auto"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          UnauthorizedUser: false,
                          inActiveUser: false,
                          noInternetConnection: false,
                        });
                        if (
                          forgotPassword === false &&
                          userId !== "" &&
                          password !== ""
                        ) {
                          if (useOTP && useOTP) {
                            this.handleLoginWithPhoneNumber();
                          } else {
                            this.handleLoginWithUserAndPassword();
                          }
                        }
                        if (
                          forgotPassword === false &&
                          userId === "" &&
                          forgotPasswordCliked === false
                        ) {
                          this.setState({
                            userIdError: true,
                          });
                        }
                        if (
                          forgotPassword === false &&
                          password === "" &&
                          forgotPasswordCliked === false
                        ) {
                          this.setState({
                            passwordError: true,
                          });
                        }
                      }}
                    />
                  </div>
                  <span className="powered-by-text">
                    A Product Of REAL Group
                  </span>
                  <span
                    className="login-web-dont-have-acc-text"
                    onClick={() => this.props.history.push("/register")}
                  >
                    Don't have an account ?
                  </span>
                </div>
                {(window.innerWidth > 768 && window.innerWidth <= 1030) &&
                  <>
                    <br />
                    <br />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        float: "right",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          console.log('clicked')
                          this.handleClickOpenDialog()
                        }
                        }
                      >
                        <Help />
                      </IconButton>
                    </div>
                  </>
                }
                <div className="login-web-register-button-container">
                  <Fab
                    onClick={() => this.props.history.push("/register")}
                    variant="extended"
                    aria-label="Delete"
                    className={classes.registerButtonStyle}
                  >
                    REGISTER
                  </Fab>
                </div>
              </div>
            </div>
            <div
              style={{
                marginLeft: "1%",
                width: (window.innerWidth > 768 && window.innerWidth <= 1024) ? "100%" : "70%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: (window.innerWidth > 768 && window.innerWidth <= 1024) ? "35px" : "15px",
              }}
            >
              <Button
                className={classes.contactUsButton}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/enquiry");
                }}
              >
                Get Quote
              </Button>
            </div>
            {(window.innerWidth > 768 && window.innerWidth <= 1030)
              ?
              <>
                <div style={{
                  marginLeft: "1%",
                  width: (window.innerWidth > 768 && window.innerWidth <= 1024) ? "100%" : "70%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <Button
                    style={styles.exploreBenifitsButton}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ openFullDialog: true });
                    }}
                  >
                    Explore Benefits <ArrowDropDown />
                  </Button>
                </div>
                <Dialog
                  PaperProps={{
                    style: {
                      background:
                        "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
                      opacity: 1,
                    },
                  }}
                  fullScreen
                  open={this.state.openFullDialog}
                  onClose={this.handleClose}
                  TransitionComponent={Transition}
                >
                  <div>
                    <div className="login-header-image">
                      <img
                        src={
                          window.cordova
                            ? `${this.image}${"/RealDealWeb2.png"}`
                            : "/RealDealWeb2.png"
                        }
                        alt="img"
                        height="70px"
                        width="auto"
                        style={{
                          marginTop: 15,
                          marginBottom: 15
                        }}
                      />
                    </div>
                    <div className="login-explore-container">
                      {benifits.map((data) => (
                        <div className="login-explore-list">
                          <Avatar
                            style={{
                              height: "45px",
                              width: "50px",
                              borderRadius: "10%",
                              backgroundColor: primaryColor,
                            }}
                            alt="Remy Sharp"
                          >
                            {data.id === "rates" ? (
                              <MaskGroupSVG />
                            ) : data.id === "booking" ? (
                              <OrderBookingSVG />
                            ) : data.id === "invoice" ? (
                              <InvoiceSVG />
                            ) : data.id === "loading" ? (
                              <MaskGroupSVG />
                            ) : data.id === "contract" ? (
                              <PinSVG />
                            ) : data.id === "payment" ? (
                              <CommerceSVG />
                            ) : null}
                          </Avatar>
                          <div className="login-explore-list-item">
                            <span className="login-explore-title-style">
                              {data.title}
                            </span>
                            <span className="login-explore-subtitle-style">
                              {data.subtitle}
                            </span>
                          </div>
                        </div>
                      ))}
                      <div style={{
                        marginLeft: "1%",
                        width: (window.innerWidth > 768 && window.innerWidth <= 1024) ? "100%" : "70%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <Button
                          style={styles.exploreBenifitsButton}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ openFullDialog: false });
                          }}
                        >
                          Back To Login <ArrowDropUp />
                        </Button>
                        <br />
                      </div>
                    </div>
                  </div>
                </Dialog>
              </>
              : <div className="login-web-contact-us-container" style={{ width: (window.innerWidth > 768 && window.innerWidth <= 1024) && '100%', justifyContent: (window.innerWidth > 768 && window.innerWidth <= 1024) && 'center' }}>
                <div className="login-web-contact-us">
                  <Button
                    className={classes.contactUsButton}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleClickOpenDialog();
                    }}
                  >
                    Contact Us
                  </Button>
                </div>
              </div>
            }
          </div>
        </div>
        <Dialog
          onClose={this.handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.openDialog}
          PaperProps={{
            style: {
              width: (window.innerWidth > 768 && window.innerWidth <= 1030) ? "60vw" : "30vw",
              height: (window.innerWidth > 768 && window.innerWidth <= 1030) ? "35vh" : "45vh",
              borderRadius: "20px",
            },
          }}
        >
          <div>
            <div
              style={{
                height: "7vh",
                background: "#9e9e9e70",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 30px",
              }}
            >
              <span style={{ fontSize: "1.2em" }}>Contact Us</span>
              <span>
                <Close onClick={this.handleCloseDialog} />
              </span>
            </div>
            <div
              style={{
                height: (window.innerWidth > 768 && window.innerWidth <= 1030) ? "20vh" : "30vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Avatar
                  style={{
                    height: "45px",
                    width: "45px",
                    borderRadius: "10%",
                    backgroundColor: primaryColor,
                  }}
                >
                  <Phone />
                </Avatar>
                <span style={{ marginLeft: "20px" }}>+91 771 4224 000</span>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Avatar
                  style={{
                    height: "45px",
                    width: "45px",
                    borderRadius: "10%",
                    backgroundColor: primaryColor,
                  }}
                >
                  <Email />
                </Avatar>
                <span style={{ marginLeft: "20px" }}>sales@realdeals.pro</span>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Avatar
                  style={{
                    height: "45px",
                    width: "45px",
                    borderRadius: "10%",
                    backgroundColor: primaryColor,
                  }}
                >
                  <Language />
                </Avatar>

                <a
                  style={{ marginLeft: "20px" }}
                  href="https://www.realgroup.org"
                  target="_blank"
                >
                  www.realgroup.org
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StaticVersionDisplay />
            </div>
          </div>
        </Dialog>
        <Dialog
          onClose={this.handleClosePasswordExpiryDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.passwordExpiryNotification}
          PaperProps={{
            style: {
              width: "40vw",
              height: "17vh",
              borderRadius: "20px",
              alignItems: 'flex-start'
            },
          }}
        >
          <div
            style={{
              padding: '10px 20px'
            }}
          >
            <p>
              Your Password is Expired!  Please Click Below Button to Change the Password.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1B388B",
                  fontWeight: "bold",
                  color: "white",
                  width: "48%",
                  padding: "10px 10px",
                  borderRadius: "7px",
                  // marginTop: "20px",
                }}
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    passwordExpiryNotification: false,
                    forgotPassword: true,
                    forgotPasswordCliked: true,
                    changePasswordCliked: true,
                    userIdError: false,
                    passwordError: false,
                    useOTP: false,
                    otpInvalid: false,
                    UnauthorizedUser: false,
                    userId: "",
                    password: "",
                    inActiveUser: false,
                    sendOTPLoader: false,
                    loginLoader: false,
                  });
                }}
              >
                Change Password
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(Login);
