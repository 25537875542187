import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { grey } from "../../../../constants/internal/colors";

import { pxToEm } from "../../../../methods";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "45%" },
});
export default function ManageCustomerCredentialDetails(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation}>
          This information will be used to LOGIN in the RIPL app.
        </Typography>
        <Typography className={classes.fillingInformation}>
          Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <div className={classes.dataDisplay}>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>EMAIL *</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.email}
              </Typography>
            ) : (
              <TextField
                type="email"
                className={classes.formControl}
                label="Email"
                onChange={props.handleChange("email")}
                variant="outlined"
                size="small"
                autoComplete="off"
                value={props.email}
                error={props.emailError}
                helperText={props.emailError ? "Invalid email" : ""}
              />
            )}
          </div>
          <div className={classes.dataFields}>
            <Typography className={classes.titleLabel}>PHONE NO. *</Typography>
            {props.customerOperation === "view" ? (
              <Typography className={classes.formControl}>
                {props.phoneNumber}
              </Typography>
            ) : (
              <TextField
                type="number"
                className={classes.formControl}
                label="Phone Number"
                variant="outlined"
                size="small"
                onChange={props.handleChange("phoneNumber")}
                autoComplete="off"
                value={props.phoneNumber}
                error={props.phoneNumberError}
                helperText={
                  props.phoneNumberError ? "Invalid phone number" : ""
                }
              />
            )}
          </div>
          {props.customerOperation === "add" ? (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>PASSWORD *</Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.password}
                </Typography>
              ) : (
                <TextField
                  type="text"
                  className={classes.formControl}
                  label="Password"
                  autoComplete="off"
                  value={props.password}
                  variant="outlined"
                  size="small"
                  name="password"
                  onChange={props.handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            props.handleGeneratePassword();
                          }}
                          edge="end"
                        >
                          GENERATE
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  error={props.passwordError}
                  helperText={props.passwordError ? "Invalid password" : ""}
                />
              )}
            </div>
          ) : null}
          {/*{props.customerOperation === "edit" ? (
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                OLD PASSWORD *
              </Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.oldPassword}
                </Typography>
              ) : (
                <TextField
                  type="text"
                  className={classes.formControl}
                  label="Old Password"
                  variant="outlined"
                  size="small"
                  onChange={props.handleChange("oldPassword")}
                  autoComplete="off"
                  value={props.oldPassword}
                />
              )}
            </div>
          ) : null}*/}
        </div>
      </div>
    </>
  );
}
