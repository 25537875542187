import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import GateIn from "../GateIn";

class DispatchOperationsGate extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <GateIn {...this.props} />
      </Layout>
    );
  }
}
export default withRouter(DispatchOperationsGate);
