import React, { Component } from "react";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  primaryColor,
  whiteSmoke,
  secondaryGrey,
  drawerTextColor,
} from "../../../../../constants/internal/colors";
import { checkApiError, findValueReturnText, hasUpdateAccess, hasWriteAccess, pxToEm } from "../../../../../methods";
import { Tab, Tabs, Button, IconButton, Typography, Chip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import SingleContractsOrderOverview from "./SingleContractsOrderOverview";
import SingleContractsItemAndPlantPanning from "./SingleContractsItemAndPlantPanning";
import SingleContractsLoadingAdvices from "./SingleContractsLoadingAdvices";
import Bread from "../../../common/Breadcrubs";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import SpinLoader from "../../../../common/SpinLoader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SnackbarComponent from "../../../common/SnackbarComponent";
import Divider from '@mui/material/Divider';
import moment from "moment";
import APIROUTES from "../../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import ToolTipComponent from "../../../common/ToolTipComponent";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Print from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";


const styles = {
  SingleContractMainBody: {
    height: "100%",
    width: "100%",
    paddingBottom: "60px",
    overflowY: "scroll",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "30px",
    width: "100%",

  },
  headerDate: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
  contactTextFieldContainer: {
    padding: "0px 10px",
    fontSize: "1.5em",
    textTransform: "uppercase",
  },
  tabContainerStyle: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "15px",
    display: "flex",
  },
  pageBreakStyle: {
    '@media print': {
      // breakInside: 'avoid',
      marginTop: 20,
      pageBreakBefore: 'always'
    }
  },
  plantOnly: {
    display: 'none',

    '@media print': {
      display: 'block'
    }

  },
  tabContainerChildStyle: (tabValue) => ({
    width: tabValue === 2 ? "85%" : "100%",
    display: "flex",
  }),
  tabStyle: (tabValue, currentTab, isMobile) => ({
    backgroundColor: whiteSmoke,
    color: tabValue === currentTab ? grey : secondaryGrey,
    fontSize: isMobile ? "0.7em" : "0.9em",
    fontWeight: "bold",
    width: currentTab === 0 ? "37%" : "33%",
  }),
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  loaderDiv: {
    display: "flex",
    width: "100%",
    height: "calc(100vh - 200px)",
    alignItems: "center",
    justifyContent: "center",
  },
  align: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
  },
  SingleContractDetailsBox: {
    width: "97%",
    height: "10%",
    padding: "30px 40px 30px 40px",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#34619C",
    background: "#F6F7FB",
    display: "flex"
  },
  singleHeaderContainer: (isMobile) => ({
    textOverflow: "ellipsis",
    maxWidth: !isMobile && "25%",
    padding: isMobile && '0 5px 0 10px'
    // minWidth: isMobile && '50%'
  }),
  singleContractDetailsText: (isMobile) => ({
    fontWeight: "600",
    fontSize: isMobile ? pxToEm(14) : "15px",
  }),
  singleContractDetailsValue: (isMobile) => ({
    fontWeight: "500",
    fontSize: isMobile ? pxToEm(16) : "25px",
    marginTop: "5px",

  }),
  singleContractSubDetailsValue: (isMobile) => ({
    fontWeight: "400",
    fontSize: isMobile ? pxToEm(14) : "15px",
    marginTop: "5px",

  })
};
class SingleContract extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.refSingleContract = React.createRef();
    this.state = {
      tabValue: props.location.state?.tabValue || 0,
      openCreateLoadingAdvices: false,
      selectedOrderId: null,
      orderDetails: [],
      orderDetailsLoader: true,
      orderLoadingAdvices: [],
      orderLoadingAdvicesLoader: true,
      orderPlantPlanningData: [],
      orderPlantPlanningDataLoader: props.location.state?.tabValue !== null ? false : true,
      allPlantsList: [],
      is_approved: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      totalQuantity: 0,
      dispatchCalculation: [],
      isOrderNoCopied: false,
      rowsCount: 0
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const id =
      this.props?.location?.pathname?.split("/")[
      this.props?.location?.pathname?.split("/").length - 1
      ];
    this.setState({ selectedOrderId: id }, () => {
      this.props.fetchInternalUsers();
      this.prepareSelectedOrderDataToDisplay(this.state.selectedOrderId);
      this.fetchPendingQuantity(this.state.selectedOrderId);
      if (this.state.orderLoadingAdvices.length === 0) { this.handleFetchLoadingAdvices() }
    }
    );

    this.setState({ is_approved: this.state?.orderDetails?.is_approved && this.props?.location?.state?.isApproved });
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  onCopyText = (orderNo) => {
    navigator.clipboard.writeText(orderNo)
    this.setState(prevValue => { return { ...prevValue, isOrderNoCopied: !prevValue.isOrderNoCopied } })
    setTimeout(() => {
      this.setState({
        isOrderNoCopied: false,
      });
    }, 1000);
  };

  handlePrintSingleContracts = () => {
    let printContents = document.getElementById('print_data').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML =
      '<html><head><title></title></head><body>' + printContents + '</body>';
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload()
  };

  fetchPendingQuantity = (orderId) => {
    return new Promise((resolve, reject) => {
      this.props
        .fetchPendingQuantity(orderId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              dispatchCalculation: response.data
            });
            resolve(response);
          } else {
            this.setState({
              orderDetailsLoader: false,
              openSnackbar: true,
              snackbarMessage: `Error occured while fetching pending quantity `,
              snackbarType: "error",
            });
            reject();
          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000)
        });
    });
  }

  prepareSelectedOrderDataToDisplay = (orderId) => {
    this.setState({ orderDetailsLoader: true });
    this.props
      .fetchOrderDetails(orderId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            orderDetails: response.data,
            orderDetailsLoader: false,
            is_approved: response.data.is_approved
          }, () => {
            this.handleFetchPlantsList();
            if (this.state.tabValue === 1) {
              this.handleTabChange(null, this.state.tabValue)
            }
          });
        }
        //  else {
        //   this.setState({
        //     orderDetailsLoader: false,
        //     openSnackbar: true,
        //     snackbarMessage: `Error occured while fetching Contract data `,
        //     snackbarType: "error",
        //   });
        // }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleTabValueChanged = (tabNo) => {
    this.setState({ tabValue: tabNo })
  }
  handleTabChange = (event, value) => {
    if (!["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.state?.orderDetails?.order_status)) {
      if (value === 2 && !this.state.is_approved) {
        this.setState({ tabValue: 0 });
        this.setState({
          orderLoadingAdvicesLoader: false,
          openSnackbar: true,
          snackbarMessage: "Contract is not APPROVED",
          snackbarType: "warning",
        });
        return null
      }
    }
    // if ((value === 2 || value === 1) && (["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.state?.orderDetails?.order_status))) {
    //   this.setState({ tabValue: 0 });
    //   this.setState({
    //     orderLoadingAdvicesLoader: false,
    //     openSnackbar: true,
    //     snackbarMessage: `Contract is ${this.state?.orderDetails?.order_status}`,
    //     snackbarType: "warning",
    //   });
    //   return null
    // }
    if ((value === 2) && (this.state?.orderDetails?.order_status === "CONVERSION_ALLOTMENT_HOLD")) {
      this.setState({ tabValue: 1 });
      if (this?.state?.orderPlantPlanningData.length === 0) {
        this.handleFetchPlantPlanningData();
        //this.handleFetchPlantsList();
        // console.log("called here")
      }
      this.setState({
        orderLoadingAdvicesLoader: false,
        openSnackbar: true,
        snackbarMessage: "Contract is in ALLOTMENT HOLD state, Please Update Plant Planning First",
        snackbarType: "warning",
      });
      return null
    }
    this.setState({ tabValue: value });
    if (value === 1) {
      if (this?.state?.orderPlantPlanningData.length === 0) {
        this.handleFetchPlantPlanningData();
        //this.handleFetchPlantsList();
        // console.log("called here")
      }
    } else if (value === 2) {

      this.setState({
        orderLoadingAdvicesLoader: true,
        openSnackbar: false,
      });
      this.handleFetchLoadingAdvices();

    }
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  handleOpenCeateLoadingAdvice = (value) => {
    this.setState({ openCreateLoadingAdvices: value });
  };
  handleFetchLoadingAdvices = (newLink = 0) => {
    let orderId = this?.state?.selectedOrderId;
    const FetchUrl = this.props.fetchOrderLoadingAdvices(orderId)
    const filteredLink = newLink === 0 ? FetchUrl : newLink
    this.setState({ orderLoadingAdvicesLoader: true, rowsCount: 0 });

    filteredLink?.then((response) => {
      if (response.status === 200) {
        const laResponse = response.data.results;
        this.setState({
          orderLoadingAdvices: response.data?.results,
          rowsCount: laResponse.length,
          orderLoadingAdvicesLoader: false,
          openSnackbar: false,
          count: response.data.count,
          prevLink: response.data.previous,
          nextLink: response.data.next,
        });
      }
      // else {
      //   this.setState({
      //     orderLoadingAdvicesLoader: false,
      //     openSnackbar: true,
      //     snackbarMessage: `Error occured while fetching Contract data `,
      //     snackbarType: "error",
      //   });
      // }
    })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };

  setRowsCount = (count) => {
    this.setState({
      rowsCount: count
    })
  }

  handleFetchPlantPlanningData = (plantOrderId) => {
    if (plantOrderId) {
      this.setState({ selectedOrderId: plantOrderId })
    }
    let orderId = plantOrderId || this?.state?.selectedOrderId;
    this.setState({
      orderPlantPlanningDataLoader: true
    });
    this.props
      .fetchOrderPlantPlanning(orderId)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data.results)

          this.setState({
            orderPlantPlanningData: response.data.results,
            orderPlantPlanningDataLoader: false,
          });
          this.fetchPendingQuantity(orderId);
        } else {
          this.setState({
            orderPlantPlanningDataLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching plant planning data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };
  handleFetchPlantsList = () => {
    let orderItemCategoryId = this.state.orderDetails?.item_category?.id;
    // console.log(orderItemCategoryId)
    this.props
      .fetchPlantsForItemCategory(orderItemCategoryId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allPlantsList: response.data.results,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching plants list`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };
  handlePlantPlanningData = (plantPlanningData) => {
    this.props
      .savePlantPlanningData(plantPlanningData)
      .then((response) => {
        if (response.status === 200) {
          this.prepareSelectedOrderDataToDisplay(this.state.selectedOrderId);
          this.handleFetchPlantPlanningData();
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while saving plant planning data`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };
  updatePlantPlanningData = (plantPlanningData) => {
    // console.log("updatePlantPlanningData", plantPlanningData)
    // console.log("updatePlantPlanningData", plantPlanningId)
    this.props
      .editPlantPlanningData(plantPlanningData, this.state.selectedOrderId)
      .then((response) => {
        if (response.status === 200) {
          this.prepareSelectedOrderDataToDisplay(this.state.selectedOrderId);
          this.handleFetchPlantPlanningData();
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while updating plant planning data`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };
  handleLaApprove = (laId, laUpdateData) => {
    this.props
      .approveLa(laUpdateData, laId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Status Approved`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => this.handleFetchLoadingAdvices(), 3000)
            });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while approving LA try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };
  handleCancelLa = (laId, updatedLaData) => {
    this.props
      .cancelLa(updatedLaData, laId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Status Cancelled`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => this.handleFetchLoadingAdvices(), 3000)
            });
          this.prepareSelectedOrderDataToDisplay(this.state.selectedOrderId);
          this.handleFetchPlantPlanningData();
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while  cancelling LA try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };

  approveContractModification = (logId, orderId, remarkMessage) => {
    let data = { is_accepted: true, remark: remarkMessage };
    this.props.approveModifiedContract(logId, data)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Contract Modification Changes Approved Successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.prepareSelectedOrderDataToDisplay(orderId);
                this.fetchPendingQuantity(orderId);
              }, 3000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while approving contract modification changes try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };

  rejectContractModification = (logId, orderId, remarkMessage) => {
    let data = { is_accepted: false, remark: remarkMessage };
    this.props.rejectModifiedContract(logId, data)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Contract Modification Changes Reject Successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.prepareSelectedOrderDataToDisplay(orderId);
                this.fetchPendingQuantity(orderId);
              }, 3000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while rejecting contract modification changes try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000)
      });
  };

  handleEditButton = (id) => {
    this.props.fetchInternalUsers();
    this.prepareSelectedOrderDataToDisplay(id);
    this.fetchPendingQuantity(id);
  }

  itemQtyTotal = (itemData) => {
    let sum =
      itemData.length > 0
        ? itemData
          .map((o) => o.loading_advice_items_total_quantity)
          .reduce((a, c) => {
            return Number(a) + Number(c);
          })
        : 0;
    return sum;
  };

  render() {
    const {
      tabValue,
      orderDetails,
      orderDetailsLoader,
      openSnackbar,
      snackbarMessage,
      snackbarType,
    } = this.state;
    const { classes, isMobile, } = this.props;
    // { console.log(this.state.orderDetails) }
    return (
      <Layout {...this.props}>
        <div className={classes.SingleContractMainBody} style={{ paddingTop: isMobile && 20 }}>
          {orderDetailsLoader ? (
            <div className={classes.loaderDiv}>
              <SpinLoader />
            </div>
          ) : (
            <>
              <div className={classes.navigationBar}>

                <Bread
                  data={[
                    {
                      title: "Contracts",
                      style: {
                        fontSize: isMobile && pxToEm(15),
                      },
                      onClick: () => this.props.history.push(INTERNALROUTES.ORDERS),
                    },
                    {
                      title: `${orderDetails.order_number || ""}`,
                      style: {
                        fontSize: isMobile && pxToEm(14),
                      },
                      onClick: "",
                    },
                  ]}
                />
                <span onClick={() => this.onCopyText(orderDetails?.order_number)}>
                  {this.state.isOrderNoCopied ? (
                    <Typography
                      style={{
                        fontSize: pxToEm(14),
                        height: 24,
                        width: 24,
                        fontWeight: "normal",
                        color: "#FFB600",
                        marginLeft: 2,
                        marginRight: 15
                      }}
                    >
                      Copied!
                    </Typography>
                  ) : (
                    <ContentCopyOutlinedIcon
                      style={{
                        padding: '0 0 5px 0',
                        // transform: 'scale(1)',
                        marginLeft: 4,
                        cursor: 'pointer',
                        marginTop: 6,
                      }}
                    />
                  )}

                </span>
                <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />

                <div className={classes.headerDate} style={{ fontSize: isMobile && pxToEm(14) }}>
                  <span>{moment(orderDetails.created_at).format("DD-MMM-YYYY")}</span>
                </div>
                <div
                  style={{ float: "right", marginLeft: 3, marginTop: 5, transform: isMobile && 'scale(0.9)' }}
                  onClick={() => {
                    // this.handleFetchPlantPlanningData();
                    // this.handleFetchLoadingAdvices()
                  }}
                >
                  <ReactToPrint
                    trigger={() => <Print style={{ cursor: 'pointer' }} />}
                    content={() => this.refSingleContract.current}
                    documentTitle={`${orderDetails.order_number}_${moment().format("DD-MM-YYYY _ h-mm")}`}
                    onBeforePrint={() => {
                      // console.log('onBeforePrint');
                      // this.handleFetchLoadingAdvices()
                      // this.handleFetchPlantPlanningData();
                      // this.handleFetchLoadingAdvices();
                    }
                    }
                  />
                  {/* <Button onClick={() => this.handlePrintSingleContracts()}>
                <Print style={{ marginRight: "3px" }} />
              </Button> */}
                </div>
              </div>

              <div id="print_data" ref={this.refSingleContract}>
                <div className={classes.align}>

                  <div
                    className={classes.SingleContractDetailsBox}
                    style={{
                      overflowY: isMobile && 'auto',
                      paddingLeft: isMobile && 0,
                      paddingRight: isMobile && 0,
                    }}
                  >

                    <div style={styles.singleHeaderContainer(isMobile)}>
                      <div style={styles.singleContractDetailsText(isMobile)}>Customer</div>

                      <div style={styles.singleContractDetailsValue(isMobile)}>{`${orderDetails?.customer?.first_name} ${orderDetails?.customer?.last_name}`}</div>
                    </div>
                    <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em" }} />

                    <div style={styles.singleHeaderContainer(isMobile)}>
                      <div style={styles.singleContractDetailsText(isMobile)}>Item Category</div>

                      <div style={styles.singleContractDetailsValue(isMobile)}>
                        {orderDetails?.item_category?.category_name || ""}
                        {orderDetails.tag !== null
                          ? " (" + (orderDetails?.tag_name) + ")"
                          : ""
                        }
                      </div>
                    </div>
                    <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em" }} />

                    <div style={styles.singleHeaderContainer(isMobile)}>
                      <div style={styles.singleContractDetailsText(isMobile)}>Rate</div>

                      <div style={styles.singleContractDetailsValue(isMobile)}>₹ {Number(orderDetails?.rate).toFixed(0) || 0}</div>
                    </div>
                    <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em" }} />

                    <div style={styles.singleHeaderContainer(isMobile)}>
                      <div style={styles.singleContractDetailsText(isMobile)}>Quantity</div>
                      <div style={styles.singleContractDetailsValue(isMobile)}>{parseFloat(orderDetails?.booked_quantity).toFixed(3)} MT</div>
                    </div>

                    <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em" }} />
                    <div style={styles.singleHeaderContainer(isMobile)}>
                      <div style={styles.singleContractDetailsText(isMobile)}> Cancelled Quantity</div>
                      <div style={styles.singleContractDetailsValue(isMobile)}>{parseFloat(orderDetails?.short_close_quantity).toFixed(3)} MT</div>
                    </div>





                  </div>
                  {/* {orderDetails?.is_approved ? null : (
                  <IconButton
                    aria-label="delete"
                    onClick={() =>
                      this.props.history.push(
                        `/internalContracts/editContracts/${orderDetails.id}`
                      )
                    }
                    size="small"
                  >
                    <EditOutlinedIcon className={classes.editIcons} />
                  </IconButton>
                )} */}
                </div>

                <div className={classes.tabContainerStyle}>
                  <>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          borderBottom: `2px solid ${primaryColor}`,
                          colur: grey,
                        },
                      }}
                      value={tabValue}
                      onChange={this.handleTabChange}
                      style={styles.tabContainerChildStyle(tabValue)}
                      variant="scrollable"
                    >
                      <Tab
                        label="Contract OVERVIEW"
                        style={styles.tabStyle(tabValue, 0, isMobile)}
                      />
                      <Tab
                        label="PLANT PLANNING"
                        style={styles.tabStyle(tabValue, 1, isMobile)}
                      />
                      <Tab
                        label="LOADING ADVICES"
                        style={styles.tabStyle(tabValue, 2, isMobile)}
                      />
                    </Tabs>
                    {tabValue === 2 ?
                      (parseInt(this.state.dispatchCalculation[0]?.order_available_quantity) <= 0 ? (
                        ""
                      ) : (
                        <ToolTipComponent
                          title="You have no create permission on this widget"
                          condition={!hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                          title2={`Contract is ${this.state?.orderDetails?.order_status}`}
                          condition2={["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.state?.orderDetails?.order_status)}
                          title3={'Contract is Expired! Please amend contract validity'}
                          condition3={new Date(this?.state?.orderDetails?.order_validity) < new Date()}
                        >
                          <Button
                            size="medium"
                            className={classes.primaryBackground}
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={
                              () =>
                                this.props.history.push(
                                  {
                                    pathname: `${INTERNALROUTES.CREATE_LOADING_ADVICE_FROM_ORDER}/${this.state.selectedOrderId}`,
                                    state: {
                                      isApproved: this.state.is_approved,
                                      pendingQuantity: this.state.dispatchCalculation,
                                      itemId: this.state.orderDetails.item_category.id,
                                      customerId: this.state.orderDetails.customer.id
                                    }
                                  }

                                )
                              //this.handleOpenCeateLoadingAdvice(true)
                            }
                            disabled={!hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                              ["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.state?.orderDetails?.order_status) ||
                              new Date(this?.state?.orderDetails?.order_validity) < new Date()
                            }
                          >
                            ADD
                          </Button>
                        </ToolTipComponent>
                      )
                      ) : null}
                  </>
                </div>
                {tabValue === 0 ? (
                  <>
                    <SingleContractsOrderOverview
                      orderDetails={orderDetails}
                      orderDetailsLoader={this.state.orderDetailsLoader}
                      user={this.props.user}
                      isMobile={isMobile}
                      approveOrder={this.props.approveOrder}
                      dispatchCalculation={this.state.dispatchCalculation}
                      orderLoadingAdvices={this.state.orderLoadingAdvices}
                      orderLoadingAdvicesLoader={this.state.orderLoadingAdvicesLoader}
                      prepareSelectedOrderDataToDisplay={this.prepareSelectedOrderDataToDisplay}
                      fetchPendingQuantity={this.fetchPendingQuantity}
                      approveContractModification={this.approveContractModification}
                      rejectContractModification={this.rejectContractModification}
                      handleFetchLoadingAdvices={this.handleFetchLoadingAdvices}
                      fetchConversionItemCategories={this.props.fetchConversionItemCategories}
                      ContractConversion={this.props.ContractConversion}
                      fetchReasonMaster={this.props.fetchReasonMaster}
                      contractShortClose={this.props.contractShortClose}
                      handleFetchPlantPlanningData={this.handleFetchPlantPlanningData}
                      handleTabValueChanged={this.handleTabValueChanged}
                      users={this.props?.users}
                      handleEditButton={this.handleEditButton}
                      setRowsCount={this.setRowsCount}
                      itemCategoryCode={localStorage.getItem("itemCategoryCode")}
                    />
                    {
                      <div className={classes.plantOnly}>
                        <div className={classes.pageBreakStyle} />
                        <div className={classes.tabContainerStyle}>
                          <>
                            <Tabs
                              TabIndicatorProps={{
                                style: {
                                  borderBottom: `2px solid ${primaryColor}`,
                                  colur: grey,
                                },
                              }}
                              value={1}
                              onChange={this.handleTabChange}
                              style={styles.tabContainerChildStyle(tabValue)}
                              variant="scrollable"
                            >
                              <Tab
                                label="Contract OVERVIEW"
                                style={styles.tabStyle(tabValue, 0, isMobile)}
                              />
                              <Tab
                                label="PLANT PLANNING"
                                style={styles.tabStyle(tabValue, 1, isMobile)}
                              />
                              <Tab
                                label="LOADING ADVICES"
                                style={styles.tabStyle(tabValue, 2, isMobile)}
                              />
                            </Tabs>
                          </>
                        </div>
                        <SingleContractsItemAndPlantPanning
                          {...this.state}
                          user={this.props.user}
                          order_status={this.state?.orderDetails?.order_status}
                          handlePlantPlanningData={this.handlePlantPlanningData}
                          updatePlantPlanningData={this.updatePlantPlanningData}
                          fetchReasonMaster={this.props.fetchReasonMaster}
                          contractShortClose={this.props.contractShortClose}
                          handleFetchPlantPlanningData={this.handleFetchPlantPlanningData}
                          prepareSelectedOrderDataToDisplay={this.prepareSelectedOrderDataToDisplay}
                          fetchPendingQuantity={this.fetchPendingQuantity}
                          itemCategoryCode={localStorage.getItem("itemCategoryCode")}
                        />
                        <div className={classes.pageBreakStyle} />
                        <div className={classes.tabContainerStyle}>
                          <>
                            <Tabs
                              TabIndicatorProps={{
                                style: {
                                  borderBottom: `2px solid ${primaryColor}`,
                                  colur: grey,
                                },
                              }}
                              value={2}
                              onChange={this.handleTabChange}
                              style={styles.tabContainerChildStyle(2)}
                              variant="scrollable"
                            >
                              <Tab
                                label="Contract OVERVIEW"
                                style={styles.tabStyle(tabValue, 0, isMobile)}
                              />
                              <Tab
                                label="PLANT PLANNING"
                                style={styles.tabStyle(tabValue, 1, isMobile)}
                              />
                              <Tab
                                label="LOADING ADVICES"
                                style={styles.tabStyle(2, 2, isMobile)}
                              />
                            </Tabs>
                            {(parseInt(this.state.dispatchCalculation[0]?.order_available_quantity) <= 0 ? (
                              ""
                            ) : (
                              <ToolTipComponent
                                title="You have no create permission on this widget"
                                condition={!hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)}
                                title2={`Contract is ${this.state?.orderDetails?.order_status}`}
                                condition2={["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.state?.orderDetails?.order_status)}
                                title3={'Contract is Expired! Please amend contract validity'}
                                condition3={new Date(this?.state?.orderDetails?.order_validity) < new Date()}
                              >
                                <Button
                                  size="medium"
                                  className={classes.primaryBackground}
                                  variant="contained"
                                  startIcon={<AddIcon />}
                                  onClick={
                                    () =>
                                      this.props.history.push(
                                        {
                                          pathname: `${INTERNALROUTES.CREATE_LOADING_ADVICE_FROM_ORDER}/${this.state.selectedOrderId}`,
                                          state: {
                                            isApproved: this.state.is_approved,
                                            pendingQuantity: this.state.dispatchCalculation,
                                            itemId: this.state.orderDetails.item_category.id,
                                            customerId: this.state.orderDetails.customer.id
                                          }
                                        }

                                      )
                                    //this.handleOpenCeateLoadingAdvice(true)
                                  }
                                  disabled={!hasWriteAccess(this.props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ||
                                    ["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(this.state?.orderDetails?.order_status) ||
                                    new Date(this?.state?.orderDetails?.order_validity) < new Date()
                                  }
                                >
                                  ADD
                                </Button>
                              </ToolTipComponent>
                            ))}
                          </>
                        </div>
                        <SingleContractsLoadingAdvices
                          {...this.state}
                          user={this.props.user}
                          orderDetails={orderDetails}
                          handleOpenCeateLoadingAdvice={
                            this.handleOpenCeateLoadingAdvice
                          }
                          orderLoadingAdvices={this.state?.orderLoadingAdvices}
                          orderLoadingAdvicesLoader={
                            this.state?.orderLoadingAdvicesLoader
                          }
                          handleLaApprove={this.handleLaApprove}
                          handleCancelLa={this.handleCancelLa}
                          handleCloseSnackbar={this.handleCloseSnackbar}
                          fetchPendingQuantity={this.fetchPendingQuantity}
                          fetchTcsRate={this.props.fetchTcsRate}
                          handleFetchLoadingAdvices={this.handleFetchLoadingAdvices}
                          setRowsCount={this.setRowsCount}
                          itemCategoryCode={localStorage.getItem("itemCategoryCode")}
                          handleBill={this.props.handleBill}
                        />
                      </div>}
                  </>
                ) : tabValue === 1 ? (
                  <SingleContractsItemAndPlantPanning
                    {...this.state}
                    user={this.props.user}
                    order_status={this.state?.orderDetails?.order_status}
                    handlePlantPlanningData={this.handlePlantPlanningData}
                    updatePlantPlanningData={this.updatePlantPlanningData}
                    fetchReasonMaster={this.props.fetchReasonMaster}
                    contractShortClose={this.props.contractShortClose}
                    handleFetchPlantPlanningData={this.handleFetchPlantPlanningData}
                    prepareSelectedOrderDataToDisplay={this.prepareSelectedOrderDataToDisplay}
                    fetchPendingQuantity={this.fetchPendingQuantity}
                    itemCategoryCode={localStorage.getItem("itemCategoryCode")}
                  />
                ) : tabValue === 2 ? (
                  <>
                    <SingleContractsLoadingAdvices
                      {...this.state}
                      user={this.props.user}
                      orderDetails={orderDetails}
                      handleOpenCeateLoadingAdvice={
                        this.handleOpenCeateLoadingAdvice
                      }
                      orderLoadingAdvices={this.state?.orderLoadingAdvices}
                      orderLoadingAdvicesLoader={
                        this.state?.orderLoadingAdvicesLoader
                      }
                      handleLaApprove={this.handleLaApprove}
                      handleCancelLa={this.handleCancelLa}
                      handleCloseSnackbar={this.handleCloseSnackbar}
                      fetchPendingQuantity={this.fetchPendingQuantity}
                      fetchTcsRate={this.props.fetchTcsRate}
                      handleFetchLoadingAdvices={this.handleFetchLoadingAdvices}
                      setRowsCount={this.setRowsCount}
                      itemCategoryCode={localStorage.getItem("itemCategoryCode")}
                      handleBill={this.props.handleBill}
                    />
                  </>
                ) : null}
              </div>
            </>
          )}
          <SnackbarComponent
            open={openSnackbar}
            handleClose={this.handleCloseSnackbar}
            snackbarMessage={snackbarMessage}
            type={snackbarType}
          />
        </div>
      </Layout>
    );
  }
}
export default withRouter(withStyles(styles)(SingleContract));
