import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CircularLoader from "../../common/Spinner";
import Badge from '@mui/material/Badge';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MuiAlert from "@material-ui/lab/Alert";
import Bread from "../../common/Breadcrubs";
import Paper from "@material-ui/core/Paper";
import FilterListIcon from '@mui/icons-material/FilterList';
import Snackbar from '@mui/material/Snackbar';
import Dialog from "@material-ui/core/Dialog";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from '@mui/material/InputLabel';
import { Button, Typography, CircularProgress, IconButton } from "@material-ui/core";
import Print from "@material-ui/icons/Print";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import "../../../../styles/internal/scroll.css";
import PlantTable from './PlantTable'
import {
  primaryColor,
  drawerTextColor,
} from "../../../../constants/internal/colors";
import { isAccessibleWidget, pxToEm, saveCSVInMobile } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Moment from 'moment'
import { flexbox } from '@mui/system';
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { CSVLink } from "react-csv";
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  invoiceCsvHeaders,
  newContractsCsvHeaders,
  contractsCsvHeaders,
  itemCategoryDDCsvHeaders,
  itemDDCsvHeaders,
  loadingAdvicesCSVHeaders,
  WBGCSVHeaders,
  EXRCSVHeaders,
  DPTCSVHeaders,
  subContractsPdfHeaders
} from '../headers'
import { withRouter } from "react-router-dom";
import ReportsPDFDownload from "./ReportsPDFDownload";
import Filters from "./Filters"
import DummyPDF from './DummyPDF.js'
import DummyInvoicePDF from './DummyInvoicePDF.js'
import Table from "../../common/Table";
import ReportsDynamicPDFDownload from "./DynamicPdf.js";

const customersStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    height: `calc(100vh - 250px)`,
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  filterSection: {
    margin: "25px 0px 20px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterAlignment: {
    display: "flex",
    alignItems: "center",
  },
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  searchBox: {
    width: "85%",
  },
  formControl: {
    minWidth: "100%",
    marginLeft: "10px",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    // paddingBottom: "30px",
  },
  rightPadding: { paddingRight: "25px" },
  fontColor: {
    color: "gray",
  },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    sx: {
      '& .MuiMenuItem-root': {
        fontFamily: 'Poppins'
      },
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      fontFamily: 'Poppins',
      // maxWidth: 300
      // whiteSpace: "normal"
      // wordBreak: "break-all"
    },
  },
  // getContentAnchorEl: null,
  // anchorOrigin: {
  //   vertical: "bottom",
  //   horizontal: "left",
  // },
};

// Invoices
const PlantSelect = ({
  classes,
  selectedFilter,
  handleSelect,
  filters,
  tabValue,
}) => {

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Select Plant</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedFilter.value}
        label="Select Plant"
        onChange={handleSelect}
        MenuProps={MenuProps}
      >
        {/* {tabValue === 0 &&
          <MenuItem value="All">All</MenuItem>
        } */}
        <MenuItem disabled value="default">
          Select Plant
        </MenuItem>
        {filters.map(({ label, value, disabled }, i) => (
          <MenuItem key={"s" + i} disabled={disabled} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MultiSelect = ({
  classes,
  selectedFilter,
  handleSelect,
  disableOptions,
  filters,
  selectFor,
  tabValue,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">Select {selectFor}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedFilter}
        onChange={handleSelect}
        input={<OutlinedInput label={`Select ${selectFor}`} sx={{ fontFamily: 'Poppins' }} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {
          // tabValue === 0 ?
          <MenuItem value="All" sx={{ fontFamily: 'Poppins' }}>
            <Checkbox checked={selectedFilter.indexOf("All") > -1} />
            <ListItemText primary="All" sx={{ fontFamily: 'Poppins' }} />
          </MenuItem>
          // :
          // <MenuItem disabled value="default">Select {selectFor}</MenuItem>
        }
        {/* <MenuItem value="All">All</MenuItem> */}
        {filters.map(({ label, value, disabled }, i) => (
          <MenuItem key={"s" + i} disabled={disableOptions} value={value} sx={{ fontFamily: 'Poppins' }}>
            <Checkbox checked={selectedFilter.indexOf(value) > -1} />
            <ListItemText primary={label} sx={{ fontFamily: 'Poppins' }} />
          </MenuItem>
          // <MenuItem key={"s" + i} disabled={disabled} value={value}>
          //   {label}
          // </MenuItem>
        ))}
      </Select>
    </FormControl>

  );
}

const MultiSelectForEXRTCL = ({
  classes,
  selectedFilter,
  handleSelect,
  disableOptions,
  filters,
  selectFor,
  tabValue,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">Select {selectFor}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedFilter}
        onChange={handleSelect}
        input={<OutlinedInput label={`Select ${selectFor}`} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem value="credit limit provided">
          <Checkbox checked={selectedFilter.indexOf("credit limit provided") > -1} />
          <ListItemText primary="credit limit provided" />
        </MenuItem>
      </Select>
    </FormControl>

  );
}

const MultiSelectForEXRModules = ({
  classes,
  selectedFilter,
  handleSelect,
  disableOptions,
  filters,
  selectFor,
  tabValue,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">Select {selectFor}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedFilter}
        onChange={handleSelect}
        input={<OutlinedInput label={`Select ${selectFor}`} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem value="customer">
          <Checkbox checked={selectedFilter.indexOf("customer") > -1} />
          <ListItemText primary="customer" />
        </MenuItem>
      </Select>
    </FormControl>

  );
}
const CategorySelect = ({
  classes,
  selectedFilter,
  handleSelect,
  disableItemCats,
  filters,
  selectFor,
  tabValue,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">Select {selectFor}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedFilter}
        onChange={handleSelect}
        input={<OutlinedInput label={`Select ${selectFor}`} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {
          // tabValue === 0 ?
          <MenuItem value="All">
            <Checkbox checked={selectedFilter.indexOf("All") > -1} />
            <ListItemText primary="All" />
          </MenuItem>
          // :
          // <MenuItem disabled value="default">Select {selectFor}</MenuItem>
        }
        {/* <MenuItem value="All">All</MenuItem> */}
        {filters.map(({ label, value, disabled }, i) => (
          <MenuItem key={"s" + i} disabled={disableItemCats} value={value}>
            <Checkbox checked={selectedFilter.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
          // <MenuItem key={"s" + i} disabled={disabled} value={value}>
          //   {label}
          // </MenuItem>
        ))}
      </Select>
    </FormControl>

  );
}

const ButtonsPanel = ({
  containerWidth,
  selectedStartDate,
  selectedEndDate,
  onButtonClick,
  tabValue,
  tabData,
  originalResponse,
  csvName,
  tabCSVData,
  csvHeaders,
  reportName,
  handleFilterIconClick,
  handleOpenSnackClick,
  selectedFilters,
  filterCount,
  user,
  loader,
  plants,
  itemCats,
  customerType,
  customerTypeL1,
  ItemTag,
  status = '',
  refCSVLink,
  handleSubContractCSVClick,
  csvClickLoader,
  fetchSubContractCSVReportProgress,
  handleTableDataSorting,
  updatedCols,
  isMobile,
}) => {
  return (
    <div style={{
      width: isMobile ? '100%' : containerWidth,
      marginBottom: 15,
      // marginLeft: 40,
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      pointerEvents: loader && "none"
      // backgroundColor: "black",
    }}>{
        // loader ?
        //   <CircularProgress size={20} color="#FFFFFF" />
        //   :
        <Button
          variant="contained"
          style={
            // (plantSelected) ?
            {
              width: "120px",
              // marginRight: "20px",
              marginTop: 12,
              backgroundColor: "#4b87c5",
              color: "white",
              // fontSize: "1vw"
            }
            // : {
            //   width: "120px",
            //   height: "50%",
            //   // marginRight: "20px",
            //   marginTop: 12,
            //   backgroundColor: "gray",
            //   color: "white"
            // }
          }
          size="small"
          onClick={(e) => onButtonClick()}
          disabled={Moment(selectedStartDate).isAfter(Moment(selectedEndDate))}
        >
          Get Reports
        </Button>
      }
      {
        loader ? null :

          <div style={{
            marginTop: 9, backgroundColor: 'transparent',
            visibility: (Moment(selectedStartDate).isAfter(Moment(selectedEndDate))) ? "hidden" : "visible"
          }}>
            {tabValue === 0 ?
              (tabData.data.length === 0) ? "" :
                <DummyInvoicePDF
                  data={tabData.data}
                  state={tabData}
                  tabValue={tabValue}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  user={user}
                  reportName={reportName}
                  handleOpenSnackClick={handleOpenSnackClick}
                  plants={plants}
                  itemCats={itemCats}
                  handleTableDataSorting={handleTableDataSorting}
                  updatedCols={updatedCols}
                /> : tabValue === 1 ?
                (tabData.data.length === 0) ? "" :
                  <DummyPDF
                    data={tabData.data}
                    state={tabData}
                    tabValue={tabValue}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    user={user}
                    reportName={reportName}
                    handleOpenSnackClick={handleOpenSnackClick}
                    plants={plants}
                    itemCats={itemCats}
                    handleTableDataSorting={handleTableDataSorting}
                    updatedCols={updatedCols}
                  />
                : tabValue === 5 ?
                  (tabData.data.length === 0) ? "" :
                    <ReportsDynamicPDFDownload
                      data={tabData.data}
                      state={tabData}
                      tabValue={tabValue}
                      pdfHeader={subContractsPdfHeaders}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      user={user}
                      reportName={reportName}
                      handleOpenSnackClick={handleOpenSnackClick}
                      plants={plants}
                      itemCats={itemCats}
                      customerType={customerType}
                      customerTypeL1={customerTypeL1}
                      ItemTag={ItemTag}
                      status={status}
                      handleTableDataSorting={handleTableDataSorting}
                      updatedCols={updatedCols}
                    />
                  :
                  (tabData.data.length === 0) ? "" :
                    <ReportsPDFDownload
                      data={tabData.data}
                      state={tabData}
                      tabValue={tabValue}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      user={user}
                      reportName={reportName}
                      handleOpenSnackClick={handleOpenSnackClick}
                      plants={plants}
                      itemCats={itemCats}
                      handleTableDataSorting={handleTableDataSorting}
                      updatedCols={updatedCols}
                    />
            }
          </div>
      }
      {


        <Tooltip title="Download CSV" placement="top">
          <div style={{
            marginTop: 16,
            visibility: (tabData.data.length === 0 || Moment(selectedStartDate).isAfter(Moment(selectedEndDate))) ? "hidden" : "visible"
          }}>
            {tabValue === 5
              ? <div>
                <IconButton
                  style={{ display: 'flex', paddingBottom: 16 }}
                  disabled={(csvClickLoader)}
                  onClick={() => {
                    if (!(csvClickLoader))
                      handleSubContractCSVClick()
                  }}
                >
                  <CloudDownloadOutlinedIcon style={{ color: (csvClickLoader) ? "rgba(0,0,0,0.4)" : "#4b87c5" }} />
                  {
                    csvClickLoader &&
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: 'scale(0.5)'
                      }}
                    >
                      <CircularProgress />
                      <Typography
                        variant="body1"
                        component="div"
                        style={{ fontSize: 33, paddingLeft: 10, color: '#333' }}
                      >
                        {`${Math.round(fetchSubContractCSVReportProgress === undefined ? 0 : fetchSubContractCSVReportProgress)}%`}
                      </Typography>
                    </div>
                  }
                </IconButton>
                <CSVLink
                  data={tabCSVData.data || []}
                  headers={csvHeaders}
                  filename={`${csvName} ${Moment().format(
                    "DD-MM-YYYY , h-mm"
                  )}.csv`}
                  className="hidden"
                  ref={refCSVLink}
                  target="_blank"
                />
              </div>
              : loader ? null :

                <CSVLink
                  data={tabCSVData.data}
                  headers={csvHeaders}
                  onClick={(event, done) => {
                    window.cordova && saveCSVInMobile(
                      tabCSVData.data,
                      csvHeaders,
                      `${csvName}.csv`,
                      handleOpenSnackClick,
                      ''
                    )
                  }}
                  filename={`${csvName} ${Moment().format(
                    "DD-MM-YYYY , h-mm"
                  )}.csv`}
                >
                  <CloudDownloadOutlinedIcon
                    style={{ color: "#4b87c5" }}
                  // disabled={tableData.length === 0}]

                  />
                </CSVLink>
            }
          </div>
        </Tooltip>
      }
      {

        tabValue !== 5 &&
        <Tooltip title="More Filters" placement="top">
          <div style={{
            marginTop: 9,
            visibility: (originalResponse.data.length === 0 && !selectedFilters)
              || Moment(selectedStartDate).isAfter(Moment(selectedEndDate))
              ? "hidden" : "visible",
            cursor: "pointer",
          }}
            onClick={() => handleFilterIconClick(true)}
          >
            {
              loader ? null :

                <Badge
                  // color="secondary"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white",
                      backgroundColor: "#4b87c5"
                    }
                  }}
                  // variant="dot"
                  badgeContent={
                    filterCount && filterCount
                  }
                // invisible={this.badgeVisibility}
                >
                  <FilterListIcon
                    style={{ color: "#4b87c5" }}
                  // disabled={tableData.length === 0}]
                  />
                </Badge>
            }
          </div>
        </Tooltip>
      }
      {/* </div> */}
    </div>
  )
}
const ItemSelect = ({
  classes,
  selectedFilter,
  handleSelect,
  filters,
  selectFor,
  tabValue,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Select {selectFor}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedFilter.value}
        label={`Select ${selectFor}`}
        onChange={handleSelect}
        MenuProps={MenuProps}
      >
        {
          tabValue === 0 ?
            <MenuItem value="All">All</MenuItem> :
            <MenuItem disabled value="default">Select {selectFor}</MenuItem>
        }
        {/* <MenuItem value="All">All</MenuItem> */}
        {filters.map(({ label, value, disabled }, i) => (
          <MenuItem key={"s" + i} disabled={disabled} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

// Dispatch Details

const SearchByTypeFilter = ({
  classes,
  selectedSearchByTypeFilter,
  handleSearchByTypeSelect,
  filters
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Search By</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSearchByTypeFilter.value}
        label="Search By"
        onChange={handleSearchByTypeSelect}
        MenuProps={MenuProps}
      >
        <MenuItem disabled value="default">Search By</MenuItem>
        {filters.map(({ label, value, disabled }, i) => (
          <MenuItem key={"s" + i} disabled={disabled} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

// Contracts Filter Panel

const ContractTypeSelect = ({
  classes,
  selectedFilter,
  handleSelect,
  disableTypes,
  filters,
  selectFor,
  tabValue,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">Select {selectFor}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedFilter}
        onChange={handleSelect}
        input={<OutlinedInput label="Select Contract Status" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {
          // tabValue === 0 ?
          <MenuItem value="All">
            <Checkbox checked={selectedFilter.indexOf("All") > -1} />
            <ListItemText primary="All" />
          </MenuItem>
        }
        {/* <MenuItem value="All">All</MenuItem> */}
        {filters.map(({ label, value, disabled }, i) => (
          <MenuItem key={"s" + i} disabled={disableTypes} value={value}>
            <Checkbox checked={selectedFilter.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>

  );
}

// DatePickers

const StartDatePicker = ({ selectedStartDate, handleStartDateChange, tabValue, isMobile }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      label="Start Date"
      value={selectedStartDate}
      maxDate={new Date()}
      onChange={(date) => {
        console.log(date)
        handleStartDateChange(date, tabValue)
      }
      }
      renderInput={(params) => <TextField {...params} style={{ width: isMobile && '100%' }} />}
      inputFormat="dd-MMM-yyyy"
      inputProps={{ readOnly: true }}
    />
  </LocalizationProvider>
);

const EndDatePicker = ({ classess, selectedStartDate, selectedEndDate, handleEndDateChange, tabValue }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      label="End Date"
      value={selectedEndDate}
      maxDate={new Date()}
      onChange={(date) => handleEndDateChange(date, tabValue)}
      inputProps={{ readOnly: true }}
      renderInput={(params) => <TextField {...params}
        error={Moment(selectedStartDate).isAfter(Moment(selectedEndDate))}
        helperText={Moment(selectedStartDate).isAfter(Moment(selectedEndDate)) ? "Invalid date selected" : ""}
        className={classess.helperText} fullWidth
      />}
      inputFormat="dd-MMM-yyyy"
    />
  </LocalizationProvider>
);

// Tabs 
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ marginTop: 20 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dataInput: {
    display: "flex"
  },
  button: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginLeft: -20
  },
  tab: {
    '& .MuiBox-root-55': {
      padding: '0px',
    },
  },
  helperText: {
    '& .MuiFormHelperText-root': {
      height: '0',
      marginTop: '0'
    }
  }
}));

function ReportsWeb({
  classes,
  // type,
  showInvoiceTable,
  showNCTable,
  showOCTable,
  showCCTable,
  handleEndDateChange,
  handleStartDateChange,
  items,
  dataUrl,
  selectedddItemPaymentMethodFilterData,
  selectedddItemBrokerFilterData,
  selectedddItemCustomerFilterData,
  handleApplyddItemFilterData,
  selectedddItemCategoryPaymentMethodFilterData,
  selectedddItemCategoryBrokerFilterData,
  selectedddItemCategoryCustomerFilterData,
  handleApplyddItemCategoryFilterData,
  handleApplyWBGFilterData,
  invoiceItemCategoryPayload,
  // loaders
  invoiceLoader,
  contractsLoader,
  ddItemLoader,
  ddItemCategoryLoader,
  WBGLoader,

  //Invoices
  invoices,
  selectedInvoiceStartDate,
  setSelectedInvoiceStartDate,
  handleInvoiceStartDateChange,
  selectedInvoiceEndDate,
  setSelectedInvoiceEndDate,

  handleInvoiceEndDateChange,
  handleInvoiceSelect,
  filters,
  selectedInvoiceFilter,
  plants,
  plant,
  selectPlant,
  selectMultiPlant,
  onInvoiceButtonClick,
  plantSelected,
  handleFilterIconClick,
  handleApplyInvoiceFilterData,
  handleApplyContractFilterData,
  handleApplyFilter,
  paymentMethods,
  paymentStatusList,
  showMoreFilters,
  invoiceCSVData,
  invoicePDFData,
  showContractMoreFilters,
  itemCategories,
  transporters,
  selectItemCategory,
  invoiceItemCategory,
  disableItemCats,
  disablePlants,
  selectedContractFilters,
  selectedInvoiceFilters,
  selectedCustomerFilterData,
  selectedBrokerFilterData,
  selectedPaymentMethodFilterData,
  selectedPaymentStatusFilterData,
  contractFilterCount,
  invoiceFilterCount,
  selectedLAFilters,
  ddItemFilterCount,
  ddItemCategoryFilterCount,
  selectedWBGFilters,
  WBGFilterCount,
  handleClearAllClick,
  originalInvoiceResponse,
  originalContractResponse,

  // Contracts/Orders
  contractCategory,
  selectedContractFilter,
  selectContractCategory,
  handleContractSelect,
  onContractButtonClick,
  contractSelected,
  contracts,
  contractCSVData,
  contractItemtag,
  contractDisableItemtag,
  selectedContractStartDate,
  handleContractStartDateChange,
  selectedContractEndDate,
  setSelectedContractEndDate,
  handleContractEndDateChange,
  contractDisableItemCats,
  showContractTable,
  selectedContractTypes,
  contractTypes,
  selectContractTypes,
  disableContractTypes,
  selectedContractPaymentMethodFilterData,
  selectedContractBrokerFilterData,
  selectedContractCustomerFilterData,
  selectedddItemFilters,
  selectedddItemCategoryFilters,
  disableContractsPlants,
  contractsPlantSelected,
  contractsPlants,
  handleHiddenColumns,
  invoiceHiddenColumns,
  contractHiddenColumns,
  ddItemCategoryHiddenColumns,
  ddItemHiddenColumns,
  wbgHiddenColumns,
  // //Dispatch Details
  searchByTypeCategories,
  handleSearchByTypeSelect,
  searchByTypeCategory,
  showddItemMoreFilters,
  showddItemCategoryMoreFilters,
  selectSearchByTypeCategory,
  // showddItemMoreFilters,
  // showddItemCategoryMoreFilters,
  showWBGMoreFilters,

  // // Search By Items
  laStatus,
  ddItemCategory,
  ddItemPlant,
  ddItemCategoryPlant,
  disableddItemPlants,
  disableddItemCategoryPlants,
  ddItemCatdisableItemCats,
  ddItemdisableItemCats,
  disableItems,
  selectDDItem,
  ddItem,
  // selectedDDItemFilter,
  // ddItemCategories,
  selectDDItemCategory,
  onDDItemButtonClick,
  onDDItemCategoryButtonClick,
  ddItemSelected,
  ddItemCategorySelected,
  showDDItemCategoryTable,
  showDDItemTable,
  itemDDetails,
  itemCategoryDDetails,
  itemCategoryDDetailsCSV,
  itemDDetailsCSV,
  // setItemDDetails,
  selectedDDItemStartDate,
  // setSelectedDDItemStartDate,
  selectedDDItemEndDate,
  selectedDDItemCategoryStartDate,
  selectedDDItemCategoryEndDate,
  // setSelectedDDItemEndDate,

  // Weighbridge and Date

  WBGSelected,
  selectedWBGEndDate,
  selectedWBGStartDate,
  WBGPlant,
  WBGResponse,
  originalWBGResponse,
  WBGResponseCSV,
  showWBGTable,
  disableWBGItemCats,
  disableWBGPlants,
  onWBGButtonClick,
  WBGCategory,
  disableDPTPlants,
  disableDPTItemCats,
  // showWBGMoreFilters,

  //EXCEPTION REPORTS
  EXRSelected,
  EXRResponse,
  originalEXRResponse,
  EXRResponseCSV,
  showEXRTable,
  EXRModule,
  EXRChangeTCLCategory,
  selectedEXRStartDate,
  selectedEXREndDate,
  disableEXRChangeTCL,
  disableEXRModules,
  onEXRButtonClick,
  exrHiddenColumns,
  selectedEXRFilters,
  showEXRMoreFilters,
  handleApplyEXRFilterData,
  EXRLoader,
  EXRFilterCount,

  //DPT
  DPTSelected,
  selectedDPTEndDate,
  selectedDPTStartDate,
  DPTPlant,
  DPTResponse,
  DPTResponseCSV,
  showDPTTable,
  // DPTDisableItemCats,
  // disableDPTPlants,
  onDPTButtonClick,
  DPTCategory,
  ddItemPlantPayload,
  WBGPlantPayload,
  invoicePlantPayload,
  ddItemCategoryPlantPayload,
  contractsPlantPayload,

  // SubContract
  customerType,
  customerTypeL1,
  itemTag,
  SubContractStatusDropdown,
  subContractDisableItemCats,
  subContractDisableCustomerType,
  subContractDisableCustomerTypeL1,
  subContractDisableItemtag,
  subContractDisableStatus,
  plannedUniqueItemSize,
  handleSubContractCSVClick,
  refCSVLink,
  SubContractCategory,
  SubContractCustomerType,
  SubContractCustomerTypeL1,
  SubContractItemtag,
  SubContractStatus,
  selectedSubContractStartDate,
  selectedSubContractEndDate,
  SubContractResponse,
  originalSubContractResponse,
  SubContractResponseCSV,
  selectedSubContractFilters,
  subContractFilterCount,
  showSubContractMoreFilters,
  subContractsLoader,
  subContractHiddenColumns,
  showSubContractTable,
  selectSubContractStatus,
  selectItemtag,
  selectCustomerTypeL1,
  selectCustomerType,
  onSubContractButtonClick,
  fetchSubContractCSVReportProgress,
  handleApplySubContractFilterData,

  handleTableDataSorting,
  handleTabValue,
  fetchReportProgress,
  isMobile,
  ...props
}) {
  const classess = useStyles();
  const [value, setValue] = React.useState(0);
  const [type, setType] = useState("")
  const [snackOpen, setSnackOpen] = useState(false)


  React.useEffect(() => {

    const tabPerm = [isAccessibleWidget(props.user, "invoices_report"),
    isAccessibleWidget(props.user, "contracts_report"),
    isAccessibleWidget(props.user, "loading_advices_report"),
    isAccessibleWidget(props.user, "weighbridge_and_gate_report")
    ];
    const tabPermIndex = tabPerm.indexOf(true);
    setValue(tabPermIndex)
    handleTabValue(tabPermIndex)
  }, [])

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    handleTabValue(newValue)
    setType(newValue === 0 ? "Invoice" :
      newValue === 1 ? "Contract" :
        newValue === 2 ? searchByTypeCategory.value
          : newValue === 3 ? "WBG"
            : "")
  };

  const handleOpenSnackClick = () => {
    setSnackOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  return (
    <Layout {...props}>
      <div style={{ height: "100%", display: "flex", width: "100%" }} >
        <div
          style={{
            width: "100%",
            // padding: "30px"
            padding: "0 30px 30px 0"
          }}        >
          <div
            style={{ marginBottom: 8 }}
          >
            <Bread
              data={[
                {
                  title: "Reports",
                  onClick: () =>
                    props.history.push("/reports"),
                },
              ]} />
          </div>
          <div className={classess.root}>
            <Paper style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "20px"
            }
            }>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "100%", marginLeft: 20 }}>
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        borderBottom: `2px solid ${primaryColor}`,
                        // colur: grey,
                      },
                    }}
                    value={value}
                    onChange={handleTabChange}
                    className={classes.tabsStyles}
                    variant="scrollable"
                  >

                    {
                      isAccessibleWidget(props.user, "invoices_report") &&
                      <Tab label="Invoices" {...a11yProps(0)} />
                    }
                    {
                      isAccessibleWidget(props.user, "contracts_report") &&
                      <Tab label="Contracts" {...a11yProps(1)} />
                    }
                    {
                      isAccessibleWidget(props.user, "loading_advices_report") &&
                      <Tab label="Loading Advices" {...a11yProps(2)} />
                    }
                    {
                      isAccessibleWidget(props.user, "weighbridge_and_gate_report") &&
                      <Tab label="Weighbridge and Gate" {...a11yProps(3)} />
                    }
                    {isAccessibleWidget(props.user, "exception_report") &&
                      <Tab label="Exception Reports" {...a11yProps(4)} />
                    }
                    {
                      1 > 0 &&
                      // isAccessibleWidget(props.user, "subcontract_report") &&
                      <Tab label="SubContract Reports" {...a11yProps(5)} />
                    }
                  </Tabs>
                </div>
              </div>
            </Paper>
          </div>

          {/* Invoice Tab */}

          <>
            {isAccessibleWidget(props.user, "invoices_report") &&
              <TabPanel value={value} index={0}>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "15px", flexDirection: isMobile && 'column' }}>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <MultiSelect
                      classes={classes}
                      selectedFilter={plant}
                      handleSelect={selectMultiPlant}
                      filters={plants.data}
                      tabValue={value}
                      disableOptions={disablePlants}
                      selectFor="Plants"
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <MultiSelect
                      classes={classes}
                      selectedFilter={invoiceItemCategory}
                      disableItemCats={disableItemCats}
                      handleSelect={selectItemCategory}
                      filters={itemCategories.data}
                      selectFor="Item Category"
                      tabValue={value}
                      disableOptions={disableItemCats}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <StartDatePicker
                      selectedStartDate={selectedInvoiceStartDate}
                      handleStartDateChange={handleStartDateChange}
                      tabValue={value}
                      isMobile={isMobile}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <EndDatePicker
                      selectedStartDate={selectedInvoiceStartDate}
                      selectedEndDate={selectedInvoiceEndDate}
                      handleEndDateChange={handleEndDateChange}
                      tabValue={value}
                      classess={classess}
                    />
                  </div>
                  <ButtonsPanel
                    containerWidth="22%"
                    selectedStartDate={selectedInvoiceStartDate}
                    handleFilterIconClick={handleFilterIconClick}
                    selectedEndDate={selectedInvoiceEndDate}
                    onButtonClick={onInvoiceButtonClick}
                    tabValue={value}
                    tabData={invoicePDFData}
                    csvHeaders={invoiceCsvHeaders}
                    originalResponse={originalInvoiceResponse}
                    csvName="Invoices"
                    tabCSVData={invoiceCSVData}
                    reportName="Invoices"
                    handleOpenSnackClick={handleOpenSnackClick}
                    selectedFilters={selectedInvoiceFilters}
                    filterCount={invoiceFilterCount}
                    user={props.user}
                    loader={invoiceLoader}
                    plants={invoicePlantPayload}
                    itemCats={invoiceItemCategoryPayload}
                    handleTableDataSorting={handleTableDataSorting}
                    updatedCols={invoiceHiddenColumns}
                    isMobile={isMobile}
                  />
                </div>
                {

                  showMoreFilters &&
                  <Filters
                    itemCategories={itemCategories}
                    transporters={transporters}
                    items={items}
                    laStatus={laStatus}
                    paymentMethods={paymentMethods}
                    paymentStatusList={paymentStatusList}
                    handleApplyFilter={handleApplyFilter}
                    handleFilterIconClick={handleFilterIconClick}
                    user={props.user}
                    handleApplyFilterData={handleApplyInvoiceFilterData}
                    selectedFilters={selectedInvoiceFilters}
                    selectedCustomerFilterData={selectedCustomerFilterData}
                    selectedBrokerFilterData={selectedBrokerFilterData}
                    selectedPaymentMethodFilterData={selectedPaymentMethodFilterData}
                    handleClearAllClick={handleClearAllClick}
                    tabValue={value}
                    isMobile={isMobile}
                  />
                  // </Dialog>
                }
                {
                  (showInvoiceTable) &&
                  <Table
                    type={type}
                    data={invoices}
                    value={value}
                    handleTableDataSorting={handleTableDataSorting}
                    handleHiddenColumns={handleHiddenColumns}
                    fetchReportProgress={fetchReportProgress}
                    module="Reports"
                    tableHeight={"calc(100vh - 310px)"}
                  />
                }
                <SnackbarComponent
                  open={snackOpen}
                  handleClose={handleClose}
                  customAutoHideDuration={2000}
                  snackbarMessage="Downloading PDF..."
                //type="info"
                />
              </TabPanel>
            }

            {/* Contracts */}
            {isAccessibleWidget(props.user, "contracts_report") &&
              <TabPanel value={value} index={1}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", alignItems: "center", marginBottom: "10px", flexDirection: isMobile && 'column' }}>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15 }}>
                    <MultiSelect
                      classes={classes}
                      selectedFilter={contractsPlants}
                      handleSelect={selectMultiPlant}
                      filters={plants.data}
                      tabValue={value}
                      disableOptions={disableContractsPlants}
                      selectFor="Plants"
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15 }}>
                    <MultiSelect
                      selectedFilter={selectedContractTypes}
                      handleSelect={selectContractTypes}
                      filters={contractTypes}
                      selectFor="Contract Types"
                      disableOptions={disableContractTypes}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15 }}>
                    <MultiSelect
                      selectedFilter={contractCategory}
                      handleSelect={selectItemCategory}
                      filters={itemCategories.data}
                      selectFor="Item Category"
                      disableOptions={contractDisableItemCats}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15 }}>
                    <MultiSelect
                      selectedFilter={contractItemtag}
                      handleSelect={selectItemtag}
                      filters={itemTag.data}
                      selectFor="Item Tag"
                      disableOptions={contractDisableItemtag}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15 }}>
                    <StartDatePicker
                      selectedStartDate={selectedContractStartDate}
                      handleStartDateChange={handleStartDateChange}
                      tabValue={value}
                      isMobile={isMobile}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15 }}>
                    <EndDatePicker
                      selectedStartDate={selectedContractStartDate}
                      selectedEndDate={selectedContractEndDate}
                      handleEndDateChange={handleEndDateChange}
                      tabValue={value}
                      classess={classess}
                    />
                  </div>

                  <ButtonsPanel
                    containerWidth="18%"
                    selectedStartDate={selectedContractStartDate}
                    selectedEndDate={selectedContractEndDate}
                    onButtonClick={onContractButtonClick}
                    tabValue={value}
                    tabData={contracts}
                    csvHeaders={contractsCsvHeaders}
                    originalResponse={originalContractResponse}
                    csvName="Contracts"
                    tabCSVData={contractCSVData}
                    reportName="Contracts"
                    handleOpenSnackClick={handleOpenSnackClick}
                    handleFilterIconClick={handleFilterIconClick}
                    selectedFilters={selectedContractFilters}
                    filterCount={contractFilterCount}
                    user={props.user}
                    loader={contractsLoader}
                    plants={contractsPlantPayload}
                    itemCats={contractCategory}
                    updatedCols={contractHiddenColumns}
                    isMobile={isMobile}
                  />


                </div>
                {showContractMoreFilters &&
                  <Filters
                    handleFilterIconClick={handleFilterIconClick}
                    itemCategories={itemCategories}
                    items={items}
                    transporters={transporters}
                    laStatus={laStatus}
                    paymentMethods={paymentMethods}
                    handleApplyFilter={handleApplyFilter}
                    user={props.user}
                    handleApplyFilterData={handleApplyContractFilterData}
                    selectedFilters={selectedContractFilters}
                    selectedCustomerFilterData={selectedContractCustomerFilterData}
                    selectedBrokerFilterData={selectedContractBrokerFilterData}
                    selectedPaymentMethodFilterData={selectedContractPaymentMethodFilterData}
                    handleClearAllClick={handleClearAllClick}
                    tabValue={value}
                    isMobile={isMobile}
                  />
                }
                {(showContractTable) &&
                  <Table
                    type={type}
                    data={contracts}
                    value={value}
                    handleTableDataSorting={handleTableDataSorting}
                    handleHiddenColumns={handleHiddenColumns}
                    fetchReportProgress={fetchReportProgress}
                    module="Reports"
                    tableHeight={"calc(100vh - 310px)"}
                  />
                }
                <SnackbarComponent
                  open={snackOpen}
                  handleClose={handleClose}
                  customAutoHideDuration={2000}
                  snackbarMessage="Downloading PDF..."
                // type="info"
                />
              </TabPanel>
            }



            {/* Dispatch Details || Loading Advice Tab */}
            {isAccessibleWidget(props.user, "loading_advices_report") &&
              <TabPanel value={value} index={2}>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "15px", flexDirection: isMobile && 'column' }}
                >
                  <div style={{ width: isMobile ? '100%' : '15%', marginBottom: isMobile && 10 }}>
                    <MultiSelect
                      classes={classes}
                      selectedFilter={ddItemCategoryPlant}
                      handleSelect={selectMultiPlant}
                      filters={plants.data}
                      tabValue={value}
                      disableOptions={disableddItemCategoryPlants}
                      selectFor="Plants"
                    />
                  </div>

                  {
                    (searchByTypeCategory.value !== "default") &&
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: isMobile ? '100%' : "84%", flexDirection: isMobile && 'column' }}>
                      <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                        <SearchByTypeFilter
                          selectedSearchByTypeFilter={searchByTypeCategory}
                          handleSearchByTypeSelect={selectSearchByTypeCategory}
                          filters={searchByTypeCategories}
                        />
                      </div>
                      {
                        searchByTypeCategory.value === "Item Category" ?
                          <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                            <MultiSelect
                              classes={classes}
                              selectedFilter={ddItemCategory}
                              handleSelect={selectItemCategory}
                              filters={itemCategories.data}
                              selectFor="Item Category"
                              disableOptions={ddItemCatdisableItemCats}
                            />
                          </div>
                          :
                          searchByTypeCategory.value === "Item" ?
                            <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                              <MultiSelect
                                classes={classes}
                                selectedFilter={ddItem}
                                handleSelect={selectDDItem}
                                filters={items.data}
                                selectFor="Item"
                                disableOptions={disableItems}
                              />
                            </div>
                            : null
                      }
                      <div style={{ width: isMobile ? '100%' : '18%', marginBottom: isMobile && 10 }}>
                        <StartDatePicker
                          selectedStartDate={
                            searchByTypeCategory.value === "Item"
                              ? selectedDDItemStartDate
                              : searchByTypeCategory.value === "Item Category" &&
                              selectedDDItemCategoryStartDate
                          }
                          handleStartDateChange={handleStartDateChange}
                          tabValue={value}
                          isMobile={isMobile}
                        />
                      </div>
                      <div style={{ width: isMobile ? '100%' : '18%', marginBottom: isMobile && 10 }}>
                        <EndDatePicker
                          selectedStartDate={
                            searchByTypeCategory.value === "Item"
                              ? selectedDDItemStartDate
                              : searchByTypeCategory.value === "Item Category" &&
                              selectedDDItemCategoryStartDate
                          }
                          selectedEndDate={
                            searchByTypeCategory.value === "Item"
                              ? selectedDDItemEndDate
                              : searchByTypeCategory.value === "Item Category" &&
                              selectedDDItemCategoryEndDate
                          }
                          handleEndDateChange={handleEndDateChange}
                          tabValue={value}
                          classess={classess}
                        />
                      </div>
                      {
                        searchByTypeCategory.value === "Item" ?
                          <ButtonsPanel
                            containerWidth="22%"
                            selectedStartDate={selectedDDItemStartDate}
                            handleFilterIconClick={handleFilterIconClick}
                            selectedEndDate={selectedDDItemEndDate}
                            onButtonClick={onDDItemButtonClick}
                            tabValue={value}
                            tabData={itemDDetails}
                            originalResponse={itemDDetails}
                            csvName="LA Item-wise"
                            tabCSVData={itemDDetailsCSV}
                            csvHeaders={loadingAdvicesCSVHeaders}
                            reportName="LA Item-wise"
                            handleOpenSnackClick={handleOpenSnackClick}
                            selectedFilters={selectedddItemFilters}
                            filterCount={ddItemFilterCount}
                            user={props.user}
                            loader={ddItemLoader}
                            updatedCols={ddItemHiddenColumns}
                            isMobile={isMobile}
                          />

                          : searchByTypeCategory.value === "Item Category" &&
                          <ButtonsPanel
                            containerWidth="22%"
                            selectedStartDate={selectedDDItemCategoryStartDate}
                            handleFilterIconClick={handleFilterIconClick}
                            selectedEndDate={selectedDDItemCategoryEndDate}
                            onButtonClick={onDDItemCategoryButtonClick}
                            tabValue={value}
                            tabData={itemCategoryDDetails}
                            originalResponse={itemCategoryDDetails}
                            csvName="LA Item-Category-wise"
                            tabCSVData={itemCategoryDDetailsCSV}
                            csvHeaders={loadingAdvicesCSVHeaders}
                            reportName="LA Item-Category-wise"
                            handleOpenSnackClick={handleOpenSnackClick}
                            selectedFilters={selectedddItemCategoryFilters}
                            filterCount={ddItemCategoryFilterCount}
                            user={props.user}
                            loader={ddItemCategoryLoader}
                            updatedCols={ddItemCategoryHiddenColumns}
                            fetchReportProgress={fetchReportProgress}
                            isMobile={isMobile}
                          />

                      }
                    </div>
                  }
                </div>
                {
                  searchByTypeCategory.value === "Item" && showddItemMoreFilters ?
                    <Filters
                      handleFilterIconClick={handleFilterIconClick}
                      itemCategories={itemCategories}
                      items={items}
                      transporters={transporters}
                      laStatus={laStatus}
                      paymentMethods={paymentMethods}
                      handleApplyFilter={handleApplyFilter}
                      user={props.user}
                      handleApplyFilterData={handleApplyddItemFilterData}
                      selectedFilters={selectedddItemFilters}
                      selectedCustomerFilterData={selectedddItemCustomerFilterData}
                      selectedBrokerFilterData={selectedddItemBrokerFilterData}
                      selectedPaymentMethodFilterData={selectedddItemPaymentMethodFilterData}
                      handleClearAllClick={handleClearAllClick}
                      tabValue={value}
                      isMobile={isMobile}
                    /> :
                    searchByTypeCategory.value === "Item Category" && showddItemCategoryMoreFilters ?
                      <Filters
                        handleFilterIconClick={handleFilterIconClick}
                        itemCategories={itemCategories}
                        items={items}
                        transporters={transporters}
                        laStatus={laStatus}
                        paymentMethods={paymentMethods}
                        handleApplyFilter={handleApplyFilter}
                        user={props.user}
                        handleApplyFilterData={handleApplyddItemCategoryFilterData}
                        selectedFilters={selectedddItemCategoryFilters}
                        selectedCustomerFilterData={selectedddItemCategoryCustomerFilterData}
                        selectedBrokerFilterData={selectedddItemCategoryBrokerFilterData}
                        selectedPaymentMethodFilterData={selectedddItemCategoryPaymentMethodFilterData}
                        handleClearAllClick={handleClearAllClick}
                        tabValue={value}
                        isMobile={isMobile}
                      /> : null
                }
                {
                  (searchByTypeCategory.value === "Item"
                    && showDDItemTable) ?
                    <Table
                      type={searchByTypeCategory.value}
                      data={itemDDetails}
                      value={value}
                      itemName={ddItem.value}
                      handleHiddenColumns={handleHiddenColumns}
                      fetchReportProgress={fetchReportProgress}
                      module="Reports"
                      tableHeight={"calc(100vh - 310px)"}
                    /> :
                    searchByTypeCategory.value === "Item Category" && showDDItemCategoryTable &&
                    <Table
                      type={searchByTypeCategory.value}
                      data={itemCategoryDDetails}
                      value={value}
                      handleHiddenColumns={handleHiddenColumns}
                      fetchReportProgress={fetchReportProgress}
                      module="Reports"
                      tableHeight={"calc(100vh - 310px)"}
                    />
                }


                <SnackbarComponent
                  open={snackOpen}
                  handleClose={handleClose}
                  customAutoHideDuration={2000}
                  snackbarMessage="Downloading PDF..."
                // type="info"
                />
              </TabPanel>
            }
            {isAccessibleWidget(props.user, "weighbridge_and_gate_report") &&
              <TabPanel value={value} index={3}>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "10px", flexDirection: isMobile && 'column' }}>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <MultiSelect
                      classes={classes}
                      selectedFilter={WBGPlant}
                      handleSelect={selectMultiPlant}
                      filters={plants.data}
                      tabValue={value}
                      disableOptions={disableWBGPlants}
                      selectFor="Plants"
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <MultiSelect
                      selectedFilter={WBGCategory}
                      handleSelect={selectItemCategory}
                      filters={itemCategories.data}
                      selectFor="Item Category"
                      disableOptions={disableWBGItemCats}

                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <StartDatePicker
                      selectedStartDate={selectedWBGStartDate}
                      handleStartDateChange={handleStartDateChange}
                      tabValue={value}
                      isMobile={isMobile}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <EndDatePicker
                      selectedStartDate={selectedWBGStartDate}
                      selectedEndDate={selectedWBGEndDate}
                      handleEndDateChange={handleEndDateChange}
                      tabValue={value}
                      classess={classess}
                    />
                  </div>
                  <ButtonsPanel
                    containerWidth="22%"
                    selectedStartDate={selectedWBGStartDate}
                    handleFilterIconClick={handleFilterIconClick}
                    selectedEndDate={selectedWBGEndDate}
                    onButtonClick={onWBGButtonClick}
                    tabValue={value}
                    tabData={WBGResponse}
                    originalResponse={originalWBGResponse}
                    csvName="WB and Gate"
                    tabCSVData={WBGResponseCSV}
                    csvHeaders={WBGCSVHeaders}
                    reportName="WB and Gate"
                    handleOpenSnackClick={handleOpenSnackClick}
                    selectedFilters={selectedWBGFilters}
                    filterCount={WBGFilterCount}
                    user={props.user}
                    loader={WBGLoader}
                    updatedCols={wbgHiddenColumns}
                    isMobile={isMobile}
                  />
                </div>

                {showWBGMoreFilters ?
                  <Filters
                    handleFilterIconClick={handleFilterIconClick}
                    itemCategories={itemCategories}
                    items={items}
                    transporters={transporters}
                    laStatus={laStatus}
                    paymentMethods={paymentMethods}
                    handleApplyFilter={handleApplyFilter}
                    user={props.user}
                    handleApplyFilterData={handleApplyWBGFilterData}
                    selectedFilters={selectedWBGFilters}
                    // selectedCustomerFilterData={selectedddItemCategoryCustomerFilterData}
                    // selectedBrokerFilterData={selectedddItemCategoryBrokerFilterData}
                    // selectedPaymentMethodFilterData={selectedddItemCategoryPaymentMethodFilterData}
                    handleClearAllClick={handleClearAllClick}
                    tabValue={value}
                    isMobile={isMobile}
                  /> : null
                }
                {
                  showWBGTable ?
                    <Table
                      type={type}
                      data={WBGResponse}
                      value={value}
                      handleHiddenColumns={handleHiddenColumns}
                      fetchReportProgress={fetchReportProgress}
                      module="Reports"
                      tableHeight={"calc(100vh - 310px)"}
                    // itemName={ddItem.value}
                    /> : null
                }
                <SnackbarComponent
                  open={snackOpen}
                  handleClose={handleClose}
                  customAutoHideDuration={2000}
                  snackbarMessage="Downloading PDF..."
                // type="info"
                />
              </TabPanel>
            }
            {/* Exception Reports */}
            {isAccessibleWidget(props.user, "exception_report") &&
              <TabPanel value={value} index={4}>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "10px", flexDirection: isMobile && 'column' }}>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <MultiSelectForEXRModules
                      classes={classes}
                      selectedFilter={EXRModule}
                      handleSelect={selectMultiPlant}
                      filters={["customer"]}
                      tabValue={value}
                      disableOptions={disableEXRModules}
                      selectFor="Modules"
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <MultiSelectForEXRTCL
                      selectedFilter={EXRChangeTCLCategory}
                      handleSelect={selectItemCategory}
                      filters={["credit limit provided"]}
                      selectFor="Credit Limit Provided"
                      disableOptions={disableEXRChangeTCL}

                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <StartDatePicker
                      selectedStartDate={selectedEXRStartDate}
                      handleStartDateChange={handleStartDateChange}
                      tabValue={value}
                      isMobile={isMobile}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : '17%', marginBottom: isMobile && 10 }}>
                    <EndDatePicker
                      selectedStartDate={selectedEXRStartDate}
                      selectedEndDate={selectedEXREndDate}
                      handleEndDateChange={handleEndDateChange}
                      tabValue={value}
                      classess={classess}
                    />
                  </div>
                  <ButtonsPanel
                    containerWidth="22%"
                    selectedStartDate={selectedEXRStartDate}
                    handleFilterIconClick={handleFilterIconClick}
                    selectedEndDate={selectedEXREndDate}
                    onButtonClick={onEXRButtonClick}
                    tabValue={value}
                    tabData={EXRResponse}
                    originalResponse={originalEXRResponse}
                    csvName="Exception Reports"
                    tabCSVData={EXRResponseCSV}
                    csvHeaders={EXRCSVHeaders}
                    reportName="Exception Reports"
                    handleOpenSnackClick={handleOpenSnackClick}
                    selectedFilters={selectedEXRFilters}
                    filterCount={EXRFilterCount}
                    user={props.user}
                    loader={EXRLoader}
                    updatedCols={exrHiddenColumns}
                    isMobile={isMobile}
                  />
                </div>

                {showEXRMoreFilters ?
                  <Filters
                    handleFilterIconClick={handleFilterIconClick}
                    // itemCategories={itemCategories}
                    // items={items}
                    // transporters={transporters}
                    // laStatus={laStatus}
                    // paymentMethods={paymentMethods}
                    // handleApplyFilter={handleApplyFilter}
                    user={props.user}
                    handleApplyFilterData={handleApplyEXRFilterData}
                    selectedFilters={selectedEXRFilters}
                    // selectedCustomerFilterData={selectedddItemCategoryCustomerFilterData}
                    // selectedBrokerFilterData={selectedddItemCategoryBrokerFilterData}
                    // selectedPaymentMethodFilterData={selectedddItemCategoryPaymentMethodFilterData}
                    handleClearAllClick={handleClearAllClick}
                    tabValue={value}
                    isMobile={isMobile}
                  /> : null
                }
                {
                  showEXRTable ?
                    <Table
                      type={type}
                      data={EXRResponse}
                      value={value}
                      handleHiddenColumns={handleHiddenColumns}
                      fetchReportProgress={fetchReportProgress}
                      module="Reports"
                      tableHeight={"calc(100vh - 310px)"}
                    // itemName={ddItem.value}
                    /> : null
                }
                <SnackbarComponent
                  open={snackOpen}
                  handleClose={handleClose}
                  customAutoHideDuration={2000}
                  snackbarMessage="Downloading PDF..."
                // type="info"
                />
              </TabPanel>
            }
            {/* SubContracts */}
            {
              //isAccessibleWidget(props.user, "subcontracts_report") &&
              1 > 0 &&
              <TabPanel value={value} index={5}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", alignItems: "center", marginBottom: "10px", flexDirection: isMobile && 'column' }}>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15, }}>
                    <MultiSelect
                      selectedFilter={SubContractCategory}
                      handleSelect={selectItemCategory}
                      filters={itemCategories.data}
                      selectFor="Item Category"
                      disableOptions={subContractDisableItemCats}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15, }}>
                    <MultiSelect
                      selectedFilter={SubContractCustomerType}
                      handleSelect={selectCustomerType}
                      filters={customerType.data}
                      selectFor="Customer Type"
                      disableOptions={subContractDisableCustomerType}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15, }}>
                    <MultiSelect
                      selectedFilter={SubContractCustomerTypeL1}
                      handleSelect={selectCustomerTypeL1}
                      filters={customerTypeL1.data}
                      selectFor="Customer Type L1"
                      disableOptions={subContractDisableCustomerTypeL1}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15, }}>
                    <MultiSelect
                      selectedFilter={SubContractItemtag}
                      handleSelect={selectItemtag}
                      filters={itemTag.data}
                      selectFor="Item Tag"
                      disableOptions={subContractDisableItemtag}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15, }}>
                    <MultiSelect
                      selectedFilter={SubContractStatus}
                      handleSelect={selectSubContractStatus}
                      filters={SubContractStatusDropdown.data}
                      selectFor="Status"
                      disableOptions={subContractDisableStatus}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15, }}>
                    <StartDatePicker
                      selectedStartDate={selectedSubContractStartDate}
                      handleStartDateChange={handleStartDateChange}
                      tabValue={value}
                      isMobile={isMobile}
                    />
                  </div>
                  <div style={{ width: isMobile ? '100%' : 200, marginBottom: isMobile ? 10 : 15, marginRight: 15, }}>
                    <EndDatePicker
                      selectedStartDate={selectedSubContractStartDate}
                      selectedEndDate={selectedSubContractEndDate}
                      handleEndDateChange={handleEndDateChange}
                      tabValue={value}
                      classess={classess}
                    />
                  </div>

                  <ButtonsPanel
                    containerWidth="inherit"
                    selectedStartDate={selectedSubContractStartDate}
                    selectedEndDate={selectedSubContractEndDate}
                    onButtonClick={onSubContractButtonClick}
                    handleSubContractCSVClick={handleSubContractCSVClick}
                    refCSVLink={refCSVLink}
                    tabValue={value}
                    tabData={SubContractResponse}
                    csvHeaders={SubContractResponseCSV.header}
                    csvClickLoader={SubContractResponseCSV.loading}
                    originalResponse={originalSubContractResponse}
                    csvName="SubContracts"
                    tabCSVData={SubContractResponseCSV}
                    reportName="Sub Contracts"
                    handleOpenSnackClick={handleOpenSnackClick}
                    handleFilterIconClick={handleFilterIconClick}
                    selectedFilters={selectedSubContractFilters}
                    filterCount={subContractFilterCount}
                    user={props.user}
                    loader={subContractsLoader}
                    fetchSubContractCSVReportProgress={fetchSubContractCSVReportProgress}
                    // plants={contractsPlantPayload}
                    itemCats={SubContractCategory}
                    customerType={SubContractCustomerType}
                    customerTypeL1={SubContractCustomerTypeL1}
                    ItemTag={SubContractItemtag}
                    status={SubContractStatus}
                    updatedCols={subContractHiddenColumns}
                    isMobile={isMobile}
                  />
                </div>
                {showSubContractMoreFilters &&
                  <Filters
                    handleFilterIconClick={handleFilterIconClick}
                    itemCategories={itemCategories}
                    items={items}
                    transporters={transporters}
                    laStatus={laStatus}
                    paymentMethods={paymentMethods}
                    handleApplyFilter={handleApplyFilter}
                    user={props.user}
                    handleApplyFilterData={handleApplySubContractFilterData}
                    selectedFilters={selectedSubContractFilters}
                    // selectedCustomerFilterData={selectedSubContractCustomerFilterData}
                    // selectedBrokerFilterData={selectedSubContractBrokerFilterData}
                    // selectedPaymentMethodFilterData={selectedContractPaymentMethodFilterData}
                    handleClearAllClick={handleClearAllClick}
                    tabValue={value}
                    isMobile={isMobile}
                  />
                }
                {(showSubContractTable) &&
                  <Table
                    type={type}
                    data={SubContractResponse}
                    value={value}
                    tablePlannedItemList={plannedUniqueItemSize}
                    handleTableDataSorting={handleTableDataSorting}
                    // handleHiddenColumns={handleHiddenColumns}
                    fetchReportProgress={fetchReportProgress}
                    module="Reports"
                    tableHeight={"calc(100vh - 310px)"}
                  />
                }
                <SnackbarComponent
                  open={snackOpen}
                  handleClose={handleClose}
                  customAutoHideDuration={2000}
                  snackbarMessage="Downloading PDF..."
                // type="info"
                />
              </TabPanel>
            }
          </>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(withStyles(customersStyles)(ReportsWeb));