import React, { Component } from "react";
import OrderCard from "./OrderCard";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor } from "../../../styles/colors";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import SpinLoader from "../../common/SpinLoader";
import DispatchCard from "./DispatchCard"

const styles = {
  PendingMainBody: {
    width: "100%",
  },
  messageShowContainer: {
    width: "100%",
    // display: "flex",
    // justifyContent: "center",
  },
  messageShow: {
    // width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // padding: "30px",
    fontSize: "1.1em",
    color: bottomNavigationFontColor,
  },
};
class Pending extends Component {
  render() {
    const { classes, order, singleLALoader, isMobile } = this.props;
    const details = this.props.details ? this.props.details : null
    if (!details) {
      let laNo = this.props.location.pathname.split("/")[3]
      this.props.fetchLADetails(laNo)
    }
    // console.log(this.props)
    return (
      <div className={classes.PendingMainBody}>
        {!isMobile ? (
          <div style={{ padding: "10px 0px 10px 2%" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                if (this.props?.location?.state?.message)
                  this.props.history.push("/dispatch")
                else
                  if (this.props.history.length > 2)
                    this.props.history.goBack();
                  else
                    this.props.history.push("/dispatch")

                // this.props.history.push(`/orders/active/${order.id}`);
                // }
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          </div>
        ) : null}
        {
          singleLALoader ?
            <div style={{
              // marginLeft: 165,
              display: "flex",
              justifyContent: "center",
              marginTop: 100
            }}>
              <SpinLoader />
            </div>
            :
            details &&
            <div style={{ width: "100%", marginTop: "8px" }}>
              <DispatchCard
                {...this.props}
                itemCategory={details.item_category.category_name}
                quantity={details.la_quantity}
                plantName={details.plant_short_name}
                LANumber={details.la_number}
                truckNumber={details.truck_number}
                dispatchStatus={details.dispatch_status}
                orderNumber={details.order.order_number}
                orderId={details.order.id}
              />
            </div>
        }
        <div className={classes.messageShowContainer}
          style={{
            padding: !isMobile ? "20px 6% 10px 6%" : "20px 5% 10px 6%",
          }}>
          <div className={classes.messageShow}>
            <span>The Loading Advice is pending for approval by REAL Group. The details will be available here when the contract is approved</span>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Pending);
