import { useEffect, useState } from "react";
import {
  arraySortBykeyAllBrowser,
  fetchApiDataAndUpdateLocalStorage,
  generateRandomColor,
  pxToEm,
  startDateOFToday,
  startOFWeek,
  endOFWeek,
  startOFMonth,
  endOFMonth,
  startDateOFYear,
  endDateOFYear,
  monthKeys,
  weekKeys,
  endDateOFToday,
  hasReadAccess,
} from "../../../../methods";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Tooltip
} from "@mui/material";
import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { MuiDateRangePicker } from "./DateRangePicker";
import DateRangeIcon from '@mui/icons-material/DateRange';
import SnackbarComponent from "../../common/SnackbarComponent";
import moment from "moment";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import PdfCsvButton from "../../common/PdfCsvButton";
import { LineSalesGraph } from "./BarAndLineSalesGraph";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: 'separate',
    // borderSpacing: '5px 4px'
  },
  paper: {
    position: 'fixed',
    top: '60%',
    left: '70%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '65%',
    zIndex: theme.zIndex.modal + 1,
  },
  underline: {
    content: "''",
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '50%', // Set the length of the underline
    borderBottom: '1px solid #000', // Style and color of the underline
  },
  root: {
    "&.MuiButtonGroup-root": {
      borderColor: "grey !important",
    },
    "&.MuiTableCell-root": {
      padding: "0 10px"
    },
    '&::-webkit-scrollbar': {
      height: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(75, 135, 197, 0.9)',
      borderRadius: 10,
      border: '2px solid transparent',
      backgroundClip: 'padding-box', // <== make the border work
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(75, 135, 197, 0.9)',
      // height: 30,
      // border: 0,
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
  },
  sticky: {
    "&.MuiTableCell-root": {
      padding: "2px"
    },
    position: window?.innerWidth > 768 ? "sticky" : "static",
    left: "200px",
    background: "white",
    //zIndex: 2,
    width: "100px",
    //boxShadow: "5px 2px 5px grey",
    //borderRight: "2px solid black"
  },
  fixed: {
    "&.MuiTableCell-root": {
      padding: "2px"
    },
    position: "sticky",
    background: "white",
    //zIndex: 3,
    width: window?.innerWidth < 768 ? "120px" : "200px",
    left: 0,
    //boxShadow: "5px 2px 5px grey",
    //borderRight: "2px solid black"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noCaret: {
    caretColor: 'transparent',
  },
  loaderStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: '100%',
  }
}));

const styles = {
  tablePaper: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "20px",
  },
  SelectContainer: (isMobile) => ({
    display: 'flex',
    width: isMobile ? '98%' : 250,
    margin: "0 10px",
    paddingTop: 8,
  }),
  dropdownContainer: (isMobile) => ({
    display: 'flex',
    width: isMobile ? '85%' : "15%",
    margin: '20px 0',
  }),
  dateButtonsGroup: (dateSelected, currentButtonValue) => ({
    width: 45,
    height: 45,
    backgroundColor: dateSelected === currentButtonValue ? "#293C79" : "white",
    color: dateSelected === currentButtonValue ? "white" : "grey"
  }),
  dropdownText: {
    '& .MuiTypography-root': {
      padding: '0',
      fontFamily: 'Poppins',
      fontSize: pxToEm(12),
      lineHeight: 1
    },
  },
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};


const PDFCSV = (props) => {
  const classes = useStyles();
  const [snackbarPDFOpen, setSnackbarPDFOpen] = useState(false);

  const handleSnackbarPDFOpen = () => {
    setSnackbarPDFOpen(true)
  }

  const handleSnackbarPDFClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarPDFOpen(false)
  }

  const {
    handlePdfClick,
    handleCsvClick,
    pdfData,
    pdfHeader,
    csvData,
    csvHeader,
    csvLoader,
    pdfloader,
    extraPdfData,
    module,
    mobileCSVFilename,
    webCSVFilename,
    onClickApiCall,
    refCSVLink,
    snackbarOpen,
  } = props;
  console.log("commanWidget", snackbarOpen)
  return (
    <div style={{ padding: '15px 0 0 10px' }}>
      {onClickApiCall
        ? <PdfCsvButton
          handlePrintClick={handlePdfClick}
          handleCSVClick={handleCsvClick}
          onClickApiCall={true}
          refCSVLink={refCSVLink}
          csvData={csvData}
          csvHeader={csvHeader}
          csvLoader={csvLoader}
          pdfLoader={pdfloader}
          mobileCSVFilename={mobileCSVFilename}
          webCSVFilename={webCSVFilename}
          handleSnackbarPDFOpen={handleSnackbarPDFOpen}
        />
        : <>
          <PdfCsvButton
            handlePrintClick={handlePdfClick}
            csvData={csvData}
            csvHeader={csvHeader}
            mobileCSVFilename={mobileCSVFilename}
            webCSVFilename={webCSVFilename}
            handleSnackbarPDFOpen={handleSnackbarPDFOpen}
          />
        </>
      }
      <SnackbarComponent
        open={snackbarPDFOpen}
        handleClose={handleSnackbarPDFClose}
        customAutoHideDuration={3000}
        snackbarMessage="Downloading ..."
      />
    </div>
  )
}

const WidgetTop = (props) => {
  const classes = useStyles();
  const [title, setTitle] = useState('')
  const [dateSelected, setDateSelected] = useState('');
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [itemCategorySelected, setItemcategorySelected] = useState([])
  const [itemCategoryDropdownList, setItemCategoryDropdownList] = useState([]);
  const [allAccessesItemcategory, setAllAccessesItemcategory] = useState([]);
  const [plantSelected, setPlantSelected] = useState([])
  const [plantDropdownList, setPlantDropdownList] = useState([]);
  const [allAccessesPlant, setAllAccessesPlant] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))

  const handleCallbackDataFunction = (receivedData, place = '', callBackLogicFunction = '') => {
    if (place === 'error') {
      callBackLogicFunction(receivedData, place)
    }
    else {
      callBackLogicFunction(receivedData, place)
    }
  }


  const handleItemCategorySelection = (e) => {
    const { value } = e.target;
    const filterValue = value.filter(d => d !== 'ALL')
    // console.log(value, filterValue)
    if (filterValue.includes("")) {
      setItemcategorySelected([])
    }
    else {
      setItemcategorySelected(typeof filterValue === "string" ? filterValue.split(",") : filterValue)
    }
  };
  const handlePlantSelection = (e) => {
    const { value } = e.target;
    const filterValue = value.filter(d => d !== 'ALL')
    // console.log(value, filterValue)
    if (filterValue.includes("")) {
      setPlantSelected([])
    }
    else {
      setPlantSelected(typeof filterValue === "string" ? filterValue.split(",") : filterValue)
    }
  };

  const handleSetDateTitle = (label, date = '') => {
    setTitle(
      label === 'T' ? `${startDateOFToday}`
        : label === 'W' ? `Week ${moment(startOFWeek).format('DD-MMM')} to ${moment(endOFWeek).format('DD-MMM')}`
          : label === 'M' ? `Month ${moment(startOFMonth).format('MMM YYYY')}`
            : label === 'Y' ? `Financial Year ${moment(startDateOFYear).year()}-${moment(endDateOFYear).year()}`
              : label === 'date_range' ? `Date ${moment(date?.startDate).format('DD-MMM\'YY')} to ${moment(date?.endDate).format('DD-MMM\'YY')}`
                : ''
    )
  }

  const handleDateChange = (date, label, callBackFunction) => {
    setDateSelected(label)
    handleSetDateTitle(label, date)
    callBackFunction({
      label: label,
      startDate: label === 'T' ? startDateOFToday : label === 'W' ? startOFWeek : label === 'M' ? startOFMonth : label === 'Y' ? startDateOFYear : label === 'date_range' ? moment(date?.startDate).format('YYYY-MM-DD') : startDateOFToday,
      endDate: label === 'T' ? endDateOFToday : label === 'W' ? endOFWeek : label === 'M' ? endOFMonth : label === 'Y' ? endDateOFYear : label === 'date_range' ? moment(date?.endDate).format('YYYY-MM-DD') : endDateOFToday
    })
    if (label === 'date_range') {
      // console.log(date)
      setDateRange(date)
      setOpen(!open)
    }
    else {
      setDateRange(null)
    }
  }

  useEffect(() => {
    if (props?.dateButtons) {
      // console.log("herere")
      setDateSelected(props?.selectedDate)
      handleSetDateTitle(props?.selectedDate, props?.selectedDate === 'date_range' ? dateRange : "")
    }
  }, [props.dateButtons])

  useEffect(() => {
    if (props?.itemCategoryFilter) {
      setItemCategoryDropdownList(props?.itemCategoryDropdownList)
      const AllItemCategory = props?.itemCategoryDropdownList.filter(d => hasReadAccess(user, `${d.item_category_code}-dashboard`))
      setAllAccessesItemcategory(AllItemCategory)
      setItemcategorySelected([])
    }
    if (props?.plantFilter) {
      setPlantDropdownList(props?.plantDropdownList)
      const AllPlant = props?.plantDropdownList.filter(d => hasReadAccess(user, `${d.plant_code}-dashboard`))
      setAllAccessesPlant(AllPlant)
      setPlantSelected([])
    }
  }, [props.plantDropdownList, props.itemCategoryDropdownList])

  useEffect(() => {
    if (props?.itemCategoryFilter) {
      let itemIDList = [];
      if (itemCategorySelected.length === 0) {
        allAccessesItemcategory.map(d => itemIDList.push(d.id))
      } else {
        itemCategorySelected.map(d => itemCategoryDropdownList?.find(h => h.category_name === d && itemIDList.push(h.id)))
      }
      props?.handleFilterSelection(itemIDList, 'item_category')
    }
  }, [itemCategorySelected])

  useEffect(() => {
    if (props?.plantFilter) {
      let plantIDList = [];
      if (plantSelected.length === 0) {
        allAccessesPlant.map(d => plantIDList.push(d.id))
      } else {
        plantSelected.map(d => plantDropdownList?.find(h => h.plant_short_name === d && plantIDList.push(h.id)))
      }
      props?.handleFilterSelection(plantIDList, 'plant')
    }
  }, [plantSelected])

  const toggle = () => setOpen(!open);
  const {
    widgetTitle,
    widgetSubtitle,
    isMobile,
    plantFilter,
    itemCategoryFilter,
    dateButtons,
  } = props;

  return (
    <>
      <div style={{ paddingLeft: 30, paddingTop: 40, paddingRight: 30, paddingBottom: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: isMobile && 'column' }}>
          <div style={{ minWidth: 'fit-content' }}>
            <div style={{ fontSize: pxToEm(20), fontFamily: 'Poppins', }}>
              {widgetTitle ?? ''}
            </div>
            <div style={{ fontSize: pxToEm(12), color: "#707070", fontFamily: 'Poppins', }}>
              {widgetSubtitle ?? ''}
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: !isMobile && 'flex-start', flexWrap: 'wrap', flexDirection: isMobile && 'column', marginTop: isMobile && 30 }}>
              <div>
                {plantFilter
                  ? <FormControl style={styles.SelectContainer(isMobile)} size="small">
                    <InputLabel
                      id="plant-filter-label"
                      style={{ fontFamily: 'Poppins', paddingTop: 8, }}
                    >
                      Plant
                    </InputLabel>
                    <Select
                      multiple
                      value={plantSelected?.length === 0 ? ["ALL"] : plantSelected}
                      onChange={handlePlantSelection}
                      input={<OutlinedInput label="Plant" />}
                      SelectDisplayProps={{ style: { paddingBottom: 11, paddingTop: 12 } }}
                      labelId="plant-filter-label"
                      id="plant-filter"
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>All</em>;
                        }
                        return selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                      style={{ fontFamily: 'Poppins', }}
                    >
                      <MenuItem key="ALL" value="">
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={plantSelected?.length === 0}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontFamily: 'Poppins',
                            },
                          }}
                          primary="ALL"
                          style={styles.dropdownText}
                        />
                      </MenuItem>
                      {plantDropdownList ?
                        plantDropdownList.map((p) => (
                          <MenuItem
                            key={p.plant_short_name}
                            value={p.plant_short_name}
                            disabled={!hasReadAccess(user, `${p.plant_code}-dashboard`)}
                          >
                            <Checkbox
                              style={{ borderColor: 'gray', color: '#1976d2' }}
                              checked={plantSelected?.indexOf(p.plant_short_name) > -1}
                            />
                            <ToolTipComponent
                              title="You have no read permission"
                              condition={!hasReadAccess(user, `${p.plant_code}-dashboard`)}
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Poppins',
                                  },
                                }}
                                primary={p.plant_short_name}
                                style={styles.dropdownText}
                              />
                            </ToolTipComponent>
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                  </FormControl>
                  : null}
              </div>
              <div>
                {itemCategoryFilter
                  ? <FormControl style={styles.SelectContainer(isMobile)} size="small">
                    <InputLabel
                      id="item-category-filter-label"
                      style={{ fontFamily: 'Poppins', paddingTop: 8, }}
                    >
                      Item Categories
                    </InputLabel>
                    <Select
                      multiple
                      value={itemCategorySelected?.length === 0 ? ["ALL"] : itemCategorySelected}
                      onChange={handleItemCategorySelection}
                      input={<OutlinedInput label="Item Categories" />}
                      SelectDisplayProps={{ style: { paddingBottom: 11, paddingTop: 12 } }}
                      labelId="item-category-filter-label"
                      id="item-category-filter"
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>All</em>;
                        }
                        return selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                      style={{
                        fontFamily: 'Poppins',
                      }}
                    >
                      <MenuItem key="ALL" value="">
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={itemCategorySelected?.length === 0}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontFamily: 'Poppins',
                            },
                          }}
                          primary="ALL"
                          style={styles.dropdownText}
                        />
                      </MenuItem>
                      {itemCategoryDropdownList ?
                        itemCategoryDropdownList.map((category) => (
                          //<ToolTipComponent
                          //  title="You have no read permission"
                          //  condition={!hasReadAccess(user, `${category.item_category_code}-dashboard`)}
                          //>
                          <MenuItem
                            key={category.category_name}
                            value={category.category_name}
                            disabled={!hasReadAccess(user, `${category.item_category_code}-dashboard`)}
                          >
                            <Checkbox
                              style={{ borderColor: 'gray', color: '#1976d2' }}
                              checked={itemCategorySelected?.indexOf(category.category_name) > -1}
                            />
                            <Tooltip title="You have no read permission">
                              <span>
                                <ListItemText
                                  primaryTypographyProps={{
                                    style: {
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      fontFamily: 'Poppins',
                                    },
                                  }}
                                  primary={category.category_name}
                                  style={styles.dropdownText}
                                />
                              </span>
                            </Tooltip>
                          </MenuItem>
                          //</ToolTipComponent>
                        ))
                        : null}
                    </Select>
                  </FormControl>
                  : null}
              </div>
              <div>
                {dateButtons
                  ? <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      '& > *': {
                        m: 1,
                      },
                    }}
                  >
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                      color="inherit"
                    >
                      {dateButtons && dateButtons.map(D => {
                        return (
                          <Button
                            key={D?.label}
                            style={styles.dateButtonsGroup(dateSelected, D?.label)}
                            onClick={(e) => {
                              D?.label !== 'date_range'
                                ? handleDateChange(e, D?.label, D?.function)
                                : setOpen(!open)
                            }}
                          >
                            {D?.label !== 'date_range' ? D?.label : <DateRangeIcon color="grey" />}
                          </Button>
                        )
                      })}
                    </ButtonGroup>
                    <MuiDateRangePicker
                      open={open}
                      toggle={toggle}
                      dateRange={dateRange}
                      onDateRangeChange={(e) => handleDateChange(e, dateButtons[dateButtons.length - 1].label, dateButtons[dateButtons.length - 1].function)}
                    />
                    <div style={{ width: '100%', textAlign: 'end', paddingRight: 10, fontSize: pxToEm(10), color: "grey", float: "right", margin: 0 }}>{title}</div>
                  </Box>
                  : null}
              </div>
              {props.pdfCsvButton &&
                <PDFCSV />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ToggleButton = (props) => {
  const classes = useStyles();
  const [toggleIndex, setToggleIndex] = useState(0);

  const handleClickToggle = (index, callbackToggleFunction, key) => {
    setToggleIndex(index);
    callbackToggleFunction(index, key)
  }

  const {
    toggleField,
    pdfCsvButton,
    handlePdfClick,
    handleCsvClick,
    pdfData,
    pdfHeader,
    csvData,
    csvHeader,
    csvLoader,
    pdfloader,
    extraPdfData,
    module,
    mobileCSVFilename,
    webCSVFilename,
    onClickApiCall = false,
    refCSVLink,
    snackbarOpen,
  } = props

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: module === 'contract_booking' ? 0 : '5px 30px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: module === 'contract_booking' ? '10px' : 0,
          '& > *': {
            m: 1,
          },
        }}
      >
        <ButtonGroup variant="outlined" aria-label="outlined button group" color="inherit" >
          {toggleField && toggleField.map((t, i) => {
            return (
              <Button
                key={t?.label}
                style={{
                  width: 90,
                  height: 35,
                  backgroundColor: toggleIndex === i
                    ? "#293C79"
                    : "white"
                }}
                onClick={() => handleClickToggle(i, t?.function, t?.key)}
              >
                <IconButton
                  style={{
                    color: toggleIndex === i ? "white" : "grey",
                    fontSize: pxToEm(14)
                  }}
                >
                  {t?.label}
                </IconButton>
              </Button>
            )
          })}
        </ButtonGroup>
      </Box>
      {pdfCsvButton
        ? <div>
          <PDFCSV
            handlePdfClick={handlePdfClick}
            handleCsvClick={handleCsvClick}
            pdfData={pdfData}
            pdfHeader={pdfHeader}
            csvData={csvData}
            csvHeader={csvHeader}
            csvLoader={csvLoader}
            pdfloader={pdfloader}
            extraPdfData={extraPdfData}
            module={module}
            mobileCSVFilename={mobileCSVFilename}
            webCSVFilename={webCSVFilename}
            onClickApiCall={onClickApiCall}
            refCSVLink={refCSVLink}
            snackbarOpen={snackbarOpen}
          />
        </div>
        : null}
    </div>
  )
}

const DropdownContainer = (props) => {
  const classes = useStyles();
  const [seletedDropdown, setSeletedDropdown] = useState(null);

  useEffect(() => {
    if (props?.graphDropdownList) {
      setSeletedDropdown(props?.graphDropdownList[0])
    }
  }, [])

  const { graphDropdownList, isMobile } = props
  return (
    <>
      <br />
      <Divider variant="fullWidth" />
      <div>
        <div style={{ minWidth: 'fit-content' }}>
          <div style={{ paddingLeft: 30 }}>
            {graphDropdownList
              ? <Autocomplete
                value={seletedDropdown}
                disableClearable
                style={styles.dropdownContainer(isMobile)}
                options={graphDropdownList}
                getOptionLabel={(option) => option?.label}
                onChange={(e, value) => {
                  setSeletedDropdown(value)
                  props?.handlDropdownSelection(value)
                }
                }
                ListboxProps={{ style: { fontFamily: 'Poppins', } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="standard"
                    className={classes.noCaret}
                    InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontFamily: 'Poppins', } }}
                  />
                )}
              />
              : null}
          </div>
        </div>
      </div>
    </>
  )
}

const LineChartTable = (props) => {
  const classes = useStyles();
  const [financialData, setFinancialData] = useState([]);
  const [hasLineChartData, setHasLineChartData] = useState(false)
  const [lineChartData, setLineChartData] = useState([]);
  const [masterLineChartData, setMasterLineChartData] = useState([]);
  const [lineColor, setLineColor] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [color, setColor] = useState([])
  const [selectedGraphRow, setSelectedGraphRow] = useState([]);


  const handleGraphTableData = (data, graphType) => {
    // console.log(data, graphType)
    const _financialData = [];
    setHasLineChartData(Object.keys(data)?.length > 0)
    let LinesKey = Object.keys(data)
    let _colors = []
    for (let i = 0; i < LinesKey.length; i++) {
      _colors.push({ name: LinesKey[i], color: props?.singleRowSelection ? "#57A0E5" : generateRandomColor(), isSelected: false })
    }
    setColor(_colors)
    if (['today', 'T'].includes(graphType)) {
      let _datakeys = [];
      const numberOfDays = 1
      const ky = moment(new Date()).format("DD/MM");
      _datakeys.push(ky)
      // const yr = moment(endOFMonth).year();
      // const mnth = moment(endOFMonth).month();
      for (const k in data) {
        let graphRow = { name: k };
        let graphTotal = 0;

        for (let i = 1; i <= numberOfDays; i++) {
          const dt = moment(new Date()).format("YYYY-MM-DD");
          const ky = moment(new Date()).format("DD/MM");
          const dayData = Number(Number(data[k][dt] ?? 0).toFixed(3));
          graphTotal += Number(Number(data[k][dt] ?? 0).toFixed(3));
          graphRow = { ...graphRow, [ky]: dayData }
        }
        graphRow = { ...graphRow, total: Number((Number(graphTotal)).toFixed(3)) };
        _financialData.push(graphRow);
        // console.log(_financialData)
      }
      setDataKeys(_datakeys);
    } else if (['week', 'W'].includes(graphType)) {
      setDataKeys(weekKeys)
      for (const k in data) {
        let graphRow = { name: k };
        let graphTotal = 0;
        for (var d = new Date(startOFWeek); d <= new Date(endOFWeek); d.setDate(d.getDate() + 1)) {
          const dayOfWeek = moment(d).format('dddd');
          const dow = moment(d).format('YYYY-MM-DD');
          const val = data[k][dow] ?? 0
          graphTotal += val;
          graphRow = { ...graphRow, [dayOfWeek]: val }
        }
        graphRow = { ...graphRow, total: Number((Number(graphTotal)).toFixed(3)) };
        _financialData.push(graphRow);
      }

    } else if (['month', 'M'].includes(graphType)) {
      let _datakeys = [];
      const numberOfDays = moment(endOFMonth).daysInMonth();
      const yr = moment(endOFMonth).year();
      const mnth = moment(endOFMonth).month();
      for (let i = 1; i <= numberOfDays; i++) {
        const ky = moment(new Date(yr, mnth, i)).format("DD/MM");
        _datakeys.push(ky);
      }
      for (const k in data) {
        let graphRow = { name: k };
        let graphTotal = 0;

        for (let i = 1; i <= numberOfDays; i++) {
          const dt = moment(new Date(yr, mnth, i)).format("YYYY-MM-DD");
          const ky = moment(new Date(yr, mnth, i)).format("DD/MM");
          const dayData = Number(Number(data[k][dt] ?? 0).toFixed(3));
          graphTotal += Number(Number(data[k][dt] ?? 0).toFixed(3));
          graphRow = { ...graphRow, [ky]: dayData }
        }
        graphRow = { ...graphRow, total: Number((Number(graphTotal)).toFixed(3)) };
        _financialData.push(graphRow);
      }
      setDataKeys(_datakeys);
    } else if (['year', 'Y'].includes(graphType)) {
      setDataKeys(monthKeys)
      for (const k in data) {
        let graphRow = { name: k };
        let graphTotal = 0;
        for (let i = 1; i <= 12; i++) {
          const monthName = moment(`${i}`, 'M').format('MMMM');
          let total = 0;
          for (const d in data[k]) {
            if (moment(d).month() == i - 1) {
              total += data[k][d]
            }
          }
          const monthData = Number(Number(total).toFixed(3));
          graphTotal += monthData;
          graphRow = { ...graphRow, [monthName]: monthData }
        }
        graphRow = { ...graphRow, total: Number((Number(graphTotal)).toFixed(3)) };
        _financialData.push(graphRow);
      }
    }
    // console.log(graphType, _financialData)
    setFinancialData(_financialData)
  }

  const getYearlyData = () => {
    let chartData = [];
    let jan = { chartKey: 'Jan' };
    let feb = { chartKey: 'Feb' };
    let mar = { chartKey: 'Mar' };
    let apr = { chartKey: 'Apr' };
    let may = { chartKey: 'May' };
    let jun = { chartKey: 'Jun' };
    let jul = { chartKey: 'Jul' };
    let aug = { chartKey: 'Aug' };
    let sep = { chartKey: 'Sep' };
    let oct = { chartKey: 'Oct' };
    let nov = { chartKey: 'Nov' };
    let dec = { chartKey: 'Dec' };
    let _graphInfo = [];
    financialData.forEach((f, i) => {
      const selectedColor = color.find(c => c.name == f.name)?.color
      const _graphRow = { id: i, color: selectedColor }
      _graphInfo.push(_graphRow)
      jan = { ...jan, [i]: f.name, [f.name]: f.January }
      feb = { ...feb, [i]: f.name, [f.name]: f.February }
      mar = { ...mar, [i]: f.name, [f.name]: f.March }
      apr = { ...apr, [i]: f.name, [f.name]: f.April }
      may = { ...may, [i]: f.name, [f.name]: f.May }
      jun = { ...jun, [i]: f.name, [f.name]: f.June }
      jul = { ...jul, [i]: f.name, [f.name]: f.July }
      aug = { ...aug, [i]: f.name, [f.name]: f.August }
      sep = { ...sep, [i]: f.name, [f.name]: f.September }
      oct = { ...oct, [i]: f.name, [f.name]: f.October }
      nov = { ...nov, [i]: f.name, [f.name]: f.November }
      dec = { ...dec, [i]: f.name, [f.name]: f.December }
    })
    chartData.push(apr)
    chartData.push(may)
    chartData.push(jun)
    chartData.push(jul)
    chartData.push(aug)
    chartData.push(sep)
    chartData.push(oct)
    chartData.push(nov)
    chartData.push(dec)
    chartData.push(jan)
    chartData.push(feb)
    chartData.push(mar)
    setMasterLineChartData(chartData)
    setLineChartData(chartData)
    setLineColor(_graphInfo);
  }

  const getMonthlyData = () => {
    let chartDataKeys = dataKeys.map(d => ({ chartKey: d }))
    let chartData = []
    let _graphInfo = [];
    for (const c of chartDataKeys) {
      let _chartData = { ...c };
      financialData.forEach((f, i) => {
        _chartData = { ..._chartData, [i]: f.name, [f.name]: f[c["chartKey"]] }
      })
      chartData.push(_chartData)
    }
    financialData.forEach((f, i) => {
      const selectedColor = color.find(c => c.name == f.name)?.color
      const _graphRow = { id: i, color: selectedColor }
      _graphInfo.push(_graphRow)
    })
    setMasterLineChartData(chartData)
    setLineChartData(chartData)
    setLineColor(_graphInfo);
  }

  const getWeeklyData = () => {
    // console.log("called getWeeklyData")
    let chartDataKeys = dataKeys.map(d => ({ chartKey: d }))
    let chartData = []
    let _graphInfo = [];

    for (const c of chartDataKeys) {
      let _chartData = { ...c };
      financialData.forEach((f, i) => {
        _chartData = { ..._chartData, [i]: f.name, [f.name]: f[c["chartKey"]] }
      })
      chartData.push(_chartData)
    }

    financialData.forEach((f, i) => {
      const selectedColor = color.find(c => c.name == f.name)?.color
      const _graphRow = { id: i, color: selectedColor }
      _graphInfo.push(_graphRow)
    })
    setMasterLineChartData(chartData)
    setLineChartData(chartData)
    setLineColor(_graphInfo);
  }

  const handleItemSelection = (name) => {
    const _color = [...color]
    const existingItem = _color.find(c => c.name == name);
    const isSelected = !existingItem.isSelected;
    existingItem.isSelected = isSelected
    let graphRow = [...selectedGraphRow]
    if (isSelected) {
      graphRow.push(name)
    } else {
      graphRow = graphRow.filter(p => p != name)
    }
    setSelectedGraphRow(graphRow);
    setColor(_color);
  }

  const checkIsItemSelected = (name) => {
    return color.find(c => c.name == name)?.isSelected
  }

  const showRoundIcon = (name) => {
    const existingColor = color.find(c => c.name == name)?.color;
    const isItemSelected = checkIsItemSelected(name);
    if (isItemSelected) {
      return <svg viewBox="0 0 20 20" width="20" height="20">
        <circle style={{ fill: "#FFFFFF", stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
      </svg>
    }
    if (existingColor) {
      return <svg viewBox="0 0 20 20" width="20" height="20">
        <circle style={{ fill: existingColor, stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
      </svg>
    } else {
      return <svg viewBox="0 0 20 20" width="20" height="20">
        <circle style={{ fill: "#FFFFFF", stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
      </svg>
    }
  }

  useEffect(() => {
    // console.log("here3", props?.graphApiData)
    if (props?.graphApiData)
      handleGraphTableData(props.graphApiData.data, props.graphApiData.graphType)
  }, [props.graphApiData])

  useEffect(() => {
    const selectedLineChartData = masterLineChartData.map(m => {
      const _data = { ...m }
      selectedGraphRow.forEach(p => delete _data[p])
      return _data
    })
    setLineChartData(selectedLineChartData)
  }, [selectedGraphRow])

  useEffect(() => {
    if (selectedDate) {
      // console.log(selectedDate)
      selectedDate === 'Y' ? getYearlyData()
        : selectedDate === 'M' ? getMonthlyData()
          : selectedDate === 'W' ? getWeeklyData()
            : selectedDate === 'T' && getWeeklyData()
    }
  }, [financialData])


  const {
    isMobile,
    selectedDate,
    graphDropdownList,
    handlDropdownSelection,
    dropdown = true
  } = props;

  return (
    <>
      {dropdown ?
        <DropdownContainer
          isMobile={isMobile}
          graphDropdownList={graphDropdownList}
          handlDropdownSelection={handlDropdownSelection}
        />
        : null}
      <div style={{ width: isMobile ? "94%" : "98%", margin: '0 10px' }}>
        <Paper style={{ borderRadius: "20px" }}>
          {/* {console.log(lineChartData, lineColor, selectedDate, hasLineChartData)} */}
          <LineSalesGraph
            data={lineChartData}
            lineColor={lineColor}
            yearSelected={selectedDate === 'Y'}
            monthSelected={selectedDate === 'M'}
            weekSelected={selectedDate === 'W'}
            todaySelected={selectedDate === 'T'}
            hasLineChartData={hasLineChartData}
            isMobile={isMobile}
          />
        </Paper>
      </div>
      {(hasLineChartData && selectedDate !== 'date_range' && financialData.length > 0) &&
        <div style={{ width: "100%", paddingTop: 20 }}>
          <TableContainer className={classes.root} style={{ width: isMobile ? "94%" : "98%", position: "relative", margin: '0 10px' }}>
            {financialData.length > 0 ?
              <Table
                className={classes.table}
                aria-label="simple table"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell className={classes.fixed} style={{ borderBottom: "none" }}></TableCell>
                    <TableCell align="right" style={{ borderBottom: "none", color: "#293C79" }} className={classes.sticky}>Total</TableCell>
                    {
                      dataKeys.map((d) => (<TableCell key={d} align="right" style={{ borderBottom: "none", width: "100px", color: "#293C79" }} className={classes.root}>{d}</TableCell>))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {financialData?.map((row) => (
                    <TableRow key={row.name} className={classes.root}>
                      <TableCell component="th" scope="row" className={classes.fixed} style={{ borderBottom: "none", display: "flex", alignItems: "center" }} onClick={() => handleItemSelection(row.name)}>
                        <span style={{ marginTop: "5px" }}>{showRoundIcon(row.name)}</span>
                        <span style={{ fontSize: pxToEm(18), marginLeft: "10px" }}>
                          {row.name}
                        </span>
                      </TableCell>
                      <TableCell align="right" style={{ borderBottom: row.name == "Fly Ash" ? "2px solid #808080" : "none" }} className={classes.sticky}>{row.total}</TableCell>
                      {
                        dataKeys.map((d) => (<TableCell align="right" style={{ borderBottom: row.name == "Fly Ash" ? "2px solid #808080" : "none", width: "100px" }} className={classes.root}>{row[d]}</TableCell>))
                      }
                    </TableRow>
                  ))}
                  <TableRow className={classes.root}>
                    <TableCell component="th" scope="row" className={classes.fixed}>

                    </TableCell>
                    <TableCell align="right" className={classes.sticky} style={{ borderTop: "2px solid grey", color: "#293C79" }}>{Number(financialData?.reduce((t, v) => t + v?.total, 0)).toFixed(3)}</TableCell>
                    {
                      dataKeys.map((d) => (<TableCell key={d} align="right" className={classes.root} style={{ borderTop: "2px solid grey", color: "#293C79", width: "100px" }}>{Number(financialData?.reduce((t, v) => t + v[d] ?? 0, 0)).toFixed(3)}</TableCell>))
                    }

                  </TableRow>
                </TableBody>
              </Table>
              : <></>}
          </TableContainer>
        </div>
      }
    </>
  )
}

const TableRowSkeleton = (props) => {
  const { skeletonName, colSpan, noOfRows } = props
  const classes = useStyles();
  return (
    [...Array(noOfRows || 2).keys()].map((components, i) => {
      return (
        <TableRow className={classes.root}>
          {[...Array((colSpan.length)).keys()].map((components, index) => {
            return (
              <TableCell
                style={{ padding: '6px 4px', }}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={'45%'}
                  height={25}
                  style={{ margin: colSpan[index]?.id == 'no_header' || colSpan[index]?.id2 === 'no_header' ? '0 0 auto 0' : '0 0 0 auto' }}
                />
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  )
}

const SimpleTable = (props) => {
  const classes = useStyles();
  const [tableRow, setTableRow] = useState([])
  const [selectedRowGraph, setSelectedRowGraph] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverRow, setHoverRow] = useState(null);

  const handleRowClick = (rowId) => {
    setSelectedRowGraph(rowId)
    props?.handlTableRowSelection(rowId)
  }

  useEffect(() => {
    if (props.rows) {
      setTableRow(props?.rows)
    }
  }, [props.rows])

  useEffect(() => {
    if (tableRow && tableRow.length > 0) {
      if (["dashboard_customer_broker", "dashboard_sales_person"].includes(props?.widget)) {
        setSelectedRowGraph(null)
        let firstRowData = tableRow[0]
        setSelectedRowGraph(firstRowData[props.tableData[0].id] ?? null)
        props?.handlTableRowSelection && props?.handlTableRowSelection(firstRowData[props.tableData[0].id] ?? null)
      }
    }
  }, [tableRow])

  const {
    isMobile,
    tableData,
    showProgress,
    backgroundOddEven,
    page,
    pageDetail,
    currentPageRows,
    noOfLoaderRows,
    widget,
  } = props;

  return (
    <>
      <TableContainer style={props?.tableContainerStyle || { width: "100%", paddingLeft: 30, paddingRight: 30 }}>
        <Table
          className={classes.table}
          aria-label="simple table"
          stickyHeader={props?.stickyHeader}
          style={{ minWidth: props?.minwidth || 'inherit', paddingRight: props?.minwidth ? 5 : 0 }}
        >
          <TableHead>
            <TableRow
              className={classes.root}
              style={{ verticalAlign: ['dashboard_live_status'].includes(widget) ? 'top' : 'inherit' }}
            >
              {tableData && tableData?.map((t, i) => {
                return (
                  <TableCell
                    key={t?.label}
                    align={t?.id === 'no_header' || t?.id2 === 'no_header' ? "left" : 'right'}
                    style={{
                      borderBottom: "none",
                      padding: '6px',
                      // cursor: "pointer",
                      color: "#293C79",
                      minWidth: t?.minWidth
                    }}
                    className={classes.root}
                  >
                    {t.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          {showProgress
            ? <TableRowSkeleton
              skeletonName='simpleTable'
              colSpan={tableData}
              noOfRows={noOfLoaderRows || 4}
            />
            : tableRow?.length > 0 ?
              <TableBody>
                {tableRow.map((row, i) => (
                  <TableRow
                    key={row}
                    style={{
                      backgroundColor: backgroundOddEven
                        ? (i % 2 === 0 && '#eeeeee')
                        : row?.colouredRow === true
                          ? '#eeeeee'
                          : 'inherit',
                      // backgroundColor: hoverRow === i && '#eee',
                      // borderradius: hoverRow === i && 15,
                      // fontWeight: hoverRow === i && 500
                    }}
                    onMouseEnter={(e) => setHoverRow(i)}
                  >
                    {tableData && tableData?.map((t, index) => {
                      return <TableCell
                        key={t?.key}
                        align={t?.id === 'no_header' || t?.id2 === 'no_header' ? "left" : 'right'}
                        style={{
                          borderBottom: row?.colouredRow === true
                            ? "0.5px solid #333"
                            : t?.id === 'no_header' || t?.id2 === 'no_header'
                              ? "none"
                              : t?.label !== '#' && tableRow?.length - 1 == i
                                ? "2px solid #808080"
                                : "none",
                          padding: '10px 4px',
                          fontWeight: row?.colouredRow === true ? 500 : 'inherit',
                          fontSize: row?.colouredRow === true && pxToEm(14),
                        }}
                        className={classes.root}
                      >
                        {["dashboard_customer_broker", "dashboard_sales_person"].includes(props?.widget)
                          ? (t?.id === 'no_header'
                            ? <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <span style={{ marginTop: "5px" }}>
                                {selectedRowGraph === row[tableData[0].id]
                                  ? <svg viewBox="0 0 20 20" width="20" height="20">
                                    <circle style={{ fill: "#57A0E5", stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
                                  </svg>
                                  : null}
                              </span>
                              <span
                                style={{
                                  fontSize: pxToEm(18),
                                  marginLeft: "10px",
                                  fontWeight: anchorEl === row[tableData[0].id] ? "500" : "400",
                                  color: anchorEl === row[tableData[0].id] ? "#3B69B0" : "#666",
                                  cursor: 'pointer'
                                }}
                                onMouseEnter={(event) => setAnchorEl(row[tableData[0].id])}
                                onMouseLeave={(event) => setAnchorEl(null)}
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleRowClick(row[tableData[0].id])
                                }}
                              >
                                {row[t.key]}
                              </span>
                            </div>
                            : t.label === 'Net Sales (Cr)'
                              ? Number((Number((row[t.key])) / 10000000) || 0)
                              : t?.label === '#'
                                ? page
                                  ? (currentPageRows === 10 || page > 1)
                                    ? `${(pageDetail.current_page * 10) - (10 - (i + 1))}`
                                    : `${i + 1}`
                                  : `${i + 1}.`
                                : row[t.key]
                          )
                          : props?.widget === 'dashboard_live_status'
                            ? t.key2 ? row[t.key] + " | " + Number(row[t.key2]).toFixed(3) : row[t.key]
                            : props?.widget === 'dashboard_item_category_sales_difference'
                              ? t?.id === 'no_header' ? row[t.key] : row[t.key] === null ? 'null' : parseFloat(row[t.key]).toFixed(2)
                              : t.label === 'Net Sales (Cr)'
                                ? Number((Number((row[t.key])) / 10000000) || 0)
                                : t?.label === '#'
                                  ? `${i + 1}.`
                                  : row[t.key]
                        }
                      </TableCell>
                    })}
                  </TableRow>
                ))}
                <TableRow>
                  {tableData && tableData?.map((t, i) => {
                    return <TableCell
                      key={t?.key}
                      align="right"
                      className={classes.root}
                      style={{
                        borderBottom: "none",
                        color: "#293C79",
                        paddingRight: "5px",
                        padding: '6px',
                        fontWeight: props?.stickyHeader && 500,
                        bottom: 0,
                        zIndex: 2,
                        position: 'sticky',
                        backgroundColor: props?.stickyHeader && '#fafafa'
                      }}
                    >
                      {t?.id === 'no_header' || t?.id2 === 'no_header'
                        ? ''
                        : t.label === 'Net Sales (Cr)'
                          ? Number(((Number(Object.values(tableRow).reduce((r, v) => r + v[t.key] ?? 0, 0)).toFixed(3)) / 10000000) || 0)
                          : t?.label === '#'
                            ? ''
                            : props?.widget === 'dashboard_live_status'
                              ? Number(Object.values(tableRow.filter(d => !d?.colouredRow)).reduce((r, v) => r + v[t.key] ?? 0, 0)).toFixed(0) + " | " +
                              Number(Object.values(tableRow.filter(d => !d?.colouredRow)).reduce((r, v) => r + v[t.key2] ?? 0, 0)).toFixed(3)
                              : props?.widget === 'dashboard_item_category_sales_difference'
                                ? Number(Object.values(tableRow).reduce((r, v) => r + v[t.key] ?? 0, 0)).toFixed(2)
                                : Number(Object.values(tableRow).reduce((r, v) => r + v[t.key] ?? 0, 0)).toFixed(3)
                      }
                    </TableCell>
                  })}
                </TableRow>
              </TableBody>
              : <TableRow>
                <TableCell colSpan={tableData.length} className={classes.root} style={{ textAlign: 'center', padding: '20px 0' }}>
                  No Data Found
                </TableCell>
              </TableRow>}
        </Table>
      </TableContainer>
    </>
  )
}

const BottomPagination = (props) => {

  const handleChangePage = (e, newPage) => {
    props?.pageChangeCallBack(newPage)
  }

  const theme = useTheme()
  const { pageDetail, page, currentPageRows } = props;
  // console.log(pageDetail)

  const handleFirstPageButtonClick = (event) => {
    handleChangePage(event, 1);
  };

  const handleBackButtonClick = (event) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    handleChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    handleChangePage(event, pageDetail.total_pages);
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: theme.palette.text.secondary,
    }}>
      <Typography style={{ marginRight: 4, fontSize: '14px', color: '#666', }}>
        {(currentPageRows === 10 || page > 1)
          ? page !== pageDetail.total_pages
            ? `${(pageDetail.current_page * 10) - 9}-${pageDetail.current_page * 10}`
            : `${(pageDetail.current_page * 10) - 9}-${((pageDetail.current_page * 10) - 10) + currentPageRows}`
          : `${1}-${currentPageRows} of ${currentPageRows}`}
      </Typography>
      <Typography style={{ marginRight: 10, fontSize: '14px', color: '#666', }}>
        {' Of ' + (pageDetail?.count || '')}
      </Typography>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 1}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1 || pageDetail?.previous_page === null}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={pageDetail?.next_page === null}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= pageDetail?.total_pages}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}


export {
  PDFCSV,
  WidgetTop,
  ToggleButton,
  DropdownContainer,
  LineChartTable,
  SimpleTable,
  BottomPagination,
}