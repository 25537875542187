// -----Web Import ------

import EinvoicingAndEwayBillingWeb from '../eInvoicingAndEwayBilling/web';
import EinvoicingCreateWeb from './web/EinvoicingCreate';
import EinvoicingDownloadInvoiceWeb from './web/EInvoicingDownloadInvoice';

// ----- Mobile Import ----

import EinvoicingAndEwayBillingMobile from '../eInvoicingAndEwayBilling/mobile';
import EinvoicingCreate from './mobile/EinvoicingCreate';
import EinvoicingDownloadInvoice from './mobile/EInvoicingDownloadInvoice';

export default EinvoicingAndEwayBillingWeb;
export {
  EinvoicingAndEwayBillingMobile,
  EinvoicingCreate,
  EinvoicingCreateWeb,
  EinvoicingDownloadInvoiceWeb,
  EinvoicingDownloadInvoice,
};
