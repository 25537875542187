import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JsBarcode from "jsbarcode";
import moment from "moment";
import {
  primaryColor,
  drawerTextColor,
} from "../../../constants/internal/colors";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const eWayBillPdfStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    borderRadius: "30px",
  },
});

class RIPLEwayBillPdfDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laDetailsInDispatchCounter: {},
      total_invoice_value: 0.0,
      total_taxable_value: 0.0,
      total_value: 0.0,
      total_cess_value: 0.0,
      total_cgst_value: 0.0,
      total_sgst_value: 0.0,
      total_igst_value: 0.0,
      total_cess_non_advol_value: 0.0,
      total_freight_advance_amount: 0.0,
      total_quantity: 0.0,
      total_tcs_amount: 0.0,
      total_freight_amount: 0.0,
      eInvoiceResponse: null,
      eWayBillResponse: null,
      transporterDistance: 0.0,
    };
  }

  componentDidMount() {
    this.setState({
      laDetailsInDispatchCounter: this.props.laDetailsInDispatchCounter,
      total_invoice_value: this.props.total_invoice_value,
      total_taxable_value: this.props.total_taxable_value,
      total_value: this.props.total_value,
      total_cess_value: this.props.total_cess_value,
      total_cgst_value: this.props.total_cgst_value,
      total_sgst_value: this.props.total_sgst_value,
      total_igst_value: this.props.total_igst_value,
      eWayBillResponse: this.props.eWayBillResponse,
      total_cess_non_advol_value: this.props.total_cess_non_advol_value,
      total_freight_advance_amount: this.props.total_freight_advance_amount,
      total_freight_amount: this.props.total_freight_amount,
      total_quantity: this.props.total_quantity,
      total_tcs_amount: this.props.total_tcs_amount,
      transporterDistance: this.props.transporterDistance,
      eInvoiceResponse: this.props.eInvoiceResponse,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.laDetailsInDispatchCounter !== state.laDetailsInDispatchCounter) {
      return { laDetailsInDispatchCounter: props.laDetailsInDispatchCounter };
    }
    if (props.total_invoice_value !== state.total_invoice_value) {
      return { total_invoice_value: props.total_invoice_value };
    }
    if (props.total_taxable_value !== state.total_taxable_value) {
      return { total_taxable_value: props.total_taxable_value };
    }
    if (props.total_value !== state.total_value) {
      return { total_value: props.total_value };
    }
    if (props.total_cess_value !== state.total_cess_value) {
      return { total_cess_value: props.total_cess_value };
    }
    if (props.total_cgst_value !== state.total_cgst_value) {
      return { total_cgst_value: props.total_cgst_value };
    }
    if (props.total_sgst_value !== state.total_sgst_value) {
      return { total_sgst_value: props.total_sgst_value };
    }
    if (props.total_igst_value !== state.total_igst_value) {
      return { total_igst_value: props.total_igst_value };
    }
    if (props.eWayBillResponse !== state.eWayBillResponse) {
      return { eWayBillResponse: props.eWayBillResponse };
    }
    if (props.total_cess_non_advol_value !== state.total_cess_non_advol_value) {
      return { total_cess_non_advol_value: props.total_cess_non_advol_value };
    }
    if (
      props.total_freight_advance_amount !== state.total_freight_advance_amount
    ) {
      return {
        total_freight_advance_amount: props.total_freight_advance_amount,
      };
    }
    if (props.total_freight_amount !== state.total_freight_amount) {
      return { total_freight_amount: props.total_freight_amount };
    }
    if (props.total_quantity !== state.total_quantity) {
      return { total_quantity: props.total_quantity };
    }
    if (props.total_tcs_amount !== state.total_tcs_amount) {
      return { total_tcs_amount: props.total_tcs_amount };
    }
    if (props.transporterDistance !== state.transporterDistance) {
      return { transporterDistance: props.transporterDistance };
    }
    if (props.eInvoiceResponse !== state.eInvoiceResponse) {
      return { eInvoiceResponse: props.eInvoiceResponse };
    }

    return null;
  }
  componentDidUpdate(prevProps) {
    // console.log("here")
    if (!this.props.invoiceDownloadLoader && !this.state.isPDFMailed) {
      console.log("eway")
      this.handleViewOrDownloadEwayBillPdf("EMAIL")
      this.setState({
        isPDFMailed: true
      })
    }

  }
  textToBarCodeBase64 = (text) => {
    let canvas = document.createElement("canvas");
    JsBarcode(canvas, text, { format: "CODE39", scale: 5 });
    return canvas.toDataURL("image/png");
  };
  handleViewOrDownloadEwayBillPdf = (pdfOperation) => {
    let wayBillItemTable = [];
    wayBillItemTable.push([
      { text: "HSN Code", style: "tableHeader" },
      { text: "Product Name & Desc.", style: "tableHeader" },
      { text: "Quantity", style: "tableHeader", alignment: "center" },
      {
        text: "Taxable Amount Rs.",
        style: "tableHeader",
        alignment: "right",
      },
      {
        text: "Tax Rate (C+S+I+Cess+Cess Non.Advol)",
        style: "tableHeader",
        alignment: "right",
      },
    ]);
    this.state.laDetailsInDispatchCounter.loading_advice_items.map(
      (itemDetails, index) => {
        console.log(
          this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes?.find(
            (o) => o.tax_type === "IGST"
          )?.tax_percent === undefined
            ? "yes"
            : "no"
        );
        wayBillItemTable.push([
          {
            text:
              this.state.laDetailsInDispatchCounter?.item_category?.hsn_code ||
              "",
            style: "tableDetailValues",
          },
          {
            text: itemDetails?.item?.item_name || "",
            style: "tableDetailValues",
          },
          {
            text: `${Number.isNaN(
              Number(
                this.state.laDetailsInDispatchCounter?.loading_advice_items[
                  index
                ]?.alloted_weight
              )
            )
              ? 0
              : Number(
                this.state.laDetailsInDispatchCounter?.loading_advice_items[
                  index
                ]?.alloted_weight
              )
              }`,
            //  `${parseFloat(itemDetails?.QUANTITY || 0).toFixed(3)} ${
            //   itemDetails?.QTYUNIT || ""
            // }`,
            style: "tableDetailValues",
            alignment: "center",
          },
          {
            text: itemDetails?.item_taxable_amount,
            // parseFloat(itemDetails?.TAXABLEVALUE || 0).toFixed(2),
            style: "tableDetailValues",
            alignment: "right",
          },
          {
            text: `${Number(itemDetails?.sgst_amount) >
              Number(itemDetails?.igst_amount)
              ? this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes.find(
                (o) => o.tax_type === "SGST"
              )?.tax_percent +
              "+" +
              this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes.find(
                (o) => o.tax_type === "CGST"
              )?.tax_percent +
              "+0.000"
              : "0.000+0.000+" +
              (this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes?.find(
                (o) => o.tax_type === "IGST"
              )?.tax_percent === undefined
                ? "0"
                : this?.state?.laDetailsInDispatchCounter?.loading_advice_taxes?.find(
                  (o) => o.tax_type === "IGST"
                )?.tax_percent) +
              itemDetails?.cess_amount
              }`,
            //     ? parseFloat(itemDetails?.SGSTRATE || 0).toFixed(3) +
            //       "+" +
            //       parseFloat(itemDetails?.CGSTRATE || 0).toFixed(3) +
            //       "+0.000"
            //     : "0.000+0.000+" +
            //       parseFloat(itemDetails?.IGSTRATE || 0).toFixed(3)
            // }+${parseFloat(itemDetails?.CESSAMOUNT1 || 0).toFixed(
            //   3
            // )}+${parseFloat(itemDetails?.CESSNONADVOLVALUE || 0).toFixed(3)}`,
            style: "tableDetailValues",
            alignment: "right",
          },
        ]);
        return wayBillItemTable;
      }
    );
    // console.log(this?.state?.laDetailsInDispatchCounter);
    // console.log(this?.state?.eWayBillResponse?.loading_advice_taxes);
    // console.log(this.props);
    var dd = {
      info: {
        title: `${this?.state?.eWayBillResponse?.EwbNo
          ? this?.state?.eWayBillResponse?.EwbNo
          : this?.state?.eWayBillResponse?.EWayBill
            ? this?.state?.eWayBillResponse?.EWayBill
            : ""
          }_${new Date().toISOString()}`,
        author: "RIPL",
        subject: "E-Way Bill Document",
        keywords: "E-Way Bill Document",
      },
      pageMargins: [20, 20, 20, 20],
      background: function (currentPage, pageSize) {

        return {
          // image: AegleBase64,
          // width: 200,
          text: "<-",
          absolutePosition: { x: 5, y: pageSize.height / 2 },
        }
      },
      content: [
        {
          columns: [
            {
              text: "Automated e-Way Bill",
              fontSize: "15",
              bold: true,
              width: "90%",
              alignment: "center",
            },
            !this?.state?.eWayBillResponse?.EwbNo ||
              !this?.state?.eWayBillResponse?.EwbDt
              ? !this?.state?.eWayBillResponse?.EWayBill ||
                !this?.state?.eWayBillResponse?.Date ||
                !this?.state?.laDetailsInDispatchCounter?.plant.plant_gst_number
                ? null
                : {
                  qr: `${this?.state?.eWayBillResponse?.EWayBill}/${this.state.laDetailsInDispatchCounter?.plant.plant_gst_number}/${this.state.eWayBillResponse.Date}`,
                  fit: "60",
                  width: "60",
                  height: "60",
                }
              : {
                qr: `${this?.state?.eWayBillResponse?.EwbNo}/${this.state.laDetailsInDispatchCounter?.plant
                  .plant_gst_number
                  }/${moment(
                    this.state.eWayBillResponse.EwbDt,
                    "YYYY-MM-DD h:mm:ss"
                  ).format("DD/MM/YYYY LTS")}`,
                fit: "60",
                width: "60",
                height: "60",
              },
          ],
          margin: [10, 10, 10, 10],
          alignment: "center",
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "1. E-Way Bill Details",
          style: "descriptionHeadingTitle",
        },
        {
          columns: [
            !this?.state?.eWayBillResponse?.EwbNo
              ? !this?.state?.eWayBillResponse?.EWayBill
                ? null
                : {
                  text: [
                    "eWay Bill No : ",
                    {
                      text: this?.state?.eWayBillResponse?.EWayBill,
                      bold: true,
                    },
                  ],
                  style: "detailsLabel",
                  width: "33%",
                }
              : {
                text: [
                  "eWay Bill No : ",
                  {
                    text: this?.state?.eWayBillResponse?.EwbNo,
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "33%",
              },
            !this?.state?.eWayBillResponse?.EwbDt
              ? !this?.state?.eWayBillResponse?.Date
                ? null
                : {
                  text: [
                    "Generated Date : ",
                    {
                      text: moment(this.state.eWayBillResponse.Date, [
                        "DD/MM/YYYY hh:mm:ss A",
                        "YYYY-MM-DD hh:mm:ss A",
                      ]).format("DD-MMM-YYYY LT"),
                      bold: true,
                    },
                  ],
                  style: "detailsLabel",
                  width: "33%",
                }
              : {
                text: [
                  "Generated Date : ",
                  {
                    text: moment(this.state.eWayBillResponse.EwbDt, [
                      "DD/MM/YYYY hh:mm:ss A",
                      "YYYY-MM-DD hh:mm:ss A",
                    ]).format("DD-MMM-YYYY LT"),
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "33%",
              },
            !this?.state?.eWayBillResponse?.EwbValidTill
              ? !this?.state?.eWayBillResponse?.ValidUpTo
                ? null
                : {
                  text: [
                    "Valid Upto : ",
                    {
                      text: moment(this.state.eWayBillResponse.ValidUpTo, [
                        "DD/MM/YYYY hh:mm:ss A",
                        "YYYY-MM-DD hh:mm:ss A",
                      ]).format("DD-MMM-YYYY LT"),
                      bold: true,
                    },
                  ],
                  style: "detailsLabel",
                  width: "33%",
                }
              : {
                text: [
                  "Valid Upto : ",
                  {
                    text: moment(this.state.eWayBillResponse.EwbValidTill, [
                      "DD/MM/YYYY hh:mm:ss A",
                      "YYYY-MM-DD hh:mm:ss A",
                    ]).format("DD-MMM-YYYY LT"),
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "33%",
              },
          ],
          margin: [10, 0, 5, 0],
        },
        {
          columns: [
            {
              text: [
                "Generated By : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.plant
                      .plant_gst_number || "",
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
            {
              text: [
                "Mode : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter
                      ?.mode_of_transport || "",
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
            {
              text: [
                "Approx Distance : ",
                {
                  text: !this?.state?.transporterDistance
                    ? ""
                    : `${this?.state?.transporterDistance || 0} KM`,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          columns: [
            {
              text: ["Type : ", { text: "Outward - Supply", bold: true }],
              style: "detailsLabel",
              width: "33%",
            },
            {
              text: [
                "Transaction type : ",
                {
                  text:
                    this.state.laDetailsInDispatchCounter?.tran_type === "SHP"
                      ? "Bill To - Ship To"
                      : "Regular",
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },

            {
              text: [
                "Document Details : ",
                {
                  text: `Tax Invoice - ${this?.state?.laDetailsInDispatchCounter
                    ?.erp_invoice_number || ""
                    } - ${moment(
                      this?.state?.eWayBillResponse?.Date ||
                      this?.state?.eWayBillResponse?.EwbDt,
                      ["DD/MM/YYYY", "YYYY-MM-DD"]
                    ).format("DD-MMM-YYYY") || ""
                    }`,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        !this?.state?.eInvoiceResponse?.Irn
          ? null
          : {
            columns: [
              {
                text: [
                  "IRN : ",
                  {
                    text: this?.state?.eInvoiceResponse?.Irn || "",
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "100%",
              },
            ],
            margin: [10, 5, 5, 0],
          },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "2. Address Details",
          style: "descriptionHeadingTitle",
        },
        {
          columns: [
            {
              columns: [
                {
                  text: [
                    "From\n",
                    {
                      text: `GSTIN : ${this?.state?.laDetailsInDispatchCounter?.plant
                        .plant_gst_number || ""
                        }\n${this?.state?.laDetailsInDispatchCounter?.plant
                          .plant_name || ""
                        }\n${this?.state?.laDetailsInDispatchCounter?.plant
                          .plant_address || ""
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
                {
                  text: [
                    "To\n",
                    {
                      text: `GSTIN : ${this?.state?.laDetailsInDispatchCounter?.buyer
                        ?.gstin_number || "URP"
                        }\n${this?.state?.laDetailsInDispatchCounter?.buyer
                          ?.first_name +
                        " " +
                        this?.state?.laDetailsInDispatchCounter?.buyer
                          ?.last_name || ""
                        }\n
                        ${this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.address ?
                          (
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.address
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.address + ", "
                              : "")
                          )
                          :
                          (
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.door_number
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.door_number + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.floor_number
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.floor_number + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.building_name
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.building_name + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.street
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.street + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.location
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.location + ", "
                              : "")
                          )
                        }
                        ${this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.address ?
                          (
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.city
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.city + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.state
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.state + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.country
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.country + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.pincode
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.customer_addresses?.pincode
                              : "")
                          )
                          :
                          (
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name + ", "
                              : "")
                            +
                            ((this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name &&
                              this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name)
                              ? ((this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name).trim().toUpperCase() !==
                                (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.district_name).trim().toUpperCase())
                                ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name + ", "
                                : ""
                              : this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name
                                ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.city_name + ", "
                                : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.state_name
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.state_name + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.pincode
                              ? this?.state?.laDetailsInDispatchCounter?.buyer?.gst_addresses?.pincode
                              : "")
                          )
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
              ],
            },
          ],
          margin: [10, 5, 10, 5],
        },

        {
          columns: [
            {
              columns: [
                {
                  text: [
                    ":: Dispatch From ::\n",
                    {
                      text: `${this?.state?.laDetailsInDispatchCounter?.plant
                        ?.plant_address || ""
                        }\n${
                        // this?.state?.laDetailsInDispatchCounter[0]?.SUPADD2 ||
                        ""
                        }\n${this?.state?.laDetailsInDispatchCounter?.plant
                          ?.plant_city || ""
                        },${this?.state?.laDetailsInDispatchCounter?.plant
                          ?.plant_gst_number || ""
                        } - ${this?.state?.laDetailsInDispatchCounter?.plant
                          ?.plant_pincode || ""
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
                {
                  text: [
                    ":: Ship To ::\n",
                    {
                      text: `
                      GSTIN : ${this?.state?.laDetailsInDispatchCounter?.consignee
                          ?.gstin_number || "URP"
                        }\n${this?.state?.laDetailsInDispatchCounter?.consignee
                          .first_name +
                        this?.state?.laDetailsInDispatchCounter?.consignee
                          ?.last_name || ""
                        }\n

                        ${this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.address ?
                          (
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.address
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.address + ", "
                              : "")
                          )
                          :
                          (
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.door_number
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.door_number + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.floor_number
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.floor_number + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.building_name
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.building_name + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.street
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.street + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.location
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.location + ", "
                              : "")
                          )
                        }
                        ${this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.address ?
                          (
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.city
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.city + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.state
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.state + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.country
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.country + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.pincode
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_customer_addresses?.pincode
                              : "")
                          )
                          :
                          (

                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name + ", "
                              : "")
                            +
                            ((this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name &&
                              this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name)
                              ? ((this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name).trim().toUpperCase() !==
                                (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.district_name).trim().toUpperCase())
                                ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name + ", "
                                : ""
                              : this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name
                                ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.city_name
                                : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.state_name
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.state_name + ", "
                              : "")
                            +
                            (this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.pincode
                              ? this?.state?.laDetailsInDispatchCounter?.consignee?.consignee_from_gst_information_addresses?.pincode
                              : "")
                          )
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
              ],
            },
          ],
          margin: [10, 5, 10, 5],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "3. Goods Details",
          style: "descriptionHeadingTitle",
        },
        {
          style: "table",
          widths: [50, 250, 100, 100, "*"],
          table: {
            headerRows: 1,
            body: wayBillItemTable,
          },
        },
        {
          columns: [
            {
              text: [
                "Tot. Taxable Amt : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.total_values
                      ?.taxable_amount || 0,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "CGST Amt : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.total_values
                      ?.cgst_amount || 0,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "SGST Amt : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.total_values
                      ?.sgst_amount || 0,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "IGST Amt : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.total_values
                      ?.igst_amount || 0,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          columns: [
            {
              text: [
                "CESS Amt : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.total_values
                      ?.cess_amount || 0,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "CESS Non.Advol Amt : ",
                {
                  // text: this?.state?.total_cess_non_advol_value || 0,
                  text: "0",
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "Other Amt : ",
                {
                  text: parseFloat(
                    this?.state?.laDetailsInDispatchCounter?.total_values
                      ?.tcs_amount || 0
                  ).toFixed(2),
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "Total Inv.Amt : ",
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.total_values
                      ?.total_item_value || 0,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          columns: [
            {
              text: [
                "Total Qty :  ",
                {
                  text:
                    Number(
                      this.state.laDetailsInDispatchCounter?.total_values
                        ?.alloted_weight
                    ) || 0,
                  bold: true,

                  // this?.state?.total_quantity || 0, bold: true
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: "",
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: "",
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: "",
              style: "detailsLabel",
              width: "25%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "4. Transportation Details",
          style: "descriptionHeadingTitle",
        },
        {
          columns: [
            {
              text: [
                "Transporter ID & Name : ",
                {
                  text: `${this?.state?.laDetailsInDispatchCounter?.transporter?.id ||
                    ""
                    } & ${this?.state?.laDetailsInDispatchCounter?.transporter_name ||
                    ""
                    }`,
                },
              ],
              style: "detailsLabel",
            },
            {
              text: [
                "LR No & LR Date : ",
                {
                  text: `${this?.state?.laDetailsInDispatchCounter?.lr_number
                    || ""
                    } & ${moment(
                      new Date(
                        this?.state?.laDetailsInDispatchCounter?.lr_date
                      )
                    ).format("DD-MMM-YYYY")}`,
                },
              ],
              style: "detailsLabel",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "5. Vehicle Details",
          style: "descriptionHeadingTitle",
          // margin:[10,10,10,10],
        },
        {
          style: "table",
          widths: [50, "*", 60, 140, 60, 18, 18],
          table: {
            headerRows: 1,
            body: [
              [
                { text: "Mode", style: "tableHeader" },
                { text: "Vehicle / LR No & Dt.", style: "tableHeader" },
                { text: "From", style: "tableHeader" },
                { text: "Entered Date", style: "tableHeader" },
                { text: "Entered By", style: "tableHeader" },
                { text: "CEWB No. (If any)", style: "tableHeader" },
                { text: "Multi Veh. Info (If any)", style: "tableHeader" },
              ],
              [
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter
                      ?.mode_of_transport || "",
                  style: "tableDetailValues",
                },
                {
                  text: `${this?.state?.laDetailsInDispatchCounter?.truck_number.toUpperCase() ||
                    ""
                    } & ${this?.state?.laDetailsInDispatchCounter?.lr_number || ""
                    } & ${moment(
                      new Date(
                        this?.state?.laDetailsInDispatchCounter?.lr_date
                      )
                    ).format("DD-MMM-YYYY")}`,
                  style: "tableDetailValues",
                },
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.plant
                      ?.plant_city || "",
                  style: "tableDetailValues",
                },
                {
                  text: !this?.state?.eWayBillResponse?.EwbDt
                    ? !this?.state?.eWayBillResponse?.Date
                      ? ""
                      : moment(this?.state?.eWayBillResponse?.Date, [
                        "DD/MM/YYYY hh:mm:ss A",
                        "YYYY-MM-DD hh:mm:ss A",
                      ]).format("DD-MMM-YYYY LT")
                    : moment(this.state.eWayBillResponse.EwbDt, [
                      "DD/MM/YYYY hh:mm:ss A",
                      "YYYY-MM-DD hh:mm:ss A",
                    ]).format("DD-MMM-YYYY LT"),
                  // style: "enteredDateDisplay",
                  style: "tableDetailValues",
                },
                {
                  text:
                    this?.state?.laDetailsInDispatchCounter?.plant
                      .plant_gst_number || "",
                  style: "tableDetailValues",
                },
                { text: "---", style: "tableDetailValues" },
                { text: "---", style: "tableDetailValues" },
              ],
            ],
          },
        },
        !this?.state?.eWayBillResponse?.EwbNo
          ? !this?.state?.eWayBillResponse?.EWayBill
            ? null
            : {
              image: this.textToBarCodeBase64(
                this?.state?.eWayBillResponse?.EWayBill || ""
              ),
              width: 120,
              height: 55,
              alignment: "center",
              margin: [10, 5, 10, 5],
            }
          : {
            image: this.textToBarCodeBase64(
              this?.state?.eWayBillResponse?.EwbNo || ""
            ),
            width: 120,
            height: 55,
            alignment: "center",
            margin: [10, 5, 10, 5],
          },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
      ],
      styles: {
        detailsTitleStyle: {
          fontSize: "12",
          bold: true,
        },
        table: {
          margin: [10, 5, 10, 5],
        },
        descriptionHeadingTitle: {
          fontSize: 10,
          bold: true,
          margin: [10, 5, 5, 5],
        },
        detailsLabel: {
          fontSize: 8,
        },
        tableHeader: {
          fontSize: 9,
          bold: true,
        },
        tableDetailValues: {
          fontSize: 9,
        },
        enteredDateDisplay: {
          fontSize: 6,
        },
      },
      defaultStyle: {
        columnGap: 10,
      },
    };
    // console.log(this.state)
    if (pdfOperation === "VIEW") {
      pdfMake.createPdf(dd).open();
    } else if (pdfOperation === "DOWNLOAD") {
      pdfMake
        .createPdf(dd)
        .download(
          `${this?.state?.eWayBillResponse?.EwbNo
            ? this?.state?.eWayBillResponse?.EwbNo
            : this?.state?.eWayBillResponse?.EWayBill
              ? this?.state?.eWayBillResponse?.EWayBill
              : ""
          }_${new Date().toISOString()}.pdf`
        );
    } else if (pdfOperation === "EMAIL") {
      const pdfDocGenerator = pdfMake.createPdf(dd);
      pdfDocGenerator.getBase64((document) => {
        //   var file = this.dataURLtoFile(document, `${new Date().toISOString()}.pdf`);
        //   console.log(file);
        //   file.open()
        this.props.pdfpayload(dd, document, "eway")
        // console.log(url)
      });
    } else {
      console.log("invalid pdf operation");
    }
  };
  render() {
    // console.log(
    //   "##############################################################################"
    // );
    // console.log(this.props.laDetailsInDispatchCounter);
    const { classes } = this.props;
    return (
      <Button
        className={classes.primaryBackground}
        sx={{ textTransform: "none" }}
        style={{
          width: this.props.pdfOperation === "VIEW" ? "30%" : "55%",
          height: "24px",
          backgroundColor: Boolean(this.props?.ewayError) && 'rgba(0,0,0,0.2)',
          color: Boolean(this.props?.ewayError) && 'rgba(0,0,0,0.2)',
        }}
        variant="outlined"
        disabled={Boolean(this.props?.ewayError)}
        size="small"
        // startIcon={<PictureAsPdfOutlinedIcon width="12px" height="12px" />}
        onClick={(evt) => {
          evt.preventDefault();
          if (!Boolean(this.props?.ewayError))
            this.handleViewOrDownloadEwayBillPdf(this.props.pdfOperation);
        }}
      >
        <span style={{ fontSize: "11.2px" }}>
          {this.props.pdfOperation}
        </span>

      </Button>
    );
  }
}
export default withStyles(eWayBillPdfStyles)(RIPLEwayBillPdfDownload);
