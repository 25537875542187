import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import DisplayItemCategoryData from "../../../../components/internal/masterManagement/ItemCategoryNewUi/web";
import ManageItemCategoryData from "../../../../components/internal/masterManagement/ItemCategoryNewUi/ManageItemCategoryRateTemplateTab";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { isAccessibleModule } from "../../../../methods";

class ManageItemCategoryDataContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      itemOperation: null,
      selectedItemId: null,
      selectedItemCategoryData: [],
      selectedItemCategoryDataFetchLoader: true,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      allRegions: [],
      allItemCategories: [],
    };
    // this.handleFetchSelectedItemData = this.handleFetchSelectedItemData.bind(
    //   this
    // );
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let itemOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ itemOperation }, () => console.log(1));
    if (itemOperation === "view" || itemOperation === "edit") {
      const id =
        this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
        ];
      this.handleFetchSelectedItemData(id);
      this.setState({ selectedItemId: id }, () => console.log(1));
    }
    this.fetchAllStates();
    this.fetchAllItemCatgories();
  }

  static getDerivedStateFromProps(props, state) {
    let propsItemOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let updatedData = new Map();
    if (propsItemOperation !== state.itemOperation) {
      updatedData.set("itemOperation", propsItemOperation);
    }
    if (propsItemOperation === "view" || propsItemOperation === "edit") {
      const propsSelectedItemId =
        props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ];
      if (propsSelectedItemId !== state.selectedItemId) {
        updatedData.set("selectedItemId", propsSelectedItemId);
      }
    }
    if (updatedData.size > 0) {
      return {
        itemOperation: updatedData.has("itemOperation")
          ? updatedData.get("itemOperation")
          : true,
        selectedItemId: updatedData.has("selectedItemId")
          ? updatedData.get("selectedItemId")
          : null,
      };
    } else {
      return null;
    }
  }
  // componentDidUpdate(nextProps, prevState) {
  //   let propsItemOperation = this.props.location.pathname.includes("add")
  //     ? "add"
  //     : this.props.location.pathname.includes("edit")
  //     ? "edit"
  //     : "view";
  //   if (propsItemOperation !== prevState.itemOperation) {
  //     return { itemOperation: propsItemOperation };
  //   }
  //   if (propsItemOperation === "view") {
  //     const propsSelectedItemId = this?.props?.location?.pathname?.split("/")[
  //       this?.props?.location?.pathname?.split("/").length - 1
  //     ];
  //     if (propsSelectedItemId !== prevState.selectedItemId) {
  //       this.handleFetchSelectedItemData(propsSelectedItemId);
  //       return { selectedItemId: propsSelectedItemId };
  //     }
  //   }
  // }
  handleFetchSelectedItemData = async (selectedItemId) => {
    // let selectedItemId = this?.state?.selectedItemId;
    await axios
      .get(
        `${APIROUTES.MM_CREATE_NEW_CATEGORY}${selectedItemId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState(
              {
                selectedItemCategoryData: response.data,
                selectedItemCategoryDataFetchLoader: false,
              },
              () => console.log(1)
            );
          } else {
            this.setState(
              {
                selectedItemCategoryDataFetchLoader: false,
                openSnackbar: true,
                snackbarMessage:
                  "Something went wrong while fetching data try again!",
                snackbarType: "warning",
              },
              () => console.log(1)
            );
          }
        },
        (error) => {
          this.setState(
            {
              selectedItemCategoryDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching data try again!",
              snackbarType: "error",
            },
            () => console.log(1)
          );
        }
      );
  };

  fetchLinkedItems = (id) => {
    return axios.get(
      `${APIROUTES.LINKED_ITEM}?item_category_id=${id}`,
      this.getOptions(this.props.user)
    );
  }

  createLinkedItems = (data) => {
    return axios.post(
      `${APIROUTES.LINKED_ITEM}`,
      data,
      this.getOptions(this.props.user)
    );
  }

  updateLinkedItems = (data, id) => {
    return axios.patch(
      `${APIROUTES.LINKED_ITEM_UPADTE_DELETE}${id}`,
      data,
      this.getOptions(this.props.user)
    );
  }

  deleteLinkedItems = (id) => {
    return axios.delete(
      `${APIROUTES.LINKED_ITEM_UPADTE_DELETE}${id}`,
      this.getOptions(this.props.user)
    );
  }

  fetchAllPaymentMethods = () => {
    return axios.get(
      `${APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD}?active=${true}`,
      this.getOptions(this.props.user)
    );
  };
  fetchAllCities = () => {
    return axios.get(
      `${APIROUTES.GET_CITY_LIST}?country_id=101`,
      this.getOptions(this.props.user)
    );
  };
  fetchItemCategoryPayemntTermData = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT}?id=${itemCategoryId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchItemCategoryCityData = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.ITEM_CATEGORY_CITY_DISCOUNT}?id=${itemCategoryId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchSelectedItemCategoryData = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.MM_CREATE_NEW_CATEGORY}${itemCategoryId}/`,
      this.getOptions(this.props.user)
    );
  };
  setRateLinkingData = (itemCategoryId, updatedData) => {
    return axios.put(
      `${APIROUTES.MM_CREATE_NEW_CATEGORY}${itemCategoryId}/`,
      updatedData,
      this.getOptions(this.props.user)
    );
  };
  deleteRateLinkingData = (itemCategoryId, updatedData) => {
    return axios.delete(
      `${APIROUTES.MM_DELETE_NEW_CATEGORY_RATE_LINKING}${itemCategoryId}/`,
      updatedData,
      this.getOptions(this.props.user)
    );
  };
  fetchDailyRateTemplate = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.DAILY_RATE_TEMPLATE_DETAILS}${itemCategoryId}/`,
      this.getOptions(this.props.user)
    );
  };
  saveRateTemplateData = (rateTemplateData) => {
    return axios.post(
      APIROUTES.DAILY_RATE_TEMPLATE_CREATE,
      rateTemplateData,
      this.getOptions(this.props.user)
    );
  };
  updateRateTemplateData = (updatedData, itemCategoryId) => {
    return axios.put(
      `${APIROUTES.DAILY_RATE_TEMPLATE_DETAILS}${itemCategoryId}/`,
      updatedData,
      this.getOptions(this.props.user)
    );
  };
  setBaseState = (updatedData, itemCategoryId) => {
    return axios.put(
      `${APIROUTES.DAILY_RATE_TEMPLATE_DETAILS}${itemCategoryId}/`,
      updatedData,
      this.getOptions(this.props.user)
    );
  };
  paymentTermDataSave = (paymentTermSaveData) => {
    return axios.post(
      APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT,
      paymentTermSaveData,
      this.getOptions(this.props.user)
    );
  };

  deletePaymentTerm = (paymentTermId) => {
    return axios.delete(
      `${APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT}${paymentTermId}/`,
      this.getOptions(this.props.user)
    );
  }

  deleteCityDiscount = (cityId) => {
    return axios.delete(
      `${APIROUTES.ITEM_CATEGORY_CITY_DISCOUNT_DELETE}${cityId}/`,
      this.getOptions(this.props.user)
    );
  }

  cityDataSave = (citySaveData) => {
    return axios.post(
      APIROUTES.ITEM_CATEGORY_CITY_DISCOUNT,
      citySaveData,
      this.getOptions(this.props.user)
    );
  };
  updateDiscountAmount = (data, updateIn, dataId) => {
    return axios.patch(
      updateIn === "city"
        ? `${APIROUTES.ITEM_CATEGORY_CITY_DISCOUNT}${dataId}/`
        : `${APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT}${dataId}/`,
      data,
      this.getOptions(this.props.user)
    );
  };
  fetchItemsData = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.ITEM_CATEGORY_MASTER_ITEMS}?id=${itemCategoryId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchAllTagsData = () => {
    return axios.get(
      APIROUTES.GET_ALL_TAGS_MASTERS_LIST,
      this.getOptions(this.props.user)
    );
  };
  fetchAllUnits = () => {
    return axios.get(APIROUTES.UNIT, this.getOptions(this.props.user));
  };
  fetchAllItemSize = (itemCategoryID) => {
    return axios.get(`${APIROUTES.GET_ITEM_CATEGORIES_ITEM_SIZE}?item_category=${itemCategoryID}`, this.getOptions(this.props.user));
  };
  fetchAllItemNature = () => {
    return axios.get(APIROUTES.ITEM_NATURE, this.getOptions(this.props.user));
  };

  fetchAllStates = () => {
    axios
      .get(APIROUTES.DAILY_RATES_STATE, this.getOptions(this.props.user))
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              allRegions: response.data,
            });
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching all states try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching all states try again!",
            snackbarType: "error",
          });
        }
      );
  };

  fetchAllItemCatgories = () => {
    axios
      .get(APIROUTES.DAILY_RATE_TEMPLATE_GET, this.getOptions(this.props.user))
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              allItemCategories: response.data,
            });
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching all item categories!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching all item categories!",
            snackbarType: "error",
          });
        }
      );
  };
  isValidAccess = () => {
    const { user } = this.props;
    if (!user) return false;
    else if (!user.is_admin) return false;
    else if (!user?.is_active) return false;
    else if (!user?.token) return false;
    else if (!user?.accessibleModules?.find((a) => a === "Masters"))
      return false;
    else return true;
  };
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  saveItemCategory = (itemCategoryData) => {
    return axios.post(
      APIROUTES.MM_CREATE_NEW_CATEGORY,
      itemCategoryData,
      this.getOptions(this.props.user)
    );
  };
  updateItemCategory = (itemCategoryData, itemCategoryId) => {
    return axios.put(
      `${APIROUTES.MM_CREATE_NEW_CATEGORY}${itemCategoryId}/`,
      itemCategoryData,
      this.getOptions(this.props.user)
    );
  };
  saveItemData = (itemData) => {
    return axios.post(
      APIROUTES.ITEM_CATEGORY_MASTER_ITEMS,
      itemData,
      this.getOptions(this.props.user)
    );
  };
  saveItemSectionData = (itemSectionData) => {
    return axios.post(
      APIROUTES.ITEM_CATEGORY_MASTER_ITEM_SECTION,
      itemSectionData,
      this.getOptions(this.props.user)
    );
  };
  updateItemData = (itemData, itemDataId) => {
    return axios.patch(
      `${APIROUTES.MM_ITEM_TYPES_POST}${itemDataId}/`,
      itemData,
      this.getOptions(this.props.user)
    );
  };
  updateItemSectionData = (sectionData) => {
    return axios.patch(
      `${APIROUTES.ITEM_CATEGORY_MASTER_ITEM_SECTION}${sectionData.id}/`,
      sectionData,
      this.getOptions(this.props.user)
    );
  };

  render() {
    const { itemOperation } = this.state;
    if (this?.props?.isMobile) {
      return <OpenInDesktop {...this.props} />;
    } else if (isAccessibleModule(this.props?.user, "Masters")) {
      return (
        <>
          {itemOperation === "view" ? (
            <DisplayItemCategoryData
              {...this.props}
              {...this.state}
              fetchAllPaymentMethods={this.fetchAllPaymentMethods}
              fetchSelectedItemCategoryData={this.fetchSelectedItemCategoryData}
              saveItemCategory={this.saveItemCategory}
              updateItemCategory={this.updateItemCategory}
              fetchDailyRateTemplate={this.fetchDailyRateTemplate}
              saveRateTemplateData={this.saveRateTemplateData}
              updateRateTemplateData={this.updateRateTemplateData}
              setBaseState={this.setBaseState}
              setRateLinkingData={this.setRateLinkingData}
              deleteRateLinkingData={this.deleteRateLinkingData}
              fetchItemCategoryPayemntTermData={
                this.fetchItemCategoryPayemntTermData
              }
              fetchItemCategoryCityData={this.fetchItemCategoryCityData}
              fetchAllCities={this.fetchAllCities}
              paymentTermDataSave={this.paymentTermDataSave}
              cityDataSave={this.cityDataSave}
              updateDiscountAmount={this.updateDiscountAmount}
              fetchItemsData={this.fetchItemsData}
              fetchAllTagsData={this.fetchAllTagsData}
              saveItemData={this.saveItemData}
              saveItemSectionData={this.saveItemSectionData}
              fetchAllUnits={this.fetchAllUnits}
              fetchAllItemSize={this.fetchAllItemSize}
              fetchAllItemNature={this.fetchAllItemNature}
              updateItemData={this.updateItemData}
              updateItemSectionData={this.updateItemSectionData}
              fetchAllItemCatgories={this.fetchAllItemCatgories}
              fetchLinkedItems={this.fetchLinkedItems}
              createLinkedItems={this.createLinkedItems}
              updateLinkedItems={this.updateLinkedItems}
              deleteLinkedItems={this.deleteLinkedItems}
              unitList={this.props.unitList}
              deletePaymentTerm={this.deletePaymentTerm}
              deleteCityDiscount={this.deleteCityDiscount}
            />
          ) : (
            <ManageItemCategoryData
              {...this.props}
              {...this.state}
              fetchAllPaymentMethods={this.fetchAllPaymentMethods}
              fetchSelectedItemCategoryData={this.fetchSelectedItemCategoryData}
              saveItemCategory={this.saveItemCategory}
              updateItemCategory={this.updateItemCategory}
              fetchDailyRateTemplate={this.fetchDailyRateTemplate}
              saveRateTemplateData={this.saveRateTemplateData}
              updateRateTemplateData={this.updateRateTemplateData}
              setBaseState={this.setBaseState}
              setRateLinkingData={this.setRateLinkingData}
              deleteRateLinkingData={this.deleteRateLinkingData}
              fetchItemCategoryPayemntTermData={
                this.fetchItemCategoryPayemntTermData
              }
              fetchItemCategoryCityData={this.fetchItemCategoryCityData}
              fetchAllCities={this.fetchAllCities}
              paymentTermDataSave={this.paymentTermDataSave}
              cityDataSave={this.cityDataSave}
              updateDiscountAmount={this.updateDiscountAmount}
              fetchItemsData={this.fetchItemsData}
              fetchAllTagsData={this.fetchAllTagsData}
              saveItemData={this.saveItemData}
              saveItemSectionData={this.saveItemSectionData}
              fetchAllUnits={this.fetchAllUnits}
              fetchAllItemSize={this.fetchAllItemSize}
              fetchAllItemNature={this.fetchAllItemNature}
              updateItemData={this.updateItemData}
              updateItemSectionData={this.updateItemSectionData}
              fetchAllItemCatgories={this.fetchAllItemCatgories}
              itemCategoriesList={this.props.itemCategoriesList}
              unitList={this.props.unitList}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    } else {
      return <AccessDenied />;
    }
  }
}
export default withUser(ManageItemCategoryDataContainer);
