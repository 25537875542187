import React, { Component } from "react";
import "../../../styles/Register.css";
import { withStyles } from "@material-ui/core/styles";

import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
  secondaryColor,
} from "../../../styles/colors";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as EmailValidator from "email-validator";
import Button from "@material-ui/core/Button";
import CircularProgressLoader from "../../common/CircularProgressLoader";
import { Avatar } from "@material-ui/core";
import { Countdown } from "../Countdown";
import B2b_URL from "../../../constants/b2b/B2bRoutes";
import axios from "axios";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { pxToEm } from "../../../methods";

const styles = {
  registerLoginCredentialsbody: {
    height: window.innerHeight - 56,
    overflow: "scroll",
  },
  loginCredentialText: {
    marginLeft: "15px",
    color: dailyRateScreenTextColor,
    // position: "absolute",
    bottom: "35px",
  },
  textFieldStyle: {
    width: "90%",
    backgroundColor: "white",
    fontSize: window.innerWidth < 375 && pxToEm(14)
  },
  infoText: {
    marginLeft: "15px",
    color: bottomNavigationFontColor,
    fontSize: window.innerWidth < 375 ? pxToEm(13) : "0.9em",

    // position: "absolute",
    // bottom: "16px",
  },
  infoTextSecond: {
    marginLeft: "15px",
    color: bottomNavigationFontColor,
    fontSize: window.innerWidth < 375 ? pxToEm(13) : "0.9em",

    // position: "absolute",
    // bottom: "2px",
  },
  InputAdornmentStyle: {
    color: secondaryColor,
    display: "flex",
    alignItems: "center",
    fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.2em",
  },
  InputAdornmentOtpStyle: {
    color: bottomNavigationFontColor,
    display: "flex",
    alignItems: "center",
    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1em",
  },
  avatar: {
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    // backgroundColor: "red",
    height: "30px",
    width: "30px",
    // opacity: 0.5,
    // marginTop: "10px",
  },
};

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
    fontSize: window.innerWidth < 375 && pxToEm(15)
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null

}
class RegisterLoginCredential extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showErrorMessageEmail: false,
      isPhoneVerified: false,
      isEmailVerified: false,
      email: "",
      emailOTP: "",
      contactNumber: "",
      phoneOTP: "",
      OTPSecretKey: "",
      OTPValidUntil: "",
      isInValidEmailOtp: false,
      isInValidPhoneOtp: false,
      invalidEmailOtpErrorMsg: '',
      invalidPhoneOtpErrorMsg: '',

    }
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.email !== this.props.email) && this.state.isEmailVerified) {
      this.setState({
        isEmailVerified: false,
        emailOTP: "",
      })
    }
    if ((prevProps.contactNumber !== this.props.contactNumber) && this.state.isPhoneVerified) {
      this.setState({
        isPhoneVerified: false,
        phoneOTP: ""
      })
    }
  }

  handleTextChange = (event) => {
    const { name, value } = event.target
    // console.log(name, value)
    this.setState({
      [name]: value
    }, () => {
      this.props.handleTextChange(event)
    })

    if (name === "contactNumber") {
      // console.log("contactNumber")
      if (this.props.isPhoneVerified) {
        this.setState({
          isPhoneVerified: false,
        })
        this.props.fieldVerified("isPhoneVerified", false)
      }
      this.setState({
        phoneSendError: false,
        phoneErrorText: "",
        disabledContactVerifyFor360Sec: false,
        showPhoneOtpTextField: false
      }, () => this.props?.handleRegistrationOtpfield(this.state?.showPhoneOtpTextField))
    }
    if (name === "email") {
      // console.log("email")
      if (this.props.isEmailVerified) {
        this.setState({
          isEmailVerified: false,
        })
        this.props.fieldVerified("isEmailVerified", false)
      }

      this.setState({

        emailSendError: false,
        emailErrorText: "",
        disabledEmailVerifyFor360Sec: false,
        showEmailOtpTextField: false
      }, () => this.props?.handleRegistrationOtpfield(this.state?.showEmailOtpTextField))
    }

  }

  handleSendOTPViaEmailTest = () => {
    this.setState(
      {
        emailOTPLoader: true,
        otpEmailError: false,
        emailOtpRecieved: false,
        isInValidEmailOtp: false,
        invalidEmailOtpErrorMsg: '',
        emailOTP: ""
      })
    // setTimeout(() => {
    axios.
      post(B2b_URL.CUSTOMER_REGISTRATION_GET_OTP,
        { credential: this.state.email })
      .then(response => {
        // console.log(response)
        if (response.status === 200) {
          this.setState({
            OTPSecretKey: response.data?.otp_secret_key,
            OTPValidUntil: response.data?.otp_valid_until,
            emailOTPLoader: false,
            emailOtpRecieved: true,
            disabledEmailVerifyFor360Sec: true,
            // emailOTPNumber: "123456",
            showEmailOtpTextField: true,
            emailSendError: false
          }, () => {
            this.handleDisabledEmailVerifyFor360Sec()
            this.props?.handleRegistrationOtpfield(this.state?.showEmailOtpTextField)
          })
        }
      })
      .catch(error => {
        console.log(error)
        let emailErrorText = ""
        if (error?.response?.status === 400) {
          if (error?.response?.data) {
            emailErrorText = error?.response?.data.non_field_errors[0]
          }
        }
        // console.log(emailErrorText)
        this.setState({
          emailOTPLoader: false,
          emailOtpRecieved: false,
          disabledEmailVerifyFor360Sec: false,
          // emailOTPNumber: "123456",
          showEmailOtpTextField: false,
          emailErrorText,
          emailSendError: true
        })
        this.props?.handleRegistrationOtpfield(this.state?.showEmailOtpTextField)
      })


    // this.handleDisabledEmailVerifyFor360Sec()
    // }, 1500);

  }

  handleEmailOTPVerification = () => {
    this.setState({
      emailVerifyLoader: true
    })
    let data = {
      credential: this.state.email,
      otp: this.state.emailOTP,
      otp_secret_key: this.state?.OTPSecretKey,
      otp_valid_until: this.state?.OTPValidUntil
    }
    axios.
      post(B2b_URL.CUSTOMER_REGISTRATION_VERIFY_OTP, data)
      .then(response => {
        // console.log(response)
        if (response.status === 202 || response.status === 200) {
          this.setState({
            isEmailVerified: true,
            emailVerifyLoader: false,
            otpEmailError: false
          }, this.handleShowEmailOTPField)
          // if (this.state.emailOTPNumber === this.state.emailOTP) {
          this.props.fieldVerified("isEmailVerified", true)
          // }
        }
      })
      .catch(error => {
        console.log(error)
        // if (error?.response?.status === 401 || error?.response?.status === 400) {
        // console.log("here", error?.response)
        this.setState({
          isInValidEmailOtp: true,
          invalidEmailOtpErrorMsg: error?.response.data?.msg
        })
        // }
        this.setState({
          isEmailVerified: false,
          emailVerifyLoader: false,
          otpEmailError: true,

        }, this.handleShowEmailOTPField)
        // if (this.state.emailOTPNumber === this.state.emailOTP) {
        this.props.fieldVerified("isEmailVerified", false)
        // }
      })
    // if (this.state.otpNumber === this.state.emailOTP) {
    // this.setState({
    //   isEmailVerified: this.state.emailOTPNumber === this.state.emailOTP ? true : false,
    //   emailVerifyLoader: false,
    //   otpEmailError: this.state.emailOTPNumber === this.state.emailOTP ? false : true
    // }, this.handleShowEmailOTPField)
    // if (this.state.emailOTPNumber === this.state.emailOTP) {
    //   this.props.fieldVerified("isEmailVerified", true)
    // }
    // }
    // }, 1500);
  }
  handleShowEmailOTPField = () => {
    // console.log(this.state.isEmailVerified)
    setTimeout(() => {
      this.setState({
        showEmailOtpTextField: this.state.isEmailVerified ? false : true
      }, () => this.props?.handleRegistrationOtpfield(this.state?.showEmailOtpTextField))
    }, 1500);
  }
  // handleVerifyEmail = () => {
  //   this.setState(
  //     {
  //       emailOTPLoader: true,
  //       otpEmailError: false,
  //       emailOtpRecieved: false,
  //     },
  //     () => {
  //       this.props.resetEmailOtp();
  //       this.props
  //         .otpSendInEmail(this.props.email)
  //         .then((result) => {
  //           if (result.status === 200) {
  //             this.setState(
  //               {
  //                 emailOTPLoader: false,
  //                 emailOtpRecieved: true,
  //                 disabledEmailVerifyFor360Sec: true,
  //                 emailOTPNumber: result.data.verification_code,
  //               },
  //               () => {
  //                 this.handleDisabledEmailVerifyFor360Sec();
  //                 this.props.handleChangeEmailOTPNumber(
  //                   this.state.emailOTPNumber
  //                 );
  //               }
  //             );
  //           }
  //         })
  //         .catch((error) => {
  //           this.setState({ emailOTPLoader: false });
  //         });
  //     }
  //   );
  // };

  handleSendOTPToPhoneTest = () => {
    this.setState({
      contactNumberOTPLoader: true,
      otpError: false,
      otpRecieved: false,
      isInValidPhoneOtp: false,
      invalidPhoneOtpErrorMsg: '',
      phoneOTP: ""
      // showPhoneOtpTextField: false
    });
    // setTimeout(() => {
    axios.
      post(B2b_URL.CUSTOMER_REGISTRATION_GET_OTP,
        { credential: this.state.contactNumber })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            OTPSecretKey: response.data?.otp_secret_key,
            OTPValidUntil: response.data?.otp_valid_until,
            disabledContactVerifyFor360Sec: true,
            contactNumberOTPLoader: false,
            otpRecieved: true,
            showPhoneOtpTextField: true,
            phoneSendError: false,
          }, () => {
            this.handleDisabledContactVerifyFor360Sec();
            this.props?.handleRegistrationOtpfield(this.state?.showPhoneOtpTextField)
          });
        }
      })
      .catch(error => {
        console.log(error)
        let phoneErrorText = ""
        if (error?.response?.status === 400) {
          if (error?.response?.data) {
            phoneErrorText = error?.response?.data.non_field_errors[0]
            this.setState({
              phoneSendError: true,
              phoneErrorText
            })
          }
        }
        this.setState({
          disabledContactVerifyFor360Sec: false,
          contactNumberOTPLoader: false,
          otpRecieved: false,
          showPhoneOtpTextField: false
        });
        this.props?.handleRegistrationOtpfield(this.state?.showPhoneOtpTextField)
      })
    // this.setState({
    //   disabledContactVerifyFor360Sec: true,
    //   contactNumberOTPLoader: false,
    //   otpRecieved: true,
    //   otpNumber: "123456",
    //   showPhoneOtpTextField: true
    // });
    // }, 1500);
    // this.handleDisabledContactVerifyFor360Sec()
  }

  handlePhoneOTPVerification = (e) => {
    // console.log(this.state.otpNumber, e.target.value)
    // console.log(this.state.otpNumber === e.target.value)
    this.setState({
      // isPhoneVerified: true,
      phoneverifyLoader: true
    })
    let data = {
      credential: this.state.contactNumber,
      otp: this.state.phoneOTP,
      otp_secret_key: this.state?.OTPSecretKey,
      otp_valid_until: this.state?.OTPValidUntil
    }
    axios.
      post(B2b_URL.CUSTOMER_REGISTRATION_VERIFY_OTP, data)
      .then(response => {
        // console.log(response)
        if (response.status === 202 || response.status === 200) {
          this.setState({
            isPhoneVerified: true,
            phoneverifyLoader: false,
            otpError: this.state.otpNumber === this.state.phoneOTP ? false : true,
          }, this.handleShowPhoneOTPField)
          // if (this.state.otpNumber === this.state.phoneOTP) {
          this.props.fieldVerified("isPhoneVerified", true)
          // }
        }
        else {
          this.setState({
            phoneverifyLoader: false,
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error?.response?.status === 400) {
          // console.log("here", error?.response)
          this.setState({
            isInValidPhoneOtp: true,
            invalidPhoneOtpErrorMsg: error?.response.data?.msg,
            phoneverifyLoader: false,
          })
        }
        this.setState({
          isPhoneVerified: false,
          phoneverifyLoader: false,
          otpError: true,
        }, this.handleShowPhoneOTPField)
        this.props.fieldVerified("isPhoneVerified", false)
      })
    // this.setState({
    //   isPhoneVerified: this.state.otpNumber === this.state.phoneOTP ? true : false,
    //   phoneverifyLoader: false,
    //   otpError: this.state.otpNumber === this.state.phoneOTP ? false : true,
    // }, this.handleShowPhoneOTPField)
    // if (this.state.otpNumber === this.state.phoneOTP) {
    //   this.props.fieldVerified("isPhoneVerified", true)
    // }
    // }, 1500);

  }
  handleShowPhoneOTPField = () => {
    setTimeout(() => {
      this.setState({
        showPhoneOtpTextField: this.state.isPhoneVerified ? false : true
      }, () => this.props?.handleRegistrationOtpfield(this.state?.showPhoneOtpTextField))
    }, 1500)
  }

  handleOTPFieldChange = (e) => {
    // console.log(e.target.name, e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    }, () => {
      if (e.target.name === 'emailOTP') {
        this.setState({
          isInValidEmailOtp: false,
          invalidEmailOtpErrorMsg: e.target.value.length === 6 && ""
        })
      }
      else if (e.target.name === 'phoneOTP') {
        this.setState({
          isInValidPhoneOtp: false,
          invalidPhoneOtpErrorMsg: e.target.value.length === 6 && ""
        })
      }
    })
  }
  // handleVeryfyContact = () => {
  //   this.props.resetOTP();
  //   this.setState({
  //     contactNumberOTPLoader: true,
  //     otpError: false,
  //     otpRecieved: false,
  //   });
  //   if (this.props.contactNumber.length === 10) {
  //     this.props
  //       .phoneExist(this.props.contactNumber)
  //       .then((status) => {
  //         if (status.data.msg === "<Response [200]>") {
  //           this.setState({ numberExist: true }, () => {
  //             if (this.state.numberExist) {
  //               this.setState({
  //                 contactNumberOTPLoader: false,
  //               });
  //             }
  //           });
  //         }
  //         if (status.data.msg === "Not exist") {
  //           this.setState(
  //             {
  //               numberNotExist: true,
  //             },
  //             () => {
  //               this.props
  //                 .sendOTP(this.props.contactNumber)
  //                 .then((res) => {
  //                   if (res.status === 200) {
  //                     this.setState(
  //                       {
  //                         disabledContactVerifyFor360Sec: true,
  //                         contactNumberOTPLoader: false,
  //                         otpRecieved: true,
  //                         otpNumber: res.data.otp,
  //                       },
  //                       () => {
  //                         this.handleDisabledContactVerifyFor360Sec();
  //                         this.props.handleChangeOtpNumber(
  //                           this.state.otpNumber
  //                         );
  //                       }
  //                     );
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   this.setState({
  //                     contactNumberOTPLoader: false,
  //                   });
  //                 });
  //             }
  //           );
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }
  // };
  handleDisabledEmailVerifyFor360Sec = () => {
    // console.log("disabled")
    setTimeout(() => {
      // console.log("disabled")
      this.setState({
        disabledEmailVerifyFor360Sec: false,
        showEmailOtpTextField: false,
        emailOTP: "",
        emailVerifyLoader: false
      }, () => this.props?.handleRegistrationOtpfield(this.state?.showEmailOtpTextField));
    }, 360000);
  };
  handleDisabledContactVerifyFor360Sec = () => {
    setTimeout(() => {
      this.setState({
        disabledContactVerifyFor360Sec: false,
        showPhoneOtpTextField: false,
        phoneOTP: "",
        phoneverifyLoader: false
      }, () => this.props?.handleRegistrationOtpfield(this.state?.showPhoneOtpTextField));
    }, 360000);
  };
  render() {
    const {
      showErrorMessage,
      showErrorMessageEmail,
      emailExist,
      numberExist,
      otpError,
      otpEmailError,
      phoneOTP,
      contactNumber,
      email,
      otp,
      //emailOTPNumber,
      emailOTP,
      emailOTPError,
      isEmailVerified,
      isPhoneVerified,
      emailSendError,
      emailErrorText,
      invalidEmailOtpErrorMsg,
      isInValidEmailOtp,
      invalidPhoneOtpErrorMsg,
      isInValidPhoneOtp,
      phoneSendError,
      phoneErrorText,
    } = this.state;
    const {
      classes,
      email_Error,
      contactNumber_Error,
      countries,
      country_code
    } = this.props;
    // console.log(countries)
    return (
      <div className={classes.registerLoginCredentialsbody}>
        <div className="register-login-credential-header" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <div className={classes.loginCredentialText}>Login Credential</div>
          <span className={classes.infoText}>
            This information will be used to login into the portal
          </span>
          <span className={classes.infoTextSecond}>
            You can add more email and contact later in setting
          </span>
        </div>

        <div className="register-login-credential-form-container">
          <br />
          <TextField
            name="email"
            value={email.toLowerCase() || ""}
            id="outlined-name"
            // label={emailExist || emailSendError ? "Email already exist" : "Email Address"}
            label="Email Address"
            margin="normal"
            variant="outlined"
            disabled={this.state.otpRecieved && this.state.showPhoneOtpTextField}
            error={showErrorMessageEmail ? true : emailExist || emailSendError ? true : false}
            className={classes.textFieldStyle}
            onChange={this.handleTextChange}
            // disabled={this.state.isEmailVerified}
            FormHelperTextProps={{
              style: { marginLeft: "0px", marginRight: "0px", },
            }}
            InputLabelProps={{ style: { fontSize: window.innerWidth < 375 && pxToEm(15) } }}
            helperText={
              // <span style={{ color: "red" }}>
              //   {email_Error ? "Please enter valid email" : emailSendError ? emailErrorText : null}
              // </span>
              this.state?.disabledEmailVerifyFor360Sec
                ?
                <span style={{ color: "green", display: 'flex', alignItems: 'center', fontSize: window.innerWidth < 375 && pxToEm(13) }}>
                  <TaskAltIcon style={{ transform: 'scale(0.8)' }} />
                  {
                    this.state.isEmailVerified && !this.state.showEmailOtpTextField
                      ? 'OTP Verified Successfully'
                      : 'OTP Sent Successfully'
                  }
                </span>
                :
                <span
                  style={{
                    color: "red",
                    fontSize: window.innerWidth < 375 ? pxToEm(13) : "0.8em",
                  }}
                >
                  {email_Error
                    ? "Please enter valid email address"
                    : emailSendError ? emailErrorText : null}
                </span>
            }
            // onFocus={() => {
            // this.props.resetEmailOtp();
            // this.props.activeStep4EmailFocus();
            // this.setState({
            //   showErrorMessageEmail: false,
            //   emailExist: false,
            //   emailOtpRecieved: false,
            // });
            // }}
            // onBlur={() => {
            // this.props.activeStep4EmailBlur();
            // if (EmailValidator.validate(email) === false) {
            //   this.setState({ showErrorMessageEmail: true });
            // }
            // if (EmailValidator.validate(email) === true) {
            //   this.props
            //     .emailExist(email)
            //     .then((status) => {
            //       if (status.data.msg === "Already exist") {
            //         this.setState({
            //           emailExist: true,
            //           emailOTPLoader: false,
            //         });
            //         // this.props.emailExistCheck();
            //       }
            //       if (status.data.msg === "Not exist") {
            //         this.props.resetEmailExistCheck();
            //       }
            //     })
            //     .catch((e) => {
            //       console.log(e);
            //     });
            // }
            // }}
            InputProps={{
              style: {
                fontSize: window.innerWidth < 375 && pxToEm(15)
              },
              endAdornment: (
                <InputAdornment position="end" style={{ transform: this.state.emailOTPLoader && 'scale(0.5)' }}>
                  {this.state.emailOTPLoader ? (
                    <CircularProgressLoader />
                  ) : this.state.isEmailVerified && !this.state.showEmailOtpTextField ?
                    <Avatar className={classes.avatar} src={"/check-circle.gif"} /> :
                    this.state.disabledEmailVerifyFor360Sec ?
                      <Countdown endTime={0} startTime={360} /> : (
                        <Button
                          disabled={
                            EmailValidator.validate(email) === false ||
                            this.state.disabledEmailVerifyFor360Sec ||
                            this.state.showPhoneOtpTextField
                          }
                          style={{
                            fontSize: "1em",
                            textTransform: "capitalize",
                            padding: "6px 0px",
                            color:
                              EmailValidator.validate(email) === false ||
                                this.state.disabledEmailVerifyFor360Sec ||
                                this.state.showPhoneOtpTextField
                                ? "grey"
                                : secondaryColor,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            // this.handleVerifyEmail();
                            this.handleSendOTPViaEmailTest();
                          }}
                        >
                          Get OTP
                        </Button>
                      )}
                </InputAdornment>
              ),
            }}
          />
          {
            this.state.emailOtpRecieved && this.state.showEmailOtpTextField ? (
              <TextField
                name="emailOTP"
                id="outlined-name"
                type="text"
                label="OTP"
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 6, minLength: 6, max: 6 }}
                value={emailOTP}
                onChange={(e) => {
                  this.handleTextChange(e)
                  this.handleOTPFieldChange(e)
                }}
                InputLabelProps={{ style: { fontSize: window.innerWidth < 375 && pxToEm(15) } }}
                onKeyPress={(event) => {
                  if (event.charCode >= 48 && event.charCode <= 57) {
                    // let it happen, don't do anything
                  }
                  else { event.preventDefault(); }
                }}
                className={classes.textFieldStyle}
                // onFocus={() => {
                //   this.setState({ otpEmailError: false });
                // }}
                // onBlur={() => {
                //   if (this.state.emailOTPNumber !== emailOTP) {
                //     this.setState({ otpEmailError: true });
                //   }
                // }}
                FormHelperTextProps={{
                  style: { marginLeft: "0px", marginRight: "0px", fontSize: window.innerWidth < 375 && pxToEm(12) },
                }}
                InputProps={{
                  style: { fontSize: window.innerWidth < 375 && pxToEm(15) },
                  endAdornment: (
                    <InputAdornment position="end" style={{ transform: this.state.emailVerifyLoader && 'scale(0.5)' }}>
                      {
                        this.state.emailVerifyLoader ? (
                          <CircularProgressLoader />
                        ) : this.state.isEmailVerified ?
                          <Avatar className={classes.avatar} src={"/check-circle.gif"} /> : (
                            <Button
                              // disabled={
                              //   EmailValidator.validate(email) === false ||
                              //   this.state.disabledEmailVerifyFor360Sec
                              // }
                              style={{
                                fontSize: "1em",
                                textTransform: "capitalize",
                                padding: "6px 0px",
                                color:
                                  !(emailOTP.length === 6)
                                    ? "grey"
                                    : secondaryColor,
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleEmailOTPVerification(e);
                              }}
                            >
                              Verify
                            </Button>
                          )}
                    </InputAdornment>
                  ),
                }}
                helperText={
                  // <span style={{ color: "red" }}>
                  // {
                  invalidEmailOtpErrorMsg
                    ? invalidEmailOtpErrorMsg
                    : emailOTP.length === 6 || emailOTP === "" || !otpEmailError
                      ? null
                      : "Invalid OTP"
                  // }
                  // </span>
                }
                error={isInValidEmailOtp ? true : emailOTP.length === 6 || emailOTP === "" ? false : true}
              />
            )
              : null
          }
          <br />


          <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "white", width: "90%" }}>

            <FormControl
              variant="outlined"
              // className={classes.formControlStyle}
              size="small"
              // className={classes.textFieldStyle}
              style={{ width: "30%" }}
              margin="normal"
            >
              <InputLabel id="demo-simple-select-label" style={{ fontSize: window.innerWidth < 375 && pxToEm(13), width: '75%' }}>
                Country Code *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-select-small"
                name="country_code"
                label="Country Code *"
                style={{ height: "54px", fontSize: window.innerWidth < 375 && pxToEm(13) }}
                MenuProps={MenuProps}
                InputLabelProps={{ style: { fontSize: window.innerWidth < 375 && pxToEm(13), width: '75%' } }}
                disabled={
                  contactNumber_Error ||
                  // this.state.disabledContactVerifyFor360Sec ||
                  (this.state.emailOtpRecieved && this.state.showEmailOtpTextField)
                  // || !isEmailVerified
                }
                value={country_code}
                onChange={(e) => {
                  e.preventDefault();
                  this.props.handleSelect(e);
                  this.setState({
                    phoneSendError: false,
                    phoneErrorText: "",
                    disabledContactVerifyFor360Sec: false,
                    showPhoneOtpTextField: false
                  })
                }}>
                <MenuItem value={null} disabled={true}>
                  Select Any
                </MenuItem>
                {
                  // countries && countries.length > 0 &&
                  countries.map(country =>
                    <MenuItem
                      value={country.phone_code[0] === "+" ? country.phone_code.replace("-", '') : `+${country.phone_code.replace("-", '')}`}
                      style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                    >
                      {country.name} {country.phone_code[0] === "+" ? `(${country.phone_code.replace("-", '')})` : `(+${country.phone_code.replace("-", '')})`}
                    </MenuItem>
                  )


                }
                {/* <MenuItem value={"+91"}>+91</MenuItem> */}
                {/* <MenuItem value={"MS."}>Ms.</MenuItem>
                <MenuItem value={"MRS."}>Mrs.</MenuItem>
                <MenuItem value={"MISS."}>Miss.</MenuItem> */}
              </Select>
            </FormControl>

            <TextField
              type="text"
              style={{ width: "65%", fontSize: window.innerWidth < 375 && pxToEm(15) }}
              // disabled={!isEmailVerified}
              name="contactNumber"
              id="outlined-name"
              label={numberExist || phoneSendError ? "Number already exist" : "Phone no."}
              InputLabelProps={{ style: { fontSize: window.innerWidth < 375 && pxToEm(15) } }}
              margin="normal"
              variant="outlined"
              disabled={(this.state.emailOtpRecieved && this.state.showEmailOtpTextField)}
              inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
              onKeyPress={(event) => {
                if (event.charCode >= 48 && event.charCode <= 57) {
                  // let it happen, don't do anything
                }
                else { event.preventDefault(); }
              }}
              FormHelperTextProps={{
                style: { marginLeft: "0px", marginRight: "0px", fontSize: window.innerWidth < 375 && pxToEm(12) },
              }}
              onChange={this.handleTextChange}
              value={contactNumber || ""}
              error={contactNumber_Error || numberExist || phoneSendError}
              helperText={
                // <span style={{ color: "red" }}>
                //   {contactNumber_Error
                //     ? "Please enter valid 10 digit  number"
                //     : phoneSendError ? phoneErrorText
                //       : null}
                // </span>
                this.state.disabledContactVerifyFor360Sec
                  ?
                  <span style={{ color: "green", display: 'flex', alignItems: 'center', fontSize: window.innerWidth < 375 && pxToEm(13) }}>
                    <TaskAltIcon style={{ transform: 'scale(0.8)' }} />
                    {this.state.isPhoneVerified && !this.state.showPhoneOtpTextField
                      ? 'OTP Verified Successfully'
                      : 'OTP Sent Successfully'
                    }
                  </span>
                  :
                  <span style={{ color: "red" }}>
                    {contactNumber_Error
                      ? "Please enter valid 10 digit  number"
                      : phoneSendError ? phoneErrorText
                        : null}
                  </span>
              }
              // onFocus={() => {
              //   this.props.resetOTP();
              //   this.setState({
              //     showErrorMessage: false,
              //     numberExist: false,
              //     showOTP_inputField: false,
              //     otpError: false,
              //     otpRecieved: false,
              //   });
              // }}
              // onBlur={() => {
              //   if (contactNumber.length < 10) {
              //     this.setState({ showErrorMessage: true });
              //   }
              // }}
              // className={classes.textFieldStyle}
              InputProps={{
                style: {
                  fontSize: window.innerWidth < 375 && pxToEm(15)
                },
                endAdornment: (this.state.isPhoneVerified && !this.state.showPhoneOtpTextField ?
                  <Avatar className={classes.avatar} src={"/check-circle.gif"} /> :
                  <InputAdornment position="end" style={{ transform: this.state.contactNumberOTPLoader && 'scale(0.5)' }}>
                    {this.state.contactNumberOTPLoader ? (
                      <CircularProgressLoader />
                    ) : this.state.disabledContactVerifyFor360Sec ?
                      <Countdown endTime={0} startTime={360} /> : (
                        <Button
                          disabled={
                            contactNumber.length < 10 ||
                            this.state.disabledContactVerifyFor360Sec ||
                            !Boolean(this.props.country_code) ||
                            this.state.showEmailOtpTextField
                            // || !isEmailVerified
                          }
                          style={{
                            fontSize: window.innerWidth < 375 ? pxToEm(15) : "1em",
                            textTransform: "capitalize",
                            padding: "6px 0px",
                            color:
                              contactNumber.length < 10 ||
                                this.state.disabledContactVerifyFor360Sec ||
                                !Boolean(this.props.country_code) ||
                                this.state.showEmailOtpTextField
                                ? "grey"
                                : secondaryColor,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            // this.handleVeryfyContact();
                            this.handleSendOTPToPhoneTest();
                          }}
                        >
                          Get OTP
                        </Button>
                      )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <br />
          {
            this.state.otpRecieved && this.state.showPhoneOtpTextField ? (
              <TextField
                name="phoneOTP"
                type="text"
                id="outlined-name"
                label="Verify OTP"
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 6, minLength: 6, max: 6 }}
                InputLabelProps={{ style: { fontSize: window.innerWidth < 375 && pxToEm(15) } }}
                onKeyPress={(event) => {
                  if (event.charCode >= 48 && event.charCode <= 57) {
                    // let it happen, don't do anything
                  }
                  else { event.preventDefault(); }
                }}
                FormHelperTextProps={{
                  style: { marginLeft: "0px", marginRight: "0px", fontSize: window.innerWidth < 375 && pxToEm(12) },
                }}
                onChange={(e) => {
                  this.handleTextChange(e)
                  this.handleOTPFieldChange(e)
                }}
                value={phoneOTP || ""}
                // onFocus={() => {
                //   this.setState({ otpError: false });
                // }}
                // onBlur={() => {
                //   if (this.state.otpNumber !== phoneOTP) {
                //     this.setState({ otpError: true });
                //   }
                // }}
                helperText={
                  invalidPhoneOtpErrorMsg
                    ? invalidPhoneOtpErrorMsg
                    : (phoneOTP.length === 6 || phoneOTP === "" || !otpError)
                      ? null
                      : "Invalid OTP"
                }
                error={isInValidPhoneOtp ? true : phoneOTP.length === 6 || phoneOTP === "" || !otpError ? false : true}
                InputProps={{
                  maxLength: 6,
                  style: { fontSize: window.innerWidth < 375 && pxToEm(15) },
                  endAdornment: (
                    <InputAdornment position="end" style={{ transform: this.state.phoneverifyLoader && 'scale(0.5)' }}>
                      {this.state.phoneverifyLoader ? (
                        <CircularProgressLoader />
                      ) : this.state.isPhoneVerified ?
                        <Avatar className={classes.avatar} src={"/check-circle.gif"} /> : (
                          <Button
                            // disabled={
                            //   contactNumber.length < 10 ||
                            //   this.state.disabledContactVerifyFor360Sec
                            // }
                            style={{
                              fontSize: "1em",
                              textTransform: "capitalize",
                              padding: "6px 0px",
                              color:
                                !(phoneOTP.length === 6)
                                  ? "grey"
                                  : secondaryColor,
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.handlePhoneOTPVerification(e);
                            }}
                          >
                            Verify
                          </Button>
                        )}
                    </InputAdornment>
                  ),
                }}
                className={classes.textFieldStyle}
              />
            ) : null
          }
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterLoginCredential);
