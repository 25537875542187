import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Slide,
  Typography,
  Button,
  Tabs,
  Tab,
  CircularProgress,
} from "@material-ui/core";

import {
  isAccessibleModule,
  generateCSV,
  isRequired,
  options,
  checkNumeric,
  isValidTanNumber,
  checkNameFieldAlphaNumeric,
  isValidClassCode,
  checkApiError,
  hasUpdateAccess,
  hasApproveAccess,
  checkInvalidValue,
  filterUnusedField
} from "../../../../methods";
import { withStyles } from "@material-ui/core/styles";
import ManageCustomerAccountDetails from "./ManageCustomerAccountDetails";
import ManageCustomerCredentialDetails from "./ManageCustomerCredentialDetails";
import ManageCustomerGeneralDetails from "./ManageCustomerGeneralDetails";
import ManageCustomerPersonalDetails from "./ManageCustomerPersonalDetails";
import ManageCustomerSubscriptionDetails from "./ManageCustomerSubscriptionDetails";
//Customer Registration New UI
import ManageCustomerGstPanDetailsNewUi from "./ManageCustomerGstPanDetailsNewUi";
import ManageCustomerGeneralDetailsNewUi from "./ManageCustomerGeneralDetailsNewUi";
import ManageCustomerAccountDetailsNewUi from "./ManageCustomerAccountDetailsNewUi";
import ManageCustomerSubscriptionDetailsNewUi from "./ManageCustomerSubscriptionDetailsNewUi";
import ManageCustomerBuyerConsigneeDetailsNewUi from "./ManageCustomerBuyerConsigneeDetailsNewUi";
import CircularLoader from "../../common/Spinner";
import axios from "axios";
import {
  secondaryBlueText,
  primaryColor,
  drawerTextColor,
  whiteSmoke,
  grey,
  secondaryGrey,
  successButtonBackground,
  cancelButtonBackground,
} from "../../../../constants/internal/colors";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {
  pxToEm,
  validateGstinNumber,
  generatePassword,
  isValidPinCode,
  isCharactersValid,
  isEmailValid,
  isPhoneNumberValid,
  isPasswordValid,
  isPanNumberValid,
  isValidCountryCode,
  isValidStdCode,
  isValidIFSCCode,
} from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import moment from "moment";
import AddMoreDottedButtonComponent from "./AddMoreDottedButtonComponent";

import ExtraPhoneNumbersDisplay from "./ExtraPhoneNumbersDisplay";
import ManageNewExtraPhone from "./ManageNewExtraPhone";
import { EventRepeat } from "@mui/icons-material";
import { isEmpty } from "lodash";
import debounce from "lodash.debounce";
import ToolTipComponent from "../../common/ToolTipComponent";
const manageCustomersStyles = (theme) => ({
  titleWithBlueFont: {
    fontSize: pxToEm(18),
    color: secondaryBlueText,
    cursor: "pointer"
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
    marginLeft: 10,
  },
  table: {
    minWidth: 650,
    border: "0px",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
  },
  productFont: {
    fontSize: pxToEm(13),
  },
  dataFont: {
    fontSize: pxToEm(13),
    color: grey,
  },
  alignText: {
    display: "flex",
    placeContent: "flex-end",
  },
  rightSpacing: {
    marginRight: 10,
  },
  arrowDimension: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
  },
  addNewButtonAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },

  displayData: {
    marginTop: 10,
  },
  searchBox: {
    minWidth: 300,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  tabsStyles: {
    backgroundColor: whiteSmoke,
    color: secondaryGrey,
    fontSize: pxToEm(15),
  },
  selectedTab: {
    backgroundColor: "#4b87c5",
    color: "#fff",
    fontSize: pxToEm(13),
  },
  tabsViewStyle: {
    width: "80%",
  },
  tabsAddStyle: {
    width: "75%",
  },
  tabsEditStyle: { width: "63%" },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "30px",
  },
  displayFlex: {
    display: "flex",
  },
  loaderStyle: { color: primaryColor },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ManageCustomers extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      useNewUi: true,
      allCategories: [],
      allStates: [],
      pincodeErrors: [],
      tabValue: 0,
      previousTabValue: null,
      customerOperation: null,
      selectedCustomerId: null,
      gstDataValidated: false,
      gstinNumber: null,
      validGstinNumber: "",
      country: "India",
      customerState: "",
      addressLineOne: null,
      addressLineTwo: "",
      addressLineThree: "",
      city: null,
      pinCode: null,
      allDealers: [],
      allDistributors: [],
      customerDateOfBirth: null,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      gstDataFetchLoader: false,
      customerFirstName: null,
      customerLastName: null,
      oldPassword: null,
      email: "",
      phoneNumber: "",
      password: null,
      employeeID: "",
      customerType: "",
      phoneCountryCode: "91",
      customerTypeL1: "",
      customerTypeL2: "",
      customerTypeL1_name: "",
      customerTypeL2_name: "",
      customerTag: "",
      customerTag_name: "",
      customerTagData: null,
      customerTypeL2Data: null,
      customerTypeL1Data: null,
      tanNumber: "",
      accountClassCode: "",
      accountVerify: false,
      accountApproved: false,
      isAccountApproved: false,
      openAdditionalCOntactForm: false,
      brokeragePercent: 0,
      customerSelectedDealerOrDistributor: [],
      pinCodeError: false,
      cityError: false,
      customerFirstNameError: false,
      customerLastNameError: false,
      passwordError: false,
      phoneNumberError: false,
      emailError: false,
      ifscCodeError: false,
      oldPasswordError: false,
      dealerDistributorName: null,
      dealerDistributorId: null,
      customerSelectedSubscriptionCategory: null,
      customerSelectedSubscriptionState: null,
      customerSelectedSubscriptionRateType: null,
      dealerDistributorGstinNumber: null,
      dealerDistributorPhoneNumber: null,
      saveDealerDistributorLoader: false,
      saveCustomerSubscriptionLoader: false,
      legalNameOfBusiness: null,
      stateJurisdiction: null,
      centreJurisdiction: null,
      dateOfRegistration: null,
      constitutionOfBusiness: null,
      taxpayerType: null,
      GSTINStatus: null,
      dateOfCancellation: null,
      tradeName: null,
      fieldVisitConducted: null,
      customerCredentialId: null,
      customerDealersDistributors: [],
      customerBroker: [],
      customerSubscriptions: [],
      openRemoveCustomerDialog: false,
      openRemoveCustomerDialogText: '',
      customerRemoveLoader: false,
      selectedCustomerDealerDistributorCreateId: null,
      removeDealerDistributorLoader: false,
      customerDontHaveGSTIN: false,
      changePaymentMethodOperation: false,
      customerPanVerified: false,
      rawUploadedFile: "",
      uploadFileName: null,
      panImageUrl: null,
      panFileName: null,
      PanImagePreview: "",
      panNumberError: false,
      panNumber: null,
      panHolderNameError: false,
      panHolderName: null,
      countryError: false,
      companyName: null,
      customerStateError: false,
      customerItemCategory: [],
      gstStatusError: null,

      //new ui states
      customerBuyerPanNumber: null,
      customerAddressBox: [],
      saveLoader: false,
      // customersExistingAddresses: [],
      lastUpdatedDate: null,
      customerRejected: false,
      customerDiscountAmount: null,
      customerRejected: false,
      customerCreditLimitAdvanceOperation: 'view',
      customerCreditLimitNonAdvanceOperation: 'view',
      totalCreditLimitAdvanceVisible: null,
      totalCreditLimitAdvance: null,
      totalCreditLimitOtherVisible: null,
      totalCreditLimitOther: null,
      effectiveCreditLimitAdvance: null,
      subcontractVclLimit: null,
      effectiveCreditLimitOther: null,
      totalCreditLimitAdvanceLogDetail: [],
      totalCreditLimitOtherLogDetail: [],
      effectiveCreditLimitAdvanceLastUpdate: null,
      effectiveCreditLimitOtherLastUpdate: null,
      syncButtonClicked: null,
      saveCreditLimitProvidedLoader: false,
      isTabOneChangeAllowed: false,
      isTabTwoChangeAllowed: false,
      isTabThreeChangeAllowed: false,

      buyerPayment: "",
      specifiedPerson: "",
      declaration: "",
      buyerTurnover: "",

      principalBuisnessAddressLineOne: null,
      principalBuisnessAddressLineTwo: null,
      principalBuisnessBuildingName: null,
      principalBuisnessDoorNumber: null,
      principalBuisnessFloorNumber: null,
      principalBuisnessStreet: null,
      principalBuisnessLocation: null,
      principalBuisnessAddressStateName: null,
      principalBuisnessAddressDistrictName: null,
      principalBuisnessAddressCityName: null,
      principalBuisnessAddressPinCode: null,
      principalBuisnessAddressK_sr_no: null,
      principalBuisnessAddressG_sr_no: null,
      principalBuisnessAddressD_sr_no: null,
      additionalBuisnessAddressLineOne: null,
      additionalBuisnessAddressLineTwo: null,
      additionalBuisnessBuildingName: null,
      additionalBuisnessStreet: null,
      additionalBuisnessLocation: null,
      additionalBuisnessDoorNumber: null,
      additionalBuisnessFloorNumber: null,

      additionalBuisnessAddressStateName: null,
      additionalBuisnessAddressPinCode: null,
      natureOfBuisnessActivity: null,
      removeAdditionalContactLoader: false,
      removeAllowedPaymentMethodLoader: false,
      additionalContactOperation: {
        operation: 'view',
        rowID: '',
      },
      additionalContactEditError: {
        contact_name: false,
        contact_email_id: false,
        contact_phone_number: false,
      },
      additionalContactRowEditData: [],
      customerAdditionalContacts: [],
      additionalContactName: null,
      additionalContactNameError: false,
      additionalContactEmail: null,
      additionalContactEmailError: false,
      additionalContactContact: null,
      additionalContactContactError: false,
      additionalContactNotificationStatus: false,
      saveAdditionalContactLoader: false,
      saveAllowedPaymetMethodLoader: false,
      customerSelectedPaymentMethodId: null,
      allCustomers: [],
      customerBuyerData: [],
      customerConsigneeData: [],
      customerBuyerId: null,
      customerBuyerName: null,
      customerBuyerGstinPanNumber: null,
      customerBuyerContactNumber: null,
      customerConsigneeId: null,
      customerConsigneeName: null,
      customerBuyerConsigneeOperation: "view",
      customerConsigneeAddress: null,
      customerConsigneeContactNumber: null,
      removeCustomerBuyerLoader: false,
      removeCustomerConsigneeLoader: false,
      customerSelectedCountryId: 101,
      selectedCountryAllStates: [],
      citiesAndStatesData: [],
      showCustomerAddressEditIcon: true,
      selectedAddressState: "",
      selectedAddressCity: "",
      selectedAddressCountry: "",
      selectedStateCode: "",
      customerAllowedPaymentMethods: [],
      customerDataFetchLoader: true,
      allAllowedPaymentMethods: [],
      allAllowedPaymentMethodsLoader: true,
      fetchCustomerDataLoader: true,
      gstAdditionalBuisnessAddressData: [],
      customerBuyersList: [],
      customerConsigneeList: [],
      customerBuyersSelectionList: [],
      customerConsigneeSelectionList: [],
      customerConsigneeSelectionListLoader: false,
      customerNameGstInformation: false,
      saveCustomerBuyerLoader: false,
      saveCustomerConsigneeLoader: false,

      // manual input field states
      customerClassCodeD: "",
      customerClassCodeG: "",
      customerClassCodeK: "",
      isDBoxChecked: false,
      isGBoxChecked: false,
      isKBoxChecked: false,
      classCodeKError: false,
      classCodeGError: false,
      classCodeDError: false,
      tanNumberError: false,
      customerTdsCode: "",
      customerTdsId: "",
      customerTdsValue: "",
      customerTdsName: "",
      customerTdsRate: "",
      customerTdsSection: "",
      customerTdsLess: 0,
      customerTinNumber: null,
      customerServiceTaxNumber: null,
      customerTitle: null,
      customerNameOnCheque: null,
      customerBankCode: null,
      customerBankAccountNumber: null,
      customerBankAccountNumberError: false,
      ifsc: null,
      customerBankName: null,
      customerCurrencyCode: null,
      customerCloseDate: null,
      customerDiv_Code: null,
      customerCreditLimit: null,
      customerBrokerage: null,
      displayAddMoreAddressButton: true,
      isCustomerApprovalNeeded: false,
      allBankData: [],
      allBankDataLoader: true,
      allTdsData: [],
      allTdsDataLoader: true,
      customerDiscountError: false,
      totalCreditLimitAdvanceError: false,
      totalCreditLimitOtherError: false,
      customerBrokerageAmountError: false,

      // extra phone number add states
      customersExtraPhoneNumbers: [],
      extraPhoneNumbers: [],
      postExtraPhone: {},
      postExtraPhoneNumber: "",
      postExtraPhoneSTD: "",
      postExtraPhoneCountryCode: "",
      isExtraPhoneNumberDataCorrect: true,
      countryCodeError: [],
      extraPhoneNumberErrorFromParentCmpnt: false,
      STDCodeError: [],
      isAllExtraPhonesDataCorrect: false,
      checkForValidity: false,
      getExtraPhoneData: false,

      country_codes: [],
      defaultPhoneCountryCode: null,
      selectedCountryCode: "",
      showExtraPhonesForm: true,


      //Account type states
      accountType: [{ account_type: 'D', account_class_code: null }],
      gcode: null,
      dcode: null,
      kcode: null,
      selectedCustomerData: {},

      // checkbook file states
      checkBookImagePreview: "",
      checkBookRawUploadedFile: "",
      checkBookUploadFileName: null,
      checkbookImageUrl: null,
      checkbookFileName: null,
      checkBookFileTypeError: false,
      panCardFileTypeError: false,
      ifsc: "",
      showAccountSnackBar: false,
      checkAccountSnackBar: false,

      // Primary Address
      address: null,
      state: null,
      city: null,
      pincode: null,
      setStateCode: false,
      showPincodeRefreshButton: false,

      helperText: "",
      error: false,
      openCustomerBuyerListPopper: false,
      openCustomerConsigneeListPopper: false,
      buyerSearch: "",
      consigneeSearch: "",
      phoneNumberTouched: false,
      countryCodeTouched: false
    };
  }

  // Common functions for all tabs
  componentDidMount() {
    this._isMounted = true;
    this.initializeData(this.props);
    // console.log(this.state.extraPhoneNumbers)
    if (this.state.checkAccountSnackBar)
      setInterval(() => this.setState({ showAccountSnackBar: true }), 3000)
    // console.log("rendered")

  }
  // componentDidUpdate() {
  //   if (this.state.previousTabValue === 1 && this.state.customerOperation === "add") {
  //     setInterval(() => this.props.history.push("/internalCustomers"), 2000)
  //     console.log("updated")
  //     // this.props.history.push("/internalCustomers/view/3")
  //   }
  //   // console.log(this.state.previousTabValue, this.state.tabValue)
  // }
  componentWillUnmount() {
    this._isMounted = false;
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   this.initializeData(nextProps);
  // }

  handleOperationChange = (key, value) => {
    // console.log(key, value)
    this.setState({
      changePaymentMethodOperation: false
    })
  }
  handleEditCustomerOperation = (tabValue) => {
    this.setState({
      customerOperation: "edit",
      tabValue: tabValue,
      password: null
    });
    // console.log(this.state.accountType)
  };
  handleCancelBtnClick = () => {
    // console.log(this.props)
    this.prepareSelectedCustomerDataToDisplay(this.props.allCustomers)
    this.clearErrorStates()
    this.props.history.push(`/internalCustomers/view/${this.state.selectedCustomerId}`)
  }

  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    console.log(data)
    return typeof (data) === Object ?
      (<>
        <p style={{ margin: 0 }}>
          state_name : {data['state_name']}
        </p>
      </>)
      :
      (data.map((item, index) => {
        return (
          <>
            {console.log(item)}
            <p style={{ margin: 0 }}>
              {item.includes("0  :") ? item.replace("0  :", "") : item}
            </p>
          </>)
      })
      )
  }

  handleExtraPhoneErrorChange = (hasError) => {
    this.setState({
      extraPhoneNumberErrorFromParentCmpnt: hasError,
    })
  };

  getSnackbarErrorMessage = (errData) => {
    let errors = []
    if (typeof (errData) === Object) {
      errors.push(errData["state_name"])
    } else {
      errData.map((item, index) => {
        const msg = item.includes("0  :") ? item.replace("0  :", "") : item;
        errors.push(msg)
      })
    }
    return errors;
  }

  clearErrorStates = () => {
    this.setState({
      countryCodeError: [],
      extraPhoneNumberError: false,
      STDCodeError: [],
      customerDiscountError: false,
      totalCreditLimitAdvanceError: false,
      totalCreditLimitOtherError: false,
      customerBrokerageAmountError: false,
      classCodeKError: false,
      classCodeGError: false,
      classCodeDError: false,
      tanNumberError: false,
      panNumberError: false,
      panNumber: null,
      panHolderNameError: false,
      panHolderName: null,
      countryError: false,
      companyName: null,
      customerStateError: false,
      customerItemCategory: [],
      gstStatusError: null,
      pinCodeError: false,
      cityError: false,
      customerFirstNameError: false,
      customerLastNameError: false,
      passwordError: false,
      phoneNumberError: false,
      emailError: false,
      ifscCodeError: false,
      oldPasswordError: false,
      checkBookImagePreview: "",
      checkBookRawUploadedFile: "",
      checkBookUploadFileName: null,
      password: null,
    })
  }

  handleAddExtraPhoneNumber = (extraPhone) => {
    // console.log(extraPhone)
    this.setState({
      extraPhoneNumbers: extraPhone
    })
  }

  handleFetchAllCountryCodes = () => {
    axios.get(APIROUTES.GET_COUNTRY_CODES)
      .then((response) => {
        this.setState({
          country_codes: response.data,
          // phoneCountryCode: "91"
        })

      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  }

  handleSyncEffectiveCreditLimit = (field) => {
    this.setState({ syncButtonClicked: field })
    axios.get(`${APIROUTES.CUSTOMERS_CREDIT_LIMIT_SYNC}?id=${this.state.selectedCustomerId}&type=${field}`)
      .then((response) => {
        this.handleOpenSnackbar('Synced Successufully ', "success")
        if (field === 'ADVANCE') {
          this.setState({
            effectiveCreditLimitAdvance: response.data.ecl_advance,
            effectiveCreditLimitAdvanceLastUpdate: response.data.ecl_advance_last_update,
            syncButtonClicked: null
          })
        }
        else {
          this.setState({
            effectiveCreditLimitOther: response.data.ecl_other,
            effectiveCreditLimitOtherLastUpdate: response.data.ecl_other_last_update,
            syncButtonClicked: null
          })
        }
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 3000)
      })
      .catch((error) => {
        this.setState({ syncButtonClicked: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  }

  handleSubcontractVclLimit = (field) => {
    axios.get(`${APIROUTES.GET_SUBCONTRACT_VCL_LIMIT_CUSTOMER}${this.state.selectedCustomerId}/`)
      .then((response) => {
        //this.handleOpenSnackbar('Synced Successufully ', "success")
        this.setState({
          subcontractVclLimit: response.data,
        })
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 3000)
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  }

  handleCustomerCreditLimitMethodOperation = (operation, type) => {
    type === 'ADVANCE'
      ? this.setState({ customerCreditLimitAdvanceOperation: operation })
      : type === 'NON-ADVANCE' && this.setState({ customerCreditLimitNonAdvanceOperation: operation })
  };

  handleTotalCreditLimitCancelButton = (field) => {
    if (field === 'ADVANCE') {
      this.setState({
        totalCreditLimitAdvanceVisible: this.state?.totalCreditLimitAdvance,
        totalCreditLimitAdvanceError: false
      })
    }
    else if (field === 'NON-ADVANCE') {
      this.setState({
        totalCreditLimitOtherVisible: this.state?.totalCreditLimitOther,
        totalCreditLimitOtherError: false
      })
    }
  }

  handleTotalCreditLimitSaveButton = (field) => {
    let data = {}
    if (field === 'ADVANCE') {
      data = {
        tcl_advance: Number(
          checkInvalidValue(this.state?.totalCreditLimitAdvanceVisible)
            ? 0
            : this.state?.totalCreditLimitAdvanceVisible
        )
      }
    }
    else if (field === 'NON-ADVANCE') {
      data = {
        tcl_other: Number(
          checkInvalidValue(this.state?.totalCreditLimitOtherVisible)
            ? 0
            : this.state?.totalCreditLimitOtherVisible
        )
      }
    }
    this.setState({ saveCreditLimitProvidedLoader: field })
    axios.patch(
      `${APIROUTES.UPDATE_CUSTOMER}${this.state.selectedCustomerId}/`,
      data,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (field === 'ADVANCE') {
          this.setState({
            totalCreditLimitAdvance: response.data.tcl_advance,
            totalCreditLimitAdvanceVisible: response.data.tcl_advance,
            totalCreditLimitAdvanceLogDetail: response.data.tcl_log_advance,
            effectiveCreditLimitAdvance: response.data.ecl_advance,
            effectiveCreditLimitAdvanceLastUpdate: response.data.ecl_advance_last_update,
            saveCreditLimitProvidedLoader: false
          })
          this.handleCustomerCreditLimitMethodOperation('view', field)
          this.handleOpenSnackbar(
            'Customer Credit Limit Provided (ADVANCE) Updated Successfully',
            "success"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        }
        else {
          this.setState({
            totalCreditLimitOther: response.data.tcl_other,
            totalCreditLimitOtherVisible: response.data.tcl_other,
            totalCreditLimitOtherLogDetail: response.data.tcl_log_other,
            effectiveCreditLimitOther: response.data.ecl_other,
            effectiveCreditLimitOtherLastUpdate: response.data.ecl_other_last_update,
            saveCreditLimitProvidedLoader: false
          })
          this.handleCustomerCreditLimitMethodOperation('view', field)
          this.handleOpenSnackbar(
            'Customer Credit Limit Provided (NON-ADVANCE) Updated Successfully',
            "success"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        }
      })
      .catch((error) => {
        this.setState({ saveCreditLimitProvidedLoader: false })
        this.handleCustomerCreditLimitMethodOperation('view', field)
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  }



  handleChange = (name, dataOne, dataTwo, dataThree, dataFour) => (event) => {
    if (['totalCreditLimitAdvanceVisible', 'totalCreditLimitOtherVisible', "customerDiscountAmount"].includes(name)) {
      this.setState({ [name]: parseFloat(event.target.value).toFixed(2) })
    } else if (name === "customerTdsCode") {
      // console.log(event.target.value)
      if (event.target.value === "none") {
        this.setState({ [name]: "", customerTdsId: "", customerTdsValue: "" });
      } else {
        this.setState({ [name]: dataOne.id, customerTdsId: dataOne.id, customerTdsValue: `${dataOne.tds_code}-${dataOne.tds_rate}-${dataOne.tds_section}` });
      }
      // console.log(event.target.value, dataOne)
    } else if (name === "ifsc" ||
      name === "customerClassCodeD" ||
      name === "customerClassCodeK" ||
      name === "customerClassCodeG" ||
      name === "tanNumber" ||
      name === "panNumber") {
      this.setState({
        [name]: event.target.value.toUpperCase()
      })
    } else {
      this.setState({ [name]: event.target.value });
    }
    // console.log(name, event.target.value)

    if (name === "gstinNumber") {
      this.setState({
        validGstinNumber: validateGstinNumber(event.target.value),
      });
    } else if (name === "pinCode") {
      this.setState({ pinCodeError: !isValidPinCode(event.target.value) });
    } else if (name === "city") {
      this.setState({ cityError: !isCharactersValid(event.target.value) });
    } else if (name === "ifsc") {
      this.setState({ ifscCodeError: !isValidIFSCCode(event.target.value) });
    } else if (name === "customerFirstName") {
      this.setState({
        customerFirstNameError: !isCharactersValid(event.target.value),
      });
    } else if (name === "customerLastName") {
      this.setState({
        customerLastNameError: !isCharactersValid(event.target.value),
      });
    } else if (name === "email") {
      this.setState({ emailError: event.target.value.length === 0 ? false : !isEmailValid(event.target.value) });
    } else if (name === "phoneNumber") {
      this.setState({
        phoneNumberError: event.target.value.length === 0 ? false : !isPhoneNumberValid(event.target.value),
      });
    } else if (name === "password") {
      this.setState({
        passwordError: event.target.value === "" ? false : !isPasswordValid(event.target.value),
      });
    } else if (name === "panNumber") {
      this.setState({
        panNumberError: !isPanNumberValid(event.target.value),
      });
    } else if (name === "panHolderName") {
      this.setState({
        panHolderNameError: !isCharactersValid(event.target.value),
      });
    } else if (name === "country") {
      this.setState({
        countryError: !isCharactersValid(event.target.value),
      });
    } else if (name === "customerState") {
      this.setState({
        customerStateError: !isCharactersValid(event.target.value),
      });
    } else if (name === "customerBankName") {
      // console.log(event.target.value)
      this.setState({
        customerBankName: dataFour,
        customerBankCode: dataOne,
      });
    } else if (name === "customerBankAccountNumber") {
      this.setState({
        customerBankAccountNumberError: event.target.value === "" ? false : !checkNumeric(event.target.value)
      })
    } else if (name === "customerDiscountAmount") {
      // console.log(event.target.value.length)
      if (event.target.value.length === 0 || event.target.value < 0) {
        this.setState({
          customerDiscountError: true
        })
        // console.log(this.state.customerDiscountError)
      } else {
        this.setState({
          customerDiscountError: false
        })
      }


    }
    else if (name === "totalCreditLimitAdvanceVisible") {
      // console.log(event.target.value.length)
      if (!event.target.value.match(/^(?:\d*\.\d{1,2}|\d+)$/)) {
        this.setState({
          totalCreditLimitAdvanceError: true
        })
        // console.log(this.state.customerDiscountError)
      } else {
        this.setState({
          totalCreditLimitAdvanceError: false
        })
      }
    } else if (name === "totalCreditLimitOtherVisible") {
      // console.log(event.target.value.length)
      if (!event.target.value.match(/^(?:\d*\.\d{1,2}|\d+)$/)) {
        this.setState({
          totalCreditLimitOtherError: true
        })
      } else {
        this.setState({
          totalCreditLimitOtherError: false
        })
      }
    } else if (name === "CustomerBrokerageAmount") {
      // console.log(event.target.value.length)
      if (event.target.value.length === 0 & event.target.value < 0) {
        this.setState({
          customerBrokerageAmountError: true
        })
        // console.log(this.state.customerBrokerageAmountError)
      } else {
        this.setState({
          customerBrokerageAmountError: false
        })
      }
      // } else if (name === "country_code") {
      //   // console.log(event.target.value)
      //   this.setState({
      //     selectedCountryCode: event.target.value
      //   })

    } else if (name === "customerClassCodeK" || name === "customerClassCodeD" || name === "customerClassCodeG") {
      let accountType = [...this.state.accountType]
      if (name === "customerClassCodeK") {
        if (event.target.value === "") {
          delete accountType.find(
            (a) => a.account_type === "K"
          ).account_class_code
        } else {
          accountType.find(
            (a) => a.account_type === "K"
          ).account_class_code = event.target.value.toUpperCase()
        }
        this.setState({
          classCodeKError: event.target.value === "" ? false : !isValidClassCode(event.target.value),
          accountType
        })
      } else if (name === "customerClassCodeD") {
        if (event.target.value === "") {
          delete accountType.find(
            (a) => a.account_type === "D"
          ).account_class_code
        } else {
          accountType.find(
            (a) => a.account_type === "D"
          ).account_class_code = event.target.value.toUpperCase()
        }
        this.setState({
          classCodeDError: event.target.value === "" ? false : !isValidClassCode(event.target.value),
          accountType
        })
      } else if (name === "customerClassCodeG") {
        if (event.target.value === "") {
          delete accountType.find(
            (a) => a.account_type === "G"
          ).account_class_code
        } else {
          accountType.find(
            (a) => a.account_type === "G"
          ).account_class_code = event.target.value.toUpperCase()
        }
        this.setState({
          classCodeGError: event.target.value === "" ? false : !isValidClassCode(event.target.value),
          accountType
        })
      }
    }
    else if (name === "tanNumber") {
      this.setState({
        tanNumberError: event.target.value === "" ? false : !isValidTanNumber(event.target.value)
      })
    }


  };

  initializeData(whichProps) {
    this.handleFetchStateDataFromMaster(this.state.customerSelectedCountryId);
    this.handleFetchAllDealers();
    this.handleFetchAllDistributors();
    this.handleFetchAllowedPaymentMethodsFromMaster();
    this.handleFetchAllBankData();
    this.handleFetchAllTdsData();
    this.handleFetchAllCountryCodes();
    this.handleFetchCustomerTags();
    this.handleFetchCustomerTypes();
    this.setState({
      removeDealerDistributorLoader: false,
      removeAdditionalContactLoader: false,
    });
    // console.log(this.state.selectedCustomerId)
    const customerOperation = this.props.location.pathname.includes("view")
      ? "view"
      : "add";
    if (!this.state.customerOperation) {
      this.setState({ customerOperation }, () =>
        this.state.customerOperation === "add"
          ? null
          : this.state.previousTabValue ?
            this.prepareSelectedCustomerDataToDisplay(whichProps.allCustomers)
            : this.prepareSelectedCustomerDataToDisplay(whichProps.allCustomers)

      );
    }
    if (customerOperation === "view") {
      const id =
        this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
        ];

      this.setState({ selectedCustomerId: id });
      // console.log(this.state.selectedCustomerId)

    }
    if (whichProps?.allCustomers.length > 0) {
      this.setState({
        allCustomers: whichProps.allCustomers,
      });
    }
    if (whichProps?.allCategories.length > 0) {
      this.setState({ allCategories: whichProps.allCategories });
    }
    if (whichProps?.allStates.length > 0) {
      this.setState({ allStates: whichProps.allStates });
    }
    if (whichProps?.allSubscriptions.length > 0) {
      let customerSubscriptions = !this.state.selectedCustomerId
        ? []
        : whichProps.allSubscriptions.filter(
          (subscription) =>
            subscription.customer === this.state.selectedCustomerId
        );
      this.setState({ customerSubscriptions });
    }
  }
  prepareSelectedCustomerDataToDisplay = async (allCustomers) => {
    // console.log("preparing", this.state.selectedCustomerId)
    if (!this.state.fetchCustomerDataLoader) {
      this.setState({
        fetchCustomerDataLoader: true
      })
    }
    if (this.state.customerOperation === "edit") {
      this.setState({
        customerOperation: "view"
      })
    }
    await this.props
      .fetchSelectedCustomerData(Number(this.state.selectedCustomerId))
      .then((response) => {
        // console.log("fetchSelectedCustomerData response : ", response);
        if (response.status === 200 || response.status === 201) {
          let selectedCustomerData = response.data;
          // console.log("selected customer data : ", selectedCustomerData);
          let customerDontHaveGSTIN = !selectedCustomerData?.gst_information
            ?.gstin_number
            ? true
            : false;
          let gstinNumber = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.gstin_number
              ? selectedCustomerData.gst_information.gstin_number
              : null
            : null;
          let gstDataValidated = !selectedCustomerData?.gst_information
            ?.gstin_number
            ? false
            : true;

          let country = "India";
          let customerFirstName = selectedCustomerData.first_name
            ? selectedCustomerData.first_name
            : null;

          let customerLastName = selectedCustomerData.last_name
            ? selectedCustomerData.last_name
            : null;
          let customerDateOfBirth = selectedCustomerData.date_of_birth
            ? selectedCustomerData.date_of_birth
            : null;
          let email = selectedCustomerData.credentials
            ? selectedCustomerData.credentials.email
              ? selectedCustomerData.credentials.email
              : ""
            : "";
          let employeeID = selectedCustomerData.credentials
            ? selectedCustomerData.credentials.employee_id
              ? selectedCustomerData.credentials.employee_id
              : ""
            : "";
          let phoneNumber = selectedCustomerData.credentials
            ? selectedCustomerData.credentials.phone
              ? String(selectedCustomerData.credentials.phone).slice(-10)
              : ""
            : "";
          // let phoneCountryCode = selectedCustomerData?.phoneCountryCode || ""
          let phoneCountryCode = selectedCustomerData.credentials
            ? selectedCustomerData.credentials.country_code && String(selectedCustomerData.credentials.country_code).length > 0
              ? String(selectedCustomerData.credentials.country_code)
              : "91"
            : "";
          // console.log(selectedCustomerData.credentials.phone)
          // console.log(String(selectedCustomerData.credentials.phone).slice(0, String(selectedCustomerData.credentials.phone).length - 10))
          this.setState({
            phoneNumberError: selectedCustomerData.credentials && selectedCustomerData.credentials.phone ?
              phoneNumber.length === 0 ? false : !isPhoneNumberValid(phoneNumber) : false,
          });
          let customerType = selectedCustomerData.customer_type
            ? selectedCustomerData.customer_type
            : null;
          let customerTypeL1 = selectedCustomerData.customer_type1
            ? selectedCustomerData.customer_type1
            : null;
          let customerTypeL1_name = selectedCustomerData.customer_type1_name
            ? selectedCustomerData.customer_type1_name
            : null;
          let customerTypeL2 = selectedCustomerData.customer_type2
            ? selectedCustomerData.customer_type2
            : null;
          let customerTypeL2_name = selectedCustomerData.customer_type2_name
            ? selectedCustomerData.customer_type2_name
            : null;
          let customerTag = selectedCustomerData.customer_tag
            ? selectedCustomerData.customer_tag
            : null;
          let customerTag_name = selectedCustomerData.customer_tag_name
            ? selectedCustomerData.customer_tag_name
            : null;
          let customerDiscountAmount = selectedCustomerData.customer_discount
            ? parseFloat(selectedCustomerData.customer_discount).toFixed(2)
            : null;
          let customerRejected = selectedCustomerData.is_rejected
            ? selectedCustomerData.is_rejected
            : null;
          let totalCreditLimitAdvanceVisible = selectedCustomerData.tcl_advance
            ? parseFloat(selectedCustomerData.tcl_advance).toFixed(2)
            : null;
          let totalCreditLimitAdvance = selectedCustomerData.tcl_advance
            ? parseFloat(selectedCustomerData.tcl_advance).toFixed(2)
            : null;
          let totalCreditLimitOtherVisible = selectedCustomerData.tcl_other
            ? parseFloat(selectedCustomerData.tcl_other).toFixed(2)
            : null;
          let totalCreditLimitOther = selectedCustomerData.tcl_other
            ? parseFloat(selectedCustomerData.tcl_other).toFixed(2)
            : null;
          let effectiveCreditLimitAdvance = selectedCustomerData.ecl_advance
            ? parseFloat(selectedCustomerData.ecl_advance).toFixed(2)
            : null;
          let effectiveCreditLimitOther = selectedCustomerData.ecl_other
            ? parseFloat(selectedCustomerData.ecl_other).toFixed(2)
            : null;
          let totalCreditLimitAdvanceLogDetail = selectedCustomerData.tcl_log_advance
            ? selectedCustomerData.tcl_log_advance
            : null;
          let totalCreditLimitOtherLogDetail = selectedCustomerData.tcl_log_other
            ? selectedCustomerData.tcl_log_other
            : null;
          let effectiveCreditLimitAdvanceLastUpdate = selectedCustomerData.ecl_advance_last_update
            ? selectedCustomerData.ecl_advance_last_update
            : null;
          let effectiveCreditLimitOtherLastUpdate = selectedCustomerData.ecl_other_last_update
            ? selectedCustomerData.ecl_other_last_update
            : null;
          let buyerPayment = selectedCustomerData.tcs_payment_above_50l
            ? selectedCustomerData.tcs_payment_above_50l
            : "";
          let specifiedPerson = selectedCustomerData.tcs_specified_person
            ? selectedCustomerData.tcs_specified_person
            : "";
          let declaration = selectedCustomerData.tcs_declaration
            ? selectedCustomerData.tcs_declaration
            : "";
          let buyerTurnover = selectedCustomerData.tcs_above_10cr
            ? selectedCustomerData.tcs_above_10cr
            : "";
          let accountVerify = selectedCustomerData.credentials
            ? selectedCustomerData.credentials.is_active
              ? selectedCustomerData.credentials.is_active
              : false
            : null;
          let accountApproved = selectedCustomerData.credentials
            ? selectedCustomerData.credentials.is_approved
              ? selectedCustomerData.credentials.is_approved
              : false
            : null;
          let isAccountApproved = selectedCustomerData.credentials
            ? selectedCustomerData.credentials.is_approved
              ? selectedCustomerData.credentials.is_approved
              : false
            : null;
          let legalNameOfBusiness = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.legal_name_of_business
              ? selectedCustomerData.gst_information.legal_name_of_business
              : null
            : null;
          let stateJurisdiction = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.state_jurisdiction
              ? selectedCustomerData.gst_information.state_jurisdiction
              : null
            : null;
          let centreJurisdiction = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.centre_jurisdiction
              ? selectedCustomerData.gst_information.centre_jurisdiction
              : null
            : null;
          let dateOfRegistration = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.date_of_registration
              ? selectedCustomerData.gst_information.date_of_registration
              : null
            : null;
          let constitutionOfBusiness = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.constitution_of_business
              ? selectedCustomerData.gst_information.constitution_of_business
              : null
            : null;
          let natureOfBuisnessActivity = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.nature_of_business_activity
              ? selectedCustomerData.gst_information.nature_of_business_activity
              : null
            : null;
          let taxpayerType = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.taxpayer_type
              ? selectedCustomerData.gst_information.taxpayer_type
              : null
            : null;
          let GSTINStatus = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.gstin_status
              ? selectedCustomerData.gst_information.gstin_status
              : null
            : null;
          let dateOfCancellation = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.date_of_cancellation
              ? selectedCustomerData.gst_information.date_of_cancellation
              : null
            : null;
          let lastUpdatedDate = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.last_updated_date
              ? selectedCustomerData.gst_information.last_updated_date
              : null
            : null;
          let tradeName = selectedCustomerData.gst_information
            ? selectedCustomerData.gst_information.trade_name
              ? selectedCustomerData.gst_information.trade_name
              : null
            : null;
          let gstPrinipalAddress =
            selectedCustomerData.gst_addresses &&
              selectedCustomerData.gst_addresses.length > 0
              ? selectedCustomerData.gst_addresses.find(
                (principalAddress) =>
                  principalAddress.is_default_address === true
              )
              : null;
          let gstAdditionalAddress =
            selectedCustomerData.gst_addresses &&
              selectedCustomerData.gst_addresses.length > 0
              ? selectedCustomerData.gst_addresses.filter(
                (principalAddress) =>
                  principalAddress.is_default_address === false
              )
              : null;
          let principalBuisnessAddressPinCode = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.pincode
              ? gstPrinipalAddress.pincode
              : null;
          let principalBuisnessAddressK_sr_no = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.K_sr_no
              ? gstPrinipalAddress.K_sr_no
              : null;
          let principalBuisnessAddressG_sr_no = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.G_sr_no
              ? gstPrinipalAddress.G_sr_no
              : null;
          let principalBuisnessAddressD_sr_no = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.D_sr_no
              ? gstPrinipalAddress.D_sr_no
              : null;
          let principalBuisnessAddressStateName = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.state_name
              ? gstPrinipalAddress.state_name
              : null;
          let principalBuisnessAddressDistrictName = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.district_name
              ? gstPrinipalAddress.district_name
              : null;
          let principalBuisnessAddressCityName = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.city_name
              ? gstPrinipalAddress.city_name
              : null;
          let principalBuisnessBuildingName = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.building_name
              ? gstPrinipalAddress.building_name
              : null;
          let principalBuisnessDoorNumber = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.door_number
              ? gstPrinipalAddress.door_number
              : null;
          let principalBuisnessFloorNumber = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.floor_number
              ? gstPrinipalAddress.floor_number
              : null;
          let principalBuisnessLocation = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.location
              ? gstPrinipalAddress.location
              : null;
          let principalBuisnessStreet = !gstPrinipalAddress
            ? null
            : gstPrinipalAddress.street
              ? gstPrinipalAddress.street
              : null;
          let panNumber = selectedCustomerData.pan_card_information
            ? selectedCustomerData.pan_card_information.pan_card_number
              ? selectedCustomerData.pan_card_information.pan_card_number
              : null
            : null;
          let panImageUrl = selectedCustomerData.pan_card_information
            ? selectedCustomerData.pan_card_information.pan_card_file
              ? selectedCustomerData.pan_card_information.pan_card_file
              : null
            : null;
          let panFileNameData = gstDataValidated
            ? []
            : selectedCustomerData.pan_card_information
              ? selectedCustomerData.pan_card_information.pan_card_file
                ? selectedCustomerData.pan_card_information.pan_card_file.split(
                  "/"
                )
                : null
              : null;

          let panFileName =
            panFileNameData.length > 0
              ? panFileNameData[Number(panFileNameData.length) - 1]
              : null;
          let companyName = selectedCustomerData.pan_card_information
            ? selectedCustomerData.pan_card_information.company_name
              ? selectedCustomerData.pan_card_information.company_name
              : null
            : null;
          let panHolderName = selectedCustomerData.pan_card_information
            ? selectedCustomerData.pan_card_information.pan_card_holder_name
              ? selectedCustomerData.pan_card_information.pan_card_holder_name
              : null
            : null;

          let tanNumber = selectedCustomerData.tan_no
            ? selectedCustomerData.tan_no
            : "";

          let customersExtraPhoneNumbers =
            selectedCustomerData.extra_phone_numbers
              ? selectedCustomerData.extra_phone_numbers
              : null;

          let accountType = (selectedCustomerData.customer_type === "DISTRIBUTOR" || selectedCustomerData.customer_type === "BROKER") && selectedCustomerData.account_types.length === 0
            ? [{ account_type: "D", account_class_code: null }, { account_type: "K", account_class_code: null }]
            : selectedCustomerData.account_types
              ? selectedCustomerData.account_types
              : null;



          // console.log(accountType)

          let gCode = accountType.find((a) => a.account_type === "G")
          let dCode = accountType.find((a) => a.account_type === "D")
          let kCode = accountType.find((a) => a.account_type === "K")

          let customerClassCodeG = gCode?.account_class_code ? gCode?.account_class_code : ""
          let customerClassCodeD = dCode?.account_class_code ? dCode?.account_class_code : "";
          let customerClassCodeK = kCode?.account_class_code ? kCode?.account_class_code : "";
          let isGBoxChecked = gCode?.account_code ? true : false
          let isDBoxChecked = dCode?.account_code ? true : false
          let isKBoxChecked = kCode?.account_code ? true : false
          // let dCode = 

          console.log(customerClassCodeD, customerClassCodeG, customerClassCodeK)

          let initialAccountTypesLength = selectedCustomerData.account_types.length

          let customerBankCode = selectedCustomerData.bank_code
            ? selectedCustomerData.bank_code
            : "";
          let customerNameOnCheque = selectedCustomerData.cename
            ? selectedCustomerData.cename
            : null;
          let customerCurrencyCode = selectedCustomerData.currency_code
            ? selectedCustomerData.currency_code
            : null;
          let customerCreditLimit = selectedCustomerData.customer_credit_limit
            ? selectedCustomerData.customer_credit_limit
            : null;
          let customerBrokerage = selectedCustomerData.brokerage
            ? selectedCustomerData.customerBrokerage
            : 0;
          let isCustomerApprovalNeeded = selectedCustomerData.is_approval_needed
            ? selectedCustomerData.is_approval_needed
            : false;
          let customerBankAccountNumber = selectedCustomerData.party_bank_acc_no
            ? selectedCustomerData.party_bank_acc_no
            : null;
          let ifsc = selectedCustomerData.ifsc
            ? selectedCustomerData.ifsc
            : null;
          let customerBankName = selectedCustomerData.party_bank_name
            ? selectedCustomerData.party_bank_name
            : null;
          let customerServiceTaxNumber = selectedCustomerData.service_tax_no
            ? selectedCustomerData.service_tax_no
            : null;
          let customerTdsCode = selectedCustomerData?.tds_code_detail
            ? selectedCustomerData.tds_code_detail.tds_code
            : "";
          let customerTdsId = selectedCustomerData?.tds_code_detail
            ? selectedCustomerData.tds_code_detail.id
            : "";
          let customerTdsSection = selectedCustomerData?.tds_code_detail
            ? selectedCustomerData.tds_code_detail.tds_section
            : "";
          let customerTdsValue = selectedCustomerData?.tds_code_detail
            ? `${selectedCustomerData.tds_code_detail.tds_code}-${selectedCustomerData.tds_code_detail.tds_rate}-${selectedCustomerData.tds_code_detail.tds_section}`
            : "";
          let customerTdsName = selectedCustomerData?.tds_code_detail
            ? selectedCustomerData.tds_code_detail.tds_name
            : "";
          let customerTdsRate = selectedCustomerData?.tds_code_detail
            ? selectedCustomerData?.tds_code_detail?.tds_rate
            : "";
          let customerTdsLess = selectedCustomerData.tds_less
            ? selectedCustomerData.tds_less
            : null;
          let customerTitle = selectedCustomerData.title
            ? selectedCustomerData.title
            : null;
          let checkbookFileName = selectedCustomerData.checkbook_image
            ? selectedCustomerData.checkbook_image
            : null;
          // console.log("here", checkbookFileName)
          this.setState({
            gstinNumber,
            gstDataValidated,
            country,
            customerFirstName,
            customerLastName,
            customerDateOfBirth,
            email,
            phoneNumber,
            phoneCountryCode,
            customerType,
            customerTypeL1,
            customerTypeL2,
            customerTypeL1_name,
            customerTypeL2_name,
            customerTag,
            customerTag_name,
            buyerPayment,
            specifiedPerson,
            declaration,
            buyerTurnover,
            accountVerify,
            accountApproved,
            isAccountApproved,
            legalNameOfBusiness,
            stateJurisdiction,
            centreJurisdiction,
            dateOfRegistration,
            constitutionOfBusiness,
            taxpayerType,
            GSTINStatus,
            dateOfCancellation,
            tradeName,
            employeeID,
            customerDontHaveGSTIN,
            customerDiscountAmount,
            customerRejected,
            totalCreditLimitAdvanceVisible,
            totalCreditLimitAdvance,
            totalCreditLimitOtherVisible,
            totalCreditLimitOther,
            effectiveCreditLimitAdvance,
            effectiveCreditLimitOther,
            totalCreditLimitAdvanceLogDetail,
            totalCreditLimitOtherLogDetail,
            effectiveCreditLimitAdvanceLastUpdate,
            effectiveCreditLimitOtherLastUpdate,
            natureOfBuisnessActivity,
            lastUpdatedDate,
            principalBuisnessAddressPinCode,
            principalBuisnessAddressK_sr_no,
            principalBuisnessAddressG_sr_no,
            principalBuisnessAddressD_sr_no,
            principalBuisnessAddressStateName,
            principalBuisnessAddressDistrictName,
            principalBuisnessAddressCityName,
            principalBuisnessFloorNumber,
            principalBuisnessBuildingName,
            principalBuisnessDoorNumber,
            principalBuisnessLocation,
            principalBuisnessStreet,
            panNumber,
            panImageUrl,
            panFileName,
            panHolderName,
            companyName,
            fetchCustomerDataLoader: false,
            gstAdditionalBuisnessAddressData: gstAdditionalAddress,
            accountType,
            gCode,
            kCode,
            dCode,
            customerClassCodeD,
            customerClassCodeG,
            customerClassCodeK,
            isDBoxChecked,
            isGBoxChecked,
            isKBoxChecked,
            tanNumber,
            customersExtraPhoneNumbers,
            customerBankCode,
            customerNameOnCheque,
            customerCurrencyCode,
            customerCreditLimit,
            customerBrokerage,
            isCustomerApprovalNeeded,
            customerBankAccountNumber,
            ifsc,
            customerBankName,
            customerServiceTaxNumber,
            customerTdsCode,
            customerTdsId,
            customerTdsSection,
            customerTdsValue,
            customerTdsName,
            customerTdsRate,
            checkbookFileName,
            customerTdsLess,
            customerTitle,
            selectedCustomerData,
            initialAccountTypesLength
          });
          if (initialAccountTypesLength === 0 && accountType.length === 2) {
            this.setState({
              accountType: [{ account_type: "D", account_class_code: null }, { account_type: "K", account_class_code: null }]
            })
          } else if (initialAccountTypesLength === 0) {
            this.setState({
              accountType: [{ account_type: "D", account_class_code: null }]
            })
          }
          if (customerType === "BROKER") {
            this.prepareCustomerBuyerSelectionList(allCustomers, customerType);
          } else if (customerType === "DEALER" || customerType === "CONSUMER") {
            this.prepareCustomerBuyerSelectionList(
              selectedCustomerData,
              customerType
            );
          }
        } else {
          this.setState({
            fetchCustomerDataLoader: false,
            // openSnackbar: true,
            // snackbarMessage: response?.data?.message
            //   ? response?.data?.message
            //   : response?.data?.details
            //   ? response?.data?.details
            //   : "Error occured while fetching customer details please try again",
            // snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // console.log("error : ", error);
        // this.setState({
        //   fetchCustomerDataLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: error?.response?.data?.message
        //     ? error?.response?.data?.message
        //     : error?.response?.data?.details
        //       ? error?.response?.data?.details
        //       : "Error occured while fetching cutomer details please try again",
        //   snackbarType: "error",
        // });
        if (error && error.response && error?.response?.data)
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
    await this.handleFetchCustomerAdditionalAddresses(
      this.state.selectedCustomerId
    );
    // console.log(this.state.selectedCustomerId)
    await this.handleFetchDealerDistributorData(this.state.selectedCustomerId);
    await this.fetchCustomerSubscription(this.state.selectedCustomerId);
    await this.handleFetchCustomerAdditionalContacts(
      this.state.selectedCustomerId
    );
    await this.getCustomerItemCategory(this.state.selectedCustomerId);
    await this.handleFetchCustomerAllowedPaymentMethod(
      this.state.selectedCustomerId
    );
    await this.handleFetchCustomerBuyers(this.state.selectedCustomerId);
    await this.handleFetchCustomerConsignees(this.state.selectedCustomerId);
    // await this.handleFetchCustomersAllConsigneesList(
    //   this.state.selectedCustomerId
    // );

  };
  handleFetchCustomerAdditionalAddresses = (customerId) => {
    this.props
      .fetchCustomerAdditionalAddresses(customerId)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data)
          let customerAddressBox = response.data;
          this.setState({
            customerAddressBox,
            displayAddMoreAddressButton: true,
          });
          this.handleFetchCityDataFromMaster(customerAddressBox);
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer's additional address data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while fetching customer's additional address data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleFetchAllowedPaymentMethodsFromMaster = () => {
    this.props
      .getAllAllowedPaymentMethod()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allAllowedPaymentMethods: response.data,
            allAllowedPaymentMethodsLoader: false,
          });
        } else {
          this.setState({
            allAllowedPaymentMethodsLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetch all allowed payment method `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   allAllowedPaymentMethodsLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while fetch all allowed payment method `,
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleFetchAllBankData = () => {
    this.props
      .fetchAllBankData()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allBankData: response.data,
            allBankDataLoader: false,
          });
        } else {
          this.setState({
            allBankDataLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetch all bank data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   allBankDataLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while fetch all bank data `,
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleFetchAllTdsData = () => {
    this.props
      .fetchAllTdsData()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allTdsData: response.data,
            allTdsDataLoader: false,
          });
        } else {
          this.setState({
            allTdsDataLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetch all tds data `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   allTdsDataLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while fetch all tds data `,
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleFetchCustomerTypes = () => {
    this.props
      .fetchCustomerTypes()
      .then((response) => {
        // console.log(response)
        if (response.status === 200) {
          this.setState({
            allCustomerTypesData: response.data,
            allCustomerTypesDataLoader: false,
          });
        } else {
          this.setState({
            allCustomerTypesDataLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching customer types `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   allCustomerTypesDataLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while fetching customer types `,
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleFetchCustomerTags = () => {
    this.props
      .fetchCustomerTags()
      .then((response) => {
        // console.log(response)
        if (response.status === 200) {
          this.setState({
            allCustomerTagsData: response.data,
            allCustomerTagsDataLoader: false,
          });
        } else {
          this.setState({
            allCustomerTagsDataLoader: false,
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching customer tags `,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   allCustomerTagsDataLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while fetching customer tags `,
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  RestrictabChange = (tab, name) => {
    // console.log(tab)
    if (this.state.customerOperation === "add") {
      if (tab === 1)
        return true
      else return false


    }
  }
  handleTabChange = (event, tabValue) => {
    // this.setState({
    //   previousTabValue: this.state.tabValue
    // })
    // console.log(this.state.previousTabValue)
    if (this.state.customerOperation === "add" && this.state.tabValue === 2) {
      // console.log("here")
      this.props.history.push("/internalCustomers")
      this.setState({
        customerOperation: "view"
      })
      this.props.history.push(`/internalCustomers/view/${this.state.selectedCustomerId}`)

      this.initializeData(this.props);
      this.prepareSelectedCustomerDataToDisplay(this.props.allCustomers)
      this.props.trigger(this.state.tabValue)
    }

    if (this.state.useNewUi) {
      // console.log("tabValue : ", tabValue);
      // let validData = !this.checkDataValidityForSaveAndTabChange(
      //   this.state.tabValue
      // );
      // if (validData) {
      this.setState({ tabValue });
      if (tabValue === 2) {
        // this.handleAccountTypeInEdit();
        // this.handleFetchCustomersAllConsigneesList(
        //   this.state.selectedCustomerId
        // );
        if (this?.state?.customerType === "BROKER") {
          this.prepareCustomerBuyerSelectionList(
            this?.state?.allCustomers,
            this?.state?.customerType
          );
        } else if (
          this?.state?.customerType === "DEALER" ||
          this?.state?.customerType === "CONSUMER"
        ) {
          this.prepareCustomerBuyerSelectionList(
            this?.state?.selectedCustomerData,
            this?.state?.customerType
          );
        }
      }
    }



    // else {
    //   this.setState({
    //     gstDataFetchLoader: false,
    //     openSnackbar: true,
    //     snackbarMessage:
    //       "Valid data must be provided in the mandatory or mentioned fields!",
    //     snackbarType: "warning",
    //   });
    // }
    // }
    //  else {
    //   let validData = !this.checkDataValidityForSaveAndTabChange(
    //     this.state.tabValue
    //   );
    //   if (validData) {
    //     this.setState({ tabValue });

    //   } else {
    //     this.setState({
    //       gstDataFetchLoader: false,
    //       openSnackbar: true,
    //       snackbarMessage:
    //         "Valid data must be provided in the mandatory or mentioned fields!",
    //       snackbarType: "warning",
    //     });
    //   }
    // }
  };
  // handleCloseSnackbar = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   this.setState({
  //     openSnackbar: false,
  //     snackbarMessage: null,
  //     snackbarType: "",
  //   });
  // };

  checkValidityForExtraPhoneFields = (error, name, index) => {
    // console.log(error)
    if (name === "country_code") {
      this.setState({
        countryCodeError: error
      })


    }


    if (name === "phone_number")
      this.setState({
        extraPhoneNumberError: error
      })
    if (name === "std_code")
      this.setState({
        STDCodeError: error
      })


    if (error.length !== 0) {
      this.setState({
        checkForValidity: true
      })
    }

  }

  checkDataValidityForExtraPhones = () => {
    // console.log(this.state.countryCodeError)
    // console.log(this.state.extraPhoneNumberError)
    // console.log(this.state.STDCodeError)

    var validate = this.state.countryError.find(e => e === true)
    // console.log(validate)


    var validatephone = this.state.extraPhoneNumberError.find(e => e === true)
    // console.log(validatephone)


    let validatestd = this.state.STDCodeError.find(e => e === true)
    // console.log(validatestd)






    // if (validate === undefined && validatephone === undefined && validatestd === undefined)
    //   this.setState({
    //     isAllExtraPhonesDataCorrect: true
    //   })
  }


  checkDataValidityForSaveAndTabChange = (currentTabValue) => {

    if (this.state.useNewUi) {
      if (currentTabValue === 0) {

        if (this.state.customerAddressBox.some(c => c.address == "" || c.pinCode == "")) {
          return true;
        }

        if ((this.state.customerAddressBox[0]?.state_detail?.id && this.state.customerDontHaveGSTIN
          && this.state.selectedAddressState !== this.state.customerAddressBox[0]?.state_detail?.id) ||
          this.state.pincodeErrors.some(c => c)) {
          return true;
        }

        if (!this.state.customerDontHaveGSTIN && (!checkInvalidValue(this.state.selectedStateCode) && this.state.selectedStateCode !== this.state.gstinNumber?.slice(0, 2))) {
          return true;
        }
        if (this.state.customerDontHaveGSTIN) {
          if (this.state.customerOperation === "view") {
            return false;
          } else if (this.state.customerOperation === "edit") {
            return false;
          } else {
            console.log(!(this.state.customerAddressBox.length > 0 && this.state.customerAddressBox[0].state != ""))
            console.log(!this.state.panNumber ||
              !this.state.companyName ||
              !this.state.rawUploadedFile ||
              this.state.panNumberError ||
              this.state.companyNameError ||
              !this.state.address ||
              !(this.state.customerAddressBox.length > 0 && this.state.customerAddressBox[0].state != ""))
            if (
              !this.state.panNumber ||
              !this.state.companyName ||
              !this.state.rawUploadedFile ||
              this.state.panNumberError ||
              this.state.companyNameError ||
              !(this.state.customerAddressBox.length > 0 && this.state.customerAddressBox[0].state != ""
                && this.state.customerAddressBox[0].address !== "")
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
        // else if (this.state.customerOperation === "add") {
        //   this.setState({
        //     isTabTwoChangeAllowed: true
        //   })
        // }

        else {
          if (this.state.customerOperation === "view") {
            return false;
          } else if (this.state.customerOperation === "edit") {
            if (
              !this.state.gstinNumber ||
              this.state.panCardFileTypeError ||
              this.state.validGstinNumber === false ||
              this.state.gstDataValidated === false
            ) {
              return true;
            } else {
              return false;
            }
          }
          // else if (this.state.customerOperation === "add") {
          //   this.setState({
          //     isTabTwoChangeAllowed: true
          //   })
          // }

          else {
            if (
              !this.state.gstinNumber ||
              this.state.validGstinNumber === false ||
              this.state.panCardFileTypeError ||
              this.state.gstDataValidated === false ||
              this.state.gstStatusError
            ) {
              return true;
            } else {
              return false;
            }
          }
        }

      } else if (currentTabValue === 1) {
        if (this.state.customerOperation === "view") {
          return false;
        } else if (this.state.customerOperation === "add") {

          if (
            // !this.state.email ||
            // !this.state.phoneNumber ||
            !this.state.password ||
            this.state.emailError ||
            this.state.passwordError ||
            this.state.phoneNumberError ||
            (!this.state.phoneCountryCode && this.state.phoneNumber != "") ||
            // this.state.classCodeDError ||
            // this.state.classCodeGError ||
            // this.state.classCodeKError ||
            this.state.tanNumberError ||
            // this.state.customerDateOfBirth === undefined ||
            !this.state.customerFirstName ||
            !this.state.customerLastName ||
            // !this.state.customerDateOfBirth ||
            this.state.customerFirstNameError ||
            this.state.customerLastNameError ||
            !this.state.customerType ||
            // !this.state.customerTypeL1 ||
            // !this.state.customerTypeL2 ||
            // !this.state.customerTag ||
            // (this.state.customerBankCode && !this.state.customerTitle) ||
            (this.state.customerBankCode && !this.state.customerNameOnCheque) ||
            (this.state.customerBankCode && !this.state.checkBookUploadFileName) ||
            // !this.state.customerBankCode ||
            // !this.state.customerTdsCode ||
            (this.state.customerBankCode && !this.state.customerBankAccountNumber) ||
            (this.state.customerBankCode && this.state.customerBankAccountNumberError) ||
            (this.state.customerBankCode && this.state.checkBookFileTypeError) ||
            (this.state.customerBankCode && !this.state.ifsc) ||
            (this.state.customerBankCode && this.state.ifscCodeError) ||
            this.state.extraPhoneNumberErrorFromParentCmpnt
          ) {
            return true;
          } else {
            return false;
          }
        } else if (this.state.customerOperation === "edit") {
          // console.log(this.state.phoneCountryCode)
          if (
            // !this.state.email ||
            // !this.state.phoneNumber ||
            this.state.emailError ||
            this.state.phoneNumberError ||
            this.state.passwordError ||
            (!this.state.phoneCountryCode && this.state.phoneNumber != "") ||
            this.state.classCodeDError ||
            this.state.classCodeGError ||
            this.state.classCodeKError ||
            this.state.tanNumberError ||
            // this.state.customerDateOfBirth === undefined ||
            !this.state.customerFirstName ||
            !this.state.customerLastName ||
            // !this.state.customerDateOfBirth ||
            this.state.customerFirstNameError ||
            this.state.customerLastNameError ||
            (this.state.customerBankCode && !this.state.customerNameOnCheque) ||
            (this.state.customerBankCode && !this.state.customerBankAccountNumber) ||
            (this.state.customerBankCode && this.state.customerBankAccountNumberError) ||
            (this.state.customerBankCode && this.state.checkBookFileTypeError) ||
            (this.state.customerBankCode && !this.state.ifsc) ||
            (this.state.customerBankCode && this.state.ifscCodeError) ||
            (this.state.customerBankCode && this.state.checkbookFileName === "" &&
              !this.state.checkBookUploadFileName)
            // (this.state.checkf)
            // ||
            // !this.state.customerTypeL1 ||
            // !this.state.customerTypeL2 ||
            // !this.state.customerTag
            // || !this.state.customerType
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (currentTabValue === 2) {
        if (this.state.customerDiscountError)
          return true
        else if (this.state.customerBrokerageAmountError)
          return true
        else if (this.state.totalCreditLimitAdvanceError)
          return true
        else if (this.state.totalCreditLimitOtherError)
          return true

        else return false;
      } else if (currentTabValue === 3) {
        return false;
      } else if (currentTabValue === 4) {
        return false;
      } else {
        return true;
      }
    } else {
      if (currentTabValue === 0) {
        if (this.state.customerOperation === "view") {
          return false;
        } else if (this.state.customerOperation === "edit") {
          if (
            !this.state.addressLineOne ||
            !this.state.city ||
            !this.state.pinCode ||
            !this.state.customerType ||
            this.state.pinCodeError ||
            this.state.cityError
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            !this.state.gstinNumber ||
            !this.state.addressLineOne ||
            !this.state.city ||
            !this.state.pinCode ||
            !this.state.validGstinNumber ||
            !this.state.customerType ||
            this.state.pinCodeError ||
            this.state.cityError
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else if (currentTabValue === 1) {
        if (this.state.customerOperation === "view") {
          return false;
        } else {
          if (
            this.state.customerDateOfBirth === undefined ||
            !this.state.customerFirstName ||
            !this.state.customerLastName ||
            !this.state.customerDateOfBirth ||
            this.state.customerFirstNameError ||
            this.state.customerLastNameError
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else if (currentTabValue === 2) {
        if (this.state.customerOperation === "add") {
          if (
            // !this.state.email ||
            // !this.state.phoneNumber ||
            !this.state.password ||
            this.state.emailError ||
            this.state.passwordError ||
            this.state.phoneNumberError
          ) {
            return true;
          } else {
            return false;
          }
        } else if (this.state.customerOperation === "edit") {
          if (
            // !this.state.email ||
            // !this.state.phoneNumber ||
            this.state.emailError ||
            this.state.phoneNumberError
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (currentTabValue === 3) {
        return false;
      } else if (currentTabValue === 4) {
        return false;
      } else {
        return true;
      }
    }
  };
  timeout = () => {
    console.log("saved")
  }
  handleSaveAndNextClick = () => {
    if (this.state.useNewUi) {
      let tabValue = this.state.tabValue;
      if (this.state.customerOperation === "edit") {
        this.setState({
          saveLoader: true
        })
        if (tabValue === 0) {
          this.setState({
            saveLoader: true
          })
          if (this.state.customerDontHaveGSTIN) {
            let customerUpdateData = new FormData();
            if (this.state.customerAddressBox.length > 0) {
              this.state.customerAddressBox.map((address, index) => {
                customerUpdateData.append(
                  `customer_addresses[${index}]address`,
                  address.address
                );
                customerUpdateData.append(
                  `customer_addresses[${index}]pincode`,
                  address.pincode
                );
                customerUpdateData.append(
                  `customer_addresses[${index}]district`,
                  address.district
                );
                customerUpdateData.append(
                  `customer_addresses[${index}]is_default_address`,
                  address.is_default_address
                );
                customerUpdateData.append(
                  `customer_addresses[${index}]is_active`,
                  address.is_active
                );
                customerUpdateData.append(
                  `customer_addresses[${index}]country`,
                  address.country
                );
                customerUpdateData.append(
                  `customer_addresses[${index}]state`,
                  address.state
                );
                customerUpdateData.append(
                  `customer_addresses[${index}]city`,
                  address.city
                );
                return customerUpdateData;
              });
            }
            customerUpdateData.append("credentials", []);

            // customerUpdateData.append(
            //   'pan_information.pan_card_holder_name',
            //   this.state.panHolderName
            // );
            // customerUpdateData.append(
            //   'pan_information.pan_card_number',
            //   this.state.panNumber.toUpperCase()
            // );
            // customerUpdateData.append(
            //   'pan_information.company_name',
            //   this.state.companyName
            // );
            // customerUpdateData.append(
            //   'pan_information.pan_card_file',
            //   this.state.rawUploadedFile
            // );
            this.handleUpdateCustomerDetails(
              customerUpdateData,
              this.state.selectedCustomerId,
              "PAN"
            );
          } else {

            // let principalBuisnessAddress = {
            //   building_name: this.state.principalBuisnessBuildingName,
            //   street: this.state.principalBuisnessStreet,
            //   location: this.state.principalBuisnessLocation,
            //   door_number: this.state.principalBuisnessDoorNumber,
            //   state_name: this.state.principalBuisnessAddressStateName,
            //   floor_number: this.state.principalBuisnessFloorNumber,
            //   pincode: this.state.principalBuisnessAddressPinCode,
            //   is_default_address: true,
            // };
            // let gstBuisnessAddressData = this.state
            //   .gstAdditionalBuisnessAddressData;
            // gstBuisnessAddressData.push(principalBuisnessAddress);
            let customerUpdateData = {
              // account_types: this.state.accountType,
              customer_addresses: this.state.customerAddressBox
              // gst_information: {
              //   gstin_number: this.state.gstinNumber,
              //   legal_name_of_business: this.state.legalNameOfBusiness,
              //   trade_name: this.state.tradeName,
              //   state_jurisdiction: this.state.stateJurisdiction,
              //   centre_jurisdiction: this.state.centreJurisdiction,
              //   date_of_registration: this.state.dateOfRegistration,
              //   constitution_of_business: this.state.constitutionOfBusiness,
              //   taxpayer_type: this.state.taxpayerType,
              //   nature_of_business_activity: this.state
              //     .natureOfBuisnessActivity,
              //   gstin_status: this.state.GSTINStatus,
              //   date_of_cancellation: this?.state?.dateOfCancellation || null,
              //   last_updated_date: this.state.lastUpdatedDate,
              // },
              // gst_addresses: gstBuisnessAddressData,
            };
            this.handleUpdateCustomerDetails(
              customerUpdateData,
              this.state.selectedCustomerId,
              "GST"
            );
          }
        } else if (tabValue === 1) {
          this.setState({
            saveLoader: true
          })

          let customerData = new FormData();
          customerData.append("is_update_general", true);
          customerData.append("first_name", this.state.customerFirstName);
          customerData.append("last_name", this.state.customerLastName);
          !checkInvalidValue(this.state.customerDateOfBirth) &&
            customerData.append(
              "date_of_birth",
              moment(this.state.customerDateOfBirth).format("YYYY-MM-DD")
            );

          if (this.state?.customerTypeL1Data)
            customerData.append("customer_type1", this.state?.customerTypeL1Data?.id);
          if (this.state?.customerTypeL2Data)
            customerData.append("customer_type2", this.state?.customerTypeL2Data?.id);
          if (this.state?.customerTagData)
            customerData.append("customer_tag", this.state?.customerTagData?.id);

          customerData.append("credentials.email", this.state.email);
          customerData.append(
            "credentials.phone",
            this.state.phoneNumber != ""
              ? this.state.phoneNumber
              : ""
          );
          customerData.append("credentials.country_code",
            this.state?.phoneCountryCode != ""
              ? this.state?.phoneCountryCode
              : ""
          );
          customerData.append("credentials.password", this.state.password);
          !checkInvalidValue(this.state.customerBankCode) &&
            customerData.append("cename", this.state.customerNameOnCheque);
          customerData.append("tds_less", Number(this.state.customerTdsLess));
          !checkInvalidValue(this.state.customerBankCode) &&
            customerData.append(
              "party_bank_acc_no",
              this.state.customerBankAccountNumber
            );
          !checkInvalidValue(this.state.customerBankCode) &&
            customerData.append("party_bank_name", this.state.customerBankName);

          customerData.append("currency_code", checkInvalidValue(this.state.customerCurrencyCode) ? "INR" : this.state.customerCurrencyCode);
          // if (this.state.customerClassCode)
          customerData.append(
            "account_class_code_erp",
            this.state.customerClassCodeD
          );
          customerData.append(
            "account_class_code_erp_G",
            this.state.customerClassCodeG
          );
          customerData.append(
            "account_class_code_erp_K",
            this.state.customerClassCodeK
          );
          customerData.append(
            "tan_no",
            this.state.tanNumber
          );
          if (this.state.customerTdsId)
            customerData.append("tds_code", this.state.customerTdsId);
          !checkInvalidValue(this.state.customerBankCode) &&
            customerData.append("bank_code", this.state.customerBankCode);
          customerData.append("title", checkInvalidValue(this.state.customerTitle) ? "MR." : this.state.customerTitle);
          !checkInvalidValue(this.state.customerBankCode) &&
            customerData.append(
              "checkbook_image",
              this.state.checkBookRawUploadedFile
            );
          !checkInvalidValue(this.state.customerBankCode) &&
            customerData.append("ifsc", this.state.ifsc);
          // if (this.state.extraPhoneNumbers.length > 0) {
          // }
          // customerData.append("account_types", this.state.accountType)

          if (this.state.postExtraPhone.length > 0)
            this.state.postExtraPhone.map((phone, index) => {
              customerData.append(`extra_phone_numbers[${index}]std_code`,
                phone.std_code,
              );
              customerData.append(`extra_phone_numbers[${index}]country_code`,
                phone.country_code,
              );
              customerData.append(`extra_phone_numbers[${index}]phone_number`,
                phone.phone_number,
              );

            })

          let changePasswordData = {
            password: this.state.password,
            phone: this.state.phoneNumber != "" ?
              String(this.state.phoneNumber)
              : "",
            country_code: this.state.phoneCountryCode != ""
              ? String(this.state?.phoneCountryCode)
              : "",
            employee_id: this?.state?.employeeID
          };
          console.log(this.state.password)
          if (!checkInvalidValue(this.state.password)
            // this.state.password !== null ||
            // this.state.password !== undefined
          ) {
            this.handleCustomerPasswordReset(changePasswordData);
          }
          // console.log("here")
          this.handleUpdateCustomerDetails(
            customerData,
            this.state.selectedCustomerId,
            "General"
          );



        } else if (tabValue === 2) {
          this.setState({
            saveLoader: true
          })
          // console.log(this.state.accountType)
          // console.log(this.state.initialAccountTypesLength)
          // if (this.state.initialAccountTypesLength === 0) {
          //   this.setState({
          //     accountType: { account_type: "D" }
          //   })
          // }
          // console.log(this.state.accountType)

          let customerUpdateData = {
            credentials: {
              is_active: this.state.accountVerify,
              is_approved: this.state.accountApproved,
            },

            account_types: this.state.accountType,
            customer_discount: this.state.customerDiscountAmount,
            // tcl_advance: this.state.totalCreditLimitAdvance,
            // tcl_other: this.state.totalCreditLimitOther,
            // ecl_advance: this.state.effectiveCreditLimitAdvance,
            // ecl_other: this.state.effectiveCreditLimitOther,
            is_approval_needed: this.state.isCustomerApprovalNeeded,
            // customer_credit_limit: this.state.customerCreditLimit,
            customer_brokerage: this.state.customerBrokerage,
            tcs_payment_above_50l: this.state.buyerPayment,
            tcs_specified_person: this.state.specifiedPerson,
            tcs_declaration: this.state.declaration,
            tcs_above_10cr: this.state.buyerTurnover,
          };

          // this.handleUpdateCustomerDetails(
          //   customerUpdateData,
          //   this.state.selectedCustomerId,
          //   "Account"
          // );
          this.props
            .handleUpdateCustomer(customerUpdateData, this.state.selectedCustomerId)
            .then((response) => {

              if (response.status === 200) {

                this.setState({
                  // tabValue: 0,
                  openSnackbar: true,
                  snackbarMessage: `Customer Account details updated successfully`,
                  snackbarType: "success",
                  showAccountSnackBar: false,
                  checkBookImagePreview: "",
                  checkBookRawUploadedFile: "",
                  checkBookUploadFileName: null,
                });
                let run = setInterval(() => {
                  // this.props.history.push('/internalCustomers')
                  this.setState({
                    customerOperation: "view",
                  })
                  this.props.fetchSelectedCustomerData(this.state.selectedCustomerId)
                    .then(response => {
                      this.props.history.push("/internalCustomers");
                      this.props.history.push(`/internalCustomers/view/${this.state.selectedCustomerId}`)
                    })
                  clearInterval(run);
                }, 4000
                )


              } else {
                console.log(response)
                this.setState({
                  openSnackbar: true,
                  snackbarMessage: `Error occured while updating customer Account details`,
                  snackbarType: "error",
                  saveLoader: false
                });
              }
            })
            .catch((error) => {
              //console.log(error.response.data)
              this.setState({
                // openSnackbar: true,
                // snackbarMessage: error?.response?.data?.customer_discount ? `${Object.keys(error?.response?.data)} : ${error?.response?.data?.customer_discount[0]}` :
                //   error?.response?.data?.customer_credit_limit ? `${Object.keys(error?.response?.data)} : ${error?.response?.data?.customer_credit_limit[0]}` :
                //     "Error occured while updating customer Account details",
                // snackbarType: "error",
                saveLoader: false,
              });

              this.handleOpenSnackbar(
                this.getSnackbarErrorMessage(checkApiError(error?.response?.data)), //this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                "error"
              );
              setTimeout(() => {
                this.handleCloseSnackbar();
              }, 4000);
            });


        }
        // if (this.state.tabValue > 2) {

        // }


        // else if (tabValue === 4) {
        //   this.props.history.push("/internalCustomers");
        // } else {
        // }
      } else {
        console.log("addd")
        if (tabValue === 1) {
          this.setState({
            saveLoader: true
          })
          if (this.state.customerDontHaveGSTIN) {
            let state = this.state.state
            let city = this.state.city
            this.setState({
              city, state
            })
            let customerData = new FormData();
            customerData.append("first_name", this.state.customerFirstName);
            customerData.append("last_name", this.state.customerLastName);
            !checkInvalidValue(this.state.customerDateOfBirth) &&
              customerData.append(
                "date_of_birth",
                moment(this.state.customerDateOfBirth).format("YYYY-MM-DD")
              );
            customerData.append("customer_type", this.state.customerType);
            if (this.state?.customerTypeL1Data)
              customerData.append("customer_type1", this.state?.customerTypeL1Data?.id);
            if (this.state?.customerTypeL2Data)
              customerData.append("customer_type2", this.state?.customerTypeL2Data?.id);
            if (this.state?.customerTagData)
              customerData.append("customer_tag", this.state?.customerTagData?.id);
            customerData.append(
              "customer_discount",
              Number(this.state.customerDiscountAmount)
            );
            // customerData.append(
            //   "tcl_advance",
            //   Number(this.state.totalCreditLimitAdvance)
            // );
            // customerData.append(
            //   "tcl_other",
            //   Number(this.state.totalCreditLimitOther)
            // );
            // customerData.append(
            //   "ecl_advance",
            //   Number(this.state.effectiveCreditLimitAdvance)
            // );
            // customerData.append(
            //   "ecl_other",
            //   Number(this.state.effectiveCreditLimitOther)
            // );
            // console.log(this.state.customerAddressBox)
            if (this.state.customerAddressBox.length > 0) {
              this.state.customerAddressBox.map((address, index) => {
                customerData.append(
                  `customer_addresses[${index}]address`,
                  address.address
                );
                customerData.append(
                  `customer_addresses[${index}]pincode`,
                  address.pincode
                );
                customerData.append(
                  `customer_addresses[${index}]district`,
                  address.district
                );
                customerData.append(
                  `customer_addresses[${index}]is_default_address`,
                  address.is_default_address
                );
                customerData.append(
                  `customer_addresses[${index}]is_active`,
                  address.is_active
                );
                customerData.append(
                  `customer_addresses[${index}]country`,
                  address.country
                );
                customerData.append(
                  `customer_addresses[${index}]state`,
                  address.state
                );
                customerData.append(
                  `customer_addresses[${index}]city`,
                  address.city
                );
                return customerData;
              });
            }
            customerData.append("credentials.email", this.state.email);
            customerData.append(
              "credentials.phone",
              this.state.phoneNumber != ""
                ? this.state.phoneNumber
                : ""
            );
            customerData.append("credentials.country_code",
              this.state?.phoneCountryCode != ""
                ? this.state?.phoneCountryCode
                : ""
            );
            customerData.append("credentials.password", this.state.password);

            customerData.append(
              "pan_information.pan_card_holder_name",
              this.state.panHolderName
            );
            customerData.append(
              "pan_information.pan_card_number",
              this.state.panNumber.toUpperCase()
            );
            customerData.append(
              "pan_information.company_name",
              this.state.companyName
            );
            customerData.append(
              "pan_information.pan_card_file",
              this.state.rawUploadedFile
            );

            if (this.state.extraPhoneNumbers.length > 0)
              this.state.extraPhoneNumbers.map((phone, index) => {
                customerData.append(`extra_phone_numbers[${index}]std_code`,
                  phone.std_code,
                );
                customerData.append(`extra_phone_numbers[${index}]country_code`,
                  phone.country_code,
                );
                customerData.append(`extra_phone_numbers[${index}]phone_number`,
                  phone.phone_number,
                );

              })

            // including mannual input fields
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("cename", this.state.customerNameOnCheque);
            customerData.append("tds_less", this.state.customerTdsLess);
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append(
                "party_bank_acc_no",
                this.state.customerBankAccountNumber
              );
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("party_bank_name", this.state.customerBankName);
            customerData.append(
              "service_tax_no",
              this.state.customerServiceTaxNumber
            );
            customerData.append(
              "currency_code",
              checkInvalidValue(this.state.customerCurrencyCode) ? "INR" : this.state.customerCurrencyCode
            );
            customerData.append(
              "tan_no",
              this.state.tanNumber
            );
            customerData.append(
              "account_class_code_erp",
              this.state.customerClassCodeD
            );
            customerData.append(
              "account_class_code_erp_G",
              this.state.customerClassCodeG
            );
            customerData.append(
              "account_class_code_erp_K",
              this.state.customerClassCodeK
            );
            if (this.state.customerTdsId)
              customerData.append("tds_code", this.state.customerTdsId);
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("bank_code", this.state.customerBankCode);
            customerData.append("title", checkInvalidValue(this.state.customerTitle) ? "MR." : this.state.customerTitle);
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append(
                "checkbook_image",
                this.state.checkBookRawUploadedFile
              );
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("ifsc", this.state.ifsc);
            console.log("customerData : ", this.state.tabValue, customerData);

            this.props
              .handleCreateCustomer(customerData)
              .then((response) => {
                if (response.status === 201) {
                  this.setState({
                    tabValue: tabValue + 1,
                    previousTabValue: this.state.tabValue === 2 ? this.state.tabValue : null,
                    selectedCustomerId: response?.data.id,
                    openSnackbar: true,
                    snackbarMessage:
                      "Customer registration with pan details is succesful",
                    snackbarType: "success",
                    saveLoader: false,
                  });
                  // this.handleFetchCustomersAllConsigneesList(
                  //   response?.data.id
                  // );
                } else {
                  this.setState({
                    gstDataFetchLoader: false,
                    saveLoader: false,
                  });
                }
              })
              .catch((error) => {
                console.log(error)
                let errorData =
                  (error?.response?.data?.credentials
                    ? error?.response?.data?.credentials.email
                      ? " : " + error?.response?.data?.credentials.email[0]
                      : ""
                    : "") +
                  (error?.response?.data?.credentials
                    ? error?.response?.data?.credentials?.phone
                      ? " : " + error?.response?.data?.credentials?.phone[0]
                      : ""
                    : "") +
                  (error?.response?.data?.pan_card_file
                    // ? error?.response?.data.credentials.phone
                    ? " : Pan Card " + error?.response?.data?.pan_card_file[0]
                    : ""
                  );
                this.setState({
                  gstDataFetchLoader: false,
                  // openSnackbar: true,
                  // snackbarMessage: `Error occured while cutomer registration ${errorData}`,
                  // snackbarType: "error",
                  saveLoader: false,
                });
                this.handleOpenSnackbar(
                  this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                  "error"
                );
                setTimeout(() => {
                  this.handleCloseSnackbar();
                }, 4000);
              });
          } else {
            let customerData = new FormData();
            customerData.append("first_name", this.state.customerFirstName);
            customerData.append("last_name", this.state.customerLastName);
            !checkInvalidValue(this.state.customerDateOfBirth) &&
              customerData.append(
                "date_of_birth",
                moment(this.state.customerDateOfBirth).format("YYYY-MM-DD")
              );
            customerData.append("customer_type", this.state.customerType);
            if (this.state?.customerTypeL1Data)
              customerData.append("customer_type1", this.state?.customerTypeL1Data?.id);
            if (this.state?.customerTypeL2Data)
              customerData.append("customer_type2", this.state?.customerTypeL2Data?.id);
            if (this.state?.customerTagData)
              customerData.append("customer_tag", this.state?.customerTagData?.id);
            customerData.append(
              "customer_discount",
              Number(this.state.customerDiscountAmount)
            );
            // customerData.append(
            //   "tcl_advance",
            //   Number(this.state.totalCreditLimitAdvance)
            // );
            // customerData.append(
            //   "tcl_other",
            //   Number(this.state.totalCreditLimitOther)
            // );
            // customerData.append(
            //   "ecl_advance",
            //   Number(this.state.effectiveCreditLimitAdvance)
            // );
            // customerData.append(
            //   "ecl_other",
            //   Number(this.state.effectiveCreditLimitOther)
            // );
            customerData.append("brokerage", 0);
            customerData.append("credentials.email", this.state.email);
            customerData.append(
              "credentials.phone",
              this.state.phoneNumber != ""
                ? this.state.phoneNumber
                : ""
            );
            customerData.append("credentials.country_code",
              this.state?.phoneCountryCode != ""
                ? this.state?.phoneCountryCode
                : ""
            );
            customerData.append("credentials.password", this.state.password);
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("cename", this.state.customerNameOnCheque);
            customerData.append("tds_less", this.state.customerTdsLess);
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append(
                "party_bank_acc_no",
                this.state.customerBankAccountNumber
              );
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("party_bank_name", this.state.customerBankName);
            customerData.append(
              "service_tax_no",
              this.state.customerServiceTaxNumber
            );
            customerData.append(
              "currency_code",
              checkInvalidValue(this.state.customerCurrencyCode) ? "INR" : this.state.customerCurrencyCode
            );
            customerData.append(
              "tan_no",
              this.state.tanNumber
            );
            customerData.append(
              "account_class_code_erp",
              this.state.customerClassCodeD
            );
            customerData.append(
              "account_class_code_erp_G",
              this.state.customerClassCodeG
            );
            customerData.append(
              "account_class_code_erp_K",
              this.state.customerClassCodeK
            );
            if (this.state.customerTdsId)
              customerData.append("tds_code", this.state.customerTdsId);
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("bank_code", this.state.customerBankCode);
            customerData.append("title", checkInvalidValue(this.state.customerTitle) ? "MR." : this.state.customerTitle);
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append(
                "checkbook_image",
                this.state.checkBookRawUploadedFile
              );
            !checkInvalidValue(this.state.customerBankCode) &&
              customerData.append("ifsc", this.state.ifsc);

            if (this.state.extraPhoneNumbers.length > 0)
              this.state.extraPhoneNumbers.map((phone, index) => {
                customerData.append(`extra_phone_numbers[${index}]std_code`,
                  phone.std_code,
                );
                customerData.append(`extra_phone_numbers[${index}]country_code`,
                  phone.country_code,
                );
                customerData.append(`extra_phone_numbers[${index}]phone_number`,
                  phone.phone_number,
                );

              })

            customerData.append(
              "gst_information.trade_name",
              this.state.tradeName
            );
            customerData.append(
              "gst_information.gstin_number",
              this.state.gstinNumber
            );
            customerData.append(
              "gst_information.legal_name_of_business",
              this.state.legalNameOfBusiness
            );
            customerData.append(
              "gst_information.state_jurisdiction",
              this.state.stateJurisdiction
            );
            customerData.append(
              "gst_information.centre_jurisdiction",
              this.state.centreJurisdiction
            );
            customerData.append(
              "gst_information.date_of_registration",
              this.state.dateOfRegistration
            );
            customerData.append(
              "gst_information.constitution_of_business",
              this.state.constitutionOfBusiness
            );
            customerData.append(
              "gst_information.taxpayer_type",
              this.state.taxpayerType
            );
            customerData.append(
              "gst_information.nature_of_business_activity",
              this.state?.natureOfBuisnessActivity?.toString()
            );
            customerData.append(
              "gst_information.gstin_status",
              this.state.GSTINStatus
            );
            customerData.append(
              "gst_information.date_of_cancellation",
              this.state.dateOfCancellation || ""
            );
            customerData.append(
              "gst_information.last_updated_date",
              this.state.lastUpdatedDate
            );
            if (this.state.customerAddressBox.length > 0) {
              this.state.customerAddressBox.map((address, index) => {
                customerData.append(
                  `customer_addresses[${index}]address`,
                  address.address
                );
                customerData.append(
                  `customer_addresses[${index}]pincode`,
                  address.pincode
                );
                customerData.append(
                  `customer_addresses[${index}]district`,
                  address.district
                );
                customerData.append(
                  `customer_addresses[${index}]is_default_address`,
                  address.is_default_address
                );
                customerData.append(
                  `customer_addresses[${index}]is_active`,
                  address.is_active
                );
                customerData.append(
                  `customer_addresses[${index}]country`,
                  address.country
                );
                customerData.append(
                  `customer_addresses[${index}]state`,
                  address.state
                );
                customerData.append(
                  `customer_addresses[${index}]city`,
                  address.city
                );
                return customerData;
              });
            }

            if (this.state.gstAdditionalBuisnessAddressData.length > 0) {
              // console.log(
              //   "Anshuman",
              //   this.state.gstAdditionalBuisnessAddressData
              // );
              this.state.gstAdditionalBuisnessAddressData.map(
                (gstAddress, index) => {
                  customerData.append(
                    `gst_addresses[${index}]building_name`,
                    gstAddress.building_name
                  );
                  customerData.append(
                    `gst_addresses[${index}]street`,
                    gstAddress.street
                  );
                  customerData.append(
                    `gst_addresses[${index}]location`,
                    gstAddress.location
                  );
                  customerData.append(
                    `gst_addresses[${index}]door_number`,
                    gstAddress.door_number
                  );
                  customerData.append(
                    `gst_addresses[${index}]floor_number`,
                    gstAddress.floor_number
                  );
                  customerData.append(
                    `gst_addresses[${index}]pincode`,
                    gstAddress.pincode
                  );
                  customerData.append(
                    `gst_addresses[${index}]district_name`,
                    gstAddress.district_name || gstAddress.city_name
                  );
                  customerData.append(
                    `gst_addresses[${index}]city_name`,
                    gstAddress.city_name || gstAddress.district_name
                  );
                  customerData.append(
                    `gst_addresses[${index}]state_name`,
                    gstAddress.state_name
                  );
                  customerData.append(
                    `gst_addresses[${index}]is_default_address`,
                    gstAddress.is_default_address
                  );
                  return customerData;
                }
              );
            }
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]building_name`,
              this.state.principalBuisnessBuildingName
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]street`,
              this.state.principalBuisnessStreet
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]location`,
              this.state.principalBuisnessLocation
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]door_number`,
              this.state.principalBuisnessDoorNumber
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]floor_number`,
              this.state.principalBuisnessFloorNumber
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]pincode`,
              this.state.principalBuisnessAddressPinCode
            );
            // customerData.append(
            //   `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
            //   }]district`,
            //   this.state.principalBuisnessAddressPinCode
            // );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]state_name`,
              this.state.principalBuisnessAddressStateName
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]district_name`,
              this.state.principalBuisnessAddressDistrictName ||
              this.state.principalBuisnessAddressCityName
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]city_name`,
              this.state.principalBuisnessAddressCityName ||
              this.state.principalBuisnessAddressDistrictName
            );
            customerData.append(
              `gst_addresses[${Number(this.state.gstAdditionalBuisnessAddressData.length) + 1
              }]is_default_address`,
              true
            );
            console.log(this.state.tabValue,)
            this.props
              .handleCreateCustomer(customerData)
              .then((response) => {
                if (response.status === 201) {
                  this.setState({
                    tabValue: tabValue + 1,
                    selectedCustomerId: response.data.id,
                    previousTabValue: this.state.tabValue === 1 ? this.state.tabValue : null,
                    openSnackbar: true,
                    snackbarMessage:
                      "Customer registration with GSTIN details is successful",
                    snackbarType: "success",
                    showAccountSnackBar: false,
                    saveLoader: false,
                  });

                  // this.handleFetchCustomersAllConsigneesList(
                  //   this.state.response.data.id
                  // );
                } else {
                  this.setState({
                    gstDataFetchLoader: false,
                    openSnackbar: true,
                    snackbarMessage: response.data.data,
                    snackbarType: "error",
                    saveLoader: false,
                  });
                }
              })
              .catch((error) => {
                // console.log(error?.response?.status)
                // let errorData = []
                // if (error?.response?.status === 400) {
                //   if (Object.keys(error?.response?.data).includes("credentials")) {
                //     console.log("here")
                //     console.log(error?.response?.data)
                //     Object.entries(error?.response?.data).forEach(([key, value]) => {
                //       // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                //       if (key === "credentials") {
                //         Object.entries(error?.response?.data.credentials).forEach(([ikey, ivalue]) => {
                //           errorData.push(`${ikey} : ${ivalue}`)
                //         })
                //       } else {
                //         errorData.push(`${key} : ${value}`)
                //       }
                this.handleOpenSnackbar(
                  this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                  "error"
                );
                setTimeout(() => {
                  this.handleCloseSnackbar();
                }, 4000);
              })
            // }

            // console.log(errorData)
            // errorData.concat(checkApiError(error?.response?.data))
            // this.handleOpenSnackbar(
            //   this.handleSnackbarMessageBox(errorData),
            //   "error"
            // );
            this.setState({
              // openSnackbar: true,
              // snackbarMessage:
              //   "Something went wrong while creating customer",
              // snackbarType: "error",
              gstDataFetchLoader: false,
              saveLoader: false,
            });
            // setTimeout(() => {
            //   this.handleCloseSnackbar();
            // }, 4000);
            //   // console.log(Object.entries(error?.response?.data))
            //   Object.entries(error?.response?.data).forEach(([key, value]) => {
            //     // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
            //     errorData += `${key} :- ${value}` + "\n"
            //   });
            //   console.log(errorData)
            //   this.setState({
            //     gstDataFetchLoader: false,
            //     openSnackbar: true,
            //     snackbarMessage:
            //       errorData,
            //     snackbarType: "error",
            //     saveLoader: false,
            //   });
            // }
            // if (error?.response?.data?.credentials?.email?.length > 0) {
            //   this.setState({
            //     gstDataFetchLoader: false,
            //     openSnackbar: true,
            //     snackbarMessage:
            //       error?.response?.data?.credentials?.email[0],
            //     snackbarType: "error",
            //     saveLoader: false,
            //   });
            // } else if (
            //   error?.response?.data?.credentials?.phone?.length > 0
            // ) {
            //   this.setState({
            //     gstDataFetchLoader: false,
            //     openSnackbar: true,
            //     snackbarMessage:
            //       error?.response?.data?.credentials?.phone[0],
            //     snackbarType: "error",
            //     saveLoader: false,
            //   });
          }
          // else {
          //   console.log("here")
          //   this.setState({
          //     openSnackbar: true,
          //     snackbarMessage:
          //       "Something went wrong while creating customer",
          //     snackbarType: "error",
          //     gstDataFetchLoader: false,
          //     saveLoader: false,
          //   });
          // }
          // });
          // }
        } else if (tabValue === 2) {
          this.setState({
            saveLoader: true
          })

          // console.log(this.state.accountType)
          let customerUpdateData = {
            credentials: {
              is_active: this.state.accountVerify,
              is_approved: this.state.accountApproved,
            },
            customer_discount: this.state.customerDiscountAmount,
            // tcl_advance: this.state.totalCreditLimitAdvance,
            // tcl_other: this.state.totalCreditLimitOther,
            // ecl_advance: this.state.effectiveCreditLimitAdvance,
            // ecl_other: this.state.effectiveCreditLimitOther,
            // customer_credit_limit: this.state.customerCreditLimit,
            brokerage: this.state.customerBrokerage,
            tcs_payment_above_50l: this.state.buyerPayment,
            tcs_specified_person: this.state.specifiedPerson,
            tcs_declaration: this.state.declaration,
            tcs_above_10cr: this.state.buyerTurnover,
            account_types: this.state.accountType
          };
          // console.log(this.state.snackbarType, this.state.openSnackbar)
          this.props
            .handleUpdateCustomer(
              customerUpdateData,
              this.state.selectedCustomerId
            )
            .then((response) => {
              // console.log(response.message)
              if (response.status === 200) {
                // this.setState({
                //   checkAccountSnackBar: true
                // })
                // if (this.state.showAccountSnackBar)
                this.setState({
                  // customerOperation: "view",
                  openSnackbar: true,
                  snackbarMessage: `Customer ${this.state.customerOperation === "add"
                    ? " created"
                    : " edited"
                    } successfully.`,
                  snackbarType: "success",
                  tabValue: tabValue,
                  showAccountSnackBar: false,
                  saveLoader: false,
                  checkBookImagePreview: "",
                  checkBookRawUploadedFile: "",
                  checkBookUploadFileName: null,
                });
                let run = setInterval(() => {
                  // console.log("haha")
                  this.props.history.push('/internalCustomers')
                  clearInterval(run);
                }, 4000
                )





              } else {
                console.log("here")
                this.setState({
                  gstDataFetchLoader: false,
                  openSnackbar: true,
                  snackbarMessage: `Error occured while ${this.state.customerOperation === "add"
                    ? " creating customer "
                    : " editing customer details"
                    }.`,
                  snackbarType: "error",
                  saveLoader: false,
                });
              }
            })
            .catch((error) => {
              // console.log(error)
              this.handleOpenSnackbar(
                this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                "error"
              );
              setTimeout(() => {
                this.handleCloseSnackbar();
              }, 4000);
              this.setState({
                gstDataFetchLoader: false,
                // openSnackbar: true,
                // snackbarMessage: `Error occured while ${this.state.customerOperation === "add"
                //   ? " creating customer "
                //   : " editing customer details"
                //   }.`,
                // snackbarType: "error",
                saveLoader: false,
              });

            });
        } else if (tabValue === 4) {
          this.props.history.push("/internalCustomers");
        } else {
          this.setState({ tabValue: tabValue + 1 });
        }
      }
    } else {
      let tabValue = this.state.tabValue;
      if (this.state.customerOperation === "edit") {
        if (tabValue === 0) {
          // console.log("this")
          let customerUpdateData = {
            credentials: {},
            city: this.state.city,
            pincode: Number(this.state.pinCode),
            district: this.state.district,
            address_line_1: this.state.addressLineOne,
            address_line_2: this.state.addressLineTwo,
            address_line_3: this.state.addressLineThree,
            customer_type: this.state.customerType,
          };
          this.handleUpdateCustomerDetails(
            customerUpdateData,
            this.state.selectedCustomerId,
            "General"
          );
        } else if (tabValue === 1) {
          let customerUpdateData = {
            credentials: {},
            first_name: this.state.customerFirstName,
            last_name: this.state.customerLastName,
            date_of_birth: moment(this.state.customerDateOfBirth).format(
              "YYYY-MM-DD"
            ),
          };
          this.handleUpdateCustomerDetails(
            customerUpdateData,
            this.state.selectedCustomerId,
            "Personnal"
          );
        } else if (tabValue === 2) {
          let customerUpdateData = {
            credentials: {
              email: this.state.email,
              phone: (this.state.phoneNumber),
            },
          };
          // let changePasswordData = {
          //   id: Number(this.state.customerCredentialId),
          //   password: this.state.password,
          //   old_password: this.state.oldPassword,
          // };
          this.handleUpdateCustomerDetails(
            customerUpdateData,
            this.state.selectedCustomerId,
            "Personnal"
          );
          // if (
          //   this.state.oldPassword !== null ||
          //   this.state.oldPassword !== undefined
          // ) {
          //   this.handleCustomerPasswordReset(changePasswordData);
          // }
        } else if (tabValue === 3) {
          let customerUpdateData = {
            credentials: {
              is_active: this.state.accountVerify,
              is_approved: this.state.accountApproved,
            },
            // brokerage: Number(this.state.brokeragePercent),
          };
          this.handleUpdateCustomerDetails(
            customerUpdateData,
            this.state.selectedCustomerId,
            "Account"
          );
        } else if (tabValue === 4) {
          this.props.history.push("/internalCustomers");
        } else {
        }
      } else {
        if (tabValue === 2) {
          // console.log("adding customer")
          let customerData = {
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phoneNumber,
            first_name: this.state.customerFirstName,
            last_name: this.state.customerLastName,
            date_of_birth: moment(this.state.customerDateOfBirth).format(
              "YYYY-MM-DD"
            ),
            country: this.state.country,
            state: this.state.customerState,
            city: this.state.city,
            pincode: Number(this.state.pinCode),
            district: this.state.district,
            address_line_1: this.state.addressLineOne,
            address_line_2: this.state.addressLineTwo,
            address_line_3: this.state.addressLineThree,
            legal_name_of_business: this.state.legalNameOfBusiness,
            GSTIN_no: this.state.gstinNumber,
            centre_jurisdiction: this.state.centreJurisdiction,
            state_jurisdiction: this.state.stateJurisdiction,
            date_of_registration: this.state.dateOfRegistration,
            constitution_of_business: this.state.constitutionOfBusiness,
            taxpayer_type: this.state.taxpayerType,
            GSTIN_or_UIN_Status: this.state.GSTINStatus,
            date_of_cancellation: this.state.dateOfCancellation,
            field_visit_conducted: this.state.fieldVisitConducted,
            trade_name: this.state.tradeName,
            customer_type: this.state.customerType,
            tcs_payment_above_50l: this.state.buyerPayment,
            tcs_specified_person: this.state.specifiedPerson,
            tcs_declaration: this.state.declaration,
            tcs_above_10cr: this.state.buyerTurnover,
          };
          this.props
            .handleCreateCustomer()
            .then((response) => {
              if (response.data.status === "success") {
                this.setState({
                  tabValue: tabValue + 1,
                  selectedCustomerId: response.data.msg.id,
                  selectedCustomerDealerDistributorCreateId:
                    response.data.msg.credential.id,
                });
              } else {
                this.setState({
                  gstDataFetchLoader: false,
                  openSnackbar: true,
                  snackbarMessage: response.data.data,
                  snackbarType: "error",
                  saveLoader: false
                });
              }
            })
            .catch((error) => {
              // this.setState({
              //   gstDataFetchLoader: false,
              //   openSnackbar: true,
              //   snackbarMessage: error?.response?.data.msg.error,
              //   snackbarType: "error",
              //   saveLoader: false
              // });
              this.handleOpenSnackbar(
                this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                "error"
              );
              setTimeout(() => {
                this.handleCloseSnackbar();
              }, 4000);
            });
        } else if (tabValue === 3) {
          let customerUpdateData = {
            credentials: {
              is_active: this.state.accountVerify,
              is_approved: this.state.accountApproved,
            },
            // brokerage: this.state.brokeragePercent,
          };

          // this.props
          //   .handleUpdateCustomer(
          //     customerUpdateData,
          //     this.state.selectedCustomerId
          //   )
          //   .then((response) => {
          //     console.log(response.message)
          //     if (response.status === 200) {
          //       this.setState({
          //         tabValue: tabValue + 1,
          //         openSnackbar: true,
          //         snackbarMessage: `Customer ${this.state.customerOperation === "add"
          //           ? " created"
          //           : " edited"
          //           } successfully.`,
          //         snackbarType: "success",
          //       });
          //     } else {
          //       this.setState({
          //         gstDataFetchLoader: false,
          //         openSnackbar: true,
          //         snackbarMessage: `Error occured while ${this.state.customerOperation === "add"
          //           ? " creating customer "
          //           : " editing customer details"
          //           }.`,
          //         snackbarType: "error",
          //       });
          //     }
          //   })
          //   .catch((error) => {
          //     this.setState({
          //       gstDataFetchLoader: false,
          //       openSnackbar: true,
          //       snackbarMessage: `Error occured while ${this.state.customerOperation === "add"
          //         ? " creating customer "
          //         : " editing customer details"
          //         }.`,
          //       snackbarType: "error",
          //     });
          // });
        } else if (tabValue === 4) {
          this.props.history.push("/internalCustomers");
        }
        else {
          // console.log("here too")
          this.setState({ tabValue: tabValue + 1 });
        }
      }
    }
  };

  handleUpdateCustomerDetails = (customerUpdateData, customerId, dataFor) => {
    // console.log(customerUpdateData, customerId, dataFor)
    // console.log(this.state.tabValue)
    // console.log("value change")
    this.props
      .handleUpdateCustomer(customerUpdateData, customerId)
      .then((response) => {
        console.log(response)
        console.log(this.state.tabValue)
        if (response.status === 200) {
          // console.log(this.state.customerOperation)
          // console.log(this.state.tabValue)
          this.setState({
            tabValue: this.state.tabValue,
            previousTabValue: this.state.tabValue === 2 ? this.state.tabValue : null,
            openSnackbar: true,
            snackbarMessage: `Customer ${dataFor} details updated successfully`,
            snackbarType: "success",
            customerOperation: "view",
            fetchCustomerDataLoader: true,
            saveLoader: false,
            checkBookImagePreview: "",
            checkBookRawUploadedFile: "",
            checkBookUploadFileName: null,
          });
          let run = setInterval(() => {
            this.prepareSelectedCustomerDataToDisplay(this.props.allCustomers)
            // this.props.fetchSelectedCustomerData(this.state.selectedCustomerId)
            //   .then(response => {
            //     this.setState({
            //       fetchCustomerDataLoader: false
            //     })
            // console.log("here")
            // this.props.history.push("/internalCustomers");
            // this.props.history.push(`/internalCustomers/view/${this.state.selectedCustomerId}`)
            // })
            clearInterval(run);
          }, 3000)
          // console.log(this.state.openSnackbar, this.state.tabValue, this.state.snackbarType, this.state.snackbarMessage)
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while updating customer ${dataFor} details`,
            snackbarType: "error",
            saveLoader: false,

          });
        }
      })
      .catch((error) => {
        //console.log(error?.response?.data)
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while updating customer ${dataFor} details`,
        //   snackbarType: "error",
        //   saveLoader: false,
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.setState({ saveLoader: false })
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  openRemoveCustomerConfirmationDialog = (dialogBox = '') => {
    this.setState({ openRemoveCustomerDialog: true, openRemoveCustomerDialogText: dialogBox });
  };
  handleRemoveCustomerDialogClose = () => {
    this.setState({ openRemoveCustomerDialog: false, openRemoveCustomerDialogText: '' });
  };
  handleRemoveCustomerClick = (selectedCustomerId) => {
    this.setState({ customerRemoveLoader: true });
    this.props
      .deleteCustomer(selectedCustomerId)
      .then((response) => {
        if (response.status === 200) {
          this.setState(
            {
              customerRemoveLoader: false,
              openRemoveCustomerDialog: false,
              openRemoveCustomerDialogText: '',
              openSnackbar: true,
              snackbarMessage: "Customer inactive successfully",
              snackbarType: "success",
            });
          setTimeout(() => {
            this.props.history.push("/internalCustomers")
          }, 3000)
        } else {
          this.setState({
            customerRemoveLoader: false,
            openSnackbar: true,
            snackbarMessage: "Error occured while deleting customer",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({ customerRemoveLoader: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleRejectCustomerClick = (selectedCustomerId) => {
    this.setState({ customerRemoveLoader: true });
    this.props
      .deleteCustomer(selectedCustomerId, 'reject')
      .then((response) => {
        if (response.status === 200) {
          this.setState(
            {
              customerRemoveLoader: false,
              openRemoveCustomerDialog: false,
              openRemoveCustomerDialogText: '',
              openSnackbar: true,
              snackbarMessage: "Customer Rejected successfully",
              snackbarType: "success",
            });
          setTimeout(() => {
            this.props.history.push("/internalCustomers")
          }, 3000)
        } else {
          this.setState({
            customerRemoveLoader: false,
            openSnackbar: true,
            snackbarMessage: "Error occured while rejecting customer",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({ customerRemoveLoader: false })
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  // Gst/Pan tab functions
  handleFetchStateDataFromMaster = (countryId) => {
    this.props
      .fetchStateDataFromMaster(countryId)
      .then((response) => {
        // console.log("fetchStateDataFromMaster response : ", response);
        if (response.status === 200) {
          let selectedCountryAllStates = response.data;
          this.setState({ selectedCountryAllStates });
        } else {
          this.setState({
            selectedCountryAllStates: [],
            gstDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage: "Error occured while fetching state data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   selectedCountryAllStates: [],
        //   gstDataFetchLoader: false,
        //   openSnackbar: true,
        //   snackbarMessage: "Error occured while fetching state data.",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleFetchCityDataFromMaster = async (stateId) => {
    // console.log('typeof stateId : ', typeof stateId);
    let citiesAndStatesData = this.state.citiesAndStatesData;

    if (typeof stateId === "object") {
      let tempCitiesAndStatesData = [];
      if (stateId.length > 0) {
        stateId.map((stateData) => {
          this.props
            .fetchCityDataFromMaster(stateData.state_detail.id)
            .then((response) => {
              if (response.status === 200) {
                let selectedStateAllCities = response.data;
                // console.log(
                //   "selectedStateAllCities : ",
                //   selectedStateAllCities
                // );
                tempCitiesAndStatesData.push(
                  selectedStateAllCities
                );
              } else {
                this.setState({
                  gstDataFetchLoader: false,
                  openSnackbar: true,
                  snackbarMessage: "Error occured while fetching city data",
                  snackbarType: "error",
                  saveLoader: false,

                });
              }
            })
            .catch((error) => {
              // this.setState({
              //   gstDataFetchLoader: false,
              //   openSnackbar: true,
              //   snackbarMessage: "Error occured while fetching city data.",
              //   snackbarType: "error",
              // });
              this.handleOpenSnackbar(
                this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                "error"
              );
              setTimeout(() => {
                this.handleCloseSnackbar();
              }, 4000);
            });

          return 1;
        });
      }
      // console.log(tempCitiesAndStatesData)
      await this.setState({ citiesAndStatesData: tempCitiesAndStatesData });
    } else {
      // console.log("citiesAndStatesData : ", citiesAndStatesData);
      if (citiesAndStatesData.has(stateId) === false) {
        await this.props
          .fetchCityDataFromMaster(stateId)
          .then((response) => {
            if (response.status === 200) {
              let selectedStateAllCities = response.data;
              citiesAndStatesData.set(stateId, selectedStateAllCities);
              this.setState({ citiesAndStatesData });
            } else {
              this.setState({
                gstDataFetchLoader: false,
                openSnackbar: true,
                snackbarMessage: "Error occured while fetching city data",
                snackbarType: "error",
              });
            }
          })
          .catch((error) => {
            // this.setState({
            //   gstDataFetchLoader: false,
            //   openSnackbar: true,
            //   snackbarMessage: "Error occured while fetching city data.",
            //   snackbarType: "error",
            // });
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 4000);
          });
      }
    }
  };

  getCustomerGSTINData = (gstinNumber) => {
    this.setState({ gstDataFetchLoader: true });
    this.props
      .handleVerifyGstinData(gstinNumber)
      .then((response) => {
        console.log(response)

        let gstData = response.data.message
          ? response.data.message
          : response.data.msg.data
            ? response.data.msg.data
            : null;

        if (!gstData) {
        } else {
          // console.log(gstData)
          if (gstData.sts === "Cancelled")
            this.setState({
              gstStatusError: true,
              openSnackbar: true,
              snackbarMessage:
                `GSTIN No. "${gstData.gstin}" has been Cancelled.`,
              snackbarType: "error"
            })
          else this.setState({
            gstStatusError: false
          })

          let gstAdditionalBuisnessAddressAllData =
            gstData.adadr ? gstData.adadr.length > 0 ? gstData.adadr.slice(0, 6) : [] : [];
          // console.log(gstAdditionalBuisnessAddressAllData)
          if (gstAdditionalBuisnessAddressAllData.length > 0) {
            let gstAdditionalBuisnessAddressData = [];
            gstAdditionalBuisnessAddressAllData.map(
              (
                additionalBuisnessAddressData,
                additionalBuisnessAddressDataIndex
              ) => {
                let singleAdditionalBuisnessData = {};
                singleAdditionalBuisnessData.building_name =
                  additionalBuisnessAddressData.addr.bnm;
                singleAdditionalBuisnessData.street =
                  additionalBuisnessAddressData.addr.st;
                singleAdditionalBuisnessData.location =
                  additionalBuisnessAddressData.addr.loc;
                singleAdditionalBuisnessData.door_number =
                  additionalBuisnessAddressData.addr.bno;
                singleAdditionalBuisnessData.floor_number =
                  additionalBuisnessAddressData.addr.flno;
                singleAdditionalBuisnessData.pincode =
                  additionalBuisnessAddressData.addr.pncd;
                singleAdditionalBuisnessData.district_name =
                  additionalBuisnessAddressData.addr.dst;
                singleAdditionalBuisnessData.city_name =
                  additionalBuisnessAddressData.addr.city;
                singleAdditionalBuisnessData.state_name =
                  additionalBuisnessAddressData.addr.stcd;
                singleAdditionalBuisnessData.is_default_address = false;
                return gstAdditionalBuisnessAddressData.push(
                  singleAdditionalBuisnessData
                );
              }
            );
            this.setState({ gstAdditionalBuisnessAddressData });
          }
          this.setState({
            gstDataFetchLoader: false,
            gstDataValidated: true,
            customerState: gstData.pradr ? gstData.pradr.addr.stcd : this.state.customerState,
            city: gstData.pradr ? gstData.pradr.addr.city : this.state.city,
            legalNameOfBusiness: gstData.lgnm,
            tradeName: gstData.tradeNam,
            GSTINStatus: gstData.sts,
            dateOfRegistration: gstData.rgdt.split("/").reverse().join("-"),
            pinCode: gstData.pradr ? gstData.pradr.addr.pncd : this.state.pinCode,
            contry: gstData.country,
            constitutionOfBusiness: gstData.ctb,
            taxpayerType: gstData.dty,
            natureOfBuisnessActivity: gstData.nba ? gstData.nba
              .toString()
              .split(",")
              .join(", ") : null,
            stateJurisdiction: gstData.stj,
            centreJurisdiction: gstData.ctj,
            dateOfCancellation: gstData.cxdt.split("/").reverse().join("-"),
            lastUpdatedDate: gstData.lstupdt.split("/").reverse().join("-"),
            principalBuisnessDoorNumber: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.bno
                  ? gstData.pradr.addr.bno
                  : ""
                : ""
              : "",
            principalBuisnessBuildingName: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.bnm
                  ? gstData.pradr.addr.bnm
                  : ""
                : ""
              : "",

            principalBuisnessFloorNumber: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.flno
                  ? gstData.pradr.addr.flno
                  : ""
                : ""
              : "",

            principalBuisnessLocation: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.loc
                  ? gstData.pradr.addr.loc
                  : ""
                : ""
              : "",

            principalBuisnessStreet: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.st
                  ? gstData.pradr.addr.st
                  : ""
                : ""
              : "",
            principalBuisnessAddressStateName: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.stcd
                  ? gstData.pradr.addr.stcd
                  : ""
                : ""
              : "",
            principalBuisnessAddressDistrictName: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.dst
                  ? gstData.pradr.addr.dst
                  : ""
                : ""
              : "",
            principalBuisnessAddressCityName: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.city
                  ? gstData.pradr.addr.city
                  : ""
                : ""
              : "",

            principalBuisnessAddressPinCode: gstData.pradr
              ? gstData.pradr.addr
                ? gstData.pradr.addr.pncd
                  ? gstData.pradr.addr.pncd
                  : ""
                : ""
              : "",
          });

        }
      })
      .catch((error) => {
        // console.log(error?.response)
        this.setState({
          gstDataFetchLoader: false,
        })
        //   openSnackbar: true,
        //   snackbarMessage: error.response?.details
        //     ? error.response?.details
        //     : error.response?.message
        //       ? error.response?.message
        //       : error.response?.data?.message ?
        //         `GSTIN ${error.response?.data?.message}s`
        //         : "Error occured while fetching GSTIN data try again.",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handlePanImageChange = (e) => {
    if (e.target.files.length) {
      this.setState({
        PanImagePreview: URL.createObjectURL(e.target.files[0]),
        rawUploadedFile: e.target.files[0],
        uploadFileName: e.target.files[0].name,
        panCardFileTypeError: this.handleFileTypeError(e.target.files[0].type)
      });
    }
  };
  handleCheckbookImageChange = (e) => {
    // console.log(e.target.files)
    if (e.target.files.length) {
      this.setState({
        checkBookImagePreview: URL.createObjectURL(e.target.files[0]),
        checkBookRawUploadedFile: e.target.files[0],
        checkBookUploadFileName: e.target.files[0].name,
        checkBookFileTypeError: this.handleFileTypeError(e.target.files[0].type)
      });
    }
  };
  handleRemovePanImage = () => {
    this.setState({
      PanImagePreview: "",
      rawUploadedFile: "",
      uploadFileName: null,
      panCardFileTypeError: false
    });
  };
  handleRemoveCheckbookImage = () => {
    console.log("here")
    this.setState({
      checkBookImagePreview: "",
      checkBookRawUploadedFile: "",
      checkbookFileName: "",
      checkBookUploadFileName: null,
      checkBookFileTypeError: false
    });
  };

  handleFileTypeError = (type) => {
    /// accepted file types
    let fileTypes = ["application/pdf", "image/png", "image/jpeg"]
    let isError = false
    console.log(type)
    if (type === "application/pdf" || type === "image/png" || type === "image/jpeg") {
      return false
    } else return true
    // fileTypes.forEach(fileType => {
    //   if (type === fileType) {
    //     console.log(fileType, "if")
    //     isError = false
    //     return
    //   } else {
    //     console.log(fileType, "else")
    //     isError = true
    //   }
    // })
    return isError
  }
  handleCustomerDontHaveGSTINChange = (event) => {
    this.setState({ customerDontHaveGSTIN: !this.state.customerDontHaveGSTIN });
  };
  handlePanVerifyChange = (event) => {
    this.setState({ customerPanVerified: !this.state.customerPanVerified });
  };
  addNewExtraPhoneNumber = () => {
    // console.log(this.state)
    this.setState({
      postExtraPhone:
      {
        std_code: "",
        phone_number: "",
        country_code: "",
        customer: this.state.selectedCustomerId
      }
    })


  };
  removeNewExtraPhoneNumbers = (phoneNumberIndex) => {
    // console.log(this.state.extraPhoneNumbers)
    this.setState((previousState) => {
      const extraPhoneNumbers = [...previousState.extraPhoneNumbers];
      extraPhoneNumbers.splice(phoneNumberIndex, 1);
      return { extraPhoneNumbers };
    });
  };
  handleExtraPhoneNumbersValueChange = (name, index) => async (event) => {
    const { value } = event.target;

    if (event.target.value.length > 2) {
      this.setState({ helperText: '', error: false });
    } else {
      this.setState({ helperText: 'Invalid format', error: true });
    }
    let isExtraPhoneNumberDataCorrect =
      name === "country_code"
        ? isValidCountryCode(value)
        : name === "phone_number"
          ? isPhoneNumberValid(value)
          : name === "std_code"
            ? isValidStdCode(value)
            : true;

    await this.setState((previousState) => {
      const extraPhoneNumbers = [...previousState.extraPhoneNumbers];
      extraPhoneNumbers[index] = {
        ...extraPhoneNumbers[index],
        name: value,
      };
      // console.log(extraPhoneNumbers)
      return { extraPhoneNumbers };

      // console.log(isExtraPhoneNumberDataCorrect)
    });
  }

  setgetExtraPhoneData = () => {
    this.setState({
      getExtraPhoneData: false
    })
  }

  handleExtraPhoneSaveClick = (e) => {
    // console.log(this.state.postExtraPhone)




    this.props.postExtraPhones(this.state.postExtraPhone)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            snackbarMessage: "Extra Phone Added Successfully",
            openSnackbar: true,
            snackbarType: "success",
            showExtraPhonesForm: false
          })
          this.props.getExtraPhones(this.state.selectedCustomerId)
            .then(response => {
              if (response.status === 200) {
                this.setState({
                  getExtraPhoneData: true
                })
              }
            }

            )
          this.setState({
            postExtraPhone: {
              country_code: "",
              phone_number: "",
              customer: this.state.selectedCustomerId,
              std_code: ""
            }
          })
        }
        // else {
        //   this.setState({
        //     openSnackbar: true,
        //     snackbarMessage: "Error occured while adding phone number",
        //     snackbarType: "error",
        //   });
        // }
      })
      .catch((error) => {
        // console.log(error)
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: "Error occured while adding phone number",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      })



  }

  handleExtraPhoneCancelClick = () => {
    this.setState({
      postExtraPhone: {
        // country_code: "",
        // phone_number: "",
        // customer: this.state.selectedCustomerId,
        // std_code: ""
      }
    })
  }

  handleAddNewPhoneField = (index, name) => async (event) => {
    // console.log(name, event.target.value)
    const { value } = event.target
    if (name === "std_code") {

      this.setState({
        postExtraPhoneSTD: value

      })
      // console.log(event.target.value)
    }
    if (name === "phone_number") {
      // setPostExtraPhones({ ...postExtraPhones, phone_number: e.target.value })
      this.setState({
        postExtraPhoneNumber: value


      })
    }
    if (name === "country_code")
      this.setState({
        selectedCountryCode: value
      })

    let country = this.state.selectedCountryCode
    let std = this.state.postExtraPhoneSTD
    let phone = this.state.postExtraPhoneNumber
    let id = this.state.selectedCustomerId

    this.setState({
      postExtraPhone: [{
        country_code: country,
        std_code: std,
        customer: id,
        phone_number: phone

      }]
    }, () => console.log())
    // console.log(this.state.postExtraPhone)

  }

  extraPhonesDelete = (type) => {
    if (type === "error") {
      this.setState({

        openSnackbar: true,
        snackbarMessage: "Error occured while deleting phone number.Please try again",
        snackbarType: "error",
      });

    } else if (type === "success") {
      this.setState({

        openSnackbar: true,
        snackbarMessage: "Phone Number Deleted Successfully",
        snackbarType: "success",
      });
    }
  }

  handleAddNewExtraPhones = (extra) => {
    // console.log("extra phones")

    // console.log(previousState.extraPhoneNumbers)
    this.setState({ extraPhoneNumbers: extra },
      () => console.log())

  }

  handleIsDirty = (flag) => {
    this.setState({ isDirty: flag });
  }

  addNewCustomerAddressBox = () => {
    if (this.state.customerOperation === "add") {
      this.setState({ displayAddMoreAddressButton: true });
    } else {
      this.setState({ displayAddMoreAddressButton: false });
    }

    this.handleIsDirty(false);

    this.setState({
      selectedAddressState: "",
      selectedAddressCity: "",
      showCustomerAddressEditIcon: false
    })

    let pincodeErrors = [...this.state.pincodeErrors];
    pincodeErrors.push(false);

    this.setState((previousState) => {
      return {
        customerAddressBox: [
          ...previousState.customerAddressBox,
          {
            is_default_address: this.state.customerAddressBox.length === 0 ? true : false,
            is_active: true,
            address: "",
            state: "",
            pincode: "",
            district: "",
            city: "",
            country: this?.state?.customerSelectedCountryId,
            operation: "add",
            pincodeError: false,
            state_detail: {}
          },
        ],
      };
    });
  };
  manageAddMoreAddressButtonDisplay = (addressOperation, customerOperation) => {
    if (customerOperation === "add") {
      console.log("here")
      return true;
    } else {
      if (addressOperation === "add") {
        console.log("here2")
        return true;
      } else if (addressOperation === "edit") {
        console.log("here3")
        return false;
      } else {
        let tempCustomerAddressBox = this.state.customerAddressBox.filter(
          (addressData) => addressData.operation === "edit"
        );
        if (tempCustomerAddressBox.length > 0) {
          console.log("here4")
          return false;
        } else {
          return true;
        }
      }
    }
  };
  handleUpdateAddressBoxOperation = (index, operation) => {
    this.setState({
      selectedAddressCity: "",
      selectedAddressState: ""
    })

    if (operation === "view") {



      this.props.fetchCustomerAdditionalAddresses(this.state.selectedCustomerId)
        .then(response => {
          let tempAddress = this.state.customerAddressBox
          tempAddress[index].pincode = response.data[index].pincode
          tempAddress[index].is_default_address = response.data[index].is_default_address
          tempAddress[index].is_active = response.data[index].is_active
          tempAddress[index].address = response.data[index].address
          this.setState({
            customerAddressBox: tempAddress
          })
        })

        .catch((error) => {
          // this.setState({
          //   openSnackbar: false,
          //   snackbarMessage: "Error occured while retrieving customer address",
          //   snackbarType: "error",
          // });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }

    //console.log(this.state.customerAddressBox)
    let tempCustomerAddressBox = this.state.customerAddressBox;
    // console.log(tempCustomerAddressBox[index])

    this.setState({
      selectedAddressCity: tempCustomerAddressBox[index].city_detail.id,
      selectedAddressState: tempCustomerAddressBox[index].state_detail.id
    })

    tempCustomerAddressBox[index].operation = operation;
    this.setState(
      {

        displayAddMoreAddressButton: this.manageAddMoreAddressButtonDisplay(
          operation,
          this?.state?.customerOperation
        ),
        // customerAddressBox: tempCustomerAddressBox,

      },
      () => console.log("address box operation updated")
    );
    this.props.fetchCityDataFromMaster(tempCustomerAddressBox[index].state_detail.id)
      .then(response => {
        let tempCities = response.data
        let citiesData = this.state.citiesAndStatesData
        citiesData[index] = tempCities
        this.setState({
          citiesAndStatesData: citiesData
        })
      })
    if (operation === "edit") {
      this.setState({
        showCustomerAddressEditIcon: false
      })
    }
    else {
      this.setState({
        showCustomerAddressEditIcon: true,
      })
    }


  };


  removeNewCustomerAddressBox = (index, addressData) => {
    if (
      !addressData.operation ||
      addressData.operation === "view" ||
      addressData.operation === "edit"
    ) {
      this.setState({
        showCustomerAddressEditIcon: true
      })
      let customerAddressId = addressData.id;
      this.props
        .removeCustomerAdditionalAddresses(customerAddressId)
        .then((response) => {
          if (response.status === 204) {
            this.handleFetchCustomerAdditionalAddresses(
              this.state.selectedCustomerId
            );
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Customer address removed successfully",
              snackbarType: "success",

            });
          } else {

            this.setState({
              openSnackbar: true,
              snackbarMessage: "Error occured while removing customer address",
              snackbarType: "error",
            });
          }
        })
        .catch((error) => {
          // this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: "Error occured while removing customer address",
          //   snackbarType: "error",
          // });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    } else {
      this.setState((previousState) => {
        const customerAddressBox = [...previousState.customerAddressBox];
        customerAddressBox.splice(index, 1);
        let pincodeErrors = [...this.state.pincodeErrors]
        pincodeErrors.splice(index, 1);
        this.setState({
          pincodeErrors: pincodeErrors
        })
        if (!customerAddressBox.some(c => c.is_default_address) && customerAddressBox.length > 0) {
          customerAddressBox[0].is_default_address = true;
        }
        return { customerAddressBox };
      });
      this.setState(
        {
          showCustomerAddressEditIcon: true,
          displayAddMoreAddressButton: this.manageAddMoreAddressButtonDisplay(
            addressData.operation,
            this?.state?.customerOperation
          ),
        },
        () => console.log("display add more button")
      );
    }
  };

  handlePinCodeErrors = (index, value) => {
    // Pin code error
    let hasError = false;
    if (this.state.customerDontHaveGSTIN) {
      if (this.state.customerOperation === "add") {
        hasError = this.state.customerAddressBox?.length > 1 &&
          this.state.customerAddressBox[0].state != value
      } else {
        let states = [...new Set(this.state.customerAddressBox.slice(1).map(c => (c.state_detail.id)))]
        let firstState = this.state.customerAddressBox[0]?.state_detail?.id;
        hasError = this.state.customerAddressBox?.length > 1 && !(states?.length == 1 && states[0] == firstState)
      }
    } else if (!this.state.customerDontHaveGSTIN) {
      hasError = this.state.selectedStateCode !== this.state.gstinNumber?.slice(0, 2);
    }

    if (this.state.customerDontHaveGSTIN && this.state.customerOperation === "add" && index == 0) {
      let pincodeErrors = [...this.state.pincodeErrors]
      this.state.customerAddressBox.forEach((c, index) => {
        if (this.state.selectedAddressState != c?.state_detail?.id) {
          pincodeErrors[index] = true;
        } else {
          pincodeErrors[index] = false;
        }
      })
      this.setState({ pincodeErrors: pincodeErrors })
    } else {
      let pincodeErrors = [...this.state.pincodeErrors]
      pincodeErrors[index] = hasError
      this.setState({ pincodeErrors: pincodeErrors })
    }


  }

  handleStateAndCityValues = (index, state, city) => {
    //  console.log(index, state, city)
    let address = this.state.customerAddressBox
    for (let i = 0; i < this.state.customerAddressBox.length; i++) {
      if (i === index) {
        address[i].city = city
        address[i].state = state
        if (this.state.customerOperation == "add") {
          address[i].state_detail = { id: state }
        }
      }

    }
    this.setState({
      customerAddressBox: address,
      city, state
    }, () => {
      this.handlePinCodeErrors(index, state)
    })


  }

  handleAddressRefreshButtonClick = (index) => {
    // console.log(this.state.pincode, index)
    const value = this.state.pincode
    // this.handleCustomerAddressBoxValueChange(index, "pincode")
    this.setState({
      showPincodeRefreshButton: false
    })

    // (checkNumeric(value))
    if (value.length === 6) {
      this.setState({
        pincodeError: false
      })
      this.props.fetchStatesAndCitiesFromPincode(value)
        .then(response => {
          // console.log(response?.data)
          if (response?.data?.length !== 0) {
            this.props.fetchCityDataFromMaster(response.data[0].state_detail.id)
              .then(response => {
                // if (this.stat.customerOperation === "add") {
                let cityArray = this.state.citiesAndStatesData
                for (let i = 0; i < this.state.customerAddressBox.length; i++) {
                  if (i === index) {
                    console.log("ran")
                    cityArray[i] = response.data
                  }
                }
                this.setState({
                  citiesAndStatesData: cityArray
                }, () => { })
                // }
                //  else {
                //   this.setState({
                //     citiesAndStatesData: response.data
                //   })
                // }
              })
              .catch((error) => {
                // this.setState({
                //   openSnackbar: true,
                //   snackbarMessage: "Error occured while fetching cities",
                //   snackbarType: "error",
                //   showPincodeRefreshButton: true
                // });
                this.handleOpenSnackbar(
                  this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                  "error"
                );
                setTimeout(() => {
                  this.handleCloseSnackbar();
                }, 4000);
              })
            // console.log("if ran")

            // this.setState({
            //   [nameThree]: response.data[0].state_detail.id,
            //   // [nameTwo]: response.data[0].city_detail.id
            // })
            this.setState({
              selectedAddressState: response.data[0].state_detail.id,
              selectedAddressCity: response.data[0].city_detail.id,
              selectedAddressCountry: response.data[0].country_detail.id,
              selectedStateCode: response.data[0].state_detail.state_code
              // setStateCode: true


            }, () => console.log())

            if (this.state.customerOperation === "add")
              this.handleStateAndCityValues(index, response.data[0].state_detail.id, response.data[0].city_detail.id)

            // console.log(this.state.city)
          } else {
            // console.log("else ran")
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Pincode Details Not Found. Please add the pincode to continue",
              snackbarType: "error",
              showPincodeRefreshButton: true
            });
          }
        })
        .catch((error) => {
          // this.setState({
          //   openSnackbar: true,
          //   snackbarMessage: "Error occured while fetching cities",
          //   snackbarType: "error",
          //   showPincodeRefreshButton: true
          // });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        })
    } else {
      if (this.state.customerOperation === "add")
        this.handleStateAndCityValues(
          index, "", "")
      this.setState({
        selectedAddressState: "",
        selectedAddressCity: "",
        pincodeError: true

        // selectedAddressCountry: ""
      })
    }
  }
  handleCustomerAddressBoxValueChange = (index, name, box, nameThree) => async (event) => {
    const { value, checked } = event.target;
    let addressboxArray = this.state.customerAddressBox

    if (name === "pincode") {
      this.setState({
        showPincodeRefreshButton: false
      })

      if (value.length === 6) {
        this.props.fetchStatesAndCitiesFromPincode(value)
          .then(response => {
            if (response?.data?.length !== 0) {
              this.props.fetchCityDataFromMaster(response.data[0].state_detail.id)
                .then(response => {
                  let cityArray = this.state.citiesAndStatesData
                  for (let i = 0; i < this.state.customerAddressBox.length; i++) {
                    if (i === index) {
                      cityArray[i] = response.data
                    }
                  }
                  this.setState({
                    citiesAndStatesData: cityArray
                  }, () => { })
                })
                .catch((error) => {
                  this.handleOpenSnackbar(
                    this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
                    "error"
                  );
                  setTimeout(() => {
                    this.handleCloseSnackbar();
                  }, 4000);
                })
              this.setState({
                selectedAddressState: response.data[0].state_detail.id,
                selectedAddressCity: response.data[0].city_detail.id,
                selectedAddressCountry: response.data[0].country_detail.id,
                selectedStateCode: response.data[0].state_detail.state_code
              }, () => console.log())

              this.handleStateAndCityValues(index, response.data[0].state_detail.id, response.data[0].city_detail.id)
            } else {
              this.setState({
                openSnackbar: true,
                snackbarMessage: "Pincode Details Not Found. Please add the pincode to continue",
                snackbarType: "error",
                showPincodeRefreshButton: true
              });


            }
          })
          .catch((error) => {
            this.setState({
              // openSnackbar: true,
              // snackbarMessage: "Error occured while fetching cities",
              // snackbarType: "error",
              showPincodeRefreshButton: true
            });
            this.handleOpenSnackbar(
              this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
              "error"
            );
            setTimeout(() => {
              this.handleCloseSnackbar();
            }, 4000);
          })
      } else {
        if (this.state.customerOperation === "add")
          this.handleStateAndCityValues(
            index, "", "")
        //box.pincodeError = true
        //console.log(box)
        this.setState({
          selectedAddressState: "",
          selectedAddressCity: "",
          // pinCodeError: true

          // selectedAddressCountry: ""
        })
      }

    }

    if (name === "state") {
      // console.log("triggered")
      this.setState({
        selectedAddressState: value
      })
      // this.props.fetchCityDataFromMaster(value)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       this.setState({
      //         citiesAndStatesData: response.data,

      //       });
      //     } else {
      //       this.setState({
      //         openSnackbar: true,
      //         snackbarMessage: "Error occured while fetching cities",
      //         snackbarType: "error",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.setState({
      //       openSnackbar: true,
      //       snackbarMessage: "Error occured while fetching cities",
      //       snackbarType: "error",
      //     });




      // })
    }

    if (name === "city") {
      this.setState({
        selectedAddressCity: value
      })
    }
    if (!name) {
      // console.log("incorrect operation");
    } else if (name === "is_default_address") {
      let customerAddressBoxBackup = []
      if (event.target.checked) {
        this.state.customerAddressBox.forEach((customerData, customerDataIndex) => {
          let tempCustomerAddressBox = customerData;
          if (index === customerDataIndex) {
            tempCustomerAddressBox.is_default_address = event.target.checked;
          } else {
            if (this.state.customerOperation === "add") {
              tempCustomerAddressBox.is_default_address = false;
            }
          }
          customerAddressBoxBackup.push(tempCustomerAddressBox);
        })
      } else {
        this.state.customerAddressBox.forEach((customerData, customerDataIndex) => {
          let tempCustomerAddressBox = customerData;
          if (index === customerDataIndex) {
            tempCustomerAddressBox.is_default_address = event.target.checked;
          }
          customerAddressBoxBackup.push(tempCustomerAddressBox);
        })

        if (this.state.customerOperation === "add") {
          if (!customerAddressBoxBackup.some(c => c.is_default_address) && customerAddressBoxBackup?.length > 0) {
            customerAddressBoxBackup[0].is_default_address = true;
          }
        }
      }
      await this.setState({
        customerAddressBox: customerAddressBoxBackup
      });
    } else if (name === "is_active") {
      await this.setState((previousState) => {
        const customerAddressBox = [...previousState.customerAddressBox];
        customerAddressBox[index] = {
          ...customerAddressBox[index],
          [name]: checked,
        };
        return { customerAddressBox };
      });
    } else {
      await this.setState((previousState) => {
        const customerAddressBox = [...previousState.customerAddressBox];
        customerAddressBox[index] = {
          ...customerAddressBox[index],
          [name]: value,
        };
        return { customerAddressBox };
      });
    }

    if (name === "std_code") {

      this.setState({
        postExtraPhoneSTD: value

      })
      // console.log(event.target.value)
    }
    if (name === "phone_number") {
      this.setState({
        postExtraPhoneNumber: value,
        phoneNumberTouched: true

      })
    }
    if (name === "country_code")
      this.setState({
        selectedCountryCode: value,
        countryCodeTouched: true
      })

    let country = this.state.selectedCountryCode
    let std = this.state.postExtraPhoneSTD
    let phone = this.state.postExtraPhoneNumber
    let id = this.state.selectedCustomerId

    this.setState({
      postExtraPhone: [{
        country_code: country,
        std_code: std,
        customer: id,
        phone_number: phone

      }]
    }, () => console.log())


    // console.log(this.state.postExtraPhone)
  };
  handleSaveCustomerAddresses = (customerAddressData) => {
    // console.log(customerAddressData)
    this.setState({
      saveLoader: true
    })
    let addressId =
      customerAddressData.operation === "edit"
        ? customerAddressData?.id || null
        : null;
    let addressData = {
      address: customerAddressData.address,
      pincode: customerAddressData.pincode,
      district: customerAddressData.district,
      is_default_address: customerAddressData.is_default_address,
      is_active: customerAddressData.is_active,
      customer: this?.state?.selectedCustomerId || "",
      country:
        customerAddressData.operation === "edit"
          ? customerAddressData?.country_detail?.id || null
          : customerAddressData?.country || null,
      state:

        customerAddressData.operation === "edit" ?
          this.state.selectedAddressState :
          customerAddressData.operation === "add" ?
            customerAddressData.state !== "" ?
              customerAddressData.state : this.state.selectedAddressState : this.state.selectedAddressState,
      city:
        customerAddressData.operation === "edit" ?
          this.state.selectedAddressCity :
          customerAddressData.operation === "add" ?
            customerAddressData.city !== "" ?
              customerAddressData.city : this.state.selectedAddressCity : this.state.selectedAddressCity,

    };
    this.props
      .saveEditCustomerAddresses(
        addressData,
        addressId,
        customerAddressData.operation
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {

          this.setState({
            openSnackbar: true,
            snackbarMessage: `Customer address ${customerAddressData.operation === "edit" ? "edited" : "added"
              } successfully`,
            snackbarType: "success",
            showCustomerAddressEditIcon: true,
            saveLoader: false,
          });
          setTimeout(() => {
            this.handleFetchCustomerAdditionalAddresses(
              this.state.selectedCustomerId
            );
          }, 2000)
        } else if (response.status === 400) {
          // console.log(response)
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.message ? response.data.message :
              response?.data?.address ? response.data.address
                : response?.data?.state ? response.data.state :
                  `Error occured while ${customerAddressData.operation === "edit" ? "updating" : "adding"
                  } customer address`,
            snackbarType: "error",
            saveLoader: false,
            // ${customerAddressData.operation === "edit" ? "updating" : "adding"} 
          });
        }
      })
      .catch((error) => {
        // console.log(error.response)
        this.setState({
          // openSnackbar: true,
          // snackbarMessage: error?.response?.data?.message ? error?.response?.data.message :
          //   error?.response?.data?.address ? `Adddress Line - ${error?.response?.data.address}`
          //     : error?.response?.data?.state ? `State Name - ${error?.response?.data.state}` :
          //       error?.response?.data?.city ? `City Name - ${error?.response?.data.city}` :
          //         `Error occured while ${customerAddressData.operation === "edit" ? "updating" : "adding"
          //         } customer address`,
          // error.response?.data?.message,
          // `Error occured while ${customerAddressData.operation === "edit" ? "updating" : "adding"
          // } customer address`,
          // snackbarType: "error",
          saveLoader: false,
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  // General tab functions
  handleGeneratePassword = () => {
    this.setState({ password: generatePassword() });
  };
  handleCustomerPasswordReset = (changePasswordData) => {
    console.log("here")
    this.props
      .resetCustomerPassword(changePasswordData)
      .then((response) => {
        if (response.status === 200) {
          // this.setState({
          //   tabValue: this.state.tabValue,
          //   openSnackbar: true,
          //   snackbarMessage: `Customer password updated successfully`,
          //   snackbarType: "success",
          // });
          this.setState({
            saveLoader: false
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while updating customer password`,
            snackbarType: "error",
            saveLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          // openSnackbar: true,
          // snackbarMessage: `Error occured while updating customer password`,
          // snackbarType: "error",
          saveLoader: false,
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  // handleAccountTypeInEdit = () => {

  //   if (this.state.accountType.length === 0) {
  //     this.setState({ accountType: { account_type: 'D' } })
  //   }


  // }

  handleDateOfBirthChange = (date) => {
    if (date !== "Invalid Date") {
      this.setState({ customerDateOfBirth: date });
    } else {
      this.setState({ customerDateOfBirth: null });
    }
  };
  handleCustomerTypeChange = (event, data, dataFor) => {
    // console.log("event.target.value : ", event.target.value, data, dataFor);
    if (dataFor === "customerType") {
      let tempAccountTypes = this.state.accountType;
      // if (tempAccountTypes.length === 0)
      //   tempAccountTypes.push({
      //     account_type: 'D'
      //   })
      // console.log(this.state.accountType)

      if (
        event.target.value === "DISTRIBUTOR" ||
        event.target.value === "BROKER"
      ) {
        tempAccountTypes.push({ account_type: "K", account_class_code: null });
        this.setState({ accountType: tempAccountTypes });
      } else {
        this.setState({
          accountType: tempAccountTypes.filter((a) => a.account_type !== "K"),
        });
      }
      this.setState({ customerType: event.target.value });
      event.preventDefault();
    }
    if (dataFor === "L1") {
      this.setState({
        customerTypeL1: data.id,
        customerTypeL1_name: data.type_name,
        customerTypeL1Data: data
      })
    }
    if (dataFor === "L2") {
      this.setState({
        customerTypeL2: data.id,
        customerTypeL2_name: data.type_name,
        customerTypeL2Data: data
      })
    }
    if (dataFor === "tag") {
      this.setState({
        customerTag: data.id,
        customerTag_name: data.tag_name,
        customerTagData: data
      })
    }
    // console.log(this.state.accountType)
  };
  // handleAccountVerifyChange = (event) => {
  //   this.setState({ accountVerify: !this.state.accountVerify });
  // };
  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    // console.log("name : ", name);
    // console.log("checked : ", checked);
    this.setState({ [name]: checked });

  };
  handleDataChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAccountTypeCheckboxValues = (field, value) => {
    this.setState({
      [field]: value
    })
  }
  handleAccountTypeCheckboxChange = (event, checkValue) => {
    const { checked, name } = event.target;

    console.log(name, checked);
    let tempAccountTypes = [...this.state.accountType]
    this.setState({
      [checkValue]: checked
    })
    // if (tempAccountTypes.length === 0)
    //   tempAccountTypes.push({ account_type: 'D' });
    // console.log("name : ", name);
    if (checked === true) {
      tempAccountTypes.push({ account_type: name, account_class_code: null });
      this.setState({ accountType: tempAccountTypes, });
    } else {
      this.setState({
        accountType: tempAccountTypes.filter((a) => a.account_type !== name),
      });
    }
    // console.log(this.state.accountType)
  };
  // Subscription tab functions
  handleCustomerSubscriptionAddNewChange = (event, name) => {
    this.setState({ [name]: event.target.value });
    event.preventDefault();
  };

  handleSaveCustomerSubscriptions = () => {
    if (!this.state.selectedCustomerId) {
      this.setState({
        openSnackbar: true,
        snackbarMessage:
          "Customer details not available to create subscription",
        snackbarType: "error",
      });
    } else if (
      !this.state.customerSelectedSubscriptionCategory ||
      !this.state.customerSelectedSubscriptionState ||
      !this.state.customerSelectedSubscriptionRateType
    ) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: "All values must be provided to create subscription",
        snackbarType: "error",
      });
    } else {
      let subscriptionData = {
        rate_type: this.state.customerSelectedSubscriptionRateType,
        customer: Number(this.state.selectedCustomerId),
        item_category: this.props.itemsWithStates.get(
          this.state.customerSelectedSubscriptionCategory
        ).id,
        state: Number(this.state.customerSelectedSubscriptionState),
      };
      this.setState({ saveCustomerSubscriptionLoader: true });
      this.props
        .handleCreateCustomerSubscription(subscriptionData)
        .then((response) => {
          if (response.status === 201) {
            this.fetchCustomerSubscription(this.state.selectedCustomerId);
            this.setState({
              saveCustomerSubscriptionLoader: false,
              openSnackbar: true,
              snackbarMessage: "Subscription added successfully",
              snackbarType: "success",
              customerSelectedSubscriptionCategory: null,
              customerSelectedSubscriptionState: null,
              customerSelectedSubscriptionRateType: null,
            });
          } else {
            this.setState({
              saveCustomerSubscriptionLoader: false,
              openSnackbar: true,
              snackbarMessage: response.data.data,
              snackbarType: "error",
              customerSelectedSubscriptionCategory: null,
              customerSelectedSubscriptionState: null,
              customerSelectedSubscriptionRateType: null,
            });
          }
        })
        .catch((error) => {
          console.log("here")
          this.setState({
            saveCustomerSubscriptionLoader: false,
            // openSnackbar: true,
            // snackbarMessage:
            // "Something went wrong while creating customer's subscription",
            // snackbarType: "error",
            customerSelectedSubscriptionCategory: null,
            customerSelectedSubscriptionState: null,
            customerSelectedSubscriptionRateType: null,
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
  };

  handleCustomerAdditionalContactEditValueChange = (e, fieldName) => {
    const { value, checked } = e.target;
    let additionalContactRowEditData = this.state?.additionalContactRowEditData
    additionalContactRowEditData[fieldName] = fieldName === 'contact_is_notification_enabled' ? checked : value
    let additionalContactEditError = this.state?.additionalContactEditError
    additionalContactEditError[fieldName] = fieldName === "contact_name" && Boolean(value)
      ? !isCharactersValid(value)
      : fieldName === "contact_email_id" && Boolean(value)
        ? !isEmailValid(value)
        : (fieldName === "contact_phone_number" && Boolean(value)) && !isPhoneNumberValid(value)

    this.setState({
      additionalContactRowEditData: additionalContactRowEditData,
      additionalContactEditError: additionalContactEditError,
    })
  }

  handleAdditionalContactOperation = (operation, rowData = '') => {
    this.setState({
      additionalContactOperation: {
        operation: operation,
        rowID: rowData === '' ? '' : rowData.id
      },
    })

    if (rowData !== '' && operation === 'edit') {
      console.log(rowData)
      this.setState({
        additionalContactRowEditData: {
          contact_id: rowData.id,
          contact_customer: rowData.customer,
          contact_email_id: rowData.email_id,
          contact_is_active: rowData.is_active,
          contact_is_notification_enabled: rowData.is_notification_enabled,
          contact_name: rowData.name,
          contact_phone_number: rowData.phone_number,
        }
      })
    }
    else if (operation === 'view') {
      this.setState({
        additionalContactEditError: {
          contact_name: false,
          contact_email_id: false,
          contact_phone_number: false,
        }
      })
    }
  }

  handleEditSaveCustomerAdditionalContact = () => {
    this.setState({ saveAdditionalContactLoader: true });
    let additionalContactData = {
      name: this.state.additionalContactRowEditData.contact_name,
      email_id: this.state.additionalContactRowEditData.contact_email_id || null,
      phone_number: this.state.additionalContactRowEditData.contact_phone_number || null,
      is_notification_enabled: this.state.additionalContactRowEditData.contact_is_notification_enabled,
      customer: this.state.additionalContactRowEditData.contact_customer,
      is_active: this.state.additionalContactRowEditData.contact_is_active,
    };
    // console.log(additionalContactData)
    this.props
      .editCustomerAdditionalContactPerson(additionalContactData, this.state.additionalContactRowEditData.contact_id)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          this.handleFetchCustomerAdditionalContacts(
            this.state.selectedCustomerId
          );
          this.handleAdditionalContactOperation('view')
          this.setState({
            saveAdditionalContactLoader: false,
            openSnackbar: true,
            snackbarMessage: "Additional contact edit successfully",
            snackbarType: "success",
            additionalContactName: null,
            additionalContactEmail: null,
            additionalContactContact: null,
            additionalContactNotificationStatus: false,
            openAdditionalCOntactForm: true
          });
        } else {
          this.setState({
            saveAdditionalContactLoader: false,
            openSnackbar: true,
            snackbarMessage: response.data.data,
            snackbarType: "error",
            additionalContactName: null,
            additionalContactEmail: null,
            additionalContactContact: null,
            additionalContactNotificationStatus: false,
            openAdditionalCOntactForm: true
          });
        }
      })
      .catch((error) => {
        this.setState({
          saveAdditionalContactLoader: false,
          additionalContactName: null,
          additionalContactEmail: null,
          additionalContactContact: null,
          additionalContactNotificationStatus: false,
          openAdditionalCOntactForm: false
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };


  handleFetchCustomerAdditionalContacts = (customerId) => {
    this.props
      .fetchCustomerAdditionalContacts(customerId)
      .then((response) => {
        if (response.status === 200) {
          let customerAdditionalContacts = response.data;
          this.setState({ customerAdditionalContacts });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer's additional contact data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while fetching customer's additional contact data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  getCustomerItemCategory = (customerId) => {
    this.props
      .fetchItemCategoryCustomer(customerId)
      .then((response) => {
        if (response.status === 200) {
          let customerItemCategory = response.data;
          this.setState({ customerItemCategory });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer's additional contact data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while fetching customer's additional contact data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  fetchCustomerSubscription = (customerId) => {
    this.props
      .getAllSubscriptionData(customerId)
      .then((response) => {
        if (response.status === 200) {
          let customerSubscriptions = response.data;
          this.setState({ customerSubscriptions });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer's subscription data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while fetching customer's subscription data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleRemoveCustomerSubscription = (subscriptionId) => {
    this.props
      .removeCustomerSubscription(subscriptionId)
      .then((response) => {
        if (response.status === 204) {
          this.fetchCustomerSubscription(this.state.selectedCustomerId);
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Subscription removed successfully",
            snackbarType: "success",
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while removing customer subscription data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while removing customer subscription data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleRemoveCustomerAdditionalContactPerson = (additionalContactPersonId) => {
    this.props
      .removeCustomerAdditionalContactPerson(additionalContactPersonId)
      .then((response) => {
        if (response.status === 204) {
          this.handleFetchCustomerAdditionalContacts(
            this.state.selectedCustomerId
          );
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Additional contact removed successfully",
            snackbarType: "success",
            removeAdditionalContactLoader: false,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while removing customer additional contact",
            snackbarType: "error",
            removeAdditionalContactLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          // openSnackbar: true,
          // snackbarMessage:
          // "Error occured while removing customer additional contact person",
          // snackbarType: "error",
          removeAdditionalContactLoader: false,
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleRemoveCustomerSelectedSubscrptionData = () => {
    this.setState({
      customerSelectedSubscriptionCategory: null,
      customerSelectedSubscriptionState: null,
      customerSelectedSubscriptionRateType: null,
    });
  };

  // Account tab functions

  handleFetchAllDealers = () => {
    this.props
      .getAllDealers()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allDealers: response.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching all dealers data`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while fetching all dealers data`,
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleFetchAllDistributors = () => {
    this.props
      .getAllDistributors()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            allDistributors: response.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while fetching all distributors data`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage: `Error occured while fetching all distributors data`,
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleCustomerAdditionalContactValueChange = (name) => (event) => {
    const { value, checked } = event.target;
    if (name === "additionalContactNotificationStatus") {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
      if (name === "additionalContactName") {
        this.setState({
          additionalContactNameError: !isCharactersValid(value),
        });
      } else if (name === "additionalContactEmail") {
        this.setState({ additionalContactEmailError: !isEmailValid(value) });
      } else if (name === "additionalContactContact") {
        this.setState({
          additionalContactContactError: !isPhoneNumberValid(value),
        });
      }
    }
  };
  handleFetchCustomerAllowedPaymentMethod = (customerId) => {
    this.props
      .fetchCustomerAllowedPaymentMethod(customerId)
      .then((response) => {
        if (response.status === 200) {
          let customerAllowedPaymentMethods = response.data;
          this.setState({ customerAllowedPaymentMethods });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer's allowed payment method data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while fetching customer's allowed payment method data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleOpenFormStateChange = () => {
    this.setState({
      openAdditionalCOntactForm: false
    })
  }
  handleSaveCustomerAdditionalContact = () => {
    this.setState({ saveAdditionalContactLoader: true });
    let additionalContactData = {
      name: this.state.additionalContactName,
      email_id: this.state.additionalContactEmail || null,
      phone_number: this.state.additionalContactContact || null,
      is_notification_enabled: this.state.additionalContactNotificationStatus,
      customer: this.state.selectedCustomerId,
    };
    this.props
      .createCustomerAdditionalContactPerson(additionalContactData)
      .then((response) => {
        if (response.status === 201) {
          this.handleFetchCustomerAdditionalContacts(
            this.state.selectedCustomerId
          );
          this.setState({
            saveAdditionalContactLoader: false,
            openSnackbar: true,
            snackbarMessage: "Additional contact added successfully",
            snackbarType: "success",
            additionalContactName: null,
            additionalContactEmail: null,
            additionalContactContact: null,
            additionalContactNotificationStatus: false,
            openAdditionalCOntactForm: true
          });
        } else {
          this.setState({
            saveAdditionalContactLoader: false,
            openSnackbar: true,
            snackbarMessage: response.data.data,
            snackbarType: "error",
            additionalContactName: null,
            additionalContactEmail: null,
            additionalContactContact: null,
            additionalContactNotificationStatus: false,
            openAdditionalCOntactForm: true
          });
        }
      })
      .catch((error) => {
        this.setState({
          saveAdditionalContactLoader: false,
          // openSnackbar: true,
          // snackbarMessage:
          // "Something went wrong while creating additional contact person",
          // snackbarType: "error",
          additionalContactName: null,
          additionalContactEmail: null,
          additionalContactContact: null,
          additionalContactNotificationStatus: false,
          openAdditionalCOntactForm: false
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleSaveCustomerAllowedPaymentMethod = () => {
    let allowedPaymentMethodToAddInCustomer = {
      is_active: true,
      customer: Number(this.state.selectedCustomerId),
      payment_method: Number(this.state.customerSelectedPaymentMethodId),
    };

    this.setState({ saveAllowedPaymetMethodLoader: true });
    this.props
      .createCustomerAllowedPaymentMethod(allowedPaymentMethodToAddInCustomer)
      .then((response) => {
        if (response.status === 201) {
          this.handleFetchCustomerAllowedPaymentMethod(
            this.state.selectedCustomerId
          );
          this.setState({
            saveAllowedPaymetMethodLoader: false,
            openSnackbar: true,
            snackbarMessage: "Payment method added successfully",
            snackbarType: "success",
            customerSelectedPaymentMethodId: null,
            changePaymentMethodOperation: true,
          });
        } else {
          this.setState({
            saveAllowedPaymetMethodLoader: false,
            openSnackbar: true,
            snackbarMessage: "Error occured while creating payment method",
            snackbarType: "error",
            customerSelectedPaymentMethodId: null,
          });
        }
      })
      .catch((error) => {
        this.setState({
          saveAllowedPaymetMethodLoader: false,
          // openSnackbar: true,
          // snackbarMessage: "Error occured while creating payment method",
          // snackbarType: "error",
          customerSelectedPaymentMethodId: null,
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleAllowedPaymentMethodChange = (event) => {
    this.setState({ customerSelectedPaymentMethodId: event.target.value });
  };
  handleRemoveAllowedPaymentMethod = (paymentMethodId) => {
    this.props
      .removeCustomerAllowedPaymentMethod(paymentMethodId)
      .then((response) => {
        if (response.status === 204) {
          this.handleFetchCustomerAllowedPaymentMethod(
            this.state.selectedCustomerId
          );
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Payment method removed successfully",
            snackbarType: "success",
            saveAllowedPaymetMethodLoader: false,
            removeAllowedPaymentMethodLoader: false,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Error occured while removing payment method",
            snackbarType: "error",
            saveAllowedPaymetMethodLoader: false,
            removeAllowedPaymentMethodLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          // openSnackbar: true,
          // snackbarMessage: "Error occured while removing payment method",
          // snackbarType: "error",
          saveAllowedPaymetMethodLoader: false,
          removeAllowedPaymentMethodLoader: false,
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleChangeCustomerSelectedDealerOrDistributor = (event, value) => {
    if (value) {
      let dealerDistributorId = value.id ? value.id : null;
      let dealerDistributorName = value.first_name
        ? value.first_name
        : value.last_name
          ? value.last_name
          : null;
      let dealerDistributorGstinNumber = value.gst_information
        ? value.gst_information.gstin_number
          ? value.gst_information.gstin_number
          : null
        : null;
      let dealerDistributorPhoneNumber = value.credentials
        ? value.credentials.phone
          ? value.credentials.phone
          : null
        : null;
      let dealerDistributorPanCardNumber = value.pan_card_information
        ? value.pan_card_information.pan_card_number
          ? value.pan_card_information.pan_card_number
          : null
        : null;

      this.setState({
        dealerDistributorName,
        dealerDistributorId,
        dealerDistributorGstinNumber,
        dealerDistributorPhoneNumber,
        dealerDistributorPanCardNumber,
      });
    } else {
      this.setState({
        dealerDistributorName: null,
        dealerDistributorId: null,
        dealerDistributorGstinNumber: null,
        dealerDistributorPhoneNumber: null,
        dealerDistributorPanCardNumber: null,
      });
    }
  };
  handleRemoveDealerDistributorCircularLoadingShow = (index) => {
    this.setState((state) => ({
      removeDealerDistributorLoader:
        index === this.state.removeDealerDistributorLoader ? false : index,
    }));
  };
  handleRemoveAdditionalContactCircularLoadingShow = (index) => {
    this.setState((state) => ({
      removeAdditionalContactLoader:
        index === this.state.removeAdditionalContactLoader ? false : index,
    }));
  };
  handleRemoveAllowedPaymentMethodCircularLoadingShow = (index) => {
    this.setState((state) => ({
      removeAllowedPaymentMethodLoader:
        index === this.state.removeAllowedPaymentMethodLoader ? false : index,
    }));
  };
  handleRemoveClickInDealerOrDistributorTable = (dealerDistributorId) => {
    this.props
      .removeCustomerDealerDistributor(dealerDistributorId)
      .then((response) => {
        if (response.status === 204) {
          this.handleFetchDealerDistributorData(this.state.selectedCustomerId);
          if (this.state.customerType === "DEALER")
            this.handleFetchAllDistributors()
          else
            this.handleFetchAllDealers()
        } else {

          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while creating customer dealer/distributor entry",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while creating customer dealer/distributor entry",
        //   snackbarType: "error",
        // });
      });
  };
  handleFetchDealerDistributorData = (customerId) => {
    // console.log("here")
    if (this.state.customerType === "DEALER") {
      this.props
        .getDealerDistributorData(`?dealer=${customerId}`)
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                removeDealerDistributorLoader: false,
                customerDealersDistributors: response.data,
                dealerDistributorName: null,
                dealerDistributorId: null,
                dealerDistributorGstinNumber: null,
                dealerDistributorPhoneNumber: null,
                dealerDistributorPanCardNumber: null,
              },
              () => console.log(1)
            );
          } else {
            this.setState({
              saveDealerDistributorLoader: false,
              removeDealerDistributorLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching customer's distributor data",
              snackbarType: "error",
              dealerDistributorName: null,
              dealerDistributorId: null,
              dealerDistributorGstinNumber: null,
              dealerDistributorPhoneNumber: null,
              dealerDistributorPanCardNumber: null,
            });
          }
        })
        .catch((error) => {
          this.setState({
            saveDealerDistributorLoader: false,
            removeDealerDistributorLoader: false,
            // openSnackbar: true,
            // snackbarMessage:
            //   "Error occured while fetching customer's distributor data",
            // snackbarType: "error",
            dealerDistributorName: null,
            dealerDistributorId: null,
            dealerDistributorGstinNumber: null,
            dealerDistributorPhoneNumber: null,
            dealerDistributorPanCardNumber: null,
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    } else if (this.state.customerType === "DISTRIBUTOR") {
      // console.log("here")
      this.props
        .getDealerDistributorData(`?distributor=${customerId}`)
        .then((response) => {
          if (response.status === 200) {
            let customerDealersDistributorsData = response.data;
            this.setState(
              {
                removeDealerDistributorLoader: false,
                customerDealersDistributors: customerDealersDistributorsData,
                dealerDistributorName: null,
                dealerDistributorId: null,
                dealerDistributorGstinNumber: null,
                dealerDistributorPanCardNumber: null,
                dealerDistributorPhoneNumber: null,
              },
              () =>
                this.prepareCustomerBuyerSelectionList(
                  customerDealersDistributorsData,
                  this.state.customerType
                )
            );
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching customer's dealer data",
              snackbarType: "error",
              removeDealerDistributorLoader: false,
              dealerDistributorName: null,
              dealerDistributorId: null,
              dealerDistributorGstinNumber: null,
              dealerDistributorPanCardNumber: null,
              dealerDistributorPhoneNumber: null,
            });
          }
        })
        .catch((error) => {
          this.setState({
            removeDealerDistributorLoader: false,
            dealerDistributorName: null,
            dealerDistributorId: null,
            dealerDistributorGstinNumber: null,
            dealerDistributorPhoneNumber: null,
            dealerDistributorPanCardNumber: null,
            // openSnackbar: true,
            // snackbarMessage: "Something went wrong while fetching dealer data",
            // snackbarType: "error",
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    } else if (this.state.customerType === "BROKER") {
      this.props
        .getBrokerData(`?broker=${customerId}`)
        .then((response) => {
          if (response.status === 200) {
            let customerBrokersData = response.data;
            this.setState({
              removeDealerDistributorLoader: false,
              customerBroker: customerBrokersData,
            });
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching customer's broker data",
              snackbarType: "error",
              removeDealerDistributorLoader: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            removeDealerDistributorLoader: false,

            // openSnackbar: true,
            // snackbarMessage: "Something went wrong while fetching broker data",
            // snackbarType: "error",
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
  };

  handleFetchAllCustomerDealerDistributorData = debounce((customerId, searchValue = 0) => {
    if (['DISTRIBUTOR', 'BROKER', 'TRADER'].includes(this.state.customerType)) {
      this.props
        .fetchSearchCustomerOptions(searchValue)
        .then((response) => {
          if (response.status === 200) {
            let customerDealersDistributorsData = response.data.results;
            // console.log(customerDealersDistributorsData)
            // this.setState(
            //   {
            //     removeDealerDistributorLoader: false,
            //     customerDealersDistributors: customerDealersDistributorsData,
            //     dealerDistributorName: null,
            //     dealerDistributorId: null,
            //     dealerDistributorGstinNumber: null,
            //     dealerDistributorPanCardNumber: null,
            //     dealerDistributorPhoneNumber: null,
            //   },
            //   () => {
            // console.log("000")
            this.prepareCustomerBuyerSelectionListBySearch(
              customerDealersDistributorsData,
              this.state.customerType
            )
            // }
            // );
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage:
                "Error occured while fetching customer's dealer data",
              snackbarType: "error",
              removeDealerDistributorLoader: false,
              dealerDistributorName: null,
              dealerDistributorId: null,
              dealerDistributorGstinNumber: null,
              dealerDistributorPanCardNumber: null,
              dealerDistributorPhoneNumber: null,
            });
          }
        })
        .catch((error) => {
          this.setState({
            removeDealerDistributorLoader: false,
            dealerDistributorName: null,
            dealerDistributorId: null,
            dealerDistributorGstinNumber: null,
            dealerDistributorPhoneNumber: null,
            dealerDistributorPanCardNumber: null,
            // openSnackbar: true,
            // snackbarMessage: "Something went wrong while fetching dealer data",
            // snackbarType: "error",
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    }
  }, 500)

  handleFetchAllCustomerConsigneeDealerDistributorData = debounce((searchValue = 0) => {
    console.log('clicked here')
    this.setState({ customerConsigneeSelectionListLoader: true })
    this.props
      .fetchSearchConsigneeCustomerOptions(searchValue)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            customerConsigneeSelectionList: response.data.results,
            customerConsigneeSelectionListLoader: false
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: "Error occured while fetching consignee list",
            snackbarType: "error",
            customerConsigneeSelectionList: [],
            customerConsigneeSelectionListLoader: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          customerConsigneeSelectionList: [],
          customerConsigneeSelectionListLoader: false
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  }, 500)

  handleSaveDealerOrDistributor = (event) => {
    this.setState({ saveDealerDistributorLoader: true });
    if (!this.state.customerType) {
      this.setState({
        gstDataFetchLoader: false,
        openSnackbar: true,
        snackbarMessage: '"CUSTOMER TYPE" must be selected',
        snackbarType: "error",
      });
    } else {
      if (this.state.customerType === "DEALER") {
        let publishData = {
          dealer: Number(this.state.selectedCustomerId),
          distributor: Number(this.state.dealerDistributorId),
        };
        this.handleCustomerDealerDistributorPublish(publishData, "Distributor");
      } else if (this.state.customerType === "DISTRIBUTOR") {
        let publishData = {
          dealer: Number(this.state.dealerDistributorId),
          distributor: Number(this.state.selectedCustomerId),
        };
        this.handleCustomerDealerDistributorPublish(publishData, "Dealer");
      } else {
      }
    }
    event.preventDefault();
  };
  handleCustomerDealerDistributorPublish = (publishData, addingDataFor) => {
    this.props
      .handleCreateDealerOrDistributor(publishData)
      .then((response) => {
        if (response.status === 201) {
          this.setState({
            saveDealerDistributorLoader: false,
            openSnackbar: true,
            snackbarMessage: `${addingDataFor} data added successfully`,
            snackbarType: "success",
          });
          setTimeout(() => {

            this.handleFetchDealerDistributorData(this.state.selectedCustomerId);
            this.handleFetchCustomerBuyers(this.state.selectedCustomerId)
            this.handleFetchCustomerConsignees(this.state.selectedCustomerId)
          }, 2000);
        }
      })
      .catch((error) => {
        // console.log(error)
        this.setState({
          saveDealerDistributorLoader: false,
          // openSnackbar: true,
          // snackbarMessage:
          //   "Error occured while creating customer dealer/distributor entry",
          // snackbarType: "error",
        });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  // Buyer/Consignee tab functions

  // handleFetchCustomersAllConsigneesList = (customerId) => {
  //   this.setState({ customerConsigneeSelectionListLoader: true })
  //   this.props
  //     .fetchCustomersAllConsigneesList(customerId)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         this.setState({
  //           customerConsigneeSelectionList: response.data,
  //           customerConsigneeSelectionListLoader: false
  //         });
  //       } else {
  //         this.setState({
  //           openSnackbar: true,
  //           snackbarMessage: "Error occured while fetching consignee list",
  //           snackbarType: "error",
  //           customerConsigneeSelectionList: [],
  //           customerConsigneeSelectionListLoader: false
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         // openSnackbar: true,
  //         // snackbarMessage: "Error occured while fetching consignee list",
  //         // snackbarType: "error",
  //         customerConsigneeSelectionList: [],
  //         customerConsigneeSelectionListLoader: false
  //       });
  //       this.handleOpenSnackbar(
  //         this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
  //         "error"
  //       );
  //       setTimeout(() => {
  //         this.handleCloseSnackbar();
  //       }, 4000);
  //     });
  // };

  prepareCustomerBuyerSelectionList = (buyerRawData, customerType) => {
    if (buyerRawData.length === undefined) {
      const tempCustomerBuyerSelectionList = [];
      let singleBuyerSelectionList = {
        id: buyerRawData.id,
        name:
          buyerRawData.gst_information !== null
            ? buyerRawData.gst_information?.trade_name
              ? buyerRawData.gst_information?.trade_name
              : buyerRawData.gst_information.legal_name_of_business
                ? buyerRawData.gst_information.legal_name_of_business
                : (buyerRawData.first_name ? buyerRawData.first_name : "") +
                (buyerRawData.last_name ? buyerRawData.last_name : "")
            : buyerRawData.pan_card_information !== null
              ? buyerRawData.pan_card_information.company_name
                ? buyerRawData.pan_card_information.company_name
                : (buyerRawData.first_name ? buyerRawData.first_name : "") +
                " " +
                (buyerRawData.last_name ? buyerRawData.last_name : "")
              : (buyerRawData.first_name ? buyerRawData.first_name : "") +
              " " +
              (buyerRawData.last_name ? buyerRawData.last_name : ""),
        gstinPanNumber:
          buyerRawData.gst_information !== null
            ? buyerRawData.gst_information.gstin_number
              ? buyerRawData.gst_information.gstin_number
              : ""
            : buyerRawData.pan_card_information !== null
              ? buyerRawData.pan_card_information.pan_card_number
                ? buyerRawData.pan_card_information.pan_card_number
                : ""
              : "",
        phone: buyerRawData.credentials
          ? buyerRawData.credentials.phone
            ? buyerRawData.credentials.phone
            : ""
          : "",
      };
      tempCustomerBuyerSelectionList.push(singleBuyerSelectionList);
      this.setState({
        customerBuyersSelectionList: tempCustomerBuyerSelectionList,
      });
    } else {
      // console.log(buyerRawData, buyerRawData.length)
      const tempCustomerBuyerSelectionList = [];
      buyerRawData.map((row) => {
        const singleCustomerBuyerSelectionList = {};
        singleCustomerBuyerSelectionList.id =
          customerType === "DISTRIBUTOR" ? row.dealer.id : row.id;
        // console.log(row);
        singleCustomerBuyerSelectionList.name =
          customerType === "DISTRIBUTOR"
            ? row.dealer.gst_information !== null
              ? row.dealer?.gst_information.trade_name
                ? row.dealer?.gst_information.trade_name
                : row.dealer?.gst_information.legal_name_of_business
                  ? row.dealer?.gst_information.legal_name_of_business
                  : (row.dealer.first_name ? row.dealer.first_name : "") +
                  (row.dealer.last_name ? row.dealer.last_name : "")
              : row.dealer.pan_card_information !== null
                ? row.dealer.pan_card_information.company_name
                  ? row.dealer.pan_card_information.company_name
                  : (row.dealer.first_name ? row.dealer.first_name : "") +
                  " " +
                  (row.dealer.last_name ? row.dealer.last_name : "")
                : (row.dealer.first_name ? row.dealer.first_name : "") +
                " " +
                (row.dealer.last_name ? row.dealer.last_name : "")
            : row.trade_name
              ? row.trade_name
              : row.legal_name_of_business
                ? row.legal_name_of_business
                : row.display_name
                  ? row.display_name
                  : "";

        singleCustomerBuyerSelectionList.gstinPanNumber =
          customerType === "DISTRIBUTOR"
            ? row.dealer.gst_information !== null
              ? row.dealer.gst_information.gstin_number
                ? row.dealer.gst_information.gstin_number
                : "N/A"
              : row.dealer.pan_card_information !== null
                ? row.dealer.pan_card_information.pan_card_number
                  ? row.dealer.pan_card_information.pan_card_number
                  : "N/A"
                : "N/A"
            : row.panGstinNumber
              ? row.panGstinNumber
              : "N/A";

        singleCustomerBuyerSelectionList.phone =
          customerType === "DISTRIBUTOR"
            ? row.dealer.credentials
              ? row.dealer.credentials.phone
                ? row.dealer.credentials.phone
                : "N/A"
              : "N/A"
            : row.credentials
              ? row.credentials.phone
                ? row.credentials.phone
                : "N/A"
              : "N/A";
        if (
          customerType == "BROKER" &&
          (row.customer_type == "DEALER" || row.customer_type == "CONSUMER")
        ) {
          tempCustomerBuyerSelectionList.push(singleCustomerBuyerSelectionList);
        } else if (customerType == "DISTRIBUTOR") {
          // console.log(singleCustomerBuyerSelectionList)
          tempCustomerBuyerSelectionList.push(singleCustomerBuyerSelectionList);
        } else {
        }
      });
      return this.setState({
        customerBuyersSelectionList: tempCustomerBuyerSelectionList,
      });
    }
  };
  prepareCustomerBuyerSelectionListBySearch = (buyerRawData, customerType) => {
    // console.log("11")
    if (buyerRawData.length === undefined) {
      // console.log("111")
      const tempCustomerBuyerSelectionList = [];
      let singleBuyerSelectionList = {
        id: buyerRawData.id,
        name:
          buyerRawData.gst_information !== null
            ? buyerRawData.gst_information?.trade_name
              ? buyerRawData.gst_information?.trade_name
              : buyerRawData.gst_information.legal_name_of_business
                ? buyerRawData.gst_information.legal_name_of_business
                : (buyerRawData.first_name ? buyerRawData.first_name : "") +
                (buyerRawData.last_name ? buyerRawData.last_name : "")
            : buyerRawData.pan_card_information !== null
              ? buyerRawData.pan_card_information.company_name
                ? buyerRawData.pan_card_information.company_name
                : (buyerRawData.first_name ? buyerRawData.first_name : "") +
                " " +
                (buyerRawData.last_name ? buyerRawData.last_name : "")
              : (buyerRawData.first_name ? buyerRawData.first_name : "") +
              " " +
              (buyerRawData.last_name ? buyerRawData.last_name : ""),
        gstinPanNumber:
          buyerRawData.gst_information !== null
            ? buyerRawData.gst_information.gstin_number
              ? buyerRawData.gst_information.gstin_number
              : ""
            : buyerRawData.pan_card_information !== null
              ? buyerRawData.pan_card_information.pan_card_number
                ? buyerRawData.pan_card_information.pan_card_number
                : ""
              : "",
        phone: buyerRawData.credentials
          ? buyerRawData.credentials.phone
            ? buyerRawData.credentials.phone
            : ""
          : "",
      };
      tempCustomerBuyerSelectionList.push(singleBuyerSelectionList);
      this.setState({
        customerBuyersSelectionList: tempCustomerBuyerSelectionList,
      });
    } else {
      // console.log("112")
      // console.log(buyerRawData, buyerRawData.length)
      const tempCustomerBuyerSelectionList = [];
      buyerRawData.map((row) => {
        const singleCustomerBuyerSelectionList = {};
        singleCustomerBuyerSelectionList.id =
          customerType === "DISTRIBUTOR" ? row.id : row.id;
        // console.log(row);
        singleCustomerBuyerSelectionList.name =
          customerType === "DISTRIBUTOR"
            ? (row.first_name ? row.first_name : "") +
            " " +
            (row.last_name ? row.last_name : "")
            : (row.first_name ? row.first_name : "") +
            " " +
            (row.last_name ? row.last_name : "")


        singleCustomerBuyerSelectionList.gstinPanNumber =
          customerType === "DISTRIBUTOR"
            ? !isEmpty(row.gstin_number)
              ? row.gstin_number
                ? row.gstin_number
                : "N/A"
              : row.pan_card_number !== null
                ? row.pan_card_number
                  ? row.pan_card_number
                  : "N/A"
                : "N/A"
            : !isEmpty(row.gstin_number)
              ? row.gstin_number
                ? row.gstin_number
                : "N/A"
              : row.pan_card_number !== null
                ? row.pan_card_number
                  ? row.pan_card_number
                  : "N/A"
                : "N/A"

        singleCustomerBuyerSelectionList.phone =
          customerType === "DISTRIBUTOR"
            ? row.phone
              ? row.phone
                ? row.phone
                : "N/A"
              : "N/A"
            : row.phone
              ? row.phone
                ? row.phone
                : "N/A"
              : "N/A";
        if (customerType == "BROKER"
          // customerType == "BROKER" &&
          // (row.customer_type == "DEALER" || row.customer_type == "CONSUMER")
        ) {
          tempCustomerBuyerSelectionList.push(singleCustomerBuyerSelectionList);
        } else if (customerType == "DISTRIBUTOR" || customerType == "TRADER") {
          // console.log(singleCustomerBuyerSelectionList)
          tempCustomerBuyerSelectionList.push(singleCustomerBuyerSelectionList);
        } else {
        }
      });
      return this.setState({
        customerBuyersSelectionList: tempCustomerBuyerSelectionList,
      });
    }
  };
  handleFetchCustomerBuyers = (customerId) => {
    this.props
      .fetchCustomerBuyers(customerId)
      .then((response) => {
        if (response.status === 200) {
          let customerBuyersList = response.data;
          this.setState({ customerBuyersList });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer's buyer data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while fetching customer's consignee data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };
  handleFetchCustomerConsignees = (customerId) => {
    this.props
      .fetchCustomerConsignees(customerId)
      .then((response) => {
        if (response.status === 200) {
          let customerConsigneeList = response.data;
          this.setState({ customerConsigneeList });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage:
              "Error occured while fetching customer's consignee data",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        // this.setState({
        //   openSnackbar: true,
        //   snackbarMessage:
        //     "Error occured while fetching customer's consignee data",
        //   snackbarType: "error",
        // });
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  handleSaveCustomerBuyerConsignee = (saveDataFor) => {
    if (saveDataFor === "buyer") {
      this.setState({ saveCustomerBuyerLoader: true });
      let customerBuyerData = {
        customer: Number(this.state.selectedCustomerId),
        buyer: this.state.customerBuyerId,
      };
      this.props
        .handleCreateCustomerBuyer(customerBuyerData)
        .then((response) => {
          console.log(response)
          if (response.status === 201) {
            this.handleFetchCustomerBuyers(this.state.selectedCustomerId);
            this.handleFetchCustomerConsignees(this.state.selectedCustomerId)

            this.setState({
              customerBuyerId: null,
              customerBuyerName: null,
              customerBuyerContactNumber: null,
              customerBuyerGstinPanNumber: null,
              saveCustomerBuyerLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Buyer added successfully",
              snackbarType: "success",

            });
          } else {
            this.setState({
              // customerBuyerId: null,
              // customerBuyerName: null,
              // customerBuyerContactNumber: null,
              // customerBuyerGstinPanNumber: null,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while saving customer's buyer data",
              snackbarType: "error",
              saveCustomerBuyerLoader: false,
            });
          }
        })
        .catch((error) => {
          // console.log(error)
          this.setState({
            // customerBuyerId: null,
            // customerBuyerName: null,
            // customerBuyerContactNumber: null,
            // customerBuyerGstinPanNumber: null,
            // openSnackbar: true,
            // snackbarMessage: "Error occured while saving customer's buyer data",
            // snackbarType: "error",
            saveCustomerBuyerLoader: false,
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    } else if (saveDataFor === "consignee") {
      this.setState({ saveCustomerConsigneeLoader: true });
      let customerConsigneeData = {
        customer: Number(this.state.selectedCustomerId),
        consignee_from_customer_addresses: this.state.customerNameGstInformation
          ? null
          : this.state.customerConsigneeId,
        consignee_from_gst_information_addresses: this.state.customerNameGstInformation
          ? this.state.customerConsigneeId
          : null,
      };
      let updatedConsigneeData = Object.entries(customerConsigneeData).reduce((a, [k, v]) => (v !== null ? (a[k] = v, a) : a), {})
      // console.log(this.state.customerNameGstInformation, updatedConsigneeData)
      this.props
        .handleCreateCustomerConsignee(customerConsigneeData)
        .then((response) => {
          // console.log(response)
          if (response.status === 201) {
            this.handleFetchCustomerConsignees(this.state.selectedCustomerId);
            this.setState({
              customerConsigneeId: null,
              customerConsigneeAddress: null,
              customerConsigneeName: null,
              customerConsigneeContactNumber: null,
              saveCustomerConsigneeLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Consignee added successfully",
              snackbarType: "success",
            });
          } else {
            this.setState({
              // customerConsigneeId: null,
              // customerConsigneeAddress: null,
              // customerConsigneeName: null,
              // customerConsigneeContactNumber: null,
              openSnackbar: true,
              snackbarMessage:
                "Error occured while saving customer's consignee data",
              snackbarType: "error",
              saveCustomerConsigneeLoader: false,
            });
          }
        })
        .catch((error) => {
          // console.log(error.response)
          this.setState({
            // customerConsigneeId: null,
            // customerConsigneeAddress: null,
            // customerConsigneeName: null,
            // customerConsigneeContactNumber: null,
            // openSnackbar: true,
            // snackbarMessage: error.response?.data && error.response?.data?.non_field_errors ? error.response?.data?.non_field_errors[0] :
            //   error?.response?.data?.consignee_from_customer_addresses ? error?.response?.data?.consignee_from_customer_addresses[0] :
            //     "Error occured while saving customer's consignee data",
            // snackbarType: "error",
            saveCustomerConsigneeLoader: false,
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("incorrect operation");
    }
  };

  handleCustomerBuyerConsigneeOperationChange = (operation, operationFor) => {
    // console.log("here", operation, operationFor)
    this.setState({
      customerBuyerConsigneeOperation: operation,
      openDropdownFor: operationFor
    })
  };

  handleRemoveCustomerBuyerConsigneeCircularLoadingShow = (
    index,
    loaderFor
  ) => {
    if (loaderFor === "buyer") {
      this.setState((state) => ({
        removeCustomerBuyerLoader:
          index === this.state.removeCustomerBuyerLoader ? false : index,
      }));
    } else if (loaderFor === "consignee") {
      this.setState((state) => ({
        removeCustomerConsigneeLoader:
          index === this.state.removeCustomerConsigneeLoader ? false : index,
      }));
    } else {
    }
  };
  handleBuyerSearchFieldCancel = (fieldName) => {
    fieldName === 'buyer' ?
      this.setState({
        openCustomerBuyerListPopper: false,
        buyerSearch: "",
        customerBuyerId: null,
        customerBuyerName: null,
        customerBuyerGstinPanNumber: null,
        customerBuyerContactNumber: null,
      })
      : fieldName === "close" ?
        this.setState({
          openCustomerBuyerListPopper: false,
        })
        :
        this.setState({
          customerConsigneeId: null,
          customerConsigneeAddress: null,
          customerConsigneeName: null,
          customerConsigneeContactNumber: null,
        })
  }
  handleConsigneeSearchFieldCancel = (fieldName) => {
    fieldName === 'consignee' ?
      this.setState({
        openCustomerConsigneeListPopper: false,
        consigneeSearch: "",
        customerConsigneeId: null,
        customerConsigneeAddress: null,
        customerConsigneeName: null,
        customerNameGstInformation: false,
        customerConsigneeContactNumber: null,
      })
      : fieldName === "close" &&
      this.setState({
        openCustomerConsigneeListPopper: false,
      })
  }
  handleClickCustomerBuyerConsignee = (fieldName, fieldvalue) => {
    const name = fieldName;
    const value = fieldvalue;
    if (name === "buyer") {
      // { console.log(value) }
      if (value) {
        this.setState({ openCustomerBuyerListPopper: false, buyerSearch: value?.name })
        let customerBuyerId = value.id ? value.id : null;
        let customerBuyerName = value.name ? value.name : null;
        let customerBuyerGstinPanNumber = value.gstinPanNumber
          ? value.gstinPanNumber
          : null;
        let customerBuyerContactNumber = value.phone ? value.phone : null;
        this.setState({
          customerBuyerId,
          customerBuyerName,
          customerBuyerGstinPanNumber,
          customerBuyerContactNumber,
        });
      } else {
        this.setState({
          customerBuyerId: null,
          customerBuyerName: null,
          customerBuyerGstinPanNumber: null,
          customerBuyerContactNumber: null,
        });
      }
    } else if (name === "consignee") {
      if (value) {
        this.setState({
          openCustomerConsigneeListPopper: false,
          consigneeSearch: `${value.address ? value.address : ""},  ${value.city ? value.city : ""}, ${value.country ? value.country : ""}`
        })
        let customerNameGstInformation = Boolean(
          value.building_name ||
          value.street ||
          value.state_name ||
          value.building_name === "" ||
          value.street === "" ||
          value.state_name === ""
        );
        let customerConsigneeId = value.id ? value.id : null;
        let customerConsigneeName = value.customer
          ? value.customer.gst_information !== null
            ? value.customer.gst_information.trade_name
              ? value.customer.gst_information.trade_name
              : value.customer.gst_information.legal_name_of_business
                ? value.customer.gst_information.legal_name_of_business
                : (value.customer.first_name ? value.customer.first_name : "") +
                " " +
                (value.customer.last_name ? value.customer.last_name : "")
            : value.customer.pan_card_information !== null
              ? value.customer.pan_card_information.company_name
                ? value.customer.pan_card_information.company_name
                : (value.customer.first_name ? value.customer.first_name : "") +
                " " +
                (value.customer.last_name ? value.customer.last_name : "")
              : ""
          : "";
        let customerConsigneeAddress = `${value.address ? value.address : ""
          },  ${value.city ? value.city : ""}, ${value.country ? value.country : ""
          }`;
        let customerConsigneeContactNumber = value.customer
          ? value.customer.credentials
            ? value.customer.credentials.phone
              ? value.customer.credentials.phone
              : null
            : null
          : null;
        this.setState({
          customerNameGstInformation,
          customerConsigneeId,
          customerConsigneeName,
          customerConsigneeAddress,
          customerConsigneeContactNumber,
        });
      } else {
        this.setState({
          customerNameGstInformation: false,
          customerConsigneeId: null,
          customerConsigneeName: null,
          customerConsigneeAddress: null,
          customerConsigneeContactNumber: null,
        });
      }
    } else {
      console.log("invalid selection");
    }
  }
  handleChangeCustomerBuyerConsignee = (name) => (event, value) => {
    // console.log(this.state.customerType)
    if (name === "buyer") {
      if (['DISTRIBUTOR', 'BROKER', 'TRADER'].includes(this.state.customerType)) {
        this.setState({ buyerSearch: event.target.value })
        if (event.target.value) {
          this.setState({ openCustomerBuyerListPopper: true, openCustomerConsigneeListPopper: false });
          this.handleFetchAllCustomerDealerDistributorData(this.state.customerType, event.target.value)
        }
        else {
          this.setState({ openCustomerBuyerListPopper: false, });
        }
      }
      else {
        if (value) {
          let customerBuyerId = value.id ? value.id : null;
          let customerBuyerName = value.name ? value.name : null;
          let customerBuyerGstinPanNumber = value.gstinPanNumber
            ? value.gstinPanNumber
            : null;
          let customerBuyerContactNumber = value.phone ? value.phone : null;
          this.setState({
            customerBuyerId,
            customerBuyerName,
            customerBuyerGstinPanNumber,
            customerBuyerContactNumber,
          });
        } else {
          this.setState({
            customerBuyerId: null,
            customerBuyerName: null,
            customerBuyerGstinPanNumber: null,
            customerBuyerContactNumber: null,
          });
        }
      }
    } else if (name === "consignee") {
      this.setState({ consigneeSearch: event.target.value })
      if (event.target.value) {
        this.setState({ openCustomerConsigneeListPopper: true, openCustomerBuyerListPopper: false });
        this.handleFetchAllCustomerConsigneeDealerDistributorData(event.target.value)
        // let customerNameGstInformation = Boolean(
        //   value.building_name ||
        //   value.street ||
        //   value.state_name ||
        //   value.building_name === "" ||
        //   value.street === "" ||
        //   value.state_name === ""
        // );
        // let customerConsigneeId = value.id ? value.id : null;
        // let customerConsigneeName = value.customer
        //   ? value.customer.gst_information !== null
        //     ? value.customer.gst_information.trade_name
        //       ? value.customer.gst_information.trade_name
        //       : value.customer.gst_information.legal_name_of_business
        //         ? value.customer.gst_information.legal_name_of_business
        //         : (value.customer.first_name ? value.customer.first_name : "") +
        //         " " +
        //         (value.customer.last_name ? value.customer.last_name : "")
        //     : value.customer.pan_card_information !== null
        //       ? value.customer.pan_card_information.company_name
        //         ? value.customer.pan_card_information.company_name
        //         : (value.customer.first_name ? value.customer.first_name : "") +
        //         " " +
        //         (value.customer.last_name ? value.customer.last_name : "")
        //       : ""
        //   : "";
        // let customerConsigneeAddress = `${value.address ? value.address : ""
        //   },  ${value.city ? value.city : ""}, ${value.country ? value.country : ""
        //   }`;
        // let customerConsigneeContactNumber = value.customer
        //   ? value.customer.credentials
        //     ? value.customer.credentials.phone
        //       ? value.customer.credentials.phone
        //       : null
        //     : null
        //   : null;
        // this.setState({
        //   customerNameGstInformation,
        //   customerConsigneeId,
        //   customerConsigneeName,
        //   customerConsigneeAddress,
        //   customerConsigneeContactNumber,
        // });
      } else {
        this.setState({ openCustomerConsigneeListPopper: false, });
        this.setState({
          customerNameGstInformation: false,
          customerConsigneeId: null,
          customerConsigneeName: null,
          customerConsigneeAddress: null,
          customerConsigneeContactNumber: null,
        });
      }
    } else {
      console.log("invalid selection");
    }
  };
  handleRemoveCustomerBuyerConsignee = (buyerConssigneeId, removeDataFrom) => {
    if (removeDataFrom === "buyer") {
      this.props
        .handleRemoveCustomerBuyer(buyerConssigneeId)
        .then((response) => {
          if (response.status === 204) {
            this.handleFetchCustomerBuyers(this.state.selectedCustomerId);
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Buyer removed successfully",
              snackbarType: "success",
              removeCustomerBuyerLoader: false,
              // saveAllowedPaymetMethodLoader: false,
              // removeAllowedPaymentMethodLoader: false,
            });
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Error occured while removing buyer",
              snackbarType: "error",
              removeCustomerBuyerLoader: false,
              // saveAllowedPaymetMethodLoader: false,
              // removeAllowedPaymentMethodLoader: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            // openSnackbar: true,
            // snackbarMessage: "Error occured while removing buyer",
            // snackbarType: "error",
            removeCustomerBuyerLoader: false,
            // saveAllowedPaymetMethodLoader: false,
            // removeAllowedPaymentMethodLoader: false,
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    } else if (removeDataFrom === "consignee") {
      this.props
        .handleRemoveCustomerConsignee(buyerConssigneeId)
        .then((response) => {
          if (response.status === 204) {
            this.handleFetchCustomerConsignees(this.state.selectedCustomerId);
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Consignee removed successfully",
              snackbarType: "success",
              removeCustomerConsigneeLoader: false,
              // saveAllowedPaymetMethodLoader: false,
              // removeAllowedPaymentMethodLoader: false,
            });
          } else {
            this.setState({
              openSnackbar: true,
              snackbarMessage: "Error occured while removing consignee",
              snackbarType: "error",
              removeCustomerConsigneeLoader: false,
              // saveAllowedPaymetMethodLoader: false,
              // removeAllowedPaymentMethodLoader: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            // openSnackbar: true,
            // snackbarMessage: "Error occured while removing consignee",
            // snackbarType: "error",
            removeCustomerConsigneeLoader: false,
            // saveAllowedPaymetMethodLoader: false,
            // removeAllowedPaymentMethodLoader: false,
          });
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("invalid ooperation");
    }
  };


  render() {
    const {
      useNewUi,
      tabValue,
      customerOperation,
      openSnackbar,
      snackbarType,
      snackbarMessage,
      openRemoveCustomerDialog,
      openRemoveCustomerDialogText,
      customerRemoveLoader,
      fetchCustomerDataLoader,
      customerRejected,
    } = this.state;
    const { classes } = this.props;
    // console.log(this.state.saveLoader)
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          {customerOperation === "view" && fetchCustomerDataLoader ? (
            <CircularLoader />
          ) : (
            <div className={classes.rightPadding}>
              <Typography className={classes.fontStyles}>
                {
                  <span
                    className={classes.titleWithBlueFont}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.goBack();
                    }}
                  >
                    Customers
                  </span>
                }
                {" > "}
                {customerOperation === "add" ? "Create Customer" : "Customer Details"}
              </Typography>
              <div className={classes.displayData}>
                <div className={classes.headerRow}>
                  <div
                    className={
                      customerOperation === "add"
                        ? classes.tabsAddStyle
                        : customerOperation === "edit"
                          ? classes.tabsEditStyle
                          : classes.tabsViewStyle
                    }
                  >
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          borderBottom: `2px solid ${primaryColor}`,
                          colur: grey,
                        },
                      }}
                      value={tabValue}
                      onChange={this.handleTabChange}
                      className={classes.tabsStyles}
                      variant="scrollable"
                    >
                      <Tab
                        label={useNewUi ? "GST/PAN" : "GENERAL"}
                        className={
                          tabValue === 0
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      // disabled={
                      //   (this.state.customerOperation === "add") ?
                      //     tabValue === 0 || tabValue === 1 ? false : true
                      //     : false
                      // }
                      />
                      <Tab
                        label={useNewUi ? "GENERAL" : "PERSONAL"}
                        className={
                          tabValue === 1
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                        // disabled={!this.state.isTabTwoChangeAllowed}
                        disabled={(this.state.customerOperation === "add") ?
                          tabValue === 1 || tabValue === 2 ? false : true
                          : false
                        }
                      />
                      <Tab
                        label={useNewUi ? "ACCOUNT" : "CREDENTIAL"}
                        className={
                          tabValue === 2
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                        disabled={(this.state.customerOperation === "add") ?
                          tabValue === 2 ? false : true
                          : false
                        }
                      />
                      {/* <Tab
                        label={useNewUi ? "SUBSCRIPTION" : "ACCOUNT"}
                        className={
                          tabValue === 3
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      />
                      <Tab
                        label={useNewUi ? "BUYER/CONSIGNEE" : "SUBSCRIPTION"}
                        className={
                          tabValue === 4
                            ? classes.selectedTab
                            : classes.tabsStyles
                        }
                      /> */}
                    </Tabs>
                  </div>
                  <div className={classes.displayFlex}>
                    {customerOperation === "view" ? (
                      <ToolTipComponent
                        title='Customer is rejected'
                        condition={customerRejected}
                        title2="You have no edit permission on this widget"
                        condition2={!hasUpdateAccess(this.props.user, "customerManagement")}
                        titile3="You have no approve permission on this widget"
                        condition3={tabValue === 2 && !hasApproveAccess(this.props.user, "customerManagement")}
                        titile4="You have no edit permission on this widget"
                        condition4={tabValue === 2 && !hasUpdateAccess(this.props.user, "customerManagement")}
                      >
                        <Button
                          size="small"
                          className={classes.primaryBackground}
                          variant="contained"
                          startIcon={<EditOutlinedIcon />}
                          onClick={() => {
                            this.handleEditCustomerOperation(tabValue);
                          }}
                          disabled={customerRejected
                            ? customerRejected
                            : tabValue === 2
                              ? !(hasApproveAccess(this.props.user, "customerManagement") || hasUpdateAccess(this.props.user, "customerManagement"))
                              : hasUpdateAccess(this.props.user, "customerManagement")
                                ? false
                                : true
                          }
                        >
                          EDIT
                        </Button>
                      </ToolTipComponent>
                    ) : (
                      <div style={{ display: "flex", pointerEvents: this.state.saveLoader && "none" }}>
                        <ToolTipComponent
                          title={tabValue === 2
                            ? "You have no edit or approve permission on this widget"
                            : "You have no edit permission on this widget"
                          }
                          condition={tabValue === 2
                            ? !(hasApproveAccess(this.props.user, "customerManagement") || hasUpdateAccess(this.props.user, "customerManagement"))
                            : !hasUpdateAccess(this.props.user, "customerManagement")
                          }
                        >
                          <Button
                            disabled={
                              tabValue === 2
                                ? (hasApproveAccess(this.props.user, "customerManagement") || hasUpdateAccess(this.props.user, "customerManagement")) && this.checkDataValidityForSaveAndTabChange(tabValue)
                                : hasUpdateAccess(this.props.user, "customerManagement")
                                  ? this.checkDataValidityForSaveAndTabChange(tabValue)
                                  : true
                            }
                            size="small"
                            className={classes.saveButton}
                            variant="contained"
                            startIcon={!this.state.saveLoader && <CheckOutlinedIcon />}
                            onClick={() => {
                              this.handleSaveAndNextClick();
                            }}
                          >
                            {
                              this.state.saveLoader ?
                                <div style={{
                                  width: this.state.customerOperation === "edit" ? "60px" : "100px",
                                  display: "flex", justifyContent: "center"
                                }}>
                                  <CircularProgress size={20} color="#FFFFFF" />
                                </div>
                                :
                                `SAVE${tabValue === 2 || this.state.customerOperation === "edit" ? "" : " & NEXT"}`
                            }
                          </Button>
                        </ToolTipComponent>

                        {customerOperation === "edit" ? (
                          (this.state.selectedCustomerData?.credentials?.is_active && !this.state.selectedCustomerData?.credentials?.is_approved) ||
                            (!this.state.selectedCustomerData?.credentials?.is_active && !this.state.selectedCustomerData?.credentials?.is_approved)
                            ?
                            <ToolTipComponent
                              title="You have no approve permission on this widget"
                              condition={!hasApproveAccess(this.props.user, "customerManagement")}
                            // title2="customer not approved"
                            // condition2={(this.state.selectedCustomerData?.credentials?.is_active && !this.state.selectedCustomerData?.credentials?.is_approved) ||
                            //   (!this.state.selectedCustomerData?.credentials?.is_active && !this.state.selectedCustomerData?.credentials?.is_approved)}
                            >
                              <Button
                                disabled={
                                  hasApproveAccess(this.props.user, "customerManagement")
                                    ? !((this.state.selectedCustomerData?.credentials?.is_active && !this.state.selectedCustomerData?.credentials?.is_approved) ||
                                      (!this.state.selectedCustomerData?.credentials?.is_active && !this.state.selectedCustomerData?.credentials?.is_approved))
                                    : true
                                }
                                size="small"
                                className={classes.removeButton}
                                variant="contained"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => {
                                  this.openRemoveCustomerConfirmationDialog('reject');
                                }}
                              >
                                REJECT
                              </Button>
                            </ToolTipComponent>
                            :
                            <ToolTipComponent
                              title="You have no approve permission on this widget"
                              condition={!hasApproveAccess(this.props.user, "customerManagement")}
                              title2="customer is inactive"
                              condition2={!this.state.selectedCustomerData?.credentials?.is_active}
                            >
                              <Button
                                disabled={
                                  hasApproveAccess(this.props.user, "customerManagement")
                                    ? !this.state.selectedCustomerData?.credentials?.is_active
                                    : true
                                }
                                size="small"
                                className={classes.removeButton}
                                variant="contained"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => {
                                  this.openRemoveCustomerConfirmationDialog();
                                }}
                              >
                                MARK INACTIVE
                              </Button>
                            </ToolTipComponent>
                        ) : null}
                      </div>
                    )}
                    {
                      customerOperation === "view" || customerOperation === "add" ? (
                        <ToolTipComponent
                          title="You have no edit permission on this widget"
                          condition={!hasUpdateAccess(this.props.user, "customerManagement")}
                          titile2="You have no approve permission on this widget"
                          condition2={tabValue === 2 && !hasApproveAccess(this.props.user, "customerManagement")}
                          titile3="You have no edit permission on this widget"
                          condition3={tabValue === 2 && !hasUpdateAccess(this.props.user, "customerManagement")}
                        >
                          <Button
                            size="small"
                            className={classes.margin}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(`/internalCustomers`)
                            }}
                            disabled={
                              tabValue === 2
                                ? !(hasApproveAccess(this.props.user, "customerManagement") || hasUpdateAccess(this.props.user, "customerManagement"))
                                : hasUpdateAccess(this.props.user, "customerManagement")
                                  ? false
                                  : true
                            }
                          >
                            {
                              customerOperation === "view" ? "BACK" : "CANCEL"
                            }


                          </Button>
                        </ToolTipComponent>
                      )

                        : (
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(this.props.user, "customerManagement")}
                            titile2="You have no approve permission on this widget"
                            condition2={tabValue === 2 && !hasApproveAccess(this.props.user, "customerManagement")}
                            titile3="You have no edit permission on this widget"
                            condition3={tabValue === 2 && !hasUpdateAccess(this.props.user, "customerManagement")}
                          >
                            <Button
                              size="small"
                              className={classes.margin}
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleCancelBtnClick()
                              }}
                              disabled={tabValue === 2
                                ? !(hasApproveAccess(this.props.user, "customerManagement") || hasUpdateAccess(this.props.user, "customerManagement"))
                                : hasUpdateAccess(this.props.user, "customerManagement")
                                  ? false
                                  : true
                              }
                            >
                              CANCEL
                            </Button>
                          </ToolTipComponent>
                        )
                    }

                  </div>
                </div>
                {useNewUi
                  ? tabValue === 0 && (
                    <ManageCustomerGstPanDetailsNewUi
                      {...this.state}
                      {...this.props}
                      handleChange={this.handleChange}
                      getCustomerGSTINData={this.getCustomerGSTINData}
                      handleCustomerDontHaveGSTINChange={
                        this.handleCustomerDontHaveGSTINChange
                      }
                      handleAddressRefreshButtonClick={this.handleAddressRefreshButtonClick}
                      handlePanVerifyChange={this.handlePanVerifyChange}
                      handlePanImageChange={this.handlePanImageChange}
                      handleRemovePanImage={this.handleRemovePanImage}
                      addNewCustomerAddressBox={this.addNewCustomerAddressBox}
                      removeNewCustomerAddressBox={
                        this.removeNewCustomerAddressBox
                      }
                      handleCustomerAddressBoxValueChange={
                        this.handleCustomerAddressBoxValueChange
                      }
                      handleStateAndCityValues={this.handleStateAndCityValues}
                      handleUpdateAddressBoxOperation={
                        this.handleUpdateAddressBoxOperation
                      }
                      handleSaveCustomerAddresses={
                        this.handleSaveCustomerAddresses
                      }
                      customerDontHaveGSTIN={this.state.customerDontHaveGSTIN}
                      stateCodeFromResponse={this.state.selectedStateCode}
                      gstinNumberOfCustomer={this.state.gstinNumber?.slice(0, 2)}
                      handleIsDirty={this.handleIsDirty}
                    />
                  )
                  : tabValue === 0 && (
                    <ManageCustomerGeneralDetails
                      {...this.state}
                      {...this.props}
                      handleChange={this.handleChange}
                      getCustomerGSTINData={this.getCustomerGSTINData}
                      handleCitiesAutocompleteClose={
                        this.handleCitiesAutocompleteClose
                      }

                      handleCustomerTypeChange={this.handleCustomerTypeChange}
                      handleCustomerDontHaveGSTINChange={
                        this.handleCustomerDontHaveGSTINChange
                      }
                      handlePanVerifyChange={this.handlePanVerifyChange}
                      handlePanImageChange={this.handlePanImageChange}
                      handleRemovePanImage={this.handleRemovePanImage}
                    />
                  )}
                <br></br>
                {/* {
                  (this.state.customerOperation === "add") ?
                    <ManageNewExtraPhone 
                    
                    /> : null
                } */}
                {useNewUi
                  ? tabValue === 1 && (
                    <ManageCustomerGeneralDetailsNewUi
                      {...this.state}
                      {...this.props}
                      handleChange={this.handleChange}
                      checkValidityForExtraPhoneFields={this.checkValidityForExtraPhoneFields}
                      handleExtraPhoneCancelClick={this.handleExtraPhoneCancelClick}
                      handleCountryCodeSelect={this.handleCountryCodeSelect}
                      handleAddNewPhoneField={this.handleAddNewPhoneField}
                      handleAddExtraPhoneNumber={this.handleAddExtraPhoneNumber}
                      handleCustomerTypeChange={this.handleCustomerTypeChange}
                      handleDateOfBirthChange={this.handleDateOfBirthChange}
                      handleGeneratePassword={this.handleGeneratePassword}
                      removeNewExtraPhoneNumbers={
                        this.removeNewExtraPhoneNumbers
                      }
                      setgetExtraPhoneData={this.setgetExtraPhoneData}
                      extraPhonesDelete={this.extraPhonesDelete}
                      handleCustomerAddressBoxValueChange={
                        this.handleCustomerAddressBoxValueChange
                      }
                      handleExtraPhoneSaveClick={this.handleExtraPhoneSaveClick}
                      handleExtraPhoneNumbersValueChange={
                        this.handleExtraPhoneNumbersValueChange
                      }
                      addNewExtraPhoneNumber={this.addNewExtraPhoneNumber}
                      //new tab rearrage imports
                      addCustomerAdditionalContact={
                        this.addCustomerAdditionalContact
                      }
                      handleRemoveCustomerAdditionalContactPerson={
                        this.handleRemoveCustomerAdditionalContactPerson
                      }
                      handleCustomerAdditionalContactValueChange={
                        this.handleCustomerAdditionalContactValueChange
                      }
                      handleSaveCustomerAdditionalContact={
                        this.handleSaveCustomerAdditionalContact
                      }
                      handleOpenFormStateChange={this.handleOpenFormStateChange}
                      handlePanVerifyChange={this.handlePanVerifyChange}
                      handleCheckbookImageChange={
                        this.handleCheckbookImageChange
                      }
                      handleRemoveCheckbookImage={
                        this.handleRemoveCheckbookImage
                      }
                      handleAddNewExtraPhones={this.handleAddNewExtraPhones}
                      onErrorChange={this.handleExtraPhoneErrorChange}
                    />
                  )
                  : tabValue === 1 && (
                    <div>
                      <ManageCustomerPersonalDetails
                        {...this.state}
                        {...this.props}
                        handleChange={this.handleChange}
                        handleDateOfBirthChange={this.handleDateOfBirthChange}
                        getExtraPhones={this.handleGetExtraPhones}

                      />
                      {/* <AddMoreDottedButtonComponent
                        buttonOnClick={
                          this.props.addNewExtraPhoneNumber
                        }
                        buttonTitle={"+ ADD EXTRA PHONE NUMBER"}
                      // onClick={setShowExtraPhones(false)}
                      /> */}
                    </div>
                  )}
                {useNewUi
                  ? tabValue === 2 && (
                    <ManageCustomerAccountDetailsNewUi
                      {...this.state}
                      {...this.props}
                      handleChange={this.handleChange}
                      handleOperationChange={this.handleOperationChange}
                      customerRejected={this.state.customerRejected}
                      customerDiscountError={this.state.customerDiscountError}
                      totalCreditLimitAdvanceError={this.state.totalCreditLimitAdvanceError}
                      totalCreditLimitOtherError={this.state.totalCreditLimitOtherError}
                      totalCreditLimitAdvanceLogDetail={this.state.totalCreditLimitAdvanceLogDetail}
                      totalCreditLimitOtherLogDetail={this.state.totalCreditLimitOtherLogDetail}
                      handleSyncEffectiveCreditLimit={this.handleSyncEffectiveCreditLimit}
                      handleSubcontractVclLimit={this.handleSubcontractVclLimit}
                      handleTotalCreditLimitSaveButton={this.handleTotalCreditLimitSaveButton}
                      handleTotalCreditLimitCancelButton={this.handleTotalCreditLimitCancelButton}
                      handleCustomerCreditLimitMethodOperation={this.handleCustomerCreditLimitMethodOperation}
                      customerBrokerageAmountError={this.state.customerBrokerageAmountError}
                      handleCustomerBuyerConsigneeOperationChange={this.handleCustomerBuyerConsigneeOperationChange}
                      // handleAccountVerifyChange={
                      //   // this.handleAccountVerifyChange
                      //   this.handleCheckboxChange
                      // }
                      handleCreateBrokerageItemCatWise={
                        this.handleCreateBrokerageItemCatWise
                      }
                      handleAccountTypeCheckboxValues={this.handleAccountTypeCheckboxValues}
                      handleChangeCustomerSelectedDealerOrDistributor={
                        this.handleChangeCustomerSelectedDealerOrDistributor
                      }
                      handleSaveDealerOrDistributor={
                        this.handleSaveDealerOrDistributor
                      }
                      handleRemoveClickInDealerOrDistributorTable={
                        this.handleRemoveClickInDealerOrDistributorTable
                      }
                      handleRemoveDealerDistributorCircularLoadingShow={
                        this.handleRemoveDealerDistributorCircularLoadingShow
                      }
                      handleAdditionalContactOperation={this.handleAdditionalContactOperation}
                      handleCustomerAdditionalContactEditValueChange={
                        this.handleCustomerAdditionalContactEditValueChange
                      }
                      additionalContactOperation={this.state?.additionalContactOperation}
                      additionalContactRowEditData={this.state?.additionalContactRowEditData}
                      additionalContactEditError={this.state?.additionalContactEditError}
                      addCustomerAdditionalContact={
                        this.addCustomerAdditionalContact
                      }
                      handleRemoveCustomerAdditionalContactPerson={
                        this.handleRemoveCustomerAdditionalContactPerson
                      }
                      handleCustomerAdditionalContactValueChange={
                        this.handleCustomerAdditionalContactValueChange
                      }
                      handleSaveCustomerAdditionalContact={
                        this.handleSaveCustomerAdditionalContact
                      }
                      handleEditSaveCustomerAdditionalContact={
                        this.handleEditSaveCustomerAdditionalContact
                      }
                      handleOpenFormStateChange={this.handleOpenFormStateChange}
                      handleRemoveAllowedPaymentMethodCircularLoadingShow={
                        this
                          .handleRemoveAllowedPaymentMethodCircularLoadingShow
                      }
                      handleSaveCustomerAllowedPaymentMethod={
                        this.handleSaveCustomerAllowedPaymentMethod
                      }
                      handleAllowedPaymentMethodChange={
                        this.handleAllowedPaymentMethodChange
                      }
                      handleRemoveAllowedPaymentMethod={
                        this.handleRemoveAllowedPaymentMethod
                      }
                      handleRemoveAdditionalContactCircularLoadingShow={
                        this.handleRemoveAdditionalContactCircularLoadingShow
                      }
                      handleAccountTypeCheckboxChange={
                        this.handleAccountTypeCheckboxChange
                      }
                      handleCheckboxChange={this.handleCheckboxChange}
                      //new tab rearrange imports
                      {...this.props}
                      // handleChange={this.handleChange}

                      handleCustomerSubscriptionAddNewChange={
                        this.handleCustomerSubscriptionAddNewChange
                      }
                      handleSaveCustomerSubscriptions={
                        this.handleSaveCustomerSubscriptions
                      }
                      handleRemoveCustomerSubscription={
                        this.handleRemoveCustomerSubscription
                      }
                      getCustomerItemCategory={this.getCustomerItemCategory}
                      handleRemoveCustomerSelectedSubscrptionData={
                        this.handleRemoveCustomerSelectedSubscrptionData
                      }
                      handleChangeCustomerBuyerConsignee={
                        this.handleChangeCustomerBuyerConsignee
                      }
                      handleClickCustomerBuyerConsignee={
                        this.handleClickCustomerBuyerConsignee
                      }
                      handleBuyerSearchFieldCancel={
                        this.handleBuyerSearchFieldCancel
                      }
                      handleConsigneeSearchFieldCancel={
                        this.handleConsigneeSearchFieldCancel
                      }
                      handleSaveCustomerBuyerConsignee={
                        this.handleSaveCustomerBuyerConsignee
                      }
                      handleRemoveCustomerBuyerConsigneeCircularLoadingShow={
                        this
                          .handleRemoveCustomerBuyerConsigneeCircularLoadingShow
                      }
                      handleRemoveCustomerBuyerConsignee={
                        this.handleRemoveCustomerBuyerConsignee
                      }
                    />
                  )
                  : tabValue === 2 && (
                    <ManageCustomerCredentialDetails
                      {...this.state}
                      {...this.props}
                      handleChange={this.handleChange}
                      handleGeneratePassword={this.handleGeneratePassword}
                    />
                  )}
                {/* {useNewUi
                  ? tabValue === 3 && (
                      <ManageCustomerSubscriptionDetailsNewUi
                        {...this.state}
                        {...this.props}
                        handleChange={this.handleChange}
                        handleCustomerSubscriptionAddNewChange={
                          this.handleCustomerSubscriptionAddNewChange
                        }
                        handleSaveCustomerSubscriptions={
                          this.handleSaveCustomerSubscriptions
                        }
                        handleRemoveCustomerSubscription={
                          this.handleRemoveCustomerSubscription
                        }
                        handleRemoveCustomerSelectedSubscrptionData={
                          this.handleRemoveCustomerSelectedSubscrptionData
                        }
                      />
                    )
                  : tabValue === 3 && (
                      <ManageCustomerAccountDetails
                        {...this.state}
                        handleChange={this.handleChange}
                        handleCheckboxChange={this.handleCheckboxChange}
                        handleChangeCustomerSelectedDealerOrDistributor={
                          this.handleChangeCustomerSelectedDealerOrDistributor
                        }
                        handleSaveDealerOrDistributor={
                          this.handleSaveDealerOrDistributor
                        }
                        handleRemoveClickInDealerOrDistributorTable={
                          this.handleRemoveClickInDealerOrDistributorTable
                        }
                        handleRemoveDealerDistributorCircularLoadingShow={
                          this.handleRemoveDealerDistributorCircularLoadingShow
                        }
                      />
                    )}
                {useNewUi
                  ? tabValue === 4 && (
                      <ManageCustomerBuyerConsigneeDetailsNewUi
                        {...this.state}
                        {...this.props}
                        handleChange={this.handleChange}
                        handleCustomerSubscriptionAddNewChange={
                          this.handleCustomerSubscriptionAddNewChange
                        }
                        handleSaveCustomerSubscriptions={
                          this.handleSaveCustomerSubscriptions
                        }
                        handleRemoveCustomerSubscription={
                          this.handleRemoveCustomerSubscription
                        }
                        handleRemoveCustomerSelectedSubscrptionData={
                          this.handleRemoveCustomerSelectedSubscrptionData
                        }
                        handleChangeCustomerBuyerConsignee={
                          this.handleChangeCustomerBuyerConsignee
                        }
                        handleSaveCustomerBuyerConsignee={
                          this.handleSaveCustomerBuyerConsignee
                        }
                        handleRemoveCustomerBuyerConsigneeCircularLoadingShow={
                          this
                            .handleRemoveCustomerBuyerConsigneeCircularLoadingShow
                        }
                        handleRemoveCustomerBuyerConsignee={
                          this.handleRemoveCustomerBuyerConsignee
                        }
                      />
                    )
                  : tabValue === 4 && (
                      <ManageCustomerSubscriptionDetails
                        {...this.state}
                        {...this.props}
                        handleChange={this.handleChange}
                        handleCustomerSubscriptionAddNewChange={
                          this.handleCustomerSubscriptionAddNewChange
                        }
                        handleSaveCustomerSubscriptions={
                          this.handleSaveCustomerSubscriptions
                        }
                        handleRemoveCustomerSubscription={
                          this.handleRemoveCustomerSubscription
                        }
                        handleRemoveCustomerSelectedSubscrptionData={
                          this.handleRemoveCustomerSelectedSubscrptionData
                        }
                      />
                    )} */}
              </div>
              <SnackbarComponent
                open={openSnackbar}
                handleClose={this.handleCloseSnackbar}
                snackbarMessage={snackbarMessage}
                type={snackbarType}
              />
              <Dialog
                open={openRemoveCustomerDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleRemoveCustomerDialogClose}
              >
                <DialogContent>
                  <DialogContentText>
                    {openRemoveCustomerDialogText !== ''
                      ? "Are you sure you want to reject this customer"
                      : "Are you sure you want to mark this customer as inactive"
                    }
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {customerRemoveLoader ? (
                    <CircularProgress
                      disableShrink
                      className={classes.loaderStyle}
                    />
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          this.handleRemoveCustomerDialogClose();
                        }}
                        className={classes.margin}
                      >
                        CANCEL
                      </Button>
                      {openRemoveCustomerDialogText !== '' ?
                        <Button
                          onClick={() => {
                            this.handleRejectCustomerClick(
                              this.state.selectedCustomerId
                            );
                          }}
                          className={classes.removeButton}
                        >
                          REJECT
                        </Button>
                        :
                        <Button
                          onClick={() => {
                            this.handleRemoveCustomerClick(
                              this.state.selectedCustomerId
                            );
                          }}
                          className={classes.removeButton}
                        >
                          MARK INACTIVE
                        </Button>
                      }
                    </>
                  )}{" "}
                </DialogActions>
              </Dialog>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
export default withStyles(manageCustomersStyles)(ManageCustomers);
