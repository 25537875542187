import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import DealerOrders from "../DealerOrders";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  b2bDealerOrderMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  },
};
class B2bDealerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <MobileLayout bottomNavigation bottomNavSelected={1}>
        <div className={classes.b2bDealerOrderMainBody}>
          <DealerOrders />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(B2bDealerOrders);
