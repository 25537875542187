import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { Typography, Avatar, Button, Paper, Grow, } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import InvoiceWayBillEnvoiceViewDownloadCard from "../InvoiceWayBillEinvoiceViewDownloadCard";
import NewInvoiceWayBillEinvoiceViewDownloadCard from "../NewInvoiceWayBillEinvoiceViewDownloadCard";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
const EinvoicingDownloadInvoiceStyles = (theme) => ({
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "99%",
    // paddingBottom: "20px",
    marginTop: "10px",
    paddingTop: "10px"
    // height: "100%",
    // marginTop: "5px",
  },
  avatar: {
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    backgroundColor: "white",
    height: "180px",
    width: "180px",
    opacity: 0.5,
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    // placeContent: "center",
    placeItems: "center",
    width: "100%",
    height: "100%",
  },
  goBackButton: {
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    width: "51%",
    paddingTop: "20px",
    justifyContent: "space-evenly",
  },
  paddingTop: {
    //  paddingTop: 20 ,
    width: "350px",
    height: "300px"
  },
  eInvoiceAndEwayBillDetailsDisplay: {
    display: "inline-block",
    boxSizing: "border-box",
  },
  cardStyles: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    // width: "100%",
  },
});

class EinvoicingDownloadInvoice extends Component {
  componentDidMount() {
    if (!this.props.invoiceType) {
      return this.props.history.push(
        INTERNALROUTES.E_INVOICING_AND_E_WAY_BILLING
      );
    }
  }

  render() {
    const { classes } = this.props;
    console.log(this.props)
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv} id="comingSoonDiv">
          <Avatar className={classes.avatar} src={"/check-circle.gif"} />
          <Typography
            style={{ opacity: 0.4, fontSize: "1.5rem", paddingTop: "1em" }}
            variant={"h4"}
          >
            Document for {this?.props?.plantItemDetails[0]?.DOCNO} generated
            succesfully.
          </Typography>

          <Paper elevation={1} className={classes.paperStyle}>
            <Grow
              in={true}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 3000 }}
            >
              <div className={classes.eInvoiceAndEwayBillDetailsDisplay}>
                <div className={classes.cardStyles}>
                  {this?.props?.eInvoiveResponse !== null ? (
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={"E-Invoice"}
                        {...this.props}
                      />
                    </div>
                  ) : (
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={"Invoice"}
                        {...this.props}
                      />
                    </div>
                  )}

                  {!this?.props?.eWayBillSelected ||
                    this?.props?.eWayBillSelected === "No" ? (
                    ""
                  ) : (
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={"E-Way Bill"}
                        {...this.props}
                      />
                    </div>
                  )}
                  {
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={"Exit Pass"}
                        {...this.props}
                      />
                    </div>
                  }
                  {/* {
                  <div className={classes.paddingTop}>
                    <NewInvoiceWayBillEinvoiceViewDownloadCard
                      documentFor={"Email"}
                      {...this.props}
                    />
                  </div>
                } */}
                </div>
              </div>
            </Grow>
          </Paper>


          <div className={classes.goBackButton}>
            <Button
              onClick={(evt) => {
                evt.preventDefault();
                this.props.history.push(
                  INTERNALROUTES.E_INVOICING_AND_E_WAY_BILLING
                );
                this.props.handleResetAllData();
              }}
            >
              Go Back
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}
export default withStyles(EinvoicingDownloadInvoiceStyles)(
  EinvoicingDownloadInvoice
);
