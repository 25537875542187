import axios from "axios";
import { generateCsv } from "export-to-csv";
import { isEqual } from "lodash";
import moment from "moment";
import { ToWords } from 'to-words';

const StaticAppVersion = () => {
  if (window?.cordova) {
    window?.cordova?.getAppVersion?.getVersionNumber().then(function (versionNumber) {
      return versionNumber
    })
  }
  return '2.0.25'
}

const checkMobileNumber = (input) => {
  const regex = /^[5-9]\d{2}\d{3}\d{4}$/;
  if (regex.test(input)) {
    return true;
  }
  return "error";
};
const checkAlphaNumericLength = (input) => {
  const regex = /^[a-zA-Z0-9]+$/;
  if (regex.test(input)) {
    return true;
  }
  return false;
};

const checkUpiId = (input) => {
  const regex = /^\w.+@\w+$/;
  if (regex.test(input)) {
    return true;
  }
  return false;
};

const checkIfscCode = (input) => {
  const regex = /^[a-zA-Z]{4}[0-9]{7}$/;
  if (regex.test(input)) {
    return true;
  }
  return false;
}

const checkApiError = (inputValue) => {
  let errorArray = [];
  if (inputValue?.status !== undefined && [500, 404].includes(inputValue?.status)) {
    errorArray.push(inputValue?.statusText + "  :  " + inputValue?.status)
    return errorArray;
  }
  else {
    let input = inputValue?.data !== undefined ? inputValue?.data : inputValue
    for (let key in input) {
      if (typeof (input[key][0]) !== typeof (String())) {
        for (let newkey in input[key][0]) {
          errorArray.push(newkey + "  :  " + (input[key][0][newkey]))
        }
      }
      else {
        errorArray.push(key + "  :  " + input[key])
      }
    }
  }
  return errorArray;
}

const generateRandomColor = () => {
  // Generate a random color code
  const colorCode = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  return colorCode;
}

const filterUnusedField = (data) => {
  return Object.entries(data).reduce((a, [k, v]) => (
    v !== null ? (a[k] = v, a) : a)
    , {})
}

const numberConvertor = (number) => {
  const toWords = new ToWords();

  let words = toWords.convert(number, { currency: true });
  return words
}

const findValueReturnText = (data, key, findValue, returnText) => {
  if (data) {
    return (data.find(d => d[key] === findValue))?.[returnText]
  }
}

const handleSnackbarMessageBox = (data) => {
  return (
    data.map((item, index) => {
      return (
        <p key={index} style={{ margin: 0 }}>
          {item}
        </p>
      )
    })
  )
}

const fetchApiDataAndUpdateLocalStorage = async (url, name, callback, callBackLogicFunction = '', fetchOneTimeOnly = false) => {
  try {
    const localStorageData = localStorage.getItem(name);
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      callback(parsedData, 'localstorage', callBackLogicFunction);
      if (fetchOneTimeOnly)
        return
    }

    const response = await axios.get(url, getOptions(JSON.parse(localStorage.getItem('user'))))
    const fetchedData = response.data;
    localStorage.setItem(name, JSON.stringify(fetchedData));

    callback(fetchedData, 'api', callBackLogicFunction);
  }
  catch (error) {
    throw callback(error, 'error', callBackLogicFunction)
  }
};


const fetchRecursivePaginatedData = async (url, payload, headers, handleCountStateUpdate, responseData = [], totalCount = 0) => {
  try {
    const response = await axios.post(url, payload, headers)
    const count = response.data.count
    const newTotalCount = totalCount + response.data.results.length;
    const nextPageUrl = response.data.next;
    handleCountStateUpdate((newTotalCount / count) * 100)

    const newData = responseData.concat(response.data.results);
    // If there is a next page, recursively fetch it
    if (nextPageUrl) {
      return fetchRecursivePaginatedData(nextPageUrl, payload, headers, handleCountStateUpdate, newData, newTotalCount);
    }
    // If there is no next page, return the combined data and the total count
    return { responseData: newData };
  } catch (error) {
    console.log(error, responseData)
    throw { error, responseData }
  }
};

const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const handleDocViewAndroid = (data, contentType, fileDownloadName) => {
  if (data) {
    window?.PreviewAnyFile.previewBase64(
      win => console.log("open status", win),
      error => console.error("open failed", error),
      data, { mimeType: contentType, name: fileDownloadName }
    );
  }
}




const printPDFInMobile = (pdfMake, dd, fileDownloadName, snackFunction = '', downloadPDFLoader = '',) => {
  if (dd) {
    pdfMake.createPdf(dd).getBuffer(function (buffer) {
      const data = buffer.toString('base64')

      let contentType = "application/pdf";
      // handleDocViewAndroid(data, contentType, fileDownloadName)
      let folderpath = ""
      if (window.cordova.platformId !== 'android') {
        folderpath = window?.cordova.file.documentsDirectory
      }
      else {
        console.log("her android device only function")
        folderpath = window?.cordova?.file?.externalApplicationStorageDirectory;
      }
      let actualFilename = fileDownloadName
      if (folderpath === null) {
        alert(`No Access of Files ${folderpath}`)
      }
      else {
        let DataBlob = b64toBlob(data, contentType);

        window.resolveLocalFileSystemURL(folderpath, function (dir) {
          console.log("Access to the directory granted succesfully");
          snackFunction !== '' && snackFunction()
          setTimeout(() => {
            dir.getFile(actualFilename, { create: true }, function (file) {
              console.log("File created succesfully.");

              file.createWriter(function (fileWriter) {
                console.log("Writing content to file");
                fileWriter.write(DataBlob);
                console.log("Folder Path" + folderpath + actualFilename);
                let finalPath = folderpath + actualFilename;
                window?.cordova.plugins.fileOpener2.open(finalPath, 'application/pdf')
                downloadPDFLoader !== '' && downloadPDFLoader()
              }, function () {
                alert('Unable to save file in path ' + folderpath);
              });
            });
          }, 2000)
        });
        // }
        // else {
        //   if (window?.cordova) {
        //     console.log("called write& download block")
        //     writePDFToTempFile(DataBlob, actualFilename)
        //       .then((tempFileURL) => {
        //         downloadPDF(tempFileURL, actualFilename);
        //       })
        //       .catch((error) => {
        //         console.error('Error writing PDF to temporary file:', error);
        //       });
        //   }
        // }
      }
    })
  }
}

const saveCSVInMobile = (csvDataArray, csvHeadersArray = '', fileDownloadName, snackFunction = '', downloadPDFLoader = '') => {
  let csvStringData = ''
  if (csvHeadersArray === '') {
    const header = Object.keys(csvDataArray[0]).join(',') + '\n';

    const csvData = csvDataArray.map(item => {
      return Object.values(item).join(',') + '\n';
    }).join('');
    // console.log(header, csvData)
    csvStringData = header + csvData;
  }
  else {
    const header = csvHeadersArray.map(item => {
      return (item.label)
    }) + '\n';

    const csvInitialData = []
    csvDataArray.map(item => {
      let rowData = csvHeadersArray.map(header => {
        return (header.key).indexOf(".") === -1
          ? item[header.key] !== undefined ? item[header.key] : ''
          : item[(header.key).slice(0, (header.key).indexOf("."))][(header.key).slice((header.key).indexOf(".") + 1,)]
      })
      csvInitialData.push(rowData)
    })
    const csvData = csvInitialData.map(row => row.join(',')).join('\n')
    csvStringData = header + csvData
    console.log(csvStringData)
  }
  let contentType = "text/csv";
  let DataBlob = new Blob([csvStringData], { type: contentType });
  const reader = new FileReader();
  reader.readAsDataURL(DataBlob);
  reader.onloadend = function () {
    var base64data = reader.result;
    // handleDocViewAndroid(base64data, contentType, fileDownloadName)
    return;
  }
  let folderpath = ""
  if (window.cordova.platformId !== 'android') {
    folderpath = window?.cordova.file.documentsDirectory
  }
  else {
    folderpath = window?.cordova.file.externalRootDirectory + 'Download/';
    if (folderpath == null) {
      folderpath = window?.cordova.file.dataDirectory + 'Download/'
      if (folderpath == null) {
        folderpath = window?.cordova?.file?.externalApplicationStorageDirectory
      }
    }
  }
  let actualFilename = fileDownloadName
  if (folderpath === null) {
    alert(`No Access of Files ${folderpath}`)
  }
  else {
    let DataBlob = new Blob([csvStringData], { type: contentType });
    // Starting to write the file
    window.resolveLocalFileSystemURL(folderpath, function (dir) {
      console.log("Access to the directory granted succesfully");
      snackFunction !== '' && snackFunction()
      setTimeout(() => {
        dir.getFile(actualFilename, { create: true }, function (file) {
          console.log("File created succesfully.");

          file.createWriter(function (fileWriter) {
            console.log("Writing content to file");
            fileWriter.write(DataBlob);
            console.log("Folder Path" + folderpath + actualFilename);
            let finalPath = folderpath + actualFilename;
            window?.cordova.plugins.fileOpener2.open(finalPath, "text/csv")
            downloadPDFLoader !== '' && downloadPDFLoader()
          }, function () {
            alert('Unable to save file in path ' + folderpath);
          });
        });
      }, 2000)
    });
  }
}


const getBase64ImageFromURL = (ford, url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");
      // console.log(ford, dataURL)
      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}

const checkDuplicateValue = (arr, field, input, initialValue) => {
  if (arr && arr?.length > 0) {
    if (UpperCaseText(input) !== UpperCaseText(initialValue)) {
      const found = arr.filter(d => {
        return (
          String(d[field]).toUpperCase() == String(input).toUpperCase()
        )
      })
      console.log(found,)
      return found;
    }
    return false;
  }
}

const checkNameFieldAlphaNumeric = (input) => {
  const regex = /^[a-zA-Z\s]+[a-zA-Z0-9&.,()-\s]+$/;
  if (regex.test(input)) {
    return true
  }
  return false;
}
const isValidClassCode = (code) => {
  const regex = /^[a-zA-Z\s]+[a-zA-Z0-9&.,()-\s]+$/;
  if (regex.test(code) && (code.length > 0 && code.length === 4)) {
    return true
  }
  return false;
}
const checkNumeric = (input) => {
  const regex = /^[0-9]+$/;
  if (regex.test(input)) {
    return true;
  }
  return false;
}

const checkValidFloatValue = (input) => {
  const regex = /^(\d{1,5}|\d{0,5}\.\d{1,3})$/
  // console.log((input))
  if (regex.test(input)) {
    return true;
  }
  return false;
}

const checkQtyLogic = (input) => {
  const regex = /^\d*\.?\d{0,2}?[0,5]{0,1}$/;
  if (regex.test(input)) {
    return true;
  }
  return false;
}

const checkInvalidValue = (input) => {
  if ([null, "null", undefined, 'undefined', NaN, "NaN", "", 'NA'].includes(input)) {
    return true;
  }
  return false;
}

const checkAlphabet = (input) => {
  const regex = /^[a-zA-Z\s]+$/;
  if (regex.test(input)) {
    return true;
  }
  return false;
}

const checkBrokerage = (input) => {
  // const regex = /^[1-9]\d{3}$/;
  const value = Number(input);
  if ((value >= 0 && value <= 9999)) {
    return true;
  }
  return false;
}

const checkTcsPercentage = (input) => {
  // const regex = /^(?:(([1-9]{0,2}){1}(?:\.\d{1,3})?)|(0+\.([0-9]{0,2}[1-9]{1}){1}?)|100|100.0|100.00|100.000)$/
  const regex = /^(?:(([0-9]{0,2}){1}(?:\.\d{1,3})?)|(0+\.([0-9]{0,3}){1}?)|100|100.0|100.00|100.000)$/
  if (regex.test(input)) {
    return true;
  }
  return false;
}

const checkEmailAddress = (input) => {
  const regex = /^([a-zA-Z0-9.]+)@([a-zA-Z]+).([a-zA-Z]+)/;
  if (regex.test(input)) {
    return true;
  }
  return "error";
};

const arraySortBykeyAllBrowser = (array, key, order = '', anotherkey = '') => {
  if (array) {
    return array.sort(function (a, b) {
      let x = typeof a[key] === 'string' ? a[key]?.toUpperCase() : a[key];
      let y = typeof b[key] === 'string' ? b[key]?.toUpperCase() : b[key];
      if (order === '') {
        if (typeof x === 'string' || x instanceof String) {
          return ((x < y) ? -1 : (x > y) ? 1 : anotherkey !== '' ? (a[anotherkey]?.toUpperCase() < b[anotherkey]?.toUpperCase()) ? -1 : (a[anotherkey]?.toUpperCase() > b[anotherkey]?.toUpperCase()) ? 1 : 0 : 0);
        } else {
          return ((x < y) ? -1 : ((x > y) ? 1 : anotherkey !== '' ? (a[anotherkey] < b[anotherkey]) ? -1 : (a[anotherkey] > b[anotherkey]) ? 1 : 0 : 0));
        }
      } else if (order === 'decreasing') {
        if (typeof x === 'string' || x instanceof String) {
          return ((x > y) ? -1 : ((x < y) ? 1 : anotherkey !== '' ? (a[anotherkey]?.toUpperCase() > b[anotherkey]?.toUpperCase()) ? -1 : (a[anotherkey]?.toUpperCase() < b[anotherkey]?.toUpperCase()) ? 1 : 0 : 0));
        } else {
          return ((x > y) ? -1 : ((x < y) ? 1 : anotherkey !== '' ? (a[anotherkey] > b[anotherkey]) ? -1 : (a[anotherkey] < b[anotherkey]) ? 1 : 0 : 0));
        }
      }
    });
  }
};

const PasswordLoginValid = (value) => {
  const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=[a-zA-Z0-9]*[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
  // const regex = /^(?=([a-zA-Z]*|[0-9]*)+[@#._][a-zA-Z0-9]*$)[a-zA-Z@#._]?\S{8,14}$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};
const UpperCaseText = (value) => {
  return String(value).toUpperCase();
}

const handlePaymentStatusText = (laDueDate, paymentStatus, module = '') => {
  if (laDueDate !== null) {
    if (paymentStatus !== "PAID") {
      let paymentDate = new Date(laDueDate);
      let todayDate = new Date();

      let timeDiff = paymentDate.getTime() - todayDate.getTime();
      let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (module === 'LA_Table') {
        return daysDiff > 0
          ? `DUE IN ${daysDiff} D`
          : daysDiff < 0
            ? `OVERDUE BY ${Math.abs(daysDiff)} D`
            : daysDiff === 0 && 'DUE TODAY'
      }
      else if (module === '') {
        return daysDiff
      }
    }
    else {
      return 'PAID'
    }
  }
  else {
    return 'NOT_FOUND'
  }
}

const CaptilizeText = (value) => {
  if (value) {
    let words = value.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + (words[i].substr(1)).toLowerCase();
    }
    return words.join(" ");
  }
}
const pxToEm = (givenPx, basefont) => {
  if (!basefont) basefont = 16;
  return `${givenPx / basefont}em`;
};
const calculatePercent = (percent, num) => (percent / 100) * num;
// Array.sort(compareValues(keyField, [order]))
function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

const handleTotalQtySumCalculation = (arr, fieldName, nestedFieldName = 0, method = '') => {
  if (arr) {
    if (method === '') {
      if (nestedFieldName !== 0) {
        let sum = arr?.reduce((a, c) => {
          return Number(a) + Number(
            (c[`${fieldName}`] && c[`${fieldName}`][`${nestedFieldName}`]) !== undefined
              ? c[`${fieldName}`][`${nestedFieldName}`]
              : 0)
        }, 0)
        return String(sum).match(/^\d*\.?\d{0,3}$/) ? sum : sum.toFixed(3)
      }
      else {
        let sum = arr?.reduce((a, c) => { return Number(a) + Number(c[`${fieldName}`]) }, 0)
        return String(sum).match(/^\d*\.?\d{0,3}$/) ? sum : sum.toFixed(3)
      }
    }
    else if (method === 'length') {
      let totalLength = 0;
      arr?.forEach(element => {
        totalLength = totalLength + element[`${fieldName}`].length
      });

      return totalLength;
    }
  }
  return 0;
}

const toProperCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );

};
const validateGstinNumber = (gstinNumber) => {
  if (gstinNumber.length === 15) {
    return true;
  } else {
    return false;
  }
  // const regex = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
  // if (regex.test(gstinNumber)) {
  //   return true;
  // } else {
  //   return false;
  // }
};
const generatePassword = () => {
  let length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const isCharactersValid = (value) => {
  const regex = /^[a-zA-Z -./']+$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

const isEmailValid = (value) => {
  const regex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

const isPhoneNumberValid = (value) => {
  if (value?.length !== 10 || value < 4999999999 || value > 9999999999) {
    return false;
  } else {
    return true;
  }
};

const isValidNumber = (value) => {
  const regex = /^\d+$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
}
const isDOBValid = (value, errorState) => {
  if (value.length <= 8 || value.length > 10) {
    return false;
  } else {
    return true;
  }
};

const isPasswordValid = (value, errorState) => {
  if (value.length < 6 || value.length > 18) {
    return false;
  } else {
    return true;
  }
};

const isConfirmPasswordValid = (confirmPassword, password) => {
  if (confirmPassword !== password) {
    return false;
  } else {
    return true;
  }
};
const isValidPinCode = (value) => {
  const regex = /^([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

const isPanNumberValid = (panNumber) => {
  let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (regpan.test(panNumber)) {
    return true; // valid pan card number
  } else {
    return false; // invalid pan card number
  }
};

const isValidTanNumber = (tanNumber) => {
  let regpan = /^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$/;
  if (regpan.test(tanNumber)) {
    return true; // valid pan card number
  } else {
    return false; // invalid pan card number
  }
};


const isValidDriverLicense = (licenseNumber) => {
  let regdriverLicense = /^([A-Z]{2}[0-9]{2})((19|20)[0-9][0-9])[0-9]{7}$/;
  if (regdriverLicense.test(licenseNumber)) {
    return true     // valid license number
  } else {
    return false; // invalid license number
  }
}

// const isAccessibleWidgetByName = (user, key_code, name) => {
//   //console.log(user)
//   //console.log(key_code)
//   const { accessibleWidgets } = user;
//   if (accessibleWidgets) {
//     const widget = accessibleWidgets?.find(
//       (widget) =>
//         widget.key_code === key_code && widget.widget_name == name &&
//         (widget?.has_read_permission || widget?.has_create_permission)
//     );
//     if (widget) {
//       console.log(widget)
//       return true;
//     }
//     return false;
//   }
//   return false;
// };


const isValidVehicleNumber = (value) => {
  const truckNumberValidationCase_1 = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/g;
  const truckNumberValidationCase_2 = /^[A-Z]{2}[0-9]{1}[A-Z]{3}[0-9]{4}$/g;
  const truckNumberValidationCase_3 = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/g;
  const truckNumberValidationCase_4 = /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/g;
  const truckNumberValidationCase_5 = /^[A-Z]{3}[0-9]{4}$/g;
  if (
    (truckNumberValidationCase_1.test(value) ||
      truckNumberValidationCase_2.test(value) ||
      truckNumberValidationCase_3.test(value) ||
      truckNumberValidationCase_4.test(value) ||
      truckNumberValidationCase_5.test(value)
    )) {
    return true
  }
  return false
}
const isAccessibleWidget = (user, key_code) => {
  //console.log(user)
  //console.log(key_code)
  const { accessibleWidgets } = user;
  if (accessibleWidgets) {
    const widget = accessibleWidgets?.find(
      (widget) =>
        widget.key_code === key_code &&
        (widget?.has_read_permission || widget?.has_create_permission)
    );
    if (widget) {
      // console.log(widget)
      return true;
    }
    return false;
  }
  return false;
};

const isAccessibleWidgetToCreateContract = (user, key_code) => {
  //console.log(user)
  //console.log(key_code)
  const { accessibleWidgets } = user;
  if (accessibleWidgets) {
    const widget = accessibleWidgets?.find(
      (widget) =>
        widget.key_code === key_code &&
        (widget?.has_read_permission && widget?.has_create_permission)
    );
    if (widget) {
      // console.log(widget)
      return true;
    }
    return false;
  }
  return false;
};


const hasWriteAccess = (user, key_code) => {
  const { accessibleWidgets } = user;
  //console.log(accessibleWidgets)
  if (accessibleWidgets) {
    const widget = accessibleWidgets?.find(
      (widget) => (widget.key_code === key_code && widget.has_create_permission === true)
    );
    if (widget) {
      if (window.innerWidth <= 768) {
        return Boolean(widget?.is_accessible_on_mobile);
      }
      else {
        // if (Boolean(widget?.is_accessible_on_web)) {
        return Boolean(widget?.has_create_permission);
        // }
      }
      return false;
    }
    return false;
  }
  return false;
};

const hasUpdateAccess = (user, key_code) => {
  // console.log(user)
  if (user === undefined || user === null) return false;
  const { accessibleWidgets } = user;
  if (accessibleWidgets) {
    const widget = accessibleWidgets?.find(
      (widget) => (widget.key_code === key_code && widget.has_update_permission === true)
    );
    // console.log(widget)
    // console.log(key_code)
    if (widget) {
      if (window.innerWidth <= 768) {
        return Boolean(widget?.is_accessible_on_mobile);
      }
      else {
        // if (Boolean(widget?.is_accessible_on_web)) {
        return Boolean(widget?.has_update_permission);
        // }
      }
      return false;
    }
    return false;
  }
  return false;
};

const hasApproveAccess = (user, key_code) => {
  // console.log(user)
  if (user === undefined || user === null) return false;
  const { accessibleWidgets } = user;
  if (accessibleWidgets) {
    const widget = accessibleWidgets?.find(
      (widget) => (widget.key_code === key_code && widget.has_approve_permission === true)
    );
    // console.log(widget)
    // console.log(key_code)
    if (widget) {
      if (window.innerWidth <= 768) {
        return Boolean(widget?.is_accessible_on_mobile);
      }
      else {
        // if (Boolean(widget?.is_accessible_on_web)) {
        return Boolean(widget?.has_approve_permission);
        // }
      }
      return false;
    }
    return false;
  }
  return false;
};

const hasReadAccess = (user, key_code) => {
  // console.log(user)
  if (user === undefined || user === null) return false;
  const { accessibleWidgets } = user;
  if (accessibleWidgets) {
    const widget = accessibleWidgets?.find(
      (widget) => (widget.key_code === key_code && widget.has_read_permission === true)
    );
    // console.log(key_code)
    if (widget) {
      if (window.innerWidth <= 768) {
        return Boolean(widget?.is_accessible_on_mobile);
      }
      else {
        // if (Boolean(widget?.is_accessible_on_web)) {
        return Boolean(widget?.has_read_permission);
        // }
      }
      return false;
    }
    return false;
  }
  return false;
};


const hasSpecialAccess = (user, key_code) => {
  // console.log(user)
  if (user === undefined || user === null) return false;
  const { accessibleWidgets } = user;
  if (accessibleWidgets) {
    const widget = accessibleWidgets?.find(
      (widget) => (widget.key_code === key_code && widget.has_special_permission === true)
    );
    // console.log(widget)
    // console.log(key_code)
    if (widget) {
      if (window.innerWidth <= 768) {
        return Boolean(widget?.is_accessible_on_mobile);
      }
      else {
        // if (Boolean(widget?.is_accessible_on_web)) {
        return Boolean(widget?.has_special_permission);
        // }
      }
      return false;
    }
    return false;
  }
  return false;
};

const isAccessibleModule = (user, moduleName) => {
  if (!user) return false;
  else if (!user.is_admin) return false;
  else if (!user?.is_active) return false;
  else if (!user?.token) return false;
  else if (!user?.accessibleModules?.find((a) => a === moduleName))
    return false;
  else return true;
};

const isPastRateFilterDateValid = (dateValue) => {
  let regDate = /^\d{4}-\d{1,2}-\d{1,2}$/;
  if (regDate.test(dateValue)) {
    return true;
  } else {
    return false;
  }
};

const isValidCountryCode = (input) => {
  let countryCodeReg = /^(\+?\d{1,3}|\d{1,4})$/;
  if (countryCodeReg.test(input)) {
    return true;
  } else {
    return false;
  }
};
const isValidStdCode = (input) => {
  let stdReg = /^[0-9]\d{2,4}$/;
  if (stdReg.test(input)) {
    return true;
  } else {
    return false;
  }
};

const isValidIFSCCode = (input) => {
  let ifscReg = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
  if (ifscReg.test(input)) {
    return true
  } else {
    return false
  }
}

/**
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
function getUserIP(onNewIP) {
  // onNewIp - your listener function for new IPs
  // compatibility for firefox and chrome
  var myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  var pc = new myPeerConnection({
    iceServers: [],
  }),
    noop = function () { },
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
  // key;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer()
    .then(function (sdp) {
      sdp.sdp.split("\n").forEach(function (line) {
        if (line.indexOf("candidate") < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(function (reason) {
      // An error occurred, so handle the failure to connect
    });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}

function generateCSV(data, fileName) {
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    title: fileName,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: `${fileName}-${new Date().toISOString()}`,
    //   headers: [
    //     {label:'Invoice', key:"doc_no"},
    //    {label:"Date",key:'doc_date'}] ,
  };
  generateCsv(data)(options)
}

function isRequired(propertyName) {
  throw new Error(`${propertyName} is must.`);
}

function options() {
  return {
    headers: {
      Authorization: `JWT ${this.token}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
}

const getOptions = (user) => {
  return {
    headers: {
      Authorization: `JWT ${user.token}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
};

const startDateOFToday = moment().format('YYYY-MM-DD');
const endDateOFToday = moment().format('YYYY-MM-DD');
const startOFWeek = moment().startOf('week').format('YYYY-MM-DD');
const endOFWeek = moment().endOf('week').format('YYYY-MM-DD');
const startOFMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOFMonth = moment().endOf('month').format('YYYY-MM-DD');
const currentMonth = moment().month()
const currentYear = currentMonth > 2 ? moment().year() : moment().year() - 1
const nextYear = currentYear + 1;
const startDateOFYear = `${currentYear}-04-01`
const endDateOFYear = `${nextYear}-03-31`
const monthKeys = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"]
const weekKeys = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export {
  StaticAppVersion,
  checkMobileNumber,
  checkEmailAddress,
  pxToEm,
  compareValues,
  validateGstinNumber,
  generatePassword,
  isCharactersValid,
  isEmailValid,
  isDOBValid,
  isValidNumber,
  isPasswordValid,
  isPhoneNumberValid,
  isConfirmPasswordValid,
  isValidPinCode,
  isPanNumberValid,
  isValidTanNumber,
  isValidDriverLicense,
  isAccessibleWidget,
  isAccessibleModule,
  hasWriteAccess,
  hasUpdateAccess,
  isValidVehicleNumber,
  hasApproveAccess,
  hasReadAccess,
  hasSpecialAccess,
  isPastRateFilterDateValid,
  isValidCountryCode,
  isValidStdCode,
  getUserIP,
  calculatePercent,
  generateCSV,
  checkValidFloatValue,
  isRequired,
  options,
  toProperCase,
  checkAlphaNumericLength,
  checkNameFieldAlphaNumeric,
  checkUpiId,
  checkApiError,
  generateRandomColor,
  filterUnusedField,
  checkIfscCode,
  checkNumeric,
  checkAlphabet,
  isValidClassCode,
  checkTcsPercentage,
  arraySortBykeyAllBrowser,
  isValidIFSCCode,
  PasswordLoginValid,
  checkBrokerage,
  UpperCaseText,
  handlePaymentStatusText,
  checkDuplicateValue,
  numberConvertor,
  getBase64ImageFromURL,
  checkQtyLogic,
  checkInvalidValue,
  handleTotalQtySumCalculation,
  isAccessibleWidgetToCreateContract,
  CaptilizeText,
  getOptions,
  handleSnackbarMessageBox,
  fetchApiDataAndUpdateLocalStorage,
  fetchRecursivePaginatedData,
  b64toBlob,
  printPDFInMobile,
  saveCSVInMobile,
  findValueReturnText,
  startDateOFToday,
  endDateOFToday,
  startOFWeek,
  endOFWeek,
  startOFMonth,
  endOFMonth,
  startDateOFYear,
  endDateOFYear,
  monthKeys,
  weekKeys
  // isAccessibleWidgetByName
};
