import React from 'react'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import { primaryColor } from "../../../../styles/colors";
import "../../../../styles/Admin/AdminProfile.css";
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import moment from "moment";

const styles = {
    adminProfileWebMainBody: {
        // paddingTop: "80px",
        //  height: window.innerHeight,
        background:
            "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
        opacity: 1,
        width: "100%",
    },
    adminProfileWebInternalBody: {
        height: "100%",
        width: "auto",
        padding: "50px 25px 10px 20px",
    },
    myProfileStyle: {
        textTransform: "capitalize",
        fontSize: "1em",
        color: "#1B388B",
        fontWeight: "80%"
    },
};

function AdminProfile(props) {
    const { classes, changePassword } = props;


    return (
        <div className={classes.adminProfileWebMainBody}>
            <div className={classes.adminProfileWebInternalBody}>
                <Breadcrumbs
                    separator=">"
                    aria-label="breadcrumb"
                >
                    <Link
                        className={classes.myProfileStyle}
                    >
                        My Profile
                    </Link>
                </Breadcrumbs>

                <div className="admin-profile-web-header-name-container">
                    <p className="admin-profile-web-header-name-text">
                        {props?.user?.first_name} {props?.user?.last_name}
                    </p>
                </div>
                <div className="admin-profile-web-email-header">
                    <div className="admin-profile-web-email-contanier">
                        <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                            Email
                        </span>
                        <span>{props?.user?.email}</span>
                    </div>
                    <div className="admin-profile-web-gst-container">
                        <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                            UID
                        </span>
                        <span>
                            {props?.user?.employee_id}
                        </span>
                    </div>
                </div>
                <br />
                <div className="admin-profile-web-email-header">
                    <div className="admin-profile-web-email-contanier">
                        <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                            Contact
                        </span>
                        <span>
                            {props?.user?.phone}
                        </span>
                    </div>

                    <div className="admin-profile-web-gst-container">
                        <span style={{ color: "#8B959A", fontSize: "0.9em" }}>
                            Date Of Birth
                        </span>
                        <span>
                            {moment(props?.user?.birth_date).format("DD-MM-YYYY")}
                        </span>
                    </div>
                </div>

                <br />
                <br />

                <div style={{ width: "20%" }}>
                    <List
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            props.history.push(
                                {
                                    pathname: "/userProfile/changePassword",
                                    props: { ...props, changePassword }
                                }
                            );
                        }}
                    >
                        <ListItem style={{ paddingLeft: "0px" }}>
                            <ListItemText
                                primary="Change Password"
                                primaryTypographyProps={{
                                    style: {
                                        fontSize: "1.2em",
                                        color: primaryColor,
                                    },
                                }}
                            />
                            <ListItemSecondaryAction style={{ display: "flex" }}>
                                <img
                                    style={{ width: "1em", height: "1em" }}
                                    src={
                                        window.cordova
                                            ? `${this.image}${"/arrowRight.svg"}`
                                            : "/arrowRight.svg"
                                    }
                                    alt="img"
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(AdminProfile);