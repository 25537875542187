import React, { Component } from "react";
import COLORS from "../../../constants/internal/colors";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  grey,
  secondaryGrey,
  primaryColor,
  whiteSmoke,
  drawerTextColor,
  successButtonBackground,
  secondaryBlueText,
  earthBlue,
  drawerBackground,
} from "../../../constants/internal/colors";

import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Avatar, createMuiTheme, FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,

  InputAdornment,
  Select
} from '@mui/material';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DispatchListTable from "./DispatchListTable";
import LoadingAdviceTable from "../common/LoadingAdviceTable"
import { ThemeProvider } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import Print from "@material-ui/icons/Print";
import Autorenew from "@material-ui/icons/Autorenew";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import DownlodPdf from "./DownloadPdf";
import { arraySortBykeyAllBrowser, handleTotalQtySumCalculation, pxToEm, saveCSVInMobile } from "../../../methods";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import Bread from "../common/Breadcrubs";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import axios from "axios";
import { isEmpty } from "lodash";
import { CSVDownload, CSVLink } from "react-csv";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { plantItemTableHeaders } from "./header";
import ReactToPrint from "react-to-print";
import { handlePrintPlantDetails } from "./PrintContents";
import SnackbarComponent from "../common/SnackbarComponent";

const customersStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: "0.9em",
  },
  filterSection: {
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  searchAutoComplete: {
    display: "flex",
    flex: 5,
  },
  headerData: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
  filterAlignment: {
    display: "flex",
    alignItems: "center",
    flex: 3,
    placeContent: "end",
  },
  addButton: {
    display: "flex",
    flex: 2,
    placeContent: "flex-end",
  },
  searchBox: {
    width: "85%",
  },
  formControl: {
    minWidth: "100%",
    marginLeft: "10px",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingRight: "25px",
    overflowY: "scroll",
    paddingBottom: "30px",
  },
  selectedTab: {
    backgroundColor: whiteSmoke,
    color: grey,
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tabsStyles: {
    backgroundColor: whiteSmoke,
    color: secondaryGrey,
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tabContainerStyle: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "15px",
    display: "flex",
  },
  tabContainerChildStyle: {
    width: "100%",
  },
  tableMainContainerStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableChildContainerStyle: {
    width: "92%",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    padding: "2px 20px 20px 20px",
    marginTop: "2px 20px 20px 20px",
  },
  displayData: {
    marginTop: 10,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
  },
  headerRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  selectFormControl: {
    display: 'flex',

    '& .MuiMenuItem-root': {
      maxHeight: 10,
    },
  },
  dropdownText: {

    '& .MuiTypography-root': {
      padding: '0',
      fontFamily: 'Poppins',
      fontSize: pxToEm(16),
      lineHeight: 1
    },
  },
});

const materialTheme = createMuiTheme({
  overrides: {
    root: {
      fontFamily: 'Poppins',
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
        fontFamily: 'Poppins',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
        fontFamily: 'Poppins',
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
        fontFamily: 'Poppins',
      },
    },
  },
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: "",
    "&$checked": {
      color: successButtonBackground,
    },
    // "&$checked + $track": {
    //   backgroundColor: successButtonBackground,
    // },
  },
  checked: {},
  track: {},
})(Switch);

class Contracts extends Component {
  constructor(props) {
    super(props);
    this.ref1Dispatch = React.createRef();
    this.ref2ReadyQty = React.createRef();
    this.refDispatchCSVLink = React.createRef();
    this.state = {
      searchText: "",
      selectedDate: moment(new Date()).format("YYYY-MM-DD"),
      itemSelect: [],
      statusSelect: [],
      weighbridgeSelect: [],
      itemCategoryIds: [],
      weighBridgeIds: [],
      dispatchPlaningFilteredListLA: [],
      FilterLoader: true,
      dateArray: [],
      dispatchCalculation: [],
      reRenderState: false,
      fetchData: false,
      tabledataChange: false,
      plant_code: "",
      dataInTable: null,
      selectedItemCategoryName: null,
      readyQtyModeSwitch: this.props?.readyQtyModeOpen || false,
      plantName: this.props.plants.find((plant) => plant.id == this.props.match.params.plantId)?.plant_name,
      weekDateArray: this?.props?.dispatchPlaningList,
      csvData: [],
      snackOpen: false,
      dispatchTableCSVData: [],
      dispatchTableCSVLoader: false
    };
  }
  handleFetchLAWeek = (plant_id, selectedDate) => {
    axios
      .get(
        `${APIROUTES.GET_LA_WEEK_DETAILS}?plant_id=${plant_id}&created_at=${selectedDate}`
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            dateArray: response.data,
          });
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }

  handleTableDataChange = (data) => {
    this.setState({ tabledataChange: data })
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleReadyQtyModeSwitch = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'readyQtyModeSwitch') {
      this.setState({ [name]: checked },
        () => {
          checked ?
            this.setState({ selectedDate: moment(new Date()).format("YYYY-MM-DD") }, () => {
              this.props.handleFetchDispatchReadyQtyDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId);
            })
            :
            this.setState({ selectedDate: moment(new Date()).format("YYYY-MM-DD") }, () => {
              this.props.handleFetchDispatchDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId);
            });
        }
      );
    }
  }

  handleDateChangeOnBack = () => {
    this.setState({ selectedDate: moment(sessionStorage.getItem("selectedDate")).format("YYYY-MM-DD") }, () => {
      this.state.readyQtyModeSwitch
        ? this.props.handleFetchDispatchReadyQtyDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId)
        : this.props.handleFetchDispatchDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId);
    });
    this.handleLADetailsTabChange(this.state.selectedDate);
  }

  handleDateChange = (selectedDate) => async (date) => {
    this.setState({ selectedDate: moment(date).format("YYYY-MM-DD") }, () => {
      this.state.readyQtyModeSwitch
        ? this.props.handleFetchDispatchReadyQtyDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId)
        : this.props.handleFetchDispatchDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId);
    });
  };
  handleDispatchTabDateChange = async (date) => {
    this.setState({ selectedDate: moment(date).format("YYYY-MM-DD") }, () => {
      this.state.readyQtyModeSwitch
        ? this.props.handleFetchDispatchReadyQtyDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId)
        : this.props.handleFetchDispatchDetailsWithDate(this.state.selectedDate, sessionStorage.getItem('itemCategoryID'), this.props.match.params.plantId);
    });
  };
  handleLADateChange = (selectedDate) => async (date) => {
    let itemCategories = this.props.plants
      ?.find((p) => p.id == this.props.match?.params?.plantId)
      ?.item_category
    let itemCategoryIds = itemCategories.filter((l) => this.state.itemSelect.some((s) => s == l.name)).map((l) => l.item_category_id)
    let weighBridgeIds = this.props.plantWeighbridge.filter((l) => this.state.weighbridgeSelect.some((s) => s == l.weighbridge_name))
      .map((l) => l.id)

    this.setState({
      selectedDate: moment(date).format("YYYY-MM-DD"),
      itemCategoryIds: itemCategoryIds,
      weighBridgeIds: weighBridgeIds,
    }, () => {
      this.props.handleFetchDispatchDetailsWithFilter(this.state.selectedDate,
        this.props.match.params.plantId, this.props.searchLAValue, itemCategoryIds, //this.props.plants.map - item_category
        this.state.statusSelect,
        weighBridgeIds);
      this.handleFetchLAWeek(this.props.match.params.plantId, this.state.selectedDate);
    });
  };
  componentWillUnmount() {
    this.setState({ selectedDate: moment(new Date()).format("YYYY-MM-DD") })
    this.props.Title !== "Loading Advices" && this.props.handleSearchClear();
    // this.props.handleDateChangeLA("")
    // this.props.handleClearState();
    // sessionStorage.removeItem('itemCategoryID');
    // sessionStorage.removeItem('itemCategoryNAME');
  }
  componentDidMount() {

    if (this.props.history.action === "POP") {
      this.handleDateChangeOnBack();
    }
    // console.log(this.props.history.action)
    // console.log("PP", this.props.dispatchPlaningListLA)
    this.props.plants.map(plant => {
      if (plant.id === Number(this.props.match.params.plantId)) {
        this.setState({ plant_code: plant.plant_code })
      }
    });
    if (this.props?.prevSelectedDate) {
      // console.log(this.props?.prevSelectedDate)
      this.handleDateChange(this.props?.prevSelectedDate)
    }
    if (this.props?.itemCategoryName) {
      this.setState({ selectedItemCategoryName: this.props.itemCategoryName })
    }
    // console.log(this.props)
    // console.log("HERE DISPATCH" + this.props.date)

    // if (this.props.date !== "") {
    // console.log("here date 2" + this.props.date)
    // this.setState({ selectedDate: this.props.date })
    // this.props.handleFetchDispatchDetailsWithDate(this.props.date, this.props.match.params.plantId)
    // this.handleFetchLAWeek(this.props.match.params.plantId, this.props.date)
    // this.props.handleDateChangeLA("")
    // }
    if (this.props.Title !== "Loading Advices" && this.props.dispatchPlaningList && this.props.dispatchPlaningList.length === 0) {
      this.props.handleFetchDispatchDetails("", this.props.match.params.itemId, this.props.match.params.plantId);
    }
    else
      if (this.props?.prevDate) {
        // console.log(this.props.prevDate)
        this.setState({
          selectedDate: this.props.prevDate
        }, () => {
          this.props.handleFetchDispatchLADetails(this.state.selectedDate, this.props.match.params.plantId);
          this.handleFetchLAWeek(this.props.match.params.plantId, this.state.selectedDate);
        })
        // this.handleLADateChange(this.props?.prevDate)
      }
      else if (this.props.date === "" && this.props.Title === "Loading Advices") {
        // console.log("date called")
        this.handleFetchLAWeek(this.props.match.params.plantId, moment(new Date()).format("YYYY-MM-DD"))
      }

  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props)
    let itemName = ""
    props.plants.map(plant => {
      if (plant.id === Number(props.match.params.plantId)) {
        plant.item_category.map(item => {
          if (item.item_category_id === Number(props.match.params.itemId))
            itemName = item.name
        })
      }
    })

    // console.log(id)
    sessionStorage.setItem('itemCategoryID', props.match.params.itemId)
    sessionStorage.setItem('itemCategoryNAME', itemName)
    // if (itemName) return { fetchData: true }
    if (props.match.params.itemId && props.match.params.plantId && !props.selectedItemCategory && !props.selectedPlant) {
      // console.log("here")
      // return { fetchData: true }

      // props.history.push(`/dispatchPlanning/${props.match.params.itemId}`)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dispatchPlaningListLA !== this.props.dispatchPlaningListLA) {
      let itemData = [...this.state?.itemSelect]
      let statusData = [...this.state?.statusSelect]
      let weighbridgeData = [...this.state?.weighbridgeSelect]
      // console.log(itemData.length + " : " + statusData.length + " : " + weighbridgeData.length)
      if (this.state?.itemSelect ||
        this.state?.statusSelect ||
        this.state?.weighbridgeSelect) {
        if (this.state?.itemSelect.length > 0) {
          // this.setState({ itemSelect: [], FilterLoader: true },
          //   () => { this.handleSelect("itemSelect", itemData) }
          // )
        }
        else if (this.state?.statusSelect.length > 0) {
          // this.setState({ statusSelect: [], FilterLoader: true },
          //   () => { this.handleSelect("statusSelect", statusData) }
          // )
        }
        else if (this.state?.weighbridgeSelect.length > 0) {
          // this.setState({ weighbridgeSelect: [], FilterLoader: true },
          //   () => { this.handleSelect("weighbridgeSelect", weighbridgeData) }
          // )
        }
        else { }
      }
    }
    if (prevProps.date !== this.props.date && this.props.date !== "") {
      this.setState({ selectedDate: this.props.date })
      this.props.handleFetchDispatchDetailsWithDate(this.props.date, this.props.match.params.plantId)
      this.handleFetchLAWeek(this.props.match.params.plantId, this.props.date)
      this.props.handleDateChangeLA("")
      // console.log("here date" + this.props.date)
    }
    if (prevProps.clickLaConformDate !== this.props.clickLaConformDate && this.props.clickLaConformDate !== "") {
      this.handleFetchLAWeek(this.props.match.params.plantId, this.props.clickLaConformDate)
    }
    if (prevProps.dispatchPlaningList !== this?.props?.dispatchPlaningList) {
      if ((prevProps.dispatchPlaningList).length !== 0 || (this?.props?.dispatchPlaningList).length !== 0) {
        // if (!Boolean(this.state?.weekDateArray.find(d => d.date == this.state?.selectedDate))) {
        //        console.log(prevProps?.dispatchPlaningList, this?.props?.dispatchPlaningList)
        this.setState({
          weekDateArray: (this?.props?.dispatchPlaningList).length !== 0
            ? this?.props?.dispatchPlaningList
            : prevProps?.dispatchPlaningList
        })
      }
    }
  }

  handleSelect = (name, value) => {
    this.setState({
      [name]: typeof value === 'string' ? value.split(',') : value,
      FilterLoader: true
    },
      () => {
        let itemCategories = this.props.plants
          ?.find((p) => p.id == this.props.match?.params?.plantId)
          ?.item_category
        let itemCategoryIds = itemCategories.filter((l) => this.state.itemSelect.some((s) => s == l.name)).map((l) => l.item_category_id)
        let weighBridgeIds = this.props.plantWeighbridge.filter((l) => this.state.weighbridgeSelect.some((s) => s == l.weighbridge_name))
          .map((l) => l.id)
        this.setState({
          itemCategoryIds: itemCategoryIds,
          weighBridgeIds: weighBridgeIds,
        })
        this.props.handleFetchDispatchDetailsWithFilter(this.state.selectedDate,
          this.props.match.params.plantId, this.props.searchLAValue, itemCategoryIds, //this.props.plants.map - item_category
          this.state.statusSelect,
          weighBridgeIds)
      })

  }

  handleItemSelect = (event) => {
    // console.log("here ItemSelect")
    if (this.state?.itemSelect.length > 0) {
      this.setState({
        dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA?.filter(data => this.state.itemSelect?.includes(data.item_category.category_name)),
      },
        () => {
          if (this.state?.statusSelect.length > 0) {
            this.setState({
              dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.statusSelect?.includes(data.dispatch_status))
            },
              () => {
                if (this.state?.weighbridgeSelect.length > 0) {
                  this.setState({
                    dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
                  }, () => {
                    this.setState({ FilterLoader: false })
                  })
                }
                else {
                  this.setState({ FilterLoader: false })
                }
              }
            )
          }
          else if (this.state?.weighbridgeSelect.length > 0) {
            this.setState({
              dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
            }, () => {
              this.setState({ FilterLoader: false })
            })
          }
          else {
            this.setState({ FilterLoader: false })
          }
        }
      )
    }
    else {
      if (this.state?.statusSelect.length > 0) {
        this.setState({
          dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA?.filter(data => this.state.statusSelect?.includes(data.dispatch_status))
        },
          () => {
            if (this.state?.weighbridgeSelect.length > 0) {
              this.setState({
                dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
              }, () => {
                this.setState({ FilterLoader: false })
              })
            }
            else {
              this.setState({ FilterLoader: false })
            }
          }
        )
      }
      else if (this.state?.weighbridgeSelect.length > 0) {
        this.setState({
          dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
        }, () => {
          this.setState({ FilterLoader: false })
        })
      }
      else {
        this.setState({ FilterLoader: true })
      }
    }
  };

  handleStatusSelect = (event) => {
    // console.log("here StatusSelect")
    if (this.state?.statusSelect.length > 0) {
      this.setState({
        dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA.filter(data => this.state.statusSelect?.includes(data.dispatch_status)),
      },
        () => {
          if (this.state?.itemSelect.length > 0) {
            this.setState({
              dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.itemSelect?.includes(data.item_category.category_name)),
            },
              () => {
                if (this.state?.weighbridgeSelect.length > 0) {
                  this.setState({
                    dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
                  }, () => {
                    this.setState({ FilterLoader: false })
                  })
                }
                else {
                  this.setState({ FilterLoader: false })
                }
              }
            )
          }
          else if (this.state?.weighbridgeSelect.length > 0) {
            this.setState({
              dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
            }, () => {
              this.setState({ FilterLoader: false })
            })
          }
          else {
            this.setState({ FilterLoader: false })
          }
        }
      )
    }
    else {
      if (this.state?.itemSelect.length > 0) {
        this.setState({
          dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA?.filter(data => this.state.itemSelect?.includes(data.item_category.category_name)),
        },
          () => {
            if (this.state?.weighbridgeSelect.length > 0) {
              this.setState({
                dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
              }, () => {
                this.setState({ FilterLoader: false })
              })
            }
            else {
              this.setState({ FilterLoader: false })
            }
          }
        )
      }
      else if (this.state?.weighbridgeSelect.length > 0) {
        this.setState({
          dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA?.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
        }, () => {
          this.setState({ FilterLoader: false })
        })
      }
      else {
        this.setState({ FilterLoader: true })
      }
    }
  };

  handleWeighBridgeSelect = (event) => {
    // console.log("here weighbridgeSelect")
    if (this.state?.weighbridgeSelect.length > 0) {
      this.setState({
        dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA.filter(data => this.state.weighbridgeSelect?.includes(data?.weighbridge?.weighbridge_name)),
      },
        () => {
          if (this.state?.itemSelect.length > 0) {
            this.setState({
              dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.itemSelect?.includes(data.item_category.category_name)),
            },
              () => {
                if (this.state?.statusSelect.length > 0) {
                  this.setState({
                    dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.statusSelect?.includes(data.dispatch_status)),
                  }, () => {
                    this.setState({ FilterLoader: false })
                  })
                }
                else {
                  this.setState({ FilterLoader: false })
                }
              }
            )
          }
          else if (this.state?.statusSelect.length > 0) {
            this.setState({
              dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.statusSelect?.includes(data.dispatch_status)),
            }, () => {
              this.setState({ FilterLoader: false })
            })
          }
          else {
            this.setState({ FilterLoader: false })
          }
        }
      )
    }
    else {
      if (this.state?.itemSelect.length > 0) {
        this.setState({
          dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA?.filter(data => this.state.itemSelect?.includes(data.item_category.category_name)),
        },
          () => {
            if (this.state?.statusSelect.length > 0) {
              this.setState({
                dispatchPlaningFilteredListLA: this.state.dispatchPlaningFilteredListLA?.filter(data => this.state.statusSelect?.includes(data.dispatch_status)),
              }, () => {
                this.setState({ FilterLoader: false })
              })
            }
            else {
              this.setState({ FilterLoader: false })
            }
          }
        )
      }
      else if (this.state?.statusSelect.length > 0) {
        this.setState({
          dispatchPlaningFilteredListLA: this.props.dispatchPlaningListLA?.filter(data => this.state.statusSelect?.includes(data.dispatch_status)),
        }, () => {
          this.setState({ FilterLoader: false })
        })
      }
      else {
        this.setState({ FilterLoader: true })
      }
    }
  };

  handlePrintTabl = () => {
    // var divElements = $('.c3 .nicEdit-main').html();
    let printContents = document.getElementById("print-details").innerHTML;

    // var divToPrint = document.getElementById('divToPrint');
    var popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write("<html><head><title></title></head><body>" + printContents + "</body>");
    popupWin.print()
    // popupWin.document.close();
  }

  handlePrintTable = () => {
    this.props?.handlePrintReload(this.state.readyQtyModeSwitch)
    let printId = this.state.readyQtyModeSwitch ? "print-ready-qty-details" : "print-details"
    let printContents = document.getElementById(printId).innerHTML;
    // let originalContents = document.body.innerHTML;
    document.body.innerHTML =
      "<html><head><title></title></head><body>" + printContents + "</body>";
    window.focus()
    window.print();
    window.location.reload()
    // this.props.plants.find((plant) => plant.id == this.props.match.params.plantId)?.plant_name + " | " + sessionStorage.getItem('itemCategoryNAME')
    this.props.history.push(`/dispatchPlanning/${this.props.match.params.itemId}/${this.props.match.params.plantId}`)
  };

  handleTotalSumCalculation = (arr, fieldName, nestedFieldName = 0) => {
    if (arr) {
      if (nestedFieldName !== 0) {
        let sum = arr?.reduce((a, c) => {
          return Number(a) + Number(
            (c[`${fieldName}`] && c[`${fieldName}`][`${nestedFieldName}`]) !== undefined
              ? c[`${fieldName}`][`${nestedFieldName}`]
              : 0)
        }, 0)
        return String(sum).match(/^\d*\.?\d{0,3}$/) ? sum : sum.toFixed(3)
      }
      else {
        let sum = arr?.reduce((a, c) => { return Number(a) + Number(c[`${fieldName}`]) }, 0)
        return String(sum).match(/^\d*\.?\d{0,3}$/) ? sum : sum.toFixed(3)
      }
    }
    return 0;
  }

  handleReadyQtyCsvData = (data) => {
    let totalSum = {
      first_name: '',
      last_name: '',
      customer_id: 'CALCULATION ',
      open_quantity: {
        open_quantity: this.handleTotalSumCalculation(data, 'open_quantity', 'open_quantity'),
      },
      today_open_quantity: this.handleTotalSumCalculation(data, 'today_open_quantity'),
      ready_quantity: this.handleTotalSumCalculation(data, 'ready_quantity'),
      loading_advice_pending: {
        pending_quantity: this.handleTotalSumCalculation(data, 'loading_advice_pending', 'pending_quantity'),
        total_la: this.handleTotalSumCalculation(data, 'loading_advice_pending', 'total_la')
      },
      loading_advice_approved: {
        approved_quantity: this.handleTotalSumCalculation(data, 'loading_advice_approved', 'approved_quantity'),
        total_la: this.handleTotalSumCalculation(data, 'loading_advice_approved', 'total_la')
      },
      loading_advice_inplant: {
        in_plant_quantity: this.handleTotalSumCalculation(data, 'loading_advice_inplant', 'in_plant_quantity'),
        total_la: this.handleTotalSumCalculation(data, 'loading_advice_inplant', 'total_la')
      },
      dispatched_quantity: {
        dispatch_quantity: this.handleTotalSumCalculation(data, 'dispatched_quantity', 'dispatch_quantity'),
        total_la: this.handleTotalSumCalculation(data, 'dispatched_quantity', 'total_la')
      },
    }
    data = data.map(v => ({ ...v, date: moment(this.state.selectedDate).format("DD-MMM-YY") }))
    let sortedData = arraySortBykeyAllBrowser(data, 'first_name');
    sortedData.push(totalSum)

    // console.log(totalSum, data)
    this.setState({ csvData: sortedData })
  }

  fetchAllDispatchCSVData = (itemID, plantID, selectedDate, headers) => {

    this.setState({ dispatchTableCSVLoader: true })
    axios.get(
      `${APIROUTES.DISPATCH_TABLE_PDF}?item_category_id=${itemID}&plant_id=${plantID}&date=${selectedDate}`)
      .then((response) => {
        if (response.status === 200) {
          let allData = response.data;
          let csvData = [];
          allData.map(data => {
            let newObject = {}
            headers.map(h => {
              newObject[h.label] = h.key === 'date'
                ? moment(selectedDate).format("DD-MMM-YY")
                : h.key === 'created_at'
                  ? moment(data.created_at).format("DD-MMM-YY")
                  : h.key === 'loading_advices_length'
                    ? data.loading_advices.length > 0 ? data.loading_advices.length : 0
                    : data[h.key]
            })
            csvData.push(newObject);
          })
          let BottomCalc = {}
          headers.map(h => {
            BottomCalc[h.label] = ['date', 'order_number', 'created_at'].includes(h.key)
              ? ""
              : h.key === 'first_name'
                ? 'CALCULATION '
                : h.key === 'loading_advices_length'
                  ? handleTotalQtySumCalculation(allData, 'loading_advices', 0, 'length')
                  : handleTotalQtySumCalculation(allData, h.key)
          })
          csvData.push(BottomCalc)
          this.setState({
            dispatchTableCSVData: csvData,
            dispatchTableCSVLoader: false
          }, () => {
            // console.log(this.refDispatchCSVLink.current)
            window.cordova
              ? saveCSVInMobile(csvData, '', `Dispatch_Planning_Contract_Wise.csv`, this.handleOpenSnackClick, '')
              : this.refDispatchCSVLink.current.link.click();
          })
        }
      })
      .catch((error) => {
        this.setState({ dispatchTableCSVLoader: false })
      });
  };

  handleLADetailsTabChange = (selectedDate) => {
    this.props?.enableLoader && this.props.enableLoader();
    let itemCategories = this.props.plants
      ?.find((p) => p.id == this.props.match?.params?.plantId)
      ?.item_category
    let itemCategoryIds = itemCategories?.filter((l) => this.state.itemSelect?.some((s) => s == l.name))?.map((l) => l.item_category_id)
    let weighBridgeIds = this.props.plantWeighbridge?.filter((l) => this.state.weighbridgeSelect?.some((s) => s == l.weighbridge_name))
      .map((l) => l.id)

    this.setState({
      selectedDate: moment(selectedDate).format("YYYY-MM-DD"),
      itemCategoryIds: itemCategoryIds,
      weighBridgeIds: weighBridgeIds,
    },
      () => {
        this.props.handleFetchDispatchDetailsWithFilter && this.props.handleFetchDispatchDetailsWithFilter(this.state.selectedDate,
          this.props.match.params.plantId, this.props.searchLAValue, itemCategoryIds, //this.props.plants.map - item_category
          this.state.statusSelect,
          weighBridgeIds)
        //this.props.handleFetchDispatchDetailsWithDate(selectedDate, this.props.match.params.plantId);
      })
  }

  handleOpenSnackClick = () => {
    this.setState({ snackOpen: true })
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackOpen: false })
  };

  render() {
    const { tabValue, readyQtyModeSwitch } = this.state;
    const { classes, dispatchPlaningList, location, isMobile } = this.props;
    const LAStatus = [
      { id: 0, status: 'PENDING' },
      { id: 1, status: 'APPROVED' },
      { id: 2, status: 'GATEIN' },
      { id: 3, status: 'LOADING' },
      { id: 4, status: 'PACKED' },
      { id: 5, status: 'ALLOTED' },
      { id: 6, status: 'BILLED' },
      { id: 7, status: 'DISPATCHED' },
      { id: 8, status: 'LAPSED' },
      { id: 9, status: 'UNLOADING' },
      { id: 10, status: 'UNLOADED' },
      { id: 11, status: 'REJECTED' },
      { id: 12, status: 'CANCELLED' },
      { id: 13, status: 'HOLD' }
    ]
    // if()
    // console.log(this.props.location.state)
    // console.log(sessionStorage)
    // console.log(this.state.fetchData)
    // console.log(this.props)
    return (
      <div className={classes.mainDiv}>
        {/* {console.log("here Ready ", this.props?.readyQtyModeOpen)} */}
        <div className={classes.rightPadding}>
          {this.props.Title !== "Loading Advices" ?
            (
              <Bread
                data={[
                  {
                    title: this.props.Title,
                    onClick: () => {
                      this.props.history.push(this.props.routeLink)
                      this.state.tabledataChange && this.props.handelFetchPlants()
                    },
                  },
                  {
                    title: this.props.plants.find((plant) => plant.id == this.props.match.params.plantId)?.plant_name + " | " + sessionStorage.getItem('itemCategoryNAME'),
                    onClick: "",
                  },
                ]}
              />
            )
            :
            (
              <Bread
                data={[
                  {
                    title: "Loading Advices",
                    onClick: () => this.props.history.push(INTERNALROUTES.LOADINGADVICE),
                  },
                  {
                    title: this.props.plants.map((plant) => { if (plant.id == this.props.match.params.plantId) return plant.plant_name }),
                    onClick: "",
                  },
                ]}
              />
            )
          }
          {this.props.Title !== "Loading Advices" ?
            <div className={classes.displayData}>
              <Paper className={classes.paperStyle} style={{ padding: readyQtyModeSwitch && "2px 20px 40px 20px", }}>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                  <div
                    className={classes.headerContainer}
                    style={{
                      padding: '20px 10px',
                      justifyContent: 'space-between',
                      flexDirection: isMobile && 'column',
                      width: isMobile ? '100%' : '75%'
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      // label="Search"
                      variant="outlined"
                      size="small"
                      style={{
                        width: isMobile ? '100%' : 340,
                        justifyContent: 'flex-start',
                        marginBottom: isMobile && 20,
                      }}
                      placeholder="Search using Contract No, GST/PAN, Name"
                      value={this.props.searchValue}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{ padding: 0, display: this.props.searchValue === '' ? 'none' : 'block' }}
                              onClick={() => {
                                this.setState({ selectedDate: moment(new Date()).format("YYYY-MM-DD"), },
                                  () => {
                                    this.props.handleSearchClear();
                                    !this.state.readyQtyModeSwitch
                                      ? this.props.handleFetchDispatchDetails("", this.props.match.params.itemId, this.props.match.params.plantId)
                                      : this.props.handleFetchDispatchReadyQtyDetailsWithDate(this.state.selectedDate, this.props.match.params.itemId, this.props.match.params.plantId)
                                  }
                                );
                              }}
                            >
                              <CloseOutlinedIcon />
                            </IconButton>
                            <IconButton>
                              <SearchOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          paddingRight: 0
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          selectedDate: this.state.selectedDate
                        })
                        !this.state.readyQtyModeSwitch
                          ? this.props.handleSearchTable(
                            e.target.value,
                            sessionStorage.getItem("itemCategoryID"),
                            this.props.match.params.plantId
                          )
                          :
                          this.props.handleSearchCustomerTable(
                            e.target.value,
                            sessionStorage.getItem("itemCategoryID"),
                            this.props.match.params.plantId,
                            this.state?.selectedDate
                          )
                      }}
                    />
                    <div className={classes.headerRightContainer} style={{ alignItems: 'center', marginBottom: isMobile && 10 }}>
                      <div style={{ width: "60%" }}>
                        <ThemeProvider theme={materialTheme}>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid
                              container
                              justify="space-around"
                              style={{
                                width: "100%",
                                background: "#8080804f",
                                display: "flex",
                                alignItems: "center",
                                height: "5vh",
                                borderRadius: "20px 20px",
                                paddingRight: 10,
                              }}
                            >
                              <KeyboardDatePicker
                                autoOk
                                size="small"
                                variant="inline"
                                format="dd/MM/yyyy"
                                value={this.state.selectedDate}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                  style: {
                                    backgroundColor: "#9E9E9E",
                                    padding: "6px",
                                    color: "white",
                                  },
                                }}
                                InputAdornmentProps={{
                                  position: "start",
                                  backgroundColor: "red",
                                }}
                                InputProps={{
                                  size: "small",
                                  disableUnderline: true,
                                  backgroundColor: "red",
                                  readOnly: true
                                }}
                                onChange={this.handleDateChange("startDate")}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </div>
                    </div>
                    <div className={classes.filterAlignment}>
                      <FormControlLabel
                        control={
                          <PurpleSwitch
                            checked={readyQtyModeSwitch}
                            onChange={this.handleReadyQtyModeSwitch}
                            name="readyQtyModeSwitch"
                            color="primary"
                          />
                        }
                        label="Ready Qty Mode"
                      />
                    </div>
                  </div>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isMobile ? 'center' : "flex-end",
                    width: isMobile ? '100%' : '25%',
                    paddingLeft: isMobile && 40,
                  }}>
                    <Tooltip title="Print" placement="top">
                      {!this.state.readyQtyModeSwitch ?
                        <span
                          style={{
                            display: "flex",
                            // width: '25%',
                            alignItems: "center",
                          }}
                          onClick={() =>
                            this.props?.dispatchTablePdf(
                              sessionStorage.getItem("itemCategoryID"),
                              this.props.match.params.plantId,
                              this.state?.selectedDate,
                              this.handleOpenSnackClick,
                              {
                                item_category_name: sessionStorage.getItem('itemCategoryNAME'),
                                plant_name: this.props.plants.find((plant) => plant.id == this.props.match.params.plantId)?.plant_name,
                                selectedDate: this.state.selectedDate,
                                userName: this.props.user?.full_name


                              }
                            )
                            // handlePrintPlantDetails('dispatch Table', this.props.user?.full_name, this.handleOpenSnackClick, "PRINT DISPATCH")
                          }
                        >
                          <Print style={{ color: "#4b87c5", cursor: "pointer" }} />
                          {
                            this.props?.dispatchTablePrintLoader &&
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                transform: 'scale(0.5)'
                              }}
                            >
                              <CircularProgress />
                            </div>
                          }
                        </span>
                        :
                        <span
                          style={{
                            display: "flex",
                            paddingBottom: 5,
                            // width: '25%',
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handlePrintPlantDetails(
                              this.state.csvData,
                              {
                                item_category_name: sessionStorage.getItem('itemCategoryNAME'),
                                plant_name: this.props.plants.find((plant) => plant.id == this.props.match.params.plantId)?.plant_name,
                                selectedDate: this.state.selectedDate,
                                userName: this.props.user?.full_name
                              },
                              this.handleOpenSnackClick,
                              "PRINT READY QTY"
                            )
                          }
                        >
                          <Print style={{ color: "#4b87c5", cursor: "pointer" }} />
                        </span>
                      }
                    </Tooltip>
                    <SnackbarComponent
                      open={this.state.snackOpen}
                      handleClose={this.handleClose}
                      customAutoHideDuration={3000}
                      snackbarMessage="Downloading PDF..."
                    />
                    {readyQtyModeSwitch ?
                      <Tooltip title="Download CSV" placement="top">
                        <div style={{
                          margin: '0 20px',
                        }}>
                          {console.log(this.state?.csvData)}
                          <CSVLink
                            data={this.state?.csvData}
                            headers={plantItemTableHeaders('ready_qty_mode')}
                            onClick={(event, done) => {
                              window.cordova && saveCSVInMobile(
                                this.state?.csvData,
                                plantItemTableHeaders('ready_qty_mode'),
                                `Dispatch_Planning_Customer_Wise.csv`,
                                this.handleOpenSnackClick,
                                ''
                              )
                            }}
                            filename={`Dispatch Planning Ready Qty ${moment().format(
                              "DD-MM-YYYY , h-mm"
                            )}.csv`}
                          >
                            <CloudDownloadOutlinedIcon
                              style={{ color: "#4b87c5" }}
                            />
                          </CSVLink>
                        </div>
                      </Tooltip>
                      :
                      <Tooltip title="Download CSV" placement="top">
                        <div style={{
                          margin: '0 20px',
                          display: 'flex'
                        }}>
                          <IconButton
                            style={{ display: 'flex' }}
                            onClick={() => this.fetchAllDispatchCSVData(
                              sessionStorage.getItem("itemCategoryID"),
                              this.props.match.params.plantId,
                              this.state.selectedDate,
                              plantItemTableHeaders('dispatch_mode')
                            )}
                          >
                            <CloudDownloadOutlinedIcon
                              style={{ color: "#4b87c5" }}
                            />
                            {
                              this.state.dispatchTableCSVLoader &&
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  transform: 'scale(0.5)'
                                }}
                              >
                                <CircularProgress />
                              </div>
                            }
                          </IconButton>
                          <CSVLink
                            data={this.state.dispatchTableCSVData}
                            filename={!window.cordova
                              ? `Dispatch Planning ${moment().format(
                                "DD-MM-YYYY , h-mm"
                              )}.csv`
                              : `Dispatch Planning.csv`}
                            className="hidden"
                            ref={this.refDispatchCSVLink}
                            target="_blank"
                          />
                        </div>
                      </Tooltip>
                    }
                  </div>
                </div>

                <Paper>
                  <Tabs
                    variant={isMobile && "scrollable"}
                  // scrollButtons={isMobile && 'on'} 
                  // allowScrollButtonsMobile={isMobile && true}
                  >
                    {(this.props?.dispatchLoader ?
                      this.state?.weekDateArray
                        ? this.state?.weekDateArray
                        : dispatchPlaningList
                      : dispatchPlaningList
                    ).map((data, index) => (

                      <>
                        <Tab
                          onClick={(e) => {
                            e.preventDefault();

                            this.setState({ selectedDate: data.date, dataInTable: data },
                              () => {
                                this.handleDispatchTabDateChange(new Date(data.date))
                                // !this.props.searchValue && this.props.handleSearchClear();
                              })

                            //this.props.setRowsCount(data?.orders?.length)
                            this.props.handleTableData(data.date)

                          }}
                          style={{
                            flex: !isMobile && 1,
                            padding: "0px",
                            borderBottom:
                              this.state.selectedDate === data.date ? `2px solid ${primaryColor}` : null,
                          }}
                          label={
                            <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: 'center',
                                  height: 20,
                                  paddingLeft: moment(data.date).format("MMM Do ") == moment(new Date()).format("MMM Do ") && 15
                                }}
                              >
                                {/* {console.log(this.state?.weekDateArray, this.state?.selectedDate, this.state?.weekDateArray[0] && Boolean(this.state?.weekDateArray[0].find(d => d.date == this.state?.selectedDate)))} */}
                                <span style={{ color: "black" }}>
                                  {moment(data.date).format("MMM Do ")}
                                </span>
                                {moment(data.date).format("MMM Do ") == moment(new Date()).format("MMM Do ") &&
                                  <span>
                                    <Avatar
                                      style={{
                                        // display: 'inline',
                                        backgroundColor: "#000",
                                        color: "#FFF",
                                        fontWeight: '700',
                                        // paddingLeft: 2,
                                        transform: 'scale(0.4)',
                                        fontSize: pxToEm(36)
                                      }}
                                    >
                                      T
                                    </Avatar>
                                  </span>
                                }
                              </div>
                              <span style={{
                                height: "15px"
                              }}>
                                {data.orders_qty?.total_la > 0 ? (data.orders_qty?.dispatched_la + "/" + data.orders_qty?.total_la + " LA") : ""}
                              </span>
                            </div>
                          }
                          className={
                            index === 2 ? classes.selectedTab : classes.tabsStyles
                          }
                        />
                      </>
                    ))}
                  </Tabs>
                  <div>
                    <DispatchListTable
                      tabValue={tabValue}
                      {...this.props}
                      {...this.state}
                      itemId={sessionStorage.getItem('itemCategoryID')}
                      plantId={this.props.match.params.plantId}
                      selectedDate={this.state.selectedDate}
                      anchorElUpdated={this.props.anchorEl}
                      openUpdated={this.props.open}
                      plants={this.props.plants}
                      plantsName={(this.props.plants.find((plant) => plant.id == this.props.match.params.plantId))?.plant_name}
                      plantsCode={(this.props.plants.find((plant) => plant.id == this.props.match.params.plantId))?.plant_code}
                      itemCategoryName={this.props?.prevItemCategoryName || this.state?.selectedItemCategoryName}
                      handleSelectedOrderDate={this.props.handleSelectedOrderDate}
                      // prevSelectedDate={this.props?.prevSelectedDate}
                      fetchPendingQuantity={this.props.fetchPendingQuantity}
                      handleActionMenuClose={this.props.handleActionMenuClose}
                      readyQtyModeSwitch={readyQtyModeSwitch}
                      handleTableDataChange={this.handleTableDataChange}
                      handleReadyQtyCsvData={this.handleReadyQtyCsvData}
                      handleTotalSumCalculation={this.handleTotalSumCalculation}
                      Ref1Dispatch={this.ref1Dispatch}
                      Ref2ReadyQty={this.ref2ReadyQty}
                    />
                  </div>
                </Paper>
                {/* <div style={{ display: "flex", alignItems: "center", marginTop: "5px", justifyContent: "flex-end" }}>
                    <Button onClick={() => this.handlePrintTable()}>
                      <Print style={{ marginRight: "3px" }} />
                      PRINT
                    </Button>
                    <DownlodPdf
                      fileName={`Dispatch_details_${moment(new Date()).format(
                        "DD/MM/YYYY_LT"
                      )}`}
                      printSection="print-details"
                      headerDate={"Dispatch Details"}
                    />
                  </div> */}
              </Paper>
            </div>
            :
            <div className={classes.displayData}>
              <Paper className={classes.paperStyle}>
                <div
                  className={classes.headerContainer}
                  style={{
                    padding: '20px 10px 10px 10px',
                    flexDirection: isMobile && 'column',
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    style={{
                      width: isMobile ? '100%' : 380,
                      justifyContent: 'flex-start',
                      marginBottom: isMobile && 20,
                    }}
                    placeholder="Search using LA, bill no. & truck no."
                    value={this.props.searchLAValue}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              padding: 0,
                              display: this.props.searchLAValue === '' ? 'none' : 'block'
                            }}
                            onClick={() => {
                              let itemCategories = this.props.plants
                                ?.find((p) => p.id == this.props.match?.params?.plantId)
                                ?.item_category
                              let itemCategoryIds = itemCategories.filter((l) => this.state.itemSelect.some((s) => s == l.name)).map((l) => l.item_category_id)
                              let weighBridgeIds = this.props.plantWeighbridge.filter((l) => this.state.weighbridgeSelect.some((s) => s == l.weighbridge_name))
                                .map((l) => l.id)
                              this.setState({
                                itemCategoryIds: itemCategoryIds,
                                weighBridgeIds: weighBridgeIds,
                              })
                              this.props.handleLASearchClear();
                              this.props.handleFetchDispatchDetailsWithFilter(
                                this.state.selectedDate,
                                this.props.match.params.plantId,
                                "",
                                itemCategoryIds,
                                this.state.statusSelect,
                                weighBridgeIds
                              );
                            }}
                          >
                            <CloseOutlinedIcon />
                          </IconButton>
                          <IconButton>
                            <SearchOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: 0
                      }
                    }}
                    onChange={(e) => {
                      this.setState({
                        selectedDate: this.state.selectedDate,
                      })
                      let itemCategories = this.props.plants
                        ?.find((p) => p.id == this.props.match?.params?.plantId)
                        ?.item_category
                      let itemCategoryIds = itemCategories.filter((l) => this.state.itemSelect.some((s) => s == l.name)).map((l) => l.item_category_id)
                      let weighBridgeIds = this.props.plantWeighbridge.filter((l) => this.state.weighbridgeSelect.some((s) => s == l.weighbridge_name))
                        .map((l) => l.id)
                      this.setState({
                        itemCategoryIds: itemCategoryIds,
                        weighBridgeIds: weighBridgeIds,
                      })
                      this.props.handleLASearchValue(
                        e.target.value,
                        this.state.selectedDate,
                        this.props.match.params.plantId,
                        itemCategoryIds,
                        this.state.statusSelect,
                        weighBridgeIds
                      )
                    }}
                  />
                  {/* <p style={{ display: "flex", alignItems: "center" }}>
                  {this.props.plants.map((plant) => {
                      if (plant.id == this.props.match.params.plantId) return plant.plant_name
                    })}
                  <IconButton
                    style={{
                      marginLeft: "4px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(
                        {
                          itemSelect: [],
                          statusSelect: [],
                          weighbridgeSelect: [],
                          selectedDate: moment(new Date()).format("YYYY-MM-DD"),
                        },
                        () => {
                          this.props.handleFetchDispatchLADetails(this.state.selectedDate, this.props.match.params.plantId);
                          this.handleFetchLAWeek(this.props.match.params.plantId, this.state.selectedDate);
                          this.props.handleFetchPlantWeighbridge(this.props.match.params.plantId);
                        }
                      );
                      this.setState({ FilterLoader: true })
                    }}
                  >
                    <Autorenew
                      style={{
                        color: COLORS.secondaryBlueText,
                      }}
                    />
                  </IconButton>
                  </p> */}
                  <div className={classes.headerRightContainer} style={{ alignItems: isMobile && "center", }}>
                    <div style={{ width: "60%", marginBottom: isMobile && 10, }}>
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            container
                            justify="space-around"
                            style={{
                              width: "100%",
                              background: "#8080804f",
                              display: "flex",
                              alignItems: "center",
                              height: "5vh",
                              borderRadius: "20px 20px",
                              paddingRight: 10,
                            }}
                          >
                            <KeyboardDatePicker
                              autoOk
                              size="small"
                              variant="inline"
                              format="dd/MM/yyyy"
                              value={this.state.selectedDate}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                                style: {
                                  backgroundColor: "#9E9E9E",
                                  padding: "6px",
                                  color: "white",
                                },
                              }}
                              InputAdornmentProps={{
                                position: "start",
                                backgroundColor: "red",
                              }}
                              InputProps={{
                                size: "small",
                                disableUnderline: true,
                                backgroundColor: "red",
                                readOnly: true
                              }}
                              onChange={this.handleLADateChange("startDate")}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? 'column' : 'row',
                    margin: '0 0 5px 0',
                    padding: '5px 10px'
                  }}
                >
                  <FormControl
                    variant='outlined'
                    sx={{ margin: isMobile ? '10px 0' : '0 8px 0 0', width: isMobile ? '100%' : 200 }}
                    className={classes.selectFormControl}
                    size="small"
                  >
                    <InputLabel id="Item-select-labeled">
                      Item Category
                    </InputLabel>
                    <Select
                      // autoFocus
                      labelId="Item-select-labeled"
                      id="Item-select-label"
                      name="itemSelect"
                      value={this.state.itemSelect}
                      // onChange={this.handleItemSelect}
                      onChange={(e) => this.handleSelect(e.target.name, e.target.value)}
                      multiple
                      autoWidth
                      input={<OutlinedInput label="Item Category" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: (48 * 4.5) + 6, width: isMobile ? '100%' : 200
                          }
                        },
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem
                        disabled
                        value="All"
                        style={{ height: 45 }}
                      >
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={(this.state.itemSelect).length === 0 ? true : false}
                        />
                        <ListItemText
                          primary="All"
                          className={classes.dropdownText}
                        />
                      </MenuItem>
                      {
                        this.props.plants.map(plant => {
                          if (plant.id == this.props.match.params.plantId) {
                            return plant.item_category.map(item => {
                              return (
                                <MenuItem
                                  key={item.item_category_id}
                                  id={item.item_category_id}
                                  value={item.name}
                                  style={{ height: 45 }}
                                >
                                  <Checkbox
                                    style={{ borderColor: 'gray', color: '#1976d2' }}
                                    checked={(this.state.itemSelect).indexOf(item.name) > -1}
                                  />
                                  <ListItemText
                                    primary={item.name}
                                    className={classes.dropdownText}
                                  />
                                </MenuItem>
                              )
                            })
                          }
                        })
                      }
                    </Select>
                  </FormControl>
                  <FormControl
                    variant='outlined'
                    sx={{ margin: isMobile ? '10px 0' : '0 8px', width: isMobile ? '100%' : 200 }}
                    className={classes.selectFormControl}
                    size="small"
                  >
                    <InputLabel id="Status-select-labeled">
                      LA Status
                    </InputLabel>
                    <Select
                      // autoFocus
                      labelId="Status-select-labeled"
                      id="Status-select-label"
                      name="statusSelect"
                      value={this.state.statusSelect}
                      // onChange={this.handleStatusSelect}
                      onChange={(e) => this.handleSelect(e.target.name, e.target.value)}
                      multiple
                      autoWidth
                      input={<OutlinedInput label="LA Status" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: (48 * 4.5) + 6,
                            width: isMobile ? '100%' : 200,
                          },
                        },
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem
                        disabled
                        value="All"
                        style={{ height: 45 }}
                      >
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={(this.state.statusSelect).length === 0 ? true : false}
                        />
                        <ListItemText
                          primary="All"
                          className={classes.dropdownText}
                        />
                      </MenuItem>
                      {
                        LAStatus.map(item => {
                          return (
                            <MenuItem
                              key={item}
                              id={item.id}
                              value={item.status}
                              style={{ height: 45 }}
                            >
                              <Checkbox
                                style={{ borderColor: 'gray', color: '#1976d2' }}
                                checked={(this.state.statusSelect).indexOf(item.status) > -1}
                              />
                              <ListItemText
                                primary={item.status}
                                className={classes.dropdownText}
                              />
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl
                    variant='outlined'
                    sx={{ margin: isMobile ? '10px 0' : '0 8px', width: isMobile ? '100%' : 200 }}
                    className={classes.selectFormControl}
                    size="small"
                  >
                    <InputLabel id="Weighbridge-select-labeled">
                      Weighbridge
                    </InputLabel>
                    <Select
                      // autoFocus
                      labelId="Weighbridge-select-labeled"
                      id="Weighbridge-select-label"
                      name="weighbridgeSelect"
                      value={this.state.weighbridgeSelect}
                      // onChange={this.handleWeighBridgeSelect}
                      onChange={(e) => this.handleSelect(e.target.name, e.target.value)}
                      multiple
                      autoWidth
                      input={<OutlinedInput label="Weighbridge" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: (48 * 4.5) + 6, width: isMobile ? '100%' : 200
                          }
                        },
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem
                        disabled
                        value="All"
                        style={{ height: 45 }}
                      >
                        <Checkbox
                          style={{ borderColor: 'gray', color: '#1976d2' }}
                          checked={(this.state.weighbridgeSelect).length === 0 ? true : false}
                        />
                        <ListItemText
                          primary="All"
                          className={classes.dropdownText}
                        />
                      </MenuItem>
                      {
                        this.props.plantWeighbridge.map(item => {
                          return (
                            <MenuItem
                              key={item?.id}
                              id={item?.id}
                              value={item?.weighbridge_name}
                            >
                              <Checkbox
                                style={{ borderColor: 'gray', color: '#1976d2' }}
                                checked={(this.state.weighbridgeSelect).indexOf(item?.weighbridge_name) > -1}
                              />
                              <ListItemText
                                primary={item?.weighbridge_name}
                                className={classes.dropdownText}
                              />
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
                <Paper>
                  <Tabs
                    variant={isMobile && "scrollable"}
                  // scrollButtons={isMobile && 'on'} 
                  // allowScrollButtonsMobile={isMobile && true}
                  >
                    {this.state.dateArray?.map((data, index) => (
                      <Tab
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleLADetailsTabChange(data.date);

                        }}
                        style={{
                          flex: !isMobile && 1,
                          padding: "0px",
                          borderBottom:
                            this.state.selectedDate === data.date ? `2px solid ${primaryColor}` : null,
                        }}
                        label={
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ color: "black" }}>
                              {moment(data.date).format("MMM Do ")}
                            </span>
                            <span style={{
                              height: "15px"
                            }}>
                              {(data.total_la) ? data?.dispatched_la + "/" + data?.total_la + " LA" : " "}
                            </span>
                          </div>
                        }
                        className={
                          index === 2 ? classes.selectedTab : classes.tabsStyles
                        }
                      />
                    ))}
                  </Tabs>
                  {!this.state?.FilterLoader ?
                    <LoadingAdviceTable
                      {...this.props}
                      user={this.props.user}
                      dispatchPlaningListLA={this.state.dispatchPlaningFilteredListLA.filter(data => (data.gate_in_date === this.state.selectedDate))}
                      Loader={this.props.Loader}
                      selectedDate={this.state.selectedDate}
                      plant_code={this.state.plant_code}
                      handleLaApprove={this.props.handleLaApprove}
                      handleCancelLa={this.props.handleCancelLa}
                      handleSnackbarClose={this.props.handleSnackbarClose}
                      fetchPendingQuantity={this.props.fetchPendingQuantity}
                      approveLaModification={this.props.approveLaModification}
                      handleDiscardWeighSlips={this.props.handleDiscardWeighSlips}
                      handleUnlockWeighbridge={this.props.handleUnlockWeighbridge}
                      handleFetchSalesReturn={this.props.handleFetchSalesReturn}
                      handleChangeLAStatus={this.props.handleChangeLAStatus}
                      handleLAReplacement={this.props.handleLAReplacement}
                      handleLACancelAfterBilled={this.props.handleLACancelAfterBilled}
                      getInvoiceDetails={this.props.getInvoiceDetails}
                      invoiceDetails={this.props.invoiceDetails}
                      hideLADetailsDialog={this.props.hideLADetailsDialog}

                      fetchReasonCancelLA={this.props.fetchReasonCancelLA}
                      rejectLaModification={this.props.rejectLaModification}
                      plants={this.props.plants}
                      itemCategoryIds={this.state?.itemCategoryIds}
                      statusSelect={this.state?.statusSelect}
                      weighBridgeIds={this.state?.weighBridgeIds}
                      searchLAValue={this.props.searchLAValue}
                      module="loadingAdvices"
                    />
                    :
                    <LoadingAdviceTable
                      {...this.props}
                      user={this.props.user}
                      dispatchPlaningListLA={this.props.dispatchPlaningListLA.filter(data => (data.gate_in_date === this.state.selectedDate))}
                      Loader={this.props.Loader}
                      selectedDate={this.state.selectedDate}
                      plant_code={this.state.plant_code}
                      handleLaApprove={this.props.handleLaApprove}
                      handleCancelLa={this.props.handleCancelLa}
                      handleSnackbarClose={this.props.handleSnackbarClose}
                      fetchPendingQuantity={this.props.fetchPendingQuantity}
                      approveLaModification={this.props.approveLaModification}
                      handleDiscardWeighSlips={this.props.handleDiscardWeighSlips}
                      handleUnlockWeighbridge={this.props.handleUnlockWeighbridge}
                      handleFetchSalesReturn={this.props.handleFetchSalesReturn}
                      handleLAReplacement={this.props.handleLAReplacement}
                      handleLACancelAfterBilled={this.props.handleLACancelAfterBilled}
                      handleChangeLAStatus={this.props.handleChangeLAStatus}
                      getInvoiceDetails={this.props.getInvoiceDetails}
                      invoiceDetails={this.props.invoiceDetails}
                      hideLADetailsDialog={this.props.hideLADetailsDialog}

                      fetchReasonCancelLA={this.props.fetchReasonCancelLA}
                      rejectLaModification={this.props.rejectLaModification}
                      plants={this.props.plants}
                      itemCategoryIds={this.state?.itemCategoryIds}
                      statusSelect={this.state?.statusSelect}
                      weighBridgeIds={this.state?.weighBridgeIds}
                      searchLAValue={this.props.searchLAValue}
                      module="loadingAdvices"
                    />
                  }
                </Paper>
              </Paper>
            </div>
          }
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(customersStyles)(Contracts));
