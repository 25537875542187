import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManageTransporter from "../../../../components/internal/masterManagement/transporter/ManageTransporter";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { arraySortBykeyAllBrowser, isAccessibleModule } from "../../../../methods";
import { Route } from "react-router-dom";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayTransporterData from "../../../../components/internal/masterManagement/transporter/web";

class ManageTranporterContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      tranporterOperation: null,
      selectedTranporterId: null,
      selectedTranporterData: [],
      selectedTranporterDataFetchLoader: false,
      allStateList: [],
      allStateListDataFetchLoader: false,
      allCityList: [],
      allCityListDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      tdsRateList: [],
      tdsRateDataFetchLoader: false,
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.getTdsRateList();
    let tranporterOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ tranporterOperation }, () => console.log(1));
    if (tranporterOperation !== "add") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedTransporterData(id);
      this.setState({ selectedTranporterId: id });
    }
    // this.handleFetchAllStatesList();
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let tranporterOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedTranporterId =
      tranporterOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (tranporterOperation !== state.tranporterOperation) {
      updatedData.set("tranporterOperation", tranporterOperation);
    }
    if (selectedTranporterId !== state.selectedTranporterId) {
      updatedData.set("selectedTranporterId", selectedTranporterId);
    }
    if (updatedData.size > 0) {
      return {
        tranporterOperation: updatedData.has("tranporterOperation")
          ? updatedData.get("tranporterOperation")
          : tranporterOperation,
        selectedTranporterId: updatedData.has("selectedTranporterId")
          ? updatedData.get("selectedTranporterId")
          : [],
      };
    }
    return null;
  }
  getTdsRateList = () => {
    this.setState({ tdsRateDataFetchLoader: true });
    axios.get(`${APIROUTES.TDS_RATE}?active=${true}`, this.getOptions(this.props.user)).then(
      (response) => {
        let responseData = arraySortBykeyAllBrowser(response.data, 'tds_name');
        this.setState({
          tdsRateList: responseData,
          tdsRateDataFetchLoader: false,
        });
      },
      (error) => {
        console.log("error", error);
        this.setState({ tdsRateDataFetchLoader: false });
      }
    );
  };
  handleFetchSelectedTransporterData = async (selectedTranporterId) => {
    await this.setState({ selectedTranporterDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.TRANSPORTER}${selectedTranporterId}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedTranporterData: response.data,
              selectedTranporterDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedTranporterDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected bank data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedTranporterDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching selected bank data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleFetchAllStatesList = (countryId) => {
    axios
      .get(
        `${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId
        }`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              allStateList: response.data,
              allStateListDataFetchLoader: false,
            });
          } else {
            this.setState({
              allStateListDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching all states data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            allStateListDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching all states data try again!",
            snackbarType: "error",
          });
        }
      );
  };
  handleFetchAllCity = (stateId) => {
    return axios.get(
      `${APIROUTES.GET_CITY_LIST}?state_id=${stateId}`,
      this.getOptions(this.props.user)
    );
  };
  handlePincodeDetail = (pincode) => {
    return axios.get(
      `${APIROUTES.GET_PINCODE}?search=${pincode}`,
      this.getOptions(this.props.user)
    );
  };
  handleTransporterDataAdd = (transporterData) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          APIROUTES.TRANSPORTER,
          transporterData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedTransporterData(response.data.id);
          this.props.getTransporterList();
          resolve(response);
        })
        .catch(reject);
    });
  handleTransporterDataUpdate = (transporterData, transporterDataId) =>
    new Promise((resolve, reject) => {
      axios
        .put(
          `${APIROUTES.TRANSPORTER}${transporterDataId}/`,
          transporterData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedTransporterData(transporterDataId);
          this.props.getTransporterList();
          resolve(response);
        })
        .catch(reject);
    });
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  render() {
    const { tranporterOperation } = this.state;
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    // if (isAccessibleModule(this.props?.user, "Banks"))
    else {
      return (
        <>
          {tranporterOperation === "view" ? (
            <DisplayTransporterData {...this.props} {...this.state} />
          ) : (
            <ManageTransporter
              {...this.props}
              {...this.state}
              handleFetchAllCity={this.handleFetchAllCity}
              handlePincodeDetail={this.handlePincodeDetail}
              handleTransporterDataUpdate={this.handleTransporterDataUpdate}
              handleTransporterDataAdd={this.handleTransporterDataAdd}
              tdsRateList={this.state?.tdsRateList}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManageTranporterContainer);
