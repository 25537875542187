import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import {
  bottomNavigationFontColor,
  secondaryColor,
  primaryColor,
} from "../../../styles/colors";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const styles = {
  formContainer: {
    paddingTop: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formStart: {
    width: "82%",
    display: "flex",
    flexDirection: "column",
  },
  formControlStyle: {
    width: "80%",
    margin: "12px 0px",
  },
  quantityTextFieldContainer: {
    display: "flex",
    alignItems: "center",
  },
  mtTextStyle: {
    marginLeft: "10px",
    fontSize: "1.1em",
    color: bottomNavigationFontColor,
    fontWeight: 600,
  },
  buttonContainer: {
    height: "65px",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  addRequirementButtonStyle: {
    height: "80%",
    width: "50%",
    backgroundColor: primaryColor,
  },
  cancelButtonStyle: {
    marginLeft: "20px",
    textTransform: "capitalize",
    color: primaryColor,
  },
};
class AddRequirement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.formContainer}>
          <div className={classes.formStart}>
            <span
              style={{
                color: secondaryColor,
                marginBottom: "15px",
                fontSize: "1.1em",
              }}
            >
              TMT Bars
            </span>
            <FormControl
              variant="outlined"
              className={classes.formControlStyle}
            >
              <InputLabel
                id="label-id"
                ref={(ref) => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-age-simple"
              >
                Quantity
              </InputLabel>
              <Select
                // id="demo-simple-select-outlined"
                // value={this.state.age}
                // onChange={this.handleChange}
                input={
                  <OutlinedInput
                    label="Quantity"
                    labelId="label-id"
                    labelWidth={this.state.labelWidth}
                    name="age"
                    id="outlined-age-simple"
                  />
                }
              >
                <MenuItem value={10}>100MT</MenuItem>
                <MenuItem value={20}>200MT</MenuItem>
                <MenuItem value={30}>300MT</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.quantityTextFieldContainer}>
              <TextField
                className={classes.formControlStyle}
                id="outlined-name"
                label="Quantity"
                type="number"
                // value={this.state.name}
                //onChange={this.handleChange('name')}
                margin="normal"
                variant="outlined"
                onFocus={() => {
                  if (window.cordova) {
                    document.querySelector("BODY").style.position = "fixed";
                    document.querySelector("BODY").style.width = "100vw";
                  }
                }}
                onBlur={() => {
                  if (window.cordova) {
                    document.querySelector("BODY").style.position = null;
                    document.querySelector("BODY").style.width = null;
                  }
                }}
              />
              <span className={classes.mtTextStyle}>MT</span>
            </div>
            <span>You will receive notification when the </span>
            <span> item is ready in plant</span>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.addRequirementButtonStyle}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
              >
                Add requirement
              </Button>
              <Button className={classes.cancelButtonStyle}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(AddRequirement));
