import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, withStyles, Switch } from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { CaptilizeText, checkAlphabet, checkApiError, checkNumeric, pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveButton from "../../common/SaveButton";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: '70%'
  }
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


function ManagePincodeData(props) {
  const classes = useStyles();
  const [pincodeData, setPincodeData] = React.useState(
    props.selectedPincodeData || { pincode: null, city: null }
  );
  const [pincodeOperation, setPincodeOperation] = React.useState(
    props.pincodeOperation
  );
  const [allCitiesList, setAllCities] = React.useState(
    props?.allCitiesList || []
  );
  const [allStatesList, setAllStates] = React.useState(
    props?.allStateList || []
  );
  const [tabValue, setTabValue] = React.useState(
    props?.tabValue
  );
  const [cityError, setCityError] = React.useState(false)
  const [stateError, setStateError] = React.useState(false)
  const [stateCodeError, setStateCodeError] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    setPincodeData({
      ...pincodeData,
      [name]: name === "is_active" ? checked : value,
    });
    if (tabValue === '2' && pincodeOperation === "add") {
      if (name === 'name') {
        return !checkAlphabet(value) ? setStateError(true) : setStateError(false)
      }
    }
    if (tabValue === '1') {
      if (name === 'name') {
        !checkAlphabet(value) ? setCityError(true) : setCityError(false)
      }
    }
    else if (name === 'gst_state_code') {
      !checkNumeric(value) ? setStateCodeError(true) : setStateCodeError(false)
    }
    // for (let key in pincodeData) {
    //   console.log(key + "=:=", pincodeData[key]);
    // }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };
  const handleEditSaveButtonClick = () => {
    setIsLoading(true)
    if (pincodeOperation === "edit") {
      tabValue === '0' ? (
        props
          .handlePincodeMasterDataUpdate(pincodeData, pincodeData.id)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              handleOpenSnackbar(
                `Pincode Master Detail Updated Successfully`,
                "success"
              );
              setTimeout(() => {
                setIsLoading(false)
                props.getPincodesList(pincodeData.city)
                props.handleFetchSelectedPincodeData(pincodeData.id)
                props.history.goBack();
                // props.history.push(`${INTERNALROUTES.PINCODE}`);
                handleCloseSnackbar()
              }, 4000);
            } else {
              setIsLoading(false)
              handleOpenSnackbar(
                `Error occured while updating pincode details please try again`,
                "error"
              );
            }
          })
          .catch((error) => {
            setIsLoading(false)
            handleOpenSnackbar(
              handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000);
          })
      )
        :
        tabValue === '1' ?
          (
            props
              .handlePincodeMasterCityDataUpdate(pincodeData, pincodeData.id)
              .then((response) => {
                if (response.status === 200 || response.status === 201) {
                  handleOpenSnackbar(
                    `Pincode Master City Detail Updated Successfully`,
                    "success"
                  );
                  setTimeout(() => {
                    setIsLoading(false)
                    props.getAllCitiesList()
                    props.getStateCityList(pincodeData.state_id)
                    props.handleFetchSelectedCityData(pincodeData.id)
                    props.getAllCitiesList()
                    props.history.goBack();
                    // props.history.push(`${INTERNALROUTES.PINCODE}`);
                    handleCloseSnackbar()
                  }, 4000);
                } else {
                  setIsLoading(false)
                  handleOpenSnackbar(
                    `Error occured while updating pincode city details please try again`,
                    "error"
                  );
                }
              })
              .catch((error) => {
                setIsLoading(false)
                handleOpenSnackbar(
                  handleSnackbarMessageBox(checkApiError(error.response.data)),
                  "error"
                );
                setTimeout(() => {
                  handleCloseSnackbar();
                }, 4000);
              })
          )
          :
          (
            props
              .handlePincodeMasterStateDataUpdate(pincodeData, pincodeData.id)
              .then((response) => {
                if (response.status === 200 || response.status === 201) {
                  handleOpenSnackbar(
                    `Pincode Master State Detail Updated Successfully`,
                    "success"
                  );
                  setTimeout(() => {
                    setIsLoading(false)
                    props.getAllStatesList()
                    props.handleFetchSelectedStateData(pincodeData.id)
                    props.history.goBack();
                    // props.history.push(`${INTERNALROUTES.PINCODE}`);
                    handleCloseSnackbar()
                  }, 4000);
                  // props.history.push(`${INTERNALROUTES.PINCODE}`);
                } else {
                  setIsLoading(false)
                  handleOpenSnackbar(
                    `Error occured while updating pincode state details please try again`,
                    "error"
                  );
                }
              })
              .catch((error) => {
                setIsLoading(false)
                handleOpenSnackbar(
                  handleSnackbarMessageBox(checkApiError(error.response.data)),
                  "error"
                );
                setTimeout(() => {
                  handleCloseSnackbar();
                }, 4000);
              })
          )
    } else if (pincodeOperation === "add") {
      let pincodeAddData = {
        city: pincodeData.city,
        pincode: pincodeData.pincode,
        is_active: (pincodeData.is_active) === undefined ? true : Boolean(pincodeData.is_active),
      }
      let cityData = {
        name: CaptilizeText(pincodeData.name),
        state_id: pincodeData.state_id,
        state_code: "NA",
        country_id: 101,
        country_code: "IN",
        is_active: (pincodeData.is_active) === undefined ? true : Boolean(pincodeData.is_active),
      }
      let stateCodeData = {
        name: pincodeData.name,
        gst_state_code: pincodeData.gst_state_code,
        id: pincodeData.id,
        country_id: 101,
        country_code: "IN",
        is_active: (pincodeData.is_active) === undefined ? true : Boolean(pincodeData.is_active),
      }

      tabValue === '0' ? (
        props
          .handlePincodeMasterDataAdd(pincodeAddData)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              handleOpenSnackbar(
                `Pincode Master Created Successfully`,
                "success"
              );
              setTimeout(() => {
                setIsLoading(false)
                props.getPincodesList(pincodeData.city)
                props.history.push(`${INTERNALROUTES.PINCODE}`);
                handleCloseSnackbar()
              }, 4000);
            } else {
              setIsLoading(false)
              handleOpenSnackbar(
                `Error occured while creating pincode details please try again`,
                "error"
              );
            }
          })
          .catch((error) => {
            setIsLoading(false)
            handleOpenSnackbar(
              handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000);
          })
      )
        : tabValue === '1' ?
          (
            props
              .handlePincodeMasterCityDataAdd(cityData)
              .then((response) => {
                if (response.status == 200 || response.status == 201) {
                  handleOpenSnackbar(
                    `Pincode Master City Created Successfully`,
                    "success"
                  );
                  setTimeout(() => {
                    setIsLoading(false)
                    props.getStateCityList(pincodeData.state_id)
                    props.getAllCitiesList()
                    props.history.push(`${INTERNALROUTES.PINCODE}`);
                    handleCloseSnackbar()
                  }, 4000);
                } else {
                  setIsLoading(false)
                  handleOpenSnackbar(
                    `Error occured while creating pincode city details please try again`,
                    "error"
                  );
                }
              })
              .catch((error) => {
                setIsLoading(false)
                if (Object.keys(error.response.data)[0] == "non_field_errors") {
                  handleOpenSnackbar(
                    // (`"Duplication" : ` + Object.values(error.response.data)[0]),
                    (`"Duplication" : ` + "The fields city_name, state_id must make a unique set."),
                    "error"
                  );
                  setTimeout(() => {
                    handleCloseSnackbar();
                  }, 4000);
                }
                else {
                  handleOpenSnackbar(
                    handleSnackbarMessageBox(checkApiError(error.response.data)),
                    "error"
                  );
                  setTimeout(() => {
                    handleCloseSnackbar();
                  }, 4000);
                }
              })
          )
          :
          (
            props
              .handlePincodeMasterStateDataAdd(stateCodeData)
              .then((response) => {
                if (response.status == 200 || response.status == 201) {
                  handleOpenSnackbar(
                    `Pincode Master State Created Successfully`,
                    "success"
                  );
                  setTimeout(() => {
                    setIsLoading(false)
                    props.getAllStatesList()
                    props.history.push(`${INTERNALROUTES.PINCODE}`);
                    handleCloseSnackbar()
                  }, 4000);
                  // props.history.push(`${INTERNALROUTES.PINCODE}`);
                } else {
                  setIsLoading(false)
                  handleOpenSnackbar(
                    `Error occured while creating pincode state details please try again`,
                    "error"
                  );
                }
              })
              .catch((error) => {
                setIsLoading(false)
                // console.log(Object.keys(error.response.data)[0] == 'gst_state_code')
                if (Object.keys(error.response.data)[0] == 'gst_state_code') {
                  handleOpenSnackbar(
                    (`"state_code" : ` + Object.values(error.response.data)[0]),
                    "error"
                  );
                  setTimeout(() => {
                    handleCloseSnackbar();
                  }, 4000);
                }
                else if (Object.keys(error.response.data)[0] == "non_field_errors") {
                  handleOpenSnackbar(
                    // (`"Duplication" : ` + Object.values(error.response.data)[0]),
                    (`"Duplication" : ` + "The fields state_name, state_code must make a unique set."),
                    "error"
                  );
                  setTimeout(() => {
                    handleCloseSnackbar();
                  }, 4000);
                }
                else {
                  handleOpenSnackbar(
                    handleSnackbarMessageBox(checkApiError(error.response.data)),
                    "error"
                  );
                  setTimeout(() => {
                    handleCloseSnackbar();
                  }, 4000);
                }
              })
          )
    } else {
      console.log("Invalid operation");
    }
  };
  React.useEffect(() => {
    setPincodeData(props?.selectedPincodeData);
    setPincodeOperation(props?.pincodeOperation);
    setAllCities(props?.allCitiesList || []);
  }, [
    props?.selectedPincodeData,
    props?.pincodeOperation,
    props?.allCitiesList,
  ]);
  React.useEffect(() => {
    setPincodeData(props?.selectedPincodeData);
    setAllStates(props?.allStateList || []);
    setTabValue(props?.tabValue || '0');
    // findCity();
  }, [props?.selectedPincodeData, props?.allStateList, props?.tabValue]);

  // for (let key in allCitiesList) {
  //   console.log(key + ":", allCitiesList[key]);
  // }
  // console.log("allStateList" + allStatesList)

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Pincode Master",
                onClick: () => props.history.push(INTERNALROUTES.PINCODE),
              },
              {
                title: pincodeOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            {tabValue === '0' ?
              <>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>PINCODE *</Typography>
                  <TextField
                    label="Pincode"
                    name="pincode"
                    type="text"
                    onChange={handleChange}
                    placeholder="Enter pincode"
                    className={classes.textField}
                    inputProps={{ maxLength: 6, minLength: 6, max: 6 }}
                    onKeyPress={(event) => {
                      if (event.charCode >= 48 && event.charCode <= 57) {
                        // let it happen, don't do anything
                      }
                      else { event.preventDefault(); }
                    }}
                    margin="dense"
                    variant="outlined"
                    value={pincodeData?.pincode || ""}
                  />
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>CITY *</Typography>
                  <Autocomplete
                    className={classes.textField}
                    size="small"
                    options={allCitiesList}
                    value={allCitiesList.find(
                      (cityData) => cityData.id === pincodeData.city
                    )}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      if (value) {
                        setPincodeData({
                          ...pincodeData,
                          ["city"]: value.id,
                        });
                      } else {
                        setPincodeData({
                          ...pincodeData,
                          ["city"]: null,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search City"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>
                    ACTIVE
                  </Typography>
                  <div className={classes.isActive}>
                    <IOSSwitch
                      checked={(pincodeData.is_active) === undefined
                        ? true
                        : Boolean(pincodeData.is_active)
                      }
                      onChange={handleChange}
                      name="is_active"
                    />
                  </div>
                </div>
              </>
              :
              tabValue === '1' ?
                <>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>STATE *</Typography>
                    <Autocomplete
                      className={classes.textField}
                      size="small"
                      options={allStatesList}
                      value={allStatesList.find(
                        (stateData) => stateData.id === pincodeData.state_id
                      )}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setPincodeData({
                            ...pincodeData,
                            ["state_id"]: value.id,
                          });
                        } else {
                          setPincodeData({
                            ...pincodeData,
                            ["state_id"]: null,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search State"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>CITY *</Typography>
                    <TextField
                      label="City"
                      name="name"
                      type="text"
                      onChange={handleChange}
                      placeholder="Enter City"
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={pincodeData?.name || ""}
                      error={cityError}
                      helperText={cityError ? "Invalid City Name" : ""}
                    />
                  </div>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>
                      ACTIVE
                    </Typography>
                    <div className={classes.isActive}>
                      <IOSSwitch
                        checked={(pincodeData.is_active) === undefined
                          ? true
                          : Boolean(pincodeData.is_active)
                        }
                        onChange={handleChange}
                        name="is_active"
                      />
                    </div>
                  </div>
                </>
                :
                <>
                  {pincodeOperation === "add" ?
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>STATE *</Typography>
                      <TextField
                        label="State"
                        name="name"
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter State"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        value={pincodeData?.name || ""}
                        error={stateError}
                        helperText={stateError ? "Invalid State" : ""}
                      />
                    </div>
                    :
                    <div className={classes.dataFields}>
                      <Typography className={classes.titleLabel}>STATE *</Typography>
                      <Autocomplete
                        className={classes.textField}
                        size="small"
                        options={allStatesList}
                        value={allStatesList.find(
                          (stateData) => stateData.id === pincodeData.id
                        )}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            setPincodeData({
                              ...pincodeData,
                              ["id"]: value.id,
                              ["name"]: value.name
                            });
                          } else {
                            setPincodeData({
                              ...pincodeData,
                              ["id"]: null,
                              ["name"]: null
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search State"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  }
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>STATE CODE *</Typography>
                    <TextField
                      label="State Code"
                      name="gst_state_code"
                      type="text"
                      onChange={handleChange}
                      placeholder="Enter State Code"
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={pincodeData?.gst_state_code || ""}
                      error={stateCodeError}
                      helperText={stateCodeError ? "Invalid State Code" : ""}
                    />
                  </div>
                  <div className={classes.dataFields}>
                    <Typography className={classes.titleLabel}>
                      ACTIVE
                    </Typography>
                    <div className={classes.isActive}>
                      <IOSSwitch
                        checked={(pincodeData.is_active) === undefined
                          ? true
                          : Boolean(pincodeData.is_active)
                        }
                        onChange={handleChange}
                        name="is_active"
                      />
                    </div>
                  </div>
                </>
            }
            <SaveButton
              size="medium"
              disabled={
                tabValue === '0' ?
                  !pincodeData?.pincode ||
                  !pincodeData?.city ||
                  pincodeData.pincode === "" ||
                  pincodeData.city === ""
                  :
                  tabValue === '1' ?
                    !pincodeData?.name ||
                    !pincodeData?.state_id ||
                    pincodeData.name === "" ||
                    pincodeData.state_id === "" ||
                    cityError
                    :
                    pincodeOperation === "add" ?
                      !pincodeData?.name ||
                      !pincodeData?.gst_state_code ||
                      pincodeData.name === "" ||
                      pincodeData.gst_state_code === "" ||
                      stateError ||
                      stateCodeError
                      :
                      !pincodeData?.id ||
                      !pincodeData?.gst_state_code ||
                      pincodeData.id === "" ||
                      pincodeData.gst_state_code === "" ||
                      stateCodeError
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {pincodeOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManagePincodeData);
