import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "../../../constants/internal/colors";

const styles = {
  gstDetailsContainer: {
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
  },
  titleTestStyle: {
    width: "40%",
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    textAlign: "center",
  },
  rightSideTextValue: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  totalTextInvoiceText: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  totalTextInvoiceTextValue: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
class GstDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, plantItemDetails } = this.props;
    console.log(this.props)
    return (
      <div className={classes.gstDetailsContainer}>
        {plantItemDetails && plantItemDetails[0].IGSTRATE ? (
          <div style={{ width: "60%", lineHeight: "3em", color: grey }}>
            {this.props.total_freight_amount == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>
                  FREIGHT CHARGES(Included)
                </span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].FREIGHTRATE} / MT
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_freight_amount}
                </span>
              </div>
            )}
            {this.props.total_igst_value == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>IGST</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].IGSTRATE} %
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_igst_value}
                </span>
              </div>
            )}
            {this.props.total_cess_value == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>CESS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].CESSRATE} %
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_cess_value}
                </span>
              </div>
            )}
            {this.props.total_tcs_amount == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>TCS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].AFRATE12} %
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_tcs_amount}
                </span>
              </div>
            )}
            {this.props.total_freight_advance_amount == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>FREIGHT ADVANCE </span>

                <span className={classes.rightSideTextValue}>
                  {this.props.total_freight_advance_amount}
                </span>
              </div>
            )}
            {
              this.props.plantItemDetails && this.props.plantItemDetails.length > 0
                && this.props.plantItemDetails[0].ROUNDOFFAMOUNT1 != 0 ?
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span className={classes.titleTestStyle}>
                    ROUND OFF
                  </span>
                  <span
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {this.props.plantItemDetails[0].ROUNDOFFAMOUNT11}
                  </span>
                </div> : null
            }
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className={classes.titleTestStyle}>
                TOTAL INVOICE VALUE
              </span>
              <span
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {this.props.total_invoice_value}
              </span>
            </div>
          </div>
        ) : (
          <div style={{ width: "60%", lineHeight: "3em", color: grey }}>
            {this.props.total_freight_amount == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>
                  FREIGHT CHARGES(Included)
                </span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].FREIGHTRATE} / MT
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_freight_amount}
                </span>
              </div>
            )}
            {this.props.total_sgst_value == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>SGST</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].SGSTRATE} %
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_sgst_value}
                </span>
              </div>
            )}
            {this.props.total_cgst_value == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>CGST</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].CGSTRATE} %
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_cgst_value}
                </span>
              </div>
            )}
            {this.props.total_cess_value == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>CESS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].CESSRATE} %
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_cess_value}
                </span>
              </div>
            )}
            {this.props.total_tcs_amount == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>TCS</span>
                <span className={classes.titleTestStyle} style={{ marginLeft: "50px" }}>
                  {plantItemDetails && plantItemDetails[0].AFRATE12} %
                </span>
                <span className={classes.rightSideTextValue}>
                  {this.props.total_tcs_amount}
                </span>
              </div>
            )}
            {this.props.total_freight_advance_amount == 0 ? null : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.titleTestStyle}>FREIGHT ADVANCE </span>

                <span className={classes.rightSideTextValue}>
                  {this.props.total_freight_advance_amount}
                </span>
              </div>
            )}
            {
              this.props.plantItemDetails && this.props.plantItemDetails.length > 0
                && this.props.plantItemDetails[0].ROUNDOFFAMOUNT1 != 0 ?
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span className={classes.titleTestStyle}>
                    ROUND OFF
                  </span>
                  <span
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {this.props.plantItemDetails[0].ROUNDOFFAMOUNT1}
                  </span>
                </div> : null
            }
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className={classes.titleTestStyle}>
                TOTAL INVOICE VALUE
              </span>
              <span className={classes.totalTextInvoiceTextValue}>
                {this.props.total_invoice_value}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(GstDetails);
