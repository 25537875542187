import * as React from 'react';
import { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import {
    DataGrid,
    gridClasses,
    useGridApiRef,
} from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
import CircularProgressLoader from "../../common/CircularProgressLoader";
import NoDataFound from "../../common/NoDataFound";
import { invoiceHeaders, WBGHeaders, contractsHeaders, LAHeaders, EXRHeaders, subContractsHeaders } from '../reports/headers'
import { styled } from '@mui/material/styles';
// import { GridApi } from '@mui/x-data-grid';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import { plantTableHeaders } from '../reports/headers'
import moment from 'moment';
import SpinLoader from '../../common/SpinLoader';
import { grey, secondaryBlueText } from '../../../constants/internal/colors';
import { makeStyles } from '@material-ui/core/styles';

const tableStyles = makeStyles((theme) => ({
    root: {
        '&::-webkit-scrollbar': {
            height: 7,
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'rgba(75, 135, 197, 0.9)',
            borderRadius: 10,
            border: '2px solid transparent',
            backgroundClip: 'padding-box', // <== make the border work
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(75, 135, 197, 0.9)',
            // height: 30,
            // border: 0,
        },

        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        },
    },
}))

const generateColumns = (headers) => {
    // console.log(headers)

    let columns = []

    headers?.forEach(header => {
        // if (header.header !== "Item Name") {
        columns.push({
            field: header.key,
            headerName: header?.header,
            width: header.minWidth,
            headerAlign: header?.headerAlign || 'center',
            renderCell: header?.renderCell,
            renderHeader: header?.renderHeader
        })
        // }
    })

    return columns
}

const generateRows = (rowData, tabValue, headers) => {
    let headersKey = headers.map(d => { return d.key })
    let plantPlanningFields = [
        "index",
        // "sgst", 
        // "plant_name", 
        "pending_qty",
        // "igst", 
        "dispached_qty",
        // "cgst", 
        "allotted_qty",
        // "plant", 
        // "plant_code", 
        "balance_qty",
        "cancelled_qty"
    ]
    // console.log(headersKey)
    let actualData = []
    if (tabValue === 0) {
        rowData.map((d, index) => {
            d?.loading_advice_items?.map((itemData, i) => {
                let allRowData = {}
                headersKey.map((field) => {
                    if (!(["item", "item_total_amount", "index", "item_net_rate", "item_dispatch_qty", "item_cancel_qty"].includes(field))) {
                        // console.log(field, d, d[field])
                        Object.assign(allRowData, { [field]: d[field] })
                    }
                    else if (field === 'index') {
                        // console.log(field, index, i, allRowData.length, String(index + 1).concat(String(i + 1)))
                        Object.assign(allRowData, {
                            [field]: String(String(index + 1) + ".").concat(String(i + 1))
                        },
                            { "id": Number(String(index + 1).concat(String(i + 1))) })
                    }
                    else {
                        // console.log(field, itemData, itemData[field])
                        Object.assign(allRowData, {
                            [field]: field === 'party_ref_date'
                                ? moment(itemData[field]).format('DD-MMM-YYYY')
                                : itemData[field]
                        })
                    }
                    // console.log('allRowData : ', allRowData)
                })
                actualData.push(allRowData)
                // console.log('actualData : ', actualData)
            })
        })
    }
    else if (tabValue === 1) {
        rowData.map((d, index) => {
            if (d.days_running < 0) d.days_running = 0
            if (d.plant_plannings && d.plant_plannings.length > 0) {
                let allRowData = {}
                headersKey.map((field) => {
                    if (!(plantPlanningFields.includes(field))) {
                        // console.log(field, d, d[field])
                        Object.assign(allRowData, { [field]: d[field] })
                    }
                    else if (field === 'index') {
                        // console.log("here if index", field, index, i, allRowData.length, String(index + 1).concat(String(i + 1)))
                        Object.assign(allRowData,
                            {
                                // "index": String(String(index + 1) + ".").concat(String(i + 1))
                                "index": String(index + 1)
                            },
                            {
                                // "id": Number(String(index + 1).concat(String(i + 1))) 
                                "id": Number(index + 1)
                            }
                        )
                    }
                    else {
                        // Object.assign(allRowData, { [field]: itemData[field] })
                        let sum = 0;
                        d.plant_plannings.map((itemData, i) => {
                            sum = sum + Number(Number(itemData[field]).toFixed(3))
                        })
                        Object.assign(allRowData, { [field]: sum })
                    }
                    // console.log('allRowData : ', allRowData, index, i, String(String(index + 1) + ".").concat(String(i + 1)))
                })
                actualData.push(allRowData)
                // console.log('actualData : ', actualData)
                // })
            }
            else {
                let allRowData = {}
                headersKey.map((field) => {
                    if (!(plantPlanningFields.includes(field))) {
                        Object.assign(allRowData, { [field]: d[field] })
                    }
                    else if (field === 'index') {
                        Object.assign(allRowData,
                            { "index": String(index + 1) },
                            { "id": Number(index + 1) }
                        )
                    }
                    else {
                        Object.assign(allRowData, { [field]: '0' })
                    }
                })
                actualData.push(allRowData)
            }
        })
    }
    // console.log("A ", actualData)
    return actualData
}


const NoDataOverlayComponent = (props) => {
    return (
        <NoDataFound style={{ display: "flex", justifyContent: "center", marginTop: 120 }} />
    );
}

const CustomFooterStatusComponent = (props) => {
    const classes = tableStyles();
    const {
        rows,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
        columns,
        contentScroll,
        onContentScroll,
        value,
        totalCustomersCount,
        totalLedgerCount,
        handleChangeRemotePage
    } = props
    let avgArray = ["avg_discount", "net_rate", "avg_rate", "avg_net_rate", "avg_discount", "avg_tare_weight"]
    let footerData = [];
    let obj = {};
    columns && columns?.forEach(d => {
        let key = d.key
        if (d?.calculation) {
            let calcValue = 0
            for (let i = 0; i < rows.length; i++) {
                let item = rows[i]
                calcValue += Number(item[key] === undefined ? 0 : item[key])
            }
            obj[key] = avgArray.includes(d.calculation)
                ? (calcValue / (rows.length)).toFixed(d.dec_places)
                : calcValue.toFixed(d.dec_places)
        }
        else {
            obj[key] = ""
        }
    })
    footerData.push(obj)
    // console.log(footerData, rows, !(rows.length === 0))

    return (
        !['dynamic_reports_1',].includes(props.value)
            ? (<Box sx={{ p: 1, width: "100%", display: 'flex', flexDirection: "column", }}>
                <Divider />
                <div
                    id="content-scroll"
                    className={classes.root}
                    style={{
                        display: ['sales_target', 'dynamic_reports_1', 'ledgerRequest', 'dispatchTracking'].includes(props.value) || (rows.length === 0)
                            ? 'none'
                            : 'flex',
                        overflowX: "auto",
                        textAlign: 'left',
                        WebkitFontSmoothing: 'auto',
                        letterSpacing: 'normal',
                        fontFamily: 'Poppins'
                    }}
                    ref={contentScroll}
                    onScroll={(e) => onContentScroll(e, 0)}
                >
                    {
                        Object.keys(footerData[0]).map((d, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        minWidth: columns[i].minWidth,
                                        whiteSpace: 'wrap',
                                        padding: 10
                                    }}
                                >
                                    {columns[i]?.calc_text && columns[i]?.calc_text + ' = ' + footerData[0][d]}
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ overflowX: "hidden" }}>
                    <TablePagination
                        showFirstButton
                        showLastButton
                        rowsPerPageOptions={['customerManagement'].includes(value) ? [10] : ['ledgerRequest'].includes(value) ? [10] : [5, 10, 25]}
                        component="div"
                        count={['customerManagement'].includes(value) ? totalCustomersCount : ['ledgerRequest'].includes(value) ? totalLedgerCount : rows?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Box>)
            : null
    );
}



export default function Table(props) {
    const { value, data, totalCustomersCount, totalLedgerCount, handleChangeRemotePage } = props
    const [hiddenColumns, setHiddenColumns] = useState([]);
    // console.log(props)
    const columns = generateColumns(value === 0 ? invoiceHeaders
        : value === 1 ? contractsHeaders
            : value === 2 ? LAHeaders
                : value === 3 ? WBGHeaders
                    : value === 4 ? EXRHeaders
                        : value === 5 ? props.tablePlannedItemList?.updatedHeader
                            : ['dispatchTracking', 'customerManagement', 'ledgerRequest', 'sales_target', 'dynamic_reports_1',].includes(props.value) ? props?.headers
                                : null)
    const headers = plantTableHeaders(props.value, props.type)
    const rows = ['dynamic_reports_1',].includes(props.value) ? props?.data : props?.data?.data;
    // console.log(rows, columns)
    const allRows = (value === 0 || value === 1) && generateRows(
        ['dynamic_reports_1',].includes(props.value) ? props?.data : props?.data?.data, value,
        value === 0 ? invoiceHeaders : value === 1 && contractsHeaders)
    // console.log(rows, allRows, value)
    const ODD_OPACITY = 0.2;
    const apiRef = useGridApiRef();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(['ledgerRequest'].includes(props.value) ? 20 : 10);
    const contentScroll = React.useRef(null)
    let _preventEvent = false;
    let contentLastScrollLeft = 0;

    React.useEffect(() => {
        if (!['ledgerRequest'].includes(props.value)) {
            setPage(0)
        }
    }, [props?.data?.data])

    // console.log(columns)
    const columnGroupingModel = value !== 5
        ? []
        : [
            {
                groupId: 'internal',
                // headerName: 'CHECK',
                children: [{ field: 'customer' }, { field: 'party_ref_number' }],
                // children: subContractsHeaders.slice(0, -1).map(h => {
                //     return { field: h.key }
                // })
            },
            // {
            //     groupId: 'Balance Item',
            //     // headerName: 'Items',
            //     children: props.tablePlannedItemList?.plannedItemSize.map(d => {
            //         return { field: d.itemSize }
            //     })
            // },
        ]


    const handleCellScrollChange = (e) => {
        const value = document.getElementsByClassName('MuiDataGrid-virtualScroller')[0]?.scrollLeft
        // console.log(
        //     value,
        //     contentLastScrollLeft,
        //     document.getElementsByClassName('MuiDataGrid-virtualScrollerContent')[0].scrollWidth,
        //     document.getElementsByClassName('MuiDataGrid-virtualScroller')[0].scrollLeft)
        if (value !== 0) {
            if (value > contentLastScrollLeft) {
                contentLastScrollLeft = value + 150
                document.getElementById('content-scroll').scrollLeft = value + 150
                onContentScroll(e, 150)
            }
            else if (value < contentLastScrollLeft) {
                contentLastScrollLeft = value - 150
                document.getElementById('content-scroll').scrollLeft = value - 150
                onContentScroll(e, 150)
            }
            else {
                contentLastScrollLeft = value
                document.getElementById('content-scroll').scrollLeft = value
                onContentScroll(e, 150)
            }
        }
        else if (value === 0) {
            contentLastScrollLeft = 0
            document.getElementById('content-scroll').scrollLeft = 0
            onContentScroll(e, 150)
        }
        else if (document.getElementById('content-scroll').scrollWidth === document.getElementsByClassName('MuiDataGrid-virtualScrollerContent')[0].scrollWidth) {
            contentLastScrollLeft = document.getElementsByClassName('MuiDataGrid-virtualScrollerContent')[0].scrollWidth
            document.getElementById('content-scroll').scrollLeft = document.getElementsByClassName('MuiDataGrid-virtualScrollerContent')[0].scrollWidth
            onContentScroll(e, 150)
        }

        // }
    }

    const onContentScroll = (e, leftValue) => {
        // console.log("leftValue = ", leftValue);
        if (leftValue === 0) {
            if (_preventEvent) {
                _preventEvent = false;
                return;
            }
            // console.log("onContentScroll", leftValue);

            if (e?.target?.scrollLeft !== contentLastScrollLeft) {
                _preventEvent = true;
                // let element = topScroll.current?.firstElementChild?.nextElementSibling?.lastElementChild
                contentLastScrollLeft = e?.target?.scrollLeft;
                document.getElementsByClassName('MuiDataGrid-virtualScroller')[0].scrollLeft = e?.target?.scrollLeft;
                // console.log(topScroll.current?.firstElementChild?.nextElementSibling?.lastElementChild, contentLastScrollLeft)
            }
        }
    };
    // { console.log("checking", props,) }

    const handleChangePage = (event, newPage) => {
        if (['customerManagement'].includes(props.value)) {
            if (newPage > page) {
                props?.handleNextCustomerPage(props?.nextLink, newPage)
            }
            else if (newPage < props.page) {
                props?.handleNextCustomerPage(props?.prevLink, newPage)
            }
        }
        if (['ledgerRequest'].includes(props.value)) {
            console.log("new", newPage)
            console.log("page", page)
            if (newPage > page) {
                props?.handleNextCustomerPage(props?.searchDataNext, newPage)
            }
            else if (newPage < props.page) {
                props?.handleNextCustomerPage(props?.searchDataPrev, newPage)
            }
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        if (['customerManagement'].includes(props.value)) {
        }
        else {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }
    };

    const handleClickButton = () => {
        setTimeout(() => {
            // console.log(apiRef.current.getSortedRows(), apiRef.current.state);
            // console.log(apiRef)
            // props?.handleTableDataSorting(props?.value, apiRef.current.getSortedRows())
        }, 100);

    };

    // const CustomPagination = () => {
    //     const apiRef = useGridApiContext();
    //     const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
    //     const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    //     return (
    //         <Pagination
    //             count={pageCount}
    //             page={paginationModel.page + 1}
    //             onChange={(event, value) => apiRef.current.setPage(value - 1)}
    //         />
    //     );
    // }



    const handlecolumnChange = (col) => {
        const index = columns.findIndex((or) => or.field === col.field);
        if (index >= 0) columns.splice(index, 1);
        console.log(columns)
        // if (!col.isVisible)
        //     setHiddenColumns(prevValue => [...prevValue, col.colDef.headerName])
        // else
        //     setHiddenColumns(hiddenColumns.filter(d => d !== col.colDef.headerName))
        // let columns = 
        // columns = columns
        // props.handleHiddenColumns(columns)
        // columns.findIndex(or=> or.key === col.field).splice
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({

        [`& .${gridClasses.row}.even`]: {
            backgroundColor: !['sales_target', 'dynamic_reports_1',].includes(props.value) && theme.palette.grey[200],
        },
        border: 0,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: ['sales_target', 'dynamic_reports_1',].includes(props.value) ? '#FAFAFA'
                : theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        //     borderRight: `1px solid ${theme.palette.mode === 'light' ? 'black' : '#303030'
        //         }`,
        // },
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: ['sales_target', 'dynamic_reports_1',].includes(props.value) && '#FAFAFA',
            color: ['sales_target', 'dynamic_reports_1',].includes(props.value) && grey,
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: !['sales_target', 'dynamic_reports_1',].includes(props.value) && `3px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
            wordWrap: "break-word",
            fontFamily: 'Poppins'
        },
        // '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        //     borderBottom: `3px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        //         }`,
        // },
        '& .MuiDataGrid-cell': {
            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
            wordWrap: "break-word",
            // justifyContent: ['sales_target_publish'].includes(props.value) && 'center'
        },

        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .MuiDataGrid-virtualScroller': {
            overflowX: (window.innerWidth <= 768 || ['sales_target', 'dynamic_reports_1', "ledgerRequest", "dispatchTracking"].includes(props.value)) ? 'auto' : 'hidden',
            overflowY: 'auto'
        },
        // ...customCheckbox(theme),
    }));

    const elementRef = useRef(null);
    // console.log(props, window.location.pathname)
    if (data?.loading)
        return (
            <div
                style={{ display: "flex", justifyContent: "center", marginTop: 120 }}
                ref={elementRef}
            >
                {window.location.pathname === '/reports' ?
                    <Box style={{ position: 'relative' }}>
                        <CircularProgress
                            variant="determinate"
                            style={{ color: '#ddd', }}
                            size={70}
                            thickness={4}
                            value={100}
                        />
                        <CircularProgress
                            variant="determinate"
                            style={{
                                position: 'absolute',
                                left: 0,
                                color: secondaryBlueText
                            }}
                            size={70}
                            thickness={4}
                            value={props?.fetchReportProgress === undefined ? 0 : props?.fetchReportProgress}
                        />
                        <Box
                            style={{
                                top: 0,
                                left: 0,
                                bottom: 5,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="body1" component="div" color="text.secondary">
                                {`${Math.round(props?.fetchReportProgress === undefined ? 0 : props?.fetchReportProgress)}%`}
                            </Typography>
                        </Box>
                    </Box>
                    :
                    <CircularProgressLoader />
                }
            </div>);

    if (props.loading)
        return (
            <div
                style={{ display: "flex", justifyContent: "center", marginTop: 120 }}
                ref={elementRef}
            ><SpinLoader /></div>);

    // if (!data || data.data?.length === 0)
    //     return (
    //         <NoDataFound style={{ display: "flex", justifyContent: "center", marginTop: 120 }} />
    //     );
    // console.log(columnGroupingModel, value, columns)

    return (
        <>
            <Box sx={{ height: props?.tableHeight || 400, width: '100%' }}>
                {/* {console.log(hiddenColumns)} */}
                <StyledDataGrid
                    id="top-scroll"
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && "normal",
                            lineHeight: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && "normal",


                            '&:hover': {
                                whiteSpace: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && "nowrap",
                                lineHeight: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && "inherit",
                            }
                        },
                        // "& .MuiDataGrid-columnHeader": {
                        //     padding: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && "0px auto",
                        // },
                        "& .MuiDataGrid-cellContent": {
                            whiteSpace: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && "normal",
                            lineHeight: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && "normal",
                            // textAlign: ['sales_target_publish'].includes(props.value) && 'center'
                        },
                        "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
                            maxHeight: ['dispatchTracking', 'customerManagement', 'ledgerRequest', 'dynamic_reports_1'].includes(props.value) && '100px !important',
                            paddingTop: ['dispatchTracking', 'customerManagement', 'ledgerRequest',].includes(props.value) && '10px',
                            paddingBottom: ['dispatchTracking', 'customerManagement', 'ledgerRequest',].includes(props.value) && '10px',
                        },
                        "& .MuiDataGrid-row": {
                            maxHeight: ['dispatchTracking', 'customerManagement', 'ledgerRequest', 'dynamic_reports_1'].includes(props.value) && '100px !important',
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            flex: ['dispatchTracking', 'customerManagement', 'ledgerRequest'].includes(props.value) && 'inherit'
                        },
                    }}
                    rows={value === 0 || value === 1
                        ? (rowsPerPage > 0 ? allRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : allRows)
                        : ["ledgerRequest", "sales_target_publish", "dynamic_reports_1", "dynamic_reports_2"].includes(value) ? rows
                            : (rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows)
                    }
                    columns={columns}
                    onColumnVisibilityChange={(col) => {
                        // console.log(col)
                        handlecolumnChange(col)
                        // setHiddenColumns([...hiddenColumns, col.field])
                    }}
                    onColumnVisibilityModelChange={col => {
                        console.log(col)
                    }}
                    components={{
                        // pagination: CustomPagination,
                        Footer: CustomFooterStatusComponent,
                        NoRowsOverlay: NoDataOverlayComponent
                    }}
                    componentsProps={{
                        footer: {
                            // [value === 0 || value === 1 ? allRows : rows]
                            rows: value === 0 || value === 1 ? allRows : rows,
                            columns: (
                                value === 0
                                    ? invoiceHeaders
                                    : value === 1
                                        ? contractsHeaders
                                        : value === 2
                                            ? LAHeaders
                                            : value === 3
                                                ? WBGHeaders
                                                : value === 4
                                                    ? EXRHeaders
                                                    : value === 5
                                                        ? props.tablePlannedItemList?.updatedHeader
                                                        : ['dispatchTracking', 'customerManagement', 'ledgerRequest', 'sales_target', 'dynamic_reports_1',].includes(props.value)
                                                            ? props?.headers
                                                            : null),
                            rowsPerPage,
                            page,
                            handleChangePage,
                            handleChangeRowsPerPage,
                            contentScroll,
                            onContentScroll,
                            value,
                            totalCustomersCount,
                            totalLedgerCount,
                            handleChangeRemotePage
                        },
                    }}
                    onCellKeyDown={!['dynamic_reports_1',].includes(props.value) && handleCellScrollChange}
                    // pageSizeOptions={[5, 10, 25, 100]}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    disableColumnFilter
                    onSortModelChange={(model) => handleClickButton()}
                    columnGroupingModel={columnGroupingModel}
                    apiRef={apiRef}
                />
                {/* <Button onClick={() => handleClickButton()}>Go to page 1</Button> */}
            </Box>
        </>
    );
}