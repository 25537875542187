import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JsBarcode from "jsbarcode";
import moment from "moment";
import {
  primaryColor,
  drawerTextColor,
} from "../../../constants/internal/colors";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const eWayBillPdfStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    borderRadius: "30px",
  },
});

class RIPLEwayBillPdfDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plantItemDetails: {},
      total_invoice_value: 0.0,
      total_taxable_value: 0.0,
      total_value: 0.0,
      total_cess_value: 0.0,
      total_cgst_value: 0.0,
      total_sgst_value: 0.0,
      total_igst_value: 0.0,
      total_cess_non_advol_value: 0.0,
      total_freight_advance_amount: 0.0,
      total_quantity: 0.0,
      total_tcs_amount: 0.0,
      total_freight_amount: 0.0,
      eInvoiveResponse: null,
      eWayBillResponse: null,
      transporterDistance: 0.0,
    };
  }

  componentDidMount() {
    this.setState({
      plantItemDetails: this.props.plantItemDetails,
      total_invoice_value: this.props.total_invoice_value,
      total_taxable_value: this.props.total_taxable_value,
      total_value: this.props.total_value,
      total_cess_value: this.props.total_cess_value,
      total_cgst_value: this.props.total_cgst_value,
      total_sgst_value: this.props.total_sgst_value,
      total_igst_value: this.props.total_igst_value,
      eWayBillResponse: this.props.eWayBillResponse,
      total_cess_non_advol_value: this.props.total_cess_non_advol_value,
      total_freight_advance_amount: this.props.total_freight_advance_amount,
      total_freight_amount: this.props.total_freight_amount,
      total_quantity: this.props.total_quantity,
      total_tcs_amount: this.props.total_tcs_amount,
      transporterDistance: this.props.transporterDistance,
      eInvoiveResponse: this.props.eInvoiveResponse,
    });
  }

  getTransporterDetails = () => {
    let transporterId = this?.state?.plantItemDetails[0]?.TRANSPORTERID || "";

    let transporterName =
      this?.state?.plantItemDetails[0]?.TRANSPORTERNAME || "";
    let details =
      transporterId != null
        ? transporterId + " & " + transporterName
        : transporterName;
    return details;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.plantItemDetails !== state.plantItemDetails) {
      return { plantItemDetails: props.plantItemDetails };
    }
    if (props.total_invoice_value !== state.total_invoice_value) {
      return { total_invoice_value: props.total_invoice_value };
    }
    if (props.total_taxable_value !== state.total_taxable_value) {
      return { total_taxable_value: props.total_taxable_value };
    }
    if (props.total_value !== state.total_value) {
      return { total_value: props.total_value };
    }
    if (props.total_cess_value !== state.total_cess_value) {
      return { total_cess_value: props.total_cess_value };
    }
    if (props.total_cgst_value !== state.total_cgst_value) {
      return { total_cgst_value: props.total_cgst_value };
    }
    if (props.total_sgst_value !== state.total_sgst_value) {
      return { total_sgst_value: props.total_sgst_value };
    }
    if (props.total_igst_value !== state.total_igst_value) {
      return { total_igst_value: props.total_igst_value };
    }
    if (props.eWayBillResponse !== state.eWayBillResponse) {
      return { eWayBillResponse: props.eWayBillResponse };
    }
    if (props.total_cess_non_advol_value !== state.total_cess_non_advol_value) {
      return { total_cess_non_advol_value: props.total_cess_non_advol_value };
    }
    if (
      props.total_freight_advance_amount !== state.total_freight_advance_amount
    ) {
      return {
        total_freight_advance_amount: props.total_freight_advance_amount,
      };
    }
    if (props.total_freight_amount !== state.total_freight_amount) {
      return { total_freight_amount: props.total_freight_amount };
    }
    if (props.total_quantity !== state.total_quantity) {
      return { total_quantity: props.total_quantity };
    }
    if (props.total_tcs_amount !== state.total_tcs_amount) {
      return { total_tcs_amount: props.total_tcs_amount };
    }
    if (props.transporterDistance !== state.transporterDistance) {
      return { transporterDistance: props.transporterDistance };
    }
    if (props.eInvoiveResponse !== state.eInvoiveResponse) {
      return { eInvoiveResponse: props.eInvoiveResponse };
    }

    return null;
  }
  textToBarCodeBase64 = (text) => {
    let canvas = document.createElement("canvas");
    JsBarcode(canvas, text, { format: "CODE39", scale: 5 });
    return canvas.toDataURL("image/png");
  };
  handleViewOrDownloadEwayBillPdf = (pdfOperation) => {
    let wayBillItemTable = [];
    wayBillItemTable.push([
      { text: "HSN Code", style: "tableHeader" },
      { text: "Product Name & Desc.", style: "tableHeader" },
      { text: "Quantity", style: "tableHeader", alignment: "center" },
      {
        text: "Taxable Amount Rs.",
        style: "tableHeader",
        alignment: "right",
      },
      {
        text: "Tax Rate (C+S+I+Cess+Cess Non.Advol)",
        style: "tableHeader",
        alignment: "right",
      },
    ]);
    this.state.plantItemDetails.map((itemDetails) => {
      wayBillItemTable.push([
        { text: itemDetails?.HSNCODE || "", style: "tableDetailValues" },
        { text: itemDetails?.PRODUCTNAME || "", style: "tableDetailValues" },
        {
          text: `${parseFloat(itemDetails?.QUANTITY || 0).toFixed(3)} ${itemDetails?.QTYUNIT || ""
            }`,
          style: "tableDetailValues",
          alignment: "center",
        },
        {
          text: parseFloat(itemDetails?.TAXABLEVALUE || 0).toFixed(2),
          style: "tableDetailValues",
          alignment: "right",
        },
        {
          text: `${Number(itemDetails?.SGSTAMOUNT1) > Number(itemDetails?.IGSTAMOUNT1)
            ? parseFloat(itemDetails?.SGSTRATE || 0).toFixed(3) +
            "+" +
            parseFloat(itemDetails?.CGSTRATE || 0).toFixed(3) +
            "+0.000"
            : "0.000+0.000+" +
            parseFloat(itemDetails?.IGSTRATE || 0).toFixed(3)
            }+${parseFloat(itemDetails?.CESSAMOUNT1 || 0).toFixed(
              3
            )}+${parseFloat(itemDetails?.CESSNONADVOLVALUE || 0).toFixed(3)}`,
          style: "tableDetailValues",
          alignment: "right",
        },
      ]);
      return wayBillItemTable;
    });
    var dd = {
      info: {
        title: `${this?.state?.eWayBillResponse?.EwbNo
          ? this?.state?.eWayBillResponse?.EwbNo
          : this?.state?.eWayBillResponse?.EWayBill
            ? this?.state?.eWayBillResponse?.EWayBill
            : ""
          }_${new Date().toISOString()}`,
        author: "RIPL",
        subject: "E-Way Bill Document",
        keywords: "E-Way Bill Document",
      },
      pageMargins: [20, 20, 20, 20],
      content: [
        {
          columns: [
            {
              text: "Automated e-Way Bill",
              fontSize: "15",
              bold: true,
              width: "90%",
              alignment: "center",
            },
            !this?.state?.eWayBillResponse?.EwbNo ||
              !this?.state?.eWayBillResponse?.EwbDt
              ? !this?.state?.eWayBillResponse?.EWayBill ||
                !this?.state?.eWayBillResponse?.Date ||
                !this?.state?.plantItemDetails[0]?.SUPGSTIN
                ? null
                : {
                  qr: `${this?.state?.eWayBillResponse?.EWayBill}/${this.state.plantItemDetails[0].SUPGSTIN}/${this.state.eWayBillResponse.Date}`,
                  fit: "60",
                  width: "60",
                  height: "60",
                }
              : {
                qr: `${this?.state?.eWayBillResponse?.EwbNo}/${this.state.plantItemDetails[0].SUPGSTIN
                  }/${moment(
                    this.state.eWayBillResponse.EwbDt,
                    "YYYY-MM-DD h:mm:ss"
                  ).format("DD/MM/YYYY LTS")}`,
                fit: "60",
                width: "60",
                height: "60",
              },
          ],
          margin: [10, 10, 10, 10],
          alignment: "center",
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "1. E-Way Bill Details",
          style: "descriptionHeadingTitle",
        },
        {
          columns: [
            !this?.state?.eWayBillResponse?.EwbNo
              ? !this?.state?.eWayBillResponse?.EWayBill
                ? null
                : {
                  text: [
                    "eWay Bill No : ",
                    {
                      text: this?.state?.eWayBillResponse?.EWayBill,
                      bold: true,
                    },
                  ],
                  style: "detailsLabel",
                  width: "33%",
                }
              : {
                text: [
                  "eWay Bill No : ",
                  {
                    text: this?.state?.eWayBillResponse?.EwbNo,
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "33%",
              },
            !this?.state?.eWayBillResponse?.EwbDt
              ? !this?.state?.eWayBillResponse?.Date
                ? null
                : {
                  text: [
                    "Generated Date : ",
                    {
                      text: this.state.eWayBillResponse.Date,
                      bold: true,
                    },
                  ],
                  style: "detailsLabel",
                  width: "33%",
                }
              : {
                text: [
                  "Generated Date : ",
                  {
                    text: moment(
                      this.state.eWayBillResponse.EwbDt,
                      "YYYY-MM-DD h:mm:ss"
                    ).format("DD/MM/YYYY LT"),
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "33%",
              },
            !this?.state?.eWayBillResponse?.EwbValidTill
              ? !this?.state?.eWayBillResponse?.ValidUpTo
                ? null
                : {
                  text: [
                    "Valid Upto : ",
                    {
                      text: this.state.eWayBillResponse.ValidUpTo,
                      bold: true,
                    },
                  ],
                  style: "detailsLabel",
                  width: "33%",
                }
              : {
                text: [
                  "Valid Upto : ",
                  {
                    text: this.state.eWayBillResponse.EwbValidTill,
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "33%",
              },
          ],
          margin: [10, 0, 5, 0],
        },
        {
          columns: [
            {
              text: [
                "Generated By : ",
                {
                  text: this?.state?.plantItemDetails[0]?.SUPGSTIN || "",
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
            {
              text: [
                "Mode : ",
                {
                  text: this?.state?.plantItemDetails[0]?.MODEOFSUPPLY || "",
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
            {
              text: [
                "Approx Distance : ",
                {
                  text: !this?.state?.transporterDistance
                    ? ""
                    : `${this?.state?.transporterDistance || 0} KM`,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          columns: [
            {
              text: ["Type : ", { text: "Outward - Supply", bold: true }],
              style: "detailsLabel",
              width: "33%",
            },
            {
              text: [
                "Transaction type : ",
                {
                  text:
                    this.state.plantItemDetails[0]?.tran_type === "SHP"
                      ? "Bill To - Ship To"
                      : "Regular",
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
            {
              text: [
                "Document Details : ",
                {
                  text: `Tax Invoice - ${this?.state?.plantItemDetails[0]?.DOCNO || ""
                    } - ${moment(
                      this?.state?.plantItemDetails[0]?.DOCDATE,
                      "DD/MM/YYYY"
                    ).format("DD/MM/YYYY") || ""
                    }`,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "33%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        !this?.state?.eInvoiveResponse?.Irn
          ? null
          : {
            columns: [
              {
                text: [
                  "IRN : ",
                  {
                    text: this?.state?.eInvoiveResponse?.Irn || "",
                    bold: true,
                  },
                ],
                style: "detailsLabel",
                width: "100%",
              },
            ],
            margin: [10, 5, 5, 0],
          },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "2. Address Details",
          style: "descriptionHeadingTitle",
        },
        {
          columns: [
            {
              columns: [
                {
                  text: [
                    "From\n",
                    {
                      text: `GSTIN : ${this?.state?.plantItemDetails[0]?.SUPGSTIN || ""
                        }\n${this?.state?.plantItemDetails[0]?.SUPNAME || ""}\n${this?.state?.plantItemDetails[0]?.GST_FROM_STATE || ""
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
                {
                  text: [
                    "To\n",
                    {
                      text: `GSTIN : ${this?.state?.plantItemDetails[0]?.RECGSTIN || "URP"
                        }\n${this?.state?.plantItemDetails[0]?.RECNAME || ""}\n${this?.state?.plantItemDetails[0]?.RECADD1 || ""
                        }, ${this?.state?.plantItemDetails[0]?.RECADD2 || ""}, ${this?.state?.plantItemDetails[0]?.RECCITY || ""
                        },${this?.state?.plantItemDetails[0]?.RECSTATE || ""} - ${this?.state?.plantItemDetails[0]?.RECPINCODE || ""
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
              ],
            },
          ],
          margin: [10, 5, 10, 5],
        },
        {
          columns: [
            {
              columns: [
                {
                  text: [
                    ":: Dispatch From ::\n",
                    {
                      text: `${this?.state?.plantItemDetails[0]?.SUPADD1 || ""
                        }\n${this?.state?.plantItemDetails[0]?.SUPADD2 || ""}\n${this?.state?.plantItemDetails[0]?.SUPCITY || ""
                        },${this?.state?.plantItemDetails[0]?.GST_FROM_STATE || ""
                        } - ${this?.state?.plantItemDetails[0]?.SUPPINCODE || ""
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
                {
                  text: [
                    ":: Ship To ::\n",
                    {
                      text: `
                      GSTIN : ${this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN ||
                        "URP"
                        }\n${this?.state?.plantItemDetails[0]?.CONSIGNEENAME || ""
                        }\n${this?.state?.plantItemDetails[0]?.CONSIGNEEADD1 || ""
                        }, ${this?.state?.plantItemDetails[0]?.CONSIGNEEADD2 || ""
                        }, ${this?.state?.plantItemDetails[0]?.CONSIGNEECITY || ""
                        },${this?.state?.plantItemDetails[0]?.CONSIGNEESTATE || ""
                        } - ${this?.state?.plantItemDetails[0]?.CONSIGNEEPINCODE || ""
                        }`,
                      bold: false,
                    },
                  ],
                  style: "detailsLabel",
                  bold: true,
                },
              ],
            },
          ],
          margin: [10, 5, 10, 5],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "3. Goods Details",
          style: "descriptionHeadingTitle",
        },
        {
          style: "table",
          widths: [50, 250, 100, 100, "*"],
          table: {
            headerRows: 1,
            body: wayBillItemTable,
          },
        },
        {
          columns: [
            {
              text: [
                "Tot. Taxable Amt ",
                { text: this?.state?.total_taxable_value || 0, bold: true },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "CGST Amt ",
                { text: this?.state?.total_cgst_value || 0, bold: true },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "SGST Amt ",
                { text: this?.state?.total_sgst_value || 0, bold: true },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "IGST Amt ",
                { text: this?.state?.total_igst_value || 0, bold: true },
              ],
              style: "detailsLabel",
              width: "25%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          columns: [
            {
              text: [
                "CESS Amt ",
                { text: this?.state?.total_cess_value || 0, bold: true },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "CESS Non.Advol Amt ",
                {
                  text: this?.state?.total_cess_non_advol_value || 0,
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "Other Amt ",
                {
                  text: parseFloat(this?.state?.total_tcs_amount || 0).toFixed(
                    2
                  ),
                  bold: true,
                },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: [
                "Total Inv.Amt ",
                { text: this?.state?.total_invoice_value || 0, bold: true },
              ],
              style: "detailsLabel",
              width: "25%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          columns: [
            {
              text: [
                "Total Qty :  ",
                { text: this?.state?.total_quantity || 0, bold: true },
              ],
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: "",
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: "",
              style: "detailsLabel",
              width: "25%",
            },
            {
              text: "",
              style: "detailsLabel",
              width: "25%",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "4. Transportation Details",
          style: "descriptionHeadingTitle",
        },
        {
          columns: [
            {
              text: [
                "Transporter ID & Name : ",
                {
                  text: this.getTransporterDetails(),
                },
              ],
              style: "detailsLabel",
            },
            {
              text: [
                "Transporter Doc. No & Date : ",
                {
                  text: `${this?.state?.plantItemDetails[0]?.TRANSDOCNO || ""
                    } & ${moment(
                      new Date(this?.state?.plantItemDetails[0]?.TRANSDOCDATE)
                    ).format("DD/MM/YYYY")}`,
                },
              ],
              style: "detailsLabel",
            },
          ],
          margin: [10, 5, 5, 0],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: "5. Vehicle Details",
          style: "descriptionHeadingTitle",
          // margin:[10,10,10,10],
        },
        {
          style: "table",
          widths: [50, "*", 60, 140, 60, 18, 18],
          table: {
            headerRows: 1,
            body: [
              [
                { text: "Mode", style: "tableHeader" },
                { text: "Vehicle / Trans Doc No & Dt.", style: "tableHeader" },
                { text: "From", style: "tableHeader" },
                { text: "Entered Date", style: "tableHeader" },
                { text: "Entered By", style: "tableHeader" },
                { text: "CEWB No. (If any)", style: "tableHeader" },
                { text: "Multi Veh. Info (If any)", style: "tableHeader" },
              ],
              [
                {
                  text: this?.state?.plantItemDetails[0]?.MODEOFSUPPLY || "",
                  style: "tableDetailValues",
                },
                {
                  text: `${this?.state?.plantItemDetails[0]?.VEHICLENO || ""
                    } & ${this?.state?.plantItemDetails[0]?.TRANSDOCNO || ""
                    } & ${moment(
                      new Date(this?.state?.plantItemDetails[0]?.TRANSDOCDATE)
                    ).format("DD/MM/YYYY")}`,
                  style: "tableDetailValues",
                },
                {
                  text: this?.state?.plantItemDetails[0]?.SUPCITY || "",
                  style: "tableDetailValues",
                },
                {
                  text: !this?.state?.eWayBillResponse?.EwbDt
                    ? !this?.state?.eWayBillResponse?.Date
                      ? ""
                      : this?.state?.eWayBillResponse?.Date
                    : moment(
                      this.state.eWayBillResponse.EwbDt,
                      "YYYY-MM-DD h:mm:ss"
                    ).format("DD/MM/YYYY LT"),
                  // style: "enteredDateDisplay",
                  style: "tableDetailValues",
                },
                {
                  text: this?.state?.plantItemDetails[0]?.SUPGSTIN || "",
                  style: "tableDetailValues",
                },
                { text: "---", style: "tableDetailValues" },
                { text: "---", style: "tableDetailValues" },
              ],
            ],
          },
        },
        !this?.state?.eWayBillResponse?.EwbNo
          ? !this?.state?.eWayBillResponse?.EWayBill
            ? null
            : {
              image: this.textToBarCodeBase64(
                this?.state?.eWayBillResponse?.EWayBill || ""
              ),
              width: 120,
              height: 55,
              alignment: "center",
              margin: [10, 5, 10, 5],
            }
          : {
            image: this.textToBarCodeBase64(
              this?.state?.eWayBillResponse?.EwbNo || ""
            ),
            width: 120,
            height: 55,
            alignment: "center",
            margin: [10, 5, 10, 5],
          },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 555,
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
      ],
      styles: {
        detailsTitleStyle: {
          fontSize: "12",
          bold: true,
        },
        table: {
          margin: [10, 5, 10, 5],
        },
        descriptionHeadingTitle: {
          fontSize: 10,
          bold: true,
          margin: [10, 5, 5, 5],
        },
        detailsLabel: {
          fontSize: 8,
        },
        tableHeader: {
          fontSize: 9,
          bold: true,
        },
        tableDetailValues: {
          fontSize: 9,
        },
        enteredDateDisplay: {
          fontSize: 6,
        },
      },
      defaultStyle: {
        columnGap: 10,
      },
    };

    if (pdfOperation === "VIEW") {
      pdfMake.createPdf(dd).open();
    } else if (pdfOperation === "DOWNLOAD") {
      pdfMake
        .createPdf(dd)
        .download(
          `${this?.state?.eWayBillResponse?.EwbNo
            ? this?.state?.eWayBillResponse?.EwbNo
            : this?.state?.eWayBillResponse?.EWayBill
              ? this?.state?.eWayBillResponse?.EWayBill
              : ""
          }_${new Date().toISOString()}.pdf`
        );
    } else {
      console.log("invalid pdf operation");
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Button
        className={classes.primaryBackground}
        variant="outlined"
        sx={{ textTransform: "none" }}
        style={{ width: this.props.pdfOperation === "VIEW" ? "25%" : "55%", height: "24px" }}
        size="small"
        // startIcon={<PictureAsPdfOutlinedIcon />}
        onClick={(evt) => {
          evt.preventDefault();
          this.handleViewOrDownloadEwayBillPdf(this.props.pdfOperation);
        }}
      >
        <span style={{ fontSize: "0.8em" }}>
          {this.props.pdfOperation}
        </span>
      </Button>
    );
  }
}
export default withStyles(eWayBillPdfStyles)(RIPLEwayBillPdfDownload);
