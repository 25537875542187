import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Button,
  Divider,
  Avatar,
  TextField,
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  orange,
  secondaryGrey,
  lightGreen,
  cancelButtonBackground,
  secondaryBlueText,
  highlitedTextColor,
  drawerTextColor,
  primaryColor,
} from "../../../../../constants/internal/colors";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import DepartureBoardOutlinedIcon from "@material-ui/icons/DepartureBoardOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import LAStatusStepper from "./LAStatusStepper";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 45px)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33%",
    display: "flex",
    flexDirection: "column",
    fontSize: theme.typography.pxToRem(5),
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  helperRight: {
    borderRight: `2px solid ${theme.palette.divider}`,
    margin: theme.spacing(1, 2),
  },

  titleRow: {
    display: "flex",
    placeItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  itemDetailsDiv: {
    display: "flex",
  },
  title: {
    color: secondaryGrey,
  },
  laStatusText: {
    fontSize: theme.typography.pxToRem(8),
  },
  statusIcon: {
    display: "flex",
    flexDirection: "column",
    placeItems: "center",
    placeContent: "center",
    marginRight: 10,
  },
  laActionsDiv: {
    display: "flex",
    width: "100%",
    placeContent: "center",
    placeItems: "center",
  },

  actionButton: {
    margin: 5,
  },

  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  loaderStyle: { color: primaryColor },
  title: { width: "35%" },
  data: {
    width: "65%",
  },
  LAtitle: {
    width: "35%",
    fontWeight: "bold",
  },
  LAdata: {
    width: "65%",
    fontWeight: "bold",
  },
}));
const styles = {
  iconStyles: (status) => ({
    color:
      status === "Pending" || status === "PENDING"
        ? secondaryGrey
        : status === "Approved" || status === "APPROVED"
          ? lightGreen
          : status === "Gate-In" || status === "GATEIN"
            ? orange
            : status === "Loading" || status === "LOADING"
              ? orange
              : status === "Packed" || status === "PACKED"
                ? orange
                : status === "Billed" || status === "BILLED"
                  ? orange
                  : status === "Dispatched" || status === "DISPATCHED"
                    ? highlitedTextColor
                    : status === "Lapsed" || status === "LAPSED"
                      ? cancelButtonBackground
                      : secondaryBlueText,
    border: `1px solid ${status === "Pending" || status === "PENDING"
      ? secondaryGrey
      : status === "Approved" || status === "APPROVED"
        ? lightGreen
        : status === "Gate-In" || status === "GATEIN"
          ? orange
          : status === "Loading" || status === "LOADING"
            ? orange
            : status === "Packed" || status === "PACKED"
              ? orange
              : status === "Billed" || status === "BILLED"
                ? orange
                : status === "Dispatched" || status === "DISPATCHED"
                  ? highlitedTextColor
                  : status === "Lapsed" || status === "LAPSED"
                    ? cancelButtonBackground
                    : secondaryBlueText
      }`,
    backgroundColor: "transparent",
    borderRadius: "50%",
  }),
  statusIcon: {
    display: "flex",
    flexDirection: "column",
    placeItems: "center",
    placeContent: "center",
    marginRight: 10,
  },
};

function LoadingAdviceListDisplayComponent(props) {
  const classes = useStyles();
  const { history } = props;
  const [rejectLaRemark, setRejectLaRemark] = React.useState(null);
  const [removeConfirmationDialogData, setRemoveConfirmationDialogData] =
    React.useState({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });

  const handleRejectClick = (laId) => {
    setRemoveConfirmationDialogData({
      openDialog: true,
      dialogTitle: `Discard LA ${laId} !`,
      dialogContent: `Confirm LA ${laId} discard`,
    });
  };
  const handleRemoveConfirmationDialogClose = () => {
    setRemoveConfirmationDialogData({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });
  };
  const handleRejectLaRemarkChange = (event) => {
    setRejectLaRemark(event.target.value);
  };
  const handleApproveClick = (laId) => {
    let laStatusUpdateData = {
      dispatch_status: "APPROVED",
      dispatch_remark: "",
    };
    props.handleLaApprove(laId, laStatusUpdateData);
  };
  const handleConfirmationDialogDeleteClick = () => {
    let laId = props?.displayData?.id;
    let laStatusUpdateData = {
      dispatch_status: "CANCELLED",
      dispatch_remark: rejectLaRemark,
    };
    props.handleCancelLa(laId, laStatusUpdateData);
    setRemoveConfirmationDialogData({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });
  };
  const [isCopied, setIsCopied] = React.useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.titleRow}>
          <div className={classes.statusIcon}>
            <Avatar
              variant="rounded"
              style={styles.iconStyles(props?.displayData?.dispatch_status)}
            >
              {props?.displayData?.status === "Pending" ||
                props?.displayData?.dispatch_status === "PENDING" ? (
                <HourglassEmptyOutlinedIcon />
              ) : props?.displayData?.status === "Approved" ||
                props?.displayData?.dispatch_status === "APPROVED" ? (
                <CheckOutlinedIcon />
              ) : props?.displayData?.status === "Gate-In" ||
                props?.displayData?.dispatch_status === "GATEIN" ? (
                <ExitToAppOutlinedIcon />
              ) : props?.displayData?.status === "Loading" ||
                props?.displayData?.dispatch_status === "LOADING" ? (
                <DashboardOutlinedIcon />
              ) : props?.displayData?.status === "Packed" ||
                props?.displayData?.dispatch_status === "PACKED" ? (
                <DepartureBoardOutlinedIcon />
              ) : props?.displayData?.status === "Billed" ||
                props?.displayData?.dispatch_status === "BILLED" ? (
                <LibraryBooksOutlinedIcon />
              ) : props?.displayData?.status === "Dispatched" ||
                props?.displayData?.dispatch_status === "DISPATCHED" ? (
                <LocalShippingOutlinedIcon />
              ) : props?.displayData?.status === "Lapsed" ||
                props?.displayData?.dispatch_status === "LAPSED" ? (
                <CancelPresentationOutlinedIcon />
              ) : (
                <AssignmentIcon />
              )}
            </Avatar>
            <Typography variant="caption" className={classes.laStatusText}>
              {props?.displayData?.status
                ? props?.displayData?.status
                : props?.displayData?.dispatch_status}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {props?.displayData?.plant?.plant_name
                ? props?.displayData?.plant?.plant_name
                : props?.displayData?.plant}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {props?.displayData?.item_category?.category_name
                ? props?.displayData?.item_category?.category_name
                : props?.displayData?.item_category}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {props?.displayData?.la_number}{" "}
            </Typography>
            <CopyToClipboard
              text={props?.displayData?.la_number}
              onCopy={onCopyText}
            >
              {isCopied ? (
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "normal",
                    color: "#FFB600",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  Copied!
                </Typography>
              ) : (
                <ContentCopyOutlinedIcon />
              )}
            </CopyToClipboard>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {props?.displayData?.truck_number}
            </Typography>
            <Typography variant="caption" className={classes.title}>
              {props?.displayData?.gate_in_date}
            </Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={clsx(classes.column, classes.helperRight)}>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Gate In Date :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.gate_in_date}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Buyer :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.buyer?.first_name
                ? props?.displayData?.buyer?.first_name
                : props?.displayData?.buyer}{" "}
              {props?.displayData?.buyer?.last_name
                ? props?.displayData?.buyer?.last_name
                : " "}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              {!props?.displayData?.buyer?.gstin_number ? "Pan" : "Gstin"} :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.buyer?.gstin_number
                ? props?.displayData?.buyer?.gstin_number || ""
                : props?.displayData?.buyer?.pan_card_number
                  ? props?.displayData?.buyer?.pan_card_number || ""
                  : ""}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Phone :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.buyer?.phone || ""}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Address :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.buyer?.address?.address || " "}{" "}
              {props?.displayData?.buyer?.address?.city || " "}{" "}
              {props?.displayData?.buyer?.address?.district || " "}{" "}
              {props?.displayData?.buyer?.address?.state || " "}{" "}
              {props?.displayData?.buyer?.address?.pincode || " "}{" "}
              {props?.displayData?.buyer?.address?.country || " "}{" "}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Consignee :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.consignee?.consignee_detail?.first_name
                ? props?.displayData?.consignee?.consignee_detail?.first_name
                : props?.displayData?.consignee}{" "}
              {props?.displayData?.consignee?.consignee_detail?.last_name
                ? props?.displayData?.consignee?.consignee_detail?.last_name
                : ""}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              {!props?.displayData?.buyer?.gstin_number ? "Pan" : "Gstin"} :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.consignee?.consignee_detail?.gstin_number
                ? props?.displayData?.consignee?.consignee_detail
                  ?.gstin_number || ""
                : props?.displayData?.consignee?.consignee_detail
                  ?.pan_card_number
                  ? props?.displayData?.consignee?.consignee_detail
                    ?.pan_card_number || ""
                  : ""}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Phone :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.consignee?.consignee_detail?.phone || ""}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Address :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.consignee?.consignee_address?.address || ""}{" "}
              {props?.displayData?.consignee?.consignee_address?.city || ""}{" "}
              {props?.displayData?.consignee?.consignee_address?.district || ""}{" "}
              {props?.displayData?.consignee?.consignee_address?.state || ""}{" "}
              {props?.displayData?.consignee?.consignee_address?.pincode || ""}{" "}
              {props?.displayData?.consignee?.consignee_address?.country || ""}{" "}
            </Typography>
          </div>
        </div>

        <div className={classes.column}>
          {props?.displayData?.loading_advice_items
            ? props?.displayData?.loading_advice_items.length > 0
              ? props?.displayData?.loading_advice_items.map((data, index) => (
                <div className={classes.itemDetailsDiv} key={index}>
                  <Typography variant="caption" className={classes.title}>
                    Item :{" "}
                  </Typography>
                  <Typography variant="caption" className={classes.data}>
                    {data.item} Q.: {data.item_qty} MT
                  </Typography>
                </div>
              ))
              : null
            : null}
        </div>
        <div className={clsx(classes.column, classes.helper)}>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Transporter :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.transporter?.acc_name || ""}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Transporter Phone :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.transporter?.mobile || ""}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Driver :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.driver_name}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Driver Phone :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.driver_contact_number}
            </Typography>
          </div>
          <div className={classes.itemDetailsDiv}>
            <Typography variant="caption" className={classes.title}>
              Payment Term :{" "}
            </Typography>
            <Typography variant="caption" className={classes.data}>
              {props?.displayData?.payment_method?.payment_method_name}
            </Typography>
          </div>
        </div>
      </AccordionDetails>
      <Divider />
      <div className={classes.laActionsDiv}>
        <LAStatusStepper
          laData={props?.displayData}
          laStatus={props?.displayData?.status
            ? props?.displayData?.status
            : props?.displayData?.dispatch_status}
        />
      </div>
      {props?.displayData?.dispatch_status === "PENDING" ? (
        <>
          <Divider />
          <AccordionActions>
            <Button
              className={classes.actionButton}
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  `/internalContracts/order/${props?.orderId}/editLoadingAdvice/${props?.displayData?.id}`
                );
              }}
            >
              EDIT
            </Button>
            <Button
              className={classes.actionButton}
              onClick={(e) => {
                e.preventDefault();
                handleApproveClick(props?.displayData?.id);
              }}
            >
              APPROVE
            </Button>
            <Button
              className={classes.actionButton}
              onClick={(e) => {
                e.preventDefault();
                handleRejectClick(props?.displayData?.id);
              }}
            >
              REJECT
            </Button>
          </AccordionActions>
          <Dialog
            open={removeConfirmationDialogData.openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleRemoveConfirmationDialogClose}
          >
            <DialogTitle>
              {removeConfirmationDialogData.dialogTitle}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {removeConfirmationDialogData.dialogContent}
              </DialogContentText>

              <TextField
                type="text"
                label="Remark *"
                multiline
                rows={3}
                value={rejectLaRemark}
                onChange={handleRejectLaRemarkChange}
                variant="outlined"
                size="small"
              />
            </DialogContent>
            <DialogActions>
              {props.removeDataLoader ? (
                <CircularProgress
                  disableShrink
                  className={classes.loaderStyle}
                />
              ) : (
                <>
                  <Button
                    onClick={handleRemoveConfirmationDialogClose}
                    className={classes.margin}
                  >
                    CANCEL
                  </Button>
                  <Button
                    onClick={handleConfirmationDialogDeleteClick}
                    className={classes.removeButton}
                    disabled={!rejectLaRemark ? true : false}
                  >
                    DELETE
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </>
      ) : null}
      <Divider />
    </Accordion>
  );
}
export default withRouter(LoadingAdviceListDisplayComponent);
