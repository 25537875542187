import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import Single from "../Single";
import { withStyles } from "@material-ui/core/styles";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {};
class SingleOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    this.props.history.push("/orders");
  };
  render() {
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        <Single {...this.props} />
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(SingleOrders);
