import React from "react";
import Layout from "../../../layouts/internalLayout/InternalLayout";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  constainer: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
  },
  messages: {
    backgroundColor: "#8080800a",
    borderRadius: 10,
    width: "100%",
    padding: 20,
    margin: 10,
    maxHeight: "70%",
    overflow: "auto",
  },
}));

function Weighbridges(props) {
  const classes = useStyles();

  React.useEffect(() => {
    class SerialScaleController {
      constructor() {
        this.encoder = new TextEncoder();
        this.decoder = new TextDecoder();
      }
      async init() {
        if ("serial" in navigator) {
          try {
            const port = await navigator.serial.requestPort();
            await port.open({ baudrate: 2400 });
            this.reader = port.readable.getReader();
            let signals = await port.getSignals();
            console.log(signals);
          } catch (err) {
            console.error("There was an error opening the serial port:", err);
          }
        } else {
          console.error(
            "Web serial doesn't seem to be enabled in your browser. Try enabling it by visiting:",
            "\nchrome://flags/#enable-experimental-web-platform-features",
            "\nopera://flags/#enable-experimental-web-platform-features",
            "\nedge://flags/#enable-experimental-web-platform-features"
          );
        }
      }
      async read() {
        try {
          const readerData = await this.reader.read();
          console.log(readerData);
          return this.decoder.decode(readerData.value);
        } catch (err) {
          const errorMessage = `error reading data: ${err}\n`;
          console.error(errorMessage);
          return errorMessage;
        }
      }
    }

    const serialScaleController = new SerialScaleController();
    const connect = document.getElementById("connect-to-serial");
    const getSerialMessages = document.getElementById("get-serial-messages");

    connect.addEventListener("pointerdown", () => {
      serialScaleController.init();
    });

    getSerialMessages.addEventListener("pointerdown", async () => {
      getSerialMessage();
    });

    async function getSerialMessage() {
      document.querySelector(
        "#serial-messages-container .message"
      ).innerText += await serialScaleController.read();
    }
  }, []);

  return (
    <Layout {...props}>
      <div className={classes.constainer}>
        <div className={classes.buttonContainer}>
          <button className="btn" id="connect-to-serial">
            Connect with Serial Device
          </button>
          <button id="get-serial-messages">Get serial messages</button>
        </div>
        <div id="serial-messages-container" className={classes.messages}>
          <div className="message"></div>
        </div>
      </div>
    </Layout>
  );
}

export default withStyles(useStyles)(Weighbridges);
