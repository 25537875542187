import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import LedgerRequest from "./LedgerRequest";
import LedgerRequestDetail from "./LedgerRequestDetail";
import backStack from "cordova-back-stack";

const styles = {

};
class Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      this.props.history.goBack();
    });
  }

  componentDidMount() {
    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = String(localStorage.getItem("currentUrl"));
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
  }

  render() {
    const {
      isMobile,
      classes,
      ledgerRequestData,
      ledgerRequestStatus,
      handleDateChange,
      fromDate,
      toDate,
      ledgerType,
      isLoading,
      user
    } = this.props;
    return (
      <div>
        {ledgerRequestData?.status === null || ledgerRequestData?.status === undefined
          ? <LedgerRequest
            {...this.props}
            classes={classes}
            isMobile={isMobile}
            fromDate={fromDate}
            toDate={toDate}
            user={user}
            ledgerRequestData={ledgerRequestData}
            handleDateChange={handleDateChange}
            ledgerType={ledgerType}
            handleLedgerType={this.props.handleLedgerType}
            handleSubmitButton={this.props.handleSubmitButton}
            handleClearState={this.props.handleClearState}
          />
          :
          <LedgerRequestDetail
            {...this.props}
            classes={classes}
            isMobile={isMobile}
            user={user}
            ledgerRequestData={ledgerRequestData}
            ledgerRequestStatus={ledgerRequestStatus}
            isLoading={isLoading}
            handleResetLedgerRequest={this.props.handleResetLedgerRequest}
            handleClearState={this.props.handleClearState}
            handleDownloadPDFButton={this.props.handleDownloadPDFButton}
          />
        }
      </div>
    );
  }
}
export default withStyles(styles)(Ledger);
