import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { grey, secondaryBlueText } from "../../../../constants/internal/colors";
import PresentToAllOutlinedIcon from "@material-ui/icons/PresentToAllOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { pxToEm } from "../../../../methods";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "65%",
  },
  formControlPanImage: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  generalDetailsDisplaySection: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "45%" },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
});
const CustomCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function ManageCustomerGeneralDetails(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation}>
          Provide the general company related information.
        </Typography>
        <Typography className={classes.fillingInformation}>
          &nbsp;Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <div className={classes.dataDisplay}>
          {!props.customerDontHaveGSTIN ? (
            <>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>GSTIN *</Typography>
                {props.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props.gstinNumber}
                  </Typography>
                ) : (
                  <TextField
                    className={classes.formControl}
                    disabled={
                      props.customerOperation === "edit" ||
                      props.customerOperation === "view"
                    }
                    label="GSTIN"
                    autoComplete="off"
                    value={props.gstinNumber}
                    variant="outlined"
                    size="small"
                    name="gstin"
                    onChange={props.handleChange("gstinNumber")}
                    InputProps={{
                      endAdornment:
                        props.customerOperation === "add" ? (
                          <InputAdornment position="end">
                            {props.validGstinNumber ? (
                              props.gstDataFetchLoader ? (
                                <CircularProgress disableShrink size={30} />
                              ) : (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.getCustomerGSTINData(
                                      props?.gstinNumber
                                    );
                                  }}
                                  edge="end"
                                >
                                  VERIFY
                                </Button>
                              )
                            ) : null}
                          </InputAdornment>
                        ) : null,
                    }}
                    error={
                      props.customerOperation === "add"
                        ? props.validGstinNumber !== ""
                          ? !props.validGstinNumber
                          : false
                        : false
                    }
                    helperText={
                      props.customerOperation === "add"
                        ? props.validGstinNumber !== ""
                          ? !props.validGstinNumber
                            ? "GSTIN number invalid"
                            : ""
                          : ""
                        : ""
                    }
                  />
                )}
              </div>
              {props?.gstDataValidated ? null : (
                <div className={classes.dataFieldsForCheckbox}>
                  <CustomCheckbox
                    checked={props.customerDontHaveGSTIN}
                    onChange={(evt) => {
                      props.handleCustomerDontHaveGSTINChange(evt);
                    }}
                    name="customerDontHaveGSTIN"
                  />{" "}
                  <Typography className={classes.generalText}>
                    I don't have GSTIN
                  </Typography>
                </div>
              )}
            </>
          ) : null}
          {!props.customerDontHaveGSTIN ? (
            props?.gstDataValidated ? (
              <>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>
                    TRADE NAME
                  </Typography>
                  {props.customerOperation === "view" ? (
                    <Typography className={classes.formControl}>
                      {props.tradeName}
                    </Typography>
                  ) : (
                    <TextField
                      disabled={true}
                      className={classes.formControl}
                      id="outlined-size-small"
                      value={props.tradeName}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>
                    LEGAL NAME
                  </Typography>
                  {props.customerOperation === "view" ? (
                    <Typography className={classes.formControl}>
                      {props.legalNameOfBusiness}
                    </Typography>
                  ) : (
                    <TextField
                      disabled={true}
                      className={classes.formControl}
                      id="outlined-size-small"
                      value={props.legalNameOfBusiness}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>
                    GST STATUS
                  </Typography>
                  {props.customerOperation === "view" ? (
                    <Typography className={classes.formControl}>
                      {props.GSTINStatus}
                    </Typography>
                  ) : (
                    <TextField
                      disabled={true}
                      className={classes.formControl}
                      id="outlined-size-small"
                      value={props.GSTINStatus}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </div>
                <div className={classes.dataFields}>
                  <Typography className={classes.titleLabel}>
                    DATE OF REG.
                  </Typography>
                  {props.customerOperation === "view" ? (
                    <Typography className={classes.formControl}>
                      {props.dateOfRegistration}
                    </Typography>
                  ) : (
                    <TextField
                      disabled={true}
                      className={classes.formControl}
                      id="outlined-size-small"
                      value={props.dateOfRegistration}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </div>
              </>
            ) : null
          ) : (
            <>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  PAN NUMBER
                </Typography>
                {props.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props.panNumber}
                  </Typography>
                ) : (
                  <TextField
                    autoComplete="off"
                    className={classes.formControl}
                    label="PAN NUMBER"
                    id="outlined-size-small"
                    value={props.panNumber}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("panNumber")}
                    error={props.panNumberError}
                    helperText={
                      props.panNumberError ? "Invalid pan number" : ""
                    }
                  />
                )}
              </div>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  PAN HOLDER NAME{" "}
                </Typography>
                {props.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props.panHolderName}
                  </Typography>
                ) : (
                  <TextField
                    autoComplete="off"
                    className={classes.formControl}
                    label="PAN HOLDER NAME"
                    id="outlined-size-small"
                    value={props.panHolderName}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("panHolderName")}
                    error={props.panHolderNameError}
                    helperText={
                      props.panHolderNameError ? "Invalid pan holder name" : ""
                    }
                  />
                )}
              </div>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  PAN CARD IMAGE{" "}
                </Typography>
                <div className={classes.formControlPanImage}>
                  <label htmlFor="upload-button">
                    {props.PanImagePreview ? (
                      <div className={classes.generalAlign}>
                        <img
                          src={props.PanImagePreview}
                          alt=""
                          width="80"
                          height="40"
                        />
                      </div>
                    ) : (
                      <div className={classes.panImageUpload}>
                        {" "}
                        <PresentToAllOutlinedIcon
                          className={classes.panImagePreview}
                        />
                        <Typography className={classes.generalText}>
                          Upload
                        </Typography>
                      </div>
                    )}
                  </label>
                  <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    inputProps={{ accept: "image/*" }}
                    onChange={(e) => {
                      e.preventDefault();
                      props.handlePanImageChange(e);
                    }}
                  />
                  {!props.PanImagePreview ? null : (
                    <DeleteOutlineOutlinedIcon
                      width="20"
                      height="20"
                      onClick={props.handleRemovePanImage}
                    />
                  )}
                </div>
              </div>
              <div className={classes.dataFieldsForCheckbox}>
                <Typography className={classes.titleLabel}>
                  PAN CARD VERIFIED
                </Typography>
                <>
                  <CustomCheckbox
                    checked={props.customerPanVerified}
                    onChange={(evt) => {
                      props.handlePanVerifyChange(evt);
                    }}
                    name="panVerified"
                  />
                </>
              </div>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>
                  COMPANY NAME{" "}
                </Typography>
                {props.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props.companyName}
                  </Typography>
                ) : (
                  <TextField
                    autoComplete="off"
                    className={classes.formControl}
                    label="COMPANY NAME"
                    id="outlined-size-small"
                    value={props.companyName}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("companyName")}
                    error={!props.companyName}
                    helperText={
                      !props.companyName ? "Company name required" : ""
                    }
                  />
                )}
              </div>
            </>
          )}
          {props.customerDontHaveGSTIN || props.gstDataValidated ? (
            <>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>COUNTRY</Typography>
                {props.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props.country}
                  </Typography>
                ) : (
                  <TextField
                    disabled={props.gstDataValidated}
                    autoComplete="off"
                    className={classes.formControl}
                    label="COUNTRY"
                    id="outlined-size-small"
                    value={props.country}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("country")}
                    error={props.countryError}
                    helperText={props.countryError ? "Country invalid" : ""}
                  />
                )}
              </div>
              <div className={classes.dataFields}>
                <Typography className={classes.titleLabel}>STATE</Typography>
                {props.customerOperation === "view" ? (
                  <Typography className={classes.formControl}>
                    {props.customerState}
                  </Typography>
                ) : (
                  <TextField
                    disabled={props.gstDataValidated}
                    autoComplete="off"
                    className={classes.formControl}
                    label="STATE"
                    id="outlined-size-small"
                    value={props.customerState}
                    variant="outlined"
                    size="small"
                    onChange={props.handleChange("customerState")}
                    error={props.customerStateError}
                    helperText={props.customerStateError ? "State invalid" : ""}
                  />
                )}
              </div>
            </>
          ) : null}
        </div>
        {props.customerDontHaveGSTIN || props.gstDataValidated ? (
          <div className={classes.dataDisplay}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                CUSTOMER TYPE *
              </Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.customerType}
                </Typography>
              ) : (
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={props.customerType}
                    error={!props.customerType}
                    onChange={(evt) => props.handleCustomerTypeChange(evt)}
                  >
                    <MenuItem value={"DEALER"}>Dealer</MenuItem>
                    <MenuItem value={"DISTRIBUTOR"}>Distributor</MenuItem>
                    <MenuItem value={"BROKER"}>Broker</MenuItem>
                    <MenuItem value={"CONSUMER"}>Consumer</MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ADDRESS LINE 1 *
              </Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.addressLineOne}
                </Typography>
              ) : (
                <TextField
                  autoComplete="off"
                  className={classes.formControl}
                  label="ADDRESS LINE 1"
                  id="outlined-size-small"
                  value={props.addressLineOne}
                  variant="outlined"
                  size="small"
                  onChange={props.handleChange("addressLineOne")}
                  error={!props.addressLineOne}
                  helperText={!props.addressLineOne ? "Address required" : ""}
                />
              )}
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ADDRESS LINE 2
              </Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.addressLineTwo}
                </Typography>
              ) : (
                <TextField
                  autoComplete="off"
                  className={classes.formControl}
                  label="ADDRESS LINE 2"
                  id="outlined-size-small"
                  value={props.addressLineTwo}
                  variant="outlined"
                  size="small"
                  onChange={props.handleChange("addressLineTwo")}
                />
              )}
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ADDRESS LINE 3
              </Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.addressLineThree}
                </Typography>
              ) : (
                <TextField
                  autoComplete="off"
                  className={classes.formControl}
                  label="ADDRESS LINE 3"
                  id="outlined-size-small"
                  value={props.addressLineThree}
                  variant="outlined"
                  size="small"
                  onChange={props.handleChange("addressLineThree")}
                />
              )}
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>CITY *</Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.city}
                </Typography>
              ) : (
                <TextField
                  type="text"
                  autoComplete="off"
                  className={classes.formControl}
                  label="CITY"
                  id="outlined-size-small"
                  value={props.city}
                  variant="outlined"
                  onChange={props.handleChange("city")}
                  size="small"
                  error={props.cityError}
                  helperText={props.cityError ? "Invalid city name" : ""}
                />
              )}
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>PIN CODE *</Typography>
              {props.customerOperation === "view" ? (
                <Typography className={classes.formControl}>
                  {props.pinCode}
                </Typography>
              ) : (
                <TextField
                  type="number"
                  autoComplete="off"
                  className={classes.formControl}
                  label="PIN CODE"
                  id="outlined-size-small"
                  value={props.pinCode}
                  variant="outlined"
                  error={props.pinCodeError}
                  onChange={props.handleChange("pinCode")}
                  helperText={props.pinCodeError ? "Invalid pin code" : ""}
                  size="small"
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
