import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import "../../../../styles/b2b/web/DailyRatesWeb.css";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Home from "../Home";
import CreateOrderWeb from "./CreateOrder";

const styles = {
  dailyRatesWebMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    overflowY: "inherit",
    width: "100%",
  },
};
class DailyRatesWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      classes,
      subscribedDailyRates,
      subscribedDailyRatesLoader,
      dailyRatesLastUpdatedAt,
      fetchOrderList,
      isOffline,
      isMobile,
      bannerData,
      invoicesList,
      invoicesPaymentDetail,
      invoiceLoader,
      handleOpenSnackbar,
      handleInvoiceClick,
      b2CQrImage,
      handlePostInvoiceDownload,
      laDetailsInDispatchCounter
    } = this.props;

    return (
      <WebLayout appBar dailyRates>
        <div className={classes.dailyRatesWebMainBody}>
          <Home
            {...this.props}
            isMobile={isMobile}
            subscribedDailyRates={subscribedDailyRates}
            subscribedDailyRatesLoader={subscribedDailyRatesLoader}
            dailyRatesLastUpdatedAt={dailyRatesLastUpdatedAt}
            fetchOrderList={fetchOrderList}
            bannerData={bannerData}
            invoicesList={invoicesList}
            invoicesPaymentDetail={invoicesPaymentDetail}
            invoiceLoader={invoiceLoader}
            handleOpenSnackbar={handleOpenSnackbar}
            handleInvoiceClick={handleInvoiceClick}
            b2CQrImage={b2CQrImage}
            handlePostInvoiceDownload={handlePostInvoiceDownload}
            laDetailsInDispatchCounter={laDetailsInDispatchCounter}
            eInvoiceResponse={this.props?.eInvoiceResponse}
            eWayBillResponse={this.props?.eWayBillResponse}
            iseWaySelect={this.props?.iseWaySelect}
            plant_state={this.props?.plant_state}
            invoiceType={this.props?.invoiceType}
          />
        </div>
        <Snackbar
          open={isOffline}
          onClose={this.handleClose}
          autoHideDuration={4000}
          TransitionComponent={"up"}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          message={<span id="message-id">No Internet Connection</span>}
        />
      </WebLayout>
    );
  }
}
export default withStyles(styles)(DailyRatesWeb);
export { CreateOrderWeb };
