import React, { Component } from "react";
import UsersAndPermissionWeb, {
  UsersAndPermissionDetails,
  ManageAccessArea,
  ManageInternalUsers,
  ManageRolesAndPermissions,
} from "../../../components/internal/usersAndPermission";
import OpenInDesktop from "../../../components/internal/common/OpenInDesktop";
import AccessDenied from "../../../components/common/AccessDenied";
import { Route } from "react-router-dom";
import axios from "axios";
import URLS from "../../../constants/internal/InternalApiRoutes";
import { compareValues, isAccessibleModule } from "../../../methods";

class UsersAndPermissionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      users: [],
      modules: [],
      errorRoles: "",
      errorUsers: "",
      errorModules: "",
      loadingRoles: false,
      loadingUsers: false,
      loadingModules: false,
      singleUser: null,
      loadingUser: false,
      errorUser: "",
      role: null,
      loadingRole: false,
      errorRole: "",
      rolesNameList: []
    };
  }

  componentDidMount() {
    if (isAccessibleModule(this.props?.user, "Users And Permissions")) {
      this.getRolesAndPermissions();
      this.getInternalUsers();
      this.getAccessAreaManagement();
    }
  }

  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  fetchItemcategories = () => {
    return axios.get(`${URLS.GET_ITEM_CATEGORIES_LIST}?active=true`, this.getOptions(this.props.user));
  }

  getRolesAndPermissions = () => {
    let roles = [];
    const loadingRoles = true;
    const errorRoles = "";
    this.setState({ roles, loadingRoles, errorRoles });
    axios
      .get(
        `${URLS.ROLES_AND_PERMISSIONS}?ordering=role_name`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          var data = response.data;
          roles = data.map((element) => ({
            id: element.id,
            modifiedAt: new Date(element.modified_at),
            assignedToCounts: element.role_assigned_to_users_count,
            name: element.role_name,
          }));
          this.setState({ rolesNameList: data.map((e, i) => (e.role_name).toUpperCase()) })
          this.setState({ roles, loadingRoles: false, errorRoles: "" });
        },
        (errorRoles) => {
          console.error(errorRoles);
          this.setState({ roles: [], loadingRoles: false, errorRoles });
        }
      );
  };

  getInternalUsers = () => {
    let users = [];
    const loadingUsers = true;
    const errorUsers = "";
    this.setState({ users, loadingUsers, errorUsers });
    axios
      .get(`${URLS.GET_ALL_INTERNAL_USERS}`, this.getOptions(this.props.user))
      .then(
        (response) => {
          var data = response.data;
          users = data
            .map((element) => ({
              id: element.id,
              email: element.email,
              is_admin: element.is_admin,
              is_active: element.is_active,
              employee_id: element.employee_id,
              firstName: element.personal_details.first_name,
              lastName: element.personal_details.last_name,
              fullName: `${element.personal_details.first_name} ${element.personal_details.last_name}`,
              createdAt: new Date(element.created_at),
              phone: element.phone,
              roles: element?.roles.map((e, i) => e.role.role_name),
            }))
            .sort(compareValues("firstName"));
          this.setState({
            // users: users.filter((e) => e.is_active),
            users: users,
            loadingUsers: false,
            errorUsers: "",
          });
        },
        (errorUsers) => {
          console.error(errorUsers);
          this.setState({ users: [], loadingUsers: false, errorUsers });
        }
      );
  };

  getAccessAreaManagement = () => {
    let modules = [];
    const loadingModules = true;
    const errorModules = "";
    this.setState({ modules, loadingModules, errorModules });
    axios.get(URLS.GET_ALL_ACCESS_AREA, this.getOptions(this.props.user)).then(
      (response) => {
        var data = response.data;
        modules = data.map((element) => ({
          id: element.id,
          createdAt: new Date(element.created_at),
          modifiedAt: new Date(element.modified_at),
          name: element.module_name,
          widgets: element.widgets,
        }));
        this.setState({ modules, loadingModules: false, errorModules: "" });
      },
      (errorModules) => {
        console.error(errorModules);
        this.setState({
          modules: [],
          loadingModules: false,
          errorModules,
        });
      }
    );
  };

  getInternalUserById = (id) => {
    let singleUser = null;
    const loadingUser = true;
    const errorUser = "";
    this.setState({ singleUser, loadingUser, errorUser });
    axios
      .get(`${URLS.GET_INTERNAL_USER}${id}/`, this.getOptions(this.props?.user))
      .then(
        (response) => {
          var data = response.data;
          const singleUser = {
            id: data.id,
            personal_details_id: data.personal_details.id,
            first_name: data.personal_details.first_name,
            last_name: data.personal_details.last_name,
            date_of_birth: data.personal_details.date_of_birth,
            employee_id: data.employee_id,
            email: data.email,
            phone: data.phone,
            is_active: data.is_active,
            is_sales_person: data?.is_sales_person,
          };
          this.setState({ singleUser, loadingUser: false, errorUser: "" });
        },
        (errorUser) => {
          console.error(errorUser);
          this.setState({
            singleUser: null,
            loadingUser: false,
            errorUser,
          });
        }
      );
  };

  postInternalUser = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${URLS.POST_INTERNAL_USER}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.getInternalUsers(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  putInternalUser = (data) =>
    new Promise((resolve, reject) => {
      let credentials = false;
      let personal = false;
      axios
        .put(
          `${URLS.UPDATE_INTERNAL_USER}${data.personal_details_id}/`,
          data,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          // credentials = response;
          // if (personal) {
          this.getInternalUsers(this.getOptions(this.props?.user));
          // resolve({ credentials, personal });
          resolve(response);
          // }
        })
        .catch((e) => {
          reject(e);
        });
      // axios
      //   .put(
      //     `${URLS.PUT_INTERNAL_USER_PERSONAL}${data.personal_details_id}`,
      //     data,
      //     this.getOptions(this.props?.user)
      //   )
      //   .then((response) => {
      //     personal = response;
      //     if (credentials) {
      //       this.getInternalUsers(this.getOptions(this.props?.user));
      //       resolve({ credentials, personal });
      //     }
      //   })
      //   .catch((e) => {
      //     reject(e);
      //   });
    });

  deleteInternalUser = (id) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${URLS.REMOVE_INTERNAL_USER}${id}/`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.getInternalUsers(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  getARoleAndPermissions = (id) => {
    let role = null;
    const loadingRole = true;
    const errorRole = "";
    this.setState({ role, loadingRole, errorRole });
    axios
      .get(
        `${URLS.ROLES_AND_PERMISSIONS}${id}/`,
        this.getOptions(this.props?.user)
      )
      .then(
        (response) => {
          var data = response.data;
          const role = {
            id: data.id,
            name: data.role_name,
            password_valid_upto: data.password_valid_upto,
            accesses: data.role_accesses.map((a) => ({
              module_name: a.module.module_name,
              module_id: a.module.id,
              id: a.id,
              widgets: a.widgets.map((w) => ({
                id: w.id,
                widget_id: w?.widget?.id,
                widget_name: w?.widget?.widget_name,
                is_accessible_on_mobile: w?.widget?.is_accessible_on_mobile,
                is_accessible_on_web: w?.widget?.is_accessible_on_web,
                is_ip_restricted: w?.widget?.is_ip_restricted,
                permission_id: w?.widget?.permissions?.id,
                has_create_permission:
                  w?.widget?.permissions?.has_create_permission,
                has_delete_permission:
                  w?.widget?.permissions?.has_delete_permission,
                has_read_permission:
                  w?.widget?.permissions?.has_read_permission,
                has_update_permission:
                  w?.widget?.permissions?.has_update_permission,
                has_approve_permission:
                  w?.widget?.permissions?.has_approve_permission,
                has_special_permission:
                  w?.widget?.permissions?.has_special_permission,
                white_listed_ips: w?.widget?.white_listed_ips?.map(
                  (ip) => ip.ip_address
                ),
              })),
            })),
            users: data.role_assigned_to_users.map((e, i) => ({
              id: e?.id,
              email: e?.assigned_to?.email,
              first_name: e?.assigned_to?.personal_details?.first_name,
              last_name: e?.assigned_to?.personal_details?.last_name,
              phone: e?.assigned_to?.phone,
              employee_id: e?.assigned_to?.employee_id,
              created_at: new Date(e?.created_at),
            })),
          };
          this.setState({ role, loadingRole: false, errorRole: "" });
        },
        (errorRole) => {
          console.error(errorRole);
          this.setState({ role: null, loadingRole: false, errorRole });
        }
      );
  };

  addModuleToARole = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(URLS.MODULES_TO_ROLE, data, this.getOptions(this.props?.user))
        .then((response) => {
          resolve(response);
        })
        .catch(reject);
    });

  addIpsInBulk = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(URLS.IPS_BULK, data, this.getOptions(this.props?.user))
        .then((response) => {
          // this.getAccessAreaManagement(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  deleteIp = (id) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`${URLS.IPS_DELETE}${id}/`, this.getOptions(this.props?.user))
        .then((response) => {
          // this.getAccessAreaManagement(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  deleteAModuleFromRole = (id) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${URLS.MODULES_TO_ROLE}${id}/`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          resolve(response);
        })
        .catch(reject);
    });

  addAUserToARole = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(URLS.ROLE_TO_A_USER, data, this.getOptions(this.props?.user))
        .then((response) => {
          this.getInternalUsers(this.getOptions(this.props?.user));
          this.getRolesAndPermissions(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  addARole = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(URLS.ROLE, data, this.getOptions(this.props?.user))
        .then((response) => {
          this.getRolesAndPermissions(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  updateWidgetPermissionForRole = (data) =>
    new Promise((resolve, reject) => {
      console.log(data)
      axios
        .put(
          `${URLS.PERMISSIONS}${data.permissionId}/`,
          data,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          // this.getARoleAndPermissions(data.role);
          resolve(response);
        })
        .catch(reject);
    });

  updateAWidget = (data) =>
    new Promise((resolve, reject) => {
      axios
        .put(
          `${URLS.WIDGETS}${data.id}/`,
          data,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          // this.getAccessAreaManagement(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  updateARole = (data) =>
    new Promise((resolve, reject) => {
      axios
        .put(`${URLS.ROLE}${data.id}/`, data, this.getOptions(this.props?.user))
        .then((response) => {
          resolve(response);
        })
        .catch(reject);
    });

  deleteARole = (id) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`${URLS.ROLE}${id}/`, this.getOptions(this.props?.user))
        .then((response) => {
          this.getRolesAndPermissions(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(error => {
          if (error['response']['status'] === 500) {
            this.getRolesAndPermissions(this.getOptions(this.props?.user));
            resolve(error)
          }
          reject(error);
        })
    });

  deleteAUserFromARole = (id) =>
    new Promise((resolve, reject) => {
      axios
        .delete(
          `${URLS.ROLE_TO_A_USER}${id}/`,
          this.getOptions(this.props?.user)
        )
        .then((response) => {
          this.getRolesAndPermissions(this.getOptions(this.props?.user));
          this.getInternalUsers(this.getOptions(this.props?.user));
          resolve(response);
        })
        .catch(reject);
    });

  render() {
    const { isMobile } = this.props;
    if (isAccessibleModule(this.props?.user, "Users And Permissions")) {
      return (
        <>
          <Route
            exact
            path={"/internalUsersAndPermissions/rolesAndPermissions/:mode"}
            render={(props) =>
            // this.props?.isMobile ? (
            //   <OpenInDesktop {...this.props} />
            // ) : 
            (
              <ManageRolesAndPermissions
                {...props}
                {...this.state}
                {...this.props}
                isMobile={isMobile}
                deleteAModuleFromRole={this.deleteAModuleFromRole}
                addModuleToARole={this.addModuleToARole}
                addARole={this.addARole}
                addAUserToARole={this.addAUserToARole}
                deleteAUserFromARole={this.deleteAUserFromARole}
                getARoleAndPermissions={this.getARoleAndPermissions}
              />
            )
            }
          />
          <Route
            exact
            path={"/internalUsersAndPermissions/rolesAndPermissions/view/:id"}
            render={(props) =>
            // this.props?.isMobile ? (
            //   <OpenInDesktop {...this.props} />
            // ) : 
            (
              <ManageRolesAndPermissions
                {...props}
                {...this.state}
                {...this.props}
                isMobile={isMobile}
                updateWidgetPermissionForRole={
                  this.updateWidgetPermissionForRole
                }
                deleteAModuleFromRole={this.deleteAModuleFromRole}
                addModuleToARole={this.addModuleToARole}
                addARole={this.addARole}
                addAUserToARole={this.addAUserToARole}
                deleteAUserFromARole={this.deleteAUserFromARole}
                getARoleAndPermissions={this.getARoleAndPermissions}
              />
            )
            }
          />
          <Route
            exact
            path={"/internalUsersAndPermissions/rolesAndPermissions/edit/:id"}
            render={(props) =>
            // this.props?.isMobile ? (
            //   <OpenInDesktop {...this.props} />
            // ) : 
            (
              <ManageRolesAndPermissions
                {...props}
                {...this.state}
                {...this.props}
                isMobile={isMobile}
                updateWidgetPermissionForRole={
                  this.updateWidgetPermissionForRole
                }
                deleteARole={this.deleteARole}
                updateARole={this.updateARole}
                deleteAModuleFromRole={this.deleteAModuleFromRole}
                addModuleToARole={this.addModuleToARole}
                addARole={this.addARole}
                addAUserToARole={this.addAUserToARole}
                deleteAUserFromARole={this.deleteAUserFromARole}
                getARoleAndPermissions={this.getARoleAndPermissions}
              />
            )
            }
          />
          <Route
            exact
            path={"/internalUsersAndPermissions/accessAreaManagement/edit"}
            render={(props) =>
            // this.props?.isMobile ? (
            //   <OpenInDesktop {...this.props} />
            // ) : 
            (
              <ManageAccessArea
                modules={this.state.modules}
                {...props}
                {...this.state}
                {...this.props}
                isMobile={isMobile}
                addIpsInBulk={this.addIpsInBulk}
                updateAWidget={this.updateAWidget}
                deleteIp={this.deleteIp}
              />
            )
            }
          />
          <Route
            exact
            path={[
              "/internalUsersAndPermissions/internalUsers/:mode/:id",
              "/internalUsersAndPermissions/internalUsers/:mode",
            ]}
            render={(props) =>
            // this.props?.isMobile ? (
            //   <OpenInDesktop {...this.props} />
            // ) : 
            (
              <ManageInternalUsers
                {...props}
                {...this.state}
                {...this.props}
                isMobile={isMobile}
                deleteInternalUser={this.deleteInternalUser}
                putInternalUser={this.putInternalUser}
                postInternalUser={this.postInternalUser}
                getInternalUserById={this.getInternalUserById}
                fetchItemcategories={this.fetchItemcategories}
              />
            )
            }
          />
          <Route
            exact
            path={[
              "/internalUsersAndPermissions/rolesAndPermissions",
              "/internalUsersAndPermissions/accessAreaManagement",
              "/internalUsersAndPermissions/internalUsers",
            ]}
            render={(props) =>
            // this.props?.isMobile ? (
            //   <OpenInDesktop {...this.props} />
            // ) : 
            (
              <UsersAndPermissionDetails
                {...props}
                {...this.state}
                {...this.props}
                isMobile={isMobile}
                displaySection={
                  props.location.pathname.split("/")[
                  props.location.pathname.split("/").length - 1
                  ]
                }
              />
            )
            }
          />
          <Route
            exact
            path="/internalUsersAndPermissions"
            render={(props) =>
            // this.props?.isMobile ? (
            //   <OpenInDesktop {...this.props} />
            // ) : 
            (
              <UsersAndPermissionWeb
                {...props}
                {...this.props}
                {...this.state}
                isMobile={isMobile}
              />
            )
            }
          />
        </>
      );
    } else {
      return <AccessDenied />;
    }
  }
}
export default UsersAndPermissionsContainer;
