import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { checkAlphabet, checkAlphaNumericLength, checkApiError, pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveButton from "../../common/SaveButton";
// import { Autocomplete } from "@mui/material";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
  isActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "70%",
  }
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
function ManageCustomerTypeData(props) {
  const classes = useStyles();
  // const [error, setError] = React.useState(false);
  const [customerTypeData, setCustomerTypeData] = React.useState(
    props.selectedCustomerTypeData || {
      id: null,
      type_name: null,
      description: null,
      is_active: true,
    }
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [customerTypeError, setCustomerTypeError] = React.useState({
    type_name: false,
    description: false,
  })
  const [customerTypeOperation, setCustomerTypeOperation] = React.useState(
    props.customerTypeOperation
  );
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });

  React.useEffect(() => {
    if (props.customerTypeOperation !== 'add') {
      if (props.selectedCustomerTypeData.type_name) {
        !checkAlphabet(props.selectedCustomerTypeData.customerType) ?
          setCustomerTypeError(prevValue => { return { ...prevValue, type_name: true } }) :
          setCustomerTypeError(prevValue => { return { ...prevValue, type_name: false } });
      }
      if (props.selectedCustomerTypeData.description) {
        !checkAlphabet(props.selectedCustomerTypeData.description) ?
          setCustomerTypeError(prevValue => { return { ...prevValue, description: true } }) :
          setCustomerTypeError(prevValue => { return { ...prevValue, description: false } });
      }
    }
  }, [props.selectedCustomerTypeData, props.customerTypeOperation]);

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, customerType) => {
    if (customerType === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }


  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    console.log(value, "value");
    if (name === "is_active") {
      setCustomerTypeData({
        ...customerTypeData,
        [name]: checked,
      });
    } else {
      setCustomerTypeData({
        ...customerTypeData,
        [name]: value,
      });
      if (!value) {
        setCustomerTypeError(prevValue => {
          return {
            ...prevValue, [name]: true
          }
        })
      } else {
        if (name === 'type_name') {
          !checkAlphabet(value) ?
            setCustomerTypeError(prevValue => { return { ...prevValue, [name]: true } }) :
            setCustomerTypeError(prevValue => { return { ...prevValue, [name]: false } })
        } else if (name === 'description') {
          !checkAlphabet(value) ?
            setCustomerTypeError(prevValue => { return { ...prevValue, [name]: true } }) :
            setCustomerTypeError(prevValue => { return { ...prevValue, [name]: false } })
        }
      }
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };

  const handleEditSaveButtonClick = () => {
    let addData = {
      type_name: customerTypeData.type_name,
      description: customerTypeData.description,
      is_active: (customerTypeData.is_active) === undefined ? true : Boolean(customerTypeData.is_active),
    }
    setIsLoading(true)
    if (customerTypeOperation === "edit") {
      props
        .handleCustomerTypeDataUpdate(customerTypeData)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Customer Type Detail Updated Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                props.history.goBack()
              }, 3000)
            }
            // props.history.goBack();
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while updating Customer Type details please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (customerTypeOperation === "add") {
      props
        .handleCustomerTypeDataAdd(addData)
        .then((response) => {
          console.log("handleCustomerTypeDataAdd response : ", response);
          if (response.status === 201) {
            handleOpenSnackbar(
              `Customer Type Created Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setTimeout(() => {
                setIsLoading(false)
                props.history.push(`${INTERNALROUTES.CUSTOMER_TYPE}/${response.data.id}`)
              }, 3000)
            }
            // props.history.push(`${INTERNALROUTES.CUSTOMER_TYPE}/${response.data.customerType}`);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              response?.data?.details
                ? response?.data?.details
                : response?.data?.message
                  ? response?.data?.message
                  : "Error occured while creating Customer Type master please try again",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };
  React.useEffect(() => {
    setCustomerTypeData(props?.selectedCustomerTypeData);
    setCustomerTypeOperation(props.customerTypeOperation);
  }, [props?.selectedCustomerTypeData, props.customerTypeOperation]);
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Customer Types Master",
                onClick: () => props.history.push(INTERNALROUTES.CUSTOMER_TYPE),
              },
              {
                title: customerTypeOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TYPE NAME *
              </Typography>
              <TextField
                label="Type Name"
                name="type_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter customer Type name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={customerTypeData?.type_name || ""}
                error={customerTypeError.type_name}
                helperText={customerTypeError.type_name ? "Invalid Name" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                DESCRIPTION *
              </Typography>
              <TextField
                label="Description"
                name="description"
                type="text"
                onChange={handleChange}
                placeholder="Enter description"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={customerTypeData?.description || ""}
                error={customerTypeError.description}
                helperText={customerTypeError.description ? "Invalid Field" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ACTIVE</Typography>
              <div className={classes.isActive}>
                <IOSSwitch
                  checked={(customerTypeData.is_active) === undefined
                    ? true
                    : Boolean(customerTypeData.is_active)
                  }
                  onChange={handleChange}
                  name="is_active"
                />
              </div>
            </div>
            <SaveButton
              size="medium"
              disabled={
                !customerTypeData?.type_name ||
                !customerTypeData?.description ||
                customerTypeError?.type_name ||
                customerTypeError.description
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {customerTypeOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
    </Layout>
  );
}
export default withRouter(ManageCustomerTypeData);
