import React, { Component } from 'react'
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormHelperText } from "@material-ui/core";


export class FilterByQuantityRange extends Component {

    render() {
        const { minQuantity, maxQuantity } = this.props
        // console.log(maxQuantity && !(maxQuantity === ""))
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
            }}>
                <TextField
                    name="minQuantity"
                    id="outlined-name"
                    label="Min Qty"
                    value={minQuantity}
                    style={{ width: "48%" }}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">MT</InputAdornment>,
                    }}
                    onKeyPress={
                        (event) => {
                            if (event.charCode >= 48 && event.charCode <= 57) {

                            } else {
                                event.preventDefault()
                            }
                        }
                    }
                    error={(maxQuantity && !(maxQuantity === "")) && (!minQuantity || minQuantity === "")}
                    helperText={(maxQuantity && !(maxQuantity === "")) && (!minQuantity || minQuantity === "") &&
                        "*Required"
                        // :
                        // minQuantity > maxQuantity
                        // && "invalid input"
                    }

                    // InputLabelProps={{
                    //     style: { fontSize: "0.9em" },
                    // }}
                    onChange={this.props.handleChange("minQuantity")}
                />
                <TextField
                    name="maxQuantity"
                    id="outlined-name"
                    label="Max Qty"
                    value={maxQuantity}
                    style={{ width: "48%", marginLeft: "4%" }}
                    margin="normal"
                    variant="outlined"
                    onChange={this.props.handleChange("maxQuantity")}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">MT</InputAdornment>,
                    }}
                    onKeyPress={
                        (event) => {
                            if (event.charCode >= 48 && event.charCode <= 57) {

                            } else {
                                event.preventDefault()
                            }
                        }
                    }
                    error={Number(minQuantity) > Number(maxQuantity)}
                    helperText={(minQuantity && !(minQuantity === "")) && (!maxQuantity || maxQuantity === "") ?
                        "*Required"
                        :
                        Number(minQuantity) > Number(maxQuantity)
                        && "invalid input"
                    }

                />

                <br></br>

                {/* <FormHelperText error>
                    {
                        minQuantity > maxQuantity &&
                        "Maximum quantity should be more than minimum quantity "
                    }
                </FormHelperText> */}

            </div>

            //   const value = e.target.value.replace(/[^0-9]/g, "");
        )
    }
}

export default FilterByQuantityRange
