import React, { Component } from "react";
import EinvoicingAndEwayBillingWeb, {
  EinvoicingAndEwayBillingMobile,
  EinvoicingCreate,
  EinvoicingCreateWeb,
  EinvoicingDownloadInvoice,
  EinvoicingDownloadInvoiceWeb,
} from "../../../components/internal/eInvoicingAndEwayBilling";
import { Route } from "react-router-dom";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import outward from "../../../components/internal/eInvoicingAndEwayBilling/data/outward";
import transportMode from "../../../components/internal/eInvoicingAndEwayBilling/data/transportMode";
import axios from "axios";
import withUser from "../../../hoc/withUser";
import moment from "moment";
import upiqr from "upiqr";
import HOST from "../../../constants/common/host";
import SnackbarComponent from "../../../components/common/SnackbarComponent";

// const EWAY = "https://ngsales.realdeals.pro/api/erp/generate-e-way/";
const EWAY = `${APIROUTES.ERP_EWAY_GENERATE}`;

class InternalEinvoicingAndEwayBillingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInvoicePostLoader: false,
      allPlants: [],
      outward: outward,
      transportMode: transportMode,
      vrNumber: "",
      Tran_SupTyp: "",
      plantName: "",
      plantCode: false,
      EInvUserName: "",
      EInvPassword: "",
      invoiceMethod: [{ name: "Invoice" }, { name: "Invoice with e-invoice" }],
      invoiceType: "",
      eWayBillSelected: "",
      transporterDistance: 0.0,
      total_invoice_value: 0.0,
      total_taxable_value: 0.0,
      total_value: 0.0,
      total_cess_value: 0.0,
      total_cgst_value: 0.0,
      total_sgst_value: 0.0,
      total_freight_amount: 0.0,
      total_freight_advance_amount: 0.0,
      total_cess_non_advol_value: 0.0,
      total_quantity: 0.0,
      total_igst_value: 0.0,
      total_tax_on_amount: 0.0,
      eInvoiveResponse: null,
      eWayBillResponse: null,
      einvoiveErrorMessage: "",
      total_tcs_amount: 0,
      plantItemDetails: [],
      vehicleTypeOption: [{ name: "R" }, { name: "O" }],
      ewayErrorResponseMessage: null,
      plantAccountDetails: {
        plantCode: null,
        companyName: null,
        bankName: null,
        accountNumber: null,
        ifscCode: null,
        upiId: null,
      },
      b2CQrImage: null,
      actualDistance: null,
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    };
  }

  componentDidMount() {
    this.fetchPlantsList()
    this.getAllStatesList()
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  handleActualDistance = (data) => {
    this.setState({ actualDistance: data !== '' ? Number(data) : data }, console.log(this.state?.actualDistance))
  }

  handleTextChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleVRNumberTextChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value.toUpperCase().trim(),
    });

  handleSetInvoiceType = (name) =>
    this.setState({
      invoiceType: name,
    });
  getAllStatesList = (countryId) => {
    axios.get(`${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}`,
      // this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = response.data
          this.setState({
            allStateList: responseData,
            // allStateListDataFetchLoader: false,
          });
          // console.log("STATE LIST" + this.state.allStateList)
          // for (let key in responseData) {
          //   console.log(key + ":", responseData[key]);
          // }
        },
        (error) => {
          console.log("error", error);
          // this.setState({ allStateListDataFetchLoader: false, });
        }
      );

  };
  handleSetPlantNameAndPlantCode = (data) => {
    this.setState({
      plantName: data.plant_short_name,
      plantCode: data.plant_code,
      EInvUserName: data.EInvUserName,
      EInvPassword: data.EInvPassword,
      plantAccountDetails: {
        plantCode: data.plant_code,
        companyName: data.plant_name,
        bankName: data.bank_name,
        accountNumber: data.plant_account_no,
        ifscCode: data.plant_account_ifsc,
        upiId: data.plant_upi_id,
      },
    });
  };

  handleResetAllData = () => {
    this.setState({
      erpFetchError: "",
      vrNumber: "",
      errorWhilePostForInvoice: "",
      plantCode: false,
      plantName: false,
      plantItemDetails: [],
      eInvoiveResponse: null,
      eWayBillResponse: null,
      erpFetchLoader: false,
      total_invoice_value: 0.0,
      total_taxable_value: 0.0,
      total_value: 0.0,
      total_cess_value: 0.0,
      total_cgst_value: 0.0,
      total_sgst_value: 0.0,
      total_freight_amount: 0.0,
      total_freight_advance_amount: 0.0,
      total_cess_non_advol_value: 0.0,
      total_igst_value: 0.0,
      total_quantity: 0.0,
      total_tax_on_amount: 0.0,
      invoiceType: "",
      eWayBillSelected: "",
      ewayErrorResponseMessage: null,
      transporterDistance: 0.0,
      plantAccountDetails: {
        plantCode: null,
        companyName: null,
        bankName: null,
        accountNumber: null,
        ifscCode: null,
        upiId: null,
      },
      b2CQrImage: null,
    });
  };

  handleFetchERPData = (t_code) => {
    this.setState({ erpFetchLoader: true });
    axios
      .get(
        `${APIROUTES.GET_ERP}?docno=${this.state.vrNumber}&entity=${this.state.plantCode}&format=json&t_code=${t_code}`
      )
      .then((response) => {
        if (response.data.rows.length !== 0) {
          if (
            this.state.vrNumber === response.data.rows[0].DOCNO &&
            this.state.plantCode === response.data.rows[0].ENTITY
          ) {
            let item_logo = null
            let plant_banner = null
            if (response.data.item_category_logo.includes(" ")) {

              item_logo = response.data.item_category_logo.replace(/ /g, "%20")
            }
            if (response.data.plant_banner.includes(" ")) {
              console.log(response.data.plant_banner)
              plant_banner = response.data.plant_banner.replace(/ /g, "%20")
            }
            this.setState(
              {
                erpFetchLoader: false,
                plantItemDetails: response.data.rows,
                erpFetchError: false,
                total_invoice_value: response.data.total_invoice_value,
                total_taxable_value: response.data.total_taxable_value,
                total_value: response.data.total_value,
                total_cess_value: response.data.total_cess_value,
                total_cgst_value: response.data.total_cgst_value,
                total_sgst_value: response.data.total_sgst_value,
                total_freight_amount: response.data.total_freight_amount,
                total_freight_advance_amount:
                  response.data.total_freight_advance_amount,
                total_cess_non_advol_value:
                  response.data.total_cess_non_advol_value,
                total_quantity: response.data.total_quantity,
                total_igst_value: response.data.total_igst_value,
                total_tcs_amount: response.data.total_tcs_amount,
                total_tax_on_amount: response.data.total_tax_on_amount,
                item_category_logo: response.data.item_category_logo && item_logo ? HOST + "media/" + item_logo :
                  response.data.item_category_logo ? HOST + "media/" + response.data.item_category_logo : null,
                plant_banner: response.data.plant_banner && plant_banner ? HOST + "media/" + plant_banner :
                  response.data.plant_banner ? HOST + "media/" + response.data.plant_banner : null
              },
              () => {
                if (response.data.rows[0].DOCTYPE === "INV") {
                  this.handleGetDistance();
                }
              }
            );
          } else this.setState({ erpFetchLoader: false });
        } else {
          this.setState({ erpFetchError: true, erpFetchLoader: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          erpFetchLoader: false,
          openSnackbar: true,
          snackbarMessage: error?.response?.data?.message
            ? error?.response?.data?.message :
            "Something went wrong. Please try again",
          snackbarType: "error",
        });
      });
  };

  getNumber = (number) => {
    if (number === null || isNaN(number)) {
      return 0;
    } else {
      return parseFloat(number);
    }
  };

  getItem_AssAmt = ({ QUANTITY, DISCOUNT_RATE, FREIGHTRATE }) => {
    // Item Ass Amount = Item_TotAmt - Discount Price
    return (
      this.getNumber(QUANTITY) *
      (this.getNumber(DISCOUNT_RATE) + this.getNumber(FREIGHTRATE))
    ).toFixed(2);
  };

  getTotalItemValue = ({
    IGSTRATE,
    QUANTITY,
    CGSTAMOUNT1,
    SGSTAMOUNT1,
    IGSTAMOUNT1,
    CESSAMOUNT1,
    FREIGHTRATE,
    DISCOUNT_RATE,
  }) => {
    // Total Item Value = Assessable Amount + CGST Amt + SGST Amt + Cess Amt + CesNonAdvlAmt + StateCesAmt + StateCesNonAdvlAmt + Otherchrg
    let totalItemValue =
      this.getNumber(
        this.getItem_AssAmt({ QUANTITY, DISCOUNT_RATE, FREIGHTRATE })
      ) + this.getNumber(CESSAMOUNT1);
    if (IGSTRATE === 0)
      totalItemValue += this.getNumber(CGSTAMOUNT1 + SGSTAMOUNT1);
    else totalItemValue += this.getNumber(IGSTAMOUNT1);
    return totalItemValue.toFixed(2);
  };
  handleCreateEwayUsingIRN = (IRN) => {
    this.setState({ ewayPostDataLoader: true });
    const E_date = this.state.plantItemDetails[0]?.DOCDATE.split("/");
    const E_docDate = E_date[2] + E_date[1] + E_date[0];
    let ewayData = {
      Invoice_data: {
        PlantCode: this.state.plantItemDetails[0]?.ENTITY,
        TCode: "S",
        GSTIN: this?.state?.plantItemDetails[0]?.SUPGSTIN || "",
        DocNo: this?.state?.plantItemDetails[0]?.DOCNO || "",
        DocDate: E_docDate,
        SupGSTIN: this.state.plantItemDetails[0]?.SUPGSTIN || "",
        SupName: this?.state?.plantItemDetails[0]?.SUPNAME || "",
        SupPincode: this?.state?.plantItemDetails[0]?.SUPPINCODE || "",
        RecGSTIN:
          this?.state?.plantItemDetails[0]?.RECGSTIN !==
            this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN
            ? this?.state?.plantItemDetails[0]?.RECGSTIN
            : this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN || "",
        RecName:
          this?.state?.plantItemDetails[0]?.RECGSTIN !==
            this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN
            ? this.state.plantItemDetails[0]?.RECNAME
            : this.state.plantItemDetails[0]?.CONSIGNEENAME,
        Recpincode: this?.state?.plantItemDetails[0]?.CONSIGNEEPINCODE || "",
        TransporterName:
          this?.state?.plantItemDetails[0]?.TRANSPORTERNAME || "",
        TransDistance: this.state.actualDistance == "" ? 0 : this.state.actualDistance, // parseFloat(this.state.transporterDistance).toFixed(0),
        VehicleNo: this?.state?.plantItemDetails[0]?.VEHICLENO || "",
        HSNCode: this?.state?.plantItemDetails[0]?.HSNCODE || "",
      },
      Irn_data: {
        Irn: IRN,
        Distance: !(['', null, undefined].includes(this.state?.actualDistance)) ? this.state?.actualDistance : 0,
        TransMode: 1,
        VehNo: this?.state?.plantItemDetails[0]?.VEHICLENO,
        VehType: "R",
        GSTIN: this?.state?.plantItemDetails[0]?.SUPGSTIN,
        PlantCode: this?.state?.plantItemDetails[0]?.ENTITY,
      },
    };
    axios({
      // url: "https://ngsales.realdeals.pro/api/erp/generate-eway-using-irn/",
      url: `${APIROUTES.GET_ERP_GENERATE_IRN}`,
      method: "post",
      data: ewayData,
      headers: {
        Authorization: `JWT ${this.props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState(
          {
            eWayBillResponse: response.data,
            ewayPostDataLoader: false,
            dataInvoicePostLoader: false,
            ewayErrorResponseMessage: null,
            actualDistance: null,
          },
          () => {
            this.props.history.push(
              "/internalEInvoicingAndEWayBilling/downloadInvoice"
            );
          }
        );
      })
      .catch((err) => {
        console.log(err?.response);
        this.setState(
          {
            ewayPostDataLoader: false,
            ewayErrorResponseMessage: err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.details
                ? err?.response?.data?.details
                : "Error occcured while creating E-way",
            dataInvoicePostLoader: false,
          },
          () => {
            this.props.history.push(
              "/internalEInvoicingAndEWayBilling/downloadInvoice"
            );
          }
        );
      });
  };

  // handleCreateEwayUsingIRN = (IRN) => {
  //   this.setState({ ewayPostDataLoader: true });
  //   let ewayData = {
  //     Invoice_data: data,
  //     Irn_data:{
  //     Irn: IRN,
  //     Distance: 0,
  //     TransMode: 1,
  //     VehNo: this?.state?.plantItemDetails[0]?.VEHICLENO,
  //     VehType: "R",
  //     GSTIN: this?.state?.plantItemDetails[0]?.SUPGSTIN,
  //     PlantCode: this?.state?.plantItemDetails[0]?.ENTITY,
  //     TransId: this.state.plantItemDetails[0]?.TRANSPORTERID,
  //     }
  //   };
  //   axios({
  //     url: "https://ngsales.realdeals.pro/api/erp/generate-eway-using-irn/",
  //     method: "post",
  //     data: ewayData,
  //     headers: {
  //       Authorization: `JWT ${this.props.user.token}`,
  //       "Content-Type": "application/json;charset=UTF-8",
  //     },
  //   })
  //     .then((response) => {
  //       this.setState(
  //         {
  //           eWayBillResponse: response.data,
  //           ewayPostDataLoader: false,
  //           dataInvoicePostLoader: false,
  //           ewayErrorResponseMessage: null,
  //         },
  //         () => {
  //           this.props.history.push(
  //             "/internalEInvoicingAndEWayBilling/downloadInvoice"
  //           );
  //         }
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err?.response);
  //       this.setState(
  //         {
  //           ewayPostDataLoader: false,
  //           ewayErrorResponseMessage: err?.response?.data?.message
  //             ? err?.response?.data?.message
  //             : err?.response?.data?.details
  //             ? err?.response?.data?.details
  //             : "Error occcured while creating E-way",
  //           dataInvoicePostLoader: false,
  //         },
  //         () => {
  //           this.props.history.push(
  //             "/internalEInvoicingAndEWayBilling/downloadInvoice"
  //           );
  //         }
  //       );
  //     });
  // };
  // handlePOSTdataForInvoive = (t_code) => {
  handlePOSTdataForInvoive = () => {
    this.setState({ dataInvoicePostLoader: true });
    if (this.state.plantItemDetails.length !== 0) {
      if (
        this.state.vrNumber === this.state.plantItemDetails[0].DOCNO &&
        this.state.plantCode === this.state.plantItemDetails[0].ENTITY
      ) {
        let repeatableData = {
          Doc_No: this.state.plantItemDetails[0]?.DOCNO,
          Gstin: this.state.plantItemDetails[0]?.ENTITY_GSTIN,
          Doc_Dt: this.state.plantItemDetails[0]?.DOCDATE,
          token_date: this.state.plantItemDetails[0]?.DOCDATE,
          token_no: this.state.plantItemDetails[0]?.DOCNO,
          BillTo_LglNm: this.state.plantItemDetails[0]?.RECNAME,
          BillTo_Addr1:
            this.state.plantItemDetails[0]?.RECADD1 +
            this.state.plantItemDetails[0]?.RECADD2,
          BillTo_Pos: this.state.plantItemDetails[0]?.RECSTATECODE,
          BillTo_Loc: this.state.plantItemDetails[0]?.RECCITY,
          BillTo_Gstin: this.state.plantItemDetails[0]?.bill_to_gstin,
          BillFrom_Gstin: this.state.plantItemDetails[0]?.SUPGSTIN,
          BillFrom_LglNm: this.state.plantItemDetails[0]?.SUPNAME,
          BillFrom_Addr1:
            this.state.plantItemDetails[0]?.SUPADD1 +
            this.state.plantItemDetails[0]?.SUPADD2,
          BillFrom_Loc: this.state.plantItemDetails[0]?.SUPCITY,
          BillFrom_Stcd: this.state.plantItemDetails[0]?.SHIPFROMSTATECODE,
          BillTo_Stcd: this.state.plantItemDetails[0]?.RECSTATECODE,
          BillFrom_Pin: this.state.plantItemDetails[0]?.SUPPINCODE,
          BillTo_Pin: this.state.plantItemDetails[0]?.RECPINCODE,
          ShipTo_LglNm: this.state.plantItemDetails[0]?.CONSIGNEENAME,
          ShipTo_Addr1:
            this.state.plantItemDetails[0]?.CONSIGNEEADD1 +
            this.state.plantItemDetails[0]?.CONSIGNEEADD2,
          ShipTo_Pin: this.state.plantItemDetails[0]?.CONSIGNEEPINCODE,
          ShipTo_Loc: this.state.plantItemDetails[0]?.CONSIGNEEADD1, // This field is for TransType SHP
          ShipTo_Stcd: this.state.plantItemDetails[0]?.SHIPTOSTATECODE, // This field is for TransType SHP
          Tran_TaxSch: "GST",
          Tran_SupTyp: this.state.plantItemDetails[0]?.tran_sup_type,
          Tran_Typ: this.state.plantItemDetails[0]?.tran_type, // if buyer and consignee gst are same then "REG",
          Doc_Typ: this.state.plantItemDetails[0]?.DOCTYPE,
          GetQRImg: 0,
          Item_Unit: this.state.plantItemDetails[0]?.quantity_unit,
          ENTITY: this.state.plantCode,
          // t_code: t_code,
          t_code: "S",
          // Total
          Val_AssVal: this.state.total_taxable_value,
          Val_CgstVal: this.state.total_cgst_value,
          Val_SgstVal: this.state.total_sgst_value,
          Val_IgstVal: this.state.total_igst_value,
          Val_CesVal: this.state.total_cess_value,
          Val_StCesVal: "",
          Val_Discount: "",
          Val_OthChrg:
            this.getNumber(this.state.total_tcs_amount) +
            this.getNumber(this.state.total_freight_advance_amount),
          Val_RndOffAmt: "",
          Val_TotInvVal: this.state.total_invoice_value,
          Val_TotInvValFc: "",
          // Items
          Item_IsServc: this.state.plantItemDetails[0]?.item_is_service,
          Item_Discount: 0,
          Item_OthChrg: 0,
          ...(this.state.eWayBillSelected === "Yes" && {
            Ewb_TransName: this.state.plantItemDetails[0]?.TRANSPORTERNAME,
            Ewb_TransMode: 1,
            Ewb_Distance: this.state.actualDistance == "" ? 0 : this.state.actualDistance,
            Ewb_VehNo: this.state.plantItemDetails[0]?.VEHICLENO,
            Ewb_VehType: "R",
            Ewb_TransId: this.state.plantItemDetails[0]?.TRANSPORTERID,
          }),
        };

        let data = this.state.plantItemDetails.map((value) => ({
          ...repeatableData,
          Item_SlNo: value.SERIAL_NO,
          Item_PrdDesc: value.PRODUCTNAME || value.PRODUCTDESC,
          Item_HsnCd: value.HSNCODE,
          Item_UnitPrice: value.DISCOUNT_RATE,
          Item_TotAmt: this.getItem_AssAmt(value),
          Item_AssAmt: this.getItem_AssAmt(value),
          Item_GstRt:
            Number(value.IGSTRATE) === 0
              ? Number(value.SGSTRATE) + Number(value.CGSTRATE)
              : value.IGSTRATE,
          Item_TotItemVal: this.getTotalItemValue(value),
          Item_IgstAmt: value.IGSTAMOUNT1,
          Item_CgstAmt: value.CGSTAMOUNT1,
          Item_SgstAmt: value.SGSTAMOUNT1,
          Item_CesNonAdvlAmt: value.CESSNONADVOLVALUE,
          Item_Qty: value.QUANTITY,
        }));
        // this.handleCreateEwayUsingIRN("123456676");
        const INVOICE =
          this.state.eWayBillSelected === "Yes"
            // ? "https://ngsales.realdeals.pro/api/erp/generate-einvoice-and-eway/"
            // : "https://ngsales.realdeals.pro/api/erp/generate-e-invoice/";
            ? `${APIROUTES.EINVOICE_EWAY_GENERATE}`
            : `${APIROUTES.EINVOICE_GENERATE}`
        axios({
          url: INVOICE,
          method: "post",
          data,
          headers: {
            Authorization: `JWT ${this.props.user.token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
          .then((response) => {
            console.log(response);
            this.setState(
              {
                eInvoiveResponse: response.data,
                errorWhilePostForInvoice: false,
                dataInvoicePostLoader: false,
                erpFetchError: "",
                vrNumber: "",
                errorWhilePostForInvoice: "",
                plantCode: false,
                plantName: false,
                einvoiveErrorMessage: response?.data?.ErrorMessage,
              },
              () => {
                if (this.state.eWayBillSelected === "Yes") {
                  if (!response.data.EwbNo) {
                    this.handleCreateEwayUsingIRN(response.data.Irn);
                  } else {
                    this.setState(
                      {
                        eWayBillResponse: response.data,
                      },
                      () => {
                        this.props.history.push(
                          "/internalEInvoicingAndEWayBilling/downloadInvoice"
                        );
                      }
                    );
                  }
                } else {
                  this.props.history.push(
                    "/internalEInvoicingAndEWayBilling/downloadInvoice"
                  );
                }
              }
            );
          })
          .catch((err) => {
            this.setState({ dataInvoicePostLoader: false });
            console.log(err.response);
            this.setState({
              einvoiveErrorMessage: err?.response?.data?.message
                ? err?.response?.data?.message
                : err?.response?.data?.details
                  ? err?.response?.data?.details
                  : "Something went wrong",
              errorWhilePostForInvoice: true,
            });
          });
      } else {
        console.log("Something went wrong");
        this.setState({ dataInvoicePostLoader: false });
      }
    } else {
      this.setState({ dataInvoicePostLoader: false });
    }
  };
  handleGetDistance = () => {
    axios({
      url: `${APIROUTES.GET_EWAY_DATA_DISTANCE_TRACKING}?source_pincode=${this.state.plantItemDetails[0]?.SUPPINCODE}
      &destination_pincode=${this.state.plantItemDetails[0]?.CONSIGNEEPINCODE}&doc_no=${this.state.plantItemDetails[0]?.DOCNO}&plant_code=${this.state.plantItemDetails[0]?.ENTITY}`,
      method: "get",
      headers: {
        Authorization: `JWT ${this.props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({
          transporterDistance:
            response.data.IsSuccess === "true"
              ? parseFloat(response.data.Distance).toFixed(2)
              : 0.0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleEWayBillSelected = (select) => {
    this.setState({ eWayBillSelected: select }, () => { });
  };

  fetchPlantsList = () => {
    axios
      .get(
        // "https://ngsales.realdeals.pro/api/masters/plants/"
        `${APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST}`
      )
      .then(response => {
        console.log(response)
        this.setState({
          allPlants: response.data
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  handlePOSTEWayData = () => {
    const _date = this.state.plantItemDetails[0]?.DOCDATE.split("/");
    const docDate = _date[2] + _date[1] + _date[0];
    this.setState({ ewayPostDataLoader: true });
    let repeatableEWAYData = {
      PlantCode: this.state.plantItemDetails[0]?.ENTITY,
      TCode: "S",
      GSTIN: this?.state?.plantItemDetails[0]?.SUPGSTIN || "",
      Year: this?.state?.plantItemDetails[0]?.YEAR || "",
      Month: this?.state?.plantItemDetails[0]?.MONTH || "",
      SupplyType: this?.state?.plantItemDetails[0]?.SUPPLYTYPE || "",
      SubType: this.state.plantItemDetails[0]?.sub_type,
      DocType: this.state.plantItemDetails[0]?.DOCTYPE,
      DocNo: this?.state?.plantItemDetails[0]?.DOCNO || "",
      DocDate: docDate,
      SupGSTIN: this.state.plantItemDetails[0]?.SUPGSTIN || "",
      SupName: this?.state?.plantItemDetails[0]?.SUPNAME || "",
      SupAdd1: this?.state?.plantItemDetails[0]?.SUPADD1 || "",
      SupAdd2: this.state.plantItemDetails[0]?.SUPADD2,
      SupCity: this.state.plantItemDetails[0]?.SUPCITY,
      SupState: this?.state?.plantItemDetails[0]?.SUPSTATE || "",
      SupPincode: this?.state?.plantItemDetails[0]?.SUPPINCODE || "",
      RecGSTIN:
        this?.state?.plantItemDetails[0]?.tran_sup_type === "B2C"
          ? this?.state?.plantItemDetails[0]?.bill_to_gstin
          : this?.state?.plantItemDetails[0]?.RECGSTIN !==
            this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN
            ? this?.state?.plantItemDetails[0]?.RECGSTIN
            : this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN || "",
      RecName:
        this?.state?.plantItemDetails[0]?.RECGSTIN !==
          this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN
          ? this.state.plantItemDetails[0]?.RECNAME
          : this.state.plantItemDetails[0]?.CONSIGNEENAME,
      RecAdd1: this.state.plantItemDetails[0]?.CONSIGNEEADD1,
      RecAdd2: this.state.plantItemDetails[0]?.CONSIGNEEADD2,
      Reccity: this.state.plantItemDetails[0]?.CONSIGNEECITY,
      RecState:
        this?.state?.plantItemDetails[0]?.RECGSTIN !==
          this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN
          ? this?.state?.plantItemDetails[0]?.RECSTATECODE
          : this?.state?.plantItemDetails[0]?.SHIPTOSTATECODE || "",
      Recpincode: this?.state?.plantItemDetails[0]?.CONSIGNEEPINCODE || "",
      TransMode: 1,
      TranType: this?.state?.plantItemDetails[0]?.tran_type || "", // if buyer and consignee gst are same then "REG",
      TransporterId: this.state.plantItemDetails[0]?.TRANSPORTERID,
      TransporterName: this?.state?.plantItemDetails[0]?.TRANSPORTERNAME || "",
      TransDistance: this.state.actualDistance == "" ? 0 : this.state.actualDistance, // parseFloat(this.state.transporterDistance).toFixed(0),
      TransDocNo: this?.state?.plantItemDetails[0]?.TRANSDOCNO || "",
      TransDocDate: !this?.state?.plantItemDetails[0]?.TRANSDOCDATE
        ? ""
        : moment(new Date(this.state.plantItemDetails[0]?.TRANSDOCDATE)).format(
          "YYYYMMDD"
        ),
      VehicleType: "R",
      VehicleNo: this?.state?.plantItemDetails[0]?.VEHICLENO || "",
      HSNCode: this?.state?.plantItemDetails[0]?.HSNCODE || "",
      TotalValue: this.state.plantItemDetails[0]?.TOTALVALUE,
      CessRate: this.state.plantItemDetails[0]?.CESSRATE,
      CessValue: this.state.plantItemDetails[0]?.CESSAMOUNT1,
      CessNonAdvol: this.state.plantItemDetails[0]?.CESSNONADVOL,
      SubSupplyDesc: this?.state?.plantItemDetails[0]?.SUBSUPPLYDESC || "",
      ShipFromStateCode:
        this?.state?.plantItemDetails[0]?.SHIPFROMSTATECODE || "",
      ShipToStateCode: this?.state?.plantItemDetails[0]?.SHIPTOSTATECODE || "",
      TotalInvoiceValue: this.state.total_invoice_value,
      CessNonAdvolValue: this.state.total_cess_non_advol_value,
      OtherValue: this.state.total_tcs_amount,
      dispatchFromGSTIN: this.state.plantItemDetails[0]?.GSTIN,
      dispatchFromTradeName:
        this.state.plantItemDetails[0]?.DISPATCHFROMTRADENAME,
      ShipToGSTIN: this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN || "",
      ShipToTradeName: this.state.plantItemDetails[0]?.SHIPTOTRADENAME,
      IsBillFromShipFromSame: 1, // 0 for Different BillFrom and ShipFrom, 1 for Same BillFrom and ShipFrom
      IsBillToShipToSame:
        this.state.plantItemDetails[0]?.CONSIGNEEGSTIN ===
          this.state.plantItemDetails[0]?.RECGSTIN
          ? 1
          : 0,
      IsGSTINSEZ: this.state.plantItemDetails[0]?.TRANTYPE === "SZ" ? 1 : 0, //In case of Outward supply if RecGSTIN is of SEZ then 1 else 0.
    };

    let data = this.state.plantItemDetails.map((e) => ({
      ...repeatableEWAYData,
      SGSTRate: e.SGSTRATE,
      SGSTValue: e.SGSTAMOUNT1,
      CGSTRate: e.CGSTRATE,
      CGSTValue: e.CGSTAMOUNT1,
      IGSTRate: e.IGSTRATE,
      IGSTValue: e.IGSTAMOUNT1,
      TaxableValue: ((e.DISCOUNT_RATE + e.FREIGHTRATE) * e.QUANTITY).toFixed(2),
      ProductName: e.PRODUCTNAME,
      ProductDesc: e.PRODUCTDESC,
      Quantity: e.QUANTITY,
      QtyUnit: e?.quantity_unit || "",
    }));

    axios({
      url: EWAY,
      method: "post",
      data,
      headers: {
        Authorization: `JWT ${this.props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        console.log(response);

        this.setState(
          {
            eWayBillResponse: response.data,
            ewayPostDataLoader: false,
            dataInvoicePostLoader: false,
            ewayErrorResponseMessage: null,
          },
          () => {
            this.props.history.push(
              "/internalEInvoicingAndEWayBilling/downloadInvoice"
            );
          }
        );
      })
      .catch((err) => {
        console.log(err?.response);
        this.setState(
          {
            ewayPostDataLoader: false,
            ewayErrorResponseMessage: err?.response?.data?.message
              ? err?.response?.data?.message
              : err?.response?.data?.details
                ? err?.response?.data?.details
                : "Error occcured while creating E-way",
            dataInvoicePostLoader: false,
          },
          () => {
            this.props.history.push(
              "/internalEInvoicingAndEWayBilling/downloadInvoice"
            );
          }
        );
      });
  };

  handleCreateB2cInvoiceQr = () => {
    this.setState({ dataInvoicePostLoader: true });
    let supplierAccountDetails = this?.state?.plantAccountDetails;
    if (this.state.plantItemDetails[0]?.PAYMENT_MODE === "AA") {
      this.props.history.push(
        "/internalEInvoicingAndEWayBilling/downloadInvoice"
      );
    } else {
      upiqr({
        payeeVPA: supplierAccountDetails?.upiId,
        payeeName: this.state.plantItemDetails[0]?.SUPNAME,
        amount: Number(this?.state?.total_invoice_value || 0),

        transactionNote: `SUPGSTN ${this.state.plantItemDetails[0]?.SUPGSTIN
          } PAYEE_AC ${supplierAccountDetails?.accountNumber} PAYEE_IFSC ${supplierAccountDetails?.ifscCode
          } ${Number(this?.state?.total_igst_value) > 0
            ? "IGST " + this?.state?.total_igst_value
            : `CGST ${this?.state?.total_cgst_value} SGST ${this?.state?.total_sgst_value}`
          } CESS ${this?.state?.total_cess_value} TOT_AMT ${this?.state?.total_invoice_value
          } INV_DATE ${moment(
            this?.state?.plantItemDetails[0]?.DOCDATE,
            "DD/MM/YYYY"
          ).format("DD MMM YYYY")}`,
      })
        .then((upi) => {
          // console.log(upi.qr); // data:image/png;base64,eR0lGODP...
          // console.log(upi.intent); // upi://pay?pa=john@upi&pn=JOHN DOE
          this.setState(
            { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
            () => console.log(1)
          );
          this.props.history.push(
            "/internalEInvoicingAndEWayBilling/downloadInvoice"
          );
        })
        .catch((err) => {
          // console.log(err);
          this.setState(
            { b2CQrImage: null, dataInvoicePostLoader: false },
            () => console.log(1)
          );
        });
    }
  };
  render() {
    const { isMobile } = this.props;
    const { allPlants } = this.state;
    return (
      <>
        <Route
          exact
          path={INTERNALROUTES.E_INVOICING_AND_E_WAY_BILLING}
          render={(props) =>
          // isMobile ? (
          //   <EinvoicingAndEwayBillingMobile {...this.props} {...this.state} />
          // ) : 
          (
            <EinvoicingAndEwayBillingWeb
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              allPlants={allPlants}
              handlePOSTdataForInvoive={this.handlePOSTdataForInvoive}
              handleFetchERPData={this.handleFetchERPData}
              handleResetAllData={this.handleResetAllData}
              handleSetPlantNameAndPlantCode={
                this.handleSetPlantNameAndPlantCode
              }
              handleTextChange={this.handleTextChange}
              handleVRNumberTextChange={this.handleVRNumberTextChange}
              handleSetInvoiceType={this.handleSetInvoiceType}
            />
          )
          }
        />
        <Route
          exact
          path={INTERNALROUTES.VIEW_AND_DOWNLOAD_INVOICE}
          render={(props) =>
          // isMobile ? (
          //   <EinvoicingDownloadInvoice {...this.props} {...this.state} />
          // ) : 
          (
            <EinvoicingDownloadInvoiceWeb
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handlePOSTdataForInvoive={this.handlePOSTdataForInvoive}
              handleFetchERPData={this.handleFetchERPData}
              handleResetAllData={this.handleResetAllData}
              handleSetPlantNameAndPlantCode={
                this.handleSetPlantNameAndPlantCode
              }
              handleTextChange={this.handleTextChange}
              handleVRNumberTextChange={this.handleVRNumberTextChange}
              handleSetInvoiceType={this.handleSetInvoiceType}
            />
          )
          }
        />
        <Route
          exact
          path={INTERNALROUTES.CREATE_E_INVOICING}
          render={(props) =>
          // isMobile ? (
          //   <EinvoicingCreate {...this.props} {...this.state} />
          // ) : 
          (
            <EinvoicingCreateWeb
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handlePOSTdataForInvoive={this.handlePOSTdataForInvoive}
              handleFetchERPData={this.handleFetchERPData}
              handleResetAllData={this.handleResetAllData}
              handleSetPlantNameAndPlantCode={
                this.handleSetPlantNameAndPlantCode
              }
              handleTextChange={this.handleTextChange}
              handleVRNumberTextChange={this.handleVRNumberTextChange}
              handleSetInvoiceType={this.handleSetInvoiceType}
              handleEWayBillSelected={this.handleEWayBillSelected}
              handlePOSTEWayData={this.handlePOSTEWayData}
              handleCreateB2cInvoiceQr={this.handleCreateB2cInvoiceQr}
              actualDistance={this.state?.actualDistance}
              handleActualDistance={this.handleActualDistance}
            />
          )
          }
        />
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
          customAutoHideDuration={5000}
        />
      </>
    );
  }
}

export default withUser(InternalEinvoicingAndEwayBillingContainer);
