import "date-fns";
import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { checkInvalidValue, pxToEm } from '../../../../../methods';

import {
  ListSubheader,
  TextField,
  Checkbox,
  InputAdornment,
  MenuItem,
  Divider,
  Paper,
  Popper,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  whiteSmoke,
  secondaryBlueText,
  earthBlue,
  drawerBackground,
} from "../../../../../constants/internal/colors";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Downshift from "downshift";
// import Lock from "@material-ui/icons/Lock";
import SpinLoader from "../../../../common/SpinLoader";
import ActionConfirmationDialog from "../../../common/ActionConfirmationDialog";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputField from "./InputField";
import { isAccessibleWidgetToCreateContract } from "../../../../../methods";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "45%",
  },
  formControl3: {
    // margin: theme.spacing(1),
    width: "30%",
  },
  contactTextFieldContainer: {
    padding: "5px 15px 20px",
    width: "100%",
    display: "flex",

    justifyContent: "space-between",
  },
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "100%",
    paddingBottom: 10,
  },
  spinLoaderContiner: {
    width: "30%",
    paddingTop: 20,
    marginTop: "20px",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  customerInfoContainer: {
    width: "32%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px"
  },
  customerInfoContainerCenter: {
    margin: "0px 30px",
    width: "32%",
    display: "flex",
    flexDirection: "column",
  },

  checkBoxLabelText: {
    color: secondaryGrey,
    fontWeight: "bold",
    marginRight: "6px",
  },
  clientApprovalDiv: { display: "flex", alignItems: "center", width: "65%" },
  discountContainerStyle: {
    display: "flex",
    paddingTop: "20px",
    paddingBottom: "30px",
  },
  discountContainerStyleLeft: {
    width: "50%",
  },
  discountContainerStyleRight: {
    width: "50%",
  },
  discountHeaderDiv: {
    padding: "10px 0px 10px 22px",
    background: whiteSmoke,
    width: "80%",
    marginBottom: "20px",
  },
  helperText: {
    position: 'absolute',
    top: pxToEm(72)
  },
  discountTextStyle: {
    fontWeight: "bold",
    color: grey,
  },
  discountListStyleDiv: {
    display: "flex",
    width: "90%",
    marginBottom: "18px",
    alignItems: "center",
  },
  discountListStyleText: {
    width: "50%",
    color: grey,
    fontWeight: "bold",
  },
  discountListStyleValue: {
    width: "50%",
  },
  container: {
    width: "32%",
  },
  width45: {
    width: "45%",
  },
}));
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

// const subscribed = [
//   {
//     label: "GK TMT",
//     id: 5,
//   },
//   {
//     label: "WIRE ROAD",
//     id: 5,
//   },
//   {
//     label: "DD ROAD",
//     id: 5,
//   },
//   {
//     label: "RR WIRE",
//     id: 5,
//   },
// ];
// const unSubscribed = [
//   {
//     label: "SHAH TMT",
//     id: 5,
//   },
//   {
//     label: "WIRE TMT",
//     id: 5,
//   },
//   {
//     label: "RR TMT",
//     id: 5,
//   },
// ];
export default function ContractDetailsAddEditTab(props) {
  const classes = useStyles();
  const { isMobile } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  function handleConfirmationDialogOpen() {
    setConfirmationDialogOpen(!confirmationDialogOpen);
  }

  function handleConfirmationDialogClose() {
    setConfirmationDialogOpen(false);
  }

  function handleMarkClientApproved() {
    props.handleCustomSetState("isClientApproved", true);
    setConfirmationDialogOpen(false);
  }

  const getRate = (data, type) => {
    const createdAt = new Date();
    const rateDiff = type == "LoadingRate" ? data?.loading_rate : data?.insurance_rate;
    const rateDiffs = type == "LoadingRate" ? data?.loading_rate_logs : data?.insurance_rate_logs;

    const qs1 = rateDiffs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
    const qs2 = rateDiffs?.find((r) =>
      r.valid_upto != null
      && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
      && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;

    if (rateDiffs?.length == 0) {
      return rateDiff;
    } else {
      if (qs1) {
        return qs1
      } else if (qs2) {
        return qs2
      } else {
        return rateDiff;
      }
    }
  }

  return (
    <Paper elevation={5} className={classes.paperStyle}>
      <div className={classes.contactTextFieldContainer} style={{ width: isMobile && '100%' }}>
        <Downshift id="downshift-popper">
          {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
            <div className={classes.container}>
              <TextField
                name="contact"
                label="Customer*"
                variant="outlined"
                margin="normal"
                fullWidth
                onChangeCapture={props.handleChange("contact")}
                inputRef={(node) => setAnchorEl(node)}
                InputProps={{
                  ...getInputProps({
                    value: props?.contact,
                    placeholder: "",
                  }),
                }}
              />
              {anchorEl && (
                <Popper
                  open={props?.openCustomerListPopper}
                  anchorEl={anchorEl}
                >
                  <div
                    {...(isOpen
                      ? getMenuProps({}, { suppressRefError: true })
                      : {})}
                  >
                    <Paper
                      square
                      style={{
                        maxHeight: "155px",
                        overflowY: "scroll",
                        marginTop: 8,
                        width: anchorEl ? anchorEl.clientWidth : null,
                      }}
                    >
                      {props?.customer.map((data, index) => (
                        <MenuItem
                          key={index}
                          component="div"
                          onClick={() => {
                            props.handleCustomSetState(
                              "contact",
                              (data?.first_name || " ") +
                              " " +
                              (data?.last_name || "")
                            );
                            props.handleCustomSetState(
                              "selectedCustomerData",
                              data
                            );
                            props.handleCustomSetState(
                              "openCustomerListPopper",
                              false
                            );
                            props.handleCustomSetState(
                              "showCustomerDetail",
                              true
                            );
                            props.handleCustomSetState(
                              "showCustomerDetailLoader",
                              true
                            );
                            props.handleCustomSetState(
                              "showCustomerDetailLoader",
                              false
                            );
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "1em",
                            }}
                          >
                            <span>
                              {data?.first_name || " "} {data?.last_name || ""}
                            </span>
                            <span style={{ color: grey, fontSize: "0.7em" }}>
                              {!data?.gstin_number
                                ? !data?.pan_card_number
                                  ? ""
                                  : `PAN - ${data?.pan_card_number}`
                                : `GSTIN - ${data?.gstin_number}`}
                            </span>
                            <span style={{ color: grey, fontSize: "0.7em" }}>
                              PHONE - {data?.phone || ""}
                            </span>
                          </div>
                        </MenuItem>
                      ))}
                    </Paper>
                  </div>
                </Popper>
              )}
            </div>
          )}
        </Downshift>
        {props?.showCustomerDetail &&
          props?.showCustomerDetailLoader === false ? (
          <>
            <div className={classes.container}>
              <Autocomplete
                autoSelect
                options={
                  props?.buyerSelectionList.length > 0
                    ? props?.buyerSelectionList
                    : []
                }
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={props.handleAutocompleteValueChange("selectedBuyer")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Buyer"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={classes.container}>
              <Autocomplete
                autoSelect
                onChange={props.handleAutocompleteValueChange(
                  "selectedConsignee"
                )}
                options={
                  props?.consigneeSelectionList.length > 0
                    ? props?.consigneeSelectionList
                    : []
                }
                getOptionLabel={(option) =>
                  (option?.consignee_detail?.first_name || "") +
                  " " +
                  (option?.consignee_detail?.last_name || "") +
                  ", " +
                  (
                    option?.consignee_address?.address ?
                      (
                        (option?.consignee_address?.address
                          ? option?.consignee_address?.address + ", "
                          : "")
                        +
                        (option?.consignee_address?.city
                          ? option?.consignee_address?.city + ", "
                          : "")
                        +
                        (option?.consignee_address?.state
                          ? option?.consignee_address?.state + ", "
                          : "")
                        +
                        (option?.consignee_address?.country
                          ? option?.consignee_address?.country + ", "
                          : "")
                        +
                        (option?.consignee_address?.pincode
                          ? option?.consignee_address?.pincode
                          : "")
                      )
                      :
                      (
                        (option?.consignee_address?.door_number
                          ? option?.consignee_address?.door_number + ", "
                          : "")
                        +
                        (option?.consignee_address?.floor_number
                          ? 'Floor No - ' + option?.consignee_address?.floor_number + ", "
                          : "")
                        +
                        (option?.consignee_address?.building_name
                          ? option?.consignee_address?.building_name + ", "
                          : "")
                        +
                        (option?.consignee_address?.street
                          ? option?.consignee_address?.street + ", "
                          : "")
                        +
                        (option?.consignee_address?.location
                          ? option?.consignee_address?.location + ", "
                          : "")
                        +
                        (option?.consignee_address?.district_name
                          ? option?.consignee_address?.district_name + ", "
                          : "")
                        +
                        ((option?.consignee_address?.city_name && option?.consignee_address?.district_name)
                          ? ((option?.consignee_address?.city_name).trim().toUpperCase() !== (option?.consignee_address?.district_name).trim().toUpperCase())
                            ? option?.consignee_address?.city_name + ", "
                            : ""
                          : option?.consignee_address?.city_name
                            ? option?.consignee_address?.city_name + ", "
                            : "")
                        +
                        (option?.consignee_address?.state_name
                          ? option?.consignee_address?.state_name + ", "
                          : "")
                        +
                        (option?.consignee_address?.pincode
                          ? option?.consignee_address?.pincode
                          : "")
                      )
                  )
                }
                value={props?.consigneeSelectionList?.find(
                  (v) => v.id === props?.selectedConsignee?.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Consignee"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </>
        ) : null}
      </div>
      <div className={classes.contactTextFieldContainer}>
        <div className={classes.customerInfoContainer}>
          {props?.showCustomerDetail ? (
            <>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  NAME:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedCustomerData?.first_name || ""}{" "}
                  {props?.selectedCustomerData?.last_name || ""}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  {!props?.selectedCustomerData?.gstin_number
                    ? !props?.selectedCustomerData?.pan_card_number
                      ? ""
                      : "PAN: "
                    : "GSTIN: "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {!props?.selectedCustomerData?.gstin_number
                    ? !props?.selectedCustomerData?.pan_card_number
                      ? ""
                      : props?.selectedCustomerData?.pan_card_number
                    : props?.selectedCustomerData?.gstin_number}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  TYPE:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedCustomerData?.customer_type ||
                    "Direct Customer"}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  DISCOUNT:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  ₹{" "}
                  {parseFloat(props?.selectedCustomerDiscount || 0).toFixed(3)}
                </span>
              </div>
              {!checkInvalidValue(props?.selectedCustomerData?.state) &&
                <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                  <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                    STATE:{" "}
                  </span>
                  <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                    {props?.selectedCustomerData?.state || ''}
                  </span>
                </div>
              }
              {/* {props?.selectedCustomerData?.customer_type === "DISTRIBUTOR" ||
              props?.selectedCustomerData?.customer_type === "BROKER" ? (
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <TextField
                    type="number"
                    name="brokerage"
                    style={{ width: "100%" }}
                    label="Brokerage"
                    margin="normal"
                    variant="outlined"
                    placeholder="Enter Brokerage Amount"
                    value={props?.brokerage}
                    onChange={props.handleChange("brokerage")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span style={{ fontSize: "0.9em", color: grey }}>
                            ₹
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    disabled={!props?.selectedBroker?.brokerage ? false : true}
                    error={props?.brokerageError}
                    helperText={
                      props?.brokerageError
                        ? "Input valid brokerage amount"
                        : null
                    }
                  />
                </div>
              ) : null} */}
            </>
          ) : null}
        </div>
        <div className={classes.customerInfoContainerCenter}>
          {!props?.selectedBuyer ? null : (
            <>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  NAME:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedBuyer?.first_name || ""}{" "}
                  {props?.selectedBuyer?.last_name || ""}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  {!props?.selectedBuyer?.gstin_number
                    ? !props?.selectedBuyer?.pan_card_number
                      ? ""
                      : "PAN: "
                    : "GSTIN: "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {!props?.selectedBuyer?.gstin_number
                    ? !props?.selectedBuyer?.pan_card_number
                      ? ""
                      : props?.selectedBuyer?.pan_card_number
                    : props?.selectedBuyer?.gstin_number}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  PHONE:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedBuyer?.phone || ""}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  ADDRESS:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {
                    (
                      props?.selectedBuyer?.address?.address ?
                        (
                          (props?.selectedBuyer?.address?.address
                            ? props?.selectedBuyer?.address?.address + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.city
                            ? props?.selectedBuyer?.address?.city + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.state
                            ? props?.selectedBuyer?.address?.state + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.country
                            ? props?.selectedBuyer?.address?.country + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.pincode
                            ? props?.selectedBuyer?.address?.pincode
                            : "")
                        )
                        :
                        (
                          (props?.selectedBuyer?.address?.door_number
                            ? props?.selectedBuyer?.address?.door_number + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.floor_number
                            ? 'Floor No - ' + props?.selectedBuyer?.address?.floor_number + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.building_name
                            ? props?.selectedBuyer?.address?.building_name + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.street
                            ? props?.selectedBuyer?.address?.street + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.location
                            ? props?.selectedBuyer?.address?.location + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.district_name
                            ? props?.selectedBuyer?.address?.district_name + ", "
                            : "")
                          +
                          ((props?.selectedBuyer?.address?.city_name && props?.selectedBuyer?.address?.district_name)
                            ? ((props?.selectedBuyer?.address?.city_name).trim().toUpperCase() !== (props?.selectedBuyer?.address?.district_name).trim().toUpperCase())
                              ? props?.selectedBuyer?.address?.city_name + ", "
                              : ""
                            : props?.selectedBuyer?.address?.city_name
                              ? props?.selectedBuyer?.address?.city_name + ", "
                              : "")
                          +
                          (props?.selectedBuyer?.address?.state_name
                            ? props?.selectedBuyer?.address?.state_name + ", "
                            : "")
                          +
                          (props?.selectedBuyer?.address?.pincode
                            ? props?.selectedBuyer?.address?.pincode
                            : "")
                        )
                    )
                  }
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  STATE:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedBuyer?.address?.address
                    ? props?.selectedBuyer?.address?.state
                    : props?.selectedBuyer?.address?.state_name}
                </span>
              </div>
            </>
          )}
        </div>
        <div className={classes.customerInfoContainer}>
          {!props?.selectedConsignee ? null : (
            <>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  NAME:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedConsignee?.consignee_detail?.first_name || ""}{" "}
                  {props?.selectedConsignee?.consignee_detail?.last_name || ""}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  {!props?.selectedConsignee?.consignee_detail?.gstin_number
                    ? !props?.selectedConsignee?.consignee_detail
                      ?.pan_card_number
                      ? ""
                      : "PAN: "
                    : "GSTIN: "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {!props?.selectedConsignee?.consignee_detail?.gstin_number
                    ? !props?.selectedConsignee?.consignee_detail
                      ?.pan_card_number
                      ? ""
                      : props?.selectedConsignee?.consignee_detail
                        ?.pan_card_number
                    : props?.selectedConsignee?.consignee_detail?.gstin_number}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  PHONE:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedConsignee?.consignee_detail?.phone || ""}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  ADDRESS:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {
                    (
                      props?.selectedConsignee?.consignee_address?.address ?
                        (
                          (props?.selectedConsignee?.consignee_address?.address
                            ? props?.selectedConsignee?.consignee_address?.address + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.city
                            ? props?.selectedConsignee?.consignee_address?.city + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.state
                            ? props?.selectedConsignee?.consignee_address?.state + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.country
                            ? props?.selectedConsignee?.consignee_address?.country + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.pincode
                            ? props?.selectedConsignee?.consignee_address?.pincode
                            : "")
                        )
                        :
                        (
                          (props?.selectedConsignee?.consignee_address?.door_number
                            ? props?.selectedConsignee?.consignee_address?.door_number + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.floor_number
                            ? 'Floor No - ' + props?.selectedConsignee?.consignee_address?.floor_number + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.building_name
                            ? props?.selectedConsignee?.consignee_address?.building_name + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.street
                            ? props?.selectedConsignee?.consignee_address?.street + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.location
                            ? props?.selectedConsignee?.consignee_address?.location + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.district_name
                            ? props?.selectedConsignee?.consignee_address?.district_name + ", "
                            : "")
                          +
                          ((props?.selectedConsignee?.consignee_address?.city_name && props?.selectedConsignee?.consignee_address?.district_name)
                            ? ((props?.selectedConsignee?.consignee_address?.city_name).trim().toUpperCase() !== (props?.selectedConsignee?.consignee_address?.district_name).trim().toUpperCase())
                              ? props?.selectedConsignee?.consignee_address?.city_name + ", "
                              : ""
                            : props?.selectedConsignee?.consignee_address?.city_name
                              ? props?.selectedConsignee?.consignee_address?.city_name + ", "
                              : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.state_name
                            ? props?.selectedConsignee?.consignee_address?.state_name + ", "
                            : "")
                          +
                          (props?.selectedConsignee?.consignee_address?.pincode
                            ? props?.selectedConsignee?.consignee_address?.pincode
                            : "")
                        )
                    )
                  }
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: isMobile && 'column', marginBottom: "10px" }}>
                <span style={{ width: "30%", fontWeight: "bold", color: grey }}>
                  STATE:{" "}
                </span>
                <span style={{ width: isMobile ? "100%" : "70%", wordBreak: 'break-all' }}>
                  {props?.selectedConsignee?.consignee_address?.address
                    ? props?.selectedConsignee?.consignee_address?.state
                    : props?.selectedConsignee?.consignee_address?.state_name}
                </span>
              </div>
            </>
          )}
        </div>
        <Divider variant="middle" style={{ margin: "30px 18px 30px 0px" }} />
      </div>
      {props?.showCustomerDetailLoader ? (
        <div className={classes.spinLoaderContiner}>
          <SpinLoader />
          <span style={{ marginTop: "40px", fontWeight: "bold", color: grey }}>
            LOADING SUBSCRIPTION
          </span>
        </div>
      ) : props?.showCustomerDetail &&
        props?.showCustomerDetailLoader === false ? (
        <div style={{
          alignItems: isMobile && "center",
          display: isMobile && "flex",
          flexDirection: isMobile && 'column',
          width: isMobile && '100%'
        }}>
          <div
            style={{
              width: "90%",
              alignItems: "center",
              display: "flex",
              flexDirection: isMobile && 'column',
              justifyContent: "space-around",
              marginBottom: "50px",
            }}
          >
            <FormControl
              variant="outlined"
              className={classes.formControl3}
              style={{
                width: isMobile && '100%',
                margin: isMobile && '30px 0'
              }}>
              <InputLabel id="categorySelectLable">Select Category*</InputLabel>
              <Select
                id="categorySelectLable"
                value={props.itemCategory}
                disabled={props?.contractOperation === "edit"}
                onChange={props.handleChange("itemCategory")}
                MenuProps={MenuProps}
                label="Select Category*"
              >
                <ListSubheader
                  style={{ color: grey }}
                  disableSticky={true}
                  disabled={true}
                >
                  Subscribed
                </ListSubheader>
                {props?.selectedCustomerItemCategoriesSubscribed.map(
                  (option, index) => {
                    return (
                      <MenuItem
                        value={
                          option.id +
                          "/" +
                          option.rate +
                          "/" +
                          option.dailyrate_id +
                          "/" +
                          option.brokerage +
                          "/" +
                          option.publish_at +
                          "/" +
                          option.is_allow_rate_zero +
                          "/" +
                          "Subscribed" +
                          "/" +
                          getRate(option, "LoadingRate") +
                          "/" +
                          getRate(option, "InsuranceRate")
                        }
                        style={{ color: "black" }}
                        disabled={!isAccessibleWidgetToCreateContract(props.user, `${option?.item_category_code}-contracts`)}
                        key={index}
                      >
                        {option.category_name}
                      </MenuItem>
                    );
                  }
                )}

                <ListSubheader
                  style={{ color: grey }}
                  disableSticky={true}
                  disabled={true}
                >
                  Not Subscribed
                </ListSubheader>
                {props?.selectedCustomerItemCategoriesNotSubscribed.map(
                  (option, index) => {
                    return (
                      <MenuItem
                        value={
                          option.id +
                          "/" +
                          option.rate +
                          "/" +
                          option.dailyrate_id +
                          "/" +
                          option.brokerage +
                          "/" +
                          option.publish_at +
                          "/" +
                          option.is_allow_rate_zero +
                          "/" +
                          "Not Subscribed" +
                          "/" +
                          getRate(option, "LoadingRate") +
                          "/" +
                          getRate(option, "InsuranceRate")
                        }
                        style={{ color: "black" }}
                        disabled={!isAccessibleWidgetToCreateContract(props.user, `${option?.item_category_code}-contracts`)}
                        key={index}
                      >
                        {option.category_name}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
            <Autocomplete
              autoSelect
              style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0, marginBottom: 9 }}
              name="Tag"
              options={props?.tagsMaster}
              getOptionLabel={(opt) => opt.tag_name}
              onInputChange={(e, value) => props.handleAutoCompleteSearch(e, value, props?.tagsMaster, 'tag', 'tag_name')}
              // value={props?.tag}
              ListboxProps={{ style: { maxHeight: '100px', } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Tag'}
                  margin="normal"
                  variant="outlined"
                  style={{ width: isMobile && '100%' }}
                />
              )}
            />
            <TextField
              type="number"
              name="rates"
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              disabled={props?.disableUserToInputRate}
              value={props?.rates == 0 ? props?.rates : Number(props?.rates).toFixed(0)}
              id="outlined-name"
              label="Rate*"
              margin="normal"
              variant="outlined"
              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
              onChange={props.handleChange("rates")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: "0.9em", color: grey }}>₹</span>
                  </InputAdornment>
                ),
              }}
              error={props?.ratesError}
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={
                props?.ratesError
                  ? props?.rateErrorMessage
                  : (props?.publishAt !== null && props?.publishAt !== "undefined")
                    ? `Published At : ${moment(props?.publishAt).format("DD/MM/YYYY")}`
                    : null
              }
            />
          </div>
          <div
            style={{
              width: "90%",
              alignItems: "center",
              display: "flex",
              flexDirection: isMobile && 'column',
              justifyContent: "space-around",
              marginBottom: "50px",
            }}
          >
            <TextField
              type="number"
              name="quantity"
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              id="outlined-name"
              label="Quantity*"
              margin="normal"
              variant="outlined"
              value={props?.quantity}
              onChange={props.handleChange("quantity")}
              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
              error={props?.quantityError}
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={props?.quantityError ? "Input valid quantity" : null}
              InputProps={{
                pattern: "[0-9]*",
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{ fontSize: "0.9em", color: grey }}>MT</span>
                  </InputAdornment>
                ),
              }}

            />
            <FormControl
              variant="outlined"
              className={classes.formControl3}
              style={{
                width: isMobile && '100%',
                margin: isMobile && '30px 0',
              }}>
              <InputLabel id="paymentTermSelectLable">
                Select Payment Terms*
              </InputLabel>
              <Select
                id="paymentTermSelectLable"
                value={props.paymentTerm}
                disabled={props?.contractOperation === "edit"}
                onChange={props.handleChange("paymentTerm")}
                MenuProps={MenuProps}
                label="Select Payment Terms*"
              >
                <ListSubheader style={{ color: grey }} disableSticky={true}>
                  Allotted
                </ListSubheader>
                {props?.selectedCustomerPaymentTermsAllotted.map(
                  (option, index) => (
                    <MenuItem
                      value={option.id}
                      style={{ color: "black" }}
                      key={index}
                    >
                      {option.payment_method_name} ({option.days} days)
                    </MenuItem>
                  )
                )}

                <ListSubheader style={{ color: grey }} disableSticky={true}>
                  Not Allotted
                </ListSubheader>

                {props?.selectedCustomerPaymentTermsNotAllotted.map(
                  (option, index) => (
                    <MenuItem
                      value={option.id}
                      style={{ color: "black" }}
                      key={index}
                    >
                      {option.payment_method_name} ({option.days} days)
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <TextField
              type="number"
              name="orderValidity"
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              id="outlined-name"
              label="Contract validity (Days)"
              margin="normal"
              variant="outlined"
              value={props?.orderValidity}
              placeholder="Enter Number of Days"
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={`Contract valid upto : ${moment(
                props?.orderValidityDate
              ).format("DD/MM/YYYY")}`}
              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
              onChange={props.handleChange("orderValidity")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{ fontSize: "0.9em", color: grey }}>Days</span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{
              width: "90%",
              alignItems: "center",
              display: "flex",
              flexDirection: isMobile && 'column',
              justifyContent: "space-around",
              marginBottom: "50px",
            }}
          >
            <TextField
              label="Internal Remark"
              onChange={props.handleChange("adminRemark")}
              multiline
              rows="4"
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <>
              <TextField
                label="Customer Remark"
                multiline
                rows="4"
                style={{
                  width: isMobile ? '100%' : "30%",
                  marginTop: isMobile ? '30px' : "0px",
                  marginBottom: isMobile ? '30px' : "0px",
                }}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
              />
            </>
            <div
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.allowBreakingFifoCheckbox}
                    onChange={props.handleChange("allowBreakingFifoCheckbox")}
                    name="allowBreakingFifoCheckbox"
                    style={{ color: "blue" }}
                  />
                }
                label="ALLOW BREAKING FIFO"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.customerApprovalCheckbox}
                    onChange={props.handleChange("customerApprovalCheckbox")}
                    name="customerApprovalCheckbox"
                    style={{ color: "blue" }}
                  />
                }
                label="CUSTOMER APPROVAL"
              />

            </div>
          </div>

          <div
            style={{
              width: "90%",
              alignItems: "center",
              display: "flex",
              flexDirection: isMobile && 'column',
              justifyContent: "space-around",
            }}
          >
            {props?.selectedCustomerData?.customer_type === "DISTRIBUTOR" ||
              props?.selectedCustomerData?.customer_type === "BROKER" ? (
              <div
                style={{
                  width: isMobile ? '100%' : "30%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: 9
                }}
              >
                <InputField
                  {...props}
                  isMobile={isMobile}
                  handleTextChangeBrokerage={props.handleTextChangeBrokerage}
                />
              </div>
            ) : null}
            <TextField
              type="number"
              name="specialDiscount"
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              label="Special Discount"
              margin="normal"
              variant="outlined"
              placeholder="Enter Additional Discount Amount"
              value={props?.specialDiscount}
              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
              onChange={props.handleChange("specialDiscount")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: "0.9em", color: grey }}>₹</span>
                  </InputAdornment>
                ),
              }}
              error={props?.specialDiscountError}
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={
                props?.specialDiscountError
                  ? "Input valid discount amount"
                  : null
              }
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault();
                }
              }}
            />
            {!props?.disableUserToInputRate
              ? <TextField
                type="number"
                name="specialPremium"
                style={{
                  width: isMobile ? '100%' : "30%",
                  marginTop: isMobile ? '30px' : "0px",
                  marginBottom: isMobile ? '30px' : "0px",
                }}
                label="Special Premium"
                margin="normal"
                variant="outlined"
                placeholder="Enter Additional Premium Amount"
                value={props?.specialPremium}
                disabled={!props.itemCategory}
                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                onWheel={(e) => e.target.blur()}
                onChange={props.handleChange("specialPremium")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span style={{ fontSize: "0.9em", color: grey }}>₹</span>
                    </InputAdornment>
                  ),
                }}
                error={props?.specialPremiumError}
                FormHelperTextProps={{ className: classes.helperText }}
                helperText={
                  props?.specialPremiumError
                    ? "Input valid premium amount"
                    : null
                }
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
              />
              :
              <Autocomplete
                autoSelect
                style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0, marginBottom: 9 }}
                name="freight_basis"
                options={props.freightBasis}
                getOptionLabel={(opt) => opt.name}
                onInputChange={(e, value) => props.handleAutoCompleteSearch(e, value, props.freightBasis, 'freight_basis', 'name')}
                // value={props?.freight_basis}
                ListboxProps={{ style: { maxHeight: '100px', } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Freight Basis*'}
                    margin="normal"
                    variant="outlined"
                    style={{ width: isMobile && '100%' }}
                  />
                )}
              />
            }
            <div style={{
              display: (isMobile || props?.selectedCustomerData?.customer_type === "DISTRIBUTOR" ||
                props?.selectedCustomerData?.customer_type === "BROKER") && 'none', width: "30%",
            }}></div>
          </div>
          <div
            style={{
              width: "90%",
              alignItems: "center",
              display: "flex",
              flexDirection: isMobile && 'column',
              justifyContent: "space-around",
              marginBottom: "30px",
              marginTop: "35px"
            }}
          >
            <TextField
              type="number"
              name="freightChargeRate"
              //className={classes.width45}
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              label="Freight Charge Rate"
              margin="normal"
              variant="outlined"
              placeholder="Enter Freight Charge Rate"
              value={props?.freightChargeRate}
              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
              onChange={props.handleChange("freightChargeRate")}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: "0.9em", color: grey }}>
                      Per MT
                    </span>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault();
                }
              }}
            />

            <TextField
              type="number"
              name="loadingPremium"
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              disabled={props?.disableLoadingPremium}
              value={props?.loadingPremium == 0 ? props?.loadingPremium : Number(props?.loadingPremium)}
              id="outlined-name"
              label="Loading Premium"
              margin="normal"
              variant="outlined"
              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
              onChange={props.handleChange("loadingPremium")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: "0.9em", color: grey }}>₹</span>
                  </InputAdornment>
                ),
              }}
              error={props?.loadingPremiumError}
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={
                props?.loadingPremiumError
                  ? props?.loadingPremiumErrorMsg
                  : null
              }

            />

            <TextField
              type="number"
              name="insurancePremium"
              style={{
                width: isMobile ? '100%' : "30%",
                marginTop: isMobile ? '30px' : "0px",
                marginBottom: isMobile ? '30px' : "0px",
              }}
              disabled={props?.disableInsurancePremium}
              value={props?.insurancePremium == 0 ? props?.insurancePremium : Number(props?.insurancePremium)}
              id="outlined-name"
              label="Insurance Premium"
              margin="normal"
              variant="outlined"
              onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
              onChange={props.handleChange("insurancePremium")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: "0.9em", color: grey }}>₹</span>
                  </InputAdornment>
                ),
              }}
              error={props?.insurancePremiumError}
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={
                props?.insurancePremiumError
                  ? props?.insurancePremiumErrorMsg
                  : null
              }

            />
          </div>
          <div className={classes.width45}></div>
          <div
            style={{
              width: "90%",
              alignItems: "center",
              display: "flex",
              flexDirection: isMobile && 'column',
              justifyContent: "space-around",
              marginBottom: "30px",
            }}
          >
            <Autocomplete
              autoSelect
              style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0, marginBottom: 9 }}
              name="salesPerson"
              options={props?.users}
              getOptionLabel={(opt) => opt.fullName}
              onInputChange={(e, value) => props.handleSearchSalesPerson(e, value, props.users)}
              // value={props?.salesPerson}
              ListboxProps={{ style: { maxHeight: '100px', } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Sales Person*'}
                  margin="normal"
                  variant="outlined"
                  style={{ width: isMobile && '100%' }}
                />
              )}
            />
            <TextField
              type="text"
              name="partyRefNo"
              // className={classes.width45}
              style={{ width: isMobile ? '100%' : '30%', marginBottom: "15px", marginTop: isMobile && '30px' }}
              label="Party Ref Number"
              margin="normal"
              variant="outlined"
              placeholder="Enter Party Ref Number"
              value={props?.partyRefNo}
              onChange={props.handleChange("partyRefNo")}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                  event.preventDefault();
                }
              }}
            />

            <Fragment>
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    // className={classes.width45}
                    style={{ width: isMobile ? '100%' : '30%', marginBottom: isMobile ? "15px" : 0, marginTop: isMobile && '30px' }}
                    inputVariant="outlined"
                    label="Party Ref Date* "
                    format="dd/MM/yyyy"
                    value={props.partyRefDate}
                    onChange={props.handleDateChange}
                    placeholder="e.g.: DD/MM/YYYY"
                    InputAdornmentProps={{ position: "start" }}
                    InputProps={{ className: classes.datePicker, readOnly: true }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Fragment>
          </div>
          {!props?.disableUserToInputRate &&
            <div
              style={{
                width: "90%",
                alignItems: "center",
                display: "flex",
                flexDirection: isMobile && 'column',
                justifyContent: "space-around",
                marginBottom: "30px",
              }}
            >
              <Autocomplete
                autoSelect
                style={{ width: isMobile ? '100%' : '30%', marginTop: isMobile ? '30px' : 0, marginBottom: 9 }}
                name="freight_basis"
                options={props.freightBasis}
                getOptionLabel={(opt) => opt.name}
                onInputChange={(e, value) => props.handleAutoCompleteSearch(e, value, props.freightBasis, 'freight_basis', 'name')}
                // value={props?.freight_basis}
                ListboxProps={{ style: { maxHeight: '100px', } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Freight Basis*'}
                    margin="normal"
                    variant="outlined"
                    style={{ width: isMobile && '100%' }}
                  />
                )}
              />
              <div style={{ width: '63%' }}></div>
            </div>
          }
        </div>
      ) : null}
      <ActionConfirmationDialog
        open={confirmationDialogOpen}
        handleClose={handleConfirmationDialogClose}
        dialogTitle={"Confirm Client Approval"}
        dialogContent={
          "This is an irreversible action, once client approval is marked it can't be changed."
        }
        cancelButtonTitle={"Let me check"}
        confirmButtonTitle={"Mark Approved"}
        handleConfirmationDialogActionConfirmed={handleMarkClientApproved}
      />
    </Paper>
  );
}
