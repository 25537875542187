import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// internal

// old routes
// import INTERNALROUTES from "../src/constants/internal/InternalRoutes";
// import InternalDashboardContainer from "./containers/internal/InternalDashboard";
// import InternalUsersAndPermissionsContainer from "./containers/internal/InternalUsersAndPermissions";
// import InternalDailyRatesContainer from "../src/containers/internal/InternalDailyRates";
// // import InternalContractsContainer from "../src/containers/internal/InternalContracts";
// import InternalContractsContainer from "./containers/internal/contracts";
// import InternalLoadingAdvicesContainer from "../src/containers/internal/InternalLoadingAdvice";
// import InternalInvoicesAndPaymentsContainer from "../src/containers/internal/InternalInvoiceAndPayments";
// import InternalCustomersContainer from "../src/containers/internal/InternalCustomers";
// import InternalReportsContainer from "../src/containers/internal/InternalReports";
// import InternalMastersManagementContainer from "../src/containers/internal/InternalMasterManagment";
// import Weighbridges from "./containers/internal/weighbridges";

// new routes after directory update
import INTERNALROUTES from "./constants/internal/InternalRoutes";
import InternalDashboardContainer from "./containers/internal/dashboard";
import InternalUsersAndPermissionsContainer from "./containers/internal/userAndPermissions";
import InternalDailyRatesContainer from "./containers/internal/dailyRates";
import InternalContractsContainer from "./containers/internal/contracts";
import InternalLoadingAdvicesContainer from "./containers/internal/loadingAdvice";
import InternalInvoicesAndPaymentsContainer from "./containers/internal/invoiceAndPayments";
import InternalCustomersContainer from "./containers/internal/customers";
import InternalMastersManagementContainer from "./containers/internal/allMasters";
import Weighbridges from "./containers/internal/weighbridges";
import DispatchPlanning from "./containers/internal/dispatchPlanning";
import InternalEinvoicingAndEwayBillingContainer from "./containers/internal/eInvoicingAndEwayBilling";
import DispatchOperation from "./containers/internal/dispatchOperation";
import Reports from "./containers/internal/reports";

// client
import Registration from "./containers/b2b/Registration";
import Home from "./containers/b2b/home";
import B2bNotification from "./containers/b2b/B2bNotification";
import B2bProfile from "./containers/b2b/B2bProfile";
import Orders from "./containers/b2b/orders";
import Dispatch from "./containers/b2b/dispatch";
import DispatchTracking from "./containers/internal/dispatchTracking";
import CustomerRequests from "./containers/internal/customerRequests";


// common
import withUser from "./hoc/withUser";
import Login from "./containers/common/Login";
import PrivacyPolicy from "../src/components/common/PrivacyPolicy";
import Enquiry from "../src/components/common/Enquiry";
import OnBoarding from "./containers/common/OnBoarding";
import withAuthentication from "./hoc/withAuthentication";
import withMUI from "./hoc/withMUI";
import "./styles/App.css";
import axios from "axios";
import UserProfileContainer from "./containers/internal/userProfile";
import AdminProfileChangePassword from "./components/internal/AdminProfile/web/AdminProfileChangePassword";
import salesTargetOperation from "./containers/internal/salesTarget";
import dynamicReports from "./containers/internal/dynamicReports";

const ProtectedRouteInternal = ({
  component: Component,
  user,
  isMobile,
  ...props
}) => (
  <Route
    {...props}
    render={(props) =>
      user && user.is_admin ? (
        <Component user={user} isMobile={isMobile} {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

const ProtectedRoute = ({ component: Component, user, isMobile, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      user && !user.is_admin ? (
        <Component user={user} isMobile={isMobile} {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function App(props) {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isMobile, setMobile] = React.useState(width <= 768);
  const handler = React.useCallback(() => {
    setWidth(window.innerWidth);
    setMobile(window.innerWidth <= 768);
  }, [setWidth]);
  useEventListener("resize", handler);

  // Global header pass for all axios call
  props.user &&
    (axios.defaults.headers.common[
      "Authorization"
    ] = `JWT ${props.user.token}`);
  axios.defaults.headers.common["Content-Type"] =
    "application/json;charset=UTF-8";

  return (
    <Router>
      <Switch>
        {/* Admin Routes */}
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.DASHBOARD}
          isMobile={isMobile}
          component={InternalDashboardContainer}
        />
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.USER_PROFILE}
          isMobile={false}
          component={UserProfileContainer}
        />
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.USER_PROFILE_CHANGE_PASSWORD}
          isMobile={false}
          component={AdminProfileChangePassword}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.USERANDPERMISSION}
          isMobile={isMobile}
          component={InternalUsersAndPermissionsContainer}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.DISPATCH_TRACKING}
          isMobile={isMobile}
          component={DispatchTracking}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.CUSTOMER_REQUESTS}
          isMobile={isMobile}
          component={CustomerRequests}
        />
        {/* <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.SALESTARGET}
          isMobile={isMobile}
          component={salesTargetOperation}
        /> */}
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.MASTERSMANAGMENT}
          isMobile={isMobile}
          component={InternalMastersManagementContainer}
        />
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.DAILYRATES}
          isMobile={isMobile}
          component={InternalDailyRatesContainer}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.ORDERS}
          isMobile={isMobile}
          component={InternalContractsContainer}
        />
        <ProtectedRouteInternal
          {...props}

          path={INTERNALROUTES.LOADINGADVICE}
          isMobile={isMobile}
          component={InternalLoadingAdvicesContainer}
        />
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.LOADINGADVICEPLANTID}
          isMobile={isMobile}
          component={InternalLoadingAdvicesContainer}
        />
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.INVOICEANDPAYMENTS}
          isMobile={isMobile}
          component={InternalInvoicesAndPaymentsContainer}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.CUSTOMERS}
          isMobile={isMobile}
          component={InternalCustomersContainer}
        />
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.REPORTS}
          isMobile={isMobile}
          component={Reports}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.DYNAMIC_REPORTS}
          isMobile={isMobile}
          component={dynamicReports}
        />
        <ProtectedRouteInternal
          {...props}
          exact
          path={INTERNALROUTES.WEIGHBRIDGES}
          isMobile={isMobile}
          component={Weighbridges}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.DISPATCH_PLANNING}
          isMobile={isMobile}
          component={DispatchPlanning}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.E_INVOICING_AND_E_WAY_BILLING}
          isMobile={isMobile}
          component={InternalEinvoicingAndEwayBillingContainer}
        />
        <ProtectedRouteInternal
          {...props}
          path={INTERNALROUTES.DISPATCH_OPERATION}
          isMobile={isMobile}
          component={DispatchOperation}
        />

        {/* Client Routes */}
        <Route
          exact
          path="/register"
          render={(props) => <Registration {...props} isMobile={isMobile} />}
        />
        <ProtectedRoute
          {...props}
          path="/home"
          isMobile={isMobile}
          component={Home}
        />
        <Route
          exact
          {...props}
          path="/b2bNotification"
          render={(props) => <B2bNotification {...props} isMobile={isMobile} />}
        />

        <ProtectedRoute
          {...props}
          path="/orders"
          isMobile={isMobile}
          component={Orders}
        />
        <ProtectedRoute
          {...props}
          path="/dispatch"
          isMobile={isMobile}
          component={Dispatch}
        />

        <ProtectedRoute
          {...props}
          path="/b2bProfile"
          component={B2bProfile}
          isMobile={isMobile}
        />

        {/* Common Routes */}
        <Route
          path="/onboarding"
          render={(props) => <OnBoarding {...props} />}
        />
        <Route
          path="/login"
          render={(props) => <Login {...props} isMobile={isMobile} />}
        />
        <Route
          path="/privacy-policy"
          render={(props) => <PrivacyPolicy {...props} isMobile={isMobile} />}
        />
        <Route
          path="/enquiry"
          render={(props) => <Enquiry {...props} isMobile={isMobile} />}
        />
        {/* Other */}
        <Redirect
          from="/"
          to={props?.user?.is_admin ? INTERNALROUTES.DASHBOARD : "/home"}
        />
      </Switch>
    </Router>
  );
}

function useEventListener(eventName, handler, element = window) {
  const savedHandler = React.useRef();
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export default withAuthentication(withMUI(withUser(App)));
