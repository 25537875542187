// import MasterManagmentWeb from './web';

// import MasterManagmentMobile from './mobile';

// import DisplayMastersDataDetailsList from './web/DisplayMastersDataDetailsList';

// import ManageTagsMasterWeb from './web/tagsMaster';

// import ManagePlantItemMasterWeb from './web/plantItemMaster';

// import ManagePaymentTermsWeb from './web/paymentTerms';

// import ManageItemCategoryWeb from './web/ItemCategoryNewUi';

// import DailyRateRegionsWeb from './web/dailyRateRegions';

// export default MasterManagmentWeb;

// export {
//   MasterManagmentMobile,
//   DisplayMastersDataDetailsList,
//   ManageTagsMasterWeb,
//   ManagePlantItemMasterWeb,
//   ManagePaymentTermsWeb,
//   ManageItemCategoryWeb,
//   DailyRateRegionsWeb,
// };

import DailyRateRegionsWeb from "./dailyRateRegions/web";
import DailyRateRegionsMobile from "./dailyRateRegions/mobile";

import ItemCategoryWeb from "./ItemCategoryNewUi/web";
import ItemCategoryMobile from "./ItemCategoryNewUi/mobile";

import PaymentTermsWeb from "./paymentTerms/web";
import PaymentTermsMobile from "./paymentTerms/mobile";

import PlantMasterWeb from "./plantItemMaster/web";
import PlantMasterMobile from "./plantItemMaster/mobile";

import TagsWeb from "./tagsMaster/web";
import TagsMobile from "./tagsMaster/mobile";

import AllMastersList from "./AllMastersList";
import DisplayMastersDataDetailsList from "./DisplayMastersDataDetailsList";

export default AllMastersList;

export {
  DisplayMastersDataDetailsList,
  DailyRateRegionsMobile,
  DailyRateRegionsWeb,
  ItemCategoryMobile,
  ItemCategoryWeb,
  PaymentTermsMobile,
  PaymentTermsWeb,
  PlantMasterMobile,
  PlantMasterWeb,
  TagsMobile,
  TagsWeb,
};
