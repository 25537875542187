import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
  secondaryColor,
} from "../../../styles/colors";
import "../../../styles/b2b/web/RegistrationWeb.css";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import SpinLoader from "../../common/SpinLoader";

const styles = {
  RegisterSuccessfullWebMainBody: {
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 50px 20px 50px",
    maxHeight: "85%",
    overflowY: "scroll",
  },
  RegisterSuccessfullWebInternalBody: {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

class RegisterSuccessfullWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      legal_name_of_business,
      date_of_registration,
      Gst_number,
      email,
      contactNumber,
      registrationLoader,
      panNumber,
    } = this.props;
    return (
      <div
        className={classes.RegisterSuccessfullWebMainBody}
        id="RegisterSuccessfullWebMainBody"
      >
        {registrationLoader ? (
          <div>
            <SpinLoader />
          </div>
        ) : (
          <div className={classes.RegisterSuccessfullWebInternalBody}>
            <div
              style={{
                width: "100%",
                color: dailyRateScreenTextColor,
                fontSize: "0.9em",
              }}
            >
              <span>Registration Successful</span>
            </div>
            <br />
            <div className="b2b-register-successful-web-show-company-details-div">
              <span
                style={{ color: dailyRateScreenTextColor, fontSize: "0.9em" }}
              >
                {legal_name_of_business}
              </span>
              <span
                style={{ color: bottomNavigationFontColor, fontSize: "0.9em" }}
              >
                {panNumber === "" ? Gst_number : panNumber}
              </span>
              <span style={{ color: secondaryColor, fontSize: "0.7em" }}>
                {email}
              </span>
              <span style={{ color: secondaryColor, fontSize: "0.7em" }}>
                {contactNumber}
              </span>
            </div>

            <div className="b2b-register-successful-web-registration-info-container">
              <div className="b2b-register-successful-web-registration-info-container-left-div">
                {/* <span> Registration date</span> */}
              </div>
              <div className="b2b-register-successful-web-registration-info-container-right-div">
                <span> {date_of_registration}</span>
              </div>
            </div>
            {/* <div className="b2b-register-successful-web-message-show-container">
              <span
                style={{ color: dailyRateScreenTextColor, fontSize: "0.9em" }}
              >
                an email has been sent to your mail
              </span>
              <span style={{ color: secondaryColor, fontSize: "0.8em" }}>
                {email}
              </span>
              <span
                style={{ color: dailyRateScreenTextColor, fontSize: "0.7em" }}
              >
                Kindly verify it
              </span>
            </div> */}
            <div className="b2b-register-successful-web-show-text-bottom">
              <span
                style={{
                  color: secondaryColor,
                  fontSize: "0.8em",
                  textAlign: "center",
                }}
              >
                Please wait while our team verify your details.
              </span>
              <span
                style={{
                  color: secondaryColor,
                  fontSize: "0.8em",
                  textAlign: "center",
                }}
              >
                This should not take more than 72 hours.
              </span>
            </div>
            <div className="b2b-register-successful-web-register-successfull-logout-div">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/");
                }}
                style={{ color: bottomNavigationFontColor, fontSize: "1em" }}
              >
                Login
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(RegisterSuccessfullWeb));
