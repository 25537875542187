import { forwardRef, useState, } from "react";
import { Button, Dialog, DialogActions, DialogContent, Slide, Tab, Tabs, TextField } from "@material-ui/core";
import { drawerTextColor, grey, whiteSmoke } from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { isAccessibleWidget, pxToEm } from "../../../../methods";
import { primaryColor } from "../../../../styles/colors";
import Bread from "../../common/Breadcrubs";
import SingleDynamicReports from "./SingleDynamicReports";
import { Autocomplete } from "@material-ui/lab";

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: 'inherit',
    margin: '30px 0'
  },
  dataInput: {
    display: "flex"
  },
  button: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginLeft: -20
  },
  tab: {
    '& .MuiBox-root-55': {
      padding: '0px',
    },
  },
  helperText: {
    '& .MuiFormHelperText-root': {
      height: '0',
      marginTop: '0'
    }
  },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "20px"
  },
  tableContainer: {
    display: "flex",
    justifyContent: 'space-between',
    width: "100%",
    paddingBottom: 30
  },
  dropdownContainer: {
    display: 'flex',
    width: 180,
    margin: '0 20px',
  },
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}



const TabHeader = [
  { label: 'Daily Rates', key: 'dailyrates' },
  { label: 'Customers', key: 'customers' },
  { label: 'Contracts', key: 'contracts' },
  { label: 'Sub Contracts', key: 'contracts' },
  { label: 'Loading Advices', key: 'loadingadvices' },
  { label: 'Invoices', key: 'invoices' },
  { label: 'E-Invoicing', key: 'einvoices' },
  { label: 'Dispatch Trackings', key: 'dispatchtrackings' },
  { label: 'User & Permissions', key: 'userpermissions' },
  { label: 'Masters', key: 'masters' },
]
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MasterTabWarningDialog = ({
  open, handleClose, data, handleProceed
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogContent>
        Changing Master will remove all selected Headers
      </DialogContent>
      <DialogActions style={{ margin: "15px 0 10px" }}>
        <Button
          onClick={() => {
            handleProceed(data)
            handleClose()
          }}
          variant="contained"
          style={{ backgroundColor: primaryColor, color: drawerTextColor }}
        >
          Proceed
        </Button>
        <Button
          onClick={handleClose}
          variant="text">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  )
}


function DynamicReportsWeb({
  isMobile,
  handleTabValue,
  filterType,
  showAllArray,
  tabActivetemplate,
  savedTemplate,
  handleModuleHeaderAdd,
  handleModuleHeaderRemove,
  handleModuleManyHeaderAddRemove,
  handleFilterTypes,
  handleCalculationTypes,
  templateSearchbar,
  handleTemplateSearchBar,
  handleTableDataOrder,
  handleRequestReportClick,
  handleCancelResetRequestReport,
  handleSaveTemplateClick,
  handleApplyTemplate,
  handleSavedTemplatePinUnpin,
  handleDeleteSavedTemplate,
  handleTableDataIsChangedValidation,
  tabAppliedTemplate,
  handleTableDataChangeValidation,
  FetchMasterFilterValueSearchApiData,
  orderByData,
  handleTableOrderBy,
  handleTabValueTitle,
  buttonClickLoader,
  filterValueApiLoader,
  allHeaderLoader,
  tabAllHeadersData,
  tableDataLoader,
  tabSelectedHeadersTableData,
  tabSelectedHeadersIdData,
  MASTERDROPDOWN,
  masterDropdownValue,
  handleMasterDropdownChange,

  handleOpenSnackbar,
  handleCloseSnackbar,
  ...props
}) {


  const [tabValue, setTabValue] = useState(0);
  const [openMasterTabWarningDialog, setOpenMasterTabWarningDialog] = useState({
    open: false,
    data: ''
  })

  const handleClose = () => {
    setOpenMasterTabWarningDialog({ open: false, data: '' })
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    handleTabValue(newValue)
  };

  return (
    <Layout {...props}>
      <div style={{ height: "100%", display: "flex", width: "100%" }}>
        <div style={{ width: "100%", padding: "0 30px 30px 0" }}>
          <div style={{ marginBottom: 8 }}>
            <Bread
              data={[
                {
                  title: "Dynamic Reports",
                  onClick: () =>
                    props.history.push("/dynamicReports"),
                },
                {
                  title: isAccessibleWidget(props.user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`) && `${TabHeader[tabValue].label}`,
                },
              ]} />
          </div>
          <div style={styles.root}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%", }}>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      borderBottom: `2px solid ${primaryColor}`,
                      colur: grey,
                    },
                  }}
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                >
                  {TabHeader.map((d, index) => {
                    return (
                      // 1 > 0 &&
                      isAccessibleWidget(props.user, `${d.key.toLowerCase()}_dynamic_report`) &&
                      <Tab
                        key={d.key}
                        label={d.label}
                        {...a11yProps(index)}
                        style={{
                          padding: 6,
                          minWidth: 140,
                          backgroundColor: whiteSmoke,
                          color: grey,
                        }}
                      />
                    )
                  })
                  }
                </Tabs>
              </div>
            </div>
          </div>
          {tabValue === 9 && isAccessibleWidget(props.user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`) &&
            <>
              <div style={{ width: 280 }}>
                <Autocomplete
                  options={MASTERDROPDOWN}
                  getOptionLabel={(option) => option.label}
                  size="small"
                  value={masterDropdownValue || null}
                  onChange={(e, newValue) => {
                    if (tabSelectedHeadersIdData[handleTabValueTitle()].selectedHeaderId.length > 0) {
                      setOpenMasterTabWarningDialog({ open: true, data: newValue })
                    }
                    else
                      handleMasterDropdownChange(newValue)
                  }}
                  required={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ margin: "12px 0" }}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <MasterTabWarningDialog
                open={openMasterTabWarningDialog.open}
                handleClose={handleClose}
                data={openMasterTabWarningDialog.data}
                handleProceed={handleMasterDropdownChange}
              />
            </>}
          {isAccessibleWidget(props.user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`) &&
            <SingleDynamicReports
              user={props.user}
              handleTabValueTitle={handleTabValueTitle}
              isMobile={isMobile}
              filterType={filterType}
              showAllArray={showAllArray}
              savedTemplate={savedTemplate}
              handleFilterTypes={handleFilterTypes}
              handleCalculationTypes={handleCalculationTypes}
              handleModuleHeaderAdd={handleModuleHeaderAdd}
              handleModuleHeaderRemove={handleModuleHeaderRemove}
              handleModuleManyHeaderAddRemove={handleModuleManyHeaderAddRemove}
              templateSearchbar={templateSearchbar}
              handleTemplateSearchBar={handleTemplateSearchBar}
              handleTableDataOrder={handleTableDataOrder}
              handleRequestReportClick={handleRequestReportClick}
              handleCancelResetRequestReport={handleCancelResetRequestReport}
              handleSaveTemplateClick={handleSaveTemplateClick}
              handleApplyTemplate={handleApplyTemplate}
              handleSavedTemplatePinUnpin={handleSavedTemplatePinUnpin}
              handleDeleteSavedTemplate={handleDeleteSavedTemplate}
              handleTableDataIsChangedValidation={handleTableDataIsChangedValidation}
              handleTableDataChangeValidation={handleTableDataChangeValidation}
              FetchMasterFilterValueSearchApiData={FetchMasterFilterValueSearchApiData}
              buttonClickLoader={buttonClickLoader}
              filterValueApiLoader={filterValueApiLoader}
              allHeaderLoader={allHeaderLoader}
              tabActivetemplate={tabActivetemplate[handleTabValueTitle()]?.activeTemplateData}
              appliedTemplateData={tabAppliedTemplate[handleTabValueTitle()]?.appiledtemplateData}
              moduleFieldHeader={tabAllHeadersData[handleTabValueTitle()].allHeaders}
              selectedHeader={tabSelectedHeadersIdData[handleTabValueTitle()].selectedHeaderId}
              tableDataLoader={tableDataLoader}
              selectedHeaderTableData={tabSelectedHeadersTableData[handleTabValueTitle()].selectedHeaderTableData}
              orderByData={orderByData[handleTabValueTitle()]}
              handleTableOrderBy={handleTableOrderBy}

              handleOpenSnackbar={handleOpenSnackbar}
              handleCloseSnackbar={handleCloseSnackbar}

            />
          }
        </div>
      </div>
    </Layout>
  )
}




export default DynamicReportsWeb