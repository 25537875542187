import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DispatchTracking from "./DispatchTracking";
import Assignment from "@material-ui/icons/Assignment";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import CancelledHoldLapsedDispatchTracking from "./CancelledHoldLapsedDispatchTracking"
import SpinLoader from "../../common/SpinLoader";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoNotDisturbOutlinedIcon from '@mui/icons-material/DoNotDisturbOutlined';

import {
  bottomNavigationFontColor,
  secondaryColor,
  errorMessageColor,
  darkGreyColor,
  primaryColor,
} from "../../../styles/colors";
import { DetailsSharp } from "@mui/icons-material";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { handlePrintTable } from "../../internal/common/CommonFunctions";
import SnackbarComponent from "../../internal/common/SnackbarComponent";
import SwipeComponent from "../common/SwipeComponent";
import backStack from "cordova-back-stack";
import { checkApiError, checkInvalidValue, pxToEm } from "../../../methods";
import DispatchList from "./DispatchList";
import DispatchLAList from "../common/DispatchLAList";
import RiplInvoicePdfDownload from "../../internal/dispatchOperation/RiplInvoicePdfDownload";
import { CircularProgress } from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
// import ScrollTop from "../common/ScrollTop";

const styles = {
  singleDispatchMainBody: {
    height: window.innerHeight - 70,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowY: "inherit",
    paddingBottom: "16px",
  },
  invoiceContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  LaAdviceParticularsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // color: bottomNavigationFontColor,
  },
  LaAdviceParticularStyle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "14px 6px 6px",
  },
  LaAdviceParticularPadding6PX: {
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 6px 6px",
  },
  LaAdviceParticularPadding16PX: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 6px 6px",
  },
  clickCircle: {
    height: "8px",
    width: "8px",
    background: errorMessageColor,
    marginLeft: "5px",
    borderRadius: "50%",
  },
  singleBlockContainer: {
    width: "90%",
    marginBottom: "20px",
  },
  textAlignStart: {
    width: '45%',
    textAlign: 'start',
    fontSize: window.innerWidth < 375 && pxToEm(13.6)
  },
  textAlignEnd: {
    width: '50%',
    textAlign: 'end',
    fontSize: window.innerWidth < 375 && pxToEm(13.6)
  }
};
class SingleDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAdvice: "completed",
      gateIn: "completed",
      loadingCompleted: "process",
      dispatched: "completed",
      details: null,
      laDetails: null,
      snackOpen: false,
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
      isLoadingInvoice: false,
      isLoadingLA: false,
    };
    // this.addNewEndRef = React.createRef(null)
  }

  UNSAFE_componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      // let data = 'PREV : ' + `${String(localStorage.getItem("prevUrl"))} \n ` + 'Return : ' + `${String(localStorage.getItem("returnUrl"))} \n ` + 'Current : ' + `${String(localStorage.getItem("currentUrl"))} \n `
      // window?.confirm(data)
      // console.log(String(localStorage.getItem("prevUrl")))
      if (String(localStorage.getItem("prevUrl")) !== 'NaN' && !checkInvalidValue(localStorage.getItem("prevUrl"))) {
        let newReturnUrl = localStorage.getItem("prevUrl")
        localStorage.setItem("returnUrl", newReturnUrl);
        this.props.history.push(String(localStorage.getItem("prevUrl")));
      }
      else {
        this.props.history.push(String(localStorage.getItem("returnUrl")));
      }
    });
  }

  componentDidMount() {
    // console.log("re-render")
    if (this.props.details) {
      this.setState({
        details: this.props.details
      })
    }
    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = localStorage.getItem("currentUrl")
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
  }


  loadOldUrl = () => {
    this.props.history.push(String(localStorage.getItem("returnUrl")) !== '/home' && String(localStorage.getItem("returnUrl")))
  }

  componentDidUpdate() {
    if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
      let returnUrl = localStorage.getItem("currentUrl")
      localStorage.setItem("returnUrl", returnUrl);
      let currentUrl = window.location.pathname;
      localStorage.setItem("currentUrl", currentUrl);
    }
    // console.log(String(localStorage.getItem("prevUrl")), String(localStorage.getItem("prevUrl")) !== 'NaN' && !checkInvalidValue(localStorage.getItem("prevUrl")))
    if (String(localStorage.getItem("prevUrl")) !== 'NaN' && !checkInvalidValue(localStorage.getItem("prevUrl"))) {
      console.log("coming here", localStorage.getItem("prevUrl"), Boolean(localStorage.getItem("prevUrl")))
      let newReturnUrl = localStorage.getItem("prevUrl")
      localStorage.setItem("returnUrl", newReturnUrl);
      localStorage.setItem("prevUrl", 'NaN');
    }
    document.addEventListener("backbutton", this.loadOldUrl, false);
    // console.log("componentDidUpdate")
    if (!this.state.details && (this.props.details || this.props.location.state.LADetails)) {
      const details = this.props.details ? this.props.details :
        this.props.location.state && this.props.location.state.LADetails ? this.props.location.state.LADetails
          : null
      const laDetails = this.props.location.state ? this.props.location.state.LADetailsrev : null
      // console.log(this.props)
      if (details) {
        this.setState({
          details,
          laDetails
        })
      } else {
        // console.log("heeeeeeere", details)
        let laNo = this.props.location.pathname.split("/")[3]
        this.props.fetchLADetails(laNo)
      }
    }
  }

  handlePhone = () => {
    window.open(`tel:${this.props?.details.driver_contact_number}`, '_blank')
  }

  handleOpenSnackClick = () => {
    this.setState({ snackOpen: true })
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackOpen: false })
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }
  handleDownloadOpeningPDFLoader = (data = '') => {
    this.setState({ isLoadingInvoice: data === "" ? false : data })
  }

  handleDownloadLAOpeningPDFLoader = (data = '') => {
    this.setState({ isLoadingLA: data === "" ? false : data })
  }

  handleErrorSnackbar = (response) => {
    if (response.status === 500) {
      this.handleOpenSnackbar('server error', 'error')
      setTimeout(() => {
        this.handleCloseSnackbar();
      }, 3000)
    }
    else {
      this.handleOpenSnackbar(
        this.handleSnackbarMessageBox(checkApiError(response?.data)),
        "error"
      );
      setTimeout(() => {
        this.handleCloseSnackbar();
      }, 4000)
    }
  }

  payload = () => {
  }
  render() {
    const { loadingAdvice, dispatched, details, laDetails, isLoadingInvoice, isLoadingLA } = this.state;
    const { classes, location, singleLALoader, isMobile } = this.props;
    // console.log(this.props, details, singleLALoader, details == null)
    console.log(this.props.history)
    return (singleLALoader ?
      <div style={{
        // marginLeft: 165,
        display: "flex",
        justifyContent: "center",
        marginTop: "40vh"
      }}>
        <SpinLoader />
      </div> :
      details ?
        <SwipeComponent
          {...this.props}
          className={classes.singleDispatchMainBody}
          rightScreen={() => ''}
          leftScreen={() => this.props.history.push("/dispatch")}
        >
          {/* <ScrollTop type="RefDiv" ref={this.addNewEndRef} /> */}
          {!this.props.isMobile ? (
            <div style={{ padding: "10px 0px 10px 4%" }}>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  if (this.props.history.length > 2)
                    // this.props.history.goBack();
                    this.props.history.push(String(localStorage.getItem("returnUrl")));
                  else
                    this.props.history.push("/dispatch")
                  // // if (status === "ONGOING") {/active/${location.state.id}
                  // this.props.fetchSingleOrderPlantPlanning(location.state)
                  // this.props.fetchSingleOrderLoadingAdvices(location.state)

                }}
              >
                <KeyboardBackspace />
              </IconButton>
            </div>
          ) : null}
          <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* {console.log(this.props)} */}
            <DispatchLAList
              key={details.id}
              status={details.dispatch_status}
              laNumber={details.la_number}
              itemCategory={details.category_name}
              quantity={
                details?.quantity ||
                details?.la_quantity
                // this.props.loadingAdviceList.find(d => d.la_number == details.la_number)?.quantity ||
                // this.props.location?.state?.order?.quantity
              }
              // plant={data.plant.plant_short_name}
              plant={
                details?.plant?.plant_short_name ||
                this.props.loadingAdviceList.find(d => d.la_number == details.la_number)?.plant?.plant_short_name ||
                this.props.location?.state?.order?.plant?.plant_short_name
              }
              truckNumber={details.truck_number}
              createdDate={details.created_at}
              loadingAdvice={details}
              module="LADetail"
            // fetchLADetails={this.props.fetchLADetails}
            />
            {/* <DispatchCard
              {...this.props}
              itemCategory={details.item_category.category_name}
              quantity={details.la_quantity}
              plantName={details.plant_short_name}
              LANumber={details.la_number}
              truckNumber={details.truck_number}
              dispatchStatus={details.dispatch_status}
              orderNumber={details.order.order_number}
              orderId={details.order.id}
            /> */}
          </div>
          <div>
            {[
              "CANCELLED",
              "REJECTED",
              "LAPSED",
              "UNLOADING",
              "UNLOADED",
              "HOLD",
            ].includes(details.dispatch_status)
              ?
              <CancelledHoldLapsedDispatchTracking
                logDetails={details.log_details}
                laDetails={laDetails}
                dispatchStatus={details.dispatch_status}
                isMobile={isMobile}
              /> : details &&
              // details?.log_details?.length > 0 &&
              // details?.log_details.map(log =>
              <DispatchTracking
                loadingAdvice={loadingAdvice}
                gateIn={details.gate_in_date}
                dispatchStatus={details.dispatch_status}
                logDetails={details?.log_details}
                laDetails={laDetails}
                isMobile={isMobile}
                details={details}
              />
              // )

            }
          </div>
          {dispatched === "process" ? (
            <div className={classes.invoiceContainer}>
              <List component="nav" style={{ width: "90%" }}>
                <ListItem button>
                  <ListItemIcon>
                    <Assignment style={{ color: secondaryColor }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Invoice"
                    secondary="Invoice due on 01/01/2020"
                    primaryTypographyProps={{
                      style: { color: secondaryColor },
                    }}
                    secondaryTypographyProps={{
                      style: {
                        // color: bottomNavigationFontColor 
                      },
                    }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton aria-label="Comments">
                      <KeyboardArrowRight />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          ) : null}
          <div className={classes.LaAdviceParticularsContainer}>
            <div className={classes.singleBlockContainer} style={{ marginTop: "10px" }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                  Loading advice particular
                </span>
                {/* <span
                  onClick={(e) => handlePrintTable(details.la_number, "PRINT")}
                >
                  <PrintOutlinedIcon style={{ color: '#000' }} />
                </span> */}
              </div>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Contract Number</span>
                <span style={styles.textAlignEnd}>{details?.order?.order_number || ""}</span>
              </div>
              {/* {
                details.loading_advice_items.map(la =>
                  <div className={classes.LaAdviceParticularPadding6PX}>
                    <span style={styles.textAlignStart}>{la?.item?.item_name}</span>
                    <span style={styles.textAlignEnd}>{la?.item_qty} MT</span>
                  </div>
                )
              } */}
              {!checkInvalidValue(details?.subcontract_number) &&
                <div className={classes.LaAdviceParticularStyle}>
                  <span style={styles.textAlignStart}>SubContract Number</span>
                  <span style={styles.textAlignEnd}>{details?.subcontract_number}</span>
                </div>
              }
              {
                details.loading_advice_items.map(la =>
                  <div className={classes.LaAdviceParticularPadding6PX}>
                    <span style={styles.textAlignStart}>{la?.item?.item_name}</span>
                    <span style={styles.textAlignEnd}>{la?.item_qty} MT</span>
                  </div>
                )
              }
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Customer Remark</span>
                <span style={styles.textAlignEnd}>{details?.customer_remark}</span>
              </div>

              {/* <div className={classes.LaAdviceParticularPadding6PX}>
              <span>7mm</span>
              <span>11 MT</span>
            </div> */}
              {/* <div className={classes.LaAdviceParticularPadding16PX}>
              <span>Order Id</span>
              <span>#1234BB</span>
            </div>
            <div className={classes.LaAdviceParticularPadding6PX}>
              <span>5mm</span>
              <span>10.098 MT</span>
            </div> */}
            </div>
          </div>
          <div className={classes.LaAdviceParticularsContainer} style={{ display: details.is_consignee && 'none' }}>
            <div className={classes.singleBlockContainer}>
              <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                Buyer
              </span>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Name</span>
                <span style={styles.textAlignEnd}>{`${details?.buyer?.first_name} ${details?.buyer?.last_name}`} </span>
              </div>
              {
                details?.buyer?.gstin_number ?
                  <div className={classes.LaAdviceParticularPadding6PX}>
                    <span style={styles.textAlignStart}>GSTIN</span>
                    <span style={styles.textAlignEnd}>{details?.buyer?.gstin_number}</span>
                  </div> : details?.buyer?.pan_card_number
                    ? <div className={classes.LaAdviceParticularPadding6PX}>
                      <span style={styles.textAlignStart}>PAN</span>
                      <span style={styles.textAlignEnd}>{details?.buyer?.pan_card_number}</span>
                    </div> : null

              }

              <div className={classes.LaAdviceParticularPadding6PX}>
                <span style={styles.textAlignStart}>Contact</span>
                <span style={styles.textAlignEnd}>{details?.buyer?.customer_addresses?.contact ?
                  details?.buyer?.customer_addresses?.contact :
                  details?.buyer?.gst_addresses?.contact &&
                  details?.buyer?.gst_addresses?.contact}</span>
              </div>
            </div>
          </div>
          <div className={classes.LaAdviceParticularsContainer}>
            <div className={classes.singleBlockContainer}>
              <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                Consignee
              </span>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Name</span>
                <span style={styles.textAlignEnd}>{`${details?.consignee?.first_name} ${details.consignee.last_name}`} </span>
              </div>
              {
                details?.consignee?.gstin_number ?
                  <div className={classes.LaAdviceParticularPadding6PX}>
                    <span style={styles.textAlignStart}>GSTIN</span>
                    <span style={styles.textAlignEnd}>{details?.consignee?.gstin_number}</span>
                  </div> : details?.consignee?.pan_card_number
                    ? <div className={classes.LaAdviceParticularPadding6PX}>
                      <span style={styles.textAlignStart}>PAN</span>
                      <span style={styles.textAlignEnd}>{details?.consignee?.pan_card_number}</span>
                    </div> : null

              }
              <div className={classes.LaAdviceParticularPadding6PX}>
                <span style={styles.textAlignStart}>Contact</span>
                <span style={styles.textAlignEnd}>{details?.consignee?.consignee_from_customer_addresses ?
                  details?.consignee?.consignee_from_customer_addresses?.contact :
                  details?.consignee?.consignee_from_gst_information_addresses?.contact
                }</span>
              </div>
            </div>
          </div>
          {/* <div style={{ padding: "0px 10px 15px" }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ color: secondaryColor, textTransform: "capitalize" }}
            >
              <AddCircleOutline style={{ marginRight: "5px" }} />
              Add Order
            </Button>
          </div> */}
          <div className={classes.LaAdviceParticularsContainer}>
            <div className={classes.singleBlockContainer}>
              <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                Payment
              </span>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Payment Mode</span>
                <span style={styles.textAlignEnd}>{details?.payment_method?.payment_method_name} </span>
              </div>
              {
                !checkInvalidValue(details?.subcontract_details) &&
                <>
                  <div className={classes.LaAdviceParticularStyle}>
                    <span style={styles.textAlignStart}>Payment Doc No</span>
                    <span style={styles.textAlignEnd}>
                      {details?.subcontract_details?.payment_doc_number}
                    </span>
                  </div>
                  <div className={classes.LaAdviceParticularStyle}>
                    <span style={styles.textAlignStart}>Payment Doc Date</span>
                    <span style={styles.textAlignEnd}>
                      {moment(details?.subcontract_details?.payment_doc_date).format('DD-MMM-YYYY')}
                    </span>
                  </div>
                  <div className={classes.LaAdviceParticularStyle}>
                    <span style={styles.textAlignStart}>Payment Bank Name</span>
                    <span style={styles.textAlignEnd}>
                      {details?.subcontract_details?.payment_bank_name}
                    </span>
                  </div>
                  <div className={classes.LaAdviceParticularStyle}>
                    <span style={styles.textAlignStart}>Payment Doc Value</span>
                    <span style={styles.textAlignEnd}>
                      {details?.subcontract_details?.payment_doc_value}
                    </span>
                  </div>
                  <div className={classes.LaAdviceParticularStyle}>
                    <span style={styles.textAlignStart}>Payment Doc Valid Upto</span>
                    <span style={styles.textAlignEnd}>
                      {moment(details?.subcontract_details?.payment_doc_valid_upto).format('DD-MMM-YYYY')}
                    </span>
                  </div>
                  <div className={classes.LaAdviceParticularStyle}>
                    <span style={styles.textAlignStart}>Payment Doc Remark</span>
                    <span style={styles.textAlignEnd}>
                      {details?.subcontract_details?.payment_doc_remark}
                    </span>
                  </div>
                </>
              }
            </div>
          </div>
          <div className={classes.LaAdviceParticularsContainer}>
            <div className={classes.singleBlockContainer}>
              <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                Transport Details
              </span>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Name</span>
                <span style={styles.textAlignEnd}>{details?.transporter?.acc_name || ""} </span>
              </div>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Contact</span>
                <span style={styles.textAlignEnd}>{details?.transporter?.mobile || ""}</span>
              </div>
            </div>
          </div>
          <div className={classes.LaAdviceParticularsContainer}>
            <div className={classes.singleBlockContainer}>
              <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                Truck Details
              </span>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Truck No</span>
                <span style={styles.textAlignEnd}>{details?.truck_number} </span>
              </div>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Truck capacity</span>
                <span style={styles.textAlignEnd}>{parseFloat(details?.truck_capacity).toFixed(0)}MT</span>
              </div>
            </div>
            <div className={classes.singleBlockContainer}>
              <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                Driver Details
              </span>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Driver Name</span>
                <span style={styles.textAlignEnd}>{details?.driver_name}</span>
              </div>
              <div className={classes.LaAdviceParticularStyle}>
                <span style={styles.textAlignStart}>Driver Licence No</span>
                <span style={styles.textAlignEnd}>{details?.driver_licence}</span>
              </div>
              {window?.cordova && window?.cordova?.platformId !== 'android'
                ?
                (<div className={classes.LaAdviceParticularStyle}>
                  <span style={styles.textAlignStart}>Contact No</span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.cordova.InAppBrowser.open(`tel:${this.props?.details.driver_contact_number}`, `_system`)
                      }}
                      style={{ textDecoration: "none", display: !details?.driver_contact_number && 'none' }}
                    >
                      <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 && pxToEm(13.6) }}>
                        {details.driver_contact_number}</span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: bottomNavigationFontColor,
                          fontSize: window.innerWidth < 375 && pxToEm(12)
                        }}
                      >
                        click to call
                        <span className={classes.clickCircle} />
                      </span>
                    </a>
                  </div>
                </div>)
                :
                (<div className={classes.LaAdviceParticularStyle}>
                  <span style={styles.textAlignStart}>Contact No</span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a
                      href="#"
                      // onclick=
                      // e.preventDefault()
                      // `window.cordova? this.handlePhone()
                      // : 
                      // "window.open(`tel:${8290889209}`, '_system'); return false;"

                      onClick={this.handlePhone}
                      // pathname={`tel://${details.driver_contact_number}`} 
                      style={{
                        textDecoration: "none",
                        display: !details?.driver_contact_number && 'none'
                      }}
                    >
                      <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 && pxToEm(13.6) }}>
                        {details.driver_contact_number}</span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: bottomNavigationFontColor,
                          fontSize: window.innerWidth < 375 && pxToEm(12)
                        }}
                      >
                        click to call
                        <span className={classes.clickCircle} />
                      </span>
                    </a>
                  </div>
                </div>)
              }
            </div>
            {(['BILLED', 'DISPATCHED',].includes(details.dispatch_status) && !checkInvalidValue(details?.erp_invoice_number)) &&
              <div style={{ display: details?.is_consignee ? 'none' : 'flex', width: '90%', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 20 }}>
                {
                  this.props?.downloadInvoiceReady ?
                    <div
                      style={{ width: '100%' }}>
                      <RiplInvoicePdfDownload
                        // pdfOperation={"CUSTOMERDOWNLOAD"}
                        {...this.props}
                        pdfpayload={this.payload}
                        handleOpenSnackBar={this.props.handleOpenSnackBar}
                        isLogoRequired={true}
                        invoiceForPlatform="customer app"
                        device="cordova"
                        docNumber={details?.erp_invoice_number}
                        invoiceData={details}
                        handleDownloadLoader={this.handleDownloadOpeningPDFLoader}
                        onClick={() => this.props.handleInvoiceClick(details)}
                        handlePostInvoiceDownload={this.props.handlePostInvoiceDownload}
                        eInvoiceResponse={this.props?.eInvoiceResponse}
                        eWayBillResponse={this.props?.eWayBillResponse}
                        iseWaySelect={this.props?.iseWaySelect}
                        plant_state={this.props?.plant_state}
                        b2CQrImage={this.props?.b2CQrImage}
                        documentFor={
                          this.props.invoiceType ?
                            this.props.invoiceType !== "Normal Invoice" ?
                              this.props?.laDetailsInDispatchCounter?.item_category
                                ?.is_allow_rate_zero &&
                                Number(
                                  this.props?.laDetailsInDispatchCounter?.order?.rate
                                ) === 0
                                ? "Delivery Challan"
                                : "E-Invoice" :
                              this.props?.laDetailsInDispatchCounter?.item_category
                                ?.is_allow_rate_zero &&
                                Number(
                                  this.props?.laDetailsInDispatchCounter?.order?.rate
                                ) === 0
                                ? "Delivery Challan"
                                : "Invoice"
                            : null
                        }
                      />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                          Invoice Print
                        </span>
                        <span
                          onClick={(e) => {
                            if (!isLoadingInvoice) {
                              this.props.handleInvoiceClick(details)
                            }
                          }}
                          style={{
                            paddingRight: 6,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            opacity: isLoadingInvoice && '0.6'
                          }}
                        >
                          <span style={{ marginRight: 10, color: secondaryColor, }}>
                            {details?.erp_invoice_number}
                          </span>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: bottomNavigationFontColor,
                              fontSize: window.innerWidth < 375 && pxToEm(12)
                            }}
                          >
                            click to print
                            <span className={classes.clickCircle} />
                          </span>
                          {/* <PrintOutlinedIcon
                            style={{
                              color: secondaryColor,
                              transform: 'scale(1.2)'
                            }}
                          /> */}
                        </span>
                      </div>
                    </div>
                    :
                    <>
                      <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                        Invoice Print
                      </span>
                      <span
                        onClick={(e) => {
                          if (!isLoadingInvoice) {
                            this.props.handleInvoiceClick(details)
                            this.handleDownloadOpeningPDFLoader(true)
                          }
                        }}
                        style={{
                          paddingRight: 6,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          opacity: isLoadingInvoice && '0.6'
                        }}
                      >
                        <span style={{ marginRight: 10, color: secondaryColor, }}>
                          {isLoadingInvoice &&
                            (<span>
                              <CircularProgress
                                size={15}
                                style={{ marginRight: "10px", color: primaryColor }}
                              />
                            </span>
                            )
                          }
                          {details?.erp_invoice_number}
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: bottomNavigationFontColor,
                            fontSize: window.innerWidth < 375 && pxToEm(12)
                          }}
                        >
                          click to print
                          <span className={classes.clickCircle} />
                        </span>
                        {/* <PrintOutlinedIcon
                          style={{
                            color: secondaryColor,
                            transform: 'scale(1.2)'
                          }}
                        /> */}
                      </span>
                    </>
                }
              </div>
            }
            {!['PENDING',].includes(details.dispatch_status) &&
              <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 20 }}>
                <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                  Loading Advice Print
                </span>
                <span
                  onClick={(e) => {
                    if (['PENDING',].includes(details.dispatch_status) || isLoadingLA) {
                      e.preventDefault()
                    } else {
                      this.handleDownloadLAOpeningPDFLoader(true)
                      if (!window.cordova) {
                        handlePrintTable(details.la_number, "PRINT", '', this.handleDownloadLAOpeningPDFLoader, this.handleErrorSnackbar)
                      }
                      else {
                        handlePrintTable(details.la_number, "CORDOVA", this.handleOpenSnackClick, this.handleDownloadLAOpeningPDFLoader, this.handleErrorSnackbar)
                      }
                    }
                  }}
                  style={{ paddingRight: 10, }}
                // disabled={['PENDING', 'PACKED'].includes(details.dispatch_status)}
                >
                  {/* <Button style={{ color: secondaryColor, textTransform: 'capitalize' }}>Print LA</Button> */}
                  {isLoadingLA &&
                    (<span>
                      <CircularProgress
                        size={15}
                        style={{ marginRight: "10px", color: primaryColor }}
                      />
                    </span>
                    )
                  }
                  <PrintOutlinedIcon
                    style={{
                      color: ['PENDING',].includes(details.dispatch_status) ? darkGreyColor : secondaryColor,
                      transform: 'scale(1.2)',
                      opacity: isLoadingLA && '0.6'
                    }}
                  />
                </span>
              </div>
            }
            {(['PENDING',].includes(details.dispatch_status) && details?.is_created_by_customer) &&
              <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 20 }}>
                <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                  Edit LA
                </span>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push({
                      pathname: `/dispatch/edit/${details?.la_number}`,
                      state: {
                        laDetails: details,
                        mode: 'edit'
                      }
                    })
                  }
                  }
                  style={{ paddingRight: 10, display: 'flex', alignItems: 'center' }}
                >
                  <span style={{ transform: 'scale(1)', paddingTop: 2, color: secondaryColor }}>
                    <EditOutlinedIcon />
                  </span>
                  {/* <span style={{ paddingLeft: 0 }}>Edit</span> */}
                </span>
              </div>
            }
            {(['PENDING',].includes(details.dispatch_status) && details?.is_created_by_customer) &&
              <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 20 }}>
                <span style={{ color: secondaryColor, fontSize: window.innerWidth < 375 ? pxToEm(16) : "1.1em" }}>
                  Cancel LA
                </span>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    this.props?.handleCancelLA(details?.id)
                  }}
                  style={{ paddingRight: 10 }}
                >
                  <div style={{ position: 'relative', transform: 'scale(1.5)' }}>
                    <span style={{ color: secondaryColor }}><DoNotDisturbOutlinedIcon /></span>
                    <span
                      style={{
                        position: 'absolute',
                        left: 1,
                        bottom: 1,
                        transform: 'scale(0.5)',
                        color: secondaryColor
                      }}
                    >
                      <LocalShippingOutlinedIcon />
                    </span>
                  </div>
                </span>
              </div>
            }
          </div>
          {/* <ScrollTop type="Button" ref={this.addNewEndRef} bottom="10px" /> */}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleCloseSnackbar}
            customAutoHideDuration={3000}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
          <SnackbarComponent
            open={this.state?.snackOpen}
            handleClose={this.handleClose}
            customAutoHideDuration={3000}
            snackbarMessage="Downloading PDF..."
          // type="info"
          />
        </SwipeComponent>
        : null


    );
  }
}
export default withStyles(styles)(SingleDispatch);
