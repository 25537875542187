import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Create from "../Create";
class createOrderWeb extends Component {
  render() {
    return (
      <WebLayout appBar dailyRates>
        <Create {...this.props} />
      </WebLayout>
    );
  }
}
export default createOrderWeb;
