import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import "../../../../styles/b2b/DailyRates.css";
import backStack from "cordova-back-stack";
import Snackbar from "@material-ui/core/Snackbar";
import Home from "../Home";
import CreateOrder from "./CreateOrder";

const styles = {
  dailyRatesMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    height: window.innerHeight - 74,
    // (Window.innerHeight * 0.09),
    overflowY: "inherit",
  },
};
class DailyRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribed: [
        { company: "TMT Bars", price: "10,000" },
        { company: "GK Bars", price: "10,00" },
        { company: "Straight", price: "10,000" },
        { company: "TMT Bars", price: "10,000" },
      ],
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      if (this.props.location.pathname === "/dailyRates") {
        window.navigator.app.exitApp();
      }
    });
  }
  componentDidMount() {
    if (window.cordova) {
      window.FirebasePlugin.onMessageReceived(
        (payload) => {
          this.props.history.push("/b2bNotification");
        },
        function (error) {
          console.error(error);
        }
      );
    }
  }
  render() {
    const {
      classes,
      subscribedDailyRates,
      subscribedDailyRatesLoader,
      dailyRatesLastUpdatedAt,
      user,
      isOffline,
      isMobile,
      fetchOrderList,
      fetchItemCategoriesPaymentDiscount,
      bannerData,
      invoicesList,
      invoicesPaymentDetail,
      invoiceLoader,
      handleOpenSnackbar,
      handleInvoiceClick,
      b2CQrImage,
      handlePostInvoiceDownload,
      laDetailsInDispatchCounter
    } = this.props;
    return (
      <MobileLayout bottomNavigation bottomNavSelected={0}>
        <div className={classes.dailyRatesMainBody}>
          <Home
            {...this.props}
            isMobile={isMobile}
            subscribedDailyRates={subscribedDailyRates}
            subscribedDailyRatesLoader={subscribedDailyRatesLoader}
            dailyRatesLastUpdatedAt={dailyRatesLastUpdatedAt}
            fetchOrderList={fetchOrderList}
            fetchItemCategoriesPaymentDiscount={fetchItemCategoriesPaymentDiscount}
            bannerData={bannerData}
            invoicesList={invoicesList}
            invoicesPaymentDetail={invoicesPaymentDetail}
            invoiceLoader={invoiceLoader}
            handleOpenSnackbar={handleOpenSnackbar}
            handleInvoiceClick={handleInvoiceClick}
            b2CQrImage={b2CQrImage}
            handlePostInvoiceDownload={handlePostInvoiceDownload}
            laDetailsInDispatchCounter={laDetailsInDispatchCounter}
            eInvoiceResponse={this.props?.eInvoiceResponse}
            eWayBillResponse={this.props?.eWayBillResponse}
            iseWaySelect={this.props?.iseWaySelect}
            plant_state={this.props?.plant_state}
            invoiceType={this.props?.invoiceType}
          />
        </div>
        <Snackbar
          open={isOffline}
          onClose={this.handleClose}
          autoHideDuration={4000}
          TransitionComponent={"up"}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          message={<span id="message-id">No Internet Connection</span>}
        />
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(DailyRates);
export { CreateOrder };
