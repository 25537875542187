import React, { forwardRef, useEffect, useRef, useState, useRouter } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import StepContent from "@material-ui/core/StepContent";
import {
	Divider,
	IconButton,
	Typography,
	Button,
	Slide,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	CircularProgress,
	Box,
	TextField,
	Chip,
	FormControl,
	InputLabel,
	Select
} from '@material-ui/core';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import Stack from '@mui/material/Stack';


import {
	cancelButtonBackground,
	successButtonBackground,
	drawerTextColor,
	primaryColor,
} from "../../../constants/internal/colors";
import {
	pxToEm,
	hasUpdateAccess,
	hasSpecialAccess,
	hasApproveAccess,
	handleTotalQtySumCalculation,
	checkInvalidValue,
	checkApiError,
	handlePaymentStatusText,
	getOptions
} from '../../../methods';
import moment from "moment";
import SpinLoader from '../../common/SpinLoader';
import LAStatusVerticalStepper from "./LAStatusVerticalStepper";
import { handlePrintTable } from './CommonFunctions';
import APIROUTES from '../../../constants/internal/InternalApiRoutes';
import SnackbarComponent from '../../common/SnackbarComponent';
import { startCase } from 'lodash';
import { Delete } from '@material-ui/icons';
import ToolTipComponent from './ToolTipComponent';
import { useHistory } from 'react-router-dom';
import { handlePrintLAOverviewTable } from './LAOverviewPdf';
import axios from 'axios';
import SalesReturnSVG from "../../common/SalesReturnSVG"

const tableStyles = makeStyles((theme) => ({
	root: {
		"& .MuiTableCell-head": {
			fontFamily: 'Poppins',
			fontSize: pxToEm(14),
			textTransform: 'uppercase',
			fontWeight: 'bold',
			color: "#839192",
			backgroundColor: "#fafafa"
		},
		"& .MuiTableCell-body": {
			fontFamily: 'Poppins',
			fontSize: pxToEm(15),
			fontWeight: '400',
		},
		'&::-webkit-scrollbar': {
			height: 7,
		},
		'&::-webkit-scrollbar-thumb': {
			background: 'rgba(75, 135, 197, 0.9)',
			borderRadius: 10,
			border: '2px solid transparent',
			backgroundClip: 'padding-box', // <== make the border work
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: 'rgba(75, 135, 197, 0.9)',
			// height: 30,
			// border: 0,
		},

		'&::-webkit-scrollbar-track': {
			background: 'transparent'
		},
	},
	stepIconRoot: {
		"& .MuiStepIcon-completed": {
			color: "#293C79",
		},
		"& .MuiStepIcon-active": {
			color: "#293C79",
		},
		"& .MuiStepIcon-disabled": {
			color: "grey",
		},
	},
	discardButton: {
		backgroundColor: "#293C79",
		color: drawerTextColor,
		margin: "10px"
	},
	noDataFound: {
		display: "flex",
		width: "100%",
		// height: "calc(100vh - 200px)",
		alignItems: "center",
		justifyContent: "center",
	},
	tableContainer: {
		display: 'flex',
		width: '100%',
		backgroundColor: '#FFF',
		padding: '0 1% 1% 1%',
		position: "relative"
	},
	gray: {
		color: "#666"
	},
	blue: {
		color: "#3B69B0"
	},
	orange: {
		color: "#FFAE42"
	},
	green: {
		color: "#4CAF50"
	},
	actionButton: {
		margin: 5,
	},
	popoverText: {
		display: "inline",
		fontFamily: 'Poppins',
		fontSize: "inherit",
		fontWeight: '400',
	},
	popoverBox: {
		'& .MuiPopover-paper': {
			backgroundColor: 'rgba(255,255,255,1)',
			color: '#000',
			marginBottom: 10
			// borderRadius: 20,
		}

	},
	popUpValueRightAlign: {
		textAlign: 'right'
	},
	dialogText: {
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: pxToEm(14),
		fontWeight: '500',
	},
	menuText: {
		display: 'flex',
		fontFamily: 'Poppins',
		justifyContent: 'space-between',
		fontSize: pxToEm(14),
		fontWeight: '800',
		color: "#3B69B0"
	},
	paginationText: {
		display: 'flex',
		fontFamily: 'Poppins',
		justifyContent: 'flex-end',
		fontSize: pxToEm(18),
		fontWeight: '800',
		color: "#3B69B0"
	},
	laActionsDiv: {
		display: "flex",
		placeContent: "center",
		placeItems: "center",
	},
	removeButton: {
		backgroundColor: cancelButtonBackground,
		color: drawerTextColor,
		marginRight: 10,
		'&:hover': {
			backgroundColor: cancelButtonBackground,
		}
	},
	successButton: {
		backgroundColor: successButtonBackground,
		color: drawerTextColor,
		marginRight: 10,

		'&:hover': {
			backgroundColor: successButtonBackground,
		}
	},
	loaderStyle: { color: primaryColor },
	loaderDiv: {
		//padding: 30,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		left: "40%",
		top: "40%"
		//height: "calc(100vh - 250px)",
	},
	'@keyframes spin': {
		"0%": {
			paddingTop: 0,
			marginBootom: 1,
			transform: 'scale(0.8) rotate(360deg)'
		},
		"100%": {
			paddingTop: 0,
			marginBootom: 1,
			transform: 'scale(0.8) rotate(0deg)'
		}
	}
}));

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: '#eaeaf0',
	},
	active: {
		color: 'red',
	},
	completed: {
		color: 'green',
	},
}));
const ActiveSvgIcon = styled(SvgIcon)(({ theme }) => ({
	color: "#293C79",
	fontSize: "small",
}))
const CustomStepIcon = (props) => {
	return (
		<ActiveSvgIcon shapeRendering="circle">
			<circle cx="12" cy="12" r="12" />
			<HighlightOffOutlinedIcon style={{ color: "#ffffff" }} fontSize="small" />
		</ActiveSvgIcon>
	);
};
// let isFirst = true

let LoadingAdviceTable = (props) => {
	const classes = tableStyles();
	const history = useHistory();
	const [tableData, setTableData] = useState([])
	const [invoiceClickedData, setInvoiceClickedData] = useState([])
	const [VehicleHoverData, setVehicleHoverData] = useState([])
	const [SubcontractHoverData, setSubcontractHoverData] = useState([])
	const [LaQty, setLaQty] = useState([]);
	const [weighBridge, setWeighBridge] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [plantCode, setPlantCode] = useState('');
	const [snackbar, setSnackbar] = useState({
		openSnackbar: false,
		snackbarMessage: "",
		snackbarType: "",
	});
	const [isCopied, setIsCopied] = useState(false);
	let Rerendered = false;
	const Transition = forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});
	const initialRender = useRef(true);
	// console.log(props.match.params?.plantId)
	const columns = [
		{ id: "GateInDate", label: "Gate In Date" },
		{ id: 'LA', label: 'LA' },
		{ id: 'Invoice', label: 'Invoice' },
		{ id: 'PaymentTerms', label: 'Payment Terms' },
		{ id: 'PlantName', label: 'Plant Name' },
		{ id: 'ItemCategory', label: 'Item Category' },
		{ id: 'Vehicle', label: 'Vehicle #' },
		{ id: 'Customer', label: 'Customer' },
		{ id: 'OrderID', label: 'Contract No.' },
		{ id: 'Buyer', label: 'Buyer' },
		{ id: 'Consignee', label: 'Consignee' },
		{ id: 'LAQty', label: 'LA Qty' },
		{ id: 'NetWeight', label: 'Net Weight' },
		{ id: 'Weighbridge', label: 'Weighbridge' },
		{ id: 'Action', label: 'Action' },
	];

	const dispatchPlaningListLAHeader = ['GateInDate', 'PlantName'];


	useEffect(() => {
		// console.log("snackBar" + props.snackBar)
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			Rerendered = true
		}
	}, [tableData]);

	useEffect(() => {
		if (!checkInvalidValue(props.plant_code)) {
			localStorage.setItem("La_plant_code", JSON.stringify(props.plant_code))
			setPlantCode(props.plant_code)
		}
		else if (checkInvalidValue(props.plant_code)) {
			let plant_code = localStorage.getItem("La_plant_code")
			if (JSON.parse(plant_code)) {
				setPlantCode(JSON.parse(plant_code))
			}
		}

	}, [props.plant_code])

	useEffect(() => {
		let data = []
		props?.dispatchPlaningListLA ? data = props.dispatchPlaningListLA : data = props.orderLoadingAdvices
		sessionStorage.setItem("selectedDate", props.selectedDate)
		const formattedData = data?.map((object, index) => ({
			id: object.id,
			GateInDate: object.gate_in_date,
			LA: object.la_number,
			Status: object.dispatch_status,
			Invoice: object.erp_invoice_number ? object.erp_invoice_number : " ",
			PlantName: object.plant?.plant_short_name,
			PlantFullName: object.plant?.plant_name,
			ItemCategoryId: object?.item_category?.id,
			ItemCategory: object?.item_category?.category_name,
			Vehicle: object.truck_number,
			itemTotalQuantity: object.loading_advice_items_total_quantity,
			Customer: object.customer_name,
			Customer_Id: object.customer_id,
			OrderID: object.order_number,
			created_from_subcontract: object.created_from_subcontract,
			sub_contract_number: object?.sub_contract_detail?.sub_contract_number,
			Buyer: object.buyer?.first_name ? (object.buyer?.first_name + " " + object.buyer?.last_name) : " ",
			Consignee: object.consignee?.consignee_detail?.first_name ? (object.consignee?.consignee_detail?.first_name + " " + object.consignee?.consignee_detail?.last_name) : " ",
			LAQty: object.loading_advice_items_total_quantity,
			// NetWeight: (parseFloat(object.gross_weight) - parseFloat(object.tare_weight)),
			NetWeight: object.loading_advice_items,
			Weighbridge: object.weighbridge ? object.weighbridge.weighbridge_name : " ",
			WeighbridgeID: object.weighbridge ? object.weighbridge.id : " ",
			order: object.order,
			orderStatus: object.order_status,
			paymentMethodId: object.payment_method?.id,
			laDueDate: object.la_due_date,
			paymentStatus: object.payment_status,
			PaymentTermsDays: object.payment_method.days,
			PaymentTerms: object.payment_method.payment_method_name,
			PaymentTermsClickedData: {
				approx: object.payment_detail?.approx,
				invoiceAmount: object.payment_detail?.invoice_amount,
				paid: object.payment_detail?.paid,
				balance: object.payment_detail?.balance,
				syncedAt: object.payment_detail?.synced_at,
				invoiceDate: object.doc_date,
				dueDate: object.la_due_date,
				advanceCreditLimit: object.buyer?.advance_credit_limit,
				nonAdvanceCreditLimit: object.buyer?.non_advance_credit_limit,
			},
			BuyerPinCode: object.buyer?.address?.pincode,
			invoiceClickedData: {
				irn: object.eway_detail?.irn_no,
				irnDate: object.eway_detail?.irn_date,
				ewayNo: object.eway_detail?.eway_no,
				ewayDate: object.eway_detail?.eway_date
			},
			VehicleHoverData: {
				transporter: object?.transporter?.acc_name ? object.transporter.acc_name : ' ',
				driver_name: object.driver_name,
				driver_no: object.driver_contact_number,
				lc_number: object.lc_number,
				lc_date: object.lc_date,
				frieght_rate: object.freight_charge_rate
			},
			SubcontractHoverData: {
				sub_contract_number: object?.sub_contract_detail?.sub_contract_number,
				sub_contract_date: object?.sub_contract_detail?.sub_contract_date,
				sub_contract_qty: object?.sub_contract_detail?.sub_contract_qty,
				payment_doc_value: object?.sub_contract_detail?.payment_doc_value,
			},
			LaQty: {
				laQty: object.loading_advice_items
			},
			weighBridge: {
				grossWeight: object.gross_weight,
				tareWeight: object.tare_weight,
				loadedQty: object.loaded_quantity,
				loadingAdviceWeight: object.loading_advice_weighings ? [object.loading_advice_weighings] : " "
			},
			lastUpdatedDiffinHrs:
				parseInt(moment.duration(moment(new Date()).diff(moment(object?.logs
					?.find(a => a.status == "BILLED")
					?.created_at)))?.asSeconds()) ?? 0,
			createdBy: object.created_by,
			laRowData: object,
			sales_vr_number: object.sales_vr_number
		}))
		setTableData(formattedData)
		return () => {
			// Anything in here is fired on component unmount.
			// setTableData([]);
			// setPage(0);
		};
	}, [props.dispatchPlaningListLA, props.selectedDate, props.orderLoadingAdvices])

	useEffect(() => {
		setPage(0);
	}, [props.selectedDate,
	props.itemCategoryIds,
	props.statusSelect,
	props.weighBridgeIds,
	props.searchLAValue,])

	const handleChangePage = (event, laPage) => {
		//if (!this.props.searchValue) {

		if (laPage > page) {
			if (props.module === "loadingAdvices") {
				props.handleFetchDispatchDetailsWithDate(
					props.selectedDate,
					props.match?.params?.plantId,
					props.searchLAValue,
					props.itemCategoryIds,
					props.statusSelect,
					props.weighBridgeIds,
					props.nextLink
				);
			} else
				return props.handleFetchLoadingAdvices(props.nextLink)


		}
		else if (laPage < page) {
			if (props.module === "loadingAdvices") {
				props.handleFetchDispatchDetailsWithDate(
					props.selectedDate,
					props.match?.params?.plantId,
					props.searchLAValue,
					props.itemCategoryIds,
					props.statusSelect,
					props.weighBridgeIds,
					props.prevLink
				);
			} else
				return props.handleFetchLoadingAdvices(props.prevLink)

			//}
		}
		// else {
		//   if (laPage > page) {
		// 	// console.log("HERE", this.props.nextLink)
		// 	this.props.handleSearchTextData(
		// 	  sessionStorage.getItem("itemCategoryID"),
		// 	  this.props.match.params.plantId,
		// 	  this.props.nextLink
		// 	);
		//   }
		//   else if (laPage < page) {
		// 	this.props.handleSearchTextData(
		// 	  sessionStorage.getItem("itemCategoryID"),
		// 	  this.props.match.params.plantId,
		// 	  this.props.prevLink
		// 	);
		//   }
		// }
		setPage(laPage);
	};

	const handleOpenSnackbar = (message, type) => {
		setSnackbar({
			openSnackbar: true,
			snackbarMessage: message,
			snackbarType: type,
		});
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbar({
			openSnackbar: false,
			snackbarMessage: "",
			snackbarType: "",
		});
	};

	const handleSnackbarMessageBox = (data) => {
		console.log(data)
		return
		(data.map((item, index) => {
			return (
				<>
					{console.log(item)}
					<p style={{ margin: 0 }}>
						{item.includes("0  :") ? item.replace("0  :", "") : item}
					</p>
				</>)
		})
		)
	}

	const handleApproveClick = (laId, date, plantId) => {
		// sessionStorage.setItem("LaStatusChanged", false)
		let laStatusUpdateData = {
			dispatch_status: "APPROVED",
			dispatch_remark: "",
		};
		props.handleLaApprove(laId, laStatusUpdateData, date, plantId)
	};
	const handleConfirmationDialogDeleteClick = (laId, rejectLaRemark, date, plantId) => {
		// sessionStorage.setItem("LaStatusChanged", false)
		let laStatusUpdateData = {
			dispatch_status: "REJECTED",
			status_remark: rejectLaRemark,
		};
		props.handleCancelLa(laId, laStatusUpdateData, date, plantId)
	};

	const onCopyText = (index) => {
		navigator.clipboard.writeText(index)
		setIsCopied(prevValue => { return { ...prevValue, [index]: !prevValue[index] } })
		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	};

	const fetchReviewModification = (number) => {
		return (
			axios.get(`${APIROUTES.GET_LA_MODIFICATION_DETAILS}la_number=${number}`, getOptions(props.user))
		)
	}

	const handleSyncPaymentButton = (number) => {
		return (
			axios.get(`${APIROUTES.SYNC_LA_PAYMENT}${number}/`, {
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				}
			})
		)
	}

	const fetchLaWeighDetails = (plantId, weighDate) => {
		return (
			axios.get(`${APIROUTES.CONTRACTS_LOADING_ADVICE_LIST}?plant_id=${plantId}&date=${weighDate}`, getOptions(props.user))
		)
	}

	const handleConfirmationLaModification = (laId, data, remarkMessage) => {
		if (data === "approve") {
			props.approveLaModification(laId, props.selectedDate, props.match.params.plantId, remarkMessage)
		}
		else if (data === "reject") {
			props.rejectLaModification(laId, props.selectedDate, props.match.params.plantId, remarkMessage)
		}
	};

	const handlePaymentDueDateText = (laDueDate) => {
		// return 'DUE IN 10 D'
		let paymentDate = new Date(laDueDate);
		let todayDate = new Date();

		let timeDiff = paymentDate.getTime() - todayDate.getTime();
		let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

		return daysDiff > 0
			? `DUE IN ${daysDiff} D`
			: daysDiff < 0
				? `OVERDUE BY ${Math.abs(daysDiff)} D`
				: daysDiff === 0 && 'DUE TODAY'
	}

	const handleDateFormat = (date, format) => {
		if (!checkInvalidValue(date)) {
			return moment(date,
				[
					"DD/MM/YYYY hh:mm:ss A",
					"DD/MM/YYYY hh:mm:ss",
					"DD/MM/YYYY",
					"YYYY-MM-DD hh:mm:ss A",
					"YYYY-MM-DD",
					"YYYY-MM-DD hh:mm:ss"
				]).format(format)
		}
		else {
			return ""
		}
	}

	const syncPayment = (number) => {
		handleSyncPaymentButton(number)
			.then((response) => {
				if (response.status === 200) {
					let data = response.data
					handleOpenSnackbar('Sync La Payment Successfully', "success");
					setTableData(tableData.map(ele => {
						if (ele.LA === number) {
							return {
								...ele,
								paymentStatus: data.payment_status,
								PaymentTermsClickedData: {
									approx: data?.approx,
									invoiceAmount: data?.invoice_amount,
									paid: data?.paid,
									balance: data?.balance,
									syncedAt: data?.synced_at,
									invoiceDate: ele.PaymentTermsClickedData.invoiceDate,
									dueDate: ele.PaymentTermsClickedData.dueDate,
									advanceCreditLimit: ele.PaymentTermsClickedData.advanceCreditLimit,
									nonAdvanceCreditLimit: ele.PaymentTermsClickedData.nonAdvanceCreditLimit,
								}
							}
						}
						else {
							return ele
						}
					}))
					setTimeout(() => {
						handleCloseSnackbar();
					}, 3000);
				}
			})
			.catch((error) => {
				handleOpenSnackbar(
					handleSnackbarMessageBox(checkApiError(error?.response?.data)),
					"error"
				);
				setTimeout(() => {
					handleCloseSnackbar();
				}, 4000);
			});
	}

	const HoverVehicle = (props) => {
		let data = props.data
		return (
			<div style={{ width: 280, padding: "5%" }}>
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Transporter
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.transporter}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Driver Name
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.driver_name}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Driver No.
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.driver_no}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						LC No.
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.lc_number}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						LC Date
					</div>
					<div className={classes.popUpValueRightAlign}>
						{![null, ""].includes(data.lc_date) ? moment(data.lc_date).format("DD-MMM-YY") : " "}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Freight Rate
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.frieght_rate}
					</div>
				</Typography>
			</div>
		)
	}

	const HoverSubcontract = (props) => {
		let data = props.data
		return (
			<div style={{ width: 280, padding: "5%" }}>
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Subcontract #
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.sub_contract_number}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Subcontract Date
					</div>
					<div className={classes.popUpValueRightAlign}>
						{![null, ""].includes(data.sub_contract_date) ? moment(data.sub_contract_date).format("DD-MMM-YY") : " "}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Subcontract Quantity
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.sub_contract_qty}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Payment Doc Value
					</div>
					<div className={classes.popUpValueRightAlign}>
						{data.payment_doc_value}
					</div>
				</Typography>
			</div>
		)
	}

	const ClickedLaQty = (props) => {
		let data = props.data
		return (
			<div style={{ width: 300, padding: "5%" }}>
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Item
					</div>
					<div className={classes.blue}>
						Item Qty
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				{data.laQty.map((item, index) => {
					return (
						<div key={index + "LaQty"}>
							<Typography className={classes.dialogText}>
								<div className={classes.blue}>
									{item.item}
								</div>
								<div>
									{item.item_qty} MT
								</div>
							</Typography>
							<Divider
								variant='middle'
								style={{
									margin: '2% 0',
									display: index === ((data.laQty).length - 1) ? 'none' : 'block'
								}}
							/>
						</div>
					)
				})}
			</div>
		)
	}

	const ClickedNetWeight = (props) => {
		let data = props.data
		return (
			<div style={{ width: 300, padding: "5%" }}>
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Item
					</div>
					<div className={classes.blue}>
						Alloted Weight
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				{data.laQty.map((item, index) => {
					return (
						<div key={index + "LaQty"}>
							<Typography className={classes.dialogText}>
								<div className={classes.blue}>
									{item.item}
								</div>
								<div>
									{item.alloted_weight} MT
								</div>
							</Typography>
							<Divider
								variant='middle'
								style={{
									margin: '2% 0',
									display: index === ((data.laQty).length - 1) ? 'none' : 'block'
								}}
							/>
						</div>
					)
				})}
			</div>
		)
	}

	const ClickedWeighBridge = (props) => {
		let data = props.data;
		// console.log(data.loadingAdviceWeight[0])
		let IndexNumber = 0;
		return (
			<div style={{ width: 200, padding: "5%" }}>
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						Tare Weight
					</div>
					<div>
						{data.tareWeight}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				{data.loadingAdviceWeight[0].map((item, index) => {
					return !item.is_tare_weight && (!item.is_discarded && !item.is_unloaded) && (IndexNumber = IndexNumber + 1) && (
						<div key={index + "netWeight"}>
							<Typography className={classes.dialogText}>
								<div className={classes.blue}>
									Weight {IndexNumber}
								</div>
								<div>
									{item.loaded_weight}
								</div>
							</Typography>
							<Divider
								variant='middle'
								style={{
									margin: '2% 0',
									display: index === (data.loadingAdviceWeight[0].length - 1) ? 'none' : 'block'
								}}
							/>
						</div>
					)
				})}
			</div>
		)
	}
	const ClickedInvoice = (props) => {
		let data = props.data
		return (
			<div style={{ width: 250, padding: "5%" }}>
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						IRN Date
					</div>
					<div>
						{/* {console.log("irnDAte " + isNull(data.irnDate) + isUndefined(data.irnDate))} */}
						{/* {(_.isNull(data.irnDate)) && (_.isUndefined(data.irnDate)) ? moment(data.irnDate, ["DD/MM/YYYY hh:mm:ss A", "DD/MM/YYYY hh:mm:ss", "DD/MM/YYYY", "YYYY-MM-DD hh:mm:ss A", "YYYY-MM-DD", "YYYY-MM-DD hh:mm:ss"]).format("DD-MMM-YY") : " "} */}
						{(data.irnDate !== undefined && data.irnDate !== null) ?
							moment(data.irnDate,
								[
									"DD/MM/YYYY hh:mm:ss A",
									"DD/MM/YYYY hh:mm:ss",
									"DD/MM/YYYY",
									"YYYY-MM-DD hh:mm:ss A",
									"YYYY-MM-DD",
									"YYYY-MM-DD hh:mm:ss"
								]).format("DD-MMM-YY") :
							data.irnDate != null ?
								moment(data.irnDate,
									[
										"DD/MM/YYYY hh:mm:ss A",
										"DD/MM/YYYY hh:mm:ss",
										"DD/MM/YYYY",
										"YYYY-MM-DD hh:mm:ss A",
										"YYYY-MM-DD",
										"YYYY-MM-DD hh:mm:ss"
									]).format("DD-MMM-YY") :
								" "
						}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						EWB
					</div>
					<div>
						{data.ewayNo !== null ? data.ewayNo : " "}
					</div>
				</Typography>
				<Divider variant='middle' style={{ margin: '2% 0' }} />
				<Typography className={classes.dialogText}>
					<div className={classes.blue}>
						EWB Date
					</div>
					<div>
						{/* {console.log("ewayDAte " + data.ewayDate)} */}
						{/* {!(_.isNull(data.ewayDate)) && !(_.isUndefined(data.ewayDate)) ? moment(data.ewayDate, ["DD/MM/YYYY hh:mm:ss A", "DD/MM/YYYY hh:mm:ss", "DD/MM/YYYY", "YYYY-MM-DD hh:mm:ss A", "YYYY-MM-DD", "YYYY-MM-DD hh:mm:ss"]).format("DD-MMM-YY") : " "} */}
						{(data.ewayDate !== undefined && data.ewayDate !== null) ?
							moment(data.ewayDate,
								[
									"DD/MM/YYYY hh:mm:ss A",
									"DD/MM/YYYY hh:mm:ss",
									"DD/MM/YYYY",
									"YYYY-MM-DD hh:mm:ss A",
									"YYYY-MM-DD",
									"YYYY-MM-DD hh:mm:ss"
								]).format("DD-MMM-YY") :
							data.ewayDate != null ?
								moment(data.ewayDate,
									[
										"DD/MM/YYYY hh:mm:ss A",
										"DD/MM/YYYY hh:mm:ss",
										"DD/MM/YYYY",
										"YYYY-MM-DD hh:mm:ss A",
										"YYYY-MM-DD",
										"YYYY-MM-DD hh:mm:ss"
									]).format("DD-MMM-YY") :
								" "
						}
					</div>
				</Typography>
			</div>
		)
	}
	const ClickedPaymentTerms = (props) => {
		let logAfterBilledObject = [
			{ key: 'Approx', value: 'approx' },
			{ key: 'Invoice Amount', value: 'invoiceAmount' },
			{ key: 'Invoice Date', value: 'invoiceDate' },
			{ key: 'Due Date', value: 'dueDate' },
			{ key: 'Paid', value: 'paid' },
			{ key: 'Balance', value: 'balance' },
			{ key: 'Synced At', value: 'syncedAt' },
		]

		let logBeforeBilledObject = [
			{ key: 'Approx Amount', value: 'approx' },
			{ key: 'Approx Due Date', value: 'dueDate' },
			{ key: 'Advance Credit Limit (Available)', value: 'advanceCreditLimit' },
			// { key: 'Non-Advance Credit Limit (Available)', value: 'nonAdvanceCreditLimit' },
		]

		let data = props.data
		return (
			<div
				style={{
					width: ["BILLED", "DISPATCHED"].includes(props.row.Status) ? 280 : 380,
					padding: ["BILLED", "DISPATCHED"].includes(props.row.Status) ? "5%" : "3%"
				}}>
				{["BILLED", "DISPATCHED"].includes(props.row.Status)
					?
					logAfterBilledObject.map((item, index) =>
					(
						<>
							<Typography className={classes.dialogText}>
								<div className={classes.blue}>{item.key}</div>
								<div>
									{item.value === 'syncedAt'
										? handleDateFormat(data[item.value], 'DD-MMM-YY hh:mm A')
										: ["invoiceDate", "dueDate"].includes(item.value)
											? handleDateFormat(data[item.value], 'DD-MMM-YY')
											: ["invoiceAmount", "paid", "balance", "approx"].includes(item.value)
												? checkInvalidValue(data[item.value]) || Number(data[item.value]).toFixed(3) === Number(0).toFixed(3)
													? " "
													: <span style={{ display: 'flex', alignItems: 'center' }}>
														<CurrencyRupeeRoundedIcon style={{ transform: 'scale(0.6)', marginRight: '-7px', }} />
														{data[item.value]}
													</span>
												: data[item.value]
									}
								</div>
							</Typography>
							<Divider variant='middle' style={{ margin: '2% 0', display: index === logAfterBilledObject.length - 1 && 'none' }} />
						</>
					))
					:
					logBeforeBilledObject.map((item, index) =>
					(
						<>
							<Typography className={classes.dialogText}>
								<div className={classes.blue}>
									{item.value !== 'advanceCreditLimit'
										? item.key
										: Number(props.row?.PaymentTermsDays) === 0
											? item.key
											: 'Non-Advance Credit Limit (Available)'
									}
								</div>
								<div>
									{["dueDate"].includes(item.value)
										? handleDateFormat(data[item.value], 'DD-MMM-YY')
										: ["advanceCreditLimit", "approx"].includes(item.value)
											? checkInvalidValue(data[item.value]) || Number(data[item.value]).toFixed(3) === Number(0).toFixed(3)
												? " "
												: <span style={{ display: 'flex', alignItems: 'center' }}>
													<CurrencyRupeeRoundedIcon style={{ transform: 'scale(0.6)', marginRight: '-7px', }} />
													{data[item.value !== 'advanceCreditLimit'
														? item.value
														: Number(props.row?.PaymentTermsDays) === 0
															? item.value
															: 'nonAdvanceCreditLimit']}
												</span>
											: data[item.value]
									}
								</div>
							</Typography>
							<Divider variant='middle' style={{ margin: '2% 0', display: index === logBeforeBilledObject.length - 1 && 'none' }} />
						</>
					))
				}
			</div>
		)
	}

	const ActionMenu = (props) => {
		const classes = tableStyles();

		const [anchorEl, setAnchorEl] = useState(null);
		const [activeStep, setActiveStep] = React.useState(0);

		const [openDialog, setOpenDialog] = useState(false)
		const [openLaModificationDialog, setOpenLaModificationDialog] = useState(false)
		const [modificationRemark, setModificationRemark] = useState({
			modificationRemarkText: null,
			modificationRemarkFocus: false,
			modificationRemarkError: false,
		})
		const [openDiscardWeighDialog, setOpenDiscardWeighDialog] = useState(false)
		const [rejectLaRemark, setRejectLaRemark] = useState();
		const [laModifiedData, setLaModifiedData] = useState([]);
		const [laModifiedDataLoader, setLaModifiedDataLoader] = useState(false);
		const [discardWeighLoader, setDiscardWeighLoader] = useState(false);
		const [weighSlips, setWeighSlips] = useState([]);
		const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
		const [openUnloadConfirmDialog, setOpenUnloadConfirmDialog] = useState(props.openUnloadConfirmDialog);
		const [openReplaceLADialog, setOpenReplaceLADialog] = useState(false);
		const [openCancelLADialog, setOpenCancelLADialog] = useState(false);
		const [openNewReplacedLADialog, setOpenNewReplacedLADialog] = useState(props.openNewReplacedLADialog);
		const [openRollBackDialog, setOpenRollBackDialog] = React.useState(false);
		const [selectedItemId, setSelectedItemId] = useState(0);
		const [LA_Cancel_Reason, setLA_Cancel_Reason] = useState("");
		const [invoiceDetails, setinvoiceDetails] = useState({});
		const [invoiceDetailsLoader, setInvoiceDetailsLoader] = useState(false);
		const [steps, setSteps] = React.useState([]);
		const [showLoader, setShowLoader] = useState(false);
		const [openUnlockWB, setOpenUnlockWB] = useState(false);
		const [syncedClicked, setSyncedClicked] = useState(null);
		const [fetchSyncClicked, setFetchSyncClicked] = useState(null);

		useEffect(() => {
			if (openLaModificationDialog === true) {
				setLaModifiedDataLoader(false)
				fetchReviewModification(props.laNumber)
					.then(response => {
						// console.log(response)
						setLaModifiedData(response.data)
						setLaModifiedDataLoader(true)
					})
					.catch(e => console.log(e))
			}
		}, [openLaModificationDialog])

		useEffect(() => {
			if (openDiscardWeighDialog === true) {
				setDiscardWeighLoader(false)
				setStep(props.rowWeighSlips[0])
				setWeighSlips(props.rowWeighSlips)
				setDiscardWeighLoader(true)
			}
		}, [openDiscardWeighDialog])

		const setStep = (loadingAdviceWeighings) => {
			if (!loadingAdviceWeighings?.[loadingAdviceWeighings?.length - 1]?.is_discarded) {
				setActiveStep(loadingAdviceWeighings?.length - 1)
			} else {
				let nonDiscardedSteps = loadingAdviceWeighings?.filter(l => !l.is_discarded)
				let lastActiveStep = nonDiscardedSteps?.[nonDiscardedSteps?.length - 1];
				if (lastActiveStep == null || lastActiveStep == undefined) {
					lastActiveStep = 0;
				}
				let stepId = loadingAdviceWeighings.indexOf(lastActiveStep)
				setActiveStep(stepId);

			}
		}

		const handleSyncClick = (number) => {
			setSyncedClicked(number)
			// setTimeout(() => {
			syncPayment(number)
			// setSyncedClicked(null)
			// }, 1000)
		}

		const handleFetchSyncClick = (number) => {
			setFetchSyncClicked(number)
			props.handleFetchSalesReturn(props.laNumber, props.selectedDate, props.plantId)

		}

		const handlConfirmCloseDialog = () => {
			setOpenConfirmDialog(false);
		}

		const handleUnloadConfirmCloseDialog = () => {
			setOpenUnloadConfirmDialog(false)
			setLA_Cancel_Reason("");
		}

		const handleOpenLAReplaceDialog = () => {
			setOpenReplaceLADialog(true);
		};

		const handleCloseLAReplaceDialog = () => {
			setOpenReplaceLADialog(false);
			setLA_Cancel_Reason("");
		};

		const handleOpenCancelLADialog = () => {
			setOpenCancelLADialog(true);
		};

		const handleCloseCancelLADialog = () => {
			setOpenCancelLADialog(false);
			setLA_Cancel_Reason("");
		};

		const handleOpenNewLAReplacedDialog = () => {
			setOpenNewReplacedLADialog(true);
		};

		const handleCloseNewLAReplacedDialog = () => {
			props.hideLADetailsDialog();
		};

		const handleOpenRollBackDialog = () => {
			setOpenRollBackDialog(true);
		};

		const handleCloseRollBackDialog = () => {
			setOpenRollBackDialog(false);
		};

		const ClickedUnlockWeighbridge = () => {
			setOpenUnlockWB(true);
		}

		const handleCloseUnlockWB = () => {
			setOpenUnlockWB(false);
		}


		const handleUnload = () => {
			setOpenUnloadConfirmDialog(false)
			if (openUnloadConfirmDialog) {
				let laStatusUpdateData = "";
				if (props?.Status === "GATEIN") {
					laStatusUpdateData = {
						dispatch_status: "UNLOADED"
					}
				} else if (props?.Status === "APPROVED") {
					laStatusUpdateData = {
						dispatch_status: "CANCELLED"
					}
				} else {
					laStatusUpdateData = {
						dispatch_status: "UNLOADING"
					}
				}
				props.handleLaApprove(props.laId, laStatusUpdateData, props.selectedDate, props.plantId, LA_Cancel_Reason)
				// console.log(props)
			}



		}

		const handleDropdownChange = (e) => {
			console.log(e.target.value)
			setLA_Cancel_Reason(e.target.value);
		}

		const handleUnloadConfirmOpenDialog = () => {
			setOpenUnloadConfirmDialog(true)
		}

		const handleConfirmOpenDialog = (itemId) => {
			setSelectedItemId(itemId);
			setOpenConfirmDialog(true);
		}

		const handleDiscard = async (weighSlipId) => {
			setOpenConfirmDialog(false);
			if (openConfirmDialog) {
				await props?.handleDiscardWeighSlips(weighSlipId, props.selectedDate, props.plantId)
			}
		}

		const handleUnlock = async () => {
			setOpenUnlockWB(false);
			if (openUnlockWB) {
				await props?.handleUnlockWeighbridge(props.laNumber, props.selectedDate, props.plantId)
			}
		}


		const handlePendingQuantity = (orderNo, plantName = "", status = "") => {
			props.fetchPendingQuantity(orderNo)
				?.then((response) => {
					history?.push(
						{
							//pathname: `/internalContracts/order/${props?.order}/editLoadingAdvice/${props?.laId}`,
							pathname: props.module === "contract" ?
								`/internalContracts/order/${props?.order}/editLoadingAdvice/${props?.laId}` :
								`/internalLoadingAdvices/order/${props?.order}/editLoadingAdvice/${props?.laId}`,
							state: {
								pendingQuantity: response.data,
								plantId: props.plantId,
								plantName: plantName,
								date: props.selectedDate,
								status: props.Status ?? status,
								laQty: props.rowData.laQty,
								itemId: props.itemId,
								customerId: props.customerId,
								laId: props?.laId,
								itemTotalQuantity: props.itemTotalQuantity,
								orderNo: orderNo,
								paymentMethodId: props.paymentMethodId,
								buyerPinCode: props.buyerPinCode,
								plants: props.plants,
								orderStatus: props.orderStatus,
								isCreatedByCustomer: props.isCreatedByCustomer,
								laRowData: props.laRowData,
								created_from_subcontract: props.created_from_subcontract
							}
						}
					)
				})
				.catch((error) => {
					console.log(error)
				});
		}

		const open = Boolean(anchorEl);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleOpenDialog = () => {
			setOpenDialog(true)
		}
		const handleCloseDialog = () => {
			setOpenDialog(false)
		}

		const handlePrintButton = () => {
			handlePrintTable(props.laNumber, "PRINT")
		}

		const handlePrintLAOverviewBtn = () => {
			handlePrintLAOverviewTable(props.laNumber, "PRINT")
		}

		const handleOpenLaModificationDialog = () => {
			setOpenLaModificationDialog(true)
		}

		const handleCloseLaModificationDialog = () => {
			setOpenLaModificationDialog(false)
			setModificationRemark({
				modificationRemarkText: null,
				modificationRemarkFocus: false,
				modificationRemarkError: false,
			})
		}

		const handleModificationRemark = (e) => {
			const { value } = e.target
			if (value) {
				setModificationRemark({
					modificationRemarkText: value,
					modificationRemarkFocus: true,
					modificationRemarkError: false,
				})
			}
			else {
				setModificationRemark({
					modificationRemarkText: null,
					modificationRemarkFocus: true,
					modificationRemarkError: true,
				})
			}
		}

		const handleOpenDiscardWeighDialog = () => {
			setOpenDiscardWeighDialog(true)
		}

		const handleCloseDiscardWeighDialog = () => {
			setOpenDiscardWeighDialog(false)
		}

		const handleNext = () => {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
			setOpenConfirmDialog(false);

		};

		const getStepContent = (index, itemId) => {
			return (
				<div>
					<Button
						variant="contained"
						color="primary"
						className={classes.discardButton}
						onClick={() => handleConfirmOpenDialog(itemId)}
					//disabled={dispatchStatus !== "GATEIN" || buttonActions[0]}
					>
						Discard
					</Button>
				</div>
			);

		}



		return (
			<div>
				<Button
					id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					{props.icon}
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					{props.Status === "PENDING" ?
						(<div style={{}}>
							<ToolTipComponent
								title="You have no approve permission on this widget"
								condition={props.module === "contract"
									? !hasApproveAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
									: !hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')
								}
							>
								<MenuItem
									onClick={(e) => {
										e.preventDefault();
										if (props?.isCreatedByCustomer) {
											handlePendingQuantity(props.order, props.plantName, props.Status);
										} else {
											handleApproveClick(props.laId, props.selectedDate, props.plantId)
										}
									}}
									className={classes.menuText}
									disabled={
										props.module === "contract"
											? hasApproveAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ? false : true
											: hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')
												? false : true
									}
									style={{
										fontWeight: '600',
										// display: props.module === "contract"
										// ? hasApproveAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ? "flex" : "none"
										// : hasApproveAccess(props.user, props.plant_code + '_loadingAdvice') ? "flex" : "none",
										justifyContent: 'flex-start',
										fontSize: pxToEm(16),
										lineHeight: 1
									}}
								>
									{props.item1Icon}{props.item1}
								</MenuItem>
							</ToolTipComponent>
							<Divider
								variant='middle'
								style={{
									margin: '2% 0',
								}} />
							<ToolTipComponent
								title="You have no approve permission on this widget"
								condition={props.module === "contract"
									? !hasApproveAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`)
									: !hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')
								}
							>
								<MenuItem
									className={classes.menuText}
									disabled={
										props.module === "contract"
											? hasApproveAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ? false : true
											: hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')
												? false : true
									}
									style={{
										fontWeight: '600',
										// display: props.module === "contract"
										// ? hasApproveAccess(props.user, `${localStorage.getItem("itemCategoryCode")}-contracts`) ? "flex" : "none"
										// : hasApproveAccess(props.user, props.plant_code + '_loadingAdvice') ? "flex" : "none",
										justifyContent: 'flex-start',
										fontSize: pxToEm(16),
										lineHeight: 1
									}}
									onClick={handleOpenDialog}
								>
									{props.item2Icon}{props.item2}
								</MenuItem>
							</ToolTipComponent>
							<Divider
								variant='middle'
								style={{
									margin: '2% 0',
									display: props.module === "contract" ? 'block' : 'none'
								}}
							/>
							<Dialog
								open={openDialog}
								TransitionComponent={Transition}
								keepMounted
								onClose={handleCloseDialog}
							>
								<DialogTitle id="alert-dialog-title">
									Reject
									<p style={{ fontSize: pxToEm(10), margin: 0 }}>
										{'Loading Advice | ' + props.laNumber}
									</p>
								</DialogTitle>
								<DialogContent
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										height: 150,
										alignContent: 'center'
									}}
								>
									<p>Please give remark</p>
									<TextField
										required
										type="text"
										label="Remark "
										multiline
										autoFocus="autoFocus"
										rows={3}
										value={rejectLaRemark}
										onChange={(event) => setRejectLaRemark(event.target.value)}
										variant="outlined"
										size="small"
										style={{ width: '100%' }}
									/>
								</DialogContent>
								<DialogActions style={{ padding: '8px 20px' }}>
									{props.removeDataLoader ? (
										<CircularProgress
											disableShrink
											className={classes.loaderStyle}
										/>
									) : (
										<div>
											<Button
												onClick={handleCloseDialog}
												className={classes.margin}
												color="primary"
											>
												CANCEL
											</Button>
											<Button
												type="submit"
												form="myform"
												color="primary"
												onClick={() => handleConfirmationDialogDeleteClick(props.laId, rejectLaRemark, props.selectedDate, props.plantId)}
												// className={classes.removeButton}
												disabled={!rejectLaRemark ? true : false}
											>
												CONFIRM
											</Button>
										</div>
									)}
								</DialogActions>
							</Dialog>
						</div>
						)
						:
						null}
					<div>
						<Divider
							variant='middle'
							style={{
								margin: '2% 0',
								display: props.Status !== "PENDING" || !(hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')) | !props.module === "contract" ? 'none' : 'block'
							}} />
						<MenuItem
							className={classes.menuText}
							onClick={handlePrintButton}
							style={{
								fontWeight: '600',
								display: 'flex',
								justifyContent: 'flex-start',
								fontSize: pxToEm(16),
								lineHeight: 1
							}}
						>
							{props.item3Icon}{props.item3}
						</MenuItem>
						<Divider variant='middle' style={{ margin: '2% 0' }} />
						<MenuItem
							className={classes.menuText}
							onClick={handlePrintLAOverviewBtn}
							style={{
								fontWeight: '600',
								display: 'flex',
								justifyContent: 'flex-start',
								fontSize: pxToEm(16),
								lineHeight: 1
							}}
						>
							{props.item3Icon}{props.item12}
						</MenuItem>
						{
							!props?.isContract ?
								<ToolTipComponent
									title="You have no edit permission on this widget"
									condition={!hasUpdateAccess(props.user, props.plant_code + '_loadingAdvice')}
									title2={`LA status is ${props.Status}`}
									condition2={['BILLED',
										'DISPATCHED',
										'LAPSED',
										'UNLOADING',
										'UNLOADED',
										'REJECTED',
										'CANCELLED',
										'HOLD'].includes(props.Status)
									}
								>
									<Divider variant='middle' style={{ margin: '2% 0' }} />
									<MenuItem
										className={classes.menuText}
										style={{
											fontWeight: '600',
											display: 'flex',
											justifyContent: 'flex-start',
											fontSize: pxToEm(16),
											lineHeight: 1
										}}
										disabled={
											hasUpdateAccess(props.user, props.plant_code + '_loadingAdvice')
												? ['BILLED',
													'DISPATCHED',
													'LAPSED',
													'UNLOADING',
													'UNLOADED',
													'REJECTED',
													'CANCELLED',
													'HOLD'].includes(props.Status)
													? true
													:
													false
												: true

										}

										onClick={(e) => {
											e.preventDefault();
											handlePendingQuantity(props.order);
										}}
									>
										{props.item4Icon}{props.item4}
									</MenuItem>
								</ToolTipComponent>
								: <></>
						}
						{!props?.isContract && ["BILLED", "DISPATCHED"].includes(props.Status) &&
							<ToolTipComponent>
								<Divider
									variant='middle'
									style={{ margin: '2% 0', display: props.isContract && 'none' }}
								/>
								<MenuItem
									onClick={(e) => {
										e.preventDefault();
										handleSyncClick(props.laNumber)
									}}
									className={classes.menuText}
									style={{
										fontWeight: '600',
										justifyContent: 'flex-start',
										fontSize: pxToEm(16),
										lineHeight: 1,
									}}
								>
									<span style={{ animation: syncedClicked === props.laNumber && 'spin 1s infinite' }}>
										{props.item16Icon}
									</span>
									<span style={{ marginLeft: 8 }}>{props.item16}</span>
								</MenuItem>
							</ToolTipComponent>
						}
						{!props?.isContract && ["BILLED", "DISPATCHED", "APPROVED", "GATEIN", "LOADING", "PACKED", "ALLOTED"].includes(props.Status) &&
							<ToolTipComponent>
								<Divider
									variant='middle'
									style={{ margin: '2% 0', display: props.isContract && 'none' }}
								/>
								<MenuItem
									onClick={(e) => {
										e.preventDefault();
										history.push(
											{
												pathname: `/dispatchOperation/weighbridge/${props?.WeighbridgeID}`,
												state: {
													LANo: props?.laNumber,
													PlantFullName: props?.PlantFullName
												}
											},
										)
									}}
									className={classes.menuText}
									style={{
										fontWeight: '600',
										justifyContent: 'flex-start',
										fontSize: pxToEm(16),
										lineHeight: 1,
									}}
								>
									<span>
										{props.item18Icon}
									</span>
									<span style={{ marginLeft: 8 }}>{props.item18}</span>
								</MenuItem>
							</ToolTipComponent>
						}
						{!props?.isContract && ["BILLED", "DISPATCHED"].includes(props.Status) &&
							<>
								<Divider
									variant='middle'
									style={{ margin: '2% 0', display: props.isContract && 'none' }}
								/>
								<MenuItem
									onClick={(e) => {
										e.preventDefault();
										handleFetchSyncClick(props.laNumber)
										//props.handleFetchSalesReturn(props.laNumber, props.selectedDate, props.plantId)
									}}
									className={classes.menuText}
									style={{
										fontWeight: '600',
										justifyContent: 'flex-start',
										fontSize: pxToEm(16),
										lineHeight: 1,
									}}
								>
									<span style={{ animation: fetchSyncClicked === props.laNumber && 'spin 1s infinite' }}>
										{props.item17Icon}
									</span>
									<span style={{ marginLeft: 8 }}>{props.item17}</span>
								</MenuItem>
							</>
						}
						{
							(!props?.isContract && props.Status === "LOADING") ?
								<>
									<ToolTipComponent
										title="You have no special permission on this widget"
										condition={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
									>
										<Divider
											variant='middle'
											style={{
												margin: '2% 0',
											}}
										/>
										<MenuItem
											className={classes.menuText}
											style={{
												fontWeight: '600',
												display: 'flex',
												justifyContent: 'flex-start',
												fontSize: pxToEm(16),
												lineHeight: 1
											}}
											disabled={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
											onClick={(e) => {
												e.preventDefault();
												ClickedUnlockWeighbridge();
											}}
										>
											{props.item13Icon} {props.item13}

										</MenuItem>
									</ToolTipComponent>
									<Dialog
										open={openUnlockWB}
										onClose={handleCloseUnlockWB}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
										<DialogContent>
											<DialogContentText id="alert-dialog-description">
												Are you sure you want to unlock the weighbridge?
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={handleCloseUnlockWB}>
												Cancel
											</Button>
											<Button onClick={() => handleUnlock()} autoFocus
												style={{
													backgroundColor:
														primaryColor,
													color: drawerTextColor,
													textTransform: 'none',
													padding: '6px 25px'
												}}
											>
												Yes
											</Button>
										</DialogActions>
									</Dialog>
								</>

								: <></>
						}
						{
							!props?.isContract && props?.Status === "LOADING" ?
								<ToolTipComponent
									title="You have no special permission on this widget"
									condition={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
								>
									<Divider
										variant='middle'
										style={{
											margin: '2% 0',
										}}
									/>
									<MenuItem
										className={classes.menuText}
										style={{
											fontWeight: '600',
											display: 'flex',
											justifyContent: 'flex-start',
											fontSize: pxToEm(16),
											lineHeight: 1
										}}
										disabled={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
										onClick={(e) => {
											e.preventDefault();
											handleOpenDiscardWeighDialog();
										}}
									>
										{props.item6Icon}{props.item6}
									</MenuItem>
								</ToolTipComponent>
								: <></>
						}
						{
							(!props?.isContract && (props.Status === "PACKED" || props.Status === "ALLOTED")) ?
								<>
									<ToolTipComponent
										title="You have no special permission on this widget"
										condition={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
									>
										<Divider
											variant='middle'
											style={{
												margin: '2% 0',
											}}
										/>
										<MenuItem
											className={classes.menuText}
											style={{
												fontWeight: '600',
												display: 'flex',
												justifyContent: 'flex-start',
												fontSize: pxToEm(16),
												lineHeight: 1
											}}
											disabled={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
											onClick={(e) => {
												e.preventDefault();
												handleOpenRollBackDialog();


											}}
										>
											{props.item7Icon} {props.Status === "ALLOTED" ? `${props.item7} TO PACKED` : `${props.item7} TO LOADING`}

										</MenuItem>
									</ToolTipComponent>
									<Dialog
										open={openRollBackDialog}
										onClose={handleCloseRollBackDialog}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">
											{"Please Confirm"}
										</DialogTitle>
										<DialogContent>
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												<span>
													Are you sure you want to change the status of this LA?
												</span>
											</div>
										</DialogContent>
										<DialogActions>
											<Button onClick={handleCloseRollBackDialog}>NO</Button>
											<Button onClick={(e) => {
												handleCloseRollBackDialog();
												let rollbackStatus = props.Status === "ALLOTED" ? "PACKED" : "LOADING";
												props.handleChangeLAStatus(props.laNumber, rollbackStatus, props.selectedDate, props.plantId);
												// handleOpenRollBackDialog(false); 
											}} autoFocus
												style={{
													backgroundColor:
														primaryColor,
													color: drawerTextColor,
													textTransform: 'none',
													padding: '6px 25px'
												}}
											>
												YES
											</Button>
										</DialogActions>
									</Dialog>
								</>

								: <></>
						}
						{!props?.isContract &&
							!["HOLD", "PENDING", "UNLOADING", "UNLOADED", "LAPSED", "REJECTED", "BILLED", "DISPATCHED", "CANCELLED"].includes(props.Status)
							?
							<>
								<ToolTipComponent
									title="You have no approve permission on this widget"
									condition={props.Status === "GATEIN" && !hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')}
									title2="You have no special permission on this widget"
									condition2={!["HOLD", "GATEIN", "PENDING", "UNLOADING", "UNLOADED", "LAPSED", "REJECTED", "BILLED", "DISPATCHED", "CANCELLED"].includes(props.Status) &&
										!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
								>
									<Divider
										variant='middle'
										style={{ margin: '2% 0', }}
									/>
									<MenuItem
										className={classes.menuText}
										style={{
											fontWeight: '600',
											display: 'flex',
											justifyContent: 'flex-start',
											fontSize: pxToEm(16),
											lineHeight: 1
										}}
										disabled={props.Status === "GATEIN"
											? !hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')
											: !["HOLD", "GATEIN", "PENDING", "UNLOADING", "UNLOADED", "LAPSED", "REJECTED", "BILLED", "DISPATCHED", "CANCELLED"].includes(props.Status) &&
											!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')
										}
										onClick={(e) => {
											setOpenUnloadConfirmDialog(true)

										}}
									>
										{props.item2Icon}
										{["LOADING", "PACKED", "ALLOTED"].includes(props.Status)
											? `${props.item9}`
											: props.Status === "GATEIN"
												? `${props.item14}`
												: `${props.item8}`
										}
									</MenuItem>
								</ToolTipComponent>
								<Dialog
									open={openUnloadConfirmDialog}
									onClose={handleUnloadConfirmCloseDialog}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									fullWidth
									maxWidth="xs"
								>
									<DialogTitle id="alert-dialog-title">
										{"Please Confirm"}
									</DialogTitle>

									<DialogContent>
										<div style={{
											minWidth: 340,
											margin: '5px 0 15px',
										}}>
											<FormControl
												fullWidth
												size="small"
												variant="outlined"
											>
												<InputLabel id="shortClose" style={{ marginLeft: "28px" }}>
													Select Cancellation Reason*
												</InputLabel>
												<Select
													id="Cancellation Reason"
													labelId="CancelLA"
													variant="outlined"
													label=" Select Cancellation Reason*"
													name="Cancel_LA_Reason"
													style={{ width: 340, marginLeft: "28px" }}
													MenuProps={{
														PaperProps: {
															style: {
																maxHeight: 45 * 4 + 10,
															},
														},
														getContentAnchorEl: null,
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",
														}
													}}
													value={LA_Cancel_Reason}
													onChange={(e) => { handleDropdownChange(e) }}
												>
													{props?.reasonMaster !== undefined && props?.reasonMaster.length > 0
														? props?.reasonMaster?.map((data) => (
															<MenuItem key={data.reason} value={data?.id}>
																{data?.reason}
															</MenuItem>
														))
														:
														<CircularProgress
															disableShrink
															className={classes.loaderStyle}
															style={{ marginLeft: 10 }}
														/>
													}
												</Select>
											</FormControl>
										</div>

										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<span>
												Are you sure you want to cancel this LA?
											</span>
										</div>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleUnloadConfirmCloseDialog}>NO</Button>
										<Button
											variant="contained"
											style={{
												backgroundColor:
													LA_Cancel_Reason
														? primaryColor
														: 'rgba(0, 0, 0, 0.26)',

												color: drawerTextColor,
												textTransform: 'none',
												padding: '6px 25px'
											}}
											disabled={!LA_Cancel_Reason}
											onClick={handleUnload}>YES</Button>

									</DialogActions>

								</Dialog>

							</>

							: <></>
						}
						<Divider
							variant='middle'
							style={{
								margin: '2% 0',
								display: !props?.isContract && (props.Status === "BILLED" || props.Status === "DISPATCHED")

							}}
						/>
						{
							(props.Status === "BILLED" || props.Status === "DISPATCHED") ?
								<>
									<MenuItem
										className={classes.menuText}
										style={{
											fontWeight: '600',
											display: 'flex',
											justifyContent: 'flex-start',
											fontSize: pxToEm(16),
											lineHeight: 1
										}}


										onClick={(e) => {
											//setOpenUnloadConfirmDialog(true)
											props.handleBill(props.laNumber)
										}}
									>
										{props.item3Icon}
										{props.item15}
									</MenuItem>
								</>

								: <></>
						}
						{
							(!props?.isContract && (props.Status === "BILLED" || props.Status === "DISPATCHED")) && (props.lastUpdatedDiffinHrs <= 86400) ?
								<>
									<ToolTipComponent
										title="You have no special permission on this widget"
										condition={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
									>
										<Divider
											variant='middle'
											style={{ margin: '2% 0', }}
										/>
										<MenuItem
											className={classes.menuText}
											style={{
												fontWeight: '600',
												display: 'flex',
												justifyContent: 'flex-start',
												fontSize: pxToEm(16),
												lineHeight: 1
											}}
											disabled={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
											onClick={(e) => {
												handleOpenLAReplaceDialog(true)
												setInvoiceDetailsLoader(false);
												props.getInvoiceDetails(props.laNumber)
													.then(response => {
														//console.log(response.data)
														setinvoiceDetails(response.data)
														setInvoiceDetailsLoader(true);

													})
													.catch((error) => {
														//console.log(error)
													})

											}}
										>
											{props.item7Icon}{props.item10}
										</MenuItem>
									</ToolTipComponent>
									<Dialog
										open={openReplaceLADialog}
										onClose={handleCloseLAReplaceDialog}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
										fullWidth
										maxWidth="xs"
									>
										<DialogTitle id="alert-dialog-title">
											{"Please Confirm"}
										</DialogTitle>
										{invoiceDetailsLoader ? <>
											<DialogContent>
												<Paper
													style={{
														width: "100%",
														padding: "10px 5px 5px",
														boxShadow:
															"rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
													}}
												>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>E-Invoice Created</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.is_invoice_created ? "YES" : "NO"}
														</span>
													</div>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>Invoice Type</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.invoice_type}
														</span>
													</div>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>Invoice Case</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.invoice_case}
														</span>
													</div>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>EWay bill Created</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.is_eway_created ? "YES" : "NO"}
														</span>
													</div>
												</Paper>

												<div style={{
													minWidth: 340,
													margin: '20px 0 15px',
												}}>
													<FormControl
														fullWidth
														size="small"
														variant="outlined"
													>
														<InputLabel id="shortClose" style={{ marginLeft: "28px" }}>
															Select Cancellation Reason*
														</InputLabel>
														<Select
															id="Cancellation Reason"
															labelId="CancelLA"
															variant="outlined"
															label=" Select Cancellation Reason*"
															name="Cancel_LA_Reason"
															style={{ width: 340, marginLeft: "28px" }}
															MenuProps={{
																PaperProps: {
																	style: {
																		maxHeight: 45 * 4 + 10,
																	},
																},
																getContentAnchorEl: null,
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left",
																}
															}}
															value={LA_Cancel_Reason}
															onChange={(e) => { handleDropdownChange(e) }}
														>
															{props?.reasonMaster !== undefined && props?.reasonMaster.length > 0
																? props?.reasonMaster?.map((data) => (
																	<MenuItem key={data.reason} value={data?.id}>
																		{data?.reason}
																	</MenuItem>
																))
																:
																<CircularProgress
																	disableShrink
																	className={classes.loaderStyle}
																	style={{ marginLeft: 10 }}
																/>
															}
														</Select>
													</FormControl>
												</div>

												<div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
													<span>
														Are you sure you want to cancel this LA?
													</span>
												</div>
											</DialogContent></>
											:
											(
												<Stack alignItems="center">
													<CircularProgress
														disableShrink
														className={classes.loaderStyle}
													/>
												</Stack>
											)
										}
										<DialogActions>
											<Button onClick={handleCloseLAReplaceDialog}>NO</Button>
											<Button
												variant="contained"
												style={{
													backgroundColor:
														LA_Cancel_Reason
															? primaryColor
															: 'rgba(0, 0, 0, 0.26)',

													color: drawerTextColor,
													textTransform: 'none',
													padding: '6px 25px'
												}}
												disabled={!LA_Cancel_Reason}
												onClick={async (e) => {
													e.preventDefault();
													setShowLoader(true);
													await props.handleLAReplacement(props.laNumber, props.selectedDate, props.plantId, LA_Cancel_Reason);
												}}
											>
												{
													showLoader ?
														<>
															<CircularProgress
																size={25}
																style={{ color: "#FFFFFF" }}
															/>
														</> :
														"YES"
												}

											</Button>

										</DialogActions>

									</Dialog>

								</>


								: <></>
						}
						{
							(!props?.isContract && (props.Status === "BILLED" || props.Status === "DISPATCHED")) && (props.lastUpdatedDiffinHrs <= 86400) ?
								<>
									<ToolTipComponent
										title="You have no special permission on this widget"
										condition={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
									>
										<Divider
											variant='middle'
											style={{ margin: '2% 0', }}
										/>
										<MenuItem
											className={classes.menuText}
											style={{
												fontWeight: '600',
												display: 'flex',
												justifyContent: 'flex-start',
												fontSize: pxToEm(16),
												lineHeight: 1
											}}
											disabled={!hasSpecialAccess(props.user, props.plant_code + '_loadingAdvice')}
											onClick={(e) => {
												handleOpenCancelLADialog(true)
												setInvoiceDetailsLoader(false);
												props.getInvoiceDetails(props.laNumber)
													.then(response => {
														//console.log(response.data)
														setinvoiceDetails(response.data)
														setInvoiceDetailsLoader(true);

													})
													.catch((error) => {
														//console.log(error)
													})

											}}
										>
											{props.item2Icon}{props.item11}
										</MenuItem>
									</ToolTipComponent>
									<Dialog
										open={openCancelLADialog}
										onClose={handleCloseCancelLADialog}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
										fullWidth
										maxWidth="xs"
									>
										<DialogTitle id="alert-dialog-title">
											{"Please Confirm"}
										</DialogTitle>
										{invoiceDetailsLoader ? <>
											<DialogContent>
												<Paper
													style={{
														width: "100%",
														padding: "10px 5px 5px",
														boxShadow:
															"rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
													}}
												>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>E-Invoice Created</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.is_invoice_created ? "YES" : "NO"}
														</span>
													</div>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>Invoice Type</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.invoice_type}
														</span>
													</div>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>Invoice Case</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.invoice_case}
														</span>
													</div>
													<div style={{ padding: "5px" }}>
														<span style={{ color: "#839192" }}>EWay bill Created</span>
														<span style={{ float: "right" }}>
															{invoiceDetails?.is_eway_created ? "YES" : "NO"}
														</span>
													</div>
												</Paper>

												<div style={{
													minWidth: 340,
													margin: '20px 0 15px',
												}}>
													<FormControl
														fullWidth
														size="small"
														variant="outlined"
													>
														<InputLabel id="shortClose" style={{ marginLeft: "28px" }}>
															Select Cancellation Reason*
														</InputLabel>
														<Select
															id="Cancellation Reason"
															labelId="CancelLA"
															variant="outlined"
															label=" Select Cancellation Reason*"
															name="Cancel_LA_Reason"
															style={{ width: 340, marginLeft: "28px" }}
															MenuProps={{
																PaperProps: {
																	style: {
																		maxHeight: 45 * 4 + 10,
																	},
																},
																getContentAnchorEl: null,
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left",
																}
															}}
															value={LA_Cancel_Reason}
															onChange={(e) => { handleDropdownChange(e) }}
														>
															{props?.reasonMaster !== undefined && props?.reasonMaster.length > 0
																? props?.reasonMaster?.map((data) => (
																	<MenuItem key={data.reason} value={data?.id}>
																		{data?.reason}
																	</MenuItem>
																))
																:
																<CircularProgress
																	disableShrink
																	className={classes.loaderStyle}
																	style={{ marginLeft: 10 }}
																/>
															}
														</Select>
													</FormControl>
												</div>

												<div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
													<span>
														Are you sure you want to cancel this LA?
													</span>
												</div>
											</DialogContent></>
											:
											(
												<Stack alignItems="center">
													<CircularProgress
														disableShrink
														className={classes.loaderStyle}
													/>
												</Stack>
											)
										}
										<DialogActions>
											<Button onClick={handleCloseCancelLADialog}>NO</Button>
											<Button
												variant="contained"
												style={{
													backgroundColor:
														LA_Cancel_Reason
															? primaryColor
															: 'rgba(0, 0, 0, 0.26)',

													color: drawerTextColor,
													textTransform: 'none',
													padding: '6px 25px'
												}}
												disabled={!LA_Cancel_Reason}
												onClick={async (e) => {
													e.preventDefault();
													setShowLoader(true);
													await props.handleLACancelAfterBilled(props.laNumber, props.selectedDate, props.plantId, LA_Cancel_Reason)
												}}
											>
												{
													showLoader ?
														<>
															<CircularProgress
																size={25}
																style={{ color: "#FFFFFF" }}
															/>
														</> :
														"YES"
												}

											</Button>

										</DialogActions>

									</Dialog>

								</>


								: <></>
						}
						<ToolTipComponent
							title="You have no approve permission on this widget"
							condition={!hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')}
						>
							<MenuItem
								className={classes.menuText}
								style={{
									fontWeight: '600',
									display: props.Status === "HOLD"
										? 'flex'
										: 'none',
									justifyContent: 'flex-start',
									fontSize: pxToEm(16),
									lineHeight: 1
								}}
								disabled={!hasApproveAccess(props.user, props.plant_code + '_loadingAdvice')}
								onClick={() => {
									handleOpenLaModificationDialog();
								}}
							>
								{props.item5Icon}{props.item5}
							</MenuItem>
						</ToolTipComponent>
						<Dialog
							open={openLaModificationDialog}
							TransitionComponent={Transition}
							keepMounted
							onClose={handleCloseLaModificationDialog}
						>
							<DialogTitle>
								{/* Please Review The Changes Of LA */}
								<p style={{ fontSize: pxToEm(12), margin: 0 }}>{`${props.laNumber}`}</p>
								<p style={{ fontSize: pxToEm(10), margin: 0 }}>Edited by {
									laModifiedDataLoader && laModifiedData?.length > 0
										? (laModifiedData[0].requested_by) + " | " + moment(laModifiedData[0].created_at).format("hh:mm A") + " " + moment(laModifiedData[0].created_at).format("Do-MMM-YY")
										: null
								}
								</p>
							</DialogTitle>
							<DialogContent className={classes.dialogBox}>
								<div className={classes.dialogText}>
									<span className={classes.blue} style={{ width: 150, fontSize: pxToEm(16), fontWeight: 800 }}>
										Fields Name
									</span>
									<span className={classes.blue} style={{ width: 190, textAlign: 'center', fontSize: pxToEm(16), fontWeight: 800 }}>
										Old Value
									</span>
									<span className={classes.blue} style={{ width: 170, textAlign: 'end', fontSize: pxToEm(16), fontWeight: 800, marginRight: 16 }}>
										New Value
									</span>
								</div>
								<Divider variant='middle' style={{ margin: '2% 0', height: 3, marginRight: 16 }} />
								{laModifiedDataLoader && laModifiedData?.length > 0 ?
									<>
										<div
											tabIndex={0}
											style={{
												// width: "100%",
												height: 200,
												overflowY: "scroll",
											}}
										>
											{laModifiedData[0].fields.slice(1,).map((item) => {
												return (
													<div style={{ marginRight: 10 }}>
														<div className={classes.dialogText}>
															<span
																className={classes.blue}
																style={{ width: 150 }}
															>
																{startCase(item[0] === "fright"
																	? "freight_basis"
																	: item[0] === "fright_advance"
																		? "freight_advance"
																		: item[0] === "lr_date"
																			? "LR Date"
																			: item[0] === "lc_date"
																				? "LC Date"
																				: item[0] === "lr_number"
																					? "LR Number"
																					: item[0] === "lc_number"
																						? "LC Number"
																						: item[0]
																)}
															</span>
															{/* {console.log(item[1] === null || item[1] === " ")} */}
															<span style={{ width: 190, textAlign: 'center' }}>
																{
																	item[1] === "" || item[1] === null
																		? "---"
																		: ["gate_in_date", "lr_date", "lc_date"].includes(item[0])
																			? moment(item[1]).format("Do MMM YY")
																			: item[0] === "truck_capacity"
																				? item[1] + " MT"
																				: item[1]
																}
															</span>
															<span
																className={classes.orange}
																style={{ width: 170, textAlign: 'end' }}
															>
																{
																	item[2] === "" || item[2] === null
																		? "---"
																		: ["gate_in_date", "lr_date", "lc_date"].includes(item[0])
																			? moment(item[2]).format("Do MMM YY")
																			: item[0] === "truck_capacity"
																				? item[2] + " MT"
																				: item[2]
																}
															</span>
														</div>
														<Divider variant='middle' style={{ margin: '2% 0' }} />
													</div>
												)
											})
											}
											{(laModifiedData[0]?.items !== undefined && laModifiedData[0]?.items.filter(d => (d.fields).length > 0).length > 0) &&
												<div className={classes.dialogText}>
													<span
														className={classes.blue}
														style={{ width: 200 }}
													>
														Items
													</span>
												</div>
											}
											{(laModifiedData[0]?.items !== undefined && (laModifiedData[0]?.items).length > 0) &&
												laModifiedData[0]?.items.filter(d => (d.fields).length > 0).map((item, index) => {
													{/* console.log(props.rowData.laQty) */ }
													const LaItem = props.rowData.laQty.find(data => data.id === item.item_id)
													return (
														<div style={{ marginRight: 10 }}>
															<div className={classes.dialogText}>
																<span
																	className={classes.blue}
																	style={{ width: 200, margin: 5 }}
																>
																	{index + 1}.
																	<Chip
																		label={item.operation}
																		size="small"
																		style={{
																			color: item.operation === "DELETED" ? 'red' : item.operation === "ADDED" ? '#329232' : "#FFAE42",
																			backgroundColor: '#EEE',
																			marginLeft: 5,
																			// height: 16,
																			// fontSize: pxToEm(14)
																		}}
																	/>
																</span>
																{item.item_id !== null ?
																	(<>
																		<span style={{ width: 170, textAlign: 'end', margin: 5 }}>
																			{item?.fields.length > 0 ?
																				Boolean(item?.fields[0].find(data => data === "item")) ||
																					item?.fields.length > 1 &&
																					Boolean(item?.fields[1].find(data => data === "item"))
																					? item.fields.map(row => {
																						return row[0] === "item" &&
																							(
																								<p style={{ margin: 0 }}>
																									{row[1]}
																								</p>
																							)
																					})
																					:
																					(
																						<p style={{ margin: 0 }}>
																							{LaItem?.item !== undefined ? LaItem.item : "  "}
																						</p>
																					)
																				:
																				null
																			}
																			{item?.fields.length > 0 ?
																				Boolean(item?.fields[0].find(data => data === "item_qty")) ||
																					item?.fields.length > 1 &&
																					Boolean(item?.fields[1].find(data => data === "item_qty"))
																					? item.fields.map(row => {
																						return row[0] === "item_qty" &&
																							(
																								<p style={{ margin: 0 }}>
																									{row[1]} MT
																								</p>
																							)
																					})
																					:
																					(
																						<p style={{ margin: 0 }}>
																							{LaItem.item_qty} MT
																						</p>
																					)
																				:
																				null
																			}
																		</span>
																		<span style={{ width: 170, textAlign: 'end', margin: 5 }}>
																			{item?.fields.length > 0 ?
																				Boolean(item?.fields[0].find(data => data === "item")) ||
																					item?.fields.length > 1 &&
																					Boolean(item?.fields[1].find(data => data === "item"))
																					? item.fields.map(row => {
																						return row[0] === "item" &&
																							(
																								<p
																									className={classes.orange}
																									style={{ margin: 0 }}
																								>
																									{item.operation === "DELETED"
																										? "---"
																										: row[2]
																									}
																								</p>
																							)
																					})
																					:
																					(
																						<p style={{ margin: 0 }}>
																							{LaItem?.item !== undefined ? LaItem.item : "  "}
																						</p>
																					)
																				:
																				null
																			}
																			{item?.fields.length > 0 ?
																				Boolean(item?.fields[0].find(data => data === "item_qty")) ||
																					item?.fields.length > 1 &&
																					Boolean(item?.fields[1].find(data => data === "item_qty"))
																					? item.fields.map(row => {
																						return row[0] === "item_qty" &&
																							(
																								<p
																									className={classes.orange}
																									style={{ margin: 0 }}
																								>
																									{item.operation === "DELETED"
																										? " "
																										: row[2] + " MT"
																									}
																								</p>
																							)
																					})
																					:
																					(
																						<p style={{ margin: 0 }}>
																							{LaItem.item_qty} MT
																						</p>
																					)
																				:
																				null
																			}
																		</span>
																	</>
																	)
																	:
																	(<>
																		<span style={{ textAlign: 'end', margin: 5 }}>
																			{Boolean(item?.fields[0].find(data => data === "item")) ||
																				item?.fields.length > 1 &&
																				Boolean(item?.fields[1].find(data => data === "item"))
																				? item.fields.map(row => {
																					return row[0] === "item" &&
																						(
																							<p style={{ margin: 0 }}>
																								---
																							</p>
																						)
																				})
																				:
																				(
																					<p style={{ margin: 0 }}>

																					</p>
																				)
																			}
																			{Boolean(item?.fields[0].find(data => data === "item_qty")) ||
																				item?.fields.length > 1 &&
																				Boolean(item?.fields[1].find(data => data === "item_qty"))
																				? item.fields.map(row => {
																					return row[0] === "item_qty" &&
																						(
																							<p style={{ margin: 0 }}>

																							</p>
																						)
																				})
																				:
																				(
																					<p style={{ margin: 0 }}>

																					</p>
																				)
																			}
																		</span>
																		<span style={{ width: 170, textAlign: 'end', margin: 5 }}>
																			{Boolean(item?.fields[0].find(data => data === "item")) ||
																				item?.fields.length > 1 &&
																				Boolean(item?.fields[1].find(data => data === "item"))
																				? item.fields.map(row => {
																					return row[0] === "item" &&
																						(
																							<p
																								className={classes.orange}
																								style={{ margin: 0 }}
																							>
																								{row[2]}
																							</p>
																						)
																				})
																				:
																				(
																					<p style={{ margin: 0 }}>
																						{LaItem?.item !== undefined ? LaItem.item : "  "}
																					</p>
																				)
																			}
																			{Boolean(item?.fields[0].find(data => data === "item_qty")) ||
																				item?.fields.length > 1 &&
																				Boolean(item?.fields[1].find(data => data === "item_qty"))
																				? item.fields.map(row => {
																					return row[0] === "item_qty" &&
																						(
																							<p
																								className={classes.orange}
																								style={{ margin: 0 }}
																							>
																								{row[2]} MT
																							</p>
																						)
																				})
																				:
																				(
																					<p style={{ margin: 0 }}>
																						{LaItem.item_qty} MT
																					</p>
																				)
																			}
																		</span>
																	</>

																	)}
															</div>
															<Divider variant='middle' style={{ margin: '2% 0' }} />
														</div>
													)
												})
											}
										</div>
										<Divider variant='middle' style={{ margin: '2% 0', height: 4 }} />
										<div>
											<TextField
												id="outlined-multiline-static"
												label="Remark"
												variant="outlined"
												focused={modificationRemark.modificationRemarkFocus || false}
												multiline
												minRows={2}
												onChange={handleModificationRemark}
												value={modificationRemark.modificationRemarkText}
												style={{
													width: '100%'
												}}
												error={modificationRemark.modificationRemarkError}
												placeholder="Remark is compulsory to reject changes"
											/>
										</div>
									</>
									:
									(<CircularProgress
										disableShrink
										className={classes.loaderStyle}
									/>)
								}
							</DialogContent>
							<DialogActions>
								<div>
									<Button
										onClick={handleCloseLaModificationDialog}
										className={classes.margin}
									>
										CANCEL
									</Button>
									<Button
										type="submit"
										onClick={() => {
											if (modificationRemark.modificationRemarkText !== null) {
												handleConfirmationLaModification(laModifiedData[0].id, "reject", modificationRemark.modificationRemarkText)
											}
											else {
												setModificationRemark({
													modificationRemarkText: null,
													modificationRemarkFocus: true,
													modificationRemarkError: true,
												})
											}
										}
										}
										className={classes.removeButton}
										disabled={laModifiedData?.length > 0 ? false : true}

									>
										REJECT CHANGES
									</Button>
									<Button
										type="submit"
										onClick={() => handleConfirmationLaModification(laModifiedData[0].id, "approve", modificationRemark.modificationRemarkText)}
										className={classes.successButton}
										disabled={laModifiedData?.length > 0 ? false : true}

									>
										APPROVE CHANGES
									</Button>
								</div>

							</DialogActions>

						</Dialog>
						<Dialog
							open={openDiscardWeighDialog}
							TransitionComponent={Transition}
							keepMounted
							onClose={handleCloseDiscardWeighDialog}
							PaperProps={{
								style: {
									minWidth: '575px',
									maxHeight: '54%',
									minHeight: "382px",
									overflowY: 'scroll'
								}
							}}
						>
							<DialogTitle>
								<p style={{ fontSize: pxToEm(12), margin: 0 }}>{`${props.laNumber}`}</p>


							</DialogTitle>
							{discardWeighLoader ?
								<>
									<DialogContent className={classes.dialogBox}>
										<Stepper activeStep={activeStep} orientation="vertical">
											{weighSlips[0]?.map((item, index) => (
												<Step key={index} completed={false}>
													<StepLabel
														StepIconComponent={item.is_discarded && CustomStepIcon}
														className={
															classes.stepIconRoot
														}
													>
														<Paper
															style={{
																width: "100%",
																padding: "10px 5px 5px",
																//marginBottom: "40px",
																boxShadow:
																	"rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
															}}
														>



															<div style={{ padding: "5px" }}>
																<span style={{ color: "#839192", fontWeight: "500" }}>Weighslip Number</span>
																<span style={{ float: "right", fontWeight: "500" }} >
																	{item?.id}
																</span>
															</div>

															<div style={{ padding: "5px" }}>
																<span style={{ color: "#839192", fontWeight: "500" }}>Date and Time</span>
																<span style={{ float: "right", fontWeight: "500" }}>
																	{moment(
																		item?.created_at
																	).format("DD-MMM-YYYY LT")}
																</span>
															</div>
															<div style={{ padding: "5px" }}>
																<span style={{ color: "#839192", fontWeight: "500" }}>{item?.is_tare_weight ? "Tare Weight" : "Weight"}</span>
																<span style={{ float: "right", fontWeight: "500" }}>
																	{item?.loaded_weight} MT
																</span>
															</div>
															{item?.is_discarded ?
																<>
																	<Divider style={{ width: "100%" }} />
																	<div style={{ padding: "5px" }}>
																		<span style={{ color: "#839192" }}>Discarded By</span>
																		<span style={{ float: "right" }}>
																			{
																				item ? item?.discarded_by
																					: ""}
																		</span>
																	</div>
																	<div style={{ padding: "5px" }}>
																		<span style={{ color: "#839192" }}>Discarded At</span>
																		<span style={{ float: "right" }}>
																			{item
																				? moment(item?.discarded_at).format("DD-MMM-YYYY LT")
																				: ""}
																		</span>
																	</div>
																</>
																: ""
															}

														</Paper>
													</StepLabel>
													<StepContent>
														{getStepContent(index, item?.id)}
													</StepContent>
												</Step>
											))}
										</Stepper>
									</DialogContent>

									<DialogActions>
										<div>
											<Button
												onClick={handleCloseDiscardWeighDialog}
												className={classes.margin}
											>
												CANCEL
											</Button>
										</div>
									</DialogActions>
								</>
								:
								(
									<Stack alignItems="center">
										<CircularProgress
											disableShrink
											className={classes.loaderStyle}
										/>
									</Stack>
								)
							}
						</Dialog>
						<>
							<Dialog
								open={openConfirmDialog}
								onClose={handlConfirmCloseDialog}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"

							>
								<DialogTitle id="alert-dialog-title">
									{"Please Confirm"}
								</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										Are you sure you want to discard?
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={handlConfirmCloseDialog}>Cancel</Button>
									<Button onClick={() => handleDiscard(selectedItemId)} autoFocus
										style={{
											backgroundColor:
												primaryColor,
											color: drawerTextColor,
											textTransform: 'none',
											padding: '6px 25px'
										}}
									>
										Discard
									</Button>

								</DialogActions>
							</Dialog>

						</>
					</div>
				</Menu>
				<Dialog
					open={openNewReplacedLADialog}
					onClose={handleCloseNewLAReplacedDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth
					maxWidth="xs"
					hideBackdrop
					PaperProps={{
						elevation: 5,
					}}
				>
					<DialogTitle id="alert-dialog-title">
						{"New LA Details"}
					</DialogTitle>
					<>
						<DialogContent>
							<Paper
								style={{
									width: "100%",
									padding: "10px 5px 5px",
									boxShadow:
										"rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
								}}
							>
								<div style={{ padding: "5px" }}>
									<span style={{ color: "#839192" }}>Replacement of</span>
									<span style={{ float: "right" }}>
										{props.replacedLADetails?.replacement_of}

									</span>
								</div>
								<div style={{ padding: "5px" }}>
									<span style={{ color: "#839192" }}>New LA Number</span>
									<span style={{ float: "right" }}>
										{props.replacedLADetails?.la_number}

									</span>
								</div>
							</Paper>

						</DialogContent></>


					<DialogActions>
						<Button
							variant="contained"
							style={{
								textTransform: 'none',
								padding: '6px 25px'
							}}
							onClick={handleCloseNewLAReplacedDialog}>CLOSE</Button>

					</DialogActions>

				</Dialog>
			</div>
		);
	}

	const Mousehover = (props) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const handlePopoverOpen = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handlePopoverClose = (e) => {
			setAnchorEl(null);
		};

		const open = Boolean(anchorEl);
		return (
			<div>
				<Typography
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
					style={{
						fontFamily: 'Poppins',
						fontSize: "inherit",
						textDecoration: "underline",
						fontWeight: anchorEl === null ? "400" : "500",
						color: anchorEl === null ? "#666" : "#3B69B0",
						cursor: 'pointer'
					}}
				>
					{props.children}
				</Typography>
				<Popover
					id="mouse-over-popover"
					sx={{
						pointerEvents: 'none',
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
					className={classes.popoverBox}
				>
					<Typography sx={{ p: 1 }}>
						{props.content}
					</Typography>
				</Popover>
			</div>
		);
	}

	const BasicPopover = (props) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleClose = (e) => {
			setAnchorEl(null);
		};

		const open = Boolean(anchorEl);
		const id = open ? 'simple-popover' : undefined;

		return (
			<div className={classes.popoverText} autoFocus onClick={(e) => e.stopPropagation()}>
				{props.anotherContent ?
					props.anotherContent === 'NOT_FOUND'
						? <div onClick={(e) => e.stopPropagation()}>
							{props.children}
						</div>
						:
						<div>
							<div style={{ display: "flex", flexBasis: "33%", justifyContent: 'center' }}>
								{props.children}
								{Boolean(props?.copyText) &&
									<span onClick={(event) => {
										onCopyText(props?.copyText)
										event.stopPropagation();
									}}
									>
										{isCopied[props?.copyText] ? (
											<Typography
												style={{
													fontSize: pxToEm(14),
													height: 24,
													width: 24,
													fontWeight: "normal",
													color: "#FFB600",
													marginLeft: 2,
												}}
											>
												Copied!
											</Typography>
										) : (
											<ContentCopyOutlinedIcon
												style={{
													padding: '0 0 5px 0',
													// transform: 'scale(1)',
													marginLeft: 4,
													cursor: 'pointer'
												}}
											/>
										)}
									</span>
								}
							</div>
							<div onClick={(e) => e.stopPropagation()}>
								<div
									onClick={handleClick}
									style={{
										display: "flex",
										flexBasis: "50%",
										justifyContent: 'center',
										alignItems: 'center',
										marginRight: Boolean(props?.copyText) && 18
									}}
								>
									<Typography
										className={
											props.anotherContent === "HOLD"
												? classes.orange
												:
												anchorEl === null
													? classes.gray
													: classes.blue}
										aria-describedby={id}
										component="span"
										variant="body1"
										style={{
											display: "inline",
											fontSize: pxToEm(14),
											textDecoration: "underline",
											fontWeight: anchorEl === null ? "400" : "600",
											cursor: 'pointer'
										}}
									>
										{
											props.anotherContent === "HOLD"
												? "ON HOLD"
												:
												props.anotherContent
										}
									</Typography>
								</div>
							</div>
						</div>
					:
					<div onClick={(e) => e.stopPropagation()}>
						{props.children}
						<IconButton
							sx={{ display: 'inline' }}
							aria-describedby={id}
							className={anchorEl === null ? classes.gray : classes.blue}
							variant="text"
							onClick={handleClick}
							size="small"
						>
							<InfoOutlinedIcon />
						</IconButton>
					</div>

				}
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					className={classes.popoverBox}
					style={{
						top: Boolean(props?.copyText) && ['UNLOADING', 'UNLOADED', 'LAPSED', 'CANCELLED'].includes(props.anotherContent) && '-30%',
						bottom: Boolean(props?.copyText) && ['UNLOADING', 'UNLOADED', 'LAPSED', 'CANCELLED'].includes(props.anotherContent) && '-50%',
						margin: Boolean(props?.copyText) && ['UNLOADING', 'UNLOADED', 'LAPSED', 'CANCELLED'].includes(props.anotherContent) && '50px 0'
					}}
				>
					<Typography sx={{ p: 2, position: Boolean(props?.copyText) && ['UNLOADING', 'UNLOADED', 'LAPSED', 'CANCELLED'].includes(props.anotherContent) && 'fixed' }}>
						{props.content}
					</Typography>
				</Popover>
			</div>
		);
	}

	const ContractsCustomersTable = (props) => {
		const theme = useTheme()
		const { count, page, rowsPerPage, onPageChange } = props;

		const handleFirstPageButtonClick = (event) => {
			props.onChangePage(event, 0);
		};

		const handleBackButtonClick = (event) => {
			props.onChangePage(event, props.page - 1);
		};

		const handleNextButtonClick = (event) => {
			props.onChangePage(event, props.page + 1);
		};

		const handleLastPageButtonClick = (event) => {
			props.onChangePage(
				event,
				Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)
			);
		};

		return (
			<div style={{
				flexShrink: 0,
				color: theme.palette.text.secondary,
				marginLeft: theme.spacing.unit * 2.5
			}}>
				{/* <IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="First Page"
				>
					{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
				</IconButton> */}
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0 || rowsPerPage == 0}
					aria-label="Previous Page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowRight />
					) : (
						<KeyboardArrowLeft />
					)}
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={(page >= Math.ceil(count / rowsPerPage) - 1) || rowsPerPage == 0}
					aria-label="Next Page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowLeft />
					) : (
						<KeyboardArrowRight />
					)}
				</IconButton>
				{/* <IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="Last Page"
				>
					{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
				</IconButton> */}
			</div>
		);
	}

	ContractsCustomersTable.propTypes = {
		count: PropTypes.number.isRequired,
		onChangePage: PropTypes.func.isRequired,
		page: PropTypes.number.isRequired,
		rowsPerPage: PropTypes.number.isRequired,
	};



	const handleChangeRowsPerPage = (event) => {
		// console.log(event.target.value)
		setRowsPerPage(event.target.value);
		setPage(0);
	};
	const handleActionMenuClick = (event) => {
		event.stopPropagation();
	};


	return (
		<div className={classes.tableContainer}>
			<Paper
				sx={{
					width: '100%',
					margin: '1% 0',
					overflow: 'hidden',
					border: '0'
				}}
			>

				<TableContainer
					id='print-details'
					autoFocus={true}
					className={classes.root}
					sx={{ height: 440, }}
				>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow className={classes.root}>
								{columns.map((column) => (
									<TableCell
										align="center"
										key={column.id}
										style={{
											minWidth: column.id === 'LA' ? 150 : 150,
											display: (props?.dispatchPlaningListLA) ?
												dispatchPlaningListLAHeader.includes(column.id) ?
													'none' : 'table-cell'
												:
												column.id === 'ItemCategory' || column.id === 'OrderID' ?
													'none' : 'table-cell'
										}}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody className={classes.root}>
							{props?.Loader ? (
								<TableRow>

									Data Loading...

								</TableRow>
							) :
								tableData.length < 1 ? (
									<div className={classes.loaderDiv}>
										<Typography className={classes.loaderStyle}>
											No Loading advices.
										</Typography>
									</div>
								)
									:

									!props.Loader ?
										!Rerendered &&
										tableData
											.map((row, index) => {
												return (
													<TableRow
														hover
														role="checkbox"
														tabIndex={-1}
														key={row.id}
														style={{ cursor: "pointer" }}
														onClick={(e) => {
															{
																props.location?.pathname.includes("/internalLoadingAdvices") &&
																	props?.fetchPendingQuantity(row.order)
																		?.then((response) => {
																			props.history?.push(
																				{
																					pathname:
																						`/internalLoadingAdvices/order/${row.order}/overviewLoadingAdvice/${row.id}`,
																					state: {
																						pendingQuantity: response.data,
																						plantId: props.match?.params?.plantId,
																						plantName: row.PlantName,
																						date: props.selectedDate,
																						status: row.Status,
																						laQty: row.LAQty,
																						itemId: row.ItemCategoryId,
																						customerId: row.Customer_Id,
																						laId: row.id,
																						itemTotalQuantity: row.itemTotalQuantity,
																						orderNo: row.order,
																						paymentMethodId: row.paymentMethodId,
																						buyerPinCode: row.BuyerPinCode,
																						plants: props.plants,
																						orderStatus: row.orderStatus,
																						isCreatedByCustomer: row["createdBy"]?.indexOf("Customer") > -1,
																						laRowData: row.laRowData,
																						created_from_subcontract: row.created_from_subcontract
																					}
																				}
																			)
																		})
																		.catch((error) => {
																			console.log(error)
																		});
															}
														}}
													>
														{columns.map((column) => {
															const value = row[column.id];
															return (
																<TableCell
																	key={column.id}
																	align="center"
																	style={{
																		wordSpacing: 2,
																		display: (props?.dispatchPlaningListLA) ? dispatchPlaningListLAHeader.includes(column.id) ? 'none' : 'table-cell' :
																			column.id === 'ItemCategory' || column.id === 'OrderID' ? 'none' : 'table-cell'
																	}}
																	onClick={(event) => {
																		if (["PaymentTerms", "Vehicle", "OrderID", "LAQty", "NetWeight", "Weighbridge", "Action"].includes(column.id)) {
																			event.stopPropagation();
																		}
																	}}
																>
																	{
																		column.id === 'GateInDate' ?
																			moment(value).format("DD-MMM-YY")
																			:
																			column.id === 'LA' ?
																				<div style={{ display: "flex", flexDirection: 'row', marginLeft: row.sales_vr_number ? "5px" : "30px" }}>
																					{
																						row.sales_vr_number &&
																						<span style={{ marginRight: "5px" }}>
																							<SalesReturnSVG
																							// style={{
																							// 	padding: '0 0 5px 0',
																							// 	// transform: 'scale(1)',
																							// 	marginRight: 10,
																							// 	cursor: 'pointer'
																							// }}
																							/>
																						</span>
																					}

																					<BasicPopover
																						content={
																							<div
																								className={classes.laActionsDiv}
																								style={{ alignItems: 'flex-start' }}
																							>
																								<LAStatusVerticalStepper
																									laNumber={row["LA"]}
																									laStatus={row['Status']}
																								/>
																							</div>
																						}
																						anotherContent={row['Status']}
																						copyText={row["LA"]}
																					>
																						{value}
																					</BasicPopover>
																				</div>
																				:
																				column.id === 'Invoice' ?
																					value !== " " ?
																						<BasicPopover
																							content={<ClickedInvoice
																								// data={invoiceClickedData[index]} />}
																								data={row["invoiceClickedData"]} />}
																						>
																							{value}
																						</BasicPopover>
																						:
																						value
																					:
																					column.id === 'PaymentTerms' ?
																						<div style={{ display: "flex", flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
																							<BasicPopover
																								content={
																									<ClickedPaymentTerms
																										data={row["PaymentTermsClickedData"]}
																										row={row}
																									/>
																								}
																								anotherContent={handlePaymentStatusText(row["laDueDate"], row['paymentStatus'], 'LA_Table')}
																							>
																								{value}
																							</BasicPopover>
																						</div>
																						:
																						column.id === 'ItemCategory' ? value
																							:
																							column.id === 'PlantName' ? value

																								:
																								column.id === 'Vehicle' ? value !== null ?
																									<Mousehover
																										content={<HoverVehicle
																											data={row["VehicleHoverData"]} />}
																									>
																										{value}
																									</Mousehover> : " "
																									:
																									column.id === 'Customer' ? value !== null ?
																										value : " "
																										:
																										column.id === 'OrderID' ? value !== null ?
																											<div>
																												<div>{value}</div>
																												{row["created_from_subcontract"] ? <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: "10px" }}>
																													<div style={{ fontSize: "1em" }}>
																														{row?.sub_contract_number}
																													</div>
																													<Mousehover
																														content={<HoverSubcontract
																															data={row["SubcontractHoverData"]} />}
																													>
																														<InfoOutlinedIcon style={{ fontSize: "1.3em" }} />
																													</Mousehover>
																												</div> : <></>}

																											</div> : " "
																											:
																											column.id === 'Buyer' ? value !== null ? value : " "
																												:
																												column.id === 'Consignee' ? value !== null ?
																													value : " "
																													:
																													column.id === 'LAQty' ? value !== null ?
																														<BasicPopover
																															content={<ClickedLaQty
																																// data={LaQty[index]} />}
																																data={row["LaQty"]} />}
																														>
																															{value}
																														</BasicPopover> : " "
																														:
																														column.id === 'NetWeight' ? value !== null ?
																															<BasicPopover
																																content={<ClickedNetWeight
																																	// data={LaQty[index]} />}
																																	data={row["LaQty"]} />}
																															>
																																{/* {Number(value) === value && value % 1 !== 0 ? value.toFixed(3) : value} */}
																																{handleTotalQtySumCalculation(value, 'alloted_weight')}
																															</BasicPopover> : " "
																															:
																															column.id === 'Weighbridge' ? value !== " " ?
																																<BasicPopover
																																	content={<ClickedWeighBridge
																																		// data={weighBridge[index]} />}
																																		data={row["weighBridge"]} />}
																																>
																																	{value}
																																</BasicPopover> : " "
																																:
																																column.id === 'Action' ?
																																	<div onClick={handleActionMenuClick}>
																																		<ActionMenu

																																			module={props.module}
																																			Status={row['Status']}
																																			plantName={row['PlantName']}
																																			orderStatus={row['orderStatus']}
																																			created_from_subcontract={row['created_from_subcontract']}
																																			laRowData={row?.laRowData}
																																			showLADetailsDialogLoader={props.showLADetailsDialogLoader}
																																			laId={row.id}
																																			laNumber={row["LA"]}
																																			item1Icon={<CheckCircleOutlinedIcon style={{ marginRight: 8 }} />}
																																			item1={row["createdBy"]?.indexOf("Customer") > -1 ? "REVIEW & APPROVE" : "APPROVE"}
																																			item2Icon={<HighlightOffOutlinedIcon style={{ marginRight: 8 }} />}
																																			item2="REJECT"
																																			item3Icon={<PrintOutlinedIcon style={{ marginRight: 8 }} />}
																																			item3="LA PRINT"
																																			icon={<MoreVertIcon />}
																																			fetchPendingQuantity={props.fetchPendingQuantity}
																																			item4Icon={<EditOutlinedIcon style={{ marginRight: 8 }} />}
																																			item4="EDIT"
																																			itemTotalQuantity={row.itemTotalQuantity}
																																			itemId={row.ItemCategoryId}
																																			customerId={row.Customer_Id}
																																			order={row.order}
																																			paymentMethodId={row.paymentMethodId}
																																			buyerPinCode={row.BuyerPinCode}
																																			isContract={props.isContract}
																																			plantId={props.match?.params?.plantId}
																																			plant_code={!checkInvalidValue(props.plant_code) ? props.plant_code : plantCode}
																																			plants={props.plants}
																																			user={props.user}
																																			item5="REVIEW MODIFICATION"
																																			item5Icon={<FactCheckOutlinedIcon style={{ marginRight: 8 }} />}
																																			item6="DISCARD WEIGHSLIPS"
																																			item6Icon={<DeleteOutlineOutlinedIcon style={{ marginRight: 8 }} />}
																																			item7="ROLL BACK"
																																			item7Icon={<PublishedWithChangesOutlinedIcon style={{ marginRight: 8 }} />}
																																			item8="CANCEL LA"
																																			item9="UNLOAD AND CANCEL LA"
																																			item10="CANCEL AND REPLACE LA"
																																			item11="CANCEL BILL"
																																			item12="LA DETAIL PRINT"
																																			item13="UNLOCK WEIGHBRIDGE"
																																			item14="CANCEL AND GATE-OUT"
																																			item15="INVOICE PRINT"
																																			item13Icon={<LockOpenOutlinedIcon style={{ marginRight: 8 }} />}
																																			selectedDate={props.selectedDate}
																																			rowData={row["LaQty"]}
																																			handleDiscardWeighSlips={props.handleDiscardWeighSlips}
																																			handleUnlockWeighbridge={props.handleUnlockWeighbridge}
																																			handleFetchSalesReturn={props.handleFetchSalesReturn}
																																			handleChangeLAStatus={props.handleChangeLAStatus}
																																			fetchReasonCancelLA={props.fetchReasonCancelLA}
																																			handleLaApprove={props.handleLaApprove}
																																			reasonMaster={props.reasonMaster}
																																			getInvoiceDetails={props.getInvoiceDetails}
																																			invoiceDetails={props.invoiceDetails}
																																			handleLAReplacement={props.handleLAReplacement}
																																			handleLACancelAfterBilled={props.handleLACancelAfterBilled}
																																			openNewReplacedLADialog={props.showReplacedLADetails}
																																			replacedLADetails={props.replacedLADetails}
																																			hideLADetailsDialog={props.hideLADetailsDialog}
																																			lastUpdatedDiffinHrs={row["lastUpdatedDiffinHrs"]}
																																			isCreatedByCustomer={row["createdBy"]?.indexOf("Customer") > -1}
																																			fetchTcsRate={props.fetchTcsRate}
																																			rowWeighSlips={row?.weighBridge?.loadingAdviceWeight}
																																			handleBill={props.handleBill}
																																			item16Icon={<AutorenewOutlinedIcon />}
																																			item17Icon={<AutorenewOutlinedIcon />}
																																			item16="SYNC LA PAYMENT"
																																			item17="FETCH SALES RETURN"
																																			item18="WEIGHBRIDGE VIEW"
																																			item18Icon={<DescriptionOutlinedIcon />}
																																			WeighbridgeID={row['WeighbridgeID']}
																																			PlantFullName={row['PlantFullName']}
																																		/>
																																	</div>
																																	:
																																	value
																	}
																</TableCell>
															);
														})}
													</TableRow>
												);
											})
										: <></>
							}
						</TableBody>
					</Table>
				</TableContainer>
				{tableData ?
					<TablePagination
						rowsPerPageOptions={props.rowsCount}
						colSpan={3}
						count={props.count}
						rowsPerPage={props.module === "loadingAdvices" ? 10 : 20}
						page={page}
						SelectProps={{
							native: true,
						}}
						onChangePage={handleChangePage}
						nextLink={props.nextLink}
						prevLink={props.prevLink}
						itemCategoryIds={props.itemCategoryIds}
						statusSelect={props.statusSelect}
						weighBridgeIds={props.weighBridgeIds}
						ActionsComponent={ContractsCustomersTable}
						component="div"
					/> :
					null
				}
				<SnackbarComponent
					open={snackbar.openSnackbar}
					handleClose={handleCloseSnackbar}
					snackbarMessage={snackbar.snackbarMessage}
					type={snackbar.snackbarType}
				/>
				<SnackbarComponent
					open={props.openSnackbar}
					handleClose={props.handleSnackbarClose}
					snackbarMessage={props.snackbarMessage}
					type={props.snackbarType}
				/>
			</Paper>
			{/* } */}
		</div>
	);
}

export default LoadingAdviceTable; 
