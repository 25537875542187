import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  darkGreyColor,
  lightGreyColor,
  primaryColor,
  secondaryColor,
  bottomNavigationFontColor,
  dailyRateScreenTextColor,
} from "../../../styles/colors";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import { withRouter } from "react-router";
import moment from "moment";
import SnackbarComponent from "../../internal/common/SnackbarComponent";
import SaveButton from "../../internal/common/SaveButton";
import { pxToEm } from "../../../methods";
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import { Typography } from "@material-ui/core";
import { FormControlLabel } from "@mui/material";
import PaymentCard from "../common/PaymentCard";

const styles = {
  createContainer: (isMobile) => ({
    // background:
    //   "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% repeat padding-box",
    height: window.innerHeight - 70,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: isMobile ? "100%" : "65%",
  }),
  createUpperContainer: (isMobile) => ({
    // background:
    //   "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% repeat padding-box",
    height: window.innerHeight - 70,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: isMobile ? "100%" : "100%",
  }),
  nameStyle: {
    margin: "0px 0px 10px",
    fontWeight: 500,
    fontSize: window.innerWidth < 375 ? pxToEm(19) : "1.4em",
    color: darkGreyColor,
  },
  inputFieldContainer: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8'
    }
  },
  paymentOptionCardMainBody: {
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
    // height: window.innerWidth < 375 ? "8vh" : "10vh",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "10px 24px",
    fontSize: window.innerWidth < 375 && pxToEm(14),
    margin: "10px 0px",
    fontFamily: 'Poppins',
    fontSize: 16
  },
  paymentOptionContainer: {
    width: "100%",
    padding: "10px",
    display: 'flex',
    justifyContent: 'center'
  },
  bookingText: {
    display: "flex",
    alignItems: "center",
    color: lightGreyColor,
    marginBottom: "0px",
  },
  confirmOrderButton: {
    // height: "50%",
    // width: "30%",
    backgroundColor: primaryColor,
    color: "white",
    fontSize: window.innerWidth < 375 && pxToEm(13)
  },
  discardOrderButton: {
    color: primaryColor,
    fontSize: window.innerWidth < 375 && pxToEm(13)
  },
  confirmButtonContainer: {
    marginTop: "10%",
    // marginBottom: "8%",
    height: "8%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
    display: "flex",
    fontSize: "0.9em",
    position: "fixed",
    bottom: "0px",
    backgroundColor: "#F5F8FC"
    // "solid linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  },
  webConfirmButtonContainer: {
    marginTop: "10%",
    // marginBottom: "8%",
    height: "8%",
    width: "75%",
    alignItems: "center",
    justifyContent: "space-evenly",
    display: "flex",
    fontSize: "0.9em",
    position: "fixed",
    bottom: "0px",
    backgroundColor: "#F5F8FC"
  },
  dialogContainer: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "15px 20px 20px 20px"
  },
  dialogTop: {
    display: "flex",
    justifyContent: "flex-end",
    // padding: "10px 26px",
    height: "5vh",
    alignItems: "center",
  },
  dialogContentContainer: {
    padding: "0px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.2em",
    height: "10vh",
  },
  dialogButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "8vh",
  },
  dialogButtonShow: {
    width: "90%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  goBackButton: {
    color: primaryColor,
    textTransform: "capitalize",
  },
  yesButtonStyle: {
    width: "78px",
    backgroundColor: primaryColor,
    padding: "8px 24px",
  },
  closeIconForWeb: {
    width: "100%",
    paddingLeft: "6%",
    display: "flex",
    alignItems: "center",
    paddingBottom: "12px",
    cursor: "pointer",
    paddingTop: "10px",
  },
  createDispatchBuyerDetailsMainContainer: {
    width: "100%",
    height: window.innerHeight - 126,
    maxHeight: window.innerHeight - 126,
    padding: "0px 20px",
    overflowY: "scroll",
  },
  buyerDetailShowContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
  },
  buyerDetailTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(17) : "1.19em",
    fontWeight: 500,
  },
  formControlStyle: {
    margin: "20px 0px 6px 0px",
    width: "100%",
  },
  paymentTermContainer: {
    display: "flex",
    flexDirection: "column",
  },
  paymentOptionCardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "22px",
    flexDirection: "column",
    marginBottom: 20
  },
  selectedBuyerStyle: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
  },
  buyerNameTextStyle: {
    color: bottomNavigationFontColor,
    fontSize: "0.9em",
  },
  rupeeIcon: {
    transform: 'scale(0.7)',
    marginRight: '-7px',
    marginLeft: '-7px',
    fontWeight: 400,
    color: '#9d9c9c'
  },
  formLabelStyle: {
    width: '100%'
    // '& MuiTypography-root.MuiFormControlLabel-labelMuiTypography-body1': { width: '100%', }
  }
};

class Create extends Component {
  constructor(props) {
    super(props);
    // let terms_advance = Boolean(
    //   JSON.parse(localStorage.getItem("terms_advance"))
    // );
    // let terms_material = Boolean(
    //   JSON.parse(localStorage.getItem("terms_material"))
    // );
    // let terms_purchase = Boolean(
    //   JSON.parse(localStorage.getItem("terms_purchase"))
    // );
    // let terms_late_payment = Boolean(
    //   JSON.parse(localStorage.getItem("terms_late_payment"))
    // );
    this.state = {
      openDialog: false,
      terms_advance: false,
      terms_material: false,
      terms_purchase: false,
      terms_late_payment: false,
      orderQuantityError: false,
      buyerSelected: false,
      consigneeSelected: false,
      selectedConsignee: null,
      selectedBuyer: null,
      all_accepted: false,
      snackOpen: false,
      snackbarMessage: '',
      snackbarType: ''

    };
  }
  handleSelectPaymentMethod = (event) => {
    this.setState({ value: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  handleSelectTermsAdvance = (key) => {
    this.setState({ [key]: !this.state[key] }, () => {
      if (this.state[key]) {
        window.localStorage.setItem(key, true);
      } else {
        window.localStorage.removeItem(key);
      }
    });
  };
  componentDidMount() {
    if (this.props.selectedDailyRatesAmount === 0 || this.props.selectedDailyRatesAmount === "0")
      this.props.history.replace("/home")
    if (this.props.buyers && this.props.buyers.length === 1 && !this.state.buyerSelected) {
      // console.log("here")
      this.setState({ selectedBuyer: this.props.buyers[0], buyerSelected: true });
      this.props.handleSelectBuyerName(this.props.buyers[0]);
    }
    if (this.props.consignees && this.props.consignees.length === 1 && !this.state.consigneeSelected) {
      this.setState({ selectedConsignee: this.props.consignees[0], consigneeSelected: true });
      this.props.handleSelectConsigneeName(this.props.consignees[0]);
    }
  }
  handleSelectBuyer = (data) => {
    this.setState({ selectedBuyer: data, buyerSelected: true });
  };
  handleSelectConsignee = (data) => {
    this.setState({ selectedConsignee: data, consigneeSelected: true });
  };
  handleConfirmOrder = () => {

    //do nothing
  };
  handleDisabledConfirmButton = () => {
    if (
      this.props.orderQuantity === "" ||
      this.props.orderQuantity === 0 ||
      this.props.orderQuantity === "0" ||
      this.props.selectedDailyRatesAmount === "" ||
      this.props.paymentMethod === "" ||
      // !this.state.terms_advance ||
      // !this.state.terms_material ||
      // !this.state.terms_purchase ||
      // !this.state.terms_late_payment ||
      !this.state.all_accepted ||
      this.props.orderQuantityError ||
      this.props.orderQuantity[0] === 0 ||
      this.props.orderQuantity[0] === "0"
      // ||
      // !this.state.buyerSelected ||
      // !this.state.consigneeSelected
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleOpenSnackClick = (message, type) => {
    this.setState({
      snackOpen: true,
      snackbarMessage: message,
      snackbarType: type
    })
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackOpen: false,
      snackbarMessage: '',
      snackbarType: ''
    })
  };

  render() {
    const {
      terms_advance,
      terms_material,
      terms_purchase,
      terms_late_payment,
      buyerSelected,
      selectedBuyer,
      // consignee,
      selectedConsignee,
      consigneeSelected,
      all_accepted
    } = this.state;
    const {
      classes,
      isMobile,
      handleTextChange,
      orderQuantity,
      orderRemark,
      selectedDailyRates,
      selectedDailyRatesAmount,
      dailyRatesLastUpdatedAt,
      paymentMethods,
      openDialog,
      buyers,
      consignees,
      isContractConfirmLoader,
      paymentMethodsDiscount,
    } = this.props;
    // console.log(this.handleDisabledConfirmButton())
    // console.log(selectedConsignee, consignees)
    // console.log(orderQuantity[0])
    return (

      <div style={styles.createContainer(isMobile)}>
        {!isMobile ? (
          <div className={classes.closeIconForWeb}>
            <Close
              onClick={(e) => {
                e.preventDefault();
                this.props.handleCloseOrderForm()

              }}
            />
            <span
              style={{
                marginLeft: "7px",
                color: darkGreyColor,
                fontWeight: 600,
              }}
            >
              New Contract
            </span>
          </div>
        ) : null}
        <div style={styles.createUpperContainer(isMobile)}>
          <div style={{ width: "85%", display: "flex", flexDirection: "column" }}>
            <p className={classes.nameStyle}>{selectedDailyRates}</p>
            <span style={{ fontSize: window.innerWidth < 375 ? pxToEm(20) : "1.4em" }}>
              ₹ {selectedDailyRatesAmount}
            </span>
            <span style={{ color: darkGreyColor, fontSize: "0.8em" }}>
              <span style={{ marginRight: "5px" }}>Last updated </span>
              {moment(
                dailyRatesLastUpdatedAt.length > 0 && dailyRatesLastUpdatedAt[0].modified_at
              ).format("MMMM Do YYYY, h:mm a")}
            </span>
          </div>
          <div className={classes.inputFieldContainer}>
            <TextField
              name="orderQuantity"
              id="outlined-name"
              label="Quantity *"
              type="number"
              onKeyDown={(evt) => (
                ["e", "E", "+", "-"].includes(evt.key) ||
                (evt.which === 38 || evt.which === 40)
              ) && evt.preventDefault()}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ style: { fontSize: window.innerWidth < 375 && pxToEm(15) } }}
              onKeyPress={(e) => e.target.value.match(/^[0]{1,}$/) || !e.target.value.match(/^\d*\.?\d{0,2}$/) && e.preventDefault()}
              onChange={(e) => {
                if (!e.target.value.match(/^[0]{1,}$/) && e.target.value.match(/^\d*\.?\d{0,3}$/)) {
                  this.props.handleTextChange(e);
                }
                else { e.preventDefault() }
                // const value = e.target.value.replace(/\D/g, "");
                // this.props.handleTextChange({
                //   target: { name: "orderQuantity", value },
                // });
              }}
              value={orderQuantity}
              InputProps={{
                style: { fontSize: window.innerWidth < 375 && pxToEm(15) },
                endAdornment: <InputAdornment position="end" style={{ transform: window.innerWidth < 375 && 'scale(0.8)' }}>MT</InputAdornment>,
              }}
              error={this.props.orderQuantityError}
              helperText={this.props.orderQuantityError ? this.props.orderQuantityError : null}
            />
            <TextField
              name="orderRemark"
              id="outlined-name"
              label="Remark (Optional)"
              margin="normal"
              variant="outlined"
              InputLabelProps={{ style: { fontSize: window.innerWidth < 375 && pxToEm(15) } }}
              InputProps={{
                style: { fontSize: window.innerWidth < 375 && pxToEm(15) },
              }}
              onChange={handleTextChange}
              value={orderRemark}
            />
            <div className={classes.buyerDetailShowContainer}>
              <span className={classes.buyerDetailTextStyle}>Buyer Details</span>
              <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
                The company to be invoiced
              </span>
              <FormControl variant="outlined" className={classes.formControlStyle}>
                <InputLabel
                  id="label-id"
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                  htmlFor="outlined-age-simple"
                >
                  Buyer
                </InputLabel>
                <Select
                  // id="demo-simple-select-outlined"
                  // value={this.state.age}
                  // onChange={this.handleChange}
                  input={
                    <OutlinedInput
                      label="Buyer"
                      labelId="label-id"
                      labelWidth={this.state.labelWidth}
                      name="age"
                      id="outlined-age-simple"
                      value={selectedBuyer && selectedBuyer.id || ""}
                      style={{
                        fontSize: window.innerWidth < 375 && pxToEm(15)
                      }}
                    />
                  }
                >
                  {buyers &&
                    buyers.map((data) => (
                      <MenuItem
                        key={data.id}
                        value={data.id}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleSelectBuyer(data);
                          this.props.handleSelectBuyerName(data);
                        }}
                        style={{
                          fontSize: window.innerWidth < 375 && pxToEm(15)
                        }}
                      >
                        {data.first_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {buyerSelected && buyerSelected ? (
                <div style={{ marginTop: "6px" }}>
                  <div className={classes.selectedBuyerStyle}>
                    <span className={classes.buyerNameTextStyle}>Buyer</span>
                    <span>{selectedBuyer.first_name}</span>
                  </div>
                  <div className={classes.selectedBuyerStyle}>{ }
                    <span className={classes.buyerNameTextStyle}>
                      {selectedBuyer.gstin_number ? "GSTIN" : "PAN"}</span>
                    <span>{selectedBuyer.gstin_number ?
                      selectedBuyer.gstin_number :
                      selectedBuyer.pan_card_number}</span>
                  </div>
                  {
                    selectedBuyer.phone &&
                    <div className={classes.selectedBuyerStyle}>
                      <span className={classes.buyerNameTextStyle}>Contact No.</span>
                      <span>{
                        selectedBuyer.phone ?
                          selectedBuyer.phone.length === 10 ?
                            `+91 ${selectedBuyer.phone}`
                            : `+ ${selectedBuyer.phone}`
                          : ""}</span>
                    </div>}
                  <div className={classes.selectedBuyerStyle}>
                    <span className={classes.buyerNameTextStyle}>Address</span>
                    <span>
                      {
                        ((selectedBuyer?.address?.address ? selectedBuyer?.address?.address + "," : "") || (selectedBuyer?.address?.building_name ? selectedBuyer?.address?.building_name + "," : " ") + (selectedBuyer?.address?.street ? selectedBuyer?.address?.street + "," : " ") + (selectedBuyer?.address?.location ? selectedBuyer?.address?.location + "," : " ")) +

                        " " +
                        ((selectedBuyer?.address?.city ? selectedBuyer?.address?.city + "," : "") || (selectedBuyer?.address?.district_name ? selectedBuyer?.address?.district_name + "," : " ")) +
                        " " +
                        ((selectedBuyer?.address?.state ? selectedBuyer?.address?.state + "," : "") || (selectedBuyer?.address?.state_name ? selectedBuyer?.address?.state_name + "," : "")) +
                        " " +
                        (selectedBuyer?.address?.pincode)}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={classes.buyerDetailShowContainer}>
              <span className={classes.buyerDetailTextStyle}>
                Consignee Details
              </span>
              <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
                Delivery address of contract
              </span>
              <FormControl variant="outlined" className={classes.formControlStyle}>
                <InputLabel
                  id="label-id"
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                  htmlFor="outlined-age-simple"
                >
                  Consignee
                </InputLabel>
                <Select
                  label="Consignee"
                  labelId="label-id"
                  labelWidth={this.state.labelWidth}
                  name="age"
                  id="outlined-age-simple"
                  value={selectedConsignee && selectedConsignee.id || ""}
                  style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                >
                  {consignees &&
                    consignees.map((data) => (
                      <MenuItem
                        // value={data.consignee_detail.first_name}
                        value={data.id}
                        key={data.id}
                        // selected={consignee.length === 1 ? data.consignee_detail.first_name : null}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleSelectConsignee(data);
                          this.props.handleSelectConsigneeName(data);
                        }}
                        style={{ fontSize: window.innerWidth < 375 && pxToEm(15) }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <p style={{ margin: 0 }}>

                            {
                              data.consignee_detail.first_name
                            }
                          </p>
                          <p style={{ fontSize: "12px", margin: 0, whiteSpace: 'initial' }}>
                            {
                              ((data?.consignee_address?.address ? data?.consignee_address?.address + "," : "") || (data?.consignee_address?.building_name ? data?.consignee_address?.building_name + "," : " ") + (data?.consignee_address?.street ? data?.consignee_address?.street + "," : " ") + (data?.consignee_address?.location ? data?.consignee_address?.location + "," : " ")) +

                              " " +
                              ((data?.consignee_address?.city ? data?.consignee_address?.city + "," : "") || (data?.consignee_address?.district_name ? data?.consignee_address?.district_name + "," : " ")) +
                              " " +
                              ((data?.consignee_address?.state ? data?.consignee_address?.state + "," : "") || (data?.consignee_address?.state_name ? data?.consignee_address?.state_name + "," : "")) +
                              " " +
                              (data?.consignee_address?.pincode)
                            }
                          </p>
                        </div>
                      </MenuItem>
                    ))}
                  {/* <MenuItem value={10}>100MT</MenuItem>
              <MenuItem value={20}>200MT</MenuItem>
              <MenuItem value={30}>300MT</MenuItem> */}
                </Select>
              </FormControl>
              {consigneeSelected && consigneeSelected ? (
                <div style={{ marginTop: "6px", }}>
                  <div className={classes.selectedBuyerStyle}>
                    <span className={classes.buyerNameTextStyle}>Consignee</span>
                    <span>{selectedConsignee.consignee_detail.first_name}</span>
                  </div>
                  <div className={classes.selectedBuyerStyle}>
                    <span className={classes.buyerNameTextStyle}>
                      {selectedConsignee.consignee_detail.gstin_number ? "GSTIN" : "PAN"}</span>
                    <span>{selectedConsignee.consignee_detail.gstin_number ?
                      selectedConsignee.consignee_detail.gstin_number :
                      selectedConsignee.consignee_detail.pan_card_number}</span>
                  </div>
                  {selectedConsignee.consignee_detail.phone &&
                    <div className={classes.selectedBuyerStyle}>
                      <span className={classes.buyerNameTextStyle}>Contact No.</span>
                      <span>{selectedConsignee.consignee_detail.phone && selectedConsignee.consignee_detail.phone.length === 10 ?
                        `+91 ${selectedConsignee.consignee_detail.phone}` : `+ ${selectedConsignee.consignee_detail.phone}`}</span>
                    </div>}
                  <div className={classes.selectedBuyerStyle}>
                    <span className={classes.buyerNameTextStyle}>Address</span>
                    <span>
                      {
                        ((selectedConsignee?.consignee_address?.address ? selectedConsignee?.consignee_address?.address + "," : "") || (selectedConsignee?.consignee_address?.building_name ? selectedConsignee?.consignee_address?.building_name + "," : " ") + (selectedConsignee?.consignee_address?.street ? selectedConsignee?.consignee_address?.street + "," : " ") + (selectedConsignee?.consignee_address?.location ? selectedConsignee?.consignee_address?.location + "," : " ")) +

                        " " +
                        ((selectedConsignee?.consignee_address?.city ? selectedConsignee?.consignee_address?.city + "," : "") || (selectedConsignee?.consignee_address?.district_name ? selectedConsignee?.consignee_address?.district_name + "," : " ")) +
                        " " +
                        ((selectedConsignee?.consignee_address?.state ? selectedConsignee?.consignee_address?.state + "," : "") || (selectedConsignee?.consignee_address?.state_name ? selectedConsignee?.consignee_address?.state_name + "," : "")) +
                        " " +
                        (selectedConsignee?.consignee_address?.pincode)}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>

            <span className={classes.buyerDetailTextStyle}>
              Payment Terms *
            </span>
            {/* <p style={{ color: lightGreyColor, margin: "5px 0px 4px 0px" }}>
              Payment Terms *
            </p> */}
          </div>

          <div className={classes.paymentOptionContainer}>
            <FormControl component="span" style={{ width: "89%", display: 'flex', alignItems: 'flex-start' }}>
              <RadioGroup
                aria-label="gender"
                name="gender2"
                style={{ width: '100%' }}
              // value={this.state.value}
              >
                {paymentMethods.map((data) => (
                  <FormControlLabel
                    // style={{ color: "grey", fontFamily: 'Poppins', marginLeft: 0, marginRight: 0 }}
                    style={styles.paymentOptionCardMainBody}
                    key={data.payment_method_detail.id}
                    value={data.payment_method_detail.payment_method_name}
                    disableTypography={true}
                    control={
                      <Radio
                        style={{
                          color: secondaryColor,
                          transform: window.innerWidth < 375 && 'scale(0.8)'
                        }}
                      />
                    }
                    // labelPlacement="end"
                    label={
                      <PaymentCard
                        allPaymentData={paymentMethodsDiscount}
                        rowDataName={data.payment_method_detail.payment_method_name}
                        rowDataID={data?.payment_method_detail?.id}
                      />
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleSelectPaymentMethod(data.payment_method_detail.id);
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>

          <div style={{ width: "85%", marginTop: "8px", marginBottom: "20px" }}>
            <span
              className={classes.buyerDetailTextStyle}
              style={{
                // paddingLeft: "30px" 
              }}
            >
              Terms & Conditions *
            </span>
            {/* <p style={{ color: lightGreyColor, margin: "5px 0px 4px 0px", paddingLeft: "30px", }}>
              Terms & Conditions *
            </p> */}
            {/* <span
            style={{
              paddingLeft: "25px",
              color: lightGreyColor,
              fontWeight: "bold",
            }}
          >
            Accept Terms And Conditions
          </span>
          <FormControl component="span">
            <FormGroup>
              <FormControlLabel
                style={{
                  margin: "10px 0px",
                  paddingLeft: "25px",
                  paddingRight: "22px",
                }}
                control={
                  <Checkbox
                    checked={terms_advance}
                    style={{
                      color: secondaryColor,
                      padding: "4px",
                      marginRight: "10px",
                    }}
                  />
                }
                label={
                  <span style={{ fontSize: "0.9em" }}>Advance or Next day</span>
                }
                onChange={(e) => {
                  this.handleSelectTermsAdvance("terms_advance");
                }}
              />
            </FormGroup>
          </FormControl> */}
            <div>
              <FormControl component="div" >
                <FormGroup>
                  <FormControlLabel
                    style={{
                      margin: '10px 0',
                      // marginTop: "10px",
                      // marginBottom: "10px",
                      // paddingLeft: "25px",
                      // paddingRight: "22px",
                      // marginBottom: "9vh",
                      display: "table",
                    }}
                    control={
                      <div style={{ display: "table-cell" }}>
                        {/* <Checkbox
                      checked={terms_material}
                      style={{
                        color: secondaryColor,
                        padding: "4px",
                        marginRight: "10px",
                      }}
                    /> */}
                      </div>
                    }
                    label={
                      <div style={{ display: 'flex', fontFamily: 'Poppins' }}>
                        <span>
                          &#8226;
                        </span>
                        <span style={{
                          fontSize: window.innerWidth < 375 ? pxToEm(12.5) : "0.9em",
                          marginLeft: !isMobile ? 5 : 10
                          // paddingTop: "5px"
                          // marginTop: "5px"
                        }}>
                          This will be considered as Contract/Sauda Patrak per Steel Association.
                        </span>
                      </div>

                    }
                  // onChange={(e) => {
                  //   this.handleSelectTermsAdvance("terms_material");
                  // }}
                  />
                </FormGroup>
              </FormControl>
              <FormControl component="div">
                <FormGroup>
                  <FormControlLabel
                    style={{
                      margin: '10px 0',
                      // paddingLeft: "25px",
                      // marginTop: "10px",
                      // marginBottom: "10px",
                      // paddingRight: "22px",
                      // marginBottom: "9vh",
                      display: "table",
                    }}
                    control={
                      <div style={{ display: "table-cell" }}>
                        {/* <Checkbox
                      checked={terms_purchase}
                      style={{
                        color: secondaryColor,
                        padding: "4px",
                        marginRight: "10px",
                      }}
                    /> */}
                      </div>
                    }
                    label={
                      <div style={{ display: 'flex', fontFamily: 'Poppins' }}>
                        <span>
                          &#8226;
                        </span>
                        <span style={{ fontSize: window.innerWidth < 375 ? pxToEm(12.5) : "0.9em", marginLeft: !isMobile ? 5 : 10 }}>
                          Materials should be lifted within 15 days from the contract date.
                        </span>
                      </div>
                    }
                  // onChange={(e) => {
                  //   this.handleSelectTermsAdvance("terms_purchase");
                  // }}
                  />
                </FormGroup>
              </FormControl>
              <FormControl component="div">
                <FormGroup>
                  <FormControlLabel
                    style={{
                      margin: "10px 0px",
                      // paddingLeft: "25px",
                      // marginTop: "10px",
                      // marginBottom: "10px",
                      // paddingRight: "22px",
                      marginBottom: "8vh",
                      display: "table",
                    }}
                    control={
                      <div style={{ display: "table-cell" }}>
                        {/* <Checkbox
                      checked={terms_late_payment}
                      style={{
                        color: secondaryColor,
                        padding: "4px",
                        marginRight: "10px",
                      }}
                    /> */}
                      </div>
                    }
                    label={
                      <div style={{ display: 'flex', fontFamily: 'Poppins' }}>
                        <span>
                          &#8226;
                        </span>
                        <span style={{ fontSize: window.innerWidth < 375 ? pxToEm(12.5) : "0.9em", marginLeft: !isMobile ? 5 : 10 }}>
                          Payment to be strictly on scheduled time, otherwise interest will be charged @18% P.A.
                        </span>
                      </div>
                    }
                  // onChange={(e) => {
                  //   this.handleSelectTermsAdvance("terms_late_payment");
                  // }}
                  />
                </FormGroup>
              </FormControl>
              <FormControl component="p">
                <FormGroup>
                  <FormControlLabel
                    style={{
                      // margin: !isMobile && "10px 0px",
                      margin: '10px 0',
                      // marginTop: "10px",
                      // marginBottom: "10px",
                      // paddingLeft: "14px",
                      // paddingRight: "22px",
                      marginBottom: "9vh",
                      display: "table",
                    }}
                    control={
                      <div style={{ display: "table-cell" }}>
                        <Checkbox
                          checked={all_accepted}
                          style={{
                            color: secondaryColor,
                            padding: "4px",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    }
                    label={
                      <span style={{
                        fontSize: window.innerWidth < 375 ? pxToEm(12.5) : "0.9em",
                        marginLeft: "5px",
                        fontFamily: 'Poppins'
                      }}>
                        I agree to the terms & conditions
                      </span>
                    }
                    onChange={(e) => {
                      this.handleSelectTermsAdvance("all_accepted");
                    }}
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>

        <div className={isMobile ? classes.confirmButtonContainer : classes.webConfirmButtonContainer}>
          <SaveButton
            // disabled={this.handleDisabledConfirmButton()}
            variant="contained"
            // color="primary"
            className={classes.confirmOrderButton}
            // style={{
            //   backgroundColor: primaryColor,
            //   color: "white"
            // }}
            onClick={(e) => {
              e.preventDefault();
              if (this.handleDisabledConfirmButton()) {
                if (this.props.paymentMethod === "") {
                  this.handleOpenSnackClick('Payment terms not valid', "warning")
                }
                else if (!this.state.all_accepted) {
                  this.handleOpenSnackClick('Select terms & conditions', "warning")
                }
                else if (this.props.selectedDailyRatesAmount === "") {
                  this.handleOpenSnackClick('Daily rate amount not valid', "warning")
                }
                else { this.handleOpenSnackClick('Quantity not valid', "warning") }
              }
              else {
                this.props.handleCreateOrder()
              }
              // this.handleConfirmOrder();
            }}
            isLoading={isContractConfirmLoader}
          >
            Confirm Contract
          </SaveButton>
          <Button
            className={classes.discardOrderButton}
            onClick={(e) => {
              e.preventDefault();
              this.props.handleCloseOrderForm("openDialog")
              // this.setState({ openDialog: true });
            }}
            disabled={isContractConfirmLoader}
          >
            Discard Contract
          </Button>
        </div>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          PaperProps={{
            style: {
              width: !isMobile && "312px",
              height: !isMobile && '235px',
              overflowY: "inherit",
              borderRadius: "15px",
              alignItems: 'flex-start',
              padding: 8
            },
          }}
        >
          <div style={styles.dialogContainer}>
            <div className={classes.dialogTop} style={{ height: !isMobile && 30 }}>
              <Close
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  // this.setState({ openDialog: false });
                  this.props.handleCloseOrderForm("goBack")
                }}
              />
            </div>
            <div className={classes.dialogContentContainer} style={{ height: !isMobile && 120 }}>
              <span>Are you sure want to cancel this Contract ?</span>
            </div>
            <div className={classes.dialogButtonContainer} style={{ height: !isMobile && 30 }}>
              <div className={classes.dialogButtonShow}>
                <Button className={classes.goBackButton}
                  onClick={(e) => {
                    e.preventDefault();

                    this.props.handleCloseOrderForm("goBack")
                    // this.props.history.push("/home");
                  }}
                >Go Back</Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.yesButtonStyle}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({

                      terms_advance: false,
                      terms_material: false,
                      terms_purchase: false,
                      terms_late_payment: false,
                      all_accepted: false
                    });
                    this.props.handleCloseOrderForm("clearData")
                    this.props.history.push("/home");
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <SnackbarComponent
          open={this.state?.snackOpen}
          handleClose={this.handleSnackbarClose}
          customAutoHideDuration={3000}
          snackbarMessage={this.state?.snackbarMessage}
          type={this.state?.snackbarType}
        />
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Create));
