import React, { Component } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import DisplayAppVersionData from "../../../../components/internal/masterManagement/appVersion/web";
import ManageAppVersionData from "../../../../components/internal/masterManagement/appVersion/ManageAppVersionData";
import { checkApiError } from "../../../../methods";

class ManageappVersionContainer extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			appVersionOperation: null,
			selectedAppVersion: null,
			selectedAppVersionData: [],
			selectedAppVersionDataFetchLoader: false,
			snackbarMessage: null,
			openSnackbar: false,
			snackbarType: "",
		};
	}
	getOptions = (user) => {
		return {
			headers: {
				Authorization: `JWT ${user.token}`,
				"Content-Type": "application/json;charset=UTF-8",
			},
		};
	};
	componentWillUnmount() {
		this._isMounted = false;
	}
	componentDidMount() {
		this._isMounted = true;
		let appVersionOperation = this.props.location.pathname.includes("add")
			? "add"
			: this.props.location.pathname.includes("edit")
				? "edit"
				: "view";

		this.setState({ appVersionOperation });
		if (appVersionOperation !== "add") {
			const id = this.props?.location?.pathname?.split("/")[
				this.props?.location?.pathname?.split("/").length - 1
			];
			this.handleFetchSelectedAppVersionData(id);
			this.setState({ selectedAppVersion: id });
		}
	}

	static getDerivedStateFromProps(props, state) {
		let updatedData = new Map();
		let appVersionOperation = props.location.pathname.includes("add")
			? "add"
			: props.location.pathname.includes("edit")
				? "edit"
				: "view";
		let selectedAppVersion =
			appVersionOperation !== "add"
				? props?.location?.pathname?.split("/")[
				props?.location?.pathname?.split("/").length - 1
				]
				: null;
		if (appVersionOperation !== state.appVersionOperation) {
			updatedData.set("appVersionOperation", appVersionOperation);
		}
		if (selectedAppVersion !== state.selectedAppVersion) {
			updatedData.set("selectedAppVersion", selectedAppVersion);
		}
		if (updatedData.size > 0) {
			return {
				appVersionOperation: updatedData.has("appVersionOperation")
					? updatedData.get("appVersionOperation")
					: appVersionOperation,
				selectedAppVersion: updatedData.has("selectedAppVersion")
					? updatedData.get("selectedAppVersion")
					: [],
			};
		}
		return null;
	}
	handleFetchSelectedAppVersionData = (id) => {
		// console.log("here", id)
		this.setState({ selectedAppVersionDataFetchLoader: true });
		axios
			.get(
				`${APIROUTES.GET_APP_VERSION}${id}`,
				this.getOptions(this.props.user)
			)
			.then(
				(response) => {
					if (response.status === 200) {
						this.setState({
							selectedAppVersionData: response.data,
							selectedAppVersionDataFetchLoader: false,
						});
					} else {
						this.setState({
							selectedAppVersionDataFetchLoader: false,
							openSnackbar: true,
							snackbarMessage:
								"Something went wrong while fetching selected App Version data try again!",
							snackbarType: "warning",
						});
					}
				},
				(error) => {
					this.handleOpenSnackbar(
						this.handleSnackbarMessageBox(checkApiError(error.response.data)),
						"error"
					);
					setTimeout(() => {
						this.handleCloseSnackbar();
					}, 4000);
				}
			);
	};

	handleappVersionDataAdd = (appVersionData) =>
		new Promise((resolve, reject) => {
			console.log(appVersionData)
			const data = {
				new_updates: appVersionData?.new_updates,
				app_version: appVersionData?.app_version,
				is_active: appVersionData.is_active
			}
			axios
				.post(
					APIROUTES.GET_APP_VERSION,
					data,
					this.getOptions(this.props.user)
				)
				.then((response) => {
					this.handleFetchSelectedAppVersionData(response.data.id);
					this.props.getAppVersionList();
					resolve(response);
				})
				.catch(reject);
		});
	handleappVersionDataUpdate = (appVersionData) =>
		new Promise((resolve, reject) => {
			const id = this.props?.location?.pathname?.split("/")[
				this.props?.location?.pathname?.split("/").length - 1
			];
			const data = {
				new_updates: appVersionData?.new_updates,
				app_version: appVersionData?.app_version,
				is_active: appVersionData.is_active
			}
			axios
				.patch(
					`${APIROUTES.GET_APP_VERSION}${id}`,
					data,
					this.getOptions(this.props.user)
				)
				.then((response) => {
					this.handleFetchSelectedAppVersionData(id);
					this.props.getAppVersionList();
					resolve(response);
				})
				.catch(reject);
		});
	handleCloseSnackbar = (event, appVersion) => {
		if (appVersion === "clickaway") {
			return;
		}
		this.setState({
			openSnackbar: false,
			snackbarMessage: null,
			snackbarType: "",
		});
	};

	handleOpenSnackbar = (message, type) => {
		this.setState({
			openSnackbar: true,
			snackbarMessage: message,
			snackbarType: type,
		});
	};

	handleSnackbarMessageBox = (data) => {
		return (
			data.map((item, index) => {
				return (
					<>
						<p style={{ margin: 0 }}>
							{item}
						</p>
					</>)
			})
		)
	}

	render() {
		const {
			appVersionOperation,
		} = this.state;
		if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
		// if (isAccessibleModule(this.props?.user, "Banks"))
		else {
			return (
				<>
					{appVersionOperation === "view" ? (
						<DisplayAppVersionData
							{...this.props}
							{...this.state}
							selectedAppVersionData={this.state?.selectedAppVersionData}
							appVersionOperation={this.state?.appVersionOperation}
							selectedAppVersionDataFetchLoader={this.state?.selectedAppVersionDataFetchLoader}
							selectedAppVersion={this.state?.selectedAppVersion}
						/>
					) : (
						<ManageAppVersionData
							{...this.props}
							{...this.state}
							handleappVersionDataUpdate={this.handleappVersionDataUpdate}
							handleappVersionDataAdd={this.handleappVersionDataAdd}
							selectedAppVersionData={this.state?.selectedAppVersionData}
							appVersionOperation={this.state?.appVersionOperation}
							selectedAppVersionDataFetchLoader={this.state?.selectedAppVersionDataFetchLoader}
							selectedAppVersion={this.state?.selectedAppVersion}
						/>
					)}
					<SnackbarComponent
						open={this.state.openSnackbar}
						handleClose={this.handleCloseSnackbar}
						snackbarMessage={this.state.snackbarMessage}
						type={this.state.snackbarType}
					/>
				</>
			);
		}
		// else return <AccessDenied />;
	}
}
export default withUser(ManageappVersionContainer);
