import React from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  withStyles,
  TablePagination,
} from "@material-ui/core";
import moment from "moment";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import COLORS from "../../../../constants/internal/colors";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import Spinner from "../../common/Spinner";

function ItemCategoryViewComponent(props) {
  const { data, classes, Fetching } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        {Fetching ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Bread
              data={[
                {
                  title: "Master Management",
                  onClick: () => props.history.goBack(),
                },
                {
                  title: "Item Categories",
                  onClick: () => props.history.goBack(),
                },
              ]}
            />
            <div className={classes.addButton}>
              <Button
                style={{
                  backgroundColor: COLORS.primaryColor,
                  color: COLORS.drawerTextColor,
                }}
                variant="contained"
                startIcon={<AddOutlinedIcon />}
                onClick={() =>
                  props.history.push(
                    `${INTERNALROUTES.LISTITEMCATEGORIESMASTER}/create`
                  )
                }
              >
                ADD NEW
              </Button>
            </div>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table
                stickyHeader
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderCell}>
                      CATEGORY
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                    >
                      STATES
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderCell}
                    >
                      LAST EDITED
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => (
                        <TableRow
                          hover
                          key={`r${d.id}`}
                          onClick={() =>
                            props.history.push(
                              INTERNALROUTES.LISTITEMCATEGORIESMASTER +
                              "/details/" +
                              d.id
                            )
                          }
                          className={classes.bodyTableRow}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.productFont}
                          >
                            {d.category_name}
                          </TableCell>
                          <TableCell align="center">{d.states_count}</TableCell>
                          <TableCell align="right">
                            <div>
                              {moment(d.modified_at).format("DD MMM YYYY")}
                              &nbsp;&nbsp;>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>{" "}
            <Paper>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </React.Fragment>
        )}
      </div>
    </Layout>
  );
}

const styles = (theme) => ({
  displayData: {
    marginTop: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
    paddingRight: "25px",
  },
  tableContainer: {
    marginTop: 30,
    width: "100%",
    height: "calc(100vh - 250px)",
    overflow: "auto",
  },
  tableHeader: {
    backgroundColor: COLORS.whiteSmoke,
  },
  tableHeaderCell: {
    color: COLORS.tableHeaderText,
    fontWeight: "bold",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: COLORS.grey,
  },
  rightItemFix: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    float: "right",
  },
  bodyTableRow: {
    "&:hover": {
      backgroundColor: COLORS.tableHoverColor + " !important",
      cursor: "pointer",
    },
  },
  iconContainer: {
    height: 30,
    width: 30,
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: COLORS.whiteSmoke,
    overflow: "hidden",
    color: COLORS.grey,
    "&:hover": {
      backgroundColor: COLORS.whiteSmoke,
      width: 70,
      transition: "width 0.3s",
      cursor: "pointer",
    },
  },
  addButton: {
    float: "right",
    marginBottom: 30,
  },
});

export default withStyles(styles)(ItemCategoryViewComponent);
