import React, { Component } from "react";
import Layout from "../../../layouts/internalLayout/InternalLayout";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  secondaryBlueText,
  primaryColor,
  drawerTextColor,
  whiteSmoke,
  grey,
} from "../../../constants/internal/colors";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { checkApiError, hasWriteAccess, pxToEm } from "../../../methods";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import Bread from "../common/Breadcrubs";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import SnackbarComponent from "../common/SnackbarComponent";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SpinLoader from "../../common/SpinLoader";
import debounce from "lodash.debounce";
import NoDataFound from "../../common/NoDataFound";
import ToolTipComponent from "../common/ToolTipComponent";

const displayMasterStyles = (theme) => ({
  titleWithBlueFont: {
    fontSize: "18px",
    color: secondaryBlueText,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
  },
  table: {
    minWidth: 650,
    border: "0px",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },

  productFont: {
    fontSize: pxToEm(13),
  },

  alignText: {
    display: "flex",
    placeContent: "flex-end",
  },
  rightSpacing: {
    marginRight: 10,
  },
  arrowDimension: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
  },
  addNewButtonAlign: {
    display: "flex",
    right: 10,
    justifyContent: "flex-end",
  },
  searchCityAlign: {
    width: "40%",
    display: "flex",
    left: 10,
    justifyContent: "flex-start",
  },
  searchBox: {
    minWidth: 500,
  },
  displayData: {
    marginTop: 10,
  },
  searchAddNew: {
    marginBottom: 20,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  tableContainer: {
    marginTop: 20,
    width: "100%",
    height: "calc(100vh - 270px)",
    overflow: "auto",
  },
  noDataDiv: {
    display: "flex",
    position: 'relative',
    left: '38vw',
    top: '18vh',
    width: '100%',
    height: '100%',
    zIndex: 9999,
  },
  inactiveChipStyle: {
    padding: '3px 6px',
    marginLeft: 5,
    alignItems: "center",
    fontSize: pxToEm(11),
    backgroundColor: "red",
    color: drawerTextColor,
    borderRadius: "45px",
  }
});
const styles = {
  tableHead: (tableFor) => ({
    fontSize: pxToEm(13),
    fontWeight: "bold",
    width:
      tableFor === "plantItemMaster"
        ? "25%"
        : tableFor === "bank"
          ? "25%"
          : tableFor === "tagsMaster"
            ? "45%"
            : tableFor === "tdsRate"
              ? "20%"
              : tableFor === "transporter"
                ? "25%"
                : tableFor === "tcsMatrix"
                  ? "25%"
                  : "33%",
  }),
  dataFont: (tableFor) => ({
    fontSize: pxToEm(13),
    color: grey,
    width:
      tableFor === "plantItemMaster"
        ? "25%"
        : tableFor === "bank"
          ? "25%"
          : tableFor === "tagsMaster"
            ? "45%"
            : tableFor === "tdsRate"
              ? "20%"
              : tableFor === "transporter"
                ? "25%"
                : "33%",
  }),
};
class DisplayMastersDataDetailsList extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      selectedDataToDiplay: [],
      selectedDataToDiplayLoader: false,
      selectedStateDataToDiplay: [],
      displaySection: null,
      currentPath: null,
      allCitiesList: [],
      allStateList: [],
      tabValue: '0',
      page: 0,
      rowsPerPage: 10,
      searchPincode: '',
      searchCity: '',
      snackbarData: {
        openSnackbar: false,
        snackbarMessage: "",
        snackbarType: "",
      },
    };
  }

  handleChangeTransportPage = (event, newPage) => {
    console.log(event.target.value)
    console.log(this.state.page, newPage)
    console.log(this.props.nextLink, this.props.prevLink)
    if (this.props.searchTransportText !== '') {
      if (newPage > this.state.page) {
        this.props.handleNextTransportPage(this.props.nextLink, '')
        this.setState({ page: newPage });
      }
      else if (newPage < this.state.page) {
        this.props.handleNextTransportPage(this.props.prevLink, '')
        this.setState({ page: newPage });
      }
    }
    else {
      if (newPage > this.state.page) {
        this.props.handleNextTransportPage(this.props.nextLink, this.props.searchTransportText)
        this.setState({ page: newPage });
      }
      else if (newPage < this.state.page) {
        this.props.handleNextTransportPage(this.props.prevLink, this.props.searchTransportText)
        this.setState({ page: newPage });
      }
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    const { displaySection, tabValue } = this.props;
    console.log(this.props?.appBannerList)
    let selectedDataToDiplay =
      displaySection === "itemsCategory"
        ? this.props.itemCategoriesList
        : displaySection === "paymentTerms"
          ? this.props.paymentTermsList
          : displaySection === "plantItemMaster"
            ? this.props.plantItemMasterList
            : displaySection === "tagsMaster"
              ? this.props.tagsMasterList
              : displaySection === "bank"
                ? this.props.bankMasterList
                : displaySection === "tdsRate"
                  ? this.props.tdsRateList
                  : displaySection === "transporter"
                    ? this.props.transporterList
                    : displaySection === "tcs"
                      ? this.props.tcsList
                      : displaySection === "tcsMatrix"
                        ? this.props.tcsMatrixList
                        : displaySection === "unit"
                          ? this.props.unitList
                          : displaySection === "pincode"
                            ? tabValue === '0'
                              ? this.props.pincodesList
                              : tabValue === '1'
                                ? this.props.cityStatesList
                                : this.props.allStateList
                            : displaySection === "itemNature"
                              ? this.props.itemNatureList
                              : displaySection === "reason"
                                ? this.props.reasonList
                                : displaySection === "customerType"
                                  ? this.props.customerTypeList
                                  : displaySection === "appBanner"
                                    ? this.props.appBannerList
                                    : displaySection === "appVersion"
                                      ? this.props.appVersionList
                                      : displaySection === "itemSize"
                                        ? this.props.itemSizeList
                                        : [];
    let selectedDataToDiplayLoader =
      displaySection === "itemsCategory"
        ? this.props.itemCategoriesDataFetchLoader
        : displaySection === "paymentTerms"
          ? this.props.paymentTermsDataFetchLoader
          : displaySection === "plantItemMaster"
            ? this.props.plantItemMasterDataFetchLoader
            : displaySection === "tagsMaster"
              ? this.props.tagsMasterDataFetchLoader
              : displaySection === "bank"
                ? this.props.bankMasterDataFetchLoader
                : displaySection === "tdsRate"
                  ? this.props.tdsRateDataFetchLoader
                  : displaySection === "transporter"
                    ? this.props.transporterDataFetchLoader
                    : displaySection === "tcs"
                      ? this.props.tcsListDataFetchLoader
                      : displaySection === "tcsMatrix"
                        ? this.props.tcsListDataFetchLoader
                        : displaySection === "unit"
                          ? this.props.unitListDataFetchLoader
                          : displaySection === "pincode"
                            ? tabValue === '0'
                              ? this.props.pincodesListDataFetchLoader
                              : tabValue === '1'
                                ? this.props.allCitiesListDataFetchLoader
                                : this.props.allStateListDataFetchLoader
                            : displaySection === "itemNature"
                              ? this.props.itemNatureListDataFetchLoader
                              : displaySection === "reason"
                                ? this.props.reasonListDataFetchLoader
                                : displaySection === "customerType"
                                  ? this.props.customerTypeListDataFetchLoader
                                  : displaySection === "appBanner"
                                    ? this.props.appBannerListDataFetchLoader
                                    : displaySection === "appVersion"
                                      ? this.props.appVersionListDataFetchLoader
                                      : displaySection === "itemSize"
                                        ? this.props.itemSizeListDataFetchLoader
                                        : false;
    let allCitiesList = this.props.allCitiesList;
    let allStateList = this.props.allStateList;
    let currentPath = this.props.location.pathname;
    this.setState({
      selectedDataToDiplay,
      selectedDataToDiplayLoader,
      currentPath,
      displaySection,
      allCitiesList,
      allStateList,
    });
    if (this.props.location.openSnackbar) {
      this.setState({
        snackbarData: {
          openSnackbar: true,
          snackbarMessage: this.props.location.snackbarMessage,
          snackbarType: this.props.location.snackbarType,
        },
      });
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (
      nextProps.allCitiesList !== this.props.allCitiesList ||
      nextProps.allStateList !== this.props.allStateList
    ) {
      let allCitiesList = this.props.allCitiesList;
      let allStateList = this.props.allStateList;

      this.setState({
        // selectedDataToDiplay,
        // currentPath,
        // displaySection,
        allCitiesList,
        allStateList,
      });
    }
    if (
      nextProps.searchedPincode !== this.props.searchedPincode
    ) {
      let searchedPincode = this.props.searchedPincode;
      // console.log(this.props?.searchedPincode)
      this.setState({
        searchedPincode
      })
    }
  }

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    this.setState({
      snackbarData: {
        openSnackbar: false,
        snackbarMessage: "",
        snackbarType: "",
      },
    });
  };

  handleMasterKeyCode = (displaySection) => {
    return displaySection === "itemsCategory"
      ? 'item_categories'
      : displaySection === "paymentTerms"
        ? 'payment_terms'
        : displaySection === "plantItemMaster"
          ? 'plant_item_master'
          : displaySection === "tagsMaster"
            ? 'tags_master'
            : displaySection === "bank"
              ? 'bank_master'
              : displaySection === "tdsRate"
                ? 'tds_rate_master'
                : displaySection === "transporter"
                  ? 'transporter_master'
                  : displaySection === "tcs"
                    ? 'tcs_master'
                    : displaySection === "tcsMatrix"
                      ? 'tcs_matrix'
                      : displaySection === "unit"
                        ? 'unit_master'
                        : displaySection === "pincode"
                          ? 'pincode_master'
                          : displaySection === "itemNature"
                            ? 'item_nature_master'
                            : displaySection === "reason"
                              ? 'reason_master'
                              : displaySection === "customerType"
                                ? 'customer_types_master'
                                : displaySection === "appBanner"
                                && 'app_banner_master'
  }

  handleTabChange = (event, newValue) => {
    // this.setState({
    //   tabValue: newValue
    // })
    this.props.handlePincodeTabValue(newValue);
    this.setState({ page: 0 });
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedStateDataToDiplay, searchPincode, searchCity } = state;
    const { displaySection, tabValue, } = props;
    console.log(props?.appBannerList)
    return {
      selectedDataToDiplay:
        displaySection === "itemsCategory"
          ? props.itemCategoriesList
          : displaySection === "paymentTerms"
            ? props.paymentTermsList
            : displaySection === "plantItemMaster"
              ? props.plantItemMasterList
              : displaySection === "tagsMaster"
                ? props.tagsMasterList
                : displaySection === "bank"
                  ? props.bankMasterList
                  : displaySection === "tdsRate"
                    ? props.tdsRateList
                    : displaySection === "transporter"
                      ? props.transporterList
                      : displaySection === "tcs"
                        ? props.tcsList
                        : displaySection === "tcsMatrix"
                          ? props.tcsMatrixList
                          : displaySection === "unit"
                            ? props.unitList
                            : displaySection === "pincode"
                              ? tabValue === '0'
                                ? ((searchPincode !== undefined && searchPincode !== '') ? props.searchedPincode : props.pincodesList)
                                : tabValue === '1'
                                  ? ((searchCity !== undefined && searchCity !== '') ? props.searchedCity : props.cityStatesList)
                                  : selectedStateDataToDiplay
                              : displaySection === "itemNature"
                                ? props.itemNatureList
                                : displaySection === "reason"
                                  ? props.reasonList
                                  : displaySection === "customerType"
                                    ? props.customerTypeList
                                    : displaySection === "appBanner"
                                      ? props.appBannerList
                                      : displaySection === "appVersion"
                                        ? props.appVersionList
                                        : displaySection === "itemSize"
                                          ? props.itemSizeList
                                          : [],
      selectedDataToDiplayLoader:
        displaySection === "itemsCategory"
          ? props.itemCategoriesDataFetchLoader
          : displaySection === "paymentTerms"
            ? props.paymentTermsDataFetchLoader
            : displaySection === "plantItemMaster"
              ? props.plantItemMasterDataFetchLoader
              : displaySection === "tagsMaster"
                ? props.tagsMasterDataFetchLoader
                : displaySection === "bank"
                  ? props.bankMasterDataFetchLoader
                  : displaySection === "tdsRate"
                    ? props.tdsRateDataFetchLoader
                    : displaySection === "transporter"
                      ? props.transporterDataFetchLoader
                      : displaySection === "tcs"
                        ? props.tcsListDataFetchLoader
                        : displaySection === "tcsMatrix"
                          ? props.tcsListDataFetchLoader
                          : displaySection === "unit"
                            ? props.unitListDataFetchLoader
                            : displaySection === "pincode"
                              ? tabValue === '0'
                                ? props.pincodesListDataFetchLoader
                                : tabValue === '1'
                                  ? props.allCitiesListDataFetchLoader
                                  : props.allStateListDataFetchLoader
                              : displaySection === "itemNature"
                                ? props.itemNatureListDataFetchLoader
                                : displaySection === "reason"
                                  ? props.reasonListDataFetchLoader
                                  : displaySection === "customerType"
                                    ? props.customerTypeListDataFetchLoader
                                    : displaySection === "appBanner"
                                      ? props.appBannerListDataFetchLoader
                                      : displaySection === "appVersion"
                                        ? props.appVersionListDataFetchLoader
                                        : displaySection === "itemSize"
                                          ? props.itemSizeListDataFetchLoader
                                          : false,
      displaySection,
      currentPath: props.location.pathname,
      allCitiesList: props.allCitiesList,
      allStateList: props.allStateList,
      selectedStateDataToDiplay: props.allStateList,
      tabValue: props.tabValue
    };
  }
  handleCityChange = (event, value) => {
    console.log("value : " + value)
    if (value) {
      this.props.getPincodesList(value.id);
      this.setState({ page: 0 });
    } else {
      this.setState({ selectedCityId: null, });
    }
  };
  handleStateChange = (event, value) => {
    if (value) {
      this.props.getStateCityList(value.id);
      this.setState({ page: 0 });
    } else {
      this.setState({ selectedCityId: null });
    }
  };
  handleStateTabChange = (event, value) => {
    if (value) {
      this.setState({
        selectedStateDataToDiplay: this.state.allStateList.filter(data => data.id === value.id),
        page: 0
      });
      console.log("VALUE" + this.state.selectedStateDataToDiplay)
    }
    else {
      this.setState({
        selectedStateDataToDiplay: this.state.allStateList,
        page: 0
      });
    }
  };

  handlePincodeSearch = debounce((pincode) => {
    this.props.getPincode(pincode)
    this.setState({ page: 0 })
  }, 500)

  handleCitySearch = debounce((city) => {
    this.props.getCity(city)
    this.setState({ page: 0 })
  }, 500)

  render() {
    const {
      selectedDataToDiplay,
      selectedDataToDiplayLoader,
      tabValue,
      currentPath,
      displaySection,
      allCitiesList,
      allStateList,
      rowsPerPage,
      page,
    } = this.state;
    const { classes } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <div className={classes.rightPadding}>
            <Bread
              data={[
                {
                  title: "Master Management",
                  onClick: () =>
                    this.props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
                },
                {
                  title:
                    displaySection === "itemsCategory"
                      ? "Item Categories"
                      : displaySection === "paymentTerms"
                        ? "Payment Terms"
                        : displaySection === "plantItemMaster"
                          ? "Plant Item Master"
                          : displaySection === "tagsMaster"
                            ? "Item Tags"
                            : displaySection === "bank"
                              ? "Bank Master"
                              : displaySection === "tdsRate"
                                ? "TDS Rate Master"
                                : displaySection === "transporter"
                                  ? "Transporter Master"
                                  : displaySection === "tcs"
                                    ? "TCS Master"
                                    : displaySection === "tcsMatrix"
                                      ? "TCS Matrix"
                                      : displaySection === "unit"
                                        ? "Unit Master"
                                        : displaySection === "pincode"
                                          ? "Pincode Master"
                                          : displaySection === "itemNature"
                                            ? "Item Nature Master"
                                            : displaySection === "reason"
                                              ? "Reason Master"
                                              : displaySection === "customerType"
                                                ? "Customer Types Master"
                                                : displaySection === "appBanner"
                                                  ? "App Banner Master"
                                                  : displaySection === "appVersion"
                                                    ? "App Version Master"
                                                    : displaySection === "itemSize"
                                                      ? "Item Size Master"
                                                      : "",

                  onClick: "",
                },
              ]}
            />
            <div className={classes.displayData}>
              <div className={classes.searchAddNew}>
                {displaySection === "pincode" ? (
                  <Tabs
                    className={classes.tableHeader}
                    textColor="primary"
                    indicatorColor="primary"
                    value={tabValue}
                    onChange={this.handleTabChange}
                  >
                    <Tab
                      value='0'
                      label="PINCODE"
                      style={styles.tableHead(displaySection)}
                    />
                    <Tab
                      value='1'
                      label="CITY"
                      style={styles.tableHead(displaySection)}
                    />
                    <Tab
                      value='2'
                      label="STATES"
                      style={styles.tableHead(displaySection)}
                    />
                  </Tabs>)
                  :
                  null}
              </div>
            </div>
            <div className={classes.displayData}>
              <div className={classes.searchAddNew}>
                {displaySection === "pincode" ? (
                  <>
                    {/* <Tabs
                      className={classes.tableHeader}
                      textColor="primary"
                      indicatorColor="primary"
                      value={this.state.tabValue}
                      onChange={this.handleTabChange}
                    >
                      <Tab
                        value='0'
                        label="PINCODE"
                        style={styles.tableHead(displaySection)}
                      />
                      <Tab
                        value='1'
                        label="CITY"
                        style={styles.tableHead(displaySection)}
                      />
                      <Tab
                        value='2'
                        label="STATES"
                        style={styles.tableHead(displaySection)}
                      />
                    </Tabs> */}
                    {tabValue === '0' ? (
                      <>
                        <Autocomplete
                          key={tabValue}
                          className={classes.searchCityAlign}
                          size="small"
                          options={allCitiesList}
                          getOptionLabel={(option) => option.name}
                          onChange={this.handleCityChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Search by City"}
                              variant="outlined"
                            />
                          )}
                        />
                        <TextField
                          className={classes.searchCityAlign}
                          type="text"
                          label="Search by Pincode"
                          autoComplete="off"
                          value={this.state.searchPincode}
                          inputProps={{ maxLength: 6, minLength: 6, max: 6 }}
                          variant="outlined"
                          size="small"
                          onKeyPress={(event) => {
                            if (event.charCode >= 48 && event.charCode <= 57) {
                              // let it happen, don't do anything
                            }
                            else { event.preventDefault(); }
                          }}
                          onChange={(e) => {
                            this.setState({ searchPincode: e.target.value },
                              () => this.handlePincodeSearch(e.target.value)
                            )
                          }
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ padding: 0, display: this.state.searchPincode === '' ? 'none' : 'block' }}
                                  onClick={() => this.setState({ searchPincode: '', })}
                                >
                                  <CloseOutlinedIcon />
                                </IconButton>
                                <IconButton>
                                  <SearchOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    )
                      :
                      tabValue === '1' ?
                        (
                          <>
                            <Autocomplete
                              key={tabValue}
                              className={classes.searchCityAlign}
                              size="small"
                              options={allStateList}
                              getOptionLabel={(option) => option.name}
                              onChange={this.handleStateChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Search State"}
                                  variant="outlined"
                                />
                              )}
                            />
                            <TextField
                              className={classes.searchCityAlign}
                              type="text"
                              label="Search by City"
                              autoComplete="off"
                              value={this.state.searchCity}
                              variant="outlined"
                              size="small"
                              onChange={(e) => {
                                this.setState({ searchCity: e.target.value },
                                  () => this.handleCitySearch(e.target.value)
                                )
                              }
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      style={{ padding: 0, display: this.state.searchCity === '' ? 'none' : 'block' }}
                                      onClick={() => this.setState({ searchCity: '', })}
                                    >
                                      <CloseOutlinedIcon />
                                    </IconButton>
                                    <IconButton>
                                      <SearchOutlinedIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </>
                        )
                        :
                        (
                          <Autocomplete
                            key={tabValue}
                            className={classes.searchCityAlign}
                            size="small"
                            options={allStateList}
                            getOptionLabel={(option) => option.name}
                            onChange={this.handleStateTabChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Search State"}
                                variant="outlined"
                              />
                            )}
                          />
                        )
                    }
                  </>
                )
                  :
                  displaySection === 'transporter'
                    ? (
                      <TextField
                        id="outlined-basic"
                        // label="Search"
                        variant="outlined"
                        size="small"
                        style={{ width: 400, justifyContent: 'flex-start' }}
                        placeholder="Search using Name,PAN, GST, Transporter ID, Account code"
                        value={this.props.searchTransportText}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                style={{ padding: 0, display: this.props.searchTransportText === '' ? 'none' : 'block' }}
                                onClick={() => {
                                  this.setState({ page: 0 })
                                  this.props.handleSearchTransport(0, '');
                                }
                                }
                              >
                                <CloseOutlinedIcon />
                              </IconButton>
                              <IconButton style={{ padding: '0 2px', }}>
                                <SearchOutlinedIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            paddingRight: 0
                          }
                        }}
                        onChange={(e) => {
                          this.props.handleSearchTransport(0, e.target.value)
                          this.setState({ page: 0 })
                        }
                        }
                      />
                    )
                    : null}
                <div className={classes.addNewButtonAlign}>
                  {
                    displaySection === "tcsMatrix" ? null : (
                      <ToolTipComponent
                        title="You have no create permission on this widget"
                        condition={!["appBanner", "appVersion", "itemSize"].includes(displaySection) && !hasWriteAccess(this.props.user, this.handleMasterKeyCode(displaySection))}
                      >
                        <Button
                          className={classes.primaryBackground}
                          variant="contained"
                          startIcon={<AddOutlinedIcon />}
                          disabled={!["appBanner", "appVersion", "itemSize"].includes(displaySection) && !hasWriteAccess(this.props.user, this.handleMasterKeyCode(displaySection))}
                          onClick={() =>
                            this.props.history.push(currentPath + "/add")
                          }
                        >
                          ADD NEW
                        </Button>
                      </ToolTipComponent>
                    )
                  }
                </div>
              </div>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
                style={{
                  height: displaySection === "pincode" ?
                    "calc(100vh - 320px)"
                    :
                    "calc(100vh - 270px)"
                }}
              >
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell
                        align="left"
                        style={styles.tableHead(displaySection)}
                      >
                        {displaySection === "itemsCategory"
                          ? "CATEGORY"
                          : displaySection === "paymentTerms"
                            ? "NAME"
                            : displaySection === "plantItemMaster"
                              ? "PLANT NAME"
                              : displaySection === "tagsMaster"
                                ? "NAME"
                                : displaySection === "bank"
                                  ? "NAME"
                                  : displaySection === "unit"
                                    ? "UNIT NAME"
                                    : displaySection === "tdsRate"
                                      ? "TDS NAME"
                                      : displaySection === "transporter"
                                        ? "NAME"
                                        : displaySection === "tcs"
                                          ? "TCS PERCENT"
                                          : displaySection === "tcsMatrix"
                                            ? "ITEM NATURE"
                                            : displaySection === "pincode"
                                              ? tabValue === '0'
                                                ? "PIN CODE" :
                                                tabValue === '1'
                                                  ? "CITY"
                                                  : "STATE"
                                              : displaySection === "itemNature"
                                                ? "ITEM NATURE"
                                                : displaySection === "reason"
                                                  ? "MODULE"
                                                  : displaySection === "customerType"
                                                    ? "TYPE NAME"
                                                    : displaySection === "appBanner"
                                                      ? "CONTENT"
                                                      : displaySection === "appVersion"
                                                        ? "VERSION"
                                                        : displaySection === "itemSize"
                                                          ? "ITEM CATEGORY"
                                                          : ""}
                      </TableCell>
                      {displaySection === "tagsMaster" ||
                        displaySection === "pincode" ? null : (
                        <TableCell
                          align="center"
                          style={styles.tableHead(displaySection)}
                        >
                          {displaySection === "itemsCategory"
                            ? "STATES"
                            : displaySection === "paymentTerms"
                              ? "DAYS"
                              : displaySection === "plantItemMaster"
                                ? "CODE"
                                : displaySection === "bank"
                                  ? "BANK CODE"
                                  : displaySection === "unit"
                                    ? "UNIT CODE"
                                    : displaySection === "tcs"
                                      ? "TCS CODE"
                                      : displaySection === "tdsRate"
                                        ? "TDS CODE"
                                        : displaySection === "transporter"
                                          ? "MOBILE"
                                          : displaySection === "itemNature"
                                            ? "NATURE NAME"
                                            : displaySection === "reason"
                                              ? "CODE"
                                              : displaySection === "customerType"
                                                ? "DESCRIPTION"
                                                : displaySection === "appBanner"
                                                  ? "WITH EFFECT FROM"
                                                  : displaySection === "appVersion"
                                                    ? "WHAT'S NEW"
                                                    : displaySection === "itemSize"
                                                      ? "ITEM SIZE"
                                                      : ""}
                        </TableCell>
                      )}
                      {displaySection === "plantItemMaster" ||
                        displaySection === "tdsRate" ||
                        displaySection === "transporter" ? (
                        <TableCell
                          align="center"
                          style={styles.tableHead(displaySection)}
                        >
                          {displaySection === "plantItemMaster"
                            ? "PLANT ADDRESS"
                            : displaySection === "tdsRate"
                              ? "TDS RATE"
                              : displaySection === "transporter"
                                ? "EMAIL"
                                : ""}
                        </TableCell>
                      ) : null}
                      <TableCell
                        align={displaySection === "bank" ? "center" : "right"}
                        style={styles.tableHead(displaySection)}
                      >
                        {displaySection === "plantItemMaster"
                          ? "ITEM CATEGORIES"
                          : displaySection === "bank"
                            ? "ADDRESS"
                            : displaySection === "tdsRate"
                              ? "VALID FROM"
                              : displaySection === "reason"
                                ? "REASON"
                                : displaySection === "appBanner"
                                  ? "WITH EFFECT TO"
                                  : displaySection === "appVersion"
                                    ? "LAST EDITED"
                                    : displaySection === "itemSize"
                                      ? "LAST EDITED"
                                      : displaySection === "pincode"
                                        ? tabValue === '0'
                                          ? "CITY"
                                          : tabValue === '1'
                                            ? "STATE"
                                            : "STATE CODE"
                                        : "LAST EDITED"}
                      </TableCell>
                      {displaySection === "tdsRate" || displaySection === "tcsMatrix" ? (
                        <TableCell
                          align="right"
                          style={styles.tableHead(displaySection)}
                        >
                          {displaySection === "tdsRate"
                            ? "VALID TILL"
                            : displaySection === "tcsMatrix"
                              ? "CREATE/EDIT"
                              : "LAST EDITED"}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!selectedDataToDiplayLoader
                      ? selectedDataToDiplay.length > 0
                        ? (displaySection === 'transporter'
                          ? selectedDataToDiplay
                          : selectedDataToDiplay
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ))
                          .map((displayData, index) => (
                            <TableRow
                              key={index}
                              onClick={() =>
                                this.props.history.push(
                                  currentPath +
                                  `${displaySection === "plantItemMaster"
                                    ? "/" + displayData.id
                                    : displaySection === "bank"
                                      ? "/" + displayData.id
                                      : displaySection === "tdsRate"
                                        ? "/" + displayData.id
                                        : displaySection === "transporter"
                                          ? "/" + displayData.id
                                          : displaySection === "tcs"
                                            ? "/" + displayData.id
                                            : displaySection === "tcsMatrix"
                                              ? "/" + displayData.item_nature
                                              : displaySection === "unit"
                                                ? "/" + displayData.id
                                                : displaySection === "pincode"
                                                  ? "/" + displayData.id
                                                  : displaySection === "itemsCategory"
                                                    ? "/" + displayData.id
                                                    : displaySection === "itemNature"
                                                      ? "/" + displayData.item_nature
                                                      : displaySection === "reason"
                                                        ? "/" + displayData.id
                                                        : displaySection === "customerType"
                                                          ? "/" + displayData.id
                                                          : displaySection === "appBanner"
                                                            ? "/" + displayData.id
                                                            : displaySection === "appVersion"
                                                              ? "/" + displayData.id
                                                              : displaySection === "itemSize"
                                                                ? "/" + displayData.id
                                                                : "/view/" + displayData.id
                                  }`
                                )
                              }
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={styles.dataFont(displaySection)}
                                align="left"
                              >
                                {displaySection === "itemsCategory"
                                  ? displayData.category_name
                                  : displaySection === "paymentTerms"
                                    ? displayData.payment_method_name
                                    : displaySection === "plantItemMaster"
                                      ? displayData.plant_name
                                      : displaySection === "tagsMaster"
                                        ? displayData.tag_name
                                        : displaySection === "bank"
                                          ? displayData.bank_name
                                          : displaySection === "tdsRate"
                                            ? displayData.tds_name
                                            : displaySection === "transporter"
                                              ? displayData.acc_name
                                              : displaySection === "tcs"
                                                ? displayData.tcs_percent
                                                : displaySection === "tcsMatrix"
                                                  ? displayData.item_nature
                                                  : displaySection === "unit"
                                                    ? displayData.unit_name
                                                    : displaySection === "pincode"
                                                      ? tabValue === '0' ?
                                                        displayData.pincode
                                                        : tabValue === '1' ?
                                                          displayData.name
                                                          : displayData.name
                                                      : displaySection === "itemNature"
                                                        ? displayData.item_nature
                                                        : displaySection === "reason"
                                                          ? (this.props?.modules.find(d => d.id === displayData.module))?.name
                                                          : displaySection === "customerType"
                                                            ? displayData.type_name
                                                            : displaySection === "appBanner"
                                                              ? displayData.news
                                                              : displaySection === "appVersion"
                                                                ? displayData.app_version
                                                                : displaySection === "itemSize"
                                                                  ? displayData.item_name
                                                                  : ""}
                                {(displaySection === "itemNature" && displayData.is_service === false)
                                  ? <span className={classes.inactiveChipStyle}>
                                    inactive
                                  </span>
                                  : (displayData?.is_active !== undefined && displayData.is_active === false) &&
                                  <span className={classes.inactiveChipStyle}>
                                    inactive
                                  </span>
                                }
                              </TableCell>
                              {displaySection === "tagsMaster" ||
                                displaySection === "pincode" ? null : (
                                <TableCell
                                  align="center"
                                  style={styles.dataFont(displaySection)}
                                >
                                  {displaySection === "itemsCategory"
                                    ? displayData.states_count
                                    : displaySection === "paymentTerms"
                                      ? displayData.days
                                      : displaySection === "plantItemMaster"
                                        ? displayData.plant_code
                                        : displaySection === "bank"
                                          ? displayData.bank_code
                                          : displaySection === "tdsRate"
                                            ? displayData.tds_code //displaySection === "tdsRate"
                                            : displaySection === "tcs"
                                              ? displayData.tcs_code
                                              : displaySection === "transporter"
                                                ? displayData.mobile
                                                : displaySection === "unit"
                                                  ? displayData.unit_code
                                                  : displaySection === "itemNature"
                                                    ? displayData.nature_name
                                                    : displaySection === "reason"
                                                      ? displayData.reason_code
                                                      : displaySection === "customerType"
                                                        ? displayData.description
                                                        : displaySection === "appBanner"
                                                          ? moment(displayData.valid_from,
                                                            [
                                                              "DD/MM/YYYY hh:mm:ss A",
                                                              "DD/MM/YYYY hh:mm:ss",
                                                              "DD/MM/YYYY",
                                                              "YYYY-MM-DD hh:mm:ss A",
                                                              "YYYY-MM-DD",
                                                              "YYYY-MM-DD hh:mm:ss"
                                                            ]).format("DD-MMM-YY")
                                                          : displaySection === "appVersion"
                                                            ? displayData.new_updates
                                                            : displaySection === "itemSize"
                                                              ? displayData.size
                                                              : ""}
                                </TableCell>
                              )}

                              {displaySection === "tdsRate" ? (
                                <TableCell
                                  align="center"
                                  style={styles.dataFont(displaySection)}
                                >
                                  {displaySection === "tdsRate"
                                    ? displayData.tds_rate
                                    : ""}
                                </TableCell>
                              ) : null}
                              {displaySection === "plantItemMaster" ||
                                displaySection === "tdsRate" ||
                                displaySection === "transporter" ? (
                                <TableCell
                                  align={
                                    displaySection === "tdsRate"
                                      ? "right"
                                      : "center"
                                  }
                                  style={styles.dataFont(displaySection)}
                                >
                                  {displaySection === "plantItemMaster"
                                    ? displayData.plant_address
                                    : displaySection === "tdsRate"
                                      ? moment(displayData.tds_valid_from).format(
                                        "DD MMM YYYY"
                                      )
                                      : displaySection === "transporter"
                                        ? displayData.email
                                        : ""}
                                </TableCell>
                              ) : null}
                              <TableCell
                                align={displaySection === "bank" ? "center" : "right"}
                                style={styles.dataFont(displaySection)}
                              >
                                {displaySection === "plantItemMaster" ? (
                                  displayData.item_categories.map(
                                    (itemCategory) =>
                                      itemCategory.item_category_name + ", "
                                  )
                                ) : displaySection === "bank" ? (
                                  displayData.address
                                ) : displaySection === "appBanner" ? (
                                  displayData.valid_upto
                                    ? moment(displayData.valid_upto,
                                      [
                                        "DD/MM/YYYY hh:mm:ss A",
                                        "DD/MM/YYYY hh:mm:ss",
                                        "DD/MM/YYYY",
                                        "YYYY-MM-DD hh:mm:ss A",
                                        "YYYY-MM-DD",
                                        "YYYY-MM-DD hh:mm:ss"
                                      ]).format("DD-MMM-YY")
                                    : "-"
                                ) : displaySection === "appVersion" || displaySection === "itemSize" ? (
                                  moment(displayData.created_at,
                                    [
                                      "DD/MM/YYYY hh:mm:ss A",
                                      "DD/MM/YYYY hh:mm:ss",
                                      "DD/MM/YYYY",
                                      "YYYY-MM-DD hh:mm:ss A",
                                      "YYYY-MM-DD",
                                      "YYYY-MM-DD hh:mm:ss"
                                    ]).format("DD-MMM-YY")
                                ) : displaySection === "pincode" ?
                                  tabValue === '0' ?
                                    (displayData.city_detail.name)
                                    : tabValue === '1' ?
                                      (displayData.state_name)
                                      : (displayData.gst_state_code)
                                  : displaySection === "tdsRate" ? (
                                    moment(displayData.tds_valid_upto).format(
                                      "DD MMM YYYY"
                                    ))
                                    : displaySection === "reason" ? (
                                      displayData.reason)
                                      : (
                                        <div className={classes.alignText}>
                                          <div className={classes.rightSpacing}>
                                            {moment(displayData.modified_at).format(
                                              "DD MMM YYYY"
                                            )}
                                          </div>
                                          <div>
                                            <ArrowForwardIosOutlinedIcon
                                              className={classes.arrowDimension}
                                            />
                                          </div>
                                        </div>
                                      )}
                              </TableCell>
                              {displaySection === "tcsMatrix" ? (
                                <TableCell
                                  align="center"
                                  style={styles.dataFont(displaySection)}
                                >
                                  <Button
                                    onClick={() =>
                                      this.props.history.push(currentPath + "/add")
                                    }>
                                    {displayData.is_matrix_created ? "EDIT" : "CREATE"}</Button>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))
                        :
                        <div className={classes.noDataDiv} style={{ top: '0vh', left: '30vw', width: 'max-content' }}>
                          <NoDataFound />
                        </div>
                      :
                      <div className={classes.noDataDiv}>
                        <SpinLoader />
                      </div>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Paper>
                {displaySection === 'transporter'
                  ? <TablePagination
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={this.props?.totaltransportCount}
                    // rowsPerPage={rowsPerPage}
                    rowsPerPage={[20]}
                    page={page}
                    onChangePage={this.handleChangeTransportPage}
                  //onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  :
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    count={selectedDataToDiplay && selectedDataToDiplay.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    component="div"
                  />
                }
              </Paper>
            </div>
          </div>
        </div>
        <SnackbarComponent
          open={this.state.snackbarData.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={this.state.snackbarData.snackbarMessage}
          type={this.state.snackbarData.snackbarType}
        />
      </Layout>
    );
  }
}
export default withStyles(displayMasterStyles)(DisplayMastersDataDetailsList);
