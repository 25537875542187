import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  iOpenInDesktopBackgroundGradient,
  sloganTextColor,
  secondaryGrey,
  secondaryBlueText,
} from "../../../constants/internal/colors";
import { pxToEm } from "../../../methods";
import Button from "@material-ui/core/Button";
import DesktopImg from "../../common/DesktopImg";
import withAuthentication from "../../../hoc/withAuthentication";
import Layout from "../../../layouts/internalLayout/InternalLayout";
const openInDesktopStyles = (theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
    width: "100vw",
    height: "100vh",
    background: iOpenInDesktopBackgroundGradient,
  },
  logoImg: {
    top: "69px",
    width: "143px",
    height: "auto",
    opacity: "1",
  },
  captiopnSlogan: {
    color: sloganTextColor,
    fontSize: pxToEm(9),
    marginTop: 5,
  },
  details: {
    marginTop: "33px",
    fontSize: pxToEm(16),
    width: "268px",
    textAlign: "center",
    color: secondaryGrey,
  },
  desktopIcon: {
    marginTop: "29px",
    width: "59px",
    height: "59px",
    opacity: "1",
    colors: secondaryBlueText,
  },
  openBrowserRequest: {
    marginTop: "9px",
    color: secondaryBlueText,
    fontSize: pxToEm(15),
    textAlign: "center",
    opacity: 1,
  },
});

class OpenInDesktop extends React.Component {
  constructor(props) {
    super(props);
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.mainDiv}>
          <img
            src={
              window.cordova
                ? `${this.image}${"/RealDealWeb2.png"}`
                : "/RealDealWeb2.png"
            }
            alt={"REAL Deals"}
            className={classes.logoImg}
            style={{
              marginBottom: 15
            }}
          />
          <span className={classes.captionSlogan}>
            Online Portal for faster deals
          </span>
          <span className={classes.details}>
            You are on a mobile device Currently, Admin portal is only available
            for Desktop.
          </span>
          <div style={{ marginTop: "20px" }}>
            <DesktopImg />
          </div>
          <span className={classes.openBrowserRequest}>
            Kindly open this on a Desktop Browser
          </span>
          <Button
            variant="outlined"
            style={{ marginTop: "20px", color: secondaryBlueText }}
            onClick={(e) => {
              e.preventDefault();
              this.props.logout();
            }}
          >
            Logout
          </Button>
        </div>
      </Layout>
    );
  }
}
export default withAuthentication(
  withStyles(openInDesktopStyles)(OpenInDesktop)
);
