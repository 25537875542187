import React, { useRef } from "react";
import PropTypes from "prop-types";
import Row from "./Row";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  TablePagination,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  TextField,
  InputAdornment,
  Chip,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Switch,
  InputLabel,
  FormHelperText,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  successButtonBackground,
  drawerTextColor,
  secondaryBlueText,
  whiteSmoke,
  orange,
  earthBlue,
  drawerBackground,
} from "../../../../constants/internal/colors";
import { hasWriteAccess, pxToEm, UpperCaseText } from "../../../../methods";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Form, Formik } from "formik";
import { validationsItemTypesDetails } from "./ItemCategoryValidationSchema";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import COLORS from "../../../../constants/internal/colors";
import ItemDialog from "./ItemDialog";
import ToolTipComponent from "../../common/ToolTipComponent";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const CustomCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props.defaultChecked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  deleteAndExpandActionStyle: { display: "flex" },
  sectionNameAndWeightStyles: {
    display: "flex",
    alignItems: "center",
    placeContent: "flex-start",
    justifyContent: "space-evenly",
  },
  inputWidth: {
    // maxWidth: 200,
    width: "10rem"
  },
  unitSelect: {
    width: "10rem",
    position: "relative"
  },
  helperText: {
    position: "absolute"
  },
  tableCellWidth: {
    minWidth: "10rem"
  },
  contentTitle: {
    color: grey,
    fontSize: pxToEm(16),
    fontWeight: "bold",
  },
  itemTypeMainDiv: {
    marginTop: 30,
  },
  tableContainer: {
    marginTop: 10,
    width: "100%",
    height: "calc(100vh - 400px)",
    overflow: "auto",

    '&::-webkit-scrollbar': {
      height: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(75, 135, 197, 0.9)',
      borderRadius: 10,
      border: '2px solid transparent',
      backgroundClip: 'padding-box', // <== make the border work
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(75, 135, 197, 0.9)',
      // height: 30,
      // border: 0,
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
  },
  table: {
    width: "100%",
  },
  tableHead: {
    width: "10rem",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  actionsDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "row",
  },
  addAndSaveButton: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0px 0px 0px",
    justifyContent: "space-between",
  },
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  saveCancelButton: {
    display: "flex",
    placeSelf: "strech",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    color: grey,
  },
  editTextIcon: {
    borderRadius: 100,
    padding: 2,
    marginLeft: "10px",
    width: "70px",
    display: "flex",
    backgroundColor: whiteSmoke,
    color: grey,
    cursor: "pointer",
  },
  editText: {
    flex: 1,
    marginLeft: 5,
    textAlign: "left",
  },
  icon: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
  },
  popoverBox: {
    '& .MuiPopover-paper': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
      marginBottom: 10,
      // borderRadius: 20,
    }
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '500',
    alignItems: "center"
  },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const styles = {
  chips: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  chip: {
    margin: 2,
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: "80%"
  },
};

export default function ManageItemCategoryItemTypesTab(props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [newItemUnderCategoryData, setNewItemUnderCategoryData] =
    React.useState({
      item_code: "",
      item_name: "",
      hsn_code: "",
      rate_difference: "",
      discount_amount: "",
      item_category: props?.selectedItemId,
      unit: "",
      items_nature: "",
      packing_weight: "",
      tolerance_upto_1mts: "",
      tolerance_from_1to5mts: "",
      tolerance_above_5mts: "",
      tags: [],
      sections: [
        {
          section_name: "",
          section_weight: "",
          item: "",
        },
      ],
      operation: "add",
      // nature_name: "",
      is_service: "",
    });
  const [displayAddNewButton, setDisplayAddNewButton] = React.useState(true);
  const [addNewItem, setAddNewItem] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [allTagsData, setAllTagsData] = React.useState(props?.allTagsData);
  const [allUnits, setAllUnits] = React.useState(props?.allUnits || []);
  const [allItemSize, setAllItemSize] = React.useState(props?.allItemSize || []);
  const [allItemNature, setAllItemNature] = React.useState(
    props?.allItemNature || []
  );
  const [allItemTypes, setAllItemTypes] = React.useState(
    props?.getItemTypes || []
  );
  const [openItemTypes, setOpenItemTypes] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [selectedData, setSelectedData] = React.useState({});
  const [searchItemType, setSearchItemType] = React.useState('')
  const [searchItemField, setSearchItemField] = React.useState([
    'item_code',
    'item_name'
  ])

  const handleClickOpenItemTypes = (scrollType) => () => {
    //handleSetItemTypeOperation("add");
    setNewItemUnderCategoryData({
      item_code: "",
      item_name: "",
      hsn_code: "",
      rate_difference: "",
      discount_amount: "",
      item_category: props?.selectedItemId,
      unit: "",
      items_nature: "",
      packing_weight: "",
      tolerance_upto_1mts: "",
      tolerance_from_1to5mts: "",
      tolerance_above_5mts: "",
      tags: [],
      sections: [
        {
          section_name: "",
          section_weight: "",
          item: "",
        },
      ],
      operation: "add",
      // nature_name: "",
      is_service: "",
    });
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClickEditItemTypes = (scrollType) => () => {
    setOpenItemTypes(true);
    setScroll(scrollType);
  };

  const handleCloseItemTypes = () => {
    // setOpenItemTypes(false);
    setOpen(false);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowSearchData = (allItemData) => {
    const formatedItemsData = allItemData.map((i) => (
      {
        ...i,
        tags: i.tags.map((t) => {
          const metaTag = props.allTagsData.find(p => p.tag_name == t.tag_name);
          return { id: metaTag?.id, tag_name: metaTag?.tag_name }
        })
      }
    )
    );
    setRows(formatedItemsData);
  }

  const handleItemSearch = (searchText) => {
    setSearchItemType(searchText)
    if (searchText !== '') {
      const filterItems = props.itemsData.filter(d => {
        return searchItemField.find(field => {
          return UpperCaseText(d[field]).includes(UpperCaseText(searchText))
        })
      })
      handleRowSearchData(filterItems)
    }
    else { handleRowSearchData(props.itemsData) }
  }

  const handleChange = (index, name, identifier, sectionIndex) => (event) => {
    if (identifier === "addNewData") {
      if (name === "section_name" || name === "section_weight") {
        let sectionDataRef = newItemUnderCategoryData["sections"][0];
        let sectionData = (sectionDataRef[name] = event.target.value)
        setNewItemUnderCategoryData({
          ...newItemUnderCategoryData,
        });
      } else {
        setNewItemUnderCategoryData({
          ...newItemUnderCategoryData,
          [name]: ['item_code'].includes(name)
            ? UpperCaseText(event.target.value)
            : name === 'is_active'
              ? event.target.checked
              : name === 'valid_from'
                ? moment(event).format("YYYY-MM-DD")
                : event.target.value,
        });
      }
    } else {
      if (name === "section_name" || name === "section_weight") {
        let selectedRow = rows.find((f) => f.id === index);
        if (selectedRow.sections.length !== 0) {
          let sectionDataRef = selectedRow["sections"][0];
          let sectionData = (sectionDataRef[name] = event.target.value);
          setRows([...rows]);
        } else {
          setNewItemUnderCategoryData({
            ...newItemUnderCategoryData,
          })
          // let sectionDataRef = newItemUnderCategoryData["sections"];
          // let sectionData = (sectionDataRef[0][name] = event.target.value)

        }
      } else if (name === "tags") {
        const updatedRowsValues = [...rows];
        let selectedTagRow = updatedRowsValues.find((f) => f.id === index);
        let selectedValues = event.target.value;
        // if(selectedTagRow["tags"].filter( e => )){

        // }
        let tagValues = selectedValues.map((e) => ({
          id: e.id,
          tag: e.id,
          tag_name: e.tag_name,
          item: selectedTagRow?.id,
        }));
        selectedTagRow["tags"] = tagValues;
        setRows(updatedRowsValues);
      } else {
        const updatedRowsValues = [...rows];
        let selectedRow = updatedRowsValues.find((f) => f.id === index);
        selectedRow[name] = name === 'is_active'
          ? event.target.checked
          : name === 'valid_from'
            ? moment(event).format("YYYY-MM-DD")
            : event.target.value;

        setRows(updatedRowsValues);
      }
    }
  };

  const [itemTypeTableOperation, setItemTypeTableOperation] =
    React.useState("view");

  const handleSetItemTypeOperation = (operation, index) => {
    if (operation === "add") {
      setNewItemUnderCategoryData({
        item_code: "",
        item_name: "",
        hsn_code: "",
        rate_difference: "",
        discount_amount: "",
        item_category: props?.selectedItemId,
        unit: "",
        items_nature: "",
        packing_weight: "",
        tolerance_upto_1mts: "",
        tolerance_from_1to5mts: "",
        tolerance_above_5mts: "",
        tags: [],
        sections: [
          {
            section_name: "",
            section_weight: "",
            item: "",
          },
        ],
        operation: "add",
        // nature_name: "",
        is_service: "",
      });
      // setAddNewItem(true);
      // setItemTypeTableOperation(operation);
    } else if (operation === "edit") {
      const updatedRowsValues = [...rows];
      const updatedRowValue = updatedRowsValues.find((u) => u.id === index);
      updatedRowValue["operation"] = "edit";
      setRows(updatedRowsValues);
      setDisplayAddNewButton(true);
    } else {
      // setItemTypeTableOperation(operation);
      const updatedRowsValues = [...rows];
      const updatedRowValue = updatedRowsValues.find((u) => u.id === index);
      if (updatedRowValue === undefined || updatedRowValue === null) {
        setAddNewItem(false);
        setDisplayAddNewButton(true);
      } else {
        updatedRowValue["operation"] = "view";
        setRows(updatedRowsValues);
        setDisplayAddNewButton(true);
      }
    }
  };

  const handleUpdateItemData = (dataToBeUpdated, index) => {
    let isError = false;
    if (props.rate_difference < 0) {
      isError = true;
      this.setState({
        error: true,
        errorMessage: { zipCode: "It should not be less than 0." },
      });
    }
    let tagsData = dataToBeUpdated.tags;

    const updatedRowsValues = [...rows];
    if (dataToBeUpdated.operation === "add") {
      updatedRowsValues.push(dataToBeUpdated);
      props.handleItemAndSectionDataSave(dataToBeUpdated)
        ?.then((response) => {
          if (response.status === 201 || response.status === 200) {
          }
        })
        .catch((error) => { console.log("error catch"); setAddNewItem(true) })
      setAddNewItem(false);
    } else {
      // for (let key in dataToBeUpdated) {
      // }
      props.handleItemAndSectionDataUpdate(dataToBeUpdated);
      setAddNewItem(false);
    }

    setRows(updatedRowsValues);
    setDisplayAddNewButton(true);
  };

  const isArray = (a) => {
    return !!a && a.constructor === Array;
  };
  React.useEffect(() => {
    console.log(props.itemsData)
    const itemsData = props.itemsData;
    const formatedItemsData = itemsData.map((i) => (
      {
        ...i,
        tags: i.tags.map((t) => {
          const metaTag = props.allTagsData.find(p => p.tag_name == t.tag_name);
          return { id: metaTag?.id, tag_name: metaTag?.tag_name }
        })
      }
    )
    );
    // console.log(formatedItemsData)
    // console.log(props.itemsData)
    setRows(formatedItemsData);
    setAllTagsData(props.allTagsData);
    setAllUnits(props.allUnits);
    setAllItemSize(props.allItemSize);
    setAllItemNature(props.allItemNature);
    setAllItemTypes(props.allItemTypes);
  }, [
    props.itemsData,
    props.allTagsData,
    props.allUnits,
    props.allItemSize,
    props.allItemNature,
    props.allItemTypes,
  ]);

  const addNewEndRef = useRef(null)

  const scrollToBottom = () => {
    addNewEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <div className={classes.itemTypeMainDiv}>
      <div className={classes.iconContainer}>
        <span className={classes.contentTitle}>ITEMS UNDER CATEGORY</span>
        <span style={{ marginLeft: 40 }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            style={{ width: 500, justifyContent: 'flex-start', }}
            placeholder="Search using Item name & code"
            value={searchItemType}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ padding: 0, display: searchItemType === '' ? 'none' : 'block' }}
                    onClick={() => {
                      setPage(0)
                      handleItemSearch('');
                    }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                  <IconButton style={{ padding: '0 2px', }}>
                    <SearchOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                paddingRight: 0
              }
            }}
            onChange={(e) => {
              handleItemSearch(e.target.value)
              setPage(0)
            }}
          />
        </span>
      </div>
      <Paper>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableHead}>
                  ITEM NAME
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  ITEM CODE
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  ITEM SIZE
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  HSN CODE
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  RATE DIFFERENCE
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  RATE EFFECTIVE FROM
                </TableCell>
                {/* <TableCell align="left" className={classes.tableHead}>
                  DISCOUNT
                </TableCell> */}
                <TableCell align="left" className={classes.tableHead}>
                  UNIT
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  ITEM NATURE
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  TAGS
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  SECTION NAME
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  SECTION WEIGHT
                </TableCell>
                {props.itemConsiderPacking ?
                  <>
                    <TableCell align="left" className={classes.tableHead}>
                      PACKING WEIGHT
                    </TableCell>
                    <TableCell align="left" className={classes.tableHead}>
                      TOLERANCE UPTO 1 MTS
                    </TableCell>
                    <TableCell align="left" className={classes.tableHead}>
                      TOLERANCE FROM 1 TO 5 MTS
                    </TableCell>
                    <TableCell align="left" className={classes.tableHead}>
                      TOLERANCE ABOVE 5 MTS
                    </TableCell>
                  </>
                  : <></>}
                <TableCell align="left" className={classes.tableHead}>
                  ACTIVE
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableHead}
                ></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : rows
              )?.map((row, index) => (
                <>
                  <Row
                    key={index}
                    parentRow={row}
                    itemTypeTableOperation={itemTypeTableOperation}
                    identifier={"dataFromDatabase"}
                    index={row.id}
                    handleChange={handleChange}
                    allTagsData={allTagsData}
                    handleSetItemTypeOperation={handleSetItemTypeOperation}
                    handleUpdateItemData={handleUpdateItemData}
                    handleItemAndSectionDataUpdate={props.handleItemAndSectionDataUpdate}
                    allUnits={allUnits}
                    allItemSize={allItemSize}
                    allItemNature={allItemNature}
                    itemConsiderPacking={props.itemConsiderPacking}
                    handleClickOpenItemTypes={handleClickOpenItemTypes}
                    handleClickEditItemTypes={handleClickEditItemTypes}
                    setOpenItemTypes={setOpenItemTypes}
                    handleCloseItemTypes={handleCloseItemTypes}
                    openItemTypes={openItemTypes}
                    scroll={scroll}
                    setScroll={setScroll}
                    setRows={setRows}
                    rows={rows}
                    user={props.user}
                  />
                  {/* <ItemTypesDialog {...props} row={row} index={row.id} /> */}
                </>
              ))}
              {addNewItem ? (
                <>
                  <Row
                    key={"addNew"}
                    parentRow={newItemUnderCategoryData}
                    itemTypeTableOperation={itemTypeTableOperation}
                    // handleChange={props.handleChange}
                    rows={rows}
                    setRows={setRows}
                    identifier={"addNewData"}
                    index={rows.length}
                    handleChange={handleChange}
                    allTagsData={allTagsData}
                    handleSetItemTypeOperation={handleSetItemTypeOperation}
                    handleUpdateItemData={handleUpdateItemData}
                    allUnits={allUnits}
                    allItemSize={allItemSize}
                    allItemNature={allItemNature}
                    itemConsiderPacking={props.itemConsiderPacking}
                    handleClickOpenItemTypes={handleClickOpenItemTypes}
                    setOpenItemTypes={setOpenItemTypes}
                    openItemTypes={openItemTypes}
                    handleCloseItemTypes={handleCloseItemTypes}
                    handleClickEditItemTypes={handleClickEditItemTypes}
                    scroll={scroll}
                    setScroll={setScroll}
                    user={props.user}
                  />
                  <div ref={addNewEndRef} />
                </>) : null}
              {/* {props.newItemUnderCategoryData.length > 0 ? (
                <Row
                  key={"addNew"}
                  row={props.newItemUnderCategoryData}
                  itemTypeTableOperation={itemTypeTableOperation}
                  handleChange={props.handleChange}
                  identifier={"addNewData"}
                />
              ) : null} */}
            </TableBody>

          </Table>
        </TableContainer>
        {rows.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
      <div className={classes.addAndSaveButton}>
        {displayAddNewButton ? (
          <ToolTipComponent
            title="You have no create permission on this widget"
            condition={!hasWriteAccess(props.user, 'item_categories')}
          >
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddOutlinedIcon />}
              className={classes.addNewButton}
              disabled={!hasWriteAccess(props.user, 'item_categories')}
              onClick={
                handleClickOpenItemTypes('paper')
              }
            >
              ADD NEW
            </Button>
          </ToolTipComponent>
        ) : null}

        {/* {itemTypeTableOperation ===
        "view" ? null : props.saveItemUnderCategoryLoader ? (
          <CircularProgress disableShrink />
        ) : (
          <div className={classes.saveCancelButton}>
            <Button
              size="medium"
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={() => handleSetItemTypeOperation("view")}
            >
              {itemTypeTableOperation === "add" ? "Save" : "Update"}
            </Button>

            <Button
              size="medium"
              onClick={() => handleSetItemTypeOperation("view")}
            >
              Cancel
            </Button>
          </div>
        )} */}
      </div>
      {
        open ? <ItemDialog {...props} record={newItemUnderCategoryData} open={open}
          handleUpdateItemData={handleUpdateItemData}
          handleCloseItemTypes={handleCloseItemTypes} identifier={"addNewData"}
          itemConsiderPacking={props.itemConsiderPacking}
          user={props.user}
        /> : <></>

      }
    </div >
  );
}
