import React, { Component } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayItemSizeData from "../../../../components/internal/masterManagement/itemSize/web";
import ManageItemSizeData from "../../../../components/internal/masterManagement/itemSize/ManageItemSizeData"
import { arraySortBykeyAllBrowser, checkApiError } from "../../../../methods";

class ManageItemSizeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      itemSizeOperation: null,
      selectedItemSize: null,
      selectedItemSizeData: [],
      selectedItemSizeDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      isLoadingRemove: false,
      removeItemSizeLoader: false,
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
      itemCategoriesList: [],
      itemCategoriesDataFetchLoader: false,
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.fetchItemCateogriesList()
    let itemSizeOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ itemSizeOperation });
    if (itemSizeOperation !== "add") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedItemSizeData(id);
      this.setState({ selectedItemSize: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let itemSizeOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedItemSize =
      itemSizeOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (itemSizeOperation !== state.itemSizeOperation) {
      updatedData.set("itemSizeOperation", itemSizeOperation);
    }
    if (selectedItemSize !== state.selectedItemSize) {
      updatedData.set("selectedItemSize", selectedItemSize);
    }
    if (updatedData.size > 0) {
      return {
        itemSizeOperation: updatedData.has("itemSizeOperation")
          ? updatedData.get("itemSizeOperation")
          : itemSizeOperation,
        selectedItemSize: updatedData.has("selectedItemSize")
          ? updatedData.get("selectedItemSize")
          : [],
      };
    }
    return null;
  }

  fetchItemCateogriesList = () => {
    if (!(this.state.itemCategoriesList.length > 0)) {
      console.log("jherj", this.state.itemCategoriesList, this.state.itemCategoriesList.length === 0)
      this.setState({ itemCategoriesDataFetchLoader: true });
      axios
        .get(`${APIROUTES.GET_ITEM_CATEGORIES_LIST}?active=${true}`, this.getOptions(this.props.user))
        .then(
          (response) => {
            let responseData = arraySortBykeyAllBrowser(response.data, 'category_name');
            this.setState({
              itemCategoriesList: responseData,
              itemCategoriesDataFetchLoader: false,
            });
          },
          (error) => {
            console.log("error", error);
            this.setState({ itemCategoriesDataFetchLoader: false });
          }
        );
    }
  };

  handleFetchSelectedItemSizeData = (selectedItemSize) => {
    console.log("herere")
    this.setState({ selectedItemSizeDataFetchLoader: true });
    axios
      .get(
        `${APIROUTES.GET_ITEM_SIZE}${selectedItemSize}`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedItemSizeData: response.data,
              selectedItemSizeDataFetchLoader: false,
            });
          }
        },
        (error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        }
      );
  };

  handleItemSizeDataAdd = (itemSizeData) =>
    new Promise((resolve, reject) => {
      const data = {
        item_category: itemSizeData.item_category,
        size: itemSizeData.size,
        is_active: itemSizeData.is_active
      }
      axios
        .post(
          APIROUTES.GET_ITEM_SIZE,
          data,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedItemSizeData(response.data.id);
          this.props.getItemSizeList();
          resolve(response);
        })
        .catch(reject);
    });
  handleItemSizeDataUpdate = (itemSizeData) =>
    new Promise((resolve, reject) => {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      const data = {
        item_category: itemSizeData.item_category,
        size: itemSizeData.size,
        is_active: itemSizeData.is_active
      }
      axios
        .patch(
          `${APIROUTES.GET_ITEM_SIZE}${id}`,
          data,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedItemSizeData(id);
          this.props.getItemSizeList();
          resolve(response);
        })
        .catch(reject);
    });
  handleCloseSnackbar = (event, itemSize) => {
    if (itemSize === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleRemoveItemSize = () => {
    this.setState({
      openDialog: true,
      dialogTitle: `Remove `,
      dialogContent: `Are you sure you want to Delete this Item Size Detail ?`,
    });
  }
  handleRemoveConfirmationDialogClose = () => {
    this.setState({
      openDialog: false,
      dialogTitle: "",
      dialogContent: "",
    });
  };

  handleConfirmationDialogDeleteClick = () => {
    // setIsLoadingRemove(true)
    this.handleRemoveConfirmationDialogClose()
    this.setState({ removeItemSizeLoader: true })
    axios.delete(`${APIROUTES.GET_ITEM_SIZE}${this.state?.selectedItemSize}`, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 204) {
          this.handleOpenSnackbar(`Item Size Removed Successfully`, "success")
          setTimeout(() => {
            this.setState({ removeItemSizeLoader: false })
            this.props.getItemSizeList();
            this.props.history.push(INTERNALROUTES.ITEM_SIZE);
            this.handleCloseSnackbar();
          }, 4000);
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
          "error"
        );
        setTimeout(() => {
          this.setState({ removeItemSizeLoader: false })
          this.handleCloseSnackbar();
        }, 4000);
      });
  };

  render() {
    const {
      itemSizeOperation,
      itemCategoriesList,
    } = this.state;

    const {

    } = this.props;

    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;

    else {
      return (
        <>
          {itemSizeOperation === "view" ? (
            <DisplayItemSizeData
              {...this.props}
              {...this.state}
              itemCategoriesList={itemCategoriesList}
              selectedItemSizeData={this.state?.selectedItemSizeData}
              itemSizeOperation={this.state?.itemSizeOperation}
              selectedItemSizeDataFetchLoader={this.state?.selectedItemSizeDataFetchLoader}
              selectedItemSize={this.state?.selectedItemSize}
              openDialog={this.state?.openDialog}
              dialogTitle={this.state?.dialogTitle}
              dialogContent={this.state?.dialogContent}
              isLoadingRemove={this.state?.isLoadingRemove}
              removeItemSizeLoader={this.state?.removeItemSizeLoader}
              handleRemoveItemSize={this.handleRemoveItemSize}
              handleRemoveConfirmationDialogClose={this.handleRemoveConfirmationDialogClose}
              handleConfirmationDialogDeleteClick={this.handleConfirmationDialogDeleteClick}
            />
          ) : (
            <ManageItemSizeData
              {...this.props}
              {...this.state}
              itemCategoriesList={itemCategoriesList}
              itemSizeList={this.props?.itemSizeList}
              handleItemSizeDataUpdate={this.handleItemSizeDataUpdate}
              handleItemSizeDataAdd={this.handleItemSizeDataAdd}
              selectedItemSizeData={this.state?.selectedItemSizeData}
              itemSizeOperation={this.state?.itemSizeOperation}
              selectedItemSizeDataFetchLoader={this.state?.selectedItemSizeDataFetchLoader}
              selectedItemSize={this.state?.selectedItemSize}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleCloseSnackbar}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManageItemSizeContainer);
