import React, { Component } from 'react'
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const styles = {
    formControlStyle: {
        width: "100%",
    },
}
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null

}

export class FilterByPaymentMethod extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }
    render() {
        const { paymentMethods, selectedPaymentMethodFilter } = this.props
        return (
            <div style={styles.formControlStyle}>
                <FormControl variant="outlined" style={styles.formControlStyle}>
                    <InputLabel
                        id="label-id"
                        ref={(ref) => {
                            this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                    >
                        Payment Term
                    </InputLabel>
                    <Select
                        // id="demo-simple-select-outlined"
                        // value={this.state.age}
                        // onChange={this.handleChange}
                        input={
                            <OutlinedInput
                                label="Payment Term"
                                labelWidth={this.state.labelWidth}
                                name="PaymentTerm"
                                id="outlined-age-simple"
                                value={selectedPaymentMethodFilter && selectedPaymentMethodFilter || ""}
                            />
                        }
                        MenuProps={MenuProps}
                    >
                        {paymentMethods &&
                            paymentMethods.map((data) => (
                                <MenuItem
                                    key={data.id}
                                    value={data.payment_method_name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleSelect({
                                            field: "selectedPaymentMethodFilter",
                                            data,
                                            selectedName: data.payment_method_name
                                        })
                                    }}
                                >
                                    {data.payment_method_name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

export default FilterByPaymentMethod