import React, { Component } from 'react'
import { Button, makeStyles } from "@material-ui/core";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Print from "@material-ui/icons/Print";
import Tooltip from "@mui/material/Tooltip";
import Moment from 'moment'
import imageCode from "./ImageCode.json"
import image from "../../../../styles/icon.png"
import { contractsSubHeaders, contractsPdfHeaders } from '../headers'
import pdfContentData from './data.json'
import { printPDFInMobile } from '../../../../methods';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
})


let contracts
let plantsList = []

let columnSpansList = []

export class ReportsPDFDownload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tableData: [],
            tabValue: "",
        }
    }
    getNestedTableHeaders = (list) => {
        // console.log(list)
        let headers = []

        list.forEach(ele => {
            headers.push({
                text: ele,
                style: ["itemsHeader", "left"],
                border: [false, false, false, false],
                // colSpan: columnSpansList.includes(head.header) ? plantsList.length : 1
            })
        })
        return headers
    }


    fetchHeaders = (pdfProps) => {
        // console.log(pdfProps)
        let tableHeaders = []
        let tableSubHeaders = []
        let headers = []

        headers = contractsPdfHeaders.filter(d => d?.header !== "Rate")
        console.log(headers)
        headers.forEach(header => {
            if (header.splitCol) {
                columnSpansList.push(header.header)
            }
        })

        plantsList = pdfProps?.state?.planned_plants || []

        // console.log(columnSpansList, plantsList, pdfProps?.state)
        let subheaders = [{ header: "", key: "" }]
        // console.log(headers)
        headers.map((head, i) => {
            tableHeaders.push({
                text: head.header,
                style: ["itemsHeader", "center"],
                border: [true, true, true, true],
                colSpan: columnSpansList.includes(head.header) ? plantsList.length : 1
            })
            // columnSpansList.forEach((span, j) => {
            if (columnSpansList.includes(head.header)) {
                console.log("here")
                plantsList.map((plant, k) => {
                    tableSubHeaders.push({
                        text: plant.plant_code,
                        style: ["itemsHeader", "center"],
                        border: [true, true, true, true],
                    })
                    // plant["key"] = `${head.key}_${plant.plant_code}`s
                    subheaders.push(plant)

                    if (k !== 0) {
                        tableHeaders.push({
                            text: '',
                            style: ["itemsHeader", "center"],
                            border: [true, true, true, true],
                            // colSpan: columnSpansList.includes(head.header) ? plantsList.length : 1
                        })
                    }
                })

            } else {
                tableSubHeaders.push({
                    text: "",
                    style: ["itemsHeader", "center"],
                    border: [false, false, false, false],
                })

                subheaders.push(head)
            }
        })


        let finalHeaders = []
        let contractsSubHeaderss = []
        contractsPdfHeaders.forEach(header => {

            if (columnSpansList.includes(header.header)) {
                plantsList.forEach(plant => {
                    contractsSubHeaderss.push(
                        {
                            minWidth: 40,
                            header: `${plant.plant_code}`,
                            key: `${header.header}_${plant.plant_code}`,
                        }
                    )

                })
            } else {
                contractsSubHeaderss.push({
                    minWidth: 40,
                    header: "",
                    key: ""
                })
            }
        })



        headers.forEach((header, i) => {
            // console.log(header.text, i)
            if (columnSpansList.includes(header.header)) {
                // finalHeaders.push(header)
                plantsList.forEach(plant => {
                    // console.log(`${header.key}_${plant.plant_code}`)
                    let key = `${header.key}_${plant.plant_code}`
                    // console.log(key)
                    finalHeaders.push({ plant_code: plant.plant_code, key })
                })
            } else {
                finalHeaders.push(header)
            }
        })



        // console.log(finalHeaders)
        if (headers) {
            this.handleCalculations({ list: JSON.parse(JSON.stringify(pdfProps.data)), pdfProps, tableHeaders, headers, pdfContentData, tableSubHeaders, subheaders, finalHeaders })
            // this.handlePDFDownload({ pdfProps, tableHeaders, headers, pdfContentData, tableSubHeaders, subheaders, finalHeaders })
        }
    }

    groupBy = key => array =>
        array.reduce((objectsByKeyValue, obj) => {
            if (obj[key]) {
                const value = obj[key];
                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            }
            return objectsByKeyValue;
        }, {});

    handleCalculations = (data) => {
        const { list, pdfProps, tableHeaders, headers } = data
        let calculationNeededFor = []
        // console.log(pdfProps)
        headers.forEach(header => {
            if (header.calculation) {
                calculationNeededFor.push(header)
            }
        })

        let groupByLA = this.groupBy('customer_code')
        let groupedList = groupByLA(list)
        console.log(groupedList)
        Object.keys(groupedList).forEach((element, index) => {
            // console.log(element)
            if (element !== "undefined")
                groupedList[element].forEach((ele, i) => {
                    // console.log(element, ele.index, `${ele.index}.${i + 1}`)

                    ele.index = `${index + 1}.${i + 1}`
                })

        })
        Object.keys(groupedList).forEach(element => {

            let fromIndex = list.findIndex(ele => ele.customer_code === element)
            let toIndex = list.findLastIndex(ele => ele.customer_code === element)

            let calcObj = {}
            calculationNeededFor.forEach(calc => {
                if (["allotted_qty", "dispached_qty", "cancelled_qty", "balance_qty"].includes(calc.key)) {
                    plantsList.map(plant => {
                        let calcValue = 0
                        let key = `${calc.key}_${plant.plant_code}`
                        for (let i = fromIndex; i <= toIndex; i++) {
                            let item = list[i]
                            // console.log(key, item[key])
                            calcValue += Number(item[key] === undefined ? 0 : item[key])
                        }
                        // console.log(key, calcValue, fromIndex, toIndex)
                        calcObj[key] = calc.calculation === "avg_net_rate"
                            || calc.calculation === "avg_discount"
                            || calc.calculation === "avg_tare_weight" ?
                            (calcValue / (toIndex - fromIndex + 1)).toFixed(calc.dec_places) :
                            calcValue.toFixed(calc.dec_places)
                        // console.log(key, calcObj[key])
                    })
                }
                else {
                    let calcValue = 0
                    let key = calc.key
                    for (let i = fromIndex; i <= toIndex; i++) {
                        let item = list[i]
                        // console.log(item)
                        calcValue += Number(item[key])
                    }
                    if (calc?.key2 !== undefined && calc?.key2 === 'quantity') {
                        let calcValue2 = 0
                        let key = calc.key2
                        for (let i = fromIndex; i <= toIndex; i++) {
                            let item = list[i]
                            // console.log(item)
                            calcValue2 += Number(item[key])
                        }
                        calcObj[key] = calcValue2.toFixed(calc.dec_places)
                    }

                    calcObj[key] = calc.calculation === "avg_net_rate"
                        || calc.calculation === "avg_discount"
                        || calc.calculation === "avg_tare_weight" ?
                        (calcValue / (toIndex - fromIndex + 1)).toFixed(calc.dec_places) :
                        calcValue.toFixed(calc.dec_places)
                }
            })
            calcObj["created_at_date"] = "SUB TOTAL"
            calcObj["isSubTotalRow"] = true
            // console.log(calcObj)
            list.splice(toIndex + 1, 0, calcObj)
        })
        let updatedList = list.filter(l => l.isSubTotalRow == true)
        // console.log("UPDATEDLIST", updatedList)
        let grandCalcObj = {}
        Object.keys(updatedList[0]).forEach(key => {
            if (key !== 'isSubTotalRow') {
                let calcValue = 0
                for (let i = 0; i < updatedList.length; i++) {
                    let item = updatedList[i]
                    calcValue += Number(item[key] === undefined ? 0 : item[key])
                }
                grandCalcObj[key] = key === "net_rate"
                    ? (calcValue / (updatedList.length)).toFixed(2)
                    : calcValue.toFixed(3)
            }
        })
        grandCalcObj["created_at_date"] = "TOTAL CALCULATION"
        grandCalcObj["isGrandTotalRow"] = true
        list.splice(list.length, 0, grandCalcObj)

        contracts = list
        // console.log(list, data)
        this.handlePDFDownload(data)
    }


    handlePDFDownload = (pdfdata) => {
        const { pdfProps, tableHeaders, headers, pdfContentData, tableSubHeaders, subheaders, finalHeaders } = pdfdata
        console.log("Download Started......", pdfProps)
        // console.log(pdfProps)
        const { tabValue } = pdfProps
        let tableDetails = []
        let tableData = []

        // total width in landscape = 660
        let columnWidths = []
        let table2Widths = []
        let tableData2 = []
        let rowData = []
        let headerColumnWidths = ["50%", "50%"]

        let selectedPlants = pdfProps.plants ? pdfProps.plants.join(', ') : null
        let SelectedItemCats = pdfProps.itemCats ? pdfProps.itemCats.join(', ') : null

        tableDetails.push([
            {
                text: `Report name - ${pdfProps.reportName}`,
                style: "leftAlignDetails",
                border: [false, false, false, false],
            },
            {
                text: `Date Range - From ${(Moment(pdfProps.startDate).format('DD-MMM-yyyy'))} To ${(Moment(pdfProps.endDate).format('DD-MMM-yyyy'))}`,
                style: "rightAlignDetails",
                border: [false, false, false, false],
            },
        ])

        tableDetails.push([
            {
                text: `Date of Generation - ${(Moment(new Date()).format('DD-MMM-yyyy'))}`,
                style: "leftAlignDetails",
                border: [false, false, false, false],
            },
            {
                text: `User Name - ${pdfProps.user.first_name} ${pdfProps.user.last_name}`,
                style: "rightAlignDetails",
                border: [false, false, false, false],
            },
        ])

        tableDetails.push([
            {
                text: `Plant name - ${selectedPlants}`,
                style: "leftAlignDetails",
                border: [false, false, false, false],
            },
            {
                text: `Item Category name - ${SelectedItemCats}`,
                style: "rightAlignDetails",
                border: [false, false, false, false],
            },
        ])

        columnWidths = [10, 30, 40, 40, 35, 35, 35, 30, 30, 35, 40, 35, 35, 30, 30, 30, 10, 30, 30]

        tableData.push(tableHeaders)
        tableData.push(tableSubHeaders)

        // for (let i = 2; i < pdfContentData.length; i++) {

        // }
        contracts.map((item, i) => {
            // tableData.push([])
            console.log(i, tableData, item, finalHeaders)

            for (let j = 0; j < finalHeaders.length; j++) {
                console.log(item[finalHeaders[j].key], Number(item[finalHeaders[j].key]))
                rowData.push(
                    {
                        text: item[finalHeaders[j].key] === 'CONVERSION_ALLOTMENT_HOLD'
                            ? 'ALLOTMENT HOLD'
                            : finalHeaders[j].key === 'booked_quantity'
                                ? item[finalHeaders[j].key] + " | " + item[finalHeaders[j].key2]
                                : item[finalHeaders[j].key],
                        border: [false, false, false, true],
                        border: [true, true, true, true],
                        style: ["tableItems"],
                        bold: (item["created_at_date"] === 'SUB TOTAL' || item["created_at_date"] === "TOTAL CALCULATION") && true,
                        alignment: (Number(item[finalHeaders[j].key]) || Number(item[finalHeaders[j].key]) == 0) ? 'center' : "left",
                        fillColor: (item?.isSubTotalRow || item?.isGrandTotalRow) && "#f5f5f5"
                    }
                )
            }
            tableData.push(rowData)
            console.log(rowData.find(d => d.text == 'SUB TOTAL'))
            if (rowData.find(d => d.text == 'SUB TOTAL') !== undefined) {
                rowData = []
                for (let j = 0; j < finalHeaders.length; j++) {
                    // console.log(item[finalHeaders[j].key])
                    rowData.push(
                        {
                            text: " ",
                            border: [false, false, false, false],
                            // border: [, false, true, true],
                            style: ["tableItems", "left"],
                            // fillColor: (item?.isSubTotalRow || item?.isGrandTotalRow) && "#f5f5f5"
                        }
                    )
                }
                tableData.push(rowData)
            }
            rowData = []
        })

        const dd = this.docdef(pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths)

        const date = new Date()
        const reportName = `${pdfProps.reportName}_${Moment(date).format("DD-MMM-YYYY_HH:mm:ss")}.pdf`
        if (!window.cordova) {
            console.log("herere", reportName)
            pdfMake
                .createPdf(dd)
                .open()
            // .download(reportName, pdfProps.handleOpenSnackClick());
        }
        else {
            let fileName = `${pdfProps.reportName}`
            printPDFInMobile(pdfMake, dd, fileName, pdfProps.handleOpenSnackClick, '')
        }
    }

    docdef = (pdfProps, tableDetails, headerColumnWidths, tableData, columnWidths, tableData2, table2Widths) => {
        let letDyanmicBodyColumnWidth = []
        let dynamicFieldlength = Number(tableData[0]?.length - (9 + 6))  // total length of column - static no. of columns
        let startingStaticColumnWidth = dynamicFieldlength === 4
            ? [30, 30, 30, 35, 28, 25, 40, 40, 40]
            : dynamicFieldlength === 8
                ? [30, 30, 30, 35, 28, 25, 35, 30, 30]
                : dynamicFieldlength >= 12 && [30, 30, 30, 35, 28, 25, 32, 25, 24]
        let lastStaticColumnWidth = dynamicFieldlength === 4
            ? [26, 23, 30, 28, 30, 25]
            : dynamicFieldlength === 8
                ? [24, 23, 25, 28, 29, 25]
                : dynamicFieldlength >= 12 && [24, 23, 24, 28, 27, 25]
        let middleDynamicColumnWidth = Array(dynamicFieldlength).fill(dynamicFieldlength === 4
            ? 30
            : dynamicFieldlength === 8
                ? 25
                : dynamicFieldlength === 12
                    ? 15
                    : dynamicFieldlength > 12 && 10)
        letDyanmicBodyColumnWidth.push(...startingStaticColumnWidth)
        letDyanmicBodyColumnWidth.push(...middleDynamicColumnWidth)
        letDyanmicBodyColumnWidth.push(...lastStaticColumnWidth)
        console.log(letDyanmicBodyColumnWidth)
        return {
            // pageSize: "A2",
            pageOrientation: 'landscape',
            header: function (currentPage) {
                // console.log(imageCode)
                if (currentPage === 1)
                    return {
                        columns: [
                            {
                                image: imageCode,
                                width: 50,
                                height: 50,
                                margin: [10, 0, 0, 0]
                            }
                        ]
                    }
            },
            // footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
            footer: function (currentPage, pageCount) {
                return {
                    table: {
                        widths: ["*"],
                        body: [
                            [
                                // { text: 'Confidential', alignment: 'left' },
                                { text: 'page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [0, 5, 35, 0], fontSize: 7, }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                };
            },

            content:
                [
                    {
                        // style: 'tableExample',
                        table: {
                            body: tableDetails,
                            widths: headerColumnWidths,
                            dontBreakRows: true,
                        },
                        margin: [0, 10, 0, 0]
                    },
                    {
                        // style: 'tableExample',s
                        table: {
                            headerRows: 2,
                            body: tableData,
                            widths: letDyanmicBodyColumnWidth,
                            dontBreakRows: true,

                        },
                        margin: dynamicFieldlength <= 4
                            ? [0, 10, 0, 0]
                            : dynamicFieldlength >= 8 && [-36, 10, 0, 0]
                    },
                    ,
                ],

            styles: {
                itemsHeader: {
                    padding: [0, 2, 0, 2],
                    // fontSize: 8,
                    fontSize: 6,
                    bold: true,
                    fillColor: "#f5f5f5",
                    alignment: "center",
                },
                tableItems: {
                    padding: [0, 2, 0, 2],
                    fontSize: 5,
                    alignment: "left",

                },
                leftAlignDetails: {
                    alignment: "left",
                    // margin: [0, 15, 0, 0],
                    fontSize: 10,
                },
                rightAlignDetails: {
                    alignment: "right",
                    // margin: [0, 15, 0, 0],
                    fontSize: 10,
                }

            },
        };
    }
    render() {
        return (
            <Tooltip title="Download PDF" placement="top">
                <div>
                    <Print onClick={() => {
                        this.fetchHeaders(this.props)
                    }}
                        style={{ color: "#4b87c5", cursor: "pointer", marginTop: 9, }} />
                </div>
            </Tooltip>
        )
    }
}

export default ReportsPDFDownload