import Layout from "../../../../layouts/internalLayout/InternalLayout";
import Bread from "../../common/Breadcrubs";
import { useState } from "react";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { grey, primaryColor, secondaryGrey, whiteSmoke } from "../../../../constants/internal/colors";
import { pxToEm } from "../../../../methods";

const styles = {
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  rightPadding: { paddingRight: "25px" },
  displayData: {
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
}

const PublishScheme = (props) => {


  const { isMobile, displaySection } = props;
  return (
    <div style={styles.mainDiv}>
      <div style={styles.rightPadding}>
        Publish Scheme
      </div>
    </div>
  )
}

export default PublishScheme;