import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Dispatch from "../Dispatch";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  b2bDispatchWebMainBody: (showDispatch) => ({
    width: "55vw",
    height: showDispatch === "ongoing"
      ? window.innerHeight - 140
      : showDispatch === "complete"
        ? window.innerHeight
        : window.innerHeight - 120,

  }),
};
class B2bDispatchWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, showDispatch } = this.props;
    return (
      <WebLayout appBar dispatchSelect>
        <div style={styles.b2bDispatchWebMainBody(showDispatch)}>
          <Dispatch {...this.props} />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(B2bDispatchWeb);
