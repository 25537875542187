import React, { Component } from "react";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayAppBannerData from "../../../../components/internal/masterManagement/appBanner/web";
import ManageAppBannerData from "../../../../components/internal/masterManagement/appBanner/ManageAppBannerData"
import { checkApiError } from "../../../../methods";

class ManageappBannerContainer extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			appBannerOperation: null,
			selectedappBanner: null,
			selectedappBannerData: [],
			selectedappBannerDataFetchLoader: false,
			snackbarMessage: null,
			openSnackbar: false,
			snackbarType: "",
			isLoadingRemove: false,
			removeAppBannerLoader: false,
			openDialog: false,
			dialogTitle: ``,
			dialogContent: "",

		};
	}
	getOptions = (user) => {
		return {
			headers: {
				Authorization: `JWT ${user.token}`,
				"Content-Type": "application/json;charset=UTF-8",
			},
		};
	};
	componentWillUnmount() {
		this._isMounted = false;
	}
	componentDidMount() {
		this._isMounted = true;
		let appBannerOperation = this.props.location.pathname.includes("add")
			? "add"
			: this.props.location.pathname.includes("edit")
				? "edit"
				: "view";

		this.setState({ appBannerOperation });
		if (appBannerOperation !== "add") {
			const id = this.props?.location?.pathname?.split("/")[
				this.props?.location?.pathname?.split("/").length - 1
			];
			this.handleFetchSelectedappBannerData(id);
			this.setState({ selectedappBanner: id });
		}
	}

	static getDerivedStateFromProps(props, state) {
		let updatedData = new Map();
		let appBannerOperation = props.location.pathname.includes("add")
			? "add"
			: props.location.pathname.includes("edit")
				? "edit"
				: "view";
		let selectedappBanner =
			appBannerOperation !== "add"
				? props?.location?.pathname?.split("/")[
				props?.location?.pathname?.split("/").length - 1
				]
				: null;
		if (appBannerOperation !== state.appBannerOperation) {
			updatedData.set("appBannerOperation", appBannerOperation);
		}
		if (selectedappBanner !== state.selectedappBanner) {
			updatedData.set("selectedappBanner", selectedappBanner);
		}
		if (updatedData.size > 0) {
			return {
				appBannerOperation: updatedData.has("appBannerOperation")
					? updatedData.get("appBannerOperation")
					: appBannerOperation,
				selectedappBanner: updatedData.has("selectedappBanner")
					? updatedData.get("selectedappBanner")
					: [],
			};
		}
		return null;
	}
	handleFetchSelectedappBannerData = (selectedappBanner) => {
		console.log("herere")
		this.setState({ selectedappBannerDataFetchLoader: true });
		axios
			.get(
				`${APIROUTES.GET_APP_BANNER_DETAIL}${selectedappBanner}/`,
				this.getOptions(this.props.user)
			)
			.then(
				(response) => {
					if (response.status === 200) {
						this.setState({
							selectedappBannerData: response.data,
							selectedappBannerDataFetchLoader: false,
						});
					} else {
						this.setState({
							selectedappBannerDataFetchLoader: false,
							openSnackbar: true,
							snackbarMessage:
								"Something went wrong while fetching selected customer Type data try again!",
							snackbarType: "warning",
						});
					}
				},
				(error) => {
					this.handleOpenSnackbar(
						this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
						"error"
					);
					setTimeout(() => {
						this.handleCloseSnackbar();
					}, 4000);
				}
			);
	};

	handleappBannerDataAdd = (appBannerData) =>
		new Promise((resolve, reject) => {
			const data = {
				news: appBannerData?.news,
				valid_from: appBannerData?.valid_from,
				// valid_upto: appBannerData.valid_upto
			}
			axios
				.post(
					APIROUTES.GET_APP_BANNER,
					data,
					this.getOptions(this.props.user)
				)
				.then((response) => {
					this.handleFetchSelectedappBannerData(response.data.id);
					this.props.getAppBannerList();
					resolve(response);
				})
				.catch(reject);
		});
	handleappBannerDataUpdate = (appBannerData) =>
		new Promise((resolve, reject) => {
			const id = this.props?.location?.pathname?.split("/")[
				this.props?.location?.pathname?.split("/").length - 1
			];
			const data = {
				news: appBannerData?.news,
				valid_from: appBannerData?.valid_from,
				// valid_upto: appBannerData.valid_upto
			}
			axios
				.patch(
					`${APIROUTES.GET_APP_BANNER}${id}/`,
					data,
					this.getOptions(this.props.user)
				)
				.then((response) => {
					this.handleFetchSelectedappBannerData(id);
					this.props.getAppBannerList();
					resolve(response);
				})
				.catch(reject);
		});
	handleCloseSnackbar = (event, appBanner) => {
		if (appBanner === "clickaway") {
			return;
		}
		this.setState({
			openSnackbar: false,
			snackbarMessage: null,
			snackbarType: "",
		});
	};

	handleOpenSnackbar = (message, type) => {
		this.setState({
			openSnackbar: true,
			snackbarMessage: message,
			snackbarType: type,
		});
	};

	handleSnackbarMessageBox = (data) => {
		return (
			data.map((item, index) => {
				return (
					<>
						<p style={{ margin: 0 }}>
							{item}
						</p>
					</>)
			})
		)
	}

	handleRemoveAppBanner = () => {
		this.setState({
			openDialog: true,
			dialogTitle: `Remove `,
			dialogContent: `Are you sure you want to Delete this App Banner Master ?`,
		});
	}
	handleRemoveConfirmationDialogClose = () => {
		this.setState({
			openDialog: false,
			dialogTitle: "",
			dialogContent: "",
		});
	};

	handleConfirmationDialogDeleteClick = () => {
		// setIsLoadingRemove(true)
		this.handleRemoveConfirmationDialogClose()
		this.setState({ removeAppBannerLoader: true })
		axios.delete(`${APIROUTES.GET_APP_BANNER_DETAIL}${this.state?.selectedappBanner}/`, this.getOptions(this.props.user))
			.then((response) => {
				if (response.status === 204) {
					this.handleOpenSnackbar(`App Banner Removed Successfully`, "success")
					setTimeout(() => {
						this.setState({ removeAppBannerLoader: false })
						this.props.getAppBannerList();
						this.props.history.push(INTERNALROUTES.APP_BANNER);
						this.handleCloseSnackbar();
					}, 4000);
				}
			})
			.catch((error) => {
				this.handleOpenSnackbar(
					this.handleSnackbarMessageBox(checkApiError(error?.response?.data)),
					"error"
				);
				setTimeout(() => {
					this.setState({ removeAppBannerLoader: false })
					this.handleCloseSnackbar();
				}, 4000);
			});
	};

	render() {
		const {
			appBannerOperation,
		} = this.state;
		if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
		// if (isAccessibleModule(this.props?.user, "Banks"))
		else {
			return (
				<>
					{appBannerOperation === "view" ? (
						<DisplayAppBannerData
							{...this.props}
							{...this.state}
							selectedappBannerData={this.state?.selectedappBannerData}
							appBannerOperation={this.state?.appBannerOperation}
							selectedappBannerDataFetchLoader={this.state?.selectedappBannerDataFetchLoader}
							selectedappBanner={this.state?.selectedappBanner}
							openDialog={this.state?.openDialog}
							dialogTitle={this.state?.dialogTitle}
							dialogContent={this.state?.dialogContent}
							isLoadingRemove={this.state?.isLoadingRemove}
							removeAppBannerLoader={this.state?.removeAppBannerLoader}
							handleRemoveAppBanner={this.handleRemoveAppBanner}
							handleRemoveConfirmationDialogClose={this.handleRemoveConfirmationDialogClose}
							handleConfirmationDialogDeleteClick={this.handleConfirmationDialogDeleteClick}
						/>
					) : (
						<ManageAppBannerData
							{...this.props}
							{...this.state}
							appBannerList={this.props?.appBannerList}
							handleappBannerDataUpdate={this.handleappBannerDataUpdate}
							handleappBannerDataAdd={this.handleappBannerDataAdd}
							selectedappBannerData={this.state?.selectedappBannerData}
							appBannerOperation={this.state?.appBannerOperation}
							selectedappBannerDataFetchLoader={this.state?.selectedappBannerDataFetchLoader}
							selectedappBanner={this.state?.selectedappBanner}
						/>
					)}
					<SnackbarComponent
						open={this.state.openSnackbar}
						handleClose={this.handleCloseSnackbar}
						snackbarMessage={this.state.snackbarMessage}
						type={this.state.snackbarType}
					/>
				</>
			);
		}
		// else return <AccessDenied />;
	}
}
export default withUser(ManageappBannerContainer);
