import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { bottomNavigationFontColor } from "../../../styles/colors";

const styles = {
  appBarStyle: {
    background:
      "transparent linear-gradient(0deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    boxShadow: "none",
    height: "70px",
    display: "flex",
    justifyContent: "center",
  },
};
class AppBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, handleBack, appBarTitle, appBarLeftIcon } = this.props;
    // console.log(this.props)
    return (
      <div>
        <AppBar
          position="static"
          color="default"
          className={classes.appBarStyle}
        >
          <Toolbar style={{ padding: "0px 8px" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleBack();
              }}
            >
              {appBarLeftIcon}
            </IconButton>
            <Typography
              variant="h6"
              style={{ color: bottomNavigationFontColor, fontSize: "1.2em" }}
            >
              {appBarTitle}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
export default withStyles(styles)(AppBarComponent);
