import React, { useEffect, useState } from "react";
import { version } from "../../../package.json";
import { StaticAppVersion } from "../../methods";


const StaticVersionDisplay = (props) => {
  const [versionNo, setVersionNo] = useState(StaticAppVersion())
  useEffect(() => {
    window?.cordova?.getAppVersion?.getVersionNumber().then(function (versionNumber) {
      setVersionNo(versionNumber)
    })
  }, [versionNo])

  return (
    <span style={{
      // color: "grey", 
      color: "#839192",
      opacity: 1
    }}>
      <span>v</span>
      <span>
        {window.cordova
          ? versionNo
          : props?.Version || versionNo
        }
      </span>
    </span>
  )
};

export default StaticVersionDisplay;
