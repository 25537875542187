import HOST from "./host";

const AUTH_URL = {
  HOST,
  POST_NOTIFICATION_TOKEN: HOST + "api/notifications/devices/",
  CREDENTIAL: HOST + "api/auth/",
  LOGOUT: HOST + "api/auth/logout/",
  GET_OTP: HOST + "api/auth/phone-auth/get-otp/",
  PHONE: HOST + "api/auth/phone-auth/verify-otp/",
  SENT_OTP_FOR_FORGOT_PASSWORD_EMAIL: HOST + "api/auth/reset-password/email-verification-get-otp-for-reset-password/",
  VERIFY_OTP_FOR_FORGOT_PASSWORD_EMAIL: HOST + "api/auth/reset-password/email-verification-verify-otp-for-reset-password/",
  SENT_OTP_FOR_FORGOT_PASSWORD: HOST + "api/auth/reset-password/phone-verification-get-otp-for-reset-password/",
  VERIFY_OTP_FOR_FORGOT_PASSWORD: HOST + "api/auth/reset-password/phone-verification-verify-otp-for-reset-password/",
  CHANGE_PASSWORD_FROM_FORGOT_PASSWORD: HOST + "api/auth/reset-password/",
  REFRESH_TOKEN: HOST + "api/auth/refresh-token/",
  GET_OTP_EMAIL_REGISTERATION: HOST + "api/auth/email-auth-for-register/get-otp/",
  VERIFY_OTP_EMAIL_REGISTERATION: HOST + "api/auth/email-auth-for-register/verify-otp/",
  GET_OTP_PHONE_REGISTERATION: HOST + "api/auth/phone-auth-for-register/get-otp/",
  VERIFY_OTP_PHONE_REGISTERATION: HOST + "api/auth/phone-auth-for-register/verify-otp/",
  GET_USE_OTP_LOGIN: HOST + "api/auth/auth-for-login/get-otp-apk/",
  VERIFY_USE_OTP_LOGIN: HOST + "api/auth/auth-for-login/verify-otp-apk/",
  GET_FORGOTPASSWORD_OTP_LOGIN: HOST + "api/auth/auth-for-forgotpassword/get-otp-apk",
  VERIFY_FORGOTPASSWORD_OTP_LOGIN: HOST + "api/auth/auth-for-forgotpassword/verify-otp-apk",
};

export default AUTH_URL;