import React, { Component } from "react";
import Confirm from "../Confirm";
import { withStyles } from "@material-ui/core/styles";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {
    ConfirmDispatchMainBody: {
        height: window.innerHeight - 70,
        background:
            "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    },
};
class ConfirmOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleBack = () => {
        this.props.history.push(String(localStorage.getItem("returnUrl")))
    };
    render() {
        const { classes } = this.props;
        return (
            <MobileLayout
                appBar
                handleBack={this.handleBack}
                appBarLeftIcon={<KeyboardBackspace />}
            >
                <div className={classes.ConfirmDispatchMainBody}>
                    <Confirm {...this.props} />
                </div>
            </MobileLayout>
        );
    }
}
export default withStyles(styles)(ConfirmOrder);
