import { forwardRef, useEffect, useState } from "react";

import {
	Button,
	Dialog,
	DialogActions,
	Divider,
	IconButton,
	InputAdornment,
	Slide,
	TextField,
	Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { checkApiError, isConfirmPasswordValid, PasswordLoginValid, pxToEm } from "../../../../methods";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";

import { drawerTextColor, grey, successButtonBackground, whiteSmoke } from "../../../../constants/internal/colors";
import SnackbarComponent from "../../common/SnackbarComponent";
import { bottomNavigationFontColor } from "../../../../styles/colors";

const PasswordExpiryStyles = makeStyles((theme) => ({
	dialogContainer: {
		border: "1px solid gainsboro",
		borderRadius: "15px",
		padding: "15px 20px 20px 20px"
	},
	dialogInnerContainer: {
		display: "flex",
		justifyContent: "flex-end"
	},
	buttonDesign: {
		backgroundColor: "#1B388B",
		fontWeight: "bold",
		color: "white",
		width: "48%",
		padding: "10px 10px",
		borderRadius: "7px",

		'&:hover': {
			backgroundColor: "#1B388B",
			color: "white",
		}
	},
	FormContainer: {
		padding: "8px 10px",
		// background: whiteSmoke,
		border: "1px solid gainsboro",
		borderRadius: "15px"
	},
	lableInputAlignment: {
		display: "flex",
		justifyContent: "center",
		margin: "10px 0 0 0",
		alignItems: "center",
		height: 64
	},
	labelStyle: {
		color: grey,
		fontSize: pxToEm(16),
		textTransform: 'none'
	},
	textField: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		width: "40ch",
	},
	saveButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: theme.spacing(2)
	},
	saveButton: {
		backgroundColor: successButtonBackground,
		color: drawerTextColor,

		'&:hover': {
			backgroundColor: successButtonBackground,
			color: drawerTextColor,
		}
	},
}))

const ChangePasswordDialog = (props) => {
	const { classes, Transition, openChangePasswordDialog } = props
	// const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
	const [passwordValue, setPasswordValue] = useState({
		oldPassword: null,
		newPassword: null,
		confirmNewPassword: null
	});
	const [showPassword, setShowPassword] = useState({
		oldPassword: false,
		newPassword: false,
		confirmNewPassword: false
	});
	const [errorPassword, setErrorPassword] = useState({
		oldPassword: false,
		newPassword: false,
		confirmNewPassword: false
	});

	const handleSave = () => {
		props
			.changePassword(passwordValue.oldPassword, passwordValue.newPassword)
			.then((response) => {
				if (response.status === 200) {
					props.CloseChangeDialog();
					props.handleOpenSnackbar(
						`Password Changed Successfully`,
						"success"
					);
					setTimeout(() => {
						props.handleCloseSnackbar();
						props.logout();
					}, 3000);
				}
			})
			.catch((error) => {
				props.handleOpenSnackbar(
					props.handleSnackbarMessageBox(checkApiError(error.response.data)),
					"error"
				);
				setTimeout(() => {
					props.handleCloseSnackbar();
				}, 4000);
			});
	}

	const handleChange = (event) => {
		const { name, value } = event.target;
		setPasswordValue(prevValue => {
			return {
				...prevValue,
				[name]: value,
			}
		});
		if (!value) {
			setErrorPassword(prevValue => {
				return {
					...prevValue,
					[name]: true
				}
			})
		} else {
			if (name === 'oldPassword') {
				setErrorPassword(prevValue => {
					return {
						...prevValue,
						[name]: false
					}
				})
			}
			else if (name === 'newPassword') {
				!PasswordLoginValid(value)
					? setErrorPassword(prevValue => {
						return {
							...prevValue,
							[name]: true
						}
					})
					: setErrorPassword(prevValue => {
						return {
							...prevValue,
							[name]: false
						}
					})
				if (passwordValue.confirmNewPassword) {
					!isConfirmPasswordValid(passwordValue.confirmNewPassword, value)
						? setErrorPassword(prevValue => {
							return {
								...prevValue,
								['confirmNewPassword']: true
							}
						})
						: setErrorPassword(prevValue => {
							return {
								...prevValue,
								['confirmNewPassword']: false
							}
						})
				}
			}
			else if (name === 'confirmNewPassword') {
				!isConfirmPasswordValid(value, passwordValue.newPassword)
					? setErrorPassword(prevValue => {
						return {
							...prevValue,
							['confirmNewPassword']: true
						}
					})
					: setErrorPassword(prevValue => {
						return {
							...prevValue,
							['confirmNewPassword']: false
						}
					})
			}
		};
	}

	return (
		<Dialog
			// onClose={handleClosePasswordExpiryDialog}
			// onClose={(e) => setOpenChangePasswordDialog(false)}
			open={openChangePasswordDialog}
			TransitionComponent={Transition}
			keepMounted
			PaperProps={{
				style: {
					borderRadius: "15px",
					alignItems: 'flex-start',
					padding: 8
				},
			}}
		>
			<div className={classes.dialogContainer}>
				<Typography style={{ fontSize: pxToEm(18), margin: '10px 0 0 0' }}>
					CHANGE PASSWORD
				</Typography>
				<Divider style={{ margin: '10px 0 25px 0' }} />
				<div className={classes.FormContainer}>
					<div className={classes.lableInputAlignment}>
						{/* <Typography className={classes.labelStyle}>
							Old Password*
						</Typography> */}
						<TextField
							name="oldPassword"
							type={showPassword.oldPassword ? 'text' : 'password'}
							label="Old Password*"
							id="outlined-margin-dense"
							value={passwordValue.oldPassword}
							placeholder="Enter Old Password"
							className={classes.textField}
							onChange={handleChange}
							size="small"
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={(e) => {
												e.preventDefault();
												setShowPassword(prevValue => {
													return {
														...prevValue,
														['oldPassword']: !prevValue['oldPassword']
													}
												})
												// handleClickShowPassword("oldPassword")
											}
											}
											edge="end"
											size="small"
										>
											{showPassword.oldPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
							error={errorPassword.oldPassword}
							helperText={
								errorPassword.oldPassword ? `Field is required` : null
							}
						/>
					</div>
					<div className={classes.lableInputAlignment}>
						{/* <Typography className={classes.labelStyle}>
							New Password*
						</Typography> */}
						<TextField
							name="newPassword"
							type={showPassword.newPassword ? 'text' : 'password'}
							label=" New Password*"
							id="outlined-margin-dense"
							value={passwordValue.newPassword}
							placeholder="Enter New Password"
							className={classes.textField}
							onChange={handleChange}
							size="small"
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={(e) => {
												e.preventDefault();
												setShowPassword(prevValue => {
													return {
														...prevValue,
														['newPassword']: !prevValue['newPassword']
													}
												})
												// handleClickShowPassword("newPassword")
											}}
											edge="end"
											size="small"
										>
											{showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
							error={errorPassword.newPassword}
							helperText={
								errorPassword.newPassword ? `Password must be 8 to 15 characters in length!` : null
							}
						/>
					</div>
					<div
						style={{
							width: "88%",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-around",
							marginTop: "10px",
							marginBottom: "20px",
						}}
						className={classes.textField}
					>
						<span
							style={{
								color: bottomNavigationFontColor,
								fontSize: "0.8em",
							}}
						>
							Password strength
						</span>
						<span
							style={{
								backgroundColor:
									passwordValue.newPassword &&
										passwordValue.newPassword.length >= 2 &&
										passwordValue.newPassword.length < 4
										? "#e68948"
										: passwordValue.newPassword &&
											passwordValue.newPassword.length >= 4 &&
											passwordValue.newPassword.length < 7
											? "#4986c2"
											: passwordValue.newPassword &&
												passwordValue.newPassword.length >= 7
												? "#c6d430"
												: "gainsboro",
								width: "13%",
								height: "10px",
								border: "1px solid #9E9E9E",
								borderRadius: "10px",
							}}
						/>
						<span
							style={{
								backgroundColor:
									passwordValue.newPassword &&
										passwordValue.newPassword.length >= 3 &&
										passwordValue.newPassword.length < 5
										? "#e68948"
										: passwordValue.newPassword &&
											passwordValue.newPassword.length >= 5 &&
											passwordValue.newPassword.length < 8
											? "#4986c2"
											: passwordValue.newPassword &&
												passwordValue.newPassword.length >= 8
												? "#c6d430"
												: "gainsboro",
								width: "13%",
								height: "10px",
								border: "1px solid #9E9E9E",
								borderRadius: "10px",
							}}
						/>
						<span
							style={{
								backgroundColor:
									passwordValue.newPassword &&
										passwordValue.newPassword.length >= 4 &&
										passwordValue.newPassword.length < 6
										? "#e68948"
										: passwordValue.newPassword &&
											passwordValue.newPassword.length >= 6 &&
											passwordValue.newPassword.length < 8
											? "#4986c2"
											: passwordValue.newPassword &&
												passwordValue.newPassword.length >= 8
												? !errorPassword.newPassword
													? "#c6d430"
													: "#4986c2"
												: "gainsboro",
								width: "13%",
								height: "10px",
								border: "1px solid #9E9E9E",
								borderRadius: "10px",
							}}
						/>
						<span
							style={{
								backgroundColor:
									passwordValue.newPassword &&
										passwordValue.newPassword.length >= 5 &&
										passwordValue.newPassword.length < 6
										? "#e68948"
										: passwordValue.newPassword &&
											passwordValue.newPassword.length >= 6 &&
											passwordValue.newPassword.length < 8
											? "#4986c2"
											: passwordValue.newPassword &&
												passwordValue.newPassword.length >= 8
												? !errorPassword.newPassword
													? "#c6d430"
													: "#4986c2"
												: "gainsboro",
								width: "13%",
								height: "10px",
								border: "1px solid #9E9E9E",
								borderRadius: "10px",
							}}
						/>
					</div>
					<div className={classes.lableInputAlignment}>
						{/* <Typography className={classes.labelStyle}>
							Confirm New Password*
						</Typography> */}
						<TextField
							name="confirmNewPassword"
							type={showPassword.confirmNewPassword ? 'text' : 'password'}
							label="Confirm Password*"
							id="outlined-margin-dense"
							value={passwordValue.confirmNewPassword}
							placeholder="Enter New Password Again"
							className={classes.textField}
							onChange={handleChange}
							disabled={!passwordValue.newPassword}
							size="small"
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={(e) => {
												// e.preventDefault();
												setShowPassword(prevValue => {
													return {
														...prevValue,
														['confirmNewPassword']: !prevValue['confirmNewPassword']
													}
												})
												// handleClickShowPassword("confirmNewPassword")
											}}
											edge="end"
											size="small"
										>
											{showPassword.confirmNewPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
							error={errorPassword.confirmNewPassword}
							helperText={errorPassword.confirmNewPassword
								? `Password not matching!`
								: null
							}
						/>
					</div>
					<DialogActions className={classes.saveButtonContainer}>
						<Button
							size="medium"
							style={{ textTransform: 'none' }}
							// className={classes.saveButton}
							// variant="contained"
							onClick={(e) => props?.BackButton()}
						>
							Back
						</Button>
						<Button
							size="medium"
							className={classes.saveButton}
							variant="contained"
							startIcon={<CheckOutlinedIcon />}
							onClick={handleSave}
						>
							SAVE
						</Button>
					</DialogActions>
				</div>
			</div>
		</Dialog>
	)
}


const PasswordExpiry = (props) => {
	const classes = PasswordExpiryStyles();
	const [openPasswordExpiryDialog, setOpenPasswordExpiryDialog] = useState(false);
	const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
	const [snackbar, setSnackbar] = useState({
		openSnackbar: null,
		snackbarMessage: null,
		snackbarType: null
	});


	useEffect(() => {
		setOpenPasswordExpiryDialog(props?.user?.is_pass_expired)
	}, [])

	const Transition = forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});

	const handleChangePasswordButton = () => {
		setOpenChangePasswordDialog(true);
		setOpenPasswordExpiryDialog(false);
	}

	const BackButton = () => {
		setOpenChangePasswordDialog(false);
		setOpenPasswordExpiryDialog(true);
	}

	const CloseChangeDialog = () => {
		setOpenChangePasswordDialog(false)
		setOpenPasswordExpiryDialog(false)
	}

	const handleOpenSnackbar = (message, type) => {
		setSnackbar({
			openSnackbar: true,
			snackbarMessage: message,
			snackbarType: type,
		})
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbar({
			openSnackbar: false,
			snackbarMessage: "",
			snackbarType: "",
		});
	};

	const handleSnackbarMessageBox = (data) => {
		return (
			data.map((item, index) => {
				return (
					<>
						<p style={{ margin: 0 }}>
							{item}
						</p>
					</>)
			})
		)
	}


	// { console.log(props) }

	return (
		<>
			<Dialog
				TransitionComponent={Transition}
				keepMounted
				open={openPasswordExpiryDialog}
				PaperProps={{
					style: {
						borderRadius: "15px",
						alignItems: 'flex-start',
						padding: 8
					},
				}}
			>
				<div className={classes.dialogContainer}>
					<Typography style={{ fontSize: pxToEm(18), marginBottom: 20 }}>
						Password Expired!
					</Typography>
					<p>
						Please Click Below Button to Change the Password.
					</p>
					<div className={classes.dialogInnerContainer}>
						<Button
							style={{ textTransform: 'none', marginRight: 10 }}
							onClick={(e) => props.logout()}
							variant="outlined"
						>
							Logout
						</Button>
						<Button
							className={classes.buttonDesign}
							variant="contained"
							onClick={handleChangePasswordButton}
						>
							Change Password
						</Button>
					</div>
					<ChangePasswordDialog
						changePassword={props.changePassword}
						classes={classes}
						Transition={Transition}
						openChangePasswordDialog={openChangePasswordDialog}
						BackButton={BackButton}
						CloseChangeDialog={CloseChangeDialog}
						logout={props.logout}
						handleOpenSnackbar={handleOpenSnackbar}
						handleCloseSnackbar={handleCloseSnackbar}
						handleSnackbarMessageBox={handleSnackbarMessageBox}
					/>
				</div>
			</Dialog>
			<SnackbarComponent
				open={snackbar.openSnackbar}
				handleClose={handleCloseSnackbar}
				snackbarMessage={snackbar.snackbarMessage}
				type={snackbar.snackbarType}
			/>
		</>
	)
}

export default PasswordExpiry;