import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Typography,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  whiteSmoke,
  successButtonBackground,
  drawerTextColor,
} from "../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { pxToEm } from "../../../../methods";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    minWidth: 130,
  },
  generalDetailsDisplaySection: {
    display: "flex",
    marginTop: "20px",
    flexDirection: "column",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0px 20px 0px",
  },

  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
  },
  tableBody: {
    fontSize: pxToEm(13),
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  addNewAction: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
  },
  addNewDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  addNewLabel: {
    fontSize: pxToEm(12),
    color: secondaryGrey,
    marginRight: 10,
  },
  removeLabel: {
    fontSize: pxToEm(12),
    color: grey,
    cursor: "pointer",
  },
  alignText: {
    display: "flex",
    alignItems: "center",
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "45%" },
});
export default function ManageCustomerSubscriptionDetails(props) {
  const [states, setStates] = React.useState([]);
  const classes = useStyles();
  const [displayAddButton, setDisplayAddButton] = React.useState(true);
  const [items, setItems] = React.useState(props?.itemsWithStates || new Map());
  const handleAddButtonClick = () => {
    setDisplayAddButton(false);
  };
  const handleCancelButtonClick = () => {
    setDisplayAddButton(true);
  };

  React.useEffect(() => {
    setItems(props.itemsWithStates);
  }, [props.itemsWithStates]);

  React.useEffect(() => {
    const updatedItems = new Map(props.itemsWithStates);
    setDisplayAddButton(true);

    for (let subs of props.customerSubscriptions)
      if (updatedItems.has(subs.category)) updatedItems.delete(subs.category);

    // console.log(props.itemsWithStates, updatedItems);
    setItems(updatedItems);
  }, [props.customerSubscriptions, props.itemsWithStates]);

  return (
    <>
      <div className={classes.fillingInformationSection}>
        <Typography className={classes.fillingInformation}>
          Fields marked with * are required
        </Typography>
      </div>
      <div className={classes.generalDetailsDisplaySection}>
        <Typography className={classes.titleLabel}>ITEM</Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell align="left" className={classes.tableHead}>
                  ITEM CATEGORY
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  STATE
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  RATE TYPE
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableHead}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.customerSubscriptions &&
              props.customerSubscriptions.length > 0
                ? props.customerSubscriptions.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={classes.tableBody}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell align="left" className={classes.tableBody}>
                        {row.state}
                      </TableCell>
                      <TableCell align="left" className={classes.tableBody}>
                        {row?.rate_type === "HR" ? "Open Rate" : "Net Rate"}
                      </TableCell>
                      <TableCell align="right" className={classes.tableBody}>
                        <Tooltip
                          title={"Click to remove"}
                          placement="left"
                          arrow
                        >
                          <CloseOutlinedIcon
                            className={classes.removeIconDimensions}
                            onClick={(evt) =>
                              props.handleRemoveCustomerSubscription(row.id)
                            }
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {displayAddButton ? (
          <Button
            variant="outlined"
            size="medium"
            startIcon={<AddOutlinedIcon />}
            className={classes.addNewButton}
            onClick={handleAddButtonClick}
          >
            ADD NEW
          </Button>
        ) : (
          <div className={classes.addNewAction}>
            <div className={classes.addNewDetails}>
              <div className={classes.alignText}>
                <Typography className={classes.addNewLabel}>
                  CATEGORY
                </Typography>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <Select
                    value={props.customerSelectedSubscriptionCategory}
                    onChange={(evt) => {
                      props.handleCustomerSubscriptionAddNewChange(
                        evt,
                        "customerSelectedSubscriptionCategory"
                      );
                      setStates(items.get(evt.target.value)?.states || []);
                    }}
                  >
                    {items &&
                      Array.from(items.keys()).map((category) => (
                        <MenuItem value={category}>{category}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.alignText}>
                <Typography className={classes.addNewLabel}>STATE</Typography>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <Select
                    value={props.customerSelectedSubscriptionState}
                    onChange={(evt) =>
                      props.handleCustomerSubscriptionAddNewChange(
                        evt,
                        "customerSelectedSubscriptionState"
                      )
                    }
                  >
                    {states.length > 0
                      ? states.map((state) => (
                          <MenuItem value={state.id}>{state.name}</MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.alignText}>
                <Typography className={classes.addNewLabel}>RATE</Typography>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <Select
                    value={props.customerSelectedSubscriptionRateType}
                    onChange={(evt) =>
                      props.handleCustomerSubscriptionAddNewChange(
                        evt,
                        "customerSelectedSubscriptionRateType"
                      )
                    }
                  >
                    <MenuItem value={"HR"}>Open Rate</MenuItem>
                    <MenuItem value={"LR"}>Net Rate</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <Typography
                  className={classes.removeLabel}
                  onClick={(evt) => {
                    evt.preventDefault();
                    props.handleRemoveCustomerSelectedSubscrptionData();
                  }}
                >
                  REMOVE
                </Typography>
              </div>
            </div>
            <div>
              {props.saveCustomerSubscriptionLoader ? (
                <CircularProgress disableShrink />
              ) : (
                <div>
                  <Button
                    disabled={
                      !props.customerSelectedSubscriptionCategory ||
                      !props.customerSelectedSubscriptionState ||
                      !props.customerSelectedSubscriptionRateType
                    }
                    size="medium"
                    className={classes.saveButton}
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      props.handleSaveCustomerSubscriptions();
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    size="medium"
                    className={classes.margin}
                    onClick={handleCancelButtonClick}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
