import React, { Component } from "react";
import { withRouter } from "react-router";
import MobileLayout from "../../../layouts/b2b/MobileLayout";
import { primaryColor } from "../../../styles/colors";
import { withStyles } from "@material-ui/core/styles";
import "../../../styles/b2b/B2bNotification.css";
import backStack from "cordova-back-stack";
import NotificationList from "../NotificationList";
import { Button, Dialog, Snackbar } from "@material-ui/core";
import { drawerTextColor } from "../../../constants/internal/colors";

const styles = {
  b2bNotificationMainBody: {
    height: window.innerHeight - 74,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    padding: "15px 2px 0px 20px",
  },
  listItemStyle: {
    paddingLeft: "0px",
    paddingTop: "0px",
  },
  listItemTextStyle: {
    marginLeft: window.innerWidth < 375 ? '5px' : "20px",
  },
  clearAllButton: {
    textTransform: "capitalize",
    color: primaryColor,
    fontWeight: "bold",
  },
  avatarStyle: {
    height: "43px",
    width: "43px",
    borderRadius: "23%",
    backgroundColor: primaryColor,
    transform: window.innerWidth < 375 && 'scale(0.7)'
  },
  dialogContainer: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "15px 20px 20px 20px"
  },
  dialogInnerContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  logOutButton: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    textTransform: 'none',
    marginRight: 10
  }
};
class B2bNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clearAllNotification: true,
      notifications: [],
      dates: [],
    };
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() {
    this.getProps(this.props);
    backStack((e) => {
      e.preventDefault();
      if (this.props.location.pathname === "/b2bNotification") {
        this.props.history.push("/dailyRates");
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    this.getProps(nextProps);
  }

  getProps = (currentProps) => {
    this.setState({
      notifications: currentProps.userNotifications,
      dates: currentProps.dates,
      notificationsLoader: false,
    });
  };

  render() {
    // const {
    //   notifications,
    //   clearAllNotification,
    //   notificationsOtherDay,
    // } = this.state;
    const { classes, isOffline, logoutPopoverText } = this.props;
    // console.log(this.state)
    // console.log(this.props)
    return (
      <MobileLayout bottomNavigation bottomNavSelected={3} logout={this.props.logout} logoutPopoverText={logoutPopoverText}>
        <div className={classes.b2bNotificationMainBody}>
          <NotificationList
            {...this.props}
            isMobile={true}
            notifications={this.state.notifications}
            dates={this.state.dates}
            handleFetchMoreNotification={this.props.handleFetchMoreNotification}
            handleNotificationClick={this.props.handleNotificationClick}
            fetchNotificationList={this.props.fetchNotificationList}
          />
          {/* {clearAllNotification ? (
            <div className="b2b-mobile-NO-notification-container">
              <Notifications style={{ color: "#35425A" }} />{" "}
              <span className="b2b-mobile-no-notification-text">
                No notifications
              </span>
            </div>
          ) : (
            <div>
              <div className="b2b-mobile-notification-header">
                <span className="b2b-mobile-notification-date-text">Today</span>
                <span className="b2b-mobile-notification-clear-all-text">
                  <Button
                    className={classes.clearAllButton}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ clearAllNotification: true });
                    }}
                  >
                    Clear All
                  </Button>
                </span>
              </div>
              <div>
                <List>
                  {notifications.map((data) => (
                    <ListItem className={classes.listItemStyle}>
                      <Avatar className={classes.avatarStyle} alt="Remy Sharp">
                        <CommerceSVG />
                      </Avatar>
                      <ListItemText
                        primary={data.title}
                        secondary={data.time}
                        className={classes.listItemTextStyle}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
              <div className="b2b-mobile-another-date">
                <span className="b2b-mobile-notification-date-text">
                  13th Apr 2020
                </span>
                <span>
                  <Button
                    className={classes.clearAllButton}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ clearAllNotification: true });
                    }}
                  >
                    Clear All
                  </Button>
                </span>
              </div>
              <div>
                <List>
                  {notificationsOtherDay.map((data) => (
                    <ListItem className={classes.listItemStyle}>
                      <Avatar className={classes.avatarStyle} alt="Remy Sharp">
                        <MaskGroupSVG />
                      </Avatar>
                      <ListItemText
                        primary={data.title}
                        secondary={data.time}
                        className={classes.listItemTextStyle}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
          )} */}
        </div>
        {/* <Dialog
          keepMounted
          open={Boolean(logoutPopoverText)}
          PaperProps={{
            style: {
              borderRadius: "15px",
              alignItems: 'flex-start',
              padding: 8
            },
          }}
        >
          <div style={styles.dialogContainer}>
            <p>
              {logoutPopoverText}
            </p>
            <div style={styles.dialogInnerContainer}>
              <Button
                style={styles.logOutButton}
                onClick={(e) => this.props.logOut()}
                variant="outlined"
              >
                Logout
              </Button>
            </div>
          </div>
        </Dialog> */}
        <Snackbar
          open={isOffline}
          onClose={this.handleClose}
          autoHideDuration={4000}
          TransitionComponent={"up"}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          message={<span id="message-id">No Internet Connection</span>}
        />
      </MobileLayout>
    );
  }
}
export default withRouter(withStyles(styles)(B2bNotification));
