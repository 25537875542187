import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import "../../../../styles/b2b/DailyRates.css";
import backStack from "cordova-back-stack";
import Ledger from "../Ledger";

const styles = {
  dailyRatesMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    height: window.innerHeight - 74,
    // (Window.innerHeight * 0.09),
    overflowY: "scroll",
  },
};
class LedgerMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }
  render() {
    const {
      classes,
      isMobile,
      ledgerRequestData,
      ledgerRequestStatus,
      handleDateChange,
      fromDate,
      toDate,
      ledgerType,
      isLoading,
      user
    } = this.props;
    { console.log("231232dfgfgfd") }
    return (
      <MobileLayout bottomNavigation bottomNavSelected={0}>
        <div className={classes.dailyRatesMainBody}>
          <Ledger
            {...this.props}
            classes={classes}
            isMobile={isMobile}
            ledgerRequestStatus={ledgerRequestStatus}
            fromDate={fromDate}
            toDate={toDate}
            handleDateChange={handleDateChange}
            ledgerRequestData={ledgerRequestData}
            ledgerType={ledgerType}
            isLoading={isLoading}
            user={user}
            handleLedgerType={this.props.handleLedgerType}
            handleSubmitButton={this.props.handleSubmitButton}
            handleResetLedgerRequest={this.props.handleResetLedgerRequest}
            handleClearState={this.props.handleClearState}
            handleDownloadPDFButton={this.props.handleDownloadPDFButton}
          />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(LedgerMobile);
