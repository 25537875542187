import React, { Component } from 'react';
import LoadingAdviceMobile, {
  LoadingAdvicePlanningWeb,
  LoadingAdvicePlanningDetailsWeb
} from '../../../components/internal/loadingAdvice';
import { Route, Switch } from "react-router-dom";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import { isAccessibleModule, isAccessibleWidget, arraySortBykeyAllBrowser } from "../../../methods";
import LoadingAdvicesContainer from "../contracts/LoadingAdvices";
import OpenInDesktop from "../../../components/internal/common/OpenInDesktop";
import { checkApiError } from "../../../methods";
import debounce from "lodash.debounce";
import { last } from 'lodash';
import DispatchCounterInvoiceDownload from '../../../components/internal/dispatchOperation/web/DispatchCounterInvoiceDownload';
import DispatchOperation from '../../../components/internal/dispatchOperation';
import NewInvoiceWayBillEinvoiceViewDownloadCard from '../../../components/internal/dispatchOperation/NewInvoiceWayBillEinvoiceViewDownloadCard';
import upiqr from "upiqr";
import moment from "moment";
class LoadingAdviceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchPlaningList: [],
      showLADetailsDialogLoader: false,
      plantLoader: false,
      plants: [],
      dispatchLoader: true,
      newDate: "",
      plantID: "",
      itemCategoryId: "",
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: " ",
      plantWeighbridge: [],
      displayNewUi: true,
      allOrders: [],
      itemCategoryTabData: [],
      itemDataFetchLoader: true,
      transType: [],
      date: "",
      clickLaConformDate: "",
      reasonMaster: "",
      invoiceDetails: {},
      replacedLADetails: {},
      showReplacedLADetails: false,
      searchLAValue: "",
      rowsCount: 0,
      eInvoiceResponse: null,
      eWayBillResponse: null,
      eWayBillSelected: false,
      eWayOptionSelected: false,
      eWayBill: [{ name: "Yes" }, { name: "No" }],
      invoiceMethod: [{ name: "Normal Invoice" }, { name: "Combined Invoice" }],
      invoiceType: "",
      iseWaySelect: false,
      laDetailsInDispatchCounter: null,
      laDispatchCounterLoader: false,
      transporterDistance: 0.0,
      plant_state: null,
      iseWaySelect: false,
      eway: null,
      laNumber: null,
      b2CQrImage: null
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentDidMount() {
    // this.fetchAllOrders();
    this.fetchTransTypeMaster();
    // if (isAccessibleModule(this.props.user, "Dispatch Planning")) {
    //   // isAccessibleModule(user, moduleName)
    // }
    // this.handelFetchPlants(); // isAccessibleWidget(user, keyCode)
    if (isAccessibleModule(this.props.user, "Loading Advices")) {
      // isAccessibleModule(user, moduleName)
      // if (isAccessibleWidget(this.props.user, "NV_loadingAdvice")) {
      // console.log("VALUE" + isAccessibleWidget(this.props.user, "AP_loadingAdvice"))
      console.log(this.props.user)
      this.handelFetchPlants(); // isAccessibleWidget(user, keyCode)
      this.fetchReasonCancelLA("Loading Advices")
      // }
    }
    this.getAllStatesList()
  }

  handleLASearchClear = () => {
    this.setState({ searchLAValue: "" })
  }

  fetchTransTypeMaster = () => {
    axios
      .get(APIROUTES.GET_TRANS_TYPE_MASTER, this.getOptions(this.props.user))
      .then((response) => {
        this.setState({
          transType: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchAllOrders = async () => {
    let itemId = this.props.location?.state?.itemId
    let customerId = this.props.location?.state?.customerId
    // console.log("fetchallOrder")
    this.setState({
      itemDataFetchLoader: true,
    });
    // await axios
    //   .get(APIROUTES.CONTRACTS_LIST, this.getOptions(this.props.user))
    await axios
      .get(`${APIROUTES.CONTRACTS_DROPDOWN_LIST}?item_category=${itemId}&customer=${customerId}`,
        this.getOptions(this.props.user))
      .then((response) => {
        const { data } = response;
        let tempraryItemCategoryData = data.map(
          (orders) => orders.item_category
        );
        const seen = new Set();
        const itemCategoryTabData = tempraryItemCategoryData.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });
        this.setState({
          allOrders: data,
          itemCategoryTabData,
          itemDataFetchLoader: false,
        });
      })
      .catch((error) => {
        console.error("method error", error)
        this.setState({ itemDataFetchLoader: true });
      });
  };


  fetchOrders = async (itemId, customerId, plantId = 0) => {
    console.log("plantId : ", plantId)
    this.setState({
      itemDataFetchLoader: true,
    });
    const baseURL = `${APIROUTES.CONTRACTS_DROPDOWN_LIST}`;
    const params = plantId === 0
      ? `?item_category=${itemId}&customer=${customerId}`
      : `?item_category=${itemId}&customer=${customerId}&plant=${plantId}`
    const finalURL = baseURL + params
    await axios.get(finalURL, this.getOptions(this.props.user))
      .then((response) => {
        const { data } = response;
        console.log(response)
        let tempraryItemCategoryData = data.map(
          (orders) => orders.item_category
        );
        const seen = new Set();
        const itemCategoryTabData = tempraryItemCategoryData.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });
        this.setState({
          allOrders: data,
          itemCategoryTabData,
          itemDataFetchLoader: false,
        });
      })
      .catch((error) => {
        this.setState({
          itemDataFetchLoader: false,
        });
      })
  }

  fetchOrderDetails = (orderId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_DATA_FETCH}${orderId}/`,
      this.getOptions(this.props.user)
    );
  };

  fetchCustomerPaymentTerms = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_PAYMENT_METHOD}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };

  fetchSearchCustomerOptions = (serchValue) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_CLIENT_SEARCH}?search=${serchValue}`,
      this.getOptions(this.props.user)
    );
  };

  fetchBuyers = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_BUYER}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };

  fetchConsignees = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_CONSIGNEE}${customerId}/`,
      this.getOptions(this.props.user)
    );
  };

  fetchBrokers = () => {
    return axios.get(
      APIROUTES.CONTRACTS_BROKER,
      this.getOptions(this.props.user)
    );
  };

  fetchItemCategories = (customerId) => {
    return axios.get(
      `${APIROUTES.CONTRACTS_ITEM_CATEGORIES}?customer_id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };


  fetchPendingQuantity = (orderId) => {
    return axios.get(
      `${APIROUTES.CHECK_PENDING_QUANTITY}?order_id=${orderId}`,
      this.getOptions(this.props.user)
    );
  }

  createOrder = (orderData) => {
    return axios.post(
      APIROUTES.CONTRACTS_CREATE,
      orderData,
      this.getOptions(this.props.user)
    );
  };

  fetchPlantsForItemCategory = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.PLANTS_FOR_ITEM_CATEGORY}${itemCategoryId}/`,
      this.getOptions(this.props.user)
    );
  };

  fetchOrderPlantPlanning = (orderId) => {
    return axios.get(
      `${APIROUTES.ORDER_PLANT_PLANNING}?order_id=${orderId}`,
      this.getOptions(this.props.user)
    );
  };

  updateLa = (laData, laId) => {
    return axios.put(
      `${APIROUTES.LOADING_ADVICE_UPDATE}${laId}/`,
      laData,
      this.getOptions(this.props.user)
    );
  };

  createLa = (laData) => {
    return axios.post(
      APIROUTES.LOADING_ADVICE_CREATE,
      laData,
      this.getOptions(this.props.user)
    );
  };

  fetchItemsData = (itemCategoryId) => {
    return axios.get(
      `${APIROUTES.ITEM_CATEGORY_MASTER_ITEMS}?id=${itemCategoryId}&active=${true}`,
      this.getOptions(this.props.user)
    );
  };

  deleteItemsData = (itemId) => {
    return axios.delete(
      `${APIROUTES.DELETE_LOADING_ADVICE_ITEM}${itemId}/`,
      this.getOptions(this.props.user)
    );
  };

  handelFetchPlants = () => {
    this.setState({ plantLoader: true });
    axios
      .get(`${APIROUTES.GET_PLANT}LA/`, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            plants: response.data,
            plantLoader: false,
          });
        }
      })
      .catch((err) => {
        // console.log(err.response);
        this.setState({
          plantLoader: false,
        });
      });
  };
  handleFetchDispatchLADetails = (date, plant_id, newLink = 0) => {

    const actualUrl = `${APIROUTES.LA_LIST_DATEWISE_LOADING_ADVICE_MODULE}`;
    const filteredLink = newLink === 0 ? actualUrl : newLink
    let payLoad = {
      date: date,
      plant_id: plant_id,
      search: ""
    }
    this.setState({ dispatchLoader: true, searchLAValue: "", rowsCount: 0 }, () => {
      axios
        .post(filteredLink, payLoad, this.getOptions(this.props.user)
        )
        .then((response) => {
          if (response.status === 200) {
            const laResponse = response.data.results.find((d) => d.date == date)?.la;
            this.setState(
              {
                dispatchPlaningList: laResponse,
                rowsCount: laResponse.length,
                count: response.data.count,
                prevLink: response.data.previous,
                nextLink: response.data.next,
                dispatchLoader: false,
              },
              () => {
                this.props.history.push(`/internalLoadingAdvices/${plant_id}`);
                // console.log(`/internalLoadingAdvices/${plant_id}`)
              }
            );
          }
        })
        .catch((err) => {
          this.setState({ dispatchLoader: false });
        });
    });
  };

  handleLASearchValue = (searchText, date, plantID, item = [], laStatus = [], weighBridge = []) => {
    this.setState({ searchLAValue: searchText },
      () => {
        // if (searchText !== "")
        //   this.handleFetchDispatchDetailsWithDate(date, plantID, searchText)
        // else
        //   this.handleFetchDispatchDetailsWithDate(date, plantID)
        this.handleFetchDispatchDetailsWithDate(date, plantID, searchText, item, laStatus, weighBridge)
      }
    )

  }

  handleLASearchValue = (searchText, date, plantID, Item, LAStatus, WB) => {
    this.setState({ searchLAValue: searchText },
      () => {
        if (searchText !== "")
          this.handleFetchDispatchDetailsWithDate(date, plantID, searchText, Item, LAStatus, WB)
        else
          this.handleFetchDispatchDetailsWithDate(date, plantID, searchText, Item, LAStatus, WB)
      }
    )

  }

  enableLoader = () => {
    this.setState({ dispatchLoader: true });
  }

  handleFetchDispatchDetailsWithDate = debounce((date, plant_id, searchText = "",
    item = [], laStatus = [], weighBridge = [], newLink = 0) => {
    let FetchUrl = `${APIROUTES.LA_LIST_DATEWISE_LOADING_ADVICE_MODULE}`

    let payLoad = {
      date: date,
      plant_id: plant_id,
      search: searchText,
      weighbridge: weighBridge,
      status: laStatus,
      item_category: item
    }

    const filteredLink = newLink === 0 ? FetchUrl : newLink
    this.setState({
      dispatchLoader: true,
      rowsCount: 0,
      newDate: date,
      plantID: plant_id,
      // itemCategoryId: item_category_id
    }, () => {
      axios
        .post(
          filteredLink,
          payLoad,
          // `${APIROUTES.CONTRACTS_LOADING_ADVICE_LIST}?plant_id=${plant_id}&date=${date}`,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          if (response.status === 200) {
            const laResponse = response.data.results.find((d) => d.date == date)?.la;
            this.setState({
              dispatchPlaningList: laResponse,
              rowsCount: laResponse.length,
              count: response.data.count,
              prevLink: response.data.previous,
              nextLink: response.data.next,
              dispatchLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ dispatchLoader: false });
        });
    });
  }, 500)


  handleFetchDispatchDetailsWithFilter = debounce((date, plant_id, searchText = "",
    item = [], laStatus = [], weighBridge = [], newLink = 0) => {
    let FetchUrl = `${APIROUTES.LA_LIST_DATEWISE_LOADING_ADVICE_MODULE}`
    let payLoad = {
      date: date,
      plant_id: plant_id,
      search: searchText,
      weighbridge: weighBridge,
      status: laStatus,
      item_category: item
    }

    const filteredLink = newLink === 0 ? FetchUrl : newLink
    this.setState({
      dispatchLoader: true,
      rowsCount: 0,
      newDate: date,
      plantID: plant_id,
      // itemCategoryId: item_category_id
    }, () => {
      axios
        .post(
          filteredLink,
          payLoad,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          if (response.status === 200) {
            const laResponse = response.data.results.find((d) => d.date == date)?.la;
            this.setState({
              dispatchPlaningList: laResponse,
              rowsCount: laResponse.length,
              count: response.data.count,
              prevLink: response.data.previous,
              nextLink: response.data.next,
              dispatchLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ dispatchLoader: false });
        });
    });
  }, 500)


  setRowsCount = (count) => {
    this.setState({
      rowsCount: count
    })
  }

  handleFetchPlantWeighbridge = (plant_id) => {
    this.setState({ dispatchLoader: true }, () => {
      axios
        .get(
          `${APIROUTES.GET_WEIGHBRIDGE}${plant_id}`,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              plantWeighbridge: response.data,
              dispatchLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ dispatchLoader: false });
        });
    });
  };

  handleUpdateLaApproval = (status, laId) =>
    new Promise((resolve, reject) => {
      let data = {
        dispatch_status: status,
        status_remark: "",
      };
      axios
        .put(
          `${APIROUTES.UPDATE_LA_STATUS_IN_DISPATCH_PLANING + laId + "/"}`,
          data,
          this.getOptions(this.props?.user)
        )
        .then(resolve)
        .catch(reject);
    });

  fetchTcsRate = (customerId, itemId) => {
    return axios
      .get(
        `${APIROUTES.GET_TCS_RATE}?customer_id=${customerId}&item_id=${itemId}`,
        this.getOptions(this.props.user)
      );
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleLaApprove = (laId, laUpdateData, date, plantId, laCancelReason) => {
    console.log("jjdknnjw")
    // let updatedData = { is_approved: true };
    if (laUpdateData?.dispatch_status != "APPROVED") {
      laUpdateData = { ...laUpdateData, cancel_reason: laCancelReason }
    }
    console.log(laUpdateData)
    axios.patch(
      `${APIROUTES.LOADING_ADVICE_APPROVE}${laId}/`,
      laUpdateData,
      this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          // this.handleFetchDispatchDetailsWithDate(
          //   this.state.newDate, this.state.plantID
          // );
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Status ${response?.data?.dispatch_status}`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => this.handleFetchDispatchDetailsWithDate(
                date ?? this.state.newDate, plantId ?? this.state.plantID
              ), 3000)
            });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while approving LA try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.handleOpenSnackbar(
          this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
          "error"
        );
        this.setState({ dispatchLoader: false });
      });
  };
  handleCancelLa = (laId, updatedLaData, date, plantId) => {
    axios.patch(
      `${APIROUTES.LOADING_ADVICE_APPROVE}${laId}/`,
      updatedLaData,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          // this.handleFetchDispatchDetailsWithDate(
          //   this.state.newDate, this.state.plantID
          // );
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Status Cancelled`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => this.handleFetchDispatchDetailsWithDate(
                date, plantId
              ), 3000)
            });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while  cancelling LA try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          dispatchLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while  cancelling LA try again!`,
          snackbarType: "error",
        });
      });
  };


  approveLaModification = (laId, date, plantId, remarkMessage) => {
    let data = { is_accepted: true, remark: remarkMessage };
    axios.patch(
      `${APIROUTES.GET_LA_MODIFICATION_ACCESSIBLE}${laId}/`,
      data,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          // this.handleFetchDispatchDetailsWithDate(
          //   this.state.newDate, this.state.plantID
          // );
          this.setState({
            openSnackbar: true,
            snackbarMessage: `LA Modification Changes Approved Successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.handleLaConformDate(date);
                this.handleSnackbarClose();
              }
                , 3000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while approving LA modification changes try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          dispatchLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while approving LA modification changes try again!`,
          snackbarType: "error",
        });
      });
  };


  handleDiscardWeighSlips = async (weighId, date, plantId) => {
    axios.get(
      `${APIROUTES.DISACRD_WEIGH_SLIPS}${weighId}/`,

      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log(response.data);
          this.setState({
            openSnackbar: true,
            snackbarMessage: `weighslip discarded`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.handleSnackbarClose();
              }
                , 3000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while discarding weigh slip`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.response?.data?.message ?
            error?.response?.data?.message :
            `Error occured while discarding weigh slip`,
          snackbarType: "error",
        });
      });
  };

  handleFetchSalesReturn = async (laNumber, date, plantId) => {
    axios.get(
      `${APIROUTES.FETCH_SALES_RETURN_LA}${laNumber}/`,

      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log(response.data);
          this.setState({
            openSnackbar: true,
            snackbarMessage: response.data.data || `fetched sales return successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.handleSnackbarClose();
              }
                , 3000)
            }
          );
        } else if (response.status === 400) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response.data.error || `Error occured while fetching sales return data`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: error?.response?.data?.error ?
              error?.response?.data?.error :
              `Error occured while fetching sales return data`,
            snackbarType: "error",
          });
        }
      });
  };

  handleUnlockWeighbridge = async (laNumber, date, plantId) => {
    let data = { is_weighbridge_locked: false };

    axios.put(
      `${APIROUTES.UNLOCK_WEIGHBRIDGE}${laNumber}/`,
      data,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `weighbridge unlocked successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.handleSnackbarClose();
              }
                , 3000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while unlock the weighbridge`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: `Error occured while unlock the weighbridge`,
          snackbarType: "error",
        });
      });
  };

  handleChangeLAStatus = async (laNumber, rollBackStatus, date, plantId) => {
    let data = {
      roll_back_status: rollBackStatus
    }
    axios.post(
      `${APIROUTES.CHANGE_LA_STATUS}${laNumber}/`, data,

      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log(response.data);
          this.setState({
            openSnackbar: true,
            snackbarMessage: `LA status changed to ${rollBackStatus}`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.handleSnackbarClose();
              }
                , 3000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while changing LA status`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: error?.response?.data?.message ?
            error?.response?.data?.message
            : `Error occured while changing LA status`,
          snackbarType: "error",
        });
      });
  };

  handleLAReplacement = async (laNumber, date, plantId, laCancelReason) => {

    axios.get(
      `${APIROUTES.LA_REPLACEMENT}${laNumber}/?cancel_reason=${laCancelReason}`,

      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `LA is cancelled successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.setState({
                  replacedLADetails: response.data,
                  showReplacedLADetails: true,
                  openSnackbar: false,
                  snackbarMessage: null,
                  snackbarType: "",
                })
                // this.handleSnackbarClose();
              }
                , 2000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while cancelling LA`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: error?.response?.data?.message
              ? error?.response?.data?.message
              : `Error occured while cancelling LA`,
            snackbarType: "error",
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while cancelling LA`,
            snackbarType: "error",
          });
        }
      })
  };

  handleLACancelAfterBilled = async (laNumber, date, plantId, laCancelReason) => {

    axios.get(
      `${APIROUTES.LA_CANCEL_AFTER_BILLED}${laNumber}/?cancel_reason=${laCancelReason}`,

      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `LA is cancelled successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.setState({
                  openSnackbar: false,
                  snackbarMessage: null,
                  snackbarType: "",
                })
                // this.handleSnackbarClose();
              }
                , 2000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while cancelling LA`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          this.setState({
            openSnackbar: true,
            snackbarMessage: error?.response?.data?.message
              ? error?.response?.data?.message
              : `Error occured while cancelling LA`,
            snackbarType: "error",
          })
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while cancelling LA`,
            snackbarType: "error",
          });
        }
      })

  };

  hideLADetailsDialogLoader = () => {
    this.setState({
      showLADetailsDialogLoader: false
    })
  }
  hideLADetailsDialog = () => {
    this.setState({
      showReplacedLADetails: false
    })
  }

  getInvoiceDetails = (laNumber) => {
    return axios.get(
      `${APIROUTES.GET_E_INVOICE_AND_E_WAY}${laNumber}/`,
      this.getOptions(this.props.user)
    )
  };


  fetchReasonCancelLA = async (moduleName) => {
    console.log("here kk", moduleName)
    return axios.get(
      `${APIROUTES.GET_REASON}?module=${moduleName}&active=${true}`,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          console.log("reasons", response?.data)
          this.setState({
            reasonMaster: response?.data,
          });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: response?.data?.details
              ? response?.data?.details
              : response?.data?.message
                ? response?.data?.message
                : response?.details
                  ? response?.details
                  : "Error occured while fetching reason master",
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackbarMessage: "Error occured while fetching reason master",
          snackbarType: "error",
        });
        // this.handleOpenSnackbar(
        //   this.handleSnackbarMessageBox(checkApiError(error.response.data)),
        //   "error"
        // );
        // setTimeout(() => {
        //   this.handleCloseSnackbar();
        // }, 4000);
      });
  };

  rejectLaModification = (laId, date, plantId, remarkMessage) => {
    let data = { is_accepted: false, remark: remarkMessage };
    axios.patch(
      `${APIROUTES.GET_LA_MODIFICATION_ACCESSIBLE}${laId}/`,
      data,
      this.getOptions(this.props.user)
    )
      .then((response) => {
        if (response.status === 200) {
          // this.handleFetchDispatchDetailsWithDate(
          //   this.state.newDate, this.state.plantID
          // );
          this.setState({
            openSnackbar: true,
            snackbarMessage: `LA Modification Changes Reject Successfully`,
            snackbarType: "success",
          },
            () => {
              setTimeout(() => {
                this.handleFetchDispatchDetailsWithDate(
                  date, plantId
                );
                this.handleLaConformDate(date);
                this.handleSnackbarClose();
              }
                , 3000)
            }
          );
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: `Error occured while rejecting LA modification changes try again!`,
            snackbarType: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          dispatchLoader: false,
          openSnackbar: true,
          snackbarMessage: `Error occured while rejecting LA modification changes try again!`,
          snackbarType: "error",
        });
      });
  };

  handleLaConformDate = (date) => {
    this.setState({
      clickLaConformDate: date
    });
  }

  handleDateChangeLA = (date) => {
    // console.log("here STATE CHANGE" + date)
    this.setState({
      date: date
    });
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  handleClearState = () => {
    this.setState({
      dispatchPlaningList: [],
      dispatchLoader: false,
    });
  };

  getAllStatesList = (countryId) => {
    axios.get(`${APIROUTES.GET_STATES_LIST}?country-id=${!countryId ? 101 : countryId}`,
      this.getOptions(this.props.user)
    )
      .then(
        (response) => {
          let responseData = arraySortBykeyAllBrowser(response.data, 'name');
          this.setState({
            allStateList: responseData,
            // allStateListDataFetchLoader: false,
          });
          // console.log("STATE LIST" + this.state.allStateList)
          // for (let key in responseData) {
          //   console.log(key + ":", responseData[key]);
          // }
        },
        (error) => {
          console.log("error", error);
          // this.setState({ allStateListDataFetchLoader: false, });
        }
      );

  };

  pdfpayload = (dd, data, codeFrom) => {
    console.log("indexLA", dd, codeFrom)
    let payload = {}
    if (this.state.iseWaySelect && codeFrom === "eway" && !this.state.eway) {
      this.setState({
        eway: data
      })
      // console.log("eway")
    }
    if (this.state.invoiceType === "Combined Invoice" && codeFrom === "invoice" && !this.state.e_invoice) {
      this.setState({
        e_invoice: data
      })
      // console.log("invoice")
      // payload.e_invoice = 
    }
    if (this.state.invoiceType === "Normal Invoice" && codeFrom === "invoice" && !this.state.normal_invoice) {
      this.setState({
        normal_invoice: data
      })
      // console.log("invoice")
      // payload.normal_invoice = 
    }

    // axios.post(`${APIROUTES.PDF_UPLOAD}${this.state?.laDetailsInDispatchCounter?.la_number}/`, { eway: data.split(",")[1] },
    //   this.getOptions(this.props?.user)
    // )
    //   .then(res => console.log(res))
    //   .catch(err => console.log(err))
  }

  handleSendPdfPayload = () => {
    let payload = {}
    if (this.state.eway && this.state.e_invoice) {
      //console.log("here", this.state.eway, this.state.e_invoice)
      payload = {
        eway: this.state.eway,
        e_invoice: this.state.e_invoice
      }
    } else
      if (this.state.eway && this.state.normal_invoice) {
        payload = {
          eway: this.state.eway,
          normal_invoice: this.state.normal_invoice
        }
      } else if (!this.state.iseWaySelect && this.state.normal_invoice) {
        payload = {
          normal_invoice: this.state.normal_invoice
        }
      } else if (!this.state.iseWaySelect && this.state.e_invoice) {
        payload = {
          e_invoice: this.state.e_invoice
        }
      }
    // console.log(payload)
    this.setState({
      isApiCalled: true
    })
    axios.post(`${APIROUTES.PDF_UPLOAD}${this.state?.laDetailsInDispatchCounter?.la_number}/`, payload,
      this.getOptions(this.props?.user)
    )
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          this.setState({
            eway: null,
            e_invoice: null,
            normal_invoice: null,
            isApiCalled: false
          })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          openSnackbar: true,
          snackbarType: "error",
          snackbarMessage: error?.response?.data?.message ? error.response.data.message : "Error while sending E-mail"
        })
      })

  }


  handleGetDistance = () => {
    axios({
      url: `${APIROUTES.GET_EWAY_DATA_DISTANCE_TRACKING}?source_pincode=${this.state.laDetailsInDispatchCounter?.plant?.plant_pincode
        }&destination_pincode=${this.state.laDetailsInDispatchCounter?.consignee
          ?.consignee_from_customer_addresses?.pincode ||
        this.state.laDetailsInDispatchCounter?.consignee
          ?.consignee_from_gst_information_addresses?.pincode
        }&la_number=${this.state?.laDetailsInDispatchCounter?.la_number}`,
      method: "get",
      headers: {
        Authorization: `JWT ${this.props.user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        this.setState({
          transporterDistance:
            response.data.IsSuccess === "true"
              ? parseFloat(response.data.Distance).toFixed(2)
              : 0.0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFetchLoadingAdviceDetails = (laNumber, callfor) => {
    this.setState({ laDispatchCounterLoader: true }, () => {
      axios
        .get(`${APIROUTES.FETCH_LA_DETAILS_IN_DISPATCH_COUNTER}${laNumber}/`)
        .then((response) => {
          if (response.status === 200) {
            this.state.allStateList.map(data => {
              if (data['id'] === response.data.plant.plant_state) {
                // console.log(data['id'] + ":" + data['name'])
                this.setState({
                  plant_state: data['name']
                })
              }
            })

            let data = response.data

            if (data.created_at && data.created_at_time)
              data.created_at_date_and_time = data.created_at + " " + data.created_at_time
            this.setState(
              {
                laDetailsInDispatchCounter: data,
                laDispatchCounterLoader: false,
                invoiceDownloadLoader: callfor === "download_screen" ? false : true,
                laNumber: laNumber
              },
              async () => {
                this.handleGetDistance();
                if (this.state.b2CQrImage === null) {
                  await upiqr({
                    currency: this?.state?.laDetailsInDispatchCounter?.customer?.currency_code,
                    payeeVPA:
                      this?.state?.laDetailsInDispatchCounter?.plant?.plant_upi_id,
                    payeeName:
                      this?.state?.laDetailsInDispatchCounter?.plant?.plant_name,
                    amount: this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value,
                    transactionRef: this.state?.laDetailsInDispatchCounter?.erp_invoice_number,
                    transactionNote: `SUPGSTN ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_gst_number
                      } PAYEE_AC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_no
                      } PAYEE_IFSC ${this?.state?.laDetailsInDispatchCounter?.plant?.plant_account_ifsc
                      } ${Number(this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount) > 0
                        ? "IGST " + this?.state?.laDetailsInDispatchCounter?.total_values?.igst_amount
                        : `CGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.cgst_amount} SGST ${this?.state?.laDetailsInDispatchCounter?.total_values?.sgst_amount}`
                      } CESS ${this?.state?.laDetailsInDispatchCounter?.total_values?.cess_amount
                      } TOT_AMT ${this?.state?.laDetailsInDispatchCounter?.total_values?.total_item_value
                      } INV_DATE ${moment(
                        this?.state?.laDetailsInDispatchCounter?.created_at,
                        ["DD/MM/YYYY", "YYYY-MM-DD"]
                      ).format("DD MMM YYYY")}`,
                  })
                    .then((upi) => {
                      // console.log(upi.qr); // data:image/png;base64,eR0lGODP...
                      //console.log("UPI", upi.intent); // upi://pay?pa=john@upi&pn=JOHN DOE
                      this.setState(
                        { b2CQrImage: upi.qr, dataInvoicePostLoader: false },
                        () => console.log(1)


                      );
                      //this.props.history.push("invoices/download");
                    })
                    .catch((err) => {
                      this.setState(
                        { b2CQrImage: null, dataInvoicePostLoader: false },
                        () => console.log("error in upiqr")
                      );
                    });
                }
                axios
                  .get(`${APIROUTES.GET_E_INVOICE_AND_E_WAY}${laNumber}/`)
                  .then((response) => {
                    // console.log(response)
                    this.setState({
                      eInvoiceResponse: response.data,
                      eWayBillResponse: response.data?.is_eway_created
                        ? response.data
                        : null,
                      iseWaySelect: response.data?.is_eway_created,
                      invoiceType: response.data?.invoice_type,
                    });
                    console.log("pushing to ila")
                    this.props.history.push(
                      `/internalLoadingAdvices/dc/2/details/invoices/download/${laNumber}`
                    );
                  });
              }
            );
          } else {
            this.setState({
              laDetailsInDispatchCounter: null,
              laDispatchCounterLoader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ laDispatchCounterLoader: false });
        });
    });
  };

  handleBill = (laNumber) => {
    this.handleFetchLoadingAdviceDetails(laNumber);

  }

  render() {
    const { isMobile } = this.props;
    // console.log(this.props)
    // console.log(this.props.location?.state?.pendingQuantity)
    return (
      <Switch>
        <Route
          exact
          path="/internalLoadingAdvices/dc/:dcId/details/invoices/download/:lanumber"
          render={(props) =>
          // isMobile ? (
          //   <DispatchOperation {...props} {...this.props} {...this.state} />
          // ) : 
          (
            <DispatchCounterInvoiceDownload
              {...props}
              {...this.props}
              {...this.state}
              isMobile={isMobile}
              handleFetchLoadingAdviceInDispatchCounter={this.handleFetchLoadingAdviceDetails}
              pdfpayload={this.pdfpayload}
              handleSendPdfPayload={this.handleSendPdfPayload}
            />
            // <p>Coming Soon..</p>
          )
          }
        />
        <Route
          exact
          path={[
            `/internalLoadingAdvices/order/:orderId/editLoadingAdvice/:laId`
          ]}
          render={(props) =>
          // isMobile ? (
          //   <OpenInDesktop {...this.props} {...this.states} />
          // ) : 
          (
            <LoadingAdvicesContainer
              {...props}
              user={this.props.user}
              {...this.state}
              isMobile={isMobile}
              fetchAllOrders={this.fetchOrders}
              fetchOrderData={this.fetchOrderDetails}
              fetchCustomerPaymentTerms={this.fetchCustomerPaymentTerms}
              fetchBuyers={this.fetchBuyers}
              fetchConsignees={this.fetchConsignees}
              fetchItemCategories={this.fetchItemCategories}
              fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
              fetchOrderPlantPlanning={this.fetchOrderPlantPlanning}
              updateLa={this.updateLa}
              createLa={this.createLa}
              fetchItemsData={this.fetchItemsData}
              itemDataFetchLoader={this.state.itemDataFetchLoader}
              plants={this.props.location?.state?.plants}
              plantId={this.props.location?.state?.plantId}
              date={this.props.location?.state?.date}
              status={this.props.location?.state?.status}
              laQty={this.props.location?.state?.laQty}
              itemId={this.props.location?.state?.itemId}
              customerId={this.props.location?.state?.customerId}
              laId={this.props.location?.state?.laId}
              itemTotalQuantity={this.props.location?.state?.itemTotalQuantity}
              orderNo={this.props.location?.state?.orderNo}
              paymentMethodId={this.props.location?.state?.paymentMethodId}
              buyerPinCode={this.props.location?.state?.buyerPinCode}
              orderStatus={this.props.location?.state?.orderStatus}
              handleFetchDispatchDetailsWithDate={
                this.handleFetchDispatchDetailsWithDate
              }
              handleFetchDispatchDetailsWithFilter={this.handleFetchDispatchDetailsWithFilter}
              handleDateChangeLA={this.handleDateChangeLA}
              deleteItemsData={this.deleteItemsData}
              fetchPendingQuantity={this.fetchPendingQuantity}
              handleLaApprove={this.handleLaApprove}
              fetchTcsRate={this.fetchTcsRate}
              setRowsCount={this.setRowsCount}

            />
          )
          }
        />
        <Route
          exact
          path={[
            `/internalLoadingAdvices/order/:orderId/overviewLoadingAdvice/:laId`
          ]}
          render={(props) =>
          // isMobile ? (
          //   <OpenInDesktop {...this.props} {...this.states} />
          // ) : 
          (
            <LoadingAdvicesContainer
              {...props}
              user={this.props.user}
              {...this.state}
              isMobile={isMobile}
              fetchAllOrders={this.fetchOrders}
              fetchOrderData={this.fetchOrderDetails}
              fetchCustomerPaymentTerms={this.fetchCustomerPaymentTerms}
              fetchBuyers={this.fetchBuyers}
              fetchConsignees={this.fetchConsignees}
              fetchItemCategories={this.fetchItemCategories}
              fetchPlantsForItemCategory={this.fetchPlantsForItemCategory}
              fetchOrderPlantPlanning={this.fetchOrderPlantPlanning}
              updateLa={this.updateLa}
              createLa={this.createLa}
              fetchItemsData={this.fetchItemsData}
              itemDataFetchLoader={this.state.itemDataFetchLoader}
              plants={this.props.location?.state?.plants}
              plantId={this.props.location?.state?.plantId}
              date={this.props.location?.state?.date}
              status={this.props.location?.state?.status}
              laQty={this.props.location?.state?.laQty}
              itemId={this.props.location?.state?.itemId}
              customerId={this.props.location?.state?.customerId}
              laId={this.props.location?.state?.laId}
              itemTotalQuantity={this.props.location?.state?.itemTotalQuantity}
              orderNo={this.props.location?.state?.orderNo}
              paymentMethodId={this.props.location?.state?.paymentMethodId}
              buyerPinCode={this.props.location?.state?.buyerPinCode}
              orderStatus={this.props.location?.state?.orderStatus}
              handleFetchDispatchDetailsWithDate={
                this.handleFetchDispatchDetailsWithDate
              }
              handleFetchDispatchDetailsWithFilter={this.handleFetchDispatchDetailsWithFilter}
              handleDateChangeLA={this.handleDateChangeLA}
              deleteItemsData={this.deleteItemsData}
              fetchPendingQuantity={this.fetchPendingQuantity}
              handleLaApprove={this.handleLaApprove}
              fetchTcsRate={this.fetchTcsRate}
              overview={true}
              setRowsCount={this.setRowsCount}

            />
          )
          }
        />
        <Route
          exact
          path="/internalLoadingAdvices"
          render={(props) =>
          // isMobile ? (
          //   <LoadingAdviceMobile {...this.state} {...props} {...this.props} />
          // ) : 
          (
            <LoadingAdvicePlanningWeb
              {...this.state}
              {...props}
              {...this.props}
              isMobile={isMobile}
              handleFetchDispatchLADetails={this.handleFetchDispatchLADetails}
              handleFetchDispatchDetailsWithDate={
                this.handleFetchDispatchDetailsWithDate
              }
              handleFetchDispatchDetailsWithFilter={this.handleFetchDispatchDetailsWithFilter}
              handleFetchPlantWeighbridge={this.handleFetchPlantWeighbridge}
            />
          )
          }
        />
        <Route
          exact
          path="/internalLoadingAdvices/:plantId"
          render={(props) =>
          // isMobile ? (
          //   <LoadingAdviceMobile {...this.state} {...props} {...this.props} />
          // ) : 
          (
            <LoadingAdvicePlanningDetailsWeb
              {...this.state}
              {...props}
              {...this.props}
              isMobile={isMobile}
              date={this.state?.date}
              clickLaConformDate={this.state?.clickLaConformDate}
              dispatchPlaningList={this.state?.dispatchPlaningList}
              plantWeighbridge={this.state?.plantWeighbridge}
              dispatchLoader={this.state?.dispatchLoader}
              handleFetchDispatchLADetails={this.handleFetchDispatchLADetails}
              handleClearState={this.handleClearState}
              handleFetchDispatchDetailsWithDate={
                this.handleFetchDispatchDetailsWithDate
              }
              handleFetchDispatchDetailsWithFilter={this.handleFetchDispatchDetailsWithFilter}
              searchLAValue={this.state.searchLAValue}
              handleLASearchValue={this.handleLASearchValue}
              handleLASearchClear={this.handleLASearchClear}
              handleLaApprove={this.handleLaApprove}
              handleCancelLa={this.handleCancelLa}
              handleSnackbarClose={this.handleSnackbarClose}
              handleFetchPlantWeighbridge={this.handleFetchPlantWeighbridge}
              fetchPendingQuantity={this.fetchPendingQuantity}
              approveLaModification={this.approveLaModification}
              handleDiscardWeighSlips={this.handleDiscardWeighSlips}
              handleUnlockWeighbridge={this.handleUnlockWeighbridge}
              handleFetchSalesReturn={this.handleFetchSalesReturn}
              handleChangeLAStatus={this.handleChangeLAStatus}
              handleLAReplacement={this.handleLAReplacement}
              handleLACancelAfterBilled={this.handleLACancelAfterBilled}
              getInvoiceDetails={this.getInvoiceDetails}
              invoiceDetails={this.state.invoiceDetails}
              rejectLaModification={this.rejectLaModification}
              handleDateChangeLA={this.handleDateChangeLA}
              hideLADetailsDialog={this.hideLADetailsDialog}
              prevDate={this.props.location?.state?.prevDate}
              enableLoader={this.enableLoader}
              setRowsCount={this.setRowsCount}
              handleBill={this.handleBill}
            />
          )
          }
        />
      </Switch>
    );
  }
}
export default LoadingAdviceContainer;