import React, { Component } from "react";
import WebLayout from "../../../../layouts/b2b/WebLayout";
import Details from "../Details";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  singleOrderDetailsMainBody: {
    width: "100%",
  },
};
class SingleOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <WebLayout appBar orderSelect>
        <div className={classes.singleOrderDetailsMainBody}>
          <Details {...this.props} />
        </div>
      </WebLayout>
    );
  }
}
export default withStyles(styles)(SingleOrderDetails);
