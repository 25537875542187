import React, { Component } from "react";
import Details from "../Details";
import { withStyles } from "@material-ui/core/styles";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {
  b2bSingleDispatchMainBody: {
    height: window.innerHeight - 70,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  },
};
class B2bSingleDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    if (this.props.history.length > 2)
      // this.props.history.goBack();
      this.props.history.push(String(localStorage.getItem("returnUrl")))
    else
      this.props.history.push("/dispatch")
  };
  render() {
    const { classes } = this.props;
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        <div className={classes.b2bSingleDispatchMainBody}>
          <Details {...this.props} />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(B2bSingleDispatch);
