import React, { Component } from "react";
import Cards from "./Cards";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SnackbarComponent from "../../common/SnackbarComponent";

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "18vh",
  },
};
class ManageDispatchCounterMaster extends Component {
  state = {
    open: false,
    dispatchCounterName: "",
    activeStatus: false,
    dispatchCounterId: "",
    updateDispatchCounter: false,
    openDeleteDialog: false,
    openSnackbar: false,
    snackbarMessage: null,
    snackbarType: "",
  };
  componentDidMount() {
    setTimeout(() => {
      this.props.fetchDispatchCounterMaster(this.props.selectedPlantId);
    }, 2000);
  }
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  handleTextChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  handleClose = () => {
    this.setState({
      open: false,
      dispatchCounterName: "",
      activeStatus: false,
      dispatchCounterId: "",
      updateDispatchCounter: false,
    });
  };
  handleCloseDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      dispatchCounterName: "",
      activeStatus: false,
      updateDispatchCounter: false,
      dispatchCounterId: "",
    });
  };
  handleAddEvent = () => {
    this.setState({ open: true });
  };

  handleDispatchCounterInPlantMasterAction = (updateDispatchCounter, dispatchCounterName, activeStatus, dispatchCounterId) => {
    if (updateDispatchCounter) {
      this.props
        .handleUpdateDispatchCounterInPlantMaster(
          dispatchCounterName,
          activeStatus,
          dispatchCounterId
        )
        .then((data) => {
          if (data.status === 200) {
            this.setState({
              open: false,
              dispatchCounterName: "",
              activeStatus: false,
              updateDispatchCounter: false,
              openSnackbar: true,
              snackbarMessage: "Updated successfully ",
              snackbarType: "success",
            });
            this.props.fetchDispatchCounterMaster(
              this.props.selectedPlantId
            );
          }
        })
        .catch((error) => {
          for (let key in error.response.data) {
            this.setState({
              openSnackbar: true,
              // snackbarMessage: `Something went wrong `,
              snackbarMessage: `${key + "  :  " + error.response.data[key]}`,
              snackbarType: "error",
            });
          }
        });
    } else {
      this.props
        .handleAddDispatchCounterInPlantMaster(
          dispatchCounterName,
          activeStatus
        )
        .then((data) => {
          if (data.status === 201) {
            this.setState({
              open: false,
              openSnackbar: true,
              snackbarMessage: "Added successfully ",
              snackbarType: "success",
            });
            this.props.fetchDispatchCounterMaster(
              this.props.selectedPlantId
            );
          }
        })
        .catch((error) => {
          for (let key in error.response.data) {
            this.setState({
              openSnackbar: true,
              // snackbarMessage: `Something went wrong `,
              snackbarMessage: `${error.response.data[key]}`,
              snackbarType: "error",
            });
          }
        });
    }
  }

  handleOpenUpdateDialog = (selectedUpdateWeighbridge) => {
    console.log(selectedUpdateWeighbridge);
    this.setState({
      open: true,
      updateDispatchCounter: true,
      dispatchCounterName: selectedUpdateWeighbridge.dispatch_counter_name,
      activeStatus: selectedUpdateWeighbridge.is_active,
      dispatchCounterId: selectedUpdateWeighbridge.id,
    });
  };
  render() {
    const {
      updateDispatchCounter,
      dispatchCounterName,
      activeStatus,
      dispatchCounterId,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        {this.props.dispatchCounterListLoader ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <Cards
            title="Dispatch Counter"
            data={this.props.dispatchCounterList}
            handleAddEvent={this.handleAddEvent}
            handleOpenUpdateDialog={this.handleOpenUpdateDialog}
            user={this.props.user}
          />
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {updateDispatchCounter ? "UPDATE" : "ADD"}
          </DialogTitle>
          <DialogContent>
            <TextField
              name="dispatchCounterName"
              autoFocus
              margin="dense"
              id="name"
              label="Enter Dispatch Name"
              fullWidth
              value={this.state.dispatchCounterName}
              onChange={(e) => this.handleTextChange(e)}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>ACTIVE</span>
              <Checkbox
                checked={this.state.activeStatus}
                onChange={this.handleChange("activeStatus")}
                value="activeStatus"
              />
            </div>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              // justifyContent: updateDispatchCounter
              //   ? "space-around"
              //   : "flex-end",
            }}
          >
            <div>
              {/* {updateDispatchCounter ? (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    padding: "4px 10px",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      open: false,
                      updateDispatchCounter: false,
                      openDeleteDialog: true,
                    });
                  }}
                >
                  <DeleteIcon />
                  Delete
                </Button>
              ) : null} */}
            </div>
            <div>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={this.state.dispatchCounterName === ""}
                onClick={(e) => {
                  this.handleDispatchCounterInPlantMasterAction(updateDispatchCounter, dispatchCounterName, activeStatus, dispatchCounterId);
                  this.handleClose()
                  // e.preventDefault();
                  // if (updateDispatchCounter) {
                  //   this.props
                  //     .handleUpdateDispatchCounterInPlantMaster(
                  //       dispatchCounterName,
                  //       activeStatus,
                  //       dispatchCounterId
                  //     )
                  //     .then((data) => {
                  //       if (data.status === 200) {
                  //         this.setState({
                  //           open: false,
                  //           dispatchCounterName: "",
                  //           activeStatus: false,
                  //           updateDispatchCounter: false,
                  //           openSnackbar: true,
                  //           snackbarMessage: "Updated successfully ",
                  //           snackbarType: "success",
                  //         });
                  //         this.props.fetchDispatchCounterMaster(
                  //           this.props.selectedPlantId
                  //         );
                  //       }
                  //     })
                  //     .catch((e) => {
                  //       this.setState({
                  //         openSnackbar: true,
                  //         snackbarMessage: "Something went wrong",
                  //         snackbarType: "error",
                  //       });
                  //     });
                  // } else {
                  //   this.props
                  //     .handleAddDispatchCounterInPlantMaster(
                  //       dispatchCounterName,
                  //       activeStatus
                  //     )
                  //     .then((data) => {
                  //       if (data.status === 201) {
                  //         this.setState({
                  //           open: false,
                  //           openSnackbar: true,
                  //           snackbarMessage: "Added successfully ",
                  //           snackbarType: "success",
                  //         });
                  //         this.props.fetchDispatchCounterMaster(
                  //           this.props.selectedPlantId
                  //         );
                  //       }
                  //     })
                  //     .catch((e) => {
                  //       this.setState({
                  //         openSnackbar: true,
                  //         snackbarMessage: "Something went wrong",
                  //         snackbarType: "error",
                  //       });
                  //     });
                  // }
                }}
                color="primary"
              >
                {updateDispatchCounter ? "UPDATE" : "ADD"}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">DELETE</DialogTitle>
          <DialogContent>Are you sure you want to delete this ?</DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px 20px",
            }}
          >
            <Button variant="contained" onClick={this.handleCloseDeleteDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ openDeleteDialog: false });
                this.props
                  .handleDeleteDispatchCounter(dispatchCounterId)
                  .then((data) => {
                    if (data.status === 204) {
                      this.setState({
                        dispatchCounterName: "",
                        activeStatus: false,
                        updateDispatchCounter: false,
                        dispatchCounterId: "",
                        openDeleteDialog: false,
                        openSnackbar: true,
                        snackbarMessage: "Deleted successfully ",
                        snackbarType: "success",
                      });
                      this.props.fetchDispatchCounterMaster(
                        this.props.selectedPlantId
                      );
                    }
                  })
                  .catch((error) => {
                    for (let key in error.response.data) {
                      this.setState({
                        openSnackbar: true,
                        // snackbarMessage: `Something went wrong `,
                        snackbarMessage: `${key + "  :  " + error.response.data[key]}`,
                        snackbarType: "error",
                      });
                    }
                  });
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
          customAutoHideDuration={4000}
        />
      </>
    );
  }
}
export default withStyles(styles)(ManageDispatchCounterMaster);
