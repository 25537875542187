import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Tooltip,
  TablePagination,
  Popper,
  MenuItem,
  Popover,
  ClickAwayListener,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  grey,
  secondaryGrey,
  whiteSmoke,
  drawerTextColor,
  successButtonBackground,
  secondaryBlueText,
} from "../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import "../../../../styles/internal/scroll.css";
import { useLocation } from "react-router-dom";
import Downshift from "downshift";
import ToolTipComponent from "../../common/ToolTipComponent";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  formControl: {
    width: "80%",
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
    width: "35%",
  },
  tableBody: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(13),
  },
  autoComplete: {
    fontFamily: 'Poppins',
    fontSize: pxToEm(16),
  },
  addNewButton: {
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  customerTypeTitle: {
    fontSize: pxToEm(15),
    color: secondaryBlueText,
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  addAndSaveButton: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0px 20px 0px",
    justifyContent: "space-between",
  },
  saveCancelButton: {
    display: "flex",
    placeSelf: "strech",
  },
  alignStateText: {
    display: "flex",
    placeContent: "flex-start",
    alignItems: "center",
  },
  rightSpacing: {
    display: "flex",
    marginLeft: "10px",
    fontSize: "0.8em",
    alignItems: "center",
    fontWeight: "normal",
  },
  editIcon: {
    heigth: "15px",
    width: "15px",
  },
  iconContainer: {
    height: 30,
    width: 30,
    background: "yellow",
    borderRadius: 100,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: whiteSmoke,
    overflow: "hidden",
    color: grey,
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "10px 5px 10px 5px",
      },
    },
  },
});
export default function CustomerBuyerConsigneeDataDisplayTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElConsignee, setAnchorElConsignee] = React.useState(null);
  const [customerType, setCustomerType] = React.useState(props.props?.customerType);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const Id = location.pathname.split("/")[3];

  React.useEffect(() => {
    setLoading(open)
    if (open) {
      setLoading(props.props.customerConsigneeSelectionListLoader);
    }
  }, [open]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes = useStyles();
  const rows =
    props.displayTableFor === "buyer"
      ? props.props.customerBuyersList &&
        props.props.customerBuyersList.length > 0
        ? props.props.customerBuyersList
        : []
      : props.displayTableFor === "consignee"
        ? props.props.customerConsigneeList &&
          props.props.customerConsigneeList.length > 0
          ? props.props.customerConsigneeList
          : []
        : [];
  // console.log("ROWS : ", rows)
  const [customerBuyerConsigneeOperation, setcustomerBuyerConsigneeOperation] =
    React.useState("view");
  const allSelectionOptions =
    props.displayTableFor === "buyer"
      ? props.props.customerBuyersSelectionList &&
        props.props.customerBuyersSelectionList.length > 0
        ? props.props.customerBuyersSelectionList
        : []
      : props.displayTableFor === "consignee"
        ? props.props.customerConsigneeSelectionList &&
          props.props.customerConsigneeSelectionList.length > 0
          ? props.props.customerConsigneeSelectionList
          : []
        : [];
  // console.log("allSelectionOptions : ", allSelectionOptions)
  const filteredSelectionOption =
    allSelectionOptions.length > 0
      ? allSelectionOptions.filter(
        (x) =>
          !rows.filter((y) => {
            if (props.displayTableFor === "buyer") {
              return Number(y.buyer_detail.id) === Number(x.id);
            } else {
              return (y.consignee_from_customer_addresses_detail.id === x.id &&
                y.consignee_from_customer_addresses_detail.building_name === x.building_name);
            }
          }).length
      )
      : allSelectionOptions;
  // console.log("filteredSelectionOption : ", filteredSelectionOption)
  // console.log(props.customerDealersDistributors)
  const dealerfilteredSelectionOption =
    filteredSelectionOption.length > 0 && props?.props?.customerDealersDistributors.length > 0
      ? filteredSelectionOption.filter(
        (x) =>
          !props?.props?.customerDealersDistributors.filter((y) => {
            if (props.displayTableFor === "buyer") {
              return Number(y.dealer.id) === Number(x.id);
            } else {
              return Number(y.dealer.id) === Number(x.customer.id);
            }
          }).length
      )
      : filteredSelectionOption;
  // console.log("dealerfilteredSelectionOption : ", dealerfilteredSelectionOption)
  const handleCustomerBuyerConsigneeOperationChange = (operation) => {
    setcustomerBuyerConsigneeOperation(operation);
  };
  // { console.log(allSelectionOptions, dealerfilteredSelectionOption, props.props?.customerDealersDistributors, rows) }

  return (
    <>
      <Typography className={classes.customerTypeTitle} component={"span"}>
        {props.displayTableFor === "buyer"
          ? "BUYER"
          : props.displayTableFor === "consignee"
            ? "CONSIGNEE"
            : ""}
      </Typography>
      <ThemeProvider theme={theme}>
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="left" className={classes.tableHead}>
                    {props.displayTableFor === "buyer" ? "NAME" : "ADDRESS"}
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    {props.displayTableFor === "buyer" ? "GSTIN / PAN" : "NAME"}
                  </TableCell>
                  <TableCell align="left" className={classes.tableHead}>
                    CONTACT
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableHead}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.length > 0
                  ? rows
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          className={classes.tableBody}
                        >
                          {props.displayTableFor === "buyer"
                            ? row.buyer_detail
                              ? row.buyer_detail.gst_information !== null
                                ? row.buyer_detail.gst_information.trade_name
                                  ? row.buyer_detail.gst_information
                                    .trade_name
                                  : row.buyer_detail.gst_information
                                    .legal_name_of_business
                                    ? row.buyer_detail.gst_information
                                      .legal_name_of_business
                                    : ""
                                : row.buyer_detail.pan_card_information !==
                                  null
                                  ? row.buyer_detail.pan_card_information
                                    .company_name
                                    ? row.buyer_detail.pan_card_information
                                      .company_name
                                    : ""
                                  : ""
                              : ""
                            : row.consignee_from_customer_addresses_detail
                              ? row.consignee_from_customer_addresses_detail.address ?
                                (
                                  (row.consignee_from_customer_addresses_detail.address
                                    ? row.consignee_from_customer_addresses_detail.address + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.city
                                    ? row.consignee_from_customer_addresses_detail.city + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.state
                                    ? row.consignee_from_customer_addresses_detail.state + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.country
                                    ? row.consignee_from_customer_addresses_detail.country + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.pincode
                                    ? row.consignee_from_customer_addresses_detail.pincode
                                    : "")
                                )
                                :
                                (
                                  (row.consignee_from_customer_addresses_detail.door_number
                                    ? row.consignee_from_customer_addresses_detail.door_number + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.floor_number
                                    ? 'Floor No - ' + row.consignee_from_customer_addresses_detail.floor_number + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.building_name
                                    ? row.consignee_from_customer_addresses_detail.building_name + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.street
                                    ? row.consignee_from_customer_addresses_detail.street + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.location
                                    ? row.consignee_from_customer_addresses_detail.location + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.district_name
                                    ? row.consignee_from_customer_addresses_detail.district_name + ", "
                                    : "")
                                  +
                                  ((row.consignee_from_customer_addresses_detail?.city_name &&
                                    row.consignee_from_customer_addresses_detail?.district_name)
                                    ? ((row.consignee_from_customer_addresses_detail?.city_name).trim().toUpperCase() !==
                                      (row.consignee_from_customer_addresses_detail?.district_name).trim().toUpperCase())
                                      ? row.consignee_from_customer_addresses_detail?.city_name + ", "
                                      : ""
                                    : row.consignee_from_customer_addresses_detail?.city_name
                                      ? row.consignee_from_customer_addresses_detail?.city_name + ", "
                                      : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.state_name
                                    ? row.consignee_from_customer_addresses_detail.state_name + ", "
                                    : "")
                                  +
                                  (row.consignee_from_customer_addresses_detail.pincode
                                    ? row.consignee_from_customer_addresses_detail.pincode
                                    : "")
                                )
                              : ""}
                        </TableCell>
                        <TableCell align="left" className={classes.tableBody}>
                          {props.displayTableFor === "buyer"
                            ? row.buyer_detail
                              ? row.buyer_detail.gst_information !== null
                                ? row.buyer_detail.gst_information
                                  .gstin_number
                                  ? row.buyer_detail.gst_information
                                    .gstin_number
                                  : ""
                                : row.buyer_detail.pan_card_information !==
                                  null
                                  ? row.buyer_detail.pan_card_information
                                    .pan_card_number
                                    ? row.buyer_detail.pan_card_information
                                      .pan_card_number
                                    : ""
                                  : ""
                              : ""
                            : row.customer_detail
                              ? row.customer_detail.gst_information !== null
                                ? row.customer_detail.gst_information.trade_name
                                  ? row.customer_detail.gst_information
                                    .trade_name
                                  : row.customer_detail.gst_information
                                    .legal_name_of_business
                                    ? row.customer_detail.gst_information
                                      .legal_name_of_business
                                    : row.customer_detail.first_name +
                                    " " +
                                    row.customer_detail.last_name
                                : row.customer_detail.pan_card_information !==
                                  null
                                  ? row.customer_detail.pan_card_information
                                    .company_name
                                    ? row.customer_detail.pan_card_information
                                      .company_name
                                    : row.customer_detail.first_name +
                                    " " +
                                    row.customer_detail.last_name
                                  : ""
                              : ""}
                        </TableCell>
                        <TableCell align="left" className={classes.tableBody}>
                          {props.displayTableFor === "buyer"
                            ? row.buyer_detail
                              ? row.buyer_detail.credentials
                                ? row.buyer_detail.credentials.phone
                                  ? row.buyer_detail.credentials.phone
                                  : ""
                                : ""
                              : ""
                            : row.customer_detail
                              ? row.customer_detail.credentials
                                ? row.customer_detail.credentials.phone
                                  ? row.customer_detail.credentials.phone
                                  : ""
                                : ""
                              : ""}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBody}
                        >
                          {/* {console.log(row.customer === parseInt(Id))} */}

                          {props.displayTableFor === "buyer" ? (
                            props.props.removeCustomerBuyerLoader ===
                              index ? (
                              <CircularProgress size={15} />
                            ) : row.buyer_detail.id === parseInt(Id) ? (
                              ""
                            ) : (
                              <ToolTipComponent
                                title="You have no edit permission on this widget"
                                condition={!hasUpdateAccess(props.props.user, "customerManagement")}
                                title2={`Click to remove ${props.displayTableFor}`}
                                condition2={hasUpdateAccess(props.props.user, "customerManagement")}
                              >
                                <IconButton
                                  className={classes.removeIconDimensions}
                                  onClick={(evt) => {
                                    props.props.handleRemoveCustomerBuyerConsignee(
                                      row.id,
                                      props.displayTableFor
                                    );
                                    props.props.handleRemoveCustomerBuyerConsigneeCircularLoadingShow(
                                      index,
                                      props.displayTableFor
                                    );
                                  }}
                                  disabled={
                                    hasUpdateAccess(props.props.user, "customerManagement")
                                      ? false
                                      : true
                                  }
                                >
                                  <DeleteOutlinedIcon />
                                </IconButton>
                              </ToolTipComponent>
                            )
                          ) : props.props.removeCustomerConsigneeLoader ===
                            index ? (
                            <CircularProgress size={15} />
                          ) : row.customer_detail.id === parseInt(Id) ? (
                            " "
                          ) : (
                            <ToolTipComponent
                              title="You have no edit permission on this widget"
                              condition={!hasUpdateAccess(props.props.user, "customerManagement")}
                              title2={`Click to remove ${props.displayTableFor}`}
                              condition2={hasUpdateAccess(props.props.user, "customerManagement")}
                            >
                              <IconButton
                                className={classes.removeIconDimensions}
                                onClick={(evt) => {
                                  props.props.handleRemoveCustomerBuyerConsignee(
                                    row.id,
                                    props.displayTableFor
                                  );
                                  props.props.handleRemoveCustomerBuyerConsigneeCircularLoadingShow(
                                    index,
                                    props.displayTableFor
                                  );
                                }}
                                disabled={
                                  hasUpdateAccess(props.props.user, "customerManagement")
                                    ? false
                                    : true
                                }
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </ToolTipComponent>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
                {customerBuyerConsigneeOperation === "add" ? (
                  <TableRow key={props.dealerDistributorId}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className={classes.tableBody}
                    >
                      {props.displayTableFor !== 'buyer' ?
                        (<Downshift id="downshift-popper">
                          {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
                            <div className={classes.formControl}>
                              <TextField
                                // label="Search Consignee"
                                variant="outlined"
                                size="small"
                                style={{ fontFamily: 'Poppins', width: 280 }}
                                // fullWidth
                                onChangeCapture={props.props.handleChangeCustomerBuyerConsignee(
                                  props.displayTableFor
                                )}
                                inputRef={(node) => setAnchorElConsignee(node)}
                                InputProps={{
                                  ...getInputProps({
                                    value: props.props.consigneeSearch,
                                    placeholder: "Search Consignee",
                                  }),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        style={{ padding: 0, display: props?.props?.consigneeSearch === '' ? 'none' : 'block' }}
                                        onClick={() => props.props.handleConsigneeSearchFieldCancel(props.displayTableFor)}
                                      >
                                        <CloseOutlinedIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {anchorElConsignee && (
                                <Popper
                                  open={props?.props?.openCustomerConsigneeListPopper}
                                  anchorEl={anchorElConsignee}
                                  placement="bottom-start"
                                >
                                  <ClickAwayListener onClickAway={(e) => props.props.handleConsigneeSearchFieldCancel("close")}>
                                    <div
                                      {...(isOpen
                                        ? getMenuProps({}, { suppressRefError: true })
                                        : {})}
                                    >
                                      <Paper
                                        square
                                        style={{
                                          maxHeight: "195px",
                                          overflowY: "scroll",
                                          marginTop: 8,
                                          width: 280
                                          // width: anchorEl ? anchorEl.clientWidth : null,
                                        }}
                                      >
                                        {filteredSelectionOption.map((data, index) => (
                                          <MenuItem
                                            key={index}
                                            component="div"
                                            onClick={() => {
                                              props.props?.handleClickCustomerBuyerConsignee(props.displayTableFor, data)
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                lineHeight: "1.2em",
                                                fontFamily: 'Poppins',
                                              }}
                                            >
                                              <span style={{ fontSize: "1em", whiteSpace: 'break-spaces' }}>
                                                {
                                                  (data.customer.gst_information !== null
                                                    ? data.customer.gst_information?.trade_name
                                                      ? data.customer.gst_information?.trade_name
                                                      : data.customer.gst_information.legal_name_of_business
                                                        ? data.customer.gst_information.legal_name_of_business
                                                        : (data.customer.first_name ? data.customer.first_name : "") +
                                                        (data.customer.last_name ? data.customer.last_name : "")
                                                    : data.customer.pan_card_information !== null
                                                      ? data.customer.pan_card_information.company_name
                                                        ? data.customer.pan_card_information.company_name
                                                        : (data.customer.first_name ? data.customer.first_name : "") +
                                                        " " +
                                                        (data.customer.last_name ? data.customer.last_name : "")
                                                      : (data.customer.first_name ? data.customer.first_name : "") +
                                                      " " +
                                                      (data.customer.last_name ? data.customer.last_name : ""))
                                                }
                                              </span>
                                              <span style={{ color: grey, fontSize: "0.9em", whiteSpace: 'break-spaces' }}>
                                                {data.address ?
                                                  (
                                                    (data.address ? data.address + ", " : "")
                                                    +
                                                    (data.city ? data.city + ", " : "")
                                                    +
                                                    (data.state ? data.state + ", " : "")
                                                    +
                                                    (data.country ? data.country + ", " : "")
                                                    +
                                                    (data.pincode ? data.pincode : "")
                                                  )
                                                  :
                                                  (
                                                    (data.door_number ? data.door_number + ", " : "")
                                                    +
                                                    (data.floor_number ? 'Floor No - ' + data.floor_number + ", " : "")
                                                    +
                                                    (data.building_name ? data.building_name + ", " : "")
                                                    +
                                                    (data.street ? data.street + ", " : "")
                                                    +
                                                    (data.location ? data.location + ", " : "")
                                                    +
                                                    (data.district_name ? data.district_name + ", " : "")
                                                    +
                                                    ((data?.city_name && data?.district_name)
                                                      ? ((data?.city_name).trim().toUpperCase() !== (data?.district_name).trim().toUpperCase())
                                                        ? data?.city_name + ", "
                                                        : ""
                                                      : data?.city_name
                                                        ? data?.city_name + ", "
                                                        : "")
                                                    +
                                                    (data.state_name ? data.state_name + ", " : "")
                                                    +
                                                    (data.pincode ? data.pincode : "")
                                                  )
                                                }
                                              </span>
                                            </div>
                                          </MenuItem>
                                        ))}
                                      </Paper>
                                    </div>
                                  </ClickAwayListener>
                                </Popper>
                              )}
                            </div>
                          )}
                        </Downshift>)
                        :
                        ['DISTRIBUTOR', 'BROKER', 'TRADER'].includes(customerType) ?
                          (<Downshift id="downshift-popper">
                            {({ getInputProps, getMenuProps, inputValue, isOpen }) => (
                              <div className={classes.formControl}>
                                <TextField
                                  label="Search"
                                  variant="outlined"
                                  size="small"
                                  style={{ fontFamily: 'Poppins', width: 280 }}
                                  // fullWidth
                                  onChangeCapture={props.props.handleChangeCustomerBuyerConsignee(
                                    props.displayTableFor
                                  )}
                                  inputRef={(node) => setAnchorEl(node)}
                                  InputProps={{
                                    ...getInputProps({
                                      value: props.displayTableFor === 'buyer'
                                        ? props?.props?.buyerSearch
                                        : props.props.consigneeSearch,
                                      placeholder: "",
                                    }),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          style={{ padding: 0, display: props?.props?.buyerSearch === '' ? 'none' : 'block' }}
                                          onClick={() => props.props.handleBuyerSearchFieldCancel(props.displayTableFor)}
                                        >
                                          <CloseOutlinedIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {anchorEl && (
                                  <Popper
                                    open={props.displayTableFor === 'buyer'
                                      ? props?.props?.openCustomerBuyerListPopper
                                      : props?.props?.openCustomerConsigneeListPopper}
                                    anchorEl={anchorEl}
                                    placement="bottom-start"
                                  >
                                    <ClickAwayListener onClickAway={(e) => props.props.handleBuyerSearchFieldCancel("close")}>
                                      <div
                                        {...(isOpen
                                          ? getMenuProps({}, { suppressRefError: true })
                                          : {})}
                                      >
                                        <Paper
                                          square
                                          style={{
                                            maxHeight: "195px",
                                            overflowY: "scroll",
                                            marginTop: 8,
                                            width: 280
                                            // width: anchorEl ? anchorEl.clientWidth : null,
                                          }}
                                        >
                                          {filteredSelectionOption.map((data, index) => (
                                            <MenuItem
                                              key={index}
                                              component="div"
                                              onClick={() => {
                                                props.props?.handleClickCustomerBuyerConsignee(props.displayTableFor, data)
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  lineHeight: "1.1em",
                                                  fontFamily: 'Poppins',
                                                }}
                                              >
                                                <span>
                                                  {props.displayTableFor === "buyer"
                                                    ? data.name
                                                    : data.address ? data.address : data.building_name ? data.building_name : "" +
                                                      ", " +
                                                      (data.city ? data.city : data.street ? data.street : "") +
                                                      ", " +
                                                      (data.country ? data.country : data.state_name ? data.state_name : "")
                                                  }
                                                </span>
                                                {/* {console.log(data.name, data?.gstinPanNumber?.length > 10)} */}
                                                <span style={{ color: grey, fontSize: "0.7em" }}>
                                                  {!((data?.gstinPanNumber)?.length > 10)
                                                    ? `PAN - ${data?.gstinPanNumber}`
                                                    : `GSTIN - ${data?.gstinPanNumber}`}
                                                </span>
                                                <span style={{ color: grey, fontSize: "0.7em" }}>
                                                  PHONE - {data?.phone || ""}
                                                </span>
                                              </div>
                                            </MenuItem>
                                          ))}
                                        </Paper>
                                      </div>
                                    </ClickAwayListener>
                                  </Popper>
                                )}
                              </div>
                            )}
                          </Downshift>)
                          :
                          (<Autocomplete
                            id="scroll"
                            options={filteredSelectionOption}
                            className={classes.formControl}
                            onChange={props.props.handleChangeCustomerBuyerConsignee(
                              props.displayTableFor
                            )}
                            getOptionLabel={(option) =>
                              props.displayTableFor === "buyer"
                                ? option.name
                                : option.address ? option.address : option.building_name ? option.building_name : "" +
                                  ", " +
                                  (option.city ? option.city : option.street ? option.street : "") +
                                  ", " +
                                  (option.country ? option.country : option.state_name ? option.state_name : "")
                            }
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                margin="nomal"
                                size="small"
                              />
                            )}
                          />)
                      }
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      {props.displayTableFor === "buyer"
                        ? props.props.customerBuyerGstinPanNumber
                        : props.props.customerConsigneeName}
                    </TableCell>
                    <TableCell align="left" className={classes.tableBody}>
                      {props.displayTableFor === "buyer"
                        ? props.props.customerBuyerContactNumber
                        : props.props.customerConsigneeContactNumber}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableBody}
                    ></TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )}
        </Paper>
      </ThemeProvider>
      <div className={classes.addAndSaveButton}>
        {customerBuyerConsigneeOperation === "view" ? (
          <ToolTipComponent
            placement='top-start'
            title="You have no edit permission on this widget"
            condition={!hasUpdateAccess(props.props.user, "customerManagement")}
          >
            <Button
              variant="outlined"
              size="medium"
              startIcon={<AddOutlinedIcon />}
              className={classes.addNewButton}
              onClick={() => {
                handleCustomerBuyerConsigneeOperationChange("add");
                props.props.handleBuyerSearchFieldCancel(props.displayTableFor)
                props.props.handleConsigneeSearchFieldCancel(props.displayTableFor)
              }}
              disabled={
                hasUpdateAccess(props.props.user, "customerManagement")
                  ? false
                  : true
              }
            >
              ADD NEW
            </Button>
          </ToolTipComponent>
        ) : null}
        {customerBuyerConsigneeOperation ===
          "view" ? null : props.displayTableFor === "buyer" ? (
            props.saveCustomerBuyerLoader ? (
              <CircularProgress disableShrink />
            ) : (
              <div className={classes.saveCancelButton}>
                <Button
                  size="medium"
                  disabled={!props.props.customerBuyerId}
                  className={classes.saveButton}
                  variant="contained"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={(evt) => {
                    props.props.handleSaveCustomerBuyerConsignee(
                      props.displayTableFor
                    )
                    handleCustomerBuyerConsigneeOperationChange("view")

                  }

                  }
                >
                  Save
                </Button>

                <Button
                  size="medium"
                  onClick={() => {
                    handleCustomerBuyerConsigneeOperationChange("view")
                    props.props.handleBuyerSearchFieldCancel(props.displayTableFor)
                    props.props.handleConsigneeSearchFieldCancel(props.displayTableFor)
                  }
                  }
                >
                  Cancel
                </Button>
              </div>
            )
          ) : props.displayTableFor === "consignee" && (
            props.saveCustomerConsigneeLoader ? (
              <CircularProgress disableShrink />
            ) : (
              <div className={classes.saveCancelButton}>
                <Button
                  size="medium"
                  disabled={!props.props.customerConsigneeId}
                  className={classes.saveButton}
                  variant="contained"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={(evt) => {
                    props.props.handleSaveCustomerBuyerConsignee(
                      props.displayTableFor
                    )
                    handleCustomerBuyerConsigneeOperationChange("view")
                  }
                  }
                >
                  Save
                </Button>

                <Button
                  size="medium"
                  onClick={() => {
                    handleCustomerBuyerConsigneeOperationChange("view")
                    props.props.handleBuyerSearchFieldCancel(props.displayTableFor)
                    props.props.handleConsigneeSearchFieldCancel(props.displayTableFor)
                  }
                  }
                >
                  Cancel
                </Button>
              </div>
            ))}
      </div>
    </>
  );
}
{/* (<Autocomplete
                          id="scroll"
                          options={filteredSelectionOption}
                          className={classes.formControl}
                          onChange={props.props.handleChangeCustomerBuyerConsignee(
                            props.displayTableFor
                          )}
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          loading={loading}
                          classes={{
                            input: classes.autoComplete,
                            option: classes.autoComplete
                          }}
                          getOptionLabel={(option) =>
                            props.displayTableFor === "buyer"
                              ? option.name
                              : (option.customer.gst_information !== null
                                ? option.customer.gst_information?.trade_name
                                  ? option.customer.gst_information?.trade_name
                                  : option.customer.gst_information.legal_name_of_business
                                    ? option.customer.gst_information.legal_name_of_business
                                    : (option.customer.first_name ? option.customer.first_name : "") +
                                    (option.customer.last_name ? option.customer.last_name : "")
                                : option.customer.pan_card_information !== null
                                  ? option.customer.pan_card_information.company_name
                                    ? option.customer.pan_card_information.company_name
                                    : (option.customer.first_name ? option.customer.first_name : "") +
                                    " " +
                                    (option.customer.last_name ? option.customer.last_name : "")
                                  : (option.customer.first_name ? option.customer.first_name : "") +
                                  " " +
                                  (option.customer.last_name ? option.customer.last_name : ""))
                              + ", " +
                              (
                                option.address ?
                                  (
                                    (option.address ? option.address + ", " : "")
                                    +
                                    (option.city ? option.city + ", " : "")
                                    +
                                    (option.state ? option.state + ", " : "")
                                    +
                                    (option.country ? option.country + ", " : "")
                                    +
                                    (option.pincode ? option.pincode : "")
                                  )
                                  :
                                  (
                                    (option.door_number ? option.door_number + ", " : "")
                                    +
                                    (option.floor_number ? 'Floor No - ' + option.floor_number + ", " : "")
                                    +
                                    (option.building_name ? option.building_name + ", " : "")
                                    +
                                    (option.street ? option.street + ", " : "")
                                    +
                                    (option.location ? option.location + ", " : "")
                                    +
                                    (option.district_name ? option.district_name + ", " : "")
                                    +
                                    ((option?.city_name && option?.district_name)
                                      ? ((option?.city_name).trim().toUpperCase() !== (option?.district_name).trim().toUpperCase())
                                        ? option?.city_name + ", "
                                        : ""
                                      : option?.city_name
                                        ? option?.city_name + ", "
                                        : "")
                                    +
                                    (option.state_name ? option.state_name + ", " : "")
                                    +
                                    (option.pincode ? option.pincode : "")
                                  )
                              )
                          }
                          size="small"
                          renderOption={(option) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                lineHeight: "1.2em",
                                fontFamily: 'Poppins',
                              }}
                            >
                              <span style={{ fontSize: "1em" }}>
                                {
                                  (option.customer.gst_information !== null
                                    ? option.customer.gst_information?.trade_name
                                      ? option.customer.gst_information?.trade_name
                                      : option.customer.gst_information.legal_name_of_business
                                        ? option.customer.gst_information.legal_name_of_business
                                        : (option.customer.first_name ? option.customer.first_name : "") +
                                        (option.customer.last_name ? option.customer.last_name : "")
                                    : option.customer.pan_card_information !== null
                                      ? option.customer.pan_card_information.company_name
                                        ? option.customer.pan_card_information.company_name
                                        : (option.customer.first_name ? option.customer.first_name : "") +
                                        " " +
                                        (option.customer.last_name ? option.customer.last_name : "")
                                      : (option.customer.first_name ? option.customer.first_name : "") +
                                      " " +
                                      (option.customer.last_name ? option.customer.last_name : ""))
                                }
                              </span>
                              <span style={{ color: grey, fontSize: "0.9em" }}>
                                {option.address ?
                                  (
                                    (option.address ? option.address + ", " : "")
                                    +
                                    (option.city ? option.city + ", " : "")
                                    +
                                    (option.state ? option.state + ", " : "")
                                    +
                                    (option.country ? option.country + ", " : "")
                                    +
                                    (option.pincode ? option.pincode : "")
                                  )
                                  :
                                  (
                                    (option.door_number ? option.door_number + ", " : "")
                                    +
                                    (option.floor_number ? 'Floor No - ' + option.floor_number + ", " : "")
                                    +
                                    (option.building_name ? option.building_name + ", " : "")
                                    +
                                    (option.street ? option.street + ", " : "")
                                    +
                                    (option.location ? option.location + ", " : "")
                                    +
                                    (option.district_name ? option.district_name + ", " : "")
                                    +
                                    ((option?.city_name && option?.district_name)
                                      ? ((option?.city_name).trim().toUpperCase() !== (option?.district_name).trim().toUpperCase())
                                        ? option?.city_name + ", "
                                        : ""
                                      : option?.city_name
                                        ? option?.city_name + ", "
                                        : "")
                                    +
                                    (option.state_name ? option.state_name + ", " : "")
                                    +
                                    (option.pincode ? option.pincode : "")
                                  )
                                }
                              </span>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              margin="nomal"
                              size="small"
                              placeholder="Add Consignee"
                            />
                          )}
                        />) */}