import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Typography,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
  TablePagination,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import {
  grey,
  secondaryGrey,
  whiteSmoke,
  successButtonBackground,
  drawerTextColor,
  secondaryBlueText,
} from "../../../../constants/internal/colors";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { hasUpdateAccess, pxToEm } from "../../../../methods";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ToolTipComponent from "../../common/ToolTipComponent";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    minWidth: 130,
  },
  generalDetailsDisplaySection: {
    display: "flex",
    marginTop: "20px",
    flexDirection: "column",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0px 20px 0px",
  },

  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
    margin: "20px 0px 20px 0px",
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  tableHead: {
    fontSize: pxToEm(13),
    fontWeight: "bold",
    color: grey,
  },
  tableBody: {
    fontSize: pxToEm(13),
  },
  addNewButton: {
    marginTop: 10,
    width: "181px",
    height: "43px",
    border: `1px dashed ${secondaryGrey}`,
    borderRadius: "4px",
    opacity: "1",
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  addNewAction: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
  },
  addNewDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  addNewLabel: {
    fontSize: pxToEm(12),
    color: secondaryGrey,
    marginRight: 10,
  },
  removeLabel: {
    fontSize: pxToEm(12),
    color: grey,
    cursor: "pointer",
  },
  alignText: {
    display: "flex",
    alignItems: "center",
  },
  removeIconDimensions: {
    height: 18,
    width: 18,
    margin: "0px 5px 0px 5px",
    color: grey,
    cursor: "pointer",
  },
  dataDisplay: { display: "flex", flexDirection: "column", width: "45%" },
  paperStyles: { padding: 20 },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
  },
});
export default function ManageCustomerSubscriptionDetails(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [states, setStates] = React.useState([]);
  const classes = useStyles();
  const [displayAddButton, setDisplayAddButton] = React.useState(true);
  const [items, setItems] = React.useState(props?.itemsWithStates || new Map());
  const handleAddButtonClick = () => {
    setDisplayAddButton(false);
  };
  const handleCancelButtonClick = () => {
    setDisplayAddButton(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setItems(props.itemsWithStates);
  }, [props.itemsWithStates]);

  React.useEffect(() => {
    const items = new Map(props.itemsWithStates);
    const subscriptions = props.customerSubscriptions;
    for (let item of subscriptions)
      if (items.has(item?.item_category?.category_name))
        items.delete(item.item_category.category_name);
    setItems(items);
    setDisplayAddButton(true);
  }, [props.customerSubscriptions, props.itemsWithStates]);
  return (
    <div className={classes.generalDetailsDisplaySection}>
      <Typography className={classes.titleLabelStyle}>
        ITEM SUBSCRIPTION
      </Typography>
      <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell align="left" className={classes.tableHead}>
                  ITEM CATEGORY
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  STATE
                </TableCell>
                <TableCell align="left" className={classes.tableHead}>
                  RATE TYPE
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableHead}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.customerSubscriptions &&
                props.customerSubscriptions.length > 0
                ? props.customerSubscriptions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <>
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          className={classes.tableBody}
                        >
                          {row?.item_category?.category_name || ""}
                        </TableCell>
                        <TableCell align="left" className={classes.tableBody}>
                          {row?.state?.state_name || ""}
                        </TableCell>
                        <TableCell align="left" className={classes.tableBody}>
                          {row?.rate_type === "HR" ? "Open Rate" : "Net Rate"}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.tableBody}
                        >
                          <ToolTipComponent
                            title="You have no edit permission on this widget"
                            condition={!hasUpdateAccess(props.user, "customerManagement")}
                            title2={"Click to remove"}
                            condition2={hasUpdateAccess(props.user, "customerManagement")}
                          >
                            <IconButton
                              className={classes.removeIconDimensions}
                              onClick={(evt) =>
                                props.handleRemoveCustomerSubscription(row.id)
                              }
                              disabled={
                                hasUpdateAccess(props.user, "customerManagement")
                                  ? false
                                  : true
                              }
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </ToolTipComponent>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {props.customerSubscriptions &&
          props.customerSubscriptions.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={props.customerSubscriptions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </Paper>
      {displayAddButton ? (
        items.size > 0 ? (
          <div className={classes.addAndSaveButton}>
            <ToolTipComponent
              placement='top-start'
              title="You have no edit permission on this widget"
              condition={!hasUpdateAccess(props.user, "customerManagement")}
            >
              <Button
                variant="outlined"
                size="medium"
                startIcon={<AddOutlinedIcon />}
                className={classes.addNewButton}
                onClick={handleAddButtonClick}
                disabled={
                  hasUpdateAccess(props.user, "customerManagement")
                    ? false
                    : true
                }
              >
                ADD NEW
              </Button>
            </ToolTipComponent>
          </div>
        ) : null
      ) : (
        <div className={classes.addNewAction}>
          <div className={classes.addNewDetails}>
            <div className={classes.alignText}>
              <Typography className={classes.addNewLabel}>CATEGORY</Typography>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerSelectedSubscriptionCategory}
                  onChange={(evt) => {
                    props.handleCustomerSubscriptionAddNewChange(
                      evt,
                      "customerSelectedSubscriptionCategory"
                    );
                    setStates(items.get(evt.target.value)?.states || []);
                  }}
                >
                  {items &&
                    Array.from(items.keys()).map((category) => (
                      <MenuItem value={category}>{category}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.alignText}>
              <Typography className={classes.addNewLabel}>STATE</Typography>

              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerSelectedSubscriptionState}
                  onChange={(evt) =>
                    props.handleCustomerSubscriptionAddNewChange(
                      evt,
                      "customerSelectedSubscriptionState"
                    )
                  }
                  disabled={props.customerSelectedSubscriptionCategory === null}
                >
                  {states.length > 0 ? (
                    states.map((state) => (
                      <MenuItem value={state.id}>
                        {console.log(state)}
                        {state.name
                          ? state.name
                          : "No state found for the selected Item Category"}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      No state found for <br></br> the selected Item Category
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText>
                  {props.customerSelectedSubscriptionCategory === null
                    ? "Please Select Item Category first"
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>
            <div className={classes.alignText}>
              <Typography className={classes.addNewLabel}>RATE</Typography>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Select
                  value={props.customerSelectedSubscriptionRateType}
                  onChange={(evt) =>
                    props.handleCustomerSubscriptionAddNewChange(
                      evt,
                      "customerSelectedSubscriptionRateType"
                    )
                  }
                >
                  <MenuItem value={"HR"}>Open Rate</MenuItem>
                  <MenuItem value={"LR"}>Net Rate</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <Typography
                className={classes.removeLabel}
                onClick={(evt) => {
                  evt.preventDefault();
                  props.handleRemoveCustomerSelectedSubscrptionData();
                }}
              >
                REMOVE
              </Typography>
            </div>
          </div>
          <div>
            {props.saveCustomerSubscriptionLoader ? (
              <CircularProgress disableShrink />
            ) : (
              <div>
                <Button
                  disabled={
                    !props.customerSelectedSubscriptionCategory ||
                    !props.customerSelectedSubscriptionState ||
                    !props.customerSelectedSubscriptionRateType
                  }
                  size="medium"
                  className={classes.saveButton}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleSaveCustomerSubscriptions();
                  }}
                >
                  Save
                </Button>

                <Button
                  size="medium"
                  className={classes.margin}
                  onClick={handleCancelButtonClick}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
