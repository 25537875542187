import React, { Component } from "react";
import Layout from "../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Avatar, Button, Paper, Grow, } from "@material-ui/core";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import InvoiceWayBillEinvoiceViewDownloadCard from "./InvoiceWayBillEinvoiceViewDownloadCard";
import NewInvoiceWayBillEinvoiceViewDownloadCard from "./NewInvoiceWayBillEinvoiceViewDownloadCard";
import Divider from '@mui/material/Divider';
import moment from "moment";
import Bread from '../common/Breadcrubs';
import CircularProgress from "@material-ui/core/CircularProgress";


const InvoiceDownloadStyles = (theme) => ({
  paperStyle: {
    boxShadow:
      "0px 0px 0px 0px rgba(0,0,0,0.09), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
    width: "99%",
    // paddingBottom: "20px",
    marginTop: "10px",
    paddingTop: "10px"
    // height: "100%",
    // marginTop: "5px",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "30px",
    width: "100%",
    marginBottom: "5px",
  },
  headerDate: {
    color: "#0000008A",
    marginLeft: "10px",
    fontSize: "18px"
  },
  avatar: {
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    // backgroundColor: "red",
    height: "180px",
    width: "180px",
    opacity: 0.5,
    // marginTop: "10px",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    // placeContent: "center",
    placeItems: "center",
    width: "100%",
    height: "100%",
    // backgroundColor: "green"
  },
  goBackButton: {
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    width: "51%",
    paddingTop: "20px",
    justifyContent: "space-evenly",
  },
  paddingTop: {
    // marginTop: "10px",
    // marginBottom: "20px",
    // minWidth: 275,
    // maxWidth: 400,
    width: "350px",
    // height: "300px",
  },
  eInvoiceAndEwayBillDetailsDisplay: {
    display: "inline-block",
    boxSizing: "border-box",
    // height: "100%"
    // alignItems: "center",
  },
  cardStyles: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    // width: "100%",
  },
});
class InvoiceDownload extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.myInput = React.createRef()
  }
  componentDidMount() {
    // if (!this.props.invoiceType) {
    //   return this.props.history.push("/dispatchOperation");
    // }
    const lano = this.props.location.pathname.split("/")
    // console.log("called", this.props)
    // console.log(lano[-1])
    if (this.props.laNumber === "") {
      this.props.history.push("/dispatchOperation")
    } else {
      this.props.handleFetchLoadingAdviceInDispatchCounter(lano[lano.length - 1], "download_screen")
    }
    if (this.props.invoiceDownloadLoader) {
      // console.log("called in componentDidMount")
    }
  }
  componentDidUpdate() {
    console.log(this.props)
    if (((this.props.eway && this.props.e_invoice)
      || (this.props.eway && this.props.normal_invoice)
      || (!this.props.iseWaySelect && this.props.normal_invoice)
      || (!this.props.iseWaySelect && this.props.e_invoice))
      && (!this.props.isApiCalled)) {
      console.log(this.props)
      this.props.handleSendPdfPayload()
    }
  }
  render() {
    const { classes, laDetailsInDispatchCounter } = this.props;
    // console.log(Moment(this.props.laDetailsInDispatchCounter.created_at_date_and_time).format("DD-MMM-YYYY LT"));
    return (
      <div className={classes.mainDiv} id="comingSoonDiv" ref={this.myInput}>
        <div className={classes.navigationBar}>
          <Bread
            data={[
              {
                title: "Dispatch Operation",
                onClick: () => this.props.history.push(INTERNALROUTES.DISPATCH_OPERATION),
              },
              {
                title: `${this.props?.laNumber || ""}`,
                onClick: "",
              },
            ]}
          />
          <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />

          <div className={classes.headerDate}>
            <span>{moment(this.props?.laDetailsInDispatchCounter?.gate_in_date).format("DD-MMM-YYYY")}</span>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Avatar className={classes.avatar} src={"/check-circle.gif"} />
        </div>
        <Typography
          style={{ opacity: 0.4, fontSize: "1.5rem", paddingTop: "1em" }}
          variant={"h4"}
        >
          Document for{" "}
          {this.props?.laDetailsInDispatchCounter?.erp_invoice_number}{" "}
          generated successfully.
        </Typography>
        {/* {console.log(this?.props)} */}
        {this.props?.invoiceDownloadLoader ?
          <Paper elevation={1} className={classes.paperStyle}>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "220px", alignItems: "center" }}>
              <CircularProgress size={50} />
            </div>
          </Paper>
          :
          <Paper elevation={1} className={classes.paperStyle}>
            <Grow
              in={true}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 3000 }}
            >
              <div className={classes.eInvoiceAndEwayBillDetailsDisplay}>
                <div className={classes.cardStyles}>
                  {this?.props?.invoiceType !== "Normal Invoice" ? (
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={
                          this.props?.laDetailsInDispatchCounter?.item_category
                            ?.is_allow_rate_zero &&
                            Number(
                              this.props?.laDetailsInDispatchCounter?.order?.rate
                            ) === 0
                            ? "Delivery Challan"
                            : "E-Invoice"
                        }
                        {...this.props}
                      />
                    </div>
                  ) : (
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={
                          this.props?.laDetailsInDispatchCounter?.item_category
                            ?.is_allow_rate_zero &&
                            Number(
                              this.props?.laDetailsInDispatchCounter?.order?.rate
                            ) === 0
                            ? "Delivery Challan"
                            : "Invoice"
                        }
                        {...this.props}
                      />
                    </div>
                  )}

                  {!this?.props?.iseWaySelect || this?.props?.iseWaySelect === "No" ? (
                    ""
                  ) : (
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={"E-Way Bill"}
                        {...this.props}
                      />
                    </div>
                  )}
                  {
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={"Exit Pass"}
                        {...this.props}
                      />
                    </div>
                  }
                  {
                    <div className={classes.paddingTop}>
                      <NewInvoiceWayBillEinvoiceViewDownloadCard
                        documentFor={"Email"}
                        {...this.props}
                      />
                    </div>
                  }
                </div>
              </div>
            </Grow>
          </Paper>
        }
        {this.props?.invoiceDownloadLoader &&
          <div className={classes.goBackButton}>
            <Button
              onClick={(evt) => {
                evt.preventDefault();
                this.props.history.push("/dispatchOperation");
                this.props.handleClearLoadingAdvice();
              }}
            >
              Go Back
            </Button>
          </div>
        }
      </div>
    );
  }
}

/*render() {
    const { classes } = this.props;
    return (
      <div>
         <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          className={classes.breadcrumbsStyle}
        >
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/dispatchOperation");
            }}
          >
            Dispatch Operations
          </Link>

          <Link color="inherit">Invoicing Download </Link>
        </Breadcrumbs> 
      </div>
    );
  }
}*/

export default withRouter(withStyles(InvoiceDownloadStyles)(InvoiceDownload));
