import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CreateLoadingAdviceContainer from "../../../../../containers/internal/contracts/LoadingAdvices";
import LoadingAdviceListDisplayComponent from "./LoadingAdviceListDisplayComponent";
import NoDataFound from "../../../../common/NoDataFound";
import SpinLoader from "../../../../common/SpinLoader";
import LoadingAdviceTable from "../../../common/LoadingAdviceTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 45px)",
  },
  dialogStyles: {
    maxWidth: "calc(100% - 200px)",
    padding: 15,
    borderRadius: 10,
  },
  loaderDiv: {
    display: "flex",
    width: "100%",
    height: "calc(100vh - 200px)",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function LoadingAdvicesTab(props) {
  const classes = useStyles();
  const { itemCategoryCode } = props;
  const [openCreateLoadingAdvices, setOpenCreateLoadingAdvices] =
    React.useState(props?.openCreateLoadingAdvices);

  const [loadingAdviceLoader, setLoadingAdvicesLoader] = React.useState(
    props?.orderLoadingAdvicesLoader
  );
  const [loadingAdviceData, setLoadingAdviceData] = React.useState(
    props?.orderLoadingAdvices
  );

  React.useEffect(() => {
    const orderLoadingAdvices = Object.keys(props?.orderLoadingAdvices);
    orderLoadingAdvices.sort(function (a, b) {
      var c =
        a?.modified_at != null
          ? new Date(a?.modified_at)
          : new Date(a?.created_at);
      var d =
        b?.modified_at != null
          ? new Date(b?.modified_at)
          : new Date(b?.created_at);
      return d - c;
    });
    setLoadingAdviceData(orderLoadingAdvices);
    setLoadingAdvicesLoader(props?.orderLoadingAdvicesLoader);
  }, [props?.orderLoadingAdvices, props?.orderLoadingAdvicesLoader]);

  return (
    <div className={classes.root}>
      {loadingAdviceLoader ? (
        <div className={classes.loaderDiv}>
          <SpinLoader />
        </div>
      ) : loadingAdviceData.length > 0 ? (
        <div style={{ display: "flex" }}>
          <LoadingAdviceTable
            {...props}
            laStatus={loadingAdviceData}
            laNumber={loadingAdviceData}
            orderLoadingAdvices={props.orderLoadingAdvices}
            orderId={props?.selectedOrderId}
            handleLaApprove={props.handleLaApprove}
            handleCancelLa={props.handleCancelLa}
            handleSnackbarClose={props.handleSnackbarClose}
            Loader={props.orderLoadingAdvicesLoader}
            isContract={true}
            module="contract"
            user={props.user}
            fetchTcsRate={props?.fetchTcsRate}
            itemCategoryCode={itemCategoryCode}
            handleBill={props?.handleBill}
          />
        </div>)
        : (
          <div className={classes.loaderDiv}>
            <NoDataFound />
          </div>
        )}
    </div>
  );
}
