import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import {
    bottomNavigationFontColor,
    lightPrimaryColor,
    primaryColor,
    secondaryColor,
    // bottomNavigationFontColor,
    dailyRateScreenTextColor,
} from "../../../styles/colors";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import OrderCard from "./OrderCard";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import SaveButton from "../../internal/common/SaveButton";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import InputLabel from "@material-ui/core/InputLabel";
// import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";
import backStack from "cordova-back-stack";
import { pxToEm } from "../../../methods";
import { CircularProgress, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select } from "@material-ui/core";
import { grey } from "../../../constants/internal/colors";

const styles = {
    orderShowTextMessageContainer: {
        width: "100%",
        // display: "flex",
        // justifyContent: "center",
        paddingTop: "10px",
    },
    orderShowTextMessage: {
        // width: "89%",
        // display: "flex",
        // flexDirection: "column",
        fontSize: "1.2em",
        // lineHeight: "1.7em",
        color: bottomNavigationFontColor,
    },
    bottomContainer: {},
    bottomTermContainer: {
        height: "7vh",
        width: "85%",
    },
    bottomButtonContainer: {
        width: "85%",
        height: "10vh",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        // paddingLeft: "5px",
        bottom: 0,
        position: "fixed"
    },
    confirmButtonStyle: {
        height: "50%",
        width: "55%",
        textTransform: "capitalize",
        backgroundColor: primaryColor,
        color: "white",
        padding: '12px 4px',
        fontSize: window.innerWidth < 310 ? pxToEm(11.5) : '0.75rem'
    },
    discardButtonStyle: {
        marginLeft: "20px",
        textTransform: "capitalize",
        color: primaryColor,
        width: "45%",
        padding: '8px 4px',
        fontSize: window.innerWidth < 310 ? pxToEm(11.5) : '0.75rem'
    },
    orderCardContainer: {
        paddingLeft: "15px",
    },
    dialogTop: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px 26px",
        height: "5vh",
        alignItems: "center",
    },
    dialogContentContainer: {
        padding: "0px 10px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "1.2em",
        height: "10vh",
    },
    dialogButtonContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        height: "8vh",
    },
    dialogButtonShow: {
        width: "70%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },
    yesButtonStyle: {
        width: "78px",
        backgroundColor: primaryColor,
        padding: "8px 24px",
    },
    goBackButton: {
        color: grey,
        textTransform: "capitalize",
    },
};
class Single extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialog: false,
            terms_advance: false,
            terms_material: false,
            terms_purchase: false,
            terms_late_payment: false,
            all_accepted: false,
            isDisabled: true,
            cancel_reason: '',
            MenuProps: {
                PaperProps: {
                    style: {
                        maxHeight: 45 * 4 + 10,
                    },
                },
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            },
        };
    }

    UNSAFE_componentWillMount() {
        backStack((e) => {
            e.preventDefault();
            // console.log(String(localStorage.getItem("prevUrl")))
            this.props.history.push(String(localStorage.getItem("returnUrl")));
        });
    }

    loadOldUrl = () => {
        this.props.history.push(String(localStorage.getItem("returnUrl")))
    }

    componentDidMount() {
        // if (!this.props.location.state)
        //     this.props.history.replace("/home")
        if (window.location.pathname !== String(localStorage.getItem("currentUrl"))) {
            let returnUrl = localStorage.getItem("currentUrl")
            localStorage.setItem("returnUrl", returnUrl);
            let currentUrl = window.location.pathname;
            localStorage.setItem("currentUrl", currentUrl);
        }
        document.addEventListener("backbutton", this.loadOldUrl);
    }

    handleDisabledConfirmButton = () => {
        if (this.state.all_accepted
            // this.state.terms_material &&
            // this.state.terms_purchase &&
            // this.state.terms_late_payment
        ) {
            return false

        } else {
            return true

        }
    };

    handleSelectTermsAdvance = (key) => {
        this.setState({ [key]: !this.state[key] }, () => {
            if (this.state[key]) {
                window.localStorage.setItem(key, true);
            } else {
                window.localStorage.removeItem(key);
            }
        });

    };

    render() {
        const { classes, isMobile, location, approveContractLoader } = this.props;
        const {
            terms_material,
            terms_purchase,
            terms_late_payment,
            isDisabled,
            all_accepted,
        } = this.state
        // console.log(this.props.location.state)
        // console.log(this.handleDisabledConfirmButton())
        return (this.props.location.state ?
            <div>
                {!this.props.isMobile ? (
                    <div style={{ padding: "10px 0px 10px 4%" }}>
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push("/home");
                            }}
                        >
                            <KeyboardBackspace />
                        </IconButton>
                    </div>
                ) : null}
                <div className={classes.orderCardContainer}>
                    <OrderCard title="Action needed" details={this.props.location.state}
                        isMobile={isMobile}
                    />
                </div>
                <div className={classes.orderShowTextMessageContainer}>
                    <div className={classes.orderShowTextMessage} style={{
                        padding: !isMobile ? "0px 6% 10px 6%" : "0px 5% 10px 6%",
                        fontSize: window.innerWidth < 310 ? pxToEm(14) : window.innerWidth < 375 && pxToEm(15)
                        // backgroundColor: "green"
                    }}>
                        <span>The contract is pending approval by you.
                            Please select the option below to
                            confirm the contract</span>
                    </div>
                </div>
                <div style={{
                    width: "100%", marginTop: "8px", marginBottom: "20px", display: "flex",
                    flexDirection: "column",
                    padding: !isMobile ? "10px 0px 10px 6%" : "0px 10px",
                    // backgroundColor: "green"

                }}>



                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                style={{
                                    // margin: !isMobile && "10px 0px",
                                    marginTop: isMobile && "10px",
                                    marginBottom: isMobile && "10px",
                                    paddingLeft: isMobile && "25px",
                                    // paddingRight: "22px",
                                    // backgroundColor: "green",
                                    display: isMobile && "table",
                                    // marginTop: "10px"
                                }}
                                control={
                                    <div style={{
                                        display: isMobile && "table-cell",

                                        // backgroundColor: "green"
                                    }}>
                                        {/* <Checkbox
                                            checked={terms_material}
                                            style={{
                                                color: secondaryColor,
                                                padding: "4px",
                                                marginRight: isMobile && "10px",
                                            }}
                                        /> */}
                                    </div>
                                }
                                label={
                                    <div style={{ display: 'flex' }}>
                                        <span>
                                            &#8226;
                                        </span>
                                        <span style={{
                                            fontSize: window.innerWidth < 310 ? pxToEm(11.5) : window.innerWidth < 375 ? pxToEm(12.5) : "0.9em",
                                            marginLeft: !isMobile ? 5 : 10
                                            // paddingTop: "5px"
                                            // marginTop: "5px"
                                        }}>
                                            This will be considered as Contract/Sauda Patrak per Steel Association.
                                        </span>
                                    </div>


                                }
                            // onChange={(e) => {
                            //     this.handleSelectTermsAdvance("terms_material");
                            // }}
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                style={{
                                    // margin: !isMobile && "10px 0px",
                                    marginTop: isMobile && "10px",
                                    marginBottom: isMobile && "10px",
                                    paddingLeft: isMobile && "25px",
                                    // paddingRight: "22px",
                                    display: isMobile && "table",
                                }}
                                control={
                                    <div style={{ display: isMobile && "table-cell" }}>
                                        {/* <Checkbox
                                            checked={terms_purchase}
                                            style={{
                                                color: secondaryColor,
                                                padding: "4px",
                                                marginRight: isMobile && "10px",
                                            }}
                                        /> */}
                                    </div>
                                }
                                label={
                                    <div style={{ display: 'flex' }}>
                                        <span>
                                            &#8226;
                                        </span>
                                        <span style={{
                                            fontSize: window.innerWidth < 310 ? pxToEm(11.5) : window.innerWidth < 375 ? pxToEm(12.5) : "0.9em",
                                            marginLeft: !isMobile ? 5 : 10
                                        }}>
                                            Materials should be lifted within 15 days from the contract date.
                                        </span>
                                    </div>
                                }
                            // onChange={(e) => {
                            //     this.handleSelectTermsAdvance("terms_purchase");
                            // }}
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                style={{
                                    // margin: !isMobile && "10px 0px",
                                    marginTop: isMobile && "10px",
                                    marginBottom: isMobile && "10px",
                                    paddingLeft: isMobile && "25px",
                                    // paddingRight: "22px",
                                    marginBottom: "9vh",
                                    display: isMobile && "table",
                                }}
                                control={
                                    <div style={{ display: isMobile && "table-cell" }}>
                                        {/* <Checkbox
                                            checked={terms_late_payment}
                                            style={{
                                                color: secondaryColor,
                                                padding: "4px",
                                                marginRight: isMobile && "10px",
                                            }}
                                        /> */}
                                    </div>
                                }
                                label={
                                    <div style={{ display: 'flex' }}>
                                        <span>
                                            &#8226;
                                        </span>
                                        <span style={{
                                            fontSize: window.innerWidth < 310 ? pxToEm(11.5) : window.innerWidth < 375 ? pxToEm(12.5) : "0.9em",
                                            marginLeft: !isMobile ? 5 : 10
                                        }}>
                                            Payment to be strictly on scheduled time, otherwise interest will be charged @18% P.A.
                                        </span>
                                    </div>
                                }
                            // onChange={(e) => {
                            //     this.handleSelectTermsAdvance("terms_late_payment");
                            // }}
                            />
                        </FormGroup>
                    </FormControl >
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                style={{
                                    // margin: !isMobile && "10px 0px",
                                    marginTop: isMobile && "10px",
                                    marginBottom: isMobile && "10px",
                                    paddingLeft: isMobile && "25px",
                                    // paddingRight: "22px",
                                    marginBottom: "9vh",
                                    display: isMobile && "table",
                                }}
                                control={
                                    <div style={{ display: isMobile && "table-cell" }}>
                                        <Checkbox
                                            checked={all_accepted}
                                            style={{
                                                color: secondaryColor,
                                                padding: "4px",
                                                marginRight: isMobile && "10px",
                                            }}
                                        />
                                    </div>
                                }
                                label={
                                    <span style={{
                                        fontSize: window.innerWidth < 310 ? pxToEm(11.5) : window.innerWidth < 375 ? pxToEm(12.5) : "0.9em",
                                        marginLeft: !isMobile && "5px"
                                    }}>
                                        I agree to the terms & conditions
                                    </span>
                                }
                                onChange={(e) => {
                                    this.handleSelectTermsAdvance("all_accepted");
                                }}
                            />
                        </FormGroup>
                    </FormControl >
                </div >
                <div
                    style={isMobile ? {
                        display: "flex",
                        position: "fixed",
                        bottom: "0px",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "95%",
                        height: "7vh",
                        paddingLeft: "12%",
                        // width: isMobile ? "85%" : "30%",
                        // height: "10vh",
                        // display: "flex",
                        // justifyContent: "space-around",
                        // alignItems: isMobile ? "center" : "flex-start",
                        // float: isMobile ? "" : "left",
                        // paddingLeft: "4%",
                        // bottom: 0,
                        // position: "fixed"
                    } :
                        {
                            display: "flex",
                            width: "50%",
                            height: "60px",
                            position: "fixed",
                            bottom: 0,
                            alignItems: "center",
                            paddingLeft: "5%"

                        }
                    }
                >
                    {/* <div className={classes.bottomTermContainer}>
                        <Checkbox checked={true} style={{ color: lightPrimaryColor }} />
                        <span>
                            I accept the
                            <span
                                style={{
                                    color: lightPrimaryColor,
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push("/orders/terms-and-conditions");
                                }}
                            >
                                Term & Conditions
                            </span>
                        </span>
                    </div> */}
                    {/* <div className={classes.bottomButtonContainer}> */}
                    <SaveButton
                        disabled={this.handleDisabledConfirmButton()}
                        variant="contained"
                        color="primary"
                        className={classes.confirmButtonStyle}
                        // style={{
                        //     height: "50%",
                        //     width: "50%",
                        //     backgroundColor: primaryColor,
                        //     color: "white",


                        // }}
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.handleOrderApproveClick(this.props.location.state, "approve")
                            // this.props.history.push({ pathname: `/orders/active/${this.props.location.state.order_number}`, state: this.props.location.state });
                            // this.props.history.push(
                            //   `/orders/orderDetails/${this.props.match.params.orderId}`
                            // );
                        }}
                        size="small"
                        isLoading={approveContractLoader}
                    >
                        Approve Contract
                    </SaveButton>
                    <Button
                        className={classes.discardButtonStyle}
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({ openDialog: true },
                                () => this.props?.fetchShortCloseReasonMaster('Contracts')
                            );
                        }}
                        disabled={approveContractLoader}
                    >
                        Cancel Contract
                    </Button>
                    {/* </div> */}
                </div>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{
                        borderRadius: 16,
                        padding: 8,
                    }}>
                        <div style={{

                            border: "1px solid gainsboro",
                            borderRadius: "15px",
                            padding: "5px",
                        }}>
                            <DialogTitle id="alert-dialog-title" style={{ padding: 8 }}>
                                Cancel
                            </DialogTitle>
                            <DialogContent style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'column',
                                minHeight: 70,
                                alignContent: 'center',
                                padding: 16
                            }}>
                                <p>
                                    Please select reason for Contract cancel
                                </p>
                                <div>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                    >
                                        <InputLabel id="cancelReason">
                                            Select Cancel Reason*
                                        </InputLabel>
                                        <Select
                                            id="Cancel Reason"
                                            labelId="cancelReason"
                                            variant="outlined"
                                            label="Select Cancel Reason*"
                                            name="cancel_reason"
                                            // style={{ width: 340 }}
                                            MenuProps={this.state.MenuProps}
                                            value={this.state.cancel_reason}
                                            onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                        >
                                            {this.props?.reasonMaster !== undefined && this.props?.reasonMaster.length > 0
                                                ? this.props?.reasonMaster.map((data) => (
                                                    <MenuItem key={data.reason} value={data.id}>
                                                        {data.reason}
                                                    </MenuItem>
                                                ))
                                                :
                                                <CircularProgress
                                                    disableShrink
                                                    className={classes.loaderStyle}
                                                    style={{ marginLeft: 10 }}
                                                />
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </DialogContent>
                            <DialogActions
                                style={{
                                    padding: '8px'
                                }}>
                                <Button className={classes.goBackButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            cancel_reason: '',
                                            openDialog: false,
                                        })
                                    }}
                                >Go Back</Button>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            openDialog: false,
                                            terms_late_payment: false,
                                            terms_material: false,
                                            terms_purchase: false,
                                            terms_advance: false,
                                            all_accepted: false
                                        });
                                        this.props.handleOrderApproveClick(this.props.location.state, "discard", this.state.cancel_reason)
                                        // this.props.history.push("/home");
                                    }}
                                    style={{ backgroundColor: !this.state.cancel_reason ? 'rgba(0,0,0,0.26)' : primaryColor, color: '#FFF' }}
                                    variant="contained"
                                    // color="primary"
                                    autoFocus
                                    disabled={!this.state.cancel_reason ? true : false}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </div>
                    </div>
                </Dialog>
                {/* <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="simple-dialog-title"
                    PaperProps={{
                        style: {
                            width: isMobile ? "78%" : "25%",
                            height: "26vh",
                            overflowY: "inherit",
                            borderRadius: "5%",
                        },
                    }}
                >
                    <div>
                        <div className={classes.dialogTop}>
                            <Close
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ openDialog: false });
                                }}
                            />
                        </div>
                        <div className={classes.dialogContentContainer}>
                            <span>Are you sure want to cancel this contract ?</span>
                        </div>
                        <div className={classes.dialogButtonContainer}>
                            <div className={classes.dialogButtonShow}>
                                <Button className={classes.goBackButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            openDialog: false,
                                        })
                                    }}
                                >Go Back</Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.yesButtonStyle}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            openDialog: false,
                                            terms_late_payment: false,
                                            terms_material: false,
                                            terms_purchase: false,
                                            terms_advance: false,
                                            all_accepted: false
                                        });
                                        this.props.handleOrderApproveClick(this.props.location.state, "discard")
                                        // this.props.history.push("/home");
                                    }}
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog> */}
            </div>
            : <div>CONTRACT NOT FOUND</div>

            /* <span
           style={{
             paddingLeft: "25px",
             color: lightGreyColor,
             fontWeight: "bold",
           }}
         >
           Accept Terms And Conditions
         </span>
         <FormControl component="fieldset">
           <FormGroup>
             <FormControlLabel
               style={{
                 margin: "10px 0px",
                 paddingLeft: "25px",
                 paddingRight: "22px",
               }}
               control={
                 <Checkbox
                   checked={terms_advance}
                   style={{
                     color: secondaryColor,
                     padding: "4px",
                     marginRight: "10px",
                   }}
                 />
               }
               label={
                 <span style={{ fontSize: "0.9em" }}>Advance or Next day</span>
               }
               onChange={(e) => {
                 this.handleSelectTermsAdvance("terms_advance");
               }}
             />
           </FormGroup>
         </FormControl> */
        );
    }
}
export default withRouter(withStyles(styles)(Single));
