import React, { Component } from "react";
import moment from "moment";
import {
    grey,
    darkGrey,
    successButtonBackground,
} from "../../../constants/internal/colors";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import SyncIcon from '@mui/icons-material/Sync';
import { makeStyles } from "@material-ui/core/styles";
import { checkInvalidValue, pxToEm } from "../../../methods";
import MousehoverPopover from "../common/MouseOverPopover";
import PopOverComponent from "../common/PopoverHover";
import {
    Divider,
    Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const styles = {
    einvoiceAndEwayCardContainer: {
        marginTop: "50px",
        display: "flex",
        gap: "50px",
        width: "90%",
        justifyContent: "space-between",
        alignContent: "space-evenly",
    },
    invoicetypeTextContainer: {
        fontSize: "1.4em",
        fontWeight: "bold",
        padding: "10px 20px",
    },
    errorOrSuccessMessageContainer: {
        height: "9vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8em",
        color: successButtonBackground,
    },
    errorMessageContainer: {
        height: "7vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8em",
        color: "red",
    },
    dialogText: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: pxToEm(14),
        fontWeight: '500',
    },
    blue: {
        color: "#3B69B0"
    },
    popUpValueRightAlign: {
        textAlign: 'right'
    },
};

class EinvoiceAndEwayCard extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleDateTimeShow = (data) => {
        return data !== null ? (
            moment(data).format("DD-MMM-YYYY") + "|" + moment(data).format("hh:mm A")
        )
            :
            "-"
    }

    PaymentHoverData = {
        payment_doc_number: this.props.laDetailsInDispatchCounter?.sub_contract_detail?.payment_doc_number,
        payment_doc_date: this.props.laDetailsInDispatchCounter?.sub_contract_detail?.payment_doc_date,
        payment_bank: this.props.laDetailsInDispatchCounter?.sub_contract_detail?.payment_bank,
    }

    HoverPaymentDetails = (props) => {
        const { classes } = this.props;
        let data = props
        return (
            <div style={{ width: 400, padding: "5%" }}>
                <Typography className={classes.dialogText}>
                    <div className={classes.blue}>
                        Payment Doc Number
                    </div>
                    <div className={classes.popUpValueRightAlign}>
                        {data?.payment_doc_number}
                    </div>
                </Typography>
                <Divider variant='middle' style={{ margin: '2% 0' }} />
                <Typography className={classes.dialogText}>
                    <div className={classes.blue}>
                        Payment Doc Date
                    </div>
                    <div className={classes.popUpValueRightAlign}>
                        {![null, ""].includes(data?.payment_doc_date) ? moment(data?.payment_doc_date).format("DD-MMM-YY") : " "}
                    </div>
                </Typography>
                <Divider variant='middle' style={{ margin: '2% 0' }} />
                <Typography className={classes.dialogText}>
                    <div className={classes.blue}>
                        Payment Bank Name
                    </div>
                    <div className={classes.popUpValueRightAlign}>
                        {data?.payment_bank}
                    </div>
                </Typography>
            </div>
        )
    }


    render() {
        const { classes, eWayBill, invoiceMethod } = this.props;

        return (
            <>
                <div className={classes.einvoiceAndEwayCardContainer}>
                    <Paper style={{ width: "50%", padding: "20px 0px 0px" }}>
                        <div className={classes.invoicetypeTextContainer}>
                            <span>Invoice Details</span>
                        </div>
                        <Divider />
                        <div id="test">
                            <FormControl
                                component="fieldset"
                                style={{ width: "100%", padding: "10px 14px" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        // width: "72%",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0px 4px",
                                        fontSize: "1.0em",
                                        // color: COLORS.grey,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            color: "#839192",
                                            fontWeight: "bold",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Date
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Plant
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            LA Number
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Supply Type
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Stock
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Invoice Number
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            alignItems: "end",
                                            color: grey
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "16px",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            {moment(
                                                this.props.laDetailsInDispatchCounter?.gate_in_date
                                            ).format("DD-MMM-YYYY")}
                                        </div>
                                        <div style={{ height: "16px", marginBottom: "5px", whiteSpace: "nowrap" }}>
                                            {this.props.laDetailsInDispatchCounter?.plant_name}
                                        </div>
                                        <div style={{ height: "16px", marginBottom: "5px" }}>
                                            {this.props.laDetailsInDispatchCounter?.la_number}
                                        </div>
                                        <div style={{ height: "16px", marginBottom: "5px" }}>
                                            {this.props.laDetailsInDispatchCounter?.tran_supply_type}
                                        </div>
                                        <div style={this.props.availableStock === null || this.props.availableStock < this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight ? { height: "16px", marginBottom: "5px", color: "red" } : { height: "16px", marginBottom: "5px", color: successButtonBackground }}>
                                            {!checkInvalidValue(this.props.availableStock) ? `${this.props.availableStock} MTS` : this.props.availableStock}
                                            {/* {this.props.availableStock === null ? this.props.stockError : `${this.props.availableStock} MTS`} */}
                                        </div>
                                        <div style={{ height: "16px", marginBottom: "5px" }}>
                                            {this.props.laDetailsInDispatchCounter
                                                ?.erp_invoice_number != null
                                                ? this.props.laDetailsInDispatchCounter
                                                    ?.erp_invoice_number
                                                : !(this.props.availableStock >= this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight)
                                                    || !(this.props.laDetailsInDispatchCounter?.payment_method?.days > 0 ?
                                                        this.props.stockAndPayment?.payment?.non_advance_credit_limit >= 0 :
                                                        this.props.stockAndPayment?.payment?.advance_credit_limit >= 0
                                                        //  -
                                                        // this.props.laDetailsInDispatchCounter?.total_values?.total_item_value >= 0
                                                    )
                                                    ? "Cannot Obtain Invoice Number"
                                                    : "Click Obtain Invoice Number"}
                                        </div>
                                    </div>
                                </div>
                            </FormControl>
                        </div>

                        <>
                            <Divider />

                            {this.props.laDetailsInDispatchCounter?.erp_invoice_number ? <div className={classes.errorMessageContainer}>
                                <span style={{ color: grey }}>Stock Verified</span>
                            </div>
                                : this.props.stockAndPayment?.stock?.error ? <div className={classes.errorMessageContainer}>
                                    <span style={{ color: "red" }}>{this.props.stockAndPayment?.stock?.error}</span>
                                </div> :
                                    <div className={classes.errorMessageContainer}>
                                        {
                                            (Number(this.props.availableStock) >= this.props.laDetailsInDispatchCounter?.total_values?.alloted_weight)
                                                ? <span style={{ color: successButtonBackground }}>Stock (Available) is greater than LA quantity</span>
                                                : <span style={{ color: "red" }}>Stock (Available) is lower than LA quantity</span>
                                        }
                                    </div>

                            }
                        </>


                    </Paper>
                    <Paper style={{ width: "50%", padding: "20px 0px 0px" }}>
                        <div className={classes.invoicetypeTextContainer}>
                            <span>Payment Details</span>
                        </div>
                        <Divider />
                        <div id="test">
                            <FormControl
                                component="fieldset"
                                style={{ width: "100%", padding: "10px 14px" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        // width: "72%",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "0px 4px",
                                        fontSize: "1.0em",
                                        // color: COLORS.grey,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            color: "#839192",
                                            fontWeight: "bold",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Payment Terms
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Due Date
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Paid Amount
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            Total Invoice Value
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                            {(this.props.laDetailsInDispatchCounter?.payment_method?.is_vcl_enabled
                                                &&
                                                this.props.laDetailsInDispatchCounter?.created_from_subcontract) ? "Virtual Credit Limit(Available)" :
                                                this.props.laDetailsInDispatchCounter?.payment_method?.days > 0 ? "Non Advance Credit Limit(Available)" : "Advance Credit Limit(Available)"}
                                        </span>
                                        <span style={{ height: "16px", marginBottom: "5px" }}>
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            alignItems: "end",
                                            color: grey
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "16px",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            {
                                                this.props.laDetailsInDispatchCounter?.payment_method?.payment_method_name
                                            }
                                        </div>
                                        <div style={{ height: "16px", marginBottom: "5px", whiteSpace: "nowrap" }}>
                                            {checkInvalidValue(this.props.stockAndPayment?.payment?.due_date) ? "" : moment(this.props.stockAndPayment?.payment?.due_date).format("DD-MMM-YYYY")}
                                        </div>
                                        <div style={{ height: "16px", marginBottom: "5px" }}>
                                            {!checkInvalidValue(this.props.stockAndPayment?.payment?.paid_amount) ? `₹ ${this.props.stockAndPayment?.payment?.paid_amount}` : this.props.stockAndPayment?.payment?.paid_amount}
                                        </div>
                                        <div style={{ height: "16px", marginBottom: "5px" }}>
                                            ₹ {this.props.laDetailsInDispatchCounter?.total_values?.total_item_value}
                                        </div>

                                        {(this.props.laDetailsInDispatchCounter?.payment_method?.is_vcl_enabled &&
                                            this.props.laDetailsInDispatchCounter?.created_from_subcontract) ? (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div
                                                    style={{
                                                        color: this.props.stockAndPayment?.payment?.virtual_credit_limit >= 0
                                                            ? successButtonBackground
                                                            : "red"
                                                    }}>
                                                    {!checkInvalidValue(this.props.stockAndPayment?.payment?.virtual_credit_limit) ? `₹ ${this.props.stockAndPayment?.payment?.virtual_credit_limit}` : this.props.stockAndPayment?.payment?.virtual_credit_limit}

                                                </div>
                                                <PopOverComponent
                                                    content={this.HoverPaymentDetails(this.PaymentHoverData)
                                                    }
                                                >
                                                    <InfoOutlinedIcon style={{ fontSize: "1.3em", marginLeft: "6px" }} />
                                                </PopOverComponent>
                                            </div>
                                        ) :
                                            (this.props.laDetailsInDispatchCounter?.payment_method?.days > 0 ? (
                                                <div>
                                                    <MousehoverPopover
                                                        content="credit limit amount - total invoice value"
                                                        icon={true}
                                                        style={{
                                                            height: "16px",
                                                            marginBottom: "5px",
                                                            color: this.props.stockAndPayment?.payment?.non_advance_credit_limit >= 0
                                                                ? successButtonBackground
                                                                : "red"
                                                        }}
                                                    >
                                                        {!checkInvalidValue(this.props.stockAndPayment?.payment?.non_advance_credit_limit) ? `₹ ${this.props.stockAndPayment?.payment?.non_advance_credit_limit}` : this.props.stockAndPayment?.payment?.non_advance_credit_limit}
                                                    </MousehoverPopover>
                                                </div>
                                            ) : (

                                                <div>
                                                    <MousehoverPopover
                                                        content="credit limit amount - total invoice value"
                                                        icon={true}
                                                        style={{
                                                            height: "16px",
                                                            marginBottom: "5px",
                                                            color: this.props.stockAndPayment?.payment?.advance_credit_limit >= 0 ? successButtonBackground : "red"
                                                        }}
                                                    >
                                                        {!checkInvalidValue(this.props.stockAndPayment?.payment?.advance_credit_limit) ? `₹ ${this.props.stockAndPayment?.payment?.advance_credit_limit}` : this.props.stockAndPayment?.payment?.advance_credit_limit}
                                                    </MousehoverPopover>
                                                </div>
                                            )
                                            )}
                                        {(this.props.laDetailsInDispatchCounter?.payment_method?.is_vcl_enabled &&
                                            this.props.laDetailsInDispatchCounter?.created_from_subcontract) ? <div style={{ height: "16px", marginBottom: "5px", fontSize: "0.7em" }}></div>
                                            :
                                            <div style={{ height: "16px", marginBottom: "5px", fontSize: "0.7em" }}>
                                                <span>
                                                    Synced At {this.handleDateTimeShow(this.props.stockAndPayment?.payment?.synced_at)}
                                                    <SyncIcon fontSize="inherit" />
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </FormControl>
                        </div>

                        <>
                            <Divider />
                            {
                                // this.props.laDetailsInDispatchCounter?.erp_invoice_number ? <div className={classes.errorMessageContainer}>
                                //     <span style={{ color: grey }}>Credit Limit (Available) Verified</span>
                                // </div>
                                //     : 
                                this.props.stockAndPayment?.payment?.error ? <div className={classes.errorMessageContainer}>
                                    <span style={{ color: "red" }}>{this.props.stockAndPayment?.payment?.error}</span>
                                </div> :
                                    <div className={classes.errorMessageContainer}>
                                        {
                                            this.props.stockAndPayment?.payment?.non_advance_credit_limit && this.props.stockAndPayment?.payment?.non_advance_credit_limit >= 0
                                                || this.props.stockAndPayment?.payment?.advance_credit_limit && this.props.stockAndPayment?.payment?.advance_credit_limit >= 0
                                                || this.props.stockAndPayment?.payment?.virtual_credit_limit && this.props.stockAndPayment?.payment?.virtual_credit_limit >= 0
                                                ?
                                                <span style={{ color: successButtonBackground }}>Credit Limit is Available</span>
                                                : <span style={{ color: "red" }}>Credit Limit is not Available</span>
                                        }
                                    </div>
                            }
                        </>


                    </Paper>
                </div>

            </>
        );
    }
}
export default withStyles(styles)(EinvoiceAndEwayCard);
