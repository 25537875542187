import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import Paper from "@material-ui/core/Paper";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { arraySortBykeyAllBrowser, pxToEm } from '../../../../methods';
import Divider from '@material-ui/core/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import TimelineIcon from '@mui/icons-material/Timeline';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { grey, primaryColorBackground, secondaryGrey } from "../../../../constants/internal/colors";
import { IconButton } from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { renderBarChart, BarAndLineSalesGraph, BarSalesGraph, LineSalesGraph } from "./BarAndLineSalesGraph";
import moment from "moment";
import { AllOutRounded } from "@material-ui/icons";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import { MuiDateRangePicker } from "./DateRangePicker";
import { TextFieldProps } from "@material-ui/core/TextField";
import { CircularProgress } from "@mui/material";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import SnackbarComponent from "../../common/SnackbarComponent";
import { tableHeaders } from "./header";
import PdfCsvButton from "../../common/PdfCsvButton";
import { handlePrintPDF } from "./PDFPrintContent";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderCollapse: 'separate',
        borderSpacing: '5px 4px'
    },
    paper: {
        position: 'fixed',
        top: '60%',
        left: '70%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '65%',
        zIndex: theme.zIndex.modal + 1,
    },
    underline: {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '50%', // Set the length of the underline
        borderBottom: '1px solid #000', // Style and color of the underline
    },
    root: {
        "&.MuiButtonGroup-root": {
            borderColor: "grey !important",
        },
        "&.MuiTableCell-root": {
            padding: "2px"
        },
        '&::-webkit-scrollbar': {
            height: 7,
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'rgba(75, 135, 197, 0.9)',
            borderRadius: 10,
            border: '2px solid transparent',
            backgroundClip: 'padding-box', // <== make the border work
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(75, 135, 197, 0.9)',
            // height: 30,
            // border: 0,
        },

        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        },
    },
    sticky: {
        "&.MuiTableCell-root": {
            padding: "2px"
        },
        position: "sticky",
        left: "200px",
        background: "white",
        //zIndex: 2,
        width: "100px",
        //boxShadow: "5px 2px 5px grey",
        //borderRight: "2px solid black"
    },
    fixed: {
        "&.MuiTableCell-root": {
            padding: "2px"
        },
        position: "sticky",
        background: "white",
        //zIndex: 3,
        width: "200px",
        left: 0,
        //boxShadow: "5px 2px 5px grey",
        //borderRight: "2px solid black"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    noCaret: {
        caretColor: 'transparent',
    },
    loaderStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: '100%',
    }
}));

const salesDataResponse = {
    "GK TMT": {
        "sales_qty": 2762.001,
        "sales_return": null,
        "avg_price": 20041.78,
        "net_sales": 2762.001,
        "net_sales_cr": 0.0002762001
    },
    "FLY ASH": {
        "sales_qty": 577.196,
        "sales_return": null,
        "avg_price": 0,
        "net_sales": 577.196,
        "net_sales_cr": 5.77196e-05
    },
    "Real Wire HB": {
        "sales_qty": 82.48,
        "sales_return": null,
        "avg_price": 44714.29,
        "net_sales": 82.48,
        "net_sales_cr": 8.248e-06
    },
    "Real Wire GI": {
        "sales_qty": 211.75,
        "sales_return": null,
        "avg_price": 42897.63,
        "net_sales": 211.75,
        "net_sales_cr": 2.1175e-05
    }
}

const allDropdowns = [
    { title: 'Sales Quantity', text: 'Total Sales (excluding intercompany)', },
    { title: 'Sales Return', text: 'Sales Return in MTS', },
    { title: 'Net Sales Quantity', text: 'Total Sales - Sales Return (in MTS)' },
    { title: 'Average Price', text: 'Weighted Average after Discount and Brokerage', },
    { title: 'Net Sales (Cr)', text: 'Net Sales in Crores', },
];

const defaultProps = {
    options: allDropdowns,
    getOptionLabel: (option) => option.title,
};
const iconStyle = {
    border: '1px solid grey', // Set your desired border color and width
    borderRadius: '50%', // Ensure the border is applied to create a circle
    fontSize: "14px",
    marginTop: "5px",
    color: "#FFFFFF",
    marginLeft: "3px"
};


export default function SalesExplorerTable(props) {
    const currentMonth = moment().month()
    const currentYear = currentMonth > 2 ? moment().year() : moment().year() - 1
    const nextYear = currentYear + 1;
    const startDateOfYear = `${currentYear}-04-01`
    const endDateOfyear = `${nextYear}-03-31`
    const monthKeys = ["April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February", "March"]
    const weekKeys = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
    const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
    const [title, setTitle] = useState('')
    const [color, setColor] = useState([])
    const [salesData, setSalesData] = React.useState({});
    const [rows, setRows] = useState([]);
    const [plantData, setPlantData] = useState([]);
    const [plantChart, setPlantChart] = useState([]);
    const [financialData, setFinancialData] = useState([]);
    const [graphAndMonthData, setGraphAndMonthData] = useState({});
    const [graphwiseRows, setGraphwiseRows] = useState([]);
    const [salesType, setSalesType] = useState(allDropdowns[0]);
    const [dropdownText, setDropdownText] = useState(allDropdowns[0]?.text)
    const [isMonthClicked, setIsMonthClicked] = useState(true);
    const [yearSelected, setYearSelected] = useState(true);
    const [monthSelected, setMonthSelected] = useState(false);
    const [weekSelected, setWeekSelected] = useState(false);
    const [customSelected, setCustomSelected] = useState(false);
    const [isPlantClicked, setIsPlantClicked] = useState(false);
    const [isForcePickerOpen, setIsOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState("sales_quantity");
    const [masterLineChartData, setMasterLineChartData] = useState([]);
    const [masterPlantChartData, setMasterPlantChartData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [lineColor, setLineColor] = useState([]);
    // const [allPlantsData, setAllPlantsData] = useState([])
    const [allItemCategory, setAllItemCategory] = useState([])
    const [dataKeys, setDataKeys] = useState([]);
    const [dateRange, setDateRange] = useState({});
    const [open, setOpen] = useState(false);
    const [selectedPlants, setSelectedPlants] = useState([]);
    const [showProgress, setShowProgress] = useState(false);
    const [snackbarType, setSnackbarType] = useState("")
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [hasPlantChartData, setHasPlantChartData] = useState(false)
    const [hasLineChartData, setHasLineChartData] = useState(false)
    const [pdfCsvData, setPdfCsvData] = useState([]);
    const [selectedDatePDFCSV, setSelectedDatePDFCSV] = useState({ start: '', end: '' });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [mounted, setMounted] = useState(false);
    const [snackbarPDFOpen, setSnackbarPDFOpen] = useState(false);
    const getOptions = (user) => {
        return {
            headers: {
                Authorization: `JWT ${user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    };

    const handleSnackbarPDFOpen = () => {
        setSnackbarPDFOpen(true)
    }

    const handleSnackbarPDFClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarPDFOpen(false)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false)
        setSnackbarMessage(null)
        setSnackbarType("")

    };

    const handleOpenSnackbar = (message, type) => {
        setOpenSnackbar(true)
        setSnackbarMessage(message)
        setSnackbarType(type)
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false)
        setSnackbarMessage("")
        setSnackbarType("")
    };


    const toggle = () => setOpen(!open);

    const handleDataRangeChange = (range) => {
        setDateRange(range)
        handleGraphWiseData(
            moment(dateRange?.startDate)?.format('YYYY-MM-DD'),
            moment(dateRange?.endDate)?.format('YYYY-MM-DD'),
            itemSelected,
            "plant")
    }

    // useEffect(() => {
    //     if (customSelected) {
    //         const sDt = moment(dateRange?.startDate)?.format('YYYY-MM-DD')
    //         const eDt = moment(dateRange?.endDate)?.format('YYYY-MM-DD')

    //         if (sDt == eDt) {
    //             setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')}`)
    //         } else {
    //             setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')} to ${moment(eDt).format('DD-MMM\'YY')}`)
    //         }
    //     }
    // }, [dateRange])


    useEffect(() => {
        if (!open && customSelected) {
            if (!dateRange?.startDate) {
                handleYearSelection()
            } else {
                const sDt = moment(dateRange?.startDate)?.format('YYYY-MM-DD')
                const eDt = moment(dateRange?.endDate)?.format('YYYY-MM-DD')
                setSelectedDatePDFCSV({ start: moment(sDt).format('DD-MMM-YY'), end: moment(eDt).format('DD-MMM-YY') })
                if (sDt == eDt) {
                    setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')}`)
                } else {
                    setTitle(`Date ${moment(sDt).format('DD-MMM\'YY')} to ${moment(eDt).format('DD-MMM\'YY')}`)
                }
                setMasterLineChartData([])
                setLineChartData([])
                handleSalesExplorerData(sDt, eDt, 'plant')
            }
        }
    }, [open])


    const getAllItemCategoriesList = () => {
        axios
            .get(
                `${APIROUTES.GET_ITEM_CATEGORY}`,
                //this.getOptions(this.props.user)
            )
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setAllItemCategory(response.data)

                } else {
                }
            })
            .catch((error) => {
                console.log("error.response", error.response);
                setOpenSnackbar(true)
                setSnackbarMessage("Error occured while fetching plant data")
                setSnackbarType("error")
                // this.setState({ itemDataFetchLoader: true });
            });
    };

    const handleGraphWiseData = (fromDate, toDate, filterName, graphType) => {
        //setShowProgress(true)
        const payload = {
            from_date: fromDate,
            to_date: toDate,
            filter_name: filterName,
            graph_type: graphType
        }
        axios.post(
            `${APIROUTES.MONTHWISE_PLANTWISE_DATA}`, payload,
        )
            .then((response) => {
                if (response.status === 200) {
                    //setShowProgress(false)
                    let data = [];
                    switch (itemSelected) {
                        case "sales_quantity":
                            data = response.data?.sales_quantity ?? [];
                            break;
                        case "avg_price":
                            data = response.data?.avg_price ?? [];
                            break;
                        case "sales_return":
                            data = response.data?.sales_return ?? [];
                            break;
                        case "net_sales":
                            data = response.data.net_sales ?? [];
                            break;
                        case "net_sales_cr":
                            data = response.data.net_sales_cr ?? [];
                            break;
                        default:
                            data = [];
                            break;
                    }
                    //console.log(data)
                    // console.log("g", Object.keys(data)?.length > 0)
                    setHasPlantChartData(Object.keys(data)?.length > 0)
                    const result = [];
                    let plantChart = [];
                    props?.plantDropdownList.forEach(a => {
                        plantChart.push({ plant: a.plant_short_name, name: a.plant_name })
                    })
                    Object.keys(data).forEach(o => {
                        const key = o;
                        let total = 0;
                        const val = data[o];
                        let plantval = props?.plantDropdownList.map(a => ({ [a.plant_short_name]: val[a.plant_name] ?? 0.0 }))
                        let item = {
                            name: key
                        };
                        for (const p in plantval) {
                            total += Object.values(plantval[p])[0] ?? 0
                            item = { ...item, ...plantval[p] }
                        }

                        plantChart = plantChart.map(p => ({ ...p, [key]: val[p["name"]] ?? 0 }))
                        item = { ...item, total: total };
                        result.push(item);
                    })
                    setMasterPlantChartData(plantChart)
                    setPlantChart(plantChart);
                    setPlantData(result);
                    //setGraphwiseRows(response.data.sales_quantity)
                    //setGraphAndMonthData(response.data.sales_quantity)
                    // setOpenSnackbar(true)
                    // setSnackbarMessage("Ledger Request Approved")
                    // setSnackbarType("success")
                } else {
                    // setOpenSnackbar(true)
                    // setSnackbarMessage("Error occured while approving Ledger Request")
                    // setSnackbarType("error")
                }
            })
            .catch((error) => {
                setOpenSnackbar(true)
                setSnackbarMessage("Error occured while fetching data")
                setSnackbarType("error")
            });
    };


    const handleSalesExplorerData = (fromDate, toDate, graphType) => {
        setShowProgress(true)
        const payload = {
            from_date: fromDate,
            to_date: toDate,
            //filter_name: filterName,
            graph_type: graphType
        }
        axios.post(
            `${APIROUTES.SALES_DATA}`, payload,
            //getOptions(user)
        )
            .then((response) => {
                if (response.status === 200) {
                    setShowProgress(false)
                    let data = response.data.sales_data
                    // setSalesData(response.data.sales_data);
                    let obj = {}
                    const sortedData = Object.keys(response.data.sales_data).sort((a, b) => {
                        return response.data.sales_data[b].net_sale - response.data.sales_data[a].net_sale;
                    }).forEach((key) => {
                        Object.assign(obj, { [key]: data[key] })
                    });
                    setSalesData(obj);
                } else {
                }
            })
            .catch((error) => {
                setOpenSnackbar(true)
                setSnackbarMessage("Error occured while fetching data")
                setSnackbarType("error")
            });
    };

    const handleYearWiseData = (fromDate, toDate, filterName, graphType) => {
        //setShowProgress(true)
        const payload = {
            from_date: fromDate,
            to_date: toDate,
            filter_name: filterName,
            graph_type: graphType
        }
        axios.post(
            `${APIROUTES.MONTHWISE_PLANTWISE_DATA}`, payload,
            //getOptions(user)
        )
            .then((response) => {
                if (response.status === 200) {
                    //setShowProgress(false)
                    let data = [];
                    switch (itemSelected) {
                        case "sales_quantity":
                            data = response.data?.sales_quantity ?? [];
                            break;
                        case "avg_price":
                            data = response.data?.avg_price ?? [];
                            break;
                        case "sales_return":
                            data = response.data?.sales_return ?? [];
                            break;
                        case "net_sales":
                            data = response.data.net_sales ?? [];
                            break;
                        case "net_sales_cr":
                            data = response.data.net_sales_cr ?? [];
                            break;
                        default:
                            data = [];
                            break;
                    }
                    const _financialData = [];
                    setDataKeys(monthKeys)
                    //console.log(data)
                    setHasLineChartData(Object.keys(data)?.length > 0)
                    for (const k in data) {
                        let plant = { name: k };
                        let plantTotal = 0;
                        for (let i = 1; i <= 12; i++) {
                            const monthName = moment(`${i}`, 'M').format('MMMM');
                            let total = 0;
                            for (const d in data[k]) {
                                if (moment(d).month() == i - 1) {
                                    total += data[k][d]
                                }
                            }
                            const monthData = Number(Number(total).toFixed(3));
                            plantTotal += monthData;
                            plant = { ...plant, [monthName]: monthData }
                        }
                        plant = { ...plant, total: plantTotal };
                        _financialData.push(plant);
                    }
                    setFinancialData(_financialData)
                } else {
                }
            })
            .catch((error) => {
                setOpenSnackbar(true)
                setSnackbarMessage("Error occured while fetching data")
                setSnackbarType("error")
            });
    }

    const handleMonthWiseData = (fromDate, toDate, filterName, graphType) => {
        setPdfCsvData([])
        //setShowProgress(true)
        const payload = {
            from_date: fromDate,
            to_date: toDate,
            filter_name: filterName,
            graph_type: graphType
        }
        axios.post(
            `${APIROUTES.MONTHWISE_PLANTWISE_DATA}`, payload,
        )
            .then((response) => {
                if (response.status === 200) {
                    //setShowProgress(false)
                    let data = [];
                    switch (itemSelected) {
                        case "sales_quantity":
                            data = response.data?.sales_quantity ?? [];
                            break;
                        case "avg_price":
                            data = response.data?.avg_price ?? [];
                            break;
                        case "sales_return":
                            data = response.data?.sales_return ?? [];
                            break;
                        case "net_sales":
                            data = response.data.net_sales ?? [];
                            break;
                        case "net_sales_cr":
                            data = response.data.net_sales_cr ?? [];
                            break;
                        default:
                            data = [];
                            break;
                    }
                    const _financialData = [];
                    let _datakeys = [];
                    setHasLineChartData(Object.keys(data)?.length > 0)
                    const numberOfDays = moment(endOfMonth).daysInMonth();
                    const yr = moment(endOfMonth).year();
                    const mnth = moment(endOfMonth).month();
                    for (let i = 1; i <= numberOfDays; i++) {
                        const ky = moment(new Date(yr, mnth, i)).format("DD/MM");
                        _datakeys.push(ky);
                    }
                    for (const k in data) {
                        let plant = { name: k };
                        let plantTotal = 0;

                        for (let i = 1; i <= numberOfDays; i++) {
                            const dt = moment(new Date(yr, mnth, i)).format("YYYY-MM-DD");
                            const ky = moment(new Date(yr, mnth, i)).format("DD/MM");
                            const dayData = Number(Number(data[k][dt] ?? 0).toFixed(3));
                            plantTotal += Number(Number(data[k][dt] ?? 0).toFixed(3));
                            plant = { ...plant, [ky]: dayData }
                        }
                        plant = { ...plant, total: plantTotal };
                        _financialData.push(plant);
                    }
                    setDataKeys(_datakeys);
                    setFinancialData(_financialData)
                } else {
                }
            })
            .catch((error) => {
                setOpenSnackbar(true)
                setSnackbarMessage("Error occured while fetching data")
                setSnackbarType("error")
            });
    }

    const handleWeekWiseData = (fromDate, toDate, filterName, graphType) => {
        setPdfCsvData([])
        //setShowProgress(true)
        const payload = {
            from_date: fromDate,
            to_date: toDate,
            filter_name: filterName,
            graph_type: graphType
        }
        axios.post(
            `${APIROUTES.MONTHWISE_PLANTWISE_DATA}`, payload,
        )
            .then((response) => {
                if (response.status === 200) {
                    //setShowProgress(false)
                    let data = [];
                    switch (itemSelected) {
                        case "sales_quantity":
                            data = response.data?.sales_quantity ?? [];
                            break;
                        case "avg_price":
                            data = response.data?.avg_price ?? [];
                            break;
                        case "sales_return":
                            data = response.data?.sales_return ?? [];
                            break;
                        case "net_sales":
                            data = response.data.net_sales ?? [];
                            break;
                        case "net_sales_cr":
                            data = response.data.net_sales_cr ?? [];
                            break;
                        default:
                            data = [];
                            break;
                    }
                    const _financialData = [];
                    setHasLineChartData(Object.keys(data)?.length > 0)
                    for (const k in data) {
                        let plant = { name: k };
                        let plantTotal = 0;
                        for (var d = new Date(startOfWeek); d <= new Date(endOfWeek); d.setDate(d.getDate() + 1)) {
                            const dayOfWeek = moment(d).format('dddd');
                            const dow = moment(d).format('YYYY-MM-DD');
                            const val = data[k][dow] ?? 0
                            plantTotal += val;
                            plant = { ...plant, [dayOfWeek]: val }
                        }
                        plant = { ...plant, total: plantTotal };
                        _financialData.push(plant);
                    }
                    setDataKeys(weekKeys)
                    setFinancialData(_financialData)
                } else {
                }
            })
            .catch((error) => {
                setOpenSnackbar(true)
                setSnackbarMessage("Error occured while fetching data")
                setSnackbarType("error")
            });
    }

    useEffect(() => {
        setMounted(true);
        // handleMonthWiseData(startDateOfYear, endDateOfyear, itemSelected, "date");
        // handleSalesExplorerData(startDateOfYear, endDateOfyear, itemSelected, "date")
        // setGraphAndMonthData(graphAndMonthData)
        handleYearSelection();
        getAllItemCategoriesList();
    }, [])

    useEffect(() => {
        //const newItem = { name: name, color: col, isSelected: false };
        const _colors = allItemCategory?.map(a => ({ name: a.category_name, color: a.color_code, isSelected: false }));
        console.log(_colors)
        setColor(_colors)
    }, [allItemCategory])
    useEffect(() => {
        if (mounted) {
            if (yearSelected) {
                getYearlyData();
            } else if (monthSelected) {
                getMonthlyData();
            } else if (weekSelected) {
                getWeeklyData();
            }
        }
    }, [financialData])

    useEffect(() => {
        if (mounted) {
            if (isMonthClicked) {
                if (yearSelected) {
                    handleYearWiseData(startDateOfYear, endDateOfyear, itemSelected, "date");
                } else if (monthSelected) {
                    handleMonthWiseData(startOfMonth, endOfMonth, itemSelected, "date");
                } else {
                    handleWeekWiseData(startOfWeek, endOfWeek, itemSelected, "date");
                }
            } else if (isPlantClicked) {
                if (yearSelected) {
                    handleGraphWiseData(startDateOfYear, endDateOfyear, itemSelected, "plant");
                } else if (monthSelected) {
                    handleGraphWiseData(startOfMonth, endOfMonth, itemSelected, "plant");
                } else if (weekSelected) {
                    handleGraphWiseData(startOfWeek, endOfWeek, itemSelected, "plant");
                } else {
                    console.log("jhfdsfhjsdjh")
                    const sDt = moment(dateRange?.startDate)?.format('YYYY-MM-DD')
                    const eDt = moment(dateRange?.endDate)?.format('YYYY-MM-DD')
                    handleGraphWiseData(sDt, eDt, itemSelected, "plant");
                }
            }
        }
    }, [props?.plantDropdownList, dateRange, itemSelected])

    useEffect(() => {
        console.log(salesData)
        setRows(Object.keys(salesData))
        setGraphwiseRows(Object.keys(graphAndMonthData))
        setPdfCsvData([])
        handlePDFCSVData(salesData)
    }, [salesData, graphAndMonthData])


    useEffect(() => {
        salesTypeChange(salesType)
    }, [salesType])


    useEffect(() => {
        if (isPlantClicked) {
            const selectedPlantChartData = masterPlantChartData.map(m => {
                const _data = { ...m }
                selectedPlants.forEach(p => delete _data[p])
                return _data
            })
            setPlantChart(selectedPlantChartData);

        } else {
            const selectedLineChartData = masterLineChartData.map(m => {
                const _data = { ...m }
                selectedPlants.forEach(p => delete _data[p])
                return _data
            })
            setLineChartData(selectedLineChartData)
        }

    }, [selectedPlants])

    const getYearlyData = () => {
        let chartData = [];
        let jan = { chartKey: 'Jan' };
        let feb = { chartKey: 'Feb' };
        let mar = { chartKey: 'Mar' };
        let apr = { chartKey: 'Apr' };
        let may = { chartKey: 'May' };
        let jun = { chartKey: 'Jun' };
        let jul = { chartKey: 'Jul' };
        let aug = { chartKey: 'Aug' };
        let sep = { chartKey: 'Sep' };
        let oct = { chartKey: 'Oct' };
        let nov = { chartKey: 'Nov' };
        let dec = { chartKey: 'Dec' };
        let plantInfo = [];
        financialData.forEach((f, i) => {
            const selectedColor = color.find(c => c.name == f.name)?.color
            const _plant = { id: i, color: selectedColor }
            plantInfo.push(_plant)
            jan = { ...jan, [i]: f.name, [f.name]: f.January }
            feb = { ...feb, [i]: f.name, [f.name]: f.February }
            mar = { ...mar, [i]: f.name, [f.name]: f.March }
            apr = { ...apr, [i]: f.name, [f.name]: f.April }
            may = { ...may, [i]: f.name, [f.name]: f.May }
            jun = { ...jun, [i]: f.name, [f.name]: f.June }
            jul = { ...jul, [i]: f.name, [f.name]: f.July }
            aug = { ...aug, [i]: f.name, [f.name]: f.August }
            sep = { ...sep, [i]: f.name, [f.name]: f.September }
            oct = { ...oct, [i]: f.name, [f.name]: f.October }
            nov = { ...nov, [i]: f.name, [f.name]: f.November }
            dec = { ...dec, [i]: f.name, [f.name]: f.December }
        })
        chartData.push(apr)
        chartData.push(may)
        chartData.push(jun)
        chartData.push(jul)
        chartData.push(aug)
        chartData.push(sep)
        chartData.push(oct)
        chartData.push(nov)
        chartData.push(dec)
        chartData.push(jan)
        chartData.push(feb)
        chartData.push(mar)
        setMasterLineChartData(chartData)
        setLineChartData(chartData)
        setLineColor(plantInfo);
    }

    const getMonthlyData = () => {
        let chartDataKeys = dataKeys.map(d => ({ chartKey: d }))
        let chartData = []
        let plantInfo = [];
        for (const c of chartDataKeys) {
            let _chartData = { ...c };
            financialData.forEach((f, i) => {
                _chartData = { ..._chartData, [i]: f.name, [f.name]: f[c["chartKey"]] }
            })
            chartData.push(_chartData)
        }
        financialData.forEach((f, i) => {
            const selectedColor = color.find(c => c.name == f.name)?.color
            const _plant = { id: i, color: selectedColor }
            plantInfo.push(_plant)
        })
        setMasterLineChartData(chartData)
        setLineChartData(chartData)
        setLineColor(plantInfo);
    }

    const getWeeklyData = () => {
        let chartDataKeys = dataKeys.map(d => ({ chartKey: d }))
        let chartData = []
        let plantInfo = [];

        for (const c of chartDataKeys) {
            let _chartData = { ...c };
            financialData.forEach((f, i) => {
                _chartData = { ..._chartData, [i]: f.name, [f.name]: f[c["chartKey"]] }
            })
            chartData.push(_chartData)
        }

        financialData.forEach((f, i) => {
            const selectedColor = color.find(c => c.name == f.name)?.color
            const _plant = { id: i, color: selectedColor }
            plantInfo.push(_plant)
        })
        setMasterLineChartData(chartData)
        setLineChartData(chartData)
        setLineColor(plantInfo);
    }

    const generateRandomColor = () => {
        const val = Math.random().toString(16).substr(-6);
        return val;
    }

    const checkIsItemSelected = (name) => {
        return color.find(c => c.name == name)?.isSelected
    }

    const handleItemSelection = (name) => {
        const _color = [...color]
        const existingItem = _color.find(c => c.name == name);
        const isSelected = !existingItem.isSelected;
        existingItem.isSelected = isSelected
        let plants = [...selectedPlants]
        if (isSelected) {
            plants.push(name)
        } else {
            plants = plants.filter(p => p != name)
        }
        setSelectedPlants(plants);
        setColor(_color);

    }

    const showRoundIcon = (name) => {
        const existingColor = color.find(c => c.name == name)?.color;
        const isItemSelected = checkIsItemSelected(name);
        if (isItemSelected) {
            return <svg viewBox="0 0 20 20" width="20" height="20">
                <circle style={{ fill: "#FFFFFF", stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
            </svg>
        }
        if (existingColor) {
            return <svg viewBox="0 0 20 20" width="20" height="20">
                <circle style={{ fill: existingColor, stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
            </svg>
        } else {
            const col = allItemCategory?.find(a => a.category_name == name)?.color_code;
            if (col) {
                const newItem = { name: name, color: col, isSelected: false };
                const items = [...color];
                items.push(newItem);
                setColor(items)
                return <svg viewBox="0 0 20 20" width="20" height="20">
                    <circle style={{ fill: col, stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
                </svg>
            } else {
                return <svg viewBox="0 0 20 20" width="20" height="20">
                    <circle style={{ fill: "#FFFFFF", stroke: "grey", strokeWidth: "1px" }} cx="10" cy="10" r="8" />
                </svg>
            }
        }
    }

    const handleResetPlantSelection = () => {
        setSelectedPlants([]);
        const _color = color.map(c => ({ ...c, isSelected: false }))
        setColor(_color)
    }

    const handleClickMonth = () => {
        // const currentYear = moment().year()
        // const nextYear = currentYear + 1;
        // const startDateOfYear = `${currentYear}-04-01`
        // const endDateOfyear = `${nextYear}-03-31`
        setIsMonthClicked(true);
        setIsPlantClicked(false)
        handleResetPlantSelection();
        if (yearSelected) {
            handleYearWiseData(startDateOfYear, endDateOfyear, itemSelected, "date");
        } else if (monthSelected) {
            handleMonthWiseData(startOfMonth, endOfMonth, itemSelected, "date");
        } else {
            handleWeekWiseData(startOfWeek, endOfWeek, itemSelected, "date");
        }
        //renderBarChart();
        //setFlag(!flag);
    }
    const handleClickgp = () => {
        setIsPlantClicked(true);
        setIsMonthClicked(false);
        handleResetPlantSelection();
    }
    const classes = useStyles();


    const salesTypeChange = (val) => {

        switch (val?.title) {
            case 'Sales Quantity':
                setSalesType(allDropdowns[0])
                setDropdownText(allDropdowns[0]?.text)
                setItemSelected("sales_quantity")
                break;
            case 'Sales Return':
                setSalesType(allDropdowns[1])
                setDropdownText(allDropdowns[1]?.text)
                setItemSelected("sales_return")
                break;
            case 'Net Sales Quantity':
                setSalesType(allDropdowns[2])
                setDropdownText(allDropdowns[2]?.text)
                setItemSelected("net_sales")
                break;
            case 'Average Price':
                setSalesType(allDropdowns[3])
                setDropdownText(allDropdowns[3]?.text)
                setItemSelected("avg_price")
                break;
            case 'Net Sales (Cr)':
                setSalesType(allDropdowns[4])
                setDropdownText(allDropdowns[4]?.text)
                setItemSelected("net_sales_cr")
                break;
            default:
                setSalesType(allDropdowns[0])
                setDropdownText(allDropdowns[0]?.text)
                setItemSelected("sales_return")
                break;
        }
    }

    const handleHeaderClick = (val) => {
        salesTypeChange(val)
    }

    const handleYearSelection = () => {
        handleSalesExplorerData(startDateOfYear, endDateOfyear, "date")
        handleYearWiseData(startDateOfYear, endDateOfyear, itemSelected, "date")
        handleGraphWiseData(startDateOfYear, endDateOfyear, itemSelected, "plant");
        handleResetPlantSelection();
        setTitle(`Financial Year ${moment(startDateOfYear).year()}-${moment(endDateOfyear).year()}`)
        setSelectedDatePDFCSV({ start: moment(startDateOfYear).format('DD-MMM-YY'), end: moment(endDateOfyear).format('DD-MMM-YY') })
        setYearSelected(true)
        setMonthSelected(false)
        setWeekSelected(false)
        setCustomSelected(false)
        setDateRange(null)
    }

    const handleMonthSelection = () => {
        handleSalesExplorerData(startOfMonth, endOfMonth, "date")
        handleMonthWiseData(startOfMonth, endOfMonth, itemSelected, "date")
        handleGraphWiseData(startOfMonth, endOfMonth, itemSelected, "plant");
        handleResetPlantSelection()
        setTitle(`Month ${moment(startOfMonth).format('MMM YYYY')}`)
        setSelectedDatePDFCSV({ start: moment(startOfMonth).format('DD-MMM-YY'), end: moment(endOfMonth).format('DD-MMM-YY') })
        setMonthSelected(true)
        setYearSelected(false)
        setWeekSelected(false)
        setCustomSelected(false)
        setDateRange(null)

    }

    const handleWeekSelection = () => {
        handleSalesExplorerData(startOfWeek, endOfWeek, "date")
        handleWeekWiseData(startOfWeek, endOfWeek, itemSelected, "date")
        handleGraphWiseData(startOfWeek, endOfWeek, itemSelected, "plant");
        handleResetPlantSelection()
        setTitle(`Week ${moment(startOfWeek).format('DD-MMM')} to ${moment(endOfWeek).format('DD-MMM')}`)
        setSelectedDatePDFCSV({ start: moment(startOfWeek).format('DD-MMM-YY'), end: moment(endOfWeek).format('DD-MMM-YY') })
        setWeekSelected(true)
        setYearSelected(false)
        setMonthSelected(false)
        setCustomSelected(false)
        setDateRange(null)

    }

    const handleDateRangePicker = () => {
        setPdfCsvData([])
        handleResetPlantSelection()
        // const sDt = moment(dateRange?.startDate)?.format('YYYY-MM-DD')
        // const eDt = moment(dateRange?.endDate)?.format('YYYY-MM-DD')
        // handleGraphWiseData(sDt, eDt, itemSelected, "plant");
        setCustomSelected(true);
        setWeekSelected(false)
        setYearSelected(false)
        setMonthSelected(false)
        setOpen(!open)
    }

    const handlePDFCSVData = (rows) => {
        if (Object.keys(rows).length > 0) {
            let itemcategories = Object.keys(rows)
            let headers = tableHeaders('sales_explorer')
            let finalPDFCSVArray = []
            Object.keys(rows).map((d, index) => {
                let obj = {};
                let category = itemcategories[index]
                headers.map(h => Object.assign(obj, {
                    [h.key]: h.key === 'item_category_name'
                        ? category
                        : h.key === 'net_sale_cr' ? ((Number(rows[d][h.key]) / 10000000) || 0)
                            : (rows[d][h.key] || 0)
                }
                ))
                finalPDFCSVArray.push(obj)
            })
            let bottomCalculation = {};
            let keys = Object.keys(finalPDFCSVArray[0])
            keys.map(k => k === 'item_category_name'
                ? Object.assign(bottomCalculation, { [k]: 'Total Calculation' })
                : Object.assign(bottomCalculation, {
                    [k]: k === 'net_sale_cr'
                        ? (Number((Number(Object.values(rows).reduce((t, v) => Number(Number(t) + Number(v[k] ?? 0)), 0))).toFixed(3)) / 10000000)
                        : (Number(Object.values(rows).reduce((t, v) => Number(Number(t) + Number(v[k] ?? 0)), 0))).toFixed(3)
                })
            )
            finalPDFCSVArray.push(bottomCalculation)
            setPdfCsvData(finalPDFCSVArray)
        }
    }

    return (
        <>
            <div style={{ paddingLeft: 30, paddingTop: 40, paddingRight: 30 }}>
                <div style={{ float: "right" }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile && 'column', marginTop: isMobile && 30 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <ButtonGroup variant="outlined" aria-label="outlined button group" color="inherit">
                                <Button style={{ width: 45, height: 45, backgroundColor: yearSelected ? "#293C79" : "white", color: yearSelected ? "white" : "grey" }}
                                    onClick={handleYearSelection}
                                >
                                    Y
                                </Button>
                                <Button style={{ width: 45, height: 45, backgroundColor: monthSelected ? "#293C79" : "white", color: monthSelected ? "white" : "grey" }}
                                    onClick={handleMonthSelection}
                                >
                                    M
                                </Button>
                                <Button style={{ width: 45, height: 45, backgroundColor: weekSelected ? "#293C79" : "white", color: weekSelected ? "white" : "grey" }}
                                    onClick={handleWeekSelection}
                                >
                                    W
                                </Button>
                                <Button style={{ width: 45, height: 45, backgroundColor: customSelected ? "#293C79" : "white", color: customSelected ? "white" : "grey" }}
                                    //onClick={() => setIsOpen((isOpen) => !isOpen)}
                                    onClick={handleDateRangePicker}

                                >
                                    <DateRangeIcon color="grey" />
                                </Button>

                            </ButtonGroup>
                            {/* <Paper className={classes.paper}>
                            <DateRangePicker
                                open={open}
                                toggle={toggle}
                                onChange={(range) => {
                                    //props.onDateRangeChange(range);
                                    toggle();
                                }}
                                initialDateRange={dateRange}
                            />
                            <Button
                                className={classes.clearButton}
                                variant="outlined"
                                color="secondary"
                                onClick={clearDates}
                            >
                                Clear Dates
                            </Button>
                        </Paper> */}
                            <MuiDateRangePicker
                                open={open}
                                toggle={toggle}
                                dateRange={dateRange}
                                onDateRangeChange={handleDataRangeChange}
                            />
                        </Box>
                        <div style={{ padding: '10px 0 0 10px' }}>
                            <PdfCsvButton
                                handlePrintClick={() => handlePrintPDF(
                                    pdfCsvData,
                                    tableHeaders('sales_explorer'),
                                    '',
                                    {
                                        startDate: selectedDatePDFCSV.start,
                                        endDate: selectedDatePDFCSV.end,
                                    },
                                    handleSnackbarPDFOpen,
                                    'sales_explorer'
                                )}
                                csvData={pdfCsvData}
                                csvHeader={tableHeaders('sales_explorer')}
                                mobileCSVFilename={`sales_explorer${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                                webCSVFilename={`sales_explorer${moment(new Date()).format("DD-MMM-YYYY_HH:mm:ss")}.csv`}
                                handleSnackbarPDFOpen={handleSnackbarPDFOpen}
                            />
                            <SnackbarComponent
                                open={snackbarPDFOpen}
                                handleClose={handleSnackbarPDFClose}
                                customAutoHideDuration={3000}
                                snackbarMessage="Downloading ..."
                            />
                        </div>
                    </div>
                    <div style={{ fontSize: pxToEm(10), color: "grey", float: "right", marginRight: "10px" }}>{title}</div>
                </div>
                {/* <Paper style={{ maxWidth: "65%" }}>
                    <DateRangePicker
                        open={open}
                        toggle={toggle}
                        onChange={(range) => {
                            //props.onDateRangeChange(range);
                            toggle();
                        }}
                        initialDateRange={dateRange}
                    />
                </Paper> */}
                <div style={{ fontSize: pxToEm(20), fontFamily: 'Poppins', }}>
                    Sales Explorer
                </div>
                <div style={{ fontSize: pxToEm(12), color: "#707070", fontFamily: 'Poppins', }}>Excluding Intercompany Sales</div>
            </div>
            <TableContainer style={{ width: "100%", paddingLeft: 30, paddingRight: 30 }}>
                {showProgress ?
                    <div className={classes.loaderStyle}>
                        <CircularProgress /> </div> :
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className={classes.root}>
                                <TableCell style={{ borderBottom: "none" }} className={classes.root}></TableCell>
                                <TableCell align="right" style={{ borderBottom: "none", textDecoration: 'underline', cursor: "pointer", color: "#293C79" }} className={classes.root} onClick={() => handleHeaderClick(allDropdowns[0])}>Sales Qty</TableCell>
                                <TableCell align="right" style={{ borderBottom: "none", textDecoration: 'underline', cursor: "pointer", color: "#293C79" }} className={classes.root} onClick={() => handleHeaderClick(allDropdowns[1])}>Sales Return</TableCell>
                                <TableCell align="right" style={{ borderBottom: "none", textDecoration: 'underline', cursor: "pointer", color: "#293C79" }} className={classes.root} onClick={() => handleHeaderClick(allDropdowns[2])}>Net Sales</TableCell>
                                <TableCell align="right" style={{ borderBottom: "none", textDecoration: 'underline', cursor: "pointer", color: "#293C79" }} className={classes.root} onClick={() => handleHeaderClick(allDropdowns[3])}>Avg Price</TableCell>
                                <TableCell align="right" style={{ borderBottom: "none", textDecoration: 'underline', cursor: "pointer", color: "#293C79" }} className={classes.root} onClick={() => handleHeaderClick(allDropdowns[4])}>Net Sales (Cr)</TableCell>

                            </TableRow>
                        </TableHead>
                        {rows.length > 0 ?
                            <TableBody>
                                {
                                    rows.map((row, i) => (
                                        <TableRow key={row}>
                                            <TableCell component="th" scope="row" style={{ borderBottom: "none", display: "flex", alignItems: "center" }} className={classes.root}>
                                                <span style={{ marginTop: "5px" }}>{showRoundIcon(row)}</span>
                                                <span style={{ fontSize: pxToEm(18), marginLeft: "10px" }}>
                                                    {row}
                                                </span>
                                            </TableCell>
                                            <TableCell align="right" style={{ borderBottom: rows.length - 1 == i ? "2px solid #808080" : "none", paddingRight: "5px" }} className={classes.root}>{salesData[row]?.sales_qty}</TableCell>
                                            <TableCell align="right" style={{ borderBottom: rows.length - 1 == i ? "2px solid #808080" : "none" }} className={classes.root}>{salesData[row]?.sales_return}</TableCell>
                                            <TableCell align="right" style={{ borderBottom: rows.length - 1 == i ? "2px solid #808080" : "none" }} className={classes.root}>{salesData[row]?.net_sale}</TableCell>
                                            <TableCell align="right" style={{ borderBottom: rows.length - 1 == i ? "2px solid #808080" : "none" }} className={classes.root}>{salesData[row]?.avg_price}</TableCell>
                                            <TableCell align="right" style={{ borderBottom: rows.length - 1 == i ? "2px solid #808080" : "none" }} className={classes.root}>{(salesData[row]?.net_sale_cr) / 10000000}</TableCell>


                                        </TableRow>
                                    ))

                                }

                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ borderBottom: "none", display: "flex", alignItems: "center" }} className={classes.root}>

                                    </TableCell>
                                    <TableCell align="right" className={classes.root} style={{ color: "#293C79" }}>{Number(Object.values(salesData).reduce((t, v) => t + v?.sales_qty ?? 0, 0)).toFixed(3)}</TableCell>
                                    <TableCell align="right" className={classes.root} style={{ color: "#293C79" }}>{Number(Object.values(salesData).reduce((t, v) => t + v?.sales_return ?? 0, 0)).toFixed(3)}</TableCell>
                                    <TableCell align="right" className={classes.root} style={{ color: "#293C79" }}>{Number(Object.values(salesData).reduce((t, v) => t + v?.net_sale ?? 0, 0)).toFixed(3)}</TableCell>
                                    <TableCell align="right" className={classes.root} style={{ color: "#293C79" }}>{Number(Object.values(salesData).reduce((t, v) => t + v?.avg_price ?? 0, 0)).toFixed(3)}</TableCell>
                                    <TableCell align="right" className={classes.root} style={{ color: "#293C79" }}>{(Number(Object.values(salesData).reduce((t, v) => t + v?.net_sale_cr ?? 0, 0)).toFixed(3)) / 10000000}</TableCell>
                                </TableRow>

                            </TableBody>
                            : <TableRow>
                                <TableCell colSpan={6} className={classes.root}>
                                    <div className={classes.loaderStyle}>
                                        <img src="/nodata-found.png" alt="No Chart" />

                                    </div>
                                </TableCell>
                            </TableRow>}
                    </Table>
                }
            </TableContainer>
            <br />
            <Divider variant="fullWidth" />
            <div style={{ paddingRight: 30, paddingTop: 40 }}>
                <div style={{ float: "right" }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            '& > *': {
                                m: 1,
                            },
                        }}
                    >
                        <ButtonGroup variant="outlined" aria-label="outlined button group" color="inherit" >
                            <Button style={{ width: 40, height: 40, backgroundColor: isMonthClicked ? "#293C79" : "white" }}
                                onClick={handleClickMonth}
                            >
                                <IconButton
                                    style={{ color: isMonthClicked ? "white" : "grey" }}

                                >
                                    <TimelineIcon />
                                </IconButton>
                            </Button>
                            <Button style={{ width: 40, height: 40, backgroundColor: isPlantClicked ? "#293C79" : "white" }}
                                onClick={handleClickgp}
                            >
                                <IconButton
                                    style={{ color: isPlantClicked ? "white" : "grey" }}

                                >
                                    <LeaderboardIcon />
                                </IconButton>
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <div style={{ fontSize: pxToEm(10), color: "grey", float: "right", marginRight: "10px" }}>{isMonthClicked && yearSelected ? "Year Wise" :
                        isMonthClicked && monthSelected ? "Month Wise" : isMonthClicked && weekSelected ? "Week Wise" : customSelected ? "custom date wise" : "Plant Wise"}</div>

                </div>
                <div style={{ paddingLeft: 30 }}>
                    <Autocomplete
                        {...defaultProps}
                        id="disable-clearable"
                        disableClearable
                        value={salesType}
                        onChange={(event, newValue) => {
                            setSalesType(newValue);
                        }}
                        sx={{ width: 200, fontFamily: 'Poppins', }}
                        renderInput={(params) => (
                            <TextField {...params}
                                //label="disableClearable"
                                variant="standard"
                                className={classes.noCaret}
                                InputProps={{ ...params.InputProps, disableUnderline: true, style: { fontFamily: 'Poppins', } }} />
                        )}
                    />
                </div>
                <div style={{ fontSize: pxToEm(12), color: "#707070", paddingLeft: 30, fontFamily: 'Poppins', }}>{dropdownText}</div>
            </div>
            <div style={{ width: "98%", alignItems: "center", marginLeft: "10px", marginRight: "10px", marginTop: "50px" }}>
                <Paper style={{ borderRadius: "20px" }}>
                    {isMonthClicked ? <LineSalesGraph
                        data={lineChartData} lineColor={lineColor}
                        yearSelected={yearSelected}
                        monthSelected={monthSelected}
                        weekSelected={weekSelected}
                        hasLineChartData={hasLineChartData}
                    /> : <BarSalesGraph data={plantChart} color={color}
                        hasPlantChartData={hasPlantChartData} />}
                </Paper>
            </div>
            {isMonthClicked ?
                customSelected ? (<></>) : (<div style={{ width: "100%", paddingTop: 20 }}>
                    <TableContainer className={classes.root} style={{ width: "100%", position: "relative" }}>
                        {/* {showProgress ? <CircularProgress /> : */}
                        {financialData.length > 0 ?
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                                style={{ tableLayout: "fixed" }}
                            >
                                <TableHead>
                                    <TableRow className={classes.root}>
                                        <TableCell className={classes.fixed} style={{ borderBottom: "none" }}></TableCell>
                                        <TableCell align="right" style={{ borderBottom: "none", color: "#293C79" }} className={classes.sticky}>Total</TableCell>
                                        {
                                            dataKeys.map((d) => (<TableCell align="right" style={{ borderBottom: "none", width: "100px", color: "#293C79" }} className={classes.root}>{d}</TableCell>))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {financialData?.map((row) => (
                                        <TableRow key={row.name} className={classes.root}>
                                            <TableCell component="th" scope="row" className={classes.fixed} style={{ borderBottom: "none", display: "flex", alignItems: "center" }} onClick={() => handleItemSelection(row.name)}>
                                                <span style={{ marginTop: "5px" }}>{showRoundIcon(row.name)}</span>
                                                <span style={{ fontSize: pxToEm(18), marginLeft: "10px" }}>
                                                    {row.name}
                                                </span>
                                            </TableCell>
                                            <TableCell align="right" style={{ borderBottom: row.name == "Fly Ash" ? "2px solid #808080" : "none" }} className={classes.sticky}>{Number(row.total).toFixed(3)}</TableCell>
                                            {
                                                dataKeys.map((d) => (<TableCell align="right" style={{ borderBottom: row.name == "Fly Ash" ? "2px solid #808080" : "none", width: "100px" }} className={classes.root}>{row[d]}</TableCell>))
                                            }
                                        </TableRow>
                                    ))}
                                    <TableRow className={classes.root}>
                                        <TableCell component="th" scope="row" className={classes.fixed} style={{ borderBottom: 'none' }}>

                                        </TableCell>
                                        <TableCell align="right" className={classes.sticky} style={{ borderTop: "2px solid grey", color: "#293C79", borderBottom: 'none' }}>{Number(financialData?.reduce((t, v) => t + v?.total, 0)).toFixed(3)}</TableCell>
                                        {
                                            dataKeys.map((d) => (<TableCell align="right" className={classes.root} style={{ borderTop: "2px solid grey", color: "#293C79", width: "100px", borderBottom: 'none' }}>{Number(financialData?.reduce((t, v) => t + v[d] ?? 0, 0)).toFixed(3)}</TableCell>))
                                        }

                                    </TableRow>
                                </TableBody>
                            </Table>
                            : <></>}
                        {/* } */}
                    </TableContainer>
                </div>)
                :
                <div style={{ width: "100%", paddingTop: 20 }}>
                    <TableContainer style={{ width: "100%", position: "relative" }}>
                        {/* {showProgress ? <CircularProgress /> : */}
                        {plantData.length > 0 ?
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                                style={{ tableLayout: "fixed" }}
                            >
                                <TableHead>
                                    <TableRow className={classes.root}>
                                        <TableCell className={classes.fixed} style={{ borderBottom: "none" }}></TableCell>
                                        <TableCell align="right" style={{ borderBottom: "none", color: "#293C79" }} className={classes.sticky}>Total</TableCell>
                                        {
                                            plantData[0] && Object.keys(plantData[0]).filter(k => k != 'name' && k != 'total').map((k) => (<TableCell align="right" style={{ borderBottom: "none", width: "100px" }} className={classes.root}>{k}</TableCell>))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {plantData?.map((row) => (
                                        < TableRow key={row.name} className={classes.root} >
                                            <TableCell component="th" scope="row" className={classes.fixed} style={{ borderBottom: "none", display: "flex", alignItems: "center" }} onClick={() => handleItemSelection(row.name)}>
                                                <span style={{ marginTop: "5px" }}>{showRoundIcon(row.name)}</span>
                                                <span style={{ fontSize: pxToEm(18), marginLeft: "10px" }}>
                                                    {row.name}
                                                </span>
                                            </TableCell>
                                            <TableCell align="right" style={{ borderBottom: row.name == "Fly Ash" ? "2px solid #808080" : "none" }} className={classes.sticky}>{Number(row.total).toFixed(3)}</TableCell>
                                            {
                                                Object.keys(row).filter(k => k != 'name' && k != 'total').sort().map((k) => (<TableCell align="right" style={{ borderBottom: "none", width: "100px" }} className={classes.root}>{Number(row[k]).toFixed(3)}</TableCell>))
                                            }
                                        </TableRow>
                                    ))}
                                    <TableRow className={classes.root}>
                                        <TableCell component="th" scope="row" className={classes.fixed}>

                                        </TableCell>
                                        <TableCell align="right" className={classes.sticky} style={{ color: "#293C79", borderTop: "2px solid grey", }}>{Number(plantData.reduce((t, v) => t += v?.total ?? 0, 0)).toFixed(3)}</TableCell>
                                        {
                                            plantData[0] && Object.keys(plantData[0]).filter(k => k != 'name' && k != 'total').sort().map((k) => (<TableCell align="right" className={classes.root} style={{ color: "#293C79", borderTop: "2px solid grey", width: "100px" }}>{Number(plantData.reduce((t, v) => t += v[k] ?? 0, 0)).toFixed(3)}</TableCell>))
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                            : <></>}
                        {/* } */}
                    </TableContainer>
                </div >
            }
            <SnackbarComponent
                open={openSnackbar}
                handleClose={handleSnackbarClose}
                snackbarMessage={snackbarMessage}
                type={snackbarType}
            />
        </>

    );
}
