import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import {
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  grey,
  whiteSmoke,
  secondaryBlueText,
  drawerTextColor,
  primaryColor,
  highlitedTextColor,
} from "../../../../constants/internal/colors";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { pxToEm } from "../../../../methods";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  tableHeaderRow: {
    backgroundColor: whiteSmoke,
    color: grey,
  },
  th: {
    color: grey,
    fontSize: pxToEm(13),
    fontWeight: "bold",
  },
  tableBody: {
    fontSize: pxToEm(14),
  },
  heading: {
    display: "flex",
    alignItems: "center",
    fontSize: pxToEm(15),
    flexBasis: "70%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: pxToEm(15),
    color: theme.palette.text.secondary,
  },
  titleWithBlueFont: {
    fontSize: pxToEm(15),
    color: secondaryBlueText,
  },
  expandPanelSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "90%",
  },
  generalSpacingRight: {
    paddingRight: 10,
  },
  restrictionIcons: {
    paddingRight: 10,
  },
  ipRestriction: {
    width: "90px",
    height: "21px",
    borderRadius: "10px",
    opacity: "1",
    fontSize: pxToEm(11),
    display: "flex",
    alignItems: "center",
    placeContent: "space-evenly",
    backgroundColor: secondaryBlueText,
    color: drawerTextColor,
  },
  ipRestrictedIcon: {
    width: "14px",
    height: "14px",
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
  },
  expansionPanelWidth: {
    width: "90%",
  },
  editButtonAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  titleStyle: {
    fontSize: pxToEm(15),
    fontWeight: "bold",
    color: grey,
  },
  titleDiv: {
    margin: "30px 0px 10px 0px",
  },
  ip: {
    color: "rgb(245, 245, 245)",
    backgroundColor: highlitedTextColor,
    padding: "4px 8px",
    borderRadius: 15,
    fontSize: 10,
  },
  dropbtn: {
    backgroundColor: "#f7f7f7",
    padding: "16px",
    borderRadius: "5px",
    color: "gray",
    fontSize: pxToEm(18),
    width: '20ch',
    overflow: "hidden",
    border: "none"
  },
  dropdownContent: {
    display: "none",
    position: "absolute",
    backgroundColor: "#f1f1f1",
    minWidth: '20ch',
    // boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    borderRadius: "5px",
    zIndex: "10"
  },
  dropdownContenta: {
    color: "gray",
    padding: "10px 12px",
    textDecoration: "none",
    display: "block",

  },
  dropdown: {
    position: "relative",
    display: "inline-block",

    "&:hover": {
      "& $dropdownContent": {display: "block"}
    }

  },
  
}));
const CutomColorCheckbox = withStyles({
  root: {
    color: secondaryBlueText,
    "&$checked": {
      color: secondaryBlueText,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function AccessAreaManagementDisplay({ modules, history }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (listItem) => (event, isExpanded) => {
    setExpanded(isExpanded ? listItem : false);
  };
  return (
    <div className={classes.root}>
      <div className={classes.editButtonAlignment}>
        <Button
          size="medium"
          className={classes.primaryBackground}
          variant="contained"
          startIcon={<EditOutlinedIcon />}
          onClick={(e) => {
            e.preventDefault();
            
            history.push(
              "/internalUsersAndPermissions/accessAreaManagement/edit"
            );
          }}
        >
          EDIT
        </Button>
      </div>
      <div className={classes.titleDiv}>
        <span className={classes.titleStyle}>ACCESS AREA MANAGEMENT</span>
      </div>
      {console.log(modules)}
      {modules.length > 0
        ? modules.map((data, index) => (
            <ExpansionPanel
              key={index}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className={classes.expandPanelSummary}>
                  <Typography className={classes.heading}>
                    <span className={classes.generalSpacingRight}>
                      {data.name}
                    </span>
                    {data.widgets.find(
                      (w) => w?.widget?.is_accessible_on_web
                    ) && (
                      <img
                        className={classes.generalSpacingRight}
                        src="/tools-and-utensil.svg"
                        alt=""
                      />
                    )}
                    {data.widgets.find(
                      (w) => w?.widget?.is_accessible_on_mobile
                    ) && (
                      <img
                        className={classes.generalSpacingRight}
                        src="/communications.svg"
                        alt=""
                      />
                    )}
                    {data.widgets.find(
                      (w) => w?.widget?.white_listed_ips?.length > 0
                    ) && <span className={classes.ip}>IP restricted</span>}
                  </Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeaderRow}>
                        {/* {data.name=="Dispatch Operations"?["PLANT", "WIDGET", "MOBILE", "DESKTOP", "IP RESTRICTION"]:["WIDGET", "MOBILE", "DESKTOP", "IP RESTRICTION"].map((e) => (
                          <TableCell
                            key={e}
                            align="center"
                            className={classes.th}
                          >
                            {e}
                          </TableCell>
                        ))} */}
                        {
                          data.name=="Dispatch Operations" || data.name=="Dispatch Planning" || data.name=="Dispatch Tracking" ? (
                          <TableCell align="center" className={classes.th}>
                            PLANT
                          </TableCell>
                          ) : null 
                        }
                        <TableCell align="center" className={classes.th}>
                          WIDGET
                        </TableCell>
                        <TableCell align="center" className={classes.th}>
                          MOBILE
                        </TableCell>
                        <TableCell align="center" className={classes.th}>
                          DESKTOP
                        </TableCell>
                        <TableCell align="center" className={classes.th}>
                          IP RESTRICTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.widgets.map((row, i) => (
                        <TableRow key={i}>
                          {
                            data.name=="Dispatch Operations" || data.name=="Dispatch Planning" || data.name=="Dispatch Tracking" ? (
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={classes.tableBody}
                            >
                              {row.widget.plant_name}
                            </TableCell>
                            ) : null 
                          }
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableBody}
                          >
                            {row.widget.widget_name}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableBody}
                          >
                            { row.widget.is_accessible_on_mobile ? (
                              <CheckCircleOutlineOutlinedIcon style={{
                                color: "green"
                              }}/>
                            ) : (
                              <CancelOutlinedIcon style={{
                                color: "lightgray"
                              }}/>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableBody}
                          >
                            { row.widget.is_accessible_on_web ? (
                              <CheckCircleOutlineOutlinedIcon style={{
                                color: "green"
                              }}/>
                            ) : (
                              <CancelOutlinedIcon style={{
                                color: "lightgray"
                              }}/>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableBody}
                          >
                            {row.widget.white_listed_ips.length === 0 ? null : row.widget.white_listed_ips.length === 1 ? <button class={classes.dropbtn}>{row.widget.white_listed_ips[0].ip_address}</button>: (
                              <div class={classes.dropdown}>
                                <button class={classes.dropbtn}>{`${row.widget.white_listed_ips.length} IPS`}</button>
                                <div class={classes.dropdownContent}>
                                {row.widget.white_listed_ips.map((data) => (
                                    <p class={classes.dropdownContenta} key={data}>{data.ip_address}</p>
                                  ))}
                                </div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))
        : null}
    </div>
  );
}

export default withRouter(AccessAreaManagementDisplay);
