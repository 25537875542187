import React from "react";
import { Typography, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const noDataFoundStyles = (theme) => ({
  avatar: {
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    backgroundColor: "white",
    height: "200px",
    width: "200px",
    opacity: 0.5,
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
    marginTop: 50
  },
});
class NoDataFound extends React.Component {
  constructor(props) {
    super(props);
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  render() {
    const { classes, type } = this.props;
    return (
      <div className={classes.mainDiv} id="noDataFoundDiv">
        <Avatar
          className={classes.avatar}
          src={
            window.cordova
              ? `${this.image}${"/nodata-found.png"}`
              : "/nodata-found.png"
          }
        />
        {type === 'noText' ? null :
          <Typography
            style={{ opacity: 0.4, fontSize: "1.5rem", paddingTop: "1em" }}
            variant={window.cordova ? "h7" : "h4"}
          >
            We didn't find any records.
          </Typography>
        }
      </div>
    );
  }
}
export default withStyles(noDataFoundStyles)(NoDataFound);
