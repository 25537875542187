import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Zoom, Avatar, Typography, Button, Paper } from "@material-ui/core";
import {
  primaryColor,
  drawerTextColor,
} from "../../../../../constants/internal/colors";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    placeItems: "center",
    placeContent: "center",
    flexDirection: "column",
    width: "50%",
    height: "calc(100vh - 220px)",
    borderRadius: "30px",
  },
  avatar: {
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    backgroundColor: "white",
    height: "180px",
    width: "180px",
    opacity: 0.5,
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    placeItems: "center",
  },
  goBackButton: {
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    width: "100%",
    paddingTop: "20px",
    justifyContent: "space-evenly",
  },
  paddingTop: { paddingTop: 20 },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    borderRadius: "30px",
  },
}));

export default function OrderConfirmationScreen(props) {
  const classes = useStyles();
  const [
    displayOrderConfirmationScreen,
    setDisplayOrderConfirmationScreen,
  ] = React.useState(props?.displayOrderConfirmationScreen);
  React.useEffect(() => {
    setDisplayOrderConfirmationScreen(props?.displayOrderConfirmationScreen);
  }, [props?.displayOrderConfirmationScreen]);
  return (
    <div className={classes.mainDiv} id="comingSoonDiv">
      <Zoom
        in={displayOrderConfirmationScreen}
        style={{ transitionDelay: "300ms" }}
      >
        <Paper elevation={4} className={classes.paper}>
          <Avatar className={classes.avatar} src={"/check-circle.gif"} />
          <Typography
            style={{ opacity: 0.4, fontSize: "1.5rem", paddingTop: "1em" }}
            variant={"h4"}
          >
            Order generated successfully.
          </Typography>
          <div className={classes.goBackButton}>
            <Button
              className={classes.primaryBackground}
              variant="outlined"
              size="medium"
              startIcon={<PictureAsPdfOutlinedIcon />}
              onClick={(evt) => {
                evt.preventDefault();
                props.handleViewOrderDetails();
              }}
            >
              Profarma Invoice
            </Button>
            <Button
              className={classes.primaryBackground}
              variant="outlined"
              size="medium"
              onClick={(evt) => {
                evt.preventDefault();
                props.handleViewOrderDetails();
              }}
            >
              View Details
            </Button>
          </div>
        </Paper>
      </Zoom>
    </div>
  );
}
