import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  dailyRateScreenTextColor,
  lightPrimaryColor,
  bottomNavigationFontColor
} from "../../../styles/colors";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import SpinLoader from "../../common/SpinLoader";
import NoDataFound from "../../common/NoDataFound";
import { pxToEm } from "../../../methods";


const styles = {
  plantCardStyle: {
    position: "relative",
    boxShadow:
      "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
    // height: "13vh",
    // width: "45%",
    width: window.innerWidth < 375 ? 125 : 160,
    // padding: "10px 0px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "4px",
    margin: "6px",
  },

  formControlLabelStyle: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "7px",
    padding: "10px 0px",
  },
  radioGroupDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  itemTitle: {
    color: lightPrimaryColor,
    fontWeight: "bold",
    fontSize: window.innerWidth < 375 ? pxToEm(14) : "1.1em",
    // marginLeft: 10

  },
  gateInTextStyle: {
    color: dailyRateScreenTextColor,
    fontSize: window.innerWidth < 375 ? pxToEm(15) : "1.19em",
    fontWeight: 500,
    marginTop: 5,
  }
};
class PlantCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      selectedPlant: null,
      value: '',
    };
  }

  componentDidUpdate(nextProp, prevProp) {
    if (this.props.plantsList !== nextProp.plantsList) {
      if (this.props?.laState?.laDetails) {
        // console.log(this.props.laState, this.props.plantsList);
        let plant = this.props?.plantsList?.find(plant =>
          plant.plant_short_name === this.props.laState?.laDetails?.plant?.plant_short_name
        )
        // console.log(plant, this.props.laState?.laDetails?.plant?.plant_short_name)
        this.setState({
          selectedPlant: this.props.laState?.laDetails?.plant?.plant_short_name,
          value: this.props.laState?.laDetails?.plant?.plant_short_name
        }, () => this.props.selectedPlantAndItem(plant))
      }
      else {
        this.setState({
          selectedPlant: null,
          selectedItem: null,
          value: ''
        }, () => this.props.selectedPlantAndItem(''))
      }
    }
  }



  handleChange = (event) => {
    const { value } = event.target
    console.log(value)
    let plant = this.props.plantsList.find(plant =>
      plant.plant_short_name === value
    )
    this.setState({
      selectedPlant: value,
      value
    }, () => this.props.selectedPlantAndItem(plant))

    // console.log(plant)
  }
  render() {
    const {
      classes, plantsList, pendingCategories,
      selectedOrderItemCategory, plantPlanningLoader,
      createLAAllowed, showLACreateError, laState } = this.props;
    const { itemArray, showKey, value } = this.state

    // console.log(plantsList)
    // console.log(showLACreateError)


    return (
      plantsList && plantsList.length > 0 ?
        <>
          <span className={classes.gateInTextStyle}>
            Select Plant & Item Category
          </span>
          <br></br>
          <span style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}>
            The following items are ready to dispatch at the plant
          </span>
          <FormControl
            component="div"
            className={classes.formControl}
            style={{ paddingBottom: "10px", display: 'flex' }}
          >
            <RadioGroup
              aria-label="gender"
              name="gender2"
              value={value}
              onChange={this.handleChange}
            >
              {
                selectedOrderItemCategory &&
                <p className={classes.itemTitle}>
                  {selectedOrderItemCategory}
                </p>
              }

              <div className={classes.radioGroupDiv}>
                {
                  plantsList &&
                  plantsList.map((data) => (
                    <FormControlLabel
                      key={data.plant}
                      className={classes.plantCardStyle}
                      value={data.plant_short_name}
                      style={{
                        display: (laState?.mode !== 'edit' && data.order_available_quantity === 0) && 'none'
                      }}
                      disabled={laState?.mode === 'edit' && true}
                      control={
                        <Radio
                          key={data.plant}
                          style={{
                            color: laState?.mode === 'edit' ? 'rgba(0,0,0,0.26)' : primaryColor,
                            position: "absolute",
                            right: "0px",
                            bottom: "0px",
                            transform: window.innerWidth < 375 && 'scale(0.8)'
                          }}
                        />
                      }
                      labelPlacement="end"
                      label={
                        <div className={classes.formControlLabelStyle}>
                          <span
                            style={{
                              fontSize: window.innerWidth < 375 && pxToEm(13.6)
                            }}
                          >
                            {data.plant_short_name} - {data.order_available_quantity} MT
                          </span>

                          <div
                            style={{
                              color: laState?.mode === 'edit' ? 'rgba(0,0,0,0.4)' : dailyRateScreenTextColor,
                              fontSize: window.innerWidth < 375 ? pxToEm(12) : "0.9em",
                            }}
                          >
                            <span>Balance</span>
                          </div>
                        </div>
                      }
                    />
                  ))
                }
              </div>
            </RadioGroup>
          </FormControl>
        </>
        : plantsList && plantsList.length === 0 ?
          <div>
            <NoDataFound />
          </div> : showLACreateError ?
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span>Not allowed</span>
            </div> :
            plantPlanningLoader ?
              <div style={{
                marginLeft: 165,
                marginTop: 100
              }}>
                <SpinLoader />
              </div>
              : null
    );
  }
}
export default withStyles(styles)(PlantCard);
