import React, { Component } from "react";
// import Customers from '../../components/internal/web/customer/Customers';
import CustomerWeb, {
  ManageCustomers,
  CustomerMobile,
} from "../../../components/internal/customer";
import AccessDenied from "../../../components/common/AccessDenied";
import { Route } from "react-router-dom";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import axios from "axios";
import SnackbarComponent from "../../../components/internal/common/SnackbarComponent";
import moment from "moment";
import { isAccessibleModule } from "../../../methods";

class InternalCustomersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCustomers: [],
      totalCustomersCount: 0,
      customerSearchData: [],
      allCategories: [],
      allSubscriptions: [],
      allStates: [],
      itemsWithStates: new Map(),
      itemsStateLoader: false,
      itemsStateError: "",
      customerOpertaion: null,
      selectedOption: null,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
      customerLoader: true,
      categoryLoader: true,
      allSubscriptionLoader: true,
      statesLoader: true,
      itemCategory: [],
      countryCodes: [],
      selectedFilter: "",
      prevLink: '',
      nextLink: '',
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  getOptionsForFormData = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        // "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "multipart/form-data",
      },
    };
  };
  componentDidMount() {
    if (isAccessibleModule(this.props?.user, "Customer Management")) {
      this.getItemCategoriesWithStates();
      // this.getAllCustomersList();
      this.fetchItemCategory();
    }
    this.trigger()
  }
  trigger = (value) => {
    if (value)
      console.log("triggered")
  }
  fetchItemCategory = () => {
    axios
      .get(`${APIROUTES.GET_ITEM_CATEGORY}?active=${true}`, this.getOptions(this.props.user))
      .then((response) => {
        this.setState({
          itemCategory: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getItemCategoriesWithStates = () => {
    const itemsWithStates = new Map();
    const itemsStateLoader = true;
    const itemsStateError = "";
    this.setState({
      itemsWithStates,
      itemsStateLoader,
      itemsStateError,
    });
    axios
      .get(
        `${APIROUTES.DAILY_RATE_TEMPLATE_DETAILS}?active=true`,
        this.getOptions(this.props.user)
      )
      .then((response) => {
        const { data } = response;
        for (let item of data) {
          itemsWithStates.set(item.category_name, {
            id: item.id,
            category_name: item.category_name,
            states: item.daily_rates_template.map((e) => ({
              id: e.state.id,
              name: e.state.state_name,
            })),
          });
        }
        this.setState({
          itemsWithStates,
          itemsStateLoader: false,
          itemsStateError: "",
        });
      })
      .catch((itemsStateError) => {
        this.setState({
          itemsWithStates: new Map(),
          itemsStateLoader: false,
          itemsStateError,
        });
      });
  };

  getAllCustomersList = (page = 0, search = "") => {
    console.log(page, search)
    this.setState({
      selectedFilter: "all"
    })
    const baseUrl =
      search === ""
        ? APIROUTES.CUSTOMERS_GET_REMOTE
        : APIROUTES.CUSTOMERS_SEARCH_REMOTE;

    const params =
      search === ""
        ? `?page=${page + 1}`
        : `?page=${page + 1}&search=${search}`;

    // if (search !== "") {
    this.setState({
      prevLink: search !== "" && null,
      nextLink: search !== "" && null,
    })
    // }
    axios
      .get(baseUrl + params, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          let allCustomersList = response.data?.results;
          if (allCustomersList?.length > 0) {
            const allCustomers = [];
            const customerSearchData = [];
            allCustomersList.map((doc) => {
              const singleCustomer = doc;
              const singleCustomerSearchData = [];
              singleCustomerSearchData.credentials = doc.credentials;
              singleCustomerSearchData.id = doc.id;
              singleCustomerSearchData.is_rejected = doc.is_rejected;
              singleCustomerSearchData.email = doc.credentials
                ? doc.credentials.email
                  ? doc.credentials.email
                  : ""
                : "";
              singleCustomerSearchData.customer_type = doc.customer_type
                ? doc.customer_type
                : "";
              singleCustomerSearchData.legal_name_of_buisness =
                doc.gst_information
                  ? doc.gst_information.legal_name_of_business
                    ? doc.gst_information.legal_name_of_business
                    : ""
                  : doc.pan_card_information
                    ? doc.pan_card_information.company_name
                      ? doc.pan_card_information.company_name
                      : ""
                    : "";
              singleCustomerSearchData.trade_name = doc.gst_information
                ? doc.gst_information.trade_name
                  ? doc.gst_information.trade_name
                  : ""
                : doc.pan_card_information
                  ? doc.pan_card_information.company_name
                    ? doc.pan_card_information.company_name
                    : ""
                  : "";
              singleCustomerSearchData.display_name =
                (doc.first_name ? doc.first_name : " ") +
                " " +
                (doc.last_name ? doc.last_name : " ");
              singleCustomerSearchData.credentials.is_new = this.checkIsNew(
                doc.credentials.created_at
              );
              singleCustomerSearchData.panGstinNumber = doc.gst_information
                ? doc.gst_information.gstin_number
                  ? doc.gst_information.gstin_number
                  : ""
                : doc.pan_card_information
                  ? doc.pan_card_information.pan_card_number
                    ? doc.pan_card_information.pan_card_number
                    : ""
                  : "";
              singleCustomerSearchData.customerHaveGstin =
                doc.gst_information !== null ? true : false;
              singleCustomerSearchData.company_name =
                doc.gst_information === null ?
                  doc.pan_card_information ? doc.pan_card_information.company_name : null : null

              // doc.gst_information !== null ? true : false;
              singleCustomer.credentials.is_new = this.checkIsNew(
                doc.credentials.created_at
              );
              singleCustomer.email = doc.credentials
                ? doc.credentials.email
                  ? doc.credentials.email
                  : ""
                : "";
              singleCustomer.display_name =
                (doc.first_name ? doc.first_name : " ") +
                " " +
                (doc.last_name ? doc.last_name : " ");
              allCustomers.push(singleCustomer);
              customerSearchData.push(singleCustomerSearchData);
              return 0;
            });

            this.setState({
              allCustomers: customerSearchData,
              totalCustomersCount: response.data?.count,
              customerSearchData,
              customerLoader: false,
            });
          } else {
            this.setState({
              allCustomers: [],
              totalCustomersCount: 0,
              customerSearchData: [],
              customerLoader: false,
            });
          }
        } else {
          this.setState({
            customerLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching data try again!",
            snackbarType: "warning",
          });
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          customerLoader: false,
          openSnackbar: true,
          snackbarMessage:
            "Something went wrong while fetching data try again!",
          snackbarType: "error",
        });
      });
  };

  getNewAndPendingCustomerList = (page, type) => {
    console.log(type)
    const baseUrl = APIROUTES.CUSTOMERS_SEARCH_REMOTE;

    const params = `?filter=${type}&page=${page + 1}`
    // search === ""
    //   ? `?page=${page + 1}`
    //   : `?page=${page + 1}&search=${search}`;
    this.setState({
      selectedFilter: type
    })
    axios
      .get(baseUrl + params, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          let allCustomersList = response.data?.results;
          if (allCustomersList?.length > 0) {
            const allCustomers = [];
            const customerSearchData = [];
            allCustomersList.map((doc) => {
              const singleCustomer = doc;
              const singleCustomerSearchData = [];
              singleCustomerSearchData.credentials = doc.credentials;
              singleCustomerSearchData.id = doc.id;
              singleCustomerSearchData.is_rejected = doc.is_rejected;
              singleCustomerSearchData.email = doc.credentials
                ? doc.credentials.email
                  ? doc.credentials.email
                  : ""
                : "";
              singleCustomerSearchData.customer_type = doc.customer_type
                ? doc.customer_type
                : "";
              singleCustomerSearchData.legal_name_of_buisness =
                doc.gst_information
                  ? doc.gst_information.legal_name_of_business
                    ? doc.gst_information.legal_name_of_business
                    : ""
                  : doc.pan_card_information
                    ? doc.pan_card_information.company_name
                      ? doc.pan_card_information.company_name
                      : ""
                    : "";
              singleCustomerSearchData.trade_name = doc.gst_information
                ? doc.gst_information.trade_name
                  ? doc.gst_information.trade_name
                  : ""
                : doc.pan_card_information
                  ? doc.pan_card_information.company_name
                    ? doc.pan_card_information.company_name
                    : ""
                  : "";
              singleCustomerSearchData.display_name =
                (doc.first_name ? doc.first_name : " ") +
                " " +
                (doc.last_name ? doc.last_name : " ");
              singleCustomerSearchData.credentials.is_new = this.checkIsNew(
                doc.credentials.created_at
              );
              singleCustomerSearchData.panGstinNumber = doc.gst_information
                ? doc.gst_information.gstin_number
                  ? doc.gst_information.gstin_number
                  : ""
                : doc.pan_card_information
                  ? doc.pan_card_information.pan_card_number
                    ? doc.pan_card_information.pan_card_number
                    : ""
                  : "";
              singleCustomerSearchData.customerHaveGstin =
                doc.gst_information !== null ? true : false;
              singleCustomer.credentials.is_new = this.checkIsNew(
                doc.credentials.created_at
              );
              singleCustomer.email = doc.credentials
                ? doc.credentials.email
                  ? doc.credentials.email
                  : ""
                : "";
              singleCustomer.display_name =
                (doc.first_name ? doc.first_name : " ") +
                " " +
                (doc.last_name ? doc.last_name : " ");
              allCustomers.push(singleCustomer);
              customerSearchData.push(singleCustomerSearchData);
              return 0;
            });

            this.setState({
              allCustomers: customerSearchData,
              totalCustomersCount: response.data?.count,
              customerSearchData,
              customerLoader: false,
            });
          } else {
            this.setState({
              allCustomers: [],
              totalCustomersCount: 0,
              customerSearchData: [],
              customerLoader: false,
            });
          }
        } else {
          this.setState({
            customerLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching data try again!",
            snackbarType: "warning",
          });
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          customerLoader: false,
          openSnackbar: true,
          snackbarMessage:
            "Something went wrong while fetching data try again!",
          snackbarType: "error",
        });
      });
  }

  getCustomerTypeCustomerList = (page = 1, filterType, customerType, searchText = "", newLink = 0) => {

    const baseUrl = APIROUTES.CUSTOMERS_SEARCH_REMOTE;
    console.log(customerType)
    const params =
      customerType.length !== 0
        ? filterType === "all"
          ? searchText === ""
            ? `?customer_type=${customerType}`
            : `?customer_type=${customerType}&search=${searchText}`
          : searchText === ""
            ? `?filter=${filterType}&customer_type=${customerType}`
            : `?filter=${filterType}&customer_type=${customerType}&search=${searchText}`
        : searchText === ""
          ? `?filter=${filterType}`
          : `?filter=${filterType}&search=${searchText}`
    console.log(newLink)
    const filteredLink = newLink === 0 ? baseUrl + params : newLink
    axios
      .get(filteredLink, this.getOptions(this.props.user))
      .then((response) => {
        if (response.status === 200) {
          let allCustomersList = response.data?.results;
          if (allCustomersList?.length > 0) {
            const allCustomers = [];
            const customerSearchData = [];
            allCustomersList.map((doc) => {
              const singleCustomer = doc;
              const singleCustomerSearchData = [];
              singleCustomerSearchData.credentials = doc.credentials;
              singleCustomerSearchData.id = doc.id;
              singleCustomerSearchData.is_rejected = doc.is_rejected;
              singleCustomerSearchData.email = doc.credentials
                ? doc.credentials.email
                  ? doc.credentials.email
                  : ""
                : "";
              singleCustomerSearchData.customer_type = doc.customer_type
                ? doc.customer_type
                : "";
              singleCustomerSearchData.legal_name_of_buisness =
                doc.gst_information
                  ? doc.gst_information.legal_name_of_business
                    ? doc.gst_information.legal_name_of_business
                    : ""
                  : doc.pan_card_information
                    ? doc.pan_card_information.company_name
                      ? doc.pan_card_information.company_name
                      : ""
                    : "";
              singleCustomerSearchData.trade_name = doc.gst_information
                ? doc.gst_information.trade_name
                  ? doc.gst_information.trade_name
                  : ""
                : doc.pan_card_information
                  ? doc.pan_card_information.company_name
                    ? doc.pan_card_information.company_name
                    : ""
                  : "";
              singleCustomerSearchData.display_name =
                (doc.first_name ? doc.first_name : " ") +
                " " +
                (doc.last_name ? doc.last_name : " ");
              singleCustomerSearchData.credentials.is_new = this.checkIsNew(
                doc.credentials.created_at
              );
              singleCustomerSearchData.panGstinNumber = doc.gst_information
                ? doc.gst_information.gstin_number
                  ? doc.gst_information.gstin_number
                  : ""
                : doc.pan_card_information
                  ? doc.pan_card_information.pan_card_number
                    ? doc.pan_card_information.pan_card_number
                    : ""
                  : "";
              singleCustomerSearchData.customerHaveGstin =
                doc.gst_information !== null ? true : false;
              singleCustomer.credentials.is_new = this.checkIsNew(
                doc.credentials.created_at
              );
              singleCustomer.email = doc.credentials
                ? doc.credentials.email
                  ? doc.credentials.email
                  : ""
                : "";
              singleCustomer.display_name =
                (doc.first_name ? doc.first_name : " ") +
                " " +
                (doc.last_name ? doc.last_name : " ");
              allCustomers.push(singleCustomer);
              customerSearchData.push(singleCustomerSearchData);
              return 0;
            });

            this.setState({
              allCustomers: customerSearchData,
              totalCustomersCount: response.data?.count,
              prevLink: response.data?.previous,
              nextLink: response.data?.next,
              customerSearchData,
              customerLoader: false,
            });
          } else {
            this.setState({
              allCustomers: [],
              totalCustomersCount: 0,
              prevLink: null,
              nextLink: null,
              customerSearchData: [],
              customerLoader: false,
            });
          }
        } else {
          this.setState({
            customerLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching data try again!",
            snackbarType: "warning",
          });
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          customerLoader: false,
          openSnackbar: true,
          snackbarMessage:
            "Something went wrong while fetching data try again!",
          snackbarType: "error",
        });
      });
  }
  checkIsNew = (date) => {
    let hours = moment().diff(moment(date), "hours");
    if (hours <= 24) {
      return true;
    } else {
      return false;
    }
  };
  handleVerifyGstinData = (gstinNumber) => {
    let gstinNumberToVerify = {
      gstin_number: gstinNumber,
    };
    return axios.post(
      APIROUTES.VERIFY_GST,
      gstinNumberToVerify,
      this.getOptions(this.props.user)
    );
  };

  handleCreateCustomer = (customerData) => {
    return axios.post(
      APIROUTES.POST_CUSTOMER,
      customerData,
      // this.getOptions(this.props.user)
      this.getOptionsForFormData(this.props.user)
    );
  };

  handleUpdateCustomer = (customerUpdateData, customerId) =>

    new Promise((resolve, reject) => {
      axios
        .put(
          `${APIROUTES.UPDATE_CUSTOMER}${customerId}/`,
          customerUpdateData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.getAllCustomersList();
          resolve(response);
        })
        .catch(reject);
    });

  handleCreateDealerOrDistributor = (dealerDistributorData) => {
    return axios.post(
      APIROUTES.POST_DEALER_DISTRIBUTOR_IN_CUSTOMER,
      dealerDistributorData,
      this.getOptions(this.props.user)
    );
  };

  handleCreateBrokerageItemCatWise = (data) => {
    const { POST_BROKERAGE_ITEMCAT: url } = APIROUTES;
    const { user } = this.props;
    // {
    //   "brokerage_amount": 0,
    //   "is_active": true,
    //   "item_category": 0,
    //   "customer": 0
    // }
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, this.getOptions(user))
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  handleCreateCustomerBuyer = (customerBuyerData) => {
    return axios.post(
      APIROUTES.CREATE_CUSTOMER_BUYER,
      customerBuyerData,
      this.getOptions(this.props.user)
    );
  };
  handleCreateCustomerConsignee = (customerConsigneeData) => {
    return axios.post(
      APIROUTES.CREATE_CUSTOMER_CONSIGNEE,
      customerConsigneeData,
      this.getOptions(this.props.user)
    );
  };
  getDealerDistributorData = (filterData) => {
    return axios.get(
      APIROUTES.GET_DEALER_DISTRIBUTOR + filterData,
      this.getOptions(this.props.user)
    );
  };
  fetchSearchCustomerOptions = (searchValue) => {
    return axios.get(
      `${APIROUTES.CUSTOMER_CLIENT_SEARCH}?search=${searchValue}`,
      this.getOptions(this.props.user)
    );
  };
  fetchSearchConsigneeCustomerOptions = (searchValue) => {
    return axios.get(
      `${APIROUTES.CUSTOMER_CLIENT_CONSIGNEE_SEARCH}?search=${searchValue}`,
      this.getOptions(this.props.user)
    );
  };
  getBrokerData = (filterData) => {
    return axios.get(
      APIROUTES.GET_BROKER + filterData,
      this.getOptions(this.props.user)
    );
  };
  getAllSubscriptionData = (customerId) => {
    return axios.get(
      `${APIROUTES.GET_ALL_CUSTOMER_SUBSCRIPTION}?id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  getAllDealers = () => {
    return axios.get(
      `${APIROUTES.CUSTOMERS_GET}?customer_type=DEALER
    `,
      this.getOptions(this.props.user)
    );
  };
  getAllDistributors = () => {
    return axios.get(
      `${APIROUTES.CUSTOMERS_GET}?customer_type=DISTRIBUTOR
    `,
      this.getOptions(this.props.user)
    );
  };
  getAllAllowedPaymentMethod = () => {
    return axios.get(
      `${APIROUTES.GET_ALL_ALLOWED_PAYMENT_METHOD}?active=${true}`,
      this.getOptions(this.props.user)
    );
  };
  fetchAllBankData = () => {
    return axios.get(`${APIROUTES.BANK_MASTER}?active=${true}`, this.getOptions(this.props.user));
  };
  fetchAllTdsData = () => {
    return axios.get(`${APIROUTES.TDS_RATE}?active=${true}`, this.getOptions(this.props.user));
  };
  fetchCustomerTypes = () => {
    return axios.get(`${APIROUTES.CUSTOMER_TYPES}?active=${true}`, this.getOptions(this.props.user));
  }
  fetchCustomerTags = () => {
    return axios.get(APIROUTES.CUSTOMER_TAGS, this.getOptions(this.props.user));
  }
  fetchSelectedCustomerData = (customerId) => {
    return axios.get(
      `${APIROUTES.CUSTOMERS_GET}${customerId}/`,
      this.getOptions(this.props.user)
    );
  };
  fetchCustomerAdditionalAddresses = (customerId) => {
    return axios.get(
      `${APIROUTES.CUSTOMER_ADDITIONAL_ADDRESSES}?id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchCustomerAllowedPaymentMethod = (customerId) => {
    return axios.get(
      `${APIROUTES.CUSTOMER_ALLOWED_PAYMENT_METHOD}?id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchCustomerBuyers = (customerId) => {
    return axios.get(
      `${APIROUTES.GET_CUSTOMER_BUYERS}?id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchCustomerConsignees = (customerId) => {
    return axios.get(
      `${APIROUTES.GET_CUSTOMER_CONSIGNEE}?id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchCustomersAllConsigneesList = (customerId) => {
    return axios.get(
      `${APIROUTES.GET_CUSTOMER_ALL_CONSIGNEE_LIST}?id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchCustomerAdditionalContacts = (customerId) => {
    return axios.get(
      `${APIROUTES.CUSTOMER_ADDITIONAL_CONTACT_PERSON}?customer-id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };

  fetchItemCategoryCustomer = (customerId) => {
    return axios.get(
      `${APIROUTES.BROKERAGE_ITEM_GET}?customer-id=${customerId}`,
      this.getOptions(this.props.user)
    );
  };

  handleCreateCustomerSubscription = (subscriptionData) => {
    return axios.post(
      APIROUTES.CREATE_CUSTOMER_SUBSCRIPTION,
      subscriptionData,
      this.getOptions(this.props.user)
    );
  };
  createCustomerAdditionalContactPerson = (additionalContactPersonData) => {
    return axios.post(
      APIROUTES.CUSTOMER_ADDITIONAL_CONTACT_PERSON,
      additionalContactPersonData,
      this.getOptions(this.props.user)
    );
  };
  editCustomerAdditionalContactPerson = (additionalContactPersonData, id) => {
    return axios.patch(
      `${APIROUTES.CUSTOMER_ADDITIONAL_CONTACT_PERSON}${id}/`,
      additionalContactPersonData,
      this.getOptions(this.props.user)
    );
  };
  createCustomerAllowedPaymentMethod = (allowedPaymentMethodData) => {
    return axios.post(
      APIROUTES.CUSTOMER_ALLOWED_PAYMENT_METHOD,
      allowedPaymentMethodData,
      this.getOptions(this.props.user)
    );
  };
  removeCustomerDealerDistributor = (dealerDistributorId) => {
    return axios.delete(
      `${APIROUTES.DELETE_CUSTOMER_DEALER_DISTRIBUTOR}${dealerDistributorId}/`,
      this.getOptions(this.props.user)
    );
  };
  removeCustomerSubscription = (subscriptionId) => {
    return axios.delete(
      `${APIROUTES.DELETE_CUSTOMER_SUBSCRIPTION}${subscriptionId}/`,
      this.getOptions(this.props.user)
    );
  };
  removeCustomerAdditionalContactPerson = (additionalContactPersonId) => {
    return axios.delete(
      `${APIROUTES.CUSTOMER_ADDITIONAL_CONTACT_PERSON}${additionalContactPersonId}/`,
      this.getOptions(this.props.user)
    );
  };
  removeCustomerAllowedPaymentMethod = (allowedPaymentMethodId) => {
    return axios.delete(
      `${APIROUTES.CUSTOMER_ALLOWED_PAYMENT_METHOD}${allowedPaymentMethodId}/`,
      this.getOptions(this.props.user)
    );
  };
  handleRemoveCustomerBuyer = (buyerId) => {
    return axios.delete(
      `${APIROUTES.DELETE_CUSTOMER_BUYER}${buyerId}/`,
      this.getOptions(this.props.user)
    );
  };
  handleRemoveCustomerConsignee = (consigneeId) => {
    return axios.delete(
      `${APIROUTES.DELETE_CUSTOMER_CONSIGNEE}${consigneeId}/`,
      this.getOptions(this.props.user)
    );
  };
  resetCustomerPassword = (changePasswordData) => {
    return axios.post(
      APIROUTES.CUSTOMER_RESET_PASSWORD,
      changePasswordData,
      this.getOptions(this.props.user)
    );
  };
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };
  fetchStateDataFromMaster = (countryId) => {
    return axios.get(
      `${APIROUTES.GET_STATES_LIST}?country-id=${countryId}`,
      this.getOptions(this.props.user)
    );
  };
  fetchCityDataFromMaster = (stateId) => {
    return axios.get(
      `${APIROUTES.GET_CITY_LIST}?state_id=${stateId}`,
      this.getOptions(this.props.user)
    );
  };
  deleteCustomer = (customerCredentialId, field) =>
    new Promise((resolve, reject) => {
      let url = field === 'reject'
        ? `${APIROUTES.DELETE_CUSTOMER}${customerCredentialId}/?is_rejected=${true}`
        : `${APIROUTES.DELETE_CUSTOMER}${customerCredentialId}/`
      axios
        .delete(url, this.getOptions(this.props.user))
        .then((response) => {
          this.getAllCustomersList();
          resolve(response);
        })
        .catch(reject);
    });
  removeCustomerAdditionalAddresses = (customerAddressId) => {
    return axios.delete(
      `${APIROUTES.CUSTOMER_ADDITIONAL_ADDRESSES}${customerAddressId}/`,
      this.getOptions(this.props.user)
    );
  };
  saveEditCustomerAddresses = (
    addressData,
    addressId,
    customerAddressOperation
  ) => {
    if (customerAddressOperation === "edit") {
      return axios.put(
        `${APIROUTES.CUSTOMER_ADDITIONAL_ADDRESSES}${addressId}/`,
        addressData,
        this.getOptions(this.props.user)
      );
    } else {
      return axios.post(
        APIROUTES.CUSTOMER_ADDITIONAL_ADDRESSES,
        addressData,
        this.getOptions(this.props.user)
      );
    }
  };

  fetchStatesAndCitiesFromPincode = (pincode) => {
    return axios
      .get(`${APIROUTES.GET_STATE_AND_CITY}${pincode}/`, this.getOptions(this.props.user))
  }

  postExtraPhones = (post) => {
    return axios
      .post(APIROUTES.CUSTOMER_EXTRA_PHONES, post, this.getOptions(this.props.user))
  }

  getExtraPhones = (id) => {
    return axios
      .get(`${APIROUTES.CUSTOMER_EXTRA_PHONES}?customer_id=${id}`, this.getOptions(this.props.user))
  }
  render() {
    const { user } = this.props;
    const { openSnackbar, snackbarType, snackbarMessage } = this.state;
    // if (this?.props?.isMobile) return <CustomerMobile {...this.props} />;
    // else 
    if (
      user.is_admin &&
      user.is_active &&
      this.props?.user?.accessibleModules?.find(
        (a) => a === "Customer Management"
      )
    ) {
      return (
        <>
          <Route
            exact
            path={["/internalCustomers/:mode", "/internalCustomers/:mode/:id"]}
            render={(props) => (
              <ManageCustomers
                {...props}
                {...this.state}
                {...this.props}
                getOptions={this.getOptions}
                trigger={this.trigger}
                handleVerifyGstinData={this.handleVerifyGstinData}
                handleCreateCustomer={this.handleCreateCustomer}
                handleCreateDealerOrDistributor={
                  this.handleCreateDealerOrDistributor
                }
                getNewAndPendingCustomerList={this.getNewAndPendingCustomerList}
                fetchStatesAndCitiesFromPincode={this.fetchStatesAndCitiesFromPincode}
                postExtraPhones={this.postExtraPhones}
                getExtraPhones={this.getExtraPhones}
                getDealerDistributorData={this.getDealerDistributorData}
                getBrokerData={this.getDealerDistributorData}
                fetchSearchCustomerOptions={this.fetchSearchCustomerOptions}
                fetchSearchConsigneeCustomerOptions={this.fetchSearchConsigneeCustomerOptions}
                handleCreateCustomerSubscription={
                  this.handleCreateCustomerSubscription
                }
                getAllSubscriptionData={this.getAllSubscriptionData}
                removeCustomerDealerDistributor={
                  this.removeCustomerDealerDistributor
                }
                removeCustomerSubscription={this.removeCustomerSubscription}
                handleUpdateCustomer={this.handleUpdateCustomer}
                resetCustomerPassword={this.resetCustomerPassword}
                deleteCustomer={this.deleteCustomer}
                fetchStateDataFromMaster={this.fetchStateDataFromMaster}
                fetchCityDataFromMaster={this.fetchCityDataFromMaster}
                fetchCustomerAdditionalContacts={
                  this.fetchCustomerAdditionalContacts
                }
                fetchItemCategoryCustomer={this.fetchItemCategoryCustomer}
                handleCreateBrokerageItemCatWise={
                  this.handleCreateBrokerageItemCatWise
                }
                createCustomerAdditionalContactPerson={
                  this.createCustomerAdditionalContactPerson
                }
                editCustomerAdditionalContactPerson={
                  this.editCustomerAdditionalContactPerson
                }
                removeCustomerAdditionalContactPerson={
                  this.removeCustomerAdditionalContactPerson
                }
                fetchCustomerAllowedPaymentMethod={
                  this.fetchCustomerAllowedPaymentMethod
                }
                removeCustomerAllowedPaymentMethod={
                  this.removeCustomerAllowedPaymentMethod
                }
                createCustomerAllowedPaymentMethod={
                  this.createCustomerAllowedPaymentMethod
                }
                fetchSelectedCustomerData={this.fetchSelectedCustomerData}
                getAllAllowedPaymentMethod={this.getAllAllowedPaymentMethod}
                getAllDealers={this.getAllDealers}
                getAllDistributors={this.getAllDistributors}
                fetchCustomerBuyers={this.fetchCustomerBuyers}
                fetchCustomerConsignees={this.fetchCustomerConsignees}
                fetchCustomersAllConsigneesList={
                  this.fetchCustomersAllConsigneesList
                }
                handleCreateCustomerBuyer={this.handleCreateCustomerBuyer}
                handleCreateCustomerConsignee={
                  this.handleCreateCustomerConsignee
                }
                handleRemoveCustomerBuyer={this.handleRemoveCustomerBuyer}
                handleRemoveCustomerConsignee={
                  this.handleRemoveCustomerConsignee
                }
                fetchCustomerAdditionalAddresses={
                  this.fetchCustomerAdditionalAddresses
                }
                removeCustomerAdditionalAddresses={
                  this.removeCustomerAdditionalAddresses
                }
                saveEditCustomerAddresses={this.saveEditCustomerAddresses}
                fetchAllBankData={this.fetchAllBankData}
                fetchAllTdsData={this.fetchAllTdsData}
                fetchCustomerTags={this.fetchCustomerTags}
                fetchCustomerTypes={this.fetchCustomerTypes}
              />
            )}
          />
          <Route
            exact
            path="/internalCustomers"
            render={(props) => (
              <CustomerWeb
                {...props}
                {...this.state}
                {...this.props}
                getAllCustomersList={this.getAllCustomersList}
                getNewAndPendingCustomerList={this.getNewAndPendingCustomerList}
                getCustomerTypeCustomerList={this.getCustomerTypeCustomerList}

              />
            )}
          />
          <SnackbarComponent
            open={openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={snackbarMessage}
            type={snackbarType}
          />
        </>
      );
    } else {
      return <AccessDenied />;
    }
  }
}
export default InternalCustomersContainer;
