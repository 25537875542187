import React, { useRef } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import Row from "./Row";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Box,
    TablePagination,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Button,
    TextField,
    InputAdornment,
    Chip,
    FormControl,
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
    Switch,
    InputLabel,
    FormHelperText,
    ThemeProvider,
    createMuiTheme
} from "@material-ui/core";
import {
    grey,
    secondaryGrey,
    successButtonBackground,
    drawerTextColor,
    secondaryBlueText,
    whiteSmoke,
    orange,
    earthBlue,
    drawerBackground,
    primaryColor
} from "../../../../constants/internal/colors";
import { hasUpdateAccess, pxToEm, UpperCaseText } from "../../../../methods";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { validationsItemTypesDetails, validationsItemTypesDetailsWithoutPackaging } from "./ItemCategoryValidationSchema";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import COLORS from "../../../../constants/internal/colors";
import Divider from "@material-ui/core/Divider";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ToolTipComponent from "../../common/ToolTipComponent";


const CustomCheckbox = withStyles({
    root: {
        color: secondaryBlueText,
        "&$checked": {
            color: secondaryBlueText,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: secondaryBlueText,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {},
        },
        MuiPickersDay: {
            day: {
                color: earthBlue,
            },
            daySelected: {
                backgroundColor: secondaryBlueText,
            },
            dayDisabled: {
                color: secondaryGrey,
            },
            current: {
                color: drawerBackground,
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: earthBlue,
            },
        },

    },
});

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        // margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: secondaryBlueText,
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "secondaryBlueText",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            checked={props.defaultChecked}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    deleteAndExpandActionStyle: { display: "flex" },
    sectionNameAndWeightStyles: {
        display: "flex",
        alignItems: "center",
        placeContent: "flex-start",
        justifyContent: "space-evenly",
    },
    inputWidth: {
        // maxWidth: 200,
        width: "10rem"
    },
    unitSelect: {
        width: "10rem",
        position: "relative"
    },
    helperText: {
        position: "absolute"
    },
    tableCellWidth: {
        minWidth: "10rem"
    },
    contentTitle: {
        color: grey,
        fontSize: pxToEm(16),
        fontWeight: "bold",
    },
    itemTypeMainDiv: {
        marginTop: 30,
    },
    tableContainer: {
        marginTop: 10,
        width: "100%",
        height: "calc(100vh - 400px)",
        overflow: "auto",
    },
    table: {
        width: "100%",
    },
    tableHead: {
        width: "10rem",
        color: grey,
        fontSize: pxToEm(13),
        fontWeight: "bold",
    },
    actionsDiv: {
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "row",
    },
    addAndSaveButton: {
        display: "flex",
        alignItems: "center",
        margin: "15px 0px 0px 0px",
        justifyContent: "space-between",
    },
    addNewButton: {
        width: "181px",
        height: "43px",
        border: `1px dashed ${secondaryGrey}`,
        borderRadius: "4px",
        opacity: "1",
        color: grey,
        fontSize: pxToEm(13),
        fontWeight: "bold",
    },
    saveCancelButton: {
        display: "flex",
        placeSelf: "strech",
    },
    generalDetailsDisplaySection: {
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
        justifyContent: "space-between",
        width: "100%",
    },
    dataDisplay: { display: "flex", flexDirection: "column", width: "100%" },
    dataFields: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
        padding: "10px 0px 10px 0px",
    },
    titleLabel: {
        fontSize: pxToEm(14),
        color: grey,
        fontWeight: "bold",
        width: "50%",
        marginBottom: "14px"
    },
    // MuiDialogContent: {
    //     styleOverrides: {
    //         root: {
    //             padding: "16px 0px",
    //         },
    //     },
    // },
    helperText: {
        position: 'absolute',
        top: pxToEm(72)
    },
    formControl: {
        width: "45%",
    },
    mainDiv: {
        width: "100%",
        height: "100%",
        paddingBottom: "30px",
        overflowY: "scroll",
    },
    saveButton: {
        backgroundColor: successButtonBackground,
        color: drawerTextColor,
        marginRight: 10,
    },
    iconContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        color: grey,
    },
    editTextIcon: {
        borderRadius: 100,
        padding: 2,
        marginLeft: "10px",
        width: "70px",
        display: "flex",
        backgroundColor: whiteSmoke,
        color: grey,
        cursor: "pointer",
    },
    editText: {
        flex: 1,
        marginLeft: 5,
        textAlign: "left",
    },
    icon: {
        height: 18,
        width: 18,
        margin: "0px 5px 0px 5px",
        color: grey,
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            backgroundColor: 'rgba(255,255,255,1)',
            color: '#000',
            marginBottom: 10,
            // borderRadius: 20,
        }
    },
    dialogText: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: pxToEm(14),
        fontWeight: '500',
        alignItems: "center"
    },
    editPrimaryBackground: {
        backgroundColor: primaryColor,
        color: drawerTextColor,
        fontSize: pxToEm(15),
        margin: "0px 10px 0px 20px",
    },
    primaryBackground: {
        // backgroundColor: primaryColor,
        color: drawerTextColor,
        fontSize: pxToEm(15),
        margin: "0px 10px 0px 20px",
    },
});
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
};
const styles = {
    chips: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",
    },
    chip: {
        margin: 2,
    },
    dialogPaper: {
        //minHeight: '80vh',
        //maxHeight: '80vh',
        minWidth: "80%"
    },
};

export default function ItemDialog(props) {
    const { open, record, handleCloseItemTypes, allTagsData, index, allUnits, allItemSize, allItemNature, Mousehover,
        scroll, ClickedRateDiffBox, identifier } = props
    const classes = useStyles();
    const [row, setRow] = React.useState({})
    const [edit, setEdit] = React.useState(false)

    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [packingError, setPackingError] = React.useState(false);
    const [packingErrMsg, setPackingErrMsg] = React.useState("");
    const [tags, selectedTags] = React.useState(allTagsData.filter((f) => props.tags?.some((a) => a?.tag_name === f?.tag_name)));

    React.useEffect(() => {
        setRow(record)
    }, [])

    const handleEdit = () => {
        setEdit(true);
        handleSetItemTypeOperation("edit");
    }

    const handleDialogCancel = () => {
        handleCloseItemTypes();
        setEdit(false);
    }

    const handleSetItemTypeOperation = (ops) => {
        const currentRow = { ...row, operation: ops };
        setRow(currentRow)
    }

    const handleUpdateItemData = (dataToBeUpdated) => {
        if (dataToBeUpdated.operation == "edit") {
            props.handleItemAndSectionDataUpdate(dataToBeUpdated);
            handleCloseItemTypes();
            setEdit(false);
        } else if (dataToBeUpdated.operation == "add") {
            props.handleItemAndSectionDataSave(dataToBeUpdated);
            handleCloseItemTypes();
            setEdit(false);
        }
    }

    const handleSectionError = (sectionWeight) => {
        console.log(props.itemConsiderPacking)
        setError(false);
        setErrorMsg("");
        if (props.itemConsiderPacking) {
            console.log("m i here")
            if (sectionWeight <= 0) {
                setError(true);
                setErrorMsg("Invalid input")
            }
        }
    }

    const handlePackingWeightError = (packingWeight) => {
        setPackingError(false);
        setPackingErrMsg("");
        if (props.itemConsiderPacking) {
            if (packingWeight <= 0) {
                setPackingError(true);
                setPackingErrMsg("Invalid input")
            }
        }
    }

    const handleChange = (index, name, identifier, sectionIndex) => (event) => {
        if (identifier === "addNewData") {
            if (name === "section_name" || name === "section_weight") {
                let sectionDataRef = row["sections"][0];
                let sectionData = (sectionDataRef[name] = event.target.value)
                setRow({
                    ...row,
                });
            } else {
                setRow({
                    ...row,
                    [name]: ['item_code'].includes(name)
                        ? UpperCaseText(event.target.value)
                        : name === 'is_active'
                            ? event.target.checked
                            : name === 'valid_from'
                                ? moment(event).format("YYYY-MM-DD")
                                : event.target.value,
                });
            }
        } else {
            if (name === "section_name" || name === "section_weight") {
                let selectedRow = row //rows.find((f) => f.id === index);
                if (selectedRow.sections.length !== 0) {
                    let sectionDataRef = selectedRow["sections"][0];
                    let sectionData = (sectionDataRef[name] = event.target.value);
                    // setRows([...rows]);
                    setRow(selectedRow)
                }
            } else if (name === "tags") {
                //const updatedRowsValues = [...rows];
                let selectedTagRow = row; //updatedRowsValues.find((f) => f.id === index);
                let selectedValues = event.target.value;
                console.log(selectedValues, Array.isArray(selectedValues))
                let tagValues = []
                !Array.isArray(selectedValues) &&
                    tagValues.push({
                        id: selectedValues.id,
                        tag: selectedValues.id,
                        tag_name: selectedValues?.tag_name,
                        item: selectedTagRow?.id,
                    })
                console.log(tagValues)
                selectedTagRow["tags"] = tagValues;
                setRow(selectedTagRow);
            } else {
                setRow({
                    ...row,
                    [name]: ['item_code'].includes(name)
                        ? UpperCaseText(event.target.value)
                        : name === 'is_active'
                            ? event.target.checked
                            : name === 'valid_from'
                                ? moment(event).format("YYYY-MM-DD")
                                : event.target.value,
                });
            }
        }
    };


    return (
        <>
            {
                row?.operation != null ?
                    <Dialog
                        open={open}
                        aria-labelledby="simple-dialog-title"
                        //aria-describedby="scroll-dialog-description"
                        classes={{ paper: classes?.dialogPaper }}
                        fullWidth={true}
                    >
                        <DialogTitle id="scroll-dialog-title">{row?.operation === "add" ? "Add New Item" : "Edit Item"}</DialogTitle>
                        <DialogContent dividers={scroll === 'paper'} style={{ overflow: "hidden" }}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                //ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                {row?.operation != "add" ?
                                    <div>
                                        <div>
                                            <div>
                                                <Formik
                                                    initialValues={{
                                                        item_name: row?.item_name || "",
                                                        item_size: row?.item_size || "",
                                                        item_code: row?.item_code || "",
                                                        hsn_code: row?.hsn_code || "",
                                                        rate_difference: row?.rate_difference || "",
                                                        discount_amount: row?.discount_amount || "",
                                                        unit: row?.unit || "",
                                                        items_nature: row?.items_nature || "",
                                                        tags: row?.tags || "",
                                                        section_name: row?.sections[0]?.section_name || "",
                                                        section_weight: row?.sections[0]?.section_weight || "",
                                                        packing_weight: row?.packing_weight || "",
                                                        tolerance_upto_1mts: row?.tolerance_upto_1mts || "",
                                                        tolerance_from_1to5mts: row?.tolerance_from_1to5mts || "",
                                                        tolerance_above_5mts: row?.tolerance_above_5mts || "",
                                                        valid_from: row?.valid_from || "",
                                                    }}
                                                    enableReinitialize={true}
                                                    validationSchema={props.itemConsiderPacking ? validationsItemTypesDetails : validationsItemTypesDetailsWithoutPackaging}
                                                    validateOnChange={false}
                                                    validateOnBlur={false}
                                                    onSubmit={() => {
                                                        handleUpdateItemData(row, index)
                                                        handleSetItemTypeOperation("view", index)
                                                    }}
                                                >
                                                    {(formik) => (
                                                        <Form>
                                                            <div style={{ maxHeight: "500px", height: "500px", overflowY: "auto" }}>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM NAME*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (

                                                                        <TextField
                                                                            type="text"
                                                                            variant="outlined"
                                                                            //name="category_name"
                                                                            className={classes.formControl}
                                                                            label="ITEM NAME"
                                                                            value={row.item_name}
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "item_name", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "item_name",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.item_name && formik.touched.item_name) ? String(formik.errors.item_name) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.item_name &&
                                                                                formik.touched.item_name
                                                                            )}
                                                                        />)
                                                                        : (
                                                                            row.item_name
                                                                        )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM CODE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <TextField
                                                                            type="text"
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            value={row.item_code}
                                                                            label="ITEM CODE"
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "item_code", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "item_code",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.item_code && formik.touched.item_code) ? String(formik.errors.item_code) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.item_code &&
                                                                                formik.touched.item_code
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        row.item_code
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM SIZE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl size="small" variant="outlined" error={Boolean(
                                                                            formik.errors.item_size &&
                                                                            formik.touched.item_size
                                                                        )}>
                                                                            <InputLabel
                                                                                htmlFor="outlined-item-size"
                                                                            >
                                                                                ITEM SIZE
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="outlined-item-size"
                                                                                disabled={
                                                                                    row?.operation === "edit" || row?.operation === "add"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                variant="outlined"
                                                                                label="ITEM SIZE"
                                                                                style={{ width: 190 }}
                                                                                MenuProps={MenuProps}
                                                                                value={row?.item_size || ""}
                                                                                onChange={
                                                                                    (e) => {
                                                                                        handleChange(index, "item_size", identifier)(e)
                                                                                        formik.setFieldValue(
                                                                                            "item_size",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                            >
                                                                                {allItemSize.length > 0
                                                                                    ? allItemSize.map((data) => (
                                                                                        <MenuItem value={data.id}>{data.size}</MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.item_size && formik.touched.item_size) ? String(formik.errors.item_size) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        allItemSize.map((e) => {
                                                                            if (e.id == row?.item_size) return e.size
                                                                        })
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        HSN CODE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (

                                                                        <TextField
                                                                            type="number"
                                                                            variant="outlined"
                                                                            //name="category_name"
                                                                            className={classes.formControl}
                                                                            label="HSN CODE"
                                                                            value={row.hsn_code}
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "hsn_code", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "hsn_code",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.hsn_code && formik.touched.hsn_code) ? String(formik.errors.hsn_code) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.hsn_code &&
                                                                                formik.touched.hsn_code
                                                                            )}
                                                                        />)
                                                                        : (
                                                                            row.hsn_code
                                                                        )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        RATE DIFFERENCE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <TextField
                                                                            type="number"
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            value={row.rate_difference}
                                                                            label="RATE DIFFERENCE"
                                                                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onKeyPress={(event) => {
                                                                                if (event?.key === '+' || event?.key === '=') {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "rate_difference", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "rate_difference",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">₹</InputAdornment>
                                                                                ),
                                                                            }}
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.rate_difference && formik.touched.rate_difference) ? String(formik.errors.rate_difference) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.rate_difference &&
                                                                                formik.touched.rate_difference
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                            ₹  {Number(row?.rate_difference)}
                                                                            <Mousehover
                                                                                content={<ClickedRateDiffBox
                                                                                    data={row?.rate_difference_logs}
                                                                                />}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        VALID FROM*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <ThemeProvider theme={materialTheme}>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker
                                                                                    name="valid_from"
                                                                                    size="small"
                                                                                    inputVariant="outlined"
                                                                                    label="Effective From"
                                                                                    format="dd/MM/yyyy"
                                                                                    style={{ width: 190, marginBottom: "14px" }}
                                                                                    //disablePast={new Date(row.valid_from)}
                                                                                    // minDate={row?.valid_from
                                                                                    //     ? new Date(row.valid_from)
                                                                                    //     : new Date()}
                                                                                    minDate={new Date(row.valid_from)}
                                                                                    value={!row.valid_from ? null : new Date(row.valid_from)}
                                                                                    helperText={(formik.errors.valid_from && formik.touched.valid_from) ? String(formik.errors.valid_from) : " "}
                                                                                    onChange={
                                                                                        (e) => {
                                                                                            handleChange(index, "valid_from", identifier)(e)
                                                                                        }}
                                                                                    placeholder="e.g.: DD/MM/YYYY"
                                                                                    InputAdornmentProps={{ position: "end", }}
                                                                                    InputProps={{
                                                                                        style: {
                                                                                            paddingRight: 0,
                                                                                        },
                                                                                        readOnly: true
                                                                                    }}
                                                                                    error={Boolean(
                                                                                        formik.errors.valid_from &&
                                                                                        formik.touched.valid_from
                                                                                    )}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </ThemeProvider>)
                                                                        :
                                                                        (!row.valid_from ? null : moment(row.valid_from).format("DD MMM YYYY"))
                                                                    }
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        UNIT*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl size="small" variant="outlined" error={Boolean(
                                                                            formik.errors.unit &&
                                                                            formik.touched.unit
                                                                        )}>
                                                                            <InputLabel
                                                                                htmlFor="outlined-unit"
                                                                            >
                                                                                UNIT
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="outlined-unit"
                                                                                disabled={
                                                                                    row?.operation === "edit" || row?.operation === "add"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                variant="outlined"
                                                                                label="UNIT"
                                                                                style={{ width: 190 }}
                                                                                MenuProps={MenuProps}
                                                                                value={row?.unit || ""}
                                                                                onChange={
                                                                                    (e) => {
                                                                                        handleChange(index, "unit", identifier)(e)
                                                                                        formik.setFieldValue(
                                                                                            "unit",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                            >
                                                                                {allUnits.length > 0
                                                                                    ? allUnits.map((data) => (
                                                                                        <MenuItem value={data.id}>{data.unit_code}</MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.unit && formik.touched.unit) ? String(formik.errors.unit) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        allUnits.map((e) => {
                                                                            if (e.id == row?.unit) return e.unit_code
                                                                        })
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM NATURE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            size="small"
                                                                            error={Boolean(
                                                                                formik.errors.items_nature &&
                                                                                formik.touched.items_nature
                                                                            )}
                                                                        >
                                                                            <InputLabel
                                                                                htmlFor="outlined-items-nature"
                                                                            >
                                                                                ITEM NATURE
                                                                            </InputLabel>
                                                                            <Select
                                                                                label="ITEM NATURE"
                                                                                id="outlined-items-nature"
                                                                                variant="outlined"
                                                                                style={{ width: 190 }}

                                                                                MenuProps={MenuProps}
                                                                                size="small"
                                                                                value={row?.items_nature || ""}
                                                                                onChange={(e) => {
                                                                                    handleChange(index, "items_nature", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "items_nature",
                                                                                        e.target.value
                                                                                    )
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        height: "0px"
                                                                                    }
                                                                                }}
                                                                                helperText={
                                                                                    formik.errors.items_nature &&
                                                                                    formik.touched.items_nature &&
                                                                                    String(formik.errors.items_nature)
                                                                                }
                                                                            >
                                                                                {allItemNature.length > 0
                                                                                    ? allItemNature.map((data, itemNatureIndex) => (
                                                                                        <MenuItem value={data.item_nature} key={itemNatureIndex}>
                                                                                            {data.item_nature}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.items_nature && formik.touched.items_nature) ? String(formik.errors.items_nature) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        row?.items_nature
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        TAGS
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <FormControl variant="outlined" size="small">
                                                                            <InputLabel
                                                                                htmlFor="outlined-items-nature"
                                                                            >
                                                                                TAGS
                                                                            </InputLabel>
                                                                            <Select
                                                                                disabled={
                                                                                    row?.operation === "edit" || row?.operation === "add"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                variant="outlined"
                                                                                style={{ width: 190 }}

                                                                                // multiple
                                                                                label="TAGS"
                                                                                value={tags && tags?.length > 0 ? tags[0] : tags}
                                                                                MenuProps={MenuProps}
                                                                                size="small"
                                                                                onChange={(e) => {
                                                                                    selectedTags(e.target.value);
                                                                                    handleChange(index, "tags", identifier)(e);
                                                                                }}
                                                                            // renderValue={(selected) => (
                                                                            //     <div style={styles.chips}>
                                                                            //         {selected.map((value, index) => {
                                                                            //             let data = allTagsData.find(
                                                                            //                 (tagsData) => tagsData.tag_name === value.tag_name
                                                                            //             );

                                                                            //             return (
                                                                            //                 <Chip
                                                                            //                     size="small"
                                                                            //                     key={data.id}
                                                                            //                     label={data?.tag_name || ""}
                                                                            //                     style={styles.chip}
                                                                            //                 />
                                                                            //             );
                                                                            //         })}
                                                                            //     </div>
                                                                            // )}
                                                                            >
                                                                                {allTagsData && allTagsData.length > 0
                                                                                    ? allTagsData.map((allTagsData, index) => (
                                                                                        <MenuItem key={index} value={allTagsData}>
                                                                                            {console.log("tags", tags)}
                                                                                            {/* <CustomCheckbox
                                                                                                checked={tags?.some(
                                                                                                    (t) => t.tag_name === allTagsData.tag_name
                                                                                                )}
                                                                                            />
                                                                                            <ListItemText primary={allTagsData.tag_name} /> */}
                                                                                            {allTagsData?.tag_name}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{" "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        row.tags.length > 0 ? row.tags[0]?.tag_name : row.tags[0]?.tag_name
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        SECTION NAME*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl variant="outlined" size="small" error={Boolean(
                                                                            formik.errors.section_name &&
                                                                            formik.touched.section_name
                                                                        )}>
                                                                            <InputLabel
                                                                                htmlFor="outlined-unit"
                                                                            >
                                                                                SECTION NAME
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="outlined-unit"
                                                                                variant="outlined"
                                                                                label="SECTION NAME"
                                                                                style={{ width: 190 }}

                                                                                MenuProps={MenuProps}
                                                                                size="small"
                                                                                value={row.sections[0]?.section_name}
                                                                                onChange={
                                                                                    (e) => {
                                                                                        handleChange(index, "section_name", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "section_name",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                            >
                                                                                {allUnits.length > 0
                                                                                    ? allUnits.map((data) => (
                                                                                        <MenuItem value={data.unit_code}>{data.unit_code}</MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.section_name && formik.touched.section_name) ? String(formik.errors.section_name) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        row.sections[0]?.section_name
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        SECTION WEIGHT*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <TextField
                                                                            label="SECTION WEIGHT"
                                                                            variant="outlined"
                                                                            //className={classes.formControl}
                                                                            style={{ width: "45%", marginBottom: "14px" }}
                                                                            value={row.sections[0]?.section_weight}
                                                                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onChange={(e) => {
                                                                                handleSectionError(e.target.value)
                                                                                handleChange(index, "section_weight", identifier, 0)(e)
                                                                                formik.setFieldValue(
                                                                                    "section_weight",
                                                                                    e.target.value
                                                                                )
                                                                            }}
                                                                            size="small"
                                                                            helperText={row.sections[0]?.section_weight ? errorMsg :
                                                                                (formik.errors.section_weight && formik.touched.section_weight) ?
                                                                                    String(formik.errors.section_weight) : ""
                                                                            }
                                                                            onKeyPress={(event) => {
                                                                                if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: { min: props.itemConsiderPacking ? 1 : 0 },
                                                                                endAdornment: <InputAdornment position="end">MTS</InputAdornment>,
                                                                            }}
                                                                            error={error || Boolean(
                                                                                formik.errors.section_weight &&
                                                                                formik.touched.section_weight
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        `${row.sections[0]?.section_weight} MTS`
                                                                    )}
                                                                </div>
                                                                {props.itemConsiderPacking ?
                                                                    <>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                PACKING WEIGHT*
                                                                            </Typography>

                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="PACKING WEIGHT"
                                                                                    variant="outlined"
                                                                                    //className={classes.formControl}
                                                                                    style={{ width: "45%", marginBottom: "14px" }}
                                                                                    value={row?.packing_weight}
                                                                                    defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handlePackingWeightError(e.target.value);
                                                                                        handleChange(index, "packing_weight", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "packing_weight",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    InputProps={{
                                                                                        inputProps: { min: props.itemConsiderPacking ? 1 : 0 },
                                                                                        endAdornment: <InputAdornment position="end">MTS</InputAdornment>,
                                                                                    }}
                                                                                    size="small"
                                                                                    helperText={row?.packing_weight ? packingErrMsg :
                                                                                        (formik.errors.packing_weight && formik.touched.packing_weight) ? String(formik.errors.packing_weight) : " "
                                                                                    }
                                                                                    error={packingError || Boolean(
                                                                                        formik.errors.packing_weight &&
                                                                                        formik.touched.packing_weight
                                                                                    )}
                                                                                />
                                                                            ) : (row?.packing_weight &&
                                                                                `${row?.packing_weight} MTS`
                                                                            )}
                                                                        </div>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                TOLERANCE UPTO 1 MTS*
                                                                            </Typography>
                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="TOL. UPTO 1 MTS"
                                                                                    variant="outlined"
                                                                                    className={classes.formControl}
                                                                                    value={row.tolerance_upto_1mts}
                                                                                    //defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handleChange(index, "tolerance_upto_1mts", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "tolerance_upto_1mts",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                                    }}
                                                                                    helperText={
                                                                                        (formik.errors.tolerance_upto_1mts && formik.touched.tolerance_upto_1mts) ? String(formik.errors.tolerance_upto_1mts) : " "
                                                                                    }
                                                                                    error={Boolean(
                                                                                        formik.errors.tolerance_upto_1mts &&
                                                                                        formik.touched.tolerance_upto_1mts
                                                                                    )}
                                                                                />
                                                                            ) : (row?.tolerance_upto_1mts &&
                                                                                `${row?.tolerance_upto_1mts} %`
                                                                            )}
                                                                        </div>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                TOLERANCE UPTO 5 MTS*
                                                                            </Typography>
                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="TOL. UPTO 5 MTS"
                                                                                    variant="outlined"
                                                                                    className={classes.formControl}
                                                                                    value={row?.tolerance_from_1to5mts}
                                                                                    //defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handleChange(index, "tolerance_from_1to5mts", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "tolerance_from_1to5mts",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                                    }}
                                                                                    helperText={
                                                                                        (formik.errors.tolerance_from_1to5mts && formik.touched.tolerance_from_1to5mts) ? String(formik.errors.tolerance_from_1to5mts) : " "
                                                                                    }
                                                                                    error={Boolean(
                                                                                        formik.errors.tolerance_from_1to5mts &&
                                                                                        formik.touched.tolerance_from_1to5mts
                                                                                    )}
                                                                                />
                                                                            ) : (row?.tolerance_from_1to5mts &&
                                                                                `${row?.tolerance_from_1to5mts} %`
                                                                            )}
                                                                        </div>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                TOLERANCE ABOVE 5 MTS*
                                                                            </Typography>
                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="TOL.ABOVE 5 MTS"
                                                                                    variant="outlined"
                                                                                    className={classes.formControl}
                                                                                    value={row?.tolerance_above_5mts}
                                                                                    // defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handleChange(index, "tolerance_above_5mts", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "tolerance_above_5mts",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                                    }}
                                                                                    helperText={
                                                                                        (formik.errors.tolerance_above_5mts && formik.touched.tolerance_above_5mts) ? String(formik.errors.tolerance_above_5mts) : " "
                                                                                    }
                                                                                    error={Boolean(
                                                                                        formik.errors.tolerance_above_5mts &&
                                                                                        formik.touched.tolerance_above_5mts
                                                                                    )}
                                                                                />
                                                                            ) : (row?.tolerance_above_5mts &&
                                                                                `${row?.tolerance_above_5mts} %`
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                    : <></>}
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ACTIVE
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <IOSSwitch
                                                                            checked={(identifier === "addNewData" && row.is_active === undefined)
                                                                                ? true
                                                                                : Boolean(row.is_active)
                                                                            }
                                                                            //checked={Boolean(row.is_active)}
                                                                            onChange={(e) => handleChange(index, "is_active", identifier)(e)}
                                                                            name="is_active"
                                                                        />
                                                                    )
                                                                        :
                                                                        (row.is_active
                                                                            ? (<CheckCircle style={{ color: successButtonBackground }} />)
                                                                            : (<Cancel style={{ color: orange }} />)
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <DialogActions>
                                                                    {console.log(props)}
                                                                    <Button onClick={handleDialogCancel}>Cancel</Button>
                                                                    {
                                                                        edit ?
                                                                            <Button type="submit" disabled={error || packingError} style={{ backgroundColor: (error || packingError) ? COLORS.grey : primaryColor }} className={classes.primaryBackground}>Update</Button> : <></>
                                                                    }
                                                                    {
                                                                        row?.operation != "add" && !edit ?
                                                                            <ToolTipComponent
                                                                                title="You have no edit permission on this widget"
                                                                                condition={!hasUpdateAccess(props.user, 'item_categories')}
                                                                            >
                                                                                <Button
                                                                                    onClick={handleEdit}
                                                                                    className={classes.editPrimaryBackground}
                                                                                    disabled={!hasUpdateAccess(props.user, 'item_categories')}
                                                                                    style={{
                                                                                        color: !hasUpdateAccess(props.user, 'item_categories') && 'rgba(0,0,0,0.26)',
                                                                                        backgroundColor: !hasUpdateAccess(props.user, 'item_categories') && 'rgba(0,0,0,0.26)',
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Button>
                                                                            </ToolTipComponent>
                                                                            :
                                                                            <></>
                                                                    }

                                                                </DialogActions>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    (row?.operation === "add") &&
                                    <div>
                                        <div>
                                            <div>
                                                <Formik
                                                    initialValues={{
                                                        item_name: row?.item_name || "",
                                                        item_size: row?.item_size || "",
                                                        item_code: row?.item_code || "",
                                                        hsn_code: row?.hsn_code || "", rate_difference: row?.rate_difference || "",
                                                        discount_amount: row?.discount_amount || "",
                                                        unit: row?.unit || "",
                                                        items_nature: row?.items_nature || "",
                                                        tags: row?.tags || "",
                                                        section_name: row?.sections[0]?.section_name || "",
                                                        section_weight: row?.sections[0]?.section_weight || "",
                                                        packing_weight: row?.packing_weight || "",
                                                        tolerance_upto_1mts: row?.tolerance_upto_1mts || "",
                                                        tolerance_from_1to5mts: row?.tolerance_from_1to5mts || "",
                                                        tolerance_above_5mts: row?.tolerance_above_5mts || "",
                                                        valid_from: row?.valid_from || "",
                                                    }}
                                                    enableReinitialize={true}
                                                    validationSchema={props?.itemConsiderPacking ? validationsItemTypesDetails : validationsItemTypesDetailsWithoutPackaging}
                                                    validateOnChange={false}
                                                    validateOnBlur={false}
                                                    onSubmit={() => {
                                                        handleUpdateItemData(row)
                                                    }}
                                                >
                                                    {(formik) => (
                                                        <Form>
                                                            <div style={{ maxHeight: "500px", height: "500px", overflowY: "auto" }}>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM NAME*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (

                                                                        <TextField
                                                                            type="text"
                                                                            variant="outlined"
                                                                            //name="category_name"
                                                                            className={classes.formControl}
                                                                            label="ITEM NAME"
                                                                            value={row.item_name}
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "item_name", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "item_name",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.item_name && formik.touched.item_name) ? String(formik.errors.item_name) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.item_name &&
                                                                                formik.touched.item_name
                                                                            )}
                                                                        />)
                                                                        : (
                                                                            row.item_name
                                                                        )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM CODE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <TextField
                                                                            type="text"
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            value={row.item_code}
                                                                            label="ITEM CODE"
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "item_code", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "item_code",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.item_code && formik.touched.item_code) ? String(formik.errors.item_code) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.item_code &&
                                                                                formik.touched.item_code
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        row.item_code
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM SIZE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl size="small" variant="outlined" error={Boolean(
                                                                            formik.errors.item_size &&
                                                                            formik.touched.item_size
                                                                        )}>
                                                                            <InputLabel
                                                                                htmlFor="outlined-item_size"
                                                                            >
                                                                                ITEM SIZE
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="outlined-item_size"
                                                                                disabled={
                                                                                    row?.operation === "edit" || row?.operation === "add"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                variant="outlined"
                                                                                label="ITEM SIZE"
                                                                                style={{ width: 190 }}
                                                                                MenuProps={MenuProps}
                                                                                value={row?.item_size || ""}
                                                                                onChange={
                                                                                    (e) => {
                                                                                        handleChange(index, "item_size", identifier)(e)
                                                                                        formik.setFieldValue(
                                                                                            "item_size",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                            >
                                                                                {allItemSize.length > 0
                                                                                    ? allItemSize.map((data) => (
                                                                                        <MenuItem value={data.id}>{data.size}</MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.item_size && formik.touched.item_size) ? String(formik.errors.item_size) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        allItemSize.map((e) => {
                                                                            if (e.id == row?.item_size) return e.size
                                                                        })
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        HSN CODE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (

                                                                        <TextField
                                                                            type="number"
                                                                            variant="outlined"
                                                                            //name="category_name"
                                                                            className={classes.formControl}
                                                                            label="HSN CODE"
                                                                            value={row.hsn_code}
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "hsn_code", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "hsn_code",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.hsn_code && formik.touched.hsn_code) ? String(formik.errors.hsn_code) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.hsn_code &&
                                                                                formik.touched.hsn_code
                                                                            )}
                                                                        />)
                                                                        : (
                                                                            row.hsn_code
                                                                        )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        RATE DIFFERENCE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <TextField
                                                                            type="number"
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            value={row.rate_difference}
                                                                            label="RATE DIFFERENCE"
                                                                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onKeyPress={(event) => {
                                                                                if (event?.key === '+' || event?.key === '=') {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            onChange={
                                                                                (e) => {
                                                                                    handleChange(index, "rate_difference", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "rate_difference",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            }
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">₹</InputAdornment>
                                                                                ),
                                                                            }}
                                                                            size="small"
                                                                            helperText={
                                                                                (formik.errors.rate_difference && formik.touched.rate_difference) ? String(formik.errors.rate_difference) : " "
                                                                            }
                                                                            error={Boolean(
                                                                                formik.errors.rate_difference &&
                                                                                formik.touched.rate_difference
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                            ₹  {Number(row?.rate_difference)}
                                                                            <Mousehover
                                                                                content={<ClickedRateDiffBox
                                                                                    data={row?.rate_difference_logs}
                                                                                />}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        VALID FROM*
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <ThemeProvider theme={materialTheme}>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                {console.log(row?.valid_from)}
                                                                                <KeyboardDatePicker
                                                                                    name="valid_from"
                                                                                    size="small"
                                                                                    inputVariant="outlined"
                                                                                    label="Effective From"
                                                                                    format="dd/MM/yyyy"
                                                                                    style={{ width: 190, marginBottom: "14px" }}
                                                                                    //disablePast={new Date(row.valid_from)}
                                                                                    // minDate={row?.valid_from
                                                                                    //     ? new Date(row?.valid_from)
                                                                                    //     : new Date()}
                                                                                    minDate={row.valid_from && new Date(row.valid_from)}
                                                                                    value={!row.valid_from ? null : new Date(row.valid_from)}
                                                                                    helperText={(formik.errors.valid_from && formik.touched.valid_from) ? String(formik.errors.valid_from) : " "}
                                                                                    onChange={
                                                                                        (e) => {
                                                                                            handleChange(index, "valid_from", identifier)(e)
                                                                                        }}
                                                                                    placeholder="e.g.: DD/MM/YYYY"
                                                                                    InputAdornmentProps={{ position: "end", }}
                                                                                    InputProps={{
                                                                                        style: {
                                                                                            paddingRight: 0,
                                                                                        },
                                                                                        readOnly: true
                                                                                    }}
                                                                                    error={Boolean(
                                                                                        formik.errors.valid_from &&
                                                                                        formik.touched.valid_from
                                                                                    )}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </ThemeProvider>)
                                                                        :
                                                                        (!row.valid_from ? null : moment(row.valid_from).format("DD MMM YYYY"))
                                                                    }
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        UNIT*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl size="small" variant="outlined" error={Boolean(
                                                                            formik.errors.unit &&
                                                                            formik.touched.unit
                                                                        )}>
                                                                            <InputLabel
                                                                                htmlFor="outlined-unit"
                                                                            >
                                                                                UNIT
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="outlined-unit"
                                                                                disabled={
                                                                                    row?.operation === "edit" || row?.operation === "add"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                variant="outlined"
                                                                                label="UNIT"
                                                                                style={{ width: 190 }}
                                                                                MenuProps={MenuProps}
                                                                                value={row?.unit || ""}
                                                                                onChange={
                                                                                    (e) => {
                                                                                        handleChange(index, "unit", identifier)(e)
                                                                                        formik.setFieldValue(
                                                                                            "unit",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                            >
                                                                                {allUnits.length > 0
                                                                                    ? allUnits.map((data) => (
                                                                                        <MenuItem value={data.id}>{data.unit_code}</MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.unit && formik.touched.unit) ? String(formik.errors.unit) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        allUnits.map((e) => {
                                                                            if (e.id == row?.unit) return e.unit_code
                                                                        })
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ITEM NATURE*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            size="small"
                                                                            error={Boolean(
                                                                                formik.errors.items_nature &&
                                                                                formik.touched.items_nature
                                                                            )}
                                                                        >
                                                                            <InputLabel
                                                                                htmlFor="outlined-items-nature"
                                                                            >
                                                                                ITEM NATURE
                                                                            </InputLabel>
                                                                            <Select
                                                                                label="ITEM NATURE"
                                                                                id="outlined-items-nature"
                                                                                variant="outlined"
                                                                                style={{ width: 190 }}

                                                                                MenuProps={MenuProps}
                                                                                size="small"
                                                                                value={row?.items_nature || ""}
                                                                                onChange={(e) => {
                                                                                    handleChange(index, "items_nature", identifier)(e)
                                                                                    formik.setFieldValue(
                                                                                        "items_nature",
                                                                                        e.target.value
                                                                                    )
                                                                                }}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        height: "0px"
                                                                                    }
                                                                                }}
                                                                                helperText={
                                                                                    formik.errors.items_nature &&
                                                                                    formik.touched.items_nature &&
                                                                                    String(formik.errors.items_nature)
                                                                                }
                                                                            >
                                                                                {allItemNature.length > 0
                                                                                    ? allItemNature.map((data, itemNatureIndex) => (
                                                                                        <MenuItem value={data.item_nature} key={itemNatureIndex}>
                                                                                            {data.item_nature}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.items_nature && formik.touched.items_nature) ? String(formik.errors.items_nature) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        row?.items_nature
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        TAGS
                                                                    </Typography>
                                                                    {row?.operation === "edit" || row?.operation === "add" ? (
                                                                        <FormControl variant="outlined" size="small">
                                                                            <InputLabel
                                                                                htmlFor="outlined-items-nature"
                                                                            >
                                                                                TAGS
                                                                            </InputLabel>
                                                                            {console.log("tags", tags)}
                                                                            <Select
                                                                                disabled={
                                                                                    row?.operation === "edit" || row?.operation === "add"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                variant="outlined"
                                                                                style={{ width: 190 }}

                                                                                // multiple
                                                                                label="TAGS"
                                                                                value={tags}
                                                                                MenuProps={MenuProps}
                                                                                size="small"
                                                                                onChange={(e) => {
                                                                                    console.log(e.target)
                                                                                    selectedTags(e.target.value);
                                                                                    handleChange(index, "tags", identifier)(e);
                                                                                }}
                                                                            // renderValue={(selected) => (
                                                                            //     <div style={styles.chips}>
                                                                            //         {selected.map((value, index) => {
                                                                            //             let data = allTagsData.find(
                                                                            //                 (tagsData) => tagsData.tag_name === value.tag_name
                                                                            //             );

                                                                            //             return (
                                                                            //                 <Chip
                                                                            //                     size="small"
                                                                            //                     key={data.id}
                                                                            //                     label={data?.tag_name || ""}
                                                                            //                     style={styles.chip}
                                                                            //                 />
                                                                            //             );
                                                                            //         })}
                                                                            //     </div>
                                                                            // )}
                                                                            >
                                                                                {allTagsData && allTagsData.length > 0
                                                                                    ? allTagsData.map((allTagsData, index) => (
                                                                                        <MenuItem key={index} value={allTagsData}>
                                                                                            {/* <CustomCheckbox
                                                                                                checked={tags?.some(
                                                                                                    (t) => t.tag_name === allTagsData.tag_name
                                                                                                )}
                                                                                            /> */}
                                                                                            {allTagsData?.tag_name}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{" "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        row.tags.map((data) => (data?.tag_name + ", "))
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        SECTION NAME*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <FormControl variant="outlined" size="small" error={Boolean(
                                                                            formik.errors.section_name &&
                                                                            formik.touched.section_name
                                                                        )}>
                                                                            <InputLabel
                                                                                htmlFor="outlined-unit"
                                                                            >
                                                                                SECTION NAME
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="outlined-unit"
                                                                                variant="outlined"
                                                                                label="SECTION NAME"
                                                                                style={{ width: 190 }}

                                                                                MenuProps={MenuProps}
                                                                                size="small"
                                                                                value={row.sections[0]?.section_name}
                                                                                onChange={
                                                                                    (e) => {
                                                                                        handleChange(index, "section_name", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "section_name",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                            >
                                                                                {allUnits.length > 0
                                                                                    ? allUnits.map((data) => (
                                                                                        <MenuItem value={data.unit_code}>{data.unit_code}</MenuItem>
                                                                                    ))
                                                                                    : null}
                                                                            </Select>
                                                                            <FormHelperText>{(formik.errors.section_name && formik.touched.section_name) ? String(formik.errors.section_name) : " "}</FormHelperText>
                                                                        </FormControl>
                                                                    ) : (
                                                                        row.sections[0]?.section_name
                                                                    )}
                                                                </div>
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        SECTION WEIGHT*
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <TextField
                                                                            label="SECTION WEIGHT"
                                                                            variant="outlined"
                                                                            //className={classes.formControl}
                                                                            style={{ width: "45%", marginBottom: "14px" }}
                                                                            value={row.sections[0]?.section_weight}
                                                                            onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            onChange={(e) => {
                                                                                handleSectionError(e.target.value)
                                                                                handleChange(index, "section_weight", identifier, 0)(e)
                                                                                formik.setFieldValue(
                                                                                    "section_weight",
                                                                                    e.target.value
                                                                                )
                                                                            }}
                                                                            size="small"
                                                                            helperText={row.sections[0]?.section_weight ? errorMsg :
                                                                                (formik.errors.section_weight && formik.touched.section_weight) ?
                                                                                    String(formik.errors.section_weight) : ""
                                                                            }
                                                                            onKeyPress={(event) => {
                                                                                if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: { min: props.itemConsiderPacking ? 1 : 0 },
                                                                                endAdornment: <InputAdornment position="end">MTS</InputAdornment>,
                                                                            }}
                                                                            error={error || Boolean(
                                                                                formik.errors.section_weight &&
                                                                                formik.touched.section_weight
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        `${row.sections[0]?.section_weight} MTS`
                                                                    )}
                                                                </div>
                                                                {props.itemConsiderPacking ?
                                                                    <>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                PACKING WEIGHT*
                                                                            </Typography>
                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="PACKING WEIGHT"
                                                                                    variant="outlined"
                                                                                    //className={classes.formControl}
                                                                                    style={{ width: "45%", marginBottom: "14px" }}
                                                                                    value={row?.packing_weight}
                                                                                    defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handlePackingWeightError(e.target.value);
                                                                                        handleChange(index, "packing_weight", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "packing_weight",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    InputProps={{
                                                                                        inputProps: { min: props.itemConsiderPacking ? 1 : 0 },
                                                                                        endAdornment: <InputAdornment position="end">MTS</InputAdornment>,
                                                                                    }}
                                                                                    size="small"
                                                                                    helperText={row?.packing_weight ? packingErrMsg :
                                                                                        (formik.errors.packing_weight && formik.touched.packing_weight) ? String(formik.errors.packing_weight) : " "
                                                                                    }
                                                                                    error={packingError || Boolean(
                                                                                        formik.errors.packing_weight &&
                                                                                        formik.touched.packing_weight
                                                                                    )}
                                                                                />
                                                                            ) : (row?.packing_weight &&
                                                                                `${row?.packing_weight} MTS`
                                                                            )}
                                                                        </div>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                TOLERANCE UPTO 1 MTS*
                                                                            </Typography>
                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="TOL. UPTO 1 MTS"
                                                                                    variant="outlined"
                                                                                    className={classes.formControl}
                                                                                    value={row.tolerance_upto_1mts}
                                                                                    //defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handleChange(index, "tolerance_upto_1mts", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "tolerance_upto_1mts",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                                    }}
                                                                                    helperText={
                                                                                        (formik.errors.tolerance_upto_1mts && formik.touched.tolerance_upto_1mts) ? String(formik.errors.tolerance_upto_1mts) : " "
                                                                                    }
                                                                                    error={Boolean(
                                                                                        formik.errors.tolerance_upto_1mts &&
                                                                                        formik.touched.tolerance_upto_1mts
                                                                                    )}
                                                                                />
                                                                            ) : (row?.tolerance_upto_1mts &&
                                                                                `${row?.tolerance_upto_1mts} %`
                                                                            )}
                                                                        </div>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                TOLERANCE UPTO 5 MTS*
                                                                            </Typography>
                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="TOL. UPTO 5 MTS"
                                                                                    variant="outlined"
                                                                                    className={classes.formControl}
                                                                                    value={row?.tolerance_from_1to5mts}
                                                                                    //defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handleChange(index, "tolerance_from_1to5mts", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "tolerance_from_1to5mts",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                                    }}
                                                                                    helperText={
                                                                                        (formik.errors.tolerance_from_1to5mts && formik.touched.tolerance_from_1to5mts) ? String(formik.errors.tolerance_from_1to5mts) : " "
                                                                                    }
                                                                                    error={Boolean(
                                                                                        formik.errors.tolerance_from_1to5mts &&
                                                                                        formik.touched.tolerance_from_1to5mts
                                                                                    )}
                                                                                />
                                                                            ) : (row?.tolerance_from_1to5mts &&
                                                                                `${row?.tolerance_from_1to5mts} %`
                                                                            )}
                                                                        </div>
                                                                        <div className={classes.dataFields}>
                                                                            <Typography className={classes.titleLabel}>
                                                                                TOLERANCE ABOVE 5 MTS*
                                                                            </Typography>
                                                                            {row?.operation === "edit" ||
                                                                                row?.operation === "add" ? (
                                                                                <TextField
                                                                                    label="TOL.ABOVE 5 MTS"
                                                                                    variant="outlined"
                                                                                    className={classes.formControl}
                                                                                    value={row?.tolerance_above_5mts}
                                                                                    defaultValue={0}
                                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) => {
                                                                                        handleChange(index, "tolerance_above_5mts", identifier, 0)(e)
                                                                                        formik.setFieldValue(
                                                                                            "tolerance_above_5mts",
                                                                                            e.target.value
                                                                                        )
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+' || event?.key === '=') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                                    }}
                                                                                    helperText={
                                                                                        (formik.errors.tolerance_above_5mts && formik.touched.tolerance_above_5mts) ? String(formik.errors.tolerance_above_5mts) : " "
                                                                                    }
                                                                                    error={Boolean(
                                                                                        formik.errors.tolerance_above_5mts &&
                                                                                        formik.touched.tolerance_above_5mts
                                                                                    )}
                                                                                />
                                                                            ) : (row?.tolerance_above_5mts &&
                                                                                `${row?.tolerance_above_5mts} %`
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                    : <></>}
                                                                <div className={classes.dataFields}>
                                                                    <Typography className={classes.titleLabel}>
                                                                        ACTIVE
                                                                    </Typography>
                                                                    {row?.operation === "edit" ||
                                                                        row?.operation === "add" ? (
                                                                        <IOSSwitch
                                                                            checked={(identifier === "addNewData" && row.is_active === undefined)
                                                                                ? true
                                                                                : Boolean(row.is_active)
                                                                            }
                                                                            //checked={Boolean(row.is_active)}
                                                                            onChange={(e) => handleChange(index, "is_active", identifier)(e)}
                                                                            name="is_active"
                                                                        />
                                                                    )
                                                                        :
                                                                        (row.is_active
                                                                            ? (<CheckCircle style={{ color: successButtonBackground }} />)
                                                                            : (<Cancel style={{ color: orange }} />)
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <DialogActions>
                                                                <Button onClick={handleDialogCancel}>Cancel</Button>
                                                                <Button type="submit" disabled={props?.itemConsiderPacking ? error || packingError : false} style={{ backgroundColor: ((error || packingError) && props?.itemConsiderPacking) ? COLORS.grey : primaryColor }} className={classes.primaryBackground}>Add</Button>
                                                            </DialogActions>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </DialogContentText>
                        </DialogContent>
                    </Dialog> : <></>
            }
        </>

    )
}
