import React, { Component } from "react";
import Pending from "../Pending";
import { withStyles } from "@material-ui/core/styles";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {
  PendingDispatchMainBody: {
    height: window.innerHeight - 70,
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
  },
};
class PendingDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    // console.log(this.props)
    if (this.props?.location?.state?.message)
      this.props.history.push("/dispatch")
    else
      if (this.props.history.length > 2)
        this.props.history.goBack();
      else
        this.props.history.push("/dispatch")
  };
  render() {
    const { classes } = this.props;
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        <div className={classes.PendingDispatchMainBody}>
          <Pending {...this.props} />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(PendingDispatch);
