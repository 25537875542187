import React, { Component } from "react";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import Pending from "../Pending";
import { withStyles } from "@material-ui/core/styles";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";

const styles = {};
class PendingOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBack = () => {
    if (this.props?.location?.state?.message)
      this.props.history.push("/home")
    else
      this.props.history.push("/orders")
  };
  render() {
    const { classes } = this.props;
    // console.log(this.props?.location?.state)
    return (
      <MobileLayout
        appBar
        handleBack={this.handleBack}
        appBarLeftIcon={<KeyboardBackspace />}
      >
        <Pending {...this.props} />
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(PendingOrders);
