import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MobileLayout from "../../../../layouts/b2b/MobileLayout";
import "../../../../styles/b2b/DailyRates.css";
import backStack from "cordova-back-stack";
import Orders from "../Orders";

const styles = {
  ordersHomeMainBody: {
    background:
      "transparent linear-gradient(180deg, #FFFFFF 0%, #F5F8FC 100%) 0% 0% no-repeat padding-box",
    opacity: 1,
    height: window.innerHeight - 74,
    // overflowY: "scroll",
  },
};
class OrdersHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (window.cordova) {
      this.image = `${window.cordova.file.applicationDirectory}www`;
    }
  }
  componentWillMount() {
    backStack((e) => {
      e.preventDefault();
      //   if (this.props.location.pathname === "/dailyRates") {
      //     window.navigator.app.exitApp();
      //   }
    });
  }

  render() {
    const { classes, isMobile } = this.props;

    return (
      <MobileLayout bottomNavigation bottomNavSelected={1}>
        <div className={classes.ordersHomeMainBody}>
          <Orders isMobile={isMobile} {...this.props} />
        </div>
      </MobileLayout>
    );
  }
}
export default withStyles(styles)(OrdersHome);
