import * as React from 'react';
import { useRef } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import CircularProgressLoader from "../../../common/CircularProgressLoader";
import NoDataFound from "../../../common/NoDataFound";
import { styled } from "@mui/material/styles";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Paper from '@mui/material/Paper';
import Moment from 'moment'
import { plantTableHeaders } from '../headers'
import { useEffect } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4b87c5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  [`&.${tableCellClasses.footer}`]: {
    fontSize: 15,
    fontWeight: 550
  },
  // ".MuiTableCell-footer": {
  //   fontSize: 14,
  //   bold: true
  // }
}));


const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      "& .MuiDataGrid-row": {
        "&:nth-child(2n)": {
          backgroundColor: "rgba(235, 235, 235, .7)"
        }
      }
    }
  },
  container: {
    marginTop: "20px",
    maxHeight: 440,
    display: "flex",
    justifyContent: "space-between",
  },
  tableCell: {
    userSelect: "text"
  }

});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function PlantTable({
  data,
  type,
  value,
  itemName,
  ...props
}) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableData, setTableData] = React.useState([])
  const headers = plantTableHeaders(value, type)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const classes = useStyles()
  // console.log(data)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0)
  }, [data])

  const tableHeaders = (<TableRow>
    {
      <StyledTableCell style={{ minWidth: 50 }}>#</StyledTableCell>
    }
    {
      headers.map(row => (
        <StyledTableCell style={{ minWidth: row.minWidth }}>{row.header}</StyledTableCell>
      ))
    }
  </TableRow>
  )

  const tableBody = (
    data.data.map((row, i) => (
      // (i === data.data.length - 1 && value === 3) ? "" :
      <TableRow
        key={i}>
        <StyledTableCell >{i + 1}</StyledTableCell>
        {
          headers.map(head => (
            <StyledTableCell
              style={{
                minWidth: head.minWidth,
                userSelect:
                  // head.key === "la_number" ||
                  //   head.key === "erp_invoice_number" ||
                  //   head.key === "order_number" ||
                  //   head.key === "order_no" ?
                  "text"
                // : "none"
              }} >{row[head.key]}</StyledTableCell>
          ))
        }
      </TableRow>
    ))
  )

  const tableFooter = (
    <TableRow>
      {
        <StyledTableCell style={{ minWidth: 50 }}></StyledTableCell>
      }
      {
        headers.map(header => (

          <StyledTableCell style={{ minWidth: header.minWidth }}>
            {
              header.calculation && data.calculations ?
                `${header.calc_text} : ${data.calculations[header.calculation]}` : ""
            }
          </StyledTableCell>

        ))
      }
    </TableRow>

  )


  const elementRef = useRef(null);
  // console.log(data);
  if (data?.loading)
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: 120 }}
        ref={elementRef}
      ><CircularProgressLoader /></div>);

  if (!data || data.data?.length === 0)
    return (
      <NoDataFound style={{ display: "flex", justifyContent: "center", marginTop: 120 }} />
    );
  // console.log(data.data)
  // console.log(tableBody && tableBody)

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {tableHeaders}
          </TableHead>
          <TableBody>
            {tableBody &&
              (rowsPerPage > 0)
              ? tableBody.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : tableBody
            }
          </TableBody>
          <TableFooter>
            {tableFooter}
          </TableFooter>
          {/* } */}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, { label: 'All', value: -1 }]}
        // colSpan={3}
        component="div"
        count={tableBody.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          }
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>

  );
}
