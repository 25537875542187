import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Paper,
  IconButton,
} from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  grey,
  secondaryBlueText,
  drawerTextColor,
  successButtonBackground,
} from "../../../../constants/internal/colors";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {
  pxToEm,
  //   isValidCountryCode,
  //   isPhoneNumberValid,
  //   isValidStdCode,
} from "../../../../methods";
// import { is } from "date-fns/locale";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import axios from "axios";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarComponent from "../../common/SnackbarComponent";



const useStyles = makeStyles({
  formControl: {
    width: "65%",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
    width: 80
  },
});

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
export default function ManageCustomerExtraPhoneNumberDisplay({
  // handleExtraPhoneSave,
  // handleAddNewPhoneField,
  setShowExtraPhonesForm,
  // extraPhoneCountryError,
  // extraPhoneNumberError,
  // extraPhoneSTDError,
  ...props
}) {
  const [extraPhoneNumbers, setExtraPhoneNumbers] = React.useState(
    props.extraPhoneNumbers
  );

  const [postExtraPhones, setPostExtraPhones] = useState({
    std_code: "", country_code: "", customer: props.selectedCustomerId, phone_number: ""
  })
  const [countryCodeSelected, setCountryCodeSelected] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState("")
  let [extraPhoneCountryError, setExtraPhoneCountryError] = useState(false)
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false)
  // let [extraPhoneSTDError, setExtraPhoneSTDError] = useState(false)
  let [extraPhoneNumberError, setExtraPhoneNumberError] = useState(false)
  let [disableSave, setDisableSave] = useState(true)
  let [openSnackbar, setOpenSnackbar] = useState(false)
  let [snackbarMessage, setSnackbarMessage] = useState(null)
  let [snackbarType, setSnackbarType] = useState(null)
  let [showSnackBar, setShowSnackBar] = useState(false)


  useEffect(() => {
    checkValidation()
  }, [props?.postExtraPhone[0]?.phone_number])



  useEffect(() => {
    if (extraPhoneNumberError
      || (!!!props?.postExtraPhone[0]?.country_code || props?.postExtraPhone[0]?.country_code?.length === 0)
      || (!!!props?.postExtraPhone[0]?.phone_number || props?.postExtraPhone[0]?.phone_number?.length === 0)
      || !phoneNumberTouched
    )
      setDisableSave(true)
    else setDisableSave(false)


  }, [extraPhoneNumberError, props?.postExtraPhone[0]?.phone_number, props?.postExtraPhone[0]?.country_code, countryCodeSelected])

  useEffect(() => {
    // console.log("save enabled")
  }, [disableSave])


  const classes = useStyles();

  let handleCountryCodeSelect = (e) => {
    setCountryCodeSelected(true)
    setPostExtraPhones({ ...postExtraPhones, country_code: e.target.value })

  }

  let checkValidation = () => {
    if (props.postExtraPhone[0]?.country_code && props.postExtraPhone[0]?.country_code?.length === 0) {
      if (props.countryCodeTouched) {
        setExtraPhoneCountryError(true)
      }
    }
    else setExtraPhoneCountryError(false)
    if (props.postExtraPhone[0]?.phone_number?.length === 0 ||
      props.postExtraPhone[0]?.phone_number?.length !== 10 ||
      props.postExtraPhone[0]?.phone_number < 0) {
      if (phoneNumberTouched) {
        setExtraPhoneNumberError(true)
      }
    } else if (props.postExtraPhone[0]?.phone_number?.length === 10) {
      const isPhoneNumberAlreadyPresent = props.extraPhones?.some(p => p.phone_number === props.postExtraPhone[0]?.phone_number)
      setExtraPhoneNumberError(isPhoneNumberAlreadyPresent);
    } else
      setExtraPhoneNumberError(false)


    // setExtraPhoneNumberError(false)
    // console.log("country - ", extraPhoneCountryError, "Phone - ", extraPhoneNumberError, "std - ", extraPhoneSTDError)
  }



  let handleAddNewPhoneField = (e, name) => {
    // console.log(e.target.value)
    if (name === "std_code") {
      setPostExtraPhones({ ...postExtraPhones, std_code: e.target.value })
    }
    else if (name === "phone_number") {
      setPostExtraPhones({ ...postExtraPhones, phone_number: e.target.value })
    }
  }

  let handleShowForm = () => {
    setShowExtraPhonesForm(false)
  }




  // let handleExtraPhoneSave = () => {
  //   if (props?.selectedCountryCode?.length === 0)
  //     setPostExtraPhones(props.selectedCountryCode)

  //   console.log(postExtraPhones)



  //   axios
  //     .post(APIROUTES.CUSTOMER_EXTRA_PHONES, postExtraPhones)
  //     .then((response) => {
  //       // console.log(response)
  //       if (response.status === 200) {

  //         let message = "Extra Phone Number Added succesfully"
  //         let type = "success"
  //         let open = true
  //         setOpenSnackbar(open)
  //         setSnackbarMessage(message)
  //         setSnackbarType(type)
  //         setShowSnackBar(true)

  //       }

  //       props.extraphoneCall(props.selectedCustomerId)

  //         // (false)
  //     })

  // }

  // console.log(snackbarMessage, snackbarType, openSnackbar)
  // console.log(extraPhoneSTDError)
  // console.log(disableSave)
  // props?.postExtraPhone[0]?.phone_number ?
  //   console.log(props.postExtraPhone[0].phone_number.length) : console.log("not There")
  // console.log(props.openSnackbar, props.snackbarMessage, props.snackbarType)
  // props.postExtraPhone.length > 0 ?
  return <div className={classes.dataDisplay}>
    <div className={classes.titileLabelDataFields}>
      <Typography className={classes.titleLabelStyle}>
        {`${props.operation === "add" ? "NEW" : " "} EXTRA PHONE NUMBER 
            `}
      </Typography>
      {/* {props.operation === "edit" ?
          ""
          : (
            <div>
              <Tooltip
                title={`Delete Extra Phone Number 
                
                  `}
                placement="left"
                arrow
              >
                <DeleteOutlineOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.removeNewExtraPhoneNumbers()
                  }
                />
              </Tooltip>
            </div>
          )} */}
    </div>

    <div className={classes.dataFields}>
      <Typography className={classes.titleLabel}>COUNTRY CODE*</Typography>
      {props.operation === "view" ? "" : (
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          <Select
            value={props?.postExtraPhone[0]?.country_code}
            // disabled={props.customerOperation === "view"}
            onChange={
              props.handleCustomerAddressBoxValueChange(
                0,
                "country_code"
              )
            }
            MenuProps={MenuProps}
          >
            <MenuItem value={null} disabled={true}>
              Select Any
            </MenuItem>
            {
              props.country_codes.map((country) =>
                <MenuItem value={country.phone_code}>
                  {`${country.name}(+${(country.phone_code[0] === '+' ?
                    country.phone_code.slice(1)
                    : country.phone_code)})`}
                </MenuItem>
              )
            }
          </Select>
          {extraPhoneCountryError && <FormHelperText style={{ color: "red" }}>This is required!</FormHelperText>}
        </FormControl>


      )}
    </div>


    <div className={classes.dataFields}>
      <Typography className={classes.titleLabel}>PHONE NUMBER*</Typography>
      {props.operation === "view" ? (
        ""
      ) : (
        <TextField
          // type="number"
          onKeyPress={
            (event) => {
              if (event.charCode >= 48 && event.charCode <= 57) {

              } else {
                event.preventDefault()
              }
            }
          }
          autoComplete="off"
          className={classes.formControl}
          label="PHONE NUMBER"
          id="outlined-size-small"
          defaultValue={props?.postExtraPhone[0]?.phone_number || ""}
          variant="outlined"
          onChange={(e) => {
            setPhoneNumberTouched(true)
            props.handlePhoneNumberIsDirty()
            props.handleCustomerAddressBoxValueChange(
              0,
              "phone_number", null, null
            )(e)
          }
          }
          size="small"
          error={extraPhoneNumberError}
          helperText={
            extraPhoneNumberError ? "Invalid phone number" : ""
          }
        />
      )}
    </div>
    <div className={classes.dataFields}>
      <Typography className={classes.titleLabel}>STD CODE</Typography>
      {props.operation === "view" ? (
        ""
      ) : (
        <TextField
          // type="number"
          onKeyPress={
            (event) => {
              if (event.charCode >= 48 && event.charCode <= 57) {

              } else {
                event.preventDefault()
              }
            }
          }
          autoComplete="off"
          className={classes.formControl}
          label="STD CODE"
          id="outlined-size-small"
          defaultValue={props?.postExtraPhone[0]?.std_code}
          variant="outlined"
          onChange={

            props.handleCustomerAddressBoxValueChange(
              0,
              "std_code"
            )
          }
          size="small"
        // error={extraPhoneSTDError}
        // helperText={
        //   extraPhoneSTDError ? "Invalid STD code" : ""
        // }
        />
      )}
    </div>

    {
      (props.customerOperation === "add") ? "" :

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            size="small"
            startIcon={<CheckOutlinedIcon />}
            onClick={() => {
              props.handleExtraPhoneSaveClick()
              handleShowForm()
            }
            }
            className={classes.saveButton}
            disabled={
              disableSave
            }
          >Save</Button>
          <Button
            size="medium"
            className={classes.margin}
            onClick={(e) => {
              setShowExtraPhonesForm(false)
              setDisableSave(true)
              props.handleExtraPhoneCancelClick(e)
            }}
          >
            CANCEL
          </Button>
        </div>
    }




  </div>






}
