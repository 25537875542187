import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router";
import COLORS from "../../../constants/internal/colors";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Dialpad from "@material-ui/icons/Dialpad";
import InfoCard from "./InfoCard";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Check from "@material-ui/icons/Check";
import Cancel from "@material-ui/icons/Cancel";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarComponent from "../../common/SnackbarComponent";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  Typography
} from '@material-ui/core';
import {
  cancelButtonBackground,
  successButtonBackground,
  drawerTextColor,
  primaryColor,
} from "../../../constants/internal/colors";
import MenuItem from '@mui/material/MenuItem';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { pxToEm } from "../../../methods";
import { CopyToClipboard } from "react-copy-to-clipboard";

const styles = {
  breadcrumbsStyle: {
    color: COLORS.secondaryBlueText,
    cursor: "pointer",
    margin: "10px 0px 0px 10px",
  },
  paperMainContainer: (laDetails, isMobile) => ({
    height: !laDetails ? "66vh" : null,
    display: "flex",
    flexDirection: isMobile && 'column',
    padding: "12px 12px 12px 12px",
    marginBottom: "22px",
  }),
  paperStyle: {
    width: "96%",
    marginTop: "2%",
  },
  plantNameShowContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px",
  },
  inputPaperStyle: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 18px",
    justifyContent: "center",
    alignItems: "center",
    height: "24vh",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 2px 3px -1px, rgba(0, 0, 0, 0.14) 1px 6px 20px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px",
  },
  rightSideContainer: {
    width: "44%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  infoCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "6%",
  },
};
class GateIn extends Component {
  constructor(props) {
    super(props);
    let gates = props.gatesDetail.filter((g) => g.id === props.location.id);

    this.state = {
      laNumber: "",
      laNumberLengthError: false,
      errorMessage: null,
      allGate_in_status: [],
      gate_in: [
        { name: "LA  Status" },
        { name: "Gate In Date" },
        { name: "Plant Status" },
        { name: "Gate Status" },
      ],
      gate_in_withRandomWt: [
        { name: "LA  Status" },
        { name: "Gate In Date" },
        { name: "Plant Status" },
        { name: "Gate Status" },
        { name: "Last Random Weight" }
      ],
      gateInStatus: false,
      gateInStatusLoader: false,
      gatesDetail: gates.length > 0 ? gates[0] : null,
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
      openGateOutReasonDialog: false,
      Gate_Out_Reason: "",
      showLoader: false,
      isCopied: false,
      gateInDiff: parseInt(moment.duration(moment(new Date()).diff(moment(this.props?.laDetails
        ?.actual_gate_in_time)))?.asSeconds())
    };
  }

  handleTextChange = (event) => {
    this.setState({ [event.target.name]: (event.target.value).trim() });
  };
  componentDidMount() {
    this.props.handleClearLoadingAdvice();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.laDetails != this.props.laDetails) {
      this.setState({
        allGate_in_status: this.props?.laDetails?.dispatch_status === "PACKED"
          || this.props?.laDetails?.dispatch_status === "ALLOTED" ||
          this.props?.laDetails?.dispatch_status === "BILLED" ?
          this.state.gate_in_withRandomWt : this.state.gate_in
      })
    }
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  handleGateInGateOut = () => {
    this.setState({ gateInStatusLoader: true }, () => {
      this.props
        .handleUpdateLoadingAdvice(
          this.props.laDetails?.id,
          this.state.gatesDetail.id,
          this.state.Gate_Out_Reason
        )
        .then((data) => {
          if (data.status === 200) {
            console.log(this.props?.laDetails)
            this.setState({
              gateInStatus: true,
              gateInStatusLoader: false,
              openSnackbar: true,
              snackbarMessage: `${this.props?.laDetails && this.props?.laDetails?.dispatch_status === "APPROVED" ? "GATE IN" : "GATE OUT"} SUCCESSFUL`,
              snackbarType: "success"
            },
              () => {
                setTimeout(
                  () => this.props.history.push("/dispatchOperation"),
                  3000
                );
              }
            );
          } else {
            this.setState({
              openSnackbar: true,
              openGateOutReasonDialog: false,
              snackbarMessage: "Error occured while processing your request",
              snackbarType: "error",
            },

            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            gateInStatusLoader: false,
            openGateOutReasonDialog: false,
            openSnackbar: true,
            snackbarMessage: err?.response?.data?.msg
              ? err?.response?.data?.msg
              : "Error occured while processing your request",
            snackbarType: "error"
          });
        });
    });
  };
  handleDisabledButton = () => {
    const { gatesDetail } = this.props;
    const { laDetails } = this.props;

    // return (
    //   (gatesDetail &&
    //     laDetails &&
    //     this.state.gatesDetail?.plant !== laDetails?.plant_name) ||
    //   this.state.gatesDetail?.is_gate_in_allowed === false ||
    //   laDetails?.dispatch_status !== "APPROVED"
    // );
    const isRandomWtCheck = !this.isStatusGood("LAST RANDOM WEIGHT", laDetails, gatesDetail);

    const isGood =
      !this.isStatusGood("LA  STATUS", laDetails, gatesDetail) ||
      !this.isStatusGood("PLANT STATUS", laDetails, gatesDetail) ||
      !this.isStatusGood("GATE IN DATE", laDetails, gatesDetail) ||
      !this.isStatusGood("GATE STATUS", laDetails, gatesDetail);

    const isGateInOutAllowed =
      laDetails && laDetails.dispatch_status === "APPROVED"
        ? this.state.gatesDetail?.is_gate_in_allowed
        : this.state.gatesDetail?.is_gate_out_allowed;

    const randomWeightCheck = laDetails && laDetails.dispatch_status === "BILLED" && isRandomWtCheck;

    return isGood || !isGateInOutAllowed || randomWeightCheck;
  };

  handleCloseGateOutReasonDialog = () => {
    this.setState({
      openGateOutReasonDialog: false
    })
  };

  handleOpenGateOutReasonDialog = () => {
    this.setState({
      openGateOutReasonDialog: true
    })
  };

  handleDropdownChange = (e) => {
    console.log(e.target.value)
    this.setState({
      Gate_Out_Reason: e.target.value
    })
  }

  isTodaysDate = (gateInDate) => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let gateinDate = new Date(gateInDate);
    gateinDate.setHours(0, 0, 0, 0);
    let gateInDateWithAdvance = new Date(gateInDate);
    gateInDateWithAdvance.setDate(gateInDateWithAdvance.getDate() + 1);
    gateInDateWithAdvance.setHours(0, 0, 0, 0);
    if (currentDate.toISOString() === gateinDate.toISOString() || (gateinDate < gateInDateWithAdvance)) {
      return true;
    }
    return false;
  }

  gateInDateCheck = (gateInDate) => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let gateinDate = new Date(gateInDate);
    gateinDate.setHours(0, 0, 0, 0);
    let gateInDateWithAdvance = new Date(gateInDate);
    gateInDateWithAdvance.setDate(gateInDateWithAdvance.getDate() + 1);
    gateInDateWithAdvance.setHours(0, 0, 0, 0);
    if (currentDate > gateInDateWithAdvance || gateinDate > currentDate) {
      return false;
    }
    return true;
  }

  isDateLater = (gateInDate) => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let dateToCompare = new Date(gateInDate);
    dateToCompare.setHours(0, 0, 0, 0);
    if (currentDate < dateToCompare) {
      return false
    }
    return true;
  }

  isStatusGood = (name, laDetails, gatesDetail) => {
    let status = false;
    switch (name.toUpperCase()) {
      case "LA  STATUS":
        if (
          laDetails.dispatch_status === "APPROVED" ||
          laDetails.dispatch_status === "BILLED" ||
          laDetails.dispatch_status === "UNLOADED"
        ) {
          status = true;
        }
        break;
      case "PLANT STATUS":
        if (this.state.gatesDetail?.plant === laDetails.plant_name) {
          status = true;
        }
        break;
      case "GATE IN DATE":
        if (this.isTodaysDate(laDetails.gate_in_date)
          && this.gateInDateCheck(laDetails.gate_in_date)
          && laDetails.dispatch_status !== "BILLED") {
          status = true;
        } else if (laDetails.dispatch_status === "BILLED" && this.isDateLater(laDetails?.gate_in_date)) {
          status = true;
        } else if (laDetails.dispatch_status === "UNLOADED" && this.isDateLater(laDetails?.gate_in_date)) {
          status = true;
        }
        break;
      case "GATE STATUS":
        if (
          (this.state.gatesDetail?.is_gate_in_allowed && laDetails.dispatch_status === "APPROVED") ||
          (this.state.gatesDetail?.is_gate_out_allowed && (laDetails.dispatch_status === "BILLED" || laDetails.dispatch_status === "UNLOADED"))
        ) {
          status = true;
        }

        break;
      case "LAST RANDOM WEIGHT":
        if ((laDetails?.random_weight_check?.is_valid || laDetails?.random_weight_check === null)) {
          status = true;
        }
        break;
      default:
        break;
    }
    return status;
  };

  onCopyText = () => {
    this.setState(prevValue => { return { ...prevValue, isCopied: !prevValue.isCopied } })
    setTimeout(() => {
      this.setState({
        isCopied: false,
      });
    }, 1000);
  }

  render() {
    const { laNumber, errorMessage, openGateOutReasonDialog, handleCloseGateOutReasonDialog, Gate_Out_Reason, showLoader, handleOpenGateOutReasonDialog, handleDropdownChange, gateInDiff } = this.state;
    const { classes, laDetails, gatesDetail, isMobile } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          className={classes.breadcrumbsStyle}
        >
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
          >
            Dispatch Operations
          </Link>
          <Link color="inherit">Gate</Link>
        </Breadcrumbs>

        <Paper elevation={1} className={classes.paperStyle}>
          <div className={classes.plantNameShowContainer}>
            <span>
              {this.state.gatesDetail?.plant} -
              {this.state.gatesDetail?.gate_name}
            </span>
            <span>{moment().format("lll")}</span>
          </div>
          <Divider variant="middle" />
          <div style={styles.paperMainContainer(laDetails, isMobile)}>
            <div style={{ width: isMobile ? '100%' : "50%" }}>
              <Paper className={classes.inputPaperStyle} elevation={1}>
                <Dialpad style={{ marginBottom: "18px" }} />
                <TextField
                  disabled={this.props.laDetails !== null}
                  name="laNumber"
                  autoFocus
                  style={{ width: "85%" }}
                  id="outlined-adornment-amount"
                  variant="outlined"
                  label="Enter LA Number here"
                  value={laNumber.toUpperCase()}
                  helperText={
                    this.state.errorMessage ? (
                      <span style={{ color: COLORS.orange }}>
                        {errorMessage}
                      </span>
                    ) : this.props.errorMessage &&
                      !this.state.laNumberLengthError ? (
                      <span style={{ color: COLORS.orange }}>
                        {this.props.errorMessage}
                      </span>
                    ) : null
                  }
                  onChange={(e) => {
                    this.handleTextChange(e);
                    let la = (e.target.value).trim();
                    if (la.length >= 14 && la.length <= 15) {
                      this.props
                        .handelFetchGates()
                        .then((status) => {
                          this.setState({
                            laNumberLengthError: false,
                            errorMessage: "",
                          });
                          if (
                            status[0].is_gate_in_allowed ||
                            status[0].is_gate_out_allowed
                          ) {
                            this.props.handleFetchLoadingAdvice(la);
                          } else {
                            throw "Gates is not active";
                          }
                        })
                        .catch((err) => {
                          this.setState({
                            errorMessage: err?.data?.message,
                            gateActiveStatus: false,
                          });
                        });
                    }
                  }}
                  onFocus={() => {
                    this.setState({ laNumberLengthError: false });
                  }}
                  onBlur={() => {
                    if (
                      !(
                        this.state.laNumber.length >= 14 &&
                        this.state.laNumber.length <= 15
                      )
                    ) {
                      this.setState({
                        laNumberLengthError: true,
                        errorMessage:
                          "Please fill valid 14 or 15 digit LA numbers",
                      });
                    } else {
                      this.setState({
                        laNumberLengthError: false,
                        errorMessage: "",
                      });
                    }
                  }}
                />
              </Paper>

              <div className={classes.infoCardContainer}>
                {laDetails === null && this.props.laLoader === false ? null : (
                  <InfoCard
                    title_1="LOADING"
                    title_2="ADVICE"
                    key_1="Item Category"
                    value_1={laDetails && laDetails.category_name}
                    key_2="Gate In Date"
                    value_2={moment(laDetails && laDetails.gate_in_date).format(
                      "DD/MM/YYYY"
                    )}
                    key_3="La Quantity"
                    value_3={laDetails && laDetails.la_quantity + "MT"}
                    key_4="LA Status"
                    value_4={laDetails && laDetails.dispatch_status}
                    infoLoader={this.props.laLoader}
                  />
                )}
                {laDetails === null && this.props.laLoader === false ? null : (
                  <InfoCard
                    title_1="TRUCK"
                    title_2="DETAILS"
                    key_1="Transporter Name"
                    value_1={laDetails && laDetails.transporter_name}
                    key_2="Driver Name"
                    value_2={laDetails && laDetails.driver_name}
                    key_3="Truck Capacity"
                    value_3={laDetails && laDetails.truck_capacity + "MT"}
                    key_4="Driver Number"
                    value_4={laDetails && laDetails.driver_contact_number}
                    infoLoader={this.props.laLoader}
                  />
                )}
              </div>
            </div>
            <div className={classes.rightSideContainer} style={{ width: isMobile && '100%' }}>
              <div style={{ width: "92%", marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <span>{laDetails && laDetails.la_number}</span>
                  <span>{laDetails && laDetails.truck_number}</span>
                </div>
                <Divider variant="middle" />
                <div>
                  {!laDetails
                    ? null
                    : this.state.allGate_in_status.map((data) => {
                      let randomTolerance = Number(laDetails?.random_weight_check?.tolerance).toFixed(2);
                      let secondaryText = data.name === "Last Random Weight" ? randomTolerance > laDetails?.random_weight_check?.master_tolerance ? `Vehicle is Overweight (${randomTolerance}%)`
                        : randomTolerance < 0 ? `Vehicle is underweight (${randomTolerance}%)`
                          : randomTolerance < laDetails?.random_weight_check?.master_tolerance ? `Weight in Tolerance (${randomTolerance}%)`
                            : "No Random Weight Done" : "";
                      return <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              style={
                                this.isStatusGood(
                                  data.name,
                                  laDetails,
                                  gatesDetail
                                )
                                  ? { background: COLORS.veryLightGreen }
                                  : { background: COLORS.lightPink }
                              }
                            >
                              {this.isStatusGood(
                                data.name,
                                laDetails,
                                gatesDetail
                              ) ? (
                                <Check
                                  style={{ color: COLORS.lightGreen_1 }}
                                />
                              ) : (
                                <Cancel style={{ color: COLORS.pink }} />
                              )}
                            </Avatar>
                          </ListItemAvatar>
                          <Tooltip title={data.name === "Last Random Weight" && laDetails?.random_weight_check !== null ? "Tolerance is " + laDetails?.random_weight_check?.master_tolerance + "%" : ''} placement="top-start">
                            <ListItemText
                              primary={data.name}
                              secondary={secondaryText}
                              primaryTypographyProps={{
                                style: { fontSize: "0.9em" },
                              }}
                              secondaryTypographyProps={{
                                style: { fontSize: "0.9em" },
                              }}
                            />

                          </Tooltip>
                          {data.name === "Last Random Weight" ?
                            <>
                              <CopyToClipboard text={secondaryText}
                                onCopy={() => this.onCopyText()}>
                                {this.state.isCopied ? (<span style={{
                                  marginRight: laDetails?.random_weight_check !== null && randomTolerance < 0 ?
                                    90 : randomTolerance < laDetails?.random_weight_check?.master_tolerance ?
                                      110 : randomTolerance > laDetails?.random_weight_check?.master_tolerance ?
                                        90 : 130, fontSize: "10px", marginTop: 13, color: "#FFB600"
                                }}>Copied!</span>) : (
                                  <ContentCopyOutlinedIcon
                                    style={{
                                      fontSize: "small",
                                      cursor: 'pointer',
                                      marginTop: 13,
                                      marginRight: isMobile ? 0 : laDetails?.random_weight_check !== null && randomTolerance < 0 ?
                                        120 : randomTolerance < laDetails?.random_weight_check?.master_tolerance ?
                                          150 : randomTolerance > laDetails?.random_weight_check?.master_tolerance ?
                                            130 : 165
                                    }}
                                  />)
                                }
                              </CopyToClipboard>
                            </>
                            : ""}

                          <ListItemSecondaryAction>
                            <span style={{ color: COLORS.grey }}>
                              {data.name === "LA  Status"
                                ? laDetails && laDetails.dispatch_status
                                : data.name === "Gate In Date"
                                  ? moment(
                                    laDetails && laDetails.gate_in_date
                                  ).format("DD/MM/YYYY")
                                  : data.name === "Plant Status"
                                    ? laDetails && laDetails.plant_name
                                    : data.name === "Gate Status"
                                      ? this.state.gatesDetail?.gate_name
                                      : data.name === "Last Random Weight"
                                        ? <div style={{ display: "flex" }}>
                                          <div>
                                            {laDetails?.random_weight_check !== null ?
                                              laDetails?.random_weight_check?.weight : "--"
                                            }
                                          </div>
                                          {/* <div>
                                            {
                                              <Tooltip
                                                interactive
                                                title={<><span id="randomWtTitle" style={{ marginBottom: 6 }}>random weight tolerance is {Number(laDetails?.random_weight_check?.tolerance).toFixed(3)}</span>
                                                  <span onClick={() => this.onCopyText(document.getElementById('randomWtTitle').innerHTML)}>
                                                    {this.state.isRandomWtCopied ? (
                                                      <Typography
                                                        style={{
                                                          fontSize: pxToEm(10),
                                                          //height: 24,
                                                          width: 25,
                                                          fontWeight: "normal",
                                                          color: "#FFB600",
                                                          marginLeft: 2,
                                                          //marginRight: 15
                                                        }}
                                                      >
                                                        Copied!
                                                      </Typography>
                                                    ) : (
                                                      <ContentCopyOutlinedIcon
                                                        style={{
                                                          fontSize: "small",
                                                          cursor: 'pointer',
                                                          //marginTop: 6,
                                                          marginLeft: 5
                                                        }}
                                                      />
                                                    )}

                                                  </span></>} placement="top"
                                              >
                                                <InfoOutlinedIcon fontSize="small" />
                                              </Tooltip>

                                            }
                                          </div> */}
                                        </div>
                                        : null}
                            </span>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>

                    }
                    )}
                  {!laDetails ? null : (laDetails?.dispatch_status === "DISPATCHED" && parseInt(moment.duration(moment(laDetails?.gate_out_date).diff(moment(laDetails
                    ?.actual_gate_in_time)))?.asSeconds()) > 43200)
                    ?
                    <div style={{ border: "dashed 1px #839192", borderRadius: "5px", padding: "10px" }}>
                      <span style={{ fontSize: "0.9em", color: "rgb(245, 134, 52)" }}>
                        Gate Out Reason : </span>
                      <span style={{ float: "right", fontSize: "1.0em", color: "#839192" }}>{laDetails?.gate_out_reason}
                      </span>
                    </div>
                    : null
                  }
                </div>


                {/* {!this.state.gateInStatus ? null : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.5em",
                      margin: "14px 0px",
                      color: COLORS.successButtonBackground,
                    }}
                  >
                    <span>{laDetails && laDetails.dispatch_status === "APPROVED" ? "GATE IN" : "GATE OUT"} SUCCESSFUL</span>
                  </div>
                )} */}

                {!laDetails ? null : this.state.gateInStatus || this.state.gateInStatusLoader ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div className={classes.buttonContainer}>
                    <Button
                      style={{
                        color: COLORS.primaryColor,
                        marginRight: "26px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ laNumber: "" });
                        this.props.handleClearLoadingAdvice();
                      }}
                    >
                      Cancel
                    </Button>
                    {/* {console.log(parseInt(moment.duration(moment(new Date()).diff(moment(this.props?.laDetails
                      ?.actual_gate_in_time)))?.asSeconds()))} */}
                    <Button
                      disabled={this.handleDisabledButton()}
                      onClick={(e) => {
                        e.preventDefault();

                        {
                          parseInt(moment.duration(moment(new Date()).diff(moment(this.props?.laDetails
                            ?.actual_gate_in_time)))?.asSeconds()) > 43200 ?
                            this.handleOpenGateOutReasonDialog() : this.handleGateInGateOut();
                        }

                        //this.handleGateInGateOut();
                      }}
                      variant="contained"
                      style={{
                        backgroundColor: this.handleDisabledButton()
                          ? COLORS.grey
                          : COLORS.primaryColor,
                        color: "white",
                        padding: "12px 48px",
                      }}
                    >
                      {this.handleDisabledButton()
                        ? "DISABLED"
                        : laDetails && laDetails.dispatch_status === "APPROVED"
                          ? "Gate In"
                          : "Gate Out"}
                    </Button>
                    {parseInt(moment.duration(moment(new Date()).diff(moment(this.props?.laDetails
                      ?.actual_gate_in_time)))?.asSeconds()) > 43200 ?
                      <Dialog
                        open={openGateOutReasonDialog}
                        onClose={this.handleCloseGateOutReasonDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="xs"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Please Confirm"}
                        </DialogTitle>
                        <DialogContent>
                          <div style={{
                            minWidth: 340,
                            //margin: '0px 0 15px',
                          }}>
                            <FormControl
                              fullWidth
                              size="small"
                              variant="outlined"
                            >
                              <InputLabel id="shortClose" style={{ marginLeft: "28px" }}>
                                Select Gate Out Reason*
                              </InputLabel>
                              <Select
                                id="Cancellation Reason"
                                labelId="CancelLA"
                                variant="outlined"
                                label=" Select Gate Out Reason*"
                                name="Cancel_LA_Reason"
                                style={{ width: 340, marginLeft: "28px" }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 45 * 4 + 10,
                                    },
                                  },
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }
                                }}
                                value={Gate_Out_Reason}
                                onChange={(e) => { this.handleDropdownChange(e) }}
                              >
                                {this.props?.reasonMaster !== undefined && this.props?.reasonMaster.length > 0
                                  ? this.props?.reasonMaster?.map((data) => (
                                    <MenuItem key={data.reason} value={data?.id}>
                                      {data?.reason}
                                    </MenuItem>
                                  ))
                                  :
                                  <CircularProgress
                                    disableShrink
                                    className={classes.loaderStyle}
                                    style={{ marginLeft: 10 }}
                                  />
                                }
                              </Select>
                            </FormControl>
                          </div>

                          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                            <span>
                              Are you sure you want to Dispatch this LA?
                            </span>
                          </div>
                        </DialogContent>

                        <DialogActions>
                          <Button onClick={this.handleCloseGateOutReasonDialog}>NO</Button>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor:
                                Gate_Out_Reason
                                  ? primaryColor
                                  : 'rgba(0, 0, 0, 0.26)',

                              color: drawerTextColor,
                              textTransform: 'none',
                              padding: '6px 25px'
                            }}
                            disabled={!Gate_Out_Reason}
                            onClick={async (e) => {
                              e.preventDefault();
                              await this.handleGateInGateOut();
                            }}
                          >
                            {
                              showLoader ?
                                <>
                                  <CircularProgress
                                    size={25}
                                    style={{ color: "#FFFFFF" }}
                                  />
                                </> :
                                "YES"
                            }

                          </Button>

                        </DialogActions>

                      </Dialog>
                      : ""
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </Paper>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleSnackbarClose}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
          customAutoHideDuration={3000}
        />
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(GateIn));
