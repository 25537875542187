import React, { Component } from "react";
import AccessDenied from "../../../../components/common/AccessDenied";
import OpenInDesktop from "../../../../components/internal/common/OpenInDesktop";
import axios from "axios";
import withUser from "../../../../hoc/withUser";
import APIROUTES from "../../../../constants/internal/InternalApiRoutes";
import ManageItemNatureData from "../../../../components/internal/masterManagement/itemNature/ManageItemNatureData";
import SnackbarComponent from "../../../../components/internal/common/SnackbarComponent";
import { isAccessibleModule } from "../../../../methods";
import { Route } from "react-router-dom";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import DisplayItemNatureData from "../../../../components/internal/masterManagement/itemNature/web";

class ManageItemNatureContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      itemNatureOperation: null,
      selectedItemNature: null,
      selectedItemNatureData: [],
      selectedItemNatureDataFetchLoader: false,
      snackbarMessage: null,
      openSnackbar: false,
      snackbarType: "",
    };
  }
  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    let itemNatureOperation = this.props.location.pathname.includes("add")
      ? "add"
      : this.props.location.pathname.includes("edit")
        ? "edit"
        : "view";

    this.setState({ itemNatureOperation }, () => console.log(1));
    if (itemNatureOperation !== "add") {
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      this.handleFetchSelectedItemNatureData(id);
      this.setState({ selectedItemNature: id });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let updatedData = new Map();
    let itemNatureOperation = props.location.pathname.includes("add")
      ? "add"
      : props.location.pathname.includes("edit")
        ? "edit"
        : "view";
    let selectedItemNature =
      itemNatureOperation !== "add"
        ? props?.location?.pathname?.split("/")[
        props?.location?.pathname?.split("/").length - 1
        ]
        : null;
    if (itemNatureOperation !== state.itemNatureOperation) {
      updatedData.set("itemNatureOperation", itemNatureOperation);
    }
    if (selectedItemNature !== state.selectedItemNature) {
      updatedData.set("selectedItemNature", selectedItemNature);
    }
    if (updatedData.size > 0) {
      return {
        itemNatureOperation: updatedData.has("itemNatureOperation")
          ? updatedData.get("itemNatureOperation")
          : itemNatureOperation,
        selectedItemNature: updatedData.has("selectedItemNature")
          ? updatedData.get("selectedItemNature")
          : [],
      };
    }
    return null;
  }
  handleFetchSelectedItemNatureData = async (selectedItemNature) => {
    await this.setState({ selectedItemNatureDataFetchLoader: true });
    await axios
      .get(
        `${APIROUTES.ITEM_NATURE}${selectedItemNature}/`,
        this.getOptions(this.props.user)
      )
      .then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              selectedItemNatureData: response.data,
              selectedItemNatureDataFetchLoader: false,
            });
          } else {
            this.setState({
              selectedItemNatureDataFetchLoader: false,
              openSnackbar: true,
              snackbarMessage:
                "Something went wrong while fetching selected item nature data try again!",
              snackbarType: "warning",
            });
          }
        },
        (error) => {
          this.setState({
            selectedItemNatureDataFetchLoader: false,
            openSnackbar: true,
            snackbarMessage:
              "Something went wrong while fetching selected item nature data try again!",
            snackbarType: "error",
          });
        }
      );
  };

  handleItemNatureDataAdd = (itemNatureData) =>
    new Promise((resolve, reject) => {
      // console.log("itemNatureData : ", itemNatureData);
      axios
        .post(
          APIROUTES.ITEM_NATURE,
          itemNatureData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedItemNatureData(response.data.item_nature);
          this.props.getItemNatureList();
          resolve(response);
        })
        .catch(reject);
    });
  handleItemNatureDataUpdate = (itemNatureData, itemNature) =>
    new Promise((resolve, reject) => {
      // console.log("itemNatureData : ", itemNatureData);
      // console.log("itemNatureId : ", itemNatureId);
      const id = this.props?.location?.pathname?.split("/")[
        this.props?.location?.pathname?.split("/").length - 1
      ];
      axios
        .patch(
          `${APIROUTES.ITEM_NATURE}${id}/`,
          itemNatureData,
          this.getOptions(this.props.user)
        )
        .then((response) => {
          this.handleFetchSelectedItemNatureData(itemNature);
          // this.handleFetchSelectedItemNatureData(response.data.item_nature);
          this.props.getItemNatureList();
          resolve(response);
        })
        .catch(reject);
    });
  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: null,
      snackbarType: "",
    });
  };

  render() {
    const { itemNatureOperation } = this.state;
    if (this?.props?.isMobile) return <OpenInDesktop {...this.props} />;
    // if (isAccessibleModule(this.props?.user, "Banks"))
    else {
      return (
        <>
          {itemNatureOperation === "view" ? (
            <DisplayItemNatureData {...this.props} {...this.state} />
          ) : (
            <ManageItemNatureData
              {...this.props}
              {...this.state}
              handleItemNatureDataUpdate={this.handleItemNatureDataUpdate}
              handleItemNatureDataAdd={this.handleItemNatureDataAdd}
            />
          )}
          <SnackbarComponent
            open={this.state.openSnackbar}
            handleClose={this.handleSnackbarClose}
            snackbarMessage={this.state.snackbarMessage}
            type={this.state.snackbarType}
          />
        </>
      );
    }
    // else return <AccessDenied />;
  }
}
export default withUser(ManageItemNatureContainer);
