import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor, errorMessageColor, secondaryColor } from "../../../styles/colors";
import Info from "@material-ui/icons/Info";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import moment from "moment";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { LottieAnimation } from "react-lottie-tools";
import GreenCheck from "../../../styles/b2b/Animations/GreenCheck.json"
import { pxToEm } from "../../../methods";
import { CircularProgress } from "@mui/material";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { lightGreen, successButtonBackground } from "../../../constants/internal/colors";

const styles = {
    orderDetailMainContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    orderDetailChildContainer: {
        borderRadius: "12px",
        width: "98%",
        backgroundColor: '#FFF',
        boxShadow:
            "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
        // boxShadow:
        //     "4px 1px 24px 0px rgba(0, 0, 0.5, 0.1), 1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
    },
    orderDetailChildTopBar: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 20px 0px",
        color: bottomNavigationFontColor,
        alignItems: 'center',
    },
    orderShowMainDetail: {
        padding: "15px 20px",
        display: "flex",
        justifyContent: "space-around",
        height: "80%",
        alignItems: "center",
    },
    orderShowMainDetailLeft: {
        width: "42%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    orderShowMainDetailRight: {
        width: "56%",
        paddingTop: 10,
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    orderShowHorizontalLine: {
        width: "0.4%",
        height: "100%",
        background: bottomNavigationFontColor,
    },
    orderShowMainDetailRightTop: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 0px 3px 10px",
    },
    orderStatusIcon: {

        height: "0.8em",
        width: "0.8em",
        marginRight: "3px",
        top: "2px",
        // animation: "spin 1s infinite linear",
    },
    clickHereToOrderCircle: {
        width: "8px",
        height: "8px",
        background: errorMessageColor,
        borderRadius: "50%",
        marginRight: 5
    }
};

class OrderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noOfDays: 0,
            statusValidity: '',
            noOfRunningDays: "",
        };
    }

    componentDidMount() {
        // if (prevProps.details !== this.props.details) {
        this.handleNoOfdays(this.props.details)
        // }
    }

    handleOngoingProgress = (details) => {
        let progress = 0;
        let dispatch = Number(details.dispatch_detail ? Number(details.dispatch_detail.dispatched_qty).toFixed(3) : Number(details?.dispatched_qty).toFixed(3))
        let Total = Number(Number(details.quantity).toFixed(3))
        progress = (dispatch / Total) * 100
        return progress
    }


    handleNoOfdays = (data) => {
        // console.log(data)
        let status = data.order_status
        let orderValidity = data.order_validity
        let createdAt = data.created_at
        let OrderCloseDate = data.order_close_date
        if (status !== "CLOSED") {
            let daysTime = new Date(orderValidity).getTime() - new Date().getTime()
            let noOfDays = Number((daysTime / (1000 * 60 * 60 * 24)).toFixed(0))
            this.setState({ noOfDays: noOfDays },
                () => this.handleStatusValidity(this.props.title, data, this.state.noOfDays))
        }
        else {
            let daysTime = new Date(OrderCloseDate == null ? createdAt : OrderCloseDate).getTime() - new Date(createdAt).getTime()
            let noOfRunningDays = Number((daysTime / (1000 * 60 * 60 * 24)).toFixed(0))
            this.setState({ noOfRunningDays: noOfRunningDays })
        }
    }

    handleStatusValidity = (title, details, noOfDays) => {
        // console.log(title, details, this.state.noOfDays, ["PENDING", "Approved", "Ongoing"].includes(title) && noOfDays < 0)
        if (["Approved", "Ongoing"].includes(title) && noOfDays < 0) {
            this.setState({ statusValidity: 'expire' });
        }
        else
            this.setState({ statusValidity: 'valid' });
    }

    render() {
        const { classes, title, details, isMobile } = this.props;
        const { noOfDays, statusValidity } = this.state;
        // console.log(details)
        // console.log(this.state.previousDetails)
        // console.log(this.props.isMobile)
        return (
            <div>
                {details &&
                    <div className={classes.orderDetailMainContainer}>
                        <div className={classes.orderDetailChildContainer}>
                            <div style={styles.orderDetailChildTopBar}>
                                <span
                                    style={{ fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13.5) }}
                                >
                                    Contract {details.order_number}
                                </span>
                                <span
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(13.5),
                                        color: title === "CLOSED"
                                            && lightGreen

                                    }}
                                >
                                    {
                                        statusValidity === 'expire'
                                            ? <div style={{ position: 'relative', transform: 'scale(0.8)' }}>
                                                <span><ShieldOutlinedIcon /></span>
                                                <span style={{ position: 'absolute', left: 0, transform: 'scale(0.7)' }}>
                                                    <PersonOutlinedIcon />
                                                </span>
                                            </div>
                                            :
                                            title === "Approved" ?
                                                <div style={{ position: 'relative', marginRight: 5, }}>
                                                    <CircularProgress
                                                        variant="determinate"
                                                        value={100}
                                                        size={15}
                                                        thickness={4}
                                                        color="inherit"
                                                    />
                                                    <CircularProgress
                                                        style={{ position: 'absolute', left: 0, color: lightGreen }}
                                                        variant="determinate"
                                                        // disableShrink
                                                        value={5}
                                                        size={15}
                                                        thickness={4}
                                                    />
                                                </div>
                                                // <CheckCircleOutlinedIcon className={classes.orderStatusIcon} style={{ color: "green" }} />
                                                :
                                                title === "Cancelled" ?
                                                    null
                                                    :
                                                    title === "Ongoing" ?
                                                        <div style={{ position: 'relative', marginRight: 5, }}>
                                                            <CircularProgress
                                                                variant="determinate"
                                                                value={100}
                                                                size={15}
                                                                thickness={4}
                                                                color="inherit"
                                                            />
                                                            <CircularProgress
                                                                style={{ position: 'absolute', left: 0 }}
                                                                variant="determinate"
                                                                // disableShrink
                                                                value={this.handleOngoingProgress(details) <= 5 ? 5 : this.handleOngoingProgress(details)}
                                                                size={15}
                                                                thickness={4}
                                                            />
                                                        </div>
                                                        :
                                                        ["PENDING", "CONVERSION_ALLOTMENT_HOLD", "HOLD"].includes(title) && (details.is_customer_approval_needed && !details.is_approved_by_customer) ?
                                                            <span
                                                                style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.props.history.push({ pathname: `/orders/action/${details.id}`, state: details });
                                                                }}
                                                            >
                                                                <span style={styles.clickHereToOrderCircle} />
                                                            </span>
                                                            : ["PENDING", "CONVERSION_ALLOTMENT_HOLD", "HOLD"].includes(title) && !(details.is_customer_approval_needed && !details.is_approved_by_customer) ?
                                                                <div style={{ position: 'relative', transform: 'scale(0.8)' }}>
                                                                    <span><ShieldOutlinedIcon /></span>
                                                                    <span style={{ position: 'absolute', left: 0, transform: 'scale(0.7)' }}>
                                                                        <PersonOutlinedIcon />
                                                                    </span>
                                                                </div>
                                                                :
                                                                title === "CLOSED" ?
                                                                    <span style={{ transform: 'scale(0.7)' }}><CheckCircleIcon /></span>
                                                                    : null

                                    }
                                    <span
                                        style={{
                                            color: title === "Ongoing" && noOfDays > 0 ? '#1976d2' : (title === "Approved" && noOfDays > 0) && lightGreen,
                                            paddingBottom: statusValidity === 'expire'
                                                ? 2
                                                : title === "Ongoing" && noOfDays > 0
                                                    ? 8
                                                    : ["PENDING", "CONVERSION_ALLOTMENT_HOLD", "HOLD"].includes(title)
                                                    && 2

                                        }}
                                    >
                                        {statusValidity === 'expire'
                                            ? "Expired"
                                            : title === "CONVERSION_ALLOTMENT_HOLD"
                                                ? "On Hold"
                                                : title === "PENDING" && (details.is_customer_approval_needed && !details.is_approved_by_customer)
                                                    ? ' click to confirm'
                                                    : title === "PENDING" && !(details.is_customer_approval_needed && !details.is_approved_by_customer)
                                                        ? "Pending"
                                                        : title === "CLOSED"
                                                            ? "Closed"
                                                            : title === "HOLD"
                                                                ? "On Hold"
                                                                : title === "Ongoing" && noOfDays > 0
                                                                    ? "Ongoing"
                                                                    : title
                                        }
                                    </span>
                                </span>
                            </div>
                            <div className={classes.orderShowMainDetail}>
                                <div className={classes.orderShowMainDetailLeft}>
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            fontSize: window.innerWidth < 310 ? pxToEm(15.5) : window.innerWidth < 375 ? pxToEm(16.5) : "1.4em",
                                        }}
                                    >
                                        {details.item_category.category_name}
                                        {details.tag
                                            ? <span style={{ fontSize: pxToEm(11) }}>
                                                {' (' + details.tag + ')'}
                                            </span>
                                            : null}
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            fontSize: window.innerWidth < 310 ? pxToEm(15.5) : window.innerWidth < 375 ? pxToEm(16.5) : "1.4em"
                                        }}
                                    >
                                        {`₹${parseInt(details.net_rate).toFixed(0)}`}
                                    </span>

                                    <span
                                        style={{
                                            fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 ? pxToEm(11.5) : "0.8em",
                                            color: bottomNavigationFontColor,
                                            marginTop: "3px",
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {
                                            this.props.details.order_status === "CLOSED"
                                                ? <span>{moment(details.created_at).format("DD-MMM") + " to " + moment(details.order_close_date == null ? details.created_at : details.order_close_date).format("DD-MMM-YY")}</span>
                                                : <span>{"created on " + moment(details.created_at).format("DD-MMM-YY")}</span>
                                        }
                                    </span>
                                    <span
                                        style={{
                                            fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 ? pxToEm(11.5) : "0.8em",
                                            color: bottomNavigationFontColor,
                                            marginTop: "3px",
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {statusValidity === 'expire'
                                            ? Math.abs(this.state.noOfDays) + " days ago"
                                            : ["APPROVED"].includes(this.props.details.order_status)
                                                ? ""
                                                : ["CANCELLED"].includes(this.props.details.order_status)
                                                    ? "-"
                                                    : ["CLOSED"].includes(this.props.details.order_status)
                                                        ? this.state.noOfRunningDays + " days"
                                                        : ["ONGOING", "HOLD", "CONVERSION_ALLOTMENT_HOLD"].includes(this.props.details.order_status)
                                                            ? this.state.noOfDays >= 0
                                                                ? this.state.noOfDays + " days left"
                                                                : Math.abs(this.state.noOfDays) + " days ago"
                                                            : " "}
                                    </span>
                                </div>
                                <div className={classes.orderShowHorizontalLine}></div>
                                <div className={classes.orderShowMainDetailRight}>
                                    {
                                        title !== "Action needed" &&
                                        <div className={classes.orderShowMainDetailRightTop}>
                                            <span
                                                style={{
                                                    color: bottomNavigationFontColor,
                                                    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                                }}
                                            >
                                                Pending
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                                }}
                                            >{`${details.dispatch_detail ? Number(details.dispatch_detail.pending).toFixed(3) : Number(details?.pending_qty).toFixed(3)}`} MT</span>
                                        </div>
                                    }
                                    {
                                        title !== "Action needed" &&

                                        <div className={classes.orderShowMainDetailRightTop}>
                                            <span
                                                style={{
                                                    color: bottomNavigationFontColor,
                                                    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                                }}
                                            >
                                                Dispatched
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                                }}
                                            >{`${details.dispatch_detail ? Number(details.dispatch_detail.dispatched_qty).toFixed(3) : Number(details?.dispatched_qty).toFixed(3)}`} MT</span>
                                        </div>
                                    }
                                    <div className={classes.orderShowMainDetailRightTop}>
                                        <span
                                            style={{
                                                color: bottomNavigationFontColor,
                                                fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                            }}
                                        >
                                            Total
                                        </span>
                                        <span
                                            style={{
                                                fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                            }}
                                        >{`${Number(details.quantity).toFixed(3)}`} MT</span>
                                    </div>
                                    <div className={classes.orderShowMainDetailRightTop}>
                                        <span
                                            style={{
                                                color: bottomNavigationFontColor,
                                                fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                            }}
                                        >
                                            Balance
                                        </span>
                                        <span
                                            style={{
                                                fontSize: window.innerWidth < 310 ? pxToEm(12) : window.innerWidth < 375 && pxToEm(13)
                                            }}
                                        >{Number(details.dispatch_detail.available_qty).toFixed(3)} MT</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default withStyles(styles)(OrderCard);
