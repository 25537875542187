import { forwardRef, useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Slide,
  TextField,
} from "@material-ui/core";
import { Paper } from "@mui/material";
// import Table from "../../common/Table";
import { primaryColor, secondaryColor } from "../../../../styles/colors";
import { drawerTextColor, grey } from "../../../../constants/internal/colors";
import ToolTipComponent from "../../common/ToolTipComponent";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { checkInvalidValue, hasSpecialAccess, hasWriteAccess } from "../../../../methods";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import TableDragDrop from "../../common/TableDragDrop";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Print from "@material-ui/icons/Print";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { rest } from "lodash";
import HOST from "../../../../constants/common/host";
import debounce from "lodash.debounce";

const styles = {
  ButtonContainer: {
    display: "flex",
    margin: "10px 0 10px 0",
    padding: "30px 0 0 30px",
  },
  HelperTextContainer: {
    display: "flex",
    paddingLeft: 30
  },
};

const SelectHeader = ({
  selectedHeader,
  handleHeaderSelect,
  allHeaderLoader,
  dropdownData,
  disableOptions,
  handleTableDataChangeValidation,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleDropdownSelection = async (newValue) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation)
      handleHeaderSelect(newValue);
  }

  return (
    <Autocomplete
      open={open} // Controlled open state
      options={
        allHeaderLoader
          ? ["Loading..."]
          : dropdownData
            ? dropdownData.filter((option) => !selectedHeader.includes(option.id))
            : []
      }
      getOptionLabel={(option) =>
        allHeaderLoader ? option : option.general_name
      }
      groupBy={(option) => option.group === null ? 'OTHERS' : option.group?.toUpperCase()}
      getOptionDisabled={(option) => option === "Loading..."}
      value={[]}
      onOpen={handleClick}
      onClose={(e, reason) => {
        if (reason === "select-option") setOpen(true);
        else setOpen(false);
      }}
      onChange={(e, newValue) => handleDropdownSelection(newValue)}
      size="small"
      disabled={disableOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Header"
          margin="normal"
          variant="outlined"
        />
      )}
    />
  );
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SaveDialog = ({
  type,
  open,
  handleClose,
  handleSaveClick,
  orderByData = "",
}) => {
  const [templateName, setTemplateName] = useState("");

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
    >
      <DialogTitle></DialogTitle>
      {type === "cancelRequest" ? (
        <DialogContent>
          Are you sure you want to cancel the request?
        </DialogContent>
      )
        : type === "resetRequest" ? (
          <DialogContent>
            Are you sure you want to reset the request?
          </DialogContent>
        )
          :
          (
            type === "template" && (
              <DialogContent>
                Please Provide Template Name
                <div style={{ margin: "20px 0" }}>
                  <TextField
                    id="outlined-basic"
                    label="Template Name"
                    style={{ width: 360 }}
                    variant="outlined"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    size="small"
                  />
                </div>
              </DialogContent>
            )
          )}
      <DialogActions style={{ margin: "15px 0 10px" }}>
        <Button
          onClick={() => {
            if (type === "cancelRequest") {
              handleSaveClick('cancel')
            } else if (type === "resetRequest") {
              handleSaveClick('reset')
            } else if (type === "template") {
              handleSaveClick(templateName, orderByData);
              setTemplateName(null);
            }
            handleClose();
          }}
          disabled={type === "template" ? !templateName : false}
          variant="contained"
          style={{
            backgroundColor:
              type === "template"
                ? templateName
                  ? primaryColor
                  : "rgba(0,0,0,0.26)"
                : primaryColor,
            color: drawerTextColor,
          }}
        >
          {type === "cancelRequest" || type === 'resetRequest' ? "YES" : "SAVE"}
        </Button>
        <Button
          onClick={() => {
            setTemplateName(null);
            handleClose();
          }}
          variant="text"
        >
          {type === "cancelRequest" || type === 'resetRequest' ? "NO" : "CANCEL"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ModuleHeaderSelectionTable = ({
  user,
  handleTabValueTitle,
  tableDataLoader,
  buttonClickLoader,
  filterValueApiLoader,
  selectedHeaderTableData,
  filterType,
  handleFilterTypes,
  handleCalculationTypes,
  handleModuleHeaderRemove,
  handleTableDataOrder,
  handleRequestReportClick,
  handleCancelResetRequestReport,
  handleSaveTemplateClick,
  handleOpenSnackbar,
  handleCloseSnackbar,
  tabActivetemplate,
  appliedTemplateData,
  handleTableDataChangeValidation,
  FetchMasterFilterValueSearchApiData,
  orderByData,
  handleTableOrderBy,
  handleTableDataIsChangedValidation,
}) => {
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [dataChangeCheckLoader, setDataChangeCheckLoader] = useState(false);

  // const handleOrderByData = (data) => {
  //   // console.log(data)
  //   setOrderByData(data);
  // };

  const handleSaveDialogClose = () => {
    setOpenSaveDialog(false);
  };

  const handleFilterValueError = () => {
    let error = false;
    selectedHeaderTableData.map((data) => {
      // console.log(data, data.is_filter && (Array.isArray(data.filter_value) ? Boolean(data.filter_value?.length <= 0) : !data.filter_value))
      if (data.is_filter && (Array.isArray(data.filter_value) ? Boolean(data.filter_value?.length <= 0) : ['', null, undefined].includes(data.filter_value))) {
        error = true;
      }
    });
    return error;
  };

  const handleOrderByError = () => {
    let error = false;
    if (selectedHeaderTableData.length > 1) {
      if (
        !orderByData?.id ||
        !orderByData?.order_by ||
        !Boolean(
          selectedHeaderTableData?.find(
            (d) => Number(d.id) === orderByData.id
          )
        )
      ) {
        error = true;
      }
    }
    return error;
  };

  const tableHeader = [
    {
      minWidth: 50,
      header: "",
      key: "id",
      headerAlign: "left",
      flex: 1,
    },
    {
      minWidth: 240,
      header: "Name",
      key: "field_name",
      headerAlign: "left",
      flex: 1,
    },
    {
      minWidth: 260,
      header: "Filter",
      key: "filter_type",
      headerAlign: "left",
      flex: 1,
    },
    {
      minWidth: 340,
      header: "Filter Value",
      key: "filter_value",
      headerAlign: "left",
      flex: 1,
    },
    {
      minWidth: 110,
      header: "Calculation",
      key: "calculation_type",
      headerAlign: "left",
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
    },
    {
      minWidth: 80,
      header: "",
      key: "action",
      sortable: false,
      headerAlign: "left",
      flex: 1,
    },
  ];
  const handleOpenDialog = async () => {
    let res = 'is_changed'
    // console.log(appliedTemplateData?.status, tabActivetemplate?.template_detail?.status, Boolean(appliedTemplateData?.status === 'SAVED' || tabActivetemplate?.template_detail?.status === 'SAVED'))
    if (Boolean(appliedTemplateData?.status === 'SAVED' || tabActivetemplate?.template_detail?.status === 'SAVED')) {
      res = await handleTableDataIsChangedValidation(selectedHeaderTableData, { order_by_field: orderByData?.id, order_by: orderByData.order_by }, 'saveTemplate')
    }
    console.log(res, 'res')
    if (res === 'is_changed') {
      setDataChangeCheckLoader(false)
      setOpenSaveDialog("template");
    }
    else {
      setDataChangeCheckLoader(false)
      handleOpenSnackbar("No changes detected in the template data. It's already saved.", "warning");
      setTimeout(() => {
        handleCloseSnackbar();
      }, 5000)
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // width: '65%'
        width: "100%",
      }}
    >
      <Paper>
        <div>
          <TableDragDrop
            user={user}
            handleTabValueTitle={handleTabValueTitle}
            tableDataLoader={tableDataLoader}
            data={selectedHeaderTableData}
            headers={tableHeader}
            filterTypes={filterType}
            handleFilterTypes={handleFilterTypes}
            handleCalculationTypes={handleCalculationTypes}
            handleModuleHeaderRemove={handleModuleHeaderRemove}
            handleTableDataOrder={handleTableDataOrder}
            orderByData={orderByData}
            handleTableOrderBy={handleTableOrderBy}
            tabActivetemplate={tabActivetemplate}
            appliedTemplateData={appliedTemplateData}
            handleTableDataChangeValidation={handleTableDataChangeValidation}
            FetchMasterFilterValueSearchApiData={FetchMasterFilterValueSearchApiData}
            filterValueApiLoader={filterValueApiLoader}
            tableHeight={"calc(100vh - 250px)"}
          />
        </div>
      </Paper>
      <div style={styles.ButtonContainer}>
        <ToolTipComponent
          title={'You have no create permission on this widget'}
          condition={!["IN_PROGRESS", "PENDING", "COMPLETED"].includes(tabActivetemplate?.status) && !hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
        >
          <Button
            style={{
              backgroundColor:
                selectedHeaderTableData.length <= 0 || buttonClickLoader === 'requestReport' ||
                  ["IN_PROGRESS", "PENDING", "COMPLETED"].includes(tabActivetemplate?.status) ||
                  !["IN_PROGRESS", "PENDING", "COMPLETED"].includes(tabActivetemplate?.status) && !hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
                  ? "rgba(0,0,0,0.26)"
                  : primaryColor,
              color:
                selectedHeaderTableData.length <= 0
                  ? "rgba(0,0,0,0.26)"
                  : drawerTextColor,
              fontSize: "0.9em",
              marginRight: 20,
            }}
            variant="contained"
            disabled={
              selectedHeaderTableData.length <= 0 || buttonClickLoader === 'requestReport' ||
              ["IN_PROGRESS", "PENDING", "COMPLETED"].includes(tabActivetemplate?.status) ||
              !["IN_PROGRESS", "PENDING", "COMPLETED"].includes(tabActivetemplate?.status) && !hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
            }
            onClick={(e) => {
              e.preventDefault();
              if (!["IN_PROGRESS", "PENDING", "COMPLETED"].includes(tabActivetemplate?.status) && hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`) || buttonClickLoader !== 'requestReport') {
                if (!handleFilterValueError() && !handleOrderByError()) {
                  if (selectedHeaderTableData.length > 0)
                    handleRequestReportClick(orderByData);
                } else if (handleFilterValueError()) {
                  handleOpenSnackbar("Please provide filter value", "warning");
                  setTimeout(() => {
                    handleCloseSnackbar();
                  }, 4000);
                } else if (handleOrderByError()) {
                  handleOpenSnackbar("Please provide order by value", "warning");
                  setTimeout(() => {
                    handleCloseSnackbar();
                  }, 4000);
                }
              }
            }}
            startIcon={
              ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) || buttonClickLoader === 'requestReport' ? (
                <CircularProgress size={12} thickness={5} color="inherit" />
              ) : null
            }
          >
            {["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)
              ? "PROCESSING REQUEST"
              : tabActivetemplate?.status === "COMPLETED"
                ? "REPORT READY"
                : "REQUEST REPORT"}
          </Button>
        </ToolTipComponent>
        {["COMPLETED"].includes(tabActivetemplate?.status) &&
          <>
            <ToolTipComponent
              title={'You have no create permission on this widget'}
              condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
              title2="Report pdf not found"
              condition2={tabActivetemplate?.report_pdf === null}
            >
              <IconButton
                style={{
                  backgroundColor: "inherit",
                  color: tabActivetemplate?.report_pdf ? "#4b87c5" : 'rgba(0,0,0,0.26)',
                  padding: '0 12px',
                }}
                variant="text"
                disabled={!tabActivetemplate?.report_pdf}
                onClick={(e) => {
                  e.preventDefault();
                  if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`) && tabActivetemplate?.report_pdf)
                    window.open(`${HOST}${tabActivetemplate?.report_pdf}`, "_blank");
                }}
              >
                <Print />
              </IconButton>
            </ToolTipComponent>
            <ToolTipComponent
              title={'You have no create permission on this widget'}
              condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
              title2="Report csv not found"
              condition2={tabActivetemplate?.report_csv === null}
            >
              <IconButton
                style={{
                  backgroundColor: "inherit",
                  color: tabActivetemplate?.report_csv ? "#4b87c5" : 'rgba(0,0,0,0.26)',
                  padding: '0 12px',
                }}
                variant="text"
                disabled={!tabActivetemplate?.report_csv}
                onClick={(e) => {
                  e.preventDefault();
                  if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`) && tabActivetemplate?.report_csv)
                    window.open(`${HOST}${tabActivetemplate?.report_csv}`, "_blank");
                }}
              >
                <CloudDownloadOutlinedIcon />
              </IconButton>
            </ToolTipComponent>
          </>
        }
        {["COMPLETED"].includes(tabActivetemplate?.status) && (
          <>
            <ToolTipComponent
              title={'You have no create permission on this widget'}
              condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
            >
              <Button
                style={{
                  backgroundColor: "inherit",
                  color: grey,
                  fontSize: "0.85em",
                }}
                variant="text"
                onClick={(e) => {
                  e.preventDefault();
                  if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`))
                    setOpenSaveDialog("resetRequest");
                }}
              >
                RESET REQUEST
              </Button>
            </ToolTipComponent>
            <SaveDialog
              type="resetRequest"
              open={openSaveDialog === "resetRequest"}
              handleClose={handleSaveDialogClose}
              handleSaveClick={handleCancelResetRequestReport}
            />
          </>
        )
        }
        {["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) && (
          <>
            <ToolTipComponent
              title={'You have no create permission on this widget'}
              condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
            >
              <Button
                style={{
                  backgroundColor: "inherit",
                  color: grey,
                  fontSize: "0.85em",
                }}
                variant="text"
                onClick={(e) => {
                  e.preventDefault();
                  if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`))
                    setOpenSaveDialog("cancelRequest");
                }}
              >
                CANCEL REQUEST
              </Button>
            </ToolTipComponent>
            <SaveDialog
              type="cancelRequest"
              open={openSaveDialog === "cancelRequest"}
              handleClose={handleSaveDialogClose}
              handleSaveClick={handleCancelResetRequestReport}
            />
          </>
        )}
        <ToolTipComponent
          title={'You have no special permission on this widget'}
          condition={!hasSpecialAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
        >
          <Button
            style={{
              backgroundColor: "inherit",
              color:
                selectedHeaderTableData.length <= 0 || buttonClickLoader === 'saveTemplate'
                  // ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)
                  ? "rgba(0,0,0,0.26)"
                  : grey,
              fontSize: "0.85em",
            }}
            variant="text"
            disabled={
              selectedHeaderTableData.length <= 0 || buttonClickLoader === 'saveTemplate' || dataChangeCheckLoader ||
              !hasSpecialAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
            }
            onClick={(e) => {
              e.preventDefault();
              if (hasSpecialAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)) {
                if (buttonClickLoader !== 'saveTemplate') {
                  if (selectedHeaderTableData.length > 0) {
                    setDataChangeCheckLoader(true)
                    handleOpenDialog()
                  }
                }
              }
            }}
            startIcon={
              buttonClickLoader === 'saveTemplate' || dataChangeCheckLoader ? (
                <CircularProgress size={12} thickness={5} color="inherit" />
              ) : null
            }
          >
            SAVE AS TEMPLATE
          </Button>
        </ToolTipComponent>
        <SaveDialog
          type="template"
          open={openSaveDialog === "template"}
          handleClose={handleSaveDialogClose}
          handleSaveClick={handleSaveTemplateClick}
          orderByData={orderByData}
        />
      </div>
    </div>
  );
};

const SearchBar = (props) => {
  const { placeholder, handleSearch, searchValue } = props;

  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      size="small"
      style={{ width: 300, justifyContent: "flex-start", paddingLeft: 15 }}
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => {
        handleSearch(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              style={{
                padding: 0,
                display: searchValue === "" ? "none" : "block",
              }}
              onClick={(e) => {
                handleSearch("");
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
            <IconButton style={{ padding: "0 2px" }}>
              <SearchOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ),
        style: {
          paddingRight: 0,
        },
      }}
    />
  );
};

const SaveTemplatesTable = ({
  user,
  handleTabValueTitle,
  templateSearchbar,
  handleTemplateSearchBar,
  savedTemplate,
  selectedHeader,
  tabActivetemplate,
  handleApplyTemplate,
  handleSavedTemplatePinUnpin,
  handleDeleteSavedTemplate,
  appliedTemplateData,
  buttonClickLoader,
}) => {
  const [applyAlertDialogOpen, setApplyAlertDialogOpen] = useState({
    open: false,
    rowData: {}
  });

  const [savedTemplateDrawerData, setSavedTemplateDrawerData] = useState({
    my_templates: [],
    all_templates: []
  })
  const [searchValue, setSearchValue] = useState("");


  // useEffect(() => {
  //   setSavedTemplateDrawerData(savedTemplate)
  // }, [savedTemplate])

  useEffect(() => {
    // console.log(savedTemplate)
    setSavedTemplateDrawerData(savedTemplate)
  }, [savedTemplate])

  const handleSearchFilterTemplateDebounce = debounce((searchText) => {
    if (searchText === '') {
      setSavedTemplateDrawerData(savedTemplate)
    }
    else {
      const formattedSearchText = searchText.replace(/\s+/g, '').toUpperCase();
      let pinnedFilterTemp = savedTemplate.my_templates.filter(d => {
        const formattedTemplateName = d.template_name.replace(/\s+/g, '').toUpperCase();
        const formattedTemplateCode = d?.template_code?.replace(/\s+/g, '').toUpperCase();
        return formattedTemplateName.includes(formattedSearchText) || formattedTemplateCode && formattedTemplateCode.includes(formattedSearchText);
      })
      let allFilterTemp = savedTemplate.all_templates.filter(d => {
        const formattedTemplateName = d.template_name.replace(/\s+/g, '').toUpperCase();
        const formattedTemplateCode = d?.template_code?.replace(/\s+/g, '').toUpperCase();
        return formattedTemplateName.includes(formattedSearchText) || formattedTemplateCode && formattedTemplateCode.includes(formattedSearchText);
      })
      // console.log(pinnedFilterTemp, allFilterTemp, searchText)
      setSavedTemplateDrawerData({
        all_templates: allFilterTemp,
        my_templates: pinnedFilterTemp,
      })
    }
  }, 300)

  useEffect(() => {
    handleSearchFilterTemplateDebounce(searchValue);
    return () => {
      handleSearchFilterTemplateDebounce.cancel();
    };
  }, [searchValue]);

  const handleSearchFilterTemplate = (searchText) => {
    setSearchValue(searchText);
  };

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ApplyAlertDialog = ({ open, handleClose, handleApplyClick, rowData, }) => {
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        // fullWidth
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          Applying this template will clear the selected headers
        </DialogContent>
        <DialogActions style={{ margin: "15px 0 10px" }}>
          <Button
            onClick={() => {
              handleApplyClick(rowData.id)
              handleClose()
            }}
            variant="contained"
            style={{ backgroundColor: primaryColor, color: drawerTextColor }}
          >
            APPLY
          </Button>
          <Button onClick={handleClose} variant="text">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ActionMenu = ({ rowData, handleDeleteSavedTemplate, buttonClickLoader }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          id="basic-button"
          aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          onClick={handleClick}
          style={{ minWidth: 30, maxWidth: 30 }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* {console.log(rowData)} */}
              <MenuItem style={{ padding: '4px 1px' }}>
                <ToolTipComponent
                  title={'You have no special permission on this widget'}
                  condition={!hasSpecialAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                >
                  <Button
                    startIcon={buttonClickLoader === 'deleteTemplate' ? (
                      <span>
                        <CircularProgress size={12} thickness={5} color="inherit" />
                        <span style={{ marginLeft: 10 }}>
                          <DeleteOutlineOutlinedIcon />
                        </span>
                      </span>
                    ) :
                      <DeleteOutlineOutlinedIcon />
                    }
                    disabled={!hasSpecialAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                    onClick={() => {
                      if (hasSpecialAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)) {
                        handleDeleteSavedTemplate(rowData.id)
                      }
                    }}
                    style={{ width: 125 }}
                  >
                    DELETE
                  </Button>
                </ToolTipComponent>
              </MenuItem>
            </div>
            <Divider variant="middle" style={{ margin: "2px 0" }} />
          </div>
        </Menu>
      </div>
    );
  };

  const handleShowAllApply = (rowData) => {
    // console.log(selectedHeader)
    if (selectedHeader && selectedHeader?.length > 0)
      setApplyAlertDialogOpen({ open: true, rowData: rowData });
    else {
      handleApplyTemplate(rowData.id)
    }
  };

  const handleApplyAlertDialogClose = () => {
    setApplyAlertDialogOpen({ open: false, rowData: {} });
  };

  return (
    <div>
      <Box sx={{ overflow: "auto", padding: "10px 0 0" }}>
        <div style={{ paddingBottom: 20 }}>
          <SearchBar
            searchValue={searchValue}
            placeholder="Search templates"
            handleSearch={handleSearchFilterTemplate}
          />
        </div>
        <div style={{ height: 'calc(100vh - 210px)', overflowY: 'scroll' }}>
          <List subheader={<ListSubheader style={{ backgroundColor: '#FFF' }}>PINNED TEMPLATES</ListSubheader>}>
            {buttonClickLoader === 'fetchSavedTemplate'
              ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={25} thickness={5} color="inherit" />
              </div>
              : (searchValue.length > 0
                ? !checkInvalidValue(savedTemplateDrawerData.my_templates) ? savedTemplateDrawerData.my_templates : []
                : !checkInvalidValue(savedTemplate?.my_templates) ? savedTemplate.my_templates : []
              )?.map((text, index) => (
                <ListItem key={text.template_code + index + 'pin'}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <ListItemText>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontSize: "0.90em",
                            // fontWeight: 500,
                            width: "92%",
                          }}
                        >
                          {text.template_name}
                        </span>
                        <span style={{ color: grey, fontSize: "0.8em" }}>
                          {text.template_code}
                        </span>
                      </div>
                    </ListItemText>
                    <div style={{ display: "flex" }}>
                      <ToolTipComponent
                        title={'You have no create permission on this widget'}
                        condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                      >
                        <Button
                          style={{
                            color:
                              ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) ||
                                !hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
                                ? "rgba(0,0,0,0.26)"
                                : secondaryColor,
                          }}
                          variant="text"
                          onClick={(e) => {
                            if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)) {
                              if (!["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)) {
                                handleShowAllApply(text)
                              }
                            }
                          }}
                          disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) ||
                            !hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                        >
                          Apply
                        </Button>
                      </ToolTipComponent>
                      <ToolTipComponent
                        title={'You have no create permission on this widget'}
                        condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                      >
                        <Checkbox
                          icon={<PushPinIcon />}
                          checkedIcon={<PushPinOutlinedIcon />}
                          disabled={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                          style={{
                            color: hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
                              ? secondaryColor
                              : "rgba(0,0,0,0.26)",
                            "&.Mui-checked": {
                              color: hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
                                ? secondaryColor
                                : "rgba(0,0,0,0.26)",
                            },
                          }}
                          onChange={(e) => {
                            if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)) {
                              if (Boolean(e.target.checked))
                                handleSavedTemplatePinUnpin(text.id, "unpinned");
                            }
                          }}
                        />
                      </ToolTipComponent>
                      <ActionMenu
                        rowData={text}
                        handleDeleteSavedTemplate={handleDeleteSavedTemplate}
                        buttonClickLoader={buttonClickLoader}
                      />
                    </div>
                  </div>
                </ListItem>
              ))}
          </List>
          <Divider style={{ margin: "20px 0" }} />
          <List subheader={<ListSubheader style={{ backgroundColor: '#FFF' }}>ALL TEMPLATES</ListSubheader>}>
            {buttonClickLoader === 'fetchSavedTemplate'
              ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={25} thickness={5} color="inherit" />
              </div>
              : (searchValue.length > 0
                ? !checkInvalidValue(savedTemplateDrawerData.all_templates) ? savedTemplateDrawerData.all_templates : []
                : !checkInvalidValue(savedTemplate?.all_templates) ? savedTemplate.all_templates : []
              ).map((temp, index) => (
                <ListItem key={temp.template_code + index + 'all'}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <ListItemText>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "0.90em", width: "92%" }}>
                          {temp.template_name}
                        </span>
                        <span style={{ color: grey, fontSize: "0.8em" }}>
                          {temp.template_code}
                        </span>
                      </div>
                    </ListItemText>
                    <div style={{ display: "flex" }}>
                      <ToolTipComponent
                        title={'You have no create permission on this widget'}
                        condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                      >
                        <Button
                          style={{
                            color:
                              ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) ||
                                !hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
                                ? "rgba(0,0,0,0.26)"
                                : secondaryColor,
                          }}
                          variant="text"
                          onClick={(e) => {
                            if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)) {
                              if (!["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status))
                                handleShowAllApply(temp)
                            }
                          }}
                          disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) ||
                            !hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                        >
                          Apply
                        </Button>
                      </ToolTipComponent>
                      {/* {console.log(savedTemplateDrawerData?.my_templates, (savedTemplateDrawerData?.my_templates === undefined || savedTemplateDrawerData?.my_templates.length === 0), (!Boolean(savedTemplateDrawerData.my_templates.find(d => d.id === temp.id))))} */}
                      {((savedTemplateDrawerData?.my_templates === undefined || savedTemplateDrawerData?.my_templates.length === 0) ||
                        (!Boolean(savedTemplateDrawerData.my_templates.find(d => d.id === temp.id)))) &&
                        <ToolTipComponent
                          title={'You have no create permission on this widget'}
                          condition={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                        >
                          <Checkbox
                            icon={<PushPinOutlinedIcon />}
                            checkedIcon={<PushPinIcon />}
                            disabled={!hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)}
                            style={{
                              color: hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
                                ? secondaryColor
                                : "rgba(0,0,0,0.26)",
                              "&.Mui-checked": {
                                color: hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)
                                  ? secondaryColor
                                  : "rgba(0,0,0,0.26)",
                              },
                            }}
                            onChange={(e) => {
                              if (hasWriteAccess(user, `${handleTabValueTitle().toLowerCase()}_dynamic_report`)) {
                                if (Boolean(e.target.checked))
                                  handleSavedTemplatePinUnpin(temp.id, "pinned");
                              }
                            }}
                          />
                        </ToolTipComponent>
                      }
                      <ActionMenu
                        rowData={temp}
                        handleDeleteSavedTemplate={handleDeleteSavedTemplate}
                        buttonClickLoader={buttonClickLoader}
                      />
                    </div>
                  </div>
                </ListItem>
              ))}
          </List>
          <ApplyAlertDialog
            open={applyAlertDialogOpen.open}
            handleClose={handleApplyAlertDialogClose}
            handleApplyClick={handleApplyTemplate}
            rowData={applyAlertDialogOpen.rowData}
          />
        </div>
      </Box>
    </div>
  );
};

export {
  SelectHeader,
  SearchBar,
  ModuleHeaderSelectionTable,
  SaveTemplatesTable,
};
