import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Switch
} from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import {
  checkApiError,
  checkDuplicateValue,
  checkEmailAddress,
  checkMobileNumber,
  isPanNumberValid,
  pxToEm,
  UpperCaseText,
  validateGstinNumber
} from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveButton from "../../common/SaveButton";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: secondaryBlueText,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "secondaryBlueText",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

function ManageTransporter(props) {
  const classes = useStyles();
  const [transporterData, setTransporterData] = React.useState(
    props?.selectedTranporterData || {
      acc_code: null,
      acc_name: null,
      addr1: null,
      addr2: null,
      addr3: null,
      city: null,
      city_name: null,
      pin: null,
      district: null,
      state_code: null,
      state_name: null,
      mobile: null,
      email: null,
      gst_number: null,
      pan_no: null,
      transporter_id: null,
      tds_code: null,
      is_active: true
      // doc_no: null,
      // doc_date: null,
    }
  );
  console.log(props.selectedTranporterData)
  const [transporterError, setTransporterError] = React.useState({
    mobile: false,
    email: false
  })
  const [transporterPincodeError, setTransporterPincodeError] = React.useState({
    errorStatus: false,
    errorText: ""
  })
  const [transporterPanError, setTransporterPanError] = React.useState({
    errorStatus: false,
    errorText: ""
  })
  const [transporterGstError, setTransporterGstError] = React.useState({
    errorStatus: false,
    errorText: ""
  })
  const [transporterIdError, setTransporterIdError] = React.useState({
    errorStatus: false,
    errorText: ""
  })
  const [tranporterOperation, setTransporterOperation] = React.useState(
    props.tranporterOperation
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [allStateList, setAllStateList] = React.useState(props.allStateList);
  const [allCityList, setAllCityList] = React.useState(props.allCityList);
  const [pincodeDetail, setPincodeDetail] = React.useState([]);
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  // const [panData, setPanData] = React.useState([]);
  // const [gstData, setGstData] = React.useState([]);
  // const [transportIdData, setTransportIdData] = React.useState([]);

  // React.useEffect(() => {
  //   props.transporterList.map(d => {
  //     setPanData(prevValue => {
  //       return [
  //         ...prevValue,
  //         d.pan_no
  //       ]
  //     });
  //     setGstData(prevValue => {
  //       return [
  //         ...prevValue,
  //         d.gst_number
  //       ]
  //     });
  //     setTransportIdData(prevValue => {
  //       return [
  //         ...prevValue,
  //         d.transporter_id
  //       ]
  //     });
  //   })
  // }, [props.transporterList])

  React.useEffect(() => {
    if (!isEmpty(pincodeDetail)) {
      console.log(pincodeDetail, pincodeDetail.city_detail?.id)
      setTransporterData({
        ...transporterData,
        ['city']: (pincodeDetail?.city_detail?.id).toString(),
        ['city_name']: pincodeDetail?.city_detail?.name,
        ['state_code']: (pincodeDetail?.state_detail?.id).toString(),
        ['state_name']: pincodeDetail?.state_detail?.name,
      });
    }
    console.log(transporterData)
  }, [pincodeDetail])

  React.useEffect(() => {
    console.log("EMAIL: ", Boolean(props.selectedTranporterData.email))
    if (props.tranporterOperation !== 'add') {
      if (props.selectedTranporterData.mobile) {
        (checkMobileNumber(props.selectedTranporterData.mobile) === "error") ?
          setTransporterError({ ...transporterError, mobile: true }) :
          setTransporterError({ ...transporterError, mobile: false });
        (checkEmailAddress(props.selectedTranporterData.email) === "error" && Boolean(props.selectedTranporterData.email)) ?
          setTransporterError({ ...transporterError, email: true }) :
          setTransporterError({ ...transporterError, email: false });
      }
    }
  }, [props?.selectedTranporterData, props.tranporterOperation]);
  const [removeConfirmationDialogData, setRemoveConfirmationDialogData] =
    React.useState({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const handleChange = (event) => {
    const { value, name, checked } = event.target;
    setTransporterData({
      ...transporterData,
      [name]: (name == 'mobile' && value == "")
        ? null
        : (name == 'email' && value == "")
          ? null
          : (name == "is_active")
            ? checked
            : ['pan_no', 'gst_number'].includes(name)
              ? UpperCaseText(value)
              : value,
    });
    if (!value) {
      if (name === 'mobile') {
        setTransporterError({ ...transporterError, mobile: false })
      }
      else if (name === 'email') {
        setTransporterError({ ...transporterError, email: false });
      }
      else if (name === 'pan_no') {
        setTransporterPanError({ errorStatus: true, errorText: 'Invalid Field' })
      }
      else if (name === "pin") {
        setTransporterPincodeError({ errorStatus: true, errorText: "Invalid Pincode" })
      }
      else if (name === "gst_number") {
        setTransporterGstError({ errorStatus: false, errorText: "" })
      }
      else if (name === "transporter_id") {
        setTransporterIdError({ errorStatus: false, errorText: "" })
        setTransporterData({
          ...transporterData,
          ["transporter_id"]: null
        })
      }
    } else {
      if (name === 'mobile') {
        (checkMobileNumber(value) === "error") ?
          setTransporterError({ ...transporterError, mobile: true }) :
          setTransporterError({ ...transporterError, mobile: false })
      }
      if (name === 'email') {
        (checkEmailAddress(value) === "error") ?
          setTransporterError({ ...transporterError, email: true }) :
          setTransporterError({ ...transporterError, email: false })
      }
      else if (name === 'pan_no') {
        // console.log(checkDuplicateValue(props?.transporterList, 'pan_no', value, props?.selectedTranporterData.pan_no))
        !isPanNumberValid(event.target.value)
          ? setTransporterPanError({ errorStatus: true, errorText: "Please Enter 10-digit alpha-numeric no. (i.e ABCDE1234M)" })
          // : panData.includes(UpperCaseText(value))
          // : checkDuplicateValue(props?.transporterList, name, value, props?.selectedTranporterData.pan_no)
          // ? setTransporterPanError({ errorStatus: true, errorText: 'Already exist' })
          : setTransporterPanError({ errorStatus: false, errorText: '' })
      }
      else if (name === 'gst_number') {
        !validateGstinNumber(value)
          ? setTransporterGstError({ errorStatus: true, errorText: "Please Enter 15-digit alpha-numeric no. (i.e 22ABCDE1234M1Z5)" })
          // : gstData.includes(UpperCaseText(value))
          // : checkDuplicateValue(props?.transporterList, name, value, props?.selectedTranporterData?.gst_number)
          // ? setTransporterGstError({ errorStatus: true, errorText: 'Already exist' })
          : setTransporterGstError({ errorStatus: false, errorText: '' })
      }
      else if (name === 'transporter_id') {
        // transportIdData.includes(UpperCaseText(value))
        // checkDuplicateValue(props?.transporterList, name, value, props?.selectedTranporterData?.transporter_id)
        // ? setTransporterIdError({ errorStatus: true, errorText: 'Already exist' })
        // : setTransporterIdError({ errorStatus: false, errorText: '' })
      }
      else if (name === 'pin') {
        if (value.length === 6) {
          setTransporterPincodeError({ errorStatus: false, errorText: "" })
          props
            .handlePincodeDetail(value)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.results.length !== 0) {
                  console.log("HERE", response)
                  setPincodeDetail(response.data.results[0]);
                }
                else {
                  setTransporterPincodeError({ errorStatus: true, errorText: "Pincode is not available" })
                }
              } else {
                handleOpenSnackbar(
                  `Error occured while fetching Pincode Detail please try again`,
                  "error"
                );
              }
            })
            .catch((error) => {
              handleOpenSnackbar(
                handleSnackbarMessageBox(checkApiError(error.response.data)),
                "error"
              );
              setTimeout(() => {
                handleCloseSnackbar();
              }, 4000);
            });
        }
        else {
          setTransporterPincodeError({ errorStatus: true, errorText: "Invalid Pincode" })
        }
      }
    }
  };
  const handleDateChange = (name) => (date) => {
    if (date !== "Invalid Date") {
      setTransporterData({
        ...transporterData,
        [name]: moment(date).format("YYYY-MM-DD"),
      });
    } else {
      console.log("invalid date");
    }
  };

  const handleAutocompleteValueChange = (name) => (event, value) => {
    if (!value) {
      setTransporterData({
        ...transporterData,
        [name]: null,
      });
    } else {
      setTransporterData({
        ...transporterData,
        [name]: value.id,
      });
      if (name === "state_code") {
        // setSelectedState(value);
        props
          .handleFetchAllCity(value.id)
          .then((response) => {
            if (response.status === 200) {
              setAllCityList(response.data);
            } else {
              handleOpenSnackbar(
                `Error occured while fetching city please try again`,
                "error"
              );
            }
          })
          .catch((error) => {
            // handleOpenSnackbar(
            //   `Error occured while fetching city please try again`,
            //   "error"
            // );
            handleOpenSnackbar(
              handleSnackbarMessageBox(checkApiError(error.response.data)),
              "error"
            );
            setTimeout(() => {
              handleCloseSnackbar();
            }, 4000);
          });
      } else if (name === "city") {
        console.log(value)
        // setSelectedCity(value);
      }
    }
  };

  const handleCancelButtonClick = () => {
    props.history.goBack();
  };

  const handleEditSaveButtonClick = () => {
    setIsLoading(true)
    if (transporterData['mobile'] == undefined || transporterData['mobile'] == "") {
      setTransporterData(prevValue => {
        return {
          ...prevValue,
          ['mobile']: null
        }
      })
    }
    if (transporterData['email'] == undefined || transporterData['email'] == "") {
      setTransporterData(prevValue => {
        return {
          ...prevValue,
          ['email']: null
        }
      })
    }
    if (tranporterOperation === "edit") {
      props
        .handleTransporterDataUpdate(transporterData, transporterData.id)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Transport Master Updated Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setIsLoading(false)
              setTimeout(() => props.history.goBack(), 3000)
            }
            // props.history.goBack();
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating transporter details please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (tranporterOperation === "add") {
      console.log(transporterData)
      props
        .handleTransporterDataAdd(transporterData)
        .then((response) => {
          if (response.status === 201) {
            handleOpenSnackbar(
              `Transport Master Created Successfully`,
              "success"
            )
            if (!snackbarData.openSnackbar) {
              setIsLoading(false)
              setTimeout(() =>
                props.history.push(`${INTERNALROUTES.TRANSPORTER}/${response.data.id}`)
                , 3000)
            }
            // props.history.push(
            //   `${INTERNALROUTES.TRANSPORTER}/${response.data.id}`
            // );
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while creating transporter master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          // for (let key in error.response.data) {
          //   handleOpenSnackbar(
          //     `${key + "  :  " + error.response.data[key]}`,
          //     "error"
          //   );
          // }
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };


  React.useEffect(() => {
    if (props.selectedTranporterData) {
      setTransporterData(props?.selectedTranporterData);
    }
    if (props.tranporterOperation) {
      setTransporterOperation(props.tranporterOperation);
    }
    if (props.allStateList) {
      setAllStateList(props.allStateList);
    }

    // if (props.allCityList) {
    //   setAllCityList(props.allCityList);
    // }
  }, [
    props?.selectedTranporterData,
    props.tranporterOperation,
    props.allStateList,
  ]);

  // React.useEffect(() => {
  //   console.log("ye");
  //   let city = props.allCityList.find((c) => c.id === Number(transporterData.city));
  //   console.log(allCityList);
  //   console.log(transporterData);
  //   setSelectedCity(city);
  // }, [allCityList]);

  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "Transporter Master",
                onClick: () => props.history.push(INTERNALROUTES.TRANSPORTER),
              },
              {
                title: tranporterOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACCOUNT CODE *
              </Typography>
              <TextField
                label="Acc Code"
                name="acc_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter acc code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.acc_code || ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACCOUNT NAME *
              </Typography>
              <TextField
                label="Account Name"
                name="acc_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter account name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.acc_name || ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ADDRESS 1 *
              </Typography>
              <TextField
                label="Address 1"
                name="addr1"
                type="text"
                onChange={handleChange}
                placeholder="Enter address 1"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.addr1 || ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ADDRESS 2</Typography>
              <TextField
                label="address 2"
                name="addr2"
                type="text"
                onChange={handleChange}
                placeholder="Enter address 2"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.addr2 || ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>ADDRESS 3</Typography>
              <TextField
                label="Address 3"
                name="addr3"
                type="text"
                onChange={handleChange}
                placeholder="Enter address 3"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.addr3 || ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>DISTRICT *</Typography>
              <TextField
                label="District"
                name="district"
                type="text"
                onChange={handleChange}
                placeholder="Enter district"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.district || ""}
              />
              {/* <Autocomplete
                autoSelect
                options={allDistrictList.length > 0 ? allDistrictList : []}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                value={transporterData?.district}
                onChange={handleAutocompleteValueChange("district")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search District *"
                    margin="normal"
                    variant="outlined"
                  />
                )}
                variant="outlined"
                className={classes.formControl}
                size="small"
              /> */}
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>PINCODE *</Typography>
              <TextField
                label="Pincode"
                name="pin"
                type="text"
                onChange={handleChange}
                placeholder="Enter pin"
                inputProps={{ maxLength: 6, minLength: 6, max: 6 }}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.pin || ""}
                error={transporterPincodeError.errorStatus}
                helperText={transporterPincodeError.errorText}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>CITY *</Typography>
              <TextField
                disabled={true}
                label="Search City"
                name="city"
                type="text"
                // onChange={handleChange}
                placeholder="Enter city"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.city_name || ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>STATE *</Typography>
              <TextField
                disabled={true}
                label="Search State"
                name="state_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter state"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.state_name || ""}
              />
            </div>
            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>CITY *</Typography>
              <Autocomplete
                autoSelect
                // disabled={true}
                options={allCityList.length > 0 ? allCityList : []}
                getOptionLabel={(option) => option.name}
                value={selectedCity}
                onChange={handleAutocompleteValueChange("city")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search City *"
                    margin="normal"
                    variant="outlined"
                  />
                )}
                variant="outlined"
                className={classes.textField}
                size="small"
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>STATE *</Typography>
              <Autocomplete
                autoSelect
                // disabled={true}
                options={allStateList.length > 0 ? allStateList : []}
                getOptionLabel={(option) => option.name}
                value={allStateList.find(
                  (s) => s.id === Number(transporterData.state_code)
                )}
                onChange={handleAutocompleteValueChange("state_code")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search State *"
                    margin="normal"
                    variant="outlined"
                  />
                )}
                variant="outlined"
                className={classes.textField}
                size="small"
              />
            </div> */}
            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>CITY *</Typography>

              <TextField
                label="City"
                name="city"
                type="text"
                onChange={handleChange}
                placeholder="Enter city"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.city || ""}
              />
            </div> */}
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>MOBILE</Typography>
              <TextField
                label="Mobile"
                name="mobile"
                type="text"
                inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                onChange={handleChange}
                placeholder="Enter mobile"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.mobile || null}
                error={transporterError.mobile}
                helperText={transporterError.mobile ? "Invalid Mobile Number" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>EMAIL</Typography>
              <TextField
                label="Email"
                name="email"
                type="text"
                onChange={handleChange}
                placeholder="Enter email"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.email || ""}
                error={transporterError.email}
                helperText={transporterError.email ? "Invalid Email Address" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                GSTIN NUMBER
              </Typography>
              <TextField
                label="Gstin"
                name="gst_number"
                type="text"
                onChange={handleChange}
                placeholder="Enter Gst Number"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 15, minLength: 15, max: 15 }}
                value={transporterData.gst_number}
                error={transporterGstError.errorStatus}
                helperText={transporterGstError.errorText}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PAN NUMBER *
              </Typography>
              <TextField
                label="Pan Number"
                name="pan_no"
                type="text"
                inputProps={{ maxLength: 10, minLength: 10, max: 10 }}
                onChange={handleChange}
                placeholder="Enter pan number"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.pan_no || ""}
                error={transporterPanError.errorStatus}
                helperText={transporterPanError.errorText}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TRANSPORTER ID
              </Typography>
              <TextField
                label="Transporter Id"
                name="transporter_id"
                type="text"
                onChange={handleChange}
                placeholder="Enter transporter id"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.transporter_id || ""}
                error={transporterIdError.errorStatus}
                helperText={transporterIdError.errorText}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS CODE *</Typography>
              <FormControl
                variant="outlined"
                className={classes.textField}
                size="small"
              >
                <Select
                  value={(props?.tdsRateList.find(d => String(d?.tds_code) == String(transporterData?.tds_code)))?.tds_code ||
                    transporterData?.tds_code !== ""
                    ? transporterData?.tds_code
                    : ""
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem value={null} disabled={true}>
                    Select Any
                  </MenuItem>
                  <MenuItem
                    value={transporterData?.tds_code}
                    style={{ display: 'none', color: "black" }}
                    key={transporterData?.tds_code}
                  >
                    {transporterData?.tds_code}
                  </MenuItem>
                  {props?.tdsRateList.map((tds) => (
                    <MenuItem key={tds.id}
                      onClick={(e) => {
                        setTransporterData({
                          ...transporterData,
                          ['tds_code']: `${tds.tds_code}`,
                        });
                      }}
                      value={`${tds.tds_code}`}
                    >{tds.tds_code}-{tds.tds_rate}-{tds.tds_section}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {console.log("isactive", transporterData?.is_active)}
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                ACTIVE STATUS
              </Typography>
              <div className={classes.dataFieldsForCheckboxCustomerAddress}>
                <IOSSwitch
                  checked={transporterData.is_active}
                  onChange={(e) => handleChange(e)}
                  name="is_active"
                />
              </div>
            </div>

            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS CODE *</Typography>
              <TextField
                label="Tds Code"
                name="tds_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter tds code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.tds_code || ""}
              />
            </div> */}
            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                DOC NUMBER *
              </Typography>
              <TextField
                label="Doc No"
                name="doc_no"
                type="text"
                onChange={handleChange}
                placeholder="Enter doc no"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={transporterData?.doc_no || ""}
              />
            </div> */}
            {/* <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>DOC DATE *</Typography>
              <Fragment>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      name="doc_date"
                      size="small"
                      inputVariant="outlined"
                      label="Doc Date *"
                      format="dd/MM/yyyy"
                      value={
                        !transporterData?.doc_date
                          ? null
                          : new Date(transporterData?.doc_date)
                      }
                      onChange={handleDateChange("doc_date")}
                      placeholder="e.g.: DD/MM/YYYY"
                      InputAdornmentProps={{ position: "start" }}
                      InputProps={{ className: classes.datePicker }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Fragment>
            </div> */}
            <SaveButton
              size="medium"
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              disabled={
                !transporterData.acc_code ||
                !transporterData.acc_name ||
                !transporterData.addr1 ||
                !transporterData.district ||
                !transporterData.pin ||
                !transporterData.city_name ||
                !transporterData.state_name ||
                !transporterData.state_code ||
                // !transporterData.mobile ||
                // !transporterData.email ||
                // !transporterData.gst_number ||
                !transporterData.pan_no ||
                // !transporterData.transporter_id ||
                !transporterData.tds_code ||
                transporterData.acc_code === "" ||
                transporterData.acc_name === "" ||
                transporterData.addr1 === "" ||
                transporterData.district === "" ||
                transporterData.pin === "" ||
                transporterData.city_name === "" ||
                transporterData.state_name === "" ||
                transporterData.state_code === "" ||
                // transporterData.mobile === "" ||
                // transporterData.email === "" ||
                // transporterData.gst_number === "" ||
                transporterData.pan_no === "" ||
                // transporterData.transporter_id === "" ||
                transporterData.tds_code === "" ||
                transporterPanError.errorStatus ||
                transporterPincodeError.errorStatus ||
                transporterGstError.errorStatus ||
                transporterIdError.errorStatus ||
                transporterError.mobile ||
                transporterError.email
              }
              isLoading={isLoading}
            >
              {tranporterOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
      {/* <RemoveConfirmationDialog
        open={removeConfirmationDialogData.openDialog}
        dialogTitle={removeConfirmationDialogData.dialogTitle}
        dialogContent={removeConfirmationDialogData.dialogContent}
        removeDataLoader={removePlantItemMasterLoader}
        handleClose={handleRemoveConfirmationDialogClose}
        handleConfirmationDialogDeleteClick={
          handleConfirmationDialogDeleteClick
        }
      /> */}
    </Layout>
  );
}
export default withRouter(ManageTransporter);
