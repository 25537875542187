import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button } from "@material-ui/core";
import {
  grey,
  secondaryBlueText,
  primaryColor,
  cancelButtonBackground,
  drawerTextColor,
  successButtonBackground,
  earthBlue,
  secondaryGrey,
  drawerBackground,
} from "../../../../constants/internal/colors";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { checkAlphabet, checkAlphaNumericLength, checkApiError, checkNumeric, pxToEm } from "../../../../methods";
import SnackbarComponent from "../../common/SnackbarComponent";
import Bread from "../../common/Breadcrubs";
import INTERNALROUTES from "../../../../constants/internal/InternalRoutes";
import { withRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import SaveButton from "../../common/SaveButton";

const useStyles = makeStyles({
  fillingInformation: {
    fontSize: 10,
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },

  generalDetailsDisplaySection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  dataFields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  dataFieldsForGstin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
    width: "45%",
  },
  addMoreAddressButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    border: "1px dashed",
    width: "45%",
    height: "10%",
  },
  titleLabel: {
    fontSize: pxToEm(15),
    color: grey,
    fontWeight: "bold",
  },
  titileLabelDataFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  dataDisplay: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataDisplayForGstin: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexWrap: "wrap",
    paddingBottom: 30,
  },
  dataFieldsForCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px 0px 20px 0px",
  },
  dataFieldsForCheckboxCustomerAddress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  generalText: {
    fontSize: pxToEm(15),
  },
  panImagePreview: {
    height: "100",
    width: "100",
  },
  panImageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    border: "1px solid black",
    borderRadius: "3px",
    width: "150px",
  },
  generalAlign: {
    display: "flex",
    alignItems: "center",
  },
  fileUploadName: {
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleLabelStyle: {
    fontSize: pxToEm(16),
    color: secondaryBlueText,
    fontWeight: "bold",
    marginBottom: 15,
  },
  paperStyles: { padding: 20 },
  addNewAddressBoxButton: {
    placeContent: "center",
    placeItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 10px 0px",
  },
  addressBoxDataDisplay: {
    display: "flex",
    flexDirection: "column",
  },
  addressBoxStyle: {
    paddingBottom: 30,
  },
  gstDetails: { padding: 20 },
  setDisplayWidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  loaderStyle: { color: primaryColor },
  topMargin: { marginTop: 30, width: "50%" },
  removeButton: {
    backgroundColor: cancelButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  saveButton: {
    backgroundColor: successButtonBackground,
    color: drawerTextColor,
    marginRight: 10,
  },
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    fontSize: pxToEm(15),
    marginRight: 10,
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    paddingBottom: "30px",
    overflowY: "scroll",
  },
  textField: {
    width: "70%",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: "transparent",
    width: "70%",
  },
  loaderStyle: { color: primaryColor },
});
const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryBlueText,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
    },
    MuiPickersDay: {
      day: {
        color: earthBlue,
      },
      daySelected: {
        backgroundColor: secondaryBlueText,
      },
      dayDisabled: {
        color: secondaryGrey,
      },
      current: {
        color: drawerBackground,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: earthBlue,
      },
    },
  },
});

function ManageTdsRate(props) {
  const classes = useStyles();
  const [tdsRateData, setTdsRateData] = React.useState(
    props?.selectedTdsRateData || {
      tds_code: null,
      tds_rate: 0,
      basic_rate: 0,
      tds_section: null,
      tds_valid_from: moment(new Date()).format("YYYY-MM-DD"),
      tds_valid_upto: null,
      code_type: null,
      user_code: null,
      parent_code: null,
      tds_acc_code: null,
    }
  );

  const [tdsError, setTdsError] = React.useState({
    tds_code: false,
    tds_name: false,
    tds_rate: false,
    basic_rate: false,
    tds_section: false,
    tds_acc_code: false,
    code_type: false,
    user_code: false,
    parent_code: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [tdsRateOperation, setTdsRateOperation] = React.useState(
    props.tdsRateOperation
  );
  const [snackbarData, setSnackbarData] = React.useState({
    openSnackbar: false,
    snackbarMessage: ``,
    snackbarType: "",
  });
  const [removeConfirmationDialogData, setRemoveConfirmationDialogData] =
    React.useState({
      openDialog: false,
      dialogTitle: ``,
      dialogContent: "",
    });

  React.useEffect(() => {
    if (props.tdsRateOperation !== 'add') {
      if (props?.selectedTdsRateData.tds_code) {
        !checkAlphabet(props?.selectedTdsRateData.tds_code) ?
          setTdsError(prevValue => { return { ...prevValue, tds_code: true } }) :
          setTdsError(prevValue => { return { ...prevValue, tds_code: false } });
      } if (props?.selectedTdsRateData.tds_name) {
        !checkNameField(props?.selectedTdsRateData.tds_name) ?
          setTdsError(prevValue => { return { ...prevValue, tds_name: true } }) :
          setTdsError(prevValue => { return { ...prevValue, tds_name: false } });
      } if (props?.selectedTdsRateData.tds_section) {
        !checkAlphaNumericLength(props?.selectedTdsRateData.tds_section) ?
          setTdsError(prevValue => { return { ...prevValue, tds_section: true } }) :
          setTdsError(prevValue => { return { ...prevValue, tds_section: false } });
      } if (props?.selectedTdsRateData.tds_acc_code) {
        !checkAlphaNumericLength(props?.selectedTdsRateData.tds_acc_code) ?
          setTdsError(prevValue => { return { ...prevValue, tds_acc_code: true } }) :
          setTdsError(prevValue => { return { ...prevValue, tds_acc_code: false } });
      } if (props?.selectedTdsRateData.code_type) {
        !checkNumeric(props?.selectedTdsRateData.code_type) ?
          setTdsError(prevValue => { return { ...prevValue, code_type: true } }) :
          setTdsError(prevValue => { return { ...prevValue, code_type: false } });
      } if (props?.selectedTdsRateData.user_code) {
        !checkAlphaNumeric(props?.selectedTdsRateData.user_code) ?
          setTdsError(prevValue => { return { ...prevValue, user_code: true } }) :
          setTdsError(prevValue => { return { ...prevValue, user_code: false } });
      } if (props?.selectedTdsRateData.parent_code) {
        !checkNumeric(props?.selectedTdsRateData.parent_code) ?
          setTdsError(prevValue => { return { ...prevValue, parent_code: true } }) :
          setTdsError(prevValue => { return { ...prevValue, parent_code: false } });
      }
    }
  }, [props.selectedTdsRateData, props.tdsRateOperation])

  const handleOpenSnackbar = (message, type) => {
    setSnackbarData({
      ...snackbarData,
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({
      ...snackbarData,
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  const handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  const checkAlphaNumeric = (input) => {
    const regex = /^[a-zA-Z0-9]+[-]{0,1}[a-zA-Z0-9]+$/;
    if (regex.test(input)) {
      return true;
    }
    return false;
  };

  const checkNameField = (input) => {
    const regex = /^[0-9a-zA-Z\s]+[a-zA-Z0-9&.,%()-\s]+$/;
    if (regex.test(input)) {
      return true
    }
    return false;
  }

  const handleChange = (event) => {
    const { value, name } = event.target;
    setTdsRateData({
      ...tdsRateData,
      [name]: value,
    });

    if (!value) {
      setTdsError(prevValue => {
        return {
          ...prevValue, [name]: true
        }
      })
    } else {
      if (name === 'tds_code') {
        !checkAlphabet(value) ?
          setTdsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'tds_name') {
        !checkNameField(value) ?
          setTdsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'tds_section') {
        !checkAlphaNumericLength(value) ?
          setTdsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'tds_acc_code') {
        !checkAlphaNumericLength(value) ?
          setTdsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'code_type') {
        !checkNumeric(value) ?
          setTdsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'user_code') {
        !checkAlphaNumeric(value) ?
          setTdsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else if (name === 'parent_code') {
        !checkNumeric(value) ?
          setTdsError(prevValue => { return { ...prevValue, [name]: true } }) :
          setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
      else {
        setTdsError(prevValue => { return { ...prevValue, [name]: false } })
      }
    }
  };
  const handleDateChange = (name) => (date) => {
    if (date !== "Invalid Date") {
      setTdsRateData({
        ...tdsRateData,
        [name]: moment(date).format("YYYY-MM-DD"),
      });
    } else {
      console.log("invalid date");
    }
  };
  const handleCancelButtonClick = () => {
    props.history.goBack();
  };
  const handleEditSaveButtonClick = () => {
    setIsLoading(true)
    if (tdsRateOperation === "edit") {
      props
        .handleTdsRateMasterDataUpdate(tdsRateData, tdsRateData.id)
        .then((response) => {
          if (response.status === 200) {
            handleOpenSnackbar(
              `Tds Rate Updated Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.goBack();
              handleCloseSnackbar()
            }, 4000);
            // props.history.goBack();
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while updating tds rate details please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else if (tdsRateOperation === "add") {
      props
        .handleTdsRateMasterDataAdd(tdsRateData)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            handleOpenSnackbar(
              `Tds Rate Created Successfully`,
              "success"
            );
            setTimeout(() => {
              setIsLoading(false)
              props.history.push(
                `${INTERNALROUTES.TDS_RATE}/${response.data.id}`
              );
              handleCloseSnackbar()
            }, 4000);
          } else {
            setIsLoading(false)
            handleOpenSnackbar(
              `Error occured while creating tds rate master please try again`,
              "error"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false)
          handleOpenSnackbar(
            handleSnackbarMessageBox(checkApiError(error.response.data)),
            "error"
          );
          setTimeout(() => {
            handleCloseSnackbar();
          }, 4000);
        });
    } else {
      console.log("Invalid operation");
    }
  };
  React.useEffect(() => {
    setTdsRateData(props?.selectedTdsRateData);
    setTdsRateOperation(props.tdsRateOperation);
  }, [props?.selectedTdsRateData, props.tdsRateOperation]);
  return (
    <Layout {...props}>
      <div className={classes.mainDiv}>
        <div className={classes.rightPadding}>
          <Bread
            data={[
              {
                title: "Master Management",
                onClick: () =>
                  props.history.push(INTERNALROUTES.MASTERSMANAGMENT),
              },
              {
                title: "TDS Rate Master",
                onClick: () => props.history.push(INTERNALROUTES.TDS_RATE),
              },
              {
                title: tdsRateOperation === "add" ? "Create New" : `Edit `,
                onClick: "",
              },
            ]}
          />
          <div className={classes.topMargin}>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS CODE *</Typography>
              <TextField
                label="Tds Code"
                name="tds_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter tds code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 2, minLength: 2, max: 2 }}
                value={tdsRateData?.tds_code || ""}
                error={tdsError.tds_code}
                helperText={tdsError.tds_code ? "Tds Code should be alphabetic" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS NAME *</Typography>
              <TextField
                label="Tds Name"
                name="tds_name"
                type="text"
                onChange={handleChange}
                placeholder="Enter tds name"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={tdsRateData?.tds_name || ""}
                error={tdsError.tds_name}
                helperText={tdsError.tds_name ? "Invalid Name" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>TDS RATE *</Typography>
              <TextField
                label="Tds Rate"
                name="tds_rate"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={handleChange}
                onKeyDown={(evt) => (
                  ["e", "E", "+", "-"].includes(evt.key) ||
                  (evt.which === 38 || evt.which === 40)
                ) && evt.preventDefault()}
                placeholder="Enter tds rate"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={tdsRateData?.tds_rate || ""}
                error={tdsError.tds_rate}
                helperText={tdsError.tds_rate ? "Invalid Rate" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                BASIC RATE *
              </Typography>
              <TextField
                label="Basic Rate"
                name="basic_rate"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={handleChange}
                onKeyDown={(evt) => (
                  ["e", "E", "+", "-"].includes(evt.key) ||
                  (evt.which === 38 || evt.which === 40)
                ) && evt.preventDefault()}
                placeholder="Enter basic rate"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={tdsRateData?.basic_rate || ""}
                error={tdsError.basic_rate}
                helperText={tdsError.basic_rate ? "Invalid Rate" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS SECTION *
              </Typography>
              <TextField
                label="Tds Section"
                name="tds_section"
                type="text"
                onChange={handleChange}
                placeholder="Enter tds section"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 5, minLength: 5, max: 5 }}
                value={tdsRateData?.tds_section || ""}
                error={tdsError.tds_section}
                helperText={tdsError.tds_section ? "Invalid Section" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS ACCOUNT CODE *
              </Typography>
              <TextField
                label="Tds Account Code"
                name="tds_acc_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter tds acc code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 5, minLength: 5, max: 5 }}
                value={tdsRateData?.tds_acc_code || ""}
                error={tdsError.tds_acc_code}
                helperText={tdsError.tds_acc_code ? "Invalid Tds Account Code" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                CODE TYPE *
              </Typography>

              <TextField
                label="Code Type"
                name="code_type"
                type="text"
                onChange={handleChange}
                placeholder="Enter code type"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 1, minLength: 1, max: 1 }}
                value={tdsRateData?.code_type || ""}
                error={tdsError.code_type}
                helperText={tdsError.code_type ? "Code Type should be numeric" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                USER CODE *
              </Typography>
              <TextField
                label="User Code"
                name="user_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter user code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={tdsRateData?.user_code || ""}
                error={tdsError.user_code}
                helperText={tdsError.user_code ? "Invalid User Code" : ""}
              />
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                PARENT CODE *
              </Typography>

              <TextField
                label="Parent Code"
                name="parent_code"
                type="text"
                onChange={handleChange}
                placeholder="Enter parent code"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                inputProps={{ maxLength: 1, minLength: 1, max: 1 }}
                value={tdsRateData?.parent_code || ""}
                error={tdsError.parent_code}
                helperText={tdsError.parent_code ? "Parent Code should be numeric" : ""}
              />
            </div>

            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS VALID FROM *
              </Typography>
              <Fragment>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      name="tds_valid_from"
                      size="small"
                      inputVariant="outlined"
                      label="Valid From *"
                      format="dd/MM/yyyy"
                      minDate={
                        tdsRateOperation === "add"
                          ? new Date()
                          : undefined
                      }
                      // maxDate={
                      //   tdsRateOperation === "add"
                      //     ? undefined
                      //     : new Date(tdsRateData?.tds_valid_upto)
                      // }
                      value={
                        !tdsRateData?.tds_valid_from
                          ? null
                          : new Date(tdsRateData?.tds_valid_from)
                      }
                      onChange={handleDateChange("tds_valid_from")}
                      placeholder="e.g.: DD/MM/YYYY"
                      InputAdornmentProps={{ position: "start" }}
                      InputProps={{ className: classes.datePicker, readOnly: true }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Fragment>
            </div>
            <div className={classes.dataFields}>
              <Typography className={classes.titleLabel}>
                TDS VALID TO *
              </Typography>
              <Fragment>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      name="tds_valid_upto"
                      size="small"
                      inputVariant="outlined"
                      label="Valid To *"
                      format="dd/MM/yyyy"
                      minDate={
                        tdsRateOperation === "add"
                          ? tdsRateData?.tds_valid_from ? new Date(tdsRateData?.tds_valid_from) : new Date()
                          : new Date(tdsRateData?.tds_valid_from)
                      }
                      value={
                        !tdsRateData?.tds_valid_upto
                          ? null
                          : new Date(tdsRateData?.tds_valid_upto)
                      }
                      onChange={handleDateChange("tds_valid_upto")}
                      placeholder="e.g.: DD/MM/YYYY"
                      InputAdornmentProps={{ position: "start" }}
                      InputProps={{ className: classes.datePicker, readOnly: true }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Fragment>
            </div>

            <SaveButton
              size="medium"
              disabled={
                !tdsRateData?.tds_code ||
                  !tdsRateData?.tds_rate ||
                  !tdsRateData?.basic_rate ||
                  !tdsRateData?.tds_section ||
                  !tdsRateData?.user_code ||
                  !tdsRateData?.tds_valid_from ||
                  !tdsRateData?.tds_valid_upto ||
                  !tdsRateData?.code_type ||
                  !tdsRateData?.parent_code ||
                  tdsError.tds_code ||
                  tdsError.tds_name ||
                  tdsError.tds_rate ||
                  tdsError.basic_rate ||
                  tdsError.tds_section ||
                  tdsError.tds_acc_code ||
                  tdsError.code_type ||
                  tdsError.user_code ||
                  tdsError.parent_code
                  ? true
                  : false
              }
              className={classes.saveButton}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleEditSaveButtonClick}
              isLoading={isLoading}
            >
              {tdsRateOperation === "add" ? "SAVE" : "UPDATE"}
            </SaveButton>
            <Button
              size="medium"
              className={classes.margin}
              onClick={handleCancelButtonClick}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
      <SnackbarComponent
        open={snackbarData.openSnackbar}
        handleClose={handleCloseSnackbar}
        snackbarMessage={snackbarData.snackbarMessage}
        type={snackbarData.snackbarType}
      />
      {/* <RemoveConfirmationDialog
        open={removeConfirmationDialogData.openDialog}
        dialogTitle={removeConfirmationDialogData.dialogTitle}
        dialogContent={removeConfirmationDialogData.dialogContent}
        removeDataLoader={removePlantItemMasterLoader}
        handleClose={handleRemoveConfirmationDialogClose}
        handleConfirmationDialogDeleteClick={
          handleConfirmationDialogDeleteClick
        }
      /> */}
    </Layout>
  );
}
export default withRouter(ManageTdsRate);
