import React from 'react'
import AdminProfile from '../../../components/internal/AdminProfile/web/AdminProfile';
import Layout from '../../../layouts/internalLayout/InternalLayout';
import axios from 'axios';
import APIROUTES from "../../../constants/internal/InternalApiRoutes";


function UserProfileContainer(props) {

    const changePassword = (oldPassword, newPassword) =>
        new Promise((resolve, reject) => {
            axios
                .post(APIROUTES.CHANGE_PASSWORD, {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    credential: props.user.employee_id,
                })
                .then(resolve)
                .catch(reject);
        });

    return (
        <Layout {...props}>

            <AdminProfile {...props} changePassword={changePassword} />

        </Layout>
    )
}

export default UserProfileContainer