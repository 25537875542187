import NoDataFound from "../../common/NoDataFound";
import { grey, secondaryGrey } from "../../../constants/internal/colors";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Autocomplete } from "@material-ui/lab";
import ToolTipComponent from "./ToolTipComponent";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { MuiDateRangePicker } from "../dashboard/web/DateRangePicker";
import EventIcon from "@mui/icons-material/Event";
import { makeStyles } from "@material-ui/core/styles";
import { checkInvalidValue } from "../../../methods";
import CircularProgressLoader from "../../common/CircularProgressLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      height: 7,
      width: 7,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(75, 135, 197, 0.9)",
      borderRadius: 10,
      height: 10,
      border: "2px solid transparent",
      backgroundClip: "padding-box", // <== make the border work
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(75, 135, 197, 0.9)",
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
  },
  tableCellWidth15: {
    color: grey,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: "0.9em",
    fontWeight: "bold",
  },
}));

const styles = {
  tableContainerStyle: {
    overflowY: "scroll",
    minHeight: "100%",
    height: "calc(100vh - 250px)",
  },
  tableCell: (width) => ({
    color: grey,
    width: width,
    minWidth: width > 90 ? 200 : width,
    fontSize: "0.9em",
    fontWeight: "bold",
  }),
  noDataDiv: {
    display: "flex",
    position: "absolute",
    left: "40vw",
    top: "0vh",
    width: "max-content",
    height: "100%",
    zIndex: 999,
    transform: "scale(0.9)",
  },
};

const ORDER = [
  { id: "ASC", order_by: "ASC" },
  { id: "DESC", order_by: "DESC" },
];

const BooleanValue = [
  { id: true, filter_value: "TRUE", },
  { id: false, filter_value: "FALSE", },
];

const BooleanYesNoValue = [
  { id: 'YES', filter_value: "YES", },
  { id: "NO", filter_value: "NO", },
];

const FilterMasterDropdown = (props) => {
  const {
    row,
    handleFilterTypes,
    disableOption,
    handleTableDataChangeValidation,
    filterValueApiLoader,
  } = props;

  const [filteredApiData, setFilteredApiData] = useState([]);
  const [dropdownValue, setDrodownValue] = useState([]);
  const [dropdownPayloadValue, setDrodownPayloadValue] = useState([]);
  const [dropdownValueId, setDrodownValueId] = useState([]);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (filteredApiData.length <= 0 && Boolean(row?.apiData)) {
      setFilteredApiData(row.apiData);
      // console.log(row, row?.filter_value[0]?.display_name)
      if (row?.filter_value[0]?.display_name) {
        console.log("H!!", row)
        setDrodownPayloadValue(row?.filter_value)
        setDrodownValueId(row?.filter_value.map(d => { return d.value }))
        setDrodownValue(row?.filter_value.map(d => {
          return {
            [row.headerData?.api_field_payload]: d.value,
            [row.headerData?.api_field_name || "first_name"]: d.display_name,
          }
        })
        )
      }
    }
  }, [row.apiData]);

  const handleChange = async (newValue, reason) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      // console.log(newValue, "newValue", reason)
      setDrodownValueId(newValue.map(d => d[row.headerData?.api_field_payload]))
      let dataChange = newValue.map(d => {
        return {
          value: d[row.headerData?.api_field_payload],
          display_name: d[row.headerData?.api_field_name || "first_name"],
        }
      })
      setDrodownPayloadValue(dataChange)
      setDrodownValue(newValue.map(d => {
        return {
          [row.headerData?.api_field_payload]: d[row.headerData?.api_field_payload],
          [row.headerData?.api_field_name || "first_name"]: d[row.headerData?.api_field_name || "first_name"],
        }
      }))
      handleFilterTypes(row, row?.id, "filter_value", dataChange, '', 'master')
    }
  };

  useEffect(() => {
    // console.log("here dropdownPayloadValue", dropdownPayloadValue)
    handleFilterTypes(row, row?.id, "filter_value", dropdownPayloadValue, '', 'master')
  }, [dropdownPayloadValue])

  // console.log(filteredApiData, dropdownValueId, filteredApiData?.filter((option) => !dropdownValueId.includes(option[row.headerData?.api_field_payload])))

  return (
    <div style={{ width: "100%" }}>
      {filterValueApiLoader[Number(row?.id)] === true
        ? <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <CircularProgress size={25} thickness={5} style={{ color: "#4b87c5", }} />
        </div>
        : <Autocomplete
          open={open}
          multiple
          id="tags-standard"
          options={filteredApiData && Array.isArray(filteredApiData) && filteredApiData.length > 0
            ? filteredApiData?.filter((option) => !dropdownValueId.includes(option[row.headerData?.api_field_payload]))
            : []
          }
          getOptionLabel={(option) => option[row.headerData?.api_field_name || "first_name"]}
          size="small"
          value={dropdownValue || null}
          getOptionSelected={(option, value) => option[row.headerData?.api_field_payload] === value[row.headerData?.api_field_payload]}
          onChange={(e, newValue, reason) => handleChange(newValue, reason)}
          disabled={disableOption}
          // onBlur={(e) =>
          //   // console.log(dropdownValue, "dropdownValue")
          //   handleFilterTypes(row, row?.id, "filter_value", dropdownPayloadValue, '', 'master')
          // }
          onOpen={handleClick}
          onClose={(e, reason) => {
            if (reason === "select-option") setOpen(true);
            else setOpen(false);
          }}
          required={true}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: "12px 0" }}
              variant="outlined"
            />
          )}
        />
      }
    </div>
  );
};
const FilterMasterSearchDropdown = (props) => {
  const {
    row,
    handleFilterTypes,
    disableOption,
    handleTableDataChangeValidation,
    FetchMasterFilterValueSearchApiData
  } = props;

  const [filteredApiData, setFilteredApiData] = useState(row?.apiData || []);
  const [inputValue, setInputValue] = React.useState('');
  const [dropdownValue, setDrodownValue] = useState([]);
  const [dropdownPayloadValue, setDrodownPayloadValue] = useState([]);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {

    if (dropdownPayloadValue.length <= 0 && row?.filter_value?.length > 0) {
      if (row?.filter_value[0]?.display_name) {
        console.log("H!!")
        setDrodownPayloadValue(row?.filter_value)
        setDrodownValue(row?.filter_value.map(d => {
          return {
            [row.headerData?.api_field_payload]: d.value,
            [row.headerData?.api_field_name || "first_name"]: d.display_name,
          }
        })
        )
      }
    }
  }, [row]);

  const handleSearchChange = async (newInputValue) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      let apiData = await FetchMasterFilterValueSearchApiData(row?.headerData.api_url, newInputValue);
      setFilteredApiData(apiData)
    }
  }

  const handleChange = async (newValue) => {
    // console.log(newValue, "newValue")
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      let dataChange = newValue.map(d => {
        return {
          value: d[row.headerData?.api_field_payload],
          display_name: d[row.headerData?.api_field_name || "first_name"],
        }
      })
      setDrodownPayloadValue(dataChange)
      setDrodownValue(newValue.map(d => {
        return {
          [row.headerData?.api_field_payload]: d[row.headerData?.api_field_payload],
          [row.headerData?.api_field_name || "first_name"]: d[row.headerData?.api_field_name || "first_name"],
        }
      })
      )
      handleFilterTypes(row, row?.id, "filter_value", dataChange, '', 'master')
    }
  }

  useEffect(() => {
    // console.log("here dropdownPayloadValue", dropdownPayloadValue)
    handleFilterTypes(row, row?.id, "filter_value", dropdownPayloadValue, '', 'master')
  }, [dropdownPayloadValue])

  // console.log(row, dropdownValue)

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        open={open}
        multiple
        id="tags-standard"
        options={filteredApiData}
        getOptionLabel={(option) => option[row.headerData?.api_field_name || "first_name"]}
        size="small"
        filterOptions={(x) => x}
        value={dropdownValue || null}
        getOptionSelected={(option, value) => option[row.headerData?.api_field_payload] === value[row.headerData?.api_field_payload]}
        onChange={(e, newValue) => handleChange(newValue)}
        disabled={disableOption}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          handleSearchChange(newInputValue)
        }}
        // onBlur={(e) =>
        // console.log(dropdownValue, "dropdownValue")
        // handleFilterTypes(row, row?.id, "filter_value", dropdownPayloadValue, '', 'master')
        // }
        onOpen={handleClick}
        onClose={(e, reason) => {
          if (reason === "select-option") setOpen(true);
          else setOpen(false);
        }}
        required={true}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ margin: "12px 0" }}
            variant="outlined"
            placeholder="Search..."
          />
        )}
      />
    </div>
  );
};


const FilterBooleanValue = (props) => {
  const { row, handleFilterTypes, disableOption, handleTableDataChangeValidation } = props;

  const handleChange = async (row, id, type, filter_value) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation)
      handleFilterTypes(row, id, type, filter_value);
  };
  // console.log(row)
  let yesNoField = row.headerData?.yes_no_field

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        autoSelect
        options={yesNoField ? BooleanYesNoValue : BooleanValue}
        getOptionLabel={(option) => option.filter_value}
        size="small"
        value={yesNoField ? row :
          row.filter_value === true
            ? BooleanValue[0]
            : row.filter_value === false
              ? BooleanValue[1] : null}
        onChange={(e, newValue) => handleChange(row, row?.id, "filter_value", newValue?.id)}
        getOptionSelected={(option, value) => option.id === value.id}
        disabled={disableOption}
        required={true}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ margin: "12px 0" }}
            variant="outlined"
          />
        )}
      />
    </div>
  );
};

const FilterTextValue = (props) => {
  const { row, handleFilterTypes, disableOption, handleTableDataChangeValidation } = props;
  const [textValue, setTextValue] = useState(row?.filter_value || null);

  const handleChange = async (filter_value) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      setTextValue(filter_value)
      handleFilterTypes(row, row?.id, "filter_value", filter_value)
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <TextField
        id="outlined-basic-text"
        variant="outlined"
        size="small"
        style={{ width: "100%" }}
        disabled={disableOption}
        value={textValue}
        onChange={(e) => handleChange(e.target.value)}
        // error={textValue === '' ? true : false}
        // onBlur={(e) =>
        //   handleFilterTypes(row, row?.id, "filter_value", textValue)
        // }
        onFocus={(e) => e.target.select()}
        onKeyDown={(e) => e.stopPropagation()}
        required={true}
      />
    </div>
  );
};
const FilterNumberValue = (props) => {
  const { row, handleFilterTypes, disableOption, handleTableDataChangeValidation } = props;
  const [textValue, setTextValue] = useState(row?.filter_value || null);

  const handleChange = async (filter_value) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      setTextValue(filter_value)
      handleFilterTypes(row, row?.id, "filter_value", filter_value)
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <TextField
        id="outlined-basic-number"
        variant="outlined"
        type="number"
        size="small"
        disabled={disableOption}
        style={{ width: "100%" }}
        value={textValue}
        required={true}
        // error={textValue === '' ? true : false}
        onChange={(e) => handleChange(e.target.value)}
        // onBlur={(e) =>
        //   handleFilterTypes(row, row?.id, "filter_value", textValue)
        // }
        onFocus={(e) => e.target.select()}
        onKeyDown={(evt) => {
          (["e", "E"].includes(evt.key) ||
            evt.which === 38 ||
            evt.which === 40) &&
            evt.preventDefault();
          !["Tab"].includes(evt.key) && evt.stopPropagation();
        }}
      />
    </div>
  );
};
const FilterNumberRangeValue = (props) => {
  const { row, handleFilterTypes, disableOption, handleTableDataChangeValidation } = props;
  const filterValue = row?.filter_value.split(' to ')
  const [textValuelte, setTextValuelte] = useState(filterValue && filterValue[0] || null);
  const [textValuegte, setTextValuegte] = useState(filterValue && filterValue[1] || null);
  console.log(row)
  const handleChange = async (filter_value, field) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      if (field === 'lte') {
        setTextValuelte(filter_value)
        handleFilterTypes(row, row?.id, "filter_value", filter_value + ' to ' + textValuegte)
      }
      else if (field === 'gte') {
        setTextValuegte(filter_value)
        handleFilterTypes(row, row?.id, "filter_value", textValuelte + ' to ' + filter_value)
      }
    }
  };

  return (
    <div style={{ width: "80%", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <TextField
        id="outlined-basic-number-range-lte"
        variant="outlined"
        type="number"
        size="small"
        disabled={disableOption}
        style={{ width: "48%" }}
        value={textValuelte}
        required={true}
        onChange={(e) => handleChange(e.target.value, 'lte')}
        onFocus={(e) => e.target.select()}
        onKeyDown={(evt) => {
          (["e", "E"].includes(evt.key) ||
            evt.which === 38 ||
            evt.which === 40) &&
            evt.preventDefault();
          !["Tab"].includes(evt.key) && evt.stopPropagation();
        }}
      />

      <span style={{ margin: '0 4px' }}> to </span>
      <TextField
        id="outlined-basic-number-range-gte"
        variant="outlined"
        type="number"
        size="small"
        disabled={disableOption}
        style={{ width: "48%" }}
        value={textValuegte}
        required={true}
        onChange={(e) => handleChange(e.target.value, 'gte')}
        onFocus={(e) => e.target.select()}
        onKeyDown={(evt) => {
          (["e", "E"].includes(evt.key) ||
            evt.which === 38 ||
            evt.which === 40) &&
            evt.preventDefault();
          !["Tab"].includes(evt.key) && evt.stopPropagation();
        }}
      />
    </div>
  );
};
const FilterDateValue = (props) => {
  const { row, handleFilterTypes, disableOption, handleTableDataChangeValidation } = props;

  const handleChange = async (row, id, type, filter_value) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation)
      handleFilterTypes(row, id, type, filter_value);
  };

  return (
    <div style={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          // label="Start Date"
          value={row?.filter_value || null}
          maxDate={new Date()}
          disabled={disableOption}
          onChange={(date) => handleChange(row, row?.id, "filter_value", moment(date).format("YYYY-MM-DD"))}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" />
          )}
          inputFormat="dd-MMM-yyyy"
          inputProps={{ readOnly: true }}
          required={true}
        />
      </LocalizationProvider>
    </div>
  );
};
const FilterDaterangeValue = (props) => {
  const { row, handleFilterTypes, disableOption, handleTableDataChangeValidation } = props;
  const [dateRangePicker, setDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    if (row?.filter_value && dateRange.startDate === undefined) {
      let date = row?.filter_value.split(" to ");
      setDateRange({ startDate: new Date(date[0]), endDate: new Date(date[1]) });
    }
  }, [row?.filter_value]);

  const handleChange = async (e, row, id, type, filter_value) => {
    setDateRangePicker(!dateRangePicker);
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      handleFilterTypes(row, id, type, filter_value);
      setDateRange(e);
    }
  };

  // console.log(row?.filter_value, "row?.filter_value");

  return (
    <div style={{ width: "100%" }}>
      <TextField
        id="input-with-icon-textfield"
        variant="outlined"
        size="small"
        disabled={disableOption}
        value={checkInvalidValue(dateRange.startDate)
          ? null
          // row?.filter_value || null
          : `${moment(new Date(dateRange?.startDate)).format('DD MMM YY')} to ${moment(new Date(dateRange?.endDate)).format('DD MMM YY')}`
        }
        // type="date"
        OpenPickerIcon={null}
        style={{
          cursor: "pointer",
        }}
        required={true}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment
              position="end"
              style={{
                cursor: "pointer",
                color: "rgba(0,0,0,0.54)",
                marginLeft: 12,
              }}
              onClick={() => setDateRangePicker(true)}
            >
              <EventIcon style={{ cursor: "pointer" }} />
            </InputAdornment>
          ),
          style: {
            paddingRight: 10,
          },
        }}
      />
      <MuiDateRangePicker
        open={dateRangePicker}
        toggle={() => setDateRangePicker(!dateRangePicker)}
        dateRange={dateRange}
        disabled={disableOption}
        onDateRangeChange={(e) => handleChange(e, row, row?.id, "filter_value", `${moment(e.startDate).format("YYYY-MM-DD")} to ${moment(e.endDate).format("YYYY-MM-DD")}`)}
      />
    </div>
  );
};

const calc = [
  { id: "SUM", calculation_type: "SUM" },
  { id: "AVERAGE", calculation_type: "AVERAGE" },
];

const TableDragDrop = ({
  headers,
  tableDataLoader,
  data,
  tableHeight,
  filterTypes,
  handleFilterTypes,
  handleCalculationTypes,
  handleModuleHeaderRemove,
  handleTableDataOrder,
  handleOrderByData,
  tabActivetemplate,
  appliedTemplateData,
  handleTableDataChangeValidation,
  FetchMasterFilterValueSearchApiData,
  filterValueApiLoader,
  orderByData,
  handleTableOrderBy,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState(data);

  const [orderBy, setOrderBy] = useState({
    id: orderByData?.id || null,
    field_name: orderByData?.field_name || null,
    order_by: orderByData?.order_by || null,
  });

  // useEffect(() => {
  //   if (orderBy.id === null) {
  //     if (Boolean(appliedTemplateData?.field_data?.length > 0))
  //       setOrderBy({
  //         id: appliedTemplateData?.order_by_field,
  //         field_name: appliedTemplateData.field_data.find(d => d.field.id === appliedTemplateData.order_by_field)?.field.general_name,
  //         order_by: appliedTemplateData?.order_by,
  //       })
  //     else if (tabActivetemplate?.template_detail)
  //       setOrderBy({
  //         id: tabActivetemplate.template_detail.order_by_field,
  //         field_name: tabActivetemplate.field_data ? tabActivetemplate.field_data.find(d => d.field.id === tabActivetemplate.template_detail.order_by_field)?.field.general_name : '',
  //         order_by: tabActivetemplate.template_detail.order_by,
  //       })
  //   }
  // }, [appliedTemplateData, tabActivetemplate])

  useEffect(() => {
    // console.log(orderBy)
    handleTableOrderBy(orderBy.id, orderBy.field_name, orderBy.order_by);
  }, [orderBy]);

  useEffect(() => {
    setRows(data);
    // setOrderBy({ id: null, field_name: null, order_by: null })
  }, [data]);

  // const handleMultiRowWarning = useCallback((row) => {
  //   if(row.headerData.)

  // }, [row])

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    // console.log("startIndex", startIndex, "endIndex", endIndex);

    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      const newRows = Array.from(rows);
      const [removed] = newRows.splice(startIndex, 1);
      newRows.splice(endIndex, 0, removed);
      // console.log(newRows);
      setRows(newRows);
      handleTableDataOrder(newRows);
    }
  };

  const handleTableRemoveIcon = async (id) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      handleModuleHeaderRemove(id)
    }
  }

  const handleFilterTypeChange = async (newValue, id, row) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      handleFilterTypes(newValue, id, '', '', row)
    }
  }

  const handleCalculationTypeChange = async (newValue, id) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      handleCalculationTypes(newValue, id)
    }
  }

  const handleOrderByChange = async (newValue, type) => {
    let checkValidation = await handleTableDataChangeValidation();
    if (checkValidation) {
      if (type === 'field_name') {
        setOrderBy((prev) => {
          return {
            ...prev,
            id: Number(newValue?.id),
            field_name: newValue?.field_name,
          };
        });
      } else {
        setOrderBy((prev) => {
          return { ...prev, order_by: newValue?.id };
        });
      }
    }
  }

  return (
    <Paper>
      <div>
        <TableContainer
          className={classes.root}
          style={styles.tableContainerStyle}
          id="scroll"
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {headers &&
                        headers.map((h, index) => (
                          <TableCell
                            align="left"
                            style={styles.tableCell(h.minWidth)}
                          >
                            {h.header}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableDataLoader
                      ? <div style={{ position: 'relative', left: '42vw', top: '15vh' }}>
                        <CircularProgressLoader />
                      </div>
                      : rows.length === 0 ? (
                        <div
                          style={{
                            position: "relative",
                            top: "5vh",
                            left: "42vw",
                            width: 50,
                          }}
                        >
                          <NoDataFound type="noText" />
                        </div>
                      ) : (
                        rows.map((row, index) => (
                          <Draggable
                            key={row.id}
                            draggableId={row.id}
                            index={index}
                          >
                            {(provided) => (
                              <TableRow
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                {headers &&
                                  headers.map((h, i) =>
                                    i === 0 ? (
                                      <TableCell
                                        {...(["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) ? {} : provided.dragHandleProps)}
                                        align="left"
                                        onDrag={false}
                                        style={{
                                          width: 15,
                                          padding: "2px",
                                          minWidth: 15,
                                          cursor: ["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status) ? 'default' : 'grab'
                                        }}
                                      >
                                        {
                                          <IconButton style={{ cursor: "grab" }} disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}>
                                            <DragIndicatorIcon />
                                          </IconButton>
                                        }
                                      </TableCell>
                                    ) : i === 1 ? (
                                      <TableCell
                                        align="left"
                                        style={{
                                          width: h.minWidth,
                                          minWidth:
                                            h.minWidth > 90 ? 200 : h.minWidth,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <span>{row.field_name}</span>
                                          <span
                                            style={{
                                              fontSize: "0.8em",
                                              color: secondaryGrey,
                                            }}
                                          >
                                            {row.headerData?.group === null
                                              ? 'OTHERS'
                                              : row.headerData?.group?.toUpperCase()
                                            }
                                            {/* {" " + handleMultiRowWarning(row)} */}
                                          </span>
                                        </div>
                                        {/* {row.field_name} */}
                                      </TableCell>
                                    ) : i === 2 ? (
                                      <TableCell
                                        align="left"
                                        style={{
                                          width: h.minWidth,
                                          minWidth:
                                            h.minWidth > 90 ? 200 : h.minWidth,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                        }}
                                      >
                                        <div style={{ width: "90%" }}>
                                          <Autocomplete
                                            autoSelect
                                            options={
                                              filterTypes
                                                ? filterTypes.filter(
                                                  (d) =>
                                                    d.field_type ===
                                                    row?.headerData?.field_type
                                                )
                                                : []
                                            }
                                            getOptionLabel={(option) =>
                                              option?.filter_type?.toUpperCase()
                                            }
                                            size="small"
                                            disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                            value={row || null}
                                            onChange={(e, newValue) => handleFilterTypeChange(newValue, row?.id, row)}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                style={{ margin: "12px 0" }}
                                                variant="outlined"
                                              />
                                            )}
                                          />
                                        </div>
                                      </TableCell>
                                    ) : i === 3 ? (
                                      <TableCell
                                        align="left"
                                        style={{
                                          width: h.minWidth,
                                          minWidth:
                                            h.minWidth > 90 ? 200 : h.minWidth,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                        }}
                                      >
                                        {/* {console.log(row)} */}
                                        {row?.filter_type === "" ? (
                                          ""
                                        ) : row?.headerData?.filter_master && !row?.headerData.searchable_api
                                          ? (
                                            <FilterMasterDropdown
                                              row={row}
                                              handleFilterTypes={handleFilterTypes}
                                              handleTableDataChangeValidation={handleTableDataChangeValidation}
                                              filterValueApiLoader={filterValueApiLoader}
                                              disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                            />
                                          )
                                          : row?.headerData?.filter_master && row?.headerData.searchable_api
                                            ? (
                                              <FilterMasterSearchDropdown
                                                row={row}
                                                handleFilterTypes={handleFilterTypes}
                                                handleTableDataChangeValidation={handleTableDataChangeValidation}
                                                FetchMasterFilterValueSearchApiData={FetchMasterFilterValueSearchApiData}
                                                disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                              />
                                            )
                                            : row?.headerData?.field_type ===
                                              "boolean" ? (
                                              <FilterBooleanValue
                                                row={row}
                                                handleFilterTypes={handleFilterTypes}
                                                handleTableDataChangeValidation={handleTableDataChangeValidation}
                                                disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                              />
                                            ) : row?.headerData?.field_type ===
                                              "text" ? (
                                              <FilterTextValue
                                                row={row}
                                                handleFilterTypes={handleFilterTypes}
                                                handleTableDataChangeValidation={handleTableDataChangeValidation}
                                                disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                              />
                                            ) : row?.headerData?.field_type ===
                                              "number" && row?.filter_type !== "In between" ? (
                                              <FilterNumberValue
                                                row={row}
                                                handleFilterTypes={handleFilterTypes}
                                                handleTableDataChangeValidation={handleTableDataChangeValidation}
                                                disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                              />
                                            ) : row?.headerData?.field_type ===
                                              "number" && row?.filter_type === "In between" ? (
                                              <FilterNumberRangeValue
                                                row={row}
                                                handleFilterTypes={handleFilterTypes}
                                                handleTableDataChangeValidation={handleTableDataChangeValidation}
                                                disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                              />
                                            ) : row?.headerData?.field_type ===
                                              "date" &&
                                              row?.filter_type !== "In between" ? (
                                              <FilterDateValue
                                                row={row}
                                                handleFilterTypes={handleFilterTypes}
                                                handleTableDataChangeValidation={handleTableDataChangeValidation}
                                                disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                              />
                                            ) : (
                                              row?.headerData?.field_type ===
                                              "date" &&
                                              row?.filter_type === "In between" && (
                                                <FilterDaterangeValue
                                                  row={row}
                                                  handleFilterTypes={handleFilterTypes}
                                                  handleTableDataChangeValidation={handleTableDataChangeValidation}
                                                  disableOption={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                                />
                                              )
                                            )}
                                      </TableCell>
                                    ) : i === 4 ? (
                                      <TableCell
                                        align="left"
                                        style={{
                                          width: h.minWidth,
                                          minWidth:
                                            h.minWidth > 130 ? 200 : h.minWidth,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                        }}
                                      >
                                        {row?.headerData?.field_type ===
                                          "number" ? (
                                          <div style={{ width: "90%" }}>
                                            <Autocomplete
                                              autoSelect
                                              options={calc}
                                              getOptionLabel={(option) =>
                                                option.calculation_type
                                              }
                                              size="small"
                                              disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                              value={row || null}
                                              onChange={(e, newValue) => handleCalculationTypeChange(newValue, row?.id)}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  style={{ margin: "12px 0" }}
                                                  variant="outlined"
                                                />
                                              )}
                                            />
                                          </div>
                                        ) : null}
                                      </TableCell>
                                    ) : (
                                      i === 5 && (
                                        <TableCell
                                          align="left"
                                          style={{
                                            width: h.minWidth,
                                            minWidth:
                                              h.minWidth > 90 ? 200 : h.minWidth,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                          }}
                                        >
                                          <div>
                                            <ToolTipComponent
                                              condition={true}
                                              title="Remove"
                                            >
                                              <IconButton
                                                disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                                                onClick={() => handleTableRemoveIcon(row.id)}
                                              >
                                                <ClearOutlinedIcon />
                                              </IconButton>
                                            </ToolTipComponent>
                                          </div>
                                        </TableCell>
                                      )
                                    )
                                  )}
                              </TableRow>
                            )}
                          </Draggable>
                        ))
                      )}
                  </TableBody>
                  {provided.placeholder}
                </Table>
              )}
            </Droppable>
          </DragDropContext>
        </TableContainer>
        {data.length > 1 && (
          <TableContainer style={{ borderTop: `1px solid ${grey}` }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  style={{
                    // cursor: "pointer",
                    backgroundColor: "#FFF",
                  }}
                >
                  <TableCell
                    align="left"
                    className={classes.tableCellWidth15}
                    style={{ backgroundColor: "#FFF", width: "6%", minWidth: 15 }}
                  ></TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableCellWidth15}
                    style={{
                      backgroundColor: "#FFF",
                      width: "20%",
                      minWidth: 200,
                    }}
                  >
                    ORDER BY
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCellWidth15}
                    style={{
                      backgroundColor: "#FFF",
                      width: "24%",
                      minWidth: 200,
                    }}
                  >
                    <div style={{ width: "90%" }}>

                      <Autocomplete
                        autoSelect
                        options={data}
                        getOptionLabel={(option) =>
                          option.field_name?.toUpperCase()
                        }
                        size="small"
                        disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                        value={
                          Boolean(orderByData?.id) && data?.find((d) => Number(d.id) === orderByData.id)
                            ? orderByData
                            : null || null
                        }
                        onChange={(e, newValue) => handleOrderByChange(newValue, 'field_name')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ margin: "12px 0" }}
                            variant="outlined"
                            label="Order Field"
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCellWidth15}
                    style={{
                      backgroundColor: "#FFF",
                      width: "26%",
                      minWidth: 200,
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <Autocomplete
                        autoSelect
                        options={ORDER}
                        getOptionLabel={(option) =>
                          option?.order_by?.toUpperCase()
                        }
                        size="small"
                        disabled={["IN_PROGRESS", "PENDING"].includes(tabActivetemplate?.status)}
                        style={{ width: 180 }}
                        value={orderByData || null}
                        onChange={(e, newValue) => handleOrderByChange(newValue, 'order_by')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ margin: "12px 0" }}
                            variant="outlined"
                            label="Order By"
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellWidth15}
                    style={{ backgroundColor: "#FFF", minWidth: "8%" }}
                  ></TableCell>
                  <TableCell
                    className={classes.tableCellWidth15}
                    style={{ backgroundColor: "#FFF", minWidth: "8%" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        )}
      </div>
    </Paper>
  );
};

export default TableDragDrop;
