import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  dailyRateScreenTextColor,
  bottomNavigationFontColor,
} from "../../../styles/colors";
import "../../../styles/b2b/web/RegistrationWeb.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = {
  RegisterCreatePasswordWebMainBody: {
    height: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 50px 20px 50px",
    maxHeight: "85%",
    overflowY: "scroll",
  },
  RegisterCreatePasswordWebInternalBody: {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  InputAdornmentStyle: {
    //color: secondaryColor,
    display: "flex",
    alignItems: "center",
    fontSize: "1.2em",
  },
  textFieldStyle: {
    width: "100%",
  },
};

class RegisterCreatePasswordWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      showErrorMessagePassword,
      showErrorMessageConfirmPassword,
    } = this.state;
    const { classes, password, confirmPassword } = this.props;
    return (
      <div
        className={classes.RegisterCreatePasswordWebMainBody}
        id="RegisterCreatePasswordWebMainBody"
      >
        <div className={classes.RegisterCreatePasswordWebInternalBody}>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <span style={{ color: dailyRateScreenTextColor }}>
              Type a Strong Password
            </span>

            <span
              style={{
                color: bottomNavigationFontColor,
                fontSize: "0.8em",
                marginTop: "3px",
              }}
            >
              The password must be between 6 to 18 characters
            </span>
          </div>
          <TextField
            name="password"
            id="outlined-name"
            label="Password"
            margin="normal"
            variant="outlined"
            type={
              this.state.showPassword && this.state.showPassword
                ? "text"
                : "password"
            }
            value={password}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            inputProps={{
              maxLength: 18,
              style: { padding: "13px 14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            onFocus={() => {
              this.props.handleOnFocusPassword();
              this.setState({ showErrorMessagePassword: false });
            }}
            onBlur={() => {
              this.props.handleOnBlurPassword();
              if (password.length < 6) {
                this.setState({ showErrorMessagePassword: true });
              }
            }}
            error={showErrorMessagePassword ? true : false}
            helperText={
              <span style={{ color: "red" }}>
                {showErrorMessagePassword
                  ? "Please enter minimum 6 digit character"
                  : null}
              </span>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ showErrorMessagePassword: false });
                  }}
                >
                  {this.state.showPassword ? (
                    <Visibility
                      style={{
                        color: bottomNavigationFontColor,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showPassword: false,
                          showErrorMessagePassword: false,
                        });
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      style={{
                        color: bottomNavigationFontColor,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showPassword: true,
                          showErrorMessagePassword: false,
                        });
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />

          <div className="b2b-register-create-password-web-strong-password-div">
            <span
              style={{ color: bottomNavigationFontColor, fontSize: "0.8em" }}
            >
              Password strength
            </span>
            <span
              style={{
                backgroundColor:
                  this.props.password &&
                  this.props.password.length >= 2 &&
                  this.props.password.length < 4
                    ? "#e68948"
                    : this.props.password &&
                      this.props.password.length >= 4 &&
                      this.props.password.length < 6
                    ? "#4986c2"
                    : this.props.password && this.props.password.length >= 6
                    ? "#c6d430"
                    : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                backgroundColor:
                  this.props.password &&
                  this.props.password.length >= 3 &&
                  this.props.password.length < 5
                    ? "#e68948"
                    : this.props.password &&
                      this.props.password.length >= 5 &&
                      this.props.password.length < 7
                    ? "#4986c2"
                    : this.props.password && this.props.password.length >= 7
                    ? "#c6d430"
                    : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                backgroundColor:
                  this.props.password &&
                  this.props.password.length >= 4 &&
                  this.props.password.length < 6
                    ? "#e68948"
                    : this.props.password &&
                      this.props.password.length >= 6 &&
                      this.props.password.length < 8
                    ? "#4986c2"
                    : this.props.password && this.props.password.length >= 8
                    ? "#c6d430"
                    : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                backgroundColor:
                  this.props.password &&
                  this.props.password.length >= 5 &&
                  this.props.password.length < 7
                    ? "#e68948"
                    : this.props.password &&
                      this.props.password.length >= 7 &&
                      this.props.password.length < 9
                    ? "#4986c2"
                    : this.props.password && this.props.password.length >= 9
                    ? "#c6d430"
                    : "gainsboro",
                width: "13%",
                height: "10px",
                border: "1px solid #9E9E9E",
                borderRadius: "10px",
              }}
            />
          </div>
          <TextField
            name="confirmPassword"
            id="outlined-name"
            label="Confirm Password"
            margin="normal"
            variant="outlined"
            type={
              this.state.showConfirmPassword && this.state.showConfirmPassword
                ? "text"
                : "password"
            }
            InputLabelProps={{
              style: { fontSize: "1em", marginTop: "-4px" },
            }}
            value={confirmPassword}
            onChange={this.props.handleTextChange}
            className={classes.textFieldStyle}
            error={showErrorMessageConfirmPassword ? true : false}
            helperText={
              <span style={{ color: "red" }}>
                {showErrorMessageConfirmPassword
                  ? "Password do not match"
                  : null}
              </span>
            }
            onFocus={() => {
              this.setState({ showErrorMessageConfirmPassword: false });
            }}
            onBlur={() => {
              if (password !== confirmPassword) {
                this.setState({ showErrorMessageConfirmPassword: true });
              }
            }}
            inputProps={{
              maxLength: 18,
              style: { padding: "13px 14px" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {this.state.showConfirmPassword ? (
                    <Visibility
                      style={{
                        color: bottomNavigationFontColor,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showConfirmPassword: false,
                          showErrorMessageConfirmPassword: false,
                        });
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      style={{
                        color: bottomNavigationFontColor,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showConfirmPassword: true,
                          showErrorMessageConfirmPassword: false,
                        });
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegisterCreatePasswordWeb);
