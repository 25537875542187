import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Delete from "@material-ui/icons/Delete";
import COLORS, { primaryColor } from "../../../constants/internal/colors";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HourglassEmpty from "@material-ui/icons/HourglassEmpty";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SnackbarComponent from "../../common/SnackbarComponent";
import pdfMake from "pdfmake/build/pdfmake";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SendIcon from '@mui/icons-material/Send';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Divider,
  CircularProgress,
  InputAdornment,
  Popover,
} from "@material-ui/core";
import { grey } from "../../../constants/internal/colors";
import { withRouter } from "react-router";
import Print from "@material-ui/icons/Print";
import axios from "axios";
import APIROUTES from "../../../constants/internal/InternalApiRoutes";
import { handlePrintTable } from "../common/CommonFunctions";
import { constant } from "lodash";
import INTERNALROUTES from "../../../constants/internal/InternalRoutes";
import { pxToEm, hasApproveAccess, checkApiError, hasWriteAccess, hasUpdateAccess, getOptions } from "../../../methods";
import ToolTipComponent from "../common/ToolTipComponent";
import { Skeleton, Stack } from "@mui/material";
import SpinLoader from "../../common/SpinLoader"

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TableRowSkeleton extends React.Component {

  render() {

    return (this.props.skeletonName === 'ready_qty' ?
      <TableRow>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={120} height={30} />
            <Skeleton animation="wave" variant="text" width={50} height={15} />
          </Stack>
        </TableCell>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={60} height={25} />
            <Skeleton animation="wave" variant="text" width={40} height={15} />
          </Stack>
        </TableCell>
        <TableCell style={{ alignItems: 'center' }}>
          {moment(new Date(this.props.selectedDate)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD") ?
            <Stack direction="row" spacing={1} style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Skeleton animation="wave" variant="text" width={120} height={60} style={{ marginLeft: 25 }} />
              <Skeleton animation="wave" variant="circular" width={30} height={30} />
            </Stack>
            :
            <Stack style={{ alignItems: 'center' }}>
              <Skeleton animation="wave" variant="text" width={60} height={25} />
            </Stack>
          }
        </TableCell>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={60} height={25} />
            <Skeleton animation="wave" variant="text" width={40} height={15} />
          </Stack>
        </TableCell>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={60} height={25} />
            <Skeleton animation="wave" variant="text" width={40} height={15} />
          </Stack>
        </TableCell>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={60} height={25} />
            <Skeleton animation="wave" variant="text" width={40} height={15} />
          </Stack>
        </TableCell>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={60} height={25} />
            <Skeleton animation="wave" variant="text" width={40} height={15} />
          </Stack>
        </TableCell>
      </TableRow>
      :
      <TableRow>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={100} height={25} />
            <Skeleton animation="wave" variant="text" width={70} height={15} />
          </Stack>
        </TableCell>
        <TableCell style={{ alignItems: 'center' }}>
          <Stack spacing={0} style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={120} height={25} />
          </Stack>
        </TableCell>
        {[...Array(5).keys()].map((components, index) => {
          return (
            <TableCell style={{ alignItems: 'center' }}>
              <Stack spacing={0} style={{ alignItems: 'center' }}>
                <Skeleton animation="wave" variant="text" width={50} height={25} />
              </Stack>
            </TableCell>
          )
        })}
        <TableCell style={{ alignItems: 'center' }}>
          <Stack style={{ alignItems: 'center' }}>
            <Skeleton animation="wave" variant="text" width={8} height={30} />
          </Stack>
        </TableCell>
      </TableRow>
    )
  }
}

class TwoLineCell extends React.Component {
  render() {
    const {
      classes,
      text1,
      text2,
      paddingRight,
    } = this.props;

    return (
      <>
        <span
          style={{
            fontSize: pxToEm(19),
            fontWeight: '600',
            color: '#000',
            paddingRight: paddingRight && paddingRight
          }}
        >{text1}</span>
        <p
          style={{
            fontSize: pxToEm(15),
            margin: 0,
            color: grey,
            // textDecoration: "underline",
            fontWeight: "500",
            cursor: 'pointer',
            opacity: text2 === "" && 0
          }}
        >
          {text2 === "" ? 'LA' : text2}
        </p>
      </>
    )
  }
}

class ActionMenu extends React.Component {
  state = {
    anchorEl: null,
    open: null,
    openLA: null,
    loader: false,
    // loaderButtonId: 0
  }

  getOptions = (user) => {
    return {
      headers: {
        Authorization: `JWT ${user.token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, openLA: 'close', }, () => this.setState({ open: Boolean(this.state.anchorEl) }))
    // this.props.handleActionClick(event.currentTarget);
  };
  handleClose = () => {
    this.setState({ anchorEl: null, }, () => this.setState({ open: Boolean(this.state.anchorEl) }))
    // this.props.handleActionClose()
  };

  componentDidUpdate(nextProps, prevState) {
    if (prevState.openLA !== this.state.openLA) {
      if (this.state.openLA == 'open') {
        // handleAddLaButton = (orderId, customerId) => {
        // this.setState({ anchorEl: this.props.anchorElUpdated, open: this.props.anchorElUpdated })
        axios.get(
          `${APIROUTES.CHECK_PENDING_QUANTITY}?order_id=${this.props.orderID}`,
          this.getOptions(this.props.user)
        )
          .then((response) => {
            if (response.status === 200) {
              this.setState({ loader: false })
              this.props.history.push({
                pathname: `/dispatchPlanning/createLoadingAdviceFromOrder/${this.props.orderID}`,
                state: {
                  itemId: this.props.itemId,
                  customerId: this.props.customerId,
                  plantId: this.props.plantId,
                  pendingQuantity: response.data,
                  prevSelectedDate: this.props.selectedDate,
                  plants: this.props.plants,
                  plantsName: this.props.plantsName,
                  itemCategoryName: this.props.itemCategoryName
                }
              });
              this.props.handleAddButton(this.props.orderID, 'add')
              this.handleActionClose()
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }
    }
  }

  componentWillUnmount = () => {

  }

  render() {
    const {
      classes,
      // open,
      // anchorEl,
      orderID,
      customerId,
      status,
    } = this.props;
    const { open, anchorEl, loader } = this.state;
    // console.log(open, anchorEl)
    // console.log(orderID, customerId)
    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={this.handleClick}
        >
          {this.props.icon}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <div>
            <ToolTipComponent
              title={`Contract is ${status}`}
              condition={["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(status)}
              title2="Page is Loading"
              condition2={this.state.loader}
              title3={'Contract is Expired! Please amend contract validity'}
              condition3={new Date(this.props.validity) < new Date()}
              title4={'There is no Open Qty'}
              condition4={Number(this.props?.pendingQty) <= Number(0)}
              title5={"You have no create permission on this widget"}
              condition5={!hasWriteAccess(this.props.user, this.props.keyCode)}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <MenuItem
                  style={{
                    display: 'flex',
                    fontFamily: 'Poppins',
                    justifyContent: 'flex-start',
                    fontSize: pxToEm(16),
                    fontWeight: '600',
                    opacity: !hasWriteAccess(this.props.user, this.props.keyCode) ||
                      (this.props?.loaderButtonId === orderID && this.state.loader)
                      || ["HOLD", "CLOSED"].includes(status) ||
                      Number(this.props?.pendingQty) <= Number(0) ||
                      new Date(this.props.validity) < new Date() ? 0.4 : 1,
                    // color: grey
                  }}
                  disabled={
                    (this.props?.loaderButtonId === orderID ? this.state.loader && true : false) ||
                    (["HOLD", "CLOSED", "CANCELLED", "AUTOCLOSED"].includes(status)) ||
                    new Date(this.props.validity) < new Date() ||
                    !hasWriteAccess(this.props.user, this.props.keyCode) ||
                    Number(this.props?.pendingQty) <= Number(0)
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.props?.loaderButtonId === orderID && this.state.loader) {
                      e.preventDefault();
                    }
                    else {
                      this.setState({
                        anchorEl: null, openLA: 'open',
                      }, () => {
                        this.setState({
                          open: Boolean(this.state.anchorEl),
                          loader: true,
                          // loaderButtonId: orderID
                        })
                        this.props.handleAddLAButtonLoader(orderID)
                        this.props.handleSelectedOrderDate(this.props.selectedOrderDate)
                      })
                    }
                    // )
                    // this.handleAddLaButton(orderID, customerId)
                  }}
                >
                  {this.props.icon1}{this.props.title1}
                </MenuItem>
                <span style={{ display: this.props?.loaderButtonId === orderID ? this.state.loader ? 'flex' : 'none' : 'none' }}>
                  <CircularProgress style={{ margin: '0 5px', height: 20, width: 20, color: primaryColor }} />
                </span>
              </div>
            </ToolTipComponent>
            <Divider
              variant='middle'
              style={{
                margin: '2% 0',
              }} />
          </div>
        </Menu>
      </div>
    )
  }
}



class ContractsCustomersTable extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;
    return (
      <div className={classes.root}>
        {/* {console.log("HERE TABLE ", rowsPerPage)} */}
        {/* <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton> */}
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0 || rowsPerPage == 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={(page >= Math.ceil(count / rowsPerPage) - 1) || rowsPerPage == 0}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        {/* <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton> */}
      </div>
    );
  }
}

ContractsCustomersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  nextLink: PropTypes.string.isRequired,
  prevLink: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

const ContractsCustomersTableWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(ContractsCustomersTable);

let counter = 0;
function createData(name, calories, fat) {
  counter += 1;
  return { id: counter, name, calories, fat };
}

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    paddingTop: "0px",
  },
  tableContainerStyle: {
    height: "49vh",
    maxHeight: "49vh",
    overflowY: "scroll",

    '@media print': {
      height: '100% !important',
      overflow: 'visible',
      margin: '50px 10px'
    }
  },
  tableBodyRow: {
    '@media print': {
      // display: 'block',
      breakInside: 'avoid',
      // breakAfter: 'auto'
    }
  },
  tablePrintVisible: {
    display: 'none',

    '@media print': {
      borderTop: `1px solid ${grey}`,
      display: 'table'
    }
  },
  container: {
    maxHeight: "45vh",
  },
  tableCellWidth15: {
    color: grey,
    width: "15%",
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tableCellWidth16: {
    color: grey,
    width: "16%",
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tableCellWidth10: {
    color: grey,
    width: "10%",
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tableCellWidth14: {
    color: grey,
    width: "14%",
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tableCellOrderIdContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellPadding8px: {},
  tableCellNoOfLA: {
    color: "blue",
    textDecoration: "underline",
  },
  menuText: {
    display: 'flex',
    fontFamily: 'Poppins',
    justifyContent: 'space-between',
    fontSize: pxToEm(14),
    fontWeight: '600',
    color: "#3B69B0"
  },
  readyQtyHelperText: {
    // display: 'flex',
    // justifyContent: 'center',
    fontSize: pxToEm(12),
    position: 'absolute',
    top: pxToEm(60),
    margin: '2px 0 0 2px'
  },
  noDataDiv: {
    display: "flex",
    position: 'relative',
    left: '35vw',
    top: '12vh',
    width: '100%',
    height: '100%',
    zIndex: 9999,
  },
});

class DispatchListTable extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    laList: [],
    laOrderId: "",
    openDialogDispatch: false,
    openDiscardDialog: false,
    selectedLaId: null,
    openSnackbar: false,
    snackbarMessage: null,
    snackbarType: "",
    rowCount: 0,
    anchorEl: null,
    open: null,
    itemCategoryName: sessionStorage.getItem('itemCategoryNAME'),
    loaderButtonId: null,
    // ready_qty: this.props?.tableDataByDate?.orders || [],
    readyQtyTextFieldData: this.props?.tableDataByDate?.orders || [],
    readyQtyEditIconShowIndex: [],
    totalReadyQty: 0
  };
  _isMounted = false;

  // getOptions = (user) => {
  //   return {
  //     headers: {
  //       Authorization: `JWT ${user.token}`,
  //       "Content-Type": "application/json;charset=UTF-8",
  //     },
  //   };
  // };

  componentDidMount() {
    // console.log(this.state)
    if (this.props.dispatchPlaningList) {

      this.props.dispatchPlaningList.map(disp => {
        if (disp.date === this.props.selectedDate || (disp.orders !== null && disp.orders.length !== this.state.rowCount)) {

          this.setState({
            rowCount: disp.orders !== null && disp.orders.length
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({ page: 0 })
    }
    if (prevProps.searchValue !== this.props.searchValue) {
      // console.log("YES", this.props.searchValue)
      this.setState({ page: 0 })
    }
    if (this.props.readyQtyModeSwitch === true) {
      if (prevProps.tableDataByDate !== this.props?.tableDataByDate) {
        this.setState({
          // ready_qty: this.props?.tableDataByDate?.orders,
          readyQtyTextFieldData: this.props?.tableDataByDate?.orders,
        }, () => {
          this.handleReadyQtyEditIconShowIndexValidation()
          this.handleTotalReadyQty()
          this.state?.readyQtyTextFieldData && this.props.handleReadyQtyCsvData(this.state?.readyQtyTextFieldData)
        }
        )
      }
    }
    if (Boolean(this.props.plantsCode)) {
      sessionStorage.setItem('plantsCode', this.props.plantsCode)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleAddLAButtonLoader = (orderID) => {
    this.setState({ loaderButtonId: orderID })
  }

  handleActionClick = (data) => {
    this.setState({ anchorEl: data }, () => this.setState({ open: Boolean(this.state.anchorEl) }))
    // console.log(event.currentTarget, Boolean(this.state.anchorEl))
  };
  handleActionClose = () => {
    this.setState({ anchorEl: null }, () => this.setState({ open: Boolean(this.state.anchorEl) }))
  };

  handleOpenSnackbar = (message, type) => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackbar: false,
      snackbarMessage: "",
      snackbarType: "",
    });
  };

  handleSnackbarMessageBox = (data) => {
    return (
      data.map((item, index) => {
        return (
          <>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          </>)
      })
    )
  }

  handlePrintButton = (laNumber, action) => {
    handlePrintTable(laNumber, action)
  }

  handleChangePage = (event, page) => {
    // console.log(this.state.page, page)
    if (!this.props.searchValue) {
      if (page > this.state.page) {
        this.props.handleFetchDispatchDetailsWithDate(
          this.props.selectedDate,
          sessionStorage.getItem("itemCategoryID"),
          this.props.match.params.plantId,
          this.props.nextLink
        );
      }
      else if (page < this.state.page) {
        this.props.handleFetchDispatchDetailsWithDate(
          this.props.selectedDate,
          sessionStorage.getItem("itemCategoryID"),
          this.props.match.params.plantId,
          this.props.prevLink
        );
      }
    }
    else {
      if (page > this.state.page) {
        // console.log("HERE", this.props.nextLink)
        this.props.handleSearchTextData(
          sessionStorage.getItem("itemCategoryID"),
          this.props.match.params.plantId,
          this.props.nextLink
        );
      }
      else if (page < this.state.page) {
        this.props.handleSearchTextData(
          sessionStorage.getItem("itemCategoryID"),
          this.props.match.params.plantId,
          this.props.prevLink
        );
      }
    }
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  handleCloseOpenDialogDispatch = () => {
    this.setState({ openDialogDispatch: false });
  };
  handleCloseDiscardDialog = () => {
    this.setState({
      openDiscardDialog: false,
      selectedLaId: null,
    });
  };

  handleReadyQtyEditIconShowIndexValidation = () => {
    if (this.props?.tableDataByDate?.orders) {
      moment(new Date(this.props.selectedDate)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD") ?
        this.setState({
          readyQtyEditIconShowIndex: Array.from(this.props?.tableDataByDate?.orders, (d, index) => (
            {
              mode: d.ready_quantity === "" ? 'add' : 'view',
              index: index
            }
          ))
        })
        :
        this.setState({
          readyQtyEditIconShowIndex:
            Array.from(this.props?.tableDataByDate?.orders, (d, index) => (
              {
                mode: 'view',
                index: index
              }
            ))
        })
      this.setState({
        // ready_qty: this.props?.tableDataByDate?.orders,
        // readyQtyTextFieldData: this.props?.tableDataByDate?.orders,
        totalReadyQty: 0
      })
    }
  }

  handleReadyQtyValidation = (data, openQty) => {
    if (data == 0 && data !== "") {
      return 'Invalid Value'
    }
    else if (data !== "" && Number(data) > Number(openQty)) {
      return `Limit Upto ${openQty} MT`
    }
    else {
      return '';
    }
  }


  handleReadyQtyValueChange = (data, index) => {
    let readyQtyTextFieldData = [...this.state.readyQtyTextFieldData]
    readyQtyTextFieldData[index].ready_quantity = data
    this.setState({ readyQtyTextFieldData },
      () => {
        this.handleTotalReadyQty()
        this.props.handleReadyQtyCsvData(this?.state?.readyQtyTextFieldData)
      })
    // let ready_qty = [...this.state?.ready_qty];
    // ready_qty[index].ready_quantity = data;
    // this.setState({ ready_qty }, () => this.handleTotalReadyQty())
    // console.log(this.state?.ready_qty)
  }

  handleEditIconShow = (indexNumber, mode) => {

    let readyQtyEditIconShowIndex = [...this.state?.readyQtyEditIconShowIndex]
    readyQtyEditIconShowIndex[indexNumber] = {
      mode: mode,
      index: indexNumber
    }
    this.setState({ readyQtyEditIconShowIndex })
  }

  handleTotalReadyQty = () => {
    // console.log(this.state.ready_qty.reduce((a, c) => Number(a) + Number(c)))
    if (this?.state?.readyQtyTextFieldData) {
      let totalReadyQty = (this.props.handleTotalSumCalculation(this?.state?.readyQtyTextFieldData, 'ready_quantity'))
      this.setState({ totalReadyQty })
    }
  }
  handleKeypress = (e, index) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      this.handleReadyQtySendData(index);
      // console.log(e.target.parentElement?.parentElement?.parentElement?.nextElementSibling?.childNodes[2]?.firstElementChild?.firstElementChild?.firstElementChild?.firstElementChild)
      let nextInput = e.target.parentElement?.parentElement?.parentElement?.nextElementSibling?.childNodes[2]?.firstElementChild?.firstElementChild?.firstElementChild?.firstElementChild;
      // console.log(nextInput)
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  handleReadyQtySendData = (index) => {
    let data = {
      ready_quantity: this.state?.readyQtyTextFieldData[index].ready_quantity,
      customer: this.state?.readyQtyTextFieldData[index].id,
      date: this.props.selectedDate,
      plant: this.props?.plantId,
      item_category: this.props?.itemId
    }
    console.log(this.state?.readyQtyEditIconShowIndex[index]?.mode)
    if (this.state?.readyQtyEditIconShowIndex[index]?.mode === "add") {
      this.props.handleCreateReadyQty(data)
        .then((response) => {
          console.log(response)
          if (response.status === 201) {
            this.handleEditIconShow(index, "view")
            this.handleOpenSnackbar(
              'Ready qty send successfully',
              "success"
            );
            setTimeout(() => {
              this.props.handleTableDataChange(true);
              this.props.handleReadyQtyCsvData(this?.state?.readyQtyTextFieldData)
              this.handleTotalReadyQty();
              this.handleCloseSnackbar();
            }, 3000);
          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        })
    }
    else {
      this.props.handleUpdateReadyQty(data, this.state?.readyQtyTextFieldData[index].id)
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            this.handleEditIconShow(index, "view")
            this.handleOpenSnackbar(
              'Ready qty updated successfully',
              "success"
            );
            setTimeout(() => {
              this.props.handleTableDataChange(true)
              this.handleTotalReadyQty()
              this.handleCloseSnackbar();
            }, 3000);
          }
        })
        .catch((error) => {
          this.handleOpenSnackbar(
            this.handleSnackbarMessageBox(checkApiError(error.response?.data)),
            "error"
          );
          setTimeout(() => {
            this.handleCloseSnackbar();
          }, 4000);
        })
    }
  }

  render() {
    const {
      classes,
      dispatchPlaningList,
      dispatchLoader,
      selectedDate,
      history,
      rowsCount,
      tableDataByDate,
      count,
      prevLink,
      nextLink,
      readyQtyModeSwitch,
    } = this.props;
    const {
      rows,
      rowsPerPage,
      page,
      rowCount,
      readyQtyEditIconShowIndex,
      readyQtyTextFieldData,
    } = this.state;
    // console.log(rowsCount)
    // console.log(this.state)
    return (
      <Paper className={classes.root} style={{ boxShadow: "none" }}>
        {!readyQtyModeSwitch ?
          <div className={classes.tableWrapper}>
            <TableContainer
              className={classes.tableContainerStyle}
              id="print-details"
              ref={this.props.Ref1Dispatch}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                    >
                      CONTRACT NO.
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                    >
                      CUSTOMER
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth16}
                    >
                      TODAY'S LA
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                    >
                      LA(APPROVED)
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                    >
                      LA(IN PLANT)
                    </TableCell>

                    <TableCell
                      align="center"
                      className={classes.tableCellWidth14}
                    >
                      DISPATCHED
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                    >
                      OPEN QTY
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth14}
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/*
                  <TableBody className={classes.noDataDiv}>
                    <div>
                      <SpinLoader />
                    </div>
                  </TableBody> 
                */}
                {dispatchLoader ? (
                  <TableBody>
                    {[...Array(10).keys()].map((components, index) => {
                      return (
                        <TableRowSkeleton
                          selectedDate={this.props.selectedDate}
                          skeletonName='dispatch_planning'
                        />
                      )
                    })}
                  </TableBody>

                ) : (
                  <TableBody>
                    {tableDataByDate &&
                      tableDataByDate?.orders?.length > 0
                      ? ((rowsPerPage > 0
                        ? tableDataByDate?.orders?.slice(0, rowsPerPage)
                        : tableDataByDate?.orders
                      )?.map((data) =>
                        // row.date === selectedDate ? (
                        <TableRow
                          className={classes.tableBodyRow}
                          style={{ cursor: "pointer", }}
                        // key={data.id}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <div
                              className={classes.tableCellOrderIdContainer}
                            >
                              <span>{data.order_number}</span>
                              <span style={{ fontSize: "0.8em" }}>
                                {(data?.tag) || ''}
                              </span>
                              <span style={{ fontSize: "0.8em" }}>
                                {moment(data.created_at).format("DD-MMM-YYYY")}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            {data.first_name + " " + data.last_name}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableCellNoOfLA}
                          >
                            {data?.loading_advices?.length > 0 ? (
                              <p
                                onClick={() => {
                                  this.setState({
                                    openDialogDispatch: true,
                                    laList: data.loading_advices,
                                    laOrderId: data.order_number,
                                  })
                                }

                                }>
                                {data.loading_advices.length} LA
                              </p>
                              //   <IconButton
                              //     aria-label="Toggle password visibility"
                              //     style={{ padding: "0px", marginLeft: "4px" }}
                              //     onClick={() => {
                              //       this.setState({
                              //         openDialogDispatch: true,
                              //         laList: data.loading_advices,
                              //         laOrderId: data.order_number,
                              //       })
                              //     }

                              //     }
                              //   >
                              //     <ErrorOutline />
                              //   </IconButton>
                              // </>
                            ) : null}

                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            {data.loading_advice_approved > 0 ? (data.loading_advice_approved + " MT") : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            {data.loading_advice_inplant > 0 ? (data.loading_advice_inplant + " MT") : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            {data.gate_out > 0 ? (data.gate_out + " MT") : ""}

                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            {data.total_pending > 0 ? (data.total_pending + " MT") : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <ActionMenu
                              user={this.props.user}
                              history={this.props.history}
                              anchorEl={this.state.anchorEl}
                              open={this.state.open}
                              orderID={data.id}
                              customerId={data.customer}
                              handleActionClick={this.handleActionClick}
                              handleActionClose={this.handleActionClose}
                              handleAddLaButton={this.handleAddLaButton}
                              handleAddButton={this.props.handleAddButton}
                              handleAddLAButtonLoader={this.handleAddLAButtonLoader}
                              loaderButtonId={this.state?.loaderButtonId}
                              icon={<MoreVertIcon />}
                              icon1={<AddIcon />}
                              title1='ADD LA'
                              pendingQty={data?.total_pending}
                              validity={data.order_validity}
                              status={data.order_status}
                              itemId={this.props.match.params.itemId}
                              plantId={this.props.match.params.plantId}
                              prevSelectedDate={this.props.selectedDate}
                              plants={this.props.plants}
                              plantsName={this.props.plantsName}
                              plantsCode={this.props.plantsCode}
                              itemCategoryName={this.props.itemCategoryName}
                              keyCode={`${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`}
                              selectedOrderDate={this.props.selectedDate}
                              handleSelectedOrderDate={this.props.handleSelectedOrderDate}
                            />
                          </TableCell>
                          {/* {console.log(
                            this.props.match.params.itemId,
                            this.props.match.params.plantId
                          )} */}
                          {/* <TableCell
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <Button
                              className={classes.buttonStyle}
                              variant="contained"
                              onClick={(e) => {
                                // e.preventDefault();
                                // this.props.handleAddButton(data.id, 'add')
                                this.handleAddLaButton(data.id, data.customer)
                                // history.push({
                                //   pathname: `/dispatchPlanning/createLoadingAdviceFromOrder/${data.id}`,
                                //   state: {
                                //     itemId: this.props.match.params.itemId,
                                //     customerId: data.customer,
                                //     plantId: this.props.match.params.plantId,
                                //     pendingQuantity: data.total_pending
                                //   }
                                // });
                                // this.props.handleAddButton(data.id, 'add')
                              }}
                            >
                              + Add LA
                            </Button>
                          </TableCell> */}
                        </TableRow>)
                        // )
                        //  : null
                      )
                      : <div style={{
                        position: 'relative',
                        top: '15vh',
                        left: '35vw',
                        width: 110
                      }}>
                        No Data Found
                      </div>
                    }
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TableContainer style={{ borderTop: `1px solid ${grey}` }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    style={{
                      // cursor: "pointer",
                      backgroundColor: '#FFF',
                    }}>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      Calculation
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      {tableDataByDate?.calculation?.sum_loading_advices !== undefined
                        ? tableDataByDate?.calculation?.sum_loading_advices + ' LA'
                        : '0 LA'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{
                        width: '13%',
                        paddingLeft: 0,
                        paddingRight: 16,
                        backgroundColor: '#FFF'
                      }}
                    >
                      {tableDataByDate?.calculation?.sum_loading_advice_approved !== undefined
                        ? tableDataByDate?.calculation?.sum_loading_advice_approved + ' MT'
                        : '0 MT'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                      style={{
                        paddingLeft: 8,
                        backgroundColor: '#FFF'
                      }}
                    >
                      {tableDataByDate?.calculation?.sum_loading_advice_inplant !== undefined
                        ? tableDataByDate?.calculation?.sum_loading_advice_inplant + ' MT'
                        : '0 MT'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                      style={{
                        paddingLeft: 18,
                        backgroundColor: '#FFF'
                      }}
                    >
                      {tableDataByDate?.calculation?.sum_gate_out !== undefined
                        ? tableDataByDate?.calculation?.sum_gate_out + ' MT'
                        : '0 MT'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth14}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      {tableDataByDate?.calculation?.sum_total_pending !== undefined
                        ? tableDataByDate?.calculation?.sum_total_pending + ' MT'
                        : '0 MT'}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth14}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      {/* --- */}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              // colSpan={3}
              count={count}
              rowsPerPage={this.state?.rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              onChangePage={this.handleChangePage}
              // onChangeRowsPerPage={this.handleChangeRowsPerPage}
              nextLink={nextLink}
              prevLink={prevLink}
              ActionsComponent={ContractsCustomersTableWrapped}
              component="div"
            />
          </div>
          :
          <div className={classes.tableWrapper}>
            <TableContainer
              className={classes.tableContainerStyle}
              // style={{ height: '52vh', maxHeight: '52vh' }}
              id="print-ready-qty-details"
              ref={this.props.Ref2ReadyQty}
            // style={{ @media print { height: '100%' }}}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                    >
                      CUSTOMER
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                    >
                      OPEN QTY
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                    >
                      READY QTY
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ width: '13%', }}
                    >
                      TODAY'S LA
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                    >
                      LA(APPROVED)
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                    >
                      LA(IN PLANT)
                    </TableCell>

                    <TableCell
                      align="center"
                      className={classes.tableCellWidth14}
                    >
                      DISPATCHED
                    </TableCell>
                  </TableRow>
                </TableHead>
                {dispatchLoader ? (
                  <TableBody>
                    {[...Array(10).keys()].map((components, index) => {
                      return (
                        <TableRowSkeleton
                          selectedDate={this.props.selectedDate}
                          skeletonName='ready_qty'
                        />
                      )
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    {this.state?.readyQtyTextFieldData &&
                      this.state?.readyQtyTextFieldData?.length > 0
                      ? this.state?.readyQtyTextFieldData?.map((data, index) =>
                        <TableRow className={classes.tableBodyRow}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <span>{data.first_name + data.last_name}</span>
                            <p
                              style={{
                                fontSize: pxToEm(13),
                                margin: '0',
                                color: grey,
                                // textDecoration: "underline",
                                fontWeight: "400",
                                cursor: 'pointer'
                              }}
                            >
                              {data.customer_id}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <span>{data.open_quantity?.open_quantity + ' MT'}</span>
                            <p
                              style={{
                                fontSize: pxToEm(13),
                                margin: '0',
                                color: grey,
                                // textDecoration: "underline",
                                fontWeight: "400",
                                cursor: 'pointer'
                              }}
                            >
                              {(data.today_open_quantity || Number(data.today_open_quantity) == Number(0)
                                ? data.today_open_quantity
                                : data.today_open_quantity) + ' MTS'}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            {
                              readyQtyEditIconShowIndex[index]?.mode === "edit" || readyQtyEditIconShowIndex[index]?.mode === "add" ?
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', width: '80%' }}>
                                  <TextField
                                    name="ready_quantity"
                                    type="number"
                                    onKeyDown={(evt) => (
                                      ["e", "E", "+", "-"].includes(evt.key) ||
                                      (evt.which === 38 || evt.which === 40)
                                    ) && evt.preventDefault()}
                                    className={classes.formControl}
                                    defaultValue={data.ready_quantity}
                                    value={readyQtyTextFieldData[index].ready_quantity || ""}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => e.target.value.match(/^\d*\.?\d{0,3}$/) ? this.handleReadyQtyValueChange(e.target.value, index) : e.preventDefault()}
                                    onKeyPress={(e) => !e.target.value.match(/^\d*\.?\d{0,2}$/) && e.preventDefault()}
                                    variant="outlined"
                                    size="small"
                                    style={{ width: '10%', marginLeft: 15 }}
                                    disabled={
                                      hasWriteAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                        ? false
                                        : true
                                    }
                                    FormHelperTextProps={{ className: classes.readyQtyHelperText }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" style={{ paddingRight: 0, transform: 'scale(0.7)' }}>
                                          MT
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={Boolean(this.handleReadyQtyValidation(readyQtyTextFieldData[index].ready_quantity, data.open_quantity?.open_quantity))}
                                    helperText={this.handleReadyQtyValidation(readyQtyTextFieldData[index].ready_quantity, data.open_quantity?.open_quantity)}
                                  />
                                  {/* {console.log(readyQtyTextFieldData[index].ready_quantity)} */}
                                  <ToolTipComponent
                                    title="You have no create permission on this widget"
                                    condition={!hasWriteAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)}
                                  >
                                    <IconButton
                                      onKeyDownCapture={(e) => this.handleKeypress(e, index)}
                                      onFocus={(e) => { }}
                                      onClick={() => this.handleReadyQtySendData(index)}
                                      disabled={Boolean(this.handleReadyQtyValidation(readyQtyTextFieldData[index].ready_quantity, data.open_quantity?.open_quantity)) ||
                                        !hasWriteAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                      }
                                      style={{
                                        transform: 'scale(1)',
                                        paddingBottom: 8,
                                        // backgroundColor: 'transparent',
                                        color: Boolean(this.handleReadyQtyValidation(readyQtyTextFieldData[index].ready_quantity, data.open_quantity?.open_quantity)) ||
                                          !hasWriteAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                          ? 'rgba(0, 0, 0, 0.12)'
                                          : "#4b87c5",
                                      }}
                                    >
                                      <SendIcon />
                                    </IconButton>
                                  </ToolTipComponent>
                                </div>
                                :
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingLeft: moment(new Date(this.props.selectedDate)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD") && 30
                                  }}
                                >
                                  <span>
                                    {data.ready_quantity !== ""
                                      ? data.ready_quantity + ' MT'
                                      : data.ready_quantity
                                    }
                                  </span>
                                  {moment(new Date(this.props.selectedDate)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD") &&
                                    <span>
                                      <ToolTipComponent
                                        title="You have no edit permission on this widget"
                                        condition={!hasUpdateAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)}
                                      >
                                        <IconButton
                                          onClick={() => this.handleEditIconShow(index, "edit")}
                                          style={{ transform: 'scale(0.8)', color: !hasUpdateAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`) && 'rgba(0, 0, 0, 0.12)' }}
                                          disabled={
                                            hasUpdateAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                              ? false
                                              : true
                                          }
                                        >
                                          <EditOutlinedIcon />
                                        </IconButton>
                                      </ToolTipComponent>
                                    </span>
                                  }
                                </div>
                            }
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <span>{data.loading_advice_pending?.pending_quantity + ' MT'}</span>
                            <p
                              style={{
                                fontSize: pxToEm(13),
                                margin: '0',
                                color: grey,
                                // textDecoration: "underline",
                                fontWeight: "400",
                                cursor: 'pointer'
                              }}
                            >
                              {data.loading_advice_pending?.total_la + ' LA'}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <span>{data.loading_advice_approved?.approved_quantity + ' MT'}</span>
                            <p
                              style={{
                                fontSize: pxToEm(13),
                                margin: '0',
                                color: grey,
                                // textDecoration: "underline",
                                fontWeight: "400",
                                cursor: 'pointer'
                              }}
                            >
                              {data.loading_advice_approved?.total_la + ' LA'}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <span>{data.loading_advice_inplant?.in_plant_quantity + ' MT'}</span>
                            <p
                              style={{
                                fontSize: pxToEm(13),
                                margin: '0',
                                color: grey,
                                // textDecoration: "underline",
                                fontWeight: "400",
                                cursor: 'pointer'
                              }}
                            >
                              {data.loading_advice_inplant?.total_la + ' LA'}
                            </p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={classes.tableCellPadding8px}
                          >
                            <span>{data.dispatched_quantity?.dispatch_quantity + ' MT'}</span>
                            <p
                              style={{
                                fontSize: pxToEm(13),
                                margin: '0',
                                color: grey,
                                // textDecoration: "underline",
                                fontWeight: "400",
                                cursor: 'pointer'
                              }}
                            >
                              {data.dispatched_quantity?.total_la + ' LA'}
                            </p>
                          </TableCell>
                        </TableRow>
                      )
                      : <div style={{
                        position: 'relative',
                        top: '15vh',
                        left: '35vw',
                        width: 110
                      }}>
                        No Data Found
                      </div>
                    }
                  </TableBody>)}
              </Table>
              <Table stickyHeader aria-label="sticky table" className={classes.tablePrintVisible}>
                <TableHead>
                  <TableRow
                    style={{
                      // cursor: "pointer",
                      backgroundColor: '#FFF',
                      fontFamily: 'Poppins'
                    }}>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'open_quantity',
                            'open_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'today_open_quantity',
                            // 'total_la'
                          ) + ' MTS'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      <TwoLineCell paddingRight='10px' text1={this.state?.totalReadyQty + ' MT'} text2="" />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{
                        width: '13%',
                        paddingLeft: 0,
                        paddingRight: 16,
                        backgroundColor: '#FFF'
                      }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_pending',
                            'pending_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_pending',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                      style={{
                        paddingLeft: 8,
                        backgroundColor: '#FFF'
                      }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_approved',
                            'approved_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_approved',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                      style={{
                        paddingLeft: 18,
                        backgroundColor: '#FFF'
                      }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_inplant',
                            'in_plant_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_inplant',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth14}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'dispatched_quantity',
                            'dispatch_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'dispatched_quantity',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <TableContainer style={{ borderTop: `1px solid ${grey}` }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    style={{
                      // cursor: "pointer",
                      backgroundColor: '#FFF',
                    }}>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'open_quantity',
                            'open_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'today_open_quantity',
                            // 'total_la'
                          ) + ' MTS'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      <TwoLineCell paddingRight='10px' text1={this.state?.totalReadyQty + ' MT'} text2="" />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth15}
                      style={{
                        width: '13%',
                        paddingLeft: 0,
                        paddingRight: 16,
                        backgroundColor: '#FFF'
                      }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_pending',
                            'pending_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_pending',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                      style={{
                        paddingLeft: 8,
                        backgroundColor: '#FFF'
                      }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_approved',
                            'approved_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_approved',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth10}
                      style={{
                        paddingLeft: 18,
                        backgroundColor: '#FFF'
                      }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_inplant',
                            'in_plant_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'loading_advice_inplant',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellWidth14}
                      style={{ backgroundColor: '#FFF' }}
                    >
                      <TwoLineCell
                        text1={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'dispatched_quantity',
                            'dispatch_quantity'
                          ) + ' MT'
                        }
                        text2={
                          this.props.handleTotalSumCalculation(
                            this.state.readyQtyTextFieldData,
                            'dispatched_quantity',
                            'total_la'
                          ) + ' LA'
                        } />
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </div>
        }
        <Dialog
          onClose={this.handleCloseOpenDialogDispatch}
          open={this.state.openDialogDispatch}
          PaperProps={{
            style: {
              width: "34%",
              height: "60%",
            },
          }}
        >
          <div
            style={{
              padding: "8px 18px 0px 18px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Contract No. - {this.state.laOrderId} </span>
            <IconButton
              onClick={() => this.setState({ openDialogDispatch: false })}
            >
              <Close />
            </IconButton>
          </div>
          <div style={{ padding: "18px 18px 0px 18px" }}>
            <span>LA List</span>
            <div style={{ Height: "100%", overflow: "scroll" }}>
              {this.state.laList.map((data) => (
                <ExpansionPanel style={{ marginTop: "10px" }}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ width: "100%" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          style={{
                            backgroundColor: "white",
                            border: `1px solid ${COLORS.secondaryBlueText}`,
                          }}
                        >
                          <HourglassEmpty style={{ color: "blue" }} />
                        </Avatar>
                        <span style={{ color: COLORS.grey }}>
                          {data.dispatch_status}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "54%",
                        }}
                      >
                        <span>{data.la_number}</span>
                        <span style={{ color: COLORS.grey, fontSize: "0.8em" }}>
                          {moment(data.gate_in_date).format("DD-MMM-YYYY")}
                        </span>
                        <span style={{ color: COLORS.grey, fontSize: "0.8em" }}>
                          {data.category_name}
                        </span>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{data.loading_advice_quantity} MT</span>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Button
                        onClick={() =>
                          this.handlePrintButton(data.la_number, "PRINT")
                        }
                      >
                        <Print style={{ marginRight: "3px" }} />
                      </Button>
                      <Button
                        onClick={() =>
                          this.handlePrintButton(data.la_number, "DOWNLOAD")
                        }
                      >
                        <CloudDownloadOutlinedIcon
                          style={{ marginRight: "3px" }}
                        />
                      </Button>
                    </div>

                    {data.dispatch_status === "PENDING" ? (
                      <ExpansionPanelDetails style={{ float: "right" }}>
                        <Typography>
                          <ToolTipComponent
                            title="You have no approve permission on this widget"
                            condition={!hasApproveAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)}
                          >
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openDiscardDialog: true,
                                  openDialogDispatch: false,
                                  selectedLaId: data.id,
                                });
                              }}
                              variant="contained"
                              style={{
                                backgroundColor: hasApproveAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                  ? COLORS.orange
                                  : 'rgba(0, 0, 0, 0.12)',
                                color: "white",
                              }}
                              disabled={
                                hasApproveAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                  ? false
                                  : true
                              }
                            >
                              <Delete />
                              Discard
                            </Button>
                          </ToolTipComponent>
                          <ToolTipComponent
                            title="You have no approve permission on this widget"
                            condition={!hasApproveAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)}
                          >
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: hasApproveAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                  ? "green"
                                  : 'rgba(0, 0, 0, 0.12)',
                                color: "white",
                                marginLeft: "16px",
                              }}
                              //disabled = {!(hasApproveAccess(this.props.user, props.plant_code + '_loadingAdvice'))}
                              onClick={(e) => {
                                e.preventDefault();
                                if (data?.created_by?.indexOf("Customer") > -1) {
                                  axios.get(`${APIROUTES.GET_MORE_LA_DETAILS}${data?.la_number}/`, getOptions(this.props.user))
                                    .then(res => {
                                      console.log(res, res.data[0])
                                      this.props.fetchPendingQuantity(tableDataByDate?.orders?.find(z => z.order_number === this.state.laOrderId)?.id)
                                        .then((response) => {
                                          this.props?.history?.push(
                                            {
                                              pathname:
                                                `/dispatchPlanning/order/${tableDataByDate?.orders?.find(z => z.order_number === this.state.laOrderId)?.id}/editLoadingAdvice/${data?.id}`,
                                              state: {
                                                pendingQuantity: response?.data,
                                                plantId: this.props.location.state?.plant_id,
                                                plantsName: this.props.plantsName,
                                                date: tableDataByDate?.date,
                                                status: data?.dispatch_status,
                                                laQty: data?.loading_advice_quantity,
                                                itemId: this.props.selectedItemCategory,
                                                customerId: tableDataByDate?.orders?.find(z => z.order_number === this.state.laOrderId)?.customer,
                                                laId: data?.id,
                                                itemTotalQuantity: data?.loading_advice_quantity,
                                                orderNo: this.state.laOrderId,
                                                paymentMethodId: data?.payment_method,
                                                buyerPinCode: null,
                                                plants: this.props.plants,
                                                orderStatus: tableDataByDate?.orders
                                                  ?.find(z => z.order_number === this.state.laOrderId)
                                                  ?.order_status,
                                                isCreatedByCustomer: true,
                                                laRowData: res.data[0]
                                              }
                                            }
                                          )
                                        })
                                    })

                                } else {
                                  this.props
                                    .handleUpdateLaApproval("APPROVED", data.id)
                                    .then((response) => {
                                      // console.log(response);
                                      if (response.status === 200) {
                                        // console.log("falied3")
                                        this.props.handleFetchDispatchDetailsWithDate(
                                          selectedDate,
                                          sessionStorage.getItem("itemCategoryID"),
                                          this.props.match.params.plantId
                                        );
                                        this.setState({
                                          openDialogDispatch: false,
                                          openSnackbar: true,
                                          snackbarMessage: "Approved successfully ",
                                          snackbarType: "success",
                                        });
                                      }
                                    })
                                    .catch((err) => {
                                      console.log(err.response);
                                    });
                                }
                              }}
                              disabled={
                                hasApproveAccess(this.props.user, `${sessionStorage.getItem('plantsCode')}-${sessionStorage.getItem('itemCategoryNAME')}-dispatch`)
                                  ? false
                                  : true
                              }
                            >
                              <Check />
                              {data?.created_by?.indexOf("Customer") > -1 ? "REVIEW & APPROVE" : "APPROVE"}
                            </Button>
                          </ToolTipComponent>
                        </Typography>
                      </ExpansionPanelDetails>
                    ) : null}
                  </div>
                </ExpansionPanel>
              ))}
            </div>
          </div>
        </Dialog>
        <Dialog
          open={this.state.openDiscardDialog}
          onClose={this.handleCloseDiscardDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Discard</DialogTitle>
          <DialogContent>Are you sure you want to Discard this ?</DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px 20px",
            }}
          >
            <Button variant="contained" onClick={this.handleCloseDiscardDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: COLORS.orange,
                color: "white",
              }}
              onClick={(e) => {
                e.preventDefault();
                this.props
                  .handleUpdateLaApproval("CANCELLED", this.state.selectedLaId)
                  .then((response) => {
                    // console.log(response);
                    if (response.status === 200) {
                      this.props.handleFetchDispatchDetailsWithDate(
                        selectedDate,
                        sessionStorage.getItem("itemCategoryID"),
                        this.props.match.params.plantId
                      );
                      this.setState({
                        openDiscardDialog: false,
                        openSnackbar: true,
                        snackbarMessage: "Discard successfully ",
                        snackbarType: "success",
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err.response);
                  });
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </Dialog>
        <SnackbarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          snackbarMessage={this.state.snackbarMessage}
          type={this.state.snackbarType}
          customAutoHideDuration={3000}
        />
      </Paper>
    );
  }
}

DispatchListTable.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(DispatchListTable));
