import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bottomNavigationFontColor, darkGreyColor, primaryColor, secondaryColor } from "../../../styles/colors";
import { pxToEm } from "../../../methods";
import { Button, Dialog, FormHelperText, IconButton } from "@material-ui/core";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Close from "@material-ui/icons/Close";
import moment from "moment";
import { lightGreen, secondaryGrey } from "../../../constants/internal/colors";
import SaveButton from "../../internal/common/SaveButton";

const styles = {
  topContainer: {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    alignItems: "center",
  },
  ledgerCardContainer: {
    cursor: "pointer",
    width: "100%",
    padding: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ledgerDetailChildContainer: {
    borderRadius: "12px",
    width: "90%",
    backgroundColor: '#FFF',
    boxShadow:
      "-1px 1px 1px 0px rgba(0, 0, 0.5, 0.1), -2px 3px 1px 0px rgba(0.7, 0, 0, 0.02), -2px 2px 7px 0px rgba(0, 0, 0, 0.12)",
  },
  ledgerDetailChildTopBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 20px 0px",
    color: bottomNavigationFontColor,
    alignItems: 'center',
  },
  ledgerShowMainDetail: {
    padding: "15px 20px 25px 20px",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    height: "80%",
    alignItems: "flex-start",
    width: '100%',
  },
  nameTitle: {
    color: secondaryColor,
    fontSize: '1.2em',
    fontWeight: 500
  },
  dateTitle: {
    fontSize: '1.3em',
    fontWeight: 500
  },
  requestedTitle: {
    color: darkGreyColor,
    fontSize: "0.8em"
  },
  bottomTextContainer: {
    width: "90%",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    padding: "6px",
    marginTop: 15
  },
  bottomText: {
    color: darkGreyColor,
    fontSize: '1.1em',
    fontWeight: 500,
    width: '100%',
  },
  buttonContainer: {
    width: "90%",
    // height: 250,
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: "center",
    padding: "6px",
    marginTop: 25
  },
  downloadButtonStyle: (isLoading, fetched) => ({
    color: isLoading || !fetched ? 'rgba(0,0,0,0.26)' : "white",
    fontSize: window.innerWidth < 375 ? '1em' : pxToEm(18),
    fontWeight: "bold",
    backgroundColor: isLoading || !fetched ? 'rgba(0,0,0,0.26)' : primaryColor,
    margin: '40px 0 6px 0',
    padding: '10px 20px'
  }),
  resetButton: {
    fontSize: window.innerWidth < 375 ? '1em' : "1.2em",
    textTransform: "inherit",
    marginRight: 10,
    color: primaryColor
  },
  dialogContainer: {
    border: "1px solid gainsboro",
    borderRadius: "15px",
    padding: "15px 20px 20px 20px"
  },
  dialogTop: {
    display: "flex",
    justifyContent: "flex-end",
    // padding: "10px 26px",
    height: "5vh",
    alignItems: "center",
  },
  dialogContentContainer: {
    padding: "0px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.2em",
    height: "10vh",
  },
  dialogButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "8vh",
  },
  dialogButtonShow: {
    width: "90%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  goBackButton: {
    color: primaryColor,
    textTransform: "capitalize",
  },
  yesButtonStyle: {
    width: "78px",
    backgroundColor: primaryColor,
    padding: "8px 24px",
  },
};
class LedgerRequestDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false
    };
  }


  handleClose = () => {
    this.setState({ openDialog: false });
  }

  handleEmail = () => {
    window.open('mailto:' + 'sales@realdeals.pro', '_blank');
  }

  render() {
    const {
      isMobile,
      classes,
      ledgerRequestData,
      isLoading
    } = this.props;
    const {
      openDialog
    } = this.state;
    return (
      <div style={styles.topContainer}>
        <div style={{
          padding: "10px 0px",
          display: 'flex',
          alignItems: 'center',
          width: '92%'
        }}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push("/b2bProfile");
            }}
          >
            <KeyboardBackspace />
          </IconButton>
          <span
            style={{
              marginLeft: "7px",
              color: darkGreyColor,
              fontWeight: 600,
              fontSize: pxToEm(20)
            }}>
            My Ledger Requests
          </span>
        </div>

        <div style={styles.ledgerCardContainer}>
          <div style={styles.ledgerDetailChildContainer}>
            <div style={styles.ledgerDetailChildTopBar}>
              <span
                style={{
                  fontSize: window.innerWidth < 310
                    ? pxToEm(12)
                    : window.innerWidth < 375 && pxToEm(13.5)
                }}
              >
                Request #{ledgerRequestData?.id}
              </span>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: ledgerRequestData.status === 'APPROVED' && lightGreen
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: window.innerWidth < 310 ? pxToEm(11) : window.innerWidth < 375 && pxToEm(13.5),

                  }}
                >
                  {
                    ledgerRequestData.status === 'PENDING'
                      ? <div style={{ position: 'relative', transform: 'scale(0.8)' }}>
                        <span><ShieldOutlinedIcon /></span>
                        <span style={{ position: 'absolute', left: 0, transform: 'scale(0.7)' }}>
                          <PersonOutlinedIcon />
                        </span>
                      </div>
                      : ledgerRequestData.status === 'APPROVED'
                        ? <span style={{ transform: 'scale(0.7)' }}><CheckCircleIcon /></span>
                        : ledgerRequestData.status === 'REJECTED' && null
                  }
                </span>
                <span
                  style={{
                    // color: 
                    paddingBottom: ledgerRequestData.status !== 'REJECTED' && 2


                  }}
                >
                  {ledgerRequestData.status === 'APPROVED'
                    ? 'APPROVED'
                    : ledgerRequestData.status === 'REJECTED'
                      ? 'Rejected'
                      : 'Approval Pending'
                  }
                </span>
              </span>
            </div>
            <div style={styles.ledgerShowMainDetail}>
              <span style={styles.nameTitle}>
                {
                  ledgerRequestData.request_type === "D"
                    ? 'Debtor Account'
                    : ledgerRequestData.request_type === "G"
                      ? 'Sales Return/Purchase Account'
                      : ledgerRequestData.request_type === "K" &&
                      'Brokerage Account'
                }
              </span>
              <span style={styles.dateTitle}>
                {moment(ledgerRequestData?.from_date).format('DD-MMM-YY')} to {moment(ledgerRequestData?.to_date).format('DD-MMM-YY')}
              </span>
              <span style={styles.requestedTitle}>
                requested on  {moment(ledgerRequestData?.created_at).format('DD-MMM-YY hh:mm A')}
              </span>
              <span style={styles.requestedTitle}>
                Please email of your ledger to
                <a
                  href="#"
                  onClick={this.handleEmail}
                  style={{
                    textDecoration: "none",
                    marginLeft: 5
                  }}>
                  sales@realdeals.pro
                </a>
              </span>
            </div>
          </div>
        </div>

        <div style={styles.bottomTextContainer}>
          <span style={styles.bottomText}>
            {ledgerRequestData.status === 'APPROVED'
              ? 'Your request has been approved. Please click on the button below to downlaod the PDF.'
              : ledgerRequestData.status === 'REJECTED'
                ? `Your ledger request has been rejected ${ledgerRequestData?.reason ? 'due to ' + ledgerRequestData?.reason : " "}`
                : 'The request is pending approval by REAL Group Sales Team. We will notify when it gets approved or rejected'
            }
          </span>
        </div>

        <div style={styles.buttonContainer}>
          {
            ledgerRequestData.status === 'APPROVED' &&
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 20
            }}>
              <SaveButton
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleDownloadPDFButton(ledgerRequestData?.id)
                  // this.props.history.push("/b2bProfile/ledger")
                }}
                variant="contained"
                disabled={!ledgerRequestData.fetched}
                startIcon={<CloudDownloadOutlinedIcon />}
                style={styles.downloadButtonStyle(isLoading, ledgerRequestData.fetched)}
                isLoading={isLoading}
              >
                Download PDF
              </SaveButton>
              <FormHelperText
                style={{ fontSize: "0.8em", color: secondaryGrey }}
              >
                {!ledgerRequestData.fetched
                  ? 'This Ledger Request has been reset'
                  : `Available upto ${moment(ledgerRequestData?.approved_rejected_at || new Date('2023', '09', '30')).add(2, 'days').format('DD-MMM-YYYY')}`
                }
              </FormHelperText>
            </div>
          }
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.setState({ openDialog: true });
              }}
              style={styles.resetButton}
              variant="text"
              startIcon={<ReplayOutlinedIcon />}
            >
              RESET TO CREATE NEW REQUEST
            </Button>
            <FormHelperText
              style={{ fontSize: "0.8em", color: secondaryGrey }}
            >
              1 request allowed per day
            </FormHelperText>
          </div>
        </div>
        <Dialog
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          PaperProps={{
            style: {
              width: isMobile ? "78%" : "25%",
              overflowY: "inherit",
              borderRadius: "15px",
              alignItems: 'flex-start',
              padding: 8
            },
          }}
        >
          <div style={styles.dialogContainer}>
            <div className={classes.dialogTop}>
              <Close
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleClose()
                }}
              />
            </div>
            <div className={classes.dialogContentContainer}>
              <span>Current PDF will not be available after you reset. Are you sure?</span>
            </div>
            <div className={classes.dialogButtonContainer}>
              <div className={classes.dialogButtonShow}>
                <Button className={classes.goBackButton}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleClose()
                  }}
                >
                  Go Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.yesButtonStyle}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleResetLedgerRequest()
                    this.handleClose()
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(LedgerRequestDetail);
