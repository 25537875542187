import React, { Component } from "react";
import Layout from "../../../../layouts/internalLayout/InternalLayout";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  grey,
  primaryColor,
  whiteSmoke,
  secondaryGrey,
  highlitedTextColor,
} from "../../../../constants/internal/colors";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import SingleContractsOrderOverview from "../SingleContractsOrderOverview";
import SingleContractsItemAndPlantPanning from "../SingleContractsItemAndPlantPanning";

const styles = {
  SingleContractMainBody: {
    height: "100%",
    width: "100%",
    paddingBottom: "60px",
    overflowY: "scroll",
  },
  navigationBar: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "100%",
  },
  contractsButtonStyle: {
    textTransform: "capitalize",
    fontSize: "1.1em",
    color: highlitedTextColor,
  },
  contactTextFieldContainer: {
    marginTop: "30px",
    paddingLeft: "5px",
    fontSize: "1.5em",
    textTransform: "uppercase",
  },
  tabContainerStyle: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "15px",
    display: "flex",
  },
  tabContainerChildStyle: {
    width: "96%",
  },
  selectedTab: {
    backgroundColor: whiteSmoke,
    color: grey,
    fontSize: "0.9em",
    fontWeight: "bold",
  },
  tabsStyles: {
    backgroundColor: whiteSmoke,
    color: secondaryGrey,
    fontSize: "0.9em",
    fontWeight: "bold",
  },
};
class SingleContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }
  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  render() {
    const { tabValue } = this.state;
    const { classes } = this.props;
    return (
      <Layout {...this.props}>
        <div className={classes.SingleContractMainBody}>
          <div className={classes.navigationBar}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/internalContracts");
              }}
              size="small"
              className={classes.contractsButtonStyle}
            >
              Contracts
            </Button>

            <ChevronRight style={{ color: "#A3A3A3" }} />
            <span style={{ color: "#A3A3A3" }}>
              {this.props.match.params.id}
            </span>
          </div>
          <div className={classes.contactTextFieldContainer}>TMT 140mt</div>
          <div className={classes.tabContainerStyle}>
            <div className={classes.tabContainerChildStyle}>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    borderBottom: `2px solid ${primaryColor}`,
                    colur: grey,
                  },
                }}
                value={tabValue}
                onChange={this.handleTabChange}
                className={classes.tabsStyles}
                variant="scrollable"
              >
                <Tab
                  style={{ width: "25%" }}
                  label="ORDER OVERVIEW"
                  className={
                    tabValue === 0 ? classes.selectedTab : classes.tabsStyles
                  }
                />
                <Tab
                  style={{ width: "25%" }}
                  label="ITEM & AND PLANT PLANNING"
                  className={
                    tabValue === 1 ? classes.selectedTab : classes.tabsStyles
                  }
                />
                <Tab
                  style={{ width: "25%" }}
                  label="LOADING ADVICE"
                  className={
                    tabValue === 2 ? classes.selectedTab : classes.tabsStyles
                  }
                />
              </Tabs>
            </div>
          </div>
          {tabValue === 0 ? (
            <SingleContractsOrderOverview />
          ) : tabValue === 1 ? (
            <SingleContractsItemAndPlantPanning />
          ) : tabValue === 2 ? (
            <div>Tab 3 cliked</div>
          ) : null}
        </div>
      </Layout>
    );
  }
}
export default withRouter(withStyles(styles)(SingleContract));
